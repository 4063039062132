(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name UserFolder
   * @description Model for an individual user folder
   */
  angular.module('app.core.user-folders').factory('UserFolder', getUserFolderModel);

  /* @ngInject */
  function getUserFolderModel(
    $log,
    $q,
    $rootScope,
    _,
    JIL_STORAGE_FOLDERS,
    jilStorageFolders,
    jsUtils,
    MEDIA_TYPE,
    MEMBER_EVENT,
    MESSAGE,
    MODEL,
    modelCache,
    REMOVE_USER_STORAGE_OPTIONS,
    STORAGE_QUOTA_TYPE,
    StorageQuota,
    User
  ) {
    class UserFolder {
      /**
       * @description Creates a new UserFolder for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {User} options.deletedBy The user who deleted the UserFolder
       * @param {Date} options.deletedOn The deletion date of the UserFolder
       * @param {String} options.id A UserFolder's ID
       * @param {String} options.name A UserFolder's name
       * @param {Array} options.owners A UserFolder's owners
       * @param {String} options.path the path of the UserFolder
       * @param {StorageQuota} options.quota the storage quota of the UserFolder
       * @param {Date} options.repositoryCreatedDate The creation date of the UserFolder
       * @param {Date} options.repositoryLastModifiedDate The last modification date of the UserFolder
       * @param {String} options.state the state of the UserFolder
       * @param {String} options.type the type of the UserFolder
       * @param {String} options.userName User name of the UserFolder
       */
      constructor(options = {}) {
        this.usersToAdd = [];
        updateModel(this, options);
      }

      batchOperation() {
        this.$resolved = false;

        // Add and remove shared users
        const operations = _.union(
          this.getZipAssignedUsersToAdd(),
          this.getZipAssignedUsersToRemove()
        );

        return jilStorageFolders.userFolders
          .batchOperation(operations)
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this));

        function onSuccess() {
          $rootScope.$emit(MESSAGE.UPDATE.USERFOLDER, this);
          modelCache.put(MODEL.USERFOLDER, this, this.getReclaimedFolderPath());
          this.$resolved = true;
          return this;
        }

        function onError(error) {
          $log.error('Failed to update users for user folder. Error: ', error);
          this.$resolved = true;
          return $q.reject(error);
        }
      }

      getFolderZipProgress() {
        return UserFolder.getUserFolderByPath({
          path: this.getReclaimedFolderPath(),
        }).then((reclaimedFolder) => {
          this.id = reclaimedFolder.id;
          this.zipAssignedUsers = reclaimedFolder.zipAssignedUsers;
          registerSavedState(this);
        });
      }

      getReclaimedFolderPath() {
        return `/${JIL_STORAGE_FOLDERS.PATH.RECLAIMED}/${this.name}`;
      }

      getZipAssignedUsersToAdd() {
        const usersToAdd = _.differenceWith(
          this.zipAssignedUsers,
          this.savedState.zipAssignedUsers,
          compareUser
        );
        return _.map(usersToAdd, (user) => ({
          op: 'add',
          path: `/${this.id}/owners/${user.id}`,
        }));
      }

      getZipAssignedUsersToRemove() {
        const usersToRemove = _.differenceWith(
          this.savedState.zipAssignedUsers,
          this.zipAssignedUsers,
          compareUser
        );
        return _.map(usersToRemove, (user) => ({
          op: 'remove',
          path: `/${this.id}/owners/${user.id}`,
        }));
      }

      hasUnsavedChanges() {
        return !jsUtils.compareArrays(
          this.savedState.zipAssignedUsers,
          this.zipAssignedUsers,
          compareUser
        );
      }

      isFolder() {
        return this.type === MEDIA_TYPE.FOLDER;
      }

      key() {
        return this.id;
      }

      /**
       * @description Method to save changes to the user folder to the back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        this.$promise = this.batchOperation();
        return this.$promise;
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        return {
          id: this.id,
        };
      }

      /**
       * @class
       * @description Transforms the /organizations UserFolder response Object into a new
       *   UserFolder instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new UserFolder(dataTransferObject);
      }

      /**
       * @description Method to fetch the remove path override for user with user folders.
       *
       * @param {String} [id] - user id to restore user folder
       * @param {REMOVE_USER_STORAGE_OPTIONS} [type] - type of remove user storage
       * @param {Array<UserFolder>} [userStorageList] - user folders to operate
       *
       * @returns {String} remove path override.
       */
      static getRemovePathOverride({id, type, userStorageList}) {
        const result = {};
        _.forEach(userStorageList, (userFolder) => {
          switch (type) {
            case REMOVE_USER_STORAGE_OPTIONS.DISCARD:
              result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE`;
              break;
            case REMOVE_USER_STORAGE_OPTIONS.DELETE:
              result[userFolder.name] = `/${userFolder.name}/STORAGE/DELETE`;
              break;
            default:
              result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE/${id}`;
          }
        });
        return result;
      }

      /**
       * @description Method to fetch the user folder by path.
       *
       * @param {Object} options - configuration options for folder retrieval
       * @returns {UserFolder} UserFolder.
       */
      static getUserFolderByPath(options = {}) {
        const model = modelCache.get(MODEL.USERFOLDER, options.path);
        if (model) {
          return _.isEmpty(model) ? $q.reject() : $q.resolve(model);
        }
        return jilStorageFolders.userFolderByPath
          .get(options)
          .$promise.then((data) => {
            const userFolder = new UserFolder(data);
            modelCache.put(MODEL.USERFOLDER, userFolder, options.path);
            return userFolder;
          })
          .catch(() => {
            modelCache.put(MODEL.USERFOLDER, {}, options.path);
          });
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes User Folder data.
     *
     * @param {Object} model UserFolder model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options = {}) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick([
            'deletedBy',
            'deletedOn',
            'id',
            'name',
            'path',
            'repositoryCreatedDate',
            'repositoryLastModifiedDate',
            'state',
            'type',
            'userName',
          ])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      if (!model.quota) {
        model.quota = new StorageQuota(
          _.defaults(options.quota, {enforcement: STORAGE_QUOTA_TYPE.HARD})
        );
      }

      if (options.deletedBy) {
        model.deletedBy = new User(options.deletedBy);
      }
      model.owners = _.map(options.owners, (owner) => new User(owner));
      model.zipAssignedUsers = model.owners;

      // The name send back from the server could be encoded
      if (model.name) {
        model.name = decodeURI(model.name);
      }

      registerSavedState(model);

      // setup model as already resolved which ensures it can be used
      // interchangably when fetched from the user-folder list or instantiated
      // independently
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    // We register the cache size for this class
    modelCache.register(MODEL.USERFOLDER, 10);
    modelCache.register(MODEL.USERFOLDERMETADATA, 1);

    modelCache.removeAllOnEvent(MODEL.USERFOLDER, [
      MEMBER_EVENT.CREATE,
      MEMBER_EVENT.DELETE,
      MEMBER_EVENT.UPDATE,
    ]);

    return UserFolder;

    /**
     * @description Compare two users.
     *
     * @param {Array} user1 reference to the first user
     * @param {Array} user2 reference to the second user
     * @returns {Boolean} true if the two items are equal
     */
    function compareUser(user1, user2) {
      return _.isMatch(user1, {email: user2.email, id: user2.id, type: user2.type});
    }

    /**
     * @description Updates model with a nested form of itself recording state
     *     which may be later modified.
     *
     * @param {Object} model - organization user model to save state on
     */
    function registerSavedState(model) {
      model.savedState = _.cloneDeep(model);
    }
  }
})();
