import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS} from '@admin-tribe/binky';
import {useCallback, useMemo, useReducer} from 'react';

import {
  checkDeHasState,
  checkDeIsEnabled,
  sortByEmail,
} from 'features/settings/common/utils/domainEnforcementUtils';
import useDomainEnforcement from 'features/settings/hooks/api/useDomainEnforcement';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
  UPDATE_DOMAIN_ENFORCEMENT_DATA: 'updateDomainEnforcementData',
  UPDATE_EXCLUSION_LIST_DATA: 'updateExclusionListData',
};

/**
 * A reducer which handles the actions dispatch for the domain enforcement
 */
const domainEnforcementStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD: {
      return {
        ...state,
        exceptionList: action.payload,
        isLoading: false,
      };
    }
    case ACTIONS.UPDATE_EXCLUSION_LIST_DATA:
      return {
        ...state,
        exceptionList: [...action.payload],
        isLoading: false,
      };
    case ACTIONS.UPDATE_DOMAIN_ENFORCEMENT_DATA:
      return {
        ...state,
        domainEnforcement: {...state.domainEnforcement, state: action.payload},
        isLoading: false,
      };
    default:
      return state;
  }
};

/**
 * A generic hook that can be used to create and update a domain enforcement state
 */
const useDomainEnforcementState = ({domainEnforcement}) => {
  const {clearDomainEnforcementCache, getExceptions, updateDomainEnforcementPolicy} =
    useDomainEnforcement();

  const [state, dispatch] = useReducer(domainEnforcementStateReducer, {
    domainEnforcement,
    exceptionList: [],
    hasLoadingError: false,
    isLoading: false,
  });

  const isDeEnabled = useMemo(
    () => checkDeIsEnabled(state.domainEnforcement.state),
    [state.domainEnforcement.state]
  );

  const isDeEnforced = useMemo(
    () =>
      checkDeHasState(state.domainEnforcement.state, [
        DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED,
      ]),
    [state.domainEnforcement.state]
  );

  const isDeNotified = useMemo(
    () =>
      checkDeHasState(state.domainEnforcement.state, [
        DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.NOTIFIED,
      ]),
    [state.domainEnforcement.state]
  );

  const updateDomainEnforcement = useCallback(
    async (updateState) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        await updateDomainEnforcementPolicy({
          directoryId: domainEnforcement.authSrc,
          state: updateState,
        });

        dispatch({
          payload: updateState,
          type: ACTIONS.UPDATE_DOMAIN_ENFORCEMENT_DATA,
        });
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});

        throw error;
      }
    },
    [updateDomainEnforcementPolicy, domainEnforcement.authSrc]
  );

  /**
   * Function used to fetch a domain enforcement exceptionList.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadExceptionList = useCallback(async () => {
    dispatch({type: ACTIONS.DATA_LOAD});

    try {
      const data = await getExceptions({directoryId: state.domainEnforcement.authSrc});
      const sortedData = data.sort(sortByEmail);

      dispatch({
        payload: sortedData,
        type: ACTIONS.FINISH_DATA_LOAD,
      });

      return sortedData;
    } catch (error) {
      dispatch({type: ACTIONS.DATA_LOAD_ERROR});
      return undefined;
    }
  }, [getExceptions, state.domainEnforcement.authSrc]);

  /**
   * Function used to patch the exception list data from the state
   */
  const updateDomainEnforcementExceptionList = useCallback((data) => {
    dispatch({
      payload: data,
      type: ACTIONS.UPDATE_EXCLUSION_LIST_DATA,
    });
  }, []);

  /**
   * Function used to reload the domain enforcement
   */
  const reloadDomainEnforcementExceptionList = () => {
    // clear the cache and reload the list of exceptions for domain enforcement
    clearDomainEnforcementCache();
    loadExceptionList();
  };

  return {
    loadExceptionList,
    reloadDomainEnforcementExceptionList,
    state: {...state, isDeEnabled, isDeEnforced, isDeNotified},
    updateDomainEnforcement,
    updateDomainEnforcementExceptionList,
  };
};

export default useDomainEnforcementState;

// exported for unit-testing
export {domainEnforcementStateReducer};
