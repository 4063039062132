(function () {
  /**
   * @deprecated moved to src2 behind `temp_react_port_support_ticket_add_discussion_item`
   *
   * @ngdoc component
   * @name app.widgets.support.component:appSupportTicketAddDiscussionItem
   * @description Component that contains buttons to turn on/off (show/hide)
   *   different ways that users can communicate back to support staff on a
   *   Support Ticket. These methods are: adding an attachment and adding a
   *   comment.
   * @param {SupportTicket} supportTicket - reference to SupportTicket service
   *   instance that files and comments should be uploaded/attached to
   */
  const componentName = 'appSupportTicketAddDiscussionItem';
  angular.module('app.widgets.support').component(componentName, {
    bindings: {
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-add-discussion-item/support-ticket-add-discussion-item.component.html',
  });

  /* @ngInject */
  function controller(_, AnalyticsEvent) {
    const vm = this;

    _.assign(vm, {
      closeAddAttachmentView,
      closeAddCommentView,
      isNoDiscussionViewEnabled,
      openAddAttachmentView,
      openAddCommentView,
    });

    /**
     * @description Method to close the add attachment view.
     */
    function closeAddAttachmentView() {
      vm.isAddAttachmentViewOpen = false;
    }

    /**
     * @description Method to close the add comment view.
     */
    function closeAddCommentView() {
      vm.isAddCommentViewOpen = false;
    }

    /**
     * @description Method to determine if the no discussion items view/message
     *   should be displayed to the user.
     * @return {Boolean} true if should show no discussion items message, else
     *   false
     */
    function isNoDiscussionViewEnabled() {
      return (
        !vm.isAddAttachmentViewOpen &&
        !vm.isAddCommentViewOpen &&
        !vm.supportTicket.isClosed() &&
        vm.supportTicket.getDiscussionItems().length === 0
      );
    }

    /**
     * @description Method to open the add attachment view.
     */
    function openAddAttachmentView() {
      closeAddCommentView();
      vm.isAddAttachmentViewOpen = true;
      triggerAnalytics('addAttachmentButton');
    }

    /**
     * @description Method to open the add comment view.
     */
    function openAddCommentView() {
      closeAddAttachmentView();
      vm.isAddCommentViewOpen = true;
      triggerAnalytics('addCommentButton');
    }

    function triggerAnalytics(componentMethod) {
      AnalyticsEvent.dispatch({
        componentMethod,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
