import {Offer} from '@admin-tribe/binky';
import {Content, ContextualHelp, Footer, Heading, Text} from '@adobe/react-spectrum';
import {OutLink} from '@pandora/react-a11y-link';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

// ContextualHelp for an offer in OfferTable.
const OfferContextualHelp = ({offer}) => {
  const intl = useIntl();

  const name = offer.merchandising.copy.name;
  const description = offer.merchandising.copy.short_description;
  const links = offer.merchandising.links?.help_admin ?? [];

  return (
    <ContextualHelp variant="info">
      <Heading>{name}</Heading>
      <Content>
        <Text>{description}</Text>
      </Content>
      {links.length > 0 && (
        <Footer>
          <OutLink
            href={links[0].href}
            linkOutAriaLabel={intl.formatMessage({
              id: 'products.allProducts.body.offers.newWindow',
            })}
          >
            {intl.formatMessage(
              {id: 'products.allProducts.body.offers.learnMore'},
              {productName: name}
            )}
          </OutLink>
        </Footer>
      )}
    </ContextualHelp>
  );
};

OfferContextualHelp.propTypes = {
  /** The offer to display the help for. */
  offer: PropTypes.instanceOf(Offer).isRequired,
};

export default OfferContextualHelp;
