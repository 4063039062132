(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name directoryAccess
   * @description directory access factory
   */
  angular.module('app.core.directories').factory('directoryAccess', DirectoryAccess);
  /* @ngInject */
  function DirectoryAccess(
    $q,
    auth,
    directoryFacade,
    DomainList,
    feature,
    ORGANIZATION_TYPE,
    OrganizationList,
    OrganizationManager
  ) {
    const service = {
      canAddDomains,
      canAssignUser: allowIfNotMigrating,
      canCreateDirectory,
      canCreateType2Or3,
      canEditEncryptionStatus,
      canEditUser: allowIfNotMigrating,
      canRemoveUser: allowIfNotMigrating,
      canSwitchIdentityTypes,
      canViewDirectories,
      canViewDirectoryDetails,
      canViewDirectoryGroups,
      canViewDomains,
      canViewEncryptionStatus,
      canViewJitProvisioningSettings,
      canViewTeacherSettings,
    };

    return service;

    //////////////

    function allowIfNotMigrating() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    /**
     * @deprecated. Use Src2 directoryAccess.js: canAddDomains().
     * @returns {Promise<Boolean>} - a promise resolving to true/false if org can claim domains
     */
    function canAddDomains() {
      return hasDomainClaiming();
    }

    function canCreateDirectory() {
      // An admin in an org with domain claiming can create directories
      return canViewDomains();
    }

    function canEditEncryptionStatus() {
      if (!canViewEncryptionStatus()) {
        return $q.resolve(false);
      }
      return hasDomainClaiming();
    }

    function canSwitchIdentityTypes() {
      if (OrganizationManager.getContractsForActiveOrg().isMigrating()) {
        return $q.resolve(false);
      }
      if (auth.isUserOrgAdmin()) {
        return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) =>
          productList.someByFulfillableItemCondition((fItems) => fItems.hasDomainClaiming())
        );
      }
      return $q.resolve(false);
    }

    function canViewDirectories() {
      if (!auth.isUserOrgAdmin()) {
        return $q.resolve(false);
      }
      return $q
        .all({hasDirectories: hasDirectories(), hasDomainClaiming: hasDomainClaiming()})
        .then((resolved) => resolved.hasDirectories || resolved.hasDomainClaiming);
    }

    function canViewEncryptionStatus() {
      return OrganizationList.get().activeOrg.type === ORGANIZATION_TYPE.ENTERPRISE;
    }

    function canViewDomains() {
      return auth.isUserOrgAdmin()
        ? $q
            .all({hasDomainClaiming: hasDomainClaiming(), hasDomains: hasDomains()})
            .then((resolved) => resolved.hasDomains || resolved.hasDomainClaiming)
        : $q.resolve(false);
    }

    /**
     * @deprecated. Use Src2 directoryAccess.js: canCreateType2Or3().
     * @returns {Promise<Boolean>} - a promise resolving to true/false if org can create type 2 or 3
     */
    function canCreateType2Or3() {
      if (OrganizationManager.getContractsForActiveOrg().isMigrating()) {
        return $q.resolve(false);
      }
      return hasDomainClaiming();
    }

    function canViewDirectoryGroups(directory) {
      return directory.$promise.then((model) => model.isPrimaryIdpAzure);
    }

    function canViewDirectoryDetails(directory) {
      return directory.$promise.then(
        (model) =>
          model.isType3() &&
          (feature.isEnabled('temp_idp_migration') ||
            model.isStatusActive() ||
            model.isStatusNeedsDomain() ||
            model.requiresActivation())
      );
    }

    function canViewTeacherSettings() {
      return (
        OrganizationManager.isActiveOrgEdu() &&
        OrganizationList.get().activeOrg.countryCode === 'US'
      );
    }

    /**
     * Checks to see if a user can see the JIT toggle.
     * Takes into consideration if the feature flag is present or not
     * and if the directory is Type3.
     *
     * @param {Object} directory The directory that needs checking
     *
     * @returns {boolean} true if can view jit settings, false otherwise
     */
    function canViewJitProvisioningSettings(directory) {
      return (
        OrganizationManager.isActiveOrgEdu() &&
        OrganizationList.get().activeOrg.countryCode === 'US' &&
        directory.isType3()
      );
    }

    function hasDirectories() {
      return directoryFacade
        .getDirectoryList()
        .$promise.then((directories) => directories.items.length > 0);
    }

    function hasDomains() {
      return DomainList.get({
        orgId: OrganizationManager.getActiveOrgId(),
      }).$promise.then((domainList) => domainList.items.length > 0);
    }

    /**
     * @deprecated. Use src2 directoryAccess.js : hasDomainClaiming().
     * @returns {Promise<Boolean>} - a promise resolving to true/false if org can claim domains
     */
    function hasDomainClaiming() {
      return $q
        .all({
          fItemsHasDomainClaiming: OrganizationManager.getProductsForActiveOrg().$promise.then(
            (productList) =>
              productList.someByFulfillableItemCondition((fItems) => fItems.hasDomainClaiming())
          ),
          migratingOrgHasDomainClaiming:
            OrganizationManager.getMigrationsForActiveOrg().$promise.then((migrationList) =>
              migrationList.shouldForceAllowDomainClaiming()
            ),
        })
        .then(
          (resolved) => resolved.fItemsHasDomainClaiming || resolved.migratingOrgHasDomainClaiming
        );
    }
  }
})();
