/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * WARNING: This constant's keys (e.g. ALASKAN_STANDARD_TIME) are used as string keys in
   * widgets.support.supportTicketTimezoneFilter.* - when changing this list be sure to update
   * the strings.
   *
   * WARNING: This constant should not be used outside of Support Anyware API. Where possible, use
   * standard IANA timezone identifiers (e.g. America/Los_Angeles) rather than the Microsoft
   * timezones in this list.
   *
   * This timezone list is the native Microsoft Dynamics timezone list, as provided by Madhava
   * Venkata (barrenka) from Adobe's Dynamics team. The list contains some additional metadata
   * from other sources as described below.
   *
   * The keys in this constant (e.g. ALASKAN_STANDARD_TIME) are snake-case and upper-case
   * transformation of the WINDOWS_ID properties.
   *
   * Value: Object with the following properties:
   *   - DYNAMICS_ID: The Dynamics ID for the timezone. This is the value that should be passed to
   *       or from the Support Anyware APIs. Sourced from the "Timezonecode" field provided by
   *       Madhava.
   *   - IANA_IDS: Array of IANA timezone IDs which are equivalent to this Dynamics timezone.
   *       Sourced from https://unicode.org/repos/cldr/trunk/common/supplemental/windowsZones.xml
   *   - UTC_OFFSET: The timezone's UTC offset, in minutes, sourced from the "UserInterfaceName"
   *       field provided by Madhava.
   *   - WINDOWS_ID: The Microsoft Windows timezone ID for this timezone. Sourced from the
   *       "StandardName" field provided by Madhava.
   */
  angular.module('app.core.support').constant('SUPPORT_TICKET_TIMEZONE', {
    AFGHANISTAN_STANDARD_TIME: {
      DYNAMICS_ID: 175,
      IANA_IDS: ['Asia/Kabul'],
      UTC_OFFSET: 270,
      WINDOWS_ID: 'Afghanistan Standard Time',
    },
    ALASKAN_STANDARD_TIME: {
      DYNAMICS_ID: 3,
      IANA_IDS: [
        'America/Anchorage',
        'America/Juneau',
        'America/Metlakatla',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
      ],
      UTC_OFFSET: -540,
      WINDOWS_ID: 'Alaskan Standard Time',
    },
    ALEUTIAN_STANDARD_TIME: {
      DYNAMICS_ID: 7,
      IANA_IDS: ['America/Adak'],
      UTC_OFFSET: -600,
      WINDOWS_ID: 'Aleutian Standard Time',
    },
    ALTAI_STANDARD_TIME: {
      DYNAMICS_ID: 208,
      IANA_IDS: ['Asia/Barnaul'],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'Altai Standard Time',
    },
    ARAB_STANDARD_TIME: {
      DYNAMICS_ID: 150,
      IANA_IDS: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'Arab Standard Time',
    },
    ARABIAN_STANDARD_TIME: {
      DYNAMICS_ID: 165,
      IANA_IDS: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Arabian Standard Time',
    },
    ARABIC_STANDARD_TIME: {
      DYNAMICS_ID: 158,
      IANA_IDS: ['Asia/Baghdad'],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'Arabic Standard Time',
    },
    ARGENTINA_STANDARD_TIME: {
      DYNAMICS_ID: 69,
      IANA_IDS: [
        'America/Argentina/La_Rioja',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Buenos_Aires',
        'America/Catamarca',
        'America/Cordoba',
        'America/Jujuy',
        'America/Mendoza',
      ],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Argentina Standard Time',
    },
    ASTRAKHAN_STANDARD_TIME: {
      DYNAMICS_ID: 176,
      IANA_IDS: ['Europe/Astrakhan', 'Europe/Ulyanovsk'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Astrakhan Standard Time',
    },
    ATLANTIC_STANDARD_TIME: {
      DYNAMICS_ID: 50,
      IANA_IDS: [
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Halifax',
        'America/Moncton',
        'America/Thule',
        'Atlantic/Bermuda',
      ],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Atlantic Standard Time',
    },
    AUS_CENTRAL_STANDARD_TIME: {
      DYNAMICS_ID: 245,
      IANA_IDS: ['Australia/Darwin'],
      UTC_OFFSET: 570,
      WINDOWS_ID: 'AUS Central Standard Time',
    },
    AUS_CENTRAL_W_STANDARD_TIME: {
      DYNAMICS_ID: 231,
      IANA_IDS: ['Australia/Eucla'],
      UTC_OFFSET: 525,
      WINDOWS_ID: 'Aus Central W. Standard Time',
    },
    AUS_EASTERN_STANDARD_TIME: {
      DYNAMICS_ID: 255,
      IANA_IDS: ['Australia/Melbourne', 'Australia/Sydney'],
      UTC_OFFSET: 600,
      WINDOWS_ID: 'AUS Eastern Standard Time',
    },
    AZERBAIJAN_STANDARD_TIME: {
      DYNAMICS_ID: 169,
      IANA_IDS: ['Asia/Baku'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Azerbaijan Standard Time',
    },
    AZORES_STANDARD_TIME: {
      DYNAMICS_ID: 80,
      IANA_IDS: ['America/Scoresbysund', 'Atlantic/Azores'],
      UTC_OFFSET: -60,
      WINDOWS_ID: 'Azores Standard Time',
    },
    BAHIA_STANDARD_TIME: {
      DYNAMICS_ID: 71,
      IANA_IDS: ['America/Bahia'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Bahia Standard Time',
    },
    BANGLADESH_STANDARD_TIME: {
      DYNAMICS_ID: 196,
      IANA_IDS: ['Asia/Dhaka', 'Asia/Thimphu'],
      UTC_OFFSET: 360,
      WINDOWS_ID: 'Bangladesh Standard Time',
    },
    BELARUS_STANDARD_TIME: {
      DYNAMICS_ID: 151,
      IANA_IDS: ['Europe/Minsk'],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'Belarus Standard Time',
    },
    BOUGAINVILLE_STANDARD_TIME: {
      DYNAMICS_ID: 276,
      IANA_IDS: ['Pacific/Bougainville'],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Bougainville Standard Time',
    },
    CANADA_CENTRAL_STANDARD_TIME: {
      DYNAMICS_ID: 25,
      IANA_IDS: ['America/Regina', 'America/Swift_Current'],
      UTC_OFFSET: -360,
      WINDOWS_ID: 'Canada Central Standard Time',
    },
    CAPE_VERDE_STANDARD_TIME: {
      DYNAMICS_ID: 83,
      IANA_IDS: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
      UTC_OFFSET: -60,
      WINDOWS_ID: 'Cape Verde Standard Time',
    },
    CAUCASUS_STANDARD_TIME: {
      DYNAMICS_ID: 170,
      IANA_IDS: ['Asia/Yerevan'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Caucasus Standard Time',
    },
    CEN_AUSTRALIA_STANDARD_TIME: {
      DYNAMICS_ID: 250,
      IANA_IDS: ['Australia/Adelaide', 'Australia/Broken_Hill'],
      UTC_OFFSET: 570,
      WINDOWS_ID: 'Cen. Australia Standard Time',
    },
    CENTRAL_AMERICA_STANDARD_TIME: {
      DYNAMICS_ID: 33,
      IANA_IDS: [
        'America/Belize',
        'America/Costa_Rica',
        'America/El_Salvador',
        'America/Guatemala',
        'America/Managua',
        'America/Tegucigalpa',
        'Etc/GMT+6',
        'Pacific/Galapagos',
      ],
      UTC_OFFSET: -360,
      WINDOWS_ID: 'Central America Standard Time',
    },
    CENTRAL_ASIA_STANDARD_TIME: {
      DYNAMICS_ID: 195,
      IANA_IDS: [
        'Antarctica/Vostok',
        'Asia/Almaty',
        'Asia/Bishkek',
        'Asia/Qyzylorda',
        'Asia/Urumqi',
        'Etc/GMT-6',
        'Indian/Chagos',
      ],
      UTC_OFFSET: 360,
      WINDOWS_ID: 'Central Asia Standard Time',
    },
    CENTRAL_BRAZILIAN_STANDARD_TIME: {
      DYNAMICS_ID: 58,
      IANA_IDS: ['America/Campo_Grande', 'America/Cuiaba'],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Central Brazilian Standard Time',
    },
    CENTRAL_EUROPE_STANDARD_TIME: {
      DYNAMICS_ID: 95,
      IANA_IDS: [
        'Europe/Belgrade',
        'Europe/Bratislava',
        'Europe/Budapest',
        'Europe/Ljubljana',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Tirane',
      ],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'Central Europe Standard Time',
    },
    CENTRAL_EUROPEAN_STANDARD_TIME: {
      DYNAMICS_ID: 100,
      IANA_IDS: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'Central European Standard Time',
    },
    CENTRAL_PACIFIC_STANDARD_TIME: {
      DYNAMICS_ID: 280,
      IANA_IDS: [
        'Antarctica/Macquarie',
        'Etc/GMT-11',
        'Pacific/Efate',
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Pacific/Noumea',
        'Pacific/Ponape',
      ],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Central Pacific Standard Time',
    },
    CENTRAL_STANDARD_TIME: {
      DYNAMICS_ID: 20,
      IANA_IDS: [
        'America/Chicago',
        'America/Indiana/Knox',
        'America/Indiana/Tell_City',
        'America/Matamoros',
        'America/Menominee',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Resolute',
        'America/Winnipeg',
        'CST6CDT',
      ],
      UTC_OFFSET: -360,
      WINDOWS_ID: 'Central Standard Time',
    },
    CENTRAL_STANDARD_TIME_MEXICO: {
      DYNAMICS_ID: 29,
      IANA_IDS: [
        'America/Bahia_Banderas',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
      ],
      UTC_OFFSET: -360,
      WINDOWS_ID: 'Central Standard Time (Mexico)',
    },
    CHATHAM_ISLANDS_STANDARD_TIME: {
      DYNAMICS_ID: 299,
      IANA_IDS: ['Pacific/Chatham'],
      UTC_OFFSET: 765,
      WINDOWS_ID: 'Chatham Islands Standard Time',
    },
    CHINA_STANDARD_TIME2: {
      DYNAMICS_ID: 210,
      IANA_IDS: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'China Standard Time',
    },
    CUBA_STANDARD_TIME: {
      DYNAMICS_ID: 44,
      IANA_IDS: ['America/Havana'],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'Cuba Standard Time',
    },
    DATELINE_STANDARD_TIME: {
      DYNAMICS_ID: 0,
      IANA_IDS: ['Etc/GMT+12'],
      UTC_OFFSET: -720,
      WINDOWS_ID: 'Dateline Standard Time',
    },
    E_AFRICA_STANDARD_TIME: {
      DYNAMICS_ID: 155,
      IANA_IDS: [
        'Africa/Addis_Ababa',
        'Africa/Asmera',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Mogadishu',
        'Africa/Nairobi',
        'Antarctica/Syowa',
        'Etc/GMT-3',
        'Indian/Antananarivo',
        'Indian/Comoro',
        'Indian/Mayotte',
      ],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'E. Africa Standard Time',
    },
    E_AUSTRALIA_STANDARD_TIME: {
      DYNAMICS_ID: 260,
      IANA_IDS: ['Australia/Brisbane', 'Australia/Lindeman'],
      UTC_OFFSET: 600,
      WINDOWS_ID: 'E. Australia Standard Time',
    },
    E_EUROPE_STANDARD_TIME: {
      DYNAMICS_ID: 115,
      IANA_IDS: ['Europe/Chisinau'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'E. Europe Standard Time',
    },
    E_SOUTH_AMERICA_STANDARD_TIME: {
      DYNAMICS_ID: 65,
      IANA_IDS: ['America/Sao_Paulo'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'E. South America Standard Time',
    },
    EASTER_ISLAND_STANDARD_TIME: {
      DYNAMICS_ID: 34,
      IANA_IDS: ['Pacific/Easter'],
      UTC_OFFSET: -360,
      WINDOWS_ID: 'Easter Island Standard Time',
    },
    EASTERN_STANDARD_TIME: {
      DYNAMICS_ID: 35,
      IANA_IDS: [
        'America/Detroit',
        'America/Indiana/Petersburg',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Iqaluit',
        'America/Kentucky/Monticello',
        'America/Louisville',
        'America/Montreal',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Pangnirtung',
        'America/Thunder_Bay',
        'America/Toronto',
        'EST5EDT',
      ],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'Eastern Standard Time',
    },
    EASTERN_STANDARD_TIME_MEXICO: {
      DYNAMICS_ID: 301,
      IANA_IDS: ['America/Cancun'],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'Eastern Standard Time (Mexico)',
    },
    EGYPT_STANDARD_TIME: {
      DYNAMICS_ID: 120,
      IANA_IDS: ['Africa/Cairo'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Egypt Standard Time',
    },
    EKATERINBURG_STANDARD_TIME: {
      DYNAMICS_ID: 180,
      IANA_IDS: ['Asia/Yekaterinburg'],
      UTC_OFFSET: 300,
      WINDOWS_ID: 'Ekaterinburg Standard Time',
    },
    FIJI_STANDARD_TIME: {
      DYNAMICS_ID: 285,
      IANA_IDS: ['Pacific/Fiji'],
      UTC_OFFSET: 720,
      WINDOWS_ID: 'Fiji Standard Time',
    },
    FLE_STANDARD_TIME: {
      DYNAMICS_ID: 125,
      IANA_IDS: [
        'Europe/Helsinki',
        'Europe/Kiev',
        'Europe/Mariehamn',
        'Europe/Riga',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Zaporozhye',
      ],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'FLE Standard Time',
    },
    GEORGIAN_STANDARD_TIME: {
      DYNAMICS_ID: 173,
      IANA_IDS: ['Asia/Tbilisi'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Georgian Standard Time',
    },
    GMT_STANDARD_TIME: {
      DYNAMICS_ID: 85,
      IANA_IDS: [
        'Atlantic/Canary',
        'Atlantic/Faeroe',
        'Atlantic/Madeira',
        'Europe/Dublin',
        'Europe/Guernsey',
        'Europe/Isle_of_Man',
        'Europe/Jersey',
        'Europe/Lisbon',
        'Europe/London',
      ],
      UTC_OFFSET: 0,
      WINDOWS_ID: 'GMT Standard Time',
    },
    GREENLAND_STANDARD_TIME: {
      DYNAMICS_ID: 73,
      IANA_IDS: ['America/Godthab'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Greenland Standard Time',
    },
    GREENWICH_STANDARD_TIME: {
      DYNAMICS_ID: 90,
      IANA_IDS: [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Bamako',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Freetown',
        'Africa/Lome',
        'Africa/Monrovia',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Atlantic/Reykjavik',
        'Atlantic/St_Helena',
      ],
      UTC_OFFSET: 0,
      WINDOWS_ID: 'Greenwich Standard Time',
    },
    GTB_STANDARD_TIME: {
      DYNAMICS_ID: 130,
      IANA_IDS: ['Asia/Famagusta', 'Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'GTB Standard Time',
    },
    HAITI_STANDARD_TIME: {
      DYNAMICS_ID: 43,
      IANA_IDS: ['America/Port-au-Prince'],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'Haiti Standard Time',
    },
    HAWAIIAN_STANDARD_TIME: {
      DYNAMICS_ID: 2,
      IANA_IDS: [
        'Etc/GMT+10',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Rarotonga',
        'Pacific/Tahiti',
      ],
      UTC_OFFSET: -600,
      WINDOWS_ID: 'Hawaiian Standard Time',
    },
    INDIA_STANDARD_TIME: {
      DYNAMICS_ID: 190,
      IANA_IDS: ['Asia/Calcutta'],
      UTC_OFFSET: 330,
      WINDOWS_ID: 'India Standard Time',
    },
    IRAN_STANDARD_TIME: {
      DYNAMICS_ID: 160,
      IANA_IDS: ['Asia/Tehran'],
      UTC_OFFSET: 210,
      WINDOWS_ID: 'Iran Standard Time',
    },
    JERUSALEM_STANDARD_TIME: {
      DYNAMICS_ID: 135,
      IANA_IDS: [],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Jerusalem Standard Time',
    },
    JORDAN_STANDARD_TIME: {
      DYNAMICS_ID: 129,
      IANA_IDS: ['Asia/Amman'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Jordan Standard Time',
    },
    KALININGRAD_STANDARD_TIME: {
      DYNAMICS_ID: 159,
      IANA_IDS: ['Europe/Kaliningrad'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Kaliningrad Standard Time',
    },
    KOREA_STANDARD_TIME: {
      DYNAMICS_ID: 230,
      IANA_IDS: ['Asia/Seoul'],
      UTC_OFFSET: 540,
      WINDOWS_ID: 'Korea Standard Time',
    },
    LORD_HOWE_STANDARD_TIME: {
      DYNAMICS_ID: 274,
      IANA_IDS: ['Australia/Lord_Howe'],
      UTC_OFFSET: 630,
      WINDOWS_ID: 'Lord Howe Standard Time',
    },
    MAGADAN_STANDARD_TIME: {
      DYNAMICS_ID: 281,
      IANA_IDS: ['Asia/Magadan'],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Magadan Standard Time',
    },
    MARQUESAS_STANDARD_TIME: {
      DYNAMICS_ID: 8,
      IANA_IDS: ['Pacific/Marquesas'],
      UTC_OFFSET: -570,
      WINDOWS_ID: 'Marquesas Standard Time',
    },
    MAURITIUS_STANDARD_TIME: {
      DYNAMICS_ID: 172,
      IANA_IDS: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Mauritius Standard Time',
    },
    MID_ATLANTIC_STANDARD_TIME: {
      DYNAMICS_ID: 75,
      IANA_IDS: [],
      UTC_OFFSET: -120,
      WINDOWS_ID: 'Mid-Atlantic Standard Time',
    },
    MIDDLE_EAST_STANDARD_TIME: {
      DYNAMICS_ID: 131,
      IANA_IDS: ['Asia/Beirut'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Middle East Standard Time',
    },
    MONTEVIDEO_STANDARD_TIME: {
      DYNAMICS_ID: 74,
      IANA_IDS: ['America/Montevideo'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Montevideo Standard Time',
    },
    MOROCCO_STANDARD_TIME: {
      DYNAMICS_ID: 84,
      IANA_IDS: ['Africa/Casablanca', 'Africa/El_Aaiun'],
      UTC_OFFSET: 0,
      WINDOWS_ID: 'Morocco Standard Time',
    },
    MOUNTAIN_STANDARD_TIME: {
      DYNAMICS_ID: 10,
      IANA_IDS: [
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Denver',
        'America/Edmonton',
        'America/Inuvik',
        'America/Ojinaga',
        'America/Yellowknife',
        'MST7MDT',
      ],
      UTC_OFFSET: -420,
      WINDOWS_ID: 'Mountain Standard Time',
    },
    MOUNTAIN_STANDARD_TIME_MEXICO: {
      DYNAMICS_ID: 12,
      IANA_IDS: ['America/Chihuahua', 'America/Mazatlan'],
      UTC_OFFSET: -420,
      WINDOWS_ID: 'Mountain Standard Time (Mexico)',
    },
    MYANMAR_STANDARD_TIME: {
      DYNAMICS_ID: 203,
      IANA_IDS: ['Asia/Rangoon', 'Indian/Cocos'],
      UTC_OFFSET: 390,
      WINDOWS_ID: 'Myanmar Standard Time',
    },
    N_CENTRAL_ASIA_STANDARD_TIME: {
      DYNAMICS_ID: 201,
      IANA_IDS: ['Asia/Novosibirsk'],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'N. Central Asia Standard Time',
    },
    NAMIBIA_STANDARD_TIME: {
      DYNAMICS_ID: 141,
      IANA_IDS: ['Africa/Windhoek'],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'Namibia Standard Time',
    },
    NEPAL_STANDARD_TIME: {
      DYNAMICS_ID: 193,
      IANA_IDS: ['Asia/Katmandu'],
      UTC_OFFSET: 345,
      WINDOWS_ID: 'Nepal Standard Time',
    },
    NEW_ZEALAND_STANDARD_TIME: {
      DYNAMICS_ID: 290,
      IANA_IDS: ['Antarctica/McMurdo', 'Pacific/Auckland'],
      UTC_OFFSET: 720,
      WINDOWS_ID: 'New Zealand Standard Time',
    },
    NEWFOUNDLAND_STANDARD_TIME: {
      DYNAMICS_ID: 60,
      IANA_IDS: ['America/St_Johns'],
      UTC_OFFSET: -210,
      WINDOWS_ID: 'Newfoundland Standard Time',
    },
    NORFOLK_STANDARD_TIME: {
      DYNAMICS_ID: 277,
      IANA_IDS: ['Pacific/Norfolk'],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Norfolk Standard Time',
    },
    NORTH_ASIA_EAST_STANDARD_TIME: {
      DYNAMICS_ID: 227,
      IANA_IDS: ['Asia/Irkutsk'],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'North Asia East Standard Time',
    },
    NORTH_ASIA_STANDARD_TIME: {
      DYNAMICS_ID: 207,
      IANA_IDS: ['Asia/Krasnoyarsk', 'Asia/Novokuznetsk'],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'North Asia Standard Time',
    },
    NORTH_KOREA_STANDARD_TIME: {
      DYNAMICS_ID: 229,
      IANA_IDS: ['Asia/Pyongyang'],
      UTC_OFFSET: 510,
      WINDOWS_ID: 'North Korea Standard Time',
    },
    OMSK_STANDARD_TIME: {
      DYNAMICS_ID: 197,
      IANA_IDS: ['Asia/Omsk'],
      UTC_OFFSET: 360,
      WINDOWS_ID: 'Omsk Standard Time',
    },
    PACIFIC_SA_STANDARD_TIME: {
      DYNAMICS_ID: 56,
      IANA_IDS: ['America/Santiago'],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Pacific SA Standard Time',
    },
    PACIFIC_STANDARD_TIME: {
      DYNAMICS_ID: 4,
      IANA_IDS: [
        'America/Dawson',
        'America/Los_Angeles',
        'America/Vancouver',
        'America/Whitehorse',
        'PST8PDT',
      ],
      UTC_OFFSET: -480,
      WINDOWS_ID: 'Pacific Standard Time',
    },
    PACIFIC_STANDARD_TIME_MEXICO: {
      DYNAMICS_ID: 5,
      IANA_IDS: ['America/Santa_Isabel', 'America/Tijuana'],
      UTC_OFFSET: -480,
      WINDOWS_ID: 'Pacific Standard Time (Mexico)',
    },
    PAKISTAN_STANDARD_TIME: {
      DYNAMICS_ID: 184,
      IANA_IDS: ['Asia/Karachi'],
      UTC_OFFSET: 300,
      WINDOWS_ID: 'Pakistan Standard Time',
    },
    PARAGUAY_STANDARD_TIME: {
      DYNAMICS_ID: 59,
      IANA_IDS: ['America/Asuncion'],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Paraguay Standard Time',
    },
    ROMANCE_STANDARD_TIME: {
      DYNAMICS_ID: 105,
      IANA_IDS: [
        'Africa/Ceuta',
        'Europe/Brussels',
        'Europe/Copenhagen',
        'Europe/Madrid',
        'Europe/Paris',
      ],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'Romance Standard Time',
    },
    RUSSIA_TIME_ZONE_3: {
      DYNAMICS_ID: 174,
      IANA_IDS: ['Europe/Samara'],
      UTC_OFFSET: 240,
      WINDOWS_ID: 'Russia Time Zone 3',
    },
    RUSSIA_TIME_ZONE_10: {
      DYNAMICS_ID: 279,
      IANA_IDS: ['Asia/Srednekolymsk'],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Russia Time Zone 10',
    },
    RUSSIA_TIME_ZONE_11: {
      DYNAMICS_ID: 295,
      IANA_IDS: ['Asia/Anadyr', 'Asia/Kamchatka'],
      UTC_OFFSET: 720,
      WINDOWS_ID: 'Russia Time Zone 11',
    },
    RUSSIAN_STANDARD_TIME: {
      DYNAMICS_ID: 145,
      IANA_IDS: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd'],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'Russian Standard Time',
    },
    SA_EASTERN_STANDARD_TIME: {
      DYNAMICS_ID: 70,
      IANA_IDS: [
        'America/Belem',
        'America/Cayenne',
        'America/Fortaleza',
        'America/Maceio',
        'America/Paramaribo',
        'America/Recife',
        'America/Santarem',
        'Antarctica/Rothera',
        'Atlantic/Stanley',
        'Etc/GMT+3',
      ],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'SA Eastern Standard Time',
    },
    SA_PACIFIC_STANDARD_TIME: {
      DYNAMICS_ID: 45,
      IANA_IDS: [
        'America/Bogota',
        'America/Cayman',
        'America/Coral_Harbour',
        'America/Eirunepe',
        'America/Guayaquil',
        'America/Jamaica',
        'America/Lima',
        'America/Panama',
        'America/Rio_Branco',
        'Etc/GMT+5',
      ],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'SA Pacific Standard Time',
    },
    SA_WESTERN_STANDARD_TIME: {
      DYNAMICS_ID: 55,
      IANA_IDS: [
        'America/Anguilla',
        'America/Antigua',
        'America/Aruba',
        'America/Barbados',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Curacao',
        'America/Dominica',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guyana',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lower_Princes',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Montserrat',
        'America/Port_of_Spain',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Santo_Domingo',
        'America/St_Barthelemy',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Tortola',
        'Etc/GMT+4',
      ],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'SA Western Standard Time',
    },
    SAINT_PIERRE_STANDARD_TIME: {
      DYNAMICS_ID: 72,
      IANA_IDS: ['America/Miquelon'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Saint Pierre Standard Time',
    },
    SAKHALIN_STANDARD_TIME: {
      DYNAMICS_ID: 278,
      IANA_IDS: ['Asia/Sakhalin'],
      UTC_OFFSET: 660,
      WINDOWS_ID: 'Sakhalin Standard Time',
    },
    SAMOA_STANDARD_TIME: {
      DYNAMICS_ID: 1,
      IANA_IDS: ['Pacific/Apia'],
      UTC_OFFSET: 780,
      WINDOWS_ID: 'Samoa Standard Time',
    },
    SE_ASIA_STANDARD_TIME: {
      DYNAMICS_ID: 205,
      IANA_IDS: [
        'Antarctica/Davis',
        'Asia/Bangkok',
        'Asia/Jakarta',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Saigon',
        'Asia/Vientiane',
        'Etc/GMT-7',
        'Indian/Christmas',
      ],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'SE Asia Standard Time',
    },
    SINGAPORE_STANDARD_TIME: {
      DYNAMICS_ID: 215,
      IANA_IDS: [
        'Asia/Brunei',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Singapore',
        'Etc/GMT-8',
      ],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'Singapore Standard Time',
    },
    SOUTH_AFRICA_STANDARD_TIME: {
      DYNAMICS_ID: 140,
      IANA_IDS: [
        'Africa/Blantyre',
        'Africa/Bujumbura',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Kigali',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Etc/GMT-2',
      ],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'South Africa Standard Time',
    },
    SRI_LANKA_STANDARD_TIME: {
      DYNAMICS_ID: 200,
      IANA_IDS: ['Asia/Colombo'],
      UTC_OFFSET: 330,
      WINDOWS_ID: 'Sri Lanka Standard Time',
    },
    SYRIA_STANDARD_TIME: {
      DYNAMICS_ID: 133,
      IANA_IDS: ['Asia/Damascus'],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'Syria Standard Time',
    },
    TAIPEI_STANDARD_TIME: {
      DYNAMICS_ID: 220,
      IANA_IDS: ['Asia/Taipei'],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'Taipei Standard Time',
    },
    TASMANIA_STANDARD_TIME: {
      DYNAMICS_ID: 265,
      IANA_IDS: ['Australia/Currie', 'Australia/Hobart'],
      UTC_OFFSET: 600,
      WINDOWS_ID: 'Tasmania Standard Time',
    },
    TOCANTINS_STANDARD_TIME: {
      DYNAMICS_ID: 77,
      IANA_IDS: ['America/Araguaina'],
      UTC_OFFSET: -180,
      WINDOWS_ID: 'Tocantins Standard Time',
    },
    TOKYO_STANDARD_TIME: {
      DYNAMICS_ID: 235,
      IANA_IDS: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
      UTC_OFFSET: 540,
      WINDOWS_ID: 'Tokyo Standard Time',
    },
    TOMSK_STANDARD_TIME: {
      DYNAMICS_ID: 211,
      IANA_IDS: ['Asia/Tomsk'],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'Tomsk Standard Time',
    },
    TONGA_STANDARD_TIME: {
      DYNAMICS_ID: 300,
      IANA_IDS: ['Pacific/Tongatapu'],
      UTC_OFFSET: 780,
      WINDOWS_ID: 'Tonga Standard Time',
    },
    TRANSBAIKAL_STANDARD_TIME: {
      DYNAMICS_ID: 241,
      IANA_IDS: ['Asia/Chita'],
      UTC_OFFSET: 540,
      WINDOWS_ID: 'Transbaikal Standard Time',
    },
    TURKEY_STANDARD_TIME: {
      DYNAMICS_ID: 134,
      IANA_IDS: ['Europe/Istanbul'],
      UTC_OFFSET: 180,
      WINDOWS_ID: 'Turkey Standard Time',
    },
    TURKS_AND_CAICOS_STANDARD_TIME: {
      DYNAMICS_ID: 51,
      IANA_IDS: ['America/Grand_Turk'],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Turks And Caicos Standard Time',
    },
    ULAANBAATAR_STANDARD_TIME: {
      DYNAMICS_ID: 228,
      IANA_IDS: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'Ulaanbaatar Standard Time',
    },
    US_EASTERN_STANDARD_TIME: {
      DYNAMICS_ID: 40,
      IANA_IDS: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
      UTC_OFFSET: -300,
      WINDOWS_ID: 'US Eastern Standard Time',
    },
    US_MOUNTAIN_STANDARD_TIME: {
      DYNAMICS_ID: 15,
      IANA_IDS: [
        'America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Hermosillo',
        'America/Phoenix',
        'Etc/GMT+7',
      ],
      UTC_OFFSET: -420,
      WINDOWS_ID: 'US Mountain Standard Time',
    },
    UTC: {
      DYNAMICS_ID: 92,
      IANA_IDS: ['America/Danmarkshavn', 'Etc/GMT', 'Etc/UTC'],
      UTC_OFFSET: 0,
      WINDOWS_ID: 'UTC',
    },
    UTC_02: {
      DYNAMICS_ID: 76,
      IANA_IDS: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
      UTC_OFFSET: -120,
      WINDOWS_ID: 'UTC-02',
    },
    UTC_08: {
      DYNAMICS_ID: 11,
      IANA_IDS: ['Etc/GMT+8', 'Pacific/Pitcairn'],
      UTC_OFFSET: -480,
      WINDOWS_ID: 'UTC-08',
    },
    UTC_09: {
      DYNAMICS_ID: 9,
      IANA_IDS: ['Etc/GMT+9', 'Pacific/Gambier'],
      UTC_OFFSET: -540,
      WINDOWS_ID: 'UTC-09',
    },
    UTC_11: {
      DYNAMICS_ID: 6,
      IANA_IDS: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
      UTC_OFFSET: -660,
      WINDOWS_ID: 'UTC-11',
    },
    UTC_12: {
      DYNAMICS_ID: 284,
      IANA_IDS: [
        'Etc/GMT-12',
        'Pacific/Funafuti',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Nauru',
        'Pacific/Tarawa',
        'Pacific/Wake',
        'Pacific/Wallis',
      ],
      UTC_OFFSET: 720,
      WINDOWS_ID: 'UTC+12',
    },
    VENEZUELA_STANDARD_TIME: {
      DYNAMICS_ID: 47,
      IANA_IDS: ['America/Caracas'],
      UTC_OFFSET: -240,
      WINDOWS_ID: 'Venezuela Standard Time',
    },
    VLADIVOSTOK_STANDARD_TIME: {
      DYNAMICS_ID: 270,
      IANA_IDS: ['Asia/Ust-Nera', 'Asia/Vladivostok'],
      UTC_OFFSET: 600,
      WINDOWS_ID: 'Vladivostok Standard Time',
    },
    W_AUSTRALIA_STANDARD_TIME: {
      DYNAMICS_ID: 225,
      IANA_IDS: ['Antarctica/Casey', 'Australia/Perth'],
      UTC_OFFSET: 480,
      WINDOWS_ID: 'W. Australia Standard Time',
    },
    W_CENTRAL_AFRICA_STANDARD_TIME: {
      DYNAMICS_ID: 113,
      IANA_IDS: [
        'Africa/Algiers',
        'Africa/Bangui',
        'Africa/Brazzaville',
        'Africa/Douala',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Luanda',
        'Africa/Malabo',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Porto-Novo',
        'Africa/Tunis',
        'Etc/GMT-1',
      ],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'W. Central Africa Standard Time',
    },
    W_EUROPE_STANDARD_TIME: {
      DYNAMICS_ID: 110,
      IANA_IDS: [
        'Arctic/Longyearbyen',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Berlin',
        'Europe/Busingen',
        'Europe/Gibraltar',
        'Europe/Luxembourg',
        'Europe/Malta',
        'Europe/Monaco',
        'Europe/Oslo',
        'Europe/Rome',
        'Europe/San_Marino',
        'Europe/Stockholm',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Zurich',
      ],
      UTC_OFFSET: 60,
      WINDOWS_ID: 'W. Europe Standard Time',
    },
    W_MONGOLIA_STANDARD_TIME: {
      DYNAMICS_ID: 209,
      IANA_IDS: ['Asia/Hovd'],
      UTC_OFFSET: 420,
      WINDOWS_ID: 'W. Mongolia Standard Time',
    },
    WEST_ASIA_STANDARD_TIME: {
      DYNAMICS_ID: 185,
      IANA_IDS: [
        'Antarctica/Mawson',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Atyrau',
        'Asia/Dushanbe',
        'Asia/Oral',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'Etc/GMT-5',
        'Indian/Kerguelen',
        'Indian/Maldives',
      ],
      UTC_OFFSET: 300,
      WINDOWS_ID: 'West Asia Standard Time',
    },
    WEST_BANK_GAZA_STANDARD_TIME: {
      DYNAMICS_ID: 142,
      IANA_IDS: [],
      UTC_OFFSET: 120,
      WINDOWS_ID: 'West Bank Gaza Standard Time',
    },
    WEST_PACIFIC_STANDARD_TIME: {
      DYNAMICS_ID: 275,
      IANA_IDS: [
        'Antarctica/DumontDUrville',
        'Etc/GMT-10',
        'Pacific/Guam',
        'Pacific/Port_Moresby',
        'Pacific/Saipan',
        'Pacific/Truk',
      ],
      UTC_OFFSET: 600,
      WINDOWS_ID: 'West Pacific Standard Time',
    },
    YAKUTSK_STANDARD_TIME: {
      DYNAMICS_ID: 240,
      IANA_IDS: ['Asia/Khandyga', 'Asia/Yakutsk'],
      UTC_OFFSET: 540,
      WINDOWS_ID: 'Yakutsk Standard Time',
    },
  });
})();
/* eslint-enable max-lines */
