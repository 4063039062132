(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common
   * @description module that defines widgets that can be used multiple places
   */
  angular.module('app.widgets.common', [
    'app.core.user',
    'app.widgets.scorecard',
    'binky.core.dom-utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.common.detail-section',
    'pascalprecht.translate',
  ]);
})();
