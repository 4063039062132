import {CONTRACT_BILLING_FREQUENCY, Product} from '@admin-tribe/binky';
import {BillingFrequency, LicensePricing} from '@pandora/react-license-pricing';
import PropTypes from 'prop-types';
import React from 'react';

const ProductLicensePricingWrapper = ({billingFrequency, product, showWithTax = true}) => {
  let priceBillingFrequency;

  switch (billingFrequency) {
    case CONTRACT_BILLING_FREQUENCY.MONTHLY:
      priceBillingFrequency = BillingFrequency.PerMonth;
      break;
    case CONTRACT_BILLING_FREQUENCY.ANNUAL:
      priceBillingFrequency = BillingFrequency.PerYear;
      break;
    default:
      priceBillingFrequency = BillingFrequency.None;
  }

  const props = {
    data: {product},
    options: {billingFrequency: priceBillingFrequency, showWithTax},
  };

  return <LicensePricing {...props} />;
};

ProductLicensePricingWrapper.propTypes = {
  /**
   * The billing frequency of current product i.e. monthly or annually
   */
  billingFrequency: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The Product enriched with pricing data to display owned licenses and pricing points
   */
  product: PropTypes.instanceOf(Product).isRequired,
  /**
   * Determine if the pricing data should show the value including tax. Defaults to true.
   */
  showWithTax: PropTypes.bool,
};

export default ProductLicensePricingWrapper;
