import {Flex} from '@adobe/react-spectrum';
import React from 'react';

import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import EduSyncCard from 'features/settings/components/edu-sync-card/EduSyncCard';

const EduRosterSyncsList = () => {
  const {eduRosterSyncs} = useRosterSyncContext();

  return (
    <Flex>
      {eduRosterSyncs.data.map((sync) => (
        <EduSyncCard key={sync.syncId} syncDetails={sync} />
      ))}
    </Flex>
  );
};

export default EduRosterSyncsList;
