(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name ExpertSessionList
   * @description Model for an open or closed expert session list.
   */
  angular.module('app.core.support').factory('ExpertSessionList', ExpertSessionListModel);

  /* @ngInject */
  function ExpertSessionListModel(
    $rootScope,
    ExpertSession,
    MESSAGE,
    MODEL,
    modelCache,
    onesieSrc2,
    SUPPORT_TICKET_QUERY_PARAM,
    SupportTicketList
  ) {
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;
    class ExpertSessionList extends SupportTicketList {
      /**
       * @description Constructor for ExpertSessionList model.
       * @class
       *
       * @param {Object} options - options object, as described below
       * @param {String} [options.adminId] - Optional. If specified, the list will be
       *   filtered to include only tickets created by this admin.
       * @param {SUPPORT_TICKET_QUERY_PARAM} options.status - either ACTIVE to fetch open sessions,
       *   or INACTIVE to fetch closed ones.
       */
      constructor(options) {
        super({
          adminId: options.adminId,
          cacheType:
            options.status === SUPPORT_TICKET_QUERY_PARAM.ACTIVE
              ? MODEL.EXPERTSESSIONLIST.ACTIVE
              : MODEL.EXPERTSESSIONLIST.INACTIVE,
          ItemClassRef: ExpertSession,
          queryParams: {
            recordType: SUPPORT_CONSTANTS.LIST_ENTITY_NAME.EXPERTS,
            status: options.status,
          },
          updateEvent: MESSAGE.UPDATE.EXPERTSESSIONS.LIST,
        });
      }

      /**
       * @description Fetches the list from cache if availble, else from the backend.
       *
       * @param {Object} [options] - options for the list (per constructor documentation)
       * @returns {ExpertSessionList} the fetched expert session list
       */
      static get(options) {
        return SupportTicketList.get(ExpertSessionList, options);
      }

      /**
       * @description Fetches the list from the backend as text/csv.
       *
       * @returns {String} the fetched expert session list csv string data
       */
      static getCsv() {
        return SupportTicketList.getCsv(SUPPORT_CONSTANTS.LIST_ENTITY_NAME.EXPERTS);
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.CREATE.EXPERTSESSION, () => {
      modelCache.removeAll(MODEL.EXPERTSESSIONLIST.ACTIVE);
    });

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.UPDATE.EXPERTSESSION, () => {
      modelCache.removeAll(MODEL.EXPERTSESSIONLIST.ACTIVE);
    });

    modelCache.register(MODEL.EXPERTSESSIONLIST.ACTIVE, 2); // 1 for the entire list, 1 for the list filtered by admin
    modelCache.register(MODEL.EXPERTSESSIONLIST.INACTIVE, 1);

    return ExpertSessionList;
  }
})();
