(function () {
  /**
   * @ngdoc module
   * @name app.core.product.configurations
   * @description Module which defines product configuration related models
   */
  angular.module('app.core.product.configurations', [
    'app.core.message',
    'app.core.model-cache',
    'app.core.organizations.organization-manager',
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.product',
    'binky.core.product.configurations',
    'binky.core.product.fulfillable-item-list',
    'binky.widgets.products.product-name',
    'pascalprecht.translate',
  ]);
})();
