import {ProductList} from '@admin-tribe/binky';
import {useAsyncModel} from '@admin-tribe/binky-ui';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';

import {filterContractList, splitContracts} from '../overviewUtils';

/**
 * @description Hook used to obtain contract list, including inactive contracts
 * @returns {Object} State object with the following:
 *   {Object} state.error -- The error during loading
 *   {Boolean} state.isLoading -- Whether data is still being loaded
 *   {Object} state.model An object that contains list of active and inactive contracts.
 *   {Contract[]} state.model.activeContracts -- List of active contracts
 *   {Contract[]} state.model.inactiveContracts -- List of inactive contracts
 */
const useOverviewData = () => {
  const orgId = rootStore.organizationStore.activeOrgId;

  const fetchOverviewData = useCallback(async () => {
    const contractList = rootStore.organizationStore.contractListIncludingInactive;

    const contracts = splitContracts(filterContractList(contractList));

    let products;

    // if there is no inactive contracts, do not call to get inactive products
    if (contracts.inactiveContracts.length > 0) {
      const productList = await ProductList.get({
        includeInactive: true,
        isCacheable: true, // since these are inactive contracts, this list doesn't need to update
        orgId,
      });
      products = productList.items;
    } else {
      products = rootStore.organizationStore.productList.items;
    }

    return {contracts, products};
  }, [orgId]);

  const {model: overviewData, isLoading, error} = useAsyncModel({loadFn: fetchOverviewData});
  return {error, isLoading, overviewData};
};

export default useOverviewData;
