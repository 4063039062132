(function () {
  angular.module('app.core.directories', [
    'app.core.api.directory-sync',
    'app.core.api.jil',
    'app.core.auth',
    'app.core.directories.azure',
    'app.core.location-from-page-load',
    'app.core.message',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'binky.core.api.kms',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.common.model.cache',
    'binky.core.directories',
    'binky.core.domains',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.product',
    'binky.core.trusts',
    'binky.widgets.common.member.status',
  ]);
})();
