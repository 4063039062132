import {CONTRACT_BILLING_FREQUENCY} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

/* A Price component wrapper to enable a visible price deduction */
const SignedPrice = ({isNegative, ...props}) => {
  const intl = useIntl();
  return intl.formatMessage(
    {
      id: 'account.selfCancel.components.signedPrice.amount',
    },
    {
      isNegative,
      price: () => <SafePrice {...props} />,
    }
  );
};

SignedPrice.propTypes = {
  /**
   * The currency object to format the price display
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }),
  /**
   * Determines if to append signify the price is being deducted
   */
  isNegative: PropTypes.bool,
  /**
   * CSS classes as defined by Pandora, used to make changes to the default styling
   * for reference see https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price#supported-css-classes
   */
  // eslint-disable-next-line react/forbid-prop-types -- CSS classes defined in pandora
  overrideStyles: PropTypes.object,
  /**
   * The price to display
   */
  price: PropTypes.number,
  /**
   * The recurrence term to append to the price (ex. /mo)
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)).isRequired,
};
export default SignedPrice;
