import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Flex, Text, TextArea, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useTrustRequests from 'features/settings/hooks/api/useTrustRequests';

const RejectAccessRequestsConfirmationModal = ({accessRequests, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {rejectTrustRequests} = useTrustRequests();

  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const handleReasonValidation = (rejectionReason) => {
    setReason(rejectionReason);
    if (rejectionReason) {
      setIsValid(true);
      return;
    }

    setIsValid(false);
  };

  const handleRejectAccessRequest = async () => {
    try {
      setIsLoading(true);

      const {failedToReject} = await rejectTrustRequests(accessRequests, reason);

      if (failedToReject?.length > 0 && accessRequests.length > 0) {
        showErrorToast(
          intl.formatMessage(
            {
              id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.toast.error.partial',
            },
            {count: accessRequests.length, failedCount: failedToReject.length}
          )
        );
      } else {
        showSuccessToast(
          intl.formatMessage(
            {
              id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.toast.success',
            },
            {count: accessRequests.length}
          )
        );
      }

      dialog.dismiss();
      onConfirm();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.toast.error',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.button.cancel',
      })}
      isLoading={isLoading}
      // Disable the confirmation button when the rejection reason is missing
      isPrimaryActionDisabled={!isValid}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRejectAccessRequest}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.button.reject',
      })}
      title={intl.formatMessage(
        {id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.title'},
        {
          count: accessRequests.length,
        }
      )}
      variant="confirmation"
    >
      <Text>
        <FormattedMessage id="settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.description" />
      </Text>
      <Flex direction="column" marginTop="size-200">
        <TextArea
          errorMessage={intl.formatMessage({
            id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.textArea.error',
          })}
          isRequired
          label={intl.formatMessage({
            id: 'settings.accessRequestsTable.rejectAccessRequestsConfirmationModal.rejectReasonTextAreaLabel',
          })}
          marginTop="size-200"
          onChange={handleReasonValidation}
          validationState={isValid || reason === null ? 'valid' : 'invalid'}
          width="100%"
        />
      </Flex>
    </ModalAlertDialog>
  );
};

RejectAccessRequestsConfirmationModal.propTypes = {
  accessRequests: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The ID of the directory
       */
      directoryId: PropTypes.string,
      /**
       * The ID of the Owning Org
       */
      owningOrgId: PropTypes.string,
      /**
       * The name of the Owning Org
       */
      owningOrgName: PropTypes.string,
    })
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RejectAccessRequestsConfirmationModal;
