/* eslint-disable max-lines */
// Disabled max-lines since I don't see a clear way to break up the modal further
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains2:addDomainsModal
   *
   * @description renders a modal-based wizard that lets the user add domains to their org. The
   *   wizard contains different steps depending on the user's input:
   *
   *   1. Enter Domains
   *     Always present in the wizard. Contains a form where the user enters one or more domains.
   *     Upon clicking Next, the JIL POST /domains:preview API is called to preview whether the
   *     domains can be added to the org.
   *
   *   2. Review Errors
   *     Shown if the user entered any domains which cannot be added due to some error, for example
   *     the domain is already added to their org.
   *
   *   3. Request Access
   *     Shown if the user entered any domains which have been added and activated by other orgs.
   *     The user is prompted to request access to the directories which the domains are linked to.
   *
   *   4. Add Domains
   *     Shown if the user entered domains which can be added to the org. Clicking the CTA on this
   *     step calls JIL POST /domains API to add the domains. In the case of success, the modal
   *     closes. In the case of errors, a page banner is shown within the modal and the user can
   *     retry adding just the failed domains.
   *
   * @binding {DirectoryList} directoryList - the model representing the org's directories
   * @binding {DomainList2} domainList - the model representing the org's domains
   */
  angular.module('app.widgets.domains2').component('appAddDomainsModal', {
    bindings: {
      directoryList: '<',
      domainList: '<',
    },
    controller,
    templateUrl: 'app/widgets/domains2/add-domains-modal/add-domains-modal.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    $q,
    $scope,
    $translate,
    _,
    ADD_DOMAINS_MODAL_ID,
    ADD_DOMAINS_WIZARD_ID,
    DisplayMessage,
    Domain,
    JIL_ADD_DOMAINS_ERROR,
    panelManager,
    toastManager,
    WIZARD_CHANGE,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    // steps is an array of each step currently present in the wizard, in chronological order
    let currentStep, domainsForm, shouldHideBackButton, steps;

    const vm = this;
    $onInit();

    /////////////////

    function $onInit() {
      // The same component instance is re-used every time the add domains modal is shown. Reset
      // all state here to prevent state bleeding over from prior add domains flows.
      _.assign(vm, {
        accessRequests: [],
        ADD_DOMAINS_MODAL_ID,
        ADD_DOMAINS_WIZARD_ID,
        doesWizardIncludeStep,
        domainPreviewErrors: [],
        domainPreviews: [],
        isBusy: false,
        isOpen: true,
        onCancel,
        onDomainsFormChange,
        onOpen,
        onRemoveAccessRequest,
        onRemoveDomain,
        STEP: {}, // all possible steps which may appear in the wizard, e.g. vm.STEP.ENTER_DOMAINS
        stepTitles: [],
        waitPromise: $q.resolve(),
      });
      vm.domainList.resetAddedItems();

      currentStep = null;
      domainsForm = {domainNames: [], isValid: false};
      shouldHideBackButton = false;
      steps = [];

      initStepDescriptors();
      setSteps([vm.STEP.ENTER_DOMAINS, vm.STEP.ADD_DOMAINS]);
      setCurrentStep(vm.STEP.ENTER_DOMAINS);
    }

    function doesWizardIncludeStep(step) {
      return _.includes(steps, step);
    }

    function onCancel() {
      closeModal();
    }

    function onDomainsFormChange($event) {
      hidePageBanner();
      domainsForm = _.pick($event, ['domainNames', 'isValid']);
    }

    function onOpen() {
      $onInit();
    }

    function onRemoveAccessRequest($event) {
      _.remove(vm.accessRequests, $event.accessRequest);
    }

    function onRemoveDomain($event) {
      _.remove(vm.domainPreviews, $event.domain);
      vm.domainList.remove([$event.domain]);
    }

    //////////////

    function addDomains() {
      callBackend({makeRequest: () => vm.domainList.save(), onSuccess});

      function onSuccess(addedDomains) {
        vm.domainPreviews = _.reject(vm.domainPreviews, domainWasAdded);
        if (vm.domainPreviews.length > 0) {
          showPageBanner(
            $translate.instant('widgets.domains2.addDomainsModal.pageBanner.someDomainsNotAdded')
          );
        } else {
          closeModal();
        }
        if (addedDomains.length > 0) {
          onChangesSaved();
          showDomainsAddedToast(addedDomains.length);
        }

        function domainWasAdded(domain) {
          return _.some(addedDomains, ['domainName', domain.domainName]);
        }
      }
    }

    function callBackend(options) {
      hidePageBanner();
      vm.isBusy = true;

      vm.waitPromise = options
        .makeRequest()
        .then(options.onSuccess, () => showPageBanner())
        .finally(() => {
          vm.isBusy = false;
        });
    }

    function closeModal() {
      panelManager.close(ADD_DOMAINS_MODAL_ID);
      vm.domainList.resetAddedItems();
      // Hack to ensure the wizard's subcomponents will be recreated from scratch next time the
      // modal is opened
      vm.isOpen = false;
    }

    function previewDomains() {
      // If the user entered domain names already in the domainList, domainList.add() will ignore
      // them. Instead, store them separately and later map them to a preview error on the client.
      const [domainNamesAlreadyInModel, domainNamesToAdd] = _.partition(
        domainsForm.domainNames,
        vm.domainList.containsDomainName.bind(vm.domainList)
      );

      const domainsToAdd = _.map(domainNamesToAdd, (domainName) => new Domain({domainName}));
      vm.domainList.resetAddedItems();
      vm.domainList.add(domainsToAdd);

      callBackend({makeRequest: () => vm.domainList.previewSave(), onSuccess});

      function onSuccess(addDomainsPreviewResponse) {
        const previewErrors = addDomainsPreviewResponse.getErrors();
        setPreviewResultsOnVm(previewErrors, domainNamesAlreadyInModel);
        vm.domainPreviews = addDomainsPreviewResponse.getSuccessResponses();

        const domainsWhichCantBeAdded = _.map(previewErrors, (error) => ({domainName: error.id}));
        vm.domainList.remove(domainsWhichCantBeAdded);

        const updatedSteps = [vm.STEP.ENTER_DOMAINS];
        if (vm.domainPreviewErrors.length > 0) {
          updatedSteps.push(vm.STEP.REVIEW_ERRORS);
        }
        if (vm.accessRequests.length > 0) {
          updatedSteps.push(vm.STEP.REQUEST_ACCESS);
        }
        if (vm.domainPreviews.length > 0) {
          updatedSteps.push(vm.STEP.ADD_DOMAINS);
        }
        return setSteps(updatedSteps).then(goToNextStep);
      }
    }

    function getCurrentStepButtonLabels() {
      return _(currentStep)
        .pick('backLabelKey', 'ctaLabelKey')
        .mapValues((val, labelName) => {
          // Button labels can be empty to tell the view that the button should be hidden
          const btnLabel = _.result(currentStep, labelName);
          return btnLabel
            ? $translate.instant(`widgets.domains2.addDomainsModal.buttons.${btnLabel}`)
            : '';
        })
        .value();
    }

    function getCurrentStepIndex() {
      return _.indexOf(steps, currentStep);
    }

    function setPreviewResultsOnVm(previewErrors, domainNamesAlreadyInModel) {
      const [existingDomainErrors, errorsToReview] = _.partition(previewErrors, [
        'response.errorCode',
        JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE,
      ]);
      const accessRequests = _.map(existingDomainErrors, (error) => ({
        directoryId: _.get(error, 'response.errorContext.directoryId'),
        domainName: error.id,
      }));
      const syntheticErrors = _.map(domainNamesAlreadyInModel, (domainName) => ({
        id: domainName,
        response: {errorCode: JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG},
      }));
      const domainPreviewErrors = errorsToReview.concat(syntheticErrors);
      _.assign(vm, {accessRequests, domainPreviewErrors});
    }

    function goToNextStep() {
      setCurrentStepByIndex(getCurrentStepIndex() + 1);
      $scope.$broadcast(WIZARD_NEXT, ADD_DOMAINS_WIZARD_ID);
    }

    function goToPreviousStep() {
      $scope.$broadcast(WIZARD_PREVIOUS, ADD_DOMAINS_WIZARD_ID);
      setCurrentStepByIndex(getCurrentStepIndex() - 1);

      hidePageBanner();

      if (currentStep === vm.STEP.ENTER_DOMAINS) {
        setSteps([vm.STEP.ENTER_DOMAINS, vm.STEP.ADD_DOMAINS]);
      }
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    /**
     * @description defines a step which can be displayed in the wizard. The titleKey option must
     *   be specified. The other options allow for customizing the step's appearance as needed; for
     *   example, its Back and call to action buttons.
     *
     * @param {String} name - the name of the step; used to access the step via vm.STEP
     * @param {Object} options - options which define this step's appearance and behaviour
     * @param {Function} options.backHandler - called when the step's Back button is clicked
     * @param {String|Function} options.backLabelKey - the translate key suffix for the step's back
     *   button, specified either as a string or a function which returns a string.
     * @param {Function} options.ctaDisabled - function which returns true if the step's call to
     *   action button should be disabled
     * @param {Function} options.ctaHandler - called when the step's CTA button is clicked
     * @param {String|Function} options.ctaLabelKey - like options.backLabelKey, for the CTA button
     * @param {String} options.titleKey - the translate key suffix for this step in the wizard's
     *   step list
     */
    function initStepDescriptor(name, options) {
      const stepDefaults = {
        backHandler: goToPreviousStep,
        backLabelKey: () => {
          if (shouldHideBackButton) {
            return '';
          }
          return 'back';
        },
        ctaDisabled: _.stubFalse,
        ctaHandler: goToNextStep,
        ctaLabelKey: () => {
          if (onLastStep()) {
            return '';
          }
          return 'next';
        },
      };
      vm.STEP[name] = _.defaults(options, stepDefaults);
    }

    function initStepDescriptors() {
      initStepDescriptor('ENTER_DOMAINS', {
        backLabelKey: '',
        ctaDisabled: () => _.isEmpty(domainsForm.domainNames) || !domainsForm.isValid,
        ctaHandler: previewDomains,
        titleKey: 'enterDomains',
      });
      initStepDescriptor('REVIEW_ERRORS', {
        titleKey: 'reviewErrors',
      });
      initStepDescriptor('REQUEST_ACCESS', {
        ctaHandler: requestAccess,
        ctaLabelKey: 'requestAccess',
        titleKey: 'requestAccess',
      });
      initStepDescriptor('ADD_DOMAINS', {
        ctaHandler: addDomains,
        ctaLabelKey: 'addDomains',
        titleKey: 'addDomains',
      });
    }

    function onChangesSaved() {
      shouldHideBackButton = true;
      updateStepButtons();
    }

    function onLastStep() {
      return currentStep === _.last(steps);
    }

    function requestAccess() {
      const directories = _(vm.accessRequests)
        .uniqBy('directoryId')
        .map((accessRequest) => ({id: accessRequest.directoryId}))
        .value();

      callBackend({makeRequest: () => vm.directoryList.requestAccess(directories), onSuccess});

      function onSuccess(requestedDirectories) {
        const [completedItems, failedItems] = _.partition(vm.accessRequests, requestWasSent);
        vm.accessRequests = failedItems;

        if (vm.accessRequests.length > 0) {
          const errorMsg = $translate.instant(
            'widgets.domains2.addDomainsModal.pageBanner.someAccessRequestsNotSent'
          );
          showPageBanner(errorMsg);
        } else if (onLastStep()) {
          closeModal();
        } else {
          goToNextStep();
        }

        if (completedItems.length > 0) {
          onChangesSaved();
          showRequestedAccessToast({
            directoryCount: requestedDirectories.length,
            domainCount: completedItems.length,
          });
        }

        function requestWasSent(accessRequest) {
          return _.some(requestedDirectories, ['directoryId', accessRequest.directoryId]);
        }
      }
    }

    function setCurrentStepByIndex(stepIdx) {
      setCurrentStep(steps[stepIdx]);
    }

    function setCurrentStep(step) {
      currentStep = step;
      updateStepButtons();
    }

    function setSteps(newSteps) {
      // A promise which will resolve after the steps have been updated
      const wizardUpdatedPromise = $q((resolve) => {
        const removeListener = $scope.$on(WIZARD_CHANGE, (event, options) => {
          if (options.wizardId === ADD_DOMAINS_WIZARD_ID) {
            removeListener();
            resolve();
          }
        });
      });
      steps = newSteps;
      vm.stepTitles = _.map(steps, (step) =>
        $translate.instant(`widgets.domains2.addDomainsModal.stepTitles.${step.titleKey}`)
      );
      return wizardUpdatedPromise;
    }

    function showRequestedAccessToast(options) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.domains2.addDomainsModal.toasts.requestedAccess',
          options,
          'messageformat'
        )
      );
    }

    function showDomainsAddedToast(count) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.domains2.addDomainsModal.toasts.domainsAdded',
          {count},
          'messageformat'
        )
      );
    }

    function showPageBanner(body) {
      vm.displayMessage = new DisplayMessage({
        body,
      });
    }

    function updateStepButtons() {
      _.assign(
        vm,
        getCurrentStepButtonLabels(),
        _.pick(currentStep, 'backHandler', 'ctaDisabled', 'ctaHandler')
      );
    }
  }
})();
/* eslint-enable max-lines */
