import {Cache, jilDirectories, log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import {DIRECTORY_STATUSES} from 'common/entities/DirectoryEntity';
import {getAuthSource} from 'features/settings/api/ims-federated';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import {getCertificates} from 'features/settings/hooks/api/useSamlCertificates';

import {getCacheKey} from './utils/hooksApiUtils';

const DIRECTORY_CACHE_KEY = 'directory';

const directoryCache = new Cache();

const getDirectoryCacheKey = ({directoryId, orgId}) =>
  getCacheKey(DIRECTORY_CACHE_KEY, orgId, directoryId);

/**
 * Get directory details for the provided directory id and org id.
 *
 * @returns {Promise<*>}
 */
const getDirectory = async ({directoryId, orgId}) => {
  try {
    const cacheKey = getDirectoryCacheKey({directoryId, orgId});
    const cachedPromise = directoryCache.get(cacheKey);

    let authSourceResponse, directoryResponse;

    if (cachedPromise) {
      [directoryResponse, authSourceResponse] = await cachedPromise;
    } else {
      const directoryPromise = jilDirectories.getDirectoryDetails({
        directoryId,
        orgId,
      });

      const authSourcePromise = getAuthSource({
        authSourceId: directoryId,
        orgId,
      });

      const requestPromise = Promise.all([directoryPromise, authSourcePromise]);

      // Cache the promise immediately so that subsequent calls will use the same promise
      directoryCache.put(cacheKey, requestPromise);

      [directoryResponse, authSourceResponse] = await requestPromise;
    }

    const directory = {
      ...directoryResponse.data,
      defaultIdp: authSourceResponse.data.defaultIdp,
      idps: authSourceResponse.data.idps,
    };

    return directory;
  } catch (error) {
    log.error(`[ID][JIL] Error getting the directory details: ${orgId} ${directoryId}`, error);

    throw error;
  }
};

/**
 * Get directory details for the provided directory id and org id - with certificates
 *
 * @returns {Promise<*>}
 */
const getDirectoryWithCertificates = async ({directoryId, orgId}) => {
  const directory = await getDirectory({directoryId, orgId});

  // load and annotate with certificates if it's active and has a default saml idp
  if (directory.status === DIRECTORY_STATUSES.ACTIVE) {
    const defaultSamlIdp = directory.idps.find((idp) => idp.id === directory.defaultIdp && idp);

    if (defaultSamlIdp?.federationType === IDP_TYPES.SAML) {
      try {
        const certificates = await getCertificates({
          directoryId: directory.id,
          idpId: directory.defaultIdp,
        });

        directory.defaultSamlIdp = defaultSamlIdp;
        directory.certificates = certificates;
      } catch (error) {
        log.error(`[ID][JIL] Error getting the SAML certificates: ${orgId} ${directoryId}`, error);

        throw error;
      }
    }
  }

  return directory;
};

/**
 * A hook that helps with making calls to the JIL API
 * Handles logging errors and caching.
 */
const useDirectory = () => {
  const getDirectoryCallback = useCallback(getDirectory, []);
  const getDirectoryWithCertificatesCallback = useCallback(getDirectoryWithCertificates, []);

  const clearDirectoryCache = useCallback(() => directoryCache.clearAll(), []);

  return {
    clearDirectoryCache,
    getDirectory: getDirectoryCallback,
    getDirectoryWithCertificates: getDirectoryWithCertificatesCallback,
  };
};

export {getDirectory, getDirectoryWithCertificates};
export default useDirectory;
