import {GoUrl, Page, PageContent, PageDescription, PageHeader} from '@admin-tribe/binky-ui';
import React from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';

import AssetSettingsTabs from './AssetSettingsTabs';

/**
 * The Asset Settings Page.
 */
const AssetSettingsPage = () => {
  const intl = useIntl();

  useDocumentTitle({key: 'settings.assetSettings.title'});

  return (
    <Page data-testid="individual-user-folders-page">
      <PageHeader title={intl.formatMessage({id: 'settings.assetSettings.title'})} />
      <PageDescription>
        <GoUrl name="asset_settings">
          {intl.formatMessage({id: 'settings.assetSettings.learnMore'})}
        </GoUrl>
      </PageDescription>
      <PageContent>
        <AssetSettingsTabs />
      </PageContent>
    </Page>
  );
};

export default AssetSettingsPage;
