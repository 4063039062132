/**
 * @description Method to retrieve a product group in an array of mixed products
 *   and product groups by its fulfillable item id.
 * @param {<Product|ProductGroup>[]} items - list of products or product groups
 *   in list
 * @param {String} id - fulfillable item id
 * @returns {ProductGroup|undefined} a fulfillable item ProductGroup if found,
 *   otherwise undefined
 */
function findFulfillableItem(items, id) {
  const flattenedItems = items.flatMap((item) => item.fulfillableItemList.items);
  return flattenedItems.find((item) => item.code === id);
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {findFulfillableItem};
