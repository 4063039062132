import {Contract, EVENT_ACTION, ProductList} from '@admin-tribe/binky';
import {GoUrl, ModalContent, PageBanner} from '@admin-tribe/binky-ui';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {isCCTProError} from '../SelfCancelUtils';
import {useProductsChangeContext} from '../components/products-change-context/ProductsChangeContext';
import SeatSelector from '../components/seat-selector/SeatSelector';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelFooterTotal from '../components/self-cancel-footer-total/SelfCancelFooterTotal';

import './SelectLicensesStep.pcss';

const DISPATCH_PRODUCTS_CHANGE_DELAY = 500;

/**
 * Self cancel workflow step component for the selection of licenses to cancel.
 */
const SelectLicensesStep = ({onClose, onContinue, defaultSelectedSeats, contract, productList}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();

  const [selectedSeats, setSelectedSeats] = useState(defaultSelectedSeats);
  const [allLicensesSelected, setAllLicensesSelected] = useState(false);

  const {dispatchProductsChange, error, isLoading} = useProductsChangeContext();

  const isCtaDisabled = useMemo(
    () => isLoading || !!error || Object.keys(selectedSeats).length === 0,
    [selectedSeats, error, isLoading]
  );

  const debouncedDispatchProductsChange = useMemo(
    () => debounce(dispatchProductsChange, DISPATCH_PRODUCTS_CHANGE_DELAY),
    [dispatchProductsChange]
  );

  // Update prices when selectedSeats changes
  useEffect(() => {
    if (Object.keys(selectedSeats).length > 0) {
      debouncedDispatchProductsChange({selectedSeats});
    }
  }, [selectedSeats, debouncedDispatchProductsChange]);

  // Show error when not isLoading, at least one seat selected and error code === 'CCTPRO_ERROR'
  const isCCTError = isCCTProError(error) && Object.keys(selectedSeats).length > 0;

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({
      clickType: EVENT_NAME.CONTINUE,
      eventAction: EVENT_ACTION.PROCEED,
    });
    onContinue?.(selectedSeats);
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({
      clickType: EVENT_NAME.CANCEL,
      eventAction: EVENT_ACTION.CLOSE,
    });
    onClose?.();
  };

  return (
    <SelfCancelBaseModal
      allLicensesSelected={allLicensesSelected}
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.buttons.continue'})}
      heading={intl.formatMessage({id: 'account.selfCancel.common.title'})}
      isCtaDisabled={isCtaDisabled}
      onCancel={onCancel}
      onCta={onCta}
      subheading={intl.formatMessage({id: 'account.selfCancel.selectLicenses.description'})}
    >
      <ModalContent>
        {isCCTError && (
          /* Need V2 Provider for PageBanner which uses V2 Alert */
          <SpectrumV2Provider>
            <PageBanner
              header={intl.formatMessage({
                id: 'account.selfCancel.selectLicenses.errorCCTPro.title',
              })}
              variant="error"
            >
              {intl.formatMessage(
                {id: 'account.selfCancel.selectLicenses.errorCCTPro.description'},
                {
                  b: (str) => <span styleName="bolded-text">{str}</span>,
                  goUrl: (str) => <GoUrl name="stockterms">{str}</GoUrl>,
                }
              )}
            </PageBanner>
          </SpectrumV2Provider>
        )}
        <SeatSelector
          aria-labelledby="self-cancel-modal-description" // SelfCancelBaseModal generated id for subheading
          contract={contract}
          isLoading={isLoading}
          onAllLicensesSelectedChange={setAllLicensesSelected}
          onSelectedSeatsChange={setSelectedSeats}
          productList={productList}
          selectedSeats={selectedSeats}
        />
      </ModalContent>
      <SelfCancelBaseFooter>
        <SelfCancelFooterTotal
          contract={contract}
          isErrorDisplayed
          productList={productList}
          selectedSeats={selectedSeats}
        />
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

SelectLicensesStep.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * The hash map of of initially selected licenses per product id.
   */
  defaultSelectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user wants to proceed with in the cancellation flow.
   * The callback will receive the current selected licenses as the argument, similarly to
   * `selectedLicenses` prop.
   */
  onContinue: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
};

export default SelectLicensesStep;
