(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc service/provider
   * @name supportAnyware
   * @description defines service to fetch support anyware api
   */
  angular.module('app.core.api.support-anyware').provider('supportAnyware', supportAnywareProvider);

  function supportAnywareProvider(_) {
    let apiKey, url;
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(supportAnywareServiceConfig, imsClientId) {
      url = `${_.get(supportAnywareServiceConfig, 'url')}`;
      apiKey = imsClientId;
    }

    /* @ngInject */
    function $get($resource, apiUtils, authentication, locale) {
      const headers = {
        Authorization: authentication.getAccessToken(),
        'X-Api-Key': apiKey,
        'X-Support-Locale': locale.getActiveLocaleCode(),
      };

      const service = {
        orgMetadataExpertSessions: orgMetadataExpertSessions(),
        orgMetadataProduct: orgMetadataProduct(),
        orgTicketAttachments: orgTicketAttachments(),
        orgTickets: orgTickets(),
      };

      return service;

      ///////////

      /* New Support APIs */

      function orgMetadataExpertSessions() {
        return $resource(
          `${url}/organizations/:orgId/metadata/expertsessions`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              headers,
              method: 'GET',
            },
          }
        );
      }

      function orgMetadataProduct() {
        return $resource(
          `${url}/organizations/:orgId/metadata/product/:fulfillableItemCode`,
          {
            fulfillableItemCode: '@fulfillableItemCode',
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              headers,
              method: 'GET',
            },
          }
        );
      }

      function orgTickets() {
        return $resource(
          `${url}/organizations/:orgId/tickets/:id:action`,
          {
            action: '@action',
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            create: {
              headers,
              method: 'POST',
            },
            get: {
              headers,
              method: 'GET',
            },
            performAction: {
              headers,
              method: 'POST',
            },
            query: {
              headers,
              isArray: true,
              method: 'GET',
            },
            update: {
              headers,
              method: 'PUT',
            },
          }
        );
      }

      function orgTicketAttachments() {
        return $resource(
          `${url}/organizations/:orgId/ticket_attachments/:ticketId/:fileName`,
          {
            fileName: '@fileName',
            orgId: apiUtils.getActiveOrg,
            ticketId: '@ticketId',
          },
          {
            download: {
              headers,
              method: 'GET',
              responseType: 'blob',
              transformResponse: (blob) => ({file: blob}),
            },
            // Angular’s default Content-Type header for POST and PUT requests is
            // application/json. So, if you omit the Content-Type header, that's
            // what it will attempt to set it as and the request will fail at the
            // server. On the other hand, if you manually attempt to set the
            // Content-Type header to multipart/form-data, then the boundary will
            // not get correctly set on the header value and the server will not
            // be able to determine where the data begins/ends (i.e. - parsing
            // error again; server fails in a different manner).
            // The "trick" is to explicitly set the Content-Type to undefined -
            // then, when the request is generated, the browser will determine
            // the correct Content-Type header and also generate/append the correct
            // boundary information to the header so the server can parse.
            upload: {
              headers: _.assignIn({'Content-Type': undefined}, headers), // see comment; above
              method: 'POST',
              transformRequest: angular.identity,
            },
          }
        );
      }
    }
  }
})();
