import {TooltipButton} from '@pandora/react-tooltip-button';
import LinkIcon from '@spectrum-icons/workflow/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A link icon wrapped in a tooltip. This typically follows the name in a table to indicate
 * the name is trusted.
 */
const TrustedIconActionButton = (props) => {
  const {'aria-label': ariaLabel, tooltipText, ...styleProps} = props;
  const intl = useIntl();

  return (
    <TooltipButton
      buttonAriaLabel={ariaLabel}
      hoverText={
        tooltipText || intl.formatMessage({id: 'settings.domainsTable.trustedIconTooltip'})
      }
      isQuiet
      margin="size-50" // focus ring is outside the bounds of the button
      variant="action"
      {...styleProps}
    >
      <LinkIcon size="S" UNSAFE_style={{color: 'var(--color-grey-600)'}} />
    </TooltipButton>
  );
};

TrustedIconActionButton.propTypes = {
  /**
   * An aria-label for the button. This should provide whatever context is needed for a screenreader
   * user to understand what this icon is associated with.
   * The default is the tooltip text.
   */
  'aria-label': PropTypes.string,
  /**
   * The text to show in a tooltip when the button is in focus or hovered on.
   * The default is 'Externally managed'.
   */
  tooltipText: PropTypes.string,
};

export default TrustedIconActionButton;
