(function () {
  /**
   * @ngdoc module
   * @name app.overview
   * @description module that defines overview pages/routes
   */
  angular.module('app.overview', [
    'app.core.auth',
    'app.core.contract.access',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.core.payment-info-editor',
    'app.core.product.access',
    'app.core.ready.org',
    'app.core.sophia',
    'app.widgets.button-with-tooltip',
    'app.widgets.hva.hva-card.assembler',
    'app.widgets.offers.free-offer-modal',
    'app.widgets.organization-type',
    'app.widgets.products',
    'app.widgets.products.purchase.add-products-modal',
    'app.widgets.remove-on-read-only',
    'app.widgets.scorecard',
    'app.widgets.sophia',
    'app.widgets.trial-tag',
    'app.widgets.users',
    'binky.core.api.jil',
    'binky.core.contact',
    'binky.core.download-utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.organizations',
    'binky.core.organizations.market-subsegments',
    'binky.core.page-context',
    'binky.core.product',
    'binky.core.src2-migration',
    'binky.shell.navigation.assembler',
    'binky.shell.navigation.constants',
    'binky.shell.workspace',
    'binky.shell.workspace.page',
    'binky.shell.workspace.page.header',
    'binky.ui-migration',
    'binky.widgets.common.button',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.progress-bar',
    'binky.widgets.common.select',
    'binky.widgets.common.titled-section',
    'binky.widgets.products.product-icon',
    'binky.widgets.products.product-name',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
