import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

import {getSerializedQueryParams} from './httpUtils';

let clientId, url;

/**
 * @description Configure Sophia V2 API
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for the Sophia api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({clientId, url} = config);
};

const getHeaders = () => {
  const authenticationToken = authentication.getAccessToken();

  const headers = {
    'X-Api-Key': clientId,
  };

  if (authenticationToken?.token) {
    headers.Authorization = `Bearer ${authenticationToken.token}`;
  }

  return headers;
};

/**
 * @description Fetches sophia content
 *
 * @param {Object} params - Sophia fetchContent params
 * @param {String} params.clientCode - The sophia client code
 * @param {ContextualParams} params.contextualParams - contextual params required by Sophia
 * @param {Array<String>} params.surfaceID - Array of surface IDs
 *
 * @returns {Promise} A promise which will resolve with the response data
 */
const getContent = (params) =>
  axios.get(url, {
    headers: getHeaders(),
    params: {
      ...params.contextualParams,
      surfaceId: params.surfaceID,
    },
    paramsSerializer: getSerializedQueryParams,
  });

const sophiaV2 = {
  configure,
  getContent,
};

export default sophiaV2;
