(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name app.core.user-folders:UserFolderList
   * @description Model for a user-folder list
   */
  angular.module('app.core.user-folders').factory('UserFolderList', userFolderList);

  /* @ngInject */
  function userFolderList(
    $q,
    $rootScope,
    _,
    AlphaListState,
    JIL_CONSTANTS,
    JIL_STORAGE_FOLDERS,
    jilStorageFolders,
    List,
    MEMBER_EVENT,
    MESSAGE,
    MODEL,
    modelCache,
    UserFolder
  ) {
    class UserFolderList extends List {
      /**
       * @description Method to create a new instance of a UserFolderList.
       * @param {Object} options - options passed to the UserFolderList constructor
       * @param {String} options.includes - type of folder to fetch, active, all, and discarded,
       *                                    defaults to active
       * @param {AlphaListState} options.state - alpha list state,
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.USERFOLDERLIST,
              itemClassRef: UserFolder,
              resource: jilStorageFolders.userFolders,
            },
            options
          )
        );
        this.includes = options.includes;
      }

      /**
       * @description Method to check if there is content in the user folder list
       * @returns {Boolean} true if there is content
       */
      hasContent() {
        return this.state.hasContent;
      }

      /**
       * @description Method to permanently delete user folder.
       * @param {Array} folders - folders to be permanently deleted
       * @returns {Promise} resolves to UserFolder on success, else rejects with error
       */
      permanentDelete(folders) {
        this.$resolved = false;

        const operations = _.map(folders, (folder) => ({
          op: 'remove',
          path: `/${folder.id}/delete`,
        }));

        this.$promise = jilStorageFolders.userFolders
          .batchOperation(operations)
          .$promise.then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess(response) {
          if (
            _.some(response, (item) => !_.chain(item).get('responseCode').inRange(200, 400).value())
          ) {
            return $q.reject();
          }
          $rootScope.$emit(MESSAGE.DELETE.USERFOLDER);
          return this.refresh();
        }

        return this.$promise;
      }

      /**
       * @description Method to refresh User Folder list from back-end.
       * @returns {Promise} resolves to UserFolder on success, else rejects with error
       */
      refresh() {
        this.$promise = super
          .refresh({includes: this.includes})
          .then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(MESSAGE.REFRESH.USERFOLDERLIST);
          return this;
        }

        return this.$promise;
      }

      /**
       * @description Method to reset User Folder list.
       * @param {Number} pageSize - size of the items per page
       * @param {String} sortKey - key for sorting
       */
      reset(pageSize, sortKey) {
        this.$promise = UserFolderList.get({
          state: new AlphaListState({
            pageSize,
            sortKey,
          }),
        }).$promise.then((folderList) => {
          this.items = folderList.items;
          this.state = folderList.state;
        });
      }

      /**
       * @description Method to search and update the list.
       * @param {Object} user - user to be searched for user folder
       * @param {String} includes - type of folder to search, active, all, and discarded,
       *                                    defaults to active
       */
      search(user, includes) {
        this.$promise = UserFolderList.getListByUsers({
          includes,
          users: [user.id],
        })
          .then((userFolders) => {
            if (userFolders.length > 0) {
              userFolders[0].owners.push(user);
              userFolders[0].userName = user.userName;
            }
            this.items = userFolders;
          })
          .catch(() => {
            this.items = [];
          })
          .finally(() => {
            this.state = new AlphaListState({
              filterQuery: user.id,
              hasContent: true,
              pageSize: 10,
              sortKey: JIL_STORAGE_FOLDERS.SORT.CREATED_DATE,
            });
          });
      }

      /**
       * @description Method to modify sort parameters of the user folder list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description Method to fetch the UserFolderList. It will be refreshed on
       *     registering the active org
       * @param {Object} options - configuration options for list retrieval
       * @returns {UserFolderList} singleton UserFolderList.
       */
      static get(options = {}) {
        let model = new UserFolderList(options);
        const cachedModel = modelCache.get(
          MODEL.USERFOLDERLIST,
          model.key({includes: options.includes})
        );
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }

      /**
       * @description Method to fetch the a list of UserFolder based on a given list of user IDs
       * @param {Object} options - configuration options for list retrieval
       * @param {Array} options.users - a list of user IDs to search on
       * @returns {UserFolderList} singleton UserFolderList.
       */
      static getListByUsers(options = {}) {
        return jilStorageFolders.userFoldersByUsers.fetch(options.users).$promise.then((data) =>
          _(data)
            .filter(['responseCode', 200])
            .map((datum) => new UserFolder(datum.response))
            .value()
        );
      }
    }

    // We register the cache size for this class, the reason for the size to be 2 is because there
    // are the active folder list and deleted folder list
    modelCache.register(MODEL.USERFOLDERLIST, 2);

    modelCache.removeAllOnEvent(MODEL.USERFOLDERLIST, [
      MESSAGE.CREATE.USERFOLDER,
      MESSAGE.DELETE.USERFOLDER,
      MESSAGE.UPDATE.USERFOLDERLIST,
      MEMBER_EVENT.CREATE,
      MEMBER_EVENT.DELETE,
      MEMBER_EVENT.UPDATE,
    ]);

    return UserFolderList;
  }
})();
