import {PATH_SUPPORT} from 'features/support/routing/supportPaths';

import help from '../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> get support
 */
const getSupport = () => ({
  content: [
    {
      links: [
        {
          key: 'help.topics.getSupport.link.one.key',
          locator: PATH_SUPPORT,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.getSupport.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.GET_SUPPORT,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.getSupport.title',
});
export default getSupport;
