import {Flex, Grid, Heading, TextField, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';
import {isPackageNameValid} from 'features/packages/stores/createPackageModalStoreUtils';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import FlatPackageCheckbox from '../../common-components/FlatPackageCheckbox';
import {getLicenseFileDetails} from '../product-selection-page/productSelectionPageUtils';
import ProductsList from '../product-selection-page/products-list/ProductsList';

import IncludedPlugins from './included-plugins/IncludedPlugins';
import PackageDetails from './package-details/PackageDetails';

const FinalizePackagePage = observer(() => {
  const {createPackageModalStore} = useCreatePackageModalContext();
  const packageSessionSettings = createPackageModalStore.pkgSessionSettings;
  const {selectedProducts, packageCreateObj} = createPackageModalStore;
  const intl = useIntl();

  /**
   * Method to check if package name has error
   * returns {Boolean} true/false
   */
  const shouldShowPackageNameError = () => {
    if (!packageCreateObj.packageName?.trim()) {
      return false;
    }
    return !isPackageNameValid(packageCreateObj.packageName);
  };

  /**
   * Method to check if we should list the included plugins
   * returns {Boolean} true/false
   */
  const showIncludedPlugins = () => packageSessionSettings.selectedPlugins.length > 0;

  /**
   * Method to check invalid package name and set valid package name
   */
  const setPackageName = (packageName) => {
    createPackageModalStore.setPackageCreateObjValues('packageName', packageName);
    createPackageModalStore.updateCreatePackageButtonStatus();
  };

  /**
   * @description Method to check the license file entry should be shown.
   *
   * @returns {Boolean} whether next button should be shown or not.
   */
  const showLicenseFileEntry = () => {
    if (
      packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
      (packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE &&
        packageSessionSettings.frlIsolatedMachineCodes &&
        packageSessionSettings.frlIsolatedMachineCodes.length > 0)
    ) {
      return true;
    }

    if (
      [
        CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE,
        CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE,
        CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE,
      ].includes(packageSessionSettings.packageType)
    ) {
      return packageSessionSettings.licenseFileSelected;
    }
    return false;
  };

  const getUpdatedSelectedProducts = () => {
    if (showLicenseFileEntry()) {
      return [
        getLicenseFileDetails({
          addRemoveOptionVisible: false,
          intl,
          licenseFileSelected: true,
          packageType: packageSessionSettings.packageType,
        }).products[0],
        ...selectedProducts,
      ];
    }
    return selectedProducts;
  };

  const generateErrorMessage = () =>
    shouldShowPackageNameError() &&
    intl.formatMessage({
      id: `packages.createPackage.finalizePackagePage.${
        packageCreateObj.packageName?.trim().length > 40 ? 'pathTooLong' : 'errorInPackageName'
      }`,
    });

  /**
   * Show product list on finalize page only when:
   * 1. Non-lightweight package is being created
   * 2. If some products are selected in lightweight packages
   */
  const showProductsList = () => {
    if (
      packageSessionSettings.packageType !== PACKAGE_TYPE_CONSTANTS.MANAGED ||
      createPackageModalStore.selectedProducts.length > 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <View marginTop="size-100">
        <Flex alignItems="center" position="relative" width="calc(50% - size-450)">
          <TextField
            autoFocus
            data-testid="package-name-input"
            description={intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.enterPackageName.description',
            })}
            errorMessage={generateErrorMessage()}
            label={intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.packageName',
            })}
            maxLength={41}
            onChange={setPackageName}
            validationState={shouldShowPackageNameError() ? 'invalid' : ''}
            value={packageCreateObj.packageName}
            width="100%"
          />
        </Flex>
        <FlatPackageCheckbox />
      </View>

      <Grid columnGap="size-900" columns={['1fr', '1fr']} marginTop="size-150">
        {(showProductsList() || showIncludedPlugins()) && (
          <View>
            {showProductsList() && (
              <View>
                <Heading marginY="size-100" size={4}>
                  {intl.formatMessage({
                    id: 'packages.createPackage.finalizePackagePage.includedApplications',
                  })}
                  {` (${selectedProducts.length})`}
                </Heading>
                <ProductsList
                  products={getUpdatedSelectedProducts()}
                  selectedAddOnsMap={packageCreateObj.selectedProductAddOnsMap}
                  showLearnMoreLink
                  showOnlyAppAddOnsSummary
                />
              </View>
            )}
            {showIncludedPlugins() && (
              <IncludedPlugins packageSessionSettings={packageSessionSettings} />
            )}
          </View>
        )}
        <View>
          <Heading marginY="size-100" size={4}>
            {intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.packageDetails',
            })}
          </Heading>
          <Flex direction="column">
            <PackageDetails
              packageCreateObj={packageCreateObj}
              packageSessionSettings={packageSessionSettings}
              selectedProductsLength={selectedProducts.length}
            />
          </Flex>
        </View>
      </Grid>
    </>
  );
});
export default FinalizePackagePage;
