(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name ExpertSession
   * @description Model for an ExpertSession
   */
  angular.module('app.core.support').factory('ExpertSession', ExpertSessionModel);

  /* @ngInject */
  function ExpertSessionModel(
    $log,
    $q,
    $rootScope,
    _,
    locale,
    MESSAGE,
    MODEL,
    modelCache,
    onesieSrc2,
    OrganizationManager,
    SUPPORT_TICKET_CASE_TYPE,
    SUPPORT_TICKET_MILO_CASE_TYPE,
    SUPPORT_TICKET_RECORD_TYPE,
    SupportTicket
  ) {
    const miloApi = onesieSrc2.support.api.milo;
    class ExpertSession extends SupportTicket {
      /**
       * @class
       * @description Creates a new ExpertSession.
       *
       * @param {Object} options - options object, as described below. See also the SupportTicket
       *   constructor options, which this object is passed to.
       * @param {String} options.expertTitleCode - the code for the session topic, e.g. 'DSW'
       * @param {String} options.scheduledFor - when the session is scheduled for, as an ISO8601
       *   date. If the session has not yet been scheduled this is undefined.
       */
      constructor(options) {
        const ticketConfig = {
          cacheType: MODEL.EXPERTSESSION,
          fields: [
            'expertTitleCode',
            'proposedTime1',
            'proposedTime2',
            'proposedTime3',
            'scheduledFor',
          ],
          updateItemMessage: MESSAGE.UPDATE.EXPERTSESSION,
        };
        super(ticketConfig, options);
      }

      /**
       * @description Method to create an expert session.
       *
       * @param {Object} supportTicket properties that describe new expert session support ticket.
       *
       * @returns {promise} promise that resolves when requested expert session is created.
       *   The promise will resolve with an object that contains the id of the support ticket that was just created
       *   or will reject with the error.
       */
      static create(supportTicket) {
        // Add required recordType field.
        const ticket = _.assign({}, supportTicket, {
          recordType: SUPPORT_TICKET_RECORD_TYPE.EXPERT,
        });

        return SupportTicket.create(ExpertSession, ticket).then((response) => {
          // The cached expert session list is out of date since it doesn't contain the expert session just created.
          $rootScope.$emit(MESSAGE.CREATE.EXPERTSESSION);
          return response;
        });
      }

      /**
       * @description Retrieves the ExpertSession with the specified ID, from cache if available,
       *   else from the backend.
       *
       * @param {Object} options - options for the ExpertSession, as described below
       * @param {String} options.id - id of the expert session to retrieve
       * @param {String} options.recordType - recordType of the expert session to retrieve
       *
       * @returns {ExpertSession} the retrieved ExpertSession
       */
      static get(options) {
        return SupportTicket.get(
          ExpertSession,
          _.assign(options, {recordType: SUPPORT_TICKET_RECORD_TYPE.EXPERT})
        );
      }

      /**
       * @description Returns the expert sessions topics for either an ADMIN case or PRODUCT case.
       *
       * @param {Object} options - options for the operation
       * @param {SUPPORT_TICKET_CASE_TYPE} options.caseType - ADMIN or PRODUCT
       * @param {Object} [options.fulfillableItemCode] - Required for PRODUCT case. The fulfillable item code for the
       *   product to retrieve topics for
       * @returns {Promise} a promise which is resolved when the metadata is fetched successfully from the backend
       */
      static getMetadata({caseType, fulfillableItemCode}) {
        const options = {
          locale: locale.getActiveLocaleCode(),
          orgId: OrganizationManager.getActiveOrgId(),
          typeCode:
            caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
              ? SUPPORT_TICKET_MILO_CASE_TYPE.PRODUCT
              : SUPPORT_TICKET_MILO_CASE_TYPE.ADMIN,
        };

        if (caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT) {
          options.productCode = fulfillableItemCode;
        }

        return $q((resolve, reject) => {
          miloApi
            .getExpertSessionMetadata(options)
            .then((response) => resolve(response.data))
            .catch((error) => {
              $log.error(`Failed to get expert session metadata from MILO API.`, error);
              reject(error.response ? error.response : error);
            });
        });
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.UPDATE.EXPERTSESSIONS.LIST, () => {
      modelCache.removeAll(MODEL.EXPERTSESSION);
    });

    modelCache.register(MODEL.EXPERTSESSION, 10);

    return ExpertSession;
  }
})();
