(function () {
  /**
   * @ngdoc module
   * @name app.widgets.sophia
   * @description module that defines Sophia widgets
   */
  angular.module('app.widgets.sophia', [
    'app.core.analytics',
    'app.core.chat',
    'app.core.product.trial-helper',
    'app.core.sophia',
    'app.main.global-modals-manager',
    'app.widgets.carousel',
    'app.widgets.support',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
    'binky.core.url-utils',
    'binky.ui-migration',
    'binky.widgets.common.button',
    'binky.widgets.common.wait',
  ]);
})();
