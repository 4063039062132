import {
  Page,
  PageBanner,
  PageBanners,
  PageContent,
  PageHeader,
  PageNav,
  useStore,
} from '@admin-tribe/binky-ui';
import {Button} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import ServerListTable from 'features/packages/components/server-list-table/ServerListTable';
import ServerSetupGuideDrawer from 'features/packages/components/server-setup-guide-drawer/ServerSetupGuideDrawer';
import CreateServerButton from 'features/packages/components/servers-tab/create-server-modal/CreateServerButton';
import CreateServerModal from 'features/packages/components/servers-tab/create-server-modal/CreateServerModal';
import {CreateServerModalContextProvider} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import FirstUserExperience from 'features/packages/components/servers-tab/first-user-experience/FirstUserExperience';
import RemoveServerButton from 'features/packages/components/servers-tab/remove-server-button/RemoveServerButton';
import PackagesServerStore from 'features/packages/stores/PackagesServerStore';

import {ServerPageContextProvider} from './ServerPageContext';

const ServersPage = observer(() => {
  const packagesServerStore = useStore(() => new PackagesServerStore());
  const {serverSessionSettings, setServerSessionSettingValues} = packagesServerStore;
  const [isCreateServerModalOpen, setIsCreateServerModalOpen] = useState(false);
  const intl = useIntl();
  const {state} = useLocation();

  // Initial load of the admin packages list.
  useEffect(() => {
    packagesServerStore.load();
    packagesServerStore.invokeEventListener();
    setIsCreateServerModalOpen(state?.openCreateServerModal || false);
    // component will unmount
    return () => {
      packagesServerStore.revokeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required to execute this only on mount
  }, []);

  const onTableSectionChange = ({action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        packagesServerStore.goToNextPage();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        packagesServerStore.goToPreviousPage();
        break;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        packagesServerStore.onPageSizeChange(payload);
        break;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        packagesServerStore.search(payload.value);
        break;
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
        packagesServerStore.setSelectedServers(payload);
        break;
      default:
        // Do nothing
        break;
    }
  };

  const onBannerClose = () => {
    setServerSessionSettingValues('showNextStepBanner', false);
  };
  // isFUE function returns whether its First time user experience or not
  const isFUE = () => packagesServerStore.packagesServerList.length === 0;
  return (
    <ServerPageContextProvider packagesServerStore={packagesServerStore}>
      <Page
        bumperCtaButtonLabel={intl.formatMessage({id: 'packages.servers.bumper.goBack'})}
        isBumpered={packagesServerStore.hasLoadingError}
        isLoading={packagesServerStore.isLoading || packagesServerStore.isDeletionInProgress}
        showContent
        size="L"
      >
        <PageBanners>
          <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PACKAGES} />
          {serverSessionSettings.showNextStepBanner && (
            <PageBanner
              header={intl.formatMessage({id: 'packages.servers.nextStepBanner.title'})}
              onClose={onBannerClose}
              variant="info"
            >
              {serverSessionSettings.nextStepBannerBody}
            </PageBanner>
          )}
        </PageBanners>

        <PageHeader
          title={intl.formatMessage({
            id: 'packages.servers.title',
          })}
        />
        <PageNav />
        <PageContent>
          {isFUE() ? (
            <FirstUserExperience />
          ) : (
            <TableSection
              getIsItemSelectable={() => true} // server list table rows are selectable
              getItemKey={(item) => item.data.serverId}
              isServerError={packagesServerStore.hasLoadingError}
              items={packagesServerStore.tableStore.rows}
              onTableSectionChange={onTableSectionChange}
              pageNumber={packagesServerStore.tableStore.currentPage}
              selectionMode={SELECTION_MODE.MULTIPLE}
              totalPages={packagesServerStore.tableStore.totalPages}
            >
              <TableFilters
                aria-label={intl.formatMessage({id: 'packages.servers.searchPlaceholder'})}
                hasSearch
                height="size-450"
                isDisabled={packagesServerStore.packagesServerList.length === 0}
                marginTop="size-0"
                minHeight="size-450"
                minLength={1}
                width="55%"
              />
              <TableActions minWidth="40%" orientation="horizontal">
                <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
                  <Button data-testid="server-setup-guide-button" variant="primary">
                    {intl.formatMessage({id: 'packages.servers.removeServers.setupGuideButton'})}
                  </Button>
                  {(close) => (
                    <ServerSetupGuideDrawer
                      closeDrawer={close}
                      onCreateServerButtonClick={() => setIsCreateServerModalOpen(true)}
                    />
                  )}
                </DrawerTrigger>

                <CreateServerButton
                  buttonLabel={intl.formatMessage({
                    id: 'packages.servers.removeServers.createServerButton',
                  })}
                />
                <RemoveServerButton />
              </TableActions>
              <ServerListTable />
            </TableSection>
          )}
          <ModalContainer>
            {isCreateServerModalOpen && (
              <CreateServerModalContextProvider packagesServerStore={packagesServerStore}>
                <CreateServerModal
                  data-testid="create-server-modal"
                  onCancel={() => setIsCreateServerModalOpen(false)}
                />
              </CreateServerModalContextProvider>
            )}
          </ModalContainer>
        </PageContent>
      </Page>
    </ServerPageContextProvider>
  );
});

export default ServersPage;
