(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories')
    .component('appDirectorySettingsChangeDirectorySection', {
      controller,
      templateUrl:
        'app/widgets/directories/directory-settings/change-directory-section/directory-settings-change-directory-section.component.html',
    });

  /* @ngInject */
  function controller(_, OrganizationManager) {
    const vm = this;
    const activeOrgId = OrganizationManager.getActiveOrgId();

    _.assign(vm, {
      supportHref: `${activeOrgId || ''}/support`,
    });
  }
})();
