import {Button, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';

/**
 * Component is used to show Close Case button in Action Section. It is used in action section to render the Close case modal
 */
const SupportTicketCloseCase = ({isOpen, isSupportCase, onCloseCase}) => {
  const intl = useIntl();

  return (
    isSupportCase &&
    isOpen && (
      <View elementType="span" flex="true" paddingEnd="size-65" paddingStart="size-65">
        <Button
          aria-label={intl.formatMessage({
            id: 'support.supportTicketActionsSection.closeCase.title',
          })}
          className="close-case-button"
          data-testid="close-case-button"
          marginBottom="size-200"
          onClick={() => onCloseCase(SUPPORT_CONSTANTS.CASE_ACTIONS.CLOSE)}
          variant="primary"
        >
          {intl.formatMessage({id: 'support.supportTicketActionsSection.closeCase.title'})}
        </Button>
      </View>
    )
  );
};

SupportTicketCloseCase.propTypes = {
  /**
   * Used to check ticket/case is open.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Check if ticket/case belongs to support case
   */
  isSupportCase: PropTypes.bool.isRequired,
  /**
   * Used to open modal with selected action
   * The parameter is action type(close)
   */
  onCloseCase: PropTypes.func.isRequired,
};

export default SupportTicketCloseCase;
