import React from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  canViewAccessRequests,
  canViewDomains,
  canViewTrusts,
} from 'core/directories/access/directoryAccess';
import {buildAccessCheckLoader, buildDefaultSectionRedirectLoader} from 'core/router/loaders';
import {
  canViewAssetSettings,
  canViewAuthentication,
  canViewConsole,
  canViewEncryptionSettings,
  canViewIdentity,
  canViewSecurityContacts,
  canViewSettings,
  canViewSharedProjectPolicies,
} from 'core/settings/access/settingsAccess';
import AuthSettingsPage from 'features/settings/components/auth-settings-page/AuthSettingsPage';
import ConsoleSettingsPage from 'features/settings/components/console-settings-page/ConsoleSettingsPage';
import SecurityContactsPage from 'features/settings/components/security-contacts-page/SecurityContactsPage';
import SettingsWorkspace from 'features/settings/components/settings-workspace/SettingsWorkspace';
import AssetSettingsPage from 'features/settings/pages/asset-settings/AssetSettingsPage';
import ContentLogsPage from 'features/settings/pages/content-logs-page/ContentLogsPage';
import DirectoryDetailsPage from 'features/settings/pages/directory-details-page/DirectoryDetailsPage';
import EncryptionSettingsPage from 'features/settings/pages/encryption-settings-page/EncryptionSettingsPage';
import IdentityPage from 'features/settings/pages/identity-page/IdentityPage';
import SharedProjectPoliciesPage from 'features/settings/pages/shared-projects/SharedProjectPoliciesPage';
import {
  directoryDetailsLoader,
  identityLoader,
} from 'features/settings/routing/identityRoutesLoaders';

import assetSettingsRoutesLoaders from './assetSettingsRoutesLoaders';
import {
  PATH_DIRECTORY_DETAILS,
  PATH_SETTINGS_ASSET,
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
  PATH_SETTINGS_AUTHENTICATION,
  PATH_SETTINGS_CONSOLE,
  PATH_SETTINGS_CONTENT_LOGS,
  PATH_SETTINGS_ENCRYPTION,
  PATH_SETTINGS_IDENTITY,
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
  PATH_SETTINGS_ROOT,
  PATH_SETTINGS_SECURITY_CONTACTS,
  PATH_SETTINGS_SHARED_PROJECT_POLICIES,
} from './settingsPaths';

/**
 * Redirects from /:orgId/settings to the appropriate sub-route
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const defaultSectionLoader = async ({request}) => {
  const destination = generatePath(
    ((await canViewIdentity()) && PATH_SETTINGS_IDENTITY) ||
      ((await canViewAssetSettings()) && PATH_SETTINGS_ASSET_SHARING_OPTIONS) ||
      ((await canViewAuthentication()) && PATH_SETTINGS_AUTHENTICATION) ||
      PATH_SETTINGS_SECURITY_CONTACTS,
    {orgId: rootStore.organizationStore.activeOrgId}
  );

  return buildDefaultSectionRedirectLoader(PATH_SETTINGS_ROOT, destination)({request});
};

const getIdentityRoutes = () => [
  {
    children: [
      {
        index: true,
        path: PATH_SETTINGS_IDENTITY_DIRECTORIES,
      },
      {
        loader: buildAccessCheckLoader(canViewDomains),
        path: PATH_SETTINGS_IDENTITY_DOMAINS,
      },
      {
        loader: buildAccessCheckLoader(canViewTrusts),
        path: PATH_SETTINGS_IDENTITY_TRUSTEES,
      },
      {
        loader: buildAccessCheckLoader(canViewAccessRequests),
        path: PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
      },
    ],
    element: <IdentityPage />,
    loader: identityLoader,
    path: PATH_SETTINGS_IDENTITY,
  },
];

/**
 * Gets all the directory details routes.
 */
const getDirectoryDetailsRoutes = () => ({
  children: [
    {
      index: true,
      path: 'overview',
    },
    {
      path: 'authentication',
    },
    {
      path: 'domains',
    },
    {
      path: 'domain-enforcement',
    },
    {
      path: 'sync',
    },
    {
      path: 'trustees',
    },
  ],
  element: <DirectoryDetailsPage />,
  loader: directoryDetailsLoader,
  path: PATH_DIRECTORY_DETAILS,
});

/** Gets all of the settings section routes */
const settingsRoutes = () => [
  {
    children: [
      ...getIdentityRoutes(),
      getDirectoryDetailsRoutes(),
      {
        element: <AssetSettingsPage />,
        loader: assetSettingsRoutesLoaders,
        path: PATH_SETTINGS_ASSET,
      },
      {
        element: <AssetSettingsPage />,
        loader: assetSettingsRoutesLoaders,
        path: PATH_SETTINGS_ASSET_SHARING_OPTIONS,
      },
      {
        element: <AssetSettingsPage />,
        loader: assetSettingsRoutesLoaders,
        path: PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
      },
      {
        element: <AssetSettingsPage />,
        loader: assetSettingsRoutesLoaders,
        path: PATH_SETTINGS_ASSET_CREDENTIALS,
      },
      {
        element: <AssetSettingsPage />,
        loader: assetSettingsRoutesLoaders,
        path: PATH_SETTINGS_ASSET_MIGRATION,
      },
      {
        element: <AuthSettingsPage />,
        loader: buildAccessCheckLoader(canViewAuthentication),
        path: PATH_SETTINGS_AUTHENTICATION,
      },
      {
        element: <SecurityContactsPage />,
        loader: buildAccessCheckLoader(canViewSecurityContacts),
        path: PATH_SETTINGS_SECURITY_CONTACTS,
      },
      {
        element: <ConsoleSettingsPage />,
        loader: buildAccessCheckLoader(canViewConsole),
        path: PATH_SETTINGS_CONSOLE,
      },
      {
        element: <ContentLogsPage />,
        loader: buildAccessCheckLoader(canViewSettings),
        path: PATH_SETTINGS_CONTENT_LOGS,
      },
      {
        element: <EncryptionSettingsPage />,
        loader: buildAccessCheckLoader(canViewEncryptionSettings),
        path: PATH_SETTINGS_ENCRYPTION,
      },
      {
        element: <SharedProjectPoliciesPage />,
        loader: buildAccessCheckLoader(canViewSharedProjectPolicies),
        path: PATH_SETTINGS_SHARED_PROJECT_POLICIES,
      },
    ],
    element: <SettingsWorkspace />,
    loader: defaultSectionLoader,
    path: PATH_SETTINGS_ROOT,
  },
];

export {defaultSectionLoader};
export default settingsRoutes;
