import {Flex, Item, TabList, TabPanels, Tabs, View, VisuallyHidden} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import CircleIcon from '@spectrum-icons/workflow/Circle';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

import SophiaCardView from '../SophiaCardView';

import styles from './SophiaBanner.pcss';

const {SURFACE_ID} = SOPHIA_CONSTANTS;

/**
 * @description Surface to fetch and render sophia cards. Shown in a "carousel" if more than 1 card.
 */
const SophiaBanner = ({
  contextualParamsOptions,
  onSophiaFetch,
  surfaceId = SURFACE_ID.ONE_CONSOLE,
}) => {
  const [sophiaCardList, setSophiaCardList] = useState(null);
  const promoTitleElId = useId();
  const intl = useIntl();

  // Fetches sophia cards on mount
  useEffect(() => {
    let isCurrent = true;
    const fetchSophiaCards = async () => {
      let sophiaCards;
      try {
        sophiaCards = await sophiaHelper.getSophiaCards({
          contextualParamsOptions,
          surfaceId,
        });
      } catch (error) {
        if (isCurrent) {
          sophiaCards = null;
        }
      }

      if (isCurrent) {
        setSophiaCardList(sophiaCards);
      }

      onSophiaFetch?.(sophiaCards);
    };

    fetchSophiaCards();

    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when these change
  }, [contextualParamsOptions, surfaceId]);

  // send onDisplayCard event if there is only 1 card
  useEffect(() => {
    if (sophiaCardList && sophiaCardList.items.length === 1) {
      sophiaHelper.onDisplayCard(sophiaCardList.items, 0);
    }
  }, [sophiaCardList]);

  const onDismissCard = (sophiaCard) => {
    setSophiaCardList((prevSophiaCardList) => {
      prevSophiaCardList.dismissCard(sophiaCard);

      // clone instance in order to trigger re-render
      return cloneDeep(prevSophiaCardList);
    });
  };

  const onTabChange = (cardId) => {
    const activeCardIndex = sophiaCardList.items.findIndex((item) => item.getCardId() === cardId);
    sophiaHelper.onDisplayCard(sophiaCardList.items, activeCardIndex);
  };

  // render nothing if there are no cards, or the list fails to fetch
  if (!sophiaCardList || sophiaCardList?.items?.length === 0) {
    return null;
  }

  // Don't render a TabView if there's only 1 card
  if (sophiaCardList.items.length === 1) {
    return (
      <View data-testid="sophia-banner" marginBottom="size-100">
        <SophiaCardView
          card={sophiaCardList.items[0]}
          onDismiss={() => {
            onDismissCard(sophiaCardList.items[0]);
          }}
        />
      </View>
    );
  }

  return (
    <View data-testid="sophia-banner">
      <VisuallyHidden elementType="span" id={promoTitleElId}>
        {intl.formatMessage({id: 'overview.promotions.title'})}
      </VisuallyHidden>
      <Flex justifyContent="center" marginBottom="size-100" width="100%">
        <Tabs
          aria-labelledby={promoTitleElId}
          density="compact"
          isQuiet
          maxWidth="1096px"
          onSelectionChange={onTabChange}
        >
          <TabList
            alignSelf="flex-end"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- set padding on tablist
            UNSAFE_className={styles.tablist}
          >
            {sophiaCardList.items.map((item, index) => (
              <Item key={item.getCardId()} textValue={item.card?.bodyCopy}>
                <CircleIcon
                  aria-label={intl.formatMessage(
                    {id: 'common.sophia.banner.tabItem'},
                    {index: index + 1}
                  )}
                  size="XXS"
                />
              </Item>
            ))}
          </TabList>
          <TabPanels>
            {sophiaCardList.items.map((item) => (
              <Item key={item.getCardId()}>
                <SophiaCardView
                  card={item}
                  onDismiss={() => {
                    onDismissCard(item);
                  }}
                />
              </Item>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </View>
  );
};

SophiaBanner.propTypes = {
  // https://git.corp.adobe.com/admin-tribe/onesie/wiki/Integration-with-Sophia
  // eslint-disable-next-line react/forbid-prop-types -- see wiki above for options
  contextualParamsOptions: PropTypes.object,
  /**
   * Optional callback once sophia data is fetched. Called with the card list
   */
  onSophiaFetch: PropTypes.func,
  /* The Sophia surface id. Defaults to SURFACE_ID.ONE_CONSOLE. */
  surfaceId: PropTypes.string,
};

export default SophiaBanner;
