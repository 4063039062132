(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support.component:appSupportTicketAddAttachment
   * @description Component that contains controls and components to allow users
   *   to add attachments to Support Tickets.
   * @param {Function} onCancel - callback to signal when this component has
   *   been canceled
   * @param {Function} onSuccess - callback to signal when this component has
   *   uploaded attachments successfully
   * @param {SupportTicket} supportTicket - reference to SupportTicket service
   *   instance that files should be uploaded/attached to
   */
  angular.module('app.widgets.support').component('appSupportTicketAddAttachment', {
    bindings: {
      onCancel: '&',
      onSuccess: '&',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-add-attachment/support-ticket-add-attachment.component.html',
  });

  /* @ngInject */
  function controller($q, $translate, _, FileSize, toastManager) {
    const vm = this;

    const MAX_ATTACHMENT_SIZE = FileSize.getNumBytes(20, 'MB'); // max file size in bytes
    const MAX_NUMBER_OF_ATTACHMENTS = 5; // maximum number of files to attach at a time
    const RESTRICTED_ATTACHMENT_TYPES = ['exe', 'dll']; // restricted upload file types
    const RESTRICTED_TYPES_ATTACH_ERROR_MSG_KEY =
      'widgets.support.supportTicketAddAttachment.restrictedTypesAttachErrorMsgKey'; // key for message to display when file attaching matches a restricted type

    _.assign(vm, {
      $onInit,
      attachFiles,
      MAX_ATTACHMENT_SIZE,
      MAX_NUMBER_OF_ATTACHMENTS,
      onAttachmentsCancel,
      RESTRICTED_ATTACHMENT_TYPES,
      RESTRICTED_TYPES_ATTACH_ERROR_MSG_KEY,
    });

    /**
     * @description Method to initialize component. Sets initial values for data
     *   required by component to operate correctly.
     */
    function $onInit() {
      setInitialState();
    }

    /**
     * @description Method to attach files to a Support Ticket.
     */
    function attachFiles() {
      vm.uploadInProgress = true;
      vm.attachmentProgressPromise = vm.supportTicket
        .addAttachments({attachments: vm.attachments, onAttachmentError, onAttachmentSuccess})
        .then(onAddAttachmentsSuccess, onAddAttachmentsError)
        .finally(() => {
          vm.uploadInProgress = false;
        });
    }

    /**
     * @description Method to handle resetting component state prior to calling
     *   controlling component's (parent's) onCancel callback.
     */
    function onAttachmentsCancel() {
      setInitialState();
      vm.onCancel();
    }

    /////////////

    /**
     * @description Method to handle case when some files failed to be attached
     *   to the Support Ticket.
     * @param {Object[]} results - array of results from attempting to attach
     *   each file. The format of each of these Objects is:
     *
     *   Object ({
     *     {String} error - error message returned from server request
     *     {Boolean failed - true if attachment failed to upload; else false
     *     {File} file - reference to file that was attempted to attach
     *   })
     */
    function onAddAttachmentsError(results) {
      _.forEach(results, removeIfUploaded);

      function removeIfUploaded(result) {
        if (!result.failed) {
          _.remove(vm.attachments, ['name', result.file.name]);
        }
      }
    }

    /**
     * @description Method to handle case when all files were attached successfully
     *   to the Support Ticket.
     */
    function onAddAttachmentsSuccess() {
      setInitialState();
      vm.onSuccess();
    }

    /**
     * @description Method to handle case when a single file fails to attach to
     *   the Support Ticket.
     * @param {File} file - reference to file that failed to attach
     */
    function onAttachmentError(file) {
      toastManager.showErrorToast(
        $translate.instant('widgets.support.supportTicketAddAttachment.toast.error', {
          filename: file.name,
        })
      );
    }

    /**
     * @description Method to handle case when a single file succeeds in being
     *   attached to the Support Ticket.
     * @param {File} file - reference to file that was attached
     */
    function onAttachmentSuccess(file) {
      toastManager.showSuccessToast(
        $translate.instant('widgets.support.supportTicketAddAttachment.toast.success', {
          filename: file.name,
        })
      );
    }

    /**
     * @description Method to set up the initial state for this component data.
     */
    function setInitialState() {
      vm.attachmentProgressPromise = $q.resolve();
      vm.attachments = [];
    }
  }
})();
