import {PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import {groupPropType} from '../prop-types/configurationGroupsPropTypes';

import EditConfigurationGroupDescription, {
  displayEditConfigurationGroupDescription,
} from './EditConfigurationGroupDescription';
import ExpandablePermissionsForm from './content-type/expandable/ExpandablePermissionsForm';
import ExpandableSelectPermissionsForm from './content-type/expandable-select/ExpandableSelectPermissionsForm';

const EditConfigurationGroup = ({group, onChange}) => {
  const setGroupEnabled = (enabled) => {
    onChange({...group, disabled: !enabled});
  };

  return (
    <>
      {displayEditConfigurationGroupDescription(group) && (
        <EditConfigurationGroupDescription group={group} setGroupEnabled={setGroupEnabled} />
      )}
      {!group.disabled && group.type === PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE.EXPANDABLE && (
        <ExpandablePermissionsForm group={group} onChange={onChange} />
      )}
      {!group.disabled &&
        group.type === PROFILE_PERMISSIONS_SECTION_CONTENT_TYPE.EXPANDABLE_SELECT && (
          <ExpandableSelectPermissionsForm group={group} onChange={onChange} />
        )}
    </>
  );
};

EditConfigurationGroup.propTypes = {
  group: groupPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditConfigurationGroup;
