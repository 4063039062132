(function () {
  /**
   * @ngdoc module
   * @name app.widgets.adobe-status
   * @description module that defines adobe status widgets
   */
  angular.module('app.widgets.adobe-status', [
    'app.core.adobe-status',
    'binky.shell.bumper',
    'binky.widgets.common.date-time',
    'binky.widgets.common.go-url',
    'binky.widgets.common.search',
    'binky.widgets.common.status',
    'ngAnimate',
  ]);
})();
