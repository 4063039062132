(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories.idp-status').constant('IDP_STATUSES', {
    DEFAULT: {label: 'widgets.directories.idpList.idpStatusLabel.default', variant: 'info'},
    INACTIVE: {label: 'widgets.directories.idpList.idpStatusLabel.inactive', variant: 'default'},
    PENDING: {label: 'widgets.directories.idpList.idpStatusLabel.unconfigured', variant: 'warning'},
  });
})();
