(function () {
  /**
   * @deprecated use src2 Quick Assign modal
   */
  angular.module('app.widgets.users').factory('QuickAssignHelper', QuickAssignHelper);

  /* @ngInject */
  function QuickAssignHelper(
    $filter,
    $q,
    $translate,
    $window,
    _,
    CLOUD,
    OrganizationUser,
    OrganizationUserList,
    PRODUCT_CODE,
    PRODUCT_FAMILY
  ) {
    const service = {
      areAllRowsPromisesResolved,
      getDownloadButtonLabel,
      getOrgUserListFromRowsDetails,
      getQuickAssignCloseConfirmationModalDetails,
      isValidUserAndRow,
      onDownload,
    };

    const CONFIRMATION_MODAL_TYPES = {
      CANCEL: 'cancel',
      CONFIRM: 'confirm',
      CSV: 'csv',
      EMAIL: 'email',
    };

    const DOWNLOAD_GO_URLS = {
      ACROBAT: 'ac_download_dc',
      ACROBAT_GET_STARTED: 'ac_getstarted_dc',
      CREATIVE_CLOUD: 'ac_download_cc',
      SPARK: 'ac_download_spark',
      STOCK: 'ac_download_stock',
    };

    return service;

    //////////////

    function areAllRowsPromisesResolved(productsAndUsersData) {
      const allRows = _.flatMap(productsAndUsersData, 'rowsDetails');
      const promises = _.map(allRows, 'rowWaitDeferred.promise');
      return $q.all(promises);
    }

    function getDownloadButtonLabel(productData) {
      if (productData.family === PRODUCT_FAMILY.SPARK || productData.isAdobeStock) {
        return 'widgets.users.quickAssignModal2.buttons.launchNow';
      } else if (productData.family === PRODUCT_FAMILY.ACROBAT) {
        if (isAcrobatGetStarted(productData)) {
          return 'widgets.users.quickAssignModal2.buttons.getStarted';
        }
        return 'widgets.users.quickAssignModal2.buttons.downloadNow';
      } else if (productData.cloud === CLOUD.CREATIVE) {
        return 'widgets.users.quickAssignModal2.buttons.downloadNow';
      }
      return 'widgets.users.quickAssignModal2.buttons.done';
    }

    function getOrgUserListFromRowsDetails(rowsDetails) {
      const allValidOrgUserRows = _.filter(rowsDetails, isValidUserAndRow);
      const orgUserList = new OrganizationUserList();
      _.forEach(allValidOrgUserRows, (row) => {
        const uniqueUser = new OrganizationUser(_.cloneDeep(row.orgUser));

        // New organization user should not have any saved states
        _.set(uniqueUser, 'savedState.products', []);

        orgUserList.addedItems.push(uniqueUser);
      });
      return orgUserList;
    }

    function isValidUserAndRow(row) {
      return row.isValidOrgUser && row.isValidRow;
    }

    function getQuickAssignCloseConfirmationModalDetails(options) {
      const {assignedLicenses, isSingleSeat, modalType} = options;

      const hasNoLicensesAssigned = assignedLicenses === 0;

      let translateKey;

      // eslint-disable-next-line no-negated-condition
      if (modalType !== CONFIRMATION_MODAL_TYPES.CONFIRM) {
        if (modalType === CONFIRMATION_MODAL_TYPES.CANCEL) {
          translateKey = isSingleSeat ? 'singleLicenseNotAssigned' : 'noLicensesAssigned';
        } else if (modalType === CONFIRMATION_MODAL_TYPES.EMAIL) {
          translateKey = 'emailInformationNotSaved';
        } else if (modalType === CONFIRMATION_MODAL_TYPES.CSV) {
          translateKey = 'csvNotSaved';
        }
      } else {
        translateKey = 'noLicensesAssigned';
      }

      const namespace = `widgets.users.quickAssignModal2.confirmationModal.${translateKey}`;

      const confirmationModalSecondaryDescription =
        (hasNoLicensesAssigned && modalType !== CONFIRMATION_MODAL_TYPES.CSV) ||
        modalType === CONFIRMATION_MODAL_TYPES.EMAIL
          ? undefined
          : $translate.instant(`${namespace}.secondaryDescription`);

      const confirmationModalSecondaryButtonLabel = $translate.instant(
        `${namespace}.secondaryButtonLabel`
      );
      const confirmationModalHeader = $translate.instant(
        `${namespace}.header`,
        {
          licensesAdded: assignedLicenses,
        },
        'messageformat'
      );

      return {
        confirmationModalCtaButtonLabel: $translate.instant(`${namespace}.ctaButtonLabel`),
        confirmationModalHeader,
        confirmationModalPrimaryDescription:
          translateKey === 'noLicensesAssigned'
            ? $translate.instant(`${namespace}.primaryDescription2`)
            : $translate.instant(`${namespace}.primaryDescription`),
        confirmationModalSecondaryButtonLabel,
        confirmationModalSecondaryDescription,
      };
    }

    function onDownload(product, onFinish) {
      if (product.cloud !== CLOUD.CREATIVE && product.family !== PRODUCT_FAMILY.ACROBAT) {
        onFinish();
      } else {
        let downloadGoUrl;
        if (product.family === PRODUCT_FAMILY.ACROBAT) {
          downloadGoUrl = isAcrobatGetStarted(product)
            ? DOWNLOAD_GO_URLS.ACROBAT_GET_STARTED
            : DOWNLOAD_GO_URLS.ACROBAT;
        } else if (product.family === PRODUCT_FAMILY.SPARK) {
          downloadGoUrl = DOWNLOAD_GO_URLS.SPARK;
        } else if (product.isAdobeStock) {
          downloadGoUrl = DOWNLOAD_GO_URLS.STOCK;
        } else {
          downloadGoUrl = DOWNLOAD_GO_URLS.CREATIVE_CLOUD;
        }
        $window.open($filter('goUrl')(downloadGoUrl), '_blank');
        onFinish();
      }
    }

    /* Private functions */

    /**
     * @description Check for specific Acrobat product codes to show the "Get started" experience
     * @param {Object} product One of the product data from quick-assign-modal2.component.js -- initProductDataList()
     * @returns {Boolean} True if user should see "Get started" experience. False otherwise.
     */
    function isAcrobatGetStarted(product) {
      return (
        product.family === PRODUCT_FAMILY.ACROBAT &&
        (product.code === PRODUCT_CODE.APAS ||
          product.code === PRODUCT_CODE.APSF ||
          product.code === PRODUCT_CODE.ASIG)
      );
    }
  }
})();
