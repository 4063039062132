(function () {
  /**
   * @deprecated Please use src2/app/common/components/add-domain-modal/AllowlistedDomainAddModal.jsx
   */
  angular.module('app.widgets.asset-sharing').component('appWhitelistedDomainAddModal', {
    bindings: {
      addAction: '=',
      validateInput: '=',
    },
    controller,
    templateUrl:
      'app/widgets/asset-sharing/whitelisted-domains/add-modal/whitelisted-domain-add-modal.component.html',
  });

  function controller($q, _, ADD_WHITELISTED_DOMAIN_MODAL_ID, panelManager) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      isAddDisabled,
      onClickAdd,
      onClickCancel,
      onClose,
      showErrorTooltip,
    });

    function $onInit() {
      _.assign(vm, {
        modalId: ADD_WHITELISTED_DOMAIN_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    function isAddDisabled() {
      return (
        vm.isBusy ||
        parseDomainsIput(vm.form.domainsToAdd).length === 0 ||
        !isInputValid(vm.form.domainsToAdd)
      );
    }

    function onClickAdd() {
      const domainsToAdd = parseDomainsIput(vm.form.domainsToAdd);
      vm.isBusy = true;
      vm.waitPromise = vm
        .addAction(domainsToAdd)
        .then(() => {
          panelManager.close(ADD_WHITELISTED_DOMAIN_MODAL_ID);
        })
        .finally(() => {
          vm.isBusy = false;
        });
    }

    function onClickCancel() {
      panelManager.close(ADD_WHITELISTED_DOMAIN_MODAL_ID);
    }

    function onClose() {
      vm.form.domainsToAdd = '';
    }

    function showErrorTooltip() {
      return !(_.isEmpty(vm.form.domainsToAdd) || isInputValid(vm.form.domainsToAdd));
    }

    //////////////

    function isInputValid(input) {
      const domains = parseDomainsIput(input);
      return domains.length > 0 && vm.validateInput(domains);
    }

    function parseDomainsIput(input) {
      const list = _.invoke(input, 'split', ',') || [];
      return _.invokeMap(list, 'trim');
    }
  }
})();
