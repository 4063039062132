import React, {useEffect} from 'react';

import useAppIntegrationsPolicyForm from 'features/products/app-integrations/hooks/useAppIntegrationsPolicyForm';

import AppIntegrationsPolicyForm from '../AppIntegrationsPolicyForm';

import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

// The 'Set auth policy' form step in the AddAppIntegrationModal workflow
const AddAppIntegrationModalSetPolicy = () => {
  const {appIntegration, setPolicyFormState} = useAddAppIntegrationModalContext();

  const {
    initAuthorizedUsers,
    isFormValid,
    newAuthorizedUsers,
    selectedPolicy,
    setMemberOperations,
    setSelectedPolicy,
  } = useAppIntegrationsPolicyForm({appIntegration});

  // Update context state with data needed for form validation and submission
  useEffect(() => {
    setPolicyFormState({
      isPolicyFormValid: isFormValid,
      newAuthorizedUsers,
      selectedPolicy,
    });
  }, [isFormValid, newAuthorizedUsers, selectedPolicy, setPolicyFormState]);

  return (
    <AppIntegrationsPolicyForm
      initAuthorizedUsers={initAuthorizedUsers}
      isGlobalPolicy={false}
      onMemberManagementChange={setMemberOperations}
      onSelectPolicy={setSelectedPolicy}
      selectedPolicy={selectedPolicy}
    />
  );
};

export default AddAppIntegrationModalSetPolicy;
