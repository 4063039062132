import {Divider} from '@adobe/react-spectrum';
import {ModalContent, ModalHeading} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {REMOVE_USER_STORAGE_OPTIONS} from 'common/services/user-folder/userFolderConstants';

/**
 * The final step that ensures that the admin wants to proceed with deleting the user with storage
 * and what the admin has chosen to do with the storage in the StorageSelection step
 */
const ConfirmationStep = ({storageOption}) => {
  const intl = useIntl();

  let message;

  switch (storageOption) {
    case REMOVE_USER_STORAGE_OPTIONS.SHARE:
      message = intl.formatMessage({
        id: 'common.removeUsersWithStorageModal.steps.confirmation.content.share',
      });
      break;
    case REMOVE_USER_STORAGE_OPTIONS.DISCARD:
      message = intl.formatMessage({
        id: 'common.removeUsersWithStorageModal.steps.confirmation.content.discard',
      });
      break;
    default:
      // default is REMOVE_USER_STORAGE_OPTIONS.DELETE
      message = intl.formatMessage({
        id: 'common.removeUsersWithStorageModal.steps.confirmation.content.delete',
      });
      break;
  }

  return (
    <>
      <ModalHeading>
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.confirmation.heading',
        })}
      </ModalHeading>
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <ModalContent>{message}</ModalContent>
    </>
  );
};

ConfirmationStep.propTypes = {
  /**
   * The user selected option of what to do with the storage during removal of users.
   */
  storageOption: PropTypes.oneOf(Object.values(REMOVE_USER_STORAGE_OPTIONS)).isRequired,
};
export default ConfirmationStep;
