import help from '../../constants/help';
import {hasOffice365IntegrationAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for product tab -> office integration
 */
const officeIntegration = () => ({
  access: hasOffice365IntegrationAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.office365Integration.link.one.key',
          locator: 'o365pdf_start',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.office365Integration.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.OFFICE_365_INTEGRATION,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  title: 'help.topics.office365Integration.title',
});
export default officeIntegration;
