/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.users:appUsersDetailSection
 *
 * @description The Users section for the Product Profile and User Group drawers.
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section.
 * @binding {int} provisionedQuantity. Used only for the Product Profile drawer.
 * @binding {int} totalQuantity. Used only for the Product Profile drawer.
 * @binding {int} userCount. Used only for the User Group drawer.
 * @binding {promise|undefined} waitPromise. If specified, a dot-dot-dot wait will be shown until
 *   the promise resolves.
 */
(function () {
  angular.module('app.widgets.users').component('appUsersDetailSection', {
    bindings: {
      headingLevel: '<?',
      provisionedQuantity: '<?',
      totalQuantity: '<?',
      userCount: '<',
      waitPromise: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/users/detail-sections/users-detail-section/users-detail-section.component.html',
  });

  /* @ngInject */
  function controller($q, _) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      vm.headingLevel = vm.headingLevel || 3;
      if (_.isUndefined(vm.waitPromise)) {
        vm.waitPromise = $q.resolve();
      }
    }
  }
})();
