import {log} from '@admin-tribe/binky';
import {showError, showSuccess} from '@admin-tribe/binky-ui';

import LegacyProductRole from 'common/entities/legacy-product-role/LegacyProductRole';

import {dispatchRemoveUserAnalytics} from './memberUtils';

/**
 * @description Given a licenseGroup, returns the list of LegacyProductRole, sorted by name.
 *
 * @param {Object} intl Intl object to format the error toasts.
 * @param {LicenseGroup} licenseGroup The license group/product profile.
 * @returns {Array} Array of LegacyProductRole sorted by 'name'.
 */
function processLegacyRoles({intl, licenseGroup}) {
  // This sort should be done with intl.collator but roles are already being sorted
  // several places this way already so to ensure consistency use the same sort.
  // eslint-disable-next-line no-nested-ternary -- want this to be compact
  const sortBy = (key) => (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);

  return licenseGroup
    .getProductRoles()
    .map(
      (role) =>
        new LegacyProductRole({
          default: role.default,
          id: role.id,
          name: intl.formatMessage({
            id: `common.memberProductUtils.legacyProductRole.${role.title}`,
          }),
        })
    )
    .sort(sortBy('name'));
}

/**
 * @description Save the member product role for the selected items/members.
 *
 * @param {PageContext} pageContext The context for the page.
 * @param {ProductUserStore} productUserStore The store for the product and profile users.
 * @param {Array} selectedItems The array of members which should have their product roles updated.
 * @param {String} selectedProductRole The id of the selected role.
 * @param {Object} tableSectionUtils From the TableSection context.
 * @returns {Promise} resolves with true if success, false if error
 */
async function saveProductRole({
  pageContext,
  productUserStore,
  selectedItems,
  selectedProductRole,
  tableSectionUtils = undefined,
}) {
  try {
    await productUserStore.saveProductRole({selectedItems, selectedProductRole});
  } catch (error) {
    log.error(`saveProductRole: ${error}`);
    // Pop an error toast and refresh the member roles.
    showError();
    return;
  }

  // Now make sure the table section and the store's list state are in sync.
  if (tableSectionUtils) {
    tableSectionUtils.clearSelectedItemKeys();
  }

  // Pop a success toast and refresh the member roles.
  showSuccess();

  dispatchRemoveUserAnalytics({
    attributes: {selectedProductRole, usersChangedCount: selectedItems.length},
    componentMethod: 'changeProductRole',
    pageContext,
  });
}

export {processLegacyRoles, saveProductRole};
