import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Packages Section
 * Wrapper over TitledSection to be used in packages tab.
 */
const PackagesSection = ({
  children,
  dividerPlacement = DividerPlacement.NONE,
  headingLevel = 2,
  headingId,
  title,
}) => (
  <TitledSection
    dividerPlacement={dividerPlacement}
    headingLevel={headingLevel}
    id={headingId}
    marginBottom="size-130"
    ruleVariant="S"
    title={title}
  >
    {children}
  </TitledSection>
);

PackagesSection.propTypes = {
  /**
   * Content to be shown in Packages section under title
   */
  children: PropTypes.element.isRequired,
  /**
   * The location to put the divider in the Packages Section. Defaults to none.
   */
  dividerPlacement: PropTypes.string,
  /**
   * The heading element's unique identifier.
   */
  headingId: PropTypes.string.isRequired,
  /**
   * The level to set the heading at. Defaults to 2.
   */
  headingLevel: PropTypes.number,
  /**
   * String displayed as heading of the section
   */
  title: PropTypes.string.isRequired,
};

export default PackagesSection;
