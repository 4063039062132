(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @description returns the localized string representing the specified reopen reason code
   * @param {SUPPORT_TICKET_ACTION_REASON_CODE.REOPEN} reasonCode - the reopen reason code
   * @returns {String} the localized reopening reason string
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.support').filter('appSupportCaseReopenReason', filter);

  /* @ngInject */
  function filter($translate, _, SUPPORT_TICKET_ACTION_REASON_CODE) {
    return filterFn;

    function filterFn(reasonCode) {
      const stringKey = _.findKey(
        SUPPORT_TICKET_ACTION_REASON_CODE.REOPEN,
        (code) => code === reasonCode
      );
      return stringKey
        ? $translate.instant(`widgets.support.supportCaseReopenReasonFilter.${stringKey}`)
        : '';
    }
  }
})();
