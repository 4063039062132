import {InsightsItemDetailListTableProvider, InsightsProvider} from '@pandora/react-insights';
import PropTypes from 'prop-types';
import React from 'react';
import {useRouteLoaderData} from 'react-router-dom';

import InsightsItemDetailListTable from './InsightsItemDetailListTable';

const InsightsItemDetailListTableWrapper = ({insightsItem, tabName}) => {
  const {accessOptions} = useRouteLoaderData('insights');

  return (
    <InsightsProvider>
      {/* No content is given because it is provided by the pandoraContentModel */}
      <InsightsItemDetailListTableProvider
        {...accessOptions}
        insightsItem={insightsItem}
        tabName={tabName}
      >
        <InsightsItemDetailListTable insightsItem={insightsItem} />
      </InsightsItemDetailListTableProvider>
    </InsightsProvider>
  );
};

InsightsItemDetailListTableWrapper.propTypes = {
  /** The Insights Item to display data for */
  insightsItem: PropTypes.shape({
    /** The ID of the insights item */
    id: PropTypes.string.isRequired,
  }).isRequired,
  /** The tab to display */
  tabName: PropTypes.string.isRequired,
};

export default InsightsItemDetailListTableWrapper;
