const PROFILE_PERMISSIONS_GROUP_TYPE = {
  EXPANDABLE: 'expandable',
  EXPANDABLE_SELECT: 'expandableSelect',
};

const PROFILE_PERMISSIONS_SUB_CONTENT_TYPE = {
  INCLUDE: 'include',
  SELECT: 'select',
  TEXT_FORM: 'textForm',
};
const EMPTY_TABLE_TYPE = {
  NO_PERMISSIONS: 'noPermissions',
  NO_SEARCH_RESULTS: 'noSearchResult',
};

const MINIMUM_PERMISSIONS_FOR_SEARCH = 4;

export {
  PROFILE_PERMISSIONS_GROUP_TYPE,
  PROFILE_PERMISSIONS_SUB_CONTENT_TYPE,
  EMPTY_TABLE_TYPE,
  MINIMUM_PERMISSIONS_FOR_SEARCH,
};
