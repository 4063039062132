import {Product} from '@admin-tribe/binky';
import {DetailSection} from '@admin-tribe/binky-ui';
import {
  Checkbox,
  Content,
  ContextualHelp,
  Divider,
  Flex,
  Heading,
  Switch,
  Text,
} from '@adobe/react-spectrum';
import {FULFILLABLE_ITEM_TYPE} from '@pandora/data-model-product';
import {ImageIcon} from '@pandora/react-image-icon';
import {useListContainerContext} from '@pandora/react-list-container';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';

const isItemSelected = (item) =>
  (item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.SERVICE && item.selected) ||
  (item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
    Number.parseInt(item.chargingModel.cap, 10) > 0);

/**
 * The Product Profile Services Section.
 * For the given context, renders a section which lists the product profile services that are available to toggle.
 */
const ProductProfileServicesSection = ({product, showDivider = true}) => {
  const intl = useIntl();

  const {listContainerState, listContainerUtils} = useListContainerContext();

  // Update ListContainer's selected keys on initial render
  useEffect(
    () => {
      // Must collect select keys first, then update the ListContainer's state once
      const selected = [];
      listContainerState.items.forEach((item) => {
        if (isItemSelected(item)) {
          selected.push(item.code);
        }
      });
      listContainerUtils.setSelectedItemKeys(selected);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run on initial render
    []
  );

  const toggleSelection = useCallback(
    (item, isSelected) => {
      const selected = listContainerState.selectedItemKeys;

      if (isSelected) {
        selected.push(item.code);
      } else {
        selected.splice(selected.indexOf(item.code), 1);
      }
      if (item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA) {
        if (isSelected) {
          const foundItem = product.fulfillableItemList.items.find(
            (fItem) => item.code === fItem.code
          );
          Object.assign(item.chargingModel, {cap: foundItem.chargingModel.cap});
        } else {
          Object.assign(item.chargingModel, {cap: '0'});
        }
      } else {
        // The only other supported type is SERVICE
        Object.assign(item, {selected: isSelected});
      }

      listContainerUtils.setSelectedItemKeys(selected);
    },
    [product, listContainerState.selectedItemKeys, listContainerUtils]
  );

  const onChangeSelectAll = useCallback(
    (isSelected) => {
      const items = listContainerState.items;

      if (isSelected) {
        items.forEach((item) => {
          if (item.purchased && !isItemSelected(item)) {
            toggleSelection(item, isSelected);
          }
        });
      } else {
        items.forEach((item) => {
          if (item.purchased) {
            toggleSelection(item, isSelected);
          }
        });
      }
    },
    [listContainerState.items, toggleSelection]
  );

  const rightHeaderContent = useCallback(() => {
    const purchasedItemsLength = listContainerState.items.filter((item) => item.purchased).length;
    return (
      <Checkbox
        isEmphasized
        isHidden={listContainerUtils.isListEmpty()}
        isIndeterminate={
          listContainerState.selectedItemKeys.length > 0 &&
          listContainerState.selectedItemKeys.length < purchasedItemsLength
        }
        isSelected={listContainerState.selectedItemKeys.length === purchasedItemsLength}
        onChange={(isSelected) => {
          onChangeSelectAll(isSelected);
        }}
      >
        {intl.formatMessage({
          id: 'common.productProfileServicesSection.enableAllServices',
        })}
      </Checkbox>
    );
  }, [listContainerState, onChangeSelectAll, intl, listContainerUtils]);

  return (
    <DetailSection
      dividerPlacement={showDivider ? 'bottom' : 'none'}
      marginBottom="size-200"
      rightHeaderContent={rightHeaderContent()}
      title={intl.formatMessage({
        id: 'common.productProfileServicesSection.title',
      })}
    >
      {listContainerState.items.map((item) => (
        <React.Fragment key={item.code}>
          <Flex alignItems="center" gap="0" marginY="size-50">
            <Switch
              isDisabled={!item.purchased}
              isEmphasized
              isSelected={listContainerState.selectedItemKeys.includes(item.code)}
              marginEnd="0" /* override Switch's default marginEnd */
              marginStart="size-100"
              onChange={(isSelected) => {
                toggleSelection(item, isSelected);
              }}
            >
              <Flex gap="size-100">
                {item.assets?.icons?.svg && (
                  <ImageIcon alt="" size="M" src={item.assets.icons.svg} />
                )}
                <Text data-testid="service-name">{item.enterpriseName}</Text>
              </Flex>
            </Switch>
            <ContextualHelp>
              <Heading>{item.enterpriseName}</Heading>
              <Content>
                <Text>{item.longDescription}</Text>
              </Content>
            </ContextualHelp>
          </Flex>
          <Divider size="S" />
        </React.Fragment>
      ))}
      {listContainerUtils.isListEmpty() &&
        intl.formatMessage({
          id: 'common.productProfileServicesSection.noServices',
        })}
    </DetailSection>
  );
};

ProductProfileServicesSection.propTypes = {
  /**
   * The product these services are being displayed for. This will be used to fetch
   * the default cap value for any quota we're switching off.
   */
  product: PropTypes.instanceOf(Product),
  /**
   * Whether to show the divider between modal description and content. The default is true.
   */
  showDivider: PropTypes.bool,
};

export default ProductProfileServicesSection;
