/**
 * @deprecated should use the React Spectrum Link
 *
 * @ngdoc component
 * @name app.widgets.common.links.text-link.component:appTextLink
 *
 * @description Component to create a text link in the application
 *
 * @param {Boolean} [block] - Expands the text to the full width of the
 *   parent. Default: false
 * @param {String} [chevron] - Adds a right arrow (chevron) after the link text
 *   if binding evaluates to 'right' at runtime. Adds a left arrow (chevron)
 *   after the link text if binding evaluates to 'left' at runtime. Default: no
 *   chevron ('')
 * @param {Link} link - Model representing the link contained in this text.
 *   Required.
 */
(function () {
  angular.module('app.widgets.common.links.text-link').component('appTextLink', {
    bindings: {
      block: '<?',
      chevron: '@?',
      link: '<',
    },
    controller,
    templateUrl: 'app/widgets/common/links/text-link/text-link.component.html',
  });

  function controller(_, translationUtils) {
    const vm = this;

    _.assign(vm, {$onInit});

    function $onInit() {
      vm.link
        .isAvailable()
        .then((availability) => {
          vm.isAvailable = availability;
        })
        .catch((error) => error);

      vm.text = translationUtils.sanitizeSafeInstant(
        vm.link.key,
        vm.link.translateValues,
        vm.link.translateInterpolation
      );
    }
  }
})();
