import {DetailSection, DetailSectionMessage, GoUrl} from '@admin-tribe/binky-ui';
import {Content, ContextualHelp, Divider, Flex, Heading, Text} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ProductServicesDetailSection = ({hasSingleAppConfig = false, services, title}) => {
  const intl = useIntl();

  return (
    <DetailSection headingLevel={3} title={title}>
      <Text data-testid="single-app-description">
        {hasSingleAppConfig &&
          intl.formatMessage(
            {id: 'products.productDetailDrawer.availableServices.description'},
            {
              // eslint-disable-next-line react/forbid-elements -- need to bold
              b: (text) => <b>{text}</b>,
              goUrl: (urlText) => <GoUrl name="aac_single_app">{urlText}</GoUrl>,
            }
          )}
      </Text>

      {services.length > 0 ? (
        services.map((service) => (
          <React.Fragment key={service.code}>
            <Flex alignItems="center" gap="size-100" marginY="size-100">
              {service.assets?.icons?.svg && (
                <ImageIcon alt="" size="M" src={service.assets.icons.svg} />
              )}
              <Text>{service.enterpriseName}</Text>
              <ContextualHelp>
                <Heading>{service.enterpriseName}</Heading>
                <Content>
                  <Text>{service.longDescription}</Text>
                </Content>
              </ContextualHelp>
            </Flex>
            <Divider size="S" />
          </React.Fragment>
        ))
      ) : (
        <DetailSectionMessage
          translatedString={intl.formatMessage({
            id: 'products.productDetailDrawer.includedApplications.noIncludedServices',
          })}
        />
      )}
    </DetailSection>
  );
};

ProductServicesDetailSection.propTypes = {
  /**
   * True if product is Single App, false otherwise
   */
  hasSingleAppConfig: PropTypes.bool,
  /**
   * Array of included services
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  services: PropTypes.arrayOf(PropTypes.object),
  /**
   * Title of the section
   */
  title: PropTypes.string.isRequired,
};

export default ProductServicesDetailSection;
