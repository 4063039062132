import {Divider, Heading, Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import TabContent from './TabContent';

/**
 * The tab content component for the help rail content
 */
const HelpRailTab = () => {
  const intl = useIntl();

  if (!rootStore.helpStore.topics.selectedRootTopicChildren?.length > 0) {
    return null; // only render tabs if there are tabs to render
  }

  const tabHeading = intl.formatMessage({id: 'help.tab.heading'});

  const isTopicOnPath = rootStore.helpStore.isTopicOnPath.bind(rootStore.helpStore);
  const selectedTabItem = rootStore.helpStore.topics?.selectedRootTopicChildren?.find((helpItem) =>
    isTopicOnPath(helpItem.id)
  );
  const selectedTabKey = selectedTabItem?.id;

  const onSelectionChange = (selectedKey) => {
    rootStore.helpStore.goToTopic(`${rootStore.helpStore.rootTopic.id}.${selectedKey}`, false);
  };

  return (
    <>
      <Heading data-testid="tab-heading" margin="2rem 0 0.5rem">
        {tabHeading}
      </Heading>
      <Divider size="S" />
      <Tabs
        aria-label={`${tabHeading}`}
        data-testid="tabs"
        items={rootStore.helpStore.topics.selectedRootTopicChildren}
        marginTop="size-100"
        onSelectionChange={onSelectionChange}
        selectedKey={selectedTabKey}
      >
        <TabList>
          {(item) => (
            <Item key={item.id} data-testid="tab-list-item">
              {intl.formatMessage({id: item.title})}
            </Item>
          )}
        </TabList>
        <TabPanels>
          {(item) => (
            <Item key={item.id} data-testid="tab-panel-item">
              <TabContent />
            </Item>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default observer(HelpRailTab);
