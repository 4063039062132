import {
  AppIntegration,
  INVALID_APPLICATION_POLICY_TARGET_ERROR,
  POLICY_ACTIONS,
} from '@admin-tribe/binky';
import {showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Divider, useDialogContainer} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AppIntegrationsPolicyModalContentModel from '../content/AppIntegrationsPolicyModalContentModel';
import {useAppIntegrationTableContext} from '../hooks/AppIntegrationTableContext';
import useAppIntegrationsPolicyForm from '../hooks/useAppIntegrationsPolicyForm';

import AppIntegrationsPolicyForm from './AppIntegrationsPolicyForm';

const UPDATE_POLICY_SUCCESS_LOC_KEY = {
  ALLOW: 'products.appIntegrations.appIntegrationsPolicyModal.toast.allow',
  CUSTOM: 'products.appIntegrations.appIntegrationsPolicyModal.toast.custom',
  DENY: 'products.appIntegrations.appIntegrationsPolicyModal.toast.deny',
};

/**
 * AppIntegrationsPolicyModal allows the admin to set an individual app integration policy to
 * allow all, block all, or configure a custom allowlist of user groups and users.
 */
const AppIntegrationsPolicyModal = ({appIntegration}) => {
  const content = useContentEntry(AppIntegrationsPolicyModalContentModel);
  const intl = useIntl();
  const dialogContext = useDialogContainer();
  const {refreshList} = useAppIntegrationTableContext();

  const {
    initAuthorizedUsers,
    isFormValid,
    newAuthorizedUsers,
    selectedPolicy,
    setMemberOperations,
    setSelectedPolicy,
  } = useAppIntegrationsPolicyForm({appIntegration});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();

  const onCta = async () => {
    setSubmitError();
    setIsSubmitting(true);

    try {
      await appIntegration.updateAppPolicy(
        POLICY_ACTIONS.REPLACE,
        selectedPolicy,
        newAuthorizedUsers
      );
    } catch (error) {
      if (error?.response?.data?.errorCode === INVALID_APPLICATION_POLICY_TARGET_ERROR) {
        setSubmitError(
          intl.formatMessage({
            id: 'products.appIntegrations.appIntegrationsPolicyModal.toast.invalidPolicyTarget',
          })
        );
      } else {
        setSubmitError(
          intl.formatMessage({
            id: 'products.appIntegrations.appIntegrationsPolicyModal.toast.genericError',
          })
        );
      }
      return;
    } finally {
      setIsSubmitting(false);
    }

    showSuccessToast(
      intl.formatMessage(
        {
          id: UPDATE_POLICY_SUCCESS_LOC_KEY[selectedPolicy],
        },
        {
          integrationName: appIntegration.name,
        }
      )
    );

    // Refresh list so it is updated with new policy. Do not need to await.
    refreshList();

    dialogContext.dismiss();
  };

  const isCtaDisabled = isSubmitting || !isFormValid;

  return (
    <ModalDialog
      content={content.search(ModalDialogModel)[0]}
      errorMessage={submitError}
      heightVariant="static"
      id="app-integrations-policy-modal"
      isCtaDisabled={isCtaDisabled}
      isLoading={isSubmitting}
      // eslint-disable-next-line react/jsx-handler-names -- Using RS function name
      onCancel={dialogContext.dismiss}
      onCta={onCta}
    >
      <ModalHeading>
        <FormattedMessage
          id="products.appIntegrations.appIntegrationsPolicyModal.heading"
          values={{integrationName: appIntegration.name}}
        />
      </ModalHeading>
      <ModalContent>
        <Divider marginBottom="size-150" marginTop="size-150" size="M" />
        <AppIntegrationsPolicyForm
          initAuthorizedUsers={initAuthorizedUsers}
          onMemberManagementChange={setMemberOperations}
          onSelectPolicy={setSelectedPolicy}
          selectedPolicy={selectedPolicy}
        />
      </ModalContent>
    </ModalDialog>
  );
};

AppIntegrationsPolicyModal.propTypes = {
  /** The AppIntegration whose policy will be updated. */
  appIntegration: PropTypes.instanceOf(AppIntegration),
};

export default AppIntegrationsPolicyModal;
