(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(accountConfig);

  /* @ngInject */
  function accountConfig(
    $stateProvider,
    $transitionsProvider,
    ACCOUNT_STATE_PARAM,
    ACCOUNT_WORKSPACE_NAV_ID,
    navAssemblerProvider
  ) {
    const state = {
      name: 'account.account',
    };

    $stateProvider.state(state.name, {
      component: 'appAccount',
      data: {
        skipReloadAnalytics: true, // prevent analytics on state transition events. Used here to stop reload state event when add product modal closes after purchase
      },
      params: {
        // Optional param which can be passed to $state.go(..) to configure the route behaviour,
        // e.g. ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT will pop the edit payment dialog.
        // This is an anti-pattern being used to save time - we should really configure a child
        // /edit-payment route to do this.
        action: null,
      },
      resolve: {
        contractList: (OrganizationManager) => OrganizationManager.getContractsForActiveOrg(),
        invoiceList: ($q, InvoiceList, organizationAccess) =>
          $q
            .resolve(organizationAccess.canViewDirectContractBillingHistory())
            .then((canView) => $q.resolve(canView ? InvoiceList.get() : undefined)),
      },
      // Similarly to action, we are using this optional query param which can be passed to
      // $state.go(..) to configure the route behaviour. Its value should be an invoiceId, which
      // will be used to open one time payment flow in PIE.
      url: `?${ACCOUNT_STATE_PARAM.AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING}`,
    });

    // add the Account section to the Account side nav
    navAssemblerProvider.configure({
      navID: ACCOUNT_WORKSPACE_NAV_ID,
      navValue: {
        access: {
          callback: accessCallback,
        },
        disabled: false,
        hidden: false,
        name: 'account.title',
        order: 2,
        state,
      },
    });

    // When the app is entering a transition to any state in the account sub-tree, we need to ensure
    // that the correct naming convention is used for the Account subsection link (above).
    const deregisterAccountOrVIPHook = $transitionsProvider.onEnter(
      {to: 'account.**'},
      (trans) => {
        const productList = trans.injector().get('OrganizationManager').getProductsForActiveOrg();
        const navManager = trans.injector().get('navManager');
        const accountNavList = navManager.find(ACCOUNT_WORKSPACE_NAV_ID);
        const accountOrVIPOverviewNavItem = accountNavList.findByState(state.name);

        const vipOverviewNavLabel = 'account.vipOverview.title2';
        accountOrVIPOverviewNavItem.name = productList.hasBuyingProgramEtlaAndVipProducts
          ? vipOverviewNavLabel
          : 'account.account.title';

        // only run hook once per session (deregister hook after complete)
        deregisterHook();
      },
      // Ensure that this hook runs before any other competing onEnter hooks
      // (higher priority hooks run first)
      {priority: 999}
    );

    function accessCallback($q, injector) {
      return injector.get('accountAccess').canAccessAccountPage();
    }

    // Method to deregister the transition hook (above), ensuring that the logic
    // contained is only executed once per session (on access of account views)
    function deregisterHook() {
      if (deregisterAccountOrVIPHook) {
        deregisterAccountOrVIPHook();
      }
    }
  }
})();
