/* eslint-disable max-lines -- handle processing bulk operations state */
import {
  LicenseGroupList,
  MIGRATION_TYPE,
  MigrationList,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  ProductList,
  feature,
  getSeatBasedAssignableLicenseCount,
  isMigrationListESMType1,
  jilDirectories,
  jilOrganizationsProductsLicenseGroups,
  jilUserGroups,
  jilUsers,
  log,
} from '@admin-tribe/binky';
import {GoUrl, useAsyncModel} from '@admin-tribe/binky-ui';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {BULK_OPERATION_MODE, CSV_ROW_LIMIT} from '../bulk-operation-utils/bulkOperationConstants';
import {getManageAdminPolicyBanner} from '../bulk-operation-utils/bulkOperationUtils';

const NUM_LICENSES_SHORT_DESCRIPTION = 1000;

/**
 * @description - useBulkOperations hooks bulk operations state into your component.
 *
 * @param {String} orgId - The org id
 * @param {String|BULK_OPERATION_MODE} mode - the bulk operation mode
 * @param {Object|PageContext} pageContext - the page context to be processed
 * @param {Function} showPageBanner - function used to show/hide informational page banner
 * @returns {Object} - an object containing bulk operation state and fns
 */
const useBulkOperations = ({orgId, mode, pageContext, showPageBanner}) => {
  const intl = useIntl();

  // UI related state:
  // Stores localization obj that should be used with translateHelper fn
  // e.g. modalDescription = { id: 'localization.key', goUrlName: 'go_url'}
  const [confirmDialogParams, setConfirmDialogParams] = useState();
  const [modalDescription, setModalDescription] = useState();
  const [modalHeading, setModalHeading] = useState();

  // TODO use rootStore instead of MigrationList
  const [migrationList, setMigrationList] = useState();
  // TODO use rootStore instead of ProductList
  const [productList, setProductList] = useState();

  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [isMigrationListLoading, setIsMigrationListLoading] = useState(false);
  const activeOrg = rootStore.organizationStore.activeOrg;

  const loadLicenseGroupList = useCallback(() => {
    if (
      pageContext.targetType === PAGE_TARGET_TYPE.USER &&
      pageContext.target === PAGE_TARGET.PRODUCT &&
      productList
    ) {
      const product = productList.items.find((p) => p.id === pageContext.targetId);
      if (product?.isTeam()) {
        return LicenseGroupList.get({
          orgId,
          product,
        });
      }
    }
    return undefined;
  }, [orgId, pageContext, productList]);

  const {model: licenseGroupList, isLoading: isLicenseGroupListLoading} = useAsyncModel({
    loadFn: loadLicenseGroupList,
  });

  const isLoading = () =>
    isLicenseGroupListLoading || isProductListLoading || isMigrationListLoading;

  // non UI-related state:
  const exportFunction = useRef();
  const exportParams = useRef();
  const uploadFunction = useRef();
  const uploadParams = useRef();

  // Side effect to fetch ProductList if needed
  useEffect(() => {
    let isMounted = true;

    const fetchProductList = async () => {
      try {
        setIsProductListLoading(true);
        // TODO use ProductList from RootStore
        const products = await ProductList.get({
          orgId,
        });
        if (isMounted) {
          setProductList(products);
        }
      } catch (error) {
        // no need to bumper, will just show default description
        log.error('Unable to fetch ProductList for bulk operations. Error:', error);
      } finally {
        if (isMounted) {
          setIsProductListLoading(false);
        }
      }
    };

    if (
      pageContext.targetType === PAGE_TARGET_TYPE.USER ||
      pageContext.targetType === PAGE_TARGET.PRODUCT
    ) {
      fetchProductList();
    }

    return () => {
      isMounted = false;
    };
  }, [orgId, mode, pageContext]);

  // Side effect to fetch MigrationList for SWITCH_IDENTITY_TYPE mode
  useEffect(() => {
    let isMounted = true;

    const fetchMigrationList = async () => {
      // Logic pulled out from OrganizationManager
      const types = [
        MIGRATION_TYPE.ESM_TYPE1,
        MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE,
        MIGRATION_TYPE.VIP2DIRECT,
        MIGRATION_TYPE.VIPMP,
        MIGRATION_TYPE.T2E,
      ];

      setIsMigrationListLoading(true);
      try {
        // TODO: Use OrganizationStore's MigrationList when it is ready
        const migrations = await MigrationList.get({
          orgId,
          types,
        });
        if (isMounted) {
          setMigrationList(migrations);
        }
      } catch (error) {
        // TODO bumper the modal. no XD yet.
        log.error('Unable to fetch MigrationList for bulk operations. Error:', error);
      } finally {
        if (isMounted) {
          setIsMigrationListLoading(false);
        }
      }
    };

    if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE || mode === BULK_OPERATION_MODE.REMOVE) {
      fetchMigrationList();
    }

    return () => {
      isMounted = false;
    };
  }, [orgId, mode]);

  // Side effect to process the bulk operation mode
  useEffect(() => {
    // eslint-disable-next-line complexity -- process bulk operation mode
    const processBulkOpMode = () => {
      const descriptionKey = 'description2';
      switch (mode) {
        case BULK_OPERATION_MODE.ADD:
          if (pageContext.targetType === PAGE_TARGET_TYPE.USER_GROUP) {
            setModalDescription({
              id: `common.bulkOperation.mode.add.userGroup.${descriptionKey}`,
            });
            setModalHeading({
              id: 'common.bulkOperation.mode.add.userGroup.title',
            });
          } else {
            const longDescriptionKey = 'longDescription2';
            // Remove if check when rootStore is used
            if (productList) {
              const numLicenses = getSeatBasedAssignableLicenseCount(productList);
              const shortDescriptionKey = 'shortDescription2';
              // if there are less than 1000 licenses, use shorter description
              setModalDescription({
                id:
                  numLicenses < NUM_LICENSES_SHORT_DESCRIPTION
                    ? `common.bulkOperation.mode.add.user.${shortDescriptionKey}`
                    : `common.bulkOperation.mode.add.user.${longDescriptionKey}`,
              });
            } else {
              // Couldn't fetch ProductList, use regular description
              setModalDescription({
                id: `common.bulkOperation.mode.add.user.${longDescriptionKey}`,
              });
            }
            setModalHeading({id: 'common.bulkOperation.mode.add.user.title'});
          }
          break;
        case BULK_OPERATION_MODE.EDIT:
          if (pageContext.targetType === PAGE_TARGET_TYPE.USER_GROUP) {
            if (feature.isEnabled('temp_user_group_sharing') && !!activeOrg.parentId) {
              setModalDescription({
                id: `common.bulkOperation.mode.edit.userGroup.descriptionSharedUserGroupNote`,
              });
            } else {
              setModalDescription({
                id: `common.bulkOperation.mode.edit.userGroup.${descriptionKey}`,
              });
            }
            setModalHeading({id: 'common.bulkOperation.mode.edit.userGroup.title'});
          } else {
            // else: targetType === PAGE_TARGET_TYPE.USER
            setModalDescription({
              id: `common.bulkOperation.mode.edit.user.${descriptionKey}`,
            });
            setModalHeading({id: 'common.bulkOperation.mode.edit.user.title'});
          }
          break;
        case BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION:
          setModalDescription({
            goUrlName: 'aac_offer_migration_bulk',
            id: `common.bulkOperation.mode.offerSwitchMigration.${descriptionKey}`,
          });
          setModalHeading({id: 'common.bulkOperation.mode.offerSwitchMigration.title'});
          break;
        case BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE:
          // Remove if check when rootStore is used
          if (migrationList) {
            setModalDescription({
              id: `common.bulkOperation.mode.switchIdentityType.${descriptionKey}`,
            });
            setModalHeading({
              id: 'common.bulkOperation.mode.switchIdentityType.title',
            });
          }
          break;
        case BULK_OPERATION_MODE.REMOVE:
          setModalDescription({
            id: `common.bulkOperation.mode.remove.user.${descriptionKey}`,
          });
          setModalHeading({
            id: 'common.bulkOperation.mode.remove.user.title',
          });
          if (pageContext.target === PAGE_TARGET.DIRECTORY) {
            if (migrationList) {
              const esmType1 = isMigrationListESMType1(migrationList);
              const bannerParams = {
                children: {
                  goUrlName: 'remove_users_help',
                  id: `common.bulkOperation.mode.directoryUsers.alert.${
                    esmType1 ? 'removeDirectoryUsersInESMOrg' : 'removeDirectoryUsersInNonESMOrg'
                  }.body`,
                },
                header: {
                  id: 'common.bulkOperation.mode.directoryUsers.alert.removeDirectoryUsers.header',
                },
                showDefaultButton: false,
                variant: 'warning',
              };
              showPageBanner(bannerParams);

              // set confimation dialog text
              setConfirmDialogParams({
                children: {
                  goUrlName: 'remove_users_help',
                  id: `common.bulkOperation.mode.directoryUsers.alert.${
                    esmType1 ? 'removeDirectoryUsersInESMOrg' : 'removeDirectoryUsersInNonESMOrg'
                  }.body`,
                },
                ctaLabel: {
                  id: 'common.bulkOperation.mode.directoryUsers.alert.removeUsers',
                },
                header: {
                  id: 'common.bulkOperation.mode.directoryUsers.alert.title',
                },
                variant: 'destructive',
              });
            }
          }
          break;
        case BULK_OPERATION_MODE.UNASSIGN:
          // edit this
          setModalDescription({
            id: `common.bulkOperation.mode.unassign.user.${descriptionKey}`,
          });
          setModalHeading({
            id: 'common.bulkOperation.mode.unassign.user.title',
          });
          break;
        default:
          break;
      }
    };

    const checkManageAdminPolicy = async () => {
      const banner = await getManageAdminPolicyBanner({
        mode,
        orgId,
        targetType: pageContext.targetType,
      });
      if (banner) {
        showPageBanner(banner);
      }
    };

    processBulkOpMode();

    checkManageAdminPolicy();
  }, [orgId, intl, migrationList, mode, pageContext, productList, showPageBanner, activeOrg]);

  // Side effect to set upload/export functions and params
  useEffect(() => {
    // Process target/targetType for export/upload
    switch (pageContext.targetType) {
      case PAGE_TARGET_TYPE.USER:
        processUserTargetType();
        break;
      case PAGE_TARGET_TYPE.USER_GROUP:
        processUserGroupTargetType();
        break;
      default:
        // other target types are not yet supported
        break;
    }

    // Assign target/targetType-agnostic export/upload functions
    switch (mode) {
      case BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE:
        exportFunction.current = jilUsers.exportIdentityType;
        uploadFunction.current = jilUsers.bulkSwitchIdentityType;
        break;
      case BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION:
        uploadFunction.current = jilUsers.bulkOfferSwitchMigration;
        break;
      default:
        // Unknown mode not supported
        break;
    }

    function processUserTargetType() {
      switch (pageContext.target) {
        case PAGE_TARGET.ORGANIZATION:
          processOrganizationTarget();
          break;
        case PAGE_TARGET.USER_GROUP:
          processUserGroupTarget();
          break;
        case PAGE_TARGET.DIRECTORY:
          processDirectoryUsersTarget();
          break;
        case PAGE_TARGET.PRODUCT: {
          // The LicenseGroupList will only exist if this is a Team product
          const isTeamProduct = !!licenseGroupList;
          if (isTeamProduct) {
            // Team products only have one license group: use the single license group for bulk operation state
            const onlyProfileId = licenseGroupList.items[0].id;
            processProductProfileState(onlyProfileId, pageContext.targetId);
          }
          break;
        }
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          processProductProfileState(pageContext.targetId, pageContext.targetParentId);
          break;
        default:
          // other targets are not yet supported
          break;
      }
    }

    function processOrganizationTarget() {
      exportFunction.current = jilUsers.exportUsers;
      switch (mode) {
        case BULK_OPERATION_MODE.ADD:
          uploadFunction.current = jilUsers.bulkAddUsers;
          break;
        case BULK_OPERATION_MODE.EDIT:
          uploadFunction.current = jilUsers.bulkEditUsers;
          break;
        case BULK_OPERATION_MODE.REMOVE:
          uploadFunction.current = jilUsers.bulkRemoveUsers;
          break;
        default:
          // other modes not supported
          break;
      }
    }

    function processDirectoryUsersTarget() {
      exportFunction.current = jilDirectories.exportDirectoryUsers;
      exportParams.current = {
        directoryId: pageContext.targetId,
      };

      switch (mode) {
        case BULK_OPERATION_MODE.REMOVE:
          uploadFunction.current = jilDirectories.bulkRemoveDirectoryUsers;
          uploadParams.current = {
            directoryId: pageContext.targetId,
          };
          break;
        default:
          // other modes not supported
          break;
      }
    }

    function processUserGroupTarget() {
      exportFunction.current = jilUserGroups.exportUserGroupUsers;
      exportParams.current = {
        userGroupId: pageContext.targetId,
      };
      uploadParams.current = {
        userGroupId: pageContext.targetId,
      };

      switch (mode) {
        case BULK_OPERATION_MODE.ADD:
          uploadFunction.current = jilUserGroups.bulkAddUserGroupUsers;
          break;
        case BULK_OPERATION_MODE.UNASSIGN:
          uploadFunction.current = jilUserGroups.bulkRemoveUserGroupUsers;
          break;
        default:
          // other modes not supported
          break;
      }
    }

    function processUserGroupTargetType() {
      exportFunction.current = jilUserGroups.exportUserGroups;

      switch (mode) {
        case BULK_OPERATION_MODE.ADD:
          uploadFunction.current = jilUserGroups.bulkAddUserGroups;
          break;
        case BULK_OPERATION_MODE.EDIT:
          uploadFunction.current = jilUserGroups.bulkEditUserGroups;
          break;
        default:
          // other modes not supported
          break;
      }
    }

    function processProductProfileState(targetId, targetParentId) {
      exportFunction.current = jilOrganizationsProductsLicenseGroups.exportLicenseGroupUserList;
      exportParams.current = {
        licenseGroupId: targetId,
        productId: targetParentId,
      };
      uploadParams.current = {
        licenseGroupId: targetId,
        productId: targetParentId,
      };

      switch (mode) {
        case BULK_OPERATION_MODE.ADD:
          uploadFunction.current = jilOrganizationsProductsLicenseGroups.bulkAddLicenseGroupUsers;
          break;
        case BULK_OPERATION_MODE.UNASSIGN:
          uploadFunction.current =
            jilOrganizationsProductsLicenseGroups.bulkRemoveLicenseGroupUsers;
          break;
        default:
          // other modes not supported
          break;
      }
    }
  }, [licenseGroupList, mode, orgId, pageContext]);

  /**
   *
   * @description Translate helper method to inject <br/>, CSV_ROW_LIMIT, and GoUrls
   * into localizated strings
   *
   * @param {Object} localizationParams. See below
   * @param {String} localizationParams.id - the localization id
   * @param {String} [localizationParams.goUrlName] -  the unique go url name
   * @returns the translated string with injected values if applicable
   */
  function translateHelper({id, goUrlName = undefined}) {
    // Localization strings need line breaks, the bulk upload go url, and the rowLimit variable
    const translateValues = {
      br: <br />,
      goUrlBulkUpload: (str) => <GoUrl name="ac_bulk_upload">{str}</GoUrl>,
      rowLimit: CSV_ROW_LIMIT,
    };

    if (goUrlName) {
      Object.assign(translateValues, {
        goUrl: (str) => <GoUrl name={goUrlName}>{str}</GoUrl>,
      });
    }

    return intl.formatMessage(
      {
        id,
      },
      translateValues
    );
  }

  return {
    confirmDialogParams,
    exportFunction: exportFunction.current,
    exportParams: exportParams.current,
    isLoading,
    modalDescription,
    modalHeading,
    translateHelper,
    uploadFunction: uploadFunction.current,
    uploadParams: uploadParams.current,
  };
};

export default useBulkOperations;
/* eslint-enable max-lines -- end file */
