(function () {
  /**
   * @deprecated ported to src2/app/common/components/remove-domain-modal
   */
  angular.module('app.widgets.asset-sharing').component('appWhitelistedDomainRemoveModal', {
    bindings: {
      removeAction: '=',
    },
    controller,
    templateUrl:
      'app/widgets/asset-sharing/whitelisted-domains/remove-modal/whitelisted-domain-remove-modal.component.html',
  });

  function controller($q, _, panelManager, REMOVE_WHITELISTED_DOMAIN_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onClickCancel,
      onClickRemove,
    });

    function $onInit() {
      _.assign(vm, {
        modalId: REMOVE_WHITELISTED_DOMAIN_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    function onClickCancel() {
      panelManager.close(REMOVE_WHITELISTED_DOMAIN_MODAL_ID);
    }

    function onClickRemove() {
      vm.isBusy = true;
      vm.waitPromise = vm
        .removeAction()
        .then(() => {
          panelManager.close(REMOVE_WHITELISTED_DOMAIN_MODAL_ID);
        })
        .finally(() => {
          vm.isBusy = false;
        });
    }
  }
})();
