(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    _,
    auth,
    binkyDateTimeFilter,
    binkyProductNameLabelFilter,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    onesieSrc2,
    OrganizationManager,
    SVG_CLASS
  ) {
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      const {dispatchHvaClickAnalytics, dispatchHvaLoadedAnalytics} =
        onesieSrc2.users.services.offerSwitchMigration.analyticsUtils;
      const {getOfferSwitchMigration, getProductsByContractMigrationList} =
        onesieSrc2.users.services.offerSwitchMigration.helper;

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.OFFER_SWITCH_MIGRATION_ASSIGN_LICENSES,
        })
      );

      return result;

      function accessCallback() {
        if (!auth.isUserOrgAdmin()) {
          return $q.reject();
        }

        return getOfferSwitchMigration().then(({contract, contractMigrationList, isEligible}) =>
          // Ensure that this HVA doesn't slow down AC initialization, so check for
          // offer switch migration, and its content for now. They require no additional API calls.
          isEligible
            ? $q.resolve([generateOfferSwitchHVA({contract, contractMigrationList})])
            : $q.reject()
        );

        ////////

        function generateOfferSwitchHVA({contract, contractMigrationList}) {
          const renewalEndDate = binkyDateTimeFilter(contract.getRenewalWindowEndDate(), {
            dateOnly: true,
          });
          const targetProducts = getTrimmedProducts(
            getProductsByContractMigrationList({
              contractMigrationList,
              productList: OrganizationManager.getProductsForActiveOrg(),
              type: onesieSrc2.users.constants.offerSwitchMigration
                .PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.TARGET,
            })
          );

          const productName = targetProducts[0].displayName; // only used for 1 target product
          const translationSuffix =
            _.size(targetProducts) > 1 ? 'withoutProductName' : 'withProductName';
          const totalAvailableLicenses = _.reduce(
            targetProducts,
            (total, product) => total + product.count,
            0
          );

          const sourceProducts = getTrimmedProducts(
            getProductsByContractMigrationList({
              contractMigrationList,
              productList: OrganizationManager.getProductsForActiveOrg(),
              type: onesieSrc2.users.constants.offerSwitchMigration
                .PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.SOURCE,
            })
          );

          dispatchHvaLoadedAnalytics(sourceProducts, targetProducts);

          // Opting for separating single and multiple products because
          // product name is shown only for single target product (simpler for translation)
          return new HvaCard({
            ctaLabel: getString('ctaLabel', {totalAvailableLicenses}),
            description: getString(`${translationSuffix}.description`, {
              productName,
              renewalEndDate,
            }),
            header: getString(`${translationSuffix}.header`, {productName, totalAvailableLicenses}),
            id: 'hva-offer-switch-migration-assign-licenses',
            onCTAClick: () => {
              dispatchHvaClickAnalytics({
                sourceName: 'AssignLicenses',
                sourceProducts,
                targetProducts,
              });

              globalModalsManager.open({
                componentBindings: [
                  {
                    attributeName: 'component',
                    type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                    value: onesieSrc2.users.components.OfferSwitchMigrationModal,
                  },
                  {
                    attributeName: 'props',
                    type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                    value: {
                      contractRenewalEndDate: renewalEndDate,
                      isOpen: true,
                      onBulkOperationSuccess: () => $state.go('users.users.all-users'),
                      // Capture the same sourceProducts and targetProducts
                      onOfferMigrationCanceled: onOfferMigrationCanceled(
                        sourceProducts,
                        targetProducts
                      ),
                    },
                  },
                ],
                componentName: 'binkyReactContainer',
              });
            },
            sectionTitle: getString(`${translationSuffix}.sectionTitle`, {
              productName,
              totalAvailableLicenses,
            }),
            svgClass: SVG_CLASS.OFFER_SWITCH_MIGRATION,
          });
        }

        ////////

        function getTrimmedProducts(targetProducts) {
          return _.map(targetProducts, (product) => ({
            count: product.getAssignableLicenseCount() - (product.delegatedQuantity || 0),
            displayName: binkyProductNameLabelFilter(product),
            id: product.offerId,
          }));
        }

        function getString(suffix, params) {
          return $translate.instant(
            `overview.highValueActionArea.setUpTasks.offerSwitch.messageFormat.${suffix}`,
            params,
            'messageformat'
          );
        }

        function onOfferMigrationCanceled(sourceProducts, targetProducts) {
          return () => dispatchHvaLoadedAnalytics(sourceProducts, targetProducts);
        }
      }
    }
  }
})();
