(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    _,
    auth,
    contractUtils,
    feature,
    globalModalsManager,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    OrganizationManager,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.ADD_PRODUCTS,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          return OrganizationManager.getProductsForActiveOrg()
            .$promise.then((productList) => {
              if (_.isEmpty(productList.items)) {
                return $q.resolve();
              }
              return $q.reject();
            })
            .then(() => OrganizationManager.getContractsForActiveOrg())
            .then((contractList) => {
              if (
                contractUtils.isAllowedToAddProducts() &&
                !contractList.hasContractInRenewalWindow()
              ) {
                const namespace = 'overview.highValueActionArea.setUpTasks.addProducts';
                return [
                  new HvaCard({
                    ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                    description: $translate.instant(`${namespace}.description`),
                    header: $translate.instant(`${namespace}.header`),
                    id: 'hva-add-products',
                    onCTAClick: () => {
                      globalModalsManager.open({
                        componentName: 'appAddProductsModal2',
                      });
                    },
                    sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                    svgClass: SVG_CLASS.BUY_PRODUCTS,
                  }),
                ];
              }
              return $q.reject();
            });
        }
        return $q.reject();
      }
    }
  }
})();
