// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Button from '@react/react-spectrum/Button';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Select from '@react/react-spectrum/Select';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import requiredIf from 'common/prop-types/requiredIf';

import './Table.pcss';

/**
 * @awaitingdeprecation - import Pagination from binky-ui
 */
const Pagination = observer(
  ({
    currentPage,
    hasNextToken,
    hasPreviousToken,
    isNextTokenPagination,
    onGoNext = () => {},
    onGoPrevious = () => {},
    onPageSizeChange = () => {},
    pageSizeOptions,
    defaultPageSize = pageSizeOptions[0].value,
    pageSize = defaultPageSize,
    showPageSizeSelector = true,
    totalPages,
  }) => {
    const hasNextPage = isNextTokenPagination ? hasNextToken : currentPage < totalPages;
    const hasPreviousPage = isNextTokenPagination ? hasPreviousToken : currentPage > 1;

    return (
      <div data-testid="pagination" styleName="pagination">
        <div styleName="pagination-buttons">
          <Button
            data-testid="previous-btn"
            disabled={!hasPreviousPage}
            onClick={onGoPrevious}
            quiet
            styleName="pagination-button previous"
          >
            <ChevronLeft styleName="chevron-left" />
            <span>
              <FormattedMessage id="common.table.previous" />
            </span>
          </Button>
          <Button
            data-testid="next-btn"
            disabled={!hasNextPage}
            onClick={onGoNext}
            quiet
            styleName="pagination-button next"
          >
            <span>
              <FormattedMessage id="common.table.next" />
            </span>
            <ChevronRight styleName="chevron-right" />
          </Button>
        </div>
        {showPageSizeSelector && (
          <div className="pagination-size-selector" styleName="pagination-size-selector">
            <span data-testid="pagesize-label">
              <FormattedMessage id="common.table.itemsPerPage" />
            </span>
            <Select
              data-testid="pagesize-select"
              flexible
              onChange={onPageSizeChange}
              options={pageSizeOptions}
              quiet
              styleName="page-selector"
              value={pageSize}
            />
          </div>
        )}
      </div>
    );
  }
);

Pagination.propTypes = {
  currentPage: requiredIf(PropTypes.number, (props) => !props.isNextTokenPagination),
  defaultPageSize: PropTypes.number,
  hasNextToken: requiredIf(PropTypes.bool, (props) => props.isNextTokenPagination),
  hasPreviousToken: requiredIf(PropTypes.bool, (props) => props.isNextTokenPagination),
  isNextTokenPagination: PropTypes.bool,
  onGoNext: PropTypes.func,
  onGoPrevious: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showPageSizeSelector: PropTypes.bool,
  totalPages: requiredIf(PropTypes.number, (props) => !props.isNextTokenPagination),
};

export default Pagination;
