import {GoUrl, OverlayWait} from '@admin-tribe/binky-ui';
import {DialogContainer, Flex, Heading, Switch, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import {SOCIAL_PROVIDERS} from 'features/settings/components/auth-settings-page/AuthenticationPolicies.constants';
import SocialProviderModal from 'features/settings/components/auth-settings-page/social-providers-section/social-providers-modal/SocialProvidersModal';
/**
 * Defines the denied social providers section under the Authentication settings
 */
const SocialProvidersSection = observer(() => {
  const intl = useIntl();

  const {authenticationPoliciesStore} = useAuthSettingsContext();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedSocialProvider, setSelectedSocialProvider] = useState(null);
  const [isEnablingMode, setIsEnablingMode] = useState(null);

  const handleSocialProviderUpdate = (socialProvider) => {
    let isSocialProviderAllowed = false;
    // eslint-disable-next-line default-case -- default case not needed here
    switch (socialProvider) {
      case SOCIAL_PROVIDERS.APPLE:
        isSocialProviderAllowed = authenticationPoliciesStore.isAppleAllowed;
        break;
      case SOCIAL_PROVIDERS.FACEBOOK:
        isSocialProviderAllowed = authenticationPoliciesStore.isFacebookAllowed;
        break;
      case SOCIAL_PROVIDERS.GOOGLE:
        isSocialProviderAllowed = authenticationPoliciesStore.isGoogleAllowed;
        break;
    }

    setSelectedSocialProvider(socialProvider);

    if (isSocialProviderAllowed) {
      setIsEnablingMode(false);
    } else {
      setIsEnablingMode(true);
    }

    // open the confirmation modal
    setIsConfirmationModalOpen(true);
  };

  return (
    <View data-testid="social-providers-section">
      <OverlayWait isLoading={authenticationPoliciesStore.isLoading} showContent>
        <Heading data-testid="titled-section-title" level={2}>
          <FormattedMessage id="settings.authSettings.socialLogin.title" />
        </Heading>

        <View
          backgroundColor="gray-50"
          borderColor="gray-200"
          borderRadius="small"
          borderWidth="thin"
          flexGrow="1"
          height="auto"
          marginBottom="size-300"
          marginTop="size-150"
          width="100%"
        >
          <View padding="size-200">
            <Flex direction="column" gap="size-100" height="100%" padding="size-200">
              <Text>
                <FormattedMessage
                  id="settings.authSettings.socialLogin.description"
                  values={{
                    goUrl: (linkText) => <GoUrl name="ac_authsettings">{linkText}</GoUrl>,
                  }}
                />
              </Text>
              <Switch
                data-testid="APPLE"
                isEmphasized
                isSelected={authenticationPoliciesStore.isAppleAllowed}
                onChange={() => {
                  handleSocialProviderUpdate(SOCIAL_PROVIDERS.APPLE);
                }}
              >
                {intl.formatMessage({
                  id: 'settings.authSettings.socialLogin.APPLE.label',
                })}
              </Switch>
              <Switch
                data-testid="FACEBOOK"
                isEmphasized
                isSelected={authenticationPoliciesStore.isFacebookAllowed}
                onChange={() => {
                  handleSocialProviderUpdate(SOCIAL_PROVIDERS.FACEBOOK);
                }}
              >
                {intl.formatMessage({
                  id: 'settings.authSettings.socialLogin.FACEBOOK.label',
                })}
              </Switch>
              <Switch
                data-testid="GOOGLE"
                isEmphasized
                isSelected={authenticationPoliciesStore.isGoogleAllowed}
                onChange={() => {
                  handleSocialProviderUpdate(SOCIAL_PROVIDERS.GOOGLE);
                }}
              >
                {intl.formatMessage({
                  id: 'settings.authSettings.socialLogin.GOOGLE.label',
                })}
              </Switch>

              <DialogContainer onDismiss={() => setIsConfirmationModalOpen(false)}>
                {isConfirmationModalOpen && (
                  <SocialProviderModal
                    isEnablingMode={isEnablingMode}
                    socialProvider={selectedSocialProvider}
                  />
                )}
              </DialogContainer>
            </Flex>
          </View>
        </View>
      </OverlayWait>
    </View>
  );
});

export default SocialProvidersSection;
