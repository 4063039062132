import {log} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

/**
 * @description Helper function to format the product URL.
 * @param {String} url - unformatted url returned by API (with '{tenantID}').
 * @returns {String} formattedURL - url formatted with activeOrg tenantID (for DMA products).
 */
function formatLinkOutUrl(url) {
  if (url.includes('{{tenantID}}')) {
    const dmaTenantId = getTenantId();
    if (!dmaTenantId) {
      log.error('Invalid product URL');
      return null;
    }
    return url.replace('{{tenantID}}', dmaTenantId);
  }

  return url;
}

/**
 * @description Helper function to find dmaTenantId of a contract.
 * @returns {String} dmaTenantId - id of dma tenant.
 */
function getTenantId() {
  const contractList = rootStore.organizationStore.contractList;
  const contract = contractList.items.find((item) => item?.dmaTenantId);
  return contract?.dmaTenantId;
}

// eslint-disable-next-line import/prefer-default-export -- util file
export {formatLinkOutUrl};
