// THIS FILE IS DEPRECATED
// Please use this file as an example to create a new service for JIL APIs: jilDnsToken.js
// or for other APIs use this file: directory-sync.js

import axios from 'axios';

import AppConstants from '../services/AppConstants';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const authorizationInterceptor = (config) => ({
  ...config,
  headers: {
    Authorization: `Bearer ${AppConstants.accessToken}`,
    ...config.headers,
  },
});

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
function antiCacheInterceptor(config) {
  return config.method === 'get'
    ? {
        ...config,
        params: {
          antiCache: Date.now(),
          ...config.params,
        },
      }
    : config;
}

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const createApiService = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(authorizationInterceptor);
  instance.interceptors.request.use(antiCacheInterceptor);

  return instance;
};

export {createApiService, authorizationInterceptor, antiCacheInterceptor};
