import {PATH_PRODUCT_DETAILS_USERS} from 'features/products/routing/productsPaths';

import help from '../../constants/help';
import {getTeamStockParams} from '../../utils/helpUtils';

/**
 *
 * @returns content for product tab -> team stock
 */
const teamStock = () => {
  const {TOPICS, LINK_TARGET_TYPE, LINK_TYPE, HELP_ITEM_TYPE} = help;
  const {access, id} = getTeamStockParams();
  return {
    access,
    content: [
      {
        links: [
          {
            key: 'help.topics.teamStock.link.one.key',
            locator: PATH_PRODUCT_DETAILS_USERS,
            migrated: true,
            params: {productId: id},
            target: LINK_TARGET_TYPE.APPLICATION,
            type: LINK_TYPE.BUTTON,
          },
          {
            key: 'help.topics.teamStock.link.two.key',
            locator: 'aac_learn_stock_team',
            target: LINK_TARGET_TYPE.GO_URL,
            type: LINK_TYPE.TEXT,
          },
        ],
        text: 'help.topics.teamStock.text',
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: TOPICS.TEAM_STOCK,
    parents: [
      {
        id: TOPICS.PRODUCTS,
      },
    ],
    title: 'help.topics.teamStock.title',
  };
};
export default teamStock;
