import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

// renders a "Helpful link" in the Helpful Resources section (under the "Popular articles") of the Overview page .
const HelpfulLinkItem = ({children, href}) => (
  <Link variant="primary">
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  </Link>
);

HelpfulLinkItem.propTypes = {
  /**
   * The label for the link
   */
  children: PropTypes.string.isRequired,
  /**
   * The link to open on click
   */
  href: PropTypes.string.isRequired,
};

export default HelpfulLinkItem;
