import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  LicenseGroupList,
} from '@admin-tribe/binky';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {ImageIcon} from '@pandora/react-image-icon';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import {
  canEnableAutoAssignmentModal,
  canViewCustomModelUsageCount,
} from 'core/products/access/productAccess';
import {isCustomModelProduct} from 'core/products/utils/customModelUtils';
import {getProductTags} from 'core/products/utils/productTagUtils';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import AutoAssignmentRuleModal from 'features/products/components/auto-assignment-rule-modal/AutoAssignmentRuleModal';
import ProductBanners from 'features/products/components/banners/ProductBanners';
import CustomModelsInfoBar from 'features/products/components/custom-models-info-bar/CustomModelsInfoBar';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';
import ProductPageInfoBar from 'features/products/components/product-page-info-bar/ProductPageInfoBar';
import StockInfoBar from 'features/products/components/stock-info-bar/StockInfoBar';
import ProductDetailsPageActions from 'features/products/pages/product-page/actions/ProductDetailsPageActions';
import {getProductProfileRule} from 'features/products/product-profiles/utils/productProfileUtils';

import ProductTabs from './ProductTabs';

/**
 * Page for Named-user licensed and SDL product details.
 */
const ProductPage = () => {
  const intl = useIntl();
  const {product} = useLoaderData();
  const [productProfileRule, setProductProfileRule] = useState();
  const [autoAssignmentRuleModalOpen, setAutoAssignmentRuleModalOpen] = useState(false);
  const [isRuleDetailsLoading, setRuleDetailsLoading] = useState(false);
  const productTags = useMemo(() => getProductTags(intl, product), [intl, product]);
  const productDisplayName = getProductDisplayName(intl, product);
  useDocumentTitle({defaultTitle: productDisplayName});

  const orgId = rootStore.organizationStore.activeOrgId;

  const fetchConsumableData = useCallback(() => {
    if (product.isGroupConsumable()) {
      return ConsumableSummarizationList.get({
        license_id: product.id,
        organization_id: orgId,
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
      });
    }
    return null;
  }, [orgId, product]);

  const {
    error: consumableError,
    isLoading: isLoadingConsumableData,
    model: consumableSummarizationList,
  } = useAsyncModel({
    loadFn: fetchConsumableData,
  });

  const fetchLicenseGroupList = useCallback(
    () =>
      canEnableAutoAssignmentModal(product)
        ? LicenseGroupList.get({
            orgId,
            product,
          })
        : null, // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies
    []
  );

  const {model: licenseGroupList} = useAsyncModel({
    loadFn: fetchLicenseGroupList,
  });

  // Team products have one license group which isn't shown in the UI so allow
  // "Change auto assignment" from this page using the default profile.
  useEffect(() => {
    if (licenseGroupList?.items.length > 0) {
      setProductProfileRule(getProductProfileRule(licenseGroupList.items[0]));
    }
  }, [licenseGroupList]);

  const onAutoAssignmentRuleModalSave = async () => {
    setAutoAssignmentRuleModalOpen(false);
    await AutoAssignRulesCache.get().refresh();
    // Force ProductPageInfoBar to re-render and update the "Auto assignment rule" scorecard.
    setProductProfileRule(getProductProfileRule(licenseGroupList.items[0]));
  };

  return (
    <>
      <Page
        data-testid="product-details-page"
        isBumpered={!!consumableError}
        isLoading={isLoadingConsumableData || isRuleDetailsLoading}
        showContent={!isLoadingConsumableData}
      >
        <ProductPageBreadcrumbs
          productDisplayName={productDisplayName}
          productOrProductGroupId={product.id}
        />

        <PageBanners>
          <ProductBanners licenseGroupList={licenseGroupList} product={product} />
        </PageBanners>

        <PageHeader title={productDisplayName}>
          <ImageIcon alt="" size="M" src={product.getIcon()} />
          <PageHeaderTagGroup productName={productDisplayName} tags={productTags} />
        </PageHeader>

        <PageActions>
          <ProductDetailsPageActions
            autoAssignModalEnabled={!!productProfileRule}
            openAutoAssignmentRuleModal={() => setAutoAssignmentRuleModalOpen(true)}
            product={product}
          />
        </PageActions>

        <PageInfoBar>
          {isCustomModelProduct(product) && canViewCustomModelUsageCount(product) && (
            <CustomModelsInfoBar product={product} />
          )}
          {product.isLegacyStock() ? (
            <StockInfoBar product={product} />
          ) : (
            <ProductPageInfoBar
              consumableSummarizationList={consumableSummarizationList}
              product={product}
              productProfileRule={productProfileRule}
            />
          )}
        </PageInfoBar>

        <PageContent>
          <ProductTabs product={product} />
        </PageContent>
      </Page>

      {autoAssignmentRuleModalOpen && (
        // Note: getProductProfileRule currently checks a managed cache, AutoAssignRulesCache,
        // rather than make a dynamic API call, as the API code in Pandora is not cached yet.
        // This means that AutoAssignRulesCache must be kept in sync with the AutoAssignmentRuleModal
        // operations which is less than ideal.
        <AutoAssignmentRuleModal
          onRuleAssignmentCancel={() => setAutoAssignmentRuleModalOpen(false)}
          onRuleAssignmentSave={onAutoAssignmentRuleModalSave}
          onShowPageLoading={(isLoading) => setRuleDetailsLoading(isLoading)}
          product={product}
          productProfile={licenseGroupList.items[0]}
          rule={productProfileRule}
        />
      )}
    </>
  );
};

export default ProductPage;
