import {Flex, Grid, Heading, TextField, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';

import EntitlementsList from './entitlements-list/EntitlementsList';
import ServerDetails from './server-details/ServerDetails';

/**
 * Finalize screen for create/edit server flow
 * it sets server name and show details of the server and selected entitlements
 */
const ServerFinalize = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionData, setServerSessionDataValues, setServerSessionSettingValues} =
    packagesServerStore;
  const intl = useIntl();

  // check for saved server name
  useEffect(() => {
    setServerSessionSettingValues('nextButtonEnabled', !!serverSessionData.displayName);
  }, [serverSessionData.displayName, setServerSessionSettingValues]);

  /**
   * Method to check if Server name has error
   * returns {Boolean} true/false
   */
  const shouldShowServerNameError = () =>
    serverSessionData.displayName?.trim() && serverSessionData.displayName.length > 40;

  /**
   * Method to set input value as server name
   */
  const setServerName = (name) => {
    setServerSessionDataValues('displayName', name);
    setServerSessionSettingValues('nextButtonEnabled', !!name);
  };

  return (
    <>
      <View borderBottomWidth="thin" borderColor="gray-500">
        <Flex alignItems="center" marginBottom="size-250" maxWidth="size-4600" position="relative">
          <TextField
            autoFocus
            data-testid="server-name-input"
            errorMessage={intl.formatMessage({
              id: 'packages.createServer.finalize.serverName.error',
            })}
            isRequired
            label={intl.formatMessage({
              id: 'packages.createServer.finalize.serverName',
            })}
            maxLength={41}
            necessityIndicator="icon"
            onChange={setServerName}
            validationState={shouldShowServerNameError() ? 'invalid' : ''}
            value={serverSessionData.displayName}
            width="100%"
          />
        </Flex>
      </View>

      <Grid columnGap="size-1200" columns={['1fr', '1fr']} marginTop="size-150">
        <Heading marginY="size-100" size={4}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.entitlements',
          })}
        </Heading>
        <Heading marginY="size-100" size={4}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.serverDetails',
          })}
        </Heading>
        <EntitlementsList />
        <ServerDetails />
      </Grid>
    </>
  );
});

export default ServerFinalize;
