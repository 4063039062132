import {Flex, Image, Text, View} from '@adobe/react-spectrum';
import {useButton} from '@react-aria/button';
import {useId} from '@react-aria/utils';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import styles from './QuickLink.pcss';
import {dispatchAnalytics} from './quickLinkUtils';

// A template for a quick link button on the overview page
const QuickLink = (props) => {
  const {analyticsOptions, label, iconSrc, Icon, onPress} = props;
  const labelId = useId();
  const buttonRef = useRef();

  const {buttonProps} = useButton(
    {
      ...props,
      onPress: (evt) => {
        dispatchAnalytics(analyticsOptions);
        onPress?.(evt);
      },
    },
    buttonRef
  );

  // extract data-* attributes to allow instrumentation
  const dataAttributes = pickBy(props, (value, propKey) => propKey.startsWith('data-'));

  return (
    // eslint-disable-next-line react/button-has-type -- covered by buttonProps
    <button
      aria-labelledby={labelId}
      {...buttonProps}
      className={styles['quick-link']}
      {...dataAttributes}
      ref={buttonRef}
    >
      <Flex alignItems="center" direction="column" marginBottom="size-50">
        <View
          borderWidth="thin"
          height="size-800"
          margin="size-100"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- style props can not set circle via borderRadius
          UNSAFE_className={
            buttonProps.disabled
              ? styles['quick-link-button-disabled']
              : styles['quick-link-button']
          }
          width="size-800"
        >
          <Flex
            alignItems="center"
            height="100%"
            justifyContent="center"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- style props can not set circle via borderRadius
            UNSAFE_className={
              buttonProps.disabled ? styles['quick-link-icon-disabled'] : styles['quick-link-icon']
            }
          >
            {iconSrc && <Image alt="" objectFit="contain" src={iconSrc} width="size-325" />}
            {Icon}
          </Flex>
        </View>

        <Text
          id={labelId}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font size
          UNSAFE_className={
            buttonProps.disabled ? styles['quick-link-label-disabled'] : styles['quick-link-label']
          }
        >
          {label}
        </Text>
      </Flex>
    </button>
  );
};

QuickLink.propTypes = {
  /**
   * analytics options as expected by quickLinkUtils.dispatchAnalytics
   */
  // eslint-disable-next-line react/forbid-prop-types -- method to pass to has too general an interface
  analyticsOptions: PropTypes.object,
  /**
   * The icon to render for the button
   */
  Icon: PropTypes.node,
  /**
   * A link to an icon to render for the button
   */
  iconSrc: PropTypes.string,
  /**
   * Whether the button is disabled. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The label/text for the button
   */
  label: PropTypes.string.isRequired,
  /**
   * Callback when the button is pressed
   */
  onPress: PropTypes.func,
  /**
   * other props:
   * 1. button props used by useButton
   * 2. data-* attributes to pass along to button
   */
};

export default QuickLink;
