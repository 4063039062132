import {GoUrl, PageBanner} from '@admin-tribe/binky-ui';
import {
  Content,
  ContextualHelp,
  Divider,
  Flex,
  Item,
  Picker,
  Text,
  TextField,
} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import InfoIcon from '@spectrum-icons/workflow/Info';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import {INVALID_SERVER_URL_REGEX, SERVERS_CONSTANTS} from 'features/packages/packagesConstants';

import EntitlementLimits from './entitlement-limits/EntitlementLimits';

/**
 * ConfigureServer screen for create/edit server flow
 * it sets dnsAddress and lanTimeout values
 */
const ConfigureServer = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {
    serverSessionData,
    serverSessionSettings,
    setServerSessionDataValues,
    setServerSessionSettingValues,
  } = packagesServerStore;
  const intl = useIntl();
  const dropdownItems = [
    {
      key: 180,
      label: intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutValue1'}),
    },
    {
      key: 365,
      label: intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutValue2'}),
    },
    {
      key: 0,
      label: intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutValue3'}),
    },
  ];

  /** Setting intial store values */
  useEffect(() => {
    packagesServerStore.setServerSessionSettingValues(
      'nextButtonEnabled',
      serverSessionData.dnsAddress.length > 0
    );
    packagesServerStore.setServerSessionSettingValues('showQuotaRadioButtons', false);
    packagesServerStore.setServerSessionDataValues(
      'activationEnforcementType',
      SERVERS_CONSTANTS.QUOTA.SOFT
    );
  }, [packagesServerStore, serverSessionData]);

  /**
   * Method to validate dns address
   */
  const shouldShowDnsAddressError = () => {
    const regex = new RegExp(INVALID_SERVER_URL_REGEX);
    return serverSessionData.dnsAddress && !regex.test(serverSessionData.dnsAddress.toLowerCase());
  };

  /**
   * Method to set dns address
   */
  const setDnsAddress = (value) => {
    setServerSessionDataValues('dnsAddress', value);
    setServerSessionSettingValues(
      'nextButtonEnabled',
      serverSessionData.dnsAddress && !shouldShowDnsAddressError()
    );
  };

  /**
   * Method to handle lan timeout change
   */
  const onLanTimeoutChange = (key) => {
    setServerSessionDataValues('lanTimeout', key);
  };
  const textFieldLabel = (
    <Flex alignItems="center" direction="row">
      <Text marginEnd="size-75">
        {intl.formatMessage({id: 'packages.createServer.configure.dnsLabel'})}
      </Text>
      <ContextualHelp variant="info">
        <Content>
          <Text>
            {intl.formatMessage({
              id: 'packages.createServer.configure.dnsServerTooltip',
            })}
          </Text>
        </Content>
      </ContextualHelp>
    </Flex>
  );

  const getBannerBody = () =>
    intl.formatMessage(
      {id: 'packages.createServer.editServer.bannerBody'},
      {
        goUrl: (str) => <GoUrl name="lic_help_lan">{str}</GoUrl>,
      }
    );
  return (
    <Flex direction="column">
      {serverSessionSettings.editServerWorkflow && (
        <SpectrumV2Provider>
          <PageBanner
            header={intl.formatMessage({id: 'packages.createServer.editServer.bannerHeader'})}
            removeWithKey={serverSessionData.serverId}
            variant="info"
          >
            {getBannerBody()}
          </PageBanner>
        </SpectrumV2Provider>
      )}
      <Flex direction="column" height="size-1250">
        <TextField
          aria-labelledby={intl.formatMessage({id: 'packages.createServer.serverInfo.title'})}
          autoFocus
          data-testid="server-name-input"
          description={intl.formatMessage({id: 'packages.createServer.configure.dnsDefaultText'})}
          errorMessage={intl.formatMessage({id: 'packages.createServer.configure.dnsError'})}
          label={textFieldLabel}
          onChange={setDnsAddress}
          validationState={shouldShowDnsAddressError() ? 'invalid' : ''}
          value={serverSessionData.dnsAddress}
          width="50%"
        />
      </Flex>
      <Divider marginBottom="size-150" size="S" />
      <Text marginBottom="size-0" marginTop="size-50" UNSAFE_style={{fontWeight: 'bold'}}>
        {intl.formatMessage({
          id: 'packages.createServer.configure.lanTimeoutTitle',
        })}
      </Text>
      <Text marginBottom="size-150">
        {intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutDesc'})}
      </Text>
      <Text id="lan-timeout-dropdown-title">
        {intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutDropdownTitle'})}
      </Text>
      <Picker
        aria-labelledby="lan-timeout-dropdown-title"
        items={dropdownItems}
        onSelectionChange={onLanTimeoutChange}
        selectedKey={serverSessionData.lanTimeout}
      >
        {(item) => <Item key={item.key}>{item.label}</Item>}
      </Picker>
      {(!serverSessionData.lanTimeout || serverSessionData.lanTimeout < dropdownItems[0].key) && (
        <Flex
          alignItems="center"
          data-testid="lan-timeout-contract"
          direction="row"
          marginTop="size-250"
        >
          <InfoIcon
            aria-label={intl.formatMessage({
              id: 'packages.createServer.configure.lanTimeoutContractTip',
            })}
            marginEnd="size-100"
            size="S"
          />
          <Text UNSAFE_style={{fontSize: 'size-175', fontStyle: 'italic'}}>
            {intl.formatMessage({id: 'packages.createServer.configure.lanTimeoutContractTip'})}
          </Text>
        </Flex>
      )}
      {serverSessionSettings.showQuotaRadioButtons && <EntitlementLimits />}
    </Flex>
  );
});
export default ConfigureServer;
