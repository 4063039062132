import {POLICY_ACTIONS} from '@admin-tribe/binky';
import {showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Divider, View, useDialogContainer} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import AppIntegrationsManageUsersModalContentModel from 'features/products/app-integrations/content/AppIntegrationsManageUsersModalContentModel';
import {useAppIntegrationTableContext} from 'features/products/app-integrations/hooks/AppIntegrationTableContext';
import useAppIntegration from 'features/products/app-integrations/hooks/useAppIntegration';

import AppIntegrationsManageUsersTableSection from './AppIntegrationsManageUsersTableSection';

/**
 * Modal for displaying or removing users that have already accepted the policy.
 */
const AppIntegrationsManageUsersModal = ({integrationName, clientId}) => {
  const intl = useIntl();
  const content = useContentEntry(AppIntegrationsManageUsersModalContentModel);
  const dialogContext = useDialogContainer();
  const {refreshList} = useAppIntegrationTableContext();

  // Form and submission state
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch additional details for the app integration (specifically the permissions)
  const {appIntegration, isLoading: isAppIntegrationLoading} = useAppIntegration({
    clientId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  const onCta = useCallback(async () => {
    setHasError(false); // reset error status

    let isSuccessful;
    try {
      setIsSubmitting(true);
      await appIntegration.updateAcceptedUsers(POLICY_ACTIONS.REMOVE, selectedUserIds);
      isSuccessful = true;
    } catch (error) {
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }

    if (isSuccessful) {
      dialogContext.dismiss();
      showSuccessToast(
        intl.formatMessage(
          {id: 'products.appIntegrations.appIntegrationsManageUsersModal.successToast'},
          {count: selectedUserIds.length, integrationName}
        )
      );
      // Refresh the table list. No need to await.
      refreshList();
    }
  }, [appIntegration, dialogContext, integrationName, intl, refreshList, selectedUserIds]);

  const showSpinner = isSubmitting || isAppIntegrationLoading;

  return (
    <ModalDialog
      content={content.search(ModalDialogModel)[0]}
      errorMessage={hasError && intl.formatMessage({id: 'common.toast.default.error'})}
      heightVariant="static"
      id="app-integations-manage-users-modal"
      // Need to null check selectedUserIds because TableSection will return undefined when you unselect all rows
      isCtaDisabled={showSpinner || selectedUserIds.length === 0}
      isLoading={showSpinner}
      // eslint-disable-next-line react/jsx-handler-names -- Using RS function name
      onCancel={dialogContext.dismiss}
      onCta={onCta}
    >
      <ModalHeading>
        {intl.formatMessage(
          {
            id: 'products.appIntegrations.appIntegrationsManageUsersModal.heading',
          },
          {integrationName}
        )}
        <Divider marginY="size-150" size="M" />
      </ModalHeading>
      <ModalContent>
        {appIntegration && (
          <View>
            {intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsManageUsersModal.description',
            })}
            <ul>
              {appIntegration.permissions.map((permission) => (
                <li key={permission.displayName}>{permission.displayName}</li>
              ))}
            </ul>
          </View>
        )}
        <AppIntegrationsManageUsersTableSection
          clientId={clientId}
          onTableSelection={setSelectedUserIds}
        />
      </ModalContent>
    </ModalDialog>
  );
};

AppIntegrationsManageUsersModal.propTypes = {
  /** The app integration client id */
  clientId: PropTypes.string.isRequired,
  /** The app integration name */
  integrationName: PropTypes.string.isRequired,
};

export default AppIntegrationsManageUsersModal;
