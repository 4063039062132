import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './OverviewList.pcss';

// renders an unordered list of items. Follows the same pattern as spectrum list & table views
const OverviewList = ({children, items, ...props}) => {
  // Allow passing in rows via children, or functionally via items prop
  const rows = items || React.Children.toArray(children);

  return (
    <ul
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      className={styles.list}
    >
      {rows.map((item) => (
        <View
          key={item.id ?? item.key}
          borderRadius="regular"
          elementType="li"
          paddingX="size-150"
          paddingY="size-100"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- list style
          UNSAFE_className={styles.cell}
        >
          <Flex gap="size-150">{typeof children === 'function' ? children(item) : item}</Flex>
        </View>
      ))}
    </ul>
  );
};

OverviewList.propTypes = {
  /**
   * aria-label for the list
   */
  'aria-label': PropTypes.string,
  /**
   * id for a label that identifies the list
   */
  'aria-labelledby': PropTypes.string,
  /**
   * Children to render as list items. If passed items prop, children should be
   * a function that returns how to render a row. If items is not passed,
   * each child should be a row node
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * Items to be rendered in the list. An id is required for setting the key
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};

export default OverviewList;
