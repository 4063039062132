const CURRENT_USER_GROUP_LIST_KEY = 'CURRENT_USER_GROUP_LIST_KEY';

const CURRENT_USER_LIST_KEY = 'CURRENT_USER_LIST_KEY';

const DOMAIN_USER_LIST_KEY_PREFIX = 'DOMAIN_USERS_TEMPLATE_';

const STANDARD_TEMPLATE_KEY = 'STANDARD_TEMPLATE_KEY';

export {
  CURRENT_USER_GROUP_LIST_KEY,
  CURRENT_USER_LIST_KEY,
  DOMAIN_USER_LIST_KEY_PREFIX,
  STANDARD_TEMPLATE_KEY,
};
