(function () {
  /**
   * @deprecated
   */
  angular.module('app.src2-route').component('appSrc2Route', {
    controller,
    templateUrl: 'app/src2-route/src2-route.component.html',
  });

  /* @ngInject */
  function controller() {
    /**
     * Empty component, just to give angular something to render
     * We still need to point the angular-ui router to something
     * Or else the nav breaks
     */
  }
})();
