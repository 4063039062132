(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    _,
    $delegate,
    $filter,
    $q,
    $window,
    $translate,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    organizationAccess,
    OrganizationManager,
    productAccess,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.VISIT_ADOBE_IO,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForEnterprise) {
          cache.canViewValueDiscoveryTasksForEnterprise =
            organizationAccess.canViewValueDiscoveryTasksForEnterprise();
        }

        const {canViewValueDiscoveryTasksForEnterprise} = cache;
        return canViewValueDiscoveryTasksForEnterprise
          .then((canView) => (canView ? $q.resolve() : $q.reject()))
          .then(() => OrganizationManager.getProductsForActiveOrg().$promise)
          .then((productList) => {
            if (_.some(productList.items, (product) => productAccess.canAssignDeveloper(product))) {
              const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.visitAdobeIO';
              return [
                new HvaCard({
                  ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                  description: $translate.instant(`${namespace}.description2`),
                  dismissOnCTAClick: true,
                  header: $translate.instant(`${namespace}.header`),
                  id: 'hva-visit-adobeio',
                  isDismissible: true,
                  onCTAClick: () => {
                    $window.open($filter('goUrl')('aac_pop_artt_developers'), '_blank');
                  },
                  sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                  svgClass: SVG_CLASS.ADOBE_IO,
                }),
              ];
            }
            return $q.reject();
          });
      }
    }
  }
})();
