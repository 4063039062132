(function () {
  /**
   * @deprecated should use the React Spectrum Tag
   */
  angular.module('app.widgets.tag').constant('TAG_COLOR', {
    BLUE: 'blue',
    GREEN: 'green',
    GREY: 'grey',
    ORANGE: 'orange',
    RED: 'red',
  });
})();
