/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.main.jobs:appLicenseDeficitReport
 *
 * @description The license deficit report for a bulk operation job result. It
 *   is reached by clicking the "View details" link next to the "not enough
 *   licenses" error in the job details drawer or job results view
 *
 * @binding {String} breadcrumbNavItemListId - id of the navigation list that is
 *   the root of the breadcrumbs for this page
 * @binding {Job} job - reference to job to display license deficit report for
 */
(function () {
  angular.module('app.main.jobs').component('appLicenseDeficitReport', {
    bindings: {
      breadcrumbNavItemListId: '<',
      job: '<',
    },
    controller,
    templateUrl: 'app/main/jobs/results/report/license-deficit-report.component.html',
  });

  /* @ngInject */
  function controller($q, $state, $stateParams, $translate, _, apiUtils, downloadUtils) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      downloadResults,
    });

    ////////

    function $onInit() {
      vm.waitOnPage = vm.job.$promise.then(onBindingLoaded);

      ////////

      function onBindingLoaded() {
        if (vm.job.canShowLicenseDeficitSummary()) {
          vm.lastCrumbName = $translate.instant('widgets.bulkOperations.report.title');
        } else {
          const currentState = $state.$current;
          const previousState = $state.get(currentState.data.breadcrumb.previous);
          $state.go(previousState, $stateParams);
        }
      }
    }

    function downloadResults() {
      const assembledCSV = assembleCSV(vm.job.getLicenseDeficitSummary());
      downloadUtils.downloadExportData(
        apiUtils.csvBlobTransformer(assembledCSV).file,
        vm.job.getLicenseDeficitReportFilename()
      );

      ////////

      function assembleCSV(licenseDeficitSummary) {
        // Add in the localized headers
        let csv = `${$translate.instant(
          'widgets.bulkOperations.report.table.heading.productName'
        )},${$translate.instant(
          'widgets.bulkOperations.report.table.heading.additionalLicenses'
        )}\n`;
        _.forEach(licenseDeficitSummary, (item) => {
          csv += `${item.name},${item.count}\n`;
        });
        return csv;
      }
    }
  }
})();
