import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';

import help from '../../constants/help';
import {hasCreatePackagesAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns {Object} content for product tab -> create packages
 */
const createPackages = () => ({
  access: hasCreatePackagesAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.createPackages.link.one.key',
          locator: PATH_ALL_PACKAGES,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
        {
          key: 'help.topics.createPackages.link.two.key',
          locator: 'aac_learn_packaging',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.createPackages.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.CREATE_PACKAGES,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  title: 'help.topics.createPackages.title',
});

export default createPackages;
