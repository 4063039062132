import {
  PATH_STORAGE_INDIVIDUAL_USER,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
  PATH_STORAGE_LIBRARIES,
  PATH_STORAGE_LIBRARY_DETAILS,
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_REPORTS,
  PATH_STORAGE_REPORTS_SHARED,
  PATH_STORAGE_REPORTS_USERS,
  PATH_STORAGE_ROOT,
  PATH_STORAGE_SHARED_PROJECTS,
  PATH_STORAGE_SHARED_PROJECT_DETAILS,
} from 'features/storage/routing/storagePaths';

const storageRouteMigration = [
  {
    stateName: 'storage.individual-user-folders',
    url: PATH_STORAGE_INDIVIDUAL_USER,
    useReactRoute: true,
  },

  {
    stateName: 'storage.individual-user-folders.user-folders',
    url: PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
    useReactRoute: true,
  },

  {
    stateName: 'storage.individual-user-folders.deleted-user-folders',
    url: PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
    useReactRoute: true,
  },

  {
    stateName: 'storage.overview',
    url: PATH_STORAGE_OVERVIEW,
    useReactRoute: true,
  },

  {
    stateName: 'storage.storage-reports.all',
    url: PATH_STORAGE_REPORTS,
    useReactRoute: true,
  },

  {
    stateName: 'storage.storage-reports-details',
    url: [PATH_STORAGE_REPORTS_USERS, PATH_STORAGE_REPORTS_SHARED],
    useReactRoute: true,
  },

  {
    stateName: 'storage',
    url: PATH_STORAGE_ROOT,
    useReactRoute: true,
  },
  {
    stateName: '', // leaving this blank because there's no corresponding angular state for this path.
    url: PATH_STORAGE_LIBRARIES,
    useReactRoute: true,
  },
  {
    stateName: '', // leaving this blank because there's no corresponding angular state for this path.
    url: PATH_STORAGE_LIBRARY_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: '',
    url: PATH_STORAGE_SHARED_PROJECTS,
    useReactRoute: true,
  },
  {
    stateName: '',
    url: PATH_STORAGE_SHARED_PROJECT_DETAILS,
    useReactRoute: true,
  },
];

export default storageRouteMigration;
