import {
  CONTRACT_BUYING_PROGRAM,
  OFFER_LIST_INTENT,
  OFFER_LIST_MODE,
  OFFER_LIST_SERVICE_PROVIDER,
  OfferList,
  PRICE_POINT,
  PoresOfferList,
  feature,
  getEnterpriseContract,
  getIndirectContract,
} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import {canViewFreeOffers} from 'core/organizations/access/organizationAccess';
import {activeOrgHasESM} from 'core/storage/access/storageAccess';

/**
 * @description Get contract ID of the contract eligible for free offers
 *
 * @returns {String} the contract ID
 */
function getContractId() {
  const contractList = rootStore.organizationStore.contractList;

  let contract = feature.isDisabled('prefer_etla_free_offers')
    ? getIndirectContract(contractList)
    : null;
  if (!contract) {
    contract = getEnterpriseContract(contractList, CONTRACT_BUYING_PROGRAM.ETLA);
  }
  return contract?.id;
}

/**
 * A hook that provides the current organization's free offers.
 *
 * @returns {Object} state - Object with state variables
 *          {OfferList} state.freeOfferList - the organization's free offers
 *          {Error} state.error - the error if fetching fails
 *          {Boolean} state.isLoading - true if in the middle of fetching data
 */
const useFreeOffers = () => {
  const fetchOfferList = useCallback(async () => {
    // this hook is only usable if temp_ctir_18512_product_offers is enabled, and we can view free offers
    if (feature.isDisabled('temp_ctir_18512_product_offers') || !canViewFreeOffers())
      return undefined;

    // run the fetching of free offers from PORES Api and fetching whether the org has ESM concurrently
    let freeOfferList, orgHasESM;

    if (feature.isDisabled('temp_ctir_21074_oms_removal')) {
      [freeOfferList, orgHasESM] = await Promise.all([
        OfferList.get({
          contractId: getContractId(),
          orgId: rootStore.organizationStore.activeOrgId,
          queryParams: {
            mode: OFFER_LIST_MODE.PURCHASE,
            price_point: PRICE_POINT.FREE,
          },
        }),
        activeOrgHasESM(),
      ]);
    } else {
      [freeOfferList, orgHasESM] = await Promise.all([
        PoresOfferList.get({
          contractId: getContractId(),
          orgId: rootStore.organizationStore.activeOrgId,
          queryParams: {
            intent: OFFER_LIST_INTENT.FREE_OFFER_DISCOVERY,
            price_point: PRICE_POINT.FREE,
            service_providers: [
              OFFER_LIST_SERVICE_PROVIDER.MERCHANDISING,
              OFFER_LIST_SERVICE_PROVIDER.PRODUCT_ARRANGEMENT,
            ],
          },
        }),
        activeOrgHasESM(),
      ]);
    }

    freeOfferList.filterOnQualifyingFreeOffers({
      organization: rootStore.organizationStore.activeOrg,
      orgHasESM,
      ownedProducts: rootStore.organizationStore.productList,
    });

    return freeOfferList;
  }, []);

  const {
    error,
    isLoading,
    model: freeOfferList,
    updateModel,
  } = useAsyncModel({
    loadFn: fetchOfferList,
  });

  return {
    error,
    freeOfferList,
    isLoading,
    refreshFreeOffers: () => {
      updateModel(fetchOfferList);
    },
  };
};

export default useFreeOffers;
