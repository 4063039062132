import {useWizardContext} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {createContext, useEffect} from 'react';

const WizardAnalyticsContext = createContext({});

/**
 * @description Provide analytics support within WizardTrigger.
 * @example
 *   <WizardTrigger steps={STEPS}>
 *     {({currentStep, dispatch}) => (
 *       <WizardAnalyticsContextProvider
 *         onDispatchPageAnalytics={dispatchWizardStepAnalytics}>
 *           ...
 */
const WizardAnalyticsContextProvider = ({children, onDispatchPageAnalytics}) => {
  const {currentStep} = useWizardContext();

  // Ensure that page analytics is only sent once when wizard step changes
  useEffect(() => {
    onDispatchPageAnalytics({wizardStep: currentStep});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only currentStep is needed
  }, [currentStep]);

  return <WizardAnalyticsContext.Provider>{children}</WizardAnalyticsContext.Provider>;
};

WizardAnalyticsContextProvider.propTypes = {
  /**
   * Components to provide analytics context for
   */
  children: PropTypes.node.isRequired,
  /**
   * Callback to dispatch analytics page change.
   * Params: {wizardStep: current wizard step}
   * Example: onDispatchPageAnalytics({wizardStep: 1});
   */
  onDispatchPageAnalytics: PropTypes.func.isRequired,
};

export {WizardAnalyticsContext, WizardAnalyticsContextProvider};
