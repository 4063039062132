import {StatusLight} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import './SyncSettingsModal.pcss';

const EXTERNALLY_MANAGED = {
  OFF: {
    label: 'settings.sync.syncSettingsModal.allowEditing.status.ALLOWED',
    lightVariant: 'negative',
  },
  ON: {
    label: 'settings.sync.syncSettingsModal.allowEditing.status.NOT_ALLOWED',
    lightVariant: 'positive',
  },
};

const ExternallyManagedStatus = observer(({isExternallyManaged}) => {
  const statusLightConfig = isExternallyManaged ? EXTERNALLY_MANAGED.ON : EXTERNALLY_MANAGED.OFF;

  return (
    <StatusLight
      // Needed for uniform spacing of both StatusLights in SyncSettingsModal
      marginBottom="14px"
      role="status"
      variant={statusLightConfig.lightVariant}
    >
      <FormattedMessage id={statusLightConfig.label} />
    </StatusLight>
  );
});

export default ExternallyManagedStatus;
export {EXTERNALLY_MANAGED};
