import {useMemo} from 'react';

import {ATTRIBUTE_MAPPINGS} from 'features/settings/common/constants/attributeMappings';

/** Returns the relevant attribute mappings labels for a given IdP  */
const useAttributeMappingsLabels = (idp) =>
  useMemo(() => {
    if (idp.isSaml) {
      return ATTRIBUTE_MAPPINGS.SAML;
    }
    if (idp.isAzure) {
      return ATTRIBUTE_MAPPINGS.AZURE;
    }
    if (idp.isOidc) {
      return ATTRIBUTE_MAPPINGS.OIDC;
    }
    return null;
  }, [idp]);

export default useAttributeMappingsLabels;
