(function () {
  /**
   * @deprecated with temp_react_port_users - see jilAssetMigrations.js
   *
   * @ngdoc service/provider
   * @name jilUsers
   * @description defines service to manage jil user resources
   */
  angular.module('app.core.api.jil').provider('jilAssetMigrations', jilAssetMigrationsProvider);

  let url;

  /* @ngInject */
  function jilAssetMigrationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        migrations: getAssetMigrationsResource(),
      };

      return service;

      ///////////

      function getAssetMigrationsResource() {
        return $resource(
          `${url}/:orgId\\:migrate.assets`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            export: {
              headers: {
                Accept: 'text/csv,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
