(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @description a filter to display the timezone string for a support ticket
   * @param {Number} timezone a SUPPORT_TICKET_TIMEZONE's DYNAMICS_ID
   * @returns {String} timezone string
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportTicketTimezone', appSupportTicketTimezoneFilter);

  /* @ngInject */
  function appSupportTicketTimezoneFilter($translate, _, moment, SUPPORT_TICKET_TIMEZONE) {
    return filterFn;

    function filterFn(timezone) {
      const timezoneKey = _.findKey(SUPPORT_TICKET_TIMEZONE, {DYNAMICS_ID: timezone});
      const timezoneObj = SUPPORT_TICKET_TIMEZONE[timezoneKey];

      const timezoneName = $translate.instant(
        `widgets.support.supportTicketTimezoneFilter.timezoneName.${timezoneKey}`
      );
      // e.g. formats '60' as '+01:00'
      const utcOffset = moment().utcOffset(timezoneObj.UTC_OFFSET).format('Z');
      return $translate.instant(
        'widgets.support.supportTicketTimezoneFilter.timezoneWithOffset',
        {timezoneName, utcOffset},
        null,
        null,
        // Prevent "US & Canada" (for example) being turned into "US &amp; Canada"
        'sceParameters'
      );
    }
  }
})();
