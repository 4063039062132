import binky from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import {getUnassignedCancellableLicensesCount} from '../../SelfCancelUtils';
import useProductUsersForCancellation from '../../hooks/use-product-users-for-cancellation/useProductUsersForCancellation';
import SelectLicensesTable from '../select-licenses-table/SelectLicensesTable';

/**
 * Seat Selector Item Body component which handles the fetching of user to cancel and the unassigned
 * licenses count to pass to SelectLicensesTable. Will be used by Accordion and single product views.
 */
const SeatSelectorItemBody = ({
  contract,
  selectedLicenses = [],
  onAllLicensesSelectedChange,
  onSelectedSeatsChange,
  product,
}) => {
  // Load the users array for Cancellation associated with the Product in the Org
  const {cancellableUsers, isLoading} = useProductUsersForCancellation({
    orgId: contract.orgId,
    productId: product.id,
  });

  const onSelectedLicensesChange = (newSelection) => {
    onSelectedSeatsChange(product.id, newSelection);
  };

  // Get the number of unassigned licenses that can be cancelled
  const cancellableUnassignedLicensesCount = getUnassignedCancellableLicensesCount(product);

  return (
    <SelectLicensesTable
      assignedUsers={cancellableUsers}
      isLoading={isLoading}
      onAllLicensesSelectedChange={onAllLicensesSelectedChange}
      onSelectedLicensesChange={onSelectedLicensesChange}
      selectedLicenses={selectedLicenses}
      unassignedLicensesCount={cancellableUnassignedLicensesCount}
    />
  );
};

SeatSelectorItemBody.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(binky.models.contract.Contract).isRequired,
  /**
   * Handler that is called when the number of selected licenses changes in order
   * to determine if all licenses are selected.
   */
  onAllLicensesSelectedChange: PropTypes.func,
  /**
   * Handler that is called when the selected licenses for the product changes.
   */
  onSelectedSeatsChange: PropTypes.func.isRequired,
  /**
   * The list of org's products.
   */
  product: PropTypes.instanceOf(binky.services.product.Product).isRequired,
  /**
   * The array of initially selected licenses (controlled).
   */
  selectedLicenses: PropTypes.arrayOf(PropTypes.string),
};

export default SeatSelectorItemBody;
