// The numbers are the same for business(team),
// schools and universities, and device licensing.
const TEAM_CALL_CENTER_PHONE_NUMBER = {
  AT: '0800 291932', // Austria
  AU: '1800 426 570', // Australia
  BE: '0800 80982', // Belgium
  BG: '+44 203 0277 764', // Bulgaria
  BO: '+1-408-454-2936', // Bolivia
  CA: '+1 800-443-8158', // Canada
  CH: '0800 000 890', // Switzerland
  CY: '+44 203 0277 764', // Cyprus
  CZ: '800 701472', // Czech Republic
  DE: '0800 7239761', // Germany
  DK: '802 06016', // Denmark
  DO: '+1-408-454-2936', // Dominican Republic
  EE: '+44 203 0277 764', // Estonia
  ES: '900 810339', // Spain
  FI: '0800 95263', // Finland
  FR: '0805 542592', // France
  GB: '0800 3892 041', // Great Britain
  GR: '+44 203 0277 764', // Greece
  HR: '+44-20-7365-0735', // Croatia
  HU: '+44 203 0277 764', // Hungary
  IE: '1800 946197', // Ireland
  IT: '800 919208', // Italy
  JA: '0120-921-104', // Japan
  JP: '0120-921-104', // Japan
  KE: '+44-20-7365-0735', // Kenya
  LK: '+65-6622-1432', // Sri Lanka
  LT: '+44 203 0277 764', // Lithuania
  LU: '800 27340', // Luxembourg
  LV: '+44 203 0277 764', // Latvia
  MO: '+852 3071-4922', // Macau SAR of China
  MT: '+44 203 0277 764', // Malta
  MU: '+44-20-7365-0735', // Mauritius
  MX: '800 925 0339', // Mexico
  NG: '+44-20-7365-0735', // Nigeria
  NL: '0800 0200164', // Netherlands
  NO: '800 36 961', // Norway
  NZ: '0508 089 111', // New Zealand
  PA: '+1-408-454-2936', // Panama
  PL: '800 702434', // Poland
  PT: '800 206708', // Portugal
  PY: '+1-408-454-2936', // Paraguay
  RO: '+44 203 0277 764', // Romania
  ROW: '+44 203 0277 764', // Rest of World
  SE: '0200 81 03 29', // Sweden
  SI: '+44 203 0277 764', // Slovenia
  SK: '+44 203 0277 764', // Slovakia
  SV: '+1-408-454-2936', // El Salvador
  TT: '800-685-3570', // Trinidad and Tobago
  US: '866-494-3192', // United States
  UY: '+1-408-454-2936', // Uruguay
  VN: '+65-6622-1432', // Vietnam
  ZA: '0800 007031', // South Africa
};

export {TEAM_CALL_CENTER_PHONE_NUMBER};
