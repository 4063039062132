import {generatePath, redirect} from 'react-router-dom';

import {isOrgId} from 'common/utils/orgUtils';
import rootStore from 'core/RootStore';
import {buildSequentialLoader} from 'core/router/loaders';
import {PATH_OVERVIEW} from 'features/overview/routing/overviewPaths';

/**
 * Redirects the user to the Overview page if they are on /
 * @param {import('react-router-dom').LoaderFunctionArgs} loaderData
 * @returns {Response | null}
 */
const redirectToOverviewLoader = ({request}) => {
  const requestPathname = new URL(request.url).pathname;
  const splitPathname = requestPathname.split('/');
  const lastPathSegment = splitPathname.slice(-1)[0];

  if (requestPathname === '/' || isOrgId(lastPathSegment)) {
    return redirect(generatePath(PATH_OVERVIEW, {orgId: rootStore.organizationStore.activeOrgId}));
  }

  return null;
};

/**
 * This loader is responsible for:
 * 1) Redirecting the user to the identity setup page if necessary
 * 2) Redirecting the user to the Overview page if they are on / or /:orgId
 * @returns {() => Promise<Response | null>}
 */
const rootLoader = buildSequentialLoader(redirectToOverviewLoader);

// eslint-disable-next-line import/prefer-default-export -- Only method for now
export {rootLoader};
