import {feature} from '@admin-tribe/binky';
import {Checkbox, Flex, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {isMacTypePlatform} from 'features/packages/adminToolsUtils';
import {CREATE_PACKAGE} from 'features/packages/packagesConstants';

import {useCreatePackageModalContext} from '../CreatePackageModalContext';

const FlatPackageCheckbox = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();
  const {packageCreateObj, pkgSessionSettings, selectedProducts, setPackageCreateObjValues} =
    createPackageModalStore;

  const isLicenseOnlyPackage = () =>
    pkgSessionSettings.packagingMode === CREATE_PACKAGE.MODE.FRL &&
    selectedProducts.length === 0 &&
    !packageCreateObj.ausstOverride &&
    !packageCreateObj.rumEnabled &&
    !packageCreateObj.enableExtenstions;

  return (
    feature.isEnabled('temp_packages_flat_templates') &&
    !isLicenseOnlyPackage() &&
    isMacTypePlatform(packageCreateObj.platform) && (
      <Flex alignItems="center" marginTop="size-100">
        <Checkbox
          isSelected={!!packageCreateObj.flatPackageEnabled}
          onChange={() =>
            setPackageCreateObjValues('flatPackageEnabled', !packageCreateObj.flatPackageEnabled)
          }
        >
          <Text>
            {intl.formatMessage({
              id: 'packages.createPackage.options.createFlatPackage',
            })}
          </Text>
        </Checkbox>
        <View marginStart="calc(size-100*(-1))">
          <GoUrl name="ent_learnmore_flat_package">
            {intl.formatMessage({
              id: 'packages.createPackage.options.learnMoreAboutFlatPackage',
            })}
          </GoUrl>
        </View>
      </Flex>
    )
  );
});

export default FlatPackageCheckbox;
