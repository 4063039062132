import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import SteppedViewHeader from './SteppedViewHeader';

const SteppedView = ({currentStep, children, ...styleProps}) => {
  const childrenArray = React.Children.toArray(children);

  // find the header
  const header = childrenArray.find((item) => item.type === SteppedViewHeader);

  // find the current step to render
  const renderedStep = childrenArray.find((child) => child.props.id === currentStep);

  return (
    <View {...styleProps}>
      {header}
      {React.cloneElement(renderedStep)}
    </View>
  );
};

SteppedView.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string})),
};

export default SteppedView;
