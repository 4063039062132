import {DialogContainer} from '@adobe/react-spectrum';
import {TableActions, useTableSectionContext} from '@pandora/react-table-section';
import React, {useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import {useAccessRequestListContext} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import AcceptAccessRequestConfirmationModal from 'features/settings/components/access-requests/accept-access-request-confirmation-modal/AcceptAccessRequestConfirmationModal';
import RejectAccessRequestConfirmationModal from 'features/settings/components/access-requests/reject-access-requests-confirmation-modal/RejectAccessRequestsConfirmationModal';

const DIALOGS = {
  ACCEPT_ALL_REQUEST_MODAL: 'ACCEPT_ALL_REQUEST_MODAL',
  ACCEPT_REQUEST_MODAL: 'ACCEPT_REQUEST_MODAL',
  REJECT_REQUEST_MODAL: 'REJECT_REQUEST_MODAL',
};

const AccessRequestsListTableActions = () => {
  const {reloadTrusteeList: reloadAccessRequestList, trusteeList} = useAccessRequestListContext();
  const {reloadTrusteeList} = useTrusteeListContext();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();

  const [modalId, setModalId] = useState();

  const onConfirmReject = () => {
    tableSectionUtils.clearSelectedItems();
    reloadAccessRequestList();
  };

  const onConfirmAccept = () => {
    tableSectionUtils.clearSelectedItems();
    // we want to reload the list of access requests after accepting request
    reloadAccessRequestList();

    reloadTrusteeList();
  };

  const canManageIdentityConfig = canChangeIdentityConfig();

  const contextualHelp = useMemo(
    () => (canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />),
    [canManageIdentityConfig]
  );

  return (
    <>
      <TableActions>
        <ButtonWithContextualHelp
          contextualHelp={contextualHelp}
          data-testid="accept-all-access-request-button"
          isDisabled={trusteeList.trusteeListData.items.length === 0 || !canManageIdentityConfig}
          onPress={() => setModalId(DIALOGS.ACCEPT_ALL_REQUEST_MODAL)}
          variant="cta"
        >
          <FormattedMessage id="settings.accessRequestsTable.actions.acceptAll" />
        </ButtonWithContextualHelp>
        <ButtonWithContextualHelp
          contextualHelp={contextualHelp}
          data-testid="accept-access-request-button"
          isDisabled={tableSectionState.selectedItems.length === 0 || !canManageIdentityConfig}
          onPress={() => setModalId(DIALOGS.ACCEPT_REQUEST_MODAL)}
          variant="cta"
        >
          <FormattedMessage id="settings.accessRequestsTable.actions.accept" />
        </ButtonWithContextualHelp>
        <ButtonWithContextualHelp
          contextualHelp={contextualHelp}
          data-testid="reject-access-request-button"
          isDisabled={tableSectionState.selectedItems.length === 0 || !canManageIdentityConfig}
          onPress={() => setModalId(DIALOGS.REJECT_REQUEST_MODAL)}
          variant="negative"
        >
          <FormattedMessage id="settings.accessRequestsTable.actions.reject" />
        </ButtonWithContextualHelp>
      </TableActions>
      <DialogContainer onDismiss={() => setModalId(null)}>
        {modalId === DIALOGS.REJECT_REQUEST_MODAL && (
          <RejectAccessRequestConfirmationModal
            accessRequests={tableSectionState.selectedItems}
            onConfirm={onConfirmReject}
          />
        )}

        {(modalId === DIALOGS.ACCEPT_REQUEST_MODAL ||
          modalId === DIALOGS.ACCEPT_ALL_REQUEST_MODAL) && (
          <AcceptAccessRequestConfirmationModal
            accessRequests={
              modalId === DIALOGS.ACCEPT_REQUEST_MODAL
                ? tableSectionState.selectedItems
                : trusteeList.trusteeListData.items
            }
            onConfirm={onConfirmAccept}
          />
        )}
      </DialogContainer>
    </>
  );
};

AccessRequestsListTableActions.displayName = 'AccessRequestsListTableActions';

export default AccessRequestsListTableActions;
