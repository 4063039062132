(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(renewProductsRoutesConfig);

  /* @ngInject */
  function renewProductsRoutesConfig(
    $stateProvider,
    _,
    RENEW_PRODUCTS_MODAL2_ID,
    RENEW_PRODUCTS_MODAL2_ROUTE_ALLOWED_STEPS,
    RENEW_PRODUCTS_MODAL2_STATES
  ) {
    $stateProvider.state('account.account.renew-products', {
      data: {
        // Prevent analytics page hit from being fired for deep linking
        skipAnalytics: true,
        title: 'account.renewProducts.title',
      },
      // Using dynamic: true, so changes to the query params will not cause the state to be entered/exited.
      // The resolves will not be re-fetched, nor will views be reloaded.
      dynamic: true,
      // Callback function for lifecycle hook, which is invoked (during a transition) when a specific state is being entered.
      // Used to prevent access to the flow if user doesn't have permissions, by redirecting to parentRoute.
      onEnter: [
        '$transition$',
        'feature',
        'OrganizationManager',
        function ($transition$, feature, OrganizationManager) {
          function redirectToParentRoute() {
            return $transition$.router.stateService.target(
              'account.account',
              $transition$.params()
            );
          }

          // Redirect to parent page if admin doesn't have access to the Renew Products modal
          return OrganizationManager.getContractsForActiveOrg()
            .$promise.then((contractList) =>
              _.find(contractList.items, ['id', $transition$.params().contractId])
            )
            .then((contract) => {
              // See https://git.corp.adobe.com/admin-tribe/onesie/blob/4b2c3473644083ab67435c4c7dbf49940abbbb0c/src/app/widgets/contracts/contract-info.component.js#L180-L181
              // and https://git.corp.adobe.com/admin-tribe/onesie/blob/4b2c3473644083ab67435c4c7dbf49940abbbb0c/src/app/widgets/contracts/renewal-detail-section/renewal-detail-section.component.js#L90-L93
              const canRenewProducts =
                contract && contract.isRenewableDirectContract() && contract.isInRenewalWindow();
              if (canRenewProducts) {
                return true;
              }
              return redirectToParentRoute();
            })
            .catch(redirectToParentRoute);
        },
      ],
      // Callback function for lifecycle hook, which is invoked (during a transition) when a specific state is being exited.
      // It will be use to clean up, in case panelManager still thinks the modal was still open. This callback can be
      // triggered by the user clicking close button in the modal, which case panelManager.close was already executed.
      // Also, when the user navigates back via browser's back button, this callback will be executed and in this case
      // the component will be unmounted but the panelManager may still have the modal as open and for that reason we
      // execute panelManager.close.
      onExit: [
        'panelManager',
        function (panelManager) {
          if (panelManager.isOpen(RENEW_PRODUCTS_MODAL2_ID)) {
            panelManager.close(RENEW_PRODUCTS_MODAL2_ID);
          }
        },
      ],
      // Callback function for lifecycle hook, which is invoked (during a transition) for a specific state that was previously active
      // will remain active (is not being entered nor exited). It will be use to sync cart items in the URL.
      // When a change in the cart items or the modal state happens, onCartItemsChange and onModalStateChange will force a state change.
      // This state change will cause a retain lifecycle event, which will trigger this callback, where we add the cart items to the URL,
      // outside of UI Router URL handling.
      onRetain: [
        '$location',
        '$state',
        '$timeout',
        'ProductPurchaseHelper',
        function ($location, $state, $timeout, ProductPurchaseHelper) {
          // $timeout is needed since the transition didn't end and the URL didn't update yet
          $timeout(() => {
            // At this point, the current URL doesn't contain cartItems query params. We need to add them if present in state param
            if ($state.params.cartItems) {
              // Convert cartItems array into object with key of the form 'items[index][key]'
              const cartItemsLocationSearch =
                ProductPurchaseHelper.convertCartItemsToLocationSearch($state.params.cartItems);
              // Append items[index][key]=value query params to URL without creating a new history record
              // eslint-disable-next-line lodash/prefer-lodash-method
              $location.search(_.assign({}, $location.search(), cartItemsLocationSearch)).replace();
            }
          });
        },
      ],
      // Component bindings for appRenewProductsModal2. Using Inline Array Annotation to access dependencies. The return value could be
      // a promise resolving to a value or a function, which will be event triggered by appRenewProductsModal2 based on lifecycle events
      // or user actions.
      resolve: {
        // The contract model matching the contractId route param
        contract: [
          '$stateParams',
          'OrganizationManager',
          ($stateParams, OrganizationManager) =>
            OrganizationManager.getContractsForActiveOrg().$promise.then((contractList) =>
              _.find(contractList.items, ['id', $stateParams.contractId])
            ),
        ],
        // Event triggered by appRenewProductsModal2 to signal a change in cartItems. This change may occur based on initial load and
        // execution of ProductPurchaseHelper.getProductBuckets or as a result of user interactions updating the cart items
        // or cart items quantities. It will be used to force a state change for the cartItems params. This state change will result
        // in a retain lifecycle event.
        onCartItemsChange: [
          '$state',
          ($state) => (cartItems) => {
            // Will go to current state with updated cartItems param and also replace the last history record
            $state.go(
              '.',
              {
                // In order to force the state change, cartItems param should have an immutable update
                cartItems: validCartItemsForState(cartItems, $state.params.step)
                  ? _.map(cartItems, (item) => _.pick(item, ['offer_id', 'numberSelected']))
                  : null,
              },
              {inherit: true, location: 'replace'}
            );
          },
        ],
        // Event triggered by appRenewProductsModal2 to signal the modal was closed. It will be used to update the URL
        // with the parent route
        onClosed: [
          '$state',
          ($state) => () => {
            // Will go to the parent state with current params
            $state.go('account.account', $state.params);
          },
        ],
        // Event triggered by appRenewProductsModal2 to signal $onInit was completed. It will be used to open the modal
        // using lastModalId as it's modal id and passing opening params to onOpen method in appRenewProductsModal2.
        // The opening params will contain the initial cartItems or step in the flow from the URL, if present
        // and initial configuration for the route, such as deepLinkingState or isAddLicenses.
        onInitialized: [
          '$location',
          '$stateParams',
          'panelManager',
          'ProductPurchaseHelper',
          ($location, $stateParams, panelManager, ProductPurchaseHelper) => () => {
            const {step} = $stateParams;
            let {cartItems} = $stateParams;
            if (_.isEmpty(cartItems)) {
              // Convert query params of the form 'items[index][key]' to cartItems array
              cartItems = ProductPurchaseHelper.convertLocationSearchToCartItems(
                $location.search()
              );
            }
            panelManager.open(RENEW_PRODUCTS_MODAL2_ID, {
              analyticsModalSourcePage: 'account',
              deepLinkingCartItems: validCartItemsForState(cartItems, step) ? cartItems : undefined,
              landingState: RENEW_PRODUCTS_MODAL2_ROUTE_ALLOWED_STEPS[step]
                ? step
                : RENEW_PRODUCTS_MODAL2_STATES.MODIFY_RENEWAL_ORDER,
            });
          },
        ],
        // Event triggered by appRenewProductsModal2 to signal a modal internal state change. This change may occur when
        // the initial state is determined or the user interacts with the modal navigating through its flow. It will be
        //  used to force a state change for the step params. This state change will result in a retain lifecycle event.
        onModalStateChange: [
          '$state',
          ($state) => (modalState) => {
            const isAllowedModalState = RENEW_PRODUCTS_MODAL2_ROUTE_ALLOWED_STEPS[modalState];
            if ($state.params.step !== modalState && isAllowedModalState) {
              // Will go to current state with updated step param and also replace the last history record
              $state.go(
                '.',
                {
                  step: modalState,
                },
                {inherit: true, location: 'replace'}
              );
            }
          },
        ],
      },

      // The url definition relative to the parent route. It will contain explicit 'step' query param, as well as
      // paypal, token & mockUserId, needed to maintain them between internal routes navigation
      url: '/renew-products?contractId&step&paypal&token&mockUserId',
      // eslint-disable-next-line sort-keys -- so url and params are close, since they are related
      params: {
        // The cartItems state param, which will not be added to the URL by UI-Router. Since cartItems format from
        // Unified Checkout doesn't adhere to UI Router, cartItems will be treated as a hidden state param and will
        // be added to the URL via onRetain lifecycle event, by adding the special query params.
        cartItems: {
          array: false,
        },
        // The current step in the flow as query param in the URL, unless it matches the default landing step
        step: {
          squash: true, // The parameter's default value is omitted from the URL
          value: RENEW_PRODUCTS_MODAL2_STATES.MODIFY_RENEWAL_ORDER,
        },
      },
      views: {
        'modal@account.account': {
          bindings: {
            onCartItemsChange: 'onCartItemsChange(cartItems)',
            onModalStateChange: 'onModalStateChange(modalState)',
          },
          component: 'appRenewProductsModal2',
        },
      },
    });

    /////////

    function validCartItemsForState(cartItems, modalState) {
      return !_.isEmpty(cartItems) && modalState !== RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER
        ? cartItems
        : null;
    }
  }
})();
