(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories')
    .constant('TRUSTED_DIRECTORY_DRAWER_ID', 'trusted-directory-drawer-id')
    .constant('TRUSTED_DIRECTORY_DELETE_MODAL_ID', 'delete-trust-modal')
    .constant('REVOKED_DIRECTORY_REMOVE_MODAL_ID', 'remove-revoked-trust-modal');
})();
