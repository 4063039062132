import {ActionButton, Flex, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {TruncatedText} from '@pandora/react-truncated-text';
import Alert from '@spectrum-icons/workflow/Alert';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import CloseIcon from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * This component is used to show server authorization file information.
 */
const FileInfo = ({fileInfoObject, onDismiss}) => {
  const intl = useIntl();

  function getErrorString() {
    if (fileInfoObject.hasFileTypeError) {
      return intl.formatMessage({
        id: 'packages.createServerModal.authorizeServer.invalidFileType',
      });
    }
    if (fileInfoObject.hasFileSizeError && !fileInfoObject.hasFileTypeError) {
      return intl.formatMessage(
        {id: 'packages.createServerModal.authorizeServer.invalidFileSize'},
        {size: rootStore.packagesUiConstantsStore.maxAtoFileSizeInMb}
      );
    }
    if (fileInfoObject.hasValidationError) {
      return intl.formatMessage(
        {id: 'packages.createServerModal.authorizeServer.fileCorruptError'},
        {fileName: fileInfoObject.name}
      );
    }

    return intl.formatMessage(
      {id: 'packages.createServerModal.authorizeServer.fileUploadError'},
      {fileName: fileInfoObject.name}
    );
  }

  const showErrorTooltip =
    fileInfoObject.hasFileTypeError ||
    fileInfoObject.hasFileSizeError ||
    fileInfoObject.hasValidationError ||
    fileInfoObject.hasUploadError;

  return (
    <View
      backgroundColor="gray-50"
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      height="size-550"
      maxWidth="size-6000"
      width="100%"
    >
      <Flex alignItems="center" data-testid="text-card-wrapper" height="size-550">
        <View data-testid="left-icon" marginStart="size-150" marginTop="size-50">
          <AttachIcon size="S" />
        </View>
        <View flex="1" marginStart="size-125" overflow="hidden">
          <TruncatedText dataTestId="text" textContent={fileInfoObject.name} />
        </View>
        {showErrorTooltip ? (
          <TooltipButton
            data-testid="invalid-code-error"
            hoverText={getErrorString()}
            isQuiet
            marginStart="size-65"
            variant="action"
          >
            <Alert size="S" UNSAFE_style={{fill: 'var(--spectrum-global-color-red-600)'}} />
          </TooltipButton>
        ) : (
          <Checkmark color="positive" marginEnd="size-65" size="S" />
        )}
        <ActionButton
          data-testid="right-button"
          isQuiet
          marginEnd="size-100"
          marginStart="auto"
          onPress={onDismiss}
        >
          <CloseIcon aria-label={intl.formatMessage({id: 'common.modal.buttons.close'})} size="S" />
        </ActionButton>
      </Flex>
    </View>
  );
};

FileInfo.propTypes = {
  /**
   * Object containing the information about authorization file
   */
  fileInfoObject: PropTypes.instanceOf(Object),
  /**
   * Callback function to handle close functionality of FileInfo component
   */
  onDismiss: PropTypes.func,
};

export default FileInfo;
