// eslint-disable-next-line eslint-comments/disable-enable-pair -- unblock REBA launch
/* eslint-disable max-lines -- unblock REBA launch */
import {
  AuthenticatedUser,
  CONTRACT_STATUS,
  CUSTOMER_SEGMENT,
  Locale,
  SALES_CHANNEL,
  configStore,
  eventBus,
  feature,
  log,
  navBus,
} from '@admin-tribe/binky';
import {OverlayWait, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {WorkflowStatus} from '@pandora/commerce-core-types';
import {SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MessageType} from '@pandora/react-app-context-provider';
import {SystemMessage} from '@pandora/react-commerce-core-app-context-provider';
import {ModalContainer, ModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import {
  ADD_PRODUCT_RTP2_FEATURES,
  ADD_PRODUCT_WORKFLOWS,
  STATUS_CODE,
} from './AddProduct.constants';
import styles from './AddProductModalWrapper.pcss';

const ADD_PRODUCT_MINI_APP_NAME = 'AddProductMiniApp';
const ADD_PRODUCT_MINI_APP_URL_ENV_PARAM = 'services.addProductMiniApp';

// Temp constants which should ideally be part if SystemMessage
const PAGE_LOAD_COMPLETE_MESSAGE = 'PageLoadComplete';
const ORDER_PLACED_AND_PROCESSING_NEXT_STEPS = 'OrderPlacedAndProcessingNextSteps';
const ASSIGN_LICENSE_DONE = 'ASSIGN_LICENSE_DONE';
const ASSIGN_LICENSE_CANCELLED = 'ASSIGN_LICENSE_CANCELLED';

/**
 * If the client passes the contractId, use that.
 * If not, find the contractId for the org if it has only one contract and that too is active.
 *
 * @param {String} contractId - Deeplink with contract id
 *
 * @returns contractId if found otherwise undefined
 */
const getActiveContractIdForMiniApp = (contractId) => {
  if (contractId) {
    return contractId;
  }

  const {contractList} = rootStore.organizationStore;

  if (
    feature.isEnabled('temp_add_product_mini_app_use_active_contract_id') &&
    contractList.items.length === 1 &&
    contractList.items[0].status === CONTRACT_STATUS.ACTIVE
  ) {
    return contractList.items[0].id;
  }

  return undefined;
};

/**
 * Based on the FF, this function returns the respective config obj.
 *
 * @returns config from configStore
 */
const getMiniAppConfiguration = () => configStore.get(ADD_PRODUCT_MINI_APP_URL_ENV_PARAM);

/**
 * Setup Add Product Mini App URL
 * See Parameters https://wiki.corp.adobe.com/display/PAN/RFC%3A+Mini+App+-+Add+Product#RFC:MiniAppAddProduct-Parameters
 *
 * @param {String} contractId - Deeplink with contract id
 * @param {Object} items - Deeplink with offer items
 * @param {String} step - Initial step. select product or review order
 * @param {String} tracking - Tracking data to send to the mini app
 * @param {String} workflow - Identify workflow as add product, add license or renewal order
 * @param {String} disabledFeatures - Optional comma-separated list of feature-flags to disable
 *
 * @returns Add Product Mini App URL
 */

// eslint-disable-next-line max-params -- need 5 params whereas limit was only 4 and complexity increased
const setUpMiniAppUrl = (contractId, step, tracking, workflow, items, disabledFeatures) => {
  const {organizationStore} = rootStore;

  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = getMiniAppConfiguration();
  const orgId = organizationStore.activeOrgId;

  // App Context Identifier as Mini App is loaded in iframe
  const iFrameCtx = 'if';

  // Basic parameters
  const params = {
    cli: 'ONESIE1',
    csm: 'light',
    // If the client passes contractId, use it. If not, try to find contractId from contractList
    ctrId: getActiveContractIdForMiniApp(contractId),
    ctx: iFrameCtx,
    lang: localeLanguage,
    orgId,
    step,
    trackingid: tracking,
    userId: AuthenticatedUser.get().getId(),
    workflow,
  };

  if (feature.isEnabled('temp_add_product_mini_app_rtp2')) {
    params.af = ADD_PRODUCT_RTP2_FEATURES.join(',');
  }

  if (disabledFeatures) {
    params.rf = disabledFeatures;
  }

  // Offer items for deep link
  if (Array.isArray(items)) {
    params.items = [];
    items.forEach((item, index) => {
      params.items[index] = {
        id: item.offerId,
        q: item.quantity,
      };
    });
  }

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);

    // Forward client ID to add product mini app
    if (urlParams.get('cli')) {
      params.cli = urlParams.get('cli');
    }

    // Forward promoid to add product mini app
    params.promoid = urlParams.get('promoid');

    if (feature.isEnabled('bug_fix_add_product_mini_app_paypal_redirect')) {
      // Forward paypal to add product mini app
      params.paypal = urlParams.get('paypal');

      // Forward Cybersource PayPal params
      params.pp = urlParams.get('pp');
      params.token = urlParams.get('token');
    }
  }

  return `${url}?${qs.stringify(params, {skipNulls: true})}`;
};

/**
 * @description Represents a wrapper for Add Product Mini App
 *
 * @param {Chat} chat - Jarvis instance
 * @param {String} contractId - Deeplink with contract id
 * @param {String} disabledFeatures - Optional comma-separated list of feature-flags to disable
 * @param {Object} items - Deeplink with offer items
 * @param {Function} onClose - Optional callback to run when the modal is closed
 * @param {Function} onSuccess - Optional callback to run when the modal adds a product successfully
 * @param {String} step - Initial step. select product or review order
 * @param {String} tracking - Tracking data to send to the mini app
 * @param {String} workflow - Identify workflow as add product, add license or renewal order
 */
const AddProductModalWrapper = ({
  chat,
  contractId,
  disabledFeatures,
  items,
  onClose,
  onSuccess,
  step,
  tracking,
  workflow,
}) => {
  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl(contractId, step, tracking, workflow, items, disabledFeatures),
    [contractId, items, step, tracking, workflow, disabledFeatures]
  );

  // Identify Mini App is ready
  const [appReady, setAppReady] = useState(false);

  // Show or close the modal dialog based on status of add product workflow
  const [showAddProductModal, setShowAddProductModal] = useState(true);

  const showSpinnerWithMessage = feature.isEnabled('temp_show_add_product_with_message_spinner');

  const intl = useIntl();

  const loadingMessage = showSpinnerWithMessage
    ? intl.formatMessage({id: 'common.addProduct.loader.message'})
    : '';

  const onCloseRemoveAddProductFromUrl = useCallback(() => {
    setShowAddProductModal(false);

    if (feature.isEnabled('bug_fix_add_product_mini_app_paypal_redirect')) {
      if (typeof window !== 'undefined') {
        const updatedUrl = new URL(window.location);

        // Delete parameters that host would not use from URL
        updatedUrl.searchParams.delete('paypal');
        updatedUrl.searchParams.delete('pp');
        updatedUrl.searchParams.delete('step');
        updatedUrl.searchParams.delete('token');
        updatedUrl.searchParams.delete('var');

        const updatedUrlString = updatedUrl.toString().replace('/add-products', '');
        window.history.pushState({path: updatedUrlString}, '', updatedUrlString);
      }
    } else {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- set the url after removing /add-product
      const path = window.location.href;
      const newPath = path.replace('/add-products', '');
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- set the url after removing /add-product
      window.history.pushState({path: `${newPath}`}, '', `${newPath}`);
    }
  }, []);

  const showSuccessMessage = useCallback(
    (data) => {
      const {customerSegment, contractType, licenseQuantity, resellerName} = data;
      let messageId;

      if (customerSegment === CUSTOMER_SEGMENT.ENTERPRISE) {
        messageId = 'successToastEnterprise';
      } else if (contractType === SALES_CHANNEL.DIRECT) {
        messageId = 'successToastDirect';
      } else {
        messageId = 'successToastIndirect';
      }

      let message = intl.formatMessage(
        {id: `products.purchase.addProductsModal.toast.messageFormat.${messageId}`},
        {
          count: licenseQuantity,
          resellerName,
        }
      );

      if (data?.isInRenewOrderFlow) {
        message = intl.formatMessage({
          id: 'common.updatePayment.addLicenses.toast',
        });
      }

      showSuccessToast(message);
    },
    [intl]
  );

  const showPaymentSavedMessage = useCallback(() => {
    showSuccessToast(intl.formatMessage({id: 'common.updatePayment.success.toast'}));
  }, [intl]);

  // Message handler to process just the System events from Add Product Mini App
  const systemMessageHandler = useCallback(
    // eslint-disable-next-line complexity -- multiple cases for system messages
    ({data, subType}) => {
      const caseAppReady = showSpinnerWithMessage
        ? PAGE_LOAD_COMPLETE_MESSAGE
        : SystemMessage.APP_LOADED;

      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case caseAppReady:
          setAppReady(true);
          break;

        case SystemMessage.CLOSE:
        case SystemMessageSubType.APP_CLOSED:
          onCloseRemoveAddProductFromUrl();
          onClose?.();

          // Send SUBMIT event to refresh the cache
          if (
            data?.statusCode === STATUS_CODE.FULLY_COMPLETE ||
            data?.statusCode === STATUS_CODE.CANCEL_WITHOUT_PROGRESS ||
            [WorkflowStatus.ORDER_COMPLETE, ASSIGN_LICENSE_DONE, ASSIGN_LICENSE_CANCELLED].includes(
              data?.status
            )
          ) {
            // Show toaster only If order is completed and also the subtype is close.
            // In other scenario i.e. when QA is launched, order complete status comes
            // as a notification and not as close message. This situation is handled in another case.
            if (WorkflowStatus.ORDER_COMPLETE === data.status) {
              showSuccessMessage(data);
            }

            eventBus.emit(
              data?.isInRenewOrderFlow ? CART_EVENT.SUBMIT_RENEWAL_ORDER : CART_EVENT.SUBMIT,
              this
            );
            onSuccess?.(data);
          }

          // For Renewal workflow, reload the page to reflect possible payment update
          // upon cancel or save since CART_EVENT.SUBMIT_RENEWAL_ORDER doesn't trigger a cache clear
          if (workflow === ADD_PRODUCT_WORKFLOWS.UPDATE_RENEW) {
            navBus.reload();
          }
          break;

        case SystemMessageSubType.NOTIFICATION:
        case ORDER_PLACED_AND_PROCESSING_NEXT_STEPS:
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
          /* istanbul ignore else  */
          if (data?.status === WorkflowStatus.ORDER_COMPLETE) {
            showSuccessMessage(data);

            // Call on Success to show that the order is placed properly.
            // The parent components can process use this event data on placing the order.
            onSuccess?.(data);
          }
          break;

        case SystemMessage.JARVIS:
          if (data?.open ?? true) {
            // Open jarvis chat if data?.open is true or undefined/null
            chat.openMessagingWindow({
              sourceText: ADD_PRODUCT_MINI_APP_NAME,
            });
          } else {
            // Close jarvis chat
            chat.closeMessageWindow();
          }
          break;

        case SystemMessage.SAVE_PAYMENT_COMPLETE:
          showPaymentSavedMessage();
          break;
      }
    },
    [
      showSpinnerWithMessage,
      onCloseRemoveAddProductFromUrl,
      onClose,
      workflow,
      showPaymentSavedMessage,
      showSuccessMessage,
      onSuccess,
      chat,
    ]
  );

  // Message handler to process the events from Add Product Mini App
  const messageHandler = useCallback(
    ({app, type, subType, data}) => {
      // Ignore the message if it is not from Add Product Mini App
      if (app !== ADD_PRODUCT_MINI_APP_NAME) return;

      log.info('Message from Add Product Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      } else if (type === MessageType.OPEN_URL) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
        window.open(data.externalUrl, data.target);
      }
    },
    [systemMessageHandler]
  );

  // Consume message from Add Product Mini App iframe
  useIframeMessage(messageHandler);

  if (!showAddProductModal) return null;

  return (
    <ModalContainer>
      <div className={styles['add-product-dialog']}>
        <ModalDialog
          dialogStyle={{
            width: '1300px',
          }}
        >
          <div styleName="dialog-content">
            <OverlayWait
              isLoading={!appReady}
              loadingMessage={loadingMessage}
              showContent={!showSpinnerWithMessage}
              size="L"
            >
              <iframe src={miniAppUrl} styleName="add-product-iframe" title="Add product" />
            </OverlayWait>
          </div>
        </ModalDialog>
      </div>
    </ModalContainer>
  );
};

AddProductModalWrapper.propTypes = {
  /**
   * Jarvis chat
   */
  chat: PropTypes.shape({
    closeMessageWindow: PropTypes.func.isRequired,
    openMessagingWindow: PropTypes.func.isRequired,
  }).isRequired,
  /**
   * Optional id which determines the active contract in add product mini app
   */
  contractId: PropTypes.string,
  /**
   * Optional string comma separated list of feature-flags to disable
   */
  disabledFeatures: PropTypes.string,
  /**
   * Optional cart items which needs to be passed on to the mini app
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      offerId: PropTypes.string,
      quantity: PropTypes.number,
    })
  ),
  /**
   * Optional handler that is called when the add-product modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Optional callback to run when the modal adds a product successfully
   */
  onSuccess: PropTypes.func,
  /**
   * Optional step which determines the default step of the add product mini app
   */
  step: PropTypes.string,
  /**
   * Optional step which determines the default step of the add product mini app
   */
  tracking: PropTypes.string,
  /**
   * Optional workflow which determines the workflow of the add product mini app
   */
  workflow: PropTypes.string,
};

export default AddProductModalWrapper;
