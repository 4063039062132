import {useEffect, useState} from 'react';

import {canViewAdmins} from 'core/admin/access/adminAccess';
import {canViewApiCredentials, canViewDevelopers} from 'core/developer/access/developerAccess';
import {canViewDirectories} from 'core/directories/access/directoryAccess';
import {canViewUsers} from 'core/user/access/userAccess';
import {canViewUserGroups} from 'core/user-group/access/userGroupAccess';

/**
 * Provides access rights for the 'Users' tabs.
 */
const useUsersAccessors = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [canViewAdminsValue, setCanViewAdminsValue] = useState(false);
  const [canViewApiCredentialsValue, setCanViewApiCredentialsValue] = useState(false);
  const [canViewDevelopersValue, setCanViewDevelopersValue] = useState(false);
  const [canViewDirectoriesValue, setCanViewDirectoriesValue] = useState(false);
  const [canViewUserGroupsValue, setCanViewUserGroups] = useState(false);
  const [canViewUsersValue, setCanViewUsersValue] = useState(false);

  // Saves results from accessor async functions
  useEffect(() => {
    (async () => {
      setCanViewAdminsValue(canViewAdmins());
      setCanViewApiCredentialsValue(canViewApiCredentials());
      setCanViewDevelopersValue(canViewDevelopers());
      setCanViewUserGroups(canViewUserGroups());
      setCanViewUsersValue(canViewUsers());

      // These methods always resolve with Promise<Boolean>.
      const values = await Promise.all([canViewDirectories()]);
      setCanViewDirectoriesValue(values[0]);

      setIsLoading(false);
    })();
  }, []);

  return {
    canViewAdmins: canViewAdminsValue,
    canViewApiCredentials: canViewApiCredentialsValue,
    canViewDevelopers: canViewDevelopersValue,
    canViewDirectories: canViewDirectoriesValue,
    canViewUserGroups: canViewUserGroupsValue,
    canViewUsers: canViewUsersValue,
    isLoading,
  };
};

export default useUsersAccessors;
