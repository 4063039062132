(function () {
  /**
   * @ngdoc module
   * @name app.core.organizations.integration-list
   * @description module that defines the OrganizationIntegrationList model
   */
  angular.module('app.core.organizations.integration-list', [
    'app.core',
    'app.core.api-integration',
    'app.core.api.jil',
    'app.core.model-cache',
    'app.core.organizations.user-list',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.lodash',
  ]);
})();
