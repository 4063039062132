import {navBus} from '@admin-tribe/binky';
import {PAGE_SECTIONS, PageBreadcrumbs} from '@admin-tribe/binky-ui';
import {INSIGHTS_SECTION} from '@pandora/data-model-insights';
import {Item} from '@react-spectrum/list';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import useInsightsPaths from '../hooks/useInsightsPaths';
import {getTranslationFromReportBroker} from '../insightsUtils';

/** Renders breadcrumbs for a given Insights Item */
const InsightsBreadcrumbs = ({insightsItem}) => {
  const intl = useIntl();
  const {reports, logs} = useInsightsPaths();

  const onAction = useCallback(
    (key) => {
      if (key === 'section') {
        const pathname =
          insightsItem.insightsSection === INSIGHTS_SECTION.REPORTS ? reports() : logs();

        navBus.navigate(pathname);
      }
    },
    [insightsItem, reports, logs]
  );

  return (
    insightsItem && (
      <PageBreadcrumbs isDisabled={false} onAction={onAction}>
        <Item key="section" data-testid="BreadcrumbInsightsSection">
          {intl.formatMessage({id: `insights.${insightsItem.insightsSection}.title`})}
        </Item>
        <Item key="item" data-testid="BreadcrumbInsightsItem">
          {getTranslationFromReportBroker(intl, insightsItem.nameKey, insightsItem.name)}
        </Item>
      </PageBreadcrumbs>
    )
  );
};

InsightsBreadcrumbs.propTypes = {
  /** The Insights Item to render tabs for */
  insightsItem: PropTypes.shape({
    id: PropTypes.string,
    insightsSection: PropTypes.string,
    name: PropTypes.string,
    nameKey: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        descriptionKey: PropTypes.string,
        id: PropTypes.string.isRequired,
      })
    ),
  }),
};

InsightsBreadcrumbs.displayName = PAGE_SECTIONS.BREADCRUMBS;

export default InsightsBreadcrumbs;
