import binky, {DirectoryUser, ORGANIZATION_MARKET_SEGMENT} from '@admin-tribe/binky';
import binkyUI, {getMemberType} from '@admin-tribe/binky-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Provider from '@react/react-spectrum/Provider';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {getEduTagHelp, getEduTags} from 'common/utils/edu-tags/eduTagsUtils';
import rootStore from 'core/RootStore';

const AddUserFormEditableFields = binkyUI.common.components.addUser.AddUserFormEditableFields;
const Avatar = binkyUI.common.components.Avatar;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading} =
  binkyUI.common.components.modal;
const {getOrganizationUserErrorMessage} =
  binkyUI.organization.organizationUser.organizationUserDisplayUtils;

const OrganizationUser = binky.services.organization.OrganizationUser;
const log = binky.services.log;

const EditUserDetailsModal = ({
  id = 'edit-user-details-modal',
  isOpen,
  onSuccess,
  onCancel,
  orgId,
  user,
}) => {
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;
  const [modalError, setModalError] = useState(null);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const orgUser = useRef(new OrganizationUser({orgId, ...user}));
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  const memberType = getMemberType(orgUser.current);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    isCurrentRef.current = true;

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore next
    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const onCta = async () => {
    setIsSavingUser(true);
    try {
      await orgUser.current.save();
      onSuccess();
    } catch (error) {
      log.error('Error saving User: ', error);
      if (isCurrentRef.current) {
        setModalError(getOrganizationUserErrorMessage(intl, error));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingUser(false);
      }
    }
  };

  // Styling for labels.
  const labelStyle = {
    color: 'var(--color-grey-700)',
    fontSize: 'var(--font-size-50)',
  };

  return (
    <ModalContainer>
      {isOpen && (
        <ModalDialog
          cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
          ctaLabel={intl.formatMessage({id: 'common.modal.buttons.save'})}
          errorMessage={modalError}
          heightVariant="static"
          id={id}
          isLoading={isSavingUser}
          onCancel={onCancel}
          onCta={onCta}
        >
          <ModalHeading>
            {intl.formatMessage({id: 'users.editUserDetailsModal.header'})}
          </ModalHeading>
          <ModalDescription>
            <Text>{intl.formatMessage({id: 'users.editUserDetailsModal.description'})}</Text>
          </ModalDescription>
          <ModalContent>
            {orgUser.current && (
              <Flex direction="row" gap="size-300" marginTop="size-300">
                <Avatar alt="" member={orgUser.current} size="L" />
                <View>
                  <Provider>
                    {/* spectrum: remove once AddUserFormEditableFields is ported to v3 */}
                    <AddUserFormEditableFields
                      id={id}
                      onFormChange={(field, value) => {
                        setModalError(null);
                        orgUser.current[field] = value;
                      }}
                      orgUser={orgUser.current}
                    />
                    <View marginTop="size-100">
                      <FieldLabel
                        label={intl.formatMessage({id: 'users.editUserDetailsModal.idType'})}
                      >
                        <Text>
                          {intl.formatMessage({
                            id: `common.idType.${camelCase(memberType.type)}`,
                          })}
                        </Text>
                      </FieldLabel>
                    </View>
                    {marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION && (
                      <View marginTop="size-100">
                        <View UNSAFE_style={labelStyle}>
                          {intl.formatMessage({
                            id: 'users.editUserDetailsModal.eduTag',
                          })}
                          {getEduTagHelp(intl)}
                        </View>
                        <Text data-testid="edu-tags">{getEduTags(user.tags, intl)}</Text>
                      </View>
                    )}
                  </Provider>
                </View>
              </Flex>
            )}
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
};

EditUserDetailsModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  // will be Object if coming from src1.
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(DirectoryUser),
    PropTypes.instanceOf(OrganizationUser),
  ]).isRequired,
};

export default EditUserDetailsModal;
