import {View} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import ClasslinkLogo from 'features/settings/assets/classlink-logo.svg';
import CleverLogo from 'features/settings/assets/clever-logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import EduSyncSourceCard from 'features/settings/components/edu-sync-configuration-steps/EduSyncSourceCard';

const PROVIDERS = [
  {
    description: 'settings.sync.syncSourceCards.clever.description',
    goUrl: 'edu_roster_sync',
    id: SYNC_PROVIDERS.CLEVER,
    logo: CleverLogo,
    name: 'settings.sync.syncSourceCards.clever.title',
  },
  {
    description: 'settings.sync.syncSourceCards.classlink.description',
    goUrl: 'edu_roster_sync',
    id: SYNC_PROVIDERS.CLASSLINK,
    logo: ClasslinkLogo,
    name: 'settings.sync.syncSourceCards.classlink.title',
  },
];

const EduSyncConfigurationSteps = observer(({defaultSelectedProvider, onProviderSelected}) => {
  const EDU_SYNC_SOURCE_CARDS = useMemo(
    () =>
      PROVIDERS.map((provider) => ({
        ...provider,
        selected: provider.id === defaultSelectedProvider,
      })),
    [defaultSelectedProvider]
  );

  return (
    <OptionSelectorWrapper>
      <OptionSelector
        data={EDU_SYNC_SOURCE_CARDS}
        options={{
          on_selection_change: (event) => event.selected && onProviderSelected(event.data.id),
          preventDeselection: true,
        }}
        react_function={(props) => (
          <View>
            <EduSyncSourceCard {...props} />
          </View>
        )}
      />
    </OptionSelectorWrapper>
  );
});

export default EduSyncConfigurationSteps;
