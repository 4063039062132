(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.hva.hva-card.assembler:HvaCard
   * @description model that represents an HVA (High Value Action) card
   */
  angular.module('app.widgets.hva.hva-card.assembler').factory('HvaCard', hvaCard);

  /* @ngInject */
  function hvaCard(_, binkySrc2) {
    /**
     * @class HvaCard
     * @description Creates a new HVA (High Value Action) Card for use.
     *
     * @param {Object} options Initialization Object (params described below)
     * @param {String} [options.actionBlockId] id of the action block
     * @param {String} [options.backgroundImage] The background image on the HVA card
     * @param {String} [options.campaignId] id of the campaign
     * @param {String} [options.containerId] id of the container
     * @param {String} [options.controlGroupId] id of the control group
     * @param {String} [options.coralIcon] The coral icon to display on the HVA card
     * @param {String} [options.coralIconColor] The color of the coral icon. It's defaulted to blue.
     * @param {String} options.ctaLabel The CTA button's translated label
     * @param {String} options.description The translated description of the HVA card
     * @param {Boolean} [options.dismissOnCTAClick] If this is set to true, the card will be dismissed on CTA click
     * @param {String} options.header The translated header of the HVA card
     * @param {String} options.id The id of the HVA card
     * @param {Boolean} [options.isDismissible] The boolean value to determine if the HVA card is dismissible or not. It will render an x-close icon on the HVA card
     * when the value is set to true
     * @param {Object} [options.offerIcons] The icon from offer data to display on the HVA card
     * @param {String} [options.offerIconTitle] The title of the icon from offer data
     * @param {Function} [options.onCTAClick] Callback function that is triggered when the button is clicked
     * @param {String} options.sectionTitle The translated title for the HVA section on the overview page
     * @param {String} [options.surfaceId] id of the surface
     * @param {SVG_CLASS} [options.svgClass] The svg class name to display an SVG image
     * @param {String} [options.treatmentId] id of the treatment
     * @param {String} [options.variationId] id of the variation
     */
    class HvaCard {
      constructor(options) {
        _.assign(this, {
          backgroundImage: options.backgroundImage,
          coralIcon: options.coralIcon,
          coralIconColor: _.defaultTo(options.coralIconColor, 'blue'),
          ctaLabel: options.ctaLabel,
          description: options.description,
          dismissOnCTAClick: options.dismissOnCTAClick,
          header: options.header,
          id: options.id,
          isDismissible: options.isDismissible,
          offerIcons: options.offerIcons,
          offerIconTitle: options.offerIconTitle,
          onCTAClick: options.onCTAClick,
          sectionTitle: options.sectionTitle,
          svgClass: options.svgClass,
        });

        const SOPHIA_CONSTANTS = binkySrc2.services.sophia.SOPHIA_CONSTANTS;

        _.assign(
          this,
          _.pick(options, [
            SOPHIA_CONSTANTS.ACTIONBLOCK_ID,
            SOPHIA_CONSTANTS.CAMPAIGN_ID,
            SOPHIA_CONSTANTS.CONTAINER_ID,
            SOPHIA_CONSTANTS.CONTROL_GROUP_ID,
            SOPHIA_CONSTANTS.SURFACE_ID,
            SOPHIA_CONSTANTS.TREATMENT_ID,
            SOPHIA_CONSTANTS.VARIATION_ID,
          ])
        );
      }

      isValid() {
        const requiredFields = ['ctaLabel', 'description', 'header', 'id', 'sectionTitle'];
        return _.every(requiredFields, (requiredField) => !!this[requiredField]);
      }
    }

    return HvaCard;
  }
})();
