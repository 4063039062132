import {
  OverlayWait,
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageNav,
} from '@admin-tribe/binky-ui';
import {TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useBulkOpsBreadcrumbs from 'features/bulk-operations/hooks/useBulkOpsBreadcrumbs';
import BulkOperationsListTable from 'features/users/components/bulk-operations/BulkOperationsListTable';
import BulkOperationsTableActions from 'features/users/components/bulk-operations/BulkOperationsTableActions';
import useBulkOperationJobs from 'features/users/services/bulk-operations/useBulkOperationJobs';

/**
 * Bulk ops jobs results page
 */
const BulkOperationsPage = ({path}) => {
  const intl = useIntl();
  const {isLoading, error, bulkOps, updateJobList} = useBulkOperationJobs(path);
  const jobList = bulkOps?.jobList;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {breadcrumbs, onAction} = useBulkOpsBreadcrumbs({
    intl,
    path,
  });

  // remove job list listeners when component unmounts
  useEffect(() => () => jobList?.destroy(), [intl, jobList, path]);

  // Should only be called when there is a jobList since onRemovalConfirmed
  // will not be called if buttons are disabled
  const refreshJobList = useCallback(async () => {
    try {
      setIsRefreshing(true);

      // if error occurs, allow useBulkOperationJobs to give us "error"
      const refreshedJobList = await bulkOps.jobList.refresh();

      return {
        download: bulkOps.download, // reuse original download as this should not change
        getDetailsHref: bulkOps.getDetailsHref, // reuse original getHref as this should not change
        getLicenseDeficitHref: bulkOps.getLicenseDeficitHref, // reuse original goToDetails as this should not change
        goToDetails: bulkOps.goToDetails, // reuse original goToDetails as this should not change
        jobList: refreshedJobList,
      };
    } finally {
      setIsRefreshing(false);
    }
  }, [bulkOps]);

  // Show page level spinner for first load.
  // Show page level bumper if error on first load.
  // For any subsequent loads use the OverlayWait and show errors the Table.
  const isPageLoading = isLoading || !breadcrumbs || jobList === undefined;
  return (
    <Page isBumpered={isPageLoading && !!error} isLoading={isPageLoading}>
      {breadcrumbs && (
        <PageBreadcrumbs
          isDisabled={false}
          items={breadcrumbs}
          onAction={(key) => onAction({breadcrumbs, key})}
        />
      )}
      <PageHeader title={intl.formatMessage({id: 'bulkOperations.title'})} />
      <PageNav />
      <PageContent>
        {jobList !== undefined && (
          <OverlayWait isLoading={isLoading} showContent={isRefreshing}>
            <TableSection
              getIsItemSelectable={(key) => jobList?.canJobBeDeleted(key)}
              isDisabled={isRefreshing}
              isServerError={error}
              items={jobList.jobs}
              pageNumber={0} // disables the paginator
              selectionMode="multiple"
            >
              <BulkOperationsTableActions
                jobList={jobList}
                onRemovalConfirmed={() => updateJobList(refreshJobList)}
              />
              <BulkOperationsListTable
                detailsHrefFn={bulkOps?.getDetailsHref}
                detailsNavFn={bulkOps?.goToDetails}
                downloadFn={bulkOps?.download}
                licenseDeficitHrefFn={bulkOps?.getLicenseDeficitHref}
                onCancelRequested={() => updateJobList(refreshJobList)}
              />
            </TableSection>
          </OverlayWait>
        )}
      </PageContent>
    </Page>
  );
};

BulkOperationsPage.propTypes = {
  /**
   * The React router path used to reach this page.
   * For example, PATH_USERS_BULK_OPERATIONS.
   */
  path: PropTypes.string.isRequired,
};

export default BulkOperationsPage;
