import {
  canUseAddProductMiniApp,
  canUseAssignProductsDeepLink,
} from 'core/products/access/productAccess';

/**
 * Use this loader on routes where you want to open the Add Products modal from a deeplink.
 * Use with `useAddProductsModalFromDeeplink` on the page component to easily open the modal.
 * @returns {{openAddProductsModalFromDeeplink: boolean}}
 */
const populateAddProductsModalDataLoader = () => {
  const data = {
    openAddProductsModalFromDeeplink: false,
  };

  if (canUseAddProductMiniApp()) {
    data.openAddProductsModalFromDeeplink = true;
  }

  return data;
};

/**
 * Use this loader on routes where you want to open the Assign Users to Products modal from a deeplink.
 * @returns {{openAssignProductsModalFromDeeplink: boolean}}
 */
const populateAssignProductsModalDataLoader = () => {
  const data = {
    openAssignProductsModalFromDeeplink: false,
  };

  if (canUseAssignProductsDeepLink()) {
    data.openAssignProductsModalFromDeeplink = true;
  }

  return data;
};

export {populateAddProductsModalDataLoader, populateAssignProductsModalDataLoader};
