(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.notifications')
    .constant('NOTIFICATION_ANALYTIC_EVENT', {
      ACTION: {ADMIN_CONSOLE: 'AdminConsole'},
      NAME: {BUTTON: 'NotificationsCardButton', DRAWER: 'notificationsDrawer'},
    })
    .constant('NOTIFICATION_TABS', {
      CONSOLE: 'console',
      STATUS: 'status',
    });
})();
