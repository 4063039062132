/* eslint-disable max-lines -- this file requires more lines */
import cloneDeep from 'lodash/cloneDeep';

import {TEMPLATE_TYPES} from 'features/packages/components/create-package-modal/CreatePackageConstants';

import {
  CREATE_PACKAGE_CONSTANTS,
  KCCC_SAP_CODE,
  PACKAGE_TYPE_CONSTANTS,
} from '../packagesConstants';

import PackagesAdobeProductsUi from './PackagesAdobeProductsUiService';
import PackagesEntitlements from './PackagesEntitlementsService';
import PackagesIngestAnalytics from './PackagesIngestAnalyticsService';

/**
 * @description Method to check if sti is not dependent on products
 * @param {Array} pointProducts non-STI products
 * @param {Object} sti
 *
 * @returns {Boolean} indicates if sti is not dependent on products
 */
function checkIfNotOrphanedSti(pointProducts, sti) {
  const sapCodeVersionKey = `${sti.sapCode}/${sti.baseVersion}`;
  return pointProducts.some((pointProduct) =>
    pointProduct.stiDependentList.includes(sapCodeVersionKey)
  );
}

/**
 * @description Method to check if plugin data should be captured in update package
 * @param {Object} adminPackage
 *
 * @returns {Boolean} Return true if plugin data should be captured in update package else false
 */
function shouldCapturePluginDataInUpdatePackage(adminPackage) {
  return !!(
    adminPackage.pluginsInfo &&
    adminPackage.pluginsInfo.isResolved &&
    !adminPackage.pluginsInfo.hasError &&
    adminPackage.pluginsInfo.plugins.length > 0
  );
}

/**
 * @description Retrieve licenses from frl params
 *
 * @param {Object} adminPackage admin package object
 * @returns {Array} licenses
 */
function getLicensesInFrlParameters(adminPackage) {
  const offersID = adminPackage.frlPackagingInfo.adminPackageLicenseInfo.map(
    (packageInfo) => packageInfo.licenseID
  );
  const entitledOffers =
    adminPackage.packageType === PACKAGE_TYPE_CONSTANTS.NUELL
      ? PackagesEntitlements.nuellOffers
      : PackagesEntitlements.getFrlOffers();

  return (
    entitledOffers
      // filter out all offers with no matching offer id and no FIs
      .filter(
        (offer) => offersID.includes(offer.id) && offer.getPackageableFulfillableItems().length > 0
      )
      // map the needed properties
      .map((offer) => ({
        apps: offer
          .getPackageableFulfillableItems()
          .reduce((fiMap, fiJson) => ({...fiMap, [fiJson.code]: JSON.stringify(fiJson)}), {}),
        licenseFullName: offer.longName,
        licenseId: offer.id,
        licenseShortName: offer.shortName,
      }))
  );
}

/**
 * @description triggers ingest event with args based params passed
 *
 * @param {Object} packageCreateObj package create object
 * @param {String} workflow workflow type
 */
function triggerIngestEvent(packageCreateObj, workflow) {
  const packageCreateIngestObj = cloneDeep(packageCreateObj);

  packageCreateIngestObj.ausstOverride = !!packageCreateIngestObj.ausstOverride;

  const optionsArray = [
    'rumEnabled',
    'ausstFallbackEnabled',
    'enableExtenstions',
    'enableAALExtension',
    'userInstallDirectory',
    'appPanelEnabled',
    'accAdminPrivileged',
    'accDisableAutoAppUpdate',
    'selfServePluginsEnabled',
    'filesPanelEnabled',
    'marketPanelEnabled',
    'browserBasedAuthEnabled',
    'betaAppDownloadEnabled',
    'disableAppTrial',
  ];

  const options = {};
  Object.keys(packageCreateIngestObj).forEach((key) => {
    if (optionsArray.includes(key)) {
      options[key] = packageCreateIngestObj[key];
      delete packageCreateIngestObj[key];
    }
  });
  packageCreateIngestObj.options = options;

  // To remove 'apps' data from license info
  packageCreateIngestObj.packageJobFrl?.licenses?.forEach((license) => {
    if (license.apps) {
      delete license.apps;
    }
  });

  PackagesIngestAnalytics.triggerAnalytics({
    type: workflow,
    value: packageCreateIngestObj,
  });
}

/**
 * @description Method to retrieve license data
 *
 * @param {Object} packageCreateObj main package create object
 * @returns {Object} license related data
 */
function setLicenseData(packageCreateObj) {
  let frlLicensesID = '';
  let frlLicensesName = '';
  if (packageCreateObj.packageJobFrl.licenses?.length > 0) {
    packageCreateObj.packageJobFrl.licenses.forEach((license) => {
      if (frlLicensesID === '') {
        frlLicensesID = license.licenseId;
      } else {
        frlLicensesID = `${frlLicensesID}::${license.licenseId}`;
      }
      if (frlLicensesName === '') {
        frlLicensesName = license.licenseFullName;
      } else {
        frlLicensesName = `${frlLicensesName}::${license.licenseFullName}`;
      }
    });
  }
  return {frlLicensesID, frlLicensesName};
}

/**
 * @description Method to retrieve creative cloud data
 *
 * @param {Number} numberOfProducts number of products
 * @param {Object} packageCreateObj main package create object
 * @returns {Object} creative cloud data
 *
 */
function setCreativeCloudData(numberOfProducts, packageCreateObj) {
  const appPanelEnabled = packageCreateObj.appPanelEnabled ? 'appPanelEnabled' : 'appPanelDisabled';
  const accAdminPrivileged = packageCreateObj.accAdminPrivileged
    ? 'accAdminPrivilegeEnabled'
    : 'accAdminPrivilegeDisabled';
  const accDisableAutoAppUpdate = packageCreateObj.accDisableAutoAppUpdate
    ? 'accDisableAutoAppUpdateEnabled'
    : 'accDisableAutoAppUpdateDisabled';
  const acccOnly = numberOfProducts === 0 ? 'acccOnly' : 'productsPackage';
  const filesPanelEnabled = packageCreateObj.filesPanelEnabled
    ? 'filesPanelEnabled'
    : 'filesPanelDisabled';
  const selfServePluginsEnabled = packageCreateObj.selfServePluginsEnabled
    ? 'selfServePluginsEnabled'
    : 'selfServePluginsDisabled';
  return {
    accAdminPrivileged,
    acccOnly,
    accDisableAutoAppUpdate,
    appPanelEnabled,
    filesPanelEnabled,
    selfServePluginsEnabled,
  };
}

/**
 * @description Retrieve License And Creative Cloud Data
 *
 * @param {Number} numberOfProducts number of selected adobe products
 * @param {Object} packageCreateObj main package create object
 *
 * @returns {Object} license and creative cloud related data
 */
function setLicenseAndCreativeCloudData(numberOfProducts, packageCreateObj) {
  let appPanelEnabled = '';
  let selfServePluginsEnabled = '';
  let accAdminPrivileged = '';
  let accDisableAutoAppUpdate = '';
  let acccOnly = '';
  let frlPackageMode = '';
  let filesPanelEnabled = '';
  let frlActivationCodesCount = 0;
  let frlLicensesID = '';
  let frlLicensesName = '';

  if (
    packageCreateObj.packageJobFrl &&
    (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
      packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE ||
      packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE)
  ) {
    frlPackageMode = numberOfProducts === 0 ? 'frl_license_only' : 'frl_apps_and_license';
    if (packageCreateObj.packageJobFrl.challengeCodes) {
      frlActivationCodesCount = packageCreateObj.packageJobFrl.challengeCodes.length;
    }
    ({frlLicensesID, frlLicensesName} = setLicenseData(packageCreateObj));
  } else if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED) {
    frlPackageMode = 'frl_apps_only';
  } else if (
    packageCreateObj.packageJobFrl &&
    packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.NUELL
  ) {
    ({frlLicensesID, frlLicensesName} = setLicenseData(packageCreateObj));
    ({
      accAdminPrivileged,
      acccOnly,
      accDisableAutoAppUpdate,
      appPanelEnabled,
      filesPanelEnabled,
      selfServePluginsEnabled,
    } = setCreativeCloudData(packageCreateObj, numberOfProducts));
  } else {
    ({accAdminPrivileged, acccOnly, appPanelEnabled, filesPanelEnabled, selfServePluginsEnabled} =
      setCreativeCloudData(packageCreateObj, numberOfProducts));
  }

  return {
    accAdminPrivileged,
    acccOnly,
    accDisableAutoAppUpdate,
    appPanelEnabled,
    filesPanelEnabled,
    frlActivationCodesCount,
    frlLicensesID,
    frlLicensesName,
    frlPackageMode,
    selfServePluginsEnabled,
  };
}

/**
 * @description Retrieve products And preferences based data
 *
 * @param {Boolean} isUpdatesOnlyPackage indicates if updates only package
 * @param {Object} packageCreateObj main package create object
 * @param {String} workflow workflow type
 *
 * @returns {Object} products And preferences based Data
 */
function setProductsAndPreferencesData(isUpdatesOnlyPackage, packageCreateObj, workflow) {
  let enableAALExtension = 'NA';
  let productStr = '';
  let ausstXmlIncluded = '';
  let userInstallPathSelected = '';
  let updatesOnlyPackage;

  if (packageCreateObj.enableAALExtension !== undefined) {
    enableAALExtension = packageCreateObj.enableAALExtension
      ? 'enableAALExtension'
      : 'disableAALExtension';
  }

  if (packageCreateObj.selectedAdobeProductIds?.length > 0) {
    packageCreateObj.selectedAdobeProductIds.forEach((productId) => {
      productStr += `${productId},`;
    });
  }

  if (packageCreateObj.ausstOverride === undefined) {
    ausstXmlIncluded = 'ausstOverrideExcluded';
  } else {
    ausstXmlIncluded = 'ausstOverrideIncluded';
  }
  if (packageCreateObj.userInstallDirectory === undefined) {
    userInstallPathSelected = 'userInstallDirectoryExcluded';
  } else {
    userInstallPathSelected = 'userInstallDirectoryIncluded';
  }
  if (workflow === 'updatePackageWorkflow') {
    updatesOnlyPackage = isUpdatesOnlyPackage ? 'updatesOnlyPackage' : 'updatesAllProductPackage';
  }
  return {
    ausstXmlIncluded,
    enableAALExtension,
    productStr,
    updatesOnlyPackage,
    userInstallPathSelected,
  };
}

/**
 * @description Retrieve License And Creative Cloud Data
 *
 * @param {String} packageType selected package type
 * @returns {Boolean} indicates if frl package type selected
 */
function checkIfFrlTypePackage(packageType) {
  return (
    packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
    packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE ||
    packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN ||
    packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE
  );
}

/**
 * @description Retrieve attributes for analytics call
 *
 * @param {Object} packageCreateObj main package create object
 * @param {Array} packagedPlugins list of packages plugins
 * @param {String} workflow workflow type
 * @param {Boolean} isUpdatesOnlyPackage indicates if updates only package
 * @param {Boolean} containsArchived indicates if archived products selected
 *
 * @returns {Object} attributes for analytics call
 */
function getAttributes({
  packageCreateObj,
  packagedPlugins,
  workflow,
  isUpdatesOnlyPackage,
  containsArchived,
}) {
  const customerType = PackagesEntitlements.isEnterpriseOrg ? 'enterpriseCustomer' : 'teamCustomer';
  const numberOfProducts = packageCreateObj.selectedAdobeProductIds
    ? packageCreateObj.selectedAdobeProductIds.length
    : 0;
  const numberOfPlugins = packagedPlugins ? packagedPlugins.length : 0;

  const {
    accAdminPrivileged,
    accDisableAutoAppUpdate,
    acccOnly,
    appPanelEnabled,
    filesPanelEnabled,
    frlActivationCodesCount,
    frlLicensesID,
    frlLicensesName,
    frlPackageMode,
    selfServePluginsEnabled,
  } = setLicenseAndCreativeCloudData(numberOfProducts, packageCreateObj);

  const {
    ausstXmlIncluded,
    enableAALExtension,
    productStr,
    userInstallPathSelected,
    updatesOnlyPackage,
  } = setProductsAndPreferencesData(isUpdatesOnlyPackage, packageCreateObj, workflow);

  const rumEnabled = packageCreateObj.rumEnabled ? 'rumEnabled' : 'rumDisabled';
  const platformBits = packageCreateObj.platform + packageCreateObj.bits;

  const locale = packageCreateObj.locale;
  const useOsLocale = packageCreateObj.osLangDetection ? 'useOsLocale' : 'useSelectedLocale';

  const pluginStr = packagedPlugins
    ?.map((plugin) => `${plugin.name}/${plugin.version}/${plugin.type}/${plugin.versionId}`)
    .join(',');

  const packageType = packageCreateObj.packageType;
  const extensionsEnabled = packageCreateObj.enableExtenstions
    ? 'extensionsEnabled'
    : 'extensionsDisabled';

  const archivedProducts = containsArchived ? 'archivedProductsIncluded' : 'latestProductsPackage';

  const commonAttrs = {
    archivedProducts,
    ausstXmlIncluded,
    customerType,
    extensionsEnabled,
    locale,
    numberOfProducts,
    packageType,
    platformBits,
    productStr,
    rumEnabled,
    updatesOnlyPackage,
    useOsLocale,
    userInstallPathSelected,
    workflow,
  };

  const pluginsRelatedAttrs = {
    numberOfPlugins,
    pluginStr,
    selfServePluginsEnabled,
  };

  const licenseRelatedAttrs = {
    frlLicensesID,
    frlLicensesName,
  };

  const accAndPanelRelatedAttrs = {
    accAdminPrivileged,
    acccOnly,
    accDisableAutoAppUpdate,
    appPanelEnabled,
    filesPanelEnabled,
  };

  if (checkIfFrlTypePackage(packageCreateObj.packageType)) {
    return {
      ...commonAttrs,
      frlActivationCodesCount,
      ...licenseRelatedAttrs,
      frlPackageMode,
    };
  }
  if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.NUELL) {
    return {
      ...accAndPanelRelatedAttrs,
      ...commonAttrs,
      ...licenseRelatedAttrs,
      ...pluginsRelatedAttrs,
    };
  }
  return {
    ...accAndPanelRelatedAttrs,
    ...commonAttrs,
    enableAALExtension,
    ...pluginsRelatedAttrs,
  };
}

/**
 * @description set FRL related data for the create package Object
 *
 * @param {Object} adminPackage admin package object
 * @param {Object} packageCreateObj main package create object
 * @param {Array} selectedSapcodes list of products selected in the package
 *
 * @returns {Object} FRL related data
 */
function setFRLParameters(adminPackage, packageCreateObj) {
  const packageJobFrl = {};
  if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED) {
    packageJobFrl.challengeCodes = adminPackage.frlPackagingInfo.challengeCodes;
  }
  if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN) {
    packageJobFrl.serverId = adminPackage.frlPackagingInfo.server.serverId;
  }
  if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE) {
    packageJobFrl.profileServerUrl = adminPackage.frlPackagingInfo.profileServerUrl;
  }

  if (packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE) {
    packageJobFrl.frlOnlineActivationType = adminPackage.frlPackagingInfo.frlOnlineActivationType;
  }
  packageJobFrl.licenses = getLicensesInFrlParameters(adminPackage);
  return packageJobFrl;
}

/**
 * @description Method to extract latest product keys
 *
 * @param {Array} latestProductKeys
 * @returns {Set} set of latest product keys
 *
 */
function extractLatestProductKeys(latestProductKeys) {
  const productKeysSet = new Set();
  latestProductKeys.forEach((productKey) => {
    const attrs = productKey.split('/');
    productKeysSet.add(`${attrs[0]}/${attrs[1]}/${attrs[2]}`);
  });
  return productKeysSet;
}

/**
 * @description Method to indicate if packaged product
 *
 * @param {Object} product to be evaluated
 * @returns {Boolean} true if packaged product else false
 *
 */
function packagedProductsFilter(product) {
  return product.sapCode !== KCCC_SAP_CODE && product.visible;
}

/**
 * @description Method return customer type
 *
 * @returns {String} customer type
 */
function getCustomerType() {
  return PackagesEntitlements.isEnterpriseOrg
    ? CREATE_PACKAGE_CONSTANTS.ENTERPRISE_CUSTOMER
    : CREATE_PACKAGE_CONSTANTS.TEAM_CUSTOMER;
}

/**
 * @description Method to evaluate updates products set
 *
 * @param {Boolean} addLatestProductKeys indiactes if latest products should be added
 * @param {Array} pointProducts non-STI products
 *
 * @returns {Set} updates products set
 *
 */
function getUpdatedProductKeysSet(addLatestProductKeys, pointProducts) {
  let productKeysSet = new Set();

  // add latest versions of all point products and their latest STIs to productKeysSet
  let pointProductsFilter = () => true;

  if (!addLatestProductKeys) {
    // need to re-add all dependent STIs for outdated point products whose update is not
    // already packaged to make sure they didn't get removed in a previous step
    pointProductsFilter = (product) =>
      !product.isUpToDate &&
      !pointProducts.some(
        (pointProduct) => pointProduct.product_key === product.getLatestProductKey()
      );
  }

  pointProducts.filter(pointProductsFilter).forEach((product) => {
    if (addLatestProductKeys) {
      productKeysSet.add(product.getLatestProductKey());
    }
    // add its latest dependent STIs if any

    let dependentStis = getStiDependencyMapValues(product);
    if (dependentStis.length === 0) {
      // if getStiDependencyMap is empty, read getStiDependencyMapMacU => mac universal package
      dependentStis = getStiDependencyMapMacuValues(product);
    }
    productKeysSet = new Set([...productKeysSet, ...extractLatestProductKeys(dependentStis)]);
  });

  return productKeysSet;
}

/**
 * @description Method to retrieve sti dependency map values as flattened array
 *
 * @param {Object} product on which dependent stis should be evaluated
 * @returns {Array} sti dependency map values
 */
function getStiDependencyMapValues(product) {
  return Object.values(
    PackagesAdobeProductsUi.getAdobeProductBySapCodeVersionPlatform(
      product.getLatestProductKey()
    ).getStiDependencyMap()
  ).flat();
}

/**
 * @description Method to retrieve sti dependency map macu values as flattened array
 *
 * @param {Object} product on which dependent stis should be evaluated
 * @returns {Array} sti dependency map macu values
 */
function getStiDependencyMapMacuValues(product) {
  return Object.values(
    PackagesAdobeProductsUi.getAdobeProductBySapCodeVersionPlatform(
      product.getLatestProductKey()
    ).getStiDependencyMapMacU()
  ).flat();
}

/**
 * @description Method return template type
 * @returns {String} customer type
 */
function getTemplateType(isNonCCTemplate, isCustomize) {
  if (PackagesEntitlements.hasDcOnlyEntitlement) {
    return isCustomize ? TEMPLATE_TYPES.DC_CUSTOMIZED : TEMPLATE_TYPES.DC_NON_CUSTOMIZED;
  }
  if (isCustomize) {
    return isNonCCTemplate ? TEMPLATE_TYPES.NON_CC_CUSTOMIZED : TEMPLATE_TYPES.CC_CUSTOMIZED;
  }
  return isNonCCTemplate ? TEMPLATE_TYPES.NON_CC_NON_CUSTOMIZED : TEMPLATE_TYPES.CC_NON_CUSTOMIZED;
}

export {
  checkIfNotOrphanedSti,
  extractLatestProductKeys,
  getAttributes,
  getCustomerType,
  getTemplateType,
  getUpdatedProductKeysSet,
  setCreativeCloudData,
  setFRLParameters,
  setLicenseData,
  setLicenseAndCreativeCloudData,
  setProductsAndPreferencesData,
  shouldCapturePluginDataInUpdatePackage,
  packagedProductsFilter,
  triggerIngestEvent,
};
/* eslint-enable max-lines -- this file requires more lines */
