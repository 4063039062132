(function () {
  angular.module('app.shell', [
    'app.core.auth',
    'app.core.contract.migrations',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.core.src2-migration',
    'app.core.user',
    'app.main.global-modals',
    'app.main.global-modals-manager',
    'app.widgets.contracts.migrations',
    'app.widgets.counter',
    'app.widgets.identity',
    'app.widgets.notifications',
    'app.widgets.organization-type',
    'app.widgets.organization-type-form',
    'app.widgets.organizations',
    'app.widgets.products.purchase.add-products-modal',
    'app.widgets.search',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.shell.navigation.breadcrumbs',
    'binky.shell.navigation.constants',
    'binky.shell.navigation.nav-item',
    'binky.shell.navigation.nav-manager',
    'binky.shell.navigation.state-manager',
    'binky.shell.navigation.tab-nav',
    'binky.shell.panels.drawer',
    'binky.shell.panels.manager',
    'binky.shell.panels.pulldown',
    'binky.shell.workspace',
    'binky.ui-migration',
    'binky.widgets.common.avatar',
    'binky.widgets.common.feature',
    'binky.widgets.common.go-url',
    'binky.widgets.common.toast',
    'binky.widgets.omni-tool.drawer',
    'binky.widgets.user.authenticated-user-pulldown',
    'ngAnimate',
    'ngCoral',
    'ngResource',
  ]);
})();
