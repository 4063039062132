(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appProductCatalogTable
   * @description Component to display a list of product-catalog-item.
   * @param {OfferList} offers A list of offer to display.
   * @param {Function} onAddItem The callback to invoke when the add button is pressed.
   *                             It will return the respected offer when clickin
   * @param {Object} [ownedProducts] Products currently owned by the organization.
   * @param {Boolean} [showPrice] Boolean value of whether to show the price or not. Default: false
   * @param {Number} [viewLimit] Maximum number of catalog items to show. Shows all when undefined. Default: undefined
   * @example
   *  <app-product-catalog-table
   *    offers="$ctrl.offers"
   *    on-add-item="$ctrl.onAddItem(offerId)"
   *    owned-products="$ctrl.ownedProducts"
   *    show-price="$ctrl.showPrice"
   *    view-limit="$ctrl.maxItems">
   *  </app-product-catalog-table>
   */
  angular.module('app.widgets.products.purchase').component('appProductCatalogTable', {
    bindings: {
      offers: '<',
      onAddItem: '&',
      ownedProducts: '<?',
      showPrice: '<?',
      viewLimit: '<?',
    },
    templateUrl:
      'app/widgets/products/purchase/catalog/product-catalog-table/product-catalog-table.component.html',
  });
})();
