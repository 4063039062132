(function () {
  /**
   * @ngdoc module
   * @name app.core.product.configurations.member-roles
   * @description module that defines the member role models
   */
  angular.module('app.core.product.configurations.member-roles', [
    'app.core.organizations.organization-manager',
    'binky.core.api.jil',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
