import {
  renderTotalConsumables,
  renderTotalInstances,
  renderUsedConsumables,
} from '../product-group-page-info-bar-constructor/productGroupPageInfoBarConstructor';

/**
 * @description Method to construct an array with all relevant scorecards for
 *   group consumable products or grouped instances products.
 * @param {Consumable} [consumable] - consumable summary for FI product
 *   scorecards; optional, not needed to construct FIG cards
 * @param {Object} intl - reference to translation service at runtime
 * @param {ProductGroupProductList} productGroup - product grouping for FIG
 *   product scorecards
 * @returns {ReactElement[]} scorecards for the product group
 */
const constructSummaryProductGroup = ({consumable, intl, productGroup}) => {
  if (productGroup.isConsumable) {
    if (consumable) {
      return [
        renderTotalConsumables({consumable, intl}),
        renderUsedConsumables({consumable, intl}),
      ];
    }
    return [];
  }
  return [renderTotalInstances({intl, productGroup})];
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {constructSummaryProductGroup};
