import {SELECTION_MODE, TableFilters, TableSection} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import useDirectoryTable from 'features/settings/hooks/useDirectoryTable';
import DirectoryListTable from 'features/users/components/directory-list-users/DirectoryListTable';

const OwnedDirectoryListTable = observer(() => {
  const intl = useIntl();
  const {onTableSectionChange} = useDirectoryTable();
  const {state: directoryList, tableOptions} = useDirectoryListContext();

  return (
    <TableSection
      isServerError={directoryList.hasLoadingError}
      items={directoryList?.directoryListData.items}
      onTableSectionChange={onTableSectionChange}
      pageNumber={tableOptions.page}
      selectionMode={SELECTION_MODE.NONE}
      totalPages={directoryList?.directoryListData.totalPages}
    >
      <TableFilters
        label={intl.formatMessage({id: 'users.ownedDirectoryListPage.tableSection.search'})}
      />
      {(directoryList?.directoryListData.items || directoryList.hasLoadingError) && (
        <DirectoryListTable />
      )}
    </TableSection>
  );
});

export default OwnedDirectoryListTable;
