import {Store} from '@admin-tribe/binky';
// eslint-disable-next-line you-dont-need-lodash-underscore/filter -- need to iterate object look up table
import filter from 'lodash/filter';
import has from 'lodash/has';
import {action, computed, makeObservable, observable} from 'mobx';

import HelpTopic from '../../core/HelpTopic';
import TopicIdentifierUtils from '../../core/TopicIdentifierUtils';

import TopicIdentifierStore from './TopicIdentifierStore';

// Domain data for help content
class TopicStore extends Store {
  constructor() {
    // set up state
    super();

    Object.assign(this, {
      focusedTopicId: undefined,
      items: {}, // HelpTopic look-up table (by id)
      selectedPath: undefined,
      selectedTopic: undefined,
      topicIdentifiers: new TopicIdentifierStore(),
    });

    makeObservable(this, {
      add: action.bound,
      focusedTopicId: observable,
      items: observable,
      selectedChildTopics: computed,
      selectedPath: observable,
      selectedRootTopic: computed,
      selectedRootTopicChildren: computed,
      selectedTopic: observable,
      selectedTopicIdentifier: computed,
      selectTopic: action.bound,
    });
  }

  /**
   * @description Method to add a new topic to the help content.
   *
   *   Configuration Objects must use the following format:
   *
   *   {
   *     access: Function,
   *     id: String,
   *     content: [
   *       { // if configuring inline content for topic (points)
   *         access: Function,
   *         links: [
   *           {
   *             key: String,
   *             locator: String,
   *             type: LinkType
   *           }
   *         ],
   *         text: String,
   *         title: String,
   *         type: HelpItemType
   *       },
   *       { // if configuring references to child topics
   *         id: String
   *       }
   *     ],
   *     feature: {
   *       enabled: Boolean,
   *       name: String
   *     },
   *     order: Number,
   *     ordered: Boolean,
   *     parents: [
   *       {
   *         id: String
   *       }
   *     ],
   *     title: String
   *   }
   *
   * @param {Object} configObj - configuration Object, for in-depth
   *   description see configure method (above)
   * @throws {Error} if attempting to add a topic that has already been
   *   configured
   */
  add(configObj) {
    this.topicIdentifiers.add(configObj.id); // track id info
    Object.assign(this.items, {[configObj.id]: new HelpTopic(configObj)});
  }

  /**
   * @description Method to return a help topic by id
   * @param {String} topicId id of topic to retrieve
   * @returns {HelpTopic|undefined} help topic requested, or undefined if no
   *   topic with that id exists
   */
  getById(topicId) {
    return this.items[topicId];
  }

  /**
   * @description Method to return a list of child topics for a given help topic
   *   (by id)
   * @param {String} topicId id of topic to retrieve children of
   * @returns {HelpTopic[]} array of child help topics
   */
  getTopicChildren(topicId) {
    // only return HelpItems that are HelpTopics with a parent ID equal to the
    // ID passed in...
    const unsortedChildren = filter(this.items, (topic) =>
      topic.parents?.some((parent) => !has(parent, 'type') && parent.id === topicId)
    );

    return unsortedChildren.sort(HelpTopic.compareTopicsByOrder);
  }

  /**
   * @description Method to determine if the given topic (help item) is
   *   available or not. Content is considered available if it exists and
   *   exists in the hierarchy described by the identifier.
   *
   *   Since help topics are not added if a user does not have access,
   *   unavailable topics are those whose own (or parent help) access check
   *   failed.
   * @param {String} topicId - dot-delineated (optional hash mark) identifier
   *   used to represent a hierarchical location in the help topic system
   * @returns {Promise} resolves with Boolean value; true if topic is
   *   available, else false
   * @throws {Error} if topic identifier passed in does not exist in topic
   *   hierarchy described by identifier
   */
  isTopicAvailable(topicId) {
    const toCheckTopicIdentifier = this.topicIdentifiers.getById(topicId);

    if (toCheckTopicIdentifier === null || toCheckTopicIdentifier === undefined) {
      return false; // topic does not exist - unavailable
    }

    const topicHierarchy = TopicIdentifierUtils.getHierarchy(toCheckTopicIdentifier);

    let currentTopic, previousTopic;
    return topicHierarchy.every((hierarchicalTopicId, index) => {
      let isValid;
      currentTopic = this.getById(hierarchicalTopicId);
      if (index === 0) {
        isValid = !!currentTopic;
      } else {
        isValid =
          !!currentTopic && currentTopic.parents.some((parent) => parent.id === previousTopic.id);
      }
      previousTopic = currentTopic;
      return isValid;
    });
  }

  /**
   * @description Method to determine if the given Topic ID is on the
   *   currently-selected topic path or not. If no topic is currently
   *   selected, then this method will return false.
   * @param {String} topicId - unique ID of topic to check
   * @returns {Boolean} true if topic is on path, else false
   */
  isTopicOnPath(topicId) {
    if (this.selectedTopic) {
      return this.selectedPath.includes(topicId);
    }
    // no current topic selected, return false...
    return false;
  }

  /**
   * @description Method to determine if the topic passed in represents the
   *   currently-selected and focused topic.
   * @param {String} topicId - unique ID of topic to check
   * @returns {Boolean} true if topic is selected topic, else false
   */
  isTopicSelected(topicId) {
    let isSelectedTopic = false;

    // if no topic currently selected, always return false
    if (this.selectedTopic?.id) {
      isSelectedTopic = TopicIdentifierUtils.getSelectedTopicId(topicId) === this.selectedTopic.id;
    }

    // otherwise, return whether the current/focused topic match param
    return (
      isSelectedTopic && TopicIdentifierUtils.getFocusedTopicId(topicId) === this.focusedTopicId
    );
  }

  get selectedChildTopics() {
    return this.selectedTopic?.id && this.getTopicChildren(this.selectedTopic.id);
  }

  get selectedRootTopic() {
    const rootTopicId =
      this.selectedTopicIdentifier && TopicIdentifierUtils.getRootTopicId(this.selectedPath);
    return rootTopicId && this.getById(rootTopicId);
  }

  get selectedRootTopicChildren() {
    return this.selectedRootTopic?.id && this.getTopicChildren(this.selectedRootTopic.id);
  }

  get selectedTopicIdentifier() {
    let selectedTopicId;
    if (this.selectedTopic !== undefined && this.selectedTopic !== null) {
      selectedTopicId = this.topicIdentifiers.getById(this.selectedTopic.id);
    }
    return selectedTopicId;
  }

  /**
   * @description Method to set a new selected help topic.
   * @param {String} topicId - dot-delineated (optional hash mark)
   *   identifier used to represent a hierarchical location in the help topic
   *   system
   * @throws {Error} if topic identifier passed in does not exist or user
   *   does not have access to topic referenced
   */
  selectTopic(topicId) {
    const toLoadTopicIdentifier = TopicIdentifierUtils.getSelectedTopicId(topicId);
    if (toLoadTopicIdentifier) {
      let toLoadTopicResolved;

      // update current path to reflect
      const toLoadTopicHierarchy = TopicIdentifierUtils.getHierarchy(topicId);

      // if accessing root of help system (top-level path; length 1)
      if (toLoadTopicHierarchy.length === 1) {
        // default to first child topic
        toLoadTopicResolved = this.getTopicChildren(toLoadTopicIdentifier)[0];
        this.selectedPath = `${topicId}.${toLoadTopicResolved.id}`;
      } else {
        // set selected topic to loaded topic
        toLoadTopicResolved = this.getById(toLoadTopicIdentifier);
        this.selectedPath = TopicIdentifierUtils.getPath(topicId);
      }
      // update selected topic attributes
      this.focusedTopicId = TopicIdentifierUtils.getFocusedTopicId(topicId);
      this.selectedTopic = toLoadTopicResolved;
    }
  }
}

export default TopicStore;
