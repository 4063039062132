(function () {
  /**
   * @ngdoc module
   * @name app.widgets.bulk-operations
   * @description module that defines bulk-operations widgets
   */
  angular.module('app.widgets.bulk-operations', [
    'angularMoment',
    'app.core.analytics',
    'app.core.api.jil',
    'app.core.gainsight',
    'app.core.jobs',
    'app.core.migration.t2e',
    'app.core.organizations.organization-manager',
    'app.core.papaparse',
    'app.core.product.configurations',
    'app.core.product.configurations.member-roles',
    'app.main.jobs',
    'app.widgets.bulk-operations.constants',
    'app.widgets.bulk-operations.context',
    'app.widgets.bulk-operations.form',
    'app.widgets.bulk-operations.localization',
    'app.widgets.bulk-operations.samples',
    'binky.core.api.jil',
    'binky.core.domains',
    'binky.core.download-utils',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.shell.panels.modal',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.drawer-header.drawer-header-description',
    'binky.widgets.common.feature',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.toast',
    'pascalprecht.translate',
  ]);
})();
