import {Locale} from '@admin-tribe/binky';

let baseUrl;

/**
 * @description Configure terms redirect from configuration.495e31ace1.json
 *
 * @param {String} url Base URL for invites mini-app
 */
const configure = ({url}) => {
  baseUrl = url;
};

/**
 * @description Redirect the current user session to the 3YC mini-app.
 *
 * @param {String} orgId The org that owns the contracts
 */
const redirectToAccept3YC = ({locale, orgId}) => {
  if (typeof window !== 'undefined') {
    window.location.href = `https://${baseUrl}/mini-apps/accept/3yc?orgId=${orgId}&locale=${locale}`;
  }
};

/**
 * @description gives Redirect url for the for this org to invites mini-app.
 * The mini-app allows the user to accept terms and conditions.
 *
 * @param {String} orgId The org that owns the contracts
 */
const redirectUrlToAcceptTC = (orgId) => {
  const locale = Locale.get().activeLocaleCode;
  const urlParams = new URLSearchParams({locale, orgId});
  return `https://${baseUrl}/mini-apps/accept/vip?${urlParams}`;
};

/**
 * @description Redirect the current user session to invites mini-app.
 * The mini-app allows the user to accept terms and conditions.
 *
 * @param {String} orgId The org that owns the contracts
 */
const redirectToAcceptTC = ({orgId}) => {
  if (typeof window !== 'undefined') {
    window.location.href = redirectUrlToAcceptTC(orgId);
  }
};

export {configure, redirectToAccept3YC, redirectToAcceptTC, redirectUrlToAcceptTC};
