import {log} from '@admin-tribe/binky';
import {
  GoUrl,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@admin-tribe/binky-ui';
import {ValidatedTextField, ValidatedTextFieldVariant} from '@pandora/react-validated-text-field';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import StorageRepository from 'common/entities/storage-repository/StorageRepository';
import StorageRepositoryList from 'common/services/storage-repository-list/StorageRepositoryList';

/**
 * This modal is used to update the storage repository name.
 */
const EditRepositoryNameModal = ({onSuccess, onClose, repositoryList}) => {
  const [modalError, setModalError] = useState(null);
  const [isSavingName, setIsSavingName] = useState(false);
  const [repositoryName, setRepositoryName] = useState(repositoryList.items[0].name);
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  const description = intl.formatMessage(
    {id: 'storage.editRepositoryNameModal.description'},
    {goUrl: (str) => <GoUrl name="aac_storage_overview_learn">{str}</GoUrl>}
  );

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const isInvalid = useMemo(() => !repositoryName?.trim(), [repositoryName]);

  const ctaToastGenerator = () =>
    intl.formatMessage({id: 'storage.editRepositoryNameModal.toast.success'});

  const onCancel = () => {
    setModalError(null);
    onClose();
  };

  const getValidationMessage = () =>
    isInvalid
      ? intl.formatMessage({
          id: 'storage.editRepositoryNameModal.textField.errorMessage.emptyString',
        })
      : '';

  const onCta = async () => {
    let isSuccess = false;

    setIsSavingName(true);

    try {
      const trimmedNewName = repositoryName.trim();
      if (trimmedNewName !== repositoryList.items[0].name) {
        const repositoryToSave = new StorageRepository({
          ...repositoryList.items[0],
          name: trimmedNewName,
        });
        await repositoryList.save([repositoryToSave]);
        onSuccess?.(trimmedNewName);
        isSuccess = true;
      }
      onClose();
    } catch (error) {
      log.error('Error saving storage repository name: ', error);
      if (isCurrentRef.current) {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingName(false);
      }
    }

    // Return to trigger success toast
    return isSuccess;
  };

  return (
    <ModalDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      ctaLabel={intl.formatMessage({id: 'common.modal.buttons.save'})}
      ctaToastGenerator={ctaToastGenerator}
      errorMessage={modalError}
      id="edit-repository-name-modal"
      isCtaDisabled={isInvalid}
      isLoading={isSavingName}
      onCancel={onCancel}
      onCta={onCta}
    >
      <ModalHeading>
        {intl.formatMessage({id: 'storage.editRepositoryNameModal.header'})}
      </ModalHeading>
      <ModalDescription>{description}</ModalDescription>
      <ModalContent>
        <ValidatedTextField
          label={intl.formatMessage({
            id: 'storage.editRepositoryNameModal.textField.label',
          })}
          maxLength="255"
          onChange={setRepositoryName}
          validationMessage={getValidationMessage()}
          value={repositoryName}
          variant={
            isInvalid ? ValidatedTextFieldVariant.ERROR : ValidatedTextFieldVariant.CONFIRMATION
          }
        />
      </ModalContent>
    </ModalDialog>
  );
};

EditRepositoryNameModal.propTypes = {
  /**
   * Optional callback to invoke when the close or cta button is clicked.
   * It has no parameters.
   */
  onClose: PropTypes.func,
  /**
   * Optional callback to invoke when the modal's actions have been successful.
   * It has one parameter which is the new repository name.
   */
  onSuccess: PropTypes.func,
  /**
   * The resolved StorageRepository list.
   */
  repositoryList: PropTypes.instanceOf(StorageRepositoryList).isRequired,
};

export default EditRepositoryNameModal;
