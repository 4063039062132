import {useMemo} from 'react';

import {
  getAllCancellingLicensesCount,
  getNextBilling,
  getNextBillingForRetention,
  getOrderNumber,
  getOrderNumberForRetention,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * @description Hook to facilitate necessary information for displaying the confirmation of an order
 *
 * @returns {Object} state - An object containing the following order confirmation details
 *          {String} state.confirmationNumber - The order confirmation number
 *          {number} state.licenseCount - The number of successfully cancelled licenses
 *          {Object} state.nextBillingAmount - An object summarizing the next billing prices
 *          {Object} state.nextBillingAmount.totalWithoutTax - Next billing price excluding tax
 *          {Object} state.nextBillingAmount.totalWithTax - Next billing price including tax
 *          {Object} state.retentionConfirmationNumber - The order confirmation number for retention
 *          {Object} state.retentionNextBillingAmount - An object summarizing the next billing prices after accepting a retention
 *          {Object} state.retentionNextBillingAmount.totalWithoutTax - Next billing price excluding tax after accepting a retention
 *          {Object} state.retentionNextBillingAmount.totalWithTax - Next billing price including tax after accepting a retention
 */
const useProductsChangeConfirmation = () => {
  const {productsChange} = useProductsChangeContext();
  return useMemo(
    () => ({
      confirmationNumber: getOrderNumber(productsChange),
      licenseCount: getAllCancellingLicensesCount(productsChange),
      nextBillingAmount: getNextBilling(productsChange) || {},
      retentionConfirmationNumber: getOrderNumberForRetention(productsChange),
      retentionNextBillingAmount: getNextBillingForRetention(productsChange) || {},
    }),
    [productsChange]
  );
};

export default useProductsChangeConfirmation;
