(function () {
  /**
   * @deprecated replaced by the Pandora Add Products Modal
   */
  angular.module('app.core.digital-river-utils').constant('DIGITAL_RIVER_COUNTRY_TO_LANGUAGE_MAP', {
    AE: 'en_IE',
    AM: 'en_IE',
    AR: 'es_AR',
    AZ: 'en_IE',
    BH: 'en_IE',
    BR: 'pt_BR',
    BY: 'ru_RU',
    CL: 'es_CL',
    CO: 'es_CO',
    CR: 'es_AR',
    DZ: 'fr_FR',
    EC: 'es_EC',
    EG: 'en_IE',
    GE: 'en_IE',
    GT: 'es_AR',
    HK: 'en_SG',
    ID: 'en_SG',
    IL: 'en_SG',
    IN: 'en_IN',
    JO: 'en_IE',
    KG: 'ru_RU',
    KR: 'ko_KR',
    KW: 'en_IE',
    KZ: 'ru_RU',
    LB: 'fr_FR',
    MA: 'fr_FR',
    MD: 'en_IE',
    MY: 'en_SG',
    OM: 'en_IE',
    PE: 'es_PE',
    PH: 'en_SG',
    QA: 'en_IE',
    RU: 'ru_RU',
    SA: 'en_IE',
    SG: 'en_SG',
    TH: 'en_SG',
    TJ: 'ru_RU',
    TM: 'en_IE',
    TN: 'fr_FR',
    TR: 'tr_TR',
    TW: 'zh_TW',
    UA: 'ru_RU',
    UZ: 'ru_RU',
    VE: 'es_VE',
    YE: 'en_IE',
  });
})();
