// import {feature} from '@admin-tribe/binky';
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line angular/component-limit
  angular.module('app.overview').config(overviewAddProductsRouteConfig).run(watchRouteChanges);

  /* @ngInject */
  function overviewAddProductsRouteConfig($urlRouterProvider, addProductsModalRoutesProvider) {
    // Create deep linking route for Add Products using addProductsModalRoutesProvider
    addProductsModalRoutesProvider.configure({
      analyticsModalSourcePage: 'overview',
      parentRoute: 'overview.overview2',
      stateName: 'overview.overview2.add-products',
    });

    $urlRouterProvider.when(
      '/overview/add-products',
      ($location, $state, orgReady, ProductPurchaseHelper) => {
        // eslint-disable-next-line promise/catch-or-return
        orgReady.whenRunReady().then((activeOrg) => {
          const queryParams = $location.search();
          $state.go(
            'overview.overview2.add-products',
            {
              // Pass through step and cartItems
              cartItems: ProductPurchaseHelper.convertLocationSearchToCartItems(queryParams),
              orgId: activeOrg.id,
              step: queryParams.step,
            },
            {inherit: true}
          );
        });
      }
    );
  }

  /* After the injectors has all providers */
  // eslint-disable-next-line angular/di-unused
  function watchRouteChanges($rootScope, _, onesieSrc2) {
    // register listener to watch route changes. If route is /add-products and
    // temp_add_product_mini_app is true then if opens product list in modal popup instead of routing to /add-product

    // eslint-disable-next-line angular/no-run-logic
    const locationChangeStart = $rootScope.$on('$locationChangeStart', (event, path) => {
      if (onesieSrc2.core.products.access.canPreventAddProductDefaultRouteForMiniApp(path)) {
        event.preventDefault();
      }
    });
    // eslint-disable-next-line angular/no-run-logic
    const routeChangeStart = $rootScope.$on('$routeChangeStart', (event, path) => {
      if (onesieSrc2.core.products.access.canPreventAddProductDefaultRouteForMiniApp(path)) {
        event.preventDefault();
      }
    });
    $rootScope.$on('$destroy', locationChangeStart);
    $rootScope.$on('$destroy', routeChangeStart);
  }
})();
