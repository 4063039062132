(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:chatToLaunchTranslator
   * @description Translate ChatDescriptor into Launch data structure
   */
  angular.module('app.core.analytics.launch-descriptor').factory('chatToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, chatDescriptor) {
      const {setProperty} = launchContext;
      setProperty('chat', {});
      setProperty('chat.chatInfo', {
        chatCategory: _.get(chatDescriptor.data, 'event.workflow'),
        chatClientId: _.get(chatDescriptor.data, 'source.client_id'),
        chatContentName: _.get(chatDescriptor.data, 'content.name'),
        chatConversationId: _.get(chatDescriptor.data, 'event.guid'),
        chatEnv: _.get(chatDescriptor, 'environment'),
        chatLanguage: _.get(chatDescriptor.data, 'event.language'),
        chatOrgguid: _.get(chatDescriptor.data, 'event.org_guid'),
      });
    }
  }
})();
