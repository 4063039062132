import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Checkbox, Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useTrustRequests from 'features/settings/hooks/api/useTrustRequests';

const AcceptAccessRequestConfirmationModal = ({accessRequests, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {acceptTrustRequests} = useTrustRequests();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldNotifyAdmins, setShouldNotifyAdmins] = useState(false);

  const handleAcceptAccessRequest = async () => {
    try {
      setIsLoading(true);

      const {failedToAccept} = await acceptTrustRequests(accessRequests, shouldNotifyAdmins);

      if (failedToAccept?.length > 0 && accessRequests.length > 0) {
        showErrorToast(
          intl.formatMessage(
            {
              id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.toast.error.partial',
            },
            {count: accessRequests.length, failedCount: failedToAccept.length}
          )
        );
      } else {
        showSuccessToast(
          intl.formatMessage(
            {
              id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.toast.success',
            },
            {count: accessRequests.length}
          )
        );
      }

      dialog.dismiss();
      onConfirm();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.toast.error',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.button.cancel',
      })}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleAcceptAccessRequest}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.button.reject',
      })}
      title={intl.formatMessage(
        {id: 'settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.title'},
        {
          count: accessRequests.length,
        }
      )}
      variant="confirmation"
    >
      <View marginBottom="size-100">
        <Text>
          <FormattedMessage id="settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.description" />
        </Text>
      </View>

      <View marginBottom="size-100">
        <Text>
          <FormattedMessage id="settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.emailAddressVisible" />
        </Text>
      </View>

      <View marginBottom="size-150">
        <Text>
          <FormattedMessage id="settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.areYouSure" />
        </Text>
      </View>

      <Checkbox onChange={(value) => setShouldNotifyAdmins(value)}>
        <FormattedMessage id="settings.accessRequestsTable.acceptAccessRequestsConfirmationModal.notifyAdminsCheckboxLabel" />
      </Checkbox>
    </ModalAlertDialog>
  );
};

AcceptAccessRequestConfirmationModal.propTypes = {
  accessRequests: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The ID of the directory
       */
      directoryId: PropTypes.string,
      /**
       * The ID of the Owning Org
       */
      owningOrgId: PropTypes.string,
      /**
       * The name of the Owning Org
       */
      owningOrgName: PropTypes.string,
    })
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AcceptAccessRequestConfirmationModal;
