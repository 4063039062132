import {AuthenticatedUser, Product, feature} from '@admin-tribe/binky';
import {EN_DASH} from '@pandora/react-table-section';

import rootStore from 'core/RootStore';
import {canViewProduct, canViewProductLicenseCount} from 'core/products/access/productAccess';
import {shouldShowProduct} from 'core/products/utils/productUtils';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {ROLE} from 'features/users/users.constants';

/**
 *
 * @param {Product | ProductGroup} item - the product / product group
 * @returns {Boolean} True if the product / product group is administerable
 */
function canClickItem(productOrProductGroup) {
  const orgId = rootStore.organizationStore.activeOrgId;

  return (
    (productOrProductGroup?.isAdministerable() ?? false) ||
    (feature.isEnabled('temp_adobe_agent_access') &&
      AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId))
  );
}

/**
 * Hides the pod when the user cannot view the usage quantity (score) values
 * @returns {Boolean} retuns true if the user is not a deployment admin
 */
function canViewUsageQuantity(orgId) {
  return !AuthenticatedUser.get().getRoles().isOnlyRoleForOrg(orgId, ROLE.ADMIN.DEPLOYMENT);
}

/**
 * Hides the pod when the user cannot view the products page
 * @returns {Boolean} whether the user can view the products and licenses pod.
 */
function canViewProductsSummarySection() {
  return canViewProduct();
}

/**
 * @param {Object} intl - react-intl object
 * @param {Product | ProductGroup} item - item to render
 * @returns {String}
 */
function getQuantityInUseString(intl, item) {
  if (item instanceof Product && canViewProductLicenseCount(item)) {
    return intl.formatMessage(
      {id: 'overview.products.pod.usage'},
      {
        total: item.getAssignableLicenseCount(),
        used: item.provisionedQuantity,
      }
    );
  }

  return EN_DASH;
}

/**
 * Method to determine if the Product or ProductGroupList should be shown.
 *
 * @param {Product} product - The product
 * @returns {Boolean} - True if the product's channels include UI.
 */
function shouldShowProductOrProductGroup(productOrProductGroup) {
  const product =
    productOrProductGroup instanceof ProductGroupProductList
      ? productOrProductGroup.items[0]
      : productOrProductGroup;
  return shouldShowProduct(product);
}

export {
  canClickItem,
  canViewProductsSummarySection,
  canViewUsageQuantity,
  getQuantityInUseString,
  shouldShowProductOrProductGroup,
};
