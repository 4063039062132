import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import AddApiCredentialsModal from 'common/components/add-api-credentials-modal/AddApiCredentialsModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';

// The 'Add api credentials' button, and the associated modal to add api credentials
// to the Product profile `API credentials` sub-page.
const AddApiCredentialsButton = observer(() => {
  const intl = useIntl();
  const {pageContext, store} = useMemberListContext();

  return (
    pageContext.isProductConfigurationTarget() &&
    pageContext.isIntegrationTargetType() && (
      <DialogTrigger>
        <Button isDisabled={store.hasLoadingError} variant="accent">
          {intl.formatMessage({id: 'common.userListTableSection.button.addAPICredentials'})}
        </Button>
        <AddApiCredentialsModal
          onSuccess={() => {
            store.load();
          }}
        />
      </DialogTrigger>
    )
  );
});

export default AddApiCredentialsButton;
