import {PACKAGE_TYPE_CONSTANTS} from 'features/packages/packagesConstants';

/**
 * @description Method to check if management options section should be shown
 * @param {Object} options function parameters, described below
 * @param {Boolean} options.isManagementOptionsSwitchSelected - Boolean denoting if management options switch is selected
 * @param {String} options.packageType - package type of package
 * @param {Array} options.selectedPlugins - array of selected plugins
 * @param {Array} options.selectedProducts - Array of selected products
 *
 * @returns {Boolean} true if management options section is to be shown, false otherwise
 */
function showManagementOptions({
  isManagementOptionsSwitchSelected,
  packageType,
  selectedPlugins,
  selectedProducts,
}) {
  if (packageType === PACKAGE_TYPE_CONSTANTS.MANAGED) {
    if (
      selectedProducts.length > 0 ||
      selectedPlugins.length === 0 ||
      (selectedPlugins.length > 0 && isManagementOptionsSwitchSelected)
    ) {
      return true;
    }
    return false;
  }
  return true;
}

/**
 * @description Method to check if management options switch should be shown
 * @param {Object} options function parameters, described below
 * @param {String} packageType - package type of package
 * @param {Array} selectedPlugins - array of selected plugins
 * @param {Array} selectedProducts - Array of selected products
 *
 * @returns {Boolean} true if management options switch is to be shown selected, false otherwise
 */
function showManagementOptionsSwitch({packageType, selectedPlugins, selectedProducts}) {
  return (
    packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
    selectedProducts.length === 0 &&
    selectedPlugins?.length > 0
  );
}

/**
 * @description Method to check if package is lightweight
 * @param {String} packageType - package type of package
 * @param {Number} selectedProductsLength - Array length of selected products
 *
 * @returns {Boolean} true if package is lightweight package, false otherwise
 */
function isLightWeightPackage(packageType, selectedProductsLength) {
  return packageType === PACKAGE_TYPE_CONSTANTS.MANAGED && selectedProductsLength === 0;
}

export {showManagementOptions, showManagementOptionsSwitch, isLightWeightPackage};
