import {Button, DialogTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {isReadOnly} from 'core/organizations/access/organizationAccess';

import AllowlistedDomainAddModal from './AllowlistedDomainAddModal';

/**
 *  Trigger and button to open AllowlistedDomainAddModal
 */
const AllowlistedDomainAddTrigger = ({onCTA}) => {
  const intl = useIntl();

  if (isReadOnly()) {
    return null;
  }

  return (
    <DialogTrigger>
      <Button data-testid="add-domain-btn" variant="cta">
        {intl.formatMessage({
          id: 'settings.assetSettings.allowlistedDomain.table.addButton',
        })}
      </Button>
      <AllowlistedDomainAddModal onCTA={onCTA} />
    </DialogTrigger>
  );
};

AllowlistedDomainAddTrigger.propTypes = {
  /**
   * Callback to invoke when the add confirmation is pressed.
   */
  onCTA: PropTypes.func.isRequired,
};

export default AllowlistedDomainAddTrigger;
