import {ActionButton, Checkbox, Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PluginAlertTooltipIcon from '../plugin-alert-tooltip-icon/PluginAlertTooltipIcon';
import PluginSearchField from '../plugin-searchfield/PluginSearchField';

// All Plugins Top Section displayed above Plugin Cards when Selected Plugins button not clicked, contains components that decide the list of plugin cards to be displayed
const AllPluginsTopSection = ({
  allPluginsLength,
  applicableSelectedProductListLength,
  areIncompatiblePluginsSelected,
  areMaxPluginsSelected,
  fetchExtensionsInCurrentContext,
  hasErrorInFilterApi,
  isFilterApiInProgress,
  pluginSearchString,
  pluginsToShowLength,
  selectedPluginsLength,
  setPluginSearchString,
  setShowCompatiblePluginsOnly,
  setShowSelectedPlugins,
  showCompatiblePluginsOnly,
}) => {
  const intl = useIntl();

  function onPluginSearch(searchText) {
    setPluginSearchString(searchText);
    fetchExtensionsInCurrentContext(false, searchText, showCompatiblePluginsOnly);
  }

  function togglePluginCompatibilityChecks(showCompatiblePlugins) {
    setShowCompatiblePluginsOnly(showCompatiblePlugins);
    fetchExtensionsInCurrentContext(false, pluginSearchString, showCompatiblePlugins);
  }

  function allPluginsSectionLabel() {
    if (pluginSearchString) {
      return hasErrorInFilterApi
        ? ''
        : intl.formatMessage(
            {id: 'packages.createPackage.choosePlugins.searchTitle'},
            {
              allPluginsLength,
              b: (chunks) => <Text UNSAFE_style={{fontWeight: 'bold'}}>{chunks}</Text>,
              pluginSearchString,
            }
          );
    }
    if (pluginsToShowLength > 0) {
      return intl.formatMessage({id: 'packages.createPackage.choosePlugins.titleBusinessPlugins'});
    }
    return hasErrorInFilterApi
      ? ''
      : intl.formatMessage({id: 'packages.createPackage.choosePlugins.noCompatiblePlugins'});
  }

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between">
        <PluginSearchField
          isFilterApiInProgress={isFilterApiInProgress}
          onPluginSearch={onPluginSearch}
          pluginSearchString={pluginSearchString}
        />

        <Flex alignItems="center" gap="size-65" marginTop="size-100">
          <Flex>
            {areMaxPluginsSelected && (
              <PluginAlertTooltipIcon
                hoverText={intl.formatMessage({
                  id: 'packages.createPackage.choosePlugins.maxPluginsTooltip',
                })}
              />
            )}
            {areIncompatiblePluginsSelected && (
              <PluginAlertTooltipIcon
                hoverText={intl.formatMessage({
                  id: 'packages.createPackage.choosePlugins.incompatiblePluginLabel',
                })}
              />
            )}
          </Flex>
          {selectedPluginsLength > 0 && (
            // Adding this div with role status, so that button label is read out by screen reader whenever selectedPluginsLength changes
            <div role="status">
              <ActionButton
                isDisabled={isFilterApiInProgress}
                onPress={() => setShowSelectedPlugins(true)}
              >
                {intl.formatMessage(
                  {id: 'packages.createPackage.choosePlugins.selectedLabel'},
                  {
                    selectedPluginsLength,
                  }
                )}
              </ActionButton>
            </div>
          )}
        </Flex>
      </Flex>

      {!isFilterApiInProgress && (
        <Flex alignItems="baseline" justifyContent="space-between">
          <div role="status">
            <Text>{allPluginsSectionLabel()}</Text>
          </div>
          {applicableSelectedProductListLength > 0 && (
            <Checkbox
              isEmphasized
              isSelected={showCompatiblePluginsOnly}
              onChange={togglePluginCompatibilityChecks}
            >
              {intl.formatMessage({id: 'packages.createPackage.choosePlugins.compatibleLabel'})}
            </Checkbox>
          )}
        </Flex>
      )}
    </Flex>
  );
};

AllPluginsTopSection.propTypes = {
  /**
   * Size of all plugins list
   */
  allPluginsLength: PropTypes.number.isRequired,
  /**
   * Size of applicable selected product list
   */
  applicableSelectedProductListLength: PropTypes.number.isRequired,
  /**
   * Boolean value to indicate if incompatible plugins have been selected
   */
  areIncompatiblePluginsSelected: PropTypes.bool.isRequired,
  /**
   * Boolean value to indicate if maximum number of plugins have been selected
   */
  areMaxPluginsSelected: PropTypes.bool.isRequired,
  /**
   * Function to fetch plugins
   */
  fetchExtensionsInCurrentContext: PropTypes.func.isRequired,
  /**
   * Boolean value to indicate if there was an error on filter(fetch) api call
   */
  hasErrorInFilterApi: PropTypes.bool.isRequired,
  /**
   * Boolean value to indicate if filter(fetch) api call in progress
   */
  isFilterApiInProgress: PropTypes.bool.isRequired,
  /**
   * Submitted text in Plugin Search Field
   */
  pluginSearchString: PropTypes.string.isRequired,
  /**
   * Size of plugins to show list
   */
  pluginsToShowLength: PropTypes.number.isRequired,
  /**
   * Size of selected plugins list
   */
  selectedPluginsLength: PropTypes.number.isRequired,
  /**
   * Function to set plugin search string on submit
   */
  setPluginSearchString: PropTypes.func.isRequired,
  /**
   * Function to set show compatible plugins only
   */
  setShowCompatiblePluginsOnly: PropTypes.func.isRequired,
  /**
   * Function to set show selected plugins
   */
  setShowSelectedPlugins: PropTypes.func.isRequired,

  /**
   * Boolean value to indicate if only compatible plugins are to be shown
   */
  showCompatiblePluginsOnly: PropTypes.bool.isRequired,
};

export default AllPluginsTopSection;
