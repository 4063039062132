import {Divider, Flex, Heading, useDialogContainer} from '@adobe/react-spectrum';
import {ModalContent, ModalDialog} from '@pandora/react-modal-dialog';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import DirectoryEntity from 'common/entities/DirectoryEntity';

const DeleteDirectoriesErrorModal = ({selectedDirectoriesCount, unremovableDirectories}) => {
  const dialog = useDialogContainer();

  return (
    <ModalDialog
      dialogStyle={{
        spaceX: 'size-0',
      }}
      onCancel={() => dialog.dismiss()}
      role="alertdialog"
      size="M"
    >
      <Heading level={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <FormattedMessage
            id="settings.directories.deleteDirectoriesErrorModal.title"
            values={{count: selectedDirectoriesCount}}
          />
          <AlertIcon color="negative" size="M" />
        </Flex>
      </Heading>
      <Divider marginTop="size-250" size="M" />
      <ModalContent>
        <FormattedMessage
          id="settings.directories.deleteDirectoriesErrorModal.description"
          values={{count: selectedDirectoriesCount}}
        />
        {selectedDirectoriesCount > 1 && (
          <ul>
            {unremovableDirectories.map((directory) => (
              <li key={directory.id}>{directory.directoryName}</li>
            ))}
          </ul>
        )}
      </ModalContent>
    </ModalDialog>
  );
};

DeleteDirectoriesErrorModal.propTypes = {
  selectedDirectoriesCount: PropTypes.number.isRequired,
  unremovableDirectories: PropTypes.arrayOf(PropTypes.instanceOf(DirectoryEntity)).isRequired,
};
export default DeleteDirectoriesErrorModal;
