(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appProductCatalogSection
   * @description Component to manage the view of a list of offers
   * @param {Number} [collapsedLimit]. maximum number of items to display when list is collapsed.
   *   Shows all when undefined. Default: undefined.
   * @param {String} key. The key that determines what title and subtitle to display
   * @param {OfferList} offers. A list of offers to display.
   * @param {Function} onAddItem. The callback to invoke when an offer is redeemed from the section.
   * @param {Object} [ownedProducts] Products currently owned by the organization.
   * @param {Boolean} [showPrice]. Boolean value of whether to show the price or not. Default: false.
   *   Default is the number of offers.
   * @param {Integer} [titleCount]. When collapsedLimit is specified, this is the count displayed in the title.
   * @example
   *  <app-product-catalog-section
   *    collapsed-limit="$ctrl.maxWhenCollapsed"
   *    key="some.translation.key"
   *    offers="$ctrl.offers"
   *    on-add-item="$ctrl.onAddItem(offerId)"
   *    owned-products="$ctrl.ownedProducts"
   *    show-price="$ctrl.showPrice">
   *  </app-product-catalog-section>
   */
  angular.module('app.widgets.products.purchase').component('appProductCatalogSection', {
    bindings: {
      collapsedLimit: '@?',
      key: '@',
      offers: '<',
      onAddItem: '&',
      ownedProducts: '<?',
      showPrice: '<?',
      titleCount: '@?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/catalog/product-catalog-section/product-catalog-section.component.html',
  });

  /* @ngInject */
  function controller($scope, $translate, _, AnalyticsHelper) {
    const vm = this;

    const translatedCollapse = $translate.instant(
      'widgets.products.purchase.catalogSection.collapse'
    );
    const translatedViewAll = $translate.instant(
      'widgets.products.purchase.catalogSection.viewAll'
    );

    _.assign(vm, {
      $onChanges,
      $onInit,
      areOffersExpanded: false,
      toggleOffersLabel: translatedViewAll,
      viewLimit: vm.collapsedLimit,
    });

    function $onChanges(changes) {
      // If offers binding is changed, update the title. This is not the same as the offers length changing.
      if (changes.offers) {
        vm.title = getTitle();
      }
    }

    function $onInit() {
      $scope.$watch('$ctrl.offers.length', () => {
        if (vm.collapsedLimit) {
          vm.onToggleOffersClick =
            vm.offers.length > vm.collapsedLimit ? onToggleOffersClick : undefined;
        }
      });

      vm.subtitle = $translate.instant(`${vm.key}.subtitle`);
    }

    function onToggleOffersClick() {
      vm.areOffersExpanded = !vm.areOffersExpanded;
      vm.viewLimit = vm.areOffersExpanded ? undefined : vm.collapsedLimit;
      vm.toggleOffersLabel = vm.areOffersExpanded ? translatedCollapse : translatedViewAll;

      AnalyticsHelper.dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: vm.areOffersExpanded ? 'viewAll' : 'collapse',
      });
    }

    ///////////

    function getTitle() {
      return vm.collapsedLimit
        ? // if there's a collapsed limit, we want to show the initial offer count in the title
          $translate.instant(`${vm.key}.title`, {
            count: _.isEmpty(vm.titleCount) ? _.size(vm.offers) : vm.titleCount,
          })
        : $translate.instant(`${vm.key}.title`);
    }
  }
})();
