/**
 * @deprecated ported to src2/app/features/users/components/directory-user-details-section/DirectoryUserDetailsSection.jsx
 *
 * @ngdoc component
 * @name app.widgets.directories:appDirectoryUserDetailsSection
 *
 * @description The directory user info section in the directory user detail drawer.
 *
 * @binding {Object} user.
 */
(function () {
  const componentName = 'appDirectoryUserDetailsSection';
  angular.module('app.widgets.directories').component(componentName, {
    bindings: {
      user: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-user-details-section/directory-user-details-section.component.html',
  });

  /* @ngInject */
  function controller(_, CountryList) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    /////////////////

    function $onInit() {
      // eslint-disable-next-line promise/catch-or-return
      CountryList.get().$promise.then((countryList) => {
        const country = _.find(countryList.items, ['countryCode', vm.user.countryCode]);
        vm.countryName = _.get(country, 'countryName');
      });
    }
  }
})();
