/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const SORTING_DIRECTIONS = {ASC: 'ASC', DESC: 'DESC'};
const SELECTION_STATUS = {FULL: 'FULL', NONE: 'NONE', PARTIAL: 'PARTIAL'};
const FIRST_PAGE = 1;
const PAGE_SIZES = [
  {label: '10', value: 10},
  {label: '20', value: 20},
  {label: '50', value: 50},
  {label: '100', value: 100},
];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1].value;

const TableConstants = {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  PAGE_SIZES,
  SELECTION_STATUS,
  SORTING_DIRECTIONS,
};

export default TableConstants;
