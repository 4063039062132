/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.bulk-operations:appFilenameDetailSection
 *
 * @description The Filename detail section in the Operation Result details
 *   drawer.
 *
 * @binding {Job} job. Job reference to display details for.
 *
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appFilenameDetailSection', {
    bindings: {
      job: '<',
    },
    templateUrl:
      'app/widgets/bulk-operations/jobs/detail-sections/filename-detail-section/filename-detail-section.component.html',
  });
})();
