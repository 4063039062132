(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.identity').component('appOktaWarningModal', {
    controller,
    templateUrl: 'app/widgets/identity/okta-warning-modal/okta-warning-modal.component.html',
  });

  /* @ngInject */
  function controller(_, $state, $stateParams, APP_OKTA_WARNING_MODAL_ID, auth, panelManager) {
    const vm = this;

    _.assign(vm, {$onInit, onConfirmClicked, onToggleConfirmButtonEnabled});

    function $onInit() {
      _.assign(vm, {
        adminId: auth.getUserProfile().userId,
        isConfirmButtonDisabled: true,
        modalId: APP_OKTA_WARNING_MODAL_ID,
        onEscapeClick: _.noop,
        orgId: $stateParams.orgId,
      });
    }

    function onToggleConfirmButtonEnabled() {
      vm.isConfirmButtonDisabled = !vm.isConfirmButtonDisabled;
    }

    function onConfirmClicked() {
      $state.go('settings.identity');
      panelManager.close(APP_OKTA_WARNING_MODAL_ID);
    }
  }
})();
