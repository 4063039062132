import {AuthenticatedUser, Locale, feature, getTrialContract} from '@admin-tribe/binky';
import partition from 'lodash/partition';
import {when} from 'mobx';

import SharedContextualParams from 'common/services/sophia/shared-contextual-params/SharedContextualParams';
import rootStore from 'core/RootStore';
import {canViewQuickAssignModal} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import auth from 'core/services/auth';

import {
  fromContractResponse,
  fromMigrationsResponse,
  fromProductsResponse,
  fromTrialContractResponse,
  fromTrialProductResponse,
  toMinimumObject,
} from './sophiaContextualParamsUtils';

/**
 * @description Model for managing contextual params required by Sophia (Test & Target)
 * See [organization-level contextual params]{@link https://git.corp.adobe.com/admin-tribe/onesie/wiki/Integration-with-Sophia}
 * See [API Spec]{@link https://wiki.corp.adobe.com/display/SOPHIA/Execution+Layer+API+Spec}
 */
class SophiaContextualParams {
  /**
   * @description instantiates a new instance of SophiaContextualParams.
   * @param {Object} options - options to pass to the SophiaContextualParams
   * @returns {SophiaContextualParams} SophiaContextualParams model object.
   */
  static get(options = {}) {
    const model = new SophiaContextualParams(options);
    return model.refresh();
  }

  constructor(options) {
    Object.assign(this, toMinimumObject(options));

    // Sophia prefers that '' is returned for String values that are N/A.
    // For Number values where 0 doesn't make sense return nothing - no property and no value.
    // Sophia request validation barfs on Number values that are not set to valid numbers.
    // If this is the case we'll get a 400 response with no useful error.
    Object.assign(this, {
      acctLanguage: Locale.get().activeLanguage,
      contractCanAddLicenses: '',
      productLanguage: Locale.get().activeLocaleCode,
      teamBannerShown: '',
    });

    if (feature.isEnabled('temp_sophia_promotional_offer')) {
      Object.assign(this, {
        selectedCancellationReasons: '',
      });
    }
  }

  fetchAndAssignCanViewQuickAssign() {
    const canView = canViewQuickAssignModal();

    Object.assign(this, {
      showQuickAssignProducts: canView,
    });

    return Promise.resolve(this);
  }

  async fetchAndAssignContacts() {
    if (auth.isUserOrgAdmin()) {
      await when(() => !!rootStore.organizationStore.contactList);
      const contactList = rootStore.organizationStore.contactList;

      Object.assign(this, {
        hasAdminCompletedSecurityContactInfo: contactList.isAllContactsComplete(),
      });
    }
    return this;
  }

  /**
   * @description fetches contracts and assigns the contract and trial contract if found.
   * @returns {SophiaContextualParams} SophiaContextualParams model object.
   */
  async fetchAndAssignContracts() {
    await when(() => !!rootStore.organizationStore.contractList);
    const contractList = rootStore.organizationStore.contractList;

    this.contract = contractList.items.find(
      (contract) => contract.isDirectContract() || contract.isIndirectContract()
    );

    if (this.contract) {
      Object.assign(this, fromContractResponse(this.contract));
    }

    const trialContract = getTrialContract(contractList);
    Object.assign(this, fromTrialContractResponse(trialContract));

    Object.assign(this, {
      isAdminContractOwner: contractList.items.some(
        (item) => item.getOwnerUserId() === AuthenticatedUser.get().getId()
      ),
    });

    return this;
  }

  async fetchAndAssignMarketSegment() {
    await when(() => !!rootStore.organizationStore.activeOrg);
    const activeOrg = rootStore.organizationStore.activeOrg;

    Object.assign(this, {
      // remove  with temp_sophia_introductions
      adminRoles: AuthenticatedUser.get()
        .getRoles()
        .roles.filter((role) => role.organization === rootStore.organizationStore.activeOrgId)
        .map((role) => role.named_role)
        .join(','),
      CommonOrgID: activeOrg.id,
      // remove  with temp_sophia_introductions
      organizationMarketSegment: activeOrg.marketSegment,
    });

    return this;
  }

  async fetchAndAssignMigrations() {
    await when(() => !!rootStore.organizationStore.migrationList);
    const migrationList = rootStore.organizationStore.migrationList;

    Object.assign(this, fromMigrationsResponse(migrationList.items));
    return this;
  }

  /**
   * @description fetches and assigns products, splitting into trial products and normal products
   * @returns {SophiaContextualParams} SophiaContextualParams model object.
   */
  async fetchAndAssignProducts() {
    await when(() => !!rootStore.organizationStore.productList);
    const productList = rootStore.organizationStore.productList;

    // Partition into trial products and non-trial products.
    const partitionedProductList = partition(productList.items, (product) =>
      trialHelper.isTrialProduct(product)
    );

    // Trial products. This list may be empty.
    Object.assign(this, fromTrialProductResponse(partitionedProductList[0]));

    // Paid products. This list may be empty.
    Object.assign(this, fromProductsResponse(partitionedProductList[1]));

    return this;
  }

  async fetchAndAssignSharedParams() {
    const sharedParams = await SharedContextualParams.get();

    sharedParams.marketSubSegments = sharedParams.marketSubSegments?.join(',');
    sharedParams.earliestContractStartDate = sharedParams.earliestContractStartDate
      ? new Date(sharedParams.earliestContractStartDate).toISOString()
      : '';

    sharedParams.productFamiliesV2 = [...new Set(sharedParams.productFamilies)];
    sharedParams.productFamilies = '';
    if (feature.isEnabled('temp_disable_product_families_sophia')) {
      sharedParams.productFamiliesV2 = [];
    }

    Object.assign(this, sharedParams);

    return this;
  }

  /**
   * @description configure the contextual params with the contract/products response.
   * @returns {Promise} resolved with this object when params are set from contract/products response.
   * Rejected if either ContractList or ProductList fail, or if there are no applicable contracts.
   */
  async refresh() {
    const tasks = [
      this.fetchAndAssignContracts(),
      this.fetchAndAssignMigrations(),
      this.fetchAndAssignProducts(),
      this.fetchAndAssignMarketSegment(),
      this.fetchAndAssignCanViewQuickAssign(),
      this.fetchAndAssignSharedParams(),
      this.fetchAndAssignContacts(),
    ];
    await Promise.all(tasks);

    return this;
  }

  /**
   * @description reduce model to object containing contextual params
   * @returns {Object} object containing only contextual params.
   */
  toMinimumModel() {
    return toMinimumObject(this);
  }
}

export default SophiaContextualParams;
