/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.trustees:appRevokeTrusteeModal
 * @binding onSuccess. Callback called if revoke trustee is successful (inc. partial success).
 * @binding trusteeList. Trust list filters on ACTIVE status.
 *
 * @description The modal is for the Owning Admin to revoke trustee access.
 */
(function () {
  const componentName = 'appRevokeTrusteeModal';
  angular.module('app.widgets.trustees').component('appRevokeTrusteeModal', {
    bindings: {
      onSuccess: '&',
      trusteeList: '<',
    },
    controller,
    templateUrl: 'app/widgets/trustees/revoke-trustee-modal/revoke-trustee-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $translate,
    _,
    AnalyticsEvent,
    DisplayMessage,
    panelManager,
    REVOKE_TRUSTEE_MODAL_ID,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onModalOpen,
      onRevoke,
    });

    function $onInit() {
      initialize();
    }

    function initialize() {
      _.assign(vm, {
        isBusy: false,
        REVOKE_TRUSTEE_MODAL_ID,
        revokeReason: '',
        waitPromise: $q.resolve(),
      });
    }

    // params.trusts {Array} - the list of Trusts which will have their access revoked
    function onModalOpen(params) {
      initialize();
      _.assign(vm, _.pick(params, ['trusts']));
    }

    function onRevoke() {
      hidePageBanner();
      vm.isBusy = true;

      const revokeReason = _.trim(vm.revokeReason);
      vm.waitPromise = vm.trusteeList
        .revokeAccess(vm.trusts, revokeReason)
        .then(onSuccess.bind(this), () => showPageBanner())
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess(responses) {
        const {completedItems, failedItems} = responses;

        if (failedItems.length > 0) {
          showPageBanner('someRevokeTrusteeRequestsNotSent');
        } else {
          panelManager.close(REVOKE_TRUSTEE_MODAL_ID);
        }

        if (completedItems.length > 0) {
          triggerAnalytics({trusteeList: completedItems});
          showRevokedToast({
            count: completedItems.length,
            trusteeOrgName: vm.trusts[0].trusteeOrgName,
          });
        }

        vm.onSuccess();
      }
    }

    ///////////

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function showPageBanner(msg) {
      vm.displayMessage = new DisplayMessage({
        body: msg
          ? $translate.instant(`widgets.trustees.revokeTrusteeModal.pageBanner.${msg}`)
          : undefined,
      });
    }

    function showRevokedToast(options) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.trustees.revokeTrusteeModal.toast.messageFormat.revokeTrustee',
          options,
          'messageformat'
        )
      );
    }

    /**
     * Dispatches analytics for revoke trustee(s) event
     *
     * @param {Object} [options={}] - object transformed into analytics attributes
     */
    function triggerAnalytics(options = {}) {
      AnalyticsEvent.dispatch({
        attributes: {
          trusteeListCount: _.get(options, 'trusteeList.length'),
        },
        componentMethod: 'revoke',
        componentMethodType: 'submit',
        componentName,
      });
    }
  }
})();
