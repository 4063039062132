import {download, jilProducts} from '@admin-tribe/binky';
import {showError, showInfo} from '@admin-tribe/binky-ui';
import {Button, Text} from '@adobe/react-spectrum';
import ExportIcon from '@spectrum-icons/workflow/Export';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Button for performing the product CSV export from the product overview page.
 */
const ExportProductsButton = () => {
  const intl = useIntl();

  const [isDisabled, setIsDisabled] = useState(false);

  const onExportCallback = async () => {
    setIsDisabled(true);
    showInfo(intl.formatMessage({id: 'products.exportProductsButton.downloadBeingPrepared'}));
    try {
      const response = await jilProducts.exportProducts({
        orgId: rootStore.organizationStore.activeOrgId,
      });
      download(response.data.file, 'products.csv');
    } catch (error) {
      showError();
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Button
      data-testid="export-products-button"
      isDisabled={isDisabled}
      onPress={() => onExportCallback()}
    >
      <ExportIcon />
      <Text>{intl.formatMessage({id: 'products.exportProductsButton.label'})}</Text>
    </Button>
  );
};

export default ExportProductsButton;
