(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories.sync:appDirectorySyncOrgInfo
   * @param {String}  confirmationTextKey Translation key of the text to display under the title.
   * @param {String}  directoryName Directory name user entered on the first step.
   * @param {Object}  display An object defining which details to show. See below.
   * @param {Boolean} [display.directoryName] The directory name the user chose on the first step. Defaults to true.
   * @param {Boolean} [display.userLoginSetting] The User Login setting. Defaults to false. This requires the userLoginSettingKey for the value.
   * @param {Boolean} [display.orgName] The name of the organization to be synced. Defaults to true.
   * @param {Boolean} [display.orgId] The id of the organization to be synced. Defaults to false.
   * @param {Boolean} [display.protocol] The protocol used to authenticate users. Defaults to false. This requires the protocolKey for the value.
   * @param {Object}  orgDetails Object containing retrieved organization details from sync service.
   * @param {String}  protocolKey Translation key of the used protocol.
   * @param {String}  userLoginSettingKey Translation key of the used user login setting.
   * @description display list of owned and trusted domains for an organization.
   */
  angular.module('app.widgets.directories.sync').component('appDirectorySyncOrgInfo', {
    bindings: {
      confirmationTextKey: '<?',
      directoryName: '<',
      display: '<?',
      orgDetails: '<',
      protocolKey: '<?',
      userLoginSettingKey: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/directories/sync/directory-sync-org-info/directory-sync-org-info.component.html',
  });

  /* @ngInject */
  function controller(_, translationUtils) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getConfirmationText,
      getProtocol,
      getUserLoginSetting,
    });

    function $onInit() {
      vm.orgId = vm.orgDetails.organization.id;
      vm.orgName = vm.orgDetails.organization.name;

      vm.display = _.defaults(vm.display, {
        directoryName: true,
        orgName: true,
      });
    }

    function getConfirmationText() {
      return translationUtils.sanitizeSafeInstant(vm.confirmationTextKey);
    }

    function getUserLoginSetting() {
      return translationUtils.sanitizeSafeInstant(vm.userLoginSettingKey);
    }

    function getProtocol() {
      return translationUtils.sanitizeSafeInstant(vm.protocolKey);
    }
  }
})();
