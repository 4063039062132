import {DialogContainer} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import React, {useCallback, useState} from 'react';

import rootStore from 'core/RootStore';

import AppIntegrationsPolicyDrawer from '../../components/AppIntegrationsPolicyDrawer';
import AppIntegrationsPolicyModal from '../../components/AppIntegrationsPolicyModal';
import AppIntegrationsTableActions from '../../components/table/AppIntegrationsTableActions';
import AppIntegrationsTableSection from '../../components/table/AppIntegrationsTableSection';
import {AppIntegrationTableContextProvider} from '../../hooks/AppIntegrationTableContext';

/**
 * @description The subpage for /products/application-integrations/policies route
 *
 * @returns AppIntegrationsPoliciesSubpage component
 */
const AppIntegrationsPoliciesSubpage = () => {
  let openDrawerFn;

  // This tracks the specific app integration clicked by the table.
  const [appIntegration, setAppIntegration] = useState();
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const onViewPolicyDetailsPress = useCallback(
    (clickedAppIntegration) => {
      setAppIntegration(clickedAppIntegration);
      openDrawerFn();
    },
    [openDrawerFn]
  );

  const onManagePolicyPress = useCallback((clickedAppIntegration) => {
    setAppIntegration(clickedAppIntegration);
    setIsPolicyModalOpen(true);
  }, []);

  return (
    <AppIntegrationTableContextProvider
      hasPolicies
      onManagePolicyPress={onManagePolicyPress}
      onViewPolicyDetailsPress={onViewPolicyDetailsPress}
      orgId={rootStore.organizationStore.activeOrgId}
    >
      <AppIntegrationsTableSection hasPolicies>
        {/* Normally TableActions would be inside the TableSection but to prevent cyclical import it is hoisted here */}
        <AppIntegrationsTableActions />
      </AppIntegrationsTableSection>
      <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
        {/* Intercept the function to open the drawer -- DialogContainer incompatible with Pandora's Drawer */}
        {(openDrawerFnFromTrigger) => {
          openDrawerFn = openDrawerFnFromTrigger;
        }}
        <AppIntegrationsPolicyDrawer clientId={appIntegration?.clientIds[0]} />
      </DrawerTrigger>
      <DialogContainer onDismiss={() => setIsPolicyModalOpen(false)}>
        {isPolicyModalOpen && <AppIntegrationsPolicyModal appIntegration={appIntegration} />}
      </DialogContainer>
    </AppIntegrationTableContextProvider>
  );
};
export default AppIntegrationsPoliciesSubpage;
