import {log} from '@admin-tribe/binky';
import {formatFileSize, formatPercentage} from '@admin-tribe/binky-ui';
import {Flex, Meter} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import StorageStats from 'common/services/storage-stats/StorageStats';
import {canViewStorage} from 'core/storage/access/storageAccess';
import ManageButton from 'features/overview/components/manage-button/ManageButton';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';
import {goToStorageOverview} from 'features/storage/routing/navigation-callbacks/navigationCallbacks';

/**
 * The Storage Summary Section component
 */
const StorageSummarySection = () => {
  const intl = useIntl();

  const [usagePercentage, setUsagePercentage] = useState(0);
  const [usageSummary, setUsageSummary] = useState();
  const [percentageRemaining, setPercentageRemaining] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [meterVariant, setMeterVariant] = useState('positive');

  const loadStorageStats = useCallback(async () => {
    const canViewStorageSummary = await canViewStorage();
    if (!canViewStorageSummary) {
      // returning null will make the pod not render if the user cannot view storage
      return null;
    }

    // Don't call get() because we want a non-cached model
    // calling StorageStats.get() can return a cached error,
    // since this is a reload button, we want a fresh call
    return new StorageStats().refresh();
  }, []);

  const {error, isLoading, model, updateModel} = useAsyncModel({
    loadFn: loadStorageStats,
  });

  const canViewStorageSection = useMemo(
    () => errorMessage || isLoading || model,
    [errorMessage, isLoading, model]
  );

  // sets error message text when api fails
  useEffect(() => {
    if (error) {
      if (error.response?.status === 404) {
        setErrorMessage(intl.formatMessage({id: 'overview.storage.pod.updating'}));
      } else {
        setErrorMessage(intl.formatMessage({id: 'overview.storage.pod.error'}));
      }
    }
  }, [error, intl]);

  // update counts when model is fetched
  useEffect(() => {
    if (!model) {
      return;
    }

    let amount, isOverStorageUsage, storageUsagePercentage, total;

    try {
      ({amount, total} = model.getUsageSummary());
      isOverStorageUsage = model.isOverUsage();
      storageUsagePercentage = model.getUsagePercentage();
    } catch (_error) {
      log.error(_error);
      setErrorMessage(intl.formatMessage({id: 'overview.storage.pod.error'}));
      return;
    }

    setMeterVariant(isOverStorageUsage ? 'warning' : 'positive');
    setUsagePercentage(storageUsagePercentage);
    setUsageSummary(
      intl.formatMessage(
        {id: 'overview.storage.pod.usage'},
        {
          amountFileSize: formatFileSize(intl, amount),
          totalFileSize: formatFileSize(intl, total),
        }
      )
    );

    let remaining = Math.floor(100 - storageUsagePercentage);
    if (remaining < 0) {
      remaining = 0;
    }

    setPercentageRemaining(
      intl.formatMessage(
        {id: 'overview.storage.pod.remaining'},
        {percentage: formatPercentage(intl, remaining)}
      )
    );
  }, [intl, model]);

  // When the user cannot view storage, don't render the pod.
  // This can happen when the org has no products with storage,
  // or the user is not an org admin or storage admin
  if (!canViewStorageSection) {
    return null;
  }

  return (
    <OverviewPod
      data-testid="storage-summary-section"
      errorMessage={errorMessage}
      isLoading={isLoading}
      onReload={() => updateModel(loadStorageStats)}
      rightHeaderContent={
        <ManageButton
          aria-label={intl.formatMessage({id: 'overview.storage.pod.manageAriaLabel'})}
          data-testid="manage-storage-link"
          onPress={goToStorageOverview}
        />
      }
      title={intl.formatMessage({id: 'overview.storage.pod.title'})}
      tooltipDescription={intl.formatMessage({id: 'overview.storage.pod.info'})}
    >
      <Flex alignItems="center" justifyContent="center" marginX="size-150" minHeight="size-900">
        {usageSummary ? ( // only show meter if usage summary to report
          <Meter
            label={usageSummary}
            value={usagePercentage}
            valueLabel={percentageRemaining}
            variant={meterVariant}
            width="100%"
          />
        ) : null}
      </Flex>
    </OverviewPod>
  );
};

StorageSummarySection.propTypes = {};

export default StorageSummarySection;
