// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {ActionButton, Badge, Flex, Heading, Image, Text, View} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import Close from '@spectrum-icons/workflow/Close';
import Label from '@spectrum-icons/workflow/Label';
import PropTypes from 'prop-types';
import React from 'react';

import TermsAndConditionsHoverOverTooltip from 'common/components/sophia/sophia-terms-and-condition/TermsAndConditionsHoverOverTooltip';
import SophiaCard from 'common/services/sophia/SophiaCard';

import styles from './SophiaRightRailMerchandisingView.pcss';
import ActionsSection from './sophia-actions/ActionsSection';
import RegularPriceInfo from './sophia-price/RegularPriceInfo';
import SpotlightPriceInfo from './sophia-price/SpotlightPriceInfo';
import StrikethroughPriceInfo from './sophia-price/StrikethroughPriceInfo';
import TermsAndConditionsDialogTrigger from './sophia-terms-and-condition/TermsAndConditionsDialogTrigger';

// eslint-disable-next-line complexity -- Complex component
const SophiaRightRailMerchandisingView = ({sophiaContent, onDismiss}) => {
  const renderCloseAction = () => (
    <ActionButton
      data-testid="close-action"
      isQuiet
      onPress={onDismiss}
      position="absolute"
      right="size-0"
      top="size-65"
      variant="primary"
    >
      <Close size="S" />
    </ActionButton>
  );

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      padding="size-400"
      position="relative"
      UNSAFE_style={{
        backgroundColor: sophiaContent.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
    >
      {sophiaContent.backgroundImage && (
        <View
          borderTopEndRadius="regular"
          borderTopStartRadius="regular"
          data-testid="commerce-value-action-background-image"
          height="static-size-65"
          left="size-0"
          position="absolute"
          top="size-0"
          UNSAFE_style={{
            background: `url(${sophiaContent.backgroundImage})`,
            backgroundSize: 'cover',
          }}
          width="100%"
        />
      )}

      {onDismiss && renderCloseAction()}

      <View data-testid="one-console-header" position="relative">
        {sophiaContent.imageUrl && (
          <View>
            <Image
              alt={sophiaContent.imageAlt || ''}
              data-testid="one-console-icon"
              height="auto"
              objectFit="cover"
              src={sophiaContent.imageUrl}
              width="size-400"
            />
          </View>
        )}
        <View
          data-testid="one-console-badge"
          isHidden={!sophiaContent.tag}
          position="absolute"
          right="calc(size-0 - size-400)"
          top="size-50"
        >
          <Badge
            data-testid="one-console-badge"
            height="auto"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to apply custom style
            UNSAFE_className={styles.badge}
            variant={sophiaContent.badgeType}
          >
            <Label aria-label="note" />
            <Text>{sophiaContent.tag}</Text>
          </Badge>
        </View>
      </View>
      <View
        borderBottomWidth="thin"
        borderColor="gray-300"
        data-testid="one-console-content"
        marginBottom="30px"
      >
        <Flex direction="column">
          <Heading data-testid="one-console-title" level={3}>
            <SafeHtml dataTestId="one-console-title-text" html={sophiaContent.title} />
          </Heading>
          {sophiaContent.description && (
            <>
              <View
                data-testid="one-console-description"
                marginBottom="18px"
                UNSAFE_style={{
                  fontSize: 'var(--spectrum-global-dimension-font-size-100)',
                }}
              >
                <SafeHtml
                  dataTestId="one-console-description-text"
                  html={sophiaContent.description}
                />
              </View>
              {!sophiaContent.disclosure && (
                <View data-testid="one-console-terms" marginBottom="size-300">
                  <TermsAndConditionsDialogTrigger
                    linkText={sophiaContent.termsLabel}
                    termsAndConditions={sophiaContent.termsAndConditions}
                    termsHeading={sophiaContent.termsHeading}
                  />
                </View>
              )}
              {sophiaContent.disclosure && (
                <TermsAndConditionsHoverOverTooltip
                  hoverOverText={sophiaContent.hoverOverText}
                  htmlContent={sophiaContent.disclosure}
                  showTerms={sophiaContent.displayTerms}
                />
              )}
            </>
          )}
        </Flex>
      </View>
      <Flex
        alignItems="center"
        data-testid="one-console-footer"
        gap={{base: 'size-100', S: 'size-0'}}
        justifyContent="space-between"
        marginTop="size-175"
      >
        <Flex alignItems="start" data-testid="one-console-price" direction="column">
          {sophiaContent.regularPrice && (
            <View data-testid="one-console-base-price" marginBottom={{base: 'size-100', S: 'none'}}>
              {sophiaContent.discountedPrice ? (
                <StrikethroughPriceInfo
                  frequency={sophiaContent.priceFrequency}
                  price={sophiaContent.regularPrice}
                  strikethroughColor={sophiaContent.strikethroughColor}
                />
              ) : (
                <RegularPriceInfo
                  displayExcludingVAT={sophiaContent.displayExcludingVAT}
                  displayPriceStartingAt={sophiaContent.displayPriceStartingAt}
                  frequency={sophiaContent.priceFrequency}
                  price={sophiaContent.regularPrice}
                />
              )}
            </View>
          )}
          {sophiaContent.discountedPrice && (
            <View
              data-testid="one-console-discount-price"
              marginBottom={{base: 'size-100', S: 'none'}}
            >
              <SpotlightPriceInfo
                color={sophiaContent.discountPriceColor}
                frequency={sophiaContent.priceFrequency}
                price={sophiaContent.discountedPrice}
              />
            </View>
          )}
        </Flex>

        <ActionsSection
          primaryCTAAction={sophiaContent.primaryCTAAction}
          primaryCTALabel={sophiaContent.primaryCTALabel}
          secondaryCTAAction={sophiaContent.secondaryCTAAction}
          secondaryCTALabel={sophiaContent.secondaryCTALabel}
        />
      </Flex>
    </View>
  );
};

SophiaRightRailMerchandisingView.propTypes = {
  onDismiss: PropTypes.func,
  sophiaContent: PropTypes.oneOfType([PropTypes.instanceOf(SophiaCard), PropTypes.object]),
};

export default SophiaRightRailMerchandisingView;
