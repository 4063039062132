(function () {
  /**
   * @deprecated moved to src2 as <ExpertSessionInfoBar>
   *
   * @ngdoc component
   * @name app.widgets.support:appExpertSessionInfoBar
   * @description renders basic information about an expert session in an info-bar
   */
  angular.module('app.widgets.support').component('appExpertSessionInfoBar', {
    bindings: {
      expertSession: '<',
    },
    templateUrl:
      'app/widgets/support/expert-session-info-bar/expert-session-info-bar.component.html',
  });
})();
