(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.search-list:appSearchList
   *
   * @description Wrapper for Coral Button list
   * @param {Number} [debounceTime] number for indicating input's debounce time. It's defaulted to 400.
   * @param {Boolean} [disabled] boolean for disabling the search list
   * @param {String} [disabledTooltipContent] tooltip string to show when in disabled state.
   * @param {Boolean} [invalid] boolean for setting validity of search list
   * @param {String} [invalidTooltipMessage] the tooltip message shown when the search list is not in a valid state
   * @param {String} [labelId] reference to a space delimited set of ids for the HTML elements that provide a label for the formField
   * @param {Boolean} [showClearButton] boolean for showing the clear search button. Default to true
   * @param {Boolean} [showDisabledIconTooltip] boolean for showing the disabled icon tooltip. Does not show if search list is invalid. Default to false
   * @param {Boolean} [showInvalidInput] boolean for showing input's invalid attr if the invalidTooltipMessage is defined
   * @param {Boolean} [showLoadingIndicator] boolean for showing the spinner icon while performing the search
   * @param {Function} [onChange] callback when a list item is selected
   * @param {Function} [onSearch] callback to perform the search
   * @param {String} [placeholderText] the placeholder message shown when the search input is cleared
   * @param {String} [searchQuery] the search string to be initially set as
   * @param {String} [selectedItem] the current selected value of the search list
   * @param {Boolean} [showChevron] boolean for showing the chevron icon to reveal the list
   * @param {Boolean} [showOverlay] boolean for showing the overlay when an item is selected
   * @param {Boolean} [showSearchResultsContainer] boolean for showing the search results container. Default to true
   * @param {Boolean} [showWaitSpinner] boolean for showing the wait spinner inside the input when onSearch is triggered
   * @param {Promise} [waitSpinnerPromise] promise for showing the wait spinner inside the input when triggered by external
   * pending factors that are not related to search input. Default is undefined.
   */

  angular.module('app.widgets.search-list').component('appSearchList', {
    bindings: {
      debounceTime: '<?',
      disabled: '@?',
      disabledTooltipContent: '@?',
      invalid: '<?',
      invalidTooltipMessage: '@?',
      labelId: '<?',
      onChange: '&?',
      onSearch: '&?',
      placeholderText: '@?',
      searchQuery: '<?',
      selectedItem: '<?',
      showChevron: '<?',
      showClearButton: '<?',
      showDisabledIconTooltip: '<?',
      showInvalidInput: '<?',
      showLoadingIndicator: '<?',
      showOverlay: '<?',
      showSearchResultsContainer: '<?',
      showWaitSpinner: '<?',
      waitSpinnerPromise: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/search-list/search-list.component.html',
    transclude: true,
  });

  const DEFAULT_DEBOUNCE_TIME = 400;

  function controller(_, $element, $log, $q, $timeout, $translate, SEARCH_QUERY_MIN_LENGTH) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $postLink,
      debounceTime: _.isNumber(vm.debounceTime) ? vm.debounceTime : DEFAULT_DEBOUNCE_TIME,
      foundSearchResults: false,
      onArrowKeysUpOrDown,
      onChevronClick,
      onClearSearch,
      onInputChange,
      searchQuery: _.defaultTo(vm.searchQuery, ''),
      showClearButton: _.defaultTo(vm.showClearButton, true),
      showClearSearchButton,
      showDisabledIcon,
      showDisabledIconTooltip: _.defaultTo(vm.showDisabledIconTooltip, false),
      showInvalidInput: vm.showInvalidInput,
      showSearchResultsContainer: _.defaultTo(vm.showSearchResultsContainer, true),
      showWaitSpinner: vm.showWaitSpinner,
      waitPromise: $q.resolve(),
    });

    function $postLink() {
      if (!_.isUndefined(vm.selectedItem)) {
        // Wait for the list-items to be transcluded
        $timeout(() => {
          const item = $element[0].querySelectorAll(
            `.search-result-list-items > list-item[value="${vm.selectedItem}"]`
          )[0];
          if (item) {
            selectItem(item);
          }
        });
      }
    }

    function $onChanges(changesObj) {
      if (changesObj.invalid) {
        vm.invalidTooltipContent = vm.invalid ? vm.invalidTooltipMessage : '';
      }
      if (changesObj.waitSpinnerPromise) {
        vm.waitPromise = changesObj.waitSpinnerPromise.currentValue;
      }
    }

    /////////////

    function clearOverlay() {
      const overlay = $element.find('coral-overlay');
      overlay.empty();
      vm.searchItemSelected = false;
    }

    function clearSearchResults() {
      vm.searchQuery = '';
      vm.foundSearchResults = false;
    }

    function createCoralButtonListItem(isHeaderItem) {
      const coralListItem = angular.element(new Coral.ButtonList.Item());
      // Explicitly set the type="button", otherwise in forms it defaults to type="submit"
      // which has a side effect of sending "click" events to the button when enter is typed
      // See https://git.corp.adobe.com/admin-tribe/onesie/issues/2471
      coralListItem[0].type = 'button';
      coralListItem[0].disabled = isHeaderItem;
      return coralListItem;
    }

    function createHeaderRow() {
      // create the header at the top
      const coralList = $element.find('coral-button-list');
      const coralHeaderListItem = createCoralButtonListItem(true);
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralHeaderListItem.attr('disabled', true);
      coralHeaderListItem[0].content = coralListItemContent[0];
      const continueTypingOrSelect = $translate.instant(
        'widgets.searchList.continueTypingOrSelect'
      );
      coralListItemContent.html(`<div class="header">${continueTypingOrSelect}</div>`);
      coralList.prepend(coralHeaderListItem);
    }

    function createListItemRow(item) {
      const coralListItem = createCoralButtonListItem(false);
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralListItem[0].content = coralListItemContent[0];
      coralListItemContent.html(item.innerHTML);
      // if the item is selected using the mouse or by pressing the
      // enter key, trigger the callback
      coralListItem.on('mousedown', () => {
        $timeout(() => {
          onSelectListItem(item);
        });
      });
      coralListItem.on('keypress', ($event) => {
        if ($event.keyCode === 13) {
          $timeout(() => {
            onSelectListItem(item);
          });
        }
      });
      return coralListItem;
    }

    function createLoadingRow() {
      const coralListItem = angular.element(new Coral.List.Item());
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      const coralWait = angular.element(new Coral.Wait());
      coralWait.attr('id', 'loading-row-wait');
      coralWait.attr('size', 'S');
      coralWait.attr('centered', true);
      coralListItem[0].content = coralListItemContent[0];
      coralListItem.attr('disabled', true);
      coralListItemContent.append(coralWait);
      return coralListItem;
    }

    function focusListItem(n, $event) {
      const autocompleteFirstButton = $element[0].querySelector(
        `.search-results-list > button:nth-child(${n}n)`
      );
      const autocompleteListEl = angular.element(autocompleteFirstButton);
      if (!_.isEmpty(autocompleteListEl)) {
        autocompleteListEl[0].focus();
        $event.preventDefault();
      }
    }

    function getInput() {
      return angular.element($element[0].querySelector('.searchlist-input'));
    }

    function hideResults() {
      vm.foundSearchResults = false;
      const coralList = $element.find('coral-button-list');
      coralList.empty();
    }

    let itemFocusIndex = 1;
    function onArrowKeysUpOrDown($event) {
      const searchResults = angular.element(
        $element[0].querySelectorAll('.search-result-list-items > list-item')
      );
      if (_.isEmpty(searchResults)) {
        return;
      }
      if ($event.keyCode === 38) {
        // up-arrow
        itemFocusIndex = _.max([1, itemFocusIndex - 1]);
        if (itemFocusIndex === 1) {
          getInput().focus();
        }
      } else if ($event.keyCode === 40) {
        // down-arrow
        itemFocusIndex = _.min([itemFocusIndex + 1, searchResults.length + 1]);
      }
      if ($event.keyCode === 38 || $event.keyCode === 40) {
        focusListItem(itemFocusIndex, $event);
      }
    }

    function onChevronClick() {
      // enable input and make it focused
      getInput().focus();
      if (vm.foundSearchResults) {
        // hide and clear search results
        clearSearchResults();
        hideResults();
      } else if (vm.searchItemSelected) {
        // clear overlay for the selected item
        clearOverlay();
        onInputChange();
      } else {
        onInputChange();
      }
      // invoke the onChange() with an empty query
      if (vm.onChange) {
        vm.onChange({
          value: '',
        });
      }
    }

    function onClearSearch() {
      // hide and clear search results
      clearSearchResults();
      hideResults();
      // clear the overlay for the selected item
      clearOverlay();
      // enable input and make it focused
      getInput().focus();
      // invoke the onChange() with an empty query
      if (vm.onChange) {
        vm.onChange({
          value: '',
        });
      }
    }

    function onInputChange() {
      if (vm.searchQuery.length < SEARCH_QUERY_MIN_LENGTH) {
        vm.invalid = false;
        vm.invalidTooltipContent = '';
        vm.invalidTooltipMessage = '';
      } else {
        // clear the previous search results
        const coralList = $element.find('coral-button-list');
        coralList.empty();
        // show the loading spinner
        if (vm.showLoadingIndicator) {
          coralList.append(createLoadingRow());
        }
        // display the results (to show the spinner)
        showResults();
      }
      // invoke the onSearch() which should return a promise
      if (vm.onSearch) {
        const searchPromise =
          vm.onSearch({
            searchQuery: _.trim(vm.searchQuery),
          }) || $q.resolve();
        const inputChangePromise = searchPromise.then(onSearchSuccess).catch(onSearchError);
        if (vm.showWaitSpinner) {
          const waitPromises = [inputChangePromise];
          if (vm.waitSpinnerPromise) {
            waitPromises.push(vm.waitSpinnerPromise);
          }
          vm.waitPromise = $q.all(waitPromises);
        }
      }
    }

    function onSearchError(error) {
      hideResults();
      vm.invalid = true;
      vm.invalidTooltipContent = $translate.instant('widgets.searchList.genericErrorMessage');
      $log.error('Failed to search the list. Error: ', error);
    }

    function onSearchSuccess() {
      // input is cleared and chevron is not shown
      if (!vm.showChevron && _.isEmpty(vm.searchQuery)) {
        hideResults();
      } else {
        // Timeout in order to trigger an Angular digest cycle
        $timeout(() => {
          const searchResults = angular.element(
            $element[0].querySelectorAll('.search-result-list-items > list-item')
          );
          // show search results if there is no error message set by the client
          if (_.isEmpty(vm.invalidTooltipMessage)) {
            // clear the previous search results
            const coralList = $element.find('coral-button-list');
            coralList.empty();
            // append results
            _.forEach(searchResults, (item) => {
              coralList.append(createListItemRow(item));
            });
            // display search results
            showResults();
          } else {
            vm.invalid = true;
            vm.invalidTooltipContent = vm.invalidTooltipMessage;
            hideResults();
          }
        });
      }
    }

    function onSelectListItem(item) {
      selectItem(item);
      // hide and clear search results
      clearSearchResults();
      hideResults();
      // invoke the onChange()
      if (vm.onChange) {
        vm.selectedItem = item.attributes.value.value;
        vm.onChange({
          value: vm.selectedItem,
        });
      }
      // focus on the input if overlay is not shown
      if (!vm.showOverlay) {
        getInput().focus();
      }
    }

    function renderOverlay(item) {
      const overlay = $element.find('coral-overlay');
      const coralListItem = angular.element(new Coral.List.Item());
      coralListItem[0].disabled = true;
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralListItemContent.html(item.innerHTML);
      coralListItem[0].content = coralListItemContent[0];
      overlay.empty().append(coralListItem[0]);
      vm.searchItemSelected = true;
    }

    function selectItem(item) {
      vm.searchItemSelected = true;
      // populate the overlay content
      if (vm.showOverlay) {
        renderOverlay(item);
      }
    }

    function showClearSearchButton() {
      return (
        vm.showClearButton &&
        (vm.searchItemSelected || (!vm.showChevron && !_.isEmpty(vm.searchQuery))) &&
        (!vm.showSearchResultsContainer ||
          (!vm.showChevron &&
            ((vm.foundSearchResults && !vm.invalid) || (vm.searchItemSelected && vm.showOverlay))))
      );
    }

    function showDisabledIcon() {
      return vm.showDisabledIconTooltip && vm.disabled && !vm.invalid && vm.disabledTooltipContent;
    }

    function showResults() {
      itemFocusIndex = 1;
      vm.invalid = false;
      vm.invalidTooltipContent = '';
      vm.foundSearchResults = true;
      createHeaderRow();
    }
  }
})();
