(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name IMS Federated constants
   * @description defines constants related to IMS Federated service.
   */
  angular.module('app.core.api.ims-federated').constant('AUTH_SOURCE_TYPES', {
    FEDERATED: 'fed',
  });
})();
