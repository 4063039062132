import {Product} from '@admin-tribe/binky';
import ShoppingCartIcon from '@spectrum-icons/workflow/ShoppingCart';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {canBuyTrialProduct} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';

import QuickLink from '../QuickLink';

const ConvertTrialQuickLink = ({trialProduct}) => {
  const intl = useIntl();

  return (
    <QuickLink
      analyticsOptions={{
        analyticsAction: 'go-to-trial-conversion',
        productList: {
          items: [
            {
              id: trialProduct.id,
              licenseCount: trialProduct.getAssignableLicenseCount(),
              licenseType: trialProduct.licenseType,
              numberUnassigned: trialProduct.getAvailableLicenseCount(),
              offer_id: trialProduct.offerId,
              pricing: trialProduct.pricing,
              productName: trialProduct.longName,
            },
          ],
        },
      }}
      data-testid="quick-link-convert-trial"
      Icon={<ShoppingCartIcon size="M" />}
      label={intl.formatMessage({id: 'overview.quickLinks.convertTrial.title'})}
      onPress={() => trialHelper.openBuyNowUrl(trialProduct?.id)}
    />
  );
};

ConvertTrialQuickLink.propTypes = {
  trialProduct: PropTypes.instanceOf(Product).isRequired,
};

ConvertTrialQuickLink.get = () => {
  const trialProduct = trialHelper.getTrialProduct() || trialHelper.getDxTrialProduct();

  if (!isAllowedToAddProducts() && canBuyTrialProduct(trialProduct)) {
    return Promise.resolve(
      <ConvertTrialQuickLink key="ConvertTrialQuickLink" trialProduct={trialProduct} />
    );
  }

  return Promise.reject(new Error('User cannot add products or buy trial products'));
};

export default ConvertTrialQuickLink;
