const JIL_STORAGE_FOLDER_PATH = {
  RECLAIMED: 'reclaimed',
};

const JIL_STORAGE_FOLDER_SORT = {
  CREATED_DATE: 'CREATED_DATE',
  DIRECTORY_NAME: 'DIRECTORY_NAME',
};

const JIL_STORAGE_TYPE = {
  ASSETS: 'ASSETS',
  REPORTS: 'REPORTS',
  USERS: 'USERS',
};

export {JIL_STORAGE_FOLDER_PATH, JIL_STORAGE_FOLDER_SORT, JIL_STORAGE_TYPE};
