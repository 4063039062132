(function () {
  /**
   * @ngdoc module
   * @name app.widgets.trial-tag
   * @description module that wraps an app tag with some styling tweaks for trials
   */
  angular.module('app.widgets.trial-tag', [
    'app.widgets.tag',
    'binky.core.lodash',
    'pascalprecht.translate',
  ]);
})();
