(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name imsFederated
   * @description defines service to manage IMS Federated service calls
   */
  angular.module('app.core.api.ims-federated').provider('imsFederated', imsFederatedProvider);

  let acsUrl, url;

  function imsFederatedProvider() {
    this.configure = configure;
    this.$get = $get;

    function configure(config) {
      url = `${config.url}`;
      acsUrl = `${config.acsUrl}`;
    }

    function $get($resource, apiUtils) {
      const service = {
        acsUrl,
        authSources: getAuthSources(),
        idps: getIdps,
      };

      return service;

      ///////////

      function getAuthSources() {
        return $resource(`${url}/organizations/:imsOrgId/authSources/:authSourceId`, {
          authSourceId: '@authSourceId',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function getIdps(authSourceId) {
        return $resource(
          `${url}/organizations/:imsOrgId/authSources/:authSourceId/idps/:idpId`,
          {
            authSourceId,
            idpId: '@idpId',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            uploadMetadata: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
