(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ConfigurationMemberRoles
   * @description Model for the product member roles
   */
  angular
    .module('app.core.product.configurations.member-roles')
    .factory('ConfigurationMemberRoles', getConfigurationMemberRolesFactory);

  /* @ngInject */
  function getConfigurationMemberRolesFactory($q, _, binkySrc2, OrganizationManager) {
    class ConfigurationMemberRoles {
      /**
       * @class
       * @description Creates a new Member Roles block for use
       *
       * @param {String} productId - Id of the product
       * @param {String} groupId - Id of the group (configuration)
       * @param {Array} memberIds - Ids of the group members to fetch roles for
       */
      constructor(productId, groupId, memberIds) {
        this.productId = productId;
        this.groupId = groupId;
        this.memberIds = memberIds;
        this.memberRoles = {};
        this.savedState = {};
        this.src2MemberConfigurationRoles =
          new binkySrc2.services.product.licenseGroup.memberConfiguration.MemberConfigurationRoles({
            licenseGroupId: groupId,
            memberIds,
            orgId: OrganizationManager.getActiveOrgId(),
            productId,
          });
      }

      hasUnsavedChanges() {
        return this.src2MemberConfigurationRoles.hasUnsavedChanges();
      }

      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          this.src2MemberConfigurationRoles
            .refresh()
            .then((memberConfigRoles) => {
              const state = _.pick(memberConfigRoles, [
                'availableRoles',
                'description',
                'learnMoreHref',
                'memberRoles',
              ]);
              _.assign(this, state);
              this.$resolved = true;
              resolve(this);
            })
            .catch((error) => {
              this.$resolved = true;
              reject(error.response);
            });
        });

        return this.$promise;
      }

      /**
       * @deprecated
       * @description Updates model with a nested form of itself recording state
       *     which may be later modified.
       */
      registerSavedState() {
        this.savedState = _.cloneDeep(this.memberRoles);
      }

      restore() {
        this.src2MemberConfigurationRoles.restore();
      }

      /**
       * @description Method to save member role changes to the back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          this.src2MemberConfigurationRoles
            .save()
            .then((memberConfigRoles) => {
              const state = _.pick(memberConfigRoles, [
                'availableRoles',
                'description',
                'learnMoreHref',
                'memberRoles',
              ]);
              _.assign(this, state);
              this.$resolved = true;
              resolve(this);
            })
            .catch((error) => {
              this.$resolved = true;
              reject(error.response);
            });
        });

        return this.$promise;
      }

      static get(productId, groupId, memberIds) {
        const model = new ConfigurationMemberRoles(productId, groupId, memberIds);
        model.refresh();
        return model;
      }
    }

    return ConfigurationMemberRoles;
  }
})();
