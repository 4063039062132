import {Content} from '@adobe/react-spectrum';
import React from 'react';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import StartedByDetailSection from './bulk-operations-drawer-details-sections/InitiatedByDetailSection';
import InputFileDetailSection from './bulk-operations-drawer-details-sections/InputFileDetailSection';
import ResultsDetailSection from './bulk-operations-drawer-details-sections/ResultsDetailSection';
import StatusDetailSection from './bulk-operations-drawer-details-sections/StatusDetailSection';

/* Displays the status of a bulk operation job */
const BulkOperationDrawerContent = () => {
  const {isExportJob, jobHasTerminated} = useBulkOperationJob();

  return (
    <Content>
      <InputFileDetailSection />
      {!isExportJob && (jobHasTerminated ? <ResultsDetailSection /> : <StatusDetailSection />)}
      <StartedByDetailSection />
    </Content>
  );
};

export default BulkOperationDrawerContent;
