import {observer} from 'mobx-react-lite';
import React from 'react';

import AttributeMapping from './AttributeMapping';
import AutoAccountCreation from './AutoAccountCreation';
import UpdateUserInformation from './UpdateUserInformation';

const SetupJit = observer(() => (
  <>
    <AutoAccountCreation />
    <AttributeMapping />
    <UpdateUserInformation />
  </>
));

export default SetupJit;
