/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase:itemScorecard
 *
 * @description The scorecard for use with a product purchase catalog item or cart item showing assigned licenses
 *
 * @param {ScorecardGroupItem} scorecard - item the model that describes score of the current product.
 *
 * @example <app-purchase-scorecard
 *            scorecard-item="$ctrl.scorecardItem"
 *          </app-pruchase-scorecard>
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appPurchaseScorecard', {
    bindings: {
      scorecardItem: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/purchase-scorecard/purchase-scorecard.component.html',
  });

  function controller(_, $translate, appScorecardScoreFilter, numberFilter, TAG_COLOR) {
    const vm = this;

    _.assign(vm, {
      getLicenseTag,
      getTagColor,
    });

    function getLicenseTag() {
      return $translate.instant('widgets.products.purchase.purchaseScorecard.scoreAndTotal', {
        score: appScorecardScoreFilter(vm.scorecardItem.score),
        total: numberFilter(vm.scorecardItem.total),
      });
    }

    function getTagColor() {
      let tagColor = TAG_COLOR.GREY;

      if (
        !vm.scorecardItem.positiveScorecard &&
        vm.scorecardItem.percentageConsumed >= vm.scorecardItem.percentageThreshold
      ) {
        tagColor = TAG_COLOR.ORANGE;
      }

      return tagColor;
    }
  }
})();
