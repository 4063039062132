import {Flex, Item, TabList, TabPanels, Tabs, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {createSearchParams, useSearchParams} from 'react-router-dom';

import ContractAdminsTable from 'features/account/contract-admins-table/ContractAdminsTable';

/**
 * Renders a tabbed component for displaying contract details and contract admins.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.renderContractDetails - The function to render contract details.
 * @returns {JSX.Element} The rendered ContractTabs component.
 */
const ContractTabs = ({renderContractDetails}) => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const contentGap = 'size-300'; // 24px

  const handleContractTabChange = (id) => {
    const newSearchParams = createSearchParams({tab: id.toString()});
    setSearchParams(newSearchParams, {replace: true});
  };

  const defaultContractTabKey = searchParams.get('tab') || 'details';

  const contractTabs = {
    contractAdmins: {
      id: 'admins',
      name: intl.formatMessage({id: 'account.overview.contractDetails.tabs.admins'}),
    },
    contractDetails: {
      id: 'details',
      name: intl.formatMessage({id: 'account.overview.contractDetails.tabs.details'}),
    },
  };
  return (
    <Tabs
      aria-label={intl.formatMessage({
        id: 'account.overview.contractDetails.tabs.ariaLabel',
      })}
      defaultSelectedKey={defaultContractTabKey}
      onSelectionChange={handleContractTabChange}
    >
      <TabList>
        <Item key={contractTabs.contractDetails.id} textValue={contractTabs.contractDetails.name}>
          <Text>{contractTabs.contractDetails.name}</Text>
        </Item>
        <Item key={contractTabs.contractAdmins.id} textValue={contractTabs.contractAdmins.name}>
          <Text>{contractTabs.contractAdmins.name}</Text>
        </Item>
      </TabList>
      <TabPanels data-testid="contract-tab-panels">
        <Item key={contractTabs.contractDetails.id} textValue={contractTabs.contractDetails.name}>
          <Flex
            data-testid="account-content-main-tabs"
            direction="column"
            marginTop="size-200"
            rowGap={contentGap}
          >
            {renderContractDetails()}
          </Flex>
        </Item>
        <Item key={contractTabs.contractAdmins.id} textValue={contractTabs.contractAdmins.name}>
          <View paddingTop="size-200">
            <ContractAdminsTable />
          </View>
        </Item>
      </TabPanels>
    </Tabs>
  );
};

ContractTabs.propTypes = {
  // Function to render the contract details
  renderContractDetails: PropTypes.func.isRequired,
};
export default ContractTabs;
