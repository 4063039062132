import {useAsyncModel} from '@pandora/react-async-model';
import shuffle from 'lodash/shuffle';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

const shuffleSophiaCancellationReasons = (reasons) => [
  ...shuffle(reasons.filter((reason) => reason.key !== 'OTHER')),
  ...reasons.filter((reason) => reason.key === 'OTHER'),
];

const transformResponse = (response) => {
  const cancellationReasons = [];
  const {items} = response;
  if (items.length === 0) {
    throw new Error('No surface content is returned from Sophia');
  }
  items.forEach((item) => {
    if (item.isJson()) {
      const content = JSON.parse(item.getContent());
      const data = content.data;
      const cancellationsurveyreasonsList = data.cancellationsurveyreasonsList;
      const cancellationItems = cancellationsurveyreasonsList.items[0];

      Object.keys(cancellationItems).forEach((cancellationItem) => {
        cancellationReasons.push({
          key: cancellationItem,
          name: cancellationItems[cancellationItem],
        });
      });
    }
  });
  if (cancellationReasons.length === 0) {
    throw new Error('No JSON content is returned from Sophia');
  }
  return shuffleSophiaCancellationReasons(cancellationReasons);
};

// Re-using the existing cancellation reasons used in useCancellationReasons hook
const getRandomizedReasons = (intl) =>
  // will need to remove OTHER key from the array before shuffling then add it back at the end
  [
    ...shuffle([
      {
        key: 'TOO_EXPENSIVE',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.tooExpensive',
        }),
      },
      {
        key: 'DOWNSIZING',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.downsizing',
        }),
      },
      {
        key: 'SHORT_TERM',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.shortTerm',
        }),
      },
      {
        key: 'CONSOLIDATING',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.consolidating',
        }),
      },
      {
        key: 'CHANGE_PLAN',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.changePlan',
        }),
      },
      {
        key: 'TECHNICAL_DIFFICULTY',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.technicalDifficulty',
        }),
      },
      {
        key: 'NOT_USING_THE_PRODUCT_ENOUGH',
        name: intl.formatMessage({
          id: 'account.selfCancel.cancellationReasons.reasonsList.notUsingProductEnough',
        }),
      },
    ]),
    {
      key: 'OTHER',
      name: intl.formatMessage({
        id: 'account.selfCancel.cancellationReasons.reasonsList.other',
      }),
    },
  ];

/**
 * @description Fetches the cancellation reasons defined in a Sophia campaign content and shows them in the
 * cancellation reasons step in Self cancel flow
 * @returns {Array<Object>} cancellationReasons - A list of reasons a user can choose from in the self cancel flow modal
 */
const useSophiaCancellationReasons = () => {
  const intl = useIntl();

  const selfCancelSurfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_SELF_CANCEL_SURVEY;

  const loadCancellationReasons = useCallback(async () => {
    const sophiaContent = await sophiaHelper.getSophiaContent({
      surfaceId: selfCancelSurfaceId,
    });
    try {
      return transformResponse(sophiaContent);
    } catch (error) {
      return getRandomizedReasons(intl);
    }
  }, [selfCancelSurfaceId, intl]);

  // Configure useAsyncModel to load the cancellationReasons model
  const {model, isLoading, error} = useAsyncModel({
    initState: [],
    loadFn: loadCancellationReasons,
  });

  if (error) {
    // Error fetching cancellation reasons from Sophia, falling back to the existing reasons
    return {isLoading: false, sophiaReasons: getRandomizedReasons(intl)};
  }

  return {error, isLoading, sophiaReasons: model};
};

export default useSophiaCancellationReasons;
