(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(accountConfig);
  /* @ngInject */
  function accountConfig(
    $stateProvider,
    $urlRouterProvider,
    ACCOUNT_WORKSPACE_NAV_ID,
    APP_SHELL_NAV_ID,
    navAssemblerProvider,
    ROLE
  ) {
    const state = {
      name: 'account',
      parent: 'org',
      url: '/account',
    };

    $stateProvider.state(state.name, {
      abstract: true,
      data: {
        title: 'account.title',
      },
      parent: state.parent,
      resolve: {
        surfaceId: ['SURFACE_ID', (SURFACE_ID) => SURFACE_ID.ONE_CONSOLE_ACCOUNT],
        // Only add left nav if an EDU org which can view Organization Details or a direct org which can view billing
        // history.
        workspaceNavItemListId: ($q) => $q.resolve(ACCOUNT_WORKSPACE_NAV_ID),
      },
      url: state.url,
      views: {
        '': {
          component: 'binkyWorkspace',
        },
      },
    });

    // add the Account section to the top nav
    navAssemblerProvider.configure({
      navID: APP_SHELL_NAV_ID,
      navValue: {
        access: {
          callback: accessCallback,
          feature: {
            name: 'bumper_account',
          },
          roles: [ROLE.ADMIN.ORG],
        },
        child: ACCOUNT_WORKSPACE_NAV_ID,
        disabled: false,
        hidden: false,
        name: 'account.title',
        order: 5,
        state,
      },
    });

    $urlRouterProvider.when('/account', ($q, $state, orgReady) => {
      // eslint-disable-next-line promise/catch-or-return
      orgReady.whenRunReady().then((activeOrg) => {
        $state.go('account.account', {
          orgId: activeOrg.id,
        });
      });
    });

    function accessCallback($q) {
      return $q.resolve(true);
    }
  }
})();
