(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.search')
    .constant('SEARCH_RESULTS_PULLDOWN_ID', 'search-results-pulldown')
    .constant('SEARCH_RESULTS_PULLDOWN_STATES', {
      SHOW_MESSAGES: 'ShowMessages',
      SHOW_TOPICS: 'ShowTopics',
    });
})();
