import {LICENSE_DEV_ADMIN, OrganizationUser, toPandoraProduct} from '@admin-tribe/binky';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  DEFAULT_INLINE_PRODUCT_COUNT,
  ProductIconList,
  ProductIconListContentModel,
} from '@pandora/react-product-icon-list';
import {useCollator} from '@react-aria/i18n';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';

import {getUserProductsForDisplay} from './memberProductIconListUtils';

// Callback used by the AdminListTableSection/UserListTableSection when display of productIcons is true.
// The targetType from the pageContext in MemberListContext will determine which products are used.
const MemberProductIconList = ({
  inlineProductCount = DEFAULT_INLINE_PRODUCT_COUNT,
  member,
  onClickMore,
}) => {
  const {pageContext} = useMemberListContext();
  const productList = rootStore.organizationStore.productList;
  const [products, setProducts] = useState();
  const intl = useIntl();
  const collator = useCollator();

  const content = useContentEntry(ProductIconListContentModel);

  /**
   *
   */
  useEffect(() => {
    const getDeveloperProducts = () => {
      // Using a set guarantees there is only one entry per product.
      const productSet = new Set();

      const findProductById = (id) =>
        rootStore.organizationStore.productList.items.find((product) => product.id === id);

      // roles: [{targets: [{id: 'target-id', name: 'profile-id', parentId: 'product-id'}...], type: LICENSE_DEV_ADMIN}]
      member.roles?.forEach((role) => {
        if (role.type === LICENSE_DEV_ADMIN) {
          role.targets.forEach((target) => {
            productSet.add(toPandoraProduct(findProductById(target.parentId)));
          });
        }
      });

      return [...productSet];
    };

    /**
     * @returns {Array<PandoraProduct>} list of developer or user products to display
     */
    const getProducts = () => {
      if (pageContext.isDeveloperTargetType()) {
        return getDeveloperProducts();
      }

      return getUserProductsForDisplay({
        collator,
        intl,
        userProducts: member.products, // each product just contains an id prop
      });
    };

    setProducts(getProducts);
  }, [collator, intl, member.products, member.roles, pageContext, productList]);

  return (
    <ProductIconList
      content={content}
      inlineProductCount={inlineProductCount}
      onClickMoreProducts={() => onClickMore(member)}
      products={products} // Array<PandoraProduct>
    />
  );
};

MemberProductIconList.propTypes = {
  /**
   * This is passed thru to the ProductIconList component.
   * The number of products to display, before any remaining products are
   * replace with a "+ x more" link.
   * If this number is 0 all products are shown.
   * The default is Pandora's DEFAULT_INLINE_PRODUCT_COUNT which is currently 3.
   */
  inlineProductCount: PropTypes.number,
  /**
   * The user/admin/developer.
   * This is required but can't be marked isRequired because it results in a warning
   * because the property isn't added until the caller clones this component and adds this property.
   */
  member: PropTypes.instanceOf(OrganizationUser),
  /**
   * The callback for the ProductIconList when the "more products" link is clicked.
   */
  onClickMore: PropTypes.func,
};

export default MemberProductIconList;
