(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:gainsightEventToLaunchTranslator
   * @description Translate GainsightEventToLaunchTranslator into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('gainsightEventToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_, binkySrc2) {
    const {formatAnalyticsDate} = binkySrc2.services.analytics.analyticsUtils;
    return {processDescriptor};

    function processDescriptor(launchContext, gainsightEventDescriptor) {
      const {setProperty} = launchContext;
      const gainsightEvent = {engagement: {}, step: {}};

      // Copy all descriptor properties into the analytics event. The property names
      // between the objects do not change.
      _.assign(gainsightEvent.engagement, _.get(gainsightEventDescriptor, 'engagement'));
      _.assign(gainsightEvent.step, _.get(gainsightEventDescriptor, 'step'));
      _.set(gainsightEvent, 'url', _.get(gainsightEventDescriptor, 'url'));

      // Serialize the unix timestamp into a format consumable by analytics
      const executionTimestamp = _.get(gainsightEvent, 'engagement.executionTimestamp');
      if (_.isFinite(executionTimestamp)) {
        const formattedDate = formatAnalyticsDate(new Date(executionTimestamp));
        _.set(gainsightEvent, 'engagement.executionTimestamp', formattedDate);
      } else {
        // The value is not in the expected format for analytics, so remove it
        _.set(gainsightEvent, 'engagement.executionTimestamp', undefined);
      }

      setProperty('gainsightEvent', gainsightEvent);
    }
  }
})();
