import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const CopyPermissionsModal = ({onContinue}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'products.productProfilePermissions.copyPermissionsDialog.cancel',
      })}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={onContinue}
      primaryActionLabel={intl.formatMessage({
        id: 'products.productProfilePermissions.copyPermissionsDialog.continue',
      })}
      title={intl.formatMessage({
        id: 'products.productProfilePermissions.copyPermissionsDialog.title',
      })}
      variant="warning"
    >
      {intl.formatMessage({
        id: 'products.productProfilePermissions.copyPermissionsDialog.content',
      })}
    </ModalAlertDialog>
  );
};

CopyPermissionsModal.propTypes = {
  /**
   * Callback to invoke the AddProductProfileModal when the Continue button is clicked.
   */
  onContinue: PropTypes.func.isRequired,
};

export default CopyPermissionsModal;
