(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.redirect').config(redirectConfig);

  /* @ngInject */
  function redirectConfig($stateProvider) {
    $stateProvider.state('redirect', {
      abstract: true,
      parent: 'org',
      url: '/redirect',
    });
  }
})();
