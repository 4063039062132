import {ProgressCircle} from '@adobe/react-spectrum';
import {usePremierSupportContact} from '@pandora/data-model-premier-support-contact';
import {
  ContactsTitledSection,
  ContactsTitledSectionContentModel,
} from '@pandora/react-contact-info';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * A widget to display the list of premier support contacts
 */
const PremierSupportContactsSection = ({fulfillableItemCode, instanceId}) => {
  const intl = useIntl();
  const [contactInfoList, setContactInfoList] = useState(undefined);
  const [region, setRegion] = useState('');

  const content = ContactsTitledSectionContentModel.createEntry({
    cloudAcceleratorSME: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.cloudAcceleratorSME',
    }),
    customerSupportManager: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.customerSupportManager',
    }),
    headerTitle: intl.formatMessage({
      id: 'support.premierSupportContactsSection.headerTitle',
    }),
    namedSupportEngineer: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.namedSupportEngineer',
    }),
    noContacts: intl.formatMessage({
      id: 'support.premierSupportContactsSection.noContacts',
    }),
    regionTitle: intl.formatMessage({
      id: 'support.premierSupportContactsSection.regionTitle',
    }),
    technicalAccountDirector: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.technicalAccountDirector',
    }),
    technicalAccountManager: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.technicalAccountManager',
    }),
    technicalSupportEngineer: intl.formatMessage({
      id: 'support.premierSupportContactsSection.titles.technicalSupportEngineer',
    }),
  });

  const {data, error, loading} = usePremierSupportContact({
    fulfillableItemCode,
    instanceId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  // Side effect to set contactInfoList
  useEffect(() => {
    setContactInfoList(data?.contacts);
    setRegion(data?.regionText);
  }, [data]);

  if (loading) {
    return (
      <ProgressCircle aria-label="loading" data-testid="loading-gif" isIndeterminate size="S" />
    );
  }
  return (
    !error && (
      <ContactsTitledSection
        contactInfoList={contactInfoList}
        content={content}
        region={region}
        width="100%"
      />
    )
  );
};

PremierSupportContactsSection.propTypes = {
  /**
   * id represents the product code.
   */
  fulfillableItemCode: PropTypes.string.isRequired,
  /**
   * id represents the instance id.
   */
  instanceId: PropTypes.string,
};

export default PremierSupportContactsSection;
