import {ButtonLink, GoUrl, ImageIcon, ModalContent, ModalDescription} from '@admin-tribe/binky-ui';
import {Flex, Image, Text, View} from '@adobe/react-spectrum';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME, FLOW_NAME} from '../../SelfCancelAnalyticsUtils';
import illustrationHeroTeamsImage from '../../assets/CCT_heroimage_Md.svg';
import userCheckIcon from '../../assets/S_IlluLicensesAssign_44x50.svg';
import {useSelfCancelAnalyticsContext} from '../self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseModal from '../self-cancel-base-modal/SelfCancelBaseModal';

import styles from './SelfCancelDoneModal.pcss';

/**
 * Self Cancel done modal component used by the confirmation or final steps.
 * It uses SelfCancelBaseModal component providing defaults and common features like
 * the styled success message, Manage Licenses section and Adobe Teams Benefits section, which can
 * be opted out.
 * @todo The current content of this component is for initial bootstrap and will be modified when fully implemented by a future user story
 */
const SelfCancelDoneModal = ({
  bubbleImage,
  bubbleContent,
  children,
  heading,
  isBubbleHidden,
  isManageLicensesSectionHidden,
  onAddProducts,
  onCta,
  onManageUsers,
  showDivider = false,
  subheading,
  successMessage,
}) => {
  const intl = useIntl();

  const {dispatchSelfCancelUiEventAnalytics} = useSelfCancelAnalyticsContext();

  const onManageUsersPress = () => {
    const eventName = `${FLOW_NAME}${EVENT_NAME.MANAGE}`;
    dispatchSelfCancelUiEventAnalytics({eventName});
    onManageUsers?.();
  };

  const onAddProductsPress = () => {
    const eventName = `${FLOW_NAME}${EVENT_NAME.PURCHASE}`;
    dispatchSelfCancelUiEventAnalytics({eventName});
    onAddProducts?.();
  };

  return (
    <SelfCancelBaseModal
      ctaLabel={intl.formatMessage({id: 'common.modal.buttons.done'})}
      ctaVariant="secondary"
      heading={heading}
      onCancel={onCta}
      onCta={onCta}
    >
      {(successMessage || subheading) && (
        <ModalDescription showDivider={showDivider}>
          {successMessage && (
            <Flex
              alignItems="center"
              data-testid="successMessage"
              elementType="p"
              gap="size-130"
              marginTop="size-175"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to apply success color
              UNSAFE_className={styles['success-message']}
            >
              <CheckmarkCircle />
              <Text
                margin="0"
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
                UNSAFE_className={styles.subheading}
              >
                {successMessage}
              </Text>
            </Flex>
          )}
          {subheading}
        </ModalDescription>
      )}
      <ModalContent>
        {!isManageLicensesSectionHidden && (
          <View
            backgroundColor="static-white"
            borderColor="gray-300"
            borderRadius="regular"
            borderWidth="thin"
            data-testid="manageLicensesSection"
            marginBottom="size-300"
            marginTop="size-500"
            padding="size-200"
          >
            <Flex alignItems="center" gap="size-225">
              <ImageIcon alt="" flexShrink="0" size="L" src={userCheckIcon} />
              <Text>
                {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- self cancel work prefers to have JSX in place */}
                {intl.formatMessage(
                  {id: 'account.selfCancel.components.selfCancelDoneModal.banner'},
                  {
                    manageUsersLink: ([content]) => (
                      <ButtonLink data-testid="manageUsersLink" onPress={onManageUsersPress}>
                        {content}
                      </ButtonLink>
                    ),

                    purchaseLink: ([content]) => (
                      <ButtonLink data-testid="purchaseLink" onPress={onAddProductsPress}>
                        {content}
                      </ButtonLink>
                    ),
                  }
                )}
              </Text>
            </Flex>
          </View>
        )}
        {children}
        {!isBubbleHidden && (
          <View
            backgroundColor="gray-75"
            data-testid="bubble"
            marginTop={
              // Adding spacing when no other content above is shown
              isManageLicensesSectionHidden && !children ? 'size-675' : undefined
            }
            paddingBottom="size-600"
            paddingTop="size-400"
          >
            <Flex alignItems="center" direction="column" flexGrow="1">
              {bubbleImage || <Image alt="" height="155px" src={illustrationHeroTeamsImage} />}
              <View
                elementType="p"
                marginBottom="size-0"
                marginTop="size-400"
                maxWidth="520px"
                paddingX="size-500"
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
                UNSAFE_className={styles['bubble-text']}
              >
                {bubbleContent ||
                  intl.formatMessage(
                    {id: 'account.selfCancel.components.selfCancelDoneModal.bubbleText'},
                    {
                      goUrl: (linkText) => (
                        <GoUrl name="go_url_for_creativecloud_business_teams" noWrap={false}>
                          {linkText}
                        </GoUrl>
                      ),
                    }
                  )}
              </View>
            </Flex>
          </View>
        )}
      </ModalContent>
    </SelfCancelBaseModal>
  );
};

SelfCancelDoneModal.propTypes = {
  /**
   * The alternate text for the bubble, used if isBubbleHidden is falsy.
   * Defaults to 'account.selfCancel.components.selfCancelDoneModal.bubbleText' translation.
   */
  bubbleContent: PropTypes.node,
  /**
   * The alternate image node for the bubble, used if isBubbleHidden is falsy.
   * Defaults to teams image.
   */
  bubbleImage: PropTypes.node,
  /**
   * The contents of the modal.
   */
  children: PropTypes.node,
  /**
   * Heading title for the modal.
   */
  heading: PropTypes.string.isRequired,
  /**
   * Whether Adobe Teams Benefits section is hidden. By default it will show the section.
   */
  isBubbleHidden: PropTypes.bool,
  /**
   * Whether Manage Licenses Section section is hidden. By default it will show the section.
   */
  isManageLicensesSectionHidden: PropTypes.bool,
  /**
   * Handler that is called when the user clicks on Add Products link.
   */
  onAddProducts: PropTypes.func,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onCta: PropTypes.func,
  /**
   * Handler that is called when the user clicks on Manage Users.
   */
  onManageUsers: PropTypes.func,
  /**
   * Whether to show the divider between modal description and content
   */
  showDivider: PropTypes.bool,
  /**
   * Description for the modal.
   */
  subheading: PropTypes.node,
  /**
   * The content of the success message.
   */
  successMessage: PropTypes.node,
};

export default SelfCancelDoneModal;
