import {DialogContainer, Flex, Heading, Switch, Text} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import AutoAccountCreationToggleWarningModal from 'features/settings/components/setup-jit/AutoAccountCreationToggleWarningModal';

import './AutoAccountCreation.pcss';

const AutoAccountCreation = observer(() => {
  const {directorySetupStore} = useDirectorySetupContext();
  const headingId = useId();
  const [isToggleWarningModalVisible, setIsToggleWarningModalVisible] = useState(false);

  const onToggleClicked = (status) => {
    // if status is disabled open the warning modal
    if (!status) {
      setIsToggleWarningModalVisible(true);
      return;
    }

    directorySetupStore.setJitStatus(status);
  };

  const onDisableConfirmed = () => {
    directorySetupStore.setJitStatus(false);
    setIsToggleWarningModalVisible(false);
  };

  return (
    <div styleName="auto-account-creation-container">
      <Flex alignItems="center" direction="row" gap="size-1600" width="100%">
        <Flex direction="column" flex={1}>
          <Heading id={headingId} level={4} marginBottom="size-50" marginTop={0}>
            <FormattedMessage id="settings.autoAccountCreation.title" />
          </Heading>
          <Text>
            <FormattedMessage id="settings.autoAccountCreation.description" />
          </Text>
        </Flex>
        <Switch
          aria-describedby={headingId}
          data-testid="jit-status-switch"
          isEmphasized
          isSelected={directorySetupStore.data.jitStatus}
          onChange={onToggleClicked}
        >
          <FormattedMessage
            id={
              directorySetupStore.data.jitStatus
                ? 'settings.autoAccountCreation.enabled'
                : 'settings.autoAccountCreation.notEnabled'
            }
          />
        </Switch>
      </Flex>

      <DialogContainer
        onDismiss={() => {
          setIsToggleWarningModalVisible(false);
        }}
      >
        {isToggleWarningModalVisible && (
          <AutoAccountCreationToggleWarningModal onConfirm={onDisableConfirmed} />
        )}
      </DialogContainer>
    </div>
  );
});

export default AutoAccountCreation;
