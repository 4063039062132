import {ModelList, log} from '@admin-tribe/binky';
import sortBy from 'lodash/sortBy';

import {
  ADOBE_TEMPLATE_MODEL_CACHE_ID,
  CREATE_PACKAGE_CONSTANTS,
} from 'features/packages/packagesConstants';

import {getUserSpecificAdobeTemplates} from '../api/tronData';
import PackagesAdobeTemplateEntity from '../entities/PackagesAdobeTemplateEntity';

import PackagesEntitlements from './PackagesEntitlementsService';

function transformResponse(responseData, ClassRef) {
  // To show macuniversal template by default in summary drawer if it is native package and macuniversal is supported.
  // native package - is a flat type package which are signed and notarized.
  responseData.packagesOnUI = responseData.packagesOnUI.map((obj) =>
    obj.npcPackageID &&
    obj.pids2Show?.Pid2ShowList.some(
      (pids) => pids.platform?.toUpperCase() === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM
    )
      ? {...obj, platform: CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM}
      : obj
  );

  const items = responseData.packagesOnUI.map((responseItem) => new ClassRef(responseItem));
  return sortBy(items, ['name', 'platform']);
}

class PackagesAdobeTemplatesService extends ModelList {
  /**
   * @description Method to construct a new PackagesAdobeTemplatesService
   *
   */
  constructor() {
    super({
      isCacheable: true,
      itemClassRef: PackagesAdobeTemplateEntity,
      modelCacheId: ADOBE_TEMPLATE_MODEL_CACHE_ID,
      resource: getUserSpecificAdobeTemplates,
      transformResponseData: (responseData, ClassRef) => transformResponse(responseData, ClassRef),
    });
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<PackagesAdobeTemplatesService>} resolves to refreshed PackagesAdobeTemplatesService model, else rejects with error message
   */
  async refresh() {
    try {
      const queryParams = {isEnterprise: PackagesEntitlements.isEnterpriseOrg};
      queryParams.isNative = true;
      await super.refresh(queryParams);
    } catch (error) {
      log.error('PackagesAdobeTemplatesService failed to refresh. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

export default PackagesAdobeTemplatesService;
