import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {setAttributes} from 'features/packages/services/PackageSummaryDrawerUtils';

import PackageSummaryDrawerContextProvider from './PackageSummaryDrawerContextProvider';
import PackageSummaryDrawerContent from './package-summary-drawer-content/PackageSummaryDrawerContent';

const PackageSummaryDrawer = ({allPackagesStore, summaryObj, isTemplate = false}) => {
  const intl = useIntl();
  const [localAttributes, setLocalAttributes] = useState({
    package: {},
    template: {},
  });

  // Set Summary Drawer attributes
  useEffect(() => {
    const setPackageSummaryDrawerAttributes = () => {
      const tempAttributes = setAttributes(toJS(summaryObj), isTemplate, intl);
      setLocalAttributes({...tempAttributes});
    };

    setPackageSummaryDrawerAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- have dependency only on summaryObj
  }, [summaryObj]);

  return (
    <PackageSummaryDrawerContextProvider attributes={localAttributes}>
      <PackageSummaryDrawerContent allPackagesStore={allPackagesStore} />
    </PackageSummaryDrawerContextProvider>
  );
};

PackageSummaryDrawer.propTypes = {
  /**
   * AllPackageStore for retry package build
   */
  allPackagesStore: PropTypes.shape({
    retryBuildingPackage: PropTypes.func,
  }),
  /**
   * Boolean to tell if we are at Templates tab
   */
  isTemplate: PropTypes.bool,
  /**
   * Template or Package object
   */
  summaryObj: PropTypes.shape({
    /**
     * Package or Template name
     */
    name: PropTypes.string.isRequired,
    /**
     * Package bundle id
     */
    packageBundleId: PropTypes.string,
    /**
     * Package or Template id
     */
    packageId: PropTypes.string,
  }),
};

export default PackageSummaryDrawer;
