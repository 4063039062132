(function () {
  /**
   * @ngdoc module
   * @name app.widgets.bulk-operations
   * @description module that defines bulk-operations context
   */
  angular.module('app.widgets.bulk-operations.context', [
    'app.core.api.jil',
    'app.core.migration.t2e',
    'app.core.organizations.organization-manager',
    'app.core.product.configurations',
    'app.widgets.bulk-operations.constants',
    'app.widgets.bulk-operations.samples',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.widgets.common.feature',
  ]);
})();
