import {DetailsButton, Page, PageActions, PageContent, PageHeader} from '@admin-tribe/binky-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import IndividualFolderDetailDrawer from 'features/storage/components/individual-folder-detail-drawer/IndividualFolderDetailDrawer';
import {
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
} from 'features/storage/routing/storagePaths';

import ActiveUsersSubpage from './ActiveUsersSubpage';
import InctiveUsersSubpage from './InactiveUsersSubpage';
import IndividualUserFoldersTabs from './IndividualUserFoldersTabs';
import {TAB_KEYS} from './IndividualUserFoldersTabsConstants';

/**
 * The Individual User Folders page.  Contains subpages for active and inactive users.
 */
const IndividualUserFoldersPage = () => {
  const intl = useIntl();
  const {orgId} = useParams();

  useDocumentTitle({key: 'storage.individualFolder.title'});

  const tabs = useMemo(
    () => [
      {
        content: <ActiveUsersSubpage />,
        key: TAB_KEYS.FOLDERS,
        name: intl.formatMessage({
          id: 'storage.individualUserFolders.activeUsers.title',
        }),
        pathname: generatePath(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS, {orgId}),
      },
      {
        content: <InctiveUsersSubpage />,
        key: TAB_KEYS.DELETED,
        name: intl.formatMessage({
          id: 'storage.individualUserFolders.inactiveUsers.title',
        }),
        pathname: generatePath(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED, {orgId}),
      },
    ],
    [intl, orgId]
  );

  return (
    <Page data-testid="individual-user-folders-page">
      <PageHeader title={intl.formatMessage({id: 'storage.individualFolder.title'})} />
      <PageActions>
        <DetailsButton detailsDrawer={<IndividualFolderDetailDrawer />} />
      </PageActions>
      <PageContent>
        <IndividualUserFoldersTabs tabs={tabs} />
      </PageContent>
    </Page>
  );
};

export default IndividualUserFoldersPage;
