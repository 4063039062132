(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name jobResultListTable
   * @description Component which displays a list of job results
   *
   * @binding {JOB_RESULT_FILTER} filter - type of job results to display
   * @binding {JobResult} jobResult - job results to display
   * @binding {PageContext} pageContext - the pageContext controlling where links to
   *   deficit reports are sent to
   */
  angular.module('app.widgets.bulk-operations').component('appJobResultListTable', {
    bindings: {
      filter: '<',
      jobResult: '<',
      pageContext: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/job-result-list-table/job-result-list-table.component.html',
  });

  /* @ngInject */
  function controller(_, JOB_RESULT_FILTER) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      filterOnErrorStatus,
      filterOnNothing,
      onPageSizeChange,
      paginationId: 'job-result-list-table',
    });

    ///////////////

    function $onInit() {
      // eslint-disable-next-line promise/catch-or-return
      vm.jobResult.$promise.then(() => {
        vm.resultItemList = vm.jobResult.items;
      });
    }

    function $onChanges(changesObj) {
      vm.filter = _.get(changesObj, 'filter.currentValue');
      if (vm.filter) {
        switch (vm.filter) {
          case JOB_RESULT_FILTER.ERRORS:
            filterOnErrorStatus();
            break;
          case JOB_RESULT_FILTER.VIEW_ALL:
          default:
            filterOnNothing();
        }
      }
    }

    function filterOnErrorStatus() {
      vm.resultItemList = vm.jobResult.getFailedResultItems();
    }

    function filterOnNothing() {
      vm.resultItemList = vm.jobResult.items;
    }

    function onPageSizeChange(pageSize) {
      vm.jobResult.pagination.pageSize = pageSize;
      vm.jobResult.pagination.currentPage = 1;
    }
  }
})();
