import {navBus} from '@admin-tribe/binky';
import {Page, PageActions, PageBreadcrumbs, PageContent, PageHeader} from '@admin-tribe/binky-ui';
import {Divider, Item, View} from '@adobe/react-spectrum';
import {findFulfillableItem} from '@pandora/data-model-product';
import {SUPPORT_TICKET_RECORD_TYPE} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {getProductDisplayNameV2} from '@pandora/react-support-ticket';
import PropTypes from 'prop-types';
import React from 'react';
import {generatePath, useParams} from 'react-router-dom';

import useDocumentTitle from '../../../../common/hooks/useDocumentTitle';
import ExpertSessionInfoBar from '../../components/expert-session-info-bar/ExpertSessionInfoBar';
import SupportCaseInfoBar from '../../components/support-case-info-bar/SupportCaseInfoBar';
import SupportDetailsDrawer from '../../components/support-details-drawer/SupportDetailsDrawer';
import SupportTicketActionSection from '../../components/support-ticket-action-section/SupportTicketActionSection';
import SupportTicketContactsSection from '../../components/support-ticket-contacts-section/SupportTicketContactsSection';
import SupportTicketAddDiscussionItemFormSection from '../../components/support-ticket-discussion-form-section/SupportTicketAddDiscussionItemFormSection';
import SupportTicketDiscussionHeader from '../../components/support-ticket-discussion-form-section/SupportTicketDiscussionHeader';
import SupportTicketDiscussionItems from '../../components/support-ticket-discussion-section/SupportTicketDiscussionItems';
import SupportTicketStatusTag from '../../components/support-ticket-status-tag/SupportTicketStatusTag';
import useSupportTicketDetailsPageHook from '../../hooks/useSupportTicketDetailsPageHook';
import {PATH_EXPERT_SESSIONS_OPEN, PATH_SUPPORT_CASES_OPEN} from '../../routing/supportPaths';

import styles from './SupportTicketDetailsPage.pcss';

const getProductIcon = (productList, code) => {
  const productInfo = findFulfillableItem(productList, code);

  return productInfo?.assets?.icons?.svg;
};

/**
 * Component is used to show all components related to SupportTicket(Support Ticket Details View).
 */
const SupportTicketDetailsPage = ({recordType}) => {
  const {ticketId} = useParams();
  const {
    intl,
    supportTicket,
    isBumpered,
    isLoading,
    activeOrgId,
    supportTicketActionSectionProps,
    supportDetailsDrawerProps,
    productList,
    getTicket,
    supportLevel,
  } = useSupportTicketDetailsPageHook(ticketId, recordType);
  const {userId} = useAuth().getUserProfile();
  useDocumentTitle({defaultTitle: supportTicket ? supportTicket?.title : 'Loading...'});

  const getTicketBreadTitle = () =>
    recordType === SUPPORT_TICKET_RECORD_TYPE.EXPERT
      ? intl.formatMessage({id: 'support.expertSession.breadcrumbs.name'})
      : intl.formatMessage({id: 'support.supportCase.breadcrumbs.name'});

  const onClickBreadcrumbs = () => {
    navBus.navigate(
      generatePath(
        recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
          ? PATH_SUPPORT_CASES_OPEN
          : PATH_EXPERT_SESSIONS_OPEN,
        {
          orgId: activeOrgId,
        }
      )
    );
  };

  return (
    <Page data-testid="support-ticket-details-page" isBumpered={isBumpered} isLoading={isLoading}>
      {supportTicket && (
        <PageBreadcrumbs isDisabled={false} onAction={onClickBreadcrumbs}>
          <Item key="ticket">{getTicketBreadTitle()}</Item>
          <Item key="ticket-title">
            <span>{supportTicket?.title}</span>
          </Item>
        </PageBreadcrumbs>
      )}
      {supportTicket?.title && <PageHeader title={supportTicket.title} />}
      {supportTicket && (
        <PageActions>
          <SupportDetailsDrawer
            {...supportDetailsDrawerProps.current}
            attachments={supportTicket.attachments}
            description={supportTicket.description}
            recordType={supportTicket.recordType.toUpperCase()}
            ticketId={supportTicket.id}
          />
        </PageActions>
      )}
      <PageContent>
        {supportTicket && (
          <>
            <View data-testid="case-details-header-area">
              <SupportTicketStatusTag
                stringKey={supportTicket.getUiStatus()?.stringKey}
                variant={supportTicket.getUiStatus()?.variant}
              />
              {supportTicket.recordType.toUpperCase() === SUPPORT_TICKET_RECORD_TYPE.CASE ? (
                <SupportCaseInfoBar
                  dateCreated={supportTicket.createdOn}
                  id={supportTicket.id}
                  impactCode={supportTicket.impactCode}
                  isEscalated={supportTicket.isEscalated}
                  isMigrated={supportTicket.isMigrated}
                  lastUpdated={supportTicket.lastModified}
                  priorityCode={supportTicket.priorityCode}
                  productIconUrl={getProductIcon(productList, supportTicket.productCode)}
                  productName={getProductDisplayNameV2(
                    supportTicket.productCode,
                    productList,
                    supportTicket.productName,
                    supportTicket.licenseId
                  )}
                  supportLevel={supportLevel}
                  typeCode={supportTicket.typeCode}
                />
              ) : (
                <ExpertSessionInfoBar
                  dateRequested={supportTicket.createdOn}
                  dateScheduled={supportTicket.scheduledFor}
                  productIconUrl={getProductIcon(productList, supportTicket.productCode)}
                  productName={getProductDisplayNameV2(
                    supportTicket.productCode,
                    productList,
                    supportTicket.productName,
                    supportTicket.licenseId
                  )}
                  sessionId={supportTicket.id}
                  typeCode={supportTicket.typeCode}
                />
              )}
            </View>
            <Divider marginX="size-10" size="M" />
            <View data-testid="case-details-body-area">
              <View
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
                UNSAFE_className={styles['support-ticket-details-discussion-header']}
              >
                <SupportTicketDiscussionHeader />
                <Divider marginX="size-10" size="M" />
                <View // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
                  UNSAFE_className={styles['support-ticket-details-discussion-form-section']}
                >
                  <View marginBottom="size-300">
                    <SupportTicketAddDiscussionItemFormSection
                      discussionItemsLength={supportTicket.getDiscussionItems()?.length}
                      isClosed={!supportTicket.isOpen()}
                      recordType={supportTicket.recordType}
                      refresh={getTicket}
                      ticketId={supportTicket.id}
                    />
                  </View>
                  <SupportTicketDiscussionItems supportTicket={supportTicket} />
                </View>
              </View>
              <View
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
                UNSAFE_className={styles['support-ticket-details-action-section']}
              >
                <SupportTicketActionSection
                  {...supportTicketActionSectionProps.current}
                  canReopen={supportTicket.canReopen}
                  canTakeSurvey={supportTicket.canTakeSurvey(userId)}
                  id={supportTicket.id}
                  isClosed={!supportTicket.isOpen()}
                  isEscalated={supportTicket.isEscalated}
                  isEscalationAllowed={supportTicket.isEscalationAllowed}
                  isOpenCase={supportTicket.isOpen()}
                  isSupportCase={supportTicket.isSupportCase()}
                  refresh={getTicket}
                  status={supportTicket.status}
                  surveyUrl={supportTicket.surveyUrl}
                />
                <SupportTicketContactsSection
                  data-testid="support-ticket-contacts-section"
                  onUpdateSuccess={getTicket}
                  supportTicket={supportTicket}
                />
              </View>
            </View>
          </>
        )}
      </PageContent>
    </Page>
  );
};

SupportTicketDetailsPage.propTypes = {
  /**
   * Used to get the type.
   */
  recordType: PropTypes.oneOf(Object.values(SUPPORT_TICKET_RECORD_TYPE)).isRequired,
};

export default SupportTicketDetailsPage;
