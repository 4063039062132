(function () {
  /**
   * @ngdoc module
   * @name app.widgets.directories.sync
   * @description module that defines directory sync components
   */
  angular.module('app.widgets.directories.sync', [
    'app.core.api.directory-sync',
    'app.core.directories.azure',
    'app.core.directories.sync',
    'binky.core.directory-sync',
    'binky.core.lodash',
    'binky.core.log',
    'binky.core.translation-utils',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.page-banner',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.search',
    'binky.widgets.common.status',
    'binky.widgets.common.toast',
    'binky.widgets.common.toggle',
    'binky.widgets.common.wait',
  ]);
})();
