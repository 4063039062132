(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name app.core.storage-reports:StorageReportList
   * @description Model for a storage report list
   */
  angular.module('app.core.storage-reports').factory('StorageReportList', storageReportList);

  /* @ngInject */
  function storageReportList(
    $q,
    $rootScope,
    _,
    jilStorageFolders,
    List,
    MESSAGE,
    MODEL,
    modelCache,
    StorageReport
  ) {
    class StorageReportList extends List {
      /**
       * @description Method to create a new instance of a StorageReportList.
       * @param {Object} options - options passed to the StorageReportList constructor
       * @param {String} options.folderType - folder path of the storage report, users or assets
       * @param {HybridListState} options.state - hybrid list state
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.STORAGEREPORTLIST,
              itemClassRef: StorageReport,
              resource: jilStorageFolders.storageFoldersByPath,
            },
            options
          )
        );
        this.folderType = options.folderType;
      }

      /**
       * @description Method to permanently delete storage report.
       * @param {Array} reports - reports to be permanently deleted
       * @returns {Promise} resolves to StorageReport on success, else rejects with error
       */
      permanentDelete(reports) {
        this.$resolved = false;

        const operations = _.map(reports, (report) => ({
          op: 'remove',
          path: `/${report.id}/delete`,
        }));

        this.$promise = jilStorageFolders.storageReportFolders
          .batchOperation(operations)
          .$promise.then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess(response) {
          if (
            _.some(response, (item) => !_.chain(item).get('responseCode').inRange(200, 400).value())
          ) {
            return $q.reject();
          }
          this.state.resetParams();
          return this.refresh();
        }

        return this.$promise;
      }

      /**
       * @description Method to refresh Storage Report list from back-end.
       * @returns {Promise} resolves to StorageReport on success, else rejects with error
       */
      refresh() {
        this.$promise = super
          .refresh({path: `/reports/${this.folderType}`})
          .then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(MESSAGE.REFRESH.STORAGEREPORTLIST);
          return this;
        }

        return this.$promise;
      }

      /**
       * @description Method to fetch the StorageReportList. It will be refreshed on
       *     registering the active org
       * @param {Object} options - configuration options for list retrieval
       * @returns {StorageReportList} singleton StorageReportList.
       */
      static get(options = {}) {
        let model = new StorageReportList(options);
        const cachedModel = modelCache.get(
          MODEL.STORAGEREPORTLIST,
          model.key({path: `/reports/${options.folderType}`})
        );
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    modelCache.register(MODEL.STORAGEREPORTLIST, 10);

    modelCache.removeAllOnEvent(MODEL.STORAGEREPORTLIST, [MESSAGE.CREATE.STORAGEREPORT]);

    return StorageReportList;
  }
})();
