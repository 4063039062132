import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Flex, Text, TextArea, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useTrustList from 'features/settings/hooks/api/useTrustList';

const RevokeTrusteeConfirmationModal = ({trustees, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);
  const {revokeTrusts} = useTrustList();

  const [reason, setReason] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const handleReasonValidation = (revocationReason) => {
    setReason(revocationReason);
    if (revocationReason) {
      setIsValid(true);
      return;
    }

    setIsValid(false);
  };

  const handleRevokeTrustee = async () => {
    if (isValid) {
      try {
        setIsLoading(true);

        const {failedToRevoke} = await revokeTrusts(trustees, reason);

        if (failedToRevoke?.length > 0 && trustees.length > 0) {
          showErrorToast(
            intl.formatMessage(
              {
                id: 'settings.trusteeTable.revokeTrusteeModal.toast.error.partial',
              },
              {count: trustees.length, failedCount: failedToRevoke.length}
            )
          );
        } else {
          showSuccessToast(
            intl.formatMessage(
              {
                id: 'settings.trusteeTable.revokeTrusteeModal.toast.success',
              },
              {
                count: trustees.length,
                trusteeOrgName: trustees[0].trusteeOrgName,
              }
            )
          );
        }

        dialog.dismiss();
        onConfirm();
      } catch (error) {
        showErrorToast(
          intl.formatMessage({id: 'settings.trusteeTable.revokeTrusteeModal.toast.error'})
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.trusteeTable.revokeTrusteeModal.cancelButton',
      })}
      isLoading={isLoading}
      // Disable the confirm button when the revocation reason is missing
      isPrimaryActionDisabled={!isValid}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRevokeTrustee}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.trusteeTable.revokeTrusteeModal.confirmButton',
      })}
      title={intl.formatMessage(
        {id: 'settings.trusteeTable.revokeTrusteeModal.title'},
        {
          count: trustees.length,
        }
      )}
      variant="confirmation"
    >
      <Text>
        <FormattedMessage
          id="settings.trusteeTable.revokeTrusteeModal.text"
          values={{
            b:
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
              // istanbul ignore next -- not testing this
              (chunks) => <strong>{chunks}</strong>,
            count: trustees.length,
            // We display directoryName and trusteeOrgName when the admin selected only one directory
            // Those values are ignored when multiple directories are selected
            directoryName: trustees[0].directoryName,
            trusteeOrgName: trustees[0].trusteeOrgName,
          }}
        />
      </Text>
      <Flex direction="column" marginTop="size-200">
        <Text>
          <FormattedMessage
            id="settings.trusteeTable.revokeTrusteeModal.revokeReasonLabel"
            values={{
              count: trustees.length,
            }}
          />
        </Text>
        <TextArea
          errorMessage={intl.formatMessage({
            id: 'settings.trusteeTable.revokeTrusteeModal.textArea.error',
          })}
          isRequired
          label={intl.formatMessage({
            id: 'settings.trusteeTable.revokeTrusteeModal.revokeReasonTextAreaLabel',
          })}
          marginTop="size-200"
          onChange={handleReasonValidation}
          validationState={isValid || reason === null ? 'valid' : 'invalid'}
          width="100%"
        />
      </Flex>
    </ModalAlertDialog>
  );
};

RevokeTrusteeConfirmationModal.displayName = 'RevokeTrusteeConfirmationModal';

RevokeTrusteeConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  trustees: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The ID of the directory
       */
      directoryId: PropTypes.string,
      /**
       * The name of the directory
       */
      directoryName: PropTypes.string,
      /**
       * Denotes if the admin should be notified via email
       * when new users are created by administrators
       * in this trustee organization.
       */
      notifyAdmins: PropTypes.bool,
      /**
       * The ID of the Trustee Org
       */
      trusteeOrgId: PropTypes.string,
      /**
       * The name of the Trustee Org
       */
      trusteeOrgName: PropTypes.string,
      /**
       * The email of the org admin
       */
      trusteeOrgSysAdminEmail: PropTypes.string,
    })
  ).isRequired,
};

export default RevokeTrusteeConfirmationModal;
