(function () {
  /**
   * @deprecated with temp_react_port_jobs
   *
   * @ngdoc factory
   * @name JobList
   * @description
   * Model for a list of Jobs
   */
  angular.module('app.core.jobs').factory('JobList', jobList);

  /* @ngInject */
  function jobList($q, $rootScope, _, jilJobs, Job, MESSAGE) {
    class JobList {
      /**
       * @description Constructs a new Job
       * @param {Object} [options] - options for JobList
       * @param {Object} [options.queryParams] - key/value pairs of query params
       */
      constructor(options = {}) {
        this.queryParams = options.queryParams;
        this.jobs = [];
        this.$resolved = false;

        this.unregisterJobDeleteListener = $rootScope.$on(
          MESSAGE.DELETE.JOB,
          this.refresh.bind(this)
        );
        this.unregisterJobUpdateListener = $rootScope.$on(
          MESSAGE.UPDATE.JOB,
          this.refresh.bind(this)
        );
      }

      /**
       * @description Method to free up any resources that need to be explicitly
       *   removed to prevent memory leaks (i.e. - listeners/handlers, etc).
       */
      destroy() {
        if (_.isFunction(this.unregisterJobDeleteListener)) {
          this.unregisterJobDeleteListener();
        }
        if (_.isFunction(this.unregisterJobUpdateListener)) {
          this.unregisterJobUpdateListener();
        }
      }

      /**
       * @description Method to easily grab all of the deletable Jobs in this
       *   list.
       * @returns {Array} array of deletable Jobs in this list
       */
      getDeletableJobs() {
        return _.filter(this.jobs, (job) => job.canBeDeleted());
      }

      /**
       * @description Method to determine if there are Jobs that can be deleted
       *   in this list.
       * @returns {Boolean} true if at least one Job in list can be deleted,
       *   else false.
       */
      hasDeletableJobs() {
        return _.some(this.jobs, (job) => job.canBeDeleted());
      }

      /**
       * @description Refreshes this JobList instance by fetching the jobs from
       *   the back-end.
       * @return {Promise} a promise which resolves with the refreshed JobList
       *   model, or rejects with an error message
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          jilJobs.jobs.query(this.queryParams, onSuccess.bind(this), onError.bind(this));

          function onError(response) {
            this.$resolved = true;
            reject(response);
          }

          function onSuccess(response) {
            // translate response values into actual Job instances
            this.jobs = _.map(response, Job.apiResponseTransformer);

            // set $resolved attribute to true and resolve promise
            this.$resolved = true;
            resolve(this);

            // notify listeners that list has been refreshed
            $rootScope.$emit(MESSAGE.REFRESH.JOBLIST);
          }
        });
        return this.$promise;
      }

      /**
       * @description Performs the API call to remove jobs from the joblist
       * @param {Array} jobs - Selected job objects that correspond to the jobs
       *   that have been checked in the UI
       * @returns {Promise} result of the operation so the UI can either refresh
       *   or show an alert
       */
      static deleteJobs(jobs) {
        const payload = getDeleteJobsPayload(jobs);
        return jilJobs.jobs.batchOperation(payload).$promise.then((response) => {
          $rootScope.$emit(MESSAGE.DELETE.JOB);
          return response;
        });
      }

      /**
       * @description Returns a new JobList representing a list of jobs which were
       *   performed on the specified directory. The JobList will populate when
       *   the back-end call completes.
       * @param {String} directoryId - the user group ID to fetch jobs for
       * @returns {JobList} a JobList representing a list of jobs performed on the directory
       */
      static getDirectoryUserJobList(directoryId) {
        return get({
          queryParams: _.omitBy({directoryId, types: 'du-remove-users'}, _.isUndefined),
        });
      }

      /**
       * @description Returns a new JobList representing a list of jobs which were
       *   performed on the specified product profile. The JobList will populate
       *   when the back-end call completes.
       * @param {String} profileId - the profile ID to fetch jobs for
       * @param {String} productId - the product ID to fetch jobs for
       * @returns {JobList} a JobList representing a list of jobs performed on the profile
       */
      static getLicenseConfigJobList(profileId, productId) {
        return get({
          queryParams: {
            licenseGroupId: profileId,
            productId,
            types: 'lg-add-users,lg-remove-users',
          },
        });
      }

      /**
       * @description Returns a new JobList representing a list of jobs which were
       *   performed on the specified user group. The JobList will populate when
       *   the back-end call completes.
       * @param {String} userGroupId - the user group ID to fetch jobs for
       * @returns {JobList} a JobList representing a list of jobs performed on the
       *   user group
       */
      static getUserGroupJobList(userGroupId) {
        if (userGroupId) {
          return get({
            queryParams: {
              types: 'ug-add-users,ug-remove-users',
              userGroupId,
            },
          });
        }
        return get({
          queryParams: {
            types: 'add-user-groups,edit-user-groups',
          },
        });
      }

      /**
       * @description Returns a new JobList representing a list of jobs which were
       * performed on the active organization's users. The JobList will populate when the
       * back-end call completes.
       *
       * @returns {JobList} a JobList representing a list of jobs performed on users
       */
      static getUsersJobList() {
        return get({
          queryParams: {
            types:
              'add-users,edit-users,offer-switch-migration,remove-users,revoke-invites,switch-users,team-evip-migration',
          },
        });
      }
    }

    return JobList;

    ////////

    /**
     * @description Method to fetch/refresh job list from back-end.
     * @param {Object} [options] - options for back-end fetch
     * @param {Object} [options.queryParams] - key/value pairs of query params
     * @returns {JobList} fetched instance from back-end
     */
    function get(options) {
      const model = new JobList(options);
      model.refresh();
      return model;
    }

    /**
     * @description Method to construct/return a list of delete operations for a
     *   given list of jobs to delete.
     * @param {Array} jobs - list of jobs to delete
     * @returns {Array} list of delete operations to delete jobs
     */
    function getDeleteJobsPayload(jobs) {
      return _.map(jobs, (job) => ({
        op: 'remove',
        path: `/${job.id}`,
      }));
    }
  }
})();
