import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {useCallback, useMemo, useReducer, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import useSteppedState from 'common/components/stepped-view/useSteppedState';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {
  ADD_DOMAINS_MODAL_WIZARD_STEPS,
  JIL_ADD_DOMAINS_ERROR,
} from 'features/settings/components/domains/add-domains-modal/addDomainsModalConstants';
import useAddDomains from 'features/settings/hooks/api/useAddDomains';
import useTrustRequests from 'features/settings/hooks/api/useTrustRequests';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  DATA_UPDATE: 'dataUpdate',
  FINISH_DATA_LOAD: 'finishDataLoad',
};

/**
 * A reducer which handles the actions dispatch for the addDomains modal
 */
const addDomainsStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case ACTIONS.DATA_UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

/**
 * A hook that holds the state for the add domains setup. It provides methods
 * for previewing domains, requesting access and claiming domains and holds
 * the state for the previewed and selected domains.
 */
const useAddDomainsSetup = () => {
  const intl = useIntl();

  const {claimDomains, previewDomains} = useAddDomains();
  const {sendTrustRequests} = useTrustRequests();
  const {
    state: {domainListData},
  } = useDomainsListContext();

  const [domainsToAdd, setDomainsToAdd] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const hasRequestStep = useRef(false);
  const hasClaimStep = useRef(false);

  const [state, dispatch] = useReducer(addDomainsStateReducer, {
    claimableDomains: [],
    hasLoadingError: false,
    isLoading: false,
    requestAccessDomains: [],
    syntheticErrorDomains: [],
  });

  const addDomainsModalSteps = useMemo(() => Object.values(ADD_DOMAINS_MODAL_WIZARD_STEPS), []);

  const addDomainsModalStepManager = useSteppedState({
    initialStepId: ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP.id,
    steps: addDomainsModalSteps,
  });

  const isCtaButtonDisabled = useMemo(() => {
    // Verify whether among the syntheticErroredDomains there is a domain with the id
    // that matches the selected domain in the table
    const areErroredDomainsSelected = !!selectedDomains?.find((domain) =>
      state.syntheticErrorDomains.find(
        (erroredDomain) => !!erroredDomain?.response?.errorCode && domain === erroredDomain.id
      )
    );

    if (state.isLoading) {
      return true;
    }

    switch (addDomainsModalStepManager.currentStep.id) {
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP.id:
        // Disable the button if no domains are added in the input
        return domainsToAdd.length === 0;
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.REQUEST_ACCESS_STEP.id:
        // Disable the button if domains that cannot be claimed are selected or no domains are selected and there is no claim step
        return areErroredDomainsSelected || (!hasClaimStep.current && selectedDomains.length === 0);
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP.id:
        // Disable the button if domains that cannot be claimed are selected or no domains are selected
        return areErroredDomainsSelected || selectedDomains.length === 0;
      default:
        return true;
    }
  }, [
    addDomainsModalStepManager.currentStep.id,
    domainsToAdd.length,
    selectedDomains,
    state.isLoading,
    state.syntheticErrorDomains,
    hasClaimStep,
  ]);

  const savePreviewDomains = useCallback(async () => {
    dispatch({type: ACTIONS.DATA_LOAD});

    try {
      const response = await previewDomains(domainsToAdd);
      const successDomains = response.filter((r) => r.responseCode >= 200 && r.responseCode <= 300);
      const errorDomains = response.filter(
        (r) => !(r.responseCode >= 200 && r.responseCode <= 300)
      );

      const requestAccessDomains = errorDomains.filter(
        (domain) =>
          domain.response.errorCode === JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE &&
          // filter already trusted domains out
          !domainListData.items.find((item) => item.id === domain.id)
      );

      // Set the availability of the request step based on whether there are domains to request access for
      hasRequestStep.current = requestAccessDomains.length > 0;

      const syntheticErrorDomains = errorDomains
        .filter(
          (domain) =>
            domain.response.errorCode !== JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE ||
            // consider trusted domains as errors
            (domain.response.errorCode === JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE &&
              domainListData.items.find((item) => item.id === domain.id))
        )
        .map((domain) => {
          // change the error code for trusted domains that are already in the org, so we don't show them as request access
          if (domain.response.errorCode === JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE) {
            return {
              ...domain,
              response: {
                ...domain.response,
                errorCode: JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG,
              },
            };
          }

          return domain;
        });

      const claimableDomains = successDomains;
      hasClaimStep.current = claimableDomains.length > 0;

      dispatch({
        payload: {claimableDomains, requestAccessDomains, syntheticErrorDomains},
        type: ACTIONS.FINISH_DATA_LOAD,
      });

      return true;
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.domains.addDomainsModal.toasts.error'}));
      dispatch({
        type: ACTIONS.DATA_LOAD_ERROR,
      });

      return false;
    }
  }, [previewDomains, domainsToAdd, domainListData.items, intl]);

  const requestAccess = useCallback(async () => {
    if (selectedDomains?.length > 0) {
      dispatch({type: ACTIONS.DATA_LOAD});

      const domainsToRequestAccess = selectedDomains?.map((domain) =>
        state.requestAccessDomains.find((requestAccessDomain) => domain === requestAccessDomain.id)
      );

      try {
        const directories = domainsToRequestAccess.map((domain) => ({
          id: domain.response.errorContext.directoryId,
        }));

        const {failedToSend, sent} = await sendTrustRequests(directories);

        dispatch({
          type: ACTIONS.FINISH_DATA_LOAD,
        });

        if (sent?.length > 0) {
          // get the domains that have been successfully sent
          const successDomains = domainsToRequestAccess.filter((domain) =>
            sent.find(
              (sentRequest) => sentRequest.directoryId === domain.response.errorContext.directoryId
            )
          );

          // filter out domains that were sent
          const newRequestAccessDomains = state.requestAccessDomains.filter(
            (domain) => !successDomains.find((sentDomain) => sentDomain.id === domain.id)
          );

          // update the state
          dispatch({
            payload: {requestAccessDomains: newRequestAccessDomains},
            type: ACTIONS.DATA_UPDATE,
          });

          showSuccessToast(
            intl.formatMessage(
              {id: 'settings.domains.addDomainsModal.toasts.requestAccess.success'},
              {directoryCount: directories.length, domainCount: sent.length}
            )
          );
        }

        if (failedToSend?.length > 0) {
          showErrorToast(
            intl.formatMessage({id: 'settings.domains.addDomainsModal.toasts.requestAccess.error'})
          );
        }
      } catch (error) {
        showErrorToast(intl.formatMessage({id: 'settings.domains.addDomainsModal.toasts.error'}));
        dispatch({
          type: ACTIONS.DATA_LOAD_ERROR,
        });
      }
    }

    // Reset the selected domains
    setSelectedDomains([]);

    // If there is a 'Domain Claim' step after requesting access, we are not
    // rendering the errored domains in the table
    dispatch({
      payload: {syntheticErrorDomains: []},
      type: ACTIONS.FINISH_DATA_LOAD,
    });
  }, [selectedDomains, state.requestAccessDomains, sendTrustRequests, intl]);

  const claimSelectedDomains = useCallback(async () => {
    if (selectedDomains.length > 0) {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        await claimDomains(selectedDomains);

        showSuccessToast(
          intl.formatMessage(
            {
              id: 'settings.domains.addDomainsModal.toasts.claimDomains.success',
            },
            {count: selectedDomains.length}
          )
        );

        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
      } catch (error) {
        showErrorToast(intl.formatMessage({id: 'settings.domains.addDomainsModal.toasts.error'}));
        dispatch({
          type: ACTIONS.DATA_LOAD_ERROR,
        });
      }
    }
    // Reset the selected domains
    setSelectedDomains([]);
  }, [claimDomains, intl, selectedDomains]);

  const ctaButtonLabelId = useMemo(() => {
    switch (addDomainsModalStepManager.currentStep.id) {
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP.id:
        return 'common.modal.buttons.next';
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.REQUEST_ACCESS_STEP.id:
        if (selectedDomains?.length === 0 && hasClaimStep.current) {
          return 'common.modal.buttons.next';
        }

        return 'settings.domains.addDomainsModal.buttons.requestAccess';
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP.id:
        return 'settings.domains.addDomainsModal.buttons.addDomains';
      default:
        return 'common.modal.buttons.next';
    }
  }, [addDomainsModalStepManager.currentStep.id, selectedDomains]);

  return {
    addDomainsModalStepManager,
    claimSelectedDomains,
    ctaButtonLabelId,
    hasClaimStep,
    hasRequestStep,
    isCtaButtonDisabled,
    requestAccess,
    savePreviewDomains,
    setDomainsToAdd,
    setSelectedDomains,
    state,
  };
};

export default useAddDomainsSetup;
export {ADD_DOMAINS_MODAL_WIZARD_STEPS, addDomainsStateReducer};
