(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.redirect').config(quickAssignRedirectConfig);

  /* @ngInject */
  function quickAssignRedirectConfig($stateProvider, $transitionsProvider) {
    const urlParam = 'redirectUrl';
    const quickAssignId = 'quick-assign-modal2';
    let redirectUrl = '';
    $stateProvider.state('redirect.redirect.quick-assign-redirect', {
      resolve: {
        modalId: () => quickAssignId,
        onClosed: [
          '$window',
          ($window) => () => {
            $window.location = redirectUrl;
          },
        ],
        /* @ngInject */
        onInitialized:
          (
            _,
            $location,
            $state,
            OrganizationManager,
            panelManager,
            REDIRECT_QUICK_ASSIGN,
            urlUtils
          ) =>
          () => {
            const potentialRedirectUrl = $location.search()[urlParam];
            if (
              potentialRedirectUrl &&
              _.some(REDIRECT_QUICK_ASSIGN.DOMAIN_WHITELIST, (domain) =>
                urlUtils.hasDomain(potentialRedirectUrl, domain)
              )
            ) {
              redirectUrl = potentialRedirectUrl;
              panelManager.open(quickAssignId, {
                isOpenedFromRedirectContext: true,
              });
            } else {
              $state.go('overview.overview2', {
                orgId: OrganizationManager.getActiveOrgId(),
              });
            }
          },
      },
      url: '/quick-assign',
      views: {
        'modal@redirect.redirect': {
          component: 'appQuickAssignModal2',
        },
      },
    });

    // Redirect to overview page if admin doesn't have access to the quick assign modal
    $transitionsProvider.onEnter({to: 'redirect.redirect.quick-assign-redirect'}, (trans) => {
      const OrganizationManager = trans.injector().get('OrganizationManager');
      const productAccess = trans.injector().get('productAccess');

      function routeToOverviewPage() {
        return trans.router.stateService.target('overview.overview2', {
          orgId: OrganizationManager.getActiveOrgId(),
        });
      }

      return productAccess
        .canViewQuickAssignModal()
        .then((canView) => {
          if (!canView) {
            return routeToOverviewPage();
          }
          return true;
        })
        .catch(() => routeToOverviewPage());
    });
  }
})();
