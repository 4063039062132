import {Directory, LicenseGroup, UserGroup} from '@admin-tribe/binky';
import {getProductDisplayName} from '@admin-tribe/binky-ui';
import {useIsMounted} from '@pandora/react-is-mounted';
import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  goToProductBulkOpsDetailsFn,
  goToProductDetails,
  goToProductDetailsBulkOps,
  goToProductProfileBulkOps,
  goToProductProfileBulkOpsDetailsFn,
  goToProductProfileDetails,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
} from 'features/products/routing/productsPaths';
import {
  goToDirectoryList,
  goToDirectoryUserBulkOps,
  goToDirectoryUsers,
  goToUserGroupBulkOperations,
  goToUserGroupBulkOpsDetailsFn,
  goToUserGroupDetails,
  goToUserGroupDetailsJobs,
  goToUserGroups,
  goToUsers,
  goToUsersBulkOperations,
  goToUsersBulkOpsResults,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
  PATH_USER_GROUP_DETAILS_RESULTS,
} from 'features/users/routing/usersPaths';

/**
 * Builds breadcrumbs for the BulkOperationsPage, the JobResultsPage or the LicenseDeficitPage.
 * The React router PATH constant is used to determine the context for the crumbs.
 *
 * @param {String} path. The React router path used to reach this page.
 *   For example, PATH_USERS_BULK_OPERATIONS or PATH_USERS_BULK_OPERATIONS_RESULTS.
 * @param {String} jobResultsLabel. Required if :jobId is in the path.
 *  See showLicenseDeficitReport and showJobResults paths below.
 *  Should initially be null until the Job resolves and this value is available.
 *
 * @returns {Object} breadcrumbs is null until the breadcrumb components resolve and
 *                   the breadcrumbs are completely built. The Page should show isLoading
 *                   until the breadcrumbs are returned.
 *    {Array} breadcrumbs - the crumbs to use to populate the Breadcrumbs Item components.
 *                          Each item has an id and a label. The label can be either a string
 *                          or a tranlation key.
 *    {Function} onAction - the onAction handler for the Breadcrumbs component.
 */
const useBulkOpsBreadcrumbs = ({jobResultsLabel = undefined, intl, path}) => {
  const {directoryId, jobId, productId, profileId, userGroupId} = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const onAction = useRef(null);
  const isMounted = useIsMounted();
  const orgId = rootStore.organizationStore.activeOrgId;

  const showLicenseDeficitReport = [
    PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
    PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  ].includes(path);

  const showJobResults =
    showLicenseDeficitReport ||
    [
      PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
      PATH_USERS_BULK_OPERATIONS_RESULTS,
      PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
      PATH_USER_GROUP_DETAILS_RESULTS,
      PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
      PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
    ].includes(path);

  /**
   * Hook which builds the breadcrumbs and onAction handler for Breadcrumbs.
   */
  useEffect(() => {
    const getProduct = () =>
      rootStore.organizationStore.productList.items.find((item) => item.id === productId);
    const translate = (label) => intl.formatMessage({defaultMessage: label, id: label});

    /**
     * Finishes constructing the crumbs and saves to `breadcrumbs` state variable.
     * Adds the bulk operations crumb and the optional jobResults and license deficit breadcrumbs.
     *
     * @param {Array} crumbs. The unique crumbs before the BulkOperationsPage crumb.
     * @param {Function} onActionBulkOps. The onAction handler for the BulkOperationsPage.
     * @param {Function} [onActionJobResults]. The onActionHandler for the LicenseDeficitPage.
     */
    const saveCrumbs = ({crumbs, onActionBulkOps, onActionJobResults}) => {
      crumbs.push({
        id: 'BulkOperationsPage',
        label: translate('bulkOperations.title'),
        onAction: onActionBulkOps,
      });
      if (showJobResults) {
        crumbs.push({
          id: 'JobResultsPage',
          label: translate(jobResultsLabel),
          onAction: onActionJobResults,
        });
      }
      if (showLicenseDeficitReport) {
        crumbs.push({
          id: 'LicenseDeficitReportPage',
          label: translate('bulkOperations.licenseDeficitReport.title'),
        });
      }
      setBreadcrumbs(crumbs);
    };

    // Fetch the name of the directory for the DirectoryUserPage crumb.
    const fetchDirectoryName = async () => {
      try {
        const directory = await Directory.get({
          id: directoryId,
          orgId,
        });
        return directory.name;
      } catch {
        return translate('bulkOperations.results.breadcrumb.directoryUser.placeholder');
      }
    };

    // Fetch the name of the license group for the Profile crumb.
    const fetchLicenseGroupName = async ({product}) => {
      try {
        const licenseGroup = await LicenseGroup.get({
          id: profileId,
          orgId,
          product,
        });
        return licenseGroup.name;
      } catch (error) {
        return translate('bulkOperations.results.breadcrumb.licenseGroup.placeholder');
      }
    };

    // Fetch the name of the user group for the UserGroupDetailsPage crumb.
    const fetchUserGroupName = async () => {
      let userGroup;
      try {
        userGroup = await UserGroup.get(orgId, userGroupId);
        return userGroup.name;
      } catch (error) {
        return translate('bulkOperations.results.breadcrumb.userGroup.placeholder');
      }
    };

    const createDirectoryUserDetailsBreadcrumbs = async () => {
      const directoryName = await fetchDirectoryName();

      if (isMounted) {
        saveCrumbs({
          crumbs: [
            {
              id: 'OwnedDirectoryListPage',
              label: translate('users.ownedDirectoryListPage.title'),
              onAction: goToDirectoryList,
            },
            {
              id: 'DirectoryUserPage',
              label: directoryName,
              onAction: () => goToDirectoryUsers({directoryId}),
            },
          ],
          onActionBulkOps: () => goToDirectoryUserBulkOps({directoryId}),
        });
      }
    };

    const createProductCrumb = () => {
      const theProduct = getProduct();

      return {
        id: 'ProductPage',
        label: getProductDisplayName(intl, theProduct),
        onAction: () => goToProductDetails({productId}),
      };
    };

    const createProductBreadcrumbs = () => {
      saveCrumbs({
        crumbs: [createProductCrumb()],
        onActionBulkOps: () => goToProductDetailsBulkOps({productId}),
        onActionJobResults: () => goToProductBulkOpsDetailsFn({productId})({jobId}),
      });
    };

    const createProductProfileBreadcrumbs = async () => {
      const product = getProduct();
      const profileName = await fetchLicenseGroupName({product});

      saveCrumbs({
        crumbs: [
          createProductCrumb(),
          {
            id: 'ProductProfileDetailsPage',
            label: profileName,
            onAction: () => goToProductProfileDetails({productId, profileId}),
          },
        ],
        onActionBulkOps: () => goToProductProfileBulkOps({productId, profileId}),
        onActionJobResults: () =>
          goToProductProfileBulkOpsDetailsFn({productId, profileId})({jobId}),
      });
    };

    const createUserBreadcrumbs = () =>
      saveCrumbs({
        crumbs: [{id: 'UsersPage', label: translate('users.title'), onAction: goToUsers}],
        onActionBulkOps: goToUsersBulkOperations,
        onActionJobResults: () => goToUsersBulkOpsResults({jobId}),
      });

    const createUserGroupBreadcrumbs = () => {
      saveCrumbs({
        crumbs: [
          {
            id: 'UserGroupsPage',
            label: translate('users.usersGroups.title'),
            onAction: goToUserGroups,
          },
        ],
        onActionBulkOps: goToUserGroupBulkOperations,
      });
    };

    const createUserGroupDetailsBreadcrumbs = async () => {
      const userGroupName = await fetchUserGroupName();

      if (isMounted) {
        saveCrumbs({
          crumbs: [
            {
              id: 'UserGroupsPage',
              label: translate('users.usersGroups.title'),
              onAction: goToUserGroups,
            },
            {
              id: 'UserGroupDetailsPage',
              label: userGroupName,
              onAction: () => goToUserGroupDetails({userGroupId}),
            },
          ],
          onActionBulkOps: () => goToUserGroupDetailsJobs({userGroupId}),
          onActionJobResults: () => goToUserGroupBulkOpsDetailsFn({userGroupId})({jobId}),
        });
      }
    };

    // eslint-disable-next-line complexity -- it is complex
    const buildBreadcrumbs = () => {
      switch (path) {
        case PATH_PRODUCT_DETAILS_BULK_OPERATIONS:
        case PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS:
        case PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT:
          createProductBreadcrumbs();
          break;

        case PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS:
        case PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS:
        case PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT:
          createProductProfileBreadcrumbs();
          break;

        case PATH_USERS_BULK_OPERATIONS:
        case PATH_USERS_BULK_OPERATIONS_RESULTS:
        case PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT:
          createUserBreadcrumbs();
          break;

        case PATH_USER_GROUPS_BULK_OPERATIONS:
        case PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS:
          createUserGroupBreadcrumbs();
          break;

        case PATH_USER_GROUP_DETAILS_BULK_OPERATIONS:
        case PATH_USER_GROUP_DETAILS_RESULTS:
        case PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT:
          createUserGroupDetailsBreadcrumbs();
          break;

        case PATH_DIRECTORY_USER_BULK_OPERATIONS:
        case PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS:
          createDirectoryUserDetailsBreadcrumbs();
          break;

        default:
          throw new Error(`useBulkOpsJobResultsBreadcrumbs: path "${path}" not handled`);
      }

      // eslint-disable-next-line no-shadow -- prop name for callback should be breadcrumbs
      onAction.current = ({breadcrumbs, key}) => {
        const item = breadcrumbs.find((crumb) => crumb.id === key);
        item.onAction?.(key);
      };
    };

    if (breadcrumbs === null && (!showJobResults || jobResultsLabel)) {
      buildBreadcrumbs();
    }
  }, [
    breadcrumbs,
    directoryId,
    intl,
    isMounted,
    jobId,
    jobResultsLabel,
    orgId,
    path,
    profileId,
    productId,
    showJobResults,
    showLicenseDeficitReport,
    userGroupId,
  ]);

  // breadcrumbs is null until all breadcrumb labels are resolved.
  return {breadcrumbs, onAction: onAction.current};
};

export default useBulkOpsBreadcrumbs;
