import {PageBanner} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A wrapper component of the Feature Restricted Page Banner
 */
const DeviceLicensesPageBanner = ({namespace, showBanner}) => {
  const intl = useIntl();
  return showBanner ? (
    <PageBanner header={intl.formatMessage({id: `${namespace}.banner.header`})} variant="info">
      {intl.formatMessage({id: `${namespace}.banner.content`})}
    </PageBanner>
  ) : null;
};

DeviceLicensesPageBanner.propTypes = {
  /**
   * The common namespace for react-intl
   */
  namespace: PropTypes.string.isRequired,

  /**
   * Whether to show banner
   */
  showBanner: PropTypes.bool.isRequired,
};
export default DeviceLicensesPageBanner;
