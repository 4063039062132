/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseProductMetadataFormSection
 *
 * @description The form for collecting additional details for a specific product.
 *  Each product may have a subset of inputs based on 'productMetadata'
 * @param {Object} productMetadata - info about the input to get, and the possible values
 * @binding {Function} onChange - callback when the metadata transitions between valid and invalid or
 *   already valid but an input has changed
 */
(function () {
  angular.module('app.widgets.support').component('appCaseProductMetadataFormSection', {
    bindings: {
      onChange: '&',
      productMetadata: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-product-metadata-form-section/case-product-metadata-form-section.component.html',
  });

  function controller(_) {
    // Properties to pick from GET: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1756603250
    // Map each property value for POST: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=CAPS&title=Create+Case+API+Specs
    const PRODUCT_TO_CASE_METADATA_MAP = {
      component: 'ent_productcomponent',
      consoleBuild: 'ent_consolebuild',
      environment: 'ent_environment',
      project: 'ent_project',
      serverBuild: 'ent_serverbuild',
      version: 'ent_productversion',
    };

    const PRODUCT_TO_CASE_MILO_METADATA_MAP = {
      component: 'componentId',
      consoleBuild: 'consoleBuildId',
      environment: 'environmentId',
      project: 'projectId',
      serverBuild: 'serverBuildId',
      version: 'versionId',
    };

    const MAX_INSTANCE_URL_ROWS = 5;

    const vm = this;

    let instanceUrl,
      isFormInvalid,
      productInfoFields,
      productMetadataFormInput,
      productMetadataKeys,
      showInstanceUrl;

    _.assign(vm, {
      $onInit,
      MAX_INSTANCE_URL_ROWS,
      onInstanceUrlChange,
      onProductMetadataChange,
    });

    function $onInit() {
      productMetadataFormInput = {};
      instanceUrl = undefined;
      isFormInvalid = true;

      // 'instanceUrl' is the only prop that can be true / false / blank.
      showInstanceUrl = !!_.get(vm.productMetadata, 'instanceUrl', false);

      // Other fields either exist or not exist, so use the white-listed properties
      productInfoFields = _.pick(vm.productMetadata, _.keys(PRODUCT_TO_CASE_METADATA_MAP));
      productMetadataKeys = _.keys(productInfoFields);

      vm.showSection = showInstanceUrl || !_.isEmpty(productInfoFields);
      checkFormValidity();
    }

    function onInstanceUrlChange(val) {
      instanceUrl = val;
      checkFormValidity();
    }

    function onProductMetadataChange(selectedVal, key) {
      productMetadataFormInput[key] = selectedVal;
      checkFormValidity();
    }

    ////////

    function convertFormInputToApiParams() {
      return _.mapKeys(
        productMetadataFormInput,
        (value, key) => PRODUCT_TO_CASE_MILO_METADATA_MAP[key]
      );
    }

    function hasAllRequiredProperties() {
      return (
        _.every(productMetadataKeys, (key) => !_.isUndefined(productMetadataFormInput[key])) &&
        (!showInstanceUrl || instanceUrl)
      );
    }

    function checkFormValidity() {
      const isInvalid = !hasAllRequiredProperties();

      // Report validity transitions so CTA button can be enabled accordingly.
      // While form is valid, report every change because the CTA button can be clicked and the modal must have the
      // current form values.
      if (isInvalid !== isFormInvalid || !isInvalid) {
        isFormInvalid = isInvalid;
        vm.onChange({caseMetadata: convertFormInputToApiParams(), instanceUrl, isInvalid});
      }
    }
  }
})();
