(function () {
  /**
   * @ngdoc module
   * @name app.account
   * @description module that defines account pages/routes
   */
  angular.module('app.account', [
    'app.account.access',
    'app.core.contract.access',
    'app.core.organizations',
    'app.core.organizations.access',
    'app.core.organizations.organization-manager',
    'app.core.ready.org',
    'app.widgets.account',
    'app.widgets.contracts',
    'app.widgets.products',
    'app.widgets.products.purchase.add-products-modal',
    'binky.core.feature',
    'binky.core.invoice',
    'binky.core.lodash',
    'binky.core.offers.fulfillable-items',
    'binky.core.page-context',
    'binky.core.product.consumable',
    'binky.shell.navigation.assembler',
    'binky.shell.navigation.constants',
    'binky.shell.workspace',
    'binky.shell.workspace.page',
    'binky.shell.workspace.page.header',
    'binky.ui-migration',
    'binky.widgets.common.feature',
    'chart.js',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
