import {CollapsibleList, CollapsibleListContentModel} from '@pandora/react-collapsible-list';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import style from './AddOnsSummary.pcss';

// AddOnsSummary component shows selected add-ons for a product.
const AddOnsSummary = (props) => {
  const {selectedAddOnsList, showHeading = true} = props;
  const content = CollapsibleListContentModel.createEntry({}, '', true);
  const intl = useIntl();

  return (
    // adding background color as transparent with default light theme, as focus ring is
    // not appearing with lightest theme
    <SpectrumV2Provider className={style['background-transparent']}>
      <CollapsibleList
        content={content}
        header={
          showHeading
            ? intl.formatMessage({
                id: 'packages.createPackage.addOns.addOnsTitle',
              })
            : intl.formatMessage({
                id: 'packages.createPackage.addOnsMenu.selectedHeader',
              })
        }
        itemName={intl.formatMessage({
          id: 'packages.createPackage.addOnsMenu.itemName',
        })}
        items={selectedAddOnsList.map((addOn) => addOn.displayName)}
        showCount
      />
    </SpectrumV2Provider>
  );
};

AddOnsSummary.propTypes = {
  /**
   * List of selected add-ons
   */
  selectedAddOnsList: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  /**
   * Boolean to control heading -`App add-ons`
   */
  showHeading: PropTypes.bool,
};

export default AddOnsSummary;
