(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ExternalLink
   * @description Model for a external link
   */
  angular.module('app.shell.navigation.links').factory('ExternalLink', getExternalLinkModel);

  /* @ngInject */
  function getExternalLinkModel($filter, $q, $window, _, Link, LINK_TARGET_TYPE) {
    class ExternalLink extends Link {
      /**
       * @description Creates a new ExternalLink for use.
       * @param {Object} options - See super class for other parameters
       */
      constructor(options = {}) {
        super(options);
      }

      /**
       * @description Method to navigate to the locator described by this link.
       */
      goToLink() {
        switch (this.target) {
          case LINK_TARGET_TYPE.EXTERNAL:
            $window.location.href = this.locator;
            break;
          case LINK_TARGET_TYPE.GO_URL:
            $window.open($filter('goUrl')(this.locator), '_blank');
            break;
          default:
            _.noop();
        }
      }

      /* eslint-disable class-methods-use-this */
      /**
       * @description Method to determine whether or not the locator described
       *   by this link is available (navigable) by the current user.
       * @returns {Promise} resolves to true if link is available, else false
       */
      isAvailable() {
        return $q.resolve(true);
      }
      /* eslint-enable class-methods-use-this */
    }

    return ExternalLink;
  }
})();
