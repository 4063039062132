import {eventBus, log} from '@admin-tribe/binky';

import contentPlatformVa6Direct from 'core/content-platform/contentPlatformVa6Direct';

import StorageFile from '../storage-file/StorageFile';

import {REPORT_STATUS} from './StorageReportConstants';

const STORAGE_REPORT_EVENTS = {CREATE: 'CreateStorageReport'};

class StorageReport extends StorageFile {
  /**
   * @description Method to create a shared storage or individual user storage report.
   *
   * @param {String} type - the type of report to be created - REPORT_FOLDER_TYPE.ASSETS or
   *   REPORT_FOLDER_TYPE.USERS
   * @returns {Promise} resolves if successfully create report, else rejects with error message
   */
  static async create(type) {
    let promise;
    try {
      promise = await contentPlatformVa6Direct.createStorageReport(type);
    } catch (error) {
      log.error('Failed to create storage report. Error: ', error);
      return Promise.reject(error);
    }
    eventBus.emit(STORAGE_REPORT_EVENTS.CREATE, this);
    return promise;
  }

  /**
   * @description Creates a new StorageReport for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.id A StorageReport's ID
   * @param {String} options.linkPath A link path to download storage report
   * @param {String} options.name A StorageReport's name
   * @param {String} options.path the path of the StorageReport
   * @param {StorageQuota} options.quota the storage quota of the StorageReport
   * @param {Date} options.repositoryCreatedDate The creation date of the StorageReport
   * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageReport
   * @param {String} options.state the state of the StorageReport
   * @param {String} options.type the type of the file which StorageReport is stored
   */
  constructor(options = {}) {
    super(options);

    this.status = REPORT_STATUS.SUCCESS;
    if (this.name?.startsWith(REPORT_STATUS.ERROR)) {
      this.status = REPORT_STATUS.ERROR;
    } else if (this.name?.startsWith(REPORT_STATUS.INPROGRESS)) {
      this.status = REPORT_STATUS.INPROGRESS;
    }
    this.name = this.name?.replace(`${this.status}_`, '');
  }
}

export {STORAGE_REPORT_EVENTS};

export default StorageReport;
