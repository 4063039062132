import {PREFERENCES_CONSTANTS} from '../../packagesConstants';

/**
 * @description Method to load the previous URL in the history list
 *
 */
const goBack = () => {
  if (typeof window !== 'undefined') {
    window.history.back();
  }
};

/**
 * @description Method to check if given path is a valid mac path
 * @param {String} path The install path on macos
 *
 * @returns {Boolean} returns true/false
 */
function isMacPathValid(path = '') {
  // if the path is empty string then valid path so return true
  if (path.length === 0) {
    return true;
  }
  const macPathRegex = new RegExp(PREFERENCES_CONSTANTS.MAC_PATH_REGEX);
  return path.length > 1 && macPathRegex.test(path);
}

/**
 * @description Method to check if given path is a valid windows path
 * @param {String} path The install path on windows
 *
 * @returns {Boolean} returns true/false
 */
function isWinPathValid(path = '') {
  // if the path is empty string then valid path so return true
  if (path.length === 0) {
    return true;
  }
  const winPathRegex = new RegExp(PREFERENCES_CONSTANTS.WIN_PATH_REGEX);
  return winPathRegex.test(path);
}

export {goBack, isMacPathValid, isWinPathValid};
