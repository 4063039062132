import {defineContents} from '@pandora/react-content-provider';
import {ModalDialogModel} from '@pandora/react-modal-dialog';

// A custom content model for the AppIntegrationsPolicyModal
const AppIntegrationsPolicyModalContentModel = defineContents('AppIntegrationsPolicyModalContent', {
  modalDialogContent: {
    id: 'modalDialogContent',
    type: ModalDialogModel,
  },
});

export default AppIntegrationsPolicyModalContentModel;
