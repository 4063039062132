(function () {
  /**
   * @deprecated Please use src2/app/core/contract/auto-renewal/autoRenewalUtils.js
   *
   * @ngdoc service
   * @name app.core.contract.auto-renewal.auto-renewal-helper
   * @description auto renewal helper factory
   */
  angular
    .module('app.core.contract.auto-renewal.auto-renewal-helper')
    .factory('autoRenewalHelper', getAutoRenewalHelper);

  const T = 0;
  const T30 = 30;
  const T45 = 45;
  /* @ngInject */
  function getAutoRenewalHelper(CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT) {
    const service = {
      getCurrentPointInRenewalInContract,
      getCurrentPointInRenewalInOverview,
    };

    return service;

    /**
     * @description Method to get the current point in the renewal window (or outside of it), so we
     * can provide custom messaging to the user. This caters for before T-45 (NA),
     * T-45 to T-30 (PRE_RENEWAL_WINDOW), T-30 to T (IN_RENEWAL_WINDOW) and then
     * post T (POST_RENEWAL_WINDOW)
     *
     * @param {Contract} contract - The contract that you want to determine the current point in
     * the renewal window for
     * @returns {string} The current point in the renewal window, 'NA', 'PRE_RENEWAL_WINDOW',
     * 'IN_RENEWAL_WINDOW', 'POST_RENEWAL_WINDOW'.
     */
    function getCurrentPointInRenewalInContract(contract) {
      return getCurrentPointInRenewal(contract.getOffsetFromAnniversaryDate());
    }

    /**
     * @description Method to get the current point in the renewal window (or outside of it), so we
     * can provide custom messaging to the user. This caters for before T-45 (NA),
     * T-45 to T-30 (PRE_RENEWAL_WINDOW), T-30 to T (IN_RENEWAL_WINDOW) and then
     * post T (POST_RENEWAL_WINDOW)
     *
     * @param {Object} overview - The renewal overview as calculated in
     * contractAndProductsToOverview in the contract compliance service, that you want to determine
     * the current point in the renewal window for
     * @returns {string} The current point in the renewal window, 'NA', 'PRE_RENEWAL_WINDOW',
     * 'IN_RENEWAL_WINDOW', 'POST_RENEWAL_WINDOW'.
     */
    function getCurrentPointInRenewalInOverview(overview) {
      return getCurrentPointInRenewal(overview.offsetFromAnniversaryDate);
    }

    function getCurrentPointInRenewal(offset) {
      if (offset < T) {
        return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.POST_RENEWAL_WINDOW;
      } else if (offset >= T && offset < T30) {
        return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.IN_RENEWAL_WINDOW;
      } else if (offset <= T45) {
        return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW;
      }
      return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.NA;
    }
  }
})();
