(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.timezone-search-list:appTimezoneSearchList
   *
   * @description Used to select one of the Dynamics timezones for a support ticket.
   * @param {labelId} labelId A space delimited set of ids for the HTML elements that provide a label
   *   for the control. Note the current implementation is using a widget which doesn't support this.
   * @param {Function} onChange Callback function on timezone change. Param is 'timezone'.
   * @param {Integer} [timeZone] the Dynamics timezone ID to pre-select - one of the DYNAMICS_IDs
   * in SUPPORT_TICKET_TIMEZONE.
   */
  angular.module('app.widgets.timezone-search-list').component('appTimezoneSearchList', {
    bindings: {
      labelId: '@?',
      onChange: '&',
      timezone: '@?',
    },
    controller,
    templateUrl: 'app/widgets/common/timezone-search-list/timezone-search-list.component.html',
  });

  function controller($filter, _, SUPPORT_TICKET_TIMEZONE, supportTicketTimezone) {
    const vm = this;

    const allTimezones = getAllTimezones();

    _.assign(vm, {
      $onInit,
      onSearch,
      onSearchListChange,
      searchResults: [],
      selectedTimezone: null,
    });

    function $onInit() {
      _.assign(vm, {
        searchResults: allTimezones,
        selectedTimezone: getInitialTimezoneSelection(),
      });
    }

    function onSearch(searchQuery) {
      const searchQueryLowerCased = _.toLower(searchQuery);
      // Disable rule as it is mistakenly applying to _.filter rather than angular filter

      vm.searchResults = _.filter(allTimezones, (timezone) =>
        _.chain(timezone.name).toLower().includes(searchQueryLowerCased).value()
      );
    }

    function onSearchListChange(timezoneAsString) {
      const timezone = convertStringToTimezone(timezoneAsString);
      onTimezoneChange(timezone);
    }

    //////////////

    function convertStringToTimezone(timezoneAsString) {
      // timezoneString can be empty if the user cleared the current timezone selection
      const timezone = timezoneAsString ? _.toNumber(timezoneAsString) : null;
      return _.isInteger(timezone) ? timezone : null;
    }

    function getAllTimezones() {
      return _(SUPPORT_TICKET_TIMEZONE)
        .map((tz) => ({
          name: $filter('appSupportTicketTimezone')(tz.DYNAMICS_ID),
          utcOffset: tz.UTC_OFFSET,
          value: tz.DYNAMICS_ID,
        }))
        .orderBy(['utcOffset', 'name'])
        .value();
    }

    function getInitialTimezoneSelection() {
      if (vm.timezone) {
        return convertStringToTimezone(vm.timezone);
      }
      const guessedTimezone = supportTicketTimezone.guess();
      // If a timezone was successfully guessed, notify the client
      if (_.isInteger(guessedTimezone)) {
        onTimezoneChange(guessedTimezone);
      }
      return guessedTimezone;
    }

    function onTimezoneChange(timezone) {
      vm.onChange({timezone});
    }
  }
})();
