import rootStore from 'core/RootStore';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

import {CREATE_PACKAGE_CONSTANTS} from '../../../../packagesConstants';
import PackagesIngestAnalytics from '../../../../services/PackagesIngestAnalyticsService';

/**
 * @description Downloads tool from the url specified and triggers download analytics if event name passed
 * @param {String} toolUrl - tool url
 * @param {String} analyticsEventName - event name
 *
 */
function downloadTool(toolUrl, analyticsEventName) {
  if (typeof window !== 'undefined') {
    window.open(toolUrl, '_self');
    if (analyticsEventName) {
      PackagesIngestAnalytics.triggerAnalytics({
        subCategory: 'FRL',
        subType: 'offline-activation-code-generator',
        type: 'click',
        value: analyticsEventName === 'censusToolDownloadWin' ? 'Windows' : 'Mac',
      });
    }
  }
}

/**
 * @description Check if the url exists for downloading the server config tool of particular platform and
 * @returns list of platforms for which download server config tool link exists
 */
const getDownloadItemsForLan = () => {
  const downloadItems = [];
  if (rootStore.packagesUiConstantsStore.serverConfigToolMacUrl) {
    downloadItems.push({
      key: 'linux',
    });
  }

  if (rootStore.packagesUiConstantsStore.serverConfigToolWinUrl) {
    downloadItems.push({
      key: 'win',
    });
  }

  return downloadItems;
};

/**
 * @description Check if the url exists for downloading the adobe code generator tool of particular platform and
 * @returns list of platforms for which download adobe code generator tool link exists
 */
const getDownloadItemsForIsolated = () => {
  const downloadItems = [];
  if (rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacUrl) {
    downloadItems.push({
      key: 'mac',
    });
  }

  if (rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacArmUrl) {
    downloadItems.push({
      key: 'macarm',
    });
  }

  if (rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinUrl) {
    downloadItems.push({
      key: 'win',
    });
  }

  if (rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinArmUrl) {
    downloadItems.push({
      key: 'winarm',
    });
  }

  return downloadItems;
};

/**
 * @description Triggers download of lan server tool based on menu item/platform pressed
 * @param {String} key - key of menu item selected/ platform
 *
 */
const onDownloadItemPressForLan = (key) => {
  // Key is linux, even though mac url fetched, this is intentional
  switch (key) {
    case 'linux':
      downloadTool(rootStore.packagesUiConstantsStore.serverConfigToolMacUrl);
      break;
    case 'win':
      downloadTool(rootStore.packagesUiConstantsStore.serverConfigToolWinUrl);
      break;
    default:
      break;
  }
};

/**
 * @description Triggers download of adobe code generator tool based on menu item/platform pressed
 * @param {String} name - key of menu item selected/ platform
 *
 */
const onDownloadItemPressForIsolated = (key) => {
  switch (key) {
    case 'mac':
      downloadTool(
        rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacUrl,
        'censusToolDownloadMac'
      );
      break;
    case 'macarm':
      downloadTool(
        rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacArmUrl,
        'censusToolDownloadMacArm'
      );
      break;
    case 'win':
      downloadTool(
        rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinUrl,
        'censusToolDownloadWin'
      );
      break;
    case 'winarm':
      downloadTool(
        rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinArmUrl,
        'censusToolDownloadWinArm'
      );
      break;
    default:
      break;
  }
};

// Activation cards data
const activationCardsData = [
  {
    getDownloadMenuItems: () => undefined,
    name: 'ent_learnmore_ConnectedActivation',
    packageType: CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE,
  },
  {
    getDownloadMenuItems: getDownloadItemsForLan,
    hasPrerequisites: true,
    hasServerLink: true,
    name: 'ent_learn_FRL_LAN',
    onDownloadItemPress: onDownloadItemPressForLan,
    packageType: CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE,
  },
  {
    getDownloadMenuItems: () => undefined,
    name: 'ent_FRL_Offiline_Help',
    packageType: CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE,
  },
  {
    getDownloadMenuItems: getDownloadItemsForIsolated,
    hasPrerequisites: true,
    name: 'ent_learnmore_IsolatedActivation',
    onDownloadItemPress: onDownloadItemPressForIsolated,
    packageType: CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE,
  },
];

/**
 * @description Returns subtype for pkg type selected
 * @param {Object} pkgType - selected package type
 *
 * @return {String} subtype
 */
function getSubType(pkgType) {
  let subType;
  switch (pkgType) {
    case CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE:
      subType = 'offline-activation';
      break;

    case CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE:
      subType = 'lan-activation';
      break;

    case CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE:
      subType = 'online-activation';
      break;

    case CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE:
      subType = 'new-offline-activation';
      break;

    default:
      break;
  }
  return subType;
}

/**
 * @description Returns boolean value to indicate if card should be displayed
 * @param {Object} PackagesEntitlements - service passed as prop from angular code(will be replaced by import of react version of the service)
 * @param {String} pkgType - selected package type
 *
 * @return {Boolean} if card should be displayed
 */
const showCard = (pkgType) => {
  switch (pkgType) {
    case CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE:
      return PackagesEntitlements.hasFrlOnlineEntitlement;
    case CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE:
      return PackagesEntitlements.hasFrlLanEntitlement;
    case CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE:
      return PackagesEntitlements.hasFrlOfflineEntitlement;
    case CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE:
      return PackagesEntitlements.hasFrlIsolatedEntitlement;
    default:
      return false;
  }
};

export {activationCardsData, getSubType, showCard};
