import {jilDomains, log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';

/**
 * A hook that helps with making calls to the JIL domains API
 * Handles previewing domains.
 */
const useAddDomains = () => {
  const previewDomains = useCallback(async (domains) => {
    const request = domains.map((domain) => ({
      domainName: domain,
    }));

    try {
      const response = await jilDomains.postDomainsPreview(
        {
          orgId: rootStore.organizationStore.activeOrgId,
        },
        request
      );

      return response.data;
    } catch (error) {
      log.error('[ID][JIL] Error previewing domains', error);

      throw error;
    }
  }, []);

  const claimDomains = useCallback(async (domains) => {
    const request = domains.map((domain) => ({
      domainName: domain,
    }));

    try {
      const response = await jilDomains.postDomains(
        {
          orgId: rootStore.organizationStore.activeOrgId,
        },
        request
      );

      return response.data;
    } catch (error) {
      log.error('[ID][JIL] Error claiming domains', error);

      throw error;
    }
  }, []);

  return {claimDomains, previewDomains};
};

export default useAddDomains;
