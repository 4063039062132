(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name SamlEventList
   * @description Model for a Saml event list
   */
  angular.module('app.core.saml-event').factory('SamlEventList', getSamlEventListModel);

  /* @ngInject */
  function getSamlEventListModel($q, _, cvl, List, SamlEvent) {
    class SamlEventList extends List {
      refresh(options) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          cvl.event.query(options, onSuccess.bind(this), onError.bind(this));

          function onError(response) {
            this.$resolved = true;
            reject(response);
          }

          function onSuccess(response) {
            // translate response values into actual Product instances
            this.items = _.map(response.results, initEvent);
            // Even though the backend returns more than 10k, but they only support 10k
            // https://git.corp.adobe.com/admin-tribe/onesie/issues/1252
            const ITEM_LIMIT = 10000;
            if (response.totalSize > ITEM_LIMIT) {
              response.totalSize = ITEM_LIMIT;
            }
            this.pagination.itemCount = response.totalSize;

            // set $resolved attribute to true and resolve promise
            this.$resolved = true;
            resolve(this);

            ////////

            function initEvent(event) {
              return SamlEvent.apiResponseTransformer(event);
            }
          }
        });
        return this.$promise;
      }

      /**
       * @description Method to retrieve an existing SamlEvent List from the back-end.
       *  This is not cached since the event times for the filter are real time and are always changing.
       * @param {Object} [options] - Configuration options for list retrieval
       * @returns {SamlEventList} new SamlEventList instance, fetched from back-end
       */
      static get(options = {}) {
        const model = new SamlEventList();
        model.refresh(options);

        return model;
      }
    }

    return SamlEventList;
  }
})();
