import {useCallback, useReducer} from 'react';

import useDomainList from 'features/settings/hooks/api/useDomainList';
import DomainListModel from 'features/settings/models/DomainListModel';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
  UPDATE_DATA: 'updateData',
};

/**
 * A reducer which handles the actions dispatch for the domainList
 */
const domainListStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        domainCount: action.payload.domainCount ?? state.domainCount,
      };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        domainListData: [...action.payload],
      };
    default:
      return state;
  }
};

/**
 * A generic hook that can be used to create a domain list state
 */
const useDomainListState = ({fetchFn, tableOptions, setTableOptions} = {}, initialData = null) => {
  const {clearDomainsCache} = useDomainList();
  const [state, dispatch] = useReducer(domainListStateReducer, {
    domainCount: initialData?.totalCount ?? 0,
    domainListData: DomainListModel.fromObject(initialData),
    hasLoadingError: false,
    isLoading: false,
  });

  const canAddDomains = initialData?.canAddDomains;

  /**
   * Function used to fetch a DomainList.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadDomainList = useCallback(
    async (params, {updateCount = false} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const response = await fetchFn(params);

        const domainList = DomainListModel.fromObject(response);
        setTableOptions({...tableOptions, ...params, totalPages: domainList.totalPages});

        dispatch({
          payload: {
            domainCount: updateCount ? response.totalCount : null,
            domainListData: domainList,
          },
          type: ACTIONS.FINISH_DATA_LOAD,
        });

        return domainList;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [fetchFn, setTableOptions, tableOptions]
  );

  /**
   * Function used to patch the data from the state
   */
  const updateDomainList = useCallback((data) => {
    dispatch({payload: data, type: ACTIONS.UPDATE_DATA});
  }, []);

  /**
   * Function used to reload the list of domains
   */
  const reloadDomainList = async (params, options) => {
    // clear the cache and reload the list of domains
    clearDomainsCache();
    await loadDomainList(params, options);
  };

  return {
    canAddDomains,
    loadDomainList,
    reloadDomainList,
    state,
    updateDomainList,
  };
};

export default useDomainListState;

// exported for unit-testing
export {domainListStateReducer};
