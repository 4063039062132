import {ActionMenu, Item, Text} from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AppConstants from 'common/services/AppConstants';
import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import {ACTIONS} from 'features/settings/components/edu-sync-card/footer/eduActionsUtils';

import styles from './EduSyncActionMenu.pcss';

const getProviderPortalLabelId = (rosterSource) => {
  switch (rosterSource.toLowerCase()) {
    case SYNC_PROVIDERS.CLEVER:
      return 'settings.sync.eduSync.card.footer.goToClever';
    case SYNC_PROVIDERS.CLASSLINK:
      return 'settings.sync.eduSync.card.footer.goToClasslink';
    default:
      return 'settings.sync.eduSync.card.footer.goToRosterProvider';
  }
};

const goToProviderPage = (provider) => {
  const url =
    provider.toLowerCase() === 'clever'
      ? 'https://schools.clever.com/applications'
      : AppConstants.configuration.services.eduRosterSync.classLinkLoginPage;

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- not on server side
  window.open(url, '_blank');
};

const EduSyncActionMenu = ({disabledEduActions, onStopEduSync, onSyncSettings, rosterSource}) => {
  const intl = useIntl();

  const providerLabel = getProviderPortalLabelId(rosterSource);

  const onAction = (action) => {
    switch (action) {
      case ACTIONS.STOP_SYNC:
        onStopEduSync();
        break;
      case ACTIONS.OPEN_PROVIDER_PORTAL:
        goToProviderPage(rosterSource);
        break;
      case ACTIONS.OPEN_SYNC_SETTINGS:
        onSyncSettings();
        break;
      default:
        break;
    }
  };

  return (
    <ActionMenu
      data-testid="edu-roster-actions-menu"
      disabledKeys={disabledEduActions}
      isQuiet
      marginEnd="size-200"
      onAction={onAction}
    >
      <Item key={ACTIONS.STOP_SYNC}>
        {intl.formatMessage({id: `settings.sync.eduSync.card.footer.buttons.stopRosterSync`})}
      </Item>
      <Item key={ACTIONS.OPEN_SYNC_SETTINGS}>
        {intl.formatMessage({id: `settings.sync.eduSync.card.footer.buttons.syncSettings`})}
      </Item>
      <Item
        key={ACTIONS.OPEN_PROVIDER_PORTAL}
        data-testid="go-to-provider-action"
        textValue={intl.formatMessage({id: providerLabel})}
      >
        <Text>{intl.formatMessage({id: providerLabel})}</Text>
        <LinkOut
          marginStart="size-100"
          /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- prevents default icon
                    aligment in grid */
          UNSAFE_className={styles['edu-sync-action-menu']}
        />
      </Item>
    </ActionMenu>
  );
};

EduSyncActionMenu.propTypes = {
  /**
   * Array of disabled actions for the buttons menu
   */
  disabledEduActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTIONS))).isRequired,
  /**
   * Callback to stop edu roster sync
   */
  onStopEduSync: PropTypes.func.isRequired,
  /**
   * Callback to open edu roster sync settings modal
   */
  onSyncSettings: PropTypes.func.isRequired,
  /**
   * The roster sync provider. The supported providers are Clever and Classlink
   */
  rosterSource: PropTypes.string.isRequired,
};

export default EduSyncActionMenu;
