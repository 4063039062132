import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import RemoveDomainErrorModal from 'features/settings/components/directory/domains/directory-domains-table/RemoveDomainErrorModal';
import useDomainList from 'features/settings/hooks/api/useDomainList';

const MODAL_ID = 'remove-domain-confirmation-modal';

const RemoveDomainConfirmationModal = ({domains, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);
  const {removeDomains} = useDomainList();
  const [selectedDomains] = useState(domains);

  const [errorDomains, setErrorDomains] = useState([]);

  const handleRemoveDomain = async () => {
    try {
      setIsLoading(true);

      const {failedToRemove, removed} = await removeDomains(selectedDomains);

      if (failedToRemove.length > 0) {
        setErrorDomains(failedToRemove);
      }

      if (removed.length > 0) {
        showSuccessToast(
          intl.formatMessage(
            {
              id: 'settings.directoryDomains.removeDomainConfirmationModal.toast.success',
            },
            {
              count: removed.length,
              domainName: removed[0].domainName,
            }
          )
        );

        onConfirm();
      }

      // only close the modal if we have successfully removed all domains
      if (failedToRemove.length === 0) {
        dialog.dismiss();
      }
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.directoryDomains.removeDomainConfirmationModal.toast.error',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (errorDomains.length > 0) {
    return (
      <RemoveDomainErrorModal
        selectedDomainsCount={selectedDomains.length}
        unremovableDomains={errorDomains}
      />
    );
  }

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.directoryDomains.removeDomainConfirmationModal.cancel',
      })}
      id={MODAL_ID}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRemoveDomain}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.directoryDomains.removeDomainConfirmationModal.confirm',
      })}
      title={intl.formatMessage({
        id: 'settings.directoryDomains.removeDomainConfirmationModal.title',
      })}
      variant="destructive"
    >
      <Text>
        <FormattedMessage
          id="settings.directoryDomains.removeDomainConfirmationModal.description"
          values={{
            b:
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
              // istanbul ignore next -- not testing this
              (chunks) => <strong>{chunks}</strong>,
            count: selectedDomains.length,
            domainName: selectedDomains[0].domainName,
          }}
        />
      </Text>
    </ModalAlertDialog>
  );
};

RemoveDomainConfirmationModal.displayName = 'RemoveDomainConfirmationModal';

RemoveDomainConfirmationModal.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The name of the directory
       */
      directoryName: PropTypes.string,
      /**
       * The type of the directory
       */
      directoryType: PropTypes.string,
      /**
       * The name of the domain
       */
      domainName: PropTypes.string,
    })
  ).isRequired,
  onConfirm: PropTypes.func,
};

export default RemoveDomainConfirmationModal;
