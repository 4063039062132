import {Text, View} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import ProviderV2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

import AzureLogo from 'features/settings/assets/azure-logo.svg';
import EduLogo from 'features/settings/assets/edu-logo.svg';
import GenericLogo from 'features/settings/assets/generic-logo.svg';
import GoogleLogo from 'features/settings/assets/google-logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import {canAddEdu} from 'features/settings/common/utils/eduUtils';
import SelectIdpCard from 'features/settings/components/directories/create-directory-modal/select-idp-section/SelectIdpCard';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';

const IDP_CARDS = {
  AZURE: {
    description: 'settings.directories.selectIdpSection.azure.description',
    goUrlLabel: 'settings.directories.selectIdpSection.learnMoreAboutAzure',
    id: 'azure-card',
    logo: AzureLogo,
    title: 'settings.directories.selectIdpSection.azure.title',
    type: IDP_TYPES.AZURE,
  },
  GOOGLE: {
    description: 'settings.directories.selectIdpSection.google.description2',
    goUrlLabel: 'settings.directories.selectIdpSection.learnMoreAboutGoogle',
    id: 'google-card',
    logo: GoogleLogo,
    title: 'settings.directories.selectIdpSection.google.title',
    type: IDP_TYPES.SAML,
  },
  SAML: {
    description: 'settings.directories.selectIdpSection.saml.description',
    goUrlLabel: 'settings.directories.selectIdpSection.learnMoreAboutSaml',
    id: 'saml-card',
    logo: GenericLogo,
    title: 'settings.directories.selectIdpSection.saml.title',
    type: IDP_TYPES.SAML,
  },
};

const EDU_IDP = {
  EDU: {
    description: 'settings.directories.selectIdpSection.edu.description',
    goUrlLabel: 'settings.directories.selectIdpSection.learnMoreAboutSoidc',
    id: 'edu-card',
    logo: EduLogo,
    title: 'settings.directories.selectIdpSection.edu.title',
    type: IDP_TYPES.SOIDC,
  },
};

const SelectIdpSection = ({hasDefaultIdp, onSelectIdp}) => {
  const AVAILABLE_IDP_CARDS = useMemo(() => {
    let availableCards = IDP_CARDS;

    // Only allow adding SOIDC type IdPs if the market segment is GOV or COM,
    // or `force_edu_idps` flag is on and if there is a default IdP
    if (canAddEdu() && hasDefaultIdp) {
      availableCards = {...IDP_CARDS, ...EDU_IDP};
    }

    return availableCards;
  }, [hasDefaultIdp]);

  return (
    <>
      <Text>
        <FormattedMessage id="settings.directories.selectIdpSection.description" />
      </Text>

      <ProviderV2>
        <OptionSelectorWrapper>
          <OptionSelector
            data={Object.values(AVAILABLE_IDP_CARDS)}
            options={{
              on_selection_change: (event) =>
                event.selected &&
                onSelectIdp?.({
                  isGoogleSelected: event.data.id === IDP_CARDS.GOOGLE.id,
                  isSoidcSelected: event.data.id === EDU_IDP.EDU.id,
                  type: event.data.type,
                }),
              preventDeselection: true,
            }}
            react_function={(props) => (
              <View>
                <SelectIdpCard {...props} />
              </View>
            )}
          />
        </OptionSelectorWrapper>
      </ProviderV2>
    </>
  );
};

SelectIdpSection.propTypes = {
  hasDefaultIdp: PropTypes.bool,
  onSelectIdp: PropTypes.func,
};

export default SelectIdpSection;
export {IDP_CARDS};
