(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:cancellationOrderToLaunchTranslator
   * @description Translate CancellationOrderDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('cancellationOrderToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, cancellationOrderDescriptor) {
      const {setProperty} = launchContext;

      setProperty(
        'transaction.attributes.cancel.orderNumber',
        _.get(cancellationOrderDescriptor, 'orderNumber')
      );

      const cancellationReasons = _.get(cancellationOrderDescriptor, 'reasonCodes');
      if (_.isArray(cancellationReasons) && !_.isEmpty(cancellationReasons)) {
        setProperty('transaction.attributes.cancel.reasonID', cancellationReasons.join('|'));
      }

      setProperty(
        'transaction.attributes.cancel.userReason',
        _.get(cancellationOrderDescriptor, 'userReason')
      );

      const retention = _.get(cancellationOrderDescriptor, 'retention');
      if (!_.isUndefined(retention)) {
        setProperty('retention.total.tax', _.get(retention, 'billingTotalTax'));
        setProperty('retention.total.priceWithoutTax', _.get(retention, 'billingTotalWithoutTax'));
        setProperty('retention.total.priceWithTax', _.get(retention, 'billingTotalWithTax'));
        setProperty('retention.total.discountTax', _.get(retention, 'discountTotalTax'));
        setProperty(
          'retention.total.discountWithoutTax',
          _.get(retention, 'discountTotalWithoutTax')
        );
        setProperty('retention.total.discountWithTax', _.get(retention, 'discountTotalWithTax'));
        setProperty('retention.offerId', _.get(retention, 'offerId'));
        setProperty('retention.orderNumber', _.get(retention, 'orderNumber'));
        setProperty('retention.promoId', _.get(retention, 'promoId'));

        const outcomes = _.get(retention, 'outcomes');
        if (!_.isEmpty(outcomes)) {
          const getPropertyString = (data, outcome, propertyName) =>
            `${data[propertyName] ? `${data[propertyName]}|` : ''}${
              outcome[propertyName] ? outcome[propertyName] : ''
            }`;

          const outcomeValues = _.reduce(
            outcomes,
            (data, outcome) => {
              data.discountAmount = getPropertyString(data, outcome, 'discountAmount');
              data.discountCurrency = getPropertyString(data, outcome, 'discountCurrency');
              data.durationAmount = getPropertyString(data, outcome, 'durationAmount');
              data.durationType = getPropertyString(data, outcome, 'durationType');
              data.durationUnit = getPropertyString(data, outcome, 'durationUnit');
              data.type = getPropertyString(data, outcome, 'type');

              return data;
            },
            {}
          );

          setProperty('retention.discountAmount', _.get(outcomeValues, 'discountAmount'));
          setProperty('retention.discountCurrency', _.get(outcomeValues, 'discountCurrency'));
          setProperty('retention.durationAmount', _.get(outcomeValues, 'durationAmount'));
          setProperty('retention.durationType', _.get(outcomeValues, 'durationType'));
          setProperty('retention.durationUnit', _.get(outcomeValues, 'durationUnit'));
          setProperty('retention.type', _.get(outcomeValues, 'type'));
        }
      }
    }
  }
})();
