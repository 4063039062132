const CERTIFICATE_TYPES = {
  ADOBE_SIGNED: 'ADOBE_SIGNED',
  // this status is used by the application but is not returned by the API.
  // There is a plan to have it returned by the API in the future, but the value might change.
  CA_SIGNED: 'CA_SIGNED',
  CERTIFICATE_SIGNING_REQUEST: 'CERTIFICATE_SIGNING_REQUEST',
};

const SAML_CERTIFICATE_STATUS = {
  ACTIVE: 'ACTIVE',
  // this status is used by the application but will never be returned by the API
  DEFAULT: 'DEFAULT',
  // this status is used by the application but will never be returned by the API
  DEFAULT_AND_EXPIRED: 'DEFAULT_AND_EXPIRED',
  DISABLED: 'DISABLED',
  // this status is used by the application but will never be returned by the API
  EXPIRED: 'EXPIRED',
  // this status is used by the application but will never be returned by the API
  EXPIRING: 'EXPIRING',
  UNKNOWN: 'UNKNOWN',
};

const DAYS_UNTIL_EXPIRATION = 90;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

// 90 days until expiration * day in milliseconds
const TIME_UNTIL_EXPIRATION = DAYS_UNTIL_EXPIRATION * DAY_IN_MILLISECONDS;

export {CERTIFICATE_TYPES, SAML_CERTIFICATE_STATUS, DAYS_UNTIL_EXPIRATION, TIME_UNTIL_EXPIRATION};
