(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.api-integration')
    .constant('API_INTEGRATION_MODEL', {
      API_INTEGRATION_CACHE_ID: 'ApiIntegrationCacheId',
    })
    .constant('API_INTEGRATION_LIST_MODEL', {
      API_INTEGRATION_LIST_CACHE_ID: 'ApiIntegrationListCacheId',
    });
})();
