import help from '../../constants/help';

const {TOPICS} = help;

/**
 * @description Method to return configuration object for the admin console
 *   help topic
 * @returns content for admin console help topic
 */
const adminConsole = () => ({
  content: [],
  id: TOPICS.ADMIN_CONSOLE,
  order: 0,
  parents: [
    {
      id: TOPICS.GET_STARTED,
    },
  ],
  title: 'help.tab.adminConsole.title',
});

export default adminConsole;
