import {Button, ButtonGroup, Text} from '@adobe/react-spectrum';
import {TableActions, useTableSectionContext} from '@pandora/react-table-section';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import EmailIcon from '@spectrum-icons/workflow/Email';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {groupInvoicesByExternalContractIdAndType} from '../../BillingHistoryUtils';

import styles from './BillingHistoryTable.pcss';

/**
 * Billing History Table Actions - Buttons for downloading and emailing invoices from above the Billing History Table
 */
const BillingHistoryTableSectionActions = ({
  isDisabled,
  isDownloading,
  onDownloadInvoices,
  onOpenEmailInvoices,
}) => {
  const intl = useIntl();

  const {tableSectionUtils, tableSectionState} = useTableSectionContext();

  const onOpenEmailInvoicesPress = () => {
    const invoices = groupInvoicesByExternalContractIdAndType(tableSectionState.selectedItemKeys);
    onOpenEmailInvoices({invoices});
  };

  const onDownloadInvoicesPress = () => {
    const invoices = groupInvoicesByExternalContractIdAndType(tableSectionState.selectedItemKeys);
    onDownloadInvoices({invoices});
  };

  const isNoneSelected = tableSectionUtils.getSelectedItemCount() < 1;

  return (
    <TableActions>
      <ButtonGroup isDisabled={isDisabled || isNoneSelected}>
        <Button
          data-testid="download-invoices-button"
          isDisabled={isDownloading || isNoneSelected}
          onPress={onDownloadInvoicesPress}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Matching Hover cursor to the rest of Admin Console
          UNSAFE_className={styles['billing-history-table-button']}
          variant="primary"
        >
          <DownloadIcon />
          <Text>{intl.formatMessage({id: 'account.billingHistory.downloadInvoices'})}</Text>
        </Button>
        <Button
          data-testid="open-email-modal-button"
          onPress={onOpenEmailInvoicesPress}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Matching Hover cursor to the rest of Admin Console
          UNSAFE_className={styles['billing-history-table-button']}
          variant="primary"
        >
          <EmailIcon />
          <Text>{intl.formatMessage({id: 'account.billingHistory.emailInvoices'})}</Text>
        </Button>
      </ButtonGroup>
    </TableActions>
  );
};

BillingHistoryTableSectionActions.displayName = 'BillingHistoryTableSectionActions';
BillingHistoryTableSectionActions.propTypes = {
  /**
   * Whether the action buttons in the TableActions are all disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * Whether downloading invoices is in progress or not.
   */
  isDownloading: PropTypes.bool,
  /**
   * Handler to download the invoices
   */
  onDownloadInvoices: PropTypes.func.isRequired,
  /**
   * Handler that opens the Email Invoices modal.
   */
  onOpenEmailInvoices: PropTypes.func.isRequired,
};

export default BillingHistoryTableSectionActions;
