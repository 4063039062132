(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name directorySync
   * @description defines service to manage Directory Sync service calls
   * @see https://aadsync-stage.services.adobe.com/docs
   * @see https://aadsync-stage.services.adobe.com/swagger-ui.html
   */
  angular.module('app.core.api.directory-sync').provider('directorySync', directorySyncProvider);

  let url;

  const directorySyncConfig = {};

  /* @ngInject */
  function directorySyncProvider(_) {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v1`;

      _.assign(directorySyncConfig, {
        azureAdminConsentUrl: config.azureAdminConsentUrl,
        azureClientId: config.azureClientId,
        azureConsentUrl: config.azureConsentUrl,
        azurePortalUrl: config.azurePortalUrl,
        googleClientId: config.googleClientId,
        googleConsentUrl: config.googleConsentUrl,
        googlePortalUrl: config.googlePortalUrl,
        googleScopes: config.googleScopes,
        scimUrl: config.scimUrl,
      });
    }

    // Store CSRF token for later retrieval.
    let csrfToken;

    /* @ngInject */
    function $get($resource, $state, $window, apiUtils, feature, PARTNER_TYPES) {
      const service = {
        azureOrgInfo,
        domains,
        generateNewCsrfToken,
        getAzureAdminConsentUrl,
        getAzureClientId,
        getAzureConsentUrl,
        getAzurePortalUrl,
        getCsrfToken,
        getGoogleConsentUrl,
        getGooglePortalUrl,
        getScimUrl,
        googleOrgInfo,
        groups: getGroups(),
        linkDomains,
        linkedOrgs: getLinkedOrgs(),
        linkGroups: getLinkGroups(),
        linkOrg,
        links,
        scimToken: getScimToken(),
        summary: getSummary(),
        syncEngine: getSyncEngine(),
        syncStatus: getSyncStatus(),
        toggleSync: getToggleSync(),
        unlinkDeprecatedSync,
        unlinkGroups: getUnlinkGroups(),
        unlinkOrg: getUnlinkOrg(),
      };

      return service;

      ///////////

      function getAzurePortalUrl() {
        return directorySyncConfig.azurePortalUrl;
      }

      function getGooglePortalUrl() {
        return directorySyncConfig.googlePortalUrl;
      }

      function getScimUrl() {
        return directorySyncConfig.scimUrl;
      }

      function getAzureConsentUrl(returnState) {
        const stateName =
          feature.isEnabled('temp_idp_migration') && returnState
            ? returnState
            : 'settings.identity.directories';

        // Get state URL without query params or hash.
        const currentUrl = _.replace($state.href(stateName, null, {absolute: true}), /[#?].+/, '');

        const encodedRedirectUri = $window.encodeURIComponent(currentUrl);
        const encodedClientId = $window.encodeURIComponent(directorySyncConfig.azureClientId);
        const encodedCsrfToken = $window.encodeURIComponent(getCsrfToken());
        return `${directorySyncConfig.azureConsentUrl}&client_id=${encodedClientId}&redirect_uri=${encodedRedirectUri}&state=${encodedCsrfToken}`;
      }

      function getAzureAdminConsentUrl() {
        return directorySyncConfig.azureAdminConsentUrl;
      }

      function getAzureClientId() {
        return directorySyncConfig.azureClientId;
      }

      function getGoogleConsentUrl() {
        const currentUrl = `${$window.location.protocol}//${$window.location.hostname}`;
        const encodedRedirectUri = $window.encodeURIComponent(currentUrl);
        const encodedClientId = $window.encodeURIComponent(directorySyncConfig.googleClientId);
        const encodedCsrfToken = $window.encodeURIComponent(getCsrfToken());
        const encodedScopes = $window.encodeURIComponent(
          directorySyncConfig.googleScopes.join(' ')
        );

        return `${directorySyncConfig.googleConsentUrl}&scope=${encodedScopes}&client_id=${encodedClientId}&redirect_uri=${encodedRedirectUri}&state=${encodedCsrfToken}`;
      }

      function getRandomNumber() {
        // Generate a pseudo-random number of 10 digits.
        return _.random(1000000000, 9999999999);
      }

      function generateNewCsrfToken() {
        csrfToken = getRandomNumber() + apiUtils.getActiveOrg();
        return csrfToken;
      }

      function getCsrfToken() {
        return csrfToken;
      }

      function azureOrgInfo(azureToken) {
        return $resource(`${url}/organizations/azureapplication/info`, null, {
          get: {
            headers: {
              'X-Azure-Authorization': azureToken,
            },
          },
        });
      }

      function googleOrgInfo(googleToken) {
        return $resource(`${url}/organizations/GSuite/info`, null, {
          get: {
            headers: {
              'X-GSuite-Authorization': googleToken,
            },
          },
        });
      }

      function getLinkedOrgs() {
        return $resource(`${url}/organizations/:imsOrgId/links`, {
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function linkOrg(azureToken) {
        return $resource(
          `${url}/organizations/:imsOrgId/link`,
          {
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              headers: {
                'X-Azure-Authorization': azureToken,
              },
              method: 'POST',
            },
          }
        );
      }

      function links(partnerType, token) {
        return $resource(
          `${url}/organizations/:imsOrgId/links`,
          {
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              headers: getAuthorizationHeader(partnerType, token),
              method: 'POST',
            },
          }
        );
      }

      function unlinkDeprecatedSync() {
        return $resource(
          // We want to remove the externallyManaged policy only for the unlinks that
          // imply deprecated Azure Sync removal, keeping the non-parametrized url for
          // empty directory link deletion
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode?removeExternallyManagedPolicy=true`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            unlink: {method: 'DELETE'},
          }
        );
      }

      function getUnlinkOrg() {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            unlink: {method: 'DELETE'},
          }
        );
      }

      function domains(partnerType, token) {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/domains/:domain`,
          {
            authSrcCode: '@authSrcCode',
            domain: '@domain',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            link: {
              headers: getAuthorizationHeader(partnerType, token),
              method: 'POST',
            },
            query: {
              headers: getAuthorizationHeader(partnerType, token),
              isArray: true,
              method: 'GET',
            },
            unlink: {
              method: 'DELETE',
            },
          }
        );
      }

      function linkDomains(partnerType, token) {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/domains/link`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              headers: getAuthorizationHeader(partnerType, token),
              method: 'POST',
            },
          }
        );
      }

      function getGroups() {
        return $resource(`${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/groups`, {
          authSrcCode: '@authSrcCode',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function getLinkGroups() {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/groups/link`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }

      function getUnlinkGroups() {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/groups/unlink`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }

      function getSummary() {
        return $resource(`${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/summary`, {
          authSrcCode: '@authSrcCode',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function getSyncStatus() {
        return $resource(`${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/sync/status`, {
          authSrcCode: '@authSrcCode',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function getSyncEngine() {
        return $resource(
          `${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/sync`,
          {
            authSrcCode: '@authSrcCode',
            imsOrgId: apiUtils.getActiveOrg,
          },
          {
            start: {
              method: 'POST',
            },
          }
        );
      }

      function getToggleSync() {
        return $resource(`${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/sync/enabled`, {
          authSrcCode: '@authSrcCode',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      function getScimToken() {
        return $resource(`${url}/organizations/:imsOrgId/authSrcs/:authSrcCode/scimToken`, {
          authSrcCode: '@authSrcCode',
          imsOrgId: apiUtils.getActiveOrg,
        });
      }

      ///////////

      function getAuthorizationHeader(partnerType, token) {
        let authorizationHeader;

        if (partnerType === PARTNER_TYPES.AZURE) {
          authorizationHeader = 'X-Azure-Authorization';
        }

        if (partnerType === PARTNER_TYPES.GSUITE) {
          authorizationHeader = 'X-GSuite-Authorization';
        }

        return token ? {[authorizationHeader]: token} : {};
      }
    }
  }
})();
