import {Store, log} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {getAdobeUpdatesResource} from '../api/tronCacheableApi';
import PackagesAdobeUpdateEntity from '../entities/PackagesAdobeUpdateEntity';

class PackagesAdobeUpdatesStore extends Store {
  adobeUpdatesMap = undefined;
  loggedMissingUpdateKeys = new Set();
  constructor() {
    super();
    makeObservable(this, {
      adobeUpdatesMap: observable,
      fetchAndProcessAdobeUpdates: action.bound,
      getAdobeUpdateByRefId: computed,
    });
  }

  /**
   * @description - Method to fetch and process all Adobe Updates
   *
   * @returns {Promise} - returns promise
   */
  async fetchAndProcessAdobeUpdates() {
    let response;
    if (this.adobeUpdatesMap === undefined) {
      try {
        response = await getAdobeUpdatesResource();
      } catch (error) {
        log.error(
          'PackagesAdobeUpdates.fetchAndProcessAdobeUpdates(): Unable to retrieve model from back-end. Error: ',
          error
        );
        return Promise.reject(error);
      }
      const allKeys = {};
      response.data.forEach((update) => {
        const adobeUpdateObject = new PackagesAdobeUpdateEntity(update);
        const referenceId = adobeUpdateObject.refId;
        allKeys[referenceId] = adobeUpdateObject;
      });

      runInAction(() => {
        this.adobeUpdatesMap = allKeys;
      });
    }

    return response;
  }

  /**
   * @description - Method to get Adobe Update by its refId
   *
   * @param {String} refId - refId for adobeUpdate
   * @returns {PackagesAdobeUpdateEntity} - Reference to corresponding AdobeUpdate object if found, else false.
   */
  get getAdobeUpdateByRefId() {
    return (refId) => {
      if (this.adobeUpdatesMap?.[refId]) {
        return this.adobeUpdatesMap[refId];
      }
      if (!this.loggedMissingUpdateKeys.has(refId)) {
        this.loggedMissingUpdateKeys.add(refId);

        log.error('PackagesAdobeUpdates.getAdobeUpdateByRefId(): Data not found for: ', refId);
      }
      return false;
    };
  }
}

export default PackagesAdobeUpdatesStore;
