(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name AuthSource constants
   * @description defines constants related to AuthSource service.
   */
  angular.module('app.core.ims-federated').constant('AUTH_SOURCE_ERROR_CODES', {
    IDP_ALREADY_EXISTS: 'idp_already_exists',
  });
})();
