import {Flex, Heading, SearchField, View, VisuallyHidden} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import RailRightOpenIcon from '@spectrum-icons/workflow/RailRightOpen';
import SearchIcon from '@spectrum-icons/workflow/Search';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {focusSelectedAppsSearchButton} from '../productSelectionPageUtils';

// SelectedProductsHeader is the header section for selected products, controls
// the products appearing in selected products sections
const SelectedProductsHeader = ({
  fiteredSelectedProductsLength,
  isRemoveAllDisabled,
  removeAllProducts,
  selectedProductsSearchFieldRef,
  selectedProductsSearchText,
  setSelectedProductsSearchText,
}) => {
  const intl = useIntl();

  const [showSelectedProductSearchBar, setShowSelectedProductSearchBar] = useState(
    !!selectedProductsSearchText
  );
  // Indicates if search open button should have autoFocus, initiating with false
  const [searchOpenButtonAutoFocus, setSearchOpenButtonAutoFocus] = useState(false);

  return (
    <Flex direction="column">
      <View>
        <Heading level={3} marginTop="size-0">
          {intl.formatMessage(
            {
              id: 'packages.createPackage.productSelection.selectedApps.title',
            },
            {productsLength: fiteredSelectedProductsLength}
          )}
        </Heading>
        <VisuallyHidden>
          <div role="status">
            {intl.formatMessage(
              {
                id: 'packages.createPackage.productSelection.selectedApps.status',
              },
              {productsLength: fiteredSelectedProductsLength}
            )}
          </div>
        </VisuallyHidden>
        {intl.formatMessage({
          id: 'packages.createPackage.chooseApps.selectedApps.desc',
        })}
      </View>
      {(!!selectedProductsSearchText || showSelectedProductSearchBar) && (
        <Flex height="size-800" justifyContent="space-between">
          <SearchField
            ref={selectedProductsSearchFieldRef}
            autoFocus
            label={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.searchPlaceholder',
            })}
            marginTop="size-100"
            onChange={setSelectedProductsSearchText}
            onClear={() => setSelectedProductsSearchText('')}
            value={selectedProductsSearchText}
          />
          <TooltipButton
            alignSelf="end"
            aria-label={intl.formatMessage({
              id: 'packages.createPackage.productSelection.selectedApps.cancelSearch',
            })}
            data-testid="search-close-button"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.productSelection.selectedApps.cancelSearch',
            })}
            onPress={() => {
              // Once search cancelled, focus should shift to search open button, so setting search button's autoFocus to true
              setSearchOpenButtonAutoFocus(true);
              setShowSelectedProductSearchBar(false);
              setSelectedProductsSearchText('');
            }}
            variant="action"
          >
            <RailRightOpenIcon />
          </TooltipButton>
        </Flex>
      )}
      {!selectedProductsSearchText && !showSelectedProductSearchBar && (
        <Flex alignItems="end" height="size-800" justifyContent="space-between">
          <TooltipButton
            aria-label={intl.formatMessage({
              id: 'packages.createPackage.productSelection.selectedApps.searchForSelectedApps',
            })}
            autoFocus={searchOpenButtonAutoFocus}
            data-testid="search-open-button"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.productSelection.selectedApps.searchSelectedApps',
            })}
            id="selected-apps-search-open-button"
            onPress={() => {
              setShowSelectedProductSearchBar(true);
            }}
            variant="action"
          >
            <SearchIcon />
          </TooltipButton>
          <TooltipButton
            data-testid="remove-all-button"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.selectedApps.removeAll',
            })}
            isDisabled={isRemoveAllDisabled()}
            onPress={() => {
              // Shifting focus to search button before disabling,
              // else focus ring stays on remove all button on pressing (remove all button gets disabled on pressing)
              focusSelectedAppsSearchButton();
              removeAllProducts();
            }}
            variant="action"
          >
            {intl.formatMessage({
              id: 'packages.createPackage.chooseApps.selectedApps.removeAll',
            })}
          </TooltipButton>
        </Flex>
      )}
    </Flex>
  );
};

SelectedProductsHeader.propTypes = {
  /**
   * fitered selected products length
   */
  fiteredSelectedProductsLength: PropTypes.number.isRequired,
  /**
   * function to check remove all button has been disabled
   */
  isRemoveAllDisabled: PropTypes.func.isRequired,
  /**
   * To remove all products from selected products section
   */
  removeAllProducts: PropTypes.func.isRequired,
  /**
   * Reference to selected products search field
   */
  selectedProductsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * search text in selected products section search field
   */
  selectedProductsSearchText: PropTypes.string.isRequired,
  /**
   * function to set search text in selected products section search field
   */
  setSelectedProductsSearchText: PropTypes.func.isRequired,
};
export default SelectedProductsHeader;
