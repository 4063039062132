import {
  PAGE_ALIGNMENT,
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  Subpage,
} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';

/** A wrapper for any Account page which renders the skeleton for the page */
const AccountPageWrapper = ({
  title = '',
  children,
  breadcrumbs,
  headerActionElement,
  isLoading = false,
  pageTestId,
  bumperCtaButtonLabel,
  bumperStrings,
}) => (
  <Page
    bumperCtaButtonLabel={bumperCtaButtonLabel}
    bumperStrings={bumperStrings}
    data-testid={pageTestId}
  >
    {breadcrumbs}
    <PageHeader title={title} />
    <PageBanners>
      <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_ACCOUNT} />
    </PageBanners>
    {headerActionElement && (
      <PageActions align={PAGE_ALIGNMENT.END}>{headerActionElement}</PageActions>
    )}

    <PageContent>
      <Subpage isLoading={isLoading}>{children}</Subpage>
    </PageContent>
  </Page>
);

AccountPageWrapper.propTypes = {
  /**
   * Elements to render in the `Page` beneath the `PageHeader`
   */
  breadcrumbs: PropTypes.node,
  /**
   * Translated text of the Bumper's CTA button label. Defaults to `Get Help`.
   */
  bumperCtaButtonLabel: PropTypes.string,
  /**
   * BumperStrings passed to the Page if the page is bumpered.
   */
  bumperStrings: PropTypes.shape({
    details: PropTypes.string,
    title: PropTypes.string,
  }),
  /**
   * The children elements to render within the `Subpage`
   */
  children: PropTypes.node.isRequired,
  /**
   * One or more CTAs to be shown inline with the page title.
   * This will be aligned on the right side of the title.
   */
  headerActionElement: PropTypes.node,
  /**
   * Indicates whether the `Subpage` is loading.  Defaults to false
   */
  isLoading: PropTypes.bool,
  /**
   * A data-testid which will be used at the page level i.e. top container.
   */
  pageTestId: PropTypes.string,
  /**
   * The title for the `PageHeader`. Defaults to ''.
   */
  title: PropTypes.string,
};

export default AccountPageWrapper;
