(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.main').config(configApp);

  /* @ngInject */
  function configApp(
    $animateProvider,
    $compileProvider,
    $qProvider,
    analyticsReadyProvider,
    apiReadyProvider,
    appReadyProvider,
    authenticationReadyProvider,
    chatReadyProvider,
    configurationReadyProvider,
    extendedUserProfileReadyProvider,
    featureReadyProvider,
    fraudMitigationReadyProvider,
    gainsightReadyProvider,
    localeReadyProvider,
    navReadyProvider,
    observabilityReadyProvider,
    src2ReadyProvider,
    termsRedirectReadyProvider
  ) {
    // Disable error message from Angular 1.6
    // http://www.codelord.net/2017/08/20/angular-1-dot-6-s-possibly-unhandled-rejection-errors/
    $qProvider.errorOnUnhandledRejections(false);
    // https://docs.angularjs.org/guide/migration#migrate1.5to1.6-ng-services-$compile
    $compileProvider.preAssignBindingsEnabled(true);
    // We allow HTTPS, HTTP, BLOB, and email links
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|blob|mailto):/);
    appReadyProvider.configure(
      analyticsReadyProvider,
      apiReadyProvider,
      authenticationReadyProvider,
      chatReadyProvider,
      configurationReadyProvider,
      extendedUserProfileReadyProvider,
      featureReadyProvider,
      fraudMitigationReadyProvider,
      gainsightReadyProvider,
      localeReadyProvider,
      navReadyProvider,
      observabilityReadyProvider,
      src2ReadyProvider,
      termsRedirectReadyProvider
    );

    // Provide a class name to be ignored by ngAnimate.
    // This is useful when swapping two or more elements in and out at the same time while preserving their transitions.
    $animateProvider.classNameFilter(/^(?:(?!disable-ng-animate).)*$/);
  }
})();
