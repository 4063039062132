(function () {
  /**
   * @deprecated Ported to Pandora-UI/administration-core
   * usage info here: https://git.corp.adobe.com/PandoraUI/administration-core/tree/packages/data-model-support-ticket
   *
   * @ngdoc service
   * @name supportAccess
   * @description defines access control logic for support operations
   */
  angular.module('app.core.support').factory('supportAccess', supportAccess);
  /* @ngInject */
  function supportAccess(
    _,
    auth,
    CLOUD,
    feature,
    OrganizationManager,
    ROLE,
    SUPPORT_TICKET_CASE_TYPE
  ) {
    const service = {
      canAccessEnterpriseSupport,
      canAccessEnterpriseSupportForCloud,
      canCreateP1Case,
      canManageTickets,
      canViewSupportContactInfo,
    };

    return service;

    //////////////

    /**
     * @description Returns true if the organization should be given the enterprise-level support
     *   experience, rather than the team experience. This controls the content of the Support
     *   Summary page.
     *
     * To be eligible for enterprise-level support, an organization must have any of the following:
     *
     *  - active enterprise contract
     *  - indirect contract with
     *    - EITHER No products, but requestedPlanType of "EVIP"
     *    - OR 1+ products with support_case_creation_allowed FI
     *  - allocation contract with 1+ products with support_case_creation_allowed FI
     * @returns {Boolean} true if the org should be given the enterprise support experience
     */
    function canAccessEnterpriseSupport() {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      const productList = OrganizationManager.getProductsForActiveOrg();
      if (contractList.hasEnterpriseContract()) {
        return true;
      }

      if (contractList.hasAllocationContract() && productList.hasSupportCaseCreationAllowed()) {
        return true;
      }

      if (contractList.hasIndirectContract()) {
        if (productList.isEmpty()) {
          const contract = contractList.getIndirectContract();
          return _.get(contract, 'requestedPlanType') === 'EVIP';
        }
        return productList.hasSupportCaseCreationAllowed();
      }
      return false;
    }

    /**
     * @description Returns true if the organization has access to support content for the
     *   specified product cloud. This controls the visibility of the cloud-specific tabs on the
     *   Support Summary page. Access is permitted if the org is eligible for enterprise-level
     *   support and has one or more products in the specified cloud.
     *
     * @param {CLOUD} cloud - the product cloud to check access for
     *
     * @returns {Boolean} true if the org should be given the enterprise support experience
     */
    function canAccessEnterpriseSupportForCloud(cloud) {
      return (
        this.canAccessEnterpriseSupport() &&
        OrganizationManager.getProductsForActiveOrg().hasProductsInCloud(cloud)
      );
    }

    /**
     * @description Returns true if fulfillable item exists in products for active org which has cloud value as EXPERIENCE.
     * @param {String} fulfillableItemCode - fulfilable item code
     *
     * @returns {Boolean} true if product fulfillableitem code and with cloud value 'EXPERIENCE' exists
     */
    function canCreateP1Case(fulfillableItemCode) {
      const fulfillableItems =
        OrganizationManager.getProductsForActiveOrg().getSupportTicketFulfillableItems({
          productFilter: {cloud: CLOUD.EXPERIENCE},
        });
      return _.some(fulfillableItems, ['code', fulfillableItemCode]);
    }

    /**
     * @description Returns true if currently logged in user can view and create support cases
     * for the active org in Admin Console
     *
     * @param {SUPPORT_TICKET_CASE_TYPE} caseType the type of case to check
     * @param {CLOUD} [cloud] the product cloud to check. Applies only if caseType is PRODUCT.
     *
     * @returns {Boolean} true if the user can view support cases for the active org
     */
    function canManageTickets({caseType, cloud} = {}) {
      const hasValidRole =
        auth.isUserOrgAdmin() ||
        auth.isUserSupportDelegate() ||
        (feature.isEnabled('temp_parkour_mm') && auth.isUserProductSupportAdmin());

      if (hasValidRole && this.canAccessEnterpriseSupport()) {
        if (caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT) {
          return canManageProductTickets(cloud);
        }

        // caseType is other than product Or caseType/cloud not specified
        return true;
      }
      return false;
    }

    function canManageProductTickets(cloud) {
      return (
        OrganizationManager.getProductsForActiveOrg().getSupportTicketFulfillableItems({
          productFilter: {cloud},
          productSupportRoleProductIds: getProductIdsForProductSupportAdmin(),
        }).length > 0
      );
    }

    /**
     * @description Determines if the current active org can view the support contract info.
     *   The support contact info is not visible for organizations that have only trial contracts.
     *
     * @returns {Boolean} true if the support contact info can be viewed
     */
    function canViewSupportContactInfo() {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return !contractList.hasOnlyTrialContracts();
    }

    /** Private Methods **/

    function getProductIdsForProductSupportAdmin() {
      if (auth.isUserProductSupportAdmin()) {
        const orgId = OrganizationManager.getActiveOrgId();

        // use getTargetsByRole in userRoleUtils from Pandora when porting to src2
        return _.reduce(
          auth.getUserProfile().roles,
          (targets, role) => {
            if (
              _.toUpper(role.named_role) === ROLE.ADMIN.PRODUCT_SUPPORT &&
              role.organization === orgId
            ) {
              targets.push(role.target);
            }
            return targets;
          },
          []
        );
      }
      return undefined;
    }
  }
})();
