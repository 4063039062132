(function () {
  /**
   * @deprecated use the AssignOrgType in src2
   *
   * @ngdoc component
   * @name app.widgets.organization-type-form:appOrganizationTypeForm
   *
   * @description The content of appOrganizationTypeForm
   * @param {Function} onFormChange The callback function to report changes in the form.
   *        It takes in an object {event}
   *        event.areRequiredFieldsFilled the boolean value to determine the state of the modal's cta button.
   *        event.hasAdditionalTerms the boolean value to tell whether or not there are additional terms that need to be accepted.
   *        event.marketSubsegments the market subsegments that are chosen.
   */
  angular.module('app.widgets.organization-type-form').component('appOrganizationTypeForm', {
    bindings: {
      onFormChange: '&',
    },
    controller,
    templateUrl:
      'app/widgets/organizations/organization-type-form/organization-type-form.component.html',
  });

  /* @ngInject */
  function controller(
    $document,
    $timeout,
    $translate,
    _,
    ORG_MARKET_SUBSEGMENT,
    OrganizationManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      handleFormChange,
      ORG_MARKET_SUBSEGMENT,
      showAdditionalTerms,
    });

    function handleFormChange() {
      resetConditionalOptions();
      const marketSubsegments = getMarketSubsegments();
      const hasAdditionalTerms = showAdditionalTerms();
      const areRequiredFieldsFilled =
        _.size(marketSubsegments) > 0 && (!hasAdditionalTerms || vm.checkboxes.additionalTerms);

      vm.onFormChange({
        event: {
          areRequiredFieldsFilled,
          hasAdditionalTerms,
          marketSubsegments,
        },
      });
    }

    function showAdditionalTerms() {
      return vm.hasEnterpriseContract && vm.checkboxes.k12;
    }

    ///////////

    function $onInit() {
      _.assign(vm, {
        checkboxes: {
          additionalTerms: false,
          higherEd: false,
          k12: false,
          nonProfit: false,
        },
        hasEnterpriseContract:
          OrganizationManager.getContractsForActiveOrg().hasEnterpriseContract(),
      });

      initCoralCheckboxLabels();
    }

    function getMarketSubsegments() {
      return _.reduce(vm.checkboxes, mapMarketSegment, []);

      function mapMarketSegment(result, value, key) {
        if (value) {
          switch (key) {
            case 'higherEd':
              result.push(ORG_MARKET_SUBSEGMENT.HIGHER_ED);
              break;
            case 'k12':
              result.push(ORG_MARKET_SUBSEGMENT.K_12);
              break;
            case 'nonProfit':
              result.push(ORG_MARKET_SUBSEGMENT.NON_PROFIT);
              break;
            default:
              break;
          }
        }
        return result;
      }
    }

    function initCoralCheckboxLabels() {
      // Coral-checkbox is not working properly on Firefox and IE. It does not translate the checkbox's label
      // with traditional approaches. Thus, manually set a label for each checkbox here.
      $timeout(() => {
        _.forEach(['k12', 'higher-ed', 'non-profit', 'additional-terms'], setLabelForCheckbox);

        function setLabelForCheckbox(checkboxName) {
          _.invoke(
            $document[0].querySelector(`.organization-type-form .${checkboxName}-checkbox`),
            'set',
            {
              label: {
                innerHTML: `${$translate.instant(
                  `widgets.organizations.organizationType.checkboxLabels.${_.camelCase(
                    checkboxName
                  )}`
                )}`,
              },
            }
          );
        }
      });
    }

    function resetConditionalOptions() {
      vm.checkboxes.additionalTerms = showAdditionalTerms() && vm.checkboxes.additionalTerms;
    }
  }
})();
