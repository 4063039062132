import {AnalyticsEvent} from '@admin-tribe/binky';
import {showError, showSuccess} from '@admin-tribe/binky-ui';
import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {useTableSectionContext} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

/**
 * The Product profile table 'Delete profiles' button.
 */
const DeleteProfilesButton = () => {
  const intl = useIntl();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();
  const {hasError, licenseGroupList, pageContext, product} = useProductProfileTableContext();
  const [isLoading, setIsLoading] = useState(false);

  const selectedItems = tableSectionState.selectedItems;
  const namespace = 'products.productProfileTable.deleteProfiles.alertDialog';

  // product will not be defined if this is a UserGroup context
  const subkey = product?.isSharedDeviceLicense() ? 'sharedDeviceLicenseContent' : 'content';

  const onDelete = async (close) => {
    setIsLoading(true);
    licenseGroupList.remove(selectedItems);

    try {
      await licenseGroupList.save({refresh: false});
      // This clears the TableSection selection before triggering
      // TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED to fetch the updated list.
      tableSectionUtils.modifyTableItems(tableSectionState.selectedItemKeys);
      showSuccess();

      AnalyticsEvent.dispatch({
        attributes: {productOffer: product?.offerId, profilesRemovedCount: selectedItems.length},
        componentMethod: 'removeProductProfiles',
        componentMethodType: 'submit',
        componentName: 'appProductProfiles',
        pageContext,
      });
    } catch (error) {
      showError();
    } finally {
      close();
      setIsLoading(false);
    }
  };

  return (
    <DialogTrigger>
      {/* Since this button has an isDisabled property we must explicitly disable it if there is an API error. */}
      <Button
        data-testid="delete-profiles-btn"
        isDisabled={selectedItems.length === 0 || hasError}
        variant="negative"
      >
        {intl.formatMessage({id: 'products.productProfileTable.buttons.deleteProfiles'})}
      </Button>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- not a computation */}
      {(close) => (
        <ModalAlertDialog
          isLoading={isLoading}
          onCancel={close}
          onClose={close}
          onPrimaryAction={() => onDelete(close)}
          onSecondaryAction={close}
          primaryActionLabel={intl.formatMessage({
            id: `${namespace}.button.deleteProfiles`,
          })}
          title={intl.formatMessage({
            id: `${namespace}.title`,
          })}
          variant="destructive"
        >
          {intl.formatMessage(
            {
              id: `${namespace}.${subkey}`,
            },
            {count: selectedItems.length}
          )}
        </ModalAlertDialog>
      )}
    </DialogTrigger>
  );
};

export default DeleteProfilesButton;
