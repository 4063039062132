import {
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import HowToDeployModalContent from './HowToDeployModalContent';

const CUSTOM_DIALOG_STYLE = {};
// There is a modal divider attached to the ModalDescription component.
// However it applies unwanted padding on top of the divider line when there is no content.
// There is a theme in AC modals where descriptions are put above the divider line.
// UX follow the standard spectrum Dialog behaviour and want the content of a description to go below the divider line.
// As a result we are manually using a divider line here.
const CUSTOM_DESCRIPTION_STYLE = {
  dividerGap: 'size-0',
};

/**
 * How To Deploy Modal dialog. It provides information on how to assign licenses to devices.
 */
const HowToDeployModal = ({isOpen, onCancel}) => {
  if (!isOpen) return null;

  return (
    <ModalContainer>
      <ModalDialog
        dataTestId="how-to-deploy-modal"
        descriptionStyle={CUSTOM_DESCRIPTION_STYLE}
        dialogStyle={CUSTOM_DIALOG_STYLE}
        onCancel={onCancel}
        size="L"
      >
        <ModalHeading level={4}>
          <FormattedMessage id="deploymentModal.title" />
        </ModalHeading>
        <ModalDescription />
        <ModalContent>
          <HowToDeployModalContent />
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  );
};

HowToDeployModal.propTypes = {
  /**
   * Whether the modal dialog is shown or hidden. By default is hidden.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Handler that is called when the user click on close button.
   */
  onCancel: PropTypes.func.isRequired,
};

export default HowToDeployModal;
