import {log} from '@admin-tribe/binky';
import {showError, showSuccess, translateString} from '@admin-tribe/binky-ui';

import rootStore from 'core/RootStore';
import {getDownloadUrl} from 'features/packages/api/tronData';

import {CREATE_PACKAGE_CONSTANTS, PRODUCT_SAPCODES} from '../packagesConstants';
import PackagesAdobeProducts from '../services/PackagesAdobeProductsService';
import PackagesAdobeProductsUi from '../services/PackagesAdobeProductsUiService';
import PackagesEntitlements from '../services/PackagesEntitlementsService';

import {
  isAcrobatOnlyTemplate,
  isCaptivateTemplate,
  isFramemakerTemplate,
  isPhotoshopElementsTemplate,
  isPremierElementsTemplate,
  isPresenterTemplate,
  isRoboHelpServerTemplate,
  isRoboHelpTemplate,
  isTechCommSuiteTemplate,
  mapPlatformToId,
  sendAnalyticsForNativePackage,
  shouldNotAddCCD,
} from './packagesAdobeTemplateEntityUtils';

class PackagesAdobeTemplateEntity {
  /**
   * @description Method to get list of native adobe products
   *
   * @param {Object} adobeTemplateJson adobeTemplateJson
   * @returns {Object} List of native adobe products
   *
   */
  static getNativeProductsList(adobeTemplateJson) {
    const sapCodeLatestVersionMap = adobeTemplateJson.sapCodeLatestVersionMap;
    let productsList = Object.keys(sapCodeLatestVersionMap).map(
      (key) => `${key}/${sapCodeLatestVersionMap[key]}`
    );

    productsList = productsList
      .filter((productKey) => !!PackagesAdobeProducts.getAdobeProductBySapCodeVersion(productKey))
      .map((productKey) => PackagesAdobeProducts.getAdobeProductBySapCodeVersion(productKey));

    // Don't show STIs on UI
    productsList = productsList
      .filter((product) => !product.isSTI && product.visible)
      .sort((product1, product2) => (product1.name > product2.name ? 1 : -1));

    const packagedProductsMap = {[adobeTemplateJson.platform]: {productList: productsList}};

    // remove KCCC if non-CC template
    if (shouldNotAddCCD(packagedProductsMap)) {
      productsList = productsList.filter((product) => product.sapCode !== PRODUCT_SAPCODES.KCCC);
    } else {
      // move Creative Cloud app on top of this list if present
      const creativeCloudApp = productsList.find(
        (product) => product.sapCode === PRODUCT_SAPCODES.KCCC
      );

      if (creativeCloudApp) {
        productsList = productsList.filter((product) => product.sapCode !== PRODUCT_SAPCODES.KCCC);
        productsList.unshift(creativeCloudApp);
      }
    }

    return productsList;
  }

  /**
   * @description Method to get map of [platform] to [array of adobe products] belonging to that platform
   *
   * @param {Object} adobeTemplateJson adobeTemplateJson
   * @returns {Object} map of [platform] to [array of adobe products]
   */
  static getPackagedAdobeProductsMap(adobeTemplateJson) {
    const packagedProductsMap = {};
    adobeTemplateJson.pids2Show.Pid2ShowList.forEach((Pid2Show) => {
      packagedProductsMap[Pid2Show.platform] = {};

      // list of apps for create package
      packagedProductsMap[Pid2Show.platform].productList = Pid2Show.productList
        .filter(
          (product) =>
            !!PackagesAdobeProducts.getAdobeProductBySapCodeVersionPlatform(product.product_key)
        )
        .map((product) =>
          PackagesAdobeProducts.getAdobeProductBySapCodeVersionPlatform(product.product_key)
        );

      // list of apps to show on UI
      // subset of previous list, excluding STI base versions and invisible apps
      packagedProductsMap[Pid2Show.platform].productListToShowOnUI = packagedProductsMap[
        Pid2Show.platform
      ].productList
        .filter((product) => !product.isSTI && product.visible)
        .sort((product1, product2) => (product1.name > product2.name ? 1 : -1));

      // move Creative Cloud app on top of this list if present
      const creativeCloudApp = packagedProductsMap[Pid2Show.platform].productListToShowOnUI.filter(
        (product) => product.sapCode === PRODUCT_SAPCODES.KCCC
      );
      packagedProductsMap[Pid2Show.platform].productListToShowOnUI = packagedProductsMap[
        Pid2Show.platform
      ].productListToShowOnUI.filter((product) => product.sapCode !== PRODUCT_SAPCODES.KCCC);

      if (creativeCloudApp.length > 0) {
        packagedProductsMap[Pid2Show.platform].productListToShowOnUI.unshift(creativeCloudApp[0]);
      }

      // add latest Creative Cloud App to this list at start if,
      // user has CC entitlement or the template is a substance template and KCCC is not already present
      if (
        PackagesEntitlements.hasCcEntitlement &&
        !packagedProductsMap[Pid2Show.platform].productListToShowOnUI.some(
          (product) => product.sapCode === PRODUCT_SAPCODES.KCCC
        )
      ) {
        if (
          PackagesAdobeProductsUi.getLatestCreativeCloudApp(Pid2Show.platform) &&
          PackagesAdobeProducts.getAdobeProductBySapCodeVersionPlatform(
            PackagesAdobeProductsUi.getLatestCreativeCloudApp(Pid2Show.platform).product_key
          )
        ) {
          packagedProductsMap[Pid2Show.platform].productListToShowOnUI.unshift(
            PackagesAdobeProducts.getAdobeProductBySapCodeVersionPlatform(
              PackagesAdobeProductsUi.getLatestCreativeCloudApp(Pid2Show.platform).product_key
            )
          );
        }
      }

      // remove KCCC if non-CC template
      if (shouldNotAddCCD(packagedProductsMap)) {
        packagedProductsMap[Pid2Show.platform].productListToShowOnUI = packagedProductsMap[
          Pid2Show.platform
        ].productListToShowOnUI.filter((product) => product.sapCode !== PRODUCT_SAPCODES.KCCC);
      }
    });

    return packagedProductsMap;
  }

  /**
   * @description Method to fetch download URL and initiate download for mac native package
   *
   * @returns {Promise} returns promise
   */
  downloadNativePackage = async () => {
    const getAnalyticsObject = (error) => ({
      error,
      name: this.name,
      nativePackageId: this.nativePackageId,
      nativePackageSHA256: this.nativePackageSHA256,
      nativePackageSize: this.nativePackageSize,
      nativeProductsList: this.nativeProductsList.map(
        (product) => `${product.sapCode}/${product.version}`
      ),
      platform: this.platform,
    });

    try {
      const response = await getDownloadUrl(this.nativePackageId, {
        getCDN: true,
        isNativePkg: true,
      });

      const url = response.data;
      if (url !== null) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- using optional chaining
        window?.open(url, '_self');
      }
      showSuccess(translateString({id: 'packages.alerts.allPackages.nativePackageDownload'}));
      sendAnalyticsForNativePackage(getAnalyticsObject());
    } catch (error) {
      sendAnalyticsForNativePackage(
        getAnalyticsObject({
          code: error.code,
          message: error.message,
        })
      );
      showError(
        translateString(
          {id: 'packages.errors.allPackages.errorDownloadingPackage'},
          {
            packageName: this.name,
          }
        )
      );
      log.error(
        'PackagesAdobeTemplateEntity.downloadNativePackage(): Unable to download. Error: ',
        error
      );
    }
  };

  /**
   * @description Method to get Packaged Apps Count depending on platform/bits
   *
   * @returns {Integer} Packaged Apps Count
   */
  getPackagedAppsCount = () => {
    if (this.nativePackageId) {
      return Object.keys(this.nativeProductsList).length;
    }
    return Object.keys(this.getPackagedAppsForUi()).length;
  };

  /**
   * @description Method to get Packaged Apps for create package workflow depending on platform/bits
   *
   * @returns {Array} Array of Packaged adobeProducts
   */
  // eslint-disable-next-line complexity -- 2 over max
  getPackagedAppsForCreatePackage = () => {
    switch (this.platform) {
      case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
        return this.packagedProductsMap.mac?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
        return this.packagedProductsMap.macarm64?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
        return this.packagedProductsMap.macuniversal?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
        return this.packagedProductsMap.winarm64?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
        return this.bits === 64
          ? this.packagedProductsMap.win64?.productList ?? []
          : this.packagedProductsMap.win32?.productList ?? [];

      default:
        return [];
    }
  };

  /**
   * @description Method to get Packaged Apps for create package workflow depending on platform/bits
   *
   * @param {Object} platform adobeTemplateJson
   * @param {Object} is32Bit adobeTemplateJson
   *
   * @returns {Array} Array of Packaged adobeProducts
   */
  // eslint-disable-next-line complexity -- 2 over max
  getPackagedAppsForCustomization = (platform, is32Bit) => {
    switch (platform) {
      case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
        return this.packagedProductsMap.mac?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
        return this.packagedProductsMap.macarm64?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
        return this.packagedProductsMap.macuniversal?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
        return this.packagedProductsMap.winarm64?.productList ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
        return is32Bit
          ? this.packagedProductsMap.win32?.productList ?? []
          : this.packagedProductsMap.win64?.productList ?? [];

      default:
        return [];
    }
  };

  /**
   * @description Method to get Packaged Apps to show on UI depending on platform/bits
   *
   * @returns {Array} Array of Packaged adobeProducts
   */
  // eslint-disable-next-line complexity -- 1 over max
  getPackagedAppsForUi = () => {
    switch (this.platform) {
      case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
        return this.packagedProductsMap.mac?.productListToShowOnUI ?? [];

      case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
        return this.packagedProductsMap.macarm64?.productListToShowOnUI ?? [];

      case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
        return this.packagedProductsMap.macuniversal?.productListToShowOnUI ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
        return this.packagedProductsMap.winarm64?.productListToShowOnUI ?? [];

      case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
        return this.bits === 64
          ? this.packagedProductsMap.win64?.productListToShowOnUI ?? []
          : this.packagedProductsMap.win32?.productListToShowOnUI ?? [];

      default:
        return [];
    }
  };

  /** Constructor for Adobe Template **/
  /**
   * @class
   * @description Creates a new AdobeTemplate for use.
   *
   * @param {Object} adobeTemplateJson Initialization adobeTemplateJson Object
   */
  constructor(adobeTemplateJson) {
    this.name = adobeTemplateJson.packageName;
    this.platform = adobeTemplateJson.platform;
    this.bits = adobeTemplateJson.bits;
    this.id = `${this.name}${this.platform}${this.bits}`;
    this.platformString = translateString({
      id: `packages.createPackage.systemConfig.platforms.${mapPlatformToId(this.platform)}`,
    });

    if (adobeTemplateJson?.npcPackageID) {
      this.nativePackageId = adobeTemplateJson.npcPackageID;
      this.nativePackageSize = adobeTemplateJson.npcPackageSize;
      this.nativePackageSHA256 = adobeTemplateJson.npcSHA256Checksum;
      this.nativeProductsList =
        PackagesAdobeTemplateEntity.getNativeProductsList(adobeTemplateJson);
      this.platformString = translateString({
        id: 'packages.createPackage.systemConfig.platforms.mac',
      });
    }

    // add all packaged products
    this.packagedProductsMap =
      PackagesAdobeTemplateEntity.getPackagedAdobeProductsMap(adobeTemplateJson);

    // set product specific template flags
    this.isAcrobatOnlyTemplate = isAcrobatOnlyTemplate(this.packagedProductsMap);
    this.isCaptivateTemplate = isCaptivateTemplate(this.packagedProductsMap);
    this.isRoboHelpServerTemplate = isRoboHelpServerTemplate(this.packagedProductsMap);
    this.isPresenterTemplate = isPresenterTemplate(this.packagedProductsMap);
    this.isPremierElementsTemplate = isPremierElementsTemplate(this.packagedProductsMap);
    this.isPhotoshopElementsTemplate = isPhotoshopElementsTemplate(this.packagedProductsMap);
    this.isTechCommSuiteTemplate = isTechCommSuiteTemplate({
      [this.platform]: {productList: this.getPackagedAppsForCreatePackage()},
    });

    this.isFramemakerTemplate = isFramemakerTemplate(this.packagedProductsMap);
    this.isRoboHelpTemplate = isRoboHelpTemplate(this.packagedProductsMap);

    this.containsMultiplePlatforms = Object.keys(this.packagedProductsMap).length > 1;
    this.shouldNotAddCCD = shouldNotAddCCD(this.packagedProductsMap);

    // set default configurations
    this.configurations = {
      accAdminPrivileged: rootStore.packagesUiConstantsStore.defaultPackageAdminPrivilegeSetting,
      accDisableAutoAppUpdate:
        rootStore.packagesUiConstantsStore.defaultPackageDisableAutoAppUpdateSetting,
      appPanelEnabled: rootStore.packagesUiConstantsStore.defaultPackageAppsPanelSetting,
      ausstFallbackEnabled: false,
      ausstOverride: false,
      betaAppDownloadEnabled: rootStore.packagesUiConstantsStore.defaultBetaAppDownloadSetting,
      enableExtenstions: rootStore.packagesUiConstantsStore.defaultExtensionsSetting,
      filesPanelEnabled: rootStore.packagesUiConstantsStore.defaultCoreSyncSetting,
      locale: rootStore.packagesUiConstantsStore.defaultPackageLocaleSetting,
      marketPanelEnabled: rootStore.packagesUiConstantsStore.defaultCoreSyncSetting,
      osLangDetection: rootStore.packagesUiConstantsStore.defaultPackageOsLanguageDetectionSetting,
      rumEnabled: rootStore.packagesUiConstantsStore.defaultPackageRumSetting,
      selfServePluginsEnabled: rootStore.packagesUiConstantsStore.defaultSelfServePluginsSetting,
      updatesEnabled:
        rootStore.packagesUiConstantsStore.defaultPackageAppsPanelSetting &&
        rootStore.packagesUiConstantsStore.defaultPackageAdminPrivilegeSetting,
      userInstallDirectory: false,
    };
  }
}

export default PackagesAdobeTemplateEntity;
