(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationIntegrationList
   * @description organization integration list model
   */
  angular
    .module('app.core.organizations.integration-list')
    .factory('OrganizationIntegrationList', getOrganizationIntegrationListFactory);

  /* @ngInject */
  function getOrganizationIntegrationListFactory(
    $q,
    $rootScope,
    _,
    ApiIntegrationList,
    ApiIntegrationUser,
    MEMBER_TECH_ACCOUNT_DOMAIN,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationUserList
  ) {
    class OrganizationIntegrationList extends OrganizationUserList {
      /**
       * @class
       * @description Constructor for OrganizationIntegrationList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.ORGANIZATIONINTEGRATIONLIST,
              memberClassRef: ApiIntegrationUser,
            },
            options
          )
        );
        this.filter.query = MEMBER_TECH_ACCOUNT_DOMAIN;
        this.filter.excludeDomain = undefined;
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @return {Promise} resolves to refreshed OrganizationIntegrationList model, else rejects with error message
       */
      refresh() {
        const deferred = $q.defer();
        this.$resolved = false;
        this.$promise = deferred.promise;
        super
          .refresh({delegatePromise: true})
          .then(() => ApiIntegrationList.get().$promise)
          .then((integrations) => {
            // join integration data with user data
            _.forEach(this.items, (item) => item.addIntegration(integrations));
            this.$resolved = true;
            deferred.resolve(this);
            modelCache.put(this.cacheType, this, this.key());
            $rootScope.$emit(MESSAGE.UPDATE.ORGANIZATIONINTEGRATIONS.LIST);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(
                MESSAGE.UPDATE.ORGANIZATIONINTEGRATIONS.COUNT,
                this.pagination.itemCount
              );
            }
          })
          .catch((error) => {
            this.$resolved = true;
            deferred.reject(error);
          });
        return deferred.promise;
      }

      /**
       * @description Method to determine whether we need to update the total itemCount of a list.
       *
       * @return {Boolean} true if the total itemCount needs to be updated
       */
      shouldUpdateTotalItemCount() {
        return _.has(this, 'pagination.itemCount');
      }

      /**
       * @description Method to retrieve an existing OrganizationIntegrationList from the back-end.
       *
       * @param {Object} [options] options for JIL API call
       * @param {Number} [options.pageSize] number of organization integrations to display per page
       *
       * @returns {OrganizationIntegrationList} new OrganizationIntegrationList Object reference
       */
      static get(options = {}) {
        let model = new OrganizationIntegrationList(options);
        const cachedModel = modelCache.get(MODEL.ORGANIZATIONINTEGRATIONLIST, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    MemberList.setupCache(MODEL.ORGANIZATIONINTEGRATIONLIST, 10);

    return OrganizationIntegrationList;
  }
})();
