(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.widgets.directories:azureGroupEntity
   * @description entity that represents an Azure group.
   */

  angular.module('app.core.directories.azure').factory('AzureGroup', azureGroup);

  function azureGroup(_, AZURE_GROUP_LINK_STATUS) {
    class AzureGroup {
      // An Azure group as returned by the API looks like this:
      // {
      //   "entity": {
      //     "description": "string",
      //     "groupName": "string",
      //     "id": "string",
      //     "source": "string",
      //     "type": "USER"
      //   },
      //   "id": "string",
      //   "status": "LINKED",
      //   "type": "USER"
      // }
      constructor(data = {}) {
        this.id = data.id;
        this.name = _.get(data, 'entity.groupName');

        this.linkStatus = data.status;
        this.isFree = this.linkStatus === AZURE_GROUP_LINK_STATUS.FREE;
        this.isLinked = this.linkStatus === AZURE_GROUP_LINK_STATUS.LINKED;
        this.isInvalid = this.linkStatus === AZURE_GROUP_LINK_STATUS.INVALID;

        if (this.isInvalid) {
          this.invalidReason = data.invalidReason;
        }

        // We store this for use when calling ADSync's POST /groups/link API.
        this.entity = data.entity;
      }
    }

    return AzureGroup;
  }
})();
