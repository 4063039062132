/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.trustees:appToggleTrusteeNotificationsModal
 *
 * @description The modal is for the Owning Admin to toggle notification setting on trustee organizations
 * Set to true if owning admins should be notified when trustees add users to the directory
 */
(function () {
  angular.module('app.widgets.trustees').component('appToggleTrusteeNotificationsModal', {
    bindings: {
      trusteeList: '<', // either a TrustList or a DirectoryTrustList
    },
    controller,
    templateUrl:
      'app/widgets/trustees/toggle-trustee-notifications-modal/toggle-trustee-notifications-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    panelManager,
    TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID,
    toastManager
  ) {
    const vm = this;
    _.assign(vm, {
      onModalClose,
      onModalOpen,
      saveNotifyAdmins,
      updatedValue: false,
    });

    _.assign(vm, {
      TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID,
      waitOnModal: $q.resolve(),
    });

    /**
     * @param {Object} params - parameters object
     * @param {Trust} params.trustee - The trustee whose notifications are being toggled.
     *   This can be either a Trust or a DirectoryTrust. notifyAdmins contains the new desired value.
     * @return {undefined} no return value
     */
    function onModalOpen(params) {
      vm.updatedValue = false;
      _.assign(vm, _.pick(params, ['trustee']));
      // Set strings for toggled notify value.
      setNotificationConfirmationModalStrings(vm.trustee.notifyAdmins);
    }

    function onModalClose() {
      // If the value hasn't changed, we need to toggle it back to its original position.
      if (!vm.updatedValue) {
        vm.trustee.notifyAdmins = !vm.trustee.notifyAdmins;
      }
    }

    function saveNotifyAdmins() {
      vm.waitOnModal = vm.trusteeList
        .saveNotificationsValue(vm.trustee)
        .then(onSuccess)
        .catch(onError);

      function onError() {
        toastManager.showErrorToast();
        panelManager.close(vm.TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID);
      }

      function onSuccess() {
        vm.updatedValue = true;
        const message = $translate.instant(
          `widgets.trustees.trusteeListTable.notificationSettings.changeSuccessMessages.${
            vm.trustee.notifyAdmins ? 'on' : 'off'
          }`,
          {
            trusteeOrgName: vm.trustee.trusteeOrgName,
          }
        );
        toastManager.showSuccessToast(message);
        panelManager.close(vm.TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID);
      }
    }

    ///////////

    function setNotificationConfirmationModalStrings(newValue) {
      const baseStringPath = `widgets.trustees.trusteeListTable.notificationSettings.confirmationModal.${
        newValue ? 'turnOn' : 'turnOff'
      }`;
      vm.notificationConfirmationTitleKey = `${baseStringPath}.title`;
      vm.notificationConfirmationMessageKey = `${baseStringPath}.message`;
      vm.notificationConfirmationButtonLabelKey = `${baseStringPath}.confirmationButton`;
    }
  }
})();
