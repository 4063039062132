import {
  FULFILLABLE_ITEM_CHARGING_UNIT,
  getProductsByOfferIds,
  getTaxTerm,
} from '@admin-tribe/binky';
import {useMemo} from 'react';

import {
  getCancellationItems,
  getCancellingItemByOfferId,
  getCurrency,
  getNextBilling,
  isMultipleTaxRate,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * @description A helper hook to calculate cancellation and pricing information to render the
 * cancellation cart. Uses Products Change model provided useProductsChangeContext hook.
 * @param {Object} options - Set up options, described below
 * @param {Contract} options.contract - The contract to determine tax and recurrence terms
 * @param {Array} options.productList The list of org's products.
 * @param {Boolean} [options.filterCancellingOnly] Whether to list only the cancelling items in the cart. Defaults to include non cancelling items.
 * @returns {Object} result - Object composed of the following cancellation cart details properties
 *          {String} result.recurrenceTerm - Recurrence Term, i.e. MONTHLY
 *          {Object} result.currency - Currency object used for the prices
 *          {Object} result.items - Items object with cancellation details for a particular product
 *          {Number} result.items.cancellingCount - Number of cancelling licenses for a particular product
 *          {Number} result.items.cancellingTotal - Total amount of cancelling licenses for a particular product
 *          {Number} result.items.currentCount - Number of all current licenses count for a particular product
 *          {Number} result.items.currentTotal - Current or original billing total without tax for a particular product
 *          {Number} result.items.perUnit - The unit of the item that is being cancelled (i.e. Licenses)
 *          {Number} result.items.pricePerUnit - The price per unit/license for a particular product
 *          {Object} result.items.product - The Product with icon, name, description, described by its id.
 *          {Number} result.items.remainingCount - Number of remaining licenses after cancellation for a particular product
 *          {Number} result.items.remainingTotal - Total amount of remaining licenses after cancellation for a particular product
 *          {Object} result.total - Total object with prices details for all products after cancellation
 *          {Boolean} result.isMultiTaxRate - Indidcates if items in the cart have multiple tax rates among them
 *          {Number} result.total.taxRate - Region tax rate for the owner
 *          {String} result.total.taxTerm - Tax term, i.e. TAX
 *          {Number} result.total.totalTax - The tax amount from the cost of the all remaining licenses
 *          {Number} result.total.totalWithTax - The total amount including tax of the cost of all remaining licenses
 *          {Number} result.total.totalWithoutTax - The total amount without tax of the cost of all remaining licenses
 */
const useCancellationCart = ({contract, productList, filterCancellingOnly}) => {
  const {productsChange} = useProductsChangeContext();
  return useMemo(() => {
    const allCancellationItems = getCancellationItems(productsChange);
    const {cancelling, nonCancelling} = allCancellationItems.reduce(
      (acc, item) => {
        if (item.newQuantity < item.totalQuantity) {
          acc.cancelling.push(item);
        } else {
          acc.nonCancelling.push(item);
        }
        return acc;
      },
      {cancelling: [], nonCancelling: []}
    );
    let cancellationItems = cancelling;
    // Add not cancelling items in the cart if not filterCancellingOnly
    if (!filterCancellingOnly) {
      cancellationItems = [...cancellationItems, ...nonCancelling];
    }

    const items = cancellationItems.map((cancellationItem) => {
      const productOfferId = cancellationItem?.offerId;
      const [product] = getProductsByOfferIds(productList, [productOfferId]) || [];
      const cancellingItem = getCancellingItemByOfferId(productsChange, productOfferId);
      let cancellingCount, cancellingTotal, remainingCount, remainingTotal;
      // If item is not cancelling, it won't have `cancellingItem`
      if (cancellingItem) {
        cancellingCount = cancellingItem?.quantity;
        cancellingTotal = cancellingItem?.cancellingPrices?.totalWithoutTax;
        remainingCount = cancellationItem?.newQuantity;
        remainingTotal = cancellationItem?.newPrices?.totalWithoutTax;
      }
      const currentCount = cancellationItem?.totalQuantity;
      const currentTotal = cancellationItem?.existingPrices?.totalWithoutTax;
      const pricePerUnit = cancellationItem?.newPrices?.unitPriceWithoutTax;

      const perUnit = FULFILLABLE_ITEM_CHARGING_UNIT.LICENSE;
      return {
        cancellingCount,
        cancellingTotal,
        currentCount,
        currentTotal,
        perUnit,
        pricePerUnit,
        product,
        remainingCount,
        remainingTotal,
      };
    });

    const currency = getCurrency(productsChange);
    const recurrenceTerm = contract?.getBillingFrequency();
    // taxRate is not coming from nextBilling and should be used from the first item of cancellationItems.
    const taxRate = cancellationItems[0]?.existingPrices?.taxRate;
    const taxTerm = getTaxTerm(contract?.getOwnerCountryCode());
    const {totalWithoutTax, totalTax, totalWithTax} = getNextBilling(productsChange) || {};
    const isMultiTaxRate = isMultipleTaxRate(cancellationItems);
    const total = {
      isMultiTaxRate,
      taxRate,
      taxTerm,
      totalTax,
      totalWithoutTax,
      totalWithTax,
    };
    return {currency, items, recurrenceTerm, total};
  }, [contract, filterCancellingOnly, productList, productsChange]);
};

export default useCancellationCart;
