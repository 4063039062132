import {NavigationAnchor, TableSectionTable} from '@admin-tribe/binky-ui';
import {Link} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {getHrefForDirectoryUsers} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

// The table for the 'Directory users' page. This expects to be wrapped in a TableSection.
const DirectoryListTable = observer(() => {
  const intl = useIntl();

  const columnDescriptor = [
    {
      key: 'directoryName',
      props: {allowsSorting: true},
    },
  ];

  // The render function will be called with an object which contains the 'item' which is
  // the DirectoryUser and the 'key'.
  const renderers = {
    directoryName: getDirectoryName,
  };

  function getDirectoryName({key, item}) {
    return (
      <Link>
        <NavigationAnchor href={getHrefForDirectoryUsers({directoryId: item.id})}>
          {item[key]}
        </NavigationAnchor>
      </Link>
    );
  }

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'users.directoryListTable.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="users.directoryListTable.table.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'users.directoryListTable.table.noItemsFoundContentMessage',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'users.directoryListTable.table.noSearchResultsContentMessage',
      })}
      renderers={renderers}
    />
  );
});

DirectoryListTable.displayName = 'DirectoryListTable';

export default DirectoryListTable;
