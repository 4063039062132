import {Flex} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import {ContentContext} from '@pandora/react-content-provider';
import {HelpTopicCardList} from '@pandora/react-help-topic-cards';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

import {getHelpTopicsGoUrls} from '../../utils/HelpTopicTabsUtils';
import SupportHelpTopics from '../support-help-topics/SupportHelpTopics';

const HelpTopicList = ({cloudType, contentModel}) => {
  const intl = useIntl();
  const {locale} = useContext(ContentContext);
  const helpCardListContent = contentModel(intl, locale);
  const goUrls = getHelpTopicsGoUrls(cloudType);

  return (
    <Flex direction="column" gap="size-300" marginTop="size-300">
      <SupportHelpTopics goUrls={goUrls} />
      <HelpTopicCardList content={helpCardListContent} />
    </Flex>
  );
};

HelpTopicList.propTypes = {
  /**
   * Used to set cloud type, which is one of either the four "real" clouds defined
   * in @pandora/administration-core-types, "General" or "Team"
   */
  cloudType: PropTypes.oneOf([
    ...Object.keys(CLOUD),
    ...Object.keys(SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY),
  ]),
  /**
   * contentModel is a stand-in for an AEM content model, which contains all the
   * RichTextJson we show in the help topic cards.
   */
  contentModel: PropTypes.func.isRequired,
};

export default HelpTopicList;
