import {feature} from '@admin-tribe/binky';
import {Flex, Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import NotificationsPanel from 'features/notifications/components/panel/NotificationsPanel';
import StatusPanel from 'features/notifications/components/panel/status-panel/StatusPanel';
import StatusPanel2 from 'features/notifications/components/panel/status-panel/StatusPanel2';
import NotificationList from 'features/notifications/models/list/NotificationList';

// Notification drawer content displaying the console and status messages
const NotificationsDrawerContent = ({
  notificationDisplayCount,
  notificationList,
  onChangeNotification,
  onClick,
  statusPanelCount,
}) => {
  const intl = useIntl();
  return (
    <Tabs
      aria-label={intl.formatMessage({
        id: 'shell.notifications.tabs.ariaLabel',
      })}
      density="regular"
      isQuiet
    >
      <TabList marginX="size-100">
        <Item key="console">
          {intl.formatMessage({id: 'shell.notifications.console'}, {notificationDisplayCount})}
        </Item>
        <Item key="status">
          {intl.formatMessage({id: 'shell.notifications.status'}, {statusPanelCount})}
        </Item>
      </TabList>
      <TabPanels>
        <Item key="console">
          <Flex justifyContent="center">
            <NotificationsPanel
              notificationList={notificationList}
              onChangeNotification={onChangeNotification}
              onClickButton={onClick}
              onUpdatePagination={
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore  -- @agooden unused function
                // istanbul ignore next
                () => {}
              }
            />
          </Flex>
        </Item>
        <Item key="status">
          {feature.isEnabled('temp_change_status_api') ? <StatusPanel2 /> : <StatusPanel />}
        </Item>
      </TabPanels>
    </Tabs>
  );
};

NotificationsDrawerContent.propTypes = {
  /**
   * Unread notification count
   */
  notificationDisplayCount: PropTypes.number.isRequired,
  /**
   * List of notifications
   */
  notificationList: PropTypes.instanceOf(NotificationList).isRequired,
  /**
   * Callback function to update notification list count
   */
  onChangeNotification: PropTypes.func.isRequired,
  /**
   * Callback function when user clicks on a notification action button
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Number of Adobe status messages
   */
  statusPanelCount: PropTypes.number.isRequired,
};

export default NotificationsDrawerContent;
