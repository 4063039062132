import {useCallback, useReducer} from 'react';

import TrusteeListModel from 'features/settings/models/TrusteeListModel';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
  UPDATE_DATA: 'updateData',
};

/**
 * A reducer which handles the actions dispatch for the trusteeList
 */
const trustListStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        trustCount: action.payload.trustCount ?? state.trustCount,
      };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        trusteeListData: [...action.payload],
      };
    default:
      return state;
  }
};

/**
 * A generic hook that can be used to create a trust list state
 */
const useTrustListState = (
  {fetchFn, clearCacheFn, tableOptions, setTableOptions} = {},
  initialTrustData = null
) => {
  const [state, dispatch] = useReducer(trustListStateReducer, {
    hasLoadingError: false,
    isLoading: !initialTrustData,
    trustCount: initialTrustData?.totalCount ?? 0,
    trusteeListData: TrusteeListModel.fromObject(initialTrustData),
  });

  /**
   * Function used to fetch a TrusteeList.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadTrusteeList = useCallback(
    async (params, {updateCount = false} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const response = await fetchFn(params);

        const trustList = TrusteeListModel.fromObject(response);
        setTableOptions({...tableOptions, ...params, totalPages: trustList.totalPages});

        dispatch({
          payload: {
            trustCount: updateCount ? response.totalCount : null,
            trusteeListData: trustList,
          },
          type: ACTIONS.FINISH_DATA_LOAD,
        });

        return trustList;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [fetchFn, setTableOptions, tableOptions]
  );

  /**
   * Function used to patch the data from the state
   */
  const updateTrusteeList = useCallback((data) => {
    dispatch({payload: data, type: ACTIONS.UPDATE_DATA});
  }, []);

  /**
   * Function used to reload the list of trustees
   */
  const reloadTrusteeList = async () => {
    // clear the cache and reload the list of trustees
    clearCacheFn();

    await loadTrusteeList({}, {updateCount: true});
  };

  return {
    loadTrusteeList,
    reloadTrusteeList,
    state,
    updateTrusteeList,
  };
};

export default useTrustListState;

// exported for unit-testing
export {trustListStateReducer};
