import {InfoBar, InfoItem, InfoItemScorecard} from '@admin-tribe/binky-ui';
import React from 'react';

import {useProductProfileDetailsContext} from 'features/products/pages/product-profile-details-page/context/ProductProfileDetailsContext';

/**
 * A wrapper for Product Profile Details Page Header info bar
 */
const ProductProfileDetailsInfoBar = () => {
  const {summaryItems} = useProductProfileDetailsContext();
  return (
    <InfoBar>
      {summaryItems.map(({label, value}) => {
        const props = {key: label, label, value};
        return typeof value === 'number' ? (
          <InfoItemScorecard {...props} />
        ) : (
          <InfoItem {...props} />
        );
      })}
    </InfoBar>
  );
};

export default ProductProfileDetailsInfoBar;
