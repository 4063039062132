import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/binky';
import {Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import CreatePackageCardCheckboxGroup from '../../create-package-card-checkbox-group/CreatePackageCardCheckboxGroup';

import LicenseTypeCard from './LicenseTypeCard';
import {
  LICENSE_TYPE_OPTIONS,
  getPkgSessionObjValuesOnSelect,
  getPkgTypeForFrlMode,
  getSubType,
  showCard,
  triggerIngestAnalytics,
} from './chooseLicenseTypePageUtils';

// Screen for Create Package to choose license type in create package workflow
// License Types: 1.Named user licensing(nul) 2.Shared device licensing(sdl/nuell) 3.Feature restricted licensing(frl)
const ChooseLicenseTypePage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();

  // when pkgType frl, set packageType based on frl entitlement
  if (
    createPackageModalStore.pkgSessionSettings.packagingMode ===
    CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE
  ) {
    const pkgType = getPkgTypeForFrlMode();
    if (pkgType) {
      createPackageModalStore.setPkgSessionSettingValues('packageType', pkgType);
    }
  }

  const onPackageTypeClick = useCallback(
    (pkgType) => {
      const pkgCreateObj = rootStore.packagesUiConstantsStore.getPkgCreateObjValues(pkgType);
      Object.keys(pkgCreateObj).forEach((key) => {
        createPackageModalStore.setPackageCreateObjValues(key, pkgCreateObj[key]);
      });
      if (
        pkgType === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE &&
        rootStore.organizationStore.activeOrg.marketSegment ===
          ORGANIZATION_MARKET_SEGMENT.EDUCATION
      ) {
        createPackageModalStore.setPackageCreateObjValues('selfServePluginsEnabled', false);
      }
      const pkgSessionObj = getPkgSessionObjValuesOnSelect(pkgType);
      Object.keys(pkgSessionObj).forEach((key) => {
        createPackageModalStore.setPkgSessionSettingValues(key, pkgSessionObj[key]);
      });
      const shouldDisableAppTrial =
        pkgSessionObj.showAccInUI && feature.isEnabled('packages_disable_app_trial')
          ? false
          : undefined;
      createPackageModalStore.setPackageCreateObjValues('disableAppTrial', shouldDisableAppTrial);
      triggerIngestAnalytics(pkgType);
    },
    [createPackageModalStore]
  );

  const onChange = useCallback(
    (values) => {
      const selectedValuesLength = values.length;
      if (selectedValuesLength > 0) {
        // When another package type selected, then the package type selected is added to end of values list
        // trigger onPackageTypeClick with the newly selected package type (as only one package type can be selected at a time)
        onPackageTypeClick(values[selectedValuesLength - 1]);
      } else {
        createPackageModalStore.setPkgSessionSettingValues('packageType', null);
        createPackageModalStore.setPkgSessionSettingValues('packagingMode', null);
      }
      createPackageModalStore.setPkgSessionSettingValues(
        'nextButtonEnabled',
        !!createPackageModalStore.pkgSessionSettings.packagingMode
      );
    },
    [createPackageModalStore, onPackageTypeClick]
  );

  return (
    <Flex direction="column" gap="size-300" marginTop="size-100" maxWidth="100%">
      {intl.formatMessage({id: 'packages.createPackage.licenseType.subtitle'})}
      <CreatePackageCardCheckboxGroup
        aria-label={intl.formatMessage({
          id: 'packages.createPackage.licenseType.groupAriaLabel',
        })}
        gap="size-200"
        onChange={onChange}
        value={[createPackageModalStore.pkgSessionSettings.packagingMode]}
      >
        {LICENSE_TYPE_OPTIONS.filter((pkgType) =>
          showCard(createPackageModalStore.pkgSessionSettings, pkgType)
        ).map((pkgType, index) => (
          <LicenseTypeCard
            key={pkgType}
            autoFocus={index === 0}
            content={intl.formatMessage({
              id: `packages.createPackage.licenseType.${pkgType}Desc`,
            })}
            name={`ent_learnmore_${getSubType(pkgType).toUpperCase()}`}
            title={intl.formatMessage({
              id: `packages.createPackage.licenseType.${pkgType}Title`,
            })}
            value={pkgType}
          />
        ))}
      </CreatePackageCardCheckboxGroup>
    </Flex>
  );
});

ChooseLicenseTypePage.propTypes = {};
export default ChooseLicenseTypePage;
