/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketPhoneNumberFormSection
 *
 * @description The phone number section for a support form.
 *
 * @binding {String} descriptionKey. The key for the description for this section.
 * @binding {Function} onChange. Callback for when the phone number changes.
 * @binding {String} [phoneNumber]. The phone number to initially display.
 * @binding {String} [showSeparator] Default is true. To show the default separator/border before the section content.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketPhoneNumberFormSection', {
    bindings: {
      descriptionKey: '@',
      onChange: '&',
      phoneNumber: '@?',
      showSeparator: '@?',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-phone-number-form-section/support-ticket-phone-number-form-section.component.html',
  });

  function controller(_, AuthenticatedUser) {
    const vm = this;

    _.assign(vm, {
      initialCountryCode: AuthenticatedUser.get().getCountryCode(),
      onPhoneNumberInputChange,
    });

    function onPhoneNumberInputChange({isValid, phoneNumber}) {
      const newPhoneNumber = isValid ? phoneNumber : null;
      vm.onChange({phoneNumber: newPhoneNumber});
    }
  }
})();
