import {
  PATH_EXPERT_SESSIONS,
  PATH_EXPERT_SESSIONS_CLOSED,
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT,
  PATH_SUPPORT_CASES,
  PATH_SUPPORT_CASES_CLOSED,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASE_DETAILS,
  PATH_SUPPORT_SUMMARY_CC,
  PATH_SUPPORT_SUMMARY_DC,
  PATH_SUPPORT_SUMMARY_EC,
  PATH_SUPPORT_SUMMARY_MORE_PRODUCT,
} from 'features/support/routing/supportPaths';

const supportRouteMigration = [
  {
    stateName: ['org.support', 'org.support.summary', 'org.support.summary.administration'],
    url: PATH_SUPPORT,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.cases',
    url: PATH_SUPPORT_CASES,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.cases.open',
    url: PATH_SUPPORT_CASES_OPEN,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.cases.closed',
    url: PATH_SUPPORT_CASES_CLOSED,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.sessions',
    url: PATH_EXPERT_SESSIONS,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.sessions.open',
    url: PATH_EXPERT_SESSIONS_OPEN,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.sessions.closed',
    url: PATH_EXPERT_SESSIONS_CLOSED,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.summary.creative-cloud',
    url: PATH_SUPPORT_SUMMARY_CC,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.summary.document-cloud',
    url: PATH_SUPPORT_SUMMARY_DC,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.summary.experience-cloud',
    url: PATH_SUPPORT_SUMMARY_EC,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.summary.more-products',
    url: PATH_SUPPORT_SUMMARY_MORE_PRODUCT,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.summary.team',
    url: PATH_SUPPORT,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.support-case-details',
    url: PATH_SUPPORT_CASE_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'org.support.expert-session-details',
    url: PATH_EXPERT_SESSION_DETAILS,
    useReactRoute: true,
  },
];

export default supportRouteMigration;
