import {Checkbox} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PackagesSection from '../../packages-section/PackagesSection';

/**
 * Notifications Section
 * To indicate if user is to be notified by email when creative cloud application updates are available.
 */
const NotificationsSection = ({notificationCheckBoxRef, preferences, setPreferences}) => {
  const intl = useIntl();

  return (
    <PackagesSection
      headingId="packages-notifications-heading"
      title={intl.formatMessage({
        id: 'packages.preferences.notification.title',
      })}
    >
      <Checkbox
        ref={notificationCheckBoxRef}
        data-testid="notifications-section-checkbox"
        isEmphasized
        isSelected={preferences.subscribed}
        marginTop="size-200"
        onChange={() => setPreferences({...preferences, subscribed: !preferences.subscribed})}
      >
        {intl.formatMessage({id: 'packages.preferences.notification.notifyUpdates'})}
      </Checkbox>
    </PackagesSection>
  );
};

NotificationsSection.propTypes = {
  /**
   * Reference to notification section's checkbox. Required in preferences button group.
   */
  notificationCheckBoxRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }).isRequired,
  /**
   * Preferences object
   */
  preferences: PropTypes.shape({
    /**
     * Indicates if user to be notified by email when creative cloud application is available.
     */
    subscribed: PropTypes.bool,
  }).isRequired,
  /**
   * Function to be called with updated preferences object when preferences.subscribed value is to be modified.
   */
  setPreferences: PropTypes.func.isRequired,
};

export default NotificationsSection;
