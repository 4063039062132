import {Flex} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import StartChatButton from '../start-chat-button/StartChatButton';
import SummaryRequestExpertSessionButton from '../summary-request-expert-session-button/SummaryRequestExpertSessionButton';
import SupportHelpTopic from '../support-help-topics/support-help-topic/SupportHelpTopic';

/**
 * Ports https://git.corp.adobe.com/admin-tribe/onesie/blob/ec55198388a930a2c1318d941387e613b76ff039/src/app/support/support-summary/team/support-summary-team.component.html#L3-L36
 * Which has a goUrl for ac_cct_support_case, a StartChat button and an CreateExpertSession button
 */
const TeamQuickActions = () => {
  const intl = useIntl();
  return (
    <Flex direction="column" gap="size-200" marginTop="size-200">
      <SupportHelpTopic
        label={intl.formatMessage({
          id: 'support.teamQuickActions.manageSupportCases',
        })}
        name="ac_cct_support_case"
      />

      <Flex direction="row" gap="size-160">
        <StartChatButton variant="accent" />
        <SummaryRequestExpertSessionButton />
      </Flex>
    </Flex>
  );
};

export default TeamQuickActions;
