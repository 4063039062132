(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilProductsApplicationIntegrations
   * @description defines service to manage jil products-application-integrations resources
   */
  angular
    .module('app.core.api.jil')
    .provider('jilProductsApplicationIntegrations', jilProductsApplicationIntegrationsProvider);

  let url;

  /* @ngInject */
  function jilProductsApplicationIntegrationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        acceptedUsersByClientId: getAcceptedUsersByClientId(),
        applicationIntegrationByClientId: getApplicationIntegrationByClientId(),
        applicationIntegrations: getApplicationIntegrations(),
        applicationPolicies: getApplicationPolicies(),
      };

      return service;

      ///////////

      function getAcceptedUsersByClientId() {
        return $resource(
          `${url}/:orgId/application-integrations/:clientId/consents`,
          {
            clientId: '@clientId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getApplicationIntegrationByClientId() {
        return $resource(`${url}/:orgId/application-integrations/:clientId`, {
          clientId: '@clientId',
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getApplicationIntegrations() {
        return $resource(
          `${url}/:orgId/application-integrations`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getApplicationPolicies() {
        return $resource(
          `${url}/:orgId/application-policies`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            update: {method: 'PATCH'},
          }
        );
      }
    }
  }
})();
