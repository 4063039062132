import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/binky';
import pick from 'lodash/pick';

import {getContentObject, getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

import HvaCard from './HvaCard';
import {HVA_ORDER, HVA_TYPE} from './hvaConstants';

class SophiaHvaCard extends HvaCard {
  static async get() {
    const sophiaCardList = await sophiaHelper.getSophiaCards({
      surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_HVA,
    });
    const sophiaCards = sophiaCardList.items;

    return sophiaCards.map((sophiaCard) => {
      const card = sophiaCard.getCard();
      const cardContent = getContentObject(card);

      const sophiaAnalyticsParams = {
        ...pick(sophiaCard.containerAnalyticsParams, [
          ACTIONBLOCK_ID,
          CAMPAIGN_ID,
          CONTROL_GROUP_ID,
          TREATMENT_ID,
          VARIATION_ID,
        ]),
        ...pick(sophiaCard, [CONTAINER_ID, SURFACE_ID]),
      };

      const defaultCardFields = {
        ctaLabel: cardContent.ctaLabel,
        description: cardContent.displayText,
        header: cardContent.bodyCopy,
        icon: {
          src: card.backgroundImage,
        },
        id: sophiaCard.getCardId(),
        isDismissible: cardContent.eventData?.isDismissible,
        learnMoreHref: card.eventAction,
        onCTA: getCtaClickEventCallback(card.actionURL),
        sectionTitle: cardContent.cardLabel,
      };

      return new SophiaHvaCard({...defaultCardFields, ...sophiaAnalyticsParams});
    });
  }

  constructor(options) {
    super({
      priorityOrder: HVA_ORDER.SOPHIA,
      type: HVA_TYPE.SOPHIA,
      ...options,
    });
  }
}

export default SophiaHvaCard;
