import PropTypes from 'prop-types';
import React from 'react';

import './OptionSelectorWrapper.pcss';

const OptionSelectorWrapper = ({children}) => (
  <div styleName="option-selector-wrapper">{children}</div>
);

OptionSelectorWrapper.propTypes = {
  // This component expects an OptionSelector as child (@pandora/react-option-selector)
  children: PropTypes.element,
};

export default OptionSelectorWrapper;
