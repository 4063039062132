(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  angular.module('app.widgets.products').component('appProductSelect', {
    bindings: {
      product: '<',
      user: '<',
    },
    controller,
    templateUrl: 'app/widgets/products/product-select/product-select.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $q,
    $scope,
    $translate,
    numberFilter,
    PRODUCT_SELECT_CHANGE,
    PRODUCT_SELECT_ERROR,
    PRODUCT_SELECT_VALIDITY_CHANGE,
    ProductConfigurationList,
    trialHelper
  ) {
    const vm = this;

    _.assign(vm, {
      didUserInitiallyHaveProduct: doesUserHaveProduct(),
      doesUserHaveProduct,
      getAvailableLicenseCount,
      getAvailableLicensesStr,
      isSelectButtonDisabled,
      productConfigurationList: null,
      promiseToWait: $q.resolve(),
      toggleProduct,
      trialHelper,
    });

    function addProductToUser() {
      let product = getUserProduct();
      if (!product) {
        product = _.clone(vm.product);
        vm.user.products.push(product);
      }
      product.licenseGroups = vm.productConfigurationList.items;
      emitChange();
    }

    function doesUserHaveProduct() {
      return _.get(getUserProduct(), 'licenseGroups.length', 0) > 0;
    }

    function emitChange() {
      $scope.$emit(PRODUCT_SELECT_CHANGE);
    }

    function emitError() {
      $scope.$emit(PRODUCT_SELECT_ERROR);
    }

    function emitValidityChange() {
      $scope.$emit(
        PRODUCT_SELECT_VALIDITY_CHANGE,
        vm.product,
        vm.productConfigurationList.$resolved
      );
    }

    function getAvailableLicenseCount() {
      let count = vm.product.getAvailableLicenseCount();
      const doesUserCurrentlyHaveProduct = doesUserHaveProduct();

      if (vm.didUserInitiallyHaveProduct && !doesUserCurrentlyHaveProduct) {
        count += 1;
      } else if (!vm.didUserInitiallyHaveProduct && doesUserCurrentlyHaveProduct) {
        count -= 1;
      }
      return count;
    }

    function getAvailableLicensesStr() {
      if (trialHelper.isExpiredTrial(vm.product)) {
        return $translate.instant('widgets.products.productSelect.trialExpired');
      } else if (getAvailableLicenseCount() > 0) {
        return $translate.instant(
          'widgets.products.productSelect.licensesAvailable',
          {
            count: numberFilter(getAvailableLicenseCount()),
          },
          'messageformat'
        );
      }
      return $translate.instant('widgets.products.productSelect.noLicensesAvailable');
    }

    function getUserProduct() {
      return _.find(vm.user.products, {id: vm.product.id});
    }

    function isSelectButtonDisabled() {
      return (
        (!doesUserHaveProduct() && getAvailableLicenseCount() <= 0) ||
        trialHelper.isExpiredTrial(vm.product)
      );
    }

    function removeProductFromUser() {
      _.remove(vm.user.products, {id: vm.product.id});
      emitChange();
    }

    function toggleProduct() {
      if (doesUserHaveProduct()) {
        removeProductFromUser();
      } else {
        const pageSize = 1;
        vm.productConfigurationList = ProductConfigurationList.get(vm.product, pageSize);
        emitValidityChange();
        vm.promiseToWait = vm.productConfigurationList.$promise;
        vm.promiseToWait
          .then(() => {
            addProductToUser();
            emitValidityChange();
          })
          .catch(emitError);
      }
    }
  }
})();
