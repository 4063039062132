/**
 * @deprecated ported to src2/app/common/components/user-groups-detail-section/UserGroupsDetailSection.test.jsx
 *
 * @ngdoc component
 * @name app.widgets.user-groups.detail-section:appGroupsDetailSection
 *
 * @description The Users Groups section used by both the User Detail page and the User drawer.
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section. Supports
 *   only levels 2, 3 or 4. If an invalid level is supplied, it will be reset to the default 3.
 * @binding {PageContext} pageContext.
 * @binding {Object|undefined} userGroups. A user group.
 * @binding {promise|undefined} waitPromise. If specified, a dot-dot-dot wait will be shown until
 *   the promise resolves.
 */
(function () {
  angular.module('app.widgets.user-groups.detail-section').component('appGroupsDetailSection', {
    bindings: {
      headingLevel: '<?',
      onEditUserGroups: '=',
      pageContext: '<?',
      user: '<?',
      userGroups: '<',
      waitPromise: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/user-groups/groups-detail-section/groups-detail-section.component.html',
  });

  /* @ngInject */
  function controller($q, _, PAGE_TARGET, userGroupAccess) {
    const vm = this;

    _.assign(vm, {$onInit, canShowButtonBar});

    function $onInit() {
      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      vm.headingLevel = vm.headingLevel || 3;
      if (vm.headingLevel < 2 || vm.headingLevel > 4) {
        vm.headingLevel = 3;
      }

      if (_.isUndefined(vm.waitPromise)) {
        vm.waitPromise = $q.resolve();
      }

      // If we're already in the 'users.user-group-details' state for a group, then clicking on a
      // link for this group will do nothing.
      // We special case and make this group a span rather than a link.
      if (vm.pageContext && vm.pageContext.target === PAGE_TARGET.USER_GROUP) {
        vm.userGroupId = vm.pageContext.targetId;
      }
    }

    function canEditUserGroups() {
      return (
        vm.user &&
        !vm.user.getType().isTechnicalAccount() &&
        // Try to call the function if it exists on the provided model
        // else return false.
        !_.result(vm.user, 'isExternallyManaged', false)
      );
    }

    function canShowButtonBar() {
      return userGroupAccess.canEditUserGroup() && canEditUserGroups();
    }
  }
})();
