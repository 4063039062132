(function () {
  /**
   * @deprecated ported to src2/app/shell/notifications/NotificationsContainer
   *
   * @ngdoc component
   * @name app.widgets.notifications:appNotificationsDrawer
   *
   * @description the notifications drawer widget
   *
   * @param {function} onClose The callback function to register for the close event.
   * @param {function} onOpen The callback function to register for the open event.
   *
   * @example <app-notifications-drawer></app-notifications-drawer>
   *
   */
  angular.module('app.widgets.notifications').component('appNotificationsDrawer', {
    bindings: {
      onClose: '<?',
      onOpen: '<?',
    },
    controller,
    templateUrl: 'app/widgets/notifications/notifications-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $interval,
    $q,
    $rootScope,
    $state,
    $translate,
    $window,
    _,
    AdobeStatusDataList,
    binkySrc2,
    feature,
    MESSAGE,
    NavItem,
    NOTIFICATION_ANALYTIC_EVENT,
    NOTIFICATION_TABS,
    onesieSrc2,
    panelManager
  ) {
    const ANALYTICS_CONSTANTS = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
    const POLLING_INTERVAL = 60000;
    const MAX_POLLING_TIMES = 5;
    const vm = this;
    _.assign(vm, {
      $onInit,
      feature,
      NOTIFICATION_TABS,
      onChangeNotification,
      onClickButton,
      onNotificationsDrawerClose,
      onNotificationsDrawerOpen,
      onUpdatePagination,
    });

    function $onInit() {
      _.assign(vm, {
        feature,
        notifications: onesieSrc2.notifications.models.list.NotificationList.get(),
        notificationsDrawerTitle: $translate.instant('widgets.notifications.title'),
        NotificationsPanel: onesieSrc2.notifications.components.NotificationsPanel,
        StatusPanel: onesieSrc2.notifications.components.StatusPanel,
        tabs: _(NOTIFICATION_TABS)
          .values()
          .map((tab) =>
            _.assign(new NavItem(), {
              onClick: () => {
                onSwitchTabs(tab);
              },
            })
          )
          .value(),
        waitOnDrawer: $q.defer(),
      });

      vm.notifications.promise
        .then(() => {
          vm.waitOnDrawer.resolve();
        })
        .catch((error) => {
          vm.waitOnDrawer.reject(error);
        });

      $interval(refreshNotifications, POLLING_INTERVAL, MAX_POLLING_TIMES);

      vm.currentStatusList = [];
      vm.adobeStatusDataList = AdobeStatusDataList.get();
      vm.adobeStatusDataList.$promise
        .then(() => {
          vm.currentStatusList = vm.adobeStatusDataList.getActiveAndRecentStatusEvents();
        })
        .catch(() => {
          vm.currentStatusList = [];
        })
        .finally(() => {
          vm.tabs[1].name = $translate.instant('widgets.notifications.tabs.status', {
            unreadSize: vm.currentStatusList.length,
          });
        });
    }

    // When this component is ported to React, it should not take an expertSessionId,
    // the second if statement should be used and the first one should be eliminated entirely
    function onClickButton({expertSessionId, notification, supportCaseId, surveyUrl}) {
      if (expertSessionId) {
        $state.go('org.support.expert-session-details', {id: expertSessionId});
      } else if (supportCaseId) {
        $state.go('org.support.support-case-details', {id: supportCaseId});
      } else {
        $window.open(surveyUrl, '_blank');
      }
      onesieSrc2.notifications.models.dispatchEvent({
        action: ANALYTICS_CONSTANTS.EVENT_ACTION.CLICK,
        name: NOTIFICATION_ANALYTIC_EVENT.NAME.BUTTON,
        notification,
      });
      panelManager.close('notifications-drawer');
    }

    function onChangeNotification(promise) {
      vm.waitOnDrawer = $q.defer();
      promise
        .then(() => {
          vm.tabs[0].name = $translate.instant('widgets.notifications.tabs.console', {
            unreadSize: vm.notifications.getUnreadCount(),
          });
          $rootScope.$emit(MESSAGE.CHANGE.NOTIFICATION);
          vm.waitOnDrawer.resolve();
        })
        .catch((error) => {
          vm.waitOnDrawer.reject(error);
        });
    }

    function onNotificationsDrawerClose() {
      onesieSrc2.notifications.models.dispatchEvent({
        action: ANALYTICS_CONSTANTS.EVENT_ACTION.CLOSE,
        name: NOTIFICATION_ANALYTIC_EVENT.NAME.DRAWER,
      });

      if (vm.onClose) {
        vm.onClose();
      }
    }

    function onNotificationsDrawerOpen() {
      vm.selectedTab = NOTIFICATION_TABS.CONSOLE;
      // we refresh the items used on open, as it ensures we have the right org
      // fetching here gets us the count for use in the child tab header
      vm.notifications.promise
        .then(() => {
          vm.unreadSize = vm.notifications.getUnreadCount();
        })
        .catch(() => {
          vm.unreadSize = 0;
        })
        .finally(() => {
          vm.tabs[0].name = $translate.instant('widgets.notifications.tabs.console', {
            unreadSize: vm.unreadSize,
          });
        });
    }

    function onSwitchTabs(tab) {
      vm.selectedTab = tab;
      onesieSrc2.notifications.models.dispatchEvent({
        action:
          NOTIFICATION_TABS.CONSOLE === tab
            ? NOTIFICATION_ANALYTIC_EVENT.ACTION.ADMIN_CONSOLE
            : NOTIFICATION_TABS.STATUS,
        name: ANALYTICS_CONSTANTS.EVENT_NAME.SWITCH_TABS,
      });
    }

    function onUpdatePagination() {
      // focus admin console tab when removing last notification in a page
      _.invoke($window.document.querySelector('binky-tab-nav .tab'), 'focus');
    }

    function refreshNotifications() {
      const itemCount = vm.notifications.getUnreadCount();
      vm.waitOnDrawer = $q.defer();
      vm.notifications.refresh();
      vm.notifications.promise
        .then(() => {
          // Only emit when new notifications come in to reduce API calls
          if (itemCount !== vm.notifications.getUnreadCount()) {
            vm.tabs[0].name = $translate.instant('widgets.notifications.tabs.console', {
              unreadSize: vm.notifications.getUnreadCount(),
            });
            $rootScope.$emit(MESSAGE.CHANGE.NOTIFICATION);
          }
          vm.waitOnDrawer.resolve();
        })
        .catch((error) => {
          vm.waitOnDrawer.reject(error);
        });
    }
  }
})();
