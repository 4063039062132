(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(accountAddProductsRouteConfig);

  /* @ngInject */
  function accountAddProductsRouteConfig(addProductsModalRoutesProvider) {
    // Create deep linking route for Add Products using addProductsModalRoutesProvider
    addProductsModalRoutesProvider.configure({
      analyticsModalSourcePage: 'account',
      parentRoute: 'account.account',
      stateName: 'account.account.add-products',
    });
  }
})();
