import {Flex, useListData} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import {IncludeListFormContextProvider} from './IncludeListFormContext';
import IncludePane from './include-pane/IncludePane';

const IncludeListForm = ({
  autoInclude,
  children: itemRenderer,
  setIncluded,
  items,
  isItemIncluded,
  readOnly,
  ...props
}) => {
  const availableSearchRef = useRef();
  const includedSearchRef = useRef();

  const {
    items: availableItems,
    append: appendAvailable,
    remove: removeAvailable,
    update: updateAvailable,
  } = useListData({
    getKey: (item) => item.key,
    initialItems: items.filter((item) => !isItemIncluded(item)),
  });

  const {
    items: includedItems,
    append: appendIncluded,
    remove: removeIncluded,
    update: updateIncluded,
  } = useListData({
    getKey: (item) => item.key,
    initialItems: items.filter((item) => isItemIncluded(item)),
  });

  // When autoInclude is changed, call insert/remove on the lists to set everything in the included one.
  // This needs to be a useEffect because useListData does not recompute based on prop changes - it's a wrapper around useState.
  useEffect(() => {
    // update each item - otherwise there is unexpected behaviour in PermissionsIncludeListForm
    includedItems.forEach((item) => updateIncluded(item.key, {...item}));
    availableItems.forEach((item) => updateAvailable(item.key, {...item}));
    if (autoInclude) {
      appendIncluded(...availableItems);
      removeAvailable(...availableItems.map((i) => i.key));
    } else {
      const available = includedItems.filter((item) => !isItemIncluded(item));
      appendAvailable(...available);
      removeIncluded(...available.map((i) => i.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this should only run when autoInclude is changed
  }, [autoInclude, isItemIncluded]);

  return (
    <IncludeListFormContextProvider
      {...props}
      itemRenderer={itemRenderer}
      readOnly={autoInclude || readOnly}
    >
      <Flex direction="row" gap="size-400">
        <IncludePane
          addRemove={(itemsToAdd) => {
            const modifiedItemsToAdd = setIncluded(itemsToAdd, true);
            removeAvailable(...modifiedItemsToAdd.map((i) => i.key));
            appendIncluded(...modifiedItemsToAdd);
            if (modifiedItemsToAdd.length >= availableItems.length) {
              // if nothing in list, focus on search of other pane
              includedSearchRef.current.focus();
            }
            // per ONESIE-31830, want to focus on neighbouring item in list, but react-spectrum does not expose selectionManager
          }}
          items={availableItems}
          searchRef={availableSearchRef}
          type="available"
        />
        <IncludePane
          addRemove={(itemsToRemove) => {
            const modifiedItemsToRemove = setIncluded(itemsToRemove, false);
            removeIncluded(...modifiedItemsToRemove.map((i) => i.key));
            appendAvailable(...modifiedItemsToRemove);
            if (modifiedItemsToRemove.length >= includedItems.length) {
              // if nothing in list, focus on search of other pane
              availableSearchRef.current.focus();
            }
          }}
          items={includedItems}
          searchRef={includedSearchRef}
          type="included"
        />
      </Flex>
    </IncludeListFormContextProvider>
  );
};
IncludeListForm.propTypes = {
  /**
   * Whether all items should be auto included. This will also make the form readOnly.
   */
  autoInclude: PropTypes.bool,
  /**
   * The empty message for the available list in the form.
   */
  availableEmptyMessage: PropTypes.string.isRequired,
  /**
   * The title of the available list in the form.
   */
  availableTitle: PropTypes.string.isRequired,
  /**
   * The callback to render each child item in the lists. Takes an argument of the item to render.
   */
  children: PropTypes.func.isRequired,
  /**
   * The empty message for the include list in the form.
   */
  includeEmptyMessage: PropTypes.string.isRequired,
  /**
   * The title of the include list in the form.
   */
  includeTitle: PropTypes.string.isRequired,
  /**
   * Callback to determine if an item is included or not.
   */
  isItemIncluded: PropTypes.func.isRequired,
  /**
   * The array of items. Items is a generic object as an item can be any object, but it must have a key.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /**
   * A function to determine the text of an item. This text is used in aria labels. Takes an argument of the item to get the text for.
   */
  itemText: PropTypes.func.isRequired,
  /**
   * The message to display when there are no results matching the search string.
   */
  noResultsMessage: PropTypes.string.isRequired,
  /**
   * Whether the include list is read only or not. A read only include list does not display the add/remove (all) buttons.
   */
  readOnly: PropTypes.bool,
  /**
   * Callback to determine whether an item should be included in the results of a search. Takes argument of an item, and the text search term.
   */
  searchItem: PropTypes.func.isRequired,
  /**
   * Callback run on change to the items. Takes an argument of the items changed, and whether they are included or not.
   */
  setIncluded: PropTypes.func.isRequired,
};

export default IncludeListForm;
