import {BULK_OPERATIONS_RESULTS_FILENAME} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import {
  getHrefForProductLicenseDeficitFn,
  getHrefForProductProfileLicenseDeficitFn,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
} from 'features/products/routing/productsPaths';
import {
  getHrefForUserGroupLicenseDeficitFn,
  getHrefForUsersLicenseDeficit,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS_RESULTS,
} from 'features/users/routing/usersPaths';

// Path to download file name.
const PATH_TO_FILENAME_MAP = {
  [PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS]: BULK_OPERATIONS_RESULTS_FILENAME.DIRECTORY_USERS,
  [PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS]: BULK_OPERATIONS_RESULTS_FILENAME.PRODUCT_USERS,
  [PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS]:
    BULK_OPERATIONS_RESULTS_FILENAME.PROFILE_USERS,
  [PATH_USER_GROUP_DETAILS_RESULTS]: BULK_OPERATIONS_RESULTS_FILENAME.USER_GROUP_DETAILS,
  [PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS]: BULK_OPERATIONS_RESULTS_FILENAME.USER_GROUPS_USERS,
  [PATH_USERS_BULK_OPERATIONS_RESULTS]: BULK_OPERATIONS_RESULTS_FILENAME.USERS,
};

/**
 * Method to determine the path to the LicenseDeficitReportPage for the given
 * path.
 *
 * @param {String} path - the React router path constant for the bulk operation
 *   results path.
 * @returns the href for the LicenseDeficitReportPage associated with 'path'
 *  or null if the path doesn't have a deficit route.
 */
function getLicenseDeficitHref({path, pathParams}) {
  const {jobId, profileId, productId, userGroupId} = pathParams;

  switch (path) {
    case PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS:
      return getHrefForProductLicenseDeficitFn({productId, profileId})({jobId});
    case PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS:
      return getHrefForProductProfileLicenseDeficitFn({productId, profileId})({jobId});
    case PATH_USERS_BULK_OPERATIONS_RESULTS:
      return getHrefForUsersLicenseDeficit({jobId});
    case PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS:
    case PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS:
      return null;
    case PATH_USER_GROUP_DETAILS_RESULTS:
      return getHrefForUserGroupLicenseDeficitFn({userGroupId})({jobId});
    default:
      throw new Error(`jobResultsUtils: getLicenseDeficitHref path ${path} not implemented`);
  }
}

/**
 * Method to determine the bulk operations results filename for the given path.
 *
 * @param {String} path - the React router path constant
 * @returns the filename
 */
function getResultsFilename({path}) {
  const filename = PATH_TO_FILENAME_MAP[path];

  if (!filename) {
    throw new Error(`jobResultsUtils: getResultsFilename path ${path} not implemented`);
  }

  return filename;
}

export {getResultsFilename, getLicenseDeficitHref};
