import {
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductsList from '../products-list/ProductsList';

// AppSystemReqDetails is a Link component which upon pressing opens a dialog containing products with special system requirements
const AppSystemReqDetails = ({products}) => {
  const intl = useIntl();
  return (
    <DialogTrigger type="popover">
      <Link variant="secondary">
        {intl.formatMessage({
          id: 'packages.createPackage.chooseApps.sysReq.actionLink',
        })}
      </Link>
      <Dialog>
        <Heading level={3}>
          {intl.formatMessage({
            id: 'packages.createPackage.chooseApps.sysReq.title',
          })}
        </Heading>
        <Divider marginBottom="size-100" marginTop="size-100" />
        <Content>
          <Flex direction="column" gap="size-150">
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.chooseApps.sysReq.desc',
              })}
            </Text>
            <ProductsList isAvailableProductsListStyling products={products} showSysReqLink />
          </Flex>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

AppSystemReqDetails.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
};

export default AppSystemReqDetails;
