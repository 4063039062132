const ATTRIBUTE_MAPPINGS = {
  AZURE: {
    country: 'ctry',
    email: 'email',
    firstName: 'given_name',
    lastName: 'family_name',
  },
  OIDC: {
    country: 'address.country',
    email: 'email',
    firstName: 'given_name',
    lastName: 'family_name',
  },
  SAML: {
    country: 'CountryCode',
    email: 'Email',
    firstName: 'FirstName',
    lastName: 'LastName',
  },
};

const COUNTRY_CODE_PROPERTY_NAME_AZURE_OIDC = 'ctry';
const COUNTRY_CODE_PROPERTY_NAME_GENERIC_SAML = 'CountryCode';
const COUNTRY_CODE_PROPERTY_NAME_OIDC = 'address.country';

export {
  ATTRIBUTE_MAPPINGS,
  COUNTRY_CODE_PROPERTY_NAME_AZURE_OIDC,
  COUNTRY_CODE_PROPERTY_NAME_GENERIC_SAML,
  COUNTRY_CODE_PROPERTY_NAME_OIDC,
};
