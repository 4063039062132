/* eslint-disable max-lines -- few lines over, will cleanup after removing FF in subsequent release */
const ACTIVATION_CODE_VALID_LENGTH = 18;

const ADOBE_TEMPLATE_MODEL_CACHE_ID = 'PackagesAdobeTemplates';

const ADOBE_TEMPLATE_MODEL_CACHE_KEY = 'ADOBE_TEMPLATES';

const ALL_ADDONS = 'all_addons';

const ALL_PACKAGES_CONSTANTS = {
  ACTION_CORRUPTED: 'NA_ACTION',
  ACTION_DOWNLOAD: 'DOWNLOAD_AVAILABLE_ACTION',
  ACTION_EXPIRED: 'DOWNLOAD_EXPIRED_ACTION',
  ACTION_NONE: 'NO_ACTION',
  ACTION_RETRY: 'RETRY_ACTION',
  REMOVE_PACKAGE_MODAL_ID: 'removePackageModalId',
  STATE_BUILDING: 'BUILDING_STATE',
  STATE_CANCELLING: 'CANCELLING_STATE',
  STATE_CORRUPTED: 'CORRUPTED_STATE',
  STATE_ERROR: 'ERROR_STATE',
  STATE_NOT_UP_TO_DATE: 'NOT_UP_TO_DATE_STATE',
  STATE_PREPARING: 'PREPARING_STATE',
  STATE_UNRESOLVED: 'STATE_UNRESOLVED',
  STATE_UP_TO_DATE: 'UP_TO_DATE_STATE',
};

const AUSST_TEXT_AREA_LIMIT = 20000;

const CREATE_PACKAGE = {
  EVENTS: {
    IS_BUILD_DISABLED: 'IS_BUILD_DISABLED',
    NEW_PACKAGE_CREATED: 'NEW_PACKAGE_CREATED',
    PACKAGE_REQUESTED: 'PACKAGE_REQUESTED',
    PROGRESS_STATUS: 'PROGRESS_STATUS',
  },
  MODE: {
    FRL: 'frl',
    NAMED: 'named',
    SDL: 'nuell_package',
  },
  TYPE: {
    FRL_ISOLATED: 'frl_isolated',
    FRL_LAN: 'frl_lan',
    FRL_OFFLINE: 'frl_offline',
    FRL_ONLINE: 'frl_online',
    MANAGED: 'managedPackage',
    SDL: 'nuell_package',
    SELF_SERVICE: 'selfService',
  },
};

const CREATE_PACKAGE_CONSTANTS = {
  DEVICE_INFO_PROTECTED: 'DEVICE_INFO_PROTECTED',
  DEVICE_INFO_SHARED: 'DEVICE_INFO_SHARED',
  ENTERPRISE_CUSTOMER: 'ENTERPRISE_CUSTOMER_TYPE',
  FRL_FIXED_DNS: 'FIXED_DNS',
  FRL_FIXED_IP: 'FIXED_IP',
  FRL_ISOLATED_PACKAGE: CREATE_PACKAGE.TYPE.FRL_ISOLATED,
  FRL_LAN_PACKAGE: CREATE_PACKAGE.TYPE.FRL_LAN,
  FRL_OFFLINE_PACKAGE: CREATE_PACKAGE.TYPE.FRL_OFFLINE,
  FRL_ONLINE_PACKAGE: CREATE_PACKAGE.TYPE.FRL_ONLINE,
  FRL_PACKAGE_TYPE: CREATE_PACKAGE.MODE.FRL,
  FRL_WIZARD_ID_ISOLATED: 'create-package-frl-isolated-wizard-id',
  FRL_WIZARD_ID_ISOLATED_DC: 'create-package-frl-isolated-dc-wizard-id',
  FRL_WIZARD_ID_LAN: 'create-package-frl-lan-wizard-id',
  FRL_WIZARD_ID_OFFLINE: 'create-package-frl-offline-wizard-id',
  FRL_WIZARD_ID_ONLINE: 'create-package-frl-online-wizard-id',
  LICENSE_FILE_SAP_CODE: 'licenseFile',
  LINUX_PLATFORM: 'LINUX',
  MAC_ARM_PLATFORM: 'MACARM',
  MAC_PLATFORM: 'MAC',
  MACU_PLATFORM: 'MACUNIVERSAL',
  MANAGED_PACKAGE: CREATE_PACKAGE.TYPE.MANAGED,
  NAME_REGEX: '[@#$&`^><?{}!/\\\\:|",~]',
  NAMED_PACKAGE_TYPE: CREATE_PACKAGE.MODE.NAMED,
  NUELL_PACKAGE_MODE: CREATE_PACKAGE.MODE.SDL,
  NUELL_WIZARD_ID: 'create-package-nuell-wizard-id',
  PPRO: 'PPRO',
  PPROBETA: 'PPROBETA',
  SELF_SERVE_PACKAGE: CREATE_PACKAGE.TYPE.SELF_SERVICE,
  SIXTY_FOUR_BIT: '64',
  TEAM_CUSTOMER: 'TEAM_CUSTOMER_TYPE',
  THIRTY_TWO_BIT: '32',
  WIN_ARM_PLATFORM: 'WINARM',
  WIN_PLATFORM: 'WIN',
};

const CREATE_PACKAGE_MODAL_ID = 'create-package-modal';

const INVALID_PACKAGE_NAME_REGEX = '[@*#$&`^><?{}!/\\\\:|",~]';

const INVALID_SERVER_URL_REGEX = '(https?://.*):(\\d{2,5})\\/?(.*)';
const CREATE_SERVER = {
  EVENTS: {
    DOWNLOAD_ATO: 'DOWNLOAD_ATO',
    NEW_SERVER_CREATED: 'NEW_SERVER_CREATED',
    SERVER_CREATION_PROGRESS: 'SERVER_CREATION_PROGRESS',
  },
};

const CREATE_SERVER_MODAL_ID = 'create-server-modal';

const EDIT_SERVER_MODAL_ID = 'edit-server-modal';

const KCCC_SAP_CODE = 'KCCC';

const PACKAGE_ANALYTICS_CONSTANTS = {
  CATEGORY: 'WEB',
  CLIENT_ID: 'ONESIE1',
  INGEST_TYPE: 'dunamis',
  PLATFORM: 'Web',
  PROJECT_NAME: 'aconsole-web-service',
  SOURCE_NAME: 'Admin Console',
  SOURCE_VERSION: '0.0.1',
  WORKFLOW: 'Packages',
};

const PACKAGE_TYPE_CONSTANTS = {
  FRL_HIGH_PRIVACY_ONLINE: 'frlHighprivacyOnline',
  FRL_ISOLATED: 'frlIsolated',
  FRL_LAN: 'frlLan',
  FRL_OFFLINE: 'frlOffline',
  MANAGED: 'managedPackage',
  NUELL: 'labNuell',
  PLUGIN_AND_SW_CONFIG: 'plugin_sw',
  PLUGIN_ONLY: 'pluginOnly',
  SELF_SERVICE: 'selfService',
  SW_CONFIG_ONLY: 'swConfig',
};

const PACKAGE_SUMMARY_MODAL_ID = 'package-summary-modal';

const PACKAGES_NEW_SERVER_CREATED = 'PackagesNewServerCreated';

const PACKAGES_WORKSPACE_NAV_ID = 'PackagesWorkspaceNavID';

const PREFERENCES_CONSTANTS = {
  MAC_PATH_REGEX: '^\\/$|(^(?=\\/)|^\\.|^\\.\\.)(\\/(?=[^/\\0])[^/\\0]+)*\\/?$',
  WIN_PATH_REGEX:
    '^(([a-zA-Z]:|\\\\\\\\\\w[ \\w\\.]*)(\\\\\\w[ \\w\\.]*|\\\\%[ \\w\\.]+%+)+|%[ \\w\\.]+%(\\\\\\w[ \\w\\.]*|\\\\%[ \\w\\.]+%+)*)',
};

const PRODUCT_SAPCODES = {
  APRO: 'APRO',
  CPTL: 'CPTL',
  CPTV: 'CPTV',
  FM: 'FM',
  KCCC: 'KCCC',
  PRE: 'PRE',
  PSE: 'PSE',
  RBHP: 'RBHP',
  RBSV: 'RBSV',
};

const MILLISECONDS_IN_A_DAY = 86400000;

const REAUTHORIZE_SERVER_MODAL_ID = 'reauthorize-server-modal';

const REMOVE_SERVER_MODAL_ID = 'removeServerModalId';

const SCREEN_NAME_CONSTANTS = {
  ACTIVATION_CODES_SCREEN: 'ACTIVATION_CODES_SCREEN',
  CHOOSE_ACTIVATION_TYPE: 'CHOOSE_ACTIVATION_TYPE',
  CHOOSE_ENTITLEMENT: 'CHOOSE_ENTITLEMENT',
  CHOOSE_LICENSE_TYPE: 'CHOOSE_LICENSE_TYPE',
  CHOOSE_PACKAGE_TYPE: 'CHOOSE_PACKAGE_TYPE',
  CHOOSE_SERVER: 'CHOOSE_SERVER',
  MANAGEMENT_OPTIONS_SCREEN: 'MANAGEMENT_OPTIONS_SCREEN',
  SELF_SERVICE_SCREEN: 'SELF_SERVICE_SCREEN',
  SHOW_APPS_SCREEN: 'SHOW_APPS_SCREEN',
  SHOW_FINALIZE_SCREEN: 'SHOW_FINALIZE_SCREEN',
  SHOW_PLUGINS_SCREEN: 'SHOW_PLUGINS_SCREEN',
  SYSTEM_CONFIG_SCREEN: 'SYSTEM_CONFIG_SCREEN',
};

const SERVER_SCREEN_NAME_CONSTANTS = {
  AUTHORIZE_SERVER: 'AUTHORIZE_SERVER',
  CONFIGURE_SERVER: 'CONFIGURE_SERVER',
  SERVER_ENTITLEMENTS: 'SERVER_ENTITLEMENTS',
  SERVER_FINALIZE: 'SERVER_FINALIZE',
  SERVER_INFO: 'SERVER_INFO',
};
const PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS = [
  SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
  SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE,
  SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN,
  SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
];

const PACKAGE_FLOWS = {
  FRL_ISOLATED_DC_NONCC_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_ISOLATED_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_LAN_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_SERVER,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_OFFLINE_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_ONLINE_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  NAMED_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  NUELL_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
};

// V2 refers to plugins support in FRL and SDL workflows.
const PACKAGE_FLOWS_V2 = {
  FRL_ISOLATED_DC_NONCC_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_ISOLATED_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_LAN_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_SERVER,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_OFFLINE_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  FRL_ONLINE_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  NAMED_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
  NUELL_PACKAGE_FLOW: [
    SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN,
    SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN,
    SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN,
  ],
};

const FLOW_TYPES = {
  CHOOSE_PACKAGE_FLOW: 'CHOOSE_PACKAGE_FLOW',
  COMPLETE_FLOW: 'COMPLETE_FLOW',
  FRL_FUE_FLOW: 'FRL_FUE_FLOW',
  FRL_ISOLATED_DC_NONCC_PACKAGE_FLOW: 'FRL_ISOLATED_DC_NONCC_PACKAGE_FLOW',
  FRL_ISOLATED_PACKAGE_FLOW: 'FRL_ISOLATED_PACKAGE_FLOW',
  FRL_LAN_PACKAGE_FLOW: 'FRL_LAN_PACKAGE_FLOW',
  FRL_OFFLINE_PACKAGE_FLOW: 'FRL_OFFLINE_PACKAGE_FLOW',
  FRL_ONLINE_PACKAGE_FLOW: 'FRL_ONLINE_PACKAGE_FLOW',
  NAMED_MANAGED_PACKAGE_FLOW: 'NAMED_MANAGED_PACKAGE_FLOW',
  NAMED_PACKAGE_FLOW: 'NAMED_PACKAGE_FLOW',
  NUELL_FUE_FLOW: 'NUELL_FUE_FLOW',
  NUELL_PACKAGE_FLOW: 'NUELL_PACKAGE_FLOW',
  SELF_SERVE_FLOW: 'SELF_SERVE_FLOW',
};

const SERVER_API_MESSAGE = {
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  ERROR_UPLOAD: 'ERROR_UPLOAD',
  ERROR_VALIDATION: 'ERROR_VALIDATION',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  WAITING: 'WAITING',
  WIZARD_CLOSED: 'WIZARD_CLOSED',
};

const SERVER_SCREEN_NAME_ID = {
  SERVER_AUTHORIZE_SCREEN: 'SERVER_AUTHORIZE_SCREEN',
  SERVER_CONFIGURE_SCREEN: 'SERVER_CONFIGURE_SCREEN',
  SERVER_ENTITLEMENT_SCREEN: 'SEREVER_ENTITLEMENT_SCREEN',
  SERVER_FINALIZE_SCREEN: 'SERVER_FINALIZE_SCREEN',
  SERVER_INFO_SCREEN: 'SERVER_INFO_SCREEN',
};

const SERVER_SETUP_GUIDE_DRAWER_ID = 'server-setup-guide-drawer';

const SERVER_SUMMARY_DRAWER_ID = 'server-summary-drawer';

const PACKAGES_LAN_SERVERS = 'PackagesLanServers';

const SERVERS_CONSTANTS = {
  EXPIRY_STATUS: {
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    EXPIRING: 'EXPIRING',
  },
  QUOTA: {
    HARD: 'HARD',
    SOFT: 'SOFT',
  },
  STATE: {
    BUILDING: 'BUILDING_STATE',
    PREPARING: 'PREPARING_STATE',
    REAUTHORIZE: 'REAUTHORIZE_STATE',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    INACTIVE: 'INACTIVE',
  },
};

const SERVERS_NEXT_STEP_BANNER = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  EVENT: 'PACKAGES_SHOW_SERVER_NEXT_STEP_BANNER',
  REAUTH: 'REAUTH',
};

const UPDATE_PACKAGE_MODAL_ID = 'update-package-modal';

const UPDATE_PACKAGE_TYPE = {
  COMPLETE: 'complete',
  UPDATE_ONLY: 'updateOnly',
};

const CREATION_STATUS_TO_SERVER_STATE = {
  [SERVER_API_MESSAGE.WAITING]: SERVERS_CONSTANTS.STATE.PREPARING,
  [SERVER_API_MESSAGE.INPROGRESS]: SERVERS_CONSTANTS.STATE.BUILDING,
};

const ADMIN_PACKAGES = 'PackagesAdminPackages';
const ADMIN_PACKAGES_PLUGINS = 'AdminPackagePlugin';
const ADOBE_PRODUCTS = 'PackagesAdobeProducts';
const PROGRESS = {
  CONSTANTS: {
    CANCELLED: 'CANCELLED',
    CUSTOMIZATION_COMPLETE: 'CUSTOMIZATION_COMPLETE',
    DOWNLOAD_GENERIC_COMPLETE: 'DOWNLOAD_GENERIC_COMPLETE',
    ERROR: 'ERROR',
    PROCESSING_START: 'PROCESSING_START',
    UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
    WAITING: 'WAITING',
  },
};

const CUSTOMIZER_PROGRESS = {
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
  ERROR_CODE: {
    ERROR_INVALID_CUSTOMIZATION_REQUEST: 'ERROR_INVALID_CUSTOMIZATION_REQUEST',
    ERROR_ORG_COUNTER_REACHED: 'ERROR_ORG_COUNTER_REACHED',
    ERROR_PACKAGE_EXPIRED: 'ERROR_PACKAGE_EXPIRED',
    SERVER_UPDATE_REQUIRED: 'SERVER_UPDATE_REQUIRED',
  },
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
};

const VERSION_FILTERS = {
  BETA_VERSIONS: 'betaVersions',
  LATEST_VERSIONS: 'latestVersions',
  LTS_VERSIONS: 'LTSVersions',
  OLDER_VERSIONS: 'olderVersions',
  PRERELEASE_VERSIONS: 'preReleaseVersions',
  SHOW_BETA: 'showBeta',
  SHOW_LATEST: 'showLatest',
  SHOW_LTS: 'showLTS',
  SHOW_OLDER: 'showOlder',
  SHOW_PRERELEASE: 'showPreRelease',
};

const ADOBE_PRODUCTS_UI = 'PackagesAdobeProductsUI';

const SHOW_TEMPLATES_TAB = 'ShowTemplatesTab';
const SHOW_SERVERS_TAB = 'ShowServersTab';
const ONE_MEGABYTE = 1048576;
const POLLING_FAILURE_COUNT = 5;

export {
  ACTIVATION_CODE_VALID_LENGTH,
  ADOBE_TEMPLATE_MODEL_CACHE_ID,
  ADOBE_TEMPLATE_MODEL_CACHE_KEY,
  ADMIN_PACKAGES,
  ALL_ADDONS,
  ALL_PACKAGES_CONSTANTS,
  AUSST_TEXT_AREA_LIMIT,
  CREATE_PACKAGE,
  CREATE_PACKAGE_CONSTANTS,
  CREATE_PACKAGE_MODAL_ID,
  CREATE_SERVER,
  CREATE_SERVER_MODAL_ID,
  EDIT_SERVER_MODAL_ID,
  FLOW_TYPES,
  INVALID_PACKAGE_NAME_REGEX,
  INVALID_SERVER_URL_REGEX,
  KCCC_SAP_CODE,
  MILLISECONDS_IN_A_DAY,
  PACKAGE_ANALYTICS_CONSTANTS,
  PACKAGE_FLOWS,
  PACKAGE_FLOWS_V2,
  PACKAGE_SUMMARY_MODAL_ID,
  PACKAGE_TYPE_CONSTANTS,
  PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS,
  PACKAGES_NEW_SERVER_CREATED,
  PACKAGES_WORKSPACE_NAV_ID,
  PREFERENCES_CONSTANTS,
  PRODUCT_SAPCODES,
  REAUTHORIZE_SERVER_MODAL_ID,
  REMOVE_SERVER_MODAL_ID,
  SCREEN_NAME_CONSTANTS,
  SERVER_API_MESSAGE,
  SERVER_SCREEN_NAME_ID,
  SERVER_SETUP_GUIDE_DRAWER_ID,
  SERVER_SUMMARY_DRAWER_ID,
  SERVERS_CONSTANTS,
  SERVERS_NEXT_STEP_BANNER,
  SHOW_TEMPLATES_TAB,
  UPDATE_PACKAGE_MODAL_ID,
  UPDATE_PACKAGE_TYPE,
  VERSION_FILTERS,
  PACKAGES_LAN_SERVERS,
  CREATION_STATUS_TO_SERVER_STATE,
  PROGRESS,
  CUSTOMIZER_PROGRESS,
  ADOBE_PRODUCTS_UI,
  ADMIN_PACKAGES_PLUGINS,
  ADOBE_PRODUCTS,
  SERVER_SCREEN_NAME_CONSTANTS,
  ONE_MEGABYTE,
  POLLING_FAILURE_COUNT,
  SHOW_SERVERS_TAB,
};
/* eslint-enable max-lines -- over line limit */
