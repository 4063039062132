(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name IdentitySetupNotifier
   * @description identity notification service
   */
  angular.module('app.core.identity').factory('identitySetupNotifier', IdentitySetupNotifier);
  /* @ngInject */
  function IdentitySetupNotifier($q, $state, $translate, _, panelManager, SETUP_IDENTITY_MODAL_ID) {
    const service = {
      notify,
    };

    return service;

    function hasAtMostOneDirectory(directoryCount) {
      return directoryCount <= 1;
    }

    function isDirectoryWaitingAction(directory) {
      return directory && (directory.requiresConfiguration() || directory.requiresActivation());
    }

    function isNoActionAvailable(directory, domain) {
      return directory && domain && directory.requiresApproval() && domain.needsDirectory();
    }

    function notify(directoryList, domainList) {
      const directory = directoryList.items[0];
      const domain = domainList.items[0];
      const directoryCount = directoryList.getTotalItemCount();

      const promises = [
        notifyDirectoryPendingApproval(directoryCount, directory, domain),
        notifyDirectoryApproved(directoryCount, directory),
      ];
      return $q.all(promises).then(_.compact);
    }

    function notifyAny(variant, name, body, buttonText, onButtonClick) {
      return {
        body,
        buttonText,
        name,
        onButtonClick,
        variant,
      };
    }

    function notifyDirectoryApproved(directoryCount, directory) {
      if (hasAtMostOneDirectory(directoryCount) && isDirectoryWaitingAction(directory)) {
        const body = $translate.instant('core.identity.notifier.messages.directoryApproved', {
          name: _.get(directory, 'name'),
        });
        const buttonText = $translate.instant('core.identity.notifier.continue');
        return $q.resolve(
          notifyAny('info', 'directory-approved-banner', body, buttonText, openSetupIdentityModal)
        );
      }
      return $q.resolve();
    }

    function notifyDirectoryPendingApproval(directoryCount, directory, domain) {
      if (hasAtMostOneDirectory(directoryCount) && isNoActionAvailable(directory, domain)) {
        const body = $translate.instant(
          'core.identity.notifier.messages.directoryPendingApproval',
          {
            name: _.get(directory, 'name'),
          }
        );
        return $q.resolve(notifyAny('none', 'directory-requires-approval-banner', body));
      }
      return $q.resolve();
    }

    function openSetupIdentityModal() {
      // eslint-disable-next-line promise/catch-or-return
      $state.go('settings.identity.guided-setup').then(() => {
        panelManager.open(SETUP_IDENTITY_MODAL_ID);
      });
    }
  }
})();
