(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.hva.hva-card.assembler')
    // Should be synced with our own wiki resource:
    // https://git.corp.adobe.com/admin-tribe/onesie/wiki/HVA-(High-Value-Action)
    .constant('HVA_ORDER', {
      /* eslint-disable sort-keys */
      SOPHIA: 0,

      // Set-up/onboarding tasks.
      TAKE_TOUR: 100, // discovery task enabled for all contracts for first usage
      ADD_PRODUCTS: 200,
      UNPAID_INVOICES: 300,
      SET_UP_IDENTITY: 400,
      SET_UP_SDL: 500,
      SET_UP_FRL: 600,
      OFFER_SWITCH_MIGRATION_ASSIGN_LICENSES: 700,
      START_ASSIGN_LICENSES: 800,
      FINISH_ASSIGN_LICENSES: 900,
      ASSIGN_ENTERPRISE_LICENSES: 1000,
      SET_UP_SECURITY_CONTACTS: 1100,

      // Value discovery tasks.
      ACCEPT_FREE_OFFERS: 1200,
      ADD_ADMINS: 1300,
      CREATE_PACKAGES: 1400,
      CHANGE_CONTRACT_OWNERS: 1500,
      VIEW_REPORTS: 1600,
      SET_POLICIES: 1700,
      BOOK_EXPERT_SESSION: 1800,
      VISIT_ADOBE_IO: 1900,
      CREATE_USER_GROUPS: 2000,
      VISIT_USER_GUIDE: 2100,
      /* eslint-enable sort-keys */
    })
    .constant('HVA_TYPE', {
      VALUE_DISCOVERY: 'ValueDiscovery',
    })
    .constant('SVG_CLASS', {
      ADD_ADMINS: 'add-admins',
      ADOBE_IO: 'adobe-io',
      ASSIGN_LICENSES: 'assign-licenses',
      BUY_PRODUCTS: 'buy-products',
      CONTRACT_OWNERS: 'contract-owners',
      CREATE_PACKAGES: 'create-packages',
      EXPERT_SESSION: 'expert-session',
      IDENTITY: 'identity',
      OFFER_SWITCH_MIGRATION: 'offer-switch-migration',
      POLICIES: 'policies',
      REPORTS: 'reports',
      TAKE_TOUR: 'take-tour',
      USER_GROUPS: 'user-groups',
    });
})();
