import React from 'react';

import JitRuleAutoCreateButton from './JitRuleAutoCreateButton';
import JitRuleAutoCreateProvider from './JitRuleAutoCreateProvider';
import JitRuleAutoCreateWidget from './JitRuleAutoCreateWidget';

const WIDGET_ID = 'jit-rule-auto-create';

const widgetComponents = {
  buttons: [<JitRuleAutoCreateButton key={1} />],
  Provider: JitRuleAutoCreateProvider,
  Widget: JitRuleAutoCreateWidget,
};

export {WIDGET_ID, widgetComponents};
