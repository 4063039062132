/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated replaced by the Pandora Add Products Modal
   *
   * @ngdoc factory
   * @name app.core.product.purchase:ProductPurchaseHelper
   * @description helper factory for the purchase workflow
   */
  angular
    .module('app.core.product.purchase')
    .factory('ProductPurchaseHelper', ProductPurchaseHelper);
  /* @ngInject */
  function ProductPurchaseHelper(
    $translate,
    _,
    binkyOfferPerTermPriceFilter,
    CART_SERIALIZE,
    LICENSE_WARNING_PERCENTAGE_THRESHOLD,
    Offer,
    PRODUCT_BUCKET,
    ScorecardGroupItem
  ) {
    const service = {
      constructLicenseScorecardItem,
      convertCartItemsToLocationSearch,
      convertLocationSearchToCartItems,
      getARRDroppedPercentageForRenewalOrder,
      getDroppedPercentageForOffers,
      getProductBuckets,
      getSortPropertyForBucket,
      isOfferPriceDifferentFromProduct,
      offerIncludesText,
    };

    return service;

    //////////////

    /**
     * @description Transforms a search object to the cart items array.
     *  Given a search object as returned by $location.search() containing the items representation,
     *  it will return an array of offers.
     *  The keys of the $location.search() object should be of the form:
     *     * items[<index>][id], representing the offer id
     *     * items[<index>][q], representing the quantity
     *  In returned object, each offer/product is represented by the offer id and the quantity.
     *  If quantity is not present in the search object, if will default to 1.
     * @param {Object} locationSearchObject - The search object as returned by $location.search()
     *                      Example: {
     *                        items[0][id]: '0159BEA90DDD83C74FC928EE249071B1',
     *                        items[0][q]: 2
     *                        items[1][id]: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                        items[1][q]: 5
     *                      }
     * @returns {Array<Object>} an array of cart items or undefined if falsy cart items
     *                      Example: [{
     *                        offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                        quantity: 2
     *                      }, {
     *                        offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                        quantity: 5
     *                      }]
     */
    function convertLocationSearchToCartItems(locationSearchObject) {
      // Parse Items
      let cartItems = [];
      const queryParamToKey = {
        [CART_SERIALIZE.OFFER_ID_PARAM]: 'offerId',
        [CART_SERIALIZE.QUANTITY_PARAM]: 'quantity',
      };
      // Pattern: items[<digits>][<characters>]
      const itemRegEx = new RegExp(`${CART_SERIALIZE.ITEM_PARAM}\\[(\\d+)]\\[(.+)]`);
      _.forEach(locationSearchObject, (itemValue, itemKey) => {
        const match = itemRegEx.exec(itemKey);
        if (match) {
          const index = parseInt(match[1], 10);
          const queryParam = match[2];
          const key = queryParamToKey[queryParam] || queryParam;
          // Initialize cart item with default quantity
          if (!_.get(cartItems, `${index}`)) {
            _.set(cartItems, `${index}.quantity`, 1);
          }
          // Sanitize the value
          const value = key === 'quantity' ? Number(itemValue) || 1 : itemValue;
          _.set(cartItems, `${index}.${key}`, value);
        }
      });

      cartItems = _.reduce(cartItems, sanitizeCartList, []);

      return _.isEmpty(cartItems) ? undefined : cartItems;

      ////////////

      function isValidCartItem({offerId, quantity} = {}) {
        return _.isString(offerId) && !_.isEmpty(offerId) && quantity >= 1;
      }

      function sanitizeCartList(sanitizedArray, cartItem) {
        if (isValidCartItem(cartItem)) {
          const indexOfItem = _.findIndex(sanitizedArray, ['offerId', cartItem.offerId]);
          if (indexOfItem > -1) {
            _.assign(sanitizedArray[indexOfItem], cartItem);
          } else {
            sanitizedArray.push(cartItem);
          }
        }
        return sanitizedArray;
      }
    }

    /**
     * @description Transforms a cart items array into its items representation in a search object.
     *  Given the cart items array it will return a serialized version, similar to the one
     *  returned by $location.search() containing items represenation.
     *  In the object, each offer/product is represented by the offer id and quantity.
     *  If quantity is not present in the search object, if will default to 1.
     * @param {Array<Object>} cartItems - an array of cart items. To defined the offer id, offerId or
     *                      offer_id are accepted. For the count, quantity and numberSelected can bu used.
     *                      Example: [{
     *                        offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                        quantity: 2
     *                      }, {
     *                        offer_id: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                        numberSelected: 5
     *                      }]
     * @returns {Object} The search object representation of the cart items.
     *                      Example: {
     *                        items[0][id]: '0159BEA90DDD83C74FC928EE249071B1',
     *                        items[0][q]: 2
     *                        items[1][id]: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                        items[1][q]: 5
     *                      }
     */
    function convertCartItemsToLocationSearch(cartItems) {
      let index = 0;
      const locationSearchObject = _.reduce(
        cartItems,
        (resultObj, item) => {
          const offerId = item.offer_id || item.offerId;
          if (offerId) {
            const itemParam = `${CART_SERIALIZE.ITEM_PARAM}[${index}]`;
            resultObj[`${itemParam}[${CART_SERIALIZE.OFFER_ID_PARAM}]`] = offerId;
            resultObj[`${itemParam}[${CART_SERIALIZE.QUANTITY_PARAM}]`] =
              item.numberSelected || item.quantity || 1; // Default to 1 for falsy as quantity should not be 0
            index += 1;
          }
          return resultObj;
        },
        {}
      );
      return locationSearchObject;
    }

    /**
     * @description Construct a license scorecard item from an offer. Rather than using the unit which results in a
     *   sentence case label, the label 'licenses used' will be provided
     *
     * @param {Offer} offer - The offer to use to construct the scorecard. The offer should have provisionedQuantity
     *   and assignableLicenseCount set.
     * @param {Boolean} [positiveScorecard] - represents rendering a variant of a scorecard-group-item component
     * @returns {ScorecardGroupItem} a ScorecardGroupItem for a license
     */
    function constructLicenseScorecardItem(offer, positiveScorecard) {
      if (positiveScorecard) {
        const remainingLicensesToAssign = offer.assignableLicenseCount - offer.provisionedQuantity;

        return ScorecardGroupItem.construct({
          label: $translate.instant('core.product.purchase.licensesToBeAssigned'),
          positiveScorecard,
          score: remainingLicensesToAssign,
          total: offer.assignableLicenseCount,
        });
      }

      return ScorecardGroupItem.construct({
        label: $translate.instant('core.product.purchase.licensesUsed'),
        percentageThreshold: LICENSE_WARNING_PERCENTAGE_THRESHOLD,
        score: offer.provisionedQuantity,
        total: offer.assignableLicenseCount,
      });
    }

    /**
     * @description Calculates the percentage of the ARR (Annual Recurring Revenue) dropped each time a pass is made
     *   thru the renewal workflow.
     *
     * @param {Object} currentTotal - the initial billingSummaryTotal without tax returned by ccs when
     *   the renewal order is initially fetched upon entering the renewal workflow. The first time the renewal
     *   workflow is entered this will be the contract's nextBillingAmount but for subsequent passes it will reflect
     *   any pending renewal changes
     * @param {Object} newTotal - the billingSummaryTotal returned by ccs
     * @returns {Number} a number between 0-100, defaults to 0 if nextBillingAmountInfo or billingSummaryTotal is missing.
     */
    function getARRDroppedPercentageForRenewalOrder({currentTotal, newTotal}) {
      return calculateDroppedPercentage({
        currentAmount: _.get(currentTotal, 'amount_with_tax'),
        newAmount: _.get(newTotal, 'amount_with_tax'),
      });
    }

    /**
     * @description Calculates the percentage of the ARR (Annual Recurring Revenue) dropped for each non-promo offer in
     *    the cart and the current renew order each time a pass is made thru the renewal workflow.
     *    There may be promo offers in the renewal order if this isn't the first time thru this workflow.
     *
     * @param {Array<Offer>} cartItems an array of offers in the shopping cart
     * @param {Array<Offer>} renewalItems an array of offers in the renewal order at the start of this pass thru the
     *  renewal workflow
     * @returns {String} a comma-separated string of offerId:droppedPercentage pairs
     *     For example: A0D5031CE1667AC5775EDECB0ABA3617:30,3997AA939496158BBD2DB503E0D6F4A3:5
     */
    function getDroppedPercentageForOffers(cartItems, renewalItems) {
      // Remove all promo offers. These should not trigger promos with further price reductions.
      const cartOffers = removePromoOffers(cartItems);
      const renewalOffers = removePromoOffers(renewalItems);

      // Find all offers that were in the renewal order that are no longer in the cart - i.e. they've been removed.
      const renewalOffersNotInCart = _.reject(renewalOffers, (renewalOffer) =>
        _.find(cartOffers, (cartOffer) => isMatchingOffer(cartOffer, renewalOffer))
      );

      // Any offers no longer in the renewal order have a droppedPercentage of 100%.
      const offerAndDroppedPercentageArray = _.map(
        renewalOffersNotInCart,
        (offer) => `${offer.offer_id}:100`
      );

      // The items in the cart may be from the renewal order or they may have been added.
      // New products have a drop percentage of 0.
      _.forEach(cartOffers, (cartOffer) => {
        const droppedPercentage = calculateDroppedPercentage({
          currentAmount: cartOffer.currentQuantity,
          newAmount: cartOffer.numberSelected,
        });
        offerAndDroppedPercentageArray.push(`${cartOffer.offer_id}:${droppedPercentage}`);
      });

      return _.join(offerAndDroppedPercentageArray);

      //////////

      function removePromoOffers(offerList) {
        return _.reject(offerList, (offer) => offer.isOfferTypePromotion());
      }
    }

    /**
     * @description helper function to seperate offers into four buckets
     *
     * @param {Object} options the options
     * @param {Array<Object>} [options.cartItems] an array of cart items
     *                      Example: [{
     *                        offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                        productArrangementCode: 'ilst_direct_indirect_team',
     *                        quantity: 2
     *                      }, {
     *                        offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                        productArrangementCode: 'phsp_direct_indirect_team',
     *                        quantity: 5
     *                      }]
     * @param {CUSTOMER_SEGMENT} [options.customerSegment] customer segment to use to filter the offers
     * @param {OfferList} options.offerList the offerList object. Array of offers is in options.offerList.items
     * @param {Array<Product>} options.productItems array of products owned by the contract
     * @returns {Object} an object containing four product buckets, each bucket is an array of offers.
     *  Details for the product buckets:
     *  1. {Array<Offer>} cartItems: offers in the shopping cart
     *  2. {Array<Offer>} ownedOffers: owned products minus offers in the shopping cart
     *  3. {Array<Offer>} unownedWithRecOffers: ("all offers" minus "offers in the shopping cart" minus "owned products")
     *                                       && items match the recommended offer criteria
     *  4. {Array<Offer>} unownedWithoutRecOffers: ("all offers" minus "offers in the shopping cart" minus "owned products")
     *                                                               && items don't match the recommended offer criteria
     */
    function getProductBuckets(options) {
      const {customerSegment, offerList, productItems} = options;

      populateOfferListItems(offerList, productItems);
      const cartItems = transformCartItems(offerList.items, options.cartItems);
      const filteredOfferItems = getFilteredOfferItems(offerList, cartItems, customerSegment);
      const offersSplitByProductItems = splitOffersByOwnership(filteredOfferItems, productItems);
      const offersSplitByRecommendation = splitOffersByRecommendation(
        offersSplitByProductItems.unownedOffers
      );

      return {
        cartItems,
        ownedOffers: offersSplitByProductItems.ownedOffers,
        unownedWithoutRecOffers: offersSplitByRecommendation.offersWithoutRecOffers,
        unownedWithRecOffers: offersSplitByRecommendation.offersWithRecOffers,
      };
    }

    /**
     * @description Get the sort property for the bucket. Defaults to sort by poresOrder.
     *
     * @param {PRODUCT_BUCKET} [productBucket] - The bucket the product belongs to
     * @returns {String} the property to sort the bucket
     */
    function getSortPropertyForBucket(productBucket) {
      switch (productBucket) {
        case PRODUCT_BUCKET.OWNED_OFFERS:
          return 'merchandising.copy.name';
        case PRODUCT_BUCKET.UNOWNED_WITH_REC_OFFERS:
        case PRODUCT_BUCKET.UNOWNED_WITHOUT_REC_OFFERS:
        default:
          return 'poresOrder';
      }
    }

    /**
     * @description Returns true if the offer's lower-cased name or lower-cased description includes the searchStr.
     *
     * @param {Offer} offer The offer object.
     * @param {String} searchStr The lowercase search string.
     * @returns {Boolean} True if the searchStr is found in the offer's name or description.
     *   False otherwise, including if searchStr is empty or undefined.
     */
    function offerIncludesText(offer, searchStr) {
      const name = _.get(offer, 'merchandising.copy.name');
      const description = _.get(offer, 'merchandising.copy.description');

      return searchStr && (includesText(name, searchStr) || includesText(description, searchStr));

      function includesText(value, searchText) {
        return _(value).toLower().includes(searchText);
      }
    }

    ////////

    function calculateDroppedPercentage({currentAmount, newAmount}) {
      if (_.isNumber(currentAmount) && _.isNumber(newAmount)) {
        return _.chain(((currentAmount - newAmount) / currentAmount) * 100)
          .round()
          .clamp(0, 100)
          .value();
      }
      return 0;
    }

    /**
     * @description Get an array of offers by applying customer segment filter, rejecting undefined product name,
     *              and removing the offers in the shopping cart
     *
     * @param {OfferList} offerList The offerList object
     * @param {Array<Offer>} cartItems array of offers in the shopping cart
     * @param {CUSTOMER_SEGMENT} [customerSegment] customer segment to filter by
     * @returns {Array<Offer>} array of filtered offers
     */
    function getFilteredOfferItems(offerList, cartItems, customerSegment) {
      // offerList.getOffersForSegment returns an array of offers
      const offersFilteredByCustomerSegment = offerList.getOffersForSegment(customerSegment);
      return _(offersFilteredByCustomerSegment)
        .reject(offerWithoutProductNameFilter)
        .pullAllWith(cartItems, isMatchingOffer)
        .value();
    }

    /**
     * @description Helper function to compare whether two offers matched based on
     *              the offer_id or the product_arrangement_code
     *
     * @param {Offer} offerA first item to be compared
     * @param {Offer | Product | Object} itemB second item to be compared
     * @returns {Boolean} whether two offers match
     */
    function isMatchingOffer(offerA, itemB) {
      // Since Offer object is in snake case, we want to map the keys of the non-Offer object
      // to snakeCase before comparing them
      const offerB =
        itemB instanceof Offer
          ? itemB
          : _(itemB)
              .pick(['offerId', 'productArrangementCode'])
              .mapKeys((value, key) => _.snakeCase(key))
              .value();

      return (
        offerA.offer_id === offerB.offer_id ||
        offerA.product_arrangement_code === offerB.product_arrangement_code
      );
    }

    /**
     * @description Determine if the pricing change tooltip should be shown to the user beside the market price.
     * There are two conditions where we might show the tooltip :-
     * - The currently owned product has more than one pricing entry (the user has paid different prices for this product in the past)
     * - The currently owned product has a single pricing entry but the price varies from the price offered to the user today
     * @param {Product} product - The Product owned by the Organization with pricing information
     * @param {Offer} offer - The offer for this Product currently
     * @param {String} offerPrice - The rendered offer price to compare the pricing data to
     * @returns {Boolean} True if the rendered offer price differs from any of the product price points
     */
    function isOfferPriceDifferentFromProduct(product, offer, offerPrice) {
      if (!product || !offer) {
        return false;
      }

      const pricing = _.get(product, 'pricing');
      const currency = _.get(product, 'pricing.currency');
      const taxKey = offer.priceIncludesTax() ? 'amountWithTax' : 'amountWithoutTax';

      if (!pricing) {
        return false;
      }

      return _.some(pricing.items, (item) => {
        const amount = _.get(item, `priceDetails.unit.${taxKey}`);
        // Ensure that the same license terms are used - if offer.isOrganizationDelegationType() is false
        // then ensure "per license" is appended to the displayed price. This replicates the behaviour in
        // binkyOfferPerTermPriceFilter().
        const perLicense = _.result(offer, 'isOrganizationDelegationType', false) === false;

        if (!_.isFinite(amount) || !currency) {
          return false;
        }

        const pseudoOffer = {
          pricing: {
            currency,
            prices: [
              {
                price_details: {
                  display_rules: {
                    price: amount,
                  },
                },
              },
            ],
          },
          term: offer.term,
        };

        return binkyOfferPerTermPriceFilter(pseudoOffer, {perLicense}) !== offerPrice;
      });
    }

    /**
     * @description Populate assignableLicenseCount, availableLicenseCount, and provisionedQuantity from productItems to offerList
     *
     * @param {offerList} offerList the offerList
     * @param {Array<Product>} productItems array of products
     */
    function populateOfferListItems(offerList, productItems) {
      _.forEach(offerList.items, (offer) => {
        const productFound = _.find(productItems, (product) => isMatchingOffer(offer, product));

        if (productFound) {
          _.assign(offer, {
            assignableLicenseCount: productFound.getAssignableLicenseCount(), // total owned license count
            availableLicenseCount: productFound.getAvailableLicenseCount(), // unassigned license count
            productBucket: PRODUCT_BUCKET.OWNED_OFFERS,
            provisionedQuantity: productFound.provisionedQuantity, // assigned license count
          });
        } else {
          _.assign(offer, {
            productBucket: PRODUCT_BUCKET.UNOWNED_WITHOUT_REC_OFFERS,
          });
        }
      });
    }

    /**
     * @description split an array of offers into ownedOffers and unownedOffers.
     *
     * @param {Array<Offer>} offerItems array of offers to be split
     * @returns {Object} an object containing two arrays of cloned offers: ownedOffers and unownedOffers
     */
    function splitOffersByOwnership(offerItems) {
      const ownedOffers = [];
      const unownedOffers = [];

      _.forEach(offerItems, (offer) => {
        const clonedOffer = _.cloneDeep(offer);

        if (offer.productBucket === PRODUCT_BUCKET.OWNED_OFFERS) {
          ownedOffers.push(clonedOffer);
        } else {
          unownedOffers.push(clonedOffer);
        }
      });

      return {
        ownedOffers: _.uniqBy(ownedOffers, 'product_arrangement_code'),
        unownedOffers: _.uniqBy(unownedOffers, 'product_arrangement_code'),
      };
    }

    /**
     * @description split an array of offers into offersWithRecOffers and offersWithoutRecOffers
     *
     * @param {Array<Offer>} offerItems array of offers to be split
     * @returns {Object} an object containing two arrays of cloned offers: offersWithRecOffers and offersWithoutRecOffers
     */
    function splitOffersByRecommendation(offerItems) {
      const sortProperty = getSortPropertyForBucket();
      const offerItemsSortedByPoresOrderAsc = _.sortBy(offerItems, [sortProperty]);
      // Top 3 offers in the offerItems become the recommended offers.
      const topThreeOffers = _.slice(offerItemsSortedByPoresOrderAsc, 0, 3);
      const restOfTheOffers = _.slice(offerItemsSortedByPoresOrderAsc, 3);

      const offersWithRecOffers = _.map(topThreeOffers, (offer) => {
        offer.productBucket = PRODUCT_BUCKET.UNOWNED_WITH_REC_OFFERS;
        return offer;
      });

      const offersWithoutRecOffers = _.map(restOfTheOffers, (offer) => {
        offer.productBucket = PRODUCT_BUCKET.UNOWNED_WITHOUT_REC_OFFERS;
        return offer;
      });

      return {
        offersWithoutRecOffers,
        offersWithRecOffers,
      };
    }

    /**
     * @description transform the cartItems into an array of offers if the cart item is not an instance of Offer
     *
     * @param {Array<Offer>} offerItems array of offers
     * @param {Array<Object>} cartItems array of object in the shopping cart, refer to options.cartItems in getProductBuckets
     * @returns {Array<Offer>} the array of cloned offers in the shopping cart
     */
    function transformCartItems(offerItems, cartItems = []) {
      const items = [];
      // Transform the cartItems if it's not an array of offers
      if (!(cartItems[0] instanceof Offer)) {
        _.forEach(cartItems, (cartItem) => {
          const clonedMatchingOffer = _.chain(offerItems)
            .find((offer) => isMatchingOffer(offer, cartItem))
            .cloneDeep()
            .value();

          if (clonedMatchingOffer) {
            clonedMatchingOffer.numberSelected = cartItem.quantity;
            clonedMatchingOffer.currentQuantity = cartItem.quantity; // to calc % change between current and new
            items.push(clonedMatchingOffer);
          } else {
            throw new TypeError(
              `ProductPurchaseHelper.transformCartItems: unable to find matching offer for cartItem with offerId ${cartItem.offerId}, productArrangementCode ${cartItem.productArrangementCode}`
            );
          }
        });
      }
      return items;
    }

    function offerWithoutProductNameFilter(offer) {
      return !_.has(offer, 'merchandising.copy.name');
    }
  }
})();
/* eslint-enable max-lines */
