import {Text, View} from '@adobe/react-spectrum';
import {looksLikeAnEmail} from '@pandora/administration-utils';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import TagField from '@react/react-spectrum/TagField';
import {Tag} from '@react/react-spectrum/TagList';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import styles from './EmailTagList.pcss';

/**
 * A Tag component wrapper that includes email validation and adapting the
 * appearance whether the tag is valid or not.
 */

const EmailTag = (tag, index) => {
  const isInvalid = !looksLikeAnEmail(tag);

  return (
    <Tag
      key={`email-field-tag-${index}`}
      className={isInvalid ? styles['error-tag'] : undefined}
      icon={
        isInvalid ? <AlertIcon color="negative" flexShrink="0" marginEnd="size-100" /> : undefined
      }
      invalid={isInvalid}
    >
      {tag}
    </Tag>
  );
};

/**
 * Tag field specifically for adding emails. Includes validation and count limitations
 */
const EmailTagList = ({emailCompletionList = [], maxEmails = 10, onEmailAdded}) => {
  const intl = useIntl();
  const [canAddMoreEmails, setCanAddMoreEmails] = useState(true);
  const [hasInvalidEmails, setHasInvalidEmails] = useState(false);

  const onChange = (emailList) => {
    const remaining = maxEmails - emailList.length;
    const isListInvalid = emailList.some((email) => !looksLikeAnEmail(email));

    setHasInvalidEmails(isListInvalid);
    setCanAddMoreEmails(remaining > 0);

    onEmailAdded({emailList, isListInvalid});
  };

  return (
    <>
      <FieldLabel
        label={intl.formatMessage(
          {id: 'account.billingHistory.emailTagList.addEmailsLabel'},
          {maxEmails}
        )}
        labelFor="email-tag-list"
      />
      <TagField
        allowCreate={canAddMoreEmails}
        className={styles['tag-field']} // doesn't take UNSAFE_className https://react-spectrum.corp.adobe.com/components/TagField
        getCompletions={() => (canAddMoreEmails ? emailCompletionList : [])} // completions will allow tag creation ignoring allowCreate prop
        id="email-tag-list"
        onChange={onChange}
        renderTag={EmailTag}
      />
      <View marginTop="size-100">
        {hasInvalidEmails ? (
          <Text
            data-testid="email-tag-list-email-error"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- adjusting font size and color per spec
            UNSAFE_className={styles['tag-message-error']}
          >
            {intl.formatMessage({id: 'account.billingHistory.emailTagList.invalidEmail'})}
          </Text>
        ) : (
          <Text
            data-testid="email-tag-list-email-message"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- adjusting font size per spec
            UNSAFE_className={styles['tag-message-example']}
          >
            {intl.formatMessage({id: 'account.billingHistory.emailTagList.message'})}
          </Text>
        )}
      </View>
    </>
  );
};

EmailTagList.propTypes = {
  /**
   * An array of emails to be suggested
   */
  emailCompletionList: PropTypes.arrayOf(PropTypes.string),
  /**
   * The maximum number of emails to be able to add to the list
   */
  maxEmails: PropTypes.number,
  /**
   * Callback that returns the list emails that have been added to the list
   */
  onEmailAdded: PropTypes.func.isRequired,
};

export default EmailTagList;
