import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import FolderIcon from 'common/components/folder-icon/FolderIcon';

/**
 * A folder icon and name, formatted to be placed in a TableView Cell.
 * This assumes the cell has enough width to display the folder icon and at least some text before
 * the text wraps.
 */
const FolderIconWithName = ({children}) => (
  <Flex alignItems="center" direction="row" gap="size-200">
    <FolderIcon />
    <Text minWidth={0} UNSAFE_style={{overflowWrap: 'break-word'}}>
      {children}
    </Text>
  </Flex>
);

FolderIconWithName.propTypes = {
  /**
   * The name to display after the folder icon.
   */
  children: PropTypes.node.isRequired,
};

export default FolderIconWithName;
