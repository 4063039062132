import {TEAM_CALL_CENTER_PHONE_NUMBER} from './ContactSalesConstants';

/**
 * @description Util method to obtain the sales contact phone number for a given country code.
 * @param {string} countryCode - Two character country code identifier.
 * @returns {string} Phone number to contact sales.
 */
function getTeamPhoneNumber(countryCode) {
  return (
    TEAM_CALL_CENTER_PHONE_NUMBER[countryCode.toUpperCase()] || TEAM_CALL_CENTER_PHONE_NUMBER.ROW
  );
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {getTeamPhoneNumber};
