(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.link-card').component('appLinkCard', {
    bindings: {
      buttonText: '@?',
      description: '@',
      goUrl: '@',
      icon: '@',
      onButtonClick: '<',
    },
    templateUrl: 'app/widgets/common/link-card/link-card.component.html',
  });
})();
