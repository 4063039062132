(function () {
  /**
   * @deprecated this has been ported to src2 to <SupportTicketContactsSection>
   */
  angular.module('app.widgets.support').component('appSupportTicketContactsSection', {
    bindings: {
      supportTicket: '<',
      supportTicketAgentAssignedKey: '@',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-contacts-section/support-ticket-contacts-section.component.html',
  });

  function controller(
    _,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    onesieSrc2,
    panelManager,
    SUPPORT_TICKET_CONTACTS_MODAL_ID
  ) {
    const vm = this;

    _.assign(vm, {
      isOpen: false,
      onClickUpdateContacts,
      SupportTicketContactsModalWrapper:
        onesieSrc2.support.components.SupportTicketContactsModalWrapper,
    });

    function onClickUpdateContacts() {
      if (feature.isEnabled('temp_react_port_contacts_modal')) {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.support.components.SupportTicketContactsModalWrapper,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                isOpen: true,
                onClose: _.noop,
                onSuccess: _.noop,
                supportTicket: vm.supportTicket,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        panelManager.open(SUPPORT_TICKET_CONTACTS_MODAL_ID, {caseType: vm.caseType});
      }
    }
  }
})();
