import rootStore from 'core/RootStore';
import jilTrusts from 'features/settings/api/jilTrusts';

/**
 * Function that makes a PATCH call to the JIL trusts endpoint.
 * See useTrustList.js and useTrustRequests.js for usage.
 */
const trustsBatchOperation = async (operations) => {
  const response = await jilTrusts.patchTrusts({
    operations,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  const failed = [];
  const successful = [];

  response.data.forEach((item) => {
    if (item.responseCode >= 200 && item.responseCode < 300) {
      successful.push(item.response);
    } else {
      failed.push(item.response);
    }
  });

  return {
    failed,
    successful,
  };
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {trustsBatchOperation};
