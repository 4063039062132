(function () {
  /**
   * @ngdoc module
   * @name app.core.storage-report-folder
   * @description Module which defines storage-report-folder related models
   */
  angular.module('app.core.storage-report-folder', [
    'app.core.api.jil',
    'app.core.message',
    'app.core.model-cache',
    'app.core.storage-quota',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.common.list.state.alpha',
    'binky.core.lodash',
  ]);
})();
