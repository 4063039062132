import {Button, Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {SUPPORT_TICKET_TYPE} from 'features/notifications/notifications.constants';

const NotificationButtons = ({onClickButton, payload}) => {
  const intl = useIntl();

  return (
    <Flex marginY="size-150">
      {payload.support_case_survey_url && (
        <View marginEnd="size-150">
          <Button
            aria-describedby={intl.formatMessage({
              id: 'notifications.card.button.takeSurvey.describedby',
            })}
            data-testid="take-survey-button"
            onPress={() => onClickButton({surveyUrl: payload.support_case_survey_url})}
            variant="primary"
          >
            <FormattedMessage id="notifications.card.button.takeSurvey.label" />
          </Button>
        </View>
      )}
      {payload.support_case_id && (
        <View marginEnd="size-150">
          <Button
            aria-describedby={intl.formatMessage({
              id: 'notifications.card.button.viewCase.describedby',
            })}
            data-testid="view-case-button"
            onPress={() =>
              onClickButton({
                expertSessionId:
                  payload?.case_type === SUPPORT_TICKET_TYPE.EXPERT_SESSION
                    ? payload.support_case_id
                    : undefined,
                supportCaseId: payload.support_case_id,
              })
            }
            variant="primary"
          >
            <FormattedMessage id="notifications.card.button.viewCase.label" />
          </Button>
        </View>
      )}
      {payload.expert_session_id && (
        <View marginEnd="size-150">
          <Button
            aria-describedby={intl.formatMessage({
              id: 'notifications.card.button.viewExpertSession.describedby',
            })}
            data-testid="view-expert-session-button"
            onPress={() => onClickButton({expertSessionId: payload.expert_session_id})}
            variant="primary"
          >
            <FormattedMessage id="notifications.card.button.viewExpertSession.label" />
          </Button>
        </View>
      )}
    </Flex>
  );
};

NotificationButtons.propTypes = {
  onClickButton: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - payload
  payload: PropTypes.object.isRequired,
};

export default NotificationButtons;
