import {dispatchUiEventAnalytics, log} from '@admin-tribe/binky';

/**
 * @description send click analytics for quick link clicks
 *
 * @param options Analytics properties to pass to dispatchUiEventAnalytics when
 *   the quick link is clicked.
 *
 * @param options.analyticsAction The business action being taken when this
 *   quick link is clicked. This is used to set the eventAction, eventName, and
 *   interaction.click in the call to dispatchUiEventAnalytics.
 *
 *   The string is prefixed with quick-link- (so that does not need to be done
 *   in the parent). Eg: go-to-account => the eventName will be
 *   quick-link-go-to-account.
 */
function dispatchAnalytics(options) {
  if (!options) {
    log.error('dispatchAnalytics called without options');
    return;
  }
  const {analyticsAction: method, ...otherOptions} = options;
  dispatchUiEventAnalytics({
    eventAction: 'click',
    eventName: `quick-link-${method}`,
    interaction: {
      click: `quick-link-${method}`,
    },
    ...otherOptions,
  });
}

// eslint-disable-next-line import/prefer-default-export -- currently only has 1
export {dispatchAnalytics};
