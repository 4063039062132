import {
  CONTRACT_BILLING_FREQUENCY,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  Product,
} from '@admin-tribe/binky';
import {BOLD_PRICE_STYLES} from '@admin-tribe/binky-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {TrialBadge} from '@pandora/react-badges';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import {InlinePrice, StrikethroughPrice} from '@pandora/react-price';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import CartItem from 'common/components/cart/CartItem';
import SafePrice from 'common/components/safe-price/SafePrice';

import SignedPrice from '../signed-price/SignedPrice';

import styles from './SelfCancelCartItem.pcss';
import CustomStrikethroughPrice from './StrikethroughPrice.pcss';

const ITEM_PREFIX = 'account.selfCancel.components.selfCancelCartItem';
const ROW_TYPES = {
  CANCELLING: 'cancelling',
  CURRENT: 'current',
  REMAINING: 'remaining',
  SUMMARY: 'summary',
};

/* Cart item for individual units to be removed from the contract including the summary of pricing before and after */
// eslint-disable-next-line complexity -- due
const SelfCancelCartItem = ({
  cancellingCount,
  cancellingTotal,
  currency,
  currentCount,
  currentTotal,
  discountPercentage,
  discountPricePerUnit,
  remainingCount,
  remainingTotal,
  perUnit,
  pricePerUnit,
  product,
  recurrenceTerm = CONTRACT_BILLING_FREQUENCY.MONTHLY,
  withSubtotalPerItem = false,
}) => {
  const intl = useIntl();

  const rows = [
    {count: currentCount, price: currentTotal, row: ROW_TYPES.CURRENT},
    {count: cancellingCount, price: cancellingTotal, row: ROW_TYPES.CANCELLING},
    {count: remainingCount, price: remainingTotal, row: ROW_TYPES.REMAINING},
  ];

  const hasCancellingTotal = typeof cancellingTotal !== 'undefined';
  const hasRemainingTotal = typeof remainingTotal !== 'undefined';
  const hasRemainingCount = typeof remainingCount !== 'undefined';

  return (
    <CartItem
      currency={currency}
      perUnit={perUnit}
      price={pricePerUnit}
      productIcon={product?.getIcon()}
      productName={product?.longName}
      recurrenceTerm={recurrenceTerm}
      subtitle={
        currency && discountPricePerUnit > 0 ? (
          <>
            <SafePrice
              currency={currency}
              data-testid="original-unit-price"
              overrideStyles={CustomStrikethroughPrice}
              price={pricePerUnit}
              PriceComponent={StrikethroughPrice}
            />
            <SafePrice
              currency={currency}
              data-testid="discounted-unit-price"
              overrideStyles={styles}
              perUnit={perUnit}
              price={discountPricePerUnit}
              PriceComponent={InlinePrice}
              recurrenceTerm={recurrenceTerm}
            />
          </>
        ) : undefined
      }
    >
      {discountPercentage > 0 && (
        <View
          data-testid="discount-badge"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
          UNSAFE_className={styles['discount-badge']}
        >
          <ContentEntryProvider
            value={{
              label: intl.formatMessage(
                {
                  id: 'account.selfCancel.components.selfCancelCartItem.discount',
                },
                {discountPercentage}
              ),
            }}
          >
            <TrialBadge
              data={{value: 1}} // value is needed in order to use TrialBadge. The actual label is passed to ContentEntryProvider.
            />
          </ContentEntryProvider>
        </View>
      )}
      <View>
        <Flex justifyContent="space-between" width={withSubtotalPerItem ? undefined : '67%'}>
          <Flex direction="column">
            {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- reduces size and color */}
            <Text data-testid="current-remaining-key" UNSAFE_className={styles.label}>
              {intl.formatMessage({
                id: hasRemainingCount ? `${ITEM_PREFIX}.remaining` : `${ITEM_PREFIX}.currentTotal`,
              })}
            </Text>
            <Text data-testid={`${ROW_TYPES.SUMMARY}-key`}>
              {intl.formatMessage(
                {
                  id: `${ITEM_PREFIX}.${ROW_TYPES.SUMMARY}.${perUnit.toLowerCase()}`,
                },
                {
                  b: (chunks) => <span className={styles['bold-subtext']}>{chunks}</span>,
                  count: hasRemainingCount ? remainingCount : currentCount,
                }
              )}
            </Text>
          </Flex>
          {!withSubtotalPerItem && cancellingCount > 0 && (
            <Flex data-testid="cancelling-count" direction="column">
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- reduces size and color */}
              <Text data-testid="cancelling-count-key" UNSAFE_className={styles.label}>
                {intl.formatMessage({
                  id: `${ITEM_PREFIX}.cancelling`,
                })}
              </Text>
              <Text data-testid="cancelling-count-value">
                {intl.formatMessage(
                  {
                    id: `${ITEM_PREFIX}.${ROW_TYPES.SUMMARY}.${perUnit.toLowerCase()}`,
                  },
                  {
                    b: (chunks) => <span className={styles['bold-subtext']}>{chunks}</span>,
                    count: cancellingCount,
                  }
                )}
              </Text>
            </Flex>
          )}
          {withSubtotalPerItem && (
            <SafePrice
              currency={currency}
              data-testid={`${ROW_TYPES.SUMMARY}-value`}
              overrideStyles={BOLD_PRICE_STYLES}
              price={hasRemainingTotal ? remainingTotal : currentTotal}
              recurrenceTerm={recurrenceTerm}
            />
          )}
        </Flex>
      </View>
      {withSubtotalPerItem && hasCancellingTotal && hasRemainingTotal && (
        <SpectrumV2Provider className={styles.accordion}>
          <Accordion
            aria-label={intl.formatMessage(
              {id: 'account.selfCancel.components.selfCancelCartItem.accordion.ariaLabel'},
              {product: product?.longName}
            )}
          >
            <AccordionItem
              ariaLevel={4}
              header={intl.formatMessage({id: `${ITEM_PREFIX}.viewDetails`})}
            >
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- adds a border */}
              <View paddingTop="size-100" UNSAFE_className={styles.summary}>
                {rows.map(({count, row, price}) => (
                  <Flex
                    key={row}
                    justifyContent="space-between"
                    marginTop={row === ROW_TYPES.REMAINING ? 'size-200' : undefined}
                    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- To style text to orange
                    UNSAFE_className={styles[`${row}-row`]}
                  >
                    <Text data-testid={`${row}-key`}>
                      {intl.formatMessage(
                        {
                          id: `${ITEM_PREFIX}.${row}.${perUnit.toLowerCase()}`,
                        },
                        {
                          b: (chunks) => <span className={styles['bold-subtext']}>{chunks}</span>,
                          count,
                        }
                      )}
                    </Text>
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- used to ensure negative sign is inline */}
                    <View data-testid={`${row}-value`} UNSAFE_className={styles.price}>
                      <SignedPrice
                        currency={currency}
                        isNegative={row === ROW_TYPES.CANCELLING}
                        overrideStyles={
                          (row === ROW_TYPES.REMAINING && BOLD_PRICE_STYLES) || undefined
                        }
                        price={price}
                        recurrenceTerm={recurrenceTerm}
                      />
                    </View>
                  </Flex>
                ))}
              </View>
            </AccordionItem>
          </Accordion>
        </SpectrumV2Provider>
      )}
    </CartItem>
  );
};

SelfCancelCartItem.propTypes = {
  /**
   * The number of items of the product to be cancelled
   */
  cancellingCount: PropTypes.number,
  /**
   * The decimal price amount of the items being cancelled to be taken off of the contract
   */
  cancellingTotal: PropTypes.number,
  /**
   * The currency object where formatString and usePrecision are provided
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values
     */
    usePrecision: PropTypes.bool,
  }).isRequired,
  /**
   * The current count of product units available to be cancelled
   */
  currentCount: PropTypes.number.isRequired,
  /**
   * The current cost of an item billed to the contract prior to cancellation
   */
  currentTotal: PropTypes.number,
  /**
   * Discount percentage provided for the product
   */
  discountPercentage: PropTypes.number,
  /**
   * Discount price provided for the product in decimal form
   */
  discountPricePerUnit: PropTypes.number,
  /**
   * The unit of the item that is being cancelled (i.e. Licenses)
   */
  perUnit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)),
  /**
   * The price per unit in decimal form
   */
  pricePerUnit: PropTypes.number.isRequired,
  /**
   * The product model of the item being cancelled
   */
  product: PropTypes.instanceOf(Product).isRequired,
  /**
   * The billing recurrence of the contract (e.g. `/mo` or `/yr`)
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The remaining number of units after cancellation
   */
  remainingCount: PropTypes.number,
  /**
   * The price billed to the contract after units are removed
   */
  remainingTotal: PropTypes.number,
  /**
   * Whether to show subtotal and Cancelling Details accordion section. per line item.
   * Defaults to false.
   */
  withSubtotalPerItem: PropTypes.bool,
};

export default SelfCancelCartItem;
