import {computed, makeObservable} from 'mobx';

const DOMAIN_STATUS = {
  FREE: 'FREE',
  INVALID: 'INVALID',
  LINKED: 'LINKED',
};

const DETAILED_DOMAIN_STATUS = {
  DOMAIN_ALREADY_CLAIMED: 'DOMAIN_ALREADY_CLAIMED',
  DOMAIN_HAS_INVALID_AUTHSRC: 'DOMAIN_HAS_INVALID_AUTHSRC',
  DOMAIN_HAS_INVALID_OWNER: 'DOMAIN_HAS_INVALID_OWNER',
};

class FederatedDomainEntity {
  constructor(data) {
    makeObservable(this, {
      isFree: computed,
      isInvalid: computed,
      isLinked: computed,
    });

    this.name = data.externalDomain.name;
    this.id = this.name;
    this.status = data.status;
    this.detailedStatus = data.detailedStatus;
  }

  get isFree() {
    return this.status === DOMAIN_STATUS.FREE;
  }

  get isInvalid() {
    return this.status === DOMAIN_STATUS.INVALID;
  }

  get isLinked() {
    return this.status === DOMAIN_STATUS.LINKED;
  }
}

export default FederatedDomainEntity;
export {DOMAIN_STATUS, DETAILED_DOMAIN_STATUS};
