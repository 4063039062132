(function () {
  /**
   * @deprecated moved to src2/app/common/components/sophia/banner
   *
   * @ngdoc component
   * @name app.widgets.sophia:appSophiaBanner
   * @description component to display carousel of cards returned by SophiaContent service.
   * @param {CONTAINER_TYPE} [containerType] Optional - the container type, used for styling. Defaults to CONTAINER_TYPE.PAGE.
   * @param {object} [contextualParamsOptions] Optional contextualParamsOptions - the options to pass to the contextual params.
   *     ex: {ARRDroppedPercentage: 20}
   * @param {SURFACE_ID} [surfaceId] Optional - the Sophia surface id. Defaults to SURFACE_ID.ONE_CONSOLE.
   * @param {SOPHIA_BANNER_VARIANT} [variant] Optional variant - type of the card.
   *     The available card types are listed under src/app/widgets/sophia/card folder. Defaults to 'sophia-card'.
   */

  angular.module('app.widgets.sophia').component('appSophiaBanner', {
    bindings: {
      containerType: '@?',
      contextualParamsOptions: '<?',
      surfaceId: '@?',
      variant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/sophia/banner/sophia-banner.component.html',
  });

  function controller(_, CONTAINER_TYPE, feature, onesieSrc2) {
    /**
     * @description controller which configures contextual params and queries Sophia for offers to display.
     * @this SophiaBanner controller object and scope properties.
     * @property {Function} this.$onInit function invoked when component instance has been compiled.
     */
    const vm = this;
    _.assign(vm, {
      CONTAINER_TYPE,
      feature,
      onesieSrc2,
    });
  }
})();
