import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductUsage, ProductUsageContentModel} from '@pandora/react-product-usage';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Tooltip for displaying consumed quantity for stock variants with assigned
 * quantity specified.
 */
const StockConsumedQuantityTooltip = ({consumed, unit}) => {
  const intl = useIntl();
  const productUsageContent = useContentEntry(ProductUsageContentModel);

  return (
    <TooltipTrigger>
      <ActionButton
        aria-label={intl.formatMessage({id: 'common.stockConsumedQuantityTooltip.ariaLabel'})}
        isQuiet
      >
        <InfoOutlineIcon />
      </ActionButton>
      <Tooltip>
        <ProductUsage
          content={productUsageContent}
          shouldOverrideFontColor={false}
          unitName={unit}
          usedQuantity={consumed}
        />
      </Tooltip>
    </TooltipTrigger>
  );
};

StockConsumedQuantityTooltip.propTypes = {
  /** Consumed quantity to display */
  consumed: PropTypes.number,
  /** Unit to display */
  unit: PropTypes.string,
};

export default StockConsumedQuantityTooltip;
