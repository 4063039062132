import {feature} from '@admin-tribe/binky';
import {SearchField} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

/* A component that will display filtered products by product name or contract name. */

const ProductSearch = ({allProductSummaryEntries, onSearchResultsChanged}) => {
  const intl = useIntl();

  const [searchVal, setSearchVal] = useState('');

  const searchProducts = (searchValue) => {
    if (searchValue === '') {
      onSearchResultsChanged(allProductSummaryEntries);
      setSearchVal(searchValue);
    } else {
      setSearchVal(searchValue);

      const filterProducts = (products) =>
        products.filter(
          (productItem) =>
            productItem.product.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            productItem.contracts?.find((contract) =>
              contract?.name.toLowerCase().includes(searchValue?.toLowerCase())
            )
        );

      // if temp_ctir_18512_product_offers enabled, also filter the offers
      const offerResults = feature.isEnabled('temp_ctir_18512_product_offers')
        ? allProductSummaryEntries.OFFERS.filter((offer) =>
            offer.merchandising.copy.name?.toLowerCase().includes(searchValue.toLowerCase())
          )
        : undefined;

      const creativeCloudProductsResults = filterProducts(allProductSummaryEntries[CLOUD.CREATIVE]);
      const documentCloudProductsResults = filterProducts(allProductSummaryEntries[CLOUD.DOCUMENT]);
      const experienceCloudProductsResults = filterProducts(
        allProductSummaryEntries[CLOUD.EXPERIENCE]
      );
      const othersCloudProductsResults = filterProducts(allProductSummaryEntries[CLOUD.OTHERS]);

      onSearchResultsChanged({
        [CLOUD.CREATIVE]: [...creativeCloudProductsResults],
        [CLOUD.EXPERIENCE]: [...experienceCloudProductsResults],
        [CLOUD.DOCUMENT]: [...documentCloudProductsResults],
        [CLOUD.OTHERS]: [...othersCloudProductsResults],
        ...(feature.isEnabled('temp_ctir_18512_product_offers') && {OFFERS: offerResults}),
      });
    }
  };

  // when allProductSummaryEntries is changed, re-run the search & callback
  useEffect(() => {
    if (feature.isEnabled('temp_ctir_18512_product_offers')) {
      searchProducts(searchVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only re-run on prop change
  }, [allProductSummaryEntries]);

  return (
    <SearchField
      data-testid="search-products"
      label={intl.formatMessage({
        id: 'products.allProducts.searchLabel',
      })}
      onChange={searchProducts}
      onClear={() => {
        searchProducts('');
      }}
      type="search"
      value={searchVal}
      width="size-3000"
    />
  );
};

ProductSearch.propTypes = {
  /**
   * Object with grouped product summary list entries based on cloud.
   */
  allProductSummaryEntries: PropTypes.shape({
    /* eslint-disable react/forbid-prop-types -- existing usage */
    [CLOUD.CREATIVE]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.DOCUMENT]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.EXPERIENCE]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.OTHERS]: PropTypes.arrayOf(PropTypes.object),
    OFFERS: PropTypes.arrayOf(PropTypes.object),
    /* eslint-enable react/forbid-prop-types -- existing usage */
  }).isRequired,
  /**
   * Callback method invoked when the search value is changed
   * list of searched products will passed as arguments
   */
  onSearchResultsChanged: PropTypes.func.isRequired,
};

export default ProductSearch;
