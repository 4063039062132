const PRODUCT_TABLE_ACTION_VARIANT = {
  ADD_USERS: 'ADD_USERS',
  BUY_LICENSES: 'BUY_LICENSES',
  BUY_PRODUCT: 'BUY_PRODUCT',
  GO_TO_DEVELOPER_CONSOLE: 'GO_TO_DEVELOPER_CONSOLE',
  GO_TO_PACKAGES: 'GO_TO_PACKAGES',
  LINK_OUT: 'LINK_OUT',
  SET_UP_SDL: 'SET_UP_SDL',
};

export {PRODUCT_TABLE_ACTION_VARIANT};
