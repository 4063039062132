import {feature} from '@admin-tribe/binky';
import {OverlayWait} from '@admin-tribe/binky-ui';
import {Checkbox} from '@adobe/react-spectrum';
import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';

import Context from './JitRuleAutoCreateContext';

/**
 * JIT Rule Widget / slot
 */
const JitRuleAutoCreateWidget = () => {
  const {isLoading, notificationsEnabled, setNotificationsEnabled} = useContext(Context);

  return (
    <OverlayWait isLoading={isLoading}>
      {(feature.isEnabled('temp_jit_widget_notifications') && (
        <Checkbox isSelected={notificationsEnabled} onChange={setNotificationsEnabled}>
          <FormattedMessage id="common.sophia.sophiaModal.widgets.jitRuleAutoCreate.enableNotificationsPrompt" />
        </Checkbox>
      )) ||
        null}
    </OverlayWait>
  );
};

export default JitRuleAutoCreateWidget;
