(function () {
  /**
   * @ngdoc module
   * @name app.widgets.bulk-operations
   * @description module that defines bulk-operations localization
   */
  angular.module('app.widgets.bulk-operations.localization', [
    'app.core.papaparse',
    'app.widgets.bulk-operations.constants',
    'binky.core.lodash',
    'binky.core.translation-utils',
  ]);
})();
