import {ModelList, dispatchUiEventAnalytics} from '@admin-tribe/binky';
import isBoolean from 'lodash/isBoolean';
import mapValues from 'lodash/mapValues';

import sophiaV2 from 'common/api/sophiaV2';

import SOPHIA_CONSTANTS from './SophiaConstants';
import SophiaContent from './SophiaContent';

/**
 * @description Service for interacting with Sophia (Test & Target).
 *  The Sophia service call is tied to a particular campaign (identified by clientCode and surfaceId)
 *  This service returns content from Sophia which is unopinionated about the structure of that content.
 *  While SophiaCardList expects the API response to be in a specific AEM structure for use in Sophia Banners,
 *  this service can be used for content that is not used used in banners.
 */
class SophiaContentList extends ModelList {
  /**
   * @param {Object} options - options for the SophiaContentList
   * @param {Object} options.contextualParams - contextualParams
   * @param {String} options.surfaceID - surfaceID
   */
  constructor(options) {
    const {contextualParams, surfaceID} = options;
    super({
      isCacheable: true,
      itemClassRef: SophiaContent,
      modelCacheId: SOPHIA_CONSTANTS.MODEL_CACHE_ID,
      resource: sophiaV2.getContent,
      transformResponseData: (response) => transformResponse(response),
    });

    Object.assign(this, {
      contextualParams,
      surfaceID,
    });
  }

  refresh() {
    return super.refresh({
      clientCode: SOPHIA_CONSTANTS.CLIENT_CODE,
      contextualParams: transformRequest(this.contextualParams.toMinimumModel()),
      surfaceID: [this.surfaceID],
    });
  }
}

function transformRequest(obj) {
  return mapValues(obj, (value) => {
    const result = isBoolean(value) ? value.toString() : value;
    return result;
  });
}

/**
 * @description Method to transform the sophia campaign response into content objects.
 * @param {Object} response array of content returned by Sophia.
 * @returns {Object[]} array of content objects.
 */
function transformResponse(response) {
  const sophiaContents = [];
  const {surfaces} = response;
  const contentAnalytics = [];

  Object.keys(surfaces).forEach((surfaceId) => {
    const {containers} = surfaces[surfaceId];
    sophiaContents.push(
      ...containers.map((container) => {
        const content = new SophiaContent({
          containerAnalyticsParams: container?.containerAnalyticsData,
          containerId: container?.containerId,
          content: container?.data,
          dataType: container?.dataType,
          surfaceId,
        });

        contentAnalytics.push(content.getAnalyticsParams());

        return content;
      })
    );
  });

  dispatchUiEventAnalytics({
    eventAction: 'load',
    eventName: 'sophiaContentLoad',
    sophia: {
      responses: contentAnalytics,
    },
  });

  return sophiaContents;
}

export default SophiaContentList;
