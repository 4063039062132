(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name IDP constants
   * @description defines constants related to Idp service.
   */
  angular
    .module('app.core.ims-federated')
    .constant('IDP_STATUS', {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
    })
    .constant('IDP_ERROR_CODES', {
      CANNOT_READ_METADATA: 'cannot_read_metadata',
      EMPTY_METADATA: 'empty_metadata',
      INVALID_METADATA: 'invalid_metadata',
      INVALID_XML: 'invalid_xml',
      MALFORMED_SSO_URL: 'malformed_sso_url',
      MULTIPLE_ENTITY_DESCRIPTORS: 'multiple_entity_descriptors',
      NO_IDP_SSO_DESCRIPTOR: 'no_idp_sso_descriptor',
      NO_SSO_SERVICE_PRESENT: 'no_sso_service_present',
      UNRECOGNIZED_METADATA_FORMAT: 'unrecognized_metadata_format',
    });
})();
