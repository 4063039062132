(function () {
  /**
   * @ngdoc module
   * @name app.core.saml-event
   * @description Module which defines event-related models
   */
  angular.module('app.core.saml-event', [
    'app.core.api.cvl',
    'binky.core.common.list',
    'binky.core.lodash',
  ]);
})();
