(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.shell.navigation.links')
    .constant('LINK_TYPE', {
      BUTTON: 'link-button',
      ICON: 'link-icon',
      TAB: 'link-tab',
      TEXT: 'link-text',
    })
    .constant('LINK_TARGET_TYPE', {
      APPLICATION: 'link-target-application',
      APPLICATION_MODAL: 'link-target-application-modal',
      EXTERNAL: 'link-target-external',
      GLOBAL_MODAL: 'link-target-global-modal',
      GO_URL: 'link-target-go-url',
      HELP: 'link-target-help',
      MODAL: 'link-target-modal',
    });
})();
