import {log} from '@admin-tribe/binky';
import {MemberListTable} from '@admin-tribe/binky-ui';
import {Content, Header, Heading, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {TABLE_SECTION_ACTIONS, TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ProductProvisioningStatusUserList from 'core/products/provisioning-status-users/ProductProvisioningStatusUserList';
import {canViewUserDetails} from 'core/user/access/userAccess';
import {getHrefForUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

const EXPIRING_USERS_DRAWER_ID = 'expiring-users-drawer-id';
const memberListDisplay = {avatar: true, email: true};

/**
 * @description This drawer displays the count of license of expiring, and
 * the count of users impacted by the expiration of those licenses. Note that these counts may not always be equal.
 * Expiring licenses are defined as both cancelling and provisioned. i.e. they will lose access in the next payment due date.
 */
const ExpiringUsersDrawer = ({expiringLicenseCount, productId}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [userList, setUserList] = useState();

  // Fetches for expiring users list and handle different pages
  useEffect(() => {
    const getExpiringUsers = async () => {
      let expiringUserList;

      setIsLoading(true);
      try {
        expiringUserList = await ProductProvisioningStatusUserList.getExpiringUsers({
          orgId: rootStore.organizationStore.activeOrgId,
          pageNumber,
          pageSize: 10,
          productId,
        });
      } catch (error) {
        // If fetch to get users list fails, we won't display an error and just hide the table
        log.error('ExpiringUsersDrawer: Failed to fetch expiring users', error);
      } finally {
        setUserList(expiringUserList);
        setIsLoading(false);
      }
    };

    getExpiringUsers();
  }, [pageNumber, productId]);

  const onTableSectionChange = useCallback(({action}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        setPageNumber((curr) => curr + 1);
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        setPageNumber((curr) => curr - 1);
        break;
      default:
        break;
    }
  }, []);

  const usersImpactedCount = userList?.getTotalItemCount();

  return (
    <Drawer aria-labelledby={EXPIRING_USERS_DRAWER_ID} isLoading={isLoading}>
      <Header>
        <Heading id={EXPIRING_USERS_DRAWER_ID} level={2} marginY="size-0">
          {intl.formatMessage({id: 'products.details.drawers.expiringUsers.heading'})}
        </Heading>
      </Header>
      <Content>
        <View data-testid="expiring-users-description" elementType="p">
          {usersImpactedCount > 0
            ? intl.formatMessage(
                {id: 'products.details.drawers.expiringUsers.usersImpacted'},
                {expiringLicenseCount, usersImpactedCount}
              )
            : intl.formatMessage(
                {id: 'products.details.drawers.expiringUsers.licensesExpiring'},
                {expiringLicenseCount}
              )}
        </View>
        {userList?.items?.length && (
          <TableSection
            items={userList.items}
            onTableSectionChange={onTableSectionChange}
            pageNumber={pageNumber}
            pageSizeOptions={false} // Hides the page size selector
            totalPages={userList.pagination.totalPages}
          >
            <MemberListTable
              allowsSorting={false}
              aria-labelledby={EXPIRING_USERS_DRAWER_ID}
              canViewMemberDetails={canViewUserDetails}
              display={memberListDisplay}
              getDisplayNameHref={(member) => getHrefForUserDetails({userId: member.id})}
            />
          </TableSection>
        )}
      </Content>
    </Drawer>
  );
};

ExpiringUsersDrawer.propTypes = {
  /* The count of expired users. */
  expiringLicenseCount: PropTypes.number.isRequired,
  /* The product id used to query for the expired users. */
  productId: PropTypes.string.isRequired,
};

export default ExpiringUsersDrawer;
