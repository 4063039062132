import UserArrowIcon from '@spectrum-icons/workflow/UserArrow';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getIntroductionsData} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import {canViewUserIntroductions} from 'core/user/access/userAccess';
import {goToSavedUserIntroductions} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';

import QuickLink from '../QuickLink';

const SavedUserIntroductionsQuickLink = ({count}) => {
  const intl = useIntl();

  return (
    <QuickLink
      analyticsOptions={{
        analyticsAction: 'saved-user-introductions',
      }}
      data-testid="quick-link-saved-user-introductions"
      Icon={<UserArrowIcon size="M" />}
      label={intl.formatMessage({id: 'overview.quickLinks.savedUserIntroductions.title'}, {count})}
      onPress={() => goToSavedUserIntroductions()}
    />
  );
};

SavedUserIntroductionsQuickLink.propTypes = {
  /**
   * The number of pending user introductions in the organization
   */
  count: PropTypes.number,
};

SavedUserIntroductionsQuickLink.get = async () => {
  if (!canViewUserIntroductions()) {
    return Promise.reject(new Error('User cannot view saved user introductions'));
  }

  // if we have any ignored introductions, but not so many actionable that probably they are not interested in action.
  const actionsData = await getIntroductionsData();
  if (actionsData.ignoredIntroductionsCount > 0) {
    return Promise.resolve(
      <SavedUserIntroductionsQuickLink
        key="SavedUserIntroductionsQuickLink"
        count={actionsData.ignoredIntroductionsCount}
      />
    );
  }
  return Promise.reject(new Error('Invalid number of saved user introductions'));
};

export default SavedUserIntroductionsQuickLink;
