(function () {
  /**
   * @deprecated Please use src2/app/core/services/product/product-group/product-list/FigProductGroupProductList.js
   *
   * @ngdoc factory
   * @name FigProductGroupProductList
   * @description Model for an individual FigProductGroupProductList which is a product group based on the
   *   product's fig id.
   */
  angular
    .module('app.core.product.product-group-product-list')
    .factory('FigProductGroupProductList', getFigProductGroupProductListModel);

  /* @ngInject */
  function getFigProductGroupProductListModel(
    $q,
    _,
    jilProductArrangements,
    modelCache,
    PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
    PRODUCT_METADATA_CACHE_ID,
    ProductGroupProductList
  ) {
    class FigProductGroupProductList extends ProductGroupProductList {
      /**
       * @class
       * @description Constructor for FigProductGroupProductList model Objects.
       * @param {Object} options - options object
       * @param {String} options.figId - The fulfillable item group id for all items in this product group.
       * @param {String} options.items - The products in this group.
       */
      constructor(options) {
        super({
          id: options.figId, // unique id for this productGroup which will be used as the cache key
          items: options.items,
        });
      }

      getProductMetadata() {
        // Normally products that share the same fig id should also share the same
        // product arrangement code, but get the metadata for each just in case.
        const paCodes = _(this.items).groupBy('productArrangementCode').keys().value();

        return $q
          .all(_.map(paCodes, fetchProductMetadataForProductArrangementCode.bind(this)))
          .then(() => this);

        ///////////////

        function fetchProductMetadataForProductArrangementCode(paCode) {
          const metadata = modelCache.get(PRODUCT_METADATA_CACHE_ID, paCode);
          let promise;

          if (metadata) {
            mergeMetadata(this.items, metadata);
            promise = $q.resolve(this);
          } else {
            promise = jilProductArrangements.metadata
              .query({
                productArrangementCode: paCode,
              })
              .$promise.then(onSuccess.bind(this));
          }
          return promise;
        }

        function onSuccess(response) {
          mergeMetadata(this.items, response);
          modelCache.put(PRODUCT_METADATA_CACHE_ID, response, this.id);
          return this;
        }

        function mergeMetadata(items, data) {
          _.forEach(items, (item) => {
            const itemData = _.find(data, {productId: item.id});
            if (itemData) {
              item.metadata = _.get(itemData, 'metadata', []);
            }
          });
        }
      }

      /**
       * @returns {boolean} returns true if any of the product has instances scorecard
       */
      hasInstancesScorecard() {
        return _.some(this.items, (item) =>
          item.hasScorecardType(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE.INSTANCES)
        );
      }
    }

    return FigProductGroupProductList;
  }
})();
