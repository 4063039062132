import {log} from '@admin-tribe/binky';

import jilTeams from '../../api/jil/jilTeams';

class TeamList {
  /**
   * @description to get deactivated devices
   *
   * @param {Array<Object>} selected orgId
   */
  // eslint-disable-next-line class-methods-use-this -- individual function
  async deactivatedDevices(orgId) {
    try {
      return await jilTeams.getDeactivatedDevices({
        orgId,
      });
    } catch (error) {
      // a 404 indicates the deactivated device can't be found for this org
      if (error?.response?.status === 404) {
        return error?.response;
      }
      log.error(`Failed to get deactivated device with orgId: ${orgId}. Error:`, error);
      throw new Error('deactivated device failed to get.');
    }
  }

  /**
   * @description Deactivate single/multiple devices of org and pool.
   *
   * @param {Array<Object>} selected single/multiple deviceIds array of orgId and poolId which has/have to be deactivated
   */
  // eslint-disable-next-line class-methods-use-this -- individual function
  async deactivateDevices(deviceIds, orgId, poolId) {
    const deviceIdsWithError = [];
    const errorMessage = [];

    await deviceIds.reduce(async (lastPromise, deviceId) => {
      await lastPromise;
      try {
        await jilTeams.deactivateDevice({
          deviceId,
          orgId,
          poolId,
        });
      } catch (error) {
        if (error?.response?.status !== 404) {
          deviceIdsWithError.push(deviceId);
          errorMessage.push(JSON.stringify(error));
        }
      }
    }, Promise.resolve());

    if (deviceIdsWithError.length > 0) {
      log.error(
        `Failed to deactivate device with deviceId: ${deviceIdsWithError.join(', ')}. Error:`,
        errorMessage.join(',')
      );
      throw new Error('device failed to deactivate.');
    }
  }

  /**
   * @description To get the list of all package pools and a summary for them.
   *
   * @param {Array<Object>} selected orgId
   */
  // eslint-disable-next-line class-methods-use-this -- individual function
  async devicePackagePoolsResource(orgId) {
    try {
      return await jilTeams.getDevicePackagePoolsResource({
        orgId,
      });
    } catch (error) {
      // a 404 indicates the pool can't be found
      if (error?.response?.status === 404) {
        return error?.response;
      }
      log.error(`Failed to get list of package pools with orgId: ${orgId}. Error:`, error);
      throw new Error('package pool not found.');
    }
  }
}

export default TeamList;
