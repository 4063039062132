(function () {
  /**
   * @ngdoc module
   * @name app.widgets.directories.idp-status
   * @description module that defines IdP status widget
   */
  angular.module('app.widgets.directories.idp-status', [
    'binky.core.lodash',
    'binky.widgets.common.status',
    'pascalprecht.translate',
  ]);
})();
