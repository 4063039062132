import {eventBus, feature} from '@admin-tribe/binky';
import {HeaderNav} from '@admin-tribe/binky-ui';
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withSize} from 'react-sizeme';

import {CART_EVENT} from 'features/offers/freeOfferCartConstants';
import useLocationChange from 'shell/hooks/useLocationChange';

import {getHeaderNavItems} from './headerNavItems';

const DEFAULT_NAV_ITEM_KEY = 'overview';

/**
 * Gets the available navigation items and determines the selected item
 * based on the current location
 */
const useNavigationItems = () => {
  const [navItems, setNavItems] = useState([]);
  const [selectedNavItemKey, setSelectedNavItemKey] = useState(DEFAULT_NAV_ITEM_KEY);
  const {currentLocationPathname} = useLocationChange();

  // Set nav items once all asynchronous calls have completed
  useEffect(() => {
    (async () => {
      setNavItems(await getHeaderNavItems());
    })();
  }, []);

  /** Determine which nav item should be visibly selected */
  useEffect(() => {
    // /:orgid/overview?flags="foo,bar,foobar"
    //            ^ targeting this
    const currentRouteRootWithParamsRemoved =
      currentLocationPathname.split('/')?.[2]?.split('?')?.[0] || DEFAULT_NAV_ITEM_KEY;

    for (let i = 0, l = navItems.length; i < l; i++) {
      if (currentRouteRootWithParamsRemoved === navItems[i].key) {
        if (feature.isEnabled('temp_account_page_force_refresh') && navItems[i].key === 'account') {
          eventBus.emit(CART_EVENT.SUBMIT, this);
        }
        setSelectedNavItemKey(navItems[i].key);
        return;
      }
    }
  }, [navItems, currentLocationPathname]);

  return {
    navItems,
    selectedNavItemKey,
  };
};

/**
 * Component that allows us to provide rootStore information to the HeaderNav
 * component and to apply a provider wrapper without saddling that on the
 * HeaderNav component itself.
 */

const HeaderNavWrapper = ({size}) => {
  const {navItems, selectedNavItemKey} = useNavigationItems();

  return (
    <div
      className="header-nav-wrapper"
      data-selected={selectedNavItemKey}
      data-testid="header-nav-wrapper"
    >
      <Flex alignItems="center" height="size-700" maxHeight="size-700" wrap="nowrap">
        <View
          marginX="auto"
          UNSAFE_style={{
            backgroundColor: 'var(--spectrum-global-color-gray-900)',
            overflow: 'hidden',
          }}
        >
          <HeaderNav
            maxWidth={size.width}
            navItems={navItems}
            selectedNavItemKey={selectedNavItemKey}
          />
        </View>
      </Flex>
    </div>
  );
};

HeaderNavWrapper.propTypes = {
  /** Data from react-sizeme */
  size: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default withSize()(HeaderNavWrapper);
export {useNavigationItems};
