import {AuthenticatedUser, toPandoraProductList} from '@admin-tribe/binky';
import {USER_ROLES} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {
  canAccessEnterpriseSupport as pandoraCanAccessEnterpriseSupport,
  canAccessEnterpriseSupportForCloud as pandoraCanAccessEnterpriseSupportForCloud,
  canManageTickets as pandoraCanManageTickets,
} from '@pandora/data-model-support-ticket';
import {userRoleUtils} from '@pandora/react-data-model-member';

import rootStore from 'core/RootStore';

const {organizationStore} = rootStore;

const getContractList = () =>
  new JilModelList({
    items: rootStore.organizationStore.contractList.items.map((contract) => new Contract(contract)),
  });

const getProductList = () => toPandoraProductList(organizationStore.productList);

/**
 * @description Method to determine if the admin allowed to access enterprise support
 *
 * @returns {Boolean} Returns true if the admin's products, contracts and/or roles allow them to access enterprise support
 */
const canAccessEnterpriseSupport = () =>
  pandoraCanAccessEnterpriseSupport(getContractList(), getProductList());

/**
 * @description Method to determine if the admin is allowed them to access enterprise
 * support for a given CLOUD.
 *
 *  * @param {string or CLOUD} cloud - the cloud type for which admin is seeking support
 *
 * @returns {Boolean} Returns true if the admin's products, contracts or roles allows them to access enterprise support for this CLOUD
 */
const canAccessEnterpriseSupportForCloud = (cloud) =>
  pandoraCanAccessEnterpriseSupportForCloud(getContractList(), getProductList(), cloud);

/**
 * @description Method to determine if the admin has a role that allows them to access enterprise support
 *
 *  * @param {String} caseType - whether the case is general in nature: 'ADMIN', or particular to a product: 'PRODUCT'
 *  * @param {string or CLOUD} cloud - the cloud type for which admin is seeking support
 *
 * @returns {Boolean} Returns true if the admin's products, contracts or roles allows them to access enterprise support for this CLOUD
 */
const canManageTickets = (caseType, cloud) => {
  const orgId = organizationStore.activeOrgId;
  const {roles} = AuthenticatedUser.get().profile;
  const productSupportRoleProductIds = userRoleUtils.getTargetsByRole(
    USER_ROLES.PRODUCT_SUPPORT_ADMIN,
    orgId,
    roles
  );
  const userIsOrgOrSupportAdmin = userRoleUtils.anyOfForOrg(
    roles,
    [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN],
    orgId
  );

  return pandoraCanManageTickets(
    getContractList(),
    getProductList(),
    productSupportRoleProductIds,
    userIsOrgOrSupportAdmin,
    caseType,
    cloud
  );
};

/**
 * @description Method to determine whether an organization can view the support tab.
 * Only developer orgs cannot view the support tab.
 *
 * @returns {Boolean} Whether or not the support tab is accessible based on active org
 */
const canViewSupport = () => !rootStore.organizationStore.isActiveOrgDeveloper;

export {
  canAccessEnterpriseSupport,
  canAccessEnterpriseSupportForCloud,
  canManageTickets,
  canViewSupport,
};
