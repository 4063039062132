import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS, feature} from '@admin-tribe/binky';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {DialogContainer, Flex, Heading, View} from '@adobe/react-spectrum';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import {checkDeIsOn} from 'features/settings/common/utils/domainEnforcementUtils';
import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import ExcludeUsersSection from 'features/settings/components/directory/domain-enforcement/exclude-users-section/ExcludeUsersSection';
import ChangeEmailSection from 'features/settings/components/directory/domain-enforcement/requirements-section/ChangeEmailSection';
import EnforceSsoSection from 'features/settings/components/directory/domain-enforcement/requirements-section/EnforceSsoSection';
import DisableDomainEnforcementConfirmationModal from 'features/settings/components/directory/domain-enforcement/settings-section/DisableDomainEnforcementConfirmationModal';
import SettingsSection from 'features/settings/components/directory/domain-enforcement/settings-section/SettingsSection';
import useDomainList from 'features/settings/hooks/api/useDomainList';

const DomainEnforcement = () => {
  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState(false);
  const {clearDomainsCache} = useDomainList();
  const {clearDirectoryCache} = useDirectoryList();

  const intl = useIntl();
  const {domainEnforcement, refreshDirectory, updateDomainEnforcement} =
    useDomainEnforcementContext();

  const isDeOn = checkDeIsOn(domainEnforcement.state);

  const onDEUpdateConfirm = async () => {
    try {
      // If DE is on (ACTIVATED, ENFORCED, NOTIFIED), we want to turn it off
      // so we need to pass DEACTIVATED otherwise, we want to turn it on, so
      // we need to pass ACTIVATED
      const updateState = isDeOn
        ? DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.DEACTIVATED
        : DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED;

      await updateDomainEnforcement(updateState);

      // Clear domains cache to update the DE status (react)
      clearDomainsCache();
      // Clear directory cache to update the DE status (react)
      clearDirectoryCache();
      // Refresh directory details (angular)
      refreshDirectory();

      showSuccessToast(
        intl.formatMessage({
          id: `settings.domainEnforcement.settingsSection.toast.${
            isDeOn ? `toggleOff` : `toggleOn`
          }.success`,
        })
      );

      setShowDisableConfirmationModal(false);
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: `settings.domainEnforcement.settingsSection.toast.${
            isDeOn ? `toggleOff` : `toggleOn`
          }.error`,
        })
      );
    }
  };

  const handleOnToggleDe = () => {
    // DisableConfirmationModal is shown only when turning off DE
    // which means that if DE is currently enabled
    // we need to disable it and show the modal
    if (isDeOn) {
      setShowDisableConfirmationModal(true);
      return;
    }

    onDEUpdateConfirm();
  };

  return (
    <>
      <View data-testid="domain-enforcement-page" paddingBottom="size-200">
        <View>
          <Heading level={3} marginTop="0">
            <FormattedMessage id="settings.domainEnforcement.settingsSection.title" />
          </Heading>
          <SettingsSection handleOnToggleDe={handleOnToggleDe} />
        </View>
        {isDeOn && (
          <>
            <View>
              <Heading level={3}>
                <FormattedMessage id="settings.domainEnforcement.requirementsSection.title" />
              </Heading>
              <Flex direction="column" gap="size-100" justifyContent="space-between">
                <EnforceSsoSection />
                <ChangeEmailSection />
              </Flex>
            </View>
            {feature.isEnabled('temp_de_exclude_user_section') && (
              <View>
                <Heading level={3}>
                  <FormattedMessage id="settings.domainEnforcement.excludeUsersSection.title" />
                </Heading>
                <ExcludeUsersSection />
              </View>
            )}
          </>
        )}
      </View>
      <DialogContainer
        onDismiss={() => {
          setShowDisableConfirmationModal(false);
        }}
      >
        {showDisableConfirmationModal && (
          <DisableDomainEnforcementConfirmationModal onConfirm={onDEUpdateConfirm} />
        )}
      </DialogContainer>
    </>
  );
};

export default DomainEnforcement;
