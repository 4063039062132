import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo, useState} from 'react';

import useAppIntegration from '../../../hooks/useAppIntegration';

/**
 * AddAppIntegrationModalContext shares state for the AddAppIntegrationModal
 */
const AddAppIntegrationModalContext = createContext(null);

/**
 * @returns AddAppIntegrationModalContext
 */
const useAddAppIntegrationModalContext = () => {
  const context = useContext(AddAppIntegrationModalContext);
  if (!context) {
    throw new Error(
      'Cannot call useAddAppIntegrationModalContext outside of a AddAppIntegrationModalContextProvider.'
    );
  }
  return context;
};

// The Provider for the AddAppIntegrationModalContext.
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- false positive, this is the only component
const AddAppIntegrationModalContextProvider = ({children, orgId}) => {
  const [tableSelection, setTableSelection] = useState();
  const [selectedClientId, setSelectedClientId] = useState();
  const {appIntegration, error, isLoading} = useAppIntegration({clientId: selectedClientId, orgId});

  // Shared state set from the last step 'set policy' in the wizard
  const [policyFormState, setPolicyFormState] = useState({
    isPolicyFormValid: false,
    newAuthorizedUsers: [],
    selectedPolicy: '',
  });

  const context = useMemo(
    () => ({
      appIntegration,
      error,
      isLoading,
      policyFormState,
      setPolicyFormState,
      setSelectedClientId,
      setTableSelection,
      tableSelection,
    }),
    [appIntegration, error, isLoading, policyFormState, tableSelection]
  );

  return (
    <AddAppIntegrationModalContext.Provider value={context}>
      {children}
    </AddAppIntegrationModalContext.Provider>
  );
};

AddAppIntegrationModalContextProvider.propTypes = {
  /** The children of the AddAppIntegrationModalContextProvider. */
  children: PropTypes.node.isRequired,
  /** The org id associated with the app integrations. */
  orgId: PropTypes.string,
};

export {AddAppIntegrationModalContextProvider, useAddAppIntegrationModalContext};
