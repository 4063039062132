import {Button} from '@adobe/react-spectrum';
import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';

import Context from './JitRuleContext';

/**
 * Button to render in the modal footer
 */
const JitRuleButton = () => {
  const {isLoading, onSubmit} = useContext(Context);

  return (
    <Button isDisabled={isLoading} onPress={onSubmit} variant="cta">
      <FormattedMessage id="common.sophia.sophiaModal.widgets.jitRuleWidget.button" />
    </Button>
  );
};

export default JitRuleButton;
