(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appSelectProducts
   * @description Component to display the purchase sections in the add products or renew products modal.
   *
   * @param {Function} onAddCatalogItem Callback to bubble up the offer selected
   * @param {Array} [ownedBucket] Array of offers to pass into owned products section.
   *                              If empty, section will be hidden. Default is hidden.
   * @param {Integer} [ownedCount] Initial count of items in the ownedBucket. Default is undefined.
   * @param {Object} [ownedProducts] Products currently owned by the organization.
   * @param {Array} [recommendedBucket] Array of offers to pass into recommended products section.
   *                              If empty, section will be hidden. Default is hidden.
   * @param {Array} [remainingBucket] Array of offers to pass into unowned and not recommended products section.
   *                              If empty, section will be hidden. Default is hidden.
   * @param {Boolean} [showPrice] Boolean value of whether to show the price or not. Default is false.
   * @example
   *      <app-select-products
   *        on-add-catalog-item="$ctrl.onAddCatalogItem({offerId})"
   *        owned-bucket="$ctrl.ownedBucket"
   *        owned-products="$ctrl.ownedProducts"
   *        recommended-bucket="$ctrl.recommendedBucket"
   *        remaining-bucket="$ctrl.remainingBucket"
   *        show-price="$ctrl.showPrice">
   *      </app-select-products>
   *
   */
  const componentName = 'appSelectProducts';
  angular.module('app.widgets.products.purchase').component(componentName, {
    bindings: {
      onAddCatalogItem: '&',
      ownedBucket: '<?',
      ownedCount: '@?',
      ownedProducts: '<?',
      recommendedBucket: '<?',
      remainingBucket: '<?',
      showPrice: '<?',
    },
    templateUrl:
      'app/widgets/products/purchase/catalog/select-products/select-products.component.html',
  });
})();
