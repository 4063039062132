import {SearchField, Text, View} from '@adobe/react-spectrum';
import pullAll from 'lodash/pullAll';
import union from 'lodash/union';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import styles from './ChooseEntitlementsPage.pcss';
import {
  getEntitlementsOfferList,
  getSelectedKeys,
  isAddRemoveOptionVisible,
  shouldDisableNonSelectedOffer,
  titleDescForPackageType,
  triggerIngestAnalytics,
} from './chooseEntitlementsPageUtils';
import EntitlementCard from './entitlement-card/EntitlementCard';

// Screen for Create Package to choose entitlements in create package workflow
const ChooseEntitlementsPage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();

  const entitlementOffersList = getEntitlementsOfferList(
    createPackageModalStore.pkgSessionSettings
  );
  const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(entitlementOffersList));
  const [entitlementNameFilter, setEntitlementNameFilter] = useState('');

  // Disable non-selected offers that have conflicting fi codes with selected offers
  function disableConflictingNonSelectedOffers(offer) {
    const selectedOffers = entitlementOffersList.filter(
      (entitlementOffer) => entitlementOffer.selected
    );
    if (selectedOffers?.length > 0) {
      const nonSelectedOffers = entitlementOffersList.filter(
        (entitlementOffer) => !entitlementOffer.selected
      );
      const nonConflictingFiCodes = rootStore.packagesUiConstantsStore.passiveAppsFiCodes;
      let fiOffers = [];
      selectedOffers.forEach((selectedOffer) => {
        fiOffers = union(
          fiOffers,
          selectedOffer.getPackageableFulfillableItems().map((item) => item.code)
        );
      });
      pullAll(fiOffers, nonConflictingFiCodes);
      nonSelectedOffers.forEach((nonSelectedOffer) => {
        const commonFi = nonSelectedOffer
          .getPackageableFulfillableItems()
          .map((item) => item.code)
          .filter((item) => fiOffers.includes(item));

        nonSelectedOffer.disabled = shouldDisableNonSelectedOffer(
          commonFi,
          nonSelectedOffer.offerId,
          offer.offerId
        );
      });
    } else {
      // If no offers selected then all offers can be enabled
      entitlementOffersList.forEach((entitlementOffer) => {
        entitlementOffer.disabled = false;
      });
    }
  }

  // Triggered when an entitlement offer card is clicked
  function offerSelectionChanged(offer) {
    if (offer.disabled) {
      offer.selected = false;
      setSelectedKeys(selectedKeys.filter((key) => key !== offer.offerId));
      return;
    }
    triggerIngestAnalytics(
      offer.longName,
      createPackageModalStore.pkgSessionSettings.packagingMode,
      createPackageModalStore.pkgSessionSettings.packageType
    );
    createPackageModalStore.setPkgSessionSettingValues('resetCreatePackageData', true);
    const wasOfferSelected = offer.selected;
    offer.selected = !wasOfferSelected;
    if (wasOfferSelected) {
      // offer previously selected, so on toggling, the offer's id should be removed from selected keys
      setSelectedKeys(selectedKeys.filter((key) => key !== offer.offerId));
    } else {
      // offer previously not selected, so on toggling, the offer's id should be added to the list of selected keys
      setSelectedKeys([...selectedKeys, offer.offerId]);
    }

    disableConflictingNonSelectedOffers(offer);
    createPackageModalStore.setPkgSessionSettingValues(
      'selectedFrlOffers',
      entitlementOffersList.filter((entitlementOffer) => entitlementOffer.selected)
    );
    createPackageModalStore.setPkgSessionSettingValues('nextButtonEnabled', false);

    if (entitlementOffersList.some((entitlementOffer) => entitlementOffer.selected)) {
      createPackageModalStore.setPkgSessionSettingValues('nextButtonEnabled', true);
    }
    createPackageModalStore.setPkgSessionSettingValues('licenseFileSelected', true);
    createPackageModalStore.setPkgSessionSettingValues(
      'addRemoveOptionVisible',
      isAddRemoveOptionVisible(createPackageModalStore.pkgSessionSettings.packageType)
    );
  }

  // Return offer if search field empty or if it's longName
  // contains searchText as substring (case insensitive search)
  function searchByNameFilter(offer) {
    if (entitlementNameFilter === '') {
      return offer;
    }
    const entitlementName = offer.longName.toLowerCase();
    return entitlementName.includes(entitlementNameFilter.toLowerCase());
  }

  return (
    <>
      <Text marginTop="size-25" UNSAFE_style={{fontSize: '16px', fontWeight: 'bold'}}>
        {intl.formatMessage(
          {id: 'packages.createPackage.chooseEntitlement.title'},
          {
            selected: selectedKeys.length,
            total: entitlementOffersList.length,
          }
        )}
      </Text>
      <View paddingBottom="size-200">
        {intl.formatMessage({
          id: `packages.createPackage.chooseEntitlement.${titleDescForPackageType(
            createPackageModalStore.pkgSessionSettings.packageType
          )}`,
        })}
      </View>
      {entitlementOffersList.length > 6 && (
        <SearchField
          aria-label={intl.formatMessage({
            id: 'packages.createPackage.chooseEntitlement.searchField.ariaLabel',
          })}
          autoFocus
          marginBottom="size-300"
          onChange={setEntitlementNameFilter}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- input box-sizing must be border-box coming as content-box in story
          UNSAFE_className={styles['search-box-sizing']}
          width="size-3400"
        />
      )}

      <div
        aria-label={intl.formatMessage(
          {id: 'packages.createPackage.chooseEntitlement.title'},
          {
            selected: selectedKeys.length,
            total: entitlementOffersList.length,
          }
        )}
        role="group"
      >
        {entitlementOffersList.filter(searchByNameFilter).map((offer, index) => (
          <EntitlementCard
            key={offer.offerId}
            autoFocus={index === 0 && entitlementOffersList.length <= 6}
            expiryDate={offer.expiryDate}
            imgSrc={offer.getIconUrl()}
            index={index}
            isCardDisabled={offer.disabled}
            isCardSelected={offer.selected}
            onChange={() => offerSelectionChanged(offer)}
            titleString={offer.longName}
          />
        ))}
      </div>
    </>
  );
});

ChooseEntitlementsPage.propTypes = {};

export default ChooseEntitlementsPage;
