import {Flex, Link, Text, TextField} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import Delete from '@spectrum-icons/workflow/Delete';
import {runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {ACTIVATION_CODE_VALID_LENGTH} from 'features/packages/packagesConstants';
import PackagesIngestAnalyticsService from 'features/packages/services/PackagesIngestAnalyticsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import ActivationCodeValidator from './ActivationCodeValidator';

/**
 * This component is used to take activation codes via text input
 */
const ActivationCodeInput = observer(
  ({
    activationCodeObject,
    addNewActivationCodeBlock,
    removeActivationCodeBlock,
    updateAllCodeList,
  }) => {
    const intl = useIntl();

    const {createPackageModalStore} = useCreatePackageModalContext();

    const [codeInput, setCodeInput] = useState(activationCodeObject.codeInput);

    const [input, setInput] = useState(activationCodeObject.code);

    const isCodeValidCheck = () => {
      let isValid = false;
      createPackageModalStore.challengeCodeList?.forEach((activationCode) => {
        if (activationCode.id === activationCodeObject.id) {
          isValid =
            activationCode.code?.length === ACTIVATION_CODE_VALID_LENGTH && !activationCode.isValid;
        }
      });
      return isValid;
    };

    const [isCodeInvalid, setIsCodeInvalid] = useState(isCodeValidCheck());

    const getMaximumId = () =>
      Math.max(
        ...createPackageModalStore.challengeCodeList.map((challengeCode) => challengeCode.id),
        0
      );

    function activationCodesCheck(codeEntered) {
      runInAction(() => {
        createPackageModalStore.challengeCodeList?.forEach((activationCode) => {
          if (activationCode.id === activationCodeObject.id) {
            const activationCodeObj = {...activationCode};

            activationCode.code = codeEntered?.replace(/[\s-]/g, '') ?? '';
            setInput(activationCode.code);

            activationCode.codeInput = activationCode.code?.match(/.{1,6}/g)?.join('-') ?? '';
            setCodeInput(activationCode.codeInput);

            activationCode.code = ActivationCodeValidator.normalizeCode(activationCode.code);

            const requiresUpdation =
              activationCode.code.length === 0 ||
              activationCode.code.length >= ACTIVATION_CODE_VALID_LENGTH - 1;

            activationCode.isValid =
              activationCode.code.length === 0 ||
              ActivationCodeValidator.isValidCode(activationCode.code);

            setIsCodeInvalid(
              activationCode.code.length < ACTIVATION_CODE_VALID_LENGTH || !activationCode.isValid
            );

            if (
              activationCodeObj.code.length === ACTIVATION_CODE_VALID_LENGTH &&
              activationCodeObj.isValid
            ) {
              PackagesIngestAnalyticsService.triggerAnalytics({
                subCategory: 'FRL:Offline:Codes',
                subType: 'activation-code',
                type: 'click',
                value: 'input',
              });
            }
            if (requiresUpdation) {
              updateAllCodeList();
            }
          }
        });
      });
    }

    function getValidationState() {
      if (codeInput.length < ACTIVATION_CODE_VALID_LENGTH + 2) return '';
      return isCodeInvalid ? 'invalid' : 'valid';
    }

    const getTextFieldLabel = () => (
      <Flex justifyContent="space-between" width="size-5000">
        <Text>
          {intl.formatMessage({
            id: 'packages.createPackage.challengeCodes.activationCode',
          })}
        </Text>
        <Text>
          <span aria-hidden="true">{ACTIVATION_CODE_VALID_LENGTH - input.length}</span>
        </Text>
      </Flex>
    );

    return (
      <>
        <Flex marginTop="size-125">
          <Flex direction="column" width="size-5000">
            <TextField
              data-testid="activation-code-input"
              errorMessage={intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.invalidCode',
              })}
              label={getTextFieldLabel()}
              maxLength={ACTIVATION_CODE_VALID_LENGTH + 2}
              onChange={activationCodesCheck}
              validationState={getValidationState()}
              value={codeInput}
              width="100%"
            />
          </Flex>
          {activationCodeObject.id !== getMaximumId() && (
            <TooltipButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.removeActivatioCode',
              })}
              isQuiet
              marginBottom="auto"
              marginStart="size-125"
              marginTop="size-300"
              onPress={removeActivationCodeBlock}
              variant="action"
            >
              <Delete />
            </TooltipButton>
          )}
        </Flex>
        {activationCodeObject.id === getMaximumId() && (
          <Link
            data-testid="add-another-input"
            isQuiet
            marginTop="size-125"
            onPress={addNewActivationCodeBlock}
            UNSAFE_style={{width: 'fit-content'}}
          >
            {intl.formatMessage({
              id: 'packages.createPackage.activationCodes.addAnotherCode',
            })}
          </Link>
        )}
      </>
    );
  }
);

ActivationCodeInput.propTypes = {
  /**
   * activation code object
   */
  activationCodeObject: PropTypes.shape({
    code: PropTypes.string,
    codeInput: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  /**
   * Function invoked to add new activation code block
   */
  addNewActivationCodeBlock: PropTypes.func.isRequired,
  /**
   * Function invoked to remove an activation code block
   */
  removeActivationCodeBlock: PropTypes.func.isRequired,
  /**
   *  Function invoked to update the allCode list
   */
  updateAllCodeList: PropTypes.func.isRequired,
};

export default ActivationCodeInput;
