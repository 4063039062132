import {Content, ContextualHelp, Flex, Footer, Heading, Item, Picker} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import {UPDATE_STRATEGIES} from 'features/settings/stores/DirectoryStore';

import './UpdateUserInformation.pcss';

const UPDATE_STRATEGIES_PICKER = {
  [UPDATE_STRATEGIES.ALWAYS_UPDATE]: {
    id: UPDATE_STRATEGIES.ALWAYS_UPDATE,
    label: 'settings.updateUserInformation.alwaysUpdateUser',
  },
  [UPDATE_STRATEGIES.DO_NOT_UPDATE]: {
    id: UPDATE_STRATEGIES.DO_NOT_UPDATE,
    label: 'settings.updateUserInformation.dontUpdateUser',
  },
  [UPDATE_STRATEGIES.UPDATE_WHEN_NOT_EMPTY]: {
    id: UPDATE_STRATEGIES.UPDATE_WHEN_NOT_EMPTY,
    label: 'settings.updateUserInformation.updateWhenNotEmpty',
  },
};

const STRATEGIES_DESCRIPTION = {
  [UPDATE_STRATEGIES.ALWAYS_UPDATE]: 'settings.updateUserInformation.alwaysUpdateUserDescription',
  [UPDATE_STRATEGIES.DO_NOT_UPDATE]: 'settings.updateUserInformation.dontUpdateUserDescription',
  [UPDATE_STRATEGIES.UPDATE_WHEN_NOT_EMPTY]:
    'settings.updateUserInformation.updateWhenNotEmptyDescription',
};

const UpdateUserInformation = observer(() => {
  const intl = useIntl();
  const {directorySetupStore} = useDirectorySetupContext();

  const strategyDescription = STRATEGIES_DESCRIPTION[directorySetupStore.data.updateStrategy];

  return (
    <div styleName="update-user-information-container">
      <Flex direction="row">
        <Heading level={4} marginBottom="size-150" marginTop={0}>
          <FormattedMessage id="settings.updateUserInformation.title" />
        </Heading>
        <ContextualHelp variant="info">
          <Heading>
            <FormattedMessage id="settings.updateUserInformation.contextualHelp.heading" />
          </Heading>
          <Content>
            <FormattedMessage id="settings.updateUserInformation.contextualHelp.content" />
          </Content>
          <Footer>
            <GoUrl name="aac_update_user" noWrap={false}>
              <FormattedMessage id="settings.updateUserInformation.contextualHelp.learnMoreAboutUpdatingUserInformation" />
            </GoUrl>
          </Footer>
        </ContextualHelp>
      </Flex>

      <Picker
        aria-label={intl.formatMessage({id: 'settings.updateUserInformation.pickerLabel'})}
        data-testid="update-strategy-picker"
        description={intl.formatMessage({id: strategyDescription})}
        items={Object.values(UPDATE_STRATEGIES_PICKER)}
        onSelectionChange={(selectedValue) => directorySetupStore.setUpdateStrategy(selectedValue)}
        selectedKey={directorySetupStore.data.updateStrategy}
        width="size-3600"
      >
        {(item) => <Item>{intl.formatMessage({id: item.label})}</Item>}
      </Picker>
    </div>
  );
});

export {UPDATE_STRATEGIES_PICKER};
export default UpdateUserInformation;
