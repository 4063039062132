(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name app.core.storage-reports:StorageReportFolderList
   * @description Model for a storage report folder list
   */
  angular
    .module('app.core.storage-report-folder')
    .factory('StorageReportFolderList', storageReportFolderList);

  /* @ngInject */
  function storageReportFolderList(
    $q,
    $rootScope,
    _,
    jilStorageFolders,
    List,
    MESSAGE,
    MODEL,
    modelCache,
    StorageReportFolder
  ) {
    class StorageReportFolderList extends List {
      /**
       * @description Method to create a new instance of a StorageReportFolderList.
       * @param {Object} options - options passed to the StorageReportFolderList constructor
       * @param {String} options.includes - type of folder to fetch, active, all, and discarded,
       *                                    defaults to active
       * @param {AlphaListState} options.state - alpha list state,
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.STORAGEREPORTFOLDERLIST,
              itemClassRef: StorageReportFolder,
              resource: jilStorageFolders.storageReportFolders,
            },
            options
          )
        );
        this.includes = options.includes;
      }

      /**
       * @description Method to refresh Storage Report Folder list from back-end.
       * @returns {Promise} resolves to StorageReportFolder on success, else rejects with error
       */
      refresh() {
        this.$promise = super
          .refresh({includes: this.includes})
          .then(onSuccess.bind(this))
          .catch($q.reject)
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(MESSAGE.REFRESH.STORAGEREPORTFOLDERLIST);
          return this;
        }

        return this.$promise;
      }

      /**
       * @description Method to fetch the StorageReportFolderList. It will be refreshed on
       *     registering the active org
       * @param {Object} options - configuration options for list retrieval
       * @returns {StorageReportFolderList} singleton StorageReportFolderList.
       */
      static get(options = {}) {
        let model = new StorageReportFolderList(options);
        const cachedModel = modelCache.get(
          MODEL.STORAGEREPORTFOLDERLIST,
          model.key({includes: options.includes})
        );
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    modelCache.register(MODEL.STORAGEREPORTFOLDERLIST, 1);

    return StorageReportFolderList;
  }
})();
