import {Flex, Link, Text} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import Alert from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';
import {KCCC_SAP_CODE} from 'features/packages/packagesConstants';

import styles from '../IncludedAppsList.pcss';

const AppDetails = ({showPlugins, product}) => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);

  function getImageUrl() {
    if (showPlugins) {
      return product.iconUrl;
    }
    return product.getIconUrl === undefined ? '' : product.getIconUrl();
  }

  function showSpecialRequirements() {
    if (product.hasSpecialSystemRequirements === undefined) {
      return false;
    }
    return !showPlugins && !product.isSTI && product.hasSpecialSystemRequirements();
  }

  return (
    <Flex direction="column">
      <Flex alignItems="center" direction="row">
        <Flex alignItems="center" marginEnd="size-150">
          <ImageIcon alt="" data-testid="product-image" size="M" src={getImageUrl()} />
        </Flex>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- product name font styles */}
        <Text UNSAFE_className={styles['product-name-text']}>
          {product.name}
          {showPlugins && (
            <Text
              marginStart="size-100"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- plugin version
              UNSAFE_className={styles['plugin-info-text']}
            >
              &#40;{product.version}&#41;
            </Text>
          )}
          {!showPlugins && product.showPlatformOnUI && (
            <Text
              data-testid="ui-platform-text"
              marginStart="size-100"
              UNSAFE_style={{fontWeight: 'normal'}}
            >
              - {product.uiPlatform}
            </Text>
          )}
          {!showPlugins && (
            <Text
              data-testid="product-version"
              marginStart="size-100"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- product info font styles
              UNSAFE_className={styles['product-info-text']}
            >
              {(attributes.ccdVersionInfoAvailable || product.sapCode !== KCCC_SAP_CODE) && (
                <>&#40;{product.version}&#41;</>
              )}
            </Text>
          )}
        </Text>
      </Flex>
      {showSpecialRequirements() && (
        <Flex
          alignItems="center"
          data-testid="system-requirements"
          direction="row"
          marginBottom="size-125"
          marginStart="size-450"
        >
          <Alert color="negative" marginEnd="size-75" size="XS" />
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- system requirements fon styles*/}
          <Text UNSAFE_className={styles['system-req-text']}>
            {intl.formatMessage({
              id: 'packages.summaryDrawerBody.includedApplications.systemRequirements',
            })}

            {product.systemRequirementURL && (
              <Link marginStart="size-75">
                <a href={product.systemRequirementURL} rel="noreferrer" target="_blank">
                  <span>
                    {intl.formatMessage({
                      id: 'packages.summaryDrawerBody.includedApplications.moreInfoLinkText',
                    })}
                  </span>
                </a>
              </Link>
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

AppDetails.propTypes = {
  /**
   * Product object
   */
  product: PropTypes.shape({
    /**
     * Callback function to get product's icon url
     */
    getIconUrl: PropTypes.func,
    /**
     * Callback function that indicates if we have some special requirements for a product
     */
    hasSpecialSystemRequirements: PropTypes.func,
    /**
     * Icon url for plugin
     */
    iconUrl: PropTypes.string,
    /**
     * Indicates if a product is STI
     */
    isSTI: PropTypes.bool,
    /**
     * Name of product
     */
    name: PropTypes.string,
    /**
     * Sapcode of product
     */
    sapCode: PropTypes.string,
    /**
     * Indicates if a product is shown on UI
     */
    showPlatformOnUI: PropTypes.bool,
    /**
     * Url to special requirements
     */
    systemRequirementURL: PropTypes.string,
    /**
     * UI Platform of product
     */
    uiPlatform: PropTypes.string,
    /**
     * Version of product
     */
    version: PropTypes.string,
  }),
  /**
   * Boolean to tell if we are showing plugins
   */
  showPlugins: PropTypes.bool,
};

export default AppDetails;
