import {Content, ContextualHelp, Heading, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * The ContextualHelp to show after the search input.
 * The text is the same for both cases of "isSearchOnlyContentMode" but if true
 * adds that it "may take a moment".
 */
const SearchForUsersContextualHelp = ({isSearchOnlyContentMode = false}) => {
  const intl = useIntl();

  const contentKey = isSearchOnlyContentMode
    ? 'users.users.searchForUsers.contextualHelp.content.searchOnlyContentMode'
    : 'users.users.searchForUsers.contextualHelp.content';

  return (
    <ContextualHelp variant="info">
      <Heading>
        {intl.formatMessage({id: 'users.users.searchForUsers.contextualHelp.heading'})}
      </Heading>
      <Content>
        <Text>{intl.formatMessage({id: contentKey})}</Text>
      </Content>
    </ContextualHelp>
  );
};

SearchForUsersContextualHelp.propTypes = {
  /**
   * True to show the content for "searchOnlyContentMode". The default is false.
   */
  isSearchOnlyContentMode: PropTypes.bool,
};
export default SearchForUsersContextualHelp;
