(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationContractMigrationContext
   * @description Model for a OrganizationContractMigrationContext object
   */
  angular
    .module('app.core.contract.migrations')
    .factory('OrganizationContractMigrationContext', getOrganizationContractMigrationContextModel);

  /* @ngInject */
  function getOrganizationContractMigrationContextModel(_, OrganizationProductMigration) {
    class OrganizationContractMigrationContext {
      /**
       * @class
       * @description Creates a new OrganizationContractMigrationContext instance
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Array} options.products organization product migration
       */
      constructor(options) {
        initModel(this, options);
      }

      static apiResponseTransformer(organizationContractMigrationContext) {
        return new OrganizationContractMigrationContext(organizationContractMigrationContext);
      }
    }

    return OrganizationContractMigrationContext;

    ////////

    function initModel(model, options) {
      _.assignIn(model, {
        products: _.map(options.products, (product) => new OrganizationProductMigration(product)),
      });
    }
  }
})();
