import {Flex, Grid, Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import {SERVERS_CONSTANTS} from 'features/packages/packagesConstants';

import styles from '../ServerFinalize.pcss';

/**
 * Server Details component for Finalize screen in create/edit server flow
 * it shows details of the server
 */
const ServerDetails = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionData} = packagesServerStore;
  const intl = useIntl();

  const getTimeOutString = () => {
    if (serverSessionData.lanTimeout === '180') {
      return 'lanTimeoutValue1';
    }
    if (serverSessionData.lanTimeout === '365') {
      return 'lanTimeoutValue2';
    }
    return 'lanTimeoutValue3';
  };

  return (
    <Flex direction="column">
      <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for text*/}
        <Text UNSAFE_className={styles['server-details-font-style']}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.dnsAddress',
          })}
        </Text>
        <Text data-testid="package-type-value">{serverSessionData.dnsAddress}</Text>
      </Grid>

      <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for text*/}
        <Text UNSAFE_className={styles['server-details-font-style']}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.lanTimeout',
          })}
        </Text>
        <Text data-testid="server-lan-timeout">
          {intl.formatMessage({
            id: `packages.createServer.configure.${getTimeOutString()}`,
          })}
        </Text>
      </Grid>
      <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for text*/}
        <Text UNSAFE_className={styles['server-details-font-style']}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.entitlementLimits',
          })}
        </Text>
        <Text data-testid="package-type-value">
          {intl.formatMessage({
            id: `packages.createServer.finalize.${
              serverSessionData.activationEnforcementType === SERVERS_CONSTANTS.QUOTA.SOFT
                ? 'softQuota'
                : 'hardQuota'
            }`,
          })}
        </Text>
      </Grid>
      <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for text*/}
        <Text UNSAFE_className={styles['server-details-font-style']}>
          {intl.formatMessage({
            id: 'packages.createServer.finalize.organizationName',
          })}
        </Text>
        <Text data-testid="package-type-value">{serverSessionData.organizationName}</Text>
      </Grid>
    </Flex>
  );
});

export default ServerDetails;
