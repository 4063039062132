(function () {
  /**
   * @ngdoc module
   * @name app.widgets.contracts
   * @description module that defines contracts related widgets
   */
  angular.module('app.widgets.contracts', [
    'angularMoment',
    'app.account.meta',
    'app.core.auth',
    'app.core.cart',
    'app.core.contact-sales',
    'app.core.contract.access',
    'app.core.contract.auto-renewal.auto-renewal-helper',
    'app.core.contract.contract-utils',
    'app.core.organizations.organization-manager',
    'app.core.user',
    'app.widgets.account',
    'app.widgets.account.authenticating-user',
    'app.widgets.products',
    'app.widgets.products.purchase',
    'app.widgets.products.purchase.renew-products-modal2',
    'app.widgets.tag',
    'binky.core.contract',
    'binky.core.lodash',
    'binky.core.price',
    'binky.core.translation-utils',
    'binky.core.user.authenticated-user',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.member.member-name',
    'pascalprecht.translate',
  ]);
})();
