import {CONTRACT_BILLING_FREQUENCY, FULFILLABLE_ITEM_CHARGING_UNIT} from '@admin-tribe/binky';
import {
  BOLD_PRICE_STYLES,
  IconHeaderDescription,
  ImageIcon,
  TruncatedText,
} from '@admin-tribe/binky-ui';
import {Flex, ProgressCircle, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SafePrice, {PRICE_FAILURE} from 'common/components/safe-price/SafePrice';

import styles from './SingleProductCancelItem.pcss';

/**
 * Header to display a product's name, price, licenses available and licenses assigned.
 */
const SingleProductCancelItem = ({
  currency,
  imageSrc,
  isLoading,
  isMultiPrice,
  pricePerUnit,
  priceTotal,
  productName,
  withSubtotalPrice = false,
  term,
  unit,
  unitsAssigned,
  unitsTotal,
}) => {
  const intl = useIntl();

  return (
    <Flex direction="row" width="100%">
      <View elementType="div" width="50%">
        <IconHeaderDescription
          data-testid="IconHeaderDescription"
          IconComponent={<ImageIcon alt="" size="L" src={imageSrc} />}
          subtitle={
            pricePerUnit > 0 ? (
              <Flex alignItems="center" wrap="wrap">
                {isMultiPrice && (
                  <Text>
                    {intl.formatMessage({
                      id: 'account.selfCancel.components.singleProductCancelItem.multiPrice',
                    })}
                    &nbsp;
                  </Text>
                )}
                <SafePrice
                  currency={currency}
                  perUnit={unit}
                  price={pricePerUnit}
                  recurrenceTerm={term}
                />
                {isMultiPrice && (
                  <TooltipButton
                    aria-label={intl.formatMessage({
                      id: 'account.selfCancel.components.singleProductCancelItem.multiPrice.tooltip',
                    })}
                    elementType="span"
                    hoverText={intl.formatMessage({
                      id: 'account.selfCancel.components.singleProductCancelItem.multiPrice.tooltip',
                    })}
                    isQuiet
                    marginStart="size-125"
                    minHeight="size-0"
                    minWidth="size-0"
                    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
                    UNSAFE_className={styles['tooltip-icon']}
                    variant="secondary"
                  >
                    <InfoOutlineIcon />
                  </TooltipButton>
                )}
              </Flex>
            ) : (
              PRICE_FAILURE
            )
          }
          title={
            <Text
              margin="size-0"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles['header-subtitle']}
            >
              <TruncatedText>{productName}</TruncatedText>
            </Text>
          }
        />
      </View>
      <Flex direction="row" justifyContent="space-between" width="50%">
        <Flex direction="column">
          <Text
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles['section-title']}
          >
            {intl.formatMessage({
              id: 'account.selfCancel.components.singleProductCancelItem.assignedUnits',
            })}
          </Text>
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the title */}
          <Text UNSAFE_className={styles.licenses}>
            {intl.formatMessage(
              {id: 'account.selfCancel.components.singleProductCancelItem.unitsAssignment'},
              {unitsAssigned, unitsTotal}
            )}
          </Text>
        </Flex>
        {withSubtotalPrice && (
          <Flex alignItems="end" data-testid="SubtotalPrice" direction="column">
            <Text
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles['section-title']}
            >
              {intl.formatMessage({
                id: 'account.selfCancel.components.singleProductCancelItem.currentPayment',
              })}
            </Text>
            {isLoading ? (
              <ProgressCircle
                aria-label={intl.formatMessage({
                  id: 'account.selfCancel.components.singleProductCancelItem.updating',
                })}
                data-testid="ProgressCircle"
                isIndeterminate
                size="S"
              />
            ) : (
              /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to style the price */
              <Text data-testid="Total" UNSAFE_className={styles['subtotal-price']}>
                <SafePrice
                  currency={currency}
                  overrideStyles={BOLD_PRICE_STYLES}
                  price={priceTotal}
                  recurrenceTerm={term}
                />
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

SingleProductCancelItem.propTypes = {
  /**
   * The currency object used for cost display.
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }),
  /**
   * The URL of the image used for the product icon.
   */
  imageSrc: PropTypes.string,
  /**
   * A flag to toggle a loading symbol if the cost is in the process of recalculating.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the price in the header should be regular or mixed with promotion/discount.
   */
  isMultiPrice: PropTypes.bool,
  /**
   * The price per license, will have a cost per specified duration.
   */
  pricePerUnit: PropTypes.number,
  /**
   * The total price of all of the licenses per specified time period.
   */
  priceTotal: PropTypes.number,
  /**
   * The name of the product of the licenses to cancel in the current contract.
   */
  productName: PropTypes.string.isRequired,
  /**
   * The time frequency of which the customer owes money.
   */
  term: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The unit for which the price is being purchased for. Either "per license" or "per transaction".
   */
  unit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)),
  /**
   * The number of units/licenses of a product assigned to users.
   */
  unitsAssigned: PropTypes.number.isRequired,
  /**
   * The total number of units/licenses of a product in the current contract.
   */
  unitsTotal: PropTypes.number.isRequired,
  /**
   * A flag to toggle rendering subtotals and loading symbol. Defaults to false.
   */
  withSubtotalPrice: PropTypes.bool,
};

export default SingleProductCancelItem;
