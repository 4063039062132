import {TextField, View} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import InfoIcon from '@spectrum-icons/workflow/Info';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import './ValidatedSearch.pcss';

// Search component that has configurable error icons and can show a given message.
// Made primarily to show validation errors/messages for entry forms.
const ValidatedSearch = ({
  id,
  marginBottom,
  maxWidth,
  onChange,
  placeholder,
  validationMessage,
  value = '',
  variant,
  ...textFieldProps
}) => {
  const idMemo = useMemo(() => id || `validated-search-${uniqueId()}-id`, [id]);

  const states = {
    confirmation: {
      icon: undefined,
      validationState: 'valid',
    },
    error: {
      icon: undefined,
      // Sets aria-invalid, so no need to set our own here.
      validationState: 'invalid',
    },
    info: {
      icon: <InfoIcon aria-describedby={`${idMemo}-validation-message`} />,
      validationState: undefined,
    },
    warning: {
      icon: <AlertIcon aria-describedby={`${idMemo}-validation-message`} />,
      validationState: undefined,
    },
  };

  return (
    <View
      data-testid="validated-search"
      marginBottom={marginBottom}
      maxWidth={maxWidth}
      role="search"
      width="100%"
    >
      <TextField
        // ValidatedSearch is primarily a wrapper around TextField, needs to support all the attributes and aria-attributes it does.
        {...textFieldProps}
        aria-describedby={validationMessage ? `${idMemo}-validation-message` : undefined}
        aria-label={placeholder}
        // Since the specs for "autocomplete=off" allow for browsers to ignore it when
        // they interpret it as a potential "login" field (for better web security),
        // we use an unofficial value instead to make browsers cautious and not use their own autocomplete.
        autoComplete="nada"
        data-testid="validated-search-input"
        icon={variant && states[variant].icon}
        id={`${idMemo}`}
        onChange={onChange}
        placeholder={placeholder}
        validationState={variant && states[variant].validationState}
        value={value}
        width="100%"
      />
      {validationMessage && (
        <View marginTop="size-40">
          {/* spectrum: use HelpText component when added to v3 -- https://jira.corp.adobe.com/browse/SDS-7633 */}
          <span
            data-testid="search-validation-message"
            // eslint-disable-next-line react/forbid-dom-props -- aria needs id
            id={`${idMemo}-validation-message`}
            styleName={variant ? `${variant}-text` : ''}
          >
            {validationMessage}
          </span>
        </View>
      )}
    </View>
  );
};

ValidatedSearch.propTypes = {
  id: PropTypes.string,
  marginBottom: PropTypes.string,
  maxWidth: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  variant: PropTypes.oneOf(['confirmation', 'error', 'info', 'warning']),
};
export default ValidatedSearch;
