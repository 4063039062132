(function () {
  /**
   * @deprecated this file has been ported to src2, in organizationAccess
   */
  angular.module('app.core.remove-on-read-only').factory('removeOnReadOnly', removeOnReadOnly);

  /* @ngInject */
  function removeOnReadOnly(feature, OrganizationManager) {
    return {
      isReadOnly,
      isReadOnlyMigratingOrHasTerms,
    };

    function isReadOnly() {
      return (
        feature.isEnabled('readOnly') ||
        OrganizationManager.getContractsForActiveOrg().isMigrating() ||
        OrganizationManager.getContractsForActiveOrg().hasTermsToAccept()
      );
    }

    function isReadOnlyMigratingOrHasTerms() {
      return (
        feature.isEnabled('readOnly') ||
        OrganizationManager.getContractsForActiveOrg().isMigrating() ||
        OrganizationManager.getContractsForActiveOrg().hasTermsToAccept()
      );
    }
  }
})();
