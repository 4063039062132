/**
 * @deprecated use src2 Support pages
 *
 * @description a filter which returns the date scheduled string for an expert session. If the
 *   session has been scheduled, it applies the given formatting function; else it returns "Not yet scheduled".
 * @param {ExpertSession} expertSession the expert session to get the scheduled date string for
 * @param {Function} formatDate the function used to format the scheduled date
 * @returns {String} the date scheduled string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appExpertSessionDateScheduled', appExpertSessionDateScheduledFilter);

  /* @ngInject */
  function appExpertSessionDateScheduledFilter($filter, $translate) {
    return filterFn;

    function filterFn(expertSession) {
      if (expertSession.scheduledFor) {
        return $filter('binkyDateTime')(expertSession.scheduledFor);
      }
      return $translate.instant('widgets.support.expertSessionDateScheduled.notYetScheduled');
    }
  }
})();
