import {LicenseGroup, LicenseGroupAdminList} from '@admin-tribe/binky';
import {
  Button,
  ButtonGroup,
  Content,
  Flex,
  Header,
  Heading,
  Text,
  View,
} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {Drawer} from '@pandora/react-drawer';
import {ListContainer} from '@pandora/react-list-container';
import {useCollator} from '@react-aria/i18n';
import {useId} from '@react-aria/utils';
import PropertiesIcon from '@spectrum-icons/workflow/Properties';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';

import AdminsDetailSection from 'common/components/admins-detail-section/AdminsDetailSection';
import ProductProfileServicesSection from 'common/components/product-profile-services-section/ProductProfileServicesSection';
import UsersQuantitiesDetailSection from 'common/components/users-quantities-detail-section/UsersQuantitiesDetailSection';
import rootStore from 'core/RootStore';
import UserNotificationsDetailSection from 'features/products/product-profiles/user-notifications-detail-section/UserNotificationsDetailSection';
import {goToProductProfileDetails} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import {getSortedServicesAndPersonDelegatedQuotas} from '../utils/productProfileUtils';

const ProductProfileDetailDrawer = ({productProfile}) => {
  const collator = useCollator();

  const sortedServicesAndQuotas = getSortedServicesAndPersonDelegatedQuotas(
    productProfile,
    collator
  );

  const nameId = useId();
  const showNotifications = !productProfile.product.isSharedDeviceLicense();

  const fetchAdmins = useCallback(
    () =>
      LicenseGroupAdminList.get({
        configurationId: productProfile.id,
        orgId: rootStore.organizationStore.activeOrgId,
        productId: productProfile.product.id,
      }),
    [productProfile.id, productProfile.product.id]
  );

  const {
    model: adminList,
    isLoading: isAdminListLoading,
    error: adminListError,
  } = useAsyncModel({
    loadFn: fetchAdmins,
  });

  return (
    <Drawer aria-labelledby={nameId}>
      <Header>
        <Heading level={2} marginTop="size-0">
          <Flex alignItems="flex-start" direction="row" UNSAFE_style={{overflowWrap: 'anywhere'}}>
            <PropertiesIcon marginEnd="size-150" size="L" width={50} />
            <Text id={nameId}>{productProfile.name}</Text>
          </Flex>
        </Heading>
        {productProfile.description && (
          <View data-testid="product-description" marginBottom="size-200" marginStart="size-800">
            <Text>{productProfile.description}</Text>
          </View>
        )}
        <ButtonGroup marginBottom="size-300" marginX="size-50">
          <Button
            data-testid="view-profile-button"
            elementType="a"
            onPress={() =>
              goToProductProfileDetails({
                productId: productProfile.product.id,
                profileId: productProfile.id,
              })
            }
            variant="secondary"
          >
            <FormattedMessage id="products.productProfileDetailDrawer.button.view" />
          </Button>
        </ButtonGroup>
      </Header>
      <Content>
        <UsersQuantitiesDetailSection
          quantities={{
            provisionedQuantity: productProfile.provisionedQuantity,
            totalQuantity: productProfile.totalQuantity,
          }}
        />
        <AdminsDetailSection
          admins={adminList?.items}
          isLoading={isAdminListLoading}
          showError={!!adminListError}
        />
        <ListContainer getItemKey={(item) => item.id} items={sortedServicesAndQuotas}>
          <ProductProfileServicesSection product={productProfile.product} />
        </ListContainer>
        {showNotifications && <UserNotificationsDetailSection productProfile={productProfile} />}
      </Content>
    </Drawer>
  );
};

ProductProfileDetailDrawer.propTypes = {
  /**
   * The product profile / license group to display admins list, user quantities
   * and 'Enabled Services' products that can be enabled/disabled from the drawer
   */
  productProfile: PropTypes.instanceOf(LicenseGroup).isRequired,
};

export default ProductProfileDetailDrawer;
