import rootStore from 'core/RootStore';

import help from '../../constants/help';
import {hasAutoAssignedProductAccess} from '../../utils/helpUtils';

const {TOPICS, HELP_ITEM_TYPE} = help;

function constructAutoAssignedProductConfig(autoAssignedProduct, order) {
  return {
    content: [
      {
        text: 'help.topics.autoAssignedProduct.text',
        translateValues: {
          productName: autoAssignedProduct.longName,
        },
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: `${TOPICS.AUTO_ASSIGNED_PRODUCT_PREFIX}_${autoAssignedProduct.id}`,
    order,
    ordered: false,
    parents: [
      {
        id: TOPICS.PRODUCTS,
      },
    ],
    title: 'help.topics.autoAssignedProduct.title',
    translateValues: {
      productName: autoAssignedProduct.longName,
    },
  };
}

/**
 * @description Method to construct help content aimed at auto assigned
 *   products. Currently, the only product it will create content for is Adobe
 *   Stock credit packs, but this could be expanded in the future
 * @returns auto assigned help content for product tab (help rail)
 */
const autoAssignedProduct = () => {
  if (hasAutoAssignedProductAccess()) {
    const cards = [];
    let order = 25; // original ordering in context of other help items (cards)
    const {fiProductGroupProductLists} = rootStore.organizationStore.orgConsumables;
    fiProductGroupProductLists.forEach((fiProductGroupProduct) => {
      if (fiProductGroupProduct.isStockCredit()) {
        cards.push(constructAutoAssignedProductConfig(fiProductGroupProduct, order));
        order += 1; // subsequent stock credit cards will be ordered after the first one
      }
    });
    return cards;
  }
  return undefined;
};

export default autoAssignedProduct;
