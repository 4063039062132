(function () {
  /**
   * @deprecated this file has been ported to src2
   *
   * @ngdoc service
   * @name contractAccess
   * @description contract access factory
   */
  angular.module('app.core.contract.access').factory('contractAccess', ContractAccess);
  /* @ngInject */
  function ContractAccess(_, auth) {
    const service = {
      canChangeContractOwner,
      canEditPayment,
      canViewAnniversaryDate,
      canViewAnniversaryDatePending,
      canViewChangeContractOwnerInfo,
      canViewLocalCurrencyChangeInfo,
      canViewProductAndServices,
      needToSwitchAsAuthenticatingUser,
    };

    return service;

    /**
     * @description Access check for whether or not current user can change the contract owner
     *
     * @param {Contract} contract the contract in which the contract owner could be changed
     * @param {OrganizationAdminList} adminList network response object that resolves to a paginated list of admins
     *
     * @returns {Promise} A promise that resolves to a boolean determining whether or not whether the current user has the
     * ability to change the contract owner
     */
    function canChangeContractOwner(contract, adminList) {
      return adminList.$promise.then(
        (list) => isContractEligibleForNewOwner(contract) && _.size(list.items) > 1
      );
    }

    /**
     * @description Access check for whether or not current user can edit the contract payment
     * @param {Contract} contract the contract in which the payment could be edited.
     *
     * @returns {Boolean} True if payment can be edited, false otherwise.
     */
    function canEditPayment(contract) {
      return (
        !contract.isDrContract &&
        contract.isDirectContract() &&
        // Set checkAuthUser: false to force direct ID comparison because
        // add/renew modals don't support T2E-to-T1 ID switch
        contract.isContractOwner({
          checkAuthUser: false,
          userId: auth.getUserId(),
        }) &&
        !contract.isOrderProcessing()
      );
    }

    /**
     * @description Access check for Overview Anniversary Date
     * @param {Contract} contract the contract used to determine if this feature is shown.
     *
     * @returns {Boolean} True if anniversary date should be shown, false otherwise.
     */
    function canViewAnniversaryDate(contract) {
      return !!(
        contract &&
        contract.getAnniversaryDate() &&
        !contract.isVIPMPContract() &&
        !contract.isModelTrial()
      );
    }

    /**
     * @description Access check for Overview Pending Anniversary Date
     * For indirect org, if the anniversary date is not set by the Reseller, it will be set when the first order is placed.
     * Before the anniversary date is set, we would like to show the anniversary date pending string to the customer.
     * @param {Contract} contract the contract used to determine if this feature is shown.
     *
     * @returns {Boolean} True if pending anniversary date should be shown, false otherwise.
     */
    function canViewAnniversaryDatePending(contract) {
      return !!(
        contract &&
        !contract.getAnniversaryDate() &&
        contract.isIndirectButNotVIPMPContract()
      );
    }

    /**
     * @description Check whether the user can see additional local currency change contract information
     * @param {Contract} contract current contract to show additional info for
     *
     * @returns {Boolean} returns true if the user can see the additional change contract owner info, else resolves to false
     */
    function canViewLocalCurrencyChangeInfo(contract) {
      return (
        contract.isInRenewalWindow() &&
        canEditPayment(contract) &&
        !!_.get(contract, 'billingInfo.nextBillingAmount.currency.futureCurrency')
      );
    }

    /**
     * @description Check whether the user can see additional change contract owner information
     * @param {Contract} contract current contract to show additional info for
     * @param {OrganizationAdminList} adminList network response object that resolves to a paginated list of admins
     *
     * @returns {Promise} A promise resolves to true if the user can see the additional change contract owner info, else resolves to false or the promise is rejected
     */
    function canViewChangeContractOwnerInfo(contract, adminList) {
      return adminList.$promise.then(
        (list) => isContractEligibleForNewOwner(contract) && _.size(list.items) === 1
      );
    }

    /**
     * @description Access check for contract-info Products and Services section
     * @param {Contract} contract the contract used to determine if this feature is shown.
     * @param {Product[]} productsAndLicenses list of products and licenses associated with the account
     *
     * @returns {Boolean} True if Products and Services section should be shown, false otherwise.
     */
    function canViewProductAndServices(contract, productsAndLicenses) {
      return (
        (contract.isIndirectContract() || contract.isRenewableDirectContract()) &&
        !contract.isEnterpriseContract() &&
        !_.isEmpty(productsAndLicenses)
      );
    }

    /**
     * @description Check whether the user is both: (1) the contract owner, and
     * (2) the user is not logged in as the authenticating user.
     *
     * @param {Object} options input options
     * @param {Contract} options.contract the contract to check from
     *
     * @returns {Boolean} true the current user is the contract owner, and
     * the user is not logged in as the authenticating user.
     * false when (1) the user is not the contract owner, or (2) the user
     * is the contract owner and is already logged in as the authenticating
     * user.
     */
    function needToSwitchAsAuthenticatingUser({contract} = {}) {
      return (
        _.invoke(contract, 'isContractOwner', {checkAuthUser: true}) &&
        _.invoke(contract, 'getOwnerUserId') !== auth.getUserId()
      );
    }

    ////////

    function isContractEligibleForNewOwner(contract) {
      return (
        contract.isContractOwner({checkAuthUser: true}) &&
        !contract.changeOwnerInvitation &&
        !contract.isPaymentCategoryPaypal() &&
        !contract.isOrderProcessing() &&
        !contract.isDrContract &&
        (contract.isDirectContract() || contract.isIndirectContract())
      );
    }
  }
})();
