import {Flex, View} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import {runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useCreatePackageModalContext} from 'features/packages/components/create-package-modal/CreatePackageModalContext';
import {
  CREATE_PACKAGE_CONSTANTS,
  KCCC_SAP_CODE,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';

import AppSystemReqDetails from '../app-system-req-details/AppSystemReqDetails';
import {
  allowLicenseFileSelectionModification,
  getLicenseFileDetails,
  selectedProductsFilter,
  showLicenseFileEntry,
} from '../productSelectionPageUtils';
import ProductsList from '../products-list/ProductsList';

import styles from './SelectedProducts.pcss';
import SelectedProductsHeader from './SelectedProductsHeader';

// SelectedProducts contains the products selected in product selection screen of create package flow
const SelectedProducts = observer(
  ({
    isEnterpriseSupportPolicyApplicable,
    selectedProductsSearchFieldRef,
    updateManagementSettings,
  }) => {
    // services
    const intl = useIntl();
    const {createPackageModalStore} = useCreatePackageModalContext();

    // states
    const [fiteredSelectedProducts, setFiteredSelectedProducts] = useState([]);
    const [isAppWithSpecialSysReqSelected, setIsAppWithSpecialSysReqSelected] = useState(false);

    function removeProduct(product) {
      if (product.sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE) {
        removeLicenseFile();
        return;
      }
      runInAction(() => {
        product.selected = false;
      });
      if (createPackageModalStore.selectedProducts.includes(product)) {
        createPackageModalStore.removeProduct(product);

        // For lightweight pacakges remove KCCC if last product is removed
        if (
          createPackageModalStore.pkgSessionSettings.packageType ===
            PACKAGE_TYPE_CONSTANTS.MANAGED &&
          createPackageModalStore.selectedProducts.length === 1 &&
          createPackageModalStore.selectedProducts[0].sapCode === KCCC_SAP_CODE
        ) {
          removeProduct(createPackageModalStore.selectedProducts[0]);
        }
      }
      updateManagementSettings();
    }

    function removeAllProducts() {
      let productsToRemain = 0;
      fiteredSelectedProducts.forEach((productToRemove) => {
        // remove all except Core components (KCCC), in case of non lightweight pacakges
        if (
          productToRemove.sapCode === KCCC_SAP_CODE &&
          !(
            createPackageModalStore.pkgSessionSettings.packageType ===
            PACKAGE_TYPE_CONSTANTS.MANAGED
          )
        ) {
          productsToRemain = 1;
        } else {
          runInAction(() => {
            productToRemove.selected = false;
          });
        }
      });
      createPackageModalStore.removeAllProducts(productsToRemain);
      updateManagementSettings();
      removeLicenseFile();
    }

    function canLicenseFileBeRemoved() {
      return (
        // license file will always be the first product in list, so checking at index 0
        fiteredSelectedProducts[0].sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE &&
        !createPackageModalStore.pkgSessionSettings.addRemoveOptionVisible
      );
    }

    function isRemoveAllDisabled() {
      // Check if the only product is a KCCC or removable license file
      const singleProductCase =
        fiteredSelectedProducts.length === 1 &&
        ((fiteredSelectedProducts[0].sapCode === KCCC_SAP_CODE &&
          createPackageModalStore.pkgSessionSettings.packageType !==
            PACKAGE_TYPE_CONSTANTS.MANAGED) ||
          canLicenseFileBeRemoved());
      // Check if both the KCCC and removable license file are present
      const twoProductCase =
        fiteredSelectedProducts.length === 2 &&
        canLicenseFileBeRemoved() &&
        fiteredSelectedProducts[1].sapCode === 'KCCC';
      return fiteredSelectedProducts.length === 0 || singleProductCase || twoProductCase;
    }

    const applySelectedProductsFilter = (product) =>
      selectedProductsFilter(createPackageModalStore.selectedProductsSearchText, product);

    function removeLicenseFile() {
      if (
        allowLicenseFileSelectionModification(
          createPackageModalStore.pkgSessionSettings.packageType
        )
      ) {
        createPackageModalStore.setPkgSessionSettingValues('licenseFileSelected', false);
        if (createPackageModalStore.selectedProducts.length === 0) {
          createPackageModalStore.setPkgSessionSettingValues('nextButtonEnabled', false);
        }
      }
    }

    function setSelectedProductAddOnsMap(productKey, selectedAddOnsList) {
      if (typeof productKey === 'string') {
        const addOnsMap = selectedAddOnsList.map((selectedAddOn) => ({
          displayName: selectedAddOn.displayName,
          id: selectedAddOn.id,
        }));
        createPackageModalStore.setPackageCreateObjValues('selectedProductAddOnsMap', {
          ...createPackageModalStore.packageCreateObj.selectedProductAddOnsMap,
          [productKey]: addOnsMap,
        });
      }
    }

    // updates product view on when products added/removed, or filters change
    useEffect(() => {
      const newFiteredSelectedProducts = createPackageModalStore.selectedProducts.filter(
        applySelectedProductsFilter
      );
      setIsAppWithSpecialSysReqSelected(
        newFiteredSelectedProducts.some((product) => product.hasSpecialSystemRequirements())
      );
      function showLicenseInSelectedProductsList() {
        return (
          showLicenseFileEntry(
            createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes,
            createPackageModalStore.pkgSessionSettings.packageType
          ) && createPackageModalStore.pkgSessionSettings.licenseFileSelected
        );
      }

      if (showLicenseInSelectedProductsList()) {
        newFiteredSelectedProducts.unshift(
          getLicenseFileDetails({
            addRemoveOptionVisible:
              !!createPackageModalStore.pkgSessionSettings.addRemoveOptionVisible,
            intl,
            licenseFileSelected: !!createPackageModalStore.pkgSessionSettings.licenseFileSelected,
            packageType: createPackageModalStore.pkgSessionSettings.packageType,
          }).products[0]
        );
      }

      // For lightweight packages, move KCCC to top
      if (
        createPackageModalStore.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
        newFiteredSelectedProducts.length > 0
      ) {
        const indexKCCC = newFiteredSelectedProducts.findIndex(
          (obj) => obj.sapCode === KCCC_SAP_CODE
        );
        if (indexKCCC > -1) {
          const groupKCCC = newFiteredSelectedProducts.splice(indexKCCC, 1)[0];
          newFiteredSelectedProducts.unshift(groupKCCC);
        }
      }

      setFiteredSelectedProducts(newFiteredSelectedProducts);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger when listed props change
    }, [
      createPackageModalStore.selectedProducts.length,
      createPackageModalStore.selectedProductsSearchText,
      createPackageModalStore.pkgSessionSettings.licenseFileSelected,
    ]);

    return (
      <div
        aria-label={intl.formatMessage(
          {
            id: 'packages.createPackage.productSelection.selectedApps.title',
          },
          {productsLength: createPackageModalStore.selectedProducts.length}
        )}
        role="group"
      >
        <Flex direction="column" gap="size-150" width="size-5000">
          <SelectedProductsHeader
            fiteredSelectedProductsLength={createPackageModalStore.selectedProducts.length}
            isRemoveAllDisabled={isRemoveAllDisabled}
            removeAllProducts={removeAllProducts}
            selectedProductsSearchFieldRef={selectedProductsSearchFieldRef}
            selectedProductsSearchText={createPackageModalStore.selectedProductsSearchText}
            setSelectedProductsSearchText={createPackageModalStore.setSelectedProductsSearchText}
          />
          <View
            height="size-3600"
            UNSAFE_style={{backgroundColor: 'var(--color-grey-50)'}}
            width="size-5000"
          >
            <View
              height={isAppWithSpecialSysReqSelected ? 'calc(100% - size-550)' : '100%'}
              overflow="auto"
            >
              <ProductsList
                onRemoveProduct={removeProduct}
                packageCreateObj={createPackageModalStore.packageCreateObj}
                products={fiteredSelectedProducts}
                setSelectedProductAddOnsMap={setSelectedProductAddOnsMap}
                showAppAddOns
                showLearnMoreLink
                showLTSLink={isEnterpriseSupportPolicyApplicable}
                showRemoveButton
                showSysReqLink
              />
            </View>
            {isAppWithSpecialSysReqSelected && (
              <Flex
                alignItems="center"
                gap="size-100"
                height="size-550"
                /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required for styling app sys req details */
                UNSAFE_className={styles['app-system-req-details']}
              >
                <AlertIcon color="notice" marginStart="size-160" size="S" />
                <AppSystemReqDetails
                  products={fiteredSelectedProducts.filter((product) =>
                    product.hasSpecialSystemRequirements()
                  )}
                />
              </Flex>
            )}
          </View>
        </Flex>
      </div>
    );
  }
);

SelectedProducts.propTypes = {
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Reference to selected products search field
   */
  selectedProductsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * Method to update management settings for the given context
   */
  updateManagementSettings: PropTypes.func.isRequired,
};
export default SelectedProducts;
