import {SEARCH_TARGET_CLASS, SEARCH_TARGET_RESULT_TYPE, UserGroup, log} from '@admin-tribe/binky';
import binkyUI, {getOrganizationUserErrorProps} from '@admin-tribe/binky-ui';
import UserGroupIcon from '@react/react-spectrum/Icon/UserGroup';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {ROLE} from 'features/users/users.constants';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;
const UserPicker = binkyUI.common.components.UserPicker;

/**
 * Defines the modal for adding admins to a user group.
 */
const AddAdminsToUserGroupModal = observer(
  ({isOpen, onCancel, onClosed /* @deprecated */, onSuccess, orgId, userGroupId}) => {
    const intl = useIntl();

    const [modalError, setModalError] = React.useState(null);
    const [modalErrorProps, setModalErrorProps] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalOpen, setModalOpen] = React.useState(isOpen);
    const [orgUserList, setOrgUserList] = React.useState();
    const [userGroup, setUserGroup] = React.useState();
    const isCurrentRef = React.useRef(true);
    const memberAndSelectedItemsListRef = React.useRef();

    const onChange = (addUserFormTableData) => {
      const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
        addUserFormTableData,
        orgId,
        (memberAndSelectedItem) => {
          const member = memberAndSelectedItem.member;
          const role = {
            targets: [{id: userGroupId}],
            type: ROLE.ADMIN.USER_GROUP,
          };
          member.roles.push(role);
          return member;
        }
      );
      setOrgUserList(newOrgUserList);
      memberAndSelectedItemsListRef.current = addUserFormTableData;
    };

    const onConfirm = async () => {
      setIsLoading(true);
      try {
        await orgUserList.save();
        if (isCurrentRef.current) {
          onSuccess?.();
          setModalOpen(false);
          setIsLoading(false);
        }
        return true;
      } catch (error) {
        if (isCurrentRef.current) {
          const props = getOrganizationUserErrorProps(intl, error);
          setModalError(props.message);
          setModalErrorProps(props);
          setIsLoading(false);
        }
        return false;
      }
    };

    const ctaToastGenerator = () =>
      intl.formatMessage(
        {id: 'common.toast.modal.adminsAdded'},
        {adminCount: orgUserList.items.length}
      );

    // gets user group for rendering the modal
    React.useEffect(() => {
      async function fetchUserGroup() {
        let retrievedUserGroup;

        try {
          retrievedUserGroup = await UserGroup.get(orgId, userGroupId);
        } catch (error) {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zakn@ to update
          // istanbul ignore else
          if (isCurrentRef.current) {
            log.error('Failed to get user group when adding user group admins. Error: ', error);
            setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
            setModalErrorProps(undefined);
          }
        }

        if (isCurrentRef.current) {
          setUserGroup(retrievedUserGroup);
          setIsLoading(false);
        }
      }

      if (isOpen) {
        setIsLoading(true);
        fetchUserGroup();
      }

      return () => {
        isCurrentRef.current = false;
      };
    }, [intl, isOpen, userGroupId, orgId]);

    return (
      <ModalContainer>
        {isModalOpen && (
          <ModalDialog
            cancelLabel={intl.formatMessage({
              id: 'common.addAdminsToUserGroupModal.cancelButton',
            })}
            ctaLabel={intl.formatMessage({
              id: 'common.addAdminsToUserGroupModal.confirmButton',
            })}
            ctaToastGenerator={ctaToastGenerator}
            errorMessage={modalError}
            errorToastProps={modalErrorProps}
            heightVariant="static"
            id="add-admins-to-user-group-modal"
            isCtaDisabled={
              !orgUserList?.hasUnsavedChanges() || memberAndSelectedItemsListRef.current.isInvalid()
            }
            isLoading={isLoading}
            onCancel={onClosed || onCancel}
            onCta={onConfirm}
          >
            {userGroup && (
              <ModalTagHeader IconComponent={<UserGroupIcon alt="" size="M" />}>
                {userGroup.name}
              </ModalTagHeader>
            )}

            <ModalHeading>
              {intl.formatMessage({id: 'common.addAdminsToUserGroupModal.header'})}
            </ModalHeading>

            <ModalDescription>
              {intl.formatMessage({id: 'common.addAdminsToUserGroupModal.description'})}
            </ModalDescription>

            <ModalContent>
              <AddUserFormTableWrapper orgId={orgId}>
                <AddUserFormTable
                  data-testid="add-user-form-table-testid"
                  onChange={onChange}
                  orgId={orgId}
                  pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                  searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                  targetOptions={{
                    searchTargetType: SEARCH_TARGET_RESULT_TYPE.ADMIN,
                    targetClass: SEARCH_TARGET_CLASS.USER_GROUP,
                    targetId: userGroupId,
                  }}
                  titleTextId="common.AddUserFormTable.title.admin"
                />
              </AddUserFormTableWrapper>
            </ModalContent>
          </ModalDialog>
        )}
      </ModalContainer>
    );
  }
);

AddAdminsToUserGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  /**
   * Optional callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  onClosed: PropTypes.func, // @deprecated in favor of onCancel
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  userGroupId: PropTypes.string.isRequired,
};

export default AddAdminsToUserGroupModal;
