(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories:appOwnedDirectoryListTable
   * @description display list of active owned directories for an organization.
   */
  angular.module('app.widgets.directories').component('appOwnedDirectoryListTable', {
    bindings: {
      directoryList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/owned-directory-list-table/owned-directory-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    DIRECTORY_LIST_PAGINATION_ID,
    DIRECTORY_OWNERSHIP_STATUS,
    DIRECTORY_STATUS,
    JIL_CONSTANTS
  ) {
    const vm = this;

    _.assign(vm, {
      isSubsetChecked: false,
      onPageChange,
      onPageSizeChange,
      paginationId: DIRECTORY_LIST_PAGINATION_ID,
      reverseSort,
      sortReverse: false,
      wait: {},
    });

    ///////////////

    function onPageChange(newPage) {
      vm.directoryList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.directoryList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.directoryList.setPage(0);
      refreshData();
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.directoryList.sortBy(JIL_CONSTANTS.SORT.NAME, vm.sortReverse);
      refreshData();
    }

    ///////////////

    function refreshData() {
      vm.directoryList.refresh();
    }
  }
})();
