import {Badge, Flex, Switch, Text, View} from '@adobe/react-spectrum';
import {FormattedDateTime} from '@pandora/react-formatted-date-time';
import {GoUrl} from '@pandora/react-go-url';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import SectionView from 'features/settings/common/components/section-view/SectionView';
import {checkDeIsOn} from 'features/settings/common/utils/domainEnforcementUtils';
import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';

import styles from './SettingsSection.pcss';

const SettingsSection = ({handleOnToggleDe}) => {
  const intl = useIntl();
  const headingId = useId();

  const {domainEnforcement} = useDomainEnforcementContext();

  const isDeOn = checkDeIsOn(domainEnforcement.state);

  return (
    <View data-testid="settings-section">
      <SectionView padding="0">
        <Flex direction="column">
          {domainEnforcement.isDeNotified && (
            <Badge
              marginBottom="-20px"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
              UNSAFE_className={styles.badge}
              variant="positive"
            >
              <Text>
                <FormattedMessage
                  id="settings.domainEnforcement.settingsSection.badge"
                  values={{
                    date: (
                      <FormattedDateTime
                        date={new Date(domainEnforcement?.stateEndsAt)}
                        format={{type: 'literal', value: '/'}}
                      />
                    ),
                  }}
                />
              </Text>
            </Badge>
          )}
          <View padding="size-200">
            <Flex direction="column" gap="size-100" height="100%" padding="size-200">
              <Text>
                <FormattedMessage
                  id="settings.domainEnforcement.settingsSection.description"
                  values={{
                    goUrl: (linkText) => (
                      <GoUrl name="aac_restricting_domains" UNSAFE_style={{color: 'inherit'}}>
                        {linkText}
                      </GoUrl>
                    ),
                  }}
                />
              </Text>
              <Switch
                aria-describedby={headingId}
                data-testid="domain-enforcement-toggle"
                isDisabled={domainEnforcement.isLoading}
                isEmphasized
                isSelected={isDeOn}
                onChange={handleOnToggleDe}
              >
                {intl.formatMessage({
                  id: isDeOn
                    ? 'settings.domainEnforcement.settingsSection.toggle.ON'
                    : 'settings.domainEnforcement.settingsSection.toggle.OFF',
                })}
              </Switch>
            </Flex>
          </View>
        </Flex>
      </SectionView>
    </View>
  );
};

SettingsSection.propTypes = {
  handleOnToggleDe: PropTypes.func.isRequired,
};

export default SettingsSection;
