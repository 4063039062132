import {JilModelList, log} from '@admin-tribe/binky';

import jilContentLogs from '../../api/jil/jilContentLogs';

import ContentLog from './ContentLog';
import ContentLogConstants from './ContentLogConstants';

/**
 * @description Method to create a new instance of a ContentLogList.
 */
class ContentLogList extends JilModelList {
  /**
   * @class ContentLogList
   * @description Method to create a new instance of an ContentLogList.
   *
   * @param {Object} options See JilModelList for additional options
   * @param {String} options.orgId Org ID
   * @param {Number} [options.pageNumber] Page number of cached model to fetch
   * @param {Number} [options.pageSize] Number of reports to display per page
   * @param {String} [options.sortExpression] The sorting criteria
   * @param {String} [options.sortOrder] The sort order
   */
  constructor(options) {
    super({
      ...options,
      isCacheable: true,
      itemClassRef: ContentLog,
      modelCacheId: ContentLogConstants.CONTENTLOGLIST_CACHE_ID,
      resource: jilContentLogs.getContentLogsList,
    });

    this.orgId = options.orgId;
  }

  /**
   * @description Method to refresh Content Log list from back-end.
   *
   * @returns {Promise} resolves to ContentLog on success, else rejects with error
   */
  async refresh() {
    try {
      await super.refresh({
        orgId: this.orgId,
      });
    } catch (error) {
      log.error('ContentLog refresh failed. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Remove all the content logs with the selected IDs.
   *
   * @param {Array<Object>} selected The list of rows which have to be removed
   */
  async remove(selected) {
    const promises = selected.map(async (item) => {
      try {
        await jilContentLogs.deleteContentLogs({id: item.id, orgId: this.orgId});
      } catch (error) {
        // a 404 indicates the content log can't be found - which is ideal, as we're trying to delete it
        if (error?.response?.status === 404) {
          return;
        }
        log.error(`Failed to delete content log with id: ${item.id}. Error: `, error);
        throw new Error('ContentLog failed to delete.');
      }
    });
    try {
      await Promise.all(promises);
    } catch (error) {
      throw new Error('ContentLog failed to delete.');
    } finally {
      this.clearCache();
      await this.refresh();
    }
  }

  /**
   * @description Creates a new content log and refreshes the view.
   *
   * @param {String} startDate start date in 'yyyy-MM-dd' format
   * @param {String} endDate end date in 'yyyy-MM-dd' format
   * @returns {Object} The updated object received as response from the backend.
   */
  async save({startDate, endDate}) {
    const payload = {
      end_date: getUTCDate(endDate),
      start_date: getUTCDate(startDate),
    };

    try {
      await jilContentLogs.postContentLog({
        orgId: this.orgId,
        payload,
      });

      this.clearCache();
      await this.refresh();
    } catch (error) {
      log.error('ContentLog failed to save. Error: ', error);
      throw new Error('ContentLog failed to save.');
    }

    ///////

    /**
     * @description Convert date string to UTC format.  If date
     *   is in the future, return current date instead.
     *
     * @param {String} localDateString date in 'yyyy-MM-dd' format
     * @returns {String}  'yyyy-MM-dd' representation of the UTC date
     *        (or `+yyyyyy-MM-dd` for years before 0 or after 9999AD)
     */
    function getUTCDate(localDateString) {
      const localDate = new Date(localDateString);
      const now = new Date();
      const dateToFormat = localDate > now ? now : localDate;
      return dateToFormat.toISOString().split('T')[0];
    }
  }
}

export default ContentLogList;
