/* eslint-disable max-lines */

(function () {
  /**
   * @deprecated this file has been ported to src2
   *
   * @ngdoc service
   * @name app.core.product:productAccess
   * @description product access factory
   */

  angular.module('app.core.product.access').factory('productAccess', ProductAccess);
  /* @ngInject */
  function ProductAccess(
    $location,
    $q,
    $window,
    _,
    AnalyticsEvent,
    auth,
    contractUtils,
    feature,
    MEMBER_TYPE,
    onesieSrc2,
    OrganizationManager,
    PRODUCT_DELEGATION_TARGET,
    QUALIFIER_TARGET,
    trialHelper
  ) {
    const service = {
      canAssign,
      canAssignAdmins,
      canAssignDeveloper,
      canAssignType1User,
      canAssignUser,
      canAssignUserIgnoringLicenseCounts,
      canBuyTrialProduct,
      canEditProducts,
      canPurchaseLicenses,
      canRemoveAdmin,
      canRemoveUser,
      canShowAdmins,
      canShowBuyingProgram,
      canShowConsumableQuotaTypeItems,
      canViewAddProductMiniApp,
      canViewAutoAssignmentRules,
      canViewIntegrations,
      canViewPermissions,
      canViewProduct,
      canViewProductLicenseCount,
      canViewQuickAssignModal,
      canViewQuickAssignModalInFirstSessionAccess,
      hasNoAvailableLicenses,
      isAPIOnlyProduct,
      isHybridProduct,
      setAddProductUrl,
    };
    let abEventSent = false;

    return service;

    //////////////

    /**
     * @description Method to determine if the given user can be assigned to the given product
     * @param {Product} product the product to check
     * @param {User} user the user to check
     *
     * @returns {Promise} Resolves with true if the produce can be assigned with Type 1 users or
     *    the user is not type 1 user
     */
    function canAssign(product, user) {
      if (OrganizationManager.getContractsForActiveOrg().isMigrating()) {
        return $q.resolve(false);
      }
      return product.$promise.then((model) => {
        const userType = user.getType();
        if (userType.isUser()) {
          return model.isDelegatableToType(userType.type);
        } else if (userType.isTechnicalAccount()) {
          return model.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY);
        } else if (userType.isUserGroup()) {
          return model.fulfillableItemList.hasUserGroupAssignment();
        }
        // For any unexpected type, we deny access
        return false;
      });
    }

    function canAssignAdmins(product) {
      if (!product.fulfillableItemList.hasLaboratoryLicenseManagement()) {
        return canAssignUserIgnoringLicenseCounts(product);
      }
      return true;
    }

    function canAssignDeveloper(product) {
      return (
        product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY) && product.isAdministerable()
      );
    }

    /**
     * @deprecated this function has been ported to src2/app/core/products/access/productAccess.js
     * @description  Method to determine if a admin can edit products.
     *
     * @returns {Boolean}  True if admin can edit products, false otherwise.
     */
    function canEditProducts() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating() && auth.canAddUsers();
    }

    /**
     * @description Method to determine if a product admin can be removed
     *
     * @returns {Boolean} True if admin can be removed, false otherwise.
     */
    function canRemoveAdmin() {
      return (
        !OrganizationManager.getContractsForActiveOrg().isMigrating() &&
        (auth.isUserOrgAdmin() || auth.isUserProductAdmin())
      );
    }

    /**
     * @description Method to determine if a product can be assigned with Type 1 users
     * @param {Product} product the product to check
     *
     * @returns {Promise} Resolves with true if the product can be assigned with Type 1 users
     */
    function canAssignType1User(product) {
      if (OrganizationManager.getContractsForActiveOrg().isMigrating()) {
        return $q.resolve(false);
      }
      return product.$promise.then((model) => model.isDelegatableToType(MEMBER_TYPE.TYPE1));
    }

    /**
     * @description Method to determine if a product can be assigned with user.
     * @param {Product} product the product to check
     *
     * @returns {Boolean} True if can be assigned with user, false otherwise.
     */
    function canAssignUser(product) {
      return canAssignUserIgnoringLicenseCounts(product) && isEnterpriseProductOrHasLicenses();

      function isEnterpriseProductOrHasLicenses() {
        // Trial products may have 'available' licenses even after the trial has expired so we also need to check if
        // product is an expired trial.
        return !product.hasNoAvailableLicenses() && !trialHelper.isExpiredTrial(product);
      }
    }

    /**
     * @description Method to determine if a product can be assigned with user ignoring license counts
     * @param {Product} product the product to check
     *
     * @returns {Boolean} True if can be assigned with user, false otherwise.
     */
    function canAssignUserIgnoringLicenseCounts(product) {
      return (
        auth.canAddUsers() &&
        !OrganizationManager.getContractsForActiveOrg().isMigrating() &&
        product.isAdministerable() &&
        product.fulfillableItemList.hasDelegationType() &&
        (feature.isDisabled('temp_onesie_35741') || product.isDelegatableToUser()) &&
        !isExcludedProduct()
      );

      //////////////

      function isExcludedProduct() {
        return (
          product.isFeatureRestrictedLicense() ||
          product.isLegacyDeviceLicense() ||
          product.fulfillableItemList.hasLaboratoryLicenseManagement() ||
          product.fulfillableItemList.hasOrgOnDemandConsumable() ||
          product.fulfillableItemList.hasOrgDelegatable()
        );
      }
    }

    /**
     * @description Method to determine if this is a team trial that can be converted to a
     *   paid product.
     * @param {Product} product the product to check
     *
     * @returns {Boolean} True if this is a trial which can be converted to a paid product.
     */
    function canBuyTrialProduct(product) {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return contractList.hasOnlyTrialContracts() && trialHelper.isTrialProduct(product);
    }

    /**
     * @description Method to determine if a product can have additional licenses
     *    purchased.
     * @param {Product} product the product to check
     *
     * @returns {Promise} Resolves with true if licenses can be purchased, false otherwise.
     */
    function canPurchaseLicenses(product) {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      if (contractList.isMigrating() || !contractUtils.isAllowedToAddProducts()) {
        return $q.resolve(false);
      }

      // If product has a PREVENT_PA_CREATION qualifier, licenses can't
      // be purchased.
      return product.$promise.then(
        (model) =>
          !model.hasTargetQualifier(QUALIFIER_TARGET.PREVENT_PA_CREATION) &&
          (model.isTeam() || model.isEnterpriseIndirect()) &&
          !model.hasUnlimitedLicenses()
      );
    }

    /**
     * @description Method to determine if users can be removed
     *
     * @returns {Boolean} Resolves with true if users can be removed
     */
    function canRemoveUser() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating() && auth.canAddUsers();
    }

    /**
     * @description Method to determine if Admins can be displayed for a product.
     * @param {Product} product the product to check
     *
     * @returns {Boolean} Returns true if Admins can be displayed, else false.
     */
    function canShowAdmins(product) {
      if (
        product.customerSegment === 'TEAM' ||
        product.isLegacyDeviceLicense() ||
        product.isFeatureRestrictedLicense() ||
        product.fulfillableItemList.hasOrgDelegatable()
      ) {
        return false;
      }
      return (
        auth.isUserOrgAdmin() ||
        (auth.isUserProductAdmin() &&
          auth.isUserProductAdminForTarget(_.get(product, 'targetExpression')))
      );
    }

    /**
     * @description To determine whether a product can show its buying program.
     * @param {Product} product model instance.
     * @returns {Boolean} Returns true if buying program can be shown in appropriate places, else false.
     */
    function canShowBuyingProgram(product) {
      const productList = OrganizationManager.getProductsForActiveOrg();
      return onesieSrc2.core.products.access.canShowBuyingProgram(productList, product);
    }

    /**
     * @description Method to determine if the product has consumable quota items to show.
     *   Note that this returns false for Enterprise Stock.
     * @param {Product} product the product to check
     *
     * @returns {Boolean} Returns true if there are consumable quota items to show, else false.
     */
    function canShowConsumableQuotaTypeItems(product) {
      return (
        product.fulfillableItemList.hasConsumableQuotaTypeItems({
          includeDelegationTypePerson: false,
          requireDelegationConfigurable: false,
        }) &&
        !product.hasLicenseBasedSign() &&
        !product.isFeatureRestrictedLicense() &&
        !product.fulfillableItemList.hasOrgDelegatable() &&
        !(product.isEnterprise() && product.isAdobeStock())
      );
    }

    /**
     * @description Method to check the url have /add-product for opening the add product mini application in modal popup
     * when mini_app_all OR us_direct flag is on along with deep_link feature flag
     * @returns {Boolean} Resolved with boolean indicating whether to open modal or not
     */
    function canViewAddProductMiniApp() {
      const path = $location.path();
      const isAddProductInUrl = _.includes(path, '/add-products');
      if (
        isAddProductInUrl &&
        onesieSrc2.core.products.access.canUseAddProductMiniApp() &&
        onesieSrc2.core.products.access.canUseAddProductMiniAppForDeepLink()
      ) {
        return true;
      }
      return false;
    }

    /**
     * @description Method to check whether the current user can view auto assignment rules
     * @returns {Boolean} Resolved with boolean indicating whether the user has access or not
     */
    function canViewAutoAssignmentRules() {
      return onesieSrc2.core.products.access.canViewAutoAssignmentRules();
    }

    /**
     * @description determines whether a product has FI for 'api_access', i.e. whether it is an "API product"
     *  If a product is not specified as an argument, then perform check on all products in the list.
     * @param {Product} [product] model instance. If undefined, checks all products.
     * @returns {Promise} resolved with a boolean indicating whether the FI is present.
     */
    function canViewIntegrations(product) {
      const promise = product
        ? $q.all([product.$promise])
        : OrganizationManager.getProductsForActiveOrg().$promise.then(
            (productList) => productList.items
          );
      return promise.then(allowsAPIKeyDelegation);

      function allowsAPIKeyDelegation(products) {
        return _.some(products, (model) =>
          model.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
        );
      }
    }

    /**
     * @description Method to determine if this Product should have permissions for us to fetch.
     * @param {Product} product the product to check
     *
     * @returns {Boolean} Resolves with true if this Product is a of a type and status
     *    to have permissions, else false
     */
    function canViewPermissions(product) {
      return product.$promise.then(
        (model) =>
          feature.isEnabled('force_permissions') ||
          model.hasConfigurationSettingForLicenseGroup() ||
          ((model.hasConfigurationSettingForLegacyPermissions() ||
            // When a product is migrated to the new model, and the flag is off,
            // we need to determine that they're still in the old model
            (model.hasConfigurationSettingForLicenseGroup(false) &&
              model.fulfillableItemList.hasUserPermissionManagement())) &&
            // legacy permissions are only shown for non-DX products, or DX ones that have been migrated
            (!model.isMarketingCloudProduct() || model.isMigrationComplete()))
      );
    }

    /**
     * @description Method to determine if the admin can view this product.
     *   These are the same roles that are in the products route file.
     *
     * @returns {Boolean} True if admin can view product, false otherwise.
     */
    function canViewProduct() {
      return (
        auth.isUserOrgAdmin() ||
        auth.isUserContractAdmin() ||
        auth.isUserProductAdmin() ||
        auth.isUserPlcAdmin()
      );
    }

    /**
     * @description Method to determine if the admin can view this product's license count
     * @param {Product} product the product to check
     *
     * @returns {Boolean} True if admin can view product license count, otherwise false
     */
    function canViewProductLicenseCount(product) {
      // Excludes products which don't use seat based delegation, and those which use the consumables API
      return canViewProduct() && product.usesSeatBasedDelegation() && !product.isConsumable();
    }

    /**
     * @deprecated to be removed with temp_quick_assign_sophia - the added
     *     logic for determining if quick assign should be shown during session
     *     start is now handled by Sophia
     *
     * @description Method to determine if Quick Assign Modal will open
     *     automatically. It will open when there are no products provisioned
     *     (with the exception of the trials case where one trial product will
     *     be provisioned).
     *
     * @returns {Promise} Resolves to true if above criteria is met, false
     *     otherwise
     */
    function canViewQuickAssignModalInFirstSessionAccess() {
      return $q
        .all([this.canViewQuickAssignModal(), trialHelper.canTrialAdminViewQAModalInFirstSession()])
        .then((result) => {
          const [canView, canTrialAdminView] = result;
          if (canView) {
            if (canTrialAdminView) {
              return true;
            }
            const productList = OrganizationManager.getProductsForActiveOrg();
            return productList.getTotalProvisionedQuantity() <= 0;
          }
          return false;
        });
    }

    /**
     * @description Method to determine if admin can view the Quick Assign Modal.
     *
     * @returns {Promise} Resolves to true if the admin can view the Quick Assign Modal, false otherwise
     */
    function canViewQuickAssignModal() {
      return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) => {
        const atLeastOneCanAssign = _.some(productList.items, (product) => canAssignUser(product));

        let normalCanView;
        // We are seeing issues with the quick assign miniapp pandora component:
        // Bearer Token being undefined and string loading issues.
        // We have a workaround in place to use the src1 QuickAssign modal. Previously the QuickAssign Src1
        // modal was only used when a client was outside the renewal window. However with its integration into
        // retention flows we require it to be accessable within the renewal window. When bug_fix_sqa_workaround
        // flag is removed we should go back to the SQA src2 QuickAssignMiniApp therefore revert the renewal window logic.
        if (feature.isEnabled('bug_fix_sqa_workaround')) {
          normalCanView = productList.hasOnlyTeamProducts() && atLeastOneCanAssign;
        } else {
          const contractIsInRenewalWindow = _.some(
            OrganizationManager.getContractsForActiveOrg().items,
            (contract) => contract.isInRenewalWindow()
          );
          normalCanView =
            !contractIsInRenewalWindow && productList.hasOnlyTeamProducts() && atLeastOneCanAssign;
        }

        // If the user meets all the requirements to see the quick-assign feature,
        // then run the A/B Test to split the audience and submit the appropriate analytics.
        if (normalCanView) {
          // The user is in the challenger test Group B. They will see the reinvent quick-assign modals.
          sendABTestAnalyticEvent('challenger');
        } // Else, the user can't see any quick-assign modals period (i.e. they are Enterprise orgs,
        // or assigned all their licenses already, etc.) and are not part of the AB test.

        return normalCanView;
      });
    }

    /**
     * @description Method to determine if the product has available licenses (or can delegate users
     *     without having them).
     * @param {Product} product the product to check for available licenses
     * @returns {Promise} Resolves to true if the product has no available licenses, false otherwise
     */
    function hasNoAvailableLicenses(product) {
      return (
        (product.hasNoAvailableLicenses() && product.isTeam()) ||
        trialHelper.isExpiredTrial(product)
      );
    }

    function sendABTestAnalyticEvent(groupString) {
      // We only need to send this event once per session, if even that.
      if (!abEventSent) {
        const options = {
          componentMethod: `ab-test-quick-assign-reinvent-${groupString}`,
          componentMethodType: 'submit',
          componentName: 'quickAssignModal2',
        };
        AnalyticsEvent.dispatch(options);

        abEventSent = true;
      }
    }

    /**
     * @description Method to determine if this Product is API only
     * @param {Product} product the product to check
     *
     * @returns {Boolean} Returns true if it is an API only product, else returns false
     */
    function isAPIOnlyProduct(product) {
      // true here says it can only be delegated to the provided type
      return product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY, true);
    }

    /**
     * @description Method to determine if this Product is a hybrid product (has API and has UI components)
     * @param {Product} product the product to check
     *
     * @returns {Boolean} Returns true if it is a hybrid product, else returns false
     */
    function isHybridProduct(product) {
      return (
        product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY) &&
        (product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.TYPE1) ||
          product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.TYPE2) ||
          product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.TYPE2E) ||
          product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.TYPE3))
      );
    }

    /**
     * @description Method to set add-product querystring while opening the mini app inside modal popup
     * when temp_add_product_mini_app is true.
     */
    function setAddProductUrl() {
      const path = $location.path();
      if (
        !_.includes(path, '/add-products') &&
        onesieSrc2.core.products.access.canUseAddProductMiniApp() &&
        onesieSrc2.core.products.access.canUseAddProductMiniAppForDeepLink()
      ) {
        $location.path(`${path}/add-products`);
        $window.history.pushState({path: `${path}/add-products`}, '', `${path}/add-products`);
      }
    }
  }
})();
/* eslint-enable max-lines */
