// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {ActionButton, Badge, Heading, Image, Text, View} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import Close from '@spectrum-icons/workflow/Close';
import Label from '@spectrum-icons/workflow/Label';
import PropTypes from 'prop-types';
import React from 'react';

import TermsAndConditionsHoverOverTooltip from 'common/components/sophia/sophia-terms-and-condition/TermsAndConditionsHoverOverTooltip';

import ActionsSection from './sophia-actions/ActionsSection';
import RegularPriceInfo from './sophia-price/RegularPriceInfo';
import SpotlightPriceInfo from './sophia-price/SpotlightPriceInfo';
import StrikethroughPriceInfo from './sophia-price/StrikethroughPriceInfo';
import TermsAndConditionsDialogTrigger from './sophia-terms-and-condition/TermsAndConditionsDialogTrigger';

const SophiaPromoActions = ({sophiaContent}) => (
  <ActionsSection
    data-testid="one-console-promo-action-section"
    primaryCTAAction={sophiaContent.primaryCTAAction}
    primaryCTALabel={sophiaContent.primaryCTALabel}
    secondaryCTAAction={sophiaContent.secondaryCTAAction}
    secondaryCTALabel={sophiaContent.secondaryCTALabel}
  />
);

SophiaPromoActions.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoBasePrice = ({sophiaContent}) => (
  <View data-testid="one-console-promo-base-price" marginBottom={{base: 'size-100', S: 'none'}}>
    {sophiaContent.discountedPrice ? (
      <StrikethroughPriceInfo
        data-testid="one-console-promo-base-price-strikethrough"
        frequency={sophiaContent.priceFrequency}
        price={sophiaContent.regularPrice}
        strikethroughColor={sophiaContent.strikethroughColor}
      />
    ) : (
      <RegularPriceInfo
        data-testid="one-console-promo-base-price-regular"
        displayExcludingVAT={sophiaContent.displayExcludingVAT}
        displayPriceStartingAt={sophiaContent.displayPriceStartingAt}
        frequency={sophiaContent.priceFrequency}
        price={sophiaContent.regularPrice}
      />
    )}
  </View>
);

SophiaPromoBasePrice.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoCloseButton = ({onDismiss}) => (
  <ActionButton
    data-testid="one-console-promo-close-action-button"
    isQuiet
    onPress={onDismiss}
    position="absolute"
    right="size-0"
    top="size-65"
    variant="primary"
  >
    <Close data-testid="one-console-promo-close-action-button-icon" size="S" />
  </ActionButton>
);

SophiaPromoCloseButton.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

const SophiaPromoDescriptionWithTerms = ({displayTerms, sophiaContent}) => (
  <View
    data-testid="one-console-promo-description"
    marginBottom="18px"
    UNSAFE_style={{
      fontSize: 'var(--spectrum-global-dimension-font-size-100)',
    }}
  >
    <SafeHtml dataTestId="one-console-promo-description-text" html={sophiaContent.description} />
    {displayTerms && <SophiaPromoTermsAndConditionsModal sophiaContent={sophiaContent} />}
  </View>
);

SophiaPromoDescriptionWithTerms.propTypes = {
  displayTerms: PropTypes.bool,
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoDiscountedPrice = ({sophiaContent}) => (
  <View data-testid="one-console-promo-discount-price" marginBottom={{base: 'size-100', S: 'none'}}>
    <SpotlightPriceInfo
      color={sophiaContent.discountPriceColor}
      data-testid="one-console-promo-discount-price-spotlight"
      frequency={sophiaContent.priceFrequency}
      price={sophiaContent.discountedPrice}
    />
  </View>
);

SophiaPromoDiscountedPrice.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoGradient = ({sophiaContent}) => (
  <View
    borderTopEndRadius="regular"
    borderTopStartRadius="regular"
    data-testid="one-console-promo-gradient"
    height="static-size-65"
    left="size-0"
    position="absolute"
    top="size-0"
    UNSAFE_style={{
      background: `url(${sophiaContent.backgroundImage})`,
      backgroundSize: 'cover',
    }}
    width="100%"
  />
);

SophiaPromoGradient.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoIcon = ({sophiaContent}) => (
  <View data-testid="one-console-promo-icon">
    <Image
      alt={sophiaContent.imageAlt || ''}
      data-testid="one-console-promo-icon-image"
      height="auto"
      objectFit="cover"
      src={sophiaContent.imageUrl}
      width="size-700"
    />
  </View>
);

SophiaPromoIcon.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoTag = ({sophiaContent, badgeStyles}) => (
  <View data-testid="one-console-promo-tag" position="absolute" {...badgeStyles.position}>
    <Badge
      data-testid="one-console-promo-tag-badge"
      height="auto"
      UNSAFE_style={badgeStyles.borderStyles}
      variant={sophiaContent.badgeType}
    >
      <Label
        aria-label="note"
        data-testid="one-console-promo-tag-label"
        UNSAFE_style={{boxSizing: 'initial'}}
      />
      <Text data-testid="one-console-promo-tag-text">{sophiaContent.tag}</Text>
    </Badge>
  </View>
);

SophiaPromoTag.propTypes = {
  badgeStyles: PropTypes.oneOfType([PropTypes.object]),
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoTermsAndConditionsModal = ({sophiaContent}) => (
  <TermsAndConditionsDialogTrigger
    data-testid="one-console-promo-terms-trigger"
    linkText={sophiaContent.termsLabel}
    termsAndConditions={sophiaContent.termsAndConditions}
    termsHeading={sophiaContent.termsHeading}
  />
);

SophiaPromoTermsAndConditionsModal.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoTitle = ({sophiaContent}) => (
  <Heading data-testid="one-console-promo-title" level={3} marginBottom="8px">
    <SafeHtml dataTestId="one-console-promo-title-text" html={sophiaContent.title} />
  </Heading>
);

SophiaPromoTitle.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

const SophiaPromoHoverOverTooltip = ({sophiaContent}) => (
  <TermsAndConditionsHoverOverTooltip
    hoverOverText={sophiaContent.hoverOverText}
    htmlContent={sophiaContent.disclosure}
    showTerms={sophiaContent.displayTerms}
  />
);

SophiaPromoHoverOverTooltip.propTypes = {
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

export {
  SophiaPromoActions,
  SophiaPromoBasePrice,
  SophiaPromoCloseButton,
  SophiaPromoDescriptionWithTerms,
  SophiaPromoDiscountedPrice,
  SophiaPromoGradient,
  SophiaPromoHoverOverTooltip,
  SophiaPromoIcon,
  SophiaPromoTag,
  SophiaPromoTermsAndConditionsModal,
  SophiaPromoTitle,
};
