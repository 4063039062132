(function () {
  /**
   * @deprecated ported to src2 or no longer required
 *
   * @ngdoc component
   * @name app.widgets.card-button-list.appCardButton
   * @description widget for a selectable card button, which must be a child of an appCardButtonList.
   * @param {String} cardButtonId a unique identifier within the containining appCardButtonList.
   * @param {Function} [onSelect] called when this card is selected by the user. The parent
   *     card-button-list provides a callback of the same name, which may be used instead if more
   *     convenient.
   * @param {Boolean} [preventDefaultOnClick] Whether to cancel the click event's default behavior (if it is
         cancelable). If the click occurs on an anchor within the card, it will prevent click from following
         the url. Default is true.
   * @param {String} [variant] Optional variant type to specify the color of the left border of the card button.
   *     Possible options are listed in CARD_BUTTON_VARIANT. Defaults to no colored border.
   */
  angular.module('app.widgets.card-button-list').component('appCardButton', {
    bindings: {
      cardButtonId: '@',
      isDisabled: '<?',
      isSelectable: '<?',
      onSelect: '&?',
      preventDefaultOnClick: '<?',
      variant: '@?',
    },
    controller,
    require: {
      parent: '^appCardButtonList',
    },
    templateUrl: 'app/widgets/common/card-button-list/card-button.component.html',
    transclude: true,
  });

  function controller($element, $scope, $timeout, _, feature, CARD_BUTTON_FOCUS_CHANGE) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      isSelected,
      onClick,
    });

    /////////////

    function $onDestroy() {
      vm.parent.deregister(vm.cardButtonId);

      if (!vm.isDisabled) {
        removeFocusChangeListeners();
      }
    }

    function $onInit() {
      _.defaults(vm, {
        isSelectable: true,
        preventDefaultOnClick: true,
      });

      _.assign(vm, {
        idTag: `card-button-${_.toLower(vm.cardButtonId)}`,
      });
      vm.parent.register(vm.cardButtonId);

      if (!vm.isDisabled) {
        // card button receives focus, so ensure values are set and passed
        // correctly starting here
        addFocusChangeListeners();
      }
    }

    /**
     * @description Method to capture events as they are created/sent down the
     *   DOM to the target element. This allows us to handle focus changes at
     *   the button level (top accessible element in product card stack) and
     *   tie in behavior that should be applied whenever *any* product card
     *   element is in focus (e.g. - show carousel arrows).
     */
    function addFocusChangeListeners() {
      // third argument - true - means that this event listener is listening
      // during the capture phase of an event's lifecycle. angular listeners are
      // unable to listen on this phase and can only catch events during the
      // bubbling phase, which is why we have to use vanilla JS methods here
      $element[0].addEventListener('blur', onBlur, true);
      $element[0].addEventListener('focus', onFocus, true);
    }

    function isSelected() {
      return vm.isSelectable && vm.parent.isCardButtonSelected(vm.cardButtonId);
    }

    function onBlur() {
      $scope.$broadcast(CARD_BUTTON_FOCUS_CHANGE, false);
    }

    function onClick(event) {
      if (event.target.nodeName !== 'BUTTON' && vm.isSelectable && !vm.isDisabled) {
        vm.parent.selectCardButton(vm.cardButtonId, vm.idTag);
        _.invoke(vm, 'onSelect', {$event: event});
        // Attempt to invoke "onSelect" hook with the cardButtonId if one is provided
        $timeout(() => _.invoke(vm.parent, 'onSelect', vm.cardButtonId));
      }

      if (vm.preventDefaultOnClick) {
        event.preventDefault();
      }
    }

    function onFocus() {
      $scope.$broadcast(CARD_BUTTON_FOCUS_CHANGE, true);
    }

    function removeFocusChangeListeners() {
      $element[0].removeEventListener('blur', onBlur, true);
      $element[0].removeEventListener('focus', onFocus, true);
    }
  }
})();
