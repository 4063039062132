import PropTypes from 'prop-types';

import CallToActionAnchorContent from './CallToActionAnchorContent';

const CallToActionAnchor = ({intl, itemIndex, linkContent, linkType, locale, topicIndex}) => {
  const linksOfType = [];
  linkContent
    ?.filter((link) => link.type === linkType)
    .forEach((linkItem) => {
      linksOfType.push(
        CallToActionAnchorContent({intl, itemIndex, linkItem, linkType, locale, topicIndex})
      );
    });
  return linksOfType;
};

CallToActionAnchor.propTypes = {
  /**
   * Reference to intl for translations
   */
  intl: PropTypes.shape({formatMessage: PropTypes.func.isRequired}),
  /**
   * The content item index
   */
  itemIndex: PropTypes.number.isRequired,
  /**
   * The link content array
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  linkContent: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * The link type
   */
  linkType: PropTypes.string.isRequired,
  /**
   * The two character code representing the current localization state
   */
  locale: PropTypes.string,
  /**
   * The topic item index
   */
  topicIndex: PropTypes.number.isRequired,
};

export default CallToActionAnchor;
