(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appRenewalChatBanner
   * @description component to display a Start Chat banner for talking to the renewal retention agent.
   *   Renders a Start Chat button component.
   * @example
   *  <app-renewal-chat-banner></app-renewal-chat-banner>
   */
  angular.module('app.widgets.products.purchase').component('appRenewalChatBanner', {
    controller,
    templateUrl:
      'app/widgets/products/purchase/renewal-chat-banner/renewal-chat-banner.component.html',
  });

  /* @ngInject */
  function controller(_, AnalyticsHelper, CHAT_APP_ID, feature, onesieSrc2) {
    const vm = this;
    _.assign(vm, {
      CHAT_APP_ID,
      isEnabled: feature.isEnabled,
      onStartChatButtonClick,
      StartChatButton: onesieSrc2.support.components.StartChatButton,
    });

    function onStartChatButtonClick() {
      AnalyticsHelper.dispatchUiEventAnalytics({eventAction: 'click', eventName: 'chatOffer'});
    }
  }
})();
