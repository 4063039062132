import {log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import {claimFederatedDomains, getFederatedDomains} from 'features/settings/api/ims-federated';

/**
 */
const useFederatedDomainList = ({authSourceId, externalToken, federatedType}) => {
  const getFederatedDomainList = useCallback(async () => {
    try {
      const response = await getFederatedDomains({
        authSourceId,
        externalToken,
        federatedType,
      });

      return response.data;
    } catch (error) {
      log.error(
        `[ID][JIL] Error getting the list of federated domains for directory ${authSourceId}`,
        error
      );

      throw error;
    }
  }, [authSourceId, externalToken, federatedType]);

  const claimDomains = useCallback(
    async ({domains}) => {
      try {
        const response = await claimFederatedDomains({
          authSourceId,
          domains,
          externalToken,
          federatedType,
        });

        return response;
      } catch (error) {
        log.error(
          `[ID][JIL] Error claiming federated domains for directory ${authSourceId}`,
          error
        );

        throw error;
      }
    },
    [authSourceId, externalToken, federatedType]
  );

  return {
    claimDomains,
    getFederatedDomainList,
  };
};

export default useFederatedDomainList;
