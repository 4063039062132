import {Grid, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './TrustMetadata.pcss';

const TrustMetadataLabel = ({children}) => (
  // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Text needs different color
  <Text UNSAFE_className={styles['trust-metadata-label']}>{children}</Text>
);

TrustMetadataLabel.propTypes = {
  children: PropTypes.node,
};

const TrustMetadata = ({trust, directory, ...styleProps}) => (
  <Grid columns={['.8fr', '1fr']} rows={['auto']} {...styleProps}>
    <TrustMetadataLabel>
      <FormattedMessage id="settings.directories.trustMetadata.owningOrganization" />
    </TrustMetadataLabel>
    <Text>{trust.owningOrgName}</Text>

    {directory.isStatusActive && (
      <>
        <TrustMetadataLabel>
          <FormattedMessage id="settings.directories.trustMetadata.approvedBy" />
        </TrustMetadataLabel>
        <Text>{trust.owningOrgSysAdminEmail}</Text>
      </>
    )}

    {directory.isStatusRejected && (
      <>
        <TrustMetadataLabel>
          <FormattedMessage id="settings.directories.trustMetadata.rejectedBy" />
        </TrustMetadataLabel>
        <Text>{trust.owningOrgSysAdminEmail}</Text>
      </>
    )}

    {(directory.isStatusRejected || directory.isStatusRevoked) && (
      <>
        <TrustMetadataLabel>
          <FormattedMessage id="settings.directories.trustMetadata.message" />
        </TrustMetadataLabel>
        <Text>{trust.reasonForRejection}</Text>
      </>
    )}
  </Grid>
);

TrustMetadata.propTypes = {
  directory: PropTypes.shape({
    isStatusActive: PropTypes.bool,
    isStatusRejected: PropTypes.bool,
    isStatusRevoked: PropTypes.bool,
  }).isRequired,
  trust: PropTypes.shape({
    owningOrgName: PropTypes.string,
    owningOrgSysAdminEmail: PropTypes.string,
    reasonForRejection: PropTypes.string,
  }).isRequired,
};

export default TrustMetadata;
export {TrustMetadataLabel};
