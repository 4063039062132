import {Search} from '@pandora/react-search';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

// Search field appears in Plugin Selection Screen
const PluginSearchField = ({isFilterApiInProgress, onPluginSearch, pluginSearchString}) => {
  const intl = useIntl();

  const [pluginSearchText, setPluginSearchText] = useState(pluginSearchString);

  const onSubmit = (pluginString) => {
    setPluginSearchText(pluginString);
    onPluginSearch(pluginString);
  };

  return (
    <Search
      aria-label={intl.formatMessage({
        id: 'packages.createPackage.choosePlugins.searchField.ariaLabel',
      })}
      isDisabled={isFilterApiInProgress}
      label={intl.formatMessage({
        id: 'packages.createPackage.choosePlugins.searchField.ariaLabel',
      })}
      marginTop="size-100"
      maxLength={100}
      minLength={3}
      onChange={setPluginSearchText}
      onSubmit={onSubmit}
      value={pluginSearchText}
    />
  );
};

PluginSearchField.propTypes = {
  /**
   * Boolean value to indicate if filter api cal in progress
   */
  isFilterApiInProgress: PropTypes.bool.isRequired,
  /**
   * Function to call on plugin searchfield text submission
   */
  onPluginSearch: PropTypes.func.isRequired,
  /**
   * Plugin searchfield text submitted
   */
  pluginSearchString: PropTypes.string.isRequired,
};
export default PluginSearchField;
