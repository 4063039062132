import {JIL_CONSTANTS, getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

import rootStore from 'core/RootStore';

let clientId, includeRoles, url;

/**
 * Configure JIL storage folders APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Fetches the storage folders based on the provided action.
 *
 * @param {String} params - the parameters to include with the GET call
 * @return {Promise} a promise which will resolve with the response
 */
const get = (params, action = '') =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-folders${action}`, {
    headers: {
      Accept: JIL_CONSTANTS.HEADERS.ACCEPT.V2,
      ...getHeaders({clientId, includeRoles}),
    },
    params,
  });

/**
 * @description Fetches the requested type of storage folders.
 *
 * @param {String} params.type - The type of storage folders to retrieve: ASSETS, USERS or REPORTS.
 * @return {Promise} a promise which will resolve with the response
 */
const getStorageFolders = (params) => get(params);

/**
 * @description Fetches the storage folders from the provided path.
 *
 * @param {String} params.path - The path to the storage folders to retrieve.
 * @return {Promise} a promise which will resolve with the response
 */
const getStorageFoldersByPath = (params) => get(params, ':children.path');

/**
 * @description Fetches the user folders from the provided path.
 *
 * @param {String} params.path - The path to the user folders to retrieve.
 * @return {Promise} a promise which will resolve with the response
 */
const getUserFoldersByPath = (params) =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-folders:search.path`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Fetches the user folders from the provided array of users.
 *
 * @param {String[]} userIds - The list of user ids to use to fetch user folders.
 * @return {Promise} a promise which will resolve with the response
 */
const getUserFoldersByUsers = (userIds) =>
  axios.post(
    `${url}/${rootStore.organizationStore.activeOrgId}/storage-folders:search.users`,
    userIds,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

/**
 * @description Modifies the provided type of storage folders.
 *
 * @param {Array<{op: string, path: string}>} params.operations - The array of operations to make on storage folders.
 * @param {String} params.type - The type of storage folders to modify: ASSETS, USERS or REPORTS.
 * @return {Promise} a promise which will resolve with the response
 */
const patchStorageFolders = ({operations, type}) =>
  axios.patch(
    `${url}/${rootStore.organizationStore.activeOrgId}/storage-folders?type=${type}`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilStorageFolders = {
  configure,
  getStorageFolders,
  getStorageFoldersByPath,
  getUserFoldersByPath,
  getUserFoldersByUsers,
  patchStorageFolders,
};

export default jilStorageFolders;
