import {download, jilOrganizationsProductsLicenseGroups, log} from '@admin-tribe/binky';
import {showError, showInfo} from '@admin-tribe/binky-ui';
import {ActionMenu, Item} from '@adobe/react-spectrum';
import {useDeviceActivationDetails} from '@pandora/react-data-source-cal';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * DeviceExportCsvMenu will display a Menu that allows selection the type of CSV export.
 * Upon selection from the Menu, the CSV file is being downloaded.
 */
const DeviceExportCsvMenu = ({
  getSelectedRows,
  excludedColumns = [],
  isDisabled = false,
  licenseGroupId,
  productId,
}) => {
  const activationDetailsOptions = {
    licenseId: productId,
    orgId: rootStore.organizationStore.activeOrgId,
  };

  // In the case of SDL where we would have a licenseConfigId
  if (licenseGroupId) activationDetailsOptions.licenseConfigId = licenseGroupId;

  const {getDevicesCsv} = useDeviceActivationDetails(activationDetailsOptions);
  const intl = useIntl();

  const EXPORT_SELECTIONS = {
    ALL_DEVICES: 'All devices',
    ALL_USERS: 'All users',
    SELECTED_DEVICES: 'Selected devices',
  };

  const translationsNamespace = 'products.deviceLicenses.exportCsvBtn';

  /**
   * Method that returned CSV data excluding given columns
   * @param {Object} parsedCsv - parsed csv data
   * @param {Array<String>} excluded - strings array for keys of columns to be excluded
   * @returns {Array<Array<String>>} that will replaced the data attribute in the parsedCsv
   */
  const excludeColumns = (parsedCsv, excluded) => {
    const excludedIndexesSet = new Set(
      excluded.map((exclColumn) => parsedCsv.data[0].indexOf(exclColumn))
    );
    return parsedCsv.data.map((row) => row.filter((el, index) => !excludedIndexesSet.has(index)));
  };

  /**
   * Method that generates CSV filename.
   * @returns {String} - The file name for the CSV download
   */
  const generateFileName = (name) => `${productId}-${licenseGroupId || ''}-${name}.csv`;

  /**
   * The exportDevicesCsv will download the devices CSV, and translate the headers before presenting it as an attachment
   * @param {Object} options
   * @param {Array<Object>} options.deviceDetails
   */
  const exportDevicesCsv = async (options = {}) => {
    try {
      const response = await getDevicesCsv(options);
      const blob = await response.blob();
      const text = await blob.text();
      const parsedCsv = Papa.parse(text);
      parsedCsv.data = excludeColumns(parsedCsv, excludedColumns);
      const headerRow = parsedCsv.data[0];
      const translatedHeaderRow = headerRow.map((key) =>
        intl.formatMessage({
          id: `${translationsNamespace}.csvHeaders.${key}`,
        })
      );
      parsedCsv.data[0] = translatedHeaderRow;
      const finalCsv = Papa.unparse(parsedCsv);
      download(finalCsv, generateFileName('devices'));
    } catch (error) {
      log.error(error);
      showError({id: `${translationsNamespace}.errorMessage`});
    }
  };

  /**
   * The exportDeviceUsersCsv will download the device users CSV as an attachment
   */
  const exportDeviceUsersCsv = async () => {
    try {
      const response = await jilOrganizationsProductsLicenseGroups.exportLicenseGroupDeviceUsers({
        licenseGroupId,
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
      });
      download(response?.data.file, generateFileName('device-users'));
    } catch (error) {
      log.error(error);
      showError({id: `${translationsNamespace}.errorMessage`});
    }
  };

  /**
   * Triggers CSV download upon selection from the Menu
   * @param {String} selection
   */
  const triggerCsvDownload = (selection) => {
    showInfo(intl.formatMessage({id: `${translationsNamespace}.infoMessage`}));
    switch (selection) {
      case EXPORT_SELECTIONS.ALL_DEVICES:
        exportDevicesCsv();
        break;
      case EXPORT_SELECTIONS.SELECTED_DEVICES:
        exportDevicesCsv({
          deviceDetails: getSelectedRows().map((device) => ({
            deviceId: device.deviceId,
            osUserId: device.osUserId,
          })),
        });
        break;
      case EXPORT_SELECTIONS.ALL_USERS:
        exportDeviceUsersCsv();
        break;
      default:
        log.error(
          'DeviceExportCsvMenu triggerCsvDownload: error downloading csv, unknown menu selection'
        );
        showError({id: `${translationsNamespace}.errorMessage`});
    }
  };

  /**
   * Method that returns an array of keys to be disabled in the Menu
   * @returns {String[]} - Array of keys
   */
  const getDisabledKeys = () => {
    if (getSelectedRows().length === 0) return [EXPORT_SELECTIONS.SELECTED_DEVICES];
    return [];
  };

  const exportAllDevices = (
    <Item key={EXPORT_SELECTIONS.ALL_DEVICES}>
      {intl.formatMessage({
        id: `${translationsNamespace}.exportAll`,
      })}
    </Item>
  );
  const exportSelectedDevices = (
    <Item key={EXPORT_SELECTIONS.SELECTED_DEVICES}>
      {intl.formatMessage({
        id: `${translationsNamespace}.exportSelected`,
      })}
    </Item>
  );

  return (
    <ActionMenu
      align="end"
      data-testid="device-export-menu"
      disabledKeys={getDisabledKeys()}
      isDisabled={isDisabled}
      marginStart="size-200"
      onAction={triggerCsvDownload}
    >
      {exportAllDevices}
      {exportSelectedDevices}
    </ActionMenu>
  );
};

DeviceExportCsvMenu.propTypes = {
  /**
   *  excludedColumns - Columns that are excluded from the CSV report
   */
  excludedColumns: PropTypes.arrayOf(PropTypes.string),
  /** Function that when called will return selected items from table. */
  getSelectedRows: PropTypes.func.isRequired,
  /**
   * To disable the Export button when there are no rows in the table. Default is false.
   */
  isDisabled: PropTypes.bool,
  /**
   *  licenseGroupId - Id of the current product profile
   */
  licenseGroupId: PropTypes.string,
  /**
   *  productId - Id of the current product
   */
  productId: PropTypes.string.isRequired,
};

export default DeviceExportCsvMenu;
