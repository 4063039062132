import {OverlayWait, showError, useStore} from '@admin-tribe/binky-ui';
import {DialogContainer, Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {DirectoryContextProvider} from 'features/settings/components/directory/DirectoryContext';
import EduRosterSyncsList from 'features/settings/components/directory/sync/EduRosterSyncsList';
import {
  RosterSyncContext,
  RosterSyncContextProvider,
} from 'features/settings/components/directory/sync/RosterSyncContext';
import SyncEmptyState from 'features/settings/components/directory/sync/SyncEmptyState';
import SyncCard from 'features/settings/components/sync-card/SyncCard';
import SyncSettingsModal from 'features/settings/components/sync-settings-modal/SyncSettingsModal';
import SyncUsersModal from 'features/settings/components/sync-users-modal/SyncUsersModal';
import EduSyncConfirmationModal from 'features/settings/components/sync-users-modal/edu-sync-confirmation-modal/EduSyncConfirmationModal';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

const DIALOGS = {
  ADD_SYNC_MODAL: Symbol('add-sync-modal'),
  CONFIRM_EDU_SYNC_MODAL: Symbol('confirm-edu-sync-modal'),
  SYNC_SETTINGS_MODAL: Symbol('sync-settings-modal'),
};

const DirectorySync = observer(
  ({
    directoryId,
    domainsLoading,
    // TODO: clear the users cache from react when that is implemented
    clearCaches,
  }) => {
    const directoryStore = useStore(() => new DirectoryStore(directoryId));

    const [dialog, setDialog] = useState(null);
    const [eduRosterSyncs, setEduRosterSyncs] = useState({
      data: [],
      isLoading: false,
    });
    const {getAllDirectoryRosterSyncs} = useEduRosterSync();
    const intl = useIntl();

    const isLoadingData = useMemo(
      () =>
        directoryStore.isLoading ||
        directoryStore.sync.isLoading ||
        domainsLoading ||
        directoryStore.domains.isLoading ||
        eduRosterSyncs.isLoading,
      [
        directoryStore.isLoading,
        directoryStore.sync.isLoading,
        domainsLoading,
        directoryStore.domains.isLoading,
        eduRosterSyncs.isLoading,
      ]
    );

    // Load data needed for the component
    useEffect(() => {
      let isMounted = true;

      directoryStore.load();
      directoryStore.sync.load();

      setEduRosterSyncs((prevState) => ({...prevState, isLoading: true}));

      const loadEduSyncs = async () => {
        try {
          const {data} = await getAllDirectoryRosterSyncs({
            directoryId: directoryStore.directoryId,
          });

          if (isMounted) {
            setEduRosterSyncs(() => ({data, isLoading: false}));
          }
        } catch (error) {
          showError(
            intl.formatMessage({
              id: 'settings.sync.eduSync.loadingError',
            })
          );
        }
      };

      loadEduSyncs();

      return () => {
        isMounted = false;
      };
    }, [directoryStore, getAllDirectoryRosterSyncs, intl, setEduRosterSyncs]);

    // Get domains from angular and add them to the store
    useEffect(() => {
      directoryStore.domains.load();
    }, [directoryStore.domains]);

    // Restore EDU login if it's in progress
    useEffect(() => {
      if (ExternalAuthService.isLoginInProgress()) {
        // we're restoring an EDU roster sync login
        const stateData = ExternalAuthService.getStateData();

        if (stateData.isEduLogin) {
          setDialog(DIALOGS.CONFIRM_EDU_SYNC_MODAL);
        }
      }
    }, []);

    const onDialogDismiss = () => {
      setDialog(null);
    };

    const onSyncAdded = () => {
      directoryStore.load();
      directoryStore.sync.load();
    };

    return (
      <DirectoryContextProvider directoryStore={directoryStore}>
        <RosterSyncContextProvider eduRosterSyncs={eduRosterSyncs}>
          <OverlayWait isLoading={isLoadingData}>
            <Flex>
              {directoryStore.sync.isSynced && (
                <SyncCard
                  clearCaches={clearCaches}
                  onSyncSettingsPressed={() => setDialog(DIALOGS.SYNC_SETTINGS_MODAL)}
                />
              )}
              <RosterSyncContext.Consumer>
                {(ctx) => ctx.eduRosterSyncs.data.length > 0 && <EduRosterSyncsList />}
              </RosterSyncContext.Consumer>
            </Flex>
            <RosterSyncContext.Consumer>
              {(ctx) =>
                !directoryStore.sync.isSynced &&
                ctx.eduRosterSyncs.data.length === 0 && (
                  <SyncEmptyState
                    clearCaches={clearCaches}
                    onAddSync={() => setDialog(DIALOGS.ADD_SYNC_MODAL)}
                  />
                )
              }
            </RosterSyncContext.Consumer>
          </OverlayWait>

          <DialogContainer onDismiss={onDialogDismiss}>
            {dialog === DIALOGS.ADD_SYNC_MODAL && (
              <SyncUsersModal
                authSrcCode={directoryStore.directoryId}
                clearCaches={clearCaches}
                directoryStore={directoryStore}
                onSyncAdded={onSyncAdded}
              />
            )}

            {dialog === DIALOGS.SYNC_SETTINGS_MODAL && (
              <SyncSettingsModal
                adminEnabled={directoryStore.sync.directoryLink.adminEnabled}
                authSrcCode={directoryStore.directoryId}
                clearCaches={clearCaches}
                directoryStore={directoryStore}
                directorySyncStore={directoryStore.sync}
                enabled={directoryStore.sync.directoryLink.enabled}
                isExternallyManaged={directoryStore.isExternallyManaged}
                lastSyncDate={directoryStore.sync.directoryLink.lastSyncDate}
                status={directoryStore.sync.directoryLink.status}
              />
            )}

            {dialog === DIALOGS.CONFIRM_EDU_SYNC_MODAL && (
              <EduSyncConfirmationModal onConfirm={onSyncAdded} />
            )}
          </DialogContainer>
        </RosterSyncContextProvider>
      </DirectoryContextProvider>
    );
  }
);

DirectorySync.propTypes = {
  clearCaches: PropTypes.func,
  directoryId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- data coming from angular
  domains: PropTypes.array,
  domainsLoading: PropTypes.bool,
  totalNumberOfDomains: PropTypes.number,
};

export default DirectorySync;
