import {
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_TYPE,
  TRUST_STATUS,
  feature,
} from '@admin-tribe/binky';
import {generatePath, redirect} from 'react-router-dom';

import {getDirectoryList} from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
// eslint-disable-next-line import/no-namespace -- not a namespace but a named import
import * as directoryAccess from 'core/directories/access/directoryAccess';
import {throwLoaderNoAccessError} from 'core/router/loaders';
import {canViewIdentity} from 'core/settings/access/settingsAccess';
import {getLinks} from 'features/settings/api/directory-sync';
import {getDirectory, getDirectoryWithCertificates} from 'features/settings/hooks/api/useDirectory';
import {getDirectoryDomainList, getDomainList} from 'features/settings/hooks/api/useDomainList';
import {getAllOrgRosterSyncs} from 'features/settings/hooks/api/useEduRosterSync';
import {getDirectoryTrusts, getTrustList} from 'features/settings/hooks/api/useTrustList';
import {
  DIRECTORY_DETAILS_TAB_NAV,
  PATH_DIRECTORY_DETAILS_DOMAINS,
} from 'features/settings/routing/settingsPaths';

/**
 * Method to check whether the user has access to directory details route.
 */
const directoryDetailsLoader = async ({params: {directoryId, orgId}, request}) => {
  const canViewDirectory = await directoryAccess.canViewDirectories();
  if (!canViewDirectory) {
    throwLoaderNoAccessError(request);
  }

  const directory = feature.isEnabled('temp_saml_certs')
    ? await getDirectoryWithCertificates({directoryId, orgId})
    : await getDirectory({directoryId, orgId});

  // Type2E or trusted directories don't have details page
  if (
    directory.type === DIRECTORY_TYPE.TYPE2E ||
    directory.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED
  ) {
    throwLoaderNoAccessError(request);
  }

  // Type2 directories don't have overview/authentication tabs ,so we make domains the default route
  if (
    directory.type === DIRECTORY_TYPE.TYPE2 &&
    !request.url.includes(`/${DIRECTORY_DETAILS_TAB_NAV.DOMAINS}`)
  ) {
    throw redirect(generatePath(PATH_DIRECTORY_DETAILS_DOMAINS, {directoryId, orgId}));
  }

  const [canAddDomains, directoryDomains, directoryTrusts] = await Promise.all([
    directoryAccess.canAddDomains(),
    getDirectoryDomainList({directoryId}),
    getDirectoryTrusts({directoryId, status: TRUST_STATUS.ACTIVE}),
  ]);

  return {
    canAddDomains,
    directory,
    directoryDomains,
    directoryTrusts,
  };
};

/**
 * Loads the data for the identity tab
 * (directories, domains, trustList, accessRequestList, scimSyncs, rosterSyncs)
 */
const identityLoader = async ({request}) => {
  const hasIdentityAccess = await canViewIdentity();
  if (!hasIdentityAccess) {
    throwLoaderNoAccessError(request);
  }

  const [
    canAddDomains,
    canViewDomains,
    canCreateDirectory,
    canViewTrusts,
    canViewAccessRequests,
    directoryList,
    domainList,
    trusts,
    accessRequests,
    {data: scimSyncs},
    eduRosterSyncs,
  ] = await Promise.all([
    directoryAccess.canAddDomains(),
    directoryAccess.canViewDomains(),
    directoryAccess.canCreateDirectory(),
    directoryAccess.canViewTrusts(),
    directoryAccess.canViewAccessRequests(),
    getDirectoryList(),
    getDomainList(),
    getTrustList(),
    getTrustList({status: TRUST_STATUS.PENDING}),
    getLinks(rootStore.organizationStore.activeOrgId),
    getAllOrgRosterSyncs(),
  ]);

  return {
    accessRequests,
    canAddDomains,
    canCreateDirectory,
    canViewAccessRequests,
    canViewDomains,
    canViewTrusts,
    directories: directoryList,
    domains: domainList,
    eduRosterSyncs,
    scimSyncs,
    trusts,
  };
};

export {directoryDetailsLoader, identityLoader};
