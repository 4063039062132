import {ActionButton} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import Help from '@spectrum-icons/workflow/Help';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import help from '../../constants/help';

const {HELP_RAIL_ID, TOPICS} = help;

/**
 * Help rail toggle button
 */
const HelpRailButton = ({id}) => {
  const uniqueId = useId();
  const intl = useIntl();

  const buttonId = id || uniqueId;

  const onPress = useCallback(() => {
    rootStore.helpStore.ui.setElementIdToFocusOnClose(buttonId);
    if (rootStore.helpStore.ui.isOpen) {
      rootStore.helpStore.ui.closeHelpRail();
    } else {
      rootStore.helpStore.goToTopic(TOPICS.GET_STARTED);
    }
  }, [buttonId]);

  return (
    <ActionButton
      aria-controls={HELP_RAIL_ID}
      aria-expanded={rootStore.helpStore.ui.isOpen}
      aria-label={intl.formatMessage({id: 'help.button.label'})}
      data-testid="helprail-toggle-button"
      id={buttonId}
      isQuiet
      onPress={onPress}
    >
      <Help aria-label={intl.formatMessage({id: 'help.button.label'})} />
    </ActionButton>
  );
};

HelpRailButton.propTypes = {
  /**
   * Id to use for nested button (optional; will generate unique id if none
   *   provided)
   */
  id: PropTypes.string,
};

export default observer(HelpRailButton);
