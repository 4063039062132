(function () {
  /**
   * @deprecated use src2 Users pages
   *
   * @ngdoc component
   * @name app.widgets.users:appUsersNoMembersContent
   *
   * @description a component to transclude stylized content into the member-list-table which is
   *  shown when there are no rows. To show just a string use member-list-table's 'no-members-string.
   *
   * @binding {String} messageType - valid values are searchOnlyContentMode
   * @binding {Function} onClick - allows an optional callback to be configured within the message
   *   content
   */
  angular.module('app.widgets.users').component('appUsersNoMembersContent', {
    bindings: {
      messageType: '@',
      onClick: '&?',
    },
    controller,
    templateUrl: 'app/widgets/users/all/no-members-content/users-no-members-content.component.html',
  });

  /* @ngInject */
  function controller($element, _) {
    const vm = this;

    _.assign(vm, {
      hideTooltip: false,
      onLinkClick,
    });

    function onLinkClick() {
      // until the wait completes and re-renders the table the pointer is still in the control
      // hide the tooltip until the re-render happens which removes the tooltip
      vm.hideTooltip = true;

      _.invoke(vm, 'onClick');
    }
  }
})();
