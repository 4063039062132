import {feature, hasDirectContract} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {getContractDisplayNames} from 'core/products/utils/productUtils';
import {
  renderConsumableQuotaTypeItems,
  renderDirectContractExpiringLicenses,
  renderDirectContractImpactedUsers,
  renderDirectContractRenewals,
  renderForAutoAssignSummaryItem,
  renderForConsumables,
  renderForContractDisplayName,
  renderForContractExpirationStatus,
  renderForTrialStatus,
  renderLicenseQuantities,
  renderNoAccessItem,
  renderProductPaymentStatuses,
} from 'features/products/components/product-page-info-bar/product-page-info-bar-constructor/ProductPageInfoBarConstructor';

/**
 * Method to construct an array with all relevant scorecards for group consumable product.
 */
const getGroupConsumableItems = async ({consumableSummarizationList, intl, product}) => {
  const productPaymentStatuses = await renderProductPaymentStatuses({intl, product});
  const items = [
    renderForConsumables({consumableSummarizationList, intl, product}),
    ...productPaymentStatuses,
  ];

  if (trialHelper.isTrialProduct(product)) {
    items.push(renderForTrialStatus({intl, product}));
  }

  items.push(renderForContractExpirationStatus({intl, product}));

  return items;
};

/**
 * Method to construct an array with all relevant scorecards for the product.
 */
const getLicenseItems = async ({intl, product, productProfileRule}) => {
  const contractList = rootStore.organizationStore.contractList;
  const contractDisplayNames = feature.isEnabled('temp_add_contract_display_name')
    ? getContractDisplayNames(product?.contractIds)
    : [];

  const items = [
    ...renderLicenseQuantities({intl, product}),
    await renderNoAccessItem({intl, product}),
    renderConsumableQuotaTypeItems({intl, product}),
  ];

  if (hasDirectContract(contractList)) {
    items.push(
      renderDirectContractExpiringLicenses({intl, product}),
      await renderDirectContractImpactedUsers({intl, product}),
      renderDirectContractRenewals({intl, product})
    );
  } else {
    const productPaymentStatuses = await renderProductPaymentStatuses({intl, product});
    items.push(...productPaymentStatuses);
  }

  if (trialHelper.isTrialProduct(product)) {
    items.push(renderForTrialStatus({intl, product}));
  }

  if (productProfileRule) {
    items.push(
      renderForAutoAssignSummaryItem({
        intl,
        productProfileRule,
      })
    );
  }
  if (contractDisplayNames.length > 0) {
    items.push(
      renderForContractDisplayName({
        contractDisplayNames,
        intl,
      })
    );
  }

  items.push(renderForContractExpirationStatus({intl, product}));

  return items;
};

export {getGroupConsumableItems, getLicenseItems};
