/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.wallet:appProductPurchaseWallet
 *
 * @description A wallet component to show the payment instrument.
 * @example
 *  <app-product-purchase-wallet
 *    payment-instrument-data="$ctrl.paymentInstrumentData"
 *    next-billing-date="$ctrl.nextBillingDate"
 *    wait-promise="$ctrl.walletWaitPromise">
 *  </app-product-purchase-wallet>
 * @binding {Object} paymentInstrumentData. An object that contains the payment instrument data to be represented by the
 *  component.
 *  It should have the following properties:
 *    {CONTRACT_PAYMENT_CATEGORY} paymentCategory.
 *    {CONTRACT_PAYMENT_TYPE} paymentType. The payment type for a specific payment category.
 *      For example, under CREDIT_CARD payment category, there are VISA, MASTERCARD, AMEX, etc.
 *      Under OFFLINE payment category, there are BANK_TRANSFER and CVS.
 *  Depending on the paymentCategory and paymentType it will contain more specific fields:
 *    {String} billingAddress. Contact information to get the first and last name.
 * @binding {String} [nextBillingDate]. When provided and paymentCategory is a credit card, it will verify that the
 *  expiration date is after this value, otherwise a warning icon tooltip will be shown. It can be any moment() parse input.
 * @binding {Promise} [waitPromise]. The component is not shown until promise is resolved.
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appProductPurchaseWallet', {
    bindings: {
      nextBillingDate: '<?',
      paymentInstrumentData: '<',
      waitPromise: '<?',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/wallet/product-purchase-wallet.component.html',
  });

  /* @ngInject */
  function controller($q, $translate, _, moment) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      const waitPromise = vm.waitPromise || $q.resolve();
      waitPromise.then(render).catch(_.noop);
    }

    ////////////

    function convertCreditCard({
      billingAddress,
      creditCardExpiryMonth,
      creditCardExpiryYear,
      creditCardShortNumber,
      paymentType,
    }) {
      let tooltipContent;
      let subtitleKey = 'subtitle';

      const variant = getCreditCardVariant(creditCardExpiryYear, creditCardExpiryMonth);

      if (variant === 'error') {
        tooltipContent = $translate.instant(
          'widgets.products.purchase.wallet.creditCard.expiredTooltip'
        );
        subtitleKey = 'expiredSubtitle';
      } else if (variant === 'warning') {
        tooltipContent = $translate.instant(
          'widgets.products.purchase.wallet.creditCard.expiringTooltip'
        );
        subtitleKey = 'expiringSubtitle';
      }

      return {
        paymentSubtitle: $translate.instant(
          `widgets.products.purchase.wallet.creditCard.${subtitleKey}`,
          {
            firstName: _.get(billingAddress, 'contact.firstName'),
            lastName: _.get(billingAddress, 'contact.lastName'),
            month: _.padStart(creditCardExpiryMonth, 2, '0'),
            year: `${creditCardExpiryYear}`.slice(2),
          }
        ),
        paymentTitle: $translate.instant('widgets.products.purchase.wallet.creditCard.title', {
          creditCardShortNumber,
          paymentType,
        }),
        paymentType,
        tooltipContent,
        variant,
      };
    }

    function convertDirectDebit({bankAccountMasked, billingAddress}) {
      return {
        paymentSubtitle: $translate.instant(
          'widgets.products.purchase.wallet.directDebit.subtitle',
          {
            firstName: _.get(billingAddress, 'contact.firstName'),
            lastName: _.get(billingAddress, 'contact.lastName'),
          }
        ),
        paymentTitle: $translate.instant('widgets.products.purchase.wallet.directDebit.title', {
          bankAccount: bankAccountMasked.slice(-4),
        }),
        paymentType: 'directdebit',
      };
    }

    function convertOffline({billingAddress, paymentType}) {
      return {
        paymentSubtitle: $translate.instant('widgets.products.purchase.wallet.offline.subtitle', {
          primaryEmail: _.get(billingAddress, 'contact.primaryEmail'),
        }),
        paymentTitle: $translate.instant(
          `widgets.products.purchase.wallet.offline.${_.camelCase(paymentType)}Title`
        ),
      };
    }

    function convertPaypal({billingAddress, payerId}) {
      return {
        paymentSubtitle: $translate.instant('widgets.products.purchase.wallet.paypal.subtitle', {
          firstName: _.get(billingAddress, 'contact.firstName'),
          lastName: _.get(billingAddress, 'contact.lastName'),
        }),
        paymentTitle: $translate.instant('widgets.products.purchase.wallet.paypal.title', {
          payerId,
        }),
        paymentType: 'paypal',
      };
    }

    function convertPurchaseOrder({billingAddress}) {
      return {
        paymentSubtitle: $translate.instant(
          'widgets.products.purchase.wallet.purchaseOrder.subtitle',
          {
            primaryEmail: _.get(billingAddress, 'contact.primaryEmail'),
          }
        ),
        paymentTitle: $translate.instant('widgets.products.purchase.wallet.purchaseOrder.title'),
      };
    }

    function getCreditCardVariant(creditCardExpiryYear, creditCardExpiryMonth) {
      // Credit card expires at the end of the month in UTC/GMT timezone.
      const utcExpiryDate = moment
        .utc([creditCardExpiryYear, creditCardExpiryMonth - 1, 1])
        .endOf('month');
      const utcNow = moment.utc();

      // If now is equal to or after expiration date, credit card has expired.
      if (utcNow.isSameOrAfter(utcExpiryDate)) {
        return 'error';
      }

      // If credit cards expires within 30 days from day, show a warning.
      const daysToExpiration = utcExpiryDate.diff(utcNow, 'days');
      if (daysToExpiration <= 30) {
        return 'warning';
      }

      // If next billing date is equal to or after credit card expiration date show a warning.
      if (vm.nextBillingDate) {
        const utcNextBillingDate = moment.utc(vm.nextBillingDate);
        if (utcNextBillingDate.isSameOrAfter(utcExpiryDate)) {
          return 'warning';
        }
      }

      return undefined;
    }

    /**
     * Payment instrument data converters, where the key is the paymentCategory and value is a converter function.
     * Each converter function will take the paymentInstrumentData and return the translated strings for the view
     * with icon, variant and tooltip information, if applicable.
     */
    const paymentInstrumentConvertersByCategory = {
      CREDIT_CARD: convertCreditCard,
      DEBIT: convertDirectDebit,
      OFFLINE: convertOffline,
      PAYPAL: convertPaypal,
      PURCHASE_ORDER: convertPurchaseOrder,
    };

    function render() {
      const {paymentCategory, paymentType} = vm.paymentInstrumentData || {};

      if (paymentCategory && paymentType) {
        vm.model = _.invoke(
          paymentInstrumentConvertersByCategory,
          paymentCategory,
          vm.paymentInstrumentData
        );
      } else {
        vm.model = undefined;
      }
    }
  }
})();
