(function () {
  /**
   * @deprecated Please use src2/app/core/services/product/product-group/product-list/ProductGroupProductList.js
   *
   * @ngdoc factory
   * @name ProductGroupProductList
   * @description Model for an individual ProductGroupProductList
   */
  angular
    .module('app.core.product.product-group-product-list')
    .factory('ProductGroupProductList', getProductGroupProductListModel);

  /* @ngInject */
  function getProductGroupProductListModel(_, MODEL, modelCache, PRODUCT_LIST_EVENT) {
    class ProductGroupProductList {
      /**
       * @class
       * @description Constructor for ProductGroupProductList model Objects.
       * @param {Object} options - options object
       * @param {String} [options.buyingProgram] - Optional. The buyingProgram for this product group.
       *   If not specified will use the buyingProgram of the first product in the group.
       * @param {String} [options.cloud] - Optional. The cloud for this product group. If not specified will
       *   use the cloud of the first product in the group.
       * @param {Object} [options.icons] - Optional. Default is the product icons. The icons to use for the overview card.
       * @param {String} options.id - The unique id for this productGroup which will be used as the cache key.
       * @param {String} options.items - The products in the group.
       * @param {Boolean} [options.isConsumable] - Optional. Default is false. True if productGroup contains consumable
       *   products. If isConsumable, the fulfillableItems are of interest and the productArrangementCode does not apply.
       * @param {String} [options.longName] - Optional. The display name for this product group. If not specified will
       *   use the longName of the first product in the group.
       */
      constructor(options) {
        this.id = options.id;
        this.isConsumable = options.isConsumable || false;
        this.items = options.items;

        this.buyingProgram = options.buyingProgram || _.get(this, 'items[0].buyingProgram');
        this.cloud = options.cloud || _.get(this, 'items[0].cloud');
        this.assets = options.assets || _.get(this, 'items[0].assets');
        this.links = options.links || _.get(this, 'items[0].links');
        this.longName = options.longName || _.get(this, 'items[0].longName');

        modelCache.put(MODEL.PRODUCTGROUPPRODUCTLIST, this, this.id);
      }

      hasValidDelegationTarget() {
        return _.some(this.items, (item) => item.hasValidDelegationTarget());
      }

      // Called by products navigator to find the product group in an array of mixed products and product groups.
      // Returns a ProductListGroup if found, otherwise undefined.
      static findProductGroupByProduct(productAndProductGroupArray, product) {
        return _.find(productAndProductGroupArray, (productOrProductGroup) => {
          if (productOrProductGroup instanceof ProductGroupProductList) {
            // Is this product one of the products in the group?
            return _.find(productOrProductGroup.items, {id: product.id});
          }
          return undefined;
        });
      }

      static get(options) {
        // If navigating thru the app the id will be in the cache.
        // If a reload of a product details page the id won't be in the cache.
        return modelCache.get(MODEL.PRODUCTGROUPPRODUCTLIST, options.id);
      }
    }

    modelCache.register(MODEL.PRODUCTGROUPPRODUCTLIST, 20);
    modelCache.removeAllOnEvent(MODEL.PRODUCTGROUPPRODUCTLIST, [PRODUCT_LIST_EVENT.REFRESH]);

    return ProductGroupProductList;
  }
})();
