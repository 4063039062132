import {Store} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import {
  SYNC_SOURCES,
  getLinks,
  getScimToken,
  getStatus,
  removeLink,
  toggleSync,
} from 'features/settings/api/directory-sync';
import DirectoryLink from 'features/settings/entities/DirectoryLink';

const SYNC_TASK_STATUSES = {
  ADMIN_OFF: 'ADMIN_OFF',
  DONE: 'DONE',
  ERROR: 'ERROR',
  NOT_STARTED: 'NOT_STARTED',
  OFF: 'OFF',
};

const SYNC_SETUP_STATUSES = {
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  UNCONFIGURED: 'UNCONFIGURED',
};

export default class DirectorySyncStore extends Store {
  adminEnabled;
  authSourceId = null;
  enabled;
  hasError = false;
  isExternallyManaged;
  isLinked;
  isPrimaryIdpOkta;
  isProcessing;
  isSynced;
  lastSyncDate;
  partnerType;
  scimToken;

  status;
  constructor(authSourceId) {
    super();

    makeObservable(this, {
      adminEnabled: observable,
      authSourceId: observable,
      enabled: observable,
      hasError: observable,
      isAzure: computed,
      isExternallyManaged: observable,
      isGoogle: computed,
      isLinked: observable,
      isPrimaryIdpOkta: observable,
      isProcessing: observable,
      isSynced: observable,
      lastSyncDate: observable,
      loadScimToken: action,
      partnerType: observable,
      removeSync: action,
      scimToken: observable,
      scimUrl: computed,
      setupStatus: computed,
      status: observable,
      toggleSync: action,
    });

    // TODO: Rename property to directoryId when component is refactored to only use the store
    this.authSourceId = authSourceId;
  }

  async fetchData() {
    const linksResponse = await getLinks(rootStore.organizationStore.activeOrgId);
    const directoryLinkData = linksResponse.data.find((link) => link.imsId === this.authSourceId);

    // we don't have a link so it makes no sense to continue
    // with getting the status and creating a DirectoryLink
    if (!directoryLinkData) {
      runInAction(() => {
        this.isSynced = false;
      });

      return;
    }

    const statusResponse = await getStatus(
      rootStore.organizationStore.activeOrgId,
      this.authSourceId
    );

    runInAction(() => {
      const status = statusResponse.data;
      this.directoryLink = new DirectoryLink({...directoryLinkData, status});
      this.isSynced = true;
    });
  }

  get isAzure() {
    return this.directoryLink.partnerType === SYNC_SOURCES.AZURE;
  }

  get isGoogle() {
    return this.directoryLink.partnerType === SYNC_SOURCES.GOOGLE;
  }

  async loadScimToken() {
    this.isLoading = true;

    try {
      const response = await getScimToken(
        this.authSourceId,
        rootStore.organizationStore.activeOrgId
      );

      if (!response.data?.token) {
        throw new Error('Error retrieving token from response');
      }

      runInAction(() => {
        this.scimToken = response.data.token;
      });
    } catch (error) {
      runInAction(() => {
        this.hasError = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async removeSync() {
    this.isLoading = true;

    // remove the link
    try {
      await removeLink(this.authSourceId, rootStore.organizationStore.activeOrgId);

      runInAction(() => {
        this.isSynced = false;
        this.isLoading = false;
        this.directoryLink = {};
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  get scimUrl() {
    return `${AppConstants.configuration.services.directorySync.scimUrl}/${this.authSourceId}`;
  }

  get setupStatus() {
    if (!this.isSynced) {
      return SYNC_SETUP_STATUSES.UNCONFIGURED;
    }

    switch (this.directoryLink.status) {
      case SYNC_TASK_STATUSES.ERROR:
        return SYNC_SETUP_STATUSES.ERROR;
      case SYNC_TASK_STATUSES.NOT_STARTED:
      case SYNC_TASK_STATUSES.OFF:
        return SYNC_SETUP_STATUSES.IN_PROGRESS;
      case SYNC_TASK_STATUSES.DONE:
        return SYNC_SETUP_STATUSES.COMPLETED;
      default:
        return SYNC_SETUP_STATUSES.IN_PROGRESS;
    }
  }

  async toggleSync() {
    this.isLoading = true;

    try {
      await toggleSync(
        rootStore.organizationStore.activeOrgId,
        this.authSourceId,
        !this.directoryLink.enabled
      );

      runInAction(() => {
        this.directoryLink.enabled = !this.directoryLink.enabled;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export {SYNC_SETUP_STATUSES, SYNC_TASK_STATUSES};
