/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCasePriorityFormSection
 *
 * @description The Priority section for the Issue Description form for the Create Case modal wizard.
 */
(function () {
  angular.module('app.widgets.support').component('appCasePriorityFormSection', {
    bindings: {
      onCardSelect: '&',
      p1CaseAllowed: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-priority-form-section/case-priority-form-section.component.html',
  });

  function controller(_, CARD_BUTTON_VARIANT, SUPPORT_CASE_PRIORITY) {
    const vm = this;

    _.assign(vm, {
      CARD_BUTTON_VARIANT,
      onSelect,
    });

    function onSelect(buttonId) {
      const buttonIdMap = {
        'case-priority1-id': SUPPORT_CASE_PRIORITY.P1,
        'case-priority2-id': SUPPORT_CASE_PRIORITY.P2,
        'case-priority3-id': SUPPORT_CASE_PRIORITY.P3,
        'case-priority4-id': SUPPORT_CASE_PRIORITY.P4,
      };

      vm.onCardSelect({priority: buttonIdMap[buttonId]});
    }
  }
})();
