import {
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  Product,
  ProductAdminList,
  feature,
} from '@admin-tribe/binky';
import {
  ProductAdminRoleFilter,
  ProductAdminRoleFilterConstants,
  useStore,
} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {getManageableProductAdminRoles} from 'core/admin/access/adminAccess';
import {canAssignAdmin, canRemoveAdmin} from 'core/products/access/productAccess';

/**
 * The subpage for the product's admins.
 */
const ProductAdminsSubpage = observer(({pageContext}) => {
  const ROLES_ALL = [PRODUCT_ADMIN, PRODUCT_SUPPORT_ADMIN];
  const [product, setProduct] = useState(undefined);
  const [roles, setRoles] = useState(undefined);
  const [showRoleFilter, setShowRoleFilter] = useState(false);

  const productId = pageContext.targetId;

  const adminStore = useStore(
    () =>
      new AdminStore({
        listClassRef: ProductAdminList,
        listOptions: {productId, roles: undefined},
      })
  );

  const getProduct = useCallback(async () => {
    const theProduct = await Product.get({id: productId, orgId: adminStore.orgId});
    setProduct(theProduct);
    if (
      feature.isEnabled('temp_parkour_mm') &&
      theProduct.isProductSupportRoleAssignmentAllowed()
    ) {
      setShowRoleFilter(true);
      adminStore.setRolesFilter(ROLES_ALL);
      setRoles(getManageableProductAdminRoles(theProduct));
    }
    adminStore.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no deps
  }, []);

  // Get associated Product (which should already be in the cache) and then load the admin list.
  // adminStore.isLoading will be true and a wait spinner will be shown until the admin list finishes
  // loading.
  const {error: apiError} = useAsyncModel({loadFn: getProduct});

  // Error getting products.
  useEffect(() => {
    if (apiError) {
      // If we can't get the product show a server error in the table.
      adminStore.hasLoadingError = true;
      adminStore.isLoading = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no dep on adminStore
  }, [apiError]);

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  function onSelectRole(option) {
    adminStore.setRolesFilter(
      option === ProductAdminRoleFilterConstants.ALL ? ROLES_ALL : [option]
    );
    adminStore.load();
  }

  return (
    <MemberListContextProvider
      addMemberProps={{productToAddAdmin: product, roles}}
      canAddMember={!!product && canAssignAdmin(product)}
      canRemoveMember={canRemoveAdmin()}
      getIsItemSelectable={getIsItemSelectable}
      memberListDisplay={{
        email: true,
        idType: true,
        viewDetails: true,
      }}
      pageContext={pageContext}
      store={adminStore}
      tableFilter={
        showRoleFilter && <ProductAdminRoleFilter onSelectRole={onSelectRole} width="size-3000" />
      }
    >
      <AdminListTableSection />
    </MemberListContextProvider>
  );
});

export default ProductAdminsSubpage;
