// NOTE: This file is deprecated and will be removed in the future
// DO NOT ADD MORE SERVICES HERE
import AppConstants from '../services/AppConstants';

import {createApiService} from './http';

const apiServices = {
  directorySync: {},
  imsFederated: {},
};

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const initApiServices = () => {
  apiServices.directorySync = createApiService(
    AppConstants.configuration.services.directorySync.url
  );
  apiServices.imsFederated = createApiService(AppConstants.configuration.services.imsFederated.url);
};

export {apiServices, initApiServices};
