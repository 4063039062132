/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketWorkingHoursFormSection
 *
 * @description The working hours section for a support form.
 *
 * @binding {String} descriptionKey. The key for the description for this section.
 * @binding {Function} onChange. Callback for when the working hours change.  Callback param is workingHours.
 * @binding {Object} [workingHoursEnd] End of working hours in the form 'HH.mm'.
 * @binding {Object} [workingHoursStart] Start of working hours in the form 'HH.mm'.
 *   Expected that both 'workingHoursStart' and 'workingHoursEnd' are set or neither are.
 *   There should be at least MIN_RANGE hours between workingHoursStart and workingHoursEnd.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketWorkingHoursFormSection', {
    bindings: {
      descriptionKey: '@',
      onChange: '&',
      workingHoursEnd: '<?',
      workingHoursStart: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-working-hours-form-section/support-ticket-working-hours-form-section.component.html',
  });

  function controller($filter, $scope, _, moment, RANGED_SLIDER_CHANGE) {
    const vm = this;
    const MIN_RANGE = 2; // 2 hours
    const DEFAULT_START_VALUE = 9; // 9AM
    const DEFAULT_END_VALUE = 17; // 5PM

    let isInvalid;

    _.assign(vm, {
      $onInit,
      onChangeSlider,
    });

    function $onInit() {
      // Selection range is 5AM-9PM / 5-21 (24 hour clock).
      // Labels every 4 hours.
      vm.min = 5;
      vm.max = 21;

      setInitialWorkingHours();
      generateLabels();
    }

    function onChangeSlider(startValueStr, endValueStr) {
      vm.startValue = _.toInteger(startValueStr);
      vm.endValue = _.toInteger(endValueStr);

      // Tell the slider if the selected range is invalid.
      isInvalid = isRangeInvalid(vm.startValue, vm.endValue);
      $scope.$broadcast(RANGED_SLIDER_CHANGE, isInvalid);

      invokeOnChangeCallback(startValueStr, endValueStr);
    }

    //////////////

    // As 24-hour string, 'HH.mm', where hours is 00-23 and mm is 00-59.
    function formatTimeForApi(timeStr) {
      return isInvalid ? undefined : moment().hours(timeStr).minutes(0).format('HH.mm');
    }

    function generateLabels() {
      vm.labels = [];
      for (let i = vm.min; i <= vm.max; i++) {
        const time = moment().hours(i).minutes(0).toDate();
        vm.labels.push($filter('binkyDateTime')(time, {timeOnly: true}));
      }
    }

    function invokeOnChangeCallback(startValueStr, endValueStr) {
      const workingHoursStart = formatTimeForApi(startValueStr);
      const workingHoursEnd = formatTimeForApi(endValueStr);

      // Invoke the callback. If the selected range is invalid, workingHoursEnd and workingHoursStart are undefined.
      vm.onChange({workingHoursEnd, workingHoursStart});
    }

    // If working hours were specified and they are valid don't call onChange handler.
    function setInitialWorkingHours() {
      if (vm.workingHoursStart && vm.workingHoursEnd) {
        // Expected format for workingHoursStart and workingHoursEnd is 'HH.mm'.
        const startHoursParts = _.split(vm.workingHoursStart, '.');
        const endHoursParts = _.split(vm.workingHoursEnd, '.');

        if (startHoursParts.length === 2 && endHoursParts.length === 2) {
          vm.startValue = _.toInteger(startHoursParts[0]);
          vm.endValue = _.toInteger(endHoursParts[0]);
        }

        // This should not be true but be safe.
        if (
          !_.isFinite(vm.startValue) ||
          !_.isFinite(vm.endValue) ||
          vm.startValue < vm.min ||
          vm.endValue > vm.max ||
          isRangeInvalid(vm.startValue, vm.endValue)
        ) {
          setDefaultWorkingHours();
        }
      } else {
        setDefaultWorkingHours();
      }
    }

    function isRangeInvalid(startValue, endValue) {
      return endValue - startValue < MIN_RANGE;
    }

    function setDefaultWorkingHours() {
      // By default, initial selection is 9AM-5PM expressed as 24-hour time.
      vm.startValue = DEFAULT_START_VALUE;
      vm.endValue = DEFAULT_END_VALUE;

      invokeOnChangeCallback(_.toString(vm.startValue), _.toString(vm.endValue));
    }
  }
})();
