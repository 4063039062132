import {
  LICENSE_QUANTITY_STATUS,
  getClosestLicenseEndDateForStatus,
  getFirstContractForProduct,
  getIndirectContract,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

import {COMPLIANCE_CONTEXT} from './ContractComplianceConstants';

/**
 * @description Helper to obtain the relevant contract information to trigger a
 *     global banner for a cancelled or the upcoming cancellation of a direct
 *     contract.
 * @returns {Object} Object wrapping functions and properties that will dictate
 *     whether a global banner will be displayed.
 */
function getDirectContractSummary() {
  const contractList = rootStore.organizationStore.contractList;

  // If an org has a direct contract, there should only be one contract which is direct.
  // @patriawa to revisit with PA-5182
  const contract = contractList.items?.[0];
  if (contract?.isDirectContract()) {
    return {
      canMessageCancellation: () => contract.canMessageCancellation(),
      canMessageUpcomingCancellation: () => contract.canMessageUpcomingCancellation(),
      offsetFromNextBillingDate: contract.getOffsetFromNextBillingDate(),
    };
  }
  return {
    canMessageCancellation: () => false,
    canMessageUpcomingCancellation: () => false,
    offsetFromNextBillingDate: null,
  };
}

/**
 * @description Method to check whether there are grace past due licenses and
 *     if so return content to show a global banner.
 * @returns {Object[]} Banner payloads to show.
 */
function hasGracePastDueLicenses() {
  return getUpdatedComplianceQty({
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE,
  });
}

/**
 * @description Method to check where there are grace past due licenses for a
 *     product, and if so return content to show in a page banner.
 * @param {Product} product - Product whose licenses will be checked to see if
 *     there are any that are grace past due.
 * @returns {Object | null} Object payload if there are licenses that are grace
 *     past due licenses, null otherwise.
 */
function hasGracePastDueLicensesForProduct(product) {
  return getUpdatedComplianceQtyForProduct({
    product,
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE,
  });
}

/**
 * @description Method to check if there are overdeployed device licenses for a
 *     device pool.
 * @param {DevicePackagePool} devicePool - Device pool whose properties will be
 *     checked.
 * @returns {Object | null} Object if there are overdeployed device licenses,
 *     null otherwise.
 */
function hasOverDeployedDeviceLicenses(devicePool) {
  const summary = devicePool?.summary;

  if (summary) {
    const overActivated = summary.getOverActivated();
    if (overActivated > 0) {
      return {count: overActivated};
    }
  }
  return null;
}

/**
 * @description Method to check whether there are past due licenses and if so
 *     return content to show a global banner.
 * @returns {Object[]} Banner payloads to show.
 */
function hasPastDueLicenses() {
  return getUpdatedComplianceQty({
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PAST_DUE,
  });
}

/**
 * @description Method to check where there are past due licenses for a product,
 *     and if so return content to show in a page banner.
 * @param {Product} product - Product whose licenses will be checked to see if
 *     there are any that are past due.
 * @returns {Object | null} Object payload if there are licenses that are past
 *     due licenses, null otherwise
 */
function hasPastDueLicensesForProduct(product) {
  return getUpdatedComplianceQtyForProduct({
    product,
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PAST_DUE,
  });
}

/**
 * @description Helper to check if there are pending payment licenses for an
 *     organization.
 * @returns {Object[]} Global banner payloads to create,
 */
function hasPendingPaymentLicenses() {
  return getUpdatedComplianceQty({
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PENDING_PAYMENT,
  });
}

/**
 * @description Helper to check if there are pending payment licenses for a
 *     product, and if so will return content to show a global banner.
 * @param {Product} product - Product whose licenses will be checked.
 * @returns {Object | null} Object payload if there are licenses that are
 *     pending payment, null otherwise.
 */
function hasPendingPaymentLicensesForProduct(product) {
  return getUpdatedComplianceQtyForProduct({
    product,
    targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PENDING_PAYMENT,
  });
}

/**
 * @description Method to determine whether or not this organization has exceeded
 *     pending payment order.
 * @returns {boolean} True if the organization has exceeded pending payment
 *     order quantity, else false
 */
function hasPendingPaymentOrderQuantityExceeded() {
  const contractList = rootStore.organizationStore.contractList;
  // @patriawa to revisit with PA-5182
  const indirectContract = getIndirectContract(contractList);
  return indirectContract?.isPendingPaymentOrderQuantityExceeded();
}

///////////

/**
 * @description Helper to take a filtered array of contracts and a list of
 *     productList items and a targetStatus to filter on and returns a sum of
 *     the license quantities that match the provided targetStatus.
 * @param {Object} options options for what status to filter on and which
 *     productListItems and contracts to iterate over.
 * @param {Contract[]} options.contracts filtered contracts the id's of which
 *     will be used to find corresponding products.
 * @param {Product[]} options.productListItems product list items to filter through
 * @param {String} options.targetStatus - Product payment status either
 *     'PAST_DUE', 'GRACE_PAST_DUE', or 'COMPLIANT'
 * @returns {Integer} sum of the license quantities that match the provided status
 */
function calculateDelinquentLicenseQuantityTotal({contracts, productListItems, targetStatus}) {
  return productListItems
    .filter((item) => contracts.find((c) => item.hasContractId(c.id)))
    .reduce((totalSum, product) => {
      const productDelinquentLicenseQuantitySum =
        product.licenseTupleList.getTotalNonRenewingForStatus(targetStatus);
      return totalSum + productDelinquentLicenseQuantitySum;
    }, 0);
}

/**
 * @description Helper to determine a contracts COMPLIANCE_CONTEXT
 * @param {Contract} contract contract to check
 *
 * @returns {COMPLIANCE_CONTEXT | undefined} returns COMPLIANCE_CONTEXT, if
 *   contract represents a compliance context that we handle, otherwise,
 *   returns undefined
 */
function getComplianceContextForContract(contract) {
  if (contract.isIndirectContract()) {
    return COMPLIANCE_CONTEXT.INDIRECT;
  }
  if (contract.isDirectContract()) {
    if (contract.isPaymentCategoryPO() || contract.isPaymentCategoryOffline()) {
      return COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE;
    }
    if (contract.isPaymentCategoryCreditCard() || contract.isPaymentCategoryPaypal()) {
      if (contract.isPUF()) {
        return COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF;
      }
      if (contract.isM2M()) {
        return COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM;
      }
    } else if (contract.isPaymentCategoryDigitalRiver()) {
      return COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER;
    }
  }
  return undefined;
}

/**
 * @description Helper to iterate over contract-product item bundles and for
 *     each sum the total license quantities that match the target status. This
 *     is specific to the global banners only.
 * @param {Object} options options for this method
 * @param {Object[]} options.contractsWithAssociatedProducts Array of contract and
 *     product item bundles
 * @param {ProductList} productList ProductList that will be leveraged for its
 *     getClosestLicenseQuantityEndDateWhen method
 * @param {Enum} options.targetProductLicenseQuantityStatus License quantities
 *     will be summed if they match this status
 * @returns {Object[]} Array of global banner payloads, potentially one for each
 *     contract
 */
function getComplianceGlobalBannerPayloads({
  contractsWithAssociatedProducts,
  productList,
  targetProductLicenseQuantityStatus,
}) {
  let contractTargetQuantityTotal = 0;
  return contractsWithAssociatedProducts.map(({contract, products}) => {
    // Get the compliance context associated with the contract
    const complianceContext = getComplianceContextForContract(contract);

    if (complianceContext) {
      const bannerPayload = {
        buyingProgram: contract.buyingProgram,
        canMessageUnbackedPromiseQuantityExceeded: () =>
          contract.canMessageUnbackedPromiseQuantityExceeded(),
        complianceContext,
        contractId: contract.id,
        resellerName: contract.getResellerName(),
        salesChannel: contract.salesChannel,
      };

      // Updated this to take a contractId because I want to only
      // consider the license quantities that belong to the current
      // contract being iterated on
      bannerPayload.endDate = getClosestLicenseEndDateForStatus(
        productList,
        targetProductLicenseQuantityStatus,
        contract.id
      );

      // Summing the delinquent licenses that match the target status
      // for the contract
      contractTargetQuantityTotal = calculateDelinquentLicenseQuantityTotal({
        contracts: [contract],
        productListItems: products,
        targetStatus: targetProductLicenseQuantityStatus,
      });

      bannerPayload.count = contractTargetQuantityTotal;
      return bannerPayload;
    }
    return null;
  });
}

/**
 * @description Updated helper that will bundle each contract with it's
 *     products and then sum up the license quantities with status that matches
 *     the targetProductLicenseQuantityStatus. This change allows multiple
 *     banners to be returned from the hasXXXLicenses calls. (ie: you can have
 *     two gpd banners if an indirect contract, and a team direct PO contract
 *     with gpd licenses are provided)
 *
 * @param {Object} options options for this method
 * @param {Enum} options.targetProductLicenseQuantityStatus license quantities
 *     will be summed if they match this status
 * @returns {Object[]} banner payloads to create
 */
function getUpdatedComplianceQty({targetProductLicenseQuantityStatus}) {
  const contractList = rootStore.organizationStore.contractList;
  const productList = rootStore.organizationStore.productList;

  // Bundle each contract with it's associated products
  const contractsWithAssociatedProducts = contractList.items.map((contract) => ({
    contract,
    products: productList.items.filter((product) => product.hasContractId(contract.id)),
  }));

  return getComplianceGlobalBannerPayloads({
    contractsWithAssociatedProducts,
    productList,
    targetProductLicenseQuantityStatus,
  });
}

/**
 * @description Updated helper for calculating the delinquent licenses for a product
 *
 * @param {Object} options options for this method
 * @param {Enum} options.targetProductLicenseQuantityStatus
 *                license quantities will be summed if they match this status
 * @param {Product} options.product product whose license quantities will
 *                                  be summed based on status
 * @returns {Object | null} banner payload to create, null otherwise.
 */
function getUpdatedComplianceQtyForProduct({product, targetProductLicenseQuantityStatus}) {
  const contractList = rootStore.organizationStore.contractList;
  // @patriawa to revisit with PA-5182
  const targetContract = getFirstContractForProduct(contractList, product);
  if (targetContract) {
    const complianceContext = getComplianceContextForContract(targetContract);
    let resellerName;
    if (complianceContext === COMPLIANCE_CONTEXT.INDIRECT) {
      resellerName = targetContract.getResellerName();
    }

    const quantity = product.licenseTupleList.getTotalNonRenewingForStatus(
      targetProductLicenseQuantityStatus
    );
    const endDate = product.licenseTupleList.getClosestEndDateForStatus(
      targetProductLicenseQuantityStatus
    );
    if (quantity) {
      return {
        buyingProgram: targetContract.buyingProgram,
        complianceContext,
        contractId: targetContract.id,
        count: quantity,
        endDate,
        resellerName,
      };
    }
  }
  return null;
}

export {
  getDirectContractSummary,
  hasGracePastDueLicenses,
  hasGracePastDueLicensesForProduct,
  hasOverDeployedDeviceLicenses,
  hasPastDueLicenses,
  hasPastDueLicensesForProduct,
  hasPendingPaymentLicenses,
  hasPendingPaymentLicensesForProduct,
  hasPendingPaymentOrderQuantityExceeded,
};
