/**
 * map ODIN content coming from Sophia to modal fields
 */
const mapContent = (sophiaContent) => {
  const nestedData = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  return {
    analyticsId: nestedData.analyticsId,
    body: nestedData.body.plaintext,
    body2: nestedData.body2?.plaintext,
    bodyImageRatio: nestedData.bodyImageRatio,
    bodyLearnMoreUrl: nestedData.bodyLearnMoreUrl,
    buttons: nestedData.buttons || [],
    heading: nestedData.heading,
    imageUrl: nestedData.imageUrl,
    modalSize: nestedData.modalSize,
    slotId: nestedData.slotId,
    slotOptions: nestedData.slotOptions,
  };
};

/**
 * map ODIN Helpful Topics content coming from Sophia to modal fields
 */
const mapHelpfulTopicsContent = (sophiaContent) => {
  const topics = [];
  const helpfulTopics = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item.helpfulTopics;
  helpfulTopics.forEach((topic) => {
    topics.push({
      ctaLabel: topic.ctaLabel,
      ctaUrl: topic.ctaUrl,
      learnMoreText: topic.learnMoreText,
      learnMoreUrl: topic.learnMoreUrl,
      parent: topic.parent,
      text: topic.text,
      title: topic.title,
    });
  });

  return topics;
};

/**
 * map ODIN content of promo cards coming from Sophia to modal fields
 */
const mapRightRailMerchandisingContent = (sophiaContent) => {
  const content = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  return {
    analyticsId: content.analyticsId,
    backgroundColor: content.backgroundColor,
    backgroundImage: content.backgroundImage,
    badgeIcon: content.badgeIcon,
    badgeType: content.badgeColor,
    description: content.description?.plaintext,
    disclosure: content.disclosure?.plaintext,
    discountedPrice: content.discountedPrice,
    discountPriceColor: content.discountPriceColor,
    displayExcludingVAT: content.displayExcludingVAT,
    displayPriceStartingAt: content.displayPriceStartingAt,
    displayTerms: content.displayTerms,
    hoverOverText: content.hoverOverText?.plaintext,
    imageUrl: content.imageUrl,
    priceFrequency: content.priceFrequency,
    primaryCTAAction: content.primaryUrl,
    primaryCTALabel: content.primaryCTALabel,
    regularPrice: content.regularPrice,
    secondaryCTAAction: content.secondaryUrl,
    secondaryCTALabel: content.secondaryCTALabel,
    strikethroughColor: content.strikethroughColor,
    tag: content.badgeText,
    termsAndConditions: content.termsAndConditions?.plaintext,
    termsHeading: content.termsHeading,
    termsLabel: content.termsLabel,
    title: content.heading,
  };
};

export {mapContent, mapHelpfulTopicsContent, mapRightRailMerchandisingContent};
