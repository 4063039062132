import {ModelList, feature, log} from '@admin-tribe/binky';

import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

import rootStore from '../../../core/RootStore';
import {discover, getExtensionList, parseLinkHeaderAndGetDownloadUrl} from '../api/packagesCces';
import PackagesExtension from '../entities/PackagesExtensionEntity';

let extensionsDownloadUrl;
class PackagesExtensionsService extends ModelList {
  /**
   * @description - Method to discover CCES APIs and init Plugin Extensions download and fetch URLs
   *
   */
  static async initApis() {
    let response;
    try {
      response = await discover();
      extensionsDownloadUrl = parseLinkHeaderAndGetDownloadUrl(response.headers.link);
    } catch (error) {
      // Treat this as soft failure, so don't throw error
      log.error('PackagesExtensionsService.initApis(): Unable to init APIs. Error: ', error);
    }
  }

  /**
   * @description Method to get filter object for the API to fetch extensions
   *
   * @param {Array} appList List of 'AdobeProduct' to fetch extensions for
   * @param {Array} extensionList List of (id, versionId) to fetch extensions for
   * @param {String} searchString Search query on extension name
   * @param {Boolean} fetchDefault Fetches plugins for default list of plugin IDs defined in config
   * @param {Boolean} platform PLatform to fetch plugins for
   * @param {Boolean} bits PLatform bits to fetch plugins for
   *
   * @returns {Object} Returns the filter object to be sent in POST payload
   */
  // eslint-disable-next-line max-params,complexity -- need 6 params whereas limit was only 4 and complexity reached 12 whereas limit was only 10
  static getExtensionApiFilter(
    appList = [],
    extensionList = [],
    searchString = '',
    fetchDefault = false,
    platform = '',
    bits = ''
  ) {
    let extensions;
    if (searchString) {
      extensions = [];
    } else if (fetchDefault) {
      extensions = rootStore.packagesUiConstantsStore.defaultPluginExtensionIds.map((val) => ({
        id: val,
      }));
    } else {
      extensions = extensionList.map((extension) => ({
        id: extension.id,
        versionId: extension.versionId,
      }));
    }
    const isWin32 =
      platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM &&
      bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT;

    let platformType = platform.toLowerCase();
    if (
      platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM ||
      platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM
    ) {
      platformType += bits;
    }
    if (
      feature.isDisabled('packages_plugins_platform_arm') &&
      (platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM ||
        platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM)
    ) {
      platformType = undefined;
    }
    if (platform === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM) {
      platformType = undefined;
    }

    const hostApps = appList.map((app) => ({
      code: app.sapCode,
      platformType,
      versionString: app.version,
    }));

    const filter = {
      fetchCompatible: true,
      filters: {
        extensions,
        hostApps,
        paid: false,
        searchString,
      },
      orderBy: [
        {
          ascending: false,
          field: 'uniqueDownloads',
        },
      ],
      pageIndex: 0,
      pageSize: 50,
    };
    if (isWin32) {
      filter.filters.extensionTypes = ['ZXP'];
    }
    return filter;
  }

  /**
   * @description Method to fetch and process all Plugin Extensions for given filters.
   *
   * @param {Array} appList - List of 'AdobeProduct' to fetch extensions for
   * @param {Array} extensionList - List of (id, versionId) to fetch extensions for
   * @param {String} searchString - Search query on extension name
   * @param {Boolean} fetchDefault - Fetches plugins for default list of plugin IDs defined in config
   * @param {String} platform - PLatform to fetch plugins for
   * @param {String} bits - PLatform bits to fetch plugins for
   *
   * @returns {Promise} - Returns promise that contains the list of 'PackagesExtension' on resolution
   */
  // eslint-disable-next-line max-params -- need 6 params whereas limit was only 4
  static async fetchAndGetExtensions(
    appList = [],
    extensionList = [],
    searchString = '',
    fetchDefault = true,
    platform = '',
    bits = ''
  ) {
    // if extensionsDownloadUrl has not been set, that means CCES service init failed
    if (!extensionsDownloadUrl) {
      throw new Error('Plugins service not initialized.');
    }
    let response;
    try {
      response = await getExtensionList(
        this.getExtensionApiFilter(
          appList,
          extensionList,
          searchString,
          fetchDefault,
          platform,
          bits
        )
      );
    } catch (error) {
      log.error(
        'PackagesExtensions.fetchAndGetExtensions(): Unable to retrieve model from back-end. Error: ',
        error
      );
      throw error;
    }
    const extensionsModel = new PackagesExtensionsService(response.data.items);
    return extensionsModel.items;
  }

  /**
   * @description Method to get Plugin Extensions download Url
   *
   * @returns {String} - Plugin Extensions download url
   */
  static getExtensionsDownloadUrl() {
    return extensionsDownloadUrl;
  }

  /**
   * @description Class constructor to initialize Plugin Extensions list model with given JSON.
   *
   * @param {Array} extensions Plugin Extensions as received from backend.
   */
  constructor(extensions) {
    super({
      items: extensions.map((extensionInfoWrapper) => new PackagesExtension(extensionInfoWrapper)),
    });
  }
}
export default PackagesExtensionsService;
