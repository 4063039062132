(function () {
  /**
   * @ngdoc module
   * @name app.core.product.configurations.access
   * @description Module which defines product access related models
   */
  angular.module('app.core.product.configurations.access', [
    'app.core.auth',
    'app.core.organizations.organization-manager',
    'binky.core.product.fulfillable-item-list',
  ]);
})();
