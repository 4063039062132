import {jilDirectories} from '@admin-tribe/binky';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Heading, TextField} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';

const DirectoryNameSection = ({setIsLoading}) => {
  const intl = useIntl();

  const {directory, reloadDirectory} = useDirectoryDetailsContext();
  const [directoryName, setDirectoryName] = useState(directory.directoryName);

  const canManageIdentityConfig = canChangeIdentityConfig();

  const onSave = async () => {
    setIsLoading(true);

    try {
      await jilDirectories.updateDirectory({
        directoryData: {name: directoryName.trim()},
        directoryId: directory.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      const updatedDirectory = await reloadDirectory();

      setDirectoryName(updatedDirectory.directoryName);

      showSuccessToast(
        intl.formatMessage({
          id: 'settings.directorySettingsModal.directoryNameSection.toasts.success',
        })
      );
    } catch {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading id="directory-name-section-heading">
        <FormattedMessage id="settings.directorySettingsModal.directoryNameSection.heading" />
      </Heading>
      <TextField
        aria-describedby="directory-name-section-heading"
        marginEnd="size-250"
        maxLength={255}
        onChange={setDirectoryName}
        value={directoryName}
        width="size-6000"
      />
      <ButtonWithContextualHelp
        contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
        isDisabled={directoryName.length === 0 || !canManageIdentityConfig}
        onPress={onSave}
        variant="accent"
      >
        <FormattedMessage id="common.modal.buttons.save" />
      </ButtonWithContextualHelp>
    </>
  );
};

DirectoryNameSection.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
export default DirectoryNameSection;
