(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name bis
   * @description defines service to fetch Billing Instruments Service (BIS) resources
   *
   * https://wiki.corp.adobe.com/display/BPS/Billing+Instruments+Service+%28BIS%29+Home
   */
  angular.module('app.core.api.bis').provider('bis', bisProvider);

  /* @ngInject */
  function bisProvider() {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = config.url;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        paymentInstruments: getPaymentInstrumentsResource(),
      };

      return service;

      ///////////

      function getPaymentInstrumentsResource() {
        return $resource(`${url}/payment-instruments`);
      }
    }
  }
})();
