/**
 * @deprecated Please use src2/app/common/components/authenticating-user-modal/AuthenticatingUserModal.jsx
 *
 * @ngdoc component
 * @name app.widgets.account.authenticating-user:appAuthenticatingUserModal
 *
 * @description The modal to confirm switching the current user to
 * contract authenticating account
 */

(function () {
  angular
    .module('app.widgets.account.authenticating-user')
    .component('appAuthenticatingUserModal', {
      bindings: {
        authenticatingUserId: '<?',
      },
      controller,
      templateUrl:
        'app/widgets/account/authenticating-user/authenticating-user-modal/authenticating-user-modal.component.html',
    });

  /* @ngInject */
  function controller(
    $location,
    _,
    APP_AUTHENTICATING_USER_MODAL,
    authentication,
    feature,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onConfirmClicked,
      onOpen,
    });

    function $onInit() {
      _.assign(vm, {
        modalId: APP_AUTHENTICATING_USER_MODAL,
      });
    }

    function onOpen({authenticatingUserId} = {}) {
      vm.authenticatingUserId = vm.authenticatingUserId || authenticatingUserId;
    }

    function onConfirmClicked() {
      panelManager.close(APP_AUTHENTICATING_USER_MODAL);

      authentication.switchTo(vm.authenticatingUserId, $location.path());
    }
  }
})();
