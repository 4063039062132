(function () {
  /**
   * @ngdoc module
   * @name app.widgets.hva.hva-card.assembler
   * @description module that defines the hva card assembler and its decorators
   */
  angular.module('app.widgets.hva.hva-card.assembler', [
    'app.account.meta',
    'app.core.auth',
    'app.core.contract.access',
    'app.core.contract.contract-utils',
    'app.core.organizations.access',
    'app.core.organizations.organization-manager',
    'app.core.user',
    'app.main.global-modals',
    'app.main.global-modals-manager',
    'app.overview',
    'binky.core.feature',
    'binky.core.feature.ready',
    'binky.core.lodash',
    'binky.core.organizations.admin-list',
    'binky.core.page-context',
    'binky.widgets.common.date-time',
    'binky.widgets.products.product-name',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
