/* eslint-disable max-lines */
(function () {
  const componentName = 'appQuickAssignModal2';
  /**
   * @deprecated use src2 Quick Assign modal
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      isFirstSessionAccess: '<?',
      modalId: '@?',
      onClosed: '&?',
      onInitialized: '&?',
      preassignedUserIds: '<?',
    },
    controller,
    templateUrl: 'app/widgets/users/quick-assign-modal2/quick-assign-modal2.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    $q,
    $log,
    $scope,
    $timeout,
    $translate,
    _,
    AuthenticatedUser,
    AnalyticsEvent,
    AnalyticsHelper,
    binkySrc2,
    BULK_OPERATION_MODE,
    bulkOperationContext,
    bulkOperationUtils,
    onesieSrc2,
    OrganizationManager,
    OrganizationUser,
    PageContext,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    panelManager,
    productAccess,
    ProductList,
    QuickAssignHelper,
    toastManager,
    trialHelper
  ) {
    let hasAsyncProvisionedProduct = false;

    const vm = this;
    vm.$onInit = $onInit;

    const QUICK_ASSIGN_FORM_TYPES = {
      CSV: 'csv',
      EMAIL_LIST: 'emailList',
      SINGLE_SEAT: 'singleSeat',
    };
    const CONFIRMATION_MODAL_TYPES = {
      CANCEL: 'cancel',
      CONFIRM: 'confirm',
      CSV: 'csv',
      EMAIL: 'email',
    };
    const BACKGROUND_CLASSES = {
      THANK_YOU_BANNER: 'thank-you-banner-svg',
      WELCOME_BANNER: 'welcome-banner-svg',
    };
    const {EVENT_ACTION} = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
    const {constructAsyncAddAssignUserAnalytics} = binkySrc2.utils.analyticsContextUtils;

    const {dispatchUserModalSuccessAnalytics} = onesieSrc2.users.services.userModals.analyticsUtils;

    /////////////

    function $onInit() {
      _.assign(vm, {
        hasAddedUsers,
        id: vm.modalId || 'quick-assign-modal2',
        isConfirmDisabled,
        isConfirmed: _.stubFalse,
        isOpenedFromRedirectContext: false,
        onConfirmationModalCancel,
        onDone,
        onDownload,
        onFormStatusChange,
        onOpen,
        onQuickAssignCancel,
        onQuickAssignClose,
        onQuickAssignConfirm,
        onQuickAssignEscape,
        onSingleSeatFinish,
        onSubmit,
        onSwitchToCsv,
        onSwitchToEmail,
        onUsersChange,
        onWelcomeNext,
        QUICK_ASSIGN_CONFIRMATION_MODAL_ID: 'quick-assign-confirmation-modal-id',
        thankYouPageSubLine1Key,
        thankYouPageSubLine2Key,
        thankYouPageTitleKey,
        waitOnConfirmModalPromise: $q.resolve(),
      });

      const getUserPromises = [];
      if (vm.preassignedUserIds) {
        vm.preassignedUsers = [];
        _.forEach(vm.preassignedUserIds, (userId) => {
          const preassignedUser = OrganizationUser.get({userId});
          if (!_.isEmpty(preassignedUser.products)) {
            vm.preassignedUsers.push(preassignedUser);
            getUserPromises.push(preassignedUser.$promise);
          }
        });

        vm.disabledTooltipKey = 'widgets.users.quickAssignModal2.trial.disabledTooltipText';
      }

      const productList = OrganizationManager.getProductsForActiveOrg();

      vm.waitPromise = $q.all(_.concat(getUserPromises, [productList.$promise])).then(() => {
        // store preassigned product counts - map product id to count
        vm.preassignedProductCount = {};
        _.forEach(vm.preassignedUsers, (user) => {
          _.forEach(user.products, (product) => {
            vm.preassignedProductCount[product.id] =
              _.get(vm.preassignedProductCount, product.id, 0) + 1;
          });
        });

        vm.numPreassignedLicenses = _(vm.preassignedProductCount).values().sum();

        vm.totalAssignableLicenses = productList.getSeatBasedAssignableLicenseCount();

        // filter out products that cannot be assigned users
        vm.assignableProducts = _.filter(productList.items, (product) =>
          productAccess.canAssignUser(product)
        );

        initProductDataList();

        // subtract the preassigned licenses to get the real count of available licenses
        vm.numAvailableLicenses =
          _.sumBy(vm.productDataList, 'availableLicenses') -
          _.defaultTo(vm.numPreassignedLicenses, 0);

        if (
          vm.totalAssignableLicenses <= 1 &&
          vm.numAvailableLicenses === 1 &&
          vm.assignableProducts.length === 1 &&
          productList.getTotalProvisionedQuantity() === 0
        ) {
          vm.formType = QUICK_ASSIGN_FORM_TYPES.SINGLE_SEAT;
          vm.showFooter = false;
          vm.centered = 'true';
        } else {
          vm.formType = QUICK_ASSIGN_FORM_TYPES.EMAIL_LIST;
          vm.showFooter = true;
        }

        if (vm.isFirstSessionAccess) {
          showWelcome();
        } else {
          vm.contentState = 'Form';
        }
      });

      dispatchModalDisplayAnalytics();

      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function isConfirmDisabled() {
      switch (vm.formType) {
        case QUICK_ASSIGN_FORM_TYPES.CSV:
          return vm.files.length === 0 || !vm.$resolved;
        default:
          return !vm.formReady || !vm.hasAddedUsers();
      }
    }

    function closeConfirmationModal() {
      panelManager.close(vm.QUICK_ASSIGN_CONFIRMATION_MODAL_ID);
    }

    function closeQuickAssignModal(params = {}) {
      panelManager.close(vm.id, params);
      _.invoke(vm, 'onClosed');
    }

    function closeQuickAssignAndConfirmationModal() {
      closeQuickAssignModal();
      panelManager.close(vm.QUICK_ASSIGN_CONFIRMATION_MODAL_ID);
      _.invoke(vm, 'onClosed');
      // enclose sending analytics in $timeout() to fix timing conflict
      // with opening confirmation modal which prevents success analytics dispatch
      $timeout(() => {
        sendClickAnalytics('quick-assign-confirmation-modal-close-all');
      });
    }

    function getNumberOfAssignedLicenses() {
      let numAssignedLicenses = 0;
      if (vm.orgUserList) {
        _.forEach(vm.orgUserList.addedItems, (orgUser) => {
          numAssignedLicenses += orgUser.products.length;
        });
      }
      return numAssignedLicenses;
    }

    function hasAddedUsers() {
      return vm.orgUserList ? !_.isEmpty(vm.orgUserList.addedItems) : false;
    }

    function onConfirmationModalCancel() {
      $timeout(() => {
        sendClickAnalytics('quick-assign-confirmation-modal-go-back');
      });
    }

    function onDownload() {
      sendClickAnalytics('self-assign-download');
      QuickAssignHelper.onDownload(vm.productDataList[0], onDone);
    }

    function onQuickAssignConfirm() {
      $timeout(() => {
        sendClickAnalytics('on-quick-assign-confirm');
      });

      switch (vm.formType) {
        case QUICK_ASSIGN_FORM_TYPES.CSV:
          vm.$resolved = false;
          uploadCsv();
          break;
        default:
          onSubmit()
            .then(showThankYou)
            .catch(() => {
              vm.submissionError = true;
            });
      }

      function uploadCsv() {
        const bulkContextData = _.pick(vm.bulkContext, [
          'uploadFunction',
          'uploadParams',
          'uploadResource',
        ]);
        const promises = [];
        _.forEach(vm.files, (file) => {
          const uploadPromise = bulkOperationUtils.uploadFile(
            bulkContextData,
            vm.pageContext,
            file
          );
          promises.push(uploadPromise);
        });
        return $q
          .all(promises)
          .then(showThankYou, onCSVUploadError)
          .finally(() => {
            vm.$resolved = true;
          });
      }

      function onCSVUploadError(err) {
        vm.displayMessage = bulkOperationUtils.getErrorMessage(err);
      }
    }

    function onFormStatusChange(isValid) {
      vm.formReady = isValid;
    }

    function onDone() {
      sendClickAnalytics('quick-assign-done');
      closeQuickAssignModal({done: true});

      switch (vm.formType) {
        case QUICK_ASSIGN_FORM_TYPES.CSV: {
          const successMessage = bulkOperationUtils.getSuccessMessage(vm.files.length);
          toastManager.showSuccessToast(successMessage);
          break;
        }
        default:
          // Note: keep this check as to only show toast when there are brand new users added to the org.
          // In the case of assigning preexisting users, we do not show a toast.
          if (vm.numAddedUsers > 0) {
            showToast(vm.numAddedUsers);
          }
      }
    }

    function onOpen(params) {
      vm.isOpenedFromRedirectContext = _.get(params, 'isOpenedFromRedirectContext', false);
    }

    function onQuickAssignCancel() {
      $timeout(() => {
        sendClickAnalytics('on-quick-assign-cancel');
      });
      vm.onConfirmationModalConfirm = closeQuickAssignAndConfirmationModal;
      openConfirmationModal(CONFIRMATION_MODAL_TYPES.CANCEL);
    }

    function onSingleSeatFinish(options = {}) {
      if (options.decideLater) {
        onQuickAssignCancel();
      } else {
        closeQuickAssignModal();
      }
    }

    function onQuickAssignClose(options = {}) {
      const {done} = options;

      // only show close confirmation modal on flows that dont end in a done button cta click
      if (!done) {
        // re-open the quick assign modal when a client clicks
        // outside the content area since we wanted to open the confirmation modal on top of
        // the quick assign modal
        panelManager.open(vm.id);
        onQuickAssignCancel();
      }
    }

    // Override the escape button
    function onQuickAssignEscape() {
      onQuickAssignClose();

      // Closing the modal by pressing the ESC key will trigger onQuickAssignEscape() but does not update bindings or watchers.
      // On the other hand, for `ng-click` events, $http callbacks are all wrapped in $scope.$apply().
      // Thus, we don’t need to call $scope.$apply() for ng-click but need to call $scope.$apply() for escape handler.
      $scope.$apply(() => {
        panelManager.open(vm.QUICK_ASSIGN_CONFIRMATION_MODAL_ID);
      });
    }

    function onSubmit(options = {}) {
      if (vm.orgUserList.addedItems.length <= 0) {
        return $q.reject();
      }

      // Need to deep clone this collection as it is mutated by the save()
      const committedItems = _.cloneDeep(vm.orgUserList.addedItems);
      const newUsers = _(vm.orgUserList.addedItems)
        .uniqBy((addedItem) => [addedItem.email, addedItem.type].join())
        .filter((user) => user.isNew())
        .value();
      vm.numAddedUsers = newUsers.length;

      vm.formReady = false;
      const userEmail = AuthenticatedUser.get().getEmail();
      vm.isSelfAssignedSingleProduct =
        _(vm.orgUserList.addedItems).union(vm.preassignedUsers).map('email').includes(userEmail) &&
        vm.assignableProducts.length === 1;

      vm.waitPromise = vm.orgUserList
        .save()
        .then(() => sendSuccessAnalytics(committedItems))
        .then(() => {
          vm.formReady = true;
          if (options.blockThankYou) {
            vm.backgroundClass = BACKGROUND_CLASSES.THANK_YOU_BANNER;
          } else {
            showThankYou();
          }
        });

      return vm.waitPromise;
    }

    function onSwitchToEmail() {
      if (vm.files.length > 0) {
        vm.onConfirmationModalConfirm = switchToEmail;
        openConfirmationModal(CONFIRMATION_MODAL_TYPES.CSV);
      } else {
        initEmailListForm();
      }
    }

    function onSwitchToCsv(hasUnsavedInfo) {
      if (getNumberOfAssignedLicenses() > 0 || hasUnsavedInfo) {
        vm.onConfirmationModalConfirm = switchToCSV;
        openConfirmationModal(CONFIRMATION_MODAL_TYPES.EMAIL);
      } else {
        initCsvForm();
      }
    }

    function onUsersChange(orgUserList) {
      vm.orgUserList = orgUserList;
      // remove existing server error banner if a valid change has gone through api calls
      if (vm.submissionError) {
        vm.submissionError = false;
      }
    }

    function onWelcomeNext() {
      vm.contentState = 'Form';
      vm.backgroundClass = null;
      if (vm.formType !== QUICK_ASSIGN_FORM_TYPES.SINGLE_SEAT) {
        vm.centered = 'false';
        vm.showFooter = true;
      }
    }

    function openConfirmationModal(modalType) {
      const modalStates = {
        assignedLicenses: getNumberOfAssignedLicenses(),
        isSingleSeat: vm.formType === QUICK_ASSIGN_FORM_TYPES.SINGLE_SEAT,
        modalType,
        totalAvailableLicenses: vm.numAvailableLicenses,
      };

      _.assign(vm, QuickAssignHelper.getQuickAssignCloseConfirmationModalDetails(modalStates));
      panelManager.open(vm.QUICK_ASSIGN_CONFIRMATION_MODAL_ID);
    }

    function showThankYou() {
      vm.backgroundClass = BACKGROUND_CLASSES.THANK_YOU_BANNER;
      vm.contentState = 'ThankYou';
      vm.centered = 'true';
      vm.doneVariant = vm.isSelfAssignedSingleProduct ? 'quiet' : 'cta';
      vm.doneButtonKey = vm.isSelfAssignedSingleProduct
        ? 'widgets.users.quickAssignModal2.buttons.maybeLater'
        : 'widgets.users.quickAssignModal2.buttons.done';
      vm.downloadButtonLabel = QuickAssignHelper.getDownloadButtonLabel(vm.productDataList[0]);
      vm.showFooter = false;
    }

    function showWelcome() {
      vm.backgroundClass = BACKGROUND_CLASSES.WELCOME_BANNER;
      vm.contentState = 'Welcome';
      vm.centered = 'true';
      vm.showFooter = false;
      // eslint-disable-next-line promise/catch-or-return
      onSubmit().then(() => {
        closeQuickAssignModal();
      });
    }

    function thankYouPageTitleKey() {
      if (vm.formType === QUICK_ASSIGN_FORM_TYPES.CSV) {
        return $translate.instant(
          'widgets.users.quickAssignModal2.thankYouPage.csvForm.title',
          {count: vm.files.length},
          'messageformat'
        );
      }
      return $translate.instant('widgets.users.quickAssignModal2.thankYouPage.default.title');
    }

    function thankYouPageSubLine1Key() {
      if (vm.formType === QUICK_ASSIGN_FORM_TYPES.CSV) {
        return 'widgets.users.quickAssignModal2.thankYouPage.csvForm.subLine1';
      }
      return hasAsyncProvisionedProduct
        ? 'widgets.users.quickAssignModal2.thankYouPage.default.subLine1Async'
        : 'widgets.users.quickAssignModal2.thankYouPage.default.subLine1';
    }

    function thankYouPageSubLine2Key() {
      return $translate.instant(
        'widgets.users.quickAssignModal2.thankYouPage.selfAssign.subLine2',
        {productName: vm.productDataList[0].longName},
        'messageformat'
      );
    }

    // private functions

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function initCsvForm() {
      vm.formType = QUICK_ASSIGN_FORM_TYPES.CSV;
      vm.showFooter = true;

      vm.files = [];
      vm.$resolved = true; // state of csv form submission. To prevent multiple accidental submissions
      hidePageBanner();

      vm.pageContext = new PageContext({
        target: PAGE_TARGET.ORGANIZATION,
        targetId: OrganizationManager.getActiveOrgId(),
        targetType: PAGE_TARGET_TYPE.USER,
      });
      vm.bulkOpMode = BULK_OPERATION_MODE.ADD;
      bulkOperationContext
        .initialize(vm.bulkOpMode, vm.pageContext)
        .then((response) => {
          vm.bulkContext = response;
        })
        .catch(_.noop)
        .finally(() => {
          _.assign(
            vm,
            _.pick(vm.bulkContext, ['uploadFunction', 'uploadParams', 'uploadResource'])
          );
        });
    }

    function initEmailListForm() {
      vm.formType = QUICK_ASSIGN_FORM_TYPES.EMAIL_LIST;
      vm.showFooter = true;
      initProductDataList();
    }

    function initProductDataList() {
      const productListWithLicenseGroups = ProductList.get({
        licenseGroupLimit: 1,
        notifyListeners: false,
        orgId: OrganizationManager.getActiveOrgId(),
      });

      vm.productDataList = _.map(vm.assignableProducts, (product) => {
        const availableLicenseCountForProduct = product.getAvailableLicenseCount();

        // we will add preassignedProductCount to the license values below to display the right value.
        // but we will subtract them when calculating form validity and state
        const preassignedProductCount = _.get(vm.preassignedProductCount, product.id, 0);
        const data = {
          assets: product.assets,
          assignedLicenses: preassignedProductCount,
          availableLicenses: availableLicenseCountForProduct + preassignedProductCount,
          cloud: product.cloud,
          code: product.code,
          family: product.family,
          id: product.id,
          isAdobeStock: product.isAdobeStock(),
          isTrial: trialHelper.isTrialProduct(product),
          longName: product.longName,
        };
        data.licenseGroupSummaryPromise = productListWithLicenseGroups.$promise.then(() => {
          const matchedProduct = _.find(productListWithLicenseGroups.items, {id: data.id});
          return _.get(matchedProduct, 'licenseGroupSummaries[0]');
        });

        hasAsyncProvisionedProduct = hasAsyncProvisionedProduct || data.isTrial;

        return data;
      });
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }

    function sendSuccessAnalytics(committedItems) {
      const deferred = $q.defer();

      // The asynchronous analytics dispatch may reject but this should not
      // impact the user.
      dispatchUserModalSuccessAnalytics({
        committedItems,
        eventName: 'QuickAssignModal2',
        orgId: OrganizationManager.getActiveOrgId(),
      }).finally(() => deferred.resolve());

      return deferred.promise;
    }

    function showToast(numAddedUsers) {
      const message = $translate.instant(
        'widgets.users.quickAssignModal2.assignedConfirmedToast',
        {
          users: numAddedUsers,
        },
        'messageformat'
      );

      toastManager.showSuccessToast(message);
    }

    function switchToCSV() {
      sendClickAnalytics('quick-assign-confirmation-modal-switch-to-csv');
      closeConfirmationModal();
      vm.orgUserList = undefined;
      initCsvForm();
    }

    function switchToEmail() {
      sendClickAnalytics('quick-assign-confirmation-modal-switch-to-email');
      closeConfirmationModal();
      vm.orgUserList = undefined;
      initEmailListForm();
    }

    function dispatchModalDisplayAnalytics() {
      const orgId = OrganizationManager.getActiveOrgId();
      const analyticsContext = {};
      constructAsyncAddAssignUserAnalytics({orgId})
        .then((result) => {
          _.assign(analyticsContext, result);
        })
        .catch((error) => {
          $log.error(
            'The analytics context function provided has unexpectedly rejected and has been ignored',
            error
          );
        })
        .finally(() => {
          AnalyticsHelper.dispatchUiEventAnalytics(
            _.assign(analyticsContext, {
              eventAction: EVENT_ACTION.DISPLAY,
              eventName: 'quickAssignModal2',
            })
          );
        });
    }
  }
})();
/* eslint-enable max-lines */
