(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    _,
    $delegate,
    $q,
    $translate,
    auth,
    CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    SVG_CLASS,
    OrganizationManager,
    ConsumableSummarizationList
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function hasSDLProductsWithNoSeatsAssigned(productList) {
      const sdlProducts = _.filter(productList.items, (product) =>
        product.fulfillableItemList.hasLaboratoryLicenseManagement()
      );

      return $q.all(
        _.map(sdlProducts, (product) =>
          ConsumableSummarizationList.get({
            include_usage: false,
            organization_id: OrganizationManager.getActiveOrgId(),
            summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
          })
            .$promise.then((summarizationList) =>
              summarizationList.getConsumablesForSummaryId(product.id, {
                summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
              })
            )
            .then((consumables) => _.some(consumables, ['consumedQuantity', 0]))
        )
      );
    }

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.SET_UP_SDL,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          return OrganizationManager.getProductsForActiveOrg()
            .$promise.then((productList) => hasSDLProductsWithNoSeatsAssigned(productList))
            .then((sdlSeatList) =>
              sdlSeatList.length > 0 && _.every(sdlSeatList) ? $q.resolve() : $q.reject()
            )
            .then(() => [
              new HvaCard({
                ctaLabel: $translate.instant(
                  'overview.highValueActionArea.setUpTasks.setUpSDL.ctaLabel'
                ),
                description: $translate.instant(
                  'overview.highValueActionArea.setUpTasks.setUpSDL.description'
                ),
                header: $translate.instant(
                  'overview.highValueActionArea.setUpTasks.setUpSDL.header'
                ),
                id: 'hva-set-up-sdl',
                onCTAClick: () => _.noop,
                sectionTitle: $translate.instant(
                  'overview.highValueActionArea.setUpTasks.setUpSDL.sectionTitle'
                ),
                svgClass: SVG_CLASS.CREATE_PACKAGES,
              }),
            ]);
        }
        return $q.reject();
      }
    }
  }
})();
