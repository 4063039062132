import {canViewValueDiscoveryTasksForEnterprise} from 'core/organizations/access/organizationAccess';
import {goToContentLogs} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * Class to create HVA card for users to view reports
 */
class ViewReportsHva extends HvaCard {
  /**
   * @description Method to fetch the ViewReportsHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<ViewReportsHva[]>} the fetched instance
   */
  static get({intl}) {
    if (!canViewValueDiscoveryTasksForEnterprise()) {
      return Promise.reject();
    }
    return Promise.resolve(new ViewReportsHva({intl}));
  }

  /**
   * @description Creates a new ViewReportsHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {ViewReportsHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.viewReports';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_pop_artt_content_logs',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/reports.svg',
      },
      id: 'hva-view-reports',
      isDismissible: true,
      onCTA: goToContentLogs,
      priorityOrder: HVA_ORDER.VIEW_REPORTS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default ViewReportsHva;
