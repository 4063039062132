(function () {
  /**
   * @deprecated use binkySrc2.services.collaborationPolicies.CollaborationPolicies
   *
   * @ngdoc factory
   * @name app.core.asset-sharing:PolicyList
   * @description abstract representation of a policy list.
   */
  angular.module('app.core.asset-sharing').factory('PolicyList', policyList);

  function policyList($q, _, MODEL, modelCache, Policy, policyService) {
    class PolicyList {
      /**
       * @description look up a policy in list by name.
       * @param {String} name name of policy.
       * @returns {Object} mutable reference to the policy object.
       */
      getPolicy(name) {
        return this.indexedPolicies[name];
      }

      /**
       * @description determine whether policy values have been modified.
       * @returns {Boolean} return true if local copy has been modified.
       */
      hasUnsavedChanges() {
        return _.some(
          this.savedState,
          (policy) =>
            !_.eq(JSON.stringify(policy.value), JSON.stringify(this.getPolicy(policy.name).value))
        );
      }

      /**
       * @description update PolicyList model from API
       * @returns {Promise} resolved with the policy list if no errors.
       */
      refresh() {
        this.$promise = policyService.policies
          .query()
          .$promise.then((response) => fromApi(response))
          .then(_.partial(setModel, this));

        return this.$promise;
      }

      /**
       * @description method to call update API with policy list.
       * @returns {Promise} resolved with the updated policy list on success.
       */
      save() {
        if (!this.hasUnsavedChanges()) {
          return $q.resolve(this);
        }
        const reqBody = toApi(this.items);
        this.$promise = policyService.policies
          .save(reqBody)
          .$promise.then((response) => fromApi(response))
          .then(_.partial(setModel, this))
          .catch((error) => {
            // Revert state on update fail
            setModel(this, this.savedState);
            return $q.reject(error);
          });

        return this.$promise;
      }

      static get() {
        let model = modelCache.get(MODEL.POLICYLIST);

        if (!model) {
          model = new PolicyList();
          model.refresh();
        }
        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.POLICYLIST, 1);

    return PolicyList;

    /////////////////

    function fromApi(resource) {
      const items = _.map(resource, (policyObj) => new Policy(policyObj));
      const errors = _(items).filter(['hasError', true]).map('err').value();
      return errors.length > 0 ? $q.reject(errors) : $q.resolve(items);
    }

    function setModel(model, items) {
      _.assign(model, {
        indexedPolicies: _.keyBy(items, 'name'),
        items,
        savedState: _.cloneDeep(items),
      });
      modelCache.put(MODEL.POLICYLIST, model);

      return model;
    }

    function toApi(policies) {
      return _.invokeMap(policies, 'toApiFormat');
    }
  }
})();
