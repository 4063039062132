import {MemberList, OrganizationUser, jilUsers} from '@admin-tribe/binky';

const DELEGATIONS_USERS_LIST_CACHE_ID = 'delegationsUsersList';

/**
 * @description Class representing a delegations users list.
 * @todo Move this module binky
 * @extends MemberList
 */
class DelegationsUsersList extends MemberList {
  /**
   * @description Method to retrieve an existing user list of users
   *   that do not have access (incompatible id type).
   * @param {string} options - options to get the delegations users list
   * @param {number} options.pageSize - Page size for when this list is fetched.
   * @returns {DelegationsUsersList} model of existing user list
   */
  static getIncompatibleIDTypeUsers(options) {
    const params = {...options, no_access_code: 'IDENTITY_TYPE'};
    return this.get(params);
  }

  /**
   * @param {Object} options - Top level wrapper object
   * @param {number} options.pageSize - Page size for when this list is fetched.
   * @param {string} options.no_access_code - String value that will be passed to
   *     resource to influence what entries are returned.
   */
  constructor(options) {
    super({
      itemClassRef: OrganizationUser,
      modelCacheId: DELEGATIONS_USERS_LIST_CACHE_ID,
      refreshResource: jilUsers.queryDelegationsUsers,
      ...options,
    });
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      no_access_code: 'IDENTITY_TYPE',
      pageSize: 1,
    };
  }
}

export default DelegationsUsersList;
