(function () {
  /**
   * @ngdoc module
   * @name app.core.ready.analytics
   * @description defines analyticsReady service, which blocks the app on registering analytics.
   */
  angular.module('app.core.ready.analytics', [
    'app.core.analytics',
    'app.core.ready.org',
    'binky.core.authentication.ready',
    'binky.core.configuration.ready',
  ]);
})();
