import {Item, Picker, Section, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SCHEDULE_OPTIONS} from 'features/settings/api/eduRosterSync';

const SYNC_TYPES = {AUTOMATIC: 'automatic', ONDEMAND: 'on-demand'};

const PICKER_OPTIONS = [
  {
    syncType: SYNC_TYPES.AUTOMATIC,
    types: [
      {
        id: SCHEDULE_OPTIONS.WEEKLY,
        label: 'settings.sync.eduRosterSyncSchedule.labels.weekly',
      },
      {
        id: SCHEDULE_OPTIONS.DAILY,
        label: 'settings.sync.eduRosterSyncSchedule.labels.daily',
      },
    ],
  },
  {
    syncType: SYNC_TYPES.ONDEMAND,
    types: [
      {
        id: SCHEDULE_OPTIONS.MANUAL,
        label: 'settings.sync.eduRosterSyncSchedule.labels.onDemand',
      },
    ],
  },
];

const EduRosterSyncSchedule = ({onChange, defaultSelectedKey, isDisabled, ...styleProps}) => {
  const intl = useIntl();

  return (
    <Picker
      data-testid="sync-frequency-picker"
      defaultSelectedKey={defaultSelectedKey}
      isDisabled={isDisabled}
      items={PICKER_OPTIONS}
      label={intl.formatMessage({id: 'settings.sync.eduRosterSyncSchedule.pickerLabel'})}
      marginTop="size-100"
      onSelectionChange={onChange}
      {...styleProps}
    >
      {(item) => (
        <Section key={item.syncType} items={item.types}>
          {(type) => (
            <Item key={type.id}>
              <Text>{intl.formatMessage({id: type.label})}</Text>
            </Item>
          )}
        </Section>
      )}
    </Picker>
  );
};

EduRosterSyncSchedule.propTypes = {
  defaultSelectedKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default EduRosterSyncSchedule;
export {PICKER_OPTIONS, SYNC_TYPES};
