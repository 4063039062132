import {hasOnlyTeamProducts} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canAssignUser} from 'core/products/access/productAccess';
import auth from 'core/services/auth';
import {goToProducts} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

function hasAtLeastOneProductUnassigned() {
  return rootStore.organizationStore.productList.items.some(
    (product) => !!(canAssignUser(product) && product.provisionedQuantity === 0)
  );
}

/**
 * This class creates HVA card allowing user to assign enterprise licenses
 */
class AssignEnterpriseLicensesHva extends HvaCard {
  static get({intl}) {
    if (
      auth.isUserOrgAdmin() &&
      !hasOnlyTeamProducts(rootStore.organizationStore.productList) &&
      hasAtLeastOneProductUnassigned()
    ) {
      return Promise.resolve([new AssignEnterpriseLicensesHva({intl})]);
    }

    return Promise.reject();
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.assignEnterpriseLicenses';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      goUrl: 'ac_manage_product',
      header: intl.formatMessage({
        id: `${namespace}.header`,
      }),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/assign-licenses.svg',
      },
      id: 'hva-assign-enterprise-licenses',
      isDismissible: false,
      onCTA: () => goToProducts(),
      priorityOrder: HVA_ORDER.ASSIGN_ENTERPRISE_LICENSES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default AssignEnterpriseLicensesHva;
