import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the Add Domains modal and its sub-components
 */
const AddDomainsModalContext = createContext({});
const useAddDomainsModalContext = () => useContext(AddDomainsModalContext);

const AddDomainsModalContextProvider = ({children, state}) => {
  const value = useMemo(() => ({...state}), [state]);

  return (
    <AddDomainsModalContext.Provider value={value}>{children}</AddDomainsModalContext.Provider>
  );
};

AddDomainsModalContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the addable domain list.
   */
  children: PropTypes.node,
  /**
   * The state of the Add Domains Modal used by the provider to keep state of the children components.
   */
  state: PropTypes.shape({
    updateSelectedDomains: PropTypes.func,
  }),
};

export {AddDomainsModalContextProvider, useAddDomainsModalContext};
