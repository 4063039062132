import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import UserGroupModal from 'common/components/user-groups/UserGroupModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';

const NewUserGroupButton = observer(() => {
  const {canAddMember, store} = useMemberListContext();
  const [isUserGroupModalOpen, setIsUserGroupModalOpen] = useState();

  const handleNewUserGroup = () => {
    setIsUserGroupModalOpen(true);
  };

  const onCancel = () => {
    setIsUserGroupModalOpen(false);
  };

  const onSave = () => {
    setIsUserGroupModalOpen(false);
    store.load();
  };

  return (
    canAddMember && (
      <>
        <Button data-testid="add-user-group-btn" onPress={handleNewUserGroup} variant="accent">
          <FormattedMessage id="users.usersGroups.button.newUserGroup" />
        </Button>
        {isUserGroupModalOpen && (
          <UserGroupModal
            isOpen={isUserGroupModalOpen}
            onCancel={onCancel}
            onSave={onSave}
            orgId={store.orgId}
          />
        )}
      </>
    )
  );
});

export default NewUserGroupButton;
