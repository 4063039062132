/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseIssueDescriptionForm
 *
 * @description The Issue Description form for the Create Case modal wizard.
 * @param {Array} caseAttachments - storage for any attachments added while creating
 *   a support case
 * @param {SUPPORT_TICKET_CASE_TYPE} type of ticket - 'ADMIN' or 'PRODUCT'
 * @param {Function} onFormChange - Callback when the form validity changes or when the form is valid, every form value
 *   change is reported.
 */
(function () {
  angular.module('app.widgets.support').component('appCaseIssueDescriptionForm', {
    bindings: {
      caseAttachments: '<',
      caseType: '<',
      onFormChange: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-issue-description-form/case-issue-description-form.component.html',
  });

  function controller(_, $log, feature, SUPPORT_TICKET_CASE_TYPE, supportAccess, SupportCase) {
    const vm = this;

    let isFormInvalid = true;
    let isProductMetadataInvalid = false; // assume there isn't any product metadata

    _.assign(vm, {
      $doCheck,
      $onInit,
      feature,
      isCaseTypeProduct,
      issueDescription: {},
      onDescriptionChange,
      onImpactCardSelected,
      onPriorityCardSelected,
      onProductMetadataChange,
      onProductSelected,
      onTitleChange,
    });

    // Triggered for every digest cycle of the component.
    function $doCheck() {
      checkFormValidity();
    }

    function $onInit() {
      vm.introKey =
        vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
          ? 'widgets.support.caseIssueDescriptionForm.introProduct'
          : 'widgets.support.caseIssueDescriptionForm.introAdmin';

      vm.issueDescription.allowP1 = false;
      updateProductMetadata();
    }

    function onDescriptionChange(description) {
      vm.issueDescription.description = description;
    }

    function onImpactCardSelected(impact) {
      vm.issueDescription.impact = impact;
    }

    function onProductMetadataChange(caseMetadata, instanceUrl, isInvalid) {
      vm.issueDescription.caseMetadata = caseMetadata;
      vm.issueDescription.instanceUrl = instanceUrl;

      isProductMetadataInvalid = isInvalid;
    }

    function onPriorityCardSelected(priority) {
      vm.issueDescription.priority = priority;
    }

    function onProductSelected(fulfillableItem) {
      vm.issueDescription.product = fulfillableItem
        ? {
            fulfillableItemCode: fulfillableItem.code,
            name: fulfillableItem.longName,
          }
        : undefined;

      vm.issueDescription.allowP1 = isP1CaseCreationAllowed();
      updateProductMetadata();
    }

    function onTitleChange(title) {
      vm.issueDescription.title = title;
    }

    //////////////

    function isDescriptionInvalid() {
      return (
        _.isUndefined(vm.issueDescription.description) || _.isEmpty(vm.issueDescription.description)
      );
    }

    function isCaseTypeProduct() {
      return vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT;
    }

    function isProductInvalid() {
      return isCaseTypeProduct() && _.isUndefined(vm.issueDescription.product);
    }

    function isTitleInvalid() {
      return _.isUndefined(vm.issueDescription.title) || _.isEmpty(vm.issueDescription.title);
    }

    function isP1CaseCreationAllowed() {
      return (
        vm.issueDescription.product &&
        supportAccess.canCreateP1Case(vm.issueDescription.product.fulfillableItemCode)
      );
    }

    function checkFormValidity() {
      const isInvalid =
        _.isUndefined(vm.issueDescription.priority) ||
        _.isUndefined(vm.issueDescription.impact) ||
        isProductInvalid() ||
        isProductMetadataInvalid ||
        isTitleInvalid() ||
        isDescriptionInvalid();

      // Report validity transitions so CTA button can be enabled accordingly.
      // While form is valid, report every change because the CTA button can be clicked and the modal must have the
      // current form values.
      if (isInvalid !== isFormInvalid || !isInvalid) {
        isFormInvalid = isInvalid;
        vm.onFormChange({event: {isInvalid, issueDescription: vm.issueDescription}});
      }
    }

    function updateProductMetadata() {
      // First re-initialize. Delete the product metadata properties in case Parkour gets upset if we send them for
      // a product which doesn't have metadata.
      vm.productMetadata = undefined;
      delete vm.issueDescription.caseMetadata;
      delete vm.issueDescription.instanceUrl;

      isProductMetadataInvalid = false;

      // If there is a product, check if there is any product metadata.
      if (vm.issueDescription.product) {
        // Disable CTA while waiting for product metadata.
        isProductMetadataInvalid = true;

        SupportCase.getProductMetadata({
          fulfillableItemCode: vm.issueDescription.product.fulfillableItemCode,
        })
          .then((response) => {
            vm.productMetadata = response;
          })
          .finally(() => {
            // Invalid if productMetadata, valid if no productMetadata.
            isProductMetadataInvalid = !!vm.productMetadata;
          });
      }
    }
  }
})();
