import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext, UserGroupList} from '@admin-tribe/binky';
import {
  InfoItemScorecard,
  Page,
  PageBanners,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {USER_GROUPS_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import UserGroupListTableSection from 'common/components/user-group-list-table-section/UserGroupListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import UserGroupStore from 'common/stores/user-group-store/UserGroupStore';
import {
  canCreateUserGroup,
  canEditUserGroup,
  canOnlyViewAdministerableGroups,
  canRemoveUserGroup,
  canViewUserGroupsBulkOperations,
} from 'core/user-group/access/userGroupAccess';

/**
 * User groups page.
 */
const UserGroupsPage = observer(() => {
  const {orgId} = useParams();

  useDocumentTitle({key: 'users.usersGroups.documentTitle'});

  const pageContext = new PageContext({
    target: PAGE_TARGET.USER_GROUP,
    targetId: orgId,
    targetType: PAGE_TARGET_TYPE.USER_GROUP,
  });

  const userGroupStore = useStore(
    () =>
      new UserGroupStore({
        listClassRef: UserGroupList,
        listOptions: {
          administerable: canOnlyViewAdministerableGroups(),
        },
      })
  );

  const intl = useIntl();
  const canAddMember = canCreateUserGroup();
  const canRemoveMember = canRemoveUserGroup();

  const userGroupOperations = useMemo(
    () =>
      // Currently all operations require an orgAdmin.
      UserOperations.getUserGroupOperations({
        canAdd: canAddMember,
        canEdit: canEditUserGroup(),
        canViewResults: canViewUserGroupsBulkOperations(),
      }),
    [canAddMember]
  );

  return (
    <Page
      data-testid="user-groups-page"
      isLoading={userGroupStore.isLoading && !userGroupStore.currentItems}
    >
      <PageBanners>
        <GlobalAdminPolicyBanner context={USER_GROUPS_CONTEXT} />
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'users.usersGroups.title'})} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.userGroups.scorecard.label'})}
          value={userGroupStore.scorecardValue}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          canAddMember={canAddMember}
          canRemoveMember={canRemoveMember}
          memberListDisplay={{
            email: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          store={userGroupStore}
          userOperations={userGroupOperations}
        >
          <UserGroupListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default UserGroupsPage;
