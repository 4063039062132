(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular
    .module('app.widgets.support')
    .constant('CASE_TYPE_SELECTION_CARD', {
      CREATIVECLOUD_DOCUMENTCLOUD: 'creativecloud_documentcloud',
      EXPERIENCECLOUD: 'experiencecloud',
      USERS_LICENSES: 'users_licenses',
    })
    .constant('CASE_TYPE_SELECTION_MODAL_ID', 'modal-case-type-selection');
})();
