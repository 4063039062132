import {DIRECTORY_ENCRYPTION_STATUS, kmi} from '@admin-tribe/binky';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const MODAL_ID = 'toggle-directory-encryption-confirmation-modal';

const contentRevoke = {
  areYouSure: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.revoke.areYouSure',
  description: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.revoke.description',
  primaryActionLabel: 'settings.directorySettingsModal.encryptionKeySection.revoke',
  successToast:
    'settings.directorySettingsModal.toggleDirectoryEncryptionModal.revoke.successToast',
  title: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.revoke.title',
};

const contentEnable = {
  areYouSure: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.enable.areYouSure',
  description: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.enable.description',
  primaryActionLabel: 'common.modal.buttons.enable',
  successToast:
    'settings.directorySettingsModal.toggleDirectoryEncryptionModal.enable.successToast',
  title: 'settings.directorySettingsModal.toggleDirectoryEncryptionModal.enable.title',
};

const ToggleDirectoryEncryptionConfirmationModal = ({directoryEncryptionInfo, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const content = useMemo(
    () =>
      directoryEncryptionInfo.encryptionStatus === DIRECTORY_ENCRYPTION_STATUS.ENABLED
        ? contentRevoke
        : contentEnable,
    [directoryEncryptionInfo.encryptionStatus]
  );

  const [isLoading, setIsLoading] = useState(false);

  const onCta = async () => {
    setIsLoading(true);

    try {
      const response = await (directoryEncryptionInfo.encryptionStatus ===
      DIRECTORY_ENCRYPTION_STATUS.ENABLED
        ? kmi.revokeDirectoryEncryption({
            directoryId: directoryEncryptionInfo.domainId,
            orgId: rootStore.organizationStore.activeOrgId,
          })
        : kmi.enableDirectoryEncryption({
            directoryId: directoryEncryptionInfo.domainId,
            orgId: rootStore.organizationStore.activeOrgId,
          }));

      onConfirm(response.data);
      showSuccessToast(intl.formatMessage({id: content.successToast}));
    } catch {
      showErrorToast();
    } finally {
      setIsLoading(false);
      dialog.dismiss();
    }
  };

  return (
    <ModalAlertDialog
      id={MODAL_ID}
      isLoading={isLoading}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={onCta}
      primaryActionLabel={intl.formatMessage({id: content.primaryActionLabel})}
      title={intl.formatMessage({id: content.title})}
      variant="warning"
    >
      <View>
        <FormattedMessage id={content.description} />
      </View>

      <View marginTop="size-200">
        <FormattedMessage id={content.areYouSure} />
      </View>
    </ModalAlertDialog>
  );
};

ToggleDirectoryEncryptionConfirmationModal.propTypes = {
  directoryEncryptionInfo: PropTypes.shape({
    domainId: PropTypes.string.isRequired,
    encryptionStatus: PropTypes.oneOf(Object.values(DIRECTORY_ENCRYPTION_STATUS)),
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
};
export default ToggleDirectoryEncryptionConfirmationModal;
