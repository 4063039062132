/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to display the protocol string for an IdP.
 *
 * @param {String} idp an Idp instance
 *
 * @returns {String} IdP protocol based on federation type mapping
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.directories').filter('appIdpProtocol', appIdpProtocolFilter);

  /* @ngInject */
  function appIdpProtocolFilter($translate, _, IDP_TYPES) {
    return getIdpProtocol;

    function getIdpProtocol(idp) {
      if (!idp) {
        return $translate.instant('widgets.directories.idpList.idpProtocols.unknown');
      }

      return $translate.instant(
        _.includes(IDP_TYPES, idp.federationType)
          ? `widgets.directories.idpList.idpProtocols.${idp.federationType}`
          : 'widgets.directories.idpList.idpProtocols.unknown'
      );
    }
  }
})();
