import {DATE_FORMATS, FormattedDateAndTime} from '@admin-tribe/binky-ui';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @iteodore needs to fix
import Tooltip from '@react/react-spectrum/Tooltip';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './SyncCard.pcss';

const NOT_STARTED = 'NOT_STARTED';

const SyncStatusLabel = observer(({adminEnabled, enabled, status, lastSyncDate}) => {
  const intl = useIntl();

  if (!adminEnabled) {
    return <FormattedMessage id="settings.sync.status.adminDisabled" />;
  }
  if (!enabled) {
    return (
      <OverlayTrigger placement="right">
        <div styleName="tooltip-info">
          <FormattedMessage id="settings.sync.status.syncOff" />
        </div>
        <Tooltip>{intl.formatMessage({id: 'settings.sync.status.tooltip.syncOff'})}</Tooltip>
      </OverlayTrigger>
    );
  }
  if (status === NOT_STARTED) {
    return <FormattedMessage id="settings.sync.status.notStarted" />;
  }
  return (
    <FormattedMessage
      id="settings.sync.status.lastSyncTime"
      values={{
        date: <FormattedDateAndTime format={DATE_FORMATS.dateTime} value={lastSyncDate} />,
      }}
    />
  );
});

export default SyncStatusLabel;
