(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('app.widgets.asset-sharing').component('appWhitelistedDomainListTable', {
    bindings: {
      selection: '<',
      whitelist: '<',
    },
    controller,
    templateUrl:
      'app/widgets/asset-sharing/whitelisted-domains/list/whitelisted-domain-list-table.component.html',
  });

  function controller(
    $scope,
    $timeout,
    _,
    JIL_CONSTANTS,
    SELECTION_STATE,
    WHITELISTED_DOMAIN_LIST_PAGINATION_ID
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      isChecked,
      onClickCheck,
      onClickCheckAll,
      onPageChange,
      onPageSizeChange,
      reverseSort,
    });

    function $onInit() {
      _.assign(vm, {
        paginationId: WHITELISTED_DOMAIN_LIST_PAGINATION_ID,
      });
      $scope.$watch(
        () => vm.whitelist.items,
        () => $timeout(updateHeaderCheckbox)
      );
    }

    function isChecked(domain) {
      return vm.selection.isItemSelected(domain);
    }

    function onClickCheck(domain) {
      vm.selection.toggleItemSelection(domain);
      $timeout(updateHeaderCheckbox);
    }

    function onClickCheckAll() {
      if (vm.isAllChecked) {
        vm.selection.deselectItems(vm.whitelist.items);
      } else {
        vm.selection.selectItems(vm.whitelist.items);
      }
      $timeout(updateHeaderCheckbox);
    }

    function onPageChange(pageNumber) {
      vm.whitelist.setPage(pageNumber);
      vm.whitelist.get();
      $timeout(updateHeaderCheckbox);
    }

    function onPageSizeChange(pageSize) {
      vm.whitelist.setPageSize(pageSize);
      vm.whitelist.get();
      $timeout(updateHeaderCheckbox);
    }

    function reverseSort() {
      const {ORDER} = JIL_CONSTANTS;
      const sortOrder = vm.sortReverse ? ORDER.ASC : ORDER.DESC;
      vm.whitelist.setSortOrder(sortOrder);
      vm.whitelist.get();
      vm.sortReverse = vm.whitelist.sort.currentSortOrder === JIL_CONSTANTS.ORDER.DESC;
      $timeout(updateHeaderCheckbox);
    }

    ///////////////

    function updateHeaderCheckbox() {
      if (_.get(vm.whitelist, 'items.length') > 0) {
        const selectionState = vm.selection.getSelectionStateForItems(vm.whitelist.items);
        vm.isAllChecked = selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
