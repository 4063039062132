(function () {
  const componentName = 'appUserGroupAddProfilesModal';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.user-groups').component(componentName, {
    bindings: {
      productProfileList: '<',
      userGroup: '<',
    },
    controller,
    templateUrl:
      'app/widgets/user-groups/add-profiles/user-group-add-profiles-modal.component.html',
  });

  /*  @ngInject */
  function controller(
    $q,
    $scope,
    $state,
    $translate,
    _,
    AnalyticsEvent,
    auth,
    binkyProductNameLabelFilter,
    DisplayMessage,
    feature,
    numberFilter,
    OrganizationManager,
    organizationUserUtils,
    panelManager,
    PRODUCT_PROFILE_SELECT_CHANGE,
    PRODUCT_PROFILE_SELECT_ERROR,
    ROLE,
    toastManager,
    USER_GROUP_ADD_PROFILES_MODAL_ID,
    USER_GROUP_THRESHOLD_GO_URL,
    USER_GROUP_USER_COUNT_THRESHOLD
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      isSaveDisabled,
      onCancel,
      onClose,
      onOpen,
      onSave,
    });

    function $onInit() {
      _.assign(vm, {
        hasChangedProductProfile: false,
        modalId: USER_GROUP_ADD_PROFILES_MODAL_ID,
        waitPromise: $q.resolve(),
      });

      $scope.$on(PRODUCT_PROFILE_SELECT_CHANGE, onProductProfileSelectChange);
      $scope.$on(PRODUCT_PROFILE_SELECT_ERROR, onError);
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function isSaveDisabled() {
      return !vm.hasChangedProductProfile || vm.isSubmitting;
    }

    function onCancel() {
      panelManager.close(USER_GROUP_ADD_PROFILES_MODAL_ID);
    }

    function onClose() {
      vm.isOpen = false;
      vm.hasChangedProductProfile = false;
      vm.isSubmitting = false;
      hidePageBanner();
      vm.userGroup = vm.userGroupBackup;
      vm.userGroupBackup = null;
    }

    function onError(err) {
      const errorCode = _.get(err, 'data.errorCode');
      if (
        errorCode === 'LDS_ERROR' &&
        err.data.ldsresponse.errorMessage === 'ALLOWABLE_LICENSE_COUNT_EXCEEDED'
      ) {
        // only show link if product is a team product and user is an org admin
        let showPurchaseLink = false;
        if (auth.hasAnyRole([ROLE.ADMIN.ORG])) {
          const productList = OrganizationManager.getProductsForActiveOrg();
          const foundProducts = [];
          _.forEach(err.data.ldsresponse.productRequiredLicenseInfos, (licenseInfo) => {
            const foundProduct = _.find(productList.items, (product) => {
              // workaround for https://git.corp.adobe.com/admin-tribe/onesie/issues/3090 (trim prefix)
              const idArray = _.split(licenseInfo.id, '-', 2);
              const trimmedId = idArray.length > 1 ? idArray[1] : undefined;
              return product.id === licenseInfo.id || product.id === trimmedId;
            });
            if (foundProduct) {
              foundProducts.push(foundProduct);
            }
          });

          if (_.find(foundProducts, {customerSegment: 'TEAM'})) {
            showPurchaseLink = auth.hasAnyRole([ROLE.ADMIN.ORG]);
          }
        }

        showPageBanner({
          message: $translate.instant('widgets.users.addUserModal.licenseCountExceeded'),
          showLicenseBtn: showPurchaseLink,
        });
      } else {
        showPageBanner();
      }
    }

    function onOpen() {
      vm.isOpen = true;
      vm.userGroupBackup = vm.userGroup;
      vm.userGroup = _.cloneDeep(vm.userGroup);

      checkForLargeGroup();
    }

    function onProductProfileSelectChange() {
      vm.hasChangedProductProfile = true;
    }

    function onSave() {
      vm.isSubmitting = true;
      const newLicenseGroups = organizationUserUtils.explodeProductLicenseGroups(
        vm.userGroup.products
      );
      const currentLicenseGroups = _.invokeMap(vm.productProfileList.items, 'toMinimumModel');
      const addedProductLicenses = _.differenceWith(
        newLicenseGroups,
        currentLicenseGroups,
        organizationUserUtils.compareProductLicenseGroups
      );
      const removedProductLicenses = _.differenceWith(
        currentLicenseGroups,
        newLicenseGroups,
        organizationUserUtils.compareProductLicenseGroups
      );
      vm.productProfileList.add(addedProductLicenses);
      vm.productProfileList.remove(removedProductLicenses);
      vm.waitPromise = vm.productProfileList
        .save()
        .then(() => {
          toastManager.showSuccessToast();
          panelManager.close(USER_GROUP_ADD_PROFILES_MODAL_ID);
          triggerAnalytics(addedProductLicenses.length, removedProductLicenses.length);
        })
        .catch(onError)
        .finally(() => {
          vm.isSubmitting = false;
        });
    }

    /////////////////

    // Display a warning message if the number of users in user group is >75k and <= 200k, but allow a profile
    // to be selected.
    // Display an error message if the user group has more than 200k users and do not allow a profile to be selected.
    function checkForLargeGroup() {
      let namespace, variant;

      if (vm.userGroup.userCount <= USER_GROUP_USER_COUNT_THRESHOLD.WARNING) {
        return;
      }

      if (vm.userGroup.userCount > USER_GROUP_USER_COUNT_THRESHOLD.ERROR) {
        namespace = 'widgets.userGroups.userGroupAddProfilesModal.pageBanner.largeGroupError';
        vm.isOpen = false; // hide app-product-profile-select
      } else {
        namespace = 'widgets.userGroups.userGroupAddProfilesModal.pageBanner.largeGroupWarning';
        variant = 'warning';
      }

      const header = $translate.instant(`${namespace}.header`);
      const message = $translate.instant(
        `${namespace}.messageformat.message`,
        {
          count: numberFilter(
            variant === 'warning'
              ? USER_GROUP_USER_COUNT_THRESHOLD.WARNING
              : USER_GROUP_USER_COUNT_THRESHOLD.ERROR
          ),
          goUrlName: USER_GROUP_THRESHOLD_GO_URL.USER_GROUP,
          supportHref: '/support',
        },
        'messageformat'
      );
      showPageBanner({header, message, variant});
    }

    function showPageBanner({header, message, showLicenseBtn, variant} = {}) {
      vm.displayMessage = new DisplayMessage({
        body: message,
        header,
        variant,
      });

      if (showLicenseBtn) {
        vm.displayMessage.ctaText = $translate.instant(
          'widgets.users.addUserModal.purchaseLicenses'
        );
        vm.displayMessage.onCtaClick = () => {
          $state.go('account.account');
        };
      }
    }

    function triggerAnalytics(profilesAddedCount, profilesRemovedCount) {
      AnalyticsEvent.dispatch({
        attributes: {
          profilesAddedCount,
          profilesRemovedCount,
        },
        componentMethod: 'addProfilesToUserGroup',
        componentMethodType: 'submit',
        componentName,
      });
    }
  }
})();
