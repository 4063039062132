(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.main.global-modals')
    .constant('DEFAULT_GLOBAL_MODAL_ID', 'default-global-modal-id')
    .constant('GLOBAL_MODALS', {
      OPEN: 'global-modals-open',
    })
    .constant('GLOBAL_MODAL_BINDING_TYPE', {
      DATA: 'Data',
      FUNCTION: 'Function',
      STRING: 'String',
    });
})();
