(function () {
  /**
   * @deprecated - with temp_react_port_users
   */
  angular.module('app.widgets.user-groups').component('appUserGroupDrawer', {
    bindings: {
      userGroup: '<',
    },
    controller,
    templateUrl: 'app/widgets/user-groups/user-group-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $state,
    _,
    feature,
    panelManager,
    ProductConfiguration,
    USER_GROUP_DETAILS_DRAWER_ID,
    UserGroupAdminList,
    UserGroupConfigurationsList,
    UserGroupUserList
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      closeDrawer,
      feature,
      onView: viewUserGroupDetailPage,
    });

    function $onInit() {
      vm.drawerId = USER_GROUP_DETAILS_DRAWER_ID;
    }

    function $onChanges(changesObj) {
      vm.userGroup = _.get(changesObj, 'userGroup.currentValue');

      if (vm.userGroup) {
        vm.userGroupConfigurationList = UserGroupConfigurationsList.get({
          groupId: vm.userGroup.id,
          // The drawer renders all of the profiles without pagination so
          // override default pageSize to get all items in a single page.
          pageSize: -1,
        });
        vm.adminList = UserGroupAdminList.get(vm.userGroup.id);
        vm.userList = UserGroupUserList.get(vm.userGroup.id);
        // eslint-disable-next-line promise/catch-or-return
        vm.userList.$promise.then(() => {
          vm.userCount = _.get(vm, 'userList.pagination.itemCount', 0);
        });
        // once we have the list of configurations, we sort them into products
        // eslint-disable-next-line promise/catch-or-return
        vm.userGroupConfigurationList.$promise.then((response) => {
          setProducts(response.items);
        });
      }
    }

    function closeDrawer() {
      panelManager.close(USER_GROUP_DETAILS_DRAWER_ID);
    }

    function viewUserGroupDetailPage() {
      vm.closeDrawer();
      $state.go('users.user-group-details', {
        userGroupId: vm.userGroup.id,
      });
    }

    // Group the product configurations by product id and then sort the groupings by
    // product name.
    function setProducts(productConfigurations) {
      const products = [];
      _.forEach(productConfigurations, (productConfiguration) => {
        let foundProduct = _.find(products, {id: productConfiguration.product.id});
        if (!foundProduct) {
          foundProduct = productConfiguration.product;
          foundProduct.licenseGroups = [];
          products.push(foundProduct);
        }
        foundProduct.licenseGroups.push(
          new ProductConfiguration({
            id: productConfiguration.id,
            name: productConfiguration.name,
          })
        );
      });
      vm.products = products;
    }
  }
})();
