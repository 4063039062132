import {useReducer} from 'react';

const ACTIONS = {
  NEXT: 'next',
  PREVIOUS: 'previous',
  REPLACE: 'replace',
  RESET: 'reset',
};

const stepsInit = ({steps = [], currentStep} = {}) => {
  const currentStepIndex = currentStep ? steps.findIndex((step) => step === currentStep) : -1;
  return {currentStep: currentStep || steps[currentStepIndex], currentStepIndex, steps};
};

const stepsReducer = (store, action) => {
  const {steps, currentStep} = store;
  const currentStepIndex = steps.findIndex((step) => step === currentStep);
  switch (action.type) {
    case ACTIONS.NEXT: {
      const nextStepIndex = Math.min(currentStepIndex + 1, steps.length);
      const nextStep = steps[nextStepIndex];
      return {
        ...store,
        currentStep: nextStep || currentStep,
        currentStepIndex: nextStep ? nextStepIndex : currentStepIndex,
      };
    }
    case ACTIONS.PREVIOUS: {
      const prevStepIndex = Math.max(0, currentStepIndex - 1);
      const prevStep = steps[prevStepIndex];
      return {
        ...store,
        currentStep: prevStep || currentStep,
        currentStepIndex: prevStep ? prevStepIndex : currentStepIndex,
      };
    }
    case ACTIONS.RESET:
      return {
        ...store,
        ...stepsInit(action.payload),
      };
    case ACTIONS.REPLACE: {
      const {replaceSteps, ...payload} = action.payload;
      const newSteps = [...steps.slice(0, currentStepIndex + 1), ...replaceSteps];
      return {
        ...store,
        ...stepsInit({
          ...store,
          ...payload,
          steps: newSteps,
        }),
      };
    }
    default:
      throw new Error(`Invalid action ${action.type}`);
  }
};

/**
 * @description A reducer hook to manage the navigation between steps in the Self Cancel workflow.
 *   It provides actions like navigate next, previous, resetting and branching the steps.
 * @param {Object} [initialStore] initial store
 * @returns {Array} the current state store paired with a dispatch method, used to fire the actions.
 *   [stepsStore, stepsDispatch]
 */
const useNavigationStepsReducer = (initialStore) =>
  useReducer(
    stepsReducer,
    typeof initialStore === 'function' ? initialStore() : initialStore,
    stepsInit
  );

export default useNavigationStepsReducer;

export {ACTIONS};
