import {Divider, Flex, Heading, View} from '@adobe/react-spectrum';
import ChatIcon from '@spectrum-icons/workflow/Chat';
import FeedbackIcon from '@spectrum-icons/workflow/Feedback';
import PeopleGroupIcon from '@spectrum-icons/workflow/PeopleGroup';
import React from 'react';
import {useIntl} from 'react-intl';

import chatProvider from 'core/services/chat/chatProvider';
import QuickLink from 'features/overview/components/quick-links-section/QuickLink';

const HelpRailFooter = () => {
  const intl = useIntl();

  const openChatPod = () => {
    chatProvider.openMessagingWindow({
      sourceText: intl.formatMessage({id: 'shell.helpRail.footer.startChatButton'}),
      sourceType: 'button',
    });
  };
  const navigateToCommunityForum = () => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will be defined
    window.open('https://www.adobe.com/go/aac_community_forum', '_blank');
  };

  return (
    <View>
      <Heading data-testid="header">
        {intl.formatMessage({id: 'shell.helpRail.footer.header'})}
      </Heading>
      <Divider marginBottom="size-100" size="M" />
      <Flex direction="row" gap="size-130">
        <View>
          <QuickLink
            data-testid="community-forum-button"
            Icon={<PeopleGroupIcon size="M" />}
            label={intl.formatMessage({id: 'shell.helpRail.footer.communityForumButton'})}
            onPress={navigateToCommunityForum}
          />
        </View>
        {chatProvider.canDisplayChat() && (
          <View>
            <QuickLink
              data-testid="open-chat-button"
              Icon={<ChatIcon size="M" />}
              label={intl.formatMessage({id: 'shell.helpRail.footer.startChatButton'})}
              onPress={() => openChatPod()}
            />
          </View>
        )}
        <View>
          <QuickLink
            // IMPORTANT: The data-testid is linked to a Gainsight selector which will open a Gainsight modal when clicked.
            // Note that an adblocker can block this modal from opening. If the data-testid is changed, please also change it in Gainsight.
            data-testid="share-feedback-button"
            Icon={<FeedbackIcon size="M" />}
            label={intl.formatMessage({id: 'shell.helpRail.footer.shareFeedbackButton'})}
          />
        </View>
      </Flex>
    </View>
  );
};

HelpRailFooter.propTypes = {};

export default HelpRailFooter;
