import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const DirectoryName = ({directory, goToDirectoryDetails}) =>
  directory.isTrusted || directory.isType2E ? (
    directory.directoryName
  ) : (
    <Link isQuiet onPress={() => goToDirectoryDetails(directory.id)}>
      {directory.directoryName}
    </Link>
  );

DirectoryName.propTypes = {
  directory: PropTypes.shape({
    directoryName: PropTypes.string,
    id: PropTypes.string,
    isTrusted: PropTypes.bool,
    isType2E: PropTypes.bool,
  }).isRequired,
  goToDirectoryDetails: PropTypes.func.isRequired,
};

export default DirectoryName;
