(function () {
  /**
   * @ngdoc module
   * @name app.widgets.remove-on-read-only
   * @description module that defines remove-on-read-only widgets
   */
  angular.module('app.widgets.remove-on-read-only', [
    'app.core.contract.migrations',
    'app.core.remove-on-read-only',
  ]);
})();
