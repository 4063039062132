import React from 'react';

import {AccessRequestListContextProvider} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import AccessRequestsListTable from 'features/settings/components/access-requests/AccessRequestsListTable';
import useAccessRequestListState from 'features/settings/components/access-requests/hooks/useAccessRequestListState';

const AccessRequestsList = () => {
  const listState = useAccessRequestListState();

  return (
    <AccessRequestListContextProvider listState={listState}>
      <AccessRequestsListTable />
    </AccessRequestListContextProvider>
  );
};

export default AccessRequestsList;
