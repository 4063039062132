import {Flex, Text, useDialogContainer} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';

const DisableDomainEnforcementConfirmationModal = ({onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {domainEnforcement} = useDomainEnforcementContext();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.domainEnforcement.disableDomainEnforcementConfirmationModal.cancel',
      })}
      data-testid="disable-domain-enforcement-confirmation-modal"
      isLoading={domainEnforcement.isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.domainEnforcement.disableDomainEnforcementConfirmationModal.confirm',
      })}
      title={intl.formatMessage({
        id: 'settings.domainEnforcement.disableDomainEnforcementConfirmationModal.title',
      })}
    >
      <Flex alignItems="space-between" direction="column">
        <Text>
          <FormattedMessage
            id="settings.domainEnforcement.disableDomainEnforcementConfirmationModal.description"
            values={{
              goUrl: (linkText) => <GoUrl name="aac_restricting_domains">{linkText}</GoUrl>,
            }}
          />
        </Text>
        <Text marginTop="size-300">
          <FormattedMessage id="settings.domainEnforcement.disableDomainEnforcementConfirmationModal.areYouSure" />
        </Text>
      </Flex>
    </ModalAlertDialog>
  );
};

DisableDomainEnforcementConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
};

export default DisableDomainEnforcementConfirmationModal;
