import {Locale} from '@admin-tribe/binky';
import axios from 'axios';

let sessionStartTimestamp, url;

/**
 * Configures the services using tron data apis with the required parameters.
 *
 * @param {Object} config Config for services using tron data apis
 * @param {String} organizationId Active Organization Id
 *
 */
const configure = (config) => {
  url = config.url;
  sessionStartTimestamp = Date.now();
};

// ///////// API Utility methods ////////////

/**
 *
 * Retrieve cacheable api header
 *
 * @return {Object} cacheable request id header
 */
function getCacheableApiHeaderObject() {
  return {
    tron_request_id: `${sessionStartTimestamp}_${Date.now()}`,
  };
}

// ///////// Cacheable resources ////////////

/**
 * Retrieves ui constants
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUiConstantsResource() {
  return axios.get(`${url}/api/cacheable/ui_constants`, {
    headers: getCacheableApiHeaderObject(),
  });
}

/**
 * Retrieves adobe products
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAdobeProducts() {
  return axios.get(`${url}/api/cacheable/adobe_products`, {
    headers: getCacheableApiHeaderObject(),
  });
}

/**
 * Retrieves Adobe update resources
 *
 *
 * @return {Promise<AxiosResponse>} - Axios response
 */
function getAdobeUpdatesResource() {
  return axios.get(
    `${url}/api/cacheable/adobe_product_updates_locale/${Locale.get().activeLanguage}`,
    {
      headers: getCacheableApiHeaderObject(),
      isArray: true,
    }
  );
}

/**
 * Retrieves all Adobe Products for create package from backend
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAdobeProductsToShowResource() {
  return axios.get(`${url}/api/cacheable/adobe_product_to_show`, {
    headers: getCacheableApiHeaderObject(),
  });
}

export {
  configure,
  getUiConstantsResource,
  getAdobeUpdatesResource,
  getAdobeProducts,
  getAdobeProductsToShowResource,
};
