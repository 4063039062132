import rootStore from 'core/RootStore';
import {CREATE_PACKAGE, CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

/**
 * @description Checks if license file selection is allowed
 * @param {String} packageType - selected package type
 *
 * @return {Boolean} Return true if license file selection is allowed
 */
const allowLicenseFileSelectionModification = (packageType) =>
  packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
  packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE ||
  packageType === CREATE_PACKAGE.TYPE.FRL_LAN;

/**
 * @description Checks if license file should be shown in products list
 * @param {Array} frlIsolatedMachineCodes - Frl Isolated machine codes
 * @param {String} packageType - selected package type
 *
 * @return {Boolean} Return true if license file should be shown in products list
 */
function showLicenseFileEntry(frlIsolatedMachineCodes, packageType) {
  return (
    packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
    packageType === CREATE_PACKAGE.TYPE.SDL ||
    packageType === CREATE_PACKAGE.TYPE.FRL_LAN ||
    (packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED && frlIsolatedMachineCodes?.length > 0) ||
    packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE
  );
}

/**
 * @description Checks if next button should be enabled
 * @param {Boolean} licenseFileSelected - Has license file been selected
 * @param {String} packageType - selected package type
 * @param {Array} frlIsolatedMachineCodes - Frl Isolated machine codes
 * @param {Boolean} someProductSelected - If some product has been selected
 *
 *
 * @return {Boolean} Return true if next button should be enabled
 */

function getNextButtonState({
  frlIsolatedMachineCodes,
  licenseFileSelected,
  packageType,
  someProductSelected,
}) {
  if (
    packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED &&
    (!frlIsolatedMachineCodes || frlIsolatedMachineCodes.length === 0)
  ) {
    return someProductSelected;
  }
  if (
    packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
    packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE ||
    packageType === CREATE_PACKAGE.TYPE.FRL_LAN
  ) {
    return someProductSelected || licenseFileSelected;
  }
  return true;
}

/**
 * @description Retrieves management settings
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 * @param {Object} packageCreateObj - Shared object in package creation flow
 * @param {Object} pkgSessionSettings - Shared object in package creation session
 * @param {Number} selectedProductsLength - selected products length
 *
 * @return {Object} Returns management settings
 */
function getManagementSettings({adobeProductsUi, packageCreateObj, pkgSessionSettings}) {
  let someProductSelected = false;
  let isRumEnabled = packageCreateObj.rumEnabled;
  let isAalExtensionEnabled = pkgSessionSettings.enableAalExtension;
  let areExtensionsEnabled = packageCreateObj.enableExtenstions;

  if (pkgSessionSettings.packagingMode === CREATE_PACKAGE.MODE.FRL) {
    someProductSelected = adobeProductsUi.some((product) => product.visible && product.selected);
    isRumEnabled = false;
    areExtensionsEnabled = false;
    // if its a license only package, all config options should be false by default
    // otherwise based on UI constants
    if (
      (pkgSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
        pkgSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN) &&
      someProductSelected
    ) {
      areExtensionsEnabled = rootStore.packagesUiConstantsStore.defaultExtensionsSetting;
      isRumEnabled = rootStore.packagesUiConstantsStore.defaultPackageRumSetting;
      if (!areExtensionsEnabled) {
        isAalExtensionEnabled = false;
      }
    } else if (
      pkgSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED &&
      someProductSelected
    ) {
      areExtensionsEnabled = rootStore.packagesUiConstantsStore.defaultExtensionsSetting;
      if (!areExtensionsEnabled) {
        isAalExtensionEnabled = false;
      }
    } else {
      isAalExtensionEnabled = false;
    }
  }
  const nextButtonEnabled = getNextButtonState({
    frlIsolatedMachineCodes: pkgSessionSettings.frlIsolatedMachineCodes,
    licenseFileSelected: pkgSessionSettings.licenseFileSelected,
    packageType: pkgSessionSettings.packageType,
    someProductSelected,
  });
  return {
    areExtensionsEnabled,
    isAalExtensionEnabled,
    isRumEnabled,
    nextButtonEnabled,
  };
}

/**
 * @description Retrieves license file details
 * @param {Boolean} addRemoveOptionVisible - should add/remove option be visible
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Boolean} licenseFileSelected - has license file been selected
 * @param {CREATE_PACKAGE_CONSTANTS} packageType - package type selected
 *
 * @return {Object} Returns license file details
 */
const getLicenseFileDetails = ({
  addRemoveOptionVisible,
  intl,
  licenseFileSelected,
  packageType,
}) => {
  const hoverText = intl.formatMessage({
    id:
      packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE ||
      packageType === CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE ||
      packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE
        ? 'packages.createPackage.productSelection.licenseFile.infoFRL'
        : 'packages.createPackage.productSelection.licenseFile.infoSDL',
  });
  return {
    name: intl.formatMessage({
      id: 'packages.createPackage.chooseApps.licenseFile.name',
    }),
    products: [
      {
        addRemoveOptionVisible: !!addRemoveOptionVisible,
        hasSpecialSystemRequirements: () => false,
        hoverText,
        isLicenseFileSelected: !!licenseFileSelected,
        name: intl.formatMessage({id: 'packages.createPackage.chooseApps.licenseFile.name'}),
        product_key: CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE,
        sapCode: CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE,
      },
    ],
    sapCode: CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE,
  };
};
/**
 * @description Shifts focus to selected applications search button
 */
const focusSelectedAppsSearchButton = () => {
  document.querySelector('[id="selected-apps-search-open-button"]')?.focus();
};

/**
 * @description Checks if product satisfies the selected apps filters
 * @param {String} selectedProductsSearchText - Search text typed in selected products search field
 * @param {Object} product - product on which filter should be applied
 *
 * @return {Boolean} Return true if product satisfies the selected apps filters
 */
function selectedProductsFilter(selectedProductsSearchText, product) {
  return (
    product.visible &&
    product.selected &&
    (!selectedProductsSearchText ||
      !!(
        selectedProductsSearchText &&
        product.name?.toUpperCase().includes(selectedProductsSearchText.toUpperCase())
      ))
  );
}

export {
  allowLicenseFileSelectionModification,
  focusSelectedAppsSearchButton,
  getLicenseFileDetails,
  getManagementSettings,
  selectedProductsFilter,
  showLicenseFileEntry,
};
