/* eslint-disable promise/prefer-await-to-then,promise/no-nesting -- Need to store promises */
import {
  AuthenticatedUser,
  FeaturesCache,
  authentication,
  eventBus,
  feature,
  jilDirectories,
  toPandoraProductList,
} from '@admin-tribe/binky';
import {insightsAccessUtils} from '@pandora/data-model-insights';
import {JilModelList} from '@pandora/data-model-list';
import {
  getAccessList,
  getInsightsItem,
  getReportBrokerFetch,
} from '@pandora/react-data-source-report-broker';
import {toJS} from 'mobx';

import API_CONSTANTS from 'common/api/ApiConstants';
import rootStore from 'core/RootStore';
import orgChangeConstants from 'shell/components/organization-switcher-container/orgChangeConstants';

/**
 * Fetches and stores data related to Insights functionality.
 * This is necessary for Insights because the data fetching happens within Pandora which does
 * not have a caching solution. Don't use this as an example for data models that live within
 * binky/ModelLists as they already have a caching solution.
 */
class InsightsStore {
  #accessOptions;
  #promiseCache = {};
  #reportBrokerFetch;

  constructor() {
    eventBus.registerEventHandler((eventId) => {
      if (eventId === orgChangeConstants.SUCCESS) {
        this.clearCachedData();
      }
    });
  }

  /**
   * @description Gets the available Insights Items, based on permissions
   * @param {string} insightsSection
   * @returns {Promise<(import('@pandora/data-model-insights').InsightsItem)[]>}
   */
  #getAvailableInsightsItems(insightsSection) {
    return this.#getFromCache(`availableItems[${insightsSection}]`, () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.getAvailableInsightsItems(results[0], insightsSection, results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.getAvailableInsightsItems(
          insightsItems,
          insightsSection,
          this.getAccessOptions()
        )
      );
    });
  }

  /**
   * @description Attempts to find a key from the cache and returns it, otherwise the value
   * from the factory will be added to the cache
   * @template T
   * @param {string} key
   * @param {() => T} factory
   * @returns {T}
   */
  #getFromCache(key, factory) {
    if (!this.#promiseCache[key]) {
      this.#promiseCache[key] = factory();
    }

    return this.#promiseCache[key];
  }

  /**
   * @description Checks if the user can access the dashboard
   * @returns {Promise<boolean>}
   */
  canViewDashboard() {
    return this.#getFromCache('canViewDashboard', () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.canViewDashboard(results[0], results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.canViewDashboard(insightsItems, this.getAccessOptions())
      );
    });
  }

  /**
   * @description Checks if the user can access any part of Insights
   * @returns {Promise<boolean>}
   */
  canViewInsights() {
    return this.#getFromCache('canViewInsights', () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.canViewInsights(results[0], results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.canViewInsights(insightsItems, this.getAccessOptions())
      );
    });
  }

  /**
   * @description Checks if the user can access logs
   * @returns {Promise<boolean>}
   */
  canViewLogs() {
    return this.#getFromCache('canViewLogs', () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.canViewLogs(results[0], results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.canViewLogs(insightsItems, this.getAccessOptions())
      );
    });
  }

  /**
   * @description Checks if the user can access reports
   * @returns {Promise<boolean>}
   */
  canViewReports() {
    return this.#getFromCache('canViewReports', () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.canViewReports(results[0], results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.canViewReports(insightsItems, this.getAccessOptions())
      );
    });
  }

  /**
   * @description Clears all data currently in the cache
   */
  clearCachedData() {
    this.#promiseCache = {};
  }

  /**
   * @description Gets the access options required to check permissions on various Insights functionality
   * @returns {import('@pandora/data-model-insights').CheckAccessOptions}
   */
  //  remove with temp_domain_enforcement_report
  getAccessOptions() {
    if (!this.#accessOptions) {
      this.#accessOptions = {
        contractList: rootStore.organizationStore.contractList,
        featureFlagMap: toJS(FeaturesCache.get().map),
        orgId: rootStore.organizationStore.activeOrgId,
        productList: toPandoraProductList(rootStore.organizationStore.productList),
        roles: AuthenticatedUser.get().getRoles().roles,
      };
    }

    return this.#accessOptions;
  }

  /**
   * @description Gets the access options required to check permissions on various Insights functionality
   * @returns {Promise<import('@pandora/data-model-insights').CheckAccessOptions>}
   */
  // rename to getAccessOptions when removing temp_domain_enforcement_report
  getAccessOptions2() {
    if (!this.#accessOptions) {
      return this.getDirectoryList().then((directoryList) => {
        this.#accessOptions = {
          contractList: rootStore.organizationStore.contractList,
          directoryList,
          featureFlagMap: toJS(FeaturesCache.get().map),
          orgId: rootStore.organizationStore.activeOrgId,
          productList: toPandoraProductList(rootStore.organizationStore.productList),
          roles: AuthenticatedUser.get().getRoles().roles,
        };
        return this.#accessOptions;
      });
    }
    return Promise.resolve(this.#accessOptions);
  }

  /**
   * @description Gets the section that should be defaulted to
   * @returns {Promise<INSIGHTS_SECTION>}
   */
  getDefaultSection() {
    return this.#getFromCache('defaultSection', () => {
      if (feature.isEnabled('temp_domain_enforcement_report')) {
        const insightsItemsPromise = this.getInsightsItems();
        const accessOptionsPromise = this.getAccessOptions2();

        return Promise.all([insightsItemsPromise, accessOptionsPromise]).then((results) =>
          insightsAccessUtils.getDefaultSection(results[0], results[1])
        );
      }

      return this.getInsightsItems().then((insightsItems) =>
        insightsAccessUtils.getDefaultSection(insightsItems, this.getAccessOptions())
      );
    });
  }

  /**
   * @description Gets the directory list for access check
   * @returns {Promise<JilModelList<Directory>>}
   */
  getDirectoryList() {
    return this.#getFromCache('directoryList', () =>
      jilDirectories
        .getDirectories({
          orgId: rootStore.organizationStore.activeOrgId,
        })
        .then((response) => new JilModelList({items: response.data}))
    );
  }

  /**
   * @description Fetches the Insights Items within a given section
   * @param {string} insightsSection
   * @returns {Promise<JilModelList<import('@pandora/data-model-insights').InsightsItem>>}
   */
  getInsightsItemList(insightsSection) {
    return this.#getFromCache(`itemsInSection[${insightsSection}]`, () =>
      this.#getAvailableInsightsItems(insightsSection).then((insightsItems) =>
        getInsightsItem(this.reportBrokerFetch, {
          apiKey: API_CONSTANTS.CLIENT_ID,
          orgId: rootStore.organizationStore.activeOrgId,
          types: insightsItems.map((item) => item.id),
        })
          .then((response) => response.json())
          .then(
            (items) =>
              new JilModelList({
                items,
              })
          )
      )
    );
  }

  /**
   * @description Gets all of the Insights Items
   * @returns {Promise<(import('@pandora/data-model-insights').InsightsItem)[]>}
   */
  getInsightsItems() {
    return this.#getFromCache('insightsItems', () =>
      getAccessList(this.reportBrokerFetch, {
        apiKey: API_CONSTANTS.CLIENT_ID,
        orgId: rootStore.organizationStore.activeOrgId,
      }).then((response) => response.json())
    );
  }

  /**
   * Gets a fetch method used to contact Report Broker
   * @returns {Function}
   */
  get reportBrokerFetch() {
    if (!this.#reportBrokerFetch) {
      this.#reportBrokerFetch = getReportBrokerFetch({
        accessToken: authentication.getAccessToken?.()?.token,
        env: authentication.getAppEnv?.(),
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
        fetch: window.fetch.bind(window),
      });
    }

    return this.#reportBrokerFetch;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export -- existing usage
export default new InsightsStore();
/* eslint-enable promise/prefer-await-to-then,promise/no-nesting -- Need to store promises */
