import {GoUrl} from '@admin-tribe/binky-ui';
import {View} from '@adobe/react-spectrum';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SupportHelpTopic.pcss';

/**
 * A widget to display the Support Help Topic.
 */
const SupportHelpTopic = ({'data-testid': dataTestId, label, name}) => (
  <View>
    <GoUrl data-testid={dataTestId || 'help-topic'} isQuiet name={name}>
      <span>{label}</span>
      <LinkOutLight
        isQuiet
        size="S"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need margin-left & vertical-align
        UNSAFE_className={styles['binky-link-out-light']}
      />
    </GoUrl>
  </View>
);

SupportHelpTopic.propTypes = {
  /**
   * data-testid is used as identifier of the component.
   * The default is 'help-topic'.
   */
  'data-testid': PropTypes.string,
  /**
   * label is used to define the text of component.
   */
  label: PropTypes.string.isRequired,
  /**
   * name is used to define the url of component.
   */
  name: PropTypes.string.isRequired,
};

export default SupportHelpTopic;
