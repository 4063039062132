/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated the Pandora purchase mini-app is now being used for all cases.
   *   This component and all its dependencies can be removed.
   *
   * @ngdoc component
   * @name app.widgets.products.purchase.add-products-modal:appAddProductsModal2
   *
   * @description the modal to add more products for direct and indirect contracts
   */
  const componentName = 'appAddProductsModal2';
  angular.module('app.widgets.products.purchase.add-products-modal').component(componentName, {
    bindings: {
      modalId: '@?',
      onCartItemsChange: '&?',
      onClosed: '&?',
      onInitialized: '&?',
      onModalStateChange: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/add-products-modal2/add-products-modal2.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    _,
    $document,
    $log,
    $q,
    $rootScope,
    $timeout,
    $translate,
    ADD_PRODUCTS_MODAL_STEPS,
    ADD_PRODUCTS_MODAL2_ID,
    ADD_PRODUCTS_MODAL2_STATES,
    AnalyticsEvent,
    AnalyticsHelper,
    AuthenticatedUser,
    binkySrc2,
    Cart,
    CART_ERROR_STATUS,
    CHAT_APP_ID,
    ContactSales,
    COMMON_EVENTS,
    CUSTOMER_SEGMENT,
    DisplayMessage,
    DISPLAY_MESSAGE_VARIANT,
    feature,
    numberFilter,
    OFFER_LIST_INTENT,
    onesieSrc2,
    organizationAccess,
    OrganizationManager,
    organizationTypeHelper,
    panelManager,
    productAccess,
    ProductList,
    ProductPurchaseHelper,
    quickAssignGlobalModal,
    toastManager,
    UiEventDescriptor
  ) {
    const vm = this;
    let analyticsSourcePage,
      contractList,
      deepLinkingState,
      isAddLicenses,
      landingState,
      originalOverflowStyle,
      ownedProductItems, // all initialized onOpen
      salesPhoneNumber;

    _.assign(vm, {
      $onInit,
      isEnabled: feature.isEnabled,
      onAddCatalogItem,
      onBack,
      onCartItemLicensesChange,
      onClose,
      onCustomerSegmentChange,
      onNext,
      onOpen,
      onOrganizationTypeFormChange,
      onRemoveFromCart,
      onStartChatButtonClick,
      showCartTotalFooter,
    });

    const {REVIEW_ORDER, SELECT_ORG_TYPES, SELECT_PRODUCTS, SHOW_PAGE_BANNER} =
      ADD_PRODUCTS_MODAL2_STATES;
    const debouncedFetchBillableItems = _.debounce(fetchBillableItems, 500);
    let organizationTypeProps = {};

    /////////////

    function $onInit() {
      _.assign(vm, {
        ADD_PRODUCTS_MODAL2_ID,
        ADD_PRODUCTS_MODAL2_STATES,
        CHAT_APP_ID,
        id: vm.modalId || ADD_PRODUCTS_MODAL2_ID,
        isJemTimedOut: false,
        promiseToWait: $q.resolve(),
        StartChatButton: onesieSrc2.support.components.StartChatButton,
      });
      salesPhoneNumber = ContactSales.getTeamPhoneNumber(AuthenticatedUser.get().getCountryCode());
      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    // Called to add a catalog item to the cart.
    function onAddCatalogItem(offerId) {
      const foundOwnedOffer = _.remove(vm.ownedOffers, {offer_id: offerId});
      const foundRemainingOffer = _.remove(vm.unownedWithoutRecOffers, {
        offer_id: offerId,
      });
      const foundRecommendedOffer = _.remove(vm.unownedWithRecOffers, {offer_id: offerId});
      const result = _.concat(foundRemainingOffer, foundOwnedOffer, foundRecommendedOffer);

      if (result.length > 1) {
        $log.error(`AddProductsModal2.onAddCatalogItem(): duplicate offers for offerid=${offerId}`);
        return;
      }
      // should only be one result with corresponding id
      const offerToAdd = _.head(result);

      // prerequisites for cart items to display properly
      _.assign(offerToAdd, {
        numberSelected: 1,
        numberToPurchase: 1,
      });

      vm.cartItems.push(offerToAdd);
      updateTotals();

      AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({
        eventName: 'addCart',
        offer: offerToAdd,
      });
    }

    function onBack() {
      vm.isJemTimedOut = false;

      // Capture analytics before modal state changes
      dispatchAnalyticsClickEventWithCart({
        eventName: vm.modalState.settings.analytics.backButtonEventName || 'previous',
      });

      setModalState(getPreviousState());
    }

    function onCartItemLicensesChange(offer) {
      updateTotals();

      AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({eventName: 'changeLicense', offer});
    }

    function onClose({dispatchAnalytics = true} = {}) {
      if (dispatchAnalytics) {
        dispatchAnalyticsClickEventWithCart({eventName: 'close'});
      }

      // This is a workaround to fix the positioning of the React Spectrum Popover
      // If the <html> tag has an "overflow: hidden" style (which can intermittently
      // be added programmatically) then the Popover is not correctly positioned.
      // The `auto` value does not seem to be sufficient to correct the behaviour.
      // https://jira.corp.adobe.com/browse/ONESIE-28131
      if (originalOverflowStyle !== 'visible') {
        $document[0].documentElement.style.overflow = originalOverflowStyle;
      }

      panelManager.close(vm.id);

      if (analyticsSourcePage) {
        const camelCaseModalId = _.camelCase(vm.ADD_PRODUCTS_MODAL2_ID);
        AnalyticsHelper.dispatchUiEventAnalytics({
          eventAction: 'close',
          eventName: camelCaseModalId,
          name: `close${_.upperFirst(camelCaseModalId)}`,
        });
      }

      _.invoke(vm, 'onClosed');
    }

    function onCustomerSegmentChange(selection) {
      if (vm.selectedCustomerSegment !== selection) {
        vm.selectedCustomerSegment = selection;

        // When changing segments, empty the shopping cart and refresh the product buckets.
        // Note: the Add product flow in which cartItems is initially set does use this method.
        if (!_.isEmpty(vm.cartItems)) {
          vm.cartItems = [];
          updateTotals();
        }

        vm.promiseToWait = setupProductBuckets();
      }
    }

    function onNext() {
      vm.displayMessage = undefined;
      if (vm.modalState.state === SELECT_ORG_TYPES) {
        dispatchAnalytics(); // Capture analytics before modal state changes
        onSelectOrgTypes();
      } else if (vm.modalState.state === REVIEW_ORDER) {
        // Don't dispatch analytics because submit has its own analytics
        submit();
      } else {
        dispatchAnalytics(); // Capture analytics before modal state changes
        setModalState(getNextState());
      }

      //////////////

      function dispatchAnalytics() {
        dispatchAnalyticsClickEventWithCart({
          eventName: vm.modalState.settings.analytics.ctaButtonEventName || 'continue',
        });
      }

      function onSelectOrgTypes() {
        vm.isSubmitting = true;
        vm.promiseToWait = organizationTypeHelper
          .updateOrgMarketSubsegments(organizationTypeProps.marketSubsegments, {
            hasAdditionalTermsToAccept: organizationTypeProps.hasAdditionalTerms,
          })
          .then(() => {
            setModalState(getNextState());
          })
          .catch(showPageBanner)
          .finally(() => {
            vm.isSubmitting = false;
          });
      }
    }

    /**
     * @description setup the add products modal dynamically based on the contract in the active org and the passed in params.
     *
     * @param {Object} params - the passed in params when opening the modal
     * @param {String} [params.analyticsModalSourcePage] - used by analytics, to inform the name of the page that the user
     *    lands on when the modal is closed.
     * @param {Array} [params.cartItems] - an array of cart items to be populated to the shopping cart.
     *    The cart item is an object containing offerId, productArrangementCode, and quantity (it must contain either
     *    offerId or productArrangementCode). We'll look up the offer by offerId first, and then fallback to using the productArrangementCode.
     *    If undefined, the shopping cart will be empty. All items should be for the same CUSTOMER_SEGMENT.
     *
     *    Example: [{
     *                 offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                 productArrangementCode: 'ilst_direct_indirect_team',
     *                 quantity: 2
     *              }, {
     *                 offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                 productArrangementCode: 'phsp_direct_indirect_team',
     *                 quantity: 5
     *              }]
     * @param {ADD_PRODUCTS_MODAL2_STATES} [params.deepLinkingState] - if present, it will be used when determining landing state
     * @param {Boolean} [options.isAddLicenses] when true, it will enforce landing in the REVIEW_ORDER state and will only allow
     *    to add licenses for a currently owned product (Add Licenses instead of Add Products flow)
     */
    function onOpen(params = {}) {
      const {analyticsModalSourcePage, cartItems = []} = params;
      _.assign(vm, {
        cartItems,
        displayMessage: undefined,
        fetchingCartPreview: false,
        isSubmitting: false,
        poNumber: '',
        selectedCustomerSegment: undefined,
        totalSummary: undefined,
      });

      analyticsSourcePage = analyticsModalSourcePage;

      deepLinkingState = params.deepLinkingState;
      isAddLicenses = params.isAddLicenses;

      contractList = OrganizationManager.getContractsForActiveOrg();
      vm.contract = contractList.getDirectOrIndirectContract();

      // This is a workaround to fix the positioning of the React Spectrum Popover
      // If the <html> tag has an "overflow: hidden" style (which can intermittently
      // be added programmatically) then the Popover is not correctly positioned.
      // The `auto` value does not seem to be sufficient to correct the behaviour.
      // https://jira.corp.adobe.com/browse/ONESIE-28131
      originalOverflowStyle = $document[0].documentElement.style.overflow;
      if (originalOverflowStyle !== 'visible') {
        $document[0].documentElement.style.overflow = 'visible';
      }

      vm.contactForMoreInfoMessage = getContactForMoreInfoMessage();
      vm.showFuturePricing = getShouldShowFuturePricing();
      vm.showPriceIncludesTax = !vm.contract.isDrContract;

      // Wait for all setup to complete before instantiating components so that the cart, offers and the
      // customerSegment are in place.
      vm.showContent = false;

      vm.promiseToWait = setupProductList()
        .then(organizationAccess.canSetUpOrganizationTypes)
        .then(determineLandingState)
        .then((state) => {
          landingState = state;
          setupAndTranslateStateSettings(landingState);
        })
        .then(setupOffersAndCart)
        .then(() => {
          // Now that setup is complete, set the state, run any init functions that may display page banners and
          // allow the components to initialize.
          setModalState(landingState);
        })
        .catch((error) => {
          $log.error(
            `AddProductsModal2.onInit(org: ${OrganizationManager.getActiveOrgId()}, contract: ${
              vm.contract.id
            }): ${error}`
          );
          if (!vm.translatedStateSettings) {
            // If promise chain has rejected while setting up products, ensure the
            // user can proceed to the error banner.
            landingState = SHOW_PAGE_BANNER;
            setupAndTranslateStateSettings();
          }
          // Show only an error banner and the close button.
          setModalState(SHOW_PAGE_BANNER);
        })
        .finally(() => {
          vm.showContent = true;
        });
    }

    function onOrganizationTypeFormChange(event) {
      organizationTypeProps = _.pick(event, [
        'hasAdditionalTerms',
        'areRequiredFieldsFilled',
        'marketSubsegments',
      ]);
    }

    function onRemoveFromCart(offerIdToRemove) {
      const cartItemRemoved = _.remove(vm.cartItems, {offer_id: offerIdToRemove})[0];
      const bucketToAddCartItem = _.get(vm, cartItemRemoved.productBucket);

      const sortProperty = ProductPurchaseHelper.getSortPropertyForBucket(
        cartItemRemoved.productBucket
      );
      // get index where the name of the removed offer would be inserted
      const indexToAddOffer = _.sortedIndexBy(bucketToAddCartItem, cartItemRemoved, sortProperty);
      // insert at index without replacement
      bucketToAddCartItem.splice(indexToAddOffer, 0, cartItemRemoved);
      updateTotals();

      AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({
        eventName: 'removeCartItem',
        offer: cartItemRemoved,
      });
    }

    function showCartTotalFooter() {
      return !_.isEmpty(vm.cartItems) && vm.modalState.state === SELECT_PRODUCTS;
    }

    ////////

    /**
     * @description get billingInfo and purchaseAuthId from Cart service Data
     * NOTE: This helper function is a compromise because the Cart service functions are not in React yet.
     *       Ideally it would have went in binky's analyticsUtils.js however we want to avoid calling onesie code from binky.
     *       Adding this helper function into analyticsUtils.js should be consisedered when the cart service react transition happens.
     * @param {Object} options - list of options to customise cart service functions
     * @param {Array} options.cartItems - list of items object that represent items in the cart
     * @param {Boolean} [options.cartHasSubmitted=false] - state that determines if the cart has been submitted or not
     * @param {Boolean} [options.useRenewalPricing=false] - state that determines if the cart uses renewal pricing
     * @returns {Object} - containing billingData and purchaseAuthId
     */
    function getAnalyticsInfoFromCartData({
      cartItems,
      cartHasSubmitted = false,
      useRenewalPricing = false,
    }) {
      const billingData = [];

      _.forEach(cartItems, (cartItem) => {
        const cartItemBillingData = vm.cart.getBillingDataForOffer({
          hasSubmitted: cartHasSubmitted,
          offerId: cartItem.offer_id,
          useRenewalPricing,
        });

        billingData.push(cartItemBillingData);
      });

      const purchaseAuthId = vm.cart.getPurchaseAuthId();

      return {billingData, purchaseAuthId};
    }

    function dispatchAnalyticsClickEventWithCart({cartHasSubmitted = false, eventName}) {
      if (feature.isEnabled('temp_analytics_cart_src2')) {
        const ANALYTICS_CONSTANTS = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
        const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;

        let billingData, cartId, purchaseAuthId;

        // cart is undefined if NFR the cart will not be populated therefore calls will fail.
        if (!isNFRProductBlocked() && vm.cart && vm.cartItems) {
          cartId = vm.cart.cartId;

          ({billingData, purchaseAuthId} = getAnalyticsInfoFromCartData({
            cartHasSubmitted,
            cartItems: vm.cartItems,
            useRenewalPricing: vm.showFuturePricing,
          }));
        }

        dispatchUiEventAnalytics({
          cart: {
            billingData,
            cartId,
            cartItems: vm.cartItems,
            contract: vm.contract,
            purchaseAuthId,
            total: vm.totalSummary,
          },
          eventAction: ANALYTICS_CONSTANTS.EVENT_ACTION.CLICK,
          eventName,
        });
      } else {
        AnalyticsHelper.dispatchUiEventAnalytics({
          cart: vm.cart,
          cartHasSubmitted,
          cartItems: vm.cartItems,
          contract: vm.contract,
          eventAction: 'click',
          eventName,
          total: vm.totalSummary,
          useRenewalPricing: vm.showFuturePricing,
        });
      }
    }

    function determineLandingState(canSetUpOrganizationTypes) {
      // If the NFR (Not For Resale) contract already has products, they cannot add any more
      if (isNFRProductBlocked()) {
        return SHOW_PAGE_BANNER;
      } else if (canSetUpOrganizationTypes) {
        return SELECT_ORG_TYPES;
      }
      if (deepLinkingState) {
        // Since cart items can be populated from deep linking, using deepLinkingState to
        // determine if landing state should be REVIEW_ORDER or SELECT_PRODUCTS
        return vm.cartItems.length > 0 && deepLinkingState === REVIEW_ORDER
          ? REVIEW_ORDER
          : SELECT_PRODUCTS;
      }
      return vm.cartItems.length > 0 ? REVIEW_ORDER : SELECT_PRODUCTS;
    }

    /**
     * @description Wrapper around CCS Preview call. This function is debounced
     * and is thus not intended for direct invocation.
     *
     * @param {Array} billableItems - subsection of the request body for CCS
     */
    function fetchBillableItems(billableItems) {
      // It is possible that this is already true if this is called from the debounce function.
      vm.fetchingCartPreview = true;
      vm.previewPromise = vm.cart
        .previewOrder({billableItems})
        .then(onCartPreviewSuccess, onCartPreviewError);

      // The arg to this is the cartPreviewResponse which contains the billing summary but not the
      // responseBillingSummary.
      function onCartPreviewSuccess() {
        // If previewing has stopped since this cart preview request was made (e.g. due to the user
        // making an invalid product selection), then discard this response
        if (!isProductSelectionValid()) {
          vm.totalSummary = undefined;
          return undefined;
        }

        // Analytics needs this information.
        if (vm.contract.isDirectContract()) {
          vm.totalSummary = vm.cart.getTotalSummary({showRenewalPricing: vm.showFuturePricing});
        }

        vm.fetchingCartPreview = false;

        // Return the cart itself since it contains the transformed billingSummary called responseBillingSummary
        // and return the totalSummary so all components are using the same information.
        return {cart: vm.cart, totalSummary: vm.totalSummary};
      }

      // Note vm.fetchingCartPreview stays true so that the CTA remains disabled.
      function onCartPreviewError(errorResponse = {}) {
        vm.totalSummary = undefined;

        const {maxLicenseLimit, orderCount, status} = _.get(errorResponse, 'data.errorInfo', {});
        let message, title;

        const {MAX_NUMBER_OF_SEATS} = CART_ERROR_STATUS;

        switch (status) {
          case MAX_NUMBER_OF_SEATS: {
            const licenseCountToRemove = orderCount - maxLicenseLimit;

            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.messageFormat.exceedMaximumCount.message',
              {
                licenseCountToRemove: numberFilter(licenseCountToRemove),
                salesPhoneNumber,
              },
              'messageformat'
            );
            title = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.messageFormat.exceedMaximumCount.title',
              {
                maxLicenseLimit: numberFilter(maxLicenseLimit),
              },
              'messageformat'
            );
            break;
          }
          default:
            break;
        }

        showPageBanner({message, title});
        return $q.reject();
      }
    }

    function getContactForMoreInfoMessage() {
      if (vm.contract.isIndirectContract()) {
        const moreInfoMessageKey = vm.contract.isVIPMPContract()
          ? 'contactAccountManager'
          : 'contactReseller';
        return $translate.instant(
          `widgets.products.purchase.addProductsModal.${moreInfoMessageKey}`
        );
      }

      return undefined;
    }

    function getNextState() {
      const currentIdx = _.indexOf(ADD_PRODUCTS_MODAL_STEPS, vm.modalState.state);
      const nextState = ADD_PRODUCTS_MODAL_STEPS[currentIdx + 1];
      return deepLinkingState && isAddLicenses && nextState === SELECT_PRODUCTS
        ? REVIEW_ORDER
        : nextState;
    }

    function getPreviousState() {
      const currentIdx = _.indexOf(ADD_PRODUCTS_MODAL_STEPS, vm.modalState.state);
      return ADD_PRODUCTS_MODAL_STEPS[currentIdx - 1];
    }

    function getShouldShowFuturePricing() {
      return (
        vm.contract.isDirectContract() &&
        !vm.contract.isDrContract &&
        vm.contract.isM2M() &&
        vm.contract.isInRenewalWindow()
      );
    }

    function hideErrorPageBanner() {
      const variant = _.get(vm.displayMessage, 'variant');
      if (_.isUndefined(variant) || variant === DISPLAY_MESSAGE_VARIANT.ERROR) {
        vm.displayMessage = undefined;
      }
    }

    // eslint-disable-next-line complexity
    function initCustomerSegment() {
      // Assume we're not going to show the plan selector.
      vm.showPlanSelector = false;

      // Pre-populate the cart using the first item to determine the customer segment.
      if (landingState === REVIEW_ORDER) {
        vm.selectedCustomerSegment = findCustomerSegmentForCartItems() || CUSTOMER_SEGMENT.TEAM;
      } else if (vm.contract.isDirectContract()) {
        vm.selectedCustomerSegment = CUSTOMER_SEGMENT.TEAM;
      } else {
        // It is an indirect contract.
        const ownsTeamIndirect = _.some(ownedProductItems, (product) => product.isTeamIndirect());
        const ownsEVIP = _.some(ownedProductItems, (product) => product.isEnterpriseIndirect());

        const hasEnterpriseOffers = _.some(vm.offerList.items, (offer) =>
          offer.isEnterpriseOffer()
        );

        // If both team indirect and EVIP products are owned, the admin will need to choose the plan.
        // Set the customer segment if it can be deduced from what the products owned or the offers available.
        // If EVIP products are owned, select ENTERPRISE.
        // If team indirect products are owned, select TEAM.
        // If no products are owned, requestedPlanType is 'EVIP' and then are enterprise offers, select EVIP else TEAM.
        if (ownsEVIP && ownsTeamIndirect) {
          vm.showPlanSelector = true;

          // For deep linking, try to determine customer segment by the first cart item
          if (deepLinkingState) {
            vm.selectedCustomerSegment = findCustomerSegmentForCartItems();
          }
        } else if (ownsEVIP) {
          vm.selectedCustomerSegment = CUSTOMER_SEGMENT.ENTERPRISE;
        } else if (ownsTeamIndirect) {
          vm.selectedCustomerSegment = CUSTOMER_SEGMENT.TEAM;
        } else {
          vm.selectedCustomerSegment =
            _.get(vm.contract, 'requestedPlanType') === 'EVIP' && hasEnterpriseOffers
              ? CUSTOMER_SEGMENT.ENTERPRISE
              : CUSTOMER_SEGMENT.TEAM;
        }
      }

      return vm.selectedCustomerSegment;

      /////////////

      // Try to determine customer segment from the first cart item. Otherwise, return undefined
      function findCustomerSegmentForCartItems() {
        let offer;
        const cartItem = _.head(vm.cartItems);
        if (cartItem) {
          offer =
            _.find(vm.offerList.items, ['offer_id', cartItem.offerId]) ||
            _.find(vm.offerList.items, [
              'product_arrangement_code',
              cartItem.productArrangementCode,
            ]);
        }
        return _.get(offer, 'customer_segment');
      }
    }

    // For NFR (Not For Resale) contract with owned products, or without owned products if the user made a selection we
    // need to restrict them to order only one license.
    function isNFRProductBlocked() {
      return (
        vm.contract.isNFR() &&
        (!_.isEmpty(ownedProductItems) || (!_.isEmpty(vm.cartItems) && !isProductSelectionValid()))
      );
    }

    // PO number is required for Direct with Purchase Order payment category
    function isDirectPONumberInvalid() {
      return (
        vm.contract.isDirectContract() &&
        vm.contract.isPaymentCategoryPO() &&
        _.isEmpty(vm.poNumber)
      );
    }

    function isProductSelectionValid() {
      const licenseCount = _.sumBy(vm.cartItems, 'numberSelected');
      return vm.contract.isNFR() ? licenseCount === 1 : licenseCount > 0;
    }

    function onStartChatButtonClick() {
      AnalyticsEvent.dispatch({
        descriptors: {
          uiEvent: new UiEventDescriptor({
            eventAction: 'click',
            eventName: 'chat',
            name: 'startChatButtonForTeamSales',
          }),
        },
      });
    }

    function setModalState(modalState) {
      vm.displayMessage = undefined;

      vm.modalState = {
        settings: _.find(vm.translatedStateSettings, {
          state: modalState,
        }),
        state: modalState,
      };

      $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, vm.id);
      _.invoke(vm.modalState.settings, 'initStateFn');

      dispatchPageAnalyticsWithCart();

      // The modalState may have changed
      // Firing onModalStateChange to signal possible change
      _.invoke(vm, 'onModalStateChange', {modalState: vm.modalState.state});

      ////////

      function dispatchPageAnalyticsWithCart() {
        if (feature.isEnabled('temp_analytics_cart_src2')) {
          const {dispatchPageAnalytics} = binkySrc2.services.analytics.analyticsUtils;
          let billingData, cartId, purchaseAuthId;

          // cart is undefined if NFR the cart will not be populated therefore calls will fail.
          if (!isNFRProductBlocked() && vm.cart && vm.cartItems) {
            cartId = vm.cart.cartId;

            ({billingData, purchaseAuthId} = getAnalyticsInfoFromCartData({
              cartItems: vm.cartItems,
              useRenewalPricing: vm.showFuturePricing,
            }));
          }

          dispatchPageAnalytics({
            cart: {
              billingData,
              cartId,
              cartItems: vm.cartItems,
              contract: vm.contract,
              purchaseAuthId,
              total: vm.totalSummary,
            },
            name: vm.modalState.settings.analytics.pageName,
            pageName: vm.modalState.settings.analytics.pageName,
          });
        } else {
          AnalyticsHelper.dispatchPageAnalytics({
            cart: vm.cart,
            cartItems: vm.cartItems,
            contract: vm.contract,
            pageName: vm.modalState.settings.analytics.pageName,
            total: vm.totalSummary,
            useRenewalPricing: vm.showFuturePricing,
          });
        }
      }
    }

    /**
     * @description helper function to setup the state settings according to the contract type and landing state.
     *   Assumes landingState is set to one of ADD_PRODUCTS_MODAL2_STATES.
     */
    function setupAndTranslateStateSettings() {
      let reviewOrderCtaButtonText;
      if (vm.contract.isDrContract) {
        reviewOrderCtaButtonText = 'buttons.completePurchase';
      } else if (vm.contract.isDirectContract()) {
        reviewOrderCtaButtonText = 'buttons.placeSecureOrder';
      } else {
        reviewOrderCtaButtonText = 'buttons.requestProducts';
      }

      isAddLicenses = deepLinkingState
        ? isAddLicenses
        : vm.cartItems.length === 1 && _.includes([REVIEW_ORDER, SHOW_PAGE_BANNER], landingState);
      /**
       * | state               | cancel btn | back btn                                      | cta btn                               |
       * |---------------------|------------|-----------------------------------------------|---------------------------------------|
       * | SELECT_ORG_TYPES    | Close      | N/A                                           | Confirm and continue                  |
       * | SELECT_PRODUCTS     | Close      | N/A                                           | Review order                          |
       * | REVIEW_ORDER        | Close      | N/A (when land directly at this state)        | Place secure order (DIRECT non-DR)|
       * |                     | Close      | Edit order                                    | Complete Purchase (DIRECT DR)     |
       * |                     | Close      | Edit order (when coming from SELECT_PRODUCTS) | Request Product(s) (INDIRECT)     |
       * | SHOW_PAGE_BANNER    | Close      | N/A                                           | N/A                                   |
       */
      const STATE_SETTINGS = [
        {
          analytics: {
            pageName: 'addProductSelection',
          },
          backBtn: undefined,
          ctaBtn: 'buttons.confirmAndContinue',
          ctaDisabled: () => !organizationTypeProps.areRequiredFieldsFilled || vm.isSubmitting,
          initStateFn: undefined,
          isCartReadOnly: false,
          modalTitle: 'title.selectYourOrganizationType',
          showTotalInCart: false,
          state: SELECT_ORG_TYPES,
        },
        {
          analytics: {
            ctaButtonEventName: 'reviewOrder',
            pageName: 'addProductSelection',
          },
          backBtn: undefined,
          ctaBtn: 'buttons.reviewOrder',
          ctaDisabled: () => !isProductSelectionValid() || vm.fetchingCartPreview,
          initStateFn: () => {
            vm.searchStr = '';
            // when coming back from review order with an invalid product selection for NFR contract,
            // the banner was previously hidden by setModalState, so adding it again here
            if (isNFRProductBlocked()) {
              showNFRPurchaseBlockedBanner();
            }
          },
          isCartReadOnly: false,
          modalTitle: 'title.addProducts',
          showTotalInCart: false,
          state: SELECT_PRODUCTS,
        },
        {
          // If the admin lands at the REVIEW_ORDER state, then they won't be able to go back to SELECT_PRODUCTS state
          // and the cart will not be read-only so they can adjust their order.
          analytics: {
            backButtonEventName: 'editOrder',
            pageName: 'addReviewOrder',
          },
          backBtn: isAddLicenses ? undefined : 'buttons.editOrder',
          ctaBtn: reviewOrderCtaButtonText,
          ctaDisabled: () =>
            !isProductSelectionValid() ||
            vm.fetchingCartPreview ||
            vm.isSubmitting ||
            vm.isJemTimedOut ||
            isDirectPONumberInvalid(),
          initStateFn: showReviewPurchaseBanner,
          isCartReadOnly: !isAddLicenses,
          modalTitle: isAddLicenses ? 'title.addLicenses' : 'title.addProducts',
          showTotalInCart: true,
          state: REVIEW_ORDER,
        },
        {
          analytics: {
            pageName: 'addShowError',
          },
          backBtn: undefined,
          ctaBtn: undefined,
          initStateFn: showDisplayMessageOnlyBanner,
          isCartReadOnly: false,
          modalTitle: isAddLicenses ? 'title.addLicenses' : 'title.addProducts',
          showTotalInCart: false,
          state: SHOW_PAGE_BANNER,
        },
      ];
      vm.translatedStateSettings = translateStateSettings(STATE_SETTINGS);
    }

    function setupCart() {
      // Re-get the cart on each open. If the org changed between opens, the contract changed.
      vm.cart = Cart.get({
        contractId: vm.contract.id,
        lastModifiedById: AuthenticatedUser.get().getId(),
        ownerId: vm.contract.getOwnerUserId(),
      });
    }

    /**
     * @description helper function to setup the offers and initialize the customer segment and the cart
     *
     * @returns {Promise} a rejected promise if any API call fails or the plan type hasn't been selected yet,
     *   else return a resolved promise
     */
    function setupOffersAndCart() {
      // Bail if NFR purchase is blocked
      if (landingState === SHOW_PAGE_BANNER) {
        return $q.resolve();
      }

      return setupOffers().then(initCustomerSegment).then(setupCart).then(setupProductBuckets);
    }

    function setupOffers() {
      vm.offerList = OrganizationManager.getOffersForActiveOrg(
        {
          intent: OFFER_LIST_INTENT.AAC_OFFER_DISCOVERY_PURCHASE,
        },
        vm.contract.id
      );

      return vm.offerList.$promise.then(() => {
        // filter the offer list items to include only those which can have a PA created for them
        vm.offerList.filterOnCanCreatePurchaseAuthorization();
      });
    }

    function setupProductBuckets() {
      // Bail if the plan type hasn't been selected yet.
      if (!vm.selectedCustomerSegment) {
        return $q.resolve();
      }

      const productBuckets = ProductPurchaseHelper.getProductBuckets({
        cartItems: vm.cartItems,
        customerSegment: vm.selectedCustomerSegment,
        offerList: vm.offerList,
        productItems: ownedProductItems,
      });

      _.assign(
        vm,
        _.pick(productBuckets, [
          'cartItems',
          'ownedOffers',
          'unownedWithoutRecOffers',
          'unownedWithRecOffers',
        ])
      );

      // Initial count of the owned offers. This should stay constant as offers are moved between the
      // ownedOffer bucket/section and the shopping cart.
      vm.ownedOfferCount = _.size(vm.ownedOffers);

      // Needs to be done here because we need the offers to be
      // set up before we can calculate billable items. This is primarily for opening with a cart item
      // to work correctly.
      // No need to debounce this function.
      if (vm.contract.isDirectContract() && isProductSelectionValid()) {
        const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
        fetchBillableItems(billableItems);
      }

      // The initial cartItems may have changed based on ProductPurchaseHelper.getProductBuckets result
      // Firing onCartItemsChange to signal possible change
      _.invoke(vm, 'onCartItemsChange', {cartItems: vm.cartItems});

      return $q.resolve();
    }

    function setupProductList() {
      const orgId = OrganizationManager.getActiveOrgId();

      vm.productList = ProductList.get({
        includePricingData: true,
        notifyListeners: false,
        orgId,
      });

      return vm.productList.$promise.then(() => {
        ownedProductItems = _.filter(vm.productList.items, {
          contractId: vm.contract.id,
        });
      });
    }

    function showAdditionalLicensesRenewedPageBanner() {
      const prefix = 'widgets.products.purchase.addProductsModal.banners.additionalLicensesRenewed';
      const message = $translate.instant(`${prefix}.message`);
      const title = $translate.instant(`${prefix}.title`);
      showPageBanner({message, title, variant: DISPLAY_MESSAGE_VARIANT.INFO});
    }

    function showDisplayMessageOnlyBanner() {
      if (isNFRProductBlocked()) {
        showNFRPurchaseBlockedBanner();
      } else {
        showPageBanner();
      }
    }

    function showNFRPurchaseBlockedBanner() {
      const prefix = 'widgets.products.purchase.addProductsModal.banners.blockedNFRPurchase';
      const message = $translate.instant(`${prefix}.message`);
      const title = $translate.instant(`${prefix}.title`);
      showPageBanner({message, title});
    }

    function showPageBanner(options = {}) {
      const {
        ctaText,
        message,
        title,
        showDefaultButton = false,
        variant = DISPLAY_MESSAGE_VARIANT.ERROR,
      } = options;

      // A close button is shown by default for INFO and HELP messages, unless showDefaultButton is explicitly
      // set to false.
      vm.displayMessage = new DisplayMessage({
        body: message,
        ctaText,
        header: title,
        showDefaultButton,
        variant,
      });

      // Make sure the message is visible.
      $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, vm.id);
    }

    function showReviewPurchaseBanner() {
      if (vm.contract.isRenewableDirectContract() && vm.contract.isInRenewalWindow()) {
        showAdditionalLicensesRenewedPageBanner();
      } else if (vm.contract.isVIPMPContract() && vm.contract.getExpirationGraceDays() > 0) {
        showVIPMPLicensesExpirationPageBanner();
      }
    }

    function showQuickAssignModal() {
      // If adding licenses from the React `Product users` page don't open
      // Quick Assign after purchasing more licenses.
      if (
        analyticsSourcePage === 'products' // from productUsersAddLicensesRouteConfig
      ) {
        return $q.resolve();
      }

      return productAccess.canViewQuickAssignModal().then((canView) => {
        if (canView) {
          _.invoke(quickAssignGlobalModal, 'open');
        }
      });
    }

    function showVIPMPLicensesExpirationPageBanner() {
      const message = $translate.instant(
        'widgets.products.purchase.addProductsModal.banners.licensesExpirationVIPMP.message',
        {
          expirationGraceDays: vm.contract.getExpirationGraceDays(),
        }
      );
      const title = $translate.instant(
        'widgets.products.purchase.addProductsModal.banners.licensesExpirationVIPMP.title',
        {
          expirationGraceDays: vm.contract.getExpirationGraceDays(),
        }
      );
      showPageBanner({
        message,
        title,
        variant: DISPLAY_MESSAGE_VARIANT.INFO,
      });
    }

    function translateStateSettings(stateSettings) {
      return _.map(stateSettings, (stateSetting) => {
        const {
          analytics,
          backBtn,
          initStateFn,
          ctaBtn,
          ctaDisabled,
          isCartReadOnly,
          modalTitle,
          showTotalInCart,
          state,
        } = stateSetting;
        return {
          analytics,
          backBtn: translateWhenDefined(backBtn),
          ctaBtn: translateWhenDefined(ctaBtn),
          ctaDisabled,
          initStateFn,
          isCartReadOnly,
          modalTitle: translateWhenDefined(modalTitle),
          showTotalInCart,
          state,
        };
      });

      ////////

      function translateWhenDefined(key) {
        return key
          ? $translate.instant(`widgets.products.purchase.addProductsModal.${key}`)
          : undefined;
      }
    }

    function submit() {
      vm.isSubmitting = true;

      const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
      const purchaseOptions = {billableItems};

      if (_.size(vm.poNumber)) {
        purchaseOptions.poNumber = vm.poNumber;
      }

      vm.promiseToWait = vm.cart
        .submitOrder(purchaseOptions)
        .then(onSubmitSuccess, onSubmitError)
        .finally(() => {
          vm.isSubmitting = false;
        });

      function onSubmitSuccess() {
        let message;
        if (vm.selectedCustomerSegment === CUSTOMER_SEGMENT.ENTERPRISE) {
          message = $translate.instant(
            'widgets.products.purchase.addProductsModal.toast.successToastEnterprise',
            {
              resellerName: vm.contract.getResellerName(),
            }
          );
        } else {
          message = $translate.instant(
            vm.contract.isDirectContract()
              ? 'widgets.products.purchase.addProductsModal.toast.messageFormat.successToastDirect'
              : 'widgets.products.purchase.addProductsModal.toast.messageFormat.successToastIndirect',
            {
              count: _.sumBy(vm.cartItems, (offer) => _.toNumber(offer.numberSelected)),
            },
            'messageformat'
          );
        }
        toastManager.showSuccessToast(message);

        dispatchAnalyticsClickEventWithCart({cartHasSubmitted: true, eventName: 'completeOrder'});

        onClose({dispatchAnalytics: false});
        vm.promiseToWait = showQuickAssignModal();
      }

      function onSubmitError({maxLicenseLimit, status} = {}) {
        let message, title;

        const {
          AUTH_ERROR,
          DUPLICATE_PURCHASE_ORDER_NUMBER,
          EXCEED_INDIRECT_CONTRACT_PER_PRODUCT_LIMIT,
          EXCEED_INDIRECT_CONTRACT_PRODUCTS_LIMIT,
        } = CART_ERROR_STATUS;

        let variant = DISPLAY_MESSAGE_VARIANT.ERROR;

        switch (status) {
          case DUPLICATE_PURCHASE_ORDER_NUMBER:
            // needs to clear when user updates PO number
            // Show duplicate PO error message
            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.duplicatePO.message'
            );
            title = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.duplicatePO.title'
            );
            break;
          case EXCEED_INDIRECT_CONTRACT_PER_PRODUCT_LIMIT:
            // will get cleared because user needs to updated total to correct
            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.exceedIndirectContractPerProductLimit.message',
              {
                maxLicenseLimit: numberFilter(maxLicenseLimit),
              }
            );
            break;
          case EXCEED_INDIRECT_CONTRACT_PRODUCTS_LIMIT:
            // will get cleared because user needs to updated total to correct
            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.exceedIndirectContractProductsLimit.message'
            );
            break;
          case binkySrc2.models.orders.ORDER_CONSTANTS.JEM_TIMEOUT_ERROR:
            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.jemTimeout.message'
            );
            title = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.jemTimeout.title'
            );
            variant = DISPLAY_MESSAGE_VARIANT.WARNING;
            vm.isJemTimedOut = true;
            break;
          case AUTH_ERROR:
            message = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.unauthorized.message',
              {
                salesPhoneNumber,
              }
            );
            title = $translate.instant(
              'widgets.products.purchase.addProductsModal.banners.unauthorized.title'
            );
            break;
          default:
            break;
        }

        showPageBanner({message, title, variant});
      }
    }

    function updateTotals() {
      hideErrorPageBanner();
      // no need to calculate indirect contracts
      if (vm.contract.isDirectContract()) {
        if (isProductSelectionValid()) {
          // Setting to true here because we don't want it in the async/debounce call.
          vm.fetchingCartPreview = true;
          const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
          debouncedFetchBillableItems(billableItems);
        } else {
          // Avoid fetching billable items for any previous valid selections
          debouncedFetchBillableItems.cancel();
          vm.previewPromise = $q.reject({license_count: NaN, reason: 'invalid selection'});
        }
      } else {
        // For indirect contract, we want to show the number of licenses purchasing.
        // Here we are leveraging the promise to pass the data to the component.
        vm.previewPromise = $q.resolve({
          license_count: _.sumBy(vm.cartItems, 'numberSelected'),
        });

        // For NFR (Not For Resale) contract, we need to restrict them to order only one license
        if (isNFRProductBlocked()) {
          showNFRPurchaseBlockedBanner();
        }
      }

      // The cart items or the quantities may have changed
      // Firing onCartItemsChange to signal possible change
      _.invoke(vm, 'onCartItemsChange', {cartItems: vm.cartItems});
    }
  }
})();
/* eslint-enable max-lines */
