import {navBus} from '@admin-tribe/binky';
import {Grid, Heading, Link, Text, View} from '@adobe/react-spectrum';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';
import {PACKAGE_TYPE_CONSTANTS} from 'features/packages/packagesConstants';
import {PATH_PACKAGES_SERVERS} from 'features/packages/routing/packagesPaths';

import styles from './PackageDetails.pcss';
import {
  getEntitlementAlignment,
  getNativePackageSHA256,
  getNativePackageSize,
  getPackagingStyle,
  showActivationType,
  showCodes,
  showEntitlements,
  showNativePackageSHA256,
  showNativePackageSize,
  showNpdId,
  showPackagingStyle,
  showServerDetails,
} from './PackageDetailsUtils';
import PackageDetailsOptions from './package-details-options/PackageDetailsOptions';

const PackageDetails = () => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);

  // eslint-disable-next-line complexity -- activation type checks
  const getActivationType = () => {
    switch (attributes.package.packageType) {
      case PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.frlHighprivacyOnline',
        });
      case PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.frlIsolated',
        });
      case PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.frlOffline',
        });
      case PACKAGE_TYPE_CONSTANTS.MANAGED:
      case PACKAGE_TYPE_CONSTANTS.PLUGIN_AND_SW_CONFIG:
      case PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY:
      case PACKAGE_TYPE_CONSTANTS.SW_CONFIG_ONLY:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.managed',
        });
      case PACKAGE_TYPE_CONSTANTS.SELF_SERVICE:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.selfService',
        });
      case PACKAGE_TYPE_CONSTANTS.NUELL:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.nuell',
        });
      case PACKAGE_TYPE_CONSTANTS.FRL_LAN:
        return intl.formatMessage({
          id: 'packages.summaryDrawerBody.packageDetails.packageType.frlLan',
        });
      default:
        return '';
    }
  };

  const getServerType = () =>
    attributes.package.frlPackagingInfo.server ? (
      <Link
        onPress={() =>
          navBus.navigate(
            generatePath(PATH_PACKAGES_SERVERS, {
              orgId: rootStore.organizationStore.activeOrgId,
            })
          )
        }
      >
        {attributes.package.frlPackagingInfo.server.name}
      </Link>
    ) : (
      intl.formatMessage({
        id: 'packages.summaryDrawerBody.packageDetails.serverRemoved',
      })
    );

  const getActivationCodesType = () => {
    if (
      attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED &&
      attributes.package.frlPackagingInfo &&
      attributes.package.frlPackagingInfo.challengeCodes &&
      attributes.package.frlPackagingInfo?.challengeCodes?.length > 0
    ) {
      return intl.formatMessage({
        id: 'packages.summaryDrawerBody.packageDetails.codesIncluded',
      });
    }
    return intl.formatMessage({
      id: 'packages.summaryDrawerBody.packageDetails.codesNotIncluded',
    });
  };

  const getEntitlements = () =>
    attributes.package.frlPackagingInfo.adminPackageLicenseInfo.length === 1 ? (
      attributes.package.frlPackagingInfo.adminPackageLicenseInfo[0].licenseFullName
    ) : (
      <ul className={styles['package-details-container']}>
        {attributes.package.frlPackagingInfo.adminPackageLicenseInfo.map((license) => (
          <li key={license.licenseID}>{license.licenseFullName}</li>
        ))}
      </ul>
    );

  const getLanguage = () => (
    <>
      {intl.formatMessage(
        {id: 'packages.summaryDrawerBody.packageDetails.locale'},
        {language: attributes.language}
      )}
      {attributes.osLangDetection && (
        <>
          {intl.formatMessage({
            id: 'packages.summaryDrawerBody.packageDetails.matchOSLocale',
          })}
        </>
      )}
    </>
  );

  const getNpdIdValue = () => showNpdId(attributes) && attributes.npdId;

  const getPackageIdValue = () => !attributes.isTemplate && attributes.packageBundleId;

  const detailsMap = [
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.packageId'}),
      showDetail: !attributes.isTemplate,
      value: getPackageIdValue(),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.npdId'}),
      showDetail: showNpdId(attributes),
      value: getNpdIdValue(),
    },
    {
      field: intl.formatMessage({
        id: 'packages.summaryDrawerBody.packageDetails.packageType.title',
      }),
      showDetail: showActivationType(attributes),
      value: showActivationType(attributes) && getActivationType(),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.server'}),
      showDetail: showServerDetails(attributes),
      value: showServerDetails(attributes) && getServerType(),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.codes'}),
      showDetail: showCodes(attributes),
      value: showCodes(attributes) && getActivationCodesType(),
    },
    {
      alignField: getEntitlementAlignment(attributes),
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.entitlement'}),
      showDetail: showEntitlements(attributes),
      value: showEntitlements(attributes) && getEntitlements(),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.platform'}),
      showDetail: true,
      value: intl.formatMessage(
        {id: 'packages.summaryDrawerBody.packageDetails.platformStrings'},
        {platform: attributes.platformStr}
      ),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.language'}),
      showDetail: true,
      value: getLanguage(),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.packagingStyle'}),
      showDetail: showPackagingStyle(attributes),
      value: getPackagingStyle(attributes),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.size'}),
      showDetail: showNativePackageSize(attributes),
      value: intl.formatMessage(
        {id: 'packages.summaryDrawerBody.packageDetails.size.value'},
        {
          size: getNativePackageSize(attributes),
        }
      ),
    },
    {
      field: intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.sha256Checksum'}),
      showDetail: showNativePackageSHA256(attributes),
      value: getNativePackageSHA256(attributes),
    },
  ];

  return (
    <View marginBottom="size-500">
      <Heading level={3}>
        {intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.heading'})}
      </Heading>

      <View maxWidth="size-4600" UNSAFE_style={{overflowWrap: 'anywhere'}}>
        {detailsMap.map(
          (detail) =>
            detail.showDetail && (
              <Grid
                key={detail.field}
                areas={['field value']}
                columns={['1fr', '3fr']}
                data-testid={detail.field}
                gap="size-500"
                marginBottom="size-300"
                marginStart="size-150"
                rows={['auto']}
              >
                <Text
                  alignSelf={detail.alignField ? detail.alignField : 'center'}
                  data-testid={`${detail.field}`}
                  gridArea="field"
                  // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling
                  UNSAFE_className={styles['package-details-field']}
                >
                  {detail.field}
                </Text>
                <Text
                  data-testid={`${detail.field}-text`}
                  gridArea="value"
                  // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling
                  UNSAFE_className={styles['package-details-value']}
                >
                  {detail.value}
                </Text>
              </Grid>
            )
        )}
        <PackageDetailsOptions />
      </View>
    </View>
  );
};

export default PackageDetails;
