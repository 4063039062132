import {Product} from '@admin-tribe/binky';
import {DetailsButton} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup} from '@adobe/react-spectrum';
import {USER_ROLES} from '@pandora/administration-core-types';
import {useAuth} from '@pandora/react-auth-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import GoToProductButton from 'common/components/go-to-product-button/GoToProductButton';
import rootStore from 'core/RootStore';
import ProductDetailsDrawer from 'features/products/components/product-details-drawer/ProductDetailsDrawer';
import GoToPackagesButton from 'features/products/device-licenses/components/go-to-packages-button/GoToPackagesButton';

/**
 * A wrapper of Product Details Page Header buttons
 */
const ProductDetailsPageActions = ({
  autoAssignModalEnabled,
  openAutoAssignmentRuleModal,
  product,
}) => {
  const intl = useIntl();
  const auth = useAuth();
  const {activeOrgId} = rootStore.organizationStore;
  const isProductAdmin = userRoleUtils.anyOfForOrg(
    auth.getUserProfile().roles,
    [USER_ROLES.PRODUCT_ADMIN],
    activeOrgId
  );
  const isSdl = product.isDeviceLicense();
  const goToProductLink = product?.links?.product_admin?.[0];

  return (
    <ButtonGroup>
      <DetailsButton detailsDrawer={<ProductDetailsDrawer product={product} />} />
      {autoAssignModalEnabled && (
        <Button
          data-testid="auto-assign-button"
          onPress={openAutoAssignmentRuleModal}
          variant="secondary"
        >
          {intl.formatMessage({
            id: 'products.productProfileDetails.autoAssignment.changeAutoAssignmentButton',
          })}
        </Button>
      )}
      {goToProductLink && isProductAdmin && <GoToProductButton goToProductLink={goToProductLink} />}
      {isSdl && <GoToPackagesButton />}
    </ButtonGroup>
  );
};

ProductDetailsPageActions.propTypes = {
  /**
   * Whether the auto assignment rule is enabled or not
   */
  autoAssignModalEnabled: PropTypes.bool,
  /**
   * Callback to open the auto assignment rule modal. Should be passed whenever
   * the auto assignment rule is enabled
   */
  openAutoAssignmentRuleModal: PropTypes.func,
  /**
   * A Product instance
   */
  product: PropTypes.instanceOf(Product).isRequired,
};
export default ProductDetailsPageActions;
