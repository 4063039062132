(function () {
  /**
   * @deprecated use src2 Storage pages
   */
  angular.module('app.core.storage-reports').constant('REPORT_STATUS', {
    ERROR: 'Error',
    INPROGRESS: 'Inprogress',
    SUCCESS: 'Success',
  });
})();
