import {navBus} from '@admin-tribe/binky';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLoaderData, useLocation, useParams} from 'react-router-dom';

import UserGroupAdminsSubpage from 'features/users/pages/user-group-admins-subpage/UserGroupAdminsSubpage';
import UserGroupAssignedProfilesSubpage from 'features/users/pages/user-group-assigned-profiles-subpage/UserGroupAssignedProfilesSubpage';
import UserGroupUsersSubpage from 'features/users/pages/user-group-users-subpage/UserGroupUsersSubpage';
import {
  PATH_USER_GROUP_DETAILS_ADMINS,
  PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES,
  PATH_USER_GROUP_DETAILS_USERS,
  USER_GROUP_DETAILS_TAB_NAV,
} from 'features/users/routing/usersPaths';

/**
 * User group details page tab nav.
 * This will display the `Users`, `Admins` and `Assigned product profiles` tabs depending on the
 * route/pathname.
 *
 * The pathname will be one of:
 *  PATH_USER_GROUP_DETAILS_USERS,
 *  PATH_USER_GROUP_DETAILS_ADMINS,
 *  PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES
 *
 * Which tabs are displayed depends on tabNavAccess.
 */
const UserGroupDetailsPageTabs = () => {
  const pathParams = useParams();
  const {userGroupId} = pathParams;
  const {tabNavAccess} = useLoaderData();
  const {pathname} = useLocation();
  const intl = useIntl();
  const [selectedKey, setSelectedKey] = useState(null);

  const keyToPathMap = useMemo(
    () => ({
      [USER_GROUP_DETAILS_TAB_NAV.USERS]: generatePath(PATH_USER_GROUP_DETAILS_USERS, pathParams),
      [USER_GROUP_DETAILS_TAB_NAV.ADMINS]: generatePath(PATH_USER_GROUP_DETAILS_ADMINS, pathParams),
      [USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES]: generatePath(
        PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES,
        pathParams
      ),
    }),
    [pathParams]
  );

  const detailsTabs = useMemo(() => {
    const tabs = [];

    // Users tab
    if (tabNavAccess[USER_GROUP_DETAILS_TAB_NAV.USERS]) {
      tabs.push({
        content: <UserGroupUsersSubpage />,
        key: USER_GROUP_DETAILS_TAB_NAV.USERS,
        name: intl.formatMessage({id: 'users.userGroupDetails.tabbedNav.tab.users'}),
        path: generatePath(PATH_USER_GROUP_DETAILS_USERS, pathParams),
      });
    }

    // Admins tab
    if (tabNavAccess[USER_GROUP_DETAILS_TAB_NAV.ADMINS]) {
      tabs.push({
        content: <UserGroupAdminsSubpage />,
        key: USER_GROUP_DETAILS_TAB_NAV.ADMINS,
        name: intl.formatMessage({id: 'users.userGroupDetails.tabbedNav.tab.admins'}),
        path: generatePath(PATH_USER_GROUP_DETAILS_ADMINS, pathParams),
      });
    }

    // Assigned profiles tab
    if (tabNavAccess[USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES]) {
      tabs.push({
        content: <UserGroupAssignedProfilesSubpage />,
        key: USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES,
        name: intl.formatMessage({id: 'users.userGroupDetails.tabbedNav.tab.assignedProfiles'}),
        path: generatePath(PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES, pathParams),
      });
    }

    // Routing should not let us get to here if the admin does not have access to any of the tabs.
    if (tabs.length === 0) {
      throw new Error(`UserGroupDetailsPageTabs: no tabs for userGroup ${userGroupId}`);
    }

    return tabs;
  }, [intl, pathParams, tabNavAccess, userGroupId]);

  /**
   * Keep the currently selected tab in sync with the URL
   */
  useEffect(() => {
    const pathEntry = Object.entries(keyToPathMap).find(([, path]) => path === pathname);
    setSelectedKey(pathEntry[0]);
  }, [keyToPathMap, pathname]);

  const onSelectionChange = useCallback(
    (key) => {
      if (key === selectedKey) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Modify the url to keep it in sync with the current tab.
      // The selectedKey will get set in the useEffect above.
      navBus.pushState({url: keyToPathMap[key]});
    },
    [keyToPathMap, selectedKey]
  );

  // Wait for the selectedKey to be set before rendering Tabs.
  return (
    selectedKey !== null && (
      <Tabs
        aria-label={intl.formatMessage({id: 'users.userGroupDetails.tabbedNav.ariaLabel'})}
        items={detailsTabs}
        onSelectionChange={onSelectionChange}
        selectedKey={selectedKey}
      >
        <TabList>{(item) => <Item key={item.key}>{item.name}</Item>}</TabList>
        <TabPanels>{(item) => <Item key={item.key}>{item.content}</Item>}</TabPanels>
      </Tabs>
    )
  );
};

export default UserGroupDetailsPageTabs;
