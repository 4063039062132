(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name UserGroupList
   * @description Model for a list of UserGroups
   */
  angular.module('app.core.user-group').factory('UserGroupList', UserGroupListFactory);

  /* @ngInject */
  function UserGroupListFactory(
    $q,
    $rootScope,
    _,
    binkySrc2,
    JIL_CONSTANTS,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationManager,
    USER_GROUP_EVENT,
    UserGroup
  ) {
    class UserGroupListSrc2 extends binkySrc2.services.users.UserGroupList {
      constructor(options = {}) {
        super(_.assign(options, {orgId: OrganizationManager.getActiveOrgId()}));

        if (options.getAllGroups) {
          // Forcibly set the pageSize to undefined to prevent paginated GET call
          this.pagination.pageSize = undefined;
        }

        this.unfilteredCount = 0;

        // Intentionally override the itemClassRef set by super class for backwards compatibility
        this.itemClassRef = UserGroup;
      }

      /**
       * @description Method to delete one or more user groups.
       *
       * @param {Object} selectedUserGroups UserGroup references to delete
       * @return {Promise} resolves if UserGroups successfully deleted, else rejects with error message
       */
      deleteUserGroups(selectedUserGroups) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .deleteUserGroups(selectedUserGroups)
            .then(() => {
              this.$resolved = true;
              resolve();
            })
            .catch((error) => {
              this.$resolved = true;
              reject(error.response);
            });
        });

        return this.$promise;
      }

      /**
       * @description Method to refresh current model state
       *
       * @returns {Promise<UserGroupListSrc2>} Returns promise resolving to model or rejecting with error
       */
      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => {
              if (this.shouldUpdateTotalItemCount()) {
                // Src2 does not have an unfilteredCount, set it here
                this.unfilteredCount = this.pagination.itemCount;
              }

              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Method to reverse the current sort ordering of the list.
       *   Note: this should either go-away or be moved down to jilModelList
       *
       * @return {undefined} no return value
       */
      reverseSortOrder() {
        this.sort.order =
          this.sort.order === JIL_CONSTANTS.ORDER.ASC
            ? JIL_CONSTANTS.ORDER.DESC
            : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description Method to retrieve an existing list of UserGroups from the back-end.
       *
       * @param {Boolean} getAllGroups true to fetch all groups, else false to fetch paginated groups
       * @param {Boolean} onlyEditable Deprecated. UserGroup 'editable' field has been replaced with 'administerable'.
       *                               Only included here to preserve original method signature
       * @param {Number} page page number of user groups to display
       * @param {Number} pageSize number of user groups to display per page
       * @param {String} sortOrder sorting order (ASC or DESC)
       * @param {String} searchQuery search query or filter to use on user groups displayed
       * @returns {UserGroupListSrc2} new UserGroupList Object reference
       */
      static get(
        getAllGroups,
        onlyEditable,
        page,
        pageSize,
        sortOrder = JIL_CONSTANTS.ORDER.ASC,
        searchQuery
      ) {
        const model = new UserGroupListSrc2({
          filterQuery: searchQuery,
          getAllGroups: !!getAllGroups,
          pageNumber: page,
          pageSize,
          sortOrder,
        });

        model.refresh();
        return model;
      }
    }
    /** *** Event listener *** */
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.CREATE.USERGROUP, () => {
      modelCache.removeAll(MODEL.USERGROUPLIST);
    });

    // Temporary while porting UserGroup and UserGroupList
    // Should not need MESSAGE.CREATE.USERGROUP above in the future
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(USER_GROUP_EVENT.CREATE, () => {
      modelCache.removeAll(MODEL.USERGROUPLIST);
    });

    // We register the cache size for this class
    modelCache.register(MODEL.USERGROUPLIST, 5);

    return UserGroupListSrc2;
  }
})();
