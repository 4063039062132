import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the DomainEnforcement page and its sub-components.
 */
const DomainEnforcementContext = createContext({});
const useDomainEnforcementContext = () => useContext(DomainEnforcementContext);

const DomainEnforcementContextProvider = ({children, deState, refreshDirectory}) => {
  const {domainEnforcement, ...rest} = deState.state;

  const value = useMemo(
    () => ({
      domainEnforcement: {...domainEnforcement, ...rest},
      loadExceptionList: deState.loadExceptionList,
      refreshDirectory,
      reloadDomainEnforcementExceptionList: deState.reloadDomainEnforcementExceptionList,
      updateDomainEnforcement: deState.updateDomainEnforcement,
      updateDomainEnforcementExceptionList: deState.updateDomainEnforcementExceptionList,
    }),
    [
      domainEnforcement,
      rest,
      deState.loadExceptionList,
      deState.reloadDomainEnforcementExceptionList,
      deState.updateDomainEnforcement,
      deState.updateDomainEnforcementExceptionList,
      refreshDirectory,
    ]
  );

  return (
    <DomainEnforcementContext.Provider value={value}>{children}</DomainEnforcementContext.Provider>
  );
};

DomainEnforcementContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the domain list.
   */
  children: PropTypes.node,
  /**
   * The domain enforcement state used by the provider to keep state of the children components.
   */
  deState: PropTypes.shape({
    /**
     * Fetch function for the domain enforcement exception list
     */
    loadExceptionList: PropTypes.func.isRequired,
    /**
     * Function used to reload the domain enforcement exception list
     */
    reloadDomainEnforcementExceptionList: PropTypes.func.isRequired,
    /**
     * DomainEnforcement data state
     */
    state: PropTypes.shape({
      domainEnforcement: PropTypes.shape({
        /**
         * The ID of the Directory
         */
        authSrc: PropTypes.string.isRequired,
        /**
         * Date when opt-out phase ends
         */
        notifyAt: PropTypes.number,
        /**
         * Current status of the Policy
         */
        state: PropTypes.string.isRequired,
        /**
         *  Date when the current state phase ends
         */
        stateEndsAt: PropTypes.number,
      }),
      exceptionList: PropTypes.arrayOf(
        PropTypes.shape({
          authSrc: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          userName: PropTypes.string,
        })
      ).isRequired,
      /**
       *  Whether the state of the domain enforcement has an error
       */
      hasLoadingError: PropTypes.bool.isRequired,
      /**
       *  Whether domain enforcement status is Activated, Enforced or Notified
       */
      isDeEnabled: PropTypes.bool.isRequired,
      /**
       *  Whether domain enforcement status is Enforced
       */
      isDeEnforced: PropTypes.bool.isRequired,
      /**
       *  Whether domain enforcement status is Notified
       */
      isDeNotified: PropTypes.bool.isRequired,
      /**
       *  Whether a JIL request is in progress
       */
      isLoading: PropTypes.bool.isRequired,
    }),
    /**
     * Function used to update the domain enforcement policy
     */
    updateDomainEnforcement: PropTypes.func.isRequired,
    /**
     * Function used to update the domain enforcement exception list
     */
    updateDomainEnforcementExceptionList: PropTypes.func.isRequired,
  }),
  /**
   *  Callback function to refresh directory
   */
  refreshDirectory: PropTypes.func.isRequired,
};

export {DomainEnforcementContextProvider, useDomainEnforcementContext};
