import {useContentEntry} from '@pandora/react-content-provider';
import {ProductIcon, ProductIconContentModel} from '@pandora/react-product-icon-list';
import PropTypes from 'prop-types';
import React from 'react';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

import InfoCardBar from '../info-card-bar/InfoCardBar';
import InfoCard from '../info-card-bar/info-card/InfoCard';
import SupportTicketCaseType from '../support-ticket-case-type/SupportTicketCaseType';

const {INFO_CARD_TYPE, SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

/**
 * Component is used to show InfoBar for a ExpertSession. It uses <InfoCardBar/> component to render <InfoCard/>
 * For ExpertSession typeCode it uses <SupportTicketCaseType/> which renders the text as per typeCode value
 */
const ExpertSessionInfoBar = ({
  'data-testid': dataTestId,
  dateRequested,
  dateScheduled,
  productName,
  productIconUrl,
  sessionId,
  typeCode,
}) => {
  const content = useContentEntry(ProductIconContentModel);
  return (
    <InfoCardBar data-testid={dataTestId || 'expert-session-info-bar'}>
      <InfoCard
        label="support.expertSessionInfoBar.fields.sessionId"
        type={INFO_CARD_TYPE.STRING}
        value={sessionId}
      />
      {typeCode && (
        <InfoCard
          data-testid="typeCode"
          label="support.expertSessionInfoBar.fields.sessionType"
          type={INFO_CARD_TYPE.COMPONENT}
          value={typeCode}
        >
          <SupportTicketCaseType
            recordType={SUPPORT_TICKET_RECORD_TYPE.EXPERT}
            typeCode={typeCode}
          />
        </InfoCard>
      )}
      <InfoCard
        data-testid="productNameWithIcon"
        label="support.expertSessionInfoBar.fields.product"
        type={INFO_CARD_TYPE.COMPONENT}
        value={productName}
      >
        <ProductIcon content={content} iconUrl={productIconUrl} name={productName} />
      </InfoCard>
      <InfoCard
        label="support.expertSessionInfoBar.fields.dateRequested"
        type={INFO_CARD_TYPE.DATETIME}
        value={dateRequested}
      />
      <InfoCard
        label="support.expertSessionInfoBar.fields.dateScheduled"
        noDataTextKey="support.expertSessionInfoBar.expertSessionDateScheduled.notYetScheduled"
        type={INFO_CARD_TYPE.DATETIME}
        value={dateScheduled}
      />
    </InfoCardBar>
  );
};

ExpertSessionInfoBar.propTypes = {
  /**
   * The specified data-testid for the component.
   * The default is 'expert-session-info-bar'.
   */
  'data-testid': PropTypes.string,
  /**
   * ExpertSession createdOn value
   */
  dateRequested: PropTypes.string,
  /**
   * ExpertSession scheduledDate value
   */
  dateScheduled: PropTypes.string,
  /**
   * ExpertSession Product Icon Url
   */
  productIconUrl: PropTypes.string,
  /**
   * ExpertSession Product Name, if not provided will hide the InfoCard
   */
  productName: PropTypes.string,
  /**
   * ExpertSession ID
   */
  sessionId: PropTypes.string.isRequired,
  /**
   * ExpertSession typeCode value, based on this value SessionType text is shown.
   */
  typeCode: PropTypes.number,
};

export default ExpertSessionInfoBar;
