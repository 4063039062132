(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appStartChatButton
   * @description Renders a Start Chat button for talking with an Adobe agent. Hides the button
   *   until the chat service has initialized. Dispatches an analytics event when clicked.
   *
   * @binding {Object} [analyticsEvent] - the extra analytics event that we would like to log to analytics.
   *   (For example, when the start chat button is clicked within a Sophia banner, we want to log a sophia-banner-click event)
   * @binding {String} [analyticsName] - the name to use for this button in analytics events.
   *   The 'startChat' analytics event requires this binding.
   * @binding {String} [appId] - appid to use
   * @binding {Function} [onStartChatButtonClick] - Callback to call when a user clicks on this button.
   * @binding {String} [variant] - the Coral variant to apply to the button (e.g. 'cta' or 'quiet').
   *   If unspecified, no variant is set on the button.
   */

  angular.module('app.widgets.support').component('appStartChatButton', {
    bindings: {
      analyticsEvent: '=?',
      analyticsName: '@',
      appId: '@?',
      class: '@?',
      onStartChatButtonClick: '&',
      variant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/support/start-chat-button/start-chat-button.component.html',
  });

  /* @ngInject */
  function controller(_, AnalyticsEvent, chat, feature, onesieSrc2, $translate) {
    const vm = this;

    _.assign(vm, {
      feature,
      onButtonClick,
      shouldShowButton,
    });

    function onButtonClick() {
      onesieSrc2.core.services.chat.chatProvider.openMessagingWindow({
        appId: vm.appId,
        sourceText: $translate.instant('widgets.support.startChatButton.startChat'),
        sourceType: 'button',
      });

      if (vm.analyticsName) {
        AnalyticsEvent.dispatch({
          componentMethod: 'startChat',
          componentMethodType: 'click',
          componentName: vm.analyticsName,
        });
      }

      if (vm.analyticsEvent) {
        AnalyticsEvent.dispatch(vm.analyticsEvent);
      }

      _.invoke(vm, 'onStartChatButtonClick');
    }

    function shouldShowButton() {
      return onesieSrc2.core.services.chat.chatProvider.canDisplayChat();
    }
  }
})();
