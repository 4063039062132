/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketContactsForm
 *
 * @description The form for the Support Ticket Contacts modal.
 *
 * @binding {Function} onFormChange. Callback called when the form validity changes from valid to invalid, or for every
 *   change when the form is valid. Keys are boolean, isValid, and Object contacts which contains the modified fields.
 * @binding {Object} supportTicket The support ticket that is being updated.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketContactsForm', {
    bindings: {
      onFormChange: '&',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-contacts-modal/support-ticket-contacts-form/support-ticket-contacts-form.component.html',
  });

  function controller($translate, _) {
    const vm = this;

    let isFormInvalid = true;

    _.assign(vm, {
      $doCheck,
      $onInit,
      contacts: {}, // this should only include fields which have been updated
      onChangeNotifyees,
      onChangePhoneNumber,
      onChangeTimezone,
      onChangeWorkingHours,
    });

    function $onInit() {
      initFormForTicketType();
    }

    // Triggered for every digest cycle of the component.
    function $doCheck() {
      checkFormValidity();
    }

    // Note: API field is phone, not phoneNumber
    function onChangePhoneNumber(phoneNumber) {
      vm.contacts.phone = phoneNumber;
    }

    function onChangeNotifyees(notifyees) {
      vm.contacts.ccEmailList = notifyees;
    }

    function onChangeTimezone(timezone) {
      vm.contacts.timezone = timezone;
    }

    // workingHoursStart and workingHoursEnd will be undefined if working hours were set to something invalid.
    // Each are already formatted as 'HH.mm' string expected by the API (for example '17.00')
    function onChangeWorkingHours(workingHoursStart, workingHoursEnd) {
      vm.contacts.workingHoursStart = workingHoursStart;
      vm.contacts.workingHoursEnd = workingHoursEnd;
    }

    //////////////

    function areWorkingHoursInvalid() {
      return (
        (_.has(vm.contacts, 'workingHoursStart') && _.isEmpty(vm.contacts.workingHoursStart)) ||
        (_.has(vm.contacts, 'workingHoursEnd') && _.isEmpty(vm.contacts.workingHoursEnd))
      );
    }

    function isPhoneNumberInvalid() {
      return _.has(vm.contacts, 'phone') && _.isEmpty(vm.contacts.phone);
    }

    // With the current control, if the timezone is changed it should always be valid.
    function isTimezoneInvalid() {
      return _.has(vm.contacts, 'timezone') && !_.isInteger(vm.contacts.timezone);
    }

    function checkFormValidity() {
      // Form controller in undefined until the form initializes.
      // No need to enable the CTA until the form has been changed.
      const isInvalid =
        _.isEmpty(vm.contacts) ||
        isPhoneNumberInvalid() ||
        isTimezoneInvalid() ||
        areWorkingHoursInvalid();

      // Report validity transitions so CTA button can be enabled accordingly.
      // While form is valid, report every change because the CTA button can be clicked and the modal must have the
      // current form values.
      if (isInvalid !== isFormInvalid || !isInvalid) {
        isFormInvalid = isInvalid;
        vm.onFormChange({event: {contacts: vm.contacts, isInvalid}});
      }
    }

    // Some strings and sections may differ depending on whether ticket is for a case or an expert session.
    function initFormForTicketType() {
      const ticketType = vm.supportTicket.isExpertSession() ? 'expert' : 'case';
      _.assign(vm, {
        emailDescriptionKey: `widgets.support.supportTicketContactsForm.ticketType.${ticketType}.emailDescription`,
        phoneNumberDescriptionKey: `widgets.support.supportTicketContactsForm.ticketType.${ticketType}.phoneNumberDescription`,
        timezoneDescriptionKey: `widgets.support.supportTicketContactsForm.ticketType.${ticketType}.timezoneDescription`,
        workingHoursDescriptionKey: vm.supportTicket.isSupportCase()
          ? `widgets.support.supportTicketContactsForm.ticketType.case.workingHoursDescription`
          : undefined,
      });
    }
  }
})();
