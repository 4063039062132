(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportHelpTopics
   * @description renders the Popular Help Topics header, go URL and topic list. The topic list
   *   should be transcluded into the component.
   *
   * @binding {String} helpGoUrl - the go URL to use in the "Browse Adobe Support..." link
   */
  angular.module('app.widgets.support').component('appSupportHelpTopics', {
    bindings: {
      helpGoUrl: '@',
    },
    controller,
    templateUrl: 'app/widgets/support/support-help-topics/support-help-topics.component.html',
    transclude: true,
  });

  /* @ngInject */
  function controller(_, feature, onesieSrc2) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      feature,
      SupportHelpTopics: onesieSrc2.support.components.SupportHelpTopics,
    });

    function $onInit() {
      _.assign(vm, {
        goUrls: [
          {
            class: 'support-articles',
            label: 'supportArticles',
            name: vm.helpGoUrl,
          },
          {
            class: 'community-forum',
            label: 'communityForum',
            name: 'entcom',
          },
        ],
      });
    }
  }
})();
