(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.main').config(routeConfig);

  /* @ngInject */
  function routeConfig(
    $locationProvider,
    $stateProvider,
    $urlRouterProvider,
    _,
    APP_SHELL_NAV_ID,
    BUMPER_TYPE,
    MAIN_TITLE_KEY,
    onesieSrc2
  ) {
    $locationProvider.html5Mode(true).hashPrefix('!');

    $stateProvider.state('app', {
      abstract: true,
      component: 'appShell',
      data: {
        title: MAIN_TITLE_KEY,
      },
      resolve: {
        appReadyProvider(appReady) {
          return appReady.promise;
        },
        shellNavItemListId: () => APP_SHELL_NAV_ID,
      },
    });

    $stateProvider.state('org', {
      abstract: true,
      parent: 'app',
      url: '/{orgId:[^/]+@[^/]+}',
    });

    $stateProvider.state('org-not-found', {
      component: 'binkyBumper',
      data: {
        title: 'main.routes.orgNotFound.title',
      },
      resolve: {
        /* @ngInject */
        activeOrgId: (OrganizationManager) => OrganizationManager.getActiveOrgId(),
        details: _.constant('main.routes.orgNotFound.details'),
        /* @ngInject */
        linkedUserAccounts: (OrganizationManager) =>
          OrganizationManager.getLinkedUserAccounts().items,
        /* @ngInject */
        onOrgChange: ($window, AuthenticatedUser, authentication) => [
          'orgId',
          'userId',
          (orgId, userId) => {
            const redirectPathSegment = $window.location.pathname + $window.location.search;
            switchTo(
              $window,
              AuthenticatedUser,
              authentication,
              orgId,
              userId,
              redirectPathSegment
            );
          },
        ],
        showOrgSwitcher: _.constant(true),
        titleKey: _.constant('main.routes.orgNotFound.title'),
      },
    });

    $stateProvider.state('not-found', {
      component: 'binkyBumper',
      data: {
        title: 'main.routes.notFound.title',
      },
      resolve: {
        details: _.constant('main.routes.notFound.details'),
        titleKey: _.constant('main.routes.notFound.title'),
      },
    });

    $stateProvider.state('unable-to-determine-user', {
      component: 'binkyBumper',
      data: {
        title: 'main.routes.unableToDetermineUser.title',
      },
      resolve: {
        button: _.constant('main.routes.unableToDetermineUser.primaryButton'),
        details: _.constant('main.routes.unableToDetermineUser.details'),
        onButtonClick: ($window) => {
          $window.open(
            'https://helpx.adobe.com/manage-account/kb/account-password-sign-help.html',
            '_blank'
          );
        },
        onSecondaryButtonClick: (authentication) => () => {
          authentication.signOut();
        },
        secondaryButton: _.constant('main.routes.unableToDetermineUser.secondaryButton'),
        titleKey: _.constant('main.routes.unableToDetermineUser.title'),
      },
    });

    $stateProvider.state('unauthorized', {
      component: 'binkyBumper',
      data: {
        title: 'main.routes.unauthorized.title',
      },
      resolve: {
        details: _.constant('main.routes.unauthorized.details'),
        onSecondaryButtonClick: (authentication) => () => {
          authentication.signOut();
        },
        secondaryButton: _.constant('main.routes.unauthorized.secondaryButton'),
        titleKey: _.constant('main.routes.unauthorized.title'),
      },
    });

    $stateProvider.state('unplanned-outage', {
      component: 'binkyBumper',
      data: {
        title: 'main.routes.unplannedOutage.title',
      },
    });

    $stateProvider.state('migrating', getMigratingDefinition('migrating'));
    $stateProvider.state('legacy-to-ac-migrating', getMigratingDefinition('legacyToACMigrating'));
    $stateProvider.state('t2e-migrating', getMigratingDefinition('t2eMigrating'));
    $stateProvider.state('vipmp-migrating', getMigratingDefinitionWithOrgName('vipmpMigrating'));
    $stateProvider.state('vip2direct-migrating', getMigratingDefinition('vip2directMigrating'));

    $stateProvider.state('accept-terms', {
      component: 'appTermsAndConditions',
      data: {
        title: 'widgets.termsAndConditions.accept',
      },
      params: {orgId: {}},
      url: '/accept-terms',
    });

    $urlRouterProvider.otherwise(($injector) => {
      const appReady = $injector.get('appReady');
      appReady
        .whenRunReady()
        .then(() => {
          const feature = $injector.get('feature');
          const externalAuthService = onesieSrc2.settings.services.ExternalAuthService;
          const isLoginInProgress = externalAuthService.isLoginInProgress();

          // If isLoginInProgress is true, then we are in the middle of an IDP login.
          // That case is now handled in src2/app/core/settings/utils/externalLoginRedirectLoader.js
          if (!isLoginInProgress) {
            const $state = $injector.get('$state');

            if (feature.isDisabled('fix_redirect_overview')) {
              redirectToOverview();
              return;
            }

            // Make sure there's no pending transition before starting another one,
            //   e.g. 'migrating' which redirects to migration bumper.
            // $state.transition is undefined when there's no pending transition,
            //   but check isActive() to be sure.
            if (!_.invoke($state, 'transition.isActive')) {
              redirectToOverview();
            }
          }
        })
        .catch((error) => {
          const $log = $injector.get('$log');
          $log.info('Failed to load the application', error);
        });

      function redirectToOverview() {
        const $state = $injector.get('$state');
        const orgReady = $injector.get('orgReady');
        // eslint-disable-next-line promise/catch-or-return
        orgReady.whenRunReady().then((activeOrg) => {
          $state.go('overview.overview2', {
            orgId: activeOrg.id,
          });
        });
      }
    });

    ////////
    function getMigratingDefinition(path) {
      return {
        component: 'binkyBumper',
        data: {
          title: `main.routes.${path}.title`,
        },
        resolve: {
          details: _.constant(`main.routes.${path}.details`),
          icon: _.constant('settings'),
          /* @ngInject */
          linkedUserAccounts: (feature, OrganizationManager) =>
            OrganizationManager.getLinkedUserAccounts().items,
          /* @ngInject */
          onOrgChange: ($window, AuthenticatedUser, authentication) => [
            'orgId',
            'userId',
            (orgId, userId) => {
              switchTo($window, AuthenticatedUser, authentication, orgId, userId);
            },
          ],
          showOrgSwitcher: _.constant(true),
          titleKey: _.constant(`main.routes.${path}.title`),
          type: _.constant(BUMPER_TYPE.CUSTOM),
        },
      };
    }

    function getMigratingDefinitionWithOrgName(path) {
      return {
        component: 'binkyBumper',
        data: {
          title: `main.routes.${path}.title`,
        },
        resolve: {
          details: _.constant(`main.routes.${path}.details`),
          icon: _.constant('settings'),
          /* @ngInject */
          linkedUserAccounts: (feature, OrganizationManager) =>
            OrganizationManager.getLinkedUserAccounts().items,
          /* @ngInject */
          onOrgChange: ($window, AuthenticatedUser, authentication) => [
            'orgId',
            'userId',
            (orgId, userId) => {
              switchTo($window, AuthenticatedUser, authentication, orgId, userId);
            },
          ],
          showOrgSwitcher: _.constant(true),
          titleKey: _.constant(`main.routes.${path}.title`),
          translateValues: ['OrganizationList', (OrganizationList) => getOrgName(OrganizationList)],
          type: _.constant(BUMPER_TYPE.CUSTOM),
        },
      };
    }

    function getOrgName(organizationList) {
      const activeOrg = organizationList.get().activeOrg;
      const orgName = _.get(activeOrg, 'name');
      return {
        title: {
          orgName,
        },
      };
    }

    function switchTo(
      $window,
      AuthenticatedUser,
      authentication,
      orgId,
      userId,
      redirectPathSegment = '/overview'
    ) {
      const authenticatedUser = AuthenticatedUser.get();
      if (userId === authenticatedUser.getId()) {
        const redirectUrl = `${$window.location.protocol}//${$window.location.hostname}${
          $window.location.port ? `:${$window.location.port}` : ''
        }/${orgId}${redirectPathSegment}`;
        // we force a full reload here, as the application did not complete loading
        // and so we don't know what state it may be in
        $window.location.href = redirectUrl;
      } else {
        const redirectPath = `${orgId}${redirectPathSegment}`;
        authentication.switchTo(userId, redirectPath);
      }
    }
  }
})();
