(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.main.global-modals-manager:GlobalModalsManager
   * @description
   */
  angular
    .module('app.main.global-modals-manager')
    .factory('globalModalsManager', GlobalModalsManager);

  /* @ngInject */
  function GlobalModalsManager($rootScope, _, GLOBAL_MODALS) {
    const service = {
      open,
    };

    return service;

    //////////////

    /**
     * @description Initialize and open a new modal. The modal component must have the required
     * bindings: modalId, onInitialized, onClosed and will be opened as a global-modal.
     * See component appGlobalModals for more details.
     *
     * @param {Object} options Initialization Object (params described below)
     * @param {List} [options.componentBindings] A list of component bindings
     * @param {String} options.componentName The component to be rendered
     * @param {Object} [options.modalParams] - Optional params pertaining to the modal to open.
     *
     * @example
     * To open appAddUserModal without global modal manager:
     * <app-add-user-modal modal-id="custom-add-user-modal-id" page-context="pageContext"></app-add-user-modal>
     * panelManager.open('add-admin-modal');
     *
     * The equivalent with global modal manager:
     * globalModalsManager.open({
     *     componentBindings: [
     *         {
     *           attributeName: 'modalId',
     *           type: GLOBAL_MODAL_BINDING_TYPE.STRING,
     *           value: 'custom-add-user-modal-id',
     *         },
     *         {
     *           attributeName: 'pageContext',
     *           type: GLOBAL_MODAL_BINDING_TYPE.DATA,
     *           value: new PageContext({
     *             target: PAGE_TARGET.ORGANIZATION,
     *             targetId: OrganizationList.get().activeOrg.id,
     *             targetType: PAGE_TARGET_TYPE.USER,
     *           }),
     *         },
     *       ],
     *     componentName: 'appAddUserModal',
     *     modalParams: {
     *       tag: PAGE_TARGET_TYPE.DEVELOPER
     *     }
     *   });
     */
    function open({componentBindings, componentName, modalParams}) {
      verifyValidInput(componentBindings, componentName);

      $rootScope.$broadcast(GLOBAL_MODALS.OPEN, {
        componentBindings,
        componentName,
        modalParams,
        version: 2,
      });
    }

    // Private functions
    function verifyValidInput(componentBindings, componentName) {
      if (!componentName) {
        throw new Error('Component Name is not defined.');
      }

      const requiredFields = ['attributeName', 'type', 'value'];

      _.forEach(componentBindings, (componentBinding) => {
        const hasMissingField = _.some(requiredFields, (requiredField) =>
          _.isUndefined(componentBinding[requiredField])
        );
        if (hasMissingField) {
          throw new Error('Required fields are missing.');
        }
      });
    }
  }
})();
