// Load libraries used by the Angular app
import './angularDependencies';

// Load the Angular app's CSS, JS and HTML modules
import '../../../../src/index.scss';
/* eslint-disable eslint-comments/no-unlimited-disable -- Hack to prevent different lint errors
depending on whether the file has been generated yet */
/* eslint-disable -- see above */
import '../../../../.tmp/angularSrcIndex';
/* eslint-enable */

import {storePersistentData} from '@admin-tribe/binky';
import Coral from '@adobe/coral-spectrum/dist/js/coral';
import angular from 'angular';

import angularTemplateCacheLoader from './angularTemplateCacheLoader';

// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
if (window.isBrowserSupported) {
  // Start the Angular app
  angular.module('app').run(angularTemplateCacheLoader);

  document.addEventListener('DOMContentLoaded', () => {
    Coral.commons.ready(async () => {
      const bootstrapTarget = document.querySelector('.angular-app'); // This must match the "angularRoot" configuration value
      // when the e2e user is running, we expect that the protractor tests are inserting
      // a module here between bootstrap and appFakes
      const {mockUserId} = storePersistentData(['mockUserId']);
      if (mockUserId) {
        try {
          const {bootstrapFakes} = await import(
            /* webpackChunkName: "fakes" */ '../../../../fakes/FakesBootstrap'
          );
          await bootstrapFakes();
          angular.bootstrap(bootstrapTarget, [mockUserId === 'e2eUser' ? 'appE2E' : 'appFakes']);
        } catch (error) {
          // eslint-disable-next-line no-console -- no other means of reporting this error
          console.error('error dynamically loading fakes', error);
        }
      } else {
        angular.bootstrap(bootstrapTarget, ['app']);
      }
    });
  });
}
