import {OverlayWait} from '@admin-tribe/binky-ui';
import {Content, Flex, IllustratedMessage, Link, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import AlertCircleIcon from '@spectrum-icons/workflow/AlertCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Pod component to layout the basic overview page components.
 */
const OverviewPod = ({
  children,
  columnSpan,
  'data-testid': dataTestId,
  dividerPlacement = DividerPlacement.NONE,
  errorMessage,
  isHidden,
  isLoading = false,
  onReload,
  rightHeaderContent,
  title,
  tooltipDescription,
  headingLevel = 2,
  minHeight,
}) => {
  const colspan = {...{base: 1, L: 1, M: 1, S: 1}, ...columnSpan};
  const hideContent = {...{base: false, L: false, M: false, S: false}, ...isHidden};
  const headingId = useId();
  const tooltipIconSize = 'S';
  const intl = useIntl();

  return (
    <View
      data-testid={dataTestId}
      elementType="section"
      gridColumn={{
        base: `span ${colspan.base}`,
        L: `span ${colspan.L}`,
        M: `span ${colspan.M}`,
      }}
      isHidden={hideContent}
    >
      {title && (
        <TitledSection
          dividerPlacement={dividerPlacement}
          headingLevel={headingLevel}
          id={headingId}
          rightHeaderContent={rightHeaderContent}
          title={title}
          tooltipDescription={tooltipDescription}
          tooltipIconSize={tooltipIconSize}
        >
          <OverlayWait isLoading={isLoading} showContent size="S">
            <View
              backgroundColor="gray-50"
              borderColor="gray-200"
              borderRadius="medium"
              borderWidth="thin"
              minHeight={minHeight}
              padding="size-150"
            >
              {errorMessage ? (
                <Flex
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  minHeight={minHeight}
                >
                  <IllustratedMessage>
                    <AlertCircleIcon aria-label={errorMessage} margin={0} size="L" />
                    <Content>{errorMessage}</Content>
                  </IllustratedMessage>
                  <Link onPress={() => onReload?.()}>
                    {intl.formatMessage({id: 'common.buttons.reload'})}
                  </Link>
                </Flex>
              ) : (
                children
              )}
            </View>
          </OverlayWait>
        </TitledSection>
      )}

      {!title && (
        <OverlayWait isLoading={isLoading} showContent size="S">
          {children}
        </OverlayWait>
      )}
    </View>
  );
};

OverviewPod.propTypes = {
  /**
   * Pod content to be displayed.
   */
  children: PropTypes.node.isRequired,
  /**
   * Responsive props instructing pod on the number of columns to span at
   * various display sizes. Optional, default is to span one column across all
   * breakpoints
   */
  columnSpan: PropTypes.shape({
    base: PropTypes.number,
    L: PropTypes.number,
    M: PropTypes.number,
    S: PropTypes.number,
  }),
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
  /**
   * Location of the divider in the overview pod. Defaults to none.
   */
  dividerPlacement: PropTypes.string,
  /**
   * Error message string to render, hides error message if null. Default is null.
   */
  errorMessage: PropTypes.string,
  /**
   * Level for the header. Default is 2.
   */
  headingLevel: PropTypes.number,
  /**
   * Responsive props instructing pod how to show/hide at various display sizes.
   * Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
  /**
   * Whether the data is loading. Will render an OverlayWait spinner. Default is false.
   */
  isLoading: PropTypes.bool,
  /**
   * Min height for the inner view. Default is undefined.
   */
  minHeight: PropTypes.string,
  /**
   * Callback when there is an error and reload is clicked
   */
  onReload: PropTypes.func,
  /**
   * Content to be displayed in the right header of the pod title.
   * Used when passing a title
   */
  rightHeaderContent: PropTypes.node,
  /**
   * Title to be displayed at the top of the overview pod.
   * Will render the content in a titled section if passed.
   */
  title: PropTypes.string,
  /**
   * Content for tooltip to be placed near title (meant to supply additional
   * info on section to users, if no tooltip needed, can omit prop)
   */
  tooltipDescription: PropTypes.string,
};

export default OverviewPod;
