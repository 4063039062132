(function () {
  /**
   * @deprecated with temp_react_port_jobs
   *
   * @ngdoc component
   * @name jobList
   * @description Component which displays a list of jobs
   *
   * @binding {JobList} jobList - list of jobs to display
   * @binding {Object} pageContext - the pageContext controlling bulk operations
   *   to fetch/display
   * @binding {Selection} selection - Object to track/store selected jobs
   */
  angular.module('app.widgets.bulk-operations').component('appJobListTable', {
    bindings: {
      jobList: '<',
      pageContext: '<',
      selection: '<',
    },
    controller,
    templateUrl: 'app/widgets/bulk-operations/jobs/job-list-table/job-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $rootScope,
    $timeout,
    $uiRouterGlobals,
    _,
    JOB_DETAILS_DRAWER_ID,
    MESSAGE,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        isAllChecked: false,
        isChecked,
        onCheckChanged,
        onClickCheckAll,
        onClickRow,
        orgId: $uiRouterGlobals.params.orgId,
      });

      vm.unregisterJobListRefreshListener = $rootScope.$on(
        MESSAGE.REFRESH.JOBLIST,
        updateHeaderCheckbox
      );
    }

    function $onDestroy() {
      vm.unregisterJobListRefreshListener();
    }

    function isChecked(job) {
      return vm.selection.isItemSelected(job);
    }

    function onCheckChanged(job) {
      vm.selection.toggleItemSelection(job);

      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    function onClickCheckAll() {
      if (vm.isAllChecked) {
        vm.selection.deselectAllItems();
      } else {
        vm.selection.selectItems(vm.jobList.getDeletableJobs());
      }

      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    function onClickRow($event, job) {
      if (wasClickOnReservedElement($event)) {
        return;
      }
      vm.selectedJob = job;
      panelManager.open(JOB_DETAILS_DRAWER_ID);
    }

    ///////////////

    function wasClickOnReservedElement($event) {
      // there are several elements in a job list table row that have their own
      // click or link behavior. in order to allow for these cases, while also
      // having any "row" click open up the details drawer, we need to detect and
      // prevent default handling
      if ($event.target) {
        const targetEl = angular.element($event.target);
        // allow operation "quick link" to line-by-line results
        // allow "cancel processing" link
        // allow "checkbox" actions
        return (
          targetEl.hasClass('operation') ||
          targetEl.hasClass('action-anchor') ||
          targetEl.hasClass('check') ||
          targetEl[0].tagName === 'INPUT'
        );
      }
      return false;
    }

    function updateHeaderCheckbox() {
      const numJobsChecked = vm.selection.count;
      const numJobsCheckable = vm.jobList.getDeletableJobs().length;

      vm.isAllChecked = numJobsChecked > 0 && numJobsChecked === numJobsCheckable;
      vm.isSubsetChecked = numJobsChecked > 0 && numJobsChecked < numJobsCheckable;
    }
  }
})();
