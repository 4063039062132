import {PageBanner, PageBannerConstants} from '@admin-tribe/binky-ui';
import {GoUrl} from '@pandora/react-go-url';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

/**
 * The banner for Domain Enforcement enablement warning, displayed in the Settings page
 */
const DomainEnforcementSettingsBanner = () => {
  const intl = useIntl();

  return (
    <PageBanner
      header={intl.formatMessage({
        id: `settings.domainEnforcement.settingsBanner.title`,
      })}
      showDefaultButton={false}
      variant={PageBannerConstants.variant.WARNING}
    >
      <FormattedMessage
        id="settings.domainEnforcement.settingsBanner.description"
        values={{
          goUrl: (linkText) => <GoUrl name="aac_restricting_domains">{linkText}</GoUrl>,
        }}
      />
    </PageBanner>
  );
};

export default DomainEnforcementSettingsBanner;
