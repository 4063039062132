import {
  DetailsButton,
  Page,
  PageActions,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageNav,
  getProductDisplayName,
} from '@admin-tribe/binky-ui';
import {Heading, IllustratedMessage} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {getProductTags} from 'core/products/utils/productTagUtils';
import ProductDetailsDrawer from 'features/products/components/product-details-drawer/ProductDetailsDrawer';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';

/**
 *  The Org Delegatable Unlimited Product Page component.
 */
const OrgDelegatableUnlimitedProductPage = () => {
  const intl = useIntl();
  const {product} = useLoaderData();
  const productTags = useMemo(() => getProductTags(intl, product), [intl, product]);
  const orgUnlimitedFi = product.fulfillableItemList.getOrgDelegatableUnlimitedItem();
  const productName = orgUnlimitedFi.longName;

  const title = getProductDisplayName(intl, product);
  useDocumentTitle({defaultTitle: title});

  return (
    <Page data-testid="org-delegatable-unlimited-product-page">
      <ProductPageBreadcrumbs
        productDisplayName={productName}
        productOrProductGroupId={product.id}
      />
      <PageHeader title={title}>
        <ImageIcon alt="" size="XL" src={product.getIcon()} />
        <PageHeaderTagGroup productName={productName} tags={productTags} />
      </PageHeader>
      <PageActions>
        <DetailsButton detailsDrawer={<ProductDetailsDrawer product={product} />} />
      </PageActions>
      <PageNav />
      <PageContent>
        <IllustratedMessage marginTop="size-100">
          <Heading level="2">
            <FormattedMessage
              id="products.details.orgDelegatableUnlimited.description"
              values={{
                br: <br />,
                productName,
              }}
            />
          </Heading>
        </IllustratedMessage>
      </PageContent>
    </Page>
  );
};

export default OrgDelegatableUnlimitedProductPage;
