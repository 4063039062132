import {Flex, Link, Text} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';

// renders a "Popular article" in the Helpful Resources section of the Overview page.
const ArticleListItem = ({children, href}) => (
  <Link flexGrow={1} variant="secondary">
    <a href={href} rel="noreferrer" target="_blank">
      <Flex flexGrow={1} gap="size-150">
        <Text
          flexGrow={1}
          UNSAFE_style={{
            fontSize: 'var(--spectrum-global-dimension-font-size-100)',
            lineHeight: 'var(--spectrum-alias-line-height-small)',
            whiteSpace: 'break-spaces',
          }}
        >
          {children}
        </Text>
        <LinkOutIcon flexShrink={0} size="S" />
      </Flex>
    </a>
  </Link>
);

ArticleListItem.propTypes = {
  /**
   * The label for the link / article
   */
  children: PropTypes.string.isRequired,
  /**
   * The link to open on click
   */
  href: PropTypes.string.isRequired,
};

export default ArticleListItem;
