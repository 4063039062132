import {TBody} from '@react/react-spectrum/Table';
import {observer} from 'mobx-react-lite';
import React from 'react';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const TableBody = observer(({children}) => <TBody>{children}</TBody>);

export default TableBody;
