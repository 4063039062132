import {Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';

import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import AvailableProducts from './available-products/AvailableProducts';
import {getManagementSettings} from './productSelectionPageUtils';
import SelectedProducts from './selected-products/SelectedProducts';

// Screen of Create Package flow to select plugins in managed package workflow
const ProductSelectionPage = observer(() => {
  const {createPackageModalStore} = useCreatePackageModalContext();

  const isEnterpriseSupportPolicyApplicable =
    PackagesEntitlements.isEnterpriseOrg &&
    PackagesEntitlements.hasCcOrDcEntitlement &&
    createPackageModalStore.pkgSessionSettings.showExtraProductsInTemplates;

  const updateManagementSettings = useCallback(() => {
    const managementSettings = getManagementSettings({
      adobeProductsUi: createPackageModalStore.adobeProductsUI,
      packageCreateObj: createPackageModalStore.packageCreateObj,
      pkgSessionSettings: createPackageModalStore.pkgSessionSettings,
    });
    createPackageModalStore.setPkgSessionSettingValues(
      'enableAalExtension',
      managementSettings.isAalExtensionEnabled
    );
    createPackageModalStore.setPkgSessionSettingValues(
      'nextButtonEnabled',
      managementSettings.nextButtonEnabled
    );
    createPackageModalStore.setPackageCreateObjValues(
      'rumEnabled',
      managementSettings.isRumEnabled
    );
    createPackageModalStore.setPackageCreateObjValues(
      'enableExtenstions',
      managementSettings.areExtensionsEnabled
    );
  }, [createPackageModalStore]);

  // refs
  const selectedProductsSearchFieldRef = useRef(null);

  return (
    <Flex
      alignItems="center"
      gap="size-500"
      justifyContent="center"
      UNSAFE_style={{minWidth: 'fit-content'}}
      width="100%"
    >
      <AvailableProducts
        isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
        selectedProductsSearchFieldRef={selectedProductsSearchFieldRef}
        updateManagementSettings={updateManagementSettings}
      />
      <SelectedProducts
        isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
        selectedProductsSearchFieldRef={selectedProductsSearchFieldRef}
        updateManagementSettings={updateManagementSettings}
      />
    </Flex>
  );
});

ProductSelectionPage.propTypes = {};
export default ProductSelectionPage;
