(function () {
  /**
   * @deprecated. Use src2 useBulkOperations.
   * This file and whole module can be removed with temp_standalone_quick_assign
   */
  angular
    .module('app.widgets.bulk-operations.context')
    .factory('bulkOperationContext', getBulkOperationContextService);

  /* @ngInject */
  function getBulkOperationContextService(
    $q,
    $translate,
    _,
    BULK_OPERATION_MODE,
    bulkOperationSamples,
    directoryAccess,
    DISPLAY_MESSAGE_VARIANT,
    DisplayMessage,
    DomainList,
    jilProducts,
    jilUserGroups,
    jilUsers,
    OrganizationManager,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    ProductConfigurationList,
    t2eMigrationManager
  ) {
    const service = {
      initialize,
    };
    const NUM_LICENSES_SHORT_DESCRIPTION = 1000;

    return service;

    ////////////

    /**
     * @description Method to initialize the bulk operation context.
     *
     * @param {string} mode The bulk operation mode to initialize for.
     * @param {PageContext} pageContext The page context for this modal, containing type, target, etc.
     * @returns {Object} an object with the configured context
     */
    function initialize(mode, pageContext) {
      const response = {};
      // we register the sampleContext here as the pageContext may be overridden in some cases
      const sampleContext = _.cloneDeep(pageContext);

      if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE) {
        response.$domainsPromise = DomainList.get({
          orgId: OrganizationManager.getActiveOrgId(),
        }).$promise.then((domainList) =>
          _.filter(
            domainList.items,
            (domain) =>
              // Only keep domains that are active or trusted
              domain.status === 'ACTIVE' || domain.ownershipStatus === 'TRUSTED'
          )
        );
      }

      let restrictAddingT1 = false;
      return directoryAccess
        .canCreateType2Or3()
        .then((canCreateType2Or3) => {
          if (canCreateType2Or3) {
            restrictAddingT1 = true;
          }
        })
        .then(processPageContext)
        .then(processModalState)
        .then(processSampleCsv)
        .then(() => response);

      function processPageContext() {
        if (pageContext) {
          switch (pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              switch (pageContext.target) {
                case PAGE_TARGET.ORGANIZATION:
                  _.assign(response, {
                    exportFunction: 'export',
                    exportResource: jilUsers.users,
                    uploadResource: jilUsers.users,
                  });

                  break;
                case PAGE_TARGET.USER_GROUP:
                  _.assign(response, {
                    exportFunction: 'exportUgUsers',
                    exportParams: {groupId: pageContext.targetId},
                    exportResource: jilUserGroups.userGroupUsersList,
                    uploadParams: {userGroupId: pageContext.targetId},
                    uploadResource: jilUserGroups.userGroupUsersList,
                  });

                  break;
                case PAGE_TARGET.PRODUCT:
                  return processProductState();
                case PAGE_TARGET.PRODUCT_CONFIGURATION:
                  processProductProfileState(pageContext.targetId, pageContext.targetParentId);

                  break;
                default:
                  // other targets are not yet supported
                  break;
              }
              break;
            case PAGE_TARGET_TYPE.USER_GROUP:
              _.assign(response, {
                exportFunction: 'export',
                exportResource: jilUserGroups.userGroups,
                uploadResource: jilUserGroups.userGroups,
              });

              break;
            default:
              // other target types are not yet supported
              break;
          }
        }
        return $q.resolve();
      }

      function processModalState() {
        switch (mode) {
          case BULK_OPERATION_MODE.ADD:
            if (_.get(pageContext, 'targetType') === PAGE_TARGET_TYPE.USER_GROUP) {
              _.assign(response, {
                modalDescriptionKey: 'widgets.bulkOperations.addUserGroups.description',
                modalTitleKey: 'widgets.bulkOperations.addUserGroups.title2',
                uploadFunction: 'bulkAdd',
              });
            } else {
              return processAddNonUserGroup();
            }
            break;
          case BULK_OPERATION_MODE.EDIT:
            if (_.get(pageContext, 'targetType') === PAGE_TARGET_TYPE.USER_GROUP) {
              _.assign(response, {
                modalDescriptionKey: 'widgets.bulkOperations.editUserGroups.description',
                modalTitleKey: 'widgets.bulkOperations.editUserGroups.title2',
                uploadFunction: 'bulkEdit',
              });
            } else {
              _.assign(response, {
                modalDescriptionKey: 'widgets.bulkOperations.editUserDetails.description',
                modalTitleKey: 'widgets.bulkOperations.editUserDetails.title2',
                uploadFunction: 'bulkEdit',
              });
            }
            break;
          case BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION:
            _.assign(response, {
              modalDescriptionKey: 'widgets.bulkOperations.offerSwitchMigration.description',
              modalTitleKey: 'widgets.bulkOperations.offerSwitchMigration.title',
              uploadFunction: 'bulkOfferSwitchMigration',
            });

            break;
          case BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE:
            // sample and exportFunction override the values set earlier in processPageContext()
            _.assign(response, {
              exportFunction: 'exportCSVSwitch',
              infoBanner: t2eMigrationManager.isT2EClean()
                ? new DisplayMessage({
                    body: $translate.instant(
                      'widgets.bulkOperations.editIdentityType.noT2eConvertBody'
                    ),
                    header: $translate.instant(
                      'widgets.bulkOperations.editIdentityType.noT2eConvertHeader'
                    ),
                    showDefaultButton: false,
                    variant: DISPLAY_MESSAGE_VARIANT.INFO,
                  })
                : undefined,
              modalDescriptionKey: 'widgets.bulkOperations.editIdentityType.description',
              modalTitleKey: 'widgets.bulkOperations.editIdentityType.title2',
              uploadFunction: 'bulkSwitchIdentity',
            });

            break;
          case BULK_OPERATION_MODE.REMOVE:
            // sample overrides the value set earlier, to show T1 users can be removed.
            _.assign(response, {
              modalDescriptionKey: 'widgets.bulkOperations.remove.description',
              modalTitleKey: 'widgets.bulkOperations.remove.title2',
              uploadFunction: 'bulkRemove',
            });

            break;
          case BULK_OPERATION_MODE.UNASSIGN:
            // sample overrides the value set earlier, to show T1 users can be unassigned.
            _.assign(response, {
              modalDescriptionKey: 'widgets.bulkOperations.unassign.description',
              modalTitleKey: 'widgets.bulkOperations.unassign.title2',
              uploadFunction: 'bulkRemove',
            });

            break;
          default:
            break;
        }
        return $q.resolve();
      }

      function processProductState() {
        const productList = OrganizationManager.getProductsForActiveOrg();
        const product = _.find(productList.items, {id: pageContext.targetId});
        if (product && product.isTeam()) {
          // for Team products we need to select the default profile ID in order to set roles
          const profileList = ProductConfigurationList.get(product, 1);
          return profileList.$promise.then(() => {
            const profileId = profileList.items[0].id;
            processProductProfileState(profileId, pageContext.targetId);
            // for team products, we alter the sample to behave as a product profile
            _.assign(sampleContext, {
              target: PAGE_TARGET.PRODUCT_CONFIGURATION,
              targetId: profileId,
              targetParentId: pageContext.targetId,
            });
          });
        }
        return $q.resolve();
      }

      function processProductProfileState(targetId, targetParentId) {
        _.assign(response, {
          exportFunction: 'export',
          exportParams: {
            groupId: targetId,
            productId: targetParentId,
          },
          exportResource: jilProducts.licenseGroupUsers,
          uploadParams: {
            licenseGroupId: targetId,
            productId: targetParentId,
          },
          uploadResource: jilProducts.licenseGroupUsers,
        });
      }

      function processSampleCsv() {
        response.getSample = () => {
          if (response.sample) {
            return $q.resolve(response.sample);
          }
          // if a sample has not previously been fetched, we fetch it now
          // we do this as a promise as it may need to fetch state to do it
          return (
            bulkOperationSamples
              // we use the sampleContext here as the pageContext may be overridden in some cases
              .getSample(mode, sampleContext, restrictAddingT1)
              .then((sampleCsv) => {
                if (!_.isNil(sampleCsv)) {
                  response.sample = sampleCsv;
                }
                return response.sample;
              })
          );
        };
      }

      function processAddNonUserGroup() {
        let modalDescriptionKey;
        const promises = [];

        const productList = OrganizationManager.getProductsForActiveOrg();

        const productPromise = productList.$promise
          .then(() => {
            // if there are less than 1000 licenses, use shorter description
            const numLicenses = productList.getSeatBasedAssignableLicenseCount();
            modalDescriptionKey =
              numLicenses < NUM_LICENSES_SHORT_DESCRIPTION
                ? 'widgets.bulkOperations.add.shortDescription'
                : 'widgets.bulkOperations.add.description';
          })
          .catch(() => {
            modalDescriptionKey = 'widgets.bulkOperations.add.description';
          });

        promises.push(productPromise);

        return $q.all(promises).then(() => {
          _.assign(response, {
            modalDescriptionKey,
            modalTitleKey: 'widgets.bulkOperations.add.title2',
            uploadFunction: 'bulkAdd',
          });
        });
      }
    }
  }
})();
