(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ContractOwnerInvite
   * @description Model for a ContractOwnerInvite. Used for sending contract owner change invitations
   */
  angular.module('app.core.invite').factory('ContractOwnerInvite', ContractOwnerInviteModel);

  /* @ngInject */
  function ContractOwnerInviteModel(
    $q,
    $rootScope,
    _,
    jilOrganizationsContractInvitations,
    MESSAGE
  ) {
    class ContractOwnerInvite {
      constructor(options = {}) {
        const {contractId, invitationCode, userId} = options;
        _.assign(this, {contractId, invitationCode, userId});
      }

      revoke() {
        this.$resolved = false;

        this.$promise = jilOrganizationsContractInvitations.revokeInvitation
          .delete({
            contractId: this.contractId,
            invitationCode: this.invitationCode,
          })
          .$promise.then(onSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;

        ////////

        function onSuccess() {
          $rootScope.$emit(MESSAGE.UPDATE.CONTRACTOWNER);
          return this;
        }
      }

      // Sends a invitation to change the contract owner.
      save() {
        this.$resolved = false;
        const deferred = $q.defer();
        this.$promise = deferred.promise;

        // https://wiki.corp.adobe.com/display/BPS/Send+Organization+Contract+Invitation
        jilOrganizationsContractInvitations.changeOwnerInvitation.save(
          {contractId: this.contractId},
          {context: {userId: this.userId}, typeCode: 'OC_TEAM_INVITE_PRIMARY'},
          onSuccess.bind(this),
          onError.bind(this)
        );
        return this.$promise;

        ////////

        function onError(response) {
          this.$resolved = true;
          deferred.reject(response);
        }

        function onSuccess() {
          $rootScope.$emit(MESSAGE.UPDATE.CONTRACTOWNER);
          // set $resolved attribute to true and resolve promise
          this.$resolved = true;
          deferred.resolve(this);
        }
      }
    }

    return ContractOwnerInvite;
  }
})();
