(function () {
  /**
   * @deprecated use src2
   */
  angular
    .module('app.core.storage-quota')
    .constant('STORAGE_QUOTA_TYPE', {
      HARD: 'hard',
      SOFT: 'soft',
    })
    .constant('STORAGE_QUOTA_UNIT', {
      BYTES: 'BYTES',
      GIGABYTES: 'GIGABYTES',
      KILOBYTES: 'KILOBYTES',
      MEGABYTES: 'MEGABYTES',
      TERABYTES: 'TERABYTES',
    });
})();
