(function () {
  /**
   * @ngdoc module
   * @name app.core.contract.migrations
   * @description Module which defines contract-related migration models
   */
  angular.module('app.core.contract.migrations', [
    'app.core.api.jil',
    'binky.core.common.list',
    'binky.core.contract.migrations',
    'binky.core.lodash',
    'binky.core.product',
  ]);
})();
