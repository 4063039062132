(function () {
  /**
   * @ngdoc module
   * @name app.core.stock
   * @description Module which defines stock models
   */
  angular.module('app.core.stock', [
    'app.core.api.stock',
    'app.core.model-cache',
    'binky.core.common.list',
    'binky.core.lodash',
  ]);
})();
