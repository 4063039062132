import {feature} from '@admin-tribe/binky';
import {WorkspaceNav} from '@admin-tribe/binky-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {canManageTickets} from 'core/support/supportAccess';
import useSupportPaths from 'features/support/hooks/useSupportPaths';

/** Renders the workspace nav for Support */
const SupportWorkspaceNav = () => {
  const intl = useIntl();
  const {expertSessions, supportCases, supportSummary} = useSupportPaths();

  const navItems = useMemo(() => {
    const items = [];

    if (!canManageTickets()) {
      return items;
    }

    items.push({
      href: supportSummary(),
      name: intl.formatMessage({id: 'support.supportTicket.supportSummary.title'}),
      order: 0,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-support-summary'} : {}),
    });

    items.push({
      href: supportCases(),
      name: intl.formatMessage({id: 'support.supportTicket.supportCases.title'}),
      order: 1,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-support-cases'} : {}),
    });

    items.push({
      href: expertSessions(),
      name: intl.formatMessage({id: 'support.supportTicket.expertSessions.title'}),
      order: 2,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-expert-sessions'} : {}),
    });

    return items;
  }, [expertSessions, intl, supportCases, supportSummary]);

  return <WorkspaceNav navItems={navItems} />;
};

SupportWorkspaceNav.displayName = 'SupportWorkspaceNav';

export default SupportWorkspaceNav;
