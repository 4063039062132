import {Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {Table, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AppIntegrationsCell from './AppIntegrationsCell';
import {
  ALL_COLUMNS_ORDERED,
  ALL_COLUMN_KEYS,
  COLUMN_KEY,
  DEFAULT_COLUMNS,
  TABLE_MODE,
} from './appIntegrationTableConstants';

const NO_ITEMS_LOC_KEYS = {
  [TABLE_MODE.HAS_ACCEPTED_USERS]:
    'products.appIntegrations.appIntegrationsTable.table.noItemsFoundContentMessage.noAcceptedUsers',
  [TABLE_MODE.HAS_POLICIES]:
    'products.appIntegrations.appIntegrationsTable.table.noItemsFoundContentMessage.noPolicies',
  [TABLE_MODE.DEFAULT]:
    'products.appIntegrations.appIntegrationsTable.table.noItemsFoundContentMessage.default',
};

/**
 * The table component that displays all app integrations. Can customize which columns are shown.
 * The mode will determine the content of the table.
 */
const AppIntegrationsTable = ({
  columns = DEFAULT_COLUMNS,
  mode = TABLE_MODE.DEFAULT,
  ...tableProps
}) => {
  const intl = useIntl();
  const {tableSectionState} = useTableSectionContext();

  return (
    <Table
      aria-label={intl.formatMessage({
        id: 'products.appIntegrations.appIntegrationsTable.table.label',
      })}
      noItemsFoundContentMessage={intl.formatMessage({id: NO_ITEMS_LOC_KEYS[mode]})}
      noItemsFoundHeadingMessage={intl.formatMessage({
        id: 'products.appIntegrations.appIntegrationsTable.table.noItemsFoundHeadingMessage.default',
      })}
      selectionMode={tableSectionState.selectionMode}
      {...tableProps}
    >
      <TableHeader columns={ALL_COLUMNS_ORDERED.filter((c) => columns.includes(c.key))}>
        {(column) => (
          <Column
            key={column.key}
            hideHeader={column.key === COLUMN_KEY.ACTIONS}
            showDivider={column.key === COLUMN_KEY.ACTIONS}
          >
            <FormattedMessage id={column.locKey} />
          </Column>
        )}
      </TableHeader>
      <TableBody items={tableSectionState.items}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- This is not a complex computation. */}
        {(appIntegration) => (
          <Row key={appIntegration.uuid}>
            {(columnKey) => (
              <Cell>
                <AppIntegrationsCell
                  appIntegration={appIntegration}
                  columnKey={columnKey}
                  mode={mode}
                />
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

AppIntegrationsTable.propTypes = {
  /** The list of columns to display. Order does not matter. Defaults to showing all 5 columns.  */
  columns: PropTypes.arrayOf(PropTypes.oneOf(ALL_COLUMN_KEYS)),
  /**
   * The viewing mode of the table.
   */
  mode: PropTypes.oneOf(Object.values(TABLE_MODE)),
};

AppIntegrationsTable.displayName = 'AppIntegrationsTable';

export default AppIntegrationsTable;
