(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name appLicenseDeficitReportTable
   * @description Component which displays table of license deficit report items
   *
   * @binding {Job} job - job reference to display license deficit report from
   */
  angular.module('app.widgets.bulk-operations').component('appLicenseDeficitReportTable', {
    bindings: {
      job: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/license-deficit-report-table/license-deficit-report-table.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    ////////

    function $onInit() {
      // eslint-disable-next-line promise/catch-or-return
      vm.job.$promise.then(() => {
        if (vm.job.canShowLicenseDeficitSummary()) {
          vm.licenseDeficitSummary = vm.job.getLicenseDeficitSummary();
        } else {
          vm.licenseDeficitSummary = [];
        }
      });
    }
  }
})();
