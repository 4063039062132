(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.carousel:appCarousel
   *
   * @description the carousel widget
   *
   * @param {List} items The item list
   * @param {Function} [onCarouselChange] A callback function to notify carousel changes
   *
   * @example
   * <app-carousel items="$ctrl.items" on-carousel-change="$ctrl.onCarouselChange(event)">
   *  <coral-panel ng-repeat="quota in $ctrl.quotas"><coral-panel-content>
   *    <app-scorecard
   *     label="{{ quota.upperLabel | translate}}"
   *     score="quota.score | number"
   *     total="quota.totalQuantity"
   *   >
   *   </app-scorecard>
   *   <coral-progress
   *     class="stock-progress"
   *     ng-if="quota.totalQuantity"
   *     ng-class="{warning: quota.progress >= $ctrl.WARNING_THRESHOLD}"
   *     ng-attr-value="{{ quota.progress }}"
   *     size="S"></coral-progress>
   *  </coral-panel-content></coral-panel>
   * </app-carousel>
   *
   */
  angular.module('app.widgets.carousel').component('appCarousel', {
    bindings: {
      items: '<',
      onCarouselChange: '&?',
    },
    controller,
    templateUrl: 'app/widgets/common/carousel/carousel.component.html',
    transclude: true,
  });

  function controller($element, $scope, $timeout, _, CAROUSEL_CHANGE_EVENT) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      // Deep-watching the changes in the item list. If there are changes, the carousel will be reset.
      $scope.$watch(() => vm.items, reset, true);
    }

    function reset() {
      const length = _.size(vm.items);

      // Do not show the nav dots if there is only one item
      vm.hideStepList = length <= 1;
      Coral.commons.ready(() => {
        // If $onChanges executes more than once, need to rm all children
        $element.find('coral-stepList').empty();
        // <coral-step> doesn't work well with ng-repeat. Thus we
        // append the steps manually
        for (let i = 0; i < length; i++) {
          const step = new Coral.Step();
          step.on('click', () => onClickStep(i));
          step.on('keydown', (event) => {
            // Left arrow keyCode is 37
            if (event.keyCode === 37 && i - 1 >= 0) {
              onClickStep(i - 1);
            }

            // Right arrow keyCode is 39
            if (event.keyCode === 39 && i + 1 < length) {
              onClickStep(i + 1);
            }
          });

          $element.find('coral-stepList').append(step);
        }
      });
    }

    function onClickStep(index) {
      _.invoke(vm, 'onCarouselChange', {
        event: {
          index,
        },
      });

      $timeout(() => {
        $scope.$emit(CAROUSEL_CHANGE_EVENT, {index});
      });
    }
  }
})();
