import {feature} from '@admin-tribe/binky';
import {WorkspaceNav} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import useSettingsPaths from 'features/settings/hooks/useSettingsPaths';

/** Renders the workspace nav (sidenav) for Settings Section */
const SettingsWorkspaceNav = ({settingsAccess}) => {
  const intl = useIntl();
  const {
    canViewAssetSettings,
    canViewAuthentication,
    canViewConsole,
    canViewEncryptionSettings,
    canViewIdentity,
    canViewSecurityContacts,
    canViewSettings,
    canViewSharedProjectPolicies,
    isLoading,
  } = settingsAccess;
  const {
    assetPath,
    authenticationPath,
    consoleSettingsPath,
    contentLogsPath,
    encryptionPath,
    identityPath,
    securityContactsPath,
    sharedProjectPoliciesPath,
  } = useSettingsPaths();

  const navItems = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const allNavItems = [
      {
        canAccess: canViewIdentity,
        href: identityPath,
        label: 'settings.identity.title',
        testId: 'nav-identity-settings',
      },
      {
        canAccess: canViewAssetSettings,
        href: assetPath,
        label: 'settings.assetSettings.title',
        testId: 'nav-asset-settings',
      },
      {
        canAccess: canViewAuthentication,
        group: 'settings.privacyAndSecurity.title',
        href: authenticationPath,
        label: 'settings.authSettings.title',
        testId: 'nav-authentication-settings',
      },
      {
        canAccess: canViewSecurityContacts,
        group: 'settings.privacyAndSecurity.title',
        href: securityContactsPath,
        label: 'settings.securityContacts.title',
        testId: 'nav-security-contacts',
      },
      {
        canAccess: canViewConsole,
        href: consoleSettingsPath,
        label: 'settings.consoleSettings.title',
        testId: 'nav-console-settings',
      },
      {
        canAccess: canViewSettings,
        href: contentLogsPath,
        label: 'settings.contentLogs.title',
        testId: 'nav-content-logs',
      },
      {
        canAccess: canViewEncryptionSettings,
        href: encryptionPath,
        label: 'settings.encryptionSettings.title',
        testId: 'nav-encryption-settings',
      },
      {
        canAccess: canViewSharedProjectPolicies,
        href: sharedProjectPoliciesPath,
        label: 'settings.sharedProjects.title',
        testId: 'nav-project-settings',
      },
    ];

    const items = allNavItems
      .filter((item) => item.canAccess)
      .map((item, index) => ({
        group: item.group && intl.formatMessage({id: item.group}),
        href: item.href(),
        name: intl.formatMessage({id: item.label}),
        order: index,
        ...(item.testId && feature.isEnabled('temp_navitem_testids') ? {testId: item.testId} : {}),
      }));

    return items;
  }, [
    assetPath,
    authenticationPath,
    canViewAssetSettings,
    canViewAuthentication,
    canViewConsole,
    canViewEncryptionSettings,
    canViewIdentity,
    canViewSecurityContacts,
    canViewSettings,
    canViewSharedProjectPolicies,
    consoleSettingsPath,
    contentLogsPath,
    encryptionPath,
    identityPath,
    intl,
    isLoading,
    securityContactsPath,
    sharedProjectPoliciesPath,
  ]);

  return <WorkspaceNav navItems={navItems} />;
};

SettingsWorkspaceNav.displayName = 'SettingsWorkspaceNav';

SettingsWorkspaceNav.propTypes = {
  /**
   * The resolved object from useSettingsAccessors()
   * Contains bools indicating whether user should have access to each page in Settings
   */
  settingsAccess: PropTypes.shape({
    canViewAssetSettings: PropTypes.bool,
    canViewAuthentication: PropTypes.bool,
    canViewConsole: PropTypes.bool,
    canViewEncryptionSettings: PropTypes.bool,
    canViewIdentity: PropTypes.bool,
    canViewSecurityContacts: PropTypes.bool,
    canViewSettings: PropTypes.bool,
    canViewSharedProjectPolicies: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SettingsWorkspaceNav;
