import {configStore, feature, log, navBus} from '@admin-tribe/binky';
import {showError} from '@admin-tribe/binky-ui';
import {
  MessageType,
  NavigationMessageSubType,
  SystemMessageSubType,
} from '@pandora/mini-app-messaging-types';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canAssignUser} from 'core/products/access/productAccess';
import {
  ADD_PRODUCTS_MINI_APP_NAME,
  CONFIRMATION_MINI_APP_NAME,
  FAILED,
  QUICK_ASSIGN_MINI_APP_NAME,
  SUBMITTED,
} from 'features/mini-apps/MiniAppConstants';
import MiniAppModalWrapper from 'features/mini-apps/modal-wrapper/MiniAppModalWrapper';
import {
  PATH_OVERVIEW_ADD_PRODUCTS,
  PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS,
} from 'features/overview/routing/overviewPaths';

import {ContentState, WorkflowType} from './QuickAssignModalConstants';

const CONFIRMATION_MINI_APP_URL_ENV_PARAM = 'services.adminMiniApps.confirmation.url';

const QUICK_ASSIGN_MINI_APP_URL_ENV_PARAM = 'services.adminMiniApps.quickAssign.url';

/**
 * @description Represents a wrapper for Quick Assign Mini App
 *
 * @param {Boolean} isFirstSessionAccess - Optional indicator to determine if the user has never seen the QuickAssign modal before. Defaults to false.
 * @param {Function} onClose - Required callback to run when the modal is closed
 * @param {String} tracking - Tracking data to send to the mini app
 */
const QuickAssignModalWrapper = ({
  isFirstSessionAccess = false,
  onBuyMore,
  onClose,
  showAdminAssigned,
  showBuyMore,
  tracking,
}) => {
  const intl = useIntl();
  const [selectedMiniApp, setSelectedMiniApp] = useState(QUICK_ASSIGN_MINI_APP_NAME);
  const [loadingMessageId, setLoadingMessageId] = useState(
    `miniApps.quickAssign.loading.${QUICK_ASSIGN_MINI_APP_NAME}`
  );
  const {pathname, search} = useLocation();

  // used to allow URL overrides
  const searchQuery = new URLSearchParams(search);
  const appUrlMapping = {
    [CONFIRMATION_MINI_APP_NAME]: configStore.get(CONFIRMATION_MINI_APP_URL_ENV_PARAM),
    [QUICK_ASSIGN_MINI_APP_NAME]: configStore.get(QUICK_ASSIGN_MINI_APP_URL_ENV_PARAM),
  };

  // If the user wants to enter the single seat workflow, ensure the url params has a single pid
  // and set the workflow type to single seat. If the user wants to enter through the welcome page,
  // additionally ensure init query param is welcome.
  const urlPIDs = searchQuery.get('pids')?.split(',');

  const {organizationStore} = rootStore;
  const orgPIDs = organizationStore.productList.items
    .filter((product) => canAssignUser(product))
    .map((product) => product.id);

  const productIds = urlPIDs || orgPIDs;

  const initialState = isFirstSessionAccess ? ContentState.WELCOME : ContentState.FORM;
  // quick assign will ignore and go to the multi seat workflow if the number of PIDs is not 0 or greater than 1
  // Welcome is only available for the single seat workflow. The number of PID
  const workflowType = isFirstSessionAccess ? WorkflowType.SINGLE_SEAT : WorkflowType.MULTI_SEAT;

  const saa = searchQuery.get('saa') || showAdminAssigned;
  const initialParams = {
    init: searchQuery.get('init') || initialState,
    pids: productIds.join(','),
    saa,
    sbm: searchQuery.get('sbm') || showBuyMore,
    trackingid: searchQuery.get('tracking') || tracking,
    wts: searchQuery.get('wts') || workflowType,
  };

  // Initiate with Quick Assign specific parameters
  const [params, setParams] = useState(initialParams);

  const goToBuyMoreCallback = useCallback(
    (data) => () => {
      const {params: dataParams, primaryActionUrl} = data;

      if (onBuyMore) {
        // user URLSearchParams to normalize the url or object from params
        const searchParams = new URLSearchParams(dataParams || primaryActionUrl);
        // although the callback does not provide add products mini app params
        // assume that some may come in the future
        onBuyMore(searchParams);
        onClose();
      } else {
        let items;
        if (primaryActionUrl) {
          // if no query params, the second item of the split string will be undefined
          // When queryString is undefined, qs.parse will return an empty object, which will make items undefined
          const queryString = primaryActionUrl.split('?')[1];
          items = qs.parse(queryString).items;
        } else {
          items = dataParams?.items;
        }

        const query = items?.length > 0 ? `?${qs.stringify({items})}` : '';

        const path = generatePath(PATH_OVERVIEW_ADD_PRODUCTS, {
          orgId: rootStore.organizationStore.activeOrgId,
        });

        // we need the use_routing_with_query_params feature flag to be enabled to use query params in the url
        navBus.navigate(
          feature.isEnabled('use_routing_with_query_params')
            ? {pathname: path, search: query}
            : path
        );
      }
    },
    [onBuyMore, onClose]
  );

  const onMessage = useCallback(
    ({data, subType}) => {
      const goToBuyMore = goToBuyMoreCallback(data);
      // Notification subtypes are assumed to be a SYSTEM type
      switch (subType) {
        case SystemMessageSubType.APP_CLOSED:
        case SystemMessageSubType.APP_LOADED:
          // handled in mini app modal wrapper, using to prevent the log
          break;
        case SystemMessageSubType.LAUNCH_APP:
          if (data.target === ADD_PRODUCTS_MINI_APP_NAME) {
            goToBuyMore();
          }
          break;
        case SystemMessageSubType.NOTIFICATION:
          // quick assign has only error notifications
          showError(data.body, {
            actionLabel: data.primaryActionLabel,
            // we only know buy more action with notifications, if other actions are needed, it can be determined with the url
            // if the url is not provided, we just log
            onAction: data.primaryActionUrl
              ? goToBuyMore
              : () => log.debug('no action url provided to indicate behavior'),
          });
          break;
        case SystemMessageSubType.REPORT_STATE:
          if (data.params?.state === SUBMITTED) {
            setLoadingMessageId(`miniApps.quickAssign.loading.${CONFIRMATION_MINI_APP_NAME}`);
          } else if (data.params?.state === FAILED) {
            log.debug('Failed to submit form');
          }
          break;

        default:
          log.debug(`Unhandled message subtype in Quick Assign: ${subType}`);
      }
    },
    [goToBuyMoreCallback]
  );

  const onCloseMessage = useCallback(
    (data) => {
      if (data?.actions) {
        data.actions.forEach((action) => {
          const {app, data: actionData, subType, type} = action.actionMessage;
          // only has one action, which is to open confirmation modal
          if (type === MessageType.NAVIGATION && subType === NavigationMessageSubType.SWITCH) {
            setSelectedMiniApp(app);
            setParams(actionData);
          }
        });
      } else if (pathname.includes(PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS)) {
        // navigate to parent route if accessed through deeplink, using relative path '..'
        // changes the url but doesn't dismiss the modal, so we need the full path
        const parentPath = pathname.match(/.+?(?=\/quick-assign-products)/)[0];
        navBus.navigate(parentPath);
      } else {
        // callback without arguments if it is simply a cancel operation
        onClose?.();
      }
    },
    [pathname, onClose]
  );

  return (
    <MiniAppModalWrapper
      baseUrl={appUrlMapping[selectedMiniApp]}
      loadingMessage={intl.formatMessage({id: loadingMessageId})}
      miniAppName={selectedMiniApp}
      onClose={onCloseMessage}
      onMessage={onMessage}
      params={params}
      title={intl.formatMessage({id: 'miniApps.quickAssign.title'})}
    />
  );
};

QuickAssignModalWrapper.propTypes = {
  /**
   * The indicator to determine if the user has never seen the QuickAssign modal before.
   * Defaults to false.
   */
  isFirstSessionAccess: PropTypes.bool,
  /**
   * Optional callback to launch the add productsModal when the user clicks on Buy More button within quick assign.
   * Passes URLSearchParams as an argument.
   */
  onBuyMore: PropTypes.func,
  /**
   * Optional handler that is called when the modal is closed if current url does not suggest we are in a quick assign deeplink.
   * If we the url is a quick assign deeplink, this will be ignored and go to the parent route.
   */
  onClose: PropTypes.func,
  /**
   * Used to determine if the current admin should be automatically filled in as the first user to assign products to.
   * Defaults to false. If this is true, the current user email will be populated.
   */
  showAdminAssigned: PropTypes.bool,
  /**
   * Used to determine if the buy more button should be shown in the quick assign mini app. May not show if contract is not eligible.
   * Default is false.
   */
  showBuyMore: PropTypes.bool,
  /**
   * Optional step which determines the default step of the mini app
   */
  tracking: PropTypes.string,
};

export default QuickAssignModalWrapper;
