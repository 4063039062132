(function () {
  /**
   * @deprecated Deprecated with temp_terms_redirect as
   * terms acceptance is moved to Invites mini-app.
   *
   * @ngdoc component
   * @name app.widgets.termsAndConditions:appTermsAndConditions
   *
   * @description a terms and conditions component managing acceptance of terms
   *
   * @example <app-terms-and-conditions></app-terms-and-conditions>
   *
   *
   */
  angular.module('app.widgets.terms-and-conditions').component('appTermsAndConditions', {
    controller,
    templateUrl: 'app/widgets/terms-and-conditions/terms-and-conditions.component.html',
  });

  function controller(
    $stateParams,
    $translate,
    $window,
    _,
    auth,
    OrganizationList,
    OrganizationManager,
    User,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getButtonText,
      getTermsTitle,
      onIndirectContextChange,
      onTermsAccepted,
      showImageBanner,
      showIndirectConfirmSection,
      showIndirectOrgNameSection,
      showWelcomeText,
    });

    function $onInit() {
      const activeOrg = OrganizationList.get().activeOrg;

      // find the contract requiring acceptance or re-acceptance
      const foundContract = _.find(
        OrganizationManager.getContractsForActiveOrg().items,
        (contract) => {
          const terms = contract.getTermsAndConditions();
          return terms && !terms.getCurrent() && terms.getMayAccept();
        }
      );

      // find the person who's logged in
      const activePerson = User.imsProfileTransformer(auth.getUserProfile());

      _.assign(vm, {
        accepting: false,
        activeOrgName: _.get(activeOrg, 'name'), // find the the org current user are logged in for
        activePerson,
        address: foundContract.getAddress(), // Not yet defined in the JIL response
        contract: foundContract,
        imageBanner: {
          description: $translate.instant('widgets.termsAndConditions.imageBanner.description'),
          imageId: 'getting-started',
          title: $translate.instant('widgets.termsAndConditions.imageBanner.title', {
            name: activePerson.firstName,
          }),
        },
        indirectContextConfirmed: false,
        isDirect: foundContract.isDirectContract(),
        isIndirect: foundContract.isIndirectContract(),
        marketSegment: _.get(activeOrg, 'marketSegment'),
        resellerInfo: foundContract.resellerInfo, // Not yet defined in the JIL response
        terms: foundContract.getTermsAndConditions(), // Not yet defined in the JIL response
      });
    }

    function getButtonText() {
      return vm.terms.accepted
        ? 'widgets.termsAndConditions.reaccept'
        : 'widgets.termsAndConditions.accept';
    }

    function getTermsTitle() {
      return vm.terms.accepted
        ? 'widgets.termsAndConditions.title'
        : 'widgets.termsAndConditions.accept';
    }

    function onIndirectContextChange() {
      vm.indirectContextConfirmed = !vm.indirectContextConfirmed;
    }

    function onTermsAccepted() {
      vm.accepting = true;
      vm.terms
        .acceptTerms(vm.contract)
        .then(() => {
          // if we have an orgId, then the app has already loaded and we can just switch to it
          // after reloading the contracts
          if ($stateParams.orgId && _.isString($stateParams.orgId)) {
            OrganizationManager.loadOrganization($stateParams.orgId);
          } else {
            // If we don't have an org id, go to the root to re-fetch everything
            // eslint-disable-next-line lodash/prefer-lodash-method
            $window.location.replace(_.get($window, 'location.origin', $window.location.href));
          }
        })
        .catch(() => {
          vm.accepting = false;
          toastManager.showErrorToast();
        });
    }

    function showImageBanner() {
      const terms = vm.contract.getTermsAndConditions();

      return (
        terms &&
        !terms.accepted &&
        (vm.contract.isVIPMPContract() || vm.contract.isIndirectContractWithNoEnrollee())
      );
    }

    function showIndirectConfirmSection() {
      return (
        vm.contract.isIndirectButNotVIPMPContract() &&
        !vm.contract.isIndirectContractWithNoEnrollee()
      );
    }

    function showIndirectOrgNameSection() {
      return vm.contract.isIndirectContract() && !showIndirectConfirmSection();
    }

    function showWelcomeText() {
      return !showImageBanner();
    }
  }
})();
