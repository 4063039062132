import {navBus} from '@admin-tribe/binky';
import {Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import {PATH_PACKAGES_SERVERS} from 'features/packages/routing/packagesPaths';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import CreatePackageCardCheckboxGroup from '../../create-package-card-checkbox-group/CreatePackageCardCheckboxGroup';

import ActivationTypeCard from './ActivationTypeCard';
import {activationCardsData, getSubType, showCard} from './chooseActivationTypePageUtils';

const ChooseActivationTypePage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();

  // update adobeLicenseFixedDns value based on package type
  useEffect(() => {
    createPackageModalStore.setPkgSessionSettingValues(
      'adobeLicenseFixedDns',
      createPackageModalStore.pkgSessionSettings.packageType ===
        CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run on initial render
  }, []);

  // close the create package modal and start the create server modal
  const onServerLinkPress = useCallback(() => {
    navBus.navigate(
      generatePath(PATH_PACKAGES_SERVERS, {orgId: rootStore.organizationStore.activeOrgId}),
      {
        state: {openCreateServerModal: true},
      }
    );
  }, []);

  // Function to reset the offers list
  const resetOffersList = useCallback(() => {
    PackagesEntitlements.resetOffers();
    createPackageModalStore.setPkgSessionSettingValues('selectedFrlOffers', []);
  }, [createPackageModalStore]);

  // Triggered when a different package type selected
  const onFrlPackageTypeSelected = useCallback(
    (pkgType) => {
      const subType = getSubType(pkgType);
      if (subType) {
        createPackageModalStore.setPkgSessionSettingValues('packageType', pkgType);
        resetOffersList();
        PackagesIngestAnalytics.triggerAnalytics({
          subCategory: 'FRL',
          subType,
          type: 'click',
        });
      }
      // Set frlSelectedServer to undefined when frl lan pkg type selected
      if (pkgType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE) {
        createPackageModalStore.setPkgSessionSettingValues('frlSelectedServer', undefined);
      }
      // Set deviceInformationShared to true, when frl online pkg type selected
      if (pkgType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE) {
        createPackageModalStore.setPkgSessionSettingValues('deviceInformationShared', true);
      }

      // set adobeLicenseFixedDns to true only when online activation type selected
      createPackageModalStore.setPkgSessionSettingValues(
        'adobeLicenseFixedDns',
        createPackageModalStore.pkgSessionSettings.packageType ===
          CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE
      );
      createPackageModalStore.setPkgSessionSettingValues('resetCreatePackageData', true);
      createPackageModalStore.setPackageCreateObjValues('rumEnabled', false);
      createPackageModalStore.setPackageCreateObjValues('enableExtenstions', false);
    },
    [createPackageModalStore, resetOffersList]
  );

  // Triggered when one of the cards is selected
  const onChange = useCallback(
    (values) => {
      const selectedValuesLength = values.length;
      if (selectedValuesLength > 0) {
        // When another package type selected, then the package type selected is added to end of values list
        // trigger onPackageTypeSelect with the newly selected package type (as only one package type can be selected at a time)
        onFrlPackageTypeSelected(values[selectedValuesLength - 1]);
      } else {
        createPackageModalStore.setPkgSessionSettingValues('packageType', null);
      }
      createPackageModalStore.setPkgSessionSettingValues(
        'nextButtonEnabled',
        !!createPackageModalStore.pkgSessionSettings.packageType
      );
    },
    [createPackageModalStore, onFrlPackageTypeSelected]
  );

  const downloadMenuItems = (activationCard) =>
    activationCard.getDownloadMenuItems()?.map(({key}) => ({
      displayString: intl.formatMessage({
        id: `packages.tools.adminTools.downloadPlatforms.${key}`,
      }),
      key,
    }));

  return (
    <>
      <Text UNSAFE_style={{fontWeight: 'bold'}}>
        {intl.formatMessage({id: 'packages.createPackage.activationType.title'})}
      </Text>
      <View paddingBottom="size-300" paddingTop="size-50">
        {intl.formatMessage({id: 'packages.createPackage.activationType.titleDesc'})}
      </View>
      <CreatePackageCardCheckboxGroup
        aria-label={intl.formatMessage({
          id: 'packages.createPackage.activationType.groupAriaLabel',
        })}
        gap="size-200"
        onChange={onChange}
        value={[createPackageModalStore.pkgSessionSettings.packageType]}
      >
        {activationCardsData
          .filter(({packageType}) => showCard(packageType))
          .map((activationCard, index) => (
            <ActivationTypeCard
              key={activationCard.packageType}
              activationDescription={intl.formatMessage({
                id: `packages.createPackage.activationType.${activationCard.packageType}TitleDesc`,
              })}
              autoFocus={index === 0}
              buttonString={
                activationCard.getDownloadMenuItems()?.length > 0
                  ? intl.formatMessage({
                      id: `packages.createPackage.activationType.${activationCard.packageType}DownloadButtonString`,
                    })
                  : ''
              }
              downloadMenuItems={downloadMenuItems(activationCard)}
              learnMoreAriaLabel={intl.formatMessage(
                {id: 'packages.createPackage.activationType.learnMoreAriaLabel'},
                {
                  title: intl.formatMessage({
                    id: `packages.createPackage.activationType.${activationCard.packageType}Title`,
                  }),
                }
              )}
              learnMoreString={intl.formatMessage(
                {id: 'packages.createPackage.licenseType.learnMoreAboutLink'},
                {
                  title: intl.formatMessage({
                    id: `packages.createPackage.activationType.${activationCard.packageType}Title`,
                  }),
                }
              )}
              name={activationCard.name}
              onDownloadItemPress={
                activationCard.onDownloadItemPress
                  ? (key) => {
                      activationCard.onDownloadItemPress(key);
                    }
                  : null
              }
              onServerLinkPress={activationCard.hasServerLink ? onServerLinkPress : null}
              prerequisites={
                activationCard.hasPrerequisites
                  ? intl.formatMessage({
                      id: `packages.createPackage.activationType.${activationCard.packageType}Perequisite`,
                    })
                  : null
              }
              title={intl.formatMessage({
                id: `packages.createPackage.activationType.${activationCard.packageType}Title`,
              })}
              value={activationCard.packageType}
              workingDescription={intl.formatMessage({
                id: `packages.createPackage.activationType.${activationCard.packageType}Desc`,
              })}
            />
          ))}
      </CreatePackageCardCheckboxGroup>
    </>
  );
});

ChooseActivationTypePage.propTypes = {};
export default ChooseActivationTypePage;
