import rootStore from 'core/RootStore';

import adminConsole from './admin-console/adminConsole';
import enterpriseAddAdmins from './admin-console/enterpriseAddAdmins';
import enterpriseCreateUserGroups from './admin-console/enterpriseCreateUserGroups';
import enterpriseDomains from './admin-console/enterpriseDomains';
import enterpriseUmapi from './admin-console/enterpriseUmapi';
import evipLicenseMigration from './admin-console/evipLicenseMigration';
import getSupport from './admin-console/getSupport';
import teamAddAdmin from './admin-console/teamAddAdmin';
import teamAddProducts from './admin-console/teamAddProducts';
import teamAddUsers from './admin-console/teamAddUsers';
import getStarted from './getStarted';
import autoAssignedProduct from './products/autoAssignedProduct';
import createPackages from './products/createPackages';
import enterpriseCloud from './products/enterpriseCloud';
import enterpriseSign from './products/enterpriseSign';
import enterpriseSign2 from './products/enterpriseSign2';
import enterpriseStock from './products/enterpriseStock';
import manageSdl from './products/manage-sdl/manageSdl';
import sdlFaq from './products/manage-sdl/sdl-faq/sdlFaq';
import sdlHowToManage from './products/manage-sdl/sdl-how-to-manage/sdlHowToManage';
import sdlSetUp from './products/manage-sdl/sdl-set-up/sdlSetUp';
import manageApiProducts from './products/manageApiProducts';
import officeIntegration from './products/officeIntegration';
import products from './products/products';
import teamSign from './products/teamSign';
import teamSign2 from './products/teamSign2';
import teamStock from './products/teamStock';
import xdForFree from './products/xdForFree';

/**
 * @description Method to initialize local data, setting up the help store with
 *   any/all local help content
 */
const initializeHelp = () => {
  // place new help topic constructors here
  const allHelpTopicConstructors = [
    adminConsole,
    autoAssignedProduct,
    createPackages,
    enterpriseAddAdmins,
    enterpriseCloud,
    enterpriseCreateUserGroups,
    enterpriseDomains,
    enterpriseSign,
    enterpriseSign2,
    enterpriseStock,
    enterpriseUmapi,
    evipLicenseMigration,
    getStarted,
    getSupport,
    manageApiProducts,
    manageSdl,
    officeIntegration,
    products,
    sdlFaq,
    sdlHowToManage,
    sdlSetUp,
    teamAddAdmin,
    teamAddProducts,
    teamAddUsers,
    teamSign,
    teamSign2,
    teamStock,
    xdForFree,
  ];

  allHelpTopicConstructors.forEach(async (helpTopicConstructor) => {
    const helpItemConfig = await helpTopicConstructor();

    if (helpItemConfig?.length > 0) {
      helpItemConfig.forEach((configuration) => {
        if (configuration.id) {
          // help item may not be accessible, so only add if an item is present
          rootStore.helpStore.addTopic(configuration);
        }
      });
    }
    if (helpItemConfig?.id) {
      // help item may not be accessible, so only add if an item is present
      rootStore.helpStore.addTopic(helpItemConfig);
    }
  });
};
export default initializeHelp;
