import PropTypes from 'prop-types';
import React from 'react';

import ChatCard from './ChatCard';
import FreeTimeOfferCard from './FreeTimeOfferCard';
import PercentageDiscountOfferCard from './PercentageDiscountOfferCard';

const cardMap = {
  CHAT: ChatCard,
  FREE_TIME: FreeTimeOfferCard,
  PERCENTAGE_DISCOUNT: PercentageDiscountOfferCard,
};

/**
 * Selects a card to render based on the type passed in via props.
 * @param {Number} [index] of the card to render
 * @param {String} [type] of the card to render
 * @param {String} [retentionId] of the card to render
 * @param {func} onCTA callback of the card to render
 * @returns The card component instance specified by type prop
 */
const SaveOffersCardFactory = ({index, onCTA, retentionId, type, ...props}) => {
  const Card = cardMap[type];
  return <Card {...props} index={index} onCTA={onCTA} retentionId={retentionId} type={type} />;
};

SaveOffersCardFactory.propTypes = {
  /**
   * The location of the card in the carousel.
   */
  index: PropTypes.number,
  /**
   * Handler that is called when the user press the CTA.
   */
  onCTA: PropTypes.func,
  /**
   * The retention id for the Save Offer, used by the component to look it up in ProductsChange
   * response.
   */
  retentionId: PropTypes.string,
  /**
   * Type of the card to render
   */
  type: PropTypes.oneOf(Object.keys(cardMap)).isRequired,
};

export default SaveOffersCardFactory;
