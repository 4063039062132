import {toPandoraProductList} from '@admin-tribe/binky';
import {CLOUD} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {
  canAccessEnterpriseSupport as pandoraCanAccessEnterpriseSupport,
  canAccessEnterpriseSupportForCloud as pandoraCanAccessEnterpriseSupportForCloud,
} from '@pandora/data-model-support-ticket';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';

import CreativeCloud from '../components/help-topic-lists/content-models/CreativeCloud';
import DocumentCloud from '../components/help-topic-lists/content-models/DocumentCloud';
import ExperienceCloud from '../components/help-topic-lists/content-models/ExperienceCloud';
import GeneralAdministration from '../components/help-topic-lists/content-models/GeneralAdministration';
import MoreProducts from '../components/help-topic-lists/content-models/MoreProducts';
import Team from '../components/help-topic-lists/content-models/Team';
import {
  PATH_SUPPORT,
  PATH_SUPPORT_SUMMARY_CC,
  PATH_SUPPORT_SUMMARY_DC,
  PATH_SUPPORT_SUMMARY_EC,
  PATH_SUPPORT_SUMMARY_MORE_PRODUCT,
} from '../routing/supportPaths';
import {SUPPORT_CONSTANTS} from '../support.constants';

const {organizationStore} = rootStore;

/**
 * Method to return array of Help Topic's GoUrls based on cloudType
 * @param {*} cloudType
 * @returns List of GoUrls
 */
const getHelpTopicsGoUrls = (cloudType) => {
  let goUrls = [];
  switch (cloudType) {
    case CLOUD.EXPERIENCE:
      goUrls = [
        {
          label: 'adobeEnterpriseDocumentation',
          name: 'ac_xc_support',
        },
        {
          label: 'communityForum',
          name: 'ExLCommunity',
        },
      ];
      break;
    case CLOUD.OTHERS:
      goUrls = [
        {
          label: 'supportArticles',
          name: 'ac_ppbuetla_support_browse',
        },
        {
          label: 'communityForum',
          name: 'entcom',
        },
      ];
      break;
    case SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.TEAM:
      goUrls = [
        {
          label: 'supportArticles',
          name: 'ac_team_support_browse',
        },
        {
          label: 'communityForum',
          name: 'entcom',
        },
      ];
      break;
    default:
      goUrls = [
        {
          label: 'supportArticles',
          name: 'ac_ccetla_support_browse',
        },
        {
          label: 'communityForum',
          name: 'entcom',
        },
      ];
      break;
  }
  return goUrls;
};

/**
 * Order of tab based on placement of items in array.
 * @returns List of Tabs which are not hidden.
 */
const getHelpTopicsTabs = () => {
  const orgId = organizationStore.activeOrgId;
  const contractList = new JilModelList({
    items: organizationStore.contractList.items.map((contract) => new Contract(contract)),
  });

  const productList = toPandoraProductList(organizationStore.productList);
  const canAccessEnterpriseSupport = pandoraCanAccessEnterpriseSupport(contractList, productList);
  const canAccessEnterpriseSupportForCloud = (cloud) =>
    pandoraCanAccessEnterpriseSupportForCloud(cloud, contractList, productList);

  const helpTopicsTabs = [
    {
      contentModel: GeneralAdministration,
      hidden: !canAccessEnterpriseSupport,
      key: SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL,
      name: 'support.supportHelpTopics.tabs.headings.administration',
      pathname: generatePath(PATH_SUPPORT, {orgId}), // `/${orgId}/support`,
    },
    {
      contentModel: CreativeCloud,
      hidden: !canAccessEnterpriseSupportForCloud(CLOUD.CREATIVE),
      key: CLOUD.CREATIVE,
      name: 'support.supportHelpTopics.tabs.headings.creativeCloud',
      pathname: generatePath(PATH_SUPPORT_SUMMARY_CC, {orgId}), // `/${orgId}/support/creative-cloud`,
    },
    {
      contentModel: DocumentCloud,
      hidden: !canAccessEnterpriseSupportForCloud(CLOUD.DOCUMENT),
      key: CLOUD.DOCUMENT,
      name: 'support.supportHelpTopics.tabs.headings.documentCloud',
      pathname: generatePath(PATH_SUPPORT_SUMMARY_DC, {orgId}), // `/${orgId}/support/document-cloud`,
    },
    {
      contentModel: ExperienceCloud,
      hidden: !canAccessEnterpriseSupportForCloud(CLOUD.EXPERIENCE),
      key: CLOUD.EXPERIENCE,
      name: 'support.supportHelpTopics.tabs.headings.experienceCloud',
      pathname: generatePath(PATH_SUPPORT_SUMMARY_EC, {orgId}), // `/${orgId}/support/experience-cloud`,
    },
    {
      contentModel: MoreProducts,
      hidden: !canAccessEnterpriseSupportForCloud(CLOUD.OTHERS),
      key: CLOUD.OTHERS,
      name: 'support.supportHelpTopics.tabs.headings.moreProducts',
      pathname: generatePath(PATH_SUPPORT_SUMMARY_MORE_PRODUCT, {orgId}), // `/${orgId}/support/more-products`,
    },
    {
      contentModel: Team,
      hidden: canAccessEnterpriseSupport,
      key: SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.TEAM,
      name: 'team-cloud',
      pathname: generatePath(PATH_SUPPORT, {orgId}), // `/${orgId}/support`,
    },
  ];

  return helpTopicsTabs.filter((tab) => !tab.hidden);
};

export {getHelpTopicsGoUrls, getHelpTopicsTabs};
