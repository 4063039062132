(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appAddEduProviderModal', {
    bindings: {
      authSource: '<',
      onIdpAdded: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/directories/add-edu-provider-modal/add-edu-provider-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $scope,
    $rootScope,
    $translate,
    $timeout,
    $q,
    _,
    APP_ADD_EDU_PROVIDER_MODAL_ID,
    AUTH_SOURCE_ERROR_CODES,
    feature,
    IDP_CHOOSER_LOGOS,
    IDP_TYPES,
    OrganizationList,
    panelManager,
    SOIDC_PROVIDERS,
    toastManager
  ) {
    const vm = this;
    const orgId = OrganizationList.get().activeOrg.id;

    const GENERIC_ERROR_CODE = Symbol('GENERIC');

    // Map of errors shown with a page-banner component if anything fails
    const ERRORS = {
      [AUTH_SOURCE_ERROR_CODES.IDP_ALREADY_EXISTS]: {
        description: 'widgets.directories.addEduProviderModal.errors.idpAlreadyExists.description',
        title: 'widgets.directories.addEduProviderModal.errors.idpAlreadyExists.title',
      },
      [GENERIC_ERROR_CODE]: {
        description: 'widgets.directories.addEduProviderModal.errors.generic.description',
        title: 'widgets.directories.addEduProviderModal.errors.generic.title',
      },
    };

    const idpList = [
      {
        id: SOIDC_PROVIDERS.BLACKBOARD,
        logo: IDP_CHOOSER_LOGOS.BLACKBOARD,
        name: $translate.instant(
          'widgets.directories.addEduProviderModal.idpChooser.idps.blackboard.name'
        ),
      },
      {
        id: SOIDC_PROVIDERS.CANVAS,
        logo: IDP_CHOOSER_LOGOS.CANVAS,
        name: $translate.instant(
          'widgets.directories.addEduProviderModal.idpChooser.idps.canvas.name'
        ),
      },
      {
        id: SOIDC_PROVIDERS.CLASSLINK,
        logo: IDP_CHOOSER_LOGOS.CLASSLINK,
        name: $translate.instant(
          'widgets.directories.addEduProviderModal.idpChooser.idps.classlink.name'
        ),
      },
      {
        id: SOIDC_PROVIDERS.CLEVER,
        logo: IDP_CHOOSER_LOGOS.CLEVER,
        name: $translate.instant(
          'widgets.directories.addEduProviderModal.idpChooser.idps.clever.name'
        ),
      },
      {
        id: SOIDC_PROVIDERS.GOOGLE,
        logo: IDP_CHOOSER_LOGOS.GOOGLE,
        name: $translate.instant(
          'widgets.directories.addEduProviderModal.idpChooser.idps.google.name'
        ),
      },
    ];

    _.assign(vm, {$onInit, onClickAdd, onClickCancel, onIdpSelected, onOpen});

    function $onInit() {
      _.assign(vm, {
        error: null,
        idp: {},
        idpList,
        isProcessing: false,
        modalId: APP_ADD_EDU_PROVIDER_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    /**
     * Handler for the selected event of the idp-chooser component.
     *
     * @param {Idp} idp An Idp object containing the selected id.
     */
    function onIdpSelected(idp) {
      vm.idp = idp;
    }

    /**
     * Handler of the onOpen event of the modal
     */
    function onOpen() {
      resetModal();
    }

    /**
     * Handler for the cancel button
     */
    function onClickCancel() {
      panelManager.close(APP_ADD_EDU_PROVIDER_MODAL_ID);
      resetModal();
    }

    /**
     * Handler for the next button
     */
    function onClickAdd() {
      vm.isProcessing = true;

      vm.waitPromise = createIdp(vm.idp.id)
        .then((authSource) => {
          $rootScope.$emit('EDU IdP added', {
            addedIdpName: vm.idp.id,
            directoryId: authSource.id,
            idps: authSource.idps,
          });

          vm.onIdpAdded();

          const providerDisplayName = _.get(_.find(idpList, {id: vm.idp.id}), 'name');
          showSuccessToast(providerDisplayName);

          panelManager.close(APP_ADD_EDU_PROVIDER_MODAL_ID);

          // Reset modal in the next digest to prevent user from briefly seeing
          // the reset state of the modal upon closing it.
          $scope.$evalAsync(resetModal);
        })
        .catch((error) => showErrorBanner(error))
        .finally(() => {
          vm.isProcessing = false;
        });
    }

    //////////

    /**
     * Creates an IdP of type SOIDC on the current AuthSource with
     * the specified providerName.
     *
     * @param {String} providerName The provider of the new SOIDC IdP.
     *
     * @returns {Promise<AuthSource>} A promise that contains the instance of the AuthSource.
     *                                The AuthSource will contain the new IdP.
     */
    function createIdp(providerName) {
      return vm.authSource
        .addIdp({federationType: IDP_TYPES.SOIDC, providerName})
        .catch((error) => {
          $log.error(
            `Could not create ${IDP_TYPES.SOIDC} IdP for ${vm.authSource.id} in ${orgId}`,
            error
          );
          return $q.reject(error);
        });
    }

    /**
     * Resets the modal to its initial state
     */
    function resetModal() {
      _.assign(vm, {
        error: null,
        idp: {},
        isProcessing: false,
      });
    }

    /**
     * Shows an error banner with a message that depends on the error code.
     * If the error code is not recognized a generic error message is used (see `ERRORS` map)
     *
     * @param {Error} err An error object that can represent a generic error or an http call error
     */
    function showErrorBanner(err) {
      const errorCode = _.get(err, 'data.error');
      vm.error = ERRORS[errorCode] || ERRORS[GENERIC_ERROR_CODE];
    }

    /**
     * Shows a success toast when the idp has been added successfuly.
     *
     * @param {String} providerName The name of the newly added provider.
     */
    function showSuccessToast(providerName) {
      toastManager.showSuccessToast(
        $translate.instant(`widgets.directories.addEduProviderModal.successToastBody`, {
          providerName,
        })
      );
    }
  }
})();
