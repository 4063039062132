import {feature} from '@admin-tribe/binky';
import {useMemo} from 'react';

import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SAML_CERTS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';

/**
 * A hook that manages the state of the cta button
 * used in directory setup modals (create directory, add idp, etc.)
 */
const useButtons = ({
  directorySetupStore,
  sectionsStepManager,
  setupAuthenticationStepManager,
  idpInfo,
  directoryInfo,
  isDoingExternalAuth,
}) => {
  const hasSamlCertsStep = useMemo(() => {
    const idpType = idpInfo.type ?? directorySetupStore.idp?.federationType;

    return (
      feature.isEnabled('temp_saml_certs') &&
      idpType === IDP_TYPES.SAML &&
      directorySetupStore.isEditingIdp
    );
  }, [directorySetupStore.idp?.federationType, directorySetupStore.isEditingIdp, idpInfo.type]);

  const ctaButtonLabelId = useMemo(() => {
    // if we are at the setup jit and IdP is SOIDC or AZURE, the button should say "Done"
    if (
      (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id &&
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id &&
        !hasSamlCertsStep) ||
      // TODO: Add SETUP_SAML_CERTS step in the SETUP_AUTHENTICATION_STEPS when temp_saml_certs is removed
      (hasSamlCertsStep && setupAuthenticationStepManager.currentStep.id === SETUP_SAML_CERTS.id)
    ) {
      return 'settings.directories.addIdpModal.buttons.done';
    }

    // if we're in the middle of updating a tenant id for azure IdP, the button should say "Update Tenant ID"
    if (
      sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id &&
      directorySetupStore.isEditingAzureIdp
    ) {
      return 'settings.directories.addIdpModal.buttons.updateTenantId';
    }

    // If an Azure IdP was selected the button should say "Login to Azure"
    if (
      sectionsStepManager.currentStep.id === SETUP_SECTIONS.SELECT_IDP.id &&
      idpInfo.type === IDP_TYPES.AZURE
    ) {
      return 'settings.directories.addIdpModal.buttons.loginToAzure';
    }

    // For all the other options, the button should say "Next"
    return 'settings.directories.addIdpModal.buttons.next';
  }, [
    sectionsStepManager.currentStep.id,
    setupAuthenticationStepManager.currentStep.id,
    hasSamlCertsStep,
    directorySetupStore.isEditingAzureIdp,
    idpInfo.type,
  ]);

  const isCtaButtonDisabled = useMemo(() => {
    // When creating a Directory disable the button if no type was selected
    // or no name was typed
    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.DIRECTORY_INFO.id) {
      return !directoryInfo.type || !directoryInfo.name;
    }

    // When creating an IdP if a card was not selected, or we're making the request
    // or we're doing a redirect to azure, disable the button
    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SELECT_IDP.id) {
      return !idpInfo.type || directorySetupStore.directoryStore.isLoading || isDoingExternalAuth;
    }

    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id) {
      if (
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id
      ) {
        // If we're configuring a SAML IdP and we don't have a metadata file
        // disable the button
        if (directorySetupStore.idp?.isSaml) {
          return !directorySetupStore.data.metadataFile;
        }

        // Only saml IdPs have a metadata file requirement
        return false;
      }

      // Since we are creating an IdP from scratch and we already set the default
      // jit settings we always have this button enabled.
      return false;
    }

    return true;
  }, [
    directoryInfo.type,
    directoryInfo.name,
    directorySetupStore.directoryStore.isLoading,
    directorySetupStore.idp?.isSaml,
    directorySetupStore.data.metadataFile,
    idpInfo,
    isDoingExternalAuth,
    sectionsStepManager.currentStep.id,
    setupAuthenticationStepManager.currentStep.id,
  ]);

  return {
    ctaButtonLabelId,
    isCtaButtonDisabled,
  };
};

export default useButtons;
