import {useAsyncModel} from '@pandora/react-async-model';
import {useOrganizationsPolicies} from '@pandora/react-data-source-jil';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';

/**
 * A hook that provides the current organization's policies, and refresh methods.
 *
 * @returns {Object} state - Object with state variables
 *          {Array<Policy>} state.policies - the organization's policies
 *          {Error} state.error - the error if fetching fails
 *          {Boolean} state.isLoading - true if in the middle of fetching data
 *          {Function<void>} state.refreshPolicies - Callback function to tell the hook to refresh its data.
 */
const useAccessRequestPolicies = () => {
  const {getOrganizationsPolicies} = useOrganizationsPolicies();

  const loadPolicies = useCallback(async () => {
    const response = await getOrganizationsPolicies({
      orgId: rootStore.organizationStore.activeOrgId,
    });
    return response.json();
  }, [getOrganizationsPolicies]);

  const {
    error,
    isLoading,
    model: policies,
    updateModel: updatePolicies,
  } = useAsyncModel({
    loadFn: loadPolicies,
  });

  return {
    error,
    isLoading,
    policies,
    refreshPolicies: () => {
      updatePolicies(loadPolicies);
    },
  };
};

export default useAccessRequestPolicies;
