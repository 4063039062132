import {TextArea} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {textPermissionPropType} from '../../../prop-types/configurationGroupsPropTypes';

const textToPermissions = (text, originalPermissions) => {
  // set makes the lines unique
  const lines = [...new Set(text.split(/,|\r\n|\r|\n/g).filter((line) => line))];

  return lines.map((line, index) =>
    index < originalPermissions.length
      ? {
          ...originalPermissions[index],
          text: line,
        }
      : {
          text: line,
        }
  );
};

const permissionsToText = (permissions) =>
  permissions.map((permission) => permission.text).join('\n');

const PermissionsTextForm = ({onChange, permissions}) => {
  const [textValue, setTextValue] = useState(permissionsToText(permissions));

  // store the original permissions that were passed to this component - these are used
  // to compute the new permissions
  // if we didn't do this, then if the user deleted all lines, then pasted back the same
  // lines again, each permission object in the array wouldn't have the id. This would
  // lead to the PATCH request containing operations to delete the id key, which ANGELS
  // does not like
  const [originalPermissions] = useState(permissions);

  const textChange = (newText) => {
    const newPermissions = textToPermissions(newText, originalPermissions);
    onChange(newPermissions);
    setTextValue(newText);
  };

  return (
    <TextArea
      /* accessibility todo: should have label */
      height="size-2400"
      onChange={textChange}
      value={textValue}
      width="100%"
    />
  );
};

PermissionsTextForm.propTypes = {
  /**
   * Callback when the permissions are changed. This takes an argument of the new permissions.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The text permissions to edit.
   */
  permissions: PropTypes.arrayOf(textPermissionPropType).isRequired,
};

export default PermissionsTextForm;
