import {ActionMenu, Item, Text} from '@adobe/react-spectrum';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import useDirectoryListActions, {
  DIRECTORY_LIST_ACTIONS,
} from 'features/settings/components/directories/directory-list/directory-list-table/directory-list-action-menu/useDirectoryListActions';

const DirectoryListActionMenu = () => {
  const {exportToCsv} = useDirectoryListActions();

  const [disabledActions, setDisabledActions] = useState(new Set([]));

  const setActionEnabled = (action, enabled) => {
    setDisabledActions((previousDisabledActions) => {
      const currentDisabledActions = new Set(previousDisabledActions);

      if (enabled) {
        currentDisabledActions.delete(action);
      } else {
        currentDisabledActions.add(action);
      }

      return currentDisabledActions;
    });
  };

  const onAction = async (action) => {
    setActionEnabled(action, false);

    // add future actions in else-ifs
    if (action === DIRECTORY_LIST_ACTIONS.EXPORT_TO_CSV) {
      await exportToCsv();
    }

    setActionEnabled(action, true);
  };

  return (
    <ActionMenu
      disabledKeys={disabledActions}
      isQuiet
      /* needed to align menu in button group */
      marginStart="size-200"
      onAction={onAction}
    >
      <Item key={DIRECTORY_LIST_ACTIONS.EXPORT_TO_CSV}>
        <Text>
          <FormattedMessage id="settings.directories.directoryListActionMenu.buttons.exportToCsv" />
        </Text>
      </Item>
    </ActionMenu>
  );
};

export default DirectoryListActionMenu;
