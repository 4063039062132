(function () {
  /**
   * @ngdoc module
   * @name app.core.asset-migration-policy
   * @description Module which defines asset migration policy related models
   */
  angular.module('app.core.asset-migration-policy', [
    'app.core.api.jil',
    'app.core.message',
    'app.core.model-cache',
    'binky.core.lodash',
  ]);
})();
