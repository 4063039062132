(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appIdpChooser', {
    bindings: {
      description: '@',
      idps: '<',
      onIdpSelect: '&',
    },
    controller,
    templateUrl: 'app/widgets/directories/idp-chooser/idp-chooser.component.html',
  });

  /* @ngInject */
  function controller(_, feature) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onIdpChange,
    });

    function $onInit() {
      // eslint-disable-next-line lodash/prefer-over-quantifier -- this is simpler to read
      vm.idpList = _.filter(vm.idps, (idp) => isIdpEnabled(idp) && isIdpVisible(idp));
    }

    function onIdpChange(cardId) {
      vm.onIdpSelect({idp: {id: cardId}});
    }

    /**
     * Checks if an IdP is visible or not based on a given predicate. If you want
     * to hide/show an IdP based on a feature flag use the requires/hidesWhenFeatureFlag
     * prroperties of the IdP object.
     *
     * @param {Object} idp The idp to do the check against.
     *
     * @return {boolean} True if the idp should be visible, false otherwise.
     */
    function isIdpVisible(idp = {}) {
      return _.isFunction(idp.isVisible) ? idp.isVisible() : true;
    }

    /**
     * Checks if an IdP should be displayed based on the `requiresFeatureFlag`
     * and `hideWhenFeatureFlag` property values. Requires property takes
     * precedence
     *
     * @param {Object} idp An IdP card object to be checked for flag presence
     *
     * @return {boolean} True if no flag condition present otherwise depends on condition
     */
    function isIdpEnabled(idp = {}) {
      if (Object.prototype.hasOwnProperty.call(idp, 'requiresFeatureFlag')) {
        return feature.isEnabled(idp.requiresFeatureFlag);
      }

      if (Object.prototype.hasOwnProperty.call(idp, 'hideWhenFeatureFlag')) {
        return feature.isDisabled(idp.hideWhenFeatureFlag);
      }

      return true;
    }
  }
})();
