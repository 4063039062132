import {Heading} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {ImageIcon} from '@pandora/react-image-icon';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './EduSyncSourceCard.pcss';

const EduSyncSourceCard = ({description, id, goUrl, logo, name, selected}) => {
  const intl = useIntl();
  return (
    <Card
      allowsSelection={false}
      data-testid={`edu-sync-card-${id}`}
      selected={selected}
      size="L"
      styleName="provider-card"
    >
      <div styleName="provider-card-body">
        <ImageIcon alt="" size="XXXL" src={logo} styleName="provider-card-logo" />
        <Heading
          level="2"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed smaller font size
          UNSAFE_className={styles['provider-card-title']}
        >
          <FormattedMessage id={name} />
        </Heading>
        <p>
          <FormattedMessage id={description} />
        </p>
        <GoUrl
          name={goUrl}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need special styles
          UNSAFE_className={styles['provider-card-go-url']}
        >
          {intl.formatMessage({id: 'settings.common.learnMoreAboutRosterSync'})}
        </GoUrl>
      </div>
    </Card>
  );
};

EduSyncSourceCard.propTypes = {
  description: PropTypes.string.isRequired,
  goUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default EduSyncSourceCard;
