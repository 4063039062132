/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.bulk-operations:appJobResultErrorRow
 *
 * @description A Result detail error row in the Result detail section of the
 *   Operation Result details drawer.
 *
 * @binding {String} count - number of times error occurred
 * @binding {String} jobId - ID of the Job that contained this error result
 * @binding {PageContext} pageContext - the pageContext controlling where links to
 *   deficit reports are sent to
 * @binding {JOB_ITEM_FAILURE_TYPE} type - type of error item to display
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appJobResultErrorRow', {
    bindings: {
      count: '@',
      jobId: '@',
      pageContext: '<',
      type: '@',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/detail-sections/result-detail-section/job-result-error-row.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $state,
    _,
    feature,
    JOB_ITEM_FAILURE_TYPE,
    jobListHelper,
    PAGE_TARGET,
    PAGE_TARGET_TYPE
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getDeficitReportLink,
      isItemLicenseCountExceeded,
    });

    function $onInit() {
      vm.translationKeySuffix = jobListHelper.getFailureMessageTranslateKeySuffix(this.type);
    }

    ////////

    function getDeficitReportLink() {
      let pageLink;
      switch (vm.pageContext.target) {
        case PAGE_TARGET.PRODUCT:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              throw new Error('Angular products has been removed');
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              throw new Error('Angular products has been removed');
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.USER_GROUP:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              pageLink = $state.href('users.user-group-license-deficit-report', {
                jobId: vm.jobId,
                userGroupId: vm.pageContext.targetId,
              });
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.ORGANIZATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              pageLink = $state.href('users.license-deficit-report', {
                jobId: vm.jobId,
              });
              break;
            default:
              logUnimplementedError();
          }
          break;
        default:
          logUnimplementedError();
      }

      return pageLink;

      ////////

      function logUnimplementedError() {
        $log.debug(
          `appJobResultErrorRow: License deficit report view unimplemented for provided pageContext: ${vm.pageContext}`
        );
      }
    }

    function isItemLicenseCountExceeded() {
      return vm.type === JOB_ITEM_FAILURE_TYPE.ALLOWABLE_LICENSE_COUNT_EXCEEDED;
    }
  }
})();
