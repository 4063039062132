const ALL_PRODUCTS_AND_SERVICES_ITEM = 'all-products-and-services';
const LICENSE_GROUP_ITEM = 'license-group-item';
const PRODUCT_DETAILS_ITEM = 'product-details-item';
const PRODUCT_GROUP_ITEM = 'product-group';

export {
  ALL_PRODUCTS_AND_SERVICES_ITEM,
  LICENSE_GROUP_ITEM,
  PRODUCT_DETAILS_ITEM,
  PRODUCT_GROUP_ITEM,
};
