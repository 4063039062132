import {PAGE_TARGET} from '@admin-tribe/binky';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import AddAdminsToLicenseGroupModal from 'common/components/add-admin-modals/AddAdminsToLicenseGroupModal';
import AddAdminsToProductModal from 'common/components/add-admin-modals/AddAdminsToProductModal';
import AddAdminsToUserGroupModal from 'common/components/add-admin-modals/AddAdminsToUserGroupModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import AddAdminModal from 'features/users/components/add-admin-modal/AddAdminModal';

// The 'Add admin' button, and the associated modal to add new admins to one of
// the Administrators page, the User group admin sub-page, a Product admin sub-page or
// a Product profile admin sub-page.
const AddAdminButton = observer(() => {
  const {addMemberProps, canAddMember, pageContext, store} = useMemberListContext();
  const [isOpen, setIsOpen] = useState(false);

  const commonProps = {
    isOpen,
    onCancel,
    onSuccess,
    orgId: store.orgId,
  };

  function onCancel() {
    setIsOpen(false);
  }

  // Filter/page params are not changed.
  function onSuccess() {
    store.load();
    setIsOpen(false);
  }

  // Render the 'Add admins' modal for the given pageConext.
  // Certain products have admin roles and if this is the case, then the AddAdminModal
  // must be used so that roles can be selected. (prop productToAddAdmin is defined)
  function renderModal() {
    if (pageContext.isOrganizationTarget() || addMemberProps?.roles) {
      return <AddAdminModal {...commonProps} {...addMemberProps} />;
    }

    if (pageContext.target === PAGE_TARGET.USER_GROUP) {
      return <AddAdminsToUserGroupModal {...commonProps} userGroupId={pageContext.targetId} />;
    }

    if (pageContext.isProductTarget()) {
      return <AddAdminsToProductModal {...commonProps} productId={pageContext.targetId} />;
    }

    if (pageContext.isProductConfigurationTarget()) {
      return (
        <AddAdminsToLicenseGroupModal
          {...commonProps}
          licenseGroupId={pageContext.targetId}
          productId={pageContext.targetParentId}
        />
      );
    }

    throw new Error('AddAdminButton: unrecognized target');
  }

  return (
    canAddMember && (
      <>
        <Button data-testid="add-admin-btn" onPress={() => setIsOpen(true)} variant="accent">
          <FormattedMessage id="common.adminListTableSection.button.addAdmin" />
        </Button>
        {isOpen && renderModal()}
      </>
    )
  );
});

export default AddAdminButton;
