import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS, feature} from '@admin-tribe/binky';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {checkDeHasState} from 'features/settings/common/utils/domainEnforcementUtils';
import DirectoryDomainEnforcementStatusWithLink from 'features/settings/components/directory-overview-card/DirectoryDomainEnforcementStatusWithLink';

import './DirectoryOverviewCard.pcss';

import DirectoryStatus from './DirectoryStatus';

const DIRECTORY_CONFIGURATION = {
  AUTHENTICATION: 'authentication',
  AUTO_ACCOUNT_CREATION: 'autoAccountCreation',
  DOMAIN_ENFORCEMENT: 'domainEnforcement',
  DOMAINS: 'domains',
  SYNC: 'sync',
};

const DirectoryOverviewCard = observer(
  ({
    authenticationStatus,
    autoAccountCreationStatus,
    domainsStatus,
    domainEnforcement,
    syncStatus,
    goToTab,
  }) => {
    const intl = useIntl();

    let DIRECTORY_OVERVIEW_LIST = [
      {name: DIRECTORY_CONFIGURATION.AUTHENTICATION, status: authenticationStatus},
      {
        count: autoAccountCreationStatus.count,
        name: DIRECTORY_CONFIGURATION.AUTO_ACCOUNT_CREATION,
        status: autoAccountCreationStatus.status,
      },
      {name: DIRECTORY_CONFIGURATION.DOMAINS, status: domainsStatus},
      {name: DIRECTORY_CONFIGURATION.SYNC, status: syncStatus},
    ];

    const DOMAIN_ENFORCEMENT_LIST_ITEM = {
      // stateEndsAt is when the notify state ends and domainEnforcement is enabled
      enablementDate: domainEnforcement?.stateEndsAt,
      name: DIRECTORY_CONFIGURATION.DOMAIN_ENFORCEMENT,
      state: domainEnforcement?.state,
    };

    if (feature.isEnabled('temp_domain_enforcement')) {
      DIRECTORY_OVERVIEW_LIST = [...DIRECTORY_OVERVIEW_LIST, DOMAIN_ENFORCEMENT_LIST_ITEM];
    }

    const isAutoAccountCreation = (item) => item.name === 'autoAccountCreation';
    const isDomainEnforcement = (item) => item.name === DIRECTORY_CONFIGURATION.DOMAIN_ENFORCEMENT;

    const handleGoToTab = (item) =>
      isAutoAccountCreation(item)
        ? goToTab(DIRECTORY_CONFIGURATION.AUTHENTICATION)
        : goToTab(item.name);

    return (
      <View
        backgroundColor="gray-50"
        borderColor="gray-200"
        borderRadius="small"
        borderWidth="thin"
        height="auto"
        width="size-6000"
      >
        <Flex direction="column" height="100%">
          <View flex="1" padding="size-200">
            <Flex data-testid="card-content-wrapper" direction="column" gap="size-120">
              <Heading level={3} marginTop="size-0" minHeight="size-300">
                {intl.formatMessage({id: 'settings.directoryOverview.card.title'})}
              </Heading>
              {DIRECTORY_OVERVIEW_LIST.map((item) => (
                <React.Fragment key={item.name}>
                  {isDomainEnforcement(item) ? (
                    <DirectoryDomainEnforcementStatusWithLink
                      data-testid={`${item.name}-status`}
                      enablementDate={item.enablementDate}
                      goToTab={() => handleGoToTab(item)}
                      hasLink={checkDeHasState(
                        item.state,
                        Object.values(DIRECTORY_DOMAIN_ENFORCEMENT_STATUS)
                      )}
                      state={item.state}
                      title={`settings.directoryOverview.card.${item.name}`}
                    />
                  ) : (
                    <DirectoryStatus
                      count={isAutoAccountCreation(item) ? item.count : null}
                      data-testid={`${item.name}-status`}
                      goToTab={() => handleGoToTab(item)}
                      status={item.status}
                      title={`settings.directoryOverview.card.${item.name}`}
                    />
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </View>
        </Flex>
      </View>
    );
  }
);

DirectoryOverviewCard.propTypes = {
  authenticationStatus: PropTypes.string,
  autoAccountCreationStatus: PropTypes.shape({count: PropTypes.number, status: PropTypes.string}),
  domainsStatus: PropTypes.string,
  goToTab: PropTypes.func,
  syncStatus: PropTypes.string,
};

export default DirectoryOverviewCard;
export {DIRECTORY_CONFIGURATION};
