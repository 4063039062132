import {UserGroup} from '@admin-tribe/binky';
import {Button} from '@adobe/react-spectrum';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canAssignProductProfileToUserGroup} from 'core/user-group/access/userGroupAccess';
import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';
import AssignProductProfilesToUserGroupsModal from 'features/users/user-groups/assign-product-profiles-to-user-groups/AssignProductProfilesToUserGroupsModal';

/**
 * The Product profile table 'Assign profile' button.
 */
const AssignProfileButton = () => {
  const intl = useIntl();
  const {pageContext, onTableSectionChange} = useProductProfileTableContext();
  const [isOpen, setIsOpen] = useState(false);
  const orgId = rootStore.organizationStore.activeOrgId;

  function onSave() {
    // Re-get the list with the current settings. Any search and selection should remain.
    onTableSectionChange({
      action: TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED,
    });

    setIsOpen(false);
  }

  return (
    canAssignProductProfileToUserGroup() && (
      <>
        <Button data-testid="assign-profile-btn" onPress={() => setIsOpen(true)} variant="accent">
          {intl.formatMessage({id: 'products.productProfileTable.buttons.assignProfile'})}
        </Button>
        {isOpen && (
          <AssignProductProfilesToUserGroupsModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSuccess={onSave}
            orgId={orgId}
            userGroup={new UserGroup({id: pageContext.targetId, orgId})}
          />
        )}
      </>
    )
  );
};

export default AssignProfileButton;
