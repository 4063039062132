/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseImpactFormSection
 *
 * @description The Impact section for the Issue Description form for the Create Case modal wizard.
 */
(function () {
  angular.module('app.widgets.support').component('appCaseImpactFormSection', {
    bindings: {
      onCardSelect: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-impact-form-section/case-impact-form-section.component.html',
  });

  function controller(_, CARD_BUTTON_VARIANT, onesieSrc2) {
    const vm = this;
    const {SUPPORT_TICKET_IMPACT} = onesieSrc2.support.constants;

    _.assign(vm, {
      CARD_BUTTON_VARIANT,
      onSelect,
    });

    function onSelect(buttonId) {
      const buttonIdMiloMap = {
        'case-impact-large-id': SUPPORT_TICKET_IMPACT.LARGE,
        'case-impact-medium-id': SUPPORT_TICKET_IMPACT.MEDIUM,
        'case-impact-small-id': SUPPORT_TICKET_IMPACT.SMALL,
      };

      vm.onCardSelect({
        impact: buttonIdMiloMap[buttonId],
      });
    }
  }
})();
