import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * @description the button for opening the modal to change encryption status of an organization.
 */
const EncryptionSettingsTriggerButton = ({onEnableConfirm, onRevokeConfirm, setting}) => {
  const intl = useIntl();

  const onPress = async (close) => {
    close();
    if (setting === 'revoke') {
      await onRevokeConfirm();
    } else {
      await onEnableConfirm();
    }
  };

  return (
    <DialogTrigger>
      <ActionButton data-testid="encryption-settings-button">
        {intl.formatMessage({
          id: `settings.encryptionSettings.button.${setting}`,
        })}
      </ActionButton>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- required more lines for this block */}
      {(close) => (
        <Dialog data-testid="encryption-settings-dialog">
          <Heading>
            {intl.formatMessage({
              id: `settings.encryptionSettings.confirm.${setting}.header`,
            })}
          </Heading>
          <Divider />
          <Content>
            <Text>
              {intl.formatMessage({
                id: `settings.encryptionSettings.confirm.${setting}.description1`,
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: `settings.encryptionSettings.confirm.${setting}.description2`,
              })}
            </Text>
          </Content>
          <ButtonGroup>
            <Button data-testid="dialog-cancel-button" onPress={close} variant="secondary">
              {intl.formatMessage({
                id: 'settings.encryptionSettings.confirm.cancelButton',
              })}
            </Button>
            <Button
              autoFocus
              data-testid="dialog-confirm-button"
              onPress={() => onPress(close)}
              variant="accent"
            >
              {intl.formatMessage({
                id: `settings.encryptionSettings.confirm.${setting}.confirmButton`,
              })}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
};

EncryptionSettingsTriggerButton.propTypes = {
  /**
   * Callback to invoke when the confirm button is selected to enable encryption.
   */
  onEnableConfirm: PropTypes.func.isRequired,
  /**
  /**
   * Callback to invoke when the confirm button is selected to revoke encryption.
   */
  onRevokeConfirm: PropTypes.func.isRequired,
  /**
   * The modal that is required (based on organization's current encryption status).
   * Either 'revoke' or 'enable'.
   */
  setting: PropTypes.oneOf(['enable', 'revoke']).isRequired,
};

export default EncryptionSettingsTriggerButton;
