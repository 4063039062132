import {ButtonGroup, Flex, Image, Link, Text, View} from '@adobe/react-spectrum';
import {ModalContainer, ModalContent, ModalDialog, ModalHeading} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {mapContent} from 'common/services/sophia/utils/utils';

import {WidgetProviderConditionalWrapper, getButtons} from './utils';
import widgetIndex from './widgets/widgetIndex';

/**
 * helper to transform user input
 *
 * We allow user input for the body of the modal to have a few special tags.
 * These are transformed into React components.
 *
 * @param {Object} intl - intl object
 * @param {String} text - text to transform
 * @param {String} [learnMoreUrl] - URL to render for a link
 *
 * @returns {String | Array<React.ReactNode>} transformed text to be rendered
 *     directly inside React
 */
const transformText = (intl, text, learnMoreUrl) =>
  intl.formatMessage(
    {defaultMessage: text, id: 'dummy.invalid'},
    {
      bold: (str) => <Text UNSAFE_style={{fontWeight: 'bold'}}>{str}</Text>,
      link: (str) => (
        <Link>
          <a href={learnMoreUrl} rel="noreferrer" target="_blank">
            {str}
          </a>
        </Link>
      ),
      para: (str) => <View elementType="p">{str}</View>,
    }
  );

/**
 * Simple Modal to demonstrate displaying Sophia content.  This will be replaced in later PRs.
 */
const SophiaModal = ({closeModal, content: sophiaContent}) => {
  let Widget, WidgetProvider, widgetButtons;
  const intl = useIntl();
  const content = useMemo(() => mapContent(sophiaContent), [sophiaContent]);

  if (content.slotId && content.slotId in widgetIndex) {
    ({Provider: WidgetProvider, Widget, buttons: widgetButtons} = widgetIndex[content.slotId]);
  }

  const buttons = useMemo(
    () => getButtons(closeModal, content, widgetButtons),
    [closeModal, content, widgetButtons]
  );
  return (
    <WidgetProviderConditionalWrapper
      closeModal={closeModal}
      widgetData={content.slotOptions}
      WidgetProvider={WidgetProvider}
    >
      <ModalContainer>
        <ModalDialog
          analyticsModalId={`sophia-modal-${content.analyticsId}`}
          closeModal={closeModal}
          onCancel={closeModal}
          size={content.modalSize}
        >
          <ModalHeading marginBottom="size-200" UNSAFE_style={{fontWeight: '400'}}>
            {content.heading}
          </ModalHeading>
          <ModalContent>
            <Flex columnGap="size-200">
              <View flexBasis={0} flexGrow={content.bodyImageRatio}>
                {transformText(intl, content.body, content.bodyLearnMoreUrl)}
              </View>
              {content.imageUrl && (
                <Image
                  alt=""
                  flexBasis={0}
                  flexGrow={1}
                  objectFit="contain"
                  src={content.imageUrl}
                />
              )}
            </Flex>
            {(content.body2 && (
              <View>{transformText(intl, content.body2, content.bodyLearnMoreUrl)}</View>
            )) ||
              null}
            {WidgetProvider && (
              <View marginTop="size-100">
                <Widget />
              </View>
            )}
          </ModalContent>
          {/* Pandora modal supplies default buttons otherwise */}
          {buttons.length > 0 && <ButtonGroup>{buttons}</ButtonGroup>}
        </ModalDialog>
      </ModalContainer>
    </WidgetProviderConditionalWrapper>
  );
};

SophiaModal.propTypes = {
  /**
   * method to close the modal
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * content to show in the modal.
   * @link - https://wiki.corp.adobe.com/display/ONE/Sophia+modal
   */
  content: PropTypes.shape({
    data: PropTypes.objectOf(
      PropTypes.shape({
        item: PropTypes.shape({
          // body of the modal
          body: PropTypes.shape({
            plaintext: PropTypes.string.isRequired,
          }).isRequired,
          // width ratio of the body text and the image
          bodyImageRatio: PropTypes.number.isRequired,
          // URL for the singular link allowed in the body
          bodyLearnMoreUrl: PropTypes.string,
          // buttons to show in the footer.
          buttons: PropTypes.arrayOf(
            PropTypes.shape({
              // the action of the button
              action: PropTypes.string.isRequired,
              // label for the button
              label: PropTypes.string.isRequired,
              // button style variant
              variant: PropTypes.string,
            })
          ),
          // heading of the modal
          heading: PropTypes.string.isRequired,
          // URL for the image to show in the modal
          imageUrl: PropTypes.string,
          // size of the modal
          modalSize: PropTypes.oneOf(['S', 'M', 'L', 'workflow']).isRequired,
          // ID for the widget to show in the modal
          slotId: PropTypes.string,
          // options to pass to the widget
          // eslint-disable-next-line react/forbid-prop-types -- based on the slot type
          slotOptions: PropTypes.any,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};
export default SophiaModal;
