import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CONTAINER_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/binky';

class Notification {
  /**
   * @description Creates a new Notification for use.
   *
   * @param {Object} options - Notification object
   *   For full list of ANS notification attributes visit
   *   https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ANS&title=ANS+API+Reference
   * {Long} options.[created-timestamp] - Notification's created timestamp
   * {String} options.notification-id - Notification's id
   * {String} options.payload - Notification's payload string in the format:
   *    "{
   *      "org_id": "123"
   *      "status": "INFO"
   *      "message": "message found here"
   *      "learn_more_link": "link"
   *      "learn_more_ui_sref": "link"
   *    }"
   * {String} options.state - Notification's state, either "NEW" or "READ" for recent notifications,
   *    or "{"read":"boolean","seen":"boolean"}" for older notifications
   * {String} options.sub-type - Notification's sub-type
   * {String} options.type - Notification's type
   */
  constructor(options = {}) {
    Object.assign(this, {
      ansObject: options,
      createdTimestamp: options['created-timestamp'],
      id: options['notification-id'],
      payload: safeParseJsonObject(options.payload),
      state: parseState(options.state),
      subType: options['sub-type'],
      type: options.type,
    });
  }

  /** Public methods **/
  /**
   * @description Method to retrieve Sophia metadata if this has it.
   *    Sophia provides data in the format:
   *    metadata: {
   *      tracking-system-payload: {
   *        'CAMPAIGN_ID=20147,
   *        THEN_ID=20147_234287ActionBlock_0IfId_default_0,
   *        SURFACE_ID=one_console_notifications_2,
   *        ORDER_ID=0,
   *        VARIATION_ID=234287,
   *        TREATMENT_ID=PR-adc9316d-fbf1-4b61-8391-5678c1bb4abe,
   *        ACTIONBLOCK_ID=20147_234287ActionBlock_0,
   *        reasonCode=NOTIFICATION,
   *        VARIATION_NAME=User's name,
   *        CONTAINER_ID=NULL,
   *        ACTION_TYPE=SendNotification'
   *      }
   *    }
   *
   * @returns {SophiaResponse} Sophia response to use for analytics events
   */
  getSophiaResponse() {
    if (this.hasSophiaData()) {
      // Since props are not passed in as strings in the sophia payload,
      // we need to split the string instead of using parse
      const sophiaPayload = this.ansObject.metadata['tracking-system-payload'];
      // Removing '{' and '}'
      const prunedResponse = sophiaPayload.slice(1, sophiaPayload.length - 1);
      const keyValuePairs = prunedResponse.split(', ');
      const sophiaPayloadObj = {};
      const sophiaPayloadObjKeys = {
        ACTIONBLOCK_ID,
        CAMPAIGN_ID,
        CONTAINER_ID,
        SURFACE_ID,
        TREATMENT_ID,
        VARIATION_ID,
      };
      keyValuePairs.forEach((pair) => {
        const splitPair = pair.split('=');
        const key = sophiaPayloadObjKeys[splitPair[0]];
        if (key) {
          sophiaPayloadObj[key] = splitPair[1];
        }
      });
      return sophiaPayloadObj;
    }
    return undefined;
  }

  /**
   * @description Method to check if the notification contains Sophia metadata in the format:
   *    metadata: {
   *      tracking-system-payload: {
   *        ... Sophia attributes
   *      }
   *    }
   * @returns {Boolean} true if the notification contains Sophia metadata.
   */
  hasSophiaData() {
    return !!(
      this.ansObject.metadata &&
      this.ansObject.metadata['tracking-system-payload'] &&
      containsSophiaAttributes(this.ansObject.metadata['tracking-system-payload'])
    );
  }

  /**
   * @description Method to update the notification's state as the opposite of its current state.
   */
  negateState() {
    this.state.read = !this.state.read;
  }

  /**
   * @description Method to return a version of this object which can be written to ANS.
   * @returns {Object} the object with payload and state written back to strings.
   */
  serialize() {
    // Update ANS object to align with any possible changes that occurred to notification
    return Object.assign(this.ansObject, {
      payload: JSON.stringify(this.payload),
      state: serializeState(this.state),
    });
  }
}

/** Private Methods **/

function containsSophiaAttributes(metadataString) {
  const sophiaPayloadKeys = [
    'ACTIONBLOCK_ID',
    'CAMPAIGN_ID',
    'CONTAINER_ID',
    'SURFACE_ID',
    'TREATMENT_ID',
    'VARIATION_ID',
  ];
  return sophiaPayloadKeys.some((key) => metadataString.includes(key));
}

function parseState(stateString) {
  const state = safeParseJsonObject(stateString);
  // Different states depending on old vs new notifications
  return {read: state.read === 'true' || stateString === 'READ'};
}

function safeParseJsonObject(str) {
  try {
    return JSON.parse(str || '{}');
  } catch (error) {
    return {};
  }
}

function serializeState(state) {
  return state.read ? 'READ' : 'NEW';
}

export default Notification;
