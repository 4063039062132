(function () {
  /**
   * @deprecated should use the React Spectrum Tag
   * @ngdoc component
   * @name app.widgets.tag:appTag
   * @description A tag component to show a given text styled based on one of the supported colors
   *
   * @param {string} [color] Optional color of tag.
   * One of: 'blue', 'green', 'grey', 'orange', and 'red'.
   * If not specified, or an invalid color is specified, the default Coral tag color is used. This tag is always size 'M'.
   * @param {string} label Translated label text.
   * @param {string} [size] size of that tag. One of 'L', 'M' (default), 'S', 'XS'. Applies only to colored tags.
   * @example <app-tag
   *            color="green"
   *            label="label"
   *            size="S">
   *          </app-tag>
   */
  angular.module('app.widgets.tag').component('appTag', {
    bindings: {
      color: '@?',
      label: '@',
      size: '@?',
    },
    controller,
  });

  function controller(_, $element, $timeout) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onInit() {
      vm.tag = new Coral.Tag();
      updateBindings();
      $element.append(vm.tag);
    }

    function $onChanges() {
      if (vm.tag) {
        updateBindings();
      }
    }

    ////////////

    function isColoredTag() {
      return _.includes(['blue', 'green', 'grey', 'orange', 'red'], vm.color);
    }

    function updateBindings() {
      // We are using the coral-tag with color='' (or color not specified or invalid color) incorrectly.
      // For this variant, Coral applies style '_coral-Tags-item'.
      // This variant is expected to be used in a coral-taglist, which we are not, is not sizable but is truncate-able.
      // _coral-Tags-item sets 'float: left' since the expectation is that this tag is in a coral-taglist which will
      // contain the float. This tag also has hover and focus states since it expects to be part of a taglist which
      // includes kb navigation.

      // If the coral-tag has a valid color, Coral applies style '_coral_Label' which by default is inline, can be sized
      // but can't be truncated.

      vm.tag.set({
        color: vm.color,
        label: {
          innerHTML: vm.label,
        },
        size: vm.size,
      });

      // classList.toggle is not supported natively for IE11 so use add/remove instead
      if (isColoredTag() && vm.size === 'XS') {
        vm.tag.classList.add('size-xs');
      } else {
        vm.tag.classList.remove('size-xs');
      }

      // If the text is truncated, add a tooltip via the title attribute.
      // The timeout is required to allow the stack to clear so measurements are accurate.
      $timeout(() => {
        if (vm.tag.label.offsetWidth < vm.tag.label.scrollWidth) {
          vm.tag.set({
            title: _.trim(angular.element(vm.tag.label).text()),
          });
        }
      });
    }
  }
})();
