/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated For free offers, use src2 FreeOfferCart.js
   *
   * @ngdoc factory
   * @name app.core.cart:Cart
   * @description model to preview/submit a new purchase authorization.
   *
   * https://wiki.corp.adobe.com/display/ecommerceservices/Exchange+Model+between+UI+and+CCS+for+One+Console
   */
  angular.module('app.core.cart').factory('Cart', getCartModel);

  /* @ngInject */
  function getCartModel(
    $interval,
    $log,
    $q,
    $rootScope,
    _,
    auth,
    binkySrc2,
    CART_ERROR_STATUS,
    CART_EVENT,
    CART_STATUS,
    CART_TYPE,
    ccs,
    CONTRACT_BUYING_PROGRAM,
    FULFILLMENT_EVENT_REFRESH_MANAGER_EVENT,
    FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS,
    fulfillmentEventRefreshManager,
    jsUtils,
    MODEL,
    modelCache,
    ORDER_TYPE,
    OrganizationManager,
    PAYLOAD_KEY,
    price,
    PRODUCT_BUYING_PROGRAM,
    promiseUtils,
    QUERY_PARAM_KEY
  ) {
    const {
      BILLABLE_ITEMS,
      BILLING_SUMMARY,
      CART_ID,
      CART_TYPE_KEY,
      CONTRACT_DETAILS,
      COUNTRY,
      CURRENCY,
      EXTERNAL_CONTRACT_ID,
      LAST_MODIFIED_BY_ID,
      OWNER_ID,
      PAYMENT_AUTHORIZATION,
      PO_NUMBER,
      PROGRAM_CONTRACT_ID,
      STATUS,
      VALIDATION_TOKEN,
    } = PAYLOAD_KEY;

    let DELAY_INTERVAL, fulfillmentEventRefreshHandler;
    let targetOfferIds = [];

    class Cart {
      /**
       * For free offers, use src2 FreeOfferCart
       * @description creates a new Cart object
       * @param {Object} options - options to configure new Cart
       * @param {Object} options.lastModifiedById - admin who's making the request
       *    (the user has to be either 'primary admin' or 'secondary admin' on the team contract to create the cart)
       * @param {Object} options.country - country of the org
       * @param {Object} options.contractId - contract id
       * @param {Object} options.ownerId - the contract owner id
       */
      constructor(options) {
        this.lastModifiedById = options.lastModifiedById;
        this.billingSummary = initializeBillingSummary(options.country, options.contractId);
        // The contractId is the cache key for the renewal order. See Key() for more information.
        this.contractId = options.contractId;
        this.ownerId = options.ownerId;
      }

      /**
       * @description Determine whether the renewing license count for the given products
       *    are greater or equal to current license count
       * @param {ProductList} productList to check whether all licenses are renewed
       * @returns {Boolean} True if all the licenses for the given products are renewed
       */
      areAllLicensesRenewed(productList) {
        return _.every(productList.items, this.areAllLicensesRenewedForProduct.bind(this));
      }

      /**
       * @description Determine whether the renewing license count for the given product
       *    is greater or equal to current license count
       * @param {Product} product to check whether all licenses are renewed
       * @returns {Boolean} True if all the licenses for the given product are renewed
       */
      areAllLicensesRenewedForProduct(product) {
        return (
          this.getRenewingLicenseCountByOfferId(product.offerId) >=
          product.getAssignableLicenseCount()
        );
      }

      /**
       * @description Determine whether the renewing license count for the given products
       *    are greater or equal to provisioned license count
       * @param {ProductList} productList to check whether all provisioned licenses are renewed
       * @returns {Boolean} True if all the provisioned licenses for the given products are renewed
       */
      areAllProvisionedLicensesRenewed(productList) {
        return _.every(
          productList.items,
          this.areAllProvisionedLicensesRenewedForProduct.bind(this)
        );
      }

      /**
       * @description Determine whether the renewing license count for the given product
       *    is greater or equal to provisioned license count
       * @param {Product} product to check whether all provisioned licenses are renewed
       * @returns {Boolean} True if all the provisioned licenses for the given product are renewed
       */
      areAllProvisionedLicensesRenewedForProduct(product) {
        return (
          this.getRenewingLicenseCountByOfferId(product.offerId) >=
          _.get(product, 'provisionedQuantity', 0)
        );
      }

      /**
       * @description Gets the billableItems
       * @param {Boolean} hasSubmitted Whether the cart has been submitted. Default is false
       *
       * @returns {Array} the array of billableItems
       */
      getBillableItems({hasSubmitted = false} = {}) {
        return hasSubmitted
          ? _.get(this, 'purchasedBillingSummaryData.billable_items')
          : _.get(this, 'responseBillingSummaryData.billable_items');
      }

      /**
       * @description Gets the billableItems in the form of cart items
       *
       * @returns {Array<Object>} an array of cartItems
       *     Example: [{
       *       offerId: '0159BEA90DDD83C74FC928EE249071B1',
       *       quantity: 2
       *     }, {
       *       offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
       *       quantity: 5
       *     }]
       */
      getBillableItemsAsCartItems() {
        return transformBillableItemsToCamelCase(this.getBillableItems());

        ////////

        function transformBillableItemsToCamelCase(items) {
          return _.map(items, (item) =>
            _.omitBy(
              {
                ignoreOfferMapping: item.ignore_offer_mapping,
                offerId: item.offer_id,
                quantity: item.quantity,
              },
              _.isUndefined
            )
          );
        }
      }

      /**
       * Get billing data for a specific offer in the cart
       * @param {Boolean} [hasSubmitted] whether the cart has been submitted.
       *   This is needed to figure out where the billing_summary is stored in.
       * @param {String} offerId Offer ID in the cart
       * @param {Boolean} [useRenewalPricing] - true to use the renewal_price_details, false to use price_details
       * @returns {Object} Billing data if the offer exists. Return undefined otherwise.
       */
      getBillingDataForOffer({hasSubmitted = false, offerId, useRenewalPricing = false}) {
        const billableItems = _.find(this.getBillableItems({hasSubmitted}), {offer_id: offerId});
        const priceDetailsKey = useRenewalPricing ? 'renewal_price_details' : 'price_details';
        return _.get(billableItems, priceDetailsKey);
      }

      /**
       * @description Retrieves the currency to use
       * @param {Boolean} [useNextBillingCurrency] Determines whether to get the currency for the next billing cycle
       *     or current cycle. Defaults to using the next billing currency.
       * @returns {Currency} The currency object from the cart response. Defaults
       *     to the currency if no next currency is avaiable.
       */
      getCurrency(useNextBillingCurrency = true) {
        return useNextBillingCurrency &&
          _.has(this.responseBillingSummaryData, 'currency_next_billing')
          ? _.get(this.responseBillingSummaryData, 'currency_next_billing')
          : _.get(this.responseBillingSummaryData, 'currency');
      }

      /**
       * @description Gets the new total and formats it according to the currency formatString.
       * @param {Boolean} includesTax - true if we should use the amount_with_tax, false for amount_without_tax
       * @param {Boolean} useRenewalPricing - true to use the new_renewal_total, false to use total
       * @returns {String} the formatted price for the new total
       */
      getNewTotalPrice(includesTax, useRenewalPricing) {
        const totalKey = useRenewalPricing ? 'new_renewal_total' : 'total';
        const amountKey = includesTax ? 'amount_with_tax' : 'amount_without_tax';
        const amount = _.get(this, `responseBillingSummaryData.${totalKey}.${amountKey}`);
        if (amount) {
          return price.format(amount, this.getCurrency());
        }
        return undefined;
      }

      /**
       * @description Gets the new total, with tax, and formats it according
       *   to the currency formatString.
       * @param {Boolean} useRenewalPricing - true to use the new_renewal_total, false to use total
       * @returns {String} the formatted price for the contract's new total, including tax
       */
      getNewTotalPriceWithTax(useRenewalPricing) {
        return this.getNewTotalPrice(true, useRenewalPricing);
      }

      /**
       * @description Gets the Purchase Order number from the contract details response
       * @returns {String} The Purchase Order string
       */
      getPONumber() {
        return _.get(this.responseBillingSummaryData, 'contract_details.customer_po_number');
      }

      /**
       * @description Returns the proration summary object, when prorated information is available.
       *   If proration days is 0 or prorated currency is not present, then proration summary is undefined.
       *   Further adjustments may be done by https://jira.corp.adobe.com/browse/ONESIE-20211
       *
       * @returns {Object} prorationSummary - the proration summary of the total
       *          {Object} prorationSummary.currency - the currency object for prorated amounts
       *          {Number} prorationSummary.days - the prorated days
       *          {Number} prorationSummary.subtotal - the prorated amount_without_tax
       *          {String} prorationSummary.taxRate - the prorated tax rate in percentage
       *          {string} prorationSummary.tax - the prorated tax amount
       *          {string} prorationSummary.total - the prorated amount_with_tax
       *          {
       *             currency: {...},
       *             days: 5,
       *             subtotal: 100.00,
       *             taxRate: 10.00,
       *             tax: 10.00,
       *             total: 110.00
       *          }
       */
      getProrationSummary() {
        const proratedDays = _.get(
          this.responseBillingSummaryData,
          'contract_details.days_of_billing'
        );
        const billingPeriodStart = _.get(
          this.responseBillingSummaryData,
          'contract_details.billing_period_start'
        );
        const billingPeriodEnd = _.get(
          this.responseBillingSummaryData,
          'contract_details.billing_period_end'
        );
        const currency = _.get(this.responseBillingSummaryData, 'currency');
        if (proratedDays && _.has(this.responseBillingSummaryData, 'sub_total_next_billing')) {
          const proratedSubTotal = _.get(this.responseBillingSummaryData, 'sub_total', {});
          return {
            billingPeriodEnd,
            billingPeriodStart,
            currency,
            days: proratedDays,
            subtotal: proratedSubTotal.amount_without_tax,
            tax: proratedSubTotal.tax,
            taxRate: proratedSubTotal.tax_rate,
            total: proratedSubTotal.amount_with_tax,
          };
        }
        return undefined;
      }

      /**
       * @description Gets the provisioned sub total (current monthly total), with tax, and formats it according
       *   to the currency formatString.
       * @returns {String} the formatted price for the contract's current monthly total, including tax
       */
      getProvisionedSubTotalPriceWithTax() {
        if (_.has(this, 'responseBillingSummaryData.provisioned_sub_total.amount_with_tax')) {
          return price.format(
            _.get(this, 'responseBillingSummaryData.provisioned_sub_total.amount_with_tax'),
            _.get(this, 'responseBillingSummaryData.currency')
          );
        }
        return undefined;
      }

      /**
       * @description Gets the purchase authorization ID from the cart's purchase data
       * @returns {String} the purchase auth ID, if one exists
       */
      getPurchaseAuthId() {
        return _.get(
          this,
          'purchasedBillingSummaryData.billable_items[0].entitlement_details.purchase_auth_id'
        );
      }

      /**
       * @description Get the renewal order by making two api calls. We first get the cartId by passing
       *    the contract-id and order-type, and then use the cartId to get the renewal order.
       * @returns {Promise} resolves with ccs response if successful, else rejects with http error response
       */
      getRenewalOrder() {
        this.$promise = ccs.cart
          .get({
            [QUERY_PARAM_KEY.CONTRACT_ID]: this.contractId,
            [QUERY_PARAM_KEY.ORDER_TYPE]: ORDER_TYPE.RENEWAL_ORDER,
          })
          .$promise.then((response) => {
            this.cartId = response.id;
            return ccs.cart.get({cartId: this.cartId}).$promise;
          })
          .then((response) => {
            this.responseBillingSummaryData = transformBillingSummary(response.billing_summary);
            modelCache.put(MODEL.CONTRACTCART, this, this.key());
            $rootScope.$emit(CART_EVENT.RENEWAL_ORDER_REFRESHED, this);
            return this;
          })
          .catch((error) => $q.reject(error));
        return this.$promise;
      }

      /**
       * @description Get the renewing license count by the provided offer id.
       * @param {String} offerId - the offerId to get renewing license count.
       * @returns {Number} The renewing license count, return zero count if the quantity is undefined.
       */
      getRenewingLicenseCountByOfferId(offerId) {
        const billableItem = _.find(this.getBillableItems(), {offer_id: offerId});

        return _.get(billableItem, 'quantity', 0);
      }

      /**
       * @description Gets the sub total and formats it according to the currency formatString.
       * @param {Boolean} options - subtotal retrieval options
       * @param {Boolean} options.useProratedPricing - Default is false. True if we should use the use subtotal for pricing which contains
       *                     prorated pricing. False will retrieve sub_total_next_billing with full price.
       * @param {Boolean} options.includesTax - true if we should use the amount_with_tax, false for amount_without_tax
       * @returns {String} the formatted price for the sub total
       */
      getSubTotalPrice({useProratedPricing, includesTax} = {}) {
        const taxString = includesTax ? 'amount_with_tax' : 'amount_without_tax';
        const currencyFormat = _.get(this, 'responseBillingSummaryData.currency');
        const billingSummaryHasNextBilling = _.has(
          this,
          'responseBillingSummaryData.sub_total_next_billing'
        );
        const billingSummaryHasSubtotal = _.has(this, 'responseBillingSummaryData.sub_total');
        // with UBP changes, sub_total_next_billing holds the full price data and sub_total is prorated prices,
        // when UBP no active, sub_total is full price and _sub_total_next_billing does not exist
        if (billingSummaryHasNextBilling && !useProratedPricing) {
          return price.format(
            _.get(this, `responseBillingSummaryData.sub_total_next_billing.${taxString}`),
            currencyFormat
          );
        } else if (billingSummaryHasSubtotal) {
          return price.format(
            _.get(this, `responseBillingSummaryData.sub_total.${taxString}`),
            currencyFormat
          );
        }
        return undefined;
      }

      /**
       * @description Gets the preview response billing data for a specific offer.
       * @param {String} offerId - Offer's offer_id field
       * @param {Boolean} includeTax - true to use the amount_with_tax, false to use amount_without_tax
       * @param {Object} options -
       * @param {Boolean} options.useProratedPricing - Default is false. True if we should use the use subtotal for pricing which contains
       *                     prorated pricing. False will retrieve sub_total_next_billing with full price.
       * @param {Boolean} options.includeTax - true to use the renewal_price_details, false to use price_details
       * @param {Boolean} options.useRenewalPricing - true to use the renewal_price_details, false to use price_details
       * @returns {String} the formatted subtotal price for the offer (for the number of licenses selected from last cart preview)
       */
      getSubTotalPriceForOffer(offerId, {useProratedPricing, includeTax, useRenewalPricing} = {}) {
        if (_.has(this, 'responseBillingSummaryData.billable_items')) {
          const billingData = _.find(this.responseBillingSummaryData.billable_items, {
            offer_id: offerId,
          });
          const priceDetailsKey = useRenewalPricing ? 'renewal_price_details' : 'price_details';
          const amountKey = includeTax ? 'amount_with_tax' : 'amount_without_tax';
          const subtotalKey =
            _.has(billingData, `${priceDetailsKey}.sub_total_next_billing`) && !useProratedPricing
              ? 'sub_total_next_billing'
              : 'sub_total';
          const priceAmount = _.get(billingData, `${priceDetailsKey}.${subtotalKey}.${amountKey}`);

          if (priceAmount) {
            return price.format(priceAmount, this.getCurrency(!useProratedPricing));
          }
        }
        return undefined;
      }

      /**
       * @description Gets the sum of renewing licenses
       *
       * @returns {Number} the sum of renewing licenses
       */
      getTotalRenewingLicenseCount() {
        const billableItems = this.getBillableItems();
        if (billableItems) {
          return _.reduce(billableItems, (sum, item) => sum + item.quantity, 0);
        }
        return undefined;
      }

      /**
       * @description This should be called for direct contracts immediately after calling preview[Renewal]Order to get
       *  an object which encapsulates a summary of the current totals.
       * @param {Object} options the options
       * @param {Boolean} options.isRenewal - should be true if called from the renewal workflow. Default is false.
       * @param {Boolean} options.showRenewalPricing - should be true if called from the purchase workflow while in the
       *   renewal window. Default is false.
       *
       *  Notes:
       *  Total is the amount_with_tax and subtotal is the amount_without_tax.
       *  For DR contracts, we get either amount_with_tax or amount_without_tax and used whichever one is defined.
       *
       * @returns {Object} totalSummary - the summary of the total
       *          {Object} totalSummary.currency - the currency object
       *          {Number} totalSummary.subtotal - the amount_without_tax
       *          {String} totalSummary.taxRate - the tax rate in percentage, for example '8.75'
       *          {string} totalSummary.tax - the tax amount
       *          {string} totalSummary.total - if totalIncludesTax the amount_with_tax, else amount_without_tax
       *          {string} totalSummary.totalIncludesTax - true if the total includes tax
       *          {Object} totalSummary.proration - the proration object, if present and isRenewal is false
       *          {Number} totalSummary.proration.days - the prorated days
       *          {Number} totalSummary.proration.subtotal - the prorated amount_without_tax
       *          {String} totalSummary.proration.taxRate - the prorated tax rate in percentage
       *          {string} totalSummary.proration.tax - the prorated tax amount
       *          {string} totalSummary.proration.total - the prorated amount_with_tax
       *          {
       *             subtotal: 1234.56,
       *             taxRate: 8.75,
       *             tax: 108.02,
       *             total: 1342.58,
       *             totalIncludesTax: true
       *          }
       */
      getTotalSummary({isRenewal = false, showRenewalPricing = false} = {}) {
        let totalProp;
        if (isRenewal) {
          totalProp = 'total';
        } else if (showRenewalPricing) {
          totalProp = 'renewal_sub_total';
        } else {
          // if there is a sub_total_next_billing, it is the total price and sub_total is prorated price
          // if there is no sub_total_next_billing, then sub_total is the full price
          totalProp = _.has(this.responseBillingSummaryData, 'sub_total_next_billing')
            ? 'sub_total_next_billing'
            : 'sub_total';
        }

        const nextInvoiceDate = _.get(
          this.responseBillingSummaryData,
          'contract_details.next_invoice_date'
        );
        const totalToUse = _.get(this.responseBillingSummaryData, totalProp, {});
        const totalIncludesTax = !_.isUndefined(totalToUse.amount_with_tax);

        const amountWithTax = totalToUse.amount_with_tax;
        const amountWithoutTax = totalToUse.amount_without_tax;

        return {
          currency: this.getCurrency(),
          nextInvoiceDate, //  only will exist with JIL Cart
          proration: isRenewal ? undefined : this.getProrationSummary(),
          subtotal: amountWithoutTax,
          tax: totalToUse.tax,
          taxRate: totalToUse.tax_rate,
          total: totalIncludesTax ? amountWithTax : amountWithoutTax,
          totalIncludesTax,
        };
      }

      /**
       * @description Returns the count of licenses from the billableItems in responseBillableSummary that have not been
       *   invoiced yet.
       * @returns {String} the count of licenses that have not been invoiced yet
       */
      getUninvoicedLicenseCount() {
        return _(this.getBillableItems())
          .filter((item) => _.get(item, 'entitlement_details.status') !== 'INVOICED')
          .sumBy('quantity');
      }

      /**
       * For free offers, use src2 FreeOfferCart
       * @description Gets the key of the cart (renewal order).
       *    We only cache the cart response when we are getting the renewal order.
       *    It takes two api calls to get the renewal order. We first get the cartId by passing
       *    the contract-id and order-type, and then use the cartId to get the renewal order.
       *    In order to avoid any extra calls, we use the contractId as the key for the cart (renewal order).
       *
       * @returns {String} the contractId of the cart (In this case, the cart is a renewal order).
       */
      key() {
        return this.contractId;
      }

      /**
       * @description Populates the renewing license count for the provided offers.
       *
       * @param {Array} offerList list of offers to populate with renewing license counts
       * @returns {Array} list of offers
       */
      populateRenewingLicenseCountForOffers(offerList) {
        const billableItems = this.getBillableItems();
        _.forEach(offerList, (offer) => {
          const billableItem = _.find(billableItems, {
            offer_id: offer.offer_id,
          });
          offer.renewingLicenseCount = _.get(billableItem, 'quantity', 0);
        });
        return offerList;
      }

      /**
       * @description make a contract cart preview call
       * @param {Object} options - options to configure the Cart
       * @param {Object} options.billableItems - an array of billable items
       *  example:
       *    [{
       *      "offerId" : "574A88C72E423DAEF5B21C4EB733C666",
       *      "quantity" : "1"
       *    }]
       * @param {String} options.externalContractId - external contract id (optional)
       * @returns {Promise} resolves with ccs response if successful, else rejects with http error response
       */
      previewOrder(options) {
        if (this.previewOutstanding) {
          // save it for later, so we only have one outstanding call at a time.
          // if multiple requests for a preview come in while a call is outstanding, only
          // the last of the requests will be saved to be submitted when the first response comes back.
          // This should reduce the number of cart preview calls made as a user uses the number stepper.
          this.requestedPreviewOptions = options;
        } else {
          this.requestedPreviewOptions = undefined;
          updateBillingSummary(this.billingSummary, options);
          if (!_.isEqual(this.lastRequestedBillableItems, this.billingSummary[BILLABLE_ITEMS])) {
            const payload = {
              [BILLING_SUMMARY]: this.billingSummary,
              [LAST_MODIFIED_BY_ID]: this.lastModifiedById,
              [STATUS]: CART_STATUS.PENDING,
            };

            if (this.ownerId) {
              _.assign(payload, {[OWNER_ID]: this.ownerId});
            }

            const cartService = promiseUtils
              .toAngularPromise(
                binkySrc2.api.jil.jilOrganizationsCart.postCart(
                  {orgId: OrganizationManager.getActiveOrgId()},
                  payload
                )
              )
              .then(({data}) => data);

            this.previewOutstanding = true;
            this.$promise = cartService
              .then((response) => {
                this.cartId = response.id;
                this.responseBillingSummaryData = transformBillingSummary(response.billing_summary);
                this.validationToken = response[VALIDATION_TOKEN];

                this.lastRequestedBillableItems = this.billingSummary[BILLABLE_ITEMS];
                this.previewOutstanding = false;
                if (this.requestedPreviewOptions) {
                  return this.previewOrder(this.requestedPreviewOptions);
                }
                return response;
              })
              .catch((error) => {
                this.previewOutstanding = false;

                // Don't use the data from the last successful call for future gets for
                // prices/totals.
                delete this.responseBillingSummaryData;

                // Since the results from the last successfully call were deleted in the line
                // above, make sure the next time this is called, the cart service is called.
                this.lastRequestedBillableItems = undefined;

                // if we have a chained request, ignore the error; our next request may succeed (and be more up to date).
                if (this.requestedPreviewOptions) {
                  return this.previewOrder(this.requestedPreviewOptions);
                }

                const errorInfo = {};
                const headers = _.get(error, 'response.headers');

                const status = _.get(headers, 'x-adobe-status');
                if (status === CART_ERROR_STATUS.MAX_NUMBER_OF_SEATS) {
                  const orderCount = _.chain(this.billingSummary)
                    .get('billable_items', [])
                    .sumBy('quantity')
                    .value();
                  const maxLicenseLimit = _.get(headers, 'x-adobe-max-license-limit');
                  _.assign(errorInfo, {
                    maxLicenseLimit,
                    orderCount,
                    status,
                  });
                }
                // Add custom error in response data and re-throw with same
                // $http error object to maintain required headers method
                error.data = _.assign(error.data, {errorInfo});
                return $q.reject(error);
              });
          }
        }
        return this.$promise;
      }

      /**
       * @description make a contract cart renewal order preview call
       * @param {Object} options - options to configure the Cart
       * @param {Object} options.billableItems - an array of billable items
       *  example:
       *    [{
       *      "offerId" : "574A88C72E423DAEF5B21C4EB733C666",
       *      "quantity" : "1"
       *    }]
       * @returns {Promise} resolves with ccs response if successful, else rejects with http error response
       */
      previewRenewalOrder(options) {
        if (this.previewOutstanding) {
          // save it for later, so we only have one outstanding call at a time.
          // if multiple requests for a preview come in while a call is outstanding, only
          // the last of the requests will be saved to be submitted when the first response comes back.
          // This should reduce the number of cart preview calls made as a user uses the number stepper.
          this.requestedPreviewOptions = options;
        } else {
          this.requestedPreviewOptions = undefined;
          updateBillingSummary(this.billingSummary, options);
          if (!_.isEqual(this.lastRequestedBillableItems, this.billingSummary[BILLABLE_ITEMS])) {
            const payload = {
              [BILLING_SUMMARY]: this.billingSummary,
              [CART_ID]: this.cartId,
              [CART_TYPE_KEY]: CART_TYPE.RENEWAL_QUOTE,
              [STATUS]: CART_STATUS.PENDING,
            };

            this.previewOutstanding = true;
            this.$promise = ccs.cart
              .put({cartId: this.cartId}, payload)
              .$promise.then((response) => {
                this.responseBillingSummaryData = transformBillingSummary(response.billing_summary);
                this.lastRequestedBillableItems = this.billingSummary[BILLABLE_ITEMS];

                this.previewOutstanding = false;
                if (this.requestedPreviewOptions) {
                  return this.previewRenewalOrder(this.requestedPreviewOptions);
                }
                return response;
              })
              .catch((error) => {
                this.previewOutstanding = false;

                // Don't use the data from the last successful call for future gets for
                // prices/totals.
                delete this.responseBillingSummaryData;

                // Since the results from the last successfully call were deleted in the line
                // above, make sure the next time this is called, the cart service is called.
                this.lastRequestedBillableItems = undefined;

                // if we have a chained request, ignore the error; our next request may succeed (and be more up to date).
                if (this.requestedPreviewOptions) {
                  return this.previewRenewalOrder(this.requestedPreviewOptions);
                }
                return $q.reject(error);
              });
          }
        }
        return this.$promise;
      }

      /**
       * For free offers, use src2 FreeOfferCart
       * @description make a contract cart submit call
       * @param {Object} options - options to configure the Cart
       * @param {Object} options.billableItems - an array of billable items
       *  example:
       *    [{
       *      "offerId" : "574A88C72E423DAEF5B21C4EB733C666",
       *      "quantity" : "1"
       *    }]
       * @param {String} options.externalContractId - external contract id (optional)
       * @param {String} options.poNumber - po number (optional)
       * @returns {Promise} resolves with ccs response if successful, else rejects with http error response
       */
      submitOrder(options) {
        updateBillingSummary(this.billingSummary, options);
        const payload = _.pickBy({
          [BILLING_SUMMARY]: this.billingSummary,
          [CART_ID]: this.cartId, // cart_id is optional for now, CCS might use this field to improve the performance in the future
          [LAST_MODIFIED_BY_ID]: this.lastModifiedById,
          [PAYMENT_AUTHORIZATION]: options.payment_authorization,
          [STATUS]: CART_STATUS.SUBMIT,
        });

        if (this.validationToken) {
          _.assign(payload, {[VALIDATION_TOKEN]: this.validationToken});
        }
        if (this.ownerId) {
          _.assign(payload, {[OWNER_ID]: this.ownerId});
        }

        const cartService = promiseUtils
          .toAngularPromise(
            binkySrc2.api.jil.jilOrganizationsCart.postCart(
              {orgId: OrganizationManager.getActiveOrgId()},
              payload
            )
          )
          .then(({data}) => data);

        return cartService
          .then((response) => {
            this.cartId = response.id;

            // - set up so contract model can use update prices before the contract response is updated
            // - using previewed total because CCS submission does not respond with require pricing whereas JIL cart does
            // - setting the isRenewal parameter will get total as the next billing prices
            const total = this.getTotalSummary({isRenewal: true});
            const {
              nextInvoiceDate: nextBillingDate,
              subtotal: priceWithoutTax,
              total: priceWithTax,
            } = total;
            const expires = binkySrc2.utils.dateUtils.getHoursFromNow(1);
            const nextBilling = {
              expires,
              nextBillingDate,
              priceWithoutTax,
              priceWithTax,
            };
            // since contract id is only available here, match the storage key used in contract
            // see binky/src2/core/models/Contract.js setSubmittedNextBilling for implementation
            binkySrc2.utils.storageUtils.setSessionStorageItem(
              `${this.contractId}_nextBilling`,
              JSON.stringify(nextBilling)
            );
            delete this.responseBillingSummaryData;
            this.purchasedBillingSummaryData = response.billing_summary;
            modelCache.remove(MODEL.CONTRACTCART, this.key());

            $rootScope.$emit(CART_EVENT.SUBMIT);
            return response;
          })
          .catch((error) => {
            // Extract the custom error header value to pass along to the error responder
            const errorInfo = {};
            const headers = _.get(error, 'response.headers');
            errorInfo.status =
              _.get(error, 'response.status') === 403 // treat all 403 errors as authorization errors
                ? CART_ERROR_STATUS.AUTH_ERROR
                : _.get(headers, 'x-adobe-status');
            errorInfo.maxLicenseLimit = _.get(headers, 'x-adobe-max-license-limit');
            return $q.reject(errorInfo);
          });
      }

      /**
       * @description make a contract cart renewal order submit call
       * @param {Object} options - options to configure the Cart
       * @param {Object} options.billableItems - an array of billable items
       *  example:
       *    [{
       *      "offerId" : "574A88C72E423DAEF5B21C4EB733C666",
       *      "quantity" : "1"
       *    }]
       * @param {String} options.ownerId - contract owner id
       * @returns {Promise} resolves with ccs response if successful, else rejects with http error response
       */
      submitRenewalOrder(options) {
        updateBillingSummary(this.billingSummary, options);
        const payload = {
          [BILLING_SUMMARY]: this.billingSummary,
          [CART_ID]: this.cartId,
          [CART_TYPE_KEY]: CART_TYPE.RENEWAL,
          [OWNER_ID]: options.ownerId,
          [STATUS]: CART_STATUS.PENDING,
        };

        return ccs.cart
          .put({cartId: this.cartId}, payload)
          .$promise.then((response) => {
            modelCache.remove(MODEL.CONTRACTCART, this.key());
            delete this.responseBillingSummaryData;
            this.purchasedBillingSummaryData = response.billing_summary;
            $rootScope.$emit(CART_EVENT.SUBMIT_RENEWAL_ORDER);
            return response;
          })
          .catch((error) => $q.reject(error));
      }

      /**
       * For free offers, use src2 FreeOfferCart
       * @description creates a new Cart object
       * @param {Object} options - options to configure new Cart
       * @param {String} options.lastModifiedById - admin who's making the request
       * @param {CART_TYPE} [options.cartType] - the type of cart (only used in the renewal workflow)
       * @param {String} options.country - country of the org
       * @param {String} options.contractId  contract id
       * @returns {Cart} new Cart object
       */
      static get(options) {
        let model = new Cart(options);
        // We only cache the cart that represents a renewal order
        if (options.cartType === CART_TYPE.RENEWAL) {
          const cachedModel = modelCache.get(MODEL.CONTRACTCART, model.key());
          if (cachedModel) {
            model = cachedModel;
          } else {
            model.getRenewalOrder(options);
          }
        }
        return model;
      }

      /**
       * @description constructs an array of billable items in the format the cart wants, based on offers.
       * @param {Array} offers - the Offers you want billable items for. They should have numberSelected populated.
       * @returns {Array} array of billable items
       */
      static constructBillableItemsFromOffers(offers) {
        return _(offers)
          .filter((offer) => offer.numberSelected > 0)
          .map((offer) =>
            _.omitBy(
              {
                ignoreOfferMapping: offer.ignoreOfferMapping, // used during renewal, default is false
                offerId: offer.offer_id,
                quantity: offer.numberSelected,
              },
              _.isUndefined
            )
          )
          .value();
      }

      /**
       * @deprecated Use src2 FreeOfferCart
       * @description Provisions a specified free offer. This assumes any qualification has already been
       *   determined by the caller.
       * @param {Offer} freeOffer - the free offer to provision
       *
       * @returns {Promise} a promise which will resolve/reject depending on the provisioning status
       */
      static provisionFreeOffer(freeOffer) {
        // Get the contract to determine the contract country and id.
        let contract;
        const contractList = OrganizationManager.getContractsForActiveOrg();
        switch (freeOffer.buying_program) {
          case PRODUCT_BUYING_PROGRAM.ETLA:
            contract = contractList.getEnterpriseContract(CONTRACT_BUYING_PROGRAM.ETLA);
            break;
          case PRODUCT_BUYING_PROGRAM.VIP:
            contract = contractList.getIndirectContract();
            break;
          default:
            return $q.reject(`Unsupported offer buying program: ${freeOffer.buying_program}`);
        }
        if (!contract) {
          return $q.reject(
            `Cannot find contract for offer buying program: ${freeOffer.buying_program}`
          );
        }

        // CCS used to require countryCode but it seems that is no longer true.
        // In any case, let it thru if undefined and let CCS return an error since it will be easier to debug that way.
        const countryCode = contract.getOwnerCountryCode();

        // Provision the qualifying offers.
        const cart = Cart.get({
          contractId: contract.id,
          country: countryCode,
          lastModifiedById: auth.getUserId(),
        });

        _.set(freeOffer, 'numberSelected', 1);

        const billableItems = Cart.constructBillableItemsFromOffers([freeOffer]);
        const purchaseOptions = {billableItems};

        // The submitOrder emits CART_EVENT.SUBMIT which triggers the ProductList to refresh.
        // Wait until the ProductList refreshes before returning.
        // This is especially important for a VIP contract which is converted to an EVIP contract with the addition of
        // this/these Enterprise offer(s).
        // For K-12, to redirect to the Identity page, the org must have an Enterprise or EVIP contract.
        return cart
          .submitOrder(purchaseOptions)
          .then(() => pollForFulfilledOrder([freeOffer]))
          .finally(() => {
            // Do not leave the offer selected.
            delete freeOffer.numberSelected;
          });
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.CONTRACTCART, 1);

    return Cart;

    //////////////

    // For free offers, use src2 FreeOfferCart
    function initializeBillingSummary(country, contractId) {
      return _.pickBy({
        [CONTRACT_DETAILS]: {
          [PROGRAM_CONTRACT_ID]: contractId,
        },
        [COUNTRY]: country,
        // Renewal order preview and submit call requires an empty currency object
        [CURRENCY]: {},
      });
    }

    /**
     * @description We poll against the Fulfillment Events API in order to determine when any in
     * progress events have completed.
     */
    /**
     * @description Polls against the Fulfillment Events API in order to determine when any in
     * progress events have completed.
     * @param {Offer[]} offersToProvision - the offers to look for within the fulfillment event list
     */
    function pollForFulfilledOrder(offersToProvision) {
      targetOfferIds = _(targetOfferIds)
        .concat(_.map(offersToProvision, 'offer_id'))
        .uniq()
        .value();

      if (_.isNil(fulfillmentEventRefreshHandler)) {
        fulfillmentEventRefreshHandler = $rootScope.$on(
          FULFILLMENT_EVENT_REFRESH_MANAGER_EVENT.UPDATE,
          (event, managerState) => {
            const offersWithInProgressFulfillmentEvents = _.get(
              managerState,
              'offersThatHaveInProgressFulfillmentEvents'
            );
            const targetOffersFulfilled = _.every(
              targetOfferIds,
              (offerId) => !_.includes(offersWithInProgressFulfillmentEvents, offerId)
            );

            if (
              targetOffersFulfilled ||
              !_.get(managerState, 'showInProgressFulfillmentEventsPresentBanner')
            ) {
              fulfillmentEventRefreshManager.stopPolling();
              OrganizationManager.getProductsForActiveOrg()
                .$promise.then((productList) => {
                  productList.refresh();
                })
                .catch((error) => {
                  $log.error(
                    'An error occurred fetching the product list after concluding the polling',
                    error
                  );
                });
              targetOfferIds = [];
              fulfillmentEventRefreshHandler();
            }
          }
        );
        // Adding a delay to increase the likelyhood that the initial fulfillment events fetch will have content
        const initialDelay = 30000;
        DELAY_INTERVAL = $interval(() => {
          fulfillmentEventRefreshManager.startPolling(
            OrganizationManager.getActiveOrgId(),
            FULFILLMENT_EVENT_REFRESH_MANAGER_SETTINGS.MODE.EXPONENTIAL_BACKOFF
          );
          $interval.cancel(DELAY_INTERVAL);
          DELAY_INTERVAL = undefined;
        }, initialDelay);
      }
    }

    // For free offers, use src2 FreeOfferCart
    function updateBillingSummary(billingSummary, options) {
      billingSummary[CONTRACT_DETAILS][PO_NUMBER] = _.get(options, 'poNumber');
      billingSummary[CONTRACT_DETAILS][EXTERNAL_CONTRACT_ID] = _.get(options, 'externalContractId');
      // remove properties with undefined value
      billingSummary[CONTRACT_DETAILS] = _.pickBy(billingSummary[CONTRACT_DETAILS]);
      billingSummary[BILLABLE_ITEMS] = transformBillableItemsToSnakeCase(options.billableItems);
    }

    // For free offers, use src2 FreeOfferCart
    function transformBillableItemsToSnakeCase(billableItems) {
      _.forEach(billableItems, (item) => {
        item[PAYLOAD_KEY.OFFER_ID] = item.offerId;
        _.unset(item, 'offerId');
        if (!_.isUndefined(item.ignoreOfferMapping)) {
          item[PAYLOAD_KEY.IGNORE_OFFER_MAPPING] = item.ignoreOfferMapping;
          _.unset(item, 'ignoreOfferMapping');
        }
      });
      return billableItems;
    }

    function transformBillingSummary(billingSummary) {
      if (_.has(billingSummary, 'currency')) {
        billingSummary.currency = jsUtils.convertObjectKeysToCamelCase(billingSummary.currency);
      }

      if (_.has(billingSummary, 'currency_next_billing')) {
        billingSummary.currency_next_billing = jsUtils.convertObjectKeysToCamelCase(
          billingSummary.currency_next_billing
        );
      }

      return billingSummary;
    }
  }
})();
/* eslint-enable max-lines */
