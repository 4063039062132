import PropTypes from 'prop-types';

/**
 * Defines the prop types for the components in the Edit Product Profile Permissions modal.
 *
 * A product profile permission (also called a license group configuration) is a very complex object. It
 * is documented at https://wiki.corp.adobe.com/display/BPS/Extensible+License+Group+Configuration+API
 * however as detailed in comments in this file, this is not fully accurate.
 */

/**
 * The type of a sub-permission.
 * A sub-permission is within a subSelectionContent in a select permission.
 */
const subPermissionPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
});

/**
 * The type of a select permission.
 * A select permission is within a sub-content that has type='select'.
 */
const selectPermissionPropType = PropTypes.shape({
  description: PropTypes.string,
  extendedDescription: PropTypes.string,
  id: PropTypes.string.isRequired,
  linkHref: PropTypes.string,
  name: PropTypes.string.isRequired, // API says optional, but this is required
  selected: PropTypes.bool.isRequired,
  subSelectionContent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['multiSelect']).isRequired,
      values: PropTypes.arrayOf(subPermissionPropType).isRequired,
    })
  ),
});

/**
 * The type of a include permission.
 * A include permission is within a sub-content that has type='include'.
 */
const includePermissionPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string, // not used
  included: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired, // API says optional, but this is required
  sortIndex: PropTypes.number.isRequired, // not in the API - this is added by LicenseGroupConfigurations to preserve ordering
});

/**
 * The type of a text permission.
 * A text permission is within a sub-content that has type='textForm'.
 */
const textPermissionPropType = PropTypes.shape({
  id: PropTypes.string, // not used
  text: PropTypes.string.isRequired,
});

/**
 * The type of a select sub-content.
 * A select sub-content is within a group.
 */
const selectSubContentPropType = {
  allSelected: PropTypes.bool, // not documented in API
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  linkHref: PropTypes.string, // not documented in API
  name: PropTypes.string,
  type: PropTypes.oneOf(['select']).isRequired,
  values: PropTypes.arrayOf(selectPermissionPropType).isRequired,
};

/**
 * The type of an include sub-content.
 * An include sub-content is within a group.
 */
const includeSubContentPropType = {
  allSelected: PropTypes.bool, // not documented in API
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  linkHref: PropTypes.string, // not documented in API
  maximumValueCount: PropTypes.number,
  minimumValueCount: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.oneOf(['include']).isRequired,
  values: PropTypes.arrayOf(includePermissionPropType).isRequired,
};

/**
 * The type of a text sub-content.
 * A text sub-content is within a group.
 */
const textSubContentPropType = {
  allSelected: PropTypes.bool, // not documented in API
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  linkHref: PropTypes.string, // not documented in API
  maximumValueCount: PropTypes.number,
  minimumValueCount: PropTypes.number,
  name: PropTypes.string,
  regex: PropTypes.string,
  type: PropTypes.oneOf(['textForm']).isRequired,
  values: PropTypes.arrayOf(textPermissionPropType).isRequired,
};

/**
 * The type of a sub-content.
 * A sub-content can be either a select, include, or text sub-content.
 */
const subContentPropType = PropTypes.oneOfType([
  PropTypes.shape(selectSubContentPropType),
  PropTypes.shape(includeSubContentPropType),
  PropTypes.shape(textSubContentPropType),
]);

/**
 * The properties that are common to both expandable and expandable-select group types.
 */
const groupCommonPropTypes = {
  allSelected: PropTypes.bool,
  canDisable: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  linkHref: PropTypes.string,
  name: PropTypes.string.isRequired,
};

/**
 * The type of an expandable group.
 */
const expandableGroupPropType = PropTypes.shape({
  ...groupCommonPropTypes,
  content: PropTypes.arrayOf(subContentPropType).isRequired,
  type: PropTypes.oneOf(['expandable']).isRequired,
});

/**
 * The type of an expandable-select group.
 * An expandable-select group has sub-contents that must have a name, and a selected property.
 */
const expandableSelectGroupPropType = PropTypes.shape({
  ...groupCommonPropTypes,
  content: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...selectSubContentPropType,
        name: PropTypes.string.isRequired, // API says optional, but this is required
        selected: PropTypes.bool.isRequired,
      }),
      PropTypes.shape({
        ...includeSubContentPropType,
        name: PropTypes.string.isRequired, // API says optional, but this is required
        selected: PropTypes.bool.isRequired,
      }),
      PropTypes.shape({
        ...textSubContentPropType,
        name: PropTypes.string.isRequired, // API says optional, but this is required
        selected: PropTypes.bool.isRequired,
      }),
    ])
  ).isRequired,
  type: PropTypes.oneOf(['expandableSelect']).isRequired,
});

/**
 * The type of a group.
 * A group can be either an expandable or an expandable-select group.
 */
const groupPropType = PropTypes.oneOfType([expandableGroupPropType, expandableSelectGroupPropType]);

/**
 * The type of a section of a configuration.
 */
const sectionPropType = PropTypes.shape({
  content: PropTypes.arrayOf(groupPropType).isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
});

/**
 * The type of a configuration.
 */
const configurationPropType = PropTypes.shape({
  duplicable: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(sectionPropType).isRequired,
});

/**
 * The type of license group configurations.
 */
const configurationsPropType = PropTypes.arrayOf(configurationPropType);

export {
  groupPropType,
  expandableSelectGroupPropType,
  expandableGroupPropType,
  subContentPropType,
  selectPermissionPropType,
  includePermissionPropType,
  textPermissionPropType,
  sectionPropType,
  configurationPropType,
  configurationsPropType,
};
