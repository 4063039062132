(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.widgets.products')
    .constant('PRODUCT_PROFILE_SELECT_CHANGE', 'product-profile-select:change')
    .constant('PRODUCT_PROFILE_SELECT_ERROR', 'product-profile-select:error')
    .constant('PRODUCT_PROFILE_SELECT_VALIDITY_CHANGE', 'product-profile-select:validity-change');
})();
