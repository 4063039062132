(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.scorecard:appScorecard
   *
   * @description the scorecard widget, with a label and value
   *
   * @param {boolean} alwaysShowTotal (optional) True if the total should be displayed even if total is 0.
   * @param {boolean} isDisabled (optional) True if the scorecard should appear disabled.
   * @param {boolean} isError (optional) True if the scorecard label color should be same red
   *   color as the error alert.
   * @param {boolean} isWarning (optional) True if the scorecard label color should be same yellow
   *   color as the warning alert.
   * @param {string} label String describing the units of the score (users, days, etc). This string
   *   should already be translated.
   * @param {string} lowerLabel String to add extra detail (currently to benefit stock cards). This string should already be translated.
   * @param {number} score The value to display on the scorecard.
   * @param {string} secondaryLabel String describing the units of the secondary score (users, days, etc).
   *   This string should already be translated.
   * @param {number} secondaryScore The value to display on the scorecard secondary tooltip.
   *
   * @example <app-scorecard id="demo-scorecard-toggle"
   *   is-disabled="$ctrl.scorecardCheckbox"
   *   label="{{string.path | translate}}"
   *   score="13"></app-scorecard>
   *
   */
  angular.module('app.widgets.scorecard').component('appScorecard', {
    bindings: {
      alwaysShowTotal: '<?',
      isDisabled: '<?',
      isError: '<?',
      isWarning: '<?',
      label: '@',
      lowerLabel: '@?',
      score: '<',
      secondaryLabel: '@?',
      secondaryScore: '<?',
      showViewDetailIcon: '<?',
      total: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/scorecard/scorecard.component.html',
  });

  function controller() {
    const SEPARATOR = '/';
    const vm = this;

    vm.$onInit = $onInit;
    vm.separator = SEPARATOR;

    ////////

    function $onInit() {
      vm.isValueDisabled = function () {
        return vm.isDisabled === true;
      };
    }
  }
})();
