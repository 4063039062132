(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.bubble
   *
   * @description an icon with a tooltip attached
   *
   * @param {string} icon The icon to show (from Coral.Icon). Default: info
   * @param {string} iconSize The size of icon to render. One of: S, M, L, XL. Default: S
   * @param {string} placement The placement of the tooltip (from Coral.Tooltip). Default: right
   *
   */
  angular.module('app.widgets.bubble').directive('appBubble', appBubbleDirective);

  function appBubbleDirective($compile, $timeout, APP_BUBBLE_MOUSELEAVE_DELAY) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        icon: '@?',
        iconSize: '@?',
        placement: '@?',
      },
      template: templateFn,
      transclude: {
        'bubble-body': '?bubbleBody',
        'bubble-footer': '?bubbleFooter',
        'bubble-header': '?bubbleHeader',
      },
    };

    return directive;

    /////////////////

    function linkFn(scope, element, attrs, ctrl, transclude) {
      const coralIcon = getCoralIcon();
      const coralTooltip = getCoralTooltip();

      if (isTranscludingSlot()) {
        element.empty();
      }
      element.append(coralIcon);
      element.append(coralTooltip);

      if (isTranscludingSlot()) {
        scope.$watch(['bubbleHeader', 'bubbleBody', 'bubbleFooter'], transcludeSlotsInCoralTooltip);
      } else {
        // appends the transcluded content to the tooltip
        element.find('coral-tooltip-content').append(element.find('div')[0]);
      }

      // Keep the tooltip open if the pointer is on the target element. Toggle open/closed on keypress
      // This isn't default Coral.Tooltip behaviour
      scope.lastMouseEnter = 0;
      element.on('mouseenter', onMouseEnter);
      element.on('mouseleave', () => {
        const delay = APP_BUBBLE_MOUSELEAVE_DELAY;
        const lastMouseLeave = Date.now();
        $timeout(() => {
          // if a new mouseenter happens after the last mouseleave event,
          // keep the tooltip open
          if (lastMouseLeave >= scope.lastMouseEnter) {
            coralTooltip.open = false;
          }
        }, delay);
      });

      // pass through angular.element() first to enable unit tests to trigger handlers on
      // the tooltip object
      const coralTooltipElement = angular.element(coralTooltip);
      coralTooltipElement.on('mouseenter', onMouseEnter);
      coralTooltipElement.on('mouseleave', () => {
        coralTooltip.open = false;
      });

      coralIcon.on('keypress', () => {
        coralTooltip.open = !coralTooltip.open;
      });

      function getCoralIcon() {
        const icon = new Coral.Icon().set({
          icon: scope.icon || 'infoOutline',
          size: scope.iconSize || 'S',
          tabIndex: 0,
        });
        angular.element(icon).on('click', (e) => e.stopPropagation());
        return icon;
      }

      function getCoralTooltip() {
        return new Coral.Tooltip().set({
          content: {
            innerHTML: isTranscludingSlot()
              ? getContainerElmForSlot('bubble-header') +
                getContainerElmForSlot('bubble-body') +
                getContainerElmForSlot('bubble-footer')
              : '',
          },
          interaction: 'off',
          placement: scope.placement || 'right',
          target: coralIcon,
        });
      }

      function onMouseEnter() {
        scope.lastMouseEnter = Date.now();
        coralTooltip.open = true;
      }

      function transcludeSlotsInCoralTooltip() {
        transcludeSlot('bubble-header');
        transcludeSlot('bubble-body');
        transcludeSlot('bubble-footer');
        $compile(coralTooltip)(scope.$parent);
      }

      function getContainerElmForSlot(slot) {
        return transclude.isSlotFilled(slot) ? `<div class="${slot}"></div>` : '';
      }

      function transcludeSlot(slot) {
        transclude(
          scope,
          (transEl) => {
            const selectedEl = element[0].querySelector(`.${slot}`);
            if (selectedEl) {
              selectedEl.appendChild(transEl[0]);
            }
          },
          false,
          slot
        );
      }

      function isTranscludingSlot() {
        return (
          transclude.isSlotFilled('bubble-header') ||
          transclude.isSlotFilled('bubble-body') ||
          transclude.isSlotFilled('bubble-footer')
        );
      }
    }
    function templateFn() {
      return `<div ng-transclude></div>`;
    }
  }
})();
