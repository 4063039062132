import {GlobalAdminPolicyList} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the adobeStatus api to fetch a list of status data
 */
const useAppAddOnsPolicyData = (orgId) => {
  const loadAppAddOnsPolicyData = useCallback(async () => {
    const policyData = await GlobalAdminPolicyList.get({orgId});

    return policyData;
  }, [orgId]);

  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadAppAddOnsPolicyData,
  });

  return {error, isLoading, policyData: model};
};

export default useAppAddOnsPolicyData;
