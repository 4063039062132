import {ActionButton, Item, Menu, MenuTrigger, View} from '@adobe/react-spectrum';
import Download from '@react/react-spectrum/Icon/Download';
import PropTypes from 'prop-types';
import React from 'react';

// Button with menu used in CreatePackageCard
const CreatePackageCardMenuButton = ({
  buttonString,
  cardMenuItems,
  onCardMenuItemPress,
  marginTop = 'size-0',
}) => (
  <MenuTrigger>
    <ActionButton height="auto" marginTop={marginTop}>
      <View
        paddingBottom="size-75"
        paddingStart="size-125"
        paddingTop="size-75"
        UNSAFE_style={{color: '#6e6e6e'}}
      >
        <Download size="S" />
      </View>
      <View
        paddingBottom="size-115"
        paddingEnd="size-150"
        paddingStart="size-100"
        paddingTop="size-75"
      >
        {buttonString}
      </View>
    </ActionButton>
    <Menu onAction={onCardMenuItemPress}>
      {cardMenuItems.map((item) => (
        <Item key={item.key}>{item.displayString}</Item>
      ))}
    </Menu>
  </MenuTrigger>
);

CreatePackageCardMenuButton.propTypes = {
  /**
   * String to be displayed on the button of the card
   */
  buttonString: PropTypes.string,
  /**
   * Array of items to populate card menu
   */
  cardMenuItems: PropTypes.arrayOf(
    PropTypes.shape({displayString: PropTypes.string.isRequired, key: PropTypes.string.isRequired})
  ),
  /**
   * Top margin of button
   */
  marginTop: PropTypes.string,
  /**
   * Function to be called when menu item pressed
   */
  onCardMenuItemPress: PropTypes.func,
};

export default CreatePackageCardMenuButton;
