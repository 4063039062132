/* eslint-disable complexity -- not refactoring now */
import {
  EVENT_ACTION,
  PAGE_TARGET_TYPE,
  PageContext,
  dispatchPageAnalytics,
  dispatchUiEventAnalytics,
  log,
} from '@admin-tribe/binky';
import {
  AlertDialog,
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalHeading,
  PageBanner,
  useFileSelection,
  useModalDialog,
} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup, DialogContainer, Divider, View} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import BulkOperationForm from '../bulk-operation-form/BulkOperationForm';
import useBulkOperations from '../bulk-operation-hooks/useBulkOperations';
import {
  BULK_OPERATION_MODAL_ID,
  BULK_OPERATION_MODE,
} from '../bulk-operation-utils/bulkOperationConstants';
import {delocalizeCsvFile} from '../bulk-operation-utils/bulkOperationLocalization';
import {
  getErrorMessage,
  getSuccessMessage,
  uploadFiles,
} from '../bulk-operation-utils/bulkOperationUtils';

import './BulkOperationModal.pcss';

const componentMethodType = 'submit';
const componentName = 'appBulkOperationModal';
const ANALYTICS_EVENT_NAME_UPLOAD = 'upload';

/**
 * The bulk operations modal for all bulk operation workflows
 */
const BulkOperationModal = ({
  mode,
  isOpen,
  onClosed,
  onCancel,
  onSuccess,
  pageContext,
  analyticsContextFunc,
}) => {
  const intl = useIntl();

  const [isConfirmModalOpen, openModal, closeModal] = useModalDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {addFiles, removeFiles, selectedFiles} = useFileSelection();

  // The error page banner shown when modal is in error
  const [pageBannerErrorParams, setPageBannerErrorParams] = useState();

  // The info page banner always shown for certain bulk operation modes
  const [pageBannerInfoParams, setPageBannerInfoParams] = useState();

  // Callback function for clients to use for show/hiding the error page banner
  const showPageBannerError = useCallback(
    (params) => {
      setPageBannerErrorParams(params);
    },
    [setPageBannerErrorParams]
  );

  const {
    confirmDialogParams,
    exportFunction,
    exportParams,
    isLoading,
    modalDescription,
    modalHeading,
    translateHelper,
    uploadFunction,
    uploadParams,
  } = useBulkOperations({
    mode,
    orgId: rootStore.organizationStore.activeOrgId,
    pageContext,
    showPageBanner: setPageBannerInfoParams,
  });

  const onCancelInternal = () => {
    onClosed?.();
    onCancel?.();

    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'cancel',
    });
  };

  const onCta = async () => {
    setIsSubmitting(true);

    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: ANALYTICS_EVENT_NAME_UPLOAD,
    });

    let filesToUpload = [];
    if (pageContext.targetType === PAGE_TARGET_TYPE.USER) {
      try {
        filesToUpload = await Promise.all(selectedFiles.map(delocalizeCsvFile));
      } catch (error) {
        const errorMsgParams = getErrorMessage(error?.message);
        showPageBannerError(errorMsgParams);

        setIsSubmitting(false);
        closeModal?.(false);

        log.error('Failed to delocalize csv', error);
        return false;
      }
    } else {
      filesToUpload = selectedFiles;
    }

    const uploadPromises = uploadFiles({
      files: filesToUpload,
      orgId: rootStore.organizationStore.activeOrgId,
      uploadFunction,
      uploadParams,
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      log.error('Failed to upload csv:', error);

      dispatchUiEventAnalytics({
        attributes: {
          csvsCount: selectedFiles.length,
        },
        componentMethod: uploadFunction,
        componentMethodType,
        componentName,
        eventAction: EVENT_ACTION.FAILED,
        eventName: ANALYTICS_EVENT_NAME_UPLOAD,
        pageContext,
      });

      // Display corresponding error of first csv upload response to fail
      const errorMsgParams = getErrorMessage(error?.response?.data?.errorCode);
      showPageBannerError(errorMsgParams);
      return false;
    } finally {
      setIsSubmitting(false);
    }

    onSuccess?.();
    onClosed?.();

    dispatchUiEventAnalytics({
      attributes: {
        csvsCount: selectedFiles.length,
      },
      componentMethod: uploadFunction,
      componentMethodType,
      componentName,
      eventAction: EVENT_ACTION.SUCCESS,
      eventName: ANALYTICS_EVENT_NAME_UPLOAD,
      pageContext,
    });

    return true; // to show success toast
  };

  const onCtaClick = confirmDialogParams ? openModal : onCta;
  const generateCta = () => getSuccessMessage(intl, selectedFiles.length);

  // Handle analytics when modal is opened
  useEffect(() => {
    if (mode === BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION) {
      dispatchPageAnalytics({
        pageName: 'frictionlessOffer:bulkOperation:offerSwitchMigration',
      });
    }
  }, [mode]);

  return isOpen ? (
    <ModalContainer>
      <ModalDialog
        analyticsContextFunc={analyticsContextFunc}
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
        ctaLabel={intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationModal.button.upload',
        })}
        ctaToastGenerator={generateCta}
        heightVariant="static"
        id={BULK_OPERATION_MODAL_ID}
        isCtaDisabled={selectedFiles.length === 0 || isSubmitting}
        isLoading={isLoading()}
        onCancel={onCancelInternal}
        onCta={onCtaClick}
      >
        {!isLoading() && (
          <>
            {modalHeading && <ModalHeading>{translateHelper(modalHeading)}</ModalHeading>}
            <ModalContent>
              <Divider marginBottom="size-150" marginTop="size-150" size="M" />
              <View marginBottom="size-100">
                {/* Need V2 Provider for PageBanner which uses V2 Alert */}
                <SpectrumV2Provider>
                  {pageBannerErrorParams && (
                    <>
                      {/* Show error first */}
                      <PageBanner
                        header={translateHelper(pageBannerErrorParams.header)}
                        showDefaultButton={pageBannerErrorParams.showDefaultButton}
                        variant={pageBannerErrorParams.variant}
                      >
                        {translateHelper(pageBannerErrorParams.children)}
                      </PageBanner>
                    </>
                  )}
                  {pageBannerInfoParams && (
                    <>
                      {/* Show informational banners second */}
                      <PageBanner
                        header={translateHelper(pageBannerInfoParams.header)}
                        showDefaultButton={pageBannerInfoParams.showDefaultButton}
                        variant={pageBannerInfoParams.variant}
                      >
                        {translateHelper(pageBannerInfoParams.children)}
                      </PageBanner>
                    </>
                  )}
                </SpectrumV2Provider>
              </View>
              <BulkOperationForm
                addFiles={addFiles}
                bulkOperationState={{
                  exportFunction,
                  exportParams,
                }}
                mode={mode}
                pageContext={pageContext}
                removeFiles={removeFiles}
                selectedFiles={selectedFiles}
                showPageBanner={showPageBannerError}
              >
                {modalDescription && <View>{translateHelper(modalDescription)}</View>}
              </BulkOperationForm>
            </ModalContent>
          </>
        )}
        {confirmDialogParams && (
          <>
            <ButtonGroup marginX="size-400">
              <Button data-testid="cancel-button" onPress={onCancelInternal} variant="secondary">
                <FormattedMessage id="common.modal.buttons.cancel" />
              </Button>
              <Button
                data-testid="cta-button"
                isDisabled={selectedFiles.length === 0 || isSubmitting}
                onPress={openModal}
                variant="accent"
              >
                <FormattedMessage id="common.bulkOperation.bulkOperationModal.button.upload" />
              </Button>
            </ButtonGroup>
            <DialogContainer onDismiss={closeModal}>
              {isConfirmModalOpen && (
                <AlertDialog
                  analyticsContextFunc={analyticsContextFunc}
                  cancelLabel={intl.formatMessage({
                    id: 'binky.shell.panels.modal.cancel',
                  })}
                  closeModal={closeModal}
                  ctaLabel={translateHelper(confirmDialogParams.ctaLabel)}
                  ctaToastGenerator={generateCta}
                  id="confirmation-dialog"
                  isLoading={isSubmitting}
                  onCancel={closeModal}
                  onCta={onCta}
                  title={translateHelper(confirmDialogParams.header)}
                  variant={confirmDialogParams.variant}
                >
                  {translateHelper(confirmDialogParams.children)}
                </AlertDialog>
              )}
            </DialogContainer>
          </>
        )}
      </ModalDialog>
    </ModalContainer>
  ) : null;
};

BulkOperationModal.propTypes = {
  /**
   * Analytics context that needs to be sent when the modal is opened
   */
  analyticsContextFunc: PropTypes.func,
  /**
   * Boolean flag to control if modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * The BULK_OPERATION_MODE that will determine the modal content.
   */
  mode: PropTypes.oneOf(Object.values(BULK_OPERATION_MODE)),
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  /**
   * @deprecated
   * Callback function called by src1 global-modals component. Do not use if opening modal from React code.
   */
  onClosed: PropTypes.func,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func,
  /**
   * The PageContext object used to determine the type of bulk operation
   */
  pageContext: PropTypes.instanceOf(PageContext),
};

export default BulkOperationModal;
/* eslint-enable complexity -- not refactoring now */
