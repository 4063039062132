(function () {
  const componentName = 'appUsersSummarySection';
  /**
   * @deprecated use src2 Overview page
   */
  angular.module('app.overview').component(componentName, {
    controller,
    templateUrl: 'app/overview/overview/users-summary-section/users-summary-section.component.html',
  });
  /* @ngInject */
  function controller(
    _,
    $rootScope,
    $state,
    $translate,
    $q,
    adminAccess,
    administratorsUtils,
    AnalyticsEvent,
    binkySrc2,
    feature,
    ROLE,
    numberFilter,
    onesieSrc2,
    OrganizationAdminList,
    OrganizationManager,
    productAccess,
    userGroupAccess,
    UserGroupList,
    userAccess,
    PRODUCT_LIST_EVENT
  ) {
    const vm = this;
    const SUMMARY_INDEX_USER = {
      ADMINISTRATORS: 1,
      DEVELOPERS: 2,
      USER_GROUPS: 3,
      USERS: 0,
    };
    const USER_TYPE = {
      ADMINISTRATORS: 'administrators',
      DEVELOPERS: 'developers',
      USER_GROUPS: 'userGroups',
      USERS: 'users',
    };

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      canShowUser,
      getUserQuantities,
      onGoToUsers,
      onManage,
    });

    vm.productList = OrganizationManager.getProductsForActiveOrg();
    vm.onProductListUpdateDeregister = $rootScope.$on(PRODUCT_LIST_EVENT.REFRESH, () => {
      $onChanges();
    });

    function $onInit() {
      vm.userSummaryList = [
        {
          nameKey: `.users`,
          routeLink: 'users.users.all-users',
          type: USER_TYPE.USERS,
        },
        {
          nameKey: `.administrators`,
          routeLink: 'users.administrators.all-admins',
          type: USER_TYPE.ADMINISTRATORS,
        },
        {
          nameKey: `.developers`,
          routeLink: 'users.developers.all-developers',
          type: USER_TYPE.DEVELOPERS,
        },
        {
          nameKey: `.userGroups`,
          routeLink: 'users.user-groups',
          type: USER_TYPE.USER_GROUPS,
        },
      ];
    }

    function $onChanges() {
      getUserQuantities();
    }

    function $onDestroy() {
      vm.onProductListUpdateDeregister();
    }

    function canShowUser(user) {
      switch (user.type) {
        case USER_TYPE.USERS:
          return userAccess.canViewUsers();
        case USER_TYPE.ADMINISTRATORS:
          return adminAccess.canViewAdmins();
        case USER_TYPE.DEVELOPERS:
          return adminAccess.canViewLicenseDeveloperAdmins() && atLeastOneCanAssignDeveloper();
        case USER_TYPE.USER_GROUPS:
          return userGroupAccess.canViewUserGroups();
        default:
          return false;
      }
    }

    function getUserQuantities() {
      hideErrorSection();

      const productsPromise = vm.productList.$promise;
      const userPromise = getUserQuantity();
      const adminPromise = getAdminQuantity();
      const userGroupPromise = getUserGroupQuantity();
      const devPromise = getDeveloperQuantity();

      vm.promiseToWait = productsPromise
        .then(() => userPromise)
        .then(() => adminPromise)
        .then(() => userGroupPromise)
        .then(() => devPromise)
        .catch(onError)
        .finally(() => {
          if (feature.isEnabled('temp_observability')) {
            binkySrc2.services.observability.observabilityMetrics.completeSpan(
              onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST.USERS_SUMMARY_SECTION
            );
          }
        });
    }

    function onManage() {
      sendClickAnalytics('manage-users');
      // go to first user page listed
      $state.go(_.find(vm.userSummaryList, canShowUser).routeLink);
    }

    function onGoToUsers(item) {
      sendClickAnalytics(`go-to-${item.type}`);
      $state.go(item.routeLink);
    }

    // private functions
    function atLeastOneCanAssignDeveloper() {
      return _.some(vm.productList.items, (product) => productAccess.canAssignDeveloper(product));
    }

    function getAdminQuantity() {
      if (!adminAccess.canViewAdmins()) {
        return $q.resolve();
      }
      const adminList = administratorsUtils.getAdministratorsList();
      return adminList.$promise.then(() => {
        vm.userSummaryList[SUMMARY_INDEX_USER.ADMINISTRATORS].quantity = numberFilter(
          adminList.getTotalItemCount()
        );
      });
    }

    function getDeveloperQuantity() {
      if (!adminAccess.canViewLicenseDeveloperAdmins() || !atLeastOneCanAssignDeveloper()) {
        return $q.resolve();
      }

      return OrganizationAdminList.get({
        pageSize: 1,
        role: ROLE.ADMIN.LICENSE_DEV,
      }).$promise.then((devList) => {
        vm.userSummaryList[SUMMARY_INDEX_USER.DEVELOPERS].quantity = numberFilter(
          _.invoke(devList, 'getTotalItemCount')
        );
      });
    }

    function getUserQuantity() {
      if (!userAccess.canViewUsers()) {
        return $q.resolve();
      }

      const orgUserListCount = new binkySrc2.services.users.OrganizationUserListCount({
        orgId: OrganizationManager.getActiveOrgId(),
      });
      return $q((resolve, reject) => {
        orgUserListCount
          .refresh()
          .then(() => {
            vm.userSummaryList[SUMMARY_INDEX_USER.USERS].quantity = numberFilter(
              orgUserListCount.totalCount
            );
            resolve();
          })
          .catch(reject);
      });
    }

    function getUserGroupQuantity() {
      if (!userGroupAccess.canViewUserGroups()) {
        return $q.resolve();
      }
      const getAllGroups = false;
      const userGroupList = UserGroupList.get(getAllGroups);
      return userGroupList.$promise.then(() => {
        vm.userSummaryList[SUMMARY_INDEX_USER.USER_GROUPS].quantity = numberFilter(
          userGroupList.unfilteredCount
        );
      });
    }

    function hideErrorSection() {
      vm.errorMsg = undefined;
    }

    function onError(error) {
      showErrorSection();
      return $q.reject(error);
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }

    function showErrorSection() {
      vm.errorMsg = $translate.instant('overview.usersArea.errorText');
    }
  }
})();
