(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationContractMigration
   * @description Model for a Organization Contract Migration object
   */
  angular
    .module('app.core.contract.migrations')
    .factory('OrganizationContractMigration', getOrganizationContractMigrationModel);

  /* @ngInject */
  function getOrganizationContractMigrationModel(_, OrganizationContractMigrationContext) {
    class OrganizationContractMigration {
      /**
       * @class
       * @description Creates a new OrganizationContractMigration instance
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Object} options.migrationContext contract specific migration data about source and target licenses quantities
       * @param {String} options.status migration status, [NOT_MIGRATING, MIGRATING, MIGRATED]
       * @param {String} options.type migration type, currently supports TEAM_VIP_TO_TEAM_EVIP
       */
      constructor(options) {
        initModel(this, options);
      }

      static apiResponseTransformer(organizationContractMigration) {
        return new OrganizationContractMigration(organizationContractMigration);
      }
    }

    return OrganizationContractMigration;

    ////////

    function initModel(model, options) {
      _.assignIn(model, _.pick(options, ['status', 'type']), {
        migrationContext: new OrganizationContractMigrationContext(options.migrationContext),
      });
    }
  }
})();
