(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.main.global-modals-manager:VideoGlobalModal
   */
  angular.module('app.main.global-modals-manager').factory('videoGlobalModal', VideoGlobalModal);

  /* @ngInject */
  function VideoGlobalModal($rootScope, GLOBAL_MODALS) {
    const service = {
      open,
    };

    return service;

    //////////////

    /**
     * Initialize and open Video Modal
     * @param {Object} options options for this method
     * @param {String} [options.modalId] the modal id
     * @param {String} options.videoUrl modal's videoUrl
     */
    function open(options) {
      const componentBindings = {
        modalId: options.modalId,
        videoUrl: options.videoUrl,
      };

      $rootScope.$broadcast(GLOBAL_MODALS.OPEN, {
        componentBindings,
        componentName: 'binkyVideoModal',
      });
    }
  }
})();
