(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilOrganizationsContractMigrations
   * @description defines service to manage jil organizations contract migrations resources
   */
  angular
    .module('app.core.api.jil')
    .provider('jilOrganizationsContractMigrations', jilOrganizationsContractMigrationsProvider);

  let url;

  /* @ngInject */
  function jilOrganizationsContractMigrationsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        migrations: getMigrationsResource(),
      };

      return service;

      ///////////

      function getMigrationsResource() {
        return $resource(`${url}/:orgId/contracts/:contractId/migrations/`, {
          contractId: '@contractId',
          orgId: apiUtils.getActiveOrg,
          type: '@type',
        });
      }
    }
  }
})();
