import {dispatchUiEventAnalytics, feature} from '@admin-tribe/binky';
import {ActionButton, Flex, Text, View} from '@adobe/react-spectrum';
import {useIntroductionList} from '@pandora/data-model-acrs';
import {INTRODUCTION_STATUS} from '@pandora/react-data-source-acrs';
import {ProductRequestSettingsOperations} from '@pandora/react-product-request-settings-modal';
import UserAddIcon from '@spectrum-icons/workflow/UserAdd';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ReviewUserIntroductionsModalWrapper from 'features/overview/components/review-user-introductions-modal-wrapper/ReviewUserIntroductionsModalWrapper';
import useAccessRequestPolicies from 'features/products/hooks/useAccessRequestPolicies';

/**
 * @description Component for notifying the user there are users to review with matching domains.
 */
const UserIntroductionsNotification = () => {
  const intl = useIntl();
  const introductionList = useIntroductionList({
    orgId: rootStore.organizationStore.activeOrgId,
  });
  const {policies: orgPolicies} = useAccessRequestPolicies();
  const [userIntroductionsCount, setUserIntroductionsCount] = useState(undefined);
  const [pendingUserIntroductionsCount, setPendingUserIntroductionsCount] = useState(undefined);

  // Set the count that will determine whether the domain matched user
  // notification should appear and which notification message to show
  useEffect(() => {
    if (
      introductionList?.data?.resources?.items?.length > 0 &&
      orgPolicies?.find?.(
        (policy) => policy.name === ProductRequestSettingsOperations.DOMAIN_MATCHING_ENABLED
      )?.value
    ) {
      const reviewPendingCount = introductionList.data.resources.items.filter(
        (introduction) => introduction.status === INTRODUCTION_STATUS.ADMIN_REVIEW_PENDING
      ).length;
      setPendingUserIntroductionsCount(reviewPendingCount);
      const reviewIgnoredCount = introductionList.data.resources.items.filter(
        (introduction) => introduction.status === INTRODUCTION_STATUS.ADMIN_IGNORED
      ).length;
      setUserIntroductionsCount(reviewPendingCount + reviewIgnoredCount);
    }
  }, [introductionList, orgPolicies]);

  const [showReviewUserIntroductionsModal, setShowUserIntroductionsModal] = useState(false);
  const onShowReviewUserIntroductionsModal = () => {
    setShowUserIntroductionsModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'userintro:notification',
    });
  };

  const onCancel = async () => {
    setShowUserIntroductionsModal(false);
    await introductionList.refresh();
  };

  const userIntroductionNotificationMessage =
    pendingUserIntroductionsCount > 0
      ? intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
            strong: (str) => <strong>{str}</strong>,
          }
        )
      : intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNoCountNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
          }
        );

  const userIntroductionShortNotificationMessage =
    pendingUserIntroductionsCount > 0
      ? intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsShortNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
            strong: (str) => <strong>{str}</strong>,
          }
        )
      : intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNoCountShortNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
          }
        );

  const generateActionButton = (message, testId, needsReview = false) => (
    <ActionButton
      height="100%"
      onPress={onShowReviewUserIntroductionsModal}
      staticColor="white"
      UNSAFE_style={{cursor: 'pointer'}}
      width="100%"
    >
      <Flex alignItems="center" marginY="size-100" width="100%" wrap="nowrap">
        <UserAddIcon
          marginStart="size-50"
          size="S"
          UNSAFE_style={{color: 'var(--spectrum-global-color-gray-50)'}}
        />
        <Flex width="100%" wrap="wrap">
          <Text data-testid={testId} UNSAFE_style={{textAlign: 'left'}}>
            {message}
          </Text>
        </Flex>
        {needsReview && (
          <Flex width="100%" wrap="wrap">
            <Text
              data-testid="domain-matched-user-notification-message-needs-review"
              UNSAFE_style={{
                textAlign: 'right',
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage({id: 'overview.users.pod.needsReview'})}
            </Text>
          </Flex>
        )}
      </Flex>
    </ActionButton>
  );

  return userIntroductionsCount > 0 ? (
    <>
      <View
        backgroundColor="cyan-800"
        borderRadius="regular"
        colorVersion={6}
        data-testid="domain-matched-user-notification"
        margin="size-50"
      >
        {feature.isEnabled('temp_admin_ddm_needs_review')
          ? generateActionButton(
              userIntroductionShortNotificationMessage,
              'domain-matched-user-short-notification-message',
              true
            )
          : generateActionButton(
              userIntroductionNotificationMessage,
              'domain-matched-user-notification-message'
            )}
      </View>
      {showReviewUserIntroductionsModal && (
        <ReviewUserIntroductionsModalWrapper onClose={onCancel} />
      )}
    </>
  ) : null;
};

export default UserIntroductionsNotification;
