//
// @deprecated Please use the OverlayWait in binky-ui src2/common/components/wait/OverlayWait.jsx
//

// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Wait should be replaced with v3 ProgressCircle
import SpectrumWait from '@react/react-spectrum/Wait';
import PropTypes from 'prop-types';
import React from 'react';

import './OverlayWait.pcss';

const OverlayWait = ({children, isLoading, ...waitProps}) => {
  const waitComponent = (
    <div styleName="wait-overlay">
      <SpectrumWait
        centered
        // TODO: Limit support to the react spectrum Wait props
        //       https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/Wait/js/Wait.js#L30-L53
        {...waitProps}
      />
    </div>
  );

  return (
    <div styleName="wait-container">
      {children}
      {isLoading && waitComponent}
    </div>
  );
};

OverlayWait.propTypes = {
  /**
   * The content to display behind the wait.
   */
  children: PropTypes.node,
  /**
   * Whether the wait should be shown above the content.
   */
  isLoading: PropTypes.bool,
};

export default OverlayWait;
