import help from '../../constants/help';
import {getEnterpriseSignParams} from '../../utils/helpUtils';

/**
 *
 * @returns content for product tab -> enterprise sign 2
 */
const enterpriseSign2 = () => {
  const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;
  const {access} = getEnterpriseSignParams();

  return {
    access,
    content: [
      {
        links: [
          {
            key: 'help.topics.enterpriseSign2.link.one.key',
            locator: 'doccloud_esignadmin_go',
            target: LINK_TARGET_TYPE.GO_URL,
            type: LINK_TYPE.BUTTON,
          },
          {
            key: 'help.topics.enterpriseSign2.link.two.key',
            locator: 'ac_dc_feature',
            target: LINK_TARGET_TYPE.GO_URL,
            type: LINK_TYPE.TEXT,
          },
        ],
        text: 'help.topics.enterpriseSign2.text',
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: TOPICS.ENTERPRISE_SIGN2,
    parents: [
      {
        id: TOPICS.PRODUCTS,
      },
    ],
    title: 'help.topics.enterpriseSign2.title',
  };
};
export default enterpriseSign2;
