import {AlertDialog, Content, Flex, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Modal for removing whitelisted domains
 */
const AllowListedDomainRemoveModal = ({onCTA}) => {
  const intl = useIntl();

  return (
    <AlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      onPrimaryAction={onCTA}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.assetSettings.allowListedDomainRemoveModal.btnRemove',
      })}
      title={intl.formatMessage({
        id: 'settings.assetSettings.allowListedDomainRemoveModal.title',
      })}
      variant="destructive"
    >
      <Content>
        <Flex direction="column" gap="size-150" marginY="size-150">
          <Text>
            {intl.formatMessage({
              id: 'settings.assetSettings.allowListedDomainRemoveModal.content',
            })}
          </Text>
          <Text>
            {intl.formatMessage({
              id: 'settings.assetSettings.allowListedDomainRemoveModal.content.second',
            })}
          </Text>
        </Flex>
        <GoUrl marginTop="size-150" name="asset_settings">
          {intl.formatMessage({
            id: 'settings.assetSettings.allowListedDomainRemoveModal.urlLabel',
          })}
        </GoUrl>
      </Content>
    </AlertDialog>
  );
};

AllowListedDomainRemoveModal.propTypes = {
  /**
   * Callback to invoke when user confirms dialog.
   */
  onCTA: PropTypes.func.isRequired,
};

export default AllowListedDomainRemoveModal;
