import {runInAction} from 'mobx';

import MemberStore from 'common/stores/member-store/MemberStore';

// Domain data for 'User groups' page.
class UserGroupStore extends MemberStore {
  constructor(props) {
    super(props);

    this.load();
  }

  async removeSelectedMembers() {
    this.isLoading = true;

    try {
      await this.memberList.removeUserGroups(this.selectedItems);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default UserGroupStore;
