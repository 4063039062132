import {DialogContainer} from '@adobe/react-spectrum';
import React, {useCallback, useState} from 'react';

import rootStore from 'core/RootStore';
import AppIntegrationsManageUsersModal from 'features/products/app-integrations/components/manage-accepted-users/AppIntegrationsManageUsersModal';
import AppIntegrationsTableSection from 'features/products/app-integrations/components/table/AppIntegrationsTableSection';
import {AppIntegrationTableContextProvider} from 'features/products/app-integrations/hooks/AppIntegrationTableContext';

/**
 * @description The subpage for /products/application-integrations/user-accepted route
 *
 * @returns AppIntegrationsUserAcceptedSubpage component
 */
const AppIntegrationsUserAcceptedSubpage = () => {
  // This tracks the specific app integration clicked by the table.
  const [appIntegration, setAppIntegration] = useState();
  const [isManageUsersModalOpen, setIsManageUsersModalOpen] = useState(false);

  const onManageUsersPress = useCallback((clickedAppIntegration) => {
    setAppIntegration(clickedAppIntegration);
    setIsManageUsersModalOpen(true);
  }, []);

  return (
    <AppIntegrationTableContextProvider
      hasAcceptedUsers
      onManageUsersPress={onManageUsersPress}
      orgId={rootStore.organizationStore.activeOrgId}
    >
      <AppIntegrationsTableSection hasAcceptedUsers />
      <DialogContainer onDismiss={() => setIsManageUsersModalOpen(false)}>
        {isManageUsersModalOpen && (
          <AppIntegrationsManageUsersModal
            clientId={appIntegration.clientIds[0]}
            integrationName={appIntegration.name}
          />
        )}
      </DialogContainer>
    </AppIntegrationTableContextProvider>
  );
};

export default AppIntegrationsUserAcceptedSubpage;
