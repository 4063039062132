import {getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

import AppConstants from 'common/services/AppConstants';

let clientId, includeRoles, url;

/**
 * Configure JIL org contract migrations API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL org contract migrations api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * Configure JIL org contract migrations API
 *
 * @param {String} contractId - The contract id
 * @param {String} type - The contract migration type. Ex: TEAM_VIP_TO_TEAM_EVIP
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const getMigrations = ({contractId, type}) =>
  axios.get(`${url}/v2/organizations/${AppConstants.orgId}/contracts/${contractId}/migrations`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {type},
  });

/**
 * Migrate user to new prooduct.
 *
 * @param {Array<Object>} requestBody - Array of data to migrate user to new prooduct
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const migrate = (requestBody) =>
  axios.post(`${url}/v2/organizations/${AppConstants.orgId}:migrate.delegates`, requestBody, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilOrganizationContractMigration = {configure, getMigrations, migrate};

export default jilOrganizationContractMigration;
