/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */

import {log, restoreDataToQueryParams} from '@admin-tribe/binky';
import debounce from 'lodash/debounce';
import {useEffect, useState} from 'react';

import {isLocationReactRoute} from 'core/router/route-migration/routeMigrationUtils';

import {
  disableRouting as disableAngularRouting,
  enableRouting as enableAngularRouting,
} from '../utils/angularUtils';

import useLocationChange from './useLocationChange';
import useShellResizer from './useShellResizer';

/**
 * @description Attempts to restore the persistent query params to the URL. It is debounced
 * so that it is only triggered once when the URL changes in quick succession.
 */
const restorePersistentQueryParams = debounce(() => {
  // We cannot restore the query params while on some paths because it interferes with the URL
  // change that is made when certain actions are made. These may be able to be removed when
  // the port to React is complete for those pages.
  const dontRestoreOnThesePaths = ['/add-products', '/users', '/renew-products'];

  const doRestore = dontRestoreOnThesePaths.every(
    (path) => !window.location.pathname.endsWith(path)
  );
  if (doRestore) {
    restoreDataToQueryParams();
  }
}, 1000);

/**
 * Determines which shell to render based on the current path
 * @returns {{isSrc1Route: boolean | null}}
 */
const useShellSwitcher = () => {
  const {currentLocationPathname} = useLocationChange();
  const [isSrc1Route, setIsSrc1Route] = useState(null);
  useShellResizer({isSrc1Route});

  /** Determines if the current route is React or Angular */
  useEffect(() => {
    restorePersistentQueryParams();

    const isReactRoute = isLocationReactRoute(currentLocationPathname);

    setIsSrc1Route(!isReactRoute);

    // eslint-disable-next-line promise/prefer-await-to-callbacks, promise/prefer-await-to-then -- Needed to test error case
    (isReactRoute ? disableAngularRouting : enableAngularRouting)().catch((error) => {
      log.error(error);
    });
  }, [currentLocationPathname]);

  return {isSrc1Route};
};

export default useShellSwitcher;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
