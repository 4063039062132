import {Content, Flex, Header, Heading, Link, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {TitledSection} from '@pandora/react-titled-section';
import React from 'react';
import {useIntl} from 'react-intl';

const SHARED_PROJECTS_LEARN_MORE_URL =
  'https://www.adobe.com/go/learn-more-libraries-business-storage-admin';

/**
 * Detail drawer for shared projects page
 */
const SharedProjectsDetailDrawer = () => {
  const intl = useIntl();

  return (
    <Drawer aria-label={intl.formatMessage({id: 'storage.sharedProjects.drawer.ariaLabel'})}>
      <Header>
        <Heading level={2} marginBottom="size-125" marginTop="size-0">
          {intl.formatMessage({id: 'storage.sharedProjects.title'})}
        </Heading>
      </Header>
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>
            {intl.formatMessage({id: 'storage.sharedProjects.drawer.description.overview'})}
          </Text>
          <Text>
            {intl.formatMessage({id: 'storage.sharedProjects.drawer.description.active'})}
          </Text>
          <Text>
            {intl.formatMessage({id: 'storage.sharedProjects.drawer.description.inactive'})}
          </Text>
        </Flex>
        <TitledSection
          marginTop="size-200"
          title={intl.formatMessage({
            id: 'storage.sharedProjects.drawer.helpfulLink',
          })}
        >
          <View marginTop="size-150">
            <Link href={SHARED_PROJECTS_LEARN_MORE_URL} target="_blank">
              {intl.formatMessage({id: 'storage.sharedProjects.drawer.goUrl'})}
            </Link>
          </View>
        </TitledSection>
      </Content>
    </Drawer>
  );
};

export default SharedProjectsDetailDrawer;
