/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to display the score for a scorecard.
 * @param {ScorecardGroupItem|Number|String} If ScorecardGroupItem, relevant property is 'score'.
 * @returns {String} if 'score' is NaN or Number.MAX_SAFE_INTEGER, then translation of 'Unlimited', if 'score' is a
 *   number, then number run thru number filter, else the unmodified 'score'.
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.scorecard').filter('appScorecardScore', appScorecardScoreFilter);

  /* @ngInject */
  function appScorecardScoreFilter($translate, _, numberFilter) {
    return getScorecardScore;

    function getScorecardScore(itemOrScore) {
      const score = _.isObject(itemOrScore) ? itemOrScore.score : itemOrScore;

      if (_.isNaN(score) || score === Number.MAX_SAFE_INTEGER) {
        return $translate.instant('widgets.common.scorecard.scorecardGroup.score.unlimited');
      }

      return _.isNumber(score) ? numberFilter(score) : score;
    }
  }
})();
