(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common.links.tab-link
   * @description module that defines the tab-link widget
   */
  angular.module('app.widgets.common.links.tab-link', [
    'app.shell.navigation.links',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'pascalprecht.translate',
  ]);
})();
