import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * The right hand manage button for overview pods.
 */
const ManageButton = ({onPress, ...props}) => {
  const intl = useIntl();

  return (
    <Link aria-label={props['aria-label']} data-testid={props['data-testid']} onPress={onPress}>
      {intl.formatMessage({id: 'common.buttons.manage'})}
    </Link>
  );
};

ManageButton.propTypes = {
  /**
   * aria-label for button
   */
  'aria-label': PropTypes.string.isRequired,
  /**
   * data-testid for button
   */
  'data-testid': PropTypes.string,
  /**
   * Callback when the link is pressed
   */
  onPress: PropTypes.func.isRequired,
};

export default ManageButton;
