// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Product} from '@admin-tribe/binky';
import {InfoBar} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {getCustomModelProductUsage} from 'core/products/utils/productUsageUtils';
import ExtendedScoreCard from 'features/products/components/extended-score-card/ExtendedScoreCard';

const CustomModelsInfoBar = ({product}) => {
  const learnMoreGoUrlCode = 'aac_learn_custom_models'; // See ONESIE-41485
  const [productUsage, setProductUsage] = React.useState(null);

  // Load the product usage data from the backend service
  useEffect(() => {
    const controller = new AbortController();
    async function load() {
      const usage = await getCustomModelProductUsage(product, controller.signal);
      setProductUsage(usage);
    }
    load();
  }, [product]);

  return (
    <InfoBar>
      <ExtendedScoreCard
        contentId="product.details.customModelsInfoBar.contextualHelp.total.content"
        count={productUsage?.total}
        goUrlCode={learnMoreGoUrlCode}
        labelId="product.details.customModelsInfoBar.total.label"
        linkId="product.details.customModelsInfoBar.contextualHelp.learnMoreLink"
        titleId="product.details.customModelsInfoBar.contextualHelp.total.title"
      />
      <ExtendedScoreCard
        contentId="product.details.customModelsInfoBar.contextualHelp.consumed.content"
        count={productUsage?.used}
        goUrlCode={learnMoreGoUrlCode}
        labelId="product.details.customModelsInfoBar.consumed.label"
        linkId="product.details.customModelsInfoBar.contextualHelp.learnMoreLink"
        titleId="product.details.customModelsInfoBar.contextualHelp.consumed.title"
      />
    </InfoBar>
  );
};

CustomModelsInfoBar.propTypes = {
  /**
   * The product we're displaying profiles (aka license groups) for.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default CustomModelsInfoBar;
