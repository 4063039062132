import {FeaturesCache, jilAuthenticationPolicies, log} from '@admin-tribe/binky';
import {addPandoraContentModel} from '@admin-tribe/binky-ui';
import {runInAction} from 'mobx';

import {initApiServices} from 'common/api/apiServices';
import jilDnsToken from 'common/api/jil/jilDnsToken';
import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import {configure as configurePackagesCcesProvider} from 'features/packages/api/packagesCces';
import {configure as configurePackagesIngestProvider} from 'features/packages/api/packagesIngest';
import {configure as configureTronCacheableApiProvider} from 'features/packages/api/tronCacheableApi';
import {configure as configureTronDataProvider} from 'features/packages/api/tronData';
import {configure as configureDirectorySync} from 'features/settings/api/directory-sync';
import {configure as configureImsFederated} from 'features/settings/api/ims-federated';
import jilDirectoryDomains from 'features/settings/api/jilDirectoryDomains';
import jilTrusts from 'features/settings/api/jilTrusts';
import samlCertificates from 'features/settings/api/samlCertificates';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import initializeHelp from 'shell/help/get-started/initializeHelp';

import {loadStrings} from './locales';
import pandoraContentModel from './pandoraContentModel';

/**
 * Features Cache initialization function
 * @param {Object} config - App configuration based on env
 * @param {String} orgId - Active Organization Id
 * @returns {Promise<undefined>} - Promise that initializes the FeaturesCache
 */
const initializeFeaturesCache = async (config, orgId) => {
  try {
    const featuresCache = new FeaturesCache(config, orgId);
    await featuresCache.executeVendors();
  } catch (error) {
    log.error('Failed to execute feature cache vendors. Error:', error);
  }
};

/**
 * Auto-Assignment Rules Cache initialization function
 * @param {Object} config - App configuration based on env
 * @param {String} orgId - Active Organization ID
 * @returns {Promise<undefined>} - Promise that initializes the AutoAssignRulesCache
 */
const initializeAutoAssignRulesCache = async (config, orgId) => {
  const autoAssignRulesCache = new AutoAssignRulesCache(config, orgId);
  await autoAssignRulesCache.refresh();
};

/**
 * Performs one-time application startup tasks required before rendering any react
 * components.  Examples might include pre-loading asset files and caching
 * data from api endpoints.
 *
 * @param {Object} options - bootstrap options
 * @param {String} options.language - Language code for selected locale.
 * @param {Object} options.configuration - App configuration based on env
 *
 * @returns {Promise} Promise object that resolves when dependencies are satisfied.
 */
const bootstrap = ({accessToken, configuration, idpStateManager, language, org}) => {
  addPandoraContentModel(pandoraContentModel);

  return Promise.all([
    loadStrings(language),
    AppConstants.init({accessToken, configuration, countryCode: org.countryCode, orgId: org.id}),
    new Promise((resolve) => {
      runInAction(() => {
        rootStore.organizationStore.activeOrg = org;
        resolve();
      });
    }),
    ExternalAuthService.init(idpStateManager, configuration),
    initApiServices(),
    jilDirectoryDomains.configure(configuration.services.jil),
    jilTrusts.configure(configuration.services.jil),
    jilDnsToken.configure(configuration.services.jil),
    jilAuthenticationPolicies.configure(configuration.services.jil),
    samlCertificates.configure(configuration.services.imsFederated),
    configureDirectorySync(configuration.services.directorySync),
    configureImsFederated(configuration.services.imsFederated),
    configurePackagesCcesProvider(configuration.services.cces),
    configureTronDataProvider(configuration.services.packages),
    configureTronCacheableApiProvider(configuration.services.packages),
    configurePackagesIngestProvider(configuration.services.ingest),
    initializeAutoAssignRulesCache(configuration, org.id),
    initializeFeaturesCache(configuration, org.id),
    initializeHelp(),
  ]);
};

export default bootstrap;
