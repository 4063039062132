import binkyUI, {OverlayWait} from '@admin-tribe/binky-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Flex,
  Heading,
  Provider,
  useDialogContainer,
} from '@adobe/react-spectrum';
import Provider2 from '@react/react-spectrum/Provider';
import {Step, StepList} from '@react/react-spectrum/StepList';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import DomainsTextArea from 'features/settings/components/dns-import-domains-modal/DomainsTextArea';
import ReviewDomainsTable from 'features/settings/components/dns-import-domains-modal/ReviewDomainsTable';
import useStore from 'features/settings/hooks/useStore';
import AddDomainsByDnsStore, {STEPS} from 'features/settings/stores/AddDomainsByDnsStore';

const ModalDialog = binkyUI.common.components.modal.ModalDialog;

const MODAL_ID = 'add-domains-by-dns-modal';

const DnsImportDomainsModal = ({directoryId, onDomainsAdded = () => {}}) => {
  const store = useStore(() => new AddDomainsByDnsStore({directoryId}));
  const dialog = useDialogContainer();
  const modalHeadingId = useId();

  const handleClaimDomains = async () => {
    await store.claimDomains();
    onDomainsAdded();
    dialog.dismiss();
  };

  return (
    <ModalDialog height="100vh" id={MODAL_ID}>
      <Heading id={modalHeadingId} level={2}>
        <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.title" />
      </Heading>
      <Divider />
      <Content>
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            <OverlayWait isLoading={store.isLoading} showContent size="M">
              <StepList interaction="off" selectedIndex={store.selectedStepIndex}>
                <Step>
                  <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.enterDomains" />
                </Step>
                <Step>
                  <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.reviewDomains" />
                </Step>
              </StepList>

              {store.step === STEPS.ADD_DOMAINS && (
                // eslint-disable-next-line react/jsx-handler-names -- should not do this for store functions
                <DomainsTextArea onChange={store.setDomainsValue} />
              )}
              {store.step === STEPS.REVIEW_DOMAINS && (
                <ReviewDomainsTable
                  domains={store.domains}
                  onRemoveDomain={(domainName) => store.removeDomain(domainName)}
                />
              )}
            </OverlayWait>
          </Provider>
        </Provider2>
      </Content>
      <ButtonGroup justifySelf="space-between">
        <Flex flex={1}>
          <Button
            data-test-id="prev-btn"
            isDisabled={store.step === STEPS.ADD_DOMAINS}
            onPress={() => store.goToStep(STEPS.ADD_DOMAINS)}
            variant="secondary"
          >
            <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.previous" />
          </Button>
        </Flex>
        <Flex flex={1} justifyContent="end">
          <Button
            data-test-id="cancel-btn"
            // eslint-disable-next-line react/jsx-handler-names -- does not work as expected
            onPress={dialog.dismiss}
            variant="primary"
          >
            <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.cancel" />
          </Button>

          {store.step === STEPS.ADD_DOMAINS && (
            <Button
              data-test-id="next-btn"
              isDisabled={store.isLoading || store.domainsValue.length === 0}
              onPress={() => store.checkDomains()}
              variant="accent"
            >
              <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.next" />
            </Button>
          )}

          {store.step === STEPS.REVIEW_DOMAINS && (
            <Button
              data-test-id="add-btn"
              isDisabled={store.isLoading || !store.canAddDomains}
              onPress={handleClaimDomains}
              variant="accent"
            >
              <FormattedMessage id="settings.directoryDomains.dnsImportDomainsModal.add" />
            </Button>
          )}
        </Flex>
      </ButtonGroup>
    </ModalDialog>
  );
};

DnsImportDomainsModal.propTypes = {
  directoryId: PropTypes.string,
  onDomainsAdded: PropTypes.func,
};

export default observer(DnsImportDomainsModal);
