(function () {
  angular.module('app.core.cart', [
    'app.core.organizations.organization-manager',
    'binky.core.api.ccs',
    'binky.core.cart',
    'binky.core.common.model.cache',
    'binky.core.fraud-mitigation',
    'binky.core.fulfillment-events.refresh-manager',
    'binky.core.lodash',
    'binky.core.organizations.market-subsegments',
    'binky.core.price',
  ]);
})();
