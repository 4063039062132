(function () {
  angular.module('app.widgets.common.drag-and-drop-file', [
    'app.widgets.common.file-size',
    'app.widgets.file-attachment',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.toast',
    'pascalprecht.translate',
  ]);
})();
