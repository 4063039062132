(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('app.core.feature').factory('featureHandler', featureHandler);

  /* @ngInject */
  function featureHandler($injector, _, feature) {
    let featureFlagRequirements;

    return {
      configure,
      handler,
    };

    function configure(configData) {
      featureFlagRequirements = _.get(configData, 'featureFlagRequirements');
    }

    function handler(flag) {
      const orgIdMatches = _.get(featureFlagRequirements, `${flag}.orgIdMatches`);
      if (
        flag !== 'force_requirements_match' &&
        feature.isDisabled('force_requirements_match') &&
        _.isArray(orgIdMatches)
      ) {
        const OrganizationManager = $injector.get('OrganizationManager');
        const activeOrgId = OrganizationManager.getActiveOrgId();
        return _.some(orgIdMatches, (regexStr) => activeOrgId.match(regexStr));
      }

      const orgIdDoesNotMatch = _.get(featureFlagRequirements, `${flag}.orgIdDoesNotMatch`);
      if (
        flag !== 'force_requirements_match' &&
        feature.isDisabled('force_requirements_match') &&
        _.isArray(orgIdDoesNotMatch)
      ) {
        const OrganizationManager = $injector.get('OrganizationManager');
        const activeOrgId = OrganizationManager.getActiveOrgId();
        return !_.some(orgIdDoesNotMatch, (regexStr) => activeOrgId.match(regexStr));
      }
      return true;
    }
  }
})();
