/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketEmailFormSection
 *
 * @description The email section for a support form.
 *
 * @binding {String} descriptionKey. The key for the description for this section.
 * @binding {String} email. The email address string to display.
 * @binding {String} [showSeparator] Default is true. To show the default separator/border before the section content.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketEmailFormSection', {
    bindings: {
      descriptionKey: '@',
      email: '@',
      showSeparator: '@?',
    },
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-email-form-section/support-ticket-email-form-section.component.html',
  });
})();
