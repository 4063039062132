import axios from 'axios';

import {getAuthorizationHeader} from 'common/api/httpUtils';
import rootStore from 'core/RootStore';

const SCHEDULE_OPTIONS = {
  DAILY: 'daily',
  MANUAL: 'manual',
  WEEKLY: 'weekly',
};

const SYNC_PROVIDERS = {
  CLASSLINK: 'classlink',
  CLEVER: 'clever',
};

const ERROR_CODES = {
  CCALL_INTEGRATION_MISSING: 'CCALL_INTEGRATION_MISSING',
  CCX_INTEGRATION_MISSING: 'CCX_INTEGRATION_MISSING',
  LICENSE_NOT_AVAILABLE: 'LICENSE_NOT_AVAILABLE',
  LICENSE_VARIANTS_CONFLICT: 'LICENSE_VARIANTS_CONFLICT',
  USER_NOT_DISTRICT_ADMIN: 'USER_NOT_DISTRICT_ADMIN',
};

let baseUrl, clientId;

/**
 * Builds the headers for EDU roster sync API calls
 */
const getHeaders = () => ({
  ...getAuthorizationHeader(),
  'X-Api-Key': clientId,
});

/**
 * Configures the EDU sync service with the required parameters.
 *
 * @param {String} url Base url for the EDU sync service.
 * @param {String} apiClientId Client id identifying the caller of the roster sync service.
 */
const configure = ({apiClientId, url}) => {
  baseUrl = `${url}/api/v1`;
  clientId = apiClientId;
};

/**
 * Get the access token for the provided sync source based on the
 * authorization code and redirect URI.
 */
const getAccessToken = ({rosterProvider, authCode, redirectUri}) =>
  axios.post(
    `${baseUrl}/tokens`,
    {
      code: authCode,
      redirectUri,
      rosterProvider,
    },
    {
      headers: {
        ...getHeaders(),
      },
    }
  );

/**
 * Get the districts for a roster source.
 */
const getRosterTenants = ({rosterProvider, accessToken}) =>
  axios.get(`${baseUrl}/rosters/${rosterProvider}/district`, {
    headers: {
      ...getHeaders(),
      'X-Roster-Authorization': accessToken,
    },
  });

/**
 * Create a new sync for the selected provider at login.
 */
const createRosterSync = ({
  accessToken,
  directoryId,
  districtId,
  products,
  rosterProvider,
  syncSchedule,
}) =>
  axios.post(
    `${baseUrl}/syncs/`,
    {
      directoryId,
      districtId,
      orgId: rootStore.organizationStore.activeOrgId,
      products,
      rosterProvider,
      // We keep MANUAL as a default for now for v1 sync to still work.
      // For v2 we get the provided syncSchedule and send that
      syncSchedule: syncSchedule || 'MANUAL',
    },
    {
      headers: {
        ...getHeaders(),
        'X-Roster-Authorization': accessToken,
      },
    }
  );

/**
 * Get a list of syncs for which the EDU roster sync is configured on an org
 */
const getOrgRosterSyncs = () =>
  axios.get(`${baseUrl}/syncs/organization/${rootStore.organizationStore.activeOrgId}`, {
    headers: {
      ...getHeaders(),
    },
  });

/**
 * Get a list of all the roster syncs configured for a specific directory
 */
const getAllDirectoryRosterSyncs = ({directoryId}) =>
  axios.get(`${baseUrl}/syncs/directory/${directoryId}`, {
    headers: {
      ...getHeaders(),
    },
  });

/**
 * Fetch the details of a roster sync by id
 */
const fetchRosterSyncInfo = ({syncId, fetchIntegration = null}) =>
  axios.get(`${baseUrl}/syncs/${syncId}`, {
    headers: {
      ...getHeaders(),
    },
    params: {
      fetchIntegration,
    },
  });

/**
 * Queue or stop(pause)/resume a roster sync by id
 */
const putRosterSyncAction = ({syncId, syncAction}) =>
  axios.put(
    `${baseUrl}/syncs/${syncId}/actions`,
    {syncAction},
    {
      headers: {
        ...getHeaders(),
      },
    }
  );

/**
 * Patches a roster sync with the given data.
 *
 * @param syncId
 * @param data
 * @param data.schedule Update the sync schedule with the provided value
 * @param data.products Update the sync products with the provided value
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchRosterSync = ({syncId, data}) =>
  axios.patch(`${baseUrl}/syncs/${syncId}`, data, {headers: {...getHeaders()}});

/**
 * Remove a roster sync by id
 */
const removeRosterSync = ({syncId}) =>
  axios.delete(`${baseUrl}/syncs/${syncId}`, {headers: {...getHeaders()}});

const eduRosterSync = {
  configure,
  createRosterSync,
  fetchRosterSyncInfo,
  getAccessToken,
  getAllDirectoryRosterSyncs,
  getOrgRosterSyncs,
  getRosterTenants,
  patchRosterSync,
  putRosterSyncAction,
  removeRosterSync,
};

export default eduRosterSync;
export {SYNC_PROVIDERS, SCHEDULE_OPTIONS, ERROR_CODES, getHeaders};
