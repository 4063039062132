import {Content, ContextualHelp, Flex, Link, StatusLight} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {DOMAIN_STATUSES} from 'common/entities/DomainEntity';
import {JIL_ADD_DOMAINS_ERROR} from 'features/settings/components/domains/add-domains-modal/addDomainsModalConstants';
import {goToSupport} from 'features/support/routing/navigation-callbacks/navigationCallbacks';

import styles from './AddDomainStatus.pcss';

const ADD_DOMAIN_STATUS_LIGHT = {
  [DOMAIN_STATUSES.UNCLAIMED]: {
    label: 'settings.directoryDomains.status.UNCLAIMED',
    variant: 'notice',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_ALREADY_CLAIMED_ACTIVE',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN',
    variant: 'negative',
  },
  DOMAIN_ALREADY_CLAIMED_BY_ORG_PROVISIONAL: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_ALREADY_CLAIMED_BY_ORG_PROVISIONAL',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_NAME_INVALID]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_NAME_INVALID',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.DOMAIN_RESERVED]: {
    label: 'settings.domains.addDomainsTable.statuses.DOMAIN_RESERVED',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.ORG_PENDING_ESM_MIGRATION]: {
    label: 'settings.domains.addDomainsTable.statuses.ORG_PENDING_MIGRATION',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.ORG_PENDING_T2E_MIGRATION]: {
    label: 'settings.domains.addDomainsTable.statuses.ORG_PENDING_MIGRATION',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.ORGS_DO_NOT_SHARE_GTM]: {
    label: 'settings.domains.addDomainsTable.statuses.ORGS_DO_NOT_SHARE_GTM',
    variant: 'negative',
  },
  [JIL_ADD_DOMAINS_ERROR.ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST]: {
    label: 'settings.domains.addDomainsTable.statuses.ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST',
    variant: 'negative',
  },
  UNKNOWN: {
    label: 'settings.domains.addDomainsTable.statuses.other',
    variant: 'negative',
  },
};

const AddDomainStatus = ({domain}) => {
  const intl = useIntl();

  const errorCode = domain?.response?.errorCode;

  if (errorCode === JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_ACTIVE) return null;

  let {variant, label} =
    ADD_DOMAIN_STATUS_LIGHT[domain.response.status] ??
    ADD_DOMAIN_STATUS_LIGHT[errorCode] ??
    ADD_DOMAIN_STATUS_LIGHT.UNKNOWN;

  const hasProvisionalTag = domain?.response?.errorContext?.tags?.includes('provisional');

  if (
    errorCode === JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN &&
    hasProvisionalTag
  ) {
    variant = ADD_DOMAIN_STATUS_LIGHT.DOMAIN_ALREADY_CLAIMED_BY_ORG_PROVISIONAL.variant;
    label = ADD_DOMAIN_STATUS_LIGHT.DOMAIN_ALREADY_CLAIMED_BY_ORG_PROVISIONAL.label;
  }

  const domainsWithContextualHelp = [
    JIL_ADD_DOMAINS_ERROR.ORGS_DO_NOT_SHARE_GTM,
    JIL_ADD_DOMAINS_ERROR.DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN,
    JIL_ADD_DOMAINS_ERROR.ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST,
  ];
  const hasContextualHelp = domainsWithContextualHelp.includes(errorCode) && !hasProvisionalTag;

  return (
    <StatusLight
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed more than provided style props
      UNSAFE_className={styles['domain-status-light']}
      variant={variant}
    >
      <Flex justifyContent="space-between" width="100%">
        <FormattedMessage id={label} />
        {hasContextualHelp && (
          <ContextualHelp alignSelf="center">
            <Content>
              <FormattedMessage
                id={`${label}.contextualHelp`}
                values={{
                  goUrl: (linkText) => (
                    <GoUrl name="mixed-storage-plans_org_prod">{linkText}</GoUrl>
                  ),
                  link: (linkText) => (
                    <Link
                      aria-label={intl.formatMessage({
                        id: 'settings.domains.addDomainsTable.statuses.adobeSupport.ariaLabel',
                      })}
                      onPress={goToSupport}
                    >
                      <span>{linkText}</span>
                    </Link>
                  ),
                }}
              />
            </Content>
          </ContextualHelp>
        )}
      </Flex>
    </StatusLight>
  );
};

AddDomainStatus.propTypes = {
  /**
   * The domains for the table in AddDomains Modal
   */
  domain: PropTypes.shape({
    domainName: PropTypes.string,
    response: PropTypes.shape({
      errorCode: PropTypes.string,
      errorContext: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
      status: PropTypes.string,
    }),
  }),
};

export default AddDomainStatus;
export {ADD_DOMAIN_STATUS_LIGHT};
