import {Flex, Header, Link, Switch, Text, View, Well} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {groupPropType} from '../prop-types/configurationGroupsPropTypes';

/**
 * @description Whether to display the description for a configuration group
 *
 * @param {object} group The configuration group to check
 * @returns {boolean} true if the gorup should be returned, false otherwise
 */
const displayEditConfigurationGroupDescription = (group) =>
  group.description || group.linkHref || group.canDisable;

const EditConfigurationGroupDescription = ({group, setGroupEnabled}) => {
  const intl = useIntl();
  return (
    <Well>
      <Flex direction="row" gap="size-0">
        <Flex direction="column" flexGrow="1" gap="size-0">
          <Header data-testid="permission-name" level={3} UNSAFE_style={{fontWeight: 'bold'}}>
            {group.name}
          </Header>
          {group.description && <Text>{group.description}</Text>}
          {group.linkHref && (
            <View>
              <Link>
                <a href={group.linkHref} rel="noreferrer" target="_blank">
                  <Flex
                    alignItems="center"
                    data-testid="permission-description"
                    direction="row"
                    gap="size-50"
                    UNSAFE_style={{display: 'inline-flex'}}
                  >
                    {intl.formatMessage(
                      {
                        id: 'products.productProfilePermissions.editPermissionsModal.learnMoreAbout',
                      },
                      {name: group.name}
                    )}
                    <LinkOutIcon
                      aria-label={intl.formatMessage({
                        id: 'products.productProfilePermissions.editPermissionsModal.opensWindow',
                      })}
                      flexShrink={0}
                      size="XS"
                    />
                  </Flex>
                </a>
              </Link>
            </View>
          )}
        </Flex>
        {group.canDisable && (
          <Switch isSelected={!group.disabled} onChange={setGroupEnabled}>
            {intl.formatMessage({
              id: group.disabled
                ? 'products.productProfilePermissions.editPermissionsModal.off'
                : 'products.productProfilePermissions.editPermissionsModal.on',
            })}
          </Switch>
        )}
      </Flex>
    </Well>
  );
};

EditConfigurationGroupDescription.propTypes = {
  group: groupPropType.isRequired,
  setGroupEnabled: PropTypes.func.isRequired,
};

export default EditConfigurationGroupDescription;
export {displayEditConfigurationGroupDescription};
