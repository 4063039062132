import binky from '@admin-tribe/binky';
import {runInAction} from 'mobx';

import TableConstants from 'common/components/table/TableConstants';
import AppConstants from 'common/services/AppConstants';

import ans from '../../api/ans';
import Notification from '../Notification';

const log = binky.services.log;

// we filter notifications to a specific start date as
// we don't care about those previously generated for EntD
const NOTIFICATIONS_START_TIME = 1497408559000;
const PAGE_SIZE = TableConstants.PAGE_SIZES[0].value;

class NotificationList {
  /**
   * @description Method to fetch the singleton NotificationList.
   * @returns {NotificationList} the NotificationList.
   */
  static get() {
    if (!NotificationList.model) {
      NotificationList.model = new NotificationList();
      NotificationList.model.refresh();
    }
    return NotificationList.model;
  }

  /**
   * @class
   * @description Creates a new NotificationList for use.
   */
  constructor() {
    this.items = [];
    this.orgId = AppConstants.orgId;
  }

  /**
   * @description Method to delete selected notifications from the back-end.
   *
   * @param {Array} ids - List of Notification id that need to be deleted
   */
  async delete(ids) {
    if (ids?.length > 0) {
      try {
        await ans.deleteNotifications({
          notifications: {
            notification: ids.map((id) => ({'notification-id': id})),
          },
        });
        this.filteredItems = this.filteredItems.filter((item) => !ids.includes(item.id));
      } catch (error) {
        log.error('NotificationList failed to delete. Error: ', error);
        throw new Error('NotificationList failed to delete.');
      }
    } else {
      throw new Error('ID is required to delete Notification');
    }
  }

  /**
   * @description Method to filter the notification list to those relevant to this org.
   * @returns {Array} the relevant items, or an empty list.
   */
  getForActiveOrg() {
    if (!this.filteredItems || this.orgId !== AppConstants.orgId) {
      this.orgId = AppConstants.orgId;
      this.filteredItems = this.items.filter(
        // make this comparison case-insensitive since orgId from Sophia might be upper case
        (item) => item.payload.org_id?.toLowerCase() === this.orgId?.toLowerCase()
      );
      this.filteredItems.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
    }
    return this.filteredItems;
  }

  /**
   * @description Method to fetch the paginated notification items.
   *
   * @param {Number} currentPage the current page.
   *
   * @returns {Array<Notification>} the pagianted notification items.
   */
  getPaginatedItems(currentPage) {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return this.getForActiveOrg().slice(startIndex, startIndex + PAGE_SIZE);
  }

  /**
   * @description Method to fetch the unread count.
   * @returns {Number} the count of unread items.
   */
  getUnreadCount() {
    return this.getForActiveOrg().filter((item) => !item.state.read).length;
  }

  /**
   * @description Method to load and process the notification list.
   */
  refresh() {
    // Clear the stored data, fetching fresh data
    this.items = [];
    this.promise = new Promise((resolve, reject) => {
      const fetchNextPage = async (to, from) => {
        try {
          const response = await ans.getNotifications({from, to});

          runInAction(() => {
            if (response?.notifications?.notification) {
              response.notifications.notification.forEach((notification) => {
                this.items.push(new Notification(notification));
              });

              const previous = response.paging?.previous;
              if (previous && response.notifications.notification.length >= PAGE_SIZE) {
                const match =
                  previous.match(/https:\/\/[^/]+\/ans\/v1\/notifications.*[&?]to=(\d+)/) || [];
                if (match.length > 1) {
                  fetchNextPage(match[1]);
                  return;
                }
              }
            }
            // Over the iterations, filteredItems can be [], keep undefined to allow it to be properly set when items are fully re-populated.
            this.filteredItems = undefined;
            resolve();
          });
        } catch (error) {
          log.error('NotificationList failed to refresh. Error: ', error);
          reject(error);
        }
      };

      fetchNextPage(Date.now(), NOTIFICATIONS_START_TIME);
    });
  }

  /**
   * @description Method to save changes to the notification list to the back-end.
   *
   * @param {Array} ids - List of Notification id that need to be updated
   */
  async save(ids) {
    if (ids?.length > 0) {
      try {
        await ans.updateNotifications({
          notifications: {
            notification: ids.map((id) => {
              const changedNotification = this.filteredItems.find((item) => item.id === id);
              changedNotification.negateState();
              return changedNotification.serialize();
            }),
          },
        });
      } catch (error) {
        // roll back change if api call failed
        ids.forEach((id) => {
          this.filteredItems.find((item) => item.id === id).negateState();
        });
        log.error('NotificationList failed to save. Error: ', error);
        throw new Error('NotificationList failed to save.');
      }
    } else {
      throw new Error('ID is required to update Notification');
    }
  }
}

export default NotificationList;
