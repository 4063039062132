(function () {
  /**
   * @deprecated replaced by the Pandora Add Products Modal
   */
  angular
    .module('app.core.digital-river-utils')
    .factory('DigitalRiverUtils', DigitalRiverUtilsModel);

  /* @ngInject */
  function DigitalRiverUtilsModel(_, DIGITAL_RIVER_COUNTRY_TO_LANGUAGE_MAP) {
    const service = {
      getPrivacyPolicyLink,
      getTermsLink,
    };

    return service;

    /////////////

    function getPrivacyPolicyLink(countryCode) {
      const languageCode = getLanguageCode(countryCode);
      return `https://shop.adobe.com/store/defaults/${languageCode}/DisplayDRPrivacyPolicyPage`;
    }

    function getTermsLink(countryCode) {
      const languageCode = getLanguageCode(countryCode);
      return `https://shop.adobe.com/store/defaults/${languageCode}/DisplayDRTermsAndConditionsPage`;
    }

    /////////////

    function getLanguageCode(countryCode) {
      // default to English
      return DIGITAL_RIVER_COUNTRY_TO_LANGUAGE_MAP[_.toUpper(countryCode)] || 'en_US';
    }
  }
})();
