import {
  ActionButton,
  Button,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  Text,
  View,
} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import ClockIcon from '@spectrum-icons/workflow/Clock';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React from 'react';

const BadgePopover = ({notices}) => {
  function getBackgroundColor(notice) {
    if (notice.variant === 'error') {
      return 'red-600';
    }
    if (notice.variant === 'info') {
      return 'blue-500';
    }
    if (notice.variant === 'special') {
      return 'purple-500';
    }
    if (notice.variant === 'success') {
      return 'green-500';
    }
    if (notice.variant === 'warning') {
      return 'orange-500';
    }
    return null;
  }

  function getIcon(notice) {
    if (notice.icon === 'alert') {
      return (
        <AlertIcon aria-label={notice.iconAltText} size="S" UNSAFE_style={{color: '#ffffff'}} />
      );
    }
    if (notice.icon === 'clock') {
      return (
        <ClockIcon aria-label={notice.iconAltText} size="S" UNSAFE_style={{color: '#ffffff'}} />
      );
    }
    if (notice.icon === 'info') {
      return (
        <InfoIcon aria-label={notice.iconAltText} size="S" UNSAFE_style={{color: '#ffffff'}} />
      );
    }
    return null;
  }

  function onActionPress(action) {
    return () => {
      if (action.onClick) {
        action.onClick();
      } else {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
        window.open(action.href, '_blank');
      }
    };
  }

  return (
    notices
      // we sort the items based on the tagged priority
      .sort((itm1, itm2) => itm1.priority - itm2.priority)
      .map((notice) => (
        <React.Fragment key={notice.label || notice.iconAltText}>
          {(notice.header || notice.body) && (
            <DialogTrigger type="popover">
              <View
                backgroundColor={getBackgroundColor(notice)}
                data-testid="badge-view"
                marginBottom="size-50"
              >
                <ActionButton data-testid="badge-button" isQuiet staticColor="white">
                  {getIcon(notice)}
                  {notice.label && <Text>{notice.label}</Text>}
                </ActionButton>
              </View>
              <Dialog data-testid="badge-popover-dialog">
                <Heading>{notice.header}</Heading>
                <Divider />
                <Content>
                  <Text data-testid="badge-popover-dialog-text">{notice.body}</Text>
                  {notice.action && (
                    <View marginTop="size-100">
                      <Button
                        data-testid="badge-popover-dialog-action-button"
                        onPress={onActionPress(notice.action)}
                        variant="primary"
                      >
                        {notice.action.label}
                      </Button>
                    </View>
                  )}
                </Content>
              </Dialog>
            </DialogTrigger>
          )}
          {!notice.header && !notice.body && (
            <View
              backgroundColor={getBackgroundColor(notice)}
              data-testid="badge-view"
              marginBottom="size-50"
              paddingBottom="size-75"
              paddingLeft="size-125"
              paddingRight="size-125"
              paddingTop="size-75"
            >
              <Flex alignItems="center" direction="row" gap="size-75">
                {getIcon(notice)}
                {notice.label && (
                  <Text UNSAFE_style={{color: '#ffffff', fontSize: '14px'}}>{notice.label}</Text>
                )}
              </Flex>
            </View>
          )}
        </React.Fragment>
      ))
  );
};

BadgePopover.propTypes = {
  /**
   * An array of notice objects to display.
   */
  notices: PropTypes.instanceOf(Array),
};

export default BadgePopover;
