import {Page, PageActions, PageContent, PageHeader} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {SORT_ORDER, TABLE_SECTION_ACTIONS, TableSection} from '@pandora/react-table-section';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import BuyMoreButton from 'common/components/buy-more-button/BuyMoreButton';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';

import ContractTable from './ContractTable';
import ContractTableRowData from './ContractTableRowData';
import {CONTRACT_TABLE_DATA_KEYS} from './OverviewConstants';
import useOverviewData from './hooks/useOverviewData';
import {sortRows} from './overviewUtils';

const CONTRACT_TABS = {
  CURRENT: 'CURRENT',
  INACTIVE: 'INACTIVE',
};

/**
 * Account overview page
 */
const OverviewPage = () => {
  const intl = useIntl();
  const {error, isLoading, overviewData} = useOverviewData();

  const {contracts, products} = overviewData || {};
  const generateTableData = useCallback(
    (newContracts) => {
      const {activeContracts, inactiveContracts} = newContracts || {};

      const newActiveTableRows = sortRows(
        activeContracts?.map((contract) =>
          ContractTableRowData.create({contract, intl, products})
        ) || [],
        {id: CONTRACT_TABLE_DATA_KEYS.DATE, sortedOrder: SORT_ORDER.ASC}
      );
      const newInactiveTableRows = sortRows(
        inactiveContracts?.map((contract) =>
          ContractTableRowData.create({
            contract,
            intl,
            productOptions: {
              allProducts: true,
            },
            products,
          })
        ) || [],
        {id: CONTRACT_TABLE_DATA_KEYS.DATE, sortedOrder: SORT_ORDER.ASC}
      );

      return {
        [CONTRACT_TABS.INACTIVE]: newInactiveTableRows,
        [CONTRACT_TABS.CURRENT]: newActiveTableRows,
      };
    },
    [intl, products]
  );

  const [tableData, setTableData] = useState(generateTableData(contracts));

  React.useEffect(() => {
    setTableData(generateTableData(contracts));
  }, [contracts, generateTableData]);

  const tabList = useMemo(() => {
    const onTableSectionChange = ({action, payload, tab}) => {
      if (action === TABLE_SECTION_ACTIONS.ON_SORT_BY) {
        const sorted = sortRows(tableData[tab], payload);
        setTableData({
          ...tableData,
          [tab]: sorted,
        });
      }
    };

    // Always show the current tab, even with no contracts
    const result = [
      {
        content: {
          children: (
            <TableSection
              isServerError={error}
              items={tableData[CONTRACT_TABS.CURRENT]}
              onTableSectionChange={({action, payload}) =>
                onTableSectionChange({action, payload, tab: CONTRACT_TABS.CURRENT})
              }
              pageNumber={0}
            >
              <ContractTable isLoading={isLoading} />
            </TableSection>
          ),
          props: {
            'data-testid': `table-${CONTRACT_TABS.CURRENT}`,
            key: CONTRACT_TABS.CURRENT,
          },
        },
        item: {
          children: intl.formatMessage({id: 'account.overview.page.tabs.current.title'}),
          props: {
            'data-testid': `tab-${CONTRACT_TABS.CURRENT}`,
            key: CONTRACT_TABS.CURRENT,
          },
        },
      },
    ];

    if (tableData[CONTRACT_TABS.INACTIVE]?.length > 0) {
      result.push({
        content: {
          children: (
            <TableSection
              isServerError={error}
              items={tableData[CONTRACT_TABS.INACTIVE]}
              onTableSectionChange={({action, payload}) =>
                onTableSectionChange({action, payload, tab: CONTRACT_TABS.INACTIVE})
              }
              pageNumber={0}
            >
              <ContractTable isLoading={isLoading} />
            </TableSection>
          ),
          props: {
            'data-testid': `table-${CONTRACT_TABS.INACTIVE}`,
            key: CONTRACT_TABS.INACTIVE,
          },
        },
        item: {
          children: intl.formatMessage({id: 'account.overview.page.tabs.inactive.title'}),
          props: {
            'data-testid': `tab-${CONTRACT_TABS.INACTIVE}`,
            key: CONTRACT_TABS.INACTIVE,
          },
        },
      });
    }

    return result;
  }, [error, intl, isLoading, tableData]);

  return (
    <Page data-testid="account-overview-page" isLoading={isLoading}>
      <PageHeader title={intl.formatMessage({id: 'account.overview.page.title'})} />
      {isAllowedToAddProducts() && (
        <PageActions>
          <BuyMoreButton />
        </PageActions>
      )}

      <PageContent>
        <View>
          <Text>{intl.formatMessage({id: 'account.overview.page.description.line0'})}</Text>
        </View>
        <View paddingBottom="size-400">
          <Text>
            {intl.formatMessage(
              {id: 'account.overview.page.description.line1'},
              {
                goUrl: (urlText) => (
                  <GoUrl name="contract_management_prod" noWrap={false}>
                    {urlText}
                  </GoUrl>
                ),
              }
            )}
          </Text>
        </View>
        <Tabs
          aria-label={intl.formatMessage({id: 'account.overview.page.tabs.label'})}
          disabledKeys={['inactive']}
        >
          <TabList>
            {tabList.map((tab) => (
              // eslint-disable-next-line react/jsx-key -- key is defined in tab.item.props
              <Item {...tab.item.props}>{tab.item.children}</Item>
            ))}
          </TabList>
          <TabPanels>
            {tabList.map((tab) => (
              // eslint-disable-next-line react/jsx-key -- key is defined in tab.content.props
              <Item {...tab.content.props}>
                <View marginTop="size-350">{tab.content.children}</View>
              </Item>
            ))}
          </TabPanels>
        </Tabs>
      </PageContent>
    </Page>
  );
};

export default OverviewPage;
