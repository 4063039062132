import {Button, Item, Menu, MenuTrigger, View} from '@adobe/react-spectrum';
import ChevronDown from '@react/react-spectrum/Icon/ChevronDown';
import PropTypes from 'prop-types';
import React from 'react';

// Button with menu used in Activation Codes Page
// with an option to download code generator urls for various platforms
const CodeGeneratorToolMenuButton = ({buttonString, cardMenuItems, onCardMenuItemPress}) => (
  <MenuTrigger>
    <Button variant="secondary">
      <ChevronDown size="S" />
      <View marginStart="size-125">{buttonString}</View>
    </Button>
    <Menu onAction={(key) => onCardMenuItemPress(key)}>
      {cardMenuItems.map((item) => item.toShow && <Item key={item.key}>{item.displayString}</Item>)}
    </Menu>
  </MenuTrigger>
);

CodeGeneratorToolMenuButton.propTypes = {
  /**
   * String to be displayed on the button of the card
   */
  buttonString: PropTypes.string.isRequired,
  /**
   * Array of items to populate card menu
   */
  cardMenuItems: PropTypes.arrayOf(
    PropTypes.shape({displayString: PropTypes.string.isRequired, key: PropTypes.string.isRequired})
  ).isRequired,
  /**
   * Function to be called when menu item pressed
   */
  onCardMenuItemPress: PropTypes.func.isRequired,
};

export default CodeGeneratorToolMenuButton;
