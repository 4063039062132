(function () {
  /**
   * @deprecated
   *
   * @ngdoc component
   * @name app.widgets.products:appProductChiclet
   * @param {String} [body] The translated body text of the product chiclet.
   * @param {Object} [icons] The icons object returned as part of the product or product service response.
   * @param {String} [iconTitle] The icon title
   * @param {String} [name] The product name.
   */
  angular.module('app.widgets.products.product-chiclet').component('appProductChiclet', {
    bindings: {
      body: '@?',
      icons: '<?',
      iconTitle: '@?',
      name: '@?',
    },
    templateUrl: 'app/widgets/products/product-chiclet/product-chiclet.component.html',
  });
})();
