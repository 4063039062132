import {action, makeObservable, observable} from 'mobx';

import ContractsStore from 'core/user-org-contracts/ContractsStore';

import PackagesAdobeUpdatesStore from '../features/packages/stores/PackagesAdobeUpdatesStore';
import PackagesUiConstantsStore from '../features/packages/stores/PackagesUiConstantsStore';
import HelpStore from '../shell/help/stores/help-store/HelpStore';

import OrganizationStore from './organizations/OrganizationStore';

class RootStore {
  isAngularSrcReady = false;

  constructor() {
    this.organizationStore = new OrganizationStore();
    this.contractsStore = new ContractsStore();
    this.helpStore = new HelpStore();
    this.packagesUiConstantsStore = new PackagesUiConstantsStore();
    this.packagesAdobeUpdatesStore = new PackagesAdobeUpdatesStore();

    makeObservable(this, {
      isAngularSrcReady: observable,
      setIsReady: action,
    });

    this.isAngularSrcReady = false;
  }

  setIsReady(isReady) {
    this.isAngularSrcReady = isReady;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export -- existing usage
export default new RootStore();
