(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name onesieExtendedUserProfileAccess
   * @description onesieExtendedUserProfile access factory
   */
  angular
    .module('app.core.extended-user-profile')
    .factory('onesieExtendedUserProfileAccess', OnesieExtendedUserProfileAccess);
  /* @ngInject */
  function OnesieExtendedUserProfileAccess(OrganizationManager) {
    const service = {
      canPatchUserProfile: allowIfNotMigrating,
    };

    return service;

    //////////////

    function allowIfNotMigrating() {
      return !OrganizationManager.getMigrationsForActiveOrg().isMigrating();
    }
  }
})();
