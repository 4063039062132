import {Divider} from '@adobe/react-spectrum';
import {ToggleSection} from '@pandora/react-toggle-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {ROLE} from '../../users.constants';

import {useAdminModalContext} from './AddAdminModalContext';
import {roleToAdminRoles, roleToTargetType} from './adminRoleUtils';
import ProductProfileSelectModal from './product-profile-select-modal/ProductProfileSelectModal';
import ProductSelectModal from './product-select-modal/ProductSelectModal';
import './AddAdminModal.pcss';

const AddAdminModalToggleSection = ({
  isOpen,
  role,
  defaultSelectedRole,
  onAdminRoleToggle,
  onAdminRoleTargetAssignment,
  ...props
}) => {
  const {user} = useAdminModalContext();
  const intl = useIntl();

  const renderAdminModal = () => {
    switch (role) {
      case ROLE.ADMIN.PRODUCT:
        return (
          <ProductSelectModal
            data-testid="product-select-modal"
            id={role}
            onItemAssignment={(items) => onAdminRoleTargetAssignment(items, ROLE.ADMIN.PRODUCT)}
            {...props}
          />
        );

      case ROLE.ADMIN.LICENSE:
        return (
          <ProductProfileSelectModal
            data-testid="product-profile-select-modal"
            id={role}
            onItemAssignment={(items) => onAdminRoleTargetAssignment(items, ROLE.ADMIN.LICENSE)}
            {...props}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ToggleSection
      key={role}
      data-testid="admin-role-toggle"
      defaultChecked={defaultSelectedRole}
      isOpen={isOpen}
      onToggle={onAdminRoleToggle}
      toggleDescription={intl.formatMessage({
        id: `common.adminRole.roles.${roleToAdminRoles(role)}.description`,
      })}
      toggleLabel={intl.formatMessage({
        id: `common.adminRole.roles.${roleToAdminRoles(role)}.name`,
      })}
      UNSAFE_style={{marginBottom: '1rem'}}
    >
      <Divider size="S" />
      <p data-testid="managed-count-label" styleName="managed-count-label">
        {intl.formatMessage(
          {
            id: `common.adminRole.managedCount.${roleToTargetType(role)}`,
          },
          {
            amount: user.roles.find((r) => r.type === role)?.targets?.length || 0,
          }
        )}
      </p>

      {renderAdminModal()}
    </ToggleSection>
  );
};

AddAdminModalToggleSection.propTypes = {
  defaultSelectedRole: PropTypes.bool,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onAdminRoleTargetAssignment: PropTypes.func,
  onAdminRoleToggle: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default AddAdminModalToggleSection;
