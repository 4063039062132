(function () {
  /**
   * @ngdoc module
   * @name app.core.storage-repository-list
   * @description Module which defines storage-repository-list related models
   */
  angular.module('app.core.storage-repository-list', [
    'app.core.api.jil',
    'app.core.model-cache',
    'binky.core.lodash',
  ]);
})();
