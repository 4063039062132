import {Checkbox, Flex, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

// Reusable component used in the ManagmentOptionsPage
// Has a title, checkbox, description and learn more url as part of its template
const ManagementOptionItem = observer(
  ({
    managementOptionTitle,
    managementOptionDesc,
    managementOptionExtendedDesc,
    learnMoreGoUrlAriaLabel = '',
    learnMoreGoUrlLink = '',
    isSelected,
    isDisabled,
    onSelectionChange,
  }) => {
    const intl = useIntl();

    return (
      <Flex direction="column" marginBottom="size-100">
        <Checkbox
          isDisabled={isDisabled}
          isEmphasized
          isSelected={isSelected}
          onChange={onSelectionChange}
        >
          <Text UNSAFE_style={{fontSize: 'size-130', fontWeight: 'bold'}}>
            {managementOptionTitle}
          </Text>
        </Checkbox>
        <View marginStart="size-300">
          <Text marginEnd="size-50">{managementOptionDesc}</Text>
          {managementOptionExtendedDesc && (
            <Text marginEnd="size-50">{managementOptionExtendedDesc}</Text>
          )}
          {learnMoreGoUrlLink && (
            <GoUrl aria-label={learnMoreGoUrlAriaLabel} name={learnMoreGoUrlLink}>
              {intl.formatMessage({
                id: 'packages.createPackage.managementOptions.learnMore',
              })}
            </GoUrl>
          )}
        </View>
      </Flex>
    );
  }
);

ManagementOptionItem.propTypes = {
  /**
   * Boolean value to indicate if checkbox is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Boolean value to indicate if checkbox is selected
   */
  isSelected: PropTypes.bool,
  /**
   * Descriptive aria label for the go url. Defaults to empty string.
   */
  learnMoreGoUrlAriaLabel: PropTypes.string,
  /**
   * Url link to be redirected to on clicking Learn More hyperlink. Defaults to empty string.
   */
  learnMoreGoUrlLink: PropTypes.string,
  /**
   * Description string for the component
   */
  managementOptionDesc: PropTypes.string.isRequired,
  /**
   * Optional extended Description string for the component,
   */
  managementOptionExtendedDesc: PropTypes.string,
  /**
   * Title string for the component
   */
  managementOptionTitle: PropTypes.string.isRequired,
  /**
   * Method to be called in case of checkbox toggle
   */
  onSelectionChange: PropTypes.func.isRequired,
};

export default ManagementOptionItem;
