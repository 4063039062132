import UserIcon from '@spectrum-icons/workflow/User';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AddUsersToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import rootStore from 'core/RootStore';
import {canAssignUser, isReadOnly} from 'core/organizations/access/organizationAccess';

import QuickLink from '../QuickLink';

const AddUsersQuickLink = ({isDisabled}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <QuickLink
        analyticsOptions={{
          analyticsAction: 'add-users',
        }}
        data-testid="quick-link-add-users"
        Icon={<UserIcon size="M" />}
        isDisabled={isDisabled}
        label={intl.formatMessage({id: 'overview.quickLinks.addUsers.title'})}
        onPress={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <AddUsersToOrgModal
          isOpen
          onClosed={() => setIsModalOpen(false)}
          onSuccess={() => setIsModalOpen(false)}
          orgId={rootStore.organizationStore.activeOrgId}
        />
      )}
    </>
  );
};

AddUsersQuickLink.propTypes = {
  /**
   * Whether the link is disabled. Defaults to false
   */
  isDisabled: PropTypes.bool,
};

AddUsersQuickLink.get = () => {
  if (!isReadOnly() && canAssignUser(rootStore.organizationStore.activeOrgId)) {
    return Promise.resolve(<AddUsersQuickLink key="AddUsersQuickLink" />);
  }

  return Promise.reject(new Error('User cannot assign users'));
};

export default AddUsersQuickLink;
