(function () {
  /**
   * @deprecated use src2 FreeOfferModal
   *
   * @ngdoc component
   * @name app.widgets.offers.free-offer-details-form:appFreeOfferDetailsForm
   *
   * @description The content of appFreeOfferDetailsForm
   * @param {Offer} offer The offer to display details for
   * @param {Function} onFormChange The callback function to report changes in the form.
   *        It takes in an object {event}
   *        event.areRequiredFieldsFilled the boolean value to determine the state of the modal's cta button.
   */
  angular
    .module('app.widgets.offers.free-offer-details-form')
    .component('appFreeOfferDetailsForm', {
      bindings: {
        offer: '<',
        onFormChange: '&',
      },
      controller,
      templateUrl:
        'app/widgets/offers/free-offer-details-form/free-offer-details-form.component.html',
    });

  /* @ngInject */
  function controller(
    $document,
    $log,
    $timeout,
    $translate,
    _,
    FULFILLABLE_ITEM_CODE,
    FULFILLABLE_ITEM_TYPE,
    PRODUCT_BUYING_PROGRAM,
    storageAccess
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      handleFormChange,
    });

    ///////////

    function $onInit() {
      const merchItems = _.get(vm, 'offer.merchandising.fulfillable_items', []);
      const paItems = _.get(vm, 'offer.product_arrangement.fulfillable_items', []);
      _.assign(vm, {
        fulfillableItemPairs: _(paItems)
          .filter(
            (fItem) =>
              _.get(fItem, 'ui.visible', false) &&
              (fItem.type === FULFILLABLE_ITEM_TYPE.DESKTOP ||
                fItem.type === FULFILLABLE_ITEM_TYPE.QUOTA ||
                fItem.type === FULFILLABLE_ITEM_TYPE.SERVICE)
          )
          .map((fItem) => ({
            merch: _.find(merchItems, {code: fItem.code}),
            pa: fItem,
            typeSort: getTypeSort(fItem),
          }))
          .sortBy(['typeSort', 'merch.copy.name'])
          .value(),
      });

      // VIP offers include both ESM and USM FIs, and expect all clients to have to know
      // which are valid based on org state. Once we stop selling USM, this can go away.
      if (
        _.some(paItems, {code: FULFILLABLE_ITEM_CODE.CC_STORAGE}) &&
        _.some(paItems, {code: FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE})
      ) {
        filterFIPairs();
      }

      function getTypeSort(fItem) {
        switch (fItem.type) {
          case FULFILLABLE_ITEM_TYPE.DESKTOP:
            return 1;
          case FULFILLABLE_ITEM_TYPE.QUOTA:
            return 2;
          case FULFILLABLE_ITEM_TYPE.SERVICE:
            return 3;
          default:
            return 4;
        }
      }

      const buyingProgram = _.get(vm, 'offer.buying_program');
      switch (buyingProgram) {
        case PRODUCT_BUYING_PROGRAM.VIP:
          vm.termsKey = 'widgets.offers.freeOfferDetailsForm.vipTerms';
          break;
        case PRODUCT_BUYING_PROGRAM.ETLA:
          vm.termsKey = 'widgets.offers.freeOfferDetailsForm.etlaTerms';
          break;
        default:
          $log.error(`Terms not found for buying program: ${buyingProgram}`);
      }

      initCoralCheckboxLabel();
    }

    function filterFIPairs() {
      storageAccess
        .activeOrgHasESM()
        .then((orgHasESM) => {
          vm.fulfillableItemPairs = _.reject(vm.fulfillableItemPairs, (itemPair) => {
            // we filter out either ESM Storage or CC Storage, based on which the org is allowed
            if (orgHasESM) {
              return itemPair.pa.code === FULFILLABLE_ITEM_CODE.CC_STORAGE;
            }
            return itemPair.pa.code === FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE;
          });
        })
        .catch(_.noop);
    }

    function handleFormChange() {
      vm.onFormChange({
        event: {
          areRequiredFieldsFilled: vm.adminAgreed,
        },
      });
    }

    function initCoralCheckboxLabel() {
      // Coral-checkbox is not working properly on Firefox and IE. It does not translate the checkbox's label
      // with traditional approaches. Thus, manually set a label for the checkbox here.
      $timeout(() => {
        _.invokeMap(
          $document[0].querySelectorAll(`.free-offer-details-form .accept-terms-checkbox`),
          'set',
          {
            label: {
              innerHTML: `${$translate.instant('widgets.offers.freeOfferDetailsForm.agree')}`,
            },
          }
        );
      });
    }
  }
})();
