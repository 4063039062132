import {GoUrl} from '@admin-tribe/binky-ui';
import {Checkbox, Flex, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useCreatePackageModalContext} from 'features/packages/components/create-package-modal/CreatePackageModalContext';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

import {
  isAusstOverrideAvailable,
  shouldShowExtensionOptions,
  showBetaAppDownloadOption,
} from './selfServicePageUtils';

const ManagementOptions = observer(
  ({
    rumEnabled,
    updateRumEnabled,
    ausstOverride,
    updateAusstOverride,
    betaAppDownloadEnabled,
    updateBetaAppDownloadEnabled,
    enableExtenstions,
    updateExtensionsSetting,
  }) => {
    const intl = useIntl();
    const {createPackageModalStore} = useCreatePackageModalContext();
    const {packageCreateObj, pkgSessionSettings} = createPackageModalStore;

    return (
      <View data-testid="management-options-block" marginTop="size-200">
        <Text
          UNSAFE_style={{
            fontSize: 'var(--spectrum-global-dimension-size-200)',
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({
            id: 'packages.createPackage.step1.managementOptions',
          })}
        </Text>
        <View marginTop="size-200">
          {PackagesEntitlements.hasDcOnlyEntitlement && (
            <Flex>
              <Checkbox
                data-testid="rum-option"
                isEmphasized
                isSelected={rumEnabled}
                onChange={() => updateRumEnabled(!rumEnabled)}
              >
                {intl.formatMessage({
                  id: 'packages.createPackage.step1.enableRUM',
                })}
              </Checkbox>
              <TooltipButton
                hoverText={intl.formatMessage({
                  id: 'packages.createPackage.step1.rumTooltip',
                })}
                isQuiet
                marginStart="calc(size-150*(-1))"
                variant="action"
              >
                <InfoOutlineIcon />
              </TooltipButton>
            </Flex>
          )}

          {shouldShowExtensionOptions(packageCreateObj.platform) && (
            <Flex direction="row" marginBottom="size-100">
              <Checkbox
                data-testid="enable-extenstions"
                isEmphasized
                isSelected={enableExtenstions}
                onChange={() => updateExtensionsSetting(!enableExtenstions)}
              >
                <Text UNSAFE_style={{fontWeight: 'bold'}}>
                  {intl.formatMessage({
                    id: 'packages.createPackage.step1.enableExtenstionsTitle',
                  })}
                </Text>
              </Checkbox>
              <View marginStart="calc(size-100*(-1))" marginTop="size-50">
                <GoUrl name="ent_exmanCmd_help">
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.learnMoreAboutExtensions',
                  })}
                </GoUrl>
              </View>
            </Flex>
          )}

          <Flex>
            <Checkbox
              data-testid="ausst-override"
              isDisabled={!isAusstOverrideAvailable(packageCreateObj)}
              isEmphasized
              isSelected={ausstOverride}
              onChange={() => updateAusstOverride(!ausstOverride)}
            >
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'packages.createPackage.step1.enableAusstOverride',
                })}
              </Text>
            </Checkbox>
            {isAusstOverrideAvailable(packageCreateObj) && (
              <TooltipButton
                hoverText={intl.formatMessage({
                  id: 'packages.createPackage.step1.ausstOverrideTooltip',
                })}
                isQuiet
                marginStart="calc(size-150*(-1))"
                variant="action"
              >
                <InfoOutlineIcon />
              </TooltipButton>
            )}
            {!isAusstOverrideAvailable(packageCreateObj) && (
              <TooltipButton
                data-testid="ausstOverride-unavailableTooltip"
                hoverText={intl.formatMessage({
                  id: 'packages.createPackage.step1.ausstOverrideUnavailableTooltip',
                })}
                isQuiet
                marginStart="calc(size-150*(-1))"
                variant="action"
              >
                <InfoOutlineIcon />
              </TooltipButton>
            )}
          </Flex>
        </View>

        {showBetaAppDownloadOption(pkgSessionSettings) && (
          <Flex direction="column" marginBottom="size-100">
            <Checkbox
              data-testid="betaApp-downloadEnabled"
              isEmphasized
              isSelected={betaAppDownloadEnabled}
              onChange={() => updateBetaAppDownloadEnabled(!betaAppDownloadEnabled)}
            >
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'packages.createPackage.step1.betaAppDownloadOption',
                })}
              </Text>
            </Checkbox>
            <View marginStart="size-325">
              <Text>
                {intl.formatMessage({
                  id: 'packages.createPackage.step1.betaAppDownloadDesc',
                })}
              </Text>
              {betaAppDownloadEnabled && (
                <View data-testid="betaApp-downloadEnabledDesc2">
                  <Text>
                    {intl.formatMessage({
                      id: 'packages.createPackage.step1.betaAppDownloadDesc2',
                    })}
                  </Text>
                  <GoUrl marginStart="size-50" name="ent_learnmore_beta_app_download">
                    {intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.learnMoreAboutBetaAppDownload',
                    })}
                  </GoUrl>
                </View>
              )}
            </View>
          </Flex>
        )}
      </View>
    );
  }
);

ManagementOptions.propTypes = {
  /**
   * ausstOverride property
   */
  ausstOverride: PropTypes.bool,
  /**
   * betaAppDownloadEnabled property
   */
  betaAppDownloadEnabled: PropTypes.bool,
  /**
   * enableAalExtension property
   */
  enableAalExtension: PropTypes.bool,
  /**
   * enableExtenstions property
   */
  enableExtenstions: PropTypes.bool,
  /**
   * whether rum is enabled or not
   */
  rumEnabled: PropTypes.bool,
  /**
   * Function to update rum property
   */
  updateAusstOverride: PropTypes.func.isRequired,
  /**
   * Function to update betaAppDownloadEnabled property
   */
  updateBetaAppDownloadEnabled: PropTypes.func.isRequired,
  /**
   * Function to update enableAalExtension property
   */
  updateEnableAalExtension: PropTypes.func.isRequired,
  /**
   * Function to update extensionsSetting property
   */
  updateExtensionsSetting: PropTypes.func.isRequired,
  /**
   * Function to update rum property
   */
  updateRumEnabled: PropTypes.func.isRequired,
};

export default ManagementOptions;
