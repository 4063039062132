(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationUserList
   * @description Model for a list of organization users
   */
  angular
    .module('app.core.organizations.user-list')
    .factory('OrganizationUserList', getOrganizationUserListModel);

  /* @ngInject */
  function getOrganizationUserListModel(
    $log,
    $q,
    $rootScope,
    _,
    jilUsers,
    MEMBER_EVENT,
    MEMBER_TECH_ACCOUNT_DOMAIN,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationUser
  ) {
    class OrganizationUserList extends MemberList {
      /**
       * @description Constructor for OrganizationUserList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.ORGANIZATIONUSERLIST,
              memberClassRef: OrganizationUser,
              resource: jilUsers.users,
            },
            options
          )
        );
        // search query pattern
        this.SEARCH_QUERY_PATTERN = new RegExp(/^[^*]*\*?$/); // no content allowed after an asterisk
        this.filter.excludeDomain = MEMBER_TECH_ACCOUNT_DOMAIN;
      }

      key() {
        return super.key({
          productConfigurations: true,
        });
      }

      /**
       * @description
       * Method to refresh the contents of the organization user list
       *
       * @param {Object} options - options for refresh method of super class
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh(options = {}) {
        return super
          .refresh(
            {
              productConfigurations: true,
            },
            options
          )
          .then((userList) => {
            // These events should be kept in sync with reset.
            $rootScope.$emit(MESSAGE.UPDATE.ORGANIZATIONUSERS.LIST);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(MESSAGE.UPDATE.ORGANIZATIONUSERS.COUNT, this.pagination.itemCount);
            }
            return userList;
          });
      }

      /**
       * @description Method to refresh many organization users within this list.
       *              This allows updates to users to occur without needing to
       *              refetch the entire list from the back end.
       *
       * @param {Array} selectedUsers list of users to refresh
       */
      refreshSelectedUsers(selectedUsers) {
        _.forEach(selectedUsers, (user) => {
          this.refreshUser(user.id);
        });
      }

      /**
       * @description Method to refresh an individual organization user within
       *              this list. This allows updates to a single user to occur
       *              without needing to refetch the entire list from the back
       *              end.
       *
       * @param {Number} userId the id of the user to refresh
       */
      refreshUser(userId) {
        const index = _.findIndex(this.items, doesUserIDMatch);
        // selected user may be on a different page of the list (not in users),
        // this is okay, since the user will be refreshed the next time they are
        // loaded into the current list (users)
        if (index > -1) {
          // selected user in current users list, so refresh
          this.items[index] = OrganizationUser.get({userId});
          modelCache.put(MODEL.ORGANIZATIONUSERLIST, this, this.key());
        }

        function doesUserIDMatch(user) {
          return user.id === userId;
        }
      }

      /**
       * @description Method to reset the organization user list to its collapsed state.
       *
       * @returns {Promise} promise - resolved when the list is reset
       */
      reset() {
        return super.reset().then((userList) => {
          // These events should be kept in sync with refresh.
          // Dispatch undefined rather than 0 for the count so that components which use it
          // (such as the scorecard) can provide distinct behaviour from the refresh driven change.
          $rootScope.$emit(MESSAGE.UPDATE.ORGANIZATIONUSERS.LIST);
          $rootScope.$emit(MESSAGE.UPDATE.ORGANIZATIONUSERS.COUNT, undefined);
          return userList;
        });
      }

      /**
       * @description Method to save changes to user list to back-end.
       *
       * @param {Object} options - options to alter the behavior of save
       * @param {Object} options.removePathOverride - memberId and path pairs to override the
       *                 default remove behavior
       * @param {Object} options.removeProductAndRolePathOverrides - memberId and paths pairs to
       *                 override the default remove behavior
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(options = {}) {
        // First we check if users are being added
        if (_.size(this.addedItems) > 0) {
          if (hasDuplicateUsers(this)) {
            this.addedItems = mergeDuplicateUsers(this);
          }

          const [newUsers, existingUsers] = _.partition(this.addedItems, (user) => user.isNew());
          const addUserPromise = addNewUsers(this, newUsers);
          const patchUserPromise = patchExistingUsers(this, existingUsers);
          this.$resolved = false;
          return addUserPromise
            .then(() => patchUserPromise)
            .catch((error) => {
              $log.error('Failed to add new users. Error: ', error);
              return $q.reject(error);
            })
            .finally(() => {
              this.$resolved = true;
            });
        }
        // We make a copy of the users being removed, to generate the deleted event
        const removedItems = _.clone(this.removedItems);
        const promise = super.save({}, options);
        // On save success we send out events specific to this list
        // eslint-disable-next-line promise/catch-or-return
        promise.then(() => {
          // Notify the app of each removed user
          _.forEach(removedItems, (removedUser) => {
            $rootScope.$emit(MEMBER_EVENT.DELETE, removedUser.id);
          });
        });
        return promise;
      }

      /**
       * @description Method to retrieve an existing list of organization users
       *              from the back-end.
       *
       * @param {Object} [options] options for JIL API call
       * @param {Number} [options.pageSize] - number of users to display per page
       *
       * @returns {OrganizationUserList} new OrganizationUserList Object reference
       */
      static get(options) {
        let model = new OrganizationUserList(options);
        const cachedModel = modelCache.get(MODEL.ORGANIZATIONUSERLIST, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    MemberList.setupCache(MODEL.ORGANIZATIONUSERLIST, 5);

    function addNewUsers(userList, addedUsers) {
      if (addedUsers.length > 0) {
        const userMinArray = _.invokeMap(addedUsers, 'toMinimumModel');
        return jilUsers.batchUsers
          .batchAdd({}, userMinArray)
          .$promise.then((response) => {
            const [successfulItems, failedItems] = _.partition(response, (item) =>
              _.inRange(item.responseCode, 200, 300)
            );
            if (successfulItems.length > 0) {
              for (let i = 0; i < response.length; i++) {
                const responseItem = response[i];
                if (_.includes(successfulItems, responseItem)) {
                  const user = addedUsers[i];
                  user.id = responseItem.response.id;
                  const message = MEMBER_EVENT.CREATE;
                  $rootScope.$emit(message, user.id);
                  userList.items.push(user);
                  _.remove(userList.addedItems, user);
                }
              }
            }

            if (failedItems.length > 0) {
              _.forEach(failedItems, (item) => {
                $log.error(
                  'Failed to update new organization user. Error: ',
                  item.response.errorCode
                );
              });
              return $q.reject('One or more of the users returned an error.');
            }
            return userList;
          })
          .catch((error) => {
            $log.error('Failed to create organization users. Error: ', error);
            return $q.reject(error);
          });
      }
      return $q.resolve(userList);
    }

    function hasDuplicateUsers(userList) {
      return (
        _.uniqBy(userList.addedItems, (addedItem) => [addedItem.email, addedItem.type].join())
          .length !== userList.addedItems.length
      );
    }

    function mergeDuplicateUsers(userList) {
      const newAddedItems = [];
      // rebuild a new addedItems array without duplicates
      _.forEach(userList.addedItems, (addedItem) => {
        const existingEntry = addedItem.id
          ? _.find(newAddedItems, ['id', addedItem.id])
          : _.find(newAddedItems, {email: addedItem.email, type: addedItem.type});
        if (existingEntry) {
          existingEntry.products.push(...addedItem.products);
        } else {
          newAddedItems.push(addedItem);
        }
      });

      return newAddedItems;
    }

    function patchExistingUsers(userList, existingUsers) {
      let operations = [];
      _.forEach(existingUsers, (user) => {
        operations = _.concat(operations, OrganizationUser.getPatchOperationsForUpdate(user));
      });
      operations = _.compact(operations);
      if (operations && operations.length > 0) {
        return jilUsers.users
          .batchOperation({}, operations)
          .$promise.then(() => {
            _.forEach(existingUsers, (user) => {
              const message = MEMBER_EVENT.UPDATE;
              $rootScope.$emit(message, user.id);
              userList.items.push(user);
              _.remove(userList.addedItems, user);
            });
            return userList;
          })
          .catch((error) => {
            $log.error('Failed to update organization users. Error: ', error);
            return $q.reject(error);
          });
      }
      return $q.resolve(userList);
    }

    return OrganizationUserList;
  }
})();
