import {Product} from '@admin-tribe/binky';
import {Button, ButtonGroup} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductDetailsDrawer from 'features/products/components/product-details-drawer/ProductDetailsDrawer';
import GoToPackagesButton from 'features/products/device-licenses/components/go-to-packages-button/GoToPackagesButton';

/**
 * A wrapper of Device Licenses Page Header buttons
 */
const DeviceLicensesPageActions = ({namespace, product}) => {
  const intl = useIntl();
  return (
    <ButtonGroup>
      <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
        <Button data-testid="Product-details-button" variant="secondary">
          {intl.formatMessage({id: `${namespace}.details.button`})}
        </Button>
        <ProductDetailsDrawer product={product} />
      </DrawerTrigger>
      <GoToPackagesButton />
    </ButtonGroup>
  );
};

DeviceLicensesPageActions.propTypes = {
  /**
   * The common namespace for react-intl
   */
  namespace: PropTypes.string.isRequired,

  /**
   * A Product instance
   */
  product: PropTypes.instanceOf(Product).isRequired,
};
export default DeviceLicensesPageActions;
