(function () {
  /**
   * @ngdoc module
   * @name app.core.delegations-users-list
   * @description Module that defines the DelegationsUsersList model
   */
  angular.module('app.core.delegations-users-list', [
    'app.core.api.jil',
    'app.core.message',
    'app.core.model-cache',
    'app.core.user',
    'binky.core.api.jil',
    'binky.core.common.member',
    'binky.core.feature',
    'binky.core.lodash',
  ]);
})();
