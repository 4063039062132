import {AppIntegrationList} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * @param {Boolean} hasAcceptedUsers - if true, only fetches integrations with accepted users
 * @param {Boolean} hasPolicies - if true, only fetches integrations with a policy
 * @param {String} orgId - the orgId for the app integrations to fetch
 * @returns {Object} state - Object with state variables
 *          {AppIntegrationList} state.appIntegrationList - the AppIntegrationList model
 *          {Boolean} state.isLoading - whether the data is being loaded
 *          {Object} state.error - the error is loading fails
 *          {Function} state.refreshList - the refresh function to update the appIntegrationList state.
 */
const useAppIntegrationList = ({hasAcceptedUsers, hasPolicies, orgId}) => {
  const loadAppIntegrationList = useCallback(
    () =>
      AppIntegrationList.get({
        hasAcceptedUsers,
        hasPolicies,
        orgId,
      }),
    [hasAcceptedUsers, hasPolicies, orgId]
  );

  const {
    model: appIntegrationList,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({
    loadFn: loadAppIntegrationList,
  });

  const refreshList = useCallback(async () => {
    await updateModel(async () => {
      const updatedModel = await appIntegrationList.refresh();
      return updatedModel;
    });
  }, [appIntegrationList, updateModel]);

  return {
    appIntegrationList,
    error,
    isLoading,
    refreshList,
  };
};

export default useAppIntegrationList;
