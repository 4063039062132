(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @description returns the name of an admin or Adobe support agent returned by the Support
   *   Anyware API. Uses firstName and lastName if provided, otherwise falls back to lastName only,
   *   firstName only, or fullName as required. If none of these are provided, falls back to
   *   "Admin".
   *
   * @param {Object} options as described below
   * @param {String} [options.firstName] the person's first name
   * @param {String} [options.fullName] the person's full name
   * @param {String} [options.lastName] the person's last name
   * @param {Boolean} [options.showAdobeSuffix] true if "(Adobe)" should be appended to the end of
   *   the returned string; defaults to false.
   * @param {String} [options.rengaId] the IMS user ID of the user whose name is being formatted.
   *   If it natches the logged in user's ID, then "(you)" is appended to the returned string.
   * @returns {String} the formatted name string
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportTicketPersonName', appSupportTicketPersonNameFilter);

  /* @ngInject */
  function appSupportTicketPersonNameFilter($translate, _, binkyPersonNameFilter) {
    const fallbackName = $translate.instant(
      'widgets.support.supportTicketPersonNameFilter.fallbackName'
    );
    return filterFn;

    function filterFn(options = {}) {
      const person = _(options)
        .pick(['firstName', 'fullName', 'lastName', 'showAdobeSuffix'])
        .assign({fallbackName, userId: options.rengaId})
        .omitBy(_.isUndefined)
        .value();
      return binkyPersonNameFilter(person);
    }
  }
})();
