import {LICENSE_DEV_ADMIN} from '@admin-tribe/binky';
import {action, makeObservable, runInAction} from 'mobx';

import MemberStore from 'common/stores/member-store/MemberStore';

/**
 * @description Domain data for Admin type MemberList, for example,
 *  Org admin, User group admin, product admin, profile admin.
 * @param {Object} [options] - configuration for the store
 * @param {Boolean} [options.immediateLoad] - if true, the list is immediately loaded. The default is false.
 * @param {Object} [options.memberStoreProps] - see MemberStore constructor for additional parameters.
 */
class AdminStore extends MemberStore {
  constructor({immediateLoad = false, ...memberStoreProps}) {
    super(memberStoreProps);

    makeObservable(this, {
      setRolesFilter: action,
    });

    if (immediateLoad) {
      this.load();
    }
  }

  // Remove LICENSE_DEV_ADMIN roles from selected members.
  // If the remove is successful, on exit from this method, isLoading will remain set to true so that the caller
  // can reset the tableSection and then reload the list.
  // The list load will set isLoading to false when it completes.
  async removeSelectedDevelopers({productId, profileId} = {}) {
    this.isLoading = true;

    // Remove the LICENSE_DEV_ADMIN role from either the one product for PRODUCT_CONFIGURATION, or all products if ORGANIZATION.
    this.selectedItems.forEach((member) => {
      const licenseDevAdminRole = member.roles.find((_role) => _role.type === LICENSE_DEV_ADMIN);

      licenseDevAdminRole.targets =
        productId && profileId
          ? licenseDevAdminRole.targets.filter(
              (target) => !(profileId === target.id && productId === target.parentId)
            )
          : [];
    });

    try {
      this.memberList.addModifiedUsers(this.selectedItems);
      await this.memberList.save({refresh: false});
    } catch (error) {
      this.memberList.modifiedUsers = [];
      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  setRolesFilter(roles) {
    this.listOptions = {...this.listOptions, roles};
  }
}

export default AdminStore;
