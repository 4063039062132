(function () {
  /**
   * @deprecated use binkySrc2.api.collaborationPolicyService
   *
   * @ngdoc provider
   * @name app.core.api:policyServiceProivder
   * @description
   *    Service that calls Policy Service
   *    https://wiki.corp.adobe.com/display/collaboration/Policy+Service
   */
  angular.module('app.core.api').provider('policyService', policyServiceProvider);

  function policyServiceProvider() {
    let url;
    this.$get = $get;
    this.configure = configure;

    function configure(config) {
      url = config ? config.url : '';
    }

    function $get($resource, apiUtils) {
      const service = {
        policies: getPoliciesResource(),
      };

      return service;

      ///////////////

      function getPoliciesResource() {
        return $resource(
          `${url}/organization/:orgId`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            save: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
