(function () {
  /**
   * @deprecated
   */
  angular.module('app.src2-route').config(src2RouteConfig);

  /* @ngInject */
  function src2RouteConfig($stateProvider, MAIN_TITLE_KEY) {
    const state = {
      name: 'src2-route',
    };

    $stateProvider.state(state.name, {
      component: 'appSrc2Route',
      data: {
        title: MAIN_TITLE_KEY,
      },
      params: {
        route: {
          raw: true,
          type: 'string',
        },
      },
      parent: 'org',
      url: '/:route',
    });
  }
})();
