(function () {
  /**
   * @deprecated replaced by GlobalAdminPolicyBanner
   *
   * @ngdoc service
   * @name app.core.global-admin.global-admin-helpers
   * @description Global Admin helpers
   */
  angular.module('app.core.global-admin').factory('globalAdminHelper', getGlobalAdminHelper);
  /* @ngInject */
  function getGlobalAdminHelper(
    $translate,
    _,
    binkyUISrc2,
    GLOBAL_ADMIN_POLICY,
    OrganizationManager,
    promiseUtils
  ) {
    const service = {
      getManageAdminPolicyBanner,
      getPolicyDisabledBanner,
    };

    return service;

    //////////////

    function getManageAdminPolicyBanner({removeWithKey}) {
      return getPolicyDisabledBanner({
        policyActionList: [
          {
            actions: [
              $translate.instant('core.globalAdmin.disabledActions.manageAdmins.addAdmins'),
              $translate.instant('core.globalAdmin.disabledActions.manageAdmins.removeAdmins'),
            ],
            policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
          },
        ],
        removeWithKey,
      });
    }

    function getPolicyDisabledBanner({policyActionList, removeWithKey}) {
      return (
        promiseUtils
          .toAngularPromise(
            binkyUISrc2.globalAdmin.globalAdminUtils.getPolicyDisabledActions({
              orgId: OrganizationManager.getActiveOrgId(),
              policyActionList,
            })
          )
          .then((actionList) =>
            actionList
              ? {
                  body: $translate.instant(
                    'core.globalAdmin.disabledActions.description',
                    {
                      actionNames: _.join(actionList, ', '),
                      count: _.size(actionList),
                    },
                    'messageformat'
                  ),
                  header: $translate.instant('core.globalAdmin.disabledActions.header'),
                  removeWithKey,
                }
              : undefined
          )
          // getPolicyDisabledActions doesn't throw an error, it resolves with undefined
          // when there's something wrong.
          .catch(_.noop)
      );
    }
  }
})();
