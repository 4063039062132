import {StatusLight} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './IdpCard.pcss';

const ACCOUNT_CREATION_STATUS = {
  DISABLED: {
    label: 'settings.authentication.autoAccountCreation.status.disabled',
    variant: 'neutral',
  },
  ENABLED: {
    label: 'settings.authentication.autoAccountCreation.status.enabled',
    variant: 'positive',
  },
};

const AutoAccountCreationStatus = observer(({jitStatus}) => {
  const accountCreationStatus = jitStatus
    ? ACCOUNT_CREATION_STATUS.ENABLED
    : ACCOUNT_CREATION_STATUS.DISABLED;

  return (
    <StatusLight
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- clear formatting
      UNSAFE_className={styles['account-creation-status']}
      variant={accountCreationStatus.variant}
    >
      <FormattedMessage id={accountCreationStatus.label} />
    </StatusLight>
  );
});

export default AutoAccountCreationStatus;
export {ACCOUNT_CREATION_STATUS};
