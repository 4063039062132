import {LicenseGroupList, PageContext, feature} from '@admin-tribe/binky';
import {Subpage, useStore} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import ProductUserStore from 'common/stores/user-store/ProductUserStore';
import {saveProductRole} from 'common/utils/member-utils/memberProductUtils';
import rootStore from 'core/RootStore';
import {
  canAssignUser,
  canAssignUserIgnoringLicenseCounts,
  canPurchaseLicenses,
  canRemoveUser,
} from 'core/products/access/productAccess';
import ProductUserList from 'core/services/product/ProductUserList';
import TableProductRolePicker from 'features/products/components/table-product-role-picker/TableProductRolePicker';

/**
 * The subpage for the product's users.
 * ToDo: when routing work done add LicenseGroup params
 */
const ProductUsersSubpage = observer(({pageContext}) => {
  const productId = pageContext.targetId;
  const product = rootStore.organizationStore.productList.items.find((p) => p.id === productId);

  const canAddMember = canAssignUserIgnoringLicenseCounts(product);
  const canAddMemberDisabled = !canAssignUser(product);
  const canRemoveMember = canRemoveUser();
  const [userOperations, setUserOperations] = useState();

  const productUserStore = useStore(
    () =>
      new ProductUserStore({
        listClassRef: ProductUserList,
        listOptions: {
          productId,
        },
        productId,
      })
  );

  // Team products have one license group which isn't shown in the UI so allow bulk operations
  // from this page using the default profile.
  const fetchLicenseGroupList = useCallback(() => {
    if (product.isTeam()) {
      return LicenseGroupList.get({
        orgId: productUserStore.orgId,
        product,
      });
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- exclude product since licenseGroup does not change if product updates
  }, [productUserStore.orgId]);

  const {
    error,
    isLoading,
    model: licenseGroupList,
  } = useAsyncModel({
    loadFn: fetchLicenseGroupList,
  });

  const getTeamLicenseGroupId = useCallback(
    () => licenseGroupList?.items?.[0]?.id,
    [licenseGroupList?.items]
  );

  // Once it can be determined if there is a team profile, finish initializing the store and then load the table list.
  useEffect(() => {
    if (licenseGroupList !== undefined) {
      productUserStore.initProductRoles({
        licenseGroupId: getTeamLicenseGroupId(), // will only be defined if team product
        showMemberRoles: product.isTeam() && product.hasConfigurationSettingForLicenseGroupMember(),
      });

      productUserStore.load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- load store after teamLicenseGroupId set
  }, [licenseGroupList]);

  // Once it can be determined if there is a team profile, initialize the operations.
  // This will get re-called when the product is updated due to assign/unassign users or purchase of licenses.
  useEffect(() => {
    if (licenseGroupList !== undefined) {
      const operations = UserOperations.getProductUserOperations({
        canAdd: canAddMember,
        canAddDisabled: canAddMemberDisabled,
        canUnassign: canRemoveMember,
        licenseGroupId: getTeamLicenseGroupId(), // will be undefined unless a team
        offerId: product.offerId,
        product,
      });
      setUserOperations(operations);
    }
  }, [
    canAddMember,
    canAddMemberDisabled,
    canRemoveMember,
    getTeamLicenseGroupId,
    licenseGroupList,
    product,
  ]);

  const onRoleChange = ({member, selectedId}) => {
    saveProductRole({
      pageContext,
      productUserStore,
      selectedItems: [member],
      selectedProductRole: selectedId,
    });
  };

  return (
    <Subpage isBumpered={!!error} isLoading={isLoading}>
      <MemberListContextProvider
        canAddLicense={!!product && canPurchaseLicenses(product)}
        canAddMember={canAddMember}
        canAddMemberDisabled={canAddMemberDisabled}
        canRemoveMember={canRemoveMember}
        memberListDisplay={{
          email: true,
          idType: true,
          productProfile: !product.isTeam(),
          productRole: productUserStore.showProductRole,
          provisioningStatus: feature.isEnabled('temp_user_provisioning_status') ? true : undefined,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={productUserStore}
        userOperations={userOperations}
      >
        <UserListTableSection
          rolePicker={
            productUserStore.showProductRole && (
              <TableProductRolePicker
                // member prop is added in by MemberListTable
                onRoleChange={onRoleChange}
                roles={productUserStore.roles}
              />
            )
          }
        />
      </MemberListContextProvider>
    </Subpage>
  );
});

ProductUsersSubpage.propTypes = {
  /**
   * The PageContext object which describes the page target and targetType.
   * This is required for some of the analytics callback.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
};

export default ProductUsersSubpage;
