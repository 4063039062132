(function () {
  /**
   * @deprecated moved to src2/app/common/components/search/search-help-pulldown
   *
   * @ngdoc component
   * @name app.widgets.search:appSearchResultsPulldown
   *
   * @description the search help results-pulldown
   *
   * @param {List} helpSearchList - A list of topics to display, usually provided by the search query.
   * @param {String} [pulldownId] - The ID to assign to the pulldown-content div. Default value is SEARCH_RESULTS_PULLDOWN_ID.
   * @param {Boolean} [showDefault] - Whether to show the initial default list of topics. Defaults to false.
   * @param {Promise} waitOnPagePromise - A Promise to wait for before displaying search content
   *
   * @example <app-search-results-pulldown
   *            help-search-list="$ctrl.helpSearchList"
   *            pulldown-id="{{$ctrl.pulldownId}}"
   *            show-default="$ctrl.showDefault"
   *            wait-on-page-promise="$ctrl.waitOnPagePromise">
   *          </app-search-results-pulldown>
   */

  angular.module('app.widgets.search').component('appSearchResultsPulldown', {
    bindings: {
      helpSearchList: '<',
      pulldownId: '@?',
      showDefault: '<?',
      waitOnPagePromise: '<',
    },
    controller,
    templateUrl:
      'app/widgets/search/search-results-pulldown/search-results-pulldown.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    SEARCH_RESULTS_PULLDOWN_ID,
    SEARCH_RESULTS_PULLDOWN_STATES,
    searchTopicsHelper
  ) {
    const vm = this;
    const NAMESPACE = 'widgets.search.searchResultsPulldown.';

    _.assign(vm, {
      $onChanges,
      $onInit,
      getPulldownId,
    });

    function $onChanges() {
      if (vm.showDefault) {
        if (_.isUndefined(vm.defaultTopics)) {
          vm.defaultTopics = searchTopicsHelper.getDefaultSearchTopics();
        }
        showTopics(vm.defaultTopics, 'title.browseCategories');
      } else if (vm.waitOnPagePromise) {
        vm.waitOnPagePromise.then(onSuccess).catch(onError);
      }

      function onSuccess() {
        if (_.isEmpty(vm.helpSearchList.items)) {
          showMessages('noResults');
        } else {
          showTopics(vm.helpSearchList.items, 'title.articles');
        }
      }

      function onError() {
        showMessages('errorState');
      }
    }

    function $onInit() {
      _.assign(vm, {
        flaggedClass: 'search-results-pulldown',
      });
    }

    function getPulldownId() {
      return _.defaultTo(vm.pulldownId, SEARCH_RESULTS_PULLDOWN_ID);
    }

    /* Private functions */
    function showMessages(stateName) {
      vm.state = SEARCH_RESULTS_PULLDOWN_STATES.SHOW_MESSAGES;
      vm.message = $translate.instant(`${NAMESPACE + stateName}.message`);
      vm.description = $translate.instant(`${NAMESPACE + stateName}.description`);
    }

    function showTopics(items, title) {
      vm.state = SEARCH_RESULTS_PULLDOWN_STATES.SHOW_TOPICS;
      vm.items = items;
      vm.title = $translate.instant(`${NAMESPACE}${title}`);
    }
  }
})();
