import {ActionButton, Flex, View} from '@adobe/react-spectrum';
import CloseIcon from '@spectrum-icons/workflow/Close';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import CarouselControls from './CarouselControls';

/**
 * GlobalBannerControls - Wrapper for the carousel controls and the dismiss
 * button of a global banner.
 */
const GlobalBannerControls = observer(() => {
  const intl = useIntl();

  function dismissAndShiftFocus() {
    rootStore.organizationStore.globalBannerStore.dismiss();

    const updatedGlobalBannerStore = rootStore.organizationStore.globalBannerStore;
    if (updatedGlobalBannerStore.numberOfQueuedBanners === 0) {
      const avatarNodes = document.querySelectorAll('.site-header-avatar');
      avatarNodes[0]?.focus();
    } else if (
      updatedGlobalBannerStore.numberOfQueuedBanners > 0 &&
      !updatedGlobalBannerStore.currentBanner.isDismissible
    ) {
      document.querySelector('#global-banner-message').focus();
    }
  }

  return (
    <Flex direction="row">
      {rootStore.organizationStore.globalBannerStore.numberOfQueuedBanners > 1 && (
        <CarouselControls />
      )}
      {rootStore.organizationStore.globalBannerStore.currentBanner.isDismissible && (
        <>
          <View
            borderStartColor="static-white"
            borderStartWidth="thin"
            flex="0 0 12px"
            width="size-150"
          />
          <ActionButton
            data-testid="dismiss-btn"
            height="size-400"
            isQuiet
            minWidth="size-400"
            onPress={() => dismissAndShiftFocus()}
            staticColor="white"
          >
            <CloseIcon
              aria-label={intl.formatMessage({id: 'globalBanner.controls.ariaLabel.dismiss'})}
              size="S"
            />
          </ActionButton>
        </>
      )}
    </Flex>
  );
});

export default GlobalBannerControls;
