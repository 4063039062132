import {useRef} from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_EXPERT_SESSIONS,
  PATH_SUPPORT,
  PATH_SUPPORT_CASES,
} from 'features/support/routing/supportPaths';

/** Hook which returns methods with generated Support pathnames */
const useSupportPaths = () => {
  const supportSummary = useRef(() =>
    generatePath(PATH_SUPPORT, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  ).current;

  const supportCases = useRef(() =>
    generatePath(PATH_SUPPORT_CASES, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  ).current;

  const expertSessions = useRef(() =>
    generatePath(PATH_EXPERT_SESSIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  ).current;

  return {
    expertSessions,
    supportCases,
    supportSummary,
  };
};

export default useSupportPaths;
