/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.account:recentHistorySection
 *
 * @description The Recent History section of the Account page.
 */
(function () {
  angular.module('app.widgets.account').component('appRecentHistorySection', {
    bindings: {
      directContract: '<',
      invoiceList: '<',
    },
    controller,
    templateUrl: 'app/widgets/account/recent-history-section/recent-history-section.component.html',
  });

  /* @ngInject */
  function controller(
    $location,
    _,
    APP_AUTHENTICATING_USER_MODAL,
    ACCOUNT_STATE_PARAM,
    contractAccess,
    feature,
    panelManager,
    paymentInfoEditor
  ) {
    const vm = this;
    const {AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING} = ACCOUNT_STATE_PARAM;

    _.assign(vm, {
      $onInit,
      feature,
      onClickPayNow,
      onSwitchToAuthenticatingAccount,
      showError: false,
      showInvoiceListTable: false,
      showNeedAuthMsg: false,
    });

    function $onInit() {
      vm.showNeedAuthMsg = contractAccess.needToSwitchAsAuthenticatingUser({
        contract: vm.directContract,
      });

      // Having vm.invoiceList means the user is authorized to see the invoices,
      // even if there are no invoices.
      if (vm.invoiceList) {
        vm.invoiceList.$promise
          .then(() => {
            // Be sure to always show the table even if the invoice count is 0.
            // invoice-list-table.component does handle 0 count.
            vm.showInvoiceListTable = true;

            autoOpenOneTimePaymentModal();
          })
          .catch(() => {
            vm.showError = true;
          });
      }
    }

    /**
     * Open one time payment PIE flow when auto open one time payment query string is in the URL.
     * The value for auto open one time payment query string will be used as the invoiceId to pay.
     * After one time payment is opened, the query string will be cleared, to prevent new auto open
     * on refresh. This will also remove the the history record for the query string navigation.
     */
    function autoOpenOneTimePaymentModal() {
      const {[AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING]: invoiceId} = $location.search();

      // Check if auto open query param was set and use it as the invoiceId for pay now
      if (invoiceId) {
        onClickPayNow(invoiceId);
        // Unset auto open one time payment query string so it is not opened again
        // eslint-disable-next-line lodash/prefer-lodash-method -- incorrectly suggesting _.replace
        $location.search(AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING, null).replace();
      }
    }

    function onClickPayNow(invoiceId) {
      paymentInfoEditor.loadOneTimePayment({contract: vm.directContract, invoiceId});
    }

    function onSwitchToAuthenticatingAccount() {
      panelManager.open(APP_AUTHENTICATING_USER_MODAL);
    }
  }
})();
