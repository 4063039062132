(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name SupportTicketList
   * @description Model for a support ticket list. This is intended to be used as the base class for
   *   the different kinds of support ticket lists, i.e. support case and expert session lists. This
   *   class provides functionality common to all kinds of ticket. It fetches the items from the
   *   Support Anyware ticket API, which is not paginated, hence it doesn't extend the usual List
   *   class.
   */
  angular.module('app.core.support').factory('SupportTicketList', SupportTicketListModel);

  /* @ngInject */
  function SupportTicketListModel(
    $log,
    $q,
    $rootScope,
    _,
    locale,
    modelCache,
    modelCacheUtils,
    onesieSrc2,
    OrganizationManager,
    Papa,
    SUPPORT_TICKET_CASE_TYPE
  ) {
    const miloApi = onesieSrc2.support.api.milo;
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;
    class SupportTicketList {
      /**
       * @description Creates a new SupportTicketList.
       * @class
       *
       * @param {Object} options - options object, as described below
       * @param {String} [options.adminId] - Optional. If specified, the list will be
       *   filtered to include only tickets created by this admin.
       * @param {String} options.cacheType - key to use to find the cache for this list, e.g.
       *   MODEL.EXPERTSESSIONLIST.ACTIVE
       * @param {Class} options.ItemClassRef - class to instantiate list items as on refresh, e.g.
       *   ExpertSession
       * @param {Object} options.queryParams - query params to pass when refreshing this list, as
       *   described below
       * @param {SUPPORT_TICKET_RECORD_TYPE} options.queryParams.recordType - type of ticket to fetch
       * @param {SUPPORT_TICKET_QUERY_PARAM} options.queryParams.status - which status of tickets to
       *   fetch (active or inactive, which are shown as 'Open' or 'Closed' in the UI)
       * @param {String} options.updateEvent - an event to dispatch when the list is refreshed, e.g.
       *   MESSAGE.UPDATE.EXPERTSESSIONS.LIST
       */
      constructor(options) {
        const expectedOptions = _.pick(options, [
          'adminId',
          'cacheType',
          'ItemClassRef',
          'queryParams',
          'updateEvent',
        ]);
        _.assign(this, expectedOptions);
        this.items = [];
        this.unfilteredTicketCount = 0;
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        const keyParams = _.assign({}, this.queryParams, {
          adminId: this.adminId || '',
        });
        return modelCacheUtils.getParameterizedKey(keyParams);
      }

      /**
       * @description Refreshes the contents of the list
       *
       * @returns {Promise} a promise which is resolved when the list is refreshed successfully
       */
      refresh() {
        this.$resolved = false;
        const {status, recordType} = this.queryParams;
        this.$promise = $q((resolve, reject) => {
          miloApi
            .getTicketList({
              orgId: OrganizationManager.getActiveOrgId(),
              params: {locale: locale.getActiveLocaleCode(), status},
              recordType,
            })
            .then((response) => {
              onSuccess.call(this, response.data, resolve);
            })
            .catch((error) => {
              const errorObject = error.response || error;
              onError.call(this, errorObject, false);
              reject(errorObject);
            });
        });
        return this.$promise;

        function onSuccess(response, resolve) {
          this.items = transformResponseData.call(this, response);
          this.unfilteredTicketCount = this.items.length;

          // If adminId is specified, only return items for this admin. The cache key reflects the adminId.
          if (this.adminId) {
            this.items = _.filter(this.items, ['admin.rengaId', this.adminId]);
          }
          this.$resolved = true;
          $rootScope.$emit(this.updateEvent, this);
          if (resolve) {
            resolve(this);
          }
          modelCache.put(this.cacheType, this, this.key());
          return this;
        }

        function onError(error, throwError = true) {
          $log.error(
            `SupportTicketList.refresh(): Failed to refresh data from back-end for ${this.constructor.name}. Error: `,
            error
          );
          this.$resolved = true;
          if (throwError) {
            throw error;
          }
        }

        ////////////////

        function transformResponseData(responseData) {
          return _.map(
            responseData,
            (responseItem) =>
              new this.ItemClassRef(
                _.defaults(responseItem, {
                  caseType:
                    responseItem.typeCode === 1
                      ? SUPPORT_TICKET_CASE_TYPE.ADMIN
                      : SUPPORT_TICKET_CASE_TYPE.PRODUCT,
                  id: responseItem.caseId,
                  priority: responseItem.priorityCode,
                })
              )
          );
        }
      }

      /**
       * @description Method to fetch the specified kind of support ticket list, either from the
       *  cache if available, else from the backend.
       *
       * @param {Class} ListClassRef the type of list to fetch (must be a subclass of
       *   SupportTicketList)
       * @param {Object} options - options to pass to the constructor of the specified subclass
       * @returns {Object} an instance of the specified subclass
       */
      static get(ListClassRef, options) {
        const model = new ListClassRef(options);
        const cachedModel = modelCache.get(model.cacheType, model.key());
        if (cachedModel) {
          return cachedModel;
        }
        model.refresh();
        return model;
      }

      /**
       * @description Method to fetch the csv data type of support ticket list from the backend.
       *
       * @param {String} recordType - recordType to pass to milo api of the cases and experts
       * @returns {String} fetched csv string data
       */
      static getCsv(recordType) {
        this.$promise = $q((resolve, reject) => {
          miloApi
            .getTicketList({
              options: {
                isCsv: true,
              },
              orgId: OrganizationManager.getActiveOrgId(),
              params: {locale: locale.getActiveLocaleCode()},
              recordType,
            })
            .then((response) => {
              resolve(this.transformColumns(recordType, response.data));
            })
            .catch((error) => reject(error.response));
        });
        return this.$promise;
      }

      /**
       * @description Transform the columns in csv to match UI
       *
       * @param {String} recordType string of record type to identify if support case or expert session
       * @param {String} csv string of support ticket list data
       * @returns {String} the fetched support ticket list string data
       */
      static transformColumns(recordType, csv) {
        const statusReasonExclusion = ['Pending Engineering', 'Pending Managed Services'];
        const UI_STATUS_IN_PROGRESS = 'In Progress';
        const AUTO_DETECT_DELIMITER = '';
        const modifiedCsvObjects = Papa.parse(csv, {
          delimiter: AUTO_DETECT_DELIMITER,
          header: true,
          quoteChar: '"',
        });
        // Hardcode header string when milo api didn't return csv data due to no case or session
        // To make sure user can download file with header and keep consistent with user tab
        if (!csv && recordType === SUPPORT_CONSTANTS.LIST_ENTITY_NAME.CASES) {
          return 'Status,Status Reason,Case Id,Product,Case Title,Type,Is Escalated,Priority,Date Created,Admin First Name,Admin Last Name';
        } else if (!csv) {
          return 'Status,Status Reason,Session Id,Product,Session Topic,Type,Date Requested,Date Scheduled,Admin First Name,Admin Last Name';
        }
        /* eslint-disable sort-keys -- disable sort keys to make sure the csv header align with the order on UI*/
        if (recordType === SUPPORT_CONSTANTS.LIST_ENTITY_NAME.CASES) {
          this.parsedCsv = _.map(modifiedCsvObjects.data, (element) => ({
            Status: element.statusText,
            'Status Reason': _.includes(statusReasonExclusion, element.statusReasonText)
              ? UI_STATUS_IN_PROGRESS
              : element.statusReasonText,
            'Case Id': element.caseId,
            Product: this.getProductName(element.productCode, element.productName),
            'Case Title': element.title,
            Type: element.typeCodeText,
            'Is Escalated': element.isEscalated,
            Priority: element.priorityText,
            'Date Created': element.createdOn,
            'Admin First Name': element['Admin First Name'],
            'Admin Last Name': element['Admin Last Name'],
          }));
        } else {
          this.parsedCsv = _.map(modifiedCsvObjects.data, (element) => ({
            Status: element.statusText,
            'Status Reason': _.includes(statusReasonExclusion, element.statusReasonText)
              ? UI_STATUS_IN_PROGRESS
              : element.statusReasonText,
            'Session Id': element.caseId,
            Product: this.getProductName(element.productCode, element.productName),
            'Session Topic': element.title,
            Type: element.typeCodeText,
            'Date Requested': element.createdOn,
            'Date Scheduled': element.scheduledFor,
            'Admin First Name': element['Admin First Name'],
            'Admin Last Name': element['Admin Last Name'],
          }));
        }
        /* eslint-enable sort-keys */
        return Papa.unparse(this.parsedCsv);
      }

      /**
       * @description Returns the name of the product that this ticket is about, or null if a name
       *   is not found.
       *
       *   The returned value is one of:
       *   1. The enterpriseName of any fulfillable item matching the ticket's fulfillableItemCode.
       *   2. The longName of any product matching the ticket's fulfillableItemCode. This applies
       *      to migrated cases, where the so-called fulfillableItemCode returned by the API is
       *      actually a four character product code like "PHSP".
       *   3. The name returned by the Support Anyware API. This name is not localized, hence we
       *      try to find the above names first. This helps in the case where the org no longer has
       *      access to the FI/product, which would mean the product list no longer contains them,
       *      causing 1 and 2 above to fail.
       *   4. If all else fails, null
       *
       * @param {String} code the productCode of support ticket
       * @param {String} productName the productName of support ticket
       * @returns {String} the product this ticket is about, or null
       */
      static getProductName(code, productName) {
        this.productList = OrganizationManager.getProductsForActiveOrg();
        return (
          _.get(this.productList.findFulfillableItem(code), 'enterpriseName') ||
          _.chain(this.productList.items).find({code}).get('longName').value() ||
          productName ||
          null
        );
      }
    }

    return SupportTicketList;
  }
})();
