(function () {
  /**
   * @ngdoc module
   * @name app.core.contract-compliance
   * @description Module which defines the contract compliance service
   */
  angular.module('app.core.contract.contract-compliance', [
    'app.core.auth',
    'app.core.cart',
    'app.core.organizations.organization-manager',
    'binky.core.contract',
    'binky.core.product',
    'binky.core.product.license-quantity-list',
    'binky.widgets.common.feature',
  ]);
})();
