/* eslint-disable max-lines -- over line limit */
import binky, {
  ProductList,
  feature,
  getLocalStorageItem,
  log,
  removeLocalStorageItem,
} from '@admin-tribe/binky';
import {ModalContainer, showSuccess} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import useNavigationStepsReducer, {
  ACTIONS as NAVIGATION,
} from 'common/hooks/useNavigationStepsReducer';

import MANAGE_PLAN_CONSTANTS from './ManagePlanConstants';
import {SELF_CANCEL_STEPS} from './SelfCancelConstants';
import {
  clearCaches,
  getCancellableLicensesCount,
  isPaidSelfCancelEligible,
} from './SelfCancelUtils';
import BumperStep from './bumper-step/BumperStep';
import CancelReviewStep from './cancel-review-step/CancelReviewStep';
import CancellationReasonsStep from './cancellation-reasons-step/CancellationReasonsStep';
import {ProductsChangeContextProvider} from './components/products-change-context/ProductsChangeContext';
import {SelfCancelAnalyticsContextProvider} from './components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseModal from './components/self-cancel-base-modal/SelfCancelBaseModal';
import DoneDiscountOfferStep from './done-discount-offer-step/DoneDiscountOfferStep';
import DoneFreeMonthsOfferStep from './done-free-months-offer-step/DoneFreeMonthsOfferStep';
import DoneFullCancelStep from './done-full-cancel-step/DoneFullCancelStep';
import DonePartialCancelStep from './done-partial-cancel-step/DonePartialCancelStep';
import ErrorStep from './error-step/ErrorStep';
import ExitStep from './exit-step/ExitStep';
import useSophiaCancellationReasons from './hooks/use-cancellation-reasons/useSophiaCancellationReasons';
import useProductListForCancellation from './hooks/use-product-list-for-cancellation/useProductListForCancellation';
import LossAversionStep from './loss-aversion-step/LossAversionStep';
import SaveOfferReviewStep from './save-offer-review-step/SaveOfferReviewStep';
import SaveOffersStep from './save-offers-step/SaveOffersStep';
import SelectLicensesStep from './select-licenses-step/SelectLicensesStep';

const {
  BUMPER_STEP,
  CANCEL_REVIEW_STEP,
  CANCELLATION_REASONS_STEP,
  DONE_DISCOUNT_OFFER_STEP,
  DONE_FREE_MONTHS_OFFER_STEP,
  DONE_FULL_CANCEL_STEP,
  DONE_PARTIAL_CANCEL_STEP,
  ERROR_STEP,
  EXIT_STEP,
  INITIAL_LOADING_STEP,
  LOSS_AVERSION_STEP,
  SAVE_OFFER_REVIEW_STEP,
  SAVE_OFFERS_STEP,
  SELECT_LICENSES_STEP,
} = SELF_CANCEL_STEPS;

/**
 * Self Cancel Modal dialog. It will be the Container Component that will hold top most state for
 * the flow, like selected licenses to cancel, cancellation reasons and comment, personalization
 * data, it will orchestrate the navigation between steps and perform the integration with top
 * level APIs.
 */
// eslint-disable-next-line complexity, max-statements -- due to conditionals in render method for steps
const SelfCancelModal = ({
  contract,
  isOpen = false,
  onCancel,
  onClosed,
  onConfirm,
  onOpenAddProducts,
  onOpenManageUsers,
  onStartChat,
  singleProductModeData,
}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [initialSteps, setInitialSteps] = useState([]);
  const [selectedRetentionId, setSelectedRetentionId] = useState();

  // Get single product mode data from either the props or session storage if available
  const getSingleProductModeData = useCallback(() => {
    const {offerId: singleProductOfferId, productCode: singleProductProductCode} =
      singleProductModeData ?? {};

    const {offerId: storedSingleProductOfferId, productCode: storedSingleProductProductCode} =
      JSON.parse(getLocalStorageItem(MANAGE_PLAN_CONSTANTS.PAID_CANCEL_PRODUCT_DETAILS)) ?? {};

    return {
      offerId: singleProductOfferId ?? storedSingleProductOfferId,
      productCode: singleProductProductCode ?? storedSingleProductProductCode,
    };
  }, [singleProductModeData]);

  const {offerId: singleProductOfferId, productCode: singleProductProductCode} = useMemo(() => {
    try {
      const productDetails = getSingleProductModeData();
      removeLocalStorageItem(MANAGE_PLAN_CONSTANTS.PAID_CANCEL_PRODUCT_DETAILS);
      return productDetails;
    } catch (error) {
      log.warn('Error while parsing single product mode data. Showing all products');
      return {};
    }
  }, [getSingleProductModeData]);

  const [{currentStep, steps}, dispatchNavigation] = useNavigationStepsReducer({
    currentStep: INITIAL_LOADING_STEP,
    steps: [INITIAL_LOADING_STEP],
  });

  const isBugFix33967Enabled = feature.isEnabled('bug_fix_33967');

  // Load productList with cancellation and pricing information
  const {productList, error: errorProductList} = useProductListForCancellation({
    orgId: contract.orgId,
  });

  const [filteredProductList, setFilteredProductList] = useState();
  const [initialized, setInitialized] = useState(false);

  const {sophiaReasons: cancellationReasons, isLoading: isCancellationReasonsLoading} =
    useSophiaCancellationReasons();

  // Initialize the filtered product list when cancellation product list is loaded
  useEffect(() => {
    if (initialized || !productList) return;

    // Creates a ProductList object from the given productList
    function createProductList(products) {
      const model = Object.create(ProductList.prototype);
      return Object.assign(model, products);
    }

    // Consider the product matching with the given offerId or product code in single product mode.
    // Only this matching product will be made available for self cancel.
    if (singleProductOfferId || singleProductProductCode) {
      const filteredItems = productList?.items?.filter(
        (item) => item.offerId === singleProductOfferId || item.code === singleProductProductCode
      );
      setFilteredProductList(
        createProductList({
          ...productList,
          items: filteredItems,
        })
      );
    } else {
      setFilteredProductList(productList);
    }

    setInitialized(true);
  }, [initialized, productList, singleProductOfferId, singleProductProductCode]);

  // Initialize steps when cancellableProductList is loaded
  useEffect(() => {
    if ((!isCancellationReasonsLoading && productList) || errorProductList) {
      let initialStep;

      if (errorProductList) {
        // Initialize currentStep with error as cancellableProductList is not available
        initialStep = ERROR_STEP;
      } else if (
        feature.isEnabled('temp_force_enable_self_cancel') ||
        isPaidSelfCancelEligible(contract, productList)
      ) {
        // Initialize currentStep first step of the flow
        initialStep = SELECT_LICENSES_STEP;
      } else {
        // Initialize currentStep with bumper
        initialStep = BUMPER_STEP;
      }

      dispatchNavigation({
        payload: {currentStep: initialStep, steps: [initialStep]},
        type: NAVIGATION.RESET,
      });
    }
  }, [contract, dispatchNavigation, errorProductList, isCancellationReasonsLoading, productList]);

  // Update navigation flow when initial steps are updated
  useEffect(() => {
    if (initialSteps.length > 0) {
      dispatchNavigation({
        payload: {
          currentStep: CANCELLATION_REASONS_STEP,
          steps: initialSteps,
        },
        type: NAVIGATION.RESET,
      });
    }
  }, [initialSteps, dispatchNavigation]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClosed();
  };

  const onModalCancel = () => {
    onCancel?.();
    closeModal();
  };

  const onModalBack = () => {
    dispatchNavigation({type: NAVIGATION.PREVIOUS});
  };

  const onModalClose = () => {
    dispatchNavigation({
      payload: {
        currentStep: EXIT_STEP,
      },
      type: NAVIGATION.RESET,
    });
  };

  const onCancelSuccess = (nextBillingAmounts) => {
    const nextBilling = {nextBillingDate: contract.getNextBillingDate(), ...nextBillingAmounts};
    onConfirm?.({nextBilling});
    closeModal();
    showSuccess(intl.formatMessage({id: 'account.selfCancel.toast.success'}));
  };

  const onSelectLicensesContinue = (newSelectedSeats) => {
    // Save License to cancel
    setSelectedSeats(newSelectedSeats);
    // Calculate last step of the flow based on license selection
    const availableSeats = filteredProductList.items.reduce(
      (seatCount, product) => seatCount + getCancellableLicensesCount(product),
      0
    );
    const cancellingSeats = Object.keys(newSelectedSeats).reduce(
      (seatCount, productId) => seatCount + newSelectedSeats[productId].length,
      0
    );
    if (cancellingSeats > 0) {
      const newSteps = [
        SELECT_LICENSES_STEP,
        CANCELLATION_REASONS_STEP,
        LOSS_AVERSION_STEP,
        SAVE_OFFERS_STEP,
        CANCEL_REVIEW_STEP,
        availableSeats === cancellingSeats ? DONE_FULL_CANCEL_STEP : DONE_PARTIAL_CANCEL_STEP,
      ];
      setInitialSteps(newSteps);
    }
  };

  const onCancellationReasonsContinue = () => {
    dispatchNavigation({type: NAVIGATION.NEXT});
  };

  const onLossAversionContinue = () => {
    dispatchNavigation({type: NAVIGATION.NEXT});
  };

  const onCancelReviewContinue = () => {
    clearCaches();
    dispatchNavigation({type: NAVIGATION.NEXT});
  };

  const onSaveOfferReviewContinue = () => {
    dispatchNavigation({type: NAVIGATION.NEXT});
  };

  const onSaveOfferReviewPrevious = () => {
    dispatchNavigation({
      payload: {
        currentStep: SAVE_OFFERS_STEP,
        steps: initialSteps,
      },
      type: NAVIGATION.RESET,
    });
  };

  const onSaveOfferAccept = (retentionId) => {
    setSelectedRetentionId(retentionId);
    dispatchNavigation({
      payload: {
        currentStep: DONE_FREE_MONTHS_OFFER_STEP,
      },
      type: NAVIGATION.RESET,
    });
  };

  const onSaveOffersReject = () => {
    dispatchNavigation({type: NAVIGATION.NEXT});
  };

  const onSaveOfferSuccess = (nextBilling) => {
    onConfirm?.({nextBilling});
    closeModal();
    showSuccess(intl.formatMessage({id: 'account.selfCancel.toast.saveOffer'}));
  };

  const onSaveOfferReview = (retentionId) => {
    setSelectedRetentionId(retentionId);
    dispatchNavigation({
      payload: {
        currentStep: SAVE_OFFER_REVIEW_STEP,
        replaceSteps: [SAVE_OFFER_REVIEW_STEP, DONE_DISCOUNT_OFFER_STEP],
      },
      type: NAVIGATION.REPLACE,
    });
  };

  const onAddProducts = () => {
    onOpenAddProducts?.();
    closeModal();
  };

  const onManageUsers = () => {
    onOpenManageUsers?.();
    closeModal();
  };

  const onStartChatCloseModal = (...args) => {
    onStartChat?.(...args);
    closeModal();
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <ModalContainer>
      <ProductsChangeContextProvider contractId={contract.id} orgId={contract.orgId}>
        <SelfCancelAnalyticsContextProvider
          comment={comment}
          currentStep={currentStep}
          productList={filteredProductList}
          selectedReasons={selectedReasons}
        >
          {currentStep === INITIAL_LOADING_STEP && <SelfCancelBaseModal isLoading />}
          {currentStep === SELECT_LICENSES_STEP && (
            <SelectLicensesStep
              contract={contract}
              defaultSelectedSeats={selectedSeats}
              onClose={onModalClose}
              onContinue={onSelectLicensesContinue}
              productList={filteredProductList}
            />
          )}
          {currentStep === CANCELLATION_REASONS_STEP && (
            <CancellationReasonsStep
              comment={comment}
              contract={contract}
              currentStep={currentStep}
              onClose={onModalClose}
              onCommentChange={setComment}
              onContinue={onCancellationReasonsContinue}
              onPrevious={onModalBack}
              onSelectedReasonsChange={setSelectedReasons}
              productList={filteredProductList}
              reasons={cancellationReasons}
              selectedReasons={selectedReasons}
              selectedSeats={selectedSeats}
              steps={steps}
            />
          )}
          {currentStep === LOSS_AVERSION_STEP && (
            <LossAversionStep
              contract={contract}
              currentStep={currentStep}
              onClose={onModalClose}
              onContinue={onLossAversionContinue}
              onPrevious={onModalBack}
              productList={filteredProductList}
              selectedSeats={selectedSeats}
              steps={steps}
            />
          )}
          {currentStep === SAVE_OFFERS_STEP && (
            <SaveOffersStep
              comment={comment}
              contract={contract}
              currentStep={currentStep}
              onAcceptSaveOffer={onSaveOfferAccept}
              onClose={onModalClose}
              onContinue={onSaveOffersReject}
              onPrevious={onModalBack}
              onReviewSaveOffer={onSaveOfferReview}
              onStartChat={isBugFix33967Enabled ? onStartChatCloseModal : onStartChat}
              productList={filteredProductList}
              selectedReasons={selectedReasons}
              selectedSeats={selectedSeats}
              steps={steps}
            />
          )}
          {currentStep === CANCEL_REVIEW_STEP && (
            <CancelReviewStep
              comment={comment}
              contract={contract}
              currentStep={currentStep}
              onClose={onModalClose}
              onConfirm={onCancelReviewContinue}
              onPrevious={onModalBack}
              onStartChat={isBugFix33967Enabled ? onStartChatCloseModal : onStartChat}
              productList={filteredProductList}
              selectedReasons={selectedReasons}
              selectedSeats={selectedSeats}
              steps={steps}
            />
          )}
          {currentStep === SAVE_OFFER_REVIEW_STEP && (
            <SaveOfferReviewStep
              comment={comment}
              contract={contract}
              currentStep={currentStep}
              onClose={onModalClose}
              onConfirm={onSaveOfferReviewContinue}
              onPrevious={onSaveOfferReviewPrevious}
              productList={productList}
              retentionId={selectedRetentionId}
              selectedReasons={selectedReasons}
              selectedSeats={selectedSeats}
              steps={steps}
            />
          )}
          {currentStep === DONE_FREE_MONTHS_OFFER_STEP && (
            <DoneFreeMonthsOfferStep
              contract={contract}
              onAddProducts={onAddProducts}
              onDone={onSaveOfferSuccess}
              onManageUsers={onManageUsers}
              productList={productList}
              retentionId={selectedRetentionId}
            />
          )}
          {currentStep === DONE_DISCOUNT_OFFER_STEP && (
            <DoneDiscountOfferStep onDone={onSaveOfferSuccess} retentionId={selectedRetentionId} />
          )}
          {currentStep === DONE_FULL_CANCEL_STEP && (
            <DoneFullCancelStep
              onAddProducts={onAddProducts}
              onDone={onCancelSuccess}
              onManageUsers={onManageUsers}
            />
          )}
          {currentStep === DONE_PARTIAL_CANCEL_STEP && (
            <DonePartialCancelStep
              onAddProducts={onAddProducts}
              onDone={onCancelSuccess}
              onManageUsers={onManageUsers}
            />
          )}
          {currentStep === BUMPER_STEP && (
            <BumperStep
              onPrevious={onModalCancel}
              onStartChat={isBugFix33967Enabled ? onStartChatCloseModal : onStartChat}
            />
          )}
          {currentStep === EXIT_STEP && <ExitStep onDone={onModalCancel} />}
          {currentStep === ERROR_STEP && <ErrorStep onDone={onModalCancel} />}
        </SelfCancelAnalyticsContextProvider>
      </ProductsChangeContextProvider>
    </ModalContainer>
  );
};

SelfCancelModal.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(binky.models.contract.Contract).isRequired,
  /**
   * Whether the modal dialog is shown or hidden. By default is hidden.
   */
  isOpen: PropTypes.bool,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onCancel: PropTypes.func,
  /**
   * A callback injected by global-modals.component.js
   * Required because this modal must be triggered by global-modals.
   */
  onClosed: PropTypes.func.isRequired,
  /**
   * Handler that is called when the user confirmed the cancellation of licenses.
   */
  onConfirm: PropTypes.func,
  /**
   * Handler that is called when the user confirmed a cancellation or a save offer and clicks on
   * Add Products link, from the confirmation step.
   */
  onOpenAddProducts: PropTypes.func,
  /**
   * Handler that is called when the user confirmed a cancellation or a save offer and clicks on
   * Manage Users, from the confirmation step.
   */
  onOpenManageUsers: PropTypes.func,
  /**
   * Handler that is called when the user wants to start a chat session.
   * Will also send up an object specifying which step a user is initiating a chat session from.
   * i.e. {step: 'SaveOffersStep'}
   */
  onStartChat: PropTypes.func,
  /**
   * Optional: When self cancel should be opened with only one product.
   * Even if the there are multiple cancellable products, if this data is passed,
   * only the product with matching offer id will be made available for cancellation.
   */
  singleProductModeData: PropTypes.shape({
    offerId: PropTypes.string,
    productCode: PropTypes.string,
  }),
};

export default SelfCancelModal;
export {SELF_CANCEL_STEPS};
/* eslint-enable max-lines -- over line limit */
