(function () {
  /**
   * @deprecated use src2 Overview page
   */
  angular.module('app.overview').component('appOverview2', {
    controller,
    templateUrl: 'app/overview/overview/overview2.component.html',
  });

  /* @ngInject */
  function controller(
    $compile,
    $document,
    $element,
    $scope,
    $timeout,
    $translate,
    $window,
    _,
    binkySrc2,
    feature,
    onesieSrc2,
    OrganizationList,
    storageAccess,
    SURFACE_ID,
    removeOnReadOnly
  ) {
    const vm = this;
    const LONG_PRODUCT_LIST_LENGTH = 10;
    const MIN_WIDTH_RESIZE = 992;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      ALL_COL_SPAN: 4,
      feature,
      onResizeProducts,
      OverviewPageSrc2: onesieSrc2.overview.pages.OverviewPage,
      removeOnReadOnly,
      SURFACE_ID,
    });

    const media = $window.matchMedia(`(min-width: ${MIN_WIDTH_RESIZE}px)`);

    function $onInit() {
      if (feature.isEnabled('temp_react_port_overview')) {
        return;
      }

      if (feature.isEnabled('temp_observability')) {
        const {OVERVIEW, PRODUCTS_SUMMARY_SECTION, USERS_SUMMARY_SECTION} =
          onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST;

        binkySrc2.services.observability.observabilityMetrics.registerSpans([
          OVERVIEW,
          PRODUCTS_SUMMARY_SECTION,
          USERS_SUMMARY_SECTION,
        ]);
        binkySrc2.services.observability.observabilityMetrics.startSpan(OVERVIEW);
      }

      // insert storage section if applicable
      storageAccess
        .canViewStorage()
        .then((canView) => {
          if (canView) {
            vm.storageSectionElement = angular.element(
              $document[0].createElement('app-storage-summary-section')
            )[0];

            const newScope = $scope.$new(true);
            $compile(vm.storageSectionElement)(newScope);

            $timeout(() => {
              moveStorageSection();
            });

            media.addListener(moveStorageSection);
          }
        })
        .catch(() => {
          vm.canViewStorage = false;
        })
        .finally(() => {
          if (feature.isEnabled('temp_observability')) {
            binkySrc2.services.observability.observabilityMetrics.completeSpan(
              onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST.OVERVIEW
            );
          }
        });

      const orgName = _.get(OrganizationList.get().activeOrg, 'name');
      vm.overviewHeading = orgName
        ? $translate.instant('overview.heading', {orgName})
        : $translate.instant('overview.title');
    }

    function $onDestroy() {
      if (feature.isEnabled('temp_react_port_overview')) {
        return;
      }
      media.removeListener(moveStorageSection);
    }

    function onResizeProducts(options) {
      vm.moveStorageRight = options.numProducts > LONG_PRODUCT_LIST_LENGTH;
      moveStorageSection();
    }

    // private functions
    function moveStorageSection() {
      if (media.matches && !vm.moveStorageRight) {
        $element[0].querySelector('.left-side').appendChild(vm.storageSectionElement);
        vm.storageSectionElement.classList.add('left-storage');
        vm.storageSectionElement.classList.remove('right-storage');
      } else {
        $element[0].querySelector('.right-side').appendChild(vm.storageSectionElement);
        vm.storageSectionElement.classList.add('right-storage');
        vm.storageSectionElement.classList.remove('left-storage');
      }
    }
  }
})();
