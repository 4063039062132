import PropTypes from 'prop-types';

/**
 * Self Cancel special footer component, only used in order to render the children inside the
 * special self cancel footer, by SelfCancelBaseModal component.
 */
const SelfCancelBaseFooter = ({children}) => children;

SelfCancelBaseFooter.propTypes = {
  /**
   * The contents of the footer.
   */
  children: PropTypes.node,
};

export default SelfCancelBaseFooter;
