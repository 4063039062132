/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseContactMethodForm
 *
 * @description The Contact Method form for the Create Case modal wizard.
 */
(function () {
  angular.module('app.widgets.support').component('appCaseContactMethodForm', {
    bindings: {
      onFormChange: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-contact-method-form/case-contact-method-form.component.html',
  });

  function controller(_, auth) {
    const vm = this;
    let isFormInvalid = true;

    _.assign(vm, {
      $doCheck,
      $onInit,
      onNotifyeesChange,
      onPhoneNumberChange,
      onTimezoneChange,
      onWorkingHoursChange,
    });

    // Triggered for every digest cycle of the component.
    function $doCheck() {
      checkFormValidity();
    }

    function $onInit() {
      initForm();
    }

    function onNotifyeesChange(notifyees) {
      vm.contactMethod.ccEmailList = notifyees;
    }

    function onPhoneNumberChange(phoneNumber) {
      vm.contactMethod.phone = phoneNumber;
    }

    function onTimezoneChange(timezone) {
      vm.contactMethod.timezone = timezone;
    }

    // workingHoursStart and workingHoursEnd will be undefined if working hours were set to something invalid.
    function onWorkingHoursChange(workingHoursStart, workingHoursEnd) {
      _.assign(vm.contactMethod, {
        workingHoursEnd,
        workingHoursStart,
      });
    }

    //////////////

    function areWorkingHoursInvalid() {
      return (
        _.isEmpty(vm.contactMethod.workingHoursStart) || _.isEmpty(vm.contactMethod.workingHoursEnd)
      );
    }

    // This will be used when form fields which require validation are added.
    function checkFormValidity() {
      const isInvalid = isPhoneNumberInvalid() || isTimezoneInvalid() || areWorkingHoursInvalid();

      // Report validity transitions so CTA button can be enabled accordingly.
      // While form is valid, report every change because the CTA button can be clicked and the modal must have the
      // current form values.
      if (isInvalid !== isFormInvalid || !isInvalid) {
        isFormInvalid = isInvalid;
        vm.onFormChange({event: {contactMethod: vm.contactMethod, isInvalid}});
      }
    }

    function initForm() {
      const userProfile = auth.getUserProfile();

      vm.contactMethod = _.assign({
        ccEmailList: userProfile.notifyees,
        email: userProfile.email,
        phone: userProfile.phoneNumber, // API field is 'phone' not 'phoneNumber'
      });
    }

    function isPhoneNumberInvalid() {
      return _.isEmpty(vm.contactMethod.phone);
    }

    function isTimezoneInvalid() {
      return !_.isInteger(vm.contactMethod.timezone);
    }
  }
})();
