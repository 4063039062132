const ANALYTIC_EVENT = {
  ACTION: {
    DISMISS: 'Dismiss',
    MARK_READ: 'markRead',
    MARK_UNREAD: 'markUnread',
  },
  NAME: {
    CARD: 'NotificationsCard',
    DRAWER: 'NotificationsDrawer',
    LINK: 'NotificationsCardLink',
    LIST: 'Notifications',
    MENU: 'NotificationsActionPopup',
    MENU_BUTTON: 'NotificationsHiddenIcon',
  },
};
const ANALYTICS_ID = '|AdminConsole|Notifications';
const DISMISS_CARD = 'dismissCard';
const KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
};
const NOTIFICATION_STATUS = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
};
const MARK_CARD = 'markCard';
const READ = 'read';
const STATUS_TO_MARKER_STYLE = {
  ERROR: 'status-error',
  INFO: '',
  WARN: 'status-warn',
};
const SUPPORT_TICKET_TYPE = {
  CASE: 'support_case',
  EXPERT_SESSION: 'expert_session',
};
const UNREAD = 'unread';

export {
  ANALYTIC_EVENT,
  ANALYTICS_ID,
  DISMISS_CARD,
  KEY_CODES,
  MARK_CARD,
  NOTIFICATION_STATUS,
  READ,
  STATUS_TO_MARKER_STYLE,
  SUPPORT_TICKET_TYPE,
  UNREAD,
};
