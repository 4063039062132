(function () {
  /**
   * @ngdoc module
   * @name app.widgets.account
   * @description module that defines account related widgets
   */
  angular.module('app.widgets.account', [
    'app.account.meta',
    'app.core.analytics',
    'app.core.invite',
    'app.core.organizations.organization-manager',
    'app.core.user',
    'app.widgets.account.authenticating-user',
    'app.widgets.organization-type',
    'app.widgets.organizations',
    'app.widgets.review-organization-types',
    'binky.core.contract',
    'binky.core.feature',
    'binky.core.invoice',
    'binky.core.offers',
    'binky.core.organizations',
    'binky.core.organizations.market-subsegments',
    'binky.core.price',
    'binky.widgets.common.date-time',
    'binky.widgets.common.feature',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.toast',
  ]);
})();
