class TrusteeEntity {
  constructor(trusteeData) {
    // Keep only the data needed in the UI
    this.directoryId = trusteeData.directoryId;
    this.directoryName = trusteeData.directoryName;
    this.notifyAdmins = trusteeData.notifyAdmins;
    this.trusteeOrgId = trusteeData.trusteeOrgId;
    this.trusteeOrgName = trusteeData.trusteeOrgName;
    this.trusteeOrgSysAdminEmail = trusteeData.trusteeOrgSysAdminEmail;
  }
}

export default TrusteeEntity;
