(function () {
  /**
   * @deprecated Please use src2/app/core/chat/chatProviderConstants.js
   */
  angular
    .module('app.core.chat')
    .constant('CHAT_USER_ROLE', {
      ENTERPRISE_ADMIN: 'enterpriseAdmin',
      TEAM_ADMIN: 'teamAdmin',
    })
    .constant('CHAT_APP_ID', {
      ONESIE1: 'ONESIE1',
      ONESIE1_CCT_RETENTION: 'ONESIE1_CCT_RETENTION',
      ONESIE1_CCT_SALES: 'ONESIE1_CCT_SALES',
      ONESIE1_CCT_SELF_CANCEL_BUMPER: 'ONESIE1_CCT_SELF_CANCEL_BUMPER',
      ONESIE1_CCT_SELF_CANCEL_REVIEW: 'ONESIE1_CCT_SELF_CANCEL_REVIEW',
      ONESIE1_CCT_SELF_CANCEL_SAVE_OFFERS: 'ONESIE1_CCT_SELF_CANCEL_SAVE_OFFERS',
      ONESIE1_TECH_SUPPORT: 'ONESIE1_TECH_SUPPORT',
      ONESIE1_TOO_EXPENSIVE: 'ONESIE1_TOO_EXPENSIVE',
    });
})();
