import {Contract, ProductList} from '@admin-tribe/binky';
import {ModalContent, SurveyReasons} from '@admin-tribe/binky-ui';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';

import {
  EVENT_NAME,
  mapReasonsToAnalyticsImpression,
  mapSelectedReasonsToAnalyticsImpression,
} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import {
  ACTIONS,
  useProductsChangeContext,
} from '../components/products-change-context/ProductsChangeContext';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelCart from '../components/self-cancel-cart/SelfCancelCart';
import SelfCancelFooterTotal from '../components/self-cancel-footer-total/SelfCancelFooterTotal';
import useCancellationCart from '../hooks/use-cancellation-cart/useCancellationCart';

import styles from './CancellationReasonsStep.pcss';

const Title = (title) => <span styleName="title">{title}</span>;
const Subtitle = (subtitle) => <span styleName="subtitle">{subtitle}</span>;

/**
 * Self cancel workflow step component for the cancellation reasons survey.
 * @todo The current content of this component is for initial bootstrap and will be modified when
 * implemented by a future user story: https://jira.corp.adobe.com/browse/ONESIE-23101
 */
const CancellationReasonsStep = ({
  comment,
  contract,
  currentStep,
  onClose,
  onCommentChange,
  onContinue,
  onPrevious,
  onSelectedReasonsChange,
  productList,
  reasons,
  selectedReasons = [],
  selectedSeats,
  steps,
}) => {
  const prefix = 'account.selfCancel.cancellationReasons';
  const intl = useIntl();
  const {dispatchNavigationAnalyticsEvent, dispatchSelfCancelPageAnalytics} =
    useSelfCancelAnalyticsContext();

  const {currency, items, recurrenceTerm} = useCancellationCart({
    contract,
    filterCancellingOnly: true,
    productList,
  });

  const {dispatchProductsChange} = useProductsChangeContext();

  const onCta = () => {
    dispatchProductsChange({comment, selectedReasons, selectedSeats, type: ACTIONS.SAVE_OFFERS});
    dispatchNavigationAnalyticsEvent({
      clickType: EVENT_NAME.CONTINUE,
      interaction: {click: mapSelectedReasonsToAnalyticsImpression(selectedReasons, reasons)},
    });
    onContinue?.();
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({
      clickType: EVENT_NAME.CANCEL,
      interaction: {click: mapSelectedReasonsToAnalyticsImpression(selectedReasons, reasons)},
    });
    onClose?.();
  };

  const onSecondary = () => {
    dispatchNavigationAnalyticsEvent({
      clickType: EVENT_NAME.PREVIOUS,
      interaction: {click: mapSelectedReasonsToAnalyticsImpression(selectedReasons, reasons)},
    });
    onPrevious?.();
  };

  const isPageAnalyticsSent = useRef(false);

  // Send state analytics on page load.
  useEffect(() => {
    if (!isPageAnalyticsSent.current) {
      isPageAnalyticsSent.current = true;
      dispatchSelfCancelPageAnalytics({
        interaction: {
          impression: mapReasonsToAnalyticsImpression(reasons),
        },
        step: SELF_CANCEL_STEPS.CANCELLATION_REASONS_STEP,
      });
    }
  }, [dispatchSelfCancelPageAnalytics, reasons]);

  return (
    <SelfCancelBaseModal
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.buttons.continue'})}
      currentStep={currentStep}
      heading={intl.formatMessage({id: 'account.selfCancel.common.title'})}
      isCtaDisabled={selectedReasons.length === 0}
      onCancel={onCancel}
      onCta={onCta}
      onSecondary={onSecondary}
      secondaryLabel={intl.formatMessage({id: 'account.selfCancel.buttons.previous'})}
      steps={steps}
      subheading={intl.formatMessage({id: `${prefix}.description`})}
    >
      <ModalContent>
        <Flex gap="size-300">
          <View flexGrow="1">
            <Heading id="self-cancel-cancellation-reasons-label" level={3} marginBottom="size-0">
              {intl.formatMessage(
                {
                  id: `${prefix}.reasonsLabel`,
                },
                {
                  b: Title,
                  i: Subtitle,
                }
              )}
            </Heading>
            <SurveyReasons
              aria-labelledby="self-cancel-cancellation-reasons-label"
              comment={comment}
              commentLabel={intl.formatMessage({
                id: `${prefix}.commentLabel`,
              })}
              commentWidth="size-5000"
              isEmphasized
              onCommentChange={onCommentChange}
              onSelectedReasonsChange={onSelectedReasonsChange}
              placeholder={intl.formatMessage({
                id: `${prefix}.commentPlaceholder`,
              })}
              reasons={reasons}
              selectedReasons={selectedReasons}
            />
          </View>
          <View flexShrink="0" width="340px">
            <Heading
              id="self-cancel-cancellation-reasons-label"
              level={3}
              marginBottom="size-150"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles.title}
            >
              {intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCart.title',
              })}
            </Heading>
            <SelfCancelCart currency={currency} items={items} recurrenceTerm={recurrenceTerm} />
          </View>
        </Flex>
      </ModalContent>
      <SelfCancelBaseFooter>
        <SelfCancelFooterTotal
          contract={contract}
          productList={productList}
          selectedSeats={selectedSeats}
        />
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

CancellationReasonsStep.propTypes = {
  /**
   * The elaborated comment reasoning for why cancellation is occurring.
   */
  comment: PropTypes.string,
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user modifies their cancellation reasons comment.
   */
  onCommentChange: PropTypes.func,
  /**
   * Handler that is called when the user wants to progress in the flow.
   */
  onContinue: PropTypes.func,
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * Handler that is called when the selected reasons changes.
   */
  onSelectedReasonsChange: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The array of cancellation reasons.
   */
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * The array of currently selected reason keys (controlled).
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  /**
   * List of steps in the flow
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default CancellationReasonsStep;
