import {GoUrl} from '@admin-tribe/binky-ui';
import {DialogContainer, Heading, Radio, RadioGroup, Text, View, Well} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import ConfirmationButtonGroup from 'features/settings/common/components/confirmation-button-group/ConfirmationButtonGroup';

import {SHARED_PROJECT_CREATION_POLICY_VALUES} from '../../pages/shared-projects/sharedProjectPoliciesConstants';

import SharedProjectCreationConfirmationDialog from './SharedProjectCreationConfirmationDialog';

// The "creation policy" section for the "Shared-project policies" settings.
const SharedProjectCreationPolicySection = ({onConfirm, selectedPolicy}) => {
  const intl = useIntl();
  const descriptionId = useId();
  const headingId = useId();
  const [value, setValue] = useState(selectedPolicy); // string
  const [isOpen, setIsOpen] = useState(false);
  const [pendingPolicy, setPendingPolicy] = useState(null); // string
  const isConfirmationButtonGroupDisabled = selectedPolicy === value;

  const NAMESPACE = 'settings.sharedProjects.creationPolicy';

  // get shared-project policy data to display in the page
  useEffect(() => {
    setValue(selectedPolicy);
  }, [selectedPolicy]);

  const onChange = (selected /* string[] */) => {
    setValue(selected);
    setPendingPolicy(selected);
  };

  const onSave = () => {
    setIsOpen(true);
  };

  const onDismiss = () => {
    setIsOpen(false);
    setPendingPolicy(null);
    setValue(selectedPolicy);
  };

  const onDiscard = () => {
    setValue(selectedPolicy);
  };

  const onPrimaryAction = () => {
    onConfirm(pendingPolicy);
    setIsOpen(false);
  };

  return (
    <>
      <Heading
        id={headingId}
        level={2}
        marginTop="size-0"
        UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-static-font-size-200)'}}
      >
        {intl.formatMessage({id: `${NAMESPACE}.header`})}
      </Heading>

      <View marginBottom="size-200">
        <Text>
          {intl.formatMessage(
            {id: `${NAMESPACE}.description`},
            // Pending ONESIE-37612 for the adobe_workspaces GoURL name to be updated and/or the destination to be assigned.
            {goUrl: (str) => <GoUrl name="aac_adobe_workspaces">{str}</GoUrl>}
          )}
        </Text>
      </View>

      {/* Change of context notice is required for accessibility Success Criterion 3.2.2 */}
      <View marginBottom="size-200">
        <Text id={descriptionId}>{intl.formatMessage({id: `${NAMESPACE}.contextChange`})}</Text>
      </View>

      {/* The RadioButton label should be succinct. Details belong in the description which is linked to the RadioButton. */}
      <Well>
        <RadioGroup
          aria-describedby={descriptionId}
          aria-labelledby={headingId}
          onChange={onChange}
          value={value}
        >
          <Radio
            aria-describedby="shared-projects-creation-all-description"
            value={SHARED_PROJECT_CREATION_POLICY_VALUES.ALL}
          >
            <strong>{intl.formatMessage({id: `${NAMESPACE}.option.all.label`})}</strong>
          </Radio>
          <Text id="shared-projects-creation-all-description">
            {intl.formatMessage({id: `${NAMESPACE}.option.all.description`})}
          </Text>
          <Radio
            aria-describedby="shared-projects-creation-admin-description"
            value={SHARED_PROJECT_CREATION_POLICY_VALUES.ADMIN}
          >
            <strong>{intl.formatMessage({id: `${NAMESPACE}.option.admin.label`})}</strong>
          </Radio>
          <Text id="shared-projects-creation-admin-description">
            {intl.formatMessage({id: `${NAMESPACE}.option.admin.description`})}
          </Text>
        </RadioGroup>
        <ConfirmationButtonGroup
          isDisabled={isConfirmationButtonGroupDisabled}
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Well>
      <DialogContainer onDismiss={onDismiss}>
        {isOpen && (
          <SharedProjectCreationConfirmationDialog
            onCancel={onDismiss}
            onPrimaryAction={onPrimaryAction}
            pendingPolicy={pendingPolicy}
          />
        )}
      </DialogContainer>
    </>
  );
};

SharedProjectCreationPolicySection.propTypes = {
  /**
   * Handler that is called when the user confirms a policy change. Takes in the new policy as a parameter.
   */
  onConfirm: PropTypes.func.isRequired,
  /**
   * The current shared-project creation-policy for the org.
   */
  selectedPolicy: PropTypes.oneOf(Object.values(SHARED_PROJECT_CREATION_POLICY_VALUES)).isRequired,
};

export default SharedProjectCreationPolicySection;
