(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.core.organizations.user:OrganizationUserHelper
   * @description
   */
  angular
    .module('app.core.organizations.user')
    .factory('OrganizationUserHelper', OrganizationUserHelper);

  /* @ngInject */
  function OrganizationUserHelper(
    $q,
    _,
    AvailableTypeList,
    ORG_USER_ERROR_CODE,
    OrganizationManager,
    SearchUserList
  ) {
    const ORG_USER_EDITABLE_FIELDS = [
      'countryCode',
      'email',
      'firstName',
      'id',
      'lastName',
      'type',
    ];

    const service = {
      populateValidatedNewUserOrExistingUser2,
      resetInputOrgUser,
    };

    return service;

    //////////////

    /**
     * This function calls AvailableTypeList and SearchUserList to determine
     * if it's valid to add this user to a given license group. It will mutate
     * the input user with retrieved info from the APIs.
     *
     * @param {OrganizationUser} inputOrgUser - The User Info to look up.
     * @param {Object} options - additional options
     * @param {Array} options.productInfo - an array of product information {productId, productName} used to add user
     * @param {Array} options.licenseGroupIds - an array of license group Ids to add user to
     * @returns {Promise} The promise is resolved if it's valid to add that input user
     * to a given license group. Otherwise, it returns a rejected promise.
     */
    function populateValidatedNewUserOrExistingUser2(inputOrgUser, options) {
      const deferred = $q.defer();

      // remove the existing state of the input org user
      resetInputOrgUser(inputOrgUser);
      assignEntitlements(inputOrgUser, options);

      const availableTypeList = AvailableTypeList.get({
        email: inputOrgUser.email,
        orgId: OrganizationManager.getActiveOrgId(),
      });

      availableTypeList.$promise.then(onAvailableTypeListSuccess).catch(onAvailableTypeListError);

      return deferred.promise;

      function onAvailableTypeListSuccess() {
        if (isAlreadyInOrg(availableTypeList)) {
          processExistingOrgUser();
        } else {
          processNewOrgUser();
        }
      }

      // Processes users that are already in the current org.
      // Determines if the user is already in the license group specified by the user,
      // and returns a response with the appropriate error code if this is the case.
      function processExistingOrgUser() {
        const searchUserListPromises = [];
        for (let i = 0; i < options.licenseGroupIds.length; i++) {
          const promise = SearchUserList.get(inputOrgUser.email, {
            targetLicenseGroupId: options.licenseGroupIds[i],
            targetProductId: options.selectedProducts[i].id,
          })
            .$promise.then((searchUserList) => {
              if (isAlreadyInLicenseGroup(searchUserList)) {
                return $q.reject({
                  errorCode: ORG_USER_ERROR_CODE.USER_ALREADY_EXISTS_IN_LICENSE_GROUP,
                  products: [options.selectedProducts[i]],
                });
              }
              updateInputUserBasedOnExistingUser(inputOrgUser, availableTypeList);
              return {
                products: [options.selectedProducts[i]],
              };
            })
            .catch({errorCode: ORG_USER_ERROR_CODE.SERVER_ERROR});
          searchUserListPromises.push(promise);
        }
        $q.all(searchUserListPromises).then(deferred.resolve).catch(deferred.reject);
      }

      function processNewOrgUser() {
        updateInputUserBasedOnAvailableType(inputOrgUser, availableTypeList);
        deferred.resolve([{products: _.flatMap(options.selectedProducts)}]);
      }

      function onAvailableTypeListError(error) {
        deferred.reject({
          errorCode: isInvalidEmail(error)
            ? ORG_USER_ERROR_CODE.INVALID_USER_EMAIL
            : ORG_USER_ERROR_CODE.SERVER_ERROR,
        });
      }
    }

    /* Private functions */
    function isAlreadyInOrg(availableTypeList) {
      return (
        _.get(availableTypeList, 'items') &&
        _.some(availableTypeList.items, 'existingUser') &&
        !_.some(availableTypeList.items, 'available')
      );
    }

    function isInvalidEmail(errorMessage) {
      return _.get(errorMessage, 'data.errorCode') === 'INVALID_EMAIL';
    }

    function isAlreadyInLicenseGroup(searchUserList) {
      return searchUserList.items && _.some(searchUserList.items, 'inTarget');
    }

    function updateInputUserBasedOnExistingUser(inputOrgUser, availableTypeList) {
      const availableTypeUser = _.find(availableTypeList.items, 'existingUser');
      if (availableTypeUser) {
        const existingUser = availableTypeUser.existingUser;

        _.assignIn(inputOrgUser, {
          countryCode: existingUser.countryCode,
          email: existingUser.email,
          firstName: existingUser.firstName,
          id: existingUser.id,
          lastName: existingUser.lastName,
          type: existingUser.type,
        });
      }
    }

    function updateInputUserBasedOnAvailableType(inputOrgUser, availableTypeList) {
      const availableTypeUser = _.find(availableTypeList.items, 'available');
      inputOrgUser.type = _.get(availableTypeUser, 'userType');
    }

    function resetInputOrgUser(inputOrgUser) {
      if (!inputOrgUser.isNew()) {
        const fieldsToOmit = _.without(ORG_USER_EDITABLE_FIELDS, 'email');
        _.forEach(fieldsToOmit, (fieldToOmit) => {
          delete inputOrgUser[fieldToOmit];
        });
      }
    }

    function assignEntitlements(inputOrgUser, options) {
      const products = [];

      for (let i = 0; i < options.selectedProducts.length; i++) {
        products.push({
          id: options.selectedProducts[i].id,
          licenseGroups: [
            {
              id: options.licenseGroupIds[i],
            },
          ],
        });
      }
      _.assignIn(inputOrgUser, {
        products,
      });
    }
  }
})();
