(function () {
  /**
   * @deprecated ported to src2/app/common/components/migrate-device-license
   */
  angular.module('app.widgets.contracts.migrations').component('appMigrationWizardSummaryPanel', {
    bindings: {
      totalLicensesCount: '<',
    },
    templateUrl:
      'app/widgets/contracts/migrations/team-to-enterprise-migration-wizard-panels/migration-wizard-summary-panel.component.html',
  });
})();
