import {
  DIRECTORY_ENCRYPTION_STATUS,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
} from '@admin-tribe/binky';
import {useCallback, useMemo, useState} from 'react';

import {ERROR_STATES} from 'features/settings/components/domains/transfer-domain-modal/transferDomainModalConstants';

/**
 * Hook that handles the logic for determining which domains are eligible to be transferred and which directories are eligible as destinations.
 * encryptedDirectories param can be removed when we remove temp_org_asset_encryption_migrated.
 */
const useDomainTransferState = ({directories, domains, encryptedDirectories}) => {
  // directories involved in trust relationships are not eligible as destinations for domain transfer

  // @TODO uncomment this and implement correct logic. this is a temporary fix for https://git.corp.adobe.com/admin-tribe/onesie/pull/18612
  // const trustDirectoryIds = useMemo(
  //   () => new Set(trusts.map((trust) => trust.directoryId)),
  //   [trusts]
  // );

  // directories associated with any selected domain are not eligible as destinations for domain transfer
  const selectedDomainDirectoryIds = useMemo(
    () => new Set(domains.map((domain) => domain.directoryId)),
    [domains]
  );

  // this needs to be removed when we remove the temp_org_asset_encryption_migrated feature flag
  const encryptedDirectoryAllowsTransfer = useCallback(
    (directory) => {
      // if org is migrated to org-level encryption, skip this check
      if (encryptedDirectories.length === 0) return true;

      const directoryEncryptionData = encryptedDirectories.find(
        ({domainId}) => domainId === directory.id
      );

      return (
        directoryEncryptionData === undefined ||
        (directoryEncryptionData.encryptionStatus !== DIRECTORY_ENCRYPTION_STATUS.ENABLED &&
          directoryEncryptionData.encryptionStatus !== DIRECTORY_ENCRYPTION_STATUS.REVOKED)
      );
    },
    [encryptedDirectories]
  );

  const directoryAllowsTransfer = useCallback(
    (directory) => {
      const {externallyManaged, ownershipStatus, status, type} = directory || {};
      return (
        ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
        (status === DIRECTORY_STATUS.ACTIVE || status === DIRECTORY_STATUS.NEEDS_DOMAIN) &&
        (type === DIRECTORY_TYPE.TYPE2 || (type === DIRECTORY_TYPE.TYPE3 && !externallyManaged)) &&
        // @TODO uncomment this and implement correct logic. this is a temporary fix for https://git.corp.adobe.com/admin-tribe/onesie/pull/18612
        // !trustDirectoryIds.has(id) &&
        encryptedDirectoryAllowsTransfer(directory)
      );
    },
    [encryptedDirectoryAllowsTransfer]
  );

  // in case of partial success, remove successfully transferred domains from the list of domains to transfer
  const [transferredDomains, setTransferredDomains] = useState([]);

  const [eligibleDomains, ineligibleDomains] = useMemo(() => {
    // we can't know which domains can be transferred if we haven't loaded the directories yet
    // this also prevents the UI from flickering between the two states
    if (directories.length === 0) return [[], []];

    const eligible = [];
    const ineligible = [];

    domains
      .filter((domain) => !transferredDomains.some((transferred) => transferred.id === domain.id))
      .forEach((domain) => {
        const domainDirectory = directories.find(
          (directory) => directory.id === domain.directoryId
        );

        if (directoryAllowsTransfer(domainDirectory)) {
          eligible.push(domain);
        } else {
          ineligible.push(domain);
        }
      });

    return [eligible, ineligible];
  }, [directories, domains, transferredDomains, directoryAllowsTransfer]);

  const eligibleDirectories = useMemo(
    () =>
      directories.filter(
        (directory) =>
          directoryAllowsTransfer(directory) && !selectedDomainDirectoryIds.has(directory.id)
      ),
    [directories, selectedDomainDirectoryIds, directoryAllowsTransfer]
  );

  const errorState = useMemo(() => {
    if (directories.length > 0) {
      if (eligibleDirectories.length === 0) return ERROR_STATES.NO_ELIGIBLE_DIRECTORIES;
      if (eligibleDomains.length === 0) return ERROR_STATES.NO_ELIGIBLE_DOMAINS;
    }

    return null;
  }, [directories.length, eligibleDirectories.length, eligibleDomains.length]);

  return {
    eligibleDirectories,
    eligibleDomains,
    errorState,
    ineligibleDomains,
    setTransferredDomains,
    transferredDomains,
  };
};

export default useDomainTransferState;
export {ERROR_STATES};
