(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.domain-utils').factory('domainUtils', domainUtils);

  /* @ngInject */
  function domainUtils(_) {
    const DOMAIN_REGEX =
      /^[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+(\.[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+)+$/;
    const DOMAIN_MAX_LEN = 255;
    const DOMAIN_PART_MAX_LEN = 63;

    const service = {
      isValidDomainName,
    };

    return service;

    ////////

    /**
     * @description Returns true if the specified string is a valid domain name. The function
     * checks that the domain conforms to the expected regex, and that the name and each of its
     * parts are within the allowed length range.
     *
     * @param {String} domainName - the domain name to validate
     * @returns {Boolean} true if the domain name is valid; else false
     */
    function isValidDomainName(domainName) {
      return (
        DOMAIN_REGEX.test(domainName) &&
        _.inRange(domainName.length, 1, DOMAIN_MAX_LEN + 1) &&
        _(domainName)
          .split('.')
          .every((part) => _.inRange(part.length, 1, DOMAIN_PART_MAX_LEN + 1))
      );
    }
  }
})();
