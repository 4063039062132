import {makeObservable, observable} from 'mobx';

class AuthenticationPolicyEntity {
  name;
  value;

  constructor(data) {
    makeObservable(this, {
      name: observable,
      value: observable,
    });

    this.name = data.name;
    this.value = data.value;
  }
}

export default AuthenticationPolicyEntity;
