(function () {
  const componentName = 'appProductsSummarySection';
  /**
   * @deprecated use src2 Overview page
   */
  angular.module('app.overview').component(componentName, {
    bindings: {
      onResizeProducts: '&?',
    },
    controller,
    templateUrl:
      'app/overview/overview/products-summary-section/products-summary-section.component.html',
  });
  /* @ngInject */
  function controller(
    _,
    $rootScope,
    $state,
    $translate,
    AnalyticsEvent,
    binkySrc2,
    feature,
    numberFilter,
    onesieSrc2,
    OrganizationManager,
    productGroupsProductsFilter,
    ProductGroupProductList,
    Product,
    PRODUCT_CHANNELS,
    PRODUCT_LIST_EVENT,
    productAccess,
    trialHelper
  ) {
    const MAX_PRODUCTS_BEFORE_EXPAND = 5;
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      getExpandProductsString,
      getQuantityInUseString,
      isClickable,
      onExpand,
      onGoToProduct,
      onManage,
      productAccess,
      sendClickAnalytics,
      trialHelper,
    });

    vm.productList = OrganizationManager.getProductsForActiveOrg();
    vm.onProductListUpdateDeregister = $rootScope.$on(PRODUCT_LIST_EVENT.REFRESH, () => {
      $onChanges();
    });

    function $onChanges() {
      setUpProducts();
    }

    function $onDestroy() {
      vm.onProductListUpdateDeregister();
    }

    function getExpandProductsString() {
      const action = vm.isExpanded ? 'shrink' : 'expand';
      const namespace = `${action}ProductsLabel`;
      return $translate.instant(
        `overview.productsServicesArea.${namespace}`,
        {
          totalNumProducts: numberFilter(vm.sortedAssignableProducts.length),
        },
        'messageformat'
      );
    }

    function getQuantityInUseString(item) {
      if (item instanceof Product && productAccess.canViewProductLicenseCount(item)) {
        return $translate.instant(
          'overview.productsServicesArea.licenseCounts',
          {
            licensesAssigned: numberFilter(item.provisionedQuantity),
            licensesAvailable: numberFilter(item.getAssignableLicenseCount()),
          },
          'messageformat'
        );
      }

      // This is an en-dash as per spectrum guildelines https://spectrum.corp.adobe.com/page/table/#Use-an-en-dash-(%E2%80%93)-for-missing-values
      return '–';
    }

    function isClickable(item) {
      if (item instanceof Product) {
        return item.isAdministerable();
      }
      if (item instanceof ProductGroupProductList) {
        return _.some(item.items, (productGroup) => productGroup.isAdministerable());
      }
      return false;
    }

    function onExpand($event) {
      // Prevent browser from scrolling when you use spacebar to click
      $event.preventDefault();

      const action = vm.isExpanded ? 'shrink' : 'expand';
      sendClickAnalytics(`${action}-products-list`);

      vm.isExpanded = !vm.isExpanded;
      vm.expandProductsString = getExpandProductsString();
      updateVisibleProducts();
      const options = {
        numProducts: vm.visibleProducts.length,
      };
      _.invoke(vm, 'onResizeProducts', {options});
    }

    function onGoToProduct($event, product) {
      // Prevent browser from scrolling when you use spacebar to click
      $event.preventDefault();

      if (product instanceof Product && product.isAdministerable()) {
        sendClickAnalytics(`go-to-product-${product.family}`);
        throw new Error('Angular products has been removed');
      } else if (
        product instanceof ProductGroupProductList &&
        _.some(product.items, (productGroup) => productGroup.isAdministerable())
      ) {
        sendClickAnalytics(`go-to-product-${product.items[0].family}`);
        throw new Error('Angular products has been removed');
      }
    }

    function onManage() {
      sendClickAnalytics('manage-products');
      throw new Error('Angular products has been removed');
    }

    // private functions
    function updateVisibleProducts() {
      vm.visibleProducts = vm.isExpanded
        ? vm.sortedAssignableProducts
        : vm.sortedAssignableProductsShortened;
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }

    function setUpProducts() {
      vm.promiseToWait = vm.productList.$promise.then(() => {
        const productsToShow = _.filter(vm.productList.items, shouldShowProduct);
        const productsAndProductGroups = productGroupsProductsFilter(productsToShow);
        vm.isEmptyState = productsAndProductGroups.length <= 0;
        vm.sortedAssignableProducts = _.orderBy(productsAndProductGroups, ['longName']);
        vm.sortedAssignableProductsShortened = _.slice(
          vm.sortedAssignableProducts,
          0,
          MAX_PRODUCTS_BEFORE_EXPAND
        );
        vm.visibleProducts = vm.sortedAssignableProductsShortened;
        vm.isExpanded = false;
        vm.expandProductsString = getExpandProductsString();
        vm.isSmb = vm.sortedAssignableProducts.length <= MAX_PRODUCTS_BEFORE_EXPAND;
        vm.forceHideManageLink =
          vm.isEmptyState || _.every(productsAndProductGroups, (product) => !isClickable(product));
        vm.hasLicenseCountProduct = _.some(
          productsAndProductGroups,
          (item) => item instanceof Product && productAccess.canViewProductLicenseCount(item)
        );

        if (feature.isEnabled('temp_observability')) {
          binkySrc2.services.observability.observabilityMetrics.completeSpan(
            onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST.PRODUCTS_SUMMARY_SECTION
          );
        }
      });
    }

    // product.customerUI is an Array of PRODUCT_CHANNELS.
    function shouldShowProduct(product) {
      return _.some(product.customerUI, (ui) => _.includes(PRODUCT_CHANNELS, ui));
    }
  }
})();
