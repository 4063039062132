import {LicenseGroupList} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';
import {PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS} from 'features/products/routing/productsPaths';

import help from '../../../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 * @description Method to return configuration object for the manage SDL
 *   help topic
 * @returns content for manage SDL help topic
 */
const sdlSetUp = async () => {
  const sharedDeviceLicenseProduct = rootStore.organizationStore.productList.items.find((product) =>
    product.fulfillableItemList.hasLaboratoryLicenseManagement()
  );

  if (sharedDeviceLicenseProduct) {
    const licenseGroupList = await LicenseGroupList.get({
      orgId: rootStore.organizationStore.activeOrgId,
      product: sharedDeviceLicenseProduct,
    });

    if (licenseGroupList?.items?.length > 0) {
      const firstSDLProductProfile = licenseGroupList.items[0];
      return {
        content: [
          {
            links: [
              {
                key: 'help.topics.sdlSetUp.reviewProfilePermissions.link.one.key',
                locator: PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
                migrated: true,
                params: {
                  productId: sharedDeviceLicenseProduct.id,
                  profileId: firstSDLProductProfile.id,
                },
                target: LINK_TARGET_TYPE.APPLICATION,
                type: LINK_TYPE.BUTTON,
              },
            ],
            text: 'help.topics.sdlSetUp.reviewProfilePermissions.text',
            title: 'help.topics.sdlSetUp.reviewProfilePermissions.title',
            type: HELP_ITEM_TYPE.STEP,
          },
          {
            links: [
              {
                key: 'help.topics.sdlSetUp.createSharedDevicePackage.link.one.key',
                locator: PATH_ALL_PACKAGES,
                migrated: true,
                target: LINK_TARGET_TYPE.APPLICATION,
                type: LINK_TYPE.BUTTON,
              },
            ],
            text: 'help.topics.sdlSetUp.createSharedDevicePackage.text',
            title: 'help.topics.sdlSetUp.createSharedDevicePackage.title',
            type: HELP_ITEM_TYPE.STEP,
          },
          {
            links: [
              {
                key: 'help.topics.sdlSetUp.deployPackage.link.one.key',
                locator: 'ent_learnmore_packagetype',
                target: LINK_TARGET_TYPE.GO_URL,
                type: LINK_TYPE.BUTTON,
              },
            ],
            text: 'help.topics.sdlSetUp.deployPackage.text',
            title: 'help.topics.sdlSetUp.deployPackage.title',
            type: HELP_ITEM_TYPE.STEP,
          },
          {
            links: [
              {
                key: 'help.topics.sdlSetUp.setUpEnterpriseIdentity.link.one.key',
                locator: 'aac_sdl_deploy_guide',
                target: LINK_TARGET_TYPE.GO_URL,
                type: LINK_TYPE.TEXT,
              },
              {
                key: 'help.topics.sdlSetUp.setUpEnterpriseIdentity.link.two.key',
                locator: 'settings.identity',
                migrated: false,
                target: LINK_TARGET_TYPE.APPLICATION,
                type: LINK_TYPE.BUTTON,
              },
            ],
            text: 'help.topics.sdlSetUp.setUpEnterpriseIdentity.text',
            title: 'help.topics.sdlSetUp.setUpEnterpriseIdentity.title',
            type: HELP_ITEM_TYPE.STEP,
          },
        ],
        id: TOPICS.SDL_SET_UP,
        order: 0,
        ordered: true,
        parents: [
          {
            id: TOPICS.MANAGE_SDL,
          },
        ],
        title: 'help.topics.sdlSetUp.title',
      };
    }
  }

  return {};
};

export default sdlSetUp;
