import {configStore, feature} from '@admin-tribe/binky';
import {ActionButton, Flex, Provider, darkTheme} from '@adobe/react-spectrum';
import GlobeGridIcon from '@spectrum-icons/workflow/GlobeGrid';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import GlobalAdminRoles from 'core/services/GlobalAdminRoles';
import OrganizationSwitcherContainer from 'shell/components/organization-switcher-container/OrganizationSwitcherContainer';
import UserPullDown from 'shell/components/user-profile-pulldown/UserPullDown';
import help from 'shell/help/constants/help';
import HelpRailButton from 'shell/help/rail/help-rail-button/HelpRailButton';
import NotificationsContainer from 'shell/notifications/NotificationsContainer';
import NotificationsContainer2 from 'shell/notifications/NotificationsContainer2';

const {ON_CLOSE_FOCUS_ELEMENT_ID} = help;

/**
 * Right Section of the global header component
 */
const SiteHeaderRight = () => {
  const activeOrgId = rootStore.organizationStore.activeOrgId;
  const [showGlobalConsoleIcon, setShowGlobalConsoleIcon] = useState(false);
  const intl = useIntl();

  // Shows or hides the Global Console Icon in Header Section
  useEffect(() => {
    const checkCompartmentRoles = async () => {
      const canSeeGlobalAdminRoles = await GlobalAdminRoles.hasCompartmentRole(activeOrgId);
      setShowGlobalConsoleIcon(canSeeGlobalAdminRoles);
    };
    if (activeOrgId) {
      checkCompartmentRoles();
    }
  }, [activeOrgId]);

  const openGlobalConsole = useCallback(() => {
    const url = configStore.get('services.globalAdminConsole.url');
    if (url && activeOrgId) {
      const deepLinkUrl = `${url}/${activeOrgId}/organizations`;
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window.open(deepLinkUrl, '_blank');
    }
  }, [activeOrgId]);

  return (
    <Flex alignItems="center" gap="size-100">
      <OrganizationSwitcherContainer />
      {showGlobalConsoleIcon && (
        <ActionButton
          aria-label={intl.formatMessage({id: 'shell.globalConsole.label'})}
          data-testid="global-console-icon"
          isQuiet
          onPress={openGlobalConsole}
        >
          <GlobeGridIcon size="S" />
        </ActionButton>
      )}
      <Provider colorScheme="dark" theme={darkTheme} UNSAFE_style={{backgroundColor: 'inherit'}}>
        <HelpRailButton id={ON_CLOSE_FOCUS_ELEMENT_ID} />
      </Provider>
      {/* @deprecated Remove NotificationsContainer with temp_change_status_api */}
      {feature.isEnabled('temp_change_status_api') ? (
        <NotificationsContainer2 />
      ) : (
        <NotificationsContainer />
      )}
      <UserPullDown />
    </Flex>
  );
};

export default SiteHeaderRight;
