import React from 'react';

import SophiaModal from 'common/components/sophia/sophia-modal/SophiaModal';
import useSophiaModal from 'common/components/sophia/sophia-modal/useSophiaModal';
import chatProvider from 'core/services/chat/chatProvider';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import useAddProductsModalFromDeeplink from 'features/products/hooks/useAddProductsModalFromDeeplink';

/**
 * Handle showing of modals on Overview page
 */
const ModalManager = () => {
  const {
    closeModal: closeSophiaModal,
    isOpen: isSophiaModalEligible,
    sophiaModalContent,
    trigger: triggerSophia,
  } = useSophiaModal();
  const {
    isModalOpen: isAddProductsModalEligible,
    props: addProductsModalProps,
    closeModal: closeAddProductsModal,
  } = useAddProductsModalFromDeeplink('overview.add-products');

  if (isAddProductsModalEligible) {
    return (
      <AddProductModalWrapper
        chat={chatProvider}
        onClose={closeAddProductsModal}
        {...addProductsModalProps}
      />
    );
  }

  triggerSophia();
  if (isSophiaModalEligible) {
    return <SophiaModal closeModal={closeSophiaModal} content={sophiaModalContent} />;
  }
  return null;
};

export default ModalManager;
