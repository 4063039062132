import {Offer, OfferList, QUALIFIER_TARGET} from '@admin-tribe/binky';
import {
  ModalContainer,
  ModalDialog,
  WIZARD_DISPATCH_ACTION,
  WizardTrigger,
  WizardView,
} from '@admin-tribe/binky-ui';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {ORGANIZATION_TYPE_BANNER_ID} from 'core/organizations/organization-settings/organizationSettingsConstants';

import {updateOrgMarketSubsegments} from '../organization/OrganizationTypeHelper';

import FreeOfferCart from './FreeOfferCart';
import FreeOfferDetailsPage from './FreeOfferDetailsPage';
import FreeOfferModalContext from './FreeOfferModalContext';
import NoOffersDetailsPage from './NoOffersDetailsPage';
import OrganizationTypePage from './OrganizationTypePage';

const FreeOfferModal = observer(({isOpen, onClosed, offerList, preferredOffer}) => {
  const intl = useIntl();
  const isCurrentRef = React.useRef(true);

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const cancelText = intl.formatMessage({id: 'common.modal.buttons.cancel'});
  const closeText = intl.formatMessage({id: 'common.modal.buttons.close'});
  const confirmText = intl.formatMessage({id: 'common.modal.buttons.confirm'});

  const [confirmButtonText, setConfirmButtonText] = useState(confirmText);
  const [isModalOpen, setModalOpen] = React.useState(isOpen);
  const [offersToAdd, setOffersToAdd] = useState();
  const [subsegments, setSubsegments] = useState();
  const [cancelButtonText, setCancelButtonText] = useState(cancelText);
  const [requiresOrgTypeStep, setRequiresOrgTypeStep] = useState(
    isEmpty(rootStore.organizationStore.activeOrg.getMarketSubsegments())
  );

  let steps = subsegments || ['temporary step holder'];

  if (requiresOrgTypeStep) {
    steps = union(['organization step'], steps);
  }

  const processOffers = (currentOfferList) => {
    let offers;

    const offerListItems = currentOfferList?.items;
    const qualifiedPreferredOffer = offerListItems?.find(
      (item) => item?.offer_id === preferredOffer?.offer_id
    );

    if (qualifiedPreferredOffer) {
      offers = union([qualifiedPreferredOffer], currentOfferList?.items);
    } else {
      offers = union([], currentOfferList?.items);
    }

    setOffersToAdd(offers);

    setRequiresOrgTypeStep(
      (prevValue) =>
        prevValue &&
        offers.some((offer) =>
          offer.hasQualifier([QUALIFIER_TARGET.FURTHER_EDU_ORG, QUALIFIER_TARGET.K12_EDU_ORG])
        )
    );
  };

  // process offers on first render
  useEffect(() => {
    processOffers(offerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- first render
  }, []);

  const onError = (error, setIsLoading, setModalError) => {
    if (isCurrentRef.current) {
      setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      setIsLoading(false);
    }
  };

  const onCtaAcceptOfferPage = async ({
    currentStep,
    dispatch,
    marketSubsegments,
    setIsLoading,
    setModalError,
  }) => {
    // accept offer pages
    let offerIdx = currentStep;
    if (requiresOrgTypeStep) {
      offerIdx -= 1;
    }

    try {
      await FreeOfferCart.provisionFreeOffer(offersToAdd[offerIdx]);
      if (isCurrentRef.current) {
        dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
        if (currentStep < marketSubsegments?.length) {
          setIsLoading(false);
        } else {
          setModalOpen(false);
          onClosed();
        }
      }
      return true;
    } catch (error) {
      onError(error, setIsLoading, setModalError);
    }
    return false;
  };

  const onCtaOrgTypePage = async ({
    marketSubsegments,
    hasAdditionalTermsToAccept,
    currentOfferList,
    dispatch,
    setIsLoading,
    setModalError,
  }) => {
    try {
      await updateOrgMarketSubsegments(marketSubsegments, {hasAdditionalTermsToAccept});
      await currentOfferList.refresh();
      if (isCurrentRef.current) {
        processOffers(currentOfferList);
        if (currentOfferList?.items?.length === 0) {
          setConfirmButtonText(closeText);
          setCancelButtonText(null);
        }
        rootStore.organizationStore.globalBannerStore.dismissBannerWithId(
          ORGANIZATION_TYPE_BANNER_ID
        );
        dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
        setIsLoading(false);
      }
      return true;
    } catch (error) {
      onError(error, setIsLoading, setModalError);
    }
    return false;
  };

  const onCta = async ({
    currentStep,
    dispatch,
    setIsLoading,
    setIsValid,
    marketSubsegments,
    hasAdditionalTermsToAccept,
    currentOfferList,
    setModalError,
  }) => {
    let isSuccess = false;
    setIsValid(false);
    setIsLoading(true);
    setSubsegments(marketSubsegments);
    // organization type page
    if (currentStep === 0 && requiresOrgTypeStep) {
      isSuccess = await onCtaOrgTypePage({
        currentOfferList,
        dispatch,
        hasAdditionalTermsToAccept,
        marketSubsegments,
        setIsLoading,
        setModalError,
      });
    } else if (offersToAdd?.length === 0) {
      setModalOpen(false);
      onClosed();
    } else {
      isSuccess = await onCtaAcceptOfferPage({
        currentStep,
        dispatch,
        marketSubsegments,
        setIsLoading,
        setModalError,
      });
    }

    return isSuccess;
  };

  const createModal = useCallback(
    (children) => (
      <ModalContainer>
        {isModalOpen && (
          <FreeOfferModalContext offerList={offerList}>
            {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- zaratan@ to fix */}
            {({
              isValid,
              isLoading,
              setIsLoading,
              setIsValid,
              marketSubsegments,
              hasAdditionalTermsToAccept,
              currentOfferList,
              modalError,
              setModalError,
            }) => (
              <WizardTrigger steps={steps}>
                {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- zaratan@ to fix */}
                {({dispatch, currentStep}) => (
                  <ModalDialog
                    cancelLabel={cancelButtonText}
                    ctaLabel={steps.length === 1 ? confirmText : confirmButtonText}
                    ctaVariant="cta"
                    data-testid={
                      preferredOffer?.offer_id
                        ? `free-offer-modal-${preferredOffer.offer_id}`
                        : 'free-offer-modal'
                    }
                    errorMessage={modalError}
                    heightVariant="static"
                    hideCancelButton={currentStep > 0 && offersToAdd?.length === 0}
                    id="free-offer-modal"
                    isCtaDisabled={!isValid}
                    isLoading={isLoading}
                    onCancel={onClosed}
                    // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- onCta params
                    onCta={() =>
                      onCta({
                        currentOfferList,
                        currentStep,
                        dispatch,
                        hasAdditionalTermsToAccept,
                        marketSubsegments,
                        setIsLoading,
                        setIsValid,
                        setModalError,
                      })
                    }
                  >
                    {children}
                  </ModalDialog>
                )}
              </WizardTrigger>
            )}
          </FreeOfferModalContext>
        )}
      </ModalContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- update only for these
    [cancelButtonText, confirmButtonText, confirmText, offerList, onClosed, steps, isModalOpen]
  );

  return createModal(
    <WizardView>
      {requiresOrgTypeStep && <OrganizationTypePage />}
      {offersToAdd?.[0] && (
        <FreeOfferDetailsPage key={offersToAdd[0].offer_id} offer={offersToAdd[0]} />
      )}
      {offersToAdd?.[1] && (
        <FreeOfferDetailsPage key={offersToAdd[1].offer_id} offer={offersToAdd[1]} />
      )}
      {/* show NoOffers page when there are no offers or need dummy page when loading */}
      {(offersToAdd?.length === 0 || (!requiresOrgTypeStep && isEmpty(offersToAdd))) && (
        <NoOffersDetailsPage />
      )}
    </WizardView>
  );
});

FreeOfferModal.propTypes = {
  isOpen: PropTypes.bool,
  offerList: PropTypes.oneOfType([PropTypes.instanceOf(OfferList), PropTypes.object]),
  onClosed: PropTypes.func,
  preferredOffer: PropTypes.oneOfType([PropTypes.instanceOf(Offer), PropTypes.object]),
};

export default FreeOfferModal;
