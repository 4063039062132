(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc constant
   * @name JIL_CONTENT_LOGS
   * @description defines constants used in making api calls to JIL content
   *  logs API
   */
  angular.module('app.core.api.jil').constant('JIL_CONTENT_LOGS', {
    SORT: {
      LOG_REPORT_CREATED: 'LOG_REPORT_CREATED',
      LOG_REPORT_CREATOR: 'LOG_REPORT_CREATOR',
      LOG_REPORT_START_DATE: 'LOG_REPORT_START_DATE',
    },
  });
})();
