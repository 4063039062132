(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appAddIdpModal', {
    bindings: {
      authSource: '<',
      onIdpAdded: '&?',
    },
    controller,
    templateUrl: 'app/widgets/directories/add-idp-modal/add-idp-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $translate,
    $timeout,
    $q,
    _,
    APP_ADD_IDP_MODAL_ID,
    APP_ADD_EDU_PROVIDER_MODAL_ID,
    APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID,
    AUTH_SOURCE_ERROR_CODES,
    feature,
    IDP_CHOOSER_LOGOS,
    IDP_TYPES,
    OrganizationList,
    ORGANIZATION_MARKET_SEGMENT,
    panelManager
  ) {
    const vm = this;
    const orgId = OrganizationList.get().activeOrg.id;

    // Stores opacity transition duration for `._coral-Dialog` (delay + transition)
    const MODAL_TRANSITION_DURATION = 410;
    const GENERIC_ERROR_CODE = 'GENERIC';
    const GOOGLE_IDP_ID = 'GOOGLE';
    const EDU_IDP_ID = 'EDU';

    const idpList = [
      {
        description: $translate.instant(
          'widgets.directories.addIdpModal.idpChooser.idps.azure.description'
        ),
        hideWhenFeatureFlag: 'temp_hide_azure_idp',
        id: IDP_TYPES.AZURE,
        logo: IDP_CHOOSER_LOGOS.AZURE,
        name: $translate.instant('widgets.directories.addIdpModal.idpChooser.idps.azure.name'),
      },
      {
        description: $translate.instant(
          'widgets.directories.addIdpModal.idpChooser.idps.google.description2'
        ),
        id: GOOGLE_IDP_ID,
        logo: IDP_CHOOSER_LOGOS.GOOGLE,
        name: $translate.instant('widgets.directories.addIdpModal.idpChooser.idps.google.name'),
      },
      {
        description: $translate.instant(
          'widgets.directories.addIdpModal.idpChooser.idps.saml.description'
        ),
        id: IDP_TYPES.SAML,
        logo: IDP_CHOOSER_LOGOS.SAML,
        name: $translate.instant('widgets.directories.addIdpModal.idpChooser.idps.saml.name'),
      },
      {
        description: $translate.instant(
          'widgets.directories.addIdpModal.idpChooser.idps.edu.description'
        ),
        id: EDU_IDP_ID,
        isVisible: () => {
          const marketSegment = OrganizationList.get().activeOrg.marketSegment;

          // EDU recommended we don't check for EDUCATION marketSegment since
          // there might be orgs that don't have it defined so we exclude
          // commercial and gov ones.
          return (
            feature.isDisabled('temp_disable_edu_adding') &&
            ((marketSegment !== ORGANIZATION_MARKET_SEGMENT.GOVERNMENT &&
              marketSegment !== ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) ||
              (feature.isEnabled('force_edu_idps') && !!vm.authSource.defaultIdp))
          );
        },
        logo: IDP_CHOOSER_LOGOS.EDU,
        name: $translate.instant('widgets.directories.addIdpModal.idpChooser.idps.edu.name'),
      },
    ];

    // Map of errors shown with a page-banner component if anything fails
    const ERRORS = {
      [AUTH_SOURCE_ERROR_CODES.IDP_ALREADY_EXISTS]: {
        description: 'widgets.directories.addIdpModal.errors.idpAlreadyExists.description',
        title: 'widgets.directories.addIdpModal.errors.idpAlreadyExists.title',
      },
      [GENERIC_ERROR_CODE]: {
        description: 'widgets.directories.addIdpModal.errors.generic.description',
        title: 'widgets.directories.addIdpModal.errors.generic.title',
      },
    };

    _.assign(vm, {$onInit, onClickCancel, onClickNext, onIdpSelected, onOpen});

    function $onInit() {
      _.assign(vm, {
        error: null,
        idp: {},
        idpList,
        isProcessing: false,
        modalId: APP_ADD_IDP_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    /**
     * Handler for the selected event of the idp-chooser component.
     *
     * @param {Idp} idp An Idp object containing the selected id.
     */
    function onIdpSelected(idp) {
      vm.idp = idp;
    }

    /**
     * Handler of the onOpen event of the modal
     */
    function onOpen() {
      resetModal();
    }

    /**
     * Handler for the cancel button
     */
    function onClickCancel() {
      panelManager.close(APP_ADD_IDP_MODAL_ID);
      resetModal();
    }

    /**
     * Handler for the next button
     */
    function onClickNext() {
      vm.isProcessing = true;

      if (vm.idp.id === IDP_TYPES.SAML) {
        vm.waitPromise = createSamlIdp()
          .then(() => {
            vm.error = null;
            panelManager.open(APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID, {
              authSource: vm.authSource,
            });

            // A new IdP was added so let whoever is listening know about it
            vm.onIdpAdded();

            $timeout(() => panelManager.close(APP_ADD_IDP_MODAL_ID), MODAL_TRANSITION_DURATION);
          })
          .catch(showErrorBanner)
          .finally(() => {
            vm.isProcessing = false;
          });
      }

      if (vm.idp.id === GOOGLE_IDP_ID) {
        vm.waitPromise = createSamlIdp()
          .then(() => {
            vm.error = null;
            panelManager.open(APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID, {
              authSource: vm.authSource,
              isGoogleSelected: true,
            });

            // A new IdP was added so let whoever is listening know about it
            vm.onIdpAdded();

            $timeout(() => panelManager.close(APP_ADD_IDP_MODAL_ID), MODAL_TRANSITION_DURATION);
          })
          .catch(showErrorBanner)
          .finally(() => {
            vm.isProcessing = false;
          });
      }

      if (vm.idp.id === IDP_TYPES.AZURE) {
        // since there is a limit of only one type of IdP and we can
        // only create an azure IdP after we have the tenantID
        // we check and throw an IDP_ALREADY_EXISTS error here if we already have one
        if (_.some(vm.authSource.idps, {federationType: IDP_TYPES.AZURE})) {
          vm.isProcessing = false;
          showErrorBanner({data: {error: AUTH_SOURCE_ERROR_CODES.IDP_ALREADY_EXISTS}});
          return;
        }

        $timeout(() => panelManager.close(APP_ADD_IDP_MODAL_ID), MODAL_TRANSITION_DURATION);
      }

      if (vm.idp.id === EDU_IDP_ID) {
        panelManager.open(APP_ADD_EDU_PROVIDER_MODAL_ID);
        $timeout(() => panelManager.close(APP_ADD_IDP_MODAL_ID), MODAL_TRANSITION_DURATION);
      }
    }

    //////////

    /**
     * Creates an IdP of type GS on the current AuthSource
     *
     * @returns {Promise<AuthSource>} A promise that contains the instance of the AuthSource.
     *                                The AuthSource will contain the new IdP.
     */
    function createSamlIdp() {
      return vm.authSource.addIdp({federationType: IDP_TYPES.SAML}).catch((error) => {
        $log.error(
          `Could not create ${IDP_TYPES.SAML} IdP for ${vm.authSource.id} in ${orgId}`,
          error
        );
        return $q.reject(error);
      });
    }

    /**
     * Resets the modal to its initial state
     */
    function resetModal() {
      _.assign(vm, {
        error: null,
        idp: {},
        isProcessing: false,
      });
    }

    /**
     * Shows an error banner with a message that depends on the error code.
     * If the error code is not recognized a generic error message is used (see `ERRORS` map)
     *
     * @param {Error} err An error object that can represent a generic error or an http call error
     */
    function showErrorBanner(err) {
      const errorCode = _.get(err, 'data.error');
      vm.error = ERRORS[errorCode] || ERRORS[GENERIC_ERROR_CODE];
    }
  }
})();
