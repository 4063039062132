import help from '../../constants/help';
import {hasXdForFreeAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for product tab -> xd for free
 */
const xdForFree = () => ({
  access: hasXdForFreeAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.xdForFree.link.one.key',
          locator: 'aac_product_xd',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.xdForFree.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.XD_FOR_FREE,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  title: 'help.topics.xdForFree.title',
});

export default xdForFree;
