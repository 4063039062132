/**
 * @deprecated should use the React Spectrum Slider
 *
 * @ngdoc component
 * @name app.widgets.common:appSlider
 *
 * @description Wrapper for Coral slider
 * @param {Boolean} [disabled] boolean for disabling the slider
 * @param {Boolean} [filled] boolean for the left part of the slider to be filled
 * @param {string} [label] the label for the slider
 * @param {Number} max the maxiumn value of the slider that can be slided to
 * @param {Number} [min] the min value of the slider, default to 0
 * @param {Function} [onChange] callback when slider moves
 * @param {String} [orientation] orientation of the slider 'horizontal' or 'vertical'
 * @param {Boolean} [showValue] boolean for displaying the value
 * @param {Number} [step] the rate of change when slider being slided
 * @param {Number} [value] the starting value of the slider
 */
(function () {
  angular.module('app.widgets.slider').component('appSlider', {
    bindings: {
      disabled: '@?',
      filled: '<?',
      label: '@?',
      labelId: '<?',
      max: '<',
      min: '<?',
      onChange: '&?',
      orientation: '@?',
      showValue: '<?',
      step: '<?',
      value: '<?',
    },
    controller,
  });

  function controller(_, $element, $timeout) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onInit() {
      vm.slider = new Coral.Slider();
      vm.slider.on('change', () => {
        // Timeout in order to trigger an Angular digest cycle.
        $timeout(() => {
          if (vm.onChange) {
            vm.onChange({value: vm.slider.value});
          }
        });
      });
      $element.append(vm.slider);
    }

    function $onChanges() {
      _.assign(vm.slider, {
        disabled: vm.disabled,
        filled: vm.filled,
        labelledBy: vm.labelId,
        max: vm.max,
        min: _.isInteger(vm.min) ? vm.min : 0,
        orientation: vm.orientation || 'horizontal',
        showValue: vm.showValue,
        step: _.isInteger(vm.step) ? vm.step : 1,
        value: vm.value,
      });
      _.set(vm, 'slider.content.innerText', vm.label);
    }
  }
})();
