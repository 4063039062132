(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.widgets.support').component('appExpertSessionsSubPage', {
    bindings: {
      emptyBody: '@',
      emptyTitle: '@',
      expertSessionList: '<',
      paginationId: '@',
    },
    controller,
    templateUrl:
      'app/widgets/support/expert-sessions-sub-page/expert-sessions-sub-page.component.html',
  });

  function controller(_) {
    const vm = this;

    _.assign(vm, {
      expertSessionListDisplay: {
        admin: true,
        dateRequested: true,
        dateScheduled: true,
        product: true,
        sessionId: true,
        sessionTopic: true,
        status: true,
      },
      onSearch,
    });

    function onSearch(value) {
      vm.searchText = value;
    }
  }
})();
