import help from '../../constants/help';
import {hasTeamSign2Access} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for product tab -> team sign 2
 */
const teamSign2 = () => ({
  access: hasTeamSign2Access,
  content: [
    {
      links: [
        {
          key: 'help.topics.teamSign2.link.one.key',
          locator: 'landing_page_esign_team',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.BUTTON,
        },
        {
          key: 'help.topics.teamSign2.link.two.key',
          locator: 'adobesign_team_features',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.teamSign2.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.TEAM_SIGN2,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  title: 'help.topics.teamSign2.title',
});
export default teamSign2;
