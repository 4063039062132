import {IMS_ID_REGEX} from '@admin-tribe/binky';
import {decodeData} from '@pandora/administration-utils';

/**
 * Checks if a given string is a valid org ID string
 * @param {string} orgId
 * @returns {boolean}
 */
const isOrgId = (orgId) => IMS_ID_REGEX.test(orgId);

const decodeBase64 = (input) => decodeData(input);

export {isOrgId, decodeBase64};
