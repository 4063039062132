(function () {
  /**
   * @deprecated moved to src2 as <SupportCaseClosedSurveyModal>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportCaseClosedSurveyModal
   *
   * @description Modal which allows to do a support case survey.
   */
  const componentName = 'appSupportCaseClosedSurveyModal';
  angular.module('app.widgets.support').component(componentName, {
    bindings: {
      onClose: '&',
      supportCase: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-case-closed-survey-modal/support-case-closed-survey-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $window,
    AnalyticsEvent,
    SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      ctaHandler,
      onBinkyModalClose,
      onClickCancel,
      SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID,
    });

    function ctaHandler() {
      $window.open(vm.supportCase.surveyUrl, '_blank');
      panelManager.close(SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID);
      triggerAnalytics('takeSurveyButton');
    }

    function onBinkyModalClose() {
      vm.onClose();
    }

    function onClickCancel() {
      panelManager.close(SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID);
      triggerAnalytics('remindMeLaterButton');
    }

    function triggerAnalytics(componentMethod) {
      AnalyticsEvent.dispatch({
        componentMethod,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
