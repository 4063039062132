(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketNotifyee
   * @description renders a notifyee email address for a support ticket.
   */
  angular.module('app.widgets.support').component('appSupportTicketNotifyee', {
    bindings: {
      email: '@',
      onRemove: '&',
    },
    templateUrl:
      'app/widgets/support/support-ticket-notifyee/support-ticket-notifyee.component.html',
  });
})();
