import {SORT_ORDER} from '@pandora/react-table-section';
import cloneDeep from 'lodash/cloneDeep';

import {INVOICE_TYPES, PO_NUMBER_REGEX} from './BillingHistoryConstants';

/**
 * @description Checks if the status of one of the invoices is unpaid.
 *
 * @param {Array} items The array of invoices
 * @returns {boolean} - True if of one the invoices is unpaid
 */
function hasUnpaidInvoices(items) {
  return items.some((invoice) => invoice.isUnpaid());
}

/**
 * @description Determines whether or not a column is sorted in ascending or descending order
 *
 * @param {String} sortedOrder
 * @returns true if a column is sorted in descending order, false if ascending
 */
function isDescending({sortedOrder}) {
  return sortedOrder === SORT_ORDER.DESC;
}

/**
 * @description Validates the format of the new PO number.
 *
 * @param {String} PONumber - The PO number of the invoice
 * @returns {boolean} - True if PO number is valid
 */
function isValidPONumber(PONumber) {
  return PONumber?.length > 0 && PO_NUMBER_REGEX.test(PONumber);
}

/**
 * @description Takes an array of externalContractIds and invoiceIds and transforms it into a mapped object
 *
 * @param {String[]} invoiceKeys - array of strings that contain both objects in this format:
 * 'externalContractId:invoiceId'
 * @returns {Object} invoiceMap - object that contains a key value pair of externalContractId
 * to list of associated invoiceIds
 */
function groupInvoicesByExternalContractIdAndType(invoiceKeys) {
  const invoiceMap = {};
  Object.keys(INVOICE_TYPES).forEach((type) => {
    invoiceMap[type] = {};
    return invoiceMap;
  });
  if (invoiceKeys instanceof Array) {
    invoiceKeys.forEach((idGroup) => {
      const [externalContractId, invoiceId, type] = idGroup.split(':');
      if (invoiceMap[type][externalContractId]) {
        invoiceMap[type][externalContractId].push(invoiceId);
      } else {
        invoiceMap[type][externalContractId] = [invoiceId];
      }
    });
  }
  return invoiceMap;
}

/**
 * @description Tallies up the total number of invoice ids
 *
 * @param {Object} selectedInvoices - object that contains with mappings of CREDIT, INVOICE, and RECEIPT,
 * to a set of key value pairs of externalContractId to list of associated invoiceIds
 * @returns {Number}
 */
function selectedInvoicesCount(selectedInvoices) {
  if (selectedInvoices instanceof Array) {
    return 0;
  }
  return Object.values(selectedInvoices)
    .flatMap((externalContractIdsToInvoiceIds) => Object.values(externalContractIdsToInvoiceIds))
    .reduce((sum, invoiceIds) => sum + invoiceIds.length, 0);
}

/**
 * @description Updates PO number of the invoice without mutating the object
 *
 * @param {Object} options - Object containing items, newPoNumber and oldPoNumber
 * @param {String} options.invoiceId - The id of the invoice to be updated
 * @param {Invoice[]} options.items - Current state of invoices
 * @param {String} options.newPoNumber - New PO number of the invoice
 * @returns {Array} - New array of object with updated PO number
 */
function updatePoNumber({invoiceId, items, newPoNumber, type}) {
  // Deep clone current state, so we can modify it without mutating
  const newItems = cloneDeep(items);
  const invoiceToUpdate = newItems.find(
    (item) => item.id === invoiceId && item.documentType === type
  );
  invoiceToUpdate.poNumber = newPoNumber;

  return newItems;
}

export {
  isDescending,
  isValidPONumber,
  hasUnpaidInvoices,
  groupInvoicesByExternalContractIdAndType,
  selectedInvoicesCount,
  updatePoNumber,
};
