(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.widgets.scorecard:ScorecardGroupItem
   * @description Model factory for ScorecardGroupItem object. The unit and score values are both required.
   *              A warning progress bar is shown if thresholdReached is true or if percentageConsumed >= percentageThreshold.
   */
  angular.module('app.widgets.scorecard').factory('ScorecardGroupItem', getScorecardGroupItem);

  /* @ngInject */
  function getScorecardGroupItem(_, WARNING_THRESHOLD) {
    /**
     * @class
     * @description Representation of all properties needed for a ScorecardGroupItem
     */
    class ScorecardGroupItem {
      /**
       * @constructs ScorecardGroupItem
       * @description Constructor for ScorecardGroupItem model object.
       * @param {Object} options - ScorecardGroupItem instance settings.
       * @param {String} [options.label] - label for the ScorecardGroupItem instance. If item.label exists, it overrides the label generated from item.unit
       * @param {String} [options.secondaryLabel] - label for optional bubble which follows label
       * @param {Number} [options.secondaryScore] - score for the optional bubble which follows the label
       * @param {String} [options.label] - label for the ScorecardGroupItem instance. If item.label exists, it overrides the label generated from item.unit
       * @param {Boolean} [options.thresholdReached] - True if the scorecard group item has reached its warning threshold. False if unspecified.
       * @param {Number} [options.total] - The total value. No default value if unspecified.
       * @param {Boolean} [options.positiveScorecard=false] - True when displaying positive scorecards, this disabled threshold coloring.
       * @param {String} options.unit - The label for the usage values.
       * @param {Number} options.score - The used value.
       * @param {Boolean} [options.showUsageUnavailable] - Used by appScorecardLabel filter.
       *   If true, the usageUnavailableKey will be shown in place of the label. Default is false.
       * @param {String} [options.usageUnavailableKey] - Used by appScorecardLabel filter.
       *   Translation key in the 'widgets.common.scorecard.label' namespace that will be shown in place of the label.
       *   Default key is 'usageDataNotAvailable'
       * @param {String} options.variant - SCORECARD_VARIANT.PRODUCT_CARD or undefined. Default is undefined.
       *   If PRODUCT_CARD and both score and total are undefined, the label will be set to 'Usage data not available'.
       */
      constructor(options = {}) {
        if (options.total) {
          this.total = options.total;
          this.percentageConsumed = (options.score / options.total) * 100;
        }
        this.label = options.label;
        this.secondaryLabel = options.secondaryLabel;
        this.secondaryScore = options.secondaryScore;

        this.positiveScorecard = options.positiveScorecard;

        this.percentageThreshold = _.isNumber(options.percentageThreshold)
          ? options.percentageThreshold
          : WARNING_THRESHOLD;
        this.score = options.score;
        this.thresholdReached = !!options.thresholdReached;
        this.unit = options.unit;

        this.showUsageUnavailable = options.showUsageUnavailable;
        this.usageUnavailableKey = options.usageUnavailableKey || 'usageDataNotAvailable';
      }

      // Wrap the constructor to make it easier to unit test with ScorecardGroupItems.
      static construct(options) {
        return new ScorecardGroupItem(options);
      }

      // Note this does not currently specify a percentageThreshold.
      static constructForConsumable(consumable) {
        return new ScorecardGroupItem({
          score: consumable.consumedQuantity,
          thresholdReached: consumable.isThresholdTriggered(),
          total: consumable.totalQuantity,
          unit: consumable.unit,
        });
      }

      static constructUsageUnavailableLabel(options = {}) {
        return new ScorecardGroupItem({
          showUsageUnavailable: true,
          usageUnavailableKey: options.usageUnavailableKey,
        });
      }
    }

    return ScorecardGroupItem;
  }
})();
