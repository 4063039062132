import {log} from '@admin-tribe/binky';

import jilTeams from '../../api/jil/jilTeams';

class Team {
  /**
   * @description To get information about a specific package pool, specifically, the list of running devices and a summary for this pool.
   *
   * @param {Array<Object>} selected orgId, poolId
   */
  // eslint-disable-next-line class-methods-use-this -- individual function
  async devicePackagePoolResource(orgId, poolId) {
    try {
      return await jilTeams.getDevicePackagePoolResource({
        orgId,
        poolId,
      });
    } catch (error) {
      // a 404 indicates the pool can't be found
      if (error?.response?.status === 404) {
        return error?.response;
      }
      log.error(
        `Failed to get information for pool and device with orgId: ${orgId} and poolId: ${poolId}. Error:`,
        error
      );
      throw new Error('package pool not found.');
    }
  }
}

export default Team;
