(function () {
  /**
   * @ngdoc module
   * @name app.widgets.domains2
   * @description module that defines domain components for the v2 domain components that's being used multi-domains project
   */
  angular.module('app.widgets.domains2', [
    'angular-clipboard',
    'app.core.api.jil',
    'app.core.directories',
    'app.core.domain-utils',
    'app.core.message',
    'app.core.organizations',
    'app.core.organizations.token',
    'app.widgets.form-section',
    'app.widgets.search-list',
    'binky.core.common.selection',
    'binky.core.domains',
    'binky.core.js-utils',
    'binky.core.lodash',
    'binky.shell.panels.modal',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.display-message',
    'binky.widgets.common.go-url',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.learn-more-go-url',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.select',
    'binky.widgets.common.status',
    'binky.widgets.common.toast',
    'binky.widgets.common.wizard',
    'binky.widgets.directories.directory-type',
    'binky.widgets.domains',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
