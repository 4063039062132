(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.notifications:appAdobeStatusItem
   *
   * @description display of a single adobe status item
   *
   * @example <app-adobe-status-item></app-adobe-status-item>
   *
   */
  angular.module('app.widgets.adobe-status').component('appAdobeStatusItem', {
    bindings: {
      statusEvent: '<',
    },
    controller,
    templateUrl: 'app/widgets/adobe-status/adobe-status-item.component.html',
  });

  /* @ngInject */
  function controller($filter, $sce, $translate, _, AdobeStatusStrings) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    vm.adobestatusStrings = AdobeStatusStrings.get();

    // noinspection Eslint
    function $onInit() {
      if (vm.statusEvent) {
        vm.eventTimestamp = vm.statusEvent.timestamp;
        vm.serviceName = vm.adobestatusStrings.getStringForKey(
          vm.statusEvent.getParentServiceNameKey()
        );
        vm.statusEvent.displayedMessage = $sce.trustAsHtml(buildEventMessage());
      }
    }

    function buildEventMessage() {
      const translationParams = {
        id: vm.statusEvent.id,
        service: vm.serviceName,
      };
      if (vm.statusEvent.isMaintenance()) {
        return getMaintenanceMessage();
      } else if (vm.statusEvent.isMajor()) {
        return getEventMessage('major');
      }
      return getEventMessage('minor');

      function getMaintenanceMessage() {
        if (vm.statusEvent.isResolved()) {
          return $translate.instant(
            'widgets.adobeStatus.adobeStatusItem.messages.maintenance.resolved',
            translationParams
          );
        } else if (vm.statusEvent.isActive()) {
          return $translate.instant(
            'widgets.adobeStatus.adobeStatusItem.messages.maintenance.ongoing',
            translationParams
          );
        } else if (vm.statusEvent.isAnnounced()) {
          translationParams.startTime = $filter('binkyDateTime')(vm.statusEvent.startTime);
          translationParams.endTime = $filter('binkyDateTime')(vm.statusEvent.endTime);
          return $translate.instant(
            'widgets.adobeStatus.adobeStatusItem.messages.maintenance.scheduled',
            translationParams
          );
        } else if (vm.statusEvent.isCancelled()) {
          translationParams.startTime = $filter('binkyDateTime')(vm.statusEvent.startTime);
          translationParams.endTime = $filter('binkyDateTime')(vm.statusEvent.endTime);
          return $translate.instant(
            'widgets.adobeStatus.adobeStatusItem.messages.maintenance.cancelled',
            translationParams
          );
        }
        return $translate.instant(
          'widgets.adobeStatus.adobeStatusItem.messages.default',
          translationParams
        );
      }

      function getEventMessage(majorOrMinor) {
        if (vm.statusEvent.isCancelled()) {
          return $translate.instant(
            `widgets.adobeStatus.adobeStatusItem.messages.${majorOrMinor}.cancelled`,
            translationParams
          );
        }
        return $translate.instant(
          vm.statusEvent.isResolved()
            ? `widgets.adobeStatus.adobeStatusItem.messages.${majorOrMinor}.resolved`
            : `widgets.adobeStatus.adobeStatusItem.messages.${majorOrMinor}.ongoing`,
          translationParams
        );
      }
    }
  }
})();
