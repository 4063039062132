import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This is an internal component to be used by the various types of InfoItems so that they
 * maintain consistent formatting.
 */
const InfoItemContainer2 = ({children, label}) => (
  <Flex direction="column" rowGap="size-25">
    <Text data-testid="info-item-label">{label}</Text>
    <Flex
      alignItems="center"
      columnGap="size-50"
      direction="row"
      height="size-400" /* so items align with drawer trigger */
      UNSAFE_style={{fontSize: '18px', fontWeight: 'bold'}}
    >
      {children}
    </Flex>
  </Flex>
);

InfoItemContainer2.propTypes = {
  /**
   * The content to display in the value container.
   */
  children: PropTypes.node.isRequired,
  /**
   * The InfoItem label.
   */
  label: PropTypes.string.isRequired,
};

export default InfoItemContainer2;
