(function () {
  /**
   * @deprecated this file has been ported to src2
   *
   * @ngdoc service
   * @name app.core.admin.access:adminAccess
   * @description admin access factory
   */
  angular.module('app.core.admin.access').factory('adminAccess', adminAccess);

  /* @ngInject */
  function adminAccess(
    $q,
    _,
    auth,
    feature,
    GLOBAL_ADMIN_POLICY,
    OrganizationManager,
    ROLE,
    storageAccess
  ) {
    const service = {
      canAssignAdmin,
      canManageAdmins,
      canViewAdmins,
      canViewLicenseDeveloperAdmins,
      canViewRole,
      getManageableAdminRoles,
      getManageableProductAdminRoles,
    };

    return service;

    //////////////

    function allowIfNotMigrating() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    function canManageAdmins() {
      return OrganizationManager.getPolicyValue(GLOBAL_ADMIN_POLICY.MANAGE_ADMINS);
    }

    function canViewAdmins() {
      return auth.hasAnyRole([
        ROLE.ADMIN.DEPLOYMENT,
        ROLE.ADMIN.LICENSE,
        ROLE.ADMIN.ORG,
        ROLE.ADMIN.PRODUCT,
        ROLE.ADMIN.STORAGE,
        ROLE.ADMIN.USER_GROUP,
      ]);
    }

    function canViewLicenseDeveloperAdmins() {
      return auth.hasAnyRole(
        feature.isEnabled('temp_parkour_mm')
          ? [
              ROLE.ADMIN.LICENSE,
              ROLE.ADMIN.ORG,
              ROLE.ADMIN.PRODUCT,
              ROLE.ADMIN.PRODUCT_SUPPORT,
              ROLE.ADMIN.SUPPORT,
              ROLE.ADMIN.USER_GROUP,
            ]
          : [
              ROLE.ADMIN.LICENSE,
              ROLE.ADMIN.ORG,
              ROLE.ADMIN.PRODUCT,
              ROLE.ADMIN.SUPPORT,
              ROLE.ADMIN.USER_GROUP,
            ]
      );
    }

    function canAssignAdmin() {
      return (
        auth.hasAnyRole([
          ROLE.ADMIN.DEPLOYMENT,
          ROLE.ADMIN.LICENSE,
          ROLE.ADMIN.ORG,
          ROLE.ADMIN.PRODUCT,
          ROLE.ADMIN.USER_GROUP,
        ]) &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddAdminLogic()
      );
    }

    function canViewRole(role) {
      return (
        $q
          .all([
            storageAccess.canViewStorage(),
            OrganizationManager.getProductsForActiveOrg().$promise,
          ])
          // eslint-disable-next-line complexity
          .then(([canViewStorage, productList]) => {
            if (
              role !== ROLE.ADMIN.ORG &&
              (productList.hasOnlyTeamProducts() ||
                OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers())
            ) {
              return false;
            }

            if (role === ROLE.ADMIN.DEPLOYMENT && !productList.hasPackageSupport()) {
              return false;
            }

            if (role === ROLE.ADMIN.STORAGE && !canViewStorage) {
              return false;
            }

            if (
              role === ROLE.ADMIN.SUPPORT &&
              (!productList.hasSupportRoleAssignmentAllowed() ||
                (feature.isEnabled('temp_parkour_mm') &&
                  // should use ROLE.ADMIN.SUPPORTS when product support admin is available
                  productList.hasProductSupportRoleAssignmentAllowed()))
            ) {
              return false;
            }

            if (
              role === ROLE.ADMIN.SUPPORTS &&
              !productList.hasProductSupportRoleAssignmentAllowed()
            ) {
              return false;
            }

            return (
              auth.isUserOrgAdmin() ||
              (role === ROLE.ADMIN.PRODUCT && auth.isUserProductAdmin()) ||
              (role === ROLE.ADMIN.LICENSE &&
                (auth.isUserProductAdmin() || auth.isUserPlcAdmin())) ||
              (role === ROLE.ADMIN.USER_GROUP && auth.isUserUserGroupAdmin()) ||
              (role === ROLE.ADMIN.DEPLOYMENT && auth.isUserDeploymentAdmin()) ||
              (role === ROLE.ADMIN.STORAGE && auth.isUserStorageAdmin()) ||
              (role === ROLE.ADMIN.SUPPORT && auth.isUserSupportDelegate())
            );
          })
      );
    }

    function getManageableAdminRoles() {
      const roles = feature.isEnabled('temp_parkour_mm')
        ? [
            ROLE.ADMIN.ORG,
            ROLE.ADMIN.PRODUCT,
            ROLE.ADMIN.LICENSE,
            ROLE.ADMIN.USER_GROUP,
            ROLE.ADMIN.DEPLOYMENT,
            ROLE.ADMIN.STORAGE,
            ROLE.ADMIN.SUPPORT,
            ROLE.ADMIN.SUPPORTS,
          ]
        : [
            ROLE.ADMIN.ORG,
            ROLE.ADMIN.PRODUCT,
            ROLE.ADMIN.LICENSE,
            ROLE.ADMIN.USER_GROUP,
            ROLE.ADMIN.DEPLOYMENT,
            ROLE.ADMIN.STORAGE,
            ROLE.ADMIN.SUPPORT,
          ];

      return $q
        .all(_.map(roles, canViewRole))
        .then((mask) => _.filter(roles, (_role, i) => mask[i]));
    }

    function getManageableProductAdminRoles(product) {
      return auth.isUserOrgAdmin() && product.isProductSupportRoleAssignmentAllowed()
        ? [ROLE.ADMIN.PRODUCT, ROLE.ADMIN.PRODUCT_SUPPORT]
        : [ROLE.ADMIN.PRODUCT];
    }
  }
})();
