import {ImageIcon, TooltipButton} from '@admin-tribe/binky-ui';
import {Checkbox, Divider, Flex, Link, Text, View, VisuallyHidden} from '@adobe/react-spectrum';
import Star from '@react/react-spectrum/Icon/Star';
import StarOutline from '@react/react-spectrum/Icon/StarOutline';
import {useFocusRing} from '@react-aria/focus';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import './PluginCardCheckbox.pcss';

/**
 * PluginCardCheckbox is a Card with Checkbox Component that will get used in Plugins screen
 */
const PluginCardCheckbox = ({
  description,
  iconUrl,
  isCardDisabled = false,
  isCardSelected = false,
  learnMoreUrl,
  onChange,
  products,
  rating,
  title,
}) => {
  const iconSize = 96;
  const intl = useIntl();

  const {isFocusVisible, focusProps} = useFocusRing();

  const ratingStars = Array.from({length: 5}, (_value, index) => ({
    filled: index < rating,
    key: index,
  }));

  const pluginCardStyle = `plugin-card-checkbox${isCardSelected ? ' selected' : ''}${
    isFocusVisible ? ' focused' : ''
  }`;

  return (
    <View height="318px" width="size-3600">
      <label htmlFor={`${title}pluginCardCheckbox`}>
        <div data-testid="plugin-card-checkbox" styleName={pluginCardStyle}>
          <Flex alignContent="space-around" direction="column" height="size-3600">
            <Flex alignItems="center" justifyContent="space-between">
              <ImageIcon alt="" size="L" src={iconUrl} />
              <Checkbox
                aria-describedby={title}
                aria-label={title}
                id={`${title}pluginCardCheckbox`}
                isDisabled={isCardDisabled}
                isEmphasized
                isSelected={isCardSelected}
                onChange={onChange}
                {...focusProps}
              />
            </Flex>
            <Flex direction="column" gap="size-150" height="size-3000" marginTop="size-150">
              <Text id={title} UNSAFE_style={{fontWeight: 'bold'}}>
                {title}
              </Text>
              <Flex gap="size-50">
                {ratingStars.map(({filled, key}) =>
                  filled ? (
                    <Star key={key} size="S" styleName="rated-star" />
                  ) : (
                    <StarOutline key={key} size="S" styleName="unrated-star" />
                  )
                )}
                <VisuallyHidden>
                  {intl.formatMessage(
                    {id: 'packages.createPackage.choosePlugins.ratingString'},
                    {rating}
                  )}
                </VisuallyHidden>
              </Flex>
              <View maxHeight="size-1600" overflow="auto">
                {description}
                <View>
                  <Link
                    aria-label={intl.formatMessage(
                      {
                        id: 'packages.createPackage.choosePlugins.learnMore.ariaLabel',
                      },
                      {pluginName: title}
                    )}
                    isQuiet
                  >
                    <a href={learnMoreUrl} rel="noreferrer" target="_blank">
                      {intl.formatMessage(
                        {
                          id: 'packages.createPackage.choosePlugins.learnMoreLabel',
                        },
                        {pluginName: title}
                      )}
                    </a>
                  </Link>
                </View>
              </View>
            </Flex>
            <Divider marginBottom="size-200" size="S" />
            <Flex gap="size-40">
              {products.slice(0, 10).map((product) => (
                <div key={product.name} styleName="tooltip-icon">
                  <TooltipButton hoverText={product.name} isQuiet variant="action">
                    <ImageIcon alt="" size="S" src={product.getIconUrl(iconSize)} />
                  </TooltipButton>
                </div>
              ))}
            </Flex>
          </Flex>
        </div>
      </label>
    </View>
  );
};

PluginCardCheckbox.propTypes = {
  /**
   * String to be as content of the card
   */
  description: PropTypes.string.isRequired,
  /**
   * Url of the icon appears on top left corner above card title
   */
  iconUrl: PropTypes.string.isRequired,
  /**
   * Boolean value indication if the card is disabled, defaults to false
   */
  isCardDisabled: PropTypes.bool,
  /**
   * Boolean value to indicate if the card is disabled. Defaults to false.
   */
  isCardSelected: PropTypes.bool,
  /**
   * Learn more url for plugin
   */
  learnMoreUrl: PropTypes.string.isRequired,
  /**
   * Function to be called when selection changes
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Array of items to populate card footer with product icons
   */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Function that returns the product icon url
       */
      getIconUrl: PropTypes.func.isRequired,
      /**
       * Name of product
       */
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Rating the plugin received
   */
  rating: PropTypes.number.isRequired,
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
};

export default PluginCardCheckbox;
