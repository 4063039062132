import {eventBus, hasContractInRenewalWindow} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import auth from 'core/services/auth';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

const GLOBAL_MODAL_OPEN_EVENT = 'global-modals-open';
/**
 * This class creates HVA card allowing user to buy products for their organization
 */
class AddProductsHva extends HvaCard {
  static get({intl}) {
    if (
      !auth.isUserOrgAdmin() ||
      rootStore.organizationStore.productList.items.length > 0 ||
      !isAllowedToAddProducts() ||
      hasContractInRenewalWindow(rootStore.organizationStore.contractList)
    ) {
      return Promise.reject();
    }

    return Promise.resolve([new AddProductsHva({intl})]);
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.addProducts';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/buy-products.svg',
      },
      id: 'hva-add-products',
      isDismissible: false,
      onCTA: () => {
        eventBus.emit(GLOBAL_MODAL_OPEN_EVENT, {
          componentName: 'appAddProductsModal2',
          version: 2,
        });
      },
      priorityOrder: HVA_ORDER.ADD_PRODUCTS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default AddProductsHva;
