import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import StorageWorkspaceNav from './StorageWorkspaceNav';

/** A component which renders the StorageWorkspaceNav and an Outlet */
const StorageWorkspace = () => (
  <Workspace useOutlet>
    <StorageWorkspaceNav />
  </Workspace>
);

export default StorageWorkspace;
