import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

/**
 * @description Fetches the personal offer merchandising data defined in a Sophia campaign content
 * @param {Object} options - options used to customize the hook
 * @param {String} options.promotionId - represents the promotion id
 * @param {String} options.selectedReasons - string representation of reasons selected
 * @param {String} options.type - defines the type of card to render on e.g FREE_TIME or PERCENTAGE_DISCOUNT
 * @returns {Object} Object of the current merchandising data
 *          {String} type - Save Offer type i.e FREE_TIME | PERCENTAGE_DISCOUNT
 *          {String} promotionId - The promotion Id.
 *          {String} cancellationReasonKey - the cancellation reason associated
 *          {String} description - description to show on the card
 *          {String} shortDescription - shortDescription to show on the card
 *          {String} headline - headline to show on the card
 *          {Boolean} isLoading - Whether the hook is loading.
 */
const useSophiaPersonalOfferGivenCancelReasons = ({promotionId, selectedReasons, type}) => {
  const getMerchandisingData = useCallback(async () => {
    const merchandisingData = await sophiaHelper.getSophiaContent({
      contextualParamsOptions: {selectedCancellationReasons: selectedReasons},
      surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.SELF_CANCEL_PROMO_MERCH,
    });

    return transformResponse(merchandisingData, promotionId, type);
  }, [selectedReasons, type, promotionId]);

  // Configure useAsyncModel to load the merchandising model
  const {
    model: sophiaMerchandisingData,
    isLoading,
    error,
  } = useAsyncModel({
    initState: [],
    loadFn: getMerchandisingData,
  });

  if (!isLoading && error) {
    // Error fetching merchandising data from Sophia
    return {error, isLoading: false, sophiaMerchandisingData};
  }

  return {error, isLoading, sophiaMerchandisingData};
};

/**
 * @description A function that transforms the sophia self cancel offer response
 * @param {Object} response Sophia response
 * @param {string} promotionId represents the promotion id
 * @param {string} type Sdefines the type of card to return on e.g FREE_TIME or PERCENTAGE_DISCOUNT
 * @returns {Object} Object of sophia merchandising offer
 */
function transformResponse(response, promotionId, type) {
  const {items} = response;

  if (items.length === 0) {
    return undefined;
  }

  let merchandisingItems;

  items.forEach((item) => {
    if (item.isJson()) {
      const content = JSON.parse(item.getContent());
      const selfCancelPersonalisedOfferList = content.data.selfcancelpersonaliseofferList.items;
      if (selfCancelPersonalisedOfferList?.length > 0) {
        merchandisingItems = selfCancelPersonalisedOfferList?.find(
          (offer) => offer?.promotionId === promotionId && type === offer?.type
        );
      }
    }
  });

  return merchandisingItems;
}

export default useSophiaPersonalOfferGivenCancelReasons;
