import {IllustratedMessage} from '@admin-tribe/binky-ui';
import {
  Button,
  Content,
  Heading,
  IllustratedMessage as SpectrumIllustratedMessage,
} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

const SyncEmptyState = observer(({onAddSync}) => {
  const intl = useIntl();
  const {directoryStore} = useDirectoryContext();

  const onClickAddSync = () => {
    onAddSync?.();
  };

  if (!directoryStore.domains.hasActiveDomains) {
    return (
      <IllustratedMessage
        content={intl.formatMessage({id: 'settings.sync.emptyState.noActiveDomainsWarningContent'})}
        heading={intl.formatMessage({
          id: 'settings.sync.emptyState.noActiveDomainsWarningHeading',
        })}
        headingLevel={2}
        illustration={<AlertIcon size="XL" />}
        marginTop={40}
      />
    );
  }

  if (directoryStore.isPrimaryIdpOkta) {
    return (
      <IllustratedMessage
        content={intl.formatMessage({id: 'settings.sync.emptyState.oktaWarningContent'})}
        heading={intl.formatMessage({id: 'settings.sync.emptyState.oktaWarningHeading'})}
        headingLevel={2}
        illustration={<AlertIcon size="XL" />}
        marginTop={40}
      />
    );
  }

  return (
    <SpectrumIllustratedMessage marginTop={40}>
      <Heading level={2}>
        <FormattedMessage id="settings.sync.emptyState.title" />
      </Heading>

      <Content>
        <FormattedMessage id="settings.sync.emptyState.description" />
      </Content>
      <Button
        data-testid="add-sync-button"
        marginTop={16}
        onPress={onClickAddSync}
        variant="accent"
      >
        {intl.formatMessage({id: 'settings.sync.addDirectorySyncButton'})}
      </Button>
    </SpectrumIllustratedMessage>
  );
});

SyncEmptyState.propTypes = {
  clearCaches: PropTypes.func,
};

export default SyncEmptyState;
