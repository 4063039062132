import {Offer} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * @description A helper hook to load a list of Offer models.
 *
 * @param {String[]} offerIds - The list of offerIds to that will be used to fetch the offers
 *
 * @returns {AsyncModelInterface} Object composed of state variables: isOfferListLoading, offerList, offerListError
 *
 * @typedef {Object} AsyncModelInterface
 * @property {Offer[]} offerList - List of Offer models
 * @property {Boolean} isOfferListLoading - Whether data is currently being loaded
 * @property {Object} offerListError - If loading data failed, then this contains the error that occurred
 */
const useOfferList = (offerIds) => {
  const loadOfferList = useCallback(
    () => Promise.all(offerIds.map((offerId) => Offer.get({offerId}))),
    [offerIds]
  );

  const {
    model: offerList,
    isLoading: isOfferListLoading,
    error: offerListError,
  } = useAsyncModel({
    loadFn: loadOfferList,
  });

  return {isOfferListLoading, offerList, offerListError};
};

export default useOfferList;
