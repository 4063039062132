(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:errorToLaunchTranslator
   * @description Translate ErrorDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('errorToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, errorDescriptor) {
      const {setProperty} = launchContext;

      setProperty('error.client.primary.errorInfo.name', _.get(errorDescriptor, 'clientErrorName'));
    }
  }
})();
