import {TooltipButton} from '@pandora/react-tooltip-button';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';

const PluginAlertTooltipIcon = ({hoverText}) => (
  <TooltipButton hoverText={hoverText} isQuiet variant="action">
    <AlertIcon color="notice" UNSAFE_style={{fill: 'var(--spectrum-semantic-notice-color-icon)'}} />
  </TooltipButton>
);
PluginAlertTooltipIcon.propTypes = {
  /**
   * String to displayed on hovering the icon
   */
  hoverText: PropTypes.string.isRequired,
};

export default PluginAlertTooltipIcon;
