import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import InsightsWorkspaceNav from './InsightsWorkspaceNav';

/** A component which renders the InsightsWorkspaceNav and an Outlet */
const InsightsWorkspace = () => (
  <Workspace useOutlet>
    <InsightsWorkspaceNav />
  </Workspace>
);

export default InsightsWorkspace;
