(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name StorageRepositoryList
   * @description Model for a list of StorageRepository
   */
  angular
    .module('app.core.storage-repository-list')
    .factory('StorageRepositoryList', StorageRepositoryListFactory);

  /* @ngInject */
  function StorageRepositoryListFactory(
    $log,
    $q,
    _,
    jilStorageRepositories,
    List,
    MODEL,
    modelCache,
    StorageRepository
  ) {
    class StorageRepositoryList extends List {
      /**
       * @description Method to construct a new StorageRepositoryList.
       *
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.STORAGEREPOLIST,
              itemClassRef: StorageRepository,
              resource: jilStorageRepositories.storageRepos,
            },
            options
          )
        );
      }

      /**
       * @description Method to save changes of the repository name to the back-end.
       *
       * @param {StorageRepository} repository - the repository being updated
       * @param {String} repository.id - the org id related to the repository
       * @param {String} repository.name - the new name repository to save
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(repository) {
        this.$resolved = false;

        const operations = [
          {
            op: 'replace',
            path: `${repository.id}/name`,
            value: repository.name,
          },
        ];

        this.$promise = jilStorageRepositories.storageRepos
          .batchOperation(operations)
          .$promise.then(() => {
            this.refresh();
          })
          .catch((error) => {
            $log.error('Repository name failed to update. Error: ', error);
            return $q.reject(error);
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Method to retrieve a list of StorageRepository from the back-end.
       *
       * @returns {StorageRepositoryList} new StorageRepositoryList Object reference
       */
      static get() {
        const model = new StorageRepositoryList();
        const cachedModel = modelCache.get(MODEL.STORAGEREPOLIST, model.key());
        if (!cachedModel) {
          model.refresh();
          return model;
        }
        return cachedModel;
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.STORAGEREPOLIST, 1);
    return StorageRepositoryList;
  }
})();
