(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support::appSupportTicketDescriptionDetailSection
   *
   * @description displays the description of a support ticket, or a fallback string if no
   *   description was entered. The Agent UI does not require agents to specify a description.
   *
   * @binding {String} sectionTitle - the translation key to use for the section's heading
   * @binding {SupportTicket} supportTicket - the ticket whose description should be displayed
   */
  angular.module('app.widgets.support').component('appSupportTicketDescriptionDetailSection', {
    bindings: {
      sectionTitle: '@',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-description-detail-section/support-ticket-description-detail-section.component.html',
  });

  /* @ngInject */
  function controller(_, $translate) {
    const vm = this;

    _.assign(vm, {
      getDescription,
    });

    function getDescription() {
      const trimmedDescription = _.chain(vm).get('supportTicket.description').trim().value();
      return (
        trimmedDescription ||
        $translate.instant('widgets.support.supportTicketDescriptionDetailSection.noDescription')
      );
    }
  }
})();
