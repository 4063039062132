import {FULFILLABLE_ITEM_CHARGING_UNIT, feature, getProductsByOfferIds} from '@admin-tribe/binky';
import {useMemo} from 'react';

import {getProductByPac, getRetentionDetails, isMultipleTaxRate} from '../../SelfCancelUtils';
import useOfferList from '../use-offer-list/useOfferList';

/**
 * @description A helper hook to return the Save Offer details given a retention Id from the
 * Products Change model. Uses Products Change model provided useProductsChangeContext hook.
 * @param {Contract} contract - the contract to determine tax and recurrence terms
 * @returns {Object} retention - Object composed of the following cancellation details properties
 *          {String} [retention.banner] - the URL of the banner image
 *          {Object} retention.currency - Currency object used for the prices
 *          {String} retention.description - Save Offer description
 *          {Number} retention.discountPrice - Total discount price
 *          {String} retention.headline - Save Offer headline
 *          {String} [retention.icon] - the URL of the icon image
 *          {Boolean} [retention.isBestValue] - Whether this Save Offer has the best total discount
 *          from the returned list.
 *          {Number} retention.nextPrice - Price during promotion
 *          {String} retention.recurrenceTerm - Recurrence Term, i.e. MONTHLY
 *          {Number} retention.regularPrice - Price after promotion
 *          {String} retention.shortDescription - Save Offer short description
 *          {Boolean} retention.isMultiTaxRate - Indidcates if items in the cart have multiple tax rates among them
 *          {String} retention.taxTerm - Tax term, i.e. TAX
 *          {Number} result.total.taxRate - Region tax rate for the owner
 *          {String} retention.total.taxTerm - Tax term, i.e. TAX
 *          {Number} retention.total.totalTax - The tax amount from the cost of the all remaining licenses
 *          {Number} retention.total.totalWithTax - The total amount including tax of the cost of all remaining licenses
 *          {Number} retention.total.totalWithoutTax - The total amount without tax of the cost of all remaining licenses
 */
const useRetentionCart = ({productList, retention: retentionDetails}) => {
  const {contractItems, existingBilling, nextBilling} =
    getRetentionDetails(retentionDetails?.retention)?.contractData || {};

  // Keep the useMemo to avoid infinite data fetches in useOffers
  const offerIds = useMemo(() => contractItems?.map((item) => item.offerId), [contractItems]);
  const {isOfferListLoading, offerList, offerListError} = useOfferList(offerIds);

  return useMemo(() => {
    const {
      currency,
      discountPeriod,
      discountNextBillingDate,
      discountPercent,
      discountPrice,
      recurrenceTerm,
      taxTerm,
      type,
    } = retentionDetails || {};

    const isPercentageDiscount = type === 'PERCENTAGE_DISCOUNT';

    const items = contractItems?.map((item) => {
      let [product] = getProductsByOfferIds(productList, [item.offerId]);

      if (
        feature.isEnabled('bug_fix_e2e_27348') &&
        !isOfferListLoading &&
        !offerListError &&
        !product
      ) {
        product = getProductByPac(item.offerId, offerList, productList);
      }

      return {
        cancellingCount: undefined,
        cancellingTotal: undefined,
        currentCount: item.totalQuantity,
        currentTotal: item?.existingPrices?.totalWithoutTax,
        discountPercentage: isPercentageDiscount ? discountPercent : undefined,
        discountPricePerUnit: isPercentageDiscount
          ? item?.newPrices?.unitPriceWithoutTax
          : undefined,
        offerId: item.offerId,
        perUnit: FULFILLABLE_ITEM_CHARGING_UNIT.LICENSE,
        pricePerUnit: item?.existingPrices?.unitPriceWithoutTax,
        product,
        remainingCount: undefined,
        remainingTotal: undefined,
      };
    });

    const isMultiTaxRate = isMultipleTaxRate(contractItems);
    const billingData = isPercentageDiscount ? nextBilling : existingBilling;

    return {
      currency,
      items,
      recurrenceTerm,
      total: {
        afterDiscountPeriodTotal: existingBilling?.totalWithTax,
        discountDuration: discountPeriod,
        discountNextBillingDate,
        discountTotal: isPercentageDiscount ? discountPrice : undefined,
        isMultiTaxRate,
        // taxRate is not coming from either nextBilling or existingBilling and should be used from the first item of contractItems.
        taxRate: contractItems?.[0]?.existingPrices?.taxRate,
        taxTerm,
        totalDiscountPercentage: isPercentageDiscount ? discountPercent : undefined,
        totalTax: billingData?.totalTax,
        totalWithoutTax: billingData?.totalWithoutTax,
        totalWithTax: nextBilling?.totalWithTax,
      },
    };
  }, [
    retentionDetails,
    contractItems,
    nextBilling,
    existingBilling,
    productList,
    isOfferListLoading,
    offerListError,
    offerList,
  ]);
};

export default useRetentionCart;
