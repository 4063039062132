import React from 'react';

import {createRoutesForTabs} from 'common/utils/routerUtils';

import StorageWorkspace from '../components/storage-workspace/StorageWorkspace';
import IndividualUserFoldersPage from '../pages/individual-users-folders-page/IndividualUserFoldersPage';
import LibrariesPage from '../pages/libraries-page/LibrariesPage';
import LibraryDetailsPage from '../pages/library-details-page/LibraryDetailsPage';
import StorageOverviewPage from '../pages/overview-page/StorageOverviewPage';
import StorageReportsDetailsPage from '../pages/reports-details-page/StorageReportsDetailsPage';
import StorageReportsPage from '../pages/reports-page/StorageReportsPage';
import SharedProjectDetailsPage from '../pages/shared-project-details-page/SharedProjectDetailsPage';
import SharedProjectsPage from '../pages/shared-projects-page/SharedProjectsPage';

import {
  PATH_STORAGE_INDIVIDUAL_USER,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
  PATH_STORAGE_LIBRARIES,
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_REPORTS,
  PATH_STORAGE_REPORTS_SHARED,
  PATH_STORAGE_REPORTS_USERS,
  PATH_STORAGE_ROOT,
  PATH_STORAGE_SHARED_PROJECTS,
} from './storagePaths';
import {
  defaultSectionLoader,
  individualFoldersAccessCheck,
  librariesAccessCheck,
  redirectToIndividualUserFolders,
  sharedProjectsAccessCheck,
  storageAccessCheck,
} from './storageRoutesLoaders';
import {storageOverviewNavCallback, storageReportsNavCallback} from './storageRoutingUtils';

/**
 * All routes in storage
 * @returns {import('react-router-dom').RouteObject[]}
 */
const storageRoutes = () => [
  {
    children: [
      {
        children: [
          {
            children: createRoutesForTabs({
              element: <IndividualUserFoldersPage />,
              routes: [{index: true}, {path: PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED}],
            }),
            loader: individualFoldersAccessCheck,
            path: PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
          },
        ],
        loader: redirectToIndividualUserFolders,
        path: PATH_STORAGE_INDIVIDUAL_USER,
      },
      {
        element: <StorageOverviewPage navigationCallback={storageOverviewNavCallback} />,
        loader: storageAccessCheck,
        path: PATH_STORAGE_OVERVIEW,
      },
      {
        element: <StorageReportsPage navigationCallback={storageReportsNavCallback} />,
        loader: storageAccessCheck,
        path: PATH_STORAGE_REPORTS,
      },
      {
        element: (
          <StorageReportsDetailsPage
            folderType="users"
            navigationCallback={storageReportsNavCallback}
          />
        ),
        loader: storageAccessCheck,
        path: PATH_STORAGE_REPORTS_USERS,
      },
      {
        element: (
          <StorageReportsDetailsPage
            folderType="assets"
            navigationCallback={storageReportsNavCallback}
          />
        ),
        loader: storageAccessCheck,
        path: PATH_STORAGE_REPORTS_SHARED,
      },
      {
        children: [
          {
            element: <LibrariesPage />,
            index: true,
          },
          {
            element: <LibraryDetailsPage />,
            path: ':libraryId',
          },
        ],
        loader: librariesAccessCheck,
        path: PATH_STORAGE_LIBRARIES,
      },
      {
        children: [
          {
            element: <SharedProjectsPage />,
            index: true,
          },
          {
            element: <SharedProjectDetailsPage />,
            path: ':projectId',
          },
        ],
        loader: sharedProjectsAccessCheck,
        path: PATH_STORAGE_SHARED_PROJECTS,
      },
    ],
    element: <StorageWorkspace />,
    loader: defaultSectionLoader,
    path: PATH_STORAGE_ROOT,
  },
];

export default storageRoutes;
