(function () {
  angular.module('app.core.chat', [
    'app.core.support',
    'binky.core.authentication',
    'binky.core.feature',
    'binky.core.locale',
    'binky.core.lodash',
    'binky.core.organizations',
  ]);
})();
