import {useTableSectionContext} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React from 'react';

import RemoveMemberButton from 'common/components/remove-member-button/RemoveMemberButton';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import {saveProductRole} from 'common/utils/member-utils/memberProductUtils';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import LegacyProductRolePicker from 'features/products/components/legacy-product-role-picker/LegacyProductRolePicker';
import UserOperationsMenu from 'features/users/components/user-operations-menu/UserOperationsMenu';

import AddApiCredentialsButton from './add-api-credentials-button/AddApiCredentialsButton';
import AddLicensesButton from './add-licenses-button/AddLicensesButton';
import AddUsersButton from './add-users-button/AddUsersButton';
import AddUsersByCsv from './add-users-button/AddUsersByCsv';
import ExportUsersByCsv from './export-users-button/ExportUsersByCsv';

/**
 * The button group for the UserListTableSection.
 * If the app is in read-only mode the buttons will not be shown.
 * This component should be wrapped in a MemberListContextProvider.
 */
const UserListTableActions = observer(() => {
  const {tableSectionUtils} = useTableSectionContext();
  const {pageContext, store} = useMemberListContext();

  const onProductRoleChange = ({selectedId}) => {
    saveProductRole({
      pageContext,
      productUserStore: store,
      selectedItems: store.selectedItems,
      selectedProductRole: selectedId,
      tableSectionUtils,
    });
  };

  // This is wrapped by <TableActions> which is a ButtonGroup.
  // On isServerError, TableActions will disable the ButtonGroup which will disable all
  // buttons in the group that do not have an explicit isDisabled property.
  // Each button should decide if the admin has the permission for it to be shown.
  return (
    <>
      {!isReadOnly() && (
        <>
          <AddLicensesButton />
          <AddUsersByCsv />
          <AddUsersButton />
          <AddApiCredentialsButton />
          <ExportUsersByCsv />
          <RemoveMemberButton />
          {store.showMultiProductRoleSwitcher && (
            <LegacyProductRolePicker
              isDisabled={tableSectionUtils.getSelectedItemCount() === 0}
              isMultiSelect
              marginStart="size-200"
              onRoleChange={onProductRoleChange}
              roles={store.roles}
            />
          )}
        </>
      )}
      <UserOperationsMenu />
    </>
  );
});

export default UserListTableActions;
