import {EDU_SYNC_STATUSES} from 'features/settings/components/edu-sync-card/EduSyncStatus';

const DIALOGS = {
  QUEUE_SYNC_CONFIRMATION_MODAL: Symbol('queue-sync-conformation-modal'),
  REMOVE_SYNC_CONFIRMATION_MODAL: Symbol('remove-sync-conformation-modal'),
  RESUME_SYNC_CONFIRMATION_MODAL: Symbol('resume-sync-confirmation-modal'),
  STOP_SYNC_CONFIRMATION_MODAL: Symbol('stop-sync-conformation-modal'),
  SYNC_SETTINGS_MODAL: Symbol('edu-sync-settings-modal'),
};

const ACTIONS = {
  OPEN_PROVIDER_PORTAL: 'open-provider-portal',
  OPEN_SYNC_SETTINGS: 'sync-settings',
  QUEUE_SYNC: 'queue-sync',
  RESUME_SYNC: 'resume-sync',
  STOP_SYNC: 'stop-sync',
};

/**
 * @description Method to compute which actions are disabled per
 *              EDU roster sync depending on its state (status)
 */
const getDisabledActions = ({syncStatus}) => {
  switch (syncStatus) {
    case EDU_SYNC_STATUSES.IDLE:
      return [ACTIONS.STOP_SYNC];
    case EDU_SYNC_STATUSES.SCHEDULED:
      return [];
    case EDU_SYNC_STATUSES.RUNNING:
      return [ACTIONS.QUEUE_SYNC, ACTIONS.RESUME_SYNC];
    case EDU_SYNC_STATUSES.STOPPED:
      return [ACTIONS.STOP_SYNC];
    case EDU_SYNC_STATUSES.INVALID:
      return [ACTIONS.STOP_SYNC, ACTIONS.QUEUE_SYNC];
    case EDU_SYNC_STATUSES.QUEUED:
      return [ACTIONS.QUEUE_SYNC];
    default:
      return [];
  }
};

/**
 * @description Method to compute whether there are errors for the
 *              products integrations that were set up
 */
const isProductIntegrationError = ({products, integrations}) =>
  !!products.find((product) =>
    integrations?.find(
      (integration) => product.name === integration.product && !integration.deployed
    )
  );
export {ACTIONS, DIALOGS, getDisabledActions, isProductIntegrationError};
