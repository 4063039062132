/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.bulk-operations:appStatusDetailSection
 *
 * @description The Status detail section in the Operation Result details
 *   drawer.
 *
 * @binding {Job} job. Job reference to display details for.
 *
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appStatusDetailSection', {
    bindings: {
      job: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/detail-sections/status-detail-section/status-detail-section.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      if (vm.job.itemSummary && vm.job.itemSummary.averageDurationMs) {
        vm.ratePerSecond =
          vm.job.itemSummary.averageDurationMs > 1000
            ? _.round(1000 / vm.job.itemSummary.averageDurationMs, 2)
            : _.floor(1000 / vm.job.itemSummary.averageDurationMs);
      }
    }
  }
})();
