import {Flex} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {useFocusManager} from '@react-aria/focus';
import AddIcon from '@spectrum-icons/workflow/Add';
import CloseIcon from '@spectrum-icons/workflow/Close';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {openLink} from 'features/packages/adminToolsUtils';
import {
  CREATE_PACKAGE_CONSTANTS,
  KCCC_SAP_CODE,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';

import {useCreatePackageModalContext} from '../../../CreatePackageModalContext';

// ProductActionsSection contains action buttons that appear in each row of Products List
const ProductActionsSection = ({
  onAddProduct,
  onRemoveProduct,
  product,
  showAddButton,
  showLearnMoreLink,
  showRemoveButton,
}) => {
  const {createPackageModalStore} = useCreatePackageModalContext();
  const intl = useIntl();
  const focusManager = useFocusManager();

  const isProductLicenseFile = () =>
    product.sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE;

  function showAddButtonForProduct() {
    // In case of license file, need to check if license file was selected and if add/remove option visible
    if (isProductLicenseFile()) {
      return !product.isLicenseFileSelected && product.addRemoveOptionVisible && showAddButton;
    }
    // In case of other products, return showAddButton
    return showAddButton;
  }

  function showRemoveButtonForProduct() {
    // For lightweight packages we can have a condition where only KCCC is added therefore we need to show remove button
    if (
      createPackageModalStore.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
      createPackageModalStore.selectedProducts.length === 1
    ) {
      return showRemoveButton;
    }

    // In case of license file, need to check if license file was selected and if add/remove option visible
    if (isProductLicenseFile()) {
      return product.isLicenseFileSelected && product.addRemoveOptionVisible && showRemoveButton;
    }

    // In case of other products, return showRemoveButton if product is not KCCC(Creative cloud desktop application), else false is returned
    return showRemoveButton && product.sapCode !== KCCC_SAP_CODE;
  }

  function showLearnMoreLinkForProduct() {
    return showLearnMoreLink && product.localeInfoUrl;
  }

  // checks if the product is license file and returns appropriate aria labels
  const actionsAriaLabels = () =>
    isProductLicenseFile()
      ? {
          addProductAriaLabel: intl.formatMessage({
            id: 'packages.createPackage.chooseApps.appList.addLicenseFileToPackage',
          }),
          removeProductAriaLabel: intl.formatMessage({
            id: 'packages.createPackage.chooseApps.appList.removeLicenseFileFromPackage',
          }),
        }
      : {
          addProductAriaLabel: intl.formatMessage(
            {
              id: 'packages.createPackage.chooseApps.appList.addToPackage',
            },
            {productName: product.name, productVersion: product.version}
          ),
          learnMoreLinkAriaLabel: intl.formatMessage(
            {
              id: 'packages.createPackage.chooseApps.appList.viewProductDetails',
            },
            {productName: product.name, productVersion: product.version}
          ),
          removeProductAriaLabel: intl.formatMessage(
            {
              id: 'packages.createPackage.chooseApps.appList.removeFromPackage',
            },
            {productName: product.name, productVersion: product.version}
          ),
        };

  return (
    <Flex justifyContent="end" width="calc(size-900 + size-50)">
      {showLearnMoreLinkForProduct() && (
        <TooltipButton
          data-testid="open-link-button"
          hoverText={actionsAriaLabels().learnMoreLinkAriaLabel}
          isQuiet
          marginStart="size-50"
          onPress={() => openLink(product.localeInfoUrl)}
          variant="action"
        >
          <LinkOutIcon />
        </TooltipButton>
      )}
      {isProductLicenseFile() && (
        <TooltipButton hoverText={product.hoverText} isQuiet marginStart="size-50" variant="action">
          <InfoOutlineIcon size="S" />
        </TooltipButton>
      )}
      {showAddButtonForProduct() && (
        <TooltipButton
          data-testid="add-product-button"
          hoverText={actionsAriaLabels().addProductAriaLabel}
          isQuiet
          marginStart="size-50"
          onPress={() => {
            focusManager.focusNext({wrap: true});
            onAddProduct(product);
          }}
          variant="action"
        >
          <AddIcon />
        </TooltipButton>
      )}
      {showRemoveButtonForProduct() && (
        <TooltipButton
          data-testid="remove-product-button"
          hoverText={actionsAriaLabels().removeProductAriaLabel}
          isQuiet
          marginStart="size-50"
          onPress={() => {
            focusManager.focusNext({wrap: true});
            onRemoveProduct(product);
          }}
          variant="action"
        >
          <CloseIcon />
        </TooltipButton>
      )}
    </Flex>
  );
};

ProductActionsSection.propTypes = {
  /**
   * Method to add product.
   */
  onAddProduct: PropTypes.func,
  /**
   * Method to remove product.
   */
  onRemoveProduct: PropTypes.func,
  /**
   * Current product in the list
   */
  product: PropTypes.shape({
    /**
     * Indicates if add/remove option should be visible
     */
    addRemoveOptionVisible: PropTypes.bool,
    /**
     * String that appears on hovering over info icon
     */
    hoverText: PropTypes.string,
    /**
     * Indicates if license file has been selected
     */
    isLicenseFileSelected: PropTypes.bool,
    /**
     * Locale info url
     */
    localeInfoUrl: PropTypes.string,
    /**
     * Name of the product
     */
    name: PropTypes.string.isRequired,
    /**
     * Sapcode of the product
     */
    sapCode: PropTypes.string.isRequired,
    /**
     * Product's version
     */
    version: PropTypes.string,
  }).isRequired,
  /**
   * If add button should be visible.
   */
  showAddButton: PropTypes.bool.isRequired,
  /**
   * If learn more links for products should be visible.
   */
  showLearnMoreLink: PropTypes.bool.isRequired,
  /**
   * If remove button should be visible.
   */
  showRemoveButton: PropTypes.bool.isRequired,
};

export default ProductActionsSection;
