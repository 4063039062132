import {User} from '@admin-tribe/binky';
import {MemberListTable} from '@admin-tribe/binky-ui';
import {Text} from '@adobe/react-spectrum';
import {TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Table component to show a list of users with their name and emails along with the title
 * for categorizing the list of users
 */
const RemoveUsersListTable = ({descriptionKey: id, users}) => {
  const intl = useIntl();

  return (
    <>
      <Text>{intl.formatMessage({id}, {count: users.length})}</Text>
      <TableSection
        items={users}
        overflowMode="wrap"
        pageNumber={0} // disables paginator
      >
        <MemberListTable
          allowsSorting={false}
          aria-label={intl.formatMessage({id: `${id}.ariaLabel`})}
          display={{email: true}}
        />
      </TableSection>
    </>
  );
};

RemoveUsersListTable.propTypes = {
  /**
   * The description categorizing the users within the table
   */
  descriptionKey: PropTypes.string.isRequired,
  /**
   * The list of users in the table
   */
  users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
};

export default RemoveUsersListTable;
