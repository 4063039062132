/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.main.jobs:appJobResults
 *
 * @description The line-by-line job results for a bulk operation. It is reached
 *   by clicking on the job in the bulk operation results view, or the "view"
 *   button of the job details drawer.
 *
 * @binding {String} breadcrumbNavItemListId - id of the navigation list that is
 *   the root of the breadcrumbs for this page
 * @binding {String} job - reference to job to display results for
 * @binding {String} jobResult - reference to job results to display
 * @binding {PageContext} pageContext - info about page that is generating job
 *   the job results view
 */
(function () {
  angular.module('app.main.jobs').component('appJobResults', {
    bindings: {
      breadcrumbNavItemListId: '<',
      job: '<',
      jobResult: '<',
      pageContext: '<',
    },
    controller,
    templateUrl: 'app/main/jobs/results/job-results.component.html',
  });

  /* @ngInject */
  function controller($q, $translate, _, JobResult, JOB_RESULT_FILTER, toastManager, TOAST_TYPES) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      downloadResults,
      onSelectListItemSelected,
    });

    ////////

    function $onInit() {
      vm.selectListOptions = [
        {
          display: $translate.instant('main.jobs.results.selectList.options.viewAll'),
          value: JOB_RESULT_FILTER.VIEW_ALL,
        },
        {
          display: $translate.instant('main.jobs.results.selectList.options.errors'),
          value: JOB_RESULT_FILTER.ERRORS,
        },
      ];
      vm.selectListSelection = JOB_RESULT_FILTER.VIEW_ALL;
      vm.waitOnPage = $q((resolve, reject) => {
        const resourcePromises = [vm.job.$promise, vm.jobResult.$promise];
        $q.all(resourcePromises).then(onBindingsLoaded).catch(reject);

        ////////

        function onBindingsLoaded() {
          vm.lastCrumbName = vm.job.getResultHeading();
          resolve();
        }
      });
    }

    function downloadResults() {
      vm.downloadInProgress = true;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      return $q((resolve, reject) => {
        JobResult.export(vm.job.id, vm.pageContext)
          .then(onFetchCSVSuccess, onFetchCSVError)
          .finally(onFetchCSVComplete);

        ////////

        function onFetchCSVComplete() {
          vm.downloadInProgress = false;
        }

        function onFetchCSVError() {
          const message = $translate.instant('widgets.bulkOperations.download.unableToDownload');
          toastManager.showErrorToast(message);
          reject();
        }

        function onFetchCSVSuccess(filename) {
          const message = $translate.instant('widgets.bulkOperations.download.downloadSuccess', {
            filename,
          });
          toastManager.showSuccessToast(message);
          resolve();
        }
      });
    }

    function onSelectListItemSelected(value) {
      vm.selectListSelection = value;
    }
  }
})();
