(function () {
  /**
   * @deprecated should use the React Spectrum Link
   *
   * @ngdoc directive
   * @name app.widgets.common.links.tab-link.directive:appTabLink
   *
   * @description Directive to create a tab link in the application. Note:
   *   this implementation currently uses one-way binding without a watch
   *   expression, so any changes to attributes will not be detected and the
   *   tab will not change accordingly. If this behavior is desired, simply
   *   add a watch expression for the values to track and update code to
   *   regenerate tab and compile contents again. (then, update this
   *   description!)
   *
   * @param {Boolean} [disabled] - Whether this Tab is disabled. When set to
   *   true, this will prevent every user interacting with the Tab. If disabled
   *   is set to true for a selected Tab it will be deselected. Default: false
   * @param {String} [icon] - pecifies the name of the icon used inside the Tab.
   *   See Coral.Icon for valid icon names. Default: '' (no icon)
   * @param {Boolean} [invalid] - Whether the current Tab is invalid. Default:
   *   false
   * @param {Link} link - Model representing the link contained in this button.
   *   Required.
   * @param {Boolean} [selected] - Whether the Tab is selected. When true, the
   *   Tab will appear as the active element in the TabList. The Tab must be a
   *   child of a TabList before this property is set to true. This property
   *   cannot be programmatically set to false. Default: false
   * @param {String|Element} [target] - The target element that will be selected
   *   when this Tab is selected. It accepts a CSS selector or a DOM element. If
   *   a CSS Selector is provided, the first matching element will be used.
   *   Default: undefined
   */
  angular.module('app.widgets.common.links.tab-link').directive('appTabLink', appTabLinkDirective);

  function appTabLinkDirective($compile, _, translationUtils) {
    const OPTIONAL_ATTRIBUTES = ['disabled', 'icon', 'invalid', 'selected', 'target'];

    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        disabled: '<?',
        icon: '@?',
        invalid: '<?',
        link: '<',
        selected: '<?',
        target: '<?',
      },
    };

    return directive;

    /////////////////

    function linkFn(scope, element) {
      const coralTab = getCoralTab();

      element.append(coralTab);

      $compile(element.contents())(scope);

      function getCoralTab() {
        const tab = new Coral.Tab().set(
          _.assign(
            {
              label: {
                textContent: translationUtils.sanitizeSafeInstant(
                  scope.link.key,
                  scope.link.translateValues,
                  scope.link.translateInterpolation
                ),
              },
            },
            _.pick(scope, OPTIONAL_ATTRIBUTES)
          )
        );

        tab.setAttribute('ng-click', 'link.goToLink()');

        scope.link
          .isAvailable()
          .then((availability) => {
            scope.isAvailable = availability;
          })
          .catch((error) => error);
        tab.setAttribute('ng-if', 'isAvailable');

        return tab;
      }
    }
  }
})();
