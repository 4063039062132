import {Locale, authentication, csvBlobTransformer, getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

import rootStore from '../RootStore';

let clientId, url;

/**
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for the axios api
 */
const configure = (config) => {
  url = config ? config.url : '';
  clientId = config ? config.clientId : '';
};

const getStorageHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject?.token) {
    throw new Error('Unable to get token for the content platform storage API call');
  }
  return {
    'Accept-Language': Locale.get().activeLocaleCode,
    Authorization: `Bearer ${tokenObject.token}`,
    'org-id': rootStore.organizationStore.activeOrgId,
    'X-Api-Key': clientId,
  };
};

const createStorageReport = (type) =>
  axios.post(
    `${url}/content/iaqs/reports?type=${type}`,
    {},
    {
      headers: getStorageHeaders(),
    }
  );

const getContentLog = async ({orgId, path}) => {
  const response = await axios.get(`${url}/${path}`, {
    headers: {
      ...getHeaders({clientId}),
      'org-id': orgId,
      'x-user-token': authentication.getAccessToken().token,
    },
    responseType: 'blob',
  });
  return csvBlobTransformer(response).data;
};

const getStorageStats = (params) =>
  axios.get(`${url}/content/iaqs/storageStats`, {
    headers: getStorageHeaders(),
    params,
  });

const contentPlatformVa6Direct = {
  configure,
  createStorageReport,
  getContentLog,
  getStorageStats,
};

export default contentPlatformVa6Direct;
