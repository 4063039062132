(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.scorecard:appScorecardGroup
   *
   * @description the scorecard group widget, with a label, value and progress bar
   *
   * @param {Array} items. An array of ScorecardGroupItem objects.
   * @param {Boolean} [showCarouselArrows] - true if the carousel should show next and previous
   *   arrow buttons for step navigation. Optional; default is false. This binding is only
   *   applicable if the carousel is visible, i.e. if there are any items.
   */
  angular.module('app.widgets.scorecard').component('appScorecardGroup', {
    bindings: {
      items: '<',
      showCarouselArrows: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/scorecard/scorecard-group/scorecard-group.component.html',
  });

  function controller(_, appScorecardLabelFilter, feature) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      feature,
      isBusy: true,
      isProgressBarHidden,
      isProgressBarWarning,
      itemIndexScorecardLabel,
      showCarousel,
    });

    //////////////

    function $onChanges() {
      vm.isBusy = _.isUndefined(vm.items);
    }

    // If progress bar should not be shown, hide it rather than not render it so that any carousel
    // dots don't bounce up and down when switching between cards and the dots will line up across the cards
    // in the row.
    function isProgressBarHidden(item) {
      return _.isUndefined(item.total) || item.total <= 0;
    }

    function isProgressBarWarning(item) {
      return item.thresholdReached || item.percentageConsumed >= item.percentageThreshold;
    }

    function itemIndexScorecardLabel(itemIndex) {
      if (_.isArray(vm.items) && !_.isUndefined(vm.items[itemIndex])) {
        return appScorecardLabelFilter(vm.items[itemIndex]);
      }
      return undefined;
    }

    function showCarousel() {
      return !vm.isBusy && vm.items.length > 0;
    }
  }
})();
