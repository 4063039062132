(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.access-requests:appTrustRequestListTable
   * @description display list of pending trust requests for a directory
   */
  angular.module('app.widgets.access-requests').component('appTrustRequestListTable', {
    bindings: {
      accessRequestList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/access-requests/trust-request-list-table/trust-request-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    $timeout,
    _,
    ACCESS_REQUESTS_EVENT,
    JIL_CONSTANTS,
    Selection,
    SELECTION_EVENT,
    SELECTION_STATE,
    TRUST_STATUS,
    TRUST_REQUEST_LIST_PAGINATION_ID
  ) {
    const vm = this;
    const deregisterHandles = [];

    _.assign(vm, {
      $onDestroy,
      $onInit,
      isChecked,
      onClickCheck,
      onClickCheckAll,
      onPageChange,
      onPageSizeChange,
      reverseSort,
    });

    deregisterHandles.push(
      // On trustList.refresh() with PENDING status.
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(ACCESS_REQUESTS_EVENT.UPDATE, updateHeaderCheckbox)
    );

    deregisterHandles.push(
      // On selection change.
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(SELECTION_EVENT.UPDATE, updateHeaderCheckbox)
    );

    ///////////////

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    function $onInit() {
      _.assign(vm, {
        isAllChecked: false,
        isSubsetChecked: false,
        paginationId: _.uniqueId(TRUST_REQUEST_LIST_PAGINATION_ID),
        selection: new Selection('id'),
        sortReverse: false,
        wait: {},
      });
    }

    function isChecked(trust) {
      return vm.selection.isItemSelected(trust);
    }

    function onClickCheck(trust) {
      vm.selection.toggleItemSelection(trust);
    }

    function onClickCheckAll() {
      if (vm.isAllChecked) {
        vm.selection.deselectItems(vm.accessRequestList.items);
      } else {
        vm.selection.selectItems(vm.accessRequestList.items);
      }
    }

    function onPageChange(newPage) {
      vm.accessRequestList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.accessRequestList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.accessRequestList.setPage(0);
      refreshData();
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.accessRequestList.sortBy(JIL_CONSTANTS.SORT.NAME, vm.sortReverse);
      refreshData();
    }

    ///////////////

    function refreshData() {
      vm.accessRequestList.refresh({status: TRUST_STATUS.PENDING}).finally(() => {
        updateHeaderCheckbox();
      });
    }

    function updateHeaderCheckbox() {
      if (_.get(vm, 'accessRequestList.items.length', 0) > 0) {
        const selectionState = vm.selection.getSelectionStateForItems(vm.accessRequestList.items);
        vm.isAllChecked = selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
