import {ModalDialog} from '@admin-tribe/binky-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  Provider,
  useDialogContainer,
} from '@adobe/react-spectrum';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import AllowEditingSection from 'features/settings/components/sync-settings-modal/AllowEditingSection';
import EditSyncSection from 'features/settings/components/sync-settings-modal/EditSyncSection';
import RemoveSyncSection from 'features/settings/components/sync-settings-modal/RemoveSyncSection';
import SyncStatusSection from 'features/settings/components/sync-settings-modal/SyncStatusSection';

import './SyncSettingsModal.pcss';

const SyncSettingsModal = observer(({clearCaches, isProcessing}) => {
  const dialog = useDialogContainer();
  const modalHeadingId = useId();

  const handleSyncSettingsModalClose = () => {
    dialog.dismiss();
  };

  return (
    <ModalDialog height="100vh" id="sync-settings-modal">
      <Heading id={modalHeadingId}>
        <FormattedMessage id="settings.sync.syncSettingsModal.title" />
      </Heading>
      <Divider />
      <Content>
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            <div styleName="dialog-section">
              <AllowEditingSection clearCaches={clearCaches} />
            </div>

            <Divider size="S" />

            <div styleName="dialog-section">
              <SyncStatusSection />
            </div>

            <Divider size="S" />
            <div styleName="dialog-section">
              <EditSyncSection />
            </div>

            <Divider size="S" />

            <div styleName="dialog-section">
              <RemoveSyncSection
                clearCaches={clearCaches}
                onSyncRemoved={handleSyncSettingsModalClose}
              />
            </div>
          </Provider>
        </Provider2>
      </Content>
      <ButtonGroup>
        <Button
          data-test-id="close-btn"
          isDisabled={isProcessing}
          onPress={handleSyncSettingsModalClose}
          variant="primary"
        >
          <FormattedMessage id="common.modal.buttons.close" />
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
});

SyncSettingsModal.propTypes = {
  clearCaches: PropTypes.func,
  isProcessing: PropTypes.bool,
};

export default SyncSettingsModal;
