(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appExpertSessionForm
   *
   * @description The form for the Request Expert Session modal.
   */
  angular.module('app.widgets.support').component('appExpertSessionForm', {
    bindings: {
      adminTopics: '<?', // required if caseType is ADMIN
      caseType: '<',
      onFormChange: '&',
      onFormError: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/request-expert-session-modal/expert-session-form/expert-session-form.component.html',
  });

  function controller(
    _,
    auth,
    feature,
    EXPERT_TITLE_CODE,
    moment,
    REQUEST_EXPERT_SESSION_FETCH_TOPICS_ERROR,
    SUPPORT_TICKET_CASE_TYPE
  ) {
    const vm = this;

    let isFormInvalid = true;

    _.assign(vm, {
      $doCheck,
      $onInit,
      isCaseTypeProduct,
      onDescriptionChange,
      onFetchTopicsError,
      onPhoneNumberChange,
      onProductSelected,
      onSessionTimeChange,
      onSessionTopicChange,
      onTimezoneChange,
      onTitleChange,
      showTitleSection,
    });

    // Triggered for every digest cycle of the component.
    function $doCheck() {
      checkFormValidity();
    }

    function $onInit() {
      vm.introKey = isCaseTypeProduct()
        ? 'widgets.support.expertSessionForm.introProduct'
        : 'widgets.support.expertSessionForm.introAdmin';

      initForm();
    }

    function onDescriptionChange(description) {
      vm.expertSession.description = description;
    }

    function onFetchTopicsError() {
      showFetchTopicsFormError();
    }

    function onProductSelected(fulfillableItem) {
      if (fulfillableItem) {
        vm.expertSession.product = {
          fulfillableItemCode: fulfillableItem.code,
          name: fulfillableItem.longName,
        };
      } else {
        // Product is removed with the 'X'.
        vm.expertSession.product = undefined;
        hideFormError();
      }
    }

    function onPhoneNumberChange(phoneNumber) {
      vm.expertSession.phone = phoneNumber;
    }

    function onTitleChange(title) {
      vm.expertSession.title = title;
    }

    // If date is not set or invalid it will be null.
    function onSessionTimeChange(index, valueAsDate) {
      const timeKey = `proposedTime${index + 1}`;
      // MILO api accepts proposed time in format - YYYY-MM-DDTHH:mm:ss (e.g 2021-06-05T18:14:36)
      vm.expertSession[timeKey] = valueAsDate
        ? moment(valueAsDate).format('YYYY-MM-DDTHH:mm:ss')
        : valueAsDate;
    }

    function onSessionTopicChange(expertTitleCode) {
      vm.expertSession.title = undefined;
      vm.expertSession.expertTitleCode = expertTitleCode;
    }

    function onTimezoneChange(timezone) {
      vm.expertSession.timezone = timezone;
    }

    function showTitleSection() {
      return vm.expertSession.expertTitleCode === EXPERT_TITLE_CODE.OTHER;
    }

    //////////////

    // False, until 3 valid times have been entered.
    // Currently this does not detect duplicate proposedTimes.
    function areSessionTimesInvalid() {
      return !_.every(getProposedTimes());
    }

    function getProposedTimes() {
      return [
        vm.expertSession.proposedTime1,
        vm.expertSession.proposedTime2,
        vm.expertSession.proposedTime3,
      ];
    }

    function checkFormValidity() {
      const isInvalid =
        areSessionTimesInvalid() ||
        isDescriptionInvalid() ||
        isProductInvalid() ||
        isPhoneNumberInvalid() ||
        isTitleInvalid() ||
        isSessionTopicInvalid() ||
        isTimezoneInvalid();

      if (!isInvalid || isInvalid !== isFormInvalid) {
        isFormInvalid = isInvalid;
        vm.onFormChange({event: {expertSession: vm.expertSession, isInvalid}});
      }
    }

    function hideFormError() {
      vm.onFormError({
        event: {
          showMessage: false,
        },
      });
    }

    function initForm() {
      const userProfile = auth.getUserProfile();

      vm.expertSession = {
        email: userProfile.email,
        phone: userProfile.phoneNumber, // API field is 'phone' not 'phoneNumber',
      };
    }

    function isCaseTypeProduct() {
      return vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT;
    }

    function isDescriptionInvalid() {
      return _.isEmpty(vm.expertSession.description);
    }

    function isPhoneNumberInvalid() {
      return _.isEmpty(vm.expertSession.phone);
    }

    function isProductInvalid() {
      return isCaseTypeProduct() && _.isUndefined(vm.expertSession.product);
    }

    function isSessionTopicInvalid() {
      return !_.isNumber(vm.expertSession.expertTitleCode);
    }

    function isTitleInvalid() {
      return showTitleSection() && _.isEmpty(vm.expertSession.title);
    }

    function isTimezoneInvalid() {
      return !_.isInteger(vm.expertSession.timezone);
    }

    function showFetchTopicsFormError() {
      vm.onFormError({
        event: {
          error: REQUEST_EXPERT_SESSION_FETCH_TOPICS_ERROR,
          showMessage: true,
        },
      });
    }
  }
})();
