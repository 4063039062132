(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Event
   * @description Model for an individual Event
   */
  angular.module('app.core.saml-event').factory('SamlEvent', getSamlEventModel);

  /* @ngInject */
  function getSamlEventModel(_) {
    class SamlEvent {
      /** Constructor (Prototype function declaration) **/
      /**
       * @class
       * @description Creates a new SamlEvent for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.actorEmail A SamlEvent's actor email
       * @param {String} options.collector A SamlEvent's collector
       * @param {Boolean} options.event A SamlEvent's detail
       * @param {String} options.eventName A SamlEvent's name
       * @param {String} options.eventTime A SamlEvent's time
       * @param {String} options.eventType A SamlEvent's type
       * @param {String} options.id A SamlEvent's id
       * @param {String} options.organization The organization event belongs to
       * @param {String} options.remoteId A SamlEvent's remote ID
       * @param {String} options.targetEmail A SamlEvent's target email
       */
      constructor(options) {
        initModel(this, options);
      }

      getClientIp() {
        return _.chain(this.event.actors).find({objectType: 'Client'}).get('ipAddress', '').value();
      }

      getPerformedBy() {
        return _.chain(this.event.actors).find({objectType: 'User'}).get('displayName', '').value();
      }

      getPerformedByEmail() {
        return _.chain(this.event.actors).find({objectType: 'User'}).get('login', '').value();
      }

      getPerformedFor() {
        return _.chain(this.event.targets)
          .find({objectType: 'User'})
          .get('displayName', '')
          .value();
      }

      getPerformedForEmail() {
        return _.chain(this.event.targets).find({objectType: 'User'}).get('login', '').value();
      }

      isNormalEvent() {
        return this.eventType === 'normal';
      }

      /** Static Methods **/
      /**
       * @class
       * @description Transforms a data response Object into a new SamlEvent
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @param {String} dataTransferObject.actorEmail A SamlEvent's actor email
       * @param {String} dataTransferObject.collector A SamlEvent's collector
       * @param {Boolean} dataTransferObject.event A SamlEvent's detail
       * @param {String} dataTransferObject.eventName A SamlEvent's name
       * @param {String} dataTransferObject.eventTime A SamlEvent's time
       * @param {String} dataTransferObject.eventType A SamlEvent's type
       * @param {String} dataTransferObject.id A SamlEvent's id
       * @param {String} dataTransferObject.organization The organization event belongs to
       * @param {String} dataTransferObject.remoteId A SamlEvent's remote ID
       * @param {String} dataTransferObject.targetEmail A SamlEvent's target email
       */
      static apiResponseTransformer(dataTransferObject) {
        return new SamlEvent(dataTransferObject);
      }
    }

    /** Private Methods **/
    /**
     * @description Initializes SamlEvent data from options.
     *
     * @param {Object} model SamlEvent model Object instance to initialize
     * @param {Object} options Initialization Object (params described below)
     * @param {String} options.actorEmail A SamlEvent's actor email
     * @param {String} options.collector A SamlEvent's collector
     * @param {Boolean} options.event A SamlEvent's detail
     * @param {String} options.eventName A SamlEvent's name
     * @param {String} options.eventTime A SamlEvent's time
     * @param {String} options.eventType A SamlEvent's type
     * @param {String} options.id A SamlEvent's id
     * @param {String} options.organization The organization event belongs to
     * @param {String} options.remoteId A SamlEvent's remote ID
     * @param {String} options.targetEmail A SamlEvent's target email
     */
    function initModel(model, options = {}) {
      _.assignIn(
        model,
        _.pick(options, [
          'actorEmail',
          'collector',
          'event',
          'eventName',
          'eventTime',
          'eventType',
          'id',
          'organization',
          'remoteId',
          'targetEmail',
        ])
      );
    }

    /** Return reference to Constructor function **/
    return SamlEvent;
  }
})();
