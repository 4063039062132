(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectorySettingsEncryptionKeySection', {
    bindings: {
      directory: '<',
      onHidePageBanner: '&',
      onShowPageBanner: '&',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-settings/encryption-key-section/directory-settings-encryption-key-section.component.html',
  });

  /* @ngInject */
  function controller(
    $interval,
    $log,
    $q,
    _,
    feature,
    identityAccess,
    DIRECTORY_SETTINGS_CONFIRMATION_MODAL_ID,
    DIRECTORY_ENCRYPTION_PROGRESS,
    panelManager
  ) {
    const vm = this;
    const namespace = 'widgets.directories.directorySettingsModal.encryptionKeySection';

    let intervalPromise;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      onClickButton,
      toggleEncryption,
    });

    /////////////////

    _.assign(vm, {
      confirmModalId: DIRECTORY_SETTINGS_CONFIRMATION_MODAL_ID,
      showButton: false,
      waitPromise: $q.resolve(),
    });

    function $onChanges() {
      updateSection();
    }

    function $onInit() {
      vm.canManageIdentityConfig = identityAccess.canChangeIdentityConfig();
      if (!vm.canManageIdentityConfig) {
        vm.tooltipContentString = 'core.globalAdmin.disabledActionTooltip';
      }
    }

    function $onDestroy() {
      if (intervalPromise) {
        $interval.cancel(intervalPromise);
      }
    }

    function onClickButton() {
      const opKey = vm.directory.isEncryptionEnabled() ? 'revoke' : 'enable';

      vm.confirmButtonLabelKey = `${namespace}.${opKey}.modalButton`;
      vm.confirmModalBodyParagraphs = [
        `${namespace}.${opKey}.modalBody.paragraph1`,
        `${namespace}.${opKey}.modalBody.paragraph2`,
      ];
      vm.confirmTitleKey = `${namespace}.${opKey}.modalTitle`;

      hidePageBanner();
      panelManager.open(vm.confirmModalId);
    }

    ////////////

    function hidePageBanner() {
      vm.onHidePageBanner();
    }

    function refreshEncryptedStatusInfo() {
      intervalPromise = $interval(
        (cnt) => {
          vm.directory
            .refreshEncryptedStatusInfo()
            .then(() => {
              intervalFn(cnt);
            })
            .catch(() => {
              $interval.cancel(intervalPromise);
              _.set(
                vm.directory,
                'encryptionStatusInfo.encryptionProgressStatus',
                DIRECTORY_ENCRYPTION_PROGRESS.FAILED
              );
              updateSection();
            });
        },
        10000, // milliseconds per retry - 10 seconds
        20 // max number of retries
      );

      // If encryption in progress, check encryption status every 10 seconds x 20.
      // It stops checking as soon as encryption is no longer in progress.
      function intervalFn(cnt) {
        $log.debug('refreshEncryptedStatusInfo', cnt, vm.directory.encryptionStatusInfo);
        if (vm.directory.isEncryptionInProgress()) {
          updateStrings();
        } else {
          $interval.cancel(intervalPromise);
          updateSection();
          if (vm.directory.isEncryptionEnabled()) {
            showEnabledPageBanner();
          }
        }
      }
    }

    function showEnabledPageBanner() {
      const options = {
        message: 'enable.message',
        variant: 'success',
      };
      vm.onShowPageBanner({options});
    }

    function showErrorPageBanner() {
      const options = {
        message: 'error.message',
        variant: 'error',
      };
      vm.onShowPageBanner({options});
    }

    function toggleEncryption() {
      panelManager.close(vm.confirmModalId);

      vm.isBusyEnablingEncryption = !vm.directory.isEncryptionEnabled();

      vm.waitPromise = vm.directory.isEncryptionEnabled()
        ? vm.directory.disableEncryption()
        : vm.directory.enableEncryption();

      vm.waitPromise.finally(updateSection);
    }

    // Called when modal is opened and after an encryption operation is performed.
    function updateSection() {
      updateStrings();

      if (
        vm.isBusyEnablingEncryption &&
        vm.directory.isEncryptionCompleted() &&
        vm.directory.isEncryptionEnabled()
      ) {
        showEnabledPageBanner();
      } else if (vm.directory.hasEncryptionFailed()) {
        showErrorPageBanner();
      } else if (vm.directory.isEncryptionInProgress()) {
        refreshEncryptedStatusInfo();
      }

      vm.isBusyEnablingEncryption =
        vm.isBusyEnablingEncryption && vm.directory.isEncryptionInProgress();
    }

    // Cases:
    //  - encryption in progress -> button hidden and update body text with # encrypted
    //  - enabled status -> revoke button label and body text
    //  - disabled status -> enable button label and body text
    //  - revoked status -> re-enable button label and body text
    //  - anything else -> button hidden and notAvailable body text
    function updateStrings() {
      if (vm.directory.isEncryptionInProgress()) {
        // fakes don't set totalUsers.
        if (_.get(vm.directory, 'encryptionStatusInfo.totalUsers', 0) > 0) {
          vm.bodyKey = `${namespace}.pending.bodyUsers`;
        } else {
          vm.bodyKey = `${namespace}.pending.body`;
        }
        vm.showButton = false;
      } else {
        let opKey;
        vm.showButton = true;
        if (vm.directory.isEncryptionEnabled()) {
          opKey = 'revoke';
        } else if (vm.directory.isEncryptionDisabled()) {
          opKey = 'enable';
        } else if (vm.directory.isEncryptionRevoked()) {
          opKey = 'reEnable';
        } else {
          // This case is not expected to happen - here for insurance.
          opKey = 'notAvailable';
          vm.showButton = false;
        }

        if (opKey === 'enable') {
          vm.bodyKey = `${namespace}.${opKey}.body2`;
        } else {
          vm.bodyKey = `${namespace}.${opKey}.body`;
        }
        vm.buttonLabelKey = `${namespace}.${opKey}.button`;
      }
    }
  }
})();
