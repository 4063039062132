import {Contract, ProductList} from '@admin-tribe/binky';
import {BulletList, BulletListItem, GoUrl} from '@admin-tribe/binky-ui';
import {Divider, Flex, Heading, Image, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import fullCancelIcon from '../assets/CCT_heroimage_sm.svg';
import SafeFormattedDateAndTime from '../components/safe-formatted-date-and-time/SafeFormattedDateAndTime';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelCart from '../components/self-cancel-cart/SelfCancelCart';
import SelfCancelDoneModal from '../components/self-cancel-done-modal/SelfCancelDoneModal';
import useRetentionCart from '../hooks/use-retention-cart/useRetentionCart';
import useRetentionDetails from '../hooks/use-retention-details/useRetentionDetails';

import styles from './DoneFreeMonthsOfferStep.pcss';

/**
 * Self cancel workflow step component used as a final success confirmation when the user accepts
 * a free months save offer.
 */
const DoneFreeMonthsOfferStep = ({
  contract,
  onAddProducts,
  onDone,
  onManageUsers,
  productList,
  retentionId,
}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent, dispatchSelfCancelPageAnalytics} =
    useSelfCancelAnalyticsContext();

  const retention = useRetentionDetails({contract, retentionId});

  const {currency, discountNextBillingDate, discountPeriod: monthsCount, discountPrice} = retention;

  const {items, recurrenceTerm, total} = useRetentionCart({productList, retention});

  // Send page load analytics when component is loaded
  useEffect(() => {
    dispatchSelfCancelPageAnalytics({
      retentionId,
      step: SELF_CANCEL_STEPS.DONE_FREE_MONTHS_OFFER_STEP,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Explicitly only execute once on component load
  }, []);

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.DONE});
    onDone?.({nextBillingDate: discountNextBillingDate});
  };

  return (
    <SelfCancelDoneModal
      heading={intl.formatMessage(
        {id: 'account.selfCancel.doneFreeMonthsOffer.title'},
        {monthsCount}
      )}
      isBubbleHidden
      isManageLicensesSectionHidden
      onAddProducts={onAddProducts}
      onCta={onCta}
      onManageUsers={onManageUsers}
      showDivider
      subheading={
        <View elementType="p" marginBottom="size-0" marginTop="size-225">
          {intl.formatMessage({id: 'account.selfCancel.doneFreeMonthsOffer.subheading'})}
        </View>
      }
      // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- required more lines for this block
      successMessage={intl.formatMessage(
        {id: 'account.selfCancel.doneFreeMonthsOffer.description'},
        {
          price: () => (
            <SafePrice
              currency={currency}
              overrideStyles={styles}
              price={discountPrice}
              recurrenceTerm={recurrenceTerm}
            />
          ),
        }
      )}
    >
      <Flex gap="size-300">
        <View flexGrow="1">
          <Heading
            level={3}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.heading}
          >
            {intl.formatMessage({
              id: 'account.selfCancel.doneFreeMonthsOffer.reviewDetailsList.title',
            })}
          </Heading>
          <BulletList>
            <BulletListItem data-testid="offer-start-bullet-point">
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- inlining string localization for clarity */}
              {intl.formatMessage(
                {
                  id: 'account.selfCancel.doneFreeMonthsOffer.reviewDetailsList.offerStart',
                },
                {
                  b: (text) => <span styleName="bolded-bullet">{text}</span>,
                  billingResumeDate: () => (
                    <SafeFormattedDateAndTime value={discountNextBillingDate} />
                  ),
                  monthsCount,
                }
              )}
            </BulletListItem>
            <BulletListItem data-testid="offer-applied-bullet-point">
              {intl.formatMessage(
                {
                  id: 'account.selfCancel.doneFreeMonthsOffer.reviewDetailsList.offerApplied',
                },
                {
                  b: (text) => <span styleName="bolded-bullet">{text}</span>,
                  monthsCount,
                }
              )}
            </BulletListItem>
            <BulletListItem data-testid="still-charged-bullet-point">
              {intl.formatMessage(
                {id: 'account.selfCancel.doneFreeMonthsOffer.reviewDetailsList.stillCharged'},
                {b: (text) => <span styleName="bolded-bullet">{text}</span>}
              )}
            </BulletListItem>
          </BulletList>
          <Divider marginY="size-350" size="S" />
          <Heading
            level={3}
            marginTop="size-0"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.heading}
          >
            {intl.formatMessage({
              id: 'account.selfCancel.doneFreeMonthsOffer.enjoyTeams.title',
            })}
          </Heading>
          <BulletList>
            <BulletListItem data-testid="bottom-content-bullet-point">
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- this block requires more lines */}
              {intl.formatMessage(
                {
                  id: 'account.selfCancel.doneFreeMonthsOffer.enjoyTeams.description',
                },
                {
                  goUrl: (linkText) => (
                    <GoUrl name="go_url_for_creativecloud_business_teams" noWrap={false}>
                      {linkText}
                    </GoUrl>
                  ),
                }
              )}
            </BulletListItem>
          </BulletList>
          <Flex marginTop="size-200">
            <Image alt="" src={fullCancelIcon} />
          </Flex>
        </View>
        <View flexShrink="0" width="340px">
          <Heading
            level={3}
            marginBottom="size-150"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.heading}
          >
            {intl.formatMessage({
              id: 'account.selfCancel.components.selfCancelCart.title',
            })}
          </Heading>
          <SelfCancelCart
            currency={currency}
            items={items}
            recurrenceTerm={recurrenceTerm}
            total={total}
          />
        </View>
      </Flex>
    </SelfCancelDoneModal>
  );
};

DoneFreeMonthsOfferStep.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Handler that is called when the user clicks on Add Products link.
   */
  onAddProducts: PropTypes.func,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onDone: PropTypes.func,
  /**
   * Handler that is called when the user clicks on Manage Users.
   */
  onManageUsers: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The retention id for the Save Offer, used by the component to look it up in ProductsChange
   * response.
   */
  retentionId: PropTypes.string.isRequired,
};

export default DoneFreeMonthsOfferStep;
