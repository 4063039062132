import {EVENT_ACTION} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import Pagination from 'common/components/table/Pagination';
import {usePagination} from 'common/components/table/Table';
import TableConstants from 'common/components/table/TableConstants';

import {dispatchEvent} from '../../models/notificationUtils';
import {ANALYTIC_EVENT, DISMISS_CARD} from '../../notifications.constants';

import Card from './NotificationCard';
import './NotificationCardList.pcss';

const toastManager = binkyUI.shell.toastManager;

const NotificationCardList = ({
  notificationList,
  onChangeNotification,
  onClickButton,
  onUpdatePagination,
}) => {
  const intl = useIntl();

  const footerStyling = 'footer-ported';
  const multiplePageContainerStyle = 'card-list-container-multiple-pages-ported';
  const notificationItemStyle = 'notification-item-ported';

  const pagination = usePagination({
    defaultPageSize: TableConstants.PAGE_SIZES[0].value,
    totalItems: notificationList.getForActiveOrg().length,
  });

  const focusSibling = (cardRef) => {
    const parent = cardRef.current.parentElement;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- jwu@ to update
    /* istanbul ignore else -- no action in else block */
    if (parent.nextSibling) {
      parent.nextSibling.lastChild.focus();
    } else if (parent.previousSibling) {
      parent.previousSibling.lastChild.focus();
    }
  };

  const updatePagination = () => {
    const totalItems = notificationList.getForActiveOrg().length;
    pagination.setTotalItems(totalItems);
    if (
      pagination.totalPages !== Math.ceil(totalItems / pagination.pageSize) &&
      pagination.currentPage === pagination.totalPages
    ) {
      // go to previous page if total pages is decreased and user is in the last page
      pagination.setCurrentPage(pagination.currentPage - 1);
      onUpdatePagination();
    }
  };

  const onChange = async ({action, cardRef, id}) => {
    try {
      const promise =
        action === DISMISS_CARD ? notificationList.delete([id]) : notificationList.save([id]);
      onChangeNotification(promise);
      await promise;
      if (action === DISMISS_CARD) {
        // focus sibling card to avoid resetting tab order
        focusSibling(cardRef);
        updatePagination();
      }
    } catch {
      toastManager.showError(intl.formatMessage({id: 'common.toast.default.error'}));
    }
  };

  const onGoNext = () => {
    pagination.setCurrentPage(pagination.currentPage + 1);
    dispatchEvent({
      action: EVENT_ACTION.NEXT,
      name: ANALYTIC_EVENT.NAME.DRAWER,
    });
  };

  const onGoPrevious = () => {
    pagination.setCurrentPage(pagination.currentPage - 1);
    dispatchEvent({
      action: EVENT_ACTION.PREV,
      name: ANALYTIC_EVENT.NAME.DRAWER,
    });
  };

  const cardListContainerStyleName =
    pagination.totalPages > 1 ? multiplePageContainerStyle : 'card-list-container-single-page';

  // This side effect is to track when the component loads to trigger analytics
  useEffect(() => {
    dispatchEvent({
      action: EVENT_ACTION.LOADED,
      name: ANALYTIC_EVENT.NAME.LIST,
      notificationList: notificationList.getPaginatedItems(pagination.currentPage),
    });
  }, [notificationList, pagination.currentPage]);

  return (
    <div data-testid="notification-card-list">
      <ol styleName={cardListContainerStyleName}>
        {notificationList.getPaginatedItems(pagination.currentPage).map((notification) => (
          <li key={notification.id} styleName={notificationItemStyle}>
            <Card notification={notification} onChange={onChange} onClickButton={onClickButton} />
          </li>
        ))}
      </ol>
      {pagination.totalPages > 1 && (
        <div styleName={footerStyling}>
          <div styleName="pagination-container">
            <Pagination
              currentPage={pagination.currentPage}
              defaultPageSize={pagination.pageSize}
              onGoNext={onGoNext}
              onGoPrevious={onGoPrevious}
              showPageSizeSelector={false}
              totalPages={pagination.totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
};

NotificationCardList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - notificationList
  notificationList: PropTypes.object.isRequired,
  onChangeNotification: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  onUpdatePagination: PropTypes.func.isRequired,
};

export default NotificationCardList;
