(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name identityAccess
   * @description identity-related access checks
   */
  angular.module('app.core.identity').factory('identityAccess', IdentityAccess);

  /* @ngInject */
  function IdentityAccess($q, auth, directoryAccess, GLOBAL_ADMIN_POLICY, OrganizationManager) {
    const service = {
      canChangeIdentityConfig,
      canClaimDomainsConfig,
      canViewIdentity,
    };

    return service;

    //////////////

    function canClaimDomainsConfig() {
      return OrganizationManager.getPolicyValue(GLOBAL_ADMIN_POLICY.CLAIM_DOMAINS);
    }

    function canChangeIdentityConfig() {
      return OrganizationManager.getPolicyValue(GLOBAL_ADMIN_POLICY.CHANGE_IDENTITY_CONFIG);
    }

    function canViewIdentity() {
      // There's no need to delay the checks because the child routes will need to have them resolved too.
      return auth.isUserOrgAdmin()
        ? $q
            .all({
              canViewDirectories: directoryAccess.canViewDirectories(),
              canViewDomains: directoryAccess.canViewDomains(),
            })
            .then((resolved) => resolved.canViewDirectories || resolved.canViewDomains)
        : directoryAccess.canViewDirectories();
    }
  }
})();
