/**
 * @deprecated use src2 Support pages
 *
 * @description a filter to display the status string for a support ticket
 * @param {Object} supportTicket a support ticket
 * @returns {String} status string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportTicketStatus', appSupportTicketStatusFilter);

  /* @ngInject */
  function appSupportTicketStatusFilter($translate) {
    return getStatusLabel;

    function getStatusLabel(supportTicket) {
      const {stringKey} = supportTicket.getUiStatus();
      return $translate.instant(`widgets.support.supportTicketStatusFilter.${stringKey}`);
    }
  }
})();
