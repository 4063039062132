import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/* basic component to display an icon with text */
const BulkOperationIconDetail = ({ariaLabel, children, Icon, color}) => (
  <Flex alignItems="center">
    <Icon aria-label={ariaLabel} color={color} flexShrink="0" marginEnd="size-100" size="S" />
    {/* when negative color is set, match the color to the icon */}
    <Text
      UNSAFE_style={{
        color: color === 'negative' && 'var(--spectrum-semantic-negative-color-default)',
        overflowWrap: 'break-word',
      }}
      width="90%"
    >
      {children}
    </Text>
  </Flex>
);

BulkOperationIconDetail.propTypes = {
  /**
   * the aria label for the icon
   */
  ariaLabel: PropTypes.string,
  /**
   * child nodes to display next to the icon
   */
  children: PropTypes.node,
  /**
   * spectrum icon color variant, when negative match the text color, when undefined, use default spectrum color (grey)
   */
  color: PropTypes.oneOf(['negative', 'positive']),
  /**
   * icon to display
   */
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired, // Some spectrum icons don't register as react nodes, but as functions
};

export default BulkOperationIconDetail;
