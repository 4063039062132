import {Button} from '@adobe/react-spectrum';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canCreateNewProductProfile} from 'core/products/access/productProfileAccess';
import AddProductProfileModal from 'features/products/product-profiles/add-product-profile-modal/AddProductProfileModal';
import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

/**
 * The Product profile table 'New profile' button.
 */
const NewProfileButton = () => {
  const intl = useIntl();
  const {product, onTableSectionChange} = useProductProfileTableContext();
  const [isOpen, setIsOpen] = useState(false);
  const orgId = rootStore.organizationStore.activeOrgId;

  function onSave() {
    setIsOpen(false);

    // Re-get the list with the current settings. Any search and selection should remain.
    onTableSectionChange({
      action: TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED,
    });
  }

  return (
    canCreateNewProductProfile(product) && (
      <>
        <Button data-testid="new-profile-btn" onPress={() => setIsOpen(true)} variant="accent">
          {intl.formatMessage({id: 'products.productProfileTable.buttons.newProfile'})}
        </Button>
        {isOpen && (
          <AddProductProfileModal
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            onSave={onSave}
            orgId={orgId}
            product={product}
          />
        )}
      </>
    )
  );
};

export default NewProfileButton;
