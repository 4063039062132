(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    _,
    ACCOUNT_STATE_PARAM,
    APP_AUTHENTICATING_USER_MODAL,
    auth,
    contractAccess,
    feature,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    INVOICE_STATUS,
    InvoiceList,
    OrganizationManager,
    panelManager
  ) {
    const oldBuildActions = $delegate.buildActionsForHVA;

    const namespace = 'overview.highValueActionArea.setUpTasks.unpaidInvoices';
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.UNPAID_INVOICES,
        })
      );
      return result;

      function accessCallback() {
        const contract = OrganizationManager.getContractsForActiveOrg().getDirectContract();

        if (
          contract &&
          contract.isContractOwner({checkAuthUser: true}) &&
          contract.isPaymentCategoryPO() &&
          !contract.isComplianceStatusGracePastDue() &&
          !contract.isComplianceStatusPastDue()
        ) {
          return InvoiceList.get({
            invoiceStatuses: [INVOICE_STATUS.PAYMENT_DUE, INVOICE_STATUS.PAST_DUE],
          }).$promise.then((unpaidInvoices) => {
            const unpaidInvoicesListItems = unpaidInvoices.items;

            if (unpaidInvoicesListItems.length > 0) {
              const unpaidInvoiceCardParams = {
                coralIcon: 'creditCard',
                description: $translate.instant(`${namespace}.description`),
                header: $translate.instant(`${namespace}.header`),
                id: 'hva-unpaid-invoices',
                isDismissible: true,
                sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
              };

              _.assign(unpaidInvoiceCardParams, getCTAParams(contract, unpaidInvoicesListItems));
              return [new HvaCard(unpaidInvoiceCardParams)];
            }
            return $q.reject();
          });
        }

        return $q.reject();
      }
    }

    function getCTAParams(contract, unpaidInvoices) {
      const hasMultipleUnpaidLicenses = unpaidInvoices.length > 1;
      const ctaLabel = $translate.instant(
        `${namespace}.messageFormat.ctaLabel`,
        {
          count: unpaidInvoices.length,
        },
        'messageformat'
      );

      return {
        ctaLabel,
        onCTAClick: () => {
          if (contractAccess.needToSwitchAsAuthenticatingUser({contract})) {
            panelManager.open(APP_AUTHENTICATING_USER_MODAL, {
              authenticatingUserId: contract.getOwnerUserId(),
            });
          } else if (hasMultipleUnpaidLicenses) {
            $state.go('account.billing-history');
          } else {
            $state.go(
              'account.account',
              {
                [ACCOUNT_STATE_PARAM.AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING]: unpaidInvoices[0].id,
              },
              {
                inherit: true,
              }
            );
          }
        },
      };
    }
  }
})();
