(function () {
  /**
   * @deprecated moved to src2 as <SupportTicketActionSection>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketActionsSection
   * @description renders various action buttons like Close case/Escalate case/Reopen case/Take survey
   */
  const componentName = 'appSupportTicketActionsSection';
  angular.module('app.widgets.support').component(componentName, {
    bindings: {
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-actions-section/support-ticket-actions-section.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $translate,
    $window,
    AnalyticsEvent,
    feature,
    panelManager,
    ESCALATE_SUPPORT_CASE_MODAL_ID,
    SUPPORT_CASE_CLOSE_MODAL_ID,
    SUPPORT_CASE_REOPEN_MODAL_ID
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      someButtonsVisible,
    });

    function $onInit() {
      vm.buttons = [
        {
          class: 'close-case',
          isDisabled: _.stubFalse,
          isVisible: () => vm.supportTicket.isSupportCase() && vm.supportTicket.isOpen(),
          labelKey: 'closeCase',
          onClick: () => openModal(SUPPORT_CASE_CLOSE_MODAL_ID),
          tooltip: _.constant(''),
        },
        {
          class: 'escalate-case',
          isDisabled: () => !vm.supportTicket.isEscalationAllowed,
          isVisible: () => vm.supportTicket.isSupportCase() && vm.supportTicket.isOpen(),
          labelKey: 'escalateCase',
          onClick: () => openModal(ESCALATE_SUPPORT_CASE_MODAL_ID),
          tooltip: () => {
            if (vm.supportTicket.isEscalated) {
              return getTooltip('alreadyEscalated');
            }

            return vm.supportTicket.isEscalationAllowed ? null : getTooltip('recentCreated');
          },
        },
        {
          class: 'reopen-case',
          isDisabled: () => !vm.supportTicket.canReopen,
          isVisible: () => vm.supportTicket.isSupportCase() && vm.supportTicket.isClosed(),
          labelKey: 'reopenCase',
          onClick: () => openModal(SUPPORT_CASE_REOPEN_MODAL_ID),
          tooltip: () => {
            if (vm.supportTicket.canReopen) {
              return getTooltip('canReopen');
            }
            return getTooltip('cannotReopen');
          },
        },
        {
          class: 'take-survey',
          isDisabled: _.stubFalse,
          isVisible: () => vm.supportTicket.canTakeSurvey(),
          labelKey: 'takeSurvey',
          onClick: () => {
            $window.open(vm.supportTicket.surveyUrl, '_blank');
            triggerAnalytics('takeSurveyButton');
          },
          tooltip: _.constant(''),
        },
      ];
    }

    function someButtonsVisible() {
      return _.some(vm.buttons, (button) => button.isVisible());
    }

    ///////////////

    function getTooltip(key) {
      return $translate.instant(`widgets.support.supportTicketActionsSection.tooltips.${key}`);
    }

    function openModal(modalId) {
      panelManager.open(modalId);
    }

    function triggerAnalytics(componentMethod) {
      AnalyticsEvent.dispatch({
        componentMethod,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
