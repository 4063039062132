(function () {
  /**
   * @ngdoc module
   * @name app.core.jobs
   * @description Module which defines the Jobs model(s)
   */
  angular.module('app.core.jobs', [
    'angularMoment',
    'app.core.api.jil',
    'app.core.message',
    'app.core.organizations',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.download-utils',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.core.translation-utils',
    'pascalprecht.translate',
  ]);
})();
