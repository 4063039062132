(function () {
  /**
   * @deprecated - Please use src2 useBulkOperationSamples
   *
   * @description Returns list of products that should be shown in the Products section of the Account page.
   * @param {Array} products. Array of Products.
   * @returns {Object} Array of Products should be shown in the Products section.
   */
  angular
    .module('app.widgets.bulk-operations.samples')
    .factory('bulkOperationSamples', getBulkOperationSamplesService);

  /* @ngInject */
  function getBulkOperationSamplesService(
    $q,
    _,
    BULK_OPERATION_CSV,
    BULK_OPERATION_CSV_HEADER,
    BULK_OPERATION_MODE,
    ConfigurationMemberRoles,
    OrganizationManager,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    ProductList,
    t2eMigrationManager
  ) {
    const service = {
      getSample,
    };

    return service;

    ////////////

    /**
     * @deprecated - Please use src2 useBulkOperationSamples
     *
     * @description Method to return sample data for the bulk operation context.
     *
     * @param {string} mode The bulk operation mode to initialize for.
     * @param {PageContext} pageContext The page context for this modal, containing type, target, etc.
     * @param {boolean} restrictAddingT1 Whether we should discourage adding T1 users in our samples
     * @returns {Object} an object with the configured context
     */
    function getSample(mode, pageContext, restrictAddingT1) {
      if (_.isNil(pageContext)) {
        throw new TypeError('Missing object pageContext for getSample()');
      }
      if (_.isNil(restrictAddingT1)) {
        throw new TypeError('Missing boolean restrictAddingT1 for getSample()');
      }

      // While we don't want to show T1 sample users if the restrictAddingT1 flag is true,
      // we should let T1 users appear in samples for removal actions.
      const modeIsRemovingUsers =
        mode === BULK_OPERATION_MODE.REMOVE || mode === BULK_OPERATION_MODE.UNASSIGN;
      const useSampleWithoutT1Users = restrictAddingT1 && !modeIsRemovingUsers;

      const isT2EClean = t2eMigrationManager.isT2EClean();

      const productList = ProductList.get({
        acceptLanguage: 'en-US',
        notifyListeners: false,
        orgId: OrganizationManager.getActiveOrgId(),
      });

      return processPageContextAndMode();

      function processPageContextAndMode() {
        let sampleCsvPromise;
        switch (pageContext.targetType) {
          case PAGE_TARGET_TYPE.USER:
            switch (pageContext.target) {
              case PAGE_TARGET.ORGANIZATION:
                sampleCsvPromise = getOrgUsersSampleCSVPromise();
                break;
              case PAGE_TARGET.USER_GROUP:
                sampleCsvPromise = $q.resolve(getUserGroupUsersSampleCSV());
                break;
              case PAGE_TARGET.PRODUCT_CONFIGURATION:
                sampleCsvPromise = getProfileUsersSampleCSVPromise();
                break;
              default:
                // other targets are not yet supported
                break;
            }
            break;
          case PAGE_TARGET_TYPE.USER_GROUP:
            sampleCsvPromise = $q.resolve(getUserGroupSampleCSV());
            break;
          default:
            // other target types are not yet supported
            break;
        }

        if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE) {
          // This sample overrides the value set earlier by PageContext
          sampleCsvPromise = $q.resolve(getSwitchIdentityTypeSampleCSV());
        }

        return sampleCsvPromise || $q.resolve();
      }

      /////////////////////////////

      function getOrgUsersSampleCSV() {
        if (productList.hasOnlyTeamProducts()) {
          const headers = BULK_OPERATION_CSV_HEADER.ORG_USERS_TEAM;

          let users = '';
          _.forEach(productList.items, (product, index) => {
            users += `${getOrgUsersTeamSampleRow(product, index + 1)}\n`;
          });
          return `${headers}\n${users}`;
        }

        // else
        if (useSampleWithoutT1Users) {
          return BULK_OPERATION_CSV.ORG_USERS_RESTRICT_T1;
        }
        return isT2EClean ? BULK_OPERATION_CSV.ORG_USERS_T2E : BULK_OPERATION_CSV.ORG_USERS;
      }

      function getOrgUsersSampleCSVPromise() {
        return productList.$promise.then(() => getOrgUsersSampleCSV());
      }

      function getOrgUsersTeamSampleRow(product, index) {
        return _.join([`user${index}@my-domain.com`, 'Jane', 'Doe', '', '', product.longName], ',');
      }

      function getUserGroupUsersSampleCSV() {
        if (useSampleWithoutT1Users) {
          return BULK_OPERATION_CSV.USER_GROUP_USERS_RESTRICT_T1;
        }
        return isT2EClean
          ? BULK_OPERATION_CSV.USER_GROUP_USERS_T2E
          : BULK_OPERATION_CSV.USER_GROUP_USERS;
      }

      function getProfileUsersSampleCSVPromise() {
        let promise = $q.resolve();
        const product = _.find(productList.items, {id: pageContext.targetParentId});
        if (product && product.hasConfigurationSettingForLicenseGroupMember()) {
          const profileId = pageContext.targetId;
          const memberRoles = ConfigurationMemberRoles.get(product.id, profileId, []);
          promise = memberRoles.$promise.then(() => _.sortBy(memberRoles.availableRoles, ['name']));
        }
        return promise.then((roles) => {
          let csvSample;
          const hasRoles = !_.isEmpty(roles);
          if (useSampleWithoutT1Users) {
            csvSample = BULK_OPERATION_CSV.PROFILE_USERS_RESTRICT_T1;
          } else if (isT2EClean) {
            csvSample = BULK_OPERATION_CSV.PROFILE_USERS_T2E;
          } else {
            csvSample = BULK_OPERATION_CSV.PROFILE_USERS;
          }
          if (hasRoles) {
            return _.replace(
              csvSample.WITH_ROLES,
              new RegExp('{PRODUCT_ROLE}', 'g'),
              roles[0].name
            );
          }
          return csvSample.WITHOUT_ROLES;
        });
      }

      function getUserGroupSampleCSV() {
        if (useSampleWithoutT1Users) {
          return BULK_OPERATION_CSV.USER_GROUP_RESTRICT_T1;
        }
        return isT2EClean ? BULK_OPERATION_CSV.USER_GROUP_T2E : BULK_OPERATION_CSV.USER_GROUP;
      }

      function getSwitchIdentityTypeSampleCSV() {
        if (isT2EClean) {
          return BULK_OPERATION_CSV.SWITCH_IDENTITY_TYPE_T2E;
        }
        return useSampleWithoutT1Users
          ? BULK_OPERATION_CSV.SWITCH_IDENTITY_TYPE_RESTRICT_T1
          : BULK_OPERATION_CSV.SWITCH_IDENTITY_TYPE;
      }
    }
  }
})();
