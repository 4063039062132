(function () {
  /**
   * @ngdoc module
   * @name app.core.api.content-platform
   * @description module that defines content-platform api resource services
   */
  angular.module('app.core.api.content-platform', [
    'app.core.api',
    'binky.core.api.utils',
    'ngResource',
  ]);
})();
