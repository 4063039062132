import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext} from '@admin-tribe/binky';
import React from 'react';
import {generatePath, matchPath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canShowAdmins, canViewTransactions, canViewUsers} from 'core/products/access/productAccess';
import {canViewProductProfiles} from 'core/products/access/productProfileAccess';
import ProductAdminsSubpage from 'features/products/pages/product-admins-subpage/ProductAdminsSubpage';
import ProductProfilesSubpage from 'features/products/pages/product-profiles-subpage/ProductProfilesSubpage';
import ProductTransactionsSubpage from 'features/products/pages/product-transactions-subpage/ProductTransactionsSubpage';
import ProductUsersSubpage from 'features/products/pages/product-users-subpage/ProductUsersSubpage';
import {
  PATH_PRODUCT_DETAILS_ADMINS,
  PATH_PRODUCT_DETAILS_PROFILES,
  PATH_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_PRODUCT_DETAILS_USERS,
} from 'features/products/routing/productsPaths';

const PRODUCT_DETAILS_TABS_KEYS = {
  ADMINS: 'admins',
  PROFILES: 'profiles',
  TRANSACTIONS: 'transactions',
  USERS: 'users',
};

function getProfilesTab({intl, product}) {
  return {
    content: (
      <ProductProfilesSubpage
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT,
            targetId: product.id,
            targetType: PAGE_TARGET_TYPE.PRODUCT_CONFIGURATION,
          })
        }
        product={product}
      />
    ),
    key: PRODUCT_DETAILS_TABS_KEYS.PROFILES,
    name: intl.formatMessage({id: 'products.details.tab.productProfiles.title'}),
    pathname: generatePath(PATH_PRODUCT_DETAILS_PROFILES, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId: product.id,
    }),
  };
}

function getUsersTab({intl, product}) {
  return {
    content: (
      <ProductUsersSubpage
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT,
            targetId: product.id,
            targetType: PAGE_TARGET_TYPE.USER,
          })
        }
      />
    ),
    key: PRODUCT_DETAILS_TABS_KEYS.USERS,
    name: intl.formatMessage({id: 'products.details.tab.users.title'}),
    pathname: generatePath(PATH_PRODUCT_DETAILS_USERS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId: product.id,
    }),
  };
}

function getAdminsTab({intl, product}) {
  return {
    content: (
      <ProductAdminsSubpage
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT,
            targetId: product.id,
            targetType: PAGE_TARGET_TYPE.ADMIN,
          })
        }
      />
    ),
    key: PRODUCT_DETAILS_TABS_KEYS.ADMINS,
    name: intl.formatMessage({id: 'products.details.tab.admins.title'}),
    pathname: generatePath(PATH_PRODUCT_DETAILS_ADMINS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId: product.id,
    }),
  };
}

function getTransactionsTab({intl, product}) {
  return {
    content: <ProductTransactionsSubpage product={product} />,
    key: PRODUCT_DETAILS_TABS_KEYS.TRANSACTIONS,
    name: intl.formatMessage({id: 'products.details.tab.transactions.title'}),
    pathname: generatePath(PATH_PRODUCT_DETAILS_TRANSACTIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId: product.id,
    }),
  };
}

/**
 * Retrieve the tabs for a Product Details page.
 *
 * @param {Object} options - params for getting tabs
 * @param {Product} options.product - a Product model for the Product
 * @param {Object} options.intl - the intl object
 */
function getTabs(options) {
  const {product} = options;
  const tabs = [];

  // Product profile tab
  if (canViewProductProfiles(product)) {
    tabs.push(getProfilesTab(options));
  }

  // Users tab
  if (canViewUsers(product)) {
    tabs.push(getUsersTab(options));
  }

  // Admins tab
  if (canShowAdmins(product)) {
    tabs.push(getAdminsTab(options));
  }

  // Transactions tab
  if (canViewTransactions(product)) {
    tabs.push(getTransactionsTab(options));
  }

  return tabs;
}

/**
 * Determine the key for the Product Details Page tab based on the given path
 * @returns {string} The tab key which matches the path
 * @throws {Error} if the path does not match any of the known paths
 */
function findKeyByPath(location) {
  if (matchPath(PATH_PRODUCT_DETAILS_PROFILES, location.pathname)) {
    return PRODUCT_DETAILS_TABS_KEYS.PROFILES;
  }

  if (matchPath(PATH_PRODUCT_DETAILS_USERS, location.pathname)) {
    return PRODUCT_DETAILS_TABS_KEYS.USERS;
  }

  if (matchPath(PATH_PRODUCT_DETAILS_ADMINS, location.pathname)) {
    return PRODUCT_DETAILS_TABS_KEYS.ADMINS;
  }

  if (matchPath(PATH_PRODUCT_DETAILS_TRANSACTIONS, location.pathname)) {
    return PRODUCT_DETAILS_TABS_KEYS.TRANSACTIONS;
  }

  throw new Error('Unable to determine product details tabs key by path');
}

export {findKeyByPath, getTabs};
