import binky from '@admin-tribe/binky';
import assignIn from 'lodash/assignIn';
import pick from 'lodash/pick';

import jilCustomData from '../../api/jil/jilCustomData';

const log = binky.services.log;

// CustomData is a customizable note object. It allows
// admins to create notes about some org, product, etc.
class CustomData {
  /**
   * @description Creates a new CustomData instance.
   *
   * @param {Object} options - the options returned by the backend
   * @param {String} options.name - the name of the customData note, treated as an ID
   * @param {String} options.value - the value of the customData note
   */
  constructor(options = {}) {
    initModel(this, options);
  }

  /**
   * @description Checks if this is a new custom data.
   * @returns {Boolean} true if the custom data is new.
   */
  isNewCustomData() {
    return this.isNew;
  }

  /**
   * @description Saves the custom data value.
   * @param {String} value the new value of the custom data.
   * @returns {Promise} resolved when custom data is updated.
   */
  async save(newValue) {
    if (!!newValue && newValue !== this.value) {
      try {
        const payload = {name: this.name, value: newValue};
        if (this.isNewCustomData()) {
          await jilCustomData.postDataResource(payload);
        } else {
          await jilCustomData.putDataResource(payload);
        }
        initModel(this, {value: newValue});
      } catch (error) {
        log.error('CustomData failed to save. Error: ', error);
        throw new Error('CustomData failed to save.');
      }
    }
  }
}

/** Private Methods **/
/**
 * @description Initializes CustomData from options.
 *
 * @param {Object} customData - CustomData model Object instance to initialize. See constructor.
 * @param {Object} options - Data to initiate the model with.
 *
 * @returns {Object} the model with selected values
 */
function initModel(customData, options) {
  const model = assignIn(customData, pick(options, ['name', 'value']));
  model.isNew = !model.value;
  return model;
}

export default CustomData;
