import {formatFileSize} from '@admin-tribe/binky-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StorageStats from 'common/services/storage-stats/StorageStats';

/**
 * The right header content for the Storage usage section.
 * This is in the format {storage used} / {total storage}.
 * The text is enlarged a bit and, if isOverUsage is true, a warning color Alert icon is displayed.
 */
const UsageBarRightHeaderContent = ({storageStats}) => {
  const intl = useIntl();
  const isOverUsage = storageStats.isOverUsage();
  const usageSummary = storageStats.getUsageSummary();

  return (
    usageSummary && (
      <Flex alignItems="center" direction="row" gap="size-50">
        <Text
          // At 16px, the Spectrum notice font color 'var(--color-orange-600)' does not pass the color contrast check
          // so the text color will always be gray.
          UNSAFE_style={{
            fontSize: 'var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200))',
          }}
        >
          <FormattedMessage
            id="storage.usageBarSection.usageSummary"
            values={{
              amountFileSize: formatFileSize(intl, usageSummary.amount),
              totalFileSize: formatFileSize(intl, usageSummary.total),
            }}
          />
        </Text>
        {isOverUsage && (
          <AlertIcon
            aria-label={intl.formatMessage({id: 'storage.usageBarSection.usageSummary.alert'})}
            color="notice"
            size="S"
          />
        )}
      </Flex>
    )
  );
};

UsageBarRightHeaderContent.propTypes = {
  /**
   * A resolved StorageStats object.
   */
  storageStats: PropTypes.instanceOf(StorageStats).isRequired,
};

export default UsageBarRightHeaderContent;
