(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    auth,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    onesieSrc2,
    OrganizationManager,
    productAccess,
    quickAssignGlobalModal
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.FINISH_ASSIGN_LICENSES,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          // If there is a trial contract, without a paid contract, don't display this HVA.
          // Since trial provisioning is asynchronous we don't want to display an inaccurate count.
          const contractList = OrganizationManager.getContractsForActiveOrg();
          if (contractList.hasOnlyTrialContracts()) {
            return $q.reject();
          }

          const getProductsPromise = OrganizationManager.getProductsForActiveOrg().$promise;
          const canViewQuickAssignModalPromise = productAccess.canViewQuickAssignModal();

          return $q.all([getProductsPromise, canViewQuickAssignModalPromise]).then((results) => {
            const productList = results[0];
            const canViewQuickAssignModal = results[1];

            const assignableLicenses = productList.getSeatBasedAssignableLicenseCount();
            const provisionedLicenses = productList.getSeatBasedTotalProvisionedQuantity();

            // At this point in HVA priority sequence, the org will have > 0 provisioned licenses
            // This is important to note as the wording of this HVA is to finish their provisioning of licenses
            // Temporary fix for https://git.corp.adobe.com/admin-tribe/onesie/issues/12327 while we figure out
            // how to handle quick assign assignable products that are not seat based
            const unassignedLicenseCount = assignableLicenses - provisionedLicenses;
            if (canViewQuickAssignModal && unassignedLicenseCount > 0) {
              return [finishAssigningLicensesHVACard(unassignedLicenseCount)];
            }
            return $q.reject();
          });
        }
        return $q.reject();
      }
    }

    /* Private functions */

    // Returns HVA card that encourages admin to FINISH assigning licenses
    function finishAssigningLicensesHVACard(unassignedLicenseCount) {
      const namespace = 'overview.highValueActionArea.setUpTasks.finishAssigningLicenses';
      return new HvaCard({
        coralIcon: 'AlertCircleFilled',
        ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
        description: $translate.instant(`${namespace}.description`),
        header: $translate.instant(`${namespace}.header`, {
          unassignedLicenseCount,
        }),
        id: 'hva-finish-assigning-licenses',
        onCTAClick: () => {
          if (feature.isEnabled('temp_standalone_quick_assign')) {
            openStandAloneQuickAssignModal();
          } else {
            quickAssignGlobalModal.open();
          }
        },
        sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
      });
    }

    function openStandAloneQuickAssignModal() {
      globalModalsManager.open({
        componentBindings: [
          {
            attributeName: 'component',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: onesieSrc2.products.components.QuickAssignModalWrapper,
          },
        ],
        componentName: 'binkyReactContainer',
      });
    }
  }
})();
