import {feature, navBus} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

import domainEnforcementHvaLogo from './domainEnforcementHvaLogo.svg';

const handleGoToSettings = () => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const redirectURL = `${orgId}/settings/identity`;

  navBus.navigate(redirectURL);
};

class DomainEnforcementHva extends HvaCard {
  static get({intl}) {
    if (feature.isDisabled('temp_domain_enforcement_announcements')) {
      return Promise.reject();
    }

    return Promise.resolve([new DomainEnforcementHva({intl})]);
  }

  constructor({intl}) {
    super({
      ctaLabel: intl.formatMessage({
        id: 'overview.highValueActionArea.setUpTasks.domainEnforcement.ctaLabel',
      }),
      description: intl.formatMessage({
        id: 'overview.highValueActionArea.setUpTasks.domainEnforcement.description',
      }),
      gainsightTour: 'domain-enforcement-start-tour',
      header: intl.formatMessage({
        id: 'overview.highValueActionArea.setUpTasks.domainEnforcement.header',
      }),
      icon: {
        src: domainEnforcementHvaLogo,
      },
      id: 'domain-enforcement',
      isDismissible: true,
      onCTA: handleGoToSettings,
      priorityOrder: HVA_ORDER.DOMAIN_ENFORCEMENT,
      sectionTitle: intl.formatMessage({
        id: 'overview.highValueActionArea.setUpTasks.domainEnforcement.sectionTitle',
      }),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default DomainEnforcementHva;
