(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular
    .module('app.widgets.support')
    .constant('CLOSED_SUPPORT_CASE_LIST_PAGINATION_ID', 'closedSupportCaseListPagination')
    .constant('OPEN_SUPPORT_CASE_LIST_PAGINATION_ID', `openSupportCaseListPagination`)
    .constant('SUPPORT_CASE_SORT_ORDER', {
      DEFAULT: 'DEFAULT',
      PRIORITY: 'PRIORITY',
    });
})();
