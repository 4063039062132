import {JIL_CONSTANTS, Store} from '@admin-tribe/binky';
import {TableConstants, TableStore} from '@admin-tribe/binky-ui';
import {compareAsc} from 'date-fns';
import {action, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';

import ContentLogConstants from '../../../common/entities/content-logs/ContentLogConstants';
import ContentLogList from '../../../common/entities/content-logs/ContentLogList';

class ContentLogsStore extends Store {
  contentLogList = undefined;
  orgId = undefined;

  tableStore;

  /**
   * @class ContentLogsStore
   */
  constructor() {
    super();

    makeObservable(this, {
      createContentLog: action,
      fetchData: action,
      goToNextPage: action.bound,
      goToPageNumber: action.bound,
      goToPreviousPage: action.bound,
      onPageSizeChange: action.bound,
      onSortBy: action,
      orgId: observable,
      removeSelectedContentLogs: action,
      resetPageState: action,
    });

    this.orgId = rootStore.organizationStore.activeOrgId;
    // Set this to true right away so the table doesn't have a chance to think it is empty.
    this.isLoading = true;

    this.tableStore = new TableStore({
      getKey: (item) => item.id,
      sortDescriptor: {
        column: ContentLogConstants.SORT.LOG_REPORT_CREATED,
        direction: TableConstants.SORT_DIRECTION.DESCENDING,
      },
    });
  }

  /**
   * @description Method to send a request to create a new content log.
   * @param {String} startDate start date in 'yyyy-MM-dd' format
   * @param {String} endDate end date in 'yyyy-MM-dd' format
   */
  async createContentLog({startDate, endDate}) {
    this.isLoading = true;

    try {
      await this.contentLogList.save({
        endDate,
        startDate,
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        throw error;
      });
    }
  }

  /**
   * @description Downloads reports associated with a content log.
   *
   * @param {Object} contentLog The selected content log which will be downloaded.
   * @returns {Promise} which is resolved when the download completes successfully.
   */
  async download(contentLog) {
    await contentLog.download({orgId: this.orgId});
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    this.contentLogList = await ContentLogList.get({
      orgId: this.orgId,
      pageNumber: this.tableStore.currentPage,
      pageSize: this.tableStore.pageSize,
      sortExpression: this.tableStore.sortDescriptor.column,
      sortOrder:
        this.tableStore.sortDescriptor.direction === TableConstants.SORT_DIRECTION.DESCENDING
          ? JIL_CONSTANTS.ORDER.DESC
          : JIL_CONSTANTS.ORDER.ASC,
    });

    runInAction(() => {
      this.tableStore.mapDataToRows(
        getSortedContentLogs(this.contentLogList.items, this.tableStore.sortDescriptor)
      );
      this.tableStore.setTotalItems(this.contentLogList.pagination.itemCount);
    });
  }

  /**
   * @description Increments page count by one.
   *
   * @returns {Object} The updated response from the ContentLogsList resource.
   */
  goToNextPage() {
    return this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  /**
   * @description Goes to the specified page number.
   *
   * @param {Number} newPageNumber The page number to move to.
   * @returns {Object} The updated response from the ContentLogsList resource.
   */
  goToPageNumber(newPageNumber) {
    this.tableStore.setCurrentPage(newPageNumber);
    return this.load();
  }

  /**
   * @description Decrements page count by one.
   *
   * @returns {Object} The updated response from the ContentLogsList resource.
   */
  goToPreviousPage() {
    return this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  /**
   * @description Callback when the table page size is changed.
   *
   * @param {Number} pageSize The new page size
   * @returns {Object} The updated response from the ContentLogsList resource.
   */
  onPageSizeChange(pageSize) {
    this.tableStore.setPageSize(pageSize);

    // Reset page back to the first page and refresh the list.
    return this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Callback when the sorting descriptor is changed.
   *
   * @param {Object} descriptor The new descriptor for sorting
   * @param {String} descriptor.column Column key to sort on
   * @param {String} descriptor.direction Sort direction, SORT_DIRECTION_ASCENDING for ascending sort, otherwise assume descending
   * @returns {Object} The updated response from the ContentLogsList resource.
   */
  onSortBy(descriptor) {
    this.tableStore.setSortDescriptor(descriptor);
    return this.load();
  }

  /**
   * @description Method to send a request to remove selected content logs.
   */
  async removeSelectedContentLogs() {
    this.isLoading = true;

    try {
      await this.contentLogList.remove(this.tableStore.selectedItems);
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        throw error;
      });
    }
  }

  /**
   * @description Method to clear table selections and set the page number to the first page.
   */
  resetPageState() {
    this.tableStore.clearSelection();
    this.tableStore.currentPage = TableConstants.FIRST_PAGE;
  }
}

/**
 * @description Returns a shallow copy of content logs rows sorted.
 * Sorting is required for the `Date Range` column, as the dates are converted to string,
 * and will be sorted incorrectly by default.
 *
 * @param {Array<Object>} contentLogsList Content Logs rows to sort on,
 * @param {Object} sortDescriptor
 * @param {String} sortDescriptor.column Column key to sort on
 * @param {SORT_DIRECTION} sortDescriptor.direction Sort direction, ASCENDING for ascending sort, otherwise assume DESCENDING
 * @returns {Array<Object>} Sorted and shallow copy of contentLogs
 */
function getSortedContentLogs(contentLogsList, {column, direction}) {
  if (column !== ContentLogConstants.SORT.LOG_REPORT_START_DATE) {
    return contentLogsList;
  }

  // 'sort' is an in-place operation so shallow-copy and 'sort', thus React can detect changes
  return [...contentLogsList].sort((a, b) => {
    let sortResult;
    sortResult = compareAsc(new Date(a.start_date), new Date(b.start_date));
    // If start-dates are the same, sort using end-dates
    if (sortResult === 0) {
      sortResult = compareAsc(new Date(a.end_date), new Date(b.end_date));
    }

    const directionMultiplier = direction === TableConstants.SORT_DIRECTION.ASCENDING ? 1 : -1;
    return sortResult * directionMultiplier;
  });
}

export default ContentLogsStore;
