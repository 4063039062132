/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketProductFormSection
 *
 * @description The product section for a support form.
 *
 * @binding {String} descriptionKey - The key for the description for this section.
 * @binding {Boolean} isExpertSession - Boolean value to identify whether Product section is for ExpertSession, it is used to filter products based on fi code disallow-expert-session
 * @binding {Function} onProductSelected - Callback when a product is selected. The callback takes an object which should include a fulfillableItem object.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketProductFormSection', {
    bindings: {
      descriptionKey: '@',
      isExpertSession: '<?',
      onProductSelected: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-product-form-section/support-ticket-product-form-section.component.html',
  });

  /* @ngInject */
  function controller($filter, $timeout, $translate, $q, _, feature, OrganizationManager) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getSupportTicketFulfillableItems,
      onSearchListItemSelected,
      onSearchSubmit,
      searchItemSelected: null,
      searchListItems: [],
    });

    function $onInit() {
      getSupportTicketFulfillableItems();
    }

    /////////////////

    function invokeCallback(obj = undefined) {
      const fulfillableItem = _.find(vm.searchResults, ['code', obj]);
      vm.onProductSelected({fulfillableItem});
    }

    function getSupportTicketFulfillableItems() {
      vm.searchListItems =
        OrganizationManager.getProductsForActiveOrg().getSupportTicketFulfillableItems({
          forExpertSession: !!vm.isExpertSession,
        });
    }

    function onSearchListItemSelected(obj) {
      vm.searchItemSelected = obj;
      invokeCallback(obj);
    }

    function onSearchSubmit(searchQuery) {
      vm.invalidTooltipMessage = '';

      if (_.isEmpty(searchQuery)) {
        vm.searchResults = [];
        return $q.resolve([]);
      }
      const searchQueryLowerCased = _.toLower(searchQuery);
      // filter the list and sort the results in alphabetical order
      vm.searchResults = _(vm.searchListItems)
        .filter((item) => {
          const itemName = _.toLower(item.enterpriseName);
          return _.includes(itemName, searchQueryLowerCased);
        })
        .sortBy(['enterpriseName'])
        .value();
      // display the invalid tooltip message if there are no matches
      if (_.isEmpty(vm.searchResults)) {
        vm.invalidTooltipMessage = $translate.instant(
          'widgets.support.supportTicketProductFormSection.invalidTooltipMessage'
        );
        invokeCallback();
      }
      return $q.resolve(vm.searchResults);
    }
  }
})();
