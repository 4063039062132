(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains2:addDomainsErrors
   *
   * @description renders a table of the specified domains which cannot be added to the org, and
   *   the respective reasons why.
   *
   * @binding {Array} errors - error items within the DomainListMultiStatusResponse returned from
   *   domainList.previewSave().
   */
  angular.module('app.widgets.domains2').component('appAddDomainsErrors', {
    bindings: {
      errors: '<',
    },
    controller,
    templateUrl:
      'app/widgets/domains2/add-domains-modal/add-domains-errors/add-domains-errors.component.html',
  });

  function controller(_, OrganizationManager) {
    const FALLBACK_MESSAGE_KEY_SUFFIX = 'other';
    const activeOrgId = OrganizationManager.getActiveOrgId();

    const vm = this;

    _.assign(vm, {
      $onChanges,
      errorsToDisplay: [],
      supportHref: `${activeOrgId || ''}/support`,
    });

    /////////////////

    function $onChanges(changes) {
      if (changes.errors) {
        updateErrorList();
      }
    }

    function updateErrorList() {
      vm.errorsToDisplay = _(vm.errors)
        .map((error) => ({
          domainName: error.id,
          messageKeySuffix: _.get(error, 'response.errorCode', FALLBACK_MESSAGE_KEY_SUFFIX),
        }))
        .sortBy('domainName')
        .value();
    }
  }
})();
