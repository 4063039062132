import {Button, Content, Flex, Header, Heading, Text} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {TooltipButton} from '@pandora/react-tooltip-button';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ServerDetails from './server-drawer-details/ServerDetails';
import ServerDrawerEntitlements from './server-drawer-entitlements/ServerDrawerEntitlements';

const ServerSummaryDrawer = ({
  closeDrawerCallback,
  server,
  handleOnReauthorizeButtonClick,
  editServerCallback,
}) => {
  const intl = useIntl();
  const expiryDateFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  function onReauthorizePress() {
    closeDrawerCallback();
    handleOnReauthorizeButtonClick();
  }

  return (
    <Drawer aria-label={server.name}>
      <Header>
        <Heading level={2} marginBottom="size-125" marginTop="size-0">
          {server.name}
        </Heading>
        <Text
          UNSAFE_style={{
            color: 'var(--spectrum-global-color-gray-700)',
            fontSize: 'var(--spectrum-global-dimension-static-font-size-200)',
            fontStyle: 'italic',
          }}
        >
          {intl.formatMessage({id: 'packages.serverSummary.createdBy'}, {name: server.userName})}
        </Text>
      </Header>
      <Content>
        <Flex marginTop="size-200">
          <Button
            data-testid="reauthorize-button"
            isDisabled={server.isCreationInProgress()}
            onPress={onReauthorizePress}
            variant="cta"
          >
            {intl.formatMessage({id: 'packages.servers.table.status.reauthorize'})}
          </Button>
          <Button
            data-testid="edit-button"
            isDisabled={server.isCreationInProgress()}
            marginStart="size-200"
            onPress={() => {
              closeDrawerCallback();
              editServerCallback();
            }}
            variant="primary"
          >
            {intl.formatMessage({
              id: 'packages.serverSummary.serverDetails.edit',
            })}
          </Button>
        </Flex>
        {/* <!-- Time remaining if not expired or expiring --> */}
        {server.isActive() && server.expiryTimestamp !== 0 && (
          <Flex
            alignItems="center"
            gap="size-100"
            marginTop="size-200"
            UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-static-font-size-200)'}}
          >
            <CheckmarkCircle color="positive" size="S" />
            <Text>
              {intl.formatMessage(
                {id: 'packages.serverSummary.authorizedUntil'},
                {expiryDate: intl.formatDate(server.expiryTimestamp, expiryDateFormat)}
              )}
            </Text>
          </Flex>
        )}
        {/* <!-- Expired message --> */}
        {server.isExpired() && server.expiryTimestamp !== 0 && (
          <Flex alignItems="center" marginTop="size-200">
            <TooltipButton
              hoverText={intl.formatMessage({
                id: 'packages.serverSummary.expiredTooltip',
              })}
              isQuiet
              variant="action"
            >
              <AlertIcon
                color="negative"
                size="S"
                UNSAFE_style={{fill: 'var(--spectrum-semantic-negative-color-icon)'}}
              />
            </TooltipButton>
            <Text>
              {intl.formatMessage(
                {id: 'packages.serverSummary.expired'},
                {expiryDate: intl.formatDate(server.expiryTimestamp, expiryDateFormat)}
              )}
            </Text>
          </Flex>
        )}
        {/* <!-- Expiring message --> */}
        {server.isExpiring() && server.expiryTimestamp !== 0 && (
          <Flex alignItems="center" marginTop="size-200">
            <TooltipButton
              hoverText={intl.formatMessage({
                id: 'packages.serverSummary.expiringTooltip',
              })}
              isQuiet
              variant="action"
            >
              <AlertIcon size="S" />
            </TooltipButton>
            <Text>
              {intl.formatMessage(
                {id: 'packages.serverSummary.authorizedUntil'},
                {expiryDate: intl.formatDate(server.expiryTimestamp, expiryDateFormat)}
              )}
            </Text>
          </Flex>
        )}
        <ServerDrawerEntitlements licenses={server.licenses} />
        <ServerDetails server={server} />
      </Content>
    </Drawer>
  );
};

ServerSummaryDrawer.propTypes = {
  /**
   * Callback to handle server summary drawer close event
   */
  closeDrawerCallback: PropTypes.func.isRequired,
  /**
   * Callback to handle edit server button click
   */
  editServerCallback: PropTypes.func.isRequired,
  /**
   * Callback to handle re-authorize button click
   */
  handleOnReauthorizeButtonClick: PropTypes.func,
  /**
   * Content to be shown in Server Drawer Section section
   */
  server: PropTypes.shape({
    /**
     * String to indicate activation enforcement type
     */
    activationEnforcementType: PropTypes.string,
    /**
     * String to indicate organisation name
     */
    branding: PropTypes.string,
    /**
     * String to indicate dns server url
     */
    dns: PropTypes.string,
    /**
     * Expiry timestamp of the server in milliseconds
     */
    expiryTimestamp: PropTypes.number,
    /**
     * Function to check if the server has hard quota
     */
    hasHardQuota: PropTypes.func,
    /**
     * Function to check if the server has soft quota
     */
    hasSoftQuota: PropTypes.func,
    /**
     * Function to check if the server is active
     */
    isActive: PropTypes.func,
    /**
     * Function to check if the server is in creation state
     */
    isCreationInProgress: PropTypes.func,
    /**
     * Function to check if the server has expired
     */
    isExpired: PropTypes.func,
    /**
     * Function to check if the server has expiring
     */
    isExpiring: PropTypes.func,
    /**
     * Number which reprsents the timeout in seconds
     */
    lanTimeout: PropTypes.number,
    /**
     * List of liceses for each server
     */
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * String to represent cloud
         */
        cloud: PropTypes.string,
        /**
         * List of fi items
         */
        fulfillableItems: PropTypes.arrayOf(
          PropTypes.shape({
            /**
             *  Fi code
             */
            code: PropTypes.string,
            /**
             *  Fi item type
             */
            fulfillableItemType: PropTypes.string,
          })
        ),
        /**
         *  Function to get url of icon
         */
        getIconUrl: PropTypes.func,
        /**
         *  Function to get packageable items
         */
        getPackageableFulfillableItems: PropTypes.func,
        /**
         *  Function to get packageable product sapcode
         */
        getPackageableProductSapCodes: PropTypes.func,
        /**
         *  String to reprsent full name of the server
         */
        longName: PropTypes.string,
        /**
         *  String to reprsent offer id of the server
         */
        offerId: PropTypes.string,
        /**
         *  Booleam to reprsent selection of server
         */
        selected: PropTypes.bool,
      })
    ),
    /**
     *  String to reprsent name of the server
     */
    name: PropTypes.string,
    /**
     *  String to reprsent user's name
     */
    userName: PropTypes.string,
  }).isRequired,
};

export default ServerSummaryDrawer;
