import {Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {useId} from '@react-aria/utils';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationDrawerButtonGroup from './BulkOperationDrawerButtonGroup';
import BulkOperationDrawerContent from './BulkOperationDrawerContent';

/* Drawer to display bulk operation results */
const BulkOperationDetailsDrawer = () => {
  const intl = useIntl();
  const headingId = useId();
  const {displayName, isExportJob, startDate} = useBulkOperationJob();

  return (
    <Drawer aria-labelledby={headingId}>
      <Header>
        <Heading id={headingId} level={2}>
          {displayName}
        </Heading>
        <View marginBottom="size-200">
          <Text>
            {intl.formatMessage({id: 'bulkOperations.results.drawer.started'}, {date: startDate})}
          </Text>
        </View>
        {!isExportJob && <BulkOperationDrawerButtonGroup />}
      </Header>
      <BulkOperationDrawerContent />
    </Drawer>
  );
};

export default BulkOperationDetailsDrawer;
