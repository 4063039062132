import {GLOBAL_ADMIN_POLICY, GlobalAdminPolicyList, UserGroup, feature} from '@admin-tribe/binky';
import {
  GoUrl,
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
  PolicyInfoTooltip,
} from '@admin-tribe/binky-ui';
import {Flex, Provider, Text, TextArea, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {setUserGroupAdminRole} from 'core/user-group/access/userGroupAccess';

import ValidatedSearch from '../validated-search/ValidatedSearch';

import styles from './UserGroupModal.pcss';

const INVALID_CHARS = '^[+=-@|].*'; // Block special characters that can potentially be used in CSV injection
const CSV_INJECTION_REGEX = new RegExp(INVALID_CHARS);
const USER_GROUP_NAME_MAX_LENGTH = 255;
const USER_GROUP_DESCRIPTION_MAX_LENGTH = 500;

const UserGroupModal = ({isOpen = false, userGroup, onCancel, onSave, orgId}) => {
  const [modalError, setModalError] = useState(null);
  const [primaryDisabled, setPrimaryDisabled] = useState(true);
  const [userGroupName, setUserGroupName] = useState(userGroup ? userGroup.name : '');
  const [userGroupDescription, setUserGroupDescription] = useState(
    userGroup ? userGroup.description : ''
  );
  const [allowEditUserGroup, setAllowEditUserGroup] = useState(true);
  const [userGroupNameInputVariant, setUserGroupNameInputVariant] = useState(null);
  const [userGroupNameErrorMessage, setUserGroupNameErrorMessage] = useState(null);

  const intl = useIntl();

  // Depending on how/where this is called, this ref may persist between usages so make
  // sure to re-initialize it to its original values on cancel or save.
  const refInitializer = new UserGroup(
    userGroup || {
      orgId,
    }
  );
  const currentUserGroup = useRef(refInitializer).current;

  const resetInputFields = () => {
    setUserGroupName(userGroup?.name);
    setUserGroupDescription(userGroup?.description);

    currentUserGroup.id = userGroup?.id;
    currentUserGroup.name = userGroup?.name;
    currentUserGroup.description = userGroup?.description;
  };

  const resetModalState = () => {
    setModalError(null);
    setPrimaryDisabled(true);
    resetInputFields();
  };

  // List of Global Admin policy and action name
  const policyActionList = [
    {
      actions: [intl.formatMessage({id: 'userGroups.userGroupModal.actionNames.editUserGroups'})],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS,
    },
  ];

  // reset modal on open/close or userGroup changes
  useEffect(() => {
    let isMounted = true;

    const checkManageUserGroupsPolicy = async () => {
      // This should use userGroupAccess/hasManageUserGroupsPolicy and remove this entire
      // useEffect.
      const globalAdminPolicyList = await GlobalAdminPolicyList.get({orgId});
      const allowPolicy = globalAdminPolicyList.getPolicyValue(
        GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS
      );

      if (isMounted) {
        setAllowEditUserGroup(allowPolicy);
      }
    };

    if (feature.isEnabled('temp_global_admin_user_groups') && isOpen) {
      checkManageUserGroupsPolicy();
    }

    resetModalState();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore resetModalState
  }, [userGroup, isOpen]);

  const onModalButtonClick = async () => {
    let isSuccess = false;
    setModalError(null);
    try {
      setPrimaryDisabled(true);
      const newUserGroup = await currentUserGroup.save();
      // Ensure product/profile admins have immediate access to their new user group.
      setUserGroupAdminRole(newUserGroup.id);
      await onSave();
      resetInputFields();
      isSuccess = true;
    } catch (error) {
      if (error.response.data.errorCode === 'DUPLICATE_GROUP_NAME') {
        setModalError(
          intl.formatMessage(
            {id: 'userGroups.userGroupModal.error.duplicate'},
            {userGroupName: currentUserGroup.name}
          )
        );
      } else if (error.response.data.errorCode === 'INVALID_GROUP_NAME') {
        setModalError(intl.formatMessage({id: 'userGroups.userGroupModal.error.invalidGroupName'}));
      } else {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }

      setPrimaryDisabled(false);
    }
    return isSuccess;
  };

  const checkIsValidUserGroupName = () => {
    if (CSV_INJECTION_REGEX.test(currentUserGroup.name)) {
      setUserGroupNameErrorMessage(
        intl.formatMessage({id: 'userGroups.userGroupModal.error.invalidGroupNameCharacter'})
      );
      setUserGroupNameInputVariant('error');
      return;
    }
    setUserGroupNameErrorMessage(null);
    setUserGroupNameInputVariant(null);
  };

  const setPrimaryButton = () => {
    setPrimaryDisabled(
      !currentUserGroup.name ||
        !currentUserGroup.hasUnsavedChanges() ||
        CSV_INJECTION_REGEX.test(currentUserGroup.name)
    );
  };

  const ctaToastGenerator = () =>
    intl.formatMessage({
      id: userGroup ? 'common.toast.modal.userGroupUpdated' : 'common.toast.modal.userGroupAdded',
    });

  const onNameChange = (input) => {
    setUserGroupName(input);
    setModalError(null);
    currentUserGroup.name = input.trim();
    checkIsValidUserGroupName();
    setPrimaryButton();
  };

  const onDescriptionChange = (input) => {
    setUserGroupDescription(input);
    setModalError(null);
    currentUserGroup.description = input.trim();
    setPrimaryButton();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer>
      <ModalDialog
        cancelLabel={intl.formatMessage({
          id: 'common.modal.buttons.cancel',
        })}
        ctaLabel={intl.formatMessage({
          id: 'common.modal.buttons.save',
        })}
        ctaToastGenerator={ctaToastGenerator}
        errorMessage={modalError}
        heightVariant="static"
        id={userGroup ? 'edit-user-group-modal' : 'add-user-group-modal'}
        isCtaDisabled={primaryDisabled}
        onCancel={() => {
          resetModalState();
          onCancel();
        }}
        onCta={() => onModalButtonClick()}
      >
        {!userGroup && (
          <ModalHeading>
            {intl.formatMessage({
              id: 'common.UserGroupModal.createTitle',
            })}
          </ModalHeading>
        )}
        {userGroup && (
          <ModalHeading>
            <Flex alignItems="baseline" direction="row" gap="size-50">
              <Text>
                {intl.formatMessage({
                  id: 'common.UserGroupModal.editTitle',
                })}
              </Text>
              {feature.isEnabled('temp_global_admin_user_groups') && !allowEditUserGroup && (
                <PolicyInfoTooltip orgId={orgId} policyActionList={policyActionList} />
              )}
            </Flex>
          </ModalHeading>
        )}
        <ModalDescription>
          <Text>
            {intl.formatMessage(
              {id: 'userGroups.userGroupModal.description'},
              {
                goUrl: (str) => <GoUrl name="aac_learn_more_usergroups">{str}</GoUrl>,
              }
            )}
          </Text>
        </ModalDescription>
        <ModalContent>
          <Provider isDisabled={!allowEditUserGroup}>
            <View width="747px">
              <ValidatedSearch
                label={intl.formatMessage({
                  id: 'userGroups.userGroupModal.inputArea.displayNameLabel',
                })}
                maxLength={USER_GROUP_NAME_MAX_LENGTH}
                onChange={onNameChange}
                validationMessage={userGroupNameErrorMessage}
                value={userGroupName}
                variant={userGroupNameInputVariant}
              />
            </View>
            <TextArea
              aria-label={intl.formatMessage({
                id: 'userGroups.userGroupModal.inputArea.displayDescriptionPlaceholder',
              })}
              id="description-input"
              label={intl.formatMessage({
                id: 'userGroups.userGroupModal.inputArea.displayDescriptionLabel',
              })}
              marginTop="size-150"
              maxLength={USER_GROUP_DESCRIPTION_MAX_LENGTH}
              necessityIndicator="label"
              onChange={onDescriptionChange}
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set classes
              UNSAFE_className={styles.textarea}
              value={userGroupDescription}
              width="747px"
            />
          </Provider>
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  );
};

UserGroupModal.propTypes = {
  /**
   * A boolean which indicates whether the modal should be opened. The default is false.
   */
  isOpen: PropTypes.bool,
  /**
   * The callback which is called when the model is closed without the user group being
   * created or modified.
   * There are no parameters.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * The callback which is called when the user group is successfully saved.
   * There are no parameters.
   */
  onSave: PropTypes.func.isRequired,
  /**
   * The orgId.
   */
  orgId: PropTypes.string,
  /**
   * A UserGroup. Will be undefined if a new user group is being created and will be set if
   * an existing user group is being edited.
   */
  userGroup: PropTypes.instanceOf(UserGroup),
};
export default UserGroupModal;
