import {CONTRACT_BILLING_FREQUENCY, TAX_TERM} from '@admin-tribe/binky';
import {Flex, ProgressCircle, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SafePrice, {PRICE_FAILURE} from 'common/components/safe-price/SafePrice';

import styles from './FooterTotal.pcss';

/**
 * Footer total to display the price and total units purchased.
 */
const FooterTotal = ({
  children,
  currency,
  isLoading = false,
  isTaxInclusive = false,
  priceTotal,
  recurrenceTerm,
  taxTerm,
}) => {
  const intl = useIntl();
  return (
    <View aria-live="polite" elementType="div" role="region">
      {isLoading ? (
        <ProgressCircle
          aria-label={intl.formatMessage({
            id: 'common.selfCancelFooterTotal.loadingLabel',
          })}
          data-testid="ProgressCircle"
          isIndeterminate
          size="M"
        />
      ) : (
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to adjust line height
        <Flex direction="column" UNSAFE_className={styles['total-section']}>
          {children}
          <SafePrice
            currency={currency}
            data-testid="Price"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
            fallbackNode={<View UNSAFE_className={styles['fallback-text']}>{PRICE_FAILURE}</View>}
            isTaxInclusive={isTaxInclusive}
            overrideStyles={styles}
            price={priceTotal}
            recurrenceTerm={recurrenceTerm}
            taxTerm={taxTerm}
          />
        </Flex>
      )}
    </View>
  );
};

FooterTotal.propTypes = {
  /**
   * The intention of children is to display a description of the number of licenses that are being added/removed/retained.
   * If there is no selection of licenses, the license count line should display "X total licenses" text.
   * If there are licenses selected, the license count line should display "X total licenses retained" text.
   * Other possible translated strings that can be added as example for Add and Renew can be found in Cart total footer Angular JS component's translations:
   * https://git.corp.adobe.com/admin-tribe/onesie/blob/4cb25a5157d5e649edff24c1f7ce845c516d9c19/src/app/widgets/str/str-en.495e31ace1.json#L3328-L3333
   */
  children: PropTypes.node.isRequired,
  /**
   * The currency type used for cost calculation.
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }),
  /**
   * A flag to toggle a loading symbol if the cost needs recalculating.
   * Defaults to false.
   */
  isLoading: PropTypes.bool,
  /**
   * A flag to toggle if the (incl. tax)/(excl. tax) string will be included in displaying the price.
   * Dependent on taxTerm prop. If taxTerm is undefined, neither string mentioned above will appear.
   */
  isTaxInclusive: PropTypes.bool,
  /**
   * The total cost of the units purchased.
   */
  priceTotal: PropTypes.number,
  /**
   * The recurrenceTerm is optional and is used to determine the
   * recurrence text (e.g. `/mo` or `/yr`) to display with the price.
   * Defaults to undefined if not passed in and would appear as a one-time cost.
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * Regional tax term (e.g. tax or VAT)
   */
  taxTerm: PropTypes.oneOf(Object.values(TAX_TERM)),
};

export default FooterTotal;
