(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.widgets.products.set-authorization-policy')
    .constant('AUTHORIZATION_POLICIES', {
      ALL_APPS: {
        ALLOW_ALL: 'ALLOW',
        BLOCK_ALL: 'DENY',
      },
      SINGLE_APP: {
        ALLOW_ALL: 'ALLOW',
        ALLOW_SOME: 'CUSTOM',
        BLOCK_ALL: 'DENY',
      },
    });
})();
