import {authentication, feature} from '@admin-tribe/binky';

import {ROLE} from 'features/users/users.constants';

/**
 * @deprecated
 *   Use the methods in UserRoles to check roles.
 *   AuthenticatedUser.getRoles() returns `UserRoles` for the logged-in admin.
 *   All 'canViewXXX' methods belong in access files.
 */
let activeOrgId,
  activeOrgUserGroupRoles,
  activeOrgUserProductRoles,
  activeOrgUserProfileRoles,
  activeOrgUserRoles;

/**
 * @deprecated see header
 */
function canAddUsers() {
  return isUserOrgAdmin() || isUserContractAdmin() || isUserProfileAdmin() || isUserProductAdmin();
}

/**
 * @deprecated see header
 */
function canExportDevices() {
  return (
    isUserOrgAdmin() ||
    isUserDeploymentAdmin() ||
    isUserSupportDelegate() ||
    isUserProductSupportAdmin()
  );
}

/**
 * @deprecated see header
 */
function canUpdateOrg() {
  return isUserOrgAdmin();
}

/**
 * @deprecated see header
 */
function canUpdateProfile(options = {}) {
  const {productTargetExpression, productProfileId} = options;
  return (
    isUserOrgAdmin() ||
    isUserContractAdmin() ||
    isUserProductAdminForTarget(productTargetExpression) ||
    isUserProfileAdminForProfileContext(productProfileId)
  );
}

/**
 * @deprecated see header
 */
function canViewNotesSection() {
  return isUserOrgAdmin();
}

/**
 * @deprecated AuthenticatedUser.getRoles().getHighestRoleForOrg()
 */
function getHighestRole() {
  if (isUserOrgAdmin()) {
    return ROLE.ADMIN.ORG;
  }
  if (isUserProductAdmin()) {
    return ROLE.ADMIN.PRODUCT;
  }
  if (isUserDeploymentAdmin()) {
    return ROLE.ADMIN.DEPLOYMENT;
  }
  if (isUserSupportDelegate()) {
    return ROLE.ADMIN.SUPPORT;
  }
  if (isUserProductSupportAdmin()) {
    return ROLE.ADMIN.PRODUCT_SUPPORT;
  }
  if (isUserProfileAdmin()) {
    return ROLE.ADMIN.LICENSE;
  }
  if (isUserUserGroupAdmin()) {
    return ROLE.ADMIN.USER_GROUP;
  }
  return ROLE.NONE;
}

/**
 * @deprecated. Use AuthenticatedUser.get().getId()
 */
async function getUserId() {
  const profile = await authentication.getProfile();
  return profile?.userId;
}

/**
 * @deprecated see header
 */
function hasAnyRole(roles) {
  return roles.some((role) => {
    let hasRole;

    switch (role) {
      case ROLE.ADMIN.ORG:
        hasRole = isUserOrgAdmin();
        break;
      case ROLE.ADMIN.CONTRACT:
        hasRole = isUserContractAdmin();
        break;
      case ROLE.ADMIN.DEPLOYMENT:
        hasRole = isUserDeploymentAdmin();
        break;
      case ROLE.ADMIN.LICENSE:
        hasRole = isUserProfileAdmin();
        break;
      case ROLE.ADMIN.PRODUCT:
        hasRole = isUserProductAdmin();
        break;
      case ROLE.ADMIN.PRODUCT_SUPPORT:
        hasRole = isUserProductSupportAdmin();
        break;
      case ROLE.ADMIN.STORAGE:
        hasRole = isUserStorageAdmin();
        break;
      case ROLE.ADMIN.SUPPORT:
        hasRole = isUserSupportDelegate();
        break;
      case ROLE.ADMIN.USER_GROUP:
        hasRole = isUserUserGroupAdmin();
        break;
      default:
        hasRole = false;
    }

    return hasRole;
  });
}

// convenient methods to check an user's role using
// https://wiki.corp.adobe.com/x/9sLfPg
/**
 * @deprecated see header
 */
function isUserDeploymentAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.DEPLOYMENT_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserOrgAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.org_admin);
}

/**
 * @deprecated see header
 */
function isUserContractAdmin() {
  return (
    feature.isEnabled('temp_contract_admin_role') &&
    !!(activeOrgUserRoles && activeOrgUserRoles.CONTRACT_ADMIN)
  );
}

/**
 * @deprecated see header
 */
function isUserProfileAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.LICENSE_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserProfileAdminForProfileContext(profileContextId) {
  return !!(
    activeOrgUserProfileRoles &&
    Object.prototype.hasOwnProperty.call(activeOrgUserProfileRoles, profileContextId)
  );
}

/**
 * @deprecated see header
 */
function isUserProductAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.PRODUCT_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserProductSupportAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.PRODUCT_SUPPORT_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserStorageAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.STORAGE_ADMIN);
}
/**
 * @deprecated see header
 */
function isUserProductAdminForTarget(targetExpression) {
  return !!(
    activeOrgUserProductRoles &&
    Object.prototype.hasOwnProperty.call(activeOrgUserProductRoles, targetExpression)
  );
}

/**
 * @deprecated see header
 */
function isUserSupportDelegate() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.SUPPORT_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserUserGroupAdmin() {
  return !!(activeOrgUserRoles && activeOrgUserRoles.USER_GROUP_ADMIN);
}

/**
 * @deprecated see header
 */
function isUserUserGroupAdminForUserGroup(userGroupId) {
  return !!(
    activeOrgUserGroupRoles &&
    Object.prototype.hasOwnProperty.call(activeOrgUserGroupRoles, userGroupId)
  );
}

/**
 * @deprecated AuthenticatedUser.getRoles().setUserGroupAdminForTarget(userGroupId)
 *
 * @description cache a user group admin role locally. This exists because IMS can
 * take a few minutes to invalidate their cache, and so for product/profile admins they
 * are left with no way to manipulate the user group they just made. We know the server
 * will enforce the real rule, so there's no escalation danger.
 *
 * @param {String} userGroupId - the user group to make this user an admin for
 * @returns {void}
 */
function setUserUserGroupAdminForUserGroup(userGroupId) {
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore if
  if (!activeOrgUserRoles) {
    activeOrgUserRoles = {};
  }

  activeOrgUserRoles.USER_GROUP_ADMIN = true;
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore if
  if (!activeOrgUserGroupRoles) {
    activeOrgUserGroupRoles = {};
  }
  activeOrgUserGroupRoles[userGroupId] = true;
}

async function processOrgRoles(orgId) {
  let userGroupRolesByOrg, userProductRolesByOrg, userProfileRolesByOrg, userRolesByOrg;
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore else
  if (!userRolesByOrg) {
    userGroupRolesByOrg = {};
    userProfileRolesByOrg = {};
    userProductRolesByOrg = {};
    userRolesByOrg = {};
    // @deprecated. Use AuthenticatedUser.get().getRoles()
    const profile = await authentication.getProfile();
    profile.roles?.forEach((role) => {
      const org = role.organization;
      if (!userRolesByOrg[org]) {
        userRolesByOrg[org] = {};
      }
      userRolesByOrg[org][role.named_role] = true;
      // Product specific admin roles
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
      // istanbul ignore else
      if (role.named_role === ROLE.ADMIN.PRODUCT) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
        if (!userProductRolesByOrg[org]) {
          userProductRolesByOrg[org] = {};
        }
        userProductRolesByOrg[org][role.target] = true;
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
      } else if (role.named_role === ROLE.ADMIN.USER_GROUP) {
        if (!userGroupRolesByOrg[org]) {
          userGroupRolesByOrg[org] = {};
        }
        userGroupRolesByOrg[org][role.target.split(':')[1]] = true;
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
      } else if (role.named_role === ROLE.ADMIN.LICENSE) {
        if (!userProfileRolesByOrg[org]) {
          userProfileRolesByOrg[org] = {};
        }
        userProfileRolesByOrg[org][role.target.split(':')[1]] = true;
      }
    });
  }
  activeOrgUserGroupRoles = userGroupRolesByOrg[orgId] || {};
  activeOrgUserProfileRoles = userProfileRolesByOrg[orgId] || {};
  activeOrgUserProductRoles = userProductRolesByOrg[orgId] || {};
  activeOrgUserRoles = userRolesByOrg[orgId] || {};
}

/**
 * @deprecated see header
 */
async function setActiveOrganization(orgId) {
  // we fetch the roles when we set the org (if we haven't already)
  activeOrgId = orgId;
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore else
  if (authentication.isSignedInUser()) {
    await processOrgRoles(activeOrgId);
  }
}

const auth = {
  canAddUsers,
  canExportDevices,
  canUpdateOrg,
  canUpdateProfile,
  canViewNotesSection,
  getHighestRole,
  getUserId,
  hasAnyRole,
  isUserContractAdmin,
  isUserDeploymentAdmin,
  isUserOrgAdmin,
  isUserProductAdmin,
  isUserProductAdminForTarget,
  isUserProductSupportAdmin,
  isUserProfileAdmin,
  isUserProfileAdminForProfileContext,
  isUserStorageAdmin,
  isUserSupportDelegate,
  isUserUserGroupAdmin,
  isUserUserGroupAdminForUserGroup,
  setActiveOrganization,
  setUserUserGroupAdminForUserGroup,
};

export default auth;
