import {GoUrl} from '@admin-tribe/binky-ui';
import {Content, Flex, Heading, Image, Text, View} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import DevicesOutline from 'common/svgs/DevicesOutline';

const GoUrlLink = (str, name) => <GoUrl name={name}>{str}</GoUrl>;

const HowToDeployModalContent = () => {
  const intl = useIntl();

  return (
    <Flex data-testid="deploy-modal-content" direction="row" marginTop="2rem">
      <View marginEnd="2rem">
        <Image alt="" marginX="auto" src={DevicesOutline} width="size-2000" />
      </View>
      <View>
        <Content>
          <FormattedMessage id="deploymentModal.preamble" />
          <View data-testid="step">
            <Heading level={3} marginBottom="size-130">
              <FormattedMessage id="deploymentModal.step1.title" />
            </Heading>
            <FormattedMessage id="deploymentModal.step1.description" />
            <Text>
              {intl.formatMessage(
                {id: 'deploymentModal.step1.description.plan'},
                {
                  goUrl: (str) => GoUrlLink(str, 'cc_DeployHelpPlan'),
                }
              )}
            </Text>
            <Text>
              {intl.formatMessage(
                {id: 'deploymentModal.step1.description.createPackages'},
                {
                  goUrl: (str) => GoUrlLink(str, 'cc_DeployHelpCreate'),
                }
              )}
            </Text>
          </View>
          <View data-testid="package-downloads" marginTop={10}>
            <Text UNSAFE_style={{fontSize: '11px'}}>
              {intl.formatMessage({id: 'deploymentModal.step1.creativeCloudPackager'})}
            </Text>
            <Flex direction="row" marginTop={5}>
              <coral-icon icon="box" />
              <Text data-testid="package-label" marginEnd={24} marginStart={7}>
                <GoUrl name="ccp_installer_osx">
                  <FormattedMessage id="deploymentModal.step1.forMac" />
                </GoUrl>
              </Text>
              <coral-icon icon="box" />
              <Text data-testid="package-label" marginStart={7}>
                <GoUrl name="ccp_installer_win">
                  <FormattedMessage id="deploymentModal.step1.forPc" />
                </GoUrl>
              </Text>
            </Flex>
          </View>
          <View data-testid="step">
            <Heading level={3} marginBottom="size-130">
              <FormattedMessage id="deploymentModal.step2.title" />
            </Heading>
            <Text>
              <FormattedMessage id="deploymentModal.step2.description" />
            </Text>
          </View>
          <View data-testid="step">
            <Heading level={3} marginBottom="size-130">
              <FormattedMessage id="deploymentModal.step3.title" />
            </Heading>
            <Text>
              <FormattedMessage id="deploymentModal.step3.description" />
            </Text>
          </View>
        </Content>
      </View>
    </Flex>
  );
};

export default HowToDeployModalContent;
