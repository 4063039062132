(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appStrikeThroughPrice
   * @description A component to display either:
   *   a full price with a line through it and a discount/promo price with a tax label OR
   *   a full price with a tax label
   *
   * @param {Offer} offer The offer with the prices to process for display.
   * @param {Product} [ownedProduct] The product currently owned by the organization for the same offer.
   * @example
   *       <app-strike-through-price
   *         offer="$ctrl.offer"
   *         owned-product="$ctrl.ownedProduct">
   *       </app-strike-through-price>
   */
  angular.module('app.widgets.products.purchase').component('appStrikeThroughPrice', {
    bindings: {
      offer: '<',
      ownedProduct: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/strike-through-price/strike-through-price.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    binkyOfferPerTermPriceFilter,
    feature,
    onesieSrc2,
    price,
    ProductPurchaseHelper
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onesieSrc2,
    });

    function $onInit() {
      const priceDetails = _.get(vm.offer, 'pricing.prices[0].price_details');
      vm.hasPromoPrice = _.get(priceDetails, 'unit.includes_discount');

      // If there is a promo price, it will be in unit, and the full price will be in unit_without_discount.
      // If there is not a promo price. the full price will be in unit.
      if (vm.hasPromoPrice) {
        // If there is a promo price, the full price is displayed first with a line through it.
        // There is no associated tax label.
        const currency = _.get(vm.offer, 'pricing.currency');
        const taxKey = vm.offer.priceIncludesTax() ? 'amount_with_tax' : 'amount_without_tax';
        const amount = _.get(priceDetails, `unit_without_discount.${taxKey}`);
        if (amount) {
          vm.fullPriceBeforeDiscount = price.format(amount, currency);
        }
      }

      // binkyOfferPerTermPriceFilter uses unit. This price will have a tax label.
      vm.unitPrice = binkyOfferPerTermPriceFilter(vm.offer);

      vm.showPriceChangeTooltip = ProductPurchaseHelper.isOfferPriceDifferentFromProduct(
        vm.ownedProduct,
        vm.offer,
        vm.unitPrice
      );
    }
  }
})();
