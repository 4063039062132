import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import PackagesWorkspaceNav from 'features/packages/components/packages-workspace-nav/PackagesWorkspaceNav';

/**
 * The Packages workspace displays the PackagesWorkspaceNav and
 * any applicable nested children routes specified in packagesRoutes.jsx
 */
const PackagesWorkspace = () => (
  <Workspace useOutlet>
    <PackagesWorkspaceNav />
  </Workspace>
);

export default PackagesWorkspace;
