import {ActionButton, Content, ContextualHelp, Flex, Heading, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Action Button that is disabled and displays a ContextualHelp depending on availability prop
 */
const ContextualHelpButton = (props) => {
  const {helpDescriptionLabel, helpTitleLabel, buttonLabel, ...restOfProps} = props;

  return (
    <Flex alignItems="center">
      <ActionButton
        isDisabled={restOfProps.isDisabled}
        marginEnd="size-100"
        width="size-2000"
        {...restOfProps}
      >
        {buttonLabel}
      </ActionButton>
      {restOfProps.isDisabled && (
        <ContextualHelp placement="right">
          <Heading>{helpTitleLabel}</Heading>
          <Content>
            <Text>{helpDescriptionLabel}</Text>
          </Content>
        </ContextualHelp>
      )}
    </Flex>
  );
};

ContextualHelpButton.propTypes = {
  /**
   * Button label for the action button
   */
  buttonLabel: PropTypes.string.isRequired,
  /**
   * Title label for the ContextualHelp description
   */
  helpDescriptionLabel: PropTypes.string.isRequired,
  /**
   * Title label for the ContextualHelp title
   */
  helpTitleLabel: PropTypes.string.isRequired,
  props: PropTypes.shape({
    /**
     * Whether the button should be disabled or not
     */
    isDisabled: PropTypes.bool.isRequired,
  }),
};

export default ContextualHelpButton;
