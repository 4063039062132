(function () {
  /**
   * @ngdoc module
   * @name app.widgets.avatar
   * @description module that defines avatar widgets
   */
  angular.module('app.widgets.avatar', [
    'app.core.auth',
    'app.core.organizations',
    'app.core.user',
    'binky.core.common.member',
    'binky.core.user.avatar',
    'binky.widgets.common.avatar',
    'binky.widgets.common.file-uploader',
  ]);
})();
