import {
  AuthenticatedUser,
  CONTRACT_BUYING_PROGRAM,
  PRODUCT_BUYING_PROGRAM,
  feature,
  getEnterpriseContract,
  getIndirectContract,
  keysToKebabCase,
  log,
  orders,
} from '@admin-tribe/binky';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import {when} from 'mobx';

import rootStore from 'core/RootStore';

import {
  ORDERS_API_EVENT,
  ORDERS_API_EVENT_STATUS,
  ORDERS_API_PAYMENT_INSTRUMENT_CATEGORY,
  ORDERS_API_PURCHASE_FLOW,
  PAYLOAD_KEY,
} from './freeOfferCartConstants';

class FreeOfferCart {
  /**
   * @description Provisions a specified free offer. This assumes any qualification has already been
   *   determined by the caller.
   * @param {Offer} freeOffer - the free offer to provision
   *
   * @returns {Promise} a promise which will resolve/reject depending on the provisioning status
   */
  static async provisionFreeOffer(freeOffer) {
    const offer = freeOffer;
    // Get the contract to determine the contract country and id.
    let contract;
    await when(() => !!rootStore.organizationStore.contractList);
    const contractList = rootStore.organizationStore.contractList;
    switch (offer.buying_program) {
      case PRODUCT_BUYING_PROGRAM.ETLA:
        contract = getEnterpriseContract(contractList, CONTRACT_BUYING_PROGRAM.ETLA);
        break;
      case PRODUCT_BUYING_PROGRAM.VIP:
        contract = getIndirectContract(contractList);
        break;
      case PRODUCT_BUYING_PROGRAM.VIPMP:
        // Remove the if line on enabling FF bug_fix_38518
        if (feature.isEnabled('bug_fix_38518')) {
          contract = getIndirectContract(contractList);
          break;
        }
      // eslint-disable-next-line no-fallthrough -- The break statement is avoided here to execute default block if FF bug_fix_38518 is disabled
      default:
        throw new Error(`Unsupported offer buying program: ${offer.buying_program}`);
    }
    if (!contract) {
      throw new Error(`Cannot find contract for offer buying program: ${offer.buying_program}`);
    }

    // CCS used to require countryCode but it seems that is no longer true.
    // In any case, let it thru if undefined and let CCS return an error since it will be easier to debug that way.
    const countryCode = contract.getOwnerCountryCode();

    const lastModifiedById = AuthenticatedUser.get().getId();
    // Provision the qualifying offers.
    const cart = FreeOfferCart.get({
      contractId: contract.id,
      country: countryCode,
      lastModifiedById,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    Object.assign(offer, {numberSelected: 1});

    const billableItems = [freeOffer].map((item) =>
      omitBy(
        {
          ignoreOfferMapping: item.ignoreOfferMapping, // used during renewal, default is false
          offerId: item.offer_id,
          quantity: item.numberSelected,
        },
        (value) => value === undefined
      )
    );

    await cart.submitOrderViaOrdersAPI({
      billableItems,
      contractId: contract.id,
      countryCode: rootStore.organizationStore.activeOrg.countryCode,
      marketSegment: rootStore.organizationStore.activeOrg.marketSegment,
      orgId: rootStore.organizationStore.activeOrgId,
      userId: lastModifiedById,
    });

    // TODO: add pollForFulfilledOrder([offer])
    delete offer.numberSelected;

    return this;
  }

  /**
   * @description creates a new Cart object
   * @param {Object} options - options to configure new Cart
   * @param {String} options.lastModifiedById - admin who's making the request
   * @param {CART_TYPE} [options.cartType] - the type of cart (only used in the renewal workflow)
   * @param {String} options.country - country of the org
   * @param {String} options.contractId  contract id
   * @returns {Cart} new Cart object
   */
  static get(options) {
    return new FreeOfferCart(options);
  }

  constructor(options) {
    this.orgId = options.orgId;
    this.contractId = options.contractId;
    this.lastModifiedById = options.lastModifiedById;
    this.billingSummary = initializeBillingSummary(options.country, options.contractId);
  }

  key() {
    return this.contractId;
  }

  /**
   * @description Method to submit an order for free products via the orders API.
   * @param {Object} options - Top level wrapper object.
   * @param {Object[]} options.billableItems - Array of items to create an order
   *     for.
   * @param {string} options.contractId - Contract id the order belongs to.
   * @param {string} options.countryCode - Country code of the organization
   *     that is being updated.
   * @param {string} options.marketSegment - Full market segment string of the org.
   * @param {string} options.orgId - Org id of the org being updated.
   * @param {string} options.userId - User id of the user initiating the request.
   * @returns {Promise} resolves with a FreeOfferCart this instance or rejects
   *     with an error.
   */
  async submitOrderViaOrdersAPI({
    billableItems,
    contractId,
    countryCode,
    marketSegment,
    orgId,
    userId,
  }) {
    const payload = {
      country: countryCode,
      // API is expecting the 3 letter representation for market segment.
      marketSegment: marketSegment.slice(0, 3),
      orderIdentity: {
        organizationId: orgId,
        ownerId: userId,
      },
      orderItems: billableItems,
      paymentInstrument: {
        category: ORDERS_API_PAYMENT_INSTRUMENT_CATEGORY.SAVED,
        paymentInstrumentContractId: contractId,
      },
      purchaseFlow: ORDERS_API_PURCHASE_FLOW.ADMIN_CONSOLE,
      referenceContractId: contractId,
      status: ORDERS_API_EVENT_STATUS.SUBMITTED,
      type: ORDERS_API_EVENT.ADD_ITEMS,
    };
    try {
      await orders.createOrder(keysToKebabCase(payload));
    } catch (error) {
      log.error('An error occurred submitting an order to the orders API', error);
      return Promise.reject(error);
    }

    return this;
  }
}

function initializeBillingSummary(country, contractId) {
  return pickBy({
    [PAYLOAD_KEY.CONTRACT_DETAILS]: {
      [PAYLOAD_KEY.PROGRAM_CONTRACT_ID]: contractId,
    },
    [PAYLOAD_KEY.COUNTRY]: country,
    // Renewal order preview and submit call requires an empty currency object
    [PAYLOAD_KEY.CURRENCY]: {},
  });
}

export default FreeOfferCart;
