(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories:appDirectorySettingsDirectoryNameSection
   * @description renders a directory name section for the directory settings modal
   * @param {Object} directory the directory that's going to be updated
   * @param {function} onHidePageBanner The callback function to hide page banner.
   * @param {function} onShowPageBanner The callback function to show page banner.
   * @example <app-directory-settings-directory-name-section
   *            directory="$ctrl.directory"
   *            on-hide-page-banner="$ctrl.hidePageBanner()"
   *            on-show-page-banner="$ctrl.showPageBanner(options)">
   *          </app-directory-settings-directory-name-section>
   */
  angular.module('app.widgets.directories').component('appDirectorySettingsDirectoryNameSection', {
    bindings: {
      directory: '<',
      onHidePageBanner: '&',
      onShowPageBanner: '&',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-settings/directory-name-section/directory-settings-directory-name-section.component.html',
  });

  /* @ngInject */
  function controller($q, $rootScope, $translate, _, feature, identityAccess, PANEL_MANAGER) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      canManageIdentityConfig: true,
      isSaveDisabled,
      onSave,
      tooltipContentString: undefined,
      waitPromise: $q.resolve(),
    });

    const deregisterModalCloseHandler = $rootScope.$on(PANEL_MANAGER.MODAL.CLOSE, onClose);

    function $onInit() {
      reset();
      vm.canManageIdentityConfig = identityAccess.canChangeIdentityConfig();
      if (!vm.canManageIdentityConfig) {
        vm.tooltipContentString = 'core.globalAdmin.disabledActionTooltip';
      }
    }

    function onSave() {
      hidePageBanner();
      vm.waitPromise = vm.directory.update({name: vm.editedName}).then(
        showSuccessPageBanner,
        // Display error banner with no title
        showErrorPageBanner
      );
      return vm.waitPromise;
    }

    // Callback called when container modal is closed
    function onClose() {
      reset();
    }

    function $onDestroy() {
      deregisterModalCloseHandler();
    }

    function isSaveDisabled() {
      return _.isEmpty(vm.editedName) || !vm.canManageIdentityConfig;
    }

    ////////////

    function hidePageBanner() {
      vm.onHidePageBanner();
    }

    function reset() {
      vm.editedName = _.get(vm, 'directory.name');
    }

    function showErrorPageBanner() {
      const options = {
        variant: 'error',
      };
      vm.onShowPageBanner({options});
    }

    function showSuccessPageBanner() {
      const options = {
        message: 'directoryNameChangedSuccess.message',
        variant: 'success',
      };
      vm.onShowPageBanner({options});
    }
  }
})();
