/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.directories:appActivateIdpModal
 *
 * @description The modal is to confirm the IdP activation operation.
 * @param {Function} onConfirm The callback to confirm the IdP activation operation.
 */

(function () {
  angular.module('app.widgets.directories').component('appActivateIdpModal', {
    bindings: {
      onConfirm: '&',
    },
    controller,
    templateUrl: 'app/widgets/directories/activate-idp-modal/activate-idp-modal.component.html',
  });

  /* @ngInject */
  function controller(_, APP_ACTIVATE_IDP_MODAL_ID, appIdpTitleFilter, feature, translationUtils) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getModalIdpsTitles,
      onConfirmClicked,
      onOpen,
    });

    function $onInit() {
      _.assign(vm, {
        confirmButtonLabelKey: 'widgets.directories.activateIdpModal.buttons.setDefault',
        defaultIdpTitle: '',
        idp: null,
        modalId: APP_ACTIVATE_IDP_MODAL_ID,
        modalTitle: 'widgets.directories.activateIdpModal.oidcTitle',
      });
    }

    function onConfirmClicked() {
      vm.onConfirm({toDefaultIdp: vm.idp});
    }

    // Modal handlers
    function onOpen(params) {
      vm.idp = params.idp;
      vm.defaultIdpTitle = params.defaultIdpTitle;
    }

    function getModalIdpsTitles() {
      return {
        canBeDefaultIdpTitle: appIdpTitleFilter(vm.idp),
        defaultIdpTitle: translationUtils.sanitizeSafeInstant(vm.defaultIdpTitle),
      };
    }
  }
})();
