(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilTeams
   * @description defines service to manage jil team resources.
   */
  angular.module('app.core.api.jil').provider('jilTeams', jilTeamsProvider);

  let url;

  /* @ngInject */
  function jilTeamsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/teams`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        deactivatedDevices: getDeactivatedDeviceListResource(),
        devicePackagePool: getDevicePackagePoolResource(),
        devicePackagePools: getDevicePackagePoolsResource(),
      };

      return service;

      ///////////

      // To get list of devices which have been deactivated.
      function getDeactivatedDeviceListResource() {
        return $resource(`${url}/:orgId/package-pools/devices/archived`, {
          orgId: apiUtils.getActiveOrg,
        });
      }

      // To get information about a specific package pool, specifically, the list of running
      // devices and a summary for this pool. The devices will be either activated or over-activated.
      function getDevicePackagePoolResource() {
        return $resource(
          `${url}/:orgId/package-pools/:poolId/devices/running`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            // To deactivate one device.
            deactivateDevice: {
              method: 'PUT',
              url: `${url}/:orgId/package-pools/:poolId/devices/:deviceId/deactivate`,
            },
          }
        );
      }

      // To get the list of all package pools and a summary for them.
      function getDevicePackagePoolsResource() {
        return $resource(`${url}/:orgId/package-pools`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
