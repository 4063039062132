import {ActionButton, Button, Tooltip, TooltipTrigger, View} from '@adobe/react-spectrum';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';

/**
 * Component is used to show Escalate Case button in Action Section. It is used in action section to render the Escalate case modal.
 */
const SupportTicketEscalateCase = memo(
  ({isEscalated, isEscalationAllowed, isOpen, isSupportCase, onEscalateCase}) => {
    const intl = useIntl();

    const getTooltip = (key) =>
      intl.formatMessage({id: `support.supportTicketActionsSection.tooltips.${key}`});

    const handleEscalateCaseHover = () => {
      if (isEscalated) {
        return getTooltip('alreadyEscalated');
      }
      return isEscalationAllowed ? null : getTooltip('recentCreated');
    };

    const styleActionButton = {
      backgroundColor: 'transparent',
      border: 0,
    };

    return (
      isSupportCase &&
      isOpen && (
        <View elementType="span" paddingEnd="size-0" paddingStart="size-65">
          <Button
            aria-label={intl.formatMessage({
              id: 'support.supportTicketActionsSection.escalateCase.title',
            })}
            className="escalate-case"
            data-testid="escalate-case-button"
            isDisabled={!isEscalationAllowed}
            marginBottom="size-200"
            onClick={() => onEscalateCase(SUPPORT_CONSTANTS.CASE_ACTIONS.ESCALATE)}
            variant="primary"
          >
            {intl.formatMessage({id: 'support.supportTicketActionsSection.escalateCase.title'})}
          </Button>
          {handleEscalateCaseHover()?.length > 0 && (
            <TooltipTrigger>
              <ActionButton UNSAFE_style={styleActionButton}>
                <InfoIcon />
              </ActionButton>
              <Tooltip>{handleEscalateCaseHover()}</Tooltip>
            </TooltipTrigger>
          )}
        </View>
      )
    );
  }
);

SupportTicketEscalateCase.propTypes = {
  /**
   * Used to set escalate case button tooltip & check selected case is already escalated.
   */
  isEscalated: PropTypes.bool.isRequired,
  /**
   * Used to set escalate case button (enable/disable) & tooltip.
   */
  isEscalationAllowed: PropTypes.bool.isRequired,
  /**
   * Used to check ticket/case is open.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Used to check if ticket/case belongs to support case.
   */
  isSupportCase: PropTypes.bool.isRequired,
  /**
   * Used to open modal with selected action
   * The parameter is action type(escalate)
   */
  onEscalateCase: PropTypes.func.isRequired,
};

export default SupportTicketEscalateCase;
