import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {
  CreateInsightsItemDetailModal,
  InsightsProvider,
  InsightsItemListTable as PandoraInsightsItemListTable,
} from '@pandora/react-insights';
import {ModalContainer, useModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

/** A table to display Insights Item data */
const InsightsItemListTable = ({accessOptions, insightsItemList, navigateToDetails}) => {
  const {isOpen, openModal, closeModal} = useModalDialog(false);
  const [modalInsightsItem, setModalInsightsItem] = useState();

  const onClickAction = useCallback(
    (insightsItem) => {
      setModalInsightsItem(insightsItem);
      openModal();
    },
    [openModal]
  );

  const onConfirm = useCallback(
    async (response) => {
      try {
        await response;
        showSuccessToast();
        navigateToDetails(modalInsightsItem, true);
      } catch {
        showErrorToast();
      }
    },
    [navigateToDetails, modalInsightsItem]
  );

  return (
    <InsightsProvider>
      {/* No content is given because it is provided by the pandoraContentModel */}
      <PandoraInsightsItemListTable
        {...accessOptions}
        insightsItemList={insightsItemList}
        onClickAction={onClickAction}
        onClickItem={(insightsItem) => {
          navigateToDetails(insightsItem, false);
        }}
      />
      <ModalContainer>
        {isOpen && (
          <CreateInsightsItemDetailModal
            insightsItem={modalInsightsItem}
            onCancel={closeModal}
            onConfirm={onConfirm}
          />
        )}
      </ModalContainer>
    </InsightsProvider>
  );
};

InsightsItemListTable.propTypes = {
  /** The access options for getting Insights data */
  accessOptions: PropTypes.shape({}),
  /** The list of Insights Items to render */
  insightsItemList: PropTypes.shape({
    /** The section to which the Insights Items belong to */
    insightsSection: PropTypes.string,
    /** The Insights Items within the list */
    items: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string})),
  }),
  /** A function which determines how to navigate to a details page */
  navigateToDetails: PropTypes.func.isRequired,
};

export default InsightsItemListTable;
