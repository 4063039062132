(function () {
  /**
   * @ngdoc module
   * @name app.core.product.product-group-product-list
   * @description Module which defines product group product list related models
   */
  angular.module('app.core.product.product-group-product-list', [
    'app.core.organizations.organization-manager',
    'binky.core.api.jil',
    'binky.core.common.list',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.widgets.products.product-name',
  ]);
})();
