import {Link, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import {
  getHrefForUserGroupDetails,
  goToUserGroupDetails,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

/**
 * Used by the ProductsDetailSection and UserGroupsDetailSection to display
 * either the user group link, or text if already on the page for this user group.
 */
const UserGroupLinkMessage = ({msgKeyNamespace, userGroup}) => {
  const {pathname} = useLocation();
  const userGroupHref = getHrefForUserGroupDetails({userGroupId: userGroup.id});
  const isText = pathname.startsWith(userGroupHref);
  const intl = useIntl();

  const generateLinkMsg = () => {
    const userGroupLink = (groupName) => (
      <Link onPress={() => goToUserGroupDetails({userGroupId: userGroup.id})}>
        <span>{groupName}</span>
      </Link>
    );

    if (msgKeyNamespace) {
      return intl.formatMessage(
        {
          id: `${msgKeyNamespace}.userGroupLink`,
        },
        {
          groupName: userGroup.name,
          link: userGroupLink,
        }
      );
    }

    return userGroupLink(userGroup.name);
  };

  const generateTextMsg = () => {
    if (msgKeyNamespace) {
      return intl.formatMessage(
        {
          id: `${msgKeyNamespace}.userGroupText`,
        },
        {groupName: userGroup.name}
      );
    }

    return userGroup.name;
  };

  return (
    <Text data-testid="user-group-link-message">
      {isText ? generateTextMsg() : generateLinkMsg()}
    </Text>
  );
};

UserGroupLinkMessage.propTypes = {
  /**
   * The optional msgKey namespace.
   * If provided there should be subkeys, 'userGroupLink' and 'userGroupText'.
   * If not provided either a link to the user group or the text name of the user group will be rendered.
   */
  msgKeyNamespace: PropTypes.string,
  /**
   * The user group id and name.
   */
  userGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default UserGroupLinkMessage;
