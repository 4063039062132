(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').component('appHvaSummarySection', {
    controller,
    templateUrl: 'app/overview/overview/hva-summary-section/hva-summary-section.component.html',
  });
  /* @ngInject */
  function controller(
    $rootScope,
    $timeout,
    _,
    AnalyticsEvent,
    binkySrc2,
    CONTACT_LIST_EVENT,
    feature,
    hvaAssembler,
    HvaCardList,
    HVA_TYPE,
    PAYMENT_INFO_EDITOR_EVENT,
    PRODUCT_LIST_EVENT,
    SophiaHelper
  ) {
    const vm = this;
    const {EVENT_ACTION} = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
    const SOPHIA_CONSTANTS = binkySrc2.services.sophia.SOPHIA_CONSTANTS;

    _.assign(vm, {
      $onDestroy,
      $onInit,
    });

    function $onDestroy() {
      _.invoke(vm, 'onProductListUpdateDeregister');
      _.invoke(vm, 'onContactListUpdateDeregister');
      _.invoke(vm, 'onPaymentSaveDeregister');
    }

    function $onInit() {
      _.assign(vm, {
        dismissCard,
        displayHvaCard,
        onCTAClick,
        showHVASummarySection,
      });

      return resetHVACardList().finally(() => {
        if (_.isUndefined(vm.onProductListUpdateDeregister)) {
          vm.onProductListUpdateDeregister = $rootScope.$on(
            PRODUCT_LIST_EVENT.REFRESH,
            resetHVACardList
          );
        }

        if (_.isUndefined(vm.onContactListUpdateDeregister)) {
          vm.onContactListUpdateDeregister = $rootScope.$on(
            CONTACT_LIST_EVENT.REFRESH,
            resetHVACardList
          );
        }

        if (_.isUndefined(vm.onPaymentSaveDeregister)) {
          vm.onPaymentSaveDeregister = $rootScope.$on(
            PAYMENT_INFO_EDITOR_EVENT.SAVED,
            resetHVACardList
          );
        }
      });
    }

    function displayHvaCard({index}) {
      vm.activeHVACard = vm.hvaCardList.getCardAtIndex(index);

      if (vm.activeHVACard) {
        sendAnalytics({
          action: EVENT_ACTION.DISPLAY,
        });
      }
    }

    function onCTAClick() {
      _.invoke(vm, 'activeHVACard.onCTAClick');

      sendAnalytics({
        action: SOPHIA_CONSTANTS.INTERACTION.PRIMARY,
      });

      if (vm.activeHVACard.dismissOnCTAClick) {
        dismissActiveCard();
      }
    }

    function dismissCard(event) {
      // preventDefault() prevents double dismissing with one spacebar/enter tap
      event.preventDefault();

      sendAnalytics({
        action: SOPHIA_CONSTANTS.INTERACTION.DISMISS,
      });

      dismissActiveCard();
    }

    function showHVASummarySection() {
      const showHVAPlaceholderDuringLoadingState = !(
        HvaCardList.isTaskCompleted(HVA_TYPE.VALUE_DISCOVERY) &&
        HvaCardList.areAllTasksCompleteInPreviousLoad()
      );
      return (vm.isBusy && showHVAPlaceholderDuringLoadingState) || vm.activeHVACard;
    }

    // Private Functions

    function dismissActiveCard() {
      // add a delay so the HVA doesn't dismiss before the next page/modal loads
      // See https://git.corp.adobe.com/admin-tribe/onesie/issues/11840
      $timeout(() => {
        vm.hvaCardList.remove(vm.activeHVACard);
        displayHvaCard({index: 0});
      }, 300);
    }

    function resetHVACardList() {
      vm.activeHVACard = undefined;
      vm.hvaCardList = undefined;
      vm.isBusy = true;

      return hvaAssembler
        .getPrioritizedHVACardList()
        .then((result) => {
          vm.hvaCardList = result;
          displayHvaCard({index: 0});
        })
        .finally(() => {
          vm.isBusy = false;
        });
    }

    /**
     * @description sends analytics event for the currently active hva card
     * @param {Object} options - params to customize the analytics event sent
     * @param {String} options.action - action taken to trigger the event
     */
    function sendAnalytics({action}) {
      if (action === EVENT_ACTION.DISPLAY) {
        SophiaHelper.dispatchDisplayEvent({
          cardIdKey: SOPHIA_CONSTANTS.HVA_KEYS.ID,
          responses: [vm.activeHVACard],
        });
      } else {
        SophiaHelper.sendInteractionAnalytics({
          action,
          card: vm.activeHVACard,
          cardIdKey: SOPHIA_CONSTANTS.HVA_KEYS.ID,
        });
      }
    }
  }
})();
