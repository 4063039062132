(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase
   *
   * @description the terms and conditions footer for purchase related modals
   */
  const componentName = 'appTermsAndConditionsFooter';
  angular.module('app.widgets.products.purchase').component(componentName, {
    bindings: {
      contract: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/terms-and-conditions-footer/terms-and-conditions-footer.component.html',
  });

  /* @ngInject */
  function controller(_, DigitalRiverUtils) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    /////////////

    function $onInit() {
      let descriptionKey, drPrivacyURL, drTermsURL;

      if (vm.contract.isIndirectContract()) {
        descriptionKey = '.description.indirectContract';
      } else if (vm.contract.isDrContract) {
        const countryCode = vm.contract.getOwnerCountryCode();
        descriptionKey = '.description.drContract';
        // Digital River's terms and conditions URL is not a goUrl
        drPrivacyURL = DigitalRiverUtils.getPrivacyPolicyLink(countryCode);
        drTermsURL = DigitalRiverUtils.getTermsLink(countryCode);
      } else if (vm.contract.isDirectContract()) {
        descriptionKey = '.description.directContract';
      }

      _.assign(vm, {
        descriptionKey,
        drPrivacyURL,
        drTermsURL,
      });
    }
  }
})();
