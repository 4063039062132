import {DIRECTORY_TYPE} from '@admin-tribe/binky';
import {DialogContainer} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import rootStore from 'core/RootStore';
import AddOidcFromDeeplinkModal from 'features/settings/components/directories/add-oidc-from-deeplink/AddOidcFromDeeplinkModal';
import NoFederatedDirectoriesErrorModal from 'features/settings/components/directories/add-oidc-from-deeplink/NoFederatedDirectoriesErrorModal';
import {SOIDC_NAMES} from 'features/settings/entities/IdpEntity';

const getDefaultProviderName = (queryParams) => {
  const oidcId = queryParams.get('oidcId');

  if (!oidcId) {
    return '';
  }

  return Object.values(SOIDC_NAMES).find(
    (provider) => oidcId.localeCompare(provider, 'en', {sensitivity: 'base'}) === 0
  );
};

const AddOidcFromDeeplink = ({
  clearDirectoryCache,
  directories,
  goToSetupDomains,
  goToDirectory,
}) => {
  const [isAddOidcModalOpen, setIsAddOidcModalOpen] = useState(false);
  const [isNoFedDirectoriesErrorModalOpen, setIsNoFedDirectoriesErrorModalOpen] = useState(false);

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- component does not make sense in SSR
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const federatedDirectories = useMemo(
    () => directories?.filter((directory) => directory.type === DIRECTORY_TYPE.TYPE3) ?? [],
    [directories]
  );

  const canAddEduIdp = useMemo(
    () => federatedDirectories.length > 0 && rootStore.organizationStore.isActiveOrgEdu,
    [federatedDirectories]
  );

  // Display an error modal when there are no federated directories and the org is EDU
  const shouldDisplayErrorModal = useMemo(
    () => federatedDirectories.length === 0 && rootStore.organizationStore.isActiveOrgEdu,
    [federatedDirectories]
  );

  // Open the add SOIDC modal on mount if we have the triggers and the org canAddEduIdp
  // Open the Error Modal if we have the triggers but no federated directories
  useEffect(() => {
    if (queryParams.has('setupOidc')) {
      if (canAddEduIdp) {
        setIsAddOidcModalOpen(true);
      }

      if (shouldDisplayErrorModal) {
        setIsNoFedDirectoriesErrorModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we check the condition only once during the initial render
  }, []);

  return (
    <>
      {isAddOidcModalOpen && (
        <DialogContainer onDismiss={() => setIsAddOidcModalOpen(false)}>
          <AddOidcFromDeeplinkModal
            defaultProviderName={getDefaultProviderName(queryParams)}
            directories={federatedDirectories}
            goToDirectory={goToDirectory}
          />
        </DialogContainer>
      )}

      {isNoFedDirectoriesErrorModalOpen && (
        <DialogContainer onDismiss={() => setIsNoFedDirectoriesErrorModalOpen(false)}>
          <NoFederatedDirectoriesErrorModal
            clearDirectoryCache={clearDirectoryCache}
            goToSetupDomains={goToSetupDomains}
          />
        </DialogContainer>
      )}
    </>
  );
};

AddOidcFromDeeplink.propTypes = {
  clearDirectoryCache: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- the value comes from angular as well
  directories: PropTypes.array,
  goToDirectory: PropTypes.func,
  goToSetupDomains: PropTypes.func,
};

export default AddOidcFromDeeplink;
