import {Divider, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A widget to display a list of InfoCard components which are seperated with a vertical Divider.
 */
const InfoCardBar = ({'data-testid': dataTestId, children}) => (
  <Flex
    data-testid={dataTestId || 'info-card-bar'}
    direction="row"
    gap="size-125"
    marginBottom="size-250"
    marginTop="size-200"
    wrap
  >
    {children &&
      React.Children.map(children, (child, index) =>
        child && child.props && (child.props.value || child.props.noDataTextKey) ? (
          <>
            {index > 0 && <Divider orientation="vertical" size="S" />}
            {child}
          </>
        ) : null
      )}
  </Flex>
);

InfoCardBar.propTypes = {
  /**
   * The InfoCard components to be added to the InfoCardBar
   */
  children: PropTypes.node.isRequired,
  /**
   * The specified data-testid for the component.
   * The default is 'info-card-bar'.
   */
  'data-testid': PropTypes.string,
};

export default InfoCardBar;
