import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import DeviceDesktop from '@react/react-spectrum/Icon/DeviceDesktop';
import {useId} from '@react-aria/utils';
import React from 'react';

import {useDeviceDetailsContext} from '../context/DeviceDetailsContext';

/**
 * A component to show the device name and a generic device icon
 */
const Header = () => {
  const {deviceDetails} = useDeviceDetailsContext();
  const {deviceName} = deviceDetails;
  const headerId = useId();
  return (
    <Heading
      data-testid="frl-device-details-drawer-header-section"
      level={2}
      marginBottom="size-200"
      marginTop="size-0"
      marginX="size-200"
    >
      <Flex alignItems="center" flexWrap="wrap" gap="size-100">
        <View
          backgroundColor="gray-300"
          height="size-550"
          UNSAFE_style={{borderRadius: '50%', display: 'inline-block'}}
          width="size-550"
        >
          <Flex
            alignContent="center"
            alignItems="center"
            height="size-550"
            justifyContent="center"
            width="size-550"
          >
            <DeviceDesktop aria-label="M Device Desktop" size="M" />
          </Flex>
        </View>
        {/* XD link when deviceName is empty: https://xd.adobe.com/view/9f8f657a-ca75-48d2-86da-45a7467d0540-cadc/screen/0b9b50ec-4805-4818-86f0-cb071dca738d/ */}
        <Text id={headerId}>{deviceName ?? EN_DASH}</Text>
      </Flex>
    </Heading>
  );
};

export default Header;
