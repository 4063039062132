/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Users pages
   */
  angular.module('app.widgets.users').factory('usersAllContext', getUsersAllContextService);

  /* @ngInject */
  function getUsersAllContextService(
    $q,
    $state,
    $translate,
    _,
    adminAccess,
    AuthenticatedUser,
    binkyProductNameLabelFilter,
    ConfigurationMemberRoles,
    directoryAccess,
    feature,
    jilDirectories,
    jilProducts,
    jilUserGroups,
    jilUsers,
    onesieSrc2,
    organizationAccess,
    OrganizationManager,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    productAccess,
    productConfigurationAccess,
    userGroupAccess
  ) {
    const service = {
      initialize,
    };

    return service;

    ////////////

    /**
     * @description Method to initialize the users-all context.
     *
     * @param {PageContext} pageContext The page context for this modal, containing type, target, etc.
     * @param {Product|ProductConfiguration|UserGroup} targetObject The object for which users are being shown
     * @returns {Object} an object with the configured context
     */
    function initialize(pageContext, targetObject) {
      const promises = [];
      const response = {
        bulkOperations: configureBulkOperations(pageContext, targetObject, promises),
        display: configureDisplay(pageContext, targetObject, promises),
        operations: configureOperations(pageContext, targetObject, promises),
        operationStrings: configureOperationStrings(pageContext),
        searchOnlyContentMode: configureSearchOnlyContentMode(pageContext),
      };
      return $q.all(promises).then(() => response);
    }

    function canAddUsersToUserGroup(pageContext, targetUserGroup) {
      return (
        userGroupAccess.canAssignUser(targetUserGroup) &&
        // Do not want to add admins to a user group when setting up for a migration
        !(
          pageContext.targetType === PAGE_TARGET_TYPE.ADMIN &&
          OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers()
        )
      );
    }

    function configureBulkOperations(pageContext, targetObject, promises) {
      return pageContext.targetType === PAGE_TARGET_TYPE.USER
        ? configureBulkOperationsForUserTargetType(pageContext, targetObject, promises)
        : {};
    }

    function configureBulkOperationsForUserTargetType(pageContext, targetObject, promises) {
      const result = {};
      switch (pageContext.target) {
        case PAGE_TARGET.DIRECTORY:
          _.assign(result, {
            canExport: true,
            canRemove: organizationAccess.canRemoveUser(),
            canViewResults: shouldAlignWithAddUserLogic(),
            exportFunction: () =>
              jilDirectories.directoryUsers.export({
                directoryId: pageContext.targetId,
              }).$promise,
            viewResults: () =>
              $state.go('users.directory-user-jobs', {
                directoryId: pageContext.targetId,
                orgId: OrganizationManager.getActiveOrgId(),
              }),
          });
          break;
        case PAGE_TARGET.ORGANIZATION:
          if (isUserOrgAdmin()) {
            _.assign(result, {
              canAdd: organizationAccess.canAssignUser(),
              canDownloadAssetMigrations: organizationAccess.canEditUser(),
              canEdit: organizationAccess.canEditUser(),
              canExport: shouldAlignWithAddUserLogic(),
              canExportLicenseDeficitReport: shouldAlignWithAddUserLogic(),
              canExportLicenseStatusReport: shouldAlignWithAddUserLogic(),
              canRemove: organizationAccess.canRemoveUser(),
              canViewResults: shouldAlignWithAddUserLogic(),
              exportFunction: () => jilUsers.users.export().$promise,
              exportLicenseDeficitReportFunction: () =>
                jilProducts.products.exportLicenseDeficitReport().$promise,
              exportLicenseStatusReportFunction: () =>
                jilUsers.users.exportLicenseStatusReport().$promise,
              needsLicensesToAddUser: false,
              viewResults: () =>
                $state.go('users.jobs', {orgId: OrganizationManager.getActiveOrgId()}),
            });
            // Switch Identity Type access is fetched from the Products available
            promises.push(
              directoryAccess.canSwitchIdentityTypes().then((val) => {
                result.canSwitchIdentityType = val;
              })
            );

            promises.push(
              onesieSrc2.users.services.offerSwitchMigration.helper
                .getOfferSwitchMigration()
                .then(({isEligible}) => {
                  result.canMigrateUsers = isEligible;
                })
            );
          }
          break;
        case PAGE_TARGET.PRODUCT:
          if (targetObject.isTeam()) {
            _.assign(result, {
              canAdd: productAccess.canAssignUserIgnoringLicenseCounts(targetObject),
              canExport: true,
              canUnassign: productAccess.canRemoveUser(),
              canViewResults: true,
              exportFunction: () =>
                pageContext.sidecarData.profileIdPromise.then(
                  (groupId) =>
                    jilProducts.licenseGroupUsers.export({
                      groupId,
                      productId: pageContext.targetId,
                    }).$promise
                ),
              needsLicensesToAddUser: productAccess.hasNoAvailableLicenses(targetObject),
              viewResults: () => {
                throw new Error('Angular products has been removed');
              },
            });
          }
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          _.assign(result, {
            canAdd: productConfigurationAccess.canAssignUser(targetObject.product),
            canExport: true,
            canUnassign: productConfigurationAccess.canRemoveUser(),
            canViewResults: true,
            exportFunction: () =>
              jilProducts.licenseGroupUsers.export({
                groupId: pageContext.targetId,
                productId: pageContext.targetParentId,
              }).$promise,
            needsLicensesToAddUser: productConfigurationAccess.hasNoAvailableLicenses(targetObject),
            viewResults: () => {
              throw new Error('Angular products has been removed');
            },
          });
          break;
        case PAGE_TARGET.USER_GROUP:
          _.assign(result, {
            canAdd: userGroupAccess.canAssignUser(targetObject),
            canExport: shouldAlignWithAddUserLogic(),
            canUnassign: userGroupAccess.canRemoveUser(targetObject),
            canViewResults: shouldAlignWithAddUserLogic(),
            exportFunction: () =>
              jilUserGroups.userGroupUsersList.exportUgUsers({
                groupId: pageContext.targetId,
              }).$promise,
            needsLicensesToAddUser: false,
            viewResults: () =>
              $state.go('users.user-group-jobs', {
                orgId: OrganizationManager.getActiveOrgId(),
                userGroupId: pageContext.targetId,
              }),
          });
          break;
        default:
          break;
      }
      return result;
    }

    function configureDisplay(pageContext, targetObject, promises) {
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.INTEGRATION:
          return configureDisplayForIntegrationTargetType(pageContext, targetObject, promises);
        case PAGE_TARGET_TYPE.USER:
          return configureDisplayForUserTargetType(pageContext, targetObject, promises);
        default:
          return {};
      }
    }

    function configureDisplayForIntegrationTargetType(pageContext, targetObject, promises) {
      const result = {};
      switch (pageContext.target) {
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          promises.push(
            targetObject.$promise.then(() => {
              const legacyRoles = processLegacyRoles(targetObject);

              _.assign(result, {
                legacyRoles,
                showLegacyRoles: legacyRoles.length > 0,
              });
            })
          );
          break;
        default:
          break;
      }
      return result;
    }

    function configureDisplayForUserTargetType(pageContext, targetObject, promises) {
      const result = {};
      let showMemberRoles;
      switch (pageContext.target) {
        case PAGE_TARGET.PRODUCT:
          // Team products may allow product member roles
          showMemberRoles =
            targetObject.isTeam() && targetObject.hasConfigurationSettingForLicenseGroupMember();
          _.assign(result, {
            fetchMemberRoles: (userList) =>
              showMemberRoles
                ? pageContext.sidecarData.profileIdPromise.then(
                    (defaultProfileId) =>
                      ConfigurationMemberRoles.get(
                        pageContext.targetId,
                        defaultProfileId,
                        _.map(userList.items, 'id')
                      ).$promise
                  )
                : undefined,
            // if we're showing forward facing roles, then we disallow the setting for user groups
            memberRoleUserTypes: {USER_GROUP: false},
            showMemberRoles,
          });
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          promises.push(
            targetObject.$promise.then(() => {
              const legacyRoles = processLegacyRoles(targetObject);
              showMemberRoles = targetObject.product.hasConfigurationSettingForLicenseGroupMember();

              _.assign(result, {
                fetchMemberRoles: (userList) =>
                  showMemberRoles
                    ? ConfigurationMemberRoles.get(
                        pageContext.targetParentId,
                        pageContext.targetId,
                        _.map(userList.items, 'id')
                      ).$promise
                    : undefined,
                legacyRoles,
                // if we're showing forward facing roles, then we disallow the setting for user groups
                memberRoleUserTypes: {USER_GROUP: false},
                showLegacyRoles: legacyRoles.length > 0,
                showMemberRoles,
              });
            })
          );
          break;
        default:
          break;
      }
      return result;
    }

    function configureOperations(pageContext, targetObject, promises) {
      let operations;

      switch (pageContext.target) {
        case PAGE_TARGET.DIRECTORY:
          operations = configureOperationsForDirectoryTarget();
          break;
        case PAGE_TARGET.ORGANIZATION:
          operations = configureOperationsForOrganizationTarget(pageContext);
          break;
        case PAGE_TARGET.PRODUCT:
          operations = configureOperationsForProductTarget(pageContext, targetObject, promises);
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          operations = configureOperationsForProductConfigurationTarget(
            pageContext,
            targetObject,
            promises
          );
          break;
        case PAGE_TARGET.USER_GROUP:
          operations = configureOperationsForUserGroupTarget(pageContext, targetObject, promises);
          break;
        default:
          operations = {};
          break;
      }

      if (feature.isEnabled('temp_global_admin_user_groups')) {
        // Since these are used for the global banner messaging they must be set for all operations.
        operations = _.defaults(operations, {
          allowAddUsers: true,
          allowEditUsers: true,
          allowRemoveUsers: true,
        });
      }

      return operations;
    }

    function configureOperationsForDirectoryTarget() {
      return {
        canAddLicenses: false,
        canAddUsers: false,
        canManageAdmins: true,
        canRemoveUsers: true,
        canSearch: true,
        needsLicensesToAddUser: false,
        removeUserStorage: true,
      };
    }

    function configureOperationsForOrganizationTarget(pageContext) {
      const result = {};
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.ADMIN:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: organizationAccess.canAssignAdmin(),
            canManageAdmins: adminAccess.canManageAdmins(),
            canRemoveUsers: shouldAlignWithAddAdminLogic(),
            canSearch: shouldAlignWithAddAdminLogic(),
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.DEVELOPER:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: organizationAccess.canAssignDeveloper(),
            canManageAdmins: true,
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.INTEGRATION:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: false,
            canManageAdmins: true,
            canRemoveUsers: false,
            canSearch: false,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.USER:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: organizationAccess.canAssignUser(),
            canManageAdmins: shouldAlignWithAddUserLogic(), // Used to determine when to disabled the add user button
            canRemoveUsers: shouldAlignWithAddUserLogic(),
            canSearch: shouldAlignWithAddUserLogic(),
            needsLicensesToAddUser: false,
            removeUserStorage: shouldAlignWithAddUserLogic(),
          });
          break;
        default:
          break;
      }
      return result;
    }

    function configureOperationsForProductTarget(pageContext, targetProduct, promises) {
      const result = {};
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.ADMIN:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: productAccess.canAssignAdmins(targetProduct),
            canManageAdmins: adminAccess.canManageAdmins(),
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.USER:
          _.assign(result, {
            canAddUsers: productAccess.canAssignUserIgnoringLicenseCounts(targetProduct),
            canManageAdmins: true,
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: productAccess.hasNoAvailableLicenses(targetProduct),
            removeUserStorage: false,
          });
          promises.push(
            productAccess.canPurchaseLicenses(targetProduct).then((canAddLicenses) => {
              _.assign(result, {
                canAddLicenses,
              });
            })
          );
          break;
        default:
          break;
      }

      promises.push(
        targetProduct.$promise.then(() => {
          _.assign(result, {
            targetName: binkyProductNameLabelFilter(targetProduct),
          });
        })
      );

      return result;
    }

    // eslint-disable-next-line id-length
    function configureOperationsForProductConfigurationTarget(
      pageContext,
      targetProfile,
      promises
    ) {
      const result = {};
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.ADMIN:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: productConfigurationAccess.canAssignAdmin(),
            canManageAdmins: adminAccess.canManageAdmins(),
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.DEVELOPER:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: productConfigurationAccess.canAssignDeveloper(),
            canManageAdmins: true,
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.INTEGRATION:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: productConfigurationAccess.canAssignIntegration(),
            canManageAdmins: true,
            canRemoveUsers: true,
            canSearch: false,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
          });
          break;
        case PAGE_TARGET_TYPE.USER:
          _.assign(result, {
            canAddLicenses: false,
            canAddUsers: productConfigurationAccess.canAssignUser(targetProfile.product),
            canManageAdmins: true,
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser:
              productConfigurationAccess.hasNoAvailableLicenses(targetProfile),
            removeUserStorage: false,
          });
          break;
        default:
      }

      promises.push(
        targetProfile.$promise.then(() => {
          _.assign(result, {
            targetName: targetProfile.name,
          });
        })
      );

      return result;
    }

    function configureOperationsForUserGroupTarget(pageContext, targetUserGroup, promises) {
      const result = {};

      promises.push(
        targetUserGroup.$promise.then(() => {
          const allowManageUserGroups = userGroupAccess.allowManageUserGroups();

          _.assign(result, {
            allowAddUsers: allowManageUserGroups,
            allowEditUsers: allowManageUserGroups,
            allowRemoveUsers: allowManageUserGroups,
            canAddLicenses: false,
            canAddUsers: canAddUsersToUserGroup(pageContext, targetUserGroup),
            canManageAdmins:
              pageContext.targetType !== PAGE_TARGET_TYPE.ADMIN || adminAccess.canManageAdmins(),
            canRemoveUsers: true,
            canSearch: true,
            needsLicensesToAddUser: false,
            removeUserStorage: false,
            targetName: targetUserGroup.name,
          });
        })
      );
      return result;
    }

    function configureOperationStrings(pageContext) {
      const result = {};
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.ADMIN:
          _.assign(result, {
            keys: {
              add: {
                button: 'widgets.users.addAdmin',
              },
              globalAdminTooltip: adminAccess.canManageAdmins()
                ? undefined
                : 'core.globalAdmin.disabledActionTooltip',
              remove: {
                button: 'widgets.users.all.removeAdmins.messageFormat.removeButton',
                modalContent: 'widgets.users.all.removeAdmins.messageFormat.modalContent',
                modalTitle: 'widgets.users.all.removeAdmins.messageFormat.modalTitle',
              },
            },
            noUsers: $translate.instant('widgets.users.all.noAdministratorsFound'),
          });
          break;
        case PAGE_TARGET_TYPE.DEVELOPER:
          _.assign(result, {
            keys: {
              add: {
                button: 'widgets.users.addDeveloper',
              },
              remove: {
                // developer removal has its own modal
                button: 'widgets.users.all.removeDevelopers.messageFormat.removeButton',
              },
            },
            noUsers: $translate.instant('widgets.users.all.noDevelopersFound'),
          });
          break;
        case PAGE_TARGET_TYPE.INTEGRATION:
          _.assign(result, {
            keys: {
              add: {
                button: 'widgets.users.addIntegrations2',
              },
              remove: {
                button: 'widgets.users.all.removeIntegrations.messageFormat2.removeButton',
                modalContent: 'widgets.users.all.removeIntegrations.messageFormat2.modalContent',
                modalTitle: 'widgets.users.all.removeIntegrations.messageFormat2.modalTitle',
              },
            },
            noUsers: $translate.instant('widgets.users.all.noIntegrations2'),
          });
          break;
        case PAGE_TARGET_TYPE.USER:
          _.assign(result, configureOperationStringsForUserTargetType(pageContext));
          break;
        default:
          break;
      }
      return result;
    }

    function configureOperationStringsForUserTargetType(pageContext) {
      const result = {
        keys: {
          add: {
            button: 'widgets.users.addUser',
          },
          remove: {
            button: 'widgets.users.all.removeUsers.messageFormat.removeButton',
          },
        },
        noUsers: $translate.instant('widgets.users.all.noUsersFound'),
      };
      switch (pageContext.target) {
        case PAGE_TARGET.DIRECTORY:
          result.keys.remove.modalContent =
            'widgets.users.all.removeUsersFromDirectory.messageFormat.modalContent';
          result.keys.remove.modalTitle =
            'widgets.users.all.removeUsersFromDirectory.messageFormat.modalTitle';
          break;
        default:
          result.keys.remove.modalContent =
            'widgets.users.all.removeUsers.messageFormat.modalContent';
          result.keys.remove.modalTitle = 'widgets.users.all.removeUsers.messageFormat.modalTitle';
          break;
      }
      return result;
    }

    function configureSearchOnlyContentMode(pageContext) {
      const result = {};

      const searchOnlyContentMode = _.get(pageContext, 'sidecarData.searchOnlyContentMode', false);
      if (searchOnlyContentMode) {
        _.assign(result, {
          binkySubPageWaitString: $translate.instant(
            'widgets.users.all.searchOnlyContentMode.waitString'
          ),
          searchInfoTooltipContent: $translate.instant(
            'widgets.users.all.searchOnlyContentMode.searchTooltip'
          ),
        });
      }

      return result;
    }

    function isUserOrgAdmin() {
      const roles = AuthenticatedUser.get().getRoles();
      return roles.isOrgAdminForOrg(OrganizationManager.getActiveOrgId());
    }

    function processLegacyRoles(productConfiguration) {
      // The legacy roles will be removed once Target migrate to member roles
      return _(productConfiguration.getProductRoles())
        .map((role) => ({
          default: role.default,
          id: role.id,
          name: $translate.instant(
            `binky.widgets.user.userRole.product.${_.camelCase(role.title)}`
          ),
        }))
        .sortBy(['name'])
        .value();
    }

    function shouldAlignWithAddAdminLogic() {
      return OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddAdminLogic();
    }

    function shouldAlignWithAddUserLogic() {
      return OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic();
    }
  }
})();
/* eslint-enable max-lines */
