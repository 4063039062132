import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import AuthenticationPoliciesStore from 'features/settings/stores/AuthenticationPoliciesStore';

/**
 * Private Context for the Authentication Settings section and its sub-components.
 * Used to pass the authenticationPoliciesStore between sub-components.
 */

const AuthSettingsContext = createContext({});
const useAuthSettingsContext = () => useContext(AuthSettingsContext);

const AuthSettingsContextProvider = ({children, authenticationPoliciesStore}) => {
  const value = useMemo(() => ({authenticationPoliciesStore}), [authenticationPoliciesStore]);

  return <AuthSettingsContext.Provider value={value}>{children}</AuthSettingsContext.Provider>;
};

AuthSettingsContextProvider.propTypes = {
  /**
   * The authentication policies store
   */
  authenticationPoliciesStore: PropTypes.instanceOf(AuthenticationPoliciesStore).isRequired,
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
};

export {AuthSettingsContextProvider, useAuthSettingsContext};
