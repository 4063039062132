import {getExpirationPhaseFromContractComplianceSymptoms} from '@admin-tribe/binky';
import {getContractExpirationStatusText} from '@admin-tribe/binky-ui';

import {CONTRACT_TABLE_DATA_KEYS} from './OverviewConstants';
import {
  getContractDate,
  getContractProductsList,
  getContractTags,
  getContractType,
  getHrefForContractDetails,
} from './overviewUtils';

/**
 * ContractTableRowData wraps all possible values that each ContractTable row needs.
 */
class ContractTableRowData {
  /**
   * @description Create a new instance of ContractTableRowData
   * @param {Contract} contract Contract instance
   * @param {Object} intl React-intl instance
   * @param {Object} [productOptions] Options for products
   * @param {Boolean} [productOptions.includeInactive] products may include
   * inactive products (see useOverviewData.js), set this option to true
   * to include inactive products for generating
   * ContractTableRowData[CONTRACT_TABLE_DATA_KEYS.PRODUCTS]
   * @param {Product[]} products ProductList.items
   */
  static create({contract, intl, productOptions = {}, products}) {
    const contractTableRowData = new ContractTableRowData();

    const expirationPhase = getExpirationPhaseFromContractComplianceSymptoms(contract);
    const isContractEtlaOrAllocation =
      contract.isBuyingProgramETLA() || contract.isModelAllocation();

    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.CAN_BUY_MORE] = contract.canAddProducts();
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_DISPLAY_NAME] =
      contract.getDisplayName();
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.TAGS] = getContractTags({contract, intl});

    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_ID] = contract.id;
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.DATE] = getContractDate(contract);
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.EXPIRATION_PHASE] = expirationPhase;
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.EXPIRATION_TEXT] =
      getContractExpirationStatusText({
        contractStatus: contract.status,
        expirationPhase,
        intl,
        isContractEtlaOrAllocation,
      });
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.IS_ETLA_OR_ALLOCATION] =
      isContractEtlaOrAllocation;
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.PRODUCTS] = getContractProductsList({
      contract,
      productOptions,
      products,
    });
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.STATUS] = contract.status;
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.TYPE] = getContractType({contract, intl});
    contractTableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_HREF] =
      getHrefForContractDetails(contract);
    return contractTableRowData;
  }
}

export default ContractTableRowData;
