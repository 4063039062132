import {Store, log} from '@admin-tribe/binky';
import {action, makeObservable} from 'mobx';

import {decodeBase64} from 'common/utils/orgUtils';

class ContractsStore extends Store {
  ACCEPT_LATER_BANNER_EXPIRY = 7;
  ACCEPT_LATER_KEY = 'acceptLaterTnc';
  ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

  constructor() {
    super();

    makeObservable(this, {
      isContractsWithAcceptLater: action,
      saveIfAcceptLaterForContractTnc: action,
      updateContractsWithAcceptLater: action,
    });
  }

  isContractsWithAcceptLater(contracts, orgId, userId) {
    const contractIdsWithNewTnc = contracts.items
      .filter((value) => value.mustAcceptTerms())
      .map((value) => value.id);

    const retrievedContractsString = localStorage.getItem(this.ACCEPT_LATER_KEY);

    if (retrievedContractsString) {
      const retrievedContractsArray = JSON.parse(retrievedContractsString);

      // remove contract IDs from local storage for which TNC accepted  or accepted later duration expired
      const updatedAcceptLaterList = retrievedContractsArray.filter(
        (value) =>
          value.oi !== orgId ||
          value.ui !== userId ||
          (contractIdsWithNewTnc.includes(value.ci) &&
            (new Date().getTime() - value.dt) / this.ONE_DAY_IN_MILLISECONDS <=
              this.ACCEPT_LATER_BANNER_EXPIRY)
      );

      localStorage.setItem(this.ACCEPT_LATER_KEY, JSON.stringify(updatedAcceptLaterList));

      // check if all contract ids with new tnc opted for accept later
      const contractIdsWithAcceptLaterList = updatedAcceptLaterList
        .filter((value) => value.oi === orgId && value.ui === userId)
        .filter((value) => contractIdsWithNewTnc.includes(value.ci))
        .map((value) => value.ci);
      return contractIdsWithAcceptLaterList.length >= contractIdsWithNewTnc.length;
    }

    return contractIdsWithNewTnc.length === 0;
  }

  isExistingContractExpired(contractDetails) {
    return (
      (new Date().getTime() - contractDetails.dt) / this.ONE_DAY_IN_MILLISECONDS >=
      this.ACCEPT_LATER_BANNER_EXPIRY
    );
  }

  saveIfAcceptLaterForContractTnc(contracts, location, userId) {
    const params = location.search();

    if (params.s) {
      try {
        const contractData = decodeBase64(decodeURIComponent(params.s));
        const orgContractIds = contracts.items ? contracts.items.map((value) => value.id) : [];

        if (!orgContractIds.includes(contractData.ci)) return;

        this.updateContractsWithAcceptLater(contractData.ci, contracts.orgId, userId);
      } catch (error) {
        log.error('Error while decoding contract data', error);
      }
    }
  }

  updateContractsWithAcceptLater(contractId, orgId, userId) {
    this.retrievedContractsString = localStorage.getItem(this.ACCEPT_LATER_KEY);
    const acceptLaterDetails = {
      ci: contractId,
      dt: new Date().getTime(),
      oi: orgId,
      ui: userId,
    };

    if (this.retrievedContractsString) {
      const retrievedContractsArray = JSON.parse(this.retrievedContractsString);
      const contractsFoundIndex = retrievedContractsArray.findIndex(
        (value) => value.ci === contractId && value.oi === orgId && value.ui === userId
      );

      if (contractsFoundIndex > -1) {
        if (this.isExistingContractExpired(retrievedContractsArray[contractsFoundIndex])) {
          retrievedContractsArray.splice(contractsFoundIndex, 1);
        } else {
          return;
        }
      }

      const updatedContracts = [...retrievedContractsArray, acceptLaterDetails];
      localStorage.setItem(this.ACCEPT_LATER_KEY, JSON.stringify(updatedContracts));
    } else {
      const updatedContracts = [acceptLaterDetails];
      localStorage.setItem(this.ACCEPT_LATER_KEY, JSON.stringify(updatedContracts));
    }
  }
}

export default ContractsStore;
