(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common.links.button-link
   * @description module that defines the button-link widget
   */
  angular.module('app.widgets.common.links.button-link', [
    'app.shell.navigation.links',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'pascalprecht.translate',
  ]);
})();
