(function () {
  /**
   * @deprecated use binkySrc2.services.collaborationPolicies.CollaborationPolicies
   */
  angular.module('app.core.asset-sharing').factory('AssetSharing', assetSharing);

  function assetSharing(
    $q,
    _,
    feature,
    INVITATION_DOMAIN_SHARING,
    onesieSrc2,
    POLICY,
    PolicyList,
    REQUEST_ACCESS_STATUS,
    RESTRICTION_STATUS,
    WhitelistedDomainList
  ) {
    class AssetSharing {
      constructor() {
        const policyList = PolicyList.get();
        _.assign(this, {
          $promise: policyList.$promise.then(_.partial(setModel, this)),
          policyList,
        });
      }

      addAllowlistedDomains(domainsToAdd) {
        const invitationDomainAllowlistPolicy = this.policyList.getPolicy(
          POLICY.INVITATION_DOMAIN_ALLOWLIST
        );
        const {domains} = invitationDomainAllowlistPolicy.value;

        invitationDomainAllowlistPolicy.value.domains = _.union(
          domains,
          _.map(domainsToAdd, _.toLower)
        );
        this.$promise = attemptUpdatePolicies(this);
        return this.$promise;
      }

      // remove with temp_use_allowlist
      addWhitelistedDomains(domainsToAdd) {
        const invitationDomainWhitelistPolicy = this.policyList.getPolicy(
          POLICY.INVITATION_DOMAIN_WHITELIST
        );
        const {domains} = invitationDomainWhitelistPolicy.value;

        invitationDomainWhitelistPolicy.value.domains = _.union(
          domains,
          _.map(domainsToAdd, _.toLower)
        );
        this.$promise = attemptUpdatePolicies(this);
        return this.$promise;
      }

      getAllowlistedDomains(options) {
        return this.$promise.then((model) => {
          const domainNames = model.policyList.getPolicy(POLICY.INVITATION_DOMAIN_ALLOWLIST).value
            .domains;
          return new WhitelistedDomainList({
            items: _.map(domainNames, (domainName) => ({name: domainName})),
            pageSize: _.get(options, 'pageSize'),
          });
        });
      }

      // remove with temp_use_allowlist
      getWhitelistedDomains(options) {
        return this.$promise.then((model) => {
          const domainNames = model.policyList.getPolicy(POLICY.INVITATION_DOMAIN_WHITELIST).value
            .domains;
          return new WhitelistedDomainList({
            items: _.map(domainNames, (domainName) => ({name: domainName})),
            pageSize: _.get(options, 'pageSize'),
          });
        });
      }

      refresh() {
        this.$promise = this.policyList.refresh().then(_.partial(setModel, this));
        return this.$promise;
      }

      removeAllowlistedDomains(domainsToRemove) {
        const invitationDomainAllowlistPolicy = this.policyList.getPolicy(
          POLICY.INVITATION_DOMAIN_ALLOWLIST
        );
        const {domains} = invitationDomainAllowlistPolicy.value;

        invitationDomainAllowlistPolicy.value.domains = _.difference(domains, domainsToRemove);
        this.$promise = attemptUpdatePolicies(this);
        return this.$promise;
      }

      // remove with temp_use_allowlist
      removeWhitelistedDomains(domainsToRemove) {
        const invitationDomainWhitelistPolicy = this.policyList.getPolicy(
          POLICY.INVITATION_DOMAIN_WHITELIST
        );
        const {domains} = invitationDomainWhitelistPolicy.value;

        invitationDomainWhitelistPolicy.value.domains = _.difference(domains, domainsToRemove);
        this.$promise = attemptUpdatePolicies(this);
        return this.$promise;
      }

      setRequestAccessStatus(requestAccessStatus) {
        const requestAccess = this.policyList.getPolicy(POLICY.REQUEST_ACCESS);
        switch (requestAccessStatus) {
          // Allow access requests
          case REQUEST_ACCESS_STATUS.ALLOW:
            requestAccess.value = true;
            break;
          // No access requests
          case REQUEST_ACCESS_STATUS.DISALLOW:
            requestAccess.value = false;
            break;
          // Invalid option
          default:
            throw new Error(`Invalid request access status: ${requestAccessStatus}`);
        }
        this.$promise = this.policyList
          .save()
          .then(_.partial(setModel, this))
          .catch((error) => {
            // revert the state on update fail
            this.requestAccessStatus = this.savedState.requestAccessStatus;
            return $q.reject(error);
          });

        return this.$promise;
      }

      setRestrictionStatus(restrictionStatus) {
        // Perform necessary updates to policy objects to set desired restriction state.
        const publicSharePolicy = this.policyList.getPolicy(POLICY.PUBLIC_SHARE);
        const invitationDomainAllowlistPolicy = this.policyList.getPolicy(
          feature.isEnabled('temp_use_allowlist')
            ? POLICY.INVITATION_DOMAIN_ALLOWLIST
            : POLICY.INVITATION_DOMAIN_WHITELIST
        );

        switch (restrictionStatus) {
          // No restrictions
          case RESTRICTION_STATUS.NONE:
            publicSharePolicy.value = true;
            invitationDomainAllowlistPolicy.value.allow = INVITATION_DOMAIN_SHARING.ALL;
            break;
          // No public link sharing
          case RESTRICTION_STATUS.PUBLIC_LINK:
            publicSharePolicy.value = false;
            invitationDomainAllowlistPolicy.value.allow = INVITATION_DOMAIN_SHARING.ALL;
            break;
          // Sharing only to domain users
          case RESTRICTION_STATUS.DOMAIN_USERS:
          case RESTRICTION_STATUS.AUTHORIZED_DOMAIN_USERS:
          case RESTRICTION_STATUS.DOMAIN_USERS_WITHOUT_DOMAIN_CLAIMING:
            publicSharePolicy.value = false;
            invitationDomainAllowlistPolicy.value.allow =
              INVITATION_DOMAIN_SHARING.CLAIMED_AND_TRUSTED_WITH_DOMAINS;
            break;
          // Invalid option
          default:
            throw new Error(`Invalid restriction status: ${restrictionStatus}`);
        }
        this.$promise = this.policyList
          .save()
          .then(_.partial(setModel, this))
          .catch((error) => {
            // revert the state on update fail
            this.restrictionStatus = this.savedState.restrictionStatus;
            return $q.reject(error);
          });

        return this.$promise;
      }

      static get() {
        return new AssetSharing();
      }
    }

    return AssetSharing;

    /////////////////

    function attemptUpdatePolicies(model) {
      const invitationDomainAllowlistPolicy = model.policyList.getPolicy(
        feature.isEnabled('temp_use_allowlist')
          ? POLICY.INVITATION_DOMAIN_ALLOWLIST
          : POLICY.INVITATION_DOMAIN_WHITELIST
      );
      return model.policyList
        .save()
        .then(_.partial(setModel, model))
        .catch((error) => {
          invitationDomainAllowlistPolicy.value.domains = model.savedState.whitelist;
          return model.refresh().finally(() => $q.reject(error));
        });
    }

    /**
     * @description Determines current request access policy.
     * @param {Object[]} policyList array of policies for the org.
     * @returns {REQUEST_ACCESS_STATUS} enum representing level of request access.
     */
    function getRequestAccessStatus(policyList) {
      // Throw error if the policy is not found
      if (_.isUndefined(policyList.getPolicy(POLICY.REQUEST_ACCESS))) {
        throw new TypeError('Failed to get request access status');
      }
      return policyList.getPolicy(POLICY.REQUEST_ACCESS).value
        ? REQUEST_ACCESS_STATUS.ALLOW
        : REQUEST_ACCESS_STATUS.DISALLOW;
    }

    /**
     * @description Determines current restriction policy.
     * @param {Object[]} policyList array of policies for the org.
     * @returns {RESTRICTION_STATUS} enum representing level of sharing restriction.
     */
    function getRestrictionStatus(policyList) {
      const invitationDomainPolicy = feature.isEnabled('temp_use_allowlist')
        ? POLICY.INVITATION_DOMAIN_ALLOWLIST
        : POLICY.INVITATION_DOMAIN_WHITELIST;
      // Throw error if one of the policy is not found
      if (
        _.isUndefined(policyList.getPolicy(POLICY.PUBLIC_SHARE)) ||
        _.isUndefined(policyList.getPolicy(invitationDomainPolicy))
      ) {
        throw new TypeError('Failed to get restriction status');
      }
      // No restrictions
      if (policyList.getPolicy(POLICY.PUBLIC_SHARE).value) {
        return $q.resolve(RESTRICTION_STATUS.NONE);
      }
      // No public link sharing
      if (
        policyList.getPolicy(invitationDomainPolicy).value.allow === INVITATION_DOMAIN_SHARING.ALL
      ) {
        return $q.resolve(RESTRICTION_STATUS.PUBLIC_LINK);
      }

      // Sharing only to domain users
      return onesieSrc2.core.directories.access.canAddDomains().then((canAddDomains) => {
        if (canAddDomains) {
          return RESTRICTION_STATUS.AUTHORIZED_DOMAIN_USERS;
        }
        return RESTRICTION_STATUS.DOMAIN_USERS_WITHOUT_DOMAIN_CLAIMING;
      });
    }

    function setModel(model, policyList) {
      const requestAccessStatus = getRequestAccessStatus(policyList);
      return getRestrictionStatus(policyList).then((restrictionStatus) =>
        assignModelData({model, policyList, requestAccessStatus, restrictionStatus})
      );
    }

    function assignModelData({model, policyList, requestAccessStatus, restrictionStatus}) {
      const whitelist = _.clone(
        policyList.getPolicy(
          feature.isEnabled('temp_use_allowlist')
            ? POLICY.INVITATION_DOMAIN_ALLOWLIST
            : POLICY.INVITATION_DOMAIN_WHITELIST
        ).value.domains
      );
      _.assign(model, {
        policyList,
        requestAccessStatus,
        restrictionStatus,
        savedState: {
          requestAccessStatus,
          restrictionStatus,
          whitelist,
        },
      });
      return model;
    }
  }
})();
