import {action, computed, makeObservable, observable, override, runInAction} from 'mobx';

import TableStore from 'common/stores/TableStore';
import {claimFederatedDomains, getFederatedDomains} from 'features/settings/api/ims-federated';
import FederatedDomainEntity from 'features/settings/entities/FederatedDomainEntity';

const FEDERATED_DOMAINS_ERROR_CODES = {
  GENERIC_ERROR: Symbol('GENERIC_ERROR'),
  INVALID_TOKEN_ERROR: Symbol('INVALID_TOKEN_ERROR'),
};
const TABLE_PAGE_SIZE = 10;

class FederatedDomainsStore extends TableStore {
  domainsData = [];
  error = null;
  hasError = false;
  loading = false;

  constructor() {
    super();

    makeObservable(this, {
      changePageSize: action,
      claimDomains: action,
      error: observable,
      goNext: override,
      goPrevious: override,
      hasError: observable,
      loadData: action,
      loading: observable,
      selectedDomainsCount: computed,
    });

    this.setPageSize(TABLE_PAGE_SIZE);
  }

  changePageSize(pageSize) {
    this.setPageSize(pageSize);
    this.loadPage();
  }

  async claimDomains(authSourceId, externalToken, federatedType) {
    this.loading = true;
    const domains = Object.keys(this.selectedItems).filter((key) => this.selectedItems[key]);

    try {
      const response = await claimFederatedDomains({
        authSourceId,
        domains,
        externalToken,
        federatedType,
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.showError();
      });

      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  goNext() {
    this.setCurrentPage(this.currentPage + 1);
    this.loadPage();
  }

  goPrevious() {
    this.setCurrentPage(this.currentPage - 1);
    this.loadPage();
  }

  async loadData(authSourceId, externalToken, federatedType) {
    this.hasError = false;
    this.loading = true;

    try {
      const response = await getFederatedDomains({authSourceId, externalToken, federatedType});
      runInAction(() => {
        this.domainsData = response.data.items.map((item) => new FederatedDomainEntity(item));
        this.setTotalItems(response.data.pageMetadata.size);
        this.loadPage();
      });
    } catch (error) {
      let errorCode;

      if (error.response?.status === 401) {
        errorCode = FEDERATED_DOMAINS_ERROR_CODES.INVALID_TOKEN_ERROR;
      }

      runInAction(() => {
        this.showError(errorCode);
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  // helpers
  loadPage() {
    const startPosition = (this.currentPage - 1) * this.pageSize;
    const endPosition = startPosition + this.pageSize;

    this.mapDataToRows(
      this.domainsData.slice(startPosition, endPosition),
      // allow row to be selected only if the domain
      // is valid and not already linked
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- neculaes@ to update
      // istanbul ignore next
      (domain) => !domain.isInvalid && !domain.isLinked
    );
  }

  get selectedDomainsCount() {
    return this.rows.filter((row) => row.selected).length;
  }

  showError(errorCode = FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR) {
    this.hasError = true;
    this.error = errorCode;
  }
}

export default FederatedDomainsStore;
export {FEDERATED_DOMAINS_ERROR_CODES, TABLE_PAGE_SIZE};
