(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.card-button-list')
    .constant('CARD_BUTTON_VARIANT', {
      IMPORTANT: 'important',
      MINOR: 'minor',
      URGENT: 'urgent',
    })
    .constant('CARD_BUTTON_FOCUS_CHANGE', 'card-button:focus-change');
})();
