import {LicenseGroupList, Product} from '@admin-tribe/binky';
import {DrawerTrigger} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import DeviceRenewalsDrawer from 'common/components/device-renewals-drawer/DeviceRenewalsDrawer';
import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {ADMIN_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import {notify} from 'core/products/notifier/productNotifier';

import Banners from './Banners';

/**
 * ProductBanners display a PageBanner for each message provided by the productNotifier
 */
const ProductBanners = ({deviceData, licenseGroupList, product}) => {
  const intl = useIntl();

  const [messagesToDisplay, setMessagesToDisplay] = useState([]);

  const openDrawer = useRef(null);

  // Loads banner required for specified product
  useEffect(() => {
    async function fetchMessagesToDisplay() {
      setMessagesToDisplay(await notify({deviceData, intl, licenseGroupList, openDrawer, product}));
    }
    fetchMessagesToDisplay();
  }, [intl, product, deviceData, licenseGroupList]);

  return (
    <>
      {deviceData?.data?.devices && (
        <DrawerTrigger
          data-testid="product-banners-drawer-trigger"
          offsetTop="var(--spectrum-global-dimension-size-700)"
        >
          {(open) => {
            openDrawer.current = open;
          }}
          {() => <DeviceRenewalsDrawer devices={deviceData.data.devices} product={product} />}
        </DrawerTrigger>
      )}
      <Banners messagesToDisplay={messagesToDisplay} />
      <GlobalAdminPolicyBanner context={ADMIN_CONTEXT} />
    </>
  );
};

ProductBanners.propTypes = {
  /**
   * If product is a legacy device an Array<Object> returned from Team.devicePackagePoolResource().
   */
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object returned from JilTeams.devicePackagePoolResource()
  deviceData: PropTypes.object,

  /**
   * The license group list for the current product
   */
  licenseGroupList: PropTypes.instanceOf(LicenseGroupList),

  /**
   * The product which for which banners should be displayed
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default ProductBanners;
