import {isMigrationListT2eClean} from '@admin-tribe/binky';
import uniqueId from 'lodash/uniqueId';

import {JOB_ITEM_FAILURE_TYPE, JOB_RESULT_ITEM_STATUS} from 'common/services/job/JobConstants';
import rootStore from 'core/RootStore';

class JobResult {
  /**
   * @description Takes a raw json object and transforms it to a JobResult object
   *
   * @param {Object} rawJobResultItemJson - object to transform
   *
   * @returns {JobResult} A job result object with attributes set from the inputted json object
   */
  static apiResponseTransformer(rawJobResultItemJson) {
    return new JobResult(rawJobResultItemJson);
  }

  /**
   * @description Constructs a JobResult object
   *
   * @param {Object} options - object from the api response, attributes are as follows
   * @param {String} [options.id] - the id of the job result. If undefined, a unique id will be generated
   * @param {Object} options.input - the line number of the job result
   * @param {String} options.input.email - the admin's email
   * @param {String} options.input.groupName - the user group name
   * @param {Number} options.line - the line number of the job result
   * @param {String} options.status - the status of the job result
   * @param {Object[]} [options.failureDetails] - a list of failure details including the type and other details
   *
   */
  constructor(options) {
    Object.assign(this, options);
    this.id = options.id ?? `job-result-${uniqueId()}`;
  }

  #createFailureMessages(intl) {
    const hasFailureDetails = Array.isArray(this.failureDetails) && this.failureDetails.length > 0;
    return hasFailureDetails
      ? this.failureDetails.map((failureDetail) => createFailureDetailMessage(intl, failureDetail))
      : [intl.formatMessage({id: 'bulkOperationResult.error.generic.failure'})];
  }

  /**
   * @description Builds a list of the failure message details.
   *
   * @param {Intl} intl - react-intl object for translating results to plain text
   *
   * @returns {String[]} Array of failure messages, which may be empty if there are no messages.
   */
  getFailureDetailMessages(intl) {
    if (this.status === JOB_RESULT_ITEM_STATUS.FAILURE) {
      return this.#createFailureMessages(intl);
    }
    return [];
  }

  /**
   * @description Determines whether or not there is a license count exceeded failure
   *
   * @param {Object} failureType - failure type from from job response
   *
   * @returns {Boolean} whether or not there is a license count exceeded failure
   */
  hasLicenseCountExceededFailure() {
    return (
      this.failureDetails?.some((failureDetail) =>
        isLicenseCountExceededFailure(failureDetail.type)
      ) ?? false
    );
  }
}

/**
 * @description Method to construct a failure detail message for a job result
 *   item.
 *
 * @param {Intl} intl - react-intl object
 * @param {Object} failureDetail - failure details to construct message from
 *
 * @returns {Object} translated failure detail message
 */
function createFailureDetailMessage(intl, failureDetail) {
  const translateKeySuffix = isT2EJobfailure(failureDetail.type)
    ? `${failureDetail.type}_T2E`
    : failureDetail.type;

  const translateValues = createFailureDetailTranslateValues(failureDetail);

  const defaultMessage = intl.formatMessage(
    {id: 'bulkOperationResult.error.generic.failureWithCode'},
    {
      failureCode: translateKeySuffix,
    }
  );

  return intl.formatMessage(
    {defaultMessage, id: `bulkOperationResult.error.${translateKeySuffix}`},
    translateValues
  );
}

/**
 * @description Determines whether or not there is a T2E version of a failure
 *
 * @param {Object} failureType - failure type from from job response
 *
 * @returns {Boolean} whether or not the failure type should be the T2E version
 */
function isT2EJobfailure(failureType) {
  const t2eFailures = [
    JOB_ITEM_FAILURE_TYPE.INVALID_USER_TYPE,
    JOB_ITEM_FAILURE_TYPE.UNKNOWN_USER_TYPE,
  ];
  return (
    isMigrationListT2eClean(rootStore.organizationStore.migrationList) &&
    t2eFailures.includes(failureType)
  );
}

/**
 * @description Determines whether or not the passed in failure type is a
 *   license count exceeded failure.
 *
 * @param {Object} failureType - failure type from from job response
 *
 * @returns {Boolean} whether or not there is a license count exceeded failure
 */
function isLicenseCountExceededFailure(failureType) {
  return failureType === JOB_ITEM_FAILURE_TYPE.ALLOWABLE_LICENSE_COUNT_EXCEEDED;
}

/**
 * @description Method to create failure detail translation values for
 *   message. If failure type is related to exceeding the license count
 *   for a Product, this method appends the product name to the translated
 *   value returned.
 *
 * @param {Object} failureDetail - failure details to construct translation
 *   from
 *
 * @returns {Object} constructed failure detail translate value object
 */
function createFailureDetailTranslateValues(failureDetail) {
  if (isLicenseCountExceededFailure(failureDetail.type)) {
    return {
      // if 0, `for {productName}` is not appended to end of message
      count: failureDetail.context ? 1 : 0,
      productName: failureDetail.context?.product.shortName,
    };
  }

  return {};
}

export default JobResult;
