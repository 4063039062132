import {AnalyticsEvent, Organization, log} from '@admin-tribe/binky';
import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
  ValidatedTextField,
} from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

/**
 * The modal used to update the organization's name.
 */
const EditOrgNameModal = ({onSuccess, onClose, org}) => {
  const [modalError, setModalError] = useState(null);
  const [isSavingOrg, setIsSavingOrg] = useState(false);
  const [orgName, setOrgName] = useState(org.name);
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  // This version of RegEx() does not support DOTALL syntax `(?s)` that is used in renga.
  const isInvalid = useMemo(
    () => !orgName?.trim() || /[\S\s]*[\n\r;<>\\][\S\s]*/.test(orgName),
    [orgName]
  );

  const ctaToastGenerator = () =>
    intl.formatMessage({id: 'settings.consoleSettings.editOrgNameModal.toast.success'});

  const onCancel = () => {
    setModalError(null);
    onClose();
  };

  const dispatchAnalytics = () => {
    AnalyticsEvent.dispatch({
      componentMethod: 'changeOrgName',
      componentMethodType: 'submit',
      componentName: 'appChangeOrgNameModal',
    });
  };

  const getValidationMessage = () => {
    if (isInvalid) {
      if (!orgName?.trim()) {
        return intl.formatMessage({
          id: 'settings.consoleSettings.editOrgNameModal.textField.errorMessage.emptyString',
        });
      }
      return intl.formatMessage({
        id: 'settings.consoleSettings.editOrgNameModal.textField.errorMessage.invalidCharacters',
      });
    }
    return '';
  };

  const onCta = async () => {
    let isSuccess = false;
    setIsSavingOrg(true);

    try {
      if (orgName !== org.name) {
        await org.update({name: orgName});
        onSuccess(org);
        dispatchAnalytics();
        isSuccess = true;
      }
      onClose();
    } catch (error) {
      log.error('Error saving organization name: ', error);
      if (isCurrentRef.current) {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingOrg(false);
      }
    }
    // Return to trigger success toast
    return isSuccess;
  };

  return (
    <ModalDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      ctaLabel={intl.formatMessage({id: 'common.modal.buttons.save'})}
      ctaToastGenerator={ctaToastGenerator}
      errorMessage={modalError}
      id="edit-org-name-modal"
      isCtaDisabled={isInvalid}
      isLoading={isSavingOrg}
      onCancel={onCancel}
      onCta={onCta}
    >
      <ModalHeading>
        {intl.formatMessage({id: 'settings.consoleSettings.editOrgNameModal.header'})}
      </ModalHeading>
      <ModalDescription data-testid="description">
        {intl.formatMessage({id: 'settings.consoleSettings.editOrgNameModal.description'})}
      </ModalDescription>
      <ModalContent>
        <Flex direction="row" gap="size-300" marginTop="size-300">
          <ValidatedTextField
            label={intl.formatMessage({
              id: 'settings.consoleSettings.editOrgNameModal.textField.label',
            })}
            maxLength="255"
            onChange={setOrgName}
            validationMessage={getValidationMessage()}
            value={orgName}
            variant={isInvalid ? 'error' : 'confirmation'}
            wrapperProps={{width: '100%'}}
          />
        </Flex>
      </ModalContent>
    </ModalDialog>
  );
};

EditOrgNameModal.propTypes = {
  /**
   * Callback to invoke when the close or cta button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func,
  /**
   * The Organization whose name is being updated.
   */
  org: PropTypes.instanceOf(Organization).isRequired,
};

export default EditOrgNameModal;
