(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name SupportCaseList
   * @description Model for a support case list. This is not a paginated API hence it doesn't subclass List.
   */
  angular.module('app.core.support').factory('SupportCaseList', SupportCaseListModel);

  /* @ngInject */
  function SupportCaseListModel(
    $rootScope,
    MESSAGE,
    MODEL,
    modelCache,
    onesieSrc2,
    SUPPORT_TICKET_QUERY_PARAM,
    SupportCase,
    SupportTicketList
  ) {
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;
    class SupportCaseList extends SupportTicketList {
      /**
       * @description Constructor for SupportCaseList model.
       * @class
       *
       * @param {Object} options - options object, as described below
       * @param {String} [options.adminId] - Optional. If specified, the list will be
       *   filtered to include only tickets created by this admin.
       * @param {SUPPORT_TICKET_QUERY_PARAM} options.status - either ACTIVE to fetch open cases,
       *   or INACTIVE to fetch closed ones.
       */
      constructor(options) {
        super({
          adminId: options.adminId,
          cacheType:
            options.status === SUPPORT_TICKET_QUERY_PARAM.ACTIVE
              ? MODEL.SUPPORTCASELIST.ACTIVE
              : MODEL.SUPPORTCASELIST.INACTIVE,
          ItemClassRef: SupportCase,
          queryParams: {
            recordType: SUPPORT_CONSTANTS.LIST_ENTITY_NAME.CASES,
            status: options.status,
          },
          updateEvent: MESSAGE.UPDATE.SUPPORTCASELIST,
        });
      }

      /**
       * @description Fetches the list from cache if available, else from the backend.
       *
       * @param {Object} [options] - options for the list (per constructor documentation)
       * @returns {SupportCaseList} the fetched support case list
       */
      static get(options) {
        return SupportTicketList.get(SupportCaseList, options);
      }

      /**
       * @description Fetches the list from the backend as text/csv.
       *
       * @returns {String} the fetched support case list string data
       */
      static getCsv() {
        return SupportTicketList.getCsv(SUPPORT_CONSTANTS.LIST_ENTITY_NAME.CASES);
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.CREATE.SUPPORTCASE, () => {
      modelCache.removeAll(MODEL.SUPPORTCASELIST.ACTIVE);
    });

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.UPDATE.SUPPORTCASE, () => {
      modelCache.removeAll(MODEL.SUPPORTCASELIST.ACTIVE);
      modelCache.removeAll(MODEL.SUPPORTCASELIST.INACTIVE);
    });

    // We register the cache sizes for this class.
    modelCache.register(MODEL.SUPPORTCASELIST.ACTIVE, 2); // 1 for the entire list, 1 for the list filtered by admin
    modelCache.register(MODEL.SUPPORTCASELIST.INACTIVE, 1);

    return SupportCaseList;
  }
})();
