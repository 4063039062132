import {Button} from '@adobe/react-spectrum';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Tooltip from '@react/react-spectrum/Tooltip';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

const HIDE_DELAY = 800;

// A component that wraps a spectrum button and copies the
// given value to the clipboard when clicked.
const CopyToClipboardButton = ({
  children,
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- neculaes@ to update
  // istanbul ignore next - test when useEffect is lifted
  hideDelay = HIDE_DELAY,
  value,
  ...buttonProps
}) => {
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltip, setTooltip] = useState({
    message: intl.formatMessage({id: 'common.copyToClipboardButton.valueCopied'}),
    variant: 'success',
  });

  // Hide the tooltip after it has been shown
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- neculaes@ to update
  // istanbul ignore next - issues with timeout
  useEffect(() => {
    if (!showTooltip) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      setShowTooltip(false);
    }, hideDelay);

    return () => clearTimeout(timeoutId);
  }, [hideDelay, showTooltip]);

  const handleClick = async () => {
    try {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- neculaes@ to update
      await window.navigator.clipboard.writeText(value);

      setTooltip({
        message: intl.formatMessage({id: 'common.copyToClipboardButton.valueCopied'}),
        variant: 'success',
      });
    } catch (error) {
      setTooltip({
        message: intl.formatMessage({id: 'common.copyToClipboardButton.errorCopying'}),
        variant: 'error',
      });
    } finally {
      setShowTooltip(true);
    }
  };

  return (
    <OverlayTrigger placement="top" show={showTooltip}>
      {showTooltip}
      <Button onPress={handleClick} {...buttonProps} variant="secondary">
        {children}
      </Button>
      <Tooltip variant={tooltip.variant}>{tooltip.message}</Tooltip>
    </OverlayTrigger>
  );
};

CopyToClipboardButton.propTypes = {
  children: PropTypes.node,
  hideDelay: PropTypes.number,
  value: PropTypes.string,
};

export default CopyToClipboardButton;
