import {Flex, Text, TextField, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from '../../CreateServerModalContext';

/**
 * ServerInfo screen for create/edit server flow
 * it sets organization name while creating server
 */
const ServerInfo = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionData, setServerSessionDataValues, setServerSessionSettingValues} =
    packagesServerStore;
  const intl = useIntl();

  const setOrganizationName = (value) => {
    setServerSessionDataValues('organizationName', value);
    setServerSessionSettingValues('nextButtonEnabled', !!value);
  };

  return (
    <View marginTop="size-100">
      <Flex direction="column">
        <Text
          UNSAFE_style={{
            fontSize: 'var(--spectrum-global-dimension-size-200)',
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({
            id: 'packages.createServer.serverInfo.title',
          })}
        </Text>
        <Text>
          {intl.formatMessage({
            id: 'packages.createServer.serverInfo.titleDesc',
          })}
        </Text>
      </Flex>
      <Flex marginTop="size-150" width="size-6000">
        <TextField
          aria-label={intl.formatMessage({
            id: 'packages.createServer.serverInfo.title',
          })}
          onChange={setOrganizationName}
          value={serverSessionData.organizationName}
          width="100%"
        />
      </Flex>
    </View>
  );
});

export default ServerInfo;
