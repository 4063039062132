import {OverlayWait, StatusLight} from '@admin-tribe/binky-ui';
import {ActionButton, Flex, Text, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import CancelIcon from '@spectrum-icons/workflow/Cancel';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

// Displays the status of a given job with status light and ability to cancel processing
const BulkOperationsStatus = () => {
  const intl = useIntl();
  const {
    cancelJob,
    displayName,
    getIsCancelable,
    getJobStatusText,
    getJobStatusVariant,
    filename,
    isCanceling,
    isJobStatusNegative,
  } = useBulkOperationJob();
  const variant = getJobStatusVariant();

  return (
    <Flex columnGap="size-100">
      <StatusLight
        alignStart
        // to colorize text to red when an error has occurred
        UNSAFE_style={{
          color: isJobStatusNegative && 'var(--spectrum-semantic-negative-color-default)',
        }}
        variant={variant}
      >
        <Text>{getJobStatusText()}</Text>
      </StatusLight>
      {getIsCancelable() && (
        <OverlayWait isLoading={isCanceling}>
          <TooltipTrigger>
            <ActionButton
              aria-label={intl.formatMessage(
                {id: 'bulkOperations.results.status.actions.cancelProcessing.ariaLabel'},
                {
                  fileName: filename,
                  jobName: displayName,
                }
              )}
              isQuiet
              onPress={cancelJob}
            >
              <CancelIcon />
            </ActionButton>
            <Tooltip>
              {intl.formatMessage(
                {id: 'bulkOperations.results.status.actions.cancelProcessing'},
                {fileName: filename}
              )}
            </Tooltip>
          </TooltipTrigger>
        </OverlayWait>
      )}
    </Flex>
  );
};

export default BulkOperationsStatus;
