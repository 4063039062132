import binkyUI from '@admin-tribe/binky-ui';
import {View} from '@adobe/react-spectrum';
import React, {useEffect} from 'react';

import {ROLE} from '../../users.constants';

import AddAdminFindUserPage from './AddAdminFindUserPage';
import {useAdminModalContext} from './AddAdminModalContext';
import {setUserRole} from './adminRoleUtils';

import './AddAdminModal.pcss';

const AdminRolePicker = binkyUI.common.components.AdminRolePicker;

const AddAdminTeamPage = () => {
  const {user, orgId} = useAdminModalContext();

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    if (user) {
      setUserRole({role: ROLE.ADMIN.ORG, toggleState: true, user});
    }
  }, [user]);

  return (
    <AddAdminFindUserPage>
      <View marginTop="size-350">
        <AdminRolePicker adminRole={ROLE.ADMIN.ORG} defaultChecked disabled orgId={orgId} />
      </View>
    </AddAdminFindUserPage>
  );
};

export default AddAdminTeamPage;
