const CART_EVENT = {
  RENEWAL_ORDER_REFRESHED: 'RenewalOrderRefreshed',
  SUBMIT: 'CartSubmit',
  SUBMIT_RENEWAL_ORDER: 'CartSubmitRenewalOrder',
};
const ORDERS_API_EVENT = {
  ADD_ITEMS: 'ADD_ITEMS',
};
const ORDERS_API_EVENT_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
};
const ORDERS_API_PAYMENT_INSTRUMENT_CATEGORY = {
  SAVED: 'SAVED',
};
const ORDERS_API_PURCHASE_FLOW = {
  ADMIN_CONSOLE: 'admin_console-ONESIE1',
};
const PAYLOAD_KEY = {
  BILLABLE_ITEMS: 'billable_items',
  BILLING_SUMMARY: 'billing_summary',
  CART_ID: 'id',
  CONTRACT_DETAILS: 'contract_details',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  EXTERNAL_CONTRACT_ID: 'external_contract_id',
  IGNORE_OFFER_MAPPING: 'ignore_offer_mapping',
  LAST_MODIFIED_BY_ID: 'last_modified_by_id',
  OFFER_ID: 'offer_id',
  PAYMENT_AUTHORIZATION: 'payment_authorization',
  PO_NUMBER: 'customer_po_number',
  PROGRAM_CONTRACT_ID: 'id',
  STATUS: 'status',
};

export {
  CART_EVENT,
  ORDERS_API_EVENT,
  ORDERS_API_EVENT_STATUS,
  ORDERS_API_PAYMENT_INSTRUMENT_CATEGORY,
  ORDERS_API_PURCHASE_FLOW,
  PAYLOAD_KEY,
};
