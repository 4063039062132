import {feature} from '@admin-tribe/binky';
import {DialogContainer, Divider, Flex, Heading, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import SetupDirectoryAuthenticationModal from 'features/settings/components/setup-directory-authentication-modal/SetupDirectoryAuthenticationModal';
import useIdpName from 'features/settings/hooks/useIdpName';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

import IdpCardFooter from './IdpCardFooter';
import IdpCardMetadata from './IdpCardMetadata';
import IdpStatus from './IdpStatus';

import './IdpCard.pcss';

const IdpCard = observer(({idp, refreshData, ...actions}) => {
  const name = useIdpName(idp);
  const [isEditIdpModalOpen, setEditIdpModalOpen] = useState(false);

  const shouldShowFooter = feature.isEnabled('temp_hide_okta_actions') ? !idp.isOkta : true;

  // If we're in the middle of an external login
  // check to see what action is being done (editing an existing one)
  // and open the respective modal only for the azure idp
  useEffect(() => {
    if (ExternalAuthService.isLoginInProgress() && idp.isAzure) {
      const stateData = ExternalAuthService.getStateData();

      if (stateData?.isEditingAzureIdp) {
        setEditIdpModalOpen(true);
      }
    }
  }, [idp.isAzure]);

  return (
    <>
      <View
        backgroundColor="gray-50"
        borderColor="gray-200"
        borderRadius="small"
        borderWidth="thin"
        data-testid="idp-card"
        height="auto"
        padding="size-200"
        width="size-4600"
      >
        <Flex direction="column" height="100%">
          <IdpStatus idp={idp} />
          <Heading
            data-testid="idp-title"
            level={3}
            marginBottom="size-75"
            marginTop="size-0"
            minHeight="size-300"
          >
            {name}
          </Heading>
          <Divider size="S" />
          <IdpCardMetadata idp={idp} />
          {shouldShowFooter && (
            <IdpCardFooter
              callbacks={actions}
              idp={idp}
              onEdit={() => setEditIdpModalOpen(true)}
              refreshData={refreshData}
            />
          )}
        </Flex>
      </View>

      <DialogContainer onDismiss={() => setEditIdpModalOpen(false)}>
        {isEditIdpModalOpen && <SetupDirectoryAuthenticationModal idp={idp} />}
      </DialogContainer>
    </>
  );
});

export default IdpCard;
