import {Locale, feature, log, toPandoraProductList} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import {CLOUD, USER_ROLES} from '@pandora/administration-core-types';
import {hasProductsInCloud} from '@pandora/data-model-product';
import {
  SUPPORT_TICKET_QUERY_PARAM,
  useSupportTicketListHook,
} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import {filterSupportTicketList} from '@pandora/react-support-ticket';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import TeamQuickActions from '../team-quick-actions/TeamQuickActions';

import ExpertSessionQuickActions from './ExpertSessionQuickActions';
import SupportCaseQuickActions from './SupportCaseQuickActions';

const {LIST_ENTITY_NAME} = SUPPORT_CONSTANTS;

const {organizationStore} = rootStore;

/**
 * Shows the two cards with quick actions at the top of the support overview tab
 * It renders  <SupportCaseQuickActions/> and  <ExpertSessionQuickActions/>
 */
const SupportSummaryQuickActions = ({
  'data-testid': dataTestId,
  canDisplayChat,
  canAccessEnterpriseSupport,
  cloudType,
  onExpertSessionCreated,
  onGoToOpenCases,
  onGoToOpenSessions,
  onSupportCaseCreated,
}) => {
  const intl = useIntl();
  const auth = useAuth();
  const {activeOrgId, productList: binkyProductList} = rootStore.organizationStore;
  const {getSupportTicketList} = useSupportTicketListHook();

  const [supportCaseCount, setSupportCaseCount] = useState(0);
  const [expertSessionCount, setExpertSessionCount] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const isOrgOrSupportAdmin = userRoleUtils.anyOfForOrg(
    auth.getUserProfile().roles,
    [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN],
    activeOrgId
  );

  const productList = toPandoraProductList(binkyProductList);

  const productSupportRoleProductIds = userRoleUtils.getTargetsByRole(
    USER_ROLES.PRODUCT_SUPPORT_ADMIN,
    activeOrgId,
    auth.getUserProfile().roles
  );

  const getFilteredSupportTicketCount = (isExpertSession, supportTicketList) =>
    filterSupportTicketList(
      isExpertSession,
      isOrgOrSupportAdmin,
      productList,
      productSupportRoleProductIds,
      supportTicketList,
      auth.getUserProfile().userId
    ).length;

  React.useEffect(() => {
    const getOpenSupportTickets = (recordType) =>
      getSupportTicketList({
        locale: Locale.get().activeLocaleCode,
        orgId: activeOrgId,
        recordType,
        status: SUPPORT_TICKET_QUERY_PARAM.ACTIVE,
      });
    const getExpertSessionAndSupportCaseCounts = async () => {
      const result = await Promise.allSettled([
        getOpenSupportTickets(LIST_ENTITY_NAME.CASES),
        getOpenSupportTickets(LIST_ENTITY_NAME.EXPERTS),
      ]);

      if (result[0].status === 'fulfilled') {
        setSupportCaseCount(getFilteredSupportTicketCount(false, result[0].value.items));
      } else {
        log.error(
          `Unable to get support cases in SupportSummaryQuickActions. Reason: ${result[0].reason}`
        );
      }
      if (result[1].status === 'fulfilled') {
        setExpertSessionCount(getFilteredSupportTicketCount(true, result[1].value.items));
      } else {
        log.error(
          `Unable to get expert sessions in SupportSummaryQuickActions. Reason: ${result[1].reason}`
        );
      }
      setLoading(false);
    };
    getExpertSessionAndSupportCaseCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this only needs to run once
  }, []);

  if (canAccessEnterpriseSupport) {
    return (
      <Flex
        data-testid={dataTestId || 'support-summary-quick-actions'}
        direction="column"
        marginBottom="size-200"
      >
        <Flex>
          <SupportCaseQuickActions
            canDisplayChat={canDisplayChat}
            cloudType={cloudType}
            data-testid="support-cases-quick-actions"
            isLoading={isLoading}
            onGoToOpenCases={onGoToOpenCases}
            onSupportCaseCreated={onSupportCaseCreated}
            supportCaseCount={supportCaseCount}
          />
          <ExpertSessionQuickActions
            cloudType={cloudType}
            data-testid="expert-sessions-quick-actions"
            expertSessionCount={expertSessionCount}
            isLoading={isLoading}
            onExpertSessionCreated={onExpertSessionCreated}
            onGoToOpenSessions={onGoToOpenSessions}
          />
        </Flex>
        {hasProductsInCloud(organizationStore.productList, CLOUD.EXPERIENCE) && (
          <Flex direction="column">
            <GoUrl
              alignSelf="flex-start"
              data-testid="ac_ec_go_url"
              isQuiet
              marginStart="static-size-65"
              marginY="size-100"
              name="ac_ec_not_supported"
            >
              <Flex>
                <Text>
                  {intl.formatMessage({
                    id: 'support.quickActions.experienceCloudLearnMore',
                  })}
                </Text>
                <LinkOutLight isQuiet marginStart="size-100" marginTop="size-10" size="S" />
              </Flex>
            </GoUrl>
            {feature.isEnabled('temp_add_support_case_lifecycle_improvements') && (
              <GoUrl
                alignSelf="flex-start"
                data-testid="ac_dx_support_case_lifecycle_improvements"
                isQuiet
                marginStart="static-size-65"
                marginY="size-100"
                name="ac_dx_support_case_lifecycle_improvements"
              >
                <Flex>
                  <Text>
                    {intl.formatMessage({
                      id: 'support.quickActions.experienceCloudSupportCaseLifecycleImprovements',
                    })}
                  </Text>
                  <LinkOutLight isQuiet marginStart="size-100" marginTop="size-10" size="S" />
                </Flex>
              </GoUrl>
            )}
          </Flex>
        )}
      </Flex>
    );
  }

  return <TeamQuickActions onExpertSessionCreated={onExpertSessionCreated} />;
};

SupportSummaryQuickActions.propTypes = {
  /**
   * Whether or not user can access enterprise support
   */
  canAccessEnterpriseSupport: PropTypes.bool.isRequired,
  /**
   * Whether or not to display chat button
   */
  canDisplayChat: PropTypes.bool.isRequired,
  /**
   * Used to set cloud type, which is one of either the four "real" clouds defined
   * in @pandora/administration-core-types, "General" for "other products", or in the
   * Teams case: undefined
   */
  cloudType: PropTypes.oneOf([
    ...Object.keys(CLOUD),
    SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL,
  ]),
  /**
   * The specified data-testid for the component.
   * The default is 'support-case-quick-actions'.
   */
  'data-testid': PropTypes.string,
  /**
   * Callback function that's called after creating an expert session
   */
  onExpertSessionCreated: PropTypes.func.isRequired,
  /**
   * Callback function for navigating to open support cases
   */
  onGoToOpenCases: PropTypes.func.isRequired,
  /**
   * Callback function for navigating to open expert sessions
   */
  onGoToOpenSessions: PropTypes.func.isRequired,
  /**
   * Callback function that's called after creating a support case, gets caseID arg
   */
  onSupportCaseCreated: PropTypes.func.isRequired,
};

export default SupportSummaryQuickActions;
