(function () {
  /**
   * @ngdoc module
   * @name app.core.main.jobs
   * @description Module containing jobs components/resources
   */
  angular.module('app.main.jobs', [
    'app.core.jobs',
    'app.widgets.bulk-operations',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.shell.workspace.page',
    'binky.shell.workspace.page.header',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.select',
    'pascalprecht.translate',
  ]);
})();
