import {Flex, Text} from '@adobe/react-spectrum';
import UsersExcludeIcon from '@spectrum-icons/workflow/UsersExclude';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import useLoseAccessCard from '../../hooks/use-lose-access-card/useLoseAccessCard';
import SelfCancelCard from '../card/SelfCancelCard';

import styles from './Card.pcss';

const LoseAccessCard = ({selectedSeats}) => {
  const {numberOfUsers} = useLoseAccessCard({selectedSeats});
  const intl = useIntl();
  return (
    <SelfCancelCard
      headerComponent={
        <Flex alignItems="center" height="100%" justifyContent="center">
          <UsersExcludeIcon alt="" size="L" />
        </Flex>
      }
      testId="lose-access-card"
      title={intl.formatMessage({
        id: 'account.selfCancel.components.lossAversionCard.loseAccess.title',
      })}
    >
      <Flex direction="column" gap="size-300" justifyContent="space-between">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change text size */}
        <Text alignSelf="center" justifySelf="center" UNSAFE_className={styles['main-text']}>
          {intl.formatMessage(
            {id: 'account.selfCancel.components.lossAversionCard.loseAccess.contentTop'},
            {numberOfUsers}
          )}
        </Text>
        <Text>
          {intl.formatMessage({
            id: 'account.selfCancel.components.lossAversionCard.loseAccess.contentBottom',
          })}
        </Text>
      </Flex>
    </SelfCancelCard>
  );
};

LoseAccessCard.propTypes = {
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default LoseAccessCard;
