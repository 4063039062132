import {useEffect} from 'react';
import {useIntl} from 'react-intl';

const appTitleKey = 'main.headTitle';

/**
 * @description Custom hook that updates the browser page title
 * @param {String} opts.key - The translation key for the title
 * @param {String} opts.defaultTitle - A non-localizable string title
 * @param {Object} [opts.params={}] - params used for localizing title
 * @returns {Object}
 */
function useDocumentTitle({key, defaultTitle, params = {}}) {
  const intl = useIntl();

  // Set title on mount
  useEffect(() => {
    const appTitleText = intl.formatMessage({id: appTitleKey});

    // Don't update the title if neither value has been given yet
    if (key || defaultTitle) {
      const titleText = key ? intl.formatMessage({id: key}, params) : defaultTitle;

      // Don't repeat the app name
      document.title = titleText === appTitleText ? appTitleText : `${titleText} | ${appTitleText}`;
    } else {
      document.title = appTitleText;
    }
  }, [intl, params, defaultTitle, key]);
}

export default useDocumentTitle;
