/* eslint-disable max-lines -- large class with many static setup methods */
import {feature, jilProducts, jilUserGroups, jilUsers} from '@admin-tribe/binky';

import jilAssetMigrations from 'common/api/jil/jilAssetMigrations';
import {
  downloadUserGroupList,
  downloadUserList,
} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationUtils';
import {canSwitchIdentityTypes} from 'core/directories/access/directoryAccess';
import {canEditUser, isOrgAdmin, isReadOnly} from 'core/organizations/access/organizationAccess';
import {canRemoveUser} from 'core/user/access/userAccess';
import {
  goToProductDetailsBulkOps,
  goToProductProfileBulkOps,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {
  goToDirectoryUserBulkOps,
  goToUserGroupBulkOperations,
  goToUserGroupDetailsJobs,
  goToUsersBulkOperations,
  replaceStateUsers,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {getOfferSwitchMigration} from 'features/users/services/offer-switch-migration/offerSwitchMigrationHelper';

import {OPERATIONS, OPERATION_DOWNLOADED_FILENAMES} from './UserOperationsConstants';
import {exportAsyncCSV} from './userOperationsUtils';

const ANALYTICS_KEY_MAP = {
  [OPERATIONS.EXPORT_USERS]: 'downloadUsers',
  [OPERATIONS.EXPORT_ASSET_MIGRATIONS]: 'downloadAssetMigrations',
  [OPERATIONS.LICENSE_DEFICIT_REPORT]: 'downloadLicenseDeficitReport',
  [OPERATIONS.LICENSE_STATUS_REPORT]: 'downloadLicenseStatusReport',
};

const MSG_NAMESPACE_ROOT = 'users.userOperations';
const USER_GROUPS_MSG_NAMESPACE_ROOT = 'users.userOperations.userGroups';

// This class goes with the UserOperationsMenu component which is used for the various User pages/subpages.
// It is used to configure the menu and implement the corresponding actions.
// See ORDERED_BULK_OPERATIONS, ORDERED_EXPORT_OPERATIONS, ORDERED_RESULT_OPERATIONS for the menu layout.
class UserOperations {
  /**
   * Method to get the bulk operations for a directory users subpage.
   *
   * @param {Boolean} [canRemove] True if the admin can remove users by CSV. Default is false.
   * @param {String} directoryId The id of the directory.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static getDirectoryUserOperations({canRemove, directoryId}) {
    const userOperations = new UserOperations();

    userOperations.configureBulkOperations({
      canRemove,
    });

    userOperations.configureResultOperations({
      canViewResults: true,
      viewResultsFunction: () =>
        goToDirectoryUserBulkOps({
          directoryId,
        }),
    });

    return userOperations;
  }

  /**
   * Method to get the bulk operations for organization users, i.e. the 'Users' page.
   *
   * @param {BULK_OPERATION_MODE} [onInitBulkOperationMode] If set, the UserOperationsMenu, on init,
   *   will open the bulk operations menu in this mode.
   *   The assumption is that the route loader has already checked that this operation is allowed.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static async getOrgUserOperations({onInitBulkOperationMode}) {
    const promises = [];

    if (isOrgAdmin()) {
      const userOperations = new UserOperations();

      let isEligible = false;
      const promise = getOfferSwitchMigration();
      promises.push(promise);

      try {
        const result = await promise;
        isEligible = result.isEligible;
      } catch {}

      userOperations.configureBulkOperations({
        canAdd: false, // Users page has a seperate button
        canEdit: canEditUser(),
        canMigrateUsers: isEligible,
        canRemove: canRemoveUser(),
        canSwitchIdentityType: canSwitchIdentityTypes(),
        onBulkOperationClose: onInitBulkOperationMode ? replaceStateUsers : undefined,
        onInitBulkOperationMode, // The assumption is that the route loader has already checked that this operation is allowed.
      });

      userOperations.configureExportOperations({
        canDownloadAssetMigrations: canEditUser(),
        canExportUsers: !isReadOnly(), // true,
      });

      userOperations.configureResultOperations({
        canExportLicenseDeficitReport: !isReadOnly(),
        canExportLicenseStatusReport: !isReadOnly(),
        canViewResults: true,
        viewResultsFunction: () => goToUsersBulkOperations(),
      });

      userOperations.configureDownloadedReports({
        [OPERATIONS.EXPORT_USERS]: {
          exportFunction: feature.isEnabled('temp_async_export_users')
            ? exportAsyncCSV
            : jilUsers.exportUsers,
          filename: OPERATION_DOWNLOADED_FILENAMES.EXPORT_USERS,
        },
        [OPERATIONS.EXPORT_ASSET_MIGRATIONS]: {
          exportFunction: jilAssetMigrations.exportMigrations,
          filename: OPERATION_DOWNLOADED_FILENAMES.EXPORT_ASSET_MIGRATIONS,
        },
        [OPERATIONS.LICENSE_DEFICIT_REPORT]: {
          exportFunction: jilProducts.exportLicenseDeficitReport,
          filename: OPERATION_DOWNLOADED_FILENAMES.LICENSE_DEFICIT_REPORT,
        },
        [OPERATIONS.LICENSE_STATUS_REPORT]: {
          exportFunction: jilUsers.exportLicenseStatusReport,
          filename: OPERATION_DOWNLOADED_FILENAMES.LICENSE_STATUS_REPORT,
        },
      });

      // Wait for all to either be fulfilled or rejected.
      await Promise.allSettled(promises);

      return userOperations;
    }

    return null;
  }

  /**
   * Method to get the bulk operations for a team product users subpage.
   *
   * @param {Boolean} [canAdd] True if the admin can add users by CSV. Default is false
   * @param {Boolean} [canAddDisabled] True if the 'can add users by CSV' should be disabled in the menu.
   *   Default is false. For example, if all licenses are currently assigned.
   * @param {Boolean} [canUnassign] True if the admin can unassign users by CSV. Default is false.
   * @param {String} licenseGroupId The product profile id.
   * @param {String} offerId The offerId which is used for analytics.
   * @param {Product} product The product.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static getProductUserOperations({
    canAdd,
    canAddDisabled,
    canUnassign,
    licenseGroupId,
    offerId,
    product,
  }) {
    const userOperations = new UserOperations();

    if (product.isTeam()) {
      const productId = product.id;

      userOperations.configureBulkOperations({
        canAdd,
        canAddDisabled,
        canUnassign,
        offerId,
      });

      userOperations.configureExportOperations({
        canExportUsers: true,
      });

      userOperations.configureResultOperations({
        canViewResults: true,
        viewResultsFunction: () =>
          goToProductDetailsBulkOps({
            productId,
          }),
      });

      userOperations.configureDownloadedReports({
        [OPERATIONS.EXPORT_USERS]: {
          exportFunction: jilProducts.exportLicenseGroupUsers,
          exportParams: {
            groupId: licenseGroupId,
            productId,
          },
        },
      });
    }

    return userOperations;
  }

  /**
   * Method to get the bulk operations for a product profile users subpage.
   *
   * @param {Boolean} [canAdd] True if the admin can add users by CSV. Default is false
   * @param {Boolean} [canAddDisabled] True if the 'can add users by CSV' should be disabled in the menu.
   *   Default is false. For example, if all licenses are currently assigned.
   * @param {Boolean} [canUnassign] True if the admin can unassign users by CSV. Default is false.
   * @param {String} licenseGroupId The product profile id.
   * @param {String} offerId The offerId which is used for analytics.
   * @param {String} productId The product id.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static getProfileUserOperations({
    canAdd,
    canAddDisabled,
    canUnassign,
    licenseGroupId,
    offerId,
    productId,
  }) {
    const userOperations = new UserOperations();

    userOperations.configureBulkOperations({
      canAdd,
      canAddDisabled,
      canUnassign,
      offerId,
    });

    userOperations.configureExportOperations({
      canExportUsers: true, // can export once LicenseGroup in place
    });

    userOperations.configureResultOperations({
      canViewResults: true,
      viewResultsFunction: () =>
        goToProductProfileBulkOps({
          productId,
          profileId: licenseGroupId,
        }),
    });

    userOperations.configureDownloadedReports({
      [OPERATIONS.EXPORT_USERS]: {
        exportFunction: jilProducts.exportLicenseGroupUsers,
        exportParams: {
          groupId: licenseGroupId,
          productId,
        },
      },
    });

    return userOperations;
  }

  /**
   * Method to get the bulk operations for the 'User groups' page.
   *
   * @param {Boolean} [canAdd] True if the admin can add user groups by CSV. Default is false.
   * @param {Boolean} [canEdit] True if the admin can edit user groups by CSV. Default is false.
   * @param {Boolean} [canViewResults] True if the admin can view bulk operation results. Default is false.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static getUserGroupOperations({canAdd, canEdit, canViewResults}) {
    const userOperations = new UserOperations({
      msgNamespace: USER_GROUPS_MSG_NAMESPACE_ROOT,
    });

    userOperations.configureBulkOperations({
      canAdd,
      canEdit,
    });

    userOperations.configureExportOperations({
      canExportUsers: true,
      exportUsersFunction: downloadUserGroupList,
    });

    userOperations.configureResultOperations({
      canViewResults,
      viewResultsFunction: goToUserGroupBulkOperations,
    });

    userOperations.configureDownloadedReports({
      [OPERATIONS.EXPORT_USERS]: {
        exportFunction: jilUserGroups.exportUserGroups,
      },
    });

    return userOperations;
  }

  /**
   * Method to get the bulk operations for a user group users subpage.
   *
   * @param {Boolean} [canAdd] True if the admin can add user groups by CSV. Default is false.
   * @param {Boolean} [canUnassign] True if the admin can unassing user groups by CSV. Default is false.
   * @param {Boolean} [canViewResults] True if the admin can view bulk operation results. Default is false.
   * @param {String} userGroupId The id of the user group.
   * @returns {UserOperations} The UserOperations configuration for the UserOperationsMenu.
   */
  static getUserGroupUserOperations({canAdd, canUnassign, canViewResults, userGroupId}) {
    const userOperations = new UserOperations();

    userOperations.configureBulkOperations({
      canAdd,
      canUnassign,
    });

    userOperations.configureExportOperations({
      canExportUsers: true,
      exportUsersFunction: downloadUserGroupList,
    });

    userOperations.configureResultOperations({
      canViewResults,
      viewResultsFunction: () => {
        goToUserGroupDetailsJobs({userGroupId});
      },
    });

    userOperations.configureDownloadedReports({
      [OPERATIONS.EXPORT_USERS]: {
        exportFunction: jilUserGroups.exportUserGroupUsers,
        exportParams: {
          userGroupId,
        },
      },
    });

    return userOperations;
  }

  /**
   * The UserOperations class.
   * This class is used to configure the UserOperationsMenu for the pages which use it.
   *
   * @param {String} msgNamespace The namespace for the menu entry strings.
   *  Either MSG_NAMESPACE_ROOT or USER_GROUPS_MSG_NAMESPACE_ROOT.
   */
  constructor({msgNamespace = MSG_NAMESPACE_ROOT} = {}) {
    this.msgNamespace = msgNamespace;

    // The table more options / user operations menu
    this.configureBulkOperations();
    this.configureExportOperations();
    this.configureResultOperations();

    // The map of jilProviders, params, and the related analytics key.
    this.configureDownloadedReports();
  }

  configureBulkOperations({
    canAdd = false,
    canAddDisabled = false,
    canEdit = false,
    canSwitchIdentityType = false,
    canRemove = false,
    canMigrateUsers = false,
    canUnassign = false,
    offerId,
    onBulkOperationClose = undefined,
    onInitBulkOperationMode = undefined,
  } = {}) {
    // bulk modal: BULK_OPERATION_MODE.ADD
    this.canAdd = canAdd;
    this.canAddDisabled = canAddDisabled;

    // bulk modal=BULK_OPERATION_MODE.EDIT
    this.canEdit = canEdit;

    // bulk modal=BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE
    this.canSwitchIdentityType = canSwitchIdentityType;

    // bulk modal=BULK_OPERATION_MODE.REMOVE
    this.canRemove = canRemove;

    // bulk modal=BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION
    this.canMigrateUsers = canMigrateUsers;

    // bulk modal=BULK_OPERATION_MODE.UNASSIGN
    this.canUnassign = canUnassign;

    // For analytics. Set if product or profile users.
    this.offerId = offerId;

    this.onBulkOperationClose = onBulkOperationClose;

    // If set, the UsersOperationsMenu, on init, will open the bulk operations modal in this mode.
    this.onInitBulkOperationMode = onInitBulkOperationMode;
  }

  configureDownloadedReports(config = {}) {
    this.downloadReportConfig = config;

    // Fill in the analytic key for each report.
    Object.keys(this.downloadReportConfig).forEach((key) => {
      this.downloadReportConfig[key].analyticsKey = ANALYTICS_KEY_MAP[key];
    });
  }

  configureExportOperations({
    canDownloadAssetMigrations = false,
    canExportUsers = false,
    exportUsersFunction = downloadUserList,
  } = {}) {
    // OPERATIONS.EXPORT_USERS
    this.canExportUsers = canExportUsers;
    this.exportUsersFunction = exportUsersFunction;

    // OPERATIONS.EXPORT_ASSET_MIGRATIONS
    this.canDownloadAssetMigrations = canDownloadAssetMigrations;
  }

  configureResultOperations({
    canViewResults = false,
    viewResultsFunction = undefined,
    canExportLicenseDeficitReport = false,
    canExportLicenseStatusReport = false,
  } = {}) {
    // OPERATIONS.OPERATION_RESULTS
    this.canViewResults = canViewResults;
    this.viewResultsFunction = viewResultsFunction;

    // OPERATIONS.LICENSE_DEFICIT_REPORT
    this.canExportLicenseDeficitReport = canExportLicenseDeficitReport;

    // OPERATIONS.LICENSE_STATUS_REPORT
    this.canExportLicenseStatusReport = canExportLicenseStatusReport;
  }

  getDownloadReportConfig(key) {
    return this.downloadReportConfig?.[key];
  }
}

export default UserOperations;
export {ANALYTICS_KEY_MAP};

/* eslint-enable max-lines -- large class with many static setup methods */
