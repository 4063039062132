import {
  AuthenticatedUser,
  CONTRACT_STATUS,
  Locale,
  configStore,
  feature,
  log,
} from '@admin-tribe/binky';
import {ModalContainer} from '@admin-tribe/binky-ui';
import {Provider, lightTheme} from '@adobe/react-spectrum';
import {MessageType, SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MatDialog, MatDialogContainer} from '@pandora/react-mat-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {SELF_CANCEL_STEPS} from 'features/account/self-cancel/SelfCancelConstants';

import {isPaidSelfCancelEligible} from '../../account/self-cancel/SelfCancelUtils';
import BumperStep from '../../account/self-cancel/bumper-step/BumperStep';
import {ProductsChangeContextProvider} from '../../account/self-cancel/components/products-change-context/ProductsChangeContext';
import {SelfCancelAnalyticsContextProvider} from '../../account/self-cancel/components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import useProductListForCancellation from '../../account/self-cancel/hooks/use-product-list-for-cancellation/useProductListForCancellation';

// eslint-disable-next-line no-unused-vars -- Need for styles in iframe
import styles from './CancelPlanModalWrapper.pcss';

const CANCEL_PLAN_MINI_APP_NAME = 'CancelPlanMiniApp';
const CANCEL_PLAN_MINI_APP_URL_ENV_PARAM = 'services.cancelPlanMiniApp';

/**
 * If the client passes the contractId, use that.
 * If not, find the contractId for the org if it has only one contract and that too is active.
 *
 * @param {String} contractId - Deeplink with contract id
 *
 * @returns contractId if found otherwise undefined
 */
const getActiveContractForMiniApp = (contractId) => {
  const {contractList} = rootStore.organizationStore;

  if (contractId) {
    return contractList.items.find((contract) => contract.id === contractId);
  }

  if (contractList.items.length === 1 && contractList.items[0].status === CONTRACT_STATUS.ACTIVE) {
    return contractList.items[0];
  }

  return undefined;
};

/**
 * Based on the FF, this function returns the respective config obj.
 *
 * @returns config from configStore
 */
const getMiniAppConfiguration = () => configStore.get(CANCEL_PLAN_MINI_APP_URL_ENV_PARAM);

/**
 * Setup Cancel Plan Mini App URL
 * See Parameters - https://git.corp.adobe.com/PandoraUI/commerce-mini-apps/blob/master/packages/react-mini-app-cancel-plan/README.md
 *
 * @param {String} contractId - Deeplink with contract id
 * @param {String} productId - Deeplink with product id
 *
 * @returns Cancel Plan Mini App URL
 */
const setUpMiniAppUrl = ({contractId, orgId, productId}) => {
  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = getMiniAppConfiguration();

  // App Context Identifier as Mini App is loaded in iframe
  const iFrameCtx = 'if';

  // Basic parameters
  const params = {
    cli: 'ONESIE1',
    ctrId: contractId,
    ctx: iFrameCtx,
    curPid: productId,
    lang: localeLanguage,
    orgId,
    userId: AuthenticatedUser.get().getId(),
  };

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);

    // Forward client ID to add product mini app
    if (urlParams.get('cli')) {
      params.cli = urlParams.get('cli');
    }
  }

  return `${url}?${qs.stringify(params, {skipNulls: true})}`;
};

/**
 * @description Hook to manage the state of cancel plan modal
 */
const useCancelPlanModalContext = ({onClose}) => {
  // Show or close the modal dialog based on status of cancel plan workflow
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(true);

  // Message handler to process just the System events from cancel plan Mini App
  const systemMessageHandler = useCallback(
    ({subType}) => {
      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case SystemMessageSubType.APP_CLOSED:
          setShowCancelPlanModal(false);
          onClose?.();
          break;
      }
    },
    [onClose]
  );

  return {showCancelPlanModal, systemMessageHandler};
};

/**
 * @description Hook to handle the messages from cancel plan mini app
 */
const useCancelPlanMessageHandler = ({systemMessageHandler}) => {
  // Message handler to process the events from cancel plan Mini App
  const messageHandler = useCallback(
    ({app, type, subType, data}) => {
      // Ignore the message if it is not from cancel plan Mini App
      if (app !== CANCEL_PLAN_MINI_APP_NAME) return;

      log.info('Message from Cancel Plan Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      } else if (type === MessageType.NAVIGATION) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
        window.open(data.externalUrl, data.target);
      }
    },
    [systemMessageHandler]
  );

  // Consume message from cancel plan Mini App iframe
  useIframeMessage(messageHandler);
};

/**
 * @description Hook to get the product list for cancellation
 */
const useGetProductListForCancellation = ({orgId}) => {
  const [initialized, setInitialized] = useState(false);
  const [products, setProducts] = useState([]);

  const {productList} = useProductListForCancellation({
    orgId,
  });

  // Initialize the products list
  useEffect(() => {
    if (initialized || !productList) return;

    setInitialized(true);
    setProducts(productList);
  }, [initialized, productList]);

  return {initialized, products};
};

/**
 * @description Check if the bumper step should be shown
 */
const shouldShowBumperStep = (contract, productList) =>
  !feature.isEnabled('temp_force_enable_self_cancel') &&
  !!contract &&
  !isPaidSelfCancelEligible(contract, productList);

/**
 * @description Represents a wrapper for Cancel Plan Mini App
 *
 * @param {String} contractId - Optional deeplink with contract id
 * @param {Function} onClose - Callback to run when the modal is closed
 * @param {Function} onStartChat - Optional callback to run when the chat is started
 * @param {String} productId - Deeplink with product id
 */
const CancelPlanModalWrapper = ({contractId, onClose, onStartChat, productId}) => {
  const intl = useIntl();

  const {organizationStore} = rootStore;
  const contract = getActiveContractForMiniApp(contractId);

  const activeContractId = contract?.id;
  const activeOrgId = contract?.orgId ?? organizationStore.activeOrgId;

  const {initialized, products} = useGetProductListForCancellation({orgId: activeOrgId});

  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl({contractId: activeContractId, orgId: activeOrgId, productId}),
    [activeContractId, activeOrgId, productId]
  );

  const {showCancelPlanModal, systemMessageHandler} = useCancelPlanModalContext({
    onClose,
  });
  useCancelPlanMessageHandler({systemMessageHandler});

  if (!initialized || !showCancelPlanModal) return null;

  if (shouldShowBumperStep(contract, products)) {
    const onStartChatCloseModal = (...args) => {
      onStartChat?.(...args);
      onClose?.();
    };

    return (
      <ModalContainer>
        <ProductsChangeContextProvider contractId={contract.id} orgId={contract.orgId}>
          <SelfCancelAnalyticsContextProvider
            comment=""
            currentStep={SELF_CANCEL_STEPS.BUMPER_STEP}
            productList={products}
            selectedReasons=""
          >
            <BumperStep onPrevious={onClose} onStartChat={onStartChatCloseModal} />
          </SelfCancelAnalyticsContextProvider>
        </ProductsChangeContextProvider>
      </ModalContainer>
    );
  }

  return (
    <Provider colorScheme="light" theme={lightTheme}>
      <MatDialogContainer
        isDismissable={false}
        isKeyboardDismissDisabled
        variant={{base: 'takeover', M: 'overlay'}}
      >
        <MatDialog aria-label={intl.formatMessage({id: 'account.cancelPlan.dialog.title'})}>
          <iframe
            src={miniAppUrl}
            styleName="cancel-plan-iframe"
            title={intl.formatMessage({id: 'account.cancelPlan.frame.title'})}
          />
        </MatDialog>
      </MatDialogContainer>
    </Provider>
  );
};

CancelPlanModalWrapper.propTypes = {
  /**
   * Optional id which determines the active contract
   */
  contractId: PropTypes.string,
  /**
   * Handler that is called when the cancel-plan modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Optional handler that is called when the chat is started.
   */
  onStartChat: PropTypes.func,
  /**
   * Id which determines the product to cancel
   */
  productId: PropTypes.string.isRequired,
};

export default CancelPlanModalWrapper;
