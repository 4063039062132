import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {EDU_SYNC_STATUSES} from 'features/settings/components/edu-sync-card/EduSyncStatus';

/**
 * Private Context for the DirectorySync page and its sub-components.
 * Used to pass the eduRosterSyncs between sub-components.
 */

const RosterSyncContext = createContext({});
const useRosterSyncContext = () => useContext(RosterSyncContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- this seems like a false positive
const RosterSyncContextProvider = ({children, eduRosterSyncs}) => {
  const [eduRosterSyncsList, setEduRosterSyncsList] = useState(eduRosterSyncs);

  const updateRosterSyncData = useCallback(
    ({syncId, updatedSyncData}) =>
      setEduRosterSyncsList((prevState) => {
        const syncIndex = prevState.data.findIndex((sync) => sync.syncId === syncId);

        return {
          ...prevState,
          data: [
            ...prevState.data.slice(0, syncIndex),
            updatedSyncData,
            ...prevState.data.slice(syncIndex + 1, prevState.data.length),
          ],
        };
      }),
    []
  );

  // update eduRosterSyncsList when eduRosterSyncs prop changes
  useEffect(() => {
    setEduRosterSyncsList(eduRosterSyncs);
  }, [eduRosterSyncs]);

  const value = useMemo(
    () => ({
      eduRosterSyncs: eduRosterSyncsList,
      setEduRosterSyncs: setEduRosterSyncsList,
      updateRosterSyncData,
    }),
    [eduRosterSyncsList, setEduRosterSyncsList, updateRosterSyncData]
  );

  return <RosterSyncContext.Provider value={value}>{children}</RosterSyncContext.Provider>;
};

RosterSyncContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the eduRosterSyncs
   */
  children: PropTypes.node,
  /**
   * The list of eduRosterSyncs
   */
  eduRosterSyncs: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The ID of the school district as registered with the roster provider
         */
        districtId: PropTypes.string.isRequired,
        /**
         * Denotes the current state of the edu roster sync
         */
        enabled: PropTypes.bool.isRequired,
        /**
         * The unique identifier for the IMS Org
         */
        imsOrgId: PropTypes.string.isRequired,
        /**
         * The timestamp for the last sync. It's null if no sync happened yet
         */
        lastSyncedAt: PropTypes.string,
        /**
         * Denotes an Adobe product
         */
        products: PropTypes.arrayOf(
          PropTypes.shape({
            productAppName: PropTypes.string,
            productName: PropTypes.string,
          }).isRequired
        ),
        /**
         * The roster sync provider. The supported providers are Clever and Classlink
         */
        rosterSource: PropTypes.string.isRequired,
        /**
         * Denotes the current status of the edu roster sync
         */
        status: PropTypes.oneOf(Object.values(EDU_SYNC_STATUSES)).isRequired,
        /**
         * The unique identifier for a edu roster sync
         */
        syncId: PropTypes.string.isRequired,
      })
    ).isRequired,
    isLoading: PropTypes.bool,
  }),
};

export {RosterSyncContextProvider, useRosterSyncContext, RosterSyncContext};
