import {authentication, log} from '@admin-tribe/binky';
import {getAcrsFetch, getAllAcrsRules} from '@pandora/react-data-source-acrs';
import {action, makeObservable, observable, runInAction} from 'mobx';

import auth from 'core/services/auth';

let orgId, singleton;

/**
 * This class mainly exists to allow the older Angular codebase of AdminConsole to reuse the
 * newer ACRS API call logic defined in @pandora/react-data-source-acrs, in a limited fashion.
 * It also acts as a limited cache-solution for src2 React code while Pandora figures out how caching will work.
 * It is believed that without Angular and with a Pandora caching solution, this class will no longer be needed,
 * as src2 React code could call the API directly.
 * It will retreive all the automatic-product-assignment rules for the given Org, and cache the result.
 * It will not update this cache unless ordered to or on orgId change.
 */
class AutoAssignRulesCache {
  /**
   * @description Method for getting the AutoAssignRulesCache singleton
   * @returns {AutoAssignRulesCache|undefined} the AutoAssignRulesCache fetched from cache
   */
  static get() {
    return singleton;
  }

  autoAssignRules = [];
  envConfig;

  /**
   * Constructs the FeaturesCache with a config and an initial orgId
   * @param {Object} envConfig  - the raw configuration of a specific environment
   * @param {String} envConfig.pandoraEnv  - the Pandora environment to use for this environment
   * @param {String} envConfig.services.ims.clientId  - the IMS client id to call ACRS
   * @param {String} initOrgId - this is the initial orgId
   */
  constructor(envConfig, initOrgId) {
    makeObservable(this, {
      autoAssignRules: observable,
      refresh: action,
    });

    this.envConfig = envConfig;
    singleton = this;
    orgId = initOrgId;
  }

  /**
   * This method makes the API call and caches the resulting auto-assignment rules
   */
  async refresh() {
    if (auth.isUserOrgAdmin()) {
      try {
        const acrsFetch = getAcrsFetch({
          accessToken: authentication.getAccessToken().token,
          apiKey: this.envConfig.services.ims.clientId,
          env: this.envConfig.pandoraEnv,
          // This fetch override is needed because this class is called outside of a binky react wrapper,
          // which takes care of doing this for other Pandora data providers. The default Pandora fetch
          // cannot be correctly mocked for our fakes environment.
          // Need to replace fetch provider with window.fetch, like:
          // https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src2/app/features/insights/insightsStore.js#L205
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
          fetch: window.fetch.bind(window),
        });

        const fetchResponse = await getAllAcrsRules(acrsFetch, {orgId});
        const autoAssignRules = await fetchResponse.json();

        runInAction(() => {
          this.autoAssignRules = autoAssignRules;
        });
      } catch (error) {
        log.error(
          'Failed to refresh data within the AutoAssignRulesCache class. Error:',
          error,
          orgId
        );
      }
    } else {
      // If the user isn't an org admin, clear any cached rules, since they aren't allowed
      // to do anything with the rules.
      this.autoAssignRules = [];
    }
  }

  /**
   * This method is called to set a new orgId when the org has changed
   * @param {String} newOrgId
   */
  async setOrgId(newOrgId) {
    orgId = newOrgId;
    await this.refresh();
  }
}
export default AutoAssignRulesCache;
