import {
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PageContext,
  UserGroupLicenseGroupsList,
} from '@admin-tribe/binky';
import {Subpage} from '@admin-tribe/binky-ui';
import React from 'react';
import {useLoaderData} from 'react-router-dom';

import useJilModelList from 'common/hooks/useJilModelList';
import ProductProfileTableSection from 'features/products/product-profiles/product-profile-table-section/ProductProfileTableSection';
import {ProductProfileTableContextProvider} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

/**
 *  The User group 'Assigned product profiles' Subpage component which is displayed in a TabbedNav.
 */
const UserGroupAssignedProfilesSubpage = () => {
  const {userGroup} = useLoaderData();

  const pageContext = new PageContext({
    target: PAGE_TARGET.USER_GROUP,
    targetId: userGroup.id,
    targetType: PAGE_TARGET_TYPE.PRODUCT_CONFIGURATION,
  });

  const {
    error: listError,
    dispatchTableSectionChange: onTableSectionChange,
    isLoading: isLoadingList,
    list: licenseGroupList,
  } = useJilModelList({
    ListClassRef: UserGroupLicenseGroupsList,
    listOptions: {
      groupId: userGroup.id,
    },
  });

  const initialLoad = licenseGroupList.items === null && isLoadingList;

  // Don't bumper the page on API error. Let the table show the server error.
  return (
    <Subpage data-testid="user-group-assigned-profiles-subpage" isLoading={initialLoad}>
      <ProductProfileTableContextProvider
        hasError={!!listError}
        isLoading={!initialLoad && isLoadingList}
        licenseGroupList={licenseGroupList}
        onTableSectionChange={onTableSectionChange}
        pageContext={pageContext}
      >
        <ProductProfileTableSection />
      </ProductProfileTableContextProvider>
    </Subpage>
  );
};

export default UserGroupAssignedProfilesSubpage;
