(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc service/provider
   * @name jilContentLogs
   * @description defines service to manage jil conteng logs resources
   */
  angular.module('app.core.api.jil').provider('jilContentLogs', jilContentLogsProvider);

  let url;

  /* @ngInject */
  function jilContentLogsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        contentLogs: getContentLogsResource(),
      };

      return service;

      ///////////

      function getContentLogsResource() {
        return $resource(
          `${url}/:orgId/content-logs/:id`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
          },
          {
            create: {
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
