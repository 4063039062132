(function () {
  /**
   * @deprecated with temp_react_port_jobs
   *
   * @ngdoc service/provider
   * @name jilJobs
   * @description defines service to manage jil job resources
   */
  angular.module('app.core.api.jil').provider('jilJobs', jilJobsProvider);

  let url;

  /* @ngInject */
  function jilJobsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        jobResult: getJobResultResource(),
        jobs: getJobsResource(),
      };

      return service;

      ///////////

      function getJobResultResource() {
        return $resource(
          `${url}/:orgId/jobs/:jobId/result`,
          {
            jobId: '@jobId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            export: {
              headers: {
                Accept: 'text/csv+organization-job-result,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }

      function getJobsResource() {
        return $resource(
          `${url}/:orgId/jobs/:jobId`,
          {
            jobId: '@jobId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }
    }
  }
})();
