import {Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import InfoItemContainer2 from 'features/users/components/info-item-container-2/InfoItemContainer2';

/**
 * InfoItemScorecard is a widget which displays a label and a numeric value or a React element.
 *
 * If the value is a finite number, it will be run through formatNumber.
 * For example, the number 1000 will be displayed as 1,000 for the English locale.
 *
 * If the value is not a finite number, for example, null/undefined/NaN, an en-dash will be
 * displayed in place of the number.
 *
 * While waiting for a initial value from an asynchronously loaded list, consider rendering this with a nil value which
 * will display an en-dash until the value is set.
 */
const InfoItemScorecard2 = ({
  children,
  'data-testid': dataTestId = 'info-item-scorecard',
  label,
  value,
}) => {
  const intl = useIntl();
  const ENDASH_STR = '\u2013';

  const valueToDisplay = Number.isFinite(value) ? intl.formatNumber(value) : ENDASH_STR;

  return (
    <InfoItemContainer2 data-testid={dataTestId} label={label}>
      <Text data-testid="info-item-value">{valueToDisplay}</Text>
      {children}
    </InfoItemContainer2>
  );
};

InfoItemScorecard2.propTypes = {
  /**
   * A drawer. The 'View details' drawer trigger will be displayed after the value.
   */
  children: PropTypes.element,
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string.isRequired,
  /**
   * The numeric value or a React element.
   */
  value: PropTypes.number,
};

export default InfoItemScorecard2;
