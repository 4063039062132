import PropTypes from 'prop-types';
import React from 'react';

import {DomainsListContextProvider} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import DirectoryDomainsTable2 from 'features/settings/components/directory/domains/directory-domains-table/DirectoryDomainsTable2';
import useDirectoryDomainsListState from 'features/settings/hooks/domains/useDirectoryDomainsListState';

const DirectoryDomains2 = ({directoryData}) => {
  const listState = useDirectoryDomainsListState(directoryData);

  return (
    <DomainsListContextProvider listState={listState}>
      <DirectoryDomainsTable2 />
    </DomainsListContextProvider>
  );
};

DirectoryDomains2.propTypes = {
  directoryData: PropTypes.shape({
    directoryId: PropTypes.string,
    directoryName: PropTypes.string,
    domainEnforcement: PropTypes.shape({
      /**
       * The ID of the Directory
       */
      authSrc: PropTypes.string.isRequired,
      /**
       * Date when opt-out phase ends
       */
      notifyAt: PropTypes.number,
      /**
       * Current status of the Policy
       */
      state: PropTypes.string.isRequired,
      /**
       *  Date when the current state phase ends
       */
      stateEndsAt: PropTypes.number,
    }),
    isType2E: PropTypes.bool,
  }),
};

export default DirectoryDomains2;
