(function () {
  /**
   * @deprecated Please use src2/core/services/product/SolutionGroupList.js
   *
   * @ngdoc factory
   * @name SolutionGroupList
   * @description Model for the solution group which returns list of permission groups for product profile
   */
  angular
    .module('app.core.solution-group-list')
    .factory('SolutionGroupList', getSolutionGroupModel);

  /* @ngInject */
  function getSolutionGroupModel(
    $q,
    $rootScope,
    _,
    binkySrc2,
    MODEL,
    modelCache,
    OrganizationManager,
    PRODUCT_CONFIGURATIONS_EVENT
  ) {
    class SolutionGroupList extends binkySrc2.services.product.solutionGroupList.SolutionGroupList {
      constructor(productId) {
        super(productId, OrganizationManager.getActiveOrgId());
        this.$resolved = false;
      }

      key() {
        return this.productId;
      }

      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => {
              modelCache.put(MODEL.SOLUTIONGROUPLIST, this, this.key());
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
      }

      static get(productId) {
        const model = new SolutionGroupList(productId);

        const cachedModel = modelCache.get(MODEL.SOLUTIONGROUPLIST, model.key());
        if (cachedModel) {
          return cachedModel;
        }
        model.refresh();

        return model;
      }
    }

    /** *** Event listeners/handlers *****/
    _.forEach([PRODUCT_CONFIGURATIONS_EVENT.UPDATE], (message) => {
      // Events do not have de-register callbacks intentionally
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(message, removeSolutionGroupList);
    });

    // We register the cache size for this class
    modelCache.register(MODEL.SOLUTIONGROUPLIST, 1);
    return SolutionGroupList;
    /**
     * @description Method to remove a SolutionGroupList instance from cache.
     *
     * @param {Object} event that triggered this cache removal
     * @param {String} productId param for removing from the cache
     */
    function removeSolutionGroupList(event, productId) {
      modelCache.remove(MODEL.SOLUTIONGROUPLIST, productId);
    }
  }
})();
