import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import CreatePackageModalStore from '../../stores/CreatePackageModalStore';

/**
 * Private Context for the Create package Modal and its sub-components.
 * Used to pass the createPackageModalStore between sub-components.
 */

const CreatePackageModalContext = createContext(null);
const useCreatePackageModalContext = () => useContext(CreatePackageModalContext);

const CreatePackageModalContextProvider = ({children, createPackageModalStore}) => {
  const value = useMemo(() => ({createPackageModalStore}), [createPackageModalStore]);

  return (
    <CreatePackageModalContext.Provider value={value}>
      {children}
    </CreatePackageModalContext.Provider>
  );
};

CreatePackageModalContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node.isRequired,
  /**
   * The domain store for Packages Adobe Templates.
   */
  createPackageModalStore: PropTypes.instanceOf(CreatePackageModalStore).isRequired,
};

export {CreatePackageModalContextProvider, useCreatePackageModalContext};
