import {getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

import rootStore from 'core/RootStore';

let clientId, includeRoles, url;

/**
 * Configure JIL storage repositories APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Fetches the organization's storage repositories.
 *
 * @return {Promise} a promise which will resolve with the response.
 */
const getStorageRepositories = () =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-repositories`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Updates the organization's storage repositories.
 *
 * @param {Array<{op: String, name: String, value: String}>} operations - an array of operations to update repositories.
 * @return {Promise} a promise which will resolve with the response.
 */
const patchStorageRepositories = (operations) =>
  axios.patch(
    `${url}/${rootStore.organizationStore.activeOrgId}/storage-repositories`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilStorageRepositories = {
  configure,
  getStorageRepositories,
  patchStorageRepositories,
};

export default jilStorageRepositories;
