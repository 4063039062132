/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSelectSessionTimesSection
 *
 * @description The section for selecting 3 times to schedule an Expert Session.
 *
 * @binding {Function} onChange. Callback for when one of the datepicker values change.
 *   The callback should have args, (index, valueAsISOString).
 *   Index is (0-2) and valueAsISOString is the selected local time as an ISO date string.
 */
(function () {
  angular.module('app.widgets.support').component('appSessionTimesFormSection', {
    bindings: {
      onChange: '&',
    },
    controller,
    templateUrl:
      'app/widgets/support/request-expert-session-modal/session-times-form-section/session-times-form-section.component.html',
  });

  function controller(_, AnalyticsEvent, moment) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onDatepickerChange,
    });

    function $onInit() {
      // The first selectable date is the day after tomorrow.
      // The Coral Datepicker calendar piece of the component ignores the time portion when
      // disabling days so best to set minDate at midnight so a selected date/time vs an input
      // date/time will have the same 'isDateInRange' validity check.
      vm.minDateAsMoment = moment().startOf('day').add(2, 'days'); // this wraps a Date obj - the Coral datepicker accepts either a moment or a Date object
      vm.maxDateAsMoment = moment().startOf('day').add(1, 'Y');
    }

    // Note the change event is not fired until the datepicker loses focus.
    // If the date is typed in and it has an invalid format, then isValid will be false, valueAsDate
    // will be null, and the input will be cleared and outlined in red.
    function onDatepickerChange(index, isInvalid, valueAsDate) {
      const valueAsISOString = isInvalid || !valueAsDate ? null : valueAsDate.toISOString();
      AnalyticsEvent.dispatch({
        attributes: {
          item: index + 1,
        },
        componentMethod: 'selectDayandTime',
        componentMethodType: 'click',
      });
      vm.onChange({index, valueAsISOString});
    }
  }
})();
