/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Quick Assign modal
   *
   * @ngdoc component
   * @name app.widgets.users.quick-assign-email-list-form:appQuickAssignEmailListForm
   *
   * @description a component representing a form with which a user can assign licenses
   * to users in the Quick Assign Modal
   *
   * @binding {String} disabledTooltipKey, the key string that will be translated to explain
   *                   why the input field is disabled
   * @binding {Function} onFormStatusChange, function called to update the status of the
   *                     form as valid/invalid
   * @binding {Function} onUsersChange, function that sets the updated orgUserList anytime
   *                     a change is made in the form
   * @binding {Array} preassignedUsers, an array of OrganizationUsers whose products are preassigned
   * @binding {Array} productDataList, contains a list of objects representing product information
   *                  for each product that has available and assignable licenses
   */
  const componentName = 'appQuickAssignEmailListForm';
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      disabledTooltipKey: '@?',
      onFormStatusChange: '&',
      onSwitchToCsv: '&',
      onUsersChange: '&',
      preassignedUsers: '<?',
      productDataList: '<',
      submissionError: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/users/quick-assign-email-list-form/quick-assign-email-list-form.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $q,
    $rootScope,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    ORG_USER_ERROR_CODE,
    OrganizationUser,
    OrganizationUserHelper,
    QuickAssignHelper,
    Selection,
    SELECTION_EVENT
  ) {
    const vm = this;
    vm.$onInit = $onInit;
    const DEBOUNCE_TIME = 1000;
    const SEARCH_QUERY_MIN_LENGTH = 3;
    const ERROR_CODE_EMAIL_TAKEN = 'emailAlreadyTaken';
    const deregisterSelectionHandler = $rootScope.$on(
      SELECTION_EVENT.UPDATE,
      onProductSelectionChange
    );

    // Show option to switch to CSV form if over cutoff
    const SMALL_SMB_LICENSE_COUNT_CUTOFF = 10;

    /////////////

    function $onInit() {
      _.assign(vm, {
        $onDestroy,
        areProductAndUsersDataFulfilled,
        getMaxAddedUsersWarningString,
        getRowNumber,
        getUsersCount,
        MAX_USERS_ADDED: 10,
        onRowInputChange,
        PRODUCTS_CASE: {
          MANY: 'manyProductsCase',
          ONE: 'oneProductCase',
        },
        productToLicenseCountMap: {},
        switchToCsv,
      });

      if (vm.productDataList.length > 1) {
        vm.productsCase = vm.PRODUCTS_CASE.MANY;
      } else {
        vm.productsCase = vm.PRODUCTS_CASE.ONE;
      }

      // generate new rows with preassigned users with preselected and disabled products and update its state
      vm.rowsDetails = _.map(vm.preassignedUsers, (userToPrefill) => generateNewRow(userToPrefill));
      _.forEach(vm.rowsDetails, (row) => handleUserInput(row));

      // init the form as usual
      vm.totalAvailableLicenseCount = getTotalAvailableLicenseCount();
      if (vm.totalAvailableLicenseCount === 1) {
        vm.rowsDetails.push(generateNewRow());
      } else {
        vm.rowsDetails.push(generateNewRow());
        vm.rowsDetails.push(generateNewRow());
      }

      updateProductToLicenseCountMap();
      vm.isSmallSmb = vm.totalAvailableLicenseCount <= SMALL_SMB_LICENSE_COUNT_CUTOFF;
    }

    function $onDestroy() {
      deregisterSelectionHandler();
    }

    function areProductAndUsersDataFulfilled() {
      const numberOfValidOrgUsers = countValidOrgUsers();
      return numberOfValidOrgUsers >= getTotalAvailableLicenseCount();
    }

    function canShowNewRow() {
      const numberOfInputRows = _.size(vm.rowsDetails);
      const numberOfTriggeredRows = _.filter(
        vm.rowsDetails,
        (rowDetails) =>
          rowDetails.productSelection.count > 0 || !_.isEmpty(rowDetails.orgUser.email)
      ).length;
      return (
        numberOfInputRows - numberOfTriggeredRows < 1 &&
        numberOfInputRows !== getTotalAvailableLicenseCount() &&
        numberOfInputRows < vm.MAX_USERS_ADDED
      );
    }

    function countValidOrgUsers() {
      const validOrgUsers = _.filter(vm.rowsDetails, 'isValidOrgUser');
      return validOrgUsers.length;
    }

    function isEmailAlreadyTakenForProduct(rowDetails, productId) {
      const email = _.get(rowDetails, 'orgUser.email');

      // Check every row (excluding the row that is being considered)
      // to see if the product being considered is selected.
      const rowsDetails = _.reject(vm.rowsDetails, rowDetails);
      // For the one product case, it can be assumed that the
      // product is selected for all rows (all rows have the same product).
      let hasProduct = vm.productsCase === vm.PRODUCTS_CASE.ONE;
      if (vm.productsCase === vm.PRODUCTS_CASE.MANY) {
        // If another row has the same product selected, set hasProduct
        // to true to factor in with other checks.
        _.forEach(rowsDetails, (row) => {
          const items = row.productSelection.items;
          hasProduct = _.some(items, ['id', productId]);
          return !hasProduct;
        });
      }

      // Email is already taken for product if there is another row
      // with a valid email that is the same as the email in the row
      // being considered, and hasProduct has been set to true above.
      return (
        _(vm.rowsDetails)
          .filter(['invalidErrorMessage', ''])
          .reject(rowDetails)
          .some(['orgUser.email', email]) && hasProduct
      );
    }

    function generateNewRow(userToPrefill) {
      const tempDeferred = $q.defer();
      tempDeferred.resolve();

      const selection = new Selection('id');
      let productsToPreassign;

      if (userToPrefill) {
        // restore cached state so that the preselected products remain when switching between csv form
        userToPrefill.restore();
        productsToPreassign = userToPrefill.products;

        _.forEach(productsToPreassign, (product) => {
          // need to toggle the product within vm.productDataList because it has necessary product fields
          const productData = _.find(vm.productDataList, {id: product.id});
          selection.toggleItemSelection(productData);
        });
      }

      return {
        forceDisabledProducts: productsToPreassign,
        forceShowEmailFieldError: false,
        isPreassigned: !_.isUndefined(userToPrefill),
        // True when OrgUser has a valid email
        isValidOrgUser: !_.isUndefined(userToPrefill),
        // true if a row is less than the minimum query length or that row has a valid org user.
        isValidRow: true,
        orgUser: userToPrefill || new OrganizationUser(),
        products: vm.productDataList,
        productSelection: selection,
        rowDebouncePromise: undefined,
        rowWaitDeferred: tempDeferred,
      };
    }

    function getMaxAddedUsersWarningString() {
      return $translate.instant(
        'widgets.users.quickAssignModal2.emailListForm.maxUsersAddedWarning',
        {
          maxUsersAdded: vm.MAX_USERS_ADDED,
        },
        'messageformat'
      );
    }

    function getRowNumber(rowDetails) {
      return _.indexOf(vm.rowsDetails, rowDetails) + 1;
    }

    function getUsersCount() {
      return $translate.instant(
        'widgets.users.quickAssignModal2.usersCount',
        {
          count: getTotalAvailableLicenseCount(),
          usersAdded: countValidOrgUsers(),
        },
        'messageformat'
      );
    }

    function getTotalAvailableLicenseCount() {
      return _.sumBy(vm.productDataList, 'availableLicenses');
    }

    function getErrorMessage(options) {
      const errorCode = options.errorCode;
      if (errorCode) {
        return errorCode === ORG_USER_ERROR_CODE.USER_ALREADY_EXISTS_IN_LICENSE_GROUP ||
          errorCode === ERROR_CODE_EMAIL_TAKEN
          ? $translate.instant(
              `widgets.users.quickAssignModal2.errorToast.${errorCode}`,
              {
                product: options.productName,
              },
              'messageformat'
            )
          : $translate.instant(`widgets.users.quickAssignModal2.errorToast.${errorCode}`);
      }
      $log.error('Quick Assign email list form failed to find error message in getErrorMessage.');
      return undefined;
    }

    // For both one and many product cases, gather product and license group information
    // for the products that the user wants to assign licenses to for a given row.
    // Once the organization user helper processes this product/license group information,
    // update row based on succcess/errors.
    function handleUserInput(rowDetails) {
      const selectedProducts = [];
      const licenseGroupIds = [];
      const promises = [];
      let products = rowDetails.products;
      const selectedItems = rowDetails.products;
      const disabledItems = rowDetails.forceDisabledProducts;

      // find the difference of the product sets based on product id to get actual selected products
      products = _.differenceBy(selectedItems, disabledItems, 'id');

      _.forEach(products, (productData) => {
        const productId = productData.id;
        const isSelected = _.some(rowDetails.productSelection.items, ['id', productId]);
        if (
          vm.productsCase === vm.PRODUCTS_CASE.ONE ||
          (vm.productsCase === vm.PRODUCTS_CASE.MANY && isSelected)
        ) {
          promises.push(
            productData.licenseGroupSummaryPromise.then((licenseGroupSummary) => {
              selectedProducts.push(productData);
              licenseGroupIds.push(_.get(licenseGroupSummary, 'id'));
            })
          );
        }
      });

      $q.all(promises)
        .then(() =>
          OrganizationUserHelper.populateValidatedNewUserOrExistingUser2(rowDetails.orgUser, {
            licenseGroupIds,
            selectedProducts,
          })
        )
        .then((responses) => {
          onHandleUserInputSuccess(responses, rowDetails);
        })
        .catch((error) => {
          onHandleUserInputError(error, rowDetails);
        })
        .finally(() => {
          rowDetails.isValidRow = rowDetails.isValidOrgUser;
          revalidateDuplicateEmailsForAllRows();
          rowDetails.rowWaitDeferred.resolve();
          vm.onFormStatusChange({isValid: isFormValid()});

          let rowsDetails = vm.rowsDetails;
          // for the case of a single product - we ignore the preassigned user
          if (vm.productsCase === vm.PRODUCTS_CASE.ONE) {
            rowsDetails = _.reject(rowsDetails, 'isPreassigned');
          }

          const orgUserList = QuickAssignHelper.getOrgUserListFromRowsDetails(rowsDetails);
          vm.onUsersChange({orgUserList});
          updateProductToLicenseCountMap();
        });
    }

    function isFormValid() {
      // Form is valid in one products case as long as no row has an invalid email
      if (vm.productsCase === vm.PRODUCTS_CASE.ONE) {
        const hasInvalidRows = _.some(vm.rowsDetails, (row) => !row.isValidRow);
        // To validate for preassigned users:
        //    Form is valid if the number of non empty valid rows are greater
        //    than the number of preassigned user rows
        if (vm.preassignedUsers) {
          const preassignedRowCount = _.filter(vm.rowsDetails, 'isPreassigned').length;
          const validRowCount = _.filter(
            vm.rowsDetails,
            (row) => !_.isEmpty(row.orgUser.email) && row.isValidRow
          ).length;

          return !hasInvalidRows && validRowCount > preassignedRowCount;
        }
        return !hasInvalidRows;
      }

      // Form is valid in many products case if:
      // 1. there are no rows with incomplete assignments (a product selected without an email,
      //    or an email filled without a product assigned)
      // 2. there are no invalid rows (rows with errors)
      const hasInvalidRows = _.some(
        vm.rowsDetails,
        (row) =>
          !row.isValidRow ||
          row.forceShowEmailFieldError ||
          (row.productSelection.count > 0 && _.isEmpty(row.orgUser.email)) ||
          (!_.isEmpty(row.orgUser.email) && row.productSelection.count === 0)
      );

      let hasPreassignedState = false;
      if (vm.preassignedUsers) {
        // Ror rows with preassigned products we need extra validation:
        // If every row is in its preassigned product state, then it is invalid
        hasPreassignedState = _.every(vm.rowsDetails, (row) => isRowInPreassignedState(row));
      }

      return !hasInvalidRows && !hasPreassignedState;

      // Returns true if the row's selected products are equal to its disabled products
      function isRowInPreassignedState(row) {
        const selectedIds = _(row.productSelection.items).map('id').sortBy('id').value();
        const disabledIds = _(row.forceDisabledProducts).map('id').sortBy('id').value();
        return _.isEqual(selectedIds, disabledIds);
      }
    }

    function onRowInputChange(searchQuery, rowDetails) {
      vm.onFormStatusChange({isValid: false});
      rowDetails.invalidErrorMessage = '';
      rowDetails.orgUser.email = searchQuery;
      if (canShowNewRow()) {
        vm.rowsDetails.push(generateNewRow());
      }
      rowDetails.isValidRow = false;
      rowDetails.rowWaitDeferred = $q.defer();
      rowDetails.forceShowEmailFieldError = false;

      if (rowDetails.rowDebouncePromise) {
        $timeout.cancel(rowDetails.rowDebouncePromise);
        rowDetails.rowDebouncePromise = undefined;
      }

      const emailLength = _.size(rowDetails.orgUser.email);
      if (emailLength >= SEARCH_QUERY_MIN_LENGTH) {
        rowDetails.rowDebouncePromise = $timeout(() => handleUserInput(rowDetails), DEBOUNCE_TIME);
      } else {
        OrganizationUserHelper.resetInputOrgUser(rowDetails.orgUser);
        rowDetails.isValidRow = true;
        rowDetails.isValidOrgUser = false;
        rowDetails.rowWaitDeferred.resolve();
        revalidateDuplicateEmailsForAllRows();
        updateProductToLicenseCountMap();
        // case where the input is cleared using the icon on the search list box
        if (_.isEmpty(searchQuery)) {
          rowDetails.forceShowEmailFieldError = undefined;

          let rowsDetails = vm.rowsDetails;
          // for the case of a single product - we ignore the preassigned user
          if (vm.productsCase === vm.PRODUCTS_CASE.ONE) {
            rowsDetails = _.reject(rowsDetails, 'isPreassigned');
          }

          const orgUserList = QuickAssignHelper.getOrgUserListFromRowsDetails(rowsDetails);
          vm.onUsersChange({orgUserList});
          vm.onFormStatusChange({isValid: isFormValid()});
        }
      }
      return rowDetails.rowWaitDeferred.promise;
    }

    function onHandleUserInputError(error, rowDetails) {
      const errorCode = _.get(error, 'errorCode');
      rowDetails.isValidOrgUser = false;
      rowDetails.forceShowEmailFieldError = true;
      rowDetails.invalidErrorMessage = getErrorMessage({
        errorCode,
        productName: _.get(error, 'products[0].longName'),
      });
      // If the error is not directly related to the accuracy of the input row/product selection,
      // display an error banner
      if (
        errorCode !== ORG_USER_ERROR_CODE.USER_ALREADY_EXISTS_IN_LICENSE_GROUP &&
        errorCode !== ORG_USER_ERROR_CODE.INVALID_USER_EMAIL
      ) {
        vm.showErrorBanner = true;
      }
    }

    function onHandleUserInputSuccess(responses, rowDetails) {
      vm.showErrorBanner = false;
      _.forEach(responses, (response) => {
        const products = response.products;
        _.forEach(products, (product) => {
          const isEmailTaken = isEmailAlreadyTakenForProduct(rowDetails, product.id);
          rowDetails.isValidOrgUser = !isEmailTaken;
          if (isEmailTaken) {
            rowDetails.invalidErrorMessage = getErrorMessage({
              errorCode: ERROR_CODE_EMAIL_TAKEN,
              productName: product.longName,
            });
            rowDetails.forceShowEmailFieldError = true;
            return false;
          }
          return true;
        });
      });
    }

    function onProductSelectionChange(event, updatedProductSelection) {
      if (vm.rowsDetails) {
        const updatedRowDetails = _.find(
          vm.rowsDetails,
          // eslint-disable-next-line lodash/matches-prop-shorthand
          (rowDetails) => rowDetails.productSelection === updatedProductSelection
        );

        if (canShowNewRow(updatedRowDetails)) {
          vm.rowsDetails.push(generateNewRow());
        }

        // eslint-disable-next-line promise/catch-or-return
        onRowInputChange(updatedRowDetails.orgUser.email, updatedRowDetails).then(() => {
          updateProductToLicenseCountMap();
        });
      }
    }

    // Calculate total number selected of each product to display
    // in status widget for many products case
    function updateProductToLicenseCountMap() {
      vm.productToLicenseCountMap = {};
      _(vm.rowsDetails)
        .filter((row) => _.size(row.orgUser.email) >= SEARCH_QUERY_MIN_LENGTH)
        .forEach((row) => {
          _.forEach(row.productSelection.items, (product) => {
            if (isValidLicense(row, product)) {
              vm.productToLicenseCountMap[product.id] =
                _.get(vm.productToLicenseCountMap, product.id, 0) + 1;
            }
          });
        });
    }

    function revalidateDuplicateEmailsForAllRows() {
      // remove any fixed duplicate email error tooltips
      _(vm.rowsDetails)
        .filter('orgUser.email')
        .forEach((row) => {
          const selectedProducts =
            vm.productsCase === vm.PRODUCTS_CASE.ONE ? row.products : row.productSelection.items;
          _.forEach(selectedProducts, (product) => {
            const isEmailTakenRecheck = isEmailAlreadyTakenForProduct(row, product.id);
            if (
              !isEmailTakenRecheck &&
              _.isEqual(
                row.invalidErrorMessage,
                getErrorMessage({errorCode: ERROR_CODE_EMAIL_TAKEN, productName: product.longName})
              )
            ) {
              row.isValidOrgUser = !isEmailTakenRecheck;
              row.invalidErrorMessage = '';
              row.forceShowEmailFieldError = false;
              onRowInputChange(row.orgUser.email, row); // reprocess this row and make sure you display any other errors it has
            }
            row.isValidRow = row.isValidOrgUser;
          });
        });
    }

    // Used to determine which products in row to count in license count table
    // Checks that either the row has no errors, or that the given product is not the one causing the error
    function isValidLicense(row, product) {
      return (
        !row.invalidErrorMessage ||
        (!_.isEqual(
          row.invalidErrorMessage,
          getErrorMessage({errorCode: ERROR_CODE_EMAIL_TAKEN, productName: product.longName})
        ) &&
          !_.isEqual(
            row.invalidErrorMessage,
            getErrorMessage({
              errorCode: ORG_USER_ERROR_CODE.USER_ALREADY_EXISTS_IN_LICENSE_GROUP,
              productName: product.longName,
            })
          ) &&
          !_.isEqual(
            row.invalidErrorMessage,
            getErrorMessage({errorCode: ORG_USER_ERROR_CODE.INVALID_USER_EMAIL})
          ) &&
          !_.isEqual(
            row.invalidErrorMessage,
            getErrorMessage({errorCode: ORG_USER_ERROR_CODE.SERVER_ERROR})
          ))
      );
    }

    function switchToCsv() {
      AnalyticsEvent.dispatch({
        componentMethod: 'quick-assign-email-list-switch-to-csv',
        componentMethodType: 'click',
        componentName,
      });
      const hasUnsavedInfo =
        _.some(vm.rowsDetails, (row) => isEmailUnsaved(row)) ||
        (vm.productsCase === vm.PRODUCTS_CASE.MANY &&
          _.some(vm.rowsDetails, (row) => isProductSelectionUnsaved(row)));

      vm.onSwitchToCsv({hasUnsavedInfo});

      function isEmailUnsaved(row) {
        return !row.isPreassigned && !_.isEmpty(row.orgUser.email);
      }

      // Determine if given row's product selection is unsaved, taking disabled products into account
      function isProductSelectionUnsaved(row) {
        const selectedItems = row.productSelection.items;
        const disabledItems = row.forceDisabledProducts;

        // find the difference of the product sets based on product id to get actual selected products
        const diff = _.differenceBy(selectedItems, disabledItems, 'id');
        return diff.length > 0;
      }
    }
  }
})();
/* eslint-enable max-lines */
