import {
  UserGroup,
  constructAsyncAddAssignUserAnalytics,
  dispatchUiEventAnalytics,
  feature,
  getProductListWithLicenseGroupSummariesIfSafe,
  getProductsAssignableToUsers,
  hasOnlyTeamProducts,
  saveMemberProductRoles,
  toPandoraProduct,
} from '@admin-tribe/binky';
import binkyUI, {getOrganizationUserErrorProps} from '@admin-tribe/binky-ui';
import {Text} from '@adobe/react-spectrum';
import {
  AssignmentAddMoreActionButtonContextProvider,
  AssignmentLicenseLimitProvider,
} from '@pandora/react-assignment-modal';
import {ProductRoleContext} from '@pandora/react-mini-cards';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {
  canAssignMember,
  canPurchaseLicenses,
  canUseAddProductMiniAppForAll,
} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {addTagsToProducts} from 'core/products/utils/productTagUtils';
import {getContractDisplayNames} from 'core/products/utils/productUtils';
import chatProvider from 'core/services/chat/chatProvider';
import {ADD_PRODUCT_WORKFLOWS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {goToUsers} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {dispatchUserModalSuccessAnalytics} from 'features/users/services/user-modals/userModalAnalyticsUtils';

/* eslint-disable import/no-cycle -- disabling import */
import {getOrgInfoWithSubscriptionStatusForAnalytics} from '../../utils/analyticsUtils';
/* eslint-enable import/no-cycle -- disabling import */

/* eslint-disable max-lines -- this file requires more lines */
const ButtonLink = binkyUI.common.components.ButtonLink;

const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const {INFO_STATUS} = binkyUI.common.components.addUser.MEMBER_AND_SELECTED_ITEMS_CONSTANTS;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading} =
  binkyUI.common.components.modal;
const UserPicker = binkyUI.common.components.UserPicker;

const AddUsersToOrgModal = observer(({isOpen, onClosed, onSuccess, orgId}) => {
  const [modalError, setModalError] = React.useState(null);
  const [modalErrorProps, setModalErrorProps] = React.useState();
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = React.useState(isOpen);
  const [productList, setProductList] = React.useState();
  const [pandoraProducts, setPandoraProducts] = React.useState();
  const [assignmentSectionTargets, setAssignmentSectionTargets] = React.useState([
    TARGET_TYPE.PRODUCTS,
  ]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [orgUserList, setOrgUserList] = React.useState(null);
  const memberAndSelectedItemsListRef = React.useRef();

  const showModalError = React.useCallback(() => {
    setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
    setModalErrorProps(undefined);
  }, [intl]);

  const onConfirm = async () => {
    let isSuccess = false;
    setIsLoading(true);
    try {
      // Need to deep clone this collection as it is mutated by the save()
      const committedItems = cloneDeep(orgUserList.addedItems);
      const addedItems = [...orgUserList.addedItems];
      await orgUserList.save();
      await saveMemberProductRoles(addedItems, {orgId});
      onSuccess?.();
      setModalOpen(false);
      isSuccess = true;
      await dispatchUserModalSuccessAnalytics({
        committedItems,
        eventName: 'AddUsersToOrgModal',
        orgId,
      });
    } catch (error) {
      const props = getOrganizationUserErrorProps(intl, error, {
        onTrialAlreadyConsumed: () => trialHelper.openBuyNowUrl(trialHelper.getTrialProduct()?.id),
      });
      setModalError(props.message);
      setModalErrorProps(props);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const onChange = (addUserFormTableData) => {
    setModalError(null);
    setModalErrorProps(undefined);
    const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
      addUserFormTableData,
      orgId,
      (memberAndSelectedItems) => {
        const member = memberAndSelectedItems.member;
        member.products = memberAndSelectedItems.getSelectedProductsAndLicenseGroups();
        member.userGroups = memberAndSelectedItems.getSelectedItems(UserGroup);
        member.productRoles = memberAndSelectedItems.productRoles;
        return member;
      }
    );
    setOrgUserList(newOrgUserList);
    memberAndSelectedItemsListRef.current = addUserFormTableData;
  };

  const ctaToastGenerator = () =>
    intl.formatMessage(
      {id: 'common.toast.modal.usersAdded'},
      {userCount: orgUserList.items.length}
    );

  const createPandoraAssignmentSectionContext = (selectedMember) => {
    // Certain members are not allowed to be assigned to normal assignable products. For example,
    // some products are disabled for TYPE1 users.
    const getDisabledProducts = () => {
      if (!selectedMember) {
        return [];
      }
      return productList?.items
        .filter((product) => !canAssignMember(product, selectedMember))
        .map((product) => product.id);
    };

    return {
      itemsToDisable: {
        products: getDisabledProducts(),
      },
      products: pandoraProducts,
    };
  };

  const validateSelectedMemberForItem = (memberAndSelectedItems) => {
    if (
      memberAndSelectedItems.member &&
      productList.items.some((product) => !canAssignMember(product, memberAndSelectedItems.member))
    ) {
      memberAndSelectedItems.addInfoStatus(INFO_STATUS.USER_PRODUCT_INFO);
    } else {
      memberAndSelectedItems.clearInfoStatus(INFO_STATUS.USER_PRODUCT_INFO);
    }
  };

  const transformToPandoraProduct = (product) => {
    if (feature.isEnabled('temp_add_contract_display_name')) {
      const contractDisplayNames = getContractDisplayNames(product.contractIds);
      const updatedProduct = {...product, contractDisplayNames};
      return toPandoraProduct(updatedProduct);
    }
    return toPandoraProduct(product);
  };

  // TnO Experiment for adding Buy Licences to Add Users workflow (ONESIE-40807)
  const tempProductSelectAddMore = feature.isEnabled('temp_product_select_add_more');
  const [showAddProductModal, setShowAddProductModal] = React.useState(false);
  const [addProductCartItems, setAddProductCartItems] = React.useState(null);

  const canPurchaseLicensesForPandoraProduct = (product) => {
    // this is a Pandora product, not a binky one we need for canPurchaseLicenses
    const binkyProduct = productList?.items.find((ogProduct) => ogProduct.id === product.id);
    if (!binkyProduct) {
      return false;
    }
    return canPurchaseLicenses(binkyProduct);
  };

  const onAddMoreProduct = (product) => {
    // make sure can use the mini app, and this is a Pandora product, not a binky one
    if (!(canUseAddProductMiniAppForAll() && canPurchaseLicensesForPandoraProduct(product))) {
      return;
    }

    // See AddLicensesButton.jsx for sending of same event
    const newOrg = getOrgInfoWithSubscriptionStatusForAnalytics(product.offerId, product.offerType);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'addLicenses',
      organizationInfo: newOrg,
    });

    setAddProductCartItems([{offerId: product.offerId, quantity: 1}]);
    setShowAddProductModal(true);
  };

  const onAddProductModalOnClose = () => {
    setAddProductCartItems(null);
    setShowAddProductModal(false);
  };

  const assignmentAddMoreActionButtonContext = tempProductSelectAddMore &&
    canUseAddProductMiniAppForAll() && {onAddMoreProduct};

  // Update the pandora-products only when the binky products change.
  React.useEffect(() => {
    if (productList) {
      setPandoraProducts(productList.items.map((product) => transformToPandoraProduct(product)));
    } else {
      setPandoraProducts([]);
    }
  }, [productList]);

  React.useEffect(() => {
    let isCurrent = true;
    setIsLoading(true);

    async function fetchProducts() {
      let tempProductList;
      try {
        tempProductList = await getProductListWithLicenseGroupSummariesIfSafe(orgId);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
        // istanbul ignore else
        if (isCurrent) {
          tempProductList.items = getProductsAssignableToUsers(tempProductList);
        }
      } catch {
        if (isCurrent) {
          showModalError();
        }
      }

      if (isCurrent) {
        addTagsToProducts(intl, tempProductList);
        setProductList(tempProductList);
        setIsLoading(false);
      }
    }

    fetchProducts();

    return () => {
      isCurrent = false;
    };
  }, [intl, orgId, showModalError]);

  React.useEffect(() => {
    if (productList) {
      const isTeam = hasOnlyTeamProducts(productList);

      if (!isTeam) {
        setAssignmentSectionTargets([
          TARGET_TYPE.USER_GROUPS,
          TARGET_TYPE.PRODUCT_PROFILES,
          TARGET_TYPE.PRODUCTS,
        ]);
      }
    }
  }, [productList]);

  const productRoleValue = useMemo(() => ({onError: showModalError}), [showModalError]);

  return (
    <>
      <ModalContainer>
        {isModalOpen && (
          <ModalDialog
            analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
            cancelLabel={intl.formatMessage({
              id: 'common.addUsersToOrgModal.cancelButton',
            })}
            ctaLabel={intl.formatMessage({
              id: 'common.addUsersToOrgModal.confirmButton',
            })}
            ctaToastGenerator={ctaToastGenerator}
            errorMessage={modalError}
            errorToastProps={modalErrorProps}
            heightVariant="static"
            id="add-users-to-org-modal"
            isCtaDisabled={
              !orgUserList?.hasUnsavedChanges() || memberAndSelectedItemsListRef.current.isInvalid()
            }
            isLoading={isLoading}
            onCancel={onClosed}
            onCta={onConfirm}
          >
            <ModalHeading>
              {intl.formatMessage({id: 'common.addUsersToOrgModal.header'})}
            </ModalHeading>
            <ModalDescription>
              <Text>
                {intl.formatMessage({id: 'common.addUsersToOrgModal.description1'})}
                <br />
                {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- zakn@ to update */}
                {intl.formatMessage(
                  {id: 'common.addUsersToOrgModal.description2'},
                  {
                    // eslint-disable-next-line react/forbid-elements -- zakn to update
                    b: (chunks) => <b>{chunks}</b>,
                    link: ([content]) => (
                      <ButtonLink
                        onPress={async () => {
                          setIsLoading(true);
                          try {
                            await goToUsers();
                          } finally {
                            setModalOpen(false);
                          }
                        }}
                      >
                        {content}
                      </ButtonLink>
                    ),
                  }
                )}
              </Text>
            </ModalDescription>

            {!tempProductSelectAddMore && (
              <ModalContent>
                <AssignmentLicenseLimitProvider value={{products: productList?.items}}>
                  <ProductRoleContext.Provider value={productRoleValue}>
                    <AddUserFormTableWrapper orgId={orgId}>
                      <AddUserFormTable
                        createPandoraAssignmentSectionContext={
                          createPandoraAssignmentSectionContext
                        }
                        data-testid="add-user-form-table-testid"
                        isSystemAdmin={isOrgAdmin()}
                        onChange={onChange}
                        orgId={orgId}
                        pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                        searchType={UserPicker.SEARCH_TYPE.NEW_USER}
                        targets={assignmentSectionTargets}
                        validateSelectedMemberForItem={validateSelectedMemberForItem}
                      />
                    </AddUserFormTableWrapper>
                  </ProductRoleContext.Provider>
                </AssignmentLicenseLimitProvider>
              </ModalContent>
            )}

            {tempProductSelectAddMore && (
              <ModalContent>
                <AssignmentAddMoreActionButtonContextProvider
                  value={assignmentAddMoreActionButtonContext}
                >
                  <AssignmentLicenseLimitProvider value={{products: productList?.items}}>
                    <ProductRoleContext.Provider value={productRoleValue}>
                      <AddUserFormTableWrapper orgId={orgId}>
                        <AddUserFormTable
                          createPandoraAssignmentSectionContext={
                            createPandoraAssignmentSectionContext
                          }
                          data-testid="add-user-form-table-testid"
                          isSystemAdmin={isOrgAdmin()}
                          onChange={onChange}
                          orgId={orgId}
                          pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                          searchType={UserPicker.SEARCH_TYPE.NEW_USER}
                          targets={assignmentSectionTargets}
                          validateSelectedMemberForItem={validateSelectedMemberForItem}
                        />
                      </AddUserFormTableWrapper>
                    </ProductRoleContext.Provider>
                  </AssignmentLicenseLimitProvider>
                </AssignmentAddMoreActionButtonContextProvider>
              </ModalContent>
            )}
          </ModalDialog>
        )}
      </ModalContainer>

      {tempProductSelectAddMore && showAddProductModal && (
        <AddProductModalWrapper
          chatProvider={chatProvider}
          items={addProductCartItems}
          onClose={onAddProductModalOnClose}
          workflow={ADD_PRODUCT_WORKFLOWS.ADD_LICENSE}
        />
      )}
    </>
  );
});

AddUsersToOrgModal.propTypes = {
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  /**
   * Optional callback when users have been added to the org. There is no parameter.
   */
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
};

export default AddUsersToOrgModal;
/* eslint-enable max-lines -- this file requires more lines */
