const TEMPLATE_TYPES = {
  CC_CUSTOMIZED: 0,
  CC_NON_CUSTOMIZED: 1,
  DC_CUSTOMIZED: 2,
  DC_NON_CUSTOMIZED: 3,
  NON_CC_CUSTOMIZED: 4,
  NON_CC_NON_CUSTOMIZED: 5,
};

const ACROBAT_CLASSIC_24_FICODE = 'acrobat_classic_2024_pro';
const ACROBAT_CLASSIC_24_VERSION = '24.0';

export {TEMPLATE_TYPES, ACROBAT_CLASSIC_24_FICODE, ACROBAT_CLASSIC_24_VERSION};
