import {AnalyticsEvent} from '@admin-tribe/binky';
import {Divider, Flex, Text} from '@adobe/react-spectrum';
import {
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
/**
 * The modal used to show ticket/case status and survey form option when
 * ticket is closed
 */
const SupportCaseClosedSurveyModal = ({caseId, isOpen, onCancel, surveyUrl}) => {
  const intl = useIntl();
  const onTakeSurvey = () => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
    window.open(surveyUrl, '_blank');
    AnalyticsEvent.dispatch({
      componentMethod: 'takeSurveyButton',
      componentMethodType: 'click',
      componentName: 'appSupportTicketActionsSection',
    });
    onCancel();
  };

  const content = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'support.supportTicketActionsSection.closedSurveyModal.RemindLater',
    }),
    ctaLabel: intl.formatMessage({id: 'notifications.card.button.takeSurvey.label'}),
  });

  return (
    <ModalContainer data-testid="support-ticket-modal-survey">
      {isOpen ? (
        <ModalDialog
          closeModal={onCancel}
          content={content}
          data-testid="support-ticket-survey-modal"
          onCancel={onCancel}
          onCta={onTakeSurvey}
        >
          <ModalHeading level={2}>
            {intl.formatMessage(
              {id: 'support.supportTicketActionsSection.closeCase.toastSuccess'},
              {
                supportCaseId: caseId,
              }
            )}
          </ModalHeading>
          <Divider />
          <ModalContent data-testid="modal-content-survey">
            <Flex direction="column">
              <Text UNSAFE_style={{fontSize: '16px'}}>
                {intl.formatMessage({
                  id: 'support.supportTicketActionsSection.closedSurveyModal.description1',
                })}
              </Text>
              <Text UNSAFE_style={{fontSize: '16px'}}>
                {intl.formatMessage({
                  id: 'support.supportTicketActionsSection.closedSurveyModal.description2',
                })}
              </Text>
            </Flex>
          </ModalContent>
        </ModalDialog>
      ) : null}
    </ModalContainer>
  );
};

SupportCaseClosedSurveyModal.propTypes = {
  /**
   * CaseID of SupportCase
   */
  caseId: PropTypes.string.isRequired,
  /**
   * Used to check whether the modal is open.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Invokes when the modal remind me later button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Used to get surveyUrl for survey form.
   */
  surveyUrl: PropTypes.string.isRequired,
};

export default SupportCaseClosedSurveyModal;
