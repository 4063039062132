/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.directories:appTrustedDirectoryDetailsDrawer
 *
 * @description The details drawer for trusted directories.
 *
 * @binding {Object} directory.
 */
(function () {
  const componentName = 'appTrustedDirectoryDetailsDrawer';
  angular.module('app.widgets.directories').component(componentName, {
    bindings: {
      directory: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/trusted-directory-details-drawer/trusted-directory-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    AnalyticsEvent,
    _,
    apiUtils,
    feature,
    panelManager,
    REVOKED_DIRECTORY_REMOVE_MODAL_ID,
    t2eMigrationManager,
    toastManager,
    TRUSTED_DIRECTORY_DRAWER_ID,
    TRUSTED_DIRECTORY_DELETE_MODAL_ID,
    Trust
  ) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      cancelRequest,
      directoryItems: [],
      drawerId: TRUSTED_DIRECTORY_DRAWER_ID,
      feature,
      isT2eOrg: t2eMigrationManager.isT2EClean(),
      onConfirmRemoveRevokedTrust,
      openRemoveConfirmation,
      remind,
      remove,
      removeRevokedTrust,
      REVOKED_DIRECTORY_REMOVE_MODAL_ID,
      TRUSTED_DIRECTORY_DELETE_MODAL_ID,
    });

    function $onChanges(changesObj) {
      vm.directory = _.get(changesObj, 'directory.currentValue');
      if (vm.directory) {
        vm.trust = Trust.get({
          directoryId: vm.directory.id,
          trusteeOrgId: apiUtils.getActiveOrg(),
        });
        vm.directoryItems = [vm.directory];
      }
    }

    function cancelRequest() {
      remove({analyticsMethod: 'cancelRequest'});
    }

    function remind() {
      vm.isBusy = true;
      vm.trust.remind().then(onSuccess).catch(onError).finally(onComplete);

      ////////

      function onSuccess() {
        AnalyticsEvent.dispatch({
          componentMethod: 'resendRequest',
          componentMethodType: 'submit',
          componentName,
        });
        toastManager.showSuccessToast();
      }

      function onError() {
        toastManager.showErrorToast();
      }

      function onComplete() {
        vm.isBusy = false;
      }
    }

    function openRemoveConfirmation() {
      panelManager.open(TRUSTED_DIRECTORY_DELETE_MODAL_ID);
    }

    /**
     * @description removes the trust asynchronously and triggers UI feedback
     * @param {Object} options options for the removal
     * @param {String} options.successMessage optional custom success message to show
     */
    function remove(options = {}) {
      const {successMessage, analyticsMethod} = options;

      if (!vm.trust) {
        return;
      }

      vm.isBusy = true;
      vm.trust.remove().then(onSuccess).catch(onError).finally(onComplete);

      ////////

      function onSuccess() {
        AnalyticsEvent.dispatch({
          componentMethod: analyticsMethod || 'remove',
          componentMethodType: 'submit',
          componentName,
        });
        panelManager.close(TRUSTED_DIRECTORY_DRAWER_ID);
        toastManager.showSuccessToast(successMessage);
      }

      function onError() {
        toastManager.showErrorToast();
      }

      function onComplete() {
        vm.isBusy = false;
      }
    }

    function removeRevokedTrust() {
      panelManager.open(REVOKED_DIRECTORY_REMOVE_MODAL_ID);
    }

    function onConfirmRemoveRevokedTrust() {
      panelManager.close(REVOKED_DIRECTORY_REMOVE_MODAL_ID);
      remove({
        analyticsMethod: 'revoke',
        successMessage: $translate.instant(
          'widgets.directories.revokedDirectoryRemoveModal.successMessage',
          {
            directoryName: vm.directory.name,
          }
        ),
      });
    }
  }
})();
