import {ActionMenu, Item} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import ProductsDetailSection from 'common/components/products-detail-section/ProductsDetailSection';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import useEditDetailsModal from 'common/hooks/use-edit-details-modal/useEditDetailsModal';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';
import {canEditProducts} from 'core/products/access/productAccess';
import ApiCredentialsDetailsSection from 'features/users/components/api-credentials-details-section/ApiCredentialsDetailsSection';
import EditApiCredentialsModal from 'features/users/components/edit-api-credentials-modal/EditApiCredentialsModal';
import {transformMember} from 'features/users/services/member-transformer/memberTransformerUtils';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

/**
 * The 'API credentials' details drawer content
 */
const ApiCredentialsDetailsDrawerContent = ({member}) => {
  const {orgId} = useParams();
  const {store: userStore} = useMemberListContext();
  const intl = useIntl();

  // Note: this model does not have the integration data added to
  // it so it should not be used for the ApiCredentialsDetailsSection.
  const fetchApiIntegrationUser = useCallback(async () => {
    const response = await ApiIntegrationUser.get({
      orgId,
      userId: member.id,
    });

    // transformMember creates full Product objects needed for ProductsDetailSection.
    return transformMember({intl, member: response});
  }, [intl, member.id, orgId]);

  const {
    model: resolvedMember,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({loadFn: fetchApiIntegrationUser});
  const showError = !!error;

  const updateModelFn = () => {
    // Update the model for the 'Products' detail section in the open drawer.
    updateModel(fetchApiIntegrationUser);
    // Update the list shown in the table under the drawer.
    userStore.load();
  };

  const {onCloseModal, onOpenModal, onSuccess, openedModal} = useEditDetailsModal({
    updateModelFn,
  });

  // Always show menu once member has loaded so focus can be recovered
  // after admin closes edit modal.
  const key = USER_DETAILS_MODAL_NAMES.EDIT_API_CREDENTIALS;
  const disabledKeys = !isLoading && !showError ? [] : [key];

  return (
    <>
      <ApiCredentialsDetailsSection member={member} />
      {openedModal === USER_DETAILS_MODAL_NAMES.EDIT_API_CREDENTIALS && (
        <EditApiCredentialsModal
          isOpen
          onCancel={onCloseModal}
          onSuccess={onSuccess}
          // Since this user may refreshed by the modal and then not have all props required by
          // ProductsDetailSection (for example the product icon), clone it so the modal uses its own shallow copy.
          user={new ApiIntegrationUser({...resolvedMember})}
        />
      )}
      <ProductsDetailSection
        data-testid="products-detail-section"
        isLoading={isLoading}
        products={resolvedMember?.products ?? []}
        rightHeaderContent={
          canEditProducts() && (
            <ActionMenu disabledKeys={disabledKeys} isQuiet onAction={onOpenModal}>
              <Item key={key}>
                {intl.formatMessage({
                  id: 'users.APICredentialsDetailsDrawerContent.products.moreOptions.edit',
                })}
              </Item>
            </ActionMenu>
          )
        }
        showError={showError}
      />
    </>
  );
};

ApiCredentialsDetailsDrawerContent.propTypes = {
  /**
   * The API credential user from the OrganizationIntegrationList.
   * This model does not have the orgId so can not be refreshed by
   * the modal.
   */
  member: PropTypes.instanceOf(ApiIntegrationUser).isRequired,
};

export default ApiCredentialsDetailsDrawerContent;
