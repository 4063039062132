import {SUPPORT_CASE_CATEGORY} from '@pandora/react-support-ticket';

const SUPPORT_CONSTANTS = {
  CASE_ACTIONS: {
    CLOSE: 'close',
    ESCALATE: 'escalate',
    REOPEN: 'reopen',
  },

  CLOSE_CASE: {
    ACTION_CODE: 'CLOSE',
    ON_SAVE_SUCCESS: 'support.supportTicketActionsSection.closeCase.toastSuccess',
    OPTION_LABEL: 'support.supportTicketActionsSection.closeCase.optionLabel',
    RESOLUTION_LABEL: 'support.supportTicketActionsSection.closeCase.resolutionLabel',
    RESOLUTION_PLACEHOLDER: 'support.supportTicketActionsSection.closeCase.resolutionPlaceholder',
    TITLE: 'support.supportTicketActionsSection.closeCase.title',
  },

  ENTITY: {
    EMAIL: 'email',
    INCIDENT: 'incident',
  },

  ESCALATE_CASE: {
    ACTION_CODE: 'ESCALATE',
    ON_SAVE_SUCCESS: 'support.supportTicketActionsSection.escalateCase.toastSuccess',
    OPTION_LABEL: 'support.supportTicketActionsSection.escalateCase.optionLabel',
    RESOLUTION_LABEL: 'support.supportTicketActionsSection.escalateCase.resolutionLabel',
    RESOLUTION_PLACEHOLDER:
      'support.supportTicketActionsSection.escalateCase.resolutionPlaceholder',
    TITLE: 'support.supportTicketActionsSection.escalateCase.title',
  },

  INFO_CARD_TYPE: {
    COMPONENT: 'component',
    DATETIME: 'dateTime',
    NUMBER: 'number',
    STRING: 'string',
  },

  LIST_ENTITY_NAME: {
    CASES: 'cases',
    EXPERTS: 'experts',
  },

  NAV_MANAGER_CLOUD_KEY: {
    GENERAL: 'GENERAL',
    TEAM: 'TEAM',
  },

  REOPEN: {
    ACTION_CODE: 'REOPEN',
    ON_SAVE_SUCCESS: 'support.supportTicketActionsSection.reopenCase.toastSuccess',
    OPTION_LABEL: 'support.supportTicketActionsSection.reopenCase.optionLabel',
    RESOLUTION_LABEL: 'support.supportTicketActionsSection.reopenCase.resolutionLabel',
    RESOLUTION_PLACEHOLDER: 'support.supportTicketActionsSection.reopenCase.resolutionPlaceholder',
    TITLE: 'support.supportTicketActionsSection.reopenCase.title',
  },

  SUPPORT_CASE_CATEGORY_MAP: {
    CREATIVE: SUPPORT_CASE_CATEGORY.CREATIVE_CLOUD,
    DOCUMENT: SUPPORT_CASE_CATEGORY.CREATIVE_CLOUD,
    EXPERIENCE: SUPPORT_CASE_CATEGORY.EXPERIENCE_CLOUD,
    GENERAL: SUPPORT_CASE_CATEGORY.GENERAL_ISSUES,
    OTHERS: SUPPORT_CASE_CATEGORY.CREATIVE_CLOUD,
  },

  SUPPORT_CASE_ORIGIN: {
    WEB: 3,
  },

  SUPPORT_CASE_TYPE: {
    ADMIN: 1,
    PRODUCT: 2,
  },

  SUPPORT_TICKET_DISCUSSION_DIRECTION: {
    INBOUND: 'Inbound', // comment or attachment created by customer
    OUTBOUND: 'Outbound', // comment or attachment created by Adobe agent
    UNKNOWN: 'Unknown', // attachment creator unknown. Not expected for comments.
  },

  SUPPORT_TICKET_IMPACT: {
    LARGE: 1,
    MEDIUM: 2,
    SMALL: 3,
  },

  SUPPORT_TICKET_PRIORITY: {
    P1: 1,
    P2: 2,
    P3: 3,
    P4: 4,
  },

  SUPPORT_TICKET_RECORD_TYPE: {
    CASE: 'CASE',
    EXPERT: 'EXPERT',
  },

  SUPPORT_TICKET_STATUS_REASON: {
    AWAITING_ASSIGNMENT: '117710001',
  },

  VARIANT_TO_COLOR_MAP: {
    DEFAULT: 'grey',
    INFO: 'blue',
    SUCCESS: 'green',
    WARNING: 'orange',
  },
};

const SUPPORT_TICKET_ACTIONS_REASONS = {
  CLOSE: [
    {
      id: 2,
      name: 'support.supportTicketActionsSection.issueObsoleteOrNoLongerOccurs',
    },
    {
      id: 1,
      name: 'support.supportTicketActionsSection.resolutionConfirmed',
    },
    {
      id: 3,
      name: 'support.supportTicketActionsSection.resolutionIrrelevantOrNoLongerRequired',
    },
  ],
  ESCALATE: [
    {
      id: 5,
      name: 'support.supportTicketActionsSection.agentCommunicationSkills',
    },
    {
      id: 6,
      name: 'support.supportTicketActionsSection.agentTechnicalKnowledge',
    },
    {
      id: 3,
      name: 'support.supportTicketActionsSection.awaitingCallback',
    },
    {
      id: 1,
      name: 'support.supportTicketActionsSection.changeInUrgency',
    },
    {
      id: 4,
      name: 'support.supportTicketActionsSection.resolutionDidNotMeetExpectations',
    },
    {
      id: 2,
      name: 'support.supportTicketActionsSection.timeToResolution',
    },
  ],
  REOPEN: [
    {
      id: 2,
      name: 'support.supportTicketActionsSection.issueHasReoccurred',
    },
    {
      id: 3,
      name: 'support.supportTicketActionsSection.resolutionDidNotSolveIssue',
    },
    {
      id: 1,
      name: 'support.supportTicketActionsSection.resolutionPartiallySolvedIssue',
    },
  ],
};

const SUPPORT_TICKET_ACTIONS = {
  CLOSE: 'close',
  ESCALATE: 'escalate',
  REOPEN: 'reopen',
};

const EXPERT_SESSION_TEAM_TOPICS = [
  {
    name: 'support.requestExpertSessionTeamModal.topics.names.general',
    url: 'ac_team_admin_expert_gen',
  },
  {
    name: 'support.requestExpertSessionTeamModal.topics.names.webApp',
    url: 'ac_team_admin_expert_web',
  },
  {
    name: 'support.requestExpertSessionTeamModal.topics.names.video',
    url: 'ac_team_admin_expert_video',
  },
  {
    name: 'support.requestExpertSessionTeamModal.topics.names.print',
    url: 'ac_team_admin_expert_print',
  },
  {
    name: 'support.requestExpertSessionTeamModal.topics.names.photography',
    url: 'ac_team_admin_expert_photo',
  },
];

const EXPERT_SESSION_TEAM_TIMEFRAME = [
  {
    name: 'support.requestExpertSessionTeamModal.timeframe.morning',
    value: '1',
  },
  {
    name: 'support.requestExpertSessionTeamModal.timeframe.afternoon',
    value: '2',
  },
];

const MESSAGE = {
  OPEN: {
    TEAM_EXPERT_SESSION_BANNER: 'OpenTeamExpertSessionBanner',
  },
};

const MAX_CHARACTER_COUNT = {
  ACTION_MODAL_SUMMARY: 250,
};

export {
  MESSAGE,
  SUPPORT_CONSTANTS,
  SUPPORT_TICKET_ACTIONS_REASONS,
  SUPPORT_TICKET_ACTIONS,
  EXPERT_SESSION_TEAM_TOPICS,
  EXPERT_SESSION_TEAM_TIMEFRAME,
  MAX_CHARACTER_COUNT,
};
