import {Locale} from '@admin-tribe/binky';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {ContractMiniAppContentModel} from '@pandora/react-mini-app-contract';
import {StoragePlansInfo} from '@pandora/react-storage-plans-info';
import PropTypes from 'prop-types';
import React from 'react';

import rootStore from 'core/RootStore';

import styles from './StoragePlans.pcss';

/**
 * @description Represents a wrapper for Pandora Storage Plans Info
 *
 */
const StoragePlans = ({contractId}) => {
  const StoragePlansInfoWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(StoragePlansInfo);

  return (
    <div className={styles['storage-plans']} data-testid="storage-plans-container">
      <StoragePlansInfoWithContent
        aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
        contractId={contractId}
        locale={Locale.get().activeLocaleCode}
        orgId={rootStore.organizationStore.activeOrgId}
      />
    </div>
  );
};

StoragePlans.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
};

export default StoragePlans;
