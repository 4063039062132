import {PageBanner} from '@admin-tribe/binky-ui';
import {Flex, Heading, Link, Text, View} from '@adobe/react-spectrum';
import {TitledSection} from '@pandora/react-titled-section';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AppIntegrationsPlatformIcon from '../AppIntegrationsPlatformIcon';

import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

// The 'Integration details' step in the AddAppIntegrationModal workflow
const AddAppIntegrationModalAppDetails = ({setIsIntegrationDetailsCtaDisabled}) => {
  const {appIntegration, error} = useAddAppIntegrationModalContext();
  const isAlreadyAuthorized = appIntegration?.policy;

  // handle next button state after making an api call
  useEffect(() => {
    if (isAlreadyAuthorized || error) {
      setIsIntegrationDetailsCtaDisabled(true);
    } else {
      setIsIntegrationDetailsCtaDisabled(false);
    }
  }, [isAlreadyAuthorized, error, setIsIntegrationDetailsCtaDisabled]);

  return (
    <View>
      {isAlreadyAuthorized && !error && <PageBannerSection bannerType="warning" />}
      {error && <PageBannerSection bannerType="error" />}

      {!error && (
        <TitledSection headingLevel={3} title={appIntegration?.name}>
          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.publisher" />
            </Heading>
            {appIntegration?.publisher?.website ? (
              <Link>
                <a
                  href={appIntegration.publisher.website}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {appIntegration.publisher.name}
                </a>
              </Link>
            ) : (
              <Text>{appIntegration?.publisher?.name}</Text>
            )}
          </View>
          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.permissions" />
            </Heading>
            <ul>
              {appIntegration?.permissions?.map((permission) => (
                <li key={permission.displayName}> {permission.displayName} </li>
              ))}
            </ul>
          </View>
          <View>
            <Heading level={4}>
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.heading.platforms" />
            </Heading>
            <View elementType="p">
              <FormattedMessage id="products.appIntegrations.addAppIntegrationModalAppDetails.description.platforms" />
            </View>
            <View>
              {appIntegration?.platforms?.map((platform, index) => (
                <Flex key={platform} alignItems="center">
                  <AppIntegrationsPlatformIcon platform={platform} />
                  <FormattedMessage
                    id="products.appIntegrations.addAppIntegrationModalAppDetails.description.platforms.clientId"
                    values={{clientId: appIntegration?.clientIds?.[index]}}
                  />
                </Flex>
              ))}
            </View>
          </View>
        </TitledSection>
      )}
    </View>
  );
};

AddAppIntegrationModalAppDetails.propTypes = {
  /** The setIsIntegrationDetailsCtaDisabled function required for to manage the state of next button */
  setIsIntegrationDetailsCtaDisabled: PropTypes.func.isRequired,
};

const PageBannerSection = ({bannerType}) => {
  const intl = useIntl();
  return (
    // V2 Provider needed for PageBanner
    <SpectrumV2Provider>
      <View marginBottom="size-100">
        <PageBanner
          header={intl.formatMessage({
            id: `products.appIntegrations.addAppIntegrationModalAppDetails.pageBanner.${bannerType}.header`,
          })}
          showDefaultButton={false}
          variant={bannerType}
        >
          <FormattedMessage
            id={`products.appIntegrations.addAppIntegrationModalAppDetails.pageBanner.${bannerType}.children`}
          />
        </PageBanner>
      </View>
    </SpectrumV2Provider>
  );
};

PageBannerSection.displayName = 'PageBannerSection';

PageBannerSection.propTypes = {
  bannerType: PropTypes.oneOf(['warning', 'error']).isRequired,
};

export default AddAppIntegrationModalAppDetails;
