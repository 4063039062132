import {
  PATH_OVERVIEW,
  PATH_OVERVIEW_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS,
} from 'features/overview/routing/overviewPaths';

const overviewRouteMigration = [
  {
    stateName: 'overview.overview2',
    url: PATH_OVERVIEW,
    useReactRoute: 'temp_react_port_overview',
  },
  {
    stateName: 'overview.overview2.add-products',
    url: `${PATH_OVERVIEW}/add-products`,
    useReactRoute: 'temp_react_port_overview',
  },
  {
    stateName: 'overview.overview2.quick-assign-products',
    url: PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
    useReactRoute: 'temp_standalone_quick_assign',
  },
  {
    stateName: 'overview.overview2.assign-products',
    url: PATH_OVERVIEW_ASSIGN_PRODUCTS,
    useReactRoute: true, // 'temp_assign_products_deeplink',
  },
  {
    stateName: 'overview.overview2.review-user-introductions',
    url: PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS,
    useReactRoute: 'temp_introductions_modal_deeplink',
  },
  {
    stateName: 'overview.overview2.review-saved-user-introductions',
    url: PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS,
    useReactRoute: 'temp_saved_introductions_modal_deeplink',
  },
];

export default overviewRouteMigration;
