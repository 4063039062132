import {
  PROFILE_PERMISSIONS_SUB_CONTENT_TYPE,
  isLicenseGroupConfigurationSubContentInvalid,
} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import {useEditProductProfilePermissionsContext} from '../context/EditProductProfilePermissionsContext';
import {subContentPropType} from '../prop-types/configurationGroupsPropTypes';

import InvalidSubContentBanner from './InvalidSubContentBanner';
import PermissionsIncludeListForm from './sub-content-type/include/PermissionsIncludeListForm';
import PermissionsSelectForm from './sub-content-type/select/PermissionsSelectForm';
import PermissionsTextForm from './sub-content-type/text/PermissionsTextForm';

const EditSubContent = ({
  allowAutoInclude,
  autoInclude,
  groupHelpLink,
  groupReadOnly,
  onChange,
  subContent,
}) => {
  const {productName} = useEditProductProfilePermissionsContext();

  const permissionsChange = (newPermissions, newAutoInclude) => {
    onChange(
      {
        ...subContent,
        values: newPermissions,
      },
      newAutoInclude
    );
  };

  return (
    <>
      {subContent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.INCLUDE && (
        <PermissionsIncludeListForm
          allowAutoInclude={allowAutoInclude}
          autoInclude={allowAutoInclude && autoInclude}
          groupHelpLink={groupHelpLink}
          onChange={permissionsChange}
          permissions={subContent.values}
          productName={productName}
          readOnly={groupReadOnly}
        />
      )}

      {subContent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.SELECT && (
        <PermissionsSelectForm onChange={permissionsChange} permissions={subContent.values} />
      )}

      {subContent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.TEXT_FORM && (
        <PermissionsTextForm onChange={permissionsChange} permissions={subContent.values} />
      )}

      {isLicenseGroupConfigurationSubContentInvalid(subContent) && (
        <InvalidSubContentBanner subContent={subContent} />
      )}
    </>
  );
};

EditSubContent.propTypes = {
  /**
   * Whether auto-include is allowed on this subContent. This is only used for 'include' subContent.
   */
  allowAutoInclude: PropTypes.bool,
  /**
   * Whether auto-include is enabled on this subContent. This is only used for 'include' subContent.
   */
  autoInclude: PropTypes.bool,
  /**
   * The optional help link URL for the group. This is only used for 'include' subContent.
   */
  groupHelpLink: PropTypes.string,
  /**
   * Whether this subContent is read only. This is only used for 'include' subContent.
   */
  groupReadOnly: PropTypes.bool,
  /**
   * Callback when the subContent is changed. This takes an argument of the new subContent.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The subContent to edit.
   */
  subContent: subContentPropType.isRequired,
};

export default EditSubContent;
