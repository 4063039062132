/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketContactsModal
 *
 * @description The modal to update contacts for either a support case or an expert session.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketContactsModal', {
    bindings: {
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-contacts-modal/support-ticket-contacts-modal.component.html',
  });

  function controller(
    $q,
    $translate,
    _,
    DisplayMessage,
    feature,
    OrganizationManager,
    SUPPORT_TICKET_CONTACTS_MODAL_ID,
    SUPPORT_TICKET_RECORD_TYPE,
    panelManager,
    toastManager
  ) {
    const vm = this;
    let contacts, formIsInvalid;

    _.assign(vm, {
      cancelHandler,
      ctaHandler,
      isCtaDisabled,
      onFormChange,
      onOpen,
      SUPPORT_TICKET_CONTACTS_MODAL_ID,
    });

    function cancelHandler() {
      panelManager.close(SUPPORT_TICKET_CONTACTS_MODAL_ID);
    }

    function ctaHandler() {
      updateContacts();
    }

    function isCtaDisabled() {
      return formIsInvalid;
    }

    // Callback from form when validity changes or when form is valid when one of the form values changes.
    function onFormChange(event) {
      formIsInvalid = event.isInvalid;
      contacts = _.assign({}, formIsInvalid ? {} : event.contacts);
    }

    function onOpen() {
      resetModal();
    }

    /////////////////

    function updateContacts() {
      const options = _.assign(
        {
          caseId: vm.supportTicket.id,
          orgId: OrganizationManager.getActiveOrgId(),
        },
        contacts
      );

      hideErrorBanner();

      vm.isBusy = true;
      vm.waitPromise = vm.supportTicket
        .update(options)
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess() {
        panelManager.close(SUPPORT_TICKET_CONTACTS_MODAL_ID);

        const toastMsg = $translate.instant(
          'widgets.support.supportTicketContactsModal.toast.contactsUpdated'
        );
        toastManager.showSuccessToast(toastMsg);
      }

      function onError() {
        showErrorBanner();
      }
    }

    function hideErrorBanner() {
      vm.displayMessage = null;
    }

    function resetModal() {
      formIsInvalid = true;

      _.assign(vm, {
        isBusy: false,
        waitPromise: $q.resolve(),
      });

      hideErrorBanner();
    }

    function showErrorBanner() {
      vm.displayMessage = new DisplayMessage();
    }
  }
})();
