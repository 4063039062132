(function () {
  /**
   * @deprecated Please see the corresponding methods in
   * src2/app/core/contract/contract-compliance/contractComplianceUtils.js or
   * src2/app/core/contract/contract-renewal/contractRenewalUtils.js
   *
   * @ngdoc factory
   * @name ContractCompliance
   * @description factory for contract compliance service
   */
  angular
    .module('app.core.contract.contract-compliance')
    .factory('contractCompliance', ContractCompliance);

  /* @ngInject */
  function ContractCompliance(
    $q,
    _,
    auth,
    Cart,
    COMPLIANCE_CONTEXT,
    LICENSE_QUANTITY_RENEWAL_STATUS,
    LICENSE_QUANTITY_STATUS,
    OrganizationManager
  ) {
    const service = {
      getRenewalSummary,
      hasGracePastDueLicensesForProduct,
      hasNotRenewedLicensesForProduct,
      hasOverDeployedDeviceLicenses,
      hasPastDueLicensesForProduct,
      hasPendingPaymentLicensesForProduct,
    };

    return service;

    function getRenewalSummary(product) {
      const deferred = $q.defer();
      OrganizationManager.getContractsForActiveOrg()
        .$promise.then((contractList) => {
          const contract = contractList.getFirstContractForProduct(product);
          deferred.resolve(contract ? constructRenewalSummary(product, contract) : {});
        })
        .catch(deferred.reject);
      return deferred.promise;
    }

    function hasGracePastDueLicensesForProduct(product) {
      return getUpdatedComplianceQtyForProduct({
        product,
        targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE,
      });
    }

    /**
     * @description Method to determine whether or not the given product
     *   has licenses that are not renewed
     * @ybarra Revisit this when product page banners are ported because this
     * requires methods that are exposed via Cart, but that can be avoided
     * entirely if we can get SLS to expose the equivalent information via
     * compliance symptoms.
     * @param {Product} product the product to check whether there are licenses not being renewed
     * @return {Object|Boolean} If there are licenses not being renewed, an Object is returned with anniversaryDate.
     *   Otherwise, a Boolean false value is returned
     */
    function hasNotRenewedLicensesForProduct(product) {
      return OrganizationManager.getContractsForActiveOrg()
        .$promise.then(getRenewalOrder)
        .then(checkForNotAllLicensesRenewed);

      function getRenewalOrder(contractList) {
        const contract = contractList.getDirectContract();
        return contract && contract.isInRenewalWindow()
          ? $q.all([
              // Pass along the contract to next promise so that we can get the anniversary date
              $q.resolve(contract),
              Cart.get({
                cartType: 'RENEWAL',
                contractId: contract.id,
                lastModifiedById: auth.getUserId(),
              }).$promise,
            ])
          : [];
      }

      function checkForNotAllLicensesRenewed([contract, cart]) {
        return contract && cart && !cart.areAllProvisionedLicensesRenewedForProduct(product)
          ? {anniversaryDate: contract.getAnniversaryDate()}
          : false;
      }
    }

    function hasOverDeployedDeviceLicenses(devicePool) {
      return devicePool.promise.then(checkForOverdeployedLicenses);

      function checkForOverdeployedLicenses(pool) {
        const summary = _.get(pool, 'summary');

        if (summary) {
          const overActivated = summary.getOverActivated();
          if (overActivated > 0) {
            return {count: overActivated};
          }
        }
        return false;
      }
    }

    function hasPastDueLicensesForProduct(product) {
      return getUpdatedComplianceQtyForProduct({
        product,
        targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PAST_DUE,
      });
    }

    function hasPendingPaymentLicensesForProduct(product) {
      return getUpdatedComplianceQtyForProduct({
        product,
        targetProductLicenseQuantityStatus: LICENSE_QUANTITY_STATUS.PENDING_PAYMENT,
      });
    }

    /////////

    function constructRenewalSummary(product, contract) {
      const isInRenewalWindow = contract.isInRenewalWindow();
      const renewalStatus = product.licenseTupleList.getRenewalStatus(
        contract.getRenewalWindowEndDate()
      );

      // True if any messages for this product can refer to users and/or users losing access.
      const isUserBasedRenewal =
        isInRenewalWindow &&
        renewalStatus !== LICENSE_QUANTITY_RENEWAL_STATUS.NOT_RENEWAL &&
        !product.fulfillableItemList.hasOverdelegationAllowed();

      const summary = {
        anniversaryDate: contract.getAnniversaryDate(),
        hasGracePastDueLicenses: product.licenseTupleList.hasGracePastDueLicenses(),
        isIndirectContract: contract.isIndirectContract(),
        isInPostAnniversaryRenewalWindow: contract.isInPostAnniversaryRenewalWindow(),
        isInPreAnniversaryRenewalWindow: contract.isInPreAnniversaryRenewalWindow(),
        isInRenewalWindow,
        isUserBasedRenewal,
        isVIPMPContract: contract.isVIPMPContract(),
        needPaymentLicensesQuantity: product.licenseTupleList.getNeedPaymentQuantity(),
        needRenewalQuantity: product.licenseTupleList.getNeedRenewalQuantity(),
        productId: product.id,
        renewalStatus,
        renewalWindowEndDate: contract.getRenewalWindowEndDate(),
        resellerName: contract.getResellerName(),
      };

      return summary;
    }

    /**
     * @description Helper to determine a contracts COMPLIANCE_CONTEXT
     * @param {Contract} contract contract to check
     *
     * @returns {COMPLIANCE_CONTEXT|undefined} returns COMPLIANCE_CONTEXT, if
     *   contract represents a compliance context that we handle, otherwise,
     *   returns undefined
     */
    function getComplianceContextForContract(contract) {
      if (contract.isIndirectContract()) {
        return COMPLIANCE_CONTEXT.INDIRECT;
      } else if (contract.isDirectContract()) {
        if (contract.isPaymentCategoryPO() || contract.isPaymentCategoryOffline()) {
          return COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE;
        } else if (contract.isPaymentCategoryCreditCard() || contract.isPaymentCategoryPaypal()) {
          if (contract.isPUF()) {
            return COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF;
          } else if (contract.isM2M()) {
            return COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM;
          }
        } else if (contract.isPaymentCategoryDigitalRiver()) {
          return COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER;
        }
      }
      return undefined;
    }

    /**
     * @description Updated helper for calculating the delinquent licenses for a product
     *
     * @param {Object} options options for this method
     * @param {Enum} options.targetProductLicenseQuantityStatus
     *                license quantities will be summed if they match this status
     * @param {Product} options.product product whose license quantities will
     *                                  be summed based on status
     * @returns {Promise} resolved promise with the banner payloads to create,
     *                    or a rejected promise if an error occurs
     */
    function getUpdatedComplianceQtyForProduct(options) {
      const {product, targetProductLicenseQuantityStatus} = options;

      return OrganizationManager.getContractsForActiveOrg()
        .$promise.then((contractList) => {
          const targetContract = contractList.getFirstContractForProduct(product);

          if (!_.isUndefined(targetContract)) {
            const complianceContext = getComplianceContextForContract(targetContract);
            const contractId = targetContract.id;
            let resellerName;
            if (complianceContext === COMPLIANCE_CONTEXT.INDIRECT) {
              resellerName = targetContract.getResellerName();
            }

            const quantity = product.licenseTupleList.getTotalNonRenewingForStatus(
              targetProductLicenseQuantityStatus
            );

            const endDate = product.licenseTupleList.getClosestEndDateForStatus(
              targetProductLicenseQuantityStatus
            );

            if (quantity) {
              return {
                buyingProgram: targetContract.buyingProgram,
                complianceContext,
                contractId,
                count: quantity,
                endDate,
                resellerName,
              };
            }
            return false;
          }
          return false;
        })
        .catch((error) => $q.reject(error));
    }
  }
})();
