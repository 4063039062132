(function () {
  /**
   * @deprecated Partially deprecated, individual deprecated methods marked below.
   */
  angular.module('app.core.contract.contract-utils').factory('contractUtils', contractUtils);

  /* @ngInject */
  function contractUtils(
    _,
    binkyContractPerTermPriceWithTaxFilter,
    CONTRACT_LEGACY_DX_ID,
    MEMBER_TYPE,
    onesieSrc2,
    OrganizationManager,
    User
  ) {
    const service = {
      getContractOwner,
      getDisplayableContracts,
      getFutureCurrency,
      getNextBillingAmountDisplayString,
      isAllowedToAddProducts,
    };

    return service;

    ////////

    function isAllowedToAddProducts() {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return (
        contractList.canAddProducts() &&
        // Currently this org admin check is redundant because the JIL contracts
        // API is already doing this check, but that will be removed in this PR:
        // https://git.corp.adobe.com/JIL-v2/jil-core/pull/12275 to unblock the
        // Orders API from getting doctored symptoms. This comment can be
        // removed when https://jira.corp.adobe.com/browse/ONESIE-33187 is
        // completed.
        onesieSrc2.core.organizations.access.isOrgAdmin() &&
        !OrganizationManager.getMigrationsForActiveOrg().shouldForceBlockPACreation()
      );
    }
    /**
     * @deprecated Please use getContractOwner from src2 contractUtils in binky.
     * @description Gets the contract owner with the pending owner prioritized
     * @param {Contract} contract - Contract containing owner information
     *
     * @property {Boolean} isPending - whether the contract owner is pending ownership
     * @property {Object} person - User object that defines the contract owner or pending owner
     * @returns {Object} - contract owner or pending owner with pending status
     */
    function getContractOwner(contract) {
      const pendingContractOwner = _.get(contract, 'changeOwnerInvitation');
      if (pendingContractOwner) {
        const {email, firstName, lastName} = pendingContractOwner;
        return {
          isPending: true,
          person: new User({
            email,
            firstName,
            lastName,
            type: MEMBER_TYPE.TYPE1, // All team admins are Type 1
          }),
        };
      }
      const personOptions = _.assign(
        {type: _.get(contract, 'ownerInfo.userType')},
        contract.ownerInfo
      );

      return {
        isPending: false,
        person: personOptions.type ? new User(personOptions) : undefined,
      };
    }

    /**
     * @deprecated Please use getAdminConsoleDisplayableContracts from src2
     *   contractListUtils in binky.
     * @description Gets the list of contracts that AC knows how to display.
     *   This removes any DX legacy contracts from the list.
     * @param {Object} [options] - options as described below
     *
     * @property {Boolean} [options.includeInactive] - Whether or not to include inactive contracts.
     *
     * @returns {Array} - array of contracts
     */
    function getDisplayableContracts({includeInactive = false} = {}) {
      return _.reject(OrganizationManager.getContractsForActiveOrg({includeInactive}).items, [
        'id',
        CONTRACT_LEGACY_DX_ID,
      ]);
    }

    /**
     * @description Returns the next formatted billing amount string for this contract, if it has one.
     * @param {Contract} contract The contract to use for billing.
     * @returns {String} returns the next formatted billing amount string for this contract, if it has one,
     *   else undefined.
     */
    function getNextBillingAmountDisplayString(contract) {
      const nextBillingInfo = contract.getNextBillingAmountInfo();
      if (nextBillingInfo) {
        return binkyContractPerTermPriceWithTaxFilter(
          contract,
          nextBillingInfo.includesTax,
          nextBillingInfo.formattedPrice
        );
      }
      return undefined;
    }

    /**
     * @deprecated Please use contract.getFutureCurrency in https://git.corp.adobe.com/admin-tribe/binky/blob/master/src2/core/models/contract/Contract.js
     * @description Returns the future currency value if one exists in the contract.
     * @param {Contract} contract the contract containing currency information
     * @return {String} returns the currency code for a future currency if it has one else undefined.
     */
    function getFutureCurrency(contract) {
      return _.get(contract, 'billingInfo.nextBillingAmount.currency.futureCurrency');
    }
  }
})();
