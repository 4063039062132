/**
 * Defines the encryption settings page under the settings tab.
 */
import {OverlayWait, Page, PageContent, PageHeader} from '@admin-tribe/binky-ui';
import {Divider} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import EncryptionSettings from 'features/settings/components/encryption-settings-management/encryption-settings/EncryptionSettings';
import useOrganizationEncryption from 'features/settings/hooks/use-organization-encryption/useOrganizationEncryption';

const EncryptionSettingsPage = () => {
  const orgId = rootStore.organizationStore.activeOrgId;
  // Retrieve organization's current encryption information
  const {encryptionInfo, error, isLoading} = useOrganizationEncryption({orgId});
  const intl = useIntl();

  return (
    <Page data-testid="encryption-settings-page">
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.encryptionSettings.title',
        })}
      />
      <PageContent>
        <Divider size="S" />
        <OverlayWait isLoading={isLoading}>
          {!isLoading && (
            <EncryptionSettings
              encryptionInfo={encryptionInfo}
              isError={!!error}
              isLoading={isLoading}
            />
          )}
        </OverlayWait>
      </PageContent>
    </Page>
  );
};

export default EncryptionSettingsPage;
