(function () {
  /**
   * @ngdoc module
   * @name app.main.global-modals-manager
   * @description Module which defines the global modules service
   */
  angular.module('app.main.global-modals-manager', [
    'app.core.src2-migration',
    'app.main.global-modals',
    'binky.core.organizations',
    'binky.core.page-context',
  ]);
})();
