import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';

import CardListContentModel from './CardListContentModel';

/**
 * Create a localized copy of RichTextJSON that will be used by the ExperienceCloud help topic card list content model.  Gets the strings themselves from xx.json.
 */
const LocalizeTeamRichTextJson = (intl, locale) => {
  const shortLocale = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale];
  return {
    helpTopicCards: [
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_cct_manage_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.1.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.1.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_ctt_additional_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.1.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.2.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.2.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_cct_add_admin_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.2.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.3.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.3.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_cct_primary_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.3.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.4.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.4.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_cct_ccp_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'teamHelpTopicListContentModel.helpTopicCards.4.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
    ],
  };
};

/**
 * Definition of the "Team" Cloud help topics content model
 */
const Team = (intl, locale) => CardListContentModel(LocalizeTeamRichTextJson(intl, locale));

export default Team;
