import {feature, getTaxTerm, parseISODuration} from '@admin-tribe/binky';
import {useMemo} from 'react';

import {
  getCurrency,
  getPromotionId,
  getRetentionDetails,
  getRetentionDiscountPercent,
  getRetentionDueLaterBillingDate,
  getRetentionDuration,
  getRetentionItemById,
  getRetentionMerchandising,
  getRetentionType,
  isRetentionBestValue,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * @description A helper hook to return the Save Offer details given a retention Id from the
 * Products Change model. Uses Products Change model provided useProductsChangeContext hook.
 * @param {Contract} contract - the contract to determine tax and recurrence terms
 * @returns {Object} retention - Object composed of the following cancellation details properties
 *          {String} [retention.banner] - the URL of the banner image
 *          {Object} retention.currency - Currency object used for the prices
 *          {String} retention.description - Save Offer description
 *          {Date} retention.discountNextBillingDate - The next billing date after the promotion ends
 *          {Number} retention.discountPercentage - Promotional percentage off the total price
 *          {Number} retention.discountPeriod - The duration of the promotion as a number
 *          {Number} retention.discountPrice - Total discount price
 *          {Date} retention.discountStartDate - The first date (inclusive) that the promotion is active
 *          {String} retention.headline - Save Offer headline
 *          {String} [retention.icon] - the URL of the icon image
 *          {Boolean} [retention.isBestValue] - Whether this Save Offer has the best total discount
 *          from the returned list.
 *          {Number} retention.nextPrice - Price during promotion
 *          {String} retention.recurrenceTerm - Recurrence Term, i.e. MONTHLY
 *          {Number} retention.regularPrice - Price after promotion
 *          {Object} retention.retention - The retention original object
 *          {String} retention.shortDescription - Save Offer short description
 *          {String} retention.taxTerm - Tax term, i.e. TAX
 *          {String} retention.termsAndConditions - Save Offer terms and conditions
 */
const useRetentionDetails = ({contract, retentionId}) => {
  const {productsChange} = useProductsChangeContext();
  const isSophiaMerchandisingEnabled = feature.isEnabled('temp_sophia_promotional_offer');

  return useMemo(() => {
    const retention = getRetentionItemById(productsChange, retentionId);
    const merchandising = getRetentionMerchandising(retention);
    const retentionDetails = getRetentionDetails(retention);
    const retentionDuration = getRetentionDuration(retention);
    const contractData = retentionDetails?.contractData;
    const merchandisingTexts = merchandising?.copy || {};
    const merchandisingAssets = merchandising?.assets || {};

    const {description, headline, shortDescription, termsAndConditions} = merchandisingTexts;
    const banner = merchandisingAssets?.backgroundImages?.['254x70'];
    const icon = merchandisingAssets?.icons?.svg;

    const isBestValue = isRetentionBestValue(retention);
    const nextPrice = contractData?.nextBilling?.totalWithoutTax;
    const regularPrice = contractData?.existingBilling?.totalWithoutTax;

    const discountNextBillingDate = getRetentionDueLaterBillingDate(retention);
    const discountPrice = contractData?.discount?.totalWithoutTax;
    const discountPriceWithTax = contractData?.discount?.totalWithTax;
    const discountPercent = getRetentionDiscountPercent(retention);
    const discountStartDate = retentionDuration?.startDate;
    const discountPeriod =
      retentionDuration?.discountPeriod?.length > 0
        ? parseISODuration(retentionDuration?.discountPeriod)?.months
        : undefined;

    const type = getRetentionType(retention);

    let promotionId;

    if (isSophiaMerchandisingEnabled) {
      promotionId = getPromotionId(retention);
    }

    const currency = retentionDetails?.currency || getCurrency(productsChange);
    const recurrenceTerm = contract.getBillingFrequency();
    const taxTerm = getTaxTerm(contract.getOwnerCountryCode());

    return {
      banner,
      currency,
      description,
      discountNextBillingDate,
      discountPercent,
      discountPeriod,
      discountPrice,
      discountPriceWithTax,
      discountStartDate,
      headline,
      icon,
      isBestValue,
      nextPrice,
      promotionId,
      recurrenceTerm,
      regularPrice,
      retention,
      shortDescription,
      taxTerm,
      termsAndConditions,
      type,
    };
  }, [contract, productsChange, retentionId, isSophiaMerchandisingEnabled]);
};

export default useRetentionDetails;
