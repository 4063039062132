(function () {
  /**
   * @deprecated replaced by the Pandora Add Products Modal
   */
  angular
    .module('app.core.product.purchase')
    .constant('PRODUCT_BUCKET', {
      OWNED_OFFERS: 'ownedOffers',
      PROMO_OFFERS: 'promoOffers',
      UNOWNED_WITH_REC_OFFERS: 'unownedWithRecOffers',
      UNOWNED_WITHOUT_REC_OFFERS: 'unownedWithoutRecOffers',
    })
    .constant('CART_SERIALIZE', {ITEM_PARAM: 'items', OFFER_ID_PARAM: 'id', QUANTITY_PARAM: 'q'});
})();
