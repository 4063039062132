import {
  EVENT_ACTION,
  constructAsyncAddAssignUserAnalytics,
  dispatchUiEventAnalytics,
  log,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

/**
 * Convert a product object to an object suitable for dispatching to analytics.
 * @param {Object} product - The product object that will be converted to an analytics object
 * @returns An object representing the product in analytics data
 */
const getAnalyticsProductFromProduct = (product) => ({
  licenseCount: product.getSeatBasedAssignableLicenseCount(),
  numberSelected: product.delegatedQuantity,
  numberUnassigned: product.getAvailableLicenseCount(),
  offer_id: product.offerId,
});

/**
 * Determine which products have been modified and create a collection of analytics objects representing
 * the changed values.
 * @param {Object} options - The arguments object passed to the function
 * @param {Object[]} [options.committedItems] - A collection of new and changed users and product assignments
 * @param {Object[]} [options.committedItems.products] - A collection of products assigned to this user
 * @param {Object[]} [options.initialItems] - A collection of users and product assignments before any changes were made during the flow
 * @param {Object[]} [options.initialItems.products] - A collection of products assigned to this user
 * @returns
 */
const getProductLicenseAnalytics = ({committedItems = [], initialItems = []} = {}) => {
  // We just need the Offer IDs for all products added, edited, or removed during the flow.
  let modifiedProducts = committedItems.flatMap((user) =>
    user.products.map((product) => product.id)
  );

  modifiedProducts = [
    ...modifiedProducts,
    ...initialItems.flatMap((user) => user.products.map((product) => product.id)),
  ];

  const productList = rootStore.organizationStore.productList;
  const productAnalytics = productList.items
    .filter(({id}) => modifiedProducts.includes(id))
    .map(getAnalyticsProductFromProduct);
  return productAnalytics.length > 0 ? {items: productAnalytics} : undefined;
};

/**
 * Get the assigned license and user count variance based on the state provided
 * @param {Object} options - The arguments object passed to the function
 * @param {Object[]} [options.committedItems] - A collection of new and changed users and product assignments
 * @param {Object[]} [options.committedItems.products] - A collection of products assigned to this user
 * @param {Object[]} [options.initialItems] - A collection of users and product assignments before any changes were made during the flow
 * @param {Object[]} [options.initialItems.products] - A collection of products assigned to this user
 * @returns {Object} - The variation from the initial state {assignedLicenseVariation, userCountVariation}
 */
const getVariationFromInitialState = ({committedItems = [], initialItems = []} = {}) => {
  let assignedLicenseVariation = committedItems.reduce(
    (total, user) => total + user.products.length,
    0
  );

  assignedLicenseVariation = initialItems.reduce(
    (total, user) => total - user.products.length,
    assignedLicenseVariation
  );

  // How many users have been added during this interaction.
  const userCountVariation = committedItems
    .filter((user) => user.isNew())
    .reduce((total) => total + 1, 0);

  return {assignedLicenseVariation, userCountVariation};
};

/**
 * @description Trigger button press analytics
 * @param {Object[]} [state.committedItems=[]] - A collection of new and changed users and product assignments
 * @param {String} [componentName] - The name of the source component ()
 * @param {String} [eventAction=success] - The event action
 * @param {String} eventName - The name of the event
 * @param {Object[]} [state.initialItems=[]] - A collection of users and product assignments before any changes were made during the flow
 * @param {String} orgId - The Organization ID
 * @returns {Promise} - A promise which will resolve when the analytics event has dispatched.
 */
const dispatchUserModalSuccessAnalytics = async ({
  committedItems = [],
  componentName,
  eventAction = EVENT_ACTION.SUCCESS,
  eventName,
  initialItems = [],
  orgId,
}) => {
  try {
    // Refresh product list store so analytics are up to date
    await rootStore.organizationStore.updateProductListSingleton();

    const analyticsContext = await constructAsyncAddAssignUserAnalytics({orgId});
    const additionalUserModalAnalytics = getVariationFromInitialState({
      committedItems,
      initialItems,
    });
    const productAnalytics = getProductLicenseAnalytics({committedItems, initialItems});

    dispatchUiEventAnalytics({
      componentName,
      eventAction,
      eventName,
      organizationInfo: {
        ...analyticsContext.organizationInfo,
        ...additionalUserModalAnalytics,
      },
      productList: productAnalytics,
    });
  } catch (error) {
    log.error('dispatchUserModalSuccessAnalytics call has unexpectedly rejected', error);
  }
};

export {
  dispatchUserModalSuccessAnalytics,
  getProductLicenseAnalytics,
  getVariationFromInitialState,
};
