(function () {
  /**
   * @ngdoc module
   * @name app.widgets.contracts.migrations
   * @description module that defines app.widgets.contracts.migrations widgets
   */
  angular.module('app.widgets.contracts.migrations', [
    'app.core.contract.migrations',
    'app.widgets.contracts.migrations.constants',
    'app.widgets.link-card',
    'app.widgets.products',
    'binky.core.contract.migrations',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.widgets.common.events',
    'binky.widgets.common.feature',
    'binky.widgets.common.wizard',
    'pascalprecht.translate',
  ]);
})();
