import {InvoiceList} from '@admin-tribe/binky';
import {useReducer} from 'react';

import rootStore from 'core/RootStore';

const PO_NUMBER_UPDATE_ACTIONS = {
  PO_NUMBER_UPDATE: 'PO_NUMBER_UPDATE',
  PO_NUMBER_UPDATE_FAILED: 'PO_NUMBER_UPDATE_FAILED',
  PO_NUMBER_UPDATE_FINISHED: 'PO_NUMBER_UPDATE_FINISHED',
  PO_NUMBER_UPDATE_START: 'PO_NUMBER_UPDATE_START',
};

// eslint-disable-next-line consistent-return -- reducer will only have these consistent states
const editPoNumberReducer = (state, {action, payload}) => {
  // eslint-disable-next-line default-case -- reducer only used inside this file
  switch (action) {
    case PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_START:
      return {
        ...state,
        hasUpdateError: false,
        isUpdateSubmitted: false,
        isUpdating: true,
        updatedInvoiceId: '',
      };
    case PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE:
      return {
        ...state,
        updatedInvoiceId: payload.invoiceId,
        updatedPoNumbers: {
          ...state.updatedPoNumbers,
          [payload.invoiceId]: payload.poNumber,
        },
      };
    case PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_FINISHED:
      return {
        ...state,
        isUpdateSubmitted: true,
        isUpdating: false,
      };
    case PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_FAILED:
      return {
        ...state,
        hasUpdateError: true,
      };
  }
};

/**
 * @description Hook used to update the invoice.
 *
 * @param {Object} data - object with data for the invoice.
 * @param {String} data.contractId - contract id of the invoice.
 * @param {String} data.invoiceId - id of the invoice.
 * @param {String} data.newPoNumber - new PO number to be saved.
 * @param {String} data.oldPoNumber - old PO number that being changed.
 * @param {String} data.reason - the reason for updaing the PO number.
 * (i.e. 'MISSING_PO_NUMBER', 'INCORRECT_PO_NUMBER', 'INVALID_PO_NUMBER').
 * @returns {Object} state - Object composed of state variables: changePoNumber, isUpdating.
 *          {Function} state.changePoNumber - Callback to change the PO number.
 *          {Boolean} state.hasUpdateError - Whether the call was successful or not.
 *          {Boolean} state.isUpdateSubmitted - Whether the call was made.
 *          {Boolean} state.isUpdating - Whether the call is currently being made.
 *          {String} state.updatedInvoiceId - Id of the updated invoice.
 *          {Object} state.updatedPoNumbers - Key value pairs object of invoiceId to PO number.
 */
const useUpdateInvoice = () => {
  const orgId = rootStore.organizationStore.activeOrgId;

  const [poNumberStore, dispatchPoNumberChange] = useReducer(editPoNumberReducer, {
    hasUpdateError: false,
    isUpdateSubmitted: false,
    isUpdating: false,
  });

  const changePoNumber = async ({contractId, invoiceId, newPoNumber, oldPoNumber, reason}) => {
    dispatchPoNumberChange({action: PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_START});
    try {
      const invoiceList = await InvoiceList.get({
        orgId,
      });
      await invoiceList.savePONumber({contractId, invoiceId, newPoNumber, oldPoNumber, reason});
      dispatchPoNumberChange({
        action: PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE,
        payload: {invoiceId, poNumber: newPoNumber},
      });
    } catch {
      // Logging the error is handled by binky's savePONumber
      dispatchPoNumberChange({action: PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_FAILED});
    } finally {
      dispatchPoNumberChange({action: PO_NUMBER_UPDATE_ACTIONS.PO_NUMBER_UPDATE_FINISHED});
    }
  };

  const {hasUpdateError, isUpdateSubmitted, isUpdating, updatedInvoiceId, updatedPoNumbers} =
    poNumberStore;

  return {
    changePoNumber,
    hasUpdateError,
    isUpdateSubmitted,
    isUpdating,
    updatedInvoiceId,
    updatedPoNumbers,
  };
};

export default useUpdateInvoice;
