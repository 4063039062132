(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.core.user.user-helper
   * @description User helper methods
   */
  angular.module('app.core.user').factory('userHelper', getUserHelper);
  /* @ngInject */
  function getUserHelper(binkySrc2, OrganizationManager) {
    const service = {
      refreshOrganizationUserListCount,
    };

    return service;

    //////////////

    function refreshOrganizationUserListCount() {
      return new binkySrc2.services.users.OrganizationUserListCount({
        orgId: OrganizationManager.getActiveOrgId(),
      })
        .refresh()
        .then((model) => model.totalCount);
    }
  }
})();
