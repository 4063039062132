(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common.links
   * @description module that defines widgets related to links
   */
  angular.module('app.widgets.common.links', [
    'app.widgets.common.links.button-link',
    'app.widgets.common.links.icon-link',
    'app.widgets.common.links.tab-link',
    'app.widgets.common.links.text-link',
  ]);
})();
