/**
 * Adjusts the React and Angular shells' CSS to accomodate each other and the header nav
 * @param {Object} props
 * @param {boolean} props.isSrc1Route - Whether the current route is an Angular route
 */
const useShellResizer = ({isSrc1Route}) => {
  const prioritizeAngularShell = isSrc1Route === true || isSrc1Route === null;

  const headerNavHeight = '56px'; // Equivalent to "size-700" in Spectrum V3
  const reactShellPageContent = document.querySelector('#app-main [data-testid="react-shell"]');
  const angularShell = document.querySelector('.angular-app');
  const angularStyles = {
    top: headerNavHeight, // Shift the Angular shell down to accomodate the React header
  };
  const reactShellPageContentStyles = {};

  if (prioritizeAngularShell) {
    angularStyles.height = `calc(100% - ${headerNavHeight})`; // Reduce the height of the Angular shell to accomodate the React header
    angularStyles.zIndex = 'auto';
    reactShellPageContentStyles.display = 'none'; // Hide the React page content, but NOT the header
  } else {
    angularStyles.height = 'auto';
    angularStyles.zIndex = 1;
    reactShellPageContentStyles.display = 'grid';
  }

  if (reactShellPageContent) {
    Object.assign(reactShellPageContent.style, reactShellPageContentStyles);
  }

  if (angularShell) {
    Object.assign(angularShell.style, angularStyles);
  }
};

export default useShellResizer;
