(function () {
  /**
   * @deprecated. Use src2 bulkOperationUtils.
   * This file and whole module can be removed with temp_standalone_quick_assign
   */
  angular
    .module('app.widgets.bulk-operations.utils')
    .factory('bulkOperationUtils', bulkOperationUtils);

  /* @ngInject */
  function bulkOperationUtils(
    $q,
    $translate,
    _,
    BULK_OPERATION_ERRORS,
    BULK_OPERATION_ROW_LIMIT,
    bulkOperationLocalization,
    DisplayMessage,
    numberFilter,
    PAGE_TARGET_TYPE
  ) {
    const service = {
      getErrorMessage,
      getSuccessMessage,
      uploadFile,
    };

    return service;

    ////////

    /**
     * @description Returns DisplayMessage with correct translated body/header
     * @param {Object} error - Error object from the rejected promise
     * @param {Object} error.data.errorCode - Error code used to determine the error message
     *
     * @returns {DisplayMessage} - DisplayMessage with body and optional header
     */
    function getErrorMessage(error) {
      const errorCode = _.get(error, 'data.errorCode');

      if (errorCode === 'BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT') {
        return new DisplayMessage({
          body: $translate.instant(
            'widgets.bulkOperations.error.message.BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT.message',
            {rowLimit: numberFilter(BULK_OPERATION_ROW_LIMIT)},
            'messageformat'
          ),
          header: $translate.instant(
            'widgets.bulkOperations.error.message.BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT.header'
          ),
        });
      } else if (_.includes(BULK_OPERATION_ERRORS, errorCode)) {
        return new DisplayMessage({
          body: $translate.instant(`widgets.bulkOperations.error.message.${errorCode}`),
        });
      }
      return new DisplayMessage({
        body: $translate.instant('widgets.bulkOperations.error.generic'),
      });
    }

    /**
     * @description Returns translated bulk operations success message
     * @param {Number} fileCount the number of files successfully uploaded
     *
     * @returns {String} translated success text
     */
    function getSuccessMessage(fileCount) {
      return $translate.instant(
        'widgets.bulkOperations.success',
        {
          count: fileCount,
        },
        'messageformat'
      );
    }

    /**
     * @description Uploads the file with the given bulkContext and pageContext
     *
     * @param {Object} bulkContext bulkContextData object with bulk-operations-context data
     * @param {Object} bulkContextData.uploadResource a reference to the resource to be used for uploading
     * @param {Object} bulkContextData.uploadFunction upload function to be called for uploadResource
     * @param {Object} [bulkContextData.uploadParams] optional upload parameters for the upload function
     * @param {PageContext} pageContext PageContext of page that is submitted the file upload
     * @param {File} file file to be uploaded
     *
     * @returns {Promise} resolved Promise when file upload succeeds else rejected promise
     */
    function uploadFile(bulkContext, pageContext, file) {
      if (_.get(pageContext, 'targetType') === PAGE_TARGET_TYPE.USER) {
        return delocalizeAndUpload(bulkContext, file);
      }

      const deferred = $q.defer();
      upload(bulkContext, deferred, file, file.name);
      return deferred.promise;
    }

    /* Private functions */

    /**
     * @description Delocalizes the user CSV and uploads it
     * @param {Object} bulkContext bulkContext object with bulk-operations-context data
     * @param {Object} bulkContextData.uploadResource a reference to the resource to be used for uploading
     * @param {Object} bulkContextData.uploadFunction upload function to be called for uploadResource
     * @param {Object} [bulkContextData.uploadParams] optional upload parameters for the upload function
     * @param {File} file file to be uploaded
     *
     * @returns {Promise} resolved Promise when file upload succeeds else rejected promise
     */
    function delocalizeAndUpload(bulkContext, file) {
      const deferred = $q.defer();
      bulkOperationLocalization
        .delocalizeCsvFile(file)
        .then((delocalizedBlob) => {
          upload(bulkContext, deferred, delocalizedBlob, file.name);
        })
        .catch((error) => {
          deferred.reject({data: {errorCode: error.message}});
        });

      return deferred.promise;
    }

    /**
     * @description Uploads the file with the given bulkContext
     *
     * @param {Object} bulkContext bulkContextData object with bulk-operations-context data
     * @param {Object} bulkContextData.uploadResource a reference to the resource to be used for uploading
     * @param {Object} bulkContextData.uploadFunction upload function to be called for uploadResource
     * @param {Object} [bulkContextData.uploadParams] optional upload parameters for the upload function
     * @param {Deferred} deferred deferred object for Promise resolve and reject assignment
     * @param {File} file file to be uploaded
     * @param {String} fileName file name
     */
    function upload(bulkContext, deferred, file, fileName) {
      const {uploadResource, uploadFunction, uploadParams} = bulkContext;

      const data = new FormData();
      data.append('file', file, fileName);
      // we wrap the file upload in its own promise
      uploadResource[uploadFunction](uploadParams || {}, data, deferred.resolve, deferred.reject);
    }
  }
})();
