// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {feature} from '@admin-tribe/binky';
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import SophiaPromoRightRailView from 'common/components/sophia/SophiaPromoRightRailView';
import SophiaRightRailMerchandisingView from 'common/components/sophia/SophiaRightRailMerchandisingView';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapRightRailMerchandisingContent} from 'common/services/sophia/utils/utils';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

const SophiaRightRailMerchandisingSection = ({isHidden}) => {
  const [sophiaContent, setSophiaContent] = React.useState(null);
  const hideContent = {...{base: true, L: false, M: true, S: true}, ...isHidden};
  const [isDismissed, setIsDismissed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_MERCHANDISING;

  // Fetches sophia cards on mount
  useEffect(() => {
    const fetchSophiaContent = async () => {
      setSophiaContent(
        await sophiaHelper.getSophiaContent({
          surfaceId,
        })
      );
      setIsLoading(false);
    };

    fetchSophiaContent();
  }, [surfaceId]);

  // render nothing if there are no cards, or the list fails to fetch
  if (!sophiaContent?.items?.[0]?.content) {
    return null;
  }

  const content = mapRightRailMerchandisingContent(JSON.parse(sophiaContent.items[0].content));
  const dismissView = () => setIsDismissed(true);
  return (
    <View isHidden={isDismissed}>
      <OverviewPod
        data-testid="sophia-right-rail-merchandising-section"
        isHidden={hideContent}
        isLoading={isLoading}
      >
        <Flex
          alignItems="baseline"
          data-testid="sophia-right-rail-merchandising-space"
          gap="size-300"
          marginTop="size-150"
          wrap
        >
          {feature.isEnabled('temp_use_sophia_promo_rail') && (
            <SophiaPromoRightRailView onDismiss={dismissView} sophiaContent={content} />
          )}
          {!feature.isEnabled('temp_use_sophia_promo_rail') && (
            <SophiaRightRailMerchandisingView onDismiss={dismissView} sophiaContent={content} />
          )}
        </Flex>
      </OverviewPod>
    </View>
  );
};

SophiaRightRailMerchandisingSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaRightRailMerchandisingSection;
