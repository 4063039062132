/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCancelCaseCreationModal
 *
 * @description The modal to confirm the Create Case form should be discarded without saving.
 *
 * @param {Function} onDiscard. The callback to the discard. It has no parameters.
 */
(function () {
  angular.module('app.widgets.support').component('appCancelCaseCreationModal', {
    bindings: {
      onDiscard: '&',
      onResume: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/support/cancel-case-creation-modal/cancel-case-creation-modal.component.html',
  });

  function controller(_, CANCEL_CASE_CREATION_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      CANCEL_CASE_CREATION_MODAL_ID,
    });
  }
})();
