import rootStore from 'core/RootStore';
import {getManageableAdminRoles} from 'core/admin/access/adminAccess';
import {canViewValueDiscoveryTasksForTeam} from 'core/organizations/access/organizationAccess';
import AddAdminModal from 'features/users/components/add-admin-modal/AddAdminModal';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to add admins
 */
class AddAdminsHva extends HvaCard {
  /**
   * @description Method to fetch the AddAdminsHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<AddAdminsHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (!canViewValueDiscoveryTasksForTeam()) {
      return Promise.reject();
    }

    const manageableRoles = await getManageableAdminRoles();
    if (!manageableRoles || manageableRoles.length === 0) {
      return Promise.reject();
    }

    return [new AddAdminsHva({intl, manageableRoles})];
  }

  /**
   * @description Method to create an AddAdminsHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @param {ROLE[]} obj.manageableRoles - manageable roles
   *
   * @returns {AddAdminsHva} the fetched instance
   */
  constructor({intl, manageableRoles}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.addAdmins';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_admin_teams_learn',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/add-admins.svg',
        style: {width: '40px'},
      },
      id: 'hva-add-admins',
      isDismissible: true,
      modalConfig: {
        closeProp: 'onCancel',
        Component: AddAdminModal,
        props: {
          includeIsOpen: true,
          orgId: rootStore.organizationStore.activeOrgId,
          roles: manageableRoles,
        },
        successProp: 'onSuccess',
      },
      priorityOrder: HVA_ORDER.ADD_ADMINS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default AddAdminsHva;
