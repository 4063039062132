(function () {
  /**
   * @deprecated with temp_react_port_jobs. Built into src2/app/common/services/job-result-list/JobResultList.js
   *
   * @ngdoc factory
   * @name jobListHelper
   * @description Provides utility functions for job list
   */
  angular.module('app.core.jobs').factory('jobListHelper', factory);
  /* @ngInject */
  function factory(_, JOB_ITEM_FAILURE_TYPE, t2eMigrationManager) {
    const service = {
      getFailureMessageTranslateKeySuffix,
    };

    return service;

    //////////////

    /**
     * @description Method to find the translation string key suffix
     * from an error code
     * @param {JOB_ITEM_FAILURE_TYPE} failureType - failure type to construct
     * the translation key suffix from
     * @returns {String} translation string key suffix
     */
    function getFailureMessageTranslateKeySuffix(failureType) {
      if (
        t2eMigrationManager.isT2EClean() &&
        _.includes(
          [JOB_ITEM_FAILURE_TYPE.INVALID_USER_TYPE, JOB_ITEM_FAILURE_TYPE.UNKNOWN_USER_TYPE],
          failureType
        )
      ) {
        return `${failureType}_T2E`;
      }

      return failureType;
    }
  }
})();
