// eslint-disable-next-line angular/window-service
const binkySrc2 = window.binkySrc2;
(function () {
  /**
   * @deprecated
   */
  angular.module('app.core.model-cache').constant('MODEL', {
    ACCEPTEDUSERLIST: 'AcceptedUserList',
    ACTIVETRUSTLIST: 'ActiveTrustList',
    APINTEGRATION: 'ApiIntegration',
    ASSETMIGRATIONPOLICY: 'AssetMigrationPolicy',
    CONTRACTCART: 'ContractCart',
    COUNTRYLIST: 'CountryList',
    DEACTIVATEDEVICELIST: 'DeactivatedDeviceList',
    DELEGATIONSUSERSLIST: 'DelegationsUsersList',
    DEVICEPACKAGEPOOL: 'DevicePackagePool',
    DIRECTORYSAMLCONFIG: 'DirectorySamlConfig',
    DIRECTORYTRUSTLIST: 'DirectoryTrustList',
    DIRECTORYUSER: 'DirectoryUser',
    DIRECTORYUSERLIST: 'DirectoryUserList',
    DIRECTORYUSERLISTCOUNT: 'DirectoryUserListCount',
    EXPERTSESSION: 'ExpertSession',
    EXPERTSESSIONLIST: {
      ACTIVE: 'ExpertSessionListActive',
      INACTIVE: 'ExpertSessionListInactive',
    },
    LICENSECONFIGURATION: 'LicenseConfiguration',
    LICENSECONFIGURATIONADMINLIST: 'LicenseConfigurationAdminList',
    LICENSECONFIGURATIONINTEGRATIONLIST: 'LicenseConfigurationIntegrationList',
    LICENSECONFIGURATIONLIST:
      binkySrc2.services.product.licenseGroup.LICENSE_GROUP_CONSTANTS.LICENSE_GROUP_LIST_CACHE_ID,
    LICENSECONFIGURATIONUSERLIST: 'LicenseConfigurationUserList',
    ORGANIZATIONADMINLIST: 'OrganizationAdminList',
    ORGANIZATIONINTEGRATIONLIST: 'OrganizationIntegrationList',
    ORGANIZATIONTOKEN: 'OrganizationToken',
    ORGANIZATIONUSER: 'OrganizationUser',
    ORGANIZATIONUSERLIST: 'OrganizationUserList',
    PACKAGES: {
      ADMIN_PACKAGES: 'PackagesAdminPackages',
      ADOBE_PRODUCTS: 'PackagesAdobeProducts',
      ADOBE_PRODUCTS_UI: 'PackagesAdobeProductsUI',
      ADOBE_TEMPLATES: 'PackagesAdobeTemplates',
      ADOBE_UPDATES: 'PackagesAdobeUpdates',
      LAN_SERVERS: 'PackagesLanServers',
      UI_CONSTANTS: 'PackagesUiConstants',
    },
    PAYMENTINSTRUMENT: 'PaymentInstrument',
    PENDINGTRUSTLIST: 'PendingTrustList',
    POLICYLIST: 'PolicyList',
    PRODUCTADMINLIST: 'ProductAdminList',
    PRODUCTGROUPPRODUCTLIST: 'ProductGroupProductList',
    PRODUCTNOACCESSUSERLIST: 'ProductNoAccessUserList',
    PRODUCTPAYMENTSTATUSUSERLIST: 'ProductPaymentStatusUserList',
    PRODUCTPROVISIONINGSTATUSUSERLIST: 'ProductProvisioningStatusUserList',
    PRODUCTSAPPLICATIONINTEGRATION: 'ProductsApplicationIntegration',
    PRODUCTSAPPLICATIONINTEGRATIONSGLOBALPOLICY: 'ProductsApplicationIntegrationsGlobalPolicy',
    PRODUCTSAPPLICATIONINTEGRATIONSLIST: 'ProductsApplicationIntegrationsList',
    PRODUCTUSERLIST: 'ProductUserList',
    SEARCHUSERLIST: 'SearchUserList',
    SHAREDFOLDER: 'SharedFolder',
    SHAREDFOLDERLIST: 'SharedFolderList',
    SHAREDFOLDERMETADATA: 'SharedFolderMetadata',
    SOLUTIONGROUPLIST: 'SolutionGroupList',
    STOCKQUANTITYLIST: 'StockQuantityList',
    STORAGEQUOTA: 'StorageQuota',
    STORAGEREPOLIST: 'StorageRepositoryList',
    STORAGEREPORT: 'StorageReport',
    STORAGEREPORTFOLDER: 'StorageReportFolder',
    STORAGEREPORTFOLDERLIST: 'StorageReportFolderList',
    STORAGEREPORTFOLDERMETADATA: 'StorageReportFolderMetadata',
    STORAGEREPORTLIST: 'StorageReportList',
    STORAGEREPORTMETADATA: 'StorageReportMetadata',
    STORAGESTATS: 'StorageStats',
    SUPPORTCASE: 'SupportCase',
    SUPPORTCASELIST: {
      ACTIVE: 'SupportCaseListActive',
      INACTIVE: 'SupportCaseListInactive',
    },
    TRUST: 'Trust',
    TRUSTLIST: 'TrustList',
    USERFOLDER: 'UserFolder',
    USERFOLDERLIST: 'UserFolderList',
    USERFOLDERMETADATA: 'UserFolderMetadata',
    USERGROUPADMINLIST: 'UserGroupAdminList',
    USERGROUPLIST: 'UserGroupList',
    USERGROUPPRODUCTCONFIGURATIONLIST: 'UserGroupProductConfigurationList',
    USERGROUPUSERLIST: 'UserGroupUserList',
  });
})();
