import {MEMBER_EVENT, User, log, modelCache} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';

import {
  ACTIONS,
  MEDIA_TYPE,
  REMOVE_USER_STORAGE_OPTIONS,
} from 'common/services/user-folder/userFolderConstants';

import {JIL_STORAGE_FOLDER_PATH, JIL_STORAGE_TYPE} from '../../api/jil/jilStorageFolderConstants';
import jilStorageFolders from '../../api/jil/jilStorageFolders';
import StorageQuota from '../storage-quota/StorageQuota';
import STORAGE_QUOTA_CONSTANTS from '../storage-quota/StorageQuotaConstants';

const {TYPE} = STORAGE_QUOTA_CONSTANTS;
const CACHE_ID = 'UserFolder';

modelCache.clearOnEvent(CACHE_ID, [MEMBER_EVENT.CREATE, MEMBER_EVENT.DELETE, MEMBER_EVENT.UPDATE]);

class UserFolder {
  /**
   * @description Method to fetch the remove path override for user with user folders.
   *
   * @param {String} [id] - user id to restore user folder
   * @param {REMOVE_USER_STORAGE_OPTIONS} [type] - type of remove user storage
   * @param {Array<UserFolder>} [userStorageList] - user folders to operate
   *
   * @returns {String} remove path override.
   */
  static getRemovePathOverride({id, type, userStorageList}) {
    const result = {};
    userStorageList.forEach((userFolder) => {
      switch (type) {
        case REMOVE_USER_STORAGE_OPTIONS.DISCARD:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE`;
          break;
        case REMOVE_USER_STORAGE_OPTIONS.DELETE:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/DELETE`;
          break;
        default:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE/${id}`;
      }
    });
    return result;
  }

  /**
   * @description Creates a new UserFolder for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {User} options.deletedBy The user who deleted the UserFolder
   * @param {Date} options.deletedOn The deletion date of the UserFolder
   * @param {String} options.id A UserFolder's ID
   * @param {String} options.name A UserFolder's name
   * @param {Array} options.owners A UserFolder's owners
   * @param {String} options.path the path of the UserFolder
   * @param {StorageQuota} options.quota the storage quota of the UserFolder
   * @param {Date} options.repositoryCreatedDate The creation date of the UserFolder
   * @param {Date} options.repositoryLastModifiedDate The last modification date of the UserFolder
   * @param {String} options.state the state of the UserFolder
   * @param {String} options.type the type of the UserFolder
   * @param {String} options.userName User name of the UserFolder
   */
  constructor(options = {}) {
    const clonedOptions = cloneDeep(options);
    const owners = clonedOptions.owners?.map((owner) => new User(owner)) || [];
    Object.assign(this, clonedOptions, {
      owners,
      quota: new StorageQuota({enforcement: TYPE.HARD, ...options.quota}),
      zipAssignedUsers: owners,
    });

    if (this.deletedBy) {
      this.deletedBy = new User(this.deletedBy);
    }

    // The name send back from the server could be encoded
    if (this.name) {
      this.name = decodeURI(this.name);
    }
  }

  /**
   * @description Fetches the operation object used for saving to a UserFolder
   *
   * @returns {Object} the operation.
   * @returns {String} {}.op - the operation to make on the folder,
   *  either ACTION.ADD or ACTION.REMOVE.
   * @returns {String} {}.path - the path to the user being modified in the folder.
   */
  #getOperation(user, op) {
    return {op, path: `/${this.id}/owners/${user.id}`};
  }

  /**
   * @description Fetch the zip assigned users of the UserFolder.
   *
   * @returns {Promise} resolves with the current UserFolder on
   *  success, else rejects with an error.
   */
  async getFolderZipProgress() {
    try {
      const {data} = await jilStorageFolders.getUserFoldersByPath({
        path: `/${JIL_STORAGE_FOLDER_PATH.RECLAIMED}/${this.name}`,
      });
      // Update UserFolder to have the fetched zipAssignedUsers
      const {id, owners} = data;
      Object.assign(this, {id, zipAssignedUsers: owners});
    } catch (error) {
      log.error('UserFolder failed to fetch folder zip. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Fetch the UserFolder key, used for caching.
   *
   * @returns {String} the key for the instance of the UserFolder.
   */
  getKey() {
    return this.id;
  }

  /**
   * @description Checks if this UserFolder represents a single folder.
   *
   * @returns {Boolean} true if this instance of the UserFolder is a single
   *  type of folders, returns false if it is multiple - MEDIA_TYPE.FOLDERS.
   */
  isFolder() {
    return this.type === MEDIA_TYPE.FOLDER;
  }

  /**
   * @description Saves changes made to the UserFolder which can include
   *  removing and adding users to the folder.
   *
   * @returns {Promise} of the patch call that saves the modifications of the folder.
   */
  save({usersToAdd, usersToRemove}) {
    const addOperations = usersToAdd.map((user) => this.#getOperation(user, ACTIONS.ADD));
    const removeOperations = usersToRemove.map((user) => this.#getOperation(user, ACTIONS.REMOVE));
    const operations = [...addOperations, ...removeOperations];

    return jilStorageFolders.patchStorageFolders({operations, type: JIL_STORAGE_TYPE.USERS});
  }
}

export default UserFolder;
