(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.core.location-from-page-load')
    .factory('locationFromPageLoad', locationFromPageLoad);

  /* @ngInject */
  function locationFromPageLoad($window, _) {
    const service = {
      clear,
      hash,
    };

    return service;

    ////////

    /**
     * @description Clears the record of the hash that was captured when the site loaded.
     */
    function clear() {
      $window.hashFromPageLoad = '';
    }

    /**
     * @description Returns the hash fragment that was captured when the site loaded, or an empty
     * string if no hash was set.
     *
     * Similar to $location.hash(), the return value is URI decoded and does not include a leading
     * #. For example, if the site loaded at URL http://example.com/#/some/path?foo=bar#hash%20value
     * then this function would return 'hash value'.
     *
     * @returns {String} the hash fragment
     */
    function hash() {
      const rawHash = _.isString($window.hashFromPageLoad) ? $window.hashFromPageLoad : '';
      const strippedHash = _.startsWith(rawHash, '#') ? rawHash.substring(1) : rawHash;
      return decodeURIComponent(strippedHash);
    }
  }
})();
