// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- excluding to improve jest performance
// istanbul ignore next -- excluding to improve jest performance
const OBSERVABILITY_SPAN_LIST = {
  ACCOUNT: 'account',
  ADDITIONAL_PRODUCTS_LIST: 'additional-products-list',
  CONTRACT_INFO: 'contract-info',
  IDENTITY_DOMAINS: 'identity-domains',
  OVERVIEW: 'overview',
  PRODUCTS_AND_LICENSES_LIST: 'products-and-licenses-list',
  PRODUCTS_SUMMARY_SECTION: 'products-summary-section',
  USER_GROUPS_ALL: 'user-groups-all',
  USERS_SUMMARY_SECTION: 'users-summary-section',
};

// eslint-disable-next-line import/prefer-default-export -- constants
export {OBSERVABILITY_SPAN_LIST};
