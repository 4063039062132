/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase.add-products-modal:appAddProductsReviewOrder
   *
   * @description Review Order step component used by redesign add products flow
   *
   * @param {Cart} cart The cart of items to use for the "purchase".
   * @param {Contract} contract. The contract that the items will be added to.
   * @param {CUSTOMER_SEGMENT} customerSegment. 'TEAM', or 'ENTERPRISE'.
   * @param {String} poNumber The Purchase Order string which the admin can optionally filled in.
   * @param {Boolean} [showFuturePricing] Default is false. If true, the pricing at renewal will be used.
   * @param {Boolean} showPriceIncludesTax True to show the total price including tax.
   * @param {Boolean} [waitForTotal]  Default is undefined/false. If true, the wait string, which is 2 en-dashes, will be
   *   shown in place of the quantity and price.
   *
   * @example
   *   <app-add-products-review-order
   *      cart="$ctrl.cart"
   *      contract="$ctrl.contract"
   *      po-number="$ctrl.poNumber"
   *      show-future-pricing="$ctrl.showFuturePricing"
   *      show-price-includes-tax="$ctrl.showPriceIncludesTax"
   *      wait-for-total="$ctrl.waitForTotal">
   *   </app-add-products-review-order>
   */
  const componentName = 'appAddProductsReviewOrder';
  angular.module('app.widgets.products.purchase.add-products-modal').component(componentName, {
    bindings: {
      cart: '<',
      contract: '<',
      customerSegment: '<',
      poNumber: '=',
      showFuturePricing: '<?',
      showPriceIncludesTax: '<',
      waitForTotal: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/add-products-modal2/add-products-review-order/add-products-review-order.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $element,
    $timeout,
    $translate,
    binkyContractPriceWithTaxFilter,
    binkyContractPerTermPriceWithTaxFilter,
    binkyDateTimeFilter,
    contractAccess,
    contractUtils,
    feature,
    numberFilter,
    OrganizationList,
    price,
    PURCHASE_ORDER_MAX_LENGTH,
    PURCHASE_WAITSTR,
    t2eMigrationManager,
    translationUtils
  ) {
    const vm = this;
    const translateNameSpace = 'widgets.products.purchase.addProductsModal.reviewOrderSections';
    let anniversaryDate, nextBillingDate, offsetFromAnniversaryDate;

    _.assign(vm, {
      $onChanges,
      $postLink,
      purchaseOrderSection: {},
      sections: [],
    });

    function $onChanges(changesObj) {
      if (_.invoke(changesObj, 'waitForTotal.isFirstChange')) {
        const cartSummary = vm.cart.getTotalSummary();
        const nextInvoiceDate = _.get(cartSummary, 'nextInvoiceDate');
        nextBillingDate = binkyDateTimeFilter(nextInvoiceDate || vm.contract.getNextBillingDate(), {
          dateOnly: true,
        });

        anniversaryDate = binkyDateTimeFilter(vm.contract.getAnniversaryDate(), {
          dateOnly: true,
        });

        offsetFromAnniversaryDate = numberFilter(vm.contract.getOffsetFromAnniversaryDate());
      }

      displayByContract();
    }

    function $postLink() {
      // add a small delay to allow angular to setup the element in the DOM before
      // calling find on the element
      $timeout(focusPOInput);
    }

    ////////

    function displayByContract() {
      vm.sections = [];
      if (vm.contract.isIndirectContract()) {
        vm.sectionTitle = $translate.instant(
          `${translateNameSpace}.indirect.${vm.customerSegment}.title`
        );
        setUpIndirectReviewOrderSections();
      } else {
        vm.sectionTitle = $translate.instant(`${translateNameSpace}.direct.title`);
        vm.showPaymentMethodSection =
          contractAccess.canEditPayment(vm.contract) && !vm.contract.isPaymentCategoryPO();
        setUpDirectReviewOrderSections();
      }
    }

    function focusPOInput() {
      const poNumberElement = angular.element($element[0].querySelector('.po-input'));
      if (poNumberElement.length > 0) {
        poNumberElement.focus();
      }
    }

    // If price isn't defined, return placeholder so there is something to display.
    // for use with binky tax filters
    function getDisplayPriceString(formattedPrice, contract, includesTax, filterFunction) {
      if (vm.waitForTotal || _.isUndefined(formattedPrice)) {
        return PURCHASE_WAITSTR;
      }

      return _.isFunction(filterFunction)
        ? filterFunction(contract, includesTax, formattedPrice)
        : formattedPrice;
    }

    // If price isn't defined, return placeholder so there is something to display.
    function getDisplayPriceStringWithTaxInfo(contract, includesTax, formattedPrice) {
      return getDisplayPriceString(
        formattedPrice,
        contract,
        includesTax,
        binkyContractPriceWithTaxFilter
      );
    }

    // There are two scenarios where we show the enterPONumber section:
    // 1. Direct contracts with purchase order payment type
    // 2. All indirect contracts
    function getEnterPONumberSection({isDirect, translationPrefix}) {
      const poNumberMaxLength = isDirect
        ? PURCHASE_ORDER_MAX_LENGTH.DIRECT
        : PURCHASE_ORDER_MAX_LENGTH.INDIRECT;

      return {
        poNumberMaxLength,
        prefix: `${translationPrefix}.poNumber`,
      };
    }

    /**
     * @description Gets the renewal sub total, including or excluding taxes and formats it according
     *   to the currency formatString. Uses cart.responseBillingSummaryData.renewal_sub_total.
     * @param {Boolean} includesTax - true if we should use the amount_with_tax, false for amount_without_tax
     * @returns {String} the formatted price for the sub total
     */
    function getRenewalSubTotalPrice(includesTax) {
      if (_.has(vm.cart, 'responseBillingSummaryData.renewal_sub_total')) {
        return price.format(
          includesTax
            ? _.get(vm.cart, 'responseBillingSummaryData.renewal_sub_total.amount_with_tax')
            : _.get(vm.cart, 'responseBillingSummaryData.renewal_sub_total.amount_without_tax'),
          vm.cart.getCurrency()
        );
      }
      return undefined;
    }

    // for use with binky tax filters
    function setupSectionHeaderWithTax(
      translationPath,
      formattedPrice,
      filterFunction,
      translationOptions = {}
    ) {
      const pricePlaceholder = _.uniqueId('PRICE_PLACEHOLDER');
      const taxString = filterFunction(vm.contract, vm.showPriceIncludesTax, pricePlaceholder);

      const newTotalString = _.replace(
        taxString,
        pricePlaceholder,
        `<span class="heading-details">${formattedPrice}</span>`
      );

      const translation = translationUtils.sanitizeSafeInstant(
        translationPath,
        _.assign({newTotalString}, translationOptions)
      );

      // when passing in text into $translate.instant, it turns some characters to html entities, this is to conver it back
      // so it doesn't reneder the characters and creates the tags properly
      return _.unescape(translation);
    }

    function setupAnniversaryDateSection({orgName, translationPrefix}) {
      vm.sections.push({
        body: $translate.instant(
          `${translationPrefix}.anniversaryDate.body`,
          {
            anniversaryDate,
            offsetFromAnniversaryDate,
            orgName,
          },
          'messageformat'
        ),
        icon: 'Calendar',
        title: $translate.instant(`${translationPrefix}.anniversaryDate.title`),
      });
    }

    function setUpDirectDigitalRiverReviewOrderSections({
      licenseCount,
      newTotalTaxString,
      subTotalTaxString,
    }) {
      const translationPrefix = `${translateNameSpace}.direct.digitalRiver`;

      // purchasingFromDigitalRiver section
      vm.sections.push({
        body: $translate.instant(`${translationPrefix}.purchasingFromDigitalRiver.body`),
        icon: 'CheckmarkCircle',
        title: $translate.instant(`${translationPrefix}.purchasingFromDigitalRiver.title`),
      });

      // paymentDue section
      vm.sections.push({
        body: $translate.instant(
          vm.contract.isM2M()
            ? `${translationPrefix}.paymentDue.body.apm`
            : `${translationPrefix}.paymentDue.body.puf`,
          {
            licenseCount,
            totalAmountTaxString: vm.contract.isM2M() ? newTotalTaxString : subTotalTaxString,
          },
          'messageformat'
        ),
        icon: 'CreditCard',
        title: $translate.instant(`${translationPrefix}.paymentDue.title`),
      });

      vm.trailingParagraph = $translate.instant(`${translationPrefix}.trailingParagraph`);
    }

    function setUpLocalCurrencySection() {
      const localCurrencyNamespace = `${translateNameSpace}.direct.localCurrencyChange`;
      const date = vm.contract.isM2M() ? nextBillingDate : anniversaryDate;

      const currencyCode = contractUtils.getFutureCurrency(vm.contract);
      const currencyImageUrl = _.get(
        vm.contract,
        'billingInfo.nextBillingAmount.currency.currencyImageUrl'
      );

      const messageKey = `${localCurrencyNamespace}.body.${currencyCode}`;
      const message = translationUtils.sanitizeSafeInstant(messageKey, {date});

      if (message !== messageKey) {
        vm.sections.push({
          body: message,
          class: 'local-currency',
          icon: currencyImageUrl,
          title: $translate.instant(`${localCurrencyNamespace}.title`),
        });
      }
    }

    function setUpDirectOfflinePaymentReviewOrderSections({licenseCount, subTotalTaxString}) {
      // For Japanese offline payment teams purchased with either bank transfer (BT) or convenience store (CVS)
      // Offline payment teams is always PUF
      const translationPrefix = `${translateNameSpace}.direct.offline`;

      // local currency section
      if (contractAccess.canViewLocalCurrencyChangeInfo(vm.contract)) {
        setUpLocalCurrencySection();
      }

      // paymentDue section
      vm.sections.push({
        body: $translate.instant(
          `${translationPrefix}.paymentDue.body`,
          {
            licenseCount,
            subTotalTaxString,
          },
          'messageformat'
        ),
        icon: 'CreditCard',
        title: $translate.instant(`${translationPrefix}.paymentDue.title`),
      });

      // email section
      vm.sections.push({
        body: $translate.instant(
          t2eMigrationManager.isT2EClean()
            ? `${translationPrefix}.email.body.t2eClean`
            : `${translationPrefix}.email.body.nonT2eClean`
        ),
        icon: 'EmailCheck',
        title: $translate.instant(`${translationPrefix}.email.title`),
      });

      vm.trailingParagraph = $translate.instant(`${translationPrefix}.trailingParagraph`);
    }

    function setUpDirectOnlinePaymentReviewOrderSections({
      licenseCount,
      newTotal,
      subTotal,
      subTotalTaxString,
    }) {
      const translationPrefix = `${translateNameSpace}.direct.online`;
      const orgName = _.get(OrganizationList.get().activeOrg, 'name');

      // local currency section
      if (contractAccess.canViewLocalCurrencyChangeInfo(vm.contract)) {
        setUpLocalCurrencySection();
      }

      if (vm.contract.isM2M()) {
        setupABMSections();
      } else {
        setupPUFSections();
      }

      //////////

      function setupABMSections() {
        vm.prorationSummary = vm.cart.getProrationSummary();

        const newTotalString = getDisplayPriceString(newTotal);

        if (!_.isEmpty(vm.prorationSummary)) {
          const proratedPriceTotal = _.get(vm.prorationSummary, 'total');
          const daysLeft = numberFilter(_.get(vm.prorationSummary, 'days'));

          const formattedProrationTotal =
            proratedPriceTotal && price.format(proratedPriceTotal, vm.prorationSummary.currency);

          const proratedPrice = getDisplayPriceString(formattedProrationTotal);

          // due now section
          vm.sections.push({
            body: $translate.instant(
              `${translationPrefix}.dueNow.messageFormat.body`,
              {daysLeft, licenseCount},
              'messageformat'
            ),
            icon: 'CheckmarkCircle',
            showProratedCalculation: true,
            title: setupSectionHeaderWithTax(
              `${translationPrefix}.dueNow.title`,
              proratedPrice,
              binkyContractPriceWithTaxFilter
            ),
          });
        }

        // updated monthly payment section
        vm.sections.push({
          body: $translate.instant(
            `${translationPrefix}.updatedMonthlyPayment2.messageFormat.body`,
            {
              licenseCount,
              newTotalString,
              orgName,
              purchaseTotal: getDisplayPriceString(subTotal),
            },
            'messageformat'
          ),
          icon: 'Calendar',
          title: setupSectionHeaderWithTax(
            `${translationPrefix}.updatedMonthlyPayment2.title`,
            newTotalString,
            binkyContractPerTermPriceWithTaxFilter,
            {nextBillingDate}
          ),
        });
      }

      function setupPUFSections() {
        // paymentDue section
        vm.sections.push({
          body: $translate.instant(
            `${translationPrefix}.paymentDue.body.puf`,
            {
              licenseCount,
              subTotalTaxString,
            },
            'messageformat'
          ),
          icon: 'Clock',
          title: $translate.instant(`${translationPrefix}.paymentDue.title`),
        });

        setupAnniversaryDateSection({orgName, translationPrefix});
      }
    }

    function setUpDirectPurchaseOrderReviewOrderSections({licenseCount}) {
      const translationPrefix = `${translateNameSpace}.direct.purchaseOrder`;
      const orgName = _.get(OrganizationList.get().activeOrg, 'name');

      vm.sections.push({
        body: $translate.instant(
          `${translationPrefix}.paymentDue.body`,
          {
            licenseCount,
          },
          'messageformat'
        ),
        icon: 'CreditCard',
        title: $translate.instant(`${translationPrefix}.paymentDue.title`),
      });

      setupAnniversaryDateSection({orgName, translationPrefix});

      // enterPONumber section
      vm.purchaseOrderSection = getEnterPONumberSection({
        isDirect: true,
        translationPrefix,
      });
    }

    function setUpDirectReviewOrderSections() {
      // NOTE: Do not use `vm.cart.getBillableItems() because that will give you a count of owned + in the cart
      // below path gives you just what's in the cart
      const itemsInCart = _.get(vm.cart, 'billingSummary.billable_items');
      const licenseCount = vm.waitForTotal
        ? PURCHASE_WAITSTR
        : numberFilter(_.sumBy(itemsInCart, 'quantity'));

      const newTotal = _.invoke(
        vm.cart,
        'getNewTotalPrice',
        vm.showPriceIncludesTax,
        vm.showFuturePricing
      );

      const newTotalTaxString = getDisplayPriceStringWithTaxInfo(
        vm.contract,
        vm.showPriceIncludesTax,
        newTotal
      );

      const subTotal = vm.showFuturePricing
        ? getRenewalSubTotalPrice(vm.showPriceIncludesTax)
        : _.invoke(vm.cart, 'getSubTotalPrice', {
            includesTax: vm.showPriceIncludesTax,
            useProratedPricing: vm.contract.isPUF(),
          });

      const subTotalTaxString = getDisplayPriceStringWithTaxInfo(
        vm.contract,
        vm.showPriceIncludesTax,
        subTotal
      );

      if (vm.contract.isDrContract) {
        setUpDirectDigitalRiverReviewOrderSections({
          licenseCount,
          newTotalTaxString,
          subTotalTaxString,
        });
      } else if (vm.contract.isPaymentCategoryOffline()) {
        setUpDirectOfflinePaymentReviewOrderSections({
          licenseCount,
          subTotalTaxString,
        });
      } else if (vm.contract.isPaymentCategoryPO()) {
        setUpDirectPurchaseOrderReviewOrderSections({licenseCount});
      } else {
        // Credit Card, Paypal, Direct Debit
        setUpDirectOnlinePaymentReviewOrderSections({
          licenseCount,
          newTotal,
          subTotal,
          subTotalTaxString,
        });
      }
    }

    function setUpIndirectReviewOrderSections() {
      const translationPrefix = `${translateNameSpace}.indirect`;

      // completeYourPurchase section
      vm.sections.push({
        body: $translate.instant(`${translationPrefix}.completeYourPurchase.body`),
        icon: 'User',
        title: $translate.instant(`${translationPrefix}.completeYourPurchase.title`),
      });

      // enterPONumber section
      vm.purchaseOrderSection = getEnterPONumberSection({
        isDirect: false,
        translationPrefix,
      });
    }
  }
})();
/* eslint-enable max-lines */
