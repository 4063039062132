(function () {
  /**
   * @deprecated ported to src2/app/features/overview/hva-cards/value-discovery-tasks/ChangeContractOwnerHva
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    _,
    contractAccess,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    organizationAccess,
    SVG_CLASS,
    OrganizationAdminList,
    OrganizationManager,
    globalModalsManager,
    GLOBAL_MODAL_BINDING_TYPE,
    ROLE
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.CHANGE_CONTRACT_OWNERS,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForTeam) {
          cache.canViewValueDiscoveryTasksForTeam =
            organizationAccess.canViewValueDiscoveryTasksForTeam();
        }

        const {canViewValueDiscoveryTasksForTeam} = cache;

        let adminList, contractToChangeOwner;

        return canViewValueDiscoveryTasksForTeam
          .then((canView) => {
            if (canView) {
              adminList = OrganizationAdminList.get({
                orgId: OrganizationManager.getActiveOrgId(),
                pageSize: 10,
                role: ROLE.ADMIN.ORG,
              });
              return adminList.$promise;
            }

            return $q.reject();
          })
          .then(() => {
            contractToChangeOwner = findContractToChangeOwner(
              OrganizationManager.getContractsForActiveOrg()
            );
            return contractAccess.canChangeContractOwner(contractToChangeOwner, adminList);
          })
          .then((canChangePrimaryAdmin) => {
            if (canChangePrimaryAdmin) {
              const namespace =
                'overview.highValueActionArea.valueDiscoveryTasks.changeContractOwner';
              return [
                new HvaCard({
                  ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                  description: $translate.instant(`${namespace}.description`),
                  dismissOnCTAClick: true,
                  header: $translate.instant(`${namespace}.header`),
                  id: 'hva-change-contract-owners',
                  isDismissible: true,
                  onCTAClick: () => {
                    globalModalsManager.open({
                      componentBindings: [
                        {
                          attributeName: 'adminList',
                          type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                          value: adminList,
                        },
                        {
                          attributeName: 'contract',
                          type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                          value: contractToChangeOwner,
                        },
                        {
                          attributeName: 'onClosed',
                          type: GLOBAL_MODAL_BINDING_TYPE.FUNCTION,
                          value: () => {
                            $state.go('account.account');
                          },
                        },
                      ],
                      componentName: 'appChangeContractOwnerModal',
                    });
                  },
                  sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                  svgClass: SVG_CLASS.CONTRACT_OWNERS,
                }),
              ];
            }
            return $q.reject();
          });
      }

      function findContractToChangeOwner(contractList) {
        return contractList.getIndirectContract() || contractList.getDirectContract();
      }
    }
  }
})();
