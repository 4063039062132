(function () {
  /**
   * @deprecated
   *
   * @ngdoc factory
   * @name app.core.directories:Directory
   * @description Service that stores and retrieves state required to resume Directory sync onboarding after redirecting from IDP portal back to Onesie.
   */
  angular.module('app.core.directories').factory('idpStateManager', idpStateManager);

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function idpStateManager(
    $location,
    $state,
    $window,
    _,
    apiUtils,
    feature,
    IDP_TYPES,
    locationFromPageLoad,
    storageUtils
  ) {
    // Session storage key name that holds user input directory name for recovery after redirect
    // back from an IDP (e.g. Azure).
    const DIRECTORY_NAME_KEY = 'onesieIdpDirectoryName';

    // Session storage key name that holds CSRF token used for validating an IDP redirect (e.g. Azure).
    const CSRF_TOKEN_KEY = 'onesieIdpCsrfToken';

    // Session storage key name that holds the type of the IDP that the onboarding is done for.
    const IDP_TYPE_KEY = 'onesieIdpType';

    // Session storage key name that holds the IDP token after a login is successful
    const IDP_TOKEN_KEY = 'onesieIdpToken';

    // Session storage key name that holds the IDP directory id where the idp login has been initiated
    const DIRECTORY_ID_KEY = 'onesieIdpDirectoryId';

    // Session storage key name that holds the return url (url when startAuthentication is called)
    const RETURN_URL_KEY = 'onesieIdpReturnUrl';

    // Session storage key name that holds the state data
    const STATE_DATA_KEY = 'onesieIdpStateData';

    // Url of the application
    const CONSOLE_URL = `${$window.location.protocol}//${$window.location.hostname}${
      $window.location.port ? `:${$window.location.port}` : ''
    }`;

    return {
      buildReturnUrl,
      clear,
      completeAuthentication,
      getAccessToken: feature.isEnabled('temp_idp_token') ? undefined : getAccessToken,
      getAccessTokenFromHash,
      getAccessTokenFromStorage,
      getDirectoryId,
      getDirectoryName,
      getReturnUrl,
      isLoginInProgress,
      isPendingAzureOnboarding,
      isPendingGoogleOnboarding,
      isPendingLogin,
      persistData,
      persistLoginData,
      startAuthentication,
      storeAccessToken,
    };

    // Clear sessionStorage data and URL hash.
    // @deprecated
    // This will be replaced by startAuthentication/completeAuthentication in the near future.
    // Please limit the use to existing features only
    function clear() {
      storageUtils.removeSessionStorageItem(CSRF_TOKEN_KEY);
      storageUtils.removeSessionStorageItem(DIRECTORY_NAME_KEY);
      storageUtils.removeSessionStorageItem(IDP_TYPE_KEY);
      storageUtils.removeSessionStorageItem(DIRECTORY_ID_KEY);
      clearUrlHash();
    }

    // Gets the IDP access token (either from sessionStorage or the url hash)
    function getAccessToken() {
      // Try to first find a token on the hash object if available
      // as that is considered the freshest one, instead of the session storage
      // one which might be expired
      const hashObj = getUrlHashAsObj();
      if (hashObj && hashObj.access_token) {
        persistToken(hashObj.access_token);
        return hashObj.access_token;
      }

      const token = storageUtils.getSessionStorageItem(IDP_TOKEN_KEY);
      if (token) {
        return token;
      }

      return null;
    }

    // Gets the IDP access token from the url hash
    // This should only be used when user comes back from an IDP login
    function getAccessTokenFromHash() {
      const hashObj = getUrlHashAsObj();
      return hashObj && hashObj.access_token;
    }

    // Gets a stored access token based on a directory id
    function getAccessTokenFromStorage(directoryId) {
      const storageKey = getTokenKey(directoryId);
      return storageUtils.getSessionStorageItem(storageKey);
    }

    function storeAccessToken(token, directoryId) {
      const storageKey = getTokenKey(directoryId);
      storageUtils.setSessionStorageItem(storageKey, token);
    }

    // Returns directory name from sessionStorage.
    //
    // @deprecated
    // This will be replaced by startAuthentication/completeAuthentication in the near future.
    // Please limit the use to existing features only
    function getDirectoryName() {
      return storageUtils.getSessionStorageItem(DIRECTORY_NAME_KEY);
    }

    // Returns directory id from sessionStorage
    //
    // @deprecated
    // This will be replaced by startAuthentication/completeAuthentication in the near future.
    // Please limit the use to existing features only
    function getDirectoryId() {
      return storageUtils.getSessionStorageItem(DIRECTORY_ID_KEY);
    }

    function isPendingAzureOnboarding() {
      return (
        isPendingLogin() && storageUtils.getSessionStorageItem(IDP_TYPE_KEY) === IDP_TYPES.AZURE
      );
    }

    function isPendingGoogleOnboarding() {
      return (
        isPendingLogin() && storageUtils.getSessionStorageItem(IDP_TYPE_KEY) === IDP_TYPES.SAML
      );
    }

    // Checks if we're in the middle of an IDP login flow.
    function isPendingLogin() {
      const hashObj = getUrlHashAsObj();

      // Check if there is a state parameter and if there is, if it's the same as the one in session storage.
      // This would indicate we're in the middle of an IDP login flow.
      return !!(
        hashObj.state &&
        hashObj.state === storageUtils.getSessionStorageItem(CSRF_TOKEN_KEY) &&
        hashObj.access_token
      );
    }

    // Store CSRF token, directory name and idp type for validation and data recovery after an IDP redirects back.
    //
    // @deprecated
    // This will be replaced by startAuthentication/completeAuthentication in the near future.
    // Please limit the use to existing features only
    function persistData(csrfToken, directoryName, idpType) {
      storageUtils.setSessionStorageItem(CSRF_TOKEN_KEY, csrfToken);
      storageUtils.setSessionStorageItem(DIRECTORY_NAME_KEY, directoryName);
      storageUtils.setSessionStorageItem(IDP_TYPE_KEY, idpType);
    }

    // Stores CSRF token and directory id for validation and recovery after an IDP redirects back.
    //
    // @deprecated
    // This will be replaced by startAuthentication/completeAuthentication in the near future.
    // Please limit the use to existing features only
    function persistLoginData(csrfToken, directoryId) {
      storageUtils.setSessionStorageItem(CSRF_TOKEN_KEY, csrfToken);
      storageUtils.setSessionStorageItem(DIRECTORY_ID_KEY, directoryId);
    }

    // Checks if a login is in progress, does the same thing as
    // isPendingLogin but does not check for a token
    function isLoginInProgress() {
      const hashObj = getUrlHashAsObj();
      const queryParams = $location.search();

      return !!(
        (hashObj.state && hashObj.state === storageUtils.getSessionStorageItem(CSRF_TOKEN_KEY)) ||
        (queryParams.state &&
          queryParams.state === storageUtils.getSessionStorageItem(CSRF_TOKEN_KEY))
      );
    }

    /**
     * Starts an authentication flow for an Idp using the specified url
     * and options. Automatically redirects to that Idp with the correct return_uri
     * and will return back to where the authentication started.
     *
     * @param {String} url Url of the Idp where we want to perform a login
     * @param {Object} options Set of options for this authentication
     * @param {Object} [options.queryParams] Set of extra query params that will be attached
     *                                       to the url. Default query params are `redirect_uri` and `state`
     * @param {Object} [options.data]        Data to be stored in session that can be used after returning from an Idp
     * @param {string} idpType Type of the Idp we are performing authentication for
     */
    function startAuthentication(url, options = {}, idpType) {
      const returnUrl = options.returnUrl || buildReturnUrl();

      storageUtils.setSessionStorageItem(RETURN_URL_KEY, returnUrl);

      const csrfToken = generateNewCsrfToken();
      storageUtils.setSessionStorageItem(CSRF_TOKEN_KEY, csrfToken);

      if (options.data) {
        // set the state data as is, it can be whatever the user wants/needs
        // setStateData takes care of writing it in a readable format for later
        setStateData(options.data);
      }

      // keep idp type to know which idp is authenticated
      // type will be undefined if it doesn't matter
      storageUtils.setSessionStorageItem(IDP_TYPE_KEY, idpType);

      const queryParams = buildAuthenticationQueryString(
        _.assign(
          {
            redirect_uri: CONSOLE_URL,
            state: csrfToken,
          },
          options.queryParams
        )
      );

      const authUrl = buildAuthUrl(url, queryParams);
      $window.location.href = authUrl;
    }

    /**
     * Completes an Idp authentication initiated with `startAuthentication`.
     * This method needs to be called or the data stored in the session storage
     * as well as hash parameters will remain intact and eventually be lost.
     *
     * If the token needs to be persisted `storeAccessToken` can be used otherwise
     * the token will be returned when this method is called and cleared from the hash
     * parameters.
     *
     * @returns {Object} An object containing the token, tokenData and the state data set
     *                   when the authentication is initiated.
     */
    function completeAuthentication() {
      const token = getAccessTokenFromHash();
      const authData = {
        data: getStateData(),
        hashData: getUrlHashAsObj(),
        queryData: $location.search(),
        token,
        tokenData: getAccessTokenData(token),
      };

      storageUtils.removeSessionStorageItem(CSRF_TOKEN_KEY);
      storageUtils.removeSessionStorageItem(IDP_TYPE_KEY);
      storageUtils.removeSessionStorageItem(STATE_DATA_KEY);
      storageUtils.removeSessionStorageItem(RETURN_URL_KEY);
      clearUrlHash();

      return authData;
    }

    function getReturnUrl() {
      return storageUtils.getSessionStorageItem(RETURN_URL_KEY);
    }

    ////////////

    // Returns an object containing information passed from and IDP via URL hash.
    function getUrlHashAsObj() {
      return _(locationFromPageLoad.hash())
        .split('&')
        .reduce((accumulator, pair) => {
          const [key, value] = _.split(pair, '=');
          accumulator[key] = value;
          return accumulator;
        }, {});
    }

    // Gets a compound storage key for a token based on the directory id
    function getTokenKey(directoryId) {
      return `${IDP_TOKEN_KEY}/${directoryId}`;
    }

    // Persists an IDP token in session storage
    // To be removed when temp_idp_token is removed
    function persistToken(token) {
      storageUtils.setSessionStorageItem(IDP_TOKEN_KEY, token);
    }

    // Clears URL hash
    function clearUrlHash() {
      $location.hash('');
      locationFromPageLoad.clear();
    }

    // Builds a query param string (key=value&etc) from an object
    function buildAuthenticationQueryString(queryParamsObj) {
      return _(queryParamsObj)
        .map((value, key) => `${key}=${$window.encodeURIComponent(value)}`)
        .join('&');
    }

    // Glues the url and query parameters together
    function buildAuthUrl(url, queryParams) {
      const glue = url.indexOf('?') > 0 ? '&' : '?';
      return `${url}${glue}${queryParams}`;
    }

    // builds the return url by using the current state
    function buildReturnUrl() {
      const currentStateName = $state.current.name;
      // We're using query params to trigger directory setup (auth + domain)
      // so we clear the url on login to make sure we don't keep triggering it
      return _.replace($state.href(currentStateName), /[#?].+/, '');
    }

    function getRandomNumber() {
      // Generate a pseudo-random number of 10 digits.
      return _.random(1000000000, 9999999999);
    }

    // Generates a new CSRF token with a random number
    // and the org id to make sure we don't start the flow
    // in an org and end it in another org
    function generateNewCsrfToken() {
      return getRandomNumber() + apiUtils.getActiveOrg();
    }

    // Sets the login state data in the storage
    // angular.toJson takes care of stringifying the data
    function setStateData(data) {
      storageUtils.setSessionStorageItem(STATE_DATA_KEY, angular.toJson(data));
    }

    // Gets the login state data after authenticating
    // angular.fromJson takes care of parsing the data
    function getStateData() {
      try {
        return angular.fromJson(storageUtils.getSessionStorageItem(STATE_DATA_KEY));
      } catch (error) {
        return null;
      }
    }

    // Reads a JWT token by decoding the payload and
    // and parsing it using angular.fromJson
    //
    // If the token is not understood an empty object is returned
    function getAccessTokenData(token) {
      // store the index of the payload
      const payload = 1;
      const tokenParts = _.split(token, '.');

      if (tokenParts[payload]) {
        try {
          const payloadJson = $window.atob(tokenParts[payload]);
          return angular.fromJson(payloadJson);
        } catch (error) {
          return {};
        }
      }

      return {};
    }
  }
})();
