import {Flex, Heading, Text, TextField, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './SamlCustomsAttributes.pcss';

const SamlCustomAttributes = ({onChange, reauthAuthnContextClasses, mfaAuthnContextClasses}) => {
  const intl = useIntl();

  const sanitizeValue = useCallback((value) => {
    if (!value) return [];

    return value
      .split(',')
      .filter((item) => item.length > 0)
      .map((item) => item.trim());
  }, []);

  const onAuthnContextForReauthenticationChange = (value) => {
    const newFields = {
      mfaAuthnContextClasses,
      reauthAuthnContextClasses: sanitizeValue(value),
    };

    onChange(newFields);
  };

  const onAuthnContextForMfaChange = (value) => {
    const newFields = {
      mfaAuthnContextClasses: sanitizeValue(value),
      reauthAuthnContextClasses,
    };

    onChange(newFields);
  };

  return (
    <>
      <Heading
        level={2}
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to style this smaller
        UNSAFE_className="heading"
      >
        <FormattedMessage id="settings.directories.samlCustomAttributes.title" />
      </Heading>

      <View backgroundColor="static-white" marginBottom="size-600" padding="size-300">
        <Flex direction="column">
          <Text marginBottom="size-150">
            <FormattedMessage
              id="settings.directories.samlCustomAttributes.description"
              values={{goUrl: (str) => <GoUrl name="term_and_conditions">{str}</GoUrl>}}
            />
          </Text>
          <TextField
            data-testid="reauth-context-classes"
            defaultValue={reauthAuthnContextClasses ? reauthAuthnContextClasses.join(',') : ''}
            description={intl.formatMessage({
              id: 'settings.directories.samlCustomAttributes.reauthAuthnContextClassesField.description',
            })}
            label={intl.formatMessage({
              id: 'settings.directories.samlCustomAttributes.reauthAuthnContextClassesField.label',
            })}
            marginBottom="size-100"
            onChange={onAuthnContextForReauthenticationChange}
            width="50%"
          />
          <TextField
            data-testid="mfa-context-classes"
            defaultValue={mfaAuthnContextClasses ? mfaAuthnContextClasses.join(',') : ''}
            description={intl.formatMessage({
              id: 'settings.directories.samlCustomAttributes.mfaAuthnContextClasses.description',
            })}
            label={intl.formatMessage({
              id: 'settings.directories.samlCustomAttributes.mfaAuthnContextClasses.label',
            })}
            onChange={onAuthnContextForMfaChange}
            width="50%"
          />
        </Flex>
      </View>
    </>
  );
};

SamlCustomAttributes.propTypes = {
  mfaAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  reauthAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
};

export default SamlCustomAttributes;
