(function () {
  /**
   * @deprecated this file has been ported to src2
   *
   * @ngdoc service
   * @name app.core.user-group:userGroupAccess
   * @description user-group access factory
   */
  angular.module('app.core.user-group.access').factory('userGroupAccess', userGroupAccess);

  /* @ngInject */
  function userGroupAccess(auth, binkySrc2, feature, OrganizationManager, ROLE) {
    const service = {
      allowManageUserGroups,
      canAssignAdmin: allowIfNotMigrating,
      canAssignProductProfile,
      canAssignUser,
      canCreateUserGroup,
      canEditUserGroup,
      canRemoveAdmin: allowIfNotMigrating,
      canRemoveUser: canAssignUser,
      canRemoveUserGroup,
      canUnassignProductProfile: canAssignProductProfile,
      canViewUserGroups,
    };

    return service;

    //////////////

    /**
     * @description Whether Global Admin policy allows the org to add/edit/remove user groups.
     * This method is not a replacement for "can(Action)UserGroup" methods, rather this method
     * is complimentary for those.
     * @returns {Boolean} true if GA policy allows for "manageAdmins" policy, false otherwise.
     */
    function allowManageUserGroups() {
      return (
        feature.isDisabled('temp_global_admin_user_groups') ||
        OrganizationManager.getPolicyValue(
          binkySrc2.api.policy.POLICY_CONSTANTS.GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS
        )
      );
    }

    function canAssignProductProfile() {
      return auth.canUpdateUserGroup() && allowIfNotMigrating();
    }

    function canAssignUser(userGroup) {
      return (
        (auth.isUserOrgAdmin() || auth.isUserUserGroupAdminForUserGroup(userGroup.id)) &&
        !userGroup.isExternallyManaged() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    function canCreateUserGroup() {
      return (
        auth.canUpdateUserGroup() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    /**
     * @returns {Boolean} true - if the user can edit a user group's members.
     * Does not relate to editing the name/description of a user group.
     */
    function canEditUserGroup() {
      return (
        (auth.isUserOrgAdmin() || auth.isUserUserGroupAdmin()) &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    function canRemoveUserGroup() {
      return (
        auth.isUserOrgAdmin() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    function allowIfNotMigrating() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    function canViewUserGroups() {
      return auth.hasAnyRole(
        feature.isEnabled('temp_parkour_mm')
          ? [
              ROLE.ADMIN.LICENSE,
              ROLE.ADMIN.ORG,
              ROLE.ADMIN.PRODUCT,
              ROLE.ADMIN.PRODUCT_SUPPORT,
              ROLE.ADMIN.SUPPORT,
              ROLE.ADMIN.STORAGE,
              ROLE.ADMIN.USER_GROUP,
            ]
          : [
              ROLE.ADMIN.LICENSE,
              ROLE.ADMIN.ORG,
              ROLE.ADMIN.PRODUCT,
              ROLE.ADMIN.SUPPORT,
              ROLE.ADMIN.STORAGE,
              ROLE.ADMIN.USER_GROUP,
            ]
      );
    }
  }
})();
