import {
  PATH_INSIGHTS_DASHBOARD,
  PATH_INSIGHTS_LOGS,
  PATH_INSIGHTS_LOGS_DETAILS,
  PATH_INSIGHTS_REPORTS,
  PATH_INSIGHTS_REPORTS_DETAILS,
  PATH_INSIGHTS_ROOT,
} from 'features/insights/routing/insightsPaths';

const insightsRouteMigration = [
  {
    stateName: 'insights',
    url: PATH_INSIGHTS_ROOT,
    useReactRoute: true,
  },
  {
    stateName: 'insights.insight-dashboard',
    url: PATH_INSIGHTS_DASHBOARD,
    useReactRoute: true,
  },
  {
    stateName: ['insights.insight-logs', 'insights.insight-logs.all'],
    url: PATH_INSIGHTS_LOGS,
    useReactRoute: true,
  },
  {
    stateName: [
      'insights.insight-logs-details',
      'insights.insight-logs-details.view',
      'insights.insight-logs-details.files',
    ],
    url: PATH_INSIGHTS_LOGS_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: [
      'insights.insight-reports-details',
      'insights.insight-reports-details.view',
      'insights.insight-reports-details.files',
    ],
    url: PATH_INSIGHTS_REPORTS_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: ['insights.insight-reports', 'insights.insight-reports.all'],
    url: PATH_INSIGHTS_REPORTS,
    useReactRoute: true,
  },
];

export default insightsRouteMigration;
