/**
 * @deprecated use src2 Support pages
 *
 * @description a filter to get the case type ("Admin" or "Product") for a support ticket
 * @param {Object} supportTicket the support ticket
 * @returns {String} the localized case type string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportTicketCaseType', appSupportTicketCaseType);

  /* @ngInject */
  function appSupportTicketCaseType($translate, _, SUPPORT_TICKET_CASE_TYPE) {
    const stringKeyMap = {
      [SUPPORT_TICKET_CASE_TYPE.ADMIN]: 'administration',
      [SUPPORT_TICKET_CASE_TYPE.PRODUCT]: 'product',
    };
    return getCaseType;

    function getCaseType(supportTicket) {
      const key = stringKeyMap[_.toUpper(supportTicket.caseType)];
      return key ? $translate.instant(`widgets.support.supportTicketCaseTypeFilter.${key}`) : '';
    }
  }
})();
