import {Content, ContextualHelp, Flex, Heading, Switch, Text} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Switch that can disabled and display a ContextualHelp depending on props
 */
const ContextualHelpSwitch = (props) => {
  const intl = useIntl();
  const headingId = useId();

  const {helpTitleLabel, helpDescriptionLabel, switchLabelId, ...restOfProps} = props;

  const handleOnChange = restOfProps?.onChange;

  return (
    <Flex alignItems="center">
      <Switch
        aria-describedby={headingId}
        isDisabled={restOfProps?.isDisabled}
        isEmphasized
        isSelected={restOfProps?.isSelected}
        marginEnd="-2px"
        onChange={handleOnChange}
        {...restOfProps}
      >
        {intl.formatMessage({
          id: switchLabelId,
        })}
      </Switch>
      {restOfProps.isDisabled && (
        <ContextualHelp placement="right">
          <Heading>{helpTitleLabel()}</Heading>
          <Content>
            <Text>{helpDescriptionLabel()}</Text>
          </Content>
        </ContextualHelp>
      )}
    </Flex>
  );
};

ContextualHelpSwitch.propTypes = {
  /**
   * Data test id for the Switch
   * It is used to find the Switch in Cypress tests
   */
  dataTestId: PropTypes.string,
  /**
   * Title label for the ContextualHelp description
   */
  helpDescriptionLabel: PropTypes.func.isRequired,
  /**
   * Title label for the ContextualHelp title
   */
  helpTitleLabel: PropTypes.func.isRequired,
  props: PropTypes.shape({
    /**
     * Whether the button should be disabled or not
     */
    isDisabled: PropTypes.bool.isRequired,
    /**
     * Whether the Switch is selected or not
     */
    isSelected: PropTypes.bool.isRequired,
    /**
     * Callback function when the switch is pressed
     */
    onChange: PropTypes.func.isRequired,
  }),
  /**
   * Switch label ID
   */
  switchLabelId: PropTypes.string.isRequired,
};

export default ContextualHelpSwitch;
