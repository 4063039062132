class ProductUsage {
  /**
   * @description Constructor for ProductUsage model object.
   *
   * @param {Object} options - key/value options for ProductUsage construction
   * @param {Number} options.total - Number describing the total quantity of the product.
   * @param {String} options.unit - String describing the unit of the product usage (users, credits, licenses, etc).
   * @param {Number} options.used - Number describing the used quantity of the product.
   */
  constructor({total, unit, used}) {
    this.total = total;
    this.unit = unit;
    this.used = used;
  }
}

export default ProductUsage;
