const ADD_PRODUCTS_MINI_APP_NAME = 'AddProductMiniApp';
const QUICK_ASSIGN_MINI_APP_NAME = 'quick-assign-app';
const CONFIRMATION_MINI_APP_NAME = 'confirmation-app';
const FAILED = 'failed';
const SUBMITTED = 'submitted';

export {
  ADD_PRODUCTS_MINI_APP_NAME,
  CONFIRMATION_MINI_APP_NAME,
  FAILED,
  SUBMITTED,
  QUICK_ASSIGN_MINI_APP_NAME,
};
