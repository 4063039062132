import {navBus} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {
  Button,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  Item,
  Link,
  Picker,
  Text,
} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_PACKAGES_SERVERS} from 'features/packages/routing/packagesPaths';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';
import PackagesLanServersService from 'features/packages/services/PackagesLanServersService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

const ChooseServerPage = observer(() => {
  const {createPackageModalStore} = useCreatePackageModalContext();
  const {pkgSessionSettings, setPkgSessionSettingValues} = createPackageModalStore;
  const intl = useIntl();
  const [selectedServer, setSelectedServer] = useState(pkgSessionSettings.frlSelectedServer);
  const [frlServerObj, setFrlServerObj] = useState([]);
  const [frlLanServersList, setFrlLanServersList] = useState([]);
  const resetOffersState = (selected) => {
    selected.licenses.forEach((offer) => {
      offer.selected = false;
      offer.disabled = false;
    });
    return selected;
  };

  /* populate available FRL servers list */
  useEffect(() => {
    const frlLanServers = PackagesLanServersService.getAllActiveLanServers();
    if (frlLanServers?.length > 0 && !selectedServer) {
      frlLanServers[0] = resetOffersState(frlLanServers[0]);
      setSelectedServer(frlLanServers[0]);
      setPkgSessionSettingValues('frlSelectedServer', frlLanServers[0]);
    }
    const frlServerArray = frlLanServers.map((server) => ({
      key: server.serverId,
      label: server.name,
    }));
    setPkgSessionSettingValues(
      'nextButtonEnabled',
      typeof pkgSessionSettings.frlSelectedServer !== 'undefined'
    );
    setFrlLanServersList(frlLanServers);
    setFrlServerObj(frlServerArray);
  }, [pkgSessionSettings.frlSelectedServer, selectedServer, setPkgSessionSettingValues]);

  /**
   * @description Method to update the server selection
   * @param {String} serverId of the server selected
   */
  const onServerChange = (serverId) => {
    let selected = frlLanServersList.find((server) => server.serverId === serverId);
    setSelectedServer(selected);

    setPkgSessionSettingValues('nextButtonEnabled', typeof selected !== 'undefined');
    PackagesEntitlementsService.resetOffers();
    if (selected) {
      selected = resetOffersState(selected);
      setPkgSessionSettingValues('frlSelectedServer', selected);
    }
    setPkgSessionSettingValues('selectedFrlOffers', []);
  };

  const createServer = useCallback(() => {
    navBus.navigate(
      generatePath(PATH_PACKAGES_SERVERS, {orgId: rootStore.organizationStore.activeOrgId}),
      {
        state: {openCreateServerModal: true},
      }
    );
  }, []);

  return (
    <Flex direction="row">
      {frlServerObj.length === 0 ? (
        <Flex
          alignItems="center"
          direction="column"
          marginEnd="auto"
          marginStart="auto"
          marginTop="size-2000"
        >
          <IllustratedMessage>
            <Heading level={3} marginY="size-0">
              {intl.formatMessage({id: 'packages.createPackage.chooseServer.title.noServer'})}
            </Heading>
            <Content>
              {intl.formatMessage({
                id: 'packages.createPackage.chooseServer.createServerLink.description',
              })}
            </Content>
          </IllustratedMessage>
          <GoUrl name="ent_FRL_Config_Ser">
            {intl.formatMessage({
              id: 'packages.createPackage.chooseServer.createServer.description',
            })}
          </GoUrl>
          <Button
            data-testid="create-server-button"
            marginTop="size-125"
            onPress={createServer}
            variant="accent"
          >
            {intl.formatMessage({id: 'packages.createPackage.chooseServer.createServerButton'})}
          </Button>
        </Flex>
      ) : (
        <Flex direction="column" gap="size-100">
          <Heading level={3} marginY="size-0">
            {intl.formatMessage({
              id: 'packages.createPackage.chooseServer.title',
            })}
          </Heading>
          <Text>{intl.formatMessage({id: 'packages.createPackage.chooseServer.description'})}</Text>
          <Picker
            aria-label={intl.formatMessage({
              id: 'packages.createPackage.chooseServer.title',
            })}
            data-testid="frl-lan-server-picker"
            items={frlServerObj}
            onSelectionChange={onServerChange}
            selectedKey={pkgSessionSettings.frlSelectedServer?.serverId}
          >
            {(item) => <Item key={item.key}>{item.label}</Item>}
          </Picker>
          <Link onPress={createServer}>
            {intl.formatMessage({id: 'packages.createPackage.chooseServer.createServerButton'})}
          </Link>
        </Flex>
      )}
    </Flex>
  );
});

ChooseServerPage.propTypes = {};

export default ChooseServerPage;
