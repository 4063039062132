(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:productListToLaunchTranslator
   * @description Translate ProductList into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('productListToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_, feature, jsUtils) {
    return {processDescriptor};

    function processDescriptor(launchContext, productListDescriptor) {
      const {setProperty} = launchContext;

      const productList = _.map(productListDescriptor.items, (productDescriptor) => {
        const product = {};

        jsUtils.translateJson(productDescriptor, product, {
          licenseCount: 'attributes.totalLicense',
          licenseType: 'attributes.licenseType',
          numberSelected: 'attributes.assignedLicense',
          numberUnassigned: 'attributes.unassignedLicenseCount',
          offer_id: 'productInfo.offerId',
          productName: 'productInfo.productName',
        });

        if (feature.isEnabled('temp_self_cancel')) {
          jsUtils.translateJson(productDescriptor, product, {
            cancelQuantity: 'attributes.cancelQuantity',
            id: 'productInfo.subscriptionGUID',
          });

          if (_.isFinite(_.get(productDescriptor, 'cancelQuantity'))) {
            // A boolean primitive with value 'false' will be ignored by Launch Analytics.
            // A coerced string value is used here to ensure it is sent.
            _.set(
              product,
              'attributes.completeLicenseCancel',
              (productDescriptor.cancelQuantity === productDescriptor.licenseCount).toString()
            );
          }
        }

        if (feature.isEnabled('temp_renewal_analytcs_promos')) {
          _.set(product, 'price', _.get(productDescriptor, 'pricing'));
          _.set(
            product,
            'attributes.sourceOfferLocation',
            _.get(productDescriptor, 'productBucket')
          );
        }

        // Need to map 'numberSelected' to multiple attributes, and
        // jsUtils.translateJson doesn't support single source property to
        // multiple targets.
        _.set(product, 'quantity', _.get(productDescriptor, 'numberSelected'));

        return product;
      });
      setProperty('product', productList);
    }
  }
})();
