import {PageContext, navBus} from '@admin-tribe/binky';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import DeviceList from 'features/products/device-licenses/components/device-list/DeviceList';
import useTotalDevices from 'features/products/device-licenses/components/hooks/useTotalDevices';
import DeviceLicensesInfoBar from 'features/products/device-licenses/components/info-bar/DeviceLicensesInfoBar';
import DeviceLicensesPageActions from 'features/products/device-licenses/components/page-actions/DeviceLicensesPageActions';
import DeviceLicensesPageBanner from 'features/products/device-licenses/components/page-banner/DeviceLicensesPageBanner';
import {DeviceLicensesPageContextProvider} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {
  getViewHeaderData,
  isActivationAllowed,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';

const HEADER_NAMESPACE = 'products.deviceLicenses';

/**
 * Page for managing FRL Devices
 */
const FeatureRestrictedPage = ({pageContext}) => {
  const intl = useIntl();
  const [triggerToRefetchFrlDevices, setTriggerToRefetchFrlDevices] = React.useState(false);

  const angularProductId = pageContext?.targetId;
  const reactProductId = useParams()?.productId;
  const productId = angularProductId || reactProductId;

  const product = rootStore.organizationStore.productList.items.find(
    (item) => item.id === productId
  );
  const productDisplayName = getProductDisplayName(intl, product);
  useDocumentTitle({defaultTitle: productDisplayName});

  const isFrlOnline = product.fulfillableItemList.hasPackagePreconditioningForFRLOnline();

  const {activatedDevices, frlOnlineUsedLicensesCount, isError, isLoading, devices} =
    useTotalDevices({
      productId,
      triggerToRefetchFrlDevices,
    });

  if (!product.isFeatureRestrictedLicense()) {
    navBus.navigate('/');
    return null;
  }

  const {showBanner, totalLicensesCount, usedLicensesCount, totalDevicesCount, earliestExpiryDate} =
    getViewHeaderData({
      activatedDevices,
      devices,
      intl,
      isLoading,
      product,
    });
  const isOverDeploymentAllowed = product.fulfillableItemList.hasOverdelegationAllowed();
  return (
    <Page data-testid="device-licenses-page" isBumpered={isError} isLoading={isLoading}>
      <PageBanners>
        <DeviceLicensesPageBanner namespace={HEADER_NAMESPACE} showBanner={showBanner} />
      </PageBanners>

      <PageHeader title={getProductDisplayName(intl, product)}>
        <ImageIcon alt="" size="M" src={product.getIcon?.()} />
      </PageHeader>

      <PageActions>
        <DeviceLicensesPageActions namespace={HEADER_NAMESPACE} product={product} />
      </PageActions>

      <PageInfoBar>
        <DeviceLicensesInfoBar
          earliestExpiryDate={earliestExpiryDate}
          isFrlOnline={isFrlOnline}
          namespace={HEADER_NAMESPACE}
          totalDevicesCount={totalDevicesCount}
          totalLicensesCount={totalLicensesCount}
          usedLicensesCount={usedLicensesCount}
        />
      </PageInfoBar>
      <PageContent>
        {isFrlOnline && (
          <Tabs aria-label={intl.formatMessage({id: `${HEADER_NAMESPACE}.tabs.ariaLabel`})}>
            <TabList>
              <Item key="Devices">
                {intl.formatMessage({id: `${HEADER_NAMESPACE}.devicesPlaceholder`})}
              </Item>
            </TabList>
            <TabPanels>
              <Item key="Devices">
                <DeviceLicensesPageContextProvider
                  value={{
                    canActivateDevices: isActivationAllowed({
                      isOverDeploymentAllowed,
                      totalLicensesCount,
                      usedLicensesCount: frlOnlineUsedLicensesCount,
                    }),
                    isOverDeploymentAllowed,
                    productId,
                    refetchHeaderData: () =>
                      setTriggerToRefetchFrlDevices(!triggerToRefetchFrlDevices),
                    totalDevicesCount,
                    totalLicensesCount,
                    usedLicensesCount,
                  }}
                >
                  <DeviceList
                    isSdl={product.isSharedDeviceLicense()}
                    productId={productId}
                    // This showIPAddress flag is used to show/hide IP address. The ip address is not part of the device data attributes that are being sent back for FRL
                    showIPAddress={false}
                    totalLicensesCount={totalLicensesCount}
                  />
                </DeviceLicensesPageContextProvider>
              </Item>
            </TabPanels>
          </Tabs>
        )}
      </PageContent>
    </Page>
  );
};

FeatureRestrictedPage.propTypes = {
  /**
   * The PageContext object used to determine the product id (targetId)
   */
  pageContext: PropTypes.instanceOf(PageContext),
};

export default FeatureRestrictedPage;
