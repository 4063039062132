import {AuthenticatedUser, authentication} from '@admin-tribe/binky';
import {AuthenticatedUserPullDown} from '@admin-tribe/binky-ui';
import React from 'react';

// User profile pull down component
const UserPullDown = () => {
  const authenticatedUser = AuthenticatedUser.get();

  const onSignOut = () => {
    authentication.signOut();
  };

  return (
    <AuthenticatedUserPullDown
      authenticatedUser={authenticatedUser}
      data-testid="user-pull-down"
      onSignOut={onSignOut}
    />
  );
};

export default UserPullDown;
