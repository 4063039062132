import {TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';

import ProductTable from './ProductTable';

// Product table section to display on the new product overview page
const ProductTableSection = ({ariaLabel, items}) => (
  <TableSection
    getItemKey={(item) => item.uuid}
    items={items}
    // Setting pageNumber to 0 will hide the pagination buttons since API is not paginated
    pageNumber={0}
  >
    <ProductTable ariaLabel={ariaLabel} items={items} />
  </TableSection>
);

ProductTableSection.propTypes = {
  /** Aria label to apply to the table to be determined by the accordion parent. */
  ariaLabel: PropTypes.string,
  /**
   * Array of items that are relevant for this table to display.
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ProductTableSection;
