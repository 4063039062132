/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.form-section:appFormSection
 *
 * @description Used to construct sections for forms, for example the support forms.
 *
 * @binding {String} [headerKey]. The key for the msg string to be translated and used for the section header.
 *   Caller should specify either the sectionHeaderKey or the formSectionHeader transclusion.
 * @binding {String} [showSeparator] Defaults to showing separator before the section content. Set to "false" to hide it.
 */
(function () {
  angular.module('app.widgets.form-section').component('appFormSection', {
    bindings: {
      headerKey: '@?',
      showSeparator: '@?',
    },
    templateUrl: 'app/widgets/common/form-section/form-section.component.html',
    transclude: {
      'form-section-body': 'formSectionBody',
      'form-section-header': '?formSectionHeader',
    },
  });
})();
