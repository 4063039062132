(function () {
  /**
   * @ngdoc module
   * @name app.core.api.jil
   * @description module that defines jil api resource services
   */
  angular.module('app.core.api.jil', [
    'app.core.api',
    'binky.core.api.jil',
    'binky.core.api.utils',
    'ngResource',
  ]);
})();
