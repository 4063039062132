/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.bulk-operations:appResultDetailSection
 *
 * @description The Result detail section in the Operation Result details
 *   drawer.
 *
 * @binding {Job} job. Job reference to display details for
 * @binding {PageContext} pageContext - the pageContext controlling where links to
 *   deficit reports are sent to
 *
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appResultDetailSection', {
    bindings: {
      job: '<',
      pageContext: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/detail-sections/result-detail-section/result-detail-section.component.html',
  });

  /* @ngInject */
  function controller($q, _, JOB_RESULT_ITEM_STATUS, JOB_STATUS) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      vm.isCancelled = vm.job.status === JOB_STATUS.CANCELLED;
    }
  }
})();
