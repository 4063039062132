(function () {
  /**
   * @ngdoc module
   * @name app.widgets.support
   * @description module that defines support widgets
   */
  angular.module('app.widgets.support', [
    'app.core.analytics',
    'app.core.auth',
    'app.core.chat',
    'app.core.email-utils',
    'app.core.organizations.organization-manager',
    'app.core.support',
    'app.widgets.button-with-tooltip',
    'app.widgets.card-button-list',
    'app.widgets.common.drag-and-drop-file',
    'app.widgets.disable-autocomplete',
    'app.widgets.file-attachment',
    'app.widgets.form-section',
    'app.widgets.ranged-slider',
    'app.widgets.search-list',
    'app.widgets.status-tag',
    'app.widgets.timezone-search-list',
    'binky.core.common.list',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.user',
    'binky.core.user.authenticated-user',
    'binky.shell.panels.modal',
    'binky.shell.workspace.page.sub-page',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.comments',
    'binky.widgets.common.date-time',
    'binky.widgets.common.datepicker',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.display-message',
    'binky.widgets.common.go-url',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.person-name',
    'binky.widgets.common.phone-number-input',
    'binky.widgets.common.search',
    'binky.widgets.common.select',
    'binky.widgets.common.status',
    'binky.widgets.common.toast',
    'binky.widgets.common.wizard',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
