import binkyUI, {ModalContent, ModalDescription, ModalWizardStepList} from '@admin-tribe/binky-ui';
import {Heading, SearchField, Text} from '@adobe/react-spectrum';
import orderBy from 'lodash/orderBy';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useProductProfileModalContext} from '../AddProductProfileModalContext';

import ProductRadio from './products-radio-group/ProductRadio';
import ProductRadioGroup from './products-radio-group/ProductRadioGroup';

const Chiclet = binkyUI.common.components.Chiclet;
const ImageIcon = binkyUI.common.components.ImageIcon;

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cawright@ to update
const AddProductProfileModalProductsPage = () => {
  const intl = useIntl();
  const {
    currentProductProfile,
    selectedSingleProductCode,
    setIsValid,
    setSelectedSingleProductCode,
  } = useProductProfileModalContext();

  const desktops = orderBy(currentProductProfile.getDesktopFulfilledItems(), 'longName');

  const [filter, setFilter] = useState('');

  const existingSelectedDesktopCode = currentProductProfile.getSelectedDesktopFulfilledItem();

  React.useEffect(() => {
    if (existingSelectedDesktopCode) {
      setSelectedSingleProductCode(existingSelectedDesktopCode.code);
      setIsValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependent on existingSelectedDesktopCode
  }, [existingSelectedDesktopCode]);

  React.useEffect(() => {
    if (selectedSingleProductCode) {
      setIsValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependent on selectedSingleProductCode
  }, [selectedSingleProductCode]);

  const createPage = (children) => (
    <>
      <ModalDescription>
        <ModalWizardStepList />
        <Text data-testid="description-text">
          {intl.formatMessage({
            id: 'products.addProductProfileModal.productsPage.description',
          })}
        </Text>
      </ModalDescription>
      {children}
    </>
  );

  if (existingSelectedDesktopCode) {
    return createPage(
      <ModalContent>
        <Heading marginTop="0">
          {intl.formatMessage({
            id: 'products.addProductProfileModal.productsPage.selectedProductLabel',
          })}
        </Heading>
        <Chiclet
          IconComponent={
            <ImageIcon
              alt={existingSelectedDesktopCode.longName}
              size="M"
              src={existingSelectedDesktopCode.assets?.icons?.svg}
            />
          }
          name={existingSelectedDesktopCode.longName}
        />
      </ModalContent>
    );
  }

  return createPage(
    <ModalContent>
      <SearchField
        data-testid="products-search-field"
        label={intl.formatMessage({
          id: 'products.addProductProfileModal.productsPage.searchLabel',
        })}
        marginBottom="size-250"
        onChange={(e) => setFilter(e.toLowerCase())}
        value={filter}
      />
      <ProductRadioGroup
        aria-label={intl.formatMessage({
          id: 'products.addProductProfileModal.productsPage.description',
        })}
        label={intl.formatMessage(
          {
            id: 'products.addProductProfileModal.productsPage.availableProductsLabel',
          },
          {numAvailable: desktops.length}
        )}
        onChange={setSelectedSingleProductCode}
        value={selectedSingleProductCode}
      >
        {desktops
          .filter((singleProduct) => singleProduct.longName.toLowerCase().includes(filter))
          .map((singleProduct, index) => (
            <ProductRadio key={singleProduct.code} index={index} value={singleProduct.code}>
              <Chiclet
                IconComponent={
                  <ImageIcon
                    alt={singleProduct.longName}
                    size="M"
                    src={singleProduct.assets?.icons?.svg}
                  />
                }
                name={singleProduct.longName}
              />
            </ProductRadio>
          ))}
      </ProductRadioGroup>
    </ModalContent>
  );
};

export default AddProductProfileModalProductsPage;
