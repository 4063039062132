/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectoryCreateAzureModal', {
    bindings: {
      directoryList: '<',
    },
    controller,
    templateUrl: 'app/widgets/directories/create-azure/directory-create-azure-modal.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    $q,
    $log,
    $scope,
    $state,
    $timeout,
    $translate,
    $window,
    _,
    APP_CREATE_AZURE_DIRECTORY_MODAL_ID,
    APP_CREATE_DIRECTORY_MODAL_ID,
    AZURE_GROUPS_RETRY_GET_EVENT,
    AZURE_GROUPS_SAVE_EVENT,
    AZURE_GROUPS_ERROR_TYPES,
    CREATE_AZURE_DIRECTORY_WIZARD_ID,
    directorySync,
    DIRECTORY_SYNC_DOMAINS_ERROR_TYPES,
    DIRECTORY_SYNC_DOMAINS_RETRY_GET_EVENT,
    DIRECTORY_SYNC_DOMAINS_SAVE_EVENT,
    feature,
    IDP_TYPES,
    idpStateManager,
    DisplayMessage,
    panelManager,
    toastManager,
    WIZARD_INIT,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    // List of all possible states the modal can be in.
    /* eslint-disable sort-keys */
    const STATES = {
      INTRO: Symbol('INTRO'), // Description, legal info, login to Azure CTA.
      DETAILS: Symbol('DETAILS'), // Azure organization info, create tenant CTA.
      DOMAINS: Symbol('DOMAINS'), // List of domains to be claimed.
      GROUPS: Symbol('GROUPS'), // List of groups to be synchronized.
    };
    /* eslint-enable sort-keys */

    const vm = this;

    _.assign(vm, {
      $onInit,
      goToDirectoryDetails,
      goToDomains,
      onAzureLogin,
      onClose,
      onConfirmOrg,
      onDetailsRetry,
      onDomainsError,
      onDomainsProcessing,
      onDomainsProcessingDone,
      onDomainsRetry,
      onDomainsSave,
      onDomainsSelectionChange,
      onDomainsSubmit,
      onGroupsError,
      onGroupsProcessing,
      onGroupsProcessingDone,
      onGroupsRetry,
      onGroupsSave,
      onGroupsSubmit,
      onOpen,
      STATES,
    });

    function $onInit() {
      _.assign(vm, {
        directoryInfoConfirmText: 'widgets.directories.createAzure.orgDetails.confirmOrgDetails',
        directoryInfoDisplay: {
          orgId: true,
          protocol: true,
        },
        directoryInfoProtocol: 'widgets.directories.createAzure.orgDetails.openIdConnect',
        modalId: APP_CREATE_AZURE_DIRECTORY_MODAL_ID,
        wizardId: CREATE_AZURE_DIRECTORY_WIZARD_ID,
        wizardStepTitles: [
          $translate.instant('widgets.directories.createAzure.wizardSteps.login'),
          $translate.instant('widgets.directories.createAzure.wizardSteps.confirm'),
          $translate.instant('widgets.directories.createAzure.wizardSteps.domains'),
          $translate.instant('widgets.directories.createAzure.wizardSteps.groups'),
        ],
        wizardWaitPromise: $q.resolve(),
      });

      // Rest modal when component initializes
      resetModal();

      // Check if we're in the middle of an Azure onboarding operation and resume it if so.
      if (idpStateManager.isPendingAzureOnboarding()) {
        resumeAzureOnboardingFlow();
      }
    }

    function onOpen(params = {}) {
      // Reset modal only if we're not resuming onboarding.
      // Otherwise, we'll lose information set during `$onInit`.
      if (vm.currentState !== STATES.DETAILS) {
        resetModal();
      }

      vm.directoryName = params.directoryName;

      // Close create directory modal if it's still open underneath.
      // Wrapping it in a timeout call in order to prevent flicker while modal is animating in.
      // 150ms matches opacity transition duration on `._coral-Dialog`.
      if (panelManager.isOpen(APP_CREATE_DIRECTORY_MODAL_ID)) {
        $timeout(() => panelManager.close(APP_CREATE_DIRECTORY_MODAL_ID), 150);
      }
    }

    function onClose() {
      // Check `isOpen()` first since we're also calling this method after a $state
      // transition which might unregister the modal.
      // See `goToDirectoryDetails()` to see state transition.
      if (panelManager.isOpen(APP_CREATE_AZURE_DIRECTORY_MODAL_ID)) {
        panelManager.close(APP_CREATE_AZURE_DIRECTORY_MODAL_ID);
      }

      // Reset modal in the next digest to prevent user from briefly seeing the reset state of the modal upon closing it.
      // 150ms matches opacity transition duration on `._coral-Dialog`.
      $timeout(resetModal, 150);
    }

    function resetModal() {
      _.assign(vm, {
        azureOrgAlreadyLinked: false,
        azureOrgError: false,
        azureOrgInfo: null,
        currentState: STATES.INTRO,
        directoryName: undefined,
        domainsError: false,
        groupsError: false,
        hasSelectedDomains: false,
        isSubmitting: false,
        waitPromise: $q.resolve(),
      });

      hideBanner();
    }

    // Write state to session storage and redirect to Azure portal for authorization.
    function onAzureLogin() {
      // Just making sure.
      if (vm.currentState !== STATES.INTRO) {
        return;
      }

      // Make sure we get a new CSRF token.
      directorySync.generateNewCsrfToken();

      // Store CSRF token and directory name for validation and data recovery after Azure portal redirects back.
      idpStateManager.persistData(directorySync.getCsrfToken(), vm.directoryName, IDP_TYPES.AZURE);

      // Show spinner while redirection is ongoing.
      vm.waitPromise = $q.defer().promise;
      vm.isSubmitting = true;

      // Go to Azure portal for consent.
      $window.location.href = directorySync.getAzureConsentUrl();
    }

    // Opens Azure onboarding modal.
    function resumeAzureOnboardingFlow() {
      vm.isSubmitting = true;

      // Read and remove directory name set in sessionStorage before redirecting to Azure portal.
      const directoryName = idpStateManager.getDirectoryName();

      // Read Azure access token from URL.
      vm.azureToken = feature.isEnabled('temp_idp_token')
        ? idpStateManager.getAccessTokenFromHash()
        : idpStateManager.getAccessToken();

      // Read Azure app info and check if it's already linked.
      vm.waitPromise = getAzureOrgInfo();

      // Set state.
      vm.currentState = STATES.DETAILS;

      // The wizard needs to be initialized first before switching states.
      $scope.$on(WIZARD_INIT, (event, wizardId) => {
        if (wizardId === vm.wizardId) {
          $timeout(() => $scope.$broadcast(WIZARD_NEXT, vm.wizardId)); // Allow one digest to finish in order for the wizard to be mounted.
        }
      });

      // Self-open modal.
      // Using `$timeout` to allow modal to finish registering.
      $timeout(() => panelManager.open(APP_CREATE_AZURE_DIRECTORY_MODAL_ID, {directoryName}));
    }

    // Get information about the authorized Azure directory and other existing Azure links.
    function getAzureOrgInfo() {
      return $q
        .all([
          directorySync.azureOrgInfo(vm.azureToken).get().$promise,
          directorySync.linkedOrgs.query().$promise,
        ])
        .then(([azureOrgInfo, linkedOrgs]) => {
          if (azureOrgInfo && azureOrgInfo.organization) {
            hideBanner();
            vm.azureOrgError = false;
            vm.azureOrgInfo = azureOrgInfo;

            // Check if Azure org is already linked.
            const linkedOrg = _.find(linkedOrgs, {azureId: vm.azureOrgInfo.organization.id});
            vm.azureOrgAlreadyLinked = !!linkedOrg;
            vm.authSrcCode = linkedOrg && linkedOrg.imsId;

            return;
          }

          $log.error('[AD Sync] Invalid response getting Azure org info.', azureOrgInfo);

          return $q.reject(); // eslint-disable-line consistent-return
        })
        .catch((error) => {
          $log.error('[AD Sync] Error getting Azure org info.', error);

          showErrorBanner();
          vm.azureOrgError = true;
        })
        .finally(() => {
          // Flow resumed, clear state.
          idpStateManager.clear();

          vm.isSubmitting = false;
        });
    }

    // Call AD Sync to create new tennant.
    function onConfirmOrg() {
      // Sanity check.
      if (vm.currentState !== STATES.DETAILS || vm.azureOrgAlreadyLinked) {
        return;
      }

      hideBanner();
      vm.isSubmitting = true;
      vm.waitPromise = directorySync
        .linkOrg(vm.azureToken)
        .save({
          name: vm.directoryName,
        })
        .$promise.then((response) => {
          // We need authSrcCode to get and set domains, groups and call sync APIs.
          vm.authSrcCode = response && response.imsId;

          toastManager.showSuccessToast(
            $translate.instant('widgets.directories.createAzure.directoryCreatedMessage')
          );

          vm.currentState = STATES.DOMAINS;
          $scope.$broadcast(WIZARD_NEXT, vm.wizardId);

          // Refresh directories we have a new one.
          vm.directoryList.refresh();
        })
        .catch((error) => {
          $log.error('[AD Sync] Error linking org.', error);

          showErrorBanner();
        })
        .finally(() => {
          vm.isSubmitting = false;
        });
    }

    function onDetailsRetry() {
      getAzureOrgInfo();
    }

    // Azure Domains
    function onDomainsError(err) {
      // If submit failed, show error button.
      if (err && err.message === DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.SUBMIT_ERROR) {
        showErrorBanner();
        return;
      }

      // Otherwise, set `domainsError` to `true` so that the modal buttons change accordingly.
      vm.domainsError = true;
    }

    function onDomainsRetry() {
      vm.domainsError = false; // Unset error state while retry call is pending.
      $scope.$broadcast(DIRECTORY_SYNC_DOMAINS_RETRY_GET_EVENT);
    }

    function onDomainsProcessing() {
      vm.isSubmitting = true;
    }

    function onDomainsProcessingDone() {
      vm.isSubmitting = false;
    }

    function onDomainsSubmit() {
      hideBanner(); // Remove error banner from previous submit errors (if any).
      $scope.$broadcast(DIRECTORY_SYNC_DOMAINS_SAVE_EVENT);
    }

    function onDomainsSave() {
      vm.currentState = STATES.GROUPS;
      $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
    }

    function onDomainsSelectionChange(selectedDomains) {
      vm.hasSelectedDomains = selectedDomains.length > 0;
    }

    function goToDomains() {
      if (vm.currentState === STATES.GROUPS) {
        hideBanner(); // Clear submit groups error (if any) before going back to Domains.
        vm.currentState = STATES.DOMAINS;
        $scope.$broadcast(WIZARD_PREVIOUS, vm.wizardId);
      }
    }

    function goToDirectoryDetails() {
      // Go to directory details if one is set, otherwise, go to directory list.
      (vm.authSrcCode
        ? $state.go('settings.directory-details', {directoryId: vm.authSrcCode})
        : $state.go('settings.identity.directories')
      ).finally(onClose);
    }

    // Azure Groups
    function onGroupsError(err) {
      // If submit failed, show error button.
      if (err && err.message === AZURE_GROUPS_ERROR_TYPES.SUBMIT_ERROR) {
        showErrorBanner();
        return;
      }

      // Otherwise, set `groupsError` to `true` so that the modal buttons change accordingly.
      vm.groupsError = true;
    }

    function onGroupsRetry() {
      vm.groupsError = false; // Unset error state while retry call is pending.
      $scope.$broadcast(AZURE_GROUPS_RETRY_GET_EVENT);
    }

    function onGroupsProcessing() {
      vm.isSubmitting = true;
    }

    function onGroupsProcessingDone() {
      vm.isSubmitting = false;
    }

    function onGroupsSubmit() {
      hideBanner(); // Remove error banner from previous submit errors (if any).
      $scope.$broadcast(AZURE_GROUPS_SAVE_EVENT);
    }

    function onGroupsSave() {
      onComplete();
    }

    /////////////////

    function onComplete() {
      // Set loading state.
      // `vm.waitPromise` is set to an unresolving promise so that modal is disabled until no longer visible.
      // So it won't become enabled while closing animation is running.
      // It gets reset after `onClose()` is called, a few lines below.
      vm.isSubmitting = true;
      vm.waitPromise = $q.defer().promise;

      // Tell sync engine to start.
      // We'll ignore any error response from this API because
      // (1) it would be difficult to explain to the user what happened and
      // (2) AD Sync service checks for changes on an interval, so worst case, sync will start later.
      directorySync.syncEngine
        .start({authSrcCode: vm.authSrcCode})
        .$promise.catch((error) => {
          $log.error('[AD Sync] Error starting sync.', error);
        })
        .finally(() => {
          // Go to newly created directory details page.
          goToDirectoryDetails();
        });
    }

    // Page banner
    function showBanner(body, header, variant) {
      vm.displayMessage = new DisplayMessage({
        body,
        header,
        variant,
      });
    }

    function hideBanner() {
      vm.displayMessage = undefined;
    }

    function showErrorBanner(message, title) {
      showBanner(message, title);
    }
  }
})();
/* eslint-enable max-lines */
