import {useIsMounted} from '@pandora/react-is-mounted';
import {useState} from 'react';

import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

/**
 * Hook to use with various Edit modal variants which use <AssignmentModalBase />.
 *
 * @param {Function} updateModelFn - function to call when the modal has successfully saved the edit.
 * @returns {Object} obj The methods to control the modal
 * @returns {Function} obj.onCloseModal - Function called when the modal is closed for any reason.
 * @returns {Function} obj.onOpenModal - Function called when the modal is opened.
 * @returns {Function} obj.onSuccess - Function called after the edit is saved.
 * @returns {USER_DETAILS_MODAL_NAMES} obj.openedModal - If the modal is open, its name, otherwise null.
 */
const useEditDetailsModal = ({updateModelFn}) => {
  const [openedModal, setOpenedModal] = useState();
  const isMounted = useIsMounted();

  const onCloseModal = () => {
    if (isMounted) {
      setOpenedModal(null);
    }
  };

  const onSuccess = () => {
    onCloseModal();
    updateModelFn();
  };

  const onOpenModal = (modalName) => {
    switch (modalName) {
      case USER_DETAILS_MODAL_NAMES.EDIT_API_CREDENTIALS:
      case USER_DETAILS_MODAL_NAMES.EDIT_USER_PROFILE:
      case USER_DETAILS_MODAL_NAMES.EDIT_PRODUCTS_AND_GROUPS:
      case USER_DETAILS_MODAL_NAMES.EDIT_DEVELOPER_PRODUCTS:
      case USER_DETAILS_MODAL_NAMES.EDIT_ADMIN_RIGHTS:
        setOpenedModal(modalName);
        break;
      default:
        throw new Error(`Invalid modal name: ${modalName}`);
    }
  };

  return {
    onCloseModal,
    onOpenModal,
    onSuccess,
    openedModal,
  };
};

export default useEditDetailsModal;
