import {feature} from '@admin-tribe/binky';
import {LinkButton, OverlayWait, TableSectionTable} from '@admin-tribe/binky-ui';
import {DialogContainer, Text, View} from '@adobe/react-spectrum';
import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import useDomainsTable from 'features/settings/common/components/domain-list-table/hooks/useDomainsTable';
import DomainStatus from 'features/settings/common/components/domain-status/DomainStatus';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import DirectoryDomainListTableActions from 'features/settings/components/directory/domains/directory-domains-table/DirectoryDomainListTableActions';
import ValidateDomainModal from 'features/settings/components/domains/validate-domain-modal/ValidateDomainModal';
import ImportFederatedDomainsModal from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModal';
import ImportFederatedDomainsModal2 from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModal2';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

const DIALOGS = {
  IMPORT_FEDERATED_DOMAINS: Symbol('import-federated-domains'),
  IMPORT_FEDERATED_DOMAINS_REFACTORED: Symbol('import-federated-domains-refactored'),
  VALIDATE_DOMAIN: Symbol('validate-domain'),
};

const DirectoryDomainsTable2 = () => {
  const [dialog, setDialog] = useState(null);

  const [domainToValidate, setDomainToValidate] = useState(null);
  const [federatedType, setFederatedType] = useState('');
  const [authData, setAuthData] = useState({});

  const {domainList, loadDomainList, tableOptions, directoryData, reloadDomainList} =
    useDomainsListContext();
  const {clearDirectoryCache} = useDirectoryList();
  const {onTableSectionChange} = useDomainsTable();
  const intl = useIntl();

  const onValidatePressed = (domain) => {
    setDomainToValidate(domain);
    setDialog(DIALOGS.VALIDATE_DOMAIN);
  };

  const onDomainValidated = () => {
    reloadDomainList();
    clearDirectoryCache();
  };

  const onImportSuccess = () => {
    reloadDomainList({}, {updateCount: true});
    clearDirectoryCache();
  };

  // get the list of domains
  useEffect(() => {
    loadDomainList({}, {updateCount: true});

    // eslint-disable-next-line react-hooks/exhaustive-deps -- will run only once during the initial render
  }, []);

  //  Check if we are in the middle of a return
  // from a federated login and open the import
  // federated domains modal if so
  useEffect(() => {
    if (ExternalAuthService.isLoginInProgress()) {
      const authenticationData = ExternalAuthService.completeAuthentication();
      setFederatedType(authenticationData.data?.federationType);

      setAuthData(authenticationData);
      setDialog(
        feature.isEnabled('temp_refactor_federated_domains')
          ? DIALOGS.IMPORT_FEDERATED_DOMAINS_REFACTORED
          : DIALOGS.IMPORT_FEDERATED_DOMAINS
      );
    }
  }, []);

  const columnDescriptor = [
    {key: 'nameHeader', props: {allowsSorting: true}},
    {key: 'statusHeader'},
    {key: 'actionsHeader'},
  ];

  const renderers = {
    actionsHeader: ({item}) => (
      <View>
        {item.needsValidation && (
          <LinkButton onClick={() => onValidatePressed(item)}>
            <FormattedMessage id="settings.directoryDomains.validateAction" />
          </LinkButton>
        )}
      </View>
    ),
    nameHeader: ({item}) => <Text>{item.domainName}</Text>,
    statusHeader: ({item}) => <DomainStatus status={item.status} />,
  };

  return (
    <OverlayWait isLoading={domainList.isLoading} showContent>
      <TableSection
        id="directory-domains-table"
        isServerError={domainList.hasLoadingError}
        items={domainList.domainListData.items}
        onTableSectionChange={onTableSectionChange}
        pageNumber={tableOptions.page}
        pageSizeOptions={tableOptions.pageSizeOptions}
        selectionMode={SELECTION_MODE.MULTIPLE}
        totalPages={tableOptions.totalPages}
      >
        <DirectoryDomainListTableActions onTableSectionChange={onTableSectionChange} />
        <TableSectionTable
          aria-label={intl.formatMessage({id: 'settings.domainsTable.ariaLabel'})}
          columnDescriptor={columnDescriptor}
          columnNamespace="settings.directoryDomains"
          data-testid="directory-domains-table"
          noItemsFoundContentMessage={intl.formatMessage({
            id: 'settings.domainsTable.noItemsFoundContentMessage',
          })}
          noSearchResultsContentMessage={intl.formatMessage({
            id: 'settings.domainsTable.noSearchResultsContentMessage',
          })}
          renderers={renderers}
        />
      </TableSection>

      <DialogContainer onDismiss={() => setDialog(null)}>
        {dialog === DIALOGS.VALIDATE_DOMAIN && (
          <ValidateDomainModal
            data-testid="validate-domain-modal"
            domains={[domainToValidate]}
            onDomainsValidated={onDomainValidated}
          />
        )}

        {dialog === DIALOGS.IMPORT_FEDERATED_DOMAINS && (
          <ImportFederatedDomainsModal
            authSourceId={directoryData.id}
            data-testid="import-domain-modal"
            domainEnforcement={directoryData.domainEnforcement}
            externalToken={authData.token}
            federatedType={federatedType}
            onImportSuccess={onImportSuccess}
          />
        )}

        {dialog === DIALOGS.IMPORT_FEDERATED_DOMAINS_REFACTORED && (
          <ImportFederatedDomainsModal2
            authSourceId={directoryData.id}
            data-testid="import-federated-domain-modal"
            domainEnforcement={directoryData.domainEnforcement}
            externalToken={authData.token}
            federatedType={federatedType}
            onImportSuccess={onImportSuccess}
          />
        )}
      </DialogContainer>
    </OverlayWait>
  );
};

export default DirectoryDomainsTable2;
