import {InfoBar, InfoItem, InfoItemScorecard} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A wrapper for Device Licenses Page Header info bar
 */
const DeviceLicensesInfoBar = ({
  namespace,
  totalLicensesCount,
  usedLicensesCount,
  totalDevicesCount,
  earliestExpiryDate,
  isFrlOnline,
}) => {
  const intl = useIntl();
  return (
    <InfoBar>
      <InfoItemScorecard
        label={intl.formatMessage({
          id: `${namespace}.scoreCards.totalLicenses`,
        })}
        value={totalLicensesCount}
      />
      <InfoItemScorecard
        label={intl.formatMessage({
          id: `${namespace}.scoreCards.usedLicenses`,
        })}
        value={usedLicensesCount}
      />
      {isFrlOnline && (
        <InfoItemScorecard
          data-test-id="total-devices-scorecard"
          label={intl.formatMessage({
            id: `${namespace}.scoreCards.totalDevices`,
          })}
          value={totalDevicesCount}
        />
      )}
      <InfoItem
        label={intl.formatMessage({
          id: `${namespace}.scoreCards.expiryDate`,
        })}
        value={earliestExpiryDate}
      />
    </InfoBar>
  );
};

DeviceLicensesInfoBar.propTypes = {
  /**
   * The earliest expiry date. This will be initially undefined until it is resolved
   */
  earliestExpiryDate: PropTypes.string,

  /**
   * Boolean showing if we have an FRL online product
   */
  isFrlOnline: PropTypes.bool.isRequired,

  /**
   * The common namespace for react-intl
   */
  namespace: PropTypes.string.isRequired,

  /**
   * The total number of devices. This will be initially undefined until it is resolved
   */
  totalDevicesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * The total number of licenses. This will be initially undefined until it is resolved
   */
  totalLicensesCount: PropTypes.number,

  /**
   * The total number of used licenses. This will be initially undefined until it is resolved
   */
  usedLicensesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default DeviceLicensesInfoBar;
