/**
 * @deprecated with temp_react_port_users
 *
 * @ngdoc component
 * @name app.widgets.users:appRemoveUserStorageModal
 *
 * @description The modal for creating and editing a storage folder.
 *
 * @binding {PageContext} pageContext. For populating Analytics event
 * @binding {OrganizationUserList} userList. To user list to be operated on
 * @binding {Selection} userListSelection. The selected users to be removed
 */
(function () {
  const componentName = 'appRemoveUserStorageModal';
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      contractList: '<',
      pageContext: '<',
      userList: '<',
      userListSelection: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/remove-user-storage-modal/remove-user-storage-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $scope,
    $translate,
    _,
    AnalyticsEvent,
    COMMON_EVENTS,
    panelManager,
    REMOVE_USER_STORAGE_MODAL_ID,
    REMOVE_USER_STORAGE_OPTIONS,
    REMOVE_USER_STORAGE_WIZARD_ID,
    TOAST_TYPES,
    toastManager,
    UserFolder,
    USER_LIST_REFRESH,
    WIZARD_CHANGE,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      isReadyToSubmit,
      isWizardAtFirstPage,
      isWizardAtLastPage,
      onClose,
      onOpen,
      REMOVE_USER_STORAGE_MODAL_ID,
      REMOVE_USER_STORAGE_OPTIONS,
      showingStorageOptionsPanel,
      showingUserListPanel,
      submit,
      updateOption,
      updateUser,
      wizardNext,
      wizardPrev,
    });

    function $onInit() {
      _.assign(vm, {
        currentOption: REMOVE_USER_STORAGE_OPTIONS.SHARE,
        currentWizardStep: 0,
        isSubmitting: false,
        wizardId: REMOVE_USER_STORAGE_WIZARD_ID,
      });

      $scope.$on(WIZARD_CHANGE, (event, options) => {
        vm.currentWizardStep = options.stepIndex;
        vm.totalWizardSteps = options.totalSteps;
      });
    }

    function isReadyToSubmit() {
      return vm.currentOption === REMOVE_USER_STORAGE_OPTIONS.SHARE ? vm.currentUser : true;
    }

    function isWizardAtFirstPage() {
      return vm.currentWizardStep === 0;
    }

    function isWizardAtLastPage() {
      return vm.currentWizardStep === vm.totalWizardSteps - 1;
    }

    function onClose() {
      _.assign(vm, {
        currentOption: REMOVE_USER_STORAGE_OPTIONS.SHARE,
        currentUser: undefined,
        displayMessage: undefined,
      });
      $scope.$broadcast(COMMON_EVENTS.RESET);
    }

    function onOpen(options = {}) {
      const removingMultipleUser = vm.userListSelection.items.length > 1;
      _.assign(vm, {
        refreshOnSave: options.refreshOnSave,
        removingMultipleUser,
        steps: removingMultipleUser ? ['', '', ''] : ['', ''], // wizard requires step title
        userStorageList: options.userStorageList,
      });
    }

    function showingStorageOptionsPanel() {
      return vm.removingMultipleUser ? vm.currentWizardStep === 1 : vm.currentWizardStep === 0;
    }

    function showingUserListPanel() {
      return vm.removingMultipleUser && vm.currentWizardStep === 0;
    }

    function updateOption(option) {
      vm.currentOption = option;
    }

    function updateUser(user) {
      vm.currentUser = user;
    }

    function wizardNext() {
      if (vm.showingStorageOptionsPanel()) {
        switch (vm.currentOption) {
          case REMOVE_USER_STORAGE_OPTIONS.DISCARD:
            vm.confirmationTitle = $translate.instant(
              'widgets.users.removeUserStorageModal.options.discard.modalTitle'
            );
            vm.confirmationMessage = $translate.instant(
              'widgets.users.removeUserStorageModal.options.discard.confirmationMessage'
            );
            break;
          case REMOVE_USER_STORAGE_OPTIONS.DELETE:
            vm.confirmationTitle = $translate.instant(
              'widgets.users.removeUserStorageModal.options.delete.modalTitle'
            );
            vm.confirmationMessage = $translate.instant(
              'widgets.users.removeUserStorageModal.options.delete.confirmationMessage'
            );
            break;
          default:
            vm.confirmationTitle = $translate.instant(
              'widgets.users.removeUserStorageModal.options.share.modalTitle'
            );
            vm.confirmationMessage = $translate.instant(
              'widgets.users.removeUserStorageModal.options.share.confirmationMessage'
            );
        }
      }
      $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
    }

    function wizardPrev() {
      $scope.$broadcast(WIZARD_PREVIOUS, vm.wizardId);
    }

    function submit() {
      const usersRemovedCount = vm.userListSelection.items.length;
      const pathParams = {
        id: _.get(vm.currentUser, 'id'),
        type: vm.currentOption,
        userStorageList: vm.userStorageList,
      };
      panelManager.close(vm.REMOVE_USER_STORAGE_MODAL_ID);
      vm.userList.remove(vm.userListSelection.items);
      vm.userList
        .save({
          refresh: vm.refreshOnSave,
          removePathOverride: UserFolder.getRemovePathOverride(pathParams),
        })
        .then(onRemoveUsersSuccess)
        .catch(onRemoveUsersError);

      function onRemoveUsersError(httpError) {
        if (_.get(httpError, 'data.errorCode') === 'CANNOT_REMOVE_CONTRACT_OWNER_FROM_ORG') {
          const removedOwner = _.find(vm.userListSelection.items, (user) =>
            vm.contractList.isContractOwner({userId: user.id})
          );
          const name = _.result(removedOwner, 'getDisplayNameOrEmail');
          if (name) {
            toastManager.showErrorToast(
              $translate.instant('widgets.users.all.removeUsers.error.message', {name})
            );
          } else {
            // show the generic error message
            toastManager.showErrorToast();
          }
        } else {
          toastManager.showErrorToast();
        }
      }

      function onRemoveUsersSuccess() {
        if (pathParams.type === REMOVE_USER_STORAGE_OPTIONS.DELETE) {
          toastManager.showSuccessToast(
            $translate.instant(
              'widgets.users.removeUserStorageModal.toast.userRemoved',
              {count: vm.userListSelection.items.length},
              'messageformat'
            )
          );
        } else if (vm.userListSelection.items.length === 1) {
          toastManager.showToast(
            TOAST_TYPES.INFO,
            $translate.instant(
              'widgets.users.removeUserStorageModal.toast.singleUserRemovedFolderProcessing'
            )
          );
        } else {
          toastManager.showToast(
            TOAST_TYPES.INFO,
            $translate.instant(
              'widgets.users.removeUserStorageModal.toast.userRemovedFolderProcessing',
              {count: vm.userStorageList.length},
              'messageformat'
            )
          );
        }
        vm.userListSelection.deselectAllItems();
        $scope.$broadcast(USER_LIST_REFRESH);
        triggerAnalytics('removeUsers', {usersRemovedCount});
      }
    }

    ////////////

    function triggerAnalytics(componentMethod, attributes = {}) {
      AnalyticsEvent.dispatch({
        attributes,
        componentMethod,
        componentMethodType: 'submit',
        componentName,
        pageContext: vm.pageContext,
      });
    }
  }
})();
