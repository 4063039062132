import {Button, DialogTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {REPORT_FOLDER_TYPE} from 'common/services/storage-report/StorageReportConstants';

import CreateReportModal from '../modal/CreateReportModal';

/**
 * The `Create report` button used on StorageReportsPage and StorageReportsDetailsPage.
 */
const CreateReportButton = ({folderType, onCreateReport}) => (
  <DialogTrigger>
    <Button data-testid="create-report-btn" variant="accent">
      <FormattedMessage id="storage.storageReports.details.button.createReport" />
    </Button>
    {(close) => (
      <CreateReportModal folderType={folderType} onClose={close} onSuccess={onCreateReport} />
    )}
  </DialogTrigger>
);

CreateReportButton.propTypes = {
  /**
   * Determines whether the modal refers to creation of shared storage or individual user folders.
   * If undefined, the modal will prompt the admin to select the folder type.
   */
  folderType: PropTypes.oneOf([REPORT_FOLDER_TYPE.ASSETS, REPORT_FOLDER_TYPE.USERS]),
  /**
   * The callback called when a report has been successfully created.
   * There is one parameter which is the report folderType - one of REPORT_FOLDER_TYPE.
   */
  onCreateReport: PropTypes.func.isRequired,
};

export default CreateReportButton;
