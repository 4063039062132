(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support.component:appSupportTicketDragAndDropFile
   * @description Component that sets up drag and drop component for use in various
   *   support ticket flows, keeping the actual component restrictions, messaging, etc
   *   consistent between Support views (i.e. - creating/viewing ticket).
   * @param {Array} attachments - empty Array to use as shared storage between this
   *   component and any using controller code (will add file attachments to this
   *   Array structure)
   */
  angular.module('app.widgets.support').component('appSupportTicketDragAndDropFile', {
    bindings: {
      attachments: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-drag-and-drop-file/support-ticket-drag-and-drop-file.component.html',
  });

  /* @ngInject */
  function controller(_, FileSize) {
    const vm = this;

    const MAX_ATTACHMENT_SIZE = FileSize.getNumBytes(20, 'MB'); // max file size in bytes
    const MAX_NUMBER_OF_ATTACHMENTS = 5; // maximum number of files to attach at a time
    const RESTRICTED_ATTACHMENT_TYPES = ['exe', 'dll']; // restricted upload file types
    const RESTRICTED_TYPES_ATTACH_ERROR_MSG_KEY =
      'widgets.support.supportTicketDragAndDropFile.restrictedTypesAttachErrorMsgKey'; // key for message to display when file attaching matches a restricted type

    _.assign(vm, {
      MAX_ATTACHMENT_SIZE,
      MAX_NUMBER_OF_ATTACHMENTS,
      RESTRICTED_ATTACHMENT_TYPES,
      RESTRICTED_TYPES_ATTACH_ERROR_MSG_KEY,
    });
  }
})();
