(function () {
  /**
   * @deprecated no longer used - this can be removed
   *
   * @ngdoc component
   * @name app.widgets.user-groups:appUserGroupListTable
   * @description
   * Directive to display list of user groups in a table with avatars and optional checkboxes
   */
  angular.module('app.widgets.user-groups').component('appUserGroupListTable', {
    bindings: {
      display: '<?',
      paginationId: '@?',
      selection: '<?',
      userGroupList: '<?',
    },
    controller,
    templateUrl: 'app/widgets/user-groups/user-group-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $scope,
    $timeout,
    _,
    panelManager,
    SELECTION_STATE,
    USER_GROUP_DETAILS_DRAWER_ID,
    USER_GROUP_REFRESH_TABLE
  ) {
    const vm = this;

    vm.$onInit = $onInit;

    function $onInit() {
      vm.onPageChange = onPageChange;
      vm.onPageSizeChange = onPageSizeChange;

      vm.sortReverse = false;
      vm.reverseSort = reverseSort;

      vm.onRowClick = onRowClick;

      if (vm.display && vm.display.checkbox && vm.selection) {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
        vm.isCheckboxDisabled = isCheckboxDisabled;
        vm.isChecked = isChecked;
        vm.onClickCheck = onClickCheck;
        vm.onClickCheckAll = onClickCheckAll;
      }

      $scope.$on(USER_GROUP_REFRESH_TABLE, updateHeaderCheckbox);
    }

    function isCheckboxDisabled(userGroup) {
      return !userGroup.isEditable();
    }

    function isChecked(userGroup) {
      return vm.selection.isItemSelected(userGroup);
    }

    function onClickCheck(userGroup) {
      vm.selection.toggleItemSelection(userGroup);
      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    function getEditableGroups() {
      const editableGroups = [];
      _.forEach(vm.userGroupList.items, (group) => {
        if (group.isEditable()) {
          editableGroups.push(group);
        }
      });
      return editableGroups;
    }

    function onClickCheckAll() {
      const editableGroups = getEditableGroups();
      if (vm.isAllChecked) {
        vm.selection.deselectItems(editableGroups);
      } else {
        vm.selection.selectItems(editableGroups);
      }

      $timeout(() => {
        updateHeaderCheckbox();
      });
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.userGroupList.reverseSortOrder();
      // eslint-disable-next-line promise/catch-or-return
      vm.userGroupList.refresh().then(() => {
        updateHeaderCheckbox();
      });
    }

    function updateHeaderCheckbox() {
      if (vm.userGroupList && vm.userGroupList.items && vm.userGroupList.items.length > 0) {
        const editableGroups = getEditableGroups();
        const selectionState = vm.selection.getSelectionStateForItems(editableGroups);
        vm.isAllChecked = editableGroups.length > 0 && selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = editableGroups.length > 0 && selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }

    function onPageChange(newPage) {
      vm.userGroupList.setPageNumber(newPage);
      // eslint-disable-next-line promise/catch-or-return
      vm.userGroupList.refresh().then(() => {
        updateHeaderCheckbox();
      });
    }

    function onPageSizeChange(pageSize) {
      vm.userGroupList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.userGroupList.setPageNumber(1);
      // eslint-disable-next-line promise/catch-or-return
      vm.userGroupList.refresh().then(() => {
        updateHeaderCheckbox();
      });
    }

    function onRowClick($event, userGroup) {
      // only perform the row click action if the target of the click event isn't an anchor itself
      if (!wasClickOnReservedElement($event)) {
        vm.selectedUserGroup = userGroup;
        $timeout(() => {
          panelManager.open(USER_GROUP_DETAILS_DRAWER_ID);
        });
      }
    }

    ///////////////

    function wasClickOnReservedElement($event) {
      if ($event.target) {
        const targetEl = angular.element($event.target);
        return targetEl.hasClass('name') || targetEl[0].tagName === 'INPUT';
      }
      return false;
    }
  }
})();
