import {TooltipButton} from '@pandora/react-tooltip-button';
import ShoppingCartIcon from '@spectrum-icons/workflow/ShoppingCart';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import useAddProductModal from 'common/hooks/useAddProductModal';
import chatProvider from 'core/services/chat/chatProvider';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

const BuyMoreTooltipButton = ({contractId}) => {
  const intl = useIntl();
  const {closeAddProductModal, openAddProducts, showAddProductModal} = useAddProductModal();

  return (
    <>
      <TooltipButton
        data-testid="buy-more-button"
        hoverText={intl.formatMessage({
          id: 'account.overview.table.column.actions.buyMore.tooltip',
        })}
        isQuiet
        onPress={openAddProducts}
        variant="action"
      >
        <ShoppingCartIcon size="S" />
      </TooltipButton>
      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          contractId={contractId}
          onClose={closeAddProductModal}
        />
      )}
    </>
  );
};

BuyMoreTooltipButton.propTypes = {
  /**
   * contractId to be passed to AddProductModalWrapper component
   */
  contractId: PropTypes.string.isRequired,
};

export default BuyMoreTooltipButton;
