/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Job
   * @description Model for a job (also known as a "bulk operation")
   */
  angular.module('app.core.jobs').factory('Job', job);

  /* @ngInject */
  function job(
    $filter,
    $q,
    $rootScope,
    $translate,
    _,
    jilJobs,
    JOB_OPERATION,
    JOB_STATUS,
    MESSAGE,
    moment,
    OrganizationList,
    translationUtils,
    User
  ) {
    const DEFAULT_OPERATION_DISPLAY_NAME = 'widgets.bulkOperations.operation.unknown';
    const DEFAULT_STATUS_DISPLAY_NAME = 'widgets.bulkOperations.status.unknown';
    const JOB_SOURCE = initJobSourceObj();
    const OPERATION_TO_DISPLAY_NAME_MAP = initOperationDisplayNameMap();
    const PLACEHOLDER_TEXT = '--';
    const STATUS_TO_DISPLAY_NAME_MAP = initStatusDisplayNameMap();
    const TERMINATED_JOB_STATUSES = initTerminatedJobStatusesArray();
    const TODAY = moment().startOf('day');
    const YESTERDAY = moment().subtract(1, 'days').startOf('day');

    class Job {
      /**
       * @description Simple constructor for new Job objects.
       * @param {Object} [options] - key/value options for Job construction
       * @param {String} [options.id] - unique id of Job
       */
      constructor(options = {}) {
        this.id = options.id;
      }

      /**
       * @description Returns true if the job has terminated and if its is an
       *   anvil job.
       * @returns {boolean} true if this job can be deleted
       */
      canBeDeleted() {
        return this.hasTerminated() && this.isAnvilJob();
      }

      /**
       * @description Cancels this job by calling the JIL update job API. If job
       *   canceled successfully, then a refresh of this Job is performed. The
       *   Promise returned by this call is resolved when the refresh completes,
       *   otherwise, if any step fails, the Promise is rejected.
       * @returns {Promise} the promise for the JIL request
       */
      cancel() {
        const operations = [
          {
            op: 'replace',
            path: `/${this.id}/cancelRequested`,
            value: true,
          },
        ];
        return jilJobs.jobs.batchOperation({}, operations).$promise.then(() => {
          $rootScope.$emit(MESSAGE.UPDATE.JOB, this.id);
          return this.refresh();
        });
      }

      /**
       * @description Returns true if this job's license deficit summary can be
       *   displayed. The summary can be displayed if the job has ceased executing
       *   it has a license deficit summary.
       * @returns {boolean} true if this job's license deficit summary can be
       *   displayed
       */
      canShowLicenseDeficitSummary() {
        return this.hasTerminated() && this.getLicenseDeficitSummary().length > 0;
      }

      /**
       * @description Returns true if this job's results can be displayed. Results
       *   can be displayed if the job has ceased executing and any of its items
       *   were actually processed.
       * @returns {boolean} true if this job's results can be displayed
       */
      canShowResults() {
        const processedCount = _.get(this, 'itemSummary.processedCount') || 0;
        return processedCount > 0 && this.hasTerminated();
      }

      /**
       * @description Method to obtain the full name of the User who started
       *   this Job.
       * @returns {User} the User who started/initiated this Job.
       */
      getInitiatedBy() {
        return new User({
          firstName: this.startedBy.firstName,
          lastName: this.startedBy.lastName,
        });
      }

      /**
       * @description Returns the output filename to label the exported license
       *   deficit report.
       * @returns {String} the filename of the license deficit report.
       */
      getLicenseDeficitReportFilename() {
        return createFilename('license-deficit.csv', this.inputFileName);
      }

      /**
       * @description Returns this job's license deficit summary, conveniently
       *   returning an empty Array if the job does not have any license deficits.
       * @returns {Array} this job's license deficit summary
       */
      getLicenseDeficitSummary() {
        const summary = _.get(this, 'itemSummary.licenseDeficitSummary');
        return _.isArray(summary) ? summary : [];
      }

      /**
       * @description Returns the translate key for this job's operation name
       *   (e.g. 'jobs.operation.addUsers' for the Add Users operation) or
       *   'jobs.operation.unknown' if the operation is not recognised.
       * @returns {string} the translate key for this job's operation name
       */
      getOperationDisplayName() {
        return (
          _.get(OPERATION_TO_DISPLAY_NAME_MAP, this.operation) || DEFAULT_OPERATION_DISPLAY_NAME
        );
      }

      /**
       * @description Method to obtain the heading and last breadcrumb name for
       *   this Job for use on the Job Results (line-by-line) view of the
       *   application.
       * @returns {String} localized String of heading/last breadcrumb name
       */
      getResultHeading() {
        if (_.isNil(this.inputFileName) || _.isEmpty(this.inputFileName)) {
          return `${translationUtils.sanitizeSafeInstant(
            this.getOperationDisplayName()
          )} - ${$translate.instant('widgets.bulkOperations.results.title')}`;
        }
        return `${translationUtils.sanitizeSafeInstant(this.getOperationDisplayName())} - ${
          this.inputFileName
        }`;
      }

      /**
       * @description Returns the filename to use when downloading this job's
       *   results file.
       * @returns {String} the filename of this job's results file
       */
      getResultsFilename() {
        let filename;
        if (this.operation === JOB_OPERATION.ASSIGN_LICENSES) {
          filename = `license-assignment-results-${OrganizationList.get().activeOrg.name}.csv`;
        } else {
          filename = createFilename('results.csv', this.inputFileName);
        }
        return filename;
      }

      /**
       * @description Method to return the started date that should be displayed
       *   in the UI. If the job was started on the same day, then "today" is
       *   returned. If the job was started on the previous day, then "yesterday"
       *   is returned. Otherwise, the formatted date String is returned.
       *   Note: All values are translated prior to returning.
       * @returns {String} the translated started date that should be shown
       */
      getStarted() {
        const {timeStarted} = this;
        if (timeStarted) {
          const jobStarted = moment(timeStarted);
          if (jobStarted.isSame(TODAY, 'd')) {
            return $translate.instant('widgets.bulkOperations.results.date.today');
          } else if (jobStarted.isSame(YESTERDAY, 'd')) {
            return $translate.instant('widgets.bulkOperations.results.date.yesterday');
          }
          return $filter('date')(timeStarted, 'mediumDate');
        }
        return PLACEHOLDER_TEXT;
      }

      /**
       * @description Returns the translate key for this job's status (e.g.
       *   'jobs.status.completed' if the job is completed) or 'jobs.status.unknown'
       *   if the status is not recognised.
       * @returns {string} the translate key for this job's status
       */
      getStatusDisplayName() {
        const statusDisplayName = this.isCanceling()
          ? 'widgets.bulkOperations.status.canceling'
          : STATUS_TO_DISPLAY_NAME_MAP[this.status];
        return statusDisplayName || DEFAULT_STATUS_DISPLAY_NAME;
      }

      /**
       * @description Returns the time remaining for the job to complete in
       *   milliseconds
       * @returns {int} time remaining for the job to finish or null if one of
       *   the dependent variables is not defined.
       */
      getTimeRemaining() {
        const totalCount = _.get(this, 'itemSummary.totalCount');
        const processedCount = _.get(this, 'itemSummary.processedCount');
        const avgDuration = _.get(this, 'itemSummary.averageDurationMs');
        if (totalCount && processedCount && avgDuration) {
          return (totalCount - processedCount) * avgDuration;
        }
        return null;
      }

      /**
       * @description Returns true if this job is not executing or queued for
       *   execution. For example the job may have completed, been cancelled, or
       *   failed to execute in the first place due to some system error.
       * @returns {boolean} true if this job is not executing or queued for
       *   execution
       */
      hasTerminated() {
        return _.some(TERMINATED_JOB_STATUSES, (status) => status === this.status);
      }

      /**
       * @description Returns true if job was completed, but contains errors.
       *   Since the 'status' in this case is still COMPLETED, we need a way to
       *   encapsulate checking to see if there were any errors encountered
       *   while attempting to process the bulk operation.
       * @returns {Boolean} true if job completed with errors, else false
       */
      hasTerminatedWithErrors() {
        const failureCount = _.get(this, 'itemSummary.failureCount');
        return this.hasTerminated() && failureCount > 0;
      }

      /**
       * @description Returns true if this is a JIL anvil job, i.e. it is sourced
       *   from JIL rather than JEM.
       * @returns {boolean} true if this is a JIL anvil job
       */
      isAnvilJob() {
        return isSourcedFrom(JOB_SOURCE.ANVIL, this.id);
      }

      /**
       * @description Returns true if this job is cancelable, i.e. it is sourced
       *   from JIL, in progress and not already requested to be canceled.
       * @returns {boolean} true if this job is cancelable
       */
      isCancelable() {
        return this.isAnvilJob() && !this.hasTerminated() && !this.isCanceling();
      }

      /**
       * @description Returns true if this job is in the process of being canceled.
       *   Once the job has terminated this method will return false.
       * @returns {boolean} true if this job is in the process of being canceled.
       */
      isCanceling() {
        return this.cancelRequested && !this.hasTerminated();
      }

      /**
       * @description Method to refresh an existing Job with latest data from
       *   back-end data store.
       * @returns {Promise} Resolves with refreshed model if successful, else
       *   rejects with error message
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          jilJobs.jobs.get({jobId: this.id}, onSuccess.bind(this), onError.bind(this));

          function onError(response) {
            this.$resolved = true;
            reject(response);
          }

          function onSuccess(response) {
            _.assignIn(this, response);
            this.$resolved = true;
            resolve(this);
          }
        });
        return this.$promise;
      }

      /**
       * @description Creates and returns a Job instance from the specified JIL
       *   job JSON
       * @param {Object} rawJobJson - the JIL job JSON
       * @returns {Job} the created Job
       */
      static apiResponseTransformer(rawJobJson) {
        const transformedJob = new Job();
        return _.assignIn(transformedJob, rawJobJson);
      }

      /**
       * @description Fetches the Job with the specified ID.
       * @param {String} id - id of the job to fetch
       * @returns {Job} reference to fetched Job
       */
      static get(id) {
        const model = new Job({id});
        model.$promise = model.refresh();
        return model;
      }
    }

    return Job;

    ////////

    /**
     * @description Returns a filename that combines the original input file
     *   name as a prefix and a default file name as a suffix, or just the
     *   default name if no input file name is available.
     * @param {String} defaultFilename - the default file name suffix to use
     * @param {String} [inputFileName] - the input file name to use
     * @returns {String} the filename for the file to be exported.
     */
    function createFilename(defaultFilename, inputFileName) {
      let filename;
      if (inputFileName) {
        const filenamePrefix = _.replace(inputFileName, /\.csv$/i, '');
        if (filenamePrefix) {
          filename = `${filenamePrefix}-${defaultFilename}`;
        }
      }
      return filename || defaultFilename;
    }

    /**
     * @description Returns true if this job is from the specified source. A
     *   job's source is indicated by the suffix of its ID; for example a job
     *   with ID 1234@jil-anvil is from the JIL anvil system. ThejobSource
     *   parameter should be one of the JOB_SOURCE constant values.
     * @param {String} jobSource - the job source to check for
     * @param {String} id - the job id to check if sourced from
     * @returns {boolean} true if the job is from the specified source
     */
    function isSourcedFrom(jobSource, id) {
      return _.endsWith(id, `@${jobSource}`);
    }

    /**
     * @description Method to initialize the job source Object/constant.
     * @returns {Object} job source Object
     */
    function initJobSourceObj() {
      // These are the suffixes which a job ID can have, e.g. 1234@jil-anvil. The
      // suffix indicates which underlying system the job was sourced from.
      return {
        ANVIL: 'jil-anvil',
        JEM: 'jem-contract-migrations',
      };
    }

    /**
     * @description Method to initialize the operation display name map.
     * @returns {Object} initialized operation display name map
     */
    function initOperationDisplayNameMap() {
      return {
        [JOB_OPERATION.ADD_ENTERPRISE_USERS]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_ENTERPRISE_USERS_PLC]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_ENTERPRISE_USERS_UG]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_ORGANIZATION_USERS]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_ORGANIZATION_USERS_LG]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_ORGANIZATION_USERS_UG]: 'widgets.bulkOperations.operation.addUsers',
        [JOB_OPERATION.ADD_USER_GROUPS]: 'widgets.bulkOperations.operation.addUserGroups',
        [JOB_OPERATION.ASSIGN_LICENSES]: 'widgets.bulkOperations.operation.assignLicenses',
        [JOB_OPERATION.EDIT_ENTERPRISE_USERS]: 'widgets.bulkOperations.operation.editUserDetails',
        [JOB_OPERATION.EDIT_ORGANIZATION_USER_GROUPS]:
          'widgets.bulkOperations.operation.editUserGroups',
        [JOB_OPERATION.EDIT_ORGANIZATION_USERS]: 'widgets.bulkOperations.operation.editUserDetails',
        [JOB_OPERATION.OFFER_MIGRATION]: 'widgets.bulkOperations.operation.offerMigration',
        [JOB_OPERATION.REMOVE_ENTERPRISE_USERS]: 'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_DU]: 'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_PLC]: 'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_UG]: 'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ORGANIZATION_USERS]: 'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ORGANIZATION_USERS_LG]:
          'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REMOVE_ORGANIZATION_USERS_UG]:
          'widgets.bulkOperations.operation.removeUsers',
        [JOB_OPERATION.REVOKE_ENTERPRISE_INVITES]:
          'widgets.bulkOperations.operation.revokeInvitations',
        [JOB_OPERATION.SWITCH_ENTERPRISE_USERS]:
          'widgets.bulkOperations.operation.editIdentityType',
        [JOB_OPERATION.SWITCH_ORGANIZATION_USERS]:
          'widgets.bulkOperations.operation.editIdentityType',
      };
    }

    /**
     * @description Method to initialize the status display name map.
     * @returns {Object} initialized status display name map
     */
    function initStatusDisplayNameMap() {
      return {
        [JOB_STATUS.CANCELLED]: 'widgets.bulkOperations.status.cancelled',
        [JOB_STATUS.COMPLETED]: 'widgets.bulkOperations.status.completed',
        [JOB_STATUS.FAILED]: 'widgets.bulkOperations.status.failed',
        [JOB_STATUS.PROCESSING]: 'widgets.bulkOperations.status.processing',
        [JOB_STATUS.QUEUED]: 'widgets.bulkOperations.status.queued',
        [JOB_STATUS.REJECTED]: 'widgets.bulkOperations.status.rejected',
        [JOB_STATUS.UNKNOWN]: 'widgets.bulkOperations.status.unknown',
        [JOB_STATUS.UNKNOWN_JOB_TYPE]: 'widgets.bulkOperations.status.rejected',
      };
    }

    /**
     * @description Method to initialize terminated job statuses Array.
     * @return {Array} terminated job statuses Array
     */
    function initTerminatedJobStatusesArray() {
      return [
        JOB_STATUS.CANCELLED,
        JOB_STATUS.COMPLETED,
        JOB_STATUS.FAILED,
        JOB_STATUS.REJECTED,
        JOB_STATUS.UNKNOWN,
        JOB_STATUS.UNKNOWN_JOB_TYPE,
      ];
    }
  }
})();
/* eslint-enable */
