import SpectrumFolderIcon from '@spectrum-icons/workflow/Folder';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A lightened folder icon. Used in the storage workflows.
 */
const FolderIcon = ({'aria-label': ariaLabel, size = 'L'}) => (
  <SpectrumFolderIcon
    aria-label={ariaLabel}
    flex="none"
    size={size}
    UNSAFE_style={{color: 'var(--color-grey-300)'}}
  />
);

FolderIcon.propTypes = {
  /**
   * An optional aria-label to assign to the icon. By default icons are decorative and
   * are hidden from assistive technology.
   */
  'aria-label': PropTypes.string,
  /**
   * The size of the icon. The default is 'L' which is appropriate for a table.
   */
  size: PropTypes.oneOf(['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']),
};

export default FolderIcon;
