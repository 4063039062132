(function () {
  /**
   * @ngdoc module
   * @name app.widgets.integrations
   * @description module that defines integration widgets
   */
  angular.module('app.widgets.integrations', [
    'app.core.analytics',
    'app.core.organizations.integration-list',
    'app.core.product.configurations.integration-list',
    'app.widgets.users',
    'binky.shell.panels.drawer',
    'binky.shell.panels.modal',
    'binky.widgets.common.date-time',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.display-message',
    'binky.widgets.common.drawer-header.drawer-header-description',
    'binky.widgets.common.member.member-list-table',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.wait',
  ]);
})();
