(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AssetMigrationPolicy
   * @description Model for the Asset Migration Policy of this org
   */
  angular
    .module('app.core.asset-migration-policy')
    .factory('AssetMigrationPolicy', getAssetMigrationPolicyModel);

  /* @ngInject */
  function getAssetMigrationPolicyModel(
    $log,
    $q,
    $rootScope,
    _,
    jilAssetMigrationPolicy,
    MESSAGE,
    MODEL,
    modelCache
  ) {
    class AssetMigrationPolicy {
      /**
       * @description Creates a new AssetMigrationPolicy for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {MIGRATION_POLICY} options.name Name of the asset migration policy.
       */
      constructor(options = {}) {
        this.name = options.name;
        updateModel(this, options);
      }

      /**
       * @description Method to fetch asset migration policy status from back-end.
       * @returns {Promise} resolves to Asset Migration Policy on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = jilAssetMigrationPolicy.migrationPolicy
          .get({
            name: this.name,
          })
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess(response) {
          updateModel(this, response);
          modelCache.put(MODEL.ASSETMIGRATIONPOLICY, this, this.name);
          return this;
        }

        function onError(error) {
          // Set the value to be false if migration data is missing
          if (_.get(error, 'status') === 404) {
            updateModel(this, {
              policy: this.name,
              value: false,
            });
            modelCache.put(MODEL.ASSETMIGRATIONPOLICY, this, this.name);
            return this;
          }
          return $q.reject(error);
        }

        return this.$promise;
      }

      /**
       * @description Method to update migration policy.
       *
       * @param {Object} [options] - options to configure the asset migration policy.
       * @param {MIGRATION_VALUE} [options.value] - value of the asset migration policy, on or off.
       *
       * @returns {Promise} resolves if successfully update migration policy, else rejects with error message
       */
      update(options = {}) {
        this.$resolved = false;
        this.$promise = jilAssetMigrationPolicy.migrationPolicy
          .update({
            name: this.name,
            value: options.value,
          })
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(MESSAGE.UPDATE.ASSETMIGRATIONPOLICY, this);
          return this;
        }

        function onError(error) {
          $log.error('Failed to update asset migration policy Error: ', error);
          return $q.reject(error);
        }

        return this.$promise;
      }

      /**
       * @description Method to fetch the AssetMigrationPolicy. It will be refreshed on
       *     registering the active org
       * @param {Object} [options] - options to configure the asset migration policy.
       * @param {MIGRATION_POLICY} options.name Name of the asset migration policy.
       *
       * @returns {AssetMigrationPolicy} singleton AssetMigrationPolicy.
       *
       */
      static get(options = {}) {
        let model = new AssetMigrationPolicy(options);

        const cachedModel = modelCache.get(MODEL.ASSETMIGRATIONPOLICY, options.name);
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }

      /**
       * @class
       * @description Transforms the AssetMigrationPolicy response Object into a new
       *   AssetMigrationPolicy instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new AssetMigrationPolicy(dataTransferObject);
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes AssetMigrationPolicy data.
     *
     * @param {Object} model AssetMigrationPolicy model Object instance to initialize
     * @param {Object} options initialization object
     * @param {MIGRATION_POLICY} options.policy Policy of the asset migration policy. Ex: STUDENT_ASSET
     * @param {MIGRATION_VALUE} options.value Value of the asset migration policy, on or off.
     */
    function updateModel(model, options = {}) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick(['policy', 'value'])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      // setup model as already resolved which ensures it can be used
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    modelCache.register(MODEL.ASSETMIGRATIONPOLICY, 1);

    modelCache.removeAllOnEvent(MODEL.ASSETMIGRATIONPOLICY, [MESSAGE.UPDATE.ASSETMIGRATIONPOLICY]);

    return AssetMigrationPolicy;
  }
})();
