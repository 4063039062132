(function () {
  /**
   * @deprecated
   *
   * @ngdoc component
   * @name app.widgets.asset-sharing:appRequestAccessOptionsSelect
   * @description implements card button list to select asset request access level.
   *
   * @binding {AssetSharing} assetSharing. The asset sharing to be presented and updated
   * @binding {Boolean} canSetSharingOptions. Ability to change sharing configuration when true. Default is to restrict changing configurations
   * @binding {Function} selectRequestAccessOption. Function that getting called when user confirm to update request access option
   */
  angular.module('app.widgets.asset-sharing').component('appRequestAccessOptionsSelect', {
    bindings: {
      assetSharing: '<',
      canSetSharingOptions: '<?',
      selectRequestAccessOption: '=',
    },
    controller,
    templateUrl:
      'app/widgets/asset-sharing/request-access-options/request-access-options-select.component.html',
  });

  function controller(
    _,
    $translate,
    panelManager,
    REQUEST_ACCESS_CONFIRMATION_MODAL_ID,
    REQUEST_ACCESS_STATUS
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      getModalDescription,
      getModalTitle,
      onClickCancel,
      onClickConfirm,
      onClickRequestAccessOption,
    });

    //////////////

    function $onInit() {
      _.assign(vm, {
        REQUEST_ACCESS_CONFIRMATION_MODAL_ID,
        requestAccessOptions: getRequestAccessOptions(),
      });
    }

    function onClickCancel() {
      vm.assetSharing.requestAccessStatus = vm.assetSharing.savedState.requestAccessStatus;
    }

    function onClickConfirm() {
      vm.selectRequestAccessOption(vm.assetSharing.requestAccessStatus);
      panelManager.close(REQUEST_ACCESS_CONFIRMATION_MODAL_ID);
    }

    function onClickRequestAccessOption(option) {
      if (option !== vm.assetSharing.savedState.requestAccessStatus) {
        panelManager.open(REQUEST_ACCESS_CONFIRMATION_MODAL_ID);
      }
    }

    function getModalDescription() {
      return $translate.instant(
        vm.assetSharing.requestAccessStatus === REQUEST_ACCESS_STATUS.ALLOW
          ? 'widgets.assetSharing.sharingOptions.allowAccessRequests.description'
          : 'widgets.assetSharing.sharingOptions.noAccessRequests.description'
      );
    }

    function getModalTitle() {
      return $translate.instant(
        vm.assetSharing.requestAccessStatus === REQUEST_ACCESS_STATUS.ALLOW
          ? 'widgets.assetSharing.sharingOptions.allowAccessRequests.modal.title'
          : 'widgets.assetSharing.sharingOptions.noAccessRequests.modal.title'
      );
    }

    function getRequestAccessOptions() {
      const options = [REQUEST_ACCESS_STATUS.ALLOW, REQUEST_ACCESS_STATUS.DISALLOW];
      return _.map(options, (option) => ({
        id: option,
        translateKey: `.${_.camelCase(option)}`,
      }));
    }
  }
})();
