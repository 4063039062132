import {
  Content,
  ContextualHelp,
  Flex,
  Heading,
  IllustratedMessage,
  Link,
  Text,
  View,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * The IllustratedMessage to show when the Users table is in search-only mode.
 */
const SearchForUsersMessage = ({onPressLink}) => {
  const intl = useIntl();

  return (
    <View backgroundColor="gray-50" data-testid="search-for-users-message" paddingY="size-800">
      <IllustratedMessage>
        <Heading level="2">{intl.formatMessage({id: 'users.searchForUsersMessage.title'})}</Heading>
        <Content>
          <View>{intl.formatMessage({id: 'users.searchForUsersMessage.description1'})}</View>
          <View>{intl.formatMessage({id: 'users.searchForUsersMessage.description2'})}</View>
          <Flex
            alignItems="center"
            columnGap="size-50"
            justifyContent="center"
            marginTop="size-100"
          >
            <Link data-testid="view-users-link" onPress={onPressLink}>
              {intl.formatMessage({id: 'users.searchForUsersMessage.link'})}
            </Link>
            <ContextualHelp variant="info">
              <Heading>
                {intl.formatMessage({id: 'users.searchForUsersMessage.contextualHelp.heading'})}
              </Heading>
              <Content>
                <Text>
                  {intl.formatMessage({id: 'users.searchForUsersMessage.contextualHelp.content'})}
                </Text>
              </Content>
            </ContextualHelp>
          </Flex>
        </Content>
      </IllustratedMessage>
    </View>
  );
};

SearchForUsersMessage.propTypes = {
  /**
   * Called when the 'View all users' link is pressed. There are no parameters.
   */
  onPressLink: PropTypes.func.isRequired,
};

export default SearchForUsersMessage;
