/* eslint-disable max-lines */
(function () {
  const componentName = 'appUsersAll';
  /**
   * @deprecated use src2 Users pages -
   *   If enableSrc2 is permanently true, all methods may be removed.
   *   This binky-react-container shim may be needed if the route can't be feature flagged due to child state(s).
   *   This is the case with the `Products users` page which has a child state for the
   *   `Add products` modeal ('products.details.users.add-products').
   *
   * @ngdoc component
   * @name app.widgets.users:appUsersAll
   *
   * @description a widget containing a search box, table, and action buttons for user lists
   *
   * @param {PageContext} pageContext The page context object.
   * @param {Component} src2Page The React page/subpage to render rather than this Angular page.
   *   If this is set the only reqired param is PageContext. All others should be undefined.
   * @param {ProductConfiguration} productProfile The product configuration.
   * @param {MemberList} userList A list of users.
   * @param {Object} [userListDisplay] An object defining which columns of the table are enabled. See below.
   * @param {Boolean} [userListDisplay.accountStatus] The account status column.
   * @param {Boolean} [userListDisplay.adminRole] The admin role column.
   * @param {Boolean} [userListDisplay.checkbox] The checkbox column.
   * @param {Boolean} [userListDisplay.email] The email column.
   * @param {Boolean} [userListDisplay.idType] The id type column.
   * @param {Boolean} [userListDisplay.name] The name column.
   * @param {Boolean} [userListDisplay.productIcons] The product icons column.
   * @param {Boolean} [userListDisplay.productProfile] The product profile column.
   *
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      addUserModalName: '@?',
      disableSort: '<?',
      pageContext: '<',
      product: '<?',
      productProfile: '<?',
      src2Page: '<?',
      totalUserCount: '<?', // from either directory-users.routes.js or user.routes.js
      userGroup: '<?',
      userList: '<',
      userListDisplay: '<?',
    },
    controller,
    templateUrl: 'app/widgets/users/all/users-all.component.html',
  });

  /* eslint-disable max-statements */
  /* @ngInject */
  function controller(
    $element,
    $log,
    $rootScope,
    $q,
    $scope,
    $state,
    $timeout,
    $translate,
    _,
    addUser2GlobalModal,
    adminAccess, // needed only before refactor
    ADD_INTEGRATION_MODAL_ID,
    AnalyticsEvent,
    AnalyticsHelper,
    auth,
    binkyProductNameLabelFilter,
    binkySrc2,
    binkyUISrc2,
    bulkOperationLocalization,
    chat,
    DIRECTORY_USER_DETAILS_DRAWER_ID,
    downloadUtils,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    INTEGRATION_DETAILS_DRAWER_ID,
    jilAssetMigrations,
    MEMBER_EVENT,
    MESSAGE,
    onesieSrc2,
    OrganizationManager,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    panelManager,
    productAccess,
    PRODUCT_LIST_EVENT,
    removeOnReadOnly,
    REMOVE_DEVELOPER_MODAL_ID,
    REMOVE_USER_STORAGE_MODAL_ID,
    REMOVE_USER_STORAGE_OPTIONS,
    RESET_SEARCH_FILTER,
    Selection,
    storageAccess,
    TOAST_TYPES,
    toastManager,
    trialHelper,
    USER_DETAILS_DRAWER_ID,
    USER_GROUP_DETAILS_DRAWER_ID,
    USER_LIST_REFRESH,
    UserFolder,
    UserFolderList,
    UserRole,
    usersAllContext
  ) {
    const vm = this;
    const USERS_ALL_ID_PREFIX = `${componentName}-`;

    const {constructAsyncAddAssignUserAnalytics} = binkySrc2.utils.analyticsContextUtils;

    const deregisterHandles = [];
    _.assign(vm, {
      $onInit,
      AddAdminModal: onesieSrc2.users.components.AddAdminModal,
      adminRoles: [],
      adminRolesPromiseResolved: false, // remove with temp_parkour_mm
      binkyUISrc2,
      BulkOperationModal: onesieSrc2.bulkOperation.components.BulkOperationModal,
      canViewUserDetails,
      changeProductRoleAndToast,
      displayUserDetailDrawer,
      downloadAssetMigrations,
      downloadingAssetMigrationsEnabled: true,
      downloadingUsersEnabled: true,
      downloadLicenseDeficitReport,
      downloadLicenseStatusReport,
      downloadUsers,
      enableSrc2: !!vm.src2Page,
      feature,
      getDeveloperProducts,
      getStatusRegionContent,
      getTotalUserCount,
      getUserProducts,
      goToBulkOperationsResults,
      hasSearchQuery,
      isAddUserButtonDisabled,
      isAdminContext: vm.pageContext.isAdminTargetType(),
      isBulkAddUserButtonDisabled,
      isBulkUnassignUserButtonDisabled,
      isDeveloperContext: vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER,
      isDownloading: {licenseDeficitReport: false, licenseStatusReport: false},
      isIntegrationContext: _.get(vm, 'pageContext.targetType') === PAGE_TARGET_TYPE.INTEGRATION,
      isRemoveUserButtonDisabled,
      modalDisplayAnalyticsContext,
      onAddAdmin,
      onAddAdminModalClose,
      onAddAdminModalSuccess,
      onAddUser,
      onBulkOpModalCancel,
      onBulkOpModalSuccess,
      onesieSrc2,
      onSearch,
      onSelectProductAdminRole,
      onViewUserList,
      openAddLicensesModal,
      openAddUserModal,
      openBulkModal,
      openRemoveUsersModal,
      refreshProductRolesAndWait,
      refreshUserList,
      removeUsersAndToast,
      searchLabelId: _.uniqueId(USERS_ALL_ID_PREFIX),
      searchOnlyContentMode: false,
      showBulkProductRoleSwitcher,
      showButtonBar,
      showProductAdminRoleFilter,
      trialHelper,
      userIsEditable,
      userListSelection: new Selection('id'),
    });
    let isUpdateInProgress = false;

    const onMemberCreateOrUpdate = () => {
      if (!isUpdateInProgress) {
        refreshUserList();
      }
    };
    const UsersAllSpan = 'users-all';
    const {completeSpan, registerSpans, setCustomAttributes, startSpan} =
      binkySrc2.services.observability.observabilityMetrics;

    if (!vm.enableSrc2) {
      deregisterHandles.push(
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(MEMBER_EVENT.UPDATE, onMemberCreateOrUpdate)
      );

      deregisterHandles.push(
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(MEMBER_EVENT.CREATE, onMemberCreateOrUpdate)
      );

      if (vm.pageContext.target === PAGE_TARGET.PRODUCT) {
        deregisterHandles.push(
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(MESSAGE.UPDATE.PRODUCTUSERS.COUNT, () => {
            // the product details controller handles refreshing the model, so we
            // need to wrap this code in a timeout to allow that code to run first
            $timeout(onRefreshProductList);
          })
        );
        deregisterHandles.push(
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(PRODUCT_LIST_EVENT.REFRESH, onRefreshProductList)
        );
      }
      if (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
        deregisterHandles.push(
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(MESSAGE.UPDATE.CONFIGURATIONUSERS.COUNT, () => {
            vm.productProfile.product.refresh();
          })
        );
      }

      const $onDestroy = () => {
        _.invokeMap(deregisterHandles, _.call);
      };
      vm.$onDestroy = $onDestroy;
    }

    function $onInit() {
      // No need to do any setup for supported src2 pages.
      if (vm.enableSrc2) {
        return;
      }

      vm.contractList = OrganizationManager.getContractsForActiveOrg();

      if (feature.isEnabled('temp_observability')) {
        registerSpans([UsersAllSpan]);
        startSpan(UsersAllSpan);
      }

      vm.isAddAdminModalOpen = false;
      vm.orgId = OrganizationManager.getActiveOrgId();
      vm.isBulkOperationModalOpen = false;

      if (vm.isAdminContext) {
        if (feature.isEnabled('temp_parkour_mm') && vm.pageContext.target === PAGE_TARGET.PRODUCT) {
          vm.adminRoles = adminAccess.getManageableProductAdminRoles(vm.product);
        } else {
          vm.adminRolesPromise = adminAccess.getManageableAdminRoles().then((adminRoles) => {
            vm.adminRoles = adminRoles;
            if (feature.isDisabled('temp_parkour_mm')) {
              vm.adminRolesPromiseResolved = true;
            }
          });
        }
      }

      $scope.$on(RESET_SEARCH_FILTER, resetFilter);

      configureBasedOnUsersAllContext();
    }

    function canViewUserDetails() {
      return auth.canViewUserDetails() && !vm.pageContext.isDirectoryTarget();
    }

    function configureBasedOnUsersAllContext() {
      usersAllContext
        .initialize(vm.pageContext, getTargetObject())
        .then((result) => {
          vm.usersAllContext = result;

          configureDisplay();
          configureBulkOperations();
          configureOperations();
          configureSearchOnlyContentMode();
          vm.isButtonBarVisible = vm.areBulkOperationsVisible || vm.areOperationsVisible;
        })
        .catch((error) => {
          $log.error('Failed to init users all context', error);
        })
        .finally(() => {
          if (feature.isEnabled('temp_observability')) {
            // Set attribute before completing the span so it's included
            if (
              vm.pageContext.target === PAGE_TARGET.ORGANIZATION &&
              vm.pageContext.targetType === PAGE_TARGET_TYPE.USER
            ) {
              // Store at session level to be tracked for subsequent traces
              setCustomAttributes({orgUserCount: vm.getTotalUserCount()});
            }

            completeSpan(UsersAllSpan);
          }
        });

      function getTargetObject() {
        switch (vm.pageContext.target) {
          case PAGE_TARGET.PRODUCT:
            return vm.product;
          case PAGE_TARGET.PRODUCT_CONFIGURATION:
            return vm.productProfile;
          case PAGE_TARGET.USER_GROUP:
            return vm.userGroup;
          default:
            return undefined;
        }
      }
    }

    function configureBulkOperations() {
      const {bulkOperations} = vm.usersAllContext;
      vm.operations = {
        add: bulkOperations.canAdd,
        edit: bulkOperations.canEdit,
        export: bulkOperations.canExport,
        exportLicenseDeficitReport: bulkOperations.canExportLicenseDeficitReport,
        exportLicenseStatusReport: bulkOperations.canExportLicenseStatusReport,
        migrateUsers: bulkOperations.canMigrateUsers,
        migrations: bulkOperations.canDownloadAssetMigrations,
        remove: bulkOperations.canRemove,
        results: bulkOperations.canViewResults,
        switchIdentityType: bulkOperations.canSwitchIdentityType,
        unassign: bulkOperations.canUnassign,
      };
      // This needs to be revisited, we shouldn't have two ways to display export
      if (vm.pageContext.target === PAGE_TARGET.DIRECTORY && vm.operations.export) {
        vm.isExportButtonVisible = true;
        vm.operations.export = false;
      }
      vm.areBulkOperationsVisible = _.some(
        [
          vm.operations.add,
          vm.operations.edit,
          vm.operations.export,
          vm.operations.exportLicenseDeficitReport,
          vm.operations.exportLicenseStatusReport,
          vm.operations.migrateUsers,
          vm.operations.migrations,
          vm.operations.remove,
          vm.operations.results,
          vm.operations.switchIdentityType,
          vm.operations.unassign,
        ],
        (val) => val === true
      );
      vm.hideBulkOpsLinkContainer = !vm.areBulkOperationsVisible;
    }

    function configureDisplay() {
      const {display} = vm.usersAllContext;
      vm.hasLicenseGroupMemberRoles = display.showMemberRoles;
      if (vm.hasLicenseGroupMemberRoles) {
        vm.userListDisplay.productRole = display.memberRoleUserTypes;
        refreshProductRolesAndWait();
      } else if (display.showLegacyRoles) {
        vm.userListDisplay.productRole = true;
        vm.roles = display.legacyRoles;
      }
    }

    function configureOperations() {
      const {operations, operationStrings} = vm.usersAllContext;
      // string keys and messages
      vm.addKey = _.get(operationStrings, 'keys.add.button');
      vm.removeKeys = _.get(operationStrings, 'keys.remove');
      vm.modalContextName = operations.targetName;
      vm.noUserMessage = operationStrings.noUsers;
      // boolean flags
      vm.isAddUserButtonVisible = operations.canAddUsers;
      vm.isAddLicensesButtonVisible = operations.canAddLicenses;
      vm.isRemoveUserButtonVisible = operations.canRemoveUsers;
      vm.shouldOpenRemoveUserStorageModal = operations.removeUserStorage;
      vm.showSearchBar = operations.canSearch;
      vm.isButtonDisabledByGlobalAdmin = !_.get(operations, 'canManageAdmins');

      // This should be revisited to be made consistent with the other
      // bulk CSV actions when the temp_smb_reinvent_quick_assign flag is removed
      vm.canAddUsersByCSVPrimaryButton =
        vm.pageContext.target === PAGE_TARGET.ORGANIZATION &&
        vm.pageContext.targetType === PAGE_TARGET_TYPE.USER &&
        auth.isUserOrgAdmin() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic();

      if (
        vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION &&
        vm.pageContext.targetType === PAGE_TARGET_TYPE.INTEGRATION
      ) {
        vm.isAddIntegrationVisible = true;
      }

      vm.areOperationsVisible = _.some(
        [
          vm.isAddUserButtonVisible,
          vm.isAddLicensesButtonVisible,
          vm.isRemoveUserButtonVisible,
          vm.canAddUsersByCSVPrimaryButton,
        ],
        (val) => val === true
      );
    }

    // In this mode, the userList is initially freshly constructed and has
    // not been refreshed. It will be refreshed only after a search query
    // has been entered or the user explicitly chooses to show the whole list.
    function configureSearchOnlyContentMode() {
      const {searchOnlyContentMode} = vm.usersAllContext;

      vm.searchOnlyContentMode = !_.isEmpty(searchOnlyContentMode);
      if (vm.searchOnlyContentMode) {
        vm.binkySubPageWaitString = searchOnlyContentMode.binkySubPageWaitString;
        vm.searchInfoTooltipContent = searchOnlyContentMode.searchInfoTooltipContent;

        vm.userList.$promise = $q.resolve();
        vm.userList.$resolved = true;
      }
    }

    // If admin is authorized to view user details, open the user details drawer.
    function displayUserDetailDrawer(user) {
      if (auth.canViewUserDetails()) {
        if (user.getType().isTechnicalAccount() && vm.isIntegrationContext) {
          vm.selectedUser = _.cloneDeep(user);
          panelManager.open(INTEGRATION_DETAILS_DRAWER_ID);
        } else if (user.type === 'USER_GROUP') {
          vm.selectedUserGroup = user;
          panelManager.open(USER_GROUP_DETAILS_DRAWER_ID);
        } else if (vm.pageContext.isDirectoryTarget()) {
          vm.selectedUser = user;
          panelManager.open(DIRECTORY_USER_DETAILS_DRAWER_ID);
        } else {
          // Clone the user so that refreshing inside of the drawer does not remove license groups from the user
          // https://git.corp.adobe.com/admin-tribe/onesie/issues/1918
          vm.selectedUser = _.cloneDeep(user);
          panelManager.open(USER_DETAILS_DRAWER_ID);
        }
      }
    }

    function downloadUsers() {
      vm.downloadingUsersEnabled = false;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      vm.usersAllContext.bulkOperations.exportFunction().then(onSuccess).catch(onError);

      function onSuccess(response) {
        bulkOperationLocalization
          .localizeCsvFile(response.file)
          .then((localizedBlob) => {
            downloadUtils.download(localizedBlob, 'users.csv');
          })
          .catch((error) => {
            $log.error(`Error downloading localized CSV file`, error);
            toastManager.showErrorToast();
          })
          .finally(() => {
            vm.downloadingUsersEnabled = true;
            triggerAnalytics('downloadUsers');
          });
      }

      function onError() {
        vm.downloadingUsersEnabled = true;
        toastManager.showErrorToast();
      }
    }

    function downloadLicenseDeficitReport() {
      downloadCSV({
        analyticsKey: 'downloadLicenseDeficitReport',
        exportFunction: vm.usersAllContext.bulkOperations.exportLicenseDeficitReportFunction,
        filename: 'license-deficit-report.csv',
        isDownloadingKey: 'licenseDeficitReport',
      });
    }

    function downloadLicenseStatusReport() {
      downloadCSV({
        analyticsKey: 'downloadLicenseStatusReport',
        exportFunction: vm.usersAllContext.bulkOperations.exportLicenseStatusReportFunction,
        filename: 'license-status-report.csv',
        isDownloadingKey: 'licenseStatusReport',
      });
    }

    function downloadCSV(configs) {
      vm.isDownloading[configs.isDownloadingKey] = true;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      configs.exportFunction().then(onSuccess).catch(onError);

      function onSuccess(response) {
        vm.isDownloading[configs.isDownloadingKey] = false;
        downloadUtils.download(response.file, configs.filename);
        triggerAnalytics(configs.analyticsKey);
      }

      function onError() {
        vm.isDownloading[configs.isDownloadingKey] = false;
        toastManager.showErrorToast();
      }
    }

    function downloadAssetMigrations() {
      vm.downloadingAssetMigrationsEnabled = false;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      jilAssetMigrations.migrations.export({}, onSuccess.bind(this), onError.bind(this));

      function onSuccess(response) {
        vm.downloadingAssetMigrationsEnabled = true;
        downloadUtils.download(response.file, 'asset_migrations.csv');
        triggerAnalytics('downloadAssetMigrations');
      }

      function onError() {
        vm.downloadingAssetMigrationsEnabled = true;
        toastManager.showErrorToast();
      }
    }

    function getDeveloperProducts(user) {
      const productList = OrganizationManager.getProductsForActiveOrg();
      // Extract the products that the user has developer access to from the 'roles' field
      const developerProducts = _(user.roles)
        .filter({type: UserRole.LICENSE_DEV_ADMIN})
        .flatMap('targets')
        .groupBy('parentId')
        .map((lgArray) => ({
          id: lgArray[0].parentId,
        }))
        .value();

      // Get the Product object for each developer products to be passed to member list table for displaying icons.
      // If there is no match for the developer product in the product list, leave an undefined which will map to an
      // unknown product icon and tooltip.
      return _(developerProducts)
        .map((product) => _.find(productList.items, {id: product.id}))
        .sortBy((product) => binkyProductNameLabelFilter(product))
        .value();
    }

    function getStatusRegionContent() {
      const statusRegionTitle = $translate.instant('widgets.users.all.statusRegionTitle');
      return $translate.instant('widgets.users.all.statusRegionContent', {
        statusRegion: statusRegionTitle,
      });
    }

    function getTotalUserCount() {
      // If the route doesn't provide totalUserCount, get it from the resolved userList.
      return _.isUndefined(vm.totalUserCount) ? vm.userList.getTotalItemCount() : vm.totalUserCount;
    }

    function getUserProducts(user) {
      const productList = OrganizationManager.getProductsForActiveOrg();

      // Find the products that the user has. If there is no match for the user product in the product list, leave an
      // undefined which will map to an unknown product icon and tooltip.
      const products = _.map(user.products, (product) =>
        _.find(productList.items, {id: product.id})
      );

      // Identify which products are trials and add the iconTitle property to the product
      // so the "Free trial" tooltip will be shown for the product's icon
      _.forEach(products, (product) => {
        if (trialHelper.isTrialProduct(product)) {
          product.iconTitle = binkyProductNameLabelFilter(product, {isTrial: true});
        }
      });

      // For display, add in any organization consumables so that it appears like they are assigned to each user
      // even though they aren't in the user's products data.
      return OrganizationManager.getOrgConsumablesForActiveOrg().getProductDisplayList(products);
    }

    function goToBulkOperationsResults() {
      vm.usersAllContext.bulkOperations.viewResults();
    }

    function hasSearchQuery() {
      return _.get(vm.userList, 'filter.query', '') !== '';
    }

    function isAddUserButtonDisabled() {
      return (
        _.get(vm, 'usersAllContext.operations.needsLicensesToAddUser') ||
        vm.isButtonDisabledByGlobalAdmin ||
        (feature.isEnabled('temp_global_admin_user_groups') &&
          !_.get(vm, 'usersAllContext.operations.allowAddUsers'))
      );
    }

    function isBulkAddUserButtonDisabled() {
      return (
        _.get(vm, 'usersAllContext.bulkOperations.needsLicensesToAddUser') ||
        (feature.isEnabled('temp_global_admin_user_groups') &&
          !_.get(vm, 'usersAllContext.operations.allowAddUsers'))
      );
    }

    function isBulkUnassignUserButtonDisabled() {
      return (
        feature.isEnabled('temp_global_admin_user_groups') &&
        !_.get(vm, 'usersAllContext.operations.allowEditUsers')
      );
    }

    function isRemoveUserButtonDisabled() {
      return feature.isEnabled('temp_global_admin_user_groups')
        ? vm.isButtonDisabledByGlobalAdmin ||
            !_.get(vm, 'usersAllContext.operations.allowRemoveUsers')
        : vm.isButtonDisabledByGlobalAdmin;
    }

    function onAddAdmin() {
      resetFilter();
    }

    function onAddUser() {
      resetFilter();
      onAddUserByContext();
    }

    function onAddUserByContext() {
      if (vm.pageContext.targetType === PAGE_TARGET_TYPE.USER) {
        switch (vm.pageContext.target) {
          case PAGE_TARGET.PRODUCT:
          case PAGE_TARGET.PRODUCT_CONFIGURATION:
            refreshProductRoles();
            break;
          case PAGE_TARGET.USER_GROUP:
            toastManager.showToast(
              TOAST_TYPES.INFO,
              $translate.instant('widgets.users.all.addUser.userGroup.message', {
                name: vm.userGroup.name,
              })
            );
            break;
          default:
            break;
        }
      }
    }

    function onSearch(value) {
      if (value !== vm.userList.filter.query) {
        vm.userList.filter.query = value;

        // In searchOnly mode X'ing out the search should not refresh the list.
        if (!vm.searchOnlyContentMode || value !== '') {
          refreshUserList();
        }
      }
    }

    function onSelectProductAdminRole(value) {
      if (value !== vm.userList.filterOption) {
        vm.userList.filterOption = value;
        refreshUserList();
      }
    }

    // View the entire user list.
    function onViewUserList() {
      resetFilter();
      refreshUserList();
    }

    function openAddUserModal() {
      if (
        vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION &&
        vm.pageContext.targetType === PAGE_TARGET_TYPE.INTEGRATION
      ) {
        panelManager.open(ADD_INTEGRATION_MODAL_ID);
      } else if (
        // Use AddAdminModal when adding admin from user tab or prdocut detail page with product support admin available
        (vm.pageContext.target === PAGE_TARGET.ORGANIZATION ||
          (feature.isEnabled('temp_parkour_mm') &&
            vm.pageContext.target === PAGE_TARGET.PRODUCT &&
            vm.adminRoles.length > 1)) &&
        vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN
      ) {
        vm.isAddAdminModalOpen = true;
      } else {
        addUser2GlobalModal.open({
          licenseGroupId: _.get(vm.productProfile, 'id'),
          modalName: vm.addUserModalName,
          productId: _.get(vm.product, 'id'),
          userGroupId: _.get(vm.userGroup, 'id'),
        });
      }
    }

    function openAddLicensesModal() {
      AnalyticsHelper.dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'addLicenses',
      });

      if (onesieSrc2.core.products.access.canUseAddProductMiniAppForAll()) {
        productAccess.setAddProductUrl();
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.products.components.AddProductModalWrapper,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                chat,
                items: [
                  {
                    offerId: _.get(vm.product, 'offerId'),
                  },
                ],
                workflow: 'add-license',
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        throw new Error('Angular products has been removed');
      }
    }

    function openBulkModal(mode) {
      vm.bulkModalMode = mode;
      vm.isBulkOperationModalOpen = true;
    }

    function openRemoveUsersModal() {
      if (vm.isDeveloperContext) {
        panelManager.open(REMOVE_DEVELOPER_MODAL_ID);
      } else if (vm.shouldOpenRemoveUserStorageModal) {
        // Note: For react port replace call to 'canViewIndividualUserFolders' with call to 'canViewStorage' and deprecate
        // canViewIndividualUserFolders.
        storageAccess
          .canViewIndividualUserFolders()
          .then((result) => {
            if (result) {
              vm.userList.$promise = UserFolderList.getListByUsers({
                users: _.map(vm.userListSelection.items, 'id'),
              })
                // eslint-disable-next-line promise/no-nesting -- deprecated code
                .then((userStorageList) => {
                  if (userStorageList.length > 0 && !OrganizationManager.isActiveOrgEdu()) {
                    panelManager.open(REMOVE_USER_STORAGE_MODAL_ID, {
                      refreshOnSave: !vm.searchOnlyContentMode,
                      userStorageList,
                    });
                  } else {
                    vm.userStorageList = userStorageList;
                    panelManager.open('modal-remove-users');
                  }
                })
                // eslint-disable-next-line promise/no-nesting -- deprecated code
                .catch(() => {
                  toastManager.showErrorToast();
                });
            } else {
              panelManager.open('modal-remove-users');
            }
          })
          .catch(_.noop());
      } else {
        panelManager.open('modal-remove-users');
      }
    }

    function onRefreshProductList() {
      const productList = OrganizationManager.getProductsForActiveOrg();
      // since refresh could still be in progress, we need to key on the
      // $promise to schedule our product attribute refresh...
      productList.$promise
        .then(() => {
          vm.product = _.find(productList.items, {id: vm.product.id});
          // Reconfigure since a new product could change things
          configureBasedOnUsersAllContext();
        })
        .catch((error) => {
          $log.error(`Unable to refresh Products at this time. Error: ${error}`);
        });
    }

    function refreshUserList() {
      vm.userList
        .refresh()
        .then(refreshProductRoles)
        .then(() => {
          $scope.$broadcast(USER_LIST_REFRESH);
          if (vm.selectedUser) {
            vm.selectedUser = _.find(vm.userList.items, {id: vm.selectedUser.id});
            // Clone the user so that refreshing inside of the drawer does not remove license groups from the user
            // https://git.corp.adobe.com/admin-tribe/onesie/issues/1918
            if (vm.selectedUser) {
              vm.selectedUser = _.cloneDeep(vm.selectedUser);
            }
          }
        })
        .catch(() => {
          toastManager.showErrorToast();
        });
    }

    function removeUsersAndToast(users) {
      const usersRemovedCount = users.length;
      panelManager.close('modal-remove-users');
      vm.userList.remove(users);

      // If we're in searchOnlyContentMode, after removing the users we don't want save() to refresh
      // the list for us.
      const saveOptions = {
        refresh: !vm.searchOnlyContentMode,
        removePathOverride:
          _.size(vm.userStorageList) > 0
            ? UserFolder.getRemovePathOverride({
                type: REMOVE_USER_STORAGE_OPTIONS.DISCARD,
                userStorageList: vm.userStorageList,
              })
            : undefined,
      };

      // save() will trigger a MEMBER_EVENT.UPDATE event for every removed user.
      // Set this so our event handler does not call refreshUserList() for every removed user.
      // We will take care of the refresh or reset in the code path below.
      isUpdateInProgress = true;

      vm.userList
        .save(saveOptions)
        .then(refreshProductRolesIfNecessary)
        .then(resetListIfNecessary)
        .then(onRemoveUsersSuccess)
        .catch(onRemoveUsersError)
        .finally(() => {
          isUpdateInProgress = false;
        });

      function onRemoveUsersError(httpError) {
        const errorCode = _.get(httpError, 'data.errorCode');

        if (errorCode === 'CANNOT_REMOVE_CONTRACT_OWNER_FROM_ORG') {
          const removedOwner = _.find(users, (user) =>
            vm.contractList.isContractOwner({userId: user.id})
          );
          const name = _.result(removedOwner, 'getDisplayNameOrEmail');
          if (name) {
            toastManager.showErrorToast(
              $translate.instant('widgets.users.all.removeUsers.error.message', {name})
            );
          } else {
            // show the generic error message
            toastManager.showErrorToast();
          }
        } else if (errorCode === 'FORBIDDEN_BY_POLICY') {
          toastManager.showErrorToast(
            $translate.instant('widgets.users.all.removeUsers.error.forbiddenByPolicy')
          );
        } else {
          toastManager.showErrorToast();
        }
      }

      function onRemoveUsersSuccess() {
        resetFilter();
        toastManager.showSuccessToast();
        vm.userListSelection.deselectAllItems();
        $scope.$broadcast(USER_LIST_REFRESH);
        triggerAnalytics('removeUsers', {usersRemovedCount});
      }

      // If we didn't have save refresh the list or the list is empty, there is no need to refresh the product roles.
      function refreshProductRolesIfNecessary() {
        return saveOptions.refresh === false || vm.userList.items.length === 0
          ? $q.resolve()
          : refreshProductRoles();
      }

      // If we didn't have save refresh the list, it means we want to reset it here.
      function resetListIfNecessary() {
        return saveOptions.refresh === false ? vm.userList.reset() : $q.resolve(vm.userList);
      }
    }

    function changeProductRoleAndToast(selectedProductRole, selectedItems) {
      const usersChangedCount = selectedItems.length;
      if (vm.hasLicenseGroupMemberRoles) {
        _.forEach(selectedItems, (item) => {
          vm.memberRoles.memberRoles[item.id] = [selectedProductRole];
        });
        vm.memberRoles.save().then(onSuccess).catch(onError);
      } else {
        vm.userList
          .setMemberProductRole(selectedItems, {id: selectedProductRole})
          .then(onSuccess)
          .catch(onError);
      }

      function onError(error) {
        $log.error(error);
        toastManager.showErrorToast();
        if (vm.hasLicenseGroupMemberRoles) {
          refreshProductRoles();
        } else {
          vm.userList.refresh();
        }
      }

      function onSuccess() {
        resetFilter();
        toastManager.showSuccessToast();
        vm.userListSelection.deselectAllItems();
        $scope.$broadcast(USER_LIST_REFRESH);

        if (vm.hasLicenseGroupMemberRoles) {
          refreshProductRoles();
        } else {
          vm.userList.refresh();
        }
        triggerAnalytics('changeProductRole', {selectedProductRole, usersChangedCount});
      }
    }

    function refreshProductRoles() {
      if (vm.usersAllContext.display.showMemberRoles) {
        return vm.usersAllContext.display.fetchMemberRoles(vm.userList).then((memberRoles) => {
          vm.memberRoles = memberRoles;
          if (vm.memberRoles.description || vm.memberRoles.learnMoreHref) {
            vm.userListDisplay.productRole.tooltip = {
              content: vm.memberRoles.description,
              learnMore: vm.memberRoles.learnMoreHref,
            };
          } else {
            vm.userListDisplay.productRole.tooltip = undefined;
          }
          vm.roles = _.sortBy(vm.memberRoles.availableRoles, ['name']);
          _.forEach(vm.userList.items, (user) => {
            const foundRole = vm.memberRoles.memberRoles[user.id];
            if (!_.isEmpty(foundRole)) {
              user.productRole = _.find(vm.roles, {id: foundRole[0]});
            }
          });
        });
      }
      return $q.resolve();
    }

    function refreshProductRolesAndWait() {
      vm.waitOnPromise = vm.userList.$promise
        .then(() => refreshProductRoles())
        .finally(() => {
          delete vm.waitOnPromise;
        });
    }

    function resetFilter() {
      vm.userList.filter.query = '';
      $element.find('binky-search').find('input').val('');
    }

    function showButtonBar() {
      const isNotReadOnly =
        vm.pageContext.target === PAGE_TARGET.DIRECTORY
          ? !removeOnReadOnly.isReadOnlyMigratingOrHasTerms()
          : !removeOnReadOnly.isReadOnly();

      return isNotReadOnly && vm.isButtonBarVisible;
    }

    function showProductAdminRoleFilter() {
      return (
        feature.isEnabled('temp_parkour_mm') &&
        vm.isAdminContext &&
        !vm.productProfile &&
        _.invoke(vm.product, 'isProductSupportRoleAssignmentAllowed')
      );
    }

    function showProductRoleSwitcher() {
      return (
        (vm.pageContext.targetType === PAGE_TARGET_TYPE.USER ||
          vm.pageContext.targetType === PAGE_TARGET_TYPE.INTEGRATION) &&
        vm.userListDisplay &&
        vm.userListDisplay.productRole
      );
    }

    function showBulkProductRoleSwitcher() {
      return (
        showProductRoleSwitcher() &&
        // we don't yet support multi-switch for license group member roles due to Sign not supporting it
        !vm.hasLicenseGroupMemberRoles &&
        vm.userListSelection.count > 0
      );
    }

    // eslint-disable-next-line complexity
    function userIsEditable(user) {
      // Since the component has multiple uses with different types of user models
      // we're using _.result to have a default false value if the function
      // is not defined. and to prevent a TypeError
      const userIsExternallyManaged = _.result(user, 'isExternallyManaged', false);

      // Not all user models have an isRemovable function and even so it may return undefined
      // if the particular list doesn't return the property.
      let userIsRemovable = true;
      if (_.result(user, 'isRemovable') !== undefined) {
        userIsRemovable = user.isRemovable();
      }

      if (user.getType().isTechnicalAccount() && !vm.isIntegrationContext) {
        return false;
      } else if (vm.pageContext.isOrganizationTarget()) {
        // users can't remove themselves from the org, or top level admin roles, or externally managed users/marked as not removable
        return auth.getUserId() !== user.id && userIsRemovable;
      } else if (vm.pageContext.isAdminTargetType()) {
        // only org admins can remove themselves from other roles
        // but the remaining types can remove users who aren't the current admin
        return auth.isUserOrgAdmin() || auth.getUserId() !== user.id;
      } else if (vm.pageContext.target === PAGE_TARGET.USER_GROUP) {
        // if it's a group and it's externally managed users can not be removed
        return !vm.userGroup.isExternallyManaged();
      } else if (vm.pageContext.target === PAGE_TARGET.DIRECTORY) {
        // an externally managed user is editable only if the account is disabled
        // for other type of users allow editing
        return userIsExternallyManaged && feature.isEnabled('temp_azure_idp')
          ? user.isDisabled()
          : !userIsExternallyManaged;
      }

      // if it's a user-group/product/profile assignment, any admin can remove a user
      return true;
    }

    ////////////

    function onAddAdminModalClose() {
      vm.isAddAdminModalOpen = false;
      $rootScope.$apply();
    }

    function onAddAdminModalSuccess() {
      onAddAdmin();
      onAddAdminModalClose();
    }

    function onBulkOpModalCancel() {
      vm.isBulkOperationModalOpen = false;
      $rootScope.$apply();
    }

    function onBulkOpModalSuccess() {
      vm.isBulkOperationModalOpen = false;
      $rootScope.$apply();
    }

    function triggerAnalytics(componentMethod, attributes = {}) {
      if (vm.product) {
        attributes.productOffer = _.get(vm.product, 'offerId');
      } else if (vm.productProfile) {
        attributes.productOffer = _.get(vm.productProfile, 'product.offerId');
      }
      AnalyticsEvent.dispatch({
        attributes,
        componentMethod: vm.isIntegrationContext ? 'removeIntegrations' : componentMethod,
        componentMethodType: 'submit',
        componentName,
        pageContext: vm.pageContext,
      });
    }

    function modalDisplayAnalyticsContext() {
      return constructAsyncAddAssignUserAnalytics({orgId: vm.orgId});
    }
  }
  /* eslint-enable max-statements */
})();
/* eslint-enable max-lines */
