import {jilDirectories} from '@admin-tribe/binky';
import {
  GoUrl,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/binky-ui';
import {Flex, Heading, Switch} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';

const TeacherAccessPolicySection = ({setIsLoading}) => {
  const intl = useIntl();
  const {directory, reloadDirectory} = useDirectoryDetailsContext();

  const canManageIdentityConfig = canChangeIdentityConfig();

  const onToggle = async (newValue) => {
    setIsLoading(true);

    try {
      const policies = {...directory.policies};
      if (newValue) {
        delete policies.POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE;
      } else {
        policies.POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE = 'true';
      }

      await jilDirectories.updateDirectory({
        directoryData: {
          policies,
        },
        directoryId: directory.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      await reloadDirectory();

      showSuccessToast(
        intl.formatMessage({
          id: newValue
            ? 'settings.directorySettingsModal.teacherAccessPolicySection.toasts.successEnabled'
            : 'settings.directorySettingsModal.teacherAccessPolicySection.toasts.successDisabled',
        })
      );
    } catch {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.directorySettingsModal.teacherAccessPolicySection.heading" />
      </Heading>
      <Flex alignItems="center" gap="size-200">
        {/* FormattedMessage renders translated string and goUrl as siblings. keep them under one parent <span> for correct flex spacing */}
        <span>
          <FormattedMessage
            id="settings.directorySettingsModal.teacherAccessPolicySection.description"
            values={{
              goUrl:
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                // istanbul ignore next -- not testing this -- goUrl is in the snapshot
                (url) => <GoUrl name="ac_teacher_access_learn">{url}</GoUrl>,
            }}
          />
        </span>

        <Switch
          isDisabled={!canManageIdentityConfig}
          isEmphasized
          // policy is "block" but toggle label is "allow"
          isSelected={!directory.isPolicyEnabled('POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE')}
          marginEnd={0}
          onChange={onToggle}
        />
        {!canManageIdentityConfig && <GlobalAdminPolicyActionDisabled />}
      </Flex>
    </>
  );
};

TeacherAccessPolicySection.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
export default TeacherAccessPolicySection;
