import React from 'react';
import {useLoaderData} from 'react-router-dom';

import ProductPage from 'features/products/pages/product-page/ProductPage';
import SharedDeviceLicenceProductPage from 'features/products/pages/shared-device-license-product-page/SharedDeviceLicenceProductPage';

// ProductPageManager renders the respective product type page.
const ProductPageManager = () => {
  const {product} = useLoaderData();

  if (product === undefined) {
    return null;
  }

  if (product.isSharedDeviceLicense()) {
    return <SharedDeviceLicenceProductPage product={product} />;
  }

  // Render the default product page
  return <ProductPage />;
};

export default ProductPageManager;
