import {
  LICENSE_DEV_ADMIN,
  OrganizationAdminList,
  OrganizationUserListCount,
  UserGroupList,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canViewAdmins} from 'core/admin/access/adminAccess';
import {canViewDevelopers} from 'core/developer/access/developerAccess';
import {canViewUsers} from 'core/user/access/userAccess';
import {canViewUserGroups} from 'core/user-group/access/userGroupAccess';

const USER_TYPE = {
  ADMINISTRATORS: 'administrators',
  DEVELOPERS: 'developers',
  USER_GROUPS: 'userGroups',
  USERS: 'users',
};

async function getAdminQuantity() {
  if (!canShowUserType(USER_TYPE.ADMINISTRATORS)) {
    return null;
  }

  const adminList = await OrganizationAdminList.get({
    includeAllAdminRoles: true,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return adminList.getTotalItemCount();
}

async function getUserQuantity() {
  if (!canShowUserType(USER_TYPE.USERS)) {
    return null;
  }

  const orgUserListCount = await OrganizationUserListCount.get({
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return orgUserListCount.totalCount;
}

async function getUserGroupQuantity() {
  if (!canShowUserType(USER_TYPE.USER_GROUPS)) {
    return null;
  }

  const userGroupList = await UserGroupList.get({
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return userGroupList.pagination.itemCount;
}

async function getDeveloperQuantity() {
  if (!canShowUserType(USER_TYPE.DEVELOPERS)) {
    return null;
  }

  const devList = await OrganizationAdminList.get({
    orgId: rootStore.organizationStore.activeOrgId,
    pageSize: 1,
    role: LICENSE_DEV_ADMIN,
  });

  return devList.getTotalItemCount();
}

/**
 *
 * @returns {Array<number | null>} n-length array containing user counts for each type
 * or null if the API fails, or the user type cannot be viewed
 */
async function getUserCounts() {
  const counts = await Promise.allSettled([
    getUserQuantity(),
    getAdminQuantity(),
    getDeveloperQuantity(),
    getUserGroupQuantity(),
  ]);

  // any list that return error should set count to null
  return counts.map((promise) => (promise.status === 'fulfilled' ? promise.value : null));
}

/**
 *
 * @param {String} type  type of user, see USER_TYPE for values
 * @returns
 */
function canShowUserType(type) {
  switch (type) {
    case USER_TYPE.USERS:
      return canViewUsers();
    case USER_TYPE.ADMINISTRATORS:
      return canViewAdmins();
    case USER_TYPE.DEVELOPERS:
      return canViewDevelopers();
    case USER_TYPE.USER_GROUPS:
      return canViewUserGroups();
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore  -- unreachable as we cover every enum value
    // istanbul ignore next
    default:
      return false;
  }
}

export {USER_TYPE, canShowUserType, getUserCounts};
