import {log} from '@admin-tribe/binky';
import {DATE_FORMATS} from '@admin-tribe/binky-ui';
import {DeviceAction} from '@pandora/react-data-source-daco';

/**
 * @description A function to determine whether the Product is FRL Online only
 * @param {Array<FulfillableItem>} productFulfillableItemList - list of fulfillable items
 * @returns {Boolean}
 */
const isProductFRLOnlineOnly = (productFulfillableItemList) =>
  productFulfillableItemList.hasPackagePreconditioningForFRLOnline() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLOffline() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLLan() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLIsolated();

/**
 * A method to determine if either totalLicensesCount or usedLicensesCount is valid
 * @param {Object} options
 * @param {Number}  options.totalLicensesCount - total licenses count
 * @param {Number}  options.usedLicensesCount - total used licenses count
 * @returns whether either totalLicensesCount or usedLicensesCount is valid
 */
const areCountsNotValid = ({totalLicensesCount, usedLicensesCount}) =>
  !Number.isInteger(totalLicensesCount) || !Number.isInteger(usedLicensesCount);

/**
 * @description A function to determine whether the Product is FRL Offline only
 * @param {Array<FulfillableItem>} productFulfillableItemList - list of fulfillable items
 * @returns {Boolean}
 */
const isProductFRLOfflineOnly = (productFulfillableItemList) =>
  productFulfillableItemList.hasPackagePreconditioningForFRLOffline() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLOnline() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLLan() &&
  !productFulfillableItemList.hasPackagePreconditioningForFRLIsolated();

/**
 * @description A function to check whether licenses quota has been reached. In case when either total licenses or used licenses
 *              are null or undefined or not integer, it returns true
 * @param {Number} totalLicensesCount - total number of licenses
 * @param {Number} usedLicensesCount - total number of used licenses
 * @return {Boolean} whether licenses quota has been reached
 */
const hasReachedLicensesQuota = ({totalLicensesCount, usedLicensesCount}) =>
  areCountsNotValid({totalLicensesCount, usedLicensesCount}) ||
  totalLicensesCount <= usedLicensesCount;

/**
 * @description A function to check whether licenses quota has been exceeded. In case when either total licenses or used licenses
 *              are null or undefined or not integer, it returns true
 * @param {Number} totalLicensesCount - total number of licenses
 * @param {Number} usedLicensesCount - total number of used licenses
 * @return {Boolean} whether licenses quota has been reached
 */
const hasExceedLicensesQuota = ({totalLicensesCount, usedLicensesCount}) =>
  areCountsNotValid({totalLicensesCount, usedLicensesCount}) ||
  totalLicensesCount < usedLicensesCount;

/**
 * @description A function to check whether activation should be allowed
 * @param {Boolean} isOverDeploymentAllowed - whether over-deployment is allowed
 * @param {Number} totalLicensesCount - total number of licenses
 * @param {Number} usedLicensesCount - total number of used licenses
 * @returns {Boolean} whether activation should be allowed
 */
const isActivationAllowed = ({
  isOverDeploymentAllowed,
  totalLicensesCount,
  usedLicensesCount,
  selectedBlockedDevice = 0,
}) => {
  if (selectedBlockedDevice) {
    return (
      isOverDeploymentAllowed ||
      !hasExceedLicensesQuota({
        totalLicensesCount,
        usedLicensesCount: usedLicensesCount + selectedBlockedDevice,
      })
    );
  }
  return (
    isOverDeploymentAllowed || !hasReachedLicensesQuota({totalLicensesCount, usedLicensesCount})
  );
};

/**
 * @description Native function to replace and avoid using lodash/isEmpty
 * @param {Object} obj - given object
 * @returns {Boolean} whether the object is empty
 */
const isEmpty = (obj = {}) => Object.entries(obj).length === 0;

const UPDATE_ACTIONS = [DeviceAction.ACTIVATE, DeviceAction.BLOCK];
/**
 * @description Determine if the banner should be displayed
 * @param {Number} usedLicensesCount - total number of used licenses
 * @param {Number} totalLicensesCount - total number of assignable licenses
 * @returns {Boolean} - whether to show the banner
 */
const isShowBanner = ({isOverDeploymentAllowed, usedLicensesCount, totalLicensesCount}) =>
  !isActivationAllowed({isOverDeploymentAllowed, totalLicensesCount, usedLicensesCount});

/**
 * @description Retrieve the total number of devices and used licenses
 * @param {Boolean} isLoading - whether the fetches of device details and activated device details are still loading
 * @param {Object} devices - details of all devices
 * @param {Object} activatedDevices - Object composed of the following details of activated devices
 * @returns {Object} result Object composed of the total number of devices and used licenses
 *          {Number} result.totalDevicesCount - the number of devices
 *          {Number} result.usedLicensesCount - the number of used licenses
 */
const getTotalDevicesAndUsedLicenses = ({isLoading, devices, activatedDevices}) => {
  let totalDevicesCount, usedLicensesCount;
  if (!isLoading && !isEmpty(devices) && !isEmpty(activatedDevices)) {
    totalDevicesCount = devices.data?.search?.total;
    usedLicensesCount = activatedDevices.data?.search?.total;
  }
  return {totalDevicesCount, usedLicensesCount};
};

/**
 * @description Get the earliest end date of a product
 * @param {Product} product - a Product instance
 * @param {Object} intl - react-intl
 * @return {String} formatted string of earliest end date
 */
const getEarliestEndDate = ({product, intl}) => {
  // As a product can be associated with multiple contracts. There will be different end dates for each contract.
  // As a temporary solution until there is an official decision, the earliest end date will be displayed.
  // To prevent discrepancy regarding unit test results in between jidoka:analysis pipeline and local machine,
  // milliseconds are used for earliest end date
  const earliestEndDateInMilliseconds = new Date(
    product.licenseTupleList.getEarliestEndDate()
  ).getTime();
  return earliestEndDateInMilliseconds
    ? intl.formatDate(earliestEndDateInMilliseconds, DATE_FORMATS.default)
    : undefined;
};

/**
 * @description Retrieve data for FRL view header
 * @param {Object} options - Initialization Object (params described below)
 * @param {Boolean} options.isLoading - whether device data is still loading
 * @param {Object} options.devices - Details of all devices
 * @param {Object} options.activatedDevices - Details of activated devices
 * @param {Product} options.product - A Product instance
 * @param {Object} options.intl - react-intl
 * @returns {Object} result - Object composed of the following the details of the FRL view page header
 *          {String} result.earliestExpiryDate - earliest end date
 *          {Boolean} result.areScorecardValuesAvailable - whether all score card values are available
 *          {Boolean} result.showBanner - whether to show a banner
 *          {Number} result.totalDevicesCount - the number of devices
 *          {Number} result.totalLicensesCount - total number of licenses
 *          {Number} result.usedLicensesCount- total number of used licenses count
 */
const getViewHeaderData = ({isLoading, devices, activatedDevices, product, intl}) => {
  const {totalDevicesCount, usedLicensesCount} = getTotalDevicesAndUsedLicenses({
    activatedDevices,
    devices,
    isLoading,
  });
  const totalLicensesCount = product.getAssignableLicenseCount();
  const earliestExpiryDate = getEarliestEndDate({intl, product});
  const showBanner = isShowBanner({
    isOverDeploymentAllowed: product.fulfillableItemList.hasOverdelegationAllowed(),
    totalLicensesCount,
    usedLicensesCount,
  });
  return {
    earliestExpiryDate,
    showBanner,
    totalDevicesCount,
    totalLicensesCount,
    usedLicensesCount,
  };
};

/**
 * @description Method to activate, deactivate, remove a device
 * @param {String} deviceDetailsNamespace - namespace of the device detail
 * @param {Function} deviceOperation - given operation which can be Activation, Deactivation, or Removal
 * @param {Object} deviceOperationOptions - options for deviceOperation function
 * @param {Object} intl - react-intl
 */
const handleDeviceAction = async ({
  deviceDetailsNamespace,
  deviceOperation,
  deviceOperationOptions,
  intl,
  onDeviceUpdatedSuccess,
  onDeviceUpdatedError,
}) => {
  const {action, deviceDetails: deviceDetailsList} = deviceOperationOptions;
  const actionLowerCase = action ? action.toLowerCase() : DeviceAction.REMOVE.toLowerCase();

  const errorMessage = intl.formatMessage(
    {
      id: `${deviceDetailsNamespace}.action.errorMessage.${actionLowerCase}`,
    },
    {deviceCount: deviceDetailsList.length}
  );

  try {
    const response = await deviceOperation(deviceOperationOptions);
    const responseJsonData = await response?.json();
    const {deviceStatusUpdatedCount, removedDevicesCount} = responseJsonData;
    let successMessage;
    if (UPDATE_ACTIONS.includes(action)) {
      successMessage = intl.formatMessage(
        {
          id: `${deviceDetailsNamespace}.action.${actionLowerCase}.successMessage`,
        },
        {deviceCount: deviceStatusUpdatedCount}
      );
    } else {
      successMessage = intl.formatMessage(
        {id: `${deviceDetailsNamespace}.action.remove.successMessage`},
        {deviceCount: removedDevicesCount}
      );
    }
    onDeviceUpdatedSuccess?.(responseJsonData.timestamp, successMessage);
  } catch (error) {
    log.error(error);
    onDeviceUpdatedError?.(errorMessage);
  }
};

/**
 * @description A function to retrieve the total number of used licenses of FRL Isolated
 * @param {Product} product - Given Product instance
 * @returns {Number} - total number of used licenses of FRL Isolated
 */
const getFRLIsolatedUsedLicensesCount = (product) => {
  if (!product.licenseActivation) {
    return 0;
  }
  return product.licenseActivation.isolatedActivationsCount;
};

/**
 * @description A function to retrieve the total number of used licenses of FRL LAN
 * @param {Product} product - Given Product instance
 * @returns {Number} - total number of used licenses of FRL LAN
 */
const getFRLLanUsedLicensesCount = (product) => {
  if (!product.licenseActivation) {
    return 0;
  }
  return product.licenseActivation.lanActivationsCount ?? 0; // temporary null check until JIL response includes lanActivationsCount
};

/**
 * @description A function to retrieve the total number of used licenses of of combined FRL Isolated and LAN
 * @param {Product} product - Given Product instance
 * @returns {Number} - total number of used licenses of combined FRL Isolated and LAN
 */
const getNonFRLOnlineUsedLicenses = (product) =>
  getFRLIsolatedUsedLicensesCount(product) + getFRLLanUsedLicensesCount(product);

/**
 * Polls the Device GraphQL API till eventTimestamp of devices is given timestamp
 * @param {options} polling options
 * @param {options.devices} array of devices
 * @param {options.getDeviceDetails} promise that fetches the data
 * @param {options.options} options for the getDeviceDetails function
 * @param {options.pollingInterval} time duration between calls
 * @param {options.maxTries} number of times to poll
 * @returns {Promise} - that will resolve if the poller has completed the ticks, or reject if any error
 */
const pollDevicesApi = ({
  devices,
  getDeviceDetails,
  options,
  timestamp,
  pollingInterval = 1000,
  // The CAL api workers sleep for max 30 seconds if there are no jobs.
  // We are using 31 seconds to bypass that.
  maxTries = 31,
}) => {
  let currentMaxTries = 0;
  let poller;

  const orString = `[${devices.reduce(
    (previousValue, currentValue) =>
      `${previousValue} {deviceId: {eq: "${currentValue.deviceId}"}}`,
    ''
  )}]`;

  const promisedTimestamp = new Date(timestamp).toISOString();

  const performTimeCheck = async (resolve, reject) => {
    try {
      const devicesData = await getDeviceDetails({
        ...options,
        or: orString,
      });

      const json = await devicesData.json();

      currentMaxTries += 1;

      /**
       * Method that checks that all queried devices are updated, by looking at timestamp
       * @param {Devices} updatedDevices
       * @returns {Boolean}
       */
      const hasAllUpdated = (updatedDevices) =>
        updatedDevices.every(
          (device) => new Date(device.eventTimestamp).toISOString() === promisedTimestamp
        );

      // If all queried devices were updated with the timestamp
      if (hasAllUpdated(json.data.search.data)) {
        clearInterval(poller);
        return resolve();
      }

      if (currentMaxTries > maxTries) {
        clearInterval(poller);
        const retryError = new Error('Timeout on trying to poll devices');
        log.error(retryError);
        return reject(retryError);
      }
    } catch (error) {
      clearInterval(poller);
      reject(error);
    }

    return null;
  };

  return new Promise((resolve, reject) => {
    poller = setInterval(() => performTimeCheck(resolve, reject), pollingInterval);
  });
};

export {
  getFRLIsolatedUsedLicensesCount,
  getFRLLanUsedLicensesCount,
  getNonFRLOnlineUsedLicenses,
  isProductFRLOfflineOnly,
  isProductFRLOnlineOnly,
  handleDeviceAction,
  hasExceedLicensesQuota,
  hasReachedLicensesQuota,
  getViewHeaderData,
  isEmpty,
  isActivationAllowed,
  pollDevicesApi,
};
