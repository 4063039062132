import {Product} from '@admin-tribe/binky';
import {DATE_FORMATS, TableSectionTable, getProductDisplayName} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * @description Table for displaying the usage list for a product that has group
 *   consumable transactions
 * @param {Product} product - Object to build table names (rows) from
 */
const TransactionsListTable = ({product}) => {
  const intl = useIntl();

  const columnDescriptor = [
    {
      key: 'product',
    },
    {
      key: 'transactionsUsed',
      props: {align: 'end'},
    },
    {
      key: 'expirationDate',
      props: {allowsSorting: true},
    },
  ];

  // The render function will be called with an object which contains the 'item' which is the
  // ConsumableUsage item and the column 'key'.
  const renderers = {
    expirationDate: ({item: {expirationDate}}) =>
      intl.formatDate(expirationDate, DATE_FORMATS.default),
    product: () => getProductDisplayName(intl, product),
    transactionsUsed: ({item: {consumedQuantity, totalQuantity}}) =>
      intl.formatMessage(
        {id: 'products.groups.transactions.list.table.usage'},
        {
          total: totalQuantity,
          used: consumedQuantity,
        }
      ),
  };

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'products.groups.transactions.list.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.groups.transactions.list.table.column"
      renderers={renderers}
    />
  );
};

TransactionsListTable.propTypes = {
  /**
   * Product to build table row names from
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

TransactionsListTable.displayName = 'TransactionsListTable';
export default TransactionsListTable;
