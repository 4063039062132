import {Product} from '@admin-tribe/binky';
import {StatusLightWithContextualHelp} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import NoAccessDrawerContainer from './NoAccessDrawerContainer';
import UserProvisioningStatusConstants from './UserProvisioningStatusConstants';

/**
 * Determines the appropriate rendering based on the user provisioning status.
 *
 * @param {Object} props - The properties for rendering.
 * @param {string} props.provisioningStatus - The user provisioning status.
 * @param {Object} props.product - The product information.
 * @param {boolean} props.showEmDashWhenNoStatus - Flag indicating whether to show an em dash when there is no status.
 * @returns {JSX.Element} - The JSX element for rendering based on the provisioning status.
 */
const UserProvisioningStatus = ({product, provisioningStatus, showEmDashWhenNoStatus}) => {
  const intl = useIntl();
  switch (provisioningStatus) {
    case UserProvisioningStatusConstants.PROVISIONED:
      return (
        <StatusLightWithContextualHelp
          hasContextualHelp={false}
          statusLightProps={{variant: 'positive'}}
          statusLightText={intl.formatMessage({id: 'common.userProvisioningStatus.provisioned'})}
        />
      );
    case UserProvisioningStatusConstants.UNPROVISIONED:
      return (
        <NoAccessDrawerContainer
          hasContextualHelp
          product={product}
          statusLightProps={{variant: 'negative'}}
        />
      );
    default:
      if (showEmDashWhenNoStatus)
        return <div>{UserProvisioningStatusConstants.RENDERED_BINKY_UI_DEFAULT}</div>;
      return <div />;
  }
};

UserProvisioningStatus.propTypes = {
  product: PropTypes.instanceOf(Product).isRequired,
  provisioningStatus: PropTypes.string,
  showEmDashWhenNoStatus: PropTypes.bool,
};

export default UserProvisioningStatus;
