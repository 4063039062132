/* eslint-disable max-lines -- over line limit */
import {feature} from '@admin-tribe/binky';
import {WIZARD_DISPATCH_ACTION, translateString} from '@admin-tribe/binky-ui';
import cloneDeep from 'lodash/cloneDeep';
import isMatch from 'lodash/isMatch';
import {action, computed, makeObservable, observable} from 'mobx';

import {isMacTypePlatform} from '../adminToolsUtils';
import {
  CREATE_PACKAGE,
  CREATE_PACKAGE_CONSTANTS,
  CREATE_PACKAGE_MODAL_ID,
  PACKAGE_FLOWS,
  PACKAGE_FLOWS_V2,
  PACKAGE_TYPE_CONSTANTS,
  PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS,
  SCREEN_NAME_CONSTANTS,
} from '../packagesConstants';
import CreatePackageFactory from '../services/CreatePackageService';
import PackagesEntitlements from '../services/PackagesEntitlementsService';
import PackagesExtensions from '../services/PackagesExtensionsService';
import PackagesIngestAnalytics from '../services/PackagesIngestAnalyticsService';
import PackagesPreferences from '../services/PackagesPreferencesService';
import {getTemplateType} from '../services/createPackageServiceUtils';

import {
  FRL_PACKAGE_CREATE_OBJ,
  INGEST_ANALYTICS_VARS,
  addValuesToPkgSessionsSetting,
  getAdobeProductUIKeyMap,
  getNextButtonStatusForAppsScreen,
  getNextButtonStatusForEntitlementsScreen,
  getPackageFlow,
  getPackageJobFrlObject,
  getPackageParameters,
  getPackageTypeForLightweightPackages,
  getPkgTypeAndTriggerIngestAnalytics,
  getPluginsData,
  getSelectedAdobeProductIDs,
  getStoreValuesForFlowPkgCreate,
  getStoreValuesForTempPkgCreate,
  initializePackageCreateObj,
  isPackageNameValid,
  postPackageCreationRequest,
  sendIngestAnalytics,
  sendIngestAnalyticsForCreatePackage,
  shouldPopulatePackageJobFrl,
} from './createPackageModalStoreUtils';

// Create package modal store is the shared state of create package flow wizards and screens
class CreatePackageModalStore {
  addedFiles = [];
  // Add products to the selected products list
  addProducts = (products) => {
    this.selectedProducts.splice(1, 0, ...products);
  };

  adobeProductsUI = [];
  // App Filters on Choose Apps/ Product selection screen
  appFilters = {
    searchText: '',
    showArchived: false,
    showBeta: false,
    showLatest: true,
    showLTS: false,
    showOlder: false,
    showPreRelease: false,
  };

  challengeCodeList = [{code: '', codeInput: '', id: 0, isValid: true}];
  fileChallengeCodeList = [];
  PACKAGE_FLOWS = cloneDeep(PACKAGE_FLOWS);
  packageCreateObj = {
    packageName: '',
  };

  // By default, Package type flow wizard starts at screen state 2, required to deduce steps of Package type flow wizard
  packageTypeFlowWizardStartScreenState = 2;
  // Initializing packageTypeSelectionScreenState to 0, tracks the step we are at in Package Type Selection Wizard
  packageTypeSelectionScreenState = 0;
  // Initializing packageTypeSelectionWizardSteps, which is a list of screens to be shown in Package Type Selection Wizard,
  // this gets updated based on choice made at each step of Package Type Selection Wizard
  packageTypeSelectionWizardSteps = [SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE];

  pkgSessionSettings = {
    frlIsolatedMachineCodes: [],
    nextButtonEnabled: true,
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    resetCreatePackageData: true,
    selectedFrlOffers: [],
    showAccInUI: true,
    showCreatePackageUI: false,
    showExtraProductsInTemplates: true,
  };

  // Remove all products starting after productsToRemain from selected products list
  removeAllProducts = (productsToRemain) => {
    this.selectedProducts.splice(productsToRemain, this.selectedProducts.length - productsToRemain);
  };

  // Remove product from the selected products list
  removeProduct = (product) => {
    this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
  };

  // To track the screen currently shown, this.pkgSessionSettings.currentPackagingFlow[this.screenstate] gives current screen
  screenstate = 0;
  selectedProducts = [];
  // Search text in Selected Products List
  selectedProductsSearchText = '';
  // PackageTypeSelectionWizard or PackageTypeFlowWizard shown based on showPackageTypeSelectionWizard
  showPackageTypeSelectionWizard = true;
  // the screen state the create package flow starts at
  startingScreenState = 0;

  constructor() {
    makeObservable(this, {
      addChallengeCode: action.bound,
      addedFiles: observable,
      addFile: action.bound,
      addProducts: action.bound,
      adobeProductsUI: observable,
      appFilters: observable,
      challengeCodeList: observable,
      clearPackageCreateObjAndPkgSessionSetting: action.bound,
      fileChallengeCodeList: observable,
      gotoNextScreen: action.bound,
      gotoPreviousScreen: action.bound,
      initialize: action.bound,
      isCreatePackageButtonVisible: computed,
      isFrlPackageWorkflow: computed,
      isNamedPackageWorkflow: computed,
      isPackageTypeSelectionWizard: computed,
      isPreviousButtonVisible: computed,
      PACKAGE_FLOWS: observable,
      packageCreateObj: observable,
      packageTypeFlowWizardStartScreenState: observable,
      packageTypeSelectionScreenState: observable,
      packageTypeSelectionWizardSteps: observable,
      pkgSessionSettings: observable,
      removeAllProducts: action.bound,
      removeFile: action.bound,
      removeProduct: action.bound,
      restoreLastState: action.bound,
      screenstate: observable,
      selectedProducts: observable,
      selectedProductsSearchText: observable,
      setAdobeProducts: action.bound,
      setAppFilters: action.bound,
      setChallengeCodeList: action.bound,
      setFileChallengeCodeList: action.bound,
      setPackageCreateObjValues: action.bound,
      setPackageParameters: action.bound,
      setPkgSessionSettingValues: action.bound,
      setScreenStateForCreatePkgFlow: action.bound,
      setScreenStateForTempatlizedPkgCreate: action.bound,
      setSelectedProductsSearchText: action.bound,
      setStoreValuesforCreatePackageOpen: action.bound,
      showPackageTypeSelectionWizard: observable,
      startingScreenState: observable,
      updateCreatePackageButtonStatus: action.bound,
      updateNextButtonEnableStatus: action.bound,
    });
    this.showPackageTypeSelectionWizard = true;
  }

  addChallengeCode(activationCode) {
    this.challengeCodeList.push(activationCode);
  }

  addFile(file) {
    this.addedFiles.push(file);
  }

  clearPackageCreateObjAndPkgSessionSetting() {
    this.packageCreateObj = {};
    this.pkgSessionSettings = {};
  }

  // eslint-disable-next-line complexity -- feature flag checks, will be fixed after FF removal
  createPackage() {
    if (this.isDcOnlyEntitlement) {
      this.setAdobeProducts();
    }

    const {containsArchived, selectedAdobeProductIDs} = getSelectedAdobeProductIDs(
      this.adobeProductsUI,
      this.pkgSessionSettings.packagingMode
    );
    // save this set to selectedAdobeProductIds array in packageCreateObj
    this.packageCreateObj.selectedAdobeProductIds = [...selectedAdobeProductIDs];

    this.setPackageParameters();

    sendIngestAnalyticsForCreatePackage(
      this.pkgSessionSettings.packageType,
      this.pkgSessionSettings.packagingMode,
      this.packageCreateObj.selectedAdobeProductIds
    );
    if (
      this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE ||
      this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE
    ) {
      this.setLicensingParameters();
    }

    // set plugin data if plugins were selected
    if (this.pkgSessionSettings.selectedPlugins?.length > 0) {
      this.packageCreateObj.pluginDownloadUrlApi = PackagesExtensions.getExtensionsDownloadUrl();
      this.packageCreateObj.plugins = getPluginsData(this.pkgSessionSettings.selectedPlugins);
    }

    // In case of lightweight packages update pacakge type and accDisabled parameter
    if (
      this.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
      this.selectedProducts.length === 0
    ) {
      this.packageCreateObj.packageType = getPackageTypeForLightweightPackages(
        this.pkgSessionSettings,
        this.packageCreateObj
      );
      this.packageCreateObj.accDisabled = true;
      if (this.packageCreateObj.packageType === PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY) {
        this.packageCreateObj.rumEnabled = false;
      }
    }
    const isLicenseOnlyPackage = () =>
      this.pkgSessionSettings.packagingMode === CREATE_PACKAGE.MODE.FRL &&
      this.selectedProducts.length === 0 &&
      !this.packageCreateObj.ausstOverride &&
      !this.packageCreateObj.rumEnabled &&
      !this.packageCreateObj.enableExtenstions;

    // In case of License only package, set flatPackageEnabled to false for mac platforms
    if (
      feature.isEnabled('temp_packages_flat_templates') &&
      isLicenseOnlyPackage() &&
      isMacTypePlatform(this.packageCreateObj.platform)
    ) {
      this.packageCreateObj.flatPackageEnabled = false;
    }

    this.packageCreateObj.packageName = this.packageCreateObj.packageName.trim();

    if (feature.isEnabled('temp_packages_products_count')) {
      this.packageCreateObj.productsCount = this.getPackagedProductCount();
    }

    postPackageCreationRequest(containsArchived, this.packageCreateObj, this.pkgSessionSettings);
    this.clearPackageCreateObjAndPkgSessionSetting();
    CreatePackageFactory.closeCreatePackage();
  }

  /**
   * Method to get selected products count including CCD in create package flow
   * @returns {int} products count
   */
  getPackagedProductCount() {
    const productsCount = this.selectedProducts.length;
    if (
      productsCount === 0 &&
      this.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE
    ) {
      // In case of selfService package, selectedProducts length is 0 but we bundle CCD in it so returning CCD count here.
      return 1;
    }
    return productsCount;
  }

  // When next or create package button clicked on create package modal
  gotoNextScreen(dispatch, closeCreatePackageModal) {
    if (this.isCreatePackageButtonVisible) {
      // Create package btn pressed
      this.createPackage();
      closeCreatePackageModal();
    } else {
      // Next Btn pressed
      const flow = getPackageFlow(
        this.isDcOnlyEntitlement,
        this.isNonCcOnlyEntitlement,
        this.pkgSessionSettings.packagingMode,
        this.pkgSessionSettings.packageType
      );
      this.pkgSessionSettings.currentPackagingFlow = this.PACKAGE_FLOWS[flow];
      if (this.pkgSessionSettings.currentScreen === SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN) {
        // Product selection screen appears after system config screen and so,
        // based on platform selection products have to be updated if required
        this.restoreLastState();
      }
      if (this.isNamedPackageWorkflow && this.isDcOnlyEntitlement && this.screenstate === 0) {
        // In case of named packaging mode and isDconlyEntitlement true, packageType set to Managed
        // so system config should be the screen after 0th screen,
        // so updating screen state to 2
        if (feature.isEnabled('temp_packages_acrobat_only_flow')) {
          this.packageCreateObj.packageType = CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE;
          this.pkgSessionSettings.packageType = CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE;
          this.pkgSessionSettings.showAccInUI = false;
        } else {
          this.packageCreateObj.packageType = CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE;
        }
        this.screenstate = 2;
      } else {
        // else, increment screen state by 1
        this.screenstate += 1;
      }
      this.setCurrentScreen();
      this.updateNextButtonEnableStatus();
      if (
        this.isCreatePackageButtonVisible &&
        (this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE ||
          this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE)
      ) {
        this.sendIngestAnalyticsForManagementOptions();
      }
      this.setWizardStateForNextScreen(dispatch);
    }
  }

  // When previous button clicked on create package modal
  gotoPreviousScreen(dispatch) {
    // When org has DC only entitlement, package type is set to Managed PACKAGE and
    // so, CHOOSE_PACKAGE_TYPE screen should not be shown in named package flow hence setting screenstate to 0
    if (
      (feature.isEnabled('temp_packages_acrobat_only_flow') &&
        this.pkgSessionSettings.currentScreen === SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN &&
        this.isDcOnlyEntitlement &&
        this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE) ||
      (feature.isDisabled('temp_packages_acrobat_only_flow') &&
        this.pkgSessionSettings.currentScreen === SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN &&
        this.isDcOnlyEntitlement)
    ) {
      this.screenstate = 0;
    } else {
      this.screenstate -= 1;
    }
    this.pkgSessionSettings.currentScreen =
      this.pkgSessionSettings.currentPackagingFlow[this.screenstate];
    this.updateNextButtonEnableStatus();
    this.setWizardStateForPrevScreen(dispatch);
  }

  // This function initializes all required values for the create package flow
  initialize() {
    this.createPackageModalId = CREATE_PACKAGE_MODAL_ID;
    this.showPackageTypeSelectionWizard = true;
    this.packageTypeSelectionScreenState = 0;
    this.packageTypeSelectionWizardSteps = [SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE];
    this.modalTitle = '';
    PackagesEntitlements.resetOffers();
    this.PACKAGE_FLOWS = cloneDeep(PACKAGE_FLOWS_V2);
    if (!PackagesExtensions.getExtensionsDownloadUrl()) {
      // If extensions url has not been fetched, plugins screen should not be shown
      Object.keys(this.PACKAGE_FLOWS).forEach((flow) => {
        this.PACKAGE_FLOWS[flow] = this.PACKAGE_FLOWS[flow].filter(
          (screen) => screen !== SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN
        );
      });
    }
    // Default selection to NAMED_PACKAGE_FLOW
    this.pkgSessionSettings.currentPackagingFlow = this.PACKAGE_FLOWS.NAMED_PACKAGE_FLOW;
    this.pkgSessionSettings.currentScreen = this.pkgSessionSettings.currentPackagingFlow[0];
    delete this.pkgSessionSettings.deviceInformationShared;
    this.pkgSessionSettings = {
      ...this.pkgSessionSettings,
      ...addValuesToPkgSessionsSetting(),
    };
    this.packageCreateObj = initializePackageCreateObj(this.packageCreateObj.packageType);
    if (CreatePackageFactory.isTemplatizedPackageCreate()) {
      this.packageCreateObj.templateType = getTemplateType(
        CreatePackageFactory.isNonCCTemplate(),
        true
      );
    }

    this.isDcOnlyEntitlement = PackagesEntitlements.hasDcOnlyEntitlement;
    this.isNonCcOnlyEntitlement = PackagesEntitlements.hasNonCcOnlyEntitlement;
    this.selectedProducts = [];
    this.productCategories = [];
    this.adobeProductsUIKeyMap = {};
    this.adobeProductsUI = [];
    this.lastSavedSettings = {};
    this.fileChallengeCodeList = [];
    this.pkgSessionSettings.frlIsolatedMachineCodes = [];
    this.addedFiles = [];
    this.setChallengeCodeList([]);
    this.addChallengeCode({
      code: '',
      codeInput: '',
      id: 0,
      isValid: true,
    });
    this.setAppFilters();
    this.setSelectedProductsSearchText();
  }

  /**
   * @description Method to check if the create package button should be visible on the create package wizard. It
   * should only be visible on the last screens of every workflow
   *
   * @returns {Boolean} whether previous button should be shown or not.
   */
  get isCreatePackageButtonVisible() {
    return (
      this.pkgSessionSettings.currentScreen === SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN ||
      this.pkgSessionSettings.currentScreen === SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN
    );
  }

  /**
   * @description method to identify if package is getting created in FRL workflow
   *
   * @returns {Boolean} whether package os getting created in FRL workflow
   */
  get isFrlPackageWorkflow() {
    return this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE;
  }

  /**
   * @description method to identify if package is getting created in Named workflow
   *
   * @returns {Boolean} whether package os getting created in Named workflow
   */
  get isNamedPackageWorkflow() {
    return this.pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE;
  }

  get isPackageTypeSelectionWizard() {
    return this.showPackageTypeSelectionWizard;
  }

  get isPreviousButtonVisible() {
    return this.startingScreenState !== this.screenstate;
  }

  removeFile(file) {
    this.addedFiles = this.addedFiles.filter((item) => item !== file);
  }

  // To store required settings and set adobe products and reset selected plugins appropriately
  restoreLastState() {
    this.setAdobeProducts(true);
    this.lastSavedSettings.platform = this.packageCreateObj.platform;
    this.lastSavedSettings.bits = this.packageCreateObj.bits;
    this.lastSavedSettings.locale = this.packageCreateObj.locale;
    this.lastSavedSettings.packageType = this.packageCreateObj.packageType;
  }

  sendIngestAnalyticsForManagementOptions() {
    INGEST_ANALYTICS_VARS.forEach((ingestAnalyticsVar) => {
      if (this.packageCreateObj[ingestAnalyticsVar]) {
        sendIngestAnalytics(ingestAnalyticsVar, this.pkgSessionSettings.packageType);
      }
    });
  }

  /**
   * @description configure the right set of adobe products based on workflow, platform and license
   * @param {boolean} resetPlugins Indicates if plugins should be reset when settings change. Defaults to false.
   * Also configure which products would be visible and selected automatically
   */
  setAdobeProducts(resetPlugins = false) {
    if (!this.pkgSessionSettings.resetCreatePackageData) {
      if (
        isMatch(this.lastSavedSettings, {
          bits: this.packageCreateObj.bits,
          locale: this.packageCreateObj.locale,
          packageType: this.packageCreateObj.packageType,
          platform: this.packageCreateObj.platform,
        })
      ) {
        return;
      }
    }

    if (resetPlugins) {
      this.pkgSessionSettings.selectedPlugins = [];
    }

    if (!this.pkgSessionSettings.isTemplatizedPackageCreate && !this.isDcOnlyEntitlement) {
      this.packageCreateObj.packageName = '';
      this.updateCreatePackageButtonStatus();
    }
    const {adobeProductsUI, adobeProductsUIKeyMap, selectedProducts} = getAdobeProductUIKeyMap(
      this.packageCreateObj.bits,
      this.packageCreateObj.platform,
      this.pkgSessionSettings
    );
    this.adobeProductsUI = adobeProductsUI;
    this.adobeProductsUIKeyMap = adobeProductsUIKeyMap;
    this.selectedProducts = selectedProducts;

    this.pkgSessionSettings.resetCreatePackageData = false;
  }

  setAppFilters(
    updatedFilters = {
      searchText: '',
      showArchived: false,
      showBeta: false,
      showLatest: true,
      showLTS: false,
      showOlder: false,
      showPreRelease: false,
    }
  ) {
    this.appFilters = updatedFilters;
  }

  setChallengeCodeList(challengeCodeList) {
    this.challengeCodeList = challengeCodeList;
  }

  setCurrentScreen() {
    this.pkgSessionSettings.currentScreen =
      this.pkgSessionSettings.currentPackagingFlow[this.screenstate];
    if (
      this.isNamedPackageWorkflow &&
      this.packageCreateObj.packageType === CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE &&
      this.screenstate === 2
    ) {
      this.pkgSessionSettings.currentScreen = SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN;
    }
  }

  setFileChallengeCodeList(fileChallengeCodeList) {
    this.fileChallengeCodeList = fileChallengeCodeList;
  }

  setLicensingParameters() {
    this.packageCreateObj.packageType = getPkgTypeAndTriggerIngestAnalytics(
      this.pkgSessionSettings.packageType
    );

    if (this.isFrlPackageWorkflow) {
      this.packageCreateObj = {...this.packageCreateObj, ...FRL_PACKAGE_CREATE_OBJ};
    }

    // we have to include FRL params for FRL license only package and FRL apps+license package
    if (shouldPopulatePackageJobFrl(this.pkgSessionSettings)) {
      this.packageCreateObj.packageJobFrl = getPackageJobFrlObject(this.pkgSessionSettings);
    }
  }

  setPackageCreateObjValues(key, value) {
    this.packageCreateObj[key] = value;
  }

  /**
   * @description set various configuration parameters of the package.
   */
  setPackageParameters() {
    this.packageCreateObj = {
      ...this.packageCreateObj,
      ...getPackageParameters(this.pkgSessionSettings),
    };

    if (
      feature.isDisabled('packages_disable_update_option') ||
      !CreatePackageFactory.isNonCCTemplate()
    ) {
      this.packageCreateObj.updatesEnabled =
        this.packageCreateObj.appPanelEnabled && this.packageCreateObj.accAdminPrivileged;
    }

    this.packageCreateObj.ausstFallbackEnabled = PackagesPreferences.ausstFallbackEnabled;
    if (this.packageCreateObj.ausstOverride) {
      this.packageCreateObj.ausstOverride = PackagesPreferences.getAusstXml(
        this.packageCreateObj.platform
      );
    } else {
      delete this.packageCreateObj.ausstOverride;
    }
    delete this.packageCreateObj.enableAALExtension;

    if (this.packageCreateObj.userInstallDirectory) {
      this.packageCreateObj.userInstallDirectory = PackagesPreferences.getUserInstallDirectory(
        this.packageCreateObj.platform
      );
    } else {
      delete this.packageCreateObj.userInstallDirectory;
    }
  }

  setPkgSessionSettingValues(key, value) {
    this.pkgSessionSettings[key] = value;
  }

  setScreenStateForCreatePkgFlow() {
    const {
      packageCreateObj,
      packageTypeSelectionWizardSteps,
      pkgSessionSettings,
      screenstate,
      showPackageTypeSelectionWizard,
    } = getStoreValuesForFlowPkgCreate(this.pkgSessionSettings);
    this.modalTitle = translateString({id: 'packages.createPackageButtonLabel'});
    this.packageCreateObj = {...this.packageCreateObj, ...packageCreateObj};
    this.packageTypeSelectionWizardSteps = packageTypeSelectionWizardSteps;
    this.pkgSessionSettings = {...this.pkgSessionSettings, ...pkgSessionSettings};
    this.screenstate = screenstate;
    this.showPackageTypeSelectionWizard = showPackageTypeSelectionWizard;
    const flow = getPackageFlow(
      this.isDcOnlyEntitlement,
      this.isNonCcOnlyEntitlement,
      this.pkgSessionSettings.packagingMode,
      this.pkgSessionSettings.packageType
    );
    this.pkgSessionSettings.currentPackagingFlow = this.PACKAGE_FLOWS[flow];
  }

  // Sets screen state for templatized package create flow
  setScreenStateForTempatlizedPkgCreate() {
    if (
      (feature.isEnabled('temp_packages_acrobat_only_flow') &&
        !CreatePackageFactory.isAccRequiredInTemplateView() &&
        !CreatePackageFactory.isAcrobatOnlyTemplate()) ||
      (feature.isDisabled('temp_packages_acrobat_only_flow') &&
        !CreatePackageFactory.isAccRequiredInTemplateView())
    ) {
      // Remove plugin selection screen from flow for non-CC template
      this.PACKAGE_FLOWS.NAMED_PACKAGE_FLOW = this.PACKAGE_FLOWS.NAMED_PACKAGE_FLOW.filter(
        (screen) => screen !== SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN
      );
      this.pkgSessionSettings.currentPackagingFlow = this.PACKAGE_FLOWS.NAMED_PACKAGE_FLOW;
    }
    const {
      modalTitle,
      packageCreateObj,
      packageTypeSelectionWizardSteps,
      pkgSessionSettings,
      screenstate,
      showPackageTypeSelectionWizard,
    } = getStoreValuesForTempPkgCreate();
    this.modalTitle = modalTitle;
    this.packageCreateObj = {...this.packageCreateObj, ...packageCreateObj};
    this.packageTypeSelectionWizardSteps = packageTypeSelectionWizardSteps;
    this.pkgSessionSettings = {...this.pkgSessionSettings, ...pkgSessionSettings};
    this.screenstate = screenstate;
    this.showPackageTypeSelectionWizard = showPackageTypeSelectionWizard;

    this.updateCreatePackageButtonStatus();
  }

  setSelectedProductsSearchText(searchText = '') {
    this.selectedProductsSearchText = searchText;
  }

  // Sets initial values, screen and flow
  setStoreValuesforCreatePackageOpen() {
    this.pkgSessionSettings.showCreatePackageUI = true;
    this.pkgSessionSettings.showAccInUI = true;
    this.pkgSessionSettings.showExtraProductsInTemplates = true;
    this.pkgSessionSettings.resetCreatePackageData = true;
    this.pkgSessionSettings.isManagementOptionsSwitchSelected = false;
    PackagesIngestAnalytics.triggerAnalytics({
      subCategory: 'none',
      subType: 'create-package',
      type: 'click',
    });
    this.initialize();
    if (PackagesEntitlements.hasFrlEntitlement) {
      this.pkgSessionSettings.hasFrlEntitlement = CreatePackageFactory.hasApplicableFrlOffers();
    }
    // set template data if its a templatized workflow
    if (CreatePackageFactory.isTemplatizedPackageCreate()) {
      this.setScreenStateForTempatlizedPkgCreate();
    } else {
      this.setScreenStateForCreatePkgFlow();
    }
    // If package type selection wizard is hidden, then update package type flow wizard start screen state
    if (!this.showPackageTypeSelectionWizard) {
      this.packageTypeFlowWizardStartScreenState = this.screenstate;
    }
    this.startingScreenState = this.screenstate;

    if (
      feature.isEnabled('temp_packages_flat_templates') &&
      CreatePackageFactory.isTemplatizedPackageCreate() &&
      isMacTypePlatform(CreatePackageFactory.getPackageTemplate().platform)
    ) {
      this.setPackageCreateObjValues('flatPackageEnabled', true);
    }
  }

  setWizardStateForNextScreen(dispatch) {
    const isPackageTypeSelectionWizardStep = PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS.includes(
      this.pkgSessionSettings.currentScreen
    );
    // If current screen part of package type selection wizard and package type selection wizard is being shown currently
    // Increment packageTypeSelectionScreenState and call dispatch to move to next screen in package type selection wizard
    if (isPackageTypeSelectionWizardStep && this.isPackageTypeSelectionWizard) {
      this.packageTypeSelectionScreenState += 1;
      this.packageTypeSelectionWizardSteps[this.packageTypeSelectionScreenState] =
        this.pkgSessionSettings.currentScreen;
      dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
    }

    // If current screen not part of package type selection wizard and package type selection wizard is not being shown currently
    // call dispatch to move to next screen in package type wizard
    if (!isPackageTypeSelectionWizardStep && !this.isPackageTypeSelectionWizard) {
      dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
    }

    if (!isPackageTypeSelectionWizardStep && this.isPackageTypeSelectionWizard) {
      // Set app filters and selected products search text to default value when wizard is changed
      this.setAppFilters();
      this.setSelectedProductsSearchText();
      // Hide package type selection wizard as currentScreen is not part of package type selection wizard steps
      this.showPackageTypeSelectionWizard = false;

      // Tracking the start screen state of package type flow wizard
      this.packageTypeFlowWizardStartScreenState = this.screenstate;
    }
  }

  setWizardStateForPrevScreen(dispatch) {
    if (this.isPackageTypeSelectionWizard) {
      this.packageTypeSelectionWizardSteps.splice(this.packageTypeSelectionScreenState, 1);
      this.packageTypeSelectionScreenState -= 1;
      dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
    } else if (
      PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS.includes(this.pkgSessionSettings.currentScreen)
    ) {
      // Set app filters and selected products search text to default value when wizard is changed
      this.setAppFilters();
      this.setSelectedProductsSearchText();
      // If current wizard is not package type selection wizard, but current screen is part of
      // PACKAGE_TYPE_SELECTION_WIZARD_FLOW_STEPS, show package type selection wizard
      this.showPackageTypeSelectionWizard = true;
    } else {
      dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
    }
  }

  // Checks package name validity and if platform is set and sets createPackageDisabled accordingly
  updateCreatePackageButtonStatus() {
    this.pkgSessionSettings.createPackageDisabled =
      !isPackageNameValid(this.packageCreateObj.packageName) || !this.packageCreateObj.platform;
  }

  /**
   * @description Method to check if the next button should be enabled on the create package wizard. It
   * should not be enabled on some screens based on the selection criteria and workflows
   */
  updateNextButtonEnableStatus() {
    switch (this.pkgSessionSettings.currentScreen) {
      case SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE:
        this.pkgSessionSettings.nextButtonEnabled = !!this.pkgSessionSettings.packagingMode;
        break;
      case SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE:
        this.pkgSessionSettings.nextButtonEnabled = !!this.pkgSessionSettings.packageType;
        break;
      case SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE:
        this.pkgSessionSettings.nextButtonEnabled = !!this.pkgSessionSettings.packageType;
        break;
      case SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN:
        this.pkgSessionSettings.nextButtonEnabled =
          !!this.pkgSessionSettings.frlIsolatedMachineCodes;
        break;
      case SCREEN_NAME_CONSTANTS.CHOOSE_SERVER:
        this.pkgSessionSettings.nextButtonEnabled = !!this.pkgSessionSettings.frlSelectedServer;
        break;
      case SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT:
        this.pkgSessionSettings.nextButtonEnabled = getNextButtonStatusForEntitlementsScreen(
          this.pkgSessionSettings.selectedFrlOffers
        );
        break;
      case SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN:
        this.pkgSessionSettings.nextButtonEnabled = !!this.packageCreateObj.platform;
        break;
      case SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN:
        this.pkgSessionSettings.nextButtonEnabled = getNextButtonStatusForAppsScreen(
          this.adobeProductsUI,
          this.pkgSessionSettings,
          this.selectedProducts
        );
        break;
      default:
        this.pkgSessionSettings.nextButtonEnabled = true;
        break;
    }
  }
}

export default CreatePackageModalStore;
/* eslint-enable max-lines -- over line limit */
