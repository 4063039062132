import {
  DATE_FORMATS,
  FormattedDateAndTime,
  OverlayWait,
  TableSectionTable,
} from '@admin-tribe/binky-ui';
import {Text} from '@adobe/react-spectrum';
import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {useAccessRequestListContext} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import useAccessRequestsTable from 'features/settings/common/components/trustee-list-table/hooks/useAccessRequestsTable';
import AccessRequestsListTableActions from 'features/settings/components/access-requests/AccessRequestsListTableActions';

const AccessRequestsListTable = () => {
  const intl = useIntl();
  const {trusteeList, loadTrusteeList, tableOptions} = useAccessRequestListContext();

  const {onTableSectionChange} = useAccessRequestsTable();

  const columnDescriptor = [
    {
      key: 'requestAccessTo',
      props: {allowsSorting: true},
    },
    {
      key: 'requestFrom',
    },
    {
      key: 'requestOn',
    },
    {
      key: 'requestBy',
    },
  ];

  const renderers = {
    requestAccessTo: ({item}) => <Text>{item.directoryName}</Text>,
    requestBy: ({item}) => <Text>{item.trusteeOrgSysAdminEmail}</Text>,
    requestFrom: ({item}) => <Text>{item.trusteeOrgName}</Text>,
    requestOn: ({item}) => (
      <FormattedDateAndTime date={item.initialRequestDate} format={DATE_FORMATS.dateTimeMedium} />
    ),
  };

  // get the list of trusted directories
  useEffect(() => {
    loadTrusteeList({}, {updateCount: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- will run only once during the initial render
  }, []);

  return (
    <OverlayWait isLoading={trusteeList.isLoading} showContent>
      {(trusteeList.trusteeListData.items || trusteeList.hasLoadingError) && (
        <TableSection
          getItemKey={
            // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing this
            // istanbul ignore next -- not testing the internal getItemKey
            (item) => `${item.directoryId}/${item.trusteeOrgId}`
          }
          isServerError={trusteeList.hasLoadingError}
          items={trusteeList?.trusteeListData?.items?.map((item) => ({
            ...item,
            // TableSections require all items to have a key property
            key: `${item.directoryId}/${item.trusteeOrgId}`,
          }))}
          onTableSectionChange={onTableSectionChange}
          pageNumber={tableOptions.page}
          pageSizeOptions={tableOptions.pageSizeOptions}
          selectionMode={SELECTION_MODE.MULTIPLE}
          totalPages={tableOptions?.totalPages}
        >
          <AccessRequestsListTableActions />
          <TableSectionTable
            aria-label={intl.formatMessage({id: 'settings.accessRequestsTable.ariaLabel'})}
            columnDescriptor={columnDescriptor}
            columnNamespace="settings.accessRequestsTable"
            noItemsFoundContentMessage={intl.formatMessage({
              id: 'settings.accessRequestsTable.noItemsFoundContentMessage',
            })}
            renderers={renderers}
          />
        </TableSection>
      )}
    </OverlayWait>
  );
};

export default AccessRequestsListTable;
