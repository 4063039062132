(function () {
  /**
   * @deprecated This file has been ported to src2
   *
   * @ngdoc component
   * @name app.widgets.sophia:appSophiaCard
   * @description component to display content from a single card returned by Sophia.
   * @param {Object} card object containing description of display properties (content and style)
   * @param {Function} [onDismissCard] The callback function to handle a sophia x on/off button's click event
   * See [API Spec]{@link https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ADBPersonalization&title=Execution+Layer+API+Spec} for card schema
   */

  angular.module('app.widgets.sophia').component('appSophiaCard', {
    bindings: {
      card: '<',
      onDismissCard: '&?',
    },
    controller,
    templateUrl: 'app/widgets/sophia/card/sophia-card.component.html',
  });

  function controller(
    $log,
    _,
    AnalyticsEvent,
    binkySrc2,
    HELP_SEARCH_WHITELIST_DOMAINS,
    SophiaCardHelper,
    SophiaHelper,
    urlUtils
  ) {
    const vm = this;
    const SOPHIA_CONSTANTS = binkySrc2.services.sophia.SOPHIA_CONSTANTS;

    _.assign(vm, {
      $onInit,
      onPrimaryCTAClick,
      onSecondaryCTAClick,
      sendClickAnalytics,
    });

    /////////////

    function $onInit() {
      const cardContent = vm.card.getCard();
      _.assign(vm, {
        cardClasses: SophiaCardHelper.getClassesForCard(vm.card),
        cardContent: SophiaCardHelper.getContentObject(cardContent),
        cardContext: `campaign-id-${vm.card.getCampaignId()}`,
        dismissCard,
        primaryCTACallback: SophiaCardHelper.getCtaClickEventCallback(cardContent.actionURL),
        primaryCTAVariant: SophiaCardHelper.getPrimaryCTAButtonVariant(vm.card),
        secondaryCTACallback: SophiaCardHelper.getCtaClickEventCallback(cardContent.eventAction),
        secondaryCTAVariant: SophiaCardHelper.getSecondaryCTAButtonVariant(vm.card),
        showPrimaryButton: showButton(cardContent.ctaLabel, cardContent.actionURL),
        showSecondaryButton: showButton(cardContent.secondaryCTALabel, cardContent.eventAction),
        style: SophiaCardHelper.getStyleObj(cardContent),
      });
    }

    function dismissCard() {
      vm.onDismissCard();
      _.invoke(vm, 'onDismissCard', {card: vm.card});

      SophiaHelper.sendInteractionAnalytics({
        action: SOPHIA_CONSTANTS.INTERACTION.DISMISS,
        card: this.card,
      });
    }

    function onPrimaryCTAClick() {
      _.invoke(vm, 'primaryCTACallback');

      SophiaHelper.sendInteractionAnalytics({
        action: SOPHIA_CONSTANTS.INTERACTION.PRIMARY,
        card: this.card,
      });
    }

    function onSecondaryCTAClick() {
      _.invoke(vm, 'secondaryCTACallback');

      SophiaHelper.sendInteractionAnalytics({
        action: SOPHIA_CONSTANTS.INTERACTION.SECONDARY,
        card: this.card,
      });
    }

    function sendClickAnalytics(card, method) {
      const {cardControlAnalyticsParams, containerAnalyticsParams = {}} = card;
      containerAnalyticsParams.cardId = card.getCard().cardID;
      const event = {
        attributes: {
          cardControlAnalyticsParams,
          containerAnalyticsParams,
        },
        cgen: 'PKDHJF1233',
        componentMethod: method,
        componentMethodType: 'click',
        componentName: 'appSophiaCard',
      };
      AnalyticsEvent.dispatch(event);
    }

    /* Private functions */

    function showButton(label, url) {
      return label && isValidAdobeUrl(url);
    }

    function isValidAdobeUrl(url) {
      if (url) {
        if (
          urlUtils.hasDomain(url, HELP_SEARCH_WHITELIST_DOMAINS.ADOBE) ||
          urlUtils.hasDomain(url, HELP_SEARCH_WHITELIST_DOMAINS.FRAMEIO)
        ) {
          return true;
        }
        $log.error(`Invalid non-Adobe domain: ${url}`);
      } else {
        $log.error('Sophia card given undefined or empty URL');
      }
      return false;
    }
  }
})();
