/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectoryCreateModal', {
    bindings: {
      directoryList: '<',
    },
    controller,
    templateUrl: 'app/widgets/directories/create/directory-create-modal.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    $q,
    $scope,
    $state,
    $translate,
    _,
    AuthSource,
    APP_CREATE_AZURE_DIRECTORY_MODAL_ID,
    APP_CREATE_DIRECTORY_MODAL_ID,
    APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID,
    APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID,
    AZURE_SCOPES,
    DisplayMessage,
    DirectoryList,
    directorySync,
    DIRECTORY_TYPE,
    feature,
    IDP_CHOOSER_LOGOS,
    idpStateManager,
    IDP_TYPES,
    OrganizationManager,
    panelManager,
    toastManager
  ) {
    const vm = this;

    let samlAuthSource = null;

    _.assign(vm, {
      $onInit,
      canChooseIdp,
      canCreateDirectory,
      createDirectory,
      getChooseIDPButtonLabel,
      getCreateButtonLabel,
      getModalTitle,
      isEnterpriseSelected,
      isFederatedIdCreated,
      isOktaSelected,
      isOrgDelegationGroupMigrated,
      onChooseIdp,
      onClose,
      onCreate,
      onIdpSelected,
      onOpen,
      resetModal,
    });

    const IDP_CHOOSER_TYPES = {
      AZURE: 'AZURE',
      GOOGLE: 'GOOGLE',
      OKTA: 'OKTA',
      SAML: 'SAML',
    };

    vm.directoryTypes = [
      {
        goUrl: 'aac_create_directory_federated',
        id: 'federated-directory',
        name: DIRECTORY_TYPE.TYPE3,
        translateKey: 'federatedId',
      },
      {
        goUrl: 'aac_create_directory_enterprise',
        id: 'enterprise-directory',
        name: DIRECTORY_TYPE.TYPE2,
        translateKey: 'enterpriseId',
      },
    ];

    const CARD_IDP_TYPES = [
      {
        descriptionKey: '.idpChooser.cards.azure.description',
        iconCssClass: 'azure-idp-logo',
        id: IDP_CHOOSER_TYPES.AZURE,
        nameKey: '.idpChooser.cards.azure.name',
      },
      {
        descriptionKey: '.idpChooser.cards.google.description2',
        iconCssClass: 'google-idp-logo',
        id: IDP_CHOOSER_TYPES.GOOGLE,
        nameKey: '.idpChooser.cards.google.name',
        requiresFeatureFlag: 'temp_google_idp',
      },
      {
        descriptionKey: '.idpChooser.cards.okta.description',
        hideWhenFeatureFlag: 'temp_saml_idp',
        iconCssClass: 'saml-idp-logo',
        id: IDP_CHOOSER_TYPES.OKTA,
        nameKey: '.idpChooser.cards.okta.name',
      },
      {
        descriptionKey: '.idpChooser.cards.saml.description',
        iconCssClass: 'saml-idp-logo',
        id: IDP_CHOOSER_TYPES.SAML,
        nameKey: '.idpChooser.cards.saml.name',
        requiresFeatureFlag: 'temp_saml_idp',
      },
    ];

    /**
     * Checks if an IDP should be displayed based on the `requiresFeatureFlag`
     * and `hideWhenFeatureFlag` property values. Requires property takes
     * precedence
     *
     * @param {Object} idp An idp card object to be checked for flag presence
     *
     * @return {boolean} True if no flag condition present otherwise depends on condition
     */
    function idpByFlag(idp = {}) {
      if (Object.prototype.hasOwnProperty.call(idp, 'requiresFeatureFlag')) {
        return feature.isEnabled(idp.requiresFeatureFlag);
      }
      if (Object.prototype.hasOwnProperty.call(idp, 'hideWhenFeatureFlag')) {
        return feature.isDisabled(idp.hideWhenFeatureFlag);
      }

      return true;
    }

    vm.idpList = [
      {
        description: $translate.instant(
          'widgets.directories.createDirectory.idpChooser.cards.azure.description'
        ),
        id: IDP_CHOOSER_TYPES.AZURE,
        logo: IDP_CHOOSER_LOGOS.AZURE,
        name: $translate.instant('widgets.directories.createDirectory.idpChooser.cards.azure.name'),
        requiresFeatureFlag: 'temp_azure_idp',
      },
      {
        description: $translate.instant(
          'widgets.directories.createDirectory.idpChooser.cards.google.description2'
        ),
        id: IDP_CHOOSER_TYPES.GOOGLE,
        logo: IDP_CHOOSER_LOGOS.GOOGLE,
        name: $translate.instant(
          'widgets.directories.createDirectory.idpChooser.cards.google.name'
        ),
        requiresFeatureFlag: 'temp_google_idp',
      },
      {
        description: $translate.instant(
          'widgets.directories.createDirectory.idpChooser.cards.saml.description'
        ),
        hideWhenFeatureFlag: 'temp_saml_idp',
        id: IDP_CHOOSER_TYPES.SAML,
        logo: IDP_CHOOSER_LOGOS.SAML,
        name: $translate.instant('widgets.directories.createDirectory.idpChooser.cards.saml.name'),
        requiresFeatureFlag: 'temp_saml_idp',
      },
    ];

    vm.idpTypes = feature.isEnabled('temp_idp_migration')
      ? null
      : _.filter(CARD_IDP_TYPES, idpByFlag);

    function $onInit() {
      _.assign(vm, {
        idp: {},
        isBusy: false,
        modalId: APP_CREATE_DIRECTORY_MODAL_ID,
        waitPromise: $q.resolve(),
      });

      resetModal();
    }

    function getChooseIDPButtonLabel() {
      return $translate.instant(
        vm.isOktaSelected()
          ? 'widgets.directories.createDirectory.buttons.create'
          : 'widgets.directories.createDirectory.buttons.next'
      );
    }

    function getCreateButtonLabel() {
      return $translate.instant(
        !vm.isOrgDelegationGroupMigrated() || vm.isEnterpriseSelected()
          ? 'widgets.directories.createDirectory.buttons.createDirectory'
          : 'widgets.directories.createDirectory.buttons.next'
      );
    }

    function getModalTitle() {
      return $translate.instant(
        vm.showIdpChooser
          ? 'widgets.directories.createDirectory.idpChooser.title'
          : 'widgets.directories.createDirectory.title'
      );
    }

    // Decide if creating a Directory is available.
    function canCreateDirectory() {
      return vm.isBusy || _.isEmpty(vm.directory.name) || _.isEmpty(vm.directory.type);
    }

    // Decide if choosing an IDP button is enabled.
    function canChooseIdp() {
      return vm.isBusy || _.isEmpty(vm.idp.id);
    }

    // Decide if azure, google, etc. are enabled
    function isOrgDelegationGroupMigrated() {
      return OrganizationManager.activeOrgDelegationGroupIsMigrated();
    }

    // Check if Enterprise directory is selected.
    function isEnterpriseSelected() {
      return _.get(vm, 'directory.type') === DIRECTORY_TYPE.TYPE2;
    }

    // Check if Okta IDP is selected.
    function isOktaSelected() {
      return _.get(vm, 'idp.id') === IDP_CHOOSER_TYPES.OKTA;
    }

    // Called after selecting a directory type and clicking "Next".
    function onCreate() {
      // If an Enterprise directory is selected, go ahead and create it.
      if (isEnterpriseSelected()) {
        createDirectory();
        return;
      }

      if (isOrgDelegationGroupMigrated()) {
        // Mutates UI to show Azure, Okta etc chooser.
        vm.showIdpChooser = true;
      } else {
        createDirectory();
      }
    }

    // Creates an Enterprise or Federated Okta directory.
    function createDirectory() {
      vm.isBusy = true;
      hidePageBanner();

      vm.waitPromise = DirectoryList.create(vm.directory)
        .then(
          (directory) => {
            vm.showIdpChooser = false;
            vm.directory = directory;
            vm.directoryList.refresh();

            if (isFederatedIdCreated()) {
              showPageBanner(
                'info',
                $translate.instant(
                  'widgets.directories.createDirectory.requiresApproval.banner.message',
                  {
                    directoryName: vm.directory.name,
                  }
                )
              );
            } else {
              onClose(true);
            }
          },
          // Display error banner with no title
          () => showPageBanner()
        )
        .finally(() => {
          vm.isBusy = false;
        });
      return vm.waitPromise;
    }

    // Called when an idp is chosen
    function onIdpSelected(idp) {
      vm.idp = idp;
    }

    // Called after choosing IDP and clicking next.
    function onChooseIdp() {
      // If Okta was selected, go ahead and greate a Type3 directory.
      if (_.get(vm, 'idp.id') === IDP_CHOOSER_TYPES.OKTA) {
        createDirectory();
        return;
      }

      if (_.get(vm, 'idp.id') === IDP_CHOOSER_TYPES.SAML) {
        vm.isBusy = true;
        hidePageBanner();

        vm.waitPromise = createSamlAuthSource()
          .then(createSamlIdpForAuthSource)
          .then((authSource) => {
            // reset the created saml authSource used for retry operation
            samlAuthSource = null;

            vm.directoryList.refresh();
            panelManager.open(APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID, {authSource});
          })
          .catch(() => showPageBanner())
          .finally(() => {
            vm.isBusy = false;
          });
      }

      // Open Azure modal
      if (_.get(vm, 'idp.id') === IDP_CHOOSER_TYPES.AZURE) {
        createAzureDirectory();
      }

      // Open Google modal
      if (_.get(vm, 'idp.id') === IDP_CHOOSER_TYPES.GOOGLE) {
        vm.isBusy = true;
        hidePageBanner();

        vm.waitPromise = createSamlAuthSource()
          .then(createSamlIdpForAuthSource)
          .then((authSource) => {
            // reset the created saml authSource used for retry operation
            samlAuthSource = null;

            vm.directoryList.refresh();

            panelManager.open(APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID, {
              authSource,
              configurationUpdated: () =>
                // Continue with domain setup

                $state
                  .go('settings.directory-details.domains', {
                    directoryId: authSource.id,
                    setup: true,
                  })
                  // If the transition fails we continue with success
                  // but display to the user that they can continue
                  // the setup from the domains tab
                  .catch(() => {
                    toastManager.showSuccessToast(
                      $translate.instant(
                        'widgets.directories.createDirectory.toasts.continueSetupInDomains'
                      )
                    );

                    // and go to the details tab
                    return $state.go('settings.directory-details', {directoryId: authSource.id});
                  }),
              isGoogleSelected: true,
            });
          })
          .catch(() => showPageBanner())
          .finally(() => {
            panelManager.close(APP_CREATE_DIRECTORY_MODAL_ID);
            vm.isBusy = false;
          });
      }
    }

    function onClose(showToast) {
      panelManager.close(APP_CREATE_DIRECTORY_MODAL_ID);
      if (showToast) {
        toastManager.showSuccessToast(
          $translate.instant('widgets.directories.createDirectory.successMessage', {
            directoryName: vm.directory.name,
          })
        );
      }
      resetModal();
      hidePageBanner();
    }

    function onOpen() {
      resetModal();
    }

    function resetModal() {
      _.assign(vm, {
        directory: {
          name: '',
          type: '',
        },
        displayMessage: undefined,
        idp: {
          id: '',
        },
        showIdpChooser: false,
      });
    }

    /////////////////

    function createAzureDirectory() {
      vm.isBusy = true;
      hidePageBanner();

      vm.waitPromise = AuthSource.create({
        name: vm.directory.name,
      })
        .then((authSource) => {
          // redirect the user to login from azure
          idpStateManager.startAuthentication(directorySync.getAzureAdminConsentUrl(), {
            data: {
              isIdentitySetupStarted: true,
            },
            queryParams: {
              client_id: directorySync.getAzureClientId(),
              scope: `${AZURE_SCOPES.OPENID} ${AZURE_SCOPES.PROFILE} ${AZURE_SCOPES.EMAIL}`,
            },
            returnUrl: $state.href('settings.directory-details.authentication', {
              directoryId: authSource.id,
            }),
          });

          return $q.defer().promise;
        })
        .catch(() => showPageBanner())
        .finally(() => {
          vm.isBusy = false;
        });
    }

    function createSamlAuthSource() {
      if (samlAuthSource) {
        return $q.resolve(samlAuthSource);
      }

      return AuthSource.create({
        name: vm.directory.name,
      }).then((authSource) => {
        samlAuthSource = authSource;
        return authSource;
      });
    }

    function createSamlIdpForAuthSource(authSource) {
      return authSource
        .addIdp({
          federationType: IDP_TYPES.SAML,
        })
        .then(() => authSource);
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function isFederatedIdCreated() {
      return (
        // Checking whether the directory is created or not by the id attribute.
        // Only created directories have an id attribute
        _.get(vm, 'directory.type') === DIRECTORY_TYPE.TYPE3 && _.has(vm, 'directory.id')
      );
    }

    function showPageBanner(variant, body) {
      vm.displayMessage = new DisplayMessage(_.pickBy({body, variant}));
    }
  }
})();
/* eslint-enable max-lines */
