import {formatFileSize, formatPercentage} from '@admin-tribe/binky-ui';
import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import {FileSize} from '@pandora/react-formatted-file-size';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {USAGE_TYPES} from 'common/services/storage-stats/StorageStatsConstants';

/**
 * A component to present a visual, screen-reader friendly, representation of the storage
 * usage data.
 */
const UsageBarTable = ({tableId, usageData}) => {
  const intl = useIntl();

  return (
    <TableView
      aria-label={intl.formatMessage({id: 'storage.usageBarSection.table.ariaLabel'})}
      data-testid={tableId}
      density="compact"
      id={tableId}
      marginTop="size-200"
      overflowMode="wrap"
    >
      <TableHeader>
        <Column>
          <FormattedMessage id="storage.usageBarSection.table.column.type" />
        </Column>
        <Column align="end">
          <FormattedMessage id="storage.usageBarSection.table.column.usage" />
        </Column>
        <Column align="end">
          <FormattedMessage id="storage.usageBarSection.table.column.percentageOfQuota" />
        </Column>
      </TableHeader>
      <TableBody items={usageData}>
        {(item) => (
          <Row key={item.type}>
            <Cell>
              <FormattedMessage id={`storage.usageBarSection.type.${item.type}`} />
            </Cell>
            <Cell>{formatFileSize(intl, item.displayAmount)}</Cell>
            <Cell>{formatPercentage(intl, item.percentage)}</Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

UsageBarTable.propTypes = {
  /**
   * The id for table that is the text equivalent for this bar chart.
   */
  tableId: PropTypes.string.isRequired,
  /**
   * The data for the table.
   */
  usageData: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The usage to display for the given type of storage.
       */
      displayAmount: PropTypes.instanceOf(FileSize).isRequired,
      /**
       * The numeric percentage of storage used for the given type of storage.
       */
      percentage: PropTypes.number.isRequired,
      /**
       * The type of storage.
       */
      type: PropTypes.oneOf(Object.values(USAGE_TYPES)),
    })
  ).isRequired,
};

export default UsageBarTable;
