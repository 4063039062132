import {feature} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useSearchParams} from 'react-router-dom';

import AddUsersToProductModal from 'common/components/add-user-modals/AddUsersToProductModal';
import rootStore from 'core/RootStore';
import {canAssignUser} from 'core/products/access/productAccess';

/**
 * Function to find or default product id.
 * If the feature 'temp_assign_products_deeplink_filtered' is not enabled, it returns the provided product id.
 * If the feature is enabled, it checks if the product id is in the list of product ids that the user can be assigned to.
 * If it is, it returns the product id, otherwise it returns the first product id that the user can be assigned to.
 * @param {string} productId - The product id to find or default.
 * @returns {string} - The found or default product id.
 */
const findOrDefaultProductId = (productId) => {
  if (!feature.isEnabled('temp_assign_products_deeplink_filtered')) {
    return productId;
  }
  const {organizationStore} = rootStore;
  const orgPIDs = organizationStore.productList.items
    .filter((product) => canAssignUser(product))
    .map((product) => product.id);
  return productId && orgPIDs?.includes(productId) ? productId : orgPIDs?.[0];
};

/**
 * Component to wrap the AddUsersToProductModal component.
 * It uses the search params to get the product id and finds or defaults it.
 * It passes the found or default product id, the active organization id and other props to the AddUsersToProductModal component.
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isFirstSessionAccess - The indicator to determine if the user has never seen the Assign modal before. Defaults to false.
 * @param {function} props.onClose - Optional handler that is called when the modal is closed if current url does not suggest we are in an assign deeplink. If we the url is an assign deeplink, this will be ignored and go to the parent route.
 * @returns {JSX.Element} - The AddUsersToProductModal component with the passed props.
 */
const AddUsersToProductModalWrapper = ({isFirstSessionAccess = false, onClose}) => {
  const {organizationStore} = rootStore;
  const orgId = organizationStore.activeOrgId;
  const [searchParams] = useSearchParams();
  const pid = searchParams.get('pid');
  const productId = findOrDefaultProductId(pid);

  return (
    <AddUsersToProductModal
      isFirstSessionAccess={isFirstSessionAccess}
      isOpen
      onClosed={onClose}
      orgId={orgId}
      productId={productId}
    />
  );
};

AddUsersToProductModalWrapper.propTypes = {
  /**
   * The indicator to determine if the user has never seen the Assign modal before.
   * Defaults to false.
   */
  isFirstSessionAccess: PropTypes.bool,
  /**
   * Optional handler that is called when the modal is closed if current url does not suggest we are in an
   * assign deeplink. If we the url is an assign deeplink, this will be ignored and go to the parent route.
   */
  onClose: PropTypes.func,
};

export {AddUsersToProductModalWrapper, findOrDefaultProductId};
