(function () {
  /**
   * @ngdoc module
   * @name app.core.api
   * @description defines api utilities
   */
  angular.module('app.core.api', [
    'app.core.auth',
    'app.core.migration',
    'binky.core.api.ready',
    'binky.core.api.search',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.migration',
    'ngResource',
  ]);
})();
