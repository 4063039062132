import {feature, hasTermsToAccept, hasTermsToReAccept, hasVIPMPContract} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {isContractAdmin, isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';
import {redirectUrlToAcceptTC} from 'features/termsRedirect/termsRedirect';

/**
 * Method to check if terms and conditions banners should be shown, and will
 * generate payloads to add to the globalBannerStore.
 */
function notifyTermsAndConditionsBanners() {
  const contractList = rootStore.organizationStore.contractList;
  if (hasTermsToAccept(contractList)) {
    if (feature.isDisabled('temp_delegate_without_provisioning_pending_tc')) {
      rootStore.organizationStore.globalBannerStore.add({
        message: hasVIPMPContract(contractList)
          ? 'globalBanner.messages.termsAndConditions.vipmp'
          : 'globalBanner.messages.termsAndConditions.vip',
        type: GLOBAL_BANNER_TYPES.WARNING,
      });
    } else {
      const banner = getBannerDetails(rootStore.organizationStore.activeOrgId);
      rootStore.organizationStore.globalBannerStore.add(banner);
    }
  }
}

function getBannerDetails(orgId) {
  const contractList = rootStore.organizationStore.contractList;
  const bannerDetails = {
    isDismissible: false,
    linkName: 'terms_reacceptance_block',
    linkText: 'packages.tools.adminTools.learnMore',
    message: feature.isEnabled('temp_contract_admin_role')
      ? 'globalBanner.messages.termsAndConditions.reviewNow.vip'
      : 'globalBanner.messages.termsAndConditions.reviewNow.vip.old',
    type: GLOBAL_BANNER_TYPES.ERROR,
  };

  // Only System Admins are allowed to accept T&C for the first time, Contract Admin's can re-accept the terms.
  if (isOrgAdmin() || (isContractAdmin() && hasTermsToReAccept(contractList))) {
    bannerDetails.buttonText = 'globalBanner.messages.termsAndConditions.reviewNow.button';
    bannerDetails.buttonUrl = redirectUrlToAcceptTC(orgId);
  }

  return bannerDetails;
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyTermsAndConditionsBanners};
