import {navBus} from '@admin-tribe/binky';
import {SUPPORT_TICKET_RECORD_TYPE} from '@pandora/data-model-support-ticket';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_EXPERT_SESSIONS,
  PATH_EXPERT_SESSIONS_CLOSED,
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT,
  PATH_SUPPORT_CASES,
  PATH_SUPPORT_CASES_CLOSED,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASE_DETAILS,
} from 'features/support/routing/supportPaths';

const goTo = (path) => {
  navBus.navigate(generatePath(path, {orgId: rootStore.organizationStore.activeOrgId}));
};

/**
 * @description Get the href for the support ticket details page.
 *
 * @param {Object} object
 * @param {String} object.ticketId - id of the support ticket.
 * @param {String} object.recordType - Type of the support ticket details page (Expert/Case).
 */
const getHrefForSupportTicketDetails = ({recordType, ticketId}) => {
  if (recordType === SUPPORT_TICKET_RECORD_TYPE.EXPERT) {
    return generatePath(PATH_EXPERT_SESSION_DETAILS, {
      orgId: rootStore.organizationStore.activeOrgId,
      ticketId,
    });
  }

  return generatePath(PATH_SUPPORT_CASE_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    ticketId,
  });
};

/**
 * @description Method to navigate to the Support page for the current organization
 */
const goToSupport = () => goTo(PATH_SUPPORT);

/**
 * @description Method to navigate to the Support Cases page for the current organization
 */
const goToSupportCases = () => goTo(PATH_SUPPORT_CASES);

/**
 * @description Method to navigate to the Open Support Cases page for the current organization
 */
const goToOpenSupportCases = () => goTo(PATH_SUPPORT_CASES_OPEN);

/**
 * @description Method to navigate to the Closed Support Cases page for the current organization
 */
const goToClosedSupportCases = () => goTo(PATH_SUPPORT_CASES_CLOSED);

/**
 * @description Method to navigate to the Expert Sessions page for the current organization
 */
const goToExpertSessions = () => goTo(PATH_EXPERT_SESSIONS);

/**
 * @description Method to navigate to the Open Expert Sessions page for the current organization
 */
const goToOpenExpertSessions = () => goTo(PATH_EXPERT_SESSIONS_OPEN);

/**
 * @description Method to navigate to the Closed Expert Sessions page for the current organization
 */
const goToClosedExpertSessions = () => goTo(PATH_EXPERT_SESSIONS_CLOSED);

export {
  getHrefForSupportTicketDetails,
  goToSupport,
  goToSupportCases,
  goToOpenSupportCases,
  goToClosedSupportCases,
  goToExpertSessions,
  goToOpenExpertSessions,
  goToClosedExpertSessions,
};
