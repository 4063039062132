/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.common:appCircleIcon
 *
 * @description The a generic circle icon
 */
(function () {
  angular.module('app.widgets.common').component('appCircleIcon', {
    bindings: {
      icon: '@?',
      iconSize: '@?',
    },
    controller,
    templateUrl: 'app/widgets/common/circle-icon/circle-icon.component.html',
    transclude: true,
  });

  function controller(_, $element) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      if (vm.icon) {
        const icon = new Coral.Icon().set({
          icon: vm.icon,
          size: vm.iconSize || 'S',
        });
        $element.find('div').append(icon);
      }
    }
  }
})();
