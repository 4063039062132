import {getEarliestAnniversaryDate} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import {Content, Divider, Flex, Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import DeviceDesktop from '@spectrum-icons/workflow/DeviceDesktop';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {DEVICE_STATUS} from 'features/products/device-licenses/DeviceConstants';

import DeactivateDeviceButton from './DeactivateDeviceButton';

const LegacyDeviceDetailsDrawer = ({device, poolId, updateTableChange}) => {
  const GoUrl = binkyUI.common.components.GoUrl;
  const {formatMessage, formatDate} = useIntl();
  const expiryDate =
    device?.expiryDate || getEarliestAnniversaryDate(rootStore.organizationStore.contractList);
  const expirationDate = new Date(expiryDate);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - expirationDate);
  const expirationDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <Drawer data-testid="device-details-drawer">
      <Header marginX="size-100">
        <Flex>
          <View
            backgroundColor="gray-300"
            borderRadius="large"
            height="size-325"
            marginEnd="size-200"
            padding="size-65"
            paddingEnd="size-100"
            width="size-350"
          >
            <DeviceDesktop
              aria-label={formatMessage({
                id: 'products.details.devices.legacyDevices.table.icon.ariaLabel',
              })}
              marginStart="size-65"
              marginTop="size-65"
              size="S"
            />
          </View>
          <View>
            <Heading level={2} marginY={0}>
              {device.deviceName}
            </Heading>
            <Text>
              {formatMessage({
                id: 'products.details.devices.legacyDevices.drawer.lastActivatedOn',
              })}
            </Text>
            <Text marginStart="size-75">
              {formatDate(device.lastActivityDate, {dateStyle: 'long'})}
            </Text>
            <Text marginTop="size-200" UNSAFE_style={{display: 'block'}}>
              {formatMessage({id: 'products.details.devices.legacyDevices.drawer.description'})}
            </Text>
          </View>
        </Flex>
        <Flex marginY="size-200">
          <DeactivateDeviceButton
            device={device}
            onUpdateTableChange={updateTableChange}
            poolId={poolId}
          />
          <View marginStart="size-200" marginTop="size-50">
            <Text>
              {formatMessage(
                {id: 'products.details.devices.legacyDevices.drawer.learnMore'},
                {
                  goUrl: (str) => <GoUrl name="manage_device_licenses">{str}</GoUrl>,
                }
              )}
            </Text>
          </View>
        </Flex>
      </Header>
      {device.status === DEVICE_STATUS.OVER_ACTIVATED && (
        <Content data-testid="payment-needed-section" marginTop="size-100">
          <Heading level={3} marginBottom="size-100">
            {formatMessage({
              id: 'products.details.devices.legacyDevices.drawer.paymentNeededTitle',
            })}
          </Heading>
          <Divider marginBottom="size-125" size="M" />
          <Text marginTop="size-75" UNSAFE_style={{color: 'var(--color-red-600)'}}>
            {formatMessage(
              {id: 'products.details.devices.legacyDevices.drawer.expiresOn'},
              {count: expirationDays}
            )}
          </Text>
          <Text marginTop="size-150" UNSAFE_style={{display: 'block'}}>
            {formatMessage({
              id: 'products.details.devices.legacyDevices.paymentNeeded.description',
            })}
          </Text>
        </Content>
      )}
    </Drawer>
  );
};
LegacyDeviceDetailsDrawer.propTypes = {
  /**
   * selected device details object
   */
  device: PropTypes.shape({
    deviceId: PropTypes.string,
    deviceName: PropTypes.string,
    expiryDate: PropTypes.string,
    lastActivityDate: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};

export default LegacyDeviceDetailsDrawer;
