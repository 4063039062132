(function () {
  /**
   * @deprecated
   */
  angular.module('app.redirect').config(redirectConfig);

  /* @ngInject */
  function redirectConfig($stateProvider) {
    $stateProvider.state('redirect.redirect', {
      abstract: true,
      component: 'appRedirect',
      url: '',
    });
  }
})();
