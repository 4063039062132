import {feature} from '@admin-tribe/binky';
import {WorkspaceNav} from '@admin-tribe/binky-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import useStorageAccessors from 'features/storage/hooks/useStorageAccessors';
import useStoragePaths from 'features/storage/hooks/useStoragePaths';

/** Renders the workspace nav (sidenav) for Storage Section */
const StorageWorkspaceNav = () => {
  const intl = useIntl();
  const {
    canViewIndividualUserFolders,
    canViewLibraries,
    canViewSharedProjects,
    canViewStorage,
    isLoading,
  } = useStorageAccessors();
  const {individualUserFoldersPath, librariesPath, overviewPath, reportsPath, sharedProjectsPath} =
    useStoragePaths();

  const navItems = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const allNavItems = [
      {
        canAccess: canViewStorage,
        href: overviewPath,
        label: 'storage.overview.title',
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-storage-overview'} : {}),
      },
      {
        canAccess: canViewIndividualUserFolders,
        href: individualUserFoldersPath,
        label: 'storage.individualFolder.title',
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-storage-folders'} : {}),
      },
      {
        canAccess: canViewStorage,
        href: reportsPath,
        label: 'storage.storageReports.title',
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-storage-reports'} : {}),
      },
      {
        canAccess: canViewLibraries,
        href: librariesPath,
        label: 'storage.libraries.title',
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-storage-libraries'} : {}),
      },
      {
        canAccess: canViewSharedProjects,
        href: sharedProjectsPath,
        label: 'storage.sharedProjects.title',
        ...(feature.isEnabled('temp_shared_projects_testid')
          ? {testId: 'nav-shared-projects-test-id'}
          : {}),
      },
    ];

    return allNavItems
      .filter(({canAccess}) => canAccess)
      .map((item, index) => ({
        href: item.href(),
        name: intl.formatMessage({id: item.label}),
        order: index,
        ...(item.testId ? {testId: item.testId} : {}),
      }));
  }, [
    canViewIndividualUserFolders,
    canViewLibraries,
    canViewSharedProjects,
    canViewStorage,
    individualUserFoldersPath,
    intl,
    isLoading,
    librariesPath,
    overviewPath,
    reportsPath,
    sharedProjectsPath,
  ]);

  return <WorkspaceNav navItems={navItems} />;
};

StorageWorkspaceNav.displayName = 'StorageWorkspaceNav';

export default StorageWorkspaceNav;
