(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketDescriptionFormSection
   *
   * @description The description section for a support form which contains an input field.
   *
   * @binding {String} descriptionKey. The key for the description for this section.
   * @binding {String} headerKey. The key for the header for this section.
   * @binding {Function} onChange. Callback for when the description changes.  Callback param is description.
   * @binding {String} placeholderKey. The key for the placeholder for the title input.
   */
  angular.module('app.widgets.support').component('appSupportTicketDescriptionFormSection', {
    bindings: {
      descriptionKey: '@',
      headerKey: '@',
      onChange: '&',
      placeholderKey: '@',
      rows: '@?',
      showSeparator: '@?',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-description-form-section/support-ticket-description-form-section.component.html',
  });

  function controller(_) {
    const vm = this;
    const DESCRIPTION_MAX_LEN = 2000;

    _.assign(vm, {
      DESCRIPTION_MAX_LEN,
      onDescriptionTextAreaChange,
    });

    // Angular has already trimmed the whitespace from the model - vm.description.
    function onDescriptionTextAreaChange() {
      // Display the max characters message if the trimmed value exceeds the max.
      vm.isDescriptionCharLimit = _.get(vm, 'description.length', 0) > DESCRIPTION_MAX_LEN;
      if (vm.isDescriptionCharLimit) {
        vm.description = vm.description.substr(0, DESCRIPTION_MAX_LEN);
      }

      vm.onChange({description: vm.description});
    }
  }
})();
