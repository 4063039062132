(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportContactInfo
   *
   * @description The support contact section for either the support header or a support form.
   *  The enterprise helpx is https://www.adobe.com/go/aac_cct_support_numbers or, for English
   *    https://helpx.adobe.com/entp/enterprise-phone-numbers.html.
   *  The team helpx is https://www.adobe.com/go/ac_other_region_ph_chg_en or, for English,
   *    https://helpx.adobe.com/entp/adobe-creative-cloud-team-support-numbers.html
   *
   * @binding {String} [countryCode] - Optional. Two-letter country code. Default is the logged-in admin's country code.
   * @binding {CUSTOMER_SEGMENT} [customerSegment] - Optional. Default is determined from the current org.
   * @binding {String} [variant] - Optional. Only option is "form". If not specified the component will be
   *  right-justified with font styling for the summary header.
   *  If "form", the component will be left-justified with font styling for a form section.
   */
  angular.module('app.widgets.support').component('appSupportContactInfo', {
    bindings: {
      countryCode: '@?',
      customerSegment: '@?',
      variant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/support/support-contact-info/support-contact-info.component.html',
  });

  function controller(_, AuthenticatedUser, CUSTOMER_SEGMENT, supportAccess, supportContactInfo) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      const customerSegment =
        vm.customerSegment ||
        (supportAccess.canAccessEnterpriseSupport()
          ? CUSTOMER_SEGMENT.ENTERPRISE
          : CUSTOMER_SEGMENT.TEAM);
      const countryCode = vm.countryCode || AuthenticatedUser.get().getCountryCode();

      _.assign(vm, {
        contactInfo: supportContactInfo.getContactInfo(customerSegment, countryCode),
        otherRegionsGoUrl: supportContactInfo.getContactInfoGoUrl(customerSegment),
      });
    }
  }
})();
