import intlTelInput from 'intl-tel-input';
// eslint-disable-next-line no-restricted-imports -- we need this till we've tested the feature
import moment from 'moment';
import format from 'number-format.js';
import Papa from 'papaparse';
import {v4 as uuidv4} from 'uuid';

import onesieSrc2 from '../../../onesieSrc2';

// Our Angular app expects to use some libraries as globals. Shimming with webpack.ProvidePlugin
// isn't sufficient because parts of the code refer to XXX as $window.XXX
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.Papa = Papa;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.format = format;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.intlTelInput = intlTelInput;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.moment = moment;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.onesieSrc2 = onesieSrc2;
// eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- ringold@ to update
window.uuidv4 = uuidv4;
