(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.sophia')
    .constant('CONTAINER_TYPE', {
      MODAL: 'modal',
      PAGE: 'page',
    })
    .constant('SOPHIA_BANNER_VARIANT', {
      RENEWAL_CHAT: 'renewalChat',
    });
})();
