import {useTabbedNavigation} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLoaderData, useParams} from 'react-router-dom';

import {
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
} from 'features/settings/routing/settingsPaths';

import AssetCredentialsSubpage from './asset-credentials-subpage/AssetCredentialsSubpage';
import AssetMigrationSubpage from './asset-migration-subpage/AssetMigrationSubpage';
import AuthorizedDomainsSubpage from './authorized-domains-subpage/AuthorizedDomainsSubpage';
import SharingOptionsSubpage from './sharing-options-subpage/SharingOptionsSubpage';

/**
 * Displays the tabs for the Asset Settings page
 */
const AssetSettingsTabs = () => {
  const params = useParams();
  const intl = useIntl();
  const {canViewAssetCredentials, canViewAssetMigration} = useLoaderData();

  const tabs = [
    {
      access: true,
      default: true,
      key: 'sharingOptions',
      name: intl.formatMessage({id: 'settings.assetSettings.sharingOptions'}),
      panel: <SharingOptionsSubpage />,
      pathname: generatePath(PATH_SETTINGS_ASSET_SHARING_OPTIONS, params),
    },
    {
      access: true,
      key: 'authorizedDomains',
      name: intl.formatMessage({id: 'settings.assetSettings.authorizedDomains'}),
      panel: <AuthorizedDomainsSubpage />,
      pathname: generatePath(PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS, params),
    },
    {
      access: canViewAssetCredentials,
      key: 'credentials',
      name: intl.formatMessage({id: 'settings.assetSettings.credentials'}),
      panel: <AssetCredentialsSubpage />,
      pathname: generatePath(PATH_SETTINGS_ASSET_CREDENTIALS, params),
    },
    {
      access: canViewAssetMigration,
      key: 'migration',
      name: intl.formatMessage({id: 'settings.assetSettings.migration'}),
      panel: <AssetMigrationSubpage />,
      pathname: generatePath(PATH_SETTINGS_ASSET_MIGRATION, params),
    },
  ];

  const displayTabs = tabs.filter((tab) => tab.access);
  const {onTabChange, selectedTabKey} = useTabbedNavigation(displayTabs);

  return (
    <Tabs
      aria-label={intl.formatMessage({id: 'settings.assetSettings.tabbedNav.label'})}
      data-testid="asset-settings-tabs"
      items={displayTabs}
      onSelectionChange={onTabChange}
      selectedKey={selectedTabKey}
    >
      <TabList>{(item) => <Item key={item.key}>{item.name}</Item>}</TabList>
      <TabPanels>
        {(item) => (
          <Item key={item.key}>
            <View marginTop="size-200">{item.panel}</View>
          </Item>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default AssetSettingsTabs;
