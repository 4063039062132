(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.identity').component('appSetupIdentityModalSection', {
    bindings: {
      directoryList: '<',
      domainList: '<',
      sectionContext: '@',
    },
    controller,
    templateUrl:
      'app/widgets/identity/setup-identity-modal/setup-identity-modal-section.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $log,
    $rootScope,
    $translate,
    _,
    ADD_DOMAINS_MODAL_ID,
    APP_CREATE_DIRECTORY_MODAL_ID,
    APP_LINK_DOMAIN_MODAL_ID,
    DOMAIN_LIST_EVENT,
    DIRECTORY_EVENT,
    DIRECTORY_LIST_EVENT,
    OrganizationManager,
    panelManager,
    SETUP_IDENTITY_MODAL_ID,
    SETUP_IDENTITY_MODAL_SECTION_CONTEXT,
    VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      isDirectoryContext,
      isDomainContext,
      isLinkingContext,
      isOrgDelegationGroupMigrated,
      isSetupIdentityModalOpen,
      onClickButton,
      openDnsAccessTokenModal,
    });

    //////////////////

    function $onInit() {
      initialize();
    }

    function initialize() {
      setTranslationNamespaceByContext();
      vm.waitOnPage = $q
        .all([vm.domainList.$promise, vm.directoryList.$promise])
        .then(() => {
          vm.domain = vm.domainList.items[0];
          vm.directory = vm.directoryList.items[0];
          initButtonSetup();
          initCustomStatusSetup();
          initStatusSetup();
        })
        .catch(_.noop());
    }

    function initButtonSetup() {
      vm.showConfigurationButton = requiresConfiguration();
      vm.showCreationButton = requiresCreation();

      vm.showButton = vm.showConfigurationButton || vm.showCreationButton;
      if (vm.showButton) {
        setButtonLabel();
      }
    }

    function initCustomStatusSetup() {
      if (!vm.showButton) {
        setCustomStatusForLinkingContext();
        if (isComplete()) {
          vm.customStatusVariant = 'success';
          vm.customStatusLabel = $translate.instant(
            `widgets.identity.setupIdentityModal${vm.namespace}.statusLabels.complete`
          );
        }
        vm.showCustomStatus = !!vm.customStatusLabel;
      }
    }

    function initStatusSetup() {
      vm.showStatus = !vm.showButton && !vm.showCustomStatus;
    }

    function setCustomStatusForLinkingContext() {
      if (isLinkingContext()) {
        let translateKey = '.incomplete';

        if (!vm.domain && !vm.directory) {
          translateKey = '.noDirectoryOrDomain';
        } else if (vm.directory && !vm.directory.isStatusActive()) {
          translateKey = '.directoryNotComplete';
        } else if (vm.domain && !vm.domain.isActive()) {
          translateKey = '.domainNotComplete';
        }

        vm.customStatusVariant = 'warning';
        vm.customStatusLabel = $translate.instant(
          `widgets.identity.setupIdentityModal${vm.namespace}.statusLabels${translateKey}`
        );
      }
    }

    // functions for setting up section based on context
    function isComplete() {
      if (isDirectoryContext()) {
        return (
          vm.directory && (vm.directory.isStatusActive() || vm.directory.isStatusNeedsDomain())
        );
      }
      if (isDomainContext()) {
        return vm.domain && vm.domain.isActive();
      }
      if (isLinkingContext()) {
        return vm.directory && vm.domain && vm.directory.isStatusActive() && vm.domain.isActive();
      }
      return false;
    }

    // eslint-disable-next-line complexity
    function requiresConfiguration() {
      if (isDirectoryContext()) {
        return (
          vm.directory &&
          (vm.directory.requiresConfiguration() || vm.directory.requiresActivation())
        );
      }
      if (isDomainContext()) {
        return vm.domain && vm.domain.needsValidation();
      }
      if (isLinkingContext()) {
        return (
          vm.directory &&
          vm.domain &&
          (vm.directory.isStatusActive() || vm.directory.isStatusNeedsDomain()) &&
          vm.domain.needsDirectory()
        );
      }
      return false;
    }

    function requiresCreation() {
      if (isDirectoryContext()) {
        return vm.directoryList.isEmpty();
      }
      if (isDomainContext()) {
        return vm.domainList.isEmpty();
      }
      return false;
    }

    // functions for setting strings

    function setTranslationNamespaceByContext() {
      if (isDirectoryContext()) {
        vm.namespace = '.createADirectorySection';
      } else if (isDomainContext()) {
        vm.namespace = '.addDomainsSection';
      } else if (isLinkingContext()) {
        vm.namespace = '.linkDomainAndDirectorySection';
      } else {
        $log.error(`Invalid section context for identity setup modal: ${vm.sectionContext}`);
      }
    }

    function setButtonLabel() {
      if (vm.showConfigurationButton) {
        vm.buttonLabel = $translate.instant(
          `widgets.identity.setupIdentityModal${vm.namespace}.buttons.configureButton`
        );
      } else if (vm.showCreationButton) {
        vm.buttonLabel = $translate.instant(
          `widgets.identity.setupIdentityModal${vm.namespace}.buttons.createButton`
        );
      }
    }

    // functions for navigation

    function openConfigurationModal() {
      if (isDomainContext()) {
        panelManager.open(VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID, {
          context: 'all',
          domainList: vm.domainList,
        });
      }
      if (isLinkingContext()) {
        vm.domainsToLink = [vm.domain];
        panelManager.open(APP_LINK_DOMAIN_MODAL_ID);
      }
    }

    function openCreationModal() {
      if (isDirectoryContext()) {
        panelManager.open(APP_CREATE_DIRECTORY_MODAL_ID);
      }
      if (isDomainContext()) {
        panelManager.open(ADD_DOMAINS_MODAL_ID);
      }
    }

    function openDnsAccessTokenModal() {
      panelManager.open(VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID, {
        context: 'token',
      });
    }

    function onClickButton() {
      if (vm.showConfigurationButton) {
        openConfigurationModal();
      } else if (vm.showCreationButton) {
        openCreationModal();
      }
    }

    // functions for determining directory context

    function isDirectoryContext() {
      return SETUP_IDENTITY_MODAL_SECTION_CONTEXT.DIRECTORY === vm.sectionContext;
    }

    function isDomainContext() {
      return SETUP_IDENTITY_MODAL_SECTION_CONTEXT.DOMAIN === vm.sectionContext;
    }

    function isLinkingContext() {
      return SETUP_IDENTITY_MODAL_SECTION_CONTEXT.LINK === vm.sectionContext;
    }

    function isSetupIdentityModalOpen() {
      return panelManager.isOpen(SETUP_IDENTITY_MODAL_ID);
    }

    function isOrgDelegationGroupMigrated() {
      return OrganizationManager.activeOrgDelegationGroupIsMigrated();
    }

    /** *** Event listeners/handlers *** **/
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(DIRECTORY_LIST_EVENT.UPDATE, initialize);

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(DIRECTORY_EVENT.UPDATE, initialize);

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(DOMAIN_LIST_EVENT.UPDATE, initialize);
  }
})();
