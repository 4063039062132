import {
  PATH_DIRECTORY_DETAILS,
  PATH_DIRECTORY_DETAILS_AUTHENTICATION,
  PATH_DIRECTORY_DETAILS_DOMAINS,
  PATH_DIRECTORY_DETAILS_DOMAIN_ENFORCEMENT,
  PATH_DIRECTORY_DETAILS_OVERVIEW,
  PATH_DIRECTORY_DETAILS_SYNC,
  PATH_DIRECTORY_DETAILS_TRUSTEES,
  PATH_SETTINGS_ASSET,
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
  PATH_SETTINGS_AUTHENTICATION,
  PATH_SETTINGS_CONSOLE,
  PATH_SETTINGS_CONTENT_LOGS,
  PATH_SETTINGS_ENCRYPTION,
  PATH_SETTINGS_IDENTITY,
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_GUIDED_SETUP,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
  PATH_SETTINGS_ROOT,
  PATH_SETTINGS_SECURITY_CONTACTS,
  PATH_SETTINGS_SHARED_PROJECT_POLICIES,
} from 'features/settings/routing/settingsPaths';

const settingsRouteMigration = [
  {
    stateName: 'settings',
    url: PATH_SETTINGS_ROOT,
    useReactRoute: true,
  },
  {
    stateName: 'settings.identity',
    url: PATH_SETTINGS_IDENTITY,
    useReactRoute: true,
  },
  {
    stateName: 'settings.identity.access-requests',
    url: PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.identity.directories',
    url: PATH_SETTINGS_IDENTITY_DIRECTORIES,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details',
    url: PATH_DIRECTORY_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.overview',
    url: PATH_DIRECTORY_DETAILS_OVERVIEW,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.authentication',
    url: PATH_DIRECTORY_DETAILS_AUTHENTICATION,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.domains',
    url: PATH_DIRECTORY_DETAILS_DOMAINS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.domainEnforcement',
    url: PATH_DIRECTORY_DETAILS_DOMAIN_ENFORCEMENT,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.sync',
    url: PATH_DIRECTORY_DETAILS_SYNC,
    useReactRoute: true,
  },
  {
    stateName: 'settings.directory-details.trustees',
    url: PATH_DIRECTORY_DETAILS_TRUSTEES,
    useReactRoute: true,
  },
  {
    stateName: 'settings.identity.domains',
    url: PATH_SETTINGS_IDENTITY_DOMAINS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.identity.guided-setup',
    url: PATH_SETTINGS_IDENTITY_GUIDED_SETUP,
    useReactRoute: false,
  },
  {
    stateName: 'settings.identity.trustees',
    url: PATH_SETTINGS_IDENTITY_TRUSTEES,
    useReactRoute: true,
  },
  {
    stateName: 'settings.asset',
    url: PATH_SETTINGS_ASSET,
    useReactRoute: true,
  },
  {
    stateName: 'settings.asset.sharing-options',
    url: PATH_SETTINGS_ASSET_SHARING_OPTIONS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.asset.authorized-domains',
    url: PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.asset.credentials',
    url: PATH_SETTINGS_ASSET_CREDENTIALS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.asset.migration',
    url: PATH_SETTINGS_ASSET_MIGRATION,
    useReactRoute: true,
  },
  {
    stateName: 'settings.authentication',
    url: PATH_SETTINGS_AUTHENTICATION,
    useReactRoute: true,
  },
  {
    stateName: 'settings.security-contacts',
    url: PATH_SETTINGS_SECURITY_CONTACTS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.console',
    url: PATH_SETTINGS_CONSOLE,
    useReactRoute: true,
  },
  {
    stateName: 'settings.content-logs',
    url: PATH_SETTINGS_CONTENT_LOGS,
    useReactRoute: true,
  },
  {
    stateName: 'settings.encryption',
    url: PATH_SETTINGS_ENCRYPTION,
    useReactRoute: true,
  },
  {
    stateName: 'settings.project-policies',
    url: PATH_SETTINGS_SHARED_PROJECT_POLICIES,
    useReactRoute: true,
  },
];

export default settingsRouteMigration;
