import {LicenseGroup, Product} from '@admin-tribe/binky';
import {InfoBar, InfoItemScorecard} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {isOrgAdminOrProductAdmin} from 'core/products/access/productProfileAccess';
import {initializeStockScorecard} from 'core/products/utils/stockUsageUtils';

import useStockQuantitiesList from './hooks/useStockQuantitiesList';

/**
 * StockInfoBar displays info items from the Stock product quantities API.
 * Otherwise it uses data from JIL products API.
 */
const StockInfoBar = ({product, productProfile}) => {
  const intl = useIntl();
  const {stockQuantitiesList} = useStockQuantitiesList({
    orgId: rootStore.organizationStore.activeOrgId,
    profileId: productProfile?.id,
  });

  const quotas = stockQuantitiesList?.items;
  const productTargetExpression = product
    ? product.targetExpression
    : productProfile?.product?.targetExpression;
  const isOrgOrProductAdmin = isOrgAdminOrProductAdmin(productTargetExpression);
  const stockQuotas = initializeStockScorecard({intl, isOrgOrProductAdmin, quotas});
  const scorecards = stockQuotas.flatMap((quota) => quota.scorecards);

  return (
    <InfoBar>
      {scorecards.map((scorecard) => (
        <InfoItemScorecard
          key={scorecard.label}
          data-testid="stock-scorecard"
          label={scorecard.label}
          value={scorecard.value}
        />
      ))}
    </InfoBar>
  );
};

StockInfoBar.propTypes = {
  /** The product instance */
  product: PropTypes.instanceOf(Product),
  /** The product profile instance. If provided, will fetch scorecard quantities specific to the profile usage rather than the product usage. */
  productProfile: PropTypes.instanceOf(LicenseGroup),
};

export default StockInfoBar;
