import {ActionMenu, DialogContainer, Item} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AccessDnsTokenModal from 'features/settings/components/access-dns-token-modal/AccessDnsTokenModal';
import LinkDomainToDirectoryModal from 'features/settings/components/domains/link-domain-to-directory-modal/LinkDomainToDirectoryModal';
import TransferDomainModal from 'features/settings/components/domains/transfer-domain-modal/TransferDomainModal';
import ValidateDomainModal from 'features/settings/components/domains/validate-domain-modal/ValidateDomainModal';

import {DOMAIN_LIST_ACTIONS} from './domainListActionsConstants';
import useDomainListActions from './useDomainListActions';

const DIALOGS = {
  ACCESS_DNS_TOKEN: 'ACCESS_DNS_TOKEN',
  LINK_DOMAIN_TO_DIRECTORY_MODAL: 'LINK_DOMAIN_TO_DIRECTORY_MODAL',
  TRANSFER_DOMAIN: 'TRANSFER_DOMAIN',
  VALIDATE_DOMAINS: 'VALIDATE_DOMAINS',
};

const DomainListActionMenu = ({onDomainsLinked, onDomainsTransferred, onDomainsValidated}) => {
  const intl = useIntl();

  const {exportToCsv} = useDomainListActions();
  const {tableSectionState} = useTableSectionContext();

  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [dialogId, setDialogId] = useState(null);

  const getDisabledActions = () => {
    // some actions are disabled by default and only enabled when appropriate domains are selected
    const disabledActions = new Set([
      DOMAIN_LIST_ACTIONS.LINK_DOMAIN_TO_DIRECTORY,
      DOMAIN_LIST_ACTIONS.TRANSFER_DOMAIN,
    ]);

    const selectedDomains = tableSectionState.selectedItems;
    // enable link to directory if domains that need directories are selected
    if (selectedDomains.length > 0 && selectedDomains.every((item) => item.needsDirectory)) {
      disabledActions.delete(DOMAIN_LIST_ACTIONS.LINK_DOMAIN_TO_DIRECTORY);
    }

    // enable transfer domains if domains that have directories are selected
    if (selectedDomains.length > 0 && selectedDomains.every((item) => item.isActive)) {
      disabledActions.delete(DOMAIN_LIST_ACTIONS.TRANSFER_DOMAIN);
    }

    if (isLoadingCsv) {
      disabledActions.add(DOMAIN_LIST_ACTIONS.EXPORT_TO_CSV);
    } else {
      disabledActions.delete(DOMAIN_LIST_ACTIONS.EXPORT_TO_CSV);
    }

    return disabledActions;
  };

  const onAction = async (action) => {
    if (action === DOMAIN_LIST_ACTIONS.EXPORT_TO_CSV) {
      setIsLoadingCsv(true);
      await exportToCsv();
      setIsLoadingCsv(false);
    }

    if (action === DOMAIN_LIST_ACTIONS.ACCESS_DNS_TOKEN) {
      setDialogId(DIALOGS.ACCESS_DNS_TOKEN);
    }

    if (action === DOMAIN_LIST_ACTIONS.TRANSFER_DOMAIN) {
      setDialogId(DIALOGS.TRANSFER_DOMAIN);
    }

    if (action === DOMAIN_LIST_ACTIONS.LINK_DOMAIN_TO_DIRECTORY) {
      setDialogId(DIALOGS.LINK_DOMAIN_TO_DIRECTORY_MODAL);
    }

    if (action === DOMAIN_LIST_ACTIONS.VALIDATE_DOMAINS) {
      setDialogId(DIALOGS.VALIDATE_DOMAINS);
    }
  };

  return (
    <>
      <ActionMenu
        data-testid="domain-list-actions-menu"
        disabledKeys={getDisabledActions()}
        isQuiet
        /* needed to align menu in button group */
        marginStart="size-200"
        onAction={onAction}
      >
        <Item key={DOMAIN_LIST_ACTIONS.ACCESS_DNS_TOKEN}>
          {intl.formatMessage({
            id: 'settings.domainsTable.domainListActionMenu.buttons.accessDnsToken',
          })}
        </Item>
        <Item key={DOMAIN_LIST_ACTIONS.VALIDATE_DOMAINS}>
          {intl.formatMessage({
            id: 'settings.domainsTable.domainListActionMenu.buttons.validateDomains',
          })}
        </Item>
        <Item key={DOMAIN_LIST_ACTIONS.LINK_DOMAIN_TO_DIRECTORY}>
          {intl.formatMessage({
            id: 'settings.domainsTable.domainListActionMenu.buttons.linkDirectory',
          })}
        </Item>
        <Item key={DOMAIN_LIST_ACTIONS.TRANSFER_DOMAIN}>
          {intl.formatMessage({
            id: 'settings.domainsTable.domainListActionMenu.buttons.transferDomains',
          })}
        </Item>
        <Item key={DOMAIN_LIST_ACTIONS.EXPORT_TO_CSV}>
          {intl.formatMessage({
            id: 'settings.domainsTable.domainListActionMenu.buttons.exportToCsv',
          })}
        </Item>
      </ActionMenu>
      <DialogContainer
        onDismiss={() => {
          setDialogId(null);
        }}
      >
        {dialogId === DIALOGS.ACCESS_DNS_TOKEN && <AccessDnsTokenModal />}
        {dialogId === DIALOGS.TRANSFER_DOMAIN && (
          <TransferDomainModal
            domains={tableSectionState.selectedItems}
            onDomainsTransferred={onDomainsTransferred}
          />
        )}
        {dialogId === DIALOGS.LINK_DOMAIN_TO_DIRECTORY_MODAL && (
          <LinkDomainToDirectoryModal
            domains={tableSectionState.selectedItems}
            onConfirm={onDomainsLinked}
          />
        )}
        {dialogId === DIALOGS.VALIDATE_DOMAINS && (
          <ValidateDomainModal
            domains={tableSectionState.selectedItems.filter((domain) => domain.needsValidation)}
            onDomainsValidated={onDomainsValidated}
          />
        )}
      </DialogContainer>
    </>
  );
};

DomainListActionMenu.propTypes = {
  onDomainsLinked: PropTypes.func,
  onDomainsTransferred: PropTypes.func,
  onDomainsValidated: PropTypes.func,
};

export default DomainListActionMenu;
