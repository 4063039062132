/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.directories:appDeleteTrustModal
 *
 * @description The confirmation modal for deleting a trusted directories.
 *
 * @binding {Object} directory to be deleted.
 * @binding {Object} trust to be deleted.
 */
(function () {
  const componentName = 'appDeleteTrustModal';
  angular.module('app.widgets.directories').component(componentName, {
    bindings: {
      directory: '<',
      trust: '<',
    },
    controller,
    templateUrl: 'app/widgets/directories/delete-trust-modal/delete-trust-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    AnalyticsEvent,
    panelManager,
    t2eMigrationManager,
    toastManager,
    TRUSTED_DIRECTORY_DRAWER_ID,
    TRUSTED_DIRECTORY_DELETE_MODAL_ID
  ) {
    const vm = this;
    _.assign(vm, {
      isT2eOrg: t2eMigrationManager.isT2EClean(),
      revoke,
      TRUSTED_DIRECTORY_DELETE_MODAL_ID,
    });

    ////////

    /**
     * @description revokes the trust asynchronously and triggers UI feedback
     */
    function revoke() {
      if (!vm.trust) {
        return;
      }

      vm.isBusy = true;
      vm.trust.revoke().then(onSuccess).catch(onError).finally(onComplete);

      ////////

      function onSuccess() {
        triggerAnalytics();
        panelManager.close(TRUSTED_DIRECTORY_DELETE_MODAL_ID);
        panelManager.close(TRUSTED_DIRECTORY_DRAWER_ID);
        toastManager.showSuccessToast();
      }

      function onError() {
        panelManager.close(TRUSTED_DIRECTORY_DELETE_MODAL_ID);
        toastManager.showErrorToast();
      }

      function onComplete() {
        vm.isBusy = false;
      }

      function triggerAnalytics() {
        AnalyticsEvent.dispatch({
          componentMethod: 'delete',
          componentMethodType: 'submit',
          componentName,
        });
      }
    }
  }
})();
