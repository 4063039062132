(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.main').factory('mainBoot', getService);

  /* @ngInject */
  function getService(
    $document,
    $injector,
    $location,
    $log,
    $rootScope,
    $state,
    $timeout,
    $transitions,
    _,
    ACCOUNT_STATE_PARAM,
    appReady,
    AUTHENTICATION_READY_ERROR,
    BUMPER_READY_ERROR,
    feature,
    fraudMitigationEventListener,
    MAIN_TITLE_KEY,
    MESSAGE,
    MIGRATION_TYPE,
    observabilityEventListener,
    onesieSrc2,
    ORG_MANAGER_ERROR,
    paymentInfoEditor,
    PRODUCT_LIST_EVENT,
    translationUtils
  ) {
    let configureNavTimeout;
    const service = {
      makeReady,
    };

    configureDynamicHeadTitle();
    configurePersistentQueryParams();

    return service;

    function makeReady(...services) {
      appReady
        .run(...services)
        .then(onAppReady)
        .catch(onAppReadyError);
    }

    ////////////

    function configureDynamicHeadTitle() {
      // listen to every successful transition to a new state in the application,
      // and update the site head title appropriately...
      $transitions.onSuccess({}, (transition) => {
        const {data, name} = transition.to();
        if (_.isNil(data.title)) {
          // since data attributes are inherited down the state tree hierarchy,
          // this error will only be thrown if a new head state/route is created
          // that fails to define a new page title for its hierarchical content
          throw new ReferenceError(
            `Transitioning to state (${name}) with undefined page title. Update state definition to include data.title attribute.`
          );
        }

        if (_.isFunction(data.title)) {
          // function passed in to data.title attribute, so call to receive
          // title for this state
          _.invoke(data, 'title', transition)
            .then((title) => {
              $document[0].title = `${title} | ${translationUtils.sanitizeSafeInstant(
                MAIN_TITLE_KEY
              )}`;
            })
            .catch(() => {
              // error fetching title, so default to main title (Admin Console)
              $document[0].title = translationUtils.sanitizeSafeInstant(MAIN_TITLE_KEY);
            });
        } else if (_.isEqual(data.title, MAIN_TITLE_KEY)) {
          // main title in use, so don't repeat
          $document[0].title = translationUtils.sanitizeSafeInstant(MAIN_TITLE_KEY);
        } else {
          // sub title in use, so append pipe (|) and main title (Admin Console)
          $document[0].title = `${translationUtils.sanitizeSafeInstant(
            data.title
          )} | ${translationUtils.sanitizeSafeInstant(MAIN_TITLE_KEY)}`;
        }
      });
    }

    function configureNav() {
      if (configureNavTimeout) {
        $timeout.cancel(configureNavTimeout);
      }

      configureNavTimeout = $timeout($injector.get('navManager').updateVisibility, 250);
    }

    function configurePersistentQueryParams() {
      // cli is used as a means of passing the referral client ID (for example for purchase)
      // it reloads with the same mock user.
      // We persist state so we can mock the azure login when adding a new IdP
      const persistentQueryParams = ['cli', 'state'];
      let savedParams = {};
      const deregisterOnBefore = $transitions.onEnter({}, () => {
        savedParams = _.pick($location.search(), persistentQueryParams);
        deregisterOnBefore();
      });
      $transitions.onSuccess({}, () => {
        $location.search(_.merge($location.search(), savedParams));
      });
    }

    function onActiveOrgChange($event, activeOrg) {
      $state.go('overview.overview2', {
        orgId: activeOrg.id,
      });

      configureNav();
      checkMigrationStatus();
      generateGlobalBanners();
    }

    function onActiveOrgChangeError($event, error, orgId) {
      switch (error) {
        case ORG_MANAGER_ERROR.TERMS_ACCEPTANCE_REQUIRED:
          $state.go('accept-terms', {
            orgId,
          });
          break;
        case ORG_MANAGER_ERROR.MIGRATING_TO_DELEGATION_GROUPS:
        case ORG_MANAGER_ERROR.DELEGATION_GROUPS_MIGRATION_ERROR:
          $state.go('migrating');
          break;
        // Split from the "default" case to ensure TERMS_REDIRECT
        // doesn't do anything else while the user is being redirected.
        case ORG_MANAGER_ERROR.TERMS_REDIRECT:
          break;
        default:
          break;
      }
    }

    function onActionEditPayment($event, {contract, invoiceId, locale, paypalDecision}) {
      if (invoiceId) {
        paymentInfoEditor.loadOneTimePayment({
          contract,
          invoiceId,
        });
      } else {
        const AuthenticatedUser = $injector.get('AuthenticatedUser');
        const OrganizationManager = $injector.get('OrganizationManager');
        const pieLoadOpts = {
          contractId: contract.id,
          contractType: 'team-direct',
          country: AuthenticatedUser.get().getCountryCode(),
          currency: _.get(contract, 'billingInfo.nextBillingAmount.currency.iso3code'),
          hasStock: OrganizationManager.getProductsForActiveOrg().hasStockProducts(),
          hidePaymentSelector: false,
          isNewPayment: false,
          locale,
          marketSegment: OrganizationManager.isActiveOrgEdu() ? 'EDU' : 'COM',
          orgId: OrganizationManager.getActiveOrgId(),
          paymentStatus: contract.complianceStatus,
          showPaymentInfoDialog: true,
        };

        // The paypal query param may have been removed from URL query params to avoid redundant loads,
        // so its value may be passed in the event payload.
        if (paypalDecision) {
          pieLoadOpts.paypal = {decision: paypalDecision};
        }

        // Open PIE
        paymentInfoEditor.load(pieLoadOpts);
      }
    }

    function onAppReady() {
      // we watch on start as the shell promise to update the URL while the APIs are still fetching
      /* eslint-disable angular/on-watch */

      $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.SUCCESS, onActiveOrgChange);
      $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.ERROR, onActiveOrgChangeError);
      $rootScope.$on(PRODUCT_LIST_EVENT.REFRESH, onProductListRefresh);
      $rootScope.$on('$locationChangeSuccess', onLocationChangeSuccess);

      if (feature.isEnabled('temp_contract_info_mini_app')) {
        // Until REBA (React Edit Billing App) is ready for integration, we will continue to use the PIE (Payment Info Editor) service
        $rootScope.$on(ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT, onActionEditPayment);
      }

      /* eslint-enable angular/on-watch */

      checkMigrationStatus();

      // Trigger the wideBanner 'state' call only for initial load
      triggerAppWideBannersAnalytics();

      generateGlobalBanners();

      fraudMitigationEventListener.configureEventListener();
      if (feature.isEnabled('temp_observability')) {
        observabilityEventListener.configureEventListener();
      }
    }

    // eslint-disable-next-line complexity -- more than 10 cases to special case
    function onAppReadyError(error) {
      const authentication = $injector.get('authentication');

      switch (error) {
        case AUTHENTICATION_READY_ERROR.USER_SIGNED_OUT:
          authentication.signIn();
          break;
        case ORG_MANAGER_ERROR.CONTRACT_CANCELLED:
          $state.go('contract-cancelled');
          break;
        case ORG_MANAGER_ERROR.ORG_NOT_FOUND:
          $state.go('org-not-found');
          break;
        case ORG_MANAGER_ERROR.NO_ORGS_AVAILABLE:
          $state.go('unauthorized');
          break;
        case ORG_MANAGER_ERROR.TERMS_ACCEPTANCE_REQUIRED:
          $state.go(
            'accept-terms',
            {},
            {
              location: 'replace',
            }
          );
          break;
        case BUMPER_READY_ERROR.UNPLANNED_OUTAGE:
          $state.go('unplanned-outage');
          break;
        case ORG_MANAGER_ERROR.MIGRATING_TO_DELEGATION_GROUPS:
        case ORG_MANAGER_ERROR.DELEGATION_GROUPS_MIGRATION_ERROR:
          $state.go('migrating');
          break;
        case ORG_MANAGER_ERROR.TERMS_REDIRECT:
          // Don't do anything while the user is being redirected
          break;
        case ORG_MANAGER_ERROR.UNABLE_TO_DETERMINE_USER:
          $state.go('unable-to-determine-user');
          break;
        default:
          // the primary difference here is we want to log the cause
          // any of the ones above are expected, and so not necessary to log
          $log.error('Unhandled appReady error: ', error);
          $state.go('unplanned-outage');
      }
    }

    function onLocationChangeSuccess() {
      const OrganizationManager = $injector.get('OrganizationManager');
      const activeOrgId = OrganizationManager.getActiveOrgId();
      const orgIdByPath = OrganizationManager.getOrgIdByPath();

      // when browser's back or forward button is clicked and org id on url is different than active org id,
      // we should load the org.
      if (!_.isEqual(activeOrgId, orgIdByPath) && _.endsWith(orgIdByPath, '@AdobeOrg')) {
        OrganizationManager.loadOrganization(orgIdByPath);
      }
    }

    function onProductListRefresh() {
      configureNav();
    }

    function checkMigrationStatus() {
      const OrganizationManager = $injector.get('OrganizationManager');
      const migrations = OrganizationManager.getMigrationsForActiveOrg();
      const migrationTypeStates = {
        [MIGRATION_TYPE.ESM_TYPE1]: 'migrating',
        [MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE]: 'legacy-to-ac-migrating',
        [MIGRATION_TYPE.T2E]: 't2e-migrating',
        [MIGRATION_TYPE.VIP2DIRECT]: 'vip2direct-migrating',
        [MIGRATION_TYPE.VIPMP]: 'vipmp-migrating',
      };

      // when this work gets ported to react/src2, we should move the type checking into migrationList and then just call getMigrationWithLockedOrg
      for (const type in migrationTypeStates) {
        if ({}.hasOwnProperty.call(migrationTypeStates, type)) {
          const migration = migrations.findByType(type);
          if (_.invoke(migration, 'isOrgLocked')) {
            $state.go(migrationTypeStates[type]);
            return;
          }
        }
      }
    }

    function generateGlobalBanners() {
      // Agent Access is always first, so it isn't lost to internal users
      onesieSrc2.core.notifier.notifyAccessingAsAdobeAgentBanner();
      // These are first to ensure they're most visible
      onesieSrc2.core.notifier.notifyContractMigrationBanners();
      onesieSrc2.core.notifier.notifyContractComplianceTrialBanners();
      onesieSrc2.core.notifier.notifyTermsAndConditionsBanners();
      onesieSrc2.core.notifier.notifyOrganizationMigrationBanners();
      onesieSrc2.core.notifier.notifyContractComplianceBanners();
      onesieSrc2.core.notifier.notifyContractRenewalBanners();

      if (feature.isEnabled('temp_vip_mp_m7_3yc')) {
        onesieSrc2.core.notifier.notifyThreeYearCommitGlobalBanner();
      }

      onesieSrc2.core.notifier.notifyContractStateBanners();
      onesieSrc2.core.notifier.notifyOrganizationSettingsBanners();
      onesieSrc2.core.notifier.notifyAutoAssignRuleErrorBanners();

      if (feature.isEnabled('temp_saml_certs')) {
        onesieSrc2.core.notifier.notifyCertificatesBanners();
      }
    }

    function triggerAppWideBannersAnalytics() {
      const binkySrc2 = $injector.get('binkySrc2');

      binkySrc2.services.analytics.analyticsUtils.dispatchPageAnalytics({
        name: 'wideBanner',
      });
    }
  }
})();
