import {
  ActionButton,
  ActionMenu,
  DialogContainer,
  Item,
  Text,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import Edit from '@spectrum-icons/workflow/Edit';
import Link from '@spectrum-icons/workflow/Link';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import LinkDomainToDirectoryModal from 'features/settings/components/domains/link-domain-to-directory-modal/LinkDomainToDirectoryModal';
import TransferDomainModal from 'features/settings/components/domains/transfer-domain-modal/TransferDomainModal';
import ValidateDomainModal from 'features/settings/components/domains/validate-domain-modal/ValidateDomainModal';

const DIALOGS = {
  LINK_DOMAIN_TO_DIRECTORY_MODAL: 'LINK_DOMAIN_TO_DIRECTORY_MODAL',
  TRANSFER_DOMAIN_MODAL: 'EDIT_DIRECTORY_MODAL',
  VALIDATE_DOMAIN_OWNERSHIP: 'VALIDATE_DOMAIN_OWNERSHIP',
};

const ACTIONS = [
  {
    ariaLabelId: 'settings.domainsTable.linkDirectoryAction.ariaLabel',
    dialogId: DIALOGS.LINK_DOMAIN_TO_DIRECTORY_MODAL,
    icon: <Link />,
    isVisible: (domain) => domain.needsDirectory,
  },
  {
    ariaLabelId: 'settings.domainsTable.validateDomainAction.ariaLabel',
    dialogId: DIALOGS.VALIDATE_DOMAIN_OWNERSHIP,
    icon: <Checkmark />,
    isVisible: (domain) => domain.needsValidation,
  },
  {
    ariaLabelId: 'settings.domainsTable.transferDomainAction.ariaLabel',
    dialogId: DIALOGS.TRANSFER_DOMAIN_MODAL,
    icon: <Edit />,
    //
    // @TODO:
    // once we have Trusts context in provider tree, add check here to see if the domain belongs to a trusted directory,
    // while also reinstating the commented-out checks below. hide this action until then (not present in src1 anyway)
    //
    isVisible: () => false,
    // isVisible: (domain) => domain.isActive && !domain.isTrusted && !domain.isSynced,
  },
];

/**
 * Gets the available actions for a domain
 */
const getActions = (domain) => ACTIONS.filter((action) => action.isVisible(domain));

const DomainListTableRowActions = ({
  domain,
  onDomainsLinked,
  onDomainsTransferred,
  onDomainsValidated,
}) => {
  const intl = useIntl();
  const [dialogId, setDialogId] = useState();

  const actions = getActions(domain);

  return (
    <>
      {/* When only one action is available */}
      {actions.length === 1 && (
        <TooltipTrigger>
          <ActionButton
            aria-label={intl.formatMessage({
              id: actions[0].ariaLabelId,
            })}
            isQuiet
            onPress={() => setDialogId(actions[0].dialogId)}
          >
            {actions[0].icon}
          </ActionButton>
          <Tooltip>
            <FormattedMessage id={actions[0].ariaLabelId} />
          </Tooltip>
        </TooltipTrigger>
      )}

      {/* When multiple actions are available */}
      {actions.length > 1 && (
        <ActionMenu
          isQuiet
          /* needed to align menu in button group */
          marginStart="size-200"
          onAction={setDialogId}
        >
          {actions.map((action) => (
            <Item key={action.dialogId}>
              {action.icon}
              <Text>
                {intl.formatMessage({
                  id: action.ariaLabelId,
                })}
              </Text>
            </Item>
          ))}
        </ActionMenu>
      )}

      <DialogContainer
        onDismiss={() => {
          setDialogId(null);
        }}
      >
        {dialogId === DIALOGS.LINK_DOMAIN_TO_DIRECTORY_MODAL && (
          <LinkDomainToDirectoryModal domains={[domain]} onConfirm={onDomainsLinked} />
        )}

        {dialogId === DIALOGS.VALIDATE_DOMAIN_OWNERSHIP && (
          <ValidateDomainModal domains={[domain]} onDomainsValidated={onDomainsValidated} />
        )}

        {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- temporarily exclude this from coverage until domain transfer action is reinstated
          // istanbul ignore next -- not testing this
          dialogId === DIALOGS.TRANSFER_DOMAIN_MODAL && (
            <TransferDomainModal domains={[domain]} onDomainsTransferred={onDomainsTransferred} />
          )
        }
      </DialogContainer>
    </>
  );
};

DomainListTableRowActions.propTypes = {
  /**
   * Domain associated with the actions
   */
  domain: PropTypes.shape({
    isActive: PropTypes.bool,
    isSynced: PropTypes.bool,
    isTrusted: PropTypes.bool,
    needsDirectory: PropTypes.bool,
    needsValidation: PropTypes.bool,
  }),
  /**
   * Callback to be called when domains are linked to a directory
   */
  onDomainsLinked: PropTypes.func,
  /**
   * Callback to be called when domains are transferred to a different directory
   */
  onDomainsTransferred: PropTypes.func,
  /**
   * Callback to be called when domains are validated
   */
  onDomainsValidated: PropTypes.func,
};

export default DomainListTableRowActions;
