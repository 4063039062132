import {
  CollaborationPolicies,
  CollaborationPoliciesConcurrencyError,
  RESTRICTION_STATUS,
  feature,
} from '@admin-tribe/binky';
import {GoUrl, OverlayWait, showError, showSuccess} from '@admin-tribe/binky-ui';
import {
  Checkbox,
  CheckboxGroup,
  DialogContainer,
  Radio,
  RadioGroup,
  Text,
  View,
  Well,
} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {isT2eOrg} from 'core/organizations/access/organizationAccess';
import ConfirmationButtonGroup from 'features/settings/common/components/confirmation-button-group/ConfirmationButtonGroup';

import SharingOptionsHeading from '../sharing-options-heading/SharingOptionsHeading';

import SharingRestrictionsConfirmationDialog from './SharingRestrictionsConfirmationDialog';

// The "Sharing restrictions policy" section for the "Sharing options" tab.
const SharingRestrictionsPolicySection = ({
  canSetSharingOptions,
  collaborationPolicies,
  hasDomainClaiming,
  onTriggerAnalytics,
}) => {
  const currentRestrictionStatus = collaborationPolicies.getRestrictionStatus();
  const initializeValueState = () =>
    feature.isDisabled('temp_asset_settings')
      ? [currentRestrictionStatus] // string []
      : currentRestrictionStatus; // string

  const intl = useIntl();
  const descriptionId = useId();
  const headingId = useId();
  const [value, setValue] = useState(initializeValueState());
  const [isOpen, setIsOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(null); // string
  const [isLoading, setIsLoading] = useState(false);
  const isT2E = useRef(isT2eOrg()).current;

  const isConfirmationButtonGroupDisabled =
    feature.isEnabled('temp_asset_settings') && value === currentRestrictionStatus;

  const NAMESPACE = 'settings.assetSettings.assetSharing.sharingOptions.sharingRestrictionsPolicy';

  const onChange = (selected /* string[] */) => {
    if (feature.isDisabled('temp_asset_settings')) {
      // If the checkbox is unchecked, there will be 0 selected.
      // Return immediately which effectively blocks the uncheck.
      if (selected.length > 1) {
        // At this point there will be 2 checkboxes selected. The initial selection and the checkbox just selected.
        const newValue = selected[selected.length - 1];
        setPendingValue(newValue);
        setIsOpen(true);
      }
    } else {
      setValue(selected);
      setPendingValue(selected);
    }
  };

  const onDiscard = () => {
    setValue(currentRestrictionStatus);
    setPendingValue(null);
  };

  const onDismiss = () => {
    setIsOpen(false);
    if (feature.isEnabled('temp_asset_settings')) {
      setValue(currentRestrictionStatus);
    }
    setPendingValue(null);
  };

  const onSave = () => {
    setIsOpen(true);
  };

  const onUpdateRestrictionStatus = async () => {
    try {
      setIsLoading(true);
      if (feature.isDisabled('temp_asset_settings')) {
        await collaborationPolicies.setRestrictionStatus(pendingValue);
        setValue([pendingValue]);
      } else {
        setValue(pendingValue);
        await collaborationPolicies.setRestrictionStatus(pendingValue);
      }
      setPendingValue(null);
      showSuccess();
    } catch (error) {
      // Precondition Failed (412) indicates a policy has been updated and local copy needs refresh.
      const msg =
        error instanceof CollaborationPoliciesConcurrencyError
          ? intl.formatMessage({id: `${NAMESPACE}.error.concurrency`})
          : undefined;
      showError(msg);
      return;
    } finally {
      setIsLoading(false);
    }

    onTriggerAnalytics({
      attributeProps: {
        sharingRestrictionPolicy: pendingValue,
      },
      componentMethod: 'updateAssetSharingRestriction',
    });
  };

  return (
    <>
      <OverlayWait isLoading={isLoading} showContent size="S">
        <SharingOptionsHeading canSetSharingOptions={canSetSharingOptions} id={headingId}>
          {intl.formatMessage({id: `${NAMESPACE}.title`})}
        </SharingOptionsHeading>

        {!isT2E && (
          <View data-testid="not-t2e-text" marginBottom="static-size-200">
            {intl.formatMessage(
              {id: `${NAMESPACE}.notT2E`},
              {
                b: (str) => <strong>{str}</strong>,
                goUrl: (str) => <GoUrl name="aac_asset_sharing_accounts">{str}</GoUrl>,
              }
            )}
          </View>
        )}

        <View marginBottom="static-size-200">{intl.formatMessage({id: `${NAMESPACE}.helpx`})}</View>

        {/* Change of context notice is required for accessibility Success Criterion 3.2.2 */}
        <View id={descriptionId} marginBottom="static-size-200">
          {intl.formatMessage({id: `${NAMESPACE}.contextChange`})}
        </View>

        {/* The Checkbox label should be succinct. Details belong in the description which is linked to the Checkbox. */}
        {feature.isDisabled('temp_asset_settings') ? (
          <Well>
            <CheckboxGroup
              aria-describedby={descriptionId}
              aria-labelledby={headingId}
              isDisabled={!canSetSharingOptions}
              onChange={onChange}
              value={value}
            >
              <Checkbox aria-describedby="status-none" value={RESTRICTION_STATUS.NONE}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.none.label`})}</strong>
              </Checkbox>
              <Text id="status-none">
                {intl.formatMessage({id: `${NAMESPACE}.radio.none.description`})}
              </Text>
              <Checkbox
                aria-describedby="status-public-link"
                value={RESTRICTION_STATUS.PUBLIC_LINK}
              >
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.publicLink.label`})}</strong>
              </Checkbox>
              <Text id="status-public-link">
                {intl.formatMessage({id: `${NAMESPACE}.radio.publicLink.description`})}
              </Text>
              <Checkbox
                aria-describedby="status-domain-users"
                value={RESTRICTION_STATUS.DOMAIN_USERS}
              >
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.domainUsers.label`})}</strong>
              </Checkbox>
              <Text id="status-domain-users">
                {intl.formatMessage({
                  id: hasDomainClaiming
                    ? `${NAMESPACE}.radio.domainUsers.description.hasDomainClaiming`
                    : `${NAMESPACE}.radio.domainUsers.description.noDomainClaiming`,
                })}
              </Text>
            </CheckboxGroup>
          </Well>
        ) : (
          /* The RadioButton label should be succinct. Details belong in the description which is linked to the RadioButton. */
          <Well>
            <RadioGroup
              aria-describedby={descriptionId}
              aria-labelledby={headingId}
              isDisabled={!canSetSharingOptions}
              onChange={onChange}
              value={value}
            >
              <Radio aria-describedby="status-none" value={RESTRICTION_STATUS.NONE}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.none.label`})}</strong>
              </Radio>
              <Text id="status-none">
                {intl.formatMessage({id: `${NAMESPACE}.radio.none.description`})}
              </Text>
              <Radio aria-describedby="status-public-link" value={RESTRICTION_STATUS.PUBLIC_LINK}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.publicLink.label`})}</strong>
              </Radio>
              <Text id="status-public-link">
                {intl.formatMessage({id: `${NAMESPACE}.radio.publicLink.description`})}
              </Text>
              <Radio aria-describedby="status-domain-users" value={RESTRICTION_STATUS.DOMAIN_USERS}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.domainUsers.label`})}</strong>
              </Radio>
              <Text id="status-domain-users">
                {intl.formatMessage({
                  id: hasDomainClaiming
                    ? `${NAMESPACE}.radio.domainUsers.description.hasDomainClaiming`
                    : `${NAMESPACE}.radio.domainUsers.description.noDomainClaiming`,
                })}
              </Text>
            </RadioGroup>
            <ConfirmationButtonGroup
              isDisabled={isConfirmationButtonGroupDisabled}
              onDiscard={onDiscard}
              onSave={onSave}
            />
          </Well>
        )}
      </OverlayWait>
      <DialogContainer onDismiss={onDismiss}>
        {isOpen && (
          <SharingRestrictionsConfirmationDialog
            onPrimaryAction={onUpdateRestrictionStatus}
            restrictionStatus={pendingValue}
          />
        )}
      </DialogContainer>
    </>
  );
};

SharingRestrictionsPolicySection.propTypes = {
  /**
   * True if the radio group in the section is enabled.
   */
  canSetSharingOptions: PropTypes.bool.isRequired,
  /**
   * The collaboration policies for the org.
   */
  collaborationPolicies: PropTypes.instanceOf(CollaborationPolicies),
  /**
   * True if org can claim a domain.
   */
  hasDomainClaiming: PropTypes.bool.isRequired,
  /**
   * The Analytics callback which is triggered when a selection is changed.
   */
  onTriggerAnalytics: PropTypes.func.isRequired,
};

export default SharingRestrictionsPolicySection;
