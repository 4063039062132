import {ActionButton, Checkbox, Content, Dialog, DialogTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {KCCC_SAP_CODE, VERSION_FILTERS} from 'features/packages/packagesConstants';

import {availableFilters, filterToIdMapping, showFilter} from './otherVersionsFilterButtonUtils';

// OtherVersionsFilterButton contains the filters to apply on the products appearing in available products section
const OtherVersionsFilterButton = ({
  adobeProductsUi,
  appFilters,
  currentExpandedProductSapCode,
  isEnterpriseSupportPolicyApplicable,
  setAppFilters,
  setCurrentExpandedProductSapCode,
  showExtraProductsInTemplates,
}) => {
  const intl = useIntl();

  const updateCurrentExpandedSapCodes = (isSelected, sapCodesOfFilterType) => {
    // If selected add sapcode to currentExpandedProductSapCode, else then remove the sapcodes Of that filterType from currentExpandedProductSapCode
    if (isSelected) {
      const uniqueCurrentExpandedProductSapCode = new Set([
        ...currentExpandedProductSapCode,
        ...sapCodesOfFilterType,
      ]);
      setCurrentExpandedProductSapCode([...uniqueCurrentExpandedProductSapCode]);
    } else {
      setCurrentExpandedProductSapCode(
        currentExpandedProductSapCode.filter((sapCode) => !sapCodesOfFilterType.includes(sapCode))
      );
    }
  };

  // Retrieve sapcodes of a particular version type
  const getFilterTypeSapCodes = (versionType) => [
    ...new Set(
      adobeProductsUi
        .filter(
          (product) =>
            product[versionType] &&
            !(product.sapCode === KCCC_SAP_CODE || !product.visible || product.selected)
        )
        .map((product) => product.sapCode)
    ),
  ];

  const onSelectionChange = (filterType, isSelected) => {
    switch (filterType) {
      case VERSION_FILTERS.SHOW_BETA:
        updateCurrentExpandedSapCodes(isSelected, getFilterTypeSapCodes('betaAppApui'));
        break;
      case VERSION_FILTERS.SHOW_PRERELEASE:
        updateCurrentExpandedSapCodes(isSelected, getFilterTypeSapCodes('preReleaseApui'));
        break;
      default:
        break;
    }

    setAppFilters({
      ...appFilters,
      [filterType]: isSelected,
    });
  };

  const isFiltersButtonDisabled = useMemo(() => {
    let areFiltersAvailable = false;
    availableFilters.forEach((filterType) => {
      // Disable filters button if no filter other than latest version shown
      // Excluding latest versions filter as it is always applied/shown
      if (filterType !== 'showLatest') {
        areFiltersAvailable =
          areFiltersAvailable ||
          showFilter(
            adobeProductsUi,
            filterType,
            isEnterpriseSupportPolicyApplicable,
            showExtraProductsInTemplates
          );
      }
    });
    return !areFiltersAvailable;
  }, [adobeProductsUi, isEnterpriseSupportPolicyApplicable, showExtraProductsInTemplates]);

  return (
    <DialogTrigger type="popover">
      <ActionButton isDisabled={isFiltersButtonDisabled}>
        {intl.formatMessage({id: 'packages.createPackage.productSelection.otherVersionsLabel'})}
      </ActionButton>
      <Dialog width="calc(size-3600 + size-50)">
        <Content>
          <div role="group">
            {availableFilters
              .filter((filterType) =>
                showFilter(
                  adobeProductsUi,
                  filterType,
                  isEnterpriseSupportPolicyApplicable,
                  showExtraProductsInTemplates
                )
              )
              .map((filterType) => (
                <Checkbox
                  key={filterType}
                  isEmphasized
                  isSelected={appFilters[filterType]}
                  marginTop="size-100"
                  onChange={(isSelected) => {
                    onSelectionChange(filterType, isSelected);
                  }}
                >
                  {intl.formatMessage({
                    id: `packages.createPackage.chooseApps.availableApps.filters.${filterToIdMapping(
                      filterType
                    )}`,
                  })}
                </Checkbox>
              ))}
          </div>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

OtherVersionsFilterButton.propTypes = {
  /**
   * Exhaustive list of packageable products for the current platform
   */
  adobeProductsUi: PropTypes.instanceOf(Array).isRequired,
  /**
   * App filters that can be applied on the available products list
   */
  appFilters: PropTypes.shape({
    /**
     * Search text
     */
    searchText: PropTypes.string,
    /**
     * If archived products should be shown
     */
    showArchived: PropTypes.bool,
    /**
     * If beta products should be shown
     */
    showBeta: PropTypes.bool,
    /**
     * If latest products should be shown
     */
    showLatest: PropTypes.bool,
    /**
     * If long term supported products should be shown
     */
    showLTS: PropTypes.bool,
    /**
     * If older products should be shown
     */
    showOlder: PropTypes.bool,
    /**
     * If pre-release products should be shown
     */
    showPreRelease: PropTypes.bool,
  }).isRequired,
  /**
   * Expanded product accordions sap codes
   */
  currentExpandedProductSapCode: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Function to set app filtera
   */
  setAppFilters: PropTypes.func.isRequired,
  /**
   * Function to set current expanded product sapcode accordions
   */
  setCurrentExpandedProductSapCode: PropTypes.func.isRequired,
  /**
   * Indicates if extra products should be shown in templates
   */
  showExtraProductsInTemplates: PropTypes.bool.isRequired,
};
export default OtherVersionsFilterButton;
