import {ConsumableUsageList} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import ConsumablesListTable from 'features/products/components/consumables-list-table/ConsumablesListTable';
import PaginatedConsumablesListTableSection from 'features/products/components/consumables-list-table/PaginatedConsumablesListTableSection';
import UnpaginatedConsumablesListTableSection from 'features/products/components/consumables-list-table/UnpaginatedConsumablesListTableSection';

/**
 * @description Component to decide which stock credit pack usage list table
 *   section to display. Since expired stock credit packs return no consumables,
 *   we present an unpaginated error view, avoiding a call we know will be
 *   empty. Current stock credit packs return consumables and use a paginated
 *   API, so we can fetch the first page of results without a problem
 * @param {Boolean} [errorUsage] - to indicate if there was an error fetching
 *   usage list. Defaults to false
 * @param {ProductGroupProductList} productGroup - to build table names
 *   (rows) from
 * @param {ConsumableUsageList} [usageList] - to decide which list table
 *   section to build
 */
const ConsumablesListManager = ({errorUsage = false, productGroup, usageList}) => {
  const intl = useIntl();

  return usageList ? (
    // this handles current stock credit packs
    <PaginatedConsumablesListTableSection hasLoadingError={!!errorUsage} usageList={usageList}>
      <ConsumablesListTable productGroup={productGroup} />
    </PaginatedConsumablesListTableSection>
  ) : (
    // this handles expired stock credit packs (known issue on external
    // team to fix - in this case the usage list is undefined, as no
    // consumables are returned)
    <UnpaginatedConsumablesListTableSection hasLoadingError={!!errorUsage} usageList={usageList}>
      <ConsumablesListTable
        noItemsFoundContentMessage={intl.formatMessage({
          id: `products.consumables.list.table.noItemsFoundContentMessage`,
        })}
        noItemsFoundHeadingMessage={intl.formatMessage({
          id: 'products.consumables.list.table.noItemsFoundHeaderMessage',
        })}
        productGroup={productGroup}
      />
    </UnpaginatedConsumablesListTableSection>
  );
};

ConsumablesListManager.propTypes = {
  /**
   * Boolean to indicate if there was an error fetching usage list. Defaults to false
   */
  errorUsage: PropTypes.bool,
  /**
   * ProductGroupProductList to build table from
   */
  productGroup: PropTypes.instanceOf(ProductGroupProductList).isRequired,
  /**
   * ConsumableUsageList to decide which list table section to build
   */
  usageList: PropTypes.instanceOf(ConsumableUsageList),
};

ConsumablesListManager.displayName = 'ConsumablesListManager';
export default ConsumablesListManager;
