import {ModalContent, ModalDescription, ModalHeading} from '@admin-tribe/binky-ui';
import {Grid, Heading, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductUpgradeSummarySection from 'features/products/product-upgrade-summary/ProductUpgradeSummarySection';

import {MAX_MIGRATION_NUMBER} from './OfferSwitchMigrationConstants';
import {useOfferSwitchMigrationContext} from './context/OfferSwitchMigrationContext';

import './OfferSwitchMigrationIntro.pcss';

const CSV_GUIDES = ['newPlan', 'csv.moreThan50', 'csv.downloadTemplate', 'csv.templateInfo'];
const WIZARD_GUIDES = [
  'newPlan',
  'wizard.expiringLicense',
  'wizard.nextStep',
  'wizard.reviewChanges',
];

const OfferSwitchMigrationIntro = () => {
  const intl = useIntl();
  const {migrationData} = useOfferSwitchMigrationContext();
  const productGroups = migrationData.productGroups || [];
  // Default behavior is to show the wizard guides even with error.
  const guideSuffixes =
    migrationData.eligibleMigrations.length > MAX_MIGRATION_NUMBER ? CSV_GUIDES : WIZARD_GUIDES;

  const getString = (suffix) =>
    intl.formatMessage(
      {id: `users.offerSwitchMigrationIntro.${suffix}`},
      {
        bold: (str) => <span styleName="guides-bold">{str}</span>,
      }
    );

  return (
    <>
      <ModalHeading>{getString('title')}</ModalHeading>
      <ModalDescription>{getString('description')}</ModalDescription>
      <ModalContent>
        <Grid areas={['guides', 'table']} columns={['1fr', '0fr']} gap="size-150">
          <View width="500px">
            <Heading>{getString('guide.title')}</Heading>
            <ul styleName="guides">
              {guideSuffixes.map((suffix) => (
                <li key={suffix}>{getString(`guide.${suffix}`)}</li>
              ))}
            </ul>
          </View>
          <View marginTop="size-200">
            <ProductUpgradeSummarySection productGroups={productGroups} />
          </View>
        </Grid>
      </ModalContent>
    </>
  );
};

export default OfferSwitchMigrationIntro;
