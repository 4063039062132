(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    _,
    auth,
    feature,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    OrganizationManager,
    productAccess,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.ASSIGN_ENTERPRISE_LICENSES,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) => {
            if (productList.hasOnlyTeamProducts()) {
              return $q.reject();
            }

            const isAtLeastOneProductUnassigned = _.some(
              productList.items,
              (product) =>
                !!(productAccess.canAssignUser(product) && product.provisionedQuantity === 0)
            );
            if (isAtLeastOneProductUnassigned) {
              const namespace = 'overview.highValueActionArea.setUpTasks.assignEnterpriseLicenses';
              return [
                new HvaCard({
                  ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                  description: $translate.instant(`${namespace}.description`),
                  header: $translate.instant(`${namespace}.header`),
                  id: 'hva-assign-enterprise-licenses',
                  onCTAClick: () => {
                    throw new Error('Angular products has been removed');
                  },
                  sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                  svgClass: SVG_CLASS.ASSIGN_LICENSES,
                }),
              ];
            }
            return $q.reject();
          });
        }
        return $q.reject();
      }
    }
  }
})();
