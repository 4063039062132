import {useTabbedNavigation} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {generatePath, useLoaderData, useParams} from 'react-router-dom';

import DomainListTable from 'features/settings/common/components/domain-list-table/DomainListTable';
import TrusteeListTable from 'features/settings/common/components/trustee-list-table/TrusteeListTable';
import AccessRequestsListTable from 'features/settings/components/access-requests/AccessRequestsListTable';
import DirectoryListPorted from 'features/settings/components/directories/directory-list/DirectoryListPorted';
import {
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
} from 'features/settings/routing/settingsPaths';

/**
 * Displays the tabs for the Identity page
 */
const IdentityTabs = () => {
  const params = useParams();
  const {canViewDomains, canViewTrusts, canViewAccessRequests} = useLoaderData();

  const NAVIGATION_KEYS = {
    ACCESS_REQUESTS: 'access-request',
    DIRECTORIES: 'directories',
    DOMAINS: 'domains',
    TRUSTEES: 'trustees',
  };

  const navigationTabs = [
    {
      default: true,
      key: NAVIGATION_KEYS.DIRECTORIES,
      pathname: generatePath(PATH_SETTINGS_IDENTITY_DIRECTORIES, params),
    },
    {
      key: NAVIGATION_KEYS.DOMAINS,
      pathname: generatePath(PATH_SETTINGS_IDENTITY_DOMAINS, params),
    },
    {
      key: NAVIGATION_KEYS.TRUSTEES,
      pathname: generatePath(PATH_SETTINGS_IDENTITY_TRUSTEES, params),
    },
    {
      key: NAVIGATION_KEYS.ACCESS_REQUESTS,
      pathname: generatePath(PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS, params),
    },
  ];

  const {onTabChange, selectedTabKey} = useTabbedNavigation(navigationTabs);

  return (
    <Tabs onSelectionChange={onTabChange} selectedKey={selectedTabKey}>
      <TabList>
        <Item key={NAVIGATION_KEYS.DIRECTORIES}>
          <FormattedMessage id="settings.identitySettings.scorecards.directories" />
        </Item>
        {canViewDomains && (
          <Item key={NAVIGATION_KEYS.DOMAINS}>
            <FormattedMessage id="settings.identitySettings.scorecards.domains" />
          </Item>
        )}
        {canViewTrusts && (
          <Item key={NAVIGATION_KEYS.TRUSTEES}>
            <FormattedMessage id="settings.identitySettings.scorecards.trustees" />
          </Item>
        )}
        {canViewAccessRequests && (
          <Item key={NAVIGATION_KEYS.ACCESS_REQUESTS}>
            <FormattedMessage id="settings.identitySettings.scorecards.accessRequests" />
          </Item>
        )}
      </TabList>
      <TabPanels>
        <Item key={NAVIGATION_KEYS.DIRECTORIES}>
          <DirectoryListPorted />
        </Item>
        <Item key={NAVIGATION_KEYS.DOMAINS}>
          <DomainListTable />
        </Item>
        <Item key={NAVIGATION_KEYS.TRUSTEES}>
          <TrusteeListTable />
        </Item>
        <Item key={NAVIGATION_KEYS.ACCESS_REQUESTS}>
          <AccessRequestsListTable />
        </Item>
      </TabPanels>
    </Tabs>
  );
};

export default IdentityTabs;
