import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import useAppIntegrationList from './useAppIntegrationList';

/**
 * AppIntegrationTableContext maintains shared state and callbacks for the
 * AppIntegrations tables.
 */
const AppIntegrationTableContext = createContext(null);

/**
 * @description Helper hook to get shared state for components under the /application-integrations route.
 *
 * @example
 * const {appIntegrationList, error, isLoading, refreshList, ...callbacks} =
 *    useAppIntegrationTableContext();
 *
 * @returns AppIntegrationTableContext. Not be confused with a PageContext.
 */
const useAppIntegrationTableContext = () => {
  const context = useContext(AppIntegrationTableContext);
  if (!context) {
    throw new Error(
      'Cannot call useAppIntegrationTableContext outside of a AppIntegrationTableContextProvider.'
    );
  }
  return context;
};

// The Provider for the AppIntegrationTableContext. See PropTypes for params.
const AppIntegrationTableContextProvider = ({
  children,
  hasAcceptedUsers,
  hasPolicies,
  onManagePolicyPress,
  onManageUsersPress,
  onViewPolicyDetailsPress,
  orgId,
}) => {
  const listState = useAppIntegrationList({hasAcceptedUsers, hasPolicies, orgId});
  const context = useMemo(
    () => ({
      ...listState,
      onManagePolicyPress,
      onManageUsersPress,
      onViewPolicyDetailsPress,
    }),
    [listState, onManagePolicyPress, onManageUsersPress, onViewPolicyDetailsPress]
  );
  return (
    <AppIntegrationTableContext.Provider value={context}>
      {children}
    </AppIntegrationTableContext.Provider>
  );
};

AppIntegrationTableContextProvider.propTypes = {
  /** The children of the AppIntegrationTableContextProvider. */
  children: PropTypes.node.isRequired,
  /** Fetches integrations with accepted users if true. */
  hasAcceptedUsers: PropTypes.bool,
  /** Fetches integrations with a policy if true. */
  hasPolicies: PropTypes.bool,
  /** Callback function for when Manage policy menuitem is selected in the Actions column */
  onManagePolicyPress: PropTypes.func,
  /** Callback function for when Manage users action is selected in the Actions column */
  onManageUsersPress: PropTypes.func,
  /** Callback function for when View details menuitem is selected in the Actions column */
  onViewPolicyDetailsPress: PropTypes.func,
  /** The org id associated with the app integrations. */
  orgId: PropTypes.string,
};

export {AppIntegrationTableContextProvider, useAppIntegrationTableContext};
