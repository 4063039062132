(function () {
  /**
   * @deprecated moved to src2/app/common/components/search/search-help-topics
   *
   * @ngdoc component
   * @name app.widgets.search:appSearchHelpTopics
   *
   * @description the search help topics widget
   *
   * @param {String} pulldownId - The ID to assign to the pulldown-content div.
   * @param {Boolean} [showHeader] - Whether to show the search's explanation-header. Default is true.
   *
   * @example <app-search-help-topics
   *            pulldown-id="help-rail-search-pulldown"
   *            show-header="false">
   *          </app-search-help-topics>
   */

  angular.module('app.widgets.search').component('appSearchHelpTopics', {
    bindings: {
      pulldownId: '@',
      showHeader: '<?',
    },
    controller,
    templateUrl: 'app/widgets/search/search-help-topics/search-help-topics.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $timeout,
    $translate,
    $window,
    _,
    AnalyticsEvent,
    feature,
    HelpSearchList,
    panelManager,
    SEARCH_RESULTS_PULLDOWN_ID,
    searchTopicsHelper
  ) {
    const DEBOUNCE_TIME = 1000;
    const SEARCH_QUERY_MIN_LENGTH = 1;

    const vm = this;

    _.defaults(vm, {
      showHeader: true,
    });

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        getPulldownId,
        getShowHeader,
        helpSearchList: new HelpSearchList({
          enable_spelling_correction: true,
          limit: 10,
          sort_order: 'desc',
          sort_orderby: 'relevancy',
          start_index: 0,
        }),
        onInputChange,
        onInputClick,
        onInputSubmit,
        onMouseEnter,
        searchPlaceholder: $translate.instant('widgets.search.searchHelpTopics.searchPlaceholder'),
        tooltipContent: $translate.instant('widgets.search.searchHelpTopics.tooltipContent'),
      });

      reset();
    }

    function getPulldownId() {
      return vm.pulldownId;
    }

    function getShowHeader() {
      return vm.showHeader;
    }

    function onInputChange(newSearchInput) {
      if (vm.input !== newSearchInput) {
        vm.input = newSearchInput;
        panelManager.open(getPulldownId());

        vm.waitOnPagePromise = $q.defer().promise;
        if (vm.searchInputPromise) {
          $timeout.cancel(vm.searchInputPromise);
          vm.searchInputPromise = undefined;
        }

        if (_.size(vm.input) < SEARCH_QUERY_MIN_LENGTH) {
          triggerSearchAnalytics('onInputChange.default', vm.input);
          reset();
        } else {
          vm.searchInputPromise = $timeout(() => {
            vm.showDefault = false;
            triggerSearchAnalytics('onInputChange.search', vm.input);
            vm.waitOnPagePromise = vm.helpSearchList.find(vm.input);
          }, DEBOUNCE_TIME);
        }
      }
    }

    function onInputClick() {
      panelManager.open(getPulldownId());
    }

    function onInputSubmit() {
      if (vm.input) {
        triggerSearchAnalytics('onInputSubmit', vm.input);
        $window.open(searchTopicsHelper.getHelpxUrl(vm.input), '_blank');
      }
    }

    function onMouseEnter() {
      AnalyticsEvent.dispatch({
        componentMethod: 'onMouseEnter',
        componentMethodType: 'popover',
        componentName: 'appSearchHelpTopics.tooltip',
        pageContext: 'search help',
      });
    }

    /* Private functions */
    function reset() {
      vm.waitOnPagePromise = $q.resolve();
      vm.helpSearchList.clear();
      vm.showDefault = true;
    }

    function triggerSearchAnalytics(method, query) {
      AnalyticsEvent.dispatch({
        attributes: {
          searchQuery: query,
        },
        componentMethod: method,
        componentMethodType: 'submit',
        componentName: 'appSearchHelpTopics',
        pageContext: 'search help',
      });
    }
  }
})();
