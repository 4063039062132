import {LicenseGroup} from '@admin-tribe/binky';
import {DetailSection} from '@admin-tribe/binky-ui';
import {ToggleSection} from '@pandora/react-toggle-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const UserNotificationsDetailSection = ({productProfile}) => {
  const intl = useIntl();

  return (
    <DetailSection
      data-testid="user-notifications-detail-section"
      headingLevel={3}
      title={intl.formatMessage({
        id: `products.userNotificationsDetailSection.notificationTitle`,
      })}
    >
      <ToggleSection
        backgroundColor="gray-100"
        defaultChecked={productProfile.notifications}
        onToggle={(value) => {
          productProfile.notifications = value;
        }}
        paddingTop="size-0"
        paddingX="size-50"
        toggleDescription={intl.formatMessage({
          id: 'products.userNotificationsDetailSection.notifyToggleDescription',
        })}
        toggleLabel={intl.formatMessage({
          id: 'products.userNotificationsDetailSection.notifyToggleLabel',
        })}
      />
    </DetailSection>
  );
};

UserNotificationsDetailSection.propTypes = {
  /**
   * The product profile / license group to display admins list, user quantities
   * and 'Enabled Services' products that can be enabled/disabled from the drawer
   */
  productProfile: PropTypes.instanceOf(LicenseGroup).isRequired,
};

export default UserNotificationsDetailSection;
