import {SELECTION_MODE, TableFilters, TableSection} from '@pandora/react-table-section';
import React from 'react';
import {useIntl} from 'react-intl';

import FederatedDomainsSectionTable from 'features/settings/components/import-federated-domains-modal/FederatedDomainsSectionTable';
import {useImportFederatedDomainsModalContext} from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModalContext';

import './ImportFederatedDomainsModal.pcss';

const FederatedDomainsTable2 = () => {
  const intl = useIntl();

  const {federatedDomainsList, totalPages, currentPageNumber, onTableSectionChange, currentItems} =
    useImportFederatedDomainsModalContext();

  const isItemSelectable = (itemKey) => {
    const foundDomain = federatedDomainsList?.find(
      (federatedDomain) => federatedDomain.name === itemKey
    );

    return !foundDomain.isInvalid && !foundDomain.isLinked;
  };

  return (
    <TableSection
      getIsItemSelectable={isItemSelectable}
      id="federated-domains-table"
      items={currentItems?.map((item) => {
        item.key = item.name;
        return item;
      })}
      onTableSectionChange={onTableSectionChange}
      pageNumber={currentPageNumber}
      selectionMode={SELECTION_MODE.MULTIPLE}
      totalPages={totalPages}
    >
      <TableFilters
        hasSearch
        label={intl.formatMessage({id: 'settings.domainsTable.search.label'})}
      />
      <FederatedDomainsSectionTable />
    </TableSection>
  );
};

export default FederatedDomainsTable2;
