import {log} from '@admin-tribe/binky';
import ShoppingCartIcon from '@spectrum-icons/workflow/ShoppingCart';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';

import useGlobalModal, {MODAL_TYPE} from 'common/hooks/useGlobalModal';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {canUseAddProductMiniApp} from 'core/products/access/productAccess';
import chatProvider from 'core/services/chat/chatProvider';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

import QuickLink from '../QuickLink';

const AddLicensesQuickLink = ({label, sophiaAnalytics}) => {
  const {openModal: openAngularAddProductsModal} = useGlobalModal({
    componentBindings: [
      {
        attributeName: 'modalId',
        type: 'String',
        value: 'appAddProductsModal2',
      },
    ],
    componentName: 'appAddProductsModal2',
    type: MODAL_TYPE.SRC1,
  });

  const [openAddProductMiniApp, setOpenAddProductMiniApp] = useState(false);
  const openAddProducts = useCallback(() => {
    // Use React Add Product Mini App
    if (canUseAddProductMiniApp()) {
      setOpenAddProductMiniApp(true);
    } else {
      // Use Angular Add Products Modal dialog
      openAngularAddProductsModal();
    }
  }, [openAngularAddProductsModal]);

  const onAddProductClose = useCallback(() => {
    setOpenAddProductMiniApp(false);
  }, []);

  const AddProductMiniApp = useCallback(
    () =>
      openAddProductMiniApp && (
        <AddProductModalWrapper chat={chatProvider} onClose={onAddProductClose} />
      ),
    [onAddProductClose, openAddProductMiniApp]
  );

  return (
    <>
      <QuickLink
        analyticsOptions={{
          analyticsAction: 'add-licenses',
          sophia: {responses: [sophiaAnalytics]},
        }}
        data-testid="quick-link-add-licenses"
        Icon={<ShoppingCartIcon size="M" />}
        label={label}
        onPress={openAddProducts}
      />
      <AddProductMiniApp />
    </>
  );
};

AddLicensesQuickLink.propTypes = {
  /**
   * The text to display as the label for this quick link
   */
  label: PropTypes.string.isRequired,
  /**
   * Sophia analytics object for the campaign serving this button
   */
  // eslint-disable-next-line react/forbid-prop-types -- object shape governed in app/common/services/sophia/SophiaContent.js
  sophiaAnalytics: PropTypes.object.isRequired,
};

AddLicensesQuickLink.get = async () => {
  if (isAllowedToAddProducts()) {
    try {
      const sophiaContentList = await sophiaHelper.getSophiaContent({
        surfaceId: 'one_console_buy_more_quick_link',
      });
      if (sophiaContentList.items.length > 0) {
        const sophiaContent = sophiaContentList.items[0];
        if (sophiaContent.isJson()) {
          const sophiaContentJSON = JSON.parse(sophiaContent.getContent());
          const label =
            sophiaContentJSON.data[Object.keys(sophiaContentJSON.data)[0]].item
              .buyMoreQuickLinkName;
          return Promise.resolve(
            <AddLicensesQuickLink
              key="AddLicensesQuickLink"
              label={label}
              sophiaAnalytics={sophiaContent.getAnalyticsParams()}
            />
          );
        }
      }
    } catch (error) {
      log.error('Error calling Sophia: ', error);
    }
  }
  return Promise.reject(new Error('User cannot add products'));
};

export default AddLicensesQuickLink;
