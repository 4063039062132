(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name app.core.storage-reports:StorageReport
   * @description Model for a storage report
   */
  angular.module('app.core.storage-reports').factory('StorageReport', getStorageReportModel);

  /* @ngInject */
  function getStorageReportModel(
    $log,
    $q,
    $rootScope,
    _,
    contentPlatformVa6Direct,
    MESSAGE,
    REPORT_STATUS,
    StorageFile
  ) {
    class StorageReport extends StorageFile {
      /**
       * @description Creates a new StorageReport for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.id A StorageReport's ID
       * @param {String} options.linkPath A link path to download storage report
       * @param {String} options.name A StorageReport's name
       * @param {Array} options.owners A StorageReport's owners
       * @param {String} options.path the path of the StorageReport
       * @param {StorageQuota} options.quota the storage quota of the StorageReport
       * @param {Date} options.repositoryCreatedDate The creation date of the StorageReport
       * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageReport
       * @param {String} options.state the state of the StorageReport
       * @param {String} options.type the type of the file which StorageReport is stored
       */
      constructor(options = {}) {
        super(options);
        initializeReportStatus(this);
      }

      /**
       * @class
       * @description Transforms the /organizations StorageReport response Object into a new
       *   StorageReport instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageReport(dataTransferObject);
      }

      /**
       * @description Method to create storage report.
       *
       * @param {String} type - folder type of the report to be created
       * @returns {Promise} resolves if successfully create report, else rejects with error message
       */
      static create(type) {
        this.$resolved = false;

        this.$promise = contentPlatformVa6Direct.storageReport
          .create({type})
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this))
          .finally(() => {
            this.$resolved = true;
          });

        function onSuccess() {
          $rootScope.$emit(MESSAGE.CREATE.STORAGEREPORT, this);
          return this;
        }

        function onError(error) {
          $log.error('Failed to create storage report. Error: ', error);
          return $q.reject(error);
        }

        return this.$promise;
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes Storage Report status.
     *
     * @param {Object} model StorageReport model Object instance
     */
    function initializeReportStatus(model) {
      if (_.startsWith(model.name, REPORT_STATUS.ERROR)) {
        model.name = _.replace(model.name, `${REPORT_STATUS.ERROR}_`, '');
        model.status = REPORT_STATUS.ERROR;
      } else if (_.startsWith(model.name, REPORT_STATUS.INPROGRESS)) {
        model.name = _.replace(model.name, `${REPORT_STATUS.INPROGRESS}_`, '');
        model.status = REPORT_STATUS.INPROGRESS;
      } else {
        model.status = REPORT_STATUS.SUCCESS;
      }
    }

    return StorageReport;
  }
})();
