/* eslint-disable max-lines -- few lines over, removing feature flags later will reduce the number of lines */
import {
  ORGANIZATION_MARKET_SEGMENT,
  OrganizationUser,
  User,
  configStore,
  log,
  toPandoraProduct,
} from '@admin-tribe/binky';
import {AccessRequest, AccessRequestUser, UserAccessRequestList} from '@pandora/data-model-acrs';
import {useAppInfoList} from '@pandora/data-model-lcs-cops';
import {Offer} from '@pandora/data-model-offer';
import {useAsyncModel} from '@pandora/react-async-model';
import {ContentContext} from '@pandora/react-content-provider';
import {REQUEST_ACCESS_STATUS, useAuthzRequest} from '@pandora/react-data-source-acrs';
import {MarketSegment, ServiceProvider, useOffers} from '@pandora/react-data-source-jil';
import {useCallback, useContext, useEffect, useState} from 'react';

import rootStore from 'core/RootStore';
import ngl from 'features/products/api/ngl';

/**
 * A hook that fetches the list of product-access requests made by users in the org.
 *
 * @returns {Object} state - Object with state variables
 *          {Array<UserAccessRequestList>} state.data - an array of users and their product requests
 *          {Error} state.error - the error if fetching fails
 *          {Boolean} state.isLoading - true if lists are being fetched
 */
// eslint-disable-next-line max-statements,@admin-tribe/admin-tribe/jsdoc-exported-functions -- arrow statements should reduce after flag removals.
const useUserAccessRequestLists = () => {
  const {locale} = useContext(ContentContext);

  const {
    data: appInfoList,
    error: appInfoError,
    loading: appInfoLoading,
  } = useAppInfoList({
    apiKey: configStore.get('services.ims.clientId'),
    getAppInfoOptions: {
      params: {
        app_version: 'latest',
        include_app_settings: false,
        include_merchandising_data: true,
      },
    },
  });

  const {getAllAuthzRequests, getAllAuthzRequestUsers} = useAuthzRequest();
  const loadAccessRequests = useCallback(async () => {
    const response = await getAllAuthzRequests({
      orgId: rootStore.organizationStore.activeOrgId,
      params: {
        status: REQUEST_ACCESS_STATUS.PENDING,
      },
    });

    if (response) {
      const jsonData = await response.json();
      const items = jsonData.map((responseItem) => new AccessRequest({...responseItem}));
      return {items};
    }
    return undefined;
  }, [getAllAuthzRequests]);

  const {
    model: accessRequestList,
    isLoading: accessRequestLoading,
    error: accessRequestError,
    updateModel: updateAccessRequests,
  } = useAsyncModel({
    loadFn: loadAccessRequests,
  });
  const productList = rootStore.organizationStore.productList;
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  const [userAccessRequestLists, setUserAccessRequestLists] = useState([]);
  const [applications, setApplications] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [isRefreshingProducts, setIsRefreshingProducts] = useState(false);
  const [users, setUsers] = useState([]);
  const [userError, setUserError] = useState();
  const [isEvaluatingLicenses, setIsEvaluatingLicenses] = useState(false);
  const [evaluateLicensesError, setEvaluateLicensesError] = useState();
  const [unmatchedPACodes, setUnmatchedPACodes] = useState([]);
  const [matchedPACodeProducts, setMatchedPACodeProducts] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [isFetchingOffers, setIsFetchingOffers] = useState(false);
  const [offersError, setOffersError] = useState();

  // Get matched and unmatched product arrangement codes
  useEffect(() => {
    const allProductArrangementCodes = accessRequestList?.items.flatMap(
      (accessRequest) => accessRequest.productArrangementCodes
    );
    const requestedPACodeSet = [...new Set(allProductArrangementCodes)];

    const matchedProducts = productList.items.filter(
      ({productArrangementCode}) =>
        productArrangementCode && requestedPACodeSet.includes(productArrangementCode)
    );
    setMatchedPACodeProducts(matchedProducts);

    const matchedPACodes = matchedProducts.map((product) => product.productArrangementCode);
    const unmatchedPACodesToSet = requestedPACodeSet.filter(
      (paCode) => paCode && !matchedPACodes.includes(paCode)
    );
    setUnmatchedPACodes(unmatchedPACodesToSet);
  }, [accessRequestList?.items, productList.items]);

  const {getOffers} = useOffers();
  const loadOfferList = useCallback(async () => {
    if (unmatchedPACodes?.length > 0) {
      const getMarketSegmentString = () => {
        switch (marketSegment) {
          case ORGANIZATION_MARKET_SEGMENT.EDUCATION:
            return MarketSegment.EDU;
          case ORGANIZATION_MARKET_SEGMENT.COMMERCIAL:
            return MarketSegment.COM;
          case ORGANIZATION_MARKET_SEGMENT.GOVERNMENT:
            return MarketSegment.GOV;
          default:
            return undefined;
        }
      };
      setIsFetchingOffers(true);
      try {
        const response = await getOffers({
          arrangementCode: unmatchedPACodes,
          locale,
          marketSegment: getMarketSegmentString(),
          serviceProviders: [ServiceProvider.MERCHANDISING],
        });

        if (response) {
          const jsonData = await response.json();
          const items = jsonData.map((responseItem) => new Offer({...responseItem}));
          setOfferList(items);
        }
      } catch (currentOfferError) {
        log.error(`Error getting Offers for product arrangement codes: ${currentOfferError}`);
        setOffersError(currentOfferError);
      } finally {
        setIsFetchingOffers(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- market segment is not needed here
  }, [getOffers, locale, unmatchedPACodes]);

  // Load offers for unmatched product arrangement codes
  useEffect(() => {
    loadOfferList();
  }, [loadOfferList, unmatchedPACodes]);

  const getApplicationsGivenIds = useCallback(
    (productOfferingIds) => applications.filter(({id}) => productOfferingIds?.includes(id)),
    [applications]
  );

  // Fetch the list of users and their user information related to the requests made for this org
  const getOrgUserList = useCallback(async () => {
    setIsFetchingUsers(true);
    try {
      // Fetches the list of user ids of those who have made requests
      const response = await getAllAuthzRequestUsers({
        orgId: rootStore.organizationStore.activeOrgId,
        params: {
          status: REQUEST_ACCESS_STATUS.PENDING,
        },
      });

      if (response) {
        const jsonData = await response.json();
        const items = jsonData.map((responseItem) => new AccessRequestUser({...responseItem}));
        // Fetch user details tied to the list of ACRS users
        const currentUsers = await Promise.all(
          items.map(async (accessRequestUser) => {
            try {
              return await OrganizationUser.get({
                orgId: rootStore.organizationStore.activeOrgId,
                userId: accessRequestUser.userAccountId,
              });
            } catch (error) {
              log.error(`Error getting user info: ${error}`);
              // Note: this is User instead of OrganizationUser because mocking both a
              // static method (get) and constructor on one binky class was problematic.
              // User provides everything we need for this placeholder, and allows us to write unit tests.
              return new User({
                id: accessRequestUser.userAccountId,
              });
            }
          })
        );
        setUsers(currentUsers);
      }
    } catch (currentUserError) {
      log.error(`Error getting product access request users: ${currentUserError}`);
      setUserError(currentUserError);
    } finally {
      setIsFetchingUsers(false);
    }
  }, [getAllAuthzRequestUsers]);

  // Fetch all ACRS user information
  useEffect(() => {
    if (!accessRequestLoading && accessRequestList?.items.length > 0 && users.length === 0) {
      getOrgUserList();
    }
  }, [accessRequestList?.items, getOrgUserList, accessRequestLoading, users.length]);

  // Fetch all unique requested app IDs to use to fetch their names, icons, and what products they can be assigned to
  useEffect(() => {
    if (!accessRequestLoading && !appInfoLoading && accessRequestList) {
      const allRequestedAppIds = accessRequestList.items.flatMap(
        (accessRequest) => accessRequest.applicationIds
      );
      const requestedAppIds = [...new Set(allRequestedAppIds)];
      const mappedAppIds = requestedAppIds.map((appId) => {
        const app = appInfoList?.items.find((appInfo) => appInfo.appId === appId);
        return {
          id: appId,
          name: app?.getMerchandisingName() || appId,
          svg: app?.getMerchandisingIcon(),
        };
      });

      const existingPACodeProducts = matchedPACodeProducts?.map((matchedProduct) => ({
        id: matchedProduct.productArrangementCode,
        name: matchedProduct.longName,
        svg: matchedProduct.getIcon(),
      }));

      const unmatchedPACodeOfferList = unmatchedPACodes?.map((paCode) => {
        const paCodeOffer = offerList?.find((offer) => offer.product_arrangement_code === paCode);
        return paCodeOffer
          ? {
              id: paCode,
              name: paCodeOffer?.getMerchandisingName(),
              svg: paCodeOffer?.getMerchandisingIcon(),
            }
          : {
              // This is an invalid product arrangement code, so we will not have merchandising info for it.
              id: paCode,
              name: undefined,
              svg: undefined,
            };
      });
      const unmatchedPACodeOffers = unmatchedPACodeOfferList.filter(
        (mappedOffers) => mappedOffers !== undefined
      );

      setApplications([...mappedAppIds, ...existingPACodeProducts, ...unmatchedPACodeOffers]);
    }
  }, [
    accessRequestList,
    appInfoList,
    accessRequestLoading,
    appInfoLoading,
    offerList,
    matchedPACodeProducts,
    unmatchedPACodes,
  ]);

  const refreshProductList = async () => {
    setIsRefreshingProducts(true);
    await productList.refresh();
    setIsRefreshingProducts(false);
  };

  // Constructing UserAccessRequestList now that all info is available
  useEffect(() => {
    const evaluatedLicensesCache = {};

    async function generateEvaluatedLicensesCache() {
      const userIds = users.map(({id}) => id);
      const licenseIds = productList.items.map(({id}) => id);
      setIsEvaluatingLicenses(true);

      // Make the minimum number of NGL calls for the relevant access requests
      const accessRequestsForGivenUsers = accessRequestList.items.filter((accessRequest) =>
        userIds.includes(accessRequest.userAccountId)
      );

      const uniqueRequestAppIds = {};
      const uniqueRequestPACodes = {};
      accessRequestsForGivenUsers.forEach(({applicationIds, productArrangementCodes}) => {
        const appIdsString = applicationIds?.sort().toString();
        if (appIdsString && !uniqueRequestAppIds[appIdsString]) {
          uniqueRequestAppIds[appIdsString] = applicationIds.sort();
        }

        const paCodesString = productArrangementCodes?.sort().toString();
        if (paCodesString && !uniqueRequestPACodes[paCodesString]) {
          uniqueRequestPACodes[paCodesString] = productArrangementCodes.sort();
        }
      });

      const convertToPandoraProductList = (binkyProductList) =>
        binkyProductList.map((binkyProduct) => toPandoraProduct(binkyProduct));

      await Promise.all(
        Object.keys(uniqueRequestAppIds).map(async (appIdsString) => {
          let relevantProducts = [];
          try {
            const response = await ngl.getEvaluatedLicensesForApps({
              appIds: uniqueRequestAppIds[appIdsString],
              licenseIds,
              orgId: rootStore.organizationStore.activeOrgId,
            });
            const relevantLicenseIds = response.data;

            relevantProducts = convertToPandoraProductList(
              productList.items.filter(({id}) => relevantLicenseIds.includes(id))
            );
          } catch (error) {
            log.error('Failed to evaluate licenses for applications. Error: ', error);
            setEvaluateLicensesError(error);
          } finally {
            evaluatedLicensesCache[appIdsString] = relevantProducts;
          }
        })
      );

      Object.keys(uniqueRequestPACodes).forEach((paCodesString) => {
        evaluatedLicensesCache[paCodesString] = convertToPandoraProductList(
          matchedPACodeProducts.filter(({productArrangementCode}) =>
            uniqueRequestPACodes[paCodesString].includes(productArrangementCode)
          )
        );
      });

      setIsEvaluatingLicenses(false);
    }

    async function constructUserAccessRequestList() {
      await generateEvaluatedLicensesCache();

      // TODO: Will slice user list to be the first pageSize of users. PageSize will be passed into this hook.
      // Map users to their requests.
      const constructedUserAccessRequestLists = users.map((orgUser) => {
        const accessRequests = accessRequestList.items.filter(
          (accessRequest) => accessRequest.userAccountId === orgUser.id
        );

        const accessRequestsWithApplicationInfo = accessRequests.map((accessRequest) => {
          const appIdsString = accessRequest.applicationIds?.sort().toString();
          const paCodesString = accessRequest.productArrangementCodes?.sort().toString();
          return new AccessRequest({
            ...accessRequest,
            applications: [
              ...getApplicationsGivenIds(accessRequest.applicationIds),
              ...getApplicationsGivenIds(accessRequest.productArrangementCodes),
            ],
            products: appIdsString
              ? evaluatedLicensesCache[appIdsString]
              : evaluatedLicensesCache[paCodesString],
          });
        });

        return new UserAccessRequestList({
          ...orgUser,
          accessRequests: accessRequestsWithApplicationInfo,
          orgId: rootStore.organizationStore.activeOrgId,
        });
      });

      setUserAccessRequestLists(constructedUserAccessRequestLists);
    }

    setUserAccessRequestLists([]);
    if (applications.length > 0 && users.length > 0) {
      constructUserAccessRequestList();
    }
  }, [
    accessRequestList?.items,
    applications,
    getApplicationsGivenIds,
    matchedPACodeProducts,
    productList.items,
    users,
  ]);

  // Log any errors that occurred
  useEffect(() => {
    if (accessRequestError) {
      log.error(`Error getting product access requests: ${accessRequestError}`);
    }
  }, [accessRequestError]);
  // Log any errors that occurred
  useEffect(() => {
    if (appInfoError) {
      log.error(`Error getting merchandising app info: ${appInfoError}`);
    }
  }, [appInfoError]);

  return {
    data: userAccessRequestLists,
    error: accessRequestError || appInfoError || userError || evaluateLicensesError || offersError,
    isLoading:
      accessRequestLoading ||
      appInfoLoading ||
      isFetchingUsers ||
      isEvaluatingLicenses ||
      isRefreshingProducts ||
      isFetchingOffers,
    updateData: () => {
      // We will refresh the product-list in "updateData", as a request approval or
      // a new product/license purchase will cause license quantities to change.
      refreshProductList();
      updateAccessRequests(loadAccessRequests);
      // Reset to refetch user list
      setUsers([]);
    },
  };
};

export default useUserAccessRequestLists;
/* eslint-enable max-lines -- few lines over, removing feature flags later will reduce the number of lines */
