import {Heading} from '@adobe/react-spectrum';
import {usePress} from '@react-aria/interactions';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import styles from './ConfigurationStep.pcss';

// TODO: Add story for this component
const ConfigurationStep = ({children, title, className, collapsible, defaultCollapsed}) => {
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(collapsible && defaultCollapsed);
  const {pressProps} = usePress({
    onPress: () => {
      setCollapsed(!collapsed);
    },
  });

  const heading = (
    <Heading
      level={2}
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed smaller font size but used level 2 for accessibility
      UNSAFE_className={styles.heading}
    >
      {title}
    </Heading>
  );

  return (
    <div className={className} styleName="configuration-step">
      {collapsible ? (
        <div
          aria-label={`${
            collapsed
              ? intl.formatMessage({id: 'settings.configurationStep.expandAriaLabel'})
              : intl.formatMessage({id: 'settings.configurationStep.collapseAriaLabel'})
          } ${title}`}
          data-testid="collapse-btn"
          role="button"
          styleName={`collapsible-heading ${collapsed ? 'collapsed' : ''}`}
          tabIndex={0}
          {...pressProps}
        >
          <ChevronDown
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- chevron rotation needs to be done in css
            UNSAFE_className={styles['chevron-icon']}
          />
          {heading}
        </div>
      ) : (
        heading
      )}
      <div aria-hidden={collapsed} data-testid="step-content" styleName={collapsed ? 'hidden' : ''}>
        {children}
      </div>
    </div>
  );
};

ConfigurationStep.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  title: PropTypes.string,
};

export default ConfigurationStep;
