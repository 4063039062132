import {useState} from 'react';

import {
  SUPPORT_CONSTANTS,
  SUPPORT_TICKET_ACTIONS,
  SUPPORT_TICKET_ACTIONS_REASONS,
} from '../../support.constants';

const initialState = {
  ACTION_CODE: '',
  OPTION_LABEL: '',
  REASON: [],
  RESOLUTION_LABEL: '',
  RESOLUTION_PLACEHOLDER: '',
  TITLE: '',
};
/**
 * useCaseDetails hook is set for selected case default value
 */
const useCaseDeatils = () => {
  const [statusObject, setStatusObject] = useState(initialState);
  function openModelByCaseType(type) {
    switch (type) {
      case SUPPORT_CONSTANTS.CASE_ACTIONS.CLOSE:
        setStatusObject({
          ...statusObject,
          ...SUPPORT_CONSTANTS.CLOSE_CASE,
          ACTION_CODE: SUPPORT_TICKET_ACTIONS.CLOSE,
          REASON: SUPPORT_TICKET_ACTIONS_REASONS.CLOSE,
        });
        break;
      case SUPPORT_CONSTANTS.CASE_ACTIONS.ESCALATE:
        setStatusObject({
          ...statusObject,
          ...SUPPORT_CONSTANTS.ESCALATE_CASE,
          ACTION_CODE: SUPPORT_TICKET_ACTIONS.ESCALATE,
          REASON: SUPPORT_TICKET_ACTIONS_REASONS.ESCALATE,
        });
        break;
      default:
        setStatusObject({
          ...statusObject,
          ...SUPPORT_CONSTANTS.REOPEN,
          ACTION_CODE: SUPPORT_TICKET_ACTIONS.REOPEN,
          REASON: SUPPORT_TICKET_ACTIONS_REASONS.REOPEN,
        });
    }
  }

  return {
    openModelByCaseType,
    setStatusObject,
    statusObject,
  };
};

export {useCaseDeatils, initialState};
