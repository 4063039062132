import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {StatusLight} from '@pandora/react-status-light';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Checks activation status of a device and displays an appropriate label and color
 */

const NAMESPACE = 'products.deviceLicenses.deviceDetails.details.label';

const DeviceStatus = ({status}) => {
  const intl = useIntl();
  let label, variant;

  switch (status) {
    case DEVICE_ACTIVATION_STATUS.BLOCKED:
      label = intl.formatMessage({
        id: `${NAMESPACE}.blockedDevices`,
      });
      variant = 'neutral';
      break;
    case DEVICE_ACTIVATION_STATUS.ACTIVATED:
      label = intl.formatMessage({
        id: `${NAMESPACE}.activatedDevices`,
      });
      variant = 'positive';
      break;
    default:
      // Do nothing
      break;
  }
  return (
    <StatusLight marginStart="-10px" variant={variant}>
      {label}
    </StatusLight>
  );
};

DeviceStatus.propTypes = {
  /**
   * Device activation status information to display
   */
  status: PropTypes.string,
};

export default DeviceStatus;
