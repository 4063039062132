import {
  CREATION_SOURCE,
  DirectoryUser,
  ORGANIZATION_MARKET_SEGMENT,
  OrganizationUser,
  feature,
} from '@admin-tribe/binky';
import {
  DETAIL_SECTION_VARIANT_CONSTANTS,
  DetailSection,
  getMemberType,
  getMemberTypeLabel,
} from '@admin-tribe/binky-ui';
import {
  ActionButton,
  ActionMenu,
  Flex,
  Grid,
  Item,
  Text,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getEduTagHelp, getEduTags} from 'common/utils/edu-tags/eduTagsUtils';
import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {canEditUser} from 'core/user/access/userAccess';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

// Styling for labels.
const labelStyle = {
  color: 'var(--color-grey-700)',
  fontSize: 'var(--font-size-50)',
};

/**
 * The User Details section on the User Details Page.
 */

const UserDetailsSection = ({headingLevel, isPageVariant, member, onMenuActionPress}) => {
  const intl = useIntl();

  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;
  const memberType = getMemberType(member);
  const memberTypeLabel = getMemberTypeLabel(intl, memberType);
  const canDisplayUsername = !!member.userName;

  const shouldRenderInfoButton = () => member.domainEnforcementException === true && isOrgAdmin();

  return (
    <DetailSection
      headingLevel={headingLevel}
      rightHeaderContent={
        canEditUser(member) && (
          <ActionMenu isQuiet onAction={onMenuActionPress}>
            <Item key={USER_DETAILS_MODAL_NAMES.EDIT_USER_PROFILE}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.moreOptions.edit',
              })}
            </Item>
          </ActionMenu>
        )
      }
      title={intl.formatMessage({
        id: 'common.userDetailsSection.title',
      })}
      variant={isPageVariant && DETAIL_SECTION_VARIANT_CONSTANTS.PAGE}
    >
      <Grid
        columnGap="size-200"
        columns={['auto', 'minmax(0, 1fr)']}
        marginTop="size-100"
        rowGap="size-50"
        rows={['auto']}
      >
        <Text data-testid="email-key" UNSAFE_style={labelStyle}>
          {intl.formatMessage({
            id: 'common.userDetailsSection.email.label',
          })}
        </Text>
        <Text>{member.email}</Text>
        {canDisplayUsername && (
          <>
            <Text data-testid="username-key" UNSAFE_style={labelStyle}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.username.label',
              })}
            </Text>
            <Text>{member.userName}</Text>
          </>
        )}
        {feature.isEnabled('temp_de_exclusion_40038') ? (
          <>
            <Text alignSelf="center" data-testid="type-key" UNSAFE_style={labelStyle}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.identityType.label',
              })}
            </Text>
            <Flex alignItems="center" direction="row" gap="size-50">
              <Text data-testid="member-type-label">{memberTypeLabel}</Text>
              {shouldRenderInfoButton() && (
                <Flex>
                  <TooltipTrigger placement="top">
                    <ActionButton
                      data-testid="tooltip-button-action"
                      isQuiet
                      justifySelf="center"
                      variant="action"
                    >
                      <InfoOutline size="S" />
                    </ActionButton>
                    <Tooltip data-testid="tooltip-button-tooltip" isOpen>
                      {intl.formatMessage({
                        id: 'common.userDetailsSection.identityType.idTooltipContent',
                      })}
                    </Tooltip>
                  </TooltipTrigger>
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <>
            <Text data-testid="type-key" UNSAFE_style={labelStyle}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.identityType.label',
              })}
            </Text>
            <Text data-testid="member-type-label">{memberTypeLabel}</Text>
          </>
        )}
        {member.creationSource === CREATION_SOURCE.JIT && (
          <>
            <Text data-testid="creation-source-key" UNSAFE_style={labelStyle}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.creationSource.label',
              })}
            </Text>
            <Flex alignItems="start">
              <Text>
                {intl.formatMessage({id: 'common.userDetailsSection.creationSource.value'})}
              </Text>
              <TooltipButton
                buttonAriaLabel={intl.formatMessage({
                  id: 'common.userDetailsSection.creationSource.value',
                })}
                hoverText={intl.formatMessage({
                  id: 'common.userDetailsSection.creationSource.tooltip',
                })}
                isQuiet
                UNSAFE_style={{position: 'relative', top: '-5px'}}
                variant="action"
              >
                <InfoOutline size="M" />
              </TooltipButton>
            </Flex>
          </>
        )}
        {marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION && (
          <>
            <Text data-testid="edu-tags-key" UNSAFE_style={labelStyle}>
              {intl.formatMessage({
                id: 'common.userDetailsSection.eduTags.label',
              })}
              {getEduTagHelp(intl)}
            </Text>
            <Text data-testid="edu-tags-value">{getEduTags(member.tags, intl)}</Text>
          </>
        )}
      </Grid>
    </DetailSection>
  );
};

UserDetailsSection.propTypes = {
  /**
   * The heading level for the DetailSection heading. The DetailSection default in binky-ui is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The user, administrator or directory user.
   */
  member: PropTypes.oneOfType([
    PropTypes.instanceOf(OrganizationUser),
    PropTypes.instanceOf(DirectoryUser),
  ]).isRequired,
  /**
   * Callback to invoke when the more options menu item is clicked. Params are being passed by MoreOptionsMenu.
   */
  onMenuActionPress: PropTypes.func.isRequired,
};

export default UserDetailsSection;
