// Start the Angular app
import './app/core/services/angularBootstrap';

import './colors.pcss';
import './media-breakpoints.pcss';
import binky, {FeaturesCache, Locale, authentication, log} from '@admin-tribe/binky';
import {getStrings} from '@admin-tribe/binky-ui';
import React from 'react';
import ReactDOM from 'react-dom';

import rootStore from 'core/RootStore';
import {checkForExternalLogin} from 'core/settings/utils/externalLoginUtils';

import App from './App';

import './overrides.pcss';

// Check for an external login before the application is ready.
// We could have used  a router loader for a redirect but there
// are a couple of issues with that:
// 1. The redirect loader enters an infinite loop because whenever we redirect
//    to the correct page the loaders will re-run.
// 2. In testing there were times when the redirect got stuck and never loaded
//    the page until a refresh was done.
checkForExternalLogin();

const {configure, localeReady, run} = binky.ready;

const CHECK_TIMEOUT = 60 * 1000; // 60s is the API timeout
const POLL_INTERVAL = 250;
const MAX_ATTEMPTS = CHECK_TIMEOUT / POLL_INTERVAL;

/**
 * Creates a polling check that resolves or rejects
 * @param {() => boolean} checkFn
 * @param {string} rejectReason
 * @returns {Promise<void>}
 */
const buildReadyCheck = (checkFn, rejectReason) =>
  new Promise((resolve, reject) => {
    let tries = 0;
    const interval = setInterval(() => {
      if (checkFn() === true) {
        clearInterval(interval);
        resolve();
      }

      tries += 1;
      if (tries > MAX_ATTEMPTS) {
        clearInterval(interval);
        reject(new Error(rejectReason));
      }
    }, POLL_INTERVAL);
  });

const src2OrganizationStoreReady = {
  run: () =>
    // Need to wait on src2/app/core/services/bootstrap.js, line 69
    buildReadyCheck(
      () => rootStore.organizationStore.activeOrg !== null,
      'Active org could not be determined'
    ),
};

const authenticationReady = {
  run: () =>
    // Need to wait for binky to define window.adobeIMS
    // binky: src/core/authentication/ready/authentication-ready.provider.js, line 63
    // binky: src2/core/services/authentication.js, line 135
    buildReadyCheck(
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
      () => !!window.adobeIMS,
      'window.adobeIMS never became available'
    ),
};

const featuresReady = {
  run: () =>
    // Need to wait on src2/app/core/services/bootstrap.js, line 86
    buildReadyCheck(
      () => FeaturesCache.get?.()?.vendors !== undefined,
      'FeatureCache vendors never became available'
    ),
};

const clientIdReady = {
  run: () =>
    // Need to wait on binky: src/core/authentication/ready/authentication-ready.provider.js, line 63
    buildReadyCheck(() => {
      let clientId;
      try {
        clientId = authentication.getClientId?.();
      } catch (error) {
        // Catching the error so that it does not bubble up just yet
      }

      return clientId !== undefined;
    }, 'Client ID could not be found'),
};

const appEnvReady = {
  run: () =>
    // Need to wait on binky: src/core/authentication/ready/authentication-ready.provider.js, line 63
    buildReadyCheck(() => {
      let appEnv;
      try {
        appEnv = authentication.getAppEnv?.();
      } catch (error) {
        // Catching the error so that it does not bubble up just yet
      }

      return appEnv !== undefined;
    }, 'App env could not be determined'),
};

const localizationReady = {
  run: () =>
    buildReadyCheck(() => {
      let messages = {};
      try {
        const language = Locale.get().activeLanguage;
        messages = getStrings(language);
      } catch (error) {
        // Catching the error so that it does not bubble up just yet
      }

      return Object.keys(messages).length > 0;
    }, 'Localization never became ready'),
};

const providers = [
  authenticationReady,
  localeReady,
  src2OrganizationStoreReady,
  featuresReady,
  clientIdReady,
  appEnvReady,
  localizationReady,
];

/**
 * The following will attempt to configure each application provider, then execute each provider's
 * run() with the generated configuration.
 */
configure(providers)
  .then(() => run(providers))
  .then(() => {
    // eslint-disable-next-line react/jsx-filename-extension, react/no-deprecated -- main entry point
    ReactDOM.render(<App />, document.querySelector('#app-main'));
  })
  // eslint-disable-next-line promise/prefer-await-to-callbacks -- No
  .catch((error) => {
    // TODO: Render an error page when this fails
    log.error('Issue preventing React app from booting properly', error);
  });

export {
  authenticationReady,
  src2OrganizationStoreReady,
  featuresReady,
  clientIdReady,
  appEnvReady,
  localizationReady,
};
