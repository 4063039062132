(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name paymentInfoEditor
   * @description Service that loads the payment info editor (PIE) and
   *   provides methods for interacting with it. For more details
   *   refer to https://git.corp.adobe.com/Adobe-Anyware/payment-info-editor/blob/master/docs/SDK-BILLING.md
   */
  angular
    .module('app.core.payment-info-editor')
    .factory('paymentInfoEditor', getPaymentInfoEditorModel);

  /* @ngInject */
  function getPaymentInfoEditorModel(
    $location,
    $log,
    $q,
    $rootScope,
    $translate,
    $window,
    _,
    AuthenticatedUser,
    configurationReady,
    domUtils,
    locale,
    onesieSrc2,
    ORGANIZATION_MARKET_SEGMENT,
    OrganizationList,
    PAYMENT_INFO_EDITOR_EVENT,
    toastManager
  ) {
    const config = configurationReady.getConfig();
    const clientId = _.get(config, 'services.ims.clientId');
    const pieConfig = _.get(config, 'services.paymentInfoEditor');

    return {
      load,
      loadOneTimePayment,
    };

    /**
     * @description Retrieves the IMS client ID from normal config if available or from onesieSrc2 as backup.
     * @returns {string} IMS client ID
     */
    function getClientId() {
      return (
        clientId ||
        _.get(onesieSrc2, 'common.services.AppConstants.configuration.services.ims.clientId')
      );
    }

    /**
     * @description Retrieves the PIE service config data from normal config if available or from onesieSrc2 as backup.
     * @returns {Object} PIE service config data
     */
    function getPieConfig() {
      return (
        pieConfig ||
        _.get(onesieSrc2, 'common.services.AppConstants.configuration.services.paymentInfoEditor')
      );
    }

    /**
     * * @description Method to set default options in order to call Payment Info Editor (PIE)
     * for one time payment and download the Billing SDK dynamically. Wraps a call to `load` method.
     *
     * Bare minimum required fields for PIE to function are contractId, contractType, country, and locale
     * Bare minimum required options for one time payment to function properly are
     * invoiceId, enableWallet (to be true), oneTimePayment (to be true)
     *
     * marketSegment can only be EDU or COM and cannot be GOV, as it is not sold 'team-direct' contract types
     *
     * @param {Object} object contains contract and invoiceId to populate fields of options to pass to BillingSDK
     * @returns {Promise} resolves when the Billing SDK is loaded and initialized successfully.
     */
    function loadOneTimePayment({contract, invoiceId}) {
      const loadOptions = {
        contractId: contract.id,
        contractType: 'team-direct',
        country: AuthenticatedUser.get().getCountryCode(),
        currency: _.get(contract, 'billingInfo.nextBillingAmount.currency.iso3code'),
        enableWallet: true,
        features: {
          pie: {
            'pf-enable-cta-layout': true,
            'pf-enable-error-banner': true,
          },
        },
        invoiceNumber: invoiceId,
        isNewPayment: false,
        locale: locale.getActiveLocaleCode(),
        // marketSegment for GOV is not supported by PIE so the only options are EDU or COM
        marketSegment:
          OrganizationList.get().activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
            ? 'EDU'
            : 'COM',
        oneTimePayment: true,
        orgId: contract.orgId,
        paymentMethodsAllowList: ['credit'],
        paymentMethodsWhitelist: ['credit'], // this property will be deprecated, sending both for now
        paymentStatus: contract.complianceStatus,
        showPaymentInfoDialog: true,
      };

      return load(loadOptions);
    }

    /**
     * @description Method to download the Billing SDK dynamically.
     * @param {Object} loadOptions options required by PIE when
     *   initializing the widget.
     * @returns {Promise} resolves when the Billing SDK is loaded
     *   and initialized successfully.
     */
    function load(loadOptions) {
      // first download the billing SDK (if needed) and then initialize it
      return downloadBillingSDK().then(() => {
        initPaymentInfoEditor(loadOptions);
      });

      /////////////

      function downloadBillingSDK() {
        if ($window.BillingSdk) {
          // either the billing sdk library was downloaded earlier, or we are in fakes mode and the fake version is loaded
          return $q.resolve();
        } else if (getPieConfig()) {
          // The PIE library is not already loaded, so download it now
          return domUtils.loadJs(getPieConfig().jsUrl);
        }
        // There is no PIE config, meaning PIE is not supported in this environment (e.g. QA)
        return $q.reject();
      }

      function initPaymentInfoEditor(loadOpts) {
        let loadingSpinner = null;
        const options = {
          clientId: getClientId(),
          // note that this is null in QA when running with fakes
          landscape: _.get(getPieConfig(), 'env'),
          loading(opts) {
            // create a loading spinner and inject it into the backdrop
            if (!_.get(loadOpts, 'oneTimePayment')) {
              addLoadingSpinner(opts);
            }
            $rootScope.$emit(PAYMENT_INFO_EDITOR_EVENT.LOADING, opts);
          },
          // loading PIE as a modal since binky-modal currently does not allow hiding the default action buttons, hence no way to close the modal once it's opened
          mode: _.get($window.BillingSdk, 'WidgetModes.MODAL'),
          ready(opts) {
            // remove the loading spinner when the iframe is displayed
            if (!_.get(loadOpts, 'oneTimePayment')) {
              removeLoadingSpinner();
            }
            try {
              $window.document.getElementsByTagName('iframe')[0].focus(); // moves focus to the iframe after it's opened
            } catch (error) {
              logFocusError(error);
            }
            $rootScope.$emit(PAYMENT_INFO_EDITOR_EVENT.READY, opts);

            if (loadOpts.paypal.decision === 'accept') {
              // PayPal payment instrument was updated upon successful PIE load, so close the widget and show a successful payment update toast.
              widget.unload();
              toastManager.showSuccessToast(
                $translate.instant('widgets.contracts.paymentUpdateSuccess')
              );
              $rootScope.$emit(PAYMENT_INFO_EDITOR_EVENT.SAVED);
            } else if (loadOpts.paypal.decision === 'cancel') {
              widget.unload();
            }
          },
          saved(info) {
            widget.unload();
            toastManager.showSuccessToast(
              $translate.instant('widgets.contracts.paymentUpdateSuccess')
            );
            $rootScope.$emit(PAYMENT_INFO_EDITOR_EVENT.SAVED, info);
          },
          stopped(reason) {
            // this is invoked if the widget isn't working and should be closed,
            // or the user requested it to be closed, or a timeout occured, etc.
            // List of possible errors is available on the following link
            // https://git.corp.adobe.com/Adobe-Anyware/payment-info-editor/blob/master/docs/SDK-BILLING.md#stopped-reasons
            removeLoadingSpinner();
            widget.unload();
            // if the modal is closed due to an error, show the error toast
            if (reason !== 'closed') {
              toastManager.showErrorToast(
                $translate.instant('widgets.contracts.paymentUpdateFail')
              );
            }
            $rootScope.$emit(PAYMENT_INFO_EDITOR_EVENT.STOPPED, reason);
          },
        };
        // instantiate and load the PIE
        const BillingWidget = _.get($window.BillingSdk, 'BillingWidget');
        let widget;
        if (BillingWidget) {
          widget = new BillingWidget(options);
          // set paypal object
          setPaypalCommunicationInfo(loadOpts);
          // set analyticsData object
          setUrlAnalyticsData(loadOpts);
          // load the PIE widget

          widget.load(loadOpts);
        } else {
          $log.error(`BillingWidget object was not defined for ${loadOpts.orgId}`);
        }

        /////////////

        function addLoadingSpinner(opts) {
          // create a loading spinner and inject it into the backdrop
          loadingSpinner = angular.element(
            new Coral.Wait().set({
              centered: true,
              size: 'L',
            })
          );
          opts.backdrop.appendChild(loadingSpinner[0]);
        }

        function logFocusError(error) {
          $log.error(error);
        }

        function removeLoadingSpinner() {
          if (loadingSpinner) {
            loadingSpinner.remove();
          }
        }

        // this function structures the input object required for the payment
        // widget in order to know how to communicate with the paypal external
        // dependency
        function setPaypalCommunicationInfo(opts) {
          // Paypal decision should come from the URL query string or load options
          const paypalValue =
            $location.search().paypal || (true && opts.paypal && opts.paypal.decision);
          opts.paypal = {
            acceptUrl: getPayPalCallbackURL('accept'),
            cancelUrl: getPayPalCallbackURL('cancel'),
            // when coming back from paypal, this param will let the iframe know
            // if the action is successful or not. The values can be either 'accept'
            // or 'cancel' based on what Paypal returns in the callback URL. So
            // initially (before being redirected to Paypal) this will be undefined,
            // but after coming back from Paypal (when this code is executed again),
            // it will be set properly.
            decision: paypalValue ? encodeURI(paypalValue) : undefined,
          };
          return opts;

          /////////////

          function getPayPalCallbackURL(action) {
            // construct the query string and append paypal param
            const queryString = constructQueryString();
            // construct PayPal callback URL
            return `${$window.location.protocol}//${$window.location.host}${$window.location.pathname}${queryString}${$window.location.hash}`;

            /////////////

            function constructQueryString() {
              const queryParams = _.assign(
                {},
                Object.fromEntries(new URLSearchParams($window.location.search)),
                {paypal: action}
              );

              const queryStr = _(queryParams)
                .map((val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
                .join('&');
              return `?${queryStr}`;
            }
          }
        }

        function setUrlAnalyticsData(opts) {
          // lower-case version of analytics params
          const analyticsParams = [
            'appctxid',
            'aud',
            'chid',
            'mv',
            'pid',
            'promoid',
            's_cid',
            's_iid',
            's_osc',
            's_rtid',
            'scid',
            'sdid',
            'trackingid',
            'ttsrccat',
          ];
          const urlParams = Object.fromEntries(new URLSearchParams($window.location.search));
          // build map of lower-case analytics params to values from URL
          const locationAnalyticsParams = {};
          _.forEach(urlParams, (value, key) => {
            if (_.includes(analyticsParams, _.toLower(key))) {
              locationAnalyticsParams[key] = encodeURIComponent(value);
            }
          });
          opts.analyticsData = locationAnalyticsParams;
          return opts;
        }
      }
    }
  }
})();
