import {
  ConsumableSummarizationList,
  LicenseGroupList,
  PageContext,
  Product,
  UserGroupLicenseGroupsList,
} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the Product profiles sub-tab.
 */
const ProductProfileTableContext = createContext(null);
const useProductProfileTableContext = () => useContext(ProductProfileTableContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- xx
const ProductProfileTableContextProvider = ({
  children,
  consumableSummarizationList,
  hasError,
  isLoading,
  licenseGroupList,
  onTableSectionChange,
  pageContext,
  product,
}) => {
  const value = useMemo(
    () => ({
      consumableSummarizationList,
      hasError,
      isLoading,
      licenseGroupList,
      onTableSectionChange,
      pageContext,
      product,
    }),
    [
      consumableSummarizationList,
      hasError,
      isLoading,
      licenseGroupList,
      onTableSectionChange,
      pageContext,
      product,
    ]
  );
  return (
    <ProductProfileTableContext.Provider value={value}>
      {children}
    </ProductProfileTableContext.Provider>
  );
};

ProductProfileTableContextProvider.propTypes = {
  /**
   * The content to be passed through to the content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * For products which use the consumables API, to get the totalQuantity.
   */
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList),
  /**
   * Whether the get list API has an error.
   */
  hasError: PropTypes.bool.isRequired,
  /**
   * Whether the get list API is loading.
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * The list of profiles to display.
   * This is not marked required because it is initially null until the list loads.
   */
  licenseGroupList: PropTypes.oneOfType([
    PropTypes.instanceOf(LicenseGroupList),
    PropTypes.instanceOf(UserGroupLicenseGroupsList),
    PropTypes.object, // during initialization is {items: null, pagination: {...}, sort: {...}}
  ]),
  /**
   * The TableSection onTableSection change callback used to notify useJilModel of TableSection changes.
   */
  onTableSectionChange: PropTypes.func.isRequired,
  /**
   * The PageContext object which describes the page.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
  /**
   * The product we're displaying profiles (aka license groups) for.
   * This is required if pageContext is for PAGE_TARGET.PRODUCT.
   */
  product: PropTypes.instanceOf(Product),
};

export {ProductProfileTableContextProvider, useProductProfileTableContext};
