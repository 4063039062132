(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories.enhanced-saml')
    .component('appEnhancedSamlConfiguration', {
      bindings: {
        authSource: '<',
        configurationComplete: '&?',
        processingDone: '&?',
        processingError: '&?',
        processingStarted: '&?',
        selectedFileChanged: '&?',
        validationStatusChanged: '&?',
      },
      controller,
      templateUrl:
        'app/widgets/directories/enhanced-saml/enhanced-saml-configuration/enhanced-saml-configuration.component.html',
    });

  function controller(
    _,
    $scope,
    $translate,
    $q,
    feature,
    ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS,
    IDP_ERROR_CODES,
    toastManager
  ) {
    const vm = this;

    // Keep a reference of all the handlers that can be deregistered
    let deregisterOnFilesChanged, deregisterOnSave;

    // Error code used when we're not sure what happened
    const GENERIC_ERROR = 'GENERIC_ERROR';

    // Object holding error translation key for the types of
    // error that could occur at save
    const ERRORS = {
      [IDP_ERROR_CODES.CANNOT_READ_METADATA]: 'cannotReadMetadata',
      [IDP_ERROR_CODES.EMPTY_METADATA]: 'emptyMetadata',
      [IDP_ERROR_CODES.INVALID_METADATA]: 'invalidMetadata',
      [IDP_ERROR_CODES.INVALID_XML]: 'invalidXml',
      [IDP_ERROR_CODES.MULTIPLE_ENTITY_DESCRIPTORS]: 'multipleEntityDescriptors',
      [IDP_ERROR_CODES.NO_IDP_SSO_DESCRIPTOR]: 'noIdpSsoDescriptor',
      [IDP_ERROR_CODES.UNRECOGNIZED_METADATA_FORMAT]: 'unrecognizedMetadataFormat',
      [IDP_ERROR_CODES.MALFORMED_SSO_URL]: 'malformedSsoUrl',
      [IDP_ERROR_CODES.NO_SSO_SERVICE_PRESENT]: 'noSsoServicePresent',
      [GENERIC_ERROR]: 'save',
    };

    _.assign(vm, {
      $onDestroy,
      $onInit,
      onCopy,
    });

    function $onInit() {
      _.assign(vm, {
        error: null,
        files: [],
        uploadError: false,
        waitPromise: $q.resolve(),
      });

      // Assign noop's to undefined handlers
      vm.processingStarted = vm.processingStarted || _.noop;
      vm.processingDone = vm.processingDone || _.noop;
      vm.processingError = vm.processingError || _.noop;
      vm.configurationComplete = vm.configurationComplete || _.noop;
      vm.selectedFileChanged = vm.selectedFileChanged || _.noop;

      // Listen to the save event
      deregisterOnSave = $scope.$on(
        ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS.SAVE_ENHANCED_SAML_CONFIGURATION,
        onSave
      );
      // Listen to file changes in order to update validation status
      deregisterOnFilesChanged = $scope.$watchCollection('$ctrl.files', onFilesChanged);

      const idp = vm.authSource.getSamlIdp();

      // Get the URL of the adobe metadata
      if (vm.authSource.getSamlIdp()) {
        vm.adobeMetadataUrl = idp.getEntityId();
        vm.entityId = idp.getEntityId();
        vm.acsUrl = idp.getAcsUrl();

        if (idp.isActive()) {
          // Add a placeholder file to the list since having the IDP active means the
          // metadata has been uploaded
          vm.files.push(
            new File(
              [],
              $translate.instant(
                'widgets.directories.enhancedSaml.configureSaml.placeholderFileName'
              )
            )
          );
        }
      }
    }

    function $onDestroy() {
      // Deregister handlers
      deregisterOnSave();
      deregisterOnFilesChanged();
    }

    function onCopy() {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.directories.enhancedSaml.configureSaml.downloadAdobeMetadata.copiedToClipboard'
        )
      );
    }

    /////////////////

    /**
     * Uploads the selected metadata
     */
    function onSave() {
      vm.processingStarted();
      vm.uploadError = false;
      vm.error = null;

      vm.waitPromise = vm.authSource
        .getSamlIdp()
        .uploadMetadataFile(_.head(vm.files))
        .then(vm.configurationComplete)
        .catch((error) => {
          if (feature.isEnabled('temp_idp_migration')) {
            showErrorBanner(_.get(error, 'data.error', GENERIC_ERROR));
          } else {
            vm.uploadError = true;
          }

          vm.processingError(error);
        })
        .finally(vm.processingDone);
    }

    /**
     * Handler for the files changed observer for upload file component
     * @param {Array} newFiles Array of the new files added
     * @param {Array} oldFiles Array of the old files before the change
     */
    function onFilesChanged(newFiles, oldFiles) {
      if (newFiles.length !== oldFiles.length) {
        vm.selectedFileChanged();
      }

      updateValidationStatus();
    }

    /**
     * Updates the validation status of this component so parent components
     * can disable/enable buttons.
     */
    function updateValidationStatus() {
      vm.validationStatusChanged({isValid: vm.files.length > 0});
    }

    /**
     * Shows the error banner with the corresponding message.
     *
     * @param {string} errorCode A value from the ERROR_CODES map or
     *                           a returned error code
     */
    function showErrorBanner(errorCode) {
      const errorTranslationKey = ERRORS[errorCode] || ERRORS[GENERIC_ERROR];

      vm.error = {
        body: `widgets.directories.enhancedSaml.configureSaml.errors.${errorTranslationKey}.body`,
        title: `widgets.directories.enhancedSaml.configureSaml.errors.${errorTranslationKey}.title`,
      };
    }
  }
})();
