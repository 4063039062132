import {AUTHORIZATION_POLICIES} from '@admin-tribe/binky';
import {useEffect, useRef, useState} from 'react';

/**
 * @description Hook for managing state for AppIntegrationsPolicyForm and AddAppIntegrationModalSetPolicy.
 *
 * @param {Object} options The options object
 * @param {AppIntegration} options.appIntegration - The app integration whose policy is being edited.
 * Can be a new or existing policy.
 */
const useAppIntegrationsPolicyForm = ({appIntegration}) => {
  // Store initial states of the policy and authorized users.
  const initPolicy = useRef(appIntegration.policy).current;
  const initAuthorizedUsers = useRef(appIntegration.authorizedUsers).current;

  // The member operations (add or remove) from the form. Applicable when policy is custom
  const [memberOperations, setMemberOperations] = useState([]);

  // The allowlist of members for a custom policy
  const [newAuthorizedUsers, setNewAuthorizedUsers] = useState([]);

  // The selected policy radio button state. If there isn't a policy yet, we default to ALLOW_ALL
  const [selectedPolicy, setSelectedPolicy] = useState(
    appIntegration.policy || AUTHORIZATION_POLICIES.SINGLE_APP.ALLOW_ALL
  );

  // Reset memberOperations state when custom policy is deselected
  useEffect(() => {
    if (selectedPolicy !== AUTHORIZATION_POLICIES.SINGLE_APP.CUSTOM) {
      setMemberOperations([]);
    }
  }, [selectedPolicy]);

  // Calculate the resulting member allowlist anytime memberOperation changes
  useEffect(() => {
    setNewAuthorizedUsers(getAuthorizedUsers({initAuthorizedUsers, memberOperations}));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run when memberOperation changes
  }, [memberOperations]);

  const isFormValid =
    selectedPolicy === AUTHORIZATION_POLICIES.SINGLE_APP.CUSTOM
      ? memberOperations.length > 0 && newAuthorizedUsers.length > 0
      : initPolicy !== selectedPolicy;

  return {
    initAuthorizedUsers,
    initPolicy,
    isFormValid,
    memberOperations,
    newAuthorizedUsers,
    selectedPolicy,
    setMemberOperations,
    setNewAuthorizedUsers,
    setSelectedPolicy,
  };
};

/**
 * @description Calculate the resulting member allowlist anytime memberOperation changes
 * @param {Member[]} initAuthorizedUsers The original allowlist of authorized members
 * @param {Object[]} memberOperations The member operations metadata. See MemberManagement onChange propType
 * @param {String} memberOperations[].type The type of operation, either 'add' or 'remove'
 * @param {Member} memberOperations[].member The member itself
 * @returns {Member[]} The new allowlist set of authorized members to save
 */
function getAuthorizedUsers({initAuthorizedUsers, memberOperations}) {
  const membersToAdd = memberOperations.filter((op) => op.type === 'add').map((op) => op.member);
  const memberIdsToRemove = memberOperations
    .filter((op) => op.type === 'remove')
    .map((op) => op.member.id);

  const updatedUsers = initAuthorizedUsers.filter((user) => !memberIdsToRemove.includes(user.id));
  updatedUsers.push(...membersToAdd);

  return updatedUsers;
}

export default useAppIntegrationsPolicyForm;
