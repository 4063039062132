/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.contracts').component('appContractInfo', {
    bindings: {
      contract: '<',
      invoiceList: '<',
      productList: '<',
    },
    controller,
    templateUrl: 'app/widgets/contracts/contract-info.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements -- declare constants
  function controller(
    $location,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    $window,
    _,
    ACCOUNT_STATE_PARAM,
    APP_AUTHENTICATING_USER_MODAL,
    AnalyticsEvent,
    auth,
    AuthenticatedUser,
    binkySrc2,
    Cart,
    CART_EVENT,
    CHANGE_CONTRACT_OWNER_MODAL_ID,
    CONTRACT_LIST_EVENT,
    contractAccess,
    contractUtils,
    ContractOwnerInvite,
    feature,
    locale,
    MANAGE_PAYMENT_SUPPORT_MODAL_ID,
    onesieSrc2,
    ORGANIZATION_MARKET_SEGMENT,
    OrganizationAdminList,
    OrganizationList,
    panelManager,
    promiseUtils,
    paymentInfoEditor,
    REVOKE_CONTRACT_OWNER_INVITATION_MODAL_ID,
    ROLE,
    toastManager,
    translationUtils,
    UiEventDescriptor
  ) {
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      authenticatingUserId: undefined,
      ChangeResellerModal: onesieSrc2.account.components.ChangeResellerModal,
      ContractBanner: onesieSrc2.account.components.ContractBanner,
      ContractInfoMiniAppWrapper: onesieSrc2.account.components.ContractInfoMiniAppWrapper,
      feature,
      isUserContractOwner,
      needAuthenticatingUserModal: false,
      onChangeResellerModalCancel,
      onChangeResellerModalSubmit,
      onesieSrc2,
      onRevokeContractOwnerInvitationConfirmation,
      openChangePrimaryAdminModal,
      openChangeResellerModal,
      openEditPaymentInfoModal,
      openOfferLink,
      openRevokeContractOwnerInvitationModal,
      orgId: $stateParams.orgId,
      PaymentInfoMiniAppWrapper: onesieSrc2.account.components.PaymentInfoMiniAppWrapper,
      REVOKE_CONTRACT_OWNER_INVITATION_MODAL_ID,
      showContractCurrencyChangeTooltip: false,
      showContractOwnerTooltip: false,
      showEditPaymentRow,
      showPaymentMethodSection,
      showRecentHistorySection,
      showRemainingDaysChart,
      showRevokeContractOwnerInvitationButton,
      showThreeYearCommitBanner,
      showThreeYearCommitInfo,
      upcomingBillAmount: undefined,
      upcomingBillAmountString: $translate.instant('widgets.contracts.pricingUnavailable'),
    });

    const deregisterHandles = [];

    const {CONTRACT_INFO} = onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST;
    const CanChangeContractOwnerSpan = `${CONTRACT_INFO}_canChangeContractOwner`;
    const CanViewChangeContractOwnerInfoSpan = `${CONTRACT_INFO}_canViewChangeContractOwnerInfo`;
    const {completeSpan, registerSpans, startSpan} =
      binkySrc2.services.observability.observabilityMetrics;

    if (vm.contract.isInRenewalWindow()) {
      deregisterHandles.push(
        /* eslint-disable angular/on-watch */
        $rootScope.$on(CART_EVENT.SUBMIT, refreshRenewalOrder),
        $rootScope.$on(CART_EVENT.SUBMIT_RENEWAL_ORDER, refreshRenewalOrder)
        /* eslint-enable angular/on-watch */
      );
    }
    deregisterHandles.push(
      /* eslint-disable angular/on-watch */
      $rootScope.$on(CONTRACT_LIST_EVENT.REFRESH, () => {
        if ($state.params.action === ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT) {
          // When $state.params.action is ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT
          // PIE will auto open. If the user was redirected to this route with
          // the action param set in order to open PIE, then the user closes or saves
          // the payment, and a CONTRACT_LIST_EVENT.REFRESH is fired, the full reload
          // will incorrectly open PIE again, as $state.params.action was set.
          // Unset $state.params.action before doing a full reload so PIE isn't opened again.
          $state.params.action = null;
        }
        $state.reload();
      })
      /* eslint-enable angular/on-watch */
    );

    //////////
    // eslint-disable-next-line complexity
    function $onInit() {
      if (feature.isEnabled('temp_observability')) {
        registerSpans([
          CONTRACT_INFO,
          CanChangeContractOwnerSpan,
          CanViewChangeContractOwnerInfoSpan,
        ]);
        startSpan(CONTRACT_INFO);
        startSpan(CanChangeContractOwnerSpan);
        startSpan(CanViewChangeContractOwnerInfoSpan);
      }

      if (vm.contract.isDirectContract() || vm.contract.isIndirectContract()) {
        vm.adminList = OrganizationAdminList.get({pageSize: 10, role: ROLE.ADMIN.ORG});
        vm.showChangeContractOwnerButton = false;

        contractAccess
          .canChangeContractOwner(vm.contract, vm.adminList)
          .then((canChangePrimaryAdmin) => {
            vm.showChangeContractOwnerButton = canChangePrimaryAdmin;
          })
          .catch(_.noop)
          .finally(() => {
            vm.ownerInfo = contractUtils.getContractOwner(vm.contract);
            if (feature.isEnabled('temp_observability')) {
              completeSpan(CanChangeContractOwnerSpan);
            }
          });

        if (shouldAutoOpenEditPaymentModal()) {
          vm.openEditPaymentInfoModal();
        }

        if (
          vm.contract.isDirectContract() &&
          !vm.contract.isDrContract &&
          vm.contract.isInRenewalWindow()
        ) {
          refreshRenewalOrder();
        }

        if (vm.contract.isIndirectContract()) {
          vm.threeYearCommitDescriptionKey =
            OrganizationList.get().activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
              ? 'widgets.contracts.threeYearCommit.descriptionForEduOrg'
              : 'widgets.contracts.threeYearCommit.description';
        }

        contractAccess
          .canViewChangeContractOwnerInfo(vm.contract, vm.adminList)
          .then((canViewInfo) => {
            vm.showContractOwnerTooltip = canViewInfo;
          })
          .catch(_.noop)
          .finally(() => {
            if (feature.isEnabled('temp_observability')) {
              completeSpan(CanViewChangeContractOwnerInfoSpan);
            }
          });

        if (contractAccess.canViewLocalCurrencyChangeInfo(vm.contract)) {
          const nextCurrencyCode = contractUtils.getFutureCurrency(vm.contract);

          const messageKey = `widgets.contracts.teamDirect.localCurrency.tooltipMessage.${nextCurrencyCode}`;
          const message = translationUtils.sanitizeSafeInstant(messageKey);

          vm.currencyChangeTooltipMessage = message === messageKey ? undefined : message;
        }
      }

      // Enterprise customers don't have access to contract info.
      vm.changeContractOwnerToolTipKey = vm.contract.isDirectContract()
        ? 'widgets.contracts.changeContractOwnerToolTipDirect'
        : 'widgets.contracts.changeContractOwnerToolTipIndirect';

      vm.showRenewalDetailSection =
        vm.contract.isIndirectButNotVIPMPContract() || vm.contract.isRenewableDirectContract();

      if (contractAccess.needToSwitchAsAuthenticatingUser({contract: vm.contract})) {
        // The authenticating account is currently the contract's T1
        vm.authenticatingUserId = vm.contract.getOwnerUserId();
        vm.needAuthenticatingUserModal = true;
      }

      if (feature.isEnabled('temp_observability')) {
        completeSpan(CONTRACT_INFO);
      }
    }

    function shouldAutoOpenEditPaymentModal() {
      return (
        showEditPaymentRow() &&
        ($state.params.action === ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT ||
          // open the PIE modal when paypal parameter is
          // in the url (when coming back from PayPal)
          $location.search().paypal)
      );
    }

    function $onChanges() {
      if (vm.contract) {
        vm.daysLeft = vm.contract.getOffsetFromNextBillingDate();

        if (vm.contract.isDirectContract()) {
          setupUpcomingBillValues();
        }
      }
    }

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    /**
     * @returns {Boolean} true if the user is the contract owner
     */
    function isUserContractOwner() {
      return vm.contract.isContractOwner({checkAuthUser: true});
    }

    function onRevokeContractOwnerInvitationConfirmation() {
      panelManager.close(REVOKE_CONTRACT_OWNER_INVITATION_MODAL_ID);

      const contractOwnerInvite = new ContractOwnerInvite({
        contractId: vm.contract.id,
        invitationCode: vm.contract.changeOwnerInvitation.invitationCode,
        userId: auth.getUserId(),
      });
      contractOwnerInvite.revoke().then(onSuccess).catch(onError);

      ////////

      function onSuccess() {
        toastManager.showSuccessToast();
      }

      function onError() {
        toastManager.showErrorToast();
      }
    }

    function openChangePrimaryAdminModal() {
      if (vm.needAuthenticatingUserModal) {
        panelManager.open(APP_AUTHENTICATING_USER_MODAL);
        return;
      }

      panelManager.open(CHANGE_CONTRACT_OWNER_MODAL_ID);
    }

    function openChangeResellerModal() {
      vm.isChangeResellerModalOpen = true;
    }

    function openEditPaymentInfoModal() {
      if (vm.needAuthenticatingUserModal) {
        panelManager.open(APP_AUTHENTICATING_USER_MODAL);
        return;
      }

      AnalyticsEvent.dispatch({
        descriptors: {
          uiEvent: new UiEventDescriptor({
            eventAction: 'click',
            eventName: 'managePayment',
            name: 'managePaymentMethodForAccount',
          }),
        },
      });
      if (vm.contract.isDrContract) {
        // Use $timeout as a hack to ensure the modal child component's $onInit has completed, and
        // the modal is therefore registered with panel manager, by the time we try to open it
        $timeout(() => panelManager.open(MANAGE_PAYMENT_SUPPORT_MODAL_ID));
      } else {
        // lazy-load the PIE by calling Payment Info Editor provider
        // to download the billing sdk
        const loadOptions = {
          contractId: vm.contract.id,
          contractType: 'team-direct',
          country: AuthenticatedUser.get().getCountryCode(),
          currency: _.get(vm.contract, 'billingInfo.nextBillingAmount.currency.iso3code'),
          hasStock: vm.productList.hasStockProducts(),
          isNewPayment: false,
          locale: locale.getActiveLocaleCode(),
          orgId: vm.orgId,
          paymentStatus: vm.contract.complianceStatus,
        };

        _.assign(loadOptions, {
          features: {
            pie: {
              'pf-enable-cta-layout': true,
              'pf-enable-error-banner': true,
            },
          },
          marketSegment:
            OrganizationList.get().activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
              ? 'EDU'
              : 'COM',
          showPaymentInfoDialog: true,
        });

        // open the PIE modal
        paymentInfoEditor.load(loadOptions);
      }
    }

    function openOfferLink() {
      if (vm.needAuthenticatingUserModal) {
        panelManager.open(APP_AUTHENTICATING_USER_MODAL);
        return;
      }

      if (feature.isEnabled('temp_terms_redirect')) {
        onesieSrc2.common.services.termsRedirect.redirectToAccept3YC({orgId: vm.orgId});
      } else {
        $window.open(vm.contract.get3YCInfo().acceptanceURL, '_blank');
      }
    }

    function openRevokeContractOwnerInvitationModal() {
      panelManager.open(REVOKE_CONTRACT_OWNER_INVITATION_MODAL_ID);
    }

    function showEditPaymentRow() {
      // This method is used show the manage payment link
      return (
        vm.contract.isDirectContract() && isUserContractOwner() && !vm.contract.isOrderProcessing()
      );
    }

    function showPaymentMethodSection() {
      // Wallet should only be shown to the contract owner
      return showEditPaymentRow() && contractAccess.canEditPayment(vm.contract);
    }

    function showRecentHistorySection() {
      // Only for direct contract and if the user is the contract owner regardless of the ID type
      return vm.contract.isDirectContract() && vm.contract.isContractOwner({checkAuthUser: true});
    }

    function showRevokeContractOwnerInvitationButton() {
      return _.has(vm, 'contract.changeOwnerInvitation');
    }

    function showRemainingDaysChart() {
      return vm.daysLeft >= 0;
    }

    ////////

    function refreshRenewalOrder() {
      vm.cart = Cart.get({
        cartType: 'RENEWAL',
        contractId: vm.contract.id,
        lastModifiedById: auth.getUserId(),
      });
      vm.cart.$promise
        .then(() => {
          setupUpcomingBillValues();
        })
        .catch(_.noop);
    }

    function setupUpcomingBillValues() {
      if (vm.contract.isAutoRenewalOff()) {
        vm.upcomingBillAmount = undefined;
        vm.upcomingBillAmountString = $translate.instant('widgets.contracts.noPaymentScheduled');
      } else if (vm.contract.isInRenewalWindow()) {
        if (vm.cart && vm.cart.getNewTotalPrice()) {
          const showPriceWithTax = !!vm.cart.getNewTotalPriceWithTax();
          vm.upcomingBillAmount = vm.cart.getNewTotalPrice(showPriceWithTax);
          vm.upcomingBillAmountString = getTaxString(showPriceWithTax);
        }
      } else if (vm.contract.isM2M() && vm.contract.getNextBillingAmountInfo()) {
        const nextBillingAmountInfo = vm.contract.getNextBillingAmountInfo();
        vm.upcomingBillAmount = nextBillingAmountInfo.formattedPrice;
        vm.upcomingBillAmountString = getTaxString(nextBillingAmountInfo.includesTax);
      } else if (vm.contract.isPUF()) {
        vm.upcomingBillAmount = undefined;
        vm.upcomingBillAmountString = $translate.instant(
          'widgets.contracts.upcomingBillMessageForPUF'
        );
      }

      ////////

      function getTaxString(includesTax) {
        return includesTax
          ? $translate.instant('widgets.contracts.upcomingBillIncludingTax')
          : $translate.instant('widgets.contracts.upcomingBillExcludingTax');
      }
    }

    function onChangeResellerModalCancel() {
      vm.isChangeResellerModalOpen = false;
      $rootScope.$apply();
    }

    function onChangeResellerModalSubmit(resellerChangeCode) {
      let errorKey;
      promiseUtils
        .toAngularPromise(vm.contract.updateReseller({resellerChangeCode}))
        .then(() => {
          toastManager.showSuccessToast(
            $translate.instant('widgets.contracts.changeContractResellerSuccessToastBody')
          );
        })
        .catch((error) => {
          if (error.errorCode === 'INVALID_RESELLER_CHANGE_CODE') {
            errorKey = 'invalid';
          } else if (error.errorCode === 'EXPIRED_RESELLER_CHANGE_CODE') {
            errorKey = 'expired';
          } else {
            errorKey = 'generic';
          }
          toastManager.showErrorToast(
            $translate.instant(`widgets.contracts.changeContractResellerErrorToastBody.${errorKey}`)
          );
        })
        .finally(() => {
          // need to the $timeout() wrapper here, otherwise the unit tests related
          // to verifying the API success/failure would fail...
          $timeout(() => {
            vm.isChangeResellerModalOpen = false;
          });
        });
    }

    function showThreeYearCommitBanner() {
      return vm.contract.isContractOwner({checkAuthUser: true}) && vm.contract.isEligibleFor3YC();
    }

    function showThreeYearCommitInfo() {
      return vm.contract.isEligibleFor3YC() || vm.contract.isEnrolledIn3YC();
    }
  }
})();
/* eslint-enable max-lines */
