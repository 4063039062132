import {getLocalStorageItem, log, setLocalStorageItem} from '@admin-tribe/binky';
import inRange from 'lodash/inRange';
import {action, computed, makeObservable, observable} from 'mobx';

import {GLOBAL_BANNER_TYPES} from './GlobalBannerConstants';

class GlobalBannerStore {
  currentBannerIndex;

  constructor() {
    this.globalBanners = [];
    this.currentBannerIndex = null;
    makeObservable(this, {
      add: action,
      advanceToNextBanner: action,
      advanceToPreviousBanner: action,
      clearAll: action,
      currentBanner: computed,
      currentBannerIndex: observable,
      dismiss: action,
      globalBanners: observable,
      numberOfQueuedBanners: computed,
    });
  }

  /**
   * @description Method to add new banners to display for a given org.
   *
   * @param {Object} options - Top level wrapper object.
   * @property {string} [options.buttonText] - The button label for banners that
   *     launch a modal via button.
   * @property {string} [options.buttonUrl] - The button url where it will redirect
   *      on click.
   * @property {string} [options.chatAppId] - To be used in conjunction with the
   *     StartChat button widget.
   * @property {Function} [options.handleChatOnClick] - Callback to dispatch an
   *     analytics event when the StartChat button is clicked.
   * @property {string} [options.id] - Id of the banner.
   * @property {boolean} [options.isDismissible] - Flag indicating whether this
   *     banner should show the close button. Defaults to true.
   * @property {string} [options.linkHref] - Href value for banners that show a href
   *     link.
   * @property {string} [options.linkName] - GoUrl value for banners that show GoUrls.
   * @property {Function} [options.linkOnClick] - Callback to invoke when the a
   *     banner that shows a link with a callback is clicked.
   * @property {string} [options.linkText] - String key for banners that show a link.
   * @property {string} [options.linkUiSref] - UiSref value to naviate to another
   *     part of the application.
   * @property {Object} [options.linkUiSrefArgs] - UiSref context arguments.
   * @property {string} options.message - String key for the banner message.
   * @property {Object} options.messageArgs - Object of values that will be used to
   *     populate the banner message, but will not be subbed into the string.
   * @property {string} [options.messageArgs.goUrlName] - GoUrl name to apply to
   *     an inline GoUrl.
   * @property {Object} [options.messageDates] - Object wrapping raw date values
   *     that will be formatted in the banner message.
   * @property {Object} [options.messageNumbers] - Object wrapping raw number
   *     values that will be formatted in the banner message.
   * @property {Object} [options.messageTimes] - Object wrapping raw date values
   *     that wil be formatted in the banner message to display the time portion
   *     only.
   * @property {string} [options.modalId] - Id of the modal that should be opened
   *     via the banner link or button.
   * @property {number} [options.priority] - Sort order is the largest number to
   *     the smallest number. Defaults to 0.
   * @property {string} [options.removeWithKey] - Key that when present in
   *     localStorage will cause the banner to not be added to the store. If it
   *     was previously added with this key, localStorage will be updated when
   *     the banner is dismissed.
   * @property {string} [options.type] Sort order is ERROR, WARNING and INFO.
   *     Defaults to INFO.
   */
  add(options) {
    const constructedBanner = {
      ...options,
      index: this.globalBanners.length,
      isDismissible: options.isDismissible ?? true,
      priority: options.priority ?? 0,
      type: options.type ?? GLOBAL_BANNER_TYPES.INFO,
    };

    if (!constructedBanner.message) {
      log.error('No message was provided to globalBannerStore.add()');
    } else if (
      ['linkHref', 'linkUiSref', 'linkName', 'modalId'].filter(
        (property) => constructedBanner[property] !== undefined
      ).length > 1
    ) {
      log.error(
        'Multiple link types were provided to globalBannerStore.add(), only one should be in use per banner'
      );
    } else if (!getLocalStorageItem(constructedBanner.removeWithKey)) {
      this.globalBanners.push(constructedBanner);
      this.globalBanners = sortBanners(this.globalBanners);
    }
    if (this.globalBanners.length > 0) {
      this.currentBannerIndex = 0;
    }
  }

  /**
   * @description Method to move to the next banner if one is present.
   */
  advanceToNextBanner() {
    if (inRange(this.currentBannerIndex + 1, 0, this.globalBanners.length)) {
      this.currentBannerIndex += 1;
    } else {
      this.currentBannerIndex = 0;
    }
  }

  /**
   * @description Method to move to the previous banner if one is present.
   */
  advanceToPreviousBanner() {
    if (inRange(this.currentBannerIndex - 1, 0, this.globalBanners.length)) {
      this.currentBannerIndex -= 1;
    } else {
      this.currentBannerIndex = this.globalBanners.length - 1;
    }
  }

  /**
   * @description Method to clear all banners.
   */
  clearAll() {
    this.globalBanners = [];
  }

  /**
   * @description Getter for the current banner.
   */
  get currentBanner() {
    return this.globalBanners[this.currentBannerIndex];
  }

  /**
   * @description Method to dismiss the current banner.
   * @param {Object} [banner] - Banner object to dismiss, defaults to the
   *     current banner if none is provided.
   */
  dismiss(banner = this.currentBanner) {
    if (banner.removeWithKey) {
      setLocalStorageItem(banner.removeWithKey, true);
    }
    this.globalBanners.splice(banner.index, 1);
    if (this.globalBanners.length > 0) {
      // We first need to update the indices of the array elements
      indexBanners(this.globalBanners);

      // Then we need to figure out the next banner to display
      // Assume we'll move to the next one and then wrap around if we remove
      // the last entry.
      if (banner.index > this.globalBanners.length - 1) {
        this.currentBannerIndex = 0;
      }
    } else {
      this.currentBannerIndex = null;
    }
  }

  /**
   * @description Method to dismiss a banner with a particular id.
   * @param {string} id Id of the banner that should be dismissed.
   */
  dismissBannerWithId(id) {
    const bannerToDismiss = this.globalBanners.find((banner) => banner.id === id);
    if (bannerToDismiss) {
      this.dismiss(bannerToDismiss);
    } else {
      log.error(`No banner found with provided id: ${id}`);
    }
  }

  /**
   * @description Getter for count of the number of banners that should be shown
   *     for an org.
   */
  get numberOfQueuedBanners() {
    return this.globalBanners.length;
  }
}

/**
 * @description Helper to re-index the banners after they've been sorted.
 * @param {Object[]} banners - Array of banners to index.
 */
function indexBanners(banners) {
  banners.forEach((banner, index) => {
    Object.assign(banner, {index});
  });
}

/**
 * @description Sorts by priority first, positive priorities appear sooner in
 *     the list than negative priorities and the default priority is 0. If the
 *     priorities are equal, then it sorts by type precedence which is ERROR,
 *     WARNING, and INFO.
 *
 * @param {Banner[]} banners is a list of objects that represent
 *
 * @returns {Banner[]} sortedBanners- a sorted list of banners
 */
function sortBanners(banners) {
  const sortedBanners = banners.filter(Boolean).sort((a, b) => {
    const priorityDifference = b.priority - a.priority;
    if (priorityDifference !== 0) {
      return priorityDifference;
    }
    if (a.type === b.type) {
      return 0;
    }
    if (
      a.type === GLOBAL_BANNER_TYPES.ERROR ||
      (a.type === GLOBAL_BANNER_TYPES.WARNING && b.type === GLOBAL_BANNER_TYPES.INFO)
    ) {
      return -1;
    }

    return 1;
  });

  indexBanners(sortedBanners);

  return sortedBanners;
}

export default GlobalBannerStore;
