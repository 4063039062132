import {log} from '@admin-tribe/binky';
import React, {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';

import GenericErrorBumper from 'shell/components/generic-error-bumper/GenericErrorBumper';

/** Handles a top level error being thrown from within the router */
const RootError = () => {
  const error = useRouteError();

  // Ignoring 404s because these will get thrown when we nav to an Angular page
  const is404 = error.status === 404;

  // Logs the error
  useEffect(() => {
    if (!is404) {
      log.error('Root application error', error);
    }
  }, [error, is404]);

  if (is404) {
    return null;
  }

  return <GenericErrorBumper />;
};

export default RootError;
