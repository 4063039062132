(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.usage-bar:appUsageBar
   * @description A usage bar component to show different type of usage
   *
   * @param {Object[]} data - The data to be displayed.
   * @param {Number} data[].amount - The amount of this usage.
   * @param {String} data[].color - Possible colors for now: blue, chartreuse, greym magenta,
   *                                indigo, and white
   * @param {String} data[].legendText - The legend text to be shown.
   * @param {String} linkText - The string for the link.
   * @param {Function} onClickLink - The callback function when the link is clicked.
   * @param {String} title - The string for title.
   *
   * @example <app-usage-bar
   *            data="[{amount: 42, color: 'indigo', legendText: 'label'}]"
   *            title="Storage Usage">
   *          </app-usage-bar>
   */
  angular.module('app.widgets.usage-bar').component('appUsageBar', {
    bindings: {
      data: '<',
      linkText: '<?',
      onClickLink: '&?',
      title: '@',
    },
    controller,
    templateUrl: 'app/widgets/common/usage-bar/usage-bar.component.html',
    transclude: true,
  });

  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      getWidthStyle,
    });

    function $onChanges() {
      _.assign(vm, {
        total: _(vm.data).map('amount').sum(),
      });
    }

    function getWidthStyle(amount) {
      return `${(amount / vm.total) * 100}%`;
    }
  }
})();
