(function () {
  /**
   * @ngdoc module
   * @name app.widgets.developers.remove-developer-modal
   * @description module that defines remove-developer-modal widget
   */
  angular.module('app.widgets.developers.remove-developer-modal', [
    'app.core.product.access',
    'app.core.user',
    'app.widgets.users',
    'binky.core.page-context',
    'binky.shell.panels.modal',
  ]);
})();
