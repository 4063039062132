import {VisuallyHidden} from '@react-aria/visually-hidden';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {NOTIFICATION_STATUS} from '../../notifications.constants';

// A VisuallyHidden span to put in a Notification that includes the read state and
// notifcation state for the screenreader.
const VisuallyHiddenStatus = ({notificationStatus, readState, statusId}) => {
  const intl = useIntl();

  // Notifications which are read and have 'INFO' status just need to expose their message to screenreader.
  if (readState && notificationStatus === NOTIFICATION_STATUS.INFO) {
    return null;
  }

  // For all other notifications, in addition to their message they should expose both their read/unread
  // state and status to the screenreader. This is done with a VisuallyHidden message.
  const readSubkey = readState ? 'READ' : 'UNREAD';
  const statusSubkey = `${notificationStatus}2`;

  return (
    <VisuallyHidden id={statusId}>
      {intl.formatMessage({
        id: `notifications.card.status.${readSubkey}.${statusSubkey}`,
      })}
    </VisuallyHidden>
  );
};

VisuallyHiddenStatus.propTypes = {
  /**
   * The notification status.
   */
  notificationStatus: PropTypes.oneOf(Object.values(NOTIFICATION_STATUS)).isRequired,
  /**
   * The current read state of the notification. True if notification has been read, else false.
   */
  readState: PropTypes.bool.isRequired,
  /**
   * A unique id to use for the status text.
   * The notification card will use this for an aria-labelledby prop.
   */
  statusId: PropTypes.string.isRequired,
};

export default VisuallyHiddenStatus;
