import {UserGroup} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AdminsDetailSection from 'common/components/admins-detail-section/AdminsDetailSection';
import ProductsDetailSection from 'common/components/products-detail-section/ProductsDetailSection';
import UsersQuantitiesDetailSection from 'common/components/users-quantities-detail-section/UsersQuantitiesDetailSection';

import useUserGroupDetailsDrawerContent from './useUserGroupDetailsDrawerContent';

/**
 * User group details drawer content
 */
const UserGroupDetailsDrawerContent = ({userGroup}) => {
  const intl = useIntl();

  const {
    adminList,
    adminListError,
    isAdminListLoading,
    isProductsLoading,
    isQuantitiesLoading,
    products,
    productsError,
    quantities,
    quantitiesError,
  } = useUserGroupDetailsDrawerContent({
    intl,
    userGroup,
  });

  return (
    <>
      <ProductsDetailSection
        isLoading={isProductsLoading}
        products={products}
        showError={!!productsError}
      />
      <UsersQuantitiesDetailSection
        isLoading={isQuantitiesLoading}
        quantities={quantities}
        showError={!!quantitiesError}
      />
      {!userGroup.isTarget && (
        <AdminsDetailSection
          admins={adminList?.items}
          isLoading={isAdminListLoading}
          showError={!!adminListError}
        />
      )}
    </>
  );
};

UserGroupDetailsDrawerContent.propTypes = {
  /**
   * The user group.
   */
  userGroup: PropTypes.instanceOf(UserGroup).isRequired,
};

export default UserGroupDetailsDrawerContent;
