import {ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';

import {ACTIONS} from './TrustDrawerActionsConstants';

const BUTTONS = [
  {
    id: ACTIONS.RESEND_REQUEST,
    isVisible: (directory) => directory.isStatusPending,
    label: 'settings.directories.trustDrawerActionButtons.resendRequest',
    variant: 'secondary',
  },
  {
    id: ACTIONS.CANCEL_REQUEST,
    isVisible: (directory) => directory.isStatusPending,
    label: 'settings.directories.trustDrawerActionButtons.cancelRequest',
    variant: 'negative',
  },
  {
    id: ACTIONS.REMOVE_TRUST,
    isVisible: (directory) =>
      directory.isStatusRevoked || directory.isStatusRejected || directory.isStatusActive,
    label: 'settings.directories.trustDrawerActionButtons.delete',
    variant: 'negative',
  },
];

const getButtons = (directory) => BUTTONS.filter((button) => button.isVisible(directory));

const TrustDrawerActionButtons = ({
  directory,
  onRemoveTrust,
  onResendRequest,
  onCancelRequest,
  ...styleProps
}) => {
  const buttons = getButtons(directory);
  const intl = useIntl();

  const canManageIdentityConfig = canChangeIdentityConfig();

  const onButtonPressed = (buttonId) => {
    switch (buttonId) {
      case ACTIONS.REMOVE_TRUST:
        onRemoveTrust();
        break;
      case ACTIONS.CANCEL_REQUEST:
        onCancelRequest();
        break;
      case ACTIONS.RESEND_REQUEST:
        onResendRequest();
        break;
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- nothing to test here
      // istanbul ignore next
      default:
      // Do nothing here since we have no callback to handle an unknown action
    }
  };

  return (
    <ButtonGroup marginTop="size-200" {...styleProps}>
      {buttons.map((button) => (
        <ButtonWithContextualHelp
          key={button.id}
          contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
          data-testid={button.id}
          isDisabled={!canManageIdentityConfig}
          onPress={() => onButtonPressed(button.id)}
          variant={button.variant}
        >
          {intl.formatMessage({id: button.label})}
        </ButtonWithContextualHelp>
      ))}
    </ButtonGroup>
  );
};

TrustDrawerActionButtons.propTypes = {
  directory: PropTypes.shape({
    isStatusActive: PropTypes.bool,
    isStatusPending: PropTypes.bool,
    isStatusRejected: PropTypes.bool,
    isStatusRevoked: PropTypes.bool,
  }),
  onCancelRequest: PropTypes.func.isRequired,
  onRemoveTrust: PropTypes.func.isRequired,
  onResendRequest: PropTypes.func.isRequired,
};

export default TrustDrawerActionButtons;
