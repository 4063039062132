(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains2:addDomains
   *
   * @description renders a table of domains which can be added to the org. Domains can be removed
   *   from the list via a "Remove" link in each row, until the list is down to one item.
   *
   * @binding {Array} domains - the domain preview returned from domainList.previewSave()
   * @binding {Function} onRemoveDomain - called when a domain is removed, with the removed domain
   */
  angular.module('app.widgets.domains2').component('appAddDomains', {
    bindings: {
      domains: '<',
      onRemoveDomain: '&',
    },
    controller,
    templateUrl: 'app/widgets/domains2/add-domains-modal/add-domains/add-domains.component.html',
  });

  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      onRemove,
    });

    /////////////////

    function $onChanges(changes) {
      if (changes.domains) {
        vm.domains = _.clone(changes.domains.currentValue);
      }
    }

    function onRemove(domain) {
      _.remove(vm.domains, domain);
      vm.onRemoveDomain({$event: {domain}});
    }
  }
})();
