(function () {
  /**
   * @ngdoc module
   * @name app.redirect
   * @description module that defines the root redirect pages/routes
   */
  angular.module('app.redirect', [
    'app.core.product.access',
    'app.widgets.users',
    'binky.shell.panels.manager',
  ]);
})();
