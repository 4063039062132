import {Store} from '@admin-tribe/binky';
import {action, computed, makeObservable} from 'mobx';

import BreadcrumbStore from '../breadcrumb-store/BreadcrumbStore';
import TopicStore from '../topic-store/TopicStore';
import UiStore from '../ui-store/UiStore';

// Domain data for help content
class HelpStore extends Store {
  constructor() {
    // set up state
    super();

    Object.assign(this, {
      breadcrumbs: new BreadcrumbStore(),
      topics: new TopicStore(),
      ui: new UiStore(),
    });

    makeObservable(this, {
      addTopic: action.bound,
      currentTopic: computed, // HelpTopic
      focusedTopicId: computed, // String
      goToTopic: action.bound,
      rootTopic: computed, // HelpTopic
    });
  }

  /**
   * @description Method to add a new topic to the help content.
   *
   *   Configuration Objects must use the following format:
   *
   *   {
   *     access: Function,
   *     id: String,
   *     content: [
   *       { // if configuring inline content for topic (points)
   *         access: Function,
   *         links: [
   *           {
   *             key: String,
   *             locator: String,
   *             type: LinkType
   *           }
   *         ],
   *         text: String,
   *         title: String,
   *         type: HelpItemType
   *       },
   *       { // if configuring references to child topics
   *         id: String
   *       }
   *     ],
   *     feature: {
   *       enabled: Boolean,
   *       name: String
   *     },
   *     order: Number,
   *     ordered: Boolean,
   *     parents: [
   *       {
   *         id: String
   *       }
   *     ],
   *     title: String
   *   }
   *
   * @param {Object} configObj - configuration Object, for in-depth
   *   description see configure method (above)
   * @throws {Error} if attempting to add a topic that has already been
   *   configured
   */
  addTopic(configObj) {
    if (!configObj) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(configObj, 'access')) {
      // gated access
      if (typeof configObj.access === 'function' && configObj.access()) {
        // check has passed (add topic)
        this.topics.add(configObj);
      }
    } else {
      // ungated access
      this.topics.add(configObj);
    }
  }

  get currentTopic() {
    return this.topics.selectedTopic;
  }

  get focusedTopicId() {
    return this.topics.focusedTopicId;
  }

  /**
   * @description Method to navigate to a new help topic.
   * @param {String} topicId - dot-delineated (optional hash mark)
   *   identifier used to represent a hierarchical location in the help topic
   *   system
   * @param {Boolean} trackHistory - true if navigation should update
   *   breadcrumbs, false if navigation should overwrite current breadcrumbs
   * @throws {Error} if topic identifier passed in does not exist or user
   *   does not have access to topic referenced
   */
  goToTopic(topicId, trackHistory) {
    // if topic changing, select new topic and update breadcrumbs
    if (!this.isTopicCurrent(topicId)) {
      this.topics.selectTopic(topicId);

      const fullPath = this.topics.focusedTopicId
        ? `${this.topics.selectedPath}#${this.topics.focusedTopicId}`
        : `${this.topics.selectedPath}`;

      if (trackHistory) {
        this.breadcrumbs.update(fullPath);
      } else {
        this.breadcrumbs.set(fullPath);
      }
    }

    // if help rail closed, then open it...
    this.ui.openHelpRail();
  }

  /**
   * @description Method to determine if the given topic (help item) is
   *   available or not. Content is considered available if it exists and
   *   exists in the hierarchy described by the identifier.
   *
   *   Since help topics are not added if a user does not have access,
   *   unavailable topics are those whose own (or parent help) access check
   *   failed.
   * @param {String} topicId - dot-delineated (optional hash mark) identifier
   *   used to represent a hierarchical location in the help topic system
   * @returns {Promise} resolves with Boolean value; true if topic is
   *   available, else false
   * @throws {Error} if topic identifier passed in does not exist in topic
   *   hierarchy described by identifier
   */
  isTopicAvailable(topicId) {
    return this.topics.isTopicAvailable(topicId);
  }

  /**
   * @description Method to determine if the topic passed in represents the
   *   currently-selected and focused topic.
   * @param {String} topicId - unique ID of topic to check
   * @returns {Boolean} true if topic is selected topic, else false
   */
  isTopicCurrent(topicId) {
    return this.topics.isTopicSelected(topicId);
  }

  /**
   * @description Method to determine if the given Topic ID is on the
   *   currently-selected topic path or not. If no topic is currently
   *   selected, then this method will return false.
   * @param {String} topicId - unique ID of topic to check
   * @returns {Boolean} true if topic is on path, else false
   */
  isTopicOnPath(topicId) {
    return this.topics.isTopicOnPath(topicId);
  }

  get rootTopic() {
    return this.topics.selectedRootTopic;
  }
}

export default HelpStore;
