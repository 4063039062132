import {PATH_SUPPORT} from 'features/support/routing/supportPaths';

import help from '../../../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 * @description Method to return configuration object for the manage SDL
 *   help topic
 * @returns content for manage SDL help topic
 */
const sdlHowToManage = () => ({
  content: [
    {
      text: 'help.topics.sdlHowToManage.retireComputers.text',
      title: 'help.topics.sdlHowToManage.retireComputers.title',
      type: HELP_ITEM_TYPE.STEP,
    },
    {
      text: 'help.topics.sdlHowToManage.addNewComputers.text',
      title: 'help.topics.sdlHowToManage.addNewComputers.title',
      type: HELP_ITEM_TYPE.STEP,
    },
    {
      links: [
        {
          key: 'help.topics.sdlHowToManage.swapComputers.link.one.key',
          locator: PATH_SUPPORT,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.sdlHowToManage.swapComputers.text',
      title: 'help.topics.sdlHowToManage.swapComputers.title',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.SDL_HOW_TO_MANAGE,
  order: 2,
  ordered: false,
  parents: [
    {
      id: TOPICS.MANAGE_SDL,
    },
  ],
  title: 'help.topics.sdlHowToManage.title',
});

export default sdlHowToManage;
