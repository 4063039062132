import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {PRICE_FAILURE, SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelDoneModal from '../components/self-cancel-done-modal/SelfCancelDoneModal';
import useProductsChangeConfirmation from '../hooks/use-products-change-confirmation/useProductsChangeConfirmation';

/**
 * Self cancel workflow step component used as a final success confirmation when the user accepts
 * a discount save offer.
 */
const DoneDiscountOfferStep = ({onDone, retentionId}) => {
  const intl = useIntl();
  const {dispatchNavigationAnalyticsEvent, dispatchSelfCancelPageAnalytics} =
    useSelfCancelAnalyticsContext();

  // Send page load analytics when component is loaded
  useEffect(() => {
    dispatchSelfCancelPageAnalytics({
      retentionId,
      step: SELF_CANCEL_STEPS.DONE_DISCOUNT_OFFER_STEP,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Explicitly only execute once on component load
  }, []);

  const {retentionConfirmationNumber, retentionNextBillingAmount} = useProductsChangeConfirmation();

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.DONE});
    onDone?.(retentionNextBillingAmount);
  };

  return (
    <SelfCancelDoneModal
      heading={intl.formatMessage({id: 'account.selfCancel.doneDiscountOffer.title'})}
      isManageLicensesSectionHidden
      onCta={onCta}
      successMessage={intl.formatMessage(
        {id: 'account.selfCancel.doneDiscountOffer.description'},
        {confirmationNumber: retentionConfirmationNumber || PRICE_FAILURE}
      )}
    />
  );
};

DoneDiscountOfferStep.propTypes = {
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onDone: PropTypes.func,
  /**
   * The selected retention Id.
   */
  retentionId: PropTypes.string.isRequired,
};

export default DoneDiscountOfferStep;
