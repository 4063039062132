import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const DeleteDirectoriesModalDescription = ({
  count,
  directoryName,
  hasTrustedDirectories,
  isT2eOrg,
}) => {
  const intl = useIntl();

  const formatValues = {
    b:
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
      // istanbul ignore next -- not testing this
      (chunks) => <strong>{chunks}</strong>,
    count,
    directoryName,
  };

  const descriptionBodyOwnedDirectories = (
    <Text>
      {intl.formatMessage(
        {id: 'settings.directories.deleteDirectoriesModal.ownedDirectories.description'},
        formatValues
      )}
    </Text>
  );

  const descriptionBodyTrustedDirectories = (
    <Text marginTop="size-200">
      {intl.formatMessage(
        {
          id: isT2eOrg
            ? 'settings.directories.deleteDirectoriesModal.trustedDirectories.deletionConsequences.t2eOrg'
            : 'settings.directories.deleteDirectoriesModal.trustedDirectories.deletionConsequences.nonT2eOrg',
        },
        formatValues
      )}
    </Text>
  );

  return (
    <Flex direction="column">
      {hasTrustedDirectories && (
        <Text>
          {intl.formatMessage(
            {
              id: 'settings.directories.deleteDirectoriesModal.trustedDirectories.aboutToDelete',
            },
            formatValues
          )}
        </Text>
      )}
      {hasTrustedDirectories ? descriptionBodyTrustedDirectories : descriptionBodyOwnedDirectories}
      {hasTrustedDirectories && (
        <Text marginTop="size-200">
          {intl.formatMessage(
            {id: 'settings.directories.deleteDirectoriesModal.trustedDirectories.confirmDeletion'},
            formatValues
          )}
        </Text>
      )}
    </Flex>
  );
};

DeleteDirectoriesModalDescription.propTypes = {
  /**
   * The number of directories selected to be deleted.
   */
  count: PropTypes.number,
  /**
   * The name of the first selected directory.
   */
  directoryName: PropTypes.string,
  /**
   * Whether any of the selected directories have an ownership status of "trusted".
   */
  hasTrustedDirectories: PropTypes.bool,
  /**
   * Whether the migration list for the selected org is T2E clean.
   */
  isT2eOrg: PropTypes.bool,
};
export default DeleteDirectoriesModalDescription;
