(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Migration
   * @description Model for a Contract's Migration object
   */
  angular
    .module('app.core.contract.migrations')
    .factory('OrganizationContractMigrations', getOrganizationContractMigrationsModel);

  /* @ngInject */
  function getOrganizationContractMigrationsModel(
    $rootScope,
    $stateParams,
    _,
    CONTRACT_MIGRATION_STARTED,
    jilOrganizationsContractMigrations,
    List,
    OrganizationContractMigration,
    OrganizationManager
  ) {
    class OrganizationContractMigrations extends List {
      /**
       * @class
       * @description Creates a new OrganizationContractMigrations instance
       *
       * @param {Object} organizationContractMigrations Initialization Object (params described below)
       * @param {Object} organizationContractMigrations.contractId contract id for this migration
       * @param {Object} organizationContractMigrations.type migration type
       */
      constructor(organizationContractMigrations) {
        super({
          itemClassRef: OrganizationContractMigration,
          items: organizationContractMigrations.items,
          resource: jilOrganizationsContractMigrations.migrations,
        });

        initModel(this, organizationContractMigrations);
      }

      migrate() {
        const reqParams = {
          contractId: this.contractId,
          type: this.type,
        };
        this.$resolved = false;
        this.$promise = this.resource
          .save(reqParams)
          .$promise.then(_.bind(this.refresh, this))
          .then(() => OrganizationManager.loadOrganization($stateParams.orgId))
          .then(() => {
            $rootScope.$broadcast(CONTRACT_MIGRATION_STARTED);
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      refresh() {
        return super.refresh({contractId: this.contractId, type: this.type});
      }

      /**
       * @description Creates a new ContractMigration object from fetched data
       * @param {Object} options - options to configure new Migration
       * @param {String} options.contractId contract id for this Migration
       * @param {String} options.type migration type
       * @returns {OrganizationContractMigrations} new OrganizationContractMigrations from fetched resource
       */
      static get(options) {
        const model = new OrganizationContractMigrations(options);
        model.refresh();
        return model;
      }
    }

    return OrganizationContractMigrations;

    ////////

    function initModel(model, options) {
      _.assignIn(model, _.pick(options, ['contractId', 'type']));
    }
  }
})();
