/* eslint-disable max-lines */
/* eslint-disable max-statements  */
(function () {
  /**
   * @deprecated use src2 User modal
   */
  angular.module('app.widgets.users').component('appAddUserForm', {
    bindings: {
      contextName: '<?',
      disableAddUser: '<?',
      disableSearchingUserGroups: '<?',
      displayMessage: '=?',
      excludedUserTypes: '<?',
      excludeTechAccounts: '<?',
      hideDescription: '@',
      isUserSelected: '=?',
      pageBanner: '=?',
      pageContext: '<',
      queuedUpUsers: '<?',
      targetProduct: '<?',
      user: '=',
      wait: '=',
    },
    controller,
    templateUrl: 'app/widgets/users/add-user-form/add-user-form.component.html',
  });

  /* @ngInject */
  function controller(
    $element,
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    auth,
    ADD_USER_FORM_ERROR,
    ADD_USER_FORM_VALIDITY_CHANGE,
    availableTypeHelper,
    AvailableTypeList,
    binkyProductNameLabelFilter,
    COMMON_EVENTS,
    CountryList,
    DisplayMessage,
    directoryAccess,
    DomainList,
    feature,
    locale,
    MEMBER_TYPE,
    numberFilter,
    OrganizationList,
    OrganizationManager,
    OrganizationUser,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    productAccess,
    t2eMigrationManager,
    translationUtils,
    USER_AUTOCOMPLETE_ERROR,
    USER_GROUP_THRESHOLD_GO_URL,
    USER_GROUP_USER_COUNT_THRESHOLD
  ) {
    const DEBOUNCE_TIME = 1000;
    const SEARCH_QUERY_MIN_LENGTH = 3;
    const MEMBER_TYPE_MAP = {
      [MEMBER_TYPE.TYPE1]: [
        {
          display: $translate.instant('widgets.users.addUserForm.types.type1.title2'),
          value: MEMBER_TYPE.TYPE1,
        },
      ],
      [MEMBER_TYPE.TYPE2]: [
        {
          display: $translate.instant('widgets.users.addUserForm.types.type2.title2'),
          value: MEMBER_TYPE.TYPE2,
        },
        {
          display: $translate.instant('widgets.users.addUserForm.types.type2.title3'),
          value: MEMBER_TYPE.TYPE2,
        },
      ],
      [MEMBER_TYPE.TYPE2E]: [
        {
          display: $translate.instant('widgets.users.addUserForm.types.type2e.title2'),
          value: MEMBER_TYPE.TYPE2E,
        },
      ],
      [MEMBER_TYPE.TYPE3]: [
        {
          display: $translate.instant('widgets.users.addUserForm.types.type3.title2'),
          value: MEMBER_TYPE.TYPE3,
        },
        {
          display: $translate.instant('widgets.users.addUserForm.types.type3.title3'),
          value: MEMBER_TYPE.TYPE3,
        },
      ],
    };
    const vm = this;
    _.assign(vm, {
      $onChanges,
      $onInit,
    });
    let shouldCheckForLargeGroup;

    /////////////
    function $onInit() {
      _.assign(vm, {
        areSomeExternallyManagedUsers,
        canAssignType1User: true,
        canCreateType2Or3: false,
        canDisplayIdTypeMessages,
        canSelectDifferentIdType,
        checkValidity,
        countryDefault: OrganizationList.get().activeOrg.countryCode,
        countryPlaceholder: $translate.instant('widgets.users.addUserForm.countryPlaceholder'),
        disableAddUser: !auth.canAddUsers() || !!vm.disableAddUser,
        disableTypeSelection,
        emailInputChangedPromise: $q.resolve(),
        emailInputWait: $q.defer(),
        emailInputWaitPromise: $q.resolve(),
        emailPlaceholder: $translate.instant('widgets.users.addUserForm.emailPlaceholder2'),
        existingUserTypeItems: [],
        feature,
        getCardButtonId,
        getIdentityTypeString,
        getUserDisplayNameOrEmail,
        hasExistingDomainUserWhoAlreadyHasCountry,
        // true: show the 'i' tooltip because at least one type is disabled, or
        // false: all types are available.
        // hasOneOrMoreDisabledTypes is not useful when hasOneOrMoreSelectableTypes is false
        hasOneOrMoreDisabledTypes: false,
        // true: show the add-user flow, or
        // false: display no-selectable-types message
        hasOneOrMoreSelectableTypes: true,
        hasType,
        identityTypePlaceholder: $translate.instant(
          'widgets.users.addUserForm.identityTypePlaceholder'
        ),
        isATrulyNewUserBeingAdded,
        isDeveloperContext: vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER,
        isEmailInvalid,
        isSurnameFirstNameLocale: locale.isSurnameFirstNameLocale(),
        MEMBER_TYPE,
        onBodyLinkClick,
        onConfirmAutocompleteUser,
        onCountryCodeChange,
        onEmailSearchInputChange,
        onSelectUser,
        onTypeChange,
        requireCountry,
        requireUserName,
        setupDomainMessage: t2eMigrationManager.isT2EClean()
          ? {
              body: $translate.instant('widgets.users.addUserForm.setupDomainT2e.body'),
              title: $translate.instant('widgets.users.addUserForm.setupDomainT2e.title'),
            }
          : {
              body: $translate.instant('widgets.users.addUserForm.setupDomain.body'),
              title: $translate.instant('widgets.users.addUserForm.setupDomain.title'),
            },
        showDetailForm: false,
        showDomainSetupMessage: false,
        showFederatedSSOAdminWarning,
        showIdentityTypeSelect,
        showNameContainer,
        showRecommendationMessage,
        showT1AlertTooltip,
        showT1Warning,
        showT1WarningTooltip,
        showUserDetailForm,
        t2t3recommendationMessage: t2eMigrationManager.isT2EClean()
          ? {
              body: $translate.instant('widgets.users.addUserForm.t2t3recommendationT2e.body'),
              title: $translate.instant('widgets.users.addUserForm.t2t3recommendationT2e.title'),
            }
          : {
              body: $translate.instant('widgets.users.addUserForm.t2t3recommendation.body2'),
              title: $translate.instant('widgets.users.addUserForm.t2t3recommendation.title'),
            },
        waitPromise: $q.resolve(),
      });

      $scope.$on(USER_AUTOCOMPLETE_ERROR, emitError);
      $scope.$on(COMMON_EVENTS.RESET, reset);

      reset();
      configureBasedOnPageContext();
      fetchCountryData();
    }

    function $onChanges() {
      configureBasedOnPageContext();
      checkToShowDomainSetupMessage();
      checkAvailableUserType();
    }

    function areSomeExternallyManagedUsers() {
      return _.some(vm.visibleExistingUserTypeItems, 'isExternallyManaged');
    }

    function buildPrioritizedAvailableUserTypes(typeList) {
      const TYPE_PRIORITIES = [
        MEMBER_TYPE.TYPE3,
        MEMBER_TYPE.TYPE2,
        MEMBER_TYPE.TYPE2E,
        MEMBER_TYPE.TYPE1,
      ];
      const prioritizedTypes = [];

      const availableTypes = _.filter(typeList, 'available');
      // Sort on the priority without being dependent on the 'userType' value.
      // Do not filter out TYPE1 even if canAssignType1User === false because
      // setDefaultUserType() requires to see all eligible user types
      _.forEach(TYPE_PRIORITIES, (userType) => {
        const availableType = _.find(availableTypes, {userType});
        if (availableType) {
          prioritizedTypes.push(availableType);
        }
      });

      return prioritizedTypes;
    }

    function canDisplayIdTypeMessages() {
      return !showUserDetailForm() && canSelectDifferentIdType();
    }

    function canSelectDifferentIdType() {
      return showIdentityTypeSelect() && vm.hasOneOrMoreSelectableTypes;
    }

    function checkAvailableUserType() {
      if (vm.targetProduct) {
        return productAccess.canAssignType1User(vm.targetProduct).then((result) => {
          vm.canAssignType1User = result;
          if (!result) {
            setSelectableTypes();
          }
        });
      }
      return $q.resolve();
    }

    function checkT2eTrustedDomainType(requiredByIdTypes) {
      const t2eTrustedDomainType = _.chain(vm.availableTypeList.items)
        .find({userType: 'TYPE2E'})
        .get('trustedDomainType')
        .value();
      return _.includes(requiredByIdTypes, t2eTrustedDomainType);
    }

    function checkToShowDomainSetupMessage() {
      if (
        (vm.targetProduct && _.invoke(vm, 'targetProduct.fulfillableItemList.hasDomainClaiming')) ||
        (vm.pageContext.target !== PAGE_TARGET.PRODUCT &&
          vm.pageContext.target !== PAGE_TARGET.STORAGE)
      ) {
        vm.waitPromise = $q
          .all([
            DomainList.get({
              orgId: OrganizationManager.getActiveOrgId(),
            }).$promise,
            directoryAccess.canCreateType2Or3(),
          ])
          .then(([domainList, canCreateType2Or3]) => {
            vm.canCreateType2Or3 = canCreateType2Or3;
            vm.showDomainSetupMessage = domainList.items.length === 0 && vm.canCreateType2Or3;
          });
      }
    }

    // Display a warning message if the number of users in user group is >75k and <= 200k, but allow users to be added.
    // Display an error message if the user group has more than 200,000 users and do not allow users to be added.
    function checkToShowLargeGroupMessage() {
      if (
        shouldCheckForLargeGroup &&
        vm.user.type === 'USER_GROUP' &&
        vm.user.userCount > USER_GROUP_USER_COUNT_THRESHOLD.WARNING
      ) {
        let goUrlName, namespace, variant;

        if (vm.user.userCount > USER_GROUP_USER_COUNT_THRESHOLD.ERROR) {
          namespace = 'widgets.users.addUserForm.largeGroupError';
          goUrlName =
            vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION
              ? USER_GROUP_THRESHOLD_GO_URL.PRODUCT_CONFIGURATION
              : USER_GROUP_THRESHOLD_GO_URL.PRODUCT;
        } else {
          namespace = 'widgets.users.addUserForm.largeGroupWarning';
          variant = 'warning';
        }

        const header = $translate.instant(`${namespace}.header`);
        const body = $translate.instant(
          `${namespace}.messageformat.message`,
          {
            count: numberFilter(
              variant === 'warning'
                ? USER_GROUP_USER_COUNT_THRESHOLD.WARNING
                : USER_GROUP_USER_COUNT_THRESHOLD.ERROR
            ),
            goUrlName,
            productName: binkyProductNameLabelFilter(vm.targetProduct),
            supportHref: '/support',
          },
          'messageformat'
        );

        vm.largeGroupDisplayMessage = new DisplayMessage({
          body,
          header,
          variant,
        });
      }
    }

    function checkValidity() {
      checkToShowLargeGroupMessage();

      const valid =
        !hasLargeGroupErrorBanner() &&
        (!vm.user.isNew() || // Allow existing user regardless
          (vm.validEmail && isValidUserType())) &&
        !!vm.isUserSelected;

      if (valid !== vm.isValid) {
        vm.isValid = valid;
        // Wait a step so that shared user has time to update
        $timeout(() => {
          $scope.$emit(ADD_USER_FORM_VALIDITY_CHANGE, vm.isValid);
        });
      }

      //////////////

      function isValidUserType() {
        return (
          vm.hasOneOrMoreSelectableTypes &&
          // Check username if it's required
          (!requireUserName() || _.size(vm.user.userName) > 0) &&
          // Check country code if it's required
          (!requireCountry() || _.some(vm.countriesData, ['value', vm.user.countryCode]))
        );
      }
    }

    function configureBasedOnPageContext() {
      vm.canAddExistingUsers = !(
        vm.pageContext.target === PAGE_TARGET.ORGANIZATION &&
        vm.pageContext.targetType === PAGE_TARGET_TYPE.USER
      );

      if (vm.isDeveloperContext) {
        vm.descriptionText = $translate.instant('widgets.users.addUserForm.emailOrNameDev');
      } else if (
        (vm.pageContext.target === PAGE_TARGET.PRODUCT ||
          vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) &&
        !_.invoke(vm, 'targetProduct.isTeam')
      ) {
        vm.descriptionText = $translate.instant(
          _.invoke(vm, 'targetProduct.isPricePointFree')
            ? 'widgets.users.addUserForm.emailOrNameProvisioningFewHours'
            : 'widgets.users.addUserForm.emailOrNameProvisioningFewMinutes'
        );
      } else {
        vm.descriptionText = $translate.instant('widgets.users.addUserForm.emailOrName2');
      }

      shouldCheckForLargeGroup =
        vm.pageContext.targetType === PAGE_TARGET_TYPE.USER &&
        (vm.pageContext.target === PAGE_TARGET.PRODUCT ||
          vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION);
    }

    function disableTypeSelection() {
      return !vm.canAssignType1User;
    }

    function emitError() {
      $scope.$emit(ADD_USER_FORM_ERROR);
    }

    function fetchCountryData() {
      vm.countryList = CountryList.get();
      vm.countryList.$promise
        .then(() => {
          vm.countriesData = _.map(vm.countryList.items, (country) => ({
            display: country.countryName,
            value: country.countryCode,
          }));
        })
        .catch(() => {
          showPageBanner();
        });
    }

    function getCardButtonId(selectedUserType) {
      return _.indexOf(vm.visibleExistingUserTypeItems, selectedUserType);
    }

    // In cases where a user exists in the domain, but not in the organization
    // the available types API returns available = true and has an existing
    // user. This function only returns users that fall into that case,
    // otherwise it returns undefined
    function getExistingDomainUserNotInOrg(type) {
      const item = _.find(vm.availableTypeList.items, {available: true, userType: type});
      return _.get(item, 'existingUser');
    }

    function getIdentityTypeString(userType) {
      return $translate.instant(`widgets.users.addUserForm.types.${_.toLower(userType)}.title2`);
    }

    function getUserDisplayNameOrEmail(user) {
      return new OrganizationUser(user).getDisplayNameOrEmail();
    }

    function hasExistingDomainUserWhoAlreadyHasCountry(type) {
      const existingUser = getExistingDomainUserNotInOrg(type);
      return existingUser && isCountryCodeDefined(existingUser.countryCode);
    }

    function hasType(type) {
      return _.some(vm.availableTypeList.items, {
        available: true,
        userType: type,
      });
    }

    function hasLargeGroupErrorBanner() {
      return _.get(vm.largeGroupDisplayMessage, 'variant') === 'error';
    }

    function hidePageBanner() {
      if (vm.pageBanner) {
        vm.pageBanner.display = false;
      }
      if (vm.displayMessage) {
        vm.displayMessage = undefined;
      }
    }

    function isATrulyNewUserBeingAdded() {
      // We want to show the user detail form right
      // away instead of clicking the "Add user of different type"
      // to fill out info since there were no available existing users found.
      return vm.existingUserTypeItems.length === 0 && vm.user.isNew();
    }

    function isCountryCodeDefined(countryCode) {
      return countryCode && countryCode !== 'UD';
    }

    function isEmailInvalid() {
      return vm.validEmail === false && vm.user.email.length >= SEARCH_QUERY_MIN_LENGTH;
    }

    function onAvailableTypeListError(error) {
      vm.validEmail = false;
      if (_.get(error, 'data.errorCode') === 'INVALID_EMAIL') {
        setInvalidEmailMessage('widgets.users.addUserForm.emailErrors.invalidFormat');
      } else {
        setInvalidEmailMessage('widgets.users.addUserForm.emailErrors.genericError');
      }
    }

    function onAvailableTypeListSuccess() {
      sortAvailableTypeList();

      if (
        _.get(vm, 'availableTypeList.items') &&
        _.some(vm.availableTypeList.items, 'existingUser') &&
        !_.some(vm.availableTypeList.items, 'available')
      ) {
        vm.validEmail = false;
        setInvalidEmailMessage('widgets.users.addUserForm.emailErrors.alreadyInOrg');
        return;
      }

      setSelectableTypes();

      if (
        _.get(vm, 'availableTypeList.items') &&
        _.some(vm.availableTypeList.items, 'existingUser')
      ) {
        vm.existingUserTypeItems = _.filter(
          vm.availableTypeList.items,
          (item) => item.existingUser && item.available
        );

        // Organize list to show synced users first
        vm.existingUserTypeItems = _.sortBy(vm.existingUserTypeItems, [
          function (user) {
            return user.isExternallyManaged ? 0 : 1;
          },
        ]);
        updateVisibleExistingUserTypeItems();

        if (vm.visibleExistingUserTypeItems.length > 1) {
          // Hold off on setting the user up until one has been selected via user cards
          return;
        }
      }

      vm.validEmail = true;

      // Set default country
      vm.user.countryCode = vm.countryDefault;

      setDefaultUserType();

      // Set default userName
      if (requireUserName()) {
        vm.user.userName = vm.user.email;
      }

      vm.showDetailForm = isATrulyNewUserBeingAdded();

      setExistingDomainUserIfNecessary();
      setTypeButtonText();
      checkValidity();
    }

    function onConfirmAutocompleteUser(user, availableTypeList) {
      reset();

      vm.largeGroupDisplayMessage = undefined;
      vm.availableTypeList = availableTypeList;
      vm.user = user || new OrganizationUser();
      vm.validEmail = !!user;
      if (user && user.isNew()) {
        onAvailableTypeListSuccess();
      } else {
        if (user) {
          vm.isUserSelected = true;
        }
        checkValidity();
      }
    }

    function onCountryCodeChange(countryCode) {
      vm.user.countryCode = countryCode;
      checkValidity();
    }

    function onEmailSearchInputChange(email) {
      reset();
      vm.user.email = email;
      vm.emailInputWait = $q.defer();
      vm.emailInputWaitPromise = vm.emailInputWait.promise;
      $timeout.cancel(vm.emailInputChangedPromise);
      if (vm.user.email.length >= SEARCH_QUERY_MIN_LENGTH) {
        vm.emailInputChangedPromise = $timeout(getAvailableTypes, DEBOUNCE_TIME);
      } else {
        vm.emailInputWait.resolve();
      }

      function getAvailableTypes() {
        vm.availableTypeList = AvailableTypeList.get({
          email: vm.user.email,
          orgId: OrganizationManager.getActiveOrgId(),
        });
        vm.availableTypeList.$promise
          .then(onAvailableTypeListSuccess)
          .catch(onAvailableTypeListError)
          .finally(() => {
            vm.emailInputWait.resolve();
          });
      }
    }

    function onBodyLinkClick() {
      // reset the user so user's details component resets as well when switching identities
      vm.user = new OrganizationUser({
        countryCode: vm.countryDefault,
        email: vm.user.email,
      });
      vm.isUserSelected = false;
      vm.visibleExistingUserTypeItems = [];
      vm.validEmail = true;
      vm.showDetailForm = true;

      checkValidity();
    }

    function onSelectUser(selectedUserCardId) {
      const index = _.toNumber(selectedUserCardId);
      vm.user = vm.visibleExistingUserTypeItems[index].existingUser;
      vm.validEmail = true;
      // Set default country
      if (_.isUndefined(vm.user.countryCode)) {
        vm.user.countryCode = vm.countryDefault;
      }

      setExistingDomainUserIfNecessary();
      setTypeButtonText();
      checkValidity();
    }

    function onTypeChange(type) {
      vm.user.type = type;

      // Reset to avoid unwanted info from carrying over
      vm.isUserSelected = false;
      vm.selectedCardId = undefined;

      updateVisibleExistingUserTypeItems();
      vm.user.userName = requireUserName() ? vm.user.email : undefined;

      // Reset products on type change so that in cases where a user can't be assigned
      // to certain products we don't just remove some products, we remove all
      vm.user.products = [];
      vm.user.userGroups = [];

      setExistingDomainUserIfNecessary();
      if (type && vm.visibleExistingUserTypeItems.length < 2) {
        vm.isUserSelected = true;
      }
      checkValidity();
    }

    function requireCountry() {
      return (
        vm.user.type &&
        // T1 does not require a country
        !vm.user.getType().isType1() &&
        // T2E requires a country only if the trusted domain is linked to T2 or T3
        (!vm.user.getType().isType2E() ||
          checkT2eTrustedDomainType([MEMBER_TYPE.TYPE2, MEMBER_TYPE.TYPE3])) &&
        // T2 or T3 requires a country if the user is not an existing directory user
        (!getExistingDomainUserNotInOrg(vm.user.type) ||
          // ... or, the existing/associated directory user has no country
          !hasExistingDomainUserWhoAlreadyHasCountry(vm.user.type))
      );
    }

    function requireUserName() {
      return (
        // T3 requires a userName if the user is not an existing directory user
        (vm.user.getType().isType3() && !getExistingDomainUserNotInOrg(vm.user.type)) ||
        // T2E requires a userName only if the trusted domain is linked to T3
        (vm.user.getType().isType2E() && checkT2eTrustedDomainType([MEMBER_TYPE.TYPE3]))
      );
    }

    function reset() {
      hidePageBanner();
      vm.availableTypeList = null;
      vm.existingUserTypeItems = [];
      vm.invalidEmailMessageKey = '';
      vm.isUserSelected = false;
      vm.selectableTypes = [];
      vm.selectedCardId = undefined;
      vm.showDetailForm = false;
      vm.validEmail = undefined;
      vm.visibleExistingUserTypeItems = [];

      vm.user = new OrganizationUser();

      // Resets isValid and triggers event if necessary
      checkValidity();
    }

    function setDefaultUserType() {
      vm.isUserSelected = true;
      // Default missing 'allowed' property to 'true' because the property won't be available
      // until https://jira.corp.adobe.com/browse/ONESIE-16297
      const allowedTypes = _.filter(
        buildPrioritizedAvailableUserTypes(vm.availableTypeList.items),
        (item) => _.get(item, 'allowed', true)
      );
      vm.user.type = _.get(allowedTypes[0], 'userType');
    }

    function setExistingDomainUserIfNecessary() {
      let existingDomainUser;
      // If there are cards to select from, should not auto-select a user
      if (vm.visibleExistingUserTypeItems.length < 2) {
        existingDomainUser = getExistingDomainUserNotInOrg(vm.user.type);
      } else if (vm.user.id) {
        // User has been selected via a user card
        existingDomainUser = vm.user;
      }
      if (existingDomainUser) {
        const countryCode = vm.user.countryCode;
        // Remove the id from user, we treat them like a brand new user
        vm.user = new OrganizationUser(_.omit(existingDomainUser, 'id'));

        if (!isCountryCodeDefined(vm.user.countryCode)) {
          vm.user.countryCode = countryCode;
        }

        vm.isUserSelected = true;
      }
    }

    function setInvalidEmailMessage(messageKey) {
      vm.invalidEmailMessageKey = messageKey;
      const tooltipMessage = $element[0].querySelector(
        '.invalid-email-tooltip coral-tooltip-content'
      );
      tooltipMessage.innerText = translationUtils.sanitizeSafeInstant(vm.invalidEmailMessageKey);
      checkValidity();
    }

    function setSelectableTypes() {
      vm.selectableTypes = [];

      // Show "(Recommended)" suffix on T2/T3 options whenever the user has a choice between T2/T3
      // and another identity type
      const showT2T3RecommendedSuffix =
        vm.hasType(vm.MEMBER_TYPE.TYPE2E) ||
        (vm.hasType(vm.MEMBER_TYPE.TYPE1) && vm.canAssignType1User);
      const typeTitleSuffix = showT2T3RecommendedSuffix ? 0 : 1;

      let availableTypes = buildPrioritizedAvailableUserTypes(vm.availableTypeList.items);
      // filter out any T1
      if (!vm.canAssignType1User) {
        availableTypes = _.reject(availableTypes, ['userType', MEMBER_TYPE.TYPE1]);
      }

      vm.hasOneOrMoreSelectableTypes = false;
      vm.hasOneOrMoreDisabledTypes = false;
      _.forEach(availableTypes, (availableType) => {
        const userType = availableType.userType;
        const overrideType = MEMBER_TYPE_MAP[userType][typeTitleSuffix];
        // Default missing 'allowed' property to 'true' because the property won't be available
        // until https://jira.corp.adobe.com/browse/ONESIE-16297
        const isAllowed = _.get(availableType, 'allowed', true);
        if (!isAllowed) {
          overrideType.disabled = true; // Only set if not allowed
        }
        vm.selectableTypes.push(overrideType);

        vm.hasOneOrMoreDisabledTypes = vm.hasOneOrMoreDisabledTypes || !isAllowed;
        vm.hasOneOrMoreSelectableTypes = vm.hasOneOrMoreSelectableTypes || isAllowed;
      });
      vm.noSelectableTypesMessage = availableTypeHelper.generateNoSelectableTypesMessage(
        vm.availableTypeList.items
      );
    }

    function setTypeButtonText() {
      // Translating doesn't work inside coral-buttongroup so inject with javascript
      // See https://git.corp.adobe.com/admin-tribe/onesie/issues/997 for context
      $timeout(() => {
        _.forEach(['type1', 'type2', 'type3'], (type) => {
          const title = $element[0].querySelector(`button.${type} .title`);
          const description = $element[0].querySelector(`button.${type} .description`);
          if (title) {
            title.innerText = $translate.instant(`widgets.users.addUserForm.types.${type}.title`);
          }
          if (description) {
            description.innerText = $translate.instant(
              `widgets.users.addUserForm.types.${type}.description`
            );
          }
        });
      });
    }

    function showFederatedSSOAdminWarning() {
      return (
        vm.pageContext.targetType === 'ADMIN' &&
        vm.user.type === MEMBER_TYPE.TYPE3 &&
        t2eMigrationManager.isT2EClean()
      );
    }

    function showIdentityTypeSelect() {
      return vm.selectableTypes.length > 1;
    }

    function showNameContainer() {
      return vm.user.type && !getExistingDomainUserNotInOrg(vm.user.type);
    }

    function showPageBanner(message) {
      if (vm.pageBanner) {
        vm.pageBanner.display = true;
        vm.pageBanner.message = message;
      }
      if (vm.displayMessage) {
        vm.displayMessage = new DisplayMessage({
          body: message,
        });
      }
    }

    function showRecommendationMessage() {
      if (t2eMigrationManager.isT2EClean()) {
        return false;
      }

      const hasType2Or3 = vm.hasType(vm.MEMBER_TYPE.TYPE2) || vm.hasType(vm.MEMBER_TYPE.TYPE3);
      if (vm.targetProduct) {
        return (
          !hasType2Or3 &&
          !vm.showDomainSetupMessage &&
          vm.targetProduct.fulfillableItemList.hasDomainClaiming()
        );
      }
      return (
        (!hasType2Or3 && vm.canCreateType2Or3) ||
        (!t2eMigrationManager.isT2EClean() && vm.selectableTypes.length > 1)
      );
    }

    function showT1Warning() {
      return (
        vm.user.type === MEMBER_TYPE.TYPE1 &&
        (vm.hasType(vm.MEMBER_TYPE.TYPE3) || vm.hasType(vm.MEMBER_TYPE.TYPE2))
      );
    }

    function showT1WarningTooltip() {
      if (vm.targetProduct && !vm.user.isNew() && vm.user.getType().isType1()) {
        return (
          _.invoke(vm, 'targetProduct.fulfillableItemList.hasDomainClaiming') &&
          vm.canAssignType1User
        );
      }
      return false;
    }

    function showT1AlertTooltip() {
      if (vm.targetProduct && vm.user.getType().isType1()) {
        vm.T1AlertTooltip = vm.user.isNew()
          ? 'widgets.users.addUserForm.type1Alert.newUser'
          : 'widgets.users.addUserForm.type1Alert.existingUser';
        return !vm.canAssignType1User;
      }
      return false;
    }

    function showUserDetailForm() {
      return (
        vm.hasOneOrMoreSelectableTypes &&
        vm.validEmail &&
        vm.user.isNew() &&
        (vm.canAssignType1User || !vm.user.getType().isType1()) &&
        vm.showDetailForm
      );
    }

    function sortAvailableTypeList() {
      // If there happens to be multiple options of the same type,
      // sort so the user with
      // the email that matches the input has priority over the user
      // with an username that matches the input.
      vm.availableTypeList.items = _.sortBy(vm.availableTypeList.items, [
        function (user) {
          if (user.existingUser) {
            if (user.existingUser.email === vm.user.email) {
              return 0;
            } else if (user.existingUser.userName === vm.user.email) {
              return 1;
            }
            return 2;
          }
          return 3;
        },
      ]);
    }

    function updateVisibleExistingUserTypeItems() {
      vm.visibleExistingUserTypeItems = vm.user.type
        ? _.filter(vm.existingUserTypeItems, (item) => _.isEqual(item.userType, vm.user.type))
        : vm.existingUserTypeItems;

      // Auto select synced user if there is one in the card list shown
      if (
        vm.visibleExistingUserTypeItems.length > 1 &&
        vm.visibleExistingUserTypeItems[0].isExternallyManaged
      ) {
        vm.selectedCardId = '0';
        onSelectUser(vm.selectedCardId);
      }
    }
  }
})();
/* eslint-enable max-statements */
/* eslint-enable max-lines */
