(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.user-groups:appUserGroupSelect
   * @description add/edit user groups assigned to a user.
   * @param {OrganizationUser} user target user whom to add/rm user groups.
   */
  angular.module('app.widgets.user-groups').component('appUserGroupSelect', {
    bindings: {
      user: '=',
    },
    controller,
    templateUrl: 'app/widgets/user-groups/select/user-group-select.component.html',
  });

  function controller(
    $q,
    $scope,
    _,
    USER_GROUP_SELECT_ERROR,
    USER_GROUP_SELECT_CHANGE,
    UserGroupList
  ) {
    const vm = this;
    _.assign(vm, {
      $doCheck,
      $onInit,
      getAssignedUserGroupTotal,
      onAutocompleteChange,
      onRemoveAll,
      previousUserUserGroups: null,
    });

    //////////////////

    function $onInit() {
      const loadAllUserGroups = true;
      const loadOnlyEditableUserGroups = true;
      vm.waitPromise = $q
        .all([
          vm.user.$promise,
          UserGroupList.get(loadAllUserGroups, loadOnlyEditableUserGroups).$promise,
        ])
        .then((result) => {
          const userGroupList = result[1];

          // Filter externally managed groups as users cannot be added to those
          userGroupList.items = _.filter(
            userGroupList.items,
            (userGroup) => !userGroup.isExternallyManaged()
          );

          const autocompleteItems = _.map(userGroupList.items, (userGroup) => ({
            display: userGroup.name,
            value: userGroup.id,
          }));
          _.assign(vm, {
            // All user groups available to be selected.
            autocompleteItems,
            // User groups currently selected/assigned to user.
            autocompleteValues: getAutocompleteValues(vm.user.userGroups, autocompleteItems),
            userGroupList,
          });
        })
        .catch(() => {
          $scope.$emit(USER_GROUP_SELECT_ERROR);
          return $q.reject();
        });
      _.defaults(vm.user, {
        userGroups: [],
      });
    }

    function $doCheck() {
      // Watch changes to the user and the user's user groups
      if (vm.previousUserUserGroups !== vm.user.userGroups) {
        vm.autocompleteValues = getAutocompleteValues(vm.user.userGroups, vm.autocompleteItems);
        vm.previousUserUserGroups = vm.user.userGroups;
      }
    }

    function getAssignedUserGroupTotal() {
      return _.get(vm.user, 'userGroups.length');
    }

    function onRemoveAll() {
      vm.user.userGroups = removeAllManageableUserGroups(vm.user.userGroups, vm.autocompleteItems);
      vm.autocompleteValues = [];
      $scope.$emit(USER_GROUP_SELECT_CHANGE);
    }

    function onAutocompleteChange(values) {
      vm.user.userGroups = removeAllManageableUserGroups(vm.user.userGroups, vm.autocompleteItems);

      const selectedGroups = _.filter(vm.userGroupList.items, (userGroup) =>
        _.some(values, (value) => value === userGroup.id)
      );
      vm.user.userGroups = vm.user.userGroups.concat(selectedGroups);
      vm.autocompleteValues = getAutocompleteValues(vm.user.userGroups, vm.autocompleteItems);
      $scope.$emit(USER_GROUP_SELECT_CHANGE);
    }

    //////////////////

    function removeAllManageableUserGroups(userGroups, manageableUserGroups) {
      // Temporarily disable for https://github.com/Gillespie59/eslint-plugin-angular/issues/449

      return _.filter(
        userGroups,
        (userGroup) => !_.some(manageableUserGroups, ['value', userGroup.id])
      );
    }

    function getAutocompleteValues(userGroups, autocompleteItems) {
      return _(userGroups)
        .filter((userGroup) => _.some(autocompleteItems, ['value', userGroup.id])) // Only allow editing of usergroups that appear in the autocomplete list.
        .sortBy(['name'])
        .map('id')
        .value();
    }
  }
})();
