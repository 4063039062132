import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';

/**
 * Table of password settings options for this org.
 */
const PasswordSettingsTable = () => {
  const intl = useIntl();
  // String IDs
  const weakAuthLevel = 'settings.authSettings.password.level.weak';
  const mediumAuthLevel = 'settings.authSettings.password.level.medium';
  const strongAuthLevel = 'settings.authSettings.password.level.strong';

  const tableStringRoot = 'settings.authSettings.password.table';
  const rows = [
    {
      id: `${tableStringRoot}.key.minChars`,
      key: 'min-num-chars',
      minChars: 8,
    },
    {
      id: `${tableStringRoot}.key.symbolNumber`,
      key: 'symbol-and-num',
      value: `${tableStringRoot}.value.symbolNumber`,
    },
    {
      id: `${tableStringRoot}.key.charCasing`,
      key: 'lower-and-upper-case',
      value: `${tableStringRoot}.value.charCasing`,
    },
    {
      id: `${tableStringRoot}.key.matchPrev`,
      key: 'match-match-prev',
      value: `${tableStringRoot}.value.matchPrev`,
    },
    {
      id: `${tableStringRoot}.key.expiration`,
      key: 'expires',
      medium: `${tableStringRoot}.value.expiration.medium`,
      strong: `${tableStringRoot}.value.expiration.strong`,
      weak: `${tableStringRoot}.value.expiration.weak`,
    },
  ];

  return (
    <TableView
      aria-label={intl.formatMessage({id: 'settings.authSettings.password.table.title'})}
      density="spacious"
    >
      <TableHeader>
        <Column key="id" showDivider width="30%">
          <FormattedMessage id="settings.authSettings.password.table.key.level" />
        </Column>
        <Column key="weak" align="center">
          <FormattedMessage id={weakAuthLevel} />
        </Column>
        <Column key="medium" align="center">
          <FormattedMessage id={mediumAuthLevel} />
        </Column>
        <Column key="strong" align="center">
          <FormattedMessage id={strongAuthLevel} />
        </Column>
      </TableHeader>
      <TableBody>
        {rows.map((row) => (
          <Row key={row.key}>
            <Cell>
              <FormattedMessage id={row.id} />
            </Cell>
            <Cell>
              {row.minChars ? (
                <FormattedNumber value={row.minChars} />
              ) : (
                <FormattedMessage id={row.value || row.weak} />
              )}
            </Cell>
            <Cell>
              {row.minChars ? (
                <FormattedNumber value={row.minChars} />
              ) : (
                <FormattedMessage id={row.value || row.medium} />
              )}
            </Cell>
            <Cell>
              {row.minChars ? (
                <FormattedNumber value={row.minChars} />
              ) : (
                <FormattedMessage id={row.value || row.strong} />
              )}
            </Cell>
          </Row>
        ))}
      </TableBody>
    </TableView>
  );
};

PasswordSettingsTable.propTypes = {};

export default PasswordSettingsTable;
