(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.contracts:appVipMarketplaceAccountTable
   * @description renders a table showing informations exclusively for VIP Marketplace contracts
   * @param {AdminList} adminList - Promise that must resolve before exposing the Contract owner
   * @param {Contract} contract - A VIPMP contract to show relevant information
   * @param {Function} onChangeClick - function to open modal to change primary admin
   * on user click
   * @param {Function} onRevokeClick - function to open revoke contract owner invitation
   * modal on user click
   *
   * @example <app-vip-marketplace-account-table
   *           admin-list="$ctrl.adminList"
   *           contract="$ctrl.contract"
   *           on-change-click="$ctrl.openChangePrimaryAdminModal()"
   *           on-revoke-click="$ctrl.openRevokeContractOwnerInvitationModal()">
   *          </app-vip-marketplace-account-table>
   */
  angular.module('app.widgets.contracts').component('appVipMarketplaceAccountTable', {
    bindings: {
      adminList: '<',
      contract: '<',
      onChangeClick: '&',
      onRevokeClick: '&',
    },
    controller,
    templateUrl:
      'app/widgets/contracts/vip-marketplace-account-table/vip-marketplace-account-table.component.html',
  });
  /* @ngInject */
  function controller($filter, $stateParams, _, contractAccess, contractUtils) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      showMembershipLevelRow,
    });

    function $onInit() {
      if (vm.contract.getAnniversaryDate()) {
        vm.anniversaryDate = $filter('binkyDateTime')(vm.contract.getAnniversaryDate(), {
          dateOnly: true,
        });
      }

      vm.showChangeContractOwnerButton = false;

      contractAccess
        .canChangeContractOwner(vm.contract, vm.adminList)
        .then((canChangeContractOwner) => {
          vm.showChangeContractOwnerButton = canChangeContractOwner;
        })
        .catch(_.noop)
        .finally(() => {
          vm.ownerInfo = contractUtils.getContractOwner(vm.contract);
        });

      vm.orgId = $stateParams.orgId;
    }

    function showMembershipLevelRow() {
      const membershipInfo = vm.contract.getSelectMembershipInfo();
      return _.get(membershipInfo, 'level');
    }
  }
})();
