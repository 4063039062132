import {useCollator} from '@adobe/react-spectrum';
import {useCallback} from 'react';

/**
 *
 * @param {deactivatedDevicesList} deactivatedDevicesList The list of devices to sort
 * @returns {Function} sorter function that accepts an object of 'id' and 'sortedOrder' used for sorting the list
 */
const useDeactivatedTableSorter = (deactivatedDevicesList) => {
  const collator = useCollator({numeric: true});

  const sorter = useCallback(
    ({id: columnName, sortedOrder}) => {
      if (deactivatedDevicesList.length === 0) {
        return [];
      }
      return [...deactivatedDevicesList].sort((a, b) => {
        const first = a[columnName];
        const second = b[columnName];
        let cmp = collator.compare(first, second);
        if (sortedOrder === 'descending') {
          cmp *= -1;
        }
        return cmp;
      });
    },
    [collator, deactivatedDevicesList]
  );

  return {sorter};
};

export default useDeactivatedTableSorter;
