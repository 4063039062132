import {MigrationPolicy} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the migration policy api to fetch the policy information
 * associated with an organization for a provided policy name
 */
const useMigrationPolicy = ({name, orgId}) => {
  const loadMigrationPolicy = useCallback(() => MigrationPolicy.get({name, orgId}), [name, orgId]);

  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadMigrationPolicy,
  });

  return {error, isLoading, policy: model};
};

export default useMigrationPolicy;
