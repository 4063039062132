(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.directories.sync')
    .constant('DIRECTORY_SYNC_DOMAIN_VERIFICATION_STATUS', {
      NOTVERIFIED: 'NOTVERIFIED',
      VERIFIED: 'VERIFIED',
    })
    .constant('DIRECTORY_SYNC_DOMAIN_LINK_STATUS', {
      FREE: 'FREE',
      INVALID: 'INVALID',
      LINKED: 'LINKED',
    });
})();
