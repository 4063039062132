// eslint-disable-next-line filenames/match-exported -- context
import {OfferList, PoresOfferList, feature, log} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useState} from 'react';

import {activeOrgHasESM} from 'core/storage/access/storageAccess';

const FreeOfferModalContext = React.createContext({});
const useFreeOfferModalContext = () => useContext(FreeOfferModalContext);

const FreeOfferModalContextProvider = ({children, offerList}) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [marketSubsegments, setMarketSubsegments] = useState([]);
  const [hasAdditionalTermsToAccept, setHasAdditionalTermsToAccept] = useState(false);
  const [currentOfferList, setCurrentOfferList] = useState(offerList);
  const [modalError, setModalError] = useState(null);
  const [orgHasESM, setOrgHasESM] = useState(false);

  // fetches the current offer list
  useEffect(() => {
    let isCurrent = true;

    async function fetchOfferList() {
      let newOfferList;
      try {
        if (feature.isDisabled('temp_ctir_21074_oms_removal')) {
          newOfferList = await OfferList.get(offerList);
        } else {
          newOfferList = await PoresOfferList.get(offerList);
        }
      } catch (error) {
        log.error('Failed to get solution groups. Error: ', error);
      }

      if (isCurrent) {
        setCurrentOfferList(newOfferList);
      }
    }

    fetchOfferList();

    return () => {
      isCurrent = false;
    };
  }, [offerList]);

  // fetches storage repo to determine if active org has ESM
  useEffect(() => {
    let isCurrent = true;

    async function fetchActiveOrgHasESM() {
      let result;
      try {
        result = await activeOrgHasESM();
      } catch (error) {
        log.error('Failed to get activeOrgHasESM for filtering FIs. Error: ', error);
      }

      if (isCurrent) {
        setOrgHasESM(result);
      }
    }

    fetchActiveOrgHasESM();

    return () => {
      isCurrent = false;
    };
  }, [orgHasESM]);

  const value = useMemo(
    () => ({
      currentOfferList,
      hasAdditionalTermsToAccept,
      isLoading,
      isValid,
      marketSubsegments,
      modalError,
      orgHasESM,
      setHasAdditionalTermsToAccept,
      setIsLoading,
      setIsValid,
      setMarketSubsegments,
      setModalError,
    }),
    [
      currentOfferList,
      hasAdditionalTermsToAccept,
      isLoading,
      isValid,
      marketSubsegments,
      modalError,
      orgHasESM,
      setHasAdditionalTermsToAccept,
      setIsLoading,
      setIsValid,
      setMarketSubsegments,
      setModalError,
    ]
  );

  return (
    <FreeOfferModalContext.Provider value={value}>
      <FreeOfferModalContext.Consumer>{children}</FreeOfferModalContext.Consumer>
    </FreeOfferModalContext.Provider>
  );
};

FreeOfferModalContextProvider.propTypes = {
  children: PropTypes.func,
  offerList: PropTypes.oneOfType([PropTypes.object]),
};

export {useFreeOfferModalContext};
export default FreeOfferModalContextProvider;
