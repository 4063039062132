import {OrganizationUser, PRODUCT_DELEGATION_TARGET} from '@admin-tribe/binky';
import {ActionMenu, Item, View} from '@adobe/react-spectrum';
import {useCollator} from '@react-aria/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AdministrativeRightsDetailSection from 'common/components/administrative-rights-detail-section/AdministrativeRightsDetailSection';
import ProductsDetailSection from 'common/components/products-detail-section/ProductsDetailSection';
import UserDetailsSection from 'common/components/user-details-section/UserDetailsSection';
import UserGroupsDetailSection from 'common/components/user-groups-detail-section/UserGroupsDetailSection';
import rootStore from 'core/RootStore';
import {canEditProducts} from 'core/products/access/productAccess';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

const EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY = USER_DETAILS_MODAL_NAMES.EDIT_PRODUCTS_AND_GROUPS;
const EDIT_DEV_PRODUCTS_ITEM_KEY = USER_DETAILS_MODAL_NAMES.EDIT_DEVELOPER_PRODUCTS;

/**
 * The User/Admin details content shared between page and drawer.
 */
// eslint-disable-next-line complexity -- due to conditionals in render method
const UserDetailsContentWrapper = ({
  headingLevel,
  isLoading,
  isPageVariant,
  member,
  onOpenModal,
  showError,
}) => {
  const collator = useCollator();
  const intl = useIntl();
  const activeOrgProducts = rootStore.organizationStore.productList;
  const activeOrgConsumableAndDelegatables = rootStore.organizationStore.orgConsumables;

  // Member can be undefined until it resolves.
  const canMemberEditProducts = () =>
    canEditProducts() &&
    !member.getType().isTechnicalAccount() &&
    activeOrgProducts.hasUserDelegatableProduct();

  const hasAPIProducts = activeOrgProducts?.items?.some((product) =>
    product?.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  );

  // Always show menu once member has loaded so focus can be recovered
  // after admin closes edit modal.
  const canShowEditMenu = member && !showError;
  // Disable the menu item keys until data is ready
  const editProductUserGroupDisabledMenuKeys =
    !isLoading && !showError ? [] : [EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY];
  const editDeveloperProductsDisabledMenuKeys =
    !isLoading && !showError ? [] : [EDIT_DEV_PRODUCTS_ITEM_KEY];

  let allMemberProducts;
  if (member) {
    allMemberProducts = activeOrgConsumableAndDelegatables.getProductDisplayList(
      collator,
      intl,
      member.products
    );
  } else {
    allMemberProducts = [];
  }

  return (
    <View marginEnd="size-10" marginTop="size-10">
      {isPageVariant && (
        <UserDetailsSection
          headingLevel={headingLevel}
          isPageVariant={isPageVariant}
          member={member}
          onMenuActionPress={onOpenModal}
        />
      )}
      <ProductsDetailSection
        data-testid="products"
        headingLevel={headingLevel}
        isLoading={isLoading}
        isPageVariant={isPageVariant}
        products={allMemberProducts}
        rightHeaderContent={
          canShowEditMenu &&
          canMemberEditProducts() && (
            <ActionMenu
              disabledKeys={editProductUserGroupDisabledMenuKeys}
              isQuiet
              onAction={onOpenModal}
            >
              <Item key={EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY}>
                {intl.formatMessage({
                  id: 'users.administrators.administratorsDetails.products.moreOptions.edit',
                })}
              </Item>
            </ActionMenu>
          )
        }
        showError={showError}
      />
      {hasAPIProducts && (
        <ProductsDetailSection
          data-testid="developer-access"
          headingLevel={headingLevel}
          isDeveloperAccess
          isLoading={isLoading}
          isPageVariant={isPageVariant}
          products={member?.developerProducts ?? []}
          rightHeaderContent={
            canShowEditMenu &&
            canMemberEditProducts() && (
              <ActionMenu
                disabledKeys={editDeveloperProductsDisabledMenuKeys}
                isQuiet
                onAction={onOpenModal}
              >
                <Item key={EDIT_DEV_PRODUCTS_ITEM_KEY}>
                  {intl.formatMessage({
                    id: 'users.administrators.administratorsDetails.developerAccess.moreOptions.edit',
                  })}
                </Item>
              </ActionMenu>
            )
          }
          showError={showError}
        />
      )}
      <UserGroupsDetailSection
        headingLevel={headingLevel}
        isLoading={isLoading}
        isPageVariant={isPageVariant}
        member={member}
        onMenuActionPress={onOpenModal}
        showError={showError}
        userGroups={member?.userGroups ?? []}
      />

      <AdministrativeRightsDetailSection
        headingLevel={headingLevel}
        isLoading={isLoading}
        isPageVariant={isPageVariant}
        member={member}
        onMenuActionPress={onOpenModal}
        showError={showError}
        userRoles={member?.roles ?? []}
      />
    </View>
  );
};

UserDetailsContentWrapper.propTypes = {
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether the component should display the loading indicator or not.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The user or admin.
   */
  member: PropTypes.instanceOf(OrganizationUser),
  /**
   * Callback to open a modal.
   */
  onOpenModal: PropTypes.func.isRequired,
  /**
   * Whether an error occurred during fetching data or not.
   */
  showError: PropTypes.bool,
};

export default UserDetailsContentWrapper;
