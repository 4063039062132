(function () {
  /**
   * @ngdoc module
   * @name app.main.global-modals
   * @description Module containing global-modals components/resources
   */
  angular.module('app.main.global-modals', [
    'app.widgets.users',
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.video-modal',
  ]);
})();
