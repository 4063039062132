import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the Directory details page and its sub-components.
 * Used to pass the directory state between sub-components.
 */
const DirectoryDetailsContext = createContext({});
const useDirectoryDetailsContext = () => useContext(DirectoryDetailsContext);

const DirectoryDetailsContextProvider = ({children, state}) => {
  const value = useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return (
    <DirectoryDetailsContext.Provider value={value}>{children}</DirectoryDetailsContext.Provider>
  );
};

DirectoryDetailsContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the directory list .
   */
  children: PropTypes.node,
  /**
   * The directory list state used by the provider to keep state of the children components.
   */
  state: PropTypes.shape({
    loadDirectory: PropTypes.func,
    reloadDirectory: PropTypes.func,
    state: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- this is an instance so it makes it easier to test like this
      directory: PropTypes.object,
      hasLoadingError: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  }),
};

export {DirectoryDetailsContextProvider, useDirectoryDetailsContext};
