(function () {
  /**
   * @ngdoc module
   * @name app.widgets.bulk-operations-form
   * @description module that defines bulk-operations form
   */
  angular.module('app.widgets.bulk-operations.form', [
    'app.main.jobs',
    'app.widgets.bulk-operations.constants',
    'app.widgets.bulk-operations.context',
    'app.widgets.bulk-operations.localization',
    'app.widgets.bulk-operations.samples',
    'binky.core.download-utils',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.widgets.common.display-message',
    'binky.widgets.common.feature',
    'binky.widgets.common.toast',
    'ngSanitize',
  ]);
})();
