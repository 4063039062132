import {CONTRACT_EXPIRATION_PHASE, jilUsers, log} from '@admin-tribe/binky';
import {
  ContractExpirationStatus,
  FormattedDateAndTime,
  NavigationAnchor,
  ProductIconList,
  showError,
  showInfo,
} from '@admin-tribe/binky-ui';
import {Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {SORT_ORDER, Table, useTableSectionContext} from '@pandora/react-table-section';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {Item, TagGroup} from '@react-spectrum/tag';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {downloadUserList} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationUtils';
import BuyMoreTooltipButton from 'common/components/buy-more-tooltip-button/BuyMoreTooltipButton';
import rootStore from 'core/RootStore';

import {CONTRACT_TABLE_DATA_KEYS} from './OverviewConstants';

const INITIAL_SORT_DESCRIPTOR = {
  column: CONTRACT_TABLE_DATA_KEYS.DATE,
  direction: SORT_ORDER.ASC,
};

/**
 * Table to display a list of contracts
 */
const ContractTable = ({isLoading}) => {
  const {tableSectionState} = useTableSectionContext();
  const intl = useIntl();

  const columns = useMemo(
    () => [
      {
        align: 'start',
        allowsSorting: true,
        children: <FormattedMessage id="account.overview.table.column.contractDisplayName" />,
        key: CONTRACT_TABLE_DATA_KEYS.CONTRACT_DISPLAY_NAME,
        minWidth: '30%',
      },
      {
        align: 'end',
        'aria-label': intl.formatMessage({
          id: 'account.overview.table.column.actions.description',
        }),
        key: CONTRACT_TABLE_DATA_KEYS.ACTIONS,
        maxWidth: '10%',
        showDivider: true,
      },
      {
        children: <FormattedMessage id="account.overview.table.column.products" />,
        key: CONTRACT_TABLE_DATA_KEYS.PRODUCTS,
        minWidth: '20%',
      },
      {
        allowsSorting: true,
        children: <FormattedMessage id="account.overview.table.column.date" />,
        key: CONTRACT_TABLE_DATA_KEYS.DATE,
        minWidth: '18%',
      },
      {
        children: <FormattedMessage id="account.overview.table.column.tags" />,
        key: CONTRACT_TABLE_DATA_KEYS.TAGS,
        minWidth: '12%',
      },
      {
        allowsSorting: true,
        children: <FormattedMessage id="account.overview.table.column.status" />,
        key: CONTRACT_TABLE_DATA_KEYS.EXPIRATION_TEXT,
      },
    ],
    [intl]
  );

  const onDownloadUserList = async () => {
    const onError = (error) => {
      showError(
        intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.children',
        })
      );

      log.error('ContractTable: failed to download users csv', error);
    };

    showInfo(
      intl.formatMessage({
        id: 'common.bulkOperation.bulkOperationForm.csvProcessing.alert.info',
      })
    );

    await downloadUserList({
      exportFunction: jilUsers.exportUsers,
      onError,
      orgId: rootStore.organizationStore.activeOrgId,
    });
  };

  return (
    <Table
      aria-label={intl.formatMessage({id: 'account.overview.table.description'})}
      noItemsFoundHeadingMessage={intl.formatMessage({
        id: 'account.overview.table.noItemsFoundHeadingMessage',
      })}
      sortDescriptor={INITIAL_SORT_DESCRIPTOR}
    >
      <TableHeader columns={columns}>
        {(column) => <Column data-testid={`column-${column.key}`} {...column} />}
      </TableHeader>
      <TableBody items={tableSectionState?.items} loadingState={isLoading ? 'loading' : 'idle'}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- inline jsx */}
        {(tableRowData) => {
          const contractDate = tableRowData[CONTRACT_TABLE_DATA_KEYS.DATE];
          const showInfoButton = tableRowData[CONTRACT_TABLE_DATA_KEYS.IS_ETLA_OR_ALLOCATION];
          const showBuyMoreButton = tableRowData[CONTRACT_TABLE_DATA_KEYS.CAN_BUY_MORE];
          const showDownloadButton =
            tableRowData[CONTRACT_TABLE_DATA_KEYS.IS_ETLA_OR_ALLOCATION] &&
            tableRowData[CONTRACT_TABLE_DATA_KEYS.EXPIRATION_PHASE] ===
              CONTRACT_EXPIRATION_PHASE.POST_GRACE;
          const contractDisplayName = tableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_DISPLAY_NAME];
          const contractId = tableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_ID];
          const contractHref = tableRowData[CONTRACT_TABLE_DATA_KEYS.CONTRACT_HREF];
          return (
            <Row key={contractId}>
              <Cell>
                {contractHref ? (
                  <NavigationAnchor data-testid="contract-name-nav-anchor" href={contractHref}>
                    {contractDisplayName}
                  </NavigationAnchor>
                ) : (
                  contractDisplayName
                )}
              </Cell>
              <Cell>
                {showInfoButton && (
                  <TooltipButton
                    hoverText={intl.formatMessage({
                      id: 'account.overview.table.column.actions.info.tooltip',
                    })}
                    isQuiet
                    variant="action"
                  >
                    <InfoOutlineIcon size="S" />
                  </TooltipButton>
                )}
                {showDownloadButton && (
                  <TooltipButton
                    data-testid="download-button"
                    hoverText={intl.formatMessage({
                      id: 'account.overview.table.column.actions.download.tooltip',
                    })}
                    isQuiet
                    onPress={onDownloadUserList}
                    variant="action"
                  >
                    <DownloadIcon size="S" />
                  </TooltipButton>
                )}

                {showBuyMoreButton && <BuyMoreTooltipButton contractId={contractId} />}
              </Cell>
              <Cell>
                {/* Display all products, wrapping if needed */}
                <ProductIconList
                  inlineProductCount={0}
                  products={tableRowData[CONTRACT_TABLE_DATA_KEYS.PRODUCTS]}
                />
              </Cell>
              <Cell>
                {contractDate && (
                  <span>
                    <FormattedDateAndTime
                      data-testid="formatted-contract-date"
                      value={contractDate}
                    />
                  </span>
                )}
              </Cell>
              <Cell data-testid="tags">
                {tableRowData[CONTRACT_TABLE_DATA_KEYS.TAGS]?.length > 0 && (
                  <TagGroup allowsRemoving={false}>
                    {tableRowData[CONTRACT_TABLE_DATA_KEYS.TAGS].map((tag) => (
                      <Item key={tag.id}>{tag.name}</Item>
                    ))}
                  </TagGroup>
                )}
              </Cell>
              <Cell>
                <ContractExpirationStatus
                  contractStatus={tableRowData[CONTRACT_TABLE_DATA_KEYS.STATUS]}
                  expirationPhase={tableRowData[CONTRACT_TABLE_DATA_KEYS.EXPIRATION_PHASE]}
                  isContractEtlaOrAllocation={
                    tableRowData[CONTRACT_TABLE_DATA_KEYS.IS_ETLA_OR_ALLOCATION]
                  }
                />
              </Cell>
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

ContractTable.displayName = 'ContractTable';

ContractTable.propTypes = {
  /**
   * Whether Table should show loading indicator
   */
  isLoading: PropTypes.bool.isRequired,
};

export default ContractTable;
