(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular
    .module('app.widgets.support')
    .constant(
      'REQUEST_EXPERT_SESSION_FETCH_TOPICS_ERROR',
      'modal-request-expert-fetch-topics-error'
    )
    .constant('REQUEST_EXPERT_SESSION_MODAL_ID', 'modal-request-expert-session');
})();
