import {INSIGHTS_SECTION} from '@pandora/data-model-insights';
import React from 'react';

import InsightsWorkspace from '../components/InsightsWorkspace';
import InsightsDashboardPage from '../pages/InsightsDashboardPage';
import InsightsSectionDetailsPage from '../pages/InsightsSectionDetailsPage';
import InsightsSectionPage from '../pages/InsightsSectionPage';

import {
  PATH_INSIGHTS_ROOT,
  PATH_INSIGHTS_SEGMENT_DASHBOARD,
  PATH_INSIGHTS_SEGMENT_DETAILS,
  PATH_INSIGHTS_SEGMENT_LOGS,
  PATH_INSIGHTS_SEGMENT_REPORTS,
} from './insightsPaths';
import {
  dashboardLoader,
  insightsLoader,
  insightsSectionDetailsLoader,
  insightsSectionLoader,
  logsAccessCheck,
  reportsAccessCheck,
} from './insightsRoutesLoaders';

/**
 * Gathers all of the Insights routes
 * @returns {import('react-router-dom').RouteObject[]}
 */
const insightsRoutes = () => {
  const topLevelRoute = {
    children: [
      {
        element: <InsightsDashboardPage />,
        loader: dashboardLoader,
        path: PATH_INSIGHTS_SEGMENT_DASHBOARD,
      },
      {
        children: [
          {
            element: <InsightsSectionPage insightsSection={INSIGHTS_SECTION.LOGS} />,
            index: true,
            loader: insightsSectionLoader(INSIGHTS_SECTION.LOGS),
          },
          {
            element: <InsightsSectionDetailsPage insightsSection={INSIGHTS_SECTION.LOGS} />,
            loader: insightsSectionDetailsLoader(INSIGHTS_SECTION.LOGS),
            path: PATH_INSIGHTS_SEGMENT_DETAILS,
            shouldRevalidate: () => false, // The tabs on the page modify the URL, but we don't need to re-render the route
          },
        ],
        loader: logsAccessCheck,
        path: PATH_INSIGHTS_SEGMENT_LOGS,
      },
      {
        children: [
          {
            element: <InsightsSectionPage insightsSection={INSIGHTS_SECTION.REPORTS} />,
            index: true,
            loader: insightsSectionLoader(INSIGHTS_SECTION.REPORTS),
          },
          {
            element: <InsightsSectionDetailsPage insightsSection={INSIGHTS_SECTION.REPORTS} />,
            loader: insightsSectionDetailsLoader(INSIGHTS_SECTION.REPORTS),
            path: PATH_INSIGHTS_SEGMENT_DETAILS,
            shouldRevalidate: () => false, // The tabs on the page modify the URL, but we don't need to re-render the route
          },
        ],
        loader: reportsAccessCheck,
        path: PATH_INSIGHTS_SEGMENT_REPORTS,
      },
    ],
    element: <InsightsWorkspace />,
    id: 'insights',
    loader: insightsLoader,
    path: PATH_INSIGHTS_ROOT,
  };

  return [topLevelRoute];
};

export default insightsRoutes;
