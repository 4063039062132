import {LICENSE_DEV_ADMIN, LicenseGroupAdminList} from '@admin-tribe/binky';
import {useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {canAssignDeveloper, canRemoveDeveloper} from 'core/products/access/productProfileAccess';

/**
 * The product profile subpage for developers.
 */
const ProfileDevelopersSubpage = observer(({pageContext}) => {
  const adminStore = useStore(
    () =>
      new AdminStore({
        immediateLoad: true,
        listClassRef: LicenseGroupAdminList,
        listOptions: {
          configurationId: pageContext.targetId,
          productId: pageContext.targetParentId,
          roles: [LICENSE_DEV_ADMIN],
        },
      })
  );

  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  return (
    <div data-testid="product-profile-developers-subpage">
      <MemberListContextProvider
        canAddMember={canAssignDeveloper()}
        canRemoveMember={canRemoveDeveloper()}
        getIsItemSelectable={getIsItemSelectable}
        memberListDisplay={{
          email: true,
          idType: true,
        }}
        pageContext={pageContext}
        store={adminStore}
      >
        <AdminListTableSection />
      </MemberListContextProvider>
    </div>
  );
});

export default ProfileDevelopersSubpage;
