import {defineContents} from '@pandora/react-content-provider';
import {ModalDialogModel} from '@pandora/react-modal-dialog';

// A custom content model for the AppIntegrationsManageUsersModal
const AppIntegrationsManageUsersModalContentModel = defineContents(
  'AppIntegrationsManageUsersModal',
  {
    modalDialogContent: {
      id: 'modalDialogContent',
      type: ModalDialogModel,
    },
  }
);

export default AppIntegrationsManageUsersModalContentModel;
