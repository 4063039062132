// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {hasDomain, log} from '@admin-tribe/binky';
import {Button, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import useGlobalModal from 'common/hooks/useGlobalModal';

const HELP_SEARCH_ALLOWLIST_DOMAINS = ['adobe.com', 'frame.io'];

const ActionsSection = ({
  primaryCTAAction,
  primaryCTALabel,
  secondaryCTAAction,
  secondaryCTALabel,
}) => {
  const isValidAdobeUrl = (url) => {
    if (url) {
      if (HELP_SEARCH_ALLOWLIST_DOMAINS.some((domain) => hasDomain(url, domain))) {
        return true;
      }
      log.error(`Invalid non-Adobe domain: ${url}`);
    } else {
      log.error('Sophia card given undefined or empty URL');
    }
    return false;
  };

  const shouldShowButton = (label, url) => label && isValidAdobeUrl(url);
  const primaryCTACallback = getCtaClickEventCallback(primaryCTAAction);
  const secondaryCTACallback = getCtaClickEventCallback(secondaryCTAAction);

  const showPrimaryButton = shouldShowButton(primaryCTALabel, primaryCTAAction);
  const showSecondaryButton = shouldShowButton(secondaryCTALabel, secondaryCTAAction);

  const [modalComponent, setModalComponent] = useState(null);
  const {openModal} = useGlobalModal(modalComponent);

  const onPrimaryActionPress = () => {
    primaryCTACallback?.();
    setModalComponent(primaryCTACallback?.());
    openModal();
  };

  const onSecondaryActionPress = () => {
    secondaryCTACallback?.();
  };

  return (
    <Flex
      alignItems="start"
      data-testid="one-console-actions"
      direction={{base: 'column', S: 'row'}}
      gap="size-200"
    >
      {showSecondaryButton && (
        <Button
          data-testid="one-console-secondary-cta-button"
          height="auto"
          onPress={onSecondaryActionPress}
        >
          {secondaryCTALabel}
        </Button>
      )}
      {showPrimaryButton && (
        <Button
          data-testid="one-console-primary-cta-button"
          height="auto"
          onPress={onPrimaryActionPress}
          variant="accent"
        >
          {primaryCTALabel}
        </Button>
      )}
    </Flex>
  );
};

ActionsSection.propTypes = {
  primaryCTAAction: PropTypes.string,
  primaryCTALabel: PropTypes.string,
  secondaryCTAAction: PropTypes.string,
  secondaryCTALabel: PropTypes.string,
};

export default ActionsSection;
