import {Locale, authentication} from '@admin-tribe/binky';
import axios from 'axios';

let clientId, url;

/**
 * Configure Hendrix API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for the Hendrix api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({clientId, url} = config);
};

const getHeaders = () => {
  const authenticationToken = authentication.getAccessToken();

  const headers = {
    'X-Api-Key': clientId,
  };

  if (authenticationToken?.token) {
    headers.Authorization = `Bearer ${authenticationToken.token}`;
  }

  return headers;
};

/**
 * @description Calls the Hendrix API to download invoices
 *
 * @param {Object} options - object containing information used to download invoices described below
 * @param {String} options.invoices - Invoices to be downloaded separated by
 *    colons (e.g. '0796496697:0797817647:0797817805')
 * @param {String} options.orderId - Order id(externalContractId) of the contract
 *    to be downloaded
 * @param {String} options.rengaId - Customer GUID(orgId)
 * @param {Object} [queryParams] - Query param object with language, api_key, user_token, type(defaults to 'invoice')
 *
 * @returns {Promise} Promise which will resolve with the response data
 */
const getDownloadInvoices = ({invoices, orderId, rengaId, responseType = 'blob', queryParams}) => {
  const language = Locale?.get()?.activeLanguage;
  const params = {...queryParams, language};

  return axios.get(
    `${url}/commerce_services/customers/${rengaId}/invoice/${orderId}/download/${invoices}`,
    {
      headers: getHeaders(),
      params,
      responseType,
    }
  );
};

/**
 * @description Calls the Hendix API for emailing invoices
 *
 * @param {Object} options - object containing information used to email invoices described below
 * @param {String[]} [options.emailList] - A list of emails to send a copy of the invoices. Defaults to an empty list.
 * @param {Boolean} [options.includePrimaryAdmin] - True if the admin wants a emailed copy of the invoices. Defaults to not sending a copy.
 * @param {String[]} options.invoiceList - A list of invoices to send by id.
 * @param {String} options.orderId - Order id(externalContractId) of the contract
 * @param {String} options.rengaId - Customer GUID(orgId)
 * @param {String} options.type - Can either by INVOICE, RECEIPT or CREDIT (cannot mix the 3 types of invoices in the same call)
 *
 * @returns {Promise} A promise that resolves with the hendrix email invoice response
 */
const postEmailInvoices = async ({
  emailList = [],
  includePrimaryAdmin,
  invoiceList,
  orderId,
  rengaId,
  type,
}) => {
  const headers = {'Content-Type': 'application/json', ...getHeaders()};

  const profile = await authentication?.getProfile();

  return axios.post(
    `${url}/products_support/customers/${rengaId}/orders/${orderId}/notifications`,
    {
      addlRcptEmails: emailList,
      customerLanguage: profile?.preferred_languages?.[0], // Optional param. API supports one language at a time.
      excludePrimaryAdmin: !includePrimaryAdmin,
      invoiceList,
      type,
    },
    {headers}
  );
};

const hendrix = {configure, getDownloadInvoices, postEmailInvoices};

export default hendrix;
