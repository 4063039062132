const COLUMN_KEY = {
  EMAIL: 'email',
  NAME: 'name',
  SOURCE_PRODUCT: 'sourceProduct',
  TARGET_PRODUCT: 'targetProduct',
};

// Use this constant to identify that the user selects "No assignment"
const NO_ASSIGNMENT_ID = 'NO_ASSIGNMENT';

const SORT_DIRECTION_ASCENDING = 'ascending';

export {COLUMN_KEY, NO_ASSIGNMENT_ID, SORT_DIRECTION_ASCENDING};
