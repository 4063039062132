import {feature, log, modelCache} from '@admin-tribe/binky';
import uniqBy from 'lodash/uniqBy';

import {getUserSpecificAdobeProducts} from 'features/packages/api/tronData';
import PackagesAdobeProductEntity from 'features/packages/entities/PackagesAdobeProductEntity';

import {ADOBE_PRODUCTS} from '../packagesConstants';

import PackagesEntitlementsService from './PackagesEntitlementsService';

class PackagesAdobeProductsService {
  /**
   * @description Method to fetch and process all Adobe Products
   * @throws {Error} if the api call getUserSpecificAdobeProducts fails
   */
  static async fetchAndProcessAdobeProducts() {
    const cachedAdobeProducts = await modelCache.get(ADOBE_PRODUCTS, 'ADOBE_PRODUCTS');
    if (cachedAdobeProducts) {
      return;
    }
    let response;
    this.adobeProductsMap = {};
    this.loggedMissingProductKeys = new Set();

    try {
      const queryParams = {isEnterprise: PackagesEntitlementsService.isEnterpriseOrg};
      response = await getUserSpecificAdobeProducts(queryParams);
    } catch (error) {
      log.error(
        'PackagesAdobeProducts.fetchAndProcessAdobeProducts(): Unable to retrieve model from back-end. Error: ',
        error
      );
      throw error;
    }
    const products = response.data;
    products.forEach((product) => {
      const adobeProductObject = new PackagesAdobeProductEntity(product);
      this.adobeProductsMap[`${product.sapCode}/${product.version}`] = adobeProductObject;
      this.adobeProductsMap[product.product_key] = adobeProductObject;
    });
    modelCache.put(ADOBE_PRODUCTS, 'ADOBE_PRODUCTS', true);
  }

  /**
   * @description Method to get Adobe Product by 'sapCode/version'.
   *
   * @param {String} sapCodeVersionKey 'sapCode/version' for AdobeProduct
   * @param {Boolean} logError If error should be logged if not found. Default true.
   * @returns {PackagesAdobeProductEntity} Reference to corresponding AdobeProduct object if found, else null.
   */
  static getAdobeProductBySapCodeVersion(sapCodeVersionKey, logError = true) {
    if (this.adobeProductsMap[sapCodeVersionKey]) {
      return this.adobeProductsMap[sapCodeVersionKey];
    }
    if (logError && !this.loggedMissingProductKeys.has(sapCodeVersionKey)) {
      this.loggedMissingProductKeys.add(sapCodeVersionKey);
      log.info(
        'PackagesAdobeProducts.getAdobeProductBySapCodeVersion(): Data not found for: ',
        sapCodeVersionKey
      );
    }
    return null;
  }

  /**
   * @description Method to get Adobe Product by 'sapCode/version/platform' key.
   *
   * @param {String} sapCodeVersionPlatformKey 'sapCode/version/platform' for AdobeProduct
   * @returns {PackagesAdobeProductEntity} Reference to corresponding AdobeProduct object if found, else null.
   */
  static getAdobeProductBySapCodeVersionPlatform(sapCodeVersionPlatformKey) {
    if (this.adobeProductsMap[sapCodeVersionPlatformKey]) {
      return this.adobeProductsMap[sapCodeVersionPlatformKey];
    }
    if (
      sapCodeVersionPlatformKey.endsWith('osx10') &&
      this.adobeProductsMap[`${sapCodeVersionPlatformKey}-64`]
    ) {
      return this.adobeProductsMap[`${sapCodeVersionPlatformKey}-64`];
    }
    if (sapCodeVersionPlatformKey.endsWith('osx10-64')) {
      // osx10subString would contain the 'sapCode/version/osx10', which is the product_key of required product
      const osx10subString = sapCodeVersionPlatformKey.slice(
        0,
        sapCodeVersionPlatformKey.length - 3
      );
      if (this.adobeProductsMap[osx10subString]) {
        return this.adobeProductsMap[osx10subString];
      }
    }
    if (!this.loggedMissingProductKeys.has(sapCodeVersionPlatformKey)) {
      this.loggedMissingProductKeys.add(sapCodeVersionPlatformKey);
      log.info(
        'PackagesAdobeProducts.getAdobeProductBySapCodeVersionPlatform(): Data not found for: ',
        sapCodeVersionPlatformKey
      );
    }
    return null;
  }

  /**
   * @description Method to get latest adobe product for a given product
   *
   * @param {AdobeProduct} product given AdobeProduct object
   * @param {String} platform Package platform context
   * @returns {PackagesAdobeProductEntity} Reference to corresponding latest AdobeProduct object if found, else null.
   */
  static getLatestAdobeProduct(product) {
    if (product.isUpToDate) {
      return product;
    }
    return PackagesAdobeProductsService.getAdobeProductBySapCodeVersionPlatform(
      product.getLatestProductKey()
    );
  }

  /**
   * @description Method to get latest Adobe Product by its sapCode. Only use this method if
   *              it's really needed, as it's computationally expensive if called frequently.
   *
   * @param {String} sapCode sapCode for AdobeProduct
   * @param {Boolean} logError If error should be logged if not found. Default true.
   * @returns {PackagesAdobeProductEntity} Reference to corresponding latest AdobeProduct object if found, else null.
   */
  static getLatestAdobeProductBySapCode(sapCode, logError = true) {
    const latestProductForSapCode = Object.values(this.adobeProductsMap).find(
      (product) => product.sapCode === sapCode && product.isUpToDate
    );
    if (latestProductForSapCode) {
      return latestProductForSapCode;
    }
    if (logError && !this.loggedMissingProductKeys.has(sapCode)) {
      this.loggedMissingProductKeys.add(sapCode);
      log.error(
        'PackagesAdobeProducts.getLatestAdobeProductBySapCode(): Data not found for: ',
        sapCode
      );
    }
    return null;
  }

  /**
   * @description Method to get list of packageable adobe products by platform
   *
   * @param {String} platform Package platform context
   * @returns {Array} list of packageable adobe products by platform
   */
  static getPackageableProductsByPlatform(platform) {
    return uniqBy(
      Object.values(this.adobeProductsMap).filter((product) =>
        isProductPackageable(product, platform)
      ),
      'product_key'
    );
  }
}

function isProductPackageable(product, platform) {
  return (
    product.isPackageable &&
    product.platformsSupported.includes(platform.toUpperCase()) &&
    isProductVersionSupported(product)
  );
}

function isProductVersionSupported(product) {
  // Show latest version of PSE and PRE. If temp_packages_show_old_PSE_PRE flag is disabled
  // For other sap codes, show all isPackageable versions

  return product.sapCode === 'PSE' || product.sapCode === 'PRE'
    ? feature.isEnabled('temp_packages_show_old_PSE_PRE') ||
        product.version === product.latestProductVersion
    : true;
}

export default PackagesAdobeProductsService;
