import {Content, ContextualHelp, Heading} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

/**
 * Contextual help to be displayed when an action is disabled by global admin policy
 */
const GlobalAdminPolicyActionDisabled = () => (
  <ContextualHelp>
    <Heading>
      <FormattedMessage id="settings.contextualHelp.actionDisabled" />
    </Heading>
    <Content>
      <FormattedMessage id="settings.contextualHelp.contactGlobalAdmin" />
    </Content>
  </ContextualHelp>
);

export default GlobalAdminPolicyActionDisabled;
