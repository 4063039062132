import {Button, DialogContainer, Heading} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import RemoveSyncModal from 'features/settings/components/remove-sync-modal/RemoveSyncModal';

const RemoveSyncSection = ({clearCaches, onSyncRemoved}) => {
  const [isRemoveSyncDialogOpened, toggleRemoveSyncDialog] = useState(false);

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.sync.syncSettingsModal.removeSync.title" />
      </Heading>

      <p>
        <FormattedMessage id="settings.sync.syncSettingsModal.removeSync.description" />
      </p>

      <Button
        data-test-id="remove-sync-btn"
        onPress={() => toggleRemoveSyncDialog(true)}
        variant="secondary"
      >
        <FormattedMessage id="settings.sync.syncSettingsModal.removeSync.button" />
      </Button>

      <DialogContainer onDismiss={() => toggleRemoveSyncDialog(false)}>
        {isRemoveSyncDialogOpened && (
          <RemoveSyncModal clearCaches={clearCaches} onSyncRemoved={onSyncRemoved} />
        )}
      </DialogContainer>
    </>
  );
};

RemoveSyncSection.propTypes = {
  clearCaches: PropTypes.func,
  onSyncRemoved: PropTypes.func,
};

export default RemoveSyncSection;
