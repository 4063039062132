(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name licenseDeficitReportTableRow
   * @description Component which displays a table row for a license deficit
   *   report table
   *
   * @binding {Object} licenseDeficit - license deficit data to display
   */
  angular
    .module('app.widgets.bulk-operations')
    .directive('appLicenseDeficitReportTableRow', licenseDeficitReportTableRow);

  /* @ngInject */
  function licenseDeficitReportTableRow() {
    const directive = {
      restrict: 'A',
      scope: {
        licenseDeficit: '<',
      },
      templateUrl:
        'app/widgets/bulk-operations/jobs/license-deficit-report-table/license-deficit-report-table-row.directive.html',
    };

    return directive;
  }
})();
