(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name UserGroup
   * @description Model for a UserGroup
   */
  angular.module('app.core.user-group').factory('UserGroup', getUserGroupModel);

  /* @ngInject */
  function getUserGroupModel($q, _, binkySrc2, OrganizationManager) {
    const DEFAULT_TYPE = 'USER_GROUP';

    class UserGroupSrc2 extends binkySrc2.services.users.UserGroup {
      constructor(options = {}) {
        super(_.assign(options, {orgId: OrganizationManager.getActiveOrgId()}));
      }

      /**
       * @description Method to delete the current model.
       *
       * @return {Promise<UserGroup>} resolves on success, else rejects
       *                   with error message
       */
      delete() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .delete()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to refresh the current model state against what is
       *              stored in the back-end.
       *
       * @return {Promise<UserGroup>} resolves to refreshed UserGroup model, else rejects
       *                   with error message
       */
      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * Method to save the current model state to the back-end.
       *
       * @return {Promise<UserGroup>} resolves to saved UserGroup model, else rejects with
       *                   error message
       */
      save() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .save()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error.response))
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to construct a UserGroup from a data Object
       *              containing user group information.
       *
       * @param {Object} responseData containing user group data
       * @returns {UserGroup} Reference to UserGroup
       */
      static apiResponseTransformer(responseData) {
        return new UserGroupSrc2(
          _.assign(responseData, {orgId: OrganizationManager.getActiveOrgId()})
        );
      }

      static canTransform(data) {
        return _.get(data, 'type') === DEFAULT_TYPE;
      }

      /**
       * @description Method to retrieve an existing UserGroup from back-end data
       *              store.
       *
       * @param {number} userGroupId - ID of the user to retrieve for this org
       * @returns {UserGroup} the user group
       */
      static get(userGroupId) {
        const model = new UserGroupSrc2({
          id: userGroupId,
          orgId: OrganizationManager.getActiveOrgId(),
        });
        model.refresh();
        return model;
      }
    }

    return UserGroupSrc2;
  }
})();
