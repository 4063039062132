import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {ALL_PACKAGES_CONSTANTS} from 'features/packages/packagesConstants';
import {goToAllPackages} from 'features/packages/routing/navigation-callbacks/navigationCallbacks';
import PackagesAdminService from 'features/packages/services/PackagesAdminService';
import PackagesAdobeProductsService from 'features/packages/services/PackagesAdobeProductsService';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to set up Feature Restricted Licensing (FRL)
 */
class SetUpFrlHva extends HvaCard {
  /**
   * @async
   *
   * @description Method to fetch the SetUpFrlHva
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @returns {Promise<SetUpFrlHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (
      !auth.isUserOrgAdmin() ||
      !rootStore.organizationStore.productList?.items ||
      !rootStore.organizationStore.productList.items.some((item) =>
        item.isFeatureRestrictedLicense()
      )
    ) {
      return Promise.reject();
    }
    PackagesAdobeProductsService.fetchAndProcessAdobeProducts();
    await PackagesAdminService.fetchAndProcessAdminPackages();
    const allAdminPackages = PackagesAdminService.getAllAdminPackages();
    if (
      allAdminPackages.every(
        (adminPackage) =>
          !adminPackage.isFrlOrNuellPackage ||
          adminPackage.statusState !== ALL_PACKAGES_CONSTANTS.STATE_UP_TO_DATE
      )
    ) {
      return Promise.resolve([new SetUpFrlHva({intl})]);
    }
    return Promise.reject();
  }

  /**
   * @description Method to create an SetUpFrlHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @return {SetUpFrlHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.setUpFRL';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      goUrl: 'aac_frl_packages_learn',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/create-packages.svg',
        style: {width: '40px'},
      },
      id: 'hva-set-up-frl',
      onCTA: goToAllPackages,
      priorityOrder: HVA_ORDER.SET_UP_FRL,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}
export default SetUpFrlHva;
