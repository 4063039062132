import {Contract, ProductList} from '@admin-tribe/binky';
import {View} from '@adobe/react-spectrum';
// Using Accordion v2, since v3 has blocking issue https://github.com/adobe/react-spectrum/issues/1989
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';

import {isProductCancellable} from '../../SelfCancelUtils';

import styles from './SeatSelector.pcss';
import SeatSelectorItemBody from './SeatSelectorItemBody';
import SeatSelectorItemHeader from './SeatSelectorItemHeader';

/**
 * Component used to allow the user to select seat to cancel from a cancellable product list.
 */
const SeatSelector = ({
  'aria-labelledby': ariaLabelledBy,
  contract,
  isLoading = false,
  selectedSeats = {},
  onAllLicensesSelectedChange,
  onSelectedSeatsChange,
  productList,
}) => {
  // Convert selected seats for a product into a hash map and fire onSelectedSeatsChange
  const handleSelectedSeatsChange = (productId, newSelection) => {
    const {[productId]: replacedArray, ...selectedSeatsWithoutProductId} = selectedSeats;
    const newSelectedSeats =
      newSelection.length > 0
        ? {
            ...selectedSeatsWithoutProductId,
            [productId]: newSelection,
          }
        : selectedSeatsWithoutProductId;
    onSelectedSeatsChange(newSelectedSeats);
  };

  // Filter the list of products to include only the cancellable ones
  const cancellableProducts = productList.items.filter(isProductCancellable);

  // Check if only there is one product and store reference
  const oneProduct = cancellableProducts.length === 1 && cancellableProducts[0];

  return (
    <View backgroundColor="static-white" borderRadius="regular" marginTop="size-300">
      {oneProduct ? (
        <View
          borderColor="gray-300"
          borderRadius="regular"
          borderWidth="thin"
          paddingBottom="size-250"
          paddingTop="size-350"
          paddingX="size-500"
        >
          <SeatSelectorItemHeader
            contract={contract}
            isLoading={isLoading}
            product={oneProduct}
            selectedLicenses={selectedSeats[oneProduct.id]}
          />
          <View paddingTop="size-125">
            <SeatSelectorItemBody
              contract={contract}
              onAllLicensesSelectedChange={onAllLicensesSelectedChange}
              onSelectedSeatsChange={handleSelectedSeatsChange}
              product={oneProduct}
              selectedLicenses={selectedSeats[oneProduct.id]}
            />
          </View>
        </View>
      ) : (
        <SpectrumV2Provider className={styles.accordion}>
          <Accordion aria-labelledby={ariaLabelledBy} ariaLevel={3}>
            {cancellableProducts.map((product) => (
              <AccordionItem
                key={product.id}
                header={
                  <SeatSelectorItemHeader
                    contract={contract}
                    isLoading={isLoading}
                    product={product}
                    selectedLicenses={selectedSeats[product.id]}
                  />
                }
              >
                <View paddingBottom="size-350" paddingEnd="size-500" paddingStart="68px">
                  <SeatSelectorItemBody
                    contract={contract}
                    onAllLicensesSelectedChange={onAllLicensesSelectedChange}
                    onSelectedSeatsChange={handleSelectedSeatsChange}
                    paddingStart="68px"
                    product={product}
                    selectedLicenses={selectedSeats[product.id]}
                  />
                </View>
              </AccordionItem>
            ))}
          </Accordion>
        </SpectrumV2Provider>
      )}
    </View>
  );
};

SeatSelector.propTypes = {
  /**
   * Id of the label for the Accordion.
   */
  'aria-labelledby': PropTypes.string.isRequired,
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Whether the seat selector header should display the loading indicator or not
   */
  isLoading: PropTypes.bool,
  /**
   * Handler that is called when the number of selected licenses changes in order
   * to determine if all licenses are selected.
   */
  onAllLicensesSelectedChange: PropTypes.func,
  /**
   * Handler that is called when the selected licenses changes.
   */
  onSelectedSeatsChange: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

export default SeatSelector;
