import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {PropTypes} from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const CancelJitSetupWarningModal = ({onConfirm}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({id: 'settings.cancelJitSetupWarningModal.buttons.cancel'})}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.cancelJitSetupWarningModal.buttons.confirm',
      })}
      title={intl.formatMessage({id: 'settings.cancelJitSetupWarningModal.title'})}
      variant="confirmation"
    >
      <Text>
        <FormattedMessage id="settings.cancelJitSetupWarningModal.autoAccountCreationDefaultDescription" />
      </Text>
      <Text>
        <FormattedMessage id="settings.cancelJitSetupWarningModal.autoAccountCreationCancelWarning" />
      </Text>
    </ModalAlertDialog>
  );
};

CancelJitSetupWarningModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default CancelJitSetupWarningModal;
