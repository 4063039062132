import {StatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {DIRECTORY_CERTIFICATE_STATUSES, DIRECTORY_STATUSES} from 'common/entities/DirectoryEntity';

import styles from './DirectoryStatus.pcss';

const DIRECTORY_STATUS = {
  [DIRECTORY_STATUSES.ACTIVE]: {
    label: 'settings.directory.status.ACTIVE',
    variant: 'positive',
  },
  [DIRECTORY_STATUSES.NEEDS_DOMAIN]: {
    label: 'settings.directory.status.NEEDS_DOMAIN',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.PENDING]: {
    label: 'settings.directory.status.PENDING',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.REJECTED]: {
    label: 'settings.directory.status.REJECTED',
    variant: 'negative',
  },
  [DIRECTORY_STATUSES.REQUIRES_ACTIVATION]: {
    label: 'settings.directory.status.REQUIRES_ACTIVATION',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.REQUIRES_APPROVAL]: {
    label: 'settings.directory.status.REQUIRES_APPROVAL',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.REQUIRES_CONFIGURATION]: {
    label: 'settings.directory.status.REQUIRES_CONFIGURATION',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.REVOKED]: {
    label: 'settings.directory.status.REVOKED',
    variant: 'notice',
  },
  [DIRECTORY_STATUSES.TRUSTED]: {
    label: 'settings.directory.status.TRUSTED',
    variant: 'positive',
  },
  [DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRED]: {
    label: 'settings.directory.status.CERTIFICATE_EXPIRED',
    variant: 'negative',
  },
  [DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRING]: {
    label: 'settings.directory.status.CERTIFICATE_EXPIRING',
    variant: 'notice',
  },
  UNKNOWN: {
    label: 'settings.directory.status.UNKNOWN',
    variant: 'neutral',
  },
};

const DirectoryStatus = ({ownershipStatus, status}) => {
  let statusLightConfig;

  if (ownershipStatus === DIRECTORY_STATUSES.TRUSTED && status === DIRECTORY_STATUSES.ACTIVE) {
    statusLightConfig = DIRECTORY_STATUS[ownershipStatus];
  } else {
    statusLightConfig = DIRECTORY_STATUS[status] || DIRECTORY_STATUS.UNKNOWN;
  }

  return (
    <StatusLight
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed more than provided style props
      UNSAFE_className={styles['directory-status-light']}
      variant={statusLightConfig.variant}
    >
      <FormattedMessage id={statusLightConfig.label} />
    </StatusLight>
  );
};

DirectoryStatus.propTypes = {
  ownershipStatus: PropTypes.string,
  status: PropTypes.string,
};

export default DirectoryStatus;
export {DIRECTORY_STATUS};
