import {
  DetailsButton,
  ImageIcon,
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import jilTeams from 'common/api/jil/jilTeams';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import Team from 'common/services/team/Team';
import rootStore from 'core/RootStore';
import {getProductTags} from 'core/products/utils/productTagUtils';
import ProductBanners from 'features/products/components/banners/ProductBanners';
import ProductDetailsDrawer from 'features/products/components/product-details-drawer/ProductDetailsDrawer';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';

import LegacyDevicesInfoBar from './LegacyDevicesInfoBar';
import LegacyDevicesSubpage from './LegacyDevicesSubpage';
import LegacyDevicesTabs from './LegacyDevicesTabs';

/**
 * Product page for DBL (device based licenses, formerly LDL).
 * Url: /products/productId/devices and /products/productId/payment-needed-devices
 */
const LegacyDevicesPage = () => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const tabHeading = intl.formatMessage({id: 'products.details.devices.legacyDevices.tabHeading'});
  const {productId} = useParams();
  const product = useMemo(
    () => rootStore.organizationStore.productList.items.find((p) => p.id === productId),
    [productId]
  );
  const productTags = useMemo(() => getProductTags(intl, product), [intl, product]);
  const productDisplayName = getProductDisplayName(intl, product);
  useDocumentTitle({defaultTitle: productDisplayName});

  const fetchPackagePools = useCallback(
    () => jilTeams.getDevicePackagePoolsResource({orgId}),
    [orgId]
  );

  const {
    error: packagePoolsError,
    isLoading: isPackagePoolsLoading,
    model: packagePool,
  } = useAsyncModel({
    loadFn: fetchPackagePools,
  });

  const fetchDeviceList = useCallback(() => {
    if (packagePool && packagePool.data) {
      const {poolId} = packagePool.data.packagePools[0];
      const teamObj = new Team();
      return teamObj.devicePackagePoolResource(orgId, poolId);
    }
    return undefined;
  }, [orgId, packagePool]);

  const {
    error: deviceListError,
    isLoading: isDeviceListLoading,
    model: deviceList,
    updateModel,
  } = useAsyncModel({
    loadFn: fetchDeviceList,
  });
  const updateTableChange = useCallback(
    () => updateModel(fetchDeviceList),
    [updateModel, fetchDeviceList]
  );

  const getTabContent = useCallback(
    (tabType, activeTab) => ({
      content: packagePool?.data ? (
        <LegacyDevicesSubpage
          activeTab={activeTab}
          deviceList={deviceList?.data?.devices}
          deviceListError={deviceListError}
          isDeviceListLoading={isDeviceListLoading}
          orgId={orgId}
          poolId={packagePool.data.packagePools.find((item) => item.poolId).poolId}
          productId={productId}
          updateTableChange={updateTableChange}
        />
      ) : (
        ''
      ),
      key: tabType,
      name: intl.formatMessage({id: `products.details.devices.legacyDevices.${tabType}`}),
      pathname: `/${orgId}/products/${productId}/${activeTab}`,
    }),
    [
      intl,
      orgId,
      packagePool,
      productId,
      deviceList,
      deviceListError,
      isDeviceListLoading,
      updateTableChange,
    ]
  );
  const tabs = useMemo(
    () => [
      getTabContent('allDevices', 'devices'),
      getTabContent('paymentNeeded', 'payment-needed-devices'),
    ],
    [getTabContent]
  );

  return (
    <Page
      data-testid="legacy-devices-page"
      isBumpered={!!packagePoolsError}
      isLoading={isPackagePoolsLoading}
    >
      <ProductPageBreadcrumbs
        productDisplayName={productDisplayName}
        productOrProductGroupId={product.id}
      />
      <PageBanners>
        <ProductBanners deviceData={deviceList} product={product} />
      </PageBanners>
      <PageHeader title={productDisplayName}>
        <ImageIcon alt="" size="L" src={product.getIcon()} />
        <PageHeaderTagGroup productName={productDisplayName} tags={productTags} />
      </PageHeader>
      <PageActions>
        <DetailsButton detailsDrawer={<ProductDetailsDrawer product={product} />} />
      </PageActions>
      <PageInfoBar>
        <LegacyDevicesInfoBar
          activated={
            deviceList?.data?.devices.length || packagePool?.data?.summary?.activation?.activated
          }
          entitled={packagePool?.data?.summary?.payment?.entitled}
        />
      </PageInfoBar>
      <PageContent>
        <LegacyDevicesTabs aria-label={tabHeading} tabs={tabs} />
      </PageContent>
    </Page>
  );
};

export default LegacyDevicesPage;
