(function () {
  /**
   * @deprecated Please use src2/app/core/services/product/product-group/product-list/FiProductGroupProductList.js
   *
   * @ngdoc factory
   * @name FiProductGroupProductList
   * @description Model for an individual FIProductGroupProductList which is a product group based on a
   *  fulfillableItem which is common across the products.
   */
  angular
    .module('app.core.product.product-group-product-list')
    .factory('FiProductGroupProductList', getFiProductGroupProductListModel);

  /* @ngInject */
  function getFiProductGroupProductListModel(
    $translate,
    _,
    binkyProductNameLabelFilter,
    FULFILLABLE_ITEM_CODE,
    ProductGroupProductList
  ) {
    class FiProductGroupProductList extends ProductGroupProductList {
      /**
       * @class
       * @description Constructor for ProductGroupProductList model Objects.
       *   This assumes there is at least one item and they are all the same org type, ie direct, indirect, etc.
       * @param {Object} options - options object
       * @param {String} options.items - The products in this group.
       * @param {String} options.fiCode - The fulfillable item code for this group of fulfillable items.
       */
      constructor(options) {
        const fulfillableItem = findFulfillableItem(options.items, options.fiCode);

        super({
          assets: _.get(fulfillableItem, 'assets'),
          id: options.fiCode, // unique id for this productGroup which will be used as the cache key
          isConsumable: true,
          items: options.items,
          links: _.get(fulfillableItem, 'links'),
          longName: _.get(fulfillableItem, 'longName'),
        });

        this.longDescription = _.get(fulfillableItem, 'longDescription');
      }

      // Returns the first matching fi.
      findFulfillableItem() {
        return findFulfillableItem(this.items, this.id);
      }

      // This is temporary until OC supports purchasing credit packs for direct orgs.
      // Return structure with href and text properties.
      getBuyMoreLink() {
        if (this.isStockCredit()) {
          const text = $translate.instant('core.product.fiProductGroupProductList.buyMore');
          return {href: 'https://stock.adobe.com/teams', text};
        }
        return undefined;
      }

      // Return the name for the individual product in the group with the matching offerId.
      // There should always be a match but if not, fallback to the name for the "grouped" product.
      getDisplayNameForOfferId(offerId) {
        const product = _.find(this.items, {offerId});
        return binkyProductNameLabelFilter(product ? product : this);
      }

      getLongDescription() {
        return this.longDescription;
      }

      isStockCredit() {
        return this.id === FULFILLABLE_ITEM_CODE.STOCK_CREDIT;
      }

      isTeamDirect() {
        return this.items[0].isTeamDirect();
      }

      isTeamIndirect() {
        return this.items[0].isTeamIndirect();
      }
    }

    ////////

    function findFulfillableItem(items, id) {
      return _(items).flatMap('fulfillableItemList.items').find(['code', id]);
    }

    return FiProductGroupProductList;
  }
})();
