/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains2:appDeleteSyncedDirectoryWarningModal
 *
 * @description The modal to show errors from the Delete Directory operation.
 */
(function () {
  angular.module('app.widgets.directories').component('appDeleteSyncedDirectoryWarningModal', {
    controller,
    templateUrl:
      'app/widgets/directories/delete-synced-directory-warning-modal/delete-synced-directory-warning-modal.component.html',
  });

  /* @ngInject */
  function controller(_, DELETE_SYNCED_DIRECTORY_WARNING_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      DELETE_SYNCED_DIRECTORY_WARNING_MODAL_ID,
      onModalOpen,
    });

    function onModalOpen(params) {
      vm.selectedDirectoriesCount = params.selectedDirectoriesCount; // number of selected directories
      vm.syncedDirectories = params.syncedDirectories; // directories that have sync enabled
    }
  }
})();
