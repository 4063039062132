import {DirectoryUser, log} from '@admin-tribe/binky';
import {
  DETAIL_SECTION_VARIANT_CONSTANTS,
  DetailSection,
  DetailSectionMessage,
} from '@admin-tribe/binky-ui';
import {Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * The Directory user details section used in the drawer or the details page for a Directory user.
 */
const DirectoryUserMembershipSection = ({directoryId, headingLevel, isPageVariant, memberId}) => {
  const intl = useIntl();

  const orgId = rootStore.organizationStore.activeOrgId;

  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Get the Directory user by userId.
  // While waiting it will show the loading spinner.
  useEffect(() => {
    let isMounted = true;

    const getDirectoryUser = async () => {
      try {
        const response = await DirectoryUser.get({
          directoryId,
          orgId,
          userId: memberId,
        });

        if (isMounted) {
          setOrganizations(response.organizations);
        }
      } catch (error) {
        if (isMounted) {
          log.error(error);
          setHasError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    getDirectoryUser();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on mount
  }, []);

  return (
    <DetailSection
      headingLevel={headingLevel}
      isLoading={isLoading}
      showError={hasError}
      title={intl.formatMessage({
        id: 'users.directoryUsersDrawer.organizationMembershipSection.title',
      })}
      variant={isPageVariant && DETAIL_SECTION_VARIANT_CONSTANTS.PAGE}
    >
      {!isLoading &&
        !hasError &&
        (organizations?.length > 0 ? (
          organizations.map((organization) => (
            <View key={organization.id}>
              <Text>{organization.name}</Text>
            </View>
          ))
        ) : (
          <DetailSectionMessage messageKey="users.directoryUsersDrawer.organizationMembershipSection.noOrganizations" />
        ))}
    </DetailSection>
  );
};

DirectoryUserMembershipSection.propTypes = {
  /**
   * The id of the directory.
   */
  directoryId: PropTypes.string.isRequired,
  /**
   * The heading level for the DetailSection heading. The DetailSection default in binky-ui is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * True if the section is being used on a Page. False if the section is being used in a Drawer.
   * The DetailSection default is false.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The id of the directory user.
   */
  memberId: PropTypes.string.isRequired,
};

export default DirectoryUserMembershipSection;
