(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @description a filter to display the agent assignee's name for a support ticket
   * @param {Object} supportTicket the support ticket whose assigned agent name should be returned
   * @returns {String} agent name, or "Not yet assigned" if the ticket is unassigned
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportTicketAgentAssignedName', appSupportTicketAgentAssignedNameFilter);

  /* @ngInject */
  function appSupportTicketAgentAssignedNameFilter($translate, appSupportTicketPersonNameFilter) {
    return getAgentAssignedDisplayName;

    function getAgentAssignedDisplayName(supportTicket) {
      if (supportTicket.isAgentAssigned()) {
        return appSupportTicketPersonNameFilter(supportTicket.agentAssigned);
      }
      return $translate.instant(
        'widgets.support.supportTicketAgentAssignedNameFilter.notYetAssigned'
      );
    }
  }
})();
