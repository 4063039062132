(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.contracts:appRenewalDetailSection
   * @description renders a renewal details section for the account page
   * @param {Contract} contract the contract that we are showing renewal details for
   * @example <app-renewal-detail-section
   *           contract="$ctrl.contract"
   *           product-list="$ctrl.productList">
   *          </app-renewal-detail-section>
   */
  angular.module('app.widgets.contracts').component('appRenewalDetailSection', {
    bindings: {
      cart: '<?',
      contract: '<',
      productList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/contracts/renewal-detail-section/renewal-detail-section.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $rootScope,
    $state,
    $translate,
    _,
    AnalyticsHelper,
    auth,
    AuthenticatedUser,
    autoRenewalHelper,
    binkySrc2,
    binkyDateTimeFilter,
    CART_EVENT,
    ContactSales,
    contractAccess,
    contractUtils,
    CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    onesieSrc2,
    panelManager,
    RENEW_PRODUCTS_MODAL2_ID,
    RENEW_PRODUCTS_MODAL2_STATES,
    translationUtils
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      allLicensesRenewed: true, // default to true since by default it auto renews all the licenses
      feature,
      hasAnniversaryDate: !!vm.contract.getAnniversaryDate(),
      hasAutoRenewalOff: isAutoRenewalOptOut(),
      openPOEntryModal,
      openRenewProductsModal,
      RENEW_PRODUCTS_MODAL2_STATES,
      showRenewalPeriod: false,
      showRenewalWorkflow: false, // true if in the renewal window and renewal data is available from Cart api
      waitPromise: undefined,
    });

    const deregisterHandles = [];
    if (!vm.hasAutoRenewalOff) {
      deregisterHandles.push(
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(CART_EVENT.RENEWAL_ORDER_REFRESHED, onFetchRenewalOrderSuccess),
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(CART_EVENT.SUBMIT_RENEWAL_ORDER, onFetchRenewalOrderSuccess)
      );
    }

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    function $onInit() {
      setupAnniversaryDate();

      if (vm.hasAutoRenewalOff) {
        setupAutoRenewOptOutRenewalSection();
        vm.waitPromise = $q.resolve();
      } else {
        showRenewalDetailSection();
      }
    }

    function openPOEntryModal() {
      $state.go('account.account.renew-products', {
        contractId: vm.contract.id,
        step: RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER,
      });
    }

    function openRenewProductsModal(landingState) {
      const buttonAction =
        landingState === RENEW_PRODUCTS_MODAL2_STATES.MODIFY_RENEWAL_ORDER
          ? 'updatePlan'
          : 'viewDetails';

      if (feature.isEnabled('temp_analytics_cart_src2')) {
        const ANALYTICS_CONSTANTS = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
        const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;

        dispatchUiEventAnalytics({
          eventAction: ANALYTICS_CONSTANTS.EVENT_ACTION.CLICK,
          eventName: buttonAction,
        });
      } else {
        AnalyticsHelper.dispatchUiEventAnalytics({
          eventAction: 'click',
          eventName: buttonAction,
        });
      }

      if (canViewUnassignedLicensesSummaryModal()) {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.account.components.UnassignedLicensesSummaryModal,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                isOpen: true,
                onOpenAssignLicensesModal: feature.isEnabled('bug_fix_sqa_workaround')
                  ? () =>
                      $state.go('overview.overview2.quick-assign-products', {
                        contractId: vm.contract.id,
                      })
                  : undefined,
                onOpenRenewalFlow: () =>
                  $state.go('account.account.renew-products', {
                    contractId: vm.contract.id,
                    step: landingState || RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER,
                  }),
                productList: vm.productList,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        $state.go('account.account.renew-products', {
          contractId: vm.contract.id,
          step: landingState || RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER,
        });
      }
    }

    ////////////

    /**
     * @description check if a given user can view the Unassigned Licenses Summary Modal
     * @returns {boolean} true: user meets requirements to view Unassigned Licenses Summary Modal
     * false: user does not meet requirements to and cannot view Unassigned Licenses Summary Modal
     */
    function canViewUnassignedLicensesSummaryModal() {
      if (!vm.contract.isDirectContract() || !auth.isUserOrgAdmin()) {
        return false;
      }

      const assignableLicenses = vm.productList.getSeatBasedAssignableLicenseCount();
      const provisionedLicenses = vm.productList.getSeatBasedTotalProvisionedQuantity();

      return provisionedLicenses < assignableLicenses;
    }

    /**
     * @description check feature flag and contract state for auto-renewal opt out
     * @returns {boolean} true: contract has opted out of auto-renewal, false: contract has not opted out of auto-renewal;
     */
    function isAutoRenewalOptOut() {
      return vm.contract.isDirectContract() && vm.contract.isAutoRenewalOff();
    }

    /**
     * @description set up renewal details section when a contract has opted out of auto-renewal
     */
    function setupAutoRenewOptOutRenewalSection() {
      const renewalMessagePathPrefix =
        'widgets.contracts.renewalDetailSection.renewalDetailMessageAutoRenewalOptOut';

      vm.supportContactNumber = ContactSales.getTeamPhoneNumber(
        AuthenticatedUser.get().getCountryCode()
      );

      switch (autoRenewalHelper.getCurrentPointInRenewalInContract(vm.contract)) {
        case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW:
          vm.renewalDetailMessageKey = `${renewalMessagePathPrefix}.preRenewalWindow`;
          vm.planExpiresDateString = vm.anniversaryDate;
          vm.startOfRenewalWindow = binkyDateTimeFilter(vm.contract.getRenewalWindowStartDate(), {
            dateOnly: true,
          });
          break;
        case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.IN_RENEWAL_WINDOW:
          vm.renewalDetailMessageKey = `${renewalMessagePathPrefix}.inRenewalWindow`;
          vm.planExpiresDateString = vm.anniversaryDate;
          vm.daysLeftString = $translate.instant(
            'widgets.contracts.renewalDetailSection.daysLeft',
            {
              count: vm.contract.getOffsetFromAnniversaryDate(),
            },
            'messageformat'
          );
          break;
        case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.POST_RENEWAL_WINDOW:
          vm.renewalDetailMessageKey = `${renewalMessagePathPrefix}.pastAnniversaryDate`;
          vm.planExpiredDateString = vm.anniversaryDate;
          break;
        default:
          showRenewalDetailSection();
          break;
      }
    }

    /**
     * @description set up the standard renewal details section for direct or indirect
     */
    function showRenewalDetailSection() {
      vm.renewalDetailMessageKey = vm.contract.isIndirectContract()
        ? 'widgets.contracts.renewalDetailSection.renewalDetailMessageIndirect'
        : 'widgets.contracts.renewalDetailSection.renewalDetailMessageDirect';

      const showRenewalPeriod =
        !_.isEmpty(vm.contract.getRenewalWindowStartDate()) &&
        !_.isEmpty(vm.contract.getRenewalWindowEndDate());

      const shouldFetchRenewalOrder =
        vm.contract.isDirectContract() &&
        !vm.contract.isDrContract &&
        vm.contract.isInRenewalWindow();

      if (showRenewalPeriod) {
        const endDate = binkyDateTimeFilter(vm.contract.getRenewalWindowEndDate(), {
          dateOnly: true,
        });
        const startDate = binkyDateTimeFilter(vm.contract.getRenewalWindowStartDate(), {
          dateOnly: true,
        });

        vm.renewalPeriod = $translate.instant(
          'widgets.contracts.renewalDetailSection.tableValues.renewalPeriod',
          {
            endDate,
            startDate,
          },
          'messageformat'
        );
      }

      if (shouldFetchRenewalOrder) {
        vm.waitPromise = vm.cart.$promise.then(onFetchRenewalOrderSuccess).catch(_.noop);
      } else {
        vm.waitPromise = $q.resolve();
      }
    }

    function setupAnniversaryDate() {
      if (vm.hasAnniversaryDate) {
        vm.anniversaryDate = binkyDateTimeFilter(vm.contract.getAnniversaryDate(), {
          dateOnly: true,
        });

        if (!vm.hasAutoRenewalOff) {
          vm.anniversaryDateString = vm.contract.isIndirectContract()
            ? vm.anniversaryDate
            : $translate.instant(
                'widgets.contracts.renewalDetailSection.tableValues.anniversaryDate',
                {
                  anniversaryDate: vm.anniversaryDate,
                },
                'messageformat'
              );
        }
      }
    }

    function onFetchRenewalOrderSuccess() {
      vm.showRenewalWorkflow = true;
      vm.renewingLicenseCount = vm.cart.getTotalRenewingLicenseCount();
      vm.allProvisionedLicensesRenewed = vm.cart.areAllProvisionedLicensesRenewed(vm.productList);

      const daysLeft = _.invoke(vm.contract, 'getOffsetFromAnniversaryDate');
      if (daysLeft) {
        // only show the days left tag when renewal workflow is enabled and daysLeftString is set.
        vm.daysLeftString = $translate.instant(
          'widgets.contracts.renewalDetailSection.daysLeft',
          {
            count: daysLeft,
          },
          'messageformat'
        );
      }

      vm.showAddPONumberButton =
        vm.contract.isDirectContract() &&
        vm.contract.isPaymentCategoryPO() &&
        !vm.cart.getPONumber();

      vm.bannerBody = getBannerBody();

      vm.renewalDetailMessageKey =
        'widgets.contracts.renewalDetailSection.renewalDetailMessageDirectInRenewalWindow';

      /**
       * @description gets the translated string for the banner body
       * @returns {String} translated string.
       */
      function getBannerBody() {
        if (contractAccess.canViewLocalCurrencyChangeInfo(vm.contract)) {
          const nextCurrencyCode = contractUtils.getFutureCurrency(vm.contract);

          const messageKey = `widgets.contracts.renewalDetailSection.banner.localCurrencyRenewalBanner.directCurrency.${nextCurrencyCode}`;
          const message = translationUtils.sanitizeSafeInstant(messageKey, {
            anniversaryDate: vm.anniversaryDate,
          });

          if (message !== messageKey) {
            return message;
          }
        }

        return $translate.instant('widgets.contracts.renewalDetailSection.banner.renewalBanner', {
          anniversaryDate: vm.anniversaryDate,
        });
      }
    }
  }
})();
