import {
  Button,
  Content,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Text,
  View,
} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {ImageIcon} from '@pandora/react-image-icon';
import {TitledSection} from '@pandora/react-titled-section';
import ErrorIcon from '@spectrum-icons/illustrations/Error';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import useAppIntegration from '../hooks/useAppIntegration';

/**
 * @description The details drawer for displaying info on an app integration's policy.
 */
const AppIntegrationsPolicyDrawer = ({clientId}) => {
  const intl = useIntl();
  // Fetch additional details for the app integration (e.g. permissions)
  const {appIntegration, error, isLoading} = useAppIntegration({
    clientId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return (
    <Drawer
      aria-label={intl.formatMessage({
        id: 'products.appIntegrations.appIntegrationsPolicyDrawer.drawerLabel',
      })}
      isLoading={isLoading}
    >
      <Header>
        {appIntegration && (
          <>
            <Flex alignItems="center">
              {appIntegration.logo && <ImageIcon alt="" size="XL" src={appIntegration.logo} />}
              <Heading level="2" marginStart={appIntegration.logo && 'size-150'}>
                {appIntegration.name}
              </Heading>
            </Flex>
            <Heading level="3" marginBottom="size-0" marginTop="size-100">
              <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyDrawer.clientIdLabel" />
            </Heading>
            <Text marginBottom="size-150">{appIntegration.clientIds[0]}</Text>
            {appIntegration.website && (
              <View>
                <Button
                  marginY="size-150"
                  onPress={() => {
                    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- False positive, it is using optional chaining
                    window?.open(appIntegration.website, '_blank', 'noopener,noreferrer');
                  }}
                  variant="primary"
                >
                  <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyDrawer.button.viewAppIntegrationLabel" />
                </Button>
              </View>
            )}
          </>
        )}
      </Header>
      <Content>
        {appIntegration && (
          <TitledSection
            headingLevel={3}
            title={intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsPolicyDrawer.permissions.title',
            })}
          >
            <ul>
              {appIntegration.permissions.map((permission) => (
                <li key={permission.displayName}> {permission.displayName} </li>
              ))}
            </ul>
          </TitledSection>
        )}

        {error && (
          <Flex alignItems="center" direction="column">
            <IllustratedMessage>
              <ErrorIcon />
              <Heading level="2">
                <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyDrawer.errorHeading" />
              </Heading>
              <Content>
                <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyDrawer.errorContent" />
              </Content>
            </IllustratedMessage>
          </Flex>
        )}
      </Content>
    </Drawer>
  );
};

AppIntegrationsPolicyDrawer.propTypes = {
  /** The client id of the app integration */
  clientId: PropTypes.string,
};

export default AppIntegrationsPolicyDrawer;
