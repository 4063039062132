const CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT = {
  IN_RENEWAL_WINDOW: 'IN_RENEWAL_WINDOW',
  NA: 'NA',
  POST_RENEWAL_WINDOW: 'POST_RENEWAL_WINDOW',
  PRE_RENEWAL_WINDOW: 'PRE_RENEWAL_WINDOW',
  PRE_RENEWAL_WINDOW_OPT_IN_SELF_SERVICE: 'PRE_RENEWAL_WINDOW_OPT_IN_SELF_SERVICE',
};

const RENEWAL_WINDOW_MARKERS = {
  T: 0,
  T3: 3,
  T30: 30,
  T45: 45,
  T90: 90,
};

const ACCOUNT_PAGE_LINK = {
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  OPT_IN_SELF_SERVICE: 'OPT_IN_SELF_SERVICE',
};

export {ACCOUNT_PAGE_LINK, CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT, RENEWAL_WINDOW_MARKERS};
