const COMPLIANCE_CONTEXT = {
  // Contract is indirect
  INDIRECT: 'INDIRECT',
  // Contract is direct, has payment category set to credit card or paypal, and
  // the billing frequency is monthly (Annual Paid Monthly; APM)
  TEAM_DIRECT_CCPAYPAL_APM: 'TEAM_DIRECT_CCPAYPAL_APM',
  // Contract is direct, has payment category set to credit card or paypal, and
  // the billing frequency is annual (Paid Up Front; PUF)
  TEAM_DIRECT_CCPAYPAL_PUF: 'TEAM_DIRECT_CCPAYPAL_PUF',
  // Contract has payment category set to VENDOR_DIGITAL_RIVER_PAYMENT
  TEAM_DIRECT_DIGITAL_RIVER: 'TEAM_DIRECT_DIGITAL_RIVER',
  // Contract has payment category set to purchase order or OFFLINE
  TEAM_DIRECT_PO_OFFLINE: 'TEAM_DIRECT_PO_OFFLINE',
  // Contract has a context that is unrecognized
  UNKNOWN: 'UNKNOWN',
};

const COMPLIANCE_GRACE_PAST_DUE_BANNER_ID = 'COMPLIANCE_GRACE_PAST_DUE_BANNER_ID';

export {COMPLIANCE_CONTEXT, COMPLIANCE_GRACE_PAST_DUE_BANNER_ID};
