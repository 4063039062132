import rootStore from 'core/RootStore';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

import educationHvaLogo from './educationHvaLogo.svg';

const handleCompleteSetup = () => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const redirectURL = `${orgId}/settings/identity/directories?setupOidc&oidcId`;

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- the handler will always run in browser
  window.location.assign(redirectURL);
};

class SetupSoidc extends HvaCard {
  static get({intl}) {
    if (!rootStore.organizationStore.isActiveOrgEdu) {
      return Promise.reject();
    }

    return Promise.resolve([new SetupSoidc({intl})]);
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.setupSoidc';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      goUrl: 'oidc_sso_edu',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: educationHvaLogo,
      },
      id: 'setup-soidc',
      onCTA: handleCompleteSetup,
      priorityOrder: HVA_ORDER.SET_UP_SOIDC,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default SetupSoidc;
