/* eslint-disable max-statements  */
(function () {
  const componentName = 'appQuickLinksSection';
  /**
   * @deprecated use src2 Overview page
   *
   * @ngdoc component
   * @name app.overview:appQuickLinksSection
   * @description renders a quick links/actions section for the overview page
   *
   * @example <app-quick-links-section>
   *          </app-quick-links-section>
   */
  angular.module('app.overview').component(componentName, {
    controller,
    templateUrl: 'app/overview/overview/quick-links-section/quick-links-section.component.html',
  });

  /* @ngInject */
  function controller(
    $location,
    $log,
    $q,
    $state,
    $rootScope,
    $translate,
    _,
    addUser2GlobalModal,
    adminAccess,
    AnalyticsEvent,
    binkyUISrc2,
    chat,
    contractUtils,
    feature,
    GLOBAL_ADMIN_POLICY,
    globalModalsManager,
    GLOBAL_MODAL_BINDING_TYPE,
    onesieSrc2,
    organizationAccess,
    OrganizationManager,
    ORGANIZATION_EVENT,
    PageContext,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    productAccess,
    ProductPurchaseHelper,
    PRODUCT_LIST_EVENT,
    removeOnReadOnly,
    trialHelper
  ) {
    const DEVELOPER_TYPE = 'dev';
    const QUICK_LINK_LIMIT = 3;
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      AddAdminModal: onesieSrc2.users.components.AddAdminModal,
      adminRoles: [],
      binkyUISrc2,
      contractUtils,
      onAddAdminModalClose,
      showQuickLinksSection,
    });

    vm.contractList = OrganizationManager.getContractsForActiveOrg();
    vm.productList = OrganizationManager.getProductsForActiveOrg();

    if (showAddAdminsLink()) {
      vm.adminRolesPromise = adminAccess.getManageableAdminRoles().then((adminRoles) => {
        vm.adminRoles = adminRoles;
      });
    } else {
      vm.adminRolesPromise = $q.resolve();
    }

    vm.onProductListUpdateDeregister = $rootScope.$on(PRODUCT_LIST_EVENT.REFRESH, () => {
      $onChanges();
    });
    vm.onOrganizationUpdateDeregister = $rootScope.$on(ORGANIZATION_EVENT.UPDATE, () => {
      $onChanges();
    });

    function $onInit() {
      vm.hasAnyAPIProduct = false;
      vm.quickLinks = [];
      vm.orgId = OrganizationManager.getActiveOrgId();
      vm.isAddAdminModalOpen = false;
      vm.policyActionList = [
        {
          actions: [
            $translate.instant('overview.quickLinksDetailSection2.globalAdmin.actions.addAdmins'),
          ],
          policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
        },
      ];

      // Called when user refresh the page having /add-products in url and temp_add_product_mini_app is enabled
      if (productAccess.canViewAddProductMiniApp()) {
        openAddProductsModal();
      }
    }

    function $onChanges() {
      const resourcePromises = [vm.adminRolesPromise, vm.productList.$promise];
      vm.waitOnPage = $q.all(resourcePromises).then(checkIfAnyAPIProducts).then(setQuickLinks);
    }

    function $onDestroy() {
      vm.onProductListUpdateDeregister();
      vm.onOrganizationUpdateDeregister();
    }

    function showQuickLinksSection() {
      return vm.quickLinks.length > 0;
    }

    ////////

    function onAddAdminModalClose() {
      vm.isAddAdminModalOpen = false;
      $rootScope.$apply();
    }

    function setQuickLinks() {
      showAccountLinkPromise()
        .then((showAccountLink) => {
          const quickLinks = [];
          const canManageAdmins = adminAccess.canManageAdmins();
          // The ordering of these links is important, as they are in priority order.
          // We only want to display up to 3 links that are applicable, and leave out the rest.

          if (showAddProductsLink()) {
            quickLinks.push({
              class: 'add-products-link',
              icon: 'shoppingCart',
              labelKey: '.addProducts',
              onClick: openAddProductsModal,
            });
          } else if (showConvertTrialLink()) {
            quickLinks.push({
              class: 'convert-trial-link',
              icon: 'shoppingCart',
              labelKey: '.convertTrial',
              onClick: goToTrialConversionPage,
            });
          }

          if (!removeOnReadOnly.isReadOnly()) {
            if (showAccountLink) {
              quickLinks.push({
                class: 'account-link',
                icon: 'creditCard',
                labelKey: '.goToAccount',
                onClick: goToAccountPage,
              });
            }

            if (showAddUsersLink()) {
              quickLinks.push({
                class: 'add-users-link',
                icon: 'userAdd',
                labelKey: '.addUsers',
                onClick: openAddUserModal,
              });
            }

            if (showAddAdminsLink()) {
              quickLinks.push({
                class: 'add-admins-link',
                disabled: !canManageAdmins,
                icon: 'userAdmin',
                labelKey: '.addAdmins',
                onClick: canManageAdmins ? openAddAdminModal : _.noop,
              });
            }

            if (showAddDevelopersLink()) {
              quickLinks.push({
                class: 'add-developers-link',
                icon: 'userDeveloper',
                labelKey: '.addDevelopers',
                onClick: openAddDeveloperModal,
              });
            }
          }
          vm.quickLinks = quickLinks.slice(0, QUICK_LINK_LIMIT);
        })
        .catch((error) => {
          $log.error('Failed to run promised checks for QuickLinksSection. Error:', error);
        });
    }

    function checkIfAnyAPIProducts() {
      vm.hasAnyAPIProduct = _.some(vm.productList.items, (product) =>
        productAccess.canAssignDeveloper(product)
      );
    }

    function goToAccountPage() {
      sendClickAnalytics('go-to-account');
      $state.go('account.account');
    }

    function goToTrialConversionPage() {
      sendClickAnalytics('go-to-trial-conversion');

      const trialProduct = trialHelper.getTrialProduct();
      trialHelper.openBuyNowUrl(_.get(trialProduct, 'id'));
    }

    function openAddAdminModal() {
      sendClickAnalytics('add-admins');
      vm.orgId = OrganizationManager.getActiveOrgId();
      vm.isAddAdminModalOpen = true;
    }

    function openAddDeveloperModal() {
      sendClickAnalytics('add-developers');
      addUser2GlobalModal.open({
        modalName: 'AddDevelopersToOrgModal',
      });
    }

    function openAddUserModal() {
      sendClickAnalytics('add-users');
      openAddUserOrDevModal();
    }

    function openAddUserOrDevModal(options) {
      let modalParams = {};
      if (options && options.userType === DEVELOPER_TYPE) {
        modalParams = {
          targetTab: PAGE_TARGET_TYPE.DEVELOPER,
        };
      }
      const targetType =
        options && options.userType === DEVELOPER_TYPE
          ? PAGE_TARGET_TYPE.DEVELOPER
          : PAGE_TARGET_TYPE.USER;

      if (PAGE_TARGET_TYPE.USER === targetType) {
        addUser2GlobalModal.open({
          modalName: 'AddUsersToOrgModal',
        });
      } else {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'pageContext',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: new PageContext({
                target: PAGE_TARGET.ORGANIZATION,
                targetId: OrganizationManager.getActiveOrgId(),
                targetType,
              }),
            },
          ],
          componentName: 'appAddUserModal',
          modalParams,
        });
      }
    }

    function openAddProductsModal() {
      sendClickAnalytics('add-products');
      // Enable the Add Product Mini App conditionally
      if (onesieSrc2.core.products.access.canUseAddProductMiniApp()) {
        productAccess.setAddProductUrl();
        const cartItems = ProductPurchaseHelper.convertLocationSearchToCartItems(
          $location.search()
        );
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.products.components.AddProductModalWrapper,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                chat,
                items: cartItems,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        $state.go('overview.overview2.add-products', {}, {inherit: true});
      }
    }

    function showAccountLinkPromise() {
      // Even if they can see the Account page and see account information,
      // we actually only care about showing this link if the user is responsible for billing.
      return organizationAccess.canViewDirectContractBillingHistory();
    }

    function showAddAdminsLink() {
      return adminAccess.canAssignAdmin();
    }

    function showAddDevelopersLink() {
      return vm.hasAnyAPIProduct;
    }

    function showAddProductsLink() {
      return vm.contractUtils.isAllowedToAddProducts();
    }

    function showAddUsersLink() {
      return organizationAccess.canAssignUser();
    }

    function showConvertTrialLink() {
      const trialProduct = trialHelper.getTrialProduct();
      const dxTrialContract = trialHelper.getDxTrialContract(vm.contractList);
      return productAccess.canBuyTrialProduct(trialProduct) || dxTrialContract;
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
/* eslint-enable max-statements  */
