import {Page, PageBanners, PageContent, PageHeader, PageNav, useStore} from '@admin-tribe/binky-ui';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';

import CreatePackageButton from '../../components/create-package-modal/CreatePackageButton';
import TemplateListTable from '../../components/template-list-table/TemplateListTable';
import CreatePackageFactory from '../../services/CreatePackageService';
import PackagesEntitlements from '../../services/PackagesEntitlementsService';
import PackagesAdobeTemplatesStore from '../../stores/PackagesAdobeTemplatesStore';

/**
 * Packages Adobe Templates page
 */
const TemplatesPage = observer(() => {
  const packagesAdobeTemplatesStore = useStore(() => new PackagesAdobeTemplatesStore());
  const intl = useIntl();

  // Initial load of the templates list.
  useEffect(() => {
    packagesAdobeTemplatesStore.load();
  }, [packagesAdobeTemplatesStore]);

  const onTableSectionChange = ({action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        packagesAdobeTemplatesStore.goToNextPage();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        packagesAdobeTemplatesStore.goToPreviousPage();
        break;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        packagesAdobeTemplatesStore.onPageSizeChange(payload);
        break;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        packagesAdobeTemplatesStore.search(payload.value);
        break;
      default:
        // Do nothing
        break;
    }
  };

  const setCreatePackageFactoryValues = () => {
    if (PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcEntitlement) {
      CreatePackageFactory.setCreatePackageFactoryValues();
    } else {
      CreatePackageFactory.setCreatePackageFactoryValuesForFrl();
    }
  };

  return (
    <Page isLoading={packagesAdobeTemplatesStore.isLoading}>
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PACKAGES} />
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'packages.adobeTemplates.title'})} />
      <PageNav />
      <PageContent>
        <TableSection
          getIsItemSelectable={() => false} // templates list table rows are not selectable
          getItemKey={(item) => item.data.id}
          isServerError={
            rootStore.packagesUiConstantsStore.hasLoadingError ||
            packagesAdobeTemplatesStore.hasLoadingError
          }
          items={packagesAdobeTemplatesStore.tableStore.rows}
          onTableSectionChange={onTableSectionChange}
          pageNumber={packagesAdobeTemplatesStore.tableStore.currentPage}
          selectionMode={SELECTION_MODE.NONE}
          totalPages={packagesAdobeTemplatesStore.tableStore.totalPages}
        >
          <TableFilters
            aria-label={intl.formatMessage({id: 'packages.adobeTemplates.searchLabel'})}
            isDisabled={packagesAdobeTemplatesStore.packagesAdobeTemplatesList.length === 0}
            label={intl.formatMessage({id: 'packages.adobeTemplates.searchLabel'})}
            minLength={0}
            width="size-2400"
          />
          <TableActions>
            <CreatePackageButton
              buttonLabel={intl.formatMessage({id: 'packages.createPackageButtonLabel'})}
              isDisabled={
                packagesAdobeTemplatesStore.isLoading || packagesAdobeTemplatesStore.hasLoadingError
              }
              setCreatePackageFactoryValues={setCreatePackageFactoryValues}
            />
          </TableActions>
          <TemplateListTable />
        </TableSection>
      </PageContent>
    </Page>
  );
});

TemplatesPage.propTypes = {};

export default TemplatesPage;
