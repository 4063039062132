import Alert from '@react/react-spectrum/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {FEDERATED_DOMAINS_ERROR_CODES} from 'features/settings/components/import-federated-domains-modal/hooks/useFederatedDomainsModalState';

const ERRORS = {
  [FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR]: {
    body: 'settings.directoryDomains.addDomainsModalLoadError.body',
    header: 'settings.directoryDomains.addDomainsModalLoadError.header',
  },
  [FEDERATED_DOMAINS_ERROR_CODES.INVALID_TOKEN_ERROR]: {
    body: 'settings.directoryDomains.addDomainsModalTokenError.body',
    header: 'settings.directoryDomains.addDomainsModalTokenError.header',
  },
};

const ImportFederatedDomainsModalAlert = ({error, onDataReload}) => {
  const intl = useIntl();

  const errorCode = error || FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR;
  const errorMessage = ERRORS[errorCode] || ERRORS[FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR];

  return (
    <Alert
      closeLabel={intl.formatMessage({
        id: 'settings.directoryDomains.addDomainModal.retryButton',
      })}
      header={intl.formatMessage({
        id: errorMessage.header,
      })}
      onClose={onDataReload}
      style={{width: '100%'}}
      variant="error"
    >
      {intl.formatMessage({id: errorMessage.body})}
    </Alert>
  );
};

ImportFederatedDomainsModalAlert.propTypes = {
  /**
   * Error type when fetching external domains
   */
  error: PropTypes.string,
  /**
   * Callback function to reload data when closing alert
   */
  onDataReload: PropTypes.func,
};

export default ImportFederatedDomainsModalAlert;
export {ERRORS, FEDERATED_DOMAINS_ERROR_CODES};
