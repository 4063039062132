import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the SetupCertificatesStep and its sub-components.
 */
const SetupCertificatesContext = createContext({});
const useSetupCertificatesContext = () => useContext(SetupCertificatesContext);

const SetupCertificatesContextProvider = ({children, listState}) => {
  const value = useMemo(() => ({...listState}), [listState]);

  return (
    <SetupCertificatesContext.Provider value={value}>{children}</SetupCertificatesContext.Provider>
  );
};

SetupCertificatesContextProvider.propTypes = {
  /**
   * The content to be passed through the context of SetupCertificates step
   */
  children: PropTypes.node,
  /**
   * The state of the SetupCertificatesStep used by the provider to keep state of the children components
   */
  listState: PropTypes.shape({
    loadCertificatesState: PropTypes.func,
    state: PropTypes.shape({
      certificates: PropTypes.arrayOf(
        PropTypes.shape({
          certificateCreatedAt: PropTypes.number,
          certificateExpiresAt: PropTypes.number,
          status: PropTypes.string,
          type: PropTypes.string,
        })
      ),
      csrs: PropTypes.arrayOf(
        PropTypes.shape({
          certificateExpiresAt: PropTypes.number,
          createdAt: PropTypes.number,
          status: PropTypes.string,
          type: PropTypes.string,
        })
      ),
      hasLoadingError: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  }),
};

export {SetupCertificatesContextProvider, useSetupCertificatesContext};
