import {
  InfoBar,
  InfoItemScorecard,
  Page,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
} from '@admin-tribe/binky-ui';
import {Image} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import TeamList from 'common/services/team-list/TeamList';
import rootStore from 'core/RootStore';
import ProductDeactivatedDevicesTable from 'features/products/components/product-deactivated-devices-table/ProductDeactivatedDevicesTable';

import noDeviceLicensesAvailable from './assets/no_device_licenses_available.svg';

/**
 * DBL (device based license) product deactivated devices page;
 * Url: /products/devices/deactivated
 */
const ProductDeactivatedDevicesPage = () => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  useDocumentTitle({key: 'products.details.devices.deactivatedDevices.heading'});

  const fetchDeviceCount = useCallback(() => {
    const teamListObj = new TeamList();
    return teamListObj.deactivatedDevices(orgId);
  }, [orgId]);

  const {
    isLoading,
    error,
    model: deactivatedDevices,
  } = useAsyncModel({
    loadFn: fetchDeviceCount,
  });

  return (
    <Page data-testid="deactivated-devices-page" isBumpered={!!error} isLoading={isLoading}>
      <PageHeader
        title={intl.formatMessage({
          id: 'products.details.devices.deactivatedDevices.heading',
        })}
      >
        <Image alt="" src={noDeviceLicensesAvailable} width="size-800" />
      </PageHeader>
      <PageInfoBar>
        <InfoBar>
          <InfoItemScorecard
            data-testid="deactivated-device-count-section"
            label={intl.formatMessage({
              id: 'products.details.devices.deactivatedDevices.counter.label',
            })}
            value={deactivatedDevices?.data?.length}
          />
        </InfoBar>
      </PageInfoBar>
      <PageNav />
      <PageContent>
        {!isLoading && !error && (
          <ProductDeactivatedDevicesTable deactivatedDevicesList={deactivatedDevices?.data} />
        )}
      </PageContent>
    </Page>
  );
};

export default ProductDeactivatedDevicesPage;
