import {AUTHORIZATION_POLICIES, MEMBER_TYPE} from '@admin-tribe/binky';
import {MemberManagement} from '@admin-tribe/binky-ui';
import {Flex, Heading, Radio, RadioGroup, Text} from '@adobe/react-spectrum';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const GLOBAL_POLICY_LOC_KEYS = {
  allow: 'products.appIntegrations.appIntegrationsPolicyForm.globalPolicy.radioButtonLabel.allow',
  deny: 'products.appIntegrations.appIntegrationsPolicyForm.globalPolicy.radioButtonLabel.deny',
};

const SINGLE_POLICY_LOC_KEYS = {
  allow: 'products.appIntegrations.appIntegrationsPolicyForm.singlePolicy.radioButtonLabel.allow',
  custom: 'products.appIntegrations.appIntegrationsPolicyForm.singlePolicy.radioButtonLabel.custom',
  deny: 'products.appIntegrations.appIntegrationsPolicyForm.singlePolicy.radioButtonLabel.deny',
};

/**
 * AppIntegrationsPolicyForm allows the admin to set a global policy for
 * all app integrations or a single app integration policy.
 */
const AppIntegrationsPolicyForm = ({
  initAuthorizedUsers,
  isGlobalPolicy,
  onMemberManagementChange,
  onSelectPolicy,
  selectedPolicy = AUTHORIZATION_POLICIES.SINGLE_APP.ALLOW_ALL,
}) => {
  const intl = useIntl();
  const radioButtonValues = initRadioButtons(isGlobalPolicy);

  return (
    <>
      {isGlobalPolicy && (
        // Since modal header is level 2, set this heading to be level 3
        <Heading level="3">
          <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyForm.heading" />
        </Heading>
      )}
      <Text>
        {isGlobalPolicy ? (
          <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyForm.globalPolicy.description" />
        ) : (
          <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyForm.singlePolicy.description" />
        )}
      </Text>
      {/* Wrapping RadioGroup in Flex to limit width of the radio group as large label click box can accidentally reset the allowlist of users  */}
      <Flex>
        <RadioGroup
          isEmphasized
          label={intl.formatMessage({
            id: 'products.appIntegrations.appIntegrationsPolicyForm.radioGroup.label',
          })}
          onChange={onSelectPolicy}
          value={selectedPolicy}
        >
          {radioButtonValues.map((radioButtonValue) => (
            <Radio key={radioButtonValue} value={radioButtonValue}>
              {isGlobalPolicy ? (
                <FormattedMessage id={GLOBAL_POLICY_LOC_KEYS[radioButtonValue.toLowerCase()]} />
              ) : (
                <FormattedMessage id={SINGLE_POLICY_LOC_KEYS[radioButtonValue.toLowerCase()]} />
              )}
            </Radio>
          ))}
        </RadioGroup>
      </Flex>

      {selectedPolicy === AUTHORIZATION_POLICIES.SINGLE_APP.CUSTOM && (
        <>
          <Flex alignItems="center" gap="size-50" marginTop="size-200">
            <InfoIcon size="S" />
            <Text>
              <FormattedMessage id="products.appIntegrations.appIntegrationsPolicyForm.info.label" />
            </Text>
          </Flex>
          <MemberManagement
            disabledTypes={[MEMBER_TYPE.TYPE1]}
            emptyStateContentText={intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsPolicyForm.memberManagement.emptyStateContent',
            })}
            emptyStateHeadingText={intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsPolicyForm.memberManagement.emptyStateHeading',
            })}
            excludeTechAccounts
            existingMembers={initAuthorizedUsers}
            onChange={onMemberManagementChange}
            orgId={rootStore.organizationStore.activeOrgId}
            tableAriaLabel={intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsPolicyForm.memberManagement.tableAriaLabel',
            })}
          />
        </>
      )}
    </>
  );
};

function initRadioButtons(isGlobalPolicy) {
  if (isGlobalPolicy) {
    return [AUTHORIZATION_POLICIES.ALL_APPS.ALLOW_ALL, AUTHORIZATION_POLICIES.ALL_APPS.BLOCK_ALL];
  }
  return [
    AUTHORIZATION_POLICIES.SINGLE_APP.ALLOW_ALL,
    AUTHORIZATION_POLICIES.SINGLE_APP.CUSTOM,
    AUTHORIZATION_POLICIES.SINGLE_APP.BLOCK_ALL,
  ];
}

AppIntegrationsPolicyForm.propTypes = {
  /** The original set of authorized users */
  initAuthorizedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  /**
   * Boolean for whether or not form should be in the 'global policy' selection mode.
   */
  isGlobalPolicy: PropTypes.bool,
  /**
   * The callback called when any changes occur on MemberManagement.
   */
  onMemberManagementChange: PropTypes.func,
  /**
   * The callback called when the radio button is selected.
   */
  onSelectPolicy: PropTypes.func,
  /**
   * The selected policy. Defaults to AUTHORIZATION_POLICIES.SINGLE_APP.ALLOW_ALL.
   */
  selectedPolicy: PropTypes.oneOf(Object.values(AUTHORIZATION_POLICIES.SINGLE_APP)),
};

export default AppIntegrationsPolicyForm;
