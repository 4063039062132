(function () {
  /**
   * @deprecated Please use getTeamPhoneNumber from src2/app/core/contact-sales/contactSalesUtils.js
   */
  angular.module('app.core.contact-sales').factory('ContactSales', ContactSalesModel);

  /* @ngInject */
  function ContactSalesModel(_, TEAM_CALL_CENTER_PHONE_NUMBER) {
    const service = {
      getTeamPhoneNumber,
    };

    return service;

    /////////////

    function getTeamPhoneNumber(countryCode) {
      // default to rest of the world phone number
      return (
        TEAM_CALL_CENTER_PHONE_NUMBER[_.toUpper(countryCode)] || TEAM_CALL_CENTER_PHONE_NUMBER.ROW
      );
    }
  }
})();
