import help from 'shell/help/constants/help';
import {hasManageSdlAccess} from 'shell/help/utils/helpUtils';

const {LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 * @description Method to return configuration object for the manage SDL
 *   help topic
 * @returns content for manage SDL help topic
 */
const manageSdl = () => ({
  access: hasManageSdlAccess,
  content: [],
  id: TOPICS.MANAGE_SDL,
  links: [
    {
      key: 'help.topics.manageSdl.link.one.key',
      locator: 'get-started.products.manage-sdl',
      target: LINK_TARGET_TYPE.HELP,
      type: LINK_TYPE.TEXT,
    },
  ],
  order: 0,
  ordered: false,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  text: 'help.topics.manageSdl.text',
  title: 'help.topics.manageSdl.title',
});

export default manageSdl;
