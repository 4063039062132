import {Divider} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {SCREEN_NAME_CONSTANTS} from 'features/packages/packagesConstants';
import {scrollToInitialPosition} from 'features/packages/stores/createPackageModalStoreUtils';

import {useCreatePackageModalContext} from '../CreatePackageModalContext';
import ChooseActivationTypePage from '../screens/choose-activation-type-page/ChooseActivationTypePage';
import ChooseLicenseTypePage from '../screens/choose-license-type-page/ChooseLicenseTypePage';
import ChoosePackageTypePage from '../screens/choose-package-type-page/ChoosePackageTypePage';
import SelfServicePage from '../screens/self-service/SelfServicePage';

// Wizard API will be hoisted so we can control it.
let wizard;

// PackageTypeSelectionWizard contains 4 screens: ChooseLicenseType, ChooseActivationType, ChoosePackageType and SelfService
// Each screen is or is not displayed based on the choice made is previous screen, in this wizard we get admin's
// choice of package type (from ChooseLicenseType, ChooseActivationType, ChoosePackageType screens)
// If package type selected as SELF_SERVICE then, Self service screen shown and admin will be prompted to create package
// Else, admin can also navigate from PackageTypeSelectionWizard to PackageTypeFlowWizard
const PackageTypeSelectionWizard = observer(({closeCreatePackageModal}) => {
  const intl = useIntl();
  const onInitWizard = (inputWizard) => {
    wizard = inputWizard;
  };
  const {createPackageModalStore} = useCreatePackageModalContext();

  const previousText = intl.formatMessage({id: 'packages.createPackage.footer.previous'});
  const nextText = intl.formatMessage({id: 'packages.createPackage.footer.next'});
  const cancelText = intl.formatMessage({id: 'packages.createPackage.footer.cancel'});
  const createText = intl.formatMessage({id: 'packages.createPackage.footer.createPackage'});
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(
    createPackageModalStore.pkgSessionSettings.nextButtonEnabled
  );

  const [isCreatePkgBtnDisabled, setIsCreatePkgBtnDisabled] = useState(
    createPackageModalStore.pkgSessionSettings.createPackageDisabled
  );

  const modalContentRef = useRef();

  // Update next button enabled status
  useEffect(() => {
    setIsNextBtnEnabled(createPackageModalStore.pkgSessionSettings.nextButtonEnabled);
  }, [createPackageModalStore.pkgSessionSettings.nextButtonEnabled]);

  // Update build package button status
  useEffect(() => {
    setIsCreatePkgBtnDisabled(createPackageModalStore.pkgSessionSettings.createPackageDisabled);
  }, [createPackageModalStore.pkgSessionSettings.createPackageDisabled]);

  const initWizardState = {
    stepIndex: createPackageModalStore.packageTypeSelectionScreenState,
    steps: createPackageModalStore.packageTypeSelectionWizardSteps.map((step) => ({name: step})),
  };

  const content = ModalDialogModel.createEntry({
    cancelLabel: cancelText,
    ctaLabel: createPackageModalStore.isCreatePackageButtonVisible ? createText : nextText,
    secondaryLabel: createPackageModalStore.isPreviousButtonVisible ? previousText : null,
  });

  const stepsToScreenMapping = {
    [SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE]: <ChooseActivationTypePage />,
    [SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE]: <ChooseLicenseTypePage />,
    [SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE]: <ChoosePackageTypePage />,
    [SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN]: <SelfServicePage />,
  };

  return (
    <ModalDialog
      content={content}
      dialogStyle={{spaceX: 'size-0', width: '1100px'}}
      heightVariant="static"
      id={createPackageModalStore.createPackageModalId}
      isCtaDisabled={
        createPackageModalStore.isCreatePackageButtonVisible
          ? isCreatePkgBtnDisabled
          : !isNextBtnEnabled
      }
      modalContentRef={modalContentRef}
      onCancel={closeCreatePackageModal}
      onCta={() => {
        createPackageModalStore.gotoNextScreen(wizard.goToNextStep, closeCreatePackageModal);
        scrollToInitialPosition(modalContentRef);
      }}
      onSecondary={
        createPackageModalStore.isPreviousButtonVisible
          ? () => {
              createPackageModalStore.gotoPreviousScreen(wizard.goToPreviousStep);
              scrollToInitialPosition(modalContentRef);
            }
          : undefined
      }
    >
      <ModalHeading UNSAFE_style={{fontSize: '28px!important', fontWeight: '300!important'}}>
        {createPackageModalStore.modalTitle}
      </ModalHeading>
      <Divider size="M" />
      <ModalContent>
        {/* Passing empty function to onStepChange as it is a required field */}
        <WizardView initConfig={initWizardState} onStepChange={() => {}}>
          {stepsToScreenMapping[createPackageModalStore.pkgSessionSettings.currentScreen]}
          <WizardDriver onInit={onInitWizard} />
        </WizardView>
      </ModalContent>
    </ModalDialog>
  );
});

PackageTypeSelectionWizard.propTypes = {
  /**
   * Function called to close create package modal
   */
  closeCreatePackageModal: PropTypes.func.isRequired,
};

export default PackageTypeSelectionWizard;
