/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.access-requests:appRejectAccessRequestModal
 * @binding accessRequestList. Trust list filters on TRUST_STATUS.PENDING status.
 * @binding onSuccess. Callback called if the reject access request call succeeds (inc. partial success).
 *
 * @description The modal is for the Owning Admin to reject an access request to a directory or directories.
 */
(function () {
  angular.module('app.widgets.access-requests').component('appRejectAccessRequestModal', {
    bindings: {
      accessRequestList: '<',
      onSuccess: '&',
    },
    controller,
    templateUrl:
      'app/widgets/access-requests/reject-access-request-modal/reject-access-request-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $translate,
    _,
    DisplayMessage,
    panelManager,
    REJECT_ACCESS_REQUEST_MODAL_ID,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onModalOpen,
      onRejectRequest,
    });

    function $onInit() {
      initialize();
    }

    function initialize() {
      _.assign(vm, {
        displayMessage: hidePageBanner(),
        isBusy: false,
        REJECT_ACCESS_REQUEST_MODAL_ID,
        rejectReason: '',
        waitPromise: $q.resolve(),
      });
    }

    function onModalOpen(params) {
      initialize();
      _.assign(vm, _.pick(params, ['accessRequestItems']));
    }

    function onRejectRequest() {
      hidePageBanner();
      vm.isBusy = true;

      const rejectReason = _.trim(vm.rejectReason);
      vm.waitPromise = vm.accessRequestList
        .rejectAccessRequests(vm.accessRequestItems, rejectReason)
        .then(onSuccess, () => showPageBanner())
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess(responses) {
        const {completedItems, failedItems} = responses;

        if (failedItems.length > 0) {
          showPageBanner('someAccessRequestsNotRejected');
        } else {
          panelManager.close(REJECT_ACCESS_REQUEST_MODAL_ID);
        }

        if (completedItems.length > 0) {
          showRejectedToast({count: completedItems.length});
        }

        // Callback parent on success.
        vm.onSuccess();
      }
    }

    ///////////

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function showPageBanner(msg) {
      vm.displayMessage = new DisplayMessage({
        body: msg
          ? $translate.instant(`widgets.accessRequest.rejectAccessRequestModal.pageBanner.${msg}`)
          : undefined,
      });
    }

    function showRejectedToast(options) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.accessRequest.rejectAccessRequestModal.toasts.messageFormat.requestRejected',
          options,
          'messageformat'
        )
      );
    }
  }
})();
