import {JilModelList, log} from '@admin-tribe/binky';

import {JIL_STORAGE_TYPE} from '../../api/jil/jilStorageFolderConstants';
import jilStorageFolders from '../../api/jil/jilStorageFolders';
import StorageReport, {STORAGE_REPORT_EVENTS} from '../storage-report/StorageReport';

const STORAGE_REPORT_LIST_CACHE_ID = 'StorageReportList';

class StorageReportList extends JilModelList {
  /**
   * @description Method to create a new instance of a StorageReportList.
   * @param {Object} options - options passed to the StorageReportList constructor
   * @param {String} options.folderType - folder path of the storage report, users or assets
   * @param {ListState} options.state - the list state used to manage paging for the jilStorageFolders
   *  API token-paged response data.
   */
  constructor(options) {
    super({
      cacheClearingEvents: [STORAGE_REPORT_EVENTS.CREATE],
      isCacheable: true,
      itemClassRef: StorageReport,
      modelCacheId: STORAGE_REPORT_LIST_CACHE_ID,
      resource: jilStorageFolders.getStorageFoldersByPath,
      ...options, // Override defaults and state
    });
    this.folderType = options.folderType;
  }

  /**
   * @description Method to permanently delete storage report.
   *   The caller should refresh the list.
   * @param {Array<StorageReport>} reports - reports to be permanently deleted
   * @returns {Promise} resolves to StorageReport on success, else rejects with error
   */
  async permanentDelete(reports) {
    let response;

    const operations = reports.map((report) => ({
      op: 'remove',
      path: `/${report.id}/delete`,
    }));

    try {
      response = await jilStorageFolders.patchStorageFolders({
        operations,
        type: JIL_STORAGE_TYPE.REPORTS,
      });

      if (
        Array.isArray(response.data) &&
        response.data.some((item) => !(item.responseCode >= 200 && item.responseCode < 400))
      ) {
        log.error('Failed to permanently delete all storage reports.');
        return Promise.reject();
      }
    } catch (error) {
      log.error('Failed to permanently delete storage reports. Error: ', error);
      return Promise.reject(error);
    }

    return this;
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<StorageReportList>} resolves to refreshed StorageReportList model, else rejects with error message
   */
  refresh() {
    return super.refresh({
      path: `/reports/${this.folderType}`,
    });
  }
}

export {STORAGE_REPORT_LIST_CACHE_ID};

export default StorageReportList;
