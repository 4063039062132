(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ProductConfigurationIntegrationList
   * @description product configuration integration list model
   */
  angular
    .module('app.core.product.configurations.integration-list')
    .factory('ProductConfigurationIntegrationList', getProductConfigurationIntegrationListFactory);

  /* @ngInject */
  function getProductConfigurationIntegrationListFactory(
    $q,
    $rootScope,
    _,
    ApiIntegrationList,
    ApiIntegrationUser,
    MEMBER_TECH_ACCOUNT_DOMAIN,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    ProductConfigurationUserList
  ) {
    class ProductConfigurationIntegrationList extends ProductConfigurationUserList {
      /**
       * @class
       * @description Constructor for ProductConfigurationIntegrationList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.LICENSECONFIGURATIONINTEGRATIONLIST,
              memberClassRef: ApiIntegrationUser,
            },
            options
          )
        );
        this.configurationId = options.profileId;
        this.productId = options.productId;
        this.filter.query = MEMBER_TECH_ACCOUNT_DOMAIN;
        this.filter.excludeDomain = undefined;
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @return {Promise} resolves to refreshed ProductConfigurationIntegrationList model, else rejects with error message
       */
      refresh() {
        const deferred = $q.defer();
        this.$resolved = false;
        this.$promise = deferred.promise;
        this.filter.query = MEMBER_TECH_ACCOUNT_DOMAIN;
        super
          .refresh({delegatePromise: true})
          .then(() => ApiIntegrationList.get().$promise)
          .then((integrations) => {
            // join integration data with user data
            _.forEach(this.items, (item) => item.addIntegration(integrations));
            this.$resolved = true;
            deferred.resolve(this);
            // update model cache
            modelCache.put(this.cacheType, this, this.key());
            // emit update event
            $rootScope.$emit(MESSAGE.UPDATE.CONFIGURATIONINTEGRATIONS.LIST, this.configurationId);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(
                MESSAGE.UPDATE.CONFIGURATIONINTEGRATIONS.COUNT,
                this.pagination.itemCount
              );
            }
          })
          .catch((error) => {
            this.$resolved = true;
            deferred.reject(error);
          });
        return deferred.promise;
      }

      /**
       * @description Method to determine whether we need to update the total itemCount of a list.
       *
       * @return {Boolean} true if the total itemCount needs to be updated
       */
      shouldUpdateTotalItemCount() {
        return _.has(this, 'pagination.itemCount');
      }

      /**
       * @description Method to retrieve an existing ProductConfigurationIntegrationList from the back-end.
       *
       * @param {Object} [options] options for JIL API call
       * @param {Number} [options.pageSize] number of configuration integrations to display per page
       *
       * @returns {ProductConfigurationIntegrationList} new ProductConfigurationIntegrationList Object reference
       */
      static get(options = {}) {
        let model = new ProductConfigurationIntegrationList(options);
        const cachedModel = modelCache.get(MODEL.LICENSECONFIGURATIONINTEGRATIONLIST, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    MemberList.setupCache(MODEL.LICENSECONFIGURATIONINTEGRATIONLIST, 10);

    return ProductConfigurationIntegrationList;
  }
})();
