// eslint-disable-next-line filenames/match-exported -- context
import {FULFILLABLE_ITEM_DELEGATION_TYPE, LicenseGroup, Product} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';

const AddProductProfileModalContext = React.createContext({});
const useProductProfileModalContext = () => useContext(AddProductProfileModalContext);

const AddProductProfileModalContextProvider = ({
  children,
  productProfile = null,
  product,
  orgId,
  setModalError,
  showStepList,
}) => {
  const [selectedSingleProductCode, setSelectedSingleProductCode] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentProductProfile] = useState(() => {
    if (productProfile) {
      return new LicenseGroup(productProfile);
    }
    const desktops = product.fulfillableItemList.hasSingleDesktopApplicationConfig()
      ? product.fulfillableItemList.getDesktopTypeItems().map((desktop) => {
          // We clone desktop FIs here because we modify them immediately
          const clonedDesktop = cloneDeep(desktop);
          clonedDesktop.selected = false;
          return clonedDesktop;
        })
      : [];

    const initialServices =
      product.fulfillableItemList.getServiceTypeItemsWithSelectedInitialized();
    const personDelegatedQuotas = product.fulfillableItemList
      .getQuotaTypeItems(false, FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON)
      // we compare as a string as the cap can technically be either a string or number
      .filter((fi) => `${fi.chargingModel.cap}` !== '0')
      .map((fi) => {
        const result = cloneDeep(fi);
        if (result.offByDefault) {
          result.chargingModel.cap = 0;
        }
        return result;
      });
    const consumableQuotas = product.fulfillableItemList.getConsumableQuotaTypeItems();

    let fulfilledItems = union(desktops, initialServices, personDelegatedQuotas, consumableQuotas);
    fulfilledItems = uniqBy(fulfilledItems, 'code');

    const defaultLicenseGroupObj = {
      fulfilledItems,
      name: '',
      notifications: true,
      orgId,
      product,
      publicName: '',
      totalQuantity: 0,
    };

    return new LicenseGroup(defaultLicenseGroupObj);
  });
  const [isNew] = useState(currentProductProfile?.isNew());
  const value = useMemo(
    () => ({
      currentProductProfile,
      isLoading,
      isNew,
      isValid,
      orgId,
      product,
      selectedSingleProductCode,
      setIsLoading,
      setIsValid,
      setModalError,
      setSelectedSingleProductCode,
      showStepList,
    }),
    [
      currentProductProfile,
      isLoading,
      isNew,
      isValid,
      orgId,
      product,
      selectedSingleProductCode,
      setIsLoading,
      setIsValid,
      setModalError,
      setSelectedSingleProductCode,
      showStepList,
    ]
  );

  return (
    <AddProductProfileModalContext.Provider value={value}>
      <AddProductProfileModalContext.Consumer>{children}</AddProductProfileModalContext.Consumer>
    </AddProductProfileModalContext.Provider>
  );
};

AddProductProfileModalContextProvider.propTypes = {
  children: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  product: PropTypes.instanceOf(Product),
  productProfile: PropTypes.instanceOf(LicenseGroup),
  setModalError: PropTypes.func,
  showStepList: PropTypes.bool,
};

export {useProductProfileModalContext};
export default AddProductProfileModalContextProvider;
