/**
 * @deprecated use src2 FreeOfferModal
 *
 * @ngdoc component
 * @name app.widgets.offers.free-offer-modal:appFreeOfferModal
 *
 * @description The modal is to accept free offers for an organization. It will also handle any qualifiers
 *   which need to be met beforehand, such as the setting of market subsegments.
 *
 * @param {String} modalId The ID to use for this modal. Defaults to FREE_OFFER_MODAL_ID.
 * @param {OfferList} offerList The list of offers to display details (and check qualifiers) for.
 * @param {Function} onClosed Called when the modal is closed.
 * @param {Function} onInitialized Called when the modal is initialized (but not yet opened).
 */
(function () {
  const componentName = 'appFreeOfferModal';
  angular.module('app.widgets.offers.free-offer-modal').component(componentName, {
    bindings: {
      modalId: '@?',
      offerList: '<',
      onClosed: '&?',
      onInitialized: '&?',
      preferredOffer: '<?',
    },
    controller,
    templateUrl: 'app/widgets/offers/free-offer-modal/free-offer-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    Cart,
    FREE_OFFER_MODAL_ID,
    FREE_OFFER_WIZARD_ID,
    organizationTypeHelper,
    OrganizationList,
    panelManager,
    QUALIFIER_TARGET,
    toastManager,
    WIZARD_CHANGE,
    WIZARD_NEXT
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onClose,
      onConfirmAndContinue,
      onModalClose,
      onModalOpen,
      onOfferFormChange,
      onOrgTypeFormChange,
      wizardId: FREE_OFFER_WIZARD_ID,
    });

    $scope.$on(WIZARD_CHANGE, onWizardChange);

    function onClose() {
      panelManager.close(vm.id);
    }

    function onConfirmAndContinue() {
      let promise;
      if (vm.isOnMarketSubSegmentForm) {
        promise = saveMarketSubSegment();
      } else {
        promise = provisionOffer(vm.displayedOffer);
      }
      promise
        .then(() => {
          if (vm.isWizardLastStep) {
            panelManager.close(vm.id);
          } else {
            vm.isConfirmAndContinueEnabled = false;
            $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
          }
        })
        .catch(() => {
          toastManager.showErrorToast();
        });
    }

    function onModalClose() {
      _.invoke(vm, 'onClosed', vm.onCloseResult);
    }

    function onModalOpen() {
      triggerAnalyticsOnOpen();
      initialize();
    }

    function onOfferFormChange(event) {
      _.assign(vm, {
        isConfirmAndContinueEnabled: event.areRequiredFieldsFilled,
      });
    }

    function onOrgTypeFormChange(event) {
      _.assign(vm, {
        hasAdditionalTerms: event.hasAdditionalTerms,
        isConfirmAndContinueEnabled: event.areRequiredFieldsFilled,
        marketSubsegments: event.marketSubsegments,
      });
    }

    ///////////

    function $onInit() {
      _.assign(vm, {
        id: getModalId(),
      });

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function initialize() {
      _.assign(vm, {
        isBusy: false,
        isConfirmAndContinueEnabled: false,
        stepTitles: _.map(vm.offerList.items, 'offer_id'),
        waitPromise: $q.resolve(),
      });

      processOffers();

      if (
        _.some(vm.offersToAdd, (offer) =>
          offer.hasQualifier([QUALIFIER_TARGET.FURTHER_EDU_ORG, QUALIFIER_TARGET.K12_EDU_ORG])
        )
      ) {
        const marketSubsegments = OrganizationList.get().activeOrg.getMarketSubsegments();
        vm.requiresOrganizationMarketSubSegment = _.isEmpty(marketSubsegments);
        vm.stepTitles = _.union(['orgType'], vm.stepTitles);
      }
      processPageDisplay(0);
    }

    function getModalId() {
      if (vm.modalId) {
        return vm.modalId;
      }
      return FREE_OFFER_MODAL_ID;
    }

    function onWizardChange(event, state) {
      processPageDisplay(state.stepIndex);
    }

    function processOffers() {
      let offersToAdd;
      // we copy the offer list, to avoid impacting other references
      // we also push the preferred offer to the front, to have it be the first to show
      const qualifiedPreferredOffer = _.find(vm.offerList.items, {
        offer_id: _.get(vm, 'preferredOffer.offer_id'),
      });
      if (qualifiedPreferredOffer) {
        offersToAdd = _.union([qualifiedPreferredOffer], vm.offerList.items);
      } else {
        offersToAdd = _.union([], vm.offerList.items);
      }
      _.assign(vm, {
        // we copy the offer list, to avoid impacting other references
        // we also push the preferred offer to the front, to have it be the first to show
        offersToAdd,
      });
    }

    function processPageDisplay(stepIdx) {
      vm.isWizardLastStep =
        stepIdx + 1 >= vm.offersToAdd.length + (vm.requiresOrganizationMarketSubSegment ? 1 : 0);

      vm.isOnMarketSubSegmentForm = vm.requiresOrganizationMarketSubSegment && stepIdx === 0;

      let modalTitleSuffix;
      if (vm.isOnMarketSubSegmentForm) {
        modalTitleSuffix = 'confirmMarketSubSegment';
      } else if (vm.offersToAdd.length > 0) {
        modalTitleSuffix = 'acceptOffer';
      } else {
        modalTitleSuffix = 'noOffers';
      }

      const productIdx = vm.requiresOrganizationMarketSubSegment ? stepIdx - 1 : stepIdx;
      vm.displayedOffer = vm.isOnMarketSubSegmentForm ? undefined : vm.offersToAdd[productIdx];

      vm.modalTitle = $translate.instant(
        `widgets.offers.freeOfferModal.titles.${modalTitleSuffix}`,
        {
          offerName: _.get(vm, 'displayedOffer.merchandising.copy.short_name'),
        }
      );
    }

    function provisionOffer(offer) {
      vm.isBusy = true;

      vm.waitPromise = Cart.provisionFreeOffer(offer)
        .then(() => {
          toastManager.showSuccessToast(
            $translate.instant('widgets.offers.freeOfferModal.toasts.freeOfferSuccess', {
              offerName: _.get(offer, 'merchandising.copy.short_name'),
            })
          );
        })
        .finally(() => {
          vm.isBusy = false;
        });
      return vm.waitPromise;
    }

    function saveMarketSubSegment() {
      vm.isBusy = true;

      vm.waitPromise = organizationTypeHelper
        .updateOrgMarketSubsegments(vm.marketSubsegments, {
          hasAdditionalTermsToAccept: vm.hasAdditionalTerms,
        })
        .then(() => vm.offerList.refresh())
        .then(() => {
          toastManager.showSuccessToast(
            $translate.instant('widgets.offers.freeOfferModal.toasts.organizationTypeSuccess')
          );
          // we re-process the offers here, as they may have changed as a result of the selection
          processOffers();
        })
        .finally(() => {
          vm.isBusy = false;
        });

      return vm.waitPromise;
    }

    /**
     * Dispatches analytics for opening the Organization Type modal.
     */
    function triggerAnalyticsOnOpen() {
      AnalyticsEvent.dispatch({
        componentMethod: 'addFreeOffer',
        componentMethodType: 'display',
        componentName,
      });
    }
  }
})();
