/**
 * @deprecated use src2 User modal
 *
 * @ngdoc component
 * @name app.widgets.users:appEditUserProfileModal
 *
 * @description modal for editing user and directory user details
 *
 * @param {Function} [onClose] Callback to be invoked when the modal is closed.
 * @param {Function} onRefresh Callback to be invoked when an action is peformed in this modal that
 *   warrants a refresh.
 * @param {PageContext} pageContext - Used for analytics and for determining whether to show a remove
 *   button in the modal's footer.
 * @param {User | DirectoryUser} user - User whose details will be edited.
 */
(function () {
  const componentName = 'appEditUserProfileModal';
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      onClose: '<?',
      onRefresh: '<',
      pageContext: '<',
      user: '=',
    },
    controller,
    templateUrl: 'app/widgets/users/edit-user-profile-modal/edit-user-profile-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    $state,
    $translate,
    _,
    AnalyticsEvent,
    DisplayMessage,
    EDIT_USER_PROFILE_MODAL_ID,
    EDIT_USER_PROFILE_OPEN,
    feature,
    ORGANIZATION_USER_ERROR_CODE,
    panelManager,
    toastManager
  ) {
    this.$onInit = $onInit;

    /////////////
    function $onInit() {
      const vm = this;
      let userBackUp = {};

      _.assign(vm, {
        closeModal,
        hidePageBanner,
        id: EDIT_USER_PROFILE_MODAL_ID,
        onClickRemoveUser,
        onModalClose,
        onModalOpen,
        promiseToWait: $q.resolve(),
        readyToSave,
        REMOVE_CONFIRMATION_MODAL_ID: 'remove-user-modal',
        removeUser,
        save,
        shouldShowRemoveButton:
          !vm.pageContext.isDirectoryTarget() && !vm.pageContext.isUserTargetType(),
        showPageBanner,
        userInfoForm: {},
      });

      function closeModal(restore) {
        vm.restore = restore;
        panelManager.close(vm.id);
      }

      function hidePageBanner() {
        vm.displayMessage = undefined;
      }

      function onClickRemoveUser() {
        panelManager.open(vm.REMOVE_CONFIRMATION_MODAL_ID);
      }

      function onModalClose() {
        if (vm.restore) {
          vm.user.firstName = userBackUp.firstName;
          vm.user.lastName = userBackUp.lastName;
          vm.user.email = userBackUp.email;
          vm.user.userName = userBackUp.userName;
          vm.user.countryCode = userBackUp.countryCode;
          vm.restore = false;
        }
        vm.userInfoForm.$setPristine();
        vm.picFile = undefined;
        // Only resetting avatar for non-T1 user, since T1 can't edit avatar
        if (!vm.user.getType().isType1()) {
          vm.resetAvatarInput();
        }
        hidePageBanner();
      }

      function onModalOpen() {
        userBackUp = _.cloneDeep(vm.user);
        $scope.$broadcast(EDIT_USER_PROFILE_OPEN);
      }

      function readyToSave() {
        return !!(
          vm.userInfoForm.$valid &&
          (vm.userInfoForm.$dirty || _.has(vm, 'picFile.path')) &&
          (vm.user.getType().isType1() ||
            _.find(vm.userInfoForm.countriesData, ['value', vm.user.countryCode]))
        );
      }

      function removeUser() {
        panelManager.close(vm.REMOVE_CONFIRMATION_MODAL_ID);
        vm.promiseToWait = vm.user.delete().then(() => {
          toastManager.showSuccessToast();
          $state.go('users.users.all-users', {});
        });
      }

      function save() {
        hidePageBanner();
        vm.promiseToWait = vm.user
          .save(vm.picFile)
          .then(() => {
            closeModal();
            toastManager.showSuccessToast();
            triggerAnalytics('editUserProfile');
          })
          .catch((error) => {
            const message =
              _.get(error, 'data.errorCode') === ORGANIZATION_USER_ERROR_CODE.USER_ALREADY_EXISTS
                ? $translate.instant(
                    'widgets.users.editUserProfileModal.errorMessage.emailOrUsernameAlreadyExists'
                  )
                : undefined;
            showPageBanner(message);
          });
      }

      function showPageBanner(message) {
        vm.displayMessage = new DisplayMessage({body: message});
      }

      function triggerAnalytics(componentMethod, attributes = {}) {
        AnalyticsEvent.dispatch({
          attributes,
          componentMethod,
          componentMethodType: 'submit',
          componentName,
          pageContext: vm.pageContext,
        });
      }
    }
  }
})();
