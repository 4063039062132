(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.products.purchase')
    .constant('PURCHASE_WAITSTR', String.fromCharCode(8211, 8211))
    .constant('LICENSE_WARNING_PERCENTAGE_THRESHOLD', 90);
})();
