import {
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  ORGANIZATION_USER_API_QUERY_PARAMS,
  ORG_ADMIN,
  OrganizationAdminList,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  PageContext,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
  feature,
} from '@admin-tribe/binky';
import {
  InfoItemScorecard,
  Page,
  PageBanners,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {ADMIN_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {
  canAssignAdmin,
  canRemoveAdmin,
  getManageableAdminRoles,
} from 'core/admin/access/adminAccess';

/**
 * The Administrators top-level page.
 */
const AdministratorsPage = observer(() => {
  const {orgId} = useParams();
  const [roles, setRoles] = useState(undefined);

  useDocumentTitle({key: 'users.administrators.documentTitle'});

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.ORGANIZATION,
      targetId: orgId,
      targetType: PAGE_TARGET_TYPE.ADMIN,
    })
  ).current;

  const adminStore = useStore(() => {
    const includeList = feature.isEnabled('temp_de_exclusion_40038')
      ? ORGANIZATION_USER_API_QUERY_PARAMS.FETCH_DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR
      : undefined;
    return new AdminStore({
      immediateLoad: true,
      listClassRef: OrganizationAdminList,
      listOptions: {
        include: includeList,
        roles: [
          COMPARTMENT_ADMIN,
          COMPARTMENT_VIEWER,
          DEPLOYMENT_ADMIN,
          LICENSE_ADMIN,
          // we intentionally omit LICENSE_DEV here as they're shown elsewhere
          ORG_ADMIN,
          CONTRACT_ADMIN,
          PRODUCT_ADMIN,
          // remove filter below when temp_parkour_mm is removed
          feature.isEnabled('temp_parkour_mm') ? PRODUCT_SUPPORT_ADMIN : undefined,
          STORAGE_ADMIN,
          SUPPORT_ADMIN,
          USER_GROUP_ADMIN,
        ].filter((role) => role),
      },
    });
  });

  const intl = useIntl();

  const getAdminRoles = useCallback(async () => {
    setRoles(await getManageableAdminRoles());
  }, []);

  // Get manageable admin roles. These are the roles shown in the 'Add admin' modal.
  useAsyncModel({loadFn: getAdminRoles});

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  return (
    <Page
      data-testid="administrators-page"
      isLoading={adminStore.isLoading && !adminStore.currentItems}
    >
      <PageBanners>
        <GlobalAdminPolicyBanner context={ADMIN_CONTEXT} />
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'users.administrators.title'})} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.administrators.scorecard.admins'})}
          value={adminStore.scorecardValue}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          addMemberProps={{roles}}
          canAddMember={roles?.length > 0 && canAssignAdmin()}
          canRemoveMember={canRemoveAdmin()}
          getIsItemSelectable={getIsItemSelectable}
          memberListDisplay={{
            adminRole: true,
            avatar: true,
            email: true,
            idType: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          store={adminStore}
        >
          <AdminListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default AdministratorsPage;
