import auth from 'core/services/auth';
import {goToSupport} from 'features/support/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to book an expert session
 */
class BookExpertSessionHva extends HvaCard {
  /**
   * @description Method to fetch the BookExpertSessionHva
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @returns {Promise<BookExpertSessionHva[]>} the fetched instance
   */
  static get({intl}) {
    if (!auth.isUserOrgAdmin() && !auth.isUserSupportDelegate()) {
      return Promise.reject();
    }
    return Promise.resolve([new BookExpertSessionHva({intl})]);
  }

  /**
   * @description Method to create an BookExpertSessionHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @return {BookExpertSessionHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.bookExpertSession';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_pop_artt_support_for_enterprise',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/expert-session.svg',
        style: {width: '40px'},
      },
      id: 'hva-book-expert-session',
      isDismissible: true,
      onCTA: goToSupport,
      priorityOrder: HVA_ORDER.BOOK_EXPERT_SESSION,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default BookExpertSessionHva;
