import help from '../../constants/help';

const {TOPICS} = help;

/**
 * @description Method to return configuration object for the products
 *   help topic
 * @returns content for products help topic
 */
const products = () => ({
  breadcrumbName: 'help.tab.products.title',
  content: [],
  id: TOPICS.PRODUCTS,
  order: 1,
  parents: [
    {
      id: TOPICS.GET_STARTED,
    },
  ],
  title: 'help.tab.products.title',
});

export default products;
