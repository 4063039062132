(function () {
  /**
   * @deprecated Please use src2/app/core/contract/auto-renewal/AutoRenewalConstants.js
   */
  angular
    .module('app.core.contract.auto-renewal.auto-renewal-helper')
    .constant('CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT', {
      IN_RENEWAL_WINDOW: 'IN_RENEWAL_WINDOW',
      NA: 'NA',
      POST_RENEWAL_WINDOW: 'POST_RENEWAL_WINDOW',
      PRE_RENEWAL_WINDOW: 'PRE_RENEWAL_WINDOW',
    });
})();
