import {DialogContainer} from '@adobe/react-spectrum';
import {TableActions, TableFilters, useTableSectionContext} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import RevokeTrusteeConfirmationModal from 'features/settings/common/components/revoke-trustee-confirmation-modal/RevokeTrusteeConfirmationModal';
import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';

const TrusteeListTableActions = () => {
  const intl = useIntl();
  const {trusteeList, reloadTrusteeList} = useTrusteeListContext();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();
  const [showRevokeTrusteeConfirmationModal, setShowRevokeTrusteeConfirmationModal] =
    useState(false);

  const canManageIdentityConfig = canChangeIdentityConfig();

  const onConfirm = () => {
    tableSectionUtils.clearSelectedItems();
    reloadTrusteeList();
  };

  return (
    <>
      <TableFilters
        isDisabled={trusteeList.hasLoadingError}
        label={intl.formatMessage({
          id: 'settings.trusteeTable.filters.search.label',
        })}
      />
      <TableActions>
        <ButtonWithContextualHelp
          contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
          data-testid="revoke-trustee-button"
          isDisabled={tableSectionState.selectedItems.length === 0 || !canManageIdentityConfig}
          onPress={() => setShowRevokeTrusteeConfirmationModal(true)}
          variant="cta"
        >
          <FormattedMessage id="settings.trusteeTable.actions.revokeTrustee" />
        </ButtonWithContextualHelp>
      </TableActions>
      <DialogContainer onDismiss={() => setShowRevokeTrusteeConfirmationModal(false)}>
        {showRevokeTrusteeConfirmationModal && (
          <RevokeTrusteeConfirmationModal
            onConfirm={onConfirm}
            trustees={tableSectionState.selectedItems}
          />
        )}
      </DialogContainer>
    </>
  );
};

TrusteeListTableActions.displayName = 'TrusteeListTableActions';

export default TrusteeListTableActions;
