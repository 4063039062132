import {AdobeStatusData} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the adobeStatus api to fetch a list of status data
 */
const useStatusData = () => {
  const loadStatusData = useCallback(async () => {
    const statusData = await AdobeStatusData.get();

    return statusData;
  }, []);

  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadStatusData,
  });

  return {error, isLoading, statusData: model};
};

export default useStatusData;
