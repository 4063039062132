(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').config(config);

  /* @ngInject */
  function config($stateProvider, _) {
    const modalId = 'overview-choose-org-type-modal';

    $stateProvider.state('overview.overview.choose-org-type', {
      data: {
        title: 'widgets.organizations.organizationType.title',
      },
      resolve: {
        modalId: _.constant(modalId),
        onClosed($state) {
          return function (result) {
            // unless modal is already transitioning to another state,
            // return to the parent state
            if (!_.has(result, 'transitionTo')) {
              $state.go('overview.overview2');
            }
          };
        },
        onInitialized(panelManager) {
          return () => panelManager.open(modalId);
        },
      },
      views: {
        'modal@overview.overview2': {
          component: 'appOrganizationTypeModal',
        },
      },
    });
  }
})();
