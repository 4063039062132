(function () {
  /**
   * @ngdoc module
   * @name app.core.user
   * @description Module which defines the User model
   */
  angular.module('app.core.user', [
    'app.core.analytics',
    'app.core.contact-sales',
    'app.core.organizations.organization-manager',
    'app.core.product.configurations',
    'app.core.storage.access',
    'app.core.user-group',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.product',
    'binky.core.user',
    'binky.widgets.products.product-name',
    'pascalprecht.translate',
  ]);
})();
