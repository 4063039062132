class LegacyProductRole {
  /**
   * @description The visual representation of a legacy product role that can be used in a Picker.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Boolean} [options.default=false] - one role will be defined with default set to true to indicate
   *   that this role is the default role
   * @param {String} options.id - A unique id for this role
   * @param {String} options.name - The display string for this role.
   */
  constructor(options) {
    this.default = options.default ?? false;
    this.id = options.id;
    this.name = options.name;
  }
}

export default LegacyProductRole;
