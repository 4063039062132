import {matchPath} from 'react-router-dom';

/**
 * Executes access checks on routes and returns all routes with passing checks.
 * Returns when all checks complete to ensure a collection of routes is is returned all at once.
 * If a route has no check then it will be returned by default.
 * @param {[{check?: boolean | Promise<boolean>, routes: (import('react-router').RouteObject)[]}]} routes
 * @returns {(import('react-router').RouteObject)[]}
 * @throws {Error} - Throws an error when an access check is rejected
 */
const getRoutes = async (routes) => {
  const results = await Promise.allSettled(routes.map((item) => item.check ?? true));

  return results.reduce((passedRoutes, currentRoute, idx) => {
    if (currentRoute.status === 'fulfilled' && currentRoute.value) {
      return [...passedRoutes, ...routes[idx].routes];
    }

    if (currentRoute.status === 'rejected') {
      throw new Error(`Access check failed on route: ${currentRoute.reason}`);
    }

    return passedRoutes;
  }, []);
};

/**
 * Generates sub routes for a page with tabs
 * @param {{PageComponent: import('react').ComponentType, routeConfig: import('react-router').RouteObject[]}} param0
 *
 * @example <caption>Creates two routes under /main-route, /main-route/tab1 and /main-route/tab2</caption>
 * const routes = [
 *   {
 *     children: createRoutesForTabs({
 *       element: <PageComponentWithTabsComponent />,
 *      routes: [{path: '/tab1'}, {path: '/tab2'}],
 *     }),
 *     path: 'main-route',
 *   }
 * ];
 *
 * @returns {import('react-router').RouteObject[]}
 */
const createRoutesForTabs = ({element, routes}) =>
  routes.map((config) => ({
    element,
    ...config,
  }));

/**
 * Determines if the request is an exact match for the route
 * @param {Location | URL | Request} urlOrRequest
 * @param {string} path
 * @returns {boolean}
 */
const isExactRouteMatch = (urlOrRequest, route) => {
  const url = urlOrRequest.pathname ? urlOrRequest : new URL(urlOrRequest.url);

  return matchPath(route, url.pathname) !== null;
};

/**
 * Determines if the request is an exact match for any of the routes
 * @param {Location | URL | Request} urlOrRequest
 * @param {string[]} routes
 * @returns {boolean}
 */
const isAnyExactRouteMatch = (urlOrRequest, routes) =>
  routes.some((route) => isExactRouteMatch(urlOrRequest, route));

export {createRoutesForTabs, getRoutes, isAnyExactRouteMatch, isExactRouteMatch};
