import {View} from '@adobe/react-spectrum';
import React, {useContext} from 'react';

import {PackageSummaryDrawerContext} from '../../PackageSummaryDrawerContextProvider';

import IncludedAppsList from './included-apps-list/IncludedAppsList';
import PackageDetails from './package-details/PackageDetails';

const PackageSummaryDrawerContentBody = () => {
  const {attributes} = useContext(PackageSummaryDrawerContext);

  return (
    <View>
      {attributes.includedApplications?.length > 0 && <IncludedAppsList />}
      {attributes.showPluginsSection && <IncludedAppsList showPlugins />}
      <PackageDetails />
    </View>
  );
};

export default PackageSummaryDrawerContentBody;
