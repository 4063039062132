(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AuthSource
   * @description Model for an AuthSource.
   */
  angular.module('app.core.ims-federated').factory('AuthSource', getAuthSourceModel);

  /* @ngInject */
  function getAuthSourceModel(
    $log,
    $q,
    _,
    apiUtils,
    AUTH_SOURCE_TYPES,
    Idp,
    IDP_TYPES,
    imsFederated
  ) {
    const DEFAULT_TYPE = AUTH_SOURCE_TYPES.FEDERATED;
    const EXTERNALLY_MANAGED_POLICY = 'EXTERNALLY_MANAGED';

    class AuthSource {
      /**
       * @description Simple constructor for new AuthSource models.
       *
       * @param {Object} [options]      Key/value options for AuthSource construction
       * @param {String} [options.id]   Unique id of the AuthSource
       * @param {String} [options.defaultIdp] Unique id of the IdP used to perform federation
       * @param {String} [options.name] Name of the AuthSource
       * @param {String} [options.type] Type of the AuthSource (see AUTH_SOURCE_TYPES)
       * @param {Object} [options.policyConfig] An object containing the policy configs for this AuthSource..
       * @param {String} [apiUtils.getActiveOrg] Unique Id of organization of the AuthSource
       * @param {Array}  [options.idps] List of IdPs this AuthSource has
       */
      constructor(options = {}) {
        this.id = options.id;
        this.defaultIdp = options.defaultIdp;
        this.name = options.name;
        this.type = options.type || DEFAULT_TYPE;
        this.idps = constructIdpsFromList(options.idps, options.id, options.defaultIdp);
        this.orgId = apiUtils.getActiveOrg();
        this.policyConfig = options.policyConfig;
      }

      /**
       * @description Adds a new IdP to this AuthSource only if the AuthSource exists,
       *              meaning that it has an id.
       *
       * @param {Object} idpOptions Key/value options for a new IdP (see Idp constructor).
       *
       * @returns {Promise<AuthSource>} The same AuthSource instance with the newly
       *                                created IdP added to the list of IdPs.
       */
      addIdp(idpOptions = {}) {
        if (!this.id) {
          return $q.reject(new Error(`Cannot create an IdP for an AuthSource that doesn't exist`));
        }

        return Idp.create(
          _.assign(idpOptions, {
            authSourceId: this.id,
          })
        ).then((savedIdp) => {
          this.idps.push(savedIdp);
          return this;
        });
      }

      /**
       * Returns an Azure IdP
       *
       * @return {Idp|undefined} The Azure IdP if it exists
       */
      getAzureIdp() {
        return _.find(this.idps, {federationType: IDP_TYPES.AZURE});
      }

      /**
       * Returns the IdP used to perform federation
       *
       * @return {Idp|undefined} The IdP used to perform federation if it exists
       */
      getDefaultIdp() {
        return _.find(this.idps, {id: this.defaultIdp});
      }

      /**
       * Returns a Generic SAML IdP
       *
       * @return {Idp|undefined} The Generic SAML IdP if it exists
       */
      getSamlIdp() {
        return _.find(this.idps, {federationType: IDP_TYPES.SAML});
      }

      /**
       * Checks to see if this AuthSource is marked as externally managed.
       * The existence of the policy is enough to mean it's marked, regardless of its value.
       * (i.e. EXTERNALLY_MANAGED policy with a value of 'false' would still be true).
       *
       * @return {boolean} True if the policy exists, false if it doesn't.
       */
      isExternallyManaged() {
        return Object.hasOwnProperty.call(this.policyConfig, EXTERNALLY_MANAGED_POLICY);
      }

      /**
       * Checks to see if this AuthSource is already created
       *
       * @return {boolean} True if it isn't already created, false if it already exists
       */
      isNew() {
        return !this.id;
      }

      /**
       * @description Removes an IdP of this AuthSource only if the AuthSource or IdP exist,
       *              meaning that they have an id and the AuthSource has more than one IdP
       * @param {String} idpId id of the IdP to be removed
       * @returns {Promise} A promise that resolves to the updated AuthSource
       */
      removeIdp(idpId) {
        if (!this.id) {
          return $q.reject(
            new Error(
              `Cannot remove an IdP ${idpId} for an AuthSource that doesn't exist in organization ${this.orgId}.`
            )
          );
        }

        if (!_.some(this.idps, {id: idpId})) {
          return $q.reject(
            new Error(
              `Cannot remove an IdP ${idpId} that doesn't exist in AuthSource ${this.id} in organization ${this.orgId}.`
            )
          );
        }

        if (this.idps.length === 1) {
          return $q.reject(new Error(`Cannot remove the only IdP in AuthSource`));
        }

        return imsFederated
          .idps(this.id)
          .delete({idpId})
          .$promise.then(() => {
            _.remove(this.idps, {id: idpId});
            return this;
          })
          .catch((error) => {
            $log.error(
              `Failed to remove IdP with id ${idpId} in AuthSource ${this.id} in organization ${this.orgId}. Error:`,
              error
            );
            return $q.reject(error);
          });
      }

      /**
       * @description Saves a new AuthSource
       *
       * @returns {Promise<AuthSource>} A promise that resolves to the same AuthSource
       *                                instance with the new properties from the API response.
       */
      save() {
        const parameters = this.isNew() ? {} : {authSourceId: this.id};
        return imsFederated.authSources
          .save(parameters, this.toMinimumModel())
          .$promise.then((response) => _.assign(this, response))
          .catch((error) => {
            $log.error('AuthSource failed to save. Error:', error);
            return $q.reject(error);
          });
      }

      /**
       * @description Method to set the IdP to perform federation, called default IdP.
       * @param {String} idpId id of the IdP to perform federation with
       * @returns {Promise<AuthSource>} A promise that resolves to the newly created AuthSource
       */
      setDefaultIdp(idpId) {
        if (!this.id) {
          return $q.reject(
            new Error(`Cannot set default IdP ${idpId} for an AuthSource that doesn't exist`)
          );
        }

        if (!_.some(this.idps, {id: idpId})) {
          return $q.reject(
            new Error(`Cannot set default IdP ${idpId} of an IdP that doesn't exist`)
          );
        }

        this.defaultIdp = idpId;
        return this.save();
      }

      /**
       * @description Returns the class action methods for a simple Object that
       *              contains only the neccessary properties used by the API
       *              to deal with an AuthSource
       *
       * @returns {Object} Minimum necessary representation of model
       */
      toMinimumModel() {
        return this.isNew()
          ? _.pick(this, ['id', 'name', 'type'])
          : _.pick(this, ['defaultIdp', 'name']);
      }

      /**
       * @description Creates a new AuthSource and saves it
       *
       * @param {Object} options The options for this new IdP, same as the constructor ones.
       *
       * @returns {Promise<AuthSource>} A promise that resolves to the newly created AuthSource
       */
      static create(options = {}) {
        const authSource = new AuthSource(options);
        return authSource.save();
      }

      /**
       * Fetches an AuthSource from server with the provided id.
       *
       * @param {String} authSourceId Id of the AuthSource to fetch
       *
       * @return {Promise<AuthSource>} A promise that resolves to an instance
       *                               of AuthSource with the fetched data
       */
      static get(authSourceId) {
        return imsFederated.authSources
          .get({authSourceId})
          .$promise.then((response) => new AuthSource(response))
          .catch((error) => {
            $log.error(`Failed to get AuthSource with id ${authSourceId}. Error:`, error);
            return $q.reject(error);
          });
      }
    }

    return AuthSource;

    /**
     * Constructs Idp models from a list of simple IdP objects
     *
     * @param {Object[]} list The list of simple IdP objects to construct from
     * @param {String}   authSourceId Id of the AuthSource that has the IdPs.
     * @param {String}   defaultIdp Id for IdP that is used to perform
     *                              federation, called default IdP.
     * @return {Idp[]} The constructed Idp models list
     */
    function constructIdpsFromList(list, authSourceId, defaultIdp) {
      return _.map(list, (idp) =>
        Idp.constructFromResponseObject(_.assign(idp, {authSourceId, defaultIdp}))
      );
    }
  }
})();
