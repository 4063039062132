import {AuthenticatedUser} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

import {MILLISECONDS_IN_A_DAY, SERVER_SCREEN_NAME_CONSTANTS} from '../packagesConstants';

const createServerFlow = {
  steps: {
    0: SERVER_SCREEN_NAME_CONSTANTS.AUTHORIZE_SERVER,
    1: SERVER_SCREEN_NAME_CONSTANTS.CONFIGURE_SERVER,
    2: SERVER_SCREEN_NAME_CONSTANTS.SERVER_INFO,
    3: SERVER_SCREEN_NAME_CONSTANTS.SERVER_ENTITLEMENTS,
    4: SERVER_SCREEN_NAME_CONSTANTS.SERVER_FINALIZE,
  },
};

const editServerFlow = {
  steps: {
    0: SERVER_SCREEN_NAME_CONSTANTS.CONFIGURE_SERVER,
    1: SERVER_SCREEN_NAME_CONSTANTS.SERVER_INFO,
    2: SERVER_SCREEN_NAME_CONSTANTS.SERVER_ENTITLEMENTS,
    3: SERVER_SCREEN_NAME_CONSTANTS.AUTHORIZE_SERVER,
    4: SERVER_SCREEN_NAME_CONSTANTS.SERVER_FINALIZE,
  },
};

/**
 * @description Function for re-setting server session data
 */
const resetServerSessionDataValues = () => {
  const serverSessionData = {
    activationEnforcementType: null,
    authorizationFile: null,
    authorizationFileIdList: [],
    displayName: '',
    dnsAddress: '',
    entitlementLimits: '',
    lanTimeout: '0',
    organizationName: rootStore.organizationStore.activeOrg.name,
    selectedLicenses: [],
    serverId: null,
  };
  const serverSessionSettings = {
    createServerModalId: null,
    currentScreen: createServerFlow.steps[0],
    currentScreenIndex: 0,
    editServerWorkflow: false,
    isCreateServerButtonVisible: null,
    isPreviousButtonDisabled: true,
    modalTitle: null,
    nextButtonEnabled: false,
    reauthorizeServerWorkflow: null,
    showQuotaRadioButtons: false,
  };
  return {serverSessionData, serverSessionSettings};
};

/**
 * @description Function for setting edit server session data
 */
const setServerObjectForEditFlow = (serverObject) => {
  const serverSessionData = {
    activationEnforcementType: serverObject.activationEnforcementType,
    authorizationFileIdList: [],
    displayName: serverObject.name,
    dnsAddress: serverObject.dns,
    lanTimeout: `${serverObject.lanTimeout / MILLISECONDS_IN_A_DAY}`,
    organizationName: serverObject.branding,
    selectedLicenses: serverObject.licenses,
    serverId: serverObject.serverId,
  };
  const serverSessionSettings = {
    currentScreen: editServerFlow.steps[0],
    currentScreenIndex: 0,
    editServerWorkflow: true,
    isPreviousButtonDisabled: true,
    nextButtonEnabled: true,
  };
  return {serverSessionData, serverSessionSettings};
};

/**
 * @description Function for setting server data
 */
const getCreateServerObject = (serverSessionData) => {
  const fiMap = {};
  const serverCreateData = {
    activationEnforcementType: serverSessionData.activationEnforcementType,
    authReportFileIds: serverSessionData.authorizationFileIdList,
    branding: {
      name: serverSessionData.organizationName,
    },
    displayName: serverSessionData.displayName,
    lanTimeout: serverSessionData.lanTimeout * MILLISECONDS_IN_A_DAY,
    // build license data for server
    licenses: serverSessionData.selectedLicenses.map((offer) => {
      offer.getPackageableFulfillableItems().forEach((fiJson) => {
        fiMap[fiJson.code] = JSON.stringify(fiJson);
      });
      return {
        apps: fiMap,
        licenseFullName: offer.longName,
        licenseId: offer.id,
        licenseShortName: offer.shortName,
      };
    }),
    orgId: rootStore.organizationStore.activeOrg.id,
    rootUrl: serverSessionData.dnsAddress,
    userId: AuthenticatedUser.get().getId(),
  };

  return serverCreateData;
};

/**
 * @description Function for setting initial server state
 */
const initialStateData = {
  createServerFlow,
  editServerFlow,
  serverSessionData: {
    activationEnforcementType: null,
    authorizationFile: null,
    authorizationFileIdList: [],
    displayName: '',
    dnsAddress: '',
    entitlementLimits: '',
    lanTimeout: '0',
    organizationName: '',
    selectedLicenses: [],
    serverId: null,
  },
  serverSessionSettings: {
    createServerModalId: null,
    currentScreen: createServerFlow.steps[0],
    currentScreenIndex: 0,
    editServerWorkflow: false,
    isCreateServerButtonVisible: null,
    isPreviousButtonDisabled: true,
    modalTitle: null,
    nextButtonEnabled: false,
    nextStepBannerBody: null,
    reauthorizeServerWorkflow: null,
    showNextStepBanner: false,
    showQuotaRadioButtons: false,
  },
};
export {
  resetServerSessionDataValues,
  setServerObjectForEditFlow,
  getCreateServerObject,
  initialStateData,
};
