import {Content, ContextualHelp, Divider, Flex, Footer, Heading, Text} from '@adobe/react-spectrum';
import {DirectoryDomainEnforcementStatus} from '@pandora/react-directory-domain-enforcement-status';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const TrustDirectoryDeStatus = ({domainEnforcement}) => (
  <>
    <Flex alignItems="center" gap="size-100">
      <Heading level={3}>
        <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.domainEnforcement.heading" />
      </Heading>
      <ContextualHelp placement="top" variant="info">
        <Heading>
          <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.domainEnforcement.contextualHelp.title" />
        </Heading>
        <Content>
          <Text>
            <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.domainEnforcement.contextualHelp.text" />
          </Text>
        </Content>
        <Footer>
          <GoUrl name="aac_restricting_domains">
            <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.domainEnforcement.contextualHelp.learnMore" />
          </GoUrl>
        </Footer>
      </ContextualHelp>
    </Flex>
    <Divider size="S" />
    <DirectoryDomainEnforcementStatus
      // stateEndsAt is when the notify state ends and domainEnforcement is enabled
      enablementDate={domainEnforcement?.stateEndsAt}
      marginStart="-10px"
      marginTop="size-150"
      state={domainEnforcement.state}
    />
    <Text>
      <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.domainEnforcement.text" />
    </Text>
  </>
);

TrustDirectoryDeStatus.propTypes = {
  domainEnforcement: PropTypes.shape({
    /**
     * The ID of the Directory
     */
    authSrc: PropTypes.string.isRequired,
    /**
     * Date when opt-out phase ends
     */
    notifyAt: PropTypes.number,
    /**
     * Current status of the Policy
     */
    state: PropTypes.string.isRequired,
    /**
     *  Date when the current state phase ends
     */
    stateEndsAt: PropTypes.number,
  }),
};

export default TrustDirectoryDeStatus;
