(function () {
  /**
   * @deprecated use src2
   */
  angular
    .module('app.core.cart')
    .constant('CART_ERROR_STATUS', {
      AUTH_ERROR: 'AUTH_ERROR', // artificial error to generalize orders api authorization error
      DUPLICATE_PURCHASE_ORDER_NUMBER: 'DUPLICATE_PURCHASE_ORDER_NUMBER',
      EXCEED_INDIRECT_CONTRACT_PER_PRODUCT_LIMIT:
        'INDIRECT_CONTRACT_MAX_NUMBER_OF_SEATS_PER_PRODUCT',
      EXCEED_INDIRECT_CONTRACT_PRODUCTS_LIMIT:
        'INDIRECT_CONTRACT_MAX_NUMBER_OF_SEATS_ACROSS_PRODUCTS',
      MAX_NUMBER_OF_SEATS: 'MAX_NUMBER_OF_SEATS',
    })
    .constant('CART_STATUS', {
      PENDING: 'PENDING',
      SUBMIT: 'SUBMIT',
    })
    .constant('CART_TYPE', {
      REGULAR: 'REGULAR',
      RENEWAL: 'RENEWAL',
      RENEWAL_QUOTE: 'RENEWAL_QUOTE',
    })
    .constant('ORDER_TYPE', {
      RENEWAL_ORDER: 'RENEWAL_ORDER',
    })
    .constant('PAYLOAD_KEY', {
      BILLABLE_ITEMS: 'billable_items',
      BILLING_SUMMARY: 'billing_summary',
      CART_ID: 'id',
      CART_TYPE_KEY: 'cart_type',
      CONTRACT_DETAILS: 'contract_details',
      COUNTRY: 'country',
      CURRENCY: 'currency',
      EXTERNAL_CONTRACT_ID: 'external_contract_id',
      IGNORE_OFFER_MAPPING: 'ignore_offer_mapping',
      LAST_MODIFIED_BY_ID: 'last_modified_by_id',
      OFFER_ID: 'offer_id',
      OWNER_ID: 'owner_id',
      PAYMENT_AUTHORIZATION: 'payment_authorization',
      PO_NUMBER: 'customer_po_number',
      PRODUCT_ARRANGEMENT_CODE: 'product_arrangement_code',
      PROGRAM_CONTRACT_ID: 'id',
      QUANTITY: 'quantity',
      STATUS: 'status',
      VALIDATION_TOKEN: 'validation_token',
    })
    .constant('QUERY_PARAM_KEY', {
      CONTRACT_ID: 'contract-id',
      ORDER_TYPE: 'order-type',
    });
})();
