import PropTypes from 'prop-types';
import React from 'react';

import CreatePackageCardCheckbox from '../../create-package-card-checkbox-group/CreatePackageCardCheckbox';

// ActivationTypeCard is CreatePackageCard with values populated accordingly to be used in ChooseActivationType screen
const ActivationTypeCard = ({
  activationDescription,
  autoFocus,
  buttonString = '',
  downloadMenuItems,
  learnMoreAriaLabel,
  learnMoreString,
  name,
  onDownloadItemPress,
  title,
  value,
}) => (
  <CreatePackageCardCheckbox
    autoFocus={autoFocus}
    buttonString={buttonString}
    cardMenuItems={downloadMenuItems}
    height="100%"
    learnMoreAriaLabel={learnMoreAriaLabel}
    learnMoreString={learnMoreString}
    minHeight="size-3000"
    name={name}
    onCardMenuItemPress={onDownloadItemPress}
    title={title}
    titleDescription={activationDescription}
    value={value}
    width="size-3400"
  />
);

ActivationTypeCard.propTypes = {
  /**
   * String that gives the description for the activation type card, displayed directly under title
   */
  activationDescription: PropTypes.string,
  /**
   * Whether the element will be auto focused.
   */
  autoFocus: PropTypes.bool.isRequired,
  /**
   * String to be displayed on the button of the card
   */
  buttonString: PropTypes.string,
  /**
   * Array of items to populate card menu
   */
  downloadMenuItems: PropTypes.arrayOf(
    PropTypes.shape({displayString: PropTypes.string.isRequired, key: PropTypes.string.isRequired})
  ),
  /**
   * Aria label of learn more links
   */
  learnMoreAriaLabel: PropTypes.string,
  /**
   * String for the GoUrl learn more link
   */
  learnMoreString: PropTypes.string,
  /**
   * String passed to GoUrl name prop
   */
  name: PropTypes.string,
  /**
   * Function to be called when download menu item pressed
   */
  onDownloadItemPress: PropTypes.func,
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Package type the card represents
   */
  value: PropTypes.string.isRequired,
};

export default ActivationTypeCard;
