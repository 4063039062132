(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:organizationInfoToLaunchTranslator
   * @description Translate OrganizationInfoDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('organizationInfoToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, descriptor) {
      const {setProperty} = launchContext;

      setProperty(
        'organization.assignedLicenseVariation',
        _.get(descriptor, 'assignedLicenseVariation')
      );
      setProperty('organization.totalUserCount', _.get(descriptor, 'totalCount'));
      setProperty('organization.userCountVariation', _.get(descriptor, 'userCountVariation'));
    }
  }
})();
