import TrusteeEntity from 'common/entities/TrusteeEntity';

class TrusteeListModel {
  static fromObject(data) {
    return new TrusteeListModel(data);
  }

  constructor(trusteeListData) {
    if (trusteeListData) {
      this.items = trusteeListData.data.map((trustee) => new TrusteeEntity(trustee));

      this.numberOfDirectories = trusteeListData.totalCount;
      this.totalPages = Math.ceil(this.numberOfDirectories / trusteeListData.pageSize);
    } else {
      // for the initial state trusteeListData is null
      this.items = [];

      this.numberOfDirectories = 0;
      this.totalPages = 1;
    }
  }
}

export default TrusteeListModel;
