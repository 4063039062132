import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {DeviceAction} from '@pandora/react-data-source-daco';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

import ConfirmationDialog from '../../confirmation-dialog/ConfirmationDialog';
import {BUTTON_VARIANT} from '../DeviceDetailsConstants';
import {useDeviceDetailsContext} from '../context/DeviceDetailsContext';
import {getIntlActionId} from '../utils/deviceDrawerUtils';

const ACTIVATION_NAMESPACE = 'products.deviceLicenses.deviceDetails.activationSection';
const DEFAULT_DEVICE_COUNT_FOR_DRAWER = 1;

/**
 * Component to display Activate/Block/Remove button in device details drawer
 */
const ActivationButton = ({action, 'data-testid': dataTestId}) => {
  const intl = useIntl();
  const {deviceDetails} = useDeviceDetailsContext();
  const {canActivateDevices} = useDeviceLicensesPageContext();
  const {licenseConfigId, licensingMode} = deviceDetails;
  const intlActionId = getIntlActionId({action, namespace: ACTIVATION_NAMESPACE});
  return (
    <DialogTrigger>
      <Button
        data-testid={dataTestId}
        isDisabled={action === DeviceAction.ACTIVATE && !canActivateDevices}
        variant={BUTTON_VARIANT[action]}
      >
        {intl.formatMessage(
          {id: `${intlActionId}.buttonName`},
          {deviceCount: DEFAULT_DEVICE_COUNT_FOR_DRAWER}
        )}
      </Button>
      {(closeConfirmationDialog) => (
        <ConfirmationDialog
          action={action}
          close={closeConfirmationDialog}
          deviceDetailsList={[deviceDetails]}
          dialogNamespace={ACTIVATION_NAMESPACE}
          licenseGroupId={licenseConfigId}
          licensingMode={licensingMode}
        />
      )}
    </DialogTrigger>
  );
};

ActivationButton.propTypes = {
  /**
   * Activate, Deactivate, or Remove action
   */
  action: PropTypes.oneOf(Object.values(DeviceAction)).isRequired,

  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
};

export default ActivationButton;
