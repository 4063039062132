import {Cell, Column, Link, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AddItemEmptyState from 'common/components/add-item-empty-state/AddItemEmptyState';
import {REPORT_FOLDER_TYPE} from 'common/services/storage-report/StorageReportConstants';

import FolderIconWithName from '../folder-icon-with-name/FolderIconWithName';

/**
 * The table for the 'Storage reports' page under the Storage tab.
 */
const ReportFolderListTable = ({list = [], navigationCallback}) => {
  const intl = useIntl();

  return (
    <TableView
      aria-label={intl.formatMessage({id: 'storage.storageReports.table.ariaLabel'})}
      density="spacious"
      minHeight={list.length === 0 ? 'size-6000' : undefined}
      renderEmptyState={() => (
        <AddItemEmptyState
          heading={intl.formatMessage({
            id: 'storage.storageReports.table.emptyState.heading',
          })}
          message={intl.formatMessage({
            id: 'storage.storageReports.table.emptyState.message',
          })}
        />
      )}
      selectionMode="none"
    >
      <TableHeader>
        <Column key="folderName">
          <FormattedMessage id="storage.storageReports.table.column.folders" />
        </Column>
        <Column key="lastUpdated">
          <FormattedMessage id="storage.storageReports.table.column.lastUpdated" />
        </Column>
      </TableHeader>
      <TableBody items={list}>
        {(item) => (
          <Row key={item.folderType}>
            <Cell>
              <FolderIconWithName>
                <Link onPress={() => navigationCallback(item.folderType)}>{item.folderName}</Link>
              </FolderIconWithName>
            </Cell>
            <Cell>{item.lastUpdated}</Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

ReportFolderListTable.propTypes = {
  /**
   * Callback to go to the 'Storage details' page for the given folder type.
   * It takes one param which is of type REPORT_FOLDER_TYPE - either 'assets' or 'users'.
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The display name for the folder.
       */
      folderName: PropTypes.string.isRequired,
      /**
       * Determines whether the modal refers to creation of shared storage or individual user folders.
       * If undefined, the modal will prompt for the folderType of the report.
       */
      folderType: PropTypes.oneOf([REPORT_FOLDER_TYPE.ASSETS, REPORT_FOLDER_TYPE.USERS]).isRequired,
      /**
       * The formatted date string of when the folder was last updated.
       */
      lastUpdated: PropTypes.string.isRequired,
    })
  ),
  /**
   * Callback to go to the 'Storage details' page for the given folder type.
   * It takes one param which is of type REPORT_FOLDER_TYPE - either 'assets' or 'users'.
   */
  navigationCallback: PropTypes.func.isRequired,
};

export default ReportFolderListTable;
