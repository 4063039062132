import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Form,
  Heading,
  Radio,
  RadioGroup,
  Text,
  TextField,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import Alert from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {UPDATE_PACKAGE_TYPE} from 'features/packages/packagesConstants';
import CreatePackageService from 'features/packages/services/CreatePackageService';
import {isPackageNameValid} from 'features/packages/stores/createPackageModalStoreUtils';

const UpdatePackage = ({onCancel, packageObj}) => {
  const intl = useIntl();
  const updatePackageDialog = useDialogContainer();

  const [name, setName] = useState(packageObj.name);
  const [packageType, setPackageType] = useState(UPDATE_PACKAGE_TYPE.UPDATE_ONLY);
  const [disableBuild, setDisableBuild] = useState(!isPackageNameValid(name));

  const handleCancel = () => (onCancel ? onCancel() : updatePackageDialog.dismiss());

  const buildUpdatedPackage = () => {
    CreatePackageService.buildUpdatedPackage(
      packageObj,
      name,
      packageType === UPDATE_PACKAGE_TYPE.UPDATE_ONLY
    );
    handleCancel();
  };

  const handleInputChange = (updatedName) => {
    setName(updatedName);
    setDisableBuild(!isPackageNameValid(updatedName));
  };

  return (
    <Dialog id="update-package-dialog" width="size-6000">
      <Heading>{intl.formatMessage({id: 'packages.updatePackage.title'})}</Heading>
      <Divider />
      <Content marginBottom="size-100">
        <Form>
          <Flex alignItems="self-end" direction="row">
            <TextField
              autoFocus
              data-testid="package-name-input"
              label={intl.formatMessage({id: 'packages.updatePackage.nameHeading'})}
              maxWidth="size-2400"
              onChange={(updatedName) => {
                handleInputChange(updatedName);
              }}
              type="text"
              value={name}
            />
            {packageObj.name.length > 0 && packageObj.name.length <= 40 && disableBuild && (
              <TooltipButton
                data-testid="error-in-package-name-tooltip"
                hoverText={intl.formatMessage({
                  id: 'packages.updatePackage.errorInPackageName',
                })}
                isQuiet
                tooltipDelayMs="0"
                variant="action"
              >
                <Alert color="negative" size="S" />
              </TooltipButton>
            )}
            {packageObj.name.length > 40 && (
              <TooltipButton
                data-testid="path-too-long-tooltip"
                hoverText={intl.formatMessage({
                  id: 'packages.updatePackage.pathTooLong',
                })}
                isQuiet
                tooltipDelayMs="0"
                variant="action"
              >
                <Alert color="negative" size="S" />
              </TooltipButton>
            )}
          </Flex>

          <Text marginTop="size-250" UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
            {intl.formatMessage({id: 'packages.updatePackage.updateConfigTitle'})}
          </Text>

          <RadioGroup
            aria-label={intl.formatMessage({id: 'packages.updatePackage.radioGroup.ariaLabel'})}
            defaultValue={packageType}
            isEmphasized
            onChange={setPackageType}
          >
            <Radio value={UPDATE_PACKAGE_TYPE.UPDATE_ONLY}>
              {intl.formatMessage({id: 'packages.updatePackage.updateOnlyConfig'})}
            </Radio>
            <Radio value={UPDATE_PACKAGE_TYPE.COMPLETE}>
              {intl.formatMessage({id: 'packages.updatePackage.updateCompletePackageConfig'})}
            </Radio>
          </RadioGroup>
        </Form>

        <Flex direction="row" marginTop="size-200">
          <Text>
            <Text>
              {intl.formatMessage(
                {id: 'packages.updatePackage.tip'},
                {
                  b: (chunks) => <Text UNSAFE_style={{fontWeight: 'bold'}}>{chunks}</Text>,
                }
              )}
              :
            </Text>
          </Text>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button data-testid="cancel-button" onPress={handleCancel} variant="secondary">
          {intl.formatMessage({id: 'packages.updatePackage.cancel'})}
        </Button>
        <Button
          data-testid="update-button"
          isDisabled={disableBuild}
          onPress={buildUpdatedPackage}
          variant="accent"
        >
          {intl.formatMessage({id: 'packages.updatePackage.build'})}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

UpdatePackage.propTypes = {
  /**
   * Function to call when the dialog is closed
   */
  onCancel: PropTypes.func,

  /**
   * Package Object
   */
  packageObj: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdatePackage;
