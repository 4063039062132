import auth from 'core/services/auth';
import {goToConsoleSettings} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to set policies
 */
class SetPoliciesHva extends HvaCard {
  /**
   * @description Method to fetch the SetPoliciesHva
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @returns {Promise<SetPoliciesHva[]>} the fetched instance
   */
  static get({intl}) {
    if (!auth.isUserOrgAdmin()) {
      return Promise.reject();
    }
    return Promise.resolve([new SetPoliciesHva({intl})]);
  }

  /**
   * @description Method to create an SetPoliciesHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @return {SetPoliciesHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.setPolicies';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_set_policies',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/policies.svg',
        style: {width: '40px'},
      },
      id: 'hva-set-policies',
      isDismissible: true,
      onCTA: goToConsoleSettings,
      priorityOrder: HVA_ORDER.SET_POLICIES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default SetPoliciesHva;
