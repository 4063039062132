(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support.component:appSupportTicketAddComment
   * @description Component that contains controls and components to allow users
   *   to add comments to Support Tickets.
   * @param {Function} onCancel - callback to signal when this component has
   *   been canceled
   * @param {Function} onSuccess - callback to signal when this component has
   *   added comments successfully
   * @param {SupportTicket} supportTicket - reference to SupportTicket service
   *   instance that comments should be added to
   */
  angular.module('app.widgets.support').component('appSupportTicketAddComment', {
    bindings: {
      onCancel: '&',
      onSuccess: '&',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-add-comment/support-ticket-add-comment.component.html',
  });

  /* @ngInject */
  function controller($q, $translate, _, feature, toastManager) {
    const vm = this;

    const COMMENT_MAX_LEN = 5000;

    _.assign(vm, {
      $onInit,
      addComment,
      COMMENT_MAX_LEN,
      isCommentInvalid,
      onCommentCancel,
      onCommentTextAreaChange,
    });

    /**
     * @description Method to initialize component.
     */
    function $onInit() {
      setInitialState();
    }

    /**
     * @description Method to add a new comment to a Support Ticket.
     */
    function addComment() {
      vm.uploadInProgress = true;
      vm.addCommentProgressPromise = vm.supportTicket
        .addComment(vm.comment)
        .then(onAddCommentSuccess, onAddCommentError)
        .finally(() => {
          vm.uploadInProgress = false;
        });
    }

    /**
     * @description Method to determine whether or not the comment added is
     *   valid or not. Currently, invalid comments are ones that only contain
     *   white space.
     * @return {Boolean} true if comment is valid, else false
     */
    function isCommentInvalid() {
      return _.trim(vm.comment).length === 0;
    }

    /**
     * @description Method to handle resetting component state prior to calling
     *   controlling component's (parent's) onCancel callback.
     */
    function onCommentCancel() {
      setInitialState();
      vm.onCancel();
    }

    /**
     * @description Method to detect if/when the maximum comment length has been
     *   reached and comment character limit message should be displayed.
     */
    function onCommentTextAreaChange() {
      vm.isCommentCharLimit = _.get(vm.comment, 'length', 0) >= COMMENT_MAX_LEN;
    }

    /////////////

    /**
     * @description Method to handle case when comment fails to be added to the
     *   Support Ticket.
     */
    function onAddCommentError() {
      toastManager.showErrorToast(
        $translate.instant('widgets.support.supportTicketAddComment.toast.error')
      );
    }

    /**
     * @description Method to handle case when comment is  added to the Support
     *   Ticket successfully.
     */
    function onAddCommentSuccess() {
      toastManager.showSuccessToast(
        $translate.instant('widgets.support.supportTicketAddComment.toast.success')
      );
      setInitialState();
      vm.onSuccess();
    }

    /**
     * @description Method to set up the initial state for this component data.
     */
    function setInitialState() {
      vm.addCommentProgressPromise = $q.resolve();
      vm.comment = '';
      vm.isCommentCharLimit = false;
    }
  }
})();
