(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ApplicationLink
   * @description Model for a application link
   */
  angular.module('app.shell.navigation.links').factory('ApplicationLink', getApplicationLinkModel);

  /* @ngInject */
  function getApplicationLinkModel(
    $q,
    $state,
    $timeout,
    _,
    globalModalsManager,
    Link,
    LINK_TARGET_TYPE,
    panelManager,
    stateManager
  ) {
    class ApplicationLink extends Link {
      /**
       * @description Creates a new ApplicationLink for use.
       * @param {Object} options - See super class for other parameters
       * @param {Object} [options.params] - any key/value params that need to
       *   accompany state changes (application links)
       */
      constructor(options = {}) {
        super(options);
        _.assign(this, _.pick(options, ['params']));
      }

      /**
       * @description Method to navigate to the locator described by this link.
       */
      goToLink() {
        switch (this.target) {
          case LINK_TARGET_TYPE.APPLICATION:
            $state.go(this.locator, this.params);
            break;
          case LINK_TARGET_TYPE.APPLICATION_MODAL:
            $state.go(this.locator[0], this.params).finally(() => {
              $timeout(() => {
                panelManager.open(this.locator[1]);
              });
            });
            break;
          case LINK_TARGET_TYPE.GLOBAL_MODAL:
            globalModalsManager.open(this.locator);
            break;
          default:
            _.noop();
        }
      }

      /**
       * @description Method to determine whether or not the locator described
       *   by this link is available (navigable) by the current user.
       * @returns {Promise} resolved with true if link is available, else false
       */
      isAvailable() {
        if (this.target === LINK_TARGET_TYPE.GLOBAL_MODAL) {
          // Global modals are created on the fly, so return true to make link always available.
          return $q.resolve(true);
        } else if (this.target === LINK_TARGET_TYPE.APPLICATION) {
          return $q.resolve(stateManager.isStateEnabled(this.locator));
        } else if (this.target === LINK_TARGET_TYPE.APPLICATION_MODAL) {
          return $q.resolve(
            stateManager.isStateEnabled(this.locator[0]) &&
              panelManager.isRegistered(this.locator[1])
          );
        }
        return $q.resolve(false);
      }
    }

    return ApplicationLink;
  }
})();
