import {OrganizationUser} from '@admin-tribe/binky';
import {DetailSection, NavigationAnchor, getMemberDisplayName} from '@admin-tribe/binky-ui';
import {Flex, Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {getHrefForAdminDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

/**
 * The Admins detail section. Shows list of admins, each linked to the their detail page.
 */
const AdminsDetailSection = ({admins, isLoading, headingLevel = 3, showError}) => {
  const intl = useIntl();

  return (
    <DetailSection
      data-testid="admins-detail-section"
      headingLevel={headingLevel}
      isLoading={isLoading}
      showError={showError}
      title={intl.formatMessage({id: 'common.adminsDetailSection.header'})}
    >
      <Flex direction="column" rowGap="size-50">
        {admins?.map((admin) => (
          <Link key={admin.id}>
            <NavigationAnchor href={getHrefForAdminDetails({userId: admin.id})}>
              <span>{getMemberDisplayName(intl, admin, {fallbackToEmail: true})}</span>
            </NavigationAnchor>
          </Link>
        ))}
        {admins?.length === 0 && <FormattedMessage id="common.adminsDetailSection.noAdmins" />}
      </Flex>
    </DetailSection>
  );
};

AdminsDetailSection.propTypes = {
  /**
   * The list of admins
   */
  admins: PropTypes.arrayOf(PropTypes.instanceOf(OrganizationUser).isRequired),
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * If true, a wait spinner will be shown in the content area.
   * children and errorText are not shown. The DetailSection default is false.
   */
  isLoading: PropTypes.bool,
  /**
   * If true, a default error message will be shown.
   * If not specified, the DetailSection defaults is false.
   */
  showError: PropTypes.bool,
};

export default AdminsDetailSection;
