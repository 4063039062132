import {Flex, Text, View} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ServerDrawerEntitlements = ({licenses}) => {
  const intl = useIntl();

  return (
    <TitledSection
      marginTop="size-500"
      title={intl.formatMessage({
        id: 'packages.serverSummary.entitlements',
      })}
    >
      <View marginStart="size-200">
        {licenses.map((license) => (
          <Flex key={license.offerId} alignItems="center" gap="size-150" marginTop="size-100">
            <ImageIcon alt="" size="M" src={license.getIconUrl()} />
            <Text>{license.longName}</Text>
          </Flex>
        ))}
      </View>
    </TitledSection>
  );
};

ServerDrawerEntitlements.propTypes = {
  /**
   * Content to be shown in Server Drawer Entitlements section
   */
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * String to represent cloud
       */
      cloud: PropTypes.string,
      /**
       * List of fi items
       */
      fulfillableItems: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           *  Fi code
           */
          code: PropTypes.string,
          /**
           *  Fi item type
           */
          fulfillableItemType: PropTypes.string,
        })
      ),
      /**
       *  Function to get url of icon
       */
      getIconUrl: PropTypes.func,
      /**
       *  Function to get packageable items
       */
      getPackageableFulfillableItems: PropTypes.func,
      /**
       *  Function to get packageable product sapcode
       */
      getPackageableProductSapCodes: PropTypes.func,
      /**
       *  String to represent full name of the server
       */
      longName: PropTypes.string,
      /**
       *  String to represent offer id of the server
       */
      offerId: PropTypes.string,
      /**
       *  Boolean to represent selection of server
       */
      selected: PropTypes.bool,
    })
  ),
};

export default ServerDrawerEntitlements;
