import {Content, ContextualHelp, Flex, Link, Switch, Text, View} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import LockClosed from '@spectrum-icons/workflow/LockClosed';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import IncludeListForm from 'common/components/include-list-form/IncludeListForm';

import {includePermissionPropType} from '../../../prop-types/configurationGroupsPropTypes';

const ItemRenderer = ({item}) => (
  <Text data-testid="permission-item" textValue={item.name}>
    {item.name}
    {item.description && (
      <ContextualHelp marginStart="size-100" variant="info">
        <Content>
          <Text>{item.description}</Text>
        </Content>
      </ContextualHelp>
    )}
  </Text>
);

ItemRenderer.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

const PermissionsIncludeListForm = ({
  allowAutoInclude = false,
  autoInclude = false,
  groupHelpLink,
  onChange,
  permissions,
  productName,
  readOnly = false,
}) => {
  const intl = useIntl();

  // Add the keys param to each value as required by the IncludeListForm.
  const valuesWithKeys = permissions.map((item) => ({...item, key: item.sortIndex}));

  const setIncluded = (valuesToSet, included) => {
    const itemsKeys = valuesToSet.map((item) => item.sortIndex);
    const newValuesWithKeys = valuesWithKeys.map((item) =>
      itemsKeys.includes(item.sortIndex)
        ? {
            ...item,
            included,
          }
        : item
    );

    // Remove the keys before passing it to the callback.
    const newValues = newValuesWithKeys.map((item) => {
      const {key, ...valueWithoutKey} = item;
      return valueWithoutKey;
    });
    onChange(newValues, autoInclude);
    return valuesToSet.map((item) => ({...item, included}));
  };

  const changeAutoInclude = (newAutoInclude) => {
    onChange(permissions, newAutoInclude);
  };

  const getAvailableEmptyMessage = () => {
    if (permissions.length > 0) {
      return intl.formatMessage({
        id: 'products.productProfilePermissions.editPermissionsModal.availableEmptyMessageAllIncluded',
      });
    }
    if (groupHelpLink) {
      return (
        <FormattedMessage
          id="products.productProfilePermissions.editPermissionsModal.availableEmptyMessageWithLink"
          values={{
            learnMoreLink: (linkText) => (
              <Link>
                <a href={groupHelpLink} rel="noreferrer" target="_blank">
                  <Flex
                    alignItems="center"
                    direction="row"
                    gap="size-50"
                    UNSAFE_style={{display: 'inline-flex'}}
                  >
                    {linkText}
                    <LinkOutIcon
                      aria-label={intl.formatMessage({
                        id: 'products.productProfilePermissions.editPermissionsModal.opensWindow',
                      })}
                      flexShrink={0}
                      size="XS"
                    />
                  </Flex>
                </a>
              </Link>
            ),
            productName,
          }}
        />
      );
    }
    return intl.formatMessage({
      id: 'products.productProfilePermissions.editPermissionsModal.availableEmptyMessage',
    });
  };
  const availableEmptyMessage = getAvailableEmptyMessage();

  return (
    <>
      {readOnly && (
        <View paddingStart="size-100">
          <Flex direction="row">
            <LockClosed marginX="size-40" size="S" />
            <Text>
              {intl.formatMessage({
                id: 'products.productProfilePermissions.editPermissionsModal.readOnly',
              })}
            </Text>
          </Flex>
        </View>
      )}
      {allowAutoInclude && (
        <View paddingStart="size-100">
          <Flex alignItems="center" direction="row">
            <Switch isSelected={autoInclude} onChange={changeAutoInclude}>
              {intl.formatMessage({
                id: 'products.productProfilePermissions.editPermissionsModal.autoInclude',
              })}{' '}
              {intl.formatMessage({
                id: autoInclude
                  ? 'products.productProfilePermissions.editPermissionsModal.on'
                  : 'products.productProfilePermissions.editPermissionsModal.off',
              })}
            </Switch>
            <ContextualHelp variant="info">
              <Content>
                <Text>
                  {intl.formatMessage({
                    id: 'products.productProfilePermissions.editPermissionsModal.autoIncludeTooltip',
                  })}
                </Text>
              </Content>
            </ContextualHelp>
          </Flex>
        </View>
      )}
      <IncludeListForm
        autoInclude={allowAutoInclude && autoInclude}
        availableEmptyMessage={availableEmptyMessage}
        availableTitle={intl.formatMessage({
          id: 'products.productProfilePermissions.editPermissionsModal.availablePermissions',
        })}
        includeEmptyMessage={intl.formatMessage({
          id: 'products.productProfilePermissions.editPermissionsModal.includedEmptyMessage',
        })}
        includeTitle={intl.formatMessage({
          id: 'products.productProfilePermissions.editPermissionsModal.includedPermissions',
        })}
        isItemIncluded={(item) => item.included}
        items={valuesWithKeys}
        itemText={(item) => item.name}
        noResultsMessage={intl.formatMessage({
          id: 'products.productProfilePermissions.editPermissionsModal.noMatchingItems',
        })}
        readOnly={readOnly}
        searchItem={(item, searchValue) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        }
        setIncluded={setIncluded}
      >
        {({item}) => <ItemRenderer key={item.key} item={item} />}
      </IncludeListForm>
    </>
  );
};

PermissionsIncludeListForm.propTypes = {
  /**
   * Whether auto include is allowed for this group.
   */
  allowAutoInclude: PropTypes.bool,
  /**
   * Whether every item is selected for this group, meaning any new elements should also be included in the selected state.
   */
  autoInclude: PropTypes.bool,
  /**
   * An optional link to provude help for this permission group.
   */
  groupHelpLink: PropTypes.string,
  /**
   * PermissionsIncludeListForm is a controlled component, onChange is called whenever the form value updates, with an argument of the updated value.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The permissions for this form.
   */
  permissions: PropTypes.arrayOf(includePermissionPropType).isRequired,
  /**
   * The name of the product, to be used as the link text for groupHelpLink.
   */
  productName: PropTypes.string.isRequired,
  /**
   * Whether the permissions include list is read only or not. A read only form does not display the add/remove (all) buttons.
   */
  readOnly: PropTypes.bool,
};

export default PermissionsIncludeListForm;
