import {FULFILLABLE_ITEM_CODE, modelCache} from '@admin-tribe/binky';

import {PRODUCT_GROUP_PRODUCT_LIST_CACHE_ID} from './ProductGroupProductListConstants';
import {findFulfillableItem} from './ProductGroupProductListUtils';

/**
 * @class ProductGroupProductList
 * @description Model for an individual ProductGroupProductList. The
 *   ProductGroupProductList is an abstract class for general product group
 *   product data/methods. Instances should be constructed from child classes
 *   FiProductGroupProductList or FigProductGroupProductList
 */
class ProductGroupProductList {
  /**
   * @description Method to find a product group in an array of mixed products and
   *   product groups by its product id.
   * @param {<Product|ProductGroupProductList>[]} productAndProductGroupArray - array of
   *   products and product groups to search
   * @returns {ProductGroup|undefined} a ProductGroup if found,
   *   otherwise undefined
   */
  static findProductGroupByProduct(productAndProductGroupArray, product) {
    return productAndProductGroupArray?.find((productOrProductGroup) => {
      if (productOrProductGroup instanceof ProductGroupProductList) {
        return productOrProductGroup.items?.find((item) => item.id === product.id);
      }
      return undefined;
    });
  }

  /**
   * @description Method to retrieve a product group product list by unique
   *   identifier.
   * @param {String} id - unique id of productgroupproductlist to retrieve
   * @returns {ProductGroupProductList|undefined} reference to product group product list
   *   (if exists), else undefined
   */
  static get({id}) {
    return modelCache.get(PRODUCT_GROUP_PRODUCT_LIST_CACHE_ID, id);
  }

  /**
   * @abstract
   * @description Constructor for ProductGroupProductList model .
   * @param {Object} options - options passed down to LicenseGroupUserList and MemberList.
   * @param {Array} [options.assets] - assets to assign; optional, will fetch
   *   from first product/item passed in
   * @param {String} [options.buyingProgram] - buying program to assign;
   *   optional, will fetch from first product/item passed in
   * @param {String} [options.cloud] - cloud product belongs to; optional, will fetch
   *   from first product/item passed in
   * @param {String} options.id - id of this product group product list
   * @param {Boolean} [options.isConsumable] - whether or not consumable product
   *   (optional; default is false)
   * @param {Array} options.items - list of products or product groups in list
   * @param {Array} [options.links] - links to assign; optional, will fetch
   *   from first product/item passed in
   * @param {String} [options.longName] - long product name to use; optional,
   *   will fetch from first product/item passed in
   */
  constructor(options) {
    this.id = options.id;
    this.isConsumable = options.isConsumable ?? false;
    this.items = options.items;

    this.assets = options.assets ?? this.items[0].assets;
    this.buyingProgram = options.buyingProgram ?? this.items[0].buyingProgram;
    this.cloud = options.cloud ?? this.items[0].cloud;
    this.links = options.links ?? this.items[0].links;
    this.longName = options.longName ?? this.items[0].longName;

    modelCache.put(PRODUCT_GROUP_PRODUCT_LIST_CACHE_ID, this.id, this);
  }

  /**
   * @description Method to obtain the long fulfillable item name for a given
   *   code. If no items match the code or the fulfillable item that matches
   *   does not have a long name, undefined is returned
   * @param {String} code - the fulfillable item code
   * @returns the long fulfillable item name (if exists), else undefined
   */
  getFulfillableItemNameByCode(code) {
    const fulfillableItem = findFulfillableItem(this.items, code);
    return fulfillableItem?.longName;
  }

  /**
   * @description Method to retrieve the icon from this product group product
   *   list, if one exists. Will return a link to the icon src if it can, else
   *   will return undefined if no icon available
   * @returns {String|undefined} link to the icon src if icon present, else
   *   undefined
   */
  getIcon() {
    return this.assets?.icons?.svg;
  }

  /**
   * @description Method to determine if a valid delegation target exists for a
   *   product in this product group product list
   * @returns {Boolean} true if product group product list contains a product
   *   with a valid delegation target, else false
   */
  hasValidDelegationTarget() {
    return this.items.some((item) => item.hasValidDelegationTarget());
  }

  /**
   * @description Method to determine if at least one the products in this group
   *   is administerable.
   * @returns {Boolean} true if product grouping is administerable, else false
   */
  isAdministerable() {
    return this.items.some((item) => item.isAdministerable());
  }

  /**
   * @description Method to determine whether or not this fi product group
   *   product list represents stock credits or not.
   * @returns {Boolean} true if this product group product list represents
   *   stock credits, else false
   */
  isStockCredit() {
    return this.id === FULFILLABLE_ITEM_CODE.STOCK_CREDIT;
  }

  /**
   * @description Method to determine whether or not this fi product group
   *   product list is team direct or not.
   * @returns {Boolean} true if this product group product list is team
   *   direct, else false
   */
  isTeamDirect() {
    return this.items[0].isTeamDirect();
  }

  /**
   * @description Method to determine whether or not this fi product group
   *   product list is team indirect or not.
   * @returns {Boolean} true if this product group product list is team
   *   indirect, else false
   */
  isTeamIndirect() {
    return this.items[0].isTeamIndirect();
  }
}

export default ProductGroupProductList;
