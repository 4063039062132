import {TR} from '@react/react-spectrum/Table';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const Row = observer((props) => {
  const cellProps = {...props};

  delete cellProps.children;
  delete cellProps.onClick;

  const onClick = (e) => props.selectable && props.onClick(e);

  return (
    <TR onClick={onClick} tabIndex="0">
      {React.Children.map(props.children, (child, index) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          first: index === 0,
          ...cellProps,
        });
      })}
    </TR>
  );
});

Row.propTypes = {
  id: PropTypes.string,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
};

export default Row;
