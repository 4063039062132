import {Button, ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ConfirmationButtonGroup = ({isDisabled, onDiscard, onSave}) => {
  const NAMESPACE = 'common.confirmation.buttonGroup';
  const intl = useIntl();
  return (
    <ButtonGroup align="end" data-testid="button-group" isDisabled={isDisabled} width="100%">
      <Button data-testid="discard-button" onPress={onDiscard} variant="secondary">
        {intl.formatMessage({id: `${NAMESPACE}.discard`})}
      </Button>
      <Button data-testid="save-button" onPress={onSave} variant="accent">
        {intl.formatMessage({id: `${NAMESPACE}.save`})}
      </Button>
    </ButtonGroup>
  );
};

ConfirmationButtonGroup.displayName = 'ConfirmationButtonGroup';

ConfirmationButtonGroup.propTypes = {
  /**
   * True if there is no policy change.
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * Handler that is called when the user discards a policy change.
   */
  onDiscard: PropTypes.func.isRequired,
  /**
   * Handler that is called when the user confirms a policy change.
   */
  onSave: PropTypes.func.isRequired,
};

export default ConfirmationButtonGroup;
