/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.admins:appAdminsDetailSection
 *
 * @description The Admins section.
 *
 */
(function () {
  angular.module('app.widgets.admins').component('appAdminsDetailSection', {
    bindings: {
      adminList: '<',
      headingLevel: '<?',
      waitPromise: '<',
    },
    controller,
    templateUrl: 'app/widgets/admins/admins-detail-section/admins-detail-section.component.html',
  });

  /* @ngInject */
  function controller($q, _, OrganizationList) {
    const vm = this;
    const organizationList = OrganizationList.get();

    _.assign(vm, {
      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      headingLevel: vm.headingLevel || 3,
      orgId: organizationList.activeOrg.id,
    });
  }
})();
