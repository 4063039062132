(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.product.product-group-product-utils')
    .factory('productGroupProductUtils', productGroupProductUtils);

  /* @ngInject */
  function productGroupProductUtils(
    _,
    FigProductGroupProductList,
    OrganizationManager,
    ProductGroupProductList
  ) {
    const service = {
      findProductGroupById,
      getMarketingCloudProductGroups,
      getProductGroupProductLists,
    };

    return service;

    ////////

    // Called by product-group-details routes to find product group by id.
    function findProductGroupById(id) {
      let productGroup = ProductGroupProductList.get({id});
      if (productGroup) {
        return productGroup;
      }

      // See if it is a product group based on fulfillable item groups
      const productList = OrganizationManager.getProductsForActiveOrg();
      const result = getMarketingCloudProductGroups(productList.items);
      productGroup = _.find(result.productGroupProductLists, {
        id,
      });

      // See if it is a product group based on an org-level quota fulfillable item.
      if (!productGroup) {
        productGroup = OrganizationManager.getOrgConsumablesForActiveOrg().getByFiCode(id);
      }

      return productGroup;
    }

    // Marketing Cloud also known as Experience Cloud. Based on product figId.
    function getMarketingCloudProductGroups(products) {
      const partitionedProducts = _.partition(products, (product) =>
        product.isMarketingCloudProduct()
      );
      const remainingProducts = partitionedProducts[1];

      const groupedMarketingCloudProducts = _(partitionedProducts[0])
        .groupBy('figId')
        .values()
        .value();

      const productGroupProductLists = [];
      _.forEach(groupedMarketingCloudProducts, (group) => {
        if (group.length > 1) {
          productGroupProductLists.push(
            new FigProductGroupProductList({
              figId: group[0].figId,
              items: group,
            })
          );
        } else {
          // This marketing cloud product didn't get added to a productGroup.
          remainingProducts.push(group[0]);
        }
      });

      return {
        productGroupProductLists, // always an array which may be empty
        remainingProducts,
      };
    }

    function getProductGroupProductLists(products) {
      // Break out any quota consumables for orgs and put them in product groups.
      let productGroupProductLists =
        OrganizationManager.getOrgConsumablesForActiveOrg().fiProductGroupProductLists;

      const remainingProducts = _.filter(
        products,
        (product) => !product.fulfillableItemList.hasOrgOnDemandConsumable()
      );

      // Break out any marketing cloud product multiples and put them each in a product group.
      const result = getMarketingCloudProductGroups(remainingProducts);

      // Combine the productGroups and return them together with the list of products now used for the productGroups.
      productGroupProductLists = _.concat(
        productGroupProductLists,
        result.productGroupProductLists
      );

      return {
        productGroupProductLists, // always an array which may be empty
        products: result.remainingProducts, // always an array which may be empty
      };
    }
  }
})();
