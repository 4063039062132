import {Flex, View} from '@adobe/react-spectrum';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {useCreatePackageModalContext} from 'features/packages/components/create-package-modal/CreatePackageModalContext';
import {
  CREATE_PACKAGE_CONSTANTS,
  KCCC_SAP_CODE,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';

import {
  allowLicenseFileSelectionModification,
  getLicenseFileDetails,
  showLicenseFileEntry,
} from '../productSelectionPageUtils';
import ProductsList from '../products-list/ProductsList';

import styles from './AvailableProducts.pcss';
import AvailableProductsHeader from './AvailableProductsHeader';
import EmptyAvailableProducts from './EmptyAvailableProducts';
import {
  getCurrentExpandedProductSapCodes,
  getEmptyAvailableAppsMessage,
  getNewProdGroups,
  getSelectedProducts,
  isAvailableProduct,
  isKCCCAvailable,
  newlyAddedSapCodes,
} from './availableProductsUtils';

// AvailableProducts contains the products available for selection in product selection screen of create package flow
const AvailableProducts = observer(
  ({
    isEnterpriseSupportPolicyApplicable,
    selectedProductsSearchFieldRef,
    updateManagementSettings,
  }) => {
    const intl = useIntl();
    const {createPackageModalStore} = useCreatePackageModalContext();

    const [productsGroupedBySapCode, setProductsGroupedBySapCode] = useState([]);
    const [availableProductsCount, setAvailableProductsCount] = useState(0);
    const [productKCCC, setProductKCCC] = useState(null);

    const showToast = useRef(true);
    const [accordionRefs, setAccordionRefs] = useState([]);

    const newCurrentExpandedProductSapCodes = getCurrentExpandedProductSapCodes(
      createPackageModalStore.adobeProductsUI,
      applyAvailableProductsFilter,
      showLicenseInAvailableProductsList
    );

    const [currentExpandedProductSapCode, setCurrentExpandedProductSapCode] = useState(
      newCurrentExpandedProductSapCodes
    );

    const addLicenseFile = useCallback(() => {
      if (
        allowLicenseFileSelectionModification(
          createPackageModalStore.pkgSessionSettings.packageType
        )
      ) {
        createPackageModalStore.setPkgSessionSettingValues('licenseFileSelected', true);
        createPackageModalStore.setPkgSessionSettingValues('nextButtonEnabled', true);
      }
    }, [createPackageModalStore]);

    // If all available products sapcode accordions have been expanded, then, collapse all accordions
    // Else, expand all the accordions
    const toggleExpandProducts = useCallback(() => {
      if (currentExpandedProductSapCode.length === productsGroupedBySapCode.length) {
        setCurrentExpandedProductSapCode([]);
      } else {
        const newCurrentExpandedProductSapCode = productsGroupedBySapCode.map(
          (currentValue) => currentValue.sapCode
        );
        setCurrentExpandedProductSapCode(newCurrentExpandedProductSapCode);
      }
    }, [currentExpandedProductSapCode.length, productsGroupedBySapCode]);

    function applyAvailableProductsFilter(product) {
      if (
        product.sapCode === KCCC_SAP_CODE &&
        createPackageModalStore.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED
      ) {
        return isKCCCAvailable({
          appFilters: createPackageModalStore.appFilters,
          bits: createPackageModalStore.packageCreateObj.bits,
          product,
          showAccInUI: createPackageModalStore.pkgSessionSettings.showAccInUI,
        });
      }

      return isAvailableProduct(
        createPackageModalStore.appFilters,
        isEnterpriseSupportPolicyApplicable,
        product
      );
    }

    const addProducts = useCallback(
      (product) => {
        // If a product other than KCCC is added first, KCCC should be addded automatically for lightweight packages
        if (
          createPackageModalStore.pkgSessionSettings.packageType ===
            PACKAGE_TYPE_CONSTANTS.MANAGED &&
          product &&
          product.sapCode !== KCCC_SAP_CODE
        ) {
          if (createPackageModalStore.selectedProducts.length === 0 && productKCCC) {
            createPackageModalStore.addProducts(
              getSelectedProducts({
                multipleSameBaseVersionMessage: intl.formatMessage({
                  id: 'packages.createPackage.chooseApps.availableApps.multipleSameBaseVersionToast',
                }),
                // product arg passed when single product should be added, else all available products should be added
                productsGroupedBySapCode: [{products: [productKCCC]}],
                selectedProducts: createPackageModalStore.selectedProducts,
                showToast,
              })
            );
          }
        }
        // showToast required to keep track of the toast that appears when
        // same base version product added or both acrobat and acrobat classic are selected in a package
        showToast.current = true;
        createPackageModalStore.addProducts(
          getSelectedProducts({
            addLicenseFile,
            aproClassicDCMessage: intl.formatMessage({
              id: 'packages.createPackage.chooseApps.availableApps.aproClassicDCToast',
            }),
            multipleSameBaseVersionMessage: intl.formatMessage({
              id: 'packages.createPackage.chooseApps.availableApps.multipleSameBaseVersionToast',
            }),
            // product arg passed when single product should be added, else all available products should be added
            productsGroupedBySapCode: product ? [{products: [product]}] : productsGroupedBySapCode,
            selectedProducts: createPackageModalStore.selectedProducts,
            showToast,
          })
        );
        showToast.current = true;
        updateManagementSettings();
      },
      [
        addLicenseFile,
        createPackageModalStore,
        intl,
        productKCCC,
        productsGroupedBySapCode,
        updateManagementSettings,
      ]
    );

    // eslint-disable-next-line @admin-tribe/admin-tribe/functions-inside-effects -- required outside of useEffect
    function showLicenseInAvailableProductsList() {
      return (
        showLicenseFileEntry(
          createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes,
          createPackageModalStore.pkgSessionSettings.packageType
        ) && !createPackageModalStore.pkgSessionSettings.licenseFileSelected
      );
    }

    const isItemPresentInAvailableProductsSection = useCallback(() => {
      if (
        showLicenseFileEntry(
          createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes,
          createPackageModalStore.pkgSessionSettings.packageType
        ) &&
        createPackageModalStore.pkgSessionSettings.addRemoveOptionVisible &&
        !createPackageModalStore.pkgSessionSettings.licenseFileSelected
      ) {
        return true;
      }
      return !!availableProductsCount;
    }, [
      availableProductsCount,
      createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes,
      createPackageModalStore.pkgSessionSettings.packageType,
      createPackageModalStore.pkgSessionSettings.addRemoveOptionVisible,
      createPackageModalStore.pkgSessionSettings.licenseFileSelected,
    ]);

    // updates product view on when products added/removed, or filters change
    useEffect(() => {
      // calculate new products to be rendered
      let newProductsGroupedBySapCode = [];
      // Include license file in productsGroupedBySapcode but not in available products count
      if (showLicenseInAvailableProductsList()) {
        newProductsGroupedBySapCode.push(
          getLicenseFileDetails({
            addRemoveOptionVisible:
              !!createPackageModalStore.pkgSessionSettings.addRemoveOptionVisible,
            intl,
            licenseFileSelected: !!createPackageModalStore.pkgSessionSettings.licenseFileSelected,
            packageType: createPackageModalStore.pkgSessionSettings.packageType,
          })
        );
      }

      const filteredAPUIs = createPackageModalStore.adobeProductsUI.filter(
        applyAvailableProductsFilter
      );
      newProductsGroupedBySapCode = [
        ...newProductsGroupedBySapCode,
        ...getNewProdGroups(filteredAPUIs),
      ];

      // For lightweight packages, move KCCC to top and update management settings to enable next button
      if (
        createPackageModalStore.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
        newProductsGroupedBySapCode.length > 0
      ) {
        // Find index of product group KCCC in newProductsGroupedBySapCode
        const indexKCCC = newProductsGroupedBySapCode.findIndex(
          (obj) => obj.sapCode === KCCC_SAP_CODE
        );

        if (indexKCCC > -1) {
          // If product group KCCC is present in newProductsGroupedBySapCode, move it to the first index in newProductsGroupedBySapCode
          const groupKCCC = newProductsGroupedBySapCode.splice(indexKCCC, 1)[0];
          newProductsGroupedBySapCode.unshift(groupKCCC);

          // Store product KCCC in a variable so that it can be accessed directly for adding KCCC automatically in lightweight packages workflow
          setProductKCCC(groupKCCC.products[0]);
        }

        updateManagementSettings();
      }

      // When product is removed from selected products and added to available products, add the
      // removed product's sapCode in currentExpandedProductSapCode
      const newlyAddedSapCodesList = newlyAddedSapCodes(
        productsGroupedBySapCode,
        newProductsGroupedBySapCode
      );
      currentExpandedProductSapCode.push(...newlyAddedSapCodesList);

      setProductsGroupedBySapCode(newProductsGroupedBySapCode);
      setAvailableProductsCount(filteredAPUIs.length);

      // update expanded indices now
      const newCurrentExpandedSapCodes = new Set(
        newProductsGroupedBySapCode
          .filter(
            (productGroup) =>
              productGroup.sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE ||
              currentExpandedProductSapCode.includes(productGroup.sapCode)
          )
          .map((productGroup) => productGroup.sapCode)
      );
      setCurrentExpandedProductSapCode([...newCurrentExpandedSapCodes]);

      // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger when listed props change
    }, [
      createPackageModalStore.adobeProductsUI.length,
      createPackageModalStore.selectedProducts.length,
      createPackageModalStore.appFilters,
      createPackageModalStore.pkgSessionSettings.licenseFileSelected,
    ]);

    // add or remove accordion item refs
    useEffect(() => {
      setAccordionRefs((refs) => productsGroupedBySapCode.map((_, i) => refs[i] || createRef()));
    }, [productsGroupedBySapCode]);

    return (
      <div
        aria-label={intl.formatMessage(
          {
            id: 'packages.createPackage.productSelection.availableApps.title',
          },
          {productsLength: availableProductsCount}
        )}
        role="group"
      >
        <Flex direction="column" gap="size-150" width="size-5000">
          <AvailableProductsHeader
            accordionRefs={accordionRefs}
            addAllProducts={addProducts}
            adobeProductsUi={createPackageModalStore.adobeProductsUI}
            appFilters={createPackageModalStore.appFilters}
            areAllProductsExpanded={
              currentExpandedProductSapCode.length === productsGroupedBySapCode.length
            }
            availableProductsCount={availableProductsCount}
            currentExpandedProductSapCode={currentExpandedProductSapCode}
            isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
            isItemPresentInAvailableProductsSection={isItemPresentInAvailableProductsSection}
            selectedProductsSearchFieldRef={selectedProductsSearchFieldRef}
            setAppFilters={createPackageModalStore.setAppFilters}
            setCurrentExpandedProductSapCode={setCurrentExpandedProductSapCode}
            showExtraProductsInTemplates={
              createPackageModalStore.pkgSessionSettings.showExtraProductsInTemplates
            }
            toggleExpandProducts={toggleExpandProducts}
          />
          <View
            height="size-3600"
            overflow="auto"
            UNSAFE_style={{backgroundColor: 'var(--color-grey-50)'}}
            width="size-5000"
          >
            {/* SpectrumV2Provider required here for proper styling to be added to Accordion */}
            {isItemPresentInAvailableProductsSection() ? (
              <SpectrumV2Provider className={styles.accordion}>
                <Accordion
                  ariaLevel={4}
                  multiselectable
                  onChange={(e) => {
                    const newCurrentExpandedProductIndices = e.filter(
                      (value) => !Number.isNaN(value)
                    );
                    const newCurrentExpandedProductSapCode = newCurrentExpandedProductIndices.map(
                      (currentValue) => productsGroupedBySapCode[currentValue].sapCode
                    );
                    setCurrentExpandedProductSapCode(newCurrentExpandedProductSapCode);
                  }}
                  selectedIndex={currentExpandedProductSapCode.map((sapCode) =>
                    productsGroupedBySapCode.findIndex((product) => product.sapCode === sapCode)
                  )}
                >
                  {productsGroupedBySapCode.map((prodGroup, index) => (
                    <AccordionItem
                      key={prodGroup.sapCode}
                      ref={accordionRefs[index]}
                      header={`${prodGroup.name} (${prodGroup.products.length})`}
                    >
                      <ProductsList
                        isAvailableProductsListStyling
                        onAddProduct={addProducts}
                        products={prodGroup.products}
                        showAddButton
                        showLearnMoreLink
                        showLTSLink={isEnterpriseSupportPolicyApplicable}
                        showSysReqLink
                      />
                    </AccordionItem>
                  ))}
                </Accordion>
              </SpectrumV2Provider>
            ) : (
              <EmptyAvailableProducts
                content={getEmptyAvailableAppsMessage(createPackageModalStore.appFilters, intl)[1]}
                title={getEmptyAvailableAppsMessage(createPackageModalStore.appFilters, intl)[0]}
              />
            )}
          </View>
        </Flex>
      </div>
    );
  }
);

AvailableProducts.propTypes = {
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Reference to selected products search field
   */
  selectedProductsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * Method to update management settings for the given context
   */
  updateManagementSettings: PropTypes.func.isRequired,
};

export default AvailableProducts;
