import {AppIntegration} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Helper hook to manage the app integration global policy state.
 * @param {orgId} orgId - the orgId for the global app integration policy
 * @returns
 */
const useAppIntegrationGlobalPolicy = ({orgId}) => {
  const loadGlobalPolicy = useCallback(() => AppIntegration.getGlobalPolicy({orgId}), [orgId]);

  const {model: globalPolicy, isLoading} = useAsyncModel({loadFn: loadGlobalPolicy});

  const updateGlobalPolicy = useCallback(
    async ({newPolicy}) => {
      await AppIntegration.setGlobalPolicy({newPolicy, orgId});
    },
    [orgId]
  );

  return {
    globalPolicy,
    isLoading,
    updateGlobalPolicy,
  };
};

export default useAppIntegrationGlobalPolicy;
