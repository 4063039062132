import React from 'react';

import JitRuleButton from './JitRuleButton';
import JitRuleProvider from './JitRuleProvider';
import JitRuleWidget from './JitRuleWidget';

const WIDGET_ID = 'jit-rule';

const widgetComponents = {
  buttons: [<JitRuleButton key={1} />],
  Provider: JitRuleProvider,
  Widget: JitRuleWidget,
};

export {WIDGET_ID, widgetComponents};
