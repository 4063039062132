import {DIRECTORY_OWNERSHIP_STATUS} from '@admin-tribe/binky';
import {
  InfoItemScorecard,
  OverlayWait,
  Page,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {DirectoryListContextProvider} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import useDirectoryListState from 'features/settings/components/directories/directory-list/hooks/useDirectoryListState';
import OwnedDirectoryListTable from 'features/users/components/owned-directory-list-table/OwnedDirectoryListTable';

/**
 * The 'Directory users' page which shows a list of all the owned diretories.
 */
const OwnedDirectoryListPage = observer(() => {
  const intl = useIntl();
  const listState = useDirectoryListState();

  useDocumentTitle({key: 'users.ownedDirectoryListPage.documentTitle'});

  // Get the list of owned directories.
  useEffect(() => {
    listState.loadDirectoryList(
      {
        ...listState.tableOptions,
        ownershipStatus: DIRECTORY_OWNERSHIP_STATUS.OWNED,
      },
      {updateCount: true}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this will run only during the initial render
  }, []);

  return (
    <Page
      data-testid="owned-directories-page"
      isLoading={listState.state.isLoading && listState.state.directoryListData.items.length === 0}
    >
      <PageHeader title={intl.formatMessage({id: 'users.ownedDirectoryListPage.title'})} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.ownedDirectoryListPage.scorecard.directories'})}
          value={listState.state?.directoryCount}
        />
      </PageInfoBar>
      <PageNav />

      <PageContent>
        <DirectoryListContextProvider listState={listState}>
          <OverlayWait isLoading={listState.state.isLoading} showContent size="M">
            <OwnedDirectoryListTable />
          </OverlayWait>
        </DirectoryListContextProvider>
      </PageContent>
    </Page>
  );
});

export default OwnedDirectoryListPage;
