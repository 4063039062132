import React from 'react';

/**
 * The context for the JIT rule widget
 *
 * It would contain the following properties:
 *
 * @property {Function} closeModal - a function to close the modal
 * @property {Boolean} isLoading - a boolean to indicate if the widget is loading
 * @property {Boolean} notificationsEnabled - a boolean to indicate if notifications are enabled
 * @property {Function} onSubmit - a function to submit these settings
 * @property {String} productName - the name of the matched product
 * @property {Date} ruleDate - the date when the rule is scheduled to be active
 * @property {Boolean} ruleEnabled - a boolean to indicate if the rule is enabled
 * @property {Function} setNotificationsEnabled - a function to set notificationsEnabled
 * @property {Function} setRuleEnabled - a function to set ruleEnabled
 * @property {String} variant - see Provider.jsx prop "variant"
 */
const JitRuleContext = React.createContext(null);

export default JitRuleContext;
