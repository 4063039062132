(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.api-integration').factory('ApiIntegration', getApiIntegrationModel);

  /* @ngInject */
  function getApiIntegrationModel(
    _,
    adobeioConsoleIntegrations,
    API_INTEGRATION_MODEL,
    modelCache
  ) {
    class ApiIntegration {
      /**
       * @description Creates a new ApiIntegration model
       * @param {Object} [options] Initialization Object (params described below)
       * @param {String} [options.id] Integration id.
       *        This is not only the id returned by the back-end,
       *        but also the id used to select integration data from the back-end
       * @param {String} [options.orgId] Organization id.
       *        This is not the Renga orgId this is the AdobeIO OrgId.
       *        This is not only the orgId returned by the back-end,
       *        but also the orgId used to select integration data from the back-end
       * @param {String} [options.apiKey] ApiKey of the integration
       * @param {String} [options.name] Name of the integration
       * @param {String} [options.description] Description for the integration
       * @param {String} [options.status] Status for the integration (ex: "ENABLED")
       * @param {String} [options.type] Type of the integration (ex: "entp")
       * @param {Array} [options.productIdList] Array of Strings representing the ids of products associated with this integration
       * @param {Boolean} [options.production] Indicates whether this integration is production or not
       * @param {Number} [options.createdDate] The date this integration was created
       * @param {Number} [options.lastModifiedDate] The date this integration was last modified
       * @param {Boolean} [options.namespaceEnabled] Indicates whether namespace is enabled
       * @param {String} [options.technicalAccountId] Technical Account Id for this integration
       * @param {String} [options.technicalAccountEmail] Technical Account Email for this integration
       * @param {Array} [options.sdkList] Array of Strings representing the names of sdks associated with this integration
       */
      constructor(options = {}) {
        _.assignIn(this, toMinimumObject(options));
      }

      refresh() {
        this.$resolved = false;
        this.$promise = adobeioConsoleIntegrations.integrationDetails
          .get({intId: this.id, orgId: this.orgId})
          .$promise.then((response) => {
            _.assignIn(this, toMinimumObject(response));
            modelCache.put(API_INTEGRATION_MODEL.API_INTEGRATION_CACHE_ID, this, this.id);
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      static apiResponseTransformer(dataTransferObject) {
        return new ApiIntegration(dataTransferObject);
      }

      /**
       * @description Method to retrieve an existing ApiIntegration from back-end data store.
       *              Will create an empty ApiIntegration if options are not provided
       * @param {Object} [options] Get params Object (params described below)
       * @param {String} [options.id] Integration id of the integration to retrieve
       * @param {String} [options.orgId] OrgId of the integration to retrieve.
       *        This is not the Renga orgId this is the AdobeIO OrgId.
       * @returns {ApiIntegration} Reference to pre-existing ApiIntegration
       */
      static get(options = {}) {
        const {id, orgId} = options;
        let model = modelCache.get(API_INTEGRATION_MODEL.API_INTEGRATION_CACHE_ID, id);
        if (!model) {
          model = new ApiIntegration({id, orgId});
          model.refresh();
        }
        return model;
      }
    }

    modelCache.register(API_INTEGRATION_MODEL.API_INTEGRATION_CACHE_ID, 10);
    return ApiIntegration;

    function toMinimumObject(object) {
      return _.pick(object, [
        'apiKey',
        'createdDate',
        'description',
        'id',
        'lastModifiedDate',
        'name',
        'namespaceEnabled',
        'orgId',
        'productIdList',
        'production',
        'sdkList',
        'status',
        'technicalAccountId',
        'technicalAccountEmail',
        'type',
      ]);
    }
  }
})();
