(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.products.purchase.add-products-modal')
    .constant('ADD_PRODUCTS_MODAL2_ID', 'add-products-modal2-id')
    .constant('ADD_PRODUCTS_MODAL2_STATES', {
      REVIEW_ORDER: 'ReviewOrder',
      SELECT_ORG_TYPES: 'SelectOrgTypes',
      SELECT_PRODUCTS: 'SelectProducts',
      SHOW_PAGE_BANNER: 'ShowPageBanner',
    })
    .constant('ADD_PRODUCTS_MODAL_STEPS', ['SelectOrgTypes', 'SelectProducts', 'ReviewOrder']);
})();
