import {ModalContent, ModalDescription, ModalHeading, TitledSection} from '@admin-tribe/binky-ui';
import {View} from '@adobe/react-spectrum';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import './OfferSwitchMigrationSummary.pcss';
import {getMigrationSummaryData} from 'features/users/services/offer-switch-migration/offerSwitchMigrationHelper';

import {useOfferSwitchMigrationContext} from './context/OfferSwitchMigrationContext';

const OfferSwitchMigrationSummary = () => {
  const intl = useIntl();

  const {migrationData} = useOfferSwitchMigrationContext();

  const {sourceProducts, targetProducts, usersToGetAccess, usersToLoseAccess} = useMemo(
    () => getMigrationSummaryData(migrationData.eligibleMigrations),
    [migrationData.eligibleMigrations]
  );

  const getString = (suffix) =>
    intl.formatMessage(
      {id: `users.offerSwitchMigrationSummary.${suffix}`},
      {
        contractRenewalEndDate: migrationData.contractRenewalEndDate,
        getAccessUserCount: usersToGetAccess.length,
        loseAccessUserCount: usersToLoseAccess.length,
        migrationCount: migrationData.eligibleMigrations.length,
        sourceProductCount: sourceProducts.length,
        sourceProductName: sourceProducts[0]?.displayName,
        targetProductCount: targetProducts.length,
        targetProductName: targetProducts[0]?.displayName,
        totalUserCount: usersToGetAccess.length + usersToLoseAccess.length,
      }
    );

  return (
    <>
      <ModalHeading>{getString('title')}</ModalHeading>
      <ModalDescription>{getString('description')}</ModalDescription>
      <ModalContent>
        <View backgroundColor="gray-50" marginTop="size-225" padding="size-300">
          <TitledSection dividerPlacement="NONE" title={getString('getAccess.title')}>
            {getString('getAccess.description')}
          </TitledSection>
        </View>
        {usersToLoseAccess.length > 0 && (
          <View backgroundColor="gray-50" marginTop="size-225" padding="size-300">
            <TitledSection dividerPlacement="NONE" title={getString('loseAccess.title')}>
              <ul styleName="lose-access-users">
                {usersToLoseAccess.map((user) => (
                  <li key={user.id}>{user.getDisplayNameOrEmail()}</li>
                ))}
              </ul>
              {getString('loseAccess.description')}
            </TitledSection>
          </View>
        )}
      </ModalContent>
    </>
  );
};

export default OfferSwitchMigrationSummary;
