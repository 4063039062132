import {
  Button,
  Content,
  ContextualHelp,
  DialogContainer,
  Flex,
  Heading,
  Item,
  Menu,
  MenuTrigger,
  Text,
  View,
} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {DeviceAction} from '@pandora/react-data-source-daco';
import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {useTableSectionContext} from '@pandora/react-table-section';
import {error as showError} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {isActivationAllowed} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';

import ConfirmationDialog from '../confirmation-dialog/ConfirmationDialog';

const DEVICE_OPTIONS_NAMESPACE = 'products.deviceLicenses.components.deviceOptionsButton';
const STATUS_NAMESPACE = 'products.deviceLicenses.deviceDetails.details.label';

const DeviceOptionsButton = ({licenseGroupId, onDeviceUpdated}) => {
  const {tableSectionState} = useTableSectionContext();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [action, setAction] = useState();
  const {totalLicensesCount, usedLicensesCount, isOverDeploymentAllowed} =
    useDeviceLicensesPageContext();

  const getLicensingMode = () =>
    tableSectionState.items.length > 0 ? tableSectionState.items[0].licensingMode : '';

  const onDialogDismiss = () => {
    setOpen(false);
  };

  const performDeviceOperation = (selection) => {
    if (tableSectionState.selectedItems.length > 100) {
      // Enforce 100 limit for Activate/Block/Remove bulk operation
      const status = {
        [DeviceAction.ACTIVATE]: intl.formatMessage({id: `${STATUS_NAMESPACE}.activatedDevices`}),
        [DeviceAction.BLOCK]: intl.formatMessage({id: `${STATUS_NAMESPACE}.blockedDevices`}),
        [DeviceAction.REMOVE]: intl.formatMessage({id: `${STATUS_NAMESPACE}.removedDevices`}),
      };
      showError(
        intl.formatMessage(
          {id: `${DEVICE_OPTIONS_NAMESPACE}.limit`},
          {status: status[selection].toLowerCase()}
        )
      );
    } else {
      setAction(selection);
      setOpen(true);
    }
  };

  /**
   * Method that has the logic for enabling or disabling the Device options button
   * @returns {boolean}
   */
  const isDeviceOptionEnabled = () => {
    if (tableSectionState.selectedItems.length === 0) return false;
    const activationState = tableSectionState.selectedItems[0].activationState;
    return tableSectionState.selectedItems.every(
      (item) => item.activationState === activationState
    );
  };

  /**
   * Method that tells if we need to enable the Activate button
   * @returns {boolean}
   */
  const areSelectedBlocked = () =>
    tableSectionState.selectedItems.every(
      (device) => device.activationState === DEVICE_ACTIVATION_STATUS.BLOCKED
    );

  /**
   * A method to determine whether blocked devices can be activated
   * @returns {Boolean} whether blocked devices can be activated
   */
  const getCanActivateLicenses = () =>
    isActivationAllowed({
      isOverDeploymentAllowed,
      selectedBlockedDevice: tableSectionState.selectedItems.length,
      totalLicensesCount,
      usedLicensesCount,
    });

  /**
   * Method that returns an array of keys to be disabled in the Menu
   * @returns {String[]} - Array of keys
   */
  const getDisabledKeys = () => {
    const disabledActionForBlockedDevices = getCanActivateLicenses()
      ? [DeviceAction.BLOCK]
      : [DeviceAction.ACTIVATE, DeviceAction.BLOCK];
    return areSelectedBlocked()
      ? disabledActionForBlockedDevices
      : [DeviceAction.ACTIVATE, DeviceAction.REMOVE];
  };

  return (
    <>
      <Flex alignItems="center" height="size-400" width="size-300">
        <ContextualHelp placement="top middle" variant="info">
          <Heading level={2}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.contextualHelp.heading`,
            })}
          </Heading>
          <Content>
            {['activated', 'blocked', 'removed'].map((key) => (
              <View key={key} marginBottom="size-50">
                <Text>
                  {intl.formatMessage(
                    {
                      id: `${DEVICE_OPTIONS_NAMESPACE}.contextualHelp.${key}`,
                    },
                    {
                      strong: (str) => <strong>{str}</strong>,
                    }
                  )}
                </Text>
              </View>
            ))}
          </Content>
        </ContextualHelp>
      </Flex>
      <MenuTrigger>
        <Button data-testid="device-options-button" isDisabled={!isDeviceOptionEnabled()}>
          {intl.formatMessage({
            id: `${DEVICE_OPTIONS_NAMESPACE}.label`,
          })}
        </Button>
        <Menu disabledKeys={getDisabledKeys()} onAction={performDeviceOperation} width="size-1600">
          <Item key={DeviceAction.ACTIVATE}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.activate`,
            })}
          </Item>
          <Item key={DeviceAction.BLOCK}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.block`,
            })}
          </Item>

          <Item key={DeviceAction.REMOVE}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.remove`,
            })}
          </Item>
        </Menu>
      </MenuTrigger>
      <DialogContainer onDismiss={onDialogDismiss}>
        {isOpen && (
          <ConfirmationDialog
            action={action}
            close={onDialogDismiss}
            deviceDetailsList={tableSectionState.selectedItems.map((item) => new Device(item))}
            licenseGroupId={licenseGroupId}
            licensingMode={getLicensingMode()}
            onDeviceUpdated={onDeviceUpdated}
          />
        )}
      </DialogContainer>
    </>
  );
};

DeviceOptionsButton.propTypes = {
  /**
   *  licenseGroupId - Id of the current product profile
   */
  licenseGroupId: PropTypes.string,
  /**
   * Callback for when the device operation was completed
   */
  onDeviceUpdated: PropTypes.func.isRequired,
};

export default DeviceOptionsButton;
