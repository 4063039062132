(function () {
  /**
   * @deprecated Partially deprecated, individual deprecated methods are marked below.
   *
   * @ngdoc service
   * @name app.account.access:accountAccess
   * @description account access factory
   */
  angular.module('app.account.access').factory('accountAccess', accountAccess);

  /* @ngInject */
  function accountAccess(
    $location,
    $q,
    _,
    auth,
    contractUtils,
    feature,
    onesieSrc2,
    organizationAccess,
    OrganizationManager
  ) {
    const service = {
      canAccessAccountPage,
      canManagePlan,
      canViewAccountOverviewPage,
      canViewSelfCancelModalFromDeepLink,
      canVisitAccountPage,
    };

    return service;

    //////////////

    /**
     * @description This is the check for allowing the appAccount page (account.account) to be
     *   visible in the nav. We show this page for direct orgs, trial contracts, and whenever
     *   the Contracts table can't be shown (ie whenever contracts are all DX legacy contracts).
     *
     * @returns {Boolean} True if user can access account page, false otherwise.
     */
    function canAccessAccountPage() {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return contractList.hasTeamContract() || contractList.hasAnyTrialContracts();
    }

    // Manage Plan and Self Cancel manage licenses shows for Direct Teams (not DR) between months
    // 0-11 when Admin is allowed to add products
    function canManagePlan(contract) {
      return (
        feature.isEnabled('temp_self_cancel') &&
        !contract.isInRenewalWindow() &&
        contract.isDirectContract() &&
        !contract.isDrContract &&
        contractUtils.isAllowedToAddProducts()
      );
    }

    /**
     * @description Determine if the user has navigated to the Self Cancel deep link and is eligible to use
     * this feature.
     * @returns {Boolean} True if the user can proceed to Self Cancel through the deep link, otherwise false.
     */
    function canViewSelfCancelModalFromDeepLink() {
      const path = $location.path();
      const isCancelLicensesInUrl = _.includes(path, '/cancel-licenses');
      if (isCancelLicensesInUrl && onesieSrc2.core.account.access.canUseSelfCancelDeepLink()) {
        return true;
      }
      return false;
    }

    /**
     * @deprecated Please use canViewAccountOverviewPage from src2 accountAccess
     * @description Method to determine whether the account overview page can
     *     be viewed. When there is only one contract that is an active direct
     *     contract, we hide account overview, otherwise it will be displayed.
     * @returns {Promise} Promise the resolves to a boolean indicating whether
     *     the account overview page can be viewed.
     */
    function canViewAccountOverviewPage() {
      const displayableContracts = contractUtils.getDisplayableContracts({includeInactive: true});
      return $q.resolve(
        !(
          displayableContracts.length === 1 &&
          displayableContracts[0].isDirectContract() &&
          displayableContracts[0].isStatusActive()
        )
      );
    }

    // This is a check for letting the user browse to the Account page URL.
    // Mainly used to show the top-nav "Account" link.
    // NOTE: This is not the check for showing information on the Account page.
    function canVisitAccountPage() {
      if (!auth.isUserOrgAdmin()) {
        return $q.resolve(false);
      }

      return organizationAccess.canViewOrganizationAccounts();
    }
  }
})();
