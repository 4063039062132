/* eslint-disable max-lines */
(function () {
  const componentName = 'appAddUserModal';
  /**
   * @deprecated use src2 User modal
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      contextName: '<?',
      modalId: '@?',
      onAddUser: '<?',
      onClosed: '&?',
      onInitialized: '&?',
      pageContext: '<',
      user: '<?',
      userList: '<?',
    },
    controller,
    templateUrl: 'app/widgets/users/add-user-modal/add-user-modal.component.html',
  });

  /* @ngInject */
  /* eslint-disable max-statements */
  function controller(
    _,
    $q,
    $scope,
    $state,
    $timeout,
    $translate,
    ADD_USER_FORM_ERROR,
    ADD_USER_FORM_VALIDITY_CHANGE,
    ADD_USER_MODAL_TABS,
    AnalyticsEvent,
    auth,
    feature,
    COMMON_EVENTS,
    ConfigurationMemberRoles,
    configurationReady,
    DisplayMessage,
    ORGANIZATION_USER_ERROR_CODE,
    OrganizationManager,
    OrganizationUser,
    organizationUserUtils,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    panelManager,
    productAccess,
    ProductConfiguration,
    ProductConfigurationList,
    PRODUCT_PROFILE_SELECT_ERROR,
    PRODUCT_PROFILE_SELECT_VALIDITY_CHANGE,
    ROLE,
    toastManager,
    translationUtils,
    trialHelper,
    USER_GROUP_SELECT_ERROR,
    userGroupAccess,
    UserGroupConfigurationsList,
    UserRole
  ) {
    const vm = this;
    let searchOnlyContentMode;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    /////////////
    function $onInit() {
      _.assign(vm, {
        canAssignType1User: true,
        canDisplayName,
        canEditProducts:
          OrganizationManager.getProductsForActiveOrg().hasUserDelegatableProduct() &&
          (auth.isUserOrgAdmin() || auth.isUserProductAdmin() || auth.isUserPlcAdmin()),
        canEditUserGroups: userGroupAccess.canEditUserGroup(),
        hasAnyGroups,
        hasAnyProfiles,
        hasTargetUser: !!vm.user,
        id: vm.modalId || 'add-user-modal',
        isOpen: false,
        isReadyToSubmit,
        isReadyToSubmitAddUserForm: false,
        isReadyToSubmitProductProfileSelect: true,
        modalContext: `add-user-modal-context-${vm.pageContext.target}-${vm.pageContext.targetType}`,
        onClose,
        onOpen,
        onProductRoleChange,
        productsAndPLCSelectedForDevelopers: null,
        promiseToWait: $q.resolve(),
        shouldDisplayAsyncAssignMsg,
        shouldDisplayUserDetails,
        shouldDisplayUsername,
        showProductProfileSelect,
        submit,
        user: vm.user || new OrganizationUser(),
        userBackup: null,
      });

      $scope.$on(ADD_USER_FORM_ERROR, onChildComponentError);
      $scope.$on(ADD_USER_FORM_VALIDITY_CHANGE, onAddUserFormChange);
      $scope.$on(PRODUCT_PROFILE_SELECT_ERROR, onChildComponentError);
      $scope.$on(PRODUCT_PROFILE_SELECT_VALIDITY_CHANGE, onProductProfileSelectValidityChange);
      $scope.$on(USER_GROUP_SELECT_ERROR, onChildComponentError);
      $scope.$on(
        'PROFILE_SELECTION_VALUES_FOR_DEVELOPER_UPDATED',
        onProductProfileSelectionForDeveloper
      );

      configureBasedOnPageContext();

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function $onChanges() {
      configureBasedOnPageContext();
    }

    function addExistingUserToProduct() {
      if (vm.user.getType().isUserGroup()) {
        const userGroupConfigurationsList = UserGroupConfigurationsList.get({groupId: vm.user.id});
        return userGroupConfigurationsList.$promise.then(() => {
          const productId = vm.pageContext.targetId;
          const product = _.find(vm.user.products, {id: productId});
          const newLicenseGroups = product
            ? organizationUserUtils.explodeProductLicenseGroups([product])
            : [];
          const currentLicenseGroups = _(userGroupConfigurationsList.items)
            .filter(['product.id', productId])
            .invokeMap('toMinimumModel')
            .value();

          const addedProductLicenses = _.differenceWith(
            newLicenseGroups,
            currentLicenseGroups,
            organizationUserUtils.compareProductLicenseGroups
          );
          const removedProductLicenses = _.differenceWith(
            currentLicenseGroups,
            newLicenseGroups,
            organizationUserUtils.compareProductLicenseGroups
          );

          userGroupConfigurationsList.add(addedProductLicenses);
          userGroupConfigurationsList.remove(removedProductLicenses);
          return userGroupConfigurationsList.save();
        });
      }
      vm.userList.addModifiedUsers([vm.user]);
      return vm.userList.save();
    }

    // eslint-disable-next-line complexity
    function addContextResourceToUser() {
      let contextResource = {
        id: vm.pageContext.targetId,
      };
      switch (vm.pageContext.target) {
        case PAGE_TARGET.ORGANIZATION:
          if (vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER && vm.isDeveloperContext) {
            // product access is not given to added developers
            vm.user.products = vm.user.savedState.products;
          }
          vm.initializeProfileSelectionForDevelopers = true;
          break;

        case PAGE_TARGET.PRODUCT:
          if (vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
            vm.user.roles = [
              {
                targets: [{id: vm.pageContext.targetId}],
                type: UserRole.PRODUCT_ADMIN,
              },
            ];
          } else if (vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER) {
            // product access is not given to added developers
            vm.user.products = vm.user.savedState.products;
            vm.initializeProfileSelectionForDevelopers = true;
          }
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          // for PLCs the context resource is flipped
          contextResource = {
            id: vm.pageContext.targetParentId,
            licenseGroups: [{id: vm.pageContext.targetId}],
          };
          if (vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
            vm.user.roles = [
              {
                targets: [{id: vm.pageContext.targetId, parentId: vm.pageContext.targetParentId}],
                type: UserRole.LICENSE_ADMIN,
              },
            ];
          } else if (
            vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER &&
            vm.isDeveloperContext
          ) {
            // when profile selection was not needed for developers from the profile developers tab
            // we set the roles field based on pageContext
            if (!vm.productsAndPLCSelectedForDevelopers) {
              vm.user.roles = [
                {
                  targets: [{id: vm.pageContext.targetId, parentId: vm.pageContext.targetParentId}],
                  type: UserRole.LICENSE_DEV_ADMIN,
                },
              ];
            }
            vm.initializeProfileSelectionForDevelopers = true;
          } else if (vm.pageContext.targetType !== PAGE_TARGET_TYPE.INTEGRATION) {
            vm.user.products = [contextResource];
          }
          break;
        case PAGE_TARGET.USER_GROUP:
          if (vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
            vm.user.roles = [
              {
                targets: [{id: vm.pageContext.targetId}],
                type: UserRole.USER_GROUP_ADMIN,
              },
            ];
          } else {
            vm.user.userGroups = [contextResource];
          }
          break;
        case PAGE_TARGET.STORAGE:
          if (vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
            vm.user.roles = [
              {
                targets: [{id: vm.pageContext.targetId}],
                type: UserRole.STORAGE_ADMIN,
              },
            ];
          }
          break;
        default:
          break;
      }
    }

    function canDisplayName() {
      if (vm.user.getType().isUserGroup()) {
        return true;
      }
      return !!(
        (vm.user.firstName && _.trim(vm.user.firstName)) ||
        (vm.user.lastName && _.trim(vm.user.lastName))
      );
    }

    function canSetProductRolesOnUser() {
      return (
        !vm.user ||
        (vm.supportedUserTypesForProductRole &&
          vm.supportedUserTypesForProductRole[_.get(vm, 'user.type')] !== false)
      );
    }

    // eslint-disable-next-line complexity
    function configureBasedOnPageContext() {
      // prevent null reference errors on first call (before $onInit is called)
      if (vm.pageContext) {
        vm.addingToProduct =
          vm.pageContext.target === PAGE_TARGET.PRODUCT &&
          (vm.pageContext.targetType === PAGE_TARGET_TYPE.USER ||
            vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER);
        const addingToProductConfiguration =
          vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION &&
          vm.pageContext.targetType === PAGE_TARGET_TYPE.USER;
        if (vm.addingToProduct || addingToProductConfiguration) {
          vm.targetProduct = _.find(OrganizationManager.getProductsForActiveOrg().items, {
            id: vm.addingToProduct ? vm.pageContext.targetId : vm.pageContext.targetParentId,
          });
          productAccess
            .canAssignType1User(vm.targetProduct)
            .then((result) => {
              vm.canAssignType1User = result;
            })
            .catch((error) => error);
        }
        vm.canSetProductRoles =
          (vm.pageContext.target === PAGE_TARGET.PRODUCT ||
            vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) &&
          vm.pageContext.targetType === PAGE_TARGET_TYPE.USER;
        vm.contextResource = {
          id: vm.pageContext.targetId,
        };
        vm.requiresChangesBeforeSubmit =
          vm.hasTargetUser ||
          (vm.addingToProduct && vm.targetProduct && vm.targetProduct.isEnterprise()) ||
          (vm.isDeveloperContext && vm.pageContext.target !== PAGE_TARGET.PRODUCT_CONFIGURATION);
        vm.showAddProfileOnAddUserPanel =
          vm.addingToProduct && vm.targetProduct && vm.targetProduct.isEnterprise();
        vm.showTabs =
          (vm.pageContext.target === PAGE_TARGET.ORGANIZATION &&
            _.includes(
              [PAGE_TARGET_TYPE.DEVELOPER, PAGE_TARGET_TYPE.INTEGRATION, PAGE_TARGET_TYPE.USER],
              vm.pageContext.targetType
            )) ||
          (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION &&
            PAGE_TARGET_TYPE.INTEGRATION === vm.pageContext.targetType);
        if (!vm.isDeveloperContext) {
          initializeModalTitleAndToastKey();
        }
      }

      function initializeModalTitleAndToastKey() {
        if (vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
          vm.editModalTitle = 'widgets.users.addUserModal.editAdmin';
          vm.addToModalTitle = 'widgets.users.addUserModal.addAdminTo';
          vm.addAModalTitle = 'widgets.users.addUserModal.addAnAdmin';
        } else {
          vm.editModalTitle = 'widgets.users.addUserModal.editUser';
          vm.addToModalTitle = 'widgets.users.addUserModal.addUserTo';
          vm.addAModalTitle = 'widgets.users.addUserModal.addAUser';
        }
        vm.detailText = 'widgets.users.addUserModal.userDetails';
        vm.summaryTitle = 'widgets.users.addUserModal.userSummary.title';
        vm.summarySubTitle = 'widgets.users.addUserModal.userSummary.subTitle';
        vm.summaryDetails = 'widgets.users.addUserModal.userSummary.userDetails';

        if (vm.hasTargetUser) {
          vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.editModalTitle);
          vm.toastSuccessKey = 'widgets.users.addUserModal.toast.userSaved';
        } else {
          vm.toastSuccessKey = 'widgets.users.addUserModal.toast.userAdded';
          if (vm.contextName) {
            vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.addToModalTitle, {
              name: vm.contextName,
            });
          } else if (vm.pageContext.target === PAGE_TARGET.STORAGE) {
            vm.modalTitle = $translate.instant('widgets.users.addUserModal.addAStorageAdmin');
          } else {
            vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.addAModalTitle);
          }
        }
        vm.modalTitle = _.unescape(vm.modalTitle);
      }
    }

    function configureBasedOnTargetTab() {
      if (vm.isIntegrationContext) {
        vm.editModalTitle = 'widgets.users.addUserModal.editIntegration2';
        vm.summaryTitle = 'widgets.users.addUserModal.integrationSummary.title2';
        vm.summaryDetails = 'widgets.users.addUserModal.integrationSummary.integrationDetails2';
        vm.canEditUserGroups = false;
        const conf = configurationReady.getConfig();
        if (_.has(conf, 'services.adobeioConsole.url')) {
          vm.linkToAdobeIO = `${conf.services.adobeioConsole.url}/integrations/${vm.user.integrationOrgId}/${vm.user.integrationId}/overview`;
        }
      } else if (vm.isDeveloperContext) {
        vm.editModalTitle = 'widgets.users.addUserModal.editDeveloper';
        vm.addToModalTitle = 'widgets.users.addUserModal.addDeveloperTo';
        vm.addAModalTitle = 'widgets.users.addUserModal.addADeveloper';
        vm.detailText = 'widgets.users.addUserModal.developerDetails';
        vm.summaryTitle = 'widgets.users.addUserModal.developerSummary.title';
        vm.summarySubTitle = 'widgets.users.addUserModal.developerSummary.subTitle';
        vm.summaryDetails = 'widgets.users.addUserModal.developerSummary.developerDetails';
        vm.canEditUserGroups = false;
      }
      if (vm.hasTargetUser) {
        vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.editModalTitle);
        vm.toastSuccessKey = 'widgets.users.addUserModal.toast.userSaved';
      } else {
        vm.toastSuccessKey = 'widgets.users.addUserModal.toast.userAdded';
        if (vm.contextName) {
          vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.addToModalTitle, {
            name: vm.contextName,
          });
        } else {
          vm.modalTitle = translationUtils.sanitizeSafeInstant(vm.addAModalTitle);
        }
      }
    }

    function fetchRoleData() {
      if (vm.canSetProductRoles) {
        const product = getProductTarget();
        if (product.hasConfigurationSettingForLicenseGroupMember()) {
          if (vm.pageContext.target === PAGE_TARGET.PRODUCT && product.isTeam()) {
            // for Team products we need to select the default profile ID in order to set roles
            const profileList = ProductConfigurationList.get(product, 1);
            profileList.$promise
              .then(() => {
                fetchMemberRoles(product.id, profileList.items[0].id);
              })
              .catch(() => {
                toastManager.showErrorToast();
              });
          } else if (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
            fetchMemberRoles(product.id, vm.pageContext.targetId);
          }
        } else if (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
          const productConfig = ProductConfiguration.get(product, vm.pageContext.targetId);
          // eslint-disable-next-line promise/catch-or-return
          productConfig.$promise.then((productConfiguration) => {
            const productRoles = productConfiguration.getProductRoles();
            _.forEach(productRoles, (productRole) => {
              productRole.name = $translate.instant(
                `binky.widgets.user.userRole.product.${_.camelCase(productRole.title)}`
              );
            });
            vm.productRoles = _.sortBy(productRoles, ['name']);
            vm.selectedProductRoleId = getDefaultProductRoleId({default: true});
            vm.supportedUserTypesForProductRole = {};
            vm.canSetProductRolesOnTarget = canSetProductRolesOnUser();
          });
        }
      }
    }

    function fetchMemberRoles(productId, profileId) {
      vm.memberRoles = ConfigurationMemberRoles.get(productId, profileId, []);
      vm.memberRoles.$promise
        .then(() => {
          vm.productRoles = _.sortBy(vm.memberRoles.availableRoles, ['name']);
          vm.selectedProductRoleId = getDefaultProductRoleId({preferred: true});
          vm.supportedUserTypesForProductRole = {USER_GROUP: false};
          vm.canSetProductRolesOnTarget = canSetProductRolesOnUser();
        })
        .catch(() => {
          toastManager.showErrorToast();
        });
    }

    function getDefaultProductRoleId(findQuery) {
      const defaultProductRole = _.find(vm.productRoles, findQuery) || vm.productRoles[0];
      return _.get(defaultProductRole, 'id');
    }

    function getProductTarget() {
      return _.find(OrganizationManager.getProductsForActiveOrg().items, {
        id:
          vm.pageContext.target === PAGE_TARGET.PRODUCT
            ? vm.pageContext.targetId
            : vm.pageContext.targetParentId,
      });
    }

    function hasAnyGroups() {
      return _.get(vm.user, 'userGroups.length') > 0;
    }

    function hasAnyProfiles() {
      return (
        OrganizationManager.getOrgConsumablesForActiveOrg().hasAutoAssignedProducts() ||
        _.some(vm.user.products, (product) => product.licenseGroups.length > 0) ||
        _.some(vm.user.developerProducts, (product) => product.licenseGroups.length > 0)
      );
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    // eslint-disable-next-line complexity
    function isReadyToSubmit() {
      if (vm.isDeveloperContext) {
        return (
          vm.user &&
          !vm.isSubmitting &&
          (vm.isReadyToSubmitAddUserForm || vm.hasTargetUser) &&
          (!vm.requiresChangesBeforeSubmit ||
            (!vm.user.isNew() && vm.user.hasUnsavedChanges()) ||
            (vm.user.isNew() && vm.user.toMinimumModel().roles.length > 0))
        );
      }
      return (
        vm.user &&
        !vm.isSubmitting &&
        (vm.isReadyToSubmitAddUserForm || vm.hasTargetUser) &&
        vm.isReadyToSubmitProductProfileSelect &&
        (!vm.requiresChangesBeforeSubmit ||
          vm.user.getUnsavedProductChanges().added.length > 0 ||
          vm.user.getUnsavedProductChanges().removed.length > 0 ||
          vm.user.getUnsavedUserGroupChanges().added.length > 0 ||
          vm.user.getUnsavedUserGroupChanges().removed.length > 0) &&
        (vm.canAssignType1User || !vm.user.getType().isType1())
      );
    }

    function onAddUserFormChange(event, isValid) {
      vm.isReadyToSubmitAddUserForm = isValid;
      vm.canSetProductRolesOnTarget = canSetProductRolesOnUser();
    }

    function onChildComponentError() {
      showPageBanner();
    }

    function onClose() {
      vm.isOpen = false;
      vm.defaultToUserGroupsTab = null;
      vm.defaultProductsTab = null;
      vm.productsAndPLCSelectedForDevelopers = null;
      vm.user = vm.userBackup;
      vm.userBackup = null;
      reset();
      hidePageBanner();

      _.invoke(vm, 'onClosed');
    }

    function onOpen(params) {
      fetchRoleData();

      const defaultTab = _.get(params, 'defaultTab');
      const targetTab = _.get(params, 'targetTab');

      // Currently this may only be true for new organization and directory users.
      // If true, when the userList changes, it is reset rather than refreshed.
      searchOnlyContentMode = _.get(params, 'searchOnlyContentMode', false);

      vm.isOpen = true;
      vm.userBackup = vm.user;
      vm.user = _.cloneDeep(vm.user);
      vm.isIntegrationContext = targetTab === PAGE_TARGET_TYPE.INTEGRATION;
      vm.isDeveloperContext = targetTab === PAGE_TARGET_TYPE.DEVELOPER;

      $timeout(() => {
        vm.defaultProductsTab = defaultTab === ADD_USER_MODAL_TABS.PRODUCTS;
        vm.defaultToUserGroupsTab = defaultTab === ADD_USER_MODAL_TABS.USER_GROUPS;
        vm.promiseToWait = _.get(vm, 'user.$promise', $q.resolve());
        if (vm.addingUsersWithoutAccess) {
          showPageBanner({
            message: $translate.instant(
              'widgets.users.addUserModal.pageBanner.addingUsersWithoutAccessMessage'
            ),
            title: $translate.instant(
              'widgets.users.addUserModal.pageBanner.addingUsersWithoutAccessTitle'
            ),
            variant: 'warning',
          });
        }
        // We need to re-configure in the case when we switch to editing
        // user groups/ user products after editing developer products on the user details page
        // because we set canEditUserGroups to false in developer workflow
        vm.canEditUserGroups = userGroupAccess.canEditUserGroup();
        configureBasedOnPageContext();
        if (vm.isIntegrationContext || vm.isDeveloperContext) {
          configureBasedOnTargetTab();
        }
      });
    }

    function onProductProfileSelectValidityChange(event, isValid) {
      vm.isReadyToSubmitProductProfileSelect = isValid;
    }

    // Function that gets triggered when user selects a product profile to assign a developer
    function onProductProfileSelectionForDeveloper(event, product) {
      if (!vm.productsAndPLCSelectedForDevelopers) {
        vm.productsAndPLCSelectedForDevelopers = vm.user.developerProducts;
      }
      // update based on the product and profile selection
      let isNewProduct = true;
      vm.productsAndPLCSelectedForDevelopers = _.map(
        vm.productsAndPLCSelectedForDevelopers,
        (eachProductsObj) => {
          if (eachProductsObj.id === product.id) {
            isNewProduct = false;
            _.assign(eachProductsObj, {
              licenseGroups: product.licenseGroups,
            });
          }
          return eachProductsObj;
        }
      );
      // if this is a new product, add it to the collection
      if (isNewProduct) {
        vm.productsAndPLCSelectedForDevelopers.push(product);
      }

      updateUserModel();

      function updateUserModel() {
        const selectedTargets = _.map(vm.productsAndPLCSelectedForDevelopers, (eachProductObject) =>
          _.map(eachProductObject.licenseGroups, (eachLicenseGroup) => ({
            id: eachLicenseGroup.id,
            parentId: eachProductObject.id,
          }))
        );
        const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE_DEV});
        if (role) {
          role.targets = _.flatten(selectedTargets);
        } else {
          vm.user.roles.push({
            targets: _.flatten(selectedTargets),
            type: UserRole.LICENSE_DEV_ADMIN,
          });
        }
      }
    }

    function onProductRoleChange(role) {
      vm.selectedProductRoleId = role;
    }

    function onSubmitError(err) {
      const errorCode = _.get(err, 'data.errorCode');
      switch (errorCode) {
        case ORGANIZATION_USER_ERROR_CODE.CANNOT_CREATE_USER:
          showPageBanner({
            message: $translate.instant('widgets.users.addUserModal.cannotCreateUser'),
          });
          break;
        case ORGANIZATION_USER_ERROR_CODE.INVALID_USER_ID:
          showPageBanner({message: $translate.instant('widgets.users.addUserModal.invalidUserId')});
          break;
        case ORGANIZATION_USER_ERROR_CODE.LDS_ERROR:
          if (err.data.ldsresponse.errorMessage === 'ALLOWABLE_LICENSE_COUNT_EXCEEDED') {
            // only show link if product is a team product and user is an org admin
            let showPurchaseLink = false;
            if (auth.hasAnyRole([ROLE.ADMIN.ORG])) {
              const productList = OrganizationManager.getProductsForActiveOrg();
              const foundProducts = [];
              _.forEach(err.data.ldsresponse.productRequiredLicenseInfos, (licenseInfo) => {
                const foundProduct = _.find(productList.items, (product) => {
                  // workaround for https://git.corp.adobe.com/admin-tribe/onesie/issues/3090 (trim prefix)
                  const idArray = _.split(licenseInfo.id, '-', 2);
                  const trimmedId = idArray.length > 1 ? idArray[1] : undefined;
                  return product.id === licenseInfo.id || product.id === trimmedId;
                });
                if (foundProduct) {
                  foundProducts.push(foundProduct);
                }
              });

              if (_.find(foundProducts, {customerSegment: 'TEAM'})) {
                showPurchaseLink = auth.hasAnyRole([ROLE.ADMIN.ORG]);
              }
            }

            showPageBanner({
              message: $translate.instant('widgets.users.addUserModal.licenseCountExceeded'),
              showLicenseBtn: showPurchaseLink,
            });
          } else {
            showPageBanner();
          }
          break;
        case ORGANIZATION_USER_ERROR_CODE.USER_ALREADY_EXISTS:
          showPageBanner({
            message: $translate.instant('widgets.users.addUserModal.emailOrUsernameAlreadyExists'),
          });
          break;
        default:
          showPageBanner();
      }
    }

    function onSubmitSuccess() {
      vm.message = translationUtils.sanitizeSafeInstant(vm.toastSuccessKey, {
        user: vm.user.getDisplayNameOrEmail(),
      });

      if (
        vm.user.getType().isUserGroup() &&
        _.invoke(vm, 'targetProduct.fulfillableItemList.hasDomainClaiming')
      ) {
        productAccess
          .canAssignType1User(vm.targetProduct)
          .then((result) => {
            if (!result) {
              vm.message = $translate.instant(
                'widgets.users.addUserModal.toast.addUserGroupToCCEProduct',
                {
                  user: vm.user.getDisplayNameOrEmail(),
                }
              );
            }
          })
          .finally(toastAndCloseModal);
      } else {
        toastAndCloseModal();
      }
    }

    function toastAndCloseModal() {
      toastManager.showSuccessToast(vm.message);
      if (vm.onAddUser) {
        vm.onAddUser();
      }
      panelManager.close(vm.id);
      triggerAnalytics();
      reset();
    }

    function reset() {
      $scope.$broadcast(COMMON_EVENTS.RESET);
      vm.isReadyToSubmitAddUserForm = false;
      vm.isSubmitting = false;
      vm.promiseToWait = $q.resolve();
      vm.selectedProductRoleId = null;
      if (!vm.hasTargetUser) {
        vm.user = new OrganizationUser();
      }
    }

    function shouldDisplayAsyncAssignMsg() {
      const products = vm.targetProduct ? [vm.targetProduct] : vm.user.products;
      return (
        vm.canEditProducts && _.some(products, (product) => trialHelper.isTrialProduct(product))
      );
    }

    function shouldDisplayUserDetails() {
      return !!vm.isUserSelected;
    }

    function shouldDisplayUsername() {
      return !_.isEmpty(vm.user.userName);
    }

    function showPageBanner(options = {}) {
      const params = {
        body: options.message,
        header: options.title,
        variant: options.variant,
      };

      if (options.showLicenseBtn) {
        _.assign(params, {
          ctaText: $translate.instant('widgets.users.addUserModal.purchaseLicenses'),
          onCtaClick: () => {
            $state.go('account.account');
          },
        });
      }

      vm.displayMessage = new DisplayMessage(params);
    }

    function showProductProfileSelect() {
      return (
        !vm.isSubmitting &&
        vm.isReadyToSubmitAddUserForm &&
        vm.showAddProfileOnAddUserPanel &&
        (vm.canAssignType1User || !vm.user.getType().isType1())
      );
    }

    function submit() {
      vm.isSubmitting = true;
      vm.needsUserListRefresh = isAddingProductRole();
      hidePageBanner();

      vm.promiseToWait = addDefaultProfileIfNecessary()
        .then(setAnalyticsState)
        .then(save)
        .then(addProductRoleIfNecessary)
        .then(refreshUserListIfNecessary)
        .then(onSubmitSuccess)
        .catch(onSubmitError)
        .finally(() => {
          vm.isSubmitting = false;
        });

      function addDefaultProfileIfNecessary() {
        let promise = $q.resolve();
        if (vm.addingToProduct) {
          if (vm.targetProduct && vm.targetProduct.isTeam()) {
            const pageSize = 1;
            const productConfigurationList = ProductConfigurationList.get(
              vm.targetProduct,
              pageSize
            );
            promise = productConfigurationList.$promise;
            // eslint-disable-next-line promise/catch-or-return
            promise.then(() => {
              vm.user.products.push({
                id: vm.targetProduct.id,
                licenseGroups: productConfigurationList.items,
              });
            });
          }
        }
        return promise;
      }

      function addProductRoleIfNecessary() {
        // we only write the role if we're able to for the target user
        if (isAddingProductRole()) {
          const product = _.find(OrganizationManager.getProductsForActiveOrg().items, {
            id:
              vm.pageContext.target === PAGE_TARGET.PRODUCT
                ? vm.pageContext.targetId
                : vm.pageContext.targetParentId,
          });
          if (product.hasConfigurationSettingForLicenseGroupMember()) {
            vm.memberRoles.memberRoles[vm.user.id] = [vm.selectedProductRoleId];
            return vm.memberRoles.save();
          }
          return vm.userList.setMemberProductRole([vm.user], {id: vm.selectedProductRoleId});
        }
        return $q.resolve();
      }

      function isAddingProductRole() {
        return (
          vm.canSetProductRoles &&
          canSetProductRolesOnUser() &&
          vm.userList &&
          vm.selectedProductRoleId
        );
      }

      function refreshUserListIfNecessary() {
        if (vm.needsUserListRefresh && vm.userList) {
          vm.userList.filter.query = '';
          return searchOnlyContentMode ? vm.userList.reset() : vm.userList.refresh();
        }
        return $q.resolve();
      }

      function save() {
        let promise = $q.resolve();
        if (vm.user.isNew() || !vm.userList) {
          addContextResourceToUser();
          promise = vm.user.save();
          vm.needsUserListRefresh = true;
        } else if (vm.addingToProduct) {
          promise = addExistingUserToProduct();
        } else if (
          (vm.pageContext.target === PAGE_TARGET.STORAGE &&
            vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) ||
          vm.pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER
        ) {
          addContextResourceToUser();
          vm.userList.addModifiedUsers([vm.user]);
          // By default, this will refresh the list.
          promise = vm.userList.save();
        } else {
          vm.userList.add([vm.user.toMinimumModel()]);
          // By default, this will refresh the list.
          promise = vm.userList.save();
        }
        if (vm.initializeProfileSelectionForDevelopers) {
          vm.productsAndPLCSelectedForDevelopers = null;
        }

        return promise;
      }

      function setAnalyticsState() {
        vm.analyticsState = {
          newUser: vm.user.isNew(),
          products: vm.user.getUnsavedProductChanges(),
          userGroups: vm.user.getUnsavedUserGroupChanges(),
        };
      }
    }

    function triggerAnalytics() {
      const productList = OrganizationManager.getProductsForActiveOrg();
      const added = processProducts(vm.analyticsState.products.added);
      const removed = processProducts(vm.analyticsState.products.removed);
      const componentMethod = getComponentMethod();
      AnalyticsEvent.dispatch({
        attributes: {
          productsAddedOffers: added.offerIds.join(','),
          productsAddedProfileCount: added.licenseGroupCount,
          productsRemovedOffers: removed.offerIds.join(','),
          productsRemovedProfileCount: removed.licenseGroupCount,
          userGroupsAddedCount: _.get(vm.analyticsState.userGroups.added, 'length', 0),
          userGroupsRemovedCount: _.get(vm.analyticsState.userGroups.removed, 'length', 0),
        },
        componentMethod,
        componentMethodType: 'submit',
        // We differentiate the component name here for usage reports in Adobe Analytics
        componentName: vm.isDeveloperContext ? 'appAddDeveloperModal' : componentName,
        pageContext: vm.pageContext,
      });

      function getComponentMethod() {
        if (vm.analyticsState.newUser) {
          return vm.isDeveloperContext ? 'addDeveloper' : 'addUser';
        }
        return vm.isDeveloperContext ? 'editDeveloper' : 'editUser';
      }

      function processProducts(products) {
        const offerIds = [];
        let licenseGroupIds = [];
        _.forEach(products, (product) => {
          licenseGroupIds = _.union(licenseGroupIds, _.map(product.licenseGroups, 'id'));
          const productListProduct = _.find(productList.items, {id: product.id});
          if (product) {
            offerIds.push(productListProduct.offerId);
          }
        });
        return {licenseGroupCount: licenseGroupIds.length, offerIds};
      }
    }
  }
})();
/* eslint-enable max-lines */
/* eslint-enable max-statements */
