(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc service/provider
   * @name cvl
   * @description defines service to manage cvl resources
   */
  angular.module('app.core.api.cvl').provider('cvl', cvlProvider);

  let url;

  /* @ngInject */
  function cvlProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        event: getEventResource(),
      };

      return service;

      ///////////
      function getEventResource() {
        return $resource(
          `${url}/events`,
          {},
          {
            export: {
              headers: {
                Accept: 'application/vnd+adobe.cvl.events+csv',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            query: {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          }
        );
      }
    }
  }
})();
