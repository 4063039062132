(function () {
  angular.module('app.core.chat.ready', [
    'app.core.chat',
    'app.core.ready.org',
    'binky.core.authentication.ready',
    'binky.core.configuration.ready',
    'binky.core.feature',
    'binky.core.feature.ready',
    'binky.core.locale.ready',
    'binky.core.lodash',
  ]);
})();
