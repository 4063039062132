/**
 * @deprecated ported to src2/app/common/components/administrative-rights-detail-section/AdministrativeRightsDetailSection.jsx
 *
 * @ngdoc component
 * @name app.widgets.user:appAdminRightsDetailSection
 *
 * @description The Administrative Rights section used by the User Detail page.
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section. Supports
 *   only levels 2, 3 or 4. If an invalid level is supplied, it will be reset to the default 3.
 * @binding {Object} roles. An array of UserRoles.
 * @binding {Object} waitPromise. When resolved, roles is available.
 */
(function () {
  angular.module('app.widgets.users').component('appAdminRightsDetailSection', {
    bindings: {
      headingLevel: '<?',
      roles: '<',
      user: '<?',
      waitPromise: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/detail-sections/admin-rights/admin-rights-detail-section.component.html',
  });

  /* @ngInject */

  function controller(
    $element,
    $rootScope,
    $stateParams,
    $q,
    $timeout,
    $translate,
    _,
    adminAccess,
    binkyUISrc2,
    feature,
    GLOBAL_ADMIN_POLICY,
    onesieSrc2,
    OrganizationManager,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    PageContext,
    ROLE,
    storageAccess
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      AddAdminModal: onesieSrc2.users.components.AddAdminModal,
      addAdminModalId: 'add-admin-modal-admin-rights-detail-section',
      addAdminModalPageContext: new PageContext({
        target: PAGE_TARGET.ORGANIZATION,
        targetId: $stateParams.orgId,
        targetType: PAGE_TARGET_TYPE.ADMIN,
      }),
      adminRightsTitle: $translate.instant('widgets.users.detailSections.adminRights.title'),
      adminRoles: [],
      adminRolesPromiseResolved: false,
      binkyUISrc2,
      canEditAdminRights,
      onAddAdminModalClose,
      onAddAdminModalSuccess,
      openEditAdminRightsModal,
      orgId: $stateParams.orgId,
    });

    // eslint-disable-next-line complexity
    function $onChanges() {
      if (canEditAdminRights()) {
        vm.adminRolesPromise = adminAccess.getManageableAdminRoles().then((adminRoles) => {
          vm.adminRoles = adminRoles;
          vm.adminRolesPromiseResolved = true;
        });

        vm.noAdminRolesDescription = OrganizationManager.getPolicyValue(
          GLOBAL_ADMIN_POLICY.MANAGE_ADMINS
        )
          ? 'widgets.users.detailSections.adminRights.noAdminRoles.description'
          : 'widgets.users.detailSections.adminRights.noAdminRoles.descriptionWithoutManageAdminsPolicy';
      } else {
        vm.adminRolesPromise = $q.resolve();
        vm.adminRolesPromiseResolved = true;
        vm.noAdminRolesDescription = undefined;
      }

      const roles = vm.roles ? vm.roles : [];

      // these four roles are really 1:1 user:org
      vm.orgAdminRole = _.find(roles, {type: ROLE.ADMIN.ORG});
      vm.compartmentAdminRole = _.find(roles, {type: ROLE.ADMIN.COMPARTMENT_ADMIN});
      vm.compartmentViewerRole = _.find(roles, {type: ROLE.ADMIN.COMPARTMENT_VIEWER});
      vm.deploymentAdminRole = _.find(roles, {type: ROLE.ADMIN.DEPLOYMENT});
      if (storageAccess.canViewStorage()) {
        vm.storageAdminRole = _.find(roles, {type: ROLE.ADMIN.STORAGE});
      }
      vm.supportAdminRole = _.find(roles, {type: ROLE.ADMIN.SUPPORT});

      // the following roles all exist 1:1 in the response, but express targets underneath themselves
      vm.productAdminRoleTargets = extractTargets(ROLE.ADMIN.PRODUCT);
      vm.productSupportAdminRoleTargets = extractTargets(ROLE.ADMIN.PRODUCT_SUPPORT);
      vm.licenseAdminRoleTargets = extractTargets(ROLE.ADMIN.LICENSE);
      vm.userGroupRoleTargets = extractTargets(ROLE.ADMIN.USER_GROUP);

      vm.hasTargets =
        vm.productAdminRoleTargets.length > 0 ||
        vm.productSupportAdminRoleTargets.length > 0 ||
        vm.licenseAdminRoleTargets.length > 0 ||
        vm.userGroupRoleTargets.length > 0;
      if (storageAccess.canViewStorage()) {
        vm.hasAdminRoles =
          vm.orgAdminRole ||
          vm.compartmentAdminRole ||
          vm.compartmentViewerRole ||
          vm.deploymentAdminRole ||
          vm.storageAdminRole ||
          vm.supportAdminRole ||
          vm.hasTargets;
      } else {
        vm.hasAdminRoles =
          vm.orgAdminRole ||
          vm.compartmentAdminRole ||
          vm.compartmentViewerRole ||
          vm.deploymentAdminRole ||
          vm.supportAdminRole ||
          vm.hasTargets;
      }

      function extractTargets(type) {
        const role = _.find(roles, {type});
        return _.get(role, 'targets', []);
      }

      // Ensure that all accordion labels have the correct heading level.
      $timeout(() => {
        setAccordionHeadingLevels();
      });
    }

    function $onInit() {
      vm.canManageAdmins = adminAccess.canManageAdmins();

      vm.isAddAdminModalOpen = false;

      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      vm.headingLevel = vm.headingLevel || 3;
      if (vm.headingLevel < 2 || vm.headingLevel > 4) {
        vm.headingLevel = 3;
      }

      vm.policyActionList = [
        {
          actions: [
            $translate.instant(
              'widgets.users.detailSections.adminRights.globalAdmin.actions.editAdminRights'
            ),
          ],
          policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
        },
      ];
    }

    function canEditAdminRights() {
      return (
        adminAccess.canAssignAdmin() &&
        vm.user &&
        !vm.user.getType().isTechnicalAccount() &&
        // Some migration types override the read-only state to allow admins to be added
        // while setting up for the migration, however do not allow admins to be edited
        // in this state.
        !OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers()
      );
    }

    function onAddAdminModalSuccess() {
      onAddAdminModalClose();
    }

    function onAddAdminModalClose() {
      vm.isAddAdminModalOpen = false;
      $rootScope.$apply();
    }

    function openEditAdminRightsModal() {
      vm.isAddAdminModalOpen = true;
    }

    function setAccordionHeadingLevels() {
      if (vm.headingLevel === 2) {
        // We have nothing to do as the default accordion item level of h3 is fine.
        return;
      }

      // As we are using an old version of Coral accordion which doesn't allow us
      // to override the heading level, we need to 'correct' the heading hierarchy
      // after the items have all loaded. The attribute check will be done whenever
      // doCheck() runs, but the :not([aria-level]) applied in the selector means that
      // the update will only happen if aria-level has not already been set.
      const labels = $element[0].querySelectorAll('coral-accordion-item h3:not([aria-level])');
      if (labels.length > 0) {
        const accordionHeadingLevel = vm.headingLevel + 1;
        _.forEach(labels, (label) => {
          label.setAttribute('aria-level', accordionHeadingLevel);
        });
      }
    }
  }
})();
