(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.common.file-size').constant('FILE_SIZE_UNIT', {
    B: 'B',
    EB: 'EB',
    GB: 'GB',
    KB: 'KB',
    MB: 'MB',
    PB: 'PB',
    TB: 'TB',
    YB: 'YB',
    ZB: 'ZB',
  });
})();
