(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketTitleFormSection
   *
   * @description The title section for a support form which contains an input field.
   *
   * @binding {String} descriptionKey. The key for the description for this section.
   * @binding {String} headerKey. The key for the header for this section.
   * @binding {Function} onChange. Callback for when the title changes.  Callback param is title.
   * @binding {String} placeholderKey. The key for the placeholder for the title input.
   */
  angular.module('app.widgets.support').component('appSupportTicketTitleFormSection', {
    bindings: {
      descriptionKey: '@',
      headerKey: '@',
      onChange: '&',
      placeholderKey: '@',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-title-form-section/support-ticket-title-form-section.component.html',
  });

  function controller($translate, _) {
    const vm = this;
    const TITLE_MAX_LEN = 300;

    _.assign(vm, {
      onTitleChange,
      TITLE_MAX_LEN,
    });

    // Angular has already trimmed the whitespace from the model - vm.title.
    function onTitleChange() {
      // Display the max characters message if the trimmed value exceeds the max.
      vm.isTitleCharLimit = _.get(vm, 'title.length', 0) > TITLE_MAX_LEN;
      if (vm.isTitleCharLimit) {
        vm.title = vm.title.substr(0, TITLE_MAX_LEN);
      }

      vm.onChange({title: vm.title});
    }
  }
})();
