import {DetailsButton, PageActions, PageHeader} from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import AppIntegrationsGlobalPolicyModal from '../../components/AppIntegrationsGlobalPolicyModal';
import useAppIntegrationGlobalPolicy from '../../hooks/useAppIntegrationGlobalPolicy';

// TODO: Remove when React routing for AppIntegrationsPage is set up.
// This isn't an actual "subpage" - it's misnamed. It was implemented to be
// just the top header of a Page because React routing hadn't been setup yet.
const AppIntegrationsHeaderSubpage = () => {
  const intl = useIntl();

  const {globalPolicy} = useAppIntegrationGlobalPolicy({
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return (
    <Flex alignItems="center">
      <PageHeader
        title={intl.formatMessage({
          // TODO: remove this string when React routing to AppIntegrationsPage is complete
          id: 'products.appIntegrations.appIntegrationsPage.appIntegrationsHeaderSubpage.title',
        })}
      />
      {globalPolicy && (
        <PageActions>
          <DetailsButton detailsModal={<AppIntegrationsGlobalPolicyModal />} />
        </PageActions>
      )}
    </Flex>
  );
};
export default AppIntegrationsHeaderSubpage;
