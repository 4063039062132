/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.access-requests:appAcceptAccessRequestModal
 * @binding accessRequestList. Trust list filtered on TRUST_STATUS.PENDING status.
 * @binding onSuccess. Callback called if the accept access request call succeeds (inc. partial success).
 *
 * @description The modal is for the Owning Admin to accept an access request a directory or directories.
 */
(function () {
  angular.module('app.widgets.access-requests').component('appAcceptAccessRequestModal', {
    bindings: {
      accessRequestList: '<',
      onSuccess: '&',
    },
    controller,
    templateUrl:
      'app/widgets/access-requests/accept-access-request-modal/accept-access-request-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $translate,
    _,
    ACCEPT_ACCESS_REQUEST_MODAL_ID,
    DisplayMessage,
    panelManager,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      isNotifyAdminsChecked,
      onAcceptRequest,
      onModalOpen,
      onToggleNotifyAdmins,
    });

    function $onInit() {
      initialize();
    }

    function initialize() {
      _.assign(vm, {
        ACCEPT_ACCESS_REQUEST_MODAL_ID,
        displayMessage: hidePageBanner(),
        isBusy: false,
        notifyAdmins: false,
        waitPromise: $q.resolve(),
      });
    }

    function isNotifyAdminsChecked() {
      return vm.notifyAdmins;
    }

    function onAcceptRequest() {
      hidePageBanner();
      vm.isBusy = true;

      // On success, the list will be refreshed, but on failure, we don't want to leave requests with modified
      // notifyAdmin values.
      const items = _.cloneDeep(vm.accessRequestItems);
      _.forEach(items, (item) => {
        item.notifyAdmins = vm.notifyAdmins;
      });

      vm.waitPromise = vm.accessRequestList
        .acceptAccessRequests(items)
        .then(onSuccess, () => showPageBanner())
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess(responses) {
        const {completedItems, failedItems} = responses;

        if (failedItems.length > 0) {
          showPageBanner('someAccessRequestsNotAccepted');
        } else {
          panelManager.close(ACCEPT_ACCESS_REQUEST_MODAL_ID);
        }

        if (completedItems.length > 0) {
          showAcceptedToast({count: completedItems.length});
        }

        // Callback parent on success.
        vm.onSuccess();
      }
    }

    // params.acceptAll {Boolean} - true if Accept All button clicked rather than selection
    // params.accessRequestItems {Array} - selected items from accessRequestList
    function onModalOpen(params) {
      initialize();
      _.assign(vm, _.pick(params, ['acceptAll', 'accessRequestItems']));
    }

    function onToggleNotifyAdmins() {
      vm.notifyAdmins = !vm.notifyAdmins;
    }

    ///////////

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function showAcceptedToast(options) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.accessRequest.acceptAccessRequestModal.toasts.messageFormat.requestAccepted',
          options,
          'messageformat'
        )
      );
    }

    function showPageBanner(msg) {
      vm.displayMessage = new DisplayMessage({
        body: msg
          ? $translate.instant(`widgets.accessRequest.acceptAccessRequestModal.pageBanner.${msg}`)
          : undefined,
      });
    }
  }
})();
