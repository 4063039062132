import {feature} from '@admin-tribe/binky';
import React from 'react';

import {AddUsersToProductModalWrapper} from 'common/components/add-user-modals/AddUsersToProductModalWrapper';
import {canViewQuickAssignModal} from 'core/products/access/productAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import QuickAssignModalWrapper from 'features/mini-apps/quick-assign-modal-wrapper/QuickAssignModalWrapper';
import ReviewUserIntroductionsModalWrapper from 'features/overview/components/review-user-introductions-modal-wrapper/ReviewUserIntroductionsModalWrapper';

import OverviewPage from '../pages/OverviewPage';

import {goToOverview} from './navigation-callbacks/navigationCallbacks';
import {
  PATH_OVERVIEW,
  PATH_SEGMENT_ASSIGN_PRODUCTS,
  PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS,
  PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
} from './overviewPaths';
import {addProductsLoader, assignProductsLoader, overviewLoader} from './overviewRoutesLoaders';

/**
 * Gets all of the Overview section routes.
 * The redirect from / and /:orgId is still being handled by Angular, so we
 * rely on that to render the React overview.
 */
const overviewRoutes = () => {
  const childrenRoutes = [
    {
      element: null,
      id: 'overview.add-products',
      loader: addProductsLoader,
      path: 'add-products',
    },
    {
      element: <QuickAssignModalWrapper />,
      id: 'overview.quick-assign-products',
      loader: buildAccessCheckLoader(canViewQuickAssignModal),
      path: PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS,
    },
    {
      element: <AddUsersToProductModalWrapper onClose={goToOverview} />,
      id: 'overview.assign-products',
      loader: assignProductsLoader,
      path: PATH_SEGMENT_ASSIGN_PRODUCTS,
    },
  ];
  if (feature.isEnabled('temp_introductions_modal_deeplink')) {
    childrenRoutes.push({
      element: <ReviewUserIntroductionsModalWrapper onClose={goToOverview} />,
      loader: buildAccessCheckLoader(feature.isEnabled('temp_introductions_modal_deeplink')),
      path: PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
    });
  }
  if (feature.isEnabled('temp_saved_introductions_modal_deeplink')) {
    childrenRoutes.push({
      element: <ReviewUserIntroductionsModalWrapper onClose={goToOverview} startTab="saved" />,
      loader: buildAccessCheckLoader(feature.isEnabled('temp_saved_introductions_modal_deeplink')),
      path: PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
    });
  }
  return [
    {
      children: childrenRoutes,
      element: <OverviewPage />,
      loader: overviewLoader,
      path: PATH_OVERVIEW,
    },
  ];
};

export default overviewRoutes;
