import {Divider, Flex, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import DownloadButton from 'features/packages/components/server-setup-guide-drawer/download-button/DownloadButton';
import CreateServerService from 'features/packages/services/CreateServerService';
import LinuxIcon from 'features/packages/svgs/LinuxIcon.svg';
import WindowsIcon from 'features/packages/svgs/WindowsIcon.svg';

import {useCreateServerModalContext} from '../../CreateServerModalContext';

import DragAndDropAuthFile from './drag-and-drop-auth-file/DragAndDropAuthFile';

/**
 * This component is used to authorize FRL LAN servers.
 */
const AuthorizeServer = observer(() => {
  const intl = useIntl();
  const {packagesServerStore} = useCreateServerModalContext();

  // Check if any authorization file is already uploaded
  useEffect(() => {
    packagesServerStore.setServerSessionSettingValues(
      'nextButtonEnabled',
      packagesServerStore.serverSessionData.authorizationFileIdList.length > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- check on component mount
  }, []);

  // Call cancelUploadOperations on component unmount
  useEffect(
    () => () => {
      CreateServerService.cancelUploadOperations();
    },
    []
  );

  return (
    <View data-testid="authorize-server-container" marginTop="size-200">
      {!packagesServerStore.serverSessionSettings.editServerWorkflow && (
        <>
          <Flex direction="column">
            <Text
              UNSAFE_style={{
                fontSize: 'var(--spectrum-global-dimension-size-175)',
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage({id: 'packages.createServerModal.authorizeServer.title'})}
            </Text>
            <Text>
              {intl.formatMessage({id: 'packages.createServerModal.authorizeServer.titleDesc'})}
            </Text>
          </Flex>

          {/* server tool download section */}

          <Flex direction="row" marginTop="size-300">
            <View marginEnd="size-200">
              <DownloadButton
                data-testid="authorize-server-download-buttons"
                icon={LinuxIcon}
                label={intl.formatMessage({
                  id: 'packages.createServerModal.authorizeServer.downloadLinux',
                })}
                marginTop="size-100"
                url={rootStore.packagesUiConstantsStore.serverConfigToolMacUrl}
              />
            </View>
            <DownloadButton
              icon={WindowsIcon}
              label={intl.formatMessage({
                id: 'packages.createServerModal.authorizeServer.downloadWin',
              })}
              url={rootStore.packagesUiConstantsStore.serverConfigToolWinUrl}
            />
          </Flex>
          <Divider marginBottom="size-300" marginTop="size-300" size="S" />
        </>
      )}

      {/* file upload drag and drop section */}
      <Flex direction="column" marginBottom="size-300">
        <Text
          UNSAFE_style={{
            fontSize: 'var(--spectrum-global-dimension-size-175)',
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({
            id: 'packages.createServerModal.authorizeServer.uploadAuthFileTitle',
          })}
        </Text>
        <Text>
          {packagesServerStore.serverSessionSettings.editServerWorkflow === false
            ? intl.formatMessage({
                id: 'packages.createServerModal.authorizeServer.uploadAuthFileTitleDesc',
              })
            : intl.formatMessage({
                id: 'packages.createServerModal.editServer.authorizationDescription',
              })}
        </Text>
      </Flex>

      {/* drag and drop input area */}
      <DragAndDropAuthFile />
    </View>
  );
});

export default AuthorizeServer;
