import './SelectedApps.pcss';
import {ActionButton, SearchField} from '@adobe/react-spectrum';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Popover should be replaced with v3 Dialog
import Popover from '@react/react-spectrum/Popover';
import Alert from '@spectrum-icons/workflow/Alert';
import RailRightOpen from '@spectrum-icons/workflow/RailRightOpen';
import Search from '@spectrum-icons/workflow/Search';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {CREATE_PACKAGE} from '../../../../../packagesConstants';
import AppListTable from '../app-list-table/AppListTable';
import AppSystemReqDetails from '../app-system-req-details/AppSystemReqDetails';
import LicenseFile from '../license-file/LicenseFile';

const SelectedApps = ({
  focusPreviousButton,
  isEnterpriseSupportPolicyApplicable,
  packageCreateObject,
  pkgSessionSettings,
  propagateChangesToContainerView,
  selectedAppsSearchBtnRef,
  selectedAppsSearchFieldRef,
  selectedProducts,
  showLicenseFileEntry,
  systemReqPopoverId,
  updateManagementSettings,
}) => {
  // services
  const intl = useIntl();

  // shared states
  const packageSessionSettings = pkgSessionSettings;

  // states
  const [fiteredSelectedProducts, setFiteredSelectedProducts] = useState([]);
  const [showSelectedProductSearchBar, setShowSelectedProductSearchBar] = useState(false);
  const [selectedProductsSearchText, setSelectedProductsSearchText] = useState('');
  const [isAppWithSpecialSysReqSelected, setIsAppWithSpecialSysReqSelected] = useState(false);
  const [show, setShow] = useState(false);

  // refs
  const sysReqBtnRef = useRef(null);

  // No element in focus when enter key pressed on remove btn so
  // Method to shift focus on to Addition Sys Req/Previous Btn
  function focusNextElement(index) {
    // if the product being removed has special system requirements and no other product has special system requirements,
    // it should focus on previous button else on sysReqBtn
    if (
      isAppWithSpecialSysReqSelected &&
      !(
        fiteredSelectedProducts[index]?.hasSpecialSystemRequirements() &&
        fiteredSelectedProducts.filter((product) => product.hasSpecialSystemRequirements())
          .length === 1
      )
    ) {
      sysReqBtnRef.current?.focus();
    } else {
      focusPreviousButton();
    }
  }

  function selectedProductsFilter(product) {
    return (
      product.visible &&
      product.selected &&
      (!selectedProductsSearchText ||
        (selectedProductsSearchText &&
          product.name?.toUpperCase().includes(selectedProductsSearchText.toUpperCase())))
    );
  }

  function removeProduct(productToRemove, isRemoveAllOp) {
    const product = productToRemove;
    product.selected = false;
    if (selectedProducts.includes(product)) {
      selectedProducts.splice(selectedProducts.indexOf(product), 1);
    }
    if (!isRemoveAllOp) {
      updateManagementSettings();
    }
  }

  function removeAllProducts() {
    fiteredSelectedProducts.forEach((productToRemove) => {
      const product = productToRemove;
      // remove all except Core components (KCCC)
      if (product.sapCode !== 'KCCC') {
        product.selected = false;
        removeProduct(product, true);
      }
    });
    updateManagementSettings();
    removeLicenseFile();
  }

  function isRemoveAllDisabled() {
    return (
      (fiteredSelectedProducts.length === 0 &&
        (!packageSessionSettings.licenseFileSelected ||
          (packageSessionSettings.licenseFileSelected &&
            !packageSessionSettings.addRemoveOptionVisible))) ||
      (fiteredSelectedProducts.length === 1 && fiteredSelectedProducts[0].sapCode === 'KCCC')
    );
  }

  function showLicenseInSelectedProductsList() {
    return showLicenseFileEntry() && packageSessionSettings.licenseFileSelected;
  }

  function removeLicenseFile() {
    if (
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN
    ) {
      packageSessionSettings.licenseFileSelected = false;
      if (selectedProducts.length === 0) {
        packageSessionSettings.nextButtonEnabled = false;
      }
    }
    propagateChangesToContainerView();
  }

  // updates product view on when products added/removed, or filters change
  useEffect(() => {
    const newFiteredSelectedProducts = selectedProducts.filter(selectedProductsFilter);
    setIsAppWithSpecialSysReqSelected(
      newFiteredSelectedProducts.some((product) => product.hasSpecialSystemRequirements())
    );
    setFiteredSelectedProducts(newFiteredSelectedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger when listed props change
  }, [selectedProducts.length, selectedProducts[0], selectedProductsSearchText]);

  return (
    <div styleName="selected-products-container">
      <h4 aria-level={3} styleName="selected-products-heading">
        <span>
          {intl.formatMessage({
            id: 'packages.createPackage.chooseApps.selectedApps.title',
          })}
        </span>
        <span styleName="apps-header-count">({fiteredSelectedProducts.length})</span>
      </h4>
      <div>
        {intl.formatMessage({
          id: 'packages.createPackage.chooseApps.selectedApps.desc',
        })}
      </div>
      {showSelectedProductSearchBar && (
        <div styleName="product-filters">
          <SearchField
            ref={selectedAppsSearchFieldRef}
            aria-labelledby={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.searchPlaceholder',
            })}
            autoFocus
            onChange={(text) => {
              setSelectedProductsSearchText(text);
            }}
            onClear={() => {
              setSelectedProductsSearchText('');
            }}
            value={selectedProductsSearchText}
            width="328px"
          />
          <span styleName="search-close">
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.collapseSelectedApps',
              })}
              data-testid="search-close-button"
              onPress={() => {
                setShowSelectedProductSearchBar(false);
                setSelectedProductsSearchText('');
                selectedAppsSearchBtnRef.current.focus();
              }}
            >
              <RailRightOpen />
            </ActionButton>
          </span>
        </div>
      )}
      {!showSelectedProductSearchBar && (
        <div styleName="product-filters">
          <span>
            <ActionButton
              ref={selectedAppsSearchBtnRef}
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.selectedApps.searchSelectedApps',
              })}
              data-testid="search-open-button"
              density="compact"
              onPress={() => {
                setShowSelectedProductSearchBar(true);
              }}
            >
              <Search />
            </ActionButton>
          </span>
          <span styleName="add-remove-group">
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.selectedApps.removeAllAvailableApps',
              })}
              data-testid="remove-all-button"
              density="compact"
              isDisabled={isRemoveAllDisabled()}
              onPress={() => {
                document
                  .querySelector(
                    "[class='previous-button ng-binding _coral-Button _coral-Button--secondary']"
                  )
                  .focus();
                removeAllProducts();
              }}
            >
              {intl.formatMessage({
                id: 'packages.createPackage.chooseApps.selectedApps.removeAll',
              })}
            </ActionButton>
          </span>
        </div>
      )}
      <div
        styleName={
          isAppWithSpecialSysReqSelected ? 'app-list-container-with-warning' : 'app-list-container'
        }
      >
        {showLicenseInSelectedProductsList() && (
          <LicenseFile
            addRemoveOptionVisible={!!packageSessionSettings.addRemoveOptionVisible}
            isLicenseFileSelected={!!packageSessionSettings.licenseFileSelected}
            onRemoveProduct={removeLicenseFile}
          />
        )}
        <AppListTable
          focusNextElement={focusNextElement}
          onRemoveProduct={removeProduct}
          pkgCreateObj={packageCreateObject}
          products={fiteredSelectedProducts}
          showLearnMoreLink
          showLTSLink={isEnterpriseSupportPolicyApplicable}
          showRemoveButton
          showSysReqLink
        />
      </div>
      {isAppWithSpecialSysReqSelected && (
        <div styleName="sys-req-popover-root">
          <Alert color="notice" marginEnd="10px" size="S" />
          <OverlayTrigger
            data-testid="sys-req-overlay-trigger"
            onHide={() => {
              setShow(false);
            }}
            placement="top"
            show={show}
            trigger="click"
          >
            <ActionButton
              ref={sysReqBtnRef}
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.sysReq.actionLink',
              })}
              data-testid="sys-req-button"
              isQuiet
              onPress={() => {
                setShow(true);
              }}
            >
              {intl.formatMessage({
                id: 'packages.createPackage.chooseApps.sysReq.actionLink',
              })}
            </ActionButton>
            <Popover
              id={systemReqPopoverId}
              placement="top"
              title={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.sysReq.title',
              })}
            >
              <AppSystemReqDetails
                intl={intl}
                products={fiteredSelectedProducts.filter((product) =>
                  product.hasSpecialSystemRequirements()
                )}
              />
            </Popover>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
};

SelectedApps.propTypes = {
  /**
   * Method to focus previous button
   */
  focusPreviousButton: PropTypes.func,
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Shared object for package create object
   */
  packageCreateObject: PropTypes.instanceOf(Object).isRequired,
  /**
   * Shared object for package creation session
   */
  pkgSessionSettings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Method to push changes to Angular scope
   */
  propagateChangesToContainerView: PropTypes.func.isRequired,
  /**
   * Reference to selected apps search button
   */
  selectedAppsSearchBtnRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * Reference to selected apps search field
   */
  selectedAppsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * List of currently selected products
   */
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
  /**
   * Method to check if file entry should be shown or not, returns true if it should be
   */
  showLicenseFileEntry: PropTypes.func.isRequired,
  /**
   * Id for system requirement popover
   */
  systemReqPopoverId: PropTypes.string.isRequired,
  /**
   * Method to update management settings for the given context
   */
  updateManagementSettings: PropTypes.func.isRequired,
};

export default SelectedApps;
