import {
  showError as showErrorToast,
  showSuccess as showSuccessToast,
  useStore,
} from '@admin-tribe/binky-ui';
import {
  ActionButton,
  Button,
  DialogContainer,
  Flex,
  Item,
  Menu,
  MenuTrigger,
} from '@adobe/react-spectrum';
import More from '@spectrum-icons/workflow/More';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import RemoveIdpModal from 'features/settings/components/directory/authentication/remove-idp-modal/RemoveIdpModal';
import SetDefaultIdpModal from 'features/settings/components/directory/authentication/set-default-idp-modal/SetDefaultIdpModal';
import IdpEntity from 'features/settings/entities/IdpEntity';
import useIdpName from 'features/settings/hooks/useIdpName';
import IdpStore from 'features/settings/stores/IdpStore';

import './IdpCard.pcss';

const DIALOGS = {
  REMOVE_IDP: Symbol('remove-idp'),
  SET_DEFAULT_IDP: Symbol('set-default-idp'),
};

// A set of priorities defined for the IdP
// card buttons described below.
//
// 1 - This is the primary button that the user sees, positioned on the right
// 2 - This is the secondary button that the user sees, positioned on the right, before 1
// 3 - These are buttons that will get thrown in the dropdown menu.
const PRIORITIES = {
  CTA: 1,
  DROPDOWN: 3,
  SECONDARY: 2,
};

// This is a list of all the possible actions
// available on an IdP card
const ACTIONS = {
  CONFIGURE: 'configure',
  DISABLE: 'disable',
  EDIT: 'edit',
  ENABLE: 'enable',
  MAKE_DEFAULT: 'makeDefault',
  REMOVE: 'remove',
  TEST: 'test',
};

/**
 * Gets the priority for the edit button
 */
const getEditPriority = (idp) => {
  if (idp.isSoidc && !idp.isGoogleSoidc) {
    return PRIORITIES.SECONDARY;
  }

  if (idp.isGoogleSoidc && !idp.isActive) {
    return PRIORITIES.SECONDARY;
  }

  if (idp.isDefault) {
    return PRIORITIES.CTA;
  }

  return PRIORITIES.DROPDOWN;
};

const getEnableAndDisablePriority = (idp) => {
  if (idp.canBeDefault && idp.isTestable) {
    return PRIORITIES.DROPDOWN;
  }

  return PRIORITIES.CTA;
};

const getRemovePriority = (idp) =>
  idp.isActive || idp.isSoidc ? PRIORITIES.DROPDOWN : PRIORITIES.SECONDARY;

/**
 * Gets the priority for the provided action based on the IdP
 *
 * @param idp An IdP entity
 * @param action The action. Can be any value from the `ACTIONS` constant.
 *
 * @return {number} Returns a priority (defined in `PRIORITIES`)
 */
const getPriority = (idp, action) => {
  switch (action) {
    case ACTIONS.CONFIGURE:
      return PRIORITIES.CTA;
    case ACTIONS.EDIT:
      return getEditPriority(idp);
    case ACTIONS.ENABLE:
    case ACTIONS.DISABLE:
      return getEnableAndDisablePriority(idp);
    case ACTIONS.MAKE_DEFAULT:
      return PRIORITIES.CTA;
    case ACTIONS.REMOVE:
      return getRemovePriority(idp);
    case ACTIONS.TEST:
      return PRIORITIES.SECONDARY;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Ignored the default as an unknown action would never be visible
    // istanbul ignore next
    default:
      return PRIORITIES.DROPDOWN;
  }
};

// Set of rules used to determine if a button is visible or not
const canDisplayConfigureButton = (idp) => idp.canBeConfigured;
const canDisplayDisableButton = (idp) => idp.isSoidc && idp.isActive && !idp.isDefault;
const canDisplayEditButton = (idp) => !idp.canBeConfigured;
const canDisplayEnableButton = (idp) => idp.isSoidc && !idp.isActive && !idp.isDefault;
const canDisplayMakeDefaultButton = (idp) => idp.canBeDefault;
const canDisplayRemoveButton = (idp) => !idp.isDefault;
const canDisplayTestButton = (idp) => idp.isTestable;

// Gets the availability of an action for a provided IdP
const isActionAvailableForIdp = (action, idp) => {
  switch (action) {
    case ACTIONS.CONFIGURE:
      return canDisplayConfigureButton(idp);
    case ACTIONS.DISABLE:
      return canDisplayDisableButton(idp);
    case ACTIONS.EDIT:
      return canDisplayEditButton(idp);
    case ACTIONS.ENABLE:
      return canDisplayEnableButton(idp);
    case ACTIONS.MAKE_DEFAULT:
      return canDisplayMakeDefaultButton(idp);
    case ACTIONS.REMOVE:
      return canDisplayRemoveButton(idp);
    case ACTIONS.TEST:
      return canDisplayTestButton(idp);
    default:
      return false;
  }
};

const IdpCardFooter = ({idp, refreshData, onEdit}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialog, setDialog] = useState(null);

  const store = useStore(() => new IdpStore(idp));
  const intl = useIntl();

  const idpName = useIdpName(idp);

  // set the provided idp on the store
  useEffect(() => {
    store.setIdp(idp);
  }, [store, idp]);

  const onRemove = () => setDialog(DIALOGS.REMOVE_IDP);

  const onSetDefault = () => setDialog(DIALOGS.SET_DEFAULT_IDP);

  const buttons = useMemo(
    () =>
      Object.values(ACTIONS)
        .filter((action) => isActionAvailableForIdp(action, idp))
        // Map to an object containing the id and a priority for that action
        .map((action) => ({
          id: action,
          priority: getPriority(idp, action),
        }))
        // Sort by priority in descending order to make sure the PRIMARY action is always displayed first
        .sort((a, b) => b.priority - a.priority)
        .reduce(
          (actions, action) => {
            if (action.priority === PRIORITIES.DROPDOWN) {
              return {
                ...actions,
                dropdown: [...actions.dropdown, action],
              };
            }
            return {
              ...actions,
              primary: [...actions.primary, action],
            };
          },
          {dropdown: [], primary: []}
        ),
    [idp]
  );

  const onUpdateIdpStatus = async () => {
    try {
      await store.updateIdpStatus();

      showSuccessToast(
        intl.formatMessage(
          {
            id: idp.isDisabled
              ? 'settings.authentication.toasts.enabledIdp'
              : 'settings.authentication.toasts.disabledIdp',
          },
          {idpName}
        )
      );

      refreshData();
    } catch (error) {
      showErrorToast(
        intl.formatMessage(
          {
            id: idp.isDisabled
              ? 'settings.authentication.toasts.errorEnabling'
              : 'settings.authentication.toasts.errorDisabling',
          },
          {idpName}
        )
      );
    }
  };

  const onAction = async (actionId) => {
    switch (actionId) {
      case ACTIONS.CONFIGURE:
      case ACTIONS.EDIT:
        onEdit();
        break;
      case ACTIONS.REMOVE:
        onRemove();
        break;
      case ACTIONS.MAKE_DEFAULT:
        onSetDefault();
        break;
      case ACTIONS.DISABLE:
      case ACTIONS.ENABLE:
        await onUpdateIdpStatus();
        break;
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Ignored the default as an unknown action would never be visible
      // istanbul ignore next
      default:
        // DO NOTHING HERE
        break;
    }
  };

  return (
    <>
      <Flex minHeight="size-400" paddingTop="size-75">
        <Flex data-testid="footer-secondary" flexGrow={1} justifyContent="flex-start">
          {buttons.dropdown.length > 0 && (
            <MenuTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
              <ActionButton
                aria-label={intl.formatMessage({
                  id: 'settings.authentication.idpActions.moreActionsLabel',
                })}
                data-testid="idp-menu-button"
                isQuiet
              >
                <More />
              </ActionButton>
              <Menu data-testid="idp-actions-menu-list" onAction={onAction}>
                {buttons.dropdown.map((button) => (
                  <Item key={button.id} data-testid={`${button.id}-button`}>
                    {intl.formatMessage({id: `settings.authentication.idpActions.${button.id}`})}
                  </Item>
                ))}
              </Menu>
            </MenuTrigger>
          )}
        </Flex>

        <Flex data-testid="footer-primary" justifyContent="flex-end">
          {buttons.primary.map((button) => {
            if (button.id === ACTIONS.TEST) {
              return (
                <Button
                  key={button.id}
                  data-testid={`${button.id}-button`}
                  elementType="a"
                  href={idp.testUrl}
                  marginStart="size-200"
                  target="_blank"
                  variant="secondary"
                >
                  <FormattedMessage id={`settings.authentication.idpActions.${button.id}`} />
                </Button>
              );
            }

            return (
              <Button
                key={button.id}
                data-testid={`${button.id}-button`}
                marginStart="size-200"
                onPress={() => onAction(button.id)}
                variant={button.priority === PRIORITIES.CTA ? 'primary' : 'secondary'}
              >
                <FormattedMessage id={`settings.authentication.idpActions.${button.id}`} />
              </Button>
            );
          })}
        </Flex>
      </Flex>

      <DialogContainer onDismiss={() => setDialog(null)}>
        {dialog === DIALOGS.REMOVE_IDP ? (
          <RemoveIdpModal idpId={idp.id} idpName={idpName} refreshData={refreshData} />
        ) : null}

        {dialog === DIALOGS.SET_DEFAULT_IDP ? (
          <SetDefaultIdpModal idp={idp} refreshData={refreshData} />
        ) : null}
      </DialogContainer>
    </>
  );
};

IdpCardFooter.propTypes = {
  callbacks: PropTypes.shape({
    editIdp: PropTypes.func,
    makeDefaultIdp: PropTypes.func,
    removeIdp: PropTypes.func,
    updateIdpStatusConfirm: PropTypes.func,
  }),
  idp: PropTypes.instanceOf(IdpEntity).isRequired,
  onEdit: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
};

export default observer(IdpCardFooter);
export {ACTIONS};
