(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.status-tag:appStatusTag
   * @description A status tag component to show a given text styled
   * based on one of the supported variants
   *
   * @param {string} label Translated label text.
   * @param {string} [variant] Optional variant type of tag, which
   * inernally maps to the corresponding <app-tag> color.
   * One of: 'default', 'info', 'success', 'warning'.
   * Defaults to 'default'.
   * @example <app-status-tag
   *            label="label"
   *            variant="success">
   *          </app-status-tag>
   */
  angular.module('app.widgets.status-tag').component('appStatusTag', {
    bindings: {
      label: '@',
      variant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/common/status-tag/status-tag.component.html',
  });

  function controller(_) {
    const VARIANT_TO_COLOR_MAP = {
      DEFAULT: 'grey',
      INFO: 'blue',
      SUCCESS: 'green',
      WARNING: 'orange',
    };

    const vm = this;

    _.assign(vm, {
      getColor,
    });

    function getColor() {
      return VARIANT_TO_COLOR_MAP[_.upperCase(vm.variant)] || VARIANT_TO_COLOR_MAP.DEFAULT;
    }
  }
})();
