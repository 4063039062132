import {Cache, getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

const JIL_DNS_TOKEN_CACHE_ID = 'jilDnsTokenCacheId';
let cache, clientId, includeRoles, url;

/**
 * Configure JIL custom data APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
  cache = new Cache();
};

const getToken = async (orgId) => {
  const cacheId = `${JIL_DNS_TOKEN_CACHE_ID}/${orgId}`;
  const cachedResponse = cache.get(cacheId);

  if (cachedResponse) {
    return cachedResponse;
  }

  const response = await axios.get(`${url}/${orgId}/token`, {
    headers: getHeaders({clientId, includeRoles}),
  });

  // add the response to the cache and make it never expire. The cache is stored based on organization id
  // and it's cleared when browser refreshes as this data doesn't change.
  cache.put(cacheId, response, {lifetime: null});

  return response;
};

const jilDnsToken = {configure, getToken};

export default jilDnsToken;
export {JIL_DNS_TOKEN_CACHE_ID};
