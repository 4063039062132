(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.card-button-list:appCardButtonList
   * @description UI widget for displaying a card group
   * @param {Function} [onSelect] optional callback invoked of the card button that is clicked. (Invoked with the ID of the card)
   * @param {Object} [selectedCardButton] indicates the ID of the card button currently selected.
   * @param {String} [variant] Optional variant type. For example: colored (width is adjusted to device width) or
   *   colored-wide (fixed width card).
   */
  angular.module('app.widgets.card-button-list').component('appCardButtonList', {
    bindings: {
      onSelect: '=?',
      selectedCardButton: '=?',
      shouldSelectDefault: '=?',
      variant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/common/card-button-list/card-button-list.component.html',
    transclude: true,
  });

  function controller(_, modalHelper) {
    const vm = this;
    const registeredCardButtons = [];

    _.assign(vm, {
      deregister,
      isCardButtonSelected,
      register,
      selectCardButton,
    });

    //////////////

    function deregister(cardButtonId) {
      if (!_.includes(registeredCardButtons, cardButtonId)) {
        throw new Error(
          `Unable to deregister appCardButton with id ${cardButtonId} which does not exist`
        );
      }
      _.pull(registeredCardButtons, cardButtonId);
      if (vm.selectedCardButton === cardButtonId && registeredCardButtons.length > 0) {
        vm.selectedCardButton = registeredCardButtons[0];
      }
    }

    function isCardButtonSelected(cardButtonId) {
      return vm.selectedCardButton === cardButtonId;
    }

    function register(cardButtonId) {
      if (_.includes(registeredCardButtons, cardButtonId)) {
        throw new Error(
          `Unabled to register appCardButton with id ${cardButtonId} which is already registered`
        );
      }
      if (_.isEmpty(registeredCardButtons) && vm.shouldSelectDefault && !vm.selectedCardButton) {
        vm.selectedCardButton = cardButtonId;
      }
      registeredCardButtons.push(cardButtonId);
    }

    /**
     * Update cardButtonList's selected card and modal context based on the card button id.
     * @param {String} cardButtonId the card button id
     * @param {String} [idTag] the card selector to update the modal context
     */
    function selectCardButton(cardButtonId, idTag) {
      if (!_.includes(registeredCardButtons, cardButtonId)) {
        throw new Error(
          `Unabled to select appCardButton with id ${cardButtonId} which is not registered`
        );
      }
      vm.selectedCardButton = cardButtonId;

      if (idTag) {
        const componentSelector = `#${idTag}`;

        // If there is an open modal that contains the current card button list,
        // we update the modal context based on the card button id.
        modalHelper.updateModalContextIfExists(cardButtonId, componentSelector);
      }
    }
  }
})();
