// eslint-disable-next-line filenames/match-exported -- context
import {Contract} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';

const AddAdminModalContext = React.createContext({});
const useAdminModalContext = () => useContext(AddAdminModalContext);

const AddAdminModalContextProvider = ({
  children,
  contractList,
  initialUser = null,
  orgId,
  products,
  setModalError,
  setModalErrorProps,
}) => {
  const [user, setUser] = useState(initialUser);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [hasUnsavedRoleChanges, setHasUnsavedRoleChanges] = useState(true);
  const [roleValidityStatus, setRoleValidityStatus] = useState({});

  const value = useMemo(
    () => ({
      contractList,
      hasUnsavedRoleChanges,
      initialUser,
      isLoadingUser,
      orgId,
      products,
      roleValidityStatus,
      setHasUnsavedRoleChanges,
      setIsLoadingUser,
      setModalError,
      setModalErrorProps,
      setRoleValidityStatus,
      setUser,
      user,
    }),
    [
      contractList,
      hasUnsavedRoleChanges,
      initialUser,
      isLoadingUser,
      orgId,
      products,
      roleValidityStatus,
      setHasUnsavedRoleChanges,
      setIsLoadingUser,
      setModalError,
      setModalErrorProps,
      setRoleValidityStatus,
      setUser,
      user,
    ]
  );

  return (
    <AddAdminModalContext.Provider value={value}>
      <AddAdminModalContext.Consumer>{children}</AddAdminModalContext.Consumer>
    </AddAdminModalContext.Provider>
  );
};

AddAdminModalContextProvider.propTypes = {
  children: PropTypes.func,
  contractList: PropTypes.arrayOf(PropTypes.instanceOf(Contract)),
  // eslint-disable-next-line react/forbid-prop-types -- user object
  initialUser: PropTypes.object,
  orgId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  products: PropTypes.arrayOf(PropTypes.object),
  setModalError: PropTypes.func.isRequired,
  setModalErrorProps: PropTypes.func.isRequired,
};

export {useAdminModalContext};
export default AddAdminModalContextProvider;
