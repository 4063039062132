(function () {
  /**
   * @ngdoc module
   * @name app.widgets.users
   * @description module that defines users widgets
   */
  angular.module('app.widgets.users', [
    'app.core.admin.access',
    'app.core.analytics',
    'app.core.api.jil',
    'app.core.auth',
    'app.core.contract.contract-utils',
    'app.core.directories',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.core.product.access',
    'app.core.product.configurations.member-roles',
    'app.core.product.org-consumables',
    'app.core.product.trial-helper',
    'app.core.remove-on-read-only',
    'app.core.user',
    'app.core.user-folders',
    'app.core.user-group.access',
    'app.core.user.access',
    'app.widgets.avatar',
    'app.widgets.bulk-operations.context',
    'app.widgets.bulk-operations.form',
    'app.widgets.bulk-operations.localization',
    'app.widgets.bulk-operations.utils',
    'app.widgets.developers.remove-developer-modal',
    'app.widgets.directories',
    'app.widgets.integrations',
    'app.widgets.products',
    'app.widgets.products.product-chiclet',
    'app.widgets.products.purchase.add-products-modal',
    'app.widgets.remove-on-read-only',
    'app.widgets.tabview',
    'app.widgets.user-groups',
    'app.widgets.users.user-autocomplete',
    'binky.core.api.jil',
    'binky.core.common.country',
    'binky.core.common.selection',
    'binky.core.download-utils',
    'binky.core.feature',
    'binky.core.locale',
    'binky.core.lodash',
    'binky.core.organizations.user',
    'binky.core.page-context',
    'binky.core.product',
    'binky.core.translation-utils',
    'binky.shell.panels.modal',
    'binky.shell.workspace.page.sub-page',
    'binky.ui-migration',
    'binky.widgets.common.accordion',
    'binky.widgets.common.autocomplete',
    'binky.widgets.common.avatar',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.display-message',
    'binky.widgets.common.drawer-header.drawer-header-icon',
    'binky.widgets.common.events',
    'binky.widgets.common.feature',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.member.member-list-table',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.member.member-type',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.toggle',
    'binky.widgets.products.product-accordion',
    'binky.widgets.products.product-assignment',
    'binky.widgets.products.product-assignment-pulldown',
    'binky.widgets.products.product-name',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
