(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appRequestExpertSessionModal
   *
   * @description The modal to request a new expert session. It is opened by the Session Type Selection modal.
   *
   *  When opening, the caseType should be specified as either SUPPORT_TICKET_CASE_TYPE ADMIN or PRODUCT.
   */
  const componentName = 'appRequestExpertSessionModal';
  angular.module('app.widgets.support').component(componentName, {
    controller,
    templateUrl:
      'app/widgets/support/request-expert-session-modal/request-expert-session-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $state,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    AnalyticsHelper,
    CANCEL_SESSION_REQUEST_MODAL_ID,
    DisplayMessage,
    ExpertSession,
    feature,
    onesieSrc2,
    SESSION_TYPE_SELECTION_MODAL_ID,
    REQUEST_EXPERT_SESSION_FETCH_TOPICS_ERROR,
    REQUEST_EXPERT_SESSION_MODAL_ID,
    SUPPORT_TICKET_CASE_TYPE,
    panelManager,
    toastManager
  ) {
    const vm = this;
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;

    let expertSession, formIsInvalid;

    _.assign(vm, {
      backHandler,
      cancelHandler,
      ctaHandler,
      isCtaDisabled,
      onDiscardSession,
      onFormChange,
      onFormError,
      onOpen,
      onResumeSession,
      REQUEST_EXPERT_SESSION_MODAL_ID,
    });

    // Close this model and reopen the session type selection modal, with the card for the caseType selected.
    function backHandler() {
      panelManager.close(REQUEST_EXPERT_SESSION_MODAL_ID);
      panelManager.open(SESSION_TYPE_SELECTION_MODAL_ID, {caseType: vm.caseType});
      triggerAnalytics('backToSelectionButton');
    }

    // Confirm the admin really wants to cancel.
    function cancelHandler() {
      // wrap in timeout to ensure view is updated immediately
      // see: https://git.corp.adobe.com/admin-tribe/onesie/issues/8989
      $timeout(() => {
        panelManager.open(CANCEL_SESSION_REQUEST_MODAL_ID);
      });
    }

    function ctaHandler() {
      createExpertSession();
      AnalyticsHelper.dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'expertSessionConfirm',
      });
      triggerAnalytics('submitButton');
    }

    function isCtaDisabled() {
      return formIsInvalid;
    }

    // Abandon the session request process.
    function onDiscardSession() {
      panelManager.close(REQUEST_EXPERT_SESSION_MODAL_ID);
      triggerAnalytics('discardButton');
    }

    // Callback from form when validity changes.
    function onFormChange(event) {
      formIsInvalid = event.isInvalid;
      expertSession = event.expertSession;
    }

    function onFormError(event = {}) {
      if (event.showMessage && event.error === REQUEST_EXPERT_SESSION_FETCH_TOPICS_ERROR) {
        showFetchTopicsError();
      } else {
        hideErrorBanner();
      }
    }

    /**
     * @param {Object} options - options for the modal
     * @param {SUPPORT_TICKET_CASE_TYPE} options.caseType - Required. Either ADMIN or PRODUCT.
     */
    function onOpen(options) {
      resetModal();

      // caseType is required but default to admin.
      vm.caseType =
        options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
          ? SUPPORT_TICKET_CASE_TYPE.PRODUCT
          : SUPPORT_TICKET_CASE_TYPE.ADMIN;

      vm.typeCode =
        options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
          ? SUPPORT_CONSTANTS.SUPPORT_CASE_TYPE.PRODUCT
          : SUPPORT_CONSTANTS.SUPPORT_CASE_TYPE.ADMIN;

      vm.headerKey = vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT ? '.product' : '.admin';

      if (vm.caseType === SUPPORT_TICKET_CASE_TYPE.ADMIN) {
        getAdminSessionTopics();
      }
    }

    // Resume the session request process.
    function onResumeSession() {
      triggerAnalytics('resumeButton');
    }

    /////////////////

    function createExpertSession() {
      const supportTicket = _.assign(
        {
          caseType: vm.caseType,
        },
        expertSession
      );

      _.assign(supportTicket, {typeCode: vm.typeCode});

      hideErrorBanner();

      vm.isBusy = true;
      vm.waitPromise = ExpertSession.create(supportTicket)
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess(response) {
        panelManager.close(REQUEST_EXPERT_SESSION_MODAL_ID);

        const toastMsg = $translate.instant(
          'widgets.support.requestExpertSessionModal.toast.expertSessionCreated',
          {
            expertSessionId: response.id,
          }
        );
        toastManager.showSuccessToast(toastMsg);

        $state.go('org.support.expert-session-details', response);
      }

      function onError() {
        showErrorBanner();
      }
    }

    function getAdminSessionTopics() {
      vm.isBusy = true;
      vm.waitPromise = ExpertSession.getMetadata({
        caseType: vm.caseType,
      })
        .then((response) => {
          vm.adminTopics = response.topics;
        })
        .catch(() => {
          showFetchTopicsError();
        })
        .finally(() => {
          vm.isBusy = false;
        });
    }

    function hideErrorBanner() {
      vm.displayMessage = null;
    }

    function resetModal() {
      formIsInvalid = true;

      _.assign(vm, {
        isBusy: false,
        waitPromise: $q.resolve(),
      });

      hideErrorBanner();
    }

    function showErrorBanner(displayMessage) {
      vm.displayMessage = displayMessage ? displayMessage : new DisplayMessage();
    }

    function showFetchTopicsError() {
      const displayMessage = new DisplayMessage({
        body: $translate.instant(
          'widgets.support.requestExpertSessionModal.displayMessage.fetchTopicsError'
        ),
      });
      showErrorBanner(displayMessage);
    }

    function triggerAnalytics(componentMethod) {
      AnalyticsEvent.dispatch({
        componentMethod,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
