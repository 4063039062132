import {PRODUCT_COMPLIANCE_SYMPTOM, Product} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';
import {when} from 'mobx';

import rootStore from 'core/RootStore';

/**
 *
 * @name PackagesFrlOfferService
 * @description A wrapper over Product class to add some utility methods for FRL stuff related to packages
 */

class PackagesFrlOfferService extends Product {
  /**
   * @description Creates a new PackagesFrlOffer for use.
   * @returns {PackagesFrlOfferService} PackagesFrlOfferService model object.
   */
  static get(productObject) {
    const model = new PackagesFrlOfferService(productObject);
    return model.refresh(productObject);
  }

  constructor(options) {
    const productObject = cloneDeep(options);
    // since the Product constructor expects 'options.fulfillableItems' but the Product model no
    // longer has the tag, we explicitly set it here before calling the Product constructor.
    productObject.fulfillableItems = cloneDeep(options.fulfillableItemList.items);
    super(productObject);
  }

  /**
   * @description Method to get icon URL for the offer
   *
   * @returns {String} Icon URL
   */
  getIconUrl() {
    const ICON_SIZE_128 = '128x128';
    const ICON_SIZE_48 = '48x48';
    const ICON_SIZE_16 = '16x16';

    if (this.assets?.icons?.[ICON_SIZE_128]) {
      return this.assets.icons[ICON_SIZE_128];
    }
    if (this.assets?.icons?.[ICON_SIZE_48]) {
      return this.assets.icons[ICON_SIZE_48];
    }
    if (this.assets?.icons?.[ICON_SIZE_16]) {
      return this.assets.icons[ICON_SIZE_16];
    }
    if (this.assets?.icons?.svg) {
      return this.assets.icons.svg;
    }
    return false;
  }

  /**
   * @description Method to get fulfillable items that are packageable from offer
   *
   * @returns {Array} Fulfillable items that are packageable
   */
  getPackageableFulfillableItems() {
    return this.fulfillableItemList
      .getDesktopTypeItems()
      .filter((item) =>
        Object.keys(rootStore.packagesUiConstantsStore.fiCodeSapCodeMap).includes(item.code)
      );
  }

  /**
   * @description Method to get SAP codes for fulfillable items that are packageable from offer
   *
   * @returns {Array} SAP codes for fulfillable items that are packageable
   */
  getPackageableProductSapCodes() {
    return this.getPackageableFulfillableItems()
      .flatMap((item) => rootStore.packagesUiConstantsStore.getSapCodeFromFiCode(item.code))
      .filter((item) => !!item);
  }

  /**
   * @description Assigns expiry date based on first contract for product
   *
   * @returns {PackagesFrlOfferService} PackagesFrlOfferService instance.
   */
  async refresh(productObject) {
    let fallBackToEndDate = true;
    const offerTuple = this.licenseTupleList?.items.filter((tuple) =>
      this.hasContractId(tuple.contractId)
    );
    offerTuple?.forEach((tuple) => {
      const canAccessUntilComplianceSymptoms = tuple.complianceSymptoms.find(
        (complianceSymptom) =>
          complianceSymptom.name === PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS_UNTIL
      );
      if (canAccessUntilComplianceSymptoms) {
        Object.assign(this, {expiryDate: canAccessUntilComplianceSymptoms.value});
        fallBackToEndDate = false;
      }
    });
    if (fallBackToEndDate) {
      await when(() => !!rootStore.organizationStore.contractList);
      const contractList = rootStore.organizationStore.contractList;
      const contract = contractList.items.find((item) => productObject.hasContractId(item.id));
      if (contract && !contract.isBuyingProgramVIP() && contract.getEndDate()) {
        Object.assign(this, {expiryDate: contract.getEndDate()});
      } else if (contract && contract.isBuyingProgramVIP() && contract.getAnniversaryDate()) {
        Object.assign(this, {expiryDate: contract.getAnniversaryDate()});
      }
    }
    return this;
  }
}

export default PackagesFrlOfferService;
