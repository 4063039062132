import {log} from '@admin-tribe/binky';
import {
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@admin-tribe/binky-ui';
import {Flex, TextArea} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import CustomData from '../../entities/custom-data/CustomData';

/**
 * The modal used to create or update a CustomData note.
 */
const EditNoteModal = ({description, header, isOpen, onSuccess, onCancel, note}) => {
  const [modalError, setModalError] = useState(null);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [noteValue, setNoteValue] = useState(note.value);
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const ctaToastGenerator = () => intl.formatMessage({id: 'common.toast.modal.notesAdded'});

  const onClose = () => {
    setModalError(null);
    onCancel();
  };

  const onCta = async () => {
    let isSuccess = false;
    setIsSavingNote(true);

    try {
      await note.save(noteValue);
      onSuccess(note);
      isSuccess = true;
    } catch (error) {
      log.error('Error saving note: ', error);
      if (isCurrentRef.current) {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingNote(false);
      }
    }
    // Return to trigger success toast
    return isSuccess;
  };

  return (
    <ModalContainer>
      {isOpen && (
        <ModalDialog
          cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
          ctaLabel={intl.formatMessage({id: 'common.modal.buttons.save'})}
          ctaToastGenerator={ctaToastGenerator}
          errorMessage={modalError}
          heightVariant="static"
          id="edit-note-modal"
          isCtaDisabled={!noteValue || noteValue === note.value}
          isLoading={isSavingNote}
          onCancel={onClose}
          onCta={onCta}
        >
          <ModalHeading>{header}</ModalHeading>
          <ModalDescription>{description}</ModalDescription>
          <ModalContent>
            <Flex direction="row" gap="size-300" marginTop="size-300">
              <TextArea
                data-testid="text-area"
                defaultValue={note.value}
                height="size-3000"
                label={intl.formatMessage({
                  id: 'common.EditNotesModal.field.label',
                })}
                maxLength="600"
                onChange={setNoteValue}
                width="100%"
              />
            </Flex>
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
};

EditNoteModal.propTypes = {
  /**
   * Description of the modal.
   */
  description: PropTypes.string.isRequired,
  /**
   * Title of the modal.
   */
  header: PropTypes.string.isRequired,
  /**
   * Whether the modal is currently open or not.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * The CustomData note to be added or edited.
   */
  note: PropTypes.instanceOf(CustomData).isRequired,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func,
};

export default EditNoteModal;
