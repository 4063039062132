(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectorySettingsModal', {
    bindings: {
      directory: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-settings/directory-settings-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    $log,
    directoryAccess,
    DisplayMessage,
    DIRECTORY_SETTINGS_MODAL_ID,
    feature,
    OrganizationEncryptionMigration,
    OrganizationList,
    panelManager
  ) {
    const vm = this;
    const namespace = 'widgets.directories.directorySettingsModal';

    _.assign(vm, {
      $onInit,
      feature,
      hidePageBanner,
      onClose,
      showPageBanner,
    });

    /////////////////

    _.assign(vm, {
      isOpen: false,
      modalId: DIRECTORY_SETTINGS_MODAL_ID,
      showEncryptionKeySection, // remove with temp_org_asset_encryption_migrated
      showRemoveDirectorySection,
      showTeacherAccessSection,
    });

    function $onInit() {
      // remove with temp_org_asset_encryption_migrated
      vm.waitOnPromise = loadPromise();
    }

    function onClose() {
      hidePageBanner();
      panelManager.close(DIRECTORY_SETTINGS_MODAL_ID);
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    // remove with temp_org_asset_encryption_migrated
    function showEncryptionKeySection() {
      return (
        vm.canEditEncryptionStatus &&
        (vm.directory.isStatusActive() || vm.directory.isStatusNeedsDomain()) &&
        !OrganizationEncryptionMigration.get({
          orgId: OrganizationList.get().activeOrg.id,
        }).isMigrated()
      );
    }

    function showRemoveDirectorySection() {
      // To be done: code here to determine when this section is visible
      return false;
    }

    function showTeacherAccessSection() {
      return directoryAccess.canViewTeacherSettings();
    }

    function showPageBanner(options) {
      vm.displayMessage = new DisplayMessage({
        body: options.message
          ? $translate.instant(`${namespace}.pageBanner.${options.message}`)
          : undefined,
        ctaText: $translate.instant(`${namespace}.closeBtn`),
        header: options.title
          ? $translate.instant(`${namespace}.pageBanner.${options.title}`)
          : options.title,
        onCtaClick: vm.hidePageBanner,
        variant: options.variant,
      });
    }

    ////////

    // remove with temp_org_asset_encryption_migrated
    function loadPromise() {
      return directoryAccess
        .canEditEncryptionStatus()
        .then((result) => {
          vm.canEditEncryptionStatus = result;
        })
        .catch((error) => {
          $log.error('Failed to check for encryption status', error);
        });
    }
  }
})();
