(function () {
  /**
   * @deprecated ported to src2/app/features/overview/hva-cards/value-discovery-tasks/VisitUserGuideHva
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $filter,
    $q,
    $translate,
    $window,
    _,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.VISIT_USER_GUIDE,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback() {
        // This HVA is viewable by all orgs and admin roles. No additional checks required.
        // Essentially acts as a "default" HVA.

        const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.visitUserGuide';

        return $q.resolve([
          new HvaCard({
            ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
            description: $translate.instant(`${namespace}.description`),
            dismissOnCTAClick: true,
            header: $translate.instant(`${namespace}.header`),
            id: 'hva-visit-user-guide',
            isDismissible: true,
            onCTAClick: () => {
              $window.open($filter('goUrl')('aac_pop_artt_users_guide'), '_blank');
            },
            sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
            // Intentional, reusing the take-tour icon for this HVA as it's the same concept.
            svgClass: SVG_CLASS.TAKE_TOUR,
          }),
        ]);
      }
    }
  }
})();
