import {Product} from '@admin-tribe/binky';
import {ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import ProductTableActionButton from 'features/products/components/product-table-action-button/ProductTableActionButton';
import ProductTableActionMenu from 'features/products/components/product-table-action-menu/ProductTableActionMenu';

/**
 * Component for displaying actions and links for a product via
 * ProductTableActionButtons OR via a ProductTableActionMenu.
 */
const ProductTableActionButtonGroup = ({actions, model}) => {
  // 1 or 2 actions should be shown as a row of buttons.
  // 3 or more should be put under the action menu.
  if (actions.length < 3) {
    return (
      <ButtonGroup marginEnd="size-100">
        {actions.map((action) => (
          <ProductTableActionButton
            key={action.variant}
            model={model}
            orgId={action.orgId}
            variant={action.variant}
          />
        ))}
      </ButtonGroup>
    );
  }
  return <ProductTableActionMenu actions={actions} model={model} />;
};

ProductTableActionButtonGroup.propTypes = {
  /** Objects containing action content */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Model for the product whose actions are being presented by this component. */
  model: PropTypes.oneOfType([
    PropTypes.instanceOf(Product),
    PropTypes.instanceOf(ProductGroupProductList),
  ]).isRequired,
};

export default ProductTableActionButtonGroup;
