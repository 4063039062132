import {feature} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Text} from '@adobe/react-spectrum';
import {WizardView} from '@pandora/react-wizard';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import SetupIdpStep from 'features/settings/components/setup-directory-authentication/SetupIdpStep';
import SetupCertificatesStep from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SetupCertificatesStep';
import SetupJit from 'features/settings/components/setup-jit/SetupJit';
import {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SAML_CERTS,
} from 'features/settings/stores/DirectorySetupStore';

const SetupDirectoryAuthenticationSteps = observer(
  ({
    currentStep,
    isGoogleSelected,
    isSoidcSelected,
    updateProvider,
    onLoginToAzure,
    onStepChange,
    isStepListInteractive,
  }) => {
    const intl = useIntl();
    const {directorySetupStore} = useDirectorySetupContext();
    const {idp} = directorySetupStore;

    const SETUP_AUTHENTICATION_CERT_STEPS = {...SETUP_AUTHENTICATION_STEPS, SETUP_SAML_CERTS};
    const hasSamlCertsStep =
      feature.isEnabled('temp_saml_certs') && idp?.isSaml && directorySetupStore.isEditingIdp;

    const FINAL_SETUP_AUTHENTICATION_STEPS = hasSamlCertsStep
      ? SETUP_AUTHENTICATION_CERT_STEPS
      : SETUP_AUTHENTICATION_STEPS;

    const wizardInitState = {
      stepIndex: Object.values(FINAL_SETUP_AUTHENTICATION_STEPS).findIndex(
        (step) => step.id === currentStep.id
      ),
      steps: Object.values(FINAL_SETUP_AUTHENTICATION_STEPS).map((step) => ({
        isActive: true,
        name: intl.formatMessage({id: `settings.setupDirectoryAuthentication.steps.${step.id}`}),
        step,
      })),
    };

    const handleOnStepChange = (stepChangeData) => {
      onStepChange(
        wizardInitState.steps.find((step) => step.name === stepChangeData.currentStepName).step
      );
    };

    return (
      <WizardView
        initConfig={wizardInitState}
        isStepListInteractive={isStepListInteractive}
        onStepChange={handleOnStepChange}
        showStepList
      >
        {currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id && (
          <SetupIdpStep
            isGoogleSelected={isGoogleSelected}
            isSoidcSelected={isSoidcSelected}
            onLoginToAzure={onLoginToAzure}
            updateProvider={updateProvider}
          />
        )}
        {currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id && (
          <>
            <Text>
              <FormattedMessage
                id="settings.setupDirectoryAuthentication.setupJitDescription"
                values={{
                  link: (linkText) => <GoUrl name="admin_auto_account_creation">{linkText}</GoUrl>,
                }}
              />
            </Text>
            <SetupJit />
          </>
        )}
        {hasSamlCertsStep && currentStep.id === SETUP_SAML_CERTS.id && (
          <>
            <Text>
              <FormattedMessage
                id="settings.certificates.modal.description"
                values={{
                  goUrl: (linkText) => <GoUrl name="aac_certificates">{linkText}</GoUrl>,
                }}
              />
            </Text>
            <SetupCertificatesStep />
          </>
        )}
      </WizardView>
    );
  }
);

export default SetupDirectoryAuthenticationSteps;
