import {StatusLight} from '@adobe/react-spectrum';
import {PropTypes} from 'prop-types';
import React from 'react';

const StatusColumn = ({showStatusLight}) =>
  showStatusLight && (
    <StatusLight
      aria-label="Unread light"
      data-testid="unread-light"
      gridArea="status"
      marginY="size-250"
      role="status"
      UNSAFE_style={{padding: 0}}
      variant="info"
    />
  );

StatusColumn.propTypes = {
  /**
  /**
   * True, if the notification status is unread.
   */
  showStatusLight: PropTypes.bool.isRequired,
};

export default StatusColumn;
