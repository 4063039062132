import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  LicenseGroupList,
  PageContext,
  Product,
} from '@admin-tribe/binky';
import {Subpage} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import useJilModelList from 'common/hooks/useJilModelList';
import rootStore from 'core/RootStore';
import ProductProfileTableSection from 'features/products/product-profiles/product-profile-table-section/ProductProfileTableSection';
import {ProductProfileTableContextProvider} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

/**
 *  The Product profiles Subpage component.
 */
const ProductProfilesSubpage = ({pageContext, product}) => {
  const {
    error: listError,
    dispatchTableSectionChange: onTableSectionChange,
    isLoading: isLoadingList,
    list: licenseGroupList,
  } = useJilModelList({
    ListClassRef: LicenseGroupList,
    listOptions: {
      product,
    },
  });

  const orgId = rootStore.organizationStore.activeOrgId;

  const fetchConsumableData = useCallback(() => {
    if (product.isGroupConsumable()) {
      return ConsumableSummarizationList.get({
        license_id: product.id,
        organization_id: orgId,
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.GROUP_ID,
      });
    }
    return null;
  }, [orgId, product]);

  const {isLoading: isConsumableSummarizationListLoading, model: consumableSummarizationList} =
    useAsyncModel({
      loadFn: fetchConsumableData,
    });

  const initialLoad =
    (isLoadingList && licenseGroupList.items === null) ||
    (isConsumableSummarizationListLoading && !consumableSummarizationList?.items);

  // Don't bumper the page on API error. Let the table show the server error.
  // Don't set licenseGroupList until the initial load of both lists.
  // ConsumableSummarizationList may fail and the table will still render
  return (
    <Subpage data-testid="product-profiles-subpage" isLoading={initialLoad}>
      <ProductProfileTableContextProvider
        consumableSummarizationList={consumableSummarizationList}
        hasError={!!listError}
        isLoading={!initialLoad && (isLoadingList || isConsumableSummarizationListLoading)}
        licenseGroupList={initialLoad ? undefined : licenseGroupList}
        onTableSectionChange={onTableSectionChange}
        pageContext={pageContext}
        product={product}
      >
        <ProductProfileTableSection />
      </ProductProfileTableContextProvider>
    </Subpage>
  );
};

ProductProfilesSubpage.propTypes = {
  /**
   * The page context. Can either be a USER_GROUP or PRODUCT target.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
  /**
   * The product we're displaying profiles (aka license groups) for.
   * This is required if pageContext is for PAGE_TARGET.PRODUCT.
   */
  product: PropTypes.instanceOf(Product),
};

export default ProductProfilesSubpage;
