(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ProductConfigurationList
   * @description Paginated configuration list for a product
   */
  angular
    .module('app.core.product.configurations')
    .factory('ProductConfigurationList', getProductConfigurationList);

  /* @ngInject */
  function getProductConfigurationList(
    $q,
    $rootScope,
    $timeout,
    _,
    auth,
    binkySrc2,
    MEMBER_EVENT,
    MESSAGE,
    MODEL,
    modelCache,
    modelCacheUtils,
    OrganizationManager,
    PRODUCT_CONFIGURATIONS_EVENT,
    ProductConfiguration
  ) {
    class LicenseGroupListSrc2 extends binkySrc2.services.product.licenseGroup.LicenseGroupList {
      constructor(options) {
        super(
          _.assign(options, {
            orgId: OrganizationManager.getActiveOrgId(),
            transformResponseData: (response) =>
              _.map(response, (item) => ProductConfiguration.apiResponseTransformer(item, this)),
          })
        );

        this.cacheType = MODEL.LICENSECONFIGURATIONLIST;

        this.deregisterOnProfileAddedHandler = $rootScope.$on(
          PRODUCT_CONFIGURATIONS_EVENT.CREATE,
          this.refresh.bind(this)
        );
      }

      /**
       * @description Method to free up any resources that need to be explicitly
       *   removed to prevent memory leaks (i.e. - listeners/handlers, etc).
       */
      destroy() {
        _.invoke(this, 'deregisterOnProfileAddedHandler');
      }

      filterList(query) {
        this.filter.query = query;
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return modelCacheUtils.getParameterizedKey({
          page: this.pagination.currentPage - 1,
          pageSize: this.pagination.pageSize,
          productId: this.product.id,
          searchQuery: this.filter.query,
          sort: this.sort.expression,
          sortOrder: this.sort.order,
        });
      }

      /**
       * @description Method to load the product config list
       * @returns {Promise<LicenseGroupListSrc2>} promise resolved with refreshed license configs,
       *           otherwise rejects with an error
       */
      refresh() {
        this.$resolved = false;

        const deferred = $q.defer();
        this.$promise = deferred.promise;
        super.refresh().then(onQuerySuccess.bind(this)).catch(onQueryError.bind(this));

        return deferred.promise;

        function onQueryError(error) {
          this.$resolved = true;
          deferred.reject(error);
        }

        function onQuerySuccess() {
          // ensure the items have a removable flag based on the Org or Product Admin role
          _.forEach(this.items, (config) => {
            config.removable =
              config.removable &&
              (auth.isUserOrgAdmin() ||
                auth.isUserProductAdminForTarget(this.product.targetExpression));
          });
          emitListUpdated(this);
          if (this.shouldUpdateTotalItemCount()) {
            emitCountUpdated(this.pagination.itemCount);
          }
          this.$resolved = true;
          deferred.resolve(this);

          // Src1 list superclass normally handles the caching, but we aren't inheriting it so do it here
          modelCache.put(this.cacheType, this, this.key());
        }
      }

      /**
       * @description Method to save changes to configuration list to the back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          super
            .save()
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Method to modify the license quota for product configs
       * @param {ProductConfiguration[]} configurations - array of configurations to modify license quota
       * @returns {Promise<LicenseGroupListSrc2>} promise object resolved when update is complete, or rejected with an error
       */
      updateQuota(configurations) {
        this.$resolved = false;

        const deferred = $q.defer();
        super
          .updateQuota(configurations)
          .then((response) => {
            // Make sure the model is put in the cache (it is wrapped in a $timeout) before
            // resolving. Otherwise there is a window when a cache get will get the stale list
            // and will not see the updates just made.
            $timeout(() => {
              emitListUpdated(this);
              deferred.resolve(response);
            });
          })
          .catch((error) => {
            deferred.reject(error.response);
          })
          .finally(() => {
            this.$resolved = true;
          });

        $timeout(() => {
          // Unlike the original src1 behavior, we won't call notify callbacks after each successful chunked API call.
          // Instead we will notify immediately so the update toast at least displays once.
          deferred.notify('patch in progress');
        });

        return deferred.promise;
      }

      /**
       * @description Method to initialize a ProductConfigurationList
       *
       * @param {Product} product - the model of the product whose list of configs should be returned
       * @param {Number} pageSize - number of items to display per page
       *
       * @returns {LicenseGroupListSrc2} productConfigurationList - new list of product configs for the product.
       */
      static get(product, pageSize) {
        const model = new LicenseGroupListSrc2({pageSize, product});
        const cachedModel = modelCache.get(MODEL.LICENSECONFIGURATIONLIST, model.key());
        if (cachedModel) {
          return cachedModel;
        }
        model.refresh();
        return model;
      }
    }

    function emitListUpdated(list) {
      $rootScope.$emit(MESSAGE.UPDATE.LICENSECONFIGURATION.LIST, list.product.id);
    }

    function emitCountUpdated(count) {
      $rootScope.$emit(MESSAGE.UPDATE.LICENSECONFIGURATION.COUNT, count);
    }

    /** *** Event listeners/handlers *****/
    _.forEach(
      [
        MEMBER_EVENT.CREATE,
        MESSAGE.UPDATE.CONFIGURATIONUSERS.LIST,
        MESSAGE.UPDATE.CONFIGURATIONADMINS.LIST,
        MESSAGE.UPDATE.CONFIGURATIONINTEGRATIONS.LIST,
        MESSAGE.UPDATE.PRODUCTUSERS.LIST,
        MESSAGE.UPDATE.PRODUCTADMINS.LIST,
        PRODUCT_CONFIGURATIONS_EVENT.CREATE,
        PRODUCT_CONFIGURATIONS_EVENT.UPDATE,
      ],
      (message) => {
        // Events do not have de-register callbacks intentionally
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(message, () => {
          modelCache.removeAll(MODEL.LICENSECONFIGURATIONLIST);
        });
      }
    );

    // We register the cache size for this class
    modelCache.register(MODEL.LICENSECONFIGURATIONLIST, 1);

    return LicenseGroupListSrc2;
  }
})();
