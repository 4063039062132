import {DATE_FORMATS, TableSectionTable} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';

/**
 * @description Table for displaying the usage list for a particular product group
 * @param {ProductGroupProductList} productGroup - Object to build table names
 *   (rows) from
 * @param {Object} tableProps - key/value pairs indicating additional settings
 *   for the underlying table (no results messaging, etc)
 */
const ConsumablesListTable = ({productGroup, ...tableProps}) => {
  const intl = useIntl();

  const columnDescriptor = [
    {
      key: 'creditPacks',
    },
    {
      key: 'creditsUsed',
      props: {align: 'end'},
    },
    {
      key: 'startDate',
      props: {allowsSorting: true},
    },
    {
      key: 'expirationDate',
      props: {allowsSorting: true},
    },
  ];

  // The render function will be called with an object which contains the 'item' which is the
  // ConsumableUsage item and the column 'key'.
  const renderers = {
    creditPacks: ({item: {offerId}}) => productGroup.getDisplayNameForOfferId(intl, offerId),
    creditsUsed: ({item: {consumedQuantity, totalQuantity}}) =>
      intl.formatMessage(
        {id: 'products.consumables.list.table.usage'},
        {
          total: totalQuantity,
          used: consumedQuantity,
        }
      ),
    expirationDate: ({item: {expirationDate}}) =>
      intl.formatDate(expirationDate, DATE_FORMATS.default),
    startDate: ({item: {startDate}}) => intl.formatDate(startDate, DATE_FORMATS.default),
  };

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'products.groups.credits.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.groups.credits.table.column"
      renderers={renderers}
      sortDescriptor={{
        column: 'expirationDate',
        direction: 'ascending',
      }}
      {...tableProps}
    />
  );
};

ConsumablesListTable.propTypes = {
  /**
   * ProductGroupProductList to build table row names from
   */
  productGroup: PropTypes.instanceOf(ProductGroupProductList).isRequired,
};

ConsumablesListTable.displayName = 'ConsumablesListTable';
export default ConsumablesListTable;
