import {Flex, Image, View} from '@adobe/react-spectrum';
import {BadgePosition, BestValueBadge} from '@pandora/react-badges';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import AdobeLogo from '@react/react-spectrum/Icon/AdobeLogo';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './Card.pcss';

const BADGE_POSITION = {position: BadgePosition.right};

const SaveOffersHeader = ({headerImage, logo, isBestValue}) => {
  const intl = useIntl();
  return (
    <Flex height="100%" position="relative">
      {headerImage && (
        <Image
          alt=""
          data-testid="header-image"
          objectFit="cover"
          src={headerImage}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
          UNSAFE_className={styles['header-image']}
          width="100%"
        />
      )}
      {isBestValue && (
        <View
          data-testid="best-value-badge"
          elementType="div"
          position="absolute"
          right="0px"
          top="24px"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
          UNSAFE_className={styles.badge}
          zIndex="3"
        >
          <ContentEntryProvider
            value={{
              label: intl.formatMessage({
                id: 'account.selfCancel.components.saveOfferCard.header.bestValueBadge',
              }),
            }}
          >
            <BestValueBadge options={BADGE_POSITION} />
          </ContentEntryProvider>
        </View>
      )}
      <View
        backgroundColor="static-white"
        elementType="div"
        height="57px"
        left="20px"
        position="absolute"
        top="37px"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
        UNSAFE_className={styles.icon}
        width="57px"
      >
        <Flex alignItems="center" data-testid="header-logo" height="100%" justifyContent="center">
          {logo || <AdobeLogo alt="" data-testid="logo-icon" size="L" />}
        </Flex>
      </View>
    </Flex>
  );
};

SaveOffersHeader.propTypes = {
  /**
   * URL for background image
   */
  headerImage: PropTypes.string,
  /**
   * Whether display badge or not
   */
  isBestValue: PropTypes.bool,
  /**
   * Logo to be displayed
   */
  logo: PropTypes.element,
};

export default SaveOffersHeader;
