import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

const {SUPPORT_TICKET_PRIORITY} = SUPPORT_CONSTANTS;

/**
 * Widget to display localized text for priority of SupportTicket based on whether SupportTicket is escalted or not.
 */
const SupportTicketPriority = ({isEscalated = false, priorityCode}) => {
  const intl = useIntl();
  const stringKeyMap = {
    [SUPPORT_TICKET_PRIORITY.P1]: 'critical',
    [SUPPORT_TICKET_PRIORITY.P2]: 'urgent',
    [SUPPORT_TICKET_PRIORITY.P3]: 'important',
    [SUPPORT_TICKET_PRIORITY.P4]: 'minor',
  };
  const key = stringKeyMap[priorityCode];
  const value = isEscalated
    ? intl.formatMessage({id: 'support.supportCase.escalated'})
    : intl.formatMessage({id: `support.supportCase.priority.${key}`});

  return <View>{value}</View>;
};

SupportTicketPriority.propTypes = {
  /**
   * Prop used to get isEscalated value.
   */
  isEscalated: PropTypes.bool,
  /**
   * Prop used to get priorityCode, this code is used to get localized text for priority
   */
  priorityCode: PropTypes.number.isRequired,
};

export default SupportTicketPriority;
