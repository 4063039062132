import {THead} from '@react/react-spectrum/Table';
import PropTypes from 'prop-types';
import React from 'react';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const TableHeader = ({children}) => (
  <THead>
    {React.Children.map(children, (child, index) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      return React.cloneElement(child, {
        first: index === 0,
        header: true,
      });
    })}
  </THead>
);

TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default TableHeader;
