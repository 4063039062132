import {Content, Grid, Heading, IllustratedMessage, Image} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import NoLogs from './NoLogs.svg';

/**
 * @description the illustrated image that appears in place of the table when no content logs are present.
 * Contains a button to create the first content log report.
 */
const ContentLogsEmptyState = () => (
  <Grid areas={['icon', 'message']} justifyItems="center">
    <Image alt="" gridArea="icon" margin="size-200" role="img" src={NoLogs} width="size-2000" />
    <IllustratedMessage gridArea="message">
      <Heading data-testid="no-logs-title" level="2">
        <FormattedMessage id="settings.contentLogs.noLogs.heading" />
      </Heading>
      <Content data-testid="no-logs-content" marginBottom="size-200">
        <FormattedMessage id="settings.contentLogs.noLogs.message" />
      </Content>
    </IllustratedMessage>
  </Grid>
);

export default ContentLogsEmptyState;
