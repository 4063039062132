import {AnalyticsEvent} from '@admin-tribe/binky';
import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import chatProvider from 'core/services/chat/chatProvider';

/**
 * Start chat button for talking with an Adobe agent.
 */
const StartChatButton = ({
  analyticsEvent,
  analyticsName,
  appId,
  onStartChatButtonPress,
  ...buttonProps
}) => {
  const intl = useIntl();

  function onButtonPress() {
    chatProvider.openMessagingWindow({
      appId,
      sourceText: intl.formatMessage({id: 'support.startChatButton.label'}),
      sourceType: 'button',
    });

    if (analyticsName) {
      AnalyticsEvent.dispatch({
        componentMethod: 'startChat',
        componentMethodType: 'click',
        componentName: analyticsName,
      });
    }

    if (analyticsEvent) {
      AnalyticsEvent.dispatch(analyticsEvent);
    }

    onStartChatButtonPress?.();
  }

  if (!chatProvider.canDisplayChat()) {
    return null;
  }

  return (
    <Button data-testid="start-chat-button" onPress={onButtonPress} {...buttonProps}>
      {intl.formatMessage({id: 'support.startChatButton.label'})}
    </Button>
  );
};

StartChatButton.propTypes = {
  /**
   * The extra analytics event that we would like to log to analytics.
   * (e.g. when the start chat button is clicked within a Sophia banner, we
   * want to log a sophia-banner-click event).
   */
  // eslint-disable-next-line react/forbid-prop-types -- The analytics event is a plain object
  analyticsEvent: PropTypes.object,
  /**
   * The name to use for this button in analytics events.
   */
  analyticsName: PropTypes.string,
  /**
   * App id to use.
   */
  appId: PropTypes.string,
  /**
   * Callback to invoke when the user clicks the button.
   */
  onStartChatButtonPress: PropTypes.func,
};
export default StartChatButton;
