import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canViewAccountDetails, canViewAccountOverviewPage} from 'core/account/access/accountAccess';
import {buildDefaultSectionRedirectLoader} from 'core/router/loaders';

import {
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from './accountPaths';

/**
 * Redirects the URL if the user landed on /account
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response | null}
 */
const defaultSectionLoader = ({request}) => {
  let destinationPath;

  if (canViewAccountOverviewPage()) {
    destinationPath = PATH_ACCOUNT_OVERVIEW;
  } else if (canViewAccountDetails()) {
    destinationPath = PATH_ACCOUNT_CONTRACT_DETAILS;
  }

  if (!destinationPath) {
    return null;
  }

  const destination = generatePath(destinationPath, {
    contractId: rootStore.organizationStore.contractListIncludingInactive.items?.[0]?.id,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return buildDefaultSectionRedirectLoader(PATH_ACCOUNT_ROOT, destination, {withQueryParams: true})(
    {request}
  );
};

// eslint-disable-next-line import/prefer-default-export -- Only 1 loader for now
export {defaultSectionLoader};
