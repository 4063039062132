/**
 * @deprecated use src2 Support pages
 *
 * @description a filter to get the case type ("Admin" or "Product") for a support ticket
 * @param {Object} supportTicket the support ticket
 * @returns {String} the localized case type string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.support').filter('appSupportCaseImpact', appSupportCaseImpact);

  /* @ngInject */
  function appSupportCaseImpact($translate, _, SUPPORT_CASE_IMPACT) {
    const stringKeyMap = {
      [SUPPORT_CASE_IMPACT.LARGE]: 'large',
      [SUPPORT_CASE_IMPACT.MEDIUM]: 'medium',
      [SUPPORT_CASE_IMPACT.SMALL]: 'small',
    };

    return getCaseType;

    function getCaseType(supportCase) {
      const key = stringKeyMap[_.toUpper(supportCase.impact)];
      return key ? $translate.instant(`widgets.support.supportCaseImpactFilter.${key}`) : '';
    }
  }
})();
