import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {SCREEN_NAME_CONSTANTS} from 'features/packages/packagesConstants';
import {scrollToInitialPosition} from 'features/packages/stores/createPackageModalStoreUtils';

import {useCreatePackageModalContext} from '../CreatePackageModalContext';
import ActivationCodesPage from '../screens/activation-codes/ActivationCodesPage';
import ChooseEntitlementsPage from '../screens/choose-entitlements-page/ChooseEntitlementsPage';
import ChooseServerPage from '../screens/choose-server/ChooseServerPage';
import FinalizePackagePage from '../screens/finalize-screen/FinalizePackagePage';
import ManagementOptionsPageWrapper from '../screens/management-options-page/ManagementOptionsPageWrapper';
import PluginSelectionPage from '../screens/plugin-selection-page/PluginSelectionPage';
import ProductSelectionPage from '../screens/product-selection-page/ProductSelectionPage';
import SystemConfigPage from '../screens/system-config/SystemConfigPage';

// Wizard API will be hoisted so we can control it.
let wizard;

// Package type wizard contains 8 screens: ActivationCodes, ChooseEntitlements, ChooseServerPage, FinalizePackagePage, ManagementOptions,
// PluginSelection, ProductSelection and SystemConfig
// Based on package type selected in PackageTypeSelectionWizard, that particular package type flow's screens are shown in this wizard
// In this wizard for all the flows, the final screen where admin is prompted to create package is the finalize screen
const PackageTypeFlowWizard = observer(({closeCreatePackageModal}) => {
  const intl = useIntl();

  const {createPackageModalStore} = useCreatePackageModalContext();

  const previousText = intl.formatMessage({id: 'packages.createPackage.footer.previous'});
  const nextText = intl.formatMessage({id: 'packages.createPackage.footer.next'});
  const cancelText = intl.formatMessage({id: 'packages.createPackage.footer.cancel'});
  const createText = intl.formatMessage({id: 'packages.createPackage.footer.createPackage'});
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(
    createPackageModalStore.pkgSessionSettings.nextButtonEnabled
  );

  const [isCreatePkgBtnDisabled, setIsCreatePkgBtnDisabled] = useState(
    createPackageModalStore.pkgSessionSettings.createPackageDisabled
  );

  const modalContentRef = useRef();

  // Update next button enabled status
  useEffect(() => {
    setIsNextBtnEnabled(createPackageModalStore.pkgSessionSettings.nextButtonEnabled);
  }, [createPackageModalStore.pkgSessionSettings.nextButtonEnabled]);

  // Update create package button status
  useEffect(() => {
    setIsCreatePkgBtnDisabled(createPackageModalStore.pkgSessionSettings.createPackageDisabled);
  }, [createPackageModalStore.pkgSessionSettings.createPackageDisabled]);

  const stepToScreenMapping = {
    [SCREEN_NAME_CONSTANTS.ACTIVATION_CODES_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.enterCodes',
      }),
      screen: <ActivationCodesPage />,
    },
    [SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.entitlement',
      }),
      screen: <ChooseEntitlementsPage />,
    },
    [SCREEN_NAME_CONSTANTS.CHOOSE_SERVER]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.server',
      }),
      screen: <ChooseServerPage />,
    },
    [SCREEN_NAME_CONSTANTS.MANAGEMENT_OPTIONS_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.options',
      }),
      screen: <ManagementOptionsPageWrapper />,
    },
    [SCREEN_NAME_CONSTANTS.SHOW_APPS_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.chooseApps',
      }),
      screen: <ProductSelectionPage />,
    },
    [SCREEN_NAME_CONSTANTS.SHOW_FINALIZE_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.finalize',
      }),
      screen: <FinalizePackagePage />,
    },
    [SCREEN_NAME_CONSTANTS.SHOW_PLUGINS_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.choosePlugins',
      }),
      screen: <PluginSelectionPage />,
    },
    [SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN]: {
      displayName: intl.formatMessage({
        id: 'packages.createPackage.wizard.configure',
      }),
      screen: <SystemConfigPage platformPickerAutoFocus />,
    },
  };

  const wizardConfig = useRef({
    stepIndex: 0,
    steps: (createPackageModalStore.pkgSessionSettings?.currentPackagingFlow ?? [])
      .slice(createPackageModalStore.packageTypeFlowWizardStartScreenState)
      .map((step) => ({
        name: stepToScreenMapping[step].displayName,
      })),
  }).current;

  const content = ModalDialogModel.createEntry({
    cancelLabel: cancelText,
    ctaLabel: createPackageModalStore.isCreatePackageButtonVisible ? createText : nextText,
    secondaryLabel: createPackageModalStore.isPreviousButtonVisible ? previousText : null,
  });

  return (
    <ModalDialog
      content={content}
      descriptionStyle={{dividerGap: 'size-125', marginBottom: 'size-200', marginTop: 'size-0'}}
      dialogStyle={{spaceX: 'size-0', width: '1100px'}}
      heightVariant="static"
      id={createPackageModalStore.createPackageModalId}
      isCtaDisabled={
        createPackageModalStore.isCreatePackageButtonVisible
          ? isCreatePkgBtnDisabled
          : !isNextBtnEnabled
      }
      modalContentRef={modalContentRef}
      onCancel={closeCreatePackageModal}
      onCta={() => {
        createPackageModalStore.gotoNextScreen(wizard.goToNextStep, closeCreatePackageModal);
        scrollToInitialPosition(modalContentRef);
      }}
      onSecondary={
        createPackageModalStore.isPreviousButtonVisible
          ? () => {
              createPackageModalStore.gotoPreviousScreen(wizard.goToPreviousStep);
              scrollToInitialPosition(modalContentRef);
            }
          : undefined
      }
    >
      <ModalHeading
        marginBottom="size-150"
        UNSAFE_style={{fontSize: '28px!important', fontWeight: '300!important'}}
      >
        {createPackageModalStore.modalTitle}
      </ModalHeading>
      <ModalContent>
        {/* Passing empty function to onStepChange as it is a required field */}
        <WizardView initConfig={wizardConfig} onStepChange={() => {}} showStepList>
          <ModalDescription />
          {stepToScreenMapping[createPackageModalStore.pkgSessionSettings.currentScreen]?.screen}
          <WizardDriver
            onInit={(inputWizard) => {
              wizard = inputWizard;
            }}
          />
        </WizardView>
      </ModalContent>
    </ModalDialog>
  );
});

PackageTypeFlowWizard.propTypes = {
  /**
   * Function called to close create package modal
   */
  closeCreatePackageModal: PropTypes.func.isRequired,
};

export default PackageTypeFlowWizard;
