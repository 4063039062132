import {feature, navBus} from '@admin-tribe/binky';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_OVERVIEW,
  PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
} from 'features/overview/routing/overviewPaths';
import {
  PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_USERS_REVIEW_USER_INTRODUCTIONS,
} from 'features/users/routing/usersPaths';

const goTo = (path, params) => {
  const pathname = generatePath(path, {orgId: rootStore.organizationStore.activeOrgId});
  navBus.navigate(
    feature.isEnabled('temp_standalone_quick_assign')
      ? {
          pathname,
          search: params,
        }
      : pathname
  );
};

/**
 * @description Method to navigate to the Organization Overview page
 */
const goToOverview = () => goTo(PATH_OVERVIEW);

/**
 * @description Method to navigate to the Quick Assign Modal
 *     This does not currently support the Standalone Quick Assign Modal -- @datl to fix
 */
const goToQuickAssignProducts = () => goTo(PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS, '?sbm=1');

/**
 * @description Method to navigate to the User Introductions mini-app.
 */
const goToUserIntroductions = () => goTo(PATH_USERS_REVIEW_USER_INTRODUCTIONS);

/**
 * @description Method to navigate to the User Introductions mini-app.
 */
const goToSavedUserIntroductions = () => goTo(PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS);

export {goToOverview, goToQuickAssignProducts, goToUserIntroductions, goToSavedUserIntroductions};
