/**
 * @description Class to wrap topic identifier methods. A topic identifier is a
 *   unique string representing a hierarchical place in the help system. These
 *   identifiers have the following form:
 *
 *   root.parent.child.selected#focused
 *
 *   In this example, the hierarchical values end up being:
 *
 *   Focused Topic: focused
 *   Hierarchy: ['root', 'parent', 'child', 'selected', 'focused']
 *   Path: root.parent.child.selected
 *   Path Hierarchy: ['root', 'parent', 'child', 'selected']
 *   Selected Topic: selected
 */
class TopicIdentifierUtils {
  /**
   * @description Method to create a list of breadcrumb paths from a given
   *   help topic identifier. A help topic identifier is a unique,
   *   dot-separated String that represents a hierarchical location in the
   *   help content. This method will translate the String value of this path
   *   into the parent paths that represent breadcrumb links back up to the
   *   root help section that a particular topic exists within (based on the
   *   path - since topics can have multiple children/routes back up the help
   *   hierarchy).
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String[]} list of help content paths that make up breadcrumb
   *   links back to the root help section they are located within
   */
  static getCrumbs(topicIdentifier) {
    const crumbs = [];

    const pathHierarchy = TopicIdentifierUtils.getPathHierarchy(topicIdentifier);
    let identifier;

    pathHierarchy.forEach((topicId) => {
      if (identifier === null || identifier === undefined) {
        // root level help topic - no breadcrumb needed
        identifier = topicId;
      } else {
        identifier += `.${topicId}`;
        crumbs.push(identifier);
      }
    });

    return crumbs;
  }

  /**
   * @description Method to obtain the focused Help Topic from a given topic
   *   identifier. Since identifiers refer to a given place in a wider
   *   hierarchy, it is useful to be able to simply "get" the focused topic
   *   from a longer chain of IDs. In this method, the focused topic is
   *   always the last topic ID in a chain, when a hash mark is used in
   *   the identifier. If a hash mark does not exist, then the focused topic
   *   is not defined for the topic identifier and undefined is returned.
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String|undefined} ID of focused HelpTopic from identifier. If
   *   identifier is any non-String value, then undefined is returned (unable
   *   to parse)
   */
  static getFocusedTopicId(topicIdentifier) {
    if (!topicIdentifier || !topicIdentifier.includes('#')) {
      return undefined;
    }
    const parts = topicIdentifier.split('#');
    return parts[parts.length - 1];
  }

  /**
   * @description Method to obtain the hierarchical representation of the
   *   given topic, as an Array.
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String[]} Array of Strings that represent the hierarchical
   *   path to a given topic in our help system (root topic is at index 0,
   *   child topic of [n] is located at [n + 1], if it exists)
   */
  static getHierarchy(topicIdentifier) {
    const focusedTopicId = TopicIdentifierUtils.getFocusedTopicId(topicIdentifier);
    const pathHierarchy = TopicIdentifierUtils.getPathHierarchy(topicIdentifier);

    if (focusedTopicId) {
      return [...pathHierarchy, focusedTopicId];
    }
    return pathHierarchy;
  }

  /**
   * @description Method to obtain the path to the selected topic in an
   *   identifier, as a dot-delineated String value. This method ensures that
   *   only the path to the selected topic is returned, removing any focused
   *   topic mark-up that may be included in the given topic identifier.
   * @param {String} topicIdentifier - dot-delineated (optional hash mark)
   *   identifier used to represent a hierarchical location in the help topic
   *   system
   * @returns {String|undefined} topic identifier with any focused topic
   *   removed from the end of the identifier (pruned). If identifier is any
   *   non-String value, then undefined is returned (unable to parse)
   */
  static getPath(topicIdentifier) {
    if (!topicIdentifier) {
      return undefined;
    }
    return topicIdentifier.split('#')?.[0];
  }

  /**
   * @description Method to obtain the hierarchical representation of the
   *   given topic, as an Array. This method is identical to the
   *   parseHierarchy method, except that it does not take a focused topic id
   *   into consideration as part of the path. This allows the method to deal
   *   with identifiers that are stored and operated on by the help
   *   breadcrumbs service, since focused topics are not stored in
   *   breadcrumbs.
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String[]} Array of Strings that represent the hierarchical
   *   path to a given topic in our help system (root topic is at index 0,
   *   child topic of [n] is located at [n + 1], if it exists). Any focused topic
   *   appended to the identifier is ignored
   */
  static getPathHierarchy(topicIdentifier) {
    return TopicIdentifierUtils.getPath(topicIdentifier)?.split('.') || [];
  }

  /**
   * @description Method to obtain the root Help Topic from a given topic
   *   identifier. Since identifiers refer to a given place in a wider
   *   hierarchy, it is useful to be able to simply "get" the root topic
   *   from a longer chain of IDs. In this method, the root topic is
   *   always the first topic ID in a chain.
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String|undefined} ID of root HelpTopic from identifier. If
   *   identifier is any non-String value, then undefined is returned (unable
   *   to parse)
   */
  static getRootTopicId(topicIdentifier) {
    if (!topicIdentifier) {
      return undefined;
    }
    return topicIdentifier.split('.')?.[0];
  }

  /**
   * @description Method to obtain the selected Help Topic from a given topic
   *   identifier. Since identifiers refer to a given place in a wider
   *   hierarchy, it is useful to be able to simply "get" the selected topic
   *   from a longer chain of IDs. In this method, the selected topic is
   *   always the last topic ID in a chain, except when a hash mark is used in
   *   the identifier. If a hash mark exists, then the selected topic is the
   *   previous topic ID (directly before hash mark), while the "last" ID
   *   encountered is the "focused" (i.e. - expanded) topic.
   * @param {String} topicIdentifier - dot-separated path to help topic that
   *   breadcrumbs should be created from
   * @returns {String|undefined} ID of selected HelpTopic from identifier. If
   *   identifier is any non-String value, then undefined is returned (unable
   *   to parse)
   */
  static getSelectedTopicId(topicIdentifier) {
    const path = TopicIdentifierUtils.getPath(topicIdentifier);
    if (!path) {
      return undefined;
    }
    const parts = path.split('.');
    return parts.slice(-1)[0];
  }
}

export default TopicIdentifierUtils;
