/* eslint-disable max-lines */
/* eslint-disable max-statements */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.users.user-autocomplete:appUserAutocomplete
   *
   * @description a component supporting autocompleting users
   *
   * @binding {Boolean} disableAddUser, disable to ability to add new user
   * @binding {Boolean} disabled, if true, disable the input box
   * @binding {Boolean} disableSearchingUserGroups, override the configuration based on pageContext,
   *                    and disable searching user groups
   * @binding {Boolean} excludeTechAccounts, true if we don't want tech accounts in the search result
   * @binding {String} inTargetMessage, override the default inTarget string ("Already added") with
   *                   custom message
   * @binding {String} labelText If set, hide the placeholder and display labelText as a label above the 'input' element
   * @binding {Function} onClear, function that clearing the selected user
   * @binding {Function} onConfirm, function that getting called when a user is getting selected
   * @binding {PageContext} pageContext, PageContext object that configures the component behavior
   * @binding {Array<OrganizationUser>} queuedUpUsers, an array of users that are already "inTarget"
   * @binding {Boolean} showIdentityType, if true or undefined, show identity type
   * @binding {Boolean} showSearchIcon, if true, show the search icon
   */
  angular.module('app.widgets.users.user-autocomplete').component('appUserAutocomplete', {
    bindings: {
      disableAddUser: '<?',
      disabled: '<?',
      disableSearchingUserGroups: '<?',
      excludedUserTypes: '<?',
      excludeTechAccounts: '<?',
      inTargetMessage: '@?',
      labelText: '@?',
      onClear: '&?',
      onConfirm: '&',
      pageContext: '<',
      queuedUpUsers: '<?',
      showIdentityType: '<?',
      showSearchIcon: '<?',
    },
    controller,
    templateUrl: 'app/widgets/users/user-autocomplete/user-autocomplete.component.html',
  });

  /* @ngInject */
  function controller(
    $document,
    $element,
    $filter,
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    AvailableTypeList,
    availableTypeHelper,
    COMMON_EVENTS,
    MemberType,
    ProductConfigurationList,
    OrganizationManager,
    OrganizationUser,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    SearchUserList,
    t2eMigrationManager,
    USER_AUTOCOMPLETE_ERROR
  ) {
    const DEBOUNCE_TIME = 1000;
    const SEARCH_QUERY_MIN_LENGTH = 3;

    const vm = this;
    _.assign(vm, {
      $onInit,
      onInputBlur,
      onInputChange,
      onInputClear,
      onInputFocus,
      onOverlayClick,
      search,
      selectUser,
      shouldRenderOverlay,
      shouldRenderPlaceholder,
      showButtonList,
      showCloseIcon,
      showOverlayIdentityType,
    });

    ////////////

    function $onInit() {
      _.assign(vm, {
        autocompleteInputClass: vm.showSearchIcon ? 'with-search-icon' : '',
        buttonListHasContent: false,
        inputId: _.uniqueId('user-autocomplete-input-'),
        inTargetMessage:
          vm.inTargetMessage || $translate.instant('widgets.users.userAutocomplete.alreadyAdded'),
        noUserFoundMessage: vm.disableAddUser
          ? $translate.instant('widgets.users.userAutocomplete.noUsersFoundAndDisableAddUser')
          : $translate.instant(
              t2eMigrationManager.isT2EClean()
                ? 'widgets.users.userAutocomplete.noUsersFoundT2e'
                : 'widgets.users.userAutocomplete.noUsersFound'
            ),
        overlayClass: vm.showSearchIcon ? 'overlay-search-icon' : '',
        promiseToWait: $q.resolve(),
      });

      configureBasedOnPageContext();
      reset();
      $scope.$on(COMMON_EVENTS.RESET, reset);

      if (vm.isTargetTeamProduct) {
        fetchDefaultProfile();
      }
    }

    function clearAndHideButtonList() {
      vm.buttonListHasContent = false;
      const coralList = $element.find('coral-button-list');
      coralList.empty();
    }

    function configureBasedOnPageContext() {
      vm.isDeveloperContext = _.get(vm, 'pageContext.targetType') === PAGE_TARGET_TYPE.DEVELOPER;
      if (
        vm.pageContext.target === PAGE_TARGET.PRODUCT ||
        vm.pageContext.targetType === PAGE_TARGET_TYPE.USER
      ) {
        const targetProduct = _.find(OrganizationManager.getProductsForActiveOrg().items, {
          id: vm.pageContext.targetId,
        });
        vm.isTargetTeamProduct = targetProduct && targetProduct.isTeam();
      } else {
        vm.isTargetTeamProduct = false;
      }

      setPlaceHolderTextKey();

      function setPlaceHolderTextKey() {
        if (
          vm.isDeveloperContext ||
          vm.isTargetTeamProduct ||
          vm.pageContext.target === PAGE_TARGET.USER_GROUP ||
          vm.pageContext.targetType === PAGE_TARGET_TYPE.ADMIN ||
          vm.disableSearchingUserGroups
        ) {
          vm.placeholderTextKey = 'widgets.users.userAutocomplete.placeholderText.nameEmail';
        } else {
          vm.placeholderTextKey =
            'widgets.users.userAutocomplete.placeholderText.nameUserGroupEmail2';
        }
      }
    }

    function createAddUserRow() {
      const coralListItem = createCoralButtonListItem();
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralListItem[0].content = coralListItemContent[0];
      const hasOnlyOnePossibleType = _.filter(vm.availableTypeList.items, 'available').length === 1;
      coralListItemContent.html(
        `<div class="autocomplete-list-item new-user-autocomplete-list-item">
          <span>
            <coral-icon class="new-user-icon" icon="addCircle" size="XS"></coral-icon>
            <span class="new-user">${$translate.instant(
              'widgets.users.userAutocomplete.newUser'
            )}</span>
            ${createSafeHTMLSnippet('<span class="email"></span>', vm.savedSearchQuery)}
          </span>
          <span class="type">${
            hasOnlyOnePossibleType
              ? $filter('binkyMemberTypeLabel')(
                  new MemberType(_.find(vm.availableTypeList.items, 'available').userType)
                )
              : ''
          }</span>
        </div>`
      );

      // We wait for both mousedown and click because on Firefox, click never is triggered
      // when the input's blur hides the autocomplete.
      coralListItem.on('mousedown', () => {
        $timeout(() => {
          const user = new OrganizationUser({
            email: vm.savedSearchQuery,
          });
          selectUser(user);
        });
      });
      coralListItem.on('click', () => {
        $timeout(() => {
          const user = new OrganizationUser({
            email: vm.savedSearchQuery,
          });
          selectUser(user);
        });
      });
      coralListItem.on('blur', onAutocompleteBlur);
      coralListItem.on('focus', onAutocompleteFocus);
      return coralListItem;
    }

    function createCoralButtonListItem() {
      const coralListItem = angular.element(new Coral.ButtonList.Item());
      // Explicitly set the type="button", otherwise in forms it defaults to type="submit"
      // which has a side effect of sending "click" events to the button when enter is typed
      // See https://git.corp.adobe.com/admin-tribe/onesie/issues/2471
      coralListItem[0].type = 'button';
      return coralListItem;
    }

    function createSafeHTMLSnippet(html, innerText) {
      const element = angular.element(html);
      element.text(innerText);
      return element[0].outerHTML;
    }

    // eslint-disable-next-line complexity
    function createUserRow(user) {
      const coralListItem = createCoralButtonListItem();
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      const isDisabledForTeamProduct =
        (user.getType().isType2() || user.getType().isType3()) && vm.isTargetTeamProduct;
      const isExcludedUserType = _.includes(vm.excludedUserTypes, user.type);
      const userExists = vm.isDeveloperContext ? user.isDeveloper() : user.inTarget;
      const isDisabled = userExists || isDisabledForTeamProduct || isExcludedUserType;
      coralListItem.attr('disabled', isDisabled ? 'disabled' : undefined);
      coralListItem[0].content = coralListItemContent[0];
      coralListItemContent.html(
        `<div class="autocomplete-list-item ${userExists ? 'already-added' : ''}">
            ${
              user.hasDisplayName()
                ? `${createSafeHTMLSnippet(
                    '<span class="name"></span>',
                    $filter('binkyMemberNameLabel')(user)
                  )}`
                : ''
            }
            ${
              user.email
                ? `${createSafeHTMLSnippet('<span class="email"></span>', user.email)}`
                : ''
            }
          <span class="type" ${vm.showIdentityType === false ? 'hidden' : ''}>${
            userExists ? vm.inTargetMessage : $filter('binkyMemberTypeLabel')(user.getType())
          }</span>
        </div>
        ${
          isDisabledForTeamProduct
            ? `<coral-tooltip variant="error" placement="right" target="_prev">${$translate.instant(
                t2eMigrationManager.isT2EClean()
                  ? 'widgets.users.userAutocomplete.cannotAddToTeamProductT2e'
                  : 'widgets.users.userAutocomplete.cannotAddToTeamProduct'
              )}</coral-tooltip>`
            : ''
        }`
      );
      // We wait for both mousedown and click because on Firefox, click never is triggered
      // when the input's blur hides the autocomplete.
      coralListItem.on('mousedown', () => {
        $timeout(() => {
          selectUser(user);
        });
      });
      coralListItem.on('click', () => {
        $timeout(() => {
          selectUser(user);
        });
      });
      coralListItem.on('blur', onAutocompleteBlur);
      coralListItem.on('focus', onAutocompleteFocus);
      return coralListItem;
    }

    function createLoadingRow() {
      const coralListItem = angular.element(new Coral.List.Item());
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      const coralWait = angular.element(new Coral.Wait());
      coralWait.attr('id', 'loading-row-wait');
      coralWait.attr('size', 'M');
      coralListItem[0].content = coralListItemContent[0];
      coralListItemContent.append(coralWait);
      return coralListItem;
    }

    function createNoAllowedAvailableTypeList() {
      const coralListItem = angular.element(new Coral.List.Item());
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralListItem[0].content = coralListItemContent[0];
      const message = availableTypeHelper.generateNoSelectableTypesMessage(
        vm.availableTypeList.items
      );
      coralListItemContent.html(
        `<span class="info"><coral-icon icon="info"></coral-icon><p class="no-allowed-available-type-list">${message}</p></span>`
      );
      return coralListItem;
    }

    function createNoUsersFoundRow() {
      const coralListItem = angular.element(new Coral.List.Item());
      const coralListItemContent = angular.element(new Coral.List.Item.Content());
      coralListItem[0].content = coralListItemContent[0];
      coralListItemContent.html(`<p class="no-users-found">${vm.noUserFoundMessage}</p>`);
      return coralListItem;
    }

    function emitError() {
      $scope.$emit(USER_AUTOCOMPLETE_ERROR);
    }

    function fetchDefaultProfile() {
      const targetProduct = _.find(OrganizationManager.getProductsForActiveOrg().items, {
        id: vm.pageContext.targetId,
      });
      if (targetProduct && targetProduct.isTeam()) {
        const pageSize = 1;
        vm.productConfigurationList = ProductConfigurationList.get(targetProduct, pageSize);
        vm.promiseToWait = vm.productConfigurationList.$promise;
        vm.productConfigurationList.$promise.finally(() => {
          if (vm.productConfigurationList.items.length !== 1) {
            emitError();
          }
        });
      }
    }

    function getInput() {
      return angular.element($element[0].querySelector('.autocomplete-input'));
    }

    function hideResults() {
      getInput().removeClass('showing-autocomplete');
      clearAndHideButtonList();
      vm.autocompleteHasFocus = false;
    }

    function onAutocompleteBlur(event) {
      if ($document[0].activeElement === event.target) {
        // Ignore blurs when the browser is losing focus
        return;
      }
      vm.autocompleteHasFocus = false;
      $timeout(() => {
        if (!vm.inputHasFocus && !vm.autocompleteHasFocus) {
          hideResults();
        }
      });
    }

    function onAutocompleteFocus() {
      vm.autocompleteHasFocus = true;
    }

    function onInputBlur(event) {
      if ($document[0].activeElement === event.target) {
        // Ignore blurs when the browser is losing focus
        return;
      }

      if (vm.user) {
        vm.searchQuery = '';
      }

      vm.inputHasFocus = false;
      $timeout(() => {
        if (!vm.inputHasFocus && !vm.autocompleteHasFocus) {
          hideResults();
        }
      });
    }

    function onInputChange() {
      vm.savedSearchQuery = vm.searchQuery;

      // Reset the user
      if (vm.user) {
        vm.user = null;
        vm.onConfirm({availableTypeList: null, user: null});
      }

      // Reset input changed promise to get debounce behavior
      vm.isChangingInput = true;
      if (vm.inputChangedPromise) {
        $timeout.cancel(vm.inputChangedPromise);
      }
      if (vm.savedSearchQuery.length < SEARCH_QUERY_MIN_LENGTH) {
        hideResults();
      } else {
        showLoadingResults();
        vm.inputChangedPromise = $timeout(() => {
          vm.isChangingInput = false;
          search();
        }, DEBOUNCE_TIME);
      }
    }

    function onInputClear() {
      _.invoke(vm, 'onClear');
      vm.onOverlayClick();
      vm.searchQuery = '';
      vm.savedSearchQuery = '';
      vm.user = null;
    }

    function onInputFocus() {
      vm.inputHasFocus = true;
      if (vm.savedSearchQuery) {
        vm.searchQuery = vm.savedSearchQuery;
      }
      search();
    }

    function onOverlayClick() {
      getInput()[0].focus();
    }

    function reset() {
      vm.autocompleteHasFocus = false;
      vm.inputChangedPromise = null;
      vm.inputHasFocus = false;
      vm.isChangingInput = false;
      vm.savedSearchQuery = '';
      vm.user = null;
      if (getInput()[0]) {
        getInput()[0].value = '';
      }
    }

    function search() {
      if (vm.savedSearchQuery.length < SEARCH_QUERY_MIN_LENGTH) {
        return;
      }
      const searchUserListOptions = vm.isTargetTeamProduct
        ? {
            includeUserGroups: false,
            targetLicenseGroupId: vm.productConfigurationList.items[0].id,
            targetProductId: vm.pageContext.targetId,
            targetType: PAGE_TARGET_TYPE.USER,
          }
        : SearchUserList.getTargetOptionsFromPageContext(vm.pageContext);

      if (vm.disableSearchingUserGroups) {
        searchUserListOptions.includeUserGroups = false;
      }

      vm.availableTypeList = AvailableTypeList.get({
        email: vm.savedSearchQuery,
        orgId: OrganizationManager.getActiveOrgId(),
      });
      vm.searchUserList = SearchUserList.get(vm.savedSearchQuery, searchUserListOptions);

      // We can't just use $q.all here because it bails as soon as one of the promises rejects (AvailableTypeList call rejects when the query isn't an email)
      const deferreds = {
        availableTypeList: $q.defer(),
        searchUserList: $q.defer(),
      };
      const promises = {
        availableTypeList: deferreds.availableTypeList.promise,
        searchUserList: deferreds.searchUserList.promise,
      };
      vm.availableTypeList.$promise.finally(() => {
        deferreds.availableTypeList.resolve();
      });
      vm.searchUserList.$promise.finally(() => {
        deferreds.searchUserList.resolve();
      });

      // eslint-disable-next-line promise/catch-or-return, complexity
      $q.all(promises).then(() => {
        if (vm.isChangingInput) {
          return;
        }

        if (vm.isTargetTeamProduct) {
          _.forEach(vm.availableTypeList.items, (item) => {
            const type = new MemberType(item.userType);
            if (type.isType2() || type.isType3()) {
              item.available = false;
            }
          });
        }

        clearAndHideButtonList();

        if (vm.excludeTechAccounts) {
          _.remove(vm.searchUserList.items, (user) => user.getType().isTechnicalAccount());
        }

        const foundAvailableTypeListResult = vm.disableAddUser
          ? undefined
          : getFoundAvailableTypeListResult();

        const foundSearchResults = vm.searchUserList.items && vm.searchUserList.items.length > 0;

        if (!foundAvailableTypeListResult && !foundSearchResults) {
          showButtonList(createNoUsersFoundRow());
          return;
        }
        // Check policies only after the input is validated because policies are loaded after the email is validated
        // And, only check policies when AvailableTypeList.get is successful
        if (foundAvailableTypeListResult && !hasOneOrMoreAllowedAvailableTypes()) {
          showButtonList(createNoAllowedAvailableTypeList());
          return;
        }
        // The user email has been validated at this point
        if (foundSearchResults) {
          _.forEach(vm.queuedUpUsers, (user) => {
            const tempUser = _.find(vm.searchUserList.items, ['id', user.id]);
            _.set(tempUser, 'inTarget', true);
          });
          _.forEach(vm.searchUserList.items, (user) => {
            showButtonList(createUserRow(user));
          });
          const input = getInput();
          input.addClass('showing-autocomplete');
        }
        if (foundAvailableTypeListResult) {
          showButtonList(createAddUserRow());
        }
      });
    }

    function hasOneOrMoreAllowedAvailableTypes() {
      // Default missing 'allowed' property to 'true' because the property won't be available
      // until https://jira.corp.adobe.com/browse/ONESIE-16297
      return (
        _(vm.availableTypeList.items)
          .filter('available')
          .filter((item) => _.get(item, 'allowed', true))
          .value().length > 0
      );
    }

    function getFoundAvailableTypeListResult() {
      let result = _.some(vm.availableTypeList.items, 'available');
      if (result && vm.queuedUpUsers) {
        const tempUser = _.find(vm.queuedUpUsers, ['email', vm.availableTypeList.email]);
        if (tempUser) {
          result = false;
        }
      }
      return result;
    }

    function selectUser(user) {
      if (vm.user === user || vm.savedSearchQuery === '') {
        return;
      }
      vm.onConfirm({availableTypeList: vm.availableTypeList, user});
      vm.user = user;
      vm.searchQuery = '';
      hideResults();
    }

    function shouldRenderOverlay() {
      return vm.user && !vm.inputHasFocus && !vm.autocompleteHasFocus;
    }

    function shouldRenderPlaceholder() {
      return !vm.labelText && !shouldRenderOverlay();
    }

    function showButtonList(content) {
      vm.buttonListHasContent = true;
      const coralList = $element.find('coral-button-list');
      coralList.append(content);
    }

    function showCloseIcon() {
      return vm.onClear && vm.savedSearchQuery.length > 0;
    }

    function showOverlayIdentityType() {
      return _.get(vm.user, 'id') && vm.showIdentityType !== false;
    }

    function showLoadingResults() {
      const coralWait = $element[0].querySelectorAll('#loading-row-wait');
      if (coralWait.length > 0) {
        return;
      }
      const input = getInput();
      input.addClass('showing-autocomplete');
      clearAndHideButtonList();
      showButtonList(createLoadingRow());
    }
  }
})();
/* eslint-enable max-lines */
/* eslint-enable max-statements */
