(function () {
  /**
   * @deprecated Please use src2/app/common/api/adobeio-console
   */
  angular
    .module('app.core.api.adobeio-console')
    .provider('adobeioConsoleIntegrations', adobeioConsoleIntegrationsProvider);

  let url;

  /* @ngInject */
  function adobeioConsoleIntegrationsProvider(_) {
    this.configure = configure;
    this.$get = $get;

    function configure(config) {
      url = `${config.url}/api/organizations`;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        integrationDetails: getIntegrationDetails(),
        integrations: getIntegrations(),
        integrationsInfo: getIntegrationsInfo(),
      };
      return service;

      function getIntegrationDetails() {
        return $resource(`${url}/:orgId/integrations/:intId`, {
          intId: '@intId',
          orgId: '@orgId',
        });
      }

      function getIntegrations() {
        return $resource(
          `${url}/:orgId/integrations`,
          {
            orgId: '@orgId',
          },
          {
            query: {
              isArray: true,
              params: {
                expanded: true,
                page: '@page',
                size: '@size',
              },
              transformResponse(data) {
                const result = angular.fromJson(data);
                return _.get(result, 'content');
              },
            },
          }
        );
      }

      function getIntegrationsInfo() {
        return $resource(
          `${url}/:orgId/integrations`,
          {
            orgId: '@orgId',
          },
          {
            query: {
              params: {
                expanded: false,
                page: 0,
                size: 1,
              },
              transformResponse(data) {
                const result = angular.fromJson(data);
                return _.pick(result, ['total']);
              },
            },
          }
        );
      }
    }
  }
})();
