(function () {
  /**
   * @ngdoc module
   * @name app.widgets.search
   * @description module that defines Search widgets
   */
  angular.module('app.widgets.search', [
    'binky.core.help-search',
    'binky.core.locale',
    'binky.shell.panels.pulldown',
    'binky.widgets.common.anchor-list',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.search',
    'binky.widgets.common.wait',
  ]);
})();
