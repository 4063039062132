(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.widgets.support').component('appSupportTicketCommentNote', {
    bindings: {
      comment: '<',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-comment-note/support-ticket-comment-note.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $translate,
    appSupportTicketPersonNameFilter,
    SUPPORT_TICKET_COMMENT_TYPE,
    SUPPORT_TICKET_DISCUSSION_DIRECTION,
    User
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getBannerHeader,
      getTitle,
      getVariant,
    });

    function $onInit() {
      const createdBy = _.get(vm, 'comment.createdBy', {});
      vm.commentCreatedBy = new User({
        id: createdBy.rengaId,
      });
    }

    function getBannerHeader() {
      return showBanner()
        ? $translate.instant('widgets.support.supportTicketCommentNote.pleaseRespond')
        : '';
    }

    function getName() {
      const createdByAgent = vm.comment.direction === SUPPORT_TICKET_DISCUSSION_DIRECTION.OUTBOUND;

      if (vm.supportTicket.isMigrated && createdByAgent) {
        // For migrated cases the agent first/last name is some system name rather than a real
        // agent name, so show "Adobe Support" instead
        return $translate.instant('widgets.support.supportTicketCommentNote.adobeSupport');
      }

      const createdBy = _(vm.comment.createdBy)
        .pick(['firstName', 'lastName', 'rengaId'])
        .assign({showAdobeSuffix: createdByAgent})
        .value();
      return appSupportTicketPersonNameFilter(createdBy);
    }

    function getTitle() {
      return $translate.instant(
        vm.comment.type === SUPPORT_TICKET_COMMENT_TYPE.ACTION_PENDING_RESPONSE
          ? 'widgets.support.supportTicketCommentNote.requested'
          : 'widgets.support.supportTicketCommentNote.commented',
        {name: getName()}
      );
    }

    function getVariant() {
      return showBanner() ? 'warning' : 'default';
    }

    ///////////

    function showBanner() {
      return vm.supportTicket.commentRequiresResponse(vm.comment);
    }
  }
})();
