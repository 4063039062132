const ContentState = {
  FORM: 'form',
  WELCOME: 'welcome',
};

const WorkflowType = {
  MULTI_SEAT: 'multi-seat',
  SINGLE_SEAT: 'single-seat',
};

export {ContentState, WorkflowType};
