import {View} from '@adobe/react-spectrum';
import {ContentContext} from '@pandora/react-content-provider';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import {observer} from 'mobx-react-lite';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import HelpRailBreadcrumbs from '../help-rail-breadcrumbs/HelpRailBreadcrumbs';

import styles from './TabContent.pcss';
import ConstructCardContent from './construct-content/ConstructCardContent';

const TabContent = () => {
  const intl = useIntl();
  const {locale} = useContext(ContentContext);

  const sortedTopics = rootStore.helpStore.topics.getTopicChildren(
    rootStore.helpStore.currentTopic.id
  );

  const selectedIndex = sortedTopics?.findIndex(
    (helpItem) =>
      helpItem.id === rootStore.helpStore.focusedTopicId ||
      helpItem.id === rootStore.helpStore.currentTopic.id
  );

  const onChange = (index) => {
    if (index === null) {
      // help topic collapsed
      rootStore.helpStore.goToTopic(rootStore.helpStore.topics.selectedPath);
    } else {
      const selectedTopic = sortedTopics[index];
      rootStore.helpStore.goToTopic(
        `${rootStore.helpStore.topics.selectedPath}#${selectedTopic.id}`
      );
    }
  };

  return sortedTopics?.length > 0 ? (
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to disable borders
    <View UNSAFE_className={styles.accordion}>
      <HelpRailBreadcrumbs />
      <Accordion ariaLevel={4} onChange={onChange} selectedIndex={selectedIndex}>
        {sortedTopics.map((item, topicIndex) => {
          const topicContent = ConstructCardContent({intl, item, locale, topicIndex});

          let topicTitle;
          if (item.title) {
            if (item.translateValues) {
              topicTitle = intl.formatMessage({id: item.title}, item.translateValues);
            } else {
              topicTitle = intl.formatMessage({id: item.title});
            }
          }

          return (
            <AccordionItem
              key={item.id}
              data-testid="accordion-item"
              header={topicTitle}
              id={item.id}
            >
              {topicContent}
            </AccordionItem>
          );
        })}
      </Accordion>
    </View>
  ) : null;
};

TabContent.propTypes = {};

export default observer(TabContent);
