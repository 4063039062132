(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilUserGroups
   * @description defines service to manage jil user group resources
   */
  angular.module('app.core.api.jil').provider('jilUserGroups', jilUserGroupsProvider);

  let url;

  /* @ngInject */
  function jilUserGroupsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        userGroupAdmins: getUserGroupAdminsResource(),
        userGroupLicenseGroups: getUserGroupLicenseGroupsResource(),
        userGroups: getUserGroupsResource(),
        userGroupUsersList: getUserGroupUsersListResource(),
      };

      return service;

      ///////////

      function getUserGroupAdminsResource() {
        return $resource(
          `${url}/:orgId/user-groups/:groupId/admins/:adminId`,
          {
            adminId: '@adminId',
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            add: {
              method: 'POST',
            },
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getUserGroupLicenseGroupsResource() {
        return $resource(
          `${url}/:orgId/user-groups/:userGroupId/license-groups/:id`,
          {
            id: '@id',
            orgId: apiUtils.getActiveOrg,
            userGroupId: '@userGroupId',
          },
          {
            add: {
              method: 'POST',
            },
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }

      function getUserGroupUsersListResource() {
        return $resource(
          `${url}/:orgId/user-groups/:groupId/users/:userId`,
          {
            groupId: '@groupId',
            orgId: apiUtils.getActiveOrg,
            userId: '@userId',
          },
          {
            add: {
              method: 'POST',
            },
            batchOperation: {
              method: 'PATCH',
            },
            bulkAdd: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'ug-add-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkRemove: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'ug-remove-users',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            exportUgUsers: {
              headers: {
                Accept: 'text/csv+user-ug,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }

      function getUserGroupsResource() {
        return $resource(
          `${url}/:orgId/user-groups/:userGroupId`,
          {
            orgId: apiUtils.getActiveOrg,
            userGroupId: '@userGroupId',
          },
          {
            bulkAdd: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'add-user-groups',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            bulkEdit: {
              headers: {
                'Content-Type': undefined,
              },
              method: 'POST',
              params: {
                type: 'edit-user-groups',
              },
              transformRequest: angular.identity,
              url: `${url}/:orgId/jobs`,
            },
            export: {
              headers: {
                Accept: 'text/csv+user-groups-all,application/json',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
            update: {
              method: 'PUT',
            },
          }
        );
      }
    }
  }
})();
