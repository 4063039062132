(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name BulkOperationLocalization
   * @description Service for localizing the CSV files used for bulk-operations.
   */
  angular
    .module('app.widgets.bulk-operations.localization')
    .factory('bulkOperationLocalization', getBulkOperationLocalizationService);

  /* @ngInject */
  function getBulkOperationLocalizationService($log, _, CSV_HEADER_MAP, Papa, translationUtils) {
    const service = {
      delocalizeCsvFile,
      delocalizeCsvText,
      localizeCsvFile,
      localizeCsvText,
    };

    return service;

    ////////////

    /**
     * @description Localizes a given CSV file.
     *              For now, only changes the headers.
     *
     * @param {File} csvFile The CSV file to read and localize
     * @returns {Promise} resolves to Blob with localized text
     */
    function localizeCsvFile(csvFile) {
      // Creates Blob instead of File to support pre-2020 Edge
      return readFileAsText(csvFile)
        .then(this.localizeCsvText)
        .then((localizedText) => new Blob([localizedText], {type: csvFile.type}));
    }

    /**
     * @description Delocalizes a given CSV file.
     *              For now, only changes the headers.
     *
     * @param {File} csvFile The CSV file to read and delocalize
     * @returns {Promise} resolves to Blob with delocalized text
     */
    function delocalizeCsvFile(csvFile) {
      // Creates Blob instead of File to support pre-2020 Edge
      return readFileAsText(csvFile)
        .then(this.delocalizeCsvText)
        .then((delocalizedText) => new Blob([delocalizedText], {type: csvFile.type}));
    }

    /**
     * @description Localizes the given CSV text string
     *              For now, only changes the headers.
     *
     * @param {String} csvText The CSV text to localize.
     * @returns {String} The delocalized CSV text.
     */
    function localizeCsvText(csvText) {
      if (_.isNil(csvText)) {
        return csvText;
      }
      const localizationMap = headerLocalizationMap();
      const localizeHeaderFunction = (header) => {
        if (!localizationMap.has(header)) {
          $log.error(
            `Unrecognized CSV header returned by backend -- "${header}" -- needs to be supported here or removed in backend`
          );
          return header;
        }
        return localizationMap.get(header);
      };

      const modifiedCsvObjects = Papa.parse(csvText, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        transformHeader: localizeHeaderFunction,
      });

      const newCsvText = Papa.unparse(modifiedCsvObjects, {
        delimiter: ',',
        header: true,
      });
      return newCsvText;
    }

    /**
     * @description Delocalizes a given CSV text string.
     *              For now, only changes the headers.
     *
     * @param {String} csvText The CSV text to delocalize.
     * @returns {String} The delocalized CSV text.
     * @throws {Error} if CSV header is not valid for the user's locale, or English locale.
     */
    function delocalizeCsvText(csvText) {
      if (_.isNil(csvText)) {
        return csvText;
      }
      const delocalizationMap = headerDelocalizationMap();
      const delocalizeHeaderFunction = (header) => {
        if (!delocalizationMap.has(header)) {
          // Header is not recognized by the localization map
          // Throw the same type of error code as JIL would for an unknown header.
          throw new Error('INVALID_STREAM_CSV_HEADER');
        }
        return delocalizationMap.get(header);
      };

      const modifiedCsvObjects = Papa.parse(csvText, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        transformHeader: delocalizeHeaderFunction,
      });

      const newCsvText = Papa.unparse(modifiedCsvObjects, {
        delimiter: ',',
        header: true,
      });
      return newCsvText;
    }

    ///////////////////////////

    /**
     * @description Method to return the raw text from a given file.
     *
     * @param {File/Blob} file The CSV file to read
     * @returns {String} The CSV file's text
     */
    function readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', reject);
        reader.addEventListener('load', () => {
          resolve(reader.result);
        });
        reader.readAsText(file);
      });
    }

    function headerLocalizationMap() {
      const map = new Map();
      _.forEach(CSV_HEADER_MAP, (header) => {
        map.set(header.jil, translationUtils.sanitizeSafeInstant(header.localizationKey));
      });
      return map;
    }

    function headerDelocalizationMap() {
      const map = new Map();
      _.forEach(CSV_HEADER_MAP, (header) => {
        // When parsing user-submitted CSVs, we want to support both
        // the user's locale, and our original "en" (English) locale that
        // has been supported prior to this feature.
        map.set(translationUtils.sanitizeSafeInstant(header.localizationKey), header.jil);
        map.set(
          translationUtils.sanitizeSafeInstant(header.localizationKey, {}, undefined, 'en'),
          header.jil
        );
      });
      return map;
    }
  }
})();
