(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.account').component('appOrganizationTypeSection', {
    controller,
    templateUrl:
      'app/widgets/account/organization-type-section/organization-type-section.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    _,
    OrganizationList,
    ORGANIZATION_EVENT,
    ORGANIZATION_TYPE_MODAL_ID,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      onClick,
    });

    const destroyFn = $rootScope.$on(ORGANIZATION_EVENT.UPDATE, () => {
      $onInit();
    });

    function $onDestroy() {
      destroyFn();
    }

    function $onInit() {
      vm.marketSubsegments = OrganizationList.get().activeOrg.getMarketSubsegments();
    }

    function onClick() {
      panelManager.open(ORGANIZATION_TYPE_MODAL_ID);
    }
  }
})();
