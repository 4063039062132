import {navBus} from '@admin-tribe/binky';
import {Button, Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_ADOBE_TEMPLATES} from 'features/packages/routing/packagesPaths';
import CreatePackageService from 'features/packages/services/CreatePackageService';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';

import CreatePackageButton from '../../create-package-modal/CreatePackageButton';

import styles from './EmptyPackageList.pcss';
import PackageTypeCard from './PackageTypeCard';

const EmptyPackageList = ({isCreatePkgBtnDisabled = false}) => {
  const intl = useIntl();

  const openBrowseTemplate = () => {
    navBus.navigate(
      generatePath(PATH_ADOBE_TEMPLATES, {orgId: rootStore.organizationStore.activeOrgId})
    );
  };

  const showFrlFirstUserExperience = () =>
    (PackagesEntitlementsService.hasFrlEntitlement &&
      CreatePackageService.hasApplicableFrlOffers()) ||
    PackagesEntitlementsService.hasNuellEntitlement;

  return (
    <Flex
      alignItems="center"
      className="all-packages-fue"
      direction="column"
      justifyContent="center"
    >
      {!showFrlFirstUserExperience() && (
        <Flex alignItems="center" direction="column" width="size-4600">
          <div className={styles['all-packages-fue-image']} />
          <div className={styles.header}>
            {intl.formatMessage({id: 'packages.allPackages.firstUserExperience.title'})}
          </div>
          <div className={styles['fue-message-text']}>
            {intl.formatMessage({id: 'packages.allPackages.firstUserExperience.message'})}
          </div>
          <Flex direction="rows" marginTop="size-250">
            <CreatePackageButton
              buttonLabel={intl.formatMessage({
                id: 'packages.allPackages.createPackageButtonLabel',
              })}
              data-testid="create-package-button"
              isDisabled={isCreatePkgBtnDisabled}
              setCreatePackageFactoryValues={() =>
                CreatePackageService.setCreatePackageFactoryValuesForNamed()
              }
              variant="accent"
            />
            <Button
              data-testid="browse-template-button"
              marginStart="size-225"
              onPress={openBrowseTemplate}
              variant="primary"
            >
              {intl.formatMessage({
                id: 'packages.allPackages.firstUserExperience.browseTemplateButtonLabel',
              })}
            </Button>
          </Flex>
        </Flex>
      )}

      {showFrlFirstUserExperience() && (
        <>
          <Flex alignItems="center" direction="column" marginBottom="size-250" marginTop="size-250">
            <span className={styles.header}>
              {intl.formatMessage({id: 'packages.allPackages.firstUserExperience.titleFrl'})}
            </span>
            <Text>
              {intl.formatMessage({id: 'packages.allPackages.firstUserExperience.titleDesc'})}
            </Text>
          </Flex>
          <Flex direction="row" gap="size-300">
            {(PackagesEntitlementsService.hasCcEntitlement ||
              PackagesEntitlementsService.hasDcEntitlement) && (
              <PackageTypeCard
                data-testid="nul-package-type-card"
                description="namedDesc"
                isCreatePkgBtnDisabled={isCreatePkgBtnDisabled}
                learnMoreLink="ent_learnmore_NUL"
                setCreatePackageFactoryValues={() =>
                  CreatePackageService.setCreatePackageFactoryValuesForNamed()
                }
                title="namedTitle"
              />
            )}
            {PackagesEntitlementsService.hasNuellEntitlement && (
              <PackageTypeCard
                data-testid="sdl-package-type-card"
                description="nuellDesc"
                isCreatePkgBtnDisabled={isCreatePkgBtnDisabled}
                learnMoreLink="ent_learnmore_SDL"
                setCreatePackageFactoryValues={() =>
                  CreatePackageService.setCreatePackageFactoryValuesForNuell()
                }
                title="nuellTitle"
              />
            )}
            {PackagesEntitlementsService.hasFrlEntitlement && (
              <PackageTypeCard
                data-testid="frl-package-type-card"
                description="frlDesc"
                isCreatePkgBtnDisabled={isCreatePkgBtnDisabled}
                learnMoreLink="ent_learnmore_FRL"
                setCreatePackageFactoryValues={() =>
                  CreatePackageService.setCreatePackageFactoryValuesForFrl()
                }
                title="frlTitle"
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
EmptyPackageList.displayName = 'EmptyPackageList';
EmptyPackageList.propTypes = {
  /**
   * Indicates if create package button should be disabled
   * By default it will be false
   */
  isCreatePkgBtnDisabled: PropTypes.bool,
};
export default EmptyPackageList;
