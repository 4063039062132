(function () {
  /**
   * @ngdoc module
   * @name app.core.main
   * @description where it all begins
   */
  angular.module('app.main', [
    'app.core',
    'app.core.chat.ready',
    'app.core.fraud-mitigation',
    'app.core.observability',
    'app.core.organizations.organization-manager',
    'app.core.src2.ready',
    'app.core.termsRedirect.ready',
    'app.main.jobs',
    'app.shell',
    'app.widgets.contracts.migrations',
    'app.widgets.organization-type',
    'app.widgets.terms-and-conditions',
    'binky.core.extended-user-profile.ready',
    'binky.core.fraud-mitigation.ready',
    'binky.core.lodash',
    'binky.core.migration',
    'binky.core.observability.ready',
    'binky.core.ready',
    'binky.shell.bumper.ready',
    'binky.shell.navigation.constants',
    'binky.shell.navigation.ready',
    'ui.router',
  ]);
})();
