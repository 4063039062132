import {GoUrl} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const SyncStatusError = observer(({directorySyncStore}) => {
  if (directorySyncStore.isAzure) {
    return (
      <>
        <FormattedMessage id="settings.sync.status.tooltip.error.azure" />
        <GoUrl name="add_azure_sync_error">
          <FormattedMessage id="settings.sync.status.tooltip.error.learnMore" />
        </GoUrl>
      </>
    );
  }

  if (directorySyncStore.isGoogle) {
    return (
      <>
        <FormattedMessage id="settings.sync.status.tooltip.error.google" />
        <GoUrl name="add_google_sync_error">
          <FormattedMessage id="settings.sync.status.tooltip.error.learnMore" />
        </GoUrl>
      </>
    );
  }

  return <FormattedMessage id="settings.sync.status.tooltip.error.generic" />;
});

export default SyncStatusError;
