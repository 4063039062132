(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.counter').component('appCounter', {
    bindings: {
      value: '@',
    },
    templateUrl: 'app/widgets/common/counter/counter.component.html',
  });
})();
