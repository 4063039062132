(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc factory
   * @name app.core.product.purchase:AddProductsModalRoutes
   * @description helper factory for add products modal2 routes
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.products.purchase.add-products-modal')
    .provider('addProductsModalRoutes', addProductsModalRoutes);
  /* @ngInject */
  function addProductsModalRoutes(
    $stateProvider,
    _,
    ADD_PRODUCTS_MODAL2_ID,
    ADD_PRODUCTS_MODAL2_STATES
  ) {
    this.configure = configure;
    this.$get = $get;

    function $get() {
      const service = {
        configure,
      };

      return service;
    }

    /**
     * Registers state configuration for Add Products deep linking. Based in the configuration provided it will call
     * $stateProvider.state with agenerated StateDeclaration object which will define Add Products nested state and
     * will configure the route in order to add deep linking.
     * @param {Object} options configuration options
     * @param {String} options.analyticsModalSourcePage the name of the page that the user lands on when the modal is closed
     * @param {ADD_PRODUCTS_MODAL2_STATES} [options.defaultState] indicate the default landing state for the modal, when deep
     *   linking state is omitted. It will default to SELECT_PRODUCTS. If REVIEW_ORDER is passed the modal will be configured
     *   as an add licenses flow. The specified defaultState value will be removed from the URL
     * @param {String} options.parentRoute the parent route which should contain the "modal" ui-view element used
     *   to render appAddProductsModal2. It will also be used to route back if user can't access flow
     * @param {String} [options.requireFeature] feature flag name to use to check if the route is accessible.
     * @param {String} options.stateName the unique state name which defined this route. This state name will be used like an id.
     */
    function configure({
      analyticsModalSourcePage,
      defaultState = ADD_PRODUCTS_MODAL2_STATES.SELECT_PRODUCTS,
      parentRoute,
      stateName,
    }) {
      let lastModalId;

      const isAddLicenses = defaultState === ADD_PRODUCTS_MODAL2_STATES.REVIEW_ORDER;

      $stateProvider.state({
        data: {
          // Prevent analytics page hit from being fired for deep linking
          skipAnalytics: true,
          title: 'widgets.products.purchase.addProductsModal.title.addProducts',
        },
        // Using dynamic: true, so changes to the query params will not cause the state to be entered/exited.
        // The resolves will not be re-fetched, nor will views be reloaded.
        dynamic: true,
        name: stateName,
        // Callback function for lifecycle hook, which is invoked (during a transition) when a specific state is being entered.
        // Used to prevent access to the flow if user doesn't have permissions, by redirecting to parentRoute.
        onEnter: [
          '$transition$',
          'contractUtils',
          'OrganizationManager',
          function ($transition$, contractUtils, OrganizationManager) {
            function redirectToParentRoute() {
              return $transition$.router.stateService.target(parentRoute, $transition$.params());
            }

            // Redirect to parent page if admin doesn't have access to the Add Products modal
            return OrganizationManager.getContractsForActiveOrg()
              .$promise.then(() => contractUtils.isAllowedToAddProducts())
              .then((canAddProducts) => {
                if (canAddProducts) {
                  return true;
                }
                return redirectToParentRoute();
              })
              .catch(redirectToParentRoute);
          },
        ],
        // Callback function for lifecycle hook, which is invoked (during a transition) when a specific state is being exited.
        // It will be use to clean up, in case panelManager still thinks the modal was still open. This callback can be
        // triggered by the user clicking close button in the modal, which case panelManager.close was already executed.
        // Also, when the user navigates back via browser's back button, this callback will be executed and in this case
        // the component will be unmounted but the panelManager may still have the modal as open and for that reason we
        // execute panelManager.close.
        onExit: [
          'panelManager',
          function (panelManager) {
            if (panelManager.isOpen(lastModalId)) {
              panelManager.close(lastModalId);
            }
          },
        ],
        // Callback function for lifecycle hook, which is invoked (during a transition) for a specific state that was previously active
        // will remain active (is not being entered nor exited). It will be use to sync cart items in the URL.
        // When a change in the cart items or the modal state happens, onCartItemsChange and onModalStateChange will force a state change.
        // This state change will cause a retain lifecycle event, which will trigger this callback, where we add the cart items to the URL,
        // outside of UI Router URL handling.
        onRetain: [
          '$location',
          '$state',
          '$timeout',
          'ProductPurchaseHelper',
          function ($location, $state, $timeout, ProductPurchaseHelper) {
            // $timeout is needed since the transition didn't end and the URL didn't update yet
            $timeout(() => {
              // At this point, the current URL doesn't contain cartItems query params. We need to add them if present in state param
              if ($state.params.cartItems) {
                // Convert cartItems array into object with key of the form 'items[index][key]'
                const cartItemsLocationSearch =
                  ProductPurchaseHelper.convertCartItemsToLocationSearch($state.params.cartItems);
                // Append items[index][key]=value query params to URL without creating a new history record
                // eslint-disable-next-line lodash/prefer-lodash-method
                $location
                  .search(_.assign({}, $location.search(), cartItemsLocationSearch))
                  .replace();
              }
            });
          },
        ],
        // Component bindings for appAddProductsModal2
        resolve: {
          // Defines the modalId used to register appAddProductsModal2 modal by binky-modal. Since ADD_PRODUCTS_MODAL2_ID modal id
          // is already registered in shell component, and the router will render another appAddProductsModal2, to prevent runtime
          // error we create a new unique id. This new unique id should be used by panelManager.open, to open the newly rendered
          // appAddProductsModal2 component, in onInitialized. This is a similar approach as appGlobalModals.
          modalId: () => {
            lastModalId = _.uniqueId(`${ADD_PRODUCTS_MODAL2_ID}-`);
            return lastModalId;
          },
          // Event triggered by appAddProductsModal2 to signal a change in cartItems. This change may occur based on initial load and
          // execution of ProductPurchaseHelper.getProductBuckets or as a result of user interactions updating the cart items
          // or cart items quantities. It will be used to force a state change for the cartItems params. This state change will result
          // in a retain lifecycle event.
          onCartItemsChange: [
            '$state',
            '$timeout',
            ($state, $timeout) => (cartItems) => {
              if (isAddLicenses && _.size(cartItems) > 1) {
                // Add Licenses is only allowed to have 1 cart item.
                // Will redirect to the parent state with current params otherwise.
                // $timeout is needed to wait for previous transition to be finished.
                $timeout(() => {
                  $state.go(parentRoute, $state.params);
                });
              } else {
                // Will go to current state with updated cartItems param and also replace the last history record
                $state.go(
                  '.',
                  {
                    // In order to force the state change, cartItems param should have an immutable update
                    cartItems:
                      _.map(cartItems, (item) => _.pick(item, ['offer_id', 'numberSelected'])) ||
                      null,
                  },
                  {inherit: true, location: 'replace'}
                );
              }
            },
          ],
          // Event triggered by appAddProductsModal2 to signal the modal was closed. It will be used to update the URL
          // with the parent route
          onClosed: [
            '$state',
            ($state) => () => {
              // Will go to the parent state with current params
              $state.go(parentRoute, $state.params);
            },
          ],
          // Event triggered by appAddProductsModal2 to signal $onInit was completed. It will be used to open the modal
          // using lastModalId as it's modal id and passing opening params to onOpen method in appAddProductsModal2.
          // The opening params will contain the initial cartItems or step in the flow from the URL, if present
          // and initial configuration for the route, such as deepLinkingState or isAddLicenses.
          onInitialized: [
            '$location',
            '$stateParams',
            'onesieSrc2',
            'panelManager',
            'ProductPurchaseHelper',
            ($location, $stateParams, onesieSrc2, panelManager, ProductPurchaseHelper) => () => {
              let {cartItems} = $stateParams;
              const path = $location.path();
              if (
                onesieSrc2.core.products.access.canPreventAddProductDefaultRouteForMiniApp(path)
              ) {
                if ($stateParams.event) {
                  return $stateParams.event.preventDefault();
                }

                return false;
              }

              if (_.isEmpty(cartItems)) {
                // Convert query params of the form 'items[index][key]' to cartItems array
                cartItems = ProductPurchaseHelper.convertLocationSearchToCartItems(
                  $location.search()
                );
              }
              return panelManager.open(lastModalId, {
                analyticsModalSourcePage,
                cartItems: _.isEmpty(cartItems) ? undefined : cartItems,
                deepLinkingState: $stateParams.step,
                isAddLicenses,
              });
            },
          ],
          // Event triggered by appAddProductsModal2 to signal a modal internal state change. This change may occur when
          // the initial state is determined or the user interacts with the modal navigating through its flow. It will be
          //  used to force a state change for the step params. This state change will result in a retain lifecycle event.
          onModalStateChange: [
            '$state',
            '$timeout',
            ($state, $timeout) => (modalState) => {
              if (
                isAddLicenses &&
                modalState !== ADD_PRODUCTS_MODAL2_STATES.REVIEW_ORDER &&
                modalState !== ADD_PRODUCTS_MODAL2_STATES.SELECT_ORG_TYPES &&
                modalState !== ADD_PRODUCTS_MODAL2_STATES.SHOW_PAGE_BANNER
              ) {
                // Add Licenses is only allowed to route to REVIEW_ORDER, SELECT_ORG_TYPES or SHOW_PAGE_BANNER steps.
                // Will redirect to the parent state with current params otherwise.
                // $timeout is needed to wait for previous transition to be finished.
                $timeout(() => {
                  $state.go(parentRoute, $state.params);
                });
              } else if ($state.params.step !== modalState) {
                // Will go to current state with updated step param and also replace the last history record
                $state.go(
                  '.',
                  {
                    step: modalState,
                  },
                  {inherit: true, location: 'replace'}
                );
              }
            },
          ],
        },
        // The url definition relative to the parent route. It will contain explicit 'step' query param, as well as
        // paypal, token & mockUserId, needed to maintain them between internal routes navigation
        url: '/add-products?step&paypal&token&mockUserId',
        // eslint-disable-next-line sort-keys -- so url and params are close, since they are related
        params: {
          // The cartItems state param, which will not be added to the URL by UI-Router. Since cartItems format from
          // Unified Checkout doesn't adhere to UI Router, cartItems will be treated as a hidden state param and will
          // be added to the URL via onRetain lifecycle event, by adding the special query params.
          cartItems: {
            array: false,
          },
          // The current step in the flow as query param in the URL, unless it matches the default landing step
          step: {
            squash: true, // The parameter's default value is omitted from the URL
            value: defaultState,
          },
        },
        views: {
          [`modal@${parentRoute}`]: {
            bindings: {
              onCartItemsChange: 'onCartItemsChange(cartItems)',
              onModalStateChange: 'onModalStateChange(modalState)',
            },
            component: 'appAddProductsModal2',
          },
        },
      });
    }
  }
})();
