import {OverlayWait} from '@admin-tribe/binky-ui';
import {Button, Checkbox, TextField} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import AppConstants from 'common/services/AppConstants';
import ConfigurationStep from 'features/settings/components/configuration-step/ConfigurationStep';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import ScimTokenInput from 'features/settings/components/scim-token-input/ScimTokenInput';

import './AzureScimConfigurationSteps.pcss';

const AzureScimConfigurationSteps = observer(
  ({authSourceId, validateSteps, onValidate = () => {}}) => {
    const intl = useIntl();
    const directoryStoreContext = useDirectoryContext();
    const directorySyncStore = directoryStoreContext.directoryStore.sync;

    return (
      <OverlayWait isLoading={directorySyncStore.isLoading} showContent>
        <ConfigurationStep
          title={intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.step1'})}
        >
          <Button
            elementType="a"
            href={AppConstants.configuration.services.directorySync.azurePortalUrl}
            target="_blank"
            variant="secondary"
          >
            <FormattedMessage id="settings.AzureScimConfigurationSteps.goToMicrosoft" />
          </Button>
        </ConfigurationStep>

        <ConfigurationStep
          title={intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.step22'})}
        >
          <p>
            <FormattedMessage
              id="settings.AzureScimConfigurationSteps.skipThisStep"
              values={{
                b:
                  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                  // istanbul ignore next -- not testing this
                  (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </p>
          <p>
            <FormattedMessage id="settings.AzureScimConfigurationSteps.addGalleryApp" />
          </p>
          <ol>
            <li>
              <FormattedMessage
                id="settings.AzureScimConfigurationSteps.addNewApp"
                values={{
                  b:
                    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                    // istanbul ignore next -- not testing this
                    (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="settings.AzureScimConfigurationSteps.addIdentityApp"
                values={{
                  b:
                    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                    // istanbul ignore next -- not testing this
                    (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
          </ol>
        </ConfigurationStep>

        <ConfigurationStep
          title={intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.step3'})}
        >
          <p>
            <FormattedMessage id="settings.AzureScimConfigurationSteps.configureProvisioning" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.goToProvisioning" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.setMode" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.copyTenantUrl" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.testAndSave" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.reviewUserAttributes" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.goToUsersAndGroups" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.addUsersAndGroups" />
            </li>
          </ol>

          <div styleName="tenant-url">
            <TextField
              data-testid="tenant-url-field"
              isDisabled
              label={intl.formatMessage({
                id: 'settings.AzureScimConfigurationSteps.tenantUrlLabel',
              })}
              marginEnd="10px"
              value={directorySyncStore.scimUrl}
              width="100%"
            />
            <CopyToClipboardButton
              data-testid="tenant-url-copy-btn"
              value={directorySyncStore.scimUrl}
            >
              <FormattedMessage id="settings.AzureScimConfigurationSteps.copyToClipboard" />
            </CopyToClipboardButton>
          </div>

          <ScimTokenInput authSourceId={authSourceId} />
        </ConfigurationStep>

        <ConfigurationStep
          title={intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.step4'})}
        >
          <ol>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.goBackToProvisioning" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.setStatusToOn" />
            </li>
            <li>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.waitForProvisioning" />
            </li>
          </ol>
        </ConfigurationStep>
        {validateSteps ? (
          <ConfigurationStep
            title={intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.step5'})}
          >
            <Checkbox onChange={onValidate}>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.setupCompleteLabel" />
            </Checkbox>
            <p>
              <FormattedMessage id="settings.AzureScimConfigurationSteps.setupCompleteAgreement" />
            </p>
          </ConfigurationStep>
        ) : null}
      </OverlayWait>
    );
  }
);

AzureScimConfigurationSteps.propTypes = {
  authSourceId: PropTypes.string,
  onValidate: PropTypes.func,
  validateSteps: PropTypes.bool,
};

export default AzureScimConfigurationSteps;
