(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc       module
   * @name        termsRedirectReadyProvider
   * @description App-ready module to initialize termsRedirect with configuration files.
   */

  angular
    .module('app.core.termsRedirect.ready')
    .provider('termsRedirectReady', termsRedirectReadyProvider);

  /* @ngInject */
  function termsRedirectReadyProvider(_, configurationReadyProvider) {
    this.$get = $get;
    this.configure = configure;

    let config;

    ////////////

    function configure() {
      return configurationReadyProvider.whenConfigReady().then((configData) => {
        config = _.get(configData, 'services.termsRedirect');
      });
    }

    /* @ngInject */
    function $get($q, feature, featureReady, onesieSrc2) {
      const runDeferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        return featureReady.whenRunReady().then(() => {
          if (feature.isEnabled('temp_terms_redirect')) {
            onesieSrc2.common.services.termsRedirect.configureTermsRedirect(config);
          }
          runDeferred.resolve();
        });
      }

      function whenRunReady() {
        return runDeferred.promise;
      }
    }
  }
})();
