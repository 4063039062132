(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:uiEventToLaunchTranslator
   * @description Translate UiEventDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('uiEventToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, uiEventDescriptor) {
      const {setProperty} = launchContext;
      const {eventAction, eventName, interaction} = uiEventDescriptor;
      setProperty('primaryEvent.eventInfo.eventName', `adminConsole:${eventName}:${eventAction}`);
      setProperty('primaryEvent.eventInfo.eventAction', eventName);
      setProperty('primaryEvent.eventInfo.interaction.click', _.get(interaction, 'click'));
      setProperty(
        'primaryEvent.eventInfo.interaction.impression',
        _.get(interaction, 'impression')
      );
    }
  }
})();
