import {ActionButton} from '@adobe/react-spectrum';
import {Item, ListView} from '@react-spectrum/list';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import RemoveCircle from '@spectrum-icons/workflow/RemoveCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useIncludeListFormContext} from '../../IncludeListFormContext';

const IncludeItemsList = ({addRemove, 'aria-labelledby': ariaLabelledBy, type, items}) => {
  const intl = useIntl();
  const {itemText, itemRenderer: ItemRenderer, readOnly} = useIncludeListFormContext();

  const buttonLabel = (item) =>
    `${
      type === 'available'
        ? intl.formatMessage({
            id: 'common.includeListForm.item.add',
          })
        : intl.formatMessage({
            id: 'common.includeListForm.item.remove',
          })
    } ${itemText(item)}`;

  // there is never a case where two keys are the same
  const sortItemsByKey = (a, b) => (a.key > b.key ? 1 : -1);

  const renderButton = (item) => (
    <ActionButton
      aria-label={buttonLabel(item)}
      data-testid="include-list-item-button"
      isDisabled={readOnly}
      isQuiet
      onPress={() => addRemove([item])}
    >
      {type === 'available' ? <AddCircle /> : <RemoveCircle />}
    </ActionButton>
  );

  return (
    <ListView
      aria-labelledby={ariaLabelledBy}
      items={[...items].sort(sortItemsByKey)}
      maxHeight="size-3000"
      overflowMode="wrap"
      selectionMode="none"
    >
      {(item) => (
        <Item key={item.key} textValue={itemText(item)}>
          <ItemRenderer item={item} />
          {renderButton(item)}
        </Item>
      )}
    </ListView>
  );
};

IncludeItemsList.propTypes = {
  /**
   * The callback function when items are being added/removed. Takes an argument of an array of items to add/remove.
   */
  addRemove: PropTypes.func.isRequired,
  /**
   * Id of the label for the items list.
   */
  'aria-labelledby': PropTypes.string.isRequired,
  /**
   * The array of items. Items is a generic object as an item can be any object, but it must have a key.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /**
   *  The typeof this include pane. Current possible values are 'avaiable' and 'included'.
   */
  type: PropTypes.oneOf(['available', 'included']).isRequired,
};

export default IncludeItemsList;
