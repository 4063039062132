(function () {
  /**
   * @ngdoc module
   * @name app.core.gainsight
   * @description module that gives gainsight tracking support
   */
  angular.module('app.core.gainsight', [
    'app.core.extended-user-profile',
    'app.core.organizations.organization-manager',
    'app.core.ready.org',
    'binky.core.extended-user-profile.ready',
    'binky.core.feature',
    'binky.core.gainsight',
    'binky.core.locale.ready',
    'binky.core.organizations',
  ]);
})();
