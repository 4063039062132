import {
  AuthenticatedUser,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  GLOBAL_ADMIN_POLICY,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  SUPPORT_ADMINS,
  USER_GROUP_ADMIN,
  feature,
  hasOnlyTeamProducts,
  hasPackageSupport,
  hasProductSupportRoleAssignmentAllowed,
  hasSupportRoleAssignmentAllowed,
} from '@admin-tribe/binky';
import {CONTRACT_BUYING_PROGRAM} from '@pandora/data-model-contract';

import rootStore from 'core/RootStore';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import auth from 'core/services/auth';
import {canViewStorage} from 'core/storage/access/storageAccess';

/**
 * Determines whether this admin can add/assign an admin.
 * It includes the check to determine if this operation is blocked by a Global Admin policy.
 *
 * @param {Boolean} [options.checkAdminPolicies] Whether to include the manage admins polices check. The default is true.
 *
 * @returns {Boolean} returns true if this admin can assign an admin.
 */
function canAssignAdmin({checkPolicies} = {checkPolicies: true}) {
  return (
    auth.hasAnyRole([
      DEPLOYMENT_ADMIN,
      LICENSE_ADMIN,
      ORG_ADMIN,
      CONTRACT_ADMIN,
      PRODUCT_ADMIN,
      USER_GROUP_ADMIN,
    ]) &&
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddAdminLogic() &&
    (checkPolicies ? hasManageAdminsPolicy() : true)
  );
}

/**
 * Determines whether this admin can remove any admin from the organization.
 * It includes the check to determine if this operation is blocked by a Global Admin policy.
 *
 * @returns {Boolean} returns true if this admin can remove any admin from the organization.
 */
function canRemoveAdmin() {
  return (
    auth.isUserOrgAdmin() &&
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddAdminLogic() &&
    hasManageAdminsPolicy()
  );
}

/**
 * @returns {Boolean} whether the user can view admins
 */
function canViewAdmins() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  if (feature.isEnabled('temp_adobe_agent_access')) {
    return (
      userRoles.anyOfForOrg(
        [
          DEPLOYMENT_ADMIN,
          LICENSE_ADMIN,
          ORG_ADMIN,
          CONTRACT_ADMIN,
          PRODUCT_ADMIN,
          STORAGE_ADMIN,
          USER_GROUP_ADMIN,
        ],
        orgId
      ) || userRoles.isActingAsAdobeAgentForOrg(orgId)
    );
  }

  return userRoles.anyOfForOrg(
    [
      DEPLOYMENT_ADMIN,
      LICENSE_ADMIN,
      ORG_ADMIN,
      CONTRACT_ADMIN,
      PRODUCT_ADMIN,
      STORAGE_ADMIN,
      USER_GROUP_ADMIN,
    ],
    orgId
  );
}

/**
 * Checks if the organization has atleast one valid VIP or VIPMP buying program with team products.
 *
 * @returns {Boolean} Returns true if the organization has a valid buying program with team products, otherwise false.
 */
function isVIPOrVIPMPContract() {
  const contractList = rootStore.organizationStore.contractList.items;
  return contractList.some(
    (contract) =>
      contract.buyingProgram === CONTRACT_BUYING_PROGRAM.VIP ||
      contract.buyingProgram === CONTRACT_BUYING_PROGRAM.VIPMP
  );
}

/**
 * Checks if the organization has only Team Direct products.
 *
 * @returns {Boolean} Returns true if the organization has a valid Team Direct products, otherwise false.
 */
const isTeamDirectContract = () => {
  const contractList = rootStore.organizationStore.contractList;
  return contractList.items.some((contract) => contract.isDirectContract());
};

/* eslint-disable complexity -- permission matrix require a lot of branches */
/**
 * @param {ROLE} role
 * @returns {Boolean} whether the user can view the admin role
 */
async function canViewRole(role) {
  // Remove the code in the else block once the flag is removed
  if (
    feature.isEnabled('temp_contract_admin_role') &&
    hasOnlyTeamProducts(rootStore.organizationStore.productList) &&
    role !== ORG_ADMIN
  ) {
    // For VIP or VIPMP contract
    if (isVIPOrVIPMPContract() && role !== CONTRACT_ADMIN) {
      return false;
    }
    // For Team Direct or ongoing migration
    if (
      isTeamDirectContract() ||
      rootStore.organizationStore.migrationList.shouldForceAllowAddAdminsOrUsers()
    ) {
      return false;
    }
  } else if (
    role !== ORG_ADMIN &&
    (hasOnlyTeamProducts(rootStore.organizationStore.productList) ||
      rootStore.organizationStore.migrationList.shouldForceAllowAddAdminsOrUsers())
  ) {
    return false;
  }

  if (role === DEPLOYMENT_ADMIN && !hasPackageSupport(rootStore.organizationStore.productList)) {
    return false;
  }

  if (role === STORAGE_ADMIN && !(await canViewStorage())) {
    return false;
  }

  if (
    role === SUPPORT_ADMIN &&
    (!hasSupportRoleAssignmentAllowed(rootStore.organizationStore.productList) ||
      hasProductSupportRoleAssignmentAllowed(rootStore.organizationStore.productList))
  ) {
    return false;
  }

  if (
    role === SUPPORT_ADMINS &&
    !hasProductSupportRoleAssignmentAllowed(rootStore.organizationStore.productList)
  ) {
    return false;
  }

  return (
    auth.isUserOrgAdmin() ||
    (role === CONTRACT_ADMIN && auth.isUserContractAdmin()) ||
    (role === PRODUCT_ADMIN && (auth.isUserContractAdmin() || auth.isUserProductAdmin())) ||
    (role === LICENSE_ADMIN &&
      (auth.isUserContractAdmin() || auth.isUserProductAdmin() || auth.isUserProfileAdmin())) ||
    (role === USER_GROUP_ADMIN && auth.isUserUserGroupAdmin()) ||
    (role === DEPLOYMENT_ADMIN && auth.isUserDeploymentAdmin()) ||
    (role === STORAGE_ADMIN && auth.isUserStorageAdmin()) ||
    (role === SUPPORT_ADMIN && auth.isUserSupportDelegate())
  );
}
/* eslint-enable complexity -- permission matrix require a lot of branches */

/**
 *
 * @returns {Promise<ROLE[]>} admin roles that the current user can manage
 */
async function getManageableAdminRoles() {
  const roles = [
    ORG_ADMIN,
    CONTRACT_ADMIN,
    PRODUCT_ADMIN,
    LICENSE_ADMIN,
    USER_GROUP_ADMIN,
    DEPLOYMENT_ADMIN,
    STORAGE_ADMIN,
    SUPPORT_ADMIN,
    SUPPORT_ADMINS,
  ];
  // create a mask of booleans, then apply mask to roles to get roles that can be managed
  const mask = await Promise.all(roles.map(canViewRole));
  return roles.filter((_role, i) => mask[i]);
}

/**
 * @param {Product} product
 * @returns {ROLE[]} product admin roles the user can manage
 */
function getManageableProductAdminRoles(product) {
  return auth.isUserOrgAdmin() && product.isProductSupportRoleAssignmentAllowed()
    ? [PRODUCT_ADMIN, PRODUCT_SUPPORT_ADMIN]
    : [PRODUCT_ADMIN];
}

/**
 * Determines whether or not there is a Global Admin policy that allows admins to be managed.
 *
 * @returns {Boolean} whether the current user can manage admins under global admin policy
 */
function hasManageAdminsPolicy() {
  return rootStore.organizationStore.globalAdminPolicyList.getPolicyValue(
    GLOBAL_ADMIN_POLICY.MANAGE_ADMINS
  );
}

/**
 * Determines whether there is a Global Admin policy that allows identity config to be changed.
 *
 * @returns {Boolean} whether the current user can change identity config under global admin policy
 */
function canChangeIdentityConfig() {
  return rootStore.organizationStore.globalAdminPolicyList.getPolicyValue(
    GLOBAL_ADMIN_POLICY.CHANGE_IDENTITY_CONFIG
  );
}

export {
  canAssignAdmin,
  canChangeIdentityConfig,
  canRemoveAdmin,
  canViewRole,
  canViewAdmins,
  getManageableProductAdminRoles,
  getManageableAdminRoles,
  hasManageAdminsPolicy,
};
