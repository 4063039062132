import {StatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {DOMAIN_STATUSES} from 'common/entities/DomainEntity';

import styles from './DomainStatus.pcss';

const DOMAIN_STATUS_LIGHT = {
  [DOMAIN_STATUSES.ACTIVE]: {
    label: 'settings.directoryDomains.status.ACTIVE',
    variant: 'positive',
  },
  [DOMAIN_STATUSES.UNCLAIMED]: {
    label: 'settings.directoryDomains.status.UNCLAIMED',
    variant: 'notice',
  },
  [DOMAIN_STATUSES.CLAIMED]: {
    label: 'settings.directoryDomains.status.CLAIMED',
    variant: 'notice',
  },
  [DOMAIN_STATUSES.VALIDATED]: {
    label: 'settings.directoryDomains.status.VALIDATED',
    variant: 'notice',
  },
  UNKNOWN: {
    label: 'settings.directoryDomains.status.UNKNOWN',
    variant: 'neutral',
  },
};

const DomainStatus = ({status}) => {
  const {variant, label} = DOMAIN_STATUS_LIGHT[status] ?? DOMAIN_STATUS_LIGHT.UNKNOWN;

  return (
    <StatusLight
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed more than provided style props
      UNSAFE_className={styles['domain-status-light']}
      variant={variant}
    >
      <FormattedMessage id={label} />
    </StatusLight>
  );
};

DomainStatus.propTypes = {
  status: PropTypes.string,
};

export default DomainStatus;
export {DOMAIN_STATUS_LIGHT};
