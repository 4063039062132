(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appPromoCatalogTable
   * @description A component which renders a list of the promo-catalog-item components.
   *
   * @param {Array}    offerList The list of offers to display in the table.
   * @param {Function} onPromoRedeemed The callback function when the redeem button is clicked.
   * @example
   *       <app-promo-catalog-table
   *          offer-list="$ctrl.promoCatalogTable.offerList"
   *          on-promo-redeemed="$ctrl.promoCatalogTable.onPromoRedeemed(offerId)">
   *       </app-promo-catalog-table>
   */
  angular.module('app.widgets.products.purchase').component('appPromoCatalogTable', {
    bindings: {
      offerList: '<',
      onPromoRedeemed: '&',
    },
    templateUrl:
      'app/widgets/products/purchase/catalog/promo-table/promo-catalog-table.component.html',
  });
})();
