import {PATH_PRODUCT_DETAILS_ADMINS} from 'features/products/routing/productsPaths';

import help from '../../constants/help';
import {getEnterpriseSignParams} from '../../utils/helpUtils';

/**
 *
 * @returns content for product tab -> enterprise sign
 */
const enterpriseSign = () => {
  const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;
  const {access, id} = getEnterpriseSignParams();

  return {
    access,
    content: [
      {
        links: [
          {
            key: 'help.topics.enterpriseSign.link.one.key',
            locator: PATH_PRODUCT_DETAILS_ADMINS,
            migrated: true,
            params: {productId: id},
            target: LINK_TARGET_TYPE.APPLICATION,
            type: LINK_TYPE.BUTTON,
          },
        ],
        text: 'help.topics.enterpriseSign.text',
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: TOPICS.ENTERPRISE_SIGN,
    parents: [
      {
        id: TOPICS.PRODUCTS,
      },
    ],
    title: 'help.topics.enterpriseSign.title',
  };
};

export default enterpriseSign;
