import rootStore from 'core/RootStore';
import {canAssignUser} from 'core/user/access/userAccess';
import {PATH_USERS} from 'features/users/routing/usersPaths';

import help from '../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> add users
 */
const teamAddUsers = () => {
  const activeOrgId = rootStore.organizationStore.activeOrgId;

  return {
    access: () => canAssignUser(activeOrgId),
    content: [
      {
        links: [
          {
            key: 'help.topics.teamAddUsers.link.one.key',
            locator: PATH_USERS,
            migrated: true,
            target: LINK_TARGET_TYPE.APPLICATION,
            type: LINK_TYPE.BUTTON,
          },
          {
            key: 'help.topics.teamAddUsers.link.two.key',
            locator: 'aac_learn_add_users_team',
            target: LINK_TARGET_TYPE.GO_URL,
            type: LINK_TYPE.TEXT,
          },
        ],
        text: 'help.topics.teamAddUsers.text',
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: TOPICS.TEAM_ADD_USERS,
    parents: [
      {
        id: TOPICS.ADMIN_CONSOLE,
      },
    ],
    title: 'help.topics.teamAddUsers.title',
  };
};
export default teamAddUsers;
