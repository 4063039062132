import {defineContents} from '@pandora/react-content-provider';
import {ModalDialogModel} from '@pandora/react-modal-dialog';

// A custom content model for the AddAppIntegrationsModal
const AddAppIntegrationModalContentModel = defineContents('AddAppIntegrationModalContent', {
  modalDialogContent: {
    id: 'modalDialogContent',
    isArray: true,
    type: ModalDialogModel,
  },
});

export default AddAppIntegrationModalContentModel;
