import {ActionButton, Button, Tooltip, TooltipTrigger, View} from '@adobe/react-spectrum';
import {SUPPORT_TICKET_STATUS} from '@pandora/data-model-support-ticket';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';

/**
 * Component is used to show Reopen Case button in Action Section. It used in action section to render the Reopen case modal.
 */
const SupportTicketReopenCase = ({canReopen, isClosed, isSupportCase, onReopenCase, status}) => {
  const intl = useIntl();

  function getTooltip(key) {
    return intl.formatMessage({id: `support.supportTicketActionsSection.tooltips.${key}`});
  }

  function handleReopenCaseHover() {
    if (canReopen) {
      return getTooltip('canReopen');
    }

    return getTooltip(
      status === SUPPORT_TICKET_STATUS.CANCELED ? 'cancelledCannotReopen' : 'cannotReopen'
    );
  }

  const styleActionButton = {
    backgroundColor: 'transparent',
    border: 0,
  };

  return (
    isSupportCase &&
    isClosed && (
      <View elementType="span" paddingEnd="size-65" paddingStart="size-0">
        <Button
          aria-label={intl.formatMessage({
            id: 'support.supportTicketActionsSection.reopenCase.title',
          })}
          class="reopen-case"
          data-testid="reopen-case-button"
          isDisabled={!canReopen}
          marginBottom="size-200"
          onClick={() => onReopenCase(SUPPORT_CONSTANTS.CASE_ACTIONS.REOPEN)}
          variant="primary"
        >
          {intl.formatMessage({id: 'support.supportTicketActionsSection.reopenCase.title'})}
        </Button>
        {handleReopenCaseHover()?.length > 0 && (
          <TooltipTrigger>
            <ActionButton UNSAFE_style={styleActionButton}>
              <InfoIcon />
            </ActionButton>
            <Tooltip>{handleReopenCaseHover()}</Tooltip>
          </TooltipTrigger>
        )}
      </View>
    )
  );
};

SupportTicketReopenCase.propTypes = {
  /**
   * Used to get reopen button tooltip value.
   */
  canReopen: PropTypes.bool,
  /**
   * Used to show reopen button if return value is true
   */
  isClosed: PropTypes.bool.isRequired,
  /**
   * Used to show reopen button if return value is true
   */
  isSupportCase: PropTypes.bool.isRequired,
  /**
   * Perform action when user click on reopen case button
   * The parameter is action type(reopen)
   */
  onReopenCase: PropTypes.func.isRequired,
  /**
   * Prop used to set tooltip
   */
  status: PropTypes.oneOf(Object.values(SUPPORT_TICKET_STATUS)),
};

export default SupportTicketReopenCase;
