import {TableSectionTable} from '@admin-tribe/binky-ui';
import {ActionButton, Badge} from '@adobe/react-spectrum';
import {ProductNameCell} from '@pandora/react-product-name-cell';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import OfferContextualHelp from 'features/products/components/contextual-offer-help/OfferContextualHelp';

// Offer table to display on the new product overview page.
const OfferTable = ({ariaLabel, onSelectOffer}) => {
  const intl = useIntl();

  const getProductTableAction = ({item}) => (
    <ActionButton
      data-testid={`free-offer-action-${item.offer_id}`}
      marginEnd="size-200"
      onPress={() => onSelectOffer(item.offer_id)}
    >
      {intl.formatMessage({id: 'products.allProducts.body.offers.getStarted'})}
    </ActionButton>
  );

  const getProductNameCell = ({item}) => {
    const contextualHelp = <OfferContextualHelp offer={item} />;

    return (
      <ProductNameCell
        contextualHelp={contextualHelp}
        iconSize="L"
        iconSrc={item.merchandising.assets.icons.svg}
        productName={item.merchandising.copy.name}
      />
    );
  };

  const getBadges = () => (
    <Badge variant="positive">
      {intl.formatMessage({id: 'products.allProducts.body.offers.freeOffer'})}
    </Badge>
  );

  const columnDescriptor = [
    {
      key: 'name',
      props: {width: '35%'},
    },
    {
      allowMissingValue: true,
      key: 'action',
      props: {align: 'end', hideHeader: true, showDivider: true, width: '10%'},
    },
    {
      key: 'tags',
      props: {align: 'end', width: '55%'},
    },
  ];

  const renderers = {
    action: getProductTableAction,
    name: getProductNameCell,
    tags: getBadges,
  };

  return (
    <TableSectionTable
      aria-label={ariaLabel}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.allProducts.table.column"
      density="spacious"
      renderers={renderers}
    />
  );
};
OfferTable.displayName = 'OfferTable';

OfferTable.propTypes = {
  /** Aria label to apply to the table. */
  ariaLabel: PropTypes.string.isRequired,
  /* Callback when an offer is selected. */
  onSelectOffer: PropTypes.func.isRequired,
};

export default OfferTable;
