import {AuthenticatedUser, FeaturesCache, Locale, authentication, log} from '@admin-tribe/binky';
import binkyUISrc2, {AnalyticsAdapter, getStrings} from '@admin-tribe/binky-ui';
import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
import 'cross-fetch';
import {AnalyticsProvider} from '@pandora/react-analytics-provider';
import {ApiContextProvider} from '@pandora/react-api-context-provider';
import {AuthProvider} from '@pandora/react-auth-provider';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import {EnvProvider} from '@pandora/react-env-provider';
import {FeatureFlagProvider} from '@pandora/react-feature-provider';
import {FetchProvider} from '@pandora/react-fetch-provider';
import {LoggerProvider} from '@pandora/react-logger-provider';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {IntlProvider} from 'react-intl';
import {RouterProvider} from 'react-router-dom';

import API_CONSTANTS from 'common/api/ApiConstants';
import rootStore from 'core/RootStore';
import buildRouter from 'core/router/buildRouter';
import pandoraContentModel from 'core/services/pandoraContentModel';
import GlobalHeader from 'shell/components/global-header/GlobalHeader';
import useShellSwitcher from 'shell/hooks/useShellSwitcher';

binkyUISrc2.common.utils.pandoraContent.addPandoraContentModel(pandoraContentModel);

const JIL_API_REGEXP = /^https:\/\/bps[\w-]+\.adobe\.io\/jil-api\//;

const pandoraRootContentModel =
  binkyUISrc2.common.utils.pandoraContent.getRootPandoraContentModel('OnesieContentModel');

/** The main entrypoint for AAC */
const App = observer(() => {
  const router = buildRouter();

  return (
    <AppProviders>
      <ShellSwitcher />
      {/* When all Angular UI is gone then the GlobalHeader can move back into Shell.jsx */}
      <GlobalHeader />
      {rootStore.isAngularSrcReady && <RouterProvider router={router} />}
    </AppProviders>
  );
});

/**
 * Component which tracks URL changes and updates the visible shell.
 * This is seperate from <App /> so that URL changes don't cause a re-render of the entire app.
 */
const ShellSwitcher = () => {
  useShellSwitcher();
  return null;
};

App.displayName = 'App';

const AppProviders = observer(({children}) => {
  const language = Locale.get().activeLanguage;
  const locale = Locale.get().activeLocaleCode;
  const messages = getStrings(language);
  const spectrumLocale = Locale.get().activeLocaleForSpectrum;
  const features = FeaturesCache.get()?.features || [];
  const authData = {
    getAccessToken: () => authentication.getAccessToken()?.token,
    getUserProfile: () => AuthenticatedUser.get().profile,
  };

  const customFetch = (path, options) => {
    const customHeaders =
      // This check against the JIL endpoint is needed since at least
      // one other endpoint (MILO) does not currently allow "Accept-Language" past its whitelist,
      // and JIL does not work with the latest browser "Accept-Language" locale-strings (like es-419)
      JIL_API_REGEXP.test(path)
        ? {
            'Accept-Language': locale,
            'X-Api-Key': authentication.getClientId(),
          }
        : {'X-Api-Key': authentication.getClientId()};
    const mergedOptions = {
      ...options,
      headers: {
        ...customHeaders,
        ...options.headers,
      },
    };

    // By default the built-in window fetch will be used. For browsers that do not support this,
    // cross-fetch fetch will be used as a polyfill. Polyfills are defined by not explicitly
    // importing the default like seen above (import 'cross-fetch';). We can not use Pandora fetch
    // due to it using cross-fetch as a ponyfill rather than a polyfill, which we are not able to
    // correctly mock for our fakes environment.
    return fetch(path, mergedOptions);
  };

  return (
    <IntlProvider defaultLocale="en" locale={language} messages={messages}>
      <SpectrumV2Provider
        locale={spectrumLocale}
        scale="medium"
        theme="light"
        UNSAFE_style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
      >
        <SpectrumV3Provider
          colorScheme="light"
          locale={spectrumLocale}
          theme={defaultTheme}
          UNSAFE_style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
        >
          <EnvProvider value={authentication.getAppEnv()}>
            <LoggerProvider value={log}>
              <AuthProvider value={authData}>
                <FetchProvider value={customFetch}>
                  <FeatureFlagProvider value={features}>
                    <ApiContextProvider
                      value={{
                        clientId: API_CONSTANTS.CLIENT_ID,
                        orgId: rootStore.organizationStore.activeOrgId,
                      }}
                    >
                      <ContentEntryProvider
                        locale={spectrumLocale}
                        model={pandoraRootContentModel}
                        value={binkyUISrc2.common.utils.pandoraContent.getRootPandoraContentValue(
                          messages
                        )}
                      >
                        <AnalyticsProvider value={AnalyticsAdapter}>{children}</AnalyticsProvider>
                      </ContentEntryProvider>
                    </ApiContextProvider>
                  </FeatureFlagProvider>
                </FetchProvider>
              </AuthProvider>
            </LoggerProvider>
          </EnvProvider>
        </SpectrumV3Provider>
      </SpectrumV2Provider>
    </IntlProvider>
  );
});

AppProviders.displayName = 'AppProviders';

AppProviders.propTypes = {
  /** The children for the providers */
  children: PropTypes.node,
};

export default App;
