import {Text, View} from '@adobe/react-spectrum';
import {SUPPORT_TICKET_STATUS} from '@pandora/data-model-support-ticket';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SupportTicketCloseCase from './SupportTicketCloseCase';
import SupportTicketEscalateCase from './SupportTicketEscalateCase';
import SupportTicketReopenCase from './SupportTicketReopenCase';
import SupportTicketSurvey from './SupportTicketSurvey';

/**
 * Component is used show the list of components for action section in the Support Cases.
 * It renders these components <SupportTicketCloseCase/>, <SupportTicketEscalateCase/>, <SupportTicketReopenCase/>, <SupportTicketSurvey/>
 */
const ActionSection = ({
  canReopen,
  canTakeSurvey,
  isCalendly,
  isClosed,
  isEscalated,
  isEscalationAllowed,
  isOpenCase,
  isSupportCase,
  onClick,
  surveyUrl,
  status,
}) => {
  const intl = useIntl();
  const componentName = 'appSupportTicketActionsSection';
  const isShowCloseButton = isSupportCase && isOpenCase;
  const isShowReopenButton = isSupportCase && isClosed;
  const isShowSurveyButton = canTakeSurvey;
  return (
    <View elementType="div">
      {isShowCloseButton && (
        <>
          <SupportTicketCloseCase
            isOpen={isOpenCase}
            isSupportCase={isSupportCase}
            onCloseCase={onClick}
          />
          <SupportTicketEscalateCase
            isEscalated={isEscalated}
            isEscalationAllowed={isEscalationAllowed}
            isOpen={isOpenCase}
            isSupportCase={isSupportCase}
            onEscalateCase={onClick}
          />
        </>
      )}
      {isShowReopenButton && (
        <SupportTicketReopenCase
          canReopen={canReopen}
          isClosed={isClosed}
          isSupportCase={isSupportCase}
          onReopenCase={onClick}
          status={status}
        />
      )}
      {isShowSurveyButton && (
        <SupportTicketSurvey
          canTakeSurvey={canTakeSurvey}
          componentName={componentName}
          surveyUrl={surveyUrl}
        />
      )}
      {!isShowCloseButton && !isShowReopenButton && !isShowSurveyButton && (
        <Text data-testid="no-action-content">
          {intl.formatMessage({
            id: isCalendly
              ? 'support.supportTicketActionsSection.isCalendly'
              : 'support.supportTicketActionsSection.noActionContent',
          })}
        </Text>
      )}
    </View>
  );
};

ActionSection.propTypes = {
  /**
   * Used to get canReopen button (enable/disable)
   */
  canReopen: PropTypes.bool,
  /**
   * Used to display survey button
   */
  canTakeSurvey: PropTypes.bool,
  /**
   * Prop used to show different user message under Action section
   */
  isCalendly: PropTypes.bool,
  /**
   * Used to get selected case is closed or not.
   */
  isClosed: PropTypes.bool.isRequired,
  /**
   * Used to set escalate case button tooltip & check selected case is already escalated.
   */
  isEscalated: PropTypes.bool,
  /**
   * Used to set escalate case button (enable/disable) & tooltip.
   */
  isEscalationAllowed: PropTypes.bool,
  /**
   * Used to check ticket is open.
   */
  isOpenCase: PropTypes.bool.isRequired,
  /**
   * Used to check if ticket belongs to support case.
   */
  isSupportCase: PropTypes.bool.isRequired,
  /**
   * Used to open popup/model on button click
   * The parameter is action type(close/reopen/escalate)
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Prop used to set tooltip
   */
  status: PropTypes.oneOf(Object.values(SUPPORT_TICKET_STATUS)),
  /**
   * Used to get surveyUrl for survey form.
   */
  surveyUrl: PropTypes.string,
};

export default ActionSection;
