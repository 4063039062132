import {feature} from '@admin-tribe/binky';
import {CLOUD} from '@pandora/administration-core-types';
import {FULFILLABLE_ITEM_TYPE, Product} from '@pandora/data-model-product';

const filterFulfillableItem = (item) =>
  ((item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.SERVICE ||
    item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA) &&
    item.code?.startsWith('support')) ||
  (item.fulfillableItemType !== FULFILLABLE_ITEM_TYPE.SERVICE &&
    item.fulfillableItemType !== FULFILLABLE_ITEM_TYPE.QUOTA);

/**
 * Method to return array of Product
 * For product.cloud === “EXPERIENCE” Exclude any product.fulfillableItems which are having
 * fulfillableItemType as SERVICE and QUOTA but code is NOT LIKE support%
 * @param {*} productListItems
 * @returns List of Product
 */
export const GetProductListItems = (productListItems) =>
  productListItems.map((product) => {
    if (
      product.cloud === CLOUD.EXPERIENCE &&
      feature.isEnabled('temp_support_ticket_product_filter')
    ) {
      return new Product({
        ...product,
        fulfillableItems: product.fulfillableItemList.items.filter(filterFulfillableItem),
      });
    }
    return new Product({
      ...product,
      fulfillableItems: product.fulfillableItemList.items,
    });
  });

export default GetProductListItems;
