(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.trustees:appTrusteeListTable
   * @description display list of trustees for an organization (TrustList) or a directory (DirectoryTrustList).
   */
  angular.module('app.widgets.trustees').component('appTrusteeListTable', {
    bindings: {
      trusteeList: '<', // either a TrustList or a DirectoryTrustList
    },
    controller,
    templateUrl: 'app/widgets/trustees/trustee-list-table/trustee-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    _,
    DIRECTORY_TRUSTEE_LIST_EVENT,
    feature,
    identityAccess,
    TRUSTEE_LIST_PAGINATION_ID,
    TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID,
    JIL_CONSTANTS,
    panelManager,
    Selection,
    SELECTION_EVENT,
    SELECTION_STATE,
    TRUSTEE_LIST_EVENT,
    TRUST_STATUS
  ) {
    const vm = this;
    const deregisterHandles = [];

    _.assign(vm, {
      $onDestroy,
      $onInit,
      canManageIdentityConfig: true,
      isAllChecked: false,
      isChecked,
      isSubsetChecked: false,
      onClickCheck,
      onClickCheckAll,
      onPageChange,
      onPageSizeChange,
      onToggleNotifyAdmins,
      paginationId: TRUSTEE_LIST_PAGINATION_ID,
      reverseSort,
      selection: new Selection('id'),
      sortReverse: false,
      wait: {},
    });

    deregisterHandles.push(
      // On trustList.refresh().
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(TRUSTEE_LIST_EVENT.UPDATE, updateHeaderCheckbox)
    );

    deregisterHandles.push(
      // On directoryTrustList.refresh().
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(DIRECTORY_TRUSTEE_LIST_EVENT.UPDATE, updateHeaderCheckbox)
    );

    deregisterHandles.push(
      // On selection change.
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(SELECTION_EVENT.UPDATE, updateHeaderCheckbox)
    );

    ///////////////

    function $onInit() {
      vm.canManageIdentityConfig = identityAccess.canChangeIdentityConfig();
    }

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    function isChecked(trustee) {
      return vm.selection.isItemSelected(trustee);
    }

    function onClickCheck(trustee) {
      vm.selection.toggleItemSelection(trustee);
    }

    function onClickCheckAll() {
      if (vm.isAllChecked) {
        vm.selection.deselectItems(vm.trusteeList.items);
      } else {
        vm.selection.selectItems(vm.trusteeList.items);
      }
    }

    function onPageChange(newPage) {
      vm.trusteeList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.trusteeList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.trusteeList.setPage(0);
      refreshData();
    }

    function onToggleNotifyAdmins(trustee) {
      // Set notifyAdmins so it reflects the position of the slider.
      trustee.notifyAdmins = !trustee.notifyAdmins;
      panelManager.open(TRUSTEES_TOGGLE_NOTIFICATIONS_MODAL_ID, {
        trustee,
      });
    }

    function reverseSort() {
      vm.sortReverse = !vm.sortReverse;
      vm.trusteeList.sortBy(JIL_CONSTANTS.SORT.TRUSTEE_ORG_NAME, vm.sortReverse);
      refreshData();
    }

    ///////////////

    function refreshData() {
      vm.trusteeList.refresh({status: TRUST_STATUS.ACTIVE});
    }

    function updateHeaderCheckbox() {
      if (_.get(vm, 'trusteeList.items.length', 0) > 0) {
        const selectionState = vm.selection.getSelectionStateForItems(vm.trusteeList.items);
        vm.isAllChecked = selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
