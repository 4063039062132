(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.organizations.user').constant('ORG_USER_ERROR_CODE', {
    INVALID_USER_EMAIL: 'invalidUserEmail',
    SERVER_ERROR: 'serverError',
    USER_ALREADY_EXISTS_IN_LICENSE_GROUP: 'userAlreadyExistsInLicenseGroup',
  });
})();
