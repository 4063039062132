(function () {
  /**
   * @deprecated moved to src2 as <SupportTicketStatusTag>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketStatusTag
   * @description renders a status tag for a support ticket using the appStatusTag component.
   */
  angular.module('app.widgets.support').component('appSupportTicketStatusTag', {
    bindings: {
      supportTicket: '<',
    },
    templateUrl:
      'app/widgets/support/support-ticket-status-tag/support-ticket-status-tag.component.html',
  });
})();
