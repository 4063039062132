import {ActionButton, Flex, Heading} from '@adobe/react-spectrum';
import CloseIcon from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import help from '../../constants/help';

const {HELP_RAIL_HEADING_ID} = help;

/**
 * The header component for the help rail
 */

const HelpRailHeader = ({onClose}) => {
  const intl = useIntl();

  return (
    <Flex
      alignItemts="center"
      data-testid="helprail-header"
      justifyContent="space-between"
      marginBottom="size-150"
    >
      <Heading id={HELP_RAIL_HEADING_ID} level="2" margin={0}>
        {rootStore.helpStore.rootTopic?.title &&
          intl.formatMessage({id: rootStore.helpStore.rootTopic.title})}
      </Heading>
      <ActionButton
        aria-label={intl.formatMessage({id: 'shell.helpRail.header.dismiss'})}
        autoFocus
        data-testid="helprail-button"
        id="close-button"
        isQuiet
        onPress={onClose}
        UNSAFE_style={{cursor: 'pointer'}}
      >
        <CloseIcon aria-label="Close icon" />
      </ActionButton>
    </Flex>
  );
};

HelpRailHeader.propTypes = {
  /**
   * Close button handler function
   */
  onClose: PropTypes.func.isRequired,
};

export default HelpRailHeader;
