import {
  Cache,
  DIRECTORY_TRUSTEE_LIST_EVENT,
  TRUSTEE_EVENT,
  eventBus,
  jilDirectories,
  log,
} from '@admin-tribe/binky';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import jilTrusts from 'features/settings/api/jilTrusts';
import {TRUST_STATUS} from 'features/settings/common/components/trustsConstants';
import {trustsBatchOperation} from 'features/settings/common/utils/trustUtils';

import {getCacheKey, mergeParamsWithDefaults} from './utils/hooksApiUtils';

const TRUST_LIST_CACHE_KEY = 'trustList';
const TRUST_ITEM_CACHE_KEY = 'trust';

const trustListCache = new Cache();

/**
 * Invalidate cache when registering Angular events
 * The possible events are:
 * DIRECTORY_TRUSTEE_LIST_EVENT.UPDATE - at directory level
 * TRUSTEE_EVENT.UPDATE - dispatched from Trustees and Access Requests tabs
 * There are 2 other events(ACCESS_REQUESTS_EVENT and TRUSTEE_LIST_EVENT
 * dispatched at the same time with TRUSTEE_EVENT.UPDATE,
 * so we add the listener only for TRUSTEE_EVENT.UPDATE )
 */
const invokeTrustEventListener = () => {
  const angularEvents = [DIRECTORY_TRUSTEE_LIST_EVENT.UPDATE, TRUSTEE_EVENT.UPDATE];

  eventBus.registerEventHandler((eventId) => {
    if (angularEvents.includes(eventId)) {
      trustListCache.clearAll();
    }
  });
};

invokeTrustEventListener();

const getTrustListCacheKey = (params) =>
  getCacheKey(TRUST_LIST_CACHE_KEY, rootStore.organizationStore.activeOrgId, params);

const getTrustItemCacheKey = ({orgId, directoryId}) =>
  getCacheKey(TRUST_ITEM_CACHE_KEY, orgId, directoryId);

const getDirectoryTrustListCacheKey = ({directoryId, ...params}) =>
  getCacheKey(TRUST_LIST_CACHE_KEY, rootStore.organizationStore.activeOrgId, directoryId, params);

/**
 * Gets the list of trusts for the provided directory id.
 *
 * @returns {Promise<{data, pageSize, totalCount: *}|*>}
 */
const getDirectoryTrusts = async ({directoryId, ...options} = {}) => {
  if (!directoryId) {
    throw new Error('Directory ID is required');
  }

  const params = mergeParamsWithDefaults({directoryId, ...options});

  const cacheKey = getDirectoryTrustListCacheKey(params);

  try {
    const cachedPromise = trustListCache.get(cacheKey);

    let response;

    // if we have a promise that is cached get the response from that
    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = jilDirectories.getDirectoryTrusts({
        directoryId,
        orgId: rootStore.organizationStore.activeOrgId,
        page: params.page,
        page_size: params.pageSize,
        search_query: params.searchQuery,
        sort: params.sort,
        sort_order: params.sortOrder,
        status: params.status,
      });
      // cache the promise before returning so parallel calls can wait for the same call
      trustListCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error(`[ID][JIL] Error getting the list of directory trusts`, error);

    // if there is an error, clear the cache, so we can try again
    trustListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * A function that helps with making calls to the JIL API for trusts.
 * Handles caching and logging.
 * @param {object} options
 * @param {number} options.page
 * @param {number} options.pageSize
 * @param {string} options.searchQuery
 * @param {string} options.sort
 * @param {string} options.sortOrder
 * @param {string} options.status
 * @returns {Promise<{data: any, pageSize: number, totalCount: number}>}
 * @throws {Error}
 * @example
 * const {data, pageSize, totalCount} = await getTrustList({
 *  page: 1,
 * pageSize: 10,
 * searchQuery: '',
 * sort: 'TRUSTEE_ORG_NAME',
 * sortOrder: 'ascending',
 * status: 'ACTIVE',
 * });
 */
const getTrustList = async (options = {}) => {
  const params = mergeParamsWithDefaults({status: TRUST_STATUS.ACTIVE, ...options});
  const cacheKey = getTrustListCacheKey(params);

  try {
    const cachedPromise = trustListCache.get(cacheKey);

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = jilTrusts.getTrusts({
        orgId: rootStore.organizationStore.activeOrgId,
        page: params.page,
        page_size: params.pageSize,
        search_query: params.searchQuery,
        sort: params.sort,
        sort_order: params.sortOrder,
        status: params.status,
      });

      // cache the promise before returning so parallel calls can wait for the same call
      trustListCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error(`[ID][JIL] Error getting the list of trusts`, error);

    // if there is an error, clear the cache, so we can try again
    trustListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * A hook that helps with making calls to the JIL API for trusts.
 * Handles caching and logging.
 */
const useTrustList = () => {
  const getTrustListCallback = useCallback(getTrustList, []);

  const getDirectoryTrustsCallback = useCallback(getDirectoryTrusts, []);

  const getTrust = useCallback(async (orgId, directoryId) => {
    try {
      const cacheKey = getTrustItemCacheKey({directoryId, orgId});
      const cachedResponse = trustListCache.get(cacheKey);

      if (cachedResponse) {
        return cachedResponse;
      }

      const response = await jilTrusts.getTrust({directoryId, orgId});
      trustListCache.put(cacheKey, response.data);

      return response.data;
    } catch (error) {
      log.error(`[ID][JIL] Error getting trust for org`, orgId, error);

      throw error;
    }
  }, []);

  const resendTrustRequest = useCallback(async (orgId, directoryId) => {
    try {
      const response = await jilTrusts.postTrustNotification({
        data: {directoryId},
        directoryId,
        orgId,
      });

      return response.data;
    } catch (error) {
      log.error(
        `[ID][JIL] Error resending trust request for directory ${directoryId} in org ${orgId}`,
        error
      );

      throw error;
    }
  }, []);

  const revokeTrusts = useCallback(async (trusts, reason = null) => {
    const operations = trusts.map((trust) => ({
      op: 'replace',
      path: reason
        ? `/${trust.trusteeOrgId}/${trust.directoryId}/REVOKE/reason/${reason}`
        : `/${trust.trusteeOrgId}/${trust.directoryId}/REVOKE`,
    }));

    try {
      const {failed, successful} = await trustsBatchOperation(operations);
      trustListCache.clearAll();

      return {
        failedToRevoke: failed,
        revoked: successful,
      };
    } catch (error) {
      log.error(`[ID][JIL] Error revoking trusts`, error);

      throw error;
    }
  }, []);

  const cancelTrustRequest = useCallback(async ({orgId, directoryId}) => {
    const operations = [
      {
        op: 'remove',
        path: `/${orgId}/${directoryId}`,
      },
    ];

    try {
      const {failed, successful} = await trustsBatchOperation(operations);
      trustListCache.clearAll();

      return {
        failedToRevoke: failed,
        revoked: successful,
      };
    } catch (error) {
      log.error(`[ID][JIL] Error revoking trusts`, error);

      throw error;
    }
  }, []);

  const saveNotificationsValue = useCallback(async (trust) => {
    const operations = [
      {
        op: 'replace',
        path: `/${trust.trusteeOrgId}/${trust.directoryId}/NOTIFICATIONS/${trust.notifyAdmins}`,
      },
    ];

    try {
      const {successful} = await trustsBatchOperation(operations);

      // "batch" operation on only one item, simply return whether it was successful or not
      return successful.length === 1;
    } catch (error) {
      log.error(
        `[ID][JIL] Error saving notifications value for trustee org ${trust.trusteeOrgId}`,
        error
      );

      throw error;
    }
  }, []);

  const clearTrustCache = useCallback(() => trustListCache.clearAll(), []);

  return {
    cancelTrustRequest,
    clearTrustCache,
    getDirectoryTrusts: getDirectoryTrustsCallback,
    getTrust,
    getTrustList: getTrustListCallback,
    resendTrustRequest,
    revokeTrusts,
    saveNotificationsValue,
  };
};

export default useTrustList;
export {
  getTrustList,
  // to be used in unit tests
  trustListCache,
  invokeTrustEventListener,
  getDirectoryTrusts,
};
