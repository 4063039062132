(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    auth,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    SVG_CLASS,
    onesieSrc2,
    OrganizationManager
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.SET_UP_SECURITY_CONTACTS,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          const contacts = OrganizationManager.getContactsForActiveOrg();
          if (!contacts.isAllContactsComplete()) {
            const namespace = 'overview.highValueActionArea.setUpTasks.securityContacts';
            return $q.resolve([
              // When porting this decorator to src2, update SecurityContactsHvaCard to use
              // Spectrum's DialogTrigger or the EditSecurityContactsButton instead of isOpen
              new HvaCard({
                ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                description: $translate.instant(`${namespace}.description`),
                header: $translate.instant(`${namespace}.header`),
                id: 'hva-security-contacts',
                onCTAClick: () => {
                  globalModalsManager.open({
                    componentBindings: [
                      {
                        attributeName: 'component',
                        type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                        value: onesieSrc2.common.components.SecurityContactsHvaCard,
                      },
                      {
                        attributeName: 'props',
                        type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                        value: {
                          isOpen: true,
                        },
                      },
                    ],
                    componentName: 'binkyReactContainer',
                  });
                },
                sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                svgClass: SVG_CLASS.POLICIES,
              }),
            ]);
          }
        }
        return $q.reject();
      }
    }
  }
})();
