(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.main.global-modals-manager:AddUserGlobalModal
   */
  angular
    .module('app.main.global-modals-manager')
    .factory('addUserGlobalModal', AddUserGlobalModal);

  /* @ngInject */
  function AddUserGlobalModal(
    $rootScope,
    GLOBAL_MODALS,
    OrganizationList,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    PageContext
  ) {
    const service = {
      open,
    };

    return service;

    //////////////

    /**
     * Initialize and open Add User Modal
     * @param {Object} options options for this method
     * @param {String} options.modalId the modal id
     * @param {PageContext} options.pageContext modal's page context
     */
    function open(options = {}) {
      const componentBindings = {
        modalId: options.modalId,
        pageContext:
          options.pageContext ||
          new PageContext({
            target: PAGE_TARGET.ORGANIZATION,
            targetId: OrganizationList.get().activeOrg.id,
            targetType: PAGE_TARGET_TYPE.USER,
          }),
      };

      $rootScope.$broadcast(GLOBAL_MODALS.OPEN, {
        componentBindings,
        componentName: 'appAddUserModal',
      });
    }
  }
})();
