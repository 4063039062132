import UserDeveloperIcon from '@spectrum-icons/workflow/UserDeveloper';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AddDevelopersToOrgModal from 'common/components/add-developer-modals/AddDevelopersToOrgModal';
import rootStore from 'core/RootStore';
import {hasAnyDeveloperProducts} from 'core/products/access/productAccess';

import QuickLink from '../QuickLink';

const AddDevelopersQuickLink = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <QuickLink
        analyticsOptions={{
          analyticsAction: 'add-developers',
        }}
        data-testid="quick-link-add-developers"
        Icon={<UserDeveloperIcon size="M" />}
        label={intl.formatMessage({id: 'overview.quickLinks.addDevelopers.title'})}
        onPress={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <AddDevelopersToOrgModal
          isOpen
          onClosed={() => setIsModalOpen(false)}
          onSuccess={() => setIsModalOpen(false)}
          orgId={rootStore.organizationStore.activeOrgId}
        />
      )}
    </>
  );
};

AddDevelopersQuickLink.propTypes = {};

AddDevelopersQuickLink.get = () => {
  if (hasAnyDeveloperProducts()) {
    return Promise.resolve(<AddDevelopersQuickLink key="AddDevelopersQuickLink" />);
  }

  return Promise.reject(new Error('User cannot assign developers'));
};

export default AddDevelopersQuickLink;
