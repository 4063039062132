/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCancelSessionRequestModal
 *
 * @description The modal to confirm the Request Expert Session form should be discarded without saving.
 *
 * @param {Function} onDiscard. The callback to the discard. It has no parameters.
 */
(function () {
  angular.module('app.widgets.support').component('appCancelSessionRequestModal', {
    bindings: {
      onDiscard: '&',
      onResume: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/support/cancel-session-request-modal/cancel-session-request-modal.component.html',
  });

  function controller(_, CANCEL_SESSION_REQUEST_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      CANCEL_SESSION_REQUEST_MODAL_ID,
    });
  }
})();
