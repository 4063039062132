(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').constant('IDP_CHOOSER_LOGOS', {
    AZURE: 'azure-idp-logo',
    BLACKBOARD: 'blackboard-idp-logo',
    CANVAS: 'canvas-idp-logo',
    CLASSLINK: 'classlink-idp-logo',
    CLEVER: 'clever-idp-logo',
    EDU: 'edu-idps-logo',
    GOOGLE: 'google-idp-logo',
    SAML: 'saml-idp-logo',
  });
})();
