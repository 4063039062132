/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
import {useCallback, useEffect, useRef, useState} from 'react';

/**
 * Observes the URL and returns the current pathname when the URL changes
 * @returns {{currentLocationPathname: string}}
 */
const useLocationChange = () => {
  const previousLocation = useRef(null);
  const [currentLocationPathname, setCurrentLocationPathname] = useState(window.location.pathname);

  /** Poll the URL and check for changes */
  const initializeUrlPolling = useCallback(() => {
    const interval = setInterval(() => {
      const previousPathname =
        previousLocation.current && new URL(previousLocation.current).pathname;
      const currentPathname = window.location.pathname;

      if (previousPathname !== currentPathname) {
        setCurrentLocationPathname(window.location.pathname);
        previousLocation.current = window.location.href;
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /** Initialize polling */
  useEffect(() => {
    const urlPollingIntervalClear = initializeUrlPolling();

    return () => {
      urlPollingIntervalClear();
    };
  }, [initializeUrlPolling]);

  return {
    currentLocationPathname,
  };
};

export default useLocationChange;
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
