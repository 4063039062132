(function () {
  /**
   * @deprecated - this list have been ported to src2
   *
   * @ngdoc factory
   * @name ProductConfigurationAdminList
   * @description product configuration admin list model
   */
  angular
    .module('app.core.product.configurations.admin-list')
    .factory('ProductConfigurationAdminList', getProductConfigurationAdminListFactory);

  /* @ngInject */
  function getProductConfigurationAdminListFactory(
    $log,
    $q,
    $rootScope,
    _,
    jilProducts,
    jilUsers,
    MEMBER_EVENT,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationUser,
    organizationUserUtils
  ) {
    class ProductConfigurationAdminList extends MemberList {
      /**
       * @class
       * @description Constructor for ProductConfigurationAdminList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.LICENSECONFIGURATIONADMINLIST,
              memberClassRef: OrganizationUser,
              resource: jilProducts.licenseGroupAdmins,
            },
            options
          )
        );
        this.modifiedUsers = [];
        this.roles = options.roles;
      }

      /**
       * @description Method for providing modified users, to be persisted on save
       * @param {Array} users - the users that have been modified
       * @returns {undefined} no return value
       */
      addModifiedUsers(users) {
        this.modifiedUsers = _.union(this.modifiedUsers, users);
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return super.key(getQueryParams(this));
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @param {Object} [options] - options passed to the refresh method of the List class
       * @param {Boolean} [options.delegatePromise] - if true, this.$promise must be resolved in the inherited class
       * @return {Promise} resolves to refreshed ProductConfigurationAdminList model, else rejects with error message
       */
      refresh(options = {}) {
        return super.refresh(getQueryParams(this), options).then((userList) => {
          $rootScope.$emit(MESSAGE.UPDATE.CONFIGURATIONADMINS.LIST, this.configurationId);
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(MESSAGE.UPDATE.CONFIGURATIONADMINS.COUNT, this.pagination.itemCount);
          }
          return userList;
        });
      }

      /**
       * @description Method to save changes to admin list to back-end.
       *
       * @param {Object} [options] - options passed to the method that contains the remove operation types for admin role
       * @param {Boolean} [options.removeUserAccess] - param that determines if user access needs to be removed or not
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(options = {}) {
        let deferred;
        if (this.roles && this.roles.length > 0) {
          const {removeUserAccess} = options;
          deferred = $q.defer();
          this.$promise = deferred.promise;

          // Calculate batch operations using helper function
          const operations = getBatchOperations(this, {
            removeUserAccess,
          });
          if (operations.length > 0) {
            this.$resolved = false;
            jilUsers.users.batchOperation(
              {},
              operations,
              () => {
                _.forEach(this.modifiedUsers, (user) => {
                  $rootScope.$emit(MEMBER_EVENT.UPDATE, user.id);
                });
                this.$resolved = true;
                this.modifiedUsers = [];
                this.filter.query = '';
                this.refresh({delegatePromise: true})
                  .then(() => {
                    deferred.resolve(this);
                  })
                  .catch(deferred.reject);
              },
              onError.bind(this)
            );
          } else {
            // no changes to save
            deferred.resolve(this);
          }
          return deferred.promise;
        }

        function onError(error) {
          $log.error('Failed to remove or modify selected users. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        return super.save({
          groupId: this.configurationId,
          productId: this.productId,
        });
      }

      /**
       * @description Method to retrieve an existing ProductConfigurationAdminList from the back-end.
       *
       * @param {String} productId ID of product the configuration is for
       * @param {String} configurationId ID of configuration to fetch admins for
       * @param {Object} options - options passed that contains the number of product admins to display per page and the roles field
       *
       * @returns {ProductConfigurationUserList} new ProductConfigurationAdminList Object reference
       */
      static get(productId, configurationId, options = {}) {
        const {pageSize, roles} = options;
        let model = new ProductConfigurationAdminList({pageSize, roles});
        model.productId = productId;
        model.configurationId = configurationId;

        const cachedModel = modelCache.get(MODEL.LICENSECONFIGURATIONADMINLIST, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    MemberList.setupCache(MODEL.LICENSECONFIGURATIONADMINLIST, 10);

    return ProductConfigurationAdminList;

    ////////////
    /**
     * @description Method to return the arguments for key and refresh methods
     *
     * @param {Object} model reference to the class object
     * @returns {Object} an object containing groupId, product id and roles if provided and feature flag enabled
     */
    function getQueryParams(model) {
      return _.omitBy(
        {
          groupId: model.configurationId,
          productId: model.productId,
          roles: _.join(model.roles, ','),
        },
        _.isNil
      );
    }

    /**
     * @description Helper method to construct the patches for Admin Role changes to be saved.
     *
     * @param {Object} model reference to the class object
     * @param {Object} [options] - options passed to the method that contains the remove operation types for the admin role
     * @param {Boolean} [options.removeUserAccess] - param that determines if user access needs to be removed or not
     * @returns {Array} the set of patches to be performed
     */
    function getBatchOperations(model, options = {}) {
      const {removeUserAccess} = options;
      let operations = [];
      _.forEach(getModifiedUsers(model.modifiedUsers), (member) => {
        operations = _.union(operations, organizationUserUtils.getAdminRolePatches(member));
        // Case when user access needs to be removed as well
        if (removeUserAccess) {
          const removeUserOperations = [
            {
              op: 'remove',
              path: `/${member.id}/products/${model.productId}/licenseGroups/${model.configurationId}`,
            },
          ];
          operations = _.union(operations, removeUserOperations);
        }
      });
      return operations;
    }

    /**
     * @description filter the modifiedUsers list for those with role changes.
     *
     * @param {Array} users an array of OrganizationUser objects to inspect for changes
     * @returns {Array} users with changes
     */
    function getModifiedUsers(users) {
      // Temporarily disable for https://github.com/Gillespie59/eslint-plugin-angular/issues/449

      return _.filter(
        users,
        (user) =>
          !compareArrays(
            organizationUserUtils.explodeAdminRoles(user.savedState.roles),
            organizationUserUtils.explodeAdminRoles(user.roles),
            organizationUserUtils.compareAdminRoles
          )
      );
    }

    /**
     * @description Compare two arrays.
     *
     * @param {Array} arr1 reference to the first array
     * @param {Array} arr2 reference to the array to compare to
     * @param {Function} isEqual the function to use to compare the two arrays
     * @returns {Boolean} true if the two items are equal
     */
    function compareArrays(arr1, arr2, isEqual) {
      return (
        _(arr1).differenceWith(arr2, isEqual).isEmpty() &&
        _(arr2).differenceWith(arr1, isEqual).isEmpty()
      );
    }
  }
})();
