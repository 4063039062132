/**
 * @deprecated with temp_react_port_users
 *
 * @ngdoc component
 * @name app.widgets.users:appRemoveUserStorageUserListSection
 *
 * @description The section for remove user storage modal, display list of users.
 * @binding {Array} userList, an array of OrganizationUser
 * @binding {Array} userStorageList, an array of UserFolder
 */
(function () {
  angular.module('app.widgets.users').component('appRemoveUserStorageUserListSection', {
    bindings: {
      userList: '<',
      userStorageList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/remove-user-storage-modal/user-list-section/remove-user-storage-user-list-section.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        storageUsers: _.map(vm.userStorageList, (userStorage) =>
          _.find(vm.userList, ['id', userStorage.name])
        ),
        usersWithNoStorage: _.differenceWith(
          vm.userList,
          vm.userStorageList,
          (arrVal, othVal) => arrVal.id === othVal.name
        ),
      });
    }
  }
})();
