import {MemberListTable, useStore} from '@admin-tribe/binky-ui';
import {Divider, View, useDialogContainer} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AddApiCredentialsModalContentModel from 'common/components/add-api-credentials-modal/content/AddApiCredentialsModalContentModel';
import MemberProductIconList from 'common/components/member-product-icon-list/MemberProductIconList';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import ApiCredentialsStore from 'common/stores/api-credentials-store/ApiCredentialsStore';
import updateMemberStore from 'common/stores/member-store/updateMemberStore';

/**
 * The modal opened from the 'Add API credentials' button on the ProductProfileApiCredentialsSubpage.
 * It displays a paged table of organization API integrations which can be added to the profile.
 */
const AddApiCredentialsModal = ({onSuccess}) => {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmittingError, setHasSubmittingError] = useState(false);
  const {store: licenseGroupIntegrationStore} = useMemberListContext();
  const dialog = useDialogContainer();

  const content = useContentEntry(AddApiCredentialsModalContentModel);
  const description = intl.formatMessage({id: 'common.addApiCredentialsModal.description'});

  const store = useStore(
    () =>
      new ApiCredentialsStore({
        ...licenseGroupIntegrationStore.listOptions, // licenseGroupId, productId
      })
  );

  // Invoked by the close button on a modal error toast.
  const onCloseErrorToast = () => {
    setHasSubmittingError(false);
  };

  const onCta = async () => {
    // Maintain the selection. If success, the component will unmount so it will be removed.
    setIsSubmitting(true);
    try {
      await store.saveProfileApiCredentials({
        licenseGroupIntegrationList: licenseGroupIntegrationStore.memberList,
      });
    } catch (error) {
      setHasSubmittingError(true);
      return;
    } finally {
      setIsSubmitting(false);
    }

    dialog.dismiss();
    onSuccess();
  };

  const shouldDisableCta = () =>
    store.selectedItems.length === 0 ||
    store.isLoading ||
    store.hasLoadingError ||
    isSubmitting ||
    hasSubmittingError;

  const descriptionId = 'add-api-credentials-description-id';

  return (
    <ModalDialog
      analyticsModalId="add-api-credentials"
      content={content.search(ModalDialogModel)[0]}
      dataTestId="add-api-credentials-modal"
      errorMessage={
        hasSubmittingError
          ? intl.formatMessage({id: 'common.addApiCredentialsModal.errorMessage'})
          : undefined
      }
      heightVariant="static"
      isCtaDisabled={shouldDisableCta()}
      isLoading={store.isLoading || isSubmitting}
      onCancel={() => dialog.dismiss()}
      onCloseError={onCloseErrorToast}
      onCta={onCta}
    >
      <ModalHeading>
        {intl.formatMessage(
          {id: 'common.addApiCredentialsModal.modalHeading'},
          {profileName: store.profileName}
        )}
        <Divider marginY="size-125" size="M" />
      </ModalHeading>
      <ModalContent>
        <View id={descriptionId} marginBottom="size-100">
          {description}
        </View>
        {(store.currentItems || store.hasLoadingError) && (
          <TableSection
            getIsItemSelectable={(item) => store.getIsItemSelectable(item)}
            isServerError={store.hasLoadingError}
            items={store.currentItems ?? []}
            onTableSectionChange={(params) => updateMemberStore({...params, store})}
            pageNumber={store.pageNumber}
            selectionMode={SELECTION_MODE.MULTIPLE}
            totalPages={store.totalPages}
          >
            <MemberListTable
              aria-labelledby={descriptionId}
              display={{
                avatar: false,
                createdDate: true,
                lastModifiedDate: true,
                productIcons: true,
              }}
              noItemsFoundContentMessage={intl.formatMessage({
                id: 'common.addApiCredentialsModal.noItemsFoundContentMessage',
              })}
              /* 0 means show all products */
              productIconList={<MemberProductIconList inlineProductCount={0} />}
            />
          </TableSection>
        )}
      </ModalContent>
    </ModalDialog>
  );
};

AddApiCredentialsModal.propTypes = {
  /**
   * Function called when the modal is closed after the API credentials have been added to the profile.
   * This should refresh the table's LicenseGroupIntegrationList.
   */
  onSuccess: PropTypes.func.isRequired,
};

// This syntax necessary to work around React 16 bug with displayName.
// ref: https://github.com/facebook/react/issues/18026
// ref: https://mobx.js.org/react-integration.html#displayname
export default observer(AddApiCredentialsModal);
