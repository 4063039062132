// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Checkbox from '@react/react-spectrum/Checkbox';
import {TD} from '@react/react-spectrum/Table';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import TableContext from './TableContext';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const DataCell = ({
  children,
  className,
  colspan,
  first,
  onSelect = () => {},
  selectable = true,
  selected,
}) => {
  const tableContext = useContext(TableContext);

  return (
    <TD
      className={className}
      colSpan={colspan}
      role={first ? 'rowheader' : 'gridcell'}
      styleName={tableContext.isTableSelectable && first ? 'selectable-cell' : ''}
    >
      {tableContext.isTableSelectable && first ? (
        <Checkbox
          checked={selected}
          disabled={!selectable}
          onChange={onSelect}
          styleName="checkbox"
        />
      ) : null}
      <span>{children}</span>
    </TD>
  );
};

DataCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  colspan: PropTypes.string,
  first: PropTypes.bool,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
};

export default DataCell;
