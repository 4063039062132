import {showInfo} from '@admin-tribe/binky-ui';
import {runInAction} from 'mobx';

import {CREATE_PACKAGE_CONSTANTS, KCCC_SAP_CODE} from 'features/packages/packagesConstants';
import {isLatestKCCC} from 'features/packages/stores/createPackageModalStoreUtils';

/* eslint-disable complexity -- complex filter */
/**
 * @description Checks if product satisfies the available apps filters
 * @param {Object} appFilters - App filters that can be applied on the available products list
 * @param {Boolean} isEnterpriseSupportPolicyApplicable - If enterprise support policy is applicable
 * @param {Object} product - product on which filter should be applied
 * @param {Object} packageType - package type
 *
 * @return {Boolean} Return true if product satisfies the app filters
 */
function isAvailableProduct(appFilters, isEnterpriseSupportPolicyApplicable, product) {
  // KCCC sapCode indicates Creative cloud desktop application and it should not be included as part
  // of available products list, as it can only be selected by default
  if (product.sapCode === KCCC_SAP_CODE || !product.visible || product.selected) {
    return false;
  }
  const matchesShowLatestFilter =
    appFilters.showLatest && !product.archive && !product.betaAppApui && !product.preReleaseApui;
  let matchesShowOlderFilter =
    appFilters.showOlder &&
    product.isUnsupportedVersion &&
    !product.betaAppApui &&
    !product.preReleaseApui;
  if (!isEnterpriseSupportPolicyApplicable) {
    matchesShowOlderFilter =
      matchesShowOlderFilter || (appFilters.showOlder && product.isMaintenanceBuild);
  }
  const matchesShowLTSFilter = appFilters.showLTS && product.isMaintenanceBuild;
  const matchesShowBetaFilter = appFilters.showBeta && product.betaAppApui;
  const matchesShowPreReleaseFilter = appFilters.showPreRelease && product.preReleaseApui;
  const matchesSearchFilter =
    !appFilters.searchText ||
    (appFilters.searchText &&
      product.name?.toUpperCase().includes(appFilters.searchText.toUpperCase()));

  return (
    (matchesShowLatestFilter ||
      matchesShowOlderFilter ||
      matchesShowLTSFilter ||
      matchesShowBetaFilter ||
      matchesShowPreReleaseFilter) &&
    matchesSearchFilter
  );
}
/* eslint-enable complexity -- complex filter */

/**
 * @description Retrieves array of strings to be displayed when available products section is empty
 * @param {Array} appFilters - app filters that can be applied on the available products list
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 *
 * @return {Array} Returns array of strings to be displayed when available products section is empty
 */
function getEmptyAvailableAppsMessage(appFilters, intl) {
  // all filters set to false
  if (Object.values(appFilters).every((val) => !val)) {
    return [
      intl.formatMessage({
        id: 'packages.createPackage.chooseApps.availableApps.emptyFilters.title',
      }),
      intl.formatMessage({
        id: 'packages.createPackage.productSelection.availableApps.emptyFilters.desc',
      }),
    ];
  }
  // just search text is set
  if (appFilters.searchText) {
    return [
      intl.formatMessage({
        id: 'packages.createPackage.chooseApps.availableApps.emptySearch.title',
      }),
      intl.formatMessage({
        id: 'packages.createPackage.chooseApps.availableApps.emptySearch.desc',
      }),
    ];
  }
  // otherwise all apps according to current criteria are already added
  return [
    intl.formatMessage({
      id: 'packages.createPackage.chooseApps.availableApps.allAppsAdded.title',
    }),
    intl.formatMessage({
      id: 'packages.createPackage.chooseApps.availableApps.allAppsAdded.desc',
    }),
  ];
}

/**
 * @description Checks if same base version product has been added to selected products
 * @param {String} toastMessage - message to be displayed in the toast
 * @param {Array} products - products in which same base verison is to be checked
 * @param {Object} selectedProduct - product that has been selected
 * @param {Object} showToast - ref to keep track of the toast
 *
 * @return {Boolean} Returns true if same base version product has been added to selected products
 */
function toShowToast(toastMessage, products, selectedProduct, showToast) {
  // check if two products have same sapCode equal to APRO
  if (
    products.some(
      (product) => product.sapCode === selectedProduct.sapCode && selectedProduct.sapCode === 'APRO'
    )
  ) {
    if (showToast.current) {
      showToast.current = !showToast.current;
      showInfo(toastMessage.apro);
    }
    return true;
  }
  if (
    products.some(
      (product) =>
        product.baseVersion === selectedProduct.baseVersion &&
        product.sapCode === selectedProduct.sapCode
    )
  ) {
    if (showToast.current) {
      showToast.current = !showToast.current;
      showInfo(toastMessage.base);
    }
    return true;
  }
  return false;
}

/**
 * @description Retrieves updated selected products
 * @param {Function} addLicenseFile - Function to add license file to selected products section
 * @param {String} aproClassicDCMessage - message to be displayed in the toast if both acrobat DC and classic are selected
 * @param {String} multipleSameBaseVersionMessage - message to be displayed in the toast if multiple apps of same base version are selected
 * @param {Array} productsGroupedBySapCode - products grouped by sap code
 * @param {Array} selectedProducts - selected products
 * @param {Object} showMultipleSameBaseVersionProductsToast - ref to keep track of the toast
 *
 * @return {Array} Returns updated selected products
 */
function getSelectedProducts({
  addLicenseFile,
  aproClassicDCMessage,
  multipleSameBaseVersionMessage,
  productsGroupedBySapCode,
  selectedProducts,
  showToast,
}) {
  const allSelectedProductsList = [];
  productsGroupedBySapCode.forEach((productGroup) => {
    productGroup.products.forEach((product) => {
      // license file will be added separately, should not be included in selected products
      if (product.sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE) {
        addLicenseFile();
        return;
      }

      const toastMessage = {apro: aproClassicDCMessage, base: multipleSameBaseVersionMessage};

      if (
        !toShowToast(
          toastMessage,
          [...selectedProducts, ...allSelectedProductsList],
          product,
          showToast
        )
      ) {
        runInAction(() => {
          product.selected = true;
        });
        allSelectedProductsList.unshift(product);
      }
    });
  });
  return allSelectedProductsList;
}

/**
 * @description Retrieves updated product groups
 * @param {Array} filteredAPUIs - Filtered list of packageable products for the current platform
 *
 * @return {Array} Returns updated product groups
 */
function getNewProdGroups(filteredAPUIs) {
  const newProductsGroupedBySapCode = [];
  filteredAPUIs.forEach((product) => {
    const existingProdGroup = newProductsGroupedBySapCode.find(
      (productGroup) => productGroup.sapCode === product.sapCode
    );
    // if prodGroup already added push product into existing prodGroup
    if (existingProdGroup) {
      existingProdGroup.products.push(product);
      // if product not archived rename existing prod grp name to product name
      if (!product.archive) {
        existingProdGroup.name = product.name;
      }
    } else {
      const newProdGroup = {
        name: product.name,
        products: [product],
        sapCode: product.sapCode,
      };
      newProductsGroupedBySapCode.push(newProdGroup);
    }
  });
  return newProductsGroupedBySapCode;
}

/**
 * @description Retrieves Current expanded product sapcodes
 * @param {Boolean} adobeProductsUi - Exhaustive list of packageable products for the current platform
 * @param {Function} applyisAvailableProduct - filter to be applied on the available products
 * @param {Function} showLicenseFileInAvailableProductsList - checks if license file in available products list
 *
 * @return {Array} Returns Current expanded product sapcodes
 */
function getCurrentExpandedProductSapCodes(
  adobeProductsUi,
  applyisAvailableProduct,
  showLicenseFileInAvailableProductsList
) {
  const newCurrentExpandedProductSapCodes = adobeProductsUi
    .filter(applyisAvailableProduct)
    .map((product) => product.sapCode);

  if (showLicenseFileInAvailableProductsList()) {
    newCurrentExpandedProductSapCodes.unshift(CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE);
  }
  return newCurrentExpandedProductSapCodes;
}

/**
 * @description Retrieves newly added sapcodes in availbe product list
 * @param {Array} productsGroupedBySapCode - Older list of products grouped by sapCode
 * @param {Array} newProductsGroupedBySapCode - New list of products grouped by sapCode
 *
 * @return {Array} Returns list of sapCodes that are present in newProductsGroupedBySapCode and not in productsGroupedBySapCode
 */
function newlyAddedSapCodes(productsGroupedBySapCode, newProductsGroupedBySapCode) {
  if (productsGroupedBySapCode.length < newProductsGroupedBySapCode.length) {
    const productsGroupedBySapCodeMap = {};
    productsGroupedBySapCode.forEach((oldProduct) => {
      productsGroupedBySapCodeMap[oldProduct.sapCode] = productsGroupedBySapCodeMap;
    });
    return newProductsGroupedBySapCode
      .filter((newProduct) => !productsGroupedBySapCodeMap[newProduct.sapCode])
      .map((product) => product.sapCode);
  }
  return [];
}

/**
 * @description Function to check if KCCC is to be shown in Available products list for lightweight packages workflow
 * @param {Object} object function parameters, defined below
 * @param {Object} object.appFilters - filters on Available products
 * @param {Number} object.bits - selected OS bits
 * @param {Object} object.product - product
 * @param {Boolean} object.showAccInUI - Boolean denoting whether to show Adobe Creative Cloud or not
 *
 * @return {Boolean} true if KCCC is available to show in Available Products, false otherwise
 */
function isKCCCAvailable({appFilters, bits, product, showAccInUI}) {
  const isProductAvailable = appFilters.searchText
    ? product.name?.toUpperCase().includes(appFilters.searchText.toUpperCase())
    : true;
  return isLatestKCCC(product, bits) && showAccInUI && !product.selected && isProductAvailable;
}

export {
  isAvailableProduct,
  getCurrentExpandedProductSapCodes,
  getEmptyAvailableAppsMessage,
  getNewProdGroups,
  getSelectedProducts,
  newlyAddedSapCodes,
  toShowToast,
  isKCCCAvailable,
};
