(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets:appExpertSessionListTable
   * @description Displays a list of open or closed expert sessions.
   *
   * @binding {Object} display. The columns to show.
   * @binding {Object} expertSessionList. An expert session list. It could be a list of open sessions, a list of open
   *   sessions filtered for the logged-in admin or a list of closed sessions. No assumptions are made here about its
   *   contents.
   * @binding {Integer} [itemsPerPage]. Specify if there should be NOT be a paginator at the bottom of the table.
   *  Do not specify this if paginationId is specified.
   * @binding {String} [paginationId]. Specify if there should be a paginator at the bottom of the table.
   * @binding {String} [searchText]. Default is no search. The string to search for. Whitespace is assumed to have
   *   been trimmed already.
   */
  angular.module('app.widgets.support').component('appExpertSessionListTable', {
    bindings: {
      display: '<?',
      expertSessionList: '<',
      itemsPerPage: '<?', // specified if there should be NOT be a paginator at the bottom of the table
      paginationId: '@?', // specified if there should be a paginator at the bottom of the table
      searchText: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/support/expert-session-list-table/expert-session-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    binkyDateTimeFilter,
    $filter,
    $translate,
    _,
    onesieSrc2,
    LIST_DEFAULT_PAGE_SIZE,
    SUPPORT_TICKET_CASE_TYPE,
    SUPPORT_TICKET_UI_STATUS
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      closeTooltip,
      currentPage: 1,
      getSessionAdminText,
      getSessionDateRequestedText,
      getSessionDateScheduledText,
      getSessionProductNameText,
      getSessionStatusText,
      onClickRow,
      onColumnSort,
      onPageSizeChange,
      openTooltip,
      orderByExpression: '',
      SORT_ADMIN: 'adminFullname',
      SORT_DATE_REQUESTED: 'createdOn',
      SORT_PRODUCT: 'productName',
      SORT_STATUS: 'sortValueStatus',
      sortAscending: true, // true if sortField should be sorted in ascending order
      sortField: '', // the currently sorted column
      SUPPORT_TICKET_CASE_TYPE,
      SupportTicketStatus: onesieSrc2.support.components.SupportTicketStatus,
      tableFilterFn,
      tooltipOpenIndex: -1,
    });

    function $onInit() {
      vm.columnCount = _.keys(vm.display).length; // equals 0 if vm.display is undefined
      vm.itemsPerPage = vm.itemsPerPage || LIST_DEFAULT_PAGE_SIZE;

      // Default ordering of the expert session table should be based on the following by order of importance
      // 1) Date request: most recent first - ISO 8601 dates are designed to be sorted as strings.
      // 2) Status: Sort value status for expert session
      // 3) Admin name: admin fullname
      // add admin name to make sure it can be sort on admin column and avoid digest loop errors
      vm.orderByExpression = [
        `-${vm.SORT_DATE_REQUESTED}`,
        getSortValueStatus,
        setSortValueAdminName,
      ];
    }

    function getSessionAdminText(expertSession) {
      return $filter('appSupportTicketPersonName')(expertSession.admin);
    }

    // AdminName: get first and last name from support.admin
    // Set the filtered value for this items's adminFullname.
    function setSortValueAdminName(expertSession) {
      expertSession.adminFullname = $filter('appSupportTicketPersonName')(expertSession.admin);
    }

    // Sort value status for expert session
    // Return the numeric sort value for this items's status.
    function getSortValueStatus(expertSession) {
      const uiStatusSortOrder = [
        SUPPORT_TICKET_UI_STATUS.PENDING_RESPONSE,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_PROVIDED,
        SUPPORT_TICKET_UI_STATUS.AWAITING_ASSIGNMENT,
        SUPPORT_TICKET_UI_STATUS.IN_PROGRESS,
        SUPPORT_TICKET_UI_STATUS.SESSION_SCHEDULED,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_CONFIRMED,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_IRRELEVANT,
        SUPPORT_TICKET_UI_STATUS.SESSION_COMPLETED,
        SUPPORT_TICKET_UI_STATUS.ISSUE_OBSOLETE,
        SUPPORT_TICKET_UI_STATUS.MERGED,
        SUPPORT_TICKET_UI_STATUS.AUTO_CLOSED,
        SUPPORT_TICKET_UI_STATUS.CANCEL_MISROUTE,
      ];

      const uiStatus = expertSession.getUiStatus();
      expertSession.sortValueStatus = _.includes(uiStatusSortOrder, uiStatus)
        ? _.indexOf(uiStatusSortOrder, uiStatus)
        : uiStatusSortOrder.length;
      return expertSession.sortValueStatus;
    }

    function getSessionDateRequestedText(expertSession) {
      return binkyDateTimeFilter(expertSession.createdOn, {dateOnly: true});
    }

    function getSessionDateScheduledText(expertSession) {
      if (expertSession.scheduledFor) {
        return binkyDateTimeFilter(expertSession.scheduledFor, {
          dateOnly: true,
        });
      }
      return $translate.instant('widgets.support.expertSessionDateScheduled.notYetScheduled');
    }

    function getSessionProductNameText(expertSession) {
      expertSession.productName = expertSession.getProductName();
      return expertSession.productName;
    }

    function getSessionStatusText(expertSession) {
      return $filter('appSupportTicketStatus')(expertSession);
    }

    function onClickRow(event /* , expertSession */) {
      if (!wasClickOnReservedElement(event)) {
        // open drawer here
      }
    }

    function onColumnSort(sortField) {
      const sortAscending = vm.sortField === sortField ? !vm.sortAscending : true;
      sortBy({sortAscending, sortField});
    }

    function onPageSizeChange(pageSize) {
      vm.currentPage = 1;
      vm.itemsPerPage = pageSize;
    }

    /**
     * Returns true if the table row for the specified expert session should be shown, based on the
     * current filter criteria. Search is case-insensitive and searches within one column at a time.
     *
     * @param {ExpertSession} expertSession - the expert session to search
     * @return {Boolean} true if the expert session should be included in the table
     */
    function tableFilterFn(expertSession) {
      // Note, whitespace is already stripped by search component
      const searchText = _.toLower(vm.searchText);

      // Search the fields one by one, starting with those we think will be searched most often
      return (
        !searchText ||
        includesText(searchText, getSessionAdminText(expertSession)) ||
        includesText(searchText, expertSession.title) ||
        includesText(searchText, expertSession.getProductName()) ||
        includesText(searchText, getSessionStatusText(expertSession)) ||
        includesText(searchText, getSessionDateRequestedText(expertSession)) ||
        includesText(searchText, getSessionDateScheduledText(expertSession)) ||
        includesText(searchText, expertSession.id)
      );
    }

    ////////////

    function includesText(searchText, stringToSearch) {
      return _(stringToSearch).toLower().includes(searchText);
    }

    function sortBy({sortAscending, sortField}) {
      vm.orderByExpression = sortAscending ? `+${sortField}` : `-${sortField}`;

      _.assign(vm, {
        sortAscending,
        sortField,
      });
    }

    function wasClickOnReservedElement(event) {
      return event.target.tagName === 'A';
    }

    function closeTooltip() {
      vm.tooltipOpenIndex = -1;
    }

    function openTooltip(index) {
      vm.tooltipOpenIndex = index;
    }
  }
})();
