import {Flex, Grid, Text, View} from '@adobe/react-spectrum';
import {TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {MILLISECONDS_IN_A_DAY} from 'features/packages/packagesConstants';

const ServerDetails = ({server}) => {
  const intl = useIntl();

  function getLanTimeoutInDays() {
    return server.lanTimeout / MILLISECONDS_IN_A_DAY;
  }

  const getServerEntitlements = () => (
    <Flex gap={server.hasSoftQuota() && server.hasHardQuota() ? 'size-50' : 'size-0'}>
      {server.hasSoftQuota() && (
        <span>
          {intl.formatMessage({
            id: 'packages.serverSummary.serverDetails.softQuota',
          })}
        </span>
      )}
      {server.hasHardQuota() && (
        <span>
          {intl.formatMessage({
            id: 'packages.serverSummary.serverDetails.hardQuota',
          })}
        </span>
      )}
    </Flex>
  );

  const serverDetailsMap = [
    {
      field: intl.formatMessage({
        id: 'packages.serverSummary.serverDetails.dnsAddress',
      }),
      value: server.dns,
    },
    {
      field: intl.formatMessage({
        id: 'packages.serverSummary.serverDetails.lanTimeout',
      }),
      value: getLanTimeoutInDays()
        ? intl.formatMessage(
            {id: 'packages.serverSummary.serverDetails.lanTimeoutValue'},
            {lanTimeout: getLanTimeoutInDays()}
          )
        : intl.formatMessage({id: 'packages.serverSummary.serverDetails.lanTimeoutValue3'}),
    },
    {
      field: intl.formatMessage({
        id: 'packages.serverSummary.serverDetails.entitlementLimits',
      }),
      value: getServerEntitlements(),
    },
    {
      field: intl.formatMessage({
        id: 'packages.serverSummary.serverDetails.branding',
      }),
      value: server.branding,
    },
  ];

  return (
    <TitledSection
      marginTop="size-500"
      title={intl.formatMessage({
        id: 'packages.serverSummary.serverDetails.heading',
      })}
    >
      <View marginStart="size-200" marginTop="size-100">
        {serverDetailsMap.map((serverDetail) => (
          <Grid
            key={serverDetail.field}
            areas={['field value']}
            columns={['2fr', '3fr']}
            gap="size-200"
            marginBottom="size-300"
            rows={['auto']}
          >
            <Text
              alignSelf="center"
              data-testid={`${serverDetail.field}`}
              gridArea="field"
              UNSAFE_style={{
                fontSize: 'var(--spectrum-global-dimension-size-130)',
                fontWeight: 'bold',
              }}
            >
              {serverDetail.field}
            </Text>
            <Text
              data-testid={`${serverDetail.field}-text`}
              gridArea="value"
              UNSAFE_style={{
                fontSize: 'var(--spectrum-global-dimension-size-175)',
              }}
              width="size-2400"
            >
              {serverDetail.value}
            </Text>
          </Grid>
        ))}
      </View>
    </TitledSection>
  );
};
ServerDetails.propTypes = {
  /**
   * Content to be shown in Server Details section
   */
  server: PropTypes.shape({
    /**
     * String to indicate activation enforcement type
     */
    activationEnforcementType: PropTypes.string,
    /**
     * String to indicate organisation name
     */
    branding: PropTypes.string,
    /**
     * String to indicate dns server url
     */
    dns: PropTypes.string,
    /**
     * Expiry timestamp of the server in milliseconds
     */
    expiryTimestamp: PropTypes.number,
    /**
     * Function to check if the server has hard quota
     */
    hasHardQuota: PropTypes.func,
    /**
     * Function to check if the server has soft quota
     */
    hasSoftQuota: PropTypes.func,
    /**
     * Function to check if the server is active
     */
    isActive: PropTypes.func,
    /**
     * Function to check if the server is in creation state
     */
    isCreationInProgress: PropTypes.func,
    /**
     * Function to check if the server has expired
     */
    isExpired: PropTypes.func,
    /**
     * Function to check if the server has expiring
     */
    isExpiring: PropTypes.func,
    /**
     * Number which represents the timeout in seconds
     */
    lanTimeout: PropTypes.number,
    /**
     * List of licenses for each server
     */
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * String to represent cloud
         */
        cloud: PropTypes.string,
        /**
         * List of fi items
         */
        fulfillableItems: PropTypes.arrayOf(
          PropTypes.shape({
            /**
             *  Fi code
             */
            code: PropTypes.string,
            /**
             *  Fi item type
             */
            fulfillableItemType: PropTypes.string,
          })
        ),
        /**
         *  Function to get url of icon
         */
        getIconUrl: PropTypes.func,
        /**
         *  Function to get packageable items
         */
        getPackageableFulfillableItems: PropTypes.func,
        /**
         *  Function to get packageable product sapcode
         */
        getPackageableProductSapCodes: PropTypes.func,
        /**
         *  String to represent full name of the server
         */
        longName: PropTypes.string,
        /**
         *  String to represent offer id of the server
         */
        offerId: PropTypes.string,
        /**
         *  Boolean to represent selection of server
         */
        selected: PropTypes.bool,
      })
    ),
    /**
     *  String to represent name of the server
     */
    name: PropTypes.string,
    /**
     *  String to represent user's name
     */
    userName: PropTypes.string,
  }),
};

export default ServerDetails;
