import {Page} from '@admin-tribe/binky-ui';
import React from 'react';
import {useLoaderData} from 'react-router-dom';

import FiProductGroupProductList from 'core/services/product/product-group/product-list/FiProductGroupProductList';
import FigProductGroupProductList from 'core/services/product/product-group/product-list/FigProductGroupProductList';
import InstancesPage from 'features/products/pages/instances-page/InstancesPage';
import OrgConsumablesPage from 'features/products/pages/org-consumables-page/OrgConsumablesPage';

/**
 * @description Page manager for urls which start with /products/groups/:groupId.
 */
const ProductGroupsPageManager = () => {
  const {productGroup} = useLoaderData();
  if (productGroup instanceof FiProductGroupProductList) {
    return <OrgConsumablesPage productGroup={productGroup} />;
  }

  if (productGroup instanceof FigProductGroupProductList) {
    return <InstancesPage productGroup={productGroup} />;
  }

  return <Page isBumpered />;
};

export default ProductGroupsPageManager;
