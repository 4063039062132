import {DialogContainer} from '@adobe/react-spectrum';
import {TableActions, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import CreateDirectoryButton from 'features/settings/components/directories/create-directory-button/CreateDirectoryButton';
import DeleteDirectoriesModal from 'features/settings/components/directories/directory-list/directory-list-table/delete-directories-modal/DeleteDirectoriesModal';
import DeleteSyncedDirectoriesWarningModal from 'features/settings/components/directories/directory-list/directory-list-table/delete-directories-modal/DeleteSyncedDirectoriesWarningModal';
import DirectoryListActionMenu from 'features/settings/components/directories/directory-list/directory-list-table/directory-list-action-menu/DirectoryListActionMenu';

const DIALOGS = {
  DELETE_DIRECTORIES_MODAL: 'DELETE_DIRECTORIES_MODAL',
  DELETE_SYNCED_DIRECTORIES_WARNING_MODAL: 'DELETE_SYNCED_DIRECTORIES_WARNING_MODAL',
};

/**
 * Defines the actions for the directory table
 */
const DirectoryTableActions = ({
  directoryLinks,
  goToSetupDomains,
  onDeleteDirectoriesConfirm,
  rosterSyncs,
}) => {
  const {clearDirectoryCache} = useDirectoryList();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();

  const [selectedDirectories, setSelectedDirectories] = useState([]);
  const [selectedSyncedDirectories, setSelectedSyncedDirectories] = useState([]);
  const [dialog, setDialog] = useState(null);

  const canManageIdentityConfig = canChangeIdentityConfig();

  const handleDirectoriesDeleteConfirm = () => {
    tableSectionUtils.clearSelectedItemKeys();
    onDeleteDirectoriesConfirm();
  };

  const onDeleteDirectories = () => {
    const {items, selectedItemKeys} = tableSectionState;

    const selectedItems = items.filter((item) => selectedItemKeys.includes(item.id));

    const scimDirectories = selectedItems.filter((directory) =>
      directoryLinks.some((directoryLink) => directoryLink.imsId === directory.id)
    );

    const rosterSyncDirectories = selectedItems.filter((directory) =>
      rosterSyncs.some((rosterSync) => rosterSync.directoryId === directory.id)
    );

    const syncedDirectories = [...new Set([...scimDirectories, ...rosterSyncDirectories])];

    setSelectedDirectories(selectedItems);
    setSelectedSyncedDirectories(syncedDirectories);

    setDialog(
      syncedDirectories.length > 0
        ? DIALOGS.DELETE_SYNCED_DIRECTORIES_WARNING_MODAL
        : DIALOGS.DELETE_DIRECTORIES_MODAL
    );
  };

  return (
    <>
      <TableActions>
        <CreateDirectoryButton
          clearDirectoryCache={clearDirectoryCache}
          goToSetupDomains={goToSetupDomains}
        />
        <ButtonWithContextualHelp
          contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
          data-testid="delete-directories-button"
          isDisabled={tableSectionState.selectedItemKeys.length === 0 || !canManageIdentityConfig}
          onPress={onDeleteDirectories}
          variant="negative"
        >
          <FormattedMessage id="settings.directories.deleteDirectoriesButton.label" />
        </ButtonWithContextualHelp>
        <DirectoryListActionMenu />
      </TableActions>
      <DialogContainer onDismiss={() => setDialog(null)}>
        {dialog === DIALOGS.DELETE_DIRECTORIES_MODAL && (
          <DeleteDirectoriesModal
            directories={selectedDirectories}
            handleDirectoriesDeleteConfirm={handleDirectoriesDeleteConfirm}
          />
        )}
        {dialog === DIALOGS.DELETE_SYNCED_DIRECTORIES_WARNING_MODAL && (
          <DeleteSyncedDirectoriesWarningModal directories={selectedSyncedDirectories} />
        )}
      </DialogContainer>
    </>
  );
};

DirectoryTableActions.displayName = 'DirectoryTableActions';
DirectoryTableActions.propTypes = {
  /**
   * The active organization's directory links
   */
  directoryLinks: PropTypes.arrayOf(
    PropTypes.shape({
      imsId: PropTypes.string,
      imsOrganizationId: PropTypes.string,
      linkCreationTime: PropTypes.string,
      partnerType: PropTypes.string,
      scimToScimv2Migrated: PropTypes.bool,
      synchronizationType: PropTypes.string,
    })
  ),
  /**
   * Handler for redirect to the directory domains setup page
   */
  goToSetupDomains: PropTypes.func.isRequired,
  /**
   * Handler for clearing cache and data reload after directory deletion
   */
  onDeleteDirectoriesConfirm: PropTypes.func.isRequired,
  /**
   * The active organization's EDU roster syncs
   */
  rosterSyncs: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      syncConfigs: PropTypes.arrayOf(
        PropTypes.shape({
          directoryId: PropTypes.string,
          enabled: PropTypes.bool,
          status: PropTypes.string,
          syncId: PropTypes.string,
        })
      ),
    })
  ),
};

export default DirectoryTableActions;
