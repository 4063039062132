import {Flex, TextField} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useCreatePackageModalContext} from 'features/packages/components/create-package-modal/CreatePackageModalContext';
import {isPackageNameValid} from 'features/packages/stores/createPackageModalStoreUtils';

import SystemConfigPage from '../system-config/SystemConfigPage';

import ManagementOptions from './ManagementOptions';
import {shouldShowExtensionOptions, showManagementOptionsBlock} from './selfServicePageUtils';

const SelfServicePage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();
  const {
    packageCreateObj,
    pkgSessionSettings,
    setPackageCreateObjValues,
    setPkgSessionSettingValues,
    updateCreatePackageButtonStatus,
  } = createPackageModalStore;

  const [enableExtenstions, setEnableExtenstions] = useState(packageCreateObj.enableExtenstions);

  const [rumEnabled, setRumEnabled] = useState(packageCreateObj.rumEnabled);

  const [enableAalExtension, setEnableAalExtension] = useState(
    pkgSessionSettings.enableAalExtension
  );

  const [ausstOverride, setAusstOverride] = useState(packageCreateObj.ausstOverride);

  const [betaAppDownloadEnabled, setBetaAppDownloadEnabled] = useState(
    packageCreateObj.betaAppDownloadEnabled
  );

  // set disableAppTrial to undefined
  useEffect(() => {
    setPackageCreateObjValues('disableAppTrial', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run on initial render
  }, []);

  // update extensions options when extensions options are not to be shown
  useEffect(() => {
    if (!shouldShowExtensionOptions(packageCreateObj.platform)) {
      setEnableExtenstions(false);
      setPackageCreateObjValues('enableExtenstions', false);
      setEnableAalExtension(false);
      setPkgSessionSettingValues('enableAalExtension', false);
    }
  }, [packageCreateObj.platform, setPackageCreateObjValues, setPkgSessionSettingValues]);

  const packageNameChecks = (name) => {
    setPackageCreateObjValues('packageName', name);
    updateCreatePackageButtonStatus();
  };

  /**
   * Method to check if package name has error
   * returns {Boolean} true/false
   */
  const shouldShowPackageNameError = () => {
    if (!packageCreateObj.packageName?.trim()) {
      return false;
    }
    return !isPackageNameValid(packageCreateObj.packageName);
  };

  const updateExtensionsSetting = (selection) => {
    setEnableExtenstions(selection);
    setPackageCreateObjValues('enableExtenstions', selection);
    if (!selection) {
      setPkgSessionSettingValues('enableAalExtension', false);
    }
  };

  const updateRumEnabled = (selection) => {
    setRumEnabled(selection);
    setPackageCreateObjValues('rumEnabled', selection);
  };

  const updateEnableAalExtension = (selection) => {
    setEnableAalExtension(selection);
    setPkgSessionSettingValues('enableAalExtension', selection);
  };

  const updateAusstOverride = (selection) => {
    setAusstOverride(selection);
    setPackageCreateObjValues('ausstOverride', selection);
  };

  const updateBetaAppDownloadEnabled = (selection) => {
    setBetaAppDownloadEnabled(selection);
    setPackageCreateObjValues('betaAppDownloadEnabled', selection);
  };

  return (
    <>
      <Flex direction="row">
        <Flex alignItems="end" width="size-4600">
          <TextField
            autoFocus
            data-testid="package-name-input"
            description={intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.enterPackageName.description',
            })}
            errorMessage={
              shouldShowPackageNameError() &&
              intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageCreateObj.packageName?.trim().length > 40
                    ? 'pathTooLong'
                    : 'errorInPackageName'
                }`,
              })
            }
            label={intl.formatMessage({
              id: 'packages.createPackage.step3.packageName',
            })}
            maxLength={41}
            onChange={packageNameChecks}
            validationState={shouldShowPackageNameError() ? 'invalid' : ''}
            value={packageCreateObj.packageName}
            width="95%"
          />
        </Flex>
      </Flex>

      <SystemConfigPage />

      {showManagementOptionsBlock() && (
        <ManagementOptions
          ausstOverride={ausstOverride}
          betaAppDownloadEnabled={betaAppDownloadEnabled}
          data-testid="management-options"
          enableAalExtension={enableAalExtension}
          enableExtenstions={enableExtenstions}
          rumEnabled={rumEnabled}
          updateAusstOverride={() => updateAusstOverride(!ausstOverride)}
          updateBetaAppDownloadEnabled={() => updateBetaAppDownloadEnabled(!betaAppDownloadEnabled)}
          updateEnableAalExtension={() => updateEnableAalExtension(!enableAalExtension)}
          updateExtensionsSetting={() => updateExtensionsSetting(!enableExtenstions)}
          updateRumEnabled={() => updateRumEnabled(!rumEnabled)}
        />
      )}
    </>
  );
});

SelfServicePage.propTypes = {};

export default SelfServicePage;
