import {TextArea} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import debounce from 'lodash/debounce';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './DomainsTextArea.pcss';
import {parseDomains, validateDomains} from 'common/utils/domainNameUtils';

const DomainsTextArea = ({onChange = () => {}}) => {
  const [isTextValid, setIsTextValid] = useState(true);
  const intl = useIntl();
  const descriptionTextId = useId();

  const onValueChange = debounce((changedValue) => {
    const domains = parseDomains(changedValue);
    const areDomainsValid = validateDomains(domains);

    if (areDomainsValid) {
      onChange(domains);
    } else {
      onChange([]);
    }

    setIsTextValid(areDomainsValid);
  }, 400);

  return (
    <>
      <p>
        <FormattedMessage id="settings.directoryDomains.addDomainsByDnsForm.description" />
      </p>
      <TextArea
        aria-describedby={descriptionTextId}
        label={intl.formatMessage({id: 'settings.directoryDomains.addDomainsByDnsForm.label'})}
        onChange={(value) => onValueChange(value)}
        validationState={isTextValid ? null : 'invalid'}
        width="100%"
      />
      <p data-testid="help-text-label" styleName="help-text">
        example.com, example.org
      </p>
    </>
  );
};

DomainsTextArea.propTypes = {
  onChange: PropTypes.func,
};

export default observer(DomainsTextArea);
