import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';

import help from '../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> add products
 */
const teamAddProducts = () => ({
  access: isAllowedToAddProducts,
  content: [
    {
      links: [
        {
          key: 'help.topics.teamAddProducts.link.one.key',
          locator: 'add-products-modal',
          target: LINK_TARGET_TYPE.MODAL,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.teamAddProducts.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.TEAM_ADD_PRODUCTS,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.teamAddProducts.title',
});
export default teamAddProducts;
