import {InfoBar, InfoItem, OverlayWait} from '@admin-tribe/binky-ui';
import {SUPPORT_TICKET_RECORD_TYPE} from '@pandora/data-model-support-ticket';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const SupportHighOverview = ({
  closedCount = 0,
  isLoading = false,
  openCount = 0,
  recordType,
  totalCount = 0,
}) => {
  const intl = useIntl();
  const isSupportCase = recordType === SUPPORT_TICKET_RECORD_TYPE.CASE;
  const totalCountLabel = isSupportCase
    ? intl.formatMessage({id: 'support.supportHighOverview.supportCase.totalCases'})
    : intl.formatMessage({id: 'support.supportHighOverview.expertSession.totalSessions'});
  const openCountLabel = isSupportCase
    ? intl.formatMessage({id: 'support.supportHighOverview.supportCase.openCases'})
    : intl.formatMessage({id: 'support.supportHighOverview.expertSession.openSessions'});
  const closedCountLabel = isSupportCase
    ? intl.formatMessage({id: 'support.supportHighOverview.supportCase.closedCases'})
    : intl.formatMessage({id: 'support.supportHighOverview.expertSession.closedSessions'});
  const overviewItems = [
    {label: totalCountLabel, value: intl.formatNumber(totalCount)},
    {label: openCountLabel, value: intl.formatNumber(openCount)},
    {label: closedCountLabel, value: intl.formatNumber(closedCount)},
  ];

  return (
    <OverlayWait isLoading={isLoading}>
      <InfoBar>
        {overviewItems.map(({label, value}) => (
          <InfoItem key={label} label={label} value={value} />
        ))}
      </InfoBar>
    </OverlayWait>
  );
};

SupportHighOverview.propTypes = {
  closedCount: PropTypes.number,
  isLoading: PropTypes.bool,
  openCount: PropTypes.number,
  recordType: PropTypes.oneOf(Object.values(SUPPORT_TICKET_RECORD_TYPE)).isRequired,
  totalCount: PropTypes.number,
};

export default SupportHighOverview;
