import {throwLoaderNoAccessError} from 'core/router/loaders';
import {
  canViewAssetCredentials,
  canViewAssetMigration,
  canViewAssetSettings,
} from 'core/settings/access/settingsAccess';

/**
 * Loads access data for the asset settings page tabs
 */
const assetSettingsRoutesLoaders = async ({request}) => {
  if (!(await canViewAssetSettings())) {
    throwLoaderNoAccessError(request);
  }

  const [canViewAssetCredentialsResult, canViewAssetMigrationResult] = await Promise.all([
    canViewAssetCredentials(),
    canViewAssetMigration(),
  ]);

  return {
    canViewAssetCredentials: canViewAssetCredentialsResult,
    canViewAssetMigration: canViewAssetMigrationResult,
  };
};

export default assetSettingsRoutesLoaders;
