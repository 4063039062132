(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support::appExpertSessionDetailsDrawer
   *
   * @description displays information about an expert session which doesn't fit on the details page
   *
   * @binding {ExpertSession} expertSession - the expert session to display information for
   */
  angular.module('app.widgets.support').component('appExpertSessionDetailsDrawer', {
    bindings: {
      expertSession: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/expert-session-details-drawer/expert-session-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(_, EXPERT_SESSION_DETAILS_DRAWER_ID) {
    const vm = this;

    _.assign(vm, {
      EXPERT_SESSION_DETAILS_DRAWER_ID,
    });
  }
})();
