(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.account')
    .constant('CHANGE_CONTRACT_OWNER_MODAL_ID', 'change-contract-owner-modal')
    .constant('CONTRACT_OWNER_REQUIRES_ADOBE_ID', 'CONTRACT_OWNER_REQUIRES_ADOBE_ID')
    .constant('INVALID_COUNTRY_CODE', 'INVALID_COUNTRY_CODE')
    .constant('PRIMARY_ADMIN_T2E_NOT_ACTIVE', 'PRIMARY_ADMIN_T2E_NOT_ACTIVE')
    .constant(
      'REVOKE_CONTRACT_OWNER_INVITATION_MODAL_ID',
      'revoke-contract-owner-invitation-modal-id'
    );
})();
