import {AnalyticsEvent, LicenseGroupConfigurations, Product} from '@admin-tribe/binky';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AddProductProfileModal from 'features/products/product-profiles/add-product-profile-modal/AddProductProfileModal';
import {goToProductProfileDetails} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import CopyPermissionsModal from './CopyPermissionsModal';

const CopyPermissionsButton = ({licenseGroupConfigurations, orgId, product}) => {
  const intl = useIntl();
  const [isCopyPermissionsModalVisible, setCopyPermissionsModalVisible] = useState(false);
  const [isAddProductProfileModalVisible, setAddProductProfileModalVisible] = useState(false);

  const onCancelCopyPermissions = () => {
    setCopyPermissionsModalVisible(false);
  };

  const onContinueCopyPermissions = () => {
    setCopyPermissionsModalVisible(false);
    setAddProductProfileModalVisible(true);
  };

  const onSaveConfigurations = async (newLicenseGroup) => {
    const newConfigurations = licenseGroupConfigurations.configurations.map((configuration) => ({
      ...configuration,
      sections:
        'sections' in configuration
          ? // Strip read-only property when copying permissions
            configuration.sections.map((section) => ({...section, allReadOnly: undefined}))
          : [],
    }));
    const copiedConfigurations = new LicenseGroupConfigurations({
      configurations: newConfigurations,
      licenseGroupId: newLicenseGroup.id,
      orgId,
      productId: product.id,
      usesLicenseGroupConfiguration: product.hasConfigurationSettingForLicenseGroup(),
    });
    await copiedConfigurations.put();

    setAddProductProfileModalVisible(false);
    goToProductProfileDetails({
      productId: product.id,
      profileId: newLicenseGroup.id,
    });

    AnalyticsEvent.dispatch({
      attributes: {
        productOffer: product.offerId,
      },
      componentMethod: 'addProductProfile',
      componentMethodType: 'submit',
      componentName: 'appProductProfileAddModal',
    });
  };

  return (
    <>
      <Button
        data-test-id="copy-permissions-btn"
        onPress={() => setCopyPermissionsModalVisible(true)}
        variant="primary"
      >
        {intl.formatMessage({
          id: 'products.productProfilePermissions.copyPermissionsButton',
        })}
      </Button>

      <DialogContainer onDismiss={onCancelCopyPermissions}>
        {isCopyPermissionsModalVisible && (
          <CopyPermissionsModal onContinue={onContinueCopyPermissions} />
        )}
      </DialogContainer>

      <AddProductProfileModal
        isOpen={isAddProductProfileModalVisible}
        onCancel={() => {
          setAddProductProfileModalVisible(false);
        }}
        onSave={onSaveConfigurations}
        orgId={orgId}
        product={product}
      />
    </>
  );
};

CopyPermissionsButton.propTypes = {
  /**
   * The configurations based on the current license group (or product profile) associated with the AutoAssignmentRule.
   */
  licenseGroupConfigurations: PropTypes.instanceOf(LicenseGroupConfigurations).isRequired,
  /**
   * The orgId associated with the activeOrgId.
   */
  orgId: PropTypes.string.isRequired,
  /**
   * The product associated with the AutoAssignmentRule.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default CopyPermissionsButton;
