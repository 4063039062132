import {useRef} from 'react';
/**
 * @awaitingdeprecation - import useStore from binky-ui
 */
const useStore = (initFn) => {
  const storeRef = useRef(null);
  if (!storeRef.current) {
    storeRef.current = initFn();
  }

  return storeRef.current;
};

export default useStore;
