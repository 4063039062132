import {
  USER_GROUP_ADMIN_LIST_EVENT,
  USER_GROUP_USER_LIST_EVENT,
  eventBus,
} from '@admin-tribe/binky';
import {
  Avatar,
  Page,
  PageActions,
  PageBanners,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageInfoBar,
  UserGroupShareInfo,
} from '@admin-tribe/binky-ui';
import {Item} from '@adobe/react-spectrum';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {USER_GROUP_DETAILS_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {goToUserGroups} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import UserGroupDetailsPageActions from 'features/users/user-groups/user-group-details/UserGroupDetailsPageActions';
import UserGroupDetailsPageInfoBar from 'features/users/user-groups/user-group-details/UserGroupDetailsPageInfoBar';
import UserGroupDetailsPageTabs from 'features/users/user-groups/user-group-details/UserGroupDetailsPageTabs';

const USER_GROUPS_ITEM = 'user-groups';

/**
 * User group details page.
 */
const UserGroupDetailsPage = () => {
  const intl = useIntl();
  const {userGroup} = useLoaderData();

  useDocumentTitle({defaultTitle: userGroup.name});

  // User group page header - name and scorecards
  const [userGroupName, setUserGroupName] = useState(userGroup.name);
  const [adminCount, setAdminCount] = useState(userGroup.adminCount);
  const [userCount, setUserCount] = useState(userGroup.userCount);

  // Register for list events to update the scorecards.
  useEffect(() => {
    const callbackFn = (eventId, count) => {
      if (eventId === USER_GROUP_ADMIN_LIST_EVENT.UPDATE.COUNT) {
        setAdminCount(count);
      } else if (eventId === USER_GROUP_USER_LIST_EVENT.UPDATE.COUNT) {
        setUserCount(count);
      }
    };
    eventBus.registerEventHandler(callbackFn);

    return () => {
      eventBus.deregisterEventHandler(callbackFn);
    };
  }, []);

  const onActionBreadcrumb = useCallback((item) => {
    if (item === USER_GROUPS_ITEM) {
      goToUserGroups();
    }
  }, []);

  return (
    <Page data-testid="user-group-details-page" showContent>
      <PageBanners>
        <GlobalAdminPolicyBanner context={USER_GROUP_DETAILS_CONTEXT} />
      </PageBanners>

      <PageBreadcrumbs isDisabled={false} onAction={onActionBreadcrumb}>
        <Item key={USER_GROUPS_ITEM}>{intl.formatMessage({id: 'users.usersGroups.title'})}</Item>
        <Item key="name">{userGroupName}</Item>
      </PageBreadcrumbs>

      <PageHeader title={userGroupName}>
        <Avatar member={userGroup} />
        <UserGroupShareInfo member={userGroup} placement="end" />
      </PageHeader>

      <PageActions>
        <UserGroupDetailsPageActions setUserGroupName={setUserGroupName} />
      </PageActions>

      <PageInfoBar>
        <UserGroupDetailsPageInfoBar adminCount={adminCount} userCount={userCount} />
      </PageInfoBar>

      <PageContent>
        <UserGroupDetailsPageTabs />
      </PageContent>
    </Page>
  );
};

export default UserGroupDetailsPage;
