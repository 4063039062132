/* eslint-disable max-lines -- Job class has many mappings and methods */
import {User, eventBus} from '@admin-tribe/binky';
import {DATE_FORMATS} from '@admin-tribe/binky-ui';
import {EN_DASH as PLACEHOLDER_TEXT} from '@pandora/react-table-section';
import {isToday, isYesterday} from 'date-fns';

import jilJobs from 'common/api/jil/jilJobs';
import {
  OPERATIONS,
  OPERATION_DOWNLOADED_FILENAMES,
} from 'common/entities/user-operations/UserOperationsConstants';
import rootStore from 'core/RootStore';

import {JOB_EVENTS, JOB_OPERATION, JOB_STATUS} from './JobConstants';

const JOB_SOURCE = {
  ANVIL: 'jil-anvil',
  // eslint-disable-next-line unicorn/no-unused-properties -- found in the original jobs model, just shows other possibilities
  JEM: 'jem-contract-migrations',
};

const DEFAULT_DISPLAY_NAME = 'common.status.unknown';

const OPERATION_TO_DISPLAY_NAME_MAP = {
  [JOB_OPERATION.ADD_ENTERPRISE_USERS]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_ENTERPRISE_USERS_PLC]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_ENTERPRISE_USERS_UG]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_ORGANIZATION_USERS]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_ORGANIZATION_USERS_LG]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_ORGANIZATION_USERS_UG]: 'services.job.operation.addUsers',
  [JOB_OPERATION.ADD_USER_GROUPS]: 'services.job.operation.addUserGroups',
  [JOB_OPERATION.ASSIGN_LICENSES]: 'services.job.operation.assignLicenses',
  [JOB_OPERATION.EDIT_ENTERPRISE_USERS]: 'services.job.operation.editUserDetails',
  [JOB_OPERATION.EXPORT_USERS_CSV]: 'services.job.operation.exportUsersCsv',
  [JOB_OPERATION.EDIT_ORGANIZATION_USER_GROUPS]: 'services.job.operation.editUserGroups',
  [JOB_OPERATION.EDIT_ORGANIZATION_USERS]: 'services.job.operation.editUserDetails',
  [JOB_OPERATION.OFFER_MIGRATION]: 'services.job.operation.offerMigration',
  [JOB_OPERATION.REMOVE_ENTERPRISE_USERS]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_DU]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_PLC]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ENTERPRISE_USERS_UG]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ORGANIZATION_USERS]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ORGANIZATION_USERS_LG]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REMOVE_ORGANIZATION_USERS_UG]: 'services.job.operation.removeUsers',
  [JOB_OPERATION.REVOKE_ENTERPRISE_INVITES]: 'services.job.operation.revokeInvitations',
  [JOB_OPERATION.SWITCH_ENTERPRISE_USERS]: 'services.job.operation.editIdentityType',
  [JOB_OPERATION.SWITCH_ORGANIZATION_USERS]: 'services.job.operation.editIdentityType',
};

const STATUS_TO_DISPLAY_NAME_MAP = {
  [JOB_STATUS.CANCELED]: 'common.status.canceled',
  [JOB_STATUS.COMPLETED]: 'common.status.completed',
  [JOB_STATUS.FAILED]: 'common.status.failed',
  [JOB_STATUS.PROCESSING]: 'common.status.processing',
  [JOB_STATUS.QUEUED]: 'services.job.status.queued',
  [JOB_STATUS.REJECTED]: 'services.job.status.rejected',
  [JOB_STATUS.UNKNOWN]: 'common.status.unknown',
  [JOB_STATUS.UNKNOWN_JOB_TYPE]: 'services.job.status.rejected',
};

const TERMINATED_JOB_STATUSES = [
  JOB_STATUS.CANCELED,
  JOB_STATUS.COMPLETED,
  JOB_STATUS.FAILED,
  JOB_STATUS.REJECTED,
  JOB_STATUS.UNKNOWN,
  JOB_STATUS.UNKNOWN_JOB_TYPE,
];

// Other export jobs will be later added ie: Export user groups CSV
const EXPORT_JOBS = [JOB_OPERATION.EXPORT_USERS_CSV];

class Job {
  /**
   * @description Creates and returns a Job instance from the specified JIL
   *   job JSON
   * @param {Object} rawJobJson - the JIL job JSON
   * @returns {Job} the created Job
   */
  static apiResponseTransformer(rawJobJson) {
    return new Job(rawJobJson);
  }

  /**
   * @description Fetches the Job with the specified ID.
   * @param {String} id - id of the job to fetch
   * @returns {Job} reference to fetched Job
   */
  static async get({id}) {
    const model = new Job({id});
    await model.refresh();
    return model;
  }

  /**
   * @description Simple constructor for new Job objects.
   * @param {Object} options - key/value options for Job construction
   * @param {Boolean} options.cancelRequested - Whether or not that there was a request to cancel the job
   * @param {String} options.id - The unique id of Job
   * @param {String} inputFileName - The file name used to process the bulk job
   * @param {Object} itemSummary - The statistics of the job
   * @param {Number} itemSummary.avgDuration - The average duration of each item
   * @param {Number} itemSummary.failureCount - The number of failed items
   * @param {Number} itemSummary.totalCount - The total number of items including pending items
   * @param {Number} itemSummary.successCount - The number of items successfully processed
   * @param {Number} itemSummary.processedCount - The number of items that have been processed
   * @param {String} operation - The operation of the Job
   * @param {Object} startedBy - The identity of user who started the job
   * @param {String} startedBy.firstName - firt name of the user who started the job
   * @param {String} startedBy.id - The id of the user who started the job
   * @param {String} startedBy.lastName - The last name of the user that started the job
   * @param {String} startedBy.userName - The user name of the user that stated the job
   * @param {JOB_STATUS} status - The status of the job. One of CANCELLED, COMPLETED, FAILED, PROCESSING, QUEUED, REJECTED, UNKNOWN, UNKNOWN_JOB_TYPE
   * @param {BULK_OPERATION_UPLOAD_ERRORS} statusReason - The reason code for the job failure. Some examples include CSV_FORMAT_USER_TYPE, CSV_READ_FAILED, and INVALID_CSV_DATA
   * @param {Date} timeFinished - The time the Job finished
   * @param {Date} timeStart - The time the Job started
   * @param {Date} timeSubmitted - The time the Job was submitted
   */
  constructor({
    cancelRequested,
    id,
    inputFileName,
    itemSummary,
    operation,
    startedBy,
    status,
    statusReason,
    timeFinished,
    timeStarted,
    timeSubmitted,
  }) {
    Object.assign(this, {
      cancelRequested,
      id,
      inputFileName,
      itemSummary,
      operation,
      startedBy,
      status,
      statusReason,
      timeFinished,
      timeStarted,
      timeSubmitted,
    });
  }

  /**
   * @description Returns true if the job has terminated and if its is an
   *   anvil job.
   * @returns {boolean} true if this job can be deleted
   */
  canBeDeleted() {
    return this.hasTerminated() && this.isAnvilJob();
  }

  /**
   * @description Cancels this job by calling the JIL update job API. If job
   *   canceled successfully, then a refresh of this Job is performed. The
   *   Promise returned by this call is resolved when the refresh completes,
   *   otherwise, if any step fails, the Promise is rejected.
   * @returns {Promise} the promise for the JIL request
   */
  async cancel() {
    const operations = [
      {
        op: 'replace',
        path: `/${this.id}/cancelRequested`,
        value: true,
      },
    ];
    await jilJobs.patchJobs({ops: operations, orgId: rootStore.organizationStore.activeOrgId});

    eventBus.emit(JOB_EVENTS.UPDATE_JOB, this.id);

    return this.refresh();
  }

  /**
   * @description Returns true if this job's license deficit summary can be
   *   displayed. The summary can be displayed if the job has ceased executing
   *   it has a license deficit summary.
   * @returns {boolean} true if this job's license deficit summary can be
   *   displayed
   */
  canShowLicenseDeficitSummary() {
    return this.hasTerminated() && this.getLicenseDeficitSummary().length > 0;
  }

  /**
   * @description Returns true if this job's results can be displayed. Results
   *   can be displayed if the job has ceased executing and any of its items
   *   were actually processed.
   * @returns {boolean} true if this job's results can be displayed
   */
  canShowResults() {
    return this.hasTerminated() && this.itemSummary?.processedCount > 0;
  }

  /**
   * @description Returns the filename to display. For input jobs it is the inputFileName
   * and for export jobs it is the results filename.
   * @returns {String} the filename of this job's file
   */
  getFilename() {
    if (this.operation === JOB_OPERATION.EXPORT_USERS_CSV) {
      return OPERATION_DOWNLOADED_FILENAMES[OPERATIONS.EXPORT_USERS];
    }
    return this.inputFileName;
  }

  /**
   * @description Method to obtain the full name of the User who started
   *   this Job.
   * @returns {User} the User who started/initiated this Job.
   */
  getInitiatedBy() {
    return new User(this.startedBy);
  }

  /**
   * @description Returns the output filename to label the exported license
   *   deficit report.
   * @returns {String} the filename of the license deficit report.
   */
  getLicenseDeficitReportFilename() {
    return createFilename('license-deficit.csv', this.inputFileName);
  }

  /**
   * @description Returns this job's license deficit summary, conveniently
   *   returning an empty Array if the job does not have any license deficits.
   * @returns {Array} this job's license deficit summary
   */
  getLicenseDeficitSummary() {
    const summary = this.itemSummary?.licenseDeficitSummary;
    return Array.isArray(summary) ? summary : [];
  }

  /**
   * @description Returns the translate key for this job's operation name
   *   (e.g. 'jobs.operation.addUsers' for the Add Users operation) or
   *   'jobs.operation.unknown' if the operation is not recognised.
   * @returns {string} the translate key for this job's operation name
   */
  getOperationDisplayName() {
    return OPERATION_TO_DISPLAY_NAME_MAP[this.operation] ?? DEFAULT_DISPLAY_NAME;
  }

  /**
   * @description Method to obtain the heading and last breadcrumb name for
   *   this Job for use on the Job Results (line-by-line) view of the
   *   application.
   * @param {String} intl - the internationalization object for translatind strings
   * @returns {String} localized String of heading/last breadcrumb name
   */
  getResultHeading(intl) {
    const translatedDisplayName = intl.formatMessage({id: this.getOperationDisplayName()});
    const subheading =
      this.inputFileName?.trim().length > 0
        ? this.inputFileName
        : intl.formatMessage({id: 'services.job.results.title'});

    return `${translatedDisplayName} - ${subheading}`;
  }

  /**
   * @description Returns the filename to use when downloading this job's
   *   results file.
   * @returns {String} the filename of this job's results file
   */
  getResultsFileName() {
    if (this.operation === JOB_OPERATION.ASSIGN_LICENSES) {
      return `license-assignment-results-${rootStore.organizationStore.activeOrg.name}.csv`;
    }
    return createFilename('results.csv', this.inputFileName);
  }

  /**
   * @description Method to return the started date that should be displayed
   *   in the UI. If the job was started on the same day, then "today" is
   *   returned. If the job was started on the previous day, then "yesterday"
   *   is returned. Otherwise, the formatted date String is returned.
   *   Note: All values are translated prior to returning.
   * @param {String} intl - the internationalization object for translatind strings and formatting dates
   * @returns {String} the translated started date that should be shown
   */
  getStarted(intl) {
    const {timeStarted} = this;
    if (timeStarted) {
      if (isToday(timeStarted)) {
        return intl.formatMessage({id: 'common.date.today'});
      }
      if (isYesterday(timeStarted)) {
        return intl.formatMessage({id: 'common.date.yesterday'});
      }

      return intl.formatDate(timeStarted, DATE_FORMATS.default);
    }

    return PLACEHOLDER_TEXT;
  }

  /**
   * @description Returns the translate key for this job's status (e.g.
   *   'jobs.status.completed' if the job is completed) or 'jobs.status.unknown'
   *   if the status is not recognised.
   * @returns {String} the translate key for this job's status
   */
  getStatusDisplayName() {
    const statusDisplayName = this.isCanceling()
      ? 'common.status.canceling'
      : STATUS_TO_DISPLAY_NAME_MAP[this.status];

    return statusDisplayName || DEFAULT_DISPLAY_NAME;
  }

  /**
   * @description Returns the time remaining for the job to complete in
   *   milliseconds
   * @returns {Integer} time remaining for the job to finish or null if one of
   *   the dependent variables is not defined.
   */
  getTimeRemaining() {
    const totalCount = this.itemSummary?.totalCount;
    const processedCount = this.itemSummary?.processedCount;
    const avgDuration = this.itemSummary?.avgDurationMs;

    if (totalCount && processedCount && avgDuration) {
      return (totalCount - processedCount) * avgDuration;
    }

    return null;
  }

  /**
   * @description Returns true if this job is not executing or queued for
   *   execution. For example the job may have completed, been cancelled, or
   *   failed to execute in the first place due to some system error.
   * @returns {boolean} true if this job is not executing or queued for
   *   execution
   */
  hasTerminated() {
    return TERMINATED_JOB_STATUSES.includes(this.status);
  }

  /**
   * @description Returns true if job was completed, but contains errors.
   *   Since the 'status' in this case is still COMPLETED, we need a way to
   *   encapsulate checking to see if there were any errors encountered
   *   while attempting to process the bulk operation.
   * @returns {Boolean} true if job completed with errors, else false
   */
  hasTerminatedWithErrors() {
    return this.hasTerminated() && this.itemSummary?.failureCount > 0;
  }

  /**
   * @description Returns true if this is a JIL anvil job, i.e. it is sourced
   *   from JIL rather than JEM.
   * @returns {Boolean} true if this is a JIL anvil job
   */
  isAnvilJob() {
    return isSourcedFrom(JOB_SOURCE.ANVIL, this.id);
  }

  /**
   * @description Returns true if this job is cancelable, i.e. it is sourced
   *   from JIL, in progress and not already requested to be canceled.
   * @returns {Boolean} true if this job is cancelable
   */
  isCancelable() {
    return this.isAnvilJob() && !this.hasTerminated() && !this.isCanceling();
  }

  /**
   * @description Returns true if this job is in the process of being canceled.
   *   Once the job has terminated this method will return false.
   * @returns {Boolean} true if this job is in the process of being canceled.
   */
  isCanceling() {
    return this.cancelRequested && !this.hasTerminated();
  }

  /**
   * @description Returns true if this job has been successfully completed.
   * @returns {Boolean} true if this job is successfully completed.
   */
  isCompleted() {
    return this.status === JOB_STATUS.COMPLETED;
  }

  /**
   * @description Returns true if this job is an export job,
   * meaning it is creating a CSV that the user has requested. ie: org users.
   * @returns {Boolean} true if this job is an export job.
   */
  isExportJob() {
    return EXPORT_JOBS.includes(this.operation);
  }

  /**
   * @description Method to refresh an existing Job with latest data from
   *   back-end data store.
   * @returns {Promise} Resolves with refreshed model if successful, else
   *   rejects with error message
   */
  async refresh() {
    // if this fails, just throw the error
    const response = await jilJobs.getJob({
      jobId: this.id,
      orgId: rootStore.organizationStore.activeOrgId,
    });
    Object.assign(this, response.data);

    return this;
  }
}

/**
 * @description Returns a filename that combines the original input file
 *   name as a prefix and a default file name as a suffix, or just the
 *   default name if no input file name is available.
 * @param {String} defaultFilename - the default file name suffix to use
 * @param {String} [inputFileName] - the input file name to use
 * @returns {String} the filename for the file to be exported.
 */
function createFilename(defaultFilename, inputFileName) {
  let filename;
  if (inputFileName) {
    const filenamePrefix = inputFileName.replace(/\.csv$/i, '');
    if (filenamePrefix) {
      filename = `${filenamePrefix}-${defaultFilename}`;
    }
  }
  return filename || defaultFilename;
}

/**
 * @description Returns true if this job is from the specified source. A
 *   job's source is indicated by the suffix of its ID; for example a job
 *   with ID 1234@jil-anvil is from the JIL anvil system. ThejobSource
 *   parameter should be one of the JOB_SOURCE constant values.
 * @param {String} jobSource - the job source to check for
 * @param {String} id - the job id to check if sourced from
 * @returns {Boolean} true if the job is from the specified source
 */
function isSourcedFrom(jobSource, id) {
  return id.endsWith(`@${jobSource}`);
}

export default Job;
/* eslint-enable max-lines -- Job class has many mappings and methods */
