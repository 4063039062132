(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.core.directories:DirectoryTrust
   * @description model for a trust under a specific directory.
   */
  angular.module('app.core.directories').factory('DirectoryTrust', getDirectoryTrustModel);

  /* @ngInject */
  function getDirectoryTrustModel($log, _, Trust) {
    class DirectoryTrust {
      /**
       * @description instantiate a DirectoryTrust object.
       * @param {Object} options - options to config the new DirectoryTrust.
       * @param {String} options.directoryId - the id of the directory.
       * @param {Boolean} options.notifyAdmins - If set true, the system admin of the owning org will be notified when new users are added to the trustee org.
       * @param {String} options.trusteeOrgName - the name of the trustee org.
       * @param {String} options.trusteeOrgSysAdminEmail - the email of the trustee org admin who sent out the trust request.
       */
      constructor(options) {
        applyResource(this, options);
        // Since directoryId may not be unique, construct a unique id.
        this.id = `${options.directoryId}-${options.trusteeOrgId}`;

        // Work around until https://git.corp.adobe.com/admin-tribe/onesie/issues/4683 is resolved.
        // Use the same default as JIL: /jil/organizations/services/DirectoriesService.java#L81
        this.directoryName = this.directoryName || `${this.directoryId} directory`;
      }

      /**
       * @description calls the PATCH API with the `replace` operation and
       * the `NOTIFICATIONS` path on the directory
       * @returns {Promise} promise
       */
      saveNotificationsValue() {
        const patchPayload = [
          {
            op: 'replace',
            path: `/${this.trusteeOrgId}/${this.directoryId}/NOTIFICATIONS/${this.notifyAdmins}`,
          },
        ];

        return Trust.performBatchOperation({
          errorCallback: onError,
          patchPayload,
        });

        function onError(err) {
          $log.error('DirectoryTrust failed to update notifications value. Error: ', err);
        }
      }

      /**
       * @description generate model subset containing only API relevant fields.
       * @returns {Object} API relevant subset of DirectoryTrust object.
       */
      toMinimumModel() {
        return toMinimumObject(this);
      }

      static apiResponseTransformer(item) {
        return new DirectoryTrust(item);
      }
    }
    return DirectoryTrust;

    //////////////

    function applyResource(trust, resource) {
      _.assignIn(trust, toMinimumObject(resource));
      return trust;
    }

    function toMinimumObject(object) {
      return _.pick(object, [
        'directoryId',
        'directoryName',
        'notifyAdmins',
        'trusteeOrgId',
        'trusteeOrgName',
        'trusteeOrgSysAdminEmail',
      ]);
    }
  }
})();
