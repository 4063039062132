(function () {
  /**
   * @deprecated with temp_react_port_jobs. Use src2/app/common/services/job-result-list/JobResultList.js
   *
   * @ngdoc factory
   * @name JobResult
   * @description Model for the result of a job. The JobResult's items property
   *   is an array of  JobResultItem instances. Each JobResultItem represents
   *   the outcome of an input item to the job, for example the result of
   *   processing a CSV row.
   */
  angular.module('app.core.jobs').factory('JobResult', jobResult);

  /* @ngInject */
  function jobResult(
    $q,
    _,
    downloadUtils,
    jilJobs,
    JOB_RESULT_ITEM_STATUS,
    JobResultItem,
    List,
    PAGE_TARGET,
    PAGE_TARGET_TYPE
  ) {
    class JobResult extends List {
      /**
       * @description Method to create a new instance of a JobResult.
       * @param {String} jobResultId - ID of JobResult to fetch from back-end
       */
      constructor(jobResultId) {
        super();
        this.id = jobResultId;
        this.$resolved = false;
      }

      /**
       * @description Method to obtain a list of JobResultItem instances in this
       *   JobResult that had a failing status.
       * @returns {Array} list of failing JobResultItem instances
       */
      getFailedResultItems() {
        return _.filter(this.items, {status: JOB_RESULT_ITEM_STATUS.FAILURE});
      }

      /**
       * @description Refreshes this JobResult instance by fetching it from the
       *   back-end.
       * @return {Promise} a promise which resolves with the refreshed JobResult
       *   model, or rejects with an error message
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          jilJobs.jobResult.get({jobId: this.id}, onSuccess.bind(this), onError.bind(this));

          ////////

          function onSuccess(jobResultJson) {
            this.items = _.map(jobResultJson.items, JobResultItem.apiResponseTransformer);
            if (this.items) {
              this.pagination.itemCount = this.items.length;
            }
            this.$resolved = true;
            resolve(this);
          }

          function onError(error) {
            this.$resolved = true;
            reject(error);
          }
        });

        return this.$promise;
      }

      /**
       * @description Downloads the job result as a CSV where each row
       *   corresponds to each row of the input.
       * @param {String} jobResultId - the ID of the job whose results should be
       *   exported
       * @param {PageContext} pageContext - context of page requesting export
       * @return {Promise} a promise which resolves to the filename when the
       *   download is complete, or rejects if the download failed
       */
      static export(jobResultId, pageContext) {
        return $q((resolve, reject) => {
          const {filename, resource} = constructExportOptions();
          resource.export({jobId: jobResultId}, onFetchCSVSuccess, reject);

          ////////

          function onFetchCSVSuccess(response) {
            downloadUtils.downloadExportData(response.file, filename);
            resolve(filename);
          }
        });

        ////////

        function constructExportOptions() {
          const options = {
            filename: 'users-results.csv',
            resource: jilJobs.jobResult,
          };

          switch (pageContext.target) {
            case PAGE_TARGET.PRODUCT_CONFIGURATION:
              switch (pageContext.targetType) {
                case PAGE_TARGET_TYPE.USER: {
                  options.filename = 'profile-users-results.csv';
                  break;
                }
                default:
                  break;
              }
              break;
            case PAGE_TARGET.USER_GROUP:
              switch (pageContext.targetType) {
                case PAGE_TARGET_TYPE.USER: {
                  options.filename = 'user-group-users-results.csv';
                  break;
                }
                default:
                  break;
              }
              break;
            case PAGE_TARGET.ORGANIZATION:
              switch (pageContext.targetType) {
                case PAGE_TARGET_TYPE.USER: {
                  options.filename = 'org-users-results.csv';
                  options.resource = jilJobs.jobResult;
                  break;
                }
                default:
                  options.resource = jilJobs.jobResult;
                  break;
              }
              break;
            default:
              break;
          }

          return options;
        }
      }

      /**
       * @description Returns a new JobResult representing the result of the
       *   specified job. The JobResult will populate when the back-end call
       *   completes.
       * @param {String} jobResultId - id of job result to fetch
       * @returns {JobResult} a JobResult representing the result of the specified
       *   job
       */
      static get(jobResultId) {
        const model = new JobResult(jobResultId);
        model.refresh();
        return model;
      }
    }

    return JobResult;
  }
})();
