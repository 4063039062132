import DomainEntity from 'common/entities/DomainEntity';

class DomainListModel {
  static fromObject(data) {
    return new DomainListModel(data);
  }

  constructor(domainListData) {
    if (domainListData) {
      this.items = domainListData.data.map((domain) => new DomainEntity(domain));

      this.numberOfDomains = domainListData.totalCount;
      this.totalPages = Math.ceil(this.numberOfDomains / domainListData.pageSize);
    } else {
      // for the initial state domainListData is null
      this.items = [];

      this.numberOfDomains = 0;
      this.totalPages = 1;
    }
  }
}

export default DomainListModel;
