import {OrganizationUser} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import rootStore from 'core/RootStore';
import {canEditProducts} from 'core/products/access/productAccess';
import {canEditUserGroup} from 'core/user-group/access/userGroupAccess';
import AddAdminModal from 'features/users/components/add-admin-modal/AddAdminModal';
import EditDeveloperAccessModal from 'features/users/components/edit-developer-access-modal/EditDeveloperAccessModal';
import EditProductsAndUserGroupsModal from 'features/users/components/edit-products-and-user-groups-modal/EditProductsAndUserGroupsModal';
import EditUserDetailsModal from 'features/users/edit-user-details-modal/EditUserDetailsModal';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

/**
 * The Modals wrapper component shared between page and drawer.
 */
const UserDetailsModalsWrapper = ({member, manageableRoles, openedModal, onCancel, onSuccess}) => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const activeOrgProducts = rootStore.organizationStore.productList;

  const canMemberEditProducts =
    canEditProducts() &&
    !member.getType().isTechnicalAccount() &&
    activeOrgProducts.hasUserDelegatableProduct();

  const canEditUserGroups =
    canEditUserGroup() && !member.getType().isTechnicalAccount() && !member.externallyManaged;

  return (
    <>
      {openedModal === USER_DETAILS_MODAL_NAMES.EDIT_USER_PROFILE && (
        <EditUserDetailsModal
          isOpen
          onCancel={onCancel}
          onSuccess={onSuccess}
          orgId={orgId}
          user={member}
        />
      )}
      {openedModal === USER_DETAILS_MODAL_NAMES.EDIT_PRODUCTS_AND_GROUPS && (
        <EditProductsAndUserGroupsModal
          canEditProducts={canMemberEditProducts}
          canEditUserGroups={canEditUserGroups}
          isOpen
          onCancel={onCancel}
          onSuccess={onSuccess}
          orgId={orgId}
          user={new OrganizationUser({...member})}
        />
      )}
      {openedModal === USER_DETAILS_MODAL_NAMES.EDIT_DEVELOPER_PRODUCTS && (
        <EditDeveloperAccessModal
          isOpen
          onCancel={onCancel}
          onSuccess={onSuccess}
          orgId={orgId}
          user={new OrganizationUser({...member})}
        />
      )}
      {openedModal === USER_DETAILS_MODAL_NAMES.EDIT_ADMIN_RIGHTS && (
        <AddAdminModal
          isOpen
          onCancel={onCancel}
          onSuccess={onSuccess}
          orgId={orgId}
          roles={manageableRoles}
          userToEdit={member}
        />
      )}
    </>
  );
};

UserDetailsModalsWrapper.propTypes = {
  /**
   *  Admin roles that the current user can manage.
   */
  manageableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The user or admin.
   */
  member: PropTypes.instanceOf(OrganizationUser).isRequired,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * The currently opened modal.
   */
  openedModal: PropTypes.string,
};

export default UserDetailsModalsWrapper;
