import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import PackagesServerStore from '../../stores/PackagesServerStore';

/**
 * Private Context for the TemplatesPage and its sub-components.
 * Used to pass the packagesAdobeTemplatesStore between sub-components.
 */

const ServerPageContext = createContext(null);
const useServerPageContext = () => useContext(ServerPageContext);

const ServerPageContextProvider = ({children, packagesServerStore}) => {
  const value = useMemo(() => ({packagesServerStore}), [packagesServerStore]);

  return <ServerPageContext.Provider value={value}>{children}</ServerPageContext.Provider>;
};

ServerPageContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
  /**
   * The domain store for Packages Adobe Templates.
   */
  packagesServerStore: PropTypes.instanceOf(PackagesServerStore).isRequired,
};

export {ServerPageContextProvider, useServerPageContext};
