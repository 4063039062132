(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.core.payment-instrument:PaymentInstrument
   * @description model to get Payment Instruments from BIS
   *
   * https://wiki.corp.adobe.com/display/BPS/Billing+Instruments+Service+%28BIS%29+Home
   */
  angular
    .module('app.core.payment-instrument')
    .factory('PaymentInstrument', getPaymentInstrumentModel);

  /* @ngInject */
  function getPaymentInstrumentModel(
    _,
    bis,
    jsUtils,
    MODEL,
    modelCache,
    PAYMENT_INFO_EDITOR_EVENT
  ) {
    class PaymentInstrument {
      /**
       * @description creates a new PaymentInstrument object
       * @param {Object} options options to configure new PaymentInstrument
       * @param {String} options.contractId contract id
       */
      constructor(options) {
        this.contractId = options.contractId;
      }

      /**
       * @description Method to return the current unique key for this PaymentInstrument.
       *    It will be used for caching the PaymentInstrument.
       * @returns {String} key to uniquely identify this payment instrument
       */
      key() {
        return this.contractId;
      }

      /**
       * @description method to fetch Payment Instrument data from BIS.
       * @returns {Promise} resolved with the PaymentInstrument instance populated with BIS data
       */
      refresh() {
        this.$resolved = false;
        this.$promise = bis.paymentInstruments
          .query({
            'contract-id': this.contractId,
          })
          .$promise.then((response) => {
            _.assign(this, transformResponse(response));
            modelCache.put(MODEL.PAYMENTINSTRUMENT, this, this.key());
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });

        return this.$promise;
      }

      /**
       * @description Method to fetch the Payment Instrument from BIS given a contractId
       * @param {Object} options - options to configure new PaymentInstrument
       * @param {String} options.contractId - contract id
       * @param {Boolean} [options.useCache] Whether cache should be used. Default is true.
       * @returns {PaymentInstrument} PaymentInstrument model
       */
      static get(options) {
        const {contractId, useCache = true} = options;

        let model = useCache && modelCache.get(MODEL.PAYMENTINSTRUMENT, contractId);

        if (!model) {
          model = new PaymentInstrument({contractId});
          model.refresh();
        }

        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.PAYMENTINSTRUMENT, 10);

    // We remove all cached instances when PIE updates the Payment Instrument
    modelCache.removeAllOnEvent(MODEL.PAYMENTINSTRUMENT, [PAYMENT_INFO_EDITOR_EVENT.SAVED]);

    return PaymentInstrument;

    //////////////

    /**
     * @description Method to transform the Payment Instruments response from BIS into property names.
     * @param {Object} response object with Payment Instrument data from BIS
     * @returns {Object} object with Payment Instrument data transformed as model properties
     */
    function transformResponse(response) {
      const transformed = jsUtils.convertObjectKeysToCamelCase(response[0]);
      transformed.billingAddress = jsUtils.convertObjectKeysToCamelCase(transformed.billingAddress);
      transformed.billingAddress.contact = jsUtils.convertObjectKeysToCamelCase(
        transformed.billingAddress.contact
      );
      return transformed;
    }
  }
})();
