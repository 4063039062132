(function () {
  /**
   * @deprecated moved to src2 as <SupportCaseInfoBar>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportCaseInfoBar
   * @description renders basic information about a support case in an info-bar
   */
  angular.module('app.widgets.support').component('appSupportCaseInfoBar', {
    bindings: {
      supportCase: '<',
    },
    controller,
    templateUrl: 'app/widgets/support/support-case-info-bar/support-case-info-bar.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      showLastUpdated,
    });

    function showLastUpdated() {
      // For migrated cases, hide the last updated field since the date indicates when the case was
      // migrated rather than when it was actually last updated by an agent or customer
      return !vm.supportCase.isMigrated && vm.supportCase.hasBeenUpdatedSinceCreation();
    }
  }
})();
