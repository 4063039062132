import {canViewIntegrations} from 'core/products/access/productAccess';
import {PATH_DEVELOPERS} from 'features/users/routing/usersPaths';

import help from '../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for product tab -> manage api products
 */
const manageApiProducts = () => ({
  access: canViewIntegrations,
  content: [
    {
      links: [
        {
          key: 'help.topics.manageApiProducts.link.two.key',
          locator: PATH_DEVELOPERS,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
        {
          key: 'help.topics.manageApiProducts.link.one.key',
          locator: 'aac_api_prod_learn',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.manageApiProducts.text2',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.MANAGE_API_PRODUCTS,
  parents: [
    {
      id: TOPICS.PRODUCTS,
    },
  ],
  title: 'help.topics.manageApiProducts.title',
});
export default manageApiProducts;
