import help from '../../constants/help';
import {hasEvipLicenceMigrationAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> evip license migration
 */
const evipLicenseMigration = () => ({
  access: hasEvipLicenceMigrationAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.evipLicenseMigration.link.one.key',
          locator: 'aac_migrate_to_enterprise',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.evipLicenseMigration.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.EVIP_LICENSE_MIGRATION,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.evipLicenseMigration.title',
});
export default evipLicenseMigration;
