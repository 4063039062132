(function () {
  /**
   * @deprecated use src2 Support pages
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.support')
    // Source: Countries with intermediate-region 'Central America' or 'South America' in
    // https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv
    .constant('SUPPORT_LATAM_COUNTRIES', [
      'AR', // Argentina
      'BO', // Bolivia
      'BR', // Brazil
      'BV', // Bouvet Island
      'BZ', // Belize
      'CL', // Chile
      'CO', // Colombia
      'CR', // Costa Rica
      'EC', // Ecuador
      'FK', // Falkland Islands
      'GF', // French Guiana
      'GS', // South Georgia and the South Sandwich Islands
      'GT', // Guatemala
      'GY', // Guyana
      'HN', // Honduras
      'MX', // Mexico
      'NI', // Nicaragua
      'PA', // Panama
      'PE', // Peru
      'PY', // Paraguay
      'SR', // Suriname
      'SV', // El Salvador
      'UY', // Uruguay
      'VE', // Venezuela
    ])
    // Countries in the Europe, Middle East, & Africa region. Note this is not a complete list; it
    // is primarily derived from the countries that were shown the EMEA phone number for Enterprise in the legacy
    // support UI.
    .constant('SUPPORT_EMEA_COUNTRIES', [
      'AE', // United Arab Emirates
      'AT', // Austria
      'BG', // Bulgaria
      'BH', // Bahrain
      'CH', // Switzerland
      'CY', // Cyprus
      'CZ', // Czech Republic
      'DE', // Germany
      'DK', // Denmark
      'DZ', // Algeria
      'EE', // Estonia
      'EG', // Egypt
      'ES', // Spain
      'FI', // Finland
      'FR', // France
      'GB', // Great Britain
      'HR', // Croatia
      'HU', // Hungary
      'IE', // Ireland
      'IL', // Israel
      'IT', // Italy
      'JO', // Jordan
      'KW', // Kuwait
      'LB', // Lebanon
      'LT', // Lithuania
      'LU', // Luxembourg
      'LV', // Latvia
      'MA', // Morocco
      'MT', // Malta
      'NL', // Netherlands
      'NO', // Norway
      'OM', // Oman
      'PL', // Poland
      'PT', // Portugal
      'QA', // Qatar
      'RO', // Romania
      'RS', // Serbia
      'RU', // Russia
      'SA', // Saudi Arabia
      'SE', // Sweden
      'SI', // Slovenia
      'SK', // Slovakia
      'TN', // Tunisia
      'TR', // Turkey
      'UA', // Ukraine
      'YE', // Yemen
    ]);
})();
