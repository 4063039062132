import {showError, showSuccess} from '@admin-tribe/binky-ui';
import {Divider, Flex, Item, Picker, TextArea} from '@adobe/react-spectrum';
import {useSupportTicketHook} from '@pandora/data-model-support-ticket';
import {
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const SUPPORT_TICKET_ACTIONS = {
  CLOSE: 'close',
  ESCALATE: 'escalate',
  REOPEN: 'reopen',
};

const MAX_CHARACTER_COUNT = {
  ACTION_MODAL_SUMMARY: 250,
};

const initialStateForm = {
  reason: null,
  reasonSummary: '',
};
/**
 * A widget to display action modal for support ticket. It uses <ModalContainer/> component to display the modal
 * based on action code.
 */
const SupportTicketActionModal = ({
  actionCode,
  caseId,
  closeModal,
  isOpen,
  onSaveSuccess,
  optionLabel,
  reasons,
  resolutionLabel,
  successMessage = '',
  setOpenSurveyModal,
  title,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialStateForm);
  const {performAction} = useSupportTicketHook();
  const validationMessage = intl.formatMessage(
    {
      id: 'support.supportTicketCommentFormSectionContentModel.validationMessage',
    },
    {maxCharacterCount: MAX_CHARACTER_COUNT.ACTION_MODAL_SUMMARY}
  );
  const content = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'common.modal.buttons.cancel',
    }),
    ctaLabel: intl.formatMessage({
      id: 'support.supportTicketActionsModal.confirmButton',
    }),
  });

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await performAction({
        action: actionCode,
        id: caseId,
        orgId: rootStore.organizationStore.activeOrgId,
        reasonCode: formData.reason,
        reasonSummary: formData.reasonSummary,
      });
      await onSaveSuccess();
      if (SUPPORT_TICKET_ACTIONS.CLOSE === actionCode) {
        setOpenSurveyModal(true);
      }
      showSuccess(
        intl.formatMessage(
          {
            id: successMessage,
          },
          {
            supportCaseId: caseId,
          }
        )
      );
    } catch (error) {
      showError(
        intl.formatMessage({
          id: 'support.supportTicketActionsSection.errorMessage',
        })
      );
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  function sortReasons(items) {
    return items.sort((a, b) => {
      const nameA = intl.formatMessage({id: a.name}).toUpperCase(); // ignore upper and lowercase
      const nameB = intl.formatMessage({id: b.name}).toUpperCase(); // ignore upper and lowercase
      return nameA.localeCompare(nameB);
    });
  }

  const getValidationStatus = () => {
    if (formData.reasonSummary.length === 0) {
      return undefined;
    }
    const hasValid = formData.reasonSummary.length < MAX_CHARACTER_COUNT.ACTION_MODAL_SUMMARY;
    return hasValid ? 'valid' : 'invalid';
  };

  const getCtaDisabled = () => formData.reasonSummary.length === 0 || !formData.reason;

  return (
    <ModalContainer data-testid="support-case-action-modal-dialog-container">
      {isOpen && (
        <ModalDialog
          closeModal={closeModal}
          content={content}
          data-testid="support-case-action-modal-dialog"
          isCtaDisabled={getCtaDisabled()}
          isLoading={isLoading}
          onCancel={closeModal}
          onCta={onSubmit}
        >
          <ModalHeading level={2}>{intl.formatMessage({id: title})}</ModalHeading>
          <Divider marginBottom="size-150" marginTop="size-150" size="M" />
          <ModalContent data-testid={`modal-content-${actionCode}`}>
            <Flex direction="column">
              <Picker
                data-testid="reason-picker"
                label={intl.formatMessage({id: optionLabel})}
                onSelectionChange={(id) => {
                  setFormData({
                    ...formData,
                    reason: id,
                  });
                }}
                selectedKey={formData.id}
                width="100%"
              >
                {sortReasons(reasons).map((item) => (
                  <Item key={item.id}>{intl.formatMessage({id: item.name})}</Item>
                ))}
              </Picker>
            </Flex>
            <Flex direction="column" marginTop="size-175">
              <TextArea
                data-testid="reasonSummary-area"
                errorMessage={validationMessage}
                label={intl.formatMessage({id: resolutionLabel})}
                maxLength={MAX_CHARACTER_COUNT.ACTION_MODAL_SUMMARY}
                onChange={(data) => {
                  setFormData({...formData, reasonSummary: data});
                }}
                validationState={getValidationStatus()}
                value={formData.reasonSummary}
                width="100%"
              />
            </Flex>
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
};

SupportTicketActionModal.propTypes = {
  /**
   * Used to get action code (close/reopen/escalate)
   */
  actionCode: PropTypes.oneOf([
    SUPPORT_TICKET_ACTIONS.CLOSE,
    SUPPORT_TICKET_ACTIONS.ESCALATE,
    SUPPORT_TICKET_ACTIONS.REOPEN,
  ]),
  /**
   * ID of the case to display in the component
   */
  caseId: PropTypes.string.isRequired,
  /**
   * closeModal callback, used to close model
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Indication of modal is open or closed
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Used to show success message after refreshing the consumer component(to ensure ticket is updated)
   * this method returns a promise to ensure that page is updated before model is closed
   */
  onSaveSuccess: PropTypes.func.isRequired,
  /**
   * Displays the label for reason picker
   */
  optionLabel: PropTypes.string.isRequired,
  /**
   * Used to provide options, why user want to close/reopen/escalate the case
   */
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Unique ID for reasons
       */
      id: PropTypes.number.isRequired,
      /**
       * Support Case reasons
       */
      name: PropTypes.string.isRequired,
    })
  ),
  /**
   * Used to display the resolution message/text
   */
  resolutionLabel: PropTypes.string.isRequired,
  /**
   * Used to display the survey modal when acton is closed
   */
  setOpenSurveyModal: PropTypes.func,
  /**
   * Display the content of success message.
   */
  successMessage: PropTypes.string,
  /**
   * Used to display the title
   */
  title: PropTypes.string.isRequired,
};

export default SupportTicketActionModal;
