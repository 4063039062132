import {UserGroupLicenseGroupsList, log} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import {when} from 'mobx';

import rootStore from 'core/RootStore';

/**
 * @description Get the products for a given user group. This will be used to populate the
 *   ProductsDetailSection.
 *
 * @param {String} the User group id
 * @returns {Promise} a promise which resolves with the product list for the given user group
 */
async function getProducts(userGroupId) {
  let products = [];

  const licenseGroupsListPromise = UserGroupLicenseGroupsList.get({
    groupId: userGroupId,
    orgId: rootStore.organizationStore.activeOrgId,
    // The drawer renders all of the profiles without pagination so
    // override default pageSize to get all items in a single page.
    pageSize: -1,
  });

  const productListPromise = when(() => !!rootStore.organizationStore.productList);

  try {
    const values = await Promise.all([licenseGroupsListPromise, productListPromise]);
    const licenseGroupsList = values[0];
    products = groupByProduct(licenseGroupsList.items, rootStore.organizationStore.productList);
  } catch (error) {
    log.error(`Error getting UserGroupLicenseGroupsList: ${error}`);
  }

  return products;
}

/**
 * @description Group the licenseGroups by product with the licenseGroups property set to the list
 *  of license groups for the product.
 *
 * Each license group model has a skeleton Product model attached. While this Product model
 * has the longName and icons, it does not have the customerSegment so isEnterprise() which
 * is used to determine whether or not to display the product license group name.
 * Because of this deficiency the ProductList is used to find the full Product model
 * for each model with a fallback to the skeleton Product model.
 *
 * @param {Array} array of licenseGroups
 * @param {Array} array of Products
 * @returns {Array} returns sorted array of products
 */
function groupByProduct(licenseGroups, productList) {
  const products = [];
  const groupedByProductId = groupBy(licenseGroups, (group) => group?.product?.id);

  Object.keys(groupedByProductId).forEach((productId) => {
    const licenseGroupProduct = productList.items.find((item) => item.id === productId);
    const clonedProduct = cloneDeep(
      licenseGroupProduct || groupedByProductId[productId][0].product
    );
    clonedProduct.licenseGroups = groupedByProductId[productId];
    products.push(clonedProduct);
  });

  return products;
}

// eslint-disable-next-line import/prefer-default-export -- utilities
export {getProducts};
