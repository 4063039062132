(function () {
  angular.module('app.core.user-group', [
    'app.core.api.jil',
    'app.core.auth',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.src2-migration',
  ]);
})();
