import PackagesAdobeProductsService from '../services/PackagesAdobeProductsService';

import PackagesAdobeProductEntity from './PackagesAdobeProductEntity';

class PackagesAdobeProductUiEntity extends PackagesAdobeProductEntity {
  /**
   * @class
   * @description Creates a new AdobeProductUi for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Boolean} options.archive If the product is archived
   * @param {Boolean} options.isHyperdriveUpdate If the product is HyperdriveUpdate
   * @param {Boolean} options.isMaintenanceBuild If the product is LTS
   * @param {Boolean} options.isSTI If the product is STI
   * @param {Boolean} options.isUnsupportedVersion If the product is isUnsupported
   * @param {Object} options.localeInfoUrls Product's localeInfoUrls
   * @param {String} options.name Product's display name
   * @param {String} options.platform Product's platform
   * @param {Array} options.productCategories Catagories that product belongs to
   * @param {String} options.sapCode Product's SAP Code
   * @param {Object} options.stiDependencyMap Product's stiDependencyMap
   * @param {Array} options.stiDependencyMapMacU Product's stiDependencyMacUMap
   * @param {String} options.version Product's version
   */
  constructor(options) {
    super(options);
    Object.assign(this, options);
    this.querySelectorKey = `${this.sapCode}-${this.version.replace(/\./g, '-')}`;
    this.selected = false;
    const adobeProduct =
      PackagesAdobeProductsService.getAdobeProductBySapCodeVersionPlatform(this.product_key) ||
      PackagesAdobeProductsService.getAdobeProductBySapCodeVersion(
        `${this.sapCode}/${this.version}`
      );
    if (adobeProduct) {
      this.name = adobeProduct.getDisplayName();
      this.visible = adobeProduct.isVisible();
      this.localeInfoUrl = adobeProduct.getLocaleInfoUrl();
      this.systemRequirementURL = adobeProduct.getSystemRequirementUrl();
      this.isHyperdriveUpdate = adobeProduct.isHdUpdate();
      this.baseVersion = adobeProduct.getBaseVersion();
      this.frlEnabled = adobeProduct.isNGLEnabled();
      this.nglLicensingLibVersion = adobeProduct.nglLicensingLibVersion;
      this.modules = adobeProduct.getModules();
    } else {
      this.name = '';
      this.visible = true;
      this.localeInfoUrl = '';
      this.systemRequirementURL = '';
      this.isHyperdriveUpdate = true;
      this.modules = [];
    }
  }

  getLocaleInfoUrl() {
    return this.localeInfoUrl;
  }

  getProductCategories() {
    return this.productCategories;
  }

  getStiDependencyMap() {
    return this.stiDependencyMap;
  }

  getStiDependencyMapMacU() {
    return this.stiDependencyMapMacU;
  }

  isArchived() {
    return this.archive;
  }

  isSelected() {
    return this.selected;
  }
}
export default PackagesAdobeProductUiEntity;
