import {EVENT_ACTION} from '@admin-tribe/binky';
import {Flex, Grid, View} from '@adobe/react-spectrum';
import {useFocusWithin, useHover} from '@react-aria/interactions';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import Notification from 'features/notifications/models/Notification';
import {dispatchEvent} from 'features/notifications/models/notificationUtils';

import {
  ANALYTIC_EVENT,
  DISMISS_CARD,
  KEY_CODES,
  MARK_CARD,
  NOTIFICATION_STATUS,
  READ,
  STATUS_TO_MARKER_STYLE,
  UNREAD,
} from '../../notifications.constants';

import VisuallyHiddenStatus from './VisuallyHiddenStatus';
import MenuColumn from './menu-column/MenuColumn';
import MessageColumn from './message-column/MessageColumn';
import StatusColumn from './status-column/StatusColumn';

import './NotificationCard.pcss';

const NotificationCard = ({notification, onChange, onClickButton}) => {
  const {id, payload, state} = notification;
  const cardRef = useRef();
  const intl = useIntl();
  const hiddenStatusId = useId();
  const messageId = useId();
  const notificationStatus = payload.status || NOTIFICATION_STATUS.INFO;
  const markerStyle = STATUS_TO_MARKER_STYLE[notificationStatus];
  const menuItems = [
    {
      body: intl.formatMessage({
        id: `notifications.card.menu.${state.read ? 'markAsUnread' : 'markAsRead'}`,
      }),
      key: MARK_CARD,
    },
    {
      body: intl.formatMessage({id: 'notifications.card.menu.dismiss'}),
      key: DISMISS_CARD,
    },
  ];

  const [isFocusWithin, setIsFocusWithin] = useState(false);
  const {focusWithinProps} = useFocusWithin({
    onFocusWithinChange: (isFocused) => {
      setIsFocusWithin(isFocused);
    },
  });

  const [isCardHovered, setIsCardHovered] = useState(false);
  const {hoverProps} = useHover({
    onHoverEnd: () => setIsCardHovered(false),
    onHoverStart: () => setIsCardHovered(true),
  });

  const onCardClicked = () => {
    if (!state.read) {
      onChange({action: MARK_CARD, id});
    }
    dispatchEvent({
      action: EVENT_ACTION.CLICK,
      name: ANALYTIC_EVENT.NAME.CARD,
      notification,
    });
  };

  const onKeyDown = ({keyCode}) => {
    if (keyCode === KEY_CODES.SPACE || keyCode === KEY_CODES.ENTER) {
      onCardClicked();
    }
  };

  const onItemSelected = (action) => {
    let selectedAction;
    if (action === MARK_CARD) {
      selectedAction = state.read
        ? ANALYTIC_EVENT.ACTION.MARK_UNREAD
        : ANALYTIC_EVENT.ACTION.MARK_READ;
    } else {
      selectedAction = ANALYTIC_EVENT.ACTION.DISMISS;
    }
    dispatchEvent({
      action: selectedAction,
      name: ANALYTIC_EVENT.NAME.MENU,
      notification,
    });
    onChange({action, cardRef, id});
  };

  return (
    <div
      {...focusWithinProps}
      {...hoverProps}
      ref={cardRef}
      aria-labelledby={
        state.read && notificationStatus === NOTIFICATION_STATUS.INFO
          ? messageId
          : `${hiddenStatusId} ${messageId}`
      }
      data-testid="notification-card"
      onClick={onCardClicked}
      onKeyDown={onKeyDown}
      role="button"
      styleName={state.read ? READ : UNREAD}
      tabIndex="0"
    >
      <article styleName={markerStyle}>
        <Flex direction="column">
          <View>
            <VisuallyHiddenStatus
              notificationStatus={notificationStatus}
              readState={state.read}
              statusId={hiddenStatusId}
            />
            <Grid areas={['status message menu']} columns={['1fr', '10fr', '2fr']} rows={['auto']}>
              <StatusColumn showStatusLight={!state.read} />
              <MessageColumn
                messageId={messageId}
                notification={notification}
                onClickButton={(options) => {
                  // Adding notification as a param to be used for analytics event
                  onClickButton(Object.assign(options, {notification}));
                }}
              />
              <MenuColumn
                menuItems={menuItems}
                notification={notification}
                onItemSelected={onItemSelected}
                showMoreOptionsMenu={isFocusWithin || isCardHovered}
              />
            </Grid>
          </View>
        </Flex>
      </article>
    </div>
  );
};

NotificationCard.propTypes = {
  /**
   * The notification object used to describe this notification.
   */
  notification: PropTypes.instanceOf(Notification),
  onChange: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
};

export default NotificationCard;
