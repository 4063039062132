(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @description returns the localized closing reason string or empty string if the given reasonCode is invalid
   * @param {SUPPORT_TICKET_ACTION_REASON_CODE.CLOSE} reasonCode - the close reason code
   * @returns {String} the localized closing reason string
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.support').filter('appSupportCaseCloseReason', filter);

  /* @ngInject */
  function filter($translate, _, SUPPORT_TICKET_ACTION_REASON_CODE) {
    return filterFn;

    function filterFn(reasonCode) {
      const stringKey = _.findKey(
        SUPPORT_TICKET_ACTION_REASON_CODE.CLOSE,
        (code) => code === reasonCode
      );
      return stringKey
        ? $translate.instant(`widgets.support.supportCaseCloseReasonFilter.${stringKey}`)
        : '';
    }
  }
})();
