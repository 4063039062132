import rootStore from 'core/RootStore';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Notifier method to check if the current org needs to take action
 *     for automatic product assignment rules, and if so it will construct
 *     global banner content and add that to the global banner store.
 */
function notifyAutoAssignRuleErrorBanners() {
  const rules = AutoAssignRulesCache.get().autoAssignRules;
  if (rules.some((rule) => rule.status === 'INVALID')) {
    rootStore.organizationStore.globalBannerStore.add({
      linkText: 'globalBanner.linkText.viewRules',
      linkUiSref: 'products.auto-assignment-rules',
      message: 'globalBanner.messages.acrs.autoAssignRulesError',
      type: GLOBAL_BANNER_TYPES.ERROR,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyAutoAssignRuleErrorBanners};
