import {log} from '@admin-tribe/binky';

import {getSATDownloadUrl} from 'features/packages/api/tronData';

import {CREATE_PACKAGE_CONSTANTS} from './packagesConstants';

/**
 * @description Opens url in a new window
 *
 * @param {String} url to be opened
 */
function openLink(url) {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens in a new window
  window.open(url, '_blank', 'noopener,noreferrer');
}

/**
 * @description Method to fetch SAT download URL for a platform and initiate download
 * @param {String} platform The platform to download SAT build for
 * @param {String} version - specific version of SAT build to download.
 * @returns {Promise} returns promise
 */
async function downloadSAT(platform, version) {
  try {
    const response = await getSATDownloadUrl({platform, version});
    const url = response.data;
    if (url !== null) {
      (() => {
        openLink(url);
      })();
    }
    return response;
  } catch (error) {
    log.error(
      'PackagesAdminTools.downloadSAT(): Unable to get download URL from back-end. Error: ',
      error
    );
    return Promise.reject(error);
  }
}

/**
 * @description Method to check if a platform is of MAC type i.e. MAC or MACARM or MACUNIVERSAL
 * @param {String} platform
 * @returns true if platform is of MAC type else false
 */
const isMacTypePlatform = (platform) =>
  platform === CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM ||
  platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM ||
  platform === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM;

export {downloadSAT, openLink, isMacTypePlatform};
