import {AlertDialog, showError, showSuccess} from '@admin-tribe/binky-ui';
import {Content, useDialogContainer} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import {AUTHENTICATION_POLICIES} from 'features/settings/stores/AuthenticationPoliciesStore';

const ConfirmTwoStepVerificationDialog = observer(() => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  const {authenticationPoliciesStore} = useAuthSettingsContext();

  const onConfirmClick = async () => {
    try {
      await authenticationPoliciesStore.setAuthenticationPolicy(
        AUTHENTICATION_POLICIES.IS_MFA_REQUIRED,
        true
      );
      showSuccess(
        intl.formatMessage({
          id: `settings.authSettings.twoStepVerification.toast.IS_MFA_REQUIRED.update.success`,
        })
      );
      dialog.dismiss();
    } catch (error) {
      showError(
        intl.formatMessage({
          id: `settings.authSettings.twoStepVerification.toast.IS_MFA_REQUIRED.update.error`,
        })
      );
    }
  };

  return (
    <AlertDialog
      ctaLabel={intl.formatMessage({
        id: 'settings.authSettings.twoStepVerification.alertDialog.buttons.enable',
      })}
      data-testid="confirm-two-step-verification-modal"
      id="confirm-two-step-verification-modal"
      isLoading={authenticationPoliciesStore.isLoadingMFARequired}
      // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
      onCancel={dialog.dismiss}
      onCta={onConfirmClick}
      title={intl.formatMessage({
        id: 'settings.authSettings.twoStepVerification.alertDialog.title',
      })}
    >
      <Content>
        <p data-testid="modal-description">
          <FormattedMessage id="settings.authSettings.twoStepVerification.alertDialog.description" />
        </p>
      </Content>
    </AlertDialog>
  );
});

export default ConfirmTwoStepVerificationDialog;
