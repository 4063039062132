const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
};

const USER_FOLDER_EVENTS = {
  CREATE: 'CreateUserFolder',
  DELETE: 'DeleteUserFolder',
};

const USER_FOLDER_STATE = {
  ACTIVE: 'ACTIVE',
  DISCARDED: 'DISCARDED',
};

const USER_FOLDER_DEFAULT_PAGE_SIZE = 50;

const MEDIA_TYPE = {
  FOLDER: 'application/vnd.adobecloud.directory+json',
  FOLDERS: [
    'application/vnd.adobecloud.directory+json',
    'application/x-sharedcloud-collection+json',
  ],
};

const REMOVE_USER_STORAGE_OPTIONS = {
  DELETE: 'DELETE',
  DISCARD: 'DISCARD',
  SHARE: 'SHARE',
};

export {
  ACTIONS,
  MEDIA_TYPE,
  REMOVE_USER_STORAGE_OPTIONS,
  USER_FOLDER_EVENTS,
  USER_FOLDER_DEFAULT_PAGE_SIZE,
  USER_FOLDER_STATE,
};
