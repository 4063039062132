import {ProductList, feature} from '@admin-tribe/binky';
import {Cell, Column, Flex, Row, TableBody, TableHeader, Text, View} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {EnDashDefaultContent, Table, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';

import trialHelper from 'core/products/trial-helper/trialHelper';
import ManagePlan from 'features/account/self-cancel/ManagePlan';
import TrialOfferBadge from 'features/trial-offer-badge/TrialOfferBadge';

import {isTrialOffer} from '../../utils/accountUtils';

import styles from './SummaryItemsTable.pcss';

const LOC_KEYS = {
  EN: 'account.acquisitions.summaryItemsTable.table.languages.en',
  MULT: 'account.acquisitions.summaryItemsTable.table.languages.mult',
};

const productNameStyling = {
  fontSize: 'larger',
};

// The table of summary items from a contract's acquisition summary.
const SummaryItemsTable = ({managePlanProps}) => {
  const intl = useIntl();
  const {tableSectionState} = useTableSectionContext();

  const summaryItems = tableSectionState.items;

  const tableLabel = feature.isEnabled('temp_hide_account_page_product_table_header')
    ? 'account.acquisitions.summaryItemsTable.table.name'
    : 'account.acquisitions.summaryItemsTable.table.label';

  const showManagePlanColumn = feature.isEnabled('trial_with_payment') && managePlanProps;
  const showTrial = feature.isEnabled('trial_with_payment');

  const renderManagePlanColumn = useCallback(
    () => (
      <Column key="manageplan" align="end">
        {feature.isEnabled('temp_hide_account_page_product_table_header') ||
        feature.isEnabled('temp_show_account_page_product_change_plan') ? null : (
          <FormattedMessage id="account.acquisitions.summaryItemsTable.table.column.actions" />
        )}
      </Column>
    ),
    []
  );

  const renderTrialBadgesTableCell = useCallback(
    (item) => {
      const {activeTrial, daysInBadge} = trialHelper.getTrialWithPaymentInfo(item);
      return (
        showTrial &&
        activeTrial &&
        daysInBadge > 0 && <TrialOfferBadge className={styles['trial-badges']} days={daysInBadge} />
      );
    },
    [showTrial]
  );

  const renderManagePlanTableCell = useCallback(
    (item) => {
      function getProductId() {
        // Collect productId from aqoIds.
        // aqoId usually has a format like "123123123123_PRODUCTID"
        // So, the part after "_" is fetched and treated as product id.
        return item?.aqoIds?.[0]?.split('_')[1];
      }

      function getProductIdFromProductArrangementCode() {
        // Collect productId from productList through product_arrangement_code from acquisition summaries.
        return managePlanProps?.productList?.items?.find(
          (product) => product?.productArrangementCode === item?.offer?.product_arrangement_code
        )?.id;
      }

      const managePlanPropsWithItem = {
        ...managePlanProps,
        singleProductModeData: {
          item,
          offerId: item.offerId,
          offerType: item?.offer?.offer_type,
          productCode: item?.offer?.product_code,
          productId: feature.isEnabled('temp_aq_id_fix')
            ? getProductIdFromProductArrangementCode()
            : getProductId(),
        },
      };

      return (
        <Cell data-testid="manage-plan">
          <EnDashDefaultContent>
            <View marginTop="size-100">
              <ManagePlan {...managePlanPropsWithItem} />
            </View>
          </EnDashDefaultContent>
        </Cell>
      );
    },
    [managePlanProps]
  );

  const calcDefaultWidth = (key) => {
    if (!showManagePlanColumn) {
      return {
        name: '70%',
        quantities: '30%',
      }[key];
    }
    return null;
  };

  const getNameWithTrialTag = (item, showTrialOffer) => (
    <>
      <Text UNSAFE_style={productNameStyling}>{item.getName()}</Text>
      <Text>{showTrial && showTrialOffer ? renderTrialBadgesTableCell(item) : <Cell />}</Text>
    </>
  );

  return (
    <Table aria-label={intl.formatMessage({id: tableLabel})}>
      <TableHeader>
        <Column key="name" width={calcDefaultWidth('name')}>
          {(feature.isEnabled('temp_show_account_page_product_change_plan') && (
            <FormattedMessage id="account.acquisitions.summaryItemsTable.table.column.productName" />
          )) ||
            (!feature.isEnabled('temp_hide_account_page_product_table_header') && (
              <FormattedMessage id="account.acquisitions.summaryItemsTable.table.column.product" />
            ))}
        </Column>
        <Column key="quantities" align="end" width={calcDefaultWidth('quantities')}>
          {(feature.isEnabled('temp_show_account_page_product_change_plan') && (
            <FormattedMessage id="account.acquisitions.summaryItemsTable.table.column.quantity" />
          )) ||
            (!feature.isEnabled('temp_hide_account_page_product_table_header') && (
              <FormattedMessage id="account.acquisitions.summaryItemsTable.table.column.quantities" />
            ))}
        </Column>
        {showManagePlanColumn ? renderManagePlanColumn() : <Column />}
      </TableHeader>
      <TableBody items={summaryItems}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- Not a large computation */}
        {(item) => {
          const showTrialOffer = isTrialOffer(item);
          return (
            <Row key={item.uuid}>
              <Cell data-testid="summary-item-name-cell">
                <Flex alignItems="center" direction="row" gap="size-100">
                  <ImageIcon alt="" size="L" src={item.getIcon()} />
                  {item.hasEnglishAndMultiLanguage ? (
                    <Flex direction="column">
                      {getNameWithTrialTag(item, showTrialOffer)}
                      <Text
                        data-testid="summary-item-offer-language"
                        UNSAFE_style={{
                          color: 'var(--color-grey-700)',
                        }}
                      >
                        <FormattedMessage id={LOC_KEYS[item.getLanguage()]} />
                      </Text>
                    </Flex>
                  ) : (
                    getNameWithTrialTag(item, showTrialOffer)
                  )}
                </Flex>
              </Cell>
              <Cell data-testid="summary-item-quantity-cell">
                <EnDashDefaultContent>
                  {item.usesSeatBasedDelegation() ? (
                    <FormattedNumber
                      value={
                        feature.isEnabled('temp_show_products_group_by_family')
                          ? item.totalQuantity
                          : item.getTotalQuantity()
                      }
                    />
                  ) : null}
                </EnDashDefaultContent>
              </Cell>
              {showManagePlanColumn ? renderManagePlanTableCell(item) : <Cell />}
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

// Needs to be set for TableSection to work.
SummaryItemsTable.displayName = 'SummaryItemsTable';

SummaryItemsTable.propTypes = {
  /**
   * The props for the ManagePlan component. When the account page is moved to src2, these
   * props should be lifted to that component, and SummaryItemsTableSection should accept children
   * so that we do not need to prop drill and can use composition instead. Only displays ManagePlan
   * when the props are provided.
   */
  managePlanProps: PropTypes.shape({
    contract: PropTypes.shape({
      id: PropTypes.string,
    }),
    productList: PropTypes.instanceOf(ProductList),
  }),
};

export default SummaryItemsTable;
