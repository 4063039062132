(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name StorageRepository
   * @description Model for an individual StorageRepository
   */
  angular
    .module('app.core.storage-repository-list')
    .factory('StorageRepository', getStorageRepositoryModel);

  /* @ngInject */
  function getStorageRepositoryModel(_) {
    class StorageRepository {
      /**
       * @description Creates a new StorageRepository.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Boolean} options.entitled Set to true if repository is 'entitled'
       * @param {String} options.id Org ID associated with the repository
       * @param {String} options.name The name of the repository
       * @param {Boolean} options.provisioned Set to true if repository is 'provisioned'
       */
      constructor(options) {
        initModel(this, options);
      }

      /**
       * @description Transforms a data response Object into a new StorageRepository
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object
       *
       * @returns {StorageRepository} The transformed storage repository
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageRepository(dataTransferObject);
      }
    }

    /**
     * @description Initializes StorageRepository data from options.
     *
     * @param {Object} model StorageRepository model Object instance to initialize
     * @param {Object} options Initialization Object. Refer to 'constructor' method for details.
     */
    function initModel(model, options) {
      _.assignIn(model, _(options).pick(['entitled', 'id', 'name', 'provisioned']).cloneDeep());
    }

    /** Return reference to class **/
    return StorageRepository;
  }
})();
