import {OverlayWait} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

/** Button group for the bulk op detail drawer */
const BulkOperationDrawerButtonGroup = () => {
  const intl = useIntl();
  const {cancelJob, canShowResults, download, getIsCancelable, goToDetails, isCanceling} =
    useBulkOperationJob();

  return (
    (getIsCancelable() || canShowResults) && (
      <OverlayWait isLoading={isCanceling}>
        <ButtonGroup marginBottom="size-200" marginX="size-50">
          {/* canShowResults returns false when jobs isCancelable but possible both return false */}
          {getIsCancelable() && (
            <Button onPress={cancelJob} variant="accent">
              {intl.formatMessage({id: 'bulkOperations.results.drawer.action.cancelProcessing'})}
            </Button>
          )}
          {canShowResults && (
            <>
              <Button onPress={goToDetails} variant="accent">
                {intl.formatMessage({id: 'bulkOperations.results.drawer.action.viewResults'})}
              </Button>
              <Button onPress={download} variant="secondary">
                {intl.formatMessage({id: 'bulkOperations.results.drawer.action.downloadResults'})}
              </Button>
            </>
          )}
        </ButtonGroup>
      </OverlayWait>
    )
  );
};

export default BulkOperationDrawerButtonGroup;
