const PATH_SUPPORT = '/:orgId/support';
const PATH_SUPPORT_CASES = `${PATH_SUPPORT}/support-cases`;
const PATH_SUPPORT_CASES_OPEN = `${PATH_SUPPORT_CASES}/open`;
const PATH_SUPPORT_CASES_CLOSED = `${PATH_SUPPORT_CASES}/closed`;
const PATH_EXPERT_SESSIONS = `${PATH_SUPPORT}/expert-sessions`;
const PATH_EXPERT_SESSIONS_OPEN = `${PATH_EXPERT_SESSIONS}/open`;
const PATH_EXPERT_SESSIONS_CLOSED = `${PATH_EXPERT_SESSIONS}/closed`;
const PATH_SUPPORT_SUMMARY_CC = `${PATH_SUPPORT}/creative-cloud`;
const PATH_SUPPORT_SUMMARY_DC = `${PATH_SUPPORT}/document-cloud`;
const PATH_SUPPORT_SUMMARY_EC = `${PATH_SUPPORT}/experience-cloud`;
const PATH_SUPPORT_SUMMARY_MORE_PRODUCT = `${PATH_SUPPORT}/more-products`;
const PATH_SUPPORT_CASE_DETAILS = `${PATH_SUPPORT_CASES}/:ticketId`;
const PATH_EXPERT_SESSION_DETAILS = `${PATH_EXPERT_SESSIONS}/:ticketId`;

export {
  PATH_SUPPORT,
  PATH_SUPPORT_CASES,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASES_CLOSED,
  PATH_EXPERT_SESSIONS,
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSIONS_CLOSED,
  PATH_SUPPORT_SUMMARY_CC,
  PATH_SUPPORT_SUMMARY_DC,
  PATH_SUPPORT_SUMMARY_EC,
  PATH_SUPPORT_SUMMARY_MORE_PRODUCT,
  PATH_SUPPORT_CASE_DETAILS,
  PATH_EXPERT_SESSION_DETAILS,
};
