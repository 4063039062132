import {FocusScope} from '@react-aria/focus';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from './CreateServerModalContext';
import CreateServerWizard from './CreateServerWizard';
import EditServerWizard from './edit-server/EditServerWizard';

// Main container for Create Server Wizard
const CreateServerModal = observer(({onCancel, isEditWorkflow, serverObject}) => {
  const intl = useIntl();
  const {packagesServerStore} = useCreateServerModalContext();
  const closeCreateServerModal = () => {
    onCancel();
    packagesServerStore.resetServerSessionDataValues();
  };

  // Set server data in case of edit server flow
  useEffect(() => {
    packagesServerStore.resetServerSessionDataValues();
    let modalTitle = intl.formatMessage({id: 'packages.createServer.title'});
    if (isEditWorkflow && serverObject?.serverId) {
      packagesServerStore.setServerObjectForEditFlow(serverObject);
      modalTitle = intl.formatMessage(
        {id: 'packages.createServer.editServer.title'},
        {serverName: serverObject.name}
      );
    }

    packagesServerStore.setServerSessionSettingValues('modalTitle', modalTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dependency on intl not required
  }, [isEditWorkflow, packagesServerStore, serverObject]);

  return (
    <FocusScope>
      {isEditWorkflow ? (
        <EditServerWizard closeCreateServerModal={closeCreateServerModal} />
      ) : (
        <CreateServerWizard closeCreateServerModal={closeCreateServerModal} />
      )}
    </FocusScope>
  );
});

export default CreateServerModal;
