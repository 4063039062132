import {AuthenticatedUser, CONTRACT_STATUS, Locale, configStore, log} from '@admin-tribe/binky';
import {Provider, lightTheme} from '@adobe/react-spectrum';
import {MessageType, SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MatDialog, MatDialogContainer} from '@pandora/react-mat-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';

import rootStore from 'core/RootStore';

// eslint-disable-next-line no-unused-vars -- Need for styles in iframe
import styles from './ChangePlanModalWrapper.pcss';

const CHANGE_PLAN_MINI_APP_NAME = 'ChangePlanMiniApp';
const CHANGE_PLAN_MINI_APP_URL_ENV_PARAM = 'services.changePlanMiniApp';

/**
 * If the client passes the contractId, use that.
 * If not, find the contractId for the org if it has only one contract and that too is active.
 *
 * @param {String} contractId - Deeplink with contract id
 *
 * @returns contractId if found otherwise undefined
 */
const getActiveContractIdForMiniApp = (contractId) => {
  if (contractId) {
    return contractId;
  }

  const {contractList} = rootStore.organizationStore;

  if (contractList.items.length === 1 && contractList.items[0].status === CONTRACT_STATUS.ACTIVE) {
    return contractList.items[0].id;
  }

  return undefined;
};

/**
 * Based on the FF, this function returns the respective config obj.
 *
 * @returns config from configStore
 */
const getMiniAppConfiguration = () => configStore.get(CHANGE_PLAN_MINI_APP_URL_ENV_PARAM);

/**
 * Setup Change Plan Mini App URL
 * See Parameters - https://git.corp.adobe.com/PandoraUI/commerce-mini-apps/blob/master/packages/react-mini-app-change-plan/README.md
 *
 * @param {String} contractId - Deeplink with contract id
 * @param {String} productId - Deeplink with product id
 *
 * @returns Change Plan Mini App URL
 */
const setUpMiniAppUrl = ({contractId, productId}) => {
  const {organizationStore} = rootStore;

  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = getMiniAppConfiguration();
  const orgId = organizationStore.activeOrgId;

  // App Context Identifier as Mini App is loaded in iframe
  const iFrameCtx = 'if';

  // Basic parameters
  const params = {
    cli: 'ONESIE1',
    ctrId: getActiveContractIdForMiniApp(contractId),
    ctx: iFrameCtx,
    curPid: productId,
    lang: localeLanguage,
    orgId,
    userId: AuthenticatedUser.get().getId(),
  };

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);

    // Forward client ID to add product mini app
    if (urlParams.get('cli')) {
      params.cli = urlParams.get('cli');
    }
  }

  return `${url}?${qs.stringify(params, {skipNulls: true})}`;
};

/**
 * @description Hook to manage the state of change plan modal
 */
const useChangePlanModalContext = ({onClose}) => {
  // Show or close the modal dialog based on status of change plan workflow
  const [showChangePlanModal, setShowChangePlanModal] = useState(true);

  // Message handler to process just the System events from change plan Mini App
  const systemMessageHandler = useCallback(
    ({subType}) => {
      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case SystemMessageSubType.APP_CLOSED:
          setShowChangePlanModal(false);
          onClose?.();
          break;
      }
    },
    [onClose]
  );

  return {showChangePlanModal, systemMessageHandler};
};

/**
 * @description Hook to handle the messages from change plan mini app
 */
const useChangePlanMessageHandler = ({systemMessageHandler}) => {
  // Message handler to process the events from change plan Mini App
  const messageHandler = useCallback(
    ({app, type, subType, data}) => {
      // Ignore the message if it is not from change plan Mini App
      if (app !== CHANGE_PLAN_MINI_APP_NAME) return;

      log.info('Message from Change Plan Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      } else if (type === MessageType.NAVIGATION) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
        window.open(data.externalUrl, data.target);
      }
    },
    [systemMessageHandler]
  );

  // Consume message from change plan Mini App iframe
  useIframeMessage(messageHandler);
};

/**
 * @description Represents a wrapper for Change Plan Mini App
 *
 * @param {String} contractId - Deeplink with contract id
 * @param {Function} onClose - Optional callback to run when the modal is closed
 * @param {String} productId - Deeplink with product id
 */
const ChangePlanModalWrapper = ({contractId, onClose, productId}) => {
  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl({contractId, productId}),
    [contractId, productId]
  );

  const {showChangePlanModal, systemMessageHandler} = useChangePlanModalContext({
    onClose,
  });
  useChangePlanMessageHandler({systemMessageHandler});

  if (!showChangePlanModal) return null;

  return (
    <Provider colorScheme="light" theme={lightTheme}>
      <MatDialogContainer
        isDismissable={false}
        isKeyboardDismissDisabled
        variant={{base: 'takeover', M: 'overlay'}}
      >
        <MatDialog aria-label="Change Plan Mini App">
          <iframe src={miniAppUrl} styleName="change-plan-iframe" title="Change Plan" />
        </MatDialog>
      </MatDialogContainer>
    </Provider>
  );
};

ChangePlanModalWrapper.propTypes = {
  /**
   * Optional id which determines the active contract
   */
  contractId: PropTypes.string,
  /**
   * Optional handler that is called when the change-plan modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Optional id which determines the product to change
   */
  productId: PropTypes.string,
};

export default ChangePlanModalWrapper;
