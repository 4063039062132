import {
  AuthenticatedUser,
  CONTRACT_ADMIN,
  GLOBAL_ADMIN_POLICY,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
  feature,
  hasOnlyTeamProducts,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {hasManageAdminsPolicy} from 'core/admin/access/adminAccess';
import {isReadOnly} from 'core/organizations/access/organizationAccess';

/**
 * @description canAssignAdminToUserGroup and exported as canRemoveAdminFromUserGroup
 *  This also checks for the Global Admin 'Manage Admins' policy.
 *
 * @param {UserGroup} userGroup - the user group to check
 * @returns {Boolean} true if the admin can assign users to a user group
 */
function canAssignAdminToUserGroup(userGroup) {
  return (
    canUpdateUserGroup({checkUserGroupPolicy: false, userGroup}) &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic() &&
    // Do not want to add admins to a user group when setting up for a migration.
    !rootStore.organizationStore.migrationList.shouldForceAllowAddAdminsOrUsers() &&
    hasManageAdminsPolicy()
  );
}

/**
 * @returns {Boolean} whether the admin can assign product profiles to a user group
 */
function canAssignProductProfileToUserGroup() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    userRoles.anyOfForOrg([ORG_ADMIN, CONTRACT_ADMIN, PRODUCT_ADMIN, LICENSE_ADMIN], orgId) &&
    rootStore.organizationStore.productList.hasUserDelegatableProduct() &&
    hasCommonUpdateCriteria({checkUserGroupPolicy: false})
  );
}

/**
 * @description canAssignUserToUserGroup and exported as canRemoveUserFromUserGroup
 *  This also checks for the Global Admin 'Manage User Groups' policy.
 *  If assigning an admin use 'canAssignAdminToUserGroup'.
 *
 * @param {UserGroup} userGroup - the user group to check
 * @returns {Boolean} true if the admin can assign users to a user group
 */
function canAssignUserToUserGroup(userGroup) {
  return canUpdateUserGroup({userGroup});
}

/**
 * Method to determine if the current admin has the role to create a new user group.
 *
 * @returns {Boolean} whether the admin can create a user group
 */
function canCreateUserGroup() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    userRoles.anyOfForOrg([ORG_ADMIN, CONTRACT_ADMIN, PRODUCT_ADMIN, LICENSE_ADMIN], orgId) &&
    hasCommonUpdateCriteria()
  );
}

/**
 * @description Method to check if the admin can modify the user group's members.
 *   This does not include editing the user group's name or description. See
 *   canEditUserGroupNameDescription.
 *
 * @returns {Boolean} true - if the admin can edit a user group's members.
 */
function canEditUserGroup() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    !isReadOnly() &&
    (userRoles.isOrgAdminForOrg(orgId) || userRoles.isUserGroupAdminForOrg(orgId)) &&
    hasCommonUpdateCriteria()
  );
}

/**
 * @description Method to check if the admin can modify the user group's name and/or description.
 *
 * @param {UserGroup} userGroup - the user group to check
 * @returns {Boolean} true - if admin can edit a user group's name and/or description.
 */
function canEditUserGroupNameDescription(userGroup) {
  return canUpdateUserGroup({userGroup});
}

/**
 * @description Method to check if particular member can edit user groups.
 *
 * @param {OrganizationUser} member - the memeber to check
 * @returns {Boolean} true - if member can edit user groups
 *
 */
function canMemberEditUserGroup(member) {
  return canEditUserGroup() && !member.getType().isTechnicalAccount() && !member?.externallyManaged;
}

/**
 * @description Method to check if particular member is restricted to only
 *   viewing administerable user groups
 * @returns {Boolean} true - if member can only view administerable user groups
 */
function canOnlyViewAdministerableGroups() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    userRoles.isUserGroupAdminForOrg(orgId) &&
    !userRoles.anyOfForOrg(
      [
        LICENSE_ADMIN,
        ORG_ADMIN,
        CONTRACT_ADMIN,
        PRODUCT_ADMIN,
        PRODUCT_SUPPORT_ADMIN,
        SUPPORT_ADMIN,
      ],
      orgId
    )
  );
}

/**
 * @returns {Boolean} true - if the user can remove a user group.
 * Does not relate to editing the name/description of a user group.
 */
function canRemoveUserGroup() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return userRoles.isOrgAdminForOrg(orgId) && hasCommonUpdateCriteria();
}

/**
 * @returns {Boolean} whether the user can view user groups
 */
function canViewUserGroups() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  if (feature.isEnabled('temp_adobe_agent_access')) {
    return (
      userRoles.anyOfForOrg(
        [
          LICENSE_ADMIN,
          ORG_ADMIN,
          CONTRACT_ADMIN,
          PRODUCT_ADMIN,
          PRODUCT_SUPPORT_ADMIN,
          SUPPORT_ADMIN,
          USER_GROUP_ADMIN,
        ],
        orgId
      ) || AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId)
    );
  }

  return userRoles.anyOfForOrg(
    [
      LICENSE_ADMIN,
      ORG_ADMIN,
      CONTRACT_ADMIN,
      PRODUCT_ADMIN,
      PRODUCT_SUPPORT_ADMIN,
      SUPPORT_ADMIN,
      USER_GROUP_ADMIN,
    ],
    orgId
  );
}

/**
 * @description Method to check whether the admin has access to the `User group` bulk operation routes.
 *
 * @returns {Boolean} true if admin has access.
 */
function canViewUserGroupsBulkOperations() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return userRoles.isOrgAdminForOrg(orgId);
}

/**
 * @description Method to determine if an individual user group can be viewed.
 *
 * @param {String} userGroupId The user group id.
 * @returns {Boolean} whether the user can view the User groups details page.
 *   Each tab has its own access method.
 */
function canViewUserGroupDetails(userGroupId) {
  return (
    canViewUserGroups() ||
    AuthenticatedUser.get()
      .getRoles()
      .isUserGroupAdminForTarget(rootStore.organizationStore.activeOrgId, userGroupId)
  );
}

/**
 * @description Method to check whether the 'Admins' tab should be shown on the User group details page.
 *  This method assumes 'canViewUserGroupDetails' has been called and returns true.
 *
 * @param {UserGroup} userGroup - the user group to check
 * @returns {Boolean} true if the 'Admins' tab should be shown.
 */
function canViewUserGroupDetailsAdmins(userGroup) {
  return userGroup.isEditable() && !hasOnlyTeamProducts(rootStore.organizationStore.productList);
}

/**
 * @description Method to check whether the admin has permission to view the 'Assigned product profiles' tab
 *  for the given UserGroup.
 *  This method assumes 'canViewUserGroupDetails' has been called and returns true.
 *
 * @param {UserGroup} userGroup - the id of the user group to check
 * @returns {Boolean} true if the 'Assigned product profiles' tab should be shown
 */
// eslint-disable-next-line id-length, @admin-tribe/admin-tribe/jsdoc-exported-functions -- xx
function canViewUserGroupDetailsAssignedProductProfiles(userGroup) {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    (userRoles.anyOfForOrg([ORG_ADMIN, CONTRACT_ADMIN, PRODUCT_ADMIN, LICENSE_ADMIN], orgId) ||
      userRoles.isUserGroupAdminForTarget(orgId, userGroup.id)) &&
    !hasOnlyTeamProducts(rootStore.organizationStore.productList)
  );
}

/**
 * @description Method to check whether the admin has access to the `User group` details bulk operation routes.
 *
 * @param {UserGroup} userGroup - the user group to check
 * @returns {Boolean} true if admin has access.
 */
function canViewUserGroupDetailsBulkOperations(userGroup) {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    !userGroup?.isTarget &&
    (userRoles.isOrgAdminForOrg(orgId) || userRoles.isUserGroupAdminForTarget(orgId, userGroup?.id))
  );
}

/**
 * @description Method to check whether the 'Users' tab should be shown on the
 *  User group details page.
 *  This method assumes 'canViewUserGroupDetails' has been called and returns true.
 *
 * @returns {Boolean} true if the 'Users' tab should be shown.
 */
function canViewUserGroupDetailsUsers() {
  return true;
}

/**
 * @description Determines whether or not there is a Global Admin policy that allows user groups to be managed.
 *
 * @returns {Boolean} whether the admin can manage user groups under GA policy
 */
function hasManageUserGroupsPolicy() {
  return (
    feature.isDisabled('temp_global_admin_user_groups') ||
    rootStore.organizationStore.globalAdminPolicyList.getPolicyValue(
      GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS
    )
  );
}

/**
 * Locally, add the USER_GROUP_ADMIN role for the given UserGroup.
 * Since IMS can take a few minutes to invalidate their cache this allow a
 * product/profile admin to look like a user group admin to our access checks and
 * we will allow updates, such as add admins/users, to the just created user group.
 * We know the server will enforce the real rule, so there's no escalation danger.
 *
 * @param {String} userGroupId The id of the new UserGroup to add the role to.
 */
function setUserGroupAdminRole(userGroupId) {
  const orgId = rootStore.organizationStore.activeOrgId;
  AuthenticatedUser.get().getRoles().setUserGroupAdminForTarget(orgId, userGroupId);
}

/* Internal methods */

/**
 * @description Method to check that the admin is an org admin, or an admin for this
 *   user group, the user group is editable, the org isn't migration, or if it is can
 *   users can be added, and also that the org is not a target for sharing.
 *   Optionally check whether the global admin policy allows user groups to be managed.
 *   This method should not be exported.
 *
 * @param {Boolean} checkUserGroupPolicy Check whether the GA policy allows users groups to be managed.
 *   Default is true.
 * @param {UserGroup} userGroup The user group.
 * @returns {Boolean} true - if the admin is an org admin or a
 *   user group admin for this user group and the user group
 *   can be modified.
 */
function canUpdateUserGroup({checkUserGroupPolicy, userGroup}) {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    (userRoles.isOrgAdminForOrg(orgId) ||
      userRoles.isUserGroupAdminForTarget(orgId, userGroup.id)) &&
    userGroup.isEditable() &&
    hasCommonUpdateCriteria({checkUserGroupPolicy})
  );
}

/**
 * @description Checks common criteria needed to update a user group.
 *  Checks that the org isn't migration, or if it is that users can be added.
 *  Optionally check whether the global admin policy allows user groups to be managed.
 *  This method should not be exported.
 *
 * @param {Boolean} checkUserGroupPolicy Check whether the GA policy allows users groups to be managed.
 *   Default is true.
 * @returns {Boolean} whether the user group satisfies the common update criteria
 *
 */
function hasCommonUpdateCriteria({checkUserGroupPolicy = true} = {}) {
  return (
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic() &&
    (!checkUserGroupPolicy || hasManageUserGroupsPolicy())
  );
}

export {
  canAssignAdminToUserGroup,
  canAssignAdminToUserGroup as canRemoveAdminFromUserGroup,
  canAssignProductProfileToUserGroup,
  canAssignProductProfileToUserGroup as canUnassignProductProfileFromUserGroup,
  canAssignUserToUserGroup,
  canAssignUserToUserGroup as canRemoveUserFromUserGroup,
  canCreateUserGroup,
  canEditUserGroup,
  canEditUserGroupNameDescription,
  canMemberEditUserGroup,
  canOnlyViewAdministerableGroups,
  canRemoveUserGroup,
  canViewUserGroups,
  canViewUserGroupsBulkOperations,
  canViewUserGroupsBulkOperations as canManageUserGroupsBulkOperations,
  canViewUserGroupDetails,
  canViewUserGroupDetailsAssignedProductProfiles,
  canViewUserGroupDetailsAdmins,
  canViewUserGroupDetailsBulkOperations,
  canViewUserGroupDetailsUsers,
  hasManageUserGroupsPolicy,
  setUserGroupAdminRole,
};
