// The types of reports
const REPORT_FOLDER_TYPE = {ASSETS: 'assets', USERS: 'users'};

// Status strings returned from the API
const REPORT_STATUS = {
  ERROR: 'Error',
  INPROGRESS: 'Inprogress',
  SUCCESS: 'Success',
};

export {REPORT_FOLDER_TYPE, REPORT_STATUS};
