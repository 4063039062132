(function () {
  /**
   * @awaitingdeprecation
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.core.analytics')
    .constant('AEP_ANALYTICS_SITE_SECTION', 'adminconsole.adobe.com')
    .constant('ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS', [
      'temp_add_product_mini_app',
      'temp_add_product_mini_app_ab_test',
      'temp_add_product_mini_app_all',
      'temp_add_product_mini_app_deep_link',
      'temp_add_product_mini_app_rtp1',
      'temp_add_product_mini_app_us_direct',
      'temp_self_cancel_ab',
      'temp_fake_ab_fg_b', // Remove after fake AB test for self-cancel project
    ]);
})();
