(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name SearchUserList
   * @description search user list model
   */
  angular.module('app.core.search.user-list').factory('SearchUserList', getSearchUserListFactory);

  /* @ngInject */
  function getSearchUserListFactory(
    _,
    binkySrc2,
    JIL_CONSTANTS,
    MemberList,
    MODEL,
    modelCache,
    modelCacheUtils,
    OrganizationManager,
    OrganizationUser,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    promiseUtils,
    SEARCH_TARGET_TYPE
  ) {
    /**
     * @description generates a API response data transformer for the given OrgID
     *
     * @param {Array} responseData list of API objects
     * @returns {Function} - ModelList transformer that converts API data into OrgUsers and UserGroups
     */
    function transformResponseData(responseData) {
      return _.map(responseData, (responseItem) =>
        OrganizationUser.apiResponseTransformer(responseItem)
      );
    }

    class SearchMemberListSrc2 extends binkySrc2.services.search.SearchMemberList {
      constructor(options = {}) {
        const orgId = OrganizationManager.getActiveOrgId();
        super(
          _.assign(options, {
            orgId,
            sortExpression: JIL_CONSTANTS.SORT.FNAME_LNAME,
            sortOrder: JIL_CONSTANTS.ORDER.ASC,
            transformResponseData,
          })
        );
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return modelCacheUtils.getParameterizedKey(
          _.omitBy(
            {
              includeUserGroups: this.includeUserGroups,
              orgId: this.orgId,
              page: this.pagination.currentPage - 1,
              pageSize: this.pagination.pageSize,
              searchQuery: this.filter.query,
              sort: this.sort.expression,
              sortOrder: this.sort.order,
              targetLicenseGroupId: this.targetLicenseGroupId,
              targetProductId: this.targetProductId,
              targetType: this.targetType,
              targetUserGroupId: this.targetUserGroupId,
            },
            _.isUndefined
          )
        );
      }

      /**
       * @description Method to refresh current model state
       *
       * @returns {Promise<UserGroupListSrc2>} Returns promise resolving to model or rejecting with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = promiseUtils
          .toAngularPromise(super.refresh())
          .then(() => {
            modelCache.put(MODEL.SEARCHUSERLIST, this, this.key());
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      /**
       * @description Method to retrieve an existing SearchUserList from the back-end.
       *
       * @param {String} filterQuery search query filter.
       * @param {Object} [targetOptions] targetOptions for the search.
       * @param {Boolean} [targetOptions.includeUserGroups] whether or not to include user groups in the response, cannot be used with targetUserGroupId.
       * @param {String} [targetOptions.targetType] type of target field. One of: SEARCH_TARGET_TYPE.ADMIN, SEARCH_TARGET_TYPE.USER, PAGE_TARGET_TYPE.USER_FOLDER. Default is SEARCH_TARGET_TYPE.USER.
       * @param {String} [targetOptions.targetProductId] Users and UserGroups matching the search will be marked inTarget if they are Admin members (as specified by the targetType) of the given targetProductId.
       * @param {String} [targetOptions.targetLicenseGroupId] Users and UserGroups matching the search will be marked inTarget if they are Admin or User members (as specified by the targetType) of the given targetLicenseGroupId. When specifying targetLicenseGroupId, the corresponding targetProductId must be provided.
       * @param {String} [targetOptions.targetUserGroupId] Users and UserGroups matching the search will be marked inTarget if they are Admin or User members (as specified by the targetType) of the given targetUserGroupId.
       *
       * @returns {SearchUserList} new SearchUserList Object reference
       */
      static get(filterQuery, targetOptions = {}) {
        let model = new SearchMemberListSrc2({
          filterQuery,
          includeUserGroups: targetOptions.includeUserGroups,
          orgId: OrganizationManager.getActiveOrgId(),
          pageSize: 5,
          targetLicenseGroupId: targetOptions.targetLicenseGroupId,
          targetProductId: targetOptions.targetProductId,
          targetType: targetOptions.targetType,
          targetUserGroupId: targetOptions.targetUserGroupId,
        });

        const cachedModel = modelCache.get(MODEL.SEARCHUSERLIST, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }

      static getTargetOptionsFromPageContext(pageContext) {
        let targetType;
        if (pageContext.targetType === PAGE_TARGET_TYPE.DEVELOPER) {
          targetType = PAGE_TARGET_TYPE.ADMIN;
        } else if (pageContext.targetType === PAGE_TARGET_TYPE.INTEGRATION) {
          targetType = PAGE_TARGET_TYPE.USER;
        } else {
          targetType = pageContext.targetType;
        }
        const targetOptions = {
          includeUserGroups:
            pageContext.target !== PAGE_TARGET.USER_GROUP &&
            pageContext.targetType === SEARCH_TARGET_TYPE.USER,
          targetType,
        };
        switch (pageContext.target) {
          case PAGE_TARGET.PRODUCT:
            if (pageContext.targetType === PAGE_TARGET_TYPE.ADMIN) {
              targetOptions.targetProductId = pageContext.targetId;
            }
            break;
          case PAGE_TARGET.PRODUCT_CONFIGURATION:
            targetOptions.targetLicenseGroupId = pageContext.targetId;
            targetOptions.targetProductId = pageContext.targetParentId;
            break;
          case PAGE_TARGET.USER_GROUP:
            targetOptions.targetUserGroupId = pageContext.targetId;
            break;
          default:
            break;
        }
        return targetOptions;
      }
    }

    MemberList.setupCache(MODEL.SEARCHUSERLIST, 10);

    return SearchMemberListSrc2;
  }
})();
