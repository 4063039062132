const USER_GROUP_LICENSE_GROUPS_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.users.USER_GROUP_LICENSE_GROUPS_CONSTANTS;
(function () {
  /**
   * @deprecated please use src2/app/services/users/UserGroupLicenseGroupsConstants
   */
  angular
    .module('app.core.user-group')
    .constant(
      'USER_GROUP_LICENSE_GROUPS_EVENT',
      USER_GROUP_LICENSE_GROUPS_CONSTANTS.USER_GROUP_LICENSE_GROUPS_EVENT
    );
})();
