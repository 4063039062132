import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

const IncludeListFormContext = createContext({});

const useIncludeListFormContext = () => useContext(IncludeListFormContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- this is one component
const IncludeListFormContextProvider = ({
  availableEmptyMessage,
  availableTitle,
  children,
  includeEmptyMessage,
  includeTitle,
  itemRenderer,
  itemText,
  noResultsMessage,
  readOnly,
  searchItem,
}) => {
  const value = useMemo(
    () => ({
      availableEmptyMessage,
      availableTitle,
      children,
      includeEmptyMessage,
      includeTitle,
      itemRenderer,
      itemText,
      noResultsMessage,
      readOnly,
      searchItem,
    }),
    [
      availableEmptyMessage,
      availableTitle,
      children,
      includeEmptyMessage,
      includeTitle,
      itemRenderer,
      itemText,
      noResultsMessage,
      readOnly,
      searchItem,
    ]
  );
  return (
    <IncludeListFormContext.Provider value={value}>{children}</IncludeListFormContext.Provider>
  );
};

IncludeListFormContextProvider.propTypes = {
  /**
   * The empty message for the available list in the form.
   */
  availableEmptyMessage: PropTypes.string.isRequired,
  /**
   * The title of the available list in the form.
   */
  availableTitle: PropTypes.string.isRequired,
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * The empty message for the include list in the form.
   */
  includeEmptyMessage: PropTypes.string.isRequired,
  /**
   * The title of the include list in the form.
   */
  includeTitle: PropTypes.string.isRequired,
  /**
   * The callback to render each child item in the lists. Takes an argument of the item to render.
   */
  itemRenderer: PropTypes.func.isRequired,
  /**
   * A function to determine the text of an item. This text is used in aria labels. Takes an argument of the item to get the text for.
   */
  itemText: PropTypes.func.isRequired,
  /**
   * The message to display when there are no results matching the search string.
   */
  noResultsMessage: PropTypes.string.isRequired,
  /**
   * Whether the include list is read only or not. A read only include list does not display the add/remove (all) buttons.
   */
  readOnly: PropTypes.bool,
  /**
   * Callback to determine whether an item should be included in the results of a search. Takes argument of an item, and the text search term.
   */
  searchItem: PropTypes.func.isRequired,
};

export {IncludeListFormContextProvider, useIncludeListFormContext};
