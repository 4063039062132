(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('app.core.organizations.organization-manager').constant('ORG_MANAGER_ERROR', {
    CONTRACT_CANCELLED: 'The contract for this organization has been cancelled.',
    DELEGATION_GROUPS_MIGRATION_ERROR: 'DELEGATION_GROUPS_MIGRATION_ERROR',
    MIGRATING_TO_DELEGATION_GROUPS: 'MIGRATING_TO_DELEGATION_GROUPS',
    NO_ORGS_AVAILABLE: 'No organizations are available!',
    ORG_NOT_FOUND: 'The organization requested was not found!',
    TERMS_ACCEPTANCE_REQUIRED: 'This organization needs to accept new Terms and Conditions!',
    TERMS_REDIRECT: 'Redirecting to accept new Terms and Conditions.',
    UNABLE_TO_DETERMINE_USER: 'UNABLE_TO_DETERMINE_USER',
  });
})();
