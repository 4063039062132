import {Divider, Flex, Heading, useDialogContainer} from '@adobe/react-spectrum';
import {ModalContent, ModalDialog} from '@pandora/react-modal-dialog';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import DirectoryEntity from 'common/entities/DirectoryEntity';

const DeleteSyncedDirectoriesWarningModal = ({directories}) => {
  const dialog = useDialogContainer();

  return (
    <ModalDialog
      dialogStyle={{
        spaceX: 'size-0',
      }}
      onCancel={() => dialog.dismiss()}
      role="alertdialog"
      size="M"
    >
      <Heading level={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <FormattedMessage id="settings.directories.deleteSyncedDirectoriesWarningModal.title" />
          <AlertIcon color="notice" size="M" />
        </Flex>
      </Heading>
      <Divider marginTop="size-250" size="M" />
      <ModalContent>
        <FormattedMessage id="settings.directories.deleteSyncedDirectoriesWarningModal.description" />
        <ul>
          {directories.map((directory) => (
            <li key={directory.id}>{directory.directoryName}</li>
          ))}
        </ul>
      </ModalContent>
    </ModalDialog>
  );
};

DeleteSyncedDirectoriesWarningModal.propTypes = {
  directories: PropTypes.arrayOf(PropTypes.instanceOf(DirectoryEntity)).isRequired,
};
export default DeleteSyncedDirectoriesWarningModal;
