const JOB_EVENTS = {
  DELETE_JOB: 'DeleteJob',
  REFRESH_JOB_LIST: 'RefreshJobList',
  UPDATE_JOB: 'UpdateJob',
};

// The failures that can occur for a particular job item (e.g. a particular
// row in a CSV). For now this list only contains the small number of failure
// types we need to reference from within our UI code, rather than all the
// types which can be returned by JIL
const JOB_ITEM_FAILURE_TYPE = {
  ALLOWABLE_LICENSE_COUNT_EXCEEDED: 'ALLOWABLE_LICENSE_COUNT_EXCEEDED',
  INVALID_USER_TYPE: 'INVALID_USER_TYPE',
  UNKNOWN_USER_TYPE: 'UNKNOWN_USER_TYPE',
};

const JOB_OPERATION = {
  ADD_ENTERPRISE_USERS: 'ADD_ENTERPRISE_USERS',
  ADD_ENTERPRISE_USERS_PLC: 'ADD_ENTERPRISE_USERS_PLC',
  ADD_ENTERPRISE_USERS_UG: 'ADD_ENTERPRISE_USERS_UG',
  ADD_ORGANIZATION_USERS: 'ADD_ORGANIZATION_USERS',
  ADD_ORGANIZATION_USERS_LG: 'ADD_ORGANIZATION_USERS_LG',
  ADD_ORGANIZATION_USERS_UG: 'ADD_ORGANIZATION_USERS_UG',
  ADD_USER_GROUPS: 'ADD_USER_GROUPS',
  ASSIGN_LICENSES: 'ASSIGN_LICENSES',
  EDIT_ENTERPRISE_USERS: 'EDIT_ENTERPRISE_USERS',
  EDIT_ORGANIZATION_USER_GROUPS: 'EDIT_ORGANIZATION_USER_GROUPS',
  EDIT_ORGANIZATION_USERS: 'EDIT_ORGANIZATION_USERS',
  EXPORT_USERS_CSV: 'EXPORT_USERS_CSV',
  OFFER_MIGRATION: 'OFFER_MIGRATION',
  REMOVE_ENTERPRISE_USERS: 'REMOVE_ENTERPRISE_USERS',
  REMOVE_ENTERPRISE_USERS_DU: 'REMOVE_ENTERPRISE_USERS_DU',
  REMOVE_ENTERPRISE_USERS_PLC: 'REMOVE_ENTERPRISE_USERS_PLC',
  REMOVE_ENTERPRISE_USERS_UG: 'REMOVE_ENTERPRISE_USERS_UG',
  REMOVE_ORGANIZATION_USERS: 'REMOVE_ORGANIZATION_USERS',
  REMOVE_ORGANIZATION_USERS_LG: 'REMOVE_ORGANIZATION_USERS_LG',
  REMOVE_ORGANIZATION_USERS_UG: 'REMOVE_ORGANIZATION_USERS_UG',
  REVOKE_ENTERPRISE_INVITES: 'REVOKE_ENTERPRISE_INVITES',
  SWITCH_ENTERPRISE_USERS: 'SWITCH_ENTERPRISE_USERS',
  SWITCH_ORGANIZATION_USERS: 'SWITCH_ORGANIZATION_USERS',
};

const JOB_RESULT_ITEM_STATUS = {
  FAILURE: 'FAILURE',
  NOT_PROCESSED: 'NOT_PROCESSED',
  SUCCESS: 'SUCCESS',
};

const JOB_STATUS = {
  CANCELED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  QUEUED: 'QUEUED',
  REJECTED: 'REJECTED',
  UNKNOWN: 'UNKNOWN',
  UNKNOWN_JOB_TYPE: 'UNKNOWN_JOB_TYPE',
};

export {JOB_EVENTS, JOB_ITEM_FAILURE_TYPE, JOB_OPERATION, JOB_RESULT_ITEM_STATUS, JOB_STATUS};
