(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.scorecard')
    // This list corresponds to the list of translation keys in 'widgets.common.scorecard.label.messageFormat'.
    .constant('SCORECARD_LABEL_UNIT', {
      CREDIT: 'credit', // comes from the offer
      DEVICE_LICENSE: 'deviceLicense', // deprecated and support will be removed at end of 2019
      GB: 'GB', // comes from the offer
      IMAGE: 'image', // comes from the offer
      INSTANCE: 'instance',
      INTEGRATION: 'integration',
      LICENSE: 'license',
      MACHINES: 'machines', // comes from the offer
      MILLION_GB_SECONDS: 'MILLION GB-SECONDS', // comes from the offer
      STOCK_IMAGE: 'stock_image', // comes from the offer
      TRANSACTIONS: 'transactions', // comes from the offer
      USER: 'user',
      USERS: 'USERS', // comes from the offer
      VIDEO: 'video', // comes from the offer
    });
})();
