import {Flex, Text, TextArea} from '@adobe/react-spectrum';
import {DividerPlacement} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {AUSST_TEXT_AREA_LIMIT} from '../../../packagesConstants';
import PackagesSection from '../../packages-section/PackagesSection';
import CheckboxWithLink from '../checkbox-with-link/CheckboxWithLink';

import styles from './AusstOverrideSection.pcss';

/**
 * Adobe Update Server Setup Tool Override Section
 * To configure internal update server
 */
const AusstOverrideSection = ({
  ausstOverrideWindowsTextAreaRef,
  preferences,
  setPreferences,
  showDivider = false,
}) => {
  const intl = useIntl();

  return (
    <PackagesSection
      dividerPlacement={showDivider ? DividerPlacement.TOP : DividerPlacement.NONE}
      headingId="packages-ausst-override-heading"
      title={intl.formatMessage({id: 'packages.preferences.ausst.title'})}
    >
      <Flex direction="column" gap="size-200" marginTop="size-100">
        <Text>{intl.formatMessage({id: 'packages.preferences.ausst.description'})}</Text>

        <TextArea
          ref={ausstOverrideWindowsTextAreaRef}
          aria-multiline
          data-testid="ausst-windows-textarea"
          label={intl.formatMessage({id: 'packages.preferences.ausst.labelWin'})}
          maxLength={AUSST_TEXT_AREA_LIMIT}
          onChange={(value) => setPreferences({...preferences, ausstOverrideWin: value})}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set classes
          UNSAFE_className={styles['text-area']}
          value={preferences.ausstOverrideWin}
          width="auto"
        />
        <TextArea
          aria-multiline
          data-testid="ausst-mac-textarea"
          label={intl.formatMessage({id: 'packages.preferences.ausst.labelMac'})}
          maxLength={AUSST_TEXT_AREA_LIMIT}
          onChange={(value) => setPreferences({...preferences, ausstOverrideMac: value})}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set classes
          UNSAFE_className={styles['text-area']}
          value={preferences.ausstOverrideMac}
          width="auto"
        />

        <CheckboxWithLink
          dataTestId="ausst-checkbox"
          isSelected={preferences.ausstFallbackEnabled}
          label={intl.formatMessage({id: 'packages.preferences.ausst.fallToAdobeServer'})}
          linkLabel={intl.formatMessage({id: 'packages.preferences.ausst.learnMore'})}
          name="ent_ausst_fallback"
          onChange={() =>
            setPreferences({
              ...preferences,
              ausstFallbackEnabled: !preferences.ausstFallbackEnabled,
            })
          }
        />
      </Flex>
    </PackagesSection>
  );
};

AusstOverrideSection.propTypes = {
  /**
   * Reference to ausst override section's checkbox. Required in preferences button group.
   */
  ausstOverrideWindowsTextAreaRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }).isRequired,
  /**
   * Preferences object
   */
  preferences: PropTypes.shape({
    /**
     * Indicates to bypass your organization's internal update server when it is unavailable
     */
    ausstFallbackEnabled: PropTypes.bool,
    /**
     * Override XML code for mac
     */
    ausstOverrideMac: PropTypes.string,
    /**
     * Override XML code for windows
     */
    ausstOverrideWin: PropTypes.string,
  }).isRequired,
  /**
   * Function to be called with updated preferences object when preferences.ausstFallbackEnabled,
   * preferences.ausstOverrideMac or preferences.ausstOverrideWin values are to be modified.
   */
  setPreferences: PropTypes.func.isRequired,
  /**
   * Boolean value to indicate if divider is to be shown
   */
  showDivider: PropTypes.bool,
};

export default AusstOverrideSection;
