import {OverlayWait, Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import useSettingsAccessors from 'features/settings/hooks/useSettingsAccessors';

import SettingsWorkspaceNav from './SettingsWorkspaceNav';

/**
 * The Settings workspace displays the SettingsWorkspaceNav and
 * any applicable nested children routes specified in settingsRoutes.jsx
 */
const SettingsWorkspace = () => {
  const settingsAccess = useSettingsAccessors();

  return (
    <OverlayWait
      isLoading={settingsAccess.isLoading}
      showContent={!settingsAccess.isLoading}
      size="L"
    >
      <Workspace useOutlet>
        <SettingsWorkspaceNav settingsAccess={settingsAccess} />
      </Workspace>
    </OverlayWait>
  );
};

export default SettingsWorkspace;
