import PropTypes from 'prop-types';
import React from 'react';

import CreatePackageCardCheckbox from '../../create-package-card-checkbox-group/CreatePackageCardCheckbox';

// LicenseTypeCard is CreatePackageCard with values populated accordingly to be used in ChooseLicenseType screen
const LicenseTypeCard = ({autoFocus, content, name, title, value}) => (
  <CreatePackageCardCheckbox
    autoFocus={autoFocus}
    height="100%"
    minHeight="size-2400"
    name={name}
    title={title}
    titleDescription={content}
    value={value}
    width="size-3600"
  />
);

LicenseTypeCard.propTypes = {
  /**
   * Whether the element will be auto focused.
   */
  autoFocus: PropTypes.bool.isRequired,
  /**
   * Description of the card
   */
  content: PropTypes.string.isRequired,
  /**
   * String passed to GoUrl name prop
   */
  name: PropTypes.string,
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * License type value the card represents
   */
  value: PropTypes.string.isRequired,
};

export default LicenseTypeCard;
