import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

let clientId, url;

/**
 * Configure NGL APIs
 * @see {@link https://admin-stage.adobe.io/publisher/directory/633/services/1467}
 *
 * @param {Object} config - The configuration object
 * @param {String} config.clientId - The identifier for the consuming application
 * @param {String} config.url - The root url for NGL api
 */
const configure = (config) => {
  ({clientId, url} = config);
};

/**
 * Calls NGL api to evaluate licenses for applications.
 * @see {@link https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=CloudTechIndia&title=Evaluate+Licenses+For+Apps+API}
 *
 * @param {Object} options - options object
 * @param {String[]} options.appIds  - the application IDs that will be mapped to a set of assignable license IDs
 * @param {String[]} options.licenseIds  - a set of license IDs that are filtered down to those that are assignable to the given application IDs
 * @param {String} options.orgId  - the organization ID
 *
 * @returns {Promise} - a promise which will resolve with an object that contains array of license IDs. Ex: { licenseIds: ["D6ABEAB304FD2C7A82AA"] }
 */
const getEvaluatedLicensesForApps = (options) =>
  axios.post(`${url}/evaluateLicensesForApps`, options, {
    headers: buildHeaders(),
  });

function buildHeaders() {
  const headers = {
    'X-Api-Key': clientId,
  };
  const token = authentication.getAccessToken()?.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

const ngl = {
  configure,
  getEvaluatedLicensesForApps,
};

export default ngl;
