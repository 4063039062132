(function () {
  /**
   * @deprecated not required for ES6/React
   *
   * @ngdoc directive
   * @name app.widgets.remove-on-read-only:appRemoveOnReadOnly
   *
   * @description remove the element if
   *
   * @example <button remove-on-read-only>Hide this paragraph.</button>
   *
   */
  angular
    .module('app.widgets.remove-on-read-only')
    .directive('appRemoveOnReadOnly', appRemoveOnReadOnly);

  function appRemoveOnReadOnly(CONTRACT_MIGRATION_STARTED, removeOnReadOnly) {
    return {
      link: (scope, element) => {
        remove();

        scope.$on(CONTRACT_MIGRATION_STARTED, remove);

        function remove() {
          if (removeOnReadOnly.isReadOnly()) {
            element.remove();
          }
        }
      },
      restrict: 'A',
    };
  }
})();
