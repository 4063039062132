import {StatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SAML_CERTIFICATE_STATUS} from 'features/settings/common/constants/samlCertificatesConstants';
import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';

const SAML_CERTIFICATE_STATUS_LIGHT = {
  [SAML_CERTIFICATE_STATUS.ACTIVE]: {
    label: 'settings.certificates.table.status.ACTIVE',
    variant: 'positive',
  },
  [SAML_CERTIFICATE_STATUS.DEFAULT]: {
    label: 'settings.certificates.table.status.DEFAULT',
    variant: 'positive',
  },
  [SAML_CERTIFICATE_STATUS.DISABLED]: {
    label: 'settings.certificates.table.status.DISABLED',
    variant: 'neutral',
  },
  [SAML_CERTIFICATE_STATUS.DEFAULT_AND_EXPIRED]: {
    label: 'settings.certificates.table.status.DEFAULT_AND_EXPIRED',
    variant: 'negative',
  },
  [SAML_CERTIFICATE_STATUS.EXPIRED]: {
    label: 'settings.certificates.table.status.EXPIRED',
    variant: 'negative',
  },
  [SAML_CERTIFICATE_STATUS.EXPIRING]: {
    label: 'settings.certificates.table.status.EXPIRING',
    variant: 'notice',
  },
  [SAML_CERTIFICATE_STATUS.UNKNOWN]: {
    label: 'settings.certificates.table.status.UNKNOWN',
    variant: 'neutral',
  },
};

const SamlCertificateStatus = ({certificate}) => {
  const intl = useIntl();
  const {
    state: {defaultCertificateId},
  } = useSetupCertificatesContext();

  let label, variant;

  // First check for a status that is not from the server, and it's determined
  // from idp data and certificate expiry date.
  // If we have a status that is not from the server we use one of the artificial statuses
  // defined in the SAML_CERTIFICATE_STATUS constant.
  if (certificate.isExpired && certificate.checkIsDefault(defaultCertificateId)) {
    ({label, variant} = SAML_CERTIFICATE_STATUS_LIGHT[SAML_CERTIFICATE_STATUS.DEFAULT_AND_EXPIRED]);
  } else if (certificate.isExpired) {
    ({label, variant} = SAML_CERTIFICATE_STATUS_LIGHT[SAML_CERTIFICATE_STATUS.EXPIRED]);
  } else if (certificate.isExpiring) {
    ({label, variant} = SAML_CERTIFICATE_STATUS_LIGHT[SAML_CERTIFICATE_STATUS.EXPIRING]);
  } else if (certificate.checkIsDefault(defaultCertificateId)) {
    ({label, variant} = SAML_CERTIFICATE_STATUS_LIGHT[SAML_CERTIFICATE_STATUS.DEFAULT]);
  } else {
    ({label, variant} =
      SAML_CERTIFICATE_STATUS_LIGHT[certificate.status] ?? SAML_CERTIFICATE_STATUS_LIGHT.UNKNOWN);
  }

  return (
    <StatusLight marginStart="-10px" role="status" variant={variant}>
      {intl.formatMessage({id: label})}
    </StatusLight>
  );
};

SamlCertificateStatus.propTypes = {
  certificate: PropTypes.shape({
    checkIsDefault: PropTypes.func,
    isExpired: PropTypes.bool,
    isExpiring: PropTypes.bool,
    status: PropTypes.string,
  }),
};

export default SamlCertificateStatus;
// exported for tests
export {SAML_CERTIFICATE_STATUS, SAML_CERTIFICATE_STATUS_LIGHT};
