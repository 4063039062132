import {View} from '@adobe/react-spectrum';
import React from 'react';

import EditBillingModal from 'common/components/edit-billing-modal/EditBillingModal';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import useEditBillingModal from 'common/hooks/useEditBillingModal';

import BillingHistoryPage from '../billing-history/pages/billing-history-page/BillingHistoryPage';
import {getContractForAccountPage} from '../utils/accountUtils';

import AccountPageWrapper from './AccountPageWrapper';

/**
 * The Account Billing history page
 */
const AccountBillingHistory = () => {
  useDocumentTitle({key: 'account.billingHistory.title'});

  const {
    closeEditBillingModal,
    openEditBillingModal,
    showEditBillingModal,
    invoiceIdToPay,
    setInvoiceIdToPay,
  } = useEditBillingModal();

  const primaryContract = getContractForAccountPage();

  const openEditPaymentInfoModal = ({invoiceId}) => {
    // Display REBA Modal
    setInvoiceIdToPay(invoiceId);
    openEditBillingModal();
  };

  // This page is not wrapped in AccountPageWrapper as it BillingHistoryPage internally takes care if it
  return (
    <AccountPageWrapper pageTestId="account-billing-history-page">
      <View
        // Add negative margin-top to this View as AccountPageWrapper adds additional margin to this PageContent
        // And because of lack of PageHeader on this page, the content needs to be moved up a bit.
        UNSAFE_style={{
          'margin-top':
            'calc(var(--spectrum-global-dimension-size-400, var(--spectrum-alias-size-400)) * -1)',
        }}
      >
        <BillingHistoryPage
          contract={primaryContract}
          onOpenOneTimePayment={openEditPaymentInfoModal}
        />
        <EditBillingModal
          contractId={primaryContract?.id}
          inPaymentMode
          invoiceId={invoiceIdToPay}
          isOpen={showEditBillingModal}
          onCancel={closeEditBillingModal}
          onSubmit={closeEditBillingModal}
        />
      </View>
    </AccountPageWrapper>
  );
};

export default AccountBillingHistory;
