import {Divider, Flex, Grid, Radio, RadioGroup, Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import {SERVERS_CONSTANTS} from 'features/packages/packagesConstants';
/**
 * Server Details component for Finalize screen in create/edit server flow
 * it shows details of the server
 */
const EntitlementLimits = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionData, setServerSessionDataValues} = packagesServerStore;
  const intl = useIntl();

  const quotaChange = (val) => {
    setServerSessionDataValues('activationEnforcementType', val);
  };

  return (
    <>
      <Divider marginBottom="size-250" marginTop="size-400" size="S" />
      <Text marginBottom="size-0" marginTop="size-50" UNSAFE_style={{fontWeight: 'bold'}}>
        {intl.formatMessage({
          id: 'packages.createServer.configure.entitlementLimitsTitle',
        })}
      </Text>
      <Text marginBottom="size-150" UNSAFE_style={{fontSize: 'size-175'}}>
        {intl.formatMessage({id: 'packages.createServer.configure.entitlementLimitsDesc'})}
      </Text>
      <RadioGroup
        aria-label="entitlementQuotaGroup"
        isEmphasized
        marginTop="size-125"
        onChange={quotaChange}
        value={serverSessionData.activationEnforcementType}
      >
        <Grid columnGap="size-250" columns={['1fr', '1fr']}>
          <Radio value={SERVERS_CONSTANTS.QUOTA.SOFT}>
            <Flex direction="column">
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'packages.createServer.configure.softQuota',
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'packages.createServer.configure.softQuotaDesc',
                })}
              </Text>
            </Flex>
          </Radio>

          <Radio value={SERVERS_CONSTANTS.QUOTA.HARD}>
            <Flex direction="column">
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'packages.createServer.configure.hardQuota',
                })}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'packages.createServer.configure.hardQuotaDesc',
                })}
              </Text>
            </Flex>
          </Radio>
        </Grid>
      </RadioGroup>
    </>
  );
});
export default EntitlementLimits;
