(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.main.jobs').constant('JOB_TYPE', {
    PROFILES: 'PROFILES',
    USER_GROUPS: 'USER_GROUPS',
    USERS: 'USERS',
  });
})();
