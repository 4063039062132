import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import {groupPropType} from '../prop-types/configurationGroupsPropTypes';

const EditProductProfilePermissionsContext = createContext({});

/**
 * @description A hook to get the EditProductProfilePermissionsContext values.
 * @returns EditProductProfilePermissionsContext
 */
const useEditProductProfilePermissionsContext = () =>
  useContext(EditProductProfilePermissionsContext);

const EditProductProfilePermissionsContextProvider = ({
  children,
  configurationGroups,
  initialGroupId,
  licenseGroupName,
  onClose,
  onSave,
  productName,
}) => {
  const value = useMemo(
    () => ({
      children,
      configurationGroups,
      initialGroupId,
      licenseGroupName,
      onClose,
      onSave,
      productName,
    }),
    [children, configurationGroups, initialGroupId, licenseGroupName, onClose, onSave, productName]
  );
  return (
    <EditProductProfilePermissionsContext.Provider value={value}>
      {children}
    </EditProductProfilePermissionsContext.Provider>
  );
};

EditProductProfilePermissionsContextProvider.propTypes = {
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * The array of configuration groups to edit.
   */
  configurationGroups: PropTypes.arrayOf(groupPropType).isRequired,
  /**
   * An optional id of the configuration group to initially show, rather than the default of the first one in configurationGroups.
   */
  initialGroupId: PropTypes.string,
  /**
   * The name of the license group.
   */
  licenseGroupName: PropTypes.string.isRequired,
  /**
   * The callback function to run on close.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The callback function to run on save. Takes a single argument of the modified configuration groups.
   */
  onSave: PropTypes.func.isRequired,
  /**
   * The name of the product. This is used for the text of the help link on the PermissionsIncludeListForm.
   */
  productName: PropTypes.string.isRequired,
};

export {EditProductProfilePermissionsContextProvider, useEditProductProfilePermissionsContext};
