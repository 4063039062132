import PropTypes from 'prop-types';
import React from 'react';

import {TrusteeListContextProvider} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import TrusteeListTable from 'features/settings/common/components/trustee-list-table/TrusteeListTable';

import useDirectoryTrusteeListState from './hooks/useDirectoryTrusteeListState';

const DirectoryTrustees = ({directoryId}) => {
  const listState = useDirectoryTrusteeListState(directoryId);

  return (
    <TrusteeListContextProvider listState={listState}>
      <TrusteeListTable />
    </TrusteeListContextProvider>
  );
};

DirectoryTrustees.propTypes = {
  directoryId: PropTypes.string,
};

export default DirectoryTrustees;
