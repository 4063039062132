import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Heading, Image, View} from '@adobe/react-spectrum';
import {Card, CardBody, CardFooter} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ToolsButton from '../tools-button/ToolsButton';

// Cards on ToolsPage where each card refers to individual tools and its details
const ToolsCard = ({
  details,
  downloadButton,
  downloadUrlLinux,
  downloadUrlMac,
  downloadUrlMacArm,
  downloadUrlMacUniversal,
  downloadUrlWin,
  downloadUrlWinArm,
  icons,
  isSatDownload = false,
  name,
  showErrorPage,
  showLearnMore = true,
  title,
  version,
  ...toolsCardProps
}) => {
  const intl = useIntl();
  return (
    <Card allowsSelection={false} data-testid={title} size="S">
      <CardBody marginEnd="size-300" marginStart="size-300">
        <View minHeight="calc(size-3000 + size-225)">
          <Flex width="size-2400">
            <Image alt="" height="size-500" marginBottom="size-200" src={icons} width="size-500" />
          </Flex>
          <Heading
            level={3}
            marginBottom="size-50"
            marginTop="size-0"
            UNSAFE_style={{color: '#2c2c2c'}}
          >
            {title}
          </Heading>
          <View maxHeight="size-1600" overflow="auto" UNSAFE_style={{color: '#6e6e6e'}}>
            {details}
          </View>
          {showLearnMore && (
            <View data-testid={name}>
              <GoUrl aria-label={toolsCardProps['aria-label']} name={name}>
                {intl.formatMessage({
                  id: 'packages.tools.adminTools.learnMore',
                })}
              </GoUrl>
            </View>
          )}
        </View>
      </CardBody>

      <CardFooter>
        <Flex justifyContent="end" marginTop="size-40">
          <ToolsButton
            cardButton={downloadButton}
            cardTitle={title}
            downloadUrlLinux={downloadUrlLinux}
            downloadUrlMac={downloadUrlMac}
            downloadUrlMacArm={downloadUrlMacArm}
            downloadUrlMacUniversal={downloadUrlMacUniversal}
            downloadUrlWin={downloadUrlWin}
            downloadUrlWinArm={downloadUrlWinArm}
            isSatDownload={isSatDownload}
            showErrorPage={showErrorPage}
            version={version}
          />
        </Flex>
      </CardFooter>
    </Card>
  );
};
ToolsCard.propTypes = {
  /**
   * The aria label of the learn more link adding to accesibility property
   */
  'aria-label': PropTypes.string.isRequired,
  /**
   * The details of the specific product which is to be found on card
   */
  details: PropTypes.string.isRequired,
  /**
   * The text of individual download button of card which is to be read later
   */
  downloadButton: PropTypes.string.isRequired,
  /**
   * Link which redirects to downloading product for linux platform
   */
  downloadUrlLinux: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC system
   */
  downloadUrlMac: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC ARM system
   */
  downloadUrlMacArm: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC Universal
   */
  downloadUrlMacUniversal: PropTypes.string,
  /**
   * Link which redirects to downloading product for Windows system
   */
  downloadUrlWin: PropTypes.string.isRequired,
  /**
   * Link which redirects to downloading product for Windows ARM system
   */
  downloadUrlWinArm: PropTypes.string,
  /**
   * path for icon on the card for respective product
   */
  icons: PropTypes.string.isRequired,
  /**
   * Boolean value that tells if the button would download SAT
   */
  isSatDownload: PropTypes.bool,
  /**
   * url which redirects to learn more
   */
  name: PropTypes.string.isRequired,
  /**
   * when downloadSAT function fails , a page with bumper is rendered through this prop.
   */
  showErrorPage: PropTypes.func.isRequired,
  /**
   * showLearnMore link is rendered through this prop.
   */
  showLearnMore: PropTypes.bool,
  /**
   * title which is displayed on the card
   */
  title: PropTypes.string.isRequired,
  /**
   * when downloadSAT function calls, its contains the version V2 to download  V2 and undefined for v1
   */
  version: PropTypes.string,
};
export default ToolsCard;
