(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(organizationDetailsConfig);

  /* @ngInject */
  function organizationDetailsConfig(
    $stateProvider,
    ACCOUNT_WORKSPACE_NAV_ID,
    navAssemblerProvider,
    ROLE
  ) {
    $stateProvider.state('account.organization-details', {
      component: 'appOrganizationDetails',
      data: {
        title: 'account.organizationDetails.title',
      },
      url: '/organization-details',
    });

    // add the Organization Details section to the Account side nav
    navAssemblerProvider.configure({
      navID: ACCOUNT_WORKSPACE_NAV_ID,
      navValue: {
        access: {
          callback: ($q, injector) =>
            injector.get('organizationAccess').canViewOrganizationDetails(),
          roles: [ROLE.ADMIN.ORG],
        },
        disabled: false,
        hidden: false,
        name: 'account.organizationDetails.title',
        order: 4,
        state: {
          name: 'account.organization-details',
        },
      },
    });
  }
})();
