import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {Cell, Row, Table, TableBody, TableHeader} from 'common/components/table/Table';
import FederatedDomainsStore from 'features/settings/stores/FederatedDomainsStore';

import FederatedDomainStatus from './FederatedDomainStatus';

import './ImportFederatedDomainsModal.pcss';

const FederatedDomainsTable = observer(({store}) => {
  const intl = useIntl();

  const onGoNext = () => {
    store.goNext();
  };

  const onGoPrevious = () => {
    store.goPrevious();
  };

  const onPageSizeChange = (value) => {
    store.changePageSize(value);
  };

  const onSelectAll = () => {
    store.selectAll();
  };

  const onSelectDomain = (domain) => {
    store.selectItem(domain);
  };

  return (
    <Table
      currentPage={store.currentPage}
      loading={store.loading}
      onGoNext={onGoNext}
      onGoPrevious={onGoPrevious}
      onPageSizeChange={onPageSizeChange}
      pageSize={store.pageSize}
      paginated
      selectable
      styleName="domains-table"
      totalPages={store.totalPages}
    >
      <TableHeader>
        <Cell
          checkboxDisabled={!store.canSelectAll}
          data-test-id="name-header"
          onSelect={onSelectAll}
          selectable={store.canSelectAll}
          selectionStatus={store.selectionStatus}
        >
          {intl.formatMessage({id: 'settings.directoryDomains.nameHeader'})}
        </Cell>
        <Cell data-test-id="status-header">
          {intl.formatMessage({id: 'settings.directoryDomains.statusHeader'})}
        </Cell>
      </TableHeader>
      <TableBody>
        {store.rows.map((row) => (
          <Row
            key={row.id}
            onClick={() => onSelectDomain(row.data.name)}
            selectable={row.selectable}
            selected={row.selected}
          >
            <Cell>{row.data.name}</Cell>
            <Cell>
              <FederatedDomainStatus
                detailedStatus={row.data.detailedStatus}
                status={row.data.status}
              />
            </Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  );
});

FederatedDomainsTable.propTypes = {
  store: PropTypes.instanceOf(FederatedDomainsStore),
};

export default FederatedDomainsTable;
