(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc provider and service
   * @name app.core.auth
   * @description Auth provider and service
   */
  angular.module('app.core.auth').provider('auth', authProvider);

  /* @ngInject */
  function authProvider() {
    this.$get = $get;

    ////////////

    /* @ngInject */
    function $get(_, authentication, binkySrc2, feature, ROLE) {
      let activeOrgId,
        activeOrgUserGroupRoles,
        activeOrgUserPLCRoles,
        activeOrgUserProductRoles,
        activeOrgUserRoles;

      const service = {
        canAddUsers,
        canExportDevices,
        canUpdateOrg,
        canUpdatePLC,
        canUpdateUserGroup,
        canViewNotesSection,
        canViewUserDetails,
        getHighestRole,
        getUserId,
        getUserProfile: authentication.getUserProfile,
        hasAnyRole,
        isUserAdobeAgent,
        isUserContractAdmin,
        isUserDeploymentAdmin,
        isUserOrgAdmin,
        isUserPlcAdmin,
        isUserPlcAdminForPLCContext,
        isUserProductAdmin,
        isUserProductAdminForTarget,
        isUserProductSupportAdmin,
        isUserStorageAdmin,
        isUserSupportDelegate,
        isUserUserGroupAdmin,
        isUserUserGroupAdminForUserGroup,
        setActiveOrganization,
        setUserUserGroupAdminForUserGroup,
      };

      return service;

      ////////////

      function canAddUsers() {
        return (
          isUserOrgAdmin() || isUserContractAdmin() || isUserPlcAdmin() || isUserProductAdmin()
        );
      }

      function canExportDevices() {
        return (
          isUserOrgAdmin() ||
          isUserDeploymentAdmin() ||
          isUserSupportDelegate() ||
          isUserProductSupportAdmin()
        );
      }

      function canUpdateOrg() {
        return isUserOrgAdmin();
      }

      function canUpdatePLC(options = {}) {
        const {productTargetExpression, productProfileId} = options;
        return (
          isUserOrgAdmin() ||
          isUserProductAdminForTarget(productTargetExpression) ||
          isUserPlcAdminForPLCContext(productProfileId)
        );
      }

      function canViewNotesSection() {
        return isUserOrgAdmin();
      }

      function canViewUserDetails() {
        return (
          isUserOrgAdmin() ||
          isUserProductAdmin() ||
          isUserPlcAdmin() ||
          isUserUserGroupAdmin() ||
          isUserStorageAdmin() ||
          isUserSupportDelegate() ||
          isUserProductSupportAdmin()
        );
      }

      /**
       * @returns {Boolean} true - if the user can create/update a user group
       */
      function canUpdateUserGroup() {
        return isUserOrgAdmin() || isUserProductAdmin() || isUserPlcAdmin();
      }

      function getHighestRole() {
        if (isUserOrgAdmin()) {
          return ROLE.ADMIN.ORG;
        } else if (isUserContractAdmin()) {
          return ROLE.ADMIN.CONTRACT;
        } else if (isUserProductAdmin()) {
          return ROLE.ADMIN.PRODUCT;
        } else if (isUserDeploymentAdmin()) {
          return ROLE.ADMIN.DEPLOYMENT;
        } else if (isUserSupportDelegate()) {
          return ROLE.ADMIN.SUPPORT;
        } else if (isUserProductSupportAdmin()) {
          return ROLE.ADMIN.PRODUCT_SUPPORT;
        } else if (isUserPlcAdmin()) {
          return ROLE.ADMIN.LICENSE;
        } else if (isUserUserGroupAdmin()) {
          return ROLE.ADMIN.USER_GROUP;
        }
        return ROLE.NONE;
      }

      function getUserId() {
        return _.get(authentication.getUserProfile(), 'userId');
      }

      function hasAnyRole(roles) {
        // eslint-disable-next-line complexity -- included additional condition for feature flag 'temp_adobe_agent_access'
        return _.some(roles, (role) => {
          let hasRole;

          switch (role) {
            case ROLE.ADMIN.ORG:
              hasRole = isUserOrgAdmin();
              break;
            case ROLE.ADMIN.CONTRACT:
              hasRole = isUserContractAdmin();
              break;
            case ROLE.ADMIN.DEPLOYMENT:
              hasRole = isUserDeploymentAdmin();
              break;
            case ROLE.ADMIN.LICENSE:
              hasRole = isUserPlcAdmin();
              break;
            case ROLE.ADMIN.PRODUCT:
              hasRole = isUserProductAdmin();
              break;
            case ROLE.ADMIN.PRODUCT_SUPPORT:
              hasRole = isUserProductSupportAdmin();
              break;
            case ROLE.ADMIN.STORAGE:
              hasRole = isUserStorageAdmin();
              break;
            case ROLE.ADMIN.SUPPORT:
              hasRole = isUserSupportDelegate();
              break;
            case ROLE.ADMIN.USER_GROUP:
              hasRole = isUserUserGroupAdmin();
              break;
            case ROLE.AGENT.ADOBE_AGENT_ADMIN:
            case ROLE.AGENT.ADOBE_AGENT_CUSTOMER_CARE:
            case ROLE.AGENT.ADOBE_AGENT_PROFESSIONAL_SERVICES:
            case ROLE.AGENT.ADOBE_AGENT_PROVISIONER:
            case ROLE.AGENT.ADOBE_AGENT_READ:
            case ROLE.AGENT.ADOBE_AGENT_RESELLER_LICENSING:
              hasRole = isUserAdobeAgent();
              break;
            default:
              hasRole = false;
          }

          return hasRole;
        });
      }

      // convenient methods to check an user's role using
      // https://wiki.corp.adobe.com/x/9sLfPg
      function isUserDeploymentAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.DEPLOYMENT_ADMIN);
      }

      function isUserAdobeAgent() {
        return (
          feature.isEnabled('temp_adobe_agent_access') &&
          binkySrc2.models.user.UserRoles.isAnyAdobeAgent()
        );
      }

      function isUserOrgAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.org_admin);
      }

      function isUserPlcAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.LICENSE_ADMIN);
      }

      function isUserPlcAdminForPLCContext(plcContextId) {
        return !!(
          activeOrgUserPLCRoles &&
          Object.prototype.hasOwnProperty.call(activeOrgUserPLCRoles, plcContextId)
        );
      }

      function isUserContractAdmin() {
        return !!(
          activeOrgUserRoles &&
          activeOrgUserRoles.CONTRACT_ADMIN &&
          feature.isEnabled('temp_contract_admin_role')
        );
      }

      function isUserProductAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.PRODUCT_ADMIN);
      }

      function isUserProductSupportAdmin() {
        return (
          feature.isEnabled('temp_parkour_mm') &&
          !!(activeOrgUserRoles && activeOrgUserRoles.PRODUCT_SUPPORT_ADMIN)
        );
      }

      function isUserStorageAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.STORAGE_ADMIN);
      }

      function isUserProductAdminForTarget(targetExpression) {
        return !!(
          activeOrgUserProductRoles &&
          Object.prototype.hasOwnProperty.call(activeOrgUserProductRoles, targetExpression)
        );
      }

      function isUserSupportDelegate() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.SUPPORT_ADMIN);
      }

      function isUserUserGroupAdmin() {
        return !!(activeOrgUserRoles && activeOrgUserRoles.USER_GROUP_ADMIN);
      }

      function isUserUserGroupAdminForUserGroup(userGroupId) {
        return !!(
          activeOrgUserGroupRoles &&
          Object.prototype.hasOwnProperty.call(activeOrgUserGroupRoles, userGroupId)
        );
      }

      /**
       * @description cache a user group admin role locally. This exists because IMS can
       * take a few minutes to invalidate their cache, and so for product/plc admins they
       * are left with no way to manipulate the user group they just made. We know the server
       * will enforce the real rule, so there's no escalation danger.
       *
       * @param {String} userGroupId - the user group to make this user an admin for
       * @returns {void}
       */
      function setUserUserGroupAdminForUserGroup(userGroupId) {
        if (!activeOrgUserRoles) {
          activeOrgUserRoles = {};
        }
        activeOrgUserRoles.USER_GROUP_ADMIN = true;
        if (!activeOrgUserGroupRoles) {
          activeOrgUserGroupRoles = {};
        }
        activeOrgUserGroupRoles[userGroupId] = true;
      }

      function processOrgRoles(orgId) {
        let userGroupRolesByOrg, userPLCRolesByOrg, userProductRolesByOrg, userRolesByOrg;
        if (!userRolesByOrg) {
          userGroupRolesByOrg = {};
          userPLCRolesByOrg = {};
          userProductRolesByOrg = {};
          userRolesByOrg = {};
          const profile = authentication.getUserProfile();
          _.forEach(profile.roles, (role) => {
            const org = role.organization;
            if (!userRolesByOrg[org]) {
              userRolesByOrg[org] = {};
            }
            userRolesByOrg[org][role.named_role] = true;
            // Product specific admin roles
            if (role.named_role === ROLE.ADMIN.PRODUCT) {
              if (!userProductRolesByOrg[org]) {
                userProductRolesByOrg[org] = {};
              }
              userProductRolesByOrg[org][role.target] = true;
            } else if (role.named_role === ROLE.ADMIN.USER_GROUP) {
              if (!userGroupRolesByOrg[org]) {
                userGroupRolesByOrg[org] = {};
              }
              userGroupRolesByOrg[org][_.split(role.target, ':')[1]] = true;
            } else if (role.named_role === ROLE.ADMIN.LICENSE) {
              if (!userPLCRolesByOrg[org]) {
                userPLCRolesByOrg[org] = {};
              }
              userPLCRolesByOrg[org][_.split(role.target, ':')[1]] = true;
            }
          });
        }
        activeOrgUserGroupRoles = userGroupRolesByOrg[orgId] || {};
        activeOrgUserPLCRoles = userPLCRolesByOrg[orgId] || {};
        activeOrgUserProductRoles = userProductRolesByOrg[orgId] || {};
        activeOrgUserRoles = userRolesByOrg[orgId] || {};
      }

      function setActiveOrganization(orgId) {
        // we fetch the roles when we set the org (if we haven't already)
        activeOrgId = orgId;
        if (authentication.isUserSignedIn()) {
          processOrgRoles(activeOrgId);
        }
      }
    }
  }
})();
