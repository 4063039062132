import PropTypes from 'prop-types';
import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {AccessRequestListContextProvider} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import {DomainsListContextProvider} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {TrusteeListContextProvider} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import useAccessRequestListState from 'features/settings/components/access-requests/hooks/useAccessRequestListState';
import {DirectoryListContextProvider} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import useDirectoryListState from 'features/settings/components/directories/directory-list/hooks/useDirectoryListState';
import useOrganizationDomainListState from 'features/settings/components/domains/hooks/useOrganizationDomainListState';
import useTrusteeListState from 'features/settings/components/trustee/hooks/useTrusteeListState';

/**
 * The IdentityContext is a provider component for all contexts created with createCustomContext.
 */
const IdentityContext = ({children}) => {
  // load the initial data from IdentityRoutesLoaders
  const {directories, canAddDomains, domains, trusts, accessRequests, canCreateDirectory} =
    useLoaderData();

  const directoryList = useDirectoryListState(directories);
  const domainsList = useOrganizationDomainListState(domains);
  const trustList = useTrusteeListState(trusts);
  const accessRequestList = useAccessRequestListState(accessRequests);

  return (
    <DirectoryListContextProvider listState={{...directoryList, canCreateDirectory}}>
      <DomainsListContextProvider listState={{...domainsList, canAddDomains}}>
        <TrusteeListContextProvider listState={trustList}>
          <AccessRequestListContextProvider listState={accessRequestList}>
            {children}
          </AccessRequestListContextProvider>
        </TrusteeListContextProvider>
      </DomainsListContextProvider>
    </DirectoryListContextProvider>
  );
};

IdentityContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IdentityContext;
