(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.organization-type-form').component('appK12Info', {
    controller,
    templateUrl:
      'app/widgets/organizations/organization-type-form/k12-info/k12-info.component.html',
  });

  /* @ngInject */
  function controller(_, t2eMigrationManager) {
    const vm = this;
    _.assign(vm, {
      isT2E: t2eMigrationManager.isT2EClean(),
      moreInfoUrl: 'primary-secondary-terms',
    });
  }
})();
