import {ActionButton, Flex, Text} from '@adobe/react-spectrum';
import Checkbox from '@react/react-spectrum/Checkbox/js/Checkbox';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Popover should be replaced with v3 Dialog
import Popover from '@react/react-spectrum/Popover';
import Add from '@spectrum-icons/workflow/Add';
import Edit from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import AddOnsSummary from '../add-ons/add-ons-summary/AddOnsSummary';

import style from './AddOnsV2.pcss';

/**
 * @description AddOns component gives functionality to select add-ons if there are any available for a product.
 * This component is used is Angular workflow of create package. It is needed because V3 react-spectrum components do not work in Angular.
 *  @param {String} productKey - product key of the product for which we are showing add-ons
 *  @param {Array} productAddOns - Array of objects contaning information related to add-ons for a product
 *  @param {Function} setSelectedProductAddOnsMap - Function to set selectedProductAddOnsMap in pacakge create object for a product
 * @deprecated This component is being replaced by features/packages/components/create-package-modal/screens/choose-apps/add-ons/AddOns.jsx
 */
const AddOnsV2 = ({productKey, productAddOns, setSelectedProductAddOnsMap}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [addOnsList] = useState(
    productAddOns?.map((productAddOn) => ({
      checked: false,
      displayName: productAddOn.displayName,
      id: productAddOn.id,
    })) ?? []
  );
  const [addOnsSelectedList, setAddOnsSelectedList] = useState([]);

  function onAddOnsChange(changedAddOn) {
    changedAddOn.checked = !changedAddOn.checked;
    setAddOnsSelectedList(addOnsList.filter((addOn) => addOn.checked));
  }

  // true if all of the checkboxes are checked
  const isSelectAllChecked = () =>
    addOnsSelectedList.length > 0 && addOnsSelectedList.length === addOnsList.length;

  // true if some, but not all, of the checkboxes are checked
  const isSelectStateMixed = () =>
    addOnsSelectedList.length > 0 && addOnsSelectedList.length < addOnsList.length;

  function onSelectAll() {
    const newValue = isSelectStateMixed() || !isSelectAllChecked();
    addOnsList.forEach((addOnTemp) => {
      addOnTemp.checked = newValue;
    });
    setAddOnsSelectedList(newValue ? addOnsList : []);
  }

  // Update selectedProductAddOnsMap every time addOnsSelectedListchanges
  useEffect(() => {
    setSelectedProductAddOnsMap(productKey, addOnsSelectedList);
  }, [addOnsSelectedList, productKey, setSelectedProductAddOnsMap]);

  return (
    <Flex data-testid="add-ons-container" direction="column" marginStart={4}>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for add add-ons heading */}
      <Text marginBottom="size-100" UNSAFE_className={style['add-ons-add-button-heading-text']}>
        {intl.formatMessage({
          id: 'packages.createPackage.addOns.addOnsTitle',
        })}
      </Text>
      <OverlayTrigger
        onHide={() => {
          setIsOpen(false);
        }}
        placement="bottom left"
        show={isOpen}
        trigger="click"
      >
        <ActionButton
          aria-controls="addOns"
          aria-expanded={isOpen}
          aria-label={
            addOnsSelectedList.length > 0
              ? intl.formatMessage({id: 'packages.createPackage.addOns.editButtonLabel'})
              : intl.formatMessage({id: 'packages.createPackage.addOns.addButtonLabel'})
          }
          data-testid="add-ons-button"
          height="size-300"
          isDisabled={addOnsList?.length === 0}
          maxWidth="size-2000"
          onPress={() => {
            setIsOpen(true);
          }}
          UNSAFE_style={{width: 'fit-content'}}
        >
          {addOnsSelectedList.length > 0 ? (
            <Edit height="size-130" width="size-130" />
          ) : (
            <Add height="size-130" width="size-130" />
          )}
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for button text*/}
          <Text UNSAFE_className={style['add-ons-add-button-text']}>
            {addOnsSelectedList.length > 0
              ? intl.formatMessage({id: 'packages.createPackage.addOns.editButtonLabel'})
              : intl.formatMessage({id: 'packages.createPackage.addOns.addButtonLabel'})}
          </Text>
        </ActionButton>
        <Popover className={style['add-ons-popover']} id="addOns">
          <Flex
            alignItems="center"
            height="size-450"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for select all checkbox
            UNSAFE_className={style['add-ons-select-all-checkbox-container']}
          >
            <Checkbox
              checked={isSelectAllChecked()}
              indeterminate={isSelectStateMixed()}
              onChange={onSelectAll}
            >
              <span className={style['add-ons-select-all-text']}>
                {intl.formatMessage({id: 'packages.createPackage.addOns.selectAllLabel'})}
              </span>
            </Checkbox>
          </Flex>
          <div className={style['add-ons-list-container']}>
            <Flex
              data-testid="add-ons-checkboxes"
              direction="column"
              marginStart="18px"
              maxHeight="136px"
            >
              {addOnsList.map((addOn) => (
                <Checkbox
                  key={addOn.id}
                  checked={addOn.checked}
                  onChange={() => onAddOnsChange(addOn)}
                >
                  {addOn.displayName}
                </Checkbox>
              ))}
            </Flex>
          </div>
        </Popover>
      </OverlayTrigger>
      {addOnsSelectedList.length > 0 && (
        <AddOnsSummary
          data-testid="add-ons-summary"
          selectedAddOnsList={addOnsSelectedList}
          showHeading={false}
        />
      )}
    </Flex>
  );
};

AddOnsV2.propTypes = {
  /**
   * Array of objects contaning information related to add-ons for a product. Defaults to []
   */
  productAddOns: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  /**
   * String representing the product name
   */
  productKey: PropTypes.string.isRequired,
  /**
   * Function to set selectedProductAddOnsMap in pacakge create object for a product
   */
  setSelectedProductAddOnsMap: PropTypes.func.isRequired,
};

export default AddOnsV2;
