(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.adobe-status')
    .constant('ADOBE_STATUS_CONSTANTS', {
      // values come from https://git.corp.adobe.com/adobe-status/statusapi/blob/master/pkg/status/pb/status.proto
      // disable sort-keys to keep the values in status-value order, to match the source file above
      /* eslint-disable sort-keys */
      EVENT_STATE: {
        NOSTATE: 0,
        ONGOING: 1,
        REPAIRED: 2,
        ANNOUNCED: 3,
        RESOLVED: 4,
        EVENT_CANCELED: 5,
        DOWNGRADED: 6,
        DISMISSEDSTATE: 7,
      },
      EVENT_TYPE: {
        NONE: 0,
        MAJOR: 1,
        MINOR: 2,
        NORMAL: 3,
        MAINTENANCE: 4,
        DISCOVERY: 5,
      },
      IMPACT_TYPE: {
        NO_IMPACT: 0,
        AVAILABILITY: 1,
        PERFORMANCE: 2,
        UNKNOWN: 3,
      },
      MESSAGE_TYPE: {
        OCCURRED: 0,
        DETECTED: 1,
        SEV_CHANGED: 2,
        FIXED: 3,
        CLOSED: 4,
        CREATED: 5,
        STARTED: 6,
        CANCELED: 7,
        REOPENED: 8,
        SEV_DOWNGRADED: 9,
        CUSTOM_MESSAGE: 10,
        DISCOVERED: 11,
        DISMISSEDMESSAGETYPE: 12,
        CFS: 13,
      },
      POLL_FREQUENCY: 300000, // 5 minutes
      // This threshold should be big enough to find resolved events that a user might
      // have encountered and be checking the console to find out about, but not so large
      // as to show very many old events.
      RECENT_HISTORY_THRESHOLD: 3600000, // 1 hour
      /* eslint-enable sort-keys */
    })
    .constant('ADOBE_STATUS_MESSAGES', {
      ERROR: 'AdobeStatusError',
      INVALIDATE: 'AdobeStatusInvalidate',
      REFRESH: 'AdobeStatusRefresh',
    });
})();
