(function () {
  /**
   * @ngdoc module
   * @name app.widgets.account.authenticating-user
   * @description module that contains the authenticating-user module
   */
  angular.module('app.widgets.account.authenticating-user', [
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'binky.widgets.common.confirmation-modal',
    'pascalprecht.translate',
  ]);
})();
