const help = {
  BULK_OPERATION_MODE: {
    ADD: 'ADD',
    EDIT: 'EDIT',
    OFFER_SWITCH_MIGRATION: 'OFFER_SWITCH_MIGRATION',
    REMOVE: 'REMOVE',
    SWITCH_IDENTITY_TYPE: 'SWITCH_IDENTITY_TYPE',
    UNASSIGN: 'UNASSIGN',
  },
  GLOBAL_MODAL_BINDING_TYPE: {
    DATA: 'Data',
    FUNCTION: 'Function',
    STRING: 'String',
  },
  HELP_ITEM_TYPE: {
    INFO: 'help-item-info',
    STEP: 'help-item-step',
  },
  HELP_RAIL_HEADING_ID: 'help-rail-heading',
  HELP_RAIL_ID: 'help-rail',
  LINK_TARGET_TYPE: {
    APPLICATION: 'link-target-application',
    APPLICATION_MODAL: 'link-target-application-modal',
    EXTERNAL: 'link-target-external',
    GLOBAL_MODAL: 'link-target-global-modal',
    GO_URL: 'link-target-go-url',
    GO_URL_UNLOCALIZED: 'link-target-go-url-unlocalized',
    HELP: 'link-target-help',
    MODAL: 'link-target-modal',
  },
  LINK_TYPE: {
    BUTTON: 'link-button',
    ICON: 'link-icon',
    TAB: 'link-tab',
    TEXT: 'link-text',
  },
  ON_CLOSE_FOCUS_ELEMENT_ID: 'help-rail-header-toggle',
  TOPICS: {
    ADMIN_CONSOLE: 'admin-console',
    AUTO_ASSIGNED_PRODUCT_PREFIX: 'auto-assigned-product-',
    CREATE_PACKAGES: 'create-packages',
    ENTERPRISE_ADD_ADMINS: 'enterprise-add-admins',
    ENTERPRISE_ADD_USERS: 'enterprise-add-users',
    ENTERPRISE_CREATE_PROFILES: 'enterprise-create-profiles',
    ENTERPRISE_CREATE_USER_GROUPS: 'enterprise-create-user-groups',
    ENTERPRISE_DOMAINS: 'enterprise-domains',
    ENTERPRISE_MARKETING_CLOUD_PREFIX: 'enterprise-marketing-cloud',
    ENTERPRISE_MARKETING_CLOUD_PREFIX2: 'enterprise-marketing-cloud2',
    ENTERPRISE_SIGN: 'enterprise-sign',
    ENTERPRISE_SIGN2: 'enterprise-sign-2',
    ENTERPRISE_STOCK: 'enterprise-stock',
    ENTERPRISE_UMAPI: 'enterprise-umapi',
    EVIP_LICENSE_MIGRATION: 'evip-license-migration',
    GET_STARTED: 'get-started',
    GET_SUPPORT: 'get-support',
    LICENSE_MIGRATION_AUTO: 'license-migration-auto',
    LICENSE_MIGRATION_MANUAL: 'license-migration-manual',
    MANAGE_API_PRODUCTS: 'manage-api-products',
    MANAGE_SDL: 'manage-sdl',
    OFFICE_365_INTEGRATION: 'office-365-integration',
    PRODUCTS: 'products',
    SDL_FAQ: 'sdl-faq',
    SDL_HOW_TO_MANAGE: 'sdl-how-to-manage',
    SDL_SET_UP: 'sdl-set-up',
    TEAM_ADD_ADMIN: 'team-add-admin',
    TEAM_ADD_PRODUCTS: 'team-add-products',
    TEAM_ADD_USERS: 'team-add-users',
    TEAM_SIGN: 'team-sign',
    TEAM_SIGN2: 'team-sign-2',
    TEAM_STOCK: 'team-stock',
    XD_FOR_FREE: 'xd-for-free',
  },
};

export default help;
