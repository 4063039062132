(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories.azure')
    .constant('AZURE_GROUPS_RETRY_GET_EVENT', 'azureGroups:retry')
    .constant('AZURE_GROUPS_SAVE_EVENT', 'azureGroups:save')
    .constant('AZURE_GROUPS_ERROR_TYPES', {
      FETCH_ERROR: 'fetchError',
      SUBMIT_ERROR: 'submitError',
    });
})();
