import {View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import {CREATE_PACKAGE} from '../../../../packagesConstants';

import AvailableApps from './available-apps/AvailableApps';
import SelectedApps from './selected-apps/SelectedApps';

/**
 * @description  Stop event propagation when search field is active element
 * and has text and also when popover is open.
 * @param {Object} event the event object
 *
 */
const handleKeyDown = (event, enableAppVersionsPopoverId, sysReqPopoverId) => {
  if (
    event.key === 'Escape' &&
    ((document.activeElement &&
      document.activeElement.type === 'search' &&
      document.activeElement.value !== '') ||
      document.querySelector(`[id=${enableAppVersionsPopoverId}]`) ||
      document.querySelector(`[id=${sysReqPopoverId}]`))
  ) {
    event.stopPropagation();
  }
};

const ChooseApps = ({
  adobeProductsUi,
  defaultExtensionsSetting,
  defaultPackageRumSetting,
  isEnterpriseSupportPolicyApplicable,
  packageCreateObj,
  pkgSessionSettings,
  propagateChangesToContainerView,
  selectedProducts,
}) => {
  // ids
  const enableAppVersionsPopoverId = useId();
  const sysReqPopoverId = useId();

  // To stop create package modal from closing, when search field is active element and has text.
  useEffect(() => {
    // Elements of shell should not be read by voice over
    document.querySelector("[class='shell rail-enabled']")?.setAttribute('aria-hidden', 'true');
    document.querySelector("[id='create-package-modal']")?.addEventListener('keydown', (event) => {
      handleKeyDown(event, enableAppVersionsPopoverId, sysReqPopoverId);
    });
    return () => {
      document.querySelector("[class='shell rail-enabled']")?.setAttribute('aria-hidden', 'false');
      document
        .querySelector("[id='create-package-modal']")
        ?.removeEventListener('keydown', (event) => {
          handleKeyDown(event, enableAppVersionsPopoverId, sysReqPopoverId);
        });
    };
  });

  // refs
  const selectedAppsSearchBtnRef = useRef(null);
  const selectedAppsSearchFieldRef = useRef(null);

  // shared states
  const packageCreateObject = packageCreateObj;
  const packageSessionSettings = pkgSessionSettings;

  function focusPreviousButton() {
    document
      .querySelector("[class='previous-button ng-binding _coral-Button _coral-Button--secondary']")
      ?.focus();
  }

  function updateNextButtonState(someProductSelected) {
    if (
      packageSessionSettings.packagingMode === CREATE_PACKAGE.MODE.NAMED &&
      selectedProducts.length === 0
    ) {
      packageSessionSettings.nextButtonEnabled = false;
    } else if (
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED &&
      (!packageSessionSettings.frlIsolatedMachineCodes ||
        packageSessionSettings.frlIsolatedMachineCodes.length === 0)
    ) {
      packageSessionSettings.nextButtonEnabled = someProductSelected;
    } else if (
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN
    ) {
      packageSessionSettings.nextButtonEnabled =
        someProductSelected || packageSessionSettings.licenseFileSelected;
    } else {
      packageSessionSettings.nextButtonEnabled = true;
    }
    propagateChangesToContainerView();
  }

  function updateManagementSettings() {
    let someProductSelected = false;
    if (packageSessionSettings.packagingMode === CREATE_PACKAGE.MODE.FRL) {
      someProductSelected = adobeProductsUi.some((product) => product.visible && product.selected);
      packageCreateObject.rumEnabled = false;
      packageCreateObject.enableExtenstions = false;
      // if its a license only package, all config options should be false by default
      // otherwise based on UI constants
      if (
        (packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
          packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN) &&
        someProductSelected
      ) {
        packageCreateObject.rumEnabled = defaultPackageRumSetting;
        packageCreateObject.enableExtenstions = defaultExtensionsSetting;
        if (!packageCreateObject.enableExtenstions) {
          packageSessionSettings.enableAalExtension = false;
        }
      } else if (
        packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED &&
        someProductSelected
      ) {
        packageCreateObject.enableExtenstions = defaultExtensionsSetting;
        if (!packageCreateObject.enableExtenstions) {
          packageSessionSettings.enableAalExtension = false;
        }
      } else {
        packageSessionSettings.enableAalExtension = false;
      }
    }
    updateNextButtonState(someProductSelected);
  }

  function showLicenseFileEntry() {
    return (
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.SDL ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN ||
      (packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ISOLATED &&
        packageSessionSettings.frlIsolatedMachineCodes?.length > 0) ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE
    );
  }

  return (
    <View margin="auto" marginTop="size-400" width="800px">
      <AvailableApps
        adobeProductsUi={adobeProductsUi}
        enableAppVersionsPopoverBtnId={enableAppVersionsPopoverId}
        isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
        pkgSessionSettings={pkgSessionSettings}
        propagateChangesToContainerView={propagateChangesToContainerView}
        selectedAppsSearchBtnRef={selectedAppsSearchBtnRef}
        selectedAppsSearchFieldRef={selectedAppsSearchFieldRef}
        selectedProducts={selectedProducts}
        showLicenseFileEntry={showLicenseFileEntry}
        updateManagementSettings={updateManagementSettings}
      />
      <SelectedApps
        focusPreviousButton={focusPreviousButton}
        isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
        packageCreateObject={packageCreateObject}
        pkgSessionSettings={pkgSessionSettings}
        propagateChangesToContainerView={propagateChangesToContainerView}
        selectedAppsSearchBtnRef={selectedAppsSearchBtnRef}
        selectedAppsSearchFieldRef={selectedAppsSearchFieldRef}
        selectedProducts={selectedProducts}
        showLicenseFileEntry={showLicenseFileEntry}
        systemReqPopoverId={sysReqPopoverId}
        updateManagementSettings={updateManagementSettings}
      />
    </View>
  );
};

ChooseApps.propTypes = {
  /**
   * Exhaustive list of packageable products for the current platform
   */
  adobeProductsUi: PropTypes.instanceOf(Array).isRequired,
  /**
   * Default extensions setting, true if enabled
   */
  defaultExtensionsSetting: PropTypes.bool.isRequired,
  /**
   * Default RUM inclusion setting, true if enabled
   */
  defaultPackageRumSetting: PropTypes.bool.isRequired,
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Shared object for package creation request
   */
  packageCreateObj: PropTypes.instanceOf(Object).isRequired,
  /**
   * Shared object for package creation session
   */
  pkgSessionSettings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Method to push changes to Angular scope
   */
  propagateChangesToContainerView: PropTypes.func.isRequired,
  /**
   * List of currently selected products
   */
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
};

export default ChooseApps;
export {handleKeyDown};
