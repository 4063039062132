(function () {
  /**
   * @ngdoc       module
   * @name        app.core.fraud-mitigation
   * @description Define app-level fraud-mitigation module
   */

  angular.module('app.core.fraud-mitigation', [
    'binky.core.feature',
    'binky.core.fraud-mitigation',
    'binky.core.fraud-mitigation.ready',
    'binky.shell.panels.manager',
    'ui.router',
  ]);
})();
