import './LicenseFile.pcss';
import {ActionButton} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Button to pair with v2 OverlayTrigger
import Button from '@react/react-spectrum/Button';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Tooltip to pair with v2 Popover
import Tooltip from '@react/react-spectrum/Tooltip';
import Add from '@spectrum-icons/workflow/Add';
import Close from '@spectrum-icons/workflow/Close';
import Document from '@spectrum-icons/workflow/Document';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const LicenseFile = ({
  addRemoveOptionVisible,
  isLicenseFileSelected,
  onAddProduct,
  onRemoveProduct,
}) => {
  const intl = useIntl();

  return (
    <table role="presentation" styleName="license-file-container-table">
      <tbody>
        <tr styleName="app-entry">
          <td styleName="app-img">
            <Document size="M" />
          </td>
          <td styleName="app-name-container">
            <div>
              <span styleName="app-name">
                {intl.formatMessage({
                  id: 'packages.createPackage.chooseApps.licenseFile.name',
                })}
              </span>
            </div>
          </td>
          <td styleName="product-actions">
            <OverlayTrigger placement="right" trigger={['hover', 'click']}>
              <Button styleName="license-file-info" variant="tool">
                <InfoOutline
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.chooseApps.licenseFile.infoFRL',
                  })}
                  size="S"
                />
              </Button>
              <Tooltip placement="right" role="tooltip" variant="inspect">
                {addRemoveOptionVisible
                  ? intl.formatMessage({
                      id: 'packages.createPackage.chooseApps.licenseFile.infoFRL',
                    })
                  : intl.formatMessage({
                      id: 'packages.createPackage.chooseApps.licenseFile.infoSDL',
                    })}
              </Tooltip>
            </OverlayTrigger>
            {!isLicenseFileSelected && addRemoveOptionVisible && (
              <ActionButton
                data-testid="add-lic-button"
                isQuiet
                marginEnd="8px"
                onPress={() => {
                  onAddProduct();
                }}
              >
                <Add />
              </ActionButton>
            )}
            {isLicenseFileSelected && addRemoveOptionVisible && (
              <ActionButton
                data-testid="remove-lic-button"
                isQuiet
                marginEnd="18px"
                onPress={() => {
                  onRemoveProduct();
                }}
              >
                <Close />
              </ActionButton>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

LicenseFile.propTypes = {
  /**
   * If add/remove buttons should be visible
   */
  addRemoveOptionVisible: PropTypes.bool.isRequired,
  /**
   * If license file is currently selected or not
   */
  isLicenseFileSelected: PropTypes.bool.isRequired,
  /**
   * Method to add license file
   */
  onAddProduct: PropTypes.func,
  /**
   * Method to remove license file
   */
  onRemoveProduct: PropTypes.func,
};

export default LicenseFile;
