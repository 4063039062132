import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback} from 'react';

import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';

TABLE_SECTION_ACTIONS.FILTER_BY_STATUS = 'filterByStatus';

/**
 * A hook that helps with managing the domain list table
 */
const useDomainsTable = () => {
  const {loadDomainList, tableOptions} = useDomainsListContext();

  const goToPage = useCallback(
    (page) => {
      const updatedTabledOptions = {
        ...tableOptions,
        page,
      };

      loadDomainList(updatedTabledOptions);
    },
    [loadDomainList, tableOptions]
  );

  const search = useCallback(
    ({value}) => {
      const updatedTabledOptions = {
        ...tableOptions,
        // reset the page when searching
        page: 1,
        searchQuery: value,
      };

      loadDomainList(updatedTabledOptions);
    },
    [loadDomainList, tableOptions]
  );

  const sortBy = useCallback(
    ({id, sortedOrder}) => {
      const updatedTabledOptions = {
        ...tableOptions,
        page: 1,
        sortColumn: id,
        sortOrder: sortedOrder,
      };

      loadDomainList(updatedTabledOptions);
    },
    [loadDomainList, tableOptions]
  );

  const onPageSizeChange = useCallback(
    (pageSize) => {
      const updatedTabledOptions = {
        ...tableOptions,
        // always reset the current page to 1 when the page size changes
        page: 1,
        pageSize,
      };

      loadDomainList(updatedTabledOptions);
    },
    [loadDomainList, tableOptions]
  );

  const filterByStatus = useCallback(
    (status) => {
      const params = {
        ...tableOptions,
        page: 1,
        status,
      };

      loadDomainList(params);
    },
    [loadDomainList, tableOptions]
  );

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          search(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          sortBy(payload);
          break;
        case TABLE_SECTION_ACTIONS.FILTER_BY_STATUS:
          filterByStatus(payload);
          break;
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- nothing to test here
        // istanbul ignore next
        default:
        // Do nothing
      }
    },
    [search, goToPage, onPageSizeChange, sortBy, filterByStatus]
  );

  return {
    filterByStatus,
    goToPage,
    onPageSizeChange,
    onTableSectionChange,
    search,
    sortBy,
  };
};

export default useDomainsTable;
export {TABLE_SECTION_ACTIONS};
