(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.main.global-modals').component('appGlobalModals', {
    controller,
    templateUrl: 'app/main/global-modals/global-modals.component.html',
  });

  /* @ngInject */
  function controller(
    $compile,
    $document,
    $element,
    $rootScope,
    $scope,
    _,
    DEFAULT_GLOBAL_MODAL_ID,
    GLOBAL_MODAL_BINDING_TYPE,
    GLOBAL_MODALS,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        onInitialized,
        reset,
      });

      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(GLOBAL_MODALS.OPEN, handleOpenGlobalModalEvent);
    }

    /**
     * Handle events to initialize global modals
     * @param {String} event The event associated with open modal
     * @param {Object} props The props passed in for the modal
     * @param {String} props.componentName The required component name to initialize
     * @param {Object} [props.componentBindings] Specified bindings for the component
     * @param {Object} [props.modalParams] - Optional params pertaining to the modal to open
     * @param {Number} [props.version] - Optional param indicating the version
     */
    function handleOpenGlobalModalEvent(event, props) {
      reset();

      if (_.isUndefined(props.version)) {
        _.assign(vm, {
          componentBindings: props.componentBindings,
          componentName: props.componentName,
          modalId: _.get(props, 'componentBindings.modalId', DEFAULT_GLOBAL_MODAL_ID),
        });
      } else {
        const modalIdBinding = _.find(props.componentBindings, {attributeName: 'modalId'});
        vm.modalId = _.get(modalIdBinding, 'value', DEFAULT_GLOBAL_MODAL_ID);

        vm.newComponent = angular.element(
          $document[0].createElement(_.kebabCase(props.componentName))
        )[0];

        vm.newScope = $scope.$new(true);
        vm.componentBindings = props.componentBindings;
        setComponentBindings(vm.newComponent, vm.newScope, props.componentBindings);
        setGlobalModalRequiredAttributes(vm.newComponent, vm.newScope);

        $compile(vm.newComponent)(vm.newScope);
        $element[0].appendChild(vm.newComponent);
      }

      _.assign(vm, {
        modalParams: props.modalParams,
      });
    }

    function onInitialized() {
      if (_.isNil(vm.modalParams)) {
        panelManager.open(vm.modalId);
      } else {
        panelManager.open(vm.modalId, vm.modalParams);
      }
    }

    function reset() {
      _.invoke(vm.newScope, '$destroy');
      _.invoke(vm.newComponent, 'remove');
      _.invoke(
        _.find(vm.componentBindings, {
          attributeName: 'onClosed',
        }),
        'value'
      );

      _.assign(vm, {
        componentBindings: undefined,
        componentName: undefined,
        modalId: undefined,
      });
    }

    // Private functions
    function setGlobalModalRequiredAttributes(newComponent, newScope) {
      if (vm.modalId === DEFAULT_GLOBAL_MODAL_ID) {
        newComponent.setAttribute('modal-id', vm.modalId);
      }

      newComponent.setAttribute('on-initialized', 'onInitialized()');
      newComponent.setAttribute('on-closed', 'reset()');

      _.assign(newScope, {
        onInitialized,
        reset,
      });
    }

    function setComponentBindings(newComponent, newScope, componentBindings) {
      _.forEach(componentBindings, ({attributeName, type, value}) => {
        const attributeNameInKebabCase = _.kebabCase(attributeName);
        if (type === GLOBAL_MODAL_BINDING_TYPE.STRING) {
          newComponent.setAttribute(attributeNameInKebabCase, value);
        } else {
          const attributeValueSuffix = type === GLOBAL_MODAL_BINDING_TYPE.FUNCTION ? '()' : '';
          const attributeValue = attributeName + attributeValueSuffix;
          newComponent.setAttribute(attributeNameInKebabCase, attributeValue);

          // inject onClosed function to react modals. When the modal is closed, it removes
          // the modal from the DOM and the react tree.
          if (attributeName === 'props') {
            value.onClosed = reset;
          }

          newScope[attributeName] = value;
        }
      });
    }
  }
})();
