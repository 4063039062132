(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.account')
    .constant('INVOICE_PAYMENT_STATUS', {
      CLOSED: 'CLOSED',
      OPEN: 'OPEN',
      PAID: 'PAID',
      PAST_DUE: 'PAST_DUE',
      PAYMENT_DUE: 'PAYMENT_DUE',
      PROCESSING: 'PROCESSING',
    })
    .constant('INVOICE_INVALID_STATUS_STR', String.fromCharCode(8211, 8211));
})();
