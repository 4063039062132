import {useMemo, useRef} from 'react';

import {
  getRetentionItems,
  getRetentionMerchandising,
  getRetentionType,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

const IDLE = 'idle';
const LOADING = 'loading';
const RENDERING = 'rendering';

/**
 * @description A helper hook to return the Save Offer details given a retention Id from the
 * Products Change model. Uses Products Change model provided useProductsChangeContext hook.
 * @param {Object} options - list of options to customize the hook
 * @param {String} options.comment - the cancellation reason inputted by admin manually. Used for
 * fetching retentions.
 * @param {Contract} options.contract - the contract to determine tax and recurrence terms
 * @param {Function} options.onAcceptFreeTimeOffer - handler that is called when the user wants to
 * accept and submit a FREE_TIME save offer.
 * @param {Function} options.onReviewSaveOffer - handler that is called when the user wants to get
 * details for a PERCENTAGE_DISCOUNT save offer.
 * @param {Function} options.onSetUpChatSession - handler that is called when the user wants to start a
 * chat session.
 * @param {String[]} options.selectedReasons - The selected reason codes for cancellation. Used for
 * fetching retentions.
 * @param {Object} options.selectedSeats - The hash map of of initially selected licenses per
 * product Id. Used for fetching retentions.
 * @returns {Object} state - Object composed of the following cancellation details properties
 *          {Object[]} cards - array of retention cards to show. Each Save Offer retention card:
 *          {Function} cards[].onCTA - The CTA function for the offer type.
 *          {String} cards[].retentionId - Identifier of the Save Offer.
 *          {String} cards[].type - Save Offer type, i.e. PERCENTAGE_DISCOUNT or FREE_TIME.
 *          {Boolean} isLoading - Whether the retention is loading.
 *          {String[]} specialInstructions - array of special instructions strings to be displayed
 *          in main Save Offers step.
 */
const useSaveOffers = ({
  contract,
  onAcceptFreeTimeOffer,
  onReviewSaveOffer,
  onSetUpChatSession,
  selectedReasons,
}) => {
  const {isLoading: isProductsChangeLoading, productsChange} = useProductsChangeContext();

  // loadStatus possible states: RENDERING, LOADING, IDLE
  const loadStatus = useRef(RENDERING);

  // Fetch the Save Offer on initial render, if retention data is not available
  return useMemo(() => {
    // Calculate save offers to show
    let cards = [];
    const specialInstructions = [];

    // Update loadStatus If previous loadStatus was loading and ProductsChange has finalized loading
    if (loadStatus.current !== IDLE && !isProductsChangeLoading) {
      loadStatus.current = IDLE;
    }
    // Sync loadStatus when ProductsChange is initially loading
    if (loadStatus.current === RENDERING && isProductsChangeLoading) {
      loadStatus.current = LOADING;
    }

    // Calculate Save Offers cards when loading has ended
    if (loadStatus.current === IDLE) {
      const promoTypeToProps = {
        FREE_TIME: {
          contract,
          onCTA: onAcceptFreeTimeOffer,
        },
        PERCENTAGE_DISCOUNT: {
          contract,
          onCTA: onReviewSaveOffer,
        },
      };

      const retentionItems = getRetentionItems(productsChange) ?? [];
      // Add valid retention cards
      cards = retentionItems.reduce((memo, retention, index) => {
        const type = getRetentionType(retention);
        const props = promoTypeToProps[type];
        if (props) {
          const merchandising = getRetentionMerchandising(retention);
          const specialInstruction = merchandising?.copy?.specialInstructions;
          if (specialInstruction) {
            specialInstructions.push(specialInstruction);
          }

          return [
            ...memo,
            {
              ...props,
              index,
              retentionId: retention.id,
              selectedReasons,
              type,
            },
          ];
        }
        return memo;
      }, []);

      // Chat Card is always present
      cards.push({index: cards.length, onCTA: onSetUpChatSession, type: 'CHAT'});
    }

    return {
      cards,
      isLoading: loadStatus.current !== IDLE,
      specialInstructions,
    };
  }, [
    contract,
    isProductsChangeLoading,
    loadStatus,
    onAcceptFreeTimeOffer,
    onReviewSaveOffer,
    onSetUpChatSession,
    productsChange,
    selectedReasons,
  ]);
};

export default useSaveOffers;
