(function () {
  /**
   * @ngdoc module
   * @name app.core.ready.org
   * @description ready module to block on org list load
   */
  angular.module('app.core.ready.org', [
    'app.core.organizations.organization-manager',
    'binky.core.api.ready',
    'binky.core.authentication',
    'binky.core.authentication.ready',
    'binky.core.feature.ready',
    'binky.core.lodash',
    'binky.core.organizations',
  ]);
})();
