import {Product} from '@admin-tribe/binky';
import {TableFilters, TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import useTableSectionPagination from 'common/hooks/useTableSectionPagination';

import ProductInstancesTable from './ProductInstancesTable';
import {getProductInstanceName} from './utils/ProductInstancesTableUtils';

/**
 * Table section for ProductInstancesTable to display grouped DX instances
 */
const ProductInstancesTableSection = ({groupedProducts}) => {
  const intl = useIntl();
  const keyedGroupedProducts = useMemo(
    () =>
      // include entire possible name for search functionality to work
      groupedProducts.map((product) => {
        product.key = getProductInstanceName(product);
        return product;
      }),
    [groupedProducts]
  );
  const {currentItems, currentPageNumber, currentTotalPages, onTableSectionChange} =
    useTableSectionPagination({items: keyedGroupedProducts, sortByKey: 'key'});

  return (
    <TableSection
      getItemKey={(item) => item.key}
      items={currentItems}
      onTableSectionChange={onTableSectionChange}
      pageNumber={currentPageNumber}
      totalPages={currentTotalPages}
    >
      <TableFilters
        hasSearch
        label={intl.formatMessage({id: 'products.productInstancesTable.search'})}
      />
      <ProductInstancesTable />
    </TableSection>
  );
};

ProductInstancesTableSection.propTypes = {
  /**
   * The list of products to be populated in the table
   */
  groupedProducts: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
};

export default ProductInstancesTableSection;
