(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name ans
   * @description defines service to fetch ans resources
   */
  angular.module('app.core.api.adobestatus').provider('adobestatus', adobeStatusProvider);

  /* @ngInject */
  function adobeStatusProvider(_) {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${_.get(config, 'url')}`;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        currentStatus: getCurrentStatus(),
        statusStrings: getStatusStrings(),
      };

      return service;

      ///////////

      function getCurrentStatus() {
        return $resource(
          `${url}/currentstatus`,
          {},
          {
            get: {
              method: 'GET',
            },
          }
        );
      }

      function getStatusStrings() {
        return $resource(`${url}/:language`, {
          language: '@language',
        });
      }
    }
  }
})();
