import {
  AuthenticatedUser,
  CONTRACT_BUYING_PROGRAM,
  Locale,
  ORGANIZATION_MARKET_SEGMENT,
  OrganizationEncryption,
  canAddProducts,
  feature,
  getDirectContract,
  getEnterpriseContract,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  hasChinaContract,
  hasEnterpriseContract,
  hasIndirectContract,
  hasOnlyTeamProducts,
  hasTermsToAccept,
  hasTermsToReAccept,
  isMigrating,
  isMigrationListT2eClean,
  someByFulfillableItemCondition,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {ENCRYPTION_SETTINGS_CONSTANTS} from 'features/settings/components/encryption-settings-management/EncryptionSettingsPageConstants';

/**
 * Returns true if an developer can be assigned to the organization.
 *
 * @returns {Boolean} true if developer can be assigned to organization
 */
function canAssignDeveloper() {
  return !isReadOnly();
}

/**
 * Determines whether this admin can assign a user to the given organization.
 * @deprecated - use userAccess/canAssignUser
 *
 * @param {String} orgId The organization id.
 * @returns {Boolean} true if this admin can assign a user to the organization.
 */
function canAssignUser(orgId) {
  const currentUser = AuthenticatedUser.get();
  const currentUserRoles = currentUser.getRoles();

  const userHasAccess =
    currentUserRoles.isOrgAdminForOrg(orgId) ||
    currentUserRoles.isContractAdminForOrg(orgId) ||
    currentUserRoles.isLicenseGroupAdminForOrg(orgId) ||
    currentUserRoles.isProductAdminForOrg(orgId);

  return (
    userHasAccess &&
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic()
  );
}

/**
 * Determines whether a user can be edited.
 *
 * @returns {Boolean} true if user can be edited.
 */
function canEditUser() {
  return (
    isOrgAdmin() &&
    !isReadOnly() &&
    // Some migration types override the read-only state to allow users to be added
    // while setting up for the migration, however do not allow users to be edited
    // (such as changing their role) during this process.
    !rootStore.organizationStore.migrationList.shouldForceAllowAddAdminsOrUsers()
  );
}

/**
 * Returns true if an developer can be removed from the organization.
 *
 * @returns {Boolean} true if developer can be removed from organization
 */
function canRemoveDeveloper() {
  return auth.isUserOrgAdmin() && !isReadOnly();
}

/**
 * @description Access method to check if the logged in user can assign the
 *     market subsegments for an org.
 * @returns {Boolean} true if they're allowed, false otherwise.
 */
function canSetUpOrganizationTypes() {
  const canViewOrgType = canViewOrganizationType();
  return canViewOrgType && hasNotSetOrgTypes() && auth.canUpdateOrg();
}

/**
 * @description Access method to check if the logged in user can access asset sharing options
 * @returns {Boolean} true if they're allowed, false otherwise.
 */
function canViewAssetSharing() {
  return someByFulfillableItemCondition(rootStore.organizationStore.productList, (fItem) =>
    fItem.hasAssetSharingPolicyConfig()
  );
}

/**
 * @description Access check for viewing direct team billing history (invoice data)
 * To view billing history there must be a direct contract and the authenticated user is the contract owner.
 * @returns {Boolean} whether the user can view the contract billing history
 */
function canViewDirectContractBillingHistory() {
  return authenticatedUserIsContractOwner(
    getDirectContract(rootStore.organizationStore.contractList)
  );
}

/**
 * Access check for viewing free offers (Enterprise and Indirect only)
 * @returns {Boolean} whether the user can view free offers
 */
function canViewFreeOffers() {
  const contractList = rootStore.organizationStore.contractList;
  return (
    (auth.isUserOrgAdmin() || auth.isUserContractAdmin()) &&
    (!!getEnterpriseContract(contractList, CONTRACT_BUYING_PROGRAM.ETLA) ||
      hasIndirectContract(contractList))
  );
}

/**
 * @description Access check for org level encryption settings
 * @returns {Boolean} true if the user can view the encryption settings page
 */
async function canViewEncryption() {
  const orgStore = rootStore.organizationStore;
  if (
    !auth.isUserOrgAdmin() ||
    orgStore.productList.items.length === 0 ||
    hasOnlyTeamProducts(orgStore.productList)
  ) {
    return false;
  }

  const orgId = rootStore.organizationStore.activeOrgId;
  const orgEncryptionInfo = await OrganizationEncryption.get({orgId});
  await orgEncryptionInfo.getOrgEncryptionStatusInfo();

  if (
    orgEncryptionInfo.encryptionProgressStatus &&
    orgEncryptionInfo.encryptionProgressStatus !==
      ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.COMPLETED
  ) {
    return false;
  }

  // This is incomplete.  The user should not have access if the org has any encrypted directories
  // https://git.corp.adobe.com/admin-tribe/binky/blob/master/src/core/organizations/organization-encryption/ ...
  //       ... organization-encryption-migration/organization-encryption-migration.service.js#L85-L86
  // @ringold to update this once Identity Accessors are available - https://jira.corp.adobe.com/browse/ONESIE-33740
  return true;
}

/**
 * @description Access check for the Organization Type section on the
 *     Organization Details page. An Education org with at least one ETLA, EVIP
 *     or VIP contact.
 * @returns {Boolean} true if they're allowed, false otherwise.
 */
function canViewOrganizationType() {
  if (
    rootStore.organizationStore.activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
  ) {
    const contractList = rootStore.organizationStore.contractList;
    return hasEnterpriseContract(contractList) || hasIndirectContract(contractList);
  }
  return false;
}

/**
 * @description Access check for the org's password policy
 * @returns {Boolean} true if the user can view the policy
 */
function canViewPasswordPolicy() {
  return someByFulfillableItemCondition(rootStore.organizationStore.productList, (fItem) =>
    fItem.hasDomainClaiming()
  );
}

/**
 * @returns {Boolean} whether the user can view VDT HVAs for an enterprise org
 */
function canViewValueDiscoveryTasksForEnterprise() {
  return auth.isUserOrgAdmin() && !hasOnlyTeamProducts(rootStore.organizationStore.productList);
}

/**
 * @returns {Boolean} whether the user can view VDT HVAs for a Team org
 */
function canViewValueDiscoveryTasksForTeam() {
  if (!auth.isUserOrgAdmin() || !hasOnlyTeamProducts(rootStore.organizationStore.productList)) {
    return false;
  }

  const assignableLicenses = getSeatBasedAssignableLicenseCount(
    rootStore.organizationStore.productList
  );
  const provisionedLicenses = getSeatBasedTotalProvisionedQuantity(
    rootStore.organizationStore.productList
  );
  return provisionedLicenses >= assignableLicenses;
}

/**
 * Access check for the Organization Details.
 * @returns boolean based on the access is allowed or not
 */
function canViewOrganizationDetails() {
  const contractList = rootStore.organizationStore.contractList;

  if (
    rootStore.organizationStore.activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
  ) {
    return hasEnterpriseContract(contractList) || hasIndirectContract(contractList);
  }

  return false;
}

/**
 * @returns {Boolean} whether the user has an active Chinese local or the org has a China contract
 */
function hasChinaLocaleOrContract() {
  return (
    Locale.get().isChineseLanguageActive ||
    hasChinaContract(rootStore.organizationStore.contractList)
  );
}

/**
 *
 * @returns {Boolean} whether products can be added to the contract
 */
function isAllowedToAddProducts() {
  return (
    canAddProducts(rootStore.organizationStore.contractList) &&
    // Currently this org admin check is redundant because the JIL contracts
    // API is already doing this check, but that will be removed in this PR:
    // https://git.corp.adobe.com/JIL-v2/jil-core/pull/12275 to unblock the
    // Orders API from getting doctored symptoms. This comment can be removed
    // when https://jira.corp.adobe.com/browse/ONESIE-33187 is completed.
    (isOrgAdmin() || isContractAdmin()) &&
    !rootStore.organizationStore.migrationList.shouldForceBlockPACreation()
  );
}

/**
 * Determines if the current logged-in admin is an org admin for the active org.
 * @returns {Boolean} true if logged in user is an Org admin
 */
function isOrgAdmin() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUserRoles = AuthenticatedUser.get().getRoles();
  return currentUserRoles.isOrgAdminForOrg(orgId);
}

/**
 * Determines if the current logged-in admin is a contract admin for the active org.
 * @returns {Boolean} true if logged in user is a contract admin
 */
function isContractAdmin() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUserRoles = AuthenticatedUser.get().getRoles();
  return currentUserRoles.isContractAdminForOrg(orgId);
}

/**
 * @returns {Boolean} whether the org is in a read-only status
 */
function isReadOnly() {
  const orgId = rootStore.organizationStore.activeOrgId;
  return (
    feature.isEnabled('readOnly') ||
    orgIsMigrating() ||
    (feature.isDisabled('temp_delegate_without_provisioning_pending_tc') &&
      hasTermsToAccept(rootStore.organizationStore.contractList)) ||
    (feature.isEnabled('temp_adobe_agent_access') &&
      // Only if the agent is auth'd solely via that do we mark it read only for them
      AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId))
  );
}

/**
 * @description Method to determine if org is T2E
 *
 * @returns {Boolean} true if org is T2E, false otherwise
 */
function isT2eOrg() {
  return isMigrationListT2eClean(rootStore.organizationStore.migrationList);
}

/**
 * @description Determines whether the current user must accept contract terms
 * before accessing AC.
 *
 * @param {ContractList} contractList List of org contracts
 *
 * @returns {Boolean} Returns true if the user must accept contract
 * terms before accessing AC.
 */
function mustAcceptContractTerms(contractList) {
  return (
    contractList.mustAcceptTerms() &&
    (isOrgAdmin() || (isContractAdmin() && hasTermsToReAccept(contractList)))
  );
}

/**
 * @description Method to check if any of the contracts of an org are migrating.
 * @returns {Boolean} true if any contracts are migrating.
 */
function orgIsMigrating() {
  return isMigrating(rootStore.organizationStore.contractList);
}

/**
 * @description Checks to see if any migration allows for
 *  adding admins or users, regardless of the usual requirements.
 *
 * @returns {Boolean} true if overriding existing behavior to add admins or users
 *  is allowed, returns false otherwise.
 */
function shouldForceAllowAddAdminsOrUsers() {
  return rootStore.organizationStore.migrationList.shouldForceAllowAddAdminsOrUsers();
}

////////

function authenticatedUserIsContractOwner(contract) {
  return !!contract?.isContractOwner({
    userId: feature.isEnabled('temp_t2e_contract_owners')
      ? AuthenticatedUser.get().getAuthenticatingAccountId()
      : AuthenticatedUser.get().getId(),
  });
}

function hasNotSetOrgTypes() {
  return rootStore.organizationStore.activeOrg.marketSubsegments === undefined;
}

export {
  canAssignDeveloper,
  canAssignUser,
  canEditUser,
  canRemoveDeveloper,
  canSetUpOrganizationTypes,
  canViewAssetSharing,
  canViewDirectContractBillingHistory,
  canViewEncryption,
  canViewFreeOffers,
  canViewOrganizationDetails,
  canViewOrganizationType,
  canViewPasswordPolicy,
  canViewValueDiscoveryTasksForEnterprise,
  canViewValueDiscoveryTasksForTeam,
  hasChinaLocaleOrContract,
  isAllowedToAddProducts,
  isOrgAdmin,
  isContractAdmin,
  isReadOnly,
  isT2eOrg,
  mustAcceptContractTerms,
  orgIsMigrating,
  shouldForceAllowAddAdminsOrUsers,
};
