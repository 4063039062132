(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AdobeStatusEvent
   * @description model that represents an Adobe status event
   */
  angular.module('app.core.adobe-status').factory('AdobeStatusEvent', getAdobeStatusEvent);

  /* @ngInject */
  function getAdobeStatusEvent(_, ADOBE_STATUS_CONSTANTS) {
    class AdobeStatusEvent {
      /**
       * @description Method to create a new instance of an AdobeStatusEvent
       * @param {Object} rawEvent - the raw event object
       * @param {Object} parent - the parent data object
       */
      constructor(rawEvent = {}, parent) {
        initModel(this, rawEvent, parent);
      }

      /**
       * @description Method to determine if the event ended after the provided date
       * @param {Date} time - the Date to check and see if this event ended after it
       * @returns {Boolean} true if this event ended after the provided date or hasn't ended yet,
       *   false otherwise. If the time to check against is undefined, it returns true
       *   for currently active events.
       */
      endedAfterTime(time) {
        if (time && _.isDate(time)) {
          return !this.endTime || this.endTime.valueOf() > time.valueOf();
        }
        return this.isActive();
      }

      /**
       * @description Gets the name string keys (for use with AdobeStatusStrings) of the services
       *   that are impacted by this event.
       * @returns {Array} array of string keys
       */
      getImpactedServiceNameKeys() {
        return _.chain(this)
          .get('impactedServices')
          .map((service) => _.get(service, 'name.token'))
          .value();
      }

      /**
       * @description Gets the name string key for the parent service for this event
       * @returns {String} parent service name key
       */
      getParentServiceNameKey() {
        return _.get(this, 'parentService.name.token');
      }

      /**
       * @description Determines if the event is currently active or not
       * @returns {Boolean} true if event is active, false otherwise
       */
      isActive() {
        return (
          this.endTime === null || this.eventState === ADOBE_STATUS_CONSTANTS.EVENT_STATE.ONGOING
        );
      }

      /**
       * @description Determines if the event is an announced event
       * @returns {Boolean} true if event state is announced
       */
      isAnnounced() {
        return this.eventState === ADOBE_STATUS_CONSTANTS.EVENT_STATE.ANNOUNCED;
      }

      /**
       * @description Determines if the event has been cancelled
       * @returns {Boolean} true if event is cancelled, false otherwise
       */
      isCancelled() {
        return this.eventState === ADOBE_STATUS_CONSTANTS.EVENT_STATE.EVENT_CANCELED;
      }

      /**
       * @description Determines if the event is a maintenance event
       * @returns {Boolean} true if event is maintenance
       */
      isMaintenance() {
        return this.eventType === ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MAINTENANCE;
      }

      /**
       * @description Determines if the event is a major event
       * @returns {Boolean} true if event is major
       */
      isMajor() {
        return this.eventType === ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MAJOR;
      }

      /**
       * @description Determines if the event is a minor event
       * @returns {Boolean} true if event is minor or maintenance
       */
      isMinor() {
        return (
          this.eventType === ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MINOR ||
          this.eventType === ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MAINTENANCE
        );
      }

      /**
       * @description Determines if the event has been resolved
       * @returns {Boolean} true if event is resolved or repaired
       */
      isResolved() {
        return (
          this.eventState === ADOBE_STATUS_CONSTANTS.EVENT_STATE.RESOLVED ||
          this.eventState === ADOBE_STATUS_CONSTANTS.EVENT_STATE.REPAIRED
        );
      }
    }

    return AdobeStatusEvent;

    /**
     * @description Initializes AdobeStatusData data from options.
     *
     * @param {Object} model AdobeStatusData model Object instance to initialize
     * @param {Object} options Initialization Object (params described below)
     * @param {String} options.id the event ID
     * @param {Array} options.impactedServices the list of services impacted by the event
     * @param {Number} options.eventState enum value of the status of the event (ongoing, resolved, etc)
     * @param {Number} options.eventType enum value of the type of the event (major, minor, etc)
     * @param {Object} parent the parent AdobeStatusData object
     */
    function initModel(model, options = {}, parent = {}) {
      _.assignIn(model, _.pick(options, ['id', 'impactedServices', 'eventState', 'eventType']));
      model.parentService = _.get(parent, 'service');

      // The timestamp comes in unix format, which is number of seconds (not milliseconds) since
      // Jan 1 1970. So we need to multiply the value by 1000 to get the proper date objects.
      model.startTime = options.startTime ? new Date(options.startTime * 1000) : null;
      model.endTime = options.endTime ? new Date(options.endTime * 1000) : null;

      model.timestamp =
        model.endTime && model.eventState !== ADOBE_STATUS_CONSTANTS.EVENT_STATE.ANNOUNCED
          ? model.endTime
          : model.startTime;
    }
  }
})();
