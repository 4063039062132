(function () {
  /**
   * @deprecated Please use src2/app/core/user/delegation-users/DelegationsUsersList.js
   *
   * @ngdoc factory
   * @name DelegationsUsersList
   * @description Paginated user list for delegations users
   */
  angular
    .module('app.core.delegations-users-list')
    .factory('DelegationsUsersList', getDelegationsUsersListModel);

  /* @ngInject */
  function getDelegationsUsersListModel(
    $rootScope,
    _,
    jilUsers,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    User
  ) {
    /**
     * @description Class representing a DelegationsUsersList.
     * @extends MemberList
     */
    class DelegationsUsersList extends MemberList {
      /**
       * @class
       * @description Constructor for DelegationsUsersList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.DELEGATIONSUSERSLIST,
              memberClassRef: User,
              resource: jilUsers.delegationsUsers,
            },
            options
          )
        );
        this.params = options;
      }

      /**
       * @description Method to refresh the contents of the delegations users list
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh() {
        return super.refresh(this.params).then((userList) => {
          $rootScope.$emit(MESSAGE.UPDATE.DELEGATIONSUSERS.LIST);
          if (this.shouldUpdateTotalItemCount()) {
            $rootScope.$emit(MESSAGE.UPDATE.DELEGATIONSUSERS.COUNT, this.pagination.itemCount);
          }
          return userList;
        });
      }

      /**
       * @description Method to retrieve an existing delegations users list.
       * @param {Object} [params] - optional params
       * @returns {DelegationsUsersList} model of existing user list
       */
      static get(params) {
        let model;

        model = new DelegationsUsersList(params);
        const cachedModel = modelCache.get(MODEL.DELEGATIONSUSERSLIST, model.key(params));

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }

      /**
       * @description Method to retrieve an existing user list of users
       *   that do not have access (incompatible id type).
       * @param {String} options - options to get the delegations users list
       * @returns {DelegationsUsersList} model of existing user list
       */
      static getIncompatibleIDTypeUsers(options) {
        const params = _.assign({}, options, {
          no_access_code: 'IDENTITY_TYPE',
        });
        return this.get(params);
      }
    }

    MemberList.setupCache(MODEL.DELEGATIONSUSERSLIST, 10);

    return DelegationsUsersList;
  }
})();
