import {
  ButtonLink,
  StatusLight,
  showError as showErrorToast,
  showInfo as showInfoToast,
} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ContentLog from '../../../../../common/entities/content-logs/ContentLog';
import {useContentLogsContext} from '../../ContentLogsContext';

/**
 * @description the component that represents the status for each content log in the table.
 * Used to download the reports, when ready.
 */
const ContentLogStatus = observer(({contentLog}) => {
  const {contentLogsStore} = useContentLogsContext();

  const intl = useIntl();
  const count = contentLog.children.length;

  const onDownload = async () => {
    try {
      showInfoToast(
        intl.formatMessage({id: 'settings.contentLogs.status.downloadBeingPrepared'}, {count})
      );
      await contentLogsStore.download(contentLog);
    } catch (error) {
      showErrorToast();
    }
  };

  switch (contentLog.status) {
    case 'IN_PROGRESS':
      return (
        <StatusLight alignStart variant="notice">
          {intl.formatMessage({id: 'settings.contentLogs.status.inProgress'})}
        </StatusLight>
      );
    case 'READY': {
      return (
        <ButtonLink
          id={contentLog.id}
          isQuiet
          marginStart="size-200"
          onPress={onDownload}
          variant="primary"
        >
          {intl.formatMessage({id: 'settings.contentLogs.status.download'}, {count})}
        </ButtonLink>
      );
    }
    default:
      return (
        <StatusLight alignStart variant="negative">
          {intl.formatMessage({id: 'settings.contentLogs.status.retry'})}
        </StatusLight>
      );
  }
});

ContentLogStatus.propTypes = {
  /**
   * The ContentLog instance for which the status is to be displayed.
   */
  contentLog: PropTypes.instanceOf(ContentLog).isRequired,
};

export default ContentLogStatus;
