(function () {
  /**
   * @deprecated. Please use organizationAccess.isT2eOrg()
   *
   * @ngdoc factory
   * @name t2eMigrationManager
   * @description Provides utility functions relevant to T2E migrations
   */
  // Disable rule complainng about number in filename
  // eslint-disable-next-line angular/factory-name
  angular.module('app.core.migration.t2e').factory('t2eMigrationManager', factory);

  /* @ngInject */
  function factory(binkySrc2, OrganizationManager) {
    return {
      isT2EClean,
    };

    ////////////

    /**
     * @description Returns true if the organization is "T2E clean". This means it does not contain
     *   any T1 accounts (except the contract owner) and new T1s cannot be added to it; instead,
     *   only T2Es can be added. This should be the case for orgs that have completed T2E migration,
     *   and orgs that have been created since the T2E project launch. JIL represents both these
     *   cases by returning a T2E migration with status MIGRATED.
     *
     * @return {Boolean} true if the organization is T2E-clean, else returns false.
     */
    function isT2EClean() {
      return binkySrc2.services.migration.migrationListUtils.isMigrationListT2eClean(
        OrganizationManager.getMigrationsForActiveOrg()
      );
    }
  }
})();
