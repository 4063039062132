import './AppListTable.pcss';
import {GoUrl, ImageIcon} from '@admin-tribe/binky-ui';
import {ActionButton, ButtonGroup, Flex, Link} from '@adobe/react-spectrum';
import Add from '@spectrum-icons/workflow/Add';
import Close from '@spectrum-icons/workflow/Close';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React, {createRef, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import AddOnsV2 from '../add-ons-v2/AddOnsV2';

const AppListTable = ({
  focusNextElement,
  onAddProduct,
  onRemoveProduct,
  products,
  showAddButton,
  showLearnMoreLink,
  showLTSLink,
  showRemoveButton,
  showSysReqLink,
  pkgCreateObj,
}) => {
  const intl = useIntl();

  // refs
  const [addBtnRefs, setAddBtnRefs] = useState([]);
  const [linkOutBtnRefs, setLinkOutBtnRefs] = useState([]);
  const [removeBtnRefs, setRemoveBtnRefs] = useState([]);

  // set btn refs
  useEffect(() => {
    setAddBtnRefs((btnRef) =>
      new Array(products.length).fill().map((_, i) => btnRef[i] || createRef())
    );
    setLinkOutBtnRefs((btnRef) =>
      new Array(products.length).fill().map((_, i) => btnRef[i] || createRef())
    );
    setRemoveBtnRefs((btnRef) =>
      new Array(products.length).fill().map((_, i) => btnRef[i] || createRef())
    );
  }, [products.length]);

  const focusSibling = (index) => {
    // on pressing enter on add/remove button in app list table products,
    // focus on either the next lts/sysreq link/link out/add/remove button appropriately
    // and if no more products/buttons, focus on either Additional System Requirements/previous button appropriately
    if (products[index + 1]) {
      if (showLTSLinkForProduct(products[index + 1])) {
        document.querySelector(`[id = 'ltsLink${products[index + 1].product_key}']`)?.focus();
      } else if (showSysReqLinkForProduct(products[index + 1])) {
        document.querySelector(`[id = 'sysReqLink${products[index + 1].product_key}']`)?.focus();
      } else if (showLearnMoreLinkForProduct(products[index + 1])) {
        linkOutBtnRefs[index + 1].current?.focus();
      } else if (showRemoveButtonForProduct(products[index + 1])) {
        removeBtnRefs[index + 1].current?.focus();
      } else if (showAddButton) {
        addBtnRefs[index + 1].current?.focus();
      } else {
        // if no tabable element found in sibling of index, then search in sibling of index+1
        focusSibling(index + 1);
      }
    } else if (typeof focusNextElement === 'function') {
      focusNextElement(index);
    }
  };

  function showLTSLinkForProduct(product) {
    return showLTSLink && product.isMaintenanceBuild;
  }

  function showSysReqLinkForProduct(product) {
    return showSysReqLink && product.hasSpecialSystemRequirements();
  }

  function showLearnMoreLinkForProduct(product) {
    return showLearnMoreLink && product.localeInfoUrl;
  }

  function showRemoveButtonForProduct(product) {
    return showRemoveButton && product.sapCode !== 'KCCC';
  }

  function setSelectedProductAddOnsMap(productKey, selectedAddOnsList) {
    if (pkgCreateObj && typeof productKey === 'string') {
      pkgCreateObj.selectedProductAddOnsMap[productKey] = selectedAddOnsList.map(
        (selectedAddOn) => ({
          displayName: selectedAddOn.displayName,
          id: selectedAddOn.id,
        })
      );
    }
  }

  function getAppNameClasses(product) {
    if (showRemoveButtonForProduct(product)) {
      return 'app-name-container app-name-width-on-selected-apps';
    }
    return 'app-name-container app-name-width-on-available-apps';
  }

  function openLink(url) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- mohanver@ to update
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <table role="presentation" styleName="app-list-container-table">
      <tbody>
        {products.map((product, index) => (
          <tr key={product.product_key} styleName="app-entry">
            <td>
              <Flex direction="column">
                <Flex alignItems="center" direction="row">
                  <div styleName="app-img">
                    <ImageIcon alt="" size="M" src={product.getIconUrl()} />
                  </div>
                  <div styleName={getAppNameClasses(product)}>
                    <div className="app-name-version">
                      <span styleName="app-name">{product.name}</span>
                      <span styleName="app-version">({product.version})</span>
                    </div>
                    {showLTSLinkForProduct(product) && (
                      <div styleName="app-more-info">
                        <GoUrl
                          id={`ltsLink${product.product_key}`}
                          name="ent_pref_unsupported_version"
                          variant="secondary"
                        >
                          {intl.formatMessage({
                            id: 'packages.createPackage.chooseApps.appList.LTSLink',
                          })}
                        </GoUrl>
                      </div>
                    )}
                    {showSysReqLinkForProduct(product) && (
                      <div styleName="app-more-info">
                        <Link id={`sysReqLink${product.product_key}`} variant="secondary">
                          <a
                            href={product.getSystemRequirementUrl()}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {intl.formatMessage({
                              id: 'packages.createPackage.chooseApps.appList.SysReqLink',
                            })}
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div styleName="product-actions">
                    <ButtonGroup data-testid="product-action-group" isQuiet>
                      {showLearnMoreLinkForProduct(product) && (
                        <ActionButton
                          ref={linkOutBtnRefs[index]}
                          aria-label={intl.formatMessage(
                            {
                              id: 'packages.createPackage.chooseApps.appList.viewProductDetails',
                            },
                            {productName: product.name, productVersion: product.version}
                          )}
                          data-testid="open-link-app-table-button"
                          density="compact"
                          isQuiet
                          margin="size-50"
                          onPress={() => openLink(product.localeInfoUrl)}
                        >
                          <LinkOut />
                        </ActionButton>
                      )}
                      {showAddButton && (
                        <ActionButton
                          ref={addBtnRefs[index]}
                          aria-label={intl.formatMessage(
                            {
                              id: 'packages.createPackage.chooseApps.appList.addToPackage',
                            },
                            {productName: product.name, productVersion: product.version}
                          )}
                          data-testid="add-app-table-product-button"
                          density="compact"
                          isQuiet
                          margin="size-50"
                          onPress={() => {
                            // on pressing enter on add button in app list table products, no element is in focus until we press tab,
                            // so explicitly shifting focus on to next tabable element by calling focusSibling
                            focusSibling(index);
                            onAddProduct(product, true, false);
                          }}
                        >
                          <Add />
                        </ActionButton>
                      )}
                      {showRemoveButtonForProduct(product) && (
                        <ActionButton
                          ref={removeBtnRefs[index]}
                          aria-label={intl.formatMessage(
                            {
                              id: 'packages.createPackage.chooseApps.appList.removeFromPackage',
                            },
                            {productName: product.name, productVersion: product.version}
                          )}
                          data-testid="remove-app-table-product-button"
                          density="compact"
                          isQuiet
                          margin="size-50"
                          onPress={() => {
                            // on pressing enter on remove button in app list table products,
                            // no element is in focus until we press tab, so explicitly shifting focus on
                            // to next tabable element with focusSibling call
                            focusSibling(index);
                            onRemoveProduct(product, false);
                          }}
                        >
                          <Close />
                        </ActionButton>
                      )}
                    </ButtonGroup>
                  </div>
                </Flex>
                {showRemoveButtonForProduct(product) && product.modules && (
                  <Flex direction="row" marginStart="30px">
                    <AddOnsV2
                      data-testid="add-ons"
                      productAddOns={product.modules}
                      productKey={product.product_key}
                      setSelectedProductAddOnsMap={setSelectedProductAddOnsMap}
                    />
                  </Flex>
                )}
              </Flex>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

AppListTable.propTypes = {
  /**
   * Method to shift focus on to the next element
   */
  focusNextElement: PropTypes.func,
  /**
   * Method to add product
   */
  onAddProduct: PropTypes.func,
  /**
   * Method to remove product
   */
  onRemoveProduct: PropTypes.func,
  /**
   * Shared object for package create object
   */
  pkgCreateObj: PropTypes.instanceOf(Object),
  /**
   * List of products to display
   */
  products: PropTypes.instanceOf(Array).isRequired,
  /**
   * If add button should be visible
   */
  showAddButton: PropTypes.bool,
  /**
   * If learn more links for products should be visible
   */
  showLearnMoreLink: PropTypes.bool,
  /**
   * If LTS links for products should be visible
   */
  showLTSLink: PropTypes.bool,
  /**
   * If remove button should be visible
   */
  showRemoveButton: PropTypes.bool,
  /**
   * If system requirements links for products should be visible
   */
  showSysReqLink: PropTypes.bool,
};

export default AppListTable;
