(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilDomains
   * @description defines service to manage jil domains resources
   */
  angular.module('app.core.api.jil').provider('jilDomains', jilDomainsProvider);

  let url;

  /* @ngInject */
  function jilDomainsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        domains: getDomainsResource(),
      };

      return service;

      ///////////

      function getDomainsResource() {
        return $resource(
          `${url}/:orgId/domains:action`,
          {orgId: apiUtils.getActiveOrg},
          {
            addMultiple: {isArray: true, method: 'POST'},
            addMultiplePreview: {isArray: true, method: 'POST', params: {action: ':preview'}},
            batchOperation: {
              isArray: true,
              method: 'PATCH',
            },
            export: {
              headers: {
                Accept: 'text/csv+domain',
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
