const AppConstants = {
  init({accessToken, configuration, countryCode, orgId}) {
    this.accessToken = accessToken;
    this.configuration = configuration;
    this.countryCode = countryCode;
    this.orgId = orgId;
  },

  // Temporary way to get the current org's country code in src2
  // TODO: @cawright remove once OrganizationList is ported to src2
  setCountryCode(countryCode) {
    this.countryCode = countryCode;
  },

  setOrgId(orgId) {
    this.orgId = orgId;
  },
};

export default AppConstants;
