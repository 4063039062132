import {
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  feature,
} from '@admin-tribe/binky';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Flex, Item, ListView, Picker, Text, useDialogContainer} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import DomainEnforcementInlineWarnings from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementInlineWarnings';
import useDomainList from 'features/settings/hooks/api/useDomainList';

const MODAL_ID = 'link-domain-to-directory-modal';

const LinkDomainToDirectoryModal = ({domains, onConfirm}) => {
  const intl = useIntl();
  const directoryList = useDirectoryList();
  const domainList = useDomainList();
  const [domainsToLink, setDomainsToLink] = useState(domains);
  const [directories, setDirectories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDirectoryDeStatus, setSelectedDirectoryDeStatus] = useState();
  const [selectedDirectory, setSelectedDirectory] = useState();
  const dialog = useDialogContainer();

  const handleOnDirectorySelectionChange = (directoryId) => {
    setSelectedDirectory(directoryId);

    const directoryStatus = directories.find((directory) => directory.id === directoryId)
      ?.domainEnforcement?.state;
    setSelectedDirectoryDeStatus(directoryStatus);
  };

  // load the list of directories
  useEffect(() => {
    const loadDirectories = async () => {
      setIsLoading(true);

      try {
        const response = await directoryList.getDirectoryList({page: null});

        const availableDirectories = response.data.filter(
          (directory) =>
            directory.type !== DIRECTORY_TYPE.TYPE2E &&
            directory.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
            (directory.status === DIRECTORY_STATUS.ACTIVE ||
              directory.status === DIRECTORY_STATUS.NEEDS_DOMAIN)
        );

        // if no directories are available for selection let the user know
        // through a toast and close the modal
        if (availableDirectories.length === 0) {
          showErrorToast(
            intl.formatMessage({
              id: 'settings.linkDomainToDirectoryModal.toasts.noActiveDirectories',
            })
          );
          dialog.dismiss();
        }

        setDirectories(availableDirectories);
      } catch (error) {
        showErrorToast(
          intl.formatMessage({
            id: 'settings.linkDomainToDirectoryModal.toasts.errorLoadingDirectories',
          })
        );

        dialog.dismiss();
      } finally {
        setIsLoading(false);
      }
    };

    loadDirectories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this needs to run only once
  }, []);

  const onLinkDirectory = async () => {
    setIsLoading(true);

    try {
      const {failedToLink, linked} = await domainList.linkDomainsToDirectory({
        directoryId: selectedDirectory,
        domains: domainsToLink,
      });

      if (linked.length > 0) {
        showSuccessToast(
          intl.formatMessage(
            {id: 'settings.linkDomainToDirectoryModal.toasts.successfulLinking'},
            {count: linked.length}
          )
        );

        onConfirm?.();
      }

      if (failedToLink.length === 0) {
        dialog.dismiss();
      } else {
        showErrorToast(
          intl.formatMessage(
            {
              id: 'settings.linkDomainToDirectoryModal.toasts.errorLinking.partial',
            },
            {count: domainsToLink.length, failedCount: failedToLink.length}
          )
        );

        setDomainsToLink(failedToLink);
      }
    } catch (error) {
      showErrorToast(
        intl.formatMessage(
          {
            id: 'settings.linkDomainToDirectoryModal.toasts.errorLinking',
          },
          {count: domains.length}
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalDialog id={MODAL_ID} isLoading={isLoading}>
      <ModalHeading>
        <FormattedMessage id="settings.linkDomainToDirectoryModal.title" />
      </ModalHeading>
      <ModalDescription>
        <FormattedMessage id="settings.linkDomainToDirectoryModal.description" />
      </ModalDescription>
      <ModalContent>
        <Flex direction="column" gap="size-250">
          <Picker
            data-testid="directory-picker"
            isDisabled={directories.length === 0}
            items={directories}
            label={intl.formatMessage({
              id: 'settings.linkDomainToDirectoryModal.directoryPickerLabel',
            })}
            onSelectionChange={handleOnDirectorySelectionChange}
          >
            {(item) => <Item>{item.name}</Item>}
          </Picker>

          {feature.isEnabled('temp_domain_enforcement') && (
            <DomainEnforcementInlineWarnings
              activatedTextKey="settings.linkDomainToDirectoryModal.domainEnforcement.activatedWarning"
              enforcedTextKey="settings.linkDomainToDirectoryModal.domainEnforcement.enforcedWarning"
              status={selectedDirectoryDeStatus}
            />
          )}

          <Text data-test-id="domains-to-be-linked">
            <FormattedMessage id="settings.linkDomainToDirectoryModal.domainsToBeLinked" />
          </Text>

          <ListView
            aria-label={intl.formatMessage({
              id: 'settings.linkDomainToDirectoryModal.domainsToBeLinked',
            })}
          >
            {domainsToLink.map((domain) => (
              <Item key={domain.domainName}>{domain.domainName}</Item>
            ))}
          </ListView>
        </Flex>
      </ModalContent>
      <ModalButtonGroup
        cancelLabel={intl.formatMessage({
          id: 'settings.linkDomainToDirectoryModal.buttons.cancel',
        })}
        ctaLabel={intl.formatMessage({
          id: 'settings.linkDomainToDirectoryModal.buttons.link',
        })}
        isCtaDisabled={!selectedDirectory || isLoading}
        onCancel={() => dialog.dismiss()}
        onCta={onLinkDirectory}
      />
    </ModalDialog>
  );
};

LinkDomainToDirectoryModal.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      domainName: PropTypes.string,
    })
  ),
  onConfirm: PropTypes.func,
};

export default LinkDomainToDirectoryModal;
