import {feature} from '@admin-tribe/binky';
import {PageBanner} from '@admin-tribe/binky-ui';
import React from 'react';
import {useIntl} from 'react-intl';

import DomainEnforcementSettingsBanner from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementSettingsBanner';
import useIdentityBanners from 'features/settings/pages/identity-page/useIdentityBanners';

import styles from './IdentityBanners.pcss';

const IdentityBanners = () => {
  const intl = useIntl();

  const banners = useIdentityBanners();

  return (
    <>
      {feature.isEnabled('temp_domain_enforcement_announcements') && (
        <DomainEnforcementSettingsBanner />
      )}

      {banners.map(
        (banner) =>
          banner && (
            <PageBanner key={banner.label.id} showDefaultButton={banner.showDefaultButton}>
              <div className={styles['banner-content']}>
                {intl.formatMessage(banner.label, banner.content)}
              </div>
            </PageBanner>
          )
      )}
    </>
  );
};

export default IdentityBanners;
