import React from 'react';

import DomainListTable from 'features/settings/common/components/domain-list-table/DomainListTable';
import {DomainsListContextProvider} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import useOrganizationDomainListState from 'features/settings/components/domains/hooks/useOrganizationDomainListState';

const DomainsList = () => {
  const listState = useOrganizationDomainListState();

  return (
    <DomainsListContextProvider listState={listState}>
      <DomainListTable />
    </DomainsListContextProvider>
  );
};

export default DomainsList;
