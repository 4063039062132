import {View} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';

import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import ClasslinkLogo from 'features/settings/assets/classlink-logo.svg';
import CleverLogo from 'features/settings/assets/clever-logo.svg';

import styles from './EduProviderLogo.pcss';

const EduProviderLogo = ({rosterSource, ...styleProps}) => {
  const providerLogo =
    rosterSource.toLowerCase() === SYNC_PROVIDERS.CLEVER ? CleverLogo : ClasslinkLogo;

  return (
    <View {...styleProps}>
      <ImageIcon alt="" className={styles['edu-provider-logo']} size="XXL" src={providerLogo} />
    </View>
  );
};

EduProviderLogo.propTypes = {
  rosterSource: PropTypes.string.isRequired,
};

export default EduProviderLogo;
