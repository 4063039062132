import {EVENT_ACTION} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {dispatchEvent} from '../../../models/notificationUtils';
import {ANALYTIC_EVENT} from '../../../notifications.constants';

const MoreOptionsMenu = binkyUI.common.components.MoreOptionsMenu;

const MenuColumn = ({menuItems, notification, onItemSelected, showMoreOptionsMenu}) => (
  <Flex gridArea="menu" marginY="size-125">
    {showMoreOptionsMenu && (
      <MoreOptionsMenu
        buttonMarginStart="size-250"
        menuItems={menuItems}
        onButtonPress={() =>
          dispatchEvent({
            action: EVENT_ACTION.CLICK,
            name: ANALYTIC_EVENT.NAME.MENU_BUTTON,
            notification,
          })
        }
        onItemSelected={onItemSelected}
        onOpenChange={(isOpen) =>
          dispatchEvent({
            action: isOpen ? EVENT_ACTION.VIEW : EVENT_ACTION.CLOSE,
            name: ANALYTIC_EVENT.NAME.MENU,
            notification,
          })
        }
      />
    )}
  </Flex>
);

MenuColumn.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - notification
  notification: PropTypes.object.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  showMoreOptionsMenu: PropTypes.bool.isRequired,
};

export default MenuColumn;
