/**
 * @deprecated use src2 Overview page
 *
 * @ngdoc component
 * @name appHelpfulResourcesSection
 *
 * @description The Helpful Resources info section in the Overview page.
 */
(function () {
  const componentName = 'appHelpfulResourcesSection';

  angular.module('app.overview').component(componentName, {
    controller,
    templateUrl:
      'app/overview/overview/helpful-resources-section/helpful-resources-section.component.html',
  });
  /* @ngInject */
  function controller(
    _,
    $filter,
    $state,
    $translate,
    $window,
    AnalyticsEvent,
    CASE_TYPE_SELECTION_MODAL_ID,
    feature,
    globalModalsManager,
    GLOBAL_MODAL_BINDING_TYPE,
    onesieSrc2,
    OrganizationManager,
    supportAccess,
    SUPPORT_TICKET_RECORD_TYPE,
    toastManager
  ) {
    const ARTICLES_DEFAULT = [
      {
        goUrlName: 'aac_pop_artt_overview',
        labelKey: '.articlesDefault.adminConsole',
      },
      {
        goUrlName: 'aac_pop_artt_identity',
        labelKey: '.articlesDefault.identity',
      },
      {
        goUrlName: 'aac_pop_artt_users',
        labelKey: '.articlesDefault.users',
      },
      {
        goUrlName: 'aac_pop_artt_users_guide',
        labelKey: '.articlesDefault.userGuide',
      },
    ];
    const ARTICLES_ENTERPRISE_FEATURE_RESTRICTED = [
      {
        goUrlName: 'aac_pop_artt_frl_package',
        labelKey: '.articlesEnterpriseFeatureRestricted.frlPackage',
      },
      {
        goUrlName: 'aac_pop_artt_frl_deployment',
        labelKey: '.articlesEnterpriseFeatureRestricted.frlDeployment',
      },
      {
        goUrlName: 'aac_pop_artt_license_toolkit',
        labelKey: '.articlesEnterpriseFeatureRestricted.licensingToolkit',
      },
    ];
    const ARTICLES_ENTERPRISE_NAMED_USER = [
      {
        goUrlName: 'aac_pop_artt_ent_ids',
        labelKey: '.articlesEnterpriseNamedUser.enterpriseIds',
      },
      {
        goUrlName: 'aac_pop_artt_add_users',
        labelKey: '.articlesEnterpriseNamedUser.addUsers',
      },
      {
        goUrlName: 'aac_pop_artt_products',
        labelKey: '.articlesEnterpriseNamedUser.products',
      },
      {
        goUrlName: 'aac_pop_artt_create_nul_package',
        labelKey: '.articlesEnterpriseNamedUser.createPackages',
      },
    ];
    const ARTICLES_ENTERPRISE_SHARED_DEVICE = [
      {
        goUrlName: 'aac_pop_artt_sdl_deploy',
        labelKey: '.articlesEnterpriseSharedDevice.sdlDeployment',
      },
      {
        goUrlName: 'aac_pop_artt_sdl_package',
        labelKey: '.articlesEnterpriseSharedDevice.sdlPackage',
      },
      {
        goUrlName: 'aac_pop_artt_sdl_license',
        labelKey: '.articlesEnterpriseSharedDevice.sdlLicense',
      },
      {
        goUrlName: 'aac_pop_artt_edu_deploy',
        labelKey: '.articlesEnterpriseSharedDevice.eduDeployment',
      },
    ];
    const ARTICLES_TEAM = [
      {
        goUrlName: 'aac_pop_artt_assign_licences',
        labelKey: '.articlesTeam.assignLicenses',
      },
      {
        goUrlName: 'aac_pop_artt_manage_team',
        labelKey: '.articlesTeam.manageAccount',
      },
      {
        goUrlName: 'aac_pop_artt_create_nul_package',
        labelKey: '.articlesTeam.createPackages',
      },
      {
        goUrlName: 'aac_pop_artt_add_product',
        labelKey: '.articlesTeam.addProductsAndLicenses',
      },
    ];

    const vm = this;
    vm.productList = OrganizationManager.getProductsForActiveOrg();
    vm.onSupportCaseCreated = onSupportCaseCreated;

    vm.LINKS = [
      {
        class: 'community-forum-link',
        labelKey: '.links.communityForum',
        onClick: goToCommunityForum,
      },
      {
        class: 'share-feedback-link',
        labelKey: '.links.shareFeedback',
      },
    ];

    if (vm.productList.hasOnlyTeamProducts()) {
      vm.LINKS.splice(1, 0, {
        class: 'adobe-support-link',
        labelKey: '.links.adobeSupport',
        onClick: goToAdobeSupport,
      });
    } else if (supportAccess.canManageTickets()) {
      vm.LINKS.splice(1, 0, {
        class: 'create-support-case-link',
        labelKey: '.links.createSupportCase',
        onClick: createSupportCase,
      });
    }

    _.assign(vm, {$onInit, openGoUrl});

    function $onInit() {
      vm.popularArticles = getRelevantArticles();
    }

    function openGoUrl(goUrlName) {
      triggerAnalytics(goUrlName);
      $window.open($filter('goUrl')(goUrlName), '_blank');
    }

    function createSupportCase() {
      if (feature.isEnabled('temp_use_pandora_support_ticket')) {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.support.components.SupportTicketMiniappWrapper,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                onCancel: _.noop,
                onCreate: vm.onSupportCaseCreated,
                recordType: SUPPORT_TICKET_RECORD_TYPE.CASE,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'modalId',
              type: GLOBAL_MODAL_BINDING_TYPE.STRING,
              value: CASE_TYPE_SELECTION_MODAL_ID,
            },
          ],
          componentName: 'appCaseTypeSelectionModal',
        });
      }
    }

    function onSupportCaseCreated(caseId) {
      toastManager.showSuccessToast(
        $translate.instant('widgets.support.createCaseModal.toast.caseCreated', {
          caseId,
        })
      );
      $state.go('org.support.support-case-details', {id: caseId});
    }

    ////////

    function getRelevantArticles() {
      if (vm.productList.hasOnlyTeamProducts()) {
        return ARTICLES_TEAM;
      } else if (hasSomeSharedDeviceProducts()) {
        return ARTICLES_ENTERPRISE_SHARED_DEVICE;
      } else if (hasSomeFeatureRestrictedProducts()) {
        return ARTICLES_ENTERPRISE_FEATURE_RESTRICTED;
      } else if (!vm.productList.isEmpty()) {
        return ARTICLES_ENTERPRISE_NAMED_USER;
      }

      return ARTICLES_DEFAULT;
    }

    function hasSomeSharedDeviceProducts() {
      return _.some(vm.productList.items, (product) =>
        product.fulfillableItemList.hasLaboratoryLicenseManagement()
      );
    }

    function hasSomeFeatureRestrictedProducts() {
      return _.some(vm.productList.items, (product) => product.isFeatureRestrictedLicense());
    }

    function goToCommunityForum() {
      openGoUrl('entcom');
    }

    function goToAdobeSupport() {
      if (vm.productList.hasOnlyTeamProducts()) {
        openGoUrl('ac_team_support_browse');
      } else {
        openGoUrl('ac_ccetla_support_browse');
      }
    }

    function triggerAnalytics(goUrlName) {
      AnalyticsEvent.dispatch({
        attributes: {
          goUrlName,
        },
        componentMethod: 'goUrl',
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
