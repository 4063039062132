(function () {
  /**
   * @ngdoc module
   * @name app.widgets.organizations
   * @description module that defines organizations widgets
   */
  angular.module('app.widgets.organizations', [
    'app.core.analytics',
    'app.core.cart',
    'app.core.feature',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.widgets.organization-type',
    'app.widgets.products',
    'binky.core.authentication',
    'binky.core.common.member.type',
    'binky.core.contract',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.organizations',
    'binky.shell.panels.pulldown',
    'binky.widgets.common.feature',
    'binky.widgets.common.go-url',
    'binky.widgets.common.member.member-type',
    'binky.widgets.common.search',
    'binky.widgets.common.select-list',
    'binky.widgets.organizations.organizations-switcher',
  ]);
})();
