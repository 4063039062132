(function () {
  /**
   * @deprecated should use the React Spectrum Link
   *
   * @ngdoc directive
   * @name app.widgets.common.links.icon-link.directive:appIconLink
   *
   * @description Directive to create an icon link in the application. Note:
   *   this implementation currently uses one-way binding without a watch
   *   expression, so any changes to attributes will not be detected and the
   *   link will not change accordingly. If this behavior is desired, simply
   *   add a watch expression for the values to track and update code to
   *   regenerate link and compile contents again. (then, update this
   *   description!)
   *
   * @param {String} icon - Specifies the icon name used to render the correct
   *   icon. See Coral.Icon for valid icon names. Required.
   * @param {Link} link - Model representing the link contained in this icon.
   *   Required.
   * @param {String} [size] - Size of the icon. It accepts both lower and upper
   *   case sizes. Default: Coral.Icon.size.SMALL
   */
  angular
    .module('app.widgets.common.links.icon-link')
    .directive('appIconLink', appIconLinkDirective);

  function appIconLinkDirective($compile, _, translationUtils) {
    const OPTIONAL_ATTRIBUTES = ['size'];

    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        icon: '@',
        link: '<',
        size: '@?',
      },
    };

    return directive;

    /////////////////

    function linkFn(scope, element) {
      const coralIcon = getCoralIcon();

      element.append(coralIcon);

      $compile(element.contents())(scope);

      function getCoralIcon() {
        const icon = new Coral.Icon().set(
          _.assign(
            {
              alt: translationUtils.sanitizeSafeInstant(
                scope.link.key,
                scope.link.translateValues,
                scope.link.translateInterpolation
              ),
              icon: scope.icon,
            },
            _.pick(scope, OPTIONAL_ATTRIBUTES)
          )
        );

        icon.setAttribute('ng-click', 'link.goToLink()');

        scope.link
          .isAvailable()
          .then((availability) => {
            scope.isAvailable = availability;
          })
          .catch((error) => error);
        icon.setAttribute('ng-if', 'isAvailable');

        return icon;
      }
    }
  }
})();
