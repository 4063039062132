import {defineContents} from '@pandora/react-content-provider';
import {ModalDialogModel} from '@pandora/react-modal-dialog';

// A custom content model for the AddApiCredentialsModal
const AddApiCredentialsModalContentModel = defineContents('AddApiCredentialsModalContent', {
  modalDialogContent: {
    id: 'modalDialogContent',
    type: ModalDialogModel,
  },
});

export default AddApiCredentialsModalContentModel;
