import {Link, Text} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './UsersSummarySectionItem.pcss';

// Renders a user type and count in an <OverviewList> on the users pod on the Overview Page
const UsersSummarySectionItem = ({item}) => {
  const intl = useIntl();

  function onGoToUsers() {
    item.navigateFn();
  }

  return (
    <>
      <Link
        data-testid={item['data-testid']}
        flexGrow={1}
        onPress={() => onGoToUsers()}
        variant="secondary"
      >
        {intl.formatMessage({id: `overview.users.pod${item.nameKey}`})}
      </Link>
      <Text
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling
        UNSAFE_className={styles['user-count-label']}
      >
        {typeof item?.quantity === 'number' ? intl.formatNumber(item.quantity) : EN_DASH}
      </Text>
    </>
  );
};

UsersSummarySectionItem.propTypes = {
  /**
   * The item render in the user list
   */
  item: PropTypes.shape({
    /**
     * data-testid for button
     */
    'data-testid': PropTypes.string,
    /**
     * id for key prop
     */
    id: PropTypes.string.isRequired,
    /**
     * loc key suffix for label
     */
    nameKey: PropTypes.string.isRequired,
    /**
     * method to call to route to given page
     */
    navigateFn: PropTypes.func.isRequired,
    /**
     * value for number of users label. Undefined if user type is not displayed.
     */
    quantity: PropTypes.number,
    /**
     * user type - see USER_TYPE const
     */
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default UsersSummarySectionItem;
