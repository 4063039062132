import {getIndirectContract} from '@admin-tribe/binky';
import {Link} from '@adobe/react-spectrum';
import React from 'react';

import rootStore from 'core/RootStore';

import {compareByVariant, getThresholdStatusKey, notifyInfo} from './notifierUtils';

/**
 * @description Method to construct and return a list of any applicable
 *   notifications that should be displayed when viewing product group product
 *   pages/views
 * @param {Object} options - the params
 * @param {Consumable} [options.consumable] - the consumable entity (if
 *   consumable grouping)
 * @param {Object} options.intl - the React intl object
 * @param {ProductGroupProductList} options.productGroup - the
 *   product group product list being rendered
 * @returns {BannerNotification[]} - banner messages to be displayed
 */
function notify({consumable, intl, productGroup}) {
  const messages = [];

  if (consumable) {
    if (productGroup.isStockCredit()) {
      messages.push(notifyAccessInfo({intl}));
    }

    const msgTypeKey = getThresholdStatusKey(consumable);
    if (msgTypeKey) {
      if (productGroup.isTeamDirect()) {
        messages.push(notifyTeamDirectThreshold({intl, msgTypeKey, productGroup}));
      } else if (productGroup.isTeamIndirect()) {
        messages.push(notifyTeamIndirectThreshold({intl, msgTypeKey}));
      }
    }
  }

  // prune any falsey values and sort the messages by variant: error, warning, info
  return messages.filter(Boolean).sort(compareByVariant);
}

// Private methods
function getResellerName() {
  const indirectContract = getIndirectContract(rootStore.organizationStore.contractList);
  return indirectContract?.getResellerName() ?? '';
}

function notifyAccessInfo({intl}) {
  const name = 'access-info-page-banner';
  return notifyInfo({
    body: intl.formatMessage({id: 'products.groups.pageBanner.accessInfo.body'}),
    name,
    removeWithKey: name,
  });
}

function notifyTeamDirectThreshold({intl, msgTypeKey, productGroup}) {
  const keyPrependFragment = `products.groups.pageBanner.teamDirect.${msgTypeKey}`;
  const {text, href} = productGroup.getBuyMoreLink(intl);
  const name = 'team-direct-threshold-page-banner';

  return notifyInfo({
    body: intl.formatMessage(
      {id: `${keyPrependFragment}.body`},
      {
        link: (url) => (
          <Link>
            <a href={url} rel="noreferrer" target="_blank">
              {text}
            </a>
          </Link>
        ),
        url: href,
      }
    ),
    header: intl.formatMessage({id: `${keyPrependFragment}.header`}),
    name,
    removeWithKey: name,
  });
}

function notifyTeamIndirectThreshold({intl, msgTypeKey}) {
  const keyPrependFragment = `products.groups.pageBanner.teamIndirect.${msgTypeKey}`;
  const name = 'team-indirect-threshold-page-banner';

  return notifyInfo({
    body: intl.formatMessage({id: `${keyPrependFragment}.body`}, {resellerName: getResellerName()}),
    header: intl.formatMessage({id: `${keyPrependFragment}.header`}),
    name,
    removeWithKey: name,
  });
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notify};
