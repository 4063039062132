import {showError, showInfo} from '@admin-tribe/binky-ui';
import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import StorageReport from 'common/services/storage-report/StorageReport';

/**
 * The `Download report` action button that is on the StorageReportsDetailsPage.
 */
const DownloadReportButton = ({report}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  const downloadLabel = intl.formatMessage({
    id: 'storage.storageReports.details.button.download',
  });

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const downloadReport = async () => {
    setIsDisabled(true);

    showInfo(
      intl.formatMessage({id: 'storage.storageReports.details.toast.downloadBeingPrepared'})
    );

    try {
      await report.download();
    } catch (error) {
      showError(intl.formatMessage({id: 'storage.storageReports.details.toast.unableToDownload'}));
    } finally {
      if (isCurrentRef.current) {
        setIsDisabled(false);
      }
    }
  };

  return (
    <TooltipTrigger>
      <ActionButton
        aria-label={downloadLabel}
        isDisabled={isDisabled}
        isQuiet
        onPress={downloadReport}
      >
        <DownloadIcon />
      </ActionButton>
      <Tooltip>{downloadLabel}</Tooltip>
    </TooltipTrigger>
  );
};

DownloadReportButton.propTypes = {
  /**
   * The report to download.
   */
  report: PropTypes.instanceOf(StorageReport).isRequired,
};

export default DownloadReportButton;
