import {Flex, Heading, Text} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';

import styles from './PackageSummaryDrawerContentHeader.pcss';
import DrawerActionButtons from './drawer-action-buttons/DrawerActionButtons';

const PackageSummaryDrawerContentHeader = ({
  allPackagesStore,
  closeDrawer,
  onCustomizePress,
  onUpdatePress,
}) => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);
  return (
    <Flex direction="column">
      <Heading level={2}>{attributes.packageName}</Heading>

      {/* If not on template summary, and if package building is failed show failure alert  */}
      {!attributes.isTemplate && (
        <Flex direction="row" marginTop="size-150">
          {attributes.showFailure && (
            <Flex alignItems="center" marginEnd="size-400">
              <Alert
                color="negative"
                data-testid="failure-alert-icon"
                marginEnd="size-65"
                size="S"
              />
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font
            styling */}
              <Text data-testid="failure-alert-text" UNSAFE_className={styles['failure-text']}>
                {intl.formatMessage({id: 'packages.summaryDrawerHeader.failed'})}
              </Text>
            </Flex>
          )}
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font
            styling */}
          <Text UNSAFE_className={styles['created-by-text']}>
            {intl.formatMessage(
              {id: 'packages.summaryDrawerHeader.createdBy'},
              {createdBy: attributes.createdBy}
            )}
          </Text>
        </Flex>
      )}

      {/* Component to show Download, Retry, Customize and Update Package Button based on conditions */}
      <DrawerActionButtons
        allPackagesStore={allPackagesStore}
        closeDrawer={closeDrawer}
        onCustomizePress={onCustomizePress}
        onUpdatePress={onUpdatePress}
      />

      {/* If not of template summary, show days left to download package */}
      {!attributes.isTemplate && attributes.showDaysLeft && (
        <Flex alignItems="center" marginTop="size-200">
          <CheckmarkCircle color="positive" size="S" />
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling */}
          <Text marginStart="size-100" UNSAFE_className={styles['remaning-days-text']}>
            {intl.formatMessage(
              {id: 'packages.summaryDrawerHeader.timeRemaining'},
              {daysLeft: attributes.package.downloadDaysRemaining}
            )}
          </Text>
          <TooltipButton
            hoverText={intl.formatMessage(
              {
                id: 'packages.summaryDrawerHeader.timeRemainingTooltip',
              },
              {maxDays: attributes.maxDaysPackageLive}
            )}
            isQuiet
            marginStart="size-40"
            tooltipDelayMs="0"
            variant="action"
          >
            <InfoOutline size="S" />
          </TooltipButton>
        </Flex>
      )}
      {!attributes.isTemplate && attributes.package.isCorruptedPackage && (
        <Flex alignItems="center">
          <Alert color="negative" data-testid="corrupt-alert-icon" size="L" />
          <Text
            data-testid="corrupt-alert-text"
            marginStart="size-125"
            /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling */
            UNSAFE_className={styles['corrupted-text']}
          >
            {intl.formatMessage({id: 'packages.summaryDrawerHeader.corruptedMessage'})}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

PackageSummaryDrawerContentHeader.propTypes = {
  /**
   * AllPackageStore for retry package build
   */
  allPackagesStore: PropTypes.shape({
    retryBuildingPackage: PropTypes.func.isRequired,
  }),
  /**
   * Callback function to close the summary drawer
   */
  closeDrawer: PropTypes.func.isRequired,
  /**
   * Callback function that opens Customize template when customize template button is pressed
   */
  onCustomizePress: PropTypes.func,
  /**
   * Callback function that opens Update Package Dialog when Update Package button is pressed
   */
  onUpdatePress: PropTypes.func.isRequired,
};

export default PackageSummaryDrawerContentHeader;
