import {eventBus, log, navBus, toPandoraProductList} from '@admin-tribe/binky';
import {
  OverlayWait,
  Page,
  PageActions,
  PageBanner,
  PageBanners,
  PageContent,
  PageHeader,
  showSuccess,
} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import {USER_ROLES} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {
  SUPPORT_TICKET_RECORD_TYPE,
  canAccessEnterpriseSupport,
  canViewSupportContactInfo,
} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {useContentEntry} from '@pandora/react-content-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import {SupportPlanSummary, SupportPlanSummaryContentModel} from '@pandora/react-support-ticket';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';

import useDocumentTitle from '../../../../common/hooks/useDocumentTitle';
import AdobeStatusSummary from '../../components/adobe-status-summary/AdobeStatusSummary';
import HelpTopicList from '../../components/help-topic-lists/HelpTopicList';
import SupportSummaryQuickActions from '../../components/support-summary-quick-actions/SupportSummaryQuickActions';
import SupportContactInfo from '../../components/support-ticket-contact-info/SupportContactInfo';
import useProductEntitlementList from '../../hooks/useProductEntitlementList';
import {
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASE_DETAILS,
} from '../../routing/supportPaths';
import {MESSAGE, SUPPORT_CONSTANTS} from '../../support.constants';
import {getHelpTopicsTabs} from '../../utils/HelpTopicTabsUtils';

/**
 * Shows Support Summary page which has Quick action buttons and Help topics
 */
const SupportSummaryPage = () => {
  const {pathname} = useLocation();
  const {fetchProductEntitlementList} = useProductEntitlementList();
  const auth = useAuth();
  const intl = useIntl();
  const pageTitle = intl.formatMessage({
    id: 'support.summary.title',
  });
  useDocumentTitle({defaultTitle: pageTitle});

  const [cloudType, setCloudType] = useState(SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL);
  const [showBanner, setShowBanner] = useState(false);
  const [productEntitlementList, setProductEntitlementList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const tabs = useMemo(() => getHelpTopicsTabs(), []);
  const content = useContentEntry(SupportPlanSummaryContentModel);
  const isOrgOrSupportAdminOrPSA = userRoleUtils.anyOfForOrg(
    auth.getUserProfile().roles,
    [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN, USER_ROLES.PRODUCT_SUPPORT_ADMIN],
    rootStore.organizationStore.activeOrgId
  );

  const tabsToShow = tabs.map((tab) => ({
    content: <HelpTopicList cloudType={tab.key} contentModel={tab.contentModel} />,
    key: tab.key,
    name: intl.formatMessage({id: tab.name}),
    pathname: tab.pathname,
  }));

  const contractList = useMemo(
    () =>
      new JilModelList({
        items: rootStore.organizationStore.contractList.items.map(
          (contract) => new Contract(contract)
        ),
      }),
    []
  );

  const productList = useMemo(
    () => toPandoraProductList(rootStore.organizationStore.productList),
    []
  );

  const accessEnterpriseSupport = useMemo(
    () => canAccessEnterpriseSupport(contractList, productList) && isOrgOrSupportAdminOrPSA,
    [contractList, isOrgOrSupportAdminOrPSA, productList]
  );

  // Registering event for banner
  useEffect(() => {
    eventBus.registerEventHandler((eventId) => {
      if (eventId === MESSAGE.OPEN.TEAM_EXPERT_SESSION_BANNER) {
        setShowBanner(true);
      }
    });
  }, []);

  const fetchProductEntitlementListAsync = useCallback(async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      setProductEntitlementList(await fetchProductEntitlementList());
    } catch (error) {
      setHasError(true);
      log.error('Unable to fetch product entitlement list', error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchProductEntitlementList]);

  // Fetching product entitlement list
  useEffect(() => {
    if (accessEnterpriseSupport) {
      fetchProductEntitlementListAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run once
  }, []);

  const onSupportTicketCreated = (ticketId, recordType) => {
    showSuccess(
      intl.formatMessage(
        {
          id:
            recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
              ? 'pandora.reactMiniAppSupportTicketContent.createCaseModalContent.supportCaseCreatingViewContentModel.caseCreated'
              : 'pandora.reactMiniAppSupportTicketContent.requestExpertSessionModalContentModel.expertSessionCreated',
        },
        {caseId: ticketId, expertSessionId: ticketId}
      )
    );

    navBus.navigate(
      generatePath(
        recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
          ? PATH_SUPPORT_CASE_DETAILS
          : PATH_EXPERT_SESSION_DETAILS,
        {
          orgId: rootStore.organizationStore.activeOrgId,
          ticketId,
        }
      )
    );
  };

  // Find the key for the current url.
  // If not found it is a bug but default to the Tabs default.
  const selectedKey = useMemo(
    () => tabs.find((tab) => tab.pathname === pathname)?.key ?? null,
    [pathname, tabs]
  );

  const onChange = (itemKey) => {
    if (itemKey !== selectedKey) {
      setCloudType(itemKey);
      const toPath = tabsToShow.find((tab) => tab.key === itemKey)?.pathname;
      navBus.navigate(toPath);
    }
  };

  return (
    <Page data-testid="support-summary-page">
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_SUPPORT} />
        {showBanner && (
          <PageBanner
            header={intl.formatMessage({
              id: 'support.summaryRequestExpertSessionBanner.alertHeader',
            })}
          >
            {intl.formatMessage({id: 'support.summaryRequestExpertSessionBanner.alertMessage'})}
          </PageBanner>
        )}
      </PageBanners>

      <PageHeader data-testid="support-summary-page-title" title={pageTitle} />
      <PageActions>{canViewSupportContactInfo(contractList) && <SupportContactInfo />}</PageActions>
      <PageContent>
        {isOrgOrSupportAdminOrPSA ? (
          <SupportSummaryQuickActions
            canAccessEnterpriseSupport={accessEnterpriseSupport}
            canDisplayChat={chatProvider.canDisplayChat()}
            cloudType={cloudType}
            onExpertSessionCreated={(ticketId) =>
              onSupportTicketCreated(ticketId, SUPPORT_TICKET_RECORD_TYPE.EXPERT)
            }
            onGoToOpenCases={() =>
              navBus.navigate(
                generatePath(PATH_SUPPORT_CASES_OPEN, {
                  orgId: rootStore.organizationStore.activeOrgId,
                })
              )
            }
            onGoToOpenSessions={() =>
              navBus.navigate(
                generatePath(PATH_EXPERT_SESSIONS_OPEN, {
                  orgId: rootStore.organizationStore.activeOrgId,
                })
              )
            }
            onSupportCaseCreated={(ticketId) =>
              onSupportTicketCreated(ticketId, SUPPORT_TICKET_RECORD_TYPE.CASE)
            }
          />
        ) : null}
        <OverlayWait isLoading={isLoading}>
          {accessEnterpriseSupport && (
            <View backgroundColor="static-white" flex="1" margin="size-50" padding="size-160">
              <SupportPlanSummary
                content={content}
                hasError={hasError}
                onTryAgain={fetchProductEntitlementListAsync}
                orgId={rootStore.organizationStore.activeOrgId}
                productEntitlementList={productEntitlementList}
                productList={productList}
              />
            </View>
          )}
        </OverlayWait>
        {tabsToShow.length > 1 && (
          <Tabs
            aria-label={intl.formatMessage({id: 'support.helpTopicTabs.ariaLabel'})}
            items={tabsToShow}
            onSelectionChange={onChange}
            selectedKey={selectedKey}
          >
            <TabList>{(item) => <Item key={item.key}>{item.name}</Item>}</TabList>
            <TabPanels>{(item) => <Item key={item.key}>{item.content}</Item>}</TabPanels>
          </Tabs>
        )}
        {tabsToShow.length === 1 && tabsToShow[0].content}
        <AdobeStatusSummary />
      </PageContent>
    </Page>
  );
};

export default SupportSummaryPage;
