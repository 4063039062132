import {Heading} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {SOIDC_NAMES} from 'features/settings/entities/IdpEntity';

import './EduIdpCard.pcss';

const EduIdpCard = ({cardSize, logo, providerName, selected, title}) => (
  <Card
    allowsSelection={false}
    data-test-id={`${providerName.toLowerCase()}-card`}
    selected={selected}
    size="S"
    styleName="card"
  >
    <div styleName="card-body">
      <ImageIcon
        alt=""
        size={cardSize || 'XXXL'}
        src={logo}
        styleName={
          // Schoology needs a special size, not supported by the ImageIcon component
          providerName === SOIDC_NAMES.SCHOOLOGY
            ? 'provider-card-logo schoology-card'
            : 'provider-card-logo'
        }
      />
      <Heading marginBottom="0">
        <FormattedMessage id={title} />
      </Heading>
    </div>
  </Card>
);

EduIdpCard.propTypes = {
  cardSize: PropTypes.string,
  logo: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default EduIdpCard;
