import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';

import CardListContentModel from './CardListContentModel';

/**
 * Create a localized copy of RichTextJSON that will be used by the ExperienceCloud help topic card list content model.  Gets the strings themselves from xx.json.
 */
const LocalizeExperienceCloudRichTextJson = (intl, locale) => {
  const shortLocale = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale];
  return {
    helpTopicCards: [
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/dma_setup_guide_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_xc_user_manage_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_xc_learn_more_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            // 4th card
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_xc_support_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_xc_dps_getting_started_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.5.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.5.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_xc_download_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'experienceCloudHelpTopicListContentModel.helpTopicCards.5.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
    ],
  };
};

/**
 * Definition of the ExperienceCloud help topic card list.
 */
const ExperienceCloud = (intl, locale) =>
  CardListContentModel(LocalizeExperienceCloudRichTextJson(intl, locale));

export default ExperienceCloud;
