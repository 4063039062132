import {TooltipButton} from '@pandora/react-tooltip-button';
import PropTypes from 'prop-types';
import React from 'react';
/**
 * Contents of table cell in list of devices that need renewal.
 */
const DeviceRenewalsDrawerCell = ({value}) => {
  const styleActionButton = {
    backgroundColor: 'transparent',
  };
  return (
    <TooltipButton hoverText={value} isQuiet UNSAFE_style={styleActionButton} variant="action">
      {value}
    </TooltipButton>
  );
};

DeviceRenewalsDrawerCell.propTypes = {
  /**
   * value to render under the Row Cell
   */
  value: PropTypes.string.isRequired,
};

DeviceRenewalsDrawerCell.displayName = 'DeviceRenewalsDrawerCell';

export default DeviceRenewalsDrawerCell;
