import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {UPDATE_STRATEGIES} from 'features/settings/entities/IdpEntity';

const UPDATE_STRATEGIES_LABEL = {
  [UPDATE_STRATEGIES.ALWAYS_UPDATE]: 'settings.updateUserInformation.alwaysUpdateUser',
  [UPDATE_STRATEGIES.DO_NOT_UPDATE]: 'settings.updateUserInformation.dontUpdateUser',
  [UPDATE_STRATEGIES.UPDATE_WHEN_NOT_EMPTY]: 'settings.updateUserInformation.updateWhenNotEmpty',
};

const UpdateStrategy = observer(({updateStrategy}) => {
  const updateStrategyLabel =
    UPDATE_STRATEGIES_LABEL[updateStrategy] ||
    UPDATE_STRATEGIES_LABEL[UPDATE_STRATEGIES.DO_NOT_UPDATE];

  return <FormattedMessage id={updateStrategyLabel} />;
});

export default UpdateStrategy;
export {UPDATE_STRATEGIES_LABEL};
