import {Store, ims, log} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';
import {action, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';
import {
  createAuthSource,
  getAttributeMappings,
  putAttributeMapping,
} from 'features/settings/api/ims-federated';
import DirectoryStore, {UPDATE_STRATEGIES} from 'features/settings/stores/DirectoryStore';

const SETUP_AUTHENTICATION_STEPS = {
  SETUP_IDP: {
    id: 'SETUP_IDP',
  },
  SETUP_JIT: {
    id: 'SETUP_JIT',
  },
};

const SETUP_SAML_CERTS = {
  id: 'SETUP_SAML_CERTS',
};

const SETUP_SECTIONS = {
  DIRECTORY_INFO: {
    id: 'DIRECTORY_INFO',
  },
  SELECT_IDP: {
    id: 'SELECT_IDP',
  },
  SETUP_AUTHENTICATION: {
    id: 'SETUP_AUTHENTICATION',
  },
};

const COUNTRY_CODE_ATTRIBUTE = 'COUNTRY_CODE';

class DirectorySetupStore extends Store {
  countries = [];
  currentSetupSection = SETUP_SECTIONS.AUTHENTICATION;
  data = {
    defaultCountry: undefined,
    jitStatus: true,
    metadataFile: null,
    mfaAuthnContextClasses: [],
    reauthAuthnContextClasses: [],
    updateStrategy: UPDATE_STRATEGIES.DO_NOT_UPDATE,
  };

  directoryId = '';
  directoryStore;
  hasIdpChanges = false;
  hasJitChanges = false;
  idp;
  isCancelingOutOfJitConfig = true;
  isEditingAzureIdp = false;
  isEditingIdp = false;
  isLoadingCountries = false;
  newTenantId = '';
  pristineData = cloneDeep(this.data);

  constructor(directoryStore) {
    super();

    this.directoryStore = directoryStore ?? new DirectoryStore();

    makeObservable(this, {
      countries: observable,
      createDirectory: action,
      createIdp: action,
      currentSetupSection: observable,
      data: observable,
      hasIdpChanges: observable,
      hasJitChanges: observable,
      idp: observable,
      // This state tracks the cancellation of jit config without any changes made.
      // Based on this an alert dialog is shown informing the user that JIT
      // is enabled by default and cancelling will not disable it.
      // The value of the state is true by default since no changes have been made to JIT yet.
      isCancelingOutOfJitConfig: observable,
      isEditingAzureIdp: observable,
      isEditingIdp: observable,
      isLoadingCountries: observable,
      loadCountries: action,
      newTenantId: observable,
      removeIdp: action,
      revertChanges: action,
      setDefaultIdp: action,
      setIdp: action,
      setJitStatus: action,
      setSamlIdpAttributes: action,
      setSetupSection: action,
      setUpdateStrategy: action,
    });
  }

  async createDirectory({name, type}) {
    this.isLoading = true;

    try {
      const response = await createAuthSource({
        name,
        orgId: rootStore.organizationStore.activeOrgId,
        type,
      });

      runInAction(() => {
        this.directoryStore.mapData(response.data);
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error creating new directory in org ${rootStore.organizationStore.activeOrgId}`,
        error
      );

      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createIdp(idpData) {
    this.isLoading = true;

    try {
      const idp = await this.directoryStore.createIdp(idpData);

      runInAction(() => {
        this.isLoading = false;
        this.setIdp(idp);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async getDefaultCountry() {
    this.isLoading = true;

    try {
      const response = await getAttributeMappings({
        authSourceId: this.directoryStore.directoryId,
        idpId: this.idp.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      const mappings = response.data.mappings;
      const countryMapping = mappings.find(
        (mapping) => mapping.targetProperty === COUNTRY_CODE_ATTRIBUTE
      );

      if (countryMapping) {
        runInAction(() => {
          this.data.defaultCountry = countryMapping.expression?.fallbackValue;
          this.pristineData.defaultCountry = this.data.defaultCountry;
        });
      }
    } catch (error) {
      log.error('[IMS][JIT] Error loading default country', error);

      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async loadCountries() {
    // Don't reload the countries if we already have the list
    if (this.countries.length > 0) {
      return;
    }

    this.isLoadingCountries = true;

    try {
      const countriesResponse = await ims.getCountries();

      runInAction(() => {
        this.countries = countriesResponse.data.countries.map((country) => ({
          id: country.countryCode,
          name: country.countryName,
        }));
      });
    } catch (error) {
      log.error('[IMS][JIT] Error loading countries', error);

      throw error;
    } finally {
      this.isLoadingCountries = false;
    }
  }

  async removeIdp(idpId) {
    await this.directoryStore.removeIdp(idpId);
  }

  revertChanges() {
    this.data = cloneDeep(this.pristineData);
    this.setJitChanges(false);
    this.setIdpChanges(false);
  }

  setDefaultCountry(value) {
    const isValueChanged = this.data.defaultCountry !== value;

    this.data.defaultCountry = value;
    this.setJitChanges(isValueChanged);
  }

  async setDefaultIdp(idpId) {
    await this.directoryStore.setDefaultIdp(idpId);
  }

  setIdp(value) {
    this.idp = value;

    // If we have a configured idp simulate a selected file
    if (this.idp.isActive) {
      this.data.metadataFile = new File([], 'metadata.xml');
    }

    this.data.jitStatus = this.idp.jitStatus ?? true;
    this.data.updateStrategy = this.idp.updateStrategy ?? UPDATE_STRATEGIES.DO_NOT_UPDATE;
    this.data.mfaAuthnContextClasses = this.idp.mfaAuthnContextClasses;
    this.data.reauthAuthnContextClasses = this.idp.reauthAuthnContextClasses;

    // set pristine data to the new data
    this.pristineData = cloneDeep(this.data);
  }

  setIdpChanges(value) {
    this.hasIdpChanges = value;
  }

  setIdpMetadataFile(file) {
    this.data.metadataFile = file;
    this.setIdpChanges(true);
  }

  setJitChanges(value) {
    this.hasJitChanges = value;
  }

  setJitStatus(status) {
    this.data.jitStatus = status;
    this.isCancelingOutOfJitConfig = false;
    this.setJitChanges(true);
  }

  setSamlIdpAttributes(attributes) {
    this.data.mfaAuthnContextClasses = attributes.mfaAuthnContextClasses;
    this.data.reauthAuthnContextClasses = attributes.reauthAuthnContextClasses;

    this.setIdpChanges(true);
  }

  setSetupSection(section) {
    this.currentSetupSection = section;
  }

  setUpdateStrategy(strategy) {
    this.data.updateStrategy = strategy;
    this.setJitChanges(true);
  }

  async updateDefaultCountry(propertyName, defaultCountry) {
    this.isLoading = true;

    try {
      await putAttributeMapping({
        authSourceId: this.directoryStore.directoryId,
        data: {
          fallbackValue: defaultCountry,
          kind: 'fallbackCountry',
          preferred: {
            kind: 'propertyAccess',
            propertyName,
          },
        },
        idpId: this.idp.id,
        orgId: rootStore.organizationStore.activeOrgId,
        property: COUNTRY_CODE_ATTRIBUTE,
      });

      runInAction(() => {
        this.data.defaultCountry = defaultCountry;
        this.pristineData.defaultCountry = defaultCountry;
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error updating defaultCountry in org ${rootStore.organizationStore.activeOrgId} for directory ${this.directoryStore.directoryId} and IdP ${this.idp.id}`,
        error
      );

      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async updateIdp({idpId, data}) {
    await this.directoryStore.updateIdp({data, idpId});

    const updatedIdp = this.directoryStore.idps.find((idp) => idp.id === idpId);

    this.setIdp(updatedIdp);
  }
}

export {COUNTRY_CODE_ATTRIBUTE, SETUP_AUTHENTICATION_STEPS, SETUP_SECTIONS, SETUP_SAML_CERTS};
export default DirectorySetupStore;
