import {Heading, Text, TextField, View} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import ProviderV2 from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {AUTH_SOURCE_TYPES} from 'features/settings/api/ims-federated';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import DirectoryTypeCard from 'features/settings/components/directories/create-directory-modal/create-directory-section/DirectoryTypeCard';

const DIRECTORY_TYPE_CARDS = [
  {
    benefits: [
      'settings.directories.createDirectorySection.federated.canUseSSO',
      'settings.directories.createDirectorySection.federated.canSync',
    ],
    description: 'settings.directories.createDirectorySection.federated.description',
    directoryType: AUTH_SOURCE_TYPES.FEDERATED,
    goUrlName: 'aac_create_directory_federated',
    learnMore: 'settings.directories.createDirectorySection.learnMoreAboutFederated',
    testId: 'federated-directory-card',
    title: 'settings.directories.createDirectorySection.federated.title',
  },
  {
    benefits: [
      'settings.directories.createDirectorySection.enterprise.createOwnPassword',
      'settings.directories.createDirectorySection.enterprise.noIdentityProvider',
      'settings.directories.createDirectorySection.enterprise.easySetup',
    ],
    description: 'settings.directories.createDirectorySection.enterprise.description',
    directoryType: AUTH_SOURCE_TYPES.ENTERPRISE,
    goUrlName: 'aac_create_directory_enterprise',
    learnMore: 'settings.directories.createDirectorySection.learnMoreAboutEnterprise',
    testId: 'enterprise-directory-card',
    title: 'settings.directories.createDirectorySection.enterprise.title',
  },
];

const CreateDirectorySection = observer(({onDirectoryNameChange, onSelectDirectoryType}) => {
  const intl = useIntl();

  return (
    <>
      <Text>
        <FormattedMessage id="settings.directories.createDirectorySection.description" />
      </Text>

      <Heading level={4}>
        <FormattedMessage id="settings.directories.createDirectorySection.directoryNameHeading" />
      </Heading>
      <TextField
        data-test-id="directory-name-input"
        label={intl.formatMessage({
          id: 'settings.directories.createDirectorySection.directoryNameLabel',
        })}
        onChange={onDirectoryNameChange}
        width="size-4600"
      />

      <Heading level={4}>
        <FormattedMessage id="settings.directories.createDirectorySection.directoryTypeHeading" />
      </Heading>

      <ProviderV2>
        <OptionSelectorWrapper>
          <OptionSelector
            data={DIRECTORY_TYPE_CARDS}
            options={{
              on_selection_change: (event) =>
                event.selected && onSelectDirectoryType?.(event.data.directoryType),
              preventDeselection: true,
            }}
            react_function={(props) => (
              <View>
                <DirectoryTypeCard {...props} />
              </View>
            )}
          />
        </OptionSelectorWrapper>
      </ProviderV2>
    </>
  );
});

CreateDirectorySection.propTypes = {
  onDirectoryNameChange: PropTypes.func,
  onSelectDirectoryType: PropTypes.func,
};

export default CreateDirectorySection;
