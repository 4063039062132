const USER_GROUP_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.users.USER_GROUP_CONSTANTS;
(function () {
  /**
   * @deprecated please use src2/app/services/users/UserGroupConstants
   */
  angular
    .module('app.core.user-group')
    .constant('USER_GROUP_THRESHOLD_GO_URL', USER_GROUP_CONSTANTS.USER_GROUP_THRESHOLD_GO_URL)
    .constant(
      'USER_GROUP_USER_COUNT_THRESHOLD',
      USER_GROUP_CONSTANTS.USER_GROUP_USER_COUNT_THRESHOLD
    )
    .constant('USER_GROUP_EVENT', USER_GROUP_CONSTANTS.USER_GROUP_EVENT);
})();
