import {Flex, SearchField} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {v4 as uuid4} from 'uuid';

import StatusCard from './status-card/StatusCard';

/**
 * Represents scrollable list of status events
 * displayed in the status tab
 */
const StatusList = ({eventsToDisplay}) => {
  const intl = useIntl();
  const [statusFilter, setStatusFilter] = useState('');

  // Return status event if search field empty or if it's display name contains searched string as substring
  // create regex based on search term to filter the items (case insensitive search)
  function searchByStatusFilter(statusEvent) {
    if (statusFilter === '') {
      return statusEvent;
    }

    const regEx = new RegExp(statusFilter, 'i');

    return (
      (statusEvent &&
        (regEx.test(statusEvent.name) ||
          regEx.test(statusEvent.incidentSeverity) ||
          regEx.test(statusEvent.status) ||
          regEx.test(statusEvent.id))) ||
      regEx.test(statusEvent.incidentId)
    );
  }

  return (
    <Flex direction="column">
      <SearchField
        aria-label={intl.formatMessage({
          id: 'status.panel.searchField.ariaLabel',
        })}
        isQuiet
        marginBottom="size-100"
        minWidth="100%"
        onChange={setStatusFilter}
        value={statusFilter}
      />
      <Flex direction="column">
        {eventsToDisplay.filter(searchByStatusFilter).map((statusEvent) => (
          <StatusCard key={uuid4()} event={statusEvent} />
        ))}
      </Flex>
    </Flex>
  );
};

StatusList.propTypes = {
  /**
   * A list of both incident and maintenance events
   * with status and associated product information
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  eventsToDisplay: PropTypes.arrayOf(PropTypes.object),
};
export default StatusList;
