import binky from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import useCancellationItem from '../../hooks/use-cancellation-item/useCancellationItem';
import SingleProductCancelItem from '../single-product-cancel-item/SingleProductCancelItem';

/**
 * SeatSelectorItemHeader component, which passes product and pricing information to
 * SingleProductCancelItem component. Will be used by Accordion and single product views.
 */
const SeatSelectorItemHeader = ({contract, isLoading, product, selectedLicenses = []}) => {
  const imageSrc = product.getIcon();
  const productName = product.longName;
  const term = contract.getBillingFrequency();
  const unit = 'LICENSE';

  const {currency, isMultiPrice, pricePerUnit, priceTotal, unitsAssigned, unitsTotal} =
    useCancellationItem({
      product,
      selectedLicenses,
    });

  return (
    <SingleProductCancelItem
      currency={currency}
      imageSrc={imageSrc}
      isLoading={isLoading}
      isMultiPrice={isMultiPrice}
      pricePerUnit={pricePerUnit}
      priceTotal={priceTotal}
      productName={productName}
      term={term}
      unit={unit}
      unitsAssigned={unitsAssigned}
      unitsTotal={unitsTotal}
    />
  );
};

SeatSelectorItemHeader.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(binky.models.contract.Contract).isRequired,
  /**
   * Whether the seat selector header should display the loading indicator or not
   */
  isLoading: PropTypes.bool,
  /**
   * The list of org's products.
   */
  product: PropTypes.instanceOf(binky.services.product.Product).isRequired,
  /**
   * The array of initially selected licenses (controlled).
   */
  selectedLicenses: PropTypes.arrayOf(PropTypes.string),
};

export default SeatSelectorItemHeader;
