(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc component
   * @name app.widgets.adobe-status:appAdobeStatusSummary
   *
   * @description the Adobe status summary, displayed on the support page to give a one-line indicator
   *   of the current status of Adobe's systems and services
   *
   * @example <app-adobe-status-summary></app-adobe-status-summary>
   *
   */
  angular.module('app.widgets.adobe-status').component('appAdobeStatusSummary', {
    controller,
    templateUrl: 'app/widgets/adobe-status/adobe-status-summary.component.html',
  });

  /* @ngInject */
  function controller($q, $rootScope, $translate, _, AdobeStatusDataList, ADOBE_STATUS_MESSAGES) {
    const vm = this;
    _.assign(vm, {
      $onDestroy,
      $onInit,
      error: false,
      statusLabel: '',
      statusVariant: 'default',
    });

    function $onInit() {
      vm.waitOnStatus = $q.defer();

      vm.adobeStatusDataList = AdobeStatusDataList.get();
      vm.adobeStatusDataList.$promise
        .then(() => {
          setStatusMessage();
          vm.error = false;
          vm.waitOnStatus.resolve();
          // listen for updates to the status list during polling
          vm.onAdobeStatusUpdateDeregister = $rootScope.$on(ADOBE_STATUS_MESSAGES.REFRESH, () => {
            setStatusMessage();
          });
        })
        .catch((error) => {
          vm.error = true;
          vm.waitOnStatus.reject(error);
        });
    }

    function $onDestroy() {
      if (vm.onAdobeStatusUpdateDeregister) {
        vm.onAdobeStatusUpdateDeregister();
      }
    }

    function setStatusMessage() {
      if (vm.adobeStatusDataList.hasOngoingEvents()) {
        vm.statusVariant = 'error';
        vm.statusLabel = $translate.instant('widgets.adobeStatus.summary.messages.ongoingEvents');
      } else if (vm.adobeStatusDataList.hasOngoingMaintenance()) {
        vm.statusVariant = 'default';
        vm.statusLabel = $translate.instant(
          'widgets.adobeStatus.summary.messages.ongoingMaintenance'
        );
      } else {
        vm.statusVariant = 'success';
        vm.statusLabel = $translate.instant('widgets.adobeStatus.summary.messages.allNormal');
      }
    }
  }
})();
