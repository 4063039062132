/**
 * @deprecated use src2 Support pages
 *
 * @description a filter to display the priority string for a support case, optionally overriding the priority
 *  with 'Escalated' if isEscalated is true.
 * @param {Integer} priority Should be one of the values in SUPPORT_CASE_PRIORITY.
 * @param {Boolean} isEscalated [false] If true, the 'Escalated' will be returned rather than the priority string.*
 * @returns {String} formatted priority string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appSupportCasePriority', appSupportCasePriorityFilter);

  /* @ngInject */
  function appSupportCasePriorityFilter($translate, _, SUPPORT_CASE_PRIORITY) {
    return getPriorityLabel;

    function getPriorityLabel(priority, isEscalated = false) {
      if (isEscalated) {
        return $translate.instant('widgets.support.supportCasePriorityFilter.escalated');
      }
      if (_.includes(SUPPORT_CASE_PRIORITY, priority)) {
        return $translate.instant(
          `widgets.support.supportCasePriorityFilter.priority.p${priority}`
        );
      }
      return '';
    }
  }
})();
