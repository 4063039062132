(function () {
  /**
   * @deprecated moved to src2 as <SupportTicketActionModal>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportCaseReopenModal
   *
   * @description Modal which allows a support case to be reopened.
   */
  angular.module('app.widgets.support').component('appSupportCaseReopenModal', {
    bindings: {
      supportCase: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-case-reopen-modal/support-case-reopen-modal.component.html',
  });

  function controller(
    _,
    $filter,
    $translate,
    SUPPORT_CASE_REOPEN_MODAL_ID,
    SUPPORT_TICKET_ACTION,
    SUPPORT_TICKET_ACTION_REASON_CODE,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onSuccess,
      SUPPORT_CASE_REOPEN_MODAL_ID,
      SUPPORT_TICKET_ACTION,
    });

    function $onInit() {
      vm.reasons = _.map(SUPPORT_TICKET_ACTION_REASON_CODE.REOPEN, (code) => ({
        code,
        label: $filter('appSupportCaseReopenReason')(code),
      }));
    }

    function onSuccess() {
      const toastMsg = $translate.instant('widgets.support.supportCaseReopenModal.toast.success', {
        supportCaseId: vm.supportCase.id,
      });
      toastManager.showSuccessToast(toastMsg);
    }
  }
})();
