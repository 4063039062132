import {Divider} from '@adobe/react-spectrum';
import {ModalContent, ModalHeading} from '@pandora/react-modal-dialog';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDeleteUsers} from 'common/components/remove-users-with-storage-modal//hooks/DeleteUsersModalContext';
import RemoveUsersListTable from 'common/components/remove-users-with-storage-modal/components/remove-users-list-table/RemoveUsersListTable';

/**
 * The action required step for when the delete users with storage modal is opened with multiple users.
 */
const ActionRequiredStep = () => {
  const intl = useIntl();
  const {usersWithStorage, usersWithoutStorage} = useDeleteUsers();

  return (
    <>
      <ModalHeading>
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.actionRequired.heading',
        })}
      </ModalHeading>
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <ModalContent>
        <RemoveUsersListTable
          descriptionKey="common.removeUsersWithStorageModal.steps.actionRequired.usersWithContent"
          users={usersWithStorage}
        />
        {usersWithoutStorage.length > 0 && (
          <RemoveUsersListTable
            descriptionKey="common.removeUsersWithStorageModal.steps.actionRequired.noFurtherAction"
            users={usersWithoutStorage}
          />
        )}
      </ModalContent>
    </>
  );
};

export default ActionRequiredStep;
