import {
  NavigationAnchor,
  ProductProfileIcon,
  TableIconNameCell,
  TableSectionTable,
  ViewDetailsDrawerTrigger,
  getProductDisplayName,
} from '@admin-tribe/binky-ui';
import {Link} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductProfileDetailDrawer from 'features/products/product-profiles/product-profile-detail-drawer/ProductProfileDetailDrawer';
import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';
import {getHrefForProductProfileDetails} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

// The table of product profiles shown under the Product 'Product profiles' sub-tab.
const ProductProfileTable = ({display, onCloseDrawer}) => {
  const {consumableSummarizationList, product} = useProductProfileTableContext();
  const {tableSectionUtils} = useTableSectionContext();
  const intl = useIntl();

  const columns = [
    {
      key: 'name',
      props: {allowsSorting: true},
    },
    {
      key: 'viewDetails',
      props: {align: 'end', hideHeader: true, showDivider: true},
    },
    {
      key: 'productName',
    },
    {
      key: 'entitledUsers',
      props: {align: 'end'},
    },
    {
      key: 'totalQuantity',
      props: {align: 'end'},
    },

    {
      key: 'adminCount',
      props: {align: 'end'},
    },

    {
      key: 'notifyUser',
    },

    {
      key: 'licensesUsed',
      props: {align: 'end'},
    },
  ];

  const columnDescriptor = columns.filter((column) => display[column.key] === true);

  const renderers = {
    adminCount: getFormattedNumber,
    entitledUsers: getFormattedNumber,
    licensesUsed: getLicensesUsed,
    name: getName,
    notifyUser: getNotifyUser,
    productName: getProductName,
    totalQuantity: getTotalQuantity,
    viewDetails: getViewDetailsDrawerTrigger,
  };

  function getName({item}) {
    return (
      <TableIconNameCell IconComponent={<ProductProfileIcon productProfile={item} />}>
        {item.isAdministerable() ? (
          <Link>
            <NavigationAnchor
              href={getHrefForProductProfileDetails({
                productId: item.product.id,
                profileId: item.id,
              })}
            >
              {item.name}
            </NavigationAnchor>
          </Link>
        ) : (
          item.name
        )}
      </TableIconNameCell>
    );
  }

  function getViewDetailsDrawerTrigger({item}) {
    return (
      item.isAdministerable() && (
        <ViewDetailsDrawerTrigger tooltipName={item.name}>
          {() => (
            <ProductProfileDetailDrawer
              onClose={() => onCloseDrawer({productProfile: item, tableSectionUtils})}
              productProfile={item}
            />
          )}
        </ViewDetailsDrawerTrigger>
      )
    );
  }

  function getFormattedNumber({item, key}) {
    const value = key === 'entitledUsers' ? item.provisionedQuantity : item[key];
    return intl.formatNumber(value);
  }

  function getProductName({item}) {
    return getProductDisplayName(intl, item.product);
  }

  function getNotifyUser({item}) {
    const subkey = item.notifications ? 'on' : 'off';
    return intl.formatMessage({id: `products.productProfileTable.notifications.${subkey}`});
  }

  function getLicensesUsed({item}) {
    const profileConsumables = consumableSummarizationList?.getConsumablesForSummaryId(item.id);
    // Why the assumption that we should use the first consumable?
    const quantity = profileConsumables?.[0]?.consumedQuantity;
    if (quantity !== undefined) {
      return intl.formatNumber(quantity);
    }
    // TableSectionTable will display EN_DASH when undefined is rendered.
    return undefined;
  }

  function getTotalQuantity({item, key}) {
    // TableSectionTable will display EN_DASH when undefined is rendered.
    return product.usesSeatBasedDelegation() ? getFormattedNumber({item, key}) : undefined;
  }

  return (
    <TableSectionTable
      aria-label={intl.formatMessage(
        {id: 'products.productProfileTable.ariaLabel'},
        {
          product: getProductDisplayName(intl, product),
        }
      )}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.productProfileTable.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'products.productProfileTable.noItemsFoundContentMessage',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'products.productProfileTable.noSearchResultsContentMessage',
      })}
      renderers={renderers}
    />
  );
};

ProductProfileTable.propTypes = {
  /**
   * The object which determines which columns in the table are shown.
   * To show a column, set the property which coresponds with the column to true.
   */
  display: PropTypes.shape({
    adminCount: PropTypes.bool,
    entitledUsers: PropTypes.bool,
    licensesUsed: PropTypes.bool,
    name: PropTypes.bool,
    notifyUser: PropTypes.bool,
    productName: PropTypes.bool,
    totalQuantity: PropTypes.bool,
    viewDetails: PropTypes.bool,
  }).isRequired,
  /**
   * The method to call when the drawer is closed.
   * It should udpate the product profile if there are any unsaved changed.
   */
  onCloseDrawer: PropTypes.func.isRequired,
};

ProductProfileTable.displayName = 'ProductProfileTable';

export default ProductProfileTable;
