import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SelfCancelCard.pcss';

/**
 * A card for self-cancel flow,
 * it will be used to display information on loss aversion and promotional deals to retain customers.
 */
const SelfCancelCard = ({
  borderRadius = 'regular',
  children,
  headerComponent,
  headerBackgroundColor = 'gray-200',
  testId,
  title,
  titleLevel = 3,
}) => (
  <View
    backgroundColor="gray-50"
    borderColor="gray-200"
    borderRadius={borderRadius}
    borderWidth="thin"
    data-testid={testId}
    height="100%"
    minHeight="360px"
    width="256px"
  >
    <Flex direction="column" height="100%">
      <View
        backgroundColor={headerBackgroundColor}
        borderTopEndRadius={borderRadius}
        borderTopStartRadius={borderRadius}
        data-testid="header-wrapper"
        flexShrink="0"
        height="size-900"
      >
        {headerComponent}
      </View>
      <View flex="1" paddingBottom="size-250" paddingTop="size-300" paddingX="size-300">
        <Flex data-testid="card-content-wrapper" direction="column" height="100%">
          <Heading
            level={titleLevel}
            marginBottom="size-0"
            marginTop="size-0"
            minHeight="size-900"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change text size
            UNSAFE_className={styles['heading-text']}
          >
            {title}
          </Heading>
          {children}
        </Flex>
      </View>
    </Flex>
  </View>
);

SelfCancelCard.propTypes = {
  /**
   * The border radius on all four sides of the card. Defaults to 'regular'.
   */
  borderRadius: PropTypes.oneOf(['xsmall', 'small', 'regular', 'medium', 'large']),
  /**
   * The text display section, can include an optional button.
   */
  children: PropTypes.node.isRequired,
  /**
   * Color of the top section of the card unless it is not headerComponent is an icon.
   */
  headerBackgroundColor: PropTypes.string,
  /**
   * Could be an icon wrapped in a flex or an image depending on the card.
   */
  headerComponent: PropTypes.node,
  /**
   * Custom data test id.
   */
  testId: PropTypes.string,
  /**
   * The card title with the most important information to convey to the user.
   */
  title: PropTypes.node.isRequired,
  /**
   * Adjusts the size of the title above, defaults to h3
   */
  titleLevel: PropTypes.number,
};

export default SelfCancelCard;
