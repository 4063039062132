import {toBinkyProduct} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import {ITEM_TYPE} from '@pandora/react-assignment-modal';
import {
  AssignmentModalSection,
  AssignmentModalSectionContentModel,
  AssignmentSectionContext,
} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {transformToPandoraProductsList} from 'common/utils/products-utils/getPandoraProducts';

import {useAdminModalContext} from '../AddAdminModalContext';

const {toPandoraProductTargets} = binkyUI.common.components.assignmentSectionUtils;

const ProductSelectModal = ({onItemAssignment, itemsToPreselect, itemsToDisable}) => {
  const [pandoraItemsToDisable, setPandoraItemsToDisable] = useState([]);
  const [pandoraItemsToPreselect, setPandoraItemsToPreselect] = useState(undefined);
  const [pandoraAssignmentSectionValue, setPandoraAssignmentSectionValue] = useState({});
  const {orgId, products, user} = useAdminModalContext();

  const binkyItemMap = useRef({
    [ITEM_TYPE.PRODUCTS]: [],
  });
  const assignedItems = useRef({});
  const content = useContentEntry(AssignmentModalSectionContentModel);
  const productTargets = toPandoraProductTargets([ITEM_TYPE.PRODUCTS]);

  const onItemAssignmentInternal = useCallback(
    (items) => {
      assignedItems.current = onItemAssignment(items);
    },
    [onItemAssignment]
  );

  const productAssignmentModalOnChange = useCallback(
    (itemMap) => {
      if (itemMap && itemMap[ITEM_TYPE.PRODUCTS]) {
        const combinedItemMap = {
          ...binkyItemMap.current,
          [ITEM_TYPE.PRODUCTS]: itemMap[ITEM_TYPE.PRODUCTS].map((product) =>
            toBinkyProduct(product)
          ),
        };
        binkyItemMap.current = combinedItemMap;
        onItemAssignmentInternal([...combinedItemMap[ITEM_TYPE.PRODUCTS]]);
      }
    },
    [onItemAssignmentInternal]
  );

  // Generate the AssignmentModal's "preselectedItems" from the given parameters
  useEffect(() => {
    if (itemsToPreselect[ITEM_TYPE.PRODUCTS]) {
      setPandoraItemsToPreselect({
        // "Products" are just ID strings in this format so they don't need conversion.
        [ITEM_TYPE.PRODUCTS]: itemsToPreselect[ITEM_TYPE.PRODUCTS],
      });
    }
  }, [itemsToPreselect]);

  // Generate the AssignmentModal's "disabledItems" from the given parameters
  useEffect(() => {
    setPandoraItemsToDisable({
      [ITEM_TYPE.PRODUCTS]:
        itemsToDisable && itemsToDisable[ITEM_TYPE.PRODUCTS]
          ? itemsToDisable[ITEM_TYPE.PRODUCTS]
          : [],
    });
  }, [itemsToDisable]);

  // Update the pandora-assignment section value when the products or pandoraItemsToDisable or pandoraItemsToPreselect change.
  useEffect(() => {
    if (products?.length > 0) {
      setPandoraAssignmentSectionValue({
        itemsToDisable: pandoraItemsToDisable,
        itemsToPreselect: pandoraItemsToPreselect,
        products: transformToPandoraProductsList(products),
        setItemsToPreselect: setPandoraItemsToPreselect,
      });
    }
  }, [pandoraItemsToDisable, pandoraItemsToPreselect, products]);

  return (
    <AssignmentSectionContext.Provider
      // We cannot convert Binky Products to Pandora Products here, this gets run
      // every render, and the conversion is too heavy-weight.
      value={pandoraAssignmentSectionValue}
    >
      <Flex direction="column" gap="size-200" marginTop="size-200">
        <AssignmentModalSection
          content={content}
          member={user}
          onChange={productAssignmentModalOnChange}
          orgId={orgId}
          showButtonLabel={false}
          targets={productTargets}
        />
      </Flex>
    </AssignmentSectionContext.Provider>
  );
};

ProductSelectModal.propTypes = {
  itemsToDisable: PropTypes.instanceOf(Object),
  itemsToPreselect: PropTypes.instanceOf(Object),
  onItemAssignment: PropTypes.func.isRequired,
};

export default ProductSelectModal;
