// Note: To trace state transitions, inject $trace, and call $trace.enable(1).
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app').run(runApp);

  /* @ngInject */
  function runApp(
    analyticsReady,
    authenticationReady,
    bumperReady,
    chatReady,
    extendedUserProfileReady,
    featureReady,
    fraudMitigationReady,
    gainsightReady,
    localeReady,
    mainBoot,
    navReady,
    observabilityReady,
    orgReady,
    src2Ready,
    termsRedirectReady
  ) {
    mainBoot.makeReady(
      analyticsReady,
      authenticationReady,
      bumperReady,
      chatReady,
      extendedUserProfileReady,
      fraudMitigationReady,
      featureReady,
      gainsightReady,
      localeReady,
      navReady,
      observabilityReady,
      orgReady,
      src2Ready,
      termsRedirectReady
    );
  }
})();
