(function () {
  angular.module('app.core.remove-on-read-only', [
    'app.core.auth',
    'app.core.contract.migrations',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'binky.core.feature',
    'binky.core.product',
  ]);
})();
