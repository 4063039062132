import {Content, Flex, Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {GoUrl} from '@pandora/react-go-url';
import {TitledSection} from '@pandora/react-titled-section';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Detail drawer for libraries page
 */
const LibrariesDetailDrawer = () => {
  const intl = useIntl();

  return (
    <Drawer aria-label={intl.formatMessage({id: 'storage.libraries.drawer.ariaLabel'})}>
      <Header>
        <Heading level={2} marginBottom="size-125" marginTop="size-0">
          {intl.formatMessage({id: 'storage.libraries.title'})}
        </Heading>
      </Header>
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>{intl.formatMessage({id: 'storage.libraries.drawer.description.overview'})}</Text>
          <Text>{intl.formatMessage({id: 'storage.libraries.drawer.description.active'})}</Text>
          <Text>{intl.formatMessage({id: 'storage.libraries.drawer.description.inactive'})}</Text>
        </Flex>
        <TitledSection
          marginTop="size-200"
          title={intl.formatMessage({
            id: 'storage.libraries.drawer.helpfulLink',
          })}
        >
          <View marginTop="size-150">
            <GoUrl name="aac_adobe_workspaces">
              {intl.formatMessage({id: 'storage.libraries.drawer.goUrl'})}
            </GoUrl>
          </View>
        </TitledSection>
      </Content>
    </Drawer>
  );
};

export default LibrariesDetailDrawer;
