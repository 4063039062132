(function () {
  /**
   * @deprecated use binkySrc2.services.collaborationPolicies.CollaborationPolicies
   *
   * @ngdoc factory
   * @name app.core.asset-sharing:whitelisted-domain-list
   * @description class wrapper to implement local pagination on whitelisted domains
   */
  angular.module('app.core.asset-sharing').factory('WhitelistedDomainList', whitelistedDomainList);

  function whitelistedDomainList(
    $filter,
    _,
    DNS_MAX_DOMAIN_LEN,
    DNS_MAX_DOMAIN_PART_LEN,
    DOMAIN_REGEX,
    JIL_CONSTANTS,
    LIST_DEFAULT_PAGE_SIZE
  ) {
    const SORT_ORDER_DEFAULT = JIL_CONSTANTS.ORDER.ASC;

    class PaginatedList {
      constructor(items = []) {
        _.assign(this, {
          items,
          workItems: _.cloneDeep(items),
        });
      }

      clear() {
        this.workItems = _.cloneDeep(this.items);
        this.itemCount = this.workItems.length;
        return this;
      }

      getItemCount() {
        return this.itemCount;
      }

      paginate(pageNumber, pageSize) {
        this.workItems = _.slice(
          this.workItems,
          (pageNumber - 1) * pageSize,
          pageNumber * pageSize
        );
        return this;
      }

      search(searchQuery) {
        const expression = {name: searchQuery};
        this.workItems = $filter('filter')(this.workItems, expression);
        this.itemCount = this.workItems.length;
        return this;
      }

      sort(sortFn) {
        this.workItems.sort(sortFn);
        return this;
      }

      value() {
        return this.workItems;
      }
    }

    class WhitelistedDomainList {
      constructor({items = [], pageNumber = 1, pageSize = LIST_DEFAULT_PAGE_SIZE}) {
        _.assign(this, {
          allItems: items,
          items: [],
          paginatedList: new PaginatedList(items),
          pagination: {
            currentPage: pageNumber,
            itemCount: items.length,
            pageSize,
          },
          search: {
            currentSearchQuery: '',
          },
          SEARCH_QUERY_MIN_LENGTH: 3,
          sort: {
            currentSortOrder: SORT_ORDER_DEFAULT,
          },
        });
      }

      /**
       * @description method to determine if array of new domains are valid.
       * @param {String[]} domainsToAdd array of domain names to add to the list.
       * @returns {Boolean} true if all domainsToAdd are valid domain names and do not contain duplicates.
       */
      canAddDomains(domainsToAdd) {
        return (
          !containsDuplicates(this.allItems, domainsToAdd) && validateDomainNames(domainsToAdd)
        );
      }

      get() {
        const sortFn = getSortFn(this.sort.currentSortOrder);

        this.items = this.paginatedList
          .clear()
          .search(this.search.currentSearchQuery)
          .sort(sortFn)
          .paginate(this.pagination.currentPage, this.pagination.pageSize)
          .value();

        this.pagination.itemCount = this.paginatedList.itemCount;

        return this;
      }

      setPage(newPage) {
        if (newPage > 0) {
          this.pagination.currentPage = newPage;
        }
      }

      setPageSize(newPageSize) {
        if (newPageSize > 0) {
          this.pagination.pageSize = newPageSize;
          this.setPage(1);
        }
      }

      setSearchQuery(searchQuery) {
        this.search.currentSearchQuery = searchQuery;
        this.setPage(1);
      }

      setSortOrder(sortOrder) {
        this.sort.currentSortOrder = sortOrder;
      }
    }

    return WhitelistedDomainList;

    /////////////

    function containsDuplicates(originalItems, itemsToAdd) {
      const fullArr = _.flatten([
        _(originalItems).map('name').map(_.toLower).value(),
        _.map(itemsToAdd, _.toLower),
      ]);
      return fullArr.length !== _.uniq(fullArr).length;
    }

    function getSortFn(sortOrder) {
      const op = sortOrder === JIL_CONSTANTS.ORDER.ASC ? _.gt : _.lte;
      return (a, b) => {
        const cmpResult = op(_.toLower(a.name), _.toLower(b.name)) ? 1 : -1;
        return cmpResult;
      };
    }

    function validateDomainNames(domainNames) {
      return _.every(domainNames, isValidFormat) && _.every(domainNames, isValidLength);

      function isValidFormat(domainName) {
        const parts = _.split(domainName, '.');
        return (
          domainName.match(DOMAIN_REGEX) &&
          _.every(parts, (part) => part.length < DNS_MAX_DOMAIN_PART_LEN && part.length > 0)
        );
      }

      function isValidLength(domainName) {
        const {length} = domainName;
        return length > 0 && length <= DNS_MAX_DOMAIN_LEN;
      }
    }
  }
})();
