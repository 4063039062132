import {useFocusRing} from '@react-aria/focus';
import {useRadio} from '@react-aria/radio';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import PropTypes from 'prop-types';
import React from 'react';

import './ProductRadio.pcss';
import {useProductRadioContext} from './ProductRadioContext';

const ProductRadio = (props) => {
  const {children, value, index} = props;

  const radioState = useProductRadioContext();
  const ref = React.useRef(null);
  const {inputProps} = useRadio(props, radioState, ref);
  const isSelected = radioState.selectedValue === value;
  const {isFocusVisible, focusProps} = useFocusRing();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control -- associated with input
    <label data-testid="radio" styleName="label">
      <VisuallyHidden>
        <input
          {...inputProps}
          {...focusProps}
          ref={ref}
          tabIndex={isSelected || (!radioState.selectedValue && index === 0) ? 0 : -1}
        />
      </VisuallyHidden>

      <div
        data-testid="option"
        styleName={`radio ${isSelected ? 'selected' : ''} ${isFocusVisible ? 'focused' : ''}`}
      >
        {children}
      </div>
    </label>
  );
};

ProductRadio.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.string,
};

export default ProductRadio;
