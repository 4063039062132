import {AuthenticatedUser} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Method for checking if the Adobe agent access global banner should
 *     be shown.
 */
function notifyAccessingAsAdobeAgentBanner() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();
  if (userRoles.isActingAsAdobeAgentForOrg(orgId)) {
    rootStore.organizationStore.globalBannerStore.add({
      message: 'globalBanner.messages.agents.accessingAsAgent',
      priority: 999,
      type: GLOBAL_BANNER_TYPES.INFO,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyAccessingAsAdobeAgentBanner};
