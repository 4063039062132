(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories.enhanced-saml')
    .constant('ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS', {
      SAVE_ENHANCED_SAML_CONFIGURATION: 'SAVE_ENHANCED_SAML_CONFIGURATION',
    });
})();
