import {Product} from '@admin-tribe/binky';
import {ActionMenu, Item, Text, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {v4 as uuid4} from 'uuid';

import AddUsersToProductModal from 'common/components/add-user-modals/AddUsersToProductModal';
import {PRODUCT_TABLE_ACTION_VARIANT} from 'common/services/product-summary-list/ProductSummaryListConstants';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {ADD_PRODUCT_WORKFLOWS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {TRACKING_IDS} from 'features/settings/common/constants/testingAndOptimizationConstants';

import {
  getProductTableActionConfig,
  sendAddLicenseCloseAnalytics,
  sendAddLicenseOpenAnalytics,
  sendAddLicenseSuccessAnalytics,
} from '../product-table-action-button/productTableActionButtonUtils';

/**
 * Action menu wrapping the ProductTable actions
 */
const ProductTableActionMenu = ({actions, model}) => {
  const intl = useIntl();

  const isAddUserVariant = actions.find(
    (action) => action.variant === PRODUCT_TABLE_ACTION_VARIANT.ADD_USERS
  );
  const isAddLicenseVariant = actions.find(
    (action) => action.variant === PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES
  );

  const [isAddProductsModalOpen, setIsAddProductsModalOpen] = useState(false);
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false);

  const openAddProductsModel = () => {
    setIsAddProductsModalOpen(true);
    sendAddLicenseOpenAnalytics();
  };

  const closeAddProductsModel = () => {
    setIsAddProductsModalOpen(false);
    sendAddLicenseCloseAnalytics();
  };

  const cartItems = [];
  if (isAddLicenseVariant && isAddProductsModalOpen) {
    const product =
      model.id && rootStore.organizationStore.productList.items.find((p) => p.id === model.id);

    if (product) {
      cartItems.push({
        offerId: product.offerId,
        quantity: 1,
      });
    }
  }

  const {actionMenuItems, variantToOnPressMap} = useMemo(() => {
    const variantToOnPressMapInner = {};
    const menuItems = actions.map((action) => {
      // skipping icon for now to avoid issue seen in this thread: https://wulff-pack.slack.com/archives/C0336PMU4LT/p1681362483746529
      const {icon, label, onPress} = getProductTableActionConfig({
        intl,
        model,
        onAddProductsModalOpen: openAddProductsModel,
        onAddUsersModalOpen: () => setIsAddUsersModalOpen(true),
        variant: action.variant,
      });
      const menuItem = {
        icon,
        key: uuid4(),
        label,
        onPress,
        variant: action.variant,
      };

      // For now there's only one action per variant
      variantToOnPressMapInner[action.variant] = menuItem.onPress;
      return menuItem;
    });
    return {
      actionMenuItems: menuItems,
      variantToOnPressMap: variantToOnPressMapInner,
    };
  }, [actions, intl, model]);

  const onAction = (key) => {
    variantToOnPressMap[key]();
  };

  return (
    <>
      <TooltipTrigger>
        <ActionMenu isQuiet marginEnd="size-200" onAction={onAction}>
          {actionMenuItems.map((item) => (
            <Item key={item.variant} textValue={item.label}>
              {item.icon}
              <Text>{item.label}</Text>
            </Item>
          ))}
        </ActionMenu>
        <Tooltip>
          {intl.formatMessage({id: 'products.allProducts.actions.ariaLabel.moreActions'})}
        </Tooltip>
      </TooltipTrigger>
      {isAddUserVariant && isAddUsersModalOpen && (
        <AddUsersToProductModal
          isOpen
          onClosed={() => setIsAddUsersModalOpen(false)}
          orgId={isAddUserVariant.orgId}
          productId={model.id}
        />
      )}
      {isAddLicenseVariant && isAddProductsModalOpen && (
        <AddProductModalWrapper
          chat={chatProvider}
          contractId={model.contractId}
          items={cartItems}
          onClose={closeAddProductsModel}
          onSuccess={sendAddLicenseSuccessAnalytics}
          tracking={TRACKING_IDS.BUY_LICENSE_DIRECT_TRACKING_ID}
          workflow={ADD_PRODUCT_WORKFLOWS.ADD_LICENSE}
        />
      )}
    </>
  );
};

ProductTableActionMenu.propTypes = {
  /** Objects containing action content */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Model for the product whose actions are being presented by this component. */
  model: PropTypes.oneOfType([
    PropTypes.instanceOf(Product),
    PropTypes.instanceOf(ProductGroupProductList),
  ]).isRequired,
};

export default ProductTableActionMenu;
