import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelDoneModal from '../components/self-cancel-done-modal/SelfCancelDoneModal';

/**
 * Self cancel workflow step component used when the user exits the flow.
 * @todo The current content of this component is for initial bootstrap and will be modified when fully implemented by a future user story
 */
const ExitStep = ({onDone}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.DONE});
    onDone?.();
  };

  return (
    <SelfCancelDoneModal
      heading={intl.formatMessage({id: 'account.selfCancel.exit.title'})}
      isManageLicensesSectionHidden
      onCta={onCta}
    />
  );
};

ExitStep.propTypes = {
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onDone: PropTypes.func,
};

export default ExitStep;
