import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  OrganizationUser,
  Product,
  feature,
  getProductListWithLicenseGroupSummariesIfSafe,
  log,
} from '@admin-tribe/binky';
import binkyUI, {
  WIZARD_DISPATCH_ACTION,
  WizardTrigger,
  WizardView,
  getOrganizationUserErrorProps,
} from '@admin-tribe/binky-ui';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {addTagsToProducts} from 'core/products/utils/productTagUtils';

import {ROLE} from '../../users.constants';

import AddAdminAssignRolesPage from './AddAdminAssignRolesPage';
import AddAdminFindUserPage from './AddAdminFindUserPage';
import AddAdminModalContext from './AddAdminModalContext';
import AddAdminSmbPage from './AddAdminTeamPage';

const {ModalContainer, ModalDialog} = binkyUI.common.components.modal;

const AddAdminModal = ({
  isOpen,
  onCancel,
  onSuccess,
  orgId,
  productToAddAdmin,
  roles,
  userToEdit,
}) => {
  const intl = useIntl();
  const nextText = intl.formatMessage({id: 'users.addAdminModal.next'});
  const backText = intl.formatMessage({id: 'users.addAdminModal.back'});
  const cancelText = intl.formatMessage({id: 'users.addAdminModal.cancel'});
  const saveText = intl.formatMessage({id: 'users.addAdminModal.save'});

  const [confirmButtonText, setConfirmButtonText] = useState(nextText);
  const [consumableSummarizationList, setConsumableSummarizationList] = useState(null);
  const [secondaryButtonText, setSecondaryButtonText] = useState(null);
  const [isLoadingConsumableData, setIsLoadingConsumableData] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [modalError, setModalError] = useState(null);
  const [modalErrorProps, setModalErrorProps] = useState();
  const [products, setProducts] = useState(null);
  const isMountedRef = useRef(true);

  const STEPS = [
    intl.formatMessage({id: 'users.addAdminModal.steps.addAdmin'}),
    intl.formatMessage({id: 'users.addAdminModal.steps.adminRoles'}),
  ];

  const isTeamOrg = !userToEdit && roles.length === 1 && roles[0] === ROLE.ADMIN.ORG;

  const onCta = async (currentStep, dispatch, user) => {
    let isSuccess = false;
    if (!isTeamOrg && !userToEdit && currentStep < STEPS.length - 1) {
      setConfirmButtonText(saveText);
      setSecondaryButtonText(backText);
      dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
    } else {
      try {
        setIsSavingUser(true);
        await user.save();
        isSuccess = true;
        onSuccess?.();
      } catch (error) {
        log.error('Failed to save user. Error: ', error);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
        if (isMountedRef.current) {
          // Axios places non-batch API errors inside "response.data" instead of "response",
          // so if it exists we will make a new batch-style-error to look more like what getOrganizationUserErrorProps expects.
          const fakeBatchError = error.response?.data ? {response: error.response.data} : error;
          const {message, ...otherProps} = getOrganizationUserErrorProps(intl, fakeBatchError);
          setModalError(message);
          setModalErrorProps({message, ...otherProps});
        }
      } finally {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
        if (isMountedRef.current) {
          setIsSavingUser(false);
        }
      }
    }

    return isSuccess;
  };

  const ctaToastGenerator = () =>
    intl.formatMessage({id: 'common.toast.modal.usersUpdated'}, {userCount: 1});

  const onSecondary = (dispatch) => {
    setConfirmButtonText(nextText);
    setSecondaryButtonText(null);
    dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
  };

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    isMountedRef.current = true;

    if (isOpen) {
      const fetchProducts = async () => {
        setIsLoadingProducts(true);
        try {
          const productList = await getProductListWithLicenseGroupSummariesIfSafe(orgId);
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
          // istanbul ignore else
          if (isMountedRef.current) {
            addTagsToProducts(intl, productList);
            setProducts(productList.items);
          }
        } catch (error) {
          log.error('Failed to fetch ProductList. Error: ', error);

          if (isMountedRef.current) {
            setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
          }
        }

        if (isMountedRef.current) {
          setIsLoadingProducts(false);
        }
      };

      if (!isTeamOrg) {
        fetchProducts();
      }
    } else {
      setConfirmButtonText(nextText);
      setSecondaryButtonText(null);
      setModalError(null);
      setModalErrorProps(undefined);
      setIsLoadingProducts(false);
      setIsSavingUser(false);
    }

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on isOpen change
  }, [isOpen, userToEdit]);

  // to fetch consumableSummarizationList if needed
  useEffect(() => {
    isMountedRef.current = true;

    const fetchConsumableData = async () => {
      setIsLoadingConsumableData(true);
      try {
        const summarizationList = await ConsumableSummarizationList.get({
          include_usage: false,
          organization_id: orgId,
          summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
        });

        if (isMountedRef.current) {
          setConsumableSummarizationList(summarizationList);
        }
      } catch (error) {
        log.error('Failed to fetch ConsumableSummarizationList. Error: ', error);

        if (isMountedRef.current) {
          setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
          setModalErrorProps(undefined);
        }
      }

      if (isMountedRef.current) {
        setIsLoadingConsumableData(false);
      }
    };

    if (
      feature.isEnabled('temp_parkour_mm') &&
      (productToAddAdmin?.isProductSupportRoleAssignmentAllowed() ||
        (!productToAddAdmin &&
          products?.some((product) => product.isProductSupportRoleAssignmentAllowed())))
    ) {
      fetchConsumableData();
    }

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on products or productToAddAdmin is fetched
  }, [products, productToAddAdmin]);

  const createModal = useCallback(
    (children) => (
      <ModalContainer>
        <AddAdminModalContext
          contractList={rootStore.organizationStore.contractList?.items}
          initialUser={cloneDeep(userToEdit)}
          isSystemAdmin={isOrgAdmin()}
          orgId={orgId}
          products={products}
          setModalError={setModalError}
          setModalErrorProps={setModalErrorProps}
        >
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- cawright@ to fix */}
          {({hasUnsavedRoleChanges, roleValidityStatus, isLoadingUser, user}) => (
            <WizardTrigger steps={STEPS}>
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- cawright@ to fix */}
              {({dispatch, currentStep}) => {
                const isCtaDisabled = () => {
                  const isLoadingData =
                    !isTeamOrg &&
                    (isLoadingConsumableData || isLoadingProducts || products === null);

                  return (
                    // disable when user is not selected yet
                    user === null ||
                    // or when fetching products/consumable data
                    isLoadingData ||
                    // or when there are no changes
                    !hasUnsavedRoleChanges ||
                    // or when a role selection is invalid
                    !Object.values(roleValidityStatus).every(Boolean)
                  );
                };

                const modalIsLoading =
                  isLoadingUser ||
                  (isLoadingProducts && user !== null) ||
                  isSavingUser ||
                  isLoadingConsumableData;

                return (
                  <ModalDialog
                    borderRadius="large"
                    cancelLabel={cancelText}
                    ctaLabel={isTeamOrg || userToEdit ? saveText : confirmButtonText}
                    ctaToastGenerator={ctaToastGenerator}
                    errorMessage={modalError}
                    errorToastProps={modalErrorProps}
                    heightVariant="dynamic"
                    id={userToEdit ? 'edit-admin-modal' : 'add-admin-modal'}
                    isCtaDisabled={isCtaDisabled()}
                    isLoading={modalIsLoading}
                    onCancel={onCancel}
                    onCta={() => onCta(currentStep, dispatch, user)}
                    onSecondary={() => onSecondary(dispatch)}
                    secondaryLabel={secondaryButtonText}
                  >
                    {children}
                  </ModalDialog>
                );
              }}
            </WizardTrigger>
          )}
        </AddAdminModalContext>
      </ModalContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only change for these vars
    [
      confirmButtonText,
      isLoadingConsumableData,
      isLoadingProducts,
      isOpen,
      isSavingUser,
      modalError,
      products,
      secondaryButtonText,
    ]
  );

  if (!isOpen) {
    return null;
  }

  if (userToEdit) {
    return createModal(
      <AddAdminAssignRolesPage
        consumableSummarizationList={consumableSummarizationList}
        roles={roles}
      />
    );
  }

  if (isTeamOrg) {
    return createModal(<AddAdminSmbPage />);
  }

  return createModal(
    <WizardView>
      <AddAdminFindUserPage productToAddAdmin={productToAddAdmin} />
      <AddAdminAssignRolesPage
        consumableSummarizationList={consumableSummarizationList}
        productToAddAdmin={productToAddAdmin}
        roles={roles}
      />
    </WizardView>
  );
};

AddAdminModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  /**
   * The product that should be assigned with admin roles(only for product or product support admin).
   */
  productToAddAdmin: PropTypes.instanceOf(Product),
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // will be an object if passed in as a prop of type OrgUser (from src1)
  userToEdit: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(OrganizationUser)]),
};

export default AddAdminModal;
