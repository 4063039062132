(function () {
  const componentName = 'appStorageSummarySection';
  /**
   * @deprecated this component has been ported to src2 StorageSummarySection.jsx
   */
  angular.module('app.overview').component(componentName, {
    controller,
    templateUrl:
      'app/overview/overview/storage-summary-section/storage-summary-section.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $filter,
    $q,
    $state,
    $translate,
    $window,
    AnalyticsEvent,
    feature,
    StorageStats
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getStats,
      getTooltipString,
      onInfoButtonClick,
      onManage,
    });

    function $onInit() {
      getStats();
    }

    function getTooltipString() {
      return $translate.instant('overview.storageArea.titleTooltip');
    }

    function getStats() {
      hideErrorSection();
      vm.waitOnStatsPromise = StorageStats.get().$promise.then(onSuccess).catch(onError);
    }

    function onInfoButtonClick() {
      sendClickAnalytics('storage-tooltip-button');
      $window.open($filter('goUrl')('ac_busID_migrate_learn'), '_blank');
    }

    function onManage() {
      sendClickAnalytics('manage-storage');
      $state.go('org.storage.overview');
    }

    /* Private functions */

    function hideErrorSection() {
      vm.errorMsg = undefined;
      vm.storageMessage = undefined;
    }

    function onError(error) {
      showErrorSection(error.status);
      return $q.reject(error);
    }

    function onSuccess(storageStats) {
      vm.usagePercentage = storageStats.getUsagePercentage();
      vm.usageSummary = storageStats.getUsageSummary();

      let percentageRemaining = 100 - vm.usagePercentage;

      if (
        feature.isEnabled('temp_self_cancel') &&
        (isNaN(percentageRemaining) || percentageRemaining < 0)
      ) {
        // sanitize the value to be a number greater or equal to 0
        percentageRemaining = 0;
      }

      vm.percentageRemaining = $translate.instant(
        'overview.storageArea.storageBar.percentageRemaining',
        {
          percentageRemaining,
        }
      );

      return $q.resolve();
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }

    function showErrorSection(errorStatusCode) {
      if (errorStatusCode === 404) {
        vm.errorMsg = $translate.instant('overview.storageArea.updating.text');
        vm.errorIcon = 'TableAndChart';
      } else {
        vm.errorMsg = $translate.instant('overview.storageArea.error.text');
      }
    }
  }
})();
