import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';

const DeviceLicensesPageContext = createContext({});

const useDeviceLicensesPageContext = () => useContext(DeviceLicensesPageContext);

/**
 * A component to provide context variables to its children component
 */

const DeviceLicensesPageContextProvider = ({children, value}) => (
  <DeviceLicensesPageContext.Provider value={value}>{children}</DeviceLicensesPageContext.Provider>
);

DeviceLicensesPageContextProvider.propTypes = {
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,

  /**
   * Values passed to all of its children
   */
  value: PropTypes.PropTypes.shape({
    canActivateDevices: PropTypes.bool,
    productId: PropTypes.string,
    refetchHeaderData: PropTypes.func,
    totalDevicesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalLicensesCount: PropTypes.number,
    usedLicensesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};
export {DeviceLicensesPageContextProvider, useDeviceLicensesPageContext};
