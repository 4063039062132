(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilDevices
   * @description defines service to manage jil device resources
   */
  angular.module('app.core.api.jil').provider('jilDevices', jilDevicesProvider);

  let url;

  /* @ngInject */
  function jilDevicesProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        devices: getDevicesResource(),
      };

      return service;

      ///////////

      function getDevicesResource() {
        return $resource(
          `${url}/:orgId/devices`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            exportDeactivatedDevices: {
              headers: {
                Accept: 'text/csv+device,application/json',
              },
              method: 'GET',
              params: {
                deactivatedOnly: true,
              },
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
