import {feature, toPandoraProduct, toPandoraProductList} from '@admin-tribe/binky';
import cloneDeep from 'lodash/cloneDeep';

import {getContractDisplayNames} from 'core/products/utils/productUtils';

/**
 * Get Pandora products with contract display name.
 * @param {Object} productList - The product list.
 * @returns {Object} - The Pandora product list.
 */
const transformToPandoraProducts = (productList) => {
  if (!productList) {
    return {};
  }

  if (feature.isEnabled('temp_add_contract_display_name')) {
    const clonedProduct = {...productList};
    clonedProduct.items.map((product) => {
      product.contractDisplayNames = getContractDisplayNames(product.contractIds);
      return product; // Add return statement
    });
  }
  return toPandoraProductList(productList);
};

/**
 * Get Pandora products with contract display name from the products array.
 * @param {Array} products - The product list.
 * @returns {Array} - The Pandora product list.
 */
const transformToPandoraProductsList = (products) => {
  if (!products) {
    return [];
  }
  if (feature.isEnabled('temp_add_contract_display_name')) {
    let clonedProducts = cloneDeep(products);
    clonedProducts = clonedProducts.map((product) => {
      product.contractDisplayNames = getContractDisplayNames(product.contractIds);
      return toPandoraProduct(product);
    });

    return clonedProducts;
  }
  return products.map((product) => toPandoraProduct(product));
};

export {transformToPandoraProducts, transformToPandoraProductsList};
