(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name SupportCase
   * @description Model for a SupportCase
   */
  angular.module('app.core.support').factory('SupportCase', SupportCaseModel);

  /* @ngInject */
  function SupportCaseModel(
    $log,
    $q,
    $rootScope,
    _,
    MESSAGE,
    MODEL,
    modelCache,
    onesieSrc2,
    OrganizationManager,
    SUPPORT_TICKET_RECORD_TYPE,
    SupportTicket
  ) {
    const miloApi = onesieSrc2.support.api.milo;
    class SupportCase extends SupportTicket {
      /**
       * @class
       * @description Creates a new SupportCase.
       *
       * @param {Object} options - options object, as described below. See also the SupportTicket
       *   constructor options, which this object is passed to.
       * @param {Boolean} options.canReopen - whether the closed case can be reopened
       * @param {Array} options.ccEmailList - the list of emails to notify about case events when creating or updating a case
       * @param {Boolean} options.impact - impact of the issue based on number of users affected by the issue
       * @param {Boolean} options.isEscalated - whether the case has been escalated by the customer
       * @param {Boolean} options.isEscalationAllowed - whether the case can be escalated or not
       * @param {Boolean} options.isMigrated - whether the case was migrated from the original
       *  Adobe CRM to the Dynamics system
       * @param {String} options.priority - the case priority selected by the customer
       * @param {String} options.workingHoursEnd - the end time for the customer's working hours in the form 'HH.mm'
       * @param {String} options.workingHoursStart - the start time for the customer's working hours in the form 'HH.mm'
       */
      constructor(options) {
        const ticketConfig = {
          cacheType: MODEL.SUPPORTCASE,
          fields: [
            'canReopen',
            'ccEmailList',
            'impact',
            'impactCode',
            'impactCodeText',
            'isEscalated',
            'isEscalationAllowed',
            'isMigrated',
            'priority',
            'priorityCode',
            'workingHoursEnd',
            'workingHoursStart',
          ],
          updateItemMessage: MESSAGE.UPDATE.SUPPORTCASE,
        };
        super(ticketConfig, options);
      }

      /**
       * @description Method to create support case.
       *
       * @param {Object} supportTicket properties that describe new support ticket.
       *
       * @returns {promise} promise that resolves when requested case is created. The promise will resolve with
       *   an object that contains the id of the support ticket that was just created or will reject with the error.
       */
      static create(supportTicket) {
        // Add required recordType field.
        const ticket = _.assign({}, supportTicket, {
          recordType: SUPPORT_TICKET_RECORD_TYPE.CASE,
        });

        return SupportTicket.create(SupportCase, ticket).then((response) => {
          // The cached case list is out of date since it doesn't contain the case just created.
          $rootScope.$emit(MESSAGE.CREATE.SUPPORTCASE);
          return response;
        });
      }

      /**
       * @description Retrieves the SupportCase with the specified ID, from cache if available,
       *   else from the backend.
       *
       * @param {Object} options - options for the SupportCase, as described below
       * @param {String} options.id - id of the support case to retrieve
       * @param {String} options.recordType - recordType of the support case to retrieve
       *
       * @returns {SupportCase} the retrieved SupportCase
       */
      static get(options) {
        return SupportTicket.get(
          SupportCase,
          _.assign({}, options, {recordType: SUPPORT_TICKET_RECORD_TYPE.CASE})
        );
      }

      /**
       * @description Retrieves the product metadata which contains additional
       *  support case questions for the specific product.
       *  Spec: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1756603250
       *
       * @param {Object} options - options for the request
       * @param {String} options.fulfillableItemCode - fulfillable item code for a product
       *
       * @returns {Object} product metadata (see Spec)
       */
      static getProductMetadata(options) {
        return $q((resolve, reject) => {
          miloApi
            .getProductMetadata({
              fulfillableItemCode: options.fulfillableItemCode,
              orgId: OrganizationManager.getActiveOrgId(),
            })
            .then((response) => resolve(response.data))
            .catch((error) => {
              $log.error(
                `Failed to get product metadata for ${options.fulfillableItemCode} with MILO API.`,
                error
              );
              reject(error.response ? error.response : error);
            });
        });
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MESSAGE.UPDATE.SUPPORTCASELIST, () => {
      modelCache.removeAll(MODEL.SUPPORTCASE);
    });

    modelCache.removeOnEvent(MODEL.SUPPORTCASE, [MESSAGE.UPDATE.SUPPORTCASE]);

    modelCache.register(MODEL.SUPPORTCASE, 10);

    return SupportCase;
  }
})();
