(function () {
  /**
   * @deprecated use src2
   */
  angular.module('app.core.email-utils').factory('emailUtils', emailUtils);

  /* @ngInject */
  function emailUtils(_) {
    const EMAIL_REGEX = /.+@.+\..+/; // refrenced in available-type-list.service.js
    const EMAIL_MAX_LEN = 254; // see: https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address

    const service = {
      looksLikeAnEmail,
    };

    return service;

    ////////

    /**
     * @description Returns true if the specified string looks like an
     * email address, because it is of the form x@y.z. This method does
     * not ensure the email address is valid.
     *
     * @param {String} email - the email address to validate
     * @returns {Boolean} true if the string seems to be an email address; else false
     */
    function looksLikeAnEmail(email) {
      return (
        EMAIL_REGEX.test(email) &&
        _.inRange(email.length, 1, EMAIL_MAX_LEN + 1) &&
        !_.includes(email, ',')
      );
    }
  }
})();
