/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.renew-products-modal2:appRenewProductsViewRenewalOrder
 * @description View or Confirm/Review the renewal order.
 *
 * @param {Cart} cart the cart for the renewal order
 * @param {Contract} contract the contract for the product renewal
 * @param {Boolean} isView True if this is viewing the renewal order. Default is false which means confirming/reviewing
 *   the renewal changes.
 * @param {String} poNumber input is populated with the purchase order number if it exists on the contract, otherwise
 * gives an opportunity to fill it in to be saved to the contract. It is a two-way binding.
 * @param {Boolean} [waitForTotal] Default is undefined/false. If true, the wait string, which is 2 en-dashes, will be
 *   shown in place of the quantity and price.
 *
 * @example <app-renew-products-review-renewal-order
 *            cart="$ctrl.cart"
 *            contract="$ctrl.contract"
 *            is-view="true"
 *            po-number="$ctrl.poNumber"
 *            wait-for-total="$ctrl.waitForTotal"
 *          </app-renew-products-review-renewal-order>
 */
(function () {
  angular
    .module('app.widgets.products.purchase.renew-products-modal2')
    .component('appRenewProductsReviewRenewalOrder', {
      bindings: {
        cart: '<',
        contract: '<',
        isView: '<?',
        poNumber: '=?',
        waitForTotal: '<?',
      },
      controller,
      templateUrl:
        'app/widgets/products/purchase/renew-products-modal2/review-renewal-order/renew-products-review-renewal-order.component.html',
    });

  /* @ngInject */
  function controller(
    _,
    $translate,
    binkyDateTimeFilter,
    binkyContractPerTermPriceWithTaxFilter,
    binkyContractPriceWithTaxFilter,
    contractAccess,
    contractUtils,
    feature,
    numberFilter,
    PURCHASE_ORDER_MAX_LENGTH,
    PURCHASE_WAITSTR,
    translationUtils
  ) {
    const vm = this;
    const keyMessagePrefix =
      'widgets.products.purchase.renewProductsModal.reviewRenewalOrderSection';
    let anniversaryDate, confirmChanges, nextBillingDate;

    _.assign(vm, {
      $onInit,
      feature,
      PURCHASE_ORDER_MAX_LENGTH,
    });

    function $onInit() {
      confirmChanges = _.isUndefined(vm.isView) || vm.isView === 'false';
      anniversaryDate = binkyDateTimeFilter(vm.contract.getAnniversaryDate(), {
        dateOnly: true,
      });
      nextBillingDate = binkyDateTimeFilter(vm.contract.getNextBillingDate(), {
        dateOnly: true,
      });

      displayPaymentDueSection();
      vm.showPONumberSection = vm.isView && vm.contract.isPaymentCategoryPO();
    }

    /////////////

    // eslint-disable-next-line complexity
    function displayPaymentDueSection() {
      const includesTax = !!vm.cart.getNewTotalPriceWithTax();
      const formattedPrice = vm.waitForTotal
        ? PURCHASE_WAITSTR
        : vm.cart.getNewTotalPrice(includesTax);
      const price = vm.contract.isM2M()
        ? binkyContractPerTermPriceWithTaxFilter(vm.contract, includesTax, formattedPrice)
        : binkyContractPriceWithTaxFilter(vm.contract, includesTax, formattedPrice);
      const count = vm.waitForTotal
        ? PURCHASE_WAITSTR
        : numberFilter(vm.cart.getTotalRenewingLicenseCount());

      vm.viewRenewalMessageList = [];

      // View changes.
      if (!confirmChanges) {
        vm.viewRenewalMessageList.push({
          body: $translate.instant(
            `${keyMessagePrefix}.messages.autoRenew.body`,
            {
              anniversaryDate,
              email: vm.contract.getOwnerEmail(),
              orgName: vm.contract.orgName,
            },
            'messageformat'
          ),
          class: 'auto-renew',
          icon: 'CheckmarkCircle',
          title: $translate.instant(`${keyMessagePrefix}.messages.autoRenew.title`),
        });
      }

      if (contractAccess.canViewLocalCurrencyChangeInfo(vm.contract)) {
        const currencyCode = contractUtils.getFutureCurrency(vm.contract);
        const currencyImageUrl = _.get(
          vm.contract,
          'billingInfo.nextBillingAmount.currency.currencyImageUrl'
        );

        const messageKey = `${keyMessagePrefix}.messages.localCurrencyChange.body.${currencyCode}`;
        const message = translationUtils.sanitizeSafeInstant(messageKey);

        if (message !== messageKey) {
          vm.viewRenewalMessageList.push({
            body: message,
            class: 'local-currency',
            icon: currencyImageUrl,
            title: $translate.instant(`${keyMessagePrefix}.messages.localCurrencyChange.title`),
          });
        }
      }

      let bodyKey;
      if (vm.contract.isPUF()) {
        bodyKey = 'PUF';
      } else {
        bodyKey = confirmChanges ? 'APM.review' : 'APM.view';
      }
      vm.viewRenewalMessageList.push({
        body: $translate.instant(
          `${keyMessagePrefix}.messages.paymentDue.body.${bodyKey}`,
          {
            anniversaryDate, // PUF
            count,
            nextBillingDate, // APM
            price,
          },
          'messageformat'
        ),
        class: 'payment-due',
        icon: 'Clock',
        title: $translate.instant(`${keyMessagePrefix}.messages.paymentDue.title`),
      });

      // Review changes.
      if (confirmChanges) {
        vm.changesToYourRenewal = {
          body: $translate.instant(
            `${keyMessagePrefix}.banner.changesToYourRenewal.body`,
            {anniversaryDate},
            'messageformat'
          ),
          header: $translate.instant(`${keyMessagePrefix}.banner.changesToYourRenewal.header`),
        };
      }

      vm.showPaymentMethodSection =
        contractAccess.canEditPayment(vm.contract) && !vm.contract.isPaymentCategoryPO();
    }
  }
})();
