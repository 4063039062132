import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * Class to create HVA card for users to visit user guide
 */
class VisitUserGuideHva extends HvaCard {
  /**
   * @description Method to fetch the VisitUserGuideHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<VisitUserGuideHva[]>} the fetched instance
   */
  static get({intl}) {
    return Promise.resolve(new VisitUserGuideHva({intl}));
  }

  /**
   * @description Creates a new VisitUserGuideHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {VisitUserGuideHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.visitUserGuide';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/take-tour.svg',
        style: {width: '40px'},
      },
      id: 'hva-visit-user-guide',
      isDismissible: true,
      onCTA: () => {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- callback will always run in browser
        window.open(
          'https://www.adobe.com/go/aac_pop_artt_users_guide',
          '_blank',
          'noopener,noreferrer'
        );
      },
      priorityOrder: HVA_ORDER.VISIT_USER_GUIDE,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default VisitUserGuideHva;
