(function () {
  angular.module('app', [
    'app.account',
    'app.core',
    'app.core.chat.ready',
    'app.core.extended-user-profile',
    'app.core.src2.ready',
    'app.core.termsRedirect.ready',
    'app.main',
    'app.overview',
    'app.redirect',
    'app.shell',
    'app.src2-route',
    'binky.core.extended-user-profile.ready',
    'binky.core.fraud-mitigation.ready',
    'binky.core.observability.ready',
    'binky.shell.bumper.ready',
    'binky.shell.navigation.ready',
  ]);
})();
