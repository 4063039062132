(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilStorageQuota
   * @description defines service to manage jil storage-quota resources
   */
  angular.module('app.core.api.jil').provider('jilStorageQuota', jilStorageQuotaProvider);

  let url;

  /* @ngInject */
  function jilStorageQuotaProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        storageQuota: getStorageQuotaResource(),
      };

      return service;

      ///////////

      function getStorageQuotaResource() {
        return $resource(`${url}/:orgId/storage-quota`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
