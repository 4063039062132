import {ActionButton, Flex, ProgressCircle} from '@adobe/react-spectrum';
import {StatusLight} from '@pandora/react-status-light';
import {EN_DASH} from '@pandora/react-table-section';
import CloseIcon from '@spectrum-icons/workflow/Close';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {ALL_PACKAGES_CONSTANTS} from 'features/packages/packagesConstants';
import {useAllPackagesPageContext} from 'features/packages/pages/all-packages/AllPackagesPageContext';

/**
 * PackageStatusState component to render current status of admin package
 */
const PackageStatusState = observer(({adminPackage}) => {
  const intl = useIntl();
  const {allPackagesStore} = useAllPackagesPageContext();

  switch (adminPackage.statusState) {
    case ALL_PACKAGES_CONSTANTS.STATE_UP_TO_DATE:
      return (
        <Flex alignItems="center" direction="row">
          <StatusLight alignStart variant="positive">
            <FormattedMessage id="packages.allPackages.status.upToDate" />
          </StatusLight>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_NOT_UP_TO_DATE:
      return (
        <Flex alignItems="center" direction="row">
          <StatusLight alignStart variant="notice">
            <FormattedMessage id="packages.allPackages.status.notUpToDate" />
          </StatusLight>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_CORRUPTED:
      return (
        <Flex alignItems="center" direction="row">
          <StatusLight alignStart variant="negative">
            <FormattedMessage id="packages.allPackages.status.corrupted" />
          </StatusLight>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_ERROR:
      return (
        <Flex alignItems="center" direction="row">
          <StatusLight alignStart variant="negative">
            <FormattedMessage id="packages.allPackages.status.error" />
          </StatusLight>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_CANCELLING:
      return (
        <Flex direction="column">
          <ProgressCircle
            aria-label={intl.formatMessage({
              id: 'packages.allPackages.status.cancelling',
            })}
            data-testid="ProgressCircle"
            isIndeterminate
            size="M"
          />
          <FormattedMessage id="packages.allPackages.status.cancelling" />
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_PREPARING:
      return (
        <Flex alignItems="center" direction="row" justifyContent="space-between">
          <Flex direction="column">
            <ProgressCircle
              aria-label={intl.formatMessage({
                id: 'packages.allPackages.status.preparing',
              })}
              data-testid="ProgressCircle"
              isIndeterminate
              size="M"
            />
            <FormattedMessage id="packages.allPackages.status.preparing" />
          </Flex>
          <ActionButton
            aria-label={intl.formatMessage({id: 'packages.allPackages.status.preparing'})}
            data-testid="cancel-preparing-package-button"
            isQuiet
            onClick={() => allPackagesStore.deleteSelectedPackages([adminPackage], true)}
          >
            <CloseIcon />
          </ActionButton>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_BUILDING:
      return (
        <Flex alignItems="center" direction="row" justifyContent="space-between">
          <Flex direction="column">
            <ProgressCircle
              aria-label={intl.formatMessage(
                {
                  id: 'packages.allPackages.status.building',
                },
                {percent: adminPackage.customizerProgress.percent}
              )}
              data-testid="ProgressCircle"
              isIndeterminate
              size="M"
            />
            {intl.formatMessage(
              {id: 'packages.allPackages.status.building'},
              {percent: adminPackage.customizerProgress.percent}
            )}
          </Flex>
          <ActionButton
            aria-label={intl.formatMessage(
              {
                id: 'packages.allPackages.status.building',
              },
              {percent: adminPackage.customizerProgress.percent}
            )}
            data-testid="cancel-building-package-button"
            isQuiet
            onClick={() => allPackagesStore.deleteSelectedPackages([adminPackage], true)}
          >
            <CloseIcon />
          </ActionButton>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.STATE_UNRESOLVED:
      return (
        <Flex alignItems="center" direction="row">
          {allPackagesStore.loadingPlugins ? (
            <ProgressCircle
              aria-label={intl.formatMessage({
                id: 'packages.allPackages.status.preparing',
              })}
              data-testid="ProgressCircle"
              isIndeterminate
              size="M"
            />
          ) : (
            <StatusLight alignStart variant="neutral" />
          )}
        </Flex>
      );
    default:
      return EN_DASH;
  }
});

PackageStatusState.propTypes = {
  /**
   * adminPackage object
   */
  adminPackage: PropTypes.shape({
    customizerProgress: PropTypes.shape({
      percent: PropTypes.number,
    }),
    statusState: PropTypes.string.isRequired,
  }).isRequired,
};

export default PackageStatusState;
