(function () {
  /**
   * @deprecated with temp_port_react_invoice
   *
   * @ngdoc component
   *
   * @name app.widgets.account:appInvoiceStatus
   * @description display an invoice status with the following options
   * INVOICE_PAYMENT_STATUS.PAID - if an invoice has been paid
   * INVOICE_PAYMENT_STATUS.PAST_DUE - if now is currently past the date when the invoice should have been paid
   * INVOICE_PAYMENT_STATUS.PAYMENT_DUE - if the date of which an invoice should be paid is coming up soon
   * INVOICE_PAYMENT_STATUS.PROCESSING - if payment for an invoice has been received and the transaction is processing
   * @param {invoice} Instance of the invoice used to retrieve which status to display
   * @param {invoice.invoiceStatus} invoiceStatus should display one of the following options above, but if it is something else,
   * it will return PAID by default
   * @param {invoice.noOfDays} noOfDays past due or payment due, if this is missing on either of those 2 states, then a -- will
   * be displayed, otherwise, this is an optional parameter not needed for the other states
   */
  angular.module('app.widgets.account').component('appInvoiceStatus', {
    bindings: {
      invoice: '<',
    },
    controller,
    templateUrl: 'app/widgets/account/invoice-status/invoice-status.component.html',
  });

  /* @ngInject */
  function controller($translate, _, INVOICE_PAYMENT_STATUS, INVOICE_INVALID_STATUS_STR) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      INVOICE_INVALID_STATUS_STR,
    });

    function $onInit() {
      vm.statusVariant = calculateVariant(vm.invoice);
      if (vm.statusVariant) {
        vm.invoiceStatus = calculateLabel(vm.invoice);
      }
    }

    function calculateLabel(invoice) {
      const translationPrefix = 'widgets.account.invoicePaymentStatus';
      switch (invoice.invoiceStatus) {
        case INVOICE_PAYMENT_STATUS.CLOSED:
          return $translate.instant(`${translationPrefix}.closed`);
        case INVOICE_PAYMENT_STATUS.OPEN:
          return $translate.instant(`${translationPrefix}.open`);
        case INVOICE_PAYMENT_STATUS.PAST_DUE:
          return invoice.noOfDays === 0
            ? $translate.instant(`${translationPrefix}.pastDueToday`)
            : $translate.instant(
                `${translationPrefix}.pastDue`,
                {count: invoice.noOfDays},
                'messageformat'
              );
        case INVOICE_PAYMENT_STATUS.PAYMENT_DUE:
          return invoice.noOfDays === 0
            ? $translate.instant(`${translationPrefix}.paymentDueToday`)
            : $translate.instant(
                `${translationPrefix}.paymentDue`,
                {count: invoice.noOfDays},
                'messageformat'
              );
        case INVOICE_PAYMENT_STATUS.PROCESSING:
          return $translate.instant(`${translationPrefix}.processing`);
        case INVOICE_PAYMENT_STATUS.PAID:
        default:
          return $translate.instant(`${translationPrefix}.paid`);
      }
    }

    function calculateVariant(invoice) {
      switch (invoice.invoiceStatus) {
        case INVOICE_PAYMENT_STATUS.CLOSED:
        case INVOICE_PAYMENT_STATUS.OPEN:
        case INVOICE_PAYMENT_STATUS.PAID:
          return 'success';
        case INVOICE_PAYMENT_STATUS.PAST_DUE:
          return invoice.noOfDays >= 0 ? 'error' : undefined;
        case INVOICE_PAYMENT_STATUS.PAYMENT_DUE:
          return invoice.noOfDays >= 0 ? 'warning' : undefined;
        case INVOICE_PAYMENT_STATUS.PROCESSING:
          return 'warning';
        default:
          return undefined;
      }
    }
  }
})();
