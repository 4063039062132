import {feature, log} from '@admin-tribe/binky';
import {
  DEVICE_ACTIVATION_STATUS,
  useDevicesApi,
} from '@pandora/react-data-source-device-activation';
import {EN_DASH} from '@pandora/react-table-section';
import {useEffect, useState} from 'react';

import rootStore from 'core/RootStore';
import {
  getNonFRLOnlineUsedLicenses,
  isProductFRLOfflineOnly,
  isProductFRLOnlineOnly,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';

/**
 * A hook that provides total number of devices and activated devices that are associated with a license Id
 *
 * @param {String} licenseGroupId - Id of the current license group
 * @param {String} productId - Id of current license
 */
const useTotalDevices = ({licenseGroupId = null, productId, triggerToRefetchFrlDevices = null}) => {
  const calApiOptions = {
    licenseId: productId,
    orgId: rootStore.organizationStore.activeOrgId,
  };
  // In the case of SDL where we would have a licenseGroupId
  if (licenseGroupId) calApiOptions.licenseConfigId = licenseGroupId;

  const {getTotalDevices, getTotalDevicesByStatus} = useDevicesApi(calApiOptions);
  const [devices, setDevices] = useState();
  const [isError, setIsError] = useState(false);
  const [activatedDevices, setActivatedDevices] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [frlOnlineUsedLicensesCount, setFrlOnlineUsedLicensesCount] = useState();

  // Fetch total number of devices and activated device
  useEffect(() => {
    let isMounted = true;
    const getAll = async (additionalUsedLicensesCount = 0) => {
      try {
        const [totalDevicesByStatusData, totalDevicesDetailsData] = await Promise.all([
          getTotalDevicesByStatus({
            activationState: DEVICE_ACTIVATION_STATUS.ACTIVATED,
          }),
          getTotalDevices(),
        ]);

        const [totalDevicesByStatusJsonData, totalDevicesDetailsJsonData] = await Promise.all([
          totalDevicesByStatusData.json(),
          totalDevicesDetailsData.json(),
        ]);
        if (isMounted) {
          const cumulativeUsedLicensesCountData = {
            data: {
              search: {
                total: totalDevicesByStatusJsonData.data.search.total + additionalUsedLicensesCount,
              },
            },
          };

          setFrlOnlineUsedLicensesCount(totalDevicesByStatusJsonData.data.search.total);
          setActivatedDevices(cumulativeUsedLicensesCountData);
          setDevices(totalDevicesDetailsJsonData);
        }
      } catch (error) {
        if (isMounted) {
          log.error(error);
          setIsError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    const product = rootStore.organizationStore.productList.items.find(
      (item) => item.id === productId
    );

    if (!product.isDeviceLicense()) {
      setIsLoading(false);
      setIsError(undefined);
      // Exit useEffect early; we don't need to fetch for non-device licenses.
      return undefined;
    }

    if (feature.isDisabled('temp_enable_react_sdl') && product.isSharedDeviceLicense()) {
      setIsLoading(false);
      setIsError(undefined);
      // Exit useEffect early; we don't fetch for SDL because their API is not ready until temp_enable_react_sdl is on
      return undefined;
    }

    if (product.isFeatureRestrictedLicense()) {
      const productFulfillableItemList = product.fulfillableItemList;
      if (isProductFRLOfflineOnly(productFulfillableItemList)) {
        // FRL Offline
        setActivatedDevices({data: {search: {total: EN_DASH}}});
        setDevices({data: {search: {total: EN_DASH}}});
        setIsLoading(false);
      } else if (!productFulfillableItemList.hasPackagePreconditioningForFRLOnline()) {
        // Any combination of FRL Offline, FRL LAN, and FRL Isolated. However, total used licenses
        // and devices counts are not provided for FRL Offline
        setActivatedDevices({
          data: {search: {total: getNonFRLOnlineUsedLicenses(product)}},
        });
        setDevices({data: {search: {total: EN_DASH}}}); // only FRL Online has total devices count
        setIsLoading(false);
      } else if (isProductFRLOnlineOnly(productFulfillableItemList)) {
        getAll();
      } else {
        // multi-flavors FRL product
        getAll(getNonFRLOnlineUsedLicenses(product));
      }
    } else {
      // non-FRL such as SDL
      getAll();
    }
    return () => {
      isMounted = false;
    };
  }, [getTotalDevices, getTotalDevicesByStatus, productId, triggerToRefetchFrlDevices]);

  return {
    activatedDevices,
    devices,
    frlOnlineUsedLicensesCount,
    isError,
    isLoading,
  };
};
export default useTotalDevices;
