(function () {
  /**
   * @deprecated Please use src2/app/core/products/access/productProfileAccess.js
   *
   * @ngdoc service
   * @name app.core.product:productConfigurationAccess
   * @description product access factory
   */
  angular
    .module('app.core.product.configurations.access')
    .factory('productConfigurationAccess', productConfigurationAccess);

  /* @ngInject */
  function productConfigurationAccess(_, auth, feature, OrganizationManager, productAccess) {
    const service = {
      canAssignAdmin: allowIfNotMigrating,
      canAssignDeveloper: allowIfNotMigrating,
      canAssignIntegration: allowIfNotMigrating,
      canAssignUser,
      canCreateDeviceReports,
      canCreateNewProductProfile,
      canRecoverLicenses,
      canRemoveAdmin: allowIfNotMigrating,
      canRemoveDeveloper: allowIfNotMigrating,
      canRemoveProductProfile,
      canRemoveUser: allowIfNotMigrating,
      canShowAdmins,
      canShowDisplayName,
      canShowUserNotifications,
      canShowUsers,
      canViewAdminCount,
      canViewAssociatedPackages,
      canViewEntitledUsersCount,
      canViewLicensesTotalQuantity,
      canViewLicensesUsedQuantity,
      canViewNotifyUser,
      canViewProductProfiles,
      canViewSetQuotaButton,
      hasNoAvailableLicenses,
    };

    return service;

    //////////////

    function allowIfNotMigrating() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    function canAssignUser(product) {
      return productAccess.canAssignUserIgnoringLicenseCounts(product);
    }

    function canCreateNewProductProfile(product, targetExpression) {
      const hasUserDelegatableProduct =
        OrganizationManager.getProductsForActiveOrg().hasUserDelegatableProduct();
      // If the childCreationAllowed field is missing entirely then it's
      // likely that the license lives in LS2 and hasn't been moved to LS3
      // yet. In that case we'll operate with the old logic.
      if (feature.isEnabled('temp_sls_m_3') && _.has(product, 'childCreationAllowed')) {
        return (
          _.get(product, 'childCreationAllowed') &&
          !OrganizationManager.getContractsForActiveOrg().isMigrating() &&
          hasUserDelegatableProduct &&
          isOrgAdminOrProductAdmin(targetExpression)
        );
      }
      return (
        !OrganizationManager.getContractsForActiveOrg().isMigrating() &&
        hasUserDelegatableProduct &&
        isOrgAdminOrProductAdmin(targetExpression)
      );
    }

    function canCreateDeviceReports(product) {
      return product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canRecoverLicenses(consumableSummarizationList, profileId, product) {
      // when the product is a shared device license product
      if (product.fulfillableItemList.hasLaboratoryLicenseManagement() && auth.isUserOrgAdmin()) {
        const consumables = consumableSummarizationList.getConsumablesForSummaryId(profileId);
        return _.get(consumables, '[0].consumedQuantity') > 0;
      }
      return false;
    }

    function canRemoveProductProfile() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    function canShowAdmins(product) {
      return product.isEnterprise() && product.isAdministerable();
    }

    function canShowDisplayName(product) {
      return !product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canShowUserNotifications(product) {
      return !product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canShowUsers(product) {
      return (
        !product.fulfillableItemList.hasLaboratoryLicenseManagement() &&
        product.fulfillableItemList.hasDelegationType() &&
        !product.isLegacyDeviceLicense() &&
        !product.isFeatureRestrictedLicense() &&
        !product.fulfillableItemList.hasOrgDelegatable()
      );
    }

    function canViewAdminCount() {
      return true;
    }

    function canViewAssociatedPackages(product) {
      return product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canViewEntitledUsersCount(product) {
      return !product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canViewLicensesTotalQuantity(product) {
      return !product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canViewLicensesUsedQuantity(product) {
      return product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canViewNotifyUser(product) {
      return !product.fulfillableItemList.hasLaboratoryLicenseManagement();
    }

    function canViewProductProfiles(product) {
      return (
        product.customerSegment !== 'TEAM' &&
        !product.isLegacyDeviceLicense() &&
        !product.isFeatureRestrictedLicense() &&
        !product.fulfillableItemList.hasOrgDelegatable()
      );
    }

    function canViewSetQuotaButton(product) {
      return (
        canViewProductProfiles(product) &&
        isOrgAdminOrProductAdmin(_.get(product, 'targetExpression')) &&
        product.usesSeatBasedDelegation()
      );
    }

    /**
     * @description Method to determine if the profile has available licenses (or can delegate users
     *     without having them).
     * @param {ProductConfiguration} productProfile the profile to check for available licenses
     * @returns {Promise} Resolves to true if the profile has no available licenses, false otherwise
     */
    function hasNoAvailableLicenses(productProfile) {
      return productProfile.product.hasNoAvailableLicenses() && productProfile.product.isTeam();
    }

    //////////

    function isOrgAdminOrProductAdmin(targetExpression) {
      return auth.isUserOrgAdmin() || auth.isUserProductAdminForTarget(targetExpression);
    }
  }
})();
