(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name SYNC_TASK_STATUSES
   * @description defines constants related to Directory Sync Service.
   */
  angular
    .module('app.core.api.directory-sync')
    .constant('SYNC_TASK_STATUSES', {
      DONE: 'DONE',
      ERROR: 'ERROR',
      NOT_STARTED: 'NOT_STARTED',
      RUNNING: 'RUNNING',
    })
    .constant('PARTNER_TYPES', {
      AZURE: 'AZURE',
      GSUITE: 'GSUITE',
    })
    .constant('SYNC_TYPE', {
      GROUP_CENTRIC: 'GROUP_CENTRIC',
      SCIM: 'SCIM',
      SCIM2: 'SCIM2',
    });
})();
