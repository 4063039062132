import {StatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const EDU_SYNC_STATUSES = {
  COMPLETED: 'Completed',
  IDLE: 'Idle',
  INVALID: 'Invalid',
  QUEUED: 'Queued',
  RUNNING: 'Running',
  SCHEDULED: 'Scheduled',
  STOPPED: 'Stopped',
  UNKNOWN: 'Unknown',
};

const EDU_SYNC_STATUS_LIGHT = {
  [EDU_SYNC_STATUSES.QUEUED]: {
    label: 'settings.sync.eduSync.status.Queued',
    variant: 'yellow',
  },
  [EDU_SYNC_STATUSES.IDLE]: {
    label: 'settings.sync.eduSync.status.Idle',
    variant: 'info',
  },
  [EDU_SYNC_STATUSES.RUNNING]: {
    label: 'settings.sync.eduSync.status.Running',
    variant: 'neutral',
  },
  [EDU_SYNC_STATUSES.SCHEDULED]: {
    label: 'settings.sync.eduSync.status.Scheduled',
    variant: 'positive',
  },
  [EDU_SYNC_STATUSES.STOPPED]: {
    label: 'settings.sync.eduSync.status.Stopped',
    variant: 'notice',
  },
  [EDU_SYNC_STATUSES.INVALID]: {
    label: 'settings.sync.eduSync.status.Invalid',
    variant: 'negative',
  },
  [EDU_SYNC_STATUSES.UNKNOWN]: {
    label: 'settings.sync.eduSync.status.Unknown',
    variant: 'neutral',
  },
};

const EduSyncStatus = ({status}) => {
  const intl = useIntl();

  const {label, variant} = EDU_SYNC_STATUS_LIGHT[status] ?? EDU_SYNC_STATUS_LIGHT.Unknown;

  return (
    <StatusLight data-testid="edu-sync-status" marginStart="-10px" role="status" variant={variant}>
      {intl.formatMessage({id: label})}
    </StatusLight>
  );
};

EduSyncStatus.propTypes = {
  status: PropTypes.string,
};

export default EduSyncStatus;
export {EDU_SYNC_STATUS_LIGHT, EDU_SYNC_STATUSES};
