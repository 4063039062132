(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.core.support').constant('EXPERT_TITLE_CODE', {
    OTHER: 76,
  });
  angular.module('app.core.support').constant('SUPPORT_TICKET_MILO_CASE_TYPE', {
    ADMIN: 1,
    PRODUCT: 2,
  });
})();
