(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.widgets.support').component('appSupportTicketAttachmentNote', {
    bindings: {
      attachment: '<',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-attachment-note/support-ticket-attachment-note.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    feature,
    $translate,
    appSupportTicketPersonNameFilter,
    SUPPORT_TICKET_DISCUSSION_DIRECTION,
    User
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getCreatedOn,
      getTitle,
    });

    function $onInit() {
      const createdBy = _.get(vm, 'attachment.createdBy', {});
      vm.commentCreatedBy = new User({
        id: createdBy.rengaId,
      });
    }

    function getCreatedOn() {
      // Hide the createdOn date for migrated case attachments because it indicates when the
      // attachment was migrated rather than the date it was originally uploaded
      return vm.supportTicket.isMigrated ? undefined : vm.attachment.createdOn;
    }

    function getName() {
      const createdBy = vm.attachment.createdBy;
      if (vm.attachment.direction === SUPPORT_TICKET_DISCUSSION_DIRECTION.OUTBOUND) {
        return appSupportTicketPersonNameFilter({
          firstName: createdBy.firstName,
          fullName: createdBy.fullName,
          lastName: createdBy.lastName,
          showAdobeSuffix: true,
        });
      } else if (vm.attachment.direction === SUPPORT_TICKET_DISCUSSION_DIRECTION.INBOUND) {
        return appSupportTicketPersonNameFilter(createdBy);
      }
      // If direction is UNKNOWN, the attachment was created pre-Parkour 2 and we don't know the
      // name of the user who created it.
      return '';
    }

    function getTitle() {
      return $translate.instant(
        _.isEmpty(getName())
          ? 'widgets.support.supportTicketAttachmentNote.attachedByUnknownUser'
          : 'widgets.support.supportTicketAttachmentNote.attached',
        {name: getName()}
      );
    }
  }
})();
