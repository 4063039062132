(function () {
  /**
   * @deprecated use src2 StorageFile
   *
   * @ngdoc factory
   * @name app.core.storage-file:StorageFile
   * @description Model for a storage file
   */
  angular.module('app.core.storage-file').factory('StorageFile', getStorageFileModel);

  /* @ngInject */
  function getStorageFileModel(
    $http,
    $q,
    $timeout,
    _,
    API_KEY,
    apiUtils,
    authentication,
    downloadUtils,
    User
  ) {
    class StorageFile {
      /**
       * @description Creates a new StorageFile for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {User} options.createdBy The user who created the storage file
       * @param {String} options.id A StorageFile's ID
       * @param {String} options.linkPath A link path to download storage file
       * @param {String} options.name A StorageFile's name
       * @param {String} options.path the path of the StorageFile
       * @param {StorageQuota} options.quota the storage quota of the StorageFile
       * @param {Date} options.repositoryCreatedDate The creation date of the StorageFile
       * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageFile
       * @param {String} options.state the state of the StorageFile
       * @param {String} options.type the type of the file which StorageFile is stored
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      /**
       * @description Method to download storage file.
       *
       * @returns {Promise} promise - resolved when the file is downloaded
       */
      download() {
        // Using $http because ACP does not support encoded url
        // Only download the report when the domain of the link path is adobe.io
        return this.linkPath && _.endsWith(getHostName(this.linkPath), 'adobe.io')
          ? $http({
              headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
                'X-Api-Key': API_KEY.ONESIE1,
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
              url: this.linkPath,
            })
              .then((response) => {
                downloadUtils.downloadExportData(_.get(response, 'data.file'), this.name);
              })
              .catch(() => $q.reject())
          : // Coral will display reverse order if we call show toast at the same time add this timeout to avoid it.
            $timeout(() => $q.reject(), 500);
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        return {
          id: this.id,
        };
      }

      /**
       * @class
       * @description Transforms the /organizations StorageFile response Object into a new
       *   StorageFile instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageFile(dataTransferObject);
      }
    }

    /** Private Methods **/

    /**
     * @description Method to get host name from url
     *
     * @param {String} url url to be processed
     * @returns {String} host name from the url
     */
    function getHostName(url) {
      const match = url.match(/:\/\/(www\d?\.)?(.[^/:]+)/i);
      return _.get(match, 'length') > 2 ? match[2] : null;
    }

    /**
     * @description Initializes Storage File data.
     *
     * @param {Object} model StorageFile model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options = {}) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick([
            'createdBy',
            'id',
            'linkPath',
            'name',
            'path',
            'repositoryCreatedDate',
            'repositoryLastModifiedDate',
            'state',
            'type',
          ])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      model.createdBy = new User(options.createdBy);

      // The name send back from the server could be encoded
      if (model.name) {
        model.name = decodeURI(model.name);
      }

      // setup model as already resolved which ensures it can be used
      // interchangably when fetched from the list or instantiated independently
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    return StorageFile;
  }
})();
