/* eslint-disable max-lines -- @mohanver to break down the component further */
import './AvailableApps.pcss';
import {showInfo} from '@admin-tribe/binky-ui';
import {ActionButton, SearchField} from '@adobe/react-spectrum';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Checkbox to pair with v2 Popover
import Checkbox from '@react/react-spectrum/Checkbox';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Popover should be replaced with v3 Dialog
import Popover from '@react/react-spectrum/Popover';
import RailRightOpen from '@spectrum-icons/workflow/RailRightOpen';
import Search from '@spectrum-icons/workflow/Search';
import TreeCollapseAll from '@spectrum-icons/workflow/TreeCollapseAll';
import TreeExpandAll from '@spectrum-icons/workflow/TreeExpandAll';
import PropTypes from 'prop-types';
import React, {createRef, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {CREATE_PACKAGE} from '../../../../../packagesConstants';
import AppListTable from '../app-list-table/AppListTable';
import LicenseFile from '../license-file/LicenseFile';

// eslint-disable-next-line max-statements -- @mohanver to break down the component further
const AvailableApps = ({
  adobeProductsUi,
  enableAppVersionsPopoverBtnId,
  isEnterpriseSupportPolicyApplicable,
  pkgSessionSettings,
  propagateChangesToContainerView,
  selectedAppsSearchBtnRef,
  selectedAppsSearchFieldRef,
  selectedProducts,
  showLicenseFileEntry,
  updateManagementSettings,
}) => {
  // services
  const intl = useIntl();

  // shared states
  const packageSessionSettings = pkgSessionSettings;

  // states
  const [appFilters, setAppFilters] = useState({
    searchText: '',
    showArchived: false,
    showBeta: false,
    showLatest: true,
    showLTS: false,
    showOlder: false,
    showPreRelease: false,
  });
  const [productsGroupedBySapCode, setProductsGroupedBySapCode] = useState([]);
  const [availableProductsCount, setAvailableProductsCount] = useState(0);
  const [hasBetaApps, setHasBetaApps] = useState(false);
  const [hasPreReleaseApps, setHasPreReleaseApps] = useState(false);
  const [hasLTSApps, setHasLTSApps] = useState(false);
  const [hasUnsupportedApps, setHasUnsupportedApps] = useState(false);
  const [isAllProductExpanded, setIsAllProductExpanded] = useState(true);
  const [isLicenseFileExpanded, setIsLicenseFileExpanded] = useState(true);
  const [currentExpandedProductIndices, setCurrentExpandedProductIndices] = useState([]);
  const [currentExpandedProductSapCode, setCurrentExpandedProductSapCode] = useState([]);
  const [showAvailableProductSearchBar, setShowAvailableProductSearchBar] = useState(false);
  const [show, setShow] = useState(false);

  // refs
  const showMultipleSameBaseVersionProductsToast = useRef(true);
  const availableAppsSearchBtnRef = useRef(null);
  const [accordionRefs, setAccordionRefs] = useState([]);

  const focusNextElement = (index) => {
    // on pressing enter on add/remove button in app list table products, no element is in focus until we press tab,
    //  so explicitly shifting focus on next accordion and if no more accordions
    // focus on selected apps search btn/field
    if (accordionRefs[index + 1]?.current?.headerId) {
      document.querySelector(`[id = ${accordionRefs[index + 1].current.headerId}]`)?.focus();
    } else if (selectedAppsSearchBtnRef.current) {
      selectedAppsSearchBtnRef.current.focus();
    } else {
      selectedAppsSearchFieldRef.current?.focus();
    }
  };

  function toggleExpandProducts(productGrpsBySapCode) {
    if (isAllProductExpanded) {
      setIsAllProductExpanded(false);
      setIsLicenseFileExpanded(false);
      setCurrentExpandedProductIndices([]);
      setCurrentExpandedProductSapCode([]);
    } else {
      setIsAllProductExpanded(true);
      setIsLicenseFileExpanded(true);
      const newCurrentExpandedProductSapCode = productGrpsBySapCode.map(
        (currentValue) => currentValue.sapCode
      );
      setCurrentExpandedProductSapCode(newCurrentExpandedProductSapCode);
      setCurrentExpandedProductIndices([...new Array(productGrpsBySapCode.length).keys()]);
    }
  }

  function onBetaAppsFilterChange() {
    const betaAppSapCodes = adobeProductsUi
      .filter((product) => product.betaAppApui)
      .map((product) => product.sapCode);
    if (appFilters.showBeta) {
      setCurrentExpandedProductSapCode(
        currentExpandedProductSapCode.filter((sapCode) => !betaAppSapCodes.includes(sapCode))
      );
    } else {
      setCurrentExpandedProductSapCode([...currentExpandedProductSapCode, ...betaAppSapCodes]);
    }
    setAppFilters({
      ...appFilters,
      showBeta: !appFilters.showBeta,
    });
  }

  function onPreReleaseAppsFilterChange() {
    const preReleaseSapCodes = adobeProductsUi
      .filter((product) => product.preReleaseApui)
      .map((product) => product.sapCode);
    if (appFilters.showPreRelease) {
      setCurrentExpandedProductSapCode(
        currentExpandedProductSapCode.filter((sapCode) => !preReleaseSapCodes.includes(sapCode))
      );
    } else {
      setCurrentExpandedProductSapCode([...currentExpandedProductSapCode, ...preReleaseSapCodes]);
    }
    setAppFilters({
      ...appFilters,
      showPreRelease: !appFilters.showPreRelease,
    });
  }

  // eslint-disable-next-line complexity -- complex filter
  function availableProductsFilter(product) {
    if (product.sapCode === 'KCCC' || !product.visible || product.selected) {
      return false;
    }

    const matchesShowLatestFilter =
      appFilters.showLatest && !product.archive && !product.betaAppApui && !product.preReleaseApui;
    let matchesShowOlderFilter =
      appFilters.showOlder &&
      product.isUnsupportedVersion &&
      !product.betaAppApui &&
      !product.preReleaseApui;
    if (!isEnterpriseSupportPolicyApplicable) {
      matchesShowOlderFilter =
        matchesShowOlderFilter || (appFilters.showOlder && product.isMaintenanceBuild);
    }
    const matchesShowLTSFilter = appFilters.showLTS && product.isMaintenanceBuild;
    const matchesShowBetaFilter = appFilters.showBeta && product.betaAppApui;
    const matchesShowPreReleaseFilter = appFilters.showPreRelease && product.preReleaseApui;
    const matchesSearchFilter =
      !appFilters.searchText ||
      (appFilters.searchText &&
        product.name?.toUpperCase().includes(appFilters.searchText.toUpperCase()));

    return (
      (matchesShowLatestFilter ||
        matchesShowOlderFilter ||
        matchesShowLTSFilter ||
        matchesShowBetaFilter ||
        matchesShowPreReleaseFilter) &&
      matchesSearchFilter
    );
  }

  function addProduct(productToAdd, alwaysShowSameBaseVersionProductsToast, isAddAllOp) {
    const product = productToAdd;
    if (
      selectedProducts.some(
        (selectedProduct) =>
          selectedProduct.baseVersion === product.baseVersion &&
          selectedProduct.sapCode === product.sapCode
      )
    ) {
      if (
        alwaysShowSameBaseVersionProductsToast ||
        showMultipleSameBaseVersionProductsToast.current
      ) {
        showMultipleSameBaseVersionProductsToast.current =
          !showMultipleSameBaseVersionProductsToast.current;
        showInfo(
          intl.formatMessage({
            id: 'packages.createPackage.chooseApps.availableApps.multipleSameBaseVersionToast',
          })
        );
      }
      return;
    }
    product.selected = true;
    selectedProducts.splice(1, 0, product);
    if (!isAddAllOp) {
      updateManagementSettings();
    }
  }

  function addAllProducts() {
    showMultipleSameBaseVersionProductsToast.current = true;
    productsGroupedBySapCode.forEach((productGroup) => {
      productGroup.products.forEach((product) => {
        addProduct(product, false, true);
      });
    });
    showMultipleSameBaseVersionProductsToast.current = true;
    updateManagementSettings();
    addLicenseFile();
  }

  function showLicenseInAvailableProductsList() {
    return showLicenseFileEntry() && !packageSessionSettings.licenseFileSelected;
  }

  function addLicenseFile() {
    if (
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_ONLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_OFFLINE ||
      packageSessionSettings.packageType === CREATE_PACKAGE.TYPE.FRL_LAN
    ) {
      packageSessionSettings.licenseFileSelected = true;
      packageSessionSettings.nextButtonEnabled = true;
    }
    propagateChangesToContainerView();
  }

  function getLicenseFileSelectedIndex() {
    return isLicenseFileExpanded ? [0] : [];
  }

  function isItemPresentInAvailableProductsSection() {
    if (
      showLicenseFileEntry() &&
      packageSessionSettings.addRemoveOptionVisible &&
      !packageSessionSettings.licenseFileSelected
    ) {
      return true;
    }
    return !!availableProductsCount;
  }

  function getEmptyAvailableAppsMessage() {
    // all filters set to false
    if (Object.values(appFilters).every((val) => !val)) {
      return [
        intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.emptyFilters.title',
        }),
        intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.emptyFilters.desc',
        }),
      ];
    }
    // just search text is set
    if (appFilters.searchText) {
      return [
        intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.emptySearch.title',
        }),
        intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.emptySearch.desc',
        }),
      ];
    }
    // otherwise all apps according to current criteria are already added
    return [
      intl.formatMessage({
        id: 'packages.createPackage.chooseApps.availableApps.allAppsAdded.title',
      }),
      intl.formatMessage({
        id: 'packages.createPackage.chooseApps.availableApps.allAppsAdded.desc',
      }),
    ];
  }

  function showOlderOrUnsupportedAppsFilter() {
    return hasUnsupportedApps && packageSessionSettings.showExtraProductsInTemplates;
  }

  function showBetaAppsFilter() {
    return hasBetaApps && packageSessionSettings.showExtraProductsInTemplates;
  }

  function showPreReleaseAppsFilter() {
    return hasPreReleaseApps && packageSessionSettings.showExtraProductsInTemplates;
  }

  function showLTSFilter() {
    return (
      hasLTSApps &&
      isEnterpriseSupportPolicyApplicable &&
      packageSessionSettings.showExtraProductsInTemplates
    );
  }

  function isFiltersButtonDisabled() {
    return (
      !showBetaAppsFilter() &&
      !showPreReleaseAppsFilter() &&
      !showOlderOrUnsupportedAppsFilter() &&
      !showLTSFilter()
    );
  }

  function getLicenseFileAccordionStyle() {
    return availableProductsCount ? {borderBottom: 'none'} : {};
  }

  // updates product view on when products added/removed, or filters change
  useEffect(() => {
    // calculate new products to be rendered
    const newProductsGroupedBySapCode = [];
    const filteredAPUIs = adobeProductsUi.filter(availableProductsFilter);
    filteredAPUIs.forEach((product) => {
      const existingProdGroup = newProductsGroupedBySapCode.find(
        (productGroup) => productGroup.sapCode === product.sapCode
      );
      if (existingProdGroup) {
        existingProdGroup.products.push(product);
        if (!product.archive) {
          existingProdGroup.name = product.name;
        }
      } else {
        const newProdGroup = {
          name: product.name,
          products: [product],
          sapCode: product.sapCode,
        };
        newProductsGroupedBySapCode.push(newProdGroup);
      }
    });
    setProductsGroupedBySapCode(newProductsGroupedBySapCode);
    setAvailableProductsCount(filteredAPUIs.length);

    // update expanded indices now
    const newCurrentExpandedProductIndices = [];
    currentExpandedProductSapCode.forEach((sapCode) => {
      const idx = newProductsGroupedBySapCode.findIndex(
        (productGroup) => productGroup.sapCode === sapCode
      );
      if (idx >= 0) newCurrentExpandedProductIndices.push(idx);
    });
    setCurrentExpandedProductIndices(newCurrentExpandedProductIndices);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger when listed props change
  }, [adobeProductsUi.length, selectedProducts.length, appFilters]);

  // reinitialize view on adobeProductsUi size change
  useEffect(() => {
    setIsAllProductExpanded(true);
    setIsLicenseFileExpanded(true);
    setHasLTSApps(adobeProductsUi.some((product) => product.isMaintenanceBuild && product.visible));
    setHasBetaApps(adobeProductsUi.some((product) => product.betaAppApui && product.visible));
    setHasPreReleaseApps(
      adobeProductsUi.some((product) => product.preReleaseApui && product.visible)
    );
    setHasUnsupportedApps(
      adobeProductsUi.some(
        (product) =>
          product.isUnsupportedVersion &&
          product.visible &&
          !product.betaAppApui &&
          !product.preReleaseApui
      )
    );
    const newCurrentExpandedProductSapCode = adobeProductsUi
      .filter(availableProductsFilter)
      .map((product) => product.sapCode);
    setCurrentExpandedProductSapCode(newCurrentExpandedProductSapCode);
    setCurrentExpandedProductIndices([
      ...new Array(newCurrentExpandedProductSapCode.length).keys(),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger when listed props change
  }, [adobeProductsUi.length]);

  // add or remove accordion item refs
  useEffect(() => {
    setAccordionRefs((refs) =>
      new Array(productsGroupedBySapCode.length).fill().map((_, i) => refs[i] || createRef())
    );
  }, [productsGroupedBySapCode.length]);

  return (
    <div styleName="available-products-container">
      <h4 aria-level={3} styleName="available-products-heading">
        <span>
          {intl.formatMessage({
            id: 'packages.createPackage.chooseApps.availableApps.title',
          })}
        </span>
        <span styleName="apps-header-count">({availableProductsCount})</span>
      </h4>
      <div>
        {intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.desc',
        })}
      </div>
      {showAvailableProductSearchBar && (
        <div styleName="product-filters">
          <SearchField
            aria-labelledby={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.searchPlaceholder',
            })}
            autoFocus
            onChange={(text) => {
              setAppFilters({
                ...appFilters,
                searchText: text,
              });
            }}
            onClear={() => {
              setAppFilters({
                ...appFilters,
                searchText: '',
              });
            }}
            value={appFilters.searchText}
            width="328px"
          />
          <span styleName="search-close">
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.collapseAvailableApps',
              })}
              data-testid="search-close-button"
              onPress={() => {
                setShowAvailableProductSearchBar(false);
                setAppFilters({
                  ...appFilters,
                  searchText: '',
                });
                availableAppsSearchBtnRef.current?.focus();
              }}
            >
              <RailRightOpen />
            </ActionButton>
          </span>
        </div>
      )}
      {!showAvailableProductSearchBar && (
        <div styleName="product-filters">
          <span>
            <ActionButton
              ref={availableAppsSearchBtnRef}
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.searchAvailableApps',
              })}
              data-testid="search-open-button"
              density="compact"
              onPress={() => {
                setShowAvailableProductSearchBar(true);
              }}
            >
              <Search />
            </ActionButton>
          </span>
          <span styleName="add-remove-group">
            <ActionButton
              data-testid="toggle-expand-button"
              density="compact"
              isDisabled={!isItemPresentInAvailableProductsSection()}
              onPress={() => {
                toggleExpandProducts(productsGroupedBySapCode);
              }}
            >
              {isAllProductExpanded ? (
                <TreeCollapseAll
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.chooseApps.availableApps.collapseAllAvailableApps',
                  })}
                  data-testid="tree-collapse-all"
                />
              ) : (
                <TreeExpandAll
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.chooseApps.availableApps.expandAllAvailableApps',
                  })}
                  data-testid="tree-expand-all"
                />
              )}
            </ActionButton>
            <OverlayTrigger
              offset={-10}
              onHide={() => {
                setShow(false);
              }}
              placement="bottom"
              show={show}
              trigger="click"
            >
              <ActionButton
                aria-controls={enableAppVersionsPopoverBtnId}
                aria-expanded={show}
                aria-label={intl.formatMessage({
                  id: 'packages.createPackage.chooseApps.availableApps.enableOtherVersions',
                })}
                data-testid="app-filters-button"
                density="compact"
                isDisabled={isFiltersButtonDisabled()}
                marginStart="6px"
                maxWidth="180px"
                onPress={() => {
                  setShow(true);
                }}
              >
                {intl.formatMessage({
                  id: 'packages.createPackage.chooseApps.availableApps.filters.appVersions',
                })}
              </ActionButton>
              <Popover id={enableAppVersionsPopoverBtnId} placement="bottom">
                <div>
                  <Checkbox
                    checked={appFilters.showLatest}
                    onChange={() => {
                      setAppFilters({
                        ...appFilters,
                        showLatest: !appFilters.showLatest,
                      });
                    }}
                  >
                    {intl.formatMessage({
                      id: 'packages.createPackage.chooseApps.availableApps.filters.latestVersions',
                    })}
                  </Checkbox>
                </div>
                {showLTSFilter() && (
                  <div>
                    <Checkbox
                      checked={appFilters.showLTS}
                      onChange={() => {
                        setAppFilters({
                          ...appFilters,
                          showLTS: !appFilters.showLTS,
                        });
                      }}
                      // if lts filter is the last item on list, then close popover on tab but not shift+tab
                      onKeyDown={(e) => {
                        if (
                          !e.shiftKey &&
                          e.key === 'Tab' &&
                          !showBetaAppsFilter() &&
                          !showPreReleaseAppsFilter() &&
                          !showOlderOrUnsupportedAppsFilter()
                        ) {
                          setShow(false);
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'packages.createPackage.chooseApps.availableApps.filters.LTSVersions',
                      })}
                    </Checkbox>
                  </div>
                )}
                {showBetaAppsFilter() && (
                  <div>
                    <Checkbox
                      checked={appFilters.showBeta}
                      onChange={() => {
                        onBetaAppsFilterChange();
                      }}
                      // if beta apps filter is the last item on list, then close popover on tab but not shift+tab
                      onKeyDown={(e) => {
                        if (
                          !e.shiftKey &&
                          e.key === 'Tab' &&
                          !showPreReleaseAppsFilter() &&
                          !showOlderOrUnsupportedAppsFilter()
                        ) {
                          setShow(false);
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'packages.createPackage.chooseApps.availableApps.filters.betaVersions',
                      })}
                    </Checkbox>
                  </div>
                )}
                {showPreReleaseAppsFilter() && (
                  <div>
                    <Checkbox
                      checked={appFilters.showPreRelease}
                      onChange={() => {
                        onPreReleaseAppsFilterChange();
                      }}
                      // if Pre Release Apps filter is the last item on list, then close popover on tab but not shift+tab
                      onKeyDown={(e) => {
                        if (!e.shiftKey && e.key === 'Tab' && !showOlderOrUnsupportedAppsFilter()) {
                          setShow(false);
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'packages.createPackage.chooseApps.availableApps.filters.preReleaseVersions',
                      })}
                    </Checkbox>
                  </div>
                )}
                {showOlderOrUnsupportedAppsFilter() && (
                  <div>
                    <Checkbox
                      checked={appFilters.showOlder}
                      onChange={() => {
                        setAppFilters({
                          ...appFilters,
                          showOlder: !appFilters.showOlder,
                        });
                      }}
                      // if Older Or Unsupported Apps filter is the last item on list, then close popover on tab but not shift+tab
                      onKeyDown={(e) => {
                        if (!e.shiftKey && e.key === 'Tab') {
                          setShow(false);
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: 'packages.createPackage.chooseApps.availableApps.filters.olderVersions',
                      })}
                    </Checkbox>
                  </div>
                )}
              </Popover>
            </OverlayTrigger>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.addAllAvailableApps',
              })}
              data-testid="add-all-button"
              density="compact"
              isDisabled={!isItemPresentInAvailableProductsSection()}
              marginStart="6px"
              onPress={() => {
                if (selectedAppsSearchBtnRef.current) {
                  selectedAppsSearchBtnRef.current.focus();
                } else {
                  selectedAppsSearchFieldRef.current?.focus();
                }
                addAllProducts();
              }}
            >
              {intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.addAll',
              })}
            </ActionButton>
          </span>
        </div>
      )}

      <div styleName="app-list-container">
        <Accordion
          ariaLevel={4}
          onChange={() => {
            setIsLicenseFileExpanded(!isLicenseFileExpanded);
          }}
          selectedIndex={getLicenseFileSelectedIndex()}
        >
          {showLicenseInAvailableProductsList() && (
            <AccordionItem
              header={intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.licenseFileAccordionHeader',
              })}
              style={getLicenseFileAccordionStyle()}
            >
              <LicenseFile
                addRemoveOptionVisible={!!packageSessionSettings.addRemoveOptionVisible}
                isLicenseFileSelected={!!packageSessionSettings.licenseFileSelected}
                onAddProduct={addLicenseFile}
              />
            </AccordionItem>
          )}
        </Accordion>
        {isItemPresentInAvailableProductsSection() ? (
          <Accordion
            ariaLevel={4}
            multiselectable
            onChange={(e) => {
              const newCurrentExpandedProductIndices = e.filter((value) => !Number.isNaN(value));
              setCurrentExpandedProductIndices(newCurrentExpandedProductIndices);
              const newCurrentExpandedProductSapCode = newCurrentExpandedProductIndices.map(
                (currentValue) => productsGroupedBySapCode[currentValue].sapCode
              );
              setCurrentExpandedProductSapCode(newCurrentExpandedProductSapCode);
            }}
            selectedIndex={currentExpandedProductIndices}
          >
            {productsGroupedBySapCode.map((prodGroup, index) => (
              <AccordionItem
                key={prodGroup.sapCode}
                ref={accordionRefs[index]}
                header={`${prodGroup.name} (${prodGroup.products.length})`}
              >
                <AppListTable
                  focusNextElement={() => {
                    focusNextElement(index);
                  }}
                  onAddProduct={addProduct}
                  products={prodGroup.products}
                  showAddButton
                  showLearnMoreLink
                  showLTSLink={isEnterpriseSupportPolicyApplicable}
                  showSysReqLink
                />
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <div data-testid="no-available-apps-message" styleName="no-available-apps-message">
            <h3 aria-level={4}>{getEmptyAvailableAppsMessage()[0]}</h3>
            <p>{getEmptyAvailableAppsMessage()[1]}</p>
          </div>
        )}
      </div>
    </div>
  );
};

AvailableApps.propTypes = {
  /**
   * Exhaustive list of packageable products for the current platform
   */
  adobeProductsUi: PropTypes.instanceOf(Array).isRequired,
  /**
   * Reference to enable app versions apps search button
   */
  enableAppVersionsPopoverBtnId: PropTypes.string.isRequired,
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Shared object for package creation session
   */
  pkgSessionSettings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Method to push changes to Angular scope
   */
  propagateChangesToContainerView: PropTypes.func.isRequired,
  /**
   * Reference to selected apps search button
   */
  selectedAppsSearchBtnRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * Reference to selected apps search field
   */
  selectedAppsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * List of currently selected products
   */
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
  /**
   * Method to check if file entry should be shown or not, returns true if it should be
   */
  showLicenseFileEntry: PropTypes.func.isRequired,
  /**
   * Method to update management settings for the given context
   */
  updateManagementSettings: PropTypes.func.isRequired,
};

export default AvailableApps;
/* eslint-enable max-lines -- @mohanver to break down the component further */
