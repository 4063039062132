import {OrganizationEncryption} from '@admin-tribe/binky';
import {Flex, ProgressCircle, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {ENCRYPTION_SETTINGS_CONSTANTS} from '../EncryptionSettingsPageConstants';

const namespace = 'settings.encryptionSettings';

/**
 * @description displays various in-progress messages when encryption
 * status for an organization is being changed
 */
const EncryptionProgressCalc = ({encryptionInfo, processedPercentage}) => {
  const intl = useIntl();

  const generateProgressMessage = () => {
    if (
      processedPercentage === 0 ||
      encryptionInfo.encryptionStatus === ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_STATUS.REVOKED
    ) {
      return (
        <Text marginTop="size-50">
          {intl.formatMessage({
            id: `${namespace}.status.inProgress.processing`,
          })}
        </Text>
      );
    }
    if (
      processedPercentage > 0 &&
      processedPercentage < 100 &&
      encryptionInfo.encryptionStatus !== ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_STATUS.REVOKED
    ) {
      return (
        <FormattedMessage
          id={`${namespace}.status.inProgress.progressPercentage`}
          marginTop="size-50"
          values={{percentage: processedPercentage}}
        />
      );
    }
    if (processedPercentage >= 100) {
      return (
        <Text marginTop="size-50">
          {intl.formatMessage({
            id: `${namespace}.status.inProgress.finishing`,
          })}
        </Text>
      );
    }
    return null;
  };

  return (
    <View>
      <Flex alignItems="center" data-testid="progress-percentage" direction="column">
        <ProgressCircle
          aria-label={intl.formatMessage({
            id: `${namespace}.status.inProgress.processing`,
          })}
          isIndeterminate
          marginX="size-100"
          size="M"
        />
        {generateProgressMessage()}
      </Flex>
    </View>
  );
};

EncryptionProgressCalc.propTypes = {
  /**
   * Object that contains encryption information
   * for an organization
   */
  encryptionInfo: PropTypes.instanceOf(OrganizationEncryption).isRequired,
  /**
   * Percentage of encrypted units/total units
   * when encryption enabling is in progress
   */
  processedPercentage: PropTypes.number.isRequired,
};

export default EncryptionProgressCalc;
