(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilTrusts
   * @description defines service to manage jil trust resources
   */
  angular.module('app.core.api.jil').provider('jilTrusts', jilTrustsProvider);

  let url;

  /* @ngInject */
  function jilTrustsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        trusts: getTrustsResource(),
        trustsNotifications: getTrustsNotificationsResource(),
      };

      return service;

      ///////////

      function getTrustsResource() {
        return $resource(
          `${url}/:orgId/trusts/:directoryId`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {isArray: true, method: 'PATCH'},
            sendTrustRequests: {isArray: true, method: 'POST'},
          }
        );
      }

      function getTrustsNotificationsResource() {
        return $resource(
          `${url}/:orgId/trusts/:directoryId/notifications`,
          {
            directoryId: '@directoryId',
            orgId: apiUtils.getActiveOrg,
          },
          {
            remind: {
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
