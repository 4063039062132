import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

/**
 * @description  Checks if the market segment is different than GOV or COM
 * or looks at EDU-related feature flags states in order to allow the adding
 * of EDU IdPs or EDU Roster Sync
 */
function canAddEdu() {
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  return (
    feature.isDisabled('temp_disable_edu_adding') &&
    ((marketSegment !== ORGANIZATION_MARKET_SEGMENT.GOVERNMENT &&
      marketSegment !== ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) ||
      feature.isEnabled('force_edu_idps'))
  );
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {canAddEdu};
