/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.renew-products-modal2:appRenewProductsSelectSpecialOffers
 * @description Select Special Offers step component
 *
 * @param {Function} onPromoRedeemed the callback when promo offer is redeemed
 * @param {Array<Offer>} promoOfferItems an array of promo offers
 * @param {Boolean} showPromoChatBanner to show or hide the chat banner under the promotional offers
 * @example   <app-renew-products-select-special-offers
 *               on-promo-redeemed="$ctrl.onPromoRedeemed()"
 *               promo-offer-items="$ctrl.promoOfferList.items"
 *               show-promo-chat-banner="$ctrl.showPromoChatBanner">
 *             </app-renew-products-select-special-offers>
 */
(function () {
  angular
    .module('app.widgets.products.purchase.renew-products-modal2')
    .component('appRenewProductsSelectSpecialOffers', {
      bindings: {
        onPromoRedeemed: '&',
        promoOfferItems: '<',
        showPromoChatBanner: '<',
      },
      controller,
      templateUrl:
        'app/widgets/products/purchase/renew-products-modal2/select-special-offers/renew-products-select-special-offers.component.html',
    });

  /* @ngInject */
  function controller(_, feature) {
    const vm = this;
    _.assign(vm, {
      feature,
    });
  }
})();
