(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.api.adobeio-console')
    .provider('adobeioConsoleOrganizations', adobeioConsoleOrganizationsProvider);

  let url;

  /* @ngInject */
  function adobeioConsoleOrganizationsProvider() {
    this.configure = configure;
    this.$get = $get;

    function configure(config) {
      url = `${config.url}/api/organizations`;
    }

    /* @ngInject */
    function $get($resource) {
      const service = {
        organizations: getOrganizations(),
      };
      return service;

      function getOrganizations() {
        return $resource(`${url}`);
      }
    }
  }
})();
