import {getLocalStorageItem, setLocalStorageItem} from '@admin-tribe/binky';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const DISMISSED_HVA_CARD_IDS = 'dismissedHvaCardIds';

/**
 * @param {HvaCard[]} hvaCards -- all possible HVA cards that can be displayed
 */
function useHvaCardList(hvaCards) {
  const intl = useIntl();
  const [items] = useState(hvaCards);
  const [sortedViewableItems, setSortedViewableItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // sort items whenever "items" changes
  useEffect(() => {
    async function sortHvaCards() {
      setIsLoading(true);
      const viewableCards = await getViewableCards();
      setSortedViewableItems(viewableCards.sort((a, b) => a.priorityOrder - b.priorityOrder));
      setIsLoading(false);
    }

    sortHvaCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only sort when items changes
  }, [items]);

  function add(hvaCard) {
    if (!hvaCard?.isValid?.()) {
      throw new Error('The input HvaCard is invalid.');
    }

    setSortedViewableItems((prevItems) => [...prevItems, hvaCard]);
  }

  function getLocalStorageItemId(itemId) {
    return `${rootStore.organizationStore.activeOrgId}_${itemId}`;
  }

  function remove(hvaCard) {
    if (!sortedViewableItems.find((item) => item.id === hvaCard.id)) {
      return;
    }

    setSortedViewableItems((prevItems) => prevItems.filter((item) => item.id !== hvaCard.id));
    dismissHvaCard(hvaCard);
  }

  function dismissHvaCard(card) {
    const storageId = getLocalStorageItemId(card.id);
    const dismissedCards = getDismissedHvaCardFromLocalStorage();
    dismissedCards.add(storageId);
    setLocalStorageItem(DISMISSED_HVA_CARD_IDS, JSON.stringify([...dismissedCards]));
  }

  function getViewableCards() {
    const fetchAndFilterCards = async (arr, predicate) => {
      const results = await Promise.allSettled(arr.map(predicate));

      return (
        results
          // rejected cards are not viewable (conditions not met)
          .filter((hvaPromise) => hvaPromise.status === 'fulfilled')
          // map to get the HVA cards
          .flatMap((hvaPromise) => hvaPromise.value)
          // filter out any HVAs that have been dismissed
          .filter(filterDismissedCards)
      );
    };
    return fetchAndFilterCards(items, (hva) => hva.get({intl}));
  }

  function filterDismissedCards(card) {
    const storageId = getLocalStorageItemId(card.id);
    return !getDismissedHvaCardFromLocalStorage().has(storageId);
  }

  return [sortedViewableItems, add, remove, isLoading];
}

function getDismissedHvaCardFromLocalStorage() {
  return new Set(JSON.parse(getLocalStorageItem(DISMISSED_HVA_CARD_IDS)));
}

export default useHvaCardList;
