(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.common.coral-checkbox-label')
    .directive('appCoralCheckboxLabel', appCoralCheckboxLabel);

  function appCoralCheckboxLabel($parse) {
    const directive = {
      compile: compileFn,
      restrict: 'A',
    };

    return directive;

    ////////////////////

    function compileFn(element, attrs) {
      const getAttrValue = $parse(attrs.appCoralCheckboxLabel);
      const templateElement = element[0];

      if (templateElement) {
        templateElement.innerText = getAttrValue();
      }
    }
  }
})();
