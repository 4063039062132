import {
  Checkbox,
  Content,
  ContextualHelp,
  Flex,
  Link,
  Radio,
  RadioGroup,
  Text,
} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {useIntl} from 'react-intl';

import {selectPermissionPropType} from '../../../prop-types/configurationGroupsPropTypes';

const radioButtonIdPrefix = 'permission-id-';

const PermissionsSelectForm = ({onChange, permissions}) => {
  const intl = useIntl();

  const selectedPermission = permissions.find((permission) => permission.selected).id;
  const onSelectChange = (newSelectedId) => {
    // onSelectChange is called when tabbing over all children of a RadioGroup
    // therefore we need to check that the item that has been tabbed over is indeed a radio button
    if (!newSelectedId || !newSelectedId.startsWith(radioButtonIdPrefix)) return;
    onChange(
      permissions.map((permission) => ({
        ...permission,
        selected: permission.id === newSelectedId.slice(radioButtonIdPrefix.length),
      }))
    );
  };

  const subPermissionChange = (selected, permissionId, subSelectionContentId, subPermissionId) => {
    onChange(
      permissions.map((permission) =>
        permission.id === permissionId
          ? {
              ...permission,
              subSelectionContent: permission.subSelectionContent.map((subSelectionContent) =>
                subSelectionContent.id === subSelectionContentId
                  ? {
                      ...subSelectionContent,
                      values: subSelectionContent.values.map((subPermission) =>
                        subPermission.id === subPermissionId
                          ? {
                              ...subPermission,
                              selected,
                            }
                          : subPermission
                      ),
                    }
                  : subSelectionContent
              ),
            }
          : permission
      )
    );
  };

  return (
    <RadioGroup
      data-testid="permission-select-form"
      onChange={onSelectChange}
      value={`${radioButtonIdPrefix}${selectedPermission}`} /* accessibility todo: should have label */
    >
      {permissions.map((permission) => (
        <Fragment key={permission.id}>
          {/* overriding the onKeyDown gives desired focus behaviour of keeping it within the radio group when arrow keys are used */}
          <Radio onKeyDown={() => {}} value={`${radioButtonIdPrefix}${permission.id}`}>
            <Text>{permission.name}</Text>
          </Radio>
          <Flex direction="column" gap="size-0" marginStart="size-300">
            <Text>
              {permission.description}
              {permission.extendedDescription && (
                <ContextualHelp variant="info">
                  <Content>
                    <Text>{permission.extendedDescription}</Text>
                  </Content>
                </ContextualHelp>
              )}
              {permission.linkHref && (
                <Text>
                  <Link marginX="size-40">
                    <a href={permission.linkHref} rel="noreferrer" target="_blank">
                      <Flex
                        alignItems="center"
                        direction="row"
                        gap="size-50"
                        UNSAFE_style={{display: 'inline-flex'}}
                      >
                        {intl.formatMessage(
                          {
                            id: 'products.productProfilePermissions.editPermissionsModal.learnMoreAbout',
                          },
                          {name: permission.name}
                        )}
                        <LinkOutIcon
                          aria-label={intl.formatMessage({
                            id: 'products.productProfilePermissions.editPermissionsModal.opensWindow',
                          })}
                          flexShrink={0}
                          size="XS"
                        />
                      </Flex>
                    </a>
                  </Link>
                </Text>
              )}
            </Text>
            {permission.subSelectionContent?.length > 0 &&
              permission.subSelectionContent.map((subSelectionContent) =>
                subSelectionContent.values.map((subPermission) => (
                  <Flex key={`${subSelectionContent.id}-${subPermission.id}`} direction="row">
                    <Checkbox
                      isDisabled={selectedPermission !== permission.id}
                      isSelected={subPermission.selected}
                      onChange={(selected) =>
                        subPermissionChange(
                          selected,
                          permission.id,
                          subSelectionContent.id,
                          subPermission.id
                        )
                      }
                    >
                      {subPermission.name}
                    </Checkbox>
                    {subPermission.description && (
                      <ContextualHelp alignSelf="center" variant="info">
                        <Content>
                          <Text>{subPermission.description}</Text>
                        </Content>
                      </ContextualHelp>
                    )}
                  </Flex>
                ))
              )}
          </Flex>
        </Fragment>
      ))}
    </RadioGroup>
  );
};

PermissionsSelectForm.propTypes = {
  /**
   * Callback when the permissions are changed. This takes an argument of the new permissions.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The select permissions to edit.
   */
  permissions: PropTypes.arrayOf(selectPermissionPropType).isRequired,
};

export default PermissionsSelectForm;
