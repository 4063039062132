import CreditCardIcon from '@spectrum-icons/workflow/CreditCard';
import React from 'react';
import {useIntl} from 'react-intl';

import {canViewDirectContractBillingHistory} from 'core/organizations/access/organizationAccess';
import {goToAccountRoot} from 'features/account/routing/navigation-callbacks/navigationCallbacks';

import QuickLink from '../QuickLink';

const AccountQuickLink = () => {
  const intl = useIntl();

  return (
    <QuickLink
      analyticsOptions={{
        analyticsAction: 'go-to-account',
      }}
      data-testid="quick-link-account"
      Icon={<CreditCardIcon size="M" />}
      label={intl.formatMessage({id: 'overview.quickLinks.goToAccount.title'})}
      onPress={() => goToAccountRoot()}
    />
  );
};

AccountQuickLink.propTypes = {};

AccountQuickLink.get = () => {
  if (canViewDirectContractBillingHistory()) {
    return Promise.resolve(<AccountQuickLink key="AccountQuickLink" />);
  }

  return Promise.reject(new Error('User cannot view direct contract billing history'));
};

export default AccountQuickLink;
