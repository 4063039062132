(function () {
  /**
   * @deprecated This file is being ported to organizationAccess in src2. Any changes should be added there.
   *
   * @ngdoc service
   * @name organizationAccess
   * @description organization access factory
   */
  angular.module('app.core.organizations.access').factory('organizationAccess', OrganizationAccess);
  /* @ngInject */
  function OrganizationAccess(
    $q,
    _,
    adminAccess,
    auth,
    CONTRACT_BUYING_PROGRAM,
    ORGANIZATION_MARKET_SEGMENT,
    OrganizationList,
    OrganizationManager
  ) {
    const service = {
      canAssignAdmin,
      canAssignDeveloper: allowIfNotMigrating,
      canAssignUser,
      canEditAdmin,
      canEditUser,
      canRemoveAdmin,
      canRemoveUser,
      canSetUpOrganizationTypes,
      canViewAssetSharing,
      canViewDirectContractBillingHistory,
      canViewFreeOffers,
      canViewOrganizationAccounts,
      canViewOrganizationDetails,
      canViewOrganizationEncryption,
      canViewOrganizationType,
      canViewPasswordPolicy,
      canViewValueDiscoveryTasksForEnterprise,
      canViewValueDiscoveryTasksForTeam,
    };

    return service;

    //////////////

    function allowIfNotMigrating() {
      return !OrganizationManager.getContractsForActiveOrg().isMigrating();
    }

    /**
     * @deprecated this function has been ported to src2 organizationAccess
     *
     * @param {Contract} contract the contract
     * @returns {Boolean} whether the authenticated user is the contract owner
     */
    function authenticatedUserIsContractOwner(contract) {
      return !!_.invoke(contract, 'isContractOwner', {userId: auth.getUserId()});
    }

    function canEditAdmin() {
      return (
        allowIfNotMigrating() &&
        // Some migration types override the read-only state to allow admins to be added
        // while setting up for the migration, however do not allow admins to be edited
        // (such as changing their role) during this process.
        !OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers()
      );
    }

    function canEditUser() {
      return (
        allowIfNotMigrating() &&
        // Some migration types override the read-only state to allow users to be added
        // while setting up for the migration, however do not allow users to be edited
        // (such as changing their role) during this process.
        !OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers()
      );
    }

    // @deprecated, call adminAccess directly instead
    function canAssignAdmin() {
      return adminAccess.canAssignAdmin();
    }

    /**
     * Determines whether this admin can assign a user to the given organization.
     *
     * @deprecated this function has been ported to src2 organizationAccess
     *
     * @returns {Boolean} returns true if the user can add users
     */
    function canAssignUser() {
      return (
        auth.canAddUsers() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    function canRemoveAdmin() {
      return (
        auth.isUserOrgAdmin() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddAdminLogic()
      );
    }

    function canRemoveUser() {
      return (
        auth.isUserOrgAdmin() &&
        allowIfNotMigrating() &&
        OrganizationManager.getMigrationsForActiveOrg().shouldAlignWithAddUserLogic()
      );
    }

    function canViewAssetSharing() {
      return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) =>
        productList.someByFulfillableItemCondition((fItems) => fItems.hasAssetSharingPolicyConfig())
      );
    }

    /**
     * Access check for viewing direct team billing history (invoice data)
     * To view billing history there must be a direct contract and the authenticated user is the contract owner.
     *
     * @deprecated this function has been ported to src2 organizationAccess
     *
     * @returns {Boolean} whether the user can view direct contract billing history
     */
    function canViewDirectContractBillingHistory() {
      return OrganizationManager.getContractsForActiveOrg()
        .$promise.then((contractList) =>
          $q.resolve(authenticatedUserIsContractOwner(contractList.getDirectContract()))
        )
        .catch($q.resolve(false));
    }

    /**
     * @deprecated this function has been ported to src2 organizationAccess
     *
     * Access check for viewing free offers (Enterprise and Indirect only)
     *
     * @returns {Boolean} whether the user can view free offers
     */
    function canViewFreeOffers() {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return (
        auth.isUserOrgAdmin() &&
        (!!contractList.getEnterpriseContract(CONTRACT_BUYING_PROGRAM.ETLA) ||
          contractList.hasIndirectContract())
      );
    }

    // Resolves to true if there is at least one contract.
    function canViewOrganizationAccounts() {
      return OrganizationManager.getContractsForActiveOrg()
        .$promise.then((contractList) => $q.resolve(!_.isEmpty(contractList.items)))
        .catch($q.resolve(false));
    }

    // Access check for the Organization Details page.
    // Until more sections are added, the check is for an Education org with at least one ETLA, EVIP or VIP contract.
    function canViewOrganizationDetails() {
      if (
        OrganizationList.get().activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
      ) {
        return OrganizationManager.getContractsForActiveOrg()
          .$promise.then((contractList) =>
            $q.resolve(contractList.hasEnterpriseContract() || contractList.hasIndirectContract())
          )
          .catch($q.resolve(false));
      }
      return $q.resolve(false);
    }

    // Access check for the Organization Type section on the Organization Details page.
    // An Education org with at least one ETLA, EVIP or VIP contact.
    function canViewOrganizationType() {
      if (
        OrganizationList.get().activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION
      ) {
        return OrganizationManager.getContractsForActiveOrg()
          .$promise.then((contractList) =>
            $q.resolve(contractList.hasEnterpriseContract() || contractList.hasIndirectContract())
          )
          .catch($q.reject());
      }
      return $q.resolve(false);
    }

    function canSetUpOrganizationTypes() {
      return canViewOrganizationType().then(
        (canViewOrgType) => canViewOrgType && hasNotSetOrgTypes() && auth.canUpdateOrg()
      );
    }

    function canViewOrganizationEncryption() {
      if (!auth.isUserOrgAdmin()) {
        return $q.resolve(false);
      }
      return OrganizationManager.getProductsForActiveOrg().$promise.then(
        (productList) =>
          _.get(productList, 'items.length', 0) > 0 && !productList.hasOnlyTeamProducts()
      );
    }

    function canViewPasswordPolicy() {
      return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) =>
        productList.someByFulfillableItemCondition((fItems) => fItems.hasDomainClaiming())
      );
    }

    /**
     * @deprecated this function has been ported to src2 organizationAccess
     * @returns {Boolean} whether the user can view tasks for an enterprise org
     */
    function canViewValueDiscoveryTasksForEnterprise() {
      if (auth.isUserOrgAdmin()) {
        return OrganizationManager.getProductsForActiveOrg().$promise.then(
          (productList) => !productList.hasOnlyTeamProducts()
        );
      }

      return $q.resolve(false);
    }

    /**
     * @deprecated this function has been ported to src2 organizationAccess
     * @returns {Boolean} whether the user can view tasks for a team org
     */
    function canViewValueDiscoveryTasksForTeam() {
      if (auth.isUserOrgAdmin()) {
        return OrganizationManager.getProductsForActiveOrg().$promise.then((productList) => {
          if (productList.hasOnlyTeamProducts()) {
            const assignableLicenses = productList.getSeatBasedAssignableLicenseCount();
            const provisionedLicenses = productList.getSeatBasedTotalProvisionedQuantity();
            return provisionedLicenses >= assignableLicenses;
          }

          return false;
        });
      }

      return $q.resolve(false);
    }

    //////////

    function hasNotSetOrgTypes() {
      return _.isUndefined(OrganizationList.get().activeOrg.marketSubsegments);
    }
  }
})();
