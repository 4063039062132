/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketNotifyeeFormSection
 *
 * @description The notifyee section for a support form.
 *
 * @binding {Array} notifyeesEmails - An array of existing notifyees
 * on the support ticket.
 * @binding {Function} onChange - Callback when an email is selected. The callback takes an object including 'notifyees' key which is an array of email addresses.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketNotifyeeFormSection', {
    bindings: {
      notifyeesEmails: '<?',
      onChange: '&',
      ticketOwnerEmail: '@',
    },
    controller,
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-notifyee-form-section/support-ticket-notifyee-form-section.component.html',
  });

  /* @ngInject */
  function controller(
    $element,
    $filter,
    $timeout,
    $translate,
    $q,
    _,
    binkyPersonNameFilter,
    emailUtils,
    SearchUserList,
    MAX_NOTIFYEES_ALLOWED
  ) {
    const vm = this;
    const SEARCH_QUERY_MIN_LENGTH = 3;

    _.assign(vm, {
      $onInit,
      allowAddNotifyee,
      getPersonName,
      getSupportTicketNotifyees,
      isNewUser: false,
      notifyees: [],
      onNotifyeesChange,
      onRemoveUser,
      onSearchListItemSelected,
      onSearchSubmit,
      processSearchResults,
      searchItemSelected: null,
      searchListItems: [],
    });

    function $onInit() {
      getSupportTicketNotifyees();
    }

    /////////////////

    function allowAddNotifyee() {
      return vm.notifyees.length === MAX_NOTIFYEES_ALLOWED;
    }

    function emailNotAlreadyAdded(email) {
      return (
        !_.isUndefined(email) &&
        _.find(vm.notifyees, (notifyee) => _.toLower(notifyee.email) === _.toLower(email)) ===
          undefined
      );
    }

    function getInput() {
      return angular.element($element[0].querySelector('.searchlist-input'));
    }

    function getPersonName(result) {
      return binkyPersonNameFilter({
        fallbackName: $translate.instant(
          'widgets.support.formSections.supportTicketNotifyeeFormSection.noName'
        ),
        firstName: result.firstName,
        lastName: result.lastName,
      });
    }

    function getSupportTicketNotifyees() {
      if (!_.isEmpty(vm.notifyeesEmails)) {
        const notifyees = _.map(vm.notifyeesEmails, (notifyeeEmail) => ({email: notifyeeEmail}));
        _.merge(vm.notifyees, notifyees);
      }
    }

    function getSupportTicketNotifyeesEmails() {
      return _.map(vm.notifyees, 'email');
    }

    function isNewUser(email) {
      return (
        emailUtils.looksLikeAnEmail(email) &&
        (_.isUndefined(vm.searchUserList) ||
          _.isEmpty(vm.searchUserList.items) ||
          _.find(
            vm.searchUserList.items,
            (userObj) => _.toLower(userObj.email) === _.toLower(email)
          ) === undefined)
      );
    }

    function isNotTicketOwner(email) {
      return _.toLower(email) !== _.toLower(vm.ticketOwnerEmail);
    }

    function onNotifyeesChange() {
      vm.onChange({notifyees: getSupportTicketNotifyeesEmails()});
    }

    function onRemoveUser(email) {
      _.remove(vm.notifyees, {email});
      onNotifyeesChange();
      // focus on the input if overlay is not shown
      getInput().focus();
    }

    function onSearchListItemSelected(email) {
      if (_.isEmpty(email)) {
        return;
      }
      vm.searchItemSelected = email;
      // if this is a new user, it should be the only search result,
      // otherwise look up the corresponding user object
      let user = {};
      if (isNewUser(email)) {
        user.email = email;
      } else {
        user = _.find(vm.searchResults, {email});
      }
      vm.notifyees.push(user);
      onNotifyeesChange();
    }

    function onSearchSubmit(searchQuery) {
      vm.invalidTooltipMessage = '';

      if (_.isEmpty(searchQuery) || searchQuery.length < SEARCH_QUERY_MIN_LENGTH) {
        vm.searchResults = [];
        return $q.resolve([]);
      }

      const searchQueryLowerCased = _.toLower(searchQuery);

      // Make an API call to search by first name, last name, or email
      vm.searchUserList = SearchUserList.get(searchQueryLowerCased, {});

      return vm.searchUserList.$promise.then(() => {
        if (_.isEmpty(vm.searchUserList.items)) {
          processSearchQuery(searchQueryLowerCased);
        } else {
          const processedResults = processSearchResults();
          if (_.isEmpty(processedResults)) {
            processSearchQuery(searchQueryLowerCased);
          } else {
            vm.isNewUser = false;
            vm.searchResults = processedResults;
          }
        }
        return $q.resolve(vm.searchResults);
      });
    }

    function processSearchQuery(searchQuery) {
      if (emailUtils.looksLikeAnEmail(searchQuery)) {
        if (isNotTicketOwner(searchQuery)) {
          if (emailNotAlreadyAdded(searchQuery)) {
            vm.isNewUser = true;
            vm.searchResults = [
              {
                email: searchQuery,
                newRecipient: true,
              },
            ];
          } else {
            // show the error tooltip indicating that user has been already added
            vm.isNewUser = false;
            vm.invalidTooltipMessage = $translate.instant(
              'widgets.support.formSections.supportTicketNotifyeeFormSection.userAlreadyAdded'
            );
          }
        } else {
          // show the tooltip error indicating that user is the ticket owner
          vm.isNewUser = false;
          vm.invalidTooltipMessage = $translate.instant(
            'widgets.support.formSections.supportTicketNotifyeeFormSection.userIsTicketOwner'
          );
        }
      } else {
        // show the tooltip error indicating that no matches found
        vm.isNewUser = false;
        vm.invalidTooltipMessage = $translate.instant(
          'widgets.support.formSections.supportTicketNotifyeeFormSection.userNotFound'
        );
      }
    }

    function processSearchResults() {
      // filter the search results to remove the ticket owner
      // as well as any users without an email address or those
      // already added to the list
      return _(vm.searchUserList.items)
        .filter((user) => isNotTicketOwner(user.email))
        .filter((user) => emailNotAlreadyAdded(user.email))
        .sortBy(['email'])
        .value();
    }
  }
})();
