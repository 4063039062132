(function () {
  /**
   * @ngdoc module
   * @name app.widgets.directories
   * @description module that defines directory components
   */
  angular.module('app.widgets.directories', [
    'app.core.analytics',
    'app.core.directories',
    'app.core.ims-federated',
    'app.core.organizations',
    'app.widgets.card-button-list',
    'app.widgets.directories.azure',
    'app.widgets.directories.enhanced-saml',
    'app.widgets.directories.idp-status',
    'app.widgets.directories.sync',
    'app.widgets.domains2',
    'app.widgets.users',
    'binky.core.common.selection',
    'binky.core.download-utils',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.translation-utils',
    'binky.core.trusts',
    'binky.shell.panels.drawer',
    'binky.widgets.common.avatar',
    'binky.widgets.common.button',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.date-time',
    'binky.widgets.common.drawer-header.drawer-header-icon',
    'binky.widgets.common.drawer-header.drawer-header-title',
    'binky.widgets.common.feature',
    'binky.widgets.common.file-uploader',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.member.member-type',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.status',
    'binky.widgets.common.toast',
    'binky.widgets.common.wizard',
    'binky.widgets.directories.delete-directory',
    'binky.widgets.directories.directory-list-table',
    'binky.widgets.directories.directory-type',
    'binky.widgets.directories.encryption-status',
    'binky.widgets.directories.status',
    'binky.widgets.domains',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
