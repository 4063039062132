(function () {
  /**
   * @ngdoc module
   * @name app.core.directories.sync
   * @description module that defines directory sync related services
   */
  angular.module('app.core.directories.sync', [
    'app.core.api.directory-sync',
    'binky.core.lodash',
    'binky.core.storage-utils',
  ]);
})();
