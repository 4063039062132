import help from '../../constants/help';
import {hasEnterpriseContractAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> enterprise umapi
 */
const enterpriseUmapi = () => ({
  access: hasEnterpriseContractAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.enterpriseUMAPI.link.one.key',
          locator: 'umapi_doc',
          target: LINK_TARGET_TYPE.GO_URL_UNLOCALIZED,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.enterpriseUMAPI.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.ENTERPRISE_UMAPI,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.enterpriseUMAPI.title',
});
export default enterpriseUmapi;
