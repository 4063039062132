(function () {
  /**
   * @deprecated
   *
   * @ngdoc component
   * @name app.widgets.asset-sharing:appSharingOptionsSelect
   * @description implements card button list to select asset sharing restriction level.
   *
   * @binding {AssetSharing} assetSharing. The asset sharing to be presented and updated
   * @binding {Boolean} canSetSharingOptions. Ability to change sharing configuration when true. Default is to restrict changing configurations
   * @binding {Function} selectRequestAccessOption. Function that getting called when user confirm to update request access option
   */
  angular.module('app.widgets.asset-sharing').component('appSharingOptionsSelect', {
    bindings: {
      assetSharing: '<',
      canSetSharingOptions: '<?',
      selectSharingOption: '=',
    },
    controller,
    templateUrl: 'app/widgets/asset-sharing/sharing-options/sharing-options-select.component.html',
  });

  function controller(_, feature, onesieSrc2, panelManager, RESTRICTION_STATUS) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      onClickCancel,
      onClickConfirm,
      onClickSharingOption,
      onesieSrc2,
    });

    //////////////

    function $onInit() {
      _.assign(vm, {
        feature,
        sharingOptions: getSharingOptions(),
      });
    }

    function onClickCancel() {
      vm.assetSharing.restrictionStatus = vm.assetSharing.savedState.restrictionStatus;
    }

    function onClickConfirm(option) {
      vm.selectSharingOption(option);
      panelManager.close(`${_.toLower(option)}-confirmation-modal-id`);
    }

    function onClickSharingOption(option) {
      if (option !== vm.assetSharing.savedState.restrictionStatus) {
        panelManager.open(`${_.toLower(option)}-confirmation-modal-id`);
      }
    }

    function getSharingOptions() {
      let options;

      return onesieSrc2.core.directories.access
        .canAddDomains()
        .then((canAddDomains) => {
          options = canAddDomains
            ? [
                RESTRICTION_STATUS.NONE,
                RESTRICTION_STATUS.PUBLIC_LINK,
                RESTRICTION_STATUS.AUTHORIZED_DOMAIN_USERS,
              ]
            : [
                RESTRICTION_STATUS.NONE,
                RESTRICTION_STATUS.PUBLIC_LINK,
                RESTRICTION_STATUS.DOMAIN_USERS_WITHOUT_DOMAIN_CLAIMING,
              ];
        })
        .finally(() => {
          vm.sharingOptions = _.map(options, (option) => ({
            id: option,
            modalId: `${_.toLower(option)}-confirmation-modal-id`,
            translateKey: `.${_.camelCase(option)}`,
          }));
        });
    }
  }
})();
