(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    _,
    $delegate,
    $q,
    $translate,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    onesieSrc2,
    organizationAccess,
    OrganizationList,
    OrganizationManager,
    OFFER_LIST_MODE,
    PRICE_POINT,
    storageAccess
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.ACCEPT_FREE_OFFERS,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function openFreeOfferModal2(freeOfferList, offer) {
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.common.components.FreeOfferModal,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                isOpen: true,
                offerList: freeOfferList,
                preferredOffer: offer,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      }

      function accessCallback() {
        let freeOfferList;
        if (organizationAccess.canViewFreeOffers()) {
          freeOfferList = OrganizationManager.getOffersForActiveOrg({
            mode: OFFER_LIST_MODE.PURCHASE,
            price_point: PRICE_POINT.FREE,
          });
          const promises = $q.all([freeOfferList.$promise, storageAccess.activeOrgHasESM()]);

          return promises.then((results) => {
            freeOfferList.filterOnQualifyingFreeOffers({
              organization: OrganizationList.get().activeOrg,
              orgHasESM: results[1],
              ownedProducts: OrganizationManager.getProductsForActiveOrg(),
            });

            if (!_.isEmpty(freeOfferList.items)) {
              const freeOffersByFamily = _.groupBy(
                freeOfferList.items,
                'product_arrangement.family'
              );

              const freeOffersToDisplay = _.map(freeOffersByFamily, _.head);

              const hvaCards = [];

              _.forEach(freeOffersToDisplay, (offer) => {
                const offerName = _.get(offer, 'merchandising.copy.name');
                const id = `hva-free-offer-${offer.product_arrangement.code}`;
                hvaCards.push(
                  new HvaCard({
                    ctaLabel: $translate.instant(
                      'overview.highValueActionArea.valueDiscoveryTasks.acceptFreeOffers.ctaLabel'
                    ),
                    description: _.get(offer, 'merchandising.copy.short_description'),
                    dismissOnCTAClick: true,
                    header: offerName,
                    id,
                    isDismissible: true,
                    offerIcons: _.get(offer, 'merchandising.assets.icons'),
                    offerIconTitle: offerName,
                    onCTAClick: () => {
                      openFreeOfferModal2(freeOfferList, offer);
                    },
                    sectionTitle: $translate.instant(
                      'overview.highValueActionArea.valueDiscoveryTasks.acceptFreeOffers.sectionTitle'
                    ),
                  })
                );
              });
              return _.isEmpty(hvaCards) ? $q.reject() : hvaCards;
            }
            return $q.reject();
          });
        }
        return $q.reject();
      }
    }
  }
})();
