import {log} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import UserFolderList from 'common/services/user-folder-list/UserFolderList';
import MemberStore from 'common/stores/member-store/MemberStore';

import {USERS_SEARCH_ONLY_CONTENT_MODE_LIMIT} from './UserStoreConstants';

/**
 * @description Domain data for User type MemberList: Org users and User group users.
 *  Product users and Profile users use subclass ProductUserStore.
 * @param {Object} [options] - configuration for the store
 * @param {Boolean} [options.immediateLoad] - if true, the list is immediately loaded. The default is false.
 * @param {Object} [options.memberStoreProps] - see MemberStore constructor for additional parameters.
 */
class UserStore extends MemberStore {
  licenseGroupId = null;

  constructor({immediateLoad = false, refreshUserCountRef = null, ...memberStoreProps}) {
    super(memberStoreProps);

    this.refreshUserCountRef = refreshUserCountRef;
    this.searchOnlyContentMode = refreshUserCountRef ? null : false;
    this.loadUsers = true;

    makeObservable(this, {
      getUserFolderListForSelectedIds: action,
      refreshUserCount: action,
      searchOnlyContentMode: observable,
      showMultiProductRoleSwitcher: computed,
      showSearchForUsersMessage: action,
      showUsers: action,
    });

    if (immediateLoad) {
      this.load();
    }
  }

  /**
   * @description The first time this is called (by Store.load), it will init searchOnlyContentMode if required.
   *   If showing the "search for users" message, this updates the user scorecard but short-ciruits the list load.
   *   Otherwise load the list.
   *
   *   Note: Store.load wraps this in a try/catch.
   *   If an error occurs, this.hasLoadingError will be set to true.
   *
   * @param {Object}[options] - Options. See MemberStore.fetchData().
   */
  async fetchData(options) {
    // Either init or update the user scorecard.
    if (this.searchOnlyContentMode === null || this.showSearchForUsersMessage()) {
      await this.refreshUserCount();
      if (this.searchOnlyContentMode) {
        return;
      }
    }

    await super.fetchData(options);
  }

  /**
   * @description For organization users, fetches the list of UserFolder for the list of selected user ids.
   * @returns {Promise} resolves with a UserFolderList with the specified users, if they exist.
   */
  async getUserFolderListForSelectedIds() {
    let userFolderList;

    const userIds = this.selectedItems.map((item) => item.id);
    try {
      this.isLoading = true;
      userFolderList = await UserFolderList.getListByUsers({userIds});
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return userFolderList?.items;
  }

  /**
   * @description Clear the search in the list. If an active search is being cleared, and we're in search-only mode,
   *   short-circuit the list refresh and redisplay the "search for users message".
   * @param {String} [newFilterQuery] - new search string
   */
  onFilterQuery(newFilterQuery) {
    // This signals to the load method that it should short-circuit the load.
    if (this.searchOnlyContentMode && newFilterQuery === '') {
      this.loadUsers = false;
    }
    super.onFilterQuery(newFilterQuery);
  }

  /**
   * @description Get the count of users in the organization or directory and depending on the count, either load the user list or
   *  display the "search for users message" which short-circuits list fetch which is relatively slow when there are a large
   *  number of users. The thinking is if there are a large number of users the chance of finding the relavant user on the
   *  first page of 20 users is slim and the admin will likely search anyways.
   * @throws {Error} any error thrown by the OrganizationUserListCount get method
   */
  async refreshUserCount() {
    let model;

    try {
      model = await this.refreshUserCountRef.get({
        orgId: this.orgId,
        ...this.listOptions,
      });
    } catch (error) {
      log.error(`Error getting user list count: ${error}`);
    }

    runInAction(() => {
      const totalCount = model?.totalCount;

      this.searchOnlyContentMode = totalCount >= USERS_SEARCH_ONLY_CONTENT_MODE_LIMIT;
      this.loadUsers = !this.searchOnlyContentMode;

      if (this.searchOnlyContentMode) {
        this.scorecardValue = totalCount;
      }
    });
  }

  /**
   * @description Remove the selected users and if in search-only mode, short-circuits the list refresh and redisplays the
   *  "search for users message".
   * @param {Object} [saveOptions] - save options to pass to the list's save method
   * @throws {Error} any error thrown by the list's save method
   */
  async removeSelectedMembers(saveOptions) {
    await super.removeSelectedMembers(saveOptions);
    // This signals to the load method that it should short-circuit the load.
    if (this.searchOnlyContentMode) {
      this.loadUsers = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this -- will be computed in subclass
  get showMultiProductRoleSwitcher() {
    return false;
  }

  /**
   * @description Determine if the "search for users message" should be shown in place of the table.
   * @returns {Boolean} true if "search for users message" should be shown in place of the table
   */
  showSearchForUsersMessage() {
    return (
      this.searchOnlyContentMode &&
      this.loadUsers === false &&
      this.filterQuery === '' &&
      !this.hasLoadingError
    );
  }

  /**
   * @description Determines if the product role switcher should be shown with the table actions.
   *   This method will be subclassed for the product and product users lists which this applies.
   * @returns {Boolean} returns false
   */

  /**
   * @description Used to load the users regardless of the setting of search-only mode.
   */
  showUsers() {
    this.loadUsers = true;
    this.load();
  }
}

export default UserStore;
