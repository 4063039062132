/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.appPoNumberSection
 * @description Enter or edit a Purchase Order number associated with a contract.
 * @param {String} poNumber Two-way binding. The Purchase Order string which the admin can optionally filled in.
 * @param {Number} [poNumberMaxLength] maximum length of a Purchase Order Number, currently 35 for DIRECT as the default
 *  @param {String} prefix the path for str-en.json to retrieve the appropriate translations
 * @param {Boolean} [required] marks the PO number input as required if true, optional if false
 *
 * @example <app-po-number-section
 *            po-number="E73E8GFKDSHFS8"
 *            po-number-max-length="35"
 *            translation-prefix="widgets.products.purchase.renewProductsModal.reviewRenewalOrderSection.messages"
 *            required="false">
 *          </app-po-number-section>
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appPoNumberSection', {
    bindings: {
      poNumber: '=?',
      poNumberMaxLength: '<?',
      required: '<?',
      translationPrefix: '<',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/po-number-section/po-number-section.component.html',
  });

  function controller(_, $element, $timeout, PURCHASE_ORDER_MAX_LENGTH, translationUtils) {
    const vm = this;

    _.assign(vm, {
      $postLink,
      body: `${vm.translationPrefix}.body`,
      PURCHASE_ORDER_MAX_LENGTH,
      title: translationUtils.sanitizeSafeInstant(`${vm.translationPrefix}.title`),
    });

    function $postLink() {
      // add a small delay to allow angular to setup the element in the DOM before
      // calling find on the element
      $timeout(focusPOInput);
    }

    function focusPOInput() {
      const poNumberElement = angular.element($element[0].querySelector('.po-input'));
      if (poNumberElement.length > 0) {
        poNumberElement.focus();
      }
    }
  }
})();
