import {ButtonGroup, DialogContainer} from '@adobe/react-spectrum';
import {useIsMounted} from '@pandora/react-is-mounted';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {SCHEDULE_OPTIONS} from 'features/settings/api/eduRosterSync';
import EduSyncSettingsModal from 'features/settings/common/components/edu-sync-settings-modal/EduSyncSettingsModal';
import {EDU_SYNC_STATUSES} from 'features/settings/components/edu-sync-card/EduSyncStatus';
import EduSyncActionMenu from 'features/settings/components/edu-sync-card/footer/EduSyncActionMenu';
import QueueResumeButton from 'features/settings/components/edu-sync-card/footer/QueueResumeButton';
import {
  DIALOGS,
  getDisabledActions,
} from 'features/settings/components/edu-sync-card/footer/eduActionsUtils';
import QueueRosterSyncConfirmationModal from 'features/settings/components/edu-sync-card/modals/QueueRosterSyncConfirmationModal';
import ResumeRosterSyncConfirmationModal from 'features/settings/components/edu-sync-card/modals/ResumeRosterSyncConfirmationModal';
import StopRosterSyncConfirmationModal from 'features/settings/components/edu-sync-card/modals/StopRosterSyncConfirmationModal';

const renderEduActionModal = ({dialog, syncDetails}) => {
  switch (dialog) {
    case DIALOGS.QUEUE_SYNC_CONFIRMATION_MODAL:
      return (
        <QueueRosterSyncConfirmationModal
          rosterSource={syncDetails.rosterSource}
          syncId={syncDetails.syncId}
        />
      );
    case DIALOGS.RESUME_SYNC_CONFIRMATION_MODAL:
      return (
        <ResumeRosterSyncConfirmationModal
          rosterSource={syncDetails.rosterSource}
          syncId={syncDetails.syncId}
        />
      );
    case DIALOGS.STOP_SYNC_CONFIRMATION_MODAL:
      return (
        <StopRosterSyncConfirmationModal
          rosterSource={syncDetails.rosterSource}
          syncId={syncDetails.syncId}
        />
      );
    case DIALOGS.SYNC_SETTINGS_MODAL:
      return <EduSyncSettingsModal sync={syncDetails} />;
    default:
      return null;
  }
};

const EduSyncCardFooter = ({syncDetails}) => {
  const isMounted = useIsMounted();
  const [dialog, setDialog] = useState(null);
  const syncStatus = syncDetails.status;

  const disabledEduActions = getDisabledActions({syncStatus});

  return (
    <>
      <EduSyncActionMenu
        disabledEduActions={disabledEduActions}
        onStopEduSync={() => setDialog(DIALOGS.STOP_SYNC_CONFIRMATION_MODAL)}
        onSyncSettings={() => setDialog(DIALOGS.SYNC_SETTINGS_MODAL)}
        rosterSource={syncDetails.rosterSource}
      />
      <ButtonGroup>
        <QueueResumeButton
          disabledEduActions={disabledEduActions}
          onQueueSyncConfirm={() => setDialog(DIALOGS.QUEUE_SYNC_CONFIRMATION_MODAL)}
          onResumeSyncConfirm={() => setDialog(DIALOGS.RESUME_SYNC_CONFIRMATION_MODAL)}
          syncDetails={syncDetails}
        />
      </ButtonGroup>

      <DialogContainer onDismiss={() => isMounted() && setDialog(null)}>
        {renderEduActionModal({
          dialog,
          syncDetails,
        })}
      </DialogContainer>
    </>
  );
};

EduSyncCardFooter.propTypes = {
  syncDetails: PropTypes.shape({
    /**
     * The ID of the school district as registered with the roster provider
     */
    districtId: PropTypes.string.isRequired,
    /**
     * Denotes the current state of the edu roster sync
     */
    enabled: PropTypes.bool.isRequired,
    /**
     * The unique identifier for the IMS Org
     */
    imsOrgId: PropTypes.string.isRequired,
    /**
     * The timestamp for the last sync. It's null if no sync happened yet
     */
    lastSyncedAt: PropTypes.string,
    /**
     * Denotes an Adobe product
     */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productAppName: PropTypes.string,
        productName: PropTypes.string,
      }).isRequired
    ),
    /**
     * The roster sync provider. The supported providers are Clever and Classlink
     */
    rosterSource: PropTypes.string.isRequired,
    /**
     * Denotes the current status of the edu roster sync
     */
    status: PropTypes.oneOf(Object.values(EDU_SYNC_STATUSES)).isRequired,
    /**
     * The unique identifier for a edu roster sync
     */
    syncId: PropTypes.string.isRequired,
    /**
     * The type of schedule by which the sync runs. One of "daily", "weekly", and "manual"
     */
    syncSchedule: PropTypes.oneOf(Object.values(SCHEDULE_OPTIONS)).isRequired,
  }).isRequired,
};

export default EduSyncCardFooter;
