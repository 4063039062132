import {eventBus} from '@admin-tribe/binky';
import {OverlayWait} from '@admin-tribe/binky-ui';
import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';

import orgChangeConstants from 'shell/components/organization-switcher-container/orgChangeConstants';

import Shell from './Shell';

/** The React portion of the application */
const ReactApp = () => {
  const [showWaitOverlay, setShowWaitOverlay] = useState(false);

  /** Registering event handlers */
  useEffect(() => {
    eventBus.registerEventHandler((eventId) => {
      // eslint-disable-next-line default-case -- No default needed
      switch (eventId) {
        case orgChangeConstants.START:
          setShowWaitOverlay(true);
          break;

        case orgChangeConstants.ERROR:
        case orgChangeConstants.SUCCESS:
          setShowWaitOverlay(false);
          break;
      }
    });
  }, []);

  return (
    <OverlayWait isLoading={showWaitOverlay}>
      <Shell>
        {/* Wrap all routes in <main> to create the main content landmark
         * https://accessibility.corp.adobe.com/docs/interaction_design/landmark_regions/#main */}
        {/* eslint-disable-next-line react/forbid-dom-props -- must be unique in document */}
        <main id="main-content">
          <Outlet />
        </main>
      </Shell>
    </OverlayWait>
  );
};

ReactApp.displayName = 'ReactApp';

export default ReactApp;
