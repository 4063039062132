(function () {
  angular.module('app.core', [
    'app.core.analytics',
    'app.core.auth',
    'app.core.gainsight',
    'app.core.jobs',
    'app.core.message',
    'app.core.payment-info-editor',
    'app.core.product.trial-helper',
    'app.core.ready',
    'app.core.user',
    'binky.core.api.ready',
    'binky.core.configuration.ready',
    'binky.core.contract',
    'binky.core.locale',
    'binky.core.lodash',
    'binky.core.log',
    'binky.core.promise-utils',
    'binky.core.src2-migration',
    'binky.core.storage-utils',
    'binky.ui-migration',
    'pascalprecht.translate',
  ]);
})();
