(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.core.gainsight')
    .constant('GAINSIGHT_PANEL_STATUS_CONTEXT', {
      SUCCESS: 'SUCCESS',
    })
    .constant('GAINSIGHT_EVENT_TYPES', {
      CUSTOM_BUTTON_CLICK: 'customButtonClick',
      ENGAGEMENT_COMPLETED: 'engagementCompleted',
      ENGAGEMENT_VIEW: 'engagementView',
      ENGAGEMENT_VIEWED_STEP: 'engagementViewedStep',
      LINK_CLICK: 'linkClick',
      SURVEY_RESPONDED: 'surveyResponded',
    });
})();
