(function () {
  /**
   * @ngdoc module
   * @name app.widgets.directories.enhanced-saml
   * @description module that defines enhanced saml directory components
   */
  angular.module('app.widgets.directories.enhanced-saml', [
    'app.core.ims-federated',
    'app.widgets.common.drag-and-drop-file',
    'binky.core.lodash',
    'binky.core.log',
    'binky.shell.panels.modal',
    'binky.widgets.common.page-banner',
    'binky.widgets.common.wait',
  ]);
})();
