import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import React from 'react';

import AddDomainsTable from 'features/settings/components/domains/add-domains-modal/add-domains-table/AddDomainsTable';
import {ADD_DOMAINS_MODAL_WIZARD_STEPS} from 'features/settings/components/domains/add-domains-modal/addDomainsModalConstants';
import {useAddDomainsModalContext} from 'features/settings/components/domains/add-domains-modal-context/AddDomainsModalContext';

const AddDomainsTableSection = () => {
  const {state, addDomainsModalStepManager, setSelectedDomains} = useAddDomainsModalContext();

  let domains = [];
  let renderStatus = true;

  if (
    addDomainsModalStepManager.currentStep.id ===
    ADD_DOMAINS_MODAL_WIZARD_STEPS.REQUEST_ACCESS_STEP.id
  ) {
    domains = [...state.syntheticErrorDomains, ...state.requestAccessDomains].map((item) => ({
      ...item,
      domainName: item.id,
    }));

    if (state.syntheticErrorDomains.length === 0) {
      renderStatus = false;
    }
  } else {
    domains = [...state.syntheticErrorDomains, ...state.claimableDomains].map((item) => ({
      ...item,
      domainName: item.domainName || item.id,
    }));
  }

  const onTableSectionChange = ({action, payload}) => {
    if (action === 'onRowSelectionChange') {
      setSelectedDomains(payload);
    }
  };

  return (
    <TableSection
      id="add-domains-table"
      items={domains.map((item) => {
        item.key = item.domainName;
        return item;
      })}
      onTableSectionChange={onTableSectionChange}
      pageNumber={0}
      selectionMode={SELECTION_MODE.MULTIPLE}
    >
      <AddDomainsTable domains={domains} renderStatus={renderStatus} />
    </TableSection>
  );
};

AddDomainsTableSection.displayName = 'AddDomainsTable';

export default AddDomainsTableSection;
