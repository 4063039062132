(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.core.support').constant('SUPPORT_CASE_IMPACT', {
    LARGE: 'LARGE',
    MEDIUM: 'MEDIUM',
    SMALL: 'SMALL',
  });
})();
