import {
  GoUrl,
  OverlayWait,
  Page,
  PageBanner,
  PageBannerConstants,
  PageContent,
  PageDescription,
  PageHeader,
  PageNav,
} from '@admin-tribe/binky-ui';
import {
  ASSIGNMENT_ISSUE,
  AutoProductAssignmentTableSection,
  RULE_ACTIONS,
  checkRuleDirectoryStatus,
  getItemsCheckedForMultipleProfiles,
  hasNoAvailableLicenses,
} from '@pandora/react-auto-product-assignment-table-section';
import {RULE_STATUS} from '@pandora/react-data-source-acrs';
import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import autoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import {getHrefForProductDetails} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {goToIdentityDirectories} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

import useRuleDetailsList from '../../hooks/useRuleDetailsList';

/**
 * Defines the auto-assignment-rules settings page under the Products tab.
 */
const AutoAssignmentRulesPage = () => {
  const intl = useIntl();
  const [highestSeverity, setHighestSeverity] = useState();

  useDocumentTitle({key: 'products.autoAssignmentRules.title'});

  const {directories, error, isLoading, ruleDetailsList, refetchRuleDetailsList} =
    useRuleDetailsList();

  const onViewIdentityInfo = useCallback(() => {
    goToIdentityDirectories();
  }, []);

  // Logic for showing the page banner.
  useEffect(() => {
    if (ruleDetailsList) {
      const inactiveDirectoryDomain = ruleDetailsList?.some((ruleDetail) => {
        const {error: ruleDirectoryError} = checkRuleDirectoryStatus({
          directories: ruleDetail.directoryList,
          tableItem: ruleDetail,
        });
        return (
          ruleDirectoryError === ASSIGNMENT_ISSUE.INACTIVE_DIRECTORY ||
          ruleDirectoryError === ASSIGNMENT_ISSUE.INACTIVE_DOMAIN
        );
      });
      const checkedTableItems = getItemsCheckedForMultipleProfiles(ruleDetailsList);
      const hasError =
        checkedTableItems.some((rule) => rule.status === RULE_STATUS.INVALID) ||
        inactiveDirectoryDomain;
      const hasWarning = checkedTableItems.some(
        (rule) => rule.isConflictingProductProfile || hasNoAvailableLicenses(rule)
      );

      if (hasError) {
        setHighestSeverity(PageBannerConstants.variant.ERROR);
      } else if (hasWarning) {
        setHighestSeverity(PageBannerConstants.variant.WARNING);
      } else {
        setHighestSeverity(undefined);
      }
    }
  }, [ruleDetailsList]);

  return (
    <Page data-testid="auto-assignment-rules-page" isLoading={!ruleDetailsList && isLoading}>
      {highestSeverity && (
        <PageBanner
          header={intl.formatMessage({
            id: 'products.autoAssignmentRules.banner.issuesHeader',
          })}
          variant={highestSeverity}
        >
          {intl.formatMessage({
            id: 'products.autoAssignmentRules.banner.issuesContent',
          })}
        </PageBanner>
      )}
      <PageHeader
        title={intl.formatMessage({
          id: 'products.autoAssignmentRules.title',
        })}
      />
      <PageDescription>
        <FormattedMessage
          id="products.autoAssignmentRules.description"
          values={{
            goUrl: (linkText) => <GoUrl name="admin_auto_assign_rules">{linkText}</GoUrl>,
          }}
        />
      </PageDescription>
      <PageNav />
      <PageContent>
        <OverlayWait isLoading={ruleDetailsList && isLoading} showContent>
          <AutoProductAssignmentTableSection
            directories={directories}
            getProductDetailsUrl={getHrefForProductDetails}
            isDisabled={isLoading}
            isServerError={!!error}
            items={ruleDetailsList}
            onTableSectionChange={({action}) => {
              if (Object.values(RULE_ACTIONS).includes(action)) {
                refetchRuleDetailsList();
                autoAssignRulesCache.get().refresh();
              }
            }}
            onViewIdentityInfo={onViewIdentityInfo}
            orgId={rootStore.organizationStore.activeOrgId}
            products={rootStore.organizationStore.productList.items}
          />
        </OverlayWait>
      </PageContent>
    </Page>
  );
};

export default AutoAssignmentRulesPage;
