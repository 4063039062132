(function () {
  /**
   * @deprecated - with temp_react_port_users
   *   When the 'User group' detail page 'Users' sub-tab is ported this model can be deprecated.
   *   This equates to the 'users.user-group-details.users' router state.
   *
   *   This model is used to display the Users table for a particular User Group.
   *   The src2 'User groups' drawer uses the src2 UserGroupUserList for display.
   *   The src2 'Add users to user group' modal uses a different API to add the users
   *   but uses the refresh here to update the Users table.
   *
   * @ngdoc factory
   * @name UserGroupUserList
   * @description user group user list model
   */
  angular
    .module('app.core.user-group.user-list')
    .factory('UserGroupUserList', getUserGroupUserListFactory);

  /* @ngInject */
  function getUserGroupUserListFactory(
    $rootScope,
    _,
    jilUserGroups,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationUser
  ) {
    class UserGroupUserList extends MemberList {
      /**
       * @class
       * @description Constructor for UserGroupUserList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.USERGROUPUSERLIST,
              memberClassRef: OrganizationUser,
              resource: jilUserGroups.userGroupUsersList,
            },
            options
          )
        );
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return super.key({
          groupId: this.userGroupId,
        });
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @return {Promise} resolves to refreshed UserGroupUserList model, else rejects with error message
       */
      refresh() {
        return super
          .refresh({
            groupId: this.userGroupId,
          })
          .then((userList) => {
            $rootScope.$emit(MESSAGE.UPDATE.USERGROUPUSERS.LIST, this.userGroupId);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(MESSAGE.UPDATE.USERGROUPUSERS.COUNT, this.pagination.itemCount);
            }
            return userList;
          });
      }

      /**
       * @description Method to save changes to user list to back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        return super.save({
          groupId: this.userGroupId,
        });
      }

      /**
       * @description Method to retrieve an existing UserGroupUserList from the back-end.
       *
       * @param {String} userGroupId ID of user group to fetch users for
       * @param {Number} pageSize number of user group users to display per page
       *
       * @returns {UserGroupUserList} new UserGroupUserList Object reference
       */
      static get(userGroupId, pageSize) {
        let model = new UserGroupUserList({pageSize});
        model.userGroupId = userGroupId;
        const cachedModel = modelCache.get(MODEL.USERGROUPUSERLIST, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    MemberList.setupCache(MODEL.USERGROUPUSERLIST, 10);

    return UserGroupUserList;
  }
})();
