import {useMemo} from 'react';

import {useAccessRequestListContext} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';

/**
 * This is a hook that manages the display of identity settings banners
 */
const useIdentityBanners = () => {
  const {
    state: {directoryListData},
  } = useDirectoryListContext();
  const {
    state: {domainListData},
  } = useDomainsListContext();
  const {
    state: {trusteeListData: accessRequestData},
  } = useAccessRequestListContext();

  const accessRequestBanner = useMemo(() => {
    const shouldDisplay = accessRequestData.numberOfDirectories > 0;

    return (
      shouldDisplay && {
        content: {count: accessRequestData.numberOfDirectories},
        label: {id: 'settings.identitySettings.banners.accessRequests'},
        showDefaultButton: true,
      }
    );
  }, [accessRequestData.numberOfDirectories]);

  const directoryApprovedBanner = useMemo(() => {
    const directory = directoryListData?.items[0];

    const shouldDisplay =
      directoryListData.numberOfDirectories === 1 &&
      (directory.requiresConfiguration || directory.requiresActivation);

    return (
      shouldDisplay && {
        content: {name: directory.directoryName},
        label: {id: 'settings.identitySettings.banners.directoryApproved'},
        showDefaultButton: true,
      }
    );
  }, [directoryListData]);

  const directoryPendingApprovalBanner = useMemo(() => {
    const domain = domainListData?.items[0];
    const directory = directoryListData?.items[0];

    const shouldDisplay =
      directoryListData.numberOfDirectories === 1 &&
      domainListData.numberOfDomains === 1 &&
      directory.requiresApproval &&
      domain.needsDirectory;

    return (
      shouldDisplay && {
        content: {name: directory.directoryName},
        label: {id: 'settings.identitySettings.banners.directoryPendingApproval'},
        showDefaultButton: true,
      }
    );
  }, [directoryListData, domainListData]);

  return [accessRequestBanner, directoryApprovedBanner, directoryPendingApprovalBanner];
};

export default useIdentityBanners;
