(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(accountOverviewConfig);

  /* @ngInject */
  function accountOverviewConfig(ACCOUNT_WORKSPACE_NAV_ID, navAssemblerProvider, ROLE) {
    // add the Billing History section to the Account side nav
    navAssemblerProvider.configure({
      navID: ACCOUNT_WORKSPACE_NAV_ID,
      navValue: [
        {
          access: {
            callback: ($q, injector) => injector.get('accountAccess').canViewAccountOverviewPage(),
            roles: [ROLE.ADMIN.ORG],
          },
          disabled: false,
          hidden: false,
          name: 'account.overview.title',
          order: 0,
          state: {
            definition: {
              component: 'binkyReactContainer',
              data: {
                title: 'account.overview.title',
              },
              resolve: {
                component: ['onesieSrc2', (onesieSrc2) => onesieSrc2.account.AccountOverviewPage],
              },
              url: '/overview',
            },
            name: 'account.overview',
          },
        },
      ],
    });
  }
})();
