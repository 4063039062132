import {VERSION_FILTERS} from 'features/packages/packagesConstants';
import CreatePackageService from 'features/packages/services/CreatePackageService';

const availableFilters = [
  VERSION_FILTERS.SHOW_LATEST,
  VERSION_FILTERS.SHOW_LTS,
  VERSION_FILTERS.SHOW_BETA,
  VERSION_FILTERS.SHOW_PRERELEASE,
  VERSION_FILTERS.SHOW_OLDER,
];

/**
 * @description Get id for the corresponding filter type
 * @param {String} filterType - type of filter
 *
 * @return {String} Returns id for the corresponding filter type
 */
const filterToIdMapping = (filterType) => {
  switch (filterType) {
    case VERSION_FILTERS.SHOW_LATEST:
      return VERSION_FILTERS.LATEST_VERSIONS;
    case VERSION_FILTERS.SHOW_LTS:
      return VERSION_FILTERS.LTS_VERSIONS;
    case VERSION_FILTERS.SHOW_BETA:
      return VERSION_FILTERS.BETA_VERSIONS;
    case VERSION_FILTERS.SHOW_PRERELEASE:
      return VERSION_FILTERS.PRERELEASE_VERSIONS;
    case VERSION_FILTERS.SHOW_OLDER:
      return VERSION_FILTERS.OLDER_VERSIONS;
    default:
      return '';
  }
};

/**
 * @description Checks if adobeProductsUi has beta apps
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 *
 * @return {Boolean} Return true if adobeProductsUi has beta apps
 */
const hasBetaApps = (adobeProductsUi) =>
  adobeProductsUi.some((product) => product.betaAppApui && product.visible);

/**
 * @description Checks if adobeProductsUi has pre-release apps
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 *
 * @return {Boolean} Return true if adobeProductsUi has pre-release apps
 */
const hasPreReleaseApps = (adobeProductsUi) =>
  adobeProductsUi.some((product) => product.preReleaseApui && product.visible);

/**
 * @description Checks if adobeProductsUi has long term supported apps
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 *
 * @return {Boolean} Return true if adobeProductsUi has long term supported apps
 */
const hasLTSApps = (adobeProductsUi) =>
  adobeProductsUi.some((product) => product.isMaintenanceBuild && product.visible);

/**
 * @description Checks if adobeProductsUi has unsupported apps
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 *
 * @return {Boolean} Return true if adobeProductsUi has unsupported apps
 */
const hasUnsupportedApps = (adobeProductsUi) =>
  adobeProductsUi.some(
    (product) =>
      product.isUnsupportedVersion &&
      product.visible &&
      !product.betaAppApui &&
      !product.preReleaseApui
  );

/* eslint-disable complexity -- required for checking on all filters */
/**
 * @description Checks if filter should be shown in the app filters list
 * @param {Array} adobeProductsUi - exhaustive list of packageable products for the current platform
 * @param {String} filterType - type of filter
 * @param {Boolean} isEnterpriseSupportPolicyApplicable - If enterprise support policy is applicable
 * @param {Boolean} showExtraProductsInTemplates - indicates if extra products should be shown in templates
 *
 * @return {Boolean} Return true if filter should be shown in the app filters list
 */
function showFilter(
  adobeProductsUi,
  filterType,
  isEnterpriseSupportPolicyApplicable,
  showExtraProductsInTemplates
) {
  switch (filterType) {
    case VERSION_FILTERS.SHOW_LATEST:
      return true;
    case VERSION_FILTERS.SHOW_LTS:
      return (
        hasLTSApps(adobeProductsUi) &&
        isEnterpriseSupportPolicyApplicable &&
        (showExtraProductsInTemplates || CreatePackageService.isNonCCTemplate())
      );
    case VERSION_FILTERS.SHOW_BETA:
      return (
        hasBetaApps(adobeProductsUi) &&
        (showExtraProductsInTemplates || CreatePackageService.isNonCCTemplate())
      );
    case VERSION_FILTERS.SHOW_PRERELEASE:
      return (
        hasPreReleaseApps(adobeProductsUi) &&
        (showExtraProductsInTemplates || CreatePackageService.isNonCCTemplate())
      );
    case VERSION_FILTERS.SHOW_OLDER:
      return (
        hasUnsupportedApps(adobeProductsUi) &&
        (showExtraProductsInTemplates || CreatePackageService.isNonCCTemplate())
      );
    default:
      return false;
  }
}
/* eslint-enable complexity -- required for checking on all filters */

export {availableFilters, filterToIdMapping, showFilter};
