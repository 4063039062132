import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';

/**
 * Thin wrapper over the modal for widget
 */
const WidgetProviderConditionalWrapper = ({WidgetProvider, children, ...widgetProviderProps}) => {
  if (WidgetProvider) {
    return <WidgetProvider {...widgetProviderProps}>{children}</WidgetProvider>;
  }
  return children;
};

/**
 * get buttons for modal
 *
 * @param {Function} closeModal function to close the modal
 * @param {Object} content content as provided by Sophia (plus post processing)
 * @param {Array<React.ReactNode>} widgetButtons buttons provided by widget
 *
 * @returns {Array<React.ReactNode>} buttons to be rendered
 */
const getButtons = (closeModal, content, widgetButtons = []) => {
  const buttons = [...widgetButtons];

  buttons.push(
    ...content.buttons.map(({action, analyticsId, label, variant}) => {
      let buttonAction;
      if (action === 'Close') {
        buttonAction = closeModal;
      } else {
        buttonAction = getCtaClickEventCallback(action);
      }
      return (
        <Button
          key={label}
          id={`sophia-modal-button-${analyticsId}`}
          onPress={buttonAction}
          variant={variant || 'secondary'}
        >
          {label}
        </Button>
      );
    })
  );

  return buttons.reverse();
};

WidgetProviderConditionalWrapper.propTypes = {
  /**
   * the actual Sophia modal elements
   */
  children: PropTypes.node.isRequired,
  /**
   * The component (not the JSX element) of the widget provider
   */
  WidgetProvider: PropTypes.func,
  /**
   * relay additional props to the widget provider
   */
};

export {getButtons, WidgetProviderConditionalWrapper};
