(function () {
  /**
   * @deprecated use binkySrc2.services.collaborationPolicies.CollaborationPolicies
   */
  angular
    .module('app.core.asset-sharing')
    .constant('RESTRICTION_STATUS', {
      AUTHORIZED_DOMAIN_USERS: 'AuthorizedDomainUsers',
      DOMAIN_USERS: 'DomainUsers',
      DOMAIN_USERS_WITHOUT_DOMAIN_CLAIMING: 'DomainUsersWithoutDomainClaiming',
      NONE: 'None',
      PUBLIC_LINK: 'PublicLink',
    })
    .constant('REQUEST_ACCESS_STATUS', {
      ALLOW: 'AllowAccessRequests',
      DISALLOW: 'NoAccessRequests',
    });
})();
