/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appRequestExpertSessionTeamModal
 *
 * @description The modal to request a new expert session for a team.
 *
 */
(function () {
  angular.module('app.widgets.support').component('appRequestExpertSessionTeamModal', {
    controller,
    templateUrl:
      'app/widgets/support/request-expert-session-team-modal/request-expert-session-team-modal.component.html',
  });

  function controller(
    $filter,
    $rootScope,
    $translate,
    $window,
    _,
    MESSAGE,
    OrganizationManager,
    REQUEST_EXPERT_SESSION_TEAM_MODAL_ID,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      ctaHandler,
      isCtaDisabled,
      onChangeTimeframe,
      onChangeTopic,
      onOpen,
      REQUEST_EXPERT_SESSION_TEAM_MODAL_ID,
    });

    const EXPERT_SESSION_TEAM_TOPICS = [
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.topics.names.general'
        ),
        url: 'ac_team_admin_expert_gen',
      },
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.topics.names.webApp'
        ),
        url: 'ac_team_admin_expert_web',
      },
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.topics.names.video'
        ),
        url: 'ac_team_admin_expert_video',
      },
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.topics.names.print'
        ),
        url: 'ac_team_admin_expert_print',
      },
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.topics.names.photography'
        ),
        url: 'ac_team_admin_expert_photo',
      },
    ];
    const EXPERT_SESSION_TEAM_TIMEFRAME = [
      {
        name: $translate.instant('widgets.support.requestExpertSessionTeamModal.timeframe.morning'),
        value: '1',
      },
      {
        name: $translate.instant(
          'widgets.support.requestExpertSessionTeamModal.timeframe.afternoon'
        ),
        value: '2',
      },
    ];

    function $onInit() {
      vm.sessionTimeframes = EXPERT_SESSION_TEAM_TIMEFRAME;
      vm.sessionTopics = EXPERT_SESSION_TEAM_TOPICS;
      vm.timeframePlaceholder = $translate.instant(
        'widgets.support.requestExpertSessionTeamModal.timeframe.placeholder'
      );
      vm.topicsPlaceholder = $translate.instant(
        'widgets.support.requestExpertSessionTeamModal.topics.placeholder'
      );

      vm.hasPaidSubscription =
        !OrganizationManager.getContractsForActiveOrg().hasOnlyTrialContracts();
    }

    function ctaHandler() {
      $window.open($filter('goUrl')(vm.sessionTopic + vm.sessionTimeframe), '_blank');
      $rootScope.$emit(MESSAGE.OPEN.TEAM_EXPERT_SESSION_BANNER);
      panelManager.close(REQUEST_EXPERT_SESSION_TEAM_MODAL_ID);
    }

    function isCtaDisabled() {
      return !(vm.sessionTopic && vm.sessionTimeframe);
    }

    function onChangeTimeframe(timeframe) {
      vm.sessionTimeframe = timeframe;
    }

    function onChangeTopic(topicGoUrl) {
      vm.sessionTopic = topicGoUrl;
    }

    function onOpen() {
      vm.sessionTimeframe = undefined;
      vm.sessionTopic = undefined;
    }
  }
})();
