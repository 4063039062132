(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets:appOpenSupportCases
   * @description Shows up to 3 open support cases for the logged-in admin.
   *
   * @binding {Object} supportCaseList. The list of open support cases for this admin.
   */
  angular.module('app.widgets.support').component('appSupportCaseSummary', {
    bindings: {
      supportCaseList: '<',
    },
    controller,
    templateUrl: 'app/widgets/support/support-case-summary/support-case-summary.component.html',
  });

  /* @ngInject */
  function controller($rootScope, _, MESSAGE, SUPPORT_CASE_SORT_ORDER) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      messageKey: undefined,
      SUPPORT_CASE_SORT_ORDER,
      supportCaseListDisplay: {
        caseTitle: true,
        status: true,
      },
    });

    function $onInit() {
      vm.supportCaseList.$promise
        .then(() => {
          $rootScope.$emit(
            MESSAGE.UPDATE.SUPPORTCASESUMMARY,
            vm.supportCaseList.items.length > 0 || vm.supportCaseList.unfilteredTicketCount > 0
          );
        })
        .catch(() => {
          vm.messageKey = 'widgets.support.supportCaseSummary.fetchError';
        });
    }
  }
})();
