(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.api-integration')
    .factory('ApiIntegrationList', getApiIntegrationListModel);

  /* @ngInject */
  function getApiIntegrationListModel(
    _,
    adobeioConsoleIntegrations,
    API_INTEGRATION_LIST_MODEL,
    ApiIntegration,
    ApiOrganizationList,
    apiUtils,
    modelCache
  ) {
    class ApiIntegrationList {
      constructor() {
        this.items = [];
      }

      getIntegrationForTechnicalAccount(technicalAccountId) {
        return _.find(this.items, {technicalAccountId});
      }

      refresh() {
        this.$resolved = false;
        this.$promise = ApiOrganizationList.get()
          .$promise.then((apiOrganizationList) => {
            this.adobeioOrgId = apiOrganizationList.getActiveAdobeioOrgId();
            return adobeioConsoleIntegrations.integrations.query({
              orgId: this.adobeioOrgId,
            }).$promise;
          })
          .then((apiIntegrationList) => {
            this.items = _.map(apiIntegrationList, (apiIntegration) =>
              ApiIntegration.apiResponseTransformer(apiIntegration)
            );
            modelCache.put(
              API_INTEGRATION_LIST_MODEL.API_INTEGRATION_LIST_CACHE_ID,
              this,
              apiUtils.getActiveOrg()
            );
            return this;
          })
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;
      }

      static get() {
        const model = new ApiIntegrationList();
        const cachedModel = modelCache.get(
          API_INTEGRATION_LIST_MODEL.API_INTEGRATION_LIST_CACHE_ID,
          apiUtils.getActiveOrg()
        );
        if (cachedModel) {
          return cachedModel;
        }
        model.refresh();
        return model;
      }
    }

    modelCache.register(API_INTEGRATION_LIST_MODEL.API_INTEGRATION_LIST_CACHE_ID, 1);
    return ApiIntegrationList;
  }
})();
