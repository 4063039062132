/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains2:appRemoveDomainErrorModal
 *
 * @description The modal to show errors from the Remove Domain operation.
 */
(function () {
  angular.module('app.widgets.domains2').component('appRemoveDomainErrorModal', {
    controller,
    templateUrl:
      'app/widgets/domains2/remove-domain-error-modal/remove-domain-error-modal.component.html',
  });

  /* @ngInject */
  function controller(_, REMOVE_DOMAIN_ERROR_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      onModalOpen,
      REMOVE_DOMAIN_ERROR_MODAL_ID,
    });

    function onModalOpen(params) {
      vm.requestCount = params.requestCount; // number of domains attempted to be removed
      vm.errorItems = params.errorItems; // domains that could not be removed
    }
  }
})();
