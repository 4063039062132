import {log} from '@admin-tribe/binky';
import {EditSecurityContactsModal, ModalContainer} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import rootStore from 'core/RootStore';

/**
 * A wrapper to handle the trigger to open the EditSecurityContactsModal from Angular.
 * Either remove this file or move the decorator logic into this file when porting
 * the security-contacts.decorator to src2.
 *
 * When the rest of this work is ported, remove the isOpen prop and either use
 * binky-ui's src2 EditSecurityContactsButton or Spectrum's DialogTrigger to open the modal.
 *
 */
const SecurityContactsHvaCard = ({isOpen = false}) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);

  const onSuccess = async () => {
    try {
      await rootStore.organizationStore.updateContactListSingleton();
    } catch (error) {
      log.error('Error updating the contact list: ', error);
    }
  };

  return (
    <ModalContainer>
      {isModalOpen && (
        <EditSecurityContactsModal
          contactList={rootStore.organizationStore.contactList}
          onClose={() => {
            setModalOpen(false);
          }}
          onSuccess={onSuccess}
        />
      )}
    </ModalContainer>
  );
};

SecurityContactsHvaCard.propTypes = {
  /**
   * A boolean which indicates whether the modal should be opened. The default is false.
   */
  isOpen: PropTypes.bool,
};
export default SecurityContactsHvaCard;
