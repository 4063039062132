(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(accountCancelLicensesRouteConfig);

  /* @ngInject */
  function accountCancelLicensesRouteConfig($stateProvider, onesieSrc2) {
    const parentRoute = 'account.account';
    const {canUseSelfCancelDeepLink} = onesieSrc2.core.account.access;

    $stateProvider.state({
      data: {
        // Prevent analytics page hit from being fired for deep linking
        skipAnalytics: true,
      },
      name: 'account.account.cancel-licenses',
      // Callback function for lifecycle hook, which is invoked (during a transition) when a specific state is being entered.
      // Used to prevent access to the flow if user doesn't have permissions, by redirecting to parentRoute.
      onEnter: [
        '$transition$',
        function ($transition$) {
          function redirectToParentRoute() {
            return $transition$.router.stateService.target(parentRoute, $transition$.params());
          }

          // Redirect to parent page if user doesn't have access to Self Cancel deep link
          if (canUseSelfCancelDeepLink()) {
            return true;
          }
          return redirectToParentRoute();
        },
      ],
      params: {},
      url: '/cancel-licenses',
    });
  }
})();
