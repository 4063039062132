(function () {
  /**
   * @deprecated
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.products')
    .filter('productGroupsProducts', productGroupsProductsFilter);

  /* @ngInject */
  function productGroupsProductsFilter(_, productGroupProductUtils) {
    return getProductGroupsProducts;

    /**
     * @description filters an array of products into an array of product groups and products
     * @param {Array} products an array of products
     * @returns {Array} an array of product groups and products
     */
    function getProductGroupsProducts(products) {
      const result = productGroupProductUtils.getProductGroupProductLists(products);
      const productsAndProductGroups = _.concat(result.products, result.productGroupProductLists);
      return productsAndProductGroups;
    }
  }
})();
