import {DetailSection} from '@admin-tribe/binky-ui';
import {Grid, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

// Styling for labels.
const labelStyle = {
  color: 'var(--color-grey-700)',
  fontSize: 'var(--spectrum-global-dimension-static-font-size-75)',
};

const ProductInstanceDetailSection = ({metaData, showError}) => {
  const intl = useIntl();
  return (
    <DetailSection
      headingLevel={3}
      showError={showError}
      title={intl.formatMessage({id: 'products.productDetailDrawer.instanceDetails.title'})}
    >
      <Grid
        alignItems="center"
        columnGap="size-450"
        columns={['auto', 'minmax(0, 1fr)']}
        marginTop="size-100"
        rowGap="size-100"
        rows={['auto']}
      >
        {metaData.map((el) => (
          <React.Fragment key={el.key}>
            <Text UNSAFE_style={labelStyle}>{el.label}</Text>
            <Text>{el.value}</Text>
          </React.Fragment>
        ))}
      </Grid>
    </DetailSection>
  );
};

ProductInstanceDetailSection.propTypes = {
  /**
   * Meta data for instance details.
   */
  metaData: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * MetaData key.
       */
      key: PropTypes.string.isRequired,
      /**
       * MetaData label.
       */
      label: PropTypes.string.isRequired,
      /**
       * MetaData value.
       */
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * True if there is an error while retrieving
   * meta data
   */
  showError: PropTypes.bool,
};

export default ProductInstanceDetailSection;
