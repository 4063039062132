(function () {
  /**
   *
   * @ngdoc factory
   * @name app.core.sophia:ContextualParams
   * @deprecated This file has been ported to src2 under `temp_react_port_contextual_params`
   * @description Model for managing contextual params required by Sophia (Test & Target)
   * See [API Spec]{@link https://wiki.corp.adobe.com/display/SOPHIA/Execution+Layer+API+Spec}
   */
  angular.module('app.core.sophia').factory('ContextualParams', getContextualParams);

  /* @ngInject */
  function getContextualParams(onesieSrc2, promiseUtils) {
    class ContextualParams extends onesieSrc2.common.services.sophia.SophiaContextualParams {
      refresh() {
        this.$promise = promiseUtils.toAngularPromise(super.refresh());
        return this.$promise;
      }

      static get(options = {}) {
        const model = new ContextualParams(options);
        model.refresh();
        return model;
      }
    }

    return ContextualParams;
  }
})();
