import {Button, DialogTrigger} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import CreateContentLogModal from './CreateContentLogModal';

/**
 * @description the button for opening the modal to create a new content log.
 */
const CreateContentLogButton = () => {
  const intl = useIntl();

  return (
    <DialogTrigger>
      <Button data-testid="create-content-log-button" variant="accent">
        {intl.formatMessage({id: 'settings.contentLogs.createReport'})}
      </Button>

      {(close) => <CreateContentLogModal onClose={close} />}
    </DialogTrigger>
  );
};

export default CreateContentLogButton;
