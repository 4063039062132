import {log} from '@admin-tribe/binky';
import {Divider, Flex, Text, View} from '@adobe/react-spectrum';
import {useAuth} from '@pandora/react-auth-provider';
import {GoUrl} from '@pandora/react-go-url';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CreateServerModalContextProvider} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import {MILLISECONDS_IN_A_DAY} from 'features/packages/packagesConstants';
import {useServerPageContext} from 'features/packages/pages/servers-page/ServerPageContext';
import CreateServerService from 'features/packages/services/CreateServerService';

import DragAndDropAuthFile from '../authorize-server/drag-and-drop-auth-file/DragAndDropAuthFile';

const ReauthorizeServer = observer(({serverObject, onCancel}) => {
  const auth = useAuth();
  const {packagesServerStore} = useServerPageContext();
  const intl = useIntl();

  const buildServerPackage = async () => {
    const serverCreateData = {
      activationEnforcementType: packagesServerStore.serverSessionData.activationEnforcementType,
      authReportFileIds: packagesServerStore.serverSessionData.authorizationFileIdList,
      branding: {
        name: packagesServerStore.serverSessionData.branding.name,
      },
      displayName: packagesServerStore.serverSessionSettings.name,
      lanTimeout: packagesServerStore.serverSessionData.lanTimeout * MILLISECONDS_IN_A_DAY,
      licenses: packagesServerStore.serverSessionData.selectedLicenses.map((offer) => {
        const fiMap = {};
        offer.getPackageableFulfillableItems().forEach((fiJson) => {
          fiMap[fiJson.code] = JSON.stringify(fiJson);
        });

        return {
          apps: fiMap,
          licenseFullName: offer.longName,
          licenseId: offer.id,
          licenseShortName: offer.shortName,
        };
      }),
      orgId: rootStore.organizationStore.activeOrgId,
      rootUrl: packagesServerStore.serverSessionData.dnsServerUrl,
      userId: auth.getUserProfile().userId,
    };

    try {
      await CreateServerService.postServerReauthorizeRequest(
        serverCreateData,
        serverObject.serverId
      );
    } catch (error) {
      log.error('Reauthorize server request failed. Error: ', error);
    }
    packagesServerStore.resetServerSessionDataValues();
    onCancel();
  };

  const handleOnCancel = () => {
    packagesServerStore.resetServerSessionDataValues();
    onCancel();
  };

  const content = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'packages.createServerModal.reauthorizeServer.cancelButtonLabel',
    }),
    ctaLabel: intl.formatMessage({
      id: 'packages.createServerModal.reauthorizeServer.doneButtonLabel',
    }),
  });

  // set serverSessionData
  useEffect(() => {
    packagesServerStore.resetServerSessionDataValues();
    packagesServerStore.setServerSessionSettingValues('name', serverObject.name);
    packagesServerStore.setServerSessionSettingValues('nextButtonEnabled', false);
    const serverSessionDataLocal = [
      {key: 'activationEnforcementType', value: serverObject.activationEnforcementType},
      {key: 'authorizationFileIdList', value: []},
      {key: 'branding', value: {name: serverObject.branding}},
      {key: 'dnsServerUrl', value: serverObject.dns},
      {key: 'lanTimeout', value: false},
      {key: 'selectedLicenses', value: serverObject.licenses},
      {key: 'serverId', value: serverObject.serverId},
    ];
    const serverSessionSettingsLocal = [
      {key: 'editServerWorkflow', value: serverObject.lanTimeout / MILLISECONDS_IN_A_DAY},
      {key: 'reauthorizeServerWorkflow', value: true},
    ];
    serverSessionDataLocal.forEach((data) =>
      packagesServerStore.setServerSessionDataValues(data.key, data.value)
    );
    serverSessionSettingsLocal.forEach((data) =>
      packagesServerStore.setServerSessionSettingValues(data.key, data.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- call on component mount
  }, []);

  return (
    <CreateServerModalContextProvider packagesServerStore={packagesServerStore}>
      <ModalDialog
        content={content}
        dialogStyle={{spaceX: 'size-0', width: '1100px'}}
        heightVariant="static"
        isCtaDisabled={!packagesServerStore.serverSessionSettings.nextButtonEnabled}
        onCancel={handleOnCancel}
        onCta={buildServerPackage}
      >
        <ModalHeading>
          {intl.formatMessage({id: 'packages.createServerModal.reauthorizeServer.title'})}
        </ModalHeading>
        <Divider size="M" />
        <ModalContent>
          <Flex direction="column">
            <Text UNSAFE_style={{fontWeight: 'bold'}}>
              {intl.formatMessage({
                id: 'packages.createServerModal.reauthorizeServer.header',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'packages.createServerModal.reauthorizeServer.description',
              })}
            </Text>
            <GoUrl name="ent_learn_FRL_LAN">
              {intl.formatMessage({
                id: 'packages.createServerModal.reauthorizeServer.learnMoreLink',
              })}
            </GoUrl>
          </Flex>
          <View marginTop="size-400">
            <DragAndDropAuthFile />
          </View>
        </ModalContent>
      </ModalDialog>
    </CreateServerModalContextProvider>
  );
});

ReauthorizeServer.propTypes = {
  /**
   * Boolean to decide whether we want to show Re-auth button or Link, defaults to false
   */
  isButtonVariant: PropTypes.bool,
  /**
   * Server info object
   */
  serverObject: PropTypes.shape({
    /**
     * Activation Enforcement Type of server
     */
    activationEnforcementType: PropTypes.string,
    /**
     * Org in which server is made
     */
    branding: PropTypes.string,
    /**
     * DNS address of server
     */
    dns: PropTypes.string,
    /**
     * LAN timeout of server
     */
    lanTimeout: PropTypes.number,
    /**
     * Licenses that are attached with server
     */
    licenses: PropTypes.instanceOf(Array),
    /**
     * Name of the server
     */
    name: PropTypes.string,
    /**
     * ID of the server
     */
    serverId: PropTypes.string,
  }),
};

export default ReauthorizeServer;
