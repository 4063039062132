import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Flex, Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduProviderLogo from 'features/settings/common/components/edu-provider-logo/EduProviderLogo';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const RemoveRosterSyncConfirmationModal = ({rosterSource, syncId, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);
  const {removeRosterSync} = useEduRosterSync();
  const {setEduRosterSyncs} = useRosterSyncContext();

  const primaryActionLabel = intl.formatMessage({
    id: 'settings.sync.eduSync.removeSyncModal.buttons.remove',
  });

  const title = intl.formatMessage({
    id: 'settings.sync.eduSync.removeSyncModal.confirmationTitle',
  });

  const handleRemoveSync = async () => {
    setIsLoading(true);

    try {
      await removeRosterSync({syncId});
      setEduRosterSyncs({data: []});
      showSuccessToast(
        intl.formatMessage({id: 'settings.sync.eduSync.removeSyncModal.toast.success'})
      );

      dialog.dismiss();
      onConfirm?.();
    } catch {
      setIsLoading(false);
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.removeSyncModal.toast.error'}));
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.removeSyncModal.cancelButton',
      })}
      data-test-id="remove-edu-roster-sync-modal"
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRemoveSync}
      primaryActionLabel={primaryActionLabel}
      title={title}
      variant="destructive"
    >
      <View>
        <EduProviderLogo rosterSource={rosterSource} />
      </View>
      <Flex direction="column">
        <Text>
          <FormattedMessage id="settings.sync.eduSync.removeSyncModal.text" />
        </Text>
        <Text marginTop="size-200">
          <strong>
            <FormattedMessage id="settings.sync.eduSync.removeSyncModal.text.confirmation" />
          </strong>
        </Text>
      </Flex>
    </ModalAlertDialog>
  );
};

RemoveRosterSyncConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  rosterSource: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
};

export default RemoveRosterSyncConfirmationModal;
