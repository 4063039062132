import {
  PRODUCT_SUPPORT_ADMIN,
  feature,
  initDeveloperProducts,
  initProducts,
  initRoles,
  initUserGroups,
} from '@admin-tribe/binky';

import {sortProducts} from 'common/components/products-detail-section/productsDetailSectionUtils';
import rootStore from 'core/RootStore';

/**
 * @description Method to transform an OrganizationUser from a productList data.
 *
 * @param {Object} options - The params
 * @param {Object} intl - react-intl to translate text
 * @param {OrganizationUser} member - The member to transform
 * @returns {OrganizationUser} Reference to OrganizationUser
 */
const transformMember = ({intl, member}) => {
  const activeOrgProducts = rootStore.organizationStore.productList.items;
  const activeOrgContracts = rootStore.organizationStore.contractList.items;

  const transformedMember = {...member};

  if (feature.isDisabled('temp_parkour_mm')) {
    // Remove role PRODUCT_SUPPORT_ADMIN from roles
    transformedMember.roles = member?.roles?.filter((role) => role.type !== PRODUCT_SUPPORT_ADMIN);
  }

  // update developerProducts using the roles field of response data with the type LICENSE_DEV_ADMIN
  transformedMember.developerProducts = sortProducts(
    intl,
    initDeveloperProducts(transformedMember.roles, activeOrgProducts)
  );

  // update products
  transformedMember.products = sortProducts(
    intl,
    initProducts(transformedMember.products, activeOrgProducts)
  );

  // update roles
  transformedMember.roles = initRoles(
    transformedMember.roles,
    activeOrgProducts,
    activeOrgContracts
  );

  // update user groups
  transformedMember.userGroups = initUserGroups(transformedMember.userGroups);

  return Object.assign(member, transformedMember);
};

// eslint-disable-next-line import/prefer-default-export -- export only one function
export {transformMember};
