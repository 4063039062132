import {csvBlobTransformer, getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

let clientId, includeRoles, url;

/**
 * @description Configure JIL jobs APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Retrieves a single job based on job id.
 *
 *
 * @param {String} jobId - the job id of the desired job to get
 * @param {String} orgId - the organization id or where the desired job was performed on
 *
 * @returns {Promise} Promise that resolves with an axios response object containing the job
 */
const getJob = ({jobId, orgId}) =>
  axios.get(`${url}/${orgId}/jobs/${jobId}`, {headers: getHeaders({clientId, includeRoles})});

/**
 * @description Retrieves the job results of the specified job
 *
 * @param {String} acceptHeader - the http Accept header to use while making the get call
 * @param {String} jobId - the job id of the desired job to get
 * @param {String} orgId - the organization id or where the desired job was performed on
 *
 * @returns {Promise} Promise that resolves with an axios response object containing the result of the specified job
 */
const getJobResults = async ({acceptHeader = 'application/json', jobId, orgId}) => {
  const response = await axios.get(`${url}/${orgId}/jobs/${jobId}/result`, {
    headers: {...getHeaders({clientId, includeRoles}), Accept: acceptHeader},
  });
  return acceptHeader.includes('csv') ? csvBlobTransformer(response) : response;
};

/**
 * @description Retrieves all jobs from the specified organization
 *
 * @param {String} [directoryId] - The directory id for directory users bulk ops results
 * @param {String} [licenseGroupId] - The license group id for license group bulk ops results
 * @param {String} orgId - the organization id or where the desired job was performed on
 * @param {String} [productId] - The product id for product bulk ops results
 * @param {String[]} typeList - list of job types to retrieve
 * @param {String} [userGroupId] - The user group id for user group bulk ops results
 *
 * @returns {Promise} Promise that resolves with an axios response object containing the list of all jobs done on the org
 */
const getOrganizationJobs = ({orgId, typeList = [], ...params}) => {
  const types = typeList.join();
  return axios.get(`${url}/${orgId}/jobs`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {types, ...params},
  });
};

/**
 * @description Updates jobs through a list of operations
 *
 * @param {Object[]} ops - operation objects describing a list of operations to be performed
 * @param {String} orgId - the organization id or where the desired job was performed on
 *
 * @returns {Promise} Promise that resolves with an axios response object containing the results of the operation
 */
const patchJobs = ({ops = [], orgId}) =>
  axios.patch(`${url}/${orgId}/jobs`, ops, {headers: getHeaders({clientId, includeRoles})});

const jilJobs = {
  configure,
  getJob,
  getJobResults,
  getOrganizationJobs,
  patchJobs,
};

export default jilJobs;
