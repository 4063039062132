/**
 * @deprecated
 *
 * @ngdoc component
 * @name app.widgets.products:appAdditionalProductsList
 *
 * @description The Additional Products section of the Account page.
 *    This is where organization-level consumables are shown and any other products that are not tied to the
 *    organization's anniversary date.
 *
 * @binding {Array} products. Array of products that have organization-level fulfillable items.
 * @binding {Object} summarizationListByOrgId. The consumables for this organization. This may not yet be resolved.
 */
(function () {
  angular.module('app.widgets.products').component('appAdditionalProductsList', {
    bindings: {
      products: '<',
      summarizationListByOrgId: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/products-list/additional-products-list/additional-products-list.component.html',
  });

  /* @ngInject */
  function controller(
    $filter,
    $q,
    $translate,
    _,
    binkyDateTimeFilter,
    binkyProductNameLabelFilter,
    binkySrc2,
    feature,
    onesieSrc2,
    OrganizationManager,
    CONSUMABLES_LIST_TABLE_SORT,
    ScorecardGroupItem
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getProductGroupDisplayLabel,
      MAX_USAGES: 10, // must be a valid page size
      showError: false,
      waitPromise: $q.defer().promise,
    });

    function $onInit() {
      // Get all the organization-level consumable "products".
      vm.productGroupProductLists =
        OrganizationManager.getOrgConsumablesForActiveOrg().fiProductGroupProductLists;

      // Get the sorted usage list for each of the product groups.
      vm.summarizationListByOrgId.$promise
        .then(() => $q.all(getUsageLists()))
        .catch(() => {
          vm.showError = true;
        })
        .finally(() => {
          vm.waitPromise = $q.resolve();

          if (feature.isEnabled('temp_observability')) {
            binkySrc2.services.observability.observabilityMetrics.completeSpan(
              onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST
                .ADDITIONAL_PRODUCTS_LIST
            );
          }
        });
    }

    // Here rather than in html so that it can be unit tested more easily.
    function getProductGroupDisplayLabel(productGroup, usageTotal) {
      return $translate.instant('widgets.products.additionalProductsSection.nameWithUsageCount', {
        count: $filter('number')(usageTotal),
        name: binkyProductNameLabelFilter(productGroup),
      });
    }

    /////////////////

    // Returns an array of all the usageList promises. Each promise will resolve when the refresh for its list completes.
    function getUsageLists() {
      const usageListPromises = [];
      vm.usagesByProductGroupId = {};

      _.forEach(vm.productGroupProductLists, (productGroup) => {
        vm.usagesByProductGroupId[productGroup.id] = [];

        const consumables = _.head(
          vm.summarizationListByOrgId.getConsumablesForFulfillableItemCode(productGroup.id)
        );

        if (consumables) {
          // For the summary progress bar.
          _.assign(vm.usagesByProductGroupId[productGroup.id], {
            summaryScorecardGroupItems: [ScorecardGroupItem.constructForConsumable(consumables)],
            usageTotal: consumables.usageTotal, // number of credit packs
          });

          // We must refresh the list so we can sort over the entire set of usages and then take the oldest MAX_USAGES.
          const usageList = consumables.consumableUsageList;
          usageList.sortBy(
            CONSUMABLES_LIST_TABLE_SORT.SORT_EXPIRATION_DATE,
            CONSUMABLES_LIST_TABLE_SORT.SORT_ORDER_DESC
          );
          usageList.setPageSize(vm.MAX_USAGES);
          usageList.refresh();

          // Must show the wait on this list until all the refresh promises resolve.
          usageListPromises.push(usageList.$promise);

          // caller will catch any errors
          // eslint-disable-next-line promise/catch-or-return
          usageList.$promise.then(() => {
            // Each usage is shown in an accordion-item-content element. Store the items needed for display.
            vm.usagesByProductGroupId[productGroup.id].usages = _.map(usageList.items, (usage) => ({
              displayName: productGroup.getDisplayNameForOfferId(usage.offerId),
              expiresOn: getExpiresOnLabel(usage.expirationDate),
              scorecardGroupItems: [ScorecardGroupItem.constructForConsumable(usage)],
            }));
          });
        } else {
          throw new Error(`no consumable for ${productGroup.id}`);
        }
      });

      return usageListPromises;

      ////////////

      function getExpiresOnLabel(expirationDate) {
        return $translate.instant('widgets.products.additionalProductsSection.expiresOn', {
          date: binkyDateTimeFilter(expirationDate, {dateOnly: true}),
        });
      }
    }
  }
})();
