(function () {
  /**
   * @deprecated with temp_react_port_jobs. Use src2/app/common/services/job-result/JobResult.js
   *
   * @ngdoc factory
   * @name JobResult
   * @description Model for the result of a single item in a job, for example, a
   *   particular CSV row.
   */
  angular.module('app.core.jobs').factory('JobResultItem', jobResultItem);

  /* @ngInject */
  function jobResultItem(
    $translate,
    _,
    JOB_ITEM_FAILURE_TYPE,
    JOB_RESULT_ITEM_STATUS,
    jobListHelper
  ) {
    const MESSAGE_TRANSLATE_KEY_PREFIX = 'widgets.bulkOperations.results.error.';

    class JobResultItem {
      /**
       * @description Method to return messages for result item
       * @returns {Array} list of messages for result item
       */
      getMessages() {
        let messages;
        switch (this.status) {
          case JOB_RESULT_ITEM_STATUS.SUCCESS:
            messages = [createMessage('success')];
            break;
          case JOB_RESULT_ITEM_STATUS.FAILURE:
            messages = createFailureMessages(this);
            break;
          case JOB_RESULT_ITEM_STATUS.NOT_PROCESSED:
            messages = [createMessage('notProcessed')];
            break;
          default:
            messages = [];
            break;
        }
        return messages;
      }

      /**
       * @description Creates and returns a JobResultItem instance from the
       *   specified JIL job result item JSON
       * @param {Object} rawJobResultItemJson - JIL job result item JSON
       * @returns {JobResultItem} the created JobResultItem
       */
      static apiResponseTransformer(rawJobResultItemJson) {
        const transformedJobResultItem = new JobResultItem();
        return _.assignIn(transformedJobResultItem, rawJobResultItemJson);
      }
    }

    return JobResultItem;

    ////////

    /**
     * @description Method to construct a failure detail message for a job result
     *   item.
     * @param {Object} failureDetail - failure details to construct message from
     * @returns {Object} constructed failure detail message
     */
    function createFailureDetailMessage(failureDetail) {
      const translateKeySuffix = `message.${jobListHelper.getFailureMessageTranslateKeySuffix(
        failureDetail.type
      )}`;
      const translateValues = createFailureDetailTranslateValues(failureDetail);
      const message = createMessage(translateKeySuffix, translateValues);
      message.translateDefault = $translate.instant(
        `${MESSAGE_TRANSLATE_KEY_PREFIX}genericFailureWithCode`,
        {
          failureCode: failureDetail.type,
        }
      );
      return message;
    }

    /**
     * @description Method to create failure detail translation values for
     *   message. If failure type is related to exceeding the license count
     *   for a Product, this method appends the product name to the translated
     *   value returned.
     * @param {Object} failureDetail - failure details to construct translation
     *   from
     * @returns {Object} constructed failure detail translate value
     */
    function createFailureDetailTranslateValues(failureDetail) {
      let translateValues;
      switch (failureDetail.type) {
        case JOB_ITEM_FAILURE_TYPE.ALLOWABLE_LICENSE_COUNT_EXCEEDED:
          translateValues = {
            productName: _.get(failureDetail, 'context.product.shortName'),
          };
          break;
        default:
          break;
      }
      return translateValues;
    }

    /**
     * @description Method to create failure messages for a job result item.
     * @param {JobResultItem} model - job result item instance to create failure
     *   messages from/for
     * @returns {Array} failure messages
     */
    function createFailureMessages(model) {
      const hasFailureDetails = _.isArray(model.failureDetails) && model.failureDetails.length > 0;
      return hasFailureDetails
        ? _.map(model.failureDetails, createFailureDetailMessage)
        : [createMessage('genericFailure')];
    }

    /**
     * @description Method to create a message for a job result item
     * @param {String} translateKeySuffix - translation key suffix for message
     * @param {String} translateValues - values to translate/store on message
     * @returns {Object} constructed message
     */
    function createMessage(translateKeySuffix, translateValues) {
      const message = {
        translateKey: `${MESSAGE_TRANSLATE_KEY_PREFIX}${translateKeySuffix}`,
      };
      if (translateValues) {
        message.translateValues = translateValues;
      }
      return message;
    }
  }
})();
