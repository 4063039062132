import {LicenseGroup, OrganizationUser, feature, getDeveloperProducts} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';

import {getContractDisplayNames} from 'core/products/utils/productUtils';

import {ROLE} from '../../users.constants';
import AssignmentModalBase from '../assignment-modal-base/AssignmentModalBase';

const Avatar = binkyUI.common.components.Avatar;
const GoUrl = binkyUI.common.components.GoUrl;
const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;

const EditDeveloperAccessModal = ({
  id = 'edit-developer-access-modal',
  isOpen,
  onCancel,
  onSuccess,
  orgId,
  user,
}) => {
  const intl = useIntl();
  const products = useRef();

  const assignItemsToUser = (assignedItems) => {
    let licenseDevRole = user.roles.find((role) => role.type === ROLE.ADMIN.LICENSE_DEV);
    if (!licenseDevRole) {
      licenseDevRole = {targets: [], type: ROLE.ADMIN.LICENSE_DEV};
      user.roles.push(licenseDevRole);
    }

    licenseDevRole.targets = assignedItems[TARGET_TYPE.PRODUCT_PROFILES];
  };

  const hasUnsavedChanges = (assignedItems) => {
    const targetsChanges = assignedItems[TARGET_TYPE.PRODUCT_PROFILES] || [];
    const licenseDevRoleTargets =
      user.roles.find((role) => role.type === ROLE.ADMIN.LICENSE_DEV)?.targets || [];

    return !isEqual(
      licenseDevRoleTargets.map((t) => t.id),
      targetsChanges?.map((t) => t.id)
    );
  };

  const onItemAssignment = (selectedItems) => {
    const changes = {};
    changes[TARGET_TYPE.PRODUCT_PROFILES] = selectedItems.map((selectedItem) => ({
      id: selectedItem.id,
      name: selectedItem.name,
      parentId: selectedItem.product.id,
    }));

    return changes;
  };

  const transformLicenseGroup = (targetLicenseGroup) => {
    const productToBeMappedWithLicenseGroup = products.current.find(
      (product) => product.id === targetLicenseGroup.parentId
    );
    if (feature.isEnabled('temp_add_contract_display_name')) {
      const productWithContractDisplayNames = {
        ...productToBeMappedWithLicenseGroup,
        contractDisplayNames: getContractDisplayNames(
          productToBeMappedWithLicenseGroup.contractIds
        ),
      };
      return new LicenseGroup({
        ...targetLicenseGroup,
        product: productWithContractDisplayNames,
      });
    }
    return new LicenseGroup({
      ...targetLicenseGroup,
      product: productToBeMappedWithLicenseGroup,
    });
  };

  const processDefaultItemsNew = () => {
    const productProfiles = [];
    const mappedTargets = {
      [TARGET_TYPE.PRODUCT_PROFILES]: productProfiles,
    };

    if (products.current) {
      const licenseDevRole = user.roles.find((role) => role.type === ROLE.ADMIN.LICENSE_DEV);

      if (licenseDevRole) {
        productProfiles.push(
          ...licenseDevRole.targets.map((targetLicenseGroup) =>
            transformLicenseGroup(targetLicenseGroup)
          )
        );
      }
    } else {
      return undefined;
    }

    return mappedTargets;
  };

  const onProductListResolve = (productList) => {
    productList.items = getDeveloperProducts(productList);
    products.current = productList.items;

    return products.current;
  };

  return (
    <AssignmentModalBase
      assignedItemLocId="users.editDeveloperAccessModal.assigned"
      assignItemsToUser={assignItemsToUser}
      description={intl.formatMessage(
        {id: 'users.editDeveloperAccessModal.description'},
        {goUrl: (str) => <GoUrl name="aac_api_prod_learn">{str}</GoUrl>}
      )}
      hasUnsavedChanges={hasUnsavedChanges}
      header={intl.formatMessage({id: 'users.editDeveloperAccessModal.header'})}
      id={id}
      isOpen={isOpen}
      onCancel={onCancel}
      onItemAssignment={onItemAssignment}
      onProductListResolve={onProductListResolve}
      onSuccess={onSuccess}
      orgId={orgId}
      processDefaultItemsNew={processDefaultItemsNew}
      TagHeaderIconComponent={<Avatar alt="" member={user} size="S" />}
      tagHeaderText={user.getDisplayName()}
      targets={[TARGET_TYPE.PRODUCT_PROFILES, TARGET_TYPE.PRODUCTS]}
      user={user}
    />
  );
};

EditDeveloperAccessModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  // will be an object if passed in as a prop of type OrgUser (from src1)
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(OrganizationUser)]),
};

export default EditDeveloperAccessModal;
