import uniqWith from 'lodash/uniqWith';

import PackagesAdobeProductsService from '../services/PackagesAdobeProductsService';

/**
 * @description - Gets the extension's icon URL from list of icons for different sizes
 *
 * @param {Array} icons - List of icon objects
 * @returns {String} - Extension's Icon URL.
 */
const getIconUrl = (icons = []) => {
  const icon =
    icons.find(({width}) => width === 96) ||
    icons.find(({width}) => width === 192) ||
    icons.find(({width}) => width === 144) ||
    icons.find(({width}) => width === 48);
  return icon?.href ?? '';
};

/**
 * @description Gets the list of supported 'PackagesAdobeProduct' objects for the extension
 *
 * @param {Array} hostApps List of supported host apps
 * @returns {Array} List of supported 'PackagesAdobeProduct' objects for the extension
 */
const getSupportedProducts = (hostApps = []) =>
  uniqWith(
    hostApps
      .map(
        (hostApp) =>
          PackagesAdobeProductsService.getAdobeProductBySapCodeVersion(
            `${hostApp.hostAppCode}/${hostApp.maxVersion}`,
            false
          ) ||
          PackagesAdobeProductsService.getLatestAdobeProductBySapCode(hostApp.hostAppCode, false)
      )
      .filter(Boolean),
    (app1, app2) => app1.sapCode === app2.sapCode
  );
class PackagesExtensionEntity {
  /**
   * @description - Creates a new PackagesExtension object for use.
   *
   * @param {Object} options - Initialization Object
   */
  constructor(options = {}) {
    this.id = options.id;
    this.learnMoreUrl = options.exchangeUrl;
    this.type = options.type;
    this.iconUrl = getIconUrl(options.icons);
    this.rating = options.rating?.overallRating;
    this.versionId = options.version?.id;
    this.version = options.version?.versionString;
    this.isDownloadable = options.version?.downloadable ?? true;
    this.supportedProducts = getSupportedProducts(options.version?.hostAppMapping);
    this.name = options.localizedMetadata?.name;
    this.description = options.localizedMetadata?.summary;
  }
}
export default PackagesExtensionEntity;
