(function () {
  /**
   * @deprecated replaced by the Pandora Add Products Modal
   *
   * @ngdoc       factory
   * @name        fraudMitigationEventListener
   * @description Forward UX events to fraudMitigation.
   */

  angular
    .module('app.core.fraud-mitigation')
    .factory('fraudMitigationEventListener', fraudMitigationEventListener);

  /* @ngInject */
  function fraudMitigationEventListener(
    $rootScope,
    $state,
    $transitions,
    feature,
    fraudMitigation,
    PANEL_MANAGER
  ) {
    const service = {
      configureEventListener,
    };

    return service;

    ////////////

    function configureEventListener() {
      if (feature.isEnabled('bumper_fraud_check')) {
        return;
      }

      $transitions.onSuccess({}, (transition) => {
        fraudMitigation.reportPageChange({pageName: transition.to().name});
      });

      // Modal changes are considered as "page changes" because
      // users can perform significant actions, e.g. add products.
      /* eslint-disable angular/on-watch */
      $rootScope.$on(PANEL_MANAGER.MODAL.OPEN, (evt, id) => {
        fraudMitigation.reportPageChange({pageName: id});
      });
      $rootScope.$on(PANEL_MANAGER.MODAL.CLOSE, () => {
        // Restore to the current page
        fraudMitigation.reportPageChange({pageName: $state.current.name});
      });
      /* eslint-enable angular/on-watch */
    }
  }
})();
