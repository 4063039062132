(function () {
  /**
   * @deprecated use src2 Storage pages
   */
  angular
    .module('app.core.user-folders')
    .constant('REMOVE_USER_STORAGE_OPTIONS', {
      DELETE: 'DELETE',
      DISCARD: 'DISCARD',
      SHARE: 'SHARE',
    })
    .constant('USER_FOLDER_TYPE', {
      USERS: 'USERS',
    })
    .constant('USER_FOLDER_STATE', {
      DISCARDED: 'DISCARDED',
    })
    .constant('MEDIA_TYPE', {
      FOLDER: 'application/vnd.adobecloud.directory+json',
      FOLDERS: [
        'application/vnd.adobecloud.directory+json',
        'application/x-sharedcloud-collection+json',
      ],
    });
})();
