(function () {
  /**
   * @ngdoc module
   * @name app.widgets.products
   * @description module that defines product widgets
   */
  angular.module('app.widgets.products', [
    'app.core',
    'app.core.analytics',
    'app.core.api.jil',
    'app.core.auth',
    'app.core.cart',
    'app.core.contract.contract-compliance',
    'app.core.digital-river-utils',
    'app.core.organizations.organization-manager',
    'app.core.product.access',
    'app.core.product.configurations.access',
    'app.core.product.configurations.admin-list',
    'app.core.product.product-group-product-list',
    'app.core.product.product-group-product-utils',
    'app.core.remove-on-read-only',
    'app.core.solution-group-list',
    'app.core.sophia',
    'app.core.stock',
    'app.main.global-modals-manager',
    'app.widgets.account',
    'app.widgets.admins',
    'app.widgets.bubble',
    'app.widgets.card-button-list',
    'app.widgets.common',
    'app.widgets.common.coral-checkbox-label',
    'app.widgets.common.links.button-link',
    'app.widgets.products.purchase',
    'app.widgets.products.set-authorization-policy',
    'app.widgets.remove-on-read-only',
    'app.widgets.scorecard',
    'app.widgets.sophia',
    'app.widgets.tabview',
    'app.widgets.tag',
    'app.widgets.trial-tag',
    'binky.core.commerce',
    'binky.core.common.member.type',
    'binky.core.common.selection',
    'binky.core.contract',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.organizations.market-subsegments',
    'binky.core.page-context',
    'binky.core.price',
    'binky.core.product',
    'binky.core.product.configurations',
    'binky.core.product.consumable',
    'binky.core.product.fulfillable-item-list',
    'binky.core.translation-utils',
    'binky.shell.panels.drawer',
    'binky.shell.panels.modal',
    'binky.widgets.commerce',
    'binky.widgets.common.accordion',
    'binky.widgets.common.button',
    'binky.widgets.common.date-time',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.display-message',
    'binky.widgets.common.drawer-header.drawer-header-description',
    'binky.widgets.common.drawer-header.drawer-header-icon',
    'binky.widgets.common.events',
    'binky.widgets.common.feature',
    'binky.widgets.common.force-positive-integer',
    'binky.widgets.common.go-url',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.number-input',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.search',
    'binky.widgets.common.select',
    'binky.widgets.common.toast',
    'binky.widgets.common.toggle',
    'binky.widgets.common.wizard',
    'binky.widgets.products.product-icon',
    'binky.widgets.products.product-name',
    'ngMaterial',
    'pascalprecht.translate',
  ]);
})();
