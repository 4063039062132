import {AlertDialog, Button, Content, DialogTrigger, Text} from '@adobe/react-spectrum';
import {useListContainerContext} from '@pandora/react-list-container';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useAllPackagesPageContext} from 'features/packages/pages/all-packages/AllPackagesPageContext';

/**
 * RemovePackageButton component to launch remove packages popover
 */
const RemovePackageButton = observer(() => {
  const intl = useIntl();
  const {listContainerUtils} = useListContainerContext();
  const {allPackagesStore} = useAllPackagesPageContext();

  const removeSelectedPackages = (close) => {
    allPackagesStore.deleteSelectedPackages(allPackagesStore.selectedPackages);
    listContainerUtils.setSelectedItemKeys([]);
    close();
  };

  const dialog = (close, packageList) => (
    <AlertDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      onCancel={close}
      onPrimaryAction={() => removeSelectedPackages(close)}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.remove'})}
      title={intl.formatMessage(
        {
          id: 'packages.allPackages.removePackages.title',
        },
        {count: packageList.length}
      )}
      variant="destructive"
    >
      <Content>
        <Text>
          {intl.formatMessage(
            {id: 'packages.allPackages.removePackages.confirmationMessage'},
            {count: packageList.length}
          )}
        </Text>
        <ul>
          {packageList.map((pkg) => (
            <li key={pkg.packageId}>
              <strong>{pkg.name}</strong>
            </li>
          ))}
        </ul>
      </Content>
    </AlertDialog>
  );
  return (
    <DialogTrigger>
      <Button
        data-testid="remove-packages-button"
        isDisabled={allPackagesStore.selectedPackages.length === 0}
        variant="negative"
      >
        {intl.formatMessage({
          id: 'packages.allPackages.removePackages.removePackageButtonLabel',
        })}
      </Button>
      {(close) => dialog(close, allPackagesStore.selectedPackages)}
    </DialogTrigger>
  );
});

RemovePackageButton.propTypes = {};

export default RemovePackageButton;
