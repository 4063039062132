import {Invoice} from '@admin-tribe/binky';
import {Item, Menu, MenuTrigger, Text} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import EmailIcon from '@spectrum-icons/workflow/Email';
import MoreIcon from '@spectrum-icons/workflow/More';
import VisibilityIcon from '@spectrum-icons/workflow/Visibility';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './BillingHistoryActionsMenu.pcss';

/**
 * Dropdown menu allowing viewing, downloading, or emailing an invoice
 */
const BillingHistoryActionsMenu = ({invoice, onDownloadInvoices, onEmailInvoice}) => {
  const intl = useIntl();
  const onActionPress = (key) => {
    switch (key) {
      case 'download':
        onDownloadInvoices({
          invoices: {[invoice.documentType]: {[invoice.externalContractId]: [invoice.id]}},
        });
        break;
      case 'email':
        onEmailInvoice({
          invoices: {[invoice.documentType]: {[invoice.externalContractId]: [invoice.id]}},
        });
        break;
      case 'view':
      default:
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens invoice in a new tab since adding a link messes up formatting
        window.open(invoice.link?.href, '_blank');
        break;
    }
  };

  return (
    <MenuTrigger>
      <TooltipButton
        buttonAriaLabel={intl.formatMessage(
          {
            id: 'account.billingHistory.table.actions.moreAriaLabel',
          },
          {invoiceId: invoice.id}
        )}
        hoverText={intl.formatMessage({id: 'account.billingHistory.table.actions.more'})}
        isQuiet
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- for cursor hover to match app
        UNSAFE_className={styles['tooltip-button-trigger']}
        variant="action"
      >
        <MoreIcon />
      </TooltipButton>
      <Menu onAction={onActionPress}>
        <Item
          key="view"
          textValue={intl.formatMessage({id: 'account.billingHistory.table.actions.view'})}
        >
          <VisibilityIcon />
          <Text>{intl.formatMessage({id: 'account.billingHistory.table.actions.view'})}</Text>
        </Item>
        <Item
          key="download"
          textValue={intl.formatMessage({
            id: 'account.billingHistory.table.actions.download',
          })}
        >
          <DownloadIcon />
          <Text>
            {intl.formatMessage({
              id: 'account.billingHistory.table.actions.download',
            })}
          </Text>
        </Item>
        <Item
          key="email"
          textValue={intl.formatMessage({id: 'account.billingHistory.table.actions.email'})}
        >
          <EmailIcon />
          <Text>{intl.formatMessage({id: 'account.billingHistory.table.actions.email'})}</Text>
        </Item>
      </Menu>
    </MenuTrigger>
  );
};

BillingHistoryActionsMenu.propTypes = {
  /**
   * Invoice that actions will apply to
   */
  invoice: PropTypes.instanceOf(Invoice).isRequired,
  /**
   * Handler to download the invoices
   */
  onDownloadInvoices: PropTypes.func.isRequired,
  /**
   * Handler to email the invoice
   */
  onEmailInvoice: PropTypes.func.isRequired,
};

export default BillingHistoryActionsMenu;
