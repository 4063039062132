(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AdobeStatusData
   * @description model that represents an Adobe status item (per service)
   */
  angular.module('app.core.adobe-status').factory('AdobeStatusData', getAdobeStatusData);

  /* @ngInject */
  function getAdobeStatusData(_, AdobeStatusEvent) {
    class AdobeStatusData {
      /**
       * @description Method to create a new instance of an AdobeStatusData item.
       * @param {Object} rawStatus - the raw status object
       */
      constructor(rawStatus = {}) {
        initModel(this, rawStatus);
      }

      /**
       * @description Finds both currently active and recently ended events
       * @param {Date} eventsSinceDate - the date to determine what is recent; all events that ended after this
       *   date are considered recent for this filter.
       * @returns {Array} list of recent and currently active events.
       */
      getActiveAndRecentEvents(eventsSinceDate) {
        return _.filter(this.history, (item) => item.endedAfterTime(eventsSinceDate));
      }

      /**
       * @description Determines if this status item has any currently active events
       * @param {Object} options - the options object for this call
       *  @param {Date} options.eventsSinceDate - the date to determine what is recent; all events that ended after this
       *   date are considered recent
       *  @param {Array} options.restrictToTypes - an array of types you want to restrict the check of ongoing events to.
       *   If left empty, event type will not be checked.
       * @returns {Boolean} true if any events are active or recent, false otherwise
       */
      hasActiveOrRecentEvents(options) {
        const opts = options || {};
        return _.some(this.history, (item) => {
          if (item.endedAfterTime(opts.eventsSinceDate)) {
            if (opts.restrictToTypes && opts.restrictToTypes.length > 0) {
              // see if this active event's type is one of the ones we're looking for
              return _.includes(opts.restrictToTypes, item.eventType);
            }
            return true;
          }
          return false;
        });
      }

      /**
       * @description Determines if this status item has any announcements
       * @returns {Boolean} true if any events are announcements
       */
      hasScheduledMaintenance() {
        return !_.isEmpty(this.scheduledMaintenance);
      }

      static apiResponseTransformer(item) {
        return new AdobeStatusData(item);
      }
    }

    return AdobeStatusData;

    function parseEventItem(historyItem, parent) {
      return new AdobeStatusEvent(historyItem, parent);
    }

    /**
     * @description Initializes AdobeStatusData data from options.
     *
     * @param {Object} model AdobeStatusData model Object instance to initialize
     * @param {Object} options Initialization Object (params described below)
     * @param {Array} options.history the list of historical status events, some of which may be ongoing
     * @param {Array} options.scheduledMaintenance the list of upcoming scheduled maintenance events
     * @param {Array} options.service the service id and name token information
     */
    function initModel(model, options = {}) {
      _.assignIn(model, _.pick(options, ['history', 'scheduledMaintenance', 'service']));
      model.history = _.map(model.history, (item) => parseEventItem(item, model));
      model.scheduledMaintenance = _.map(model.scheduledMaintenance, (item) =>
        parseEventItem(item, model)
      );
    }
  }
})();
