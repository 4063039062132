import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS} from '@admin-tribe/binky';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';

const ChangeEmailConfirmationModal = () => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {domainEnforcement, updateDomainEnforcement} = useDomainEnforcementContext();

  const getModalTranslationIds = () => {
    if (domainEnforcement.isDeEnforced) {
      return {
        confirm:
          'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOff.confirm',
        description:
          'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOff.description',
        recommendAgainst:
          'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOff.recommendAgainst',
        title:
          'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOff.title',
        toasts: {
          error: 'settings.domainEnforcement.requirementsSection.changeEmail.toast.toggleOff.error',
          success:
            'settings.domainEnforcement.requirementsSection.changeEmail.toast.toggleOff.success',
        },
        variant: 'destructive',
      };
    }

    return {
      confirm:
        'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOn.confirm',
      description:
        'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOn.description',
      title:
        'settings.domainEnforcement.requirementsSection.changeEmailConfirmationModal.turnOn.title',
      toasts: {
        error: 'settings.domainEnforcement.requirementsSection.changeEmail.toast.toggleOn.error',
        success:
          'settings.domainEnforcement.requirementsSection.changeEmail.toast.toggleOn.success',
      },
      variant: 'confirmation',
    };
  };

  const MODAL_TRANSLATION_IDS = getModalTranslationIds();

  const handleOnChangeEmailToggle = async () => {
    try {
      // If DE is ENFORCED, we want to turn it off, so we need to pass ACTIVATED
      // otherwise, we want to turn it on, so we need to pass ENFORCED
      const updateState = domainEnforcement.isDeEnforced
        ? DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED
        : DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED;

      await updateDomainEnforcement(updateState);

      dialog.dismiss();

      showSuccessToast(
        intl.formatMessage({
          id: MODAL_TRANSLATION_IDS.toasts.success,
        })
      );
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: MODAL_TRANSLATION_IDS.toasts.error,
        })
      );
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      isLoading={domainEnforcement.isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleOnChangeEmailToggle}
      primaryActionLabel={intl.formatMessage({
        id: MODAL_TRANSLATION_IDS.confirm,
      })}
      title={intl.formatMessage({
        id: MODAL_TRANSLATION_IDS.title,
      })}
      variant={MODAL_TRANSLATION_IDS.variant}
    >
      <Text>
        <FormattedMessage id={MODAL_TRANSLATION_IDS.description} />
      </Text>

      {domainEnforcement.isDeEnforced && (
        <View marginTop="size-200">
          <Text>
            <FormattedMessage id={MODAL_TRANSLATION_IDS.recommendAgainst} />
          </Text>
        </View>
      )}
    </ModalAlertDialog>
  );
};

ChangeEmailConfirmationModal.displayName = 'ChangeEmailConfirmationModal';

ChangeEmailConfirmationModal.propTypes = {};

export default ChangeEmailConfirmationModal;
