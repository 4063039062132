(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support::appSupportTicketDrawerAttachmentsSection
   *
   * @description displays the files attached to a support ticket, most recent first
   *
   * @binding {SupportTicket} supportTicket - the ticket whose attachments should be displayed
   */
  angular.module('app.widgets.support').component('appSupportTicketDrawerAttachmentsSection', {
    bindings: {
      supportTicket: '<',
    },
    templateUrl:
      'app/widgets/support/support-ticket-drawer-attachments-section/support-ticket-drawer-attachments-section.component.html',
  });
})();
