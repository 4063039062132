import rootStore from 'core/RootStore';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';

/**
 * @description Determine if the rule is Adobe created
 *
 * @param {Object} rule object as returned by the ACRS API
 * @returns {Boolean} is the rule adobe created
 */
// Note - Temporarily, we rely on the existence of the property
// waitingUntilDate to determine if the rule is Adobe created.
const isDefaultRule = (rule) => !!rule.waitingUntilDate;

/**
 * @description Get product and rule for the target product arrangement code
 *
 * Note - This method will only find Adobe created rules, as this widget is
 * only concerned with those.
 *
 * The function would return undefined in the following cases, since we cannot
 * tell which rule to pick
 * 1. If there is no matching product
 * 2. If there are none or multiple rules from matched products
 * 3. If there are multiple matching products with such rules
 *
 * @returns {Array<Object>|undefined} an array containing the rule and product
 *     object (2 items), or undefined if not found
 */
const getAdobeRuleAndProduct = (productArrangementCode) => {
  const autoAssignRules = AutoAssignRulesCache.get().autoAssignRules;
  const productList = rootStore.organizationStore.productList;

  const matchingProducts = productList.items.filter(
    (product) => product.productArrangementCode === productArrangementCode
  );
  if (matchingProducts.length === 0) {
    return undefined;
  }

  const matchingRules = autoAssignRules?.filter(
    (rule) =>
      isDefaultRule(rule) && matchingProducts.some((product) => product.id === rule.licenseId)
  );
  if (matchingRules.length > 1 || matchingRules.length === 0) {
    return undefined;
  }

  return [
    matchingRules[0],
    // find the product for the rule
    matchingProducts.find((product) => product.id === matchingRules[0].licenseId),
  ];
};

export {getAdobeRuleAndProduct, isDefaultRule};
