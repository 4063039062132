export default class HelpItem {
  /**
   * @description Creates a new HelpItem for use. The HelpItem is the base
   *   class for items that display within the help section.
   * @param {Object} options - Initialization Object (params described
   *   below)
   * @param {Function} [options.access] - Callback to see if signed
   *   in user should have access to this item. If omitted, user will always
   *   have access. If provided, method should return a Promise
   *   to allow chaining and async access calculations
   * @param {Link[]} [options.links] - List of any links that should be
   *   associated with this help item. HelpTopics may contain no links,
   *   so this parameter is optional
   * @param {String} [options.text] - Any textual content that should be
   *   associated with this help item. Optional - may be excluded
   * @param {String} [options.title] - The String translation key for the
   *   title of this help item. Optional - some items do not contain titles
   *   (e.g. - info items)
   * @param {Object<string, string>} translateValues - Any key/value translation
   *   replacement values needed for text
   * @param {HELP_ITEM_TYPE} [options.type] - The type of help item
   *   represented. Optional - should be omitted for HelpTopics. Otherwise, if
   *   used, must be one of: HELP_ITEM_TYPE.INFO or HELP_ITEM_TYPE.STEP
   */
  constructor(options = {}) {
    const classAttrs = ['access', 'links', 'text', 'title', 'translateValues', 'type'];

    classAttrs.forEach((attr) => {
      if (options[attr] !== undefined && options[attr] !== null) {
        Object.assign(this, {[attr]: options[attr]});
      }
    });
  }

  /**
   * @description Method to determine if instance is a HelpItem or a HelpTopic.
   *   Since topics extend an item, they possess a unique 'type' attribute that
   *   sets them apart. This simply checks for the presence of that attribute.
   * @returns {boolean} true if this is a help item, else false if a topic
   */
  isTopic() {
    return !Object.prototype.hasOwnProperty.call(this, 'type');
  }
}
