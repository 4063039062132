(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories.idp-status:appIdpStatus
   * @description IdP status. Can be default (in use), inactive or unconfigured.
   *              Maps to the defaultness of the IdP for its directory or it being
   *              inactive
   *
   * @param {Object} idp the IdP for which the status is displayed
   * @example <app-idp-status
   *            idp="idp">
   *          </app-idp-status>
   *
   */
  angular.module('app.widgets.directories.idp-status').component('appIdpStatus', {
    bindings: {
      idp: '<',
    },
    controller,
    templateUrl: 'app/widgets/directories/idp-status/idp-status.component.html',
  });

  /* @ngInject */
  function controller($translate, _, IDP_STATUSES) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      if (!vm.idp.isActive()) {
        vm.labelKey = IDP_STATUSES.PENDING.label;
        vm.variant = IDP_STATUSES.PENDING.variant;
      } else if (vm.idp.isActive()) {
        vm.labelKey = vm.idp.isDefault() ? IDP_STATUSES.DEFAULT.label : IDP_STATUSES.INACTIVE.label;
        vm.variant = vm.idp.isDefault()
          ? IDP_STATUSES.DEFAULT.variant
          : IDP_STATUSES.INACTIVE.variant;
      }
    }
  }
})();
