/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.cancellation-check-list:appCancellationCheckList
 *
 * @description Handle a cancellation reasons checklist and comment section, returning the selected reasons and comment
 * @example
 *  <app-cancellation-check-list
 *    cancellation-reason="$ctrl.cancellationReason"
 *    cancellation-reason-comment="$ctrl.cancellationReasonComment"
 *    on-reasons-change="$ctrl.onReasonsChange({ reasons, isValid })">
 *  </app-cancellation-check-list>
 *
 * @binding {Array} [cancellationReason] - Array of initially selected reasons
 * @binding {String} cancellationReasonComment - Two-way binding string which will be updated to reflect comment text
 * @binding {Function} onReasonsChange - A function that will receive the selected reasons and the form validity
 *   when there's a new selection or the validity has changed. The form is valid if there is at least one reason selected, but
 *   if Other reason is selected, the comment text is required.
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appCancellationCheckList', {
    bindings: {
      cancellationReason: '<?',
      cancellationReasonComment: '=',
      onReasonsChange: '&',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/cancellation-check-list/cancellation-check-list.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    chat,
    feature,
    CANCEL_REASONS,
    CANCELLATION_REASONS_LIST,
    CHAT_APP_ID,
    binkySrc2,
    onesieSrc2
  ) {
    const vm = this;
    let isValid;

    const {EVENT_ACTION} = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;

    _.assign(vm, {
      $onInit,
      onCommentTextAreaChange,
      onReasonChecked,
    });

    function $onInit() {
      // Used for checking if a cancellation reason should be initially checked
      const checkedCancellationReasonsHash = _.zipObject(
        vm.cancellationReason,
        _.fill(new Array(_.size(vm.cancellationReason)), true)
      );
      const cancellationReasonsKey = 'widgets.products.purchase.cancellationList.reasons2';
      // Generate ordered reasons array
      vm.reasons = _.map(CANCELLATION_REASONS_LIST, (reasonKey) => {
        const reason = {
          checked: !!checkedCancellationReasonsHash[reasonKey],
          key: CANCEL_REASONS[reasonKey], // should this constant name change
          label: $translate.instant(`${cancellationReasonsKey}.${_.camelCase(reasonKey)}`),
        };
        if (reasonKey === CANCEL_REASONS.OTHER) {
          vm.showComment = reason.checked;
        }
        return reason;
      });
      // Fire initial onReasonsChange to reconcile internal and external state
      fireOnReasonsChange();
    }

    function onCommentTextAreaChange() {
      // Fire onReasonsChange is there is a change in validity based on comment changing from or to an empty string
      const isCommentEmpty = !vm.cancellationReasonComment;
      if ((isCommentEmpty && isValid) || (!isCommentEmpty && !isValid)) {
        fireOnReasonsChange();
      }
    }

    function onReasonChecked(reason) {
      reason.checked = !reason.checked;

      if (reason.key === CANCEL_REASONS.OTHER) {
        vm.showComment = reason.checked;
      } else if (reason.key === CANCEL_REASONS.TECHNICAL_DIFFICULTY && reason.checked) {
        dispatchChatClickAnalytics('renewalTechnicalDifficultyCancellationStartChat');

        onesieSrc2.core.services.chat.chatProvider.openMessagingWindow({
          appId: CHAT_APP_ID.ONESIE1_TECH_SUPPORT,
          sourceText: $translate.instant(
            'account.selfCancel.cancellationReasons.reasonsList.technicalDifficulty'
          ),
          sourceType: `Cancellation Reason: ${CANCEL_REASONS.TECHNICAL_DIFFICULTY}`,
        });
      } else if (reason.key === CANCEL_REASONS.TOO_EXPENSIVE && reason.checked) {
        dispatchChatClickAnalytics('renewalTooExpensiveCancellationStartChat');

        // open jarvis too expensive chat window
        onesieSrc2.core.services.chat.chatProvider.openMessagingWindow({
          appId: CHAT_APP_ID.ONESIE1_TOO_EXPENSIVE,
          sourceText: $translate.instant(
            'account.selfCancel.cancellationReasons.reasonsList.tooExpensive'
          ),
          sourceType: `Cancellation Reason: ${CANCEL_REASONS.TOO_EXPENSIVE}`,
        });
      }

      fireOnReasonsChange();
    }

    //////////

    /**
     * @description Dispatch analytics event when a user selects a cancellation reason that activates the chat click action
     * @param {String} eventName - name of the analytics event
     */
    function dispatchChatClickAnalytics(eventName) {
      const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;

      dispatchUiEventAnalytics({
        eventAction: EVENT_ACTION.CLICK,
        eventName,
      });
    }

    function fireOnReasonsChange() {
      const reasons = _.reduce(
        vm.reasons,
        (list, {checked, key}) => {
          if (checked) {
            list.push(key);
          }
          return list;
        },
        []
      );
      // Save last valid state to check when validity changes
      isValid = vm.showComment ? !_.isEmpty(vm.cancellationReasonComment) : !_.isEmpty(reasons);

      vm.onReasonsChange({
        isValid,
        reasons,
      });
    }
  }
})();
