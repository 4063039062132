(function () {
  const componentName = 'appQuickAssignSingleSeatForm';
  /**
   * @deprecated use src2 Quick Assign modal
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      onFinish: '&',
      onSubmit: '&',
      onUsersChange: '&',
      productDataList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/quick-assign-single-seat-form/quick-assign-single-seat-form.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $timeout,
    $translate,
    $q,
    AuthenticatedUser,
    AnalyticsEvent,
    CLOUD,
    OrganizationUser,
    OrganizationUserHelper,
    PRODUCT_FAMILY,
    QuickAssignHelper
  ) {
    const vm = this;
    vm.$onInit = $onInit;
    const DEBOUNCE_TIME = 1000;
    const SEARCH_QUERY_MIN_LENGTH = 3;

    const MODAL_STATE_CLASSNAMES = {
      ASSIGN_ONE_USER: 'assign-one-user',
      DETERMINE_SELF_ASSIGNMENT: 'determine-self-assignment',
      SELF_ASSIGNMENT_CONFIRMED: 'self-assignment-confirmed',
    };
    const MODAL_STATES = {
      ASSIGN_ONE_USER: 'AssignOneUser',
      DETERMINE_SELF_ASSIGNMENT: 'DetermineSelfAssignment',
      SELF_ASSIGNMENT_CONFIRMED: 'SelfAssignmentConfirmed',
    };

    _.assign(vm, {
      $onInit,
      isConfirmCtaDisabled,
      onBack,
      onConfirm,
      onDecideLater,
      onDownload,
      onEmailSearchInputChange,
      onMaybeLater,
      onNext,
      onNoSelfAssign,
      onYesSelfAssign,
      waitPromise: $q.resolve(),
    });

    /////////////

    function $onInit() {
      vm.modalState = {};
      vm.modalState.state = MODAL_STATES.DETERMINE_SELF_ASSIGNMENT;
      vm.class = MODAL_STATE_CLASSNAMES.DETERMINE_SELF_ASSIGNMENT;

      const tempDeferred = $q.defer();
      tempDeferred.resolve();

      vm.userEmail = AuthenticatedUser.get().getEmail();

      vm.rowDetails = {
        // Force email input error when user types into first/last name fields without typing in an email
        forceShowEmailFieldError: false,
        // True when OrgUser has a valid email
        isValidOrgUser: undefined,
        // true if a row is less than the minimum query length or that row has a valid org user.
        isValidRow: true,
        orgUser: new OrganizationUser(),
        rowDebouncePromise: undefined,
        rowWaitDeferred: tempDeferred,
      };

      vm.product = vm.productDataList[0];

      vm.downloadTitle =
        'widgets.users.quickAssignModal2.singleSeatFlow.confirmSelfAssignmentState.title';

      vm.downloadLabel = QuickAssignHelper.getDownloadButtonLabel(vm.product);

      vm.showSelfAssignmentSecondaryButton =
        vm.product.cloud === CLOUD.CREATIVE ||
        vm.product.family === PRODUCT_FAMILY.SPARK ||
        vm.product.family === PRODUCT_FAMILY.ACROBAT ||
        vm.product.isAdobeStock;
    }

    function isConfirmCtaDisabled() {
      return !(vm.rowDetails.isValidOrgUser && vm.rowDetails.isValidRow);
    }

    function onBack() {
      sendClickAnalytics('go-back');
      vm.modalState.state = vm.modalState.previous;
    }

    function onConfirm() {
      vm.confirmError = false;
      sendClickAnalytics('confirm-assign-email');
      vm.onSubmit().catch(() => {
        vm.confirmError = true;
      });
    }

    function onDecideLater() {
      sendClickAnalytics('decide-later');
      const options = {
        decideLater: true,
      };
      vm.onFinish({options});
    }

    function onDownload() {
      sendClickAnalytics('self-assign-download');

      QuickAssignHelper.onDownload(vm.product, vm.onFinish);
    }

    function onEmailSearchInputChange(searchQuery) {
      vm.rowDetails.invalidErrorMessage = '';
      vm.rowDetails.orgUser.email = searchQuery;
      vm.rowDetails.isValidRow = false;
      vm.rowDetails.rowWaitDeferred = $q.defer();
      vm.rowDetails.forceShowEmailFieldError = false;

      if (vm.rowDetails.rowDebouncePromise) {
        $timeout.cancel(vm.rowDetails.rowDebouncePromise);
        vm.rowDetails.rowDebouncePromise = undefined;
      }

      const emailLength = _.size(vm.rowDetails.orgUser.email);
      if (emailLength >= SEARCH_QUERY_MIN_LENGTH) {
        vm.rowDetails.rowDebouncePromise = $timeout(
          () => handleUserInput(vm.rowDetails),
          DEBOUNCE_TIME
        );
      } else {
        OrganizationUserHelper.resetInputOrgUser(vm.rowDetails.orgUser);
        vm.rowDetails.isValidRow = true;
        vm.rowDetails.isValidOrgUser = false;
        vm.rowDetails.rowWaitDeferred.resolve();
      }

      return vm.rowDetails.rowWaitDeferred.promise;
    }

    function onMaybeLater() {
      sendClickAnalytics('self-assign-maybe-later');
      vm.onFinish();
    }

    function onNext(options = {}) {
      if (vm.modalState.state === MODAL_STATES.DETERMINE_SELF_ASSIGNMENT) {
        vm.modalState.previous = vm.modalState.state;
        if (options.selfAssigned === true) {
          vm.modalState.state = MODAL_STATES.SELF_ASSIGNMENT_CONFIRMED;
          vm.class = MODAL_STATE_CLASSNAMES.SELF_ASSIGNMENT_CONFIRMED;
        } else {
          vm.modalState.state = MODAL_STATES.ASSIGN_ONE_USER;
          vm.class = MODAL_STATE_CLASSNAMES.ASSIGN_ONE_USER;
        }
      }
    }

    function onNoSelfAssign() {
      sendClickAnalytics('no-self-assign');
      onNext({selfAssigned: false});
    }

    function onYesSelfAssign() {
      vm.confirmError = false;
      sendClickAnalytics('yes-self-assign');
      vm.rowDetails.products = [vm.product];
      vm.waitPromise = onEmailSearchInputChange(vm.userEmail)
        .then(() => {
          const orgUserList = QuickAssignHelper.getOrgUserListFromRowsDetails([vm.rowDetails]);
          vm.onUsersChange({orgUserList});
          const options = {
            blockThankYou: true,
          };
          return vm.onSubmit({options});
        })
        .then(() => {
          onNext({selfAssigned: true});
        })
        .catch(() => {
          vm.confirmError = true;
        });
    }

    // private functions
    function getErrorMessage(errorCode) {
      return errorCode
        ? $translate.instant(`widgets.users.quickAssignModal2.errorToast.${errorCode}`)
        : undefined;
    }

    function handleUserInput(rowDetails) {
      const licenseGroupInfo = vm.product.licenseGroupSummaryPromise;
      licenseGroupInfo
        .then((licenseGroupSummary) =>
          OrganizationUserHelper.populateValidatedNewUserOrExistingUser2(rowDetails.orgUser, {
            licenseGroupIds: [_.get(licenseGroupSummary, 'id')],
            selectedProducts: [vm.product],
          })
        )
        .then(() => onHandleUserInputSuccess(rowDetails))
        .catch((error) => onHandleUserInputError(rowDetails, error))
        .finally(() => {
          rowDetails.isValidRow = rowDetails.isValidOrgUser;
          rowDetails.rowWaitDeferred.resolve();
          rowDetails.products = [vm.product];
          const orgUserList = QuickAssignHelper.getOrgUserListFromRowsDetails([rowDetails]);
          vm.onUsersChange({orgUserList});
        });
    }

    function onHandleUserInputError(rowDetails, error) {
      rowDetails.forceShowEmailFieldError = true;
      rowDetails.isValidOrgUser = false;
      rowDetails.invalidErrorMessage = getErrorMessage(_.get(error, 'errorCode'));
    }

    function onHandleUserInputSuccess(rowDetails) {
      rowDetails.isValidOrgUser = true;
    }

    function sendClickAnalytics(method) {
      AnalyticsEvent.dispatch({
        componentMethod: method,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
