import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import PackagesAdobeProductsUi from 'features/packages/services/PackagesAdobeProductsUiService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';

/**
 * @description Retrieves Entitlements Offer List
 * @param {Object} pkgSessionSettings - Shared object for package creation session
 *
 * @return {Object} Entitlement Offer List based on packaging mode and package type
 */
function getEntitlementsOfferList(pkgSessionSettings) {
  let offersList = [];
  if (pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE) {
    if (pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE) {
      offersList = pkgSessionSettings.frlSelectedServer.licenses.filter((license) =>
        license.fulfillableItemList.getDesktopTypeItems().some((fulfillableItem) => {
          const unitCodes = fulfillableItem.chargingModel.unit;
          return (
            fulfillableItem.code === rootStore.packagesUiConstantsStore.frlPreconditioningFiCode &&
            unitCodes.includes(rootStore.packagesUiConstantsStore.frlLanUnitCode)
          );
        })
      );
    } else {
      // in case of frl_online, frl_offline, frl_isolated call getPackageableOffers
      offersList = getPackageableOffers(
        PackagesEntitlements.getFrlOffers(pkgSessionSettings.packageType)
      );
    }
  } else if (pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE) {
    offersList = getPackageableOffers(PackagesEntitlements.getNuellOffers);
  }
  return offersList;
}

/**
 * @description Get Initial Selected Keys
 * @param {Object} entitlementOffersList - list of entitlements offers
 *
 * @return {Object} list of selected keys
 */
function getSelectedKeys(entitlementOffersList) {
  let keys = [];
  entitlementOffersList.forEach((offer) => {
    if (offer.selected) {
      keys = [...keys, offer.offerId];
    }
  });
  return keys;
}

/**
 * @description Retrieves PackageableNuellOffers
 * @param {Object} offers - frl/nuell offers
 *
 * @return {Object} Offer List
 */
function getPackageableOffers(offers) {
  const frlEnabledProducts = PackagesAdobeProductsUi.getAllPackageableAdobeProducts().filter(
    (product) => product.frlEnabled
  );
  // filter those offers which have atleast 1 packageable FRL/NGL enabled product
  return offers.filter((offer) =>
    offer
      .getPackageableProductSapCodes()
      .some((sapCode) =>
        frlEnabledProducts.some((frlEnabledProduct) => frlEnabledProduct.sapCode === sapCode)
      )
  );
}

/**
 * @description Trigger Ingest Analytics
 * @param {String} offerLongName - name of the entitlement offer
 * @param {String} packagingMode - Selected packaging mode in the create package flow
 * @param {String} packageType - Selected package type in the create package flow
 */
function triggerIngestAnalytics(offerLongName, packagingMode, packageType) {
  if (packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE) {
    PackagesIngestAnalytics.triggerAnalytics({
      content: offerLongName,
      subCategory: PackagesIngestAnalytics.getIngestSubcategory(packageType, 'Entitlement'),
      subType: 'entitlement',
      type: 'click',
    });
  }

  if (packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE) {
    PackagesIngestAnalytics.triggerAnalytics({
      content: offerLongName,
      subCategory: 'SDL:Entitlement',
      subType: 'entitlement',
      type: 'click',
    });
  }
}

/**
 * @description Indicates if add remove option should be visible in frl license file
 * @param {String} packageType - Selected package type in the create package flow
 *
 * @return {Boolean} if add remove option should be visible in frl license file
 */
function isAddRemoveOptionVisible(packageType) {
  return (
    packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE ||
    packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE ||
    packageType === CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE
  );
}

/**
 * @description Indicates if offer should be disabled
 * @param {Array} commonFi - If common fi codes present
 * @param {String} offerId - Current offer's id
 * @param {String} nonSelectedOfferId - Non selected offer's id
 *
 * @return {Boolean} if add remove option should be visible in frl license file
 */
function shouldDisableNonSelectedOffer(commonFi, nonSelectedOfferId, offerId) {
  return offerId !== nonSelectedOfferId && commonFi?.length > 0;
}

/**
 * @description title Description For Package Type
 * @param {String} packageType - selected package type
 *
 * @return {String} title Description For Package Type
 */
function titleDescForPackageType(packageType) {
  return packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE ? 'titleDescFrlLan' : 'titleDesc';
}

export {
  getEntitlementsOfferList,
  getSelectedKeys,
  isAddRemoveOptionVisible,
  shouldDisableNonSelectedOffer,
  titleDescForPackageType,
  triggerIngestAnalytics,
};
