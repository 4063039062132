import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Text, View, Well} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import StorageRepositoryList from 'common/services/storage-repository-list/StorageRepositoryList';

import EditRepositoryNameButton from '../edit-repository-name/button/EditRepositoryNameButton';

/**
 * Repository name section for Storage overview page.
 * The repository name may be edited.
 */
const RepositoryNameSection = ({marginBottom, repositoryList, showDivider = true}) => {
  // Since canViewStorage is checking for a repository with '{entitled: true, provisioned: true}'
  // and there currently can only be 1 repository we know if we get here the first item has the
  // name.
  const [repositoryName, setRepositoryName] = useState(repositoryList.items?.[0].name);
  const intl = useIntl();

  const headingId = 'repository-name-section';
  const descriptionId = `${headingId}-description`;

  const heading = intl.formatMessage({id: 'storage.repositoryNameSection.title'});
  const description = intl.formatMessage(
    {id: 'storage.repositoryNameSection.description'},
    {goUrl: (str) => <GoUrl name="aac_storage_overview_learn">{str}</GoUrl>}
  );

  return (
    <TitledSection
      dividerPlacement={(showDivider && DividerPlacement.TOP) || DividerPlacement.NONE}
      headingLevel={2}
      id={headingId}
      marginBottom={marginBottom}
      title={heading}
    >
      {/* Want the divider to be full width but the content to be 75% width. */}
      <View maxWidth="75%">
        <View id={descriptionId} marginTop="size-100">
          {description}
        </View>
        <Flex gap="size-100" marginTop="size-200">
          <Well width="90%">
            <Text
              aria-describedby={descriptionId}
              aria-labelledby={headingId}
              data-testid="repository-name"
              UNSAFE_style={{fontSize: '18px', fontStyle: 'italic'}}
            >
              {repositoryName}
            </Text>
          </Well>
          <EditRepositoryNameButton onSuccess={setRepositoryName} repositoryList={repositoryList} />
        </Flex>
      </View>
    </TitledSection>
  );
};

RepositoryNameSection.propTypes = {
  /**
   * Margin to add after the section. Should be specified with Spectrum sizes.
   * The default is undefined.
   */
  marginBottom: PropTypes.string,
  /**
   * The resolved StorageRepository list.
   */
  repositoryList: PropTypes.instanceOf(StorageRepositoryList).isRequired,
  /**
   * Whether or not to show a divider at the top of the section. The default is true.
   */
  showDivider: PropTypes.bool,
};

export default RepositoryNameSection;
