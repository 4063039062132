(function () {
  /**
   * @deprecated this has been ported to src2
   *
   * @ngdoc service
   * @name app.core.user.access:userAccess
   * @description user access factory
   */
  angular.module('app.core.user.access').factory('userAccess', userAccess);

  /* @ngInject */
  function userAccess(auth, feature, ROLE) {
    const service = {
      canViewUsers,
    };

    return service;

    //////////////

    function canViewUsers() {
      if (feature.isEnabled('temp_parkour_mm')) {
        if (feature.isEnabled('temp_adobe_agent_access')) {
          return auth.hasAnyRole([
            ROLE.ADMIN.LICENSE,
            ROLE.ADMIN.ORG,
            ROLE.ADMIN.PRODUCT,
            ROLE.ADMIN.PRODUCT_SUPPORT,
            ROLE.ADMIN.SUPPORT,
            ROLE.ADMIN.STORAGE,
            ROLE.ADMIN.USER_GROUP,
            ROLE.AGENT.ADOBE_AGENT_ADMIN,
            ROLE.AGENT.ADOBE_AGENT_CUSTOMER_CARE,
            ROLE.AGENT.ADOBE_AGENT_PROFESSIONAL_SERVICES,
            ROLE.AGENT.ADOBE_AGENT_PROVISIONER,
            ROLE.AGENT.ADOBE_AGENT_READ,
            ROLE.AGENT.ADOBE_AGENT_RESELLER_LICENSING,
          ]);
        }
        return auth.hasAnyRole([
          ROLE.ADMIN.LICENSE,
          ROLE.ADMIN.ORG,
          ROLE.ADMIN.PRODUCT,
          ROLE.ADMIN.PRODUCT_SUPPORT,
          ROLE.ADMIN.SUPPORT,
          ROLE.ADMIN.STORAGE,
          ROLE.ADMIN.USER_GROUP,
        ]);
      }

      if (feature.isEnabled('temp_adobe_agent_access')) {
        return auth.hasAnyRole([
          ROLE.ADMIN.LICENSE,
          ROLE.ADMIN.ORG,
          ROLE.ADMIN.PRODUCT,
          ROLE.ADMIN.SUPPORT,
          ROLE.ADMIN.STORAGE,
          ROLE.ADMIN.USER_GROUP,
          ROLE.AGENT.ADOBE_AGENT_ADMIN,
          ROLE.AGENT.ADOBE_AGENT_CUSTOMER_CARE,
          ROLE.AGENT.ADOBE_AGENT_PROFESSIONAL_SERVICES,
          ROLE.AGENT.ADOBE_AGENT_PROVISIONER,
          ROLE.AGENT.ADOBE_AGENT_READ,
          ROLE.AGENT.ADOBE_AGENT_RESELLER_LICENSING,
        ]);
      }

      return auth.hasAnyRole([
        ROLE.ADMIN.LICENSE,
        ROLE.ADMIN.ORG,
        ROLE.ADMIN.PRODUCT,
        ROLE.ADMIN.SUPPORT,
        ROLE.ADMIN.STORAGE,
        ROLE.ADMIN.USER_GROUP,
      ]);
    }
  }
})();
