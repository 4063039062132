/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc service
   * @name supportContactInfo
   * @description provides details for how to contact Adobe customer support, such as phone numbers
   *   and support contact hours.
   */
  angular.module('app.widgets.support').factory('supportContactInfo', supportContactInfo);
  /* @ngInject */
  function supportContactInfo(
    $translate,
    _,
    CUSTOMER_SEGMENT,
    SUPPORT_EMEA_COUNTRIES,
    SUPPORT_LATAM_COUNTRIES
  ) {
    const ENTERPRISE_CONTACT_INFO = [
      {
        countryCodes: ['AU'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['1800141710'],
        regionKey: 'australia',
      },
      {
        countryCodes: ['AT'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800 205 275'],
        regionKey: 'austria',
      },
      {
        countryCodes: ['BR'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['08000474558'],
        regionKey: 'brazil',
      },
      {
        countryCodes: ['CN'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'mandarin',
        phoneNumbers: [
          getTranslation('phoneNumber.netcom', {phoneNumber: '10800 744 0439'}),
          getTranslation('phoneNumber.telecom', {phoneNumber: '10800 440 0422'}),
          getTranslation('phoneNumber.mobile', {phoneNumber: '400 600 0130'}),
        ],
        regionKey: 'china',
      },
      {
        countryCodes: ['FR'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'french',
        phoneNumbers: ['08 05 54 04 81'],
        regionKey: 'france',
      },
      {
        countryCodes: ['DE'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800 101 2251'],
        regionKey: 'germany',
      },
      {
        countryCodes: ['HK'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['30126697'],
        regionKey: 'hongKong2',
      },
      {
        countryCodes: ['IN'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['0008004420064'],
        regionKey: 'india',
      },
      {
        countryCodes: ['ID'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['001803447413'],
        regionKey: 'indonesia',
      },
      {
        countryCodes: ['IE'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['1800995030'],
        regionKey: 'ireland',
      },
      {
        countryCodes: ['JP'],
        hoursKey: '930amTo530pmMonToFri',
        languageKey: 'japanese',
        phoneNumbers: [
          getTranslation('phoneNumber.ccDcNumber', {
            phoneNumber: '0120-988-240',
          }),
          getTranslation('phoneNumber.dxNumber', {phoneNumber: '0120-071-300'}),
        ],
        regionKey: 'japan',
      },
      {
        countryCodes: ['KR'],
        hoursKey: '9amTo6pmMonToFri',
        languageKey: 'korean',
        phoneNumbers: ['080-929-0880'],
        regionKey: 'korea',
      },
      {
        countryCodes: ['MY'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['1-300-80-0029'],
        regionKey: 'malaysia',
      },
      {
        countryCodes: ['MX'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['018000835911'],
        regionKey: 'mexico',
      },
      {
        countryCodes: ['NZ'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['508562395'],
        regionKey: 'newZealand',
      },
      {
        countryCodes: ['PH'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['180014410442'],
        regionKey: 'philippines',
      },
      {
        countryCodes: ['SG'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['18006227213'],
        regionKey: 'singapore',
      },
      {
        countryCodes: ['SE'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['020 109289'],
        regionKey: 'sweden',
      },
      {
        countryCodes: ['CH'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800 000 050'],
        regionKey: 'switzerland',
      },
      {
        countryCodes: ['TW'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'mandarin',
        phoneNumbers: ['0800666029'],
        regionKey: 'taiwan2',
      },
      {
        countryCodes: ['TH'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['00-18004418858'],
        regionKey: 'thailand',
      },
      {
        countryCodes: ['GB'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['08000 280441'],
        regionKey: 'unitedKingdom',
      },
      {
        countryCodes: ['CA', 'US'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['800-685-2950'],
        regionKey: 'usAndCanada',
      },
      {
        countryCodes: SUPPORT_EMEA_COUNTRIES,
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['+44 207 136 9109'],
        regionKey: 'emea',
      },
      {
        countryCodes: SUPPORT_LATAM_COUNTRIES,
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['1-408-454-5912'],
        regionKey: 'latam',
      },
    ];

    const TEAM_CONTACT_INFO = [
      {
        countryCodes: ['AR'],
        hoursKey: '9amTo7pmMonToFri',
        languageKey: 'spanish',
        phoneNumbers: ['0800-266-7537'],
        regionKey: 'latam',
      },
      {
        countryCodes: ['AT'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800-090-9598'],
        regionKey: 'austria',
      },
      {
        countryCodes: ['AU'],
        hoursKey: '24x7',
        languageKey: 'ukEnglish',
        phoneNumbers: ['1800-131-154'],
        regionKey: 'australia',
      },
      {
        countryCodes: ['BO', 'DO', 'PA', 'PY', 'SV', 'UY'],
        hoursKey: '9amTo7pmMonToFri',
        languageKey: 'spanish',
        phoneNumbers: ['+1-408-454-2936'],
        regionKey: 'latam',
      },
      {
        countryCodes: ['BR'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'portuguese',
        phoneNumbers: ['0800-047-5064'],
        regionKey: 'brazil',
      },
      {
        countryCodes: ['CN'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'mandarin',
        phoneNumbers: [
          getTranslation('phoneNumber.netcom', {phoneNumber: '10800 744 0438'}),
          getTranslation('phoneNumber.telecom', {phoneNumber: '10800 440 0421'}),
        ],
        regionKey: 'chinaUaApac',
      },
      {
        countryCodes: ['CZ'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['420-239-016-347'],
        regionKey: 'czechRepublic',
      },
      {
        countryCodes: ['DK'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['45-3832-2914'],
        regionKey: 'denmark',
      },
      {
        countryCodes: ['FI'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['09-6937-9671'],
        regionKey: 'finland',
      },
      {
        countryCodes: ['FR'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'french',
        phoneNumbers: ['08 05 54 30 79'],
        regionKey: 'france',
      },
      {
        countryCodes: ['DE'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800-724-3493'],
        regionKey: 'germany',
      },
      {
        countryCodes: ['HK'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'mandarin',
        phoneNumbers: ['3071 4922'],
        regionKey: 'hongKong2',
      },
      {
        countryCodes: ['IN'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['000-800-440-1751'],
        regionKey: 'india',
      },
      {
        countryCodes: ['ID'],
        hoursKey: '24x7',
        languageKey: 'english',
        phoneNumbers: ['3071 4922'],
        regionKey: 'indonesia',
      },
      {
        countryCodes: ['IE'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'ukEnglish',
        phoneNumbers: ['01-242-1552'],
        regionKey: 'ireland',
      },
      {
        countryCodes: ['IT'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['02-6968-2817'],
        regionKey: 'italy',
      },
      {
        countryCodes: ['JP'],
        hoursKey: '930amTo530pmMonToFri',
        languageKey: 'japanese',
        phoneNumbers: ['0120-907-633'],
        regionKey: 'japan',
      },
      {
        countryCodes: ['KE', 'MU', 'NG', 'HR'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'ukEnglish',
        phoneNumbers: ['+44-20-7365-0735'],
        regionKey: 'emea',
      },
      {
        countryCodes: ['KR'],
        hoursKey: '9amTo6pmMonToFri',
        languageKey: 'korean',
        phoneNumbers: ['080-949-0880'],
        regionKey: 'korea',
      },
      {
        countryCodes: ['MY'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['1-300-80-0029'],
        regionKey: 'malaysia',
      },
      {
        countryCodes: ['MO'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'cantonese',
        phoneNumbers: ['+852 3071-4922'],
        regionKey: 'seasia',
      },
      {
        countryCodes: ['NL'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['020-201-3331'],
        regionKey: 'netherlands',
      },
      {
        countryCodes: ['NZ'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'ukEnglish',
        phoneNumbers: ['0800-384-473'],
        regionKey: 'newZealand',
      },
      {
        countryCodes: ['NO'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['23 16 28 81'],
        regionKey: 'norway',
      },
      {
        countryCodes: ['PH'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['1-800-1441-0442'],
        regionKey: 'philippines',
      },
      {
        countryCodes: ['PL'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['00-800-441-19-35'],
        regionKey: 'poland',
      },
      {
        countryCodes: ['RU'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['8 800 333 36 63'],
        regionKey: 'russia',
      },
      {
        countryCodes: ['SG'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'ukEnglish',
        phoneNumbers: ['800-448-1643'],
        regionKey: 'singapore',
      },
      {
        countryCodes: ['ES'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['900-822-399'],
        regionKey: 'spain',
      },
      {
        countryCodes: ['LK', 'VN'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'ukEnglish',
        phoneNumbers: ['+65-6622-1432'],
        regionKey: 'seasia',
      },
      {
        countryCodes: ['SE'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['08-5853-6692'],
        regionKey: 'sweden',
      },
      {
        countryCodes: ['CH'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'german',
        phoneNumbers: ['0800-110-345'],
        regionKey: 'switzerland',
      },
      {
        countryCodes: ['TW'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'mandarin',
        phoneNumbers: ['0800-668-958'],
        regionKey: 'taiwan2',
      },
      {
        countryCodes: ['TH'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['001-800-441-8858'],
        regionKey: 'thailand',
      },
      {
        countryCodes: ['TR'],
        hoursKey: '9amTo5pmMonToFri',
        languageKey: 'english',
        phoneNumbers: ['90 212 336 0357'],
        regionKey: 'turkey',
      },
      {
        countryCodes: ['GB'],
        hoursKey: '24x7',
        languageKey: 'ukEnglish',
        phoneNumbers: ['08000-289-094'],
        regionKey: 'unitedKingdom',
      },
      {
        countryCodes: ['CA', 'US'],
        hoursKey: '24x7',
        languageKey: 'usEnglish',
        phoneNumbers: ['1-800-685-3570'],
        regionKey: 'usAndCanada',
      },
      {
        countryCodes: ['MX'],
        hoursKey: '9amTo7pmMonToFri',
        languageKey: 'spanish',
        phoneNumbers: ['01-800-062-2240'],
        regionKey: 'mexico',
      },
      {
        countryCodes: ['PT'],
        hoursKey: '1130amTo9pmMonToFri',
        languageKey: 'portuguese',
        phoneNumbers: ['800-210-168'],
        regionKey: 'emea',
      },
      {
        countryCodes: ['TT'],
        hoursKey: '24x7',
        languageKey: 'usEnglish',
        phoneNumbers: ['800-685-3570'],
        regionKey: 'latam',
      },
      {
        countryCodes: SUPPORT_EMEA_COUNTRIES,
        hoursKey: '24x5',
        languageKey: 'ukEnglish',
        phoneNumbers: ['+44-207-365-0735'],
        regionKey: 'emea',
      },
      {
        countryCodes: SUPPORT_LATAM_COUNTRIES,
        hoursKey: '5amTo7pmMonToFri',
        languageKey: 'usEnglish',
        phoneNumbers: ['1-800-443-8158'],
        regionKey: 'latam',
      },
    ];

    const service = {
      getContactInfo,
      getContactInfoGoUrl,
    };

    return service;

    //////////////

    /**
     * @description Returns the support contact information for the given customer segment and
     *   country. If there is no contact information for the specified country, information for US
     *   is returned.
     *
     *   The returned object contains the following properties:
     *     - {String} hours - the hours that support is available, as a localized string, e.g.
     *         "9 AM - 5 PM (Mon - Fri)"
     *     - {String} language - the language of the support center as a localized string, e.g.
     *         "US English"
     *     - {Array<String>} phoneNumbers: The phone numbers for support, as strings, localized
     *         where necessary. Most are non-localized phone numbers, however some, such as
     *         Chinese phone numbers, contain words like "Netcom" which are localized.
     *     - region: the region the contact information relates to, e.g. "United States & Canada"
     *
     * @param {CUSTOMER_SEGMENT} customerSegment - TEAM or ENTERPRISE
     * @param {String} countryCode - the ISO2 country code
     * @returns {Object} the support contact information, as described above
     */
    function getContactInfo(customerSegment, countryCode) {
      const infoSet =
        customerSegment === CUSTOMER_SEGMENT.TEAM ? TEAM_CONTACT_INFO : ENTERPRISE_CONTACT_INFO;
      const info = lookupInfoSet(infoSet, countryCode) || lookupInfoSet(infoSet, 'US');
      return {
        hours: getTranslation(`hours.${info.hoursKey}`),
        language: getTranslation(`language.${info.languageKey}`),
        phoneNumbers: info.phoneNumbers,
        region: getTranslation(`region.${info.regionKey}`),
      };
    }

    function getContactInfoGoUrl(customerSegment) {
      return customerSegment === CUSTOMER_SEGMENT.TEAM
        ? 'aac_cct_support_numbers'
        : 'ac_other_region_ph_chg';
    }

    function getTranslation(keySuffix, values) {
      return $translate.instant(`widgets.support.supportContactInfo.${keySuffix}`, values);
    }

    function lookupInfoSet(infoSet, countryCode) {
      return _.find(infoSet, (item) => _.includes(item.countryCodes, countryCode));
    }
  }
})();
/* eslint-enable max-lines */
