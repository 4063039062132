(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.contracts:appManagePaymentSupportModal
   * @description modal which instructs customers to contact customer support in order to
   *   edit their payment method. This is shown to Digital River customers.
   */
  angular.module('app.widgets.contracts').component('appManagePaymentSupportModal', {
    controller,
    templateUrl:
      'app/widgets/contracts/manage-payment-support-modal/manage-payment-support-modal.component.html',
  });

  /* @ngInject */
  function controller(_, MANAGE_PAYMENT_SUPPORT_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      MANAGE_PAYMENT_SUPPORT_MODAL_ID,
      supportHref: `/support`,
    });
  }
})();
