import {ActionButton, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import AndroidIcon from '@react/react-spectrum/Icon/Android';
import AppleIcon from '@react/react-spectrum/Icon/Apple';
import DesktopAndMobileIcon from '@spectrum-icons/workflow/DesktopAndMobile';
import DevicePhoneIcon from '@spectrum-icons/workflow/DevicePhone';
import GlobeIcon from '@spectrum-icons/workflow/Globe';
import HomepageIcon from '@spectrum-icons/workflow/Homepage';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const PLATFORM_ICONS = {
  mobile: <DevicePhoneIcon />,
  native: <DesktopAndMobileIcon />,
  oauthandroid: <AndroidIcon />,
  oauthios: <AppleIcon />,
  oauthweb: <GlobeIcon />,
  spa: <HomepageIcon />,
  web: <GlobeIcon />,
};

// Renders the corresponding app integration platform icon with a helpful tooltip
const AppIntegrationsPlatformIcon = ({platform}) => {
  const intl = useIntl();
  return (
    <TooltipTrigger delay={0}>
      <ActionButton
        aria-label={intl.formatMessage({
          id: `products.appIntegrations.appIntegrationsPlatformIcon.label.${platform}`,
        })}
        isQuiet
      >
        {PLATFORM_ICONS[platform]}
      </ActionButton>
      <Tooltip>
        <FormattedMessage
          id={`products.appIntegrations.appIntegrationsPlatformIcon.label.${platform}`}
        />
      </Tooltip>
    </TooltipTrigger>
  );
};

AppIntegrationsPlatformIcon.propTypes = {
  /** The platform string */
  platform: PropTypes.oneOf(Object.keys(PLATFORM_ICONS)),
};

export default AppIntegrationsPlatformIcon;
