(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common.links.text-link
   * @description module that defines the text-link widget
   */
  angular.module('app.widgets.common.links.text-link', [
    'binky.core.lodash',
    'binky.core.translation-utils',
    'pascalprecht.translate',
  ]);
})();
