// This is needed to map the column names to the sorting field.
const FIELD_TO_SORT_EXPRESSION = {
  amount: 'INVOICE_AMOUNT',
  date: 'INVOICE_DATE',
  invoiceDate: 'INVOICE_DATE',
};

const PO_NUMBER_UPDATE_REASON = ['MISSING_PO_NUMBER', 'INCORRECT_PO_NUMBER', 'INVALID_PO_NUMBER'];

const PO_NUMBER_REGEX = /^[\dA-Za-z]{1,35}$/;

const MODAL_NAMES = {
  EDIT_PO_NUMBER: 'editPONumber',
  EMAIL_INVOICES: 'emailInvoices',
};

const INVOICE_TYPES = {CREDIT: 'CREDIT', INVOICE: 'INVOICE', RECEIPT: 'RECEIPT'};

export {
  FIELD_TO_SORT_EXPRESSION,
  INVOICE_TYPES,
  MODAL_NAMES,
  PO_NUMBER_UPDATE_REASON,
  PO_NUMBER_REGEX,
};
