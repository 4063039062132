import {SearchField, Text, View} from '@adobe/react-spectrum';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import EntitlementCard from 'features/packages/components/create-package-modal/screens/choose-entitlements-page/entitlement-card/EntitlementCard';
import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';

const MINIMUM_OFFERS_TO_SHOW_SEARCH = 6;
/**
 * Server Entitlements screen for create/edit server flow
 * it allow user to select/deselect entitlements for the server
 */
const ServerEntitlements = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionData, setServerSessionSettingValues, setServerSessionDataValues} =
    packagesServerStore;
  const intl = useIntl();
  const [offersList, setOffersList] = useState([]);
  const [entitlementNameFilter, setEntitlementNameFilter] = useState('');

  /* populate available entitlements list */
  useEffect(() => {
    const entitlementOffersList = cloneDeep(
      PackagesEntitlementsService.getFrlOffers(rootStore.packagesUiConstantsStore.frlLanUnitCode)
    );

    // In case of edit server flow pre-selecting the entitlements
    if (serverSessionData.selectedLicenses.length > 0) {
      entitlementOffersList.forEach((offer) => {
        serverSessionData.selectedLicenses.forEach((license) => {
          if (offer.offerId === license.offerId) {
            offer.selected = true;
          }
        });
      });
    }
    setServerSessionSettingValues(
      'nextButtonEnabled',
      serverSessionData.selectedLicenses.length > 0
    );

    setOffersList(entitlementOffersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount
  }, []);

  /**
   * searchByNameFilter method to handle search bar
   * contains searchText as substring (case insensitive search)
   * Return offer if search field empty or if it's longName
   */
  function searchByNameFilter(offer) {
    if (entitlementNameFilter === '') {
      return offer;
    }
    const entitlementName = offer.longName.toLowerCase();
    return entitlementName.includes(entitlementNameFilter.toLowerCase());
  }

  /**
   * offerSelectionChanged callback function to handle offer selection change event
   * It set selected offer in serverSessionData also handles next button enable/disabled state
   */
  const offerSelectionChanged = (selectedOffer) => {
    selectedOffer.selected = !selectedOffer.selected;
    const selectedOffers = offersList.filter((offer) => offer.selected === true);
    setServerSessionDataValues('selectedLicenses', selectedOffers);
    setServerSessionSettingValues('nextButtonEnabled', selectedOffers.length > 0);
  };

  return (
    <>
      <Text marginTop="size-200" UNSAFE_style={{fontSize: '16px', fontWeight: 'bold'}}>
        {intl.formatMessage(
          {id: 'packages.createServer.chooseEntitlement.title'},
          {
            selected: serverSessionData.selectedLicenses.length,
            total: offersList.length,
          }
        )}
      </Text>
      <View paddingBottom="size-150">
        {intl.formatMessage({
          id: 'packages.createServer.chooseEntitlement.titleDesc',
        })}
      </View>
      {offersList.length > MINIMUM_OFFERS_TO_SHOW_SEARCH && (
        <SearchField
          aria-label={intl.formatMessage({
            id: 'packages.createServer.chooseEntitlement.searchField.ariaLabel',
          })}
          autoFocus
          label={intl.formatMessage({
            id: 'packages.createServer.chooseEntitlement.searchPlaceHolder',
          })}
          marginBottom="size-100"
          maxLength={50}
          onChange={setEntitlementNameFilter}
        />
      )}

      <div
        aria-label={intl.formatMessage(
          {id: 'packages.createServer.chooseEntitlement.title'},
          {
            selected: serverSessionData.selectedLicenses.length,
            total: offersList.length,
          }
        )}
        role="group"
      >
        {offersList.filter(searchByNameFilter).map((offer, index) => (
          <EntitlementCard
            key={offer.offerId}
            autoFocus={index === 0 && offersList.length <= 6}
            expiryDate={offer.expiryDate}
            imgSrc={offer.getIconUrl()}
            index={index}
            isCardDisabled={offer.disabled || false}
            isCardSelected={offer.selected || false}
            onChange={() => offerSelectionChanged(offer)}
            titleString={offer.longName}
          />
        ))}
      </div>
    </>
  );
});

export default ServerEntitlements;
