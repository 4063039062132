import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

import API_CONSTANTS from 'common/api/ApiConstants';

const CLIENT_ID = API_CONSTANTS.CLIENT_ID;

let url;

/**
 * Configure manage jil team resources. API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL teams api
 */
const configure = (config) => {
  url = `${config.url}/v2/teams`;
};

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Api-Key': CLIENT_ID,
  };
  const token = authentication.getAccessToken()?.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

/**
 * @description To get list of devices which have been deactivated.
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @returns {Promise} Resolves to the axios response object.
 */
const getDeactivatedDevices = ({orgId}) =>
  axios.get(`${url}/${orgId}/package-pools/devices/archived`, {
    headers: getHeaders(),
  });

/**
 * @description To get information about a specific package pool, specifically, the list of running devices and a summary for this pool. The devices will be either activated or over-activated.
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.poolId - The pool id
 * @returns {Promise} Resolves to the axios response object.
 */
const getDevicePackagePoolResource = ({orgId, poolId}) =>
  axios.get(`${url}/${orgId}/package-pools/${poolId}/devices/running`, {
    headers: getHeaders(),
  });

/**
 * @description To get the list of all package pools and a summary for them.
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @returns {Promise} Resolves to the axios response object.
 */
const getDevicePackagePoolsResource = ({orgId}) =>
  axios.get(`${url}/${orgId}/package-pools`, {
    headers: getHeaders(),
  });

/**
 * @description To deactivate one device.
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @param {String} options.poolId - The pool id
 * @param {String} options.deviceId - The device id
 * @returns {Promise} Resolves to the axios response object.
 */
const deactivateDevice = ({deviceId, orgId, poolId}) =>
  // PUT expects post data as second parameters. If only one arg, the first arg is
  // interpreted as data rather than params.
  axios.put(`${url}/${orgId}/package-pools/${poolId}/devices/${deviceId}/deactivate`, undefined, {
    headers: getHeaders(),
  });

const jilTeams = {
  configure,
  deactivateDevice,
  getDeactivatedDevices,
  getDevicePackagePoolResource,
  getDevicePackagePoolsResource,
  getHeaders,
};

export default jilTeams;
