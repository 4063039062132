(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories:azureGroupsTable
   * @description allow management of which Azure groups should be synchronized.
   */
  angular.module('app.widgets.directories.azure').component('appAzureGroupsTable', {
    bindings: {
      authSrcCode: '<',
      hideSaveButton: '<',
      onError: '&?',
      onProcessing: '&?',
      onProcessingDone: '&?',
      onSave: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/directories/azure/azure-groups-table/azure-groups-table.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $element,
    $q,
    $log,
    $scope,
    $timeout,
    directorySync,
    AzureGroup,
    AZURE_GROUPS_ERROR_TYPES,
    AZURE_GROUP_INVALID_REASON,
    AZURE_GROUPS_RETRY_GET_EVENT,
    AZURE_GROUPS_SAVE_EVENT
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      getGroups,
      getToggleLabelKey,
      getTooltipContentKey,
      isInSync,
      onPageChange,
      onPageSizeChange,
      onSearch: _.debounce(onSearch, 200),
      onSyncChange,
      saveGroups,
    });

    // Store groups that have been selected for sync / desync across pages.
    let groupsSelected = [];
    const groupsDeselected = [];

    let searchQuery;

    function $onInit() {
      _.assign(vm, {
        getGroupsError: false,
        groups: [],
        invalidAuthSrcCode: false,
        isProcessing: false,
        isSearchApplied: false,
        itemsPerPage: 10,
        showContentWhileLoading: false,
        waitPromise: $q.resolve(),
      });

      // Make sure processing callbacks are functions to avoid type checking each time.
      vm.onError = vm.onError || angular.noop;
      vm.onProcessing = vm.onProcessing || angular.noop;
      vm.onProcessingDone = vm.onProcessingDone || angular.noop;
      vm.onSave = vm.onSave || angular.noop;

      getGroups();
      setupEventHandlers();
    }

    function setupEventHandlers() {
      $scope.$on(AZURE_GROUPS_RETRY_GET_EVENT, getGroups);
      $scope.$on(AZURE_GROUPS_SAVE_EVENT, saveGroups);
    }

    function setProcessingState() {
      vm.isProcessing = true;
      vm.onProcessing();
    }
    function unsetProcessingState() {
      vm.isProcessing = false;
      vm.onProcessingDone();
    }

    function onPageChange(direction) {
      if (direction > 1 && hasNextPage()) {
        currentPage += 1;
        getGroups();
      } else if (direction < 1 && hasPreviousPage()) {
        currentPage -= 1;
        getGroups();
      }
    }

    function onPageSizeChange(val) {
      vm.itemsPerPage = _.parseInt(val);
      resetPagination();
      getGroups();
    }

    // Called when a group's `binky-toggle` changes state.
    // Keeps `groupsSelected` up to date.
    function onSyncChange(changedGroup) {
      // If change happened on an already linked group, remove it from the linked list
      // and add it to the unlinked list.
      // Otherwise, do the opposite.
      if (_.find(groupsSelected, {id: changedGroup.id})) {
        _.remove(groupsSelected, {id: changedGroup.id});

        // Add group to list of groups to unlink only if it's linked.
        // This avoids adding groups that have been selected then deselected on the UI only.
        if (changedGroup.isLinked) {
          groupsDeselected.push(changedGroup);
        }
      } else {
        _.remove(groupsDeselected, {id: changedGroup.id});
        groupsSelected.push(changedGroup);
      }
    }

    // Reset list of deselected groups after it was sent to API.
    function resetGroupsDeselected() {
      groupsDeselected.length = 0;
    }

    function onSearch(query) {
      if (!query || !_.isString(query)) {
        searchQuery = undefined;
        vm.isSearchApplied = false;
        resetPagination();
        getGroups();
      } else if (query !== searchQuery) {
        searchQuery = query;
        vm.isSearchApplied = true;
        resetPagination();
        getGroups();
      }

      // Tell Angular something changed since we're being called by lodash's _.debounce.
      $scope.$digest();
    }

    function getGroups() {
      if (vm.authSrcCode) {
        setProcessingState();
        vm.waitPromise = directorySync.groups
          .get({
            authSrcCode: vm.authSrcCode,
            groupNamePrefix: searchQuery,
            nextPageToken: pageTokens[currentPage],
            pageSize: vm.itemsPerPage,
          })
          .$promise.then((groupPage) => {
            vm.showContentWhileLoading = true; // Show data under binky-wait now that we have it.
            vm.invalidAuthSrcCode = false;
            vm.getGroupsError = false;

            // Store next page token.
            addPageToken(groupPage.nextPageToken);

            // Expose data to view.
            const groups = groupPage.data;
            vm.groups = _.map(groups, (group) => new AzureGroup(group));

            // Detect groups that are already in sync.
            // Store linked groups uniquely by group ID.
            groupsSelected.length = 0; // Flush array as to not preserve data from previous get calls.
            groupsSelected = _.unionBy(groupsSelected, _.filter(vm.groups, {isLinked: true}), 'id');

            // Now that we have data, enable pagination buttons.
            // Using timeout because pagination component is being mounted during this cycle
            // so buttons don't yet exist.
            $timeout(enablePaginationButtons);
          })
          .catch((error) => {
            $log.error('[AD Sync] Error fetching groups.', error);

            vm.getGroupsError = true;
            vm.onError({err: new Error(AZURE_GROUPS_ERROR_TYPES.FETCH_ERROR)});

            return $q.reject(error);
          })
          .finally(unsetProcessingState);

        return vm.waitPromise;
      }

      vm.invalidAuthSrcCode = true;
      vm.onError({err: new Error(AZURE_GROUPS_ERROR_TYPES.FETCH_ERROR)});
      return $q.reject();
    }

    function saveGroups() {
      setProcessingState();

      // Only send selected groups that are free. Linked ones are already linked.
      const freeSelectedGroups = _.filter(groupsSelected, {isFree: true});

      const linkGroupsPromise =
        freeSelectedGroups.length > 0
          ? directorySync.linkGroups.save(
              {authSrcCode: vm.authSrcCode},
              _.map(freeSelectedGroups, 'entity')
            ).$promise
          : $q.resolve();

      const unlinkGroupsPromise =
        groupsDeselected.length > 0
          ? directorySync.unlinkGroups.save(
              {authSrcCode: vm.authSrcCode},
              _.map(groupsDeselected, 'id')
            ).$promise
          : $q.resolve();

      vm.waitPromise = $q
        // Adding `$timeout(500)` so that if no changes were made there is a brief loading period.
        .all([linkGroupsPromise, unlinkGroupsPromise, $timeout(500)])
        .then(getGroups)
        .then(() => {
          // Reset deselected groups after API success.
          resetGroupsDeselected();

          // Unset processing state before triggering `onSave()`.
          unsetProcessingState();

          vm.onSave();
        })
        .catch((error) => {
          $log.error('[AD Sync] Error saving groups.', error);

          unsetProcessingState();
          vm.onError({err: new Error(AZURE_GROUPS_ERROR_TYPES.SUBMIT_ERROR)});
        });
    }

    // Check if given group is in list of linked groups to be sent to API on submit.
    function isInSync(group) {
      return !!_.find(groupsSelected, {id: group.id});
    }

    function getTooltipContentKey(group) {
      if (group.invalidReason === AZURE_GROUP_INVALID_REASON.NAME_CLASH) {
        return '.notAvailableReasons.nameClash';
      } else if (
        group.invalidReason === AZURE_GROUP_INVALID_REASON.NAME_CONTAINS_INVALID_CHARACTERS
      ) {
        return '.notAvailableReasons.invalidCharacters';
      }

      return '.notAvailableReasons.unknown';
    }

    function getToggleLabelKey(group) {
      if (group.isInvalid) {
        return '.notAvailable';
      } else if (isInSync(group)) {
        return '.inSync';
      }

      return '.notSynced';
    }

    //////////////

    // Azure groups pagination is token-based.
    // First page has no token, each API response sends a `nextPageToken`
    // to be used for getting the next page until that token is empty.
    const pageTokens = [undefined]; // Store page tokens in order to enable pagination.
    let currentPage = 0; // First page has no token, hence the initial `undefined` entry.
    function addPageToken(token) {
      const nextPageToken = token;
      if (nextPageToken && !_.includes(pageTokens, nextPageToken)) {
        pageTokens.push(nextPageToken);
      }
    }

    // Called if search is applied or number of items per page changes.
    function resetPagination() {
      // Reset `pageTokens` array.
      pageTokens.length = 0;
      pageTokens.push(undefined);
      currentPage = 0;
    }

    // Check if there is a page to the right or left of current page.
    function hasNextPage() {
      return currentPage < pageTokens.length - 1;
    }
    function hasPreviousPage() {
      return currentPage > 0;
    }

    // Enable prev and next pagination buttons.
    // This is needed to enable reuse of binkyPaginationControlBar with Azure pagination model
    // where we don't use tokens to navigate.
    function enablePaginationButtons() {
      const leftButton = $element[0].querySelector('binky-pagination-control-bar button.left');
      const rightButton = $element[0].querySelector('binky-pagination-control-bar button.right');

      if (leftButton) {
        leftButton.disabled = false;
      }
      if (rightButton) {
        rightButton.disabled = false;
      }
    }
  }
})();
