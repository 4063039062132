import {Flex, Header, Link, Text} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {useIntl} from 'react-intl';

import EditSubContent from '../../../edit-sub-content/EditSubContent';
import {expandableGroupPropType} from '../../../prop-types/configurationGroupsPropTypes';

const ExpandablePermissionsForm = ({group, onChange}) => {
  const intl = useIntl();

  const onSubContentChange = (newSubContent, newAutoInclude) => {
    onChange({
      ...group,
      content: group.content.map((subContent) => ({
        ...(subContent.id === newSubContent.id ? newSubContent : subContent),
      })),
      ...('allSelected' in group && {allSelected: newAutoInclude}),
    });
  };

  return group.content.map((subContent) => (
    <Fragment key={subContent.id}>
      {subContent.name && (
        <Header level={4} UNSAFE_style={{fontWeight: 'bold'}}>
          {subContent.name}
        </Header>
      )}
      {subContent.description && <Text>{subContent.description}</Text>}
      {subContent.description && subContent.linkHref && ' '}
      {subContent.linkHref && (
        <Link marginX="size-40">
          <a href={subContent.linkHref} rel="noreferrer" target="_blank">
            <Flex
              alignItems="center"
              direction="row"
              gap="size-50"
              UNSAFE_style={{display: 'inline-flex'}}
            >
              {intl.formatMessage(
                {
                  id: subContent.name
                    ? 'products.productProfilePermissions.editPermissionsModal.learnMoreAbout'
                    : 'products.productProfilePermissions.editPermissionsModal.learnMoreUnknown',
                },
                {name: subContent.name}
              )}
              <LinkOutIcon
                aria-label={intl.formatMessage({
                  id: 'products.productProfilePermissions.editPermissionsModal.opensWindow',
                })}
                flexShrink={0}
                size="XS"
              />
            </Flex>
          </a>
        </Link>
      )}
      <EditSubContent
        allowAutoInclude={'allSelected' in group}
        autoInclude={'allSelected' in group && group.allSelected}
        groupHelpLink={group.linkHref}
        groupReadOnly={'editable' in group && !group.editable}
        onChange={onSubContentChange}
        subContent={subContent}
      />
    </Fragment>
  ));
};

ExpandablePermissionsForm.propTypes = {
  /**
   * The group to edit.
   */
  group: expandableGroupPropType.isRequired,

  /**
   * Callback when the group is changed. This takes an argument of the new group.
   */
  onChange: PropTypes.func.isRequired,
};

export default ExpandablePermissionsForm;
