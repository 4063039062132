import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import PackagesServerStore from 'features/packages/stores/PackagesServerStore';

/**
 * Private Context for the Create Server Modal and its sub-components.
 * Used to pass the packagesServerStore between sub-components.
 */

const CreateServerModalContext = createContext(null);
const useCreateServerModalContext = () => useContext(CreateServerModalContext);

const CreateServerModalContextProvider = ({children, packagesServerStore}) => {
  const value = useMemo(() => ({packagesServerStore}), [packagesServerStore]);

  return (
    <CreateServerModalContext.Provider value={value}>{children}</CreateServerModalContext.Provider>
  );
};

CreateServerModalContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node.isRequired,
  /**
   * The domain store for Servers.
   */
  packagesServerStore: PropTypes.instanceOf(PackagesServerStore).isRequired,
};

export {CreateServerModalContextProvider, useCreateServerModalContext};
