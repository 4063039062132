/**
 * @deprecated with temp_react_port_users
 *
 * @ngdoc component
 * @name app.widgets.users:appRemoveUserStorageOptionsSection
 *
 * @description The section for remove user storage modal, display the options to perform when user
 *              is removed.
 *
 * @binding {Function} onOptionChange, function that getting called when option has changed
 * @binding {Function} onUserChange, function that getting called when user has changed
 * @binding {REMOVE_USER_STORAGE_OPTIONS} option, the current option
 * @binding {PageContext} pageContext, passed into user-autocomplete for configuration purpose
 * @binding {Array} usersToBeRemoved, passed into user-autocomplete, to prevent those users getting
 *                  selected
 */
(function () {
  angular.module('app.widgets.users').component('appRemoveUserStorageOptionsSection', {
    bindings: {
      onOptionChange: '<',
      onUserChange: '<',
      option: '<',
      pageContext: '<',
      usersToBeRemoved: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/remove-user-storage-modal/options-section/remove-user-storage-options-section.component.html',
  });

  /* @ngInject */
  function controller($element, $translate, _, REMOVE_USER_STORAGE_OPTIONS) {
    const vm = this;

    _.assign(vm, {
      $doCheck,
      $onInit,
    });

    function $doCheck() {
      if (!vm.shareStr) {
        const shareRadioButton = $element[0].querySelectorAll('.radio-btn-share')[0];
        if (shareRadioButton) {
          shareRadioButton.innerText = $translate.instant(
            'widgets.users.removeUserStorageModal.options.share.title'
          );
          vm.shareStr = true;
        }
      }

      if (!vm.discardStr) {
        const discardRadioButton = $element[0].querySelectorAll('.radio-btn-discard')[0];
        if (discardRadioButton) {
          discardRadioButton.innerText = $translate.instant(
            'widgets.users.removeUserStorageModal.options.discard.title'
          );
          vm.discardStr = true;
        }
      }

      if (!vm.deleteStr) {
        const deleteRadioButton = $element[0].querySelectorAll('.radio-btn-delete')[0];
        if (deleteRadioButton) {
          deleteRadioButton.innerText = $translate.instant(
            'widgets.users.removeUserStorageModal.options.delete.title'
          );
          vm.deleteStr = true;
        }
      }
    }

    function $onInit() {
      _.assign(vm, {
        REMOVE_USER_STORAGE_OPTIONS,
      });
    }
  }
})();
