import {
  DIRECTORY_OWNERSHIP_STATUS,
  DOMAIN_STATUS,
  DomainList,
  LicenseGroupList,
  MIGRATION_TYPE,
  MemberConfigurationRoles,
  MigrationList,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  ProductList,
  feature,
  hasOnlyTeamProducts,
  isMigrationListT2eClean,
  log,
} from '@admin-tribe/binky';
import {useAsyncModel} from '@admin-tribe/binky-ui';
import sortBy from 'lodash/sortBy';
import {toJS} from 'mobx';
import {useCallback, useEffect, useState} from 'react';

import {canCreateType2Or3} from 'core/directories/access/directoryAccess';

import {
  BULK_OPERATION_MODE,
  CSV_HEADER,
  CSV_STANDARD_TEMPLATES,
} from '../bulk-operation-utils/bulkOperationConstants';

const blobOptions = {type: 'text/csv'};

// An undefined variable that signifies if a state is required
// in order to prepare the standard sample templates
const INITIAL_UNDEFINED = undefined;

/**
 * @description Hooks the bulk operation sample state into your function.
 *
 * @param {String} orgId - The org id
 * @param {String|BULK_OPERATION_MODE} mode - The bulk operation mode
 * @param {Object|PageContext} pageContext - The PageContext to provide user's state
 * @returns {Object} with below fields
 * @returns {Blob} sampleTemplate - the Blob of the standard CSV template
 * @returns {Array<Domain>} - the list of ACTIVE or TRUSTED domains for download templates
 */
const useBulkOperationSamples = ({mode, orgId, pageContext}) => {
  const [sampleTemplate, setSampleTemplate] = useState();
  const [domains, setDomains] = useState();

  // Flag for excluding T1 users in samples
  const [excludeT1Users, setExcludeT1Users] = useState(INITIAL_UNDEFINED);

  const [isT2EClean, setIsT2EClean] = useState(INITIAL_UNDEFINED);

  // TODO: Replace with RootStore when it is ready
  const fetchMigrationList = useCallback(() => {
    const types = [
      MIGRATION_TYPE.ESM_TYPE1,
      MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE,
      MIGRATION_TYPE.VIP2DIRECT,
      MIGRATION_TYPE.VIPMP,
      MIGRATION_TYPE.T2E,
    ];
    return MigrationList.get({orgId, types});
  }, [orgId]);
  const {model: migrationList, error: migrationListError} = useAsyncModel({
    initState: INITIAL_UNDEFINED,
    loadFn: fetchMigrationList,
  });

  // TODO: Replace with RootStore when it is ready
  const fetchProductList = useCallback(() => ProductList.get({orgId}), [orgId]);
  const {model: productList, error: productListError} = useAsyncModel({
    initState: INITIAL_UNDEFINED,
    loadFn: fetchProductList,
  });

  const fetchDomains = useCallback(() => {
    if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE) {
      return DomainList.get({
        orgId,
        pageSize: 100,
      });
    }
    return undefined;
  }, [mode, orgId]);
  const {
    model: domainList,
    isLoading: isLoadingDomains,
    error: domainListError,
  } = useAsyncModel({
    loadFn: fetchDomains,
  });

  const loadLicenseGroupList = useCallback(() => {
    if (
      pageContext.targetType === PAGE_TARGET_TYPE.USER &&
      pageContext.target === PAGE_TARGET.PRODUCT &&
      productList
    ) {
      const product = productList.items.find((p) => p.id === pageContext.targetId);
      if (product?.isTeam()) {
        return LicenseGroupList.get({
          orgId,
          product,
        });
      }
    }
    return undefined;
  }, [orgId, pageContext, productList]);

  const {model: licenseGroupList, isLoading: isLicenseGroupListLoading} = useAsyncModel({
    loadFn: loadLicenseGroupList,
  });

  const fetchMemberConfigRoles = useCallback(() => {
    if (productList && pageContext.targetType === PAGE_TARGET_TYPE.USER) {
      let product, profileId;

      if (pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
        product = productList.items.find((p) => p.id === pageContext.targetParentId);
        profileId = pageContext.targetId;
      } else if (pageContext.target === PAGE_TARGET.PRODUCT && licenseGroupList) {
        product = productList.items.find((p) => p.id === pageContext.targetId);
        // when target === product, we fall into the Team product case.
        // So then use the profile id of its only license group
        profileId = licenseGroupList.items[0].id;
      }

      if (product?.hasConfigurationSettingForLicenseGroupMember()) {
        return MemberConfigurationRoles.get({
          licenseGroupId: profileId,
          memberIds: [],
          orgId,
          productId: product.id,
        });
      }
    }
    return undefined;
  }, [licenseGroupList, orgId, pageContext, productList]);
  const {
    model: memberConfigRoles,
    isLoading: isLoadingMemberConfigRoles,
    error: memberConfigRolesError,
  } = useAsyncModel({
    loadFn: fetchMemberConfigRoles,
  });

  // Filter out and set the domains when domainList is ready
  useEffect(() => {
    if (domainList) {
      const validDomains = domainList.items.filter(
        (domain) =>
          // Only return domains that are active or trusted
          domain.status === DOMAIN_STATUS.ACTIVE ||
          domain.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED
      );
      setDomains(validDomains);
    }
  }, [domainList]);

  // Updates isT2EClean when migrationList exists
  useEffect(() => {
    if (migrationList) {
      setIsT2EClean(isMigrationListT2eClean(migrationList));
    }
  }, [migrationList]);

  // Update excludeT1Users flag accordingly
  useEffect(() => {
    let isMounted = true;

    const checkCanCreateType2Or3 = async () => {
      const restrictAddingT1 = await canCreateType2Or3();
      if (restrictAddingT1) {
        // While we don't want to show T1 sample users if the restrictAddingT1 flag is true,
        // we should let T1 users appear in samples for removal actions.
        const modeIsRemovingUsers =
          mode === BULK_OPERATION_MODE.REMOVE || mode === BULK_OPERATION_MODE.UNASSIGN;

        if (isMounted) {
          setExcludeT1Users(restrictAddingT1 && !modeIsRemovingUsers);
        }
      } else if (isMounted) {
        setExcludeT1Users(false);
      }
    };

    checkCanCreateType2Or3();

    return () => {
      isMounted = false;
    };
  }, [mode]);

  // Sets sampleTemplate based on pageContext
  useEffect(() => {
    // Don't prepare sample templates unless below states are ready
    // eslint-disable-next-line complexity -- wait on fetching models
    const isReadyToPrepareSamples = () => {
      // If there are more than one error, just log the first
      const err =
        migrationListError || productListError || memberConfigRolesError || domainListError;
      if (err) {
        log.error('Failed to fetch required states for bulk operation sample templates.', err);
        return false;
      }

      // We only care about the loading states of optional fetches
      const isLoadingOptionalStates =
        isLoadingMemberConfigRoles || isLoadingDomains || isLicenseGroupListLoading;

      return (
        !isLoadingOptionalStates &&
        // Required states below that need to exist before we can prepare samples
        productList &&
        migrationList &&
        isT2EClean !== INITIAL_UNDEFINED &&
        excludeT1Users !== INITIAL_UNDEFINED
      );
    };

    if (isReadyToPrepareSamples()) {
      let template = '';
      switch (pageContext.targetType) {
        case PAGE_TARGET_TYPE.USER:
          switch (pageContext.target) {
            case PAGE_TARGET.ORGANIZATION:
              if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE) {
                template = getSwitchIdentityTypeSampleCSV();
                setSampleTemplate(new Blob([template], blobOptions));
              } else {
                template = getOrgUsersSampleCSV();
                setSampleTemplate(new Blob([template], blobOptions));
              }
              break;
            case PAGE_TARGET.USER_GROUP:
              template = getUserGroupUsersSampleCSV();
              setSampleTemplate(new Blob([template], blobOptions));
              break;
            case PAGE_TARGET.DIRECTORY:
              template = getDirectoryUsersSampleCSV();
              setSampleTemplate(new Blob([template], blobOptions));
              break;
            case PAGE_TARGET.PRODUCT:
              // Entering this case means this is a Team Product
              // so we will use its only license group for bulk op state
              template = getProfileUsersSampleCSV();
              setSampleTemplate(new Blob([template], blobOptions));
              break;
            case PAGE_TARGET.PRODUCT_CONFIGURATION:
              template = getProfileUsersSampleCSV();
              setSampleTemplate(new Blob([template], blobOptions));
              break;
            default:
              // other targets are not yet supported
              break;
          }
          break;
        case PAGE_TARGET_TYPE.USER_GROUP:
          template = getUserGroupSampleCSV();
          setSampleTemplate(new Blob([template], blobOptions));
          break;
        default:
          // other target types are not yet supported
          break;
      }
    }

    function getOrgUsersSampleCSV() {
      if (hasOnlyTeamProducts(productList)) {
        const headers = CSV_HEADER.ORG_USERS_TEAM;
        let users = '';
        productList?.items.forEach((product, index) => {
          users += `${getOrgUsersTeamSampleRow(product, index + 1)}\n`;
        });
        return `${headers}\n${users}`;
      }

      if (feature.isEnabled('temp_contract_admin_role')) {
        if (excludeT1Users) {
          return CSV_STANDARD_TEMPLATES.ORG_USERS_RESTRICT_T1;
        }
        return isT2EClean ? CSV_STANDARD_TEMPLATES.ORG_USERS_T2E : CSV_STANDARD_TEMPLATES.ORG_USERS;
      }

      // remove these with temp_contract_admin_role
      if (excludeT1Users) {
        return CSV_STANDARD_TEMPLATES.ORG_USERS_RESTRICT_T1_WITHOUT_CONTRACTS;
      }
      return isT2EClean
        ? CSV_STANDARD_TEMPLATES.ORG_USERS_T2E_WITHOUT_CONTRACTS
        : CSV_STANDARD_TEMPLATES.ORG_USERS_WITHOUT_CONTRACTS;
    }

    function getOrgUsersTeamSampleRow(product, index) {
      return [`user${index}@my-domain.com`, 'Jane', 'Doe', '', '', product.longName].join(',');
    }

    function getUserGroupUsersSampleCSV() {
      if (excludeT1Users) {
        return CSV_STANDARD_TEMPLATES.USER_GROUP_USERS_RESTRICT_T1;
      }

      return isT2EClean
        ? CSV_STANDARD_TEMPLATES.USER_GROUP_USERS_T2E
        : CSV_STANDARD_TEMPLATES.USER_GROUP_USERS;
    }

    function getDirectoryUsersSampleCSV() {
      return feature.isEnabled('temp_contract_admin_role')
        ? CSV_STANDARD_TEMPLATES.ORG_USERS
        : CSV_STANDARD_TEMPLATES.ORG_USERS_WITHOUT_CONTRACTS;
    }

    function getProfileUsersSampleCSV() {
      let csvSample;
      const availableRoles = sortBy(toJS(memberConfigRoles?.availableRoles), ['name']);

      if (excludeT1Users) {
        csvSample = CSV_STANDARD_TEMPLATES.PROFILE_USERS_RESTRICT_T1;
      } else if (isT2EClean) {
        csvSample = CSV_STANDARD_TEMPLATES.PROFILE_USERS_T2E;
      } else {
        csvSample = CSV_STANDARD_TEMPLATES.PROFILE_USERS;
      }

      if (availableRoles?.length !== 0) {
        const regExp = /{PRODUCT_ROLE}/g;
        return csvSample.WITH_ROLES.replace(regExp, availableRoles[0].name);
      }
      return csvSample.WITHOUT_ROLES;
    }

    function getUserGroupSampleCSV() {
      if (excludeT1Users) {
        return CSV_STANDARD_TEMPLATES.USER_GROUP_RESTRICT_T1;
      }

      return isT2EClean ? CSV_STANDARD_TEMPLATES.USER_GROUP_T2E : CSV_STANDARD_TEMPLATES.USER_GROUP;
    }

    function getSwitchIdentityTypeSampleCSV() {
      if (isT2EClean) {
        return CSV_STANDARD_TEMPLATES.SWITCH_IDENTITY_TYPE_T2E;
      }
      return excludeT1Users
        ? CSV_STANDARD_TEMPLATES.SWITCH_IDENTITY_TYPE_RESTRICT_T1
        : CSV_STANDARD_TEMPLATES.SWITCH_IDENTITY_TYPE;
    }
  }, [
    domainListError,
    excludeT1Users,
    isLicenseGroupListLoading,
    isLoadingDomains,
    isLoadingMemberConfigRoles,
    isT2EClean,
    licenseGroupList,
    memberConfigRoles,
    memberConfigRolesError,
    migrationList,
    migrationListError,
    mode,
    pageContext,
    productList,
    productListError,
  ]);

  return {
    domains,
    sampleTemplate,
  };
};

export default useBulkOperationSamples;
