import cloneDeep from 'lodash/cloneDeep';

import StorageQuota from '../storage-quota/StorageQuota';
import STORAGE_QUOTA_CONSTANTS from '../storage-quota/StorageQuotaConstants';

const {TYPE} = STORAGE_QUOTA_CONSTANTS;

class StorageReportFolder {
  /**
   * @description Creates a new StorageReportFolder for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.id A StorageReportFolder's ID
   * @param {String} options.name A StorageReportFolder's name
   * @param {String} options.path the path of the StorageReportFolder
   * @param {StorageQuota} options.quota the storage quota of the StorageReportFolder
   * @param {Date} options.repositoryCreatedDate The creation date of the StorageReportFolder
   * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageReportFolder
   * @param {String} options.state the state of the StorageReportFolder
   * @param {String} options.type the type of the file which StorageReportFolder is stored
   */
  constructor(options = {}) {
    const clonedOptions = cloneDeep(options);
    Object.assign(this, clonedOptions, {
      quota: new StorageQuota({enforcement: TYPE.HARD, ...options.quota}),
    });

    // The name send back from the server could be encoded
    if (this.name) {
      this.name = decodeURI(this.name);
    }
  }
}

export default StorageReportFolder;
