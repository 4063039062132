import {AlertDialog, Button, Content, DialogTrigger, Flex, Text} from '@adobe/react-spectrum';
import {useListContainerContext} from '@pandora/react-list-container';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useServerPageContext} from 'features/packages/pages/servers-page/ServerPageContext';

/**
 * RemoveServerButton component to launch remove server popover
 */
const RemoveServerButton = observer(() => {
  const intl = useIntl();
  const {listContainerUtils} = useListContainerContext();
  const {packagesServerStore} = useServerPageContext();

  const removeSelectedPackages = (close) => {
    packagesServerStore.deleteSelectedServers(packagesServerStore.selectedServers, false);
    packagesServerStore.setSelectedServers([]);
    listContainerUtils.setSelectedItemKeys([]);
    close();
  };

  const dialog = (close, serverList) => (
    <AlertDialog
      cancelLabel={intl.formatMessage({id: 'packages.servers.removeServers.cancelButton'})}
      onCancel={close}
      onPrimaryAction={() => removeSelectedPackages(close)}
      primaryActionLabel={intl.formatMessage({id: 'packages.servers.removeServers.removeButton'})}
      title={intl.formatMessage(
        {
          id: 'packages.servers.removeServers.title',
        },
        {
          count: serverList.length,
        }
      )}
      variant="destructive"
    >
      <Content>
        <Flex direction="column">
          <Text>
            {intl.formatMessage(
              {id: 'packages.servers.removeServers.description'},
              {count: serverList.length}
            )}
          </Text>
          <Text marginTop="size-150">
            {intl.formatMessage(
              {id: 'packages.servers.removeServers.confirmationMessage'},
              {count: serverList.length}
            )}
          </Text>
        </Flex>
        <ul>
          {serverList.map((server) => (
            <li key={server.serverId}>
              <strong>{server.name}</strong>
            </li>
          ))}
        </ul>
      </Content>
    </AlertDialog>
  );
  return (
    <DialogTrigger>
      <Button
        data-testid="remove-servers-button"
        fill
        isDisabled={packagesServerStore.selectedServers.length === 0}
        variant="negative"
      >
        {intl.formatMessage({id: 'packages.servers.removeServers.removeButton'})}
      </Button>
      {(close) => dialog(close, packagesServerStore.selectedServers)}
    </DialogTrigger>
  );
});

RemoveServerButton.propTypes = {};

export default RemoveServerButton;
