/* eslint-disable @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- conditional CSS from sophia API*/
import {INTERACTION, hasDomain, log} from '@admin-tribe/binky';
import {ActionButton, Button, ButtonGroup, Heading, Link, Text, View} from '@adobe/react-spectrum';
import CloseIcon from '@spectrum-icons/workflow/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import useGlobalModal from 'common/hooks/useGlobalModal';
import SophiaCard from 'common/services/sophia/SophiaCard';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

import styles from './SophiaCardView.pcss';
import {
  getClassesForCard,
  getContentObject,
  getCtaClickEventCallback,
  getPrimaryCTAButtonVariant,
  getSecondaryCTAButtonVariant,
  getStyleObj,
} from './sophiaCardUtils';

const HELP_SEARCH_ALLOWLIST_DOMAINS = ['adobe.com', 'frame.io'];

/**
 * @description Renders a Sophia banner with default styling, and styling applied from the sophia API response
 */
const SophiaCardView = ({card, onDismiss}) => {
  const intl = useIntl();

  const isValidAdobeUrl = (url) => {
    if (url) {
      if (HELP_SEARCH_ALLOWLIST_DOMAINS.some((domain) => hasDomain(url, domain))) {
        return true;
      }
      log.error(`Invalid non-Adobe domain: ${url}`);
    } else {
      log.error('Sophia card given undefined or empty URL');
    }
    return false;
  };

  const shouldShowButton = (label, url) => label && isValidAdobeUrl(url);

  const cardContent = getContentObject(card.getCard());
  const cardContext = `campaign-id-${card.getCampaignId()}`;
  const cardClasses = getClassesForCard(card);
  const primaryCTACallback = getCtaClickEventCallback(card.getCard().actionURL);
  const primaryCTAVariant = getPrimaryCTAButtonVariant(card);
  const secondaryCTACallback = getCtaClickEventCallback(card.getCard().eventAction);
  const secondaryCTAVariant = getSecondaryCTAButtonVariant(card);
  const showPrimaryButton = shouldShowButton(card.getCard().ctaLabel, card.getCard().actionURL);
  const showSecondaryButton = shouldShowButton(
    card.getCard().secondaryCTALabel,
    card.getCard().eventAction
  );

  const [ModalComponent, setModalComponent] = useState(null);
  const {Modal, modalProps, openModal} = useGlobalModal(ModalComponent);
  const style = useMemo(() => getStyleObj(card.getCard()), [card]);

  const dismissCard = () => {
    onDismiss?.(card);

    sophiaHelper.sendInteractionAnalytics({
      action: INTERACTION.DISMISS,
      card,
    });
  };

  const getDescription = () => {
    const values = {
      link: (str) => (
        <Link>
          <a href={cardContent.eventData.learnMoreHref} rel="noreferrer" target="_blank">
            {str}
          </a>
        </Link>
      ),
    };

    // pass in a fake id, so that the defaultMessage is used as fallback,
    // which is really just our already-translated sophia string.
    // We're just using intl as a tool to inject links
    return intl.formatMessage(
      {defaultMessage: cardContent.displayText, id: 'hva.description'},
      values
    );
  };

  const onPrimaryCta = () => {
    setModalComponent(primaryCTACallback?.());
    openModal();
    sophiaHelper.sendInteractionAnalytics({
      action: INTERACTION.PRIMARY,
      card,
    });
  };

  const onSecondaryCta = () => {
    secondaryCTACallback?.();
    sophiaHelper.sendInteractionAnalytics({
      action: INTERACTION.SECONDARY,
      card,
    });
  };

  return (
    <View
      // gainsight tracking context
      data-context={cardContext}
      UNSAFE_className={classNames(cardClasses.map((className) => styles[className]))}
      UNSAFE_style={style.global}
    >
      <View UNSAFE_className={styles['dismiss-sophia-button']}>
        <ActionButton
          aria-label={intl.formatMessage({
            id: 'overview.promotions.card.dismiss',
          })}
          height="size-200"
          isQuiet
          minWidth="size-200"
          onPress={dismissCard}
          variant="primary"
          width="size-200"
        >
          <CloseIcon size="XS" />
        </ActionButton>
      </View>
      <View UNSAFE_className={`${styles['card-section']} ${styles['title-section']}`}>
        <Heading
          level={2}
          marginBottom="size-85"
          UNSAFE_className={`${styles['card-text']}`}
          UNSAFE_style={style.bodyCopy}
        >
          {cardContent.cardLabel}
          {cardContent.bodyCopy}
        </Heading>
      </View>
      <View
        UNSAFE_className={`${styles['card-section']} ${styles['card-text']} ${styles['content-section']}`}
        UNSAFE_style={style.displayText}
      >
        <Text>{getDescription()}</Text>
      </View>
      <View
        UNSAFE_className={`${styles['card-section']} ${styles['button-section']}`}
        UNSAFE_style={style.cta}
      >
        <ButtonGroup>
          {showPrimaryButton && (
            <Button data-testid="banner-cta" onPress={onPrimaryCta} variant={primaryCTAVariant}>
              {cardContent.ctaLabel}
            </Button>
          )}
          {showSecondaryButton && (
            <Button
              data-testid="banner-secondary-cta-button"
              onPress={onSecondaryCta}
              UNSAFE_className={`${styles['secondary-cta-button']} ${styles['sophia-cta']}`}
              variant={secondaryCTAVariant}
            >
              {cardContent.secondaryCTALabel}
            </Button>
          )}
        </ButtonGroup>
      </View>
      {Modal && <Modal {...modalProps} />}
    </View>
  );
};

SophiaCardView.propTypes = {
  card: PropTypes.oneOfType([PropTypes.instanceOf(SophiaCard), PropTypes.object]),
  onDismiss: PropTypes.func,
};

export default SophiaCardView;
/* eslint-enable @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- conditional CSS from sophia API */
