import binkyUI from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';

import AppConstants from 'common/services/AppConstants';
import {canAssignUser} from 'core/organizations/access/organizationAccess';

const AddUserFormContextProvider = binkyUI.common.components.addUser.AddUserFormContext;
const UserPickerContextProvider = binkyUI.common.components.UserPickerContext;

// Shared class to consolidate logic for calculate various props
// needed for the AddUserFormTable.

const AddUserFormTableWrapper = ({children, orgId}) => {
  const canAddUser = React.useMemo(() => canAssignUser(orgId), [orgId]);

  return (
    <AddUserFormContextProvider defaultCountryCode={AppConstants.countryCode}>
      <UserPickerContextProvider canAddUser={canAddUser}>{children}</UserPickerContextProvider>
    </AddUserFormContextProvider>
  );
};

AddUserFormTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default AddUserFormTableWrapper;
