import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS} from '@admin-tribe/binky';
import {PageBannerConstants} from '@admin-tribe/binky-ui';
import {differenceInDays} from 'date-fns';

/**
 * @description
 * Return the variant of the banner based on
 * how many days are left until Domain Enforcement is activated
 */
const getBannerVariant = (activationDate) => {
  const currentDate = new Date();
  const daysUntilActivation = differenceInDays(
    // set the minutes,seconds and milliseconds to 00:00:00
    // differenceInDays considers only full days so 1 minute after midnight means one less day
    new Date(activationDate).setMinutes(0, 0, 0),
    currentDate
  );

  // with more than 15 days before the activation date
  // the banner will be shown in info variant
  if (daysUntilActivation > 15) {
    return PageBannerConstants.variant.INFO;
  }

  // with less than 15 days before the activation date
  // the banner will be shown in warning variant
  return PageBannerConstants.variant.WARNING;
};

/**
 * @description Checks if Domain Enforcement is enabled (ACTIVATED or ENFORCED)
 * @param {string} state
 * @returns {boolean}
 */
const checkDeIsEnabled = (state) =>
  state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED ||
  state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED;

/**
 * @description Checks if Domain Enforcement has a specific state
 * @param {array} list of target states
 * @returns {boolean}
 */
const checkDeHasState = (state, listOfStates) => listOfStates.includes(state);

/**
 * @description Checks if Domain Enforcement is on (ACTIVATED, ENFORCED or NOTIFIED)
 * @param {string} state
 * @returns {boolean}
 */
const checkDeIsOn = (state) =>
  state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED ||
  state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED ||
  state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.NOTIFIED;

/**
 * @description Helper function to sort exception list by email
 *
 * @returns {sortvalue}
 */
const sortByEmail = (a, b) => (a.email > b.email ? 1 : -1);

export {getBannerVariant, checkDeIsEnabled, checkDeHasState, checkDeIsOn, sortByEmail};
