(function () {
  /**
   * @ngdoc module
   * @name app.widgets.products.purchase.renew-products-modal2
   * @description module that defines renew-products-modal2 component
   */
  angular.module('app.widgets.products.purchase.renew-products-modal2', [
    'app.core.analytics',
    'app.core.cart',
    'app.core.chat',
    'app.core.contract.access',
    'app.core.organizations.organization-manager',
    'app.core.product.purchase',
    'app.core.sophia',
    'app.widgets.products',
    'app.widgets.products.purchase',
    'app.widgets.support',
    'binky.core.analytics-page',
    'binky.core.lodash',
    'binky.core.ui-event',
    'binky.core.user.authenticated-user',
    'binky.shell.panels.manager',
    'binky.shell.panels.modal',
    'binky.ui-migration',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.events',
    'binky.widgets.common.wait',
    'pascalprecht.translate',
  ]);
})();
