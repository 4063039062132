/* eslint-disable max-statements */
/* eslint-disable max-lines */
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import {getTypeAndAudienceFromAutoAssignRules} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';

(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc service/provider
   * @name gainsightReady
   * @description defines service to connect to gainsight and send current user information
   */
  angular.module('app.core.gainsight').provider('gainsightReady', gainsightReady);

  let gainsightApiKey, gainsightUrl;

  /* @ngInject */
  function gainsightReady(_, configurationReadyProvider, MIGRATION_TYPE) {
    const ngInjector = angular.injector(['ng']);
    const $q = ngInjector.get('$q');
    _.assign(this, {
      $get,
      configure,
    });

    ////////////

    function configure() {
      const configDeferred = $q.defer();
      configurationReadyProvider
        .whenConfigReady()
        .then((configData) => {
          gainsightApiKey = _.get(configData, 'services.gainsight.apiKey');
          gainsightUrl = _.get(configData, 'services.gainsight.url');
          if (!gainsightApiKey) {
            configDeferred.reject('No Gainsight api key found');
          }
          if (!gainsightUrl) {
            configDeferred.reject('No Gainsight url found');
          }
          configDeferred.resolve();
        })
        .catch(configDeferred.reject);

      return configDeferred.promise;
    }

    ////////////

    /* @ngInject */
    function $get(
      $log,
      $window,
      $injector,
      $rootScope,
      binkySrc2,
      CLOUD,
      extendedUserProfileReady,
      feature,
      gainsightHelper,
      DOMAIN_STATUS,
      GAINSIGHT_EVENT_TYPES,
      localeReady,
      MESSAGE,
      ORGANIZATION_MARKET_SEGMENT,
      orgReady,
      PANEL_MANAGER
    ) {
      const deferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      function run() {
        // Resolve immediately; should not block Onesie from starting up
        deferred.resolve();

        const orgReadyPromise = orgReady.whenRunReady();
        const extendedUserProfileReadyPromise = extendedUserProfileReady.whenRunReady();
        const localeReadyPromise = localeReady.whenRunReady();

        // Disable rule since there's no action to take if any of the ready providers reject - it
        // does not even make sense to log, as with that approach, the same error could be logged
        // by each ready provider client.
        // eslint-disable-next-line promise/catch-or-return
        gainsightHelper
          .whenFeatureAndAuthRunReady()
          .then(() => orgReadyPromise)
          .then(() => extendedUserProfileReadyPromise)
          .then(() => localeReadyPromise)
          .then(() => {
            if (feature.isEnabled('gainsight_tracking')) {
              const authUser = $injector.get('AuthenticatedUser').get();
              const userId = authUser.getId().toString();
              const userRoles = authUser.getRoles();
              const orgList = $injector.get('OrganizationList');
              const activeOrg = orgList.get().activeOrg;
              const orgId = activeOrg.id;
              // Gainsight can only run if this user is an org admin for the org being looked at
              if (userRoles.isOrgAdminForOrg(orgId)) {
                gainsightHelper.loadGainsightScript(gainsightApiKey, gainsightUrl);
                pushAccountInfo(userId, activeOrg);
                setupCustomEventTracking();
                setupSwitchOrgListener();
                setupEventListeners();
              } else {
                $log.info('Gainsight cannot load for non-org admins');
              }
            }
          });

        return deferred.promise;
      }

      function pushAccountInfo(userId, activeOrg) {
        const orgManager = $injector.get('OrganizationManager');
        const productList = orgManager.getProductsForActiveOrg();
        const licensesTotal = productList.getSeatBasedAssignableLicenseCount();
        const licensesAssigned = productList.getSeatBasedTotalProvisionedQuantity();
        const licensesUnassigned = licensesTotal - licensesAssigned;

        const locale = $injector.get('locale');
        const localeString = locale.getCoralLocale();

        const orgMarketSegment = activeOrg.marketSegment;
        const orgMarketSubsegments = activeOrg.getMarketSubsegments();
        const orgContracts = orgManager.getContractsForActiveOrg();
        const orgMigrations = orgManager.getMigrationsForActiveOrg();

        const hasPackageSupport = productList.hasPackageSupport();
        const hasCreativeCloudProducts = productList.hasProductsInCloud(CLOUD.CREATIVE);
        const hasDocumentCloudProducts = productList.hasProductsInCloud(CLOUD.DOCUMENT);
        const contractProductCodeList = _.map(productList.items, 'code');
        const hasExperienceCloudProducts = productList.hasProductsInCloud(CLOUD.EXPERIENCE);
        const hasTeamProducts = productList.hasTeamProduct();
        const hasEnterpriseProducts = productList.hasETLAProducts || productList.hasEVIPProducts;
        const productFamilies = _.map(productList.items, 'family');
        const hasSDLProducts = _.some(productList.items, (item) =>
          item.fulfillableItemList.hasLaboratoryLicenseManagement()
        );
        const paCodeList = _.map(productList.items, 'productArrangementCode');
        const directoryList = $injector.get('directoryFacade').getDirectoryList();

        const onesieSrc2 = $injector.get('onesieSrc2');
        const SharedContextualParams = onesieSrc2.common.services.sophia.SharedContextualParams;
        const CONTEXTUAL_PARAMS_SHARED = onesieSrc2.common.services.sophia.CONTEXTUAL_PARAMS_SHARED;

        const OnesieExtendedUserProfile = $injector.get('OnesieExtendedUserProfile');
        const userProfile = OnesieExtendedUserProfile.get();

        const directorySync = onesieSrc2.settings.services.directorySync;

        const {DIRECTORY_STATUS, DIRECTORY_TYPE} = binkySrc2.services.directory.DIRECTORY_CONSTANTS;

        const isEDUIdPEligible =
          (activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.GOVERNMENT &&
            activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) ||
          feature.isEnabled('force_edu_market_segment');

        userProfile.$promise
          .then(() => orgContracts.$promise)
          .then(() => orgMigrations.$promise)
          .then(() => directoryList.$promise)
          .then(() => SharedContextualParams.get())
          .then((sharedContextualParams) =>
            getEduSyncs(isEDUIdPEligible).then((eduRosterSyncs) => [
              sharedContextualParams,
              eduRosterSyncs,
            ])
          )
          .then(([sharedContextualParams, eduRosterSyncs]) =>
            getAuthSourcesInformation().then((authSourcesInformation) => [
              sharedContextualParams,
              eduRosterSyncs,
              authSourcesInformation,
            ])
          )
          .then(([sharedContextualParams, eduRosterSyncs, authSourcesInformation]) =>
            directorySync
              .getLinks(activeOrg.id)
              .then((directoryLinks) => [
                sharedContextualParams,
                eduRosterSyncs,
                authSourcesInformation,
                directoryLinks,
              ])
          )
          .then(
            ([sharedContextualParams, eduRosterSyncs, authSourcesInformation, directoryLinks]) => {
              const list = orgContracts.items;
              const migrations = orgMigrations.items;
              const customerSegments = _(list).map('customerSegment').compact().uniq().value();
              const salesChannels = _(list).map('salesChannel').compact().uniq().value();

              const models = _(list).map('model').compact().uniq().value();
              const contractBuyingPrograms = _(list).map('buyingProgram').compact().uniq().value();
              const earliestContractStartDate = orgContracts.getEarliestStartDate()
                ? orgContracts.getEarliestStartDate().getTime()
                : undefined;
              const hasContractInRenewalWindow = orgContracts.hasContractInRenewalWindow();
              const earliestContractAnniversaryDate = orgContracts.getEarliestAnniversaryDate()
                ? new Date(orgContracts.getEarliestAnniversaryDate()).getTime()
                : undefined;

              const esmType1Migration = _.find(migrations, ['type', MIGRATION_TYPE.ESM_TYPE1]);
              const t2eMigration = _.find(migrations, ['type', MIGRATION_TYPE.T2E]);
              const esmType1MigrationStatus = _.get(esmType1Migration, 'status');
              const t2eMigrationStatus = _.get(t2eMigration, 'status');

              const vipDirectToTeamDirectMigration = _.find(migrations, [
                'type',
                MIGRATION_TYPE.VIP2DIRECT,
              ]);
              const orgMigrationStatusVIP2DIRECT = _.get(vipDirectToTeamDirectMigration, 'status');
              const orgMigrationVIP2DIRECTStartDate = Date.parse(
                _.get(vipDirectToTeamDirectMigration, 'metadata.scheduledStartDate')
              );

              const maLegacyToAdobeMigration = _.find(migrations, [
                'type',
                MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE,
              ]);
              const orgMigrationStatusMALegacyToAdobe = _.get(maLegacyToAdobeMigration, 'status');
              const orgMigrationMALegacyToAdobeStartDateInMs = Date.parse(
                _.get(maLegacyToAdobeMigration, 'metadata.scheduledStartDate')
              );
              const orgMigrationMALegacyToAdobeGainsightDataBlob = _.chain(maLegacyToAdobeMigration)
                .invoke('getGainsightData')
                .toString()
                .value();
              const orgMigrationMALegacyToAdobeProducts = _.get(
                maLegacyToAdobeMigration,
                'products'
              );

              // send directory setup state information. We need to share whether there are any directories
              // that have finished setup-- trusted directories and their status (pending/active), or owned
              // directories with an active default idp.
              const orgDirectoryConfigurationStatuses = _.map(
                directoryList.items,
                (directory) =>
                  `${directory.ownershipStatus}:${directory.status}:${
                    directory.isConfigured() ? 'CONFIGURED' : 'NOT_CONFIGURED'
                  }`
              );

              const orgMigrationEsmStartDateInMs = Date.parse(
                _.get(esmType1Migration, 'metadata.scheduledStartDate')
              );
              const orgMigrationT2EStartDateInMs = Date.parse(
                _.get(t2eMigration, 'metadata.scheduledStartDate')
              );

              const enabledFeatures = _.filter(feature.getFeatures(), (f) => feature.isEnabled(f));

              const hasDeprecatedOktaIdp = _.some(directoryList.items, {isPrimaryIdpOkta: true});
              const hasFederationSetup = _.some(directoryList.items, (directory) =>
                directory.isType3()
              );

              // there is at least one federated directory with no domain.
              const hasDirectoryWithNoDomain = _.some(directoryList.items, {
                status: DIRECTORY_STATUS.NEEDS_DOMAIN,
                type: DIRECTORY_TYPE.TYPE3,
              });
              const isEDURosterSyncEligible =
                hasFederationSetup && isEDUIdPEligible && eduRosterSyncs.data.count === 0;

              const syncSources = _(eduRosterSyncs.data.syncConfigs)
                .concat(directoryLinks.data)
                .map((sync) => sync.partnerType || sync.rosterSource || 'Roster')
                .value();

              const hasNoSyncSetup = syncSources.length === 0;
              const pendingUserIntroductions = sharedContextualParams['1pic'] > 0;
              const landingPage = $window.location.pathname;
              $window.aptrinsic('reset');

              const autoAssignRules = AutoAssignRulesCache.get().autoAssignRules;
              const [jitRuleAudience, jitRuleType] =
                getTypeAndAudienceFromAutoAssignRules(autoAssignRules);

              $window.aptrinsic(
                'identify',
                {
                  // user information
                  adminRoles: sharedContextualParams['1ar'],
                  enabledFeatures,
                  id: userId, // Required for logged in app users
                  landingPage,
                  locale: localeString,
                  signUpDate: userProfile.adminConsoleFirstLoginTime.getTime(), // gainsight needs date in milliseconds
                },
                _.assign(
                  {
                    // account/org information
                    contractBuyingPrograms,
                    contractProductCodeList,
                    customerSegments,
                    earliestContractAnniversaryDate,
                    earliestContractStartDate,
                    hasContractInRenewalWindow,
                    hasCreativeCloudProducts,
                    hasDeprecatedOktaIdp,
                    hasDirectoryWithNoDomain,
                    hasDocumentCloudProducts,
                    hasEnterpriseProducts,
                    hasExperienceCloudProducts,
                    hasFederationSetup,
                    hasNoSyncSetup,
                    hasPackageSupport,
                    hasSDLProducts,
                    hasTeamProducts,
                    id: activeOrg.id, // Required
                    isEDUIdPEligible,
                    isEDURosterSyncEligible,
                    jitRuleAudience,
                    jitRuleType,
                    licensesAssigned,
                    licensesTotal,
                    licensesUnassigned,
                    models,
                    orgDirectoryConfigurationStatuses,
                    orgMarketSegment,
                    orgMarketSubsegments,
                    orgMigrationESMStartDate: orgMigrationEsmStartDateInMs,
                    orgMigrationMALegacyToAdobeGainsightDataBlob,
                    orgMigrationMALegacyToAdobeProducts,
                    orgMigrationMALegacyToAdobeStartDate: orgMigrationMALegacyToAdobeStartDateInMs,
                    orgMigrationStatusESM: esmType1MigrationStatus,
                    orgMigrationStatusMALegacyToAdobe,
                    orgMigrationStatusT2E: t2eMigrationStatus,
                    orgMigrationStatusVIP2DIRECT,
                    orgMigrationT2EStartDate: orgMigrationT2EStartDateInMs,
                    orgMigrationVIP2DIRECTStartDate,
                    paCodeList,
                    pendingUserIntroductions,
                    productFamilies,
                    salesChannels,
                    syncSources,
                  },
                  authSourcesInformation,
                  _.pick(sharedContextualParams, CONTEXTUAL_PARAMS_SHARED)
                )
              );

              sendVariantIdToGainsight();
            }
          )
          .catch((error) => {
            $log.error(
              'Failed to retrieve session data and did not send to gainsight. Error: ',
              error
            );
          });
      }

      function sendVariantIdToGainsight() {
        const groups = feature.getAllReleases();
        const expectedReleases = _.filter(groups, (group) =>
          _.includes(group.release_name, '_gainsight_modal_')
        );
        const eventResults = [];
        eventResults.push(
          _.map(
            expectedReleases,
            (expectedRelease) =>
              `${expectedRelease.release_name}:${expectedRelease.release_analytics_params[0].variant_id}`
          )
        );

        // Send the variant-id to 'userLandingEvent'
        $window.aptrinsic('track', 'userLandingEvent', {'variant-id': eventResults.join(',')});
      }

      function getEduSyncs(isEDUIdPEligible) {
        const onesieSrc2 = $injector.get('onesieSrc2');
        const eduRosterSync = onesieSrc2.settings.services.eduRosterSync;

        return isEDUIdPEligible
          ? eduRosterSync.getOrgRosterSyncs()
          : $q.resolve({data: {count: 0, syncConfigs: []}});
      }

      function getAuthSourcesInformation() {
        const onesieSrc2 = $injector.get('onesieSrc2');
        const orgList = $injector.get('OrganizationList');
        const activeOrg = orgList.get().activeOrg;

        const imsFederated = onesieSrc2.settings.services.imsFederated;
        const {IDP_TYPES, SOIDC_NAMES} = onesieSrc2.settings.constants;
        const {DIRECTORY_OWNERSHIP_STATUS} = binkySrc2.services.directory.DIRECTORY_CONSTANTS;

        return imsFederated.getAuthSources({orgId: activeOrg.id}).then((response) => {
          const ownedFederatedAuthSources = _.filter(response.data, {
            ownershipStatus: DIRECTORY_OWNERSHIP_STATUS.OWNED,
            type: 'fed',
          });
          const idps = _.flatMap(ownedFederatedAuthSources, 'idps');

          // there are no federated directories with an Education SSO provider (Clever, Classlink, etc.)
          const hasNoEduSSOproviders = _.every(
            idps,
            (idp) => idp.federationType !== IDP_TYPES.SOIDC
          );

          // there is at least one federated directory without Google OIDC
          const hasDirectoryWithNoGoogleOIDC = _.some(
            ownedFederatedAuthSources,
            (authSource) =>
              !_.find(authSource.idps, {
                federationType: IDP_TYPES.SOIDC,
                providerName: SOIDC_NAMES.GOOGLE,
              })
          );

          // there is at least one directory without JIT enabled on any of its IDPs
          const hasDirectoryWIthNoJITAccount = _.some(ownedFederatedAuthSources, (authSource) =>
            _.every(authSource.idps, {jitConfig: {accountCreation: false}})
          );

          return {
            hasDirectoryWithNoGoogleOIDC,
            hasDirectoryWIthNoJITAccount,
            hasNoEduSSOproviders,
          };
        });
      }

      function setupCustomEventTracking() {
        // We don't need to worry about destroy because this is a singleton
        /* eslint-disable angular/on-watch */
        $rootScope.$on(PANEL_MANAGER.MODAL.CLOSE, (evt, id, params) => {
          $window.aptrinsic('track', 'Modal Close', _.assign({'modal-id': id}, params));
        });

        $rootScope.$on('EDU IdP added', (evt, {addedIdpName, directoryId, idps}) => {
          getEduIdpCreationEventData({directoryId, idps})
            .then((data) => {
              $window.aptrinsic('track', 'EDU IdP added', data);
            })
            .catch((error) => {
              $log.error(
                `${addedIdpName} config added for ${directoryId}, but error occurred preparing Gainsight data. Did not send to Gainsight. Error: `,
                error
              );
            });
        });
        /* eslint-enable angular/on-watch */
      }

      function getEduIdpCreationEventData({directoryId, idps}) {
        const directoryLinkService = $injector.get('DirectoryLink');

        const orgList = $injector.get('OrganizationList');
        const activeOrg = orgList.get().activeOrg;

        const onesieSrc2 = $injector.get('onesieSrc2');
        const eduRosterSync = onesieSrc2.settings.services.eduRosterSync;

        const jilDirectories = binkySrc2.api.jil.jilDirectories;

        const mappedIdps = _.map(
          idps,
          (idp) => idp.providerName || idp.sniffedProvider || `${idp.federationType} Provider`
        );

        const gainsightData = {
          idps: mappedIdps,
        };

        let hasDirectoryLink, hasEduRosterSyncs;

        return directoryLinkService
          .get(directoryId)
          .then((directoryLink) => {
            hasDirectoryLink = directoryLink.isSyncedDirectory();
          })
          .then(() => eduRosterSync.getAllDirectoryRosterSyncs({directoryId}))
          .then((response) => {
            hasEduRosterSyncs = response.data.length > 0;

            _.assign(gainsightData, {hasActiveSync: hasDirectoryLink || hasEduRosterSyncs});
          })
          .then(() =>
            jilDirectories.getDomains({
              directoryId,
              orgId: activeOrg.id,
            })
          )
          .then((response) => {
            const hasActiveDomains = _.some(response.data, {status: DOMAIN_STATUS.ACTIVE});
            _.assign(gainsightData, {hasActiveDomains});
          })
          .then(() => gainsightData);
      }

      function setupSwitchOrgListener() {
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.SUCCESS, () => {
          pushAccountInfo();
        });
      }

      function setupEventListeners() {
        // Add listeners for Gainsight Engagement Events and mirror to Launch
        // https://support.gainsight.com/PX/API_for_Developers/02Usage_of_Different_APIs/Javascript_Event_Listener_API
        const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;

        _(GAINSIGHT_EVENT_TYPES)
          .values()
          .forEach((eventType) => {
            try {
              $window.aptrinsic('addListener', eventType, (...args) =>
                dispatch(eventType, ...args)
              );
            } catch (error) {
              $log.warn(`Unable to add Gainsight PX listener for type "${eventType}"`, error);
            }
          });

        // This dispatch will handle the callback for each Gainsight event type. The arguments varies by event type:
        // For Link Click and Custom Button Clicks arguments are: type, [url, engagement, step]
        // For all other event types, the arguments are: type, [engagement]
        function dispatch(type, ...args) {
          let gainsightEvent = {};

          switch (type) {
            case GAINSIGHT_EVENT_TYPES.LINK_CLICK:
            case GAINSIGHT_EVENT_TYPES.CUSTOM_BUTTON_CLICK:
              if (args.length >= 3) {
                gainsightEvent = {
                  engagement: args[1],
                  step: args[2],
                  url: args[0],
                };
              } else {
                $log.warn(
                  `Cannot dispatch analytics for Gainsight event "${type}": expected >= 3 args but got ${args.length}`
                );
              }
              break;

            default:
              if (args.length > 0) {
                gainsightEvent = {
                  engagement: args[0],
                };
              } else {
                $log.warn(
                  `Cannot dispatch analytics for Gainsight event "${type}": expected >= 1 args but got ${args.length}`
                );
              }
          }

          dispatchUiEventAnalytics({
            eventAction: type,
            eventName: 'gainsight',
            gainsightEvent,
          });
        }
      }

      function whenRunReady() {
        return deferred.promise;
      }
    }
  }
})();
/* eslint-enable max-lines */
/* eslint-enable max-statements */
