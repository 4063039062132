(function () {
  /**
   * @ngdoc module
   * @name app.core.support
   * @description Module which defines the Support model
   */
  angular.module('app.core.support', [
    'app.core.api.support-anyware',
    'app.core.auth',
    'app.core.organizations.organization-manager',
    'binky.core.contract',
    'binky.core.product',
  ]);
})();
