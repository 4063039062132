(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appPurchaseFilterAndSelectProducts
   * @description A widget to which allows the displayed products to be filter by CUSTOMER_SEGMENT and/or search string
   *  and shows the resulting products in the 3 sections.
   *
   * @param {Function} onAddCatalogItem. Callback to bubble up the offer selected. Param is offerId.
   * @binding {Function} onPlanChange. Callback when selector has changed. Param selection is CUSTOMER_SEGMENT.
   * @param {Array} [ownedBucket]. Array of offers to pass into owned products section.
   *   If empty, section will be hidden. Default is hidden.
   * @param {Integer} [ownedCount]. Initial count of items in the ownedBucket. Default is undefined.
   * @binding {CUSTOMER_SEGMENT} planSelected. Two-way binding. If initially set, and showPlanSelector is true,
   *   it will be used to initialize the plan selector and it will be updated to reflect the selected CUSTOMER_SEGMENT.
   * @param {Object} [ownedProducts] Products currently owned by the organization.
   * @param {Array} [recommendedBucket]. Array of offers to pass into recommended products section.
   *   If empty, section will be hidden. Default is hidden.
   * @param {Array} [remainingBucket]. Array of offers to pass into unowned and not recommended products section.
   *   If empty, section will be hidden. Default is hidden.
   * @binding {Boolean} showPlanSelector. Default is undefined. If true, the plan selector will be shown.
   * @param {Boolean} [showPrice]. Boolean value of whether to show the price or not. Default is false.
   */
  angular.module('app.widgets.products.purchase').component('appPurchaseFilterAndSelectProducts', {
    bindings: {
      onAddCatalogItem: '&',
      onPlanChange: '&', // needed to notify that cart should be cleared and buckets initialized
      ownedBucket: '<?',
      ownedCount: '@?',
      ownedProducts: '<?', // Passed to child components
      planSelected: '=', // if returning to view, need to re-init plan selector to previous selection
      recommendedBucket: '<?',
      remainingBucket: '<?',
      showPlanSelector: '<',
      showPrice: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/purchase-filter-and-select-products/purchase-filter-and-select-products.component.html',
  });

  /* @ngInject */
  function controller(
    $scope,
    _,
    AnalyticsEvent,
    AnalyticsHelper,
    UiEventDescriptor,
    ProductDescriptor,
    ProductPurchaseHelper
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onPlanSelectionChange,
      onProductSearch,
    });

    function $onInit() {
      // Watch the buckets so that as offers are moved to and from the cart the displayed buckets are
      // updated too.
      $scope.$watchGroup(
        [
          '$ctrl.ownedBucket.length',
          '$ctrl.recommendedBucket.length',
          '$ctrl.remainingBucket.length',
        ],
        (newValues) => {
          // Skip filter on initial pass when [undefined, undefined, undefined].
          // Also we don't want to show the new products string until the buckets are in place.
          if (!_.includes(newValues, undefined)) {
            filterAllOffers();
          }
        }
      );

      vm.searchStr = '';
    }

    function onPlanSelectionChange({selection}) {
      if (vm.selectedCustomerSegment !== selection) {
        vm.selectedCustomerSegment = selection;
        _.invoke(vm, 'onPlanChange', {selection});

        const eventName = AnalyticsHelper.customerSegmentToAnalyticsName(selection);
        AnalyticsHelper.dispatchUiEventAnalytics({eventAction: 'click', eventName});
      }
    }

    function onProductSearch({value}) {
      // store search string as lower-case since comparison will always be lower-case
      vm.searchStr = _.toLower(value);
      filterAllOffers();

      dispatchSearchAnalytics(value);

      ////////

      function dispatchSearchAnalytics(searchTerm) {
        const customerSegment = getCustomerSegment();
        const eventName = `${AnalyticsHelper.customerSegmentToAnalyticsName(
          customerSegment
        )}Search`;

        AnalyticsEvent.dispatch({
          descriptors: {
            product: new ProductDescriptor({searchTerm}),
            uiEvent: new UiEventDescriptor({eventAction: 'click', eventName}),
          },
        });
      }

      function getCustomerSegment() {
        if (vm.selectedCustomerSegment) {
          return vm.selectedCustomerSegment;
        }

        // One of the existing buckets has 1 product, which has 'customer_segment'
        const offer =
          _.get(vm, 'ownedBucket[0]') ||
          _.get(vm, 'recommendedBucket[0]') ||
          _.get(vm, 'remainingBucket[0]');

        return _.get(offer, 'customer_segment');
      }
    }

    ////////////////

    function filterAllOffers() {
      vm.ownedBucketForDisplay = filterOffers(vm.ownedBucket);
      vm.recommendedBucketForDisplay = filterOffers(vm.recommendedBucket);
      vm.remainingBucketForDisplay = filterOffers(vm.remainingBucket);

      vm.showNoProducts =
        _.isEmpty(vm.ownedBucketForDisplay) &&
        _.isEmpty(vm.recommendedBucketForDisplay) &&
        _.isEmpty(vm.remainingBucketForDisplay);
    }

    function filterOffers(offers) {
      return vm.searchStr
        ? _.filter(offers, (offer) => ProductPurchaseHelper.offerIncludesText(offer, vm.searchStr))
        : offers;
    }
  }
})();
