import {useMemo} from 'react';

import {getDisplayNamesFromSelectedSeats, getETFAmount} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';
/**
 * Hook that retrieves list of loss aversion cards that should be shown
 * given the Products Change model from the provided context.
 * @param {Contract} contract The contract of the org.
 * @param {Object} selectedSeats The hash map of initially selected licenses per product id.
 * @returns {Array} Returns an array of the props needed to populate loss aversion cards
 */
const useLossAversionCards = ({contract, selectedSeats}) => {
  const {productsChange} = useProductsChangeContext();
  return useMemo(() => {
    const cards = [];

    // CancellationFeeCard will be shown when there is an early termination fee
    if (getETFAmount(productsChange)?.totalWithoutTax > 0) {
      cards.push({
        contract,
        type: 'CancellationFeeCard',
      });
    }

    // LoseAccessCard is only shown when an assigned user is losing access to a license
    if (getDisplayNamesFromSelectedSeats(selectedSeats)?.length > 0) {
      cards.push({selectedSeats, type: 'LoseAccessCard'});
    }

    // TeamStorageCard is always shown (UnusedAssetsCard was descoped for M2)
    cards.push({type: 'TeamStorageCard'});

    return cards;
  }, [contract, productsChange, selectedSeats]);
};

export default useLossAversionCards;
