import {FocusScope} from '@react-aria/focus';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import CreatePackageFactory from '../../services/CreatePackageService';

import {useCreatePackageModalContext} from './CreatePackageModalContext';
import PackageTypeFlowWizard from './wizards/PackageTypeFlowWizard';
import PackageTypeSelectionWizard from './wizards/PackageTypeSelectionWizard';

// Create package modal is the main container of the create package flow
// It contains two wizards: PackageTypeSelectionWizard and PackageTypeFlowWizard
const CreatePackageModal = observer(({onCancel}) => {
  const {createPackageModalStore} = useCreatePackageModalContext();

  const closeCreatePackageModal = () => {
    onCancel();
    createPackageModalStore.clearPackageCreateObjAndPkgSessionSetting();
    CreatePackageFactory.closeCreatePackage();
  };

  return (
    <FocusScope>
      {createPackageModalStore.isPackageTypeSelectionWizard && (
        <PackageTypeSelectionWizard closeCreatePackageModal={closeCreatePackageModal} />
      )}
      {!createPackageModalStore.isPackageTypeSelectionWizard && (
        <PackageTypeFlowWizard closeCreatePackageModal={closeCreatePackageModal} />
      )}
    </FocusScope>
  );
});

CreatePackageModal.propTypes = {
  /**
   * Function called on clicking cancel button or for closing modal
   */
  onCancel: PropTypes.func.isRequired,
};
export default CreatePackageModal;
