(function () {
  /**
   * @ngdoc module
   * @name app.core.product.product-group-product-utils
   * @description Module which defines product group product related services
   */
  angular.module('app.core.product.product-group-product-utils', [
    'app.core.product.product-group-product-list',
    'binky.core.lodash',
    'binky.core.product.fulfillable-item-list',
  ]);
})();
