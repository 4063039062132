import {jilDirectories} from '@admin-tribe/binky';
import {showError, showInfo} from '@admin-tribe/binky-ui';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {downloadUserList} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationUtils';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';

// The 'Export users by CSV' button which when pressed downloads a CSV file containing the directory users.
const ExportUsersByCsv = observer(() => {
  const {pageContext, store} = useMemberListContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const onDownloadUserList = async () => {
    const onError = () => {
      showError(
        intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.children',
        })
      );
    };

    showInfo(
      intl.formatMessage({
        id: 'common.bulkOperation.bulkOperationForm.csvProcessing.alert.info',
      })
    );

    setIsDisabled(true);

    // This always resolves. On error, it calls the onError callback.
    await downloadUserList({
      exportFunction: jilDirectories.exportDirectoryUsers,
      exportParams: store.listOptions,
      onError,
      orgId,
    });

    setIsDisabled(false);
  };

  return (
    pageContext.isDirectoryTarget() && (
      /* Since this button has an isDisabled property we must explicitly disable it if there is an API error. */
      <Button
        data-testid="export-users-CSV-btn"
        isDisabled={isDisabled || store.hasLoadingError}
        onPress={onDownloadUserList}
        variant="primary"
      >
        {intl.formatMessage({id: 'common.userListTableSection.button.exportUsersByCsv'})}
      </Button>
    )
  );
});

export default ExportUsersByCsv;
