(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.widgets.support').component('appSupportTicketDiscussionSection', {
    bindings: {
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-discussion-section/support-ticket-discussion-section.component.html',
  });

  /* @ngInject */
  function controller(_, $scope, feature, onesieSrc2) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      discussionItems: [],
      feature,
      isAttachment,
      isComment,
      refresh: vm.supportTicket.refresh.bind(vm.supportTicket),
      SupportTicketAddDiscussionItemFormSection:
        onesieSrc2.support.components.SupportTicketAddDiscussionItemFormSection,
      SupportTicketDiscussionHeader: onesieSrc2.support.components.SupportTicketDiscussionHeader,
      SupportTicketDiscussionItems: onesieSrc2.support.components.SupportTicketDiscussionItems,
    });

    function $onInit() {
      // Whenever the support ticket is refreshed, update the view
      $scope.$watch(
        () => vm.supportTicket.$promise,
        () => vm.supportTicket.$promise.then(updateDiscussionItems)
      );
    }

    function isAttachment(item) {
      return _.includes(vm.supportTicket.attachments, item);
    }

    function isComment(item) {
      return _.includes(vm.supportTicket.comments, item);
    }

    /////////////

    function updateDiscussionItems() {
      vm.discussionItems = vm.supportTicket.getDiscussionItems();
    }
  }
})();
