import {feature} from '@admin-tribe/binky';
import {Divider, Flex, Heading} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import OverviewList from 'features/overview/components/overview-list/OverviewList';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

import ArticleListItem from './ArticleListItem';
import HelpfulLinkItem from './HelpfulLinkItem';
import {getHelpfulResources} from './helpfulResourcesSectionUtils';

const MAX_ARTICLES = 4;
const MAX_LINKS = 3;

/**
 * The Helpful Resources Section component on the Overview Page.
 */
const HelpfulResourcesSection = ({dividerPlacement = DividerPlacement.NONE, isHidden}) => {
  const hideContent = {...{base: false, L: false, M: false, S: false}, ...isHidden};
  const intl = useIntl();
  const headingId = useId();
  const popularArticlesHeadingId = useId();

  const {model: helpfulResources, isLoading} = useAsyncModel({
    initState: [[], []],
    loadFn: getHelpfulResources,
  });

  const [popularArticles, links] = useMemo(() => {
    // debug flag to show all articles & links
    if (feature.isEnabled('show_all_helpful_resources')) {
      return [helpfulResources[0], helpfulResources[1]];
    }
    return [helpfulResources[0].slice(0, MAX_ARTICLES), helpfulResources[1].slice(0, MAX_LINKS)];
  }, [helpfulResources]);

  // don't render anything if there is nothing to display
  if (!isLoading && popularArticles.length === 0 && links.length === 0) {
    return null;
  }

  return (
    <OverviewPod
      data-testid="helpful-resources-section"
      isHidden={hideContent}
      isLoading={isLoading}
    >
      <TitledSection
        dividerPlacement={dividerPlacement}
        headingLevel={2}
        id={headingId}
        title={intl.formatMessage({id: 'overview.helpResources.pod.title'})}
      >
        <Flex direction="column" gap="size-150">
          {popularArticles.length > 0 && (
            <Flex direction="column">
              <Heading
                id={popularArticlesHeadingId}
                level={3}
                margin={0}
                marginTop="size-250"
                UNSAFE_style={{
                  fontSize: 'var(--spectrum-global-dimension-font-size-50)',
                  fontWeight: 'var(--spectrum-global-font-weight-medium)',
                }}
              >
                {intl.formatMessage({id: 'overview.helpResources.popularArticles.title'})}
              </Heading>
              <OverviewList aria-labelledby={popularArticlesHeadingId} items={popularArticles}>
                {({actionURL, cardLabel}) => (
                  <ArticleListItem href={actionURL}>{cardLabel}</ArticleListItem>
                )}
              </OverviewList>
            </Flex>
          )}

          {popularArticles.length > 0 && links.length > 0 && <Divider size="S" />}

          {links.length > 0 &&
            links.map(({actionURL, cardID, cardLabel}) => (
              <HelpfulLinkItem key={cardID} href={actionURL} id={cardID}>
                {cardLabel}
              </HelpfulLinkItem>
            ))}
        </Flex>
      </TitledSection>
    </OverviewPod>
  );
};

HelpfulResourcesSection.propTypes = {
  /**
   * Location of the divider in the helpful resources section. Defaults to NONE
   * (main content appearance)
   */
  dividerPlacement: PropTypes.string,
  /**
   * Responsive props instructing pod how to show/hide at various display sizes.
   * Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default HelpfulResourcesSection;
