(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    _,
    $delegate,
    $q,
    $state,
    $translate,
    directoryAccess,
    directoryFacade,
    feature,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    organizationAccess,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.SET_UP_IDENTITY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForEnterprise) {
          cache.canViewValueDiscoveryTasksForEnterprise =
            organizationAccess.canViewValueDiscoveryTasksForEnterprise();
        }

        const {canViewValueDiscoveryTasksForEnterprise} = cache;
        return canViewValueDiscoveryTasksForEnterprise
          .then((canView) => (canView ? $q.resolve() : $q.reject()))
          .then(() => directoryAccess.canCreateDirectory())
          .then((canCreateDirectory) => (canCreateDirectory ? $q.resolve() : $q.reject()))
          .then(() => directoryFacade.getDirectoryList().$promise)
          .then((directoryList) => {
            if (
              !_.some(
                directoryList.items,
                (item) => !item.isType2E() && (item.isConfigured() || item.isTrusted())
              )
            ) {
              return [
                new HvaCard({
                  ctaLabel: $translate.instant(
                    'overview.highValueActionArea.setUpTasks.setUpIdentity.ctaLabel'
                  ),
                  description: $translate.instant(
                    'overview.highValueActionArea.setUpTasks.setUpIdentity.description'
                  ),
                  header: $translate.instant(
                    'overview.highValueActionArea.setUpTasks.setUpIdentity.header'
                  ),
                  id: 'hva-set-up-identity',
                  onCTAClick: () => {
                    $state.go('settings.identity');
                  },
                  sectionTitle: $translate.instant(
                    'overview.highValueActionArea.setUpTasks.setUpIdentity.sectionTitle'
                  ),
                  svgClass: SVG_CLASS.IDENTITY,
                }),
              ];
            }
            return $q.reject();
          });
      }
    }
  }
})();
