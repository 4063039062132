(function () {
  /**
   * @deprecated Please use src2/app/core/products/trial-helper/trialHelperConstants.js
   */
  angular.module('app.core.product.trial-helper').constant('TRIAL_STATUS', {
    DAYS_LEFT_INFO: 'DAYS_LEFT_INFO',
    DAYS_LEFT_WARNING: 'DAYS_LEFT_WARNING',
    EXPIRED: 'EXPIRED',
    LAST_DAY: 'LAST_DAY',
    NOT_TRIAL: 'NOT_TRIAL',
  });
})();
