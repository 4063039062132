/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.main.jobs:appJobs
 *
 * @description The bulk operation results for an organization. It is reached by
 *   clicking on the Bulk Operation Results button dropdown in the all-users view.
 *
 * @binding {Object} breadcrumbNavItemListId - id of the navigation list that is
 *   the root of the breadcrumbs for this page
 * @binding {Object} pageContext - the pageContext controlling bulk operations
 *   to fetch/display
 */
(function () {
  angular.module('app.main.jobs').component('appJobs', {
    bindings: {
      breadcrumbNavItemListId: '<',
      pageContext: '<',
    },
    controller,
    templateUrl: 'app/main/jobs/jobs.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $q,
    _,
    JobList,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    panelManager,
    Selection,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      onRemoveJobsConfirmation,
      openRemoveJobsModal,
    });

    // eslint-disable-next-line complexity
    function $onInit() {
      vm.waitOnPage = $q.defer();
      vm.selection = new Selection();

      switch (vm.pageContext.target) {
        case PAGE_TARGET.PRODUCT:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              vm.jobList = JobList.getLicenseConfigJobList(
                _.get(vm.pageContext, 'sidecarData.profileId'),
                vm.pageContext.targetId
              );
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              vm.jobList = JobList.getLicenseConfigJobList(
                vm.pageContext.targetId,
                vm.pageContext.targetParentId
              );
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.USER_GROUP:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              vm.jobList = JobList.getUserGroupJobList(vm.pageContext.targetId);
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.DIRECTORY:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              vm.jobList = JobList.getDirectoryUserJobList(vm.pageContext.targetId);
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.ORGANIZATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              vm.jobList = JobList.getUsersJobList();
              break;
            default:
              logUnimplementedError();
          }
          break;
        default:
          logUnimplementedError();
      }

      if (vm.jobList) {
        vm.jobList.$promise
          .then(() => {
            vm.waitOnPage.resolve();
          })
          .catch((error) => {
            vm.waitOnPage.reject(error);
          });
      } else {
        vm.waitOnPage.resolve();
      }

      ////////

      function logUnimplementedError() {
        $log.debug(`AppJobsCtrl: View unimplemented for provided pageContext: ${vm.pageContext}`);
      }
    }

    function $onDestroy() {
      if (vm.jobList) {
        vm.jobList.destroy();
      }
    }

    function onRemoveJobsConfirmation() {
      panelManager.close('modal-remove-jobs');
      removeJobs(vm.jobsToRemove);
    }

    function openRemoveJobsModal(jobsToRemove) {
      vm.jobsToRemove = jobsToRemove;
      panelManager.open('modal-remove-jobs');
    }

    ////////

    /**
     * @description Method used to remove Jobs.
     * @param {Array} jobs - list of Jobs to remove
     */
    function removeJobs(jobs) {
      vm.removeInProgress = true;

      JobList.deleteJobs(jobs).then(onSuccess, onError).finally(onComplete);

      ////////

      function onComplete() {
        vm.removeInProgress = false;
      }

      function onError() {
        toastManager.showErrorToast();
      }

      function onSuccess() {
        toastManager.showSuccessToast();
        vm.selection.deselectAllItems();
      }
    }
  }
})();
