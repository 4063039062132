import {SELECTION_MODE} from '@pandora/react-table-section';

import rootStore from 'core/RootStore';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import {
  canRemoveProductProfile,
  canViewDetails,
  canViewEntitledUsersCount,
  canViewLicensesTotalQuantity,
  canViewLicensesUsedQuantity,
  canViewNotifyUser,
} from 'core/products/access/productProfileAccess';
import {canUnassignProductProfileFromUserGroup} from 'core/user-group/access/userGroupAccess';

/**
 * @description Method to determine which fields to show in the Product profile table.
 * @param {PageContext} pageContext The PageContext object used to determine the context,
 *   either User or UserGroup.
 * @returns {Object} an object with display and selectionMode properties to feed into the table.
 * @throws {Error} if pageContext target not User or UserGroup
 */
function getProfileTableSectionProps({pageContext}) {
  let display, selectionMode;

  if (pageContext.isProductTarget()) {
    const productList = rootStore.organizationStore.productList;
    const product = productList.items.find((p) => p.id === pageContext.targetId);

    display = {
      adminCount: true,
      entitledUsers: canViewEntitledUsersCount(product),
      licensesUsed: canViewLicensesUsedQuantity(product),
      name: true,
      notifyUser: canViewNotifyUser(product),
      totalQuantity: canViewLicensesTotalQuantity(product),
      viewDetails: canViewDetails(product),
    };

    selectionMode =
      !isReadOnly() && canRemoveProductProfile() ? SELECTION_MODE.MULTIPLE : SELECTION_MODE.NONE;
  } else if (pageContext.isUserGroupTarget()) {
    display = {
      name: true,
      productName: true,
    };

    selectionMode =
      !isReadOnly() && canUnassignProductProfileFromUserGroup()
        ? SELECTION_MODE.MULTIPLE
        : SELECTION_MODE.NONE;
  } else {
    throw new Error(`getProfileTableSectionProps: unhandled targetType ${pageContext.targetType}`);
  }

  return {display, selectionMode};
}

// eslint-disable-next-line import/prefer-default-export -- utilities
export {getProfileTableSectionProps};
