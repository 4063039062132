/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseTypeSelectionModal
 *
 * @description The modal to select the type of support case to create. Once the case type is selected, and
 *  the 'Create Case' button is clicked, the Create Support Case modal will open to allow a case to be created.
 *
 * @param {String} [modalId] The ID to use for this modal. Defaults to CASE_TYPE_SELECTION_MODAL_ID.
 * @param {Function} [onInitialized] Called when the modal is initialized (but not yet opened).
 */
(function () {
  const componentName = 'appCaseTypeSelectionModal';
  angular.module('app.widgets.support').component(componentName, {
    bindings: {
      modalId: '@?',
      onInitialized: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/support/case-type-selection-modal/case-type-selection-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $timeout,
    AnalyticsEvent,
    CREATE_CASE_MODAL_ID,
    CASE_TYPE_SELECTION_MODAL_ID,
    CASE_TYPE_SELECTION_CARD,
    CLOUD,
    SUPPORT_TICKET_CASE_TYPE,
    OrganizationManager,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      id: vm.modalId || CASE_TYPE_SELECTION_MODAL_ID,
      onClickCancel,
      onClickCreateCase,
      onClickCreateCaseFromLink,
      onOpen,
    });

    function $onInit() {
      vm.caseTypes = getCaseTypes();
      vm.modalVariant = 'fullpage-fullscreen responsive';

      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function getCaseTypes() {
      const USERS_LICENSES_CARD_POSSIBLE_ISSUE_COUNT = 5; // must be in sync with number of issues in str file
      // eslint-disable-next-line id-length
      const CREATIVECLOUD_DOCUMENTCLOUD_CARD_POSSIBLE_ISSUE_COUNT = 5; // must be in sync with number of issues in str file
      const EXPERIENCECLOUD_CARD_POSSIBLE_ISSUE_COUNT = 5; // must be in sync with number of issues in str file

      const caseTypes = [
        {
          id: CASE_TYPE_SELECTION_CARD.USERS_LICENSES,
          issueKeys: getIssueKeys(USERS_LICENSES_CARD_POSSIBLE_ISSUE_COUNT),
          translateKey: '.usersLicenses',
        },
      ];

      if (
        OrganizationManager.getProductsForActiveOrg().hasProductsInCloud(CLOUD.CREATIVE) ||
        OrganizationManager.getProductsForActiveOrg().hasProductsInCloud(CLOUD.DOCUMENT) ||
        OrganizationManager.getProductsForActiveOrg().hasProductsInCloud(CLOUD.OTHERS)
      ) {
        caseTypes.push({
          id: CASE_TYPE_SELECTION_CARD.CREATIVECLOUD_DOCUMENTCLOUD,
          issueKeys: getIssueKeys(CREATIVECLOUD_DOCUMENTCLOUD_CARD_POSSIBLE_ISSUE_COUNT),
          translateKey: '.creativeCloudDocumentCloud',
        });
      }

      if (OrganizationManager.getProductsForActiveOrg().hasProductsInCloud(CLOUD.EXPERIENCE)) {
        caseTypes.push({
          id: CASE_TYPE_SELECTION_CARD.EXPERIENCECLOUD,
          issueKeys: getIssueKeys(EXPERIENCECLOUD_CARD_POSSIBLE_ISSUE_COUNT),
          translateKey: '.experienceCloud',
        });
      }

      return caseTypes;
    }

    function onClickCreateCase() {
      panelManager.close(CASE_TYPE_SELECTION_MODAL_ID);

      let caseType;

      if (vm.selectedCardButtonId === CASE_TYPE_SELECTION_CARD.USERS_LICENSES) {
        caseType = SUPPORT_TICKET_CASE_TYPE.ADMIN;
      } else if (
        vm.selectedCardButtonId === CASE_TYPE_SELECTION_CARD.CREATIVECLOUD_DOCUMENTCLOUD ||
        vm.selectedCardButtonId === CASE_TYPE_SELECTION_CARD.EXPERIENCECLOUD
      ) {
        caseType = SUPPORT_TICKET_CASE_TYPE.PRODUCT;
      }

      panelManager.open(CREATE_CASE_MODAL_ID, {caseType});
    }

    function onClickCreateCaseFromLink() {
      panelManager.close(CASE_TYPE_SELECTION_MODAL_ID);

      const caseType =
        vm.caseTypes.length === 1
          ? SUPPORT_TICKET_CASE_TYPE.ADMIN
          : SUPPORT_TICKET_CASE_TYPE.PRODUCT;

      panelManager.open(CREATE_CASE_MODAL_ID, {caseType});
    }

    function onClickCancel() {
      AnalyticsEvent.dispatch({
        componentMethod: 'cancelButton',
        componentMethodType: 'click',
        componentName,
      });
    }

    /**
     * @param {Object} options - options for the modal
     * @param {SUPPORT_TICKET_CASE_TYPE} [options.caseType] - Optional. Either ADMIN or PRODUCT. If not specified, neither
     *   is pre-selected.
     * @param {CLOUD} [options.cloud] - Optional. Product cloud type. Expected for PRODUCT case type only.
     */
    function onOpen(options = {}) {
      if (options.caseType === SUPPORT_TICKET_CASE_TYPE.ADMIN) {
        vm.selectedCardButtonId = CASE_TYPE_SELECTION_CARD.USERS_LICENSES;
      } else if (options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT) {
        vm.selectedCardButtonId =
          options.cloud === CLOUD.EXPERIENCE
            ? CASE_TYPE_SELECTION_CARD.EXPERIENCECLOUD
            : CASE_TYPE_SELECTION_CARD.CREATIVECLOUD_DOCUMENTCLOUD;
      }
    }

    /////////////////

    function getIssueKeys(count) {
      return _.times(count, (index) => `.li${index + 1}`);
    }
  }
})();
