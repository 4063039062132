import axios from 'axios';

import {getAuthorizationHeader} from 'common/api/httpUtils';
import rootStore from 'core/RootStore';

// API documentation: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3035461186

let baseUrl;

const configure = ({url}) => {
  baseUrl = url;
};

/**
 * Get the list of certificates for a specific idp.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 */
const getCertificates = ({directoryId, idpId}) =>
  axios.get(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/keys`,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Create a new certificate for a specific idp.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 */
const createCertificate = ({directoryId, idpId}) =>
  axios.post(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/keys`,
    null, // we don't need to send anything on body
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Change the status of a certificate by id.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {String} certificateId - certificate id
 * @param {String} status - status to update.
 *                 Options for status: 'ACTIVE' or 'DISABLED'
 */
const updateCertificateStatus = ({directoryId, idpId, certificateId, status}) =>
  axios.post(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/keys/${certificateId}`,
    {status},
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Remove a certificate by id.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {String} certificateId - certificate id
 */
const removeCertificate = ({directoryId, idpId, certificateId}) =>
  axios.delete(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/keys/${certificateId}`,
    {headers: {...getAuthorizationHeader()}}
  );

/**
 * Remove a certificate by id.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {String} csrId - The id of the certificate signing request
 */
const removeCertificateSigningRequest = ({directoryId, idpId, csrId}) =>
  axios.delete(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/csrs/${csrId}`,
    {headers: {...getAuthorizationHeader()}}
  );

const getCertificateDownloadUrl = ({directoryId, idpId, certificateId}) =>
  `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/keys/${certificateId}/certificate`;

/**
 * Get the list of Certificates Signing Requests (CSRS) for a specific idp.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 */
const getCertificatesSigningRequests = ({directoryId, idpId}) =>
  axios.get(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/csrs`,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Get the details for a specific CSR.
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {String} csrId - The id of the certificate signing request
 */
const getCsrDetails = ({directoryId, idpId, csrId}) =>
  axios.get(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/csrs/${csrId}`,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {Object} certificateData - The body of the request. It might contains the following properties:
 *                 TODO: Describe the properties
 */
const createCertificateSigningRequest = ({directoryId, idpId, certificateData}) =>
  axios.post(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/csrs`,
    certificateData,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Uploads a CSR file to complete the CSR creation process
 *
 * @param {String} directoryId - directoryId id
 * @param {String} idpId - IdP id
 * @param {String} csrId - The id of the certificate signing request
 * @param {File} csrFile - The file that will get uploaded
 */
const uploadCsrFile = ({directoryId, idpId, csrId, csrFile}) => {
  const formData = new FormData();
  formData.append('file', csrFile);

  return axios.post(
    `${baseUrl}/organizations/${rootStore.organizationStore.activeOrgId}/authSources/${directoryId}/idps/${idpId}/csrs/${csrId}/complete`,
    formData,
    {
      headers: {
        ...getAuthorizationHeader(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

const samlCertificates = {
  configure,
  createCertificate,
  createCertificateSigningRequest,
  getCertificateDownloadUrl,
  getCertificates,
  getCertificatesSigningRequests,
  getCsrDetails,
  removeCertificate,
  removeCertificateSigningRequest,
  updateCertificateStatus,
  uploadCsrFile,
};

export default samlCertificates;
