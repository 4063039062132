import {Button, ButtonGroup, Content, Flex, Heading, Image, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import startChatBumperHero from '../assets/F_IlluAdminConsole_CancelExclusionsBanner_1100x250px_1x.svg';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';

import styles from './BumperStep.pcss';

const {BUMPER_STEP} = SELF_CANCEL_STEPS;

/**
 * Bumper component shown when the user is not eligible for the Self Cancel workflow
 * @todo Integration with Jarvis chat will be implemented by a future user story.
 */
const BumperStep = ({onPrevious, onStartChat}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();

  const onCTA = () => {
    onStartChat?.({step: BUMPER_STEP});
  };

  const onBack = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.DONE});
    onPrevious?.();
  };

  return (
    <SelfCancelBaseModal>
      <Image alt="" height="250px" objectFit="cover" slot="hero" src={startChatBumperHero} />
      <Content data-testid="modal-content">
        <Flex alignItems="center" direction="column">
          <Heading
            data-testid="modal-header"
            level="1"
            marginBottom="size-300"
            marginTop="size-0"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.heading}
          >
            {intl.formatMessage({id: 'account.selfCancel.bumper.title'})}
          </Heading>
          <Text
            maxWidth="660px"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.description}
          >
            {intl.formatMessage({
              id: 'account.selfCancel.bumper.description',
            })}
          </Text>
          <ButtonGroup marginTop="size-900">
            <Button data-testid="cancel-button" isQuiet onPress={onBack} variant="secondary">
              {intl.formatMessage({
                id: 'account.selfCancel.bumper.goBack',
              })}
            </Button>
            <Button autoFocus data-testid="cta-button" onPress={onCTA} variant="accent">
              {intl.formatMessage({
                id: 'account.selfCancel.bumper.startChat',
              })}
            </Button>
          </ButtonGroup>
        </Flex>
      </Content>
    </SelfCancelBaseModal>
  );
};

BumperStep.propTypes = {
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * Handler that is called when the user wants to start a chat session.
   * Will also send up an object specifying which step a user is initiating a chat session from.
   * i.e. {step: 'BumperStep'}
   */
  onStartChat: PropTypes.func,
};

export default BumperStep;
