import {Link} from '@adobe/react-spectrum';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ReauthorizeServer from './ReauthorizeServer';

const ReauthorizeServerLink = ({serverObject}) => {
  const intl = useIntl();
  const [isReauthorizeServerDialogOpen, setIsReauthorizeServerDialogOpen] = useState(false);

  const onReauthorizeLinkPress = () => {
    setIsReauthorizeServerDialogOpen(true);
  };
  return (
    <>
      <Link
        aria-label={intl.formatMessage({
          id: 'packages.servers.table.status.reauthorize',
        })}
        isQuiet
        onPress={onReauthorizeLinkPress}
      >
        {intl.formatMessage({id: 'packages.servers.table.status.reauthorize'})}
      </Link>
      <ModalContainer>
        {isReauthorizeServerDialogOpen && (
          <ReauthorizeServer
            onCancel={() => setIsReauthorizeServerDialogOpen(false)}
            serverObject={serverObject}
          />
        )}
      </ModalContainer>
    </>
  );
};

ReauthorizeServerLink.propTypes = {
  /**
   * Server info object
   */
  serverObject: PropTypes.shape({
    /**
     * Activation Enforcement Type of server
     */
    activationEnforcementType: PropTypes.string,
    /**
     * Org in which server is made
     */
    branding: PropTypes.string,
    /**
     * DNS address of server
     */
    dns: PropTypes.string,
    /**
     * LAN timeout of server
     */
    lanTimeout: PropTypes.number,
    /**
     * Licenses that are attached with server
     */
    licenses: PropTypes.instanceOf(Array),
    /**
     * Name of the server
     */
    name: PropTypes.string,
    /**
     * ID of the server
     */
    serverId: PropTypes.string,
  }),
};

export default ReauthorizeServerLink;
