import {observer} from 'mobx-react-lite';
import React from 'react';

import RemoveMemberButton from 'common/components/remove-member-button/RemoveMemberButton';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';

import AddAdminButton from './add-admin-button/AddAdminButton';
import AddDevelopersButton from './add-developers-button/AddDevelopersButton';

/**
 * The button group for the AdminListTableSection.
 * This component should be wrapped in a MemberListContextProvider.
 */
const AdminListTableActions = observer(() => {
  const {pageContext} = useMemberListContext();

  // This is wrapped by <TableActions> which is a ButtonGroup.
  return pageContext.isDeveloperTargetType() ? (
    <>
      <AddDevelopersButton />
      <RemoveMemberButton data-testid="remove-developer-btn" />
    </>
  ) : (
    <>
      <AddAdminButton />
      <RemoveMemberButton data-testid="remove-admin-btn" />
    </>
  );
});

export default AdminListTableActions;
