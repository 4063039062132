import {PRODUCT_SAPCODES} from '../packagesConstants';

/**
 * @description Method to retrieve products count (non-STI) for update package workflow
 * @param {Object} packagedProducts products packaged in admin package
 * @param {Object} selectedAdobeProductIds selected products for which updated package to be created
 * @returns {int} products count
 */
function getProductCountForUpdateWorkflow(packagedProducts, selectedAdobeProductIds) {
  const nonSTIPackagedProductsSapCode = packagedProducts
    .filter((product) => !product.isSTI)
    .map((product) => product.sapCode);

  let nonSTISelectedProductsSize = selectedAdobeProductIds
    .map((id) => id.split('/')[0])
    .filter((sapCode) => nonSTIPackagedProductsSapCode.includes(sapCode)).length;

  if (nonSTIPackagedProductsSapCode.includes(PRODUCT_SAPCODES.KCCC)) {
    nonSTISelectedProductsSize += 1;
  }
  return nonSTISelectedProductsSize;
}

// eslint-disable-next-line import/prefer-default-export -- More named exports will be added in this utility file
export {getProductCountForUpdateWorkflow};
