import PropTypes from 'prop-types';
import React from 'react';

import Bumper from '../bumper/NotificationsBumper';
import CardList from '../card-list/NotificationCardList';

const NotificationsPanel = ({
  notificationList,
  onChangeNotification,
  onClickButton,
  onUpdatePagination,
}) => (
  <div data-testid="notifications-panel">
    {notificationList.getForActiveOrg().length > 0 ? (
      <CardList
        notificationList={notificationList}
        onChangeNotification={onChangeNotification}
        onClickButton={onClickButton}
        onUpdatePagination={onUpdatePagination}
      />
    ) : (
      <Bumper />
    )}
  </div>
);

NotificationsPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - notificationList
  notificationList: PropTypes.object.isRequired,
  onChangeNotification: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  onUpdatePagination: PropTypes.func.isRequired,
};

export default NotificationsPanel;
