import {Text} from '@adobe/react-spectrum';
import Box from '@react/react-spectrum/Icon/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import CreatePackageCardCheckbox from '../../create-package-card-checkbox-group/CreatePackageCardCheckbox';

import styles from './PackageTypeCard.pcss';

// PackageTypeCard is CreatePackageCard with values populated accordingly to be used in ChoosePackageType screen
const PackageTypeCard = ({autoFocus, benefits, description, result, title, value}) => {
  const intl = useIntl();

  const sections = [
    {
      content: (
        <ul className={styles['section-list']}>
          {benefits.map((benefit) => (
            <li key={benefit}>{benefit}</li>
          ))}
        </ul>
      ),
      title: intl.formatMessage({id: 'packages.createPackage.packageType.benefits'}),
    },
    {
      content: <Text>{result}</Text>,
      title: intl.formatMessage({id: 'packages.createPackage.packageType.result'}),
    },
  ];

  return (
    <CreatePackageCardCheckbox
      autoFocus={autoFocus}
      contentHeight="size-800"
      sections={sections}
      title={title}
      titleDescription={description}
      titleIcon={<Box />}
      value={value}
      width="size-3600"
    />
  );
};

PackageTypeCard.propTypes = {
  /**
   * Whether the element will be auto focused.
   */
  autoFocus: PropTypes.bool.isRequired,
  /**
   * Array of strings that are populated as list in the section below title section
   */
  benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Description of the card
   */
  description: PropTypes.string.isRequired,
  /**
   * String to display in the last section
   */
  result: PropTypes.string.isRequired,
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Package type represented by the card
   */
  value: PropTypes.string.isRequired,
};

export default PackageTypeCard;
