/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.integrations:appIntegrationsDetailSection
 *
 * @description The integration detail section in the integrations drawer
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section.
 * @binding {ApiIntegrationUser} user
 */
(function () {
  angular.module('app.widgets.integrations').component('appIntegrationsDetailSection', {
    bindings: {
      headingLevel: '<?',
      user: '<',
    },
    controller,
    templateUrl:
      'app/widgets/integrations/integrations-detail-section/integrations-detail-section.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      headingLevel: vm.headingLevel || 3,
    });
  }
})();
