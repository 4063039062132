import {DIRECTORY_ENCRYPTION_PROGRESS, DIRECTORY_ENCRYPTION_STATUS, kmi} from '@admin-tribe/binky';
import {GoUrl, showError as showErrorToast} from '@admin-tribe/binky-ui';
import {DialogContainer, Heading, StatusLight, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import ToggleDirectoryEncryptionConfirmationModal from 'features/settings/components/directory/directory-settings-modal/encryption-key-section/ToggleDirectoryEncryptionConfirmationModal';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';

const mapEncryptionInfoToContent = (encryptionInfo) => {
  if (encryptionInfo.encryptionProgressStatus === DIRECTORY_ENCRYPTION_PROGRESS.IN_PROGRESS) {
    return {
      description: encryptionInfo.totalUsers
        ? 'settings.directorySettingsModal.encryptionKeySection.encryptionIsBeingPerformedUsersCount'
        : 'settings.directorySettingsModal.encryptionKeySection.encryptionIsBeingPerformed',
      hasAction: false,
      statusLight: {
        label: 'settings.directorySettingsModal.encryptionKeySection.inProgress',
        variant: 'notice',
      },
    };
  }

  switch (encryptionInfo.encryptionStatus) {
    case DIRECTORY_ENCRYPTION_STATUS.DISABLED:
      return {
        buttonLabel: 'common.modal.buttons.enable',
        description: 'settings.directorySettingsModal.encryptionKeySection.keyCanBeGenerated',
        hasAction: true,
        statusLight: {
          label: 'settings.directorySettingsModal.encryptionKeySection.disabled',
          variant: 'neutral',
        },
      };

    case DIRECTORY_ENCRYPTION_STATUS.ENABLED:
      return {
        buttonLabel: 'settings.directorySettingsModal.encryptionKeySection.revoke',
        description: 'settings.directorySettingsModal.encryptionKeySection.keyCanBeRevoked',
        hasAction: true,
        statusLight: {
          label: 'settings.directorySettingsModal.encryptionKeySection.enabled',
          variant: 'info',
        },
      };

    case DIRECTORY_ENCRYPTION_STATUS.REVOKED:
      return {
        buttonLabel: 'settings.directorySettingsModal.encryptionKeySection.reEnable',
        description: 'settings.directorySettingsModal.encryptionKeySection.keyCanBeReEnabled',
        hasAction: true,
        statusLight: {
          label: 'settings.directorySettingsModal.encryptionKeySection.revoked',
          variant: 'neutral',
        },
      };

    case DIRECTORY_ENCRYPTION_STATUS.FAILED:
      return {
        description: 'settings.directorySettingsModal.encryptionKeySection.keyIsNotAvailable',
        hasAction: false,
        statusLight: {
          label: 'settings.directorySettingsModal.encryptionKeySection.failed',
          variant: 'negative',
        },
      };

    case DIRECTORY_ENCRYPTION_STATUS.NOT_APPLICABLE:
    case DIRECTORY_ENCRYPTION_STATUS.UNKNOWN:
    default:
      return {
        description: 'settings.directorySettingsModal.encryptionKeySection.keyIsNotAvailable',
        hasAction: false,
        statusLight: {
          label: 'settings.directorySettingsModal.encryptionKeySection.notAvailable',
          variant: 'negative',
        },
      };
  }
};

// the entire encryption-key-settings directory and its contents should be removed with temp_org_asset_encryption_migrated
const EncryptionKeySection = ({setIsLoading}) => {
  const intl = useIntl();
  const {directory, reloadDirectory} = useDirectoryDetailsContext();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [directoryEncryptionInfo, setDirectoryEncryptionInfo] = useState({});

  const content = useMemo(
    () => mapEncryptionInfoToContent(directoryEncryptionInfo),
    [directoryEncryptionInfo]
  );

  const canManageIdentityConfig = canChangeIdentityConfig();

  // initial load - get directory encryption info from kmi
  useEffect(
    () => {
      const loadDirectoryEncryptionInfo = async () => {
        setIsLoading(true);

        try {
          const response = await kmi.getEncryptedDomains({
            domainId: directory.id,
            orgId: rootStore.organizationStore.activeOrgId,
          });

          setDirectoryEncryptionInfo(response.data);
        } catch {
          // display UI for unknown/invalid encryption status in case of fetch error
          setDirectoryEncryptionInfo({
            encryptionProgressStatus: DIRECTORY_ENCRYPTION_PROGRESS.NOT_APPLICABLE,
            encryptionStatus: DIRECTORY_ENCRYPTION_STATUS.UNKNOWN,
          });

          showErrorToast(
            intl.formatMessage({
              id: 'settings.directorySettingsModal.encryptionKeySection.toasts.error',
            })
          );
        } finally {
          setIsLoading(false);
        }
      };

      loadDirectoryEncryptionInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
    []
  );

  const onEncryptionToggleConfirm = (updatedEncryptionInfo) => {
    setDirectoryEncryptionInfo(updatedEncryptionInfo);
    reloadDirectory();
  };

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.directorySettingsModal.encryptionKeySection.heading" />
      </Heading>
      <StatusLight variant={content.statusLight.variant}>
        <FormattedMessage id={content.statusLight.label} />
      </StatusLight>
      <FormattedMessage
        id={content.description}
        values={{
          encryptedUsers: directoryEncryptionInfo.encryptedUsers,
          goUrl: (text) => <GoUrl name="aac_directory_encryption_faq">{text}</GoUrl>,
          totalUsers: directoryEncryptionInfo.totalUsers,
        }}
      />
      {content.hasAction && (
        <View marginTop="size-125">
          <ButtonWithContextualHelp
            contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
            isDisabled={!canManageIdentityConfig}
            onPress={() => setIsConfirmationModalOpen(true)}
            variant="accent"
          >
            <FormattedMessage id={content.buttonLabel} />
          </ButtonWithContextualHelp>
        </View>
      )}
      <DialogContainer onDismiss={() => setIsConfirmationModalOpen(false)}>
        {isConfirmationModalOpen && (
          <ToggleDirectoryEncryptionConfirmationModal
            directoryEncryptionInfo={directoryEncryptionInfo}
            onConfirm={onEncryptionToggleConfirm}
          />
        )}
      </DialogContainer>
    </>
  );
};

EncryptionKeySection.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
export default EncryptionKeySection;
