(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ContentPlatform
   * @description Model for managing different endpoints of the content platform
   */
  angular.module('app.core.content-platform').factory('ContentPlatform', getContentPlatformModel);

  /* @ngInject */
  function getContentPlatformModel(
    _,
    contentPlatformIrl1Direct,
    contentPlatformJpn3Direct,
    contentPlatformVa6c2Direct,
    contentPlatformVa6Direct
  ) {
    class ContentPlatform {
      // eslint-disable-next-line consistent-return
      static getProviderBasedOnRegion(host) {
        if (_.includes(host, 'jpn3')) {
          return contentPlatformJpn3Direct;
        } else if (_.includes(host, 'irl1')) {
          return contentPlatformIrl1Direct;
        } else if (_.includes(host, 'va6c2')) {
          return contentPlatformVa6c2Direct;
        } else if (_.includes(host, 'va6')) {
          return contentPlatformVa6Direct;
        }
      }

      static isKnownProvider(host) {
        return _.includes(host, 'jpn3') || _.includes(host, 'irl1') || _.includes(host, 'va6');
      }
    }

    return ContentPlatform;
  }
})();
