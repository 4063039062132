import {useTableSectionContext} from '@admin-tribe/binky-ui';
import {AlertDialog, Button, DialogTrigger} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

/**
 * The `Permanently delete` button on the StorageReportDetailsPage.
 */
const DeleteReportButton = observer(({onDeleteReport}) => {
  const {listStore} = useTableSectionContext(); // a StorageReportListStore
  const intl = useIntl();

  return (
    <DialogTrigger>
      <Button
        data-testid="delete-report-btn"
        isDisabled={listStore.tableStore.selectedItemCount === 0}
        variant="negative"
      >
        <FormattedMessage id="storage.storageReports.details.button.permanentlyDelete" />
      </Button>
      <AlertDialog
        cancelLabel={intl.formatMessage({
          id: 'common.modal.buttons.cancel',
        })}
        level={2}
        onPrimaryAction={onDeleteReport}
        primaryActionLabel={intl.formatMessage({
          id: 'storage.storageReports.deleteReportModal.button.cta',
        })}
        title={intl.formatMessage({
          id: 'storage.storageReports.deleteReportModal.title',
        })}
        variant="destructive"
      >
        <FormattedMessage
          id="storage.storageReports.deleteReportModal.description"
          values={{
            count: listStore.tableStore.selectedItemCount,
          }}
        />
      </AlertDialog>
    </DialogTrigger>
  );
});

DeleteReportButton.propTypes = {
  /**
   * The store for the StorageReportLists.
   */
  onDeleteReport: PropTypes.func.isRequired,
};

export default DeleteReportButton;
