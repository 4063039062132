(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name DirectoryUser
   * @description Model for a directory user.
   */
  angular.module('app.core.directories').factory('DirectoryUser', getDirectoryUserModel);

  // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
  function getDirectoryUserModel(
    $log,
    $q,
    $rootScope,
    _,
    ACCOUNT_STATUSES,
    jilDirectories,
    Member,
    MEMBER_EVENT,
    MODEL,
    modelCache,
    User
  ) {
    const EDITABLE_FIELDS = ['countryCode', 'email', 'firstName', 'lastName', 'userName'];
    class DirectoryUser extends User {
      /**
       * @description Creates a new DirectoryUser.
       *
       * @param {Object} [options] options for the DirectoryUser as detailed below
       * @param {String} [options.directoryId] the id of the directory the user belongs to
       * @param {String} [options.id] the user id
       * @param {String} [options.email] the email address
       * @param {String} [options.firstName] the first name
       * @param {String} [options.lastName] the last name
       * @param {String} [options.countryCode] the country code
       * @param {String} [options.organizations] the orgs a user belongs to
       * @param {String} [options.type] the user type
       * @param {Boolean} [options.editable] True if User is editable
       * @param {String} [options.userName] the user name
       * @param {String} [options.externallyManaged] True if the user has been created from an external system
       * @param {String} [options.accountStatus] Status of this account
       */
      constructor(options = {}) {
        super(options);
        this.organizations = options.organizations;
        this.directoryId = options.directoryId;
        this.externallyManaged = options.externallyManaged;
        this.accountStatus = options.accountStatus;
        registerSavedState(this);
      }

      /**
       * @description Method to determine if there are any unsaved changes to this object.
       *
       * @returns {Boolean} true if there are unsaved changes, else false
       */
      hasUnsavedChanges() {
        const savedBaseState = _.pick(this.savedState, EDITABLE_FIELDS);
        return !_.isEqual(savedBaseState, _.pick(this, EDITABLE_FIELDS));
      }

      /**
       * @description Method to determine if this user is disabled.
       *
       * @returns {Boolean} true if the account status is set to disabled, false otherwise
       */
      isDisabled() {
        return this.accountStatus === ACCOUNT_STATUSES.DISABLED;
      }

      /**
       * @description Method to determine if this user has been created in an
       *              external system (e.g. Azure)
       *
       * @returns {Boolean|*} true if the user comes from an external system, false otherwise
       */
      isExternallyManaged() {
        return this.externallyManaged;
      }

      key() {
        return this.id;
      }

      /**
       * @description Method to refresh this user's state from the back-end.
       *
       * @returns {Promise} resolves to fetched DirectoryUser instance, else
       *                    rejects with error message
       */
      refresh() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;
        this.$resolved = false;

        jilDirectories.directoryUsers.get(
          {directoryId: this.directoryId, userId: this.id},
          onSuccess.bind(this),
          onError.bind(this)
        );

        return this.$promise;

        function onError(error) {
          this.$resolved = true;
          deferred.reject(error);
        }

        function onSuccess(response) {
          _.assignIn(this, DirectoryUser.apiResponseTransformer(response), {
            directoryId: this.directoryId,
          });
          this.$resolved = true;
          modelCache.put(MODEL.DIRECTORYUSER, this, this.key());
          deferred.resolve(this);
        }
      }

      /**
       * @description Method to save updates to a directory user's properties.
       *  NOTE: this should only be used for updating a directory user, create is NOT supported.
       * @param {File} avatar - Avatar file to set for the current directory user
       * @returns {Promise} Resolved promise or rejected promise when resulting batch operation fails
       */
      save(avatar) {
        if (_.isNil(this.id)) {
          this.$promise = $q.reject(
            'Create is not supported for directory users in this code path'
          );
          return this.$promise;
        }

        if (this.hasUnsavedChanges() || avatar) {
          this.resolved = false;
          const promises = [];
          if (avatar) {
            const formData = new FormData();
            formData.append('image', avatar);

            promises.push(
              jilDirectories.directoryUsersAvatar.upload(
                _.omitBy({directoryId: this.directoryId, userId: this.id}, _.isUndefined),
                formData
              ).$promise
            );
          }
          this.$promise = $q
            .all(promises)
            .then(updateUser.bind(this))
            .catch(onError.bind(this))
            .finally(() => {
              this.$resolved = true;
            });
        } else {
          this.$promise = $q.resolve();
        }

        return this.$promise;

        function updateUser() {
          // first we find any directory user state changes
          const operations = getPatchOperationsForUpdate(this);

          return performBatchOperation(this.directoryId, operations)
            .then(onSuccess.bind(this))
            .catch(onError.bind(this));
        }

        function onSuccess() {
          registerSavedState(this);
          $rootScope.$emit(MEMBER_EVENT.UPDATE, this.id);
          return $q.resolve(this);
        }

        function onError(error) {
          $log.error('Failed to update selected directory user. Error: ', error);
          return $q.reject(error);
        }
      }

      /**
       * @description Method to construct a DirectoryUser from a data Object containing user information.
       *
       * @param {Object} responseData Object containing user data
       * @returns {DirectoryUser} Reference to DirectoryUser
       */
      static apiResponseTransformer(responseData) {
        return new DirectoryUser(responseData);
      }

      /**
       * @description Method to retrieve an existing DirectoryUser from
       *              back-end data store.
       *
       * @param {Object} options options for the DirectoryUser as detailed below
       * @param {String} options.id ID of the user to retrieve for this org
       * @param {String} options.directoryId ID of the directory the user belongs to
       *
       * @returns {DirectoryUser} Reference to pre-existing user
       */
      static get(options = {}) {
        let model = modelCache.get(MODEL.DIRECTORYUSER, options.id);
        if (model) {
          return model;
        }
        model = new DirectoryUser(options);
        model.refresh();
        return model;
      }
    }

    // We register the cache size for this class
    Member.setupCache(MODEL.DIRECTORYUSER, 10);

    /**
     * Return the constructor function
     */
    return DirectoryUser;

    /**
     * @description Takes the fields that have been updated for the provided directory user and generates
     *              the corresponding PATCHes.
     * @param {DirectoryUser} user - User whose properties will be used to generate PATCHes
     * @returns {Array<Patch>} Patches that correspond to the updated values for the provided directory user
     */
    function getPatchOperationsForUpdate(user) {
      return _(EDITABLE_FIELDS)
        .filter((fieldKey) => user[fieldKey] !== user.savedState[fieldKey])
        .map((fieldKey) => ({
          op: 'replace',
          path: `/${user.id}/${fieldKey}/${user[fieldKey]}`,
        }))
        .value();
    }

    /**
     * @description Method to perform batch operations against this user.
     *
     * @param {String} directoryId - id of the directory this user belongs to
     * @param {Array<Patch>} operations - list of operations to perform in batch
     *
     * @returns {Promise} resolves if batch operation succeeds, else rejects w/error msg
     */
    function performBatchOperation(directoryId, operations) {
      if (operations && operations.length > 0) {
        return jilDirectories.directoryUsers.batchOperation(
          {directoryId},
          operations,
          onBatchOperationSuccess,
          onBatchOperationError
        ).$promise;
      }
      return $q.resolve();

      function onBatchOperationError(error) {
        logErrorAndRejectPromise(
          error,
          'DirectoryUser.performBatchOperation() failed to perform batch operation(s). Error: '
        );
      }

      function onBatchOperationSuccess() {
        return $q.resolve();
      }

      function logErrorAndRejectPromise(error, message) {
        $log.error(message, error);
        return $q.reject(error);
      }
    }

    /**
     * @description Updates model with a nested form of itself recording state
     *     which may be later modified.
     *
     * @param {Object} model - organization user model to save state on
     */
    function registerSavedState(model) {
      model.savedState = _(model).pick(EDITABLE_FIELDS).cloneDeep();
    }
  }
})();
