(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ApiIntegrationUser
   * @description Model for an api integration user.
   */
  angular
    .module('app.core.api-integration')
    .factory('ApiIntegrationUser', getApiIntegrationUserModel);
  function getApiIntegrationUserModel(
    $q,
    _,
    ApiIntegrationList,
    Member,
    MODEL,
    modelCache,
    OrganizationUser
  ) {
    class ApiIntegrationUser extends OrganizationUser {
      addIntegration(integrations) {
        let foundIntegration;
        if (integrations) {
          foundIntegration = _.find(integrations.items, {technicalAccountId: this.id});
        }
        if (foundIntegration) {
          this.apiKey = foundIntegration.apiKey;
          this.createdDate = foundIntegration.createdDate;
          this.description = foundIntegration.description;
          this.integrationId = foundIntegration.id;
          this.integrationOrgId = foundIntegration.orgId;
          this.firstName = foundIntegration.name;
          this.lastModifiedDate = foundIntegration.lastModifiedDate;
          this.name = foundIntegration.name;
        } else {
          this.name = this.email;
        }
      }

      getDisplayName() {
        return this.name;
      }

      getDisplayNameOrEmail() {
        return this.name;
      }

      refresh() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;
        this.$resolved = false;
        super
          .refresh({delegatePromise: true})
          .then(() => ApiIntegrationList.get().$promise)
          .then((integrations) => {
            this.addIntegration(integrations);
            modelCache.put(MODEL.APINTEGRATION, this, this.key());
            this.$resolved = true;
            deferred.resolve(this);
          })
          .catch((error) => {
            this.$resolved = true;
            deferred.reject(error);
          });
        return deferred.promise;
      }

      save(avatar) {
        return super.save(avatar, {isIntegration: true});
      }

      static get(options = {}) {
        let model = modelCache.get(MODEL.APINTEGRATION, options.userId);
        if (model) {
          return model;
        }

        model = new ApiIntegrationUser({id: options.userId});
        model.refresh();

        return model;
      }

      /**
       * @description Method to construct a ApiIntegrationUser from a data Object containing user information.
       *
       * @param {Object} responseData Object containing user data
       * @returns {ApiIntegrationUser} Reference to ApiIntegrationUser
       */
      static apiResponseTransformer(responseData) {
        return new ApiIntegrationUser(super.apiResponseTransformer(responseData));
      }
    }

    Member.setupCache(MODEL.APINTEGRATION, 10);

    return ApiIntegrationUser;
  }
})();
