(function () {
  /**
   * @deprecated should use the React Spectrum Tag
   *
   * @ngdoc component
   * @name app.widgets.tag:appTrialTag
   * @description A truncate-able tag component styled for trials.
   *
   * @param {string} [size] size of that tag. One of 'L', 'M' (default), 'S', 'XS'.
   *
   * @example <app-trial-tag size="S"></app-trial-tag>
   *  To truncate the tag use the set-coral-tag-max-width mixin.
   *    app-trial-tag {
   *      @include set-coral-tag-max-width(rem(120px));
   *    }
   */
  angular.module('app.widgets.trial-tag').component('appTrialTag', {
    bindings: {
      size: '@?',
    },
    templateUrl: 'app/widgets/common/trial-tag/trial-tag.component.html',
  });
})();
