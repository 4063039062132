import {canAssignAdmin} from 'core/admin/access/adminAccess';
import {PATH_ADMINISTRATORS} from 'features/users/routing/usersPaths';

import help from '../../constants/help';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> add admin
 */
const teamAddAdmin = () => ({
  access: canAssignAdmin,
  content: [
    {
      links: [
        {
          key: 'help.topics.teamAddAdmin.link.one.key',
          locator: PATH_ADMINISTRATORS,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
      ],
      text: 'help.topics.teamAddAdmin.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.TEAM_ADD_ADMIN,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.teamAddAdmin.title',
});

export default teamAddAdmin;
