(function () {
  /**
   * @ngdoc module
   * @name app.core.api.support-anyware
   * @description module that defines Support Anyware services
   */
  angular.module('app.core.api.support-anyware', [
    'app.core.api',
    'app.core.auth',
    'binky.core.api.utils',
    'binky.core.lodash',
    'ngResource',
  ]);
})();
