(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.asset-sharing')
    .constant('DNS_MAX_DOMAIN_LEN', 255)
    .constant('DNS_MAX_DOMAIN_PART_LEN', 63)
    .constant(
      'DOMAIN_REGEX',
      /^[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+(\.[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+)+$/
    );
})();
