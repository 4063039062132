import {feature} from '@admin-tribe/binky';
import {Flex, Grid, VisuallyHidden} from '@adobe/react-spectrum';
import {DividerPlacement} from '@pandora/react-titled-section';
import React from 'react';
import {useIntl} from 'react-intl';
import {Outlet} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';
import rootStore from 'core/RootStore';
import ResponsiveProvider from 'features/overview/shell/container/ResponsiveProvider';

import {
  HelpfulResourcesSection,
  HvaSummarySection,
  ModalManager,
  ProductsSummarySection,
  QuickLinksSection,
  SophiaBanner,
  SophiaRightRailMerchandisingSection,
  StorageSummarySection,
  UpdatesSummarySection,
  UsersSummarySection,
} from '../components';

/**
 * The Overview page
 */
const OverviewPage = () => {
  const contentGap = 'size-300'; // 24px
  const intl = useIntl();

  useDocumentTitle({key: 'overview.title'});
  usePageAnalytics({name: 'overview'});

  return (
    <ResponsiveProvider>
      <ModalManager />
      <Flex data-testid="overview-page" justifyContent="center" width="100%">
        <VisuallyHidden>
          <h1>
            {intl.formatMessage(
              {id: 'overview.heading'},
              {orgName: rootStore.organizationStore.activeOrg.name}
            )}
          </h1>
        </VisuallyHidden>
        <Grid
          columns={{
            base: '1fr',
            L: '3fr 1fr',
          }}
          data-testid="overview-content"
          flexBasis="1440px" // must use px here, calc does not work
          gap={contentGap}
        >
          <Flex
            data-testid="overview-content-sidebar"
            direction="column"
            gap={contentGap}
            isHidden={{
              base: false,
              L: false,
            }}
          >
            <Grid
              columns={{
                base: '1fr',
                // make columns equal width
                L: 'minmax(0, 1fr) minmax(0, 1fr)',
                M: 'minmax(0, 1fr) minmax(0, 1fr)',
              }}
              data-testid="overview-content-main"
              gap={contentGap}
            >
              <HvaSummarySection />
              <ProductsSummarySection />
              <UsersSummarySection />
              <SophiaBanner />
              <StorageSummarySection />
              <UpdatesSummarySection />
              <HelpfulResourcesSection
                dividerPlacement={DividerPlacement.NONE}
                isHidden={{
                  base: false,
                  L: true,
                }}
              />
            </Grid>
          </Flex>
          <Flex
            data-testid="overview-content-sidebar"
            direction="column"
            gap={contentGap}
            isHidden={{
              base: false,
              L: false,
            }}
          >
            <QuickLinksSection dividerPlacement={DividerPlacement.BOTTOM} />
            {feature.isEnabled('temp_use_right_rail_merchandising_section') && (
              <SophiaRightRailMerchandisingSection
                dividerPlacement={DividerPlacement.BOTTOM}
                isHidden={{
                  base: true,
                  L: false,
                }}
              />
            )}
            <HelpfulResourcesSection dividerPlacement={DividerPlacement.BOTTOM} />
          </Flex>
          {/* Spacer to prevent footer from covering content - remove once there is a global fix in the shell */}
          {/* <View height="size-2000" />*/}
        </Grid>
        {/* arbitrary location to render Outlet since currently known child routes are inside a modal */}
        {feature.isEnabled('temp_standalone_quick_assign') && <Outlet />}
      </Flex>
    </ResponsiveProvider>
  );
};

OverviewPage.propTypes = {};

export default OverviewPage;
