import {
  AuthenticatedUser,
  ORGANIZATION_MARKET_SEGMENT,
  feature,
  hasEnterpriseProducts,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canViewDirectories, canViewDomains} from 'core/directories/access/directoryAccess';
import {
  canViewAssetSharing,
  canViewEncryption,
  canViewPasswordPolicy,
} from 'core/organizations/access/organizationAccess';
import auth from 'core/services/auth';
import {activeOrgHasESM} from 'core/storage/access/storageAccess';

import {canViewSharedProjects} from './sharedProjectsAccess';

/**
 * @description Determine if user has access to Asset Settings pages
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewAssetSettings() {
  return allPromisesResolveTrue([canViewSettings(), canViewAssetSharing()]);
}

/**
 * @description Determine if user has access the Asset Settings Credentials subpage
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewAssetCredentials() {
  const activeOrg = rootStore.organizationStore.activeOrg;
  const isCommercialEnterprise = () =>
    hasEnterpriseProducts(rootStore.organizationStore.productList) &&
    activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.COMMERCIAL;

  if (feature.isEnabled('temp_cai_gov_hed')) {
    const isGovernment = activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.GOVERNMENT;
    const isHigherEducation =
      activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION &&
      !activeOrg.tags.includes('edu_k12');
    const assetCredentialsAllowed = isGovernment || isHigherEducation || isCommercialEnterprise();
    return allPromisesResolveTrue([canViewAssetSettings(), assetCredentialsAllowed]);
  }

  return allPromisesResolveTrue([canViewAssetSettings(), isCommercialEnterprise()]);
}

/**
 * @description Determine if user has access the Asset Settings Migration subpage
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewAssetMigration() {
  const isNonEsmEdu = async () =>
    !(await activeOrgHasESM()) && rootStore.organizationStore.isActiveOrgEdu;
  return allPromisesResolveTrue([canViewAssetSettings(), isNonEsmEdu()]);
}

/**
 * @description Determine if user has access to the Password Preferences (Authentication) settings page
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewAuthentication() {
  return allPromisesResolveTrue([canViewSettings(), canViewPasswordPolicy()]);
}

/**
 * @description Method to determine if user has access to the Console Settings page
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewConsole() {
  return allPromisesResolveTrue([canViewSettings(), isUserOrgAdminForOrg()]);
}

/**
 * @description Determine if user has access to Encryption Settings page
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewEncryptionSettings() {
  return allPromisesResolveTrue([canViewSettings(), canViewEncryption()]);
}

/**
 * @description Determine if user has access to Identity Settings pages
 *
 * @returns {Promise} Resolves to true if user has access
 */
async function canViewIdentity() {
  const canViewIdentityDirectories = await canViewDirectories();

  if (auth.isUserOrgAdmin()) {
    const canViewIdentityDomains = await canViewDomains();

    return canViewIdentityDomains || canViewIdentityDirectories;
  }

  return canViewIdentityDirectories;
}

/**
 * @description Determine if user has access to the Security Contacts page
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewSecurityContacts() {
  return allPromisesResolveTrue([canViewSettings(), isUserOrgAdminForOrg()]);
}

/**
 * @description Method to determine if user has access to the Shared-Project Policies page
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewSharedProjectPolicies() {
  return allPromisesResolveTrue([canViewSettings(), canViewSharedProjects()]);
}

/**
 * @description Method to determine if user has access to Settings related content.
 *              This function is a prerequisite for all the page level accessors.
 *
 * @returns {Promise} Resolves to true if user has access
 */
function canViewSettings() {
  return Promise.resolve(
    !rootStore.organizationStore.isActiveOrgDeveloper &&
      (isUserOrgAdminForOrg() || isStorageAdminForOrg())
  );
}

/* Private Functions */

function allPromisesResolveTrue(promises) {
  // eslint-disable-next-line promise/prefer-await-to-then -- Promise.all is a good way to support parallel requests
  return Promise.all(promises).then((results) => results.every((result) => result));
}

function isUserOrgAdminForOrg() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUserRoles = AuthenticatedUser.get().getRoles();
  return currentUserRoles.isOrgAdminForOrg(orgId);
}

function isStorageAdminForOrg() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUserRoles = AuthenticatedUser.get().getRoles();
  return currentUserRoles.isStorageAdminForOrg(orgId);
}

export {
  canViewAssetCredentials,
  canViewAssetMigration,
  canViewAssetSettings,
  canViewAuthentication,
  canViewConsole,
  canViewEncryptionSettings,
  canViewIdentity,
  canViewSecurityContacts,
  canViewSettings,
  canViewSharedProjectPolicies,
};
