(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.section-with-icon:appSectionWithIcon
   * @description A component to display a formatted section with an icon, title, and transcluded content
   *
   * @binding {String|undefined} iconName. The icon name. See:
   *   https://helpx.adobe.com/experience-manager/6-3/sites/developing/using/reference-materials/coral-ui/coralui3/Coral.Icon.html#availableIcons
   * @binding {String|undefined} iconSize. The coral icon size. Default is 'S'.
   * @binding {String} sectionTitle. The translated title to be used for the section title.
   * @binding {promise|undefined} waitPromise. If specified, a wait spinner will be shown until the promise resolves.
   * @param {Transclude} sectionIcon A transclusion for the section icon. Optional.
   * @param {Transclude} sectionBody A transclusion for the section body.
   *
   * @example      <app-section-with-icon
   *                  icon-name="{{ $ctrl.iconName }}"
   *                  section-title="{{ $ctrl.title }}"
   *                  wait-promise="$ctrl.sectionWithIconDeferred.promise">
   *                  <section-body>
   *                    <p>You'll be charged <strong>$164.99</strong> today for one-time-purchase.</p>
   *                    <binky-button>Demo Including Component</binky-button>
   *                  </section-body>
   *                </app-section-with-icon>
   */
  angular.module('app.widgets.section-with-icon').component('appSectionWithIcon', {
    bindings: {
      iconName: '@?',
      iconSize: '@?',
      sectionTitle: '@',
      waitPromise: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/section-with-icon/section-with-icon.component.html',
    transclude: {
      'section-body': 'sectionBody',
      'section-icon': '?sectionIcon',
    },
  });

  /* @ngInject */
  function controller(_, $transclude) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      vm.showSectionIcon = $transclude.isSlotFilled('section-icon') && !vm.iconName;
    }
  }
})();
