import {Locale, feature} from '@admin-tribe/binky';
import {GoUrl, PageBanner} from '@admin-tribe/binky-ui';
import {Button, Flex} from '@adobe/react-spectrum';
import {getContractTermsAcceptance} from '@pandora/data-model-contract';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {isContractAdmin, isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {redirectToAccept3YC} from 'features/termsRedirect/termsRedirect';

/**
 * VIP MP 3YC invitation box
 * @param {String} contractId - Current Contract ID
 * @returns {React.Element|null} - 3YC Banner or null if the user isn't a contract owner and eligible for 3YC
 */
const ThreeYearCommitBanner = ({contractId}) => {
  const intl = useIntl();
  const {activeOrgId: orgId, contractList} = rootStore.organizationStore;
  const contract = useMemo(
    () => contractList.items.find((item) => item.id === contractId),
    [contractId, contractList]
  );

  function openOfferLink() {
    const locale = Locale.get().activeLocaleCode;
    if (feature.isEnabled('temp_terms_redirect')) {
      redirectToAccept3YC({locale, orgId});
    } else {
      const {acceptanceURL} = contract.get3YCInfo('ELIGIBLE');
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window.open(acceptanceURL, '_self', 'noopener,noreferrer');
    }
  }

  // The VIP3YC status is either Eligible or Enrolled.
  // If the customer is eligible for 3YC VIP, show banner of invitation to join.
  // Otherwise, customer has enrolled to 3YC VIP. Check if the customer needs to re-accept the terms.
  const closeLabel = showThreeYearCommitReacceptanceBanner(contract)
    ? intl.formatMessage({id: 'common.threeYearCommitBanner.reaccept'})
    : intl.formatMessage({id: 'common.threeYearCommitBanner.joinNow'});
  const header = intl.formatMessage({id: 'common.threeYearCommitBanner.title'});

  if (
    feature.isDisabled('temp_vip_3yc') ||
    !(isOrgAdmin() || isContractAdmin()) ||
    !(showThreeYearCommitBanner(contract) || showThreeYearCommitReacceptanceBanner(contract))
  )
    return null;

  return (
    // the default button for the PageBanner doesn't meet the XD style requirements so showDefaultButton is set to false
    <PageBanner header={header} showDefaultButton={false}>
      {intl.formatMessage(
        {id: 'common.threeYearCommitBanner.description'},
        {goUrl: (str) => <GoUrl name="aac_3ycp_learn">{str}</GoUrl>}
      )}
      <Flex justifyContent="end">
        <Button data-testid="join-now-button" onClick={openOfferLink} variant="primary">
          {closeLabel}
        </Button>
      </Flex>
    </PageBanner>
  );
};

/**
 * Determines whether the user should be shown the 3YC banner
 * @param {String} contractId - Current contract
 * @returns {boolean}
 */
function showThreeYearCommitBanner(contract) {
  return contract.isEligibleFor3YC();
}

/**
 * Determines whether the user shoule be shown the re-acceptance banner
 * @param {String} contractId - Current contract
 * @returns {boolean}
 */
function showThreeYearCommitReacceptanceBanner(contract) {
  if (feature.isEnabled('temp_3yc_needs_reaccept')) {
    return (
      contract.isEnrolledIn3YC() &&
      getContractTermsAcceptance(contract, 'tc_vip3yc_indirect')?.needsAcceptance?.needed
    );
  }
  return false;
}

ThreeYearCommitBanner.propTypes = {
  contractId: PropTypes.string,
};

export default ThreeYearCommitBanner;
