import {COMPARTMENT_ADMIN, COMPARTMENT_VIEWER, jilUsers, log, modelCache} from '@admin-tribe/binky';
import pick from 'lodash/pick';

const CACHE_ID = 'GLOBAL_ADMIN_ROLES';

/**
 * GlobalAdminRoles represents the Global Admin roles that a user has access to.
 */
class GlobalAdminRoles {
  /**
   * @description Get either cached or a new instance of GlobalAdminRoles.
   * @param {Object} options See constructor for details
   * @returns {Promise} Promise that resolves with the GlobalAdminRoles instance.
   */
  static get(options) {
    const newInstance = new GlobalAdminRoles(options);

    const cacheKey = newInstance.getModelKey();
    if (modelCache.has(CACHE_ID, cacheKey)) {
      const cachedPromise = modelCache.get(CACHE_ID, cacheKey);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    const promise = newInstance.refresh();
    modelCache.put(CACHE_ID, cacheKey, promise);
    return promise;
  }

  /**
   * @description Check whether the current user has COMPARTMENT roles.
   * @param {String} orgId Org ID to check for
   * @returns {Promise<Boolean>} true if the user has any COMPARTMENT roles, false otherwise.
   */
  static async hasCompartmentRole(orgId) {
    const globalAdminRoles = await GlobalAdminRoles.get({orgId});
    return globalAdminRoles.hasAnyRole([COMPARTMENT_ADMIN, COMPARTMENT_VIEWER]);
  }

  /**
   * @description Constructor
   * @param {Object} options Options for initialization
   * @param {String} options.orgId Org ID
   */
  constructor(options) {
    this.orgId = options.orgId;
    this.effectiveRoles = [];
  }

  /**
   * @description Generate cache key
   * @returns {String} Unique cache key
   */
  getModelKey() {
    return `orgId:${this.orgId}`;
  }

  /**
   * Check whether any of 'roles' exists
   * @param {Array<String>} roles List of roles to check
   * @returns {Boolean} true if any of the 'roles' exists.
   * false if none of the 'roles' exists.
   */
  hasAnyRole(roles) {
    return !!this.effectiveRoles.find((item) => roles.includes(item.roleCode));
  }

  /**
   * @description Refresh data from API
   * @returns {Promise<GlobalAdminRoles>} The current instance
   */
  async refresh() {
    try {
      const response = await jilUsers.getGlobalAdminRoles({orgId: this.orgId});
      onQuerySuccess.bind(this)(response);
    } catch (error) {
      onQueryError.bind(this)(error);
    }

    return this;

    ////////

    function onQueryError(error) {
      log.error(
        `GlobalAdminRoles.refresh(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      throw error;
    }

    function onQuerySuccess(response) {
      initModel(this, response.data);
    }
  }
}

function initModel(model, options) {
  Object.assign(model, pick(options, ['effectiveRoles']));
}

export default GlobalAdminRoles;
