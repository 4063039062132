(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.user').constant('ROLE', {
    ADMIN: {
      COMPARTMENT_ADMIN: 'COMPARTMENT_ADMIN',
      COMPARTMENT_VIEWER: 'COMPARTMENT_VIEWER',
      CONTRACT: 'CONTRACT_ADMIN',
      DEPLOYMENT: 'DEPLOYMENT_ADMIN',
      LICENSE: 'LICENSE_ADMIN',
      LICENSE_DEV: 'LICENSE_DEV_ADMIN',
      ORG: 'ORG_ADMIN',
      PRODUCT: 'PRODUCT_ADMIN',
      PRODUCT_SUPPORT: 'PRODUCT_SUPPORT_ADMIN',
      STORAGE: 'STORAGE_ADMIN',
      SUPPORT: 'SUPPORT_ADMIN',
      SUPPORTS: 'SUPPORT_ADMINS',
      USER_GROUP: 'USER_GROUP_ADMIN',
    },
    AGENT: {
      ADOBE_AGENT_ADMIN: 'ADOBE_AGENT_ADMIN',
      ADOBE_AGENT_CUSTOMER_CARE: 'ADOBE_AGENT_CUSTOMER_CARE',
      ADOBE_AGENT_PROFESSIONAL_SERVICES: 'ADOBE_AGENT_PROFESSIONAL_SERVICES',
      ADOBE_AGENT_PROVISIONER: 'ADOBE_AGENT_PROVISIONER',
      ADOBE_AGENT_READ: 'ADOBE_AGENT_READ',
      ADOBE_AGENT_RESELLER_LICENSING: 'ADOBE_AGENT_RESELLER_LICENSING',
    },
    NONE: 'NONE',
  });
})();
