import {feature, log} from '@admin-tribe/binky';
import {useDomainMappingList} from '@pandora/data-model-acrs';
import {Directory, useOrganizationDirectoryList} from '@pandora/data-model-directory';
import {useOrganizationDomainList} from '@pandora/data-model-domain';
import {useAsyncModel} from '@pandora/react-async-model';
import {useAcrsRules} from '@pandora/react-data-source-acrs';
import {useCallback, useEffect, useState} from 'react';

import rootStore from 'core/RootStore';

/**
 * A hook that provides the current rule details list that contains the rule's
 * related products, directories, and domains. The organization's directories
 * and domains are also provided.
 *
 * @returns {Object} state - Object with state variables
 *          {Array<Directory>} state.directories - the organization's directories with domains
 *          {Error} state.error - the error if fetching fails
 *          {Boolean} state.isLoading - true if lists are being fetched
 *          {Array<RuleDetails>} state.ruleDetailsList - the list of rules with related details like its directories and product.
 */
const useRuleDetailsList = () => {
  const {getAllAcrsRules} = useAcrsRules();
  const loadRuleList = useCallback(async () => {
    const response = await getAllAcrsRules({orgId: rootStore.organizationStore.activeOrgId});
    return response.json();
  }, [getAllAcrsRules]);
  const {
    data: fetchedEmailDomains,
    error: fetchedEmailDomainsError,
    loading: isFetchingEmailDomains,
  } = useDomainMappingList({orgId: rootStore.organizationStore.activeOrgId});
  const {
    data: fetchedDirectories,
    error: fetchedDirectoriesError,
    loading: isFetchingDirectories,
  } = useOrganizationDirectoryList({orgId: rootStore.organizationStore.activeOrgId});
  const {
    data: fetchedDomains,
    error: fetchedDomainsError,
    loading: isFetchingDomains,
  } = useOrganizationDomainList({orgId: rootStore.organizationStore.activeOrgId});
  const {
    model: fetchedRules,
    isLoading: isLoadingRules,
    error: fetchedRuleListError,
    updateModel: updateRules,
  } = useAsyncModel({
    loadFn: loadRuleList,
  });

  const [directories, setDirectories] = useState(undefined);
  const [emailDomains, setEmailDomains] = useState(undefined);
  const [ruleDetailsList, setRuleDetailsList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const filterRelatedDomains = useCallback((unfilteredDirectory, ruleDirectories) => {
    const ruleDomainNames = ruleDirectories?.find(
      (ruleDirectory) => ruleDirectory?.directoryId === unfilteredDirectory.id
    )?.domainNames;

    let filteredDomains = unfilteredDirectory.domains;
    // Only a subset of domains are included in the rule
    if (ruleDomainNames?.length > 0) {
      filteredDomains = filteredDomains?.filter((domain) =>
        ruleDomainNames.includes(domain.domainName)
      );
    }
    return filteredDomains;
  }, []);

  const combineDirectoriesAndDomains = useCallback(
    ({orgDirectories, orgDomains}) =>
      orgDirectories?.items?.map(
        (directory) =>
          new Directory({
            ...directory,
            domains: orgDomains?.items?.filter((domain) => domain.directoryId === directory.id),
          })
      ),
    []
  );

  // Setting the table items list for the auto product assignment table
  useEffect(() => {
    if (
      !isFetchingDirectories &&
      !isFetchingDomains &&
      !(feature.isEnabled('temp_jit_domain_mapping') && isFetchingEmailDomains) &&
      !isLoadingRules
    ) {
      const currentDirectories = combineDirectoriesAndDomains({
        orgDirectories: fetchedDirectories,
        orgDomains: fetchedDomains,
      });

      const currentRuleDetailsList = fetchedRules?.map((rule) => {
        // Retrieve product and its info that is tied to the rule
        const product = rootStore.organizationStore.productList.items?.find(
          (currentProduct) => currentProduct.id === rule.licenseId
        );

        // Retrieve directories included in this rule
        const currentDirectoriesInRule =
          currentDirectories?.filter((directory) =>
            // Directory id is included in the rule's directories
            rule.directories?.some((ruleDirectory) => ruleDirectory.directoryId === directory.id)
          ) ?? [];

        rule.directories?.forEach((directory) => {
          if (currentDirectoriesInRule.every(({id}) => id !== directory.directoryId)) {
            // This blank object represents a rule's directory
            // that is no longer associated with the org
            currentDirectoriesInRule.push({});
          }
        });

        // Reduce retrieved directories to only include domains used by the rule
        const ruleDirectoriesWithRelatedDomains = currentDirectoriesInRule.map((directory) => ({
          ...directory,
          domains: filterRelatedDomains(directory, rule.directories),
        }));
        return {
          ...rule,
          directoryList: ruleDirectoriesWithRelatedDomains,
          product,
        };
      });

      setDirectories(currentDirectories);
      if (feature.isEnabled('temp_jit_domain_mapping')) {
        setEmailDomains(fetchedEmailDomains);
      }
      setRuleDetailsList(currentRuleDetailsList);
      setIsLoading(false);
    }
  }, [
    combineDirectoriesAndDomains,
    fetchedDirectories,
    fetchedDomains,
    fetchedEmailDomains,
    fetchedRules,
    filterRelatedDomains,
    isFetchingEmailDomains,
    isFetchingDirectories,
    isFetchingDomains,
    isLoadingRules,
  ]);

  // Log any errors that occurred
  useEffect(() => {
    setError(undefined);
    // The order of the errors does not matter
    if (fetchedDirectoriesError) {
      log.error(
        `Error getting directories for auto-product assignment rules: ${fetchedDirectoriesError}`
      );
      setError(fetchedDirectoriesError);
    }

    if (fetchedDomainsError) {
      log.error(`Error getting domains for auto-product assignment rules: ${fetchedDomainsError}`);
      setError(fetchedDomainsError);
    }

    if (feature.isEnabled('temp_jit_domain_mapping') && fetchedEmailDomainsError) {
      log.error(
        `Error getting email domains for auto-product assignment rules: ${fetchedEmailDomainsError}`
      );
      setError(fetchedEmailDomainsError);
    }

    if (fetchedRuleListError) {
      log.error(`Error getting auto-product assignment rules: ${fetchedRuleListError}`);
      setError(fetchedRuleListError);
    }
  }, [
    fetchedDirectoriesError,
    fetchedDomainsError,
    fetchedEmailDomainsError,
    fetchedRuleListError,
  ]);

  return {
    directories,
    emailDomains,
    error,
    isLoading,
    refetchRuleDetailsList: () => updateRules(loadRuleList),
    ruleDetailsList,
  };
};

export default useRuleDetailsList;
