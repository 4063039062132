/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories:directorySyncDomainsTable
   * @description allow management of which Directory Sync domains should be synchronized.
   */
  angular.module('app.widgets.directories.sync').component('appDirectorySyncDomainsTable', {
    bindings: {
      authSrcCode: '<',
      confirmUnsync: '<',
      hideSaveButton: '<',
      onError: '&?',
      onProcessing: '&?',
      onProcessingDone: '&?',
      onSave: '&?',
      onSelectionChange: '&?',
      partnerToken: '<',
      partnerType: '<',
      saveOnToggle: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/sync/directory-sync-domains-table/directory-sync-domains-table.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    _,
    $element,
    $q,
    $log,
    $rootScope,
    $scope,
    feature,
    directorySync,
    DIRECTORY_SYNC_DOMAINS_ERROR_TYPES,
    DIRECTORY_SYNC_DOMAINS_RETRY_GET_EVENT,
    DIRECTORY_SYNC_DOMAINS_SAVE_EVENT,
    DirectorySyncDomain,
    DIRECTORY_SYNC_EVENT,
    panelManager,
    REMOVE_SYNCED_DOMAIN_MODAL_ID
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      getDomains,
      getSelectAllButtonLabel,
      hasDomains,
      hasSelectableDomains,
      isDomainProcessing,
      isInSync,
      onPageChange,
      onPageSizeChange,
      onSearch: _.debounce(onSearch, 200),
      onSelectAll,
      onSyncChange,
      onUnlinkCanceled,
      onUnlinkConfirmed,
      saveDomains: feature.isEnabled('temp_link_one_domain') ? undefined : saveDomains,
      showDenySyncLabel,
      showInSyncLabel,
      showNotSyncedLabel,
      showSelectAllButton,
    });

    // Store unfiltered domains in order to restore data after filtering, see `onSearch()`.
    let unfilteredDomains = null;

    // Store domains that have been selected for sync.
    // This is used when `saveOnToggle` is turned off
    let domainsLinked = [];

    function $onInit() {
      _.defaults(
        vm,
        {
          canSelectAllDomains: true,
          currentPage: 1,
          domains: [],
          domainsProcessedWithError: [],
          domainsProcessingQueue: [],
          getDomainsError: false,
          hideSaveButton: feature.isEnabled('temp_link_one_domain') ? true : vm.hideSaveButton,
          invalidAuthSrcCode: false,
          isProcessing: false,
          isSearchApplied: false,
          itemsPerPage: 10,
          showContentWhileLoading: false,
          waitPromise: $q.resolve(),
        },
        {
          confirmUnsync: true,
          saveOnToggle: true,
        }
      );

      // Make sure processing callbacks are functions to avoid type checking each time.
      vm.onError = vm.onError || angular.noop;
      vm.onProcessing = vm.onProcessing || angular.noop;
      vm.onProcessingDone = vm.onProcessingDone || angular.noop;
      vm.onSave = vm.onSave || angular.noop;
      vm.onSelectionChange = vm.onSelectionChange || angular.noop;

      getDomains();
      setupEventHandlers();
    }

    function onPageChange(val) {
      vm.currentPage = _.parseInt(val);
    }

    function onPageSizeChange(val) {
      vm.itemsPerPage = _.parseInt(val);
    }

    function onSelectAll() {
      if (hasSelectableDomains()) {
        domainsLinked = getSelectableDomains(unfilteredDomains);
      } else {
        domainsLinked.length = 0;
      }

      // Reset search to avoid a situation where no selectable domain is visible
      // thus selecting domains that are not on screen.
      resetSearch();

      vm.onSelectionChange({selectedDomains: domainsLinked});
    }

    // Check if there are domains that can be selected.
    // This is used to know in which direction to toggle: all on or all off.
    // As well as for the UI to know which button label to show.
    function hasSelectableDomains() {
      const selectableDomainsCount = getSelectableDomains(unfilteredDomains).length;
      const selectedDomainsCount = domainsLinked.length;

      return selectableDomainsCount > selectedDomainsCount;
    }

    // Check if there are any domains as to show or hide the "Select All" button.
    // Note we're not looking at `vm.domains` which only represents what's on screen.
    // For example, it'd be empty when a non-matching search query is applied.
    function hasDomains() {
      return !_.isEmpty(unfilteredDomains);
    }

    // Called when a domain's `binky-toggle` changes state.
    // Keeps `domainsLinked` up to date.
    function onSyncChange(changedDomain) {
      if (feature.isEnabled('temp_link_one_domain') && vm.saveOnToggle) {
        // mark this domain as being processed
        addDomainToQueue(changedDomain);
        vm.onProcessing();

        if (isInSync(changedDomain)) {
          unlinkDomain(changedDomain);
        } else {
          linkDomain(changedDomain);
        }
      } else {
        // Remove domain if already in the list or add it otherwise.
        if (_.find(domainsLinked, {id: changedDomain.id})) {
          _.remove(domainsLinked, {id: changedDomain.id});
        } else {
          domainsLinked.push(changedDomain);
        }

        vm.onSelectionChange({selectedDomains: domainsLinked});
      }
    }

    function onSearch(query) {
      if (!query || !_.isString(query)) {
        vm.domains = unfilteredDomains.slice(0); // Create new array instance but preserve array item instances.
        vm.isSearchApplied = false;
      } else {
        const lowerCaseQuery = _.toLower(query);
        const filterFn = (domain) =>
          domain.name && _(domain.name).toLower().includes(lowerCaseQuery);

        vm.domains = _.filter(unfilteredDomains, filterFn);
        vm.isSearchApplied = true;
      }

      // Tell Angular something changed since we're being called by lodash's _.debounce.
      $scope.$evalAsync();
    }

    function getDomains() {
      if (vm.authSrcCode) {
        setProcessingState();

        vm.waitPromise = directorySync
          .domains(vm.partnerType, vm.partnerToken)
          .query({authSrcCode: vm.authSrcCode})
          .$promise.then((domains) => {
            vm.showContentWhileLoading = true; // Show data under binky-wait now that we have it.
            vm.invalidAuthSrcCode = false;
            vm.getDomainsError = false;

            unfilteredDomains = _.map(domains, (domain) => new DirectorySyncDomain(domain));
            vm.domains = unfilteredDomains.slice(0); // Create new array instance but preserve array item instances.

            // Store linked domains. List will be invalidated in `onSyncChange()`.
            domainsLinked = _.filter(vm.domains, {isLinked: true});

            vm.canSelectAllDomains = _.reduce(
              vm.domains,
              (canSelectAllDomains, domain) =>
                canSelectAllDomains || ((domain.isFree || domain.isLinked) && domain.isVerified),
              false
            );
          })
          .catch((error) => {
            $log.error('[AD Sync] Error fetching domains.', error);

            const errorType =
              error.status === 401
                ? DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.UNAUTHORIZED_ERROR
                : DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.FETCH_ERROR;

            vm.onError({err: new Error(errorType)});
            vm.getDomainsError = true;
          })
          .finally(unsetProcessingState);
      } else {
        vm.invalidAuthSrcCode = true;
        vm.onError({err: new Error(DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.FETCH_ERROR)});
      }
    }

    function linkDomain(domain) {
      domain.isLinked = true;
      const domainProcessed = getDomainProcessedFunction();

      // We call the domainProcessed function in finally because we take a
      // note of the state of the domain that was saved (success or error)
      // and then display a success/fail toast based on that in
      // the domainProcessed handler
      saveDomain(domain).finally(() => domainProcessed(domain));
    }

    function unlinkDomain(domain) {
      domain.isLinked = false;

      if (vm.confirmUnsync) {
        panelManager.open(REMOVE_SYNCED_DOMAIN_MODAL_ID, {domain});
      } else {
        onUnlinkConfirmed(domain);
      }
    }

    function onUnlinkConfirmed(domain) {
      if (panelManager.isOpen(REMOVE_SYNCED_DOMAIN_MODAL_ID)) {
        panelManager.close(REMOVE_SYNCED_DOMAIN_MODAL_ID);
      }

      // If unsync needs to be confirmed don't debounce onDomainProcessed.
      // This is done so we don't add uneccessary wait time, user does not have
      // time to click fast through toggles anyway.
      const domainProcessed = vm.confirmUnsync ? onDomainProcessed : getDomainProcessedFunction();
      deleteDomain(domain).finally(() => domainProcessed(domain));
    }

    function onUnlinkCanceled(domain) {
      domain.isLinked = true;

      // Remove domain from processing queue
      _.remove(vm.domainsProcessingQueue, {id: domain.id});
    }

    function onDomainProcessed(savedDomain) {
      // remove domain from processing queue
      _.remove(vm.domainsProcessingQueue, {id: savedDomain.id});

      const hasError = _.find(vm.domainsProcessedWithError, {id: savedDomain.id});
      const isProcessing = vm.domainsProcessingQueue.length > 0;

      if (hasError) {
        savedDomain.isLinked = !savedDomain.isLinked;
      }

      if (!isProcessing) {
        if (vm.domainsProcessedWithError.length > 0) {
          vm.onProcessingDone();
          vm.onError({err: new Error(DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.SUBMIT_ERROR)});

          // we processed the errored domains so clear them
          vm.domainsProcessedWithError.length = 0;
        } else {
          // we finished processing domains without any error
          // so call the done and save handlers
          vm.onProcessingDone();
          vm.onSave();
        }

        updateSelection();
      }

      // Tell angular something changed since we're being
      // called by lodash's _.debounce.
      $scope.$evalAsync();
    }

    function saveDomains() {
      setProcessingState();
      vm.waitPromise = directorySync
        .linkDomains(vm.partnerType, vm.partnerToken)
        .save(
          {authSrcCode: vm.authSrcCode},
          // POST API takes an array of domain names to import.
          _.map(domainsLinked, 'name')
        )
        .$promise.then(() => {
          // Unset processing state before triggering `onSave()`.
          unsetProcessingState();

          // trigger the DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED event to clear the domain
          // and directory cache because a new domain has been synced
          $rootScope.$emit(DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED);

          vm.onSave();
        })
        .catch((error) => {
          $log.error('[AD Sync] Error saving domains.', error);

          unsetProcessingState();
          vm.onError({err: new Error(DIRECTORY_SYNC_DOMAINS_ERROR_TYPES.SUBMIT_ERROR)});
        });
    }

    function saveDomain(domain) {
      return directorySync
        .domains(vm.partnerType, vm.partnerToken)
        .link({authSrcCode: vm.authSrcCode, domain: domain.id})
        .$promise.then(onDomainSaved)
        .catch((error) => onDomainError(error, domain));
    }

    function deleteDomain(domain) {
      return directorySync
        .domains(vm.partnerType, vm.partnerToken)
        .unlink({authSrcCode: vm.authSrcCode, domain: domain.id})
        .$promise.then(onDomainSaved)
        .catch((error) => onDomainError(error, domain));
    }

    function onDomainSaved() {
      // trigger the DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED event to clear the domain
      // and directory cache because a new domain has been synced
      $rootScope.$emit(DIRECTORY_SYNC_EVENT.DOMAIN_SYNCED);
    }

    function onDomainError(err, domain) {
      vm.domainsProcessedWithError.push(domain);
      $log.error(`[AD Sync] Error saving domains ${domain.id}`, err);
    }

    // Check if given domain is in list of linked domains to be sent to API on submit.
    function isInSync(domain) {
      return feature.isEnabled('temp_link_one_domain') && vm.saveOnToggle
        ? domain.isLinked
        : _.includes(domainsLinked, domain);
    }

    function isDomainProcessing(domain) {
      return _.some(vm.domainsProcessingQueue, {id: domain.id});
    }

    // Sync toggle label helpers.
    function showDenySyncLabel(domain) {
      return domain.isInvalid || !domain.isVerified;
    }
    function showInSyncLabel(domain) {
      return !domain.isInvalid && domain.isVerified && isInSync(domain);
    }
    function showNotSyncedLabel(domain) {
      return !domain.isInvalid && domain.isVerified && !isInSync(domain);
    }

    function showSelectAllButton() {
      if (feature.isEnabled('temp_link_one_domain')) {
        return !vm.saveOnToggle && vm.canSelectAllDomains;
      }

      return vm.canSelectAllDomains;
    }

    /**
     * Gets the sync/select all button label based on the `saveOnToggle` property
     *
     * @return {string} key to be translated
     */
    function getSelectAllButtonLabel() {
      return hasSelectableDomains()
        ? 'widgets.directories.sync.directorySyncDomainsTable.selectAllBtn'
        : 'widgets.directories.sync.directorySyncDomainsTable.deselectAllBtn';
    }

    //////////

    function setupEventHandlers() {
      $scope.$on(DIRECTORY_SYNC_DOMAINS_RETRY_GET_EVENT, getDomains);
      $scope.$on(DIRECTORY_SYNC_DOMAINS_SAVE_EVENT, saveDomains);
    }

    function setProcessingState() {
      vm.isProcessing = true;
      vm.onProcessing();
    }

    function unsetProcessingState() {
      vm.isProcessing = false;
      vm.onProcessingDone();
    }

    // Returns domains that are verified and valid.
    function getSelectableDomains(domains) {
      return _.filter(domains, (domain) => domain.isVerified && !domain.isInvalid);
    }

    // Reset search input and unfilter data.
    function resetSearch() {
      $element.find('binky-search').find('input').val('');
      onSearch();
    }

    function addDomainToQueue(domain) {
      vm.domainsProcessingQueue.push({
        hasError: false,
        id: domain.id,
      });
    }

    function getDomainProcessedFunction() {
      return _.debounce(onDomainProcessed, 700);
    }

    function updateSelection() {
      const linkedDomains = _.filter(vm.domains, {isLinked: true});
      vm.onSelectionChange({selectedDomains: linkedDomains || []});
    }
  }
})();
/* eslint-enable max-lines */
