import {StatusLight} from '@pandora/react-status-light';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {JOB_RESULT_ITEM_STATUS} from 'common/services/job/JobConstants';

/**
 * A StatusLight with the JobResult status.
 */
const JobResultsStatusLight = ({alignStart = true, status}) => {
  const intl = useIntl();

  let statusText, variant;
  switch (status) {
    case JOB_RESULT_ITEM_STATUS.SUCCESS:
      statusText = intl.formatMessage({id: 'bulkOperationResult.status.success'});
      variant = 'positive';
      break;
    case JOB_RESULT_ITEM_STATUS.FAILURE:
      statusText = intl.formatMessage({id: 'bulkOperationResult.status.failure'});
      variant = 'negative';
      break;
    case JOB_RESULT_ITEM_STATUS.NOT_PROCESSED:
      statusText = intl.formatMessage({id: 'bulkOperationResult.status.notProcessed'});
      variant = 'neutral';
      break;
    default:
      break;
  }

  return (
    statusText && (
      <StatusLight alignStart={alignStart} variant={variant}>
        {statusText}
      </StatusLight>
    )
  );
};

JobResultsStatusLight.propTypes = {
  /**
   * If true, removes the margin to the left of the StatusLight icon so that if in a table column the StatusLight
   * will align with its visible left edge lined up with the left edge of its header. Default is true.
   */
  alignStart: PropTypes.bool,
  /**
   * The JobResult status. One of JOB_RESULT_ITEM_STATUS.
   */
  status: PropTypes.oneOf(Object.values(JOB_RESULT_ITEM_STATUS)),
};

export default JobResultsStatusLight;
