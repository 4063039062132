(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportSummaryButtons
   * @description renders buttons for creating support cases and requesting expert sessions for
   *   the specified case type and product cloud. Also renders a Start Chat button for talking with
   *   an Adobe agent. Hides the buttons if the user does not have the right to create the
   *   specified kind of support tickets.
   *
   * @binding {SUPPORT_TICKET_CASE_TYPE} caseType - the kind of ticket to pre-select in the
   *   Create Case or Request Expert session modal opened by this component
   * @binding {CLOUD} [cloud] - if caseType is Product, the buttons will only be shown if
   *   the user can create tickets for products in this cloud
   */
  const componentName = 'appSupportSummaryButtons';
  angular.module('app.widgets.support').component(componentName, {
    bindings: {
      caseType: '@',
      cloud: '@?',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-summary-buttons/support-summary-buttons.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $state,
    $translate,
    AnalyticsHelper,
    CASE_TYPE_SELECTION_MODAL_ID,
    chat,
    feature,
    panelManager,
    onesieSrc2,
    supportAccess,
    SUPPORT_TICKET_ANALYTIC_EVENT_NAME,
    SUPPORT_TICKET_RECORD_TYPE,
    SESSION_TYPE_SELECTION_MODAL_ID,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      feature,
      isEnabled: feature.isEnabled,
      onClickCreateCase,
      onClickRequestExpertSession,
      onesieSrc2,
      onExpertSessionCreated,
      onSupportCaseCreated,
      shouldShowButtons,
      shouldShowChatButton,
      SUPPORT_TICKET_ANALYTIC_EVENT_NAME,
      SUPPORT_TICKET_RECORD_TYPE,
    });

    // remove along with temp_use_pandora_support_ticket
    function onClickCreateCase() {
      panelManager.open(CASE_TYPE_SELECTION_MODAL_ID, {caseType: vm.caseType, cloud: vm.cloud});
    }

    function onSupportCaseCreated(caseId) {
      toastManager.showSuccessToast(
        $translate.instant('widgets.support.createCaseModal.toast.caseCreated', {
          caseId,
        })
      );
      $state.go('org.support.support-case-details', {id: caseId});
    }

    // remove along with temp_use_pandora_support_ticket
    function onClickRequestExpertSession() {
      AnalyticsHelper.dispatchUiEventAnalytics({
        eventAction: 'open',
        eventName: 'supportSummaryRequestExpertSession',
      });
      panelManager.open(SESSION_TYPE_SELECTION_MODAL_ID, {caseType: vm.caseType});
    }

    function onExpertSessionCreated(expertSessionId) {
      toastManager.showSuccessToast(
        $translate.instant('widgets.support.requestExpertSessionModal.toast.expertSessionCreated', {
          expertSessionId,
        })
      );
      $state.go('org.support.expert-session-details', {id: expertSessionId});
    }

    function shouldShowButtons() {
      const options = {caseType: vm.caseType};
      // cloud is an optional param - don't pass it along if it is an empty string or not defined
      if (vm.cloud) {
        options.cloud = vm.cloud;
      }
      return supportAccess.canManageTickets(options);
    }

    function shouldShowChatButton() {
      return onesieSrc2.core.services.chat.chatProvider.canDisplayChat();
    }
  }
})();
