// eslint-disable-next-line angular/window-service
const binkySrc2 = window.binkySrc2;

const {PRODUCT_USER_LIST_EVENT} = binkySrc2.services.product.userList.PRODUCT_USER_LIST_CONSTANTS;
const {PRODUCT_ADMIN_LIST_EVENT} =
  binkySrc2.services.product.adminList.PRODUCT_ADMIN_LIST_CONSTANTS;
const {LICENSE_GROUP_ADMIN_LIST_EVENT} =
  binkySrc2.services.product.licenseGroup.adminList.LICENSE_GROUP_ADMIN_LIST_CONSTANTS;
const {LICENSE_GROUP_INTEGRATIONS_LIST_EVENT} =
  binkySrc2.services.product.licenseGroup.integrationsList
    .LICENSE_GROUP_INTEGRATIONS_LIST_CONSTANTS;
const {LICENSE_GROUP_USER_LIST_EVENT} =
  binkySrc2.services.product.licenseGroup.userList.LICENSE_GROUP_USER_LIST_CONSTANTS;

// For those looking to use these constants in src2, please port the constant to a constants file neighboring the most relevant model/service.
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.message').constant('MESSAGE', {
    CHANGE: {
      // Ported to src2/app/shell/components/organization-switcher-container/orgChangeConstants.js
      ACTIVEORGANIZATION: {
        ERROR: 'ChangeActiveOrganizationError',
        START: 'ChangeActiveOrganizationStart',
        SUCCESS: 'ChangeActiveOrganizationSuccess',
      },
      NOTIFICATION: 'ChangeNotification',
    },
    CREATE: {
      CONFIGURATION: 'CreateConfiguration',
      CONTENTLOG: 'CreateContentLog',
      EXPERTSESSION: 'CreateExpertSession',
      SHAREDFOLDER: 'CreateSharedFolder',
      STORAGEREPORT: 'CreateStorageReport',
      SUPPORTCASE: 'CreateSupportCase',
      USERFOLDER: 'CreateUserFolder',
      USERGROUP: 'CreateUserGroup',
    },
    DEACTIVATE: {
      DEVICE: 'DeactivateDevice',
    },
    DELETE: {
      CONFIGURATION: 'DeleteConfiguration',
      CONTENTLOG: 'DeleteContentLog',
      JOB: 'DeleteJob',
      SHAREDFOLDER: 'DeleteSharedFolder',
      TRUST: 'DeleteTrust',
      USERFOLDER: 'DeleteUserFolder',
    },
    OPEN: {
      TEAM_EXPERT_SESSION_BANNER: 'OpenTeamExpertSessionBanner',
    },
    REFRESH: {
      CONTENTLOGLIST: 'RefreshContentLogList',
      CONTRACTLIST: 'RefreshContractList',
      JOBLIST: 'RefreshJobList',
      SHAREDFOLDERLIST: 'RefreshSharedFolderList',
      STORAGEREPORTFOLDERLIST: 'RefreshStorageReportFolderList',
      STORAGEREPORTLIST: 'RefreshStorageReportList',
    },
    SEARCH: 'Search',
    UPDATE: {
      ACCESSREQUESTS: {
        COUNT: 'UpdateAccessRequestsCount',
        LIST: 'UpdateAccessRequestsList',
      },
      ASSETMIGRATIONPOLICY: 'UpdateAssetMigrationPolicy',
      CONFIGURATION: 'UpdateConfiguration',
      CONFIGURATIONADMINS: {
        COUNT: LICENSE_GROUP_ADMIN_LIST_EVENT.UPDATE.COUNT,
        LIST: LICENSE_GROUP_ADMIN_LIST_EVENT.UPDATE.LIST,
      },
      CONFIGURATIONINTEGRATIONS: {
        COUNT: LICENSE_GROUP_INTEGRATIONS_LIST_EVENT.UPDATE.COUNT,
        LIST: LICENSE_GROUP_INTEGRATIONS_LIST_EVENT.UPDATE.LIST,
      },
      CONFIGURATIONUSERS: {
        COUNT: LICENSE_GROUP_USER_LIST_EVENT.UPDATE.COUNT,
        LIST: LICENSE_GROUP_USER_LIST_EVENT.UPDATE.LIST,
      },
      CONTENTLOG: 'UpdateContentLog',
      CONTRACTOWNER: 'UpdateContractOwner',
      DEACTIVATEDEVICELIST: 'DeactivatedDeviceList',
      DELEGATIONSUSERS: {
        COUNT: 'UpdateDelegationsUsersCount',
        LIST: 'UpdateDelegationsUsersList',
      },
      DEVICEPACKAGEPOOL: 'DevicePackagePool',
      DIRECTORYUSERS: {
        COUNT: 'UpdateDirectoryUserCount',
        LIST: 'UpdateDirectoryUserList',
      },
      EXPERTSESSION: 'UpdateExpertSession',
      EXPERTSESSIONS: {
        LIST: 'UpdateExpertSessionsList',
      },
      EXPERTSESSIONSUMMARY: 'ExpertSessionSummary',
      JOB: 'UpdateJob',
      LICENSECONFIGURATION: {
        COUNT: 'UpdateLicenseConfigurationCount',
        LIST: 'UpdateLicenseConfigurationList',
      },
      ORGANIZATIONINTEGRATIONS: {
        COUNT: 'UpdateOrganizationIntegrationsCount',
        LIST: 'UpdateOrganizationIntegrationsList',
      },
      ORGANIZATIONUSERS: {
        COUNT: 'UpdateOrganizationUsersCount',
        LIST: 'UpdateOrganizationUsersList',
      },
      PRODUCT: {
        CONFIGURATIONCOUNT: 'UpdateProductConfigurationCount',
      },
      PRODUCTADMINS: {
        COUNT: PRODUCT_ADMIN_LIST_EVENT.UPDATE.COUNT,
        LIST: PRODUCT_ADMIN_LIST_EVENT.UPDATE.LIST,
      },
      PRODUCTAPPINTEGRATIONSGLOBALPOLICY: 'UpdateProductsApplicationIntegrationsGlobalPolicy',
      PRODUCTENTITLEMENTID: 'ProductEntitlementId',
      PRODUCTNOACCESSUSERS: {
        COUNT: 'UpdateProductNoAccessUsersCount',
        LIST: 'UpdateProductNoAccessUsersList',
      },
      PRODUCTPAYMENTSTATUSUSERS: {
        COUNT: 'UpdateProductPaymentStatusUsersCount',
        LIST: 'UpdateProductPaymentStatusUsersList',
      },
      PRODUCTPROVISIONINGSTATUSUSERS: {
        COUNT: 'UpdateProductPaymentStatusUsersCount',
        LIST: 'UpdateProductPaymentStatusUsersList',
      },
      PRODUCTSAPPLICATIONINTEGRATIONS: 'UpdateProductApplicationIntegrations',
      PRODUCTUSERS: {
        COUNT: PRODUCT_USER_LIST_EVENT.UPDATE.COUNT,
        LIST: PRODUCT_USER_LIST_EVENT.UPDATE.LIST,
      },
      SHAREDFOLDER: 'UpdateSharedFolder',
      SHAREDFOLDERLIST: 'UpdateSharedFolderList',
      SUPPORTCASE: 'UpdateSupportCase',
      SUPPORTCASELIST: 'SupportCaseList',
      SUPPORTCASESUMMARY: 'SupportCaseSummary',
      TOOLBAR: {
        ACTIONS: 'UpdateToolbarActions',
        MODALS: 'UpdateToolbarModals',
      },
      TRUSTEE: 'UpdateTrustee',
      TRUSTEES: {
        COUNT: 'UpdateTrusteesCount',
        LIST: 'UpdateTrusteesList',
      },
      USERFOLDERLIST: 'UpdateUserFolderList',
      USERGROUPADMINS: {
        COUNT: 'UpdateUserGroupAdminsCount',
        LIST: 'UpdateUserGroupAdminsList',
      },
      USERGROUPLICENSECONFIGURATIONS: {
        COUNT: 'UpdateUserGroupLicenseConfigurationsCount',
        LIST: 'UpdateUserGroupLicenseConfigurationsList',
      },
      USERGROUPUSERS: {
        COUNT: 'UpdateUserGroupUsersCount',
        LIST: 'UpdateUserGroupUsersList',
      },
    },
  });
})();
