(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appProductCatalogItem
   * @description Component to display a formatted offer as a section in a catalog.
   * @param {Offer} offer The offer to display.
   * @param {Object} [ownedProducts] Products currently owned by the organization.
   * @param {Boolean} showPrice Boolean value of whether to show the price or not.
   * @param {Function} onAddItem The callback to invoke when the add button is pressed.
   * @example
   *      <app-product-catalog-item
   *        showPrice="$ctrl.showPrice"
   *        offer="$ctrl.offer"
   *        owned-products="$ctrl.ownedProducts"
   *        on-add-item="$ctrl.onAddItem(offerId)">
   *      </app-product-catalog-item>
   *
   */
  angular.module('app.widgets.products.purchase').component('appProductCatalogItem', {
    bindings: {
      offer: '<',
      onAddItem: '&',
      ownedProducts: '<?',
      showPrice: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/catalog/product-catalog-item/product-catalog-item.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $translate,
    binkyOfferPerTermPriceFilter,
    feature,
    onesieSrc2,
    PRODUCT_CATALOG_ITEM_TAGS,
    ProductPurchaseHelper
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getPricing,
      onesieSrc2,
    });

    function $onInit() {
      if (vm.offer.assignableLicenseCount) {
        vm.scorecardItem = ProductPurchaseHelper.constructLicenseScorecardItem(vm.offer);
      }

      if (vm.ownedProducts) {
        vm.ownedProduct = _.find(vm.ownedProducts.items, ['offerId', vm.offer.offer_id]);
        vm.showPriceChangeTooltip = ProductPurchaseHelper.isOfferPriceDifferentFromProduct(
          vm.ownedProduct,
          vm.offer,
          getPricing()
        );
      }

      vm.translatedTags = translateOfferTags();
    }

    /**
     * @description Converts a list of constant format strings from offers API metadata object to translated string.
     * Currently it omits failed translations. Defaults to an empty list;
     * @returns {Array} - successfully translated strings
     */
    function translateOfferTags() {
      const tags = _.get(vm.offer.metadata, 'tags');

      return tags ? _(tags).map(translateOfferTag).omitBy(_.isUndefined).value() : [];
    }

    /**
     * @description Convert constant format string returned from offers API metadata object to translated string.
     * Currently only returns a translated string for the 'best value' tag. Defaults to undefined for all other translations.
     * @param {String} label - constant label from offer API e.g. BEST_VALUE
     * @returns {String} - translated string
     */
    function translateOfferTag(label) {
      return label === PRODUCT_CATALOG_ITEM_TAGS.BEST_VALUE
        ? $translate.instant('widgets.offers.tags.bestValue')
        : undefined;
    }

    function getPricing() {
      return binkyOfferPerTermPriceFilter(vm.offer);
    }
  }
})();
