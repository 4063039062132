import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import AllPackagesStore from '../../stores/AllPackagesStore';

/**
 * Private Context for the TemplatesPage and its sub-components.
 * Used to pass the allPackagesStore between sub-components.
 */

const AllPackagesPageContext = createContext(null);
const useAllPackagesPageContext = () => useContext(AllPackagesPageContext);

const AllPackagesPageContextProvider = ({children, allPackagesStore}) => {
  const value = useMemo(() => ({allPackagesStore}), [allPackagesStore]);

  return (
    <AllPackagesPageContext.Provider value={value}>{children}</AllPackagesPageContext.Provider>
  );
};

AllPackagesPageContextProvider.propTypes = {
  /**
   * The domain store for Admin Packages.
   */
  allPackagesStore: PropTypes.instanceOf(AllPackagesStore).isRequired,
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
};

export {AllPackagesPageContextProvider, useAllPackagesPageContext};
