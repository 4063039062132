/**
 * @deprecated ported to src2/app/common/components/products-detail-section/ProductsDetailSection.jsx
 *
 * @ngdoc component
 * @name app.widgets.products:appProductsDetailSection
 *
 * @description The Products section used by both the User Detail page and the User drawer.
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section. Supports
 *   only levels 2, 3 or 4. If an invalid level is supplied, it will be reset to the default 3.
 * @binding {Boolean|undefined} showAssignedBy. If true, show the Assigned By text.
 *   Default is false.
 * @binding {Boolean|undefined} showAssignedByUserGroup. If true, show the Assigned By User Group text.
 *   Default is false.
 * @binding {Object} products. A list of products to display.
 * @binding {promise|undefined} wait promise. If specified, a dot-dot-dot wait will be shown until
 *   the promise resolves.
 */
(function () {
  angular.module('app.widgets.products').component('appProductsDetailSection', {
    bindings: {
      developerTabContext: '<?',
      editProductButtonText: '@?',
      headingLevel: '<?',
      onEditProducts: '=',
      pageContext: '<?',
      products: '<',
      showAssignedBy: '<?',
      showAssignedByUserGroup: '<?',
      title: '@?',
      user: '<?',
      waitPromise: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/products/detail-sections/products-detail-section/products-detail-section.component.html',
  });

  /* @ngInject */
  function controller(
    $element,
    $q,
    $translate,
    _,
    FiProductGroupProductList,
    OrganizationManager,
    organizationUserUtils,
    PAGE_TARGET_TYPE,
    productAccess,
    trialHelper
  ) {
    const vm = this;

    _.assign(vm, {
      $doCheck,
      $onInit,
      isAutoAssignedProduct,
      isTrialProduct,
      showButtonBar,
      showProduct,
    });

    function $onInit() {
      _.assign(vm, {
        allProducts: OrganizationManager.getProductsForActiveOrg(),
        currentExplodedLicenseGroups: [],
        developerTabContext: _.get(vm, 'developerTabContext', false),
        licenseGroupLabelsByProductId: {},
        showAssignedBy: _.get(vm, 'showAssignedBy', false),
        showAssignedByUserGroup: _.get(vm, 'showAssignedByUserGroup', false),
      });

      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      vm.headingLevel = vm.headingLevel || 3;
      if (vm.headingLevel < 2 || vm.headingLevel > 4) {
        vm.headingLevel = 3;
      }
      vm.productHeadingLevel = vm.headingLevel + 1;
      if (_.isUndefined(vm.waitPromise)) {
        vm.waitPromise = $q.resolve();
      }
      if (!vm.title) {
        if (vm.developerTabContext) {
          vm.title = $translate.instant(
            'widgets.products.detailSections.productsDetailSection.developerProducts'
          );
        } else {
          vm.title = $translate.instant(
            'widgets.products.detailSections.productsDetailSection.title'
          );
        }
      }
      if (!vm.editProductButtonText) {
        vm.editProductButtonText = $translate.instant(
          'widgets.products.detailSections.productsDetailSection.editProductsAssigned'
        );
      }
      setSortedProducts(vm.products);
      setlicenseGroupLabelsByProductId(vm.products);
    }

    function $doCheck() {
      const explodedLicenseGroups = organizationUserUtils.explodeProductLicenseGroups(vm.products);
      if (hasProductsChanged(explodedLicenseGroups)) {
        setSortedProducts(vm.products);
        setlicenseGroupLabelsByProductId(vm.products);
        vm.currentExplodedLicenseGroups = explodedLicenseGroups;
      }

      setAccordionHeadingLevels();
    }

    function canEditProducts() {
      return (
        vm.user &&
        (_.get(vm, 'pageContext.targetType') === PAGE_TARGET_TYPE.INTEGRATION ||
          !vm.user.getType().isTechnicalAccount()) &&
        vm.allProducts.hasUserDelegatableProduct()
      );
    }

    function isAutoAssignedProduct(product) {
      return (
        product instanceof FiProductGroupProductList ||
        // this will return false if product not instanceof Product
        _.result(product, 'fulfillableItemList.hasOrgDelegatable', false)
      );
    }

    function isTrialProduct(product) {
      return trialHelper.isTrialProduct(product);
    }

    function showButtonBar() {
      return productAccess.canEditProducts() && canEditProducts();
    }

    function showProduct(product) {
      return isAutoAssignedProduct(product) || product.licenseGroups.length > 0;
    }

    /////////////////

    function hasProductsChanged(explodedLicenseGroups) {
      return (
        !_(explodedLicenseGroups)
          .differenceWith(vm.currentExplodedLicenseGroups, areLicenseGroupsEqual)
          .isEmpty() ||
        !_(vm.currentExplodedLicenseGroups)
          .differenceWith(explodedLicenseGroups, areLicenseGroupsEqual)
          .isEmpty()
      );

      function areLicenseGroupsEqual(licenseGroup1, licenseGroup2) {
        return licenseGroup1.id === licenseGroup2.id;
      }
    }

    function setAccordionHeadingLevels() {
      // As we are using an old version of Coral accordion which doesn't allow us
      // to override the heading level, we need to 'correct' the heading hierarchy
      // after the items have all loaded. The attribute check will be done whenever
      // doCheck() runs, but the :not([aria-level]) applied in the selector means that
      // the update will only happen if aria-level has not already been set.
      const labels = $element[0].querySelectorAll('coral-accordion-item h3:not([aria-level])');
      if (labels.length > 0) {
        // Each accordion is below a product heading, so ensure we set the heading level accordingly.
        const accordionHeadingLevel = vm.productHeadingLevel + 1;
        _.forEach(labels, (label) => {
          label.setAttribute('aria-level', accordionHeadingLevel);
        });
      }
    }

    // Adds in any organization consumables and then sorts the 'products' by display name.
    function setSortedProducts(products) {
      vm.sortedProducts =
        OrganizationManager.getOrgConsumablesForActiveOrg().getProductDisplayList(products);
    }

    function setlicenseGroupLabelsByProductId(products) {
      _.forEach(products, (product) => {
        vm.licenseGroupLabelsByProductId[product.id] = [];
        _.forEach(product.licenseGroups, (licenseGroup) => {
          vm.licenseGroupLabelsByProductId[product.id].push({getItemLabel});

          function getItemLabel() {
            return vm.showAssignedByUserGroup && licenseGroup.userGroup
              ? getLicenseGroupNameLabel(licenseGroup) +
                  getLicenseGroupUserGroupAssignedByLabel(licenseGroup)
              : getLicenseGroupNameLabel(licenseGroup);
          }
        });
      });
    }

    function getLicenseGroupNameLabel(licenseGroup) {
      return $translate.instant(
        'widgets.products.detailSections.productsDetailSection.licenseGroupsAccordion.licenseGroupName',
        {
          licenseGroupName: licenseGroup.name,
        }
      );
    }

    function getLicenseGroupUserGroupAssignedByLabel(licenseGroup) {
      return $translate.instant(
        'widgets.products.detailSections.productsDetailSection.licenseGroupsAccordion.assignedByUserGroup',
        {
          licenseGroupName: licenseGroup.name,
          userGroupId: licenseGroup.userGroup.id,
          userGroupName: licenseGroup.userGroup.name,
        }
      );
    }
  }
})();
