(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').config(config);

  /* @ngInject */
  function config($stateProvider, _) {
    const modalId = 'overview-add-user-modal';

    $stateProvider.state('overview.overview.add-user', {
      data: {
        title: 'overview.quickLinksDetailSection2.addUsers',
      },
      params: {
        targetId: null,
        targetType: null,
      },
      resolve: {
        contextName($stateParams, binkyProductNameLabelFilter, OrganizationManager) {
          const product = getProductModel($stateParams, OrganizationManager);
          return binkyProductNameLabelFilter(product);
        },
        modalId: _.constant(modalId),
        onClosed($state) {
          return () => $state.go('overview.overview2');
        },
        onInitialized($stateParams, panelManager) {
          return () => panelManager.open(modalId, {targetTab: $stateParams.targetType});
        },
        pageContext($stateParams, PAGE_TARGET, PageContext) {
          return new PageContext({
            target: PAGE_TARGET.PRODUCT,
            targetId: $stateParams.targetId,
            targetType: $stateParams.targetType,
          });
        },
      },
      views: {
        'modal@overview.overview2': {
          component: 'appAddUserModal',
        },
      },
    });

    ////////////////

    function getProductModel($stateParams, OrganizationManager) {
      return _.find(OrganizationManager.getProductsForActiveOrg().items, [
        'id',
        $stateParams.targetId,
      ]);
    }
  }
})();
