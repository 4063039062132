(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.core.directories:DirectoryTrustList
   * @description fetch the list of trusts for a specific directory.
   */
  angular.module('app.core.directories').factory('DirectoryTrustList', directoryTrustList);

  /* @ngInject */
  function directoryTrustList(
    $log,
    $q,
    $rootScope,
    _,
    DIRECTORY_TRUSTEE_EVENT,
    DIRECTORY_TRUSTEE_LIST_EVENT,
    DirectoryTrust,
    JIL_CONSTANTS,
    jilDirectories,
    jilTrusts,
    List,
    MODEL,
    modelCache,
    TRUST_STATUS,
    TRUSTEE_EVENT
  ) {
    class DirectoryTrustList extends List {
      /**
       * @description instantiate the list of Trusts for a directory.
       * @param {Object} options the options to pass when initialize a directory trust list.
       * @param {String} options.directoryId the id of the directory.
       */
      constructor(options = {}) {
        super({
          cacheType: MODEL.DIRECTORYTRUSTLIST,
          itemClassRef: DirectoryTrust,
          resource: jilDirectories.directoryTrusts,
          sortExpression: JIL_CONSTANTS.SORT.TRUSTEE_ORG_NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
        this.$resolved = false;
        this.directoryId = options.directoryId;
        this.items = [];
      }

      /**
       * @description Method to accept, reject, revoke or a delete a list of Trust requests
       * @param {Array} operations - list of operations to perform in batch
       * @param {Object} queryParams optional params to use for the list refresh
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the DirectoryTrustList has been refreshed with the specified queryParams.
       */
      batchOperation(operations, queryParams) {
        this.$promise = $q((resolve, reject) => {
          if (_.isArray(operations) && operations.length > 0) {
            this.$resolved = false;
            jilTrusts.trusts.batchOperation(
              {},
              operations,
              onSuccess.bind(this),
              onError.bind(this)
            );
          } else {
            resolve([]);
          }

          ////////

          // Multi-status response is an Array of
          // {
          //   "responseCode": httpStatusCode, // 2xx if successful, else a failure code
          //   "response": responseObject      // an OrganizationTrust object if successful, else a description of the failure
          // }
          function onSuccess(response) {
            const [completedItems, failedItems] = _.partition(response, (item) =>
              _.inRange(item.responseCode, 200, 300)
            );

            // Since there can be multiple pages cached for this particular directory (different pageSizes, etc) we have
            // to clear the entire cache before refreshing.
            modelCache.removeAll(MODEL.DIRECTORYTRUSTLIST);

            // The refresh call eventually sets $resolved to true as required.
            this.refresh(queryParams)
              .then(() => resolve({completedItems, failedItems}))
              .catch(onError.bind(this));
          }

          function onError(error) {
            $log.error('Failed to PATCH directory trust list. Error: ', error);
            this.$resolved = true;
            reject(error);
          }
        });

        return this.$promise;
      }

      /**
       * @description reload the directory trust list
       * @param {Object} params the params to pass to refresh.
       * @param {String} params.status the status of the trusts.
       * @returns {Promise} resolved when DirectoryTrust list fetch completes.
       */
      refresh(params = {}) {
        // We need to add the directoryId into the payload so that the cache key
        // generated from the payload stays the same regardless of us calling
        // DirectoryTrustList.get or directoryTrustList.refresh.
        const payload = _.assign({directoryId: this.directoryId}, params);
        return super.refresh(payload).then(() => {
          if (params.status === TRUST_STATUS.ACTIVE) {
            $rootScope.$emit(DIRECTORY_TRUSTEE_LIST_EVENT.UPDATE);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(
                DIRECTORY_TRUSTEE_LIST_EVENT.UPDATE_COUNT,
                this.pagination.itemCount
              );
            }
          }
        });
      }

      /**
       * @description Method to revoke the access for a list of active Trusts.
       * @param {Array} items the list of trusts the should have their access revoked
       * @param {String} [revokeReason] optional; reason the requests are revoked
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the DirectoryTrustList has been refreshed with the directoryTrusts with
       *   TRUST_STATUS.ACTIVE status.
       */
      revokeAccess(items, revokeReason) {
        const operations = _.map(items, (item) => ({
          op: 'replace',
          path: revokeReason
            ? `/${item.trusteeOrgId}/${item.directoryId}/REVOKE/reason/${revokeReason}`
            : `/${item.trusteeOrgId}/${item.directoryId}/REVOKE`,
        }));

        return this.batchOperation(operations, {status: TRUST_STATUS.ACTIVE}).then((responses) =>
          emitUpdateDirectoryTrusteeEvents(responses)
        );
      }

      /**
       * @description Method to save the notification value for an active DirectoryTrust.
       * @param {Object} item The directoryTrust item to update.
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg.
       *   If success, the DirectoryTrustList has been refreshed with the directoryTrusts with
       *   TRUST_STATUS.ACTIVE status.
       */
      saveNotificationsValue(item) {
        const operations = [
          {
            op: 'replace',
            path: `/${item.trusteeOrgId}/${item.directoryId}/NOTIFICATIONS/${item.notifyAdmins}`,
          },
        ];

        return this.batchOperation(operations, {status: TRUST_STATUS.ACTIVE}).then((responses) =>
          emitUpdateDirectoryTrusteeEvents(responses)
        );
      }

      /**
       * @description Method to modify sort parameters of the trust list paginator
       * @param {String} property - property on which to sort
       * @param {Boolean} desc - true if sort should be descending
       */
      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description instantiates a new instance of DirectoryTrustList.
       * @param {Object} params the query parameters to pass when refreshing a directory trust list
       * @returns {DirectoryTrustList} DirectoryTrustList model object.
       */
      static get(params = {}) {
        let model = new DirectoryTrustList(params);
        const cachedModel = modelCache.get(MODEL.DIRECTORYTRUSTLIST, model.key(params));
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh(params);
        }
        return model;
      }
    }

    // Cache right now is size 1 so removeAll.
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(TRUSTEE_EVENT.UPDATE, () => {
      modelCache.removeAll(MODEL.DIRECTORYTRUSTLIST);
    });

    // We register the cache size for this class
    modelCache.register(MODEL.DIRECTORYTRUSTLIST, 10);

    return DirectoryTrustList;

    //////////////

    // There has been an update which impacts a directory active trust / trustee.
    function emitUpdateDirectoryTrusteeEvents(responses) {
      $rootScope.$emit(DIRECTORY_TRUSTEE_EVENT.UPDATE);
      return responses;
    }
  }
})();
