import {CONTRACT_BUYING_PROGRAM, CONTRACT_PAYMENT_CATEGORY} from '@admin-tribe/binky';

const INVOICE_STATUS = {
  PAST_DUE: 'PAST_DUE',
  PAYMENT_DUE: 'PAYMENT_DUE',
};
const INVOICE_UNPAID_STATUSES = [INVOICE_STATUS.PAST_DUE, INVOICE_STATUS.PAYMENT_DUE];

const EXPIRING_DAYS_LEFT = 30;

/**
 * checks for incomplete payment information
 * @param {*} paymentInstrumentInfo
 * @returns boolean
 */
function isIncompletePaymentInfo(paymentInstrumentInfo) {
  const {creditCardShortNumber, address, expiryDate} = paymentInstrumentInfo || {};

  return (
    !creditCardShortNumber ||
    !address?.contact?.firstName ||
    !address?.contact?.lastName ||
    !expiryDate
  );
}
/**
 * Checks for contract buyingprogram VIP or VIPMP
 * @param {*} contract
 * @returns boolean
 */
function isVIPOrVIPMPContract(contract) {
  return (
    contract.isVIPMPContract() ||
    (contract.isIndirectContract() && contract.buyingProgram === CONTRACT_BUYING_PROGRAM.VIP)
  );
}

/**
 * Checks for payment category VIP or VIPMP
 * @param {*} paymentInstrumentInfo
 * @returns boolean
 */
function isCardPayment(paymentInstrumentInfo) {
  const {paymentCategory} = paymentInstrumentInfo || {};
  return paymentCategory === CONTRACT_PAYMENT_CATEGORY.CREDIT_CARD;
}

export {
  isIncompletePaymentInfo,
  isVIPOrVIPMPContract,
  isCardPayment,
  EXPIRING_DAYS_LEFT,
  INVOICE_STATUS,
  INVOICE_UNPAID_STATUSES,
};
