(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilStorageFolders
   * @description defines service to manage jil shared-folders resources
   */
  angular.module('app.core.api.jil').provider('jilStorageFolders', jilStorageFoldersProvider);

  let url;

  /* @ngInject */
  function jilStorageFoldersProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        assetsByFolderId: getAssetsByFolderIdResource(),
        assetsByType: getAssetsByTypeResource(),
        storageFolders: getStorageFoldersResource(),
        storageFoldersByPath: getStorageFoldersByPathResource(),
        storageReportFolders: getStorageReportFoldersResource(),
        userFolderByPath: getUserFolderByPathResource(),
        userFolders: getUserFoldersResource(),
        userFoldersByUsers: getUserFoldersByUsersResource(),
      };

      return service;

      ///////////

      function getAssetsByFolderIdResource() {
        return $resource(
          `${url}/:orgId/storage-folders\\:children.id?id=:id`,
          {
            id: '@id',
            includes: '@includes',
            orgId: apiUtils.getActiveOrg,
          },
          {
            query: {
              headers: {
                Accept: apiUtils.getAcceptHeader,
              },
              isArray: true,
            },
          }
        );
      }

      function getAssetsByTypeResource() {
        return $resource(
          `${url}/:orgId/storage-folders\\:children.type?type=:type`,
          {
            includes: '@includes',
            orgId: apiUtils.getActiveOrg,
            recursive: '@recursive',
            type: '@type',
          },
          {
            query: {
              headers: {
                Accept: apiUtils.getAcceptHeader,
              },
              isArray: true,
            },
          }
        );
      }

      function getStorageReportFoldersResource() {
        return $resource(
          `${url}/:orgId/storage-folders`,
          {
            includes: '@includes',
            orgId: apiUtils.getActiveOrg,
            type: 'REPORTS',
          },
          {
            batchOperation: {
              isArray: true,
              method: 'PATCH',
            },
          }
        );
      }

      function getStorageFoldersByPathResource() {
        return $resource(
          `${url}/:orgId/storage-folders\\:children.path?path=:path`,
          {
            orgId: apiUtils.getActiveOrg,
            path: '@path',
          },
          {
            query: {
              headers: {
                Accept: apiUtils.getAcceptHeader,
              },
              isArray: true,
            },
          }
        );
      }

      function getUserFolderByPathResource() {
        return $resource(`${url}/:orgId/storage-folders\\:search.path?path=:path`, {
          orgId: apiUtils.getActiveOrg,
          path: '@path',
        });
      }

      function getUserFoldersResource() {
        return $resource(
          `${url}/:orgId/storage-folders`,
          {
            includes: '@includes',
            orgId: apiUtils.getActiveOrg,
            type: 'USERS',
          },
          {
            batchOperation: {
              isArray: true,
              method: 'PATCH',
            },
            create: {
              method: 'POST',
            },
            query: {
              headers: {
                Accept: apiUtils.getAcceptHeader,
              },
              isArray: true,
            },
          }
        );
      }

      // Type can be ASSETS, USERS and REPORTS
      function getStorageFoldersResource() {
        return $resource(
          `${url}/:orgId/storage-folders`,
          {
            includes: '@includes',
            omitReadOnly: '@omitReadOnly',
            orgId: apiUtils.getActiveOrg,
            type: '@type',
          },
          {
            batchOperation: {
              isArray: true,
              method: 'PATCH',
            },
            create: {
              method: 'POST',
            },
            query: {
              headers: {
                Accept: apiUtils.getAcceptHeader,
              },
              isArray: true,
            },
          }
        );
      }

      function getUserFoldersByUsersResource() {
        return $resource(
          `${url}/:orgId/storage-folders\\:search.users`,
          {
            includes: '@includes',
            orgId: apiUtils.getActiveOrg,
          },
          {
            fetch: {
              isArray: true,
              method: 'POST',
            },
          }
        );
      }
    }
  }
})();
