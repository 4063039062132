const ContentLogConstants = {
  CONTENTLOGLIST_CACHE_ID: 'ContentLogList',
  SORT: {
    LOG_REPORT_CREATED: 'LOG_REPORT_CREATED',
    LOG_REPORT_CREATOR: 'LOG_REPORT_CREATOR',
    LOG_REPORT_START_DATE: 'LOG_REPORT_START_DATE',
  },
};

export default ContentLogConstants;
