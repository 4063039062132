import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import OrdinalIcon from 'shell/help/common/components/ordinal-icon/OrdinalIcon';

import styles from './ConstructContent.pcss';

const ConstructItemCardContent = ({
  actionButton,
  intl,
  item,
  itemIndex,
  linkContent,
  ordered,
  topicIndex,
}) => {
  let topicContent, topicTitle;
  // only help items display titles (and have types)
  if (item.title && item.type) {
    if (item.translateValues) {
      topicTitle = intl.formatMessage({id: item.title}, item.translateValues);
    } else {
      topicTitle = intl.formatMessage({id: item.title});
    }
  }
  if (item.text) {
    if (item.translateValues) {
      topicContent = intl.formatMessage({id: item.text}, item.translateValues);
    } else {
      topicContent = intl.formatMessage({id: item.text});
    }
  }

  return ordered ? (
    <Flex
      key={`item-${topicIndex}${itemIndex}`}
      data-testid="card-content"
      direction="row"
      gap="size-200"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to apply custom style
      UNSAFE_className={styles['accordion-content']}
    >
      <View width="size-225">
        <OrdinalIcon number={itemIndex + 1 /* zero-based */} />
      </View>
      <View width="size-3600">
        <Heading data-testid="card-title" level={4} marginTop="size-0">
          {topicTitle}
        </Heading>
        {topicContent}
        <View
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to apply custom style
          UNSAFE_className={styles['accordion-content-message']}
        >
          <Text data-testid="message">{topicContent}</Text>
          {linkContent}
        </View>
        {actionButton}
      </View>
    </Flex>
  ) : (
    <View
      key={`item-${topicIndex}${itemIndex}`}
      data-testid="card-content"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to apply custom style
      UNSAFE_className={styles['accordion-content']}
    >
      {topicTitle && (
        <Heading data-testid="card-title" level={4} marginTop="size-0">
          {topicTitle}
        </Heading>
      )}
      <View
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to apply custom style
        UNSAFE_className={styles['accordion-content-message']}
      >
        <Text data-testid="message">{topicContent}</Text>
        {linkContent}
      </View>
      {actionButton}
    </View>
  );
};

ConstructItemCardContent.propTypes = {
  /**
   * Any action button that should be displayed
   */
  actionButton: PropTypes.element,
  /**
   * Reference to intl for translations
   */
  intl: PropTypes.shape({formatMessage: PropTypes.func.isRequired}),
  /**
   * The help item content to build accordion content
   */
  item: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    translateValues: PropTypes.objectOf(PropTypes.string),
    type: PropTypes.string,
  }).isRequired,
  /**
   * The topic index
   */
  itemIndex: PropTypes.number.isRequired,
  /**
   * Any link text that should be displayed
   */
  linkContent: PropTypes.element,
  /**
   * Whether or not to display ordered (ordinal) content
   */
  ordered: PropTypes.bool.isRequired,
  /**
   * The topic index
   */
  topicIndex: PropTypes.number.isRequired,
};

export default ConstructItemCardContent;
