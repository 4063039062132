import {navBus} from '@admin-tribe/binky';
import {OverlayWait, showError, useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {DirectoryContextProvider} from 'features/settings/components/directory/DirectoryContext';
import DirectoryOverviewCard from 'features/settings/components/directory-overview-card/DirectoryOverviewCard';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';
import {PATH_DIRECTORY_DETAILS} from 'features/settings/routing/settingsPaths';
import DirectoryStore, {
  AUTHENTICATION_SETUP_STATUSES,
} from 'features/settings/stores/DirectoryStore';

const DirectoryOverview = observer(({directoryId, domainEnforcement}) => {
  const directoryStore = useStore(() => new DirectoryStore(directoryId));
  const directoryDomainsStore = directoryStore.domains;
  const directorySyncStore = directoryStore.sync;
  const intl = useIntl();
  const {getAllDirectoryRosterSyncs} = useEduRosterSync();
  const [eduRosterSyncs, setEduRosterSyncs] = useState({
    data: [],
    isLoading: false,
  });

  const {directory} = useDirectoryDetailsContext();

  const computeDirectorySyncStatus = (syncStatus, eduSyncs) => {
    const eduSyncStatus = eduSyncs?.data[0]?.status;
    if (syncStatus === AUTHENTICATION_SETUP_STATUSES.UNCONFIGURED && eduSyncStatus) {
      return eduSyncStatus;
    }

    return syncStatus;
  };

  const computedStatus = computeDirectorySyncStatus(directorySyncStore.setupStatus, eduRosterSyncs);
  const getAuthenticationStatus = () => {
    if (directory?.status === AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRED) {
      return AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRED;
    }

    if (directory?.status === AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRING) {
      return AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRING;
    }

    return directoryStore.setupStatus;
  };

  const computedAuthenticationStatus = getAuthenticationStatus();

  // Load data needed for the component
  useEffect(() => {
    let isMounted = true;

    directoryStore.load();
    directoryStore.sync.load();

    setEduRosterSyncs((prevState) => ({...prevState, isLoading: true}));

    const loadEduSyncs = async () => {
      try {
        const {data} = await getAllDirectoryRosterSyncs({
          directoryId: directoryStore.directoryId,
        });

        if (isMounted) {
          setEduRosterSyncs(() => ({data, isLoading: false}));
        }
      } catch (error) {
        showError(
          intl.formatMessage({
            id: 'settings.sync.eduSync.loadingError',
          })
        );
      }
    };

    loadEduSyncs();

    return () => {
      isMounted = false;
    };
  }, [directoryStore, getAllDirectoryRosterSyncs, intl, setEduRosterSyncs]);

  const navigateToTab = useCallback(
    (tab) =>
      navBus.navigate(
        generatePath(`${PATH_DIRECTORY_DETAILS}/${tab}`, {
          directoryId,
          orgId: rootStore.organizationStore.activeOrgId,
        })
      ),
    [directoryId]
  );

  // Get domains from angular and add them to the store
  useEffect(() => {
    directoryStore.domains.load();
  }, [directoryStore.domains]);

  return (
    <DirectoryContextProvider directoryStore={directoryStore}>
      <OverlayWait
        isLoading={
          directoryStore.isLoading || directoryStore.sync.isLoading || eduRosterSyncs.isLoading
        }
      >
        <DirectoryOverviewCard
          authenticationStatus={computedAuthenticationStatus}
          autoAccountCreationStatus={directoryStore.autoAccountCreationStatus}
          domainEnforcement={domainEnforcement}
          domainsStatus={directoryDomainsStore.setupStatus}
          goToTab={navigateToTab}
          syncStatus={computedStatus}
        />
      </OverlayWait>
    </DirectoryContextProvider>
  );
});

DirectoryOverview.propTypes = {
  directoryId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- data coming from angular
  domains: PropTypes.array,
  domainsLoading: PropTypes.bool,
  totalNumberOfDomains: PropTypes.number,
};

export default DirectoryOverview;
