import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';

import CardListContentModel from './CardListContentModel';

/**
 * Create a localized copy of RichTextJSON that will be used by the "Other products"
 * help topic card list content model.  Gets the strings themselves from xx.json.
 */
const LocalizeMoreProductsCloudRichTextJson = (intl, locale) => {
  const shortLocale = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale];
  return {
    helpTopicCards: [
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_more_captivate_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.1.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.1.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_more_presenter_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.1.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.2.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.2.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/aac_more_framemaker_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.2.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.3.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.3.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/aac_more_robohelp_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.3.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.4.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.4.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/aac_more_tcs_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'moreProductsHelpTopicListContentModel.helpTopicCards.4.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
    ],
  };
};

/**
 * Definition of the "More products" Cloud help topics content model
 */
const MoreProducts = (intl, locale) =>
  CardListContentModel(LocalizeMoreProductsCloudRichTextJson(intl, locale));

export default MoreProducts;
