/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCreateCaseModal
 *
 * @description The modal to create a new support case. It is opened by the Support Case Type Selection modal.
 *
 *  When opening, the caseType should be specified as either SUPPORT_TICKET_CASE_TYPE ADMIN or PRODUCT.
 */
(function () {
  const componentName = 'appCreateCaseModal';
  angular.module('app.widgets.support').component(componentName, {
    controller,
    templateUrl: 'app/widgets/support/create-case-modal/create-case-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    $scope,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    CANCEL_CASE_CREATION_MODAL_ID,
    CASE_TYPE_SELECTION_MODAL_ID,
    COMMON_EVENTS,
    CREATE_CASE_MODAL_ID,
    CREATE_CASE_WIZARD_ID,
    DisplayMessage,
    feature,
    onesieSrc2,
    SupportCase,
    SUPPORT_TICKET_CASE_TYPE,
    panelManager,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    const vm = this;
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;

    // steps is an array of each step currently present in the wizard, in chronological order
    let contactMethod,
      contactMethodFormIsInvalid,
      currentStep,
      issueDescription,
      issueDescriptionFormIsInvalid,
      steps;

    _.assign(vm, {
      $onInit,
      cancelHandler,
      CREATE_CASE_MODAL_ID,
      CREATE_CASE_WIZARD_ID,
      onContactMethodFormChange,
      onDiscardCase,
      onIssueDescriptionFormChange,
      onOpen,
      onResumeCase,
      STEP: {}, // all possible steps which may appear in the wizard, e.g. vm.STEP.ISSUE_DESCRIPTION
      stepTitles: [],
    });

    function $onInit() {
      initSteps();
    }

    // Confirm the admin really wants to cancel.
    function cancelHandler() {
      // wrap in timeout to ensure view is updated immediately
      // see: https://git.corp.adobe.com/admin-tribe/onesie/issues/8989
      $timeout(() => {
        panelManager.open(CANCEL_CASE_CREATION_MODAL_ID);
      });
    }

    // Callback from Case Contact Method form when validity changes or for valid form when content changes.
    function onContactMethodFormChange(event) {
      contactMethodFormIsInvalid = event.isInvalid;
      _.assign(contactMethod, event.isInvalid ? {} : event.contactMethod);
    }

    // Abandon the case creation process.
    function onDiscardCase() {
      panelManager.close(CREATE_CASE_MODAL_ID);
      triggerAnalytics('discardButton');
    }

    // Callback from Case Issue Description form when validity changes or for valid form when content changes.
    function onIssueDescriptionFormChange(event) {
      issueDescriptionFormIsInvalid = event.isInvalid;
      _.assign(issueDescription, event.isInvalid ? {} : event.issueDescription);
    }

    /**
     * @param {Object} options - options for the modal
     * @param {SUPPORT_TICKET_CASE_TYPE} options.caseType - Required. Either ADMIN or PRODUCT.
     */
    function onOpen(options) {
      resetModal();

      // caseType is required but default to admin.
      vm.caseType =
        options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
          ? SUPPORT_TICKET_CASE_TYPE.PRODUCT
          : SUPPORT_TICKET_CASE_TYPE.ADMIN;

      vm.typeCode =
        options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
          ? SUPPORT_CONSTANTS.SUPPORT_CASE_TYPE.PRODUCT
          : SUPPORT_CONSTANTS.SUPPORT_CASE_TYPE.ADMIN;

      vm.headerKey = vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT ? '.product' : '.admin';
    }

    // Resume the case creation process.
    function onResumeCase() {
      triggerAnalytics('resumeButton');
    }

    /////////////////

    // Reopen the case type selection modal, with the card for the caseType selected.
    function backToSelectionHandler() {
      panelManager.close(CREATE_CASE_MODAL_ID);
      panelManager.open(CASE_TYPE_SELECTION_MODAL_ID, {caseType: vm.caseType});
      triggerAnalytics('backToSelectionButton');
    }

    function getCurrentStepButtonLabels() {
      return _(currentStep)
        .pick('backLabelKey', 'ctaLabelKey')
        .mapValues((val, labelName) => {
          // Button labels can be empty to tell the view that the button should be hidden
          const btnLabel = _.result(currentStep, labelName);
          return btnLabel
            ? $translate.instant(`widgets.support.createCaseModal.buttons.${btnLabel}`)
            : '';
        })
        .value();
    }

    function getCurrentStepIndex() {
      return _.indexOf(steps, currentStep);
    }

    function goToNextStep() {
      if (getCurrentStepIndex() + 1 === steps.length) {
        submitHandler();
      } else {
        $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, CREATE_CASE_MODAL_ID);
        setCurrentStepByIndex(getCurrentStepIndex() + 1);
        $scope.$broadcast(WIZARD_NEXT, CREATE_CASE_WIZARD_ID);
      }
    }

    function goToPreviousStep() {
      hideErrorBanner();
      if (getCurrentStepIndex() === 0) {
        backToSelectionHandler();
      } else {
        $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, CREATE_CASE_MODAL_ID);
        $scope.$broadcast(WIZARD_PREVIOUS, CREATE_CASE_WIZARD_ID);
        setCurrentStepByIndex(getCurrentStepIndex() - 1);
      }
    }

    function hideErrorBanner() {
      vm.displayMessage = null;
    }

    function initStepDescriptor(name, options) {
      const stepDefaults = {
        backHandler: goToPreviousStep,
        backLabelKey: 'previous',
        ctaHandler: goToNextStep,
        ctaLabelKey: 'next',
      };
      vm.STEP[name] = _.defaults(options, stepDefaults);
    }

    function initSteps() {
      initStepDescriptor('ISSUE_DESCRIPTION', {
        backLabelKey: 'backToSelection',
        ctaDisabled: () => issueDescriptionFormIsInvalid,
        titleKey: 'issueDescription',
      });
      initStepDescriptor('CONTACT_METHOD', {
        ctaDisabled: () => contactMethodFormIsInvalid,
        ctaLabelKey: 'submit',
        titleKey: 'contactMethod',
      });

      steps = [vm.STEP.ISSUE_DESCRIPTION, vm.STEP.CONTACT_METHOD];
      vm.stepTitles = _.map(steps, (step) =>
        $translate.instant(`widgets.support.createCaseModal.stepTitles.${step.titleKey}`)
      );
    }

    function resetModal() {
      contactMethodFormIsInvalid = true;
      issueDescriptionFormIsInvalid = true;
      contactMethod = {};
      issueDescription = {};

      _.assign(vm, {
        case: {},
        caseAttachments: [],
        isBusy: false,
      });

      hideErrorBanner();

      setCurrentStep(vm.STEP.ISSUE_DESCRIPTION);
    }

    function setCurrentStep(step) {
      currentStep = step;

      // Update step buttons.
      _.assign(
        vm,
        getCurrentStepButtonLabels(),
        _.pick(currentStep, 'backHandler', 'ctaDisabled', 'ctaHandler')
      );
    }

    function setCurrentStepByIndex(stepIdx) {
      setCurrentStep(steps[stepIdx]);
    }

    function showErrorBanner() {
      vm.displayMessage = new DisplayMessage();
    }

    function submitHandler() {
      const supportTicket = _.assign(
        {
          caseType: vm.caseType,
        },
        contactMethod,
        issueDescription
      );

      _.assign(supportTicket, {typeCode: vm.typeCode});

      hideErrorBanner();

      vm.isBusy = true;
      SupportCase.create(supportTicket).then(onSuccess).catch(onError);

      function onSuccess(response) {
        // Deliberately construct a new case here rather than calling SupportCase.get(..), in order
        // to avoid an unnecessary refresh call. The case detail page will eventually refresh the
        // model when the user is redirected there, after any attachments are uploaded.
        vm.supportCase = new SupportCase({id: response.id});
      }

      function onError() {
        vm.isBusy = false;
        showErrorBanner();
      }

      triggerAnalytics('submitButton');
    }

    function triggerAnalytics(componentMethod) {
      AnalyticsEvent.dispatch({
        componentMethod,
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
