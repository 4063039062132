import PropTypes from 'prop-types';

import help from '../../../constants/help';

import ConstructItemCardContent from './ConstructItemCardContent';
import CallToActionAnchor from './call-to-action-anchor/CallToActionAnchor';

const {LINK_TYPE} = help;

const ConstructTopicCard = ({intl, item, locale, topicIndex}) => {
  const linkContent = CallToActionAnchor({
    intl,
    itemIndex: '-topic',
    linkContent: item.links,
    linkType: LINK_TYPE.TEXT,
    locale,
    topicIndex,
  });

  return ConstructItemCardContent({
    intl,
    item,
    itemIndex: '-topic',
    linkContent,
    ordered: item.ordered,
    topicIndex,
  });
};

ConstructTopicCard.propTypes = {
  /**
   * Reference to intl for translations
   */
  intl: PropTypes.shape({formatMessage: PropTypes.func.isRequired}),
  /**
   * item content to build accordion content
   */
  item: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types -- existing usage
        links: PropTypes.arrayOf(PropTypes.object),
        text: PropTypes.string,
        title: PropTypes.string,
        translateValues: PropTypes.objectOf(PropTypes.string),
      })
    ),
  }).isRequired,
  /**
   * The two character code representing the current localization state
   */
  locale: PropTypes.string,
  /**
   * The topic index
   */
  topicIndex: PropTypes.number.isRequired,
};

export default ConstructTopicCard;
