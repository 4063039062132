import {showError as showErrorToast} from '@admin-tribe/binky-ui';
import {
  ActionButton,
  Button,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  Text,
  View,
} from '@adobe/react-spectrum';
import {DropZone} from '@react-spectrum/dropzone';
import Upload from '@spectrum-icons/illustrations/Upload';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import Close from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FileTrigger} from 'react-aria-components';
import {FormattedMessage, useIntl} from 'react-intl';

const METADATA_ACCEPTED_TYPES = ['application/octet-stream'];

const UploadFile = ({selectedFile, setSelectedFile}) => {
  const intl = useIntl();

  const [hasError, setHasError] = useState(false);

  const handleFilesAdded = async (filesAdded) => {
    let file;

    // when a file is dropped, we get the value in filesAdded.items[0] and have to call getFile()
    if (filesAdded.type === 'drop') {
      try {
        file = await filesAdded.items[0].getFile();
      } catch {
        showErrorToast();
      }
    } else {
      // when the browse button is used, we get the value in filesAdded[0] and it's already a File object
      file = filesAdded[0];
    }

    // only PEM files are accepted
    // .pem is not a standard MIME type, so we check the file extension
    // the server will also check the file contents
    const isAcceptedType = file?.name?.toLowerCase().endsWith('.pem');

    if (!isAcceptedType) {
      setHasError(true);
      return;
    }

    setHasError(false);
    setSelectedFile(file);
  };

  const handleRemoveMetadata = () => {
    setSelectedFile(null);
  };

  return (
    <>
      {!selectedFile && (
        <DropZone
          acceptedFileTypes={METADATA_ACCEPTED_TYPES}
          // this is used for the visual feedback when dragging a file over the dropzone
          getDropOperation={(types) =>
            METADATA_ACCEPTED_TYPES.some((type) => types.has(type)) ? 'copy' : 'cancel'
          }
          isFilled={selectedFile}
          onDrop={handleFilesAdded}
        >
          <IllustratedMessage>
            <Upload />
            <Heading>
              <Text slot="label">
                <FormattedMessage id="common.uploadFile.heading" />
              </Text>
            </Heading>
            <Content data-testid="upload-file-zone">
              <FileTrigger acceptedTypes={METADATA_ACCEPTED_TYPES} onSelect={handleFilesAdded}>
                <Button variant="primary">
                  <FormattedMessage id="common.uploadFile.browse" />
                </Button>
              </FileTrigger>
            </Content>
          </IllustratedMessage>
        </DropZone>
      )}

      {hasError && (
        <Text marginTop="size-200" UNSAFE_style={{color: 'var(--color-red-600)'}}>
          <FormattedMessage data-testid="error-message" id="common.uploadFile.error" />
        </Text>
      )}

      {selectedFile && (
        <View
          backgroundColor="static-white"
          borderColor="gray-300"
          borderRadius="regular"
          borderWidth="thin"
          padding="size-150"
          width="100%"
        >
          <Flex data-testid="file-card" justifyContent="space-between">
            <Flex alignItems="center">
              <AttachIcon height="size-175" marginEnd="size-100" width="size-175" />
              <Text data-testid="file-name">{selectedFile.name}</Text>
            </Flex>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'common.uploadFile.removeMetadata',
              })}
              data-testid="remove-file-btn"
              isQuiet
              onPress={handleRemoveMetadata}
            >
              <Close />
            </ActionButton>
          </Flex>
        </View>
      )}
    </>
  );
};

UploadFile.propTypes = {
  selectedFile: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  setSelectedFile: PropTypes.func.isRequired,
};

export default UploadFile;
