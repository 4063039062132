(function () {
  /**
   * @ngdoc module
   * @name app.widgets.timezone-search-list
   * @description module provides a search list populated with timezones
   */
  angular.module('app.widgets.timezone-search-list', [
    'app.core.support',
    'app.widgets.search-list',
    'binky.core.lodash',
    'pascalprecht.translate',
  ]);
})();
