// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import {SpotlightSmallPrice} from '@pandora/react-price';
import PropTypes from 'prop-types';
import React from 'react';

import {getPriceProp} from 'common/components/sophia/sophia-price/util';

/**
 * @description Component for displaying highlighted price info for Sophia cards and banners
 */
const SpotlightPriceInfo = ({color, frequency, price}) => {
  const priceProps = getPriceProp(price, frequency, true);

  return (
    <View data-testid="spotlight-price" UNSAFE_style={{color}}>
      <SpotlightSmallPrice {...priceProps} />
    </View>
  );
};

SpotlightPriceInfo.propTypes = {
  // https://git.corp.adobe.com/admin-tribe/onesie/wiki/Integration-with-Sophia
  color: PropTypes.string,

  frequency: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- see wiki above for options
  price: PropTypes.object,
};

export default SpotlightPriceInfo;
