import {ModalWizardStepList, WizardTrigger} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const STEP_NAME_TO_WIZARD_STEP = {
  CancellationReasonsStep: 'feedback',
  CancelReviewStep: 'review',
  LossAversionStep: 'details',
  SaveOfferReviewStep: 'review',
  SaveOffersStep: 'offers',
};

/**
 * Steps list component rendering the selectable steps of the flow, highlighting current one.
 * Uses WizardStepList and WizardTrigger components.
 */
const SelfCancelWizard = ({steps = [], currentStep}) => {
  const intl = useIntl();

  const wizardSteps = steps.filter((step) => STEP_NAME_TO_WIZARD_STEP[step]);

  if (wizardSteps?.length === 0) {
    return null;
  }

  const wizardStepIndex = wizardSteps.findIndex((step) => step === currentStep);

  const wizardStepsTranslated = wizardSteps.map((wizardStep) =>
    intl.formatMessage({
      id: `account.selfCancel.components.SelfCancelWizard.${STEP_NAME_TO_WIZARD_STEP[wizardStep]}`,
    })
  );

  return (
    <WizardTrigger defaultCurrentStep={wizardStepIndex} steps={wizardStepsTranslated}>
      {() => <ModalWizardStepList />}
    </WizardTrigger>
  );
};

SelfCancelWizard.propTypes = {
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default SelfCancelWizard;
