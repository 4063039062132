import PackagesEntitlements from '../services/PackagesEntitlementsService';

/**
 * @description Filter to be applied on each template in the list, based on which template is included in packagesAdobeTemplatesList
 *
 * @param {Object} template
 * @returns {Boolean} returns if template has certain entitlement based on type of template passed
 */
/* eslint-disable complexity -- checks required */
const templateFilter = (template = {}) => {
  if (template.isCaptivateTemplate && template.isPresenterTemplate) {
    return (
      PackagesEntitlements.hasCaptivateEntitlement && PackagesEntitlements.hasPresenterEntitlement
    );
  }
  if (template.isCaptivateTemplate) {
    return PackagesEntitlements.hasCaptivateEntitlement;
  }
  if (template.isPresenterTemplate) {
    return PackagesEntitlements.hasPresenterEntitlement;
  }
  if (template.isRoboHelpTemplate) {
    return PackagesEntitlements.hasRobohelpEntitlement;
  }
  if (template.isFramemakerTemplate) {
    return PackagesEntitlements.hasFramemakerEntitlement;
  }
  if (template.isTechCommSuiteTemplate) {
    return PackagesEntitlements.hasTcsEntitlement;
  }
  if (template.isRoboHelpServerTemplate) {
    return PackagesEntitlements.hasRoboHelpServerEntitlement;
  }
  if (template.isPhotoshopElementsTemplate) {
    return PackagesEntitlements.hasPhotoshopElementsEntitlement;
  }
  if (template.isPremierElementsTemplate) {
    return PackagesEntitlements.hasPremierElementsEntitlement;
  }
  if (template.isAcrobatOnlyTemplate) {
    return (
      PackagesEntitlements.hasDcEntitlement ||
      PackagesEntitlements.hasCcEntitlement ||
      PackagesEntitlements.hasTcsEntitlement
    );
  }
  return PackagesEntitlements.hasCcEntitlement;
};
/* eslint-enable complexity -- checks required */

// eslint-disable-next-line import/prefer-default-export -- one utility export only
export {templateFilter};
