(function () {
  angular.module('app.core.src2.ready', [
    'app.core.organizations.organization-manager',
    'app.core.ready.org',
    'app.core.src2-migration',
    'binky.core.authentication',
    'binky.core.authentication.ready',
    'binky.core.configuration.ready',
    'binky.core.feature.ready',
    'binky.core.locale.ready',
    'binky.core.lodash',
    'binky.core.src2-migration',
    'ui.router',
  ]);
})();
