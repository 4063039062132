import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import ResponsiveProviderContent from './ResponsiveProviderContent';

// Provider for Overview CSS grid layout that handles resizing with various breakpoints
const ResponsiveProvider = ({children, 'data-testid': dataTestId = 'responsive-provider'}) => {
  const containerRef = useRef();

  return (
    <div ref={containerRef}>
      <ResponsiveProviderContent containerRef={containerRef} data-testid={dataTestId}>
        {children}
      </ResponsiveProviderContent>
    </div>
  );
};

ResponsiveProvider.propTypes = {
  /**
   * The child components to render inside of this provider context
   */
  children: PropTypes.node.isRequired,
  /**
   * The specified data-testid string (defaults to 'responsive-provider')
   */
  'data-testid': PropTypes.string,
};

export default ResponsiveProvider;
