(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.avatar').component('appAvatarUpdater', {
    bindings: {
      hideErrorMessage: '<?',
      picFile: '=',
      resetAvatarInput: '=',
      showErrorMessage: '<?',
      user: '<',
    },
    controller,
    templateUrl: 'app/widgets/common/avatar/avatar-updater.component.html',
  });

  /* @ngInject */
  function controller($q, $sce, $translate) {
    this.$onInit = $onInit;

    /////////////
    function $onInit() {
      const vm = this;

      vm.$sce = $sce;
      vm.onSelect = onSelect;
      vm.editable = !vm.user.getType().isType1() && !vm.user.getType().isType2E();

      function onSelect() {
        // avatar size can't be larger than 100 KB
        if (vm.picFile && vm.picFile.size > 100000) {
          vm.picFile = undefined;
          vm.showErrorMessage($translate.instant('widgets.avatar.errorMessage'));
        } else {
          vm.hideErrorMessage();
        }
      }
    }
  }
})();
