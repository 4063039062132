import {feature} from '@admin-tribe/binky';
import {ModalDescription, ModalDialog, ModalHeading} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup, Flex, Heading, View} from '@adobe/react-spectrum';
import {AlertBanner, AlertBannerVariant} from '@pandora/react-alert-banner';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import requiredIf from 'common/prop-types/requiredIf';

import SelfCancelWizard from '../self-cancel-wizard/SelfCancelWizard';

import SelfCancelBaseFooter from './SelfCancelBaseFooter';
import styles from './SelfCancelBaseModal.pcss';

/**
 * Self Cancel base modal component used as the basis for all the dialogs. Provides the most common
 * features, like the special self cancel footer, heading, subheading, integration with step wizard
 * component and close, secondary and CTA buttons.
 * It will parse children components to filter SelfCancelBaseFooter component,in order to render it
 * inside the special self cancel footer.
 */
// eslint-disable-next-line complexity -- 1 over max and due to conditionals in the render
const SelfCancelBaseModal = ({
  allLicensesSelected,
  cancelLabel,
  children,
  ctaLabel,
  ctaVariant = 'cta',
  currentStep,
  errorMessage,
  heading,
  isCtaDisabled = false,
  isLoading = false,
  onCancel,
  onCloseError,
  onCta,
  onSecondary,
  secondaryLabel,
  steps,
  subheading,
}) => {
  const intl = useIntl();

  const childrenArr = React.Children.toArray(children);
  // Using SelfCancelBaseFooter to split children components
  const {content, footerContent} =
    childrenArr &&
    groupBy(childrenArr, ({type}) => (type === SelfCancelBaseFooter ? 'footerContent' : 'content'));

  return (
    <ModalDialog
      errorMessage={errorMessage}
      height="793px"
      heightVariant="static"
      id="self-cancel-modal"
      isLoading={isLoading}
      onCancel={onCancel}
      onCloseError={onCloseError}
      size="L"
    >
      {/* Prevent styles from Heading from being applied */}
      <Heading UNSAFE_style={{fontSize: 'inherit', fontWeight: 'inherit'}}>
        {/* ModalDialog has its own custom styling with width set to 1100px and we want the AlertBanner to go all the way across the ModalDialog */}
        {/* position: relative is needed to align the AlertBanner to the top of the ModalDialog */}
        {feature.isEnabled('show_paid_cancel_all_licenses_selected_banner') &&
          allLicensesSelected && (
            <View
              bottom={40}
              data-testid="cancel-all-licenses-info-banner"
              position="relative"
              right={40}
              width={1100}
            >
              <AlertBanner variant={AlertBannerVariant.INFO}>
                {intl.formatMessage({
                  id: 'account.selfCancel.components.selfCancelBaseModal.informationBannerDescription',
                })}
              </AlertBanner>
            </View>
          )}
        {heading && <ModalHeading level={2}>{heading}</ModalHeading>}
        {subheading && (
          <ModalDescription>
            {steps && <SelfCancelWizard currentStep={currentStep} steps={steps} />}
            <View data-testid="subheading" elementType="p" margin="0">
              {subheading}
            </View>
          </ModalDescription>
        )}
      </Heading>
      {content}
      {/* ButtonGroup is required in order not to render the default ModalButtonGroup from ModalDialog */}
      <ButtonGroup
        margin="size-0"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for custom styling
        UNSAFE_className={ctaLabel && styles['footer-button-group']}
      >
        <Flex alignItems="center" data-testid="self-cancel-footer" flexGrow="1" width="100%">
          {cancelLabel && (
            <Button
              data-testid="cancel-button"
              marginTop="size-0"
              onPress={onCancel}
              variant="secondary"
            >
              {cancelLabel}
            </Button>
          )}
          {secondaryLabel && (
            <Button
              data-testid="secondary-button"
              marginStart="size-200"
              marginTop="size-0"
              onPress={onSecondary}
              variant="secondary"
            >
              {secondaryLabel}
            </Button>
          )}
          {footerContent && (
            <Flex
              data-testid="footer-content"
              flexGrow="1"
              justifyContent="end"
              marginEnd="size-200"
              marginStart="size-200"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to align right
              UNSAFE_className={styles['footer-content']}
            >
              {footerContent}
            </Flex>
          )}
          {ctaLabel && (
            <Button
              data-testid="cta-button"
              isDisabled={isCtaDisabled}
              marginStart="auto" // needed to right align when no other button
              onPress={onCta}
              variant={ctaVariant}
            >
              {ctaLabel}
            </Button>
          )}
        </Flex>
      </ButtonGroup>
    </ModalDialog>
  );
};

SelfCancelBaseModal.propTypes = {
  /**
   * Whether all licenses are selected.
   */
  allLicensesSelected: PropTypes.bool,
  /**
   * Label for the cancel button.
   */
  cancelLabel: PropTypes.string,
  /**
   * The contents of the modal.
   */
  children: PropTypes.node,
  /**
   * Label for the CTA button.
   */
  ctaLabel: PropTypes.string,
  /**
   * Variant for the CTA button.
   */
  ctaVariant: PropTypes.string,
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Error message to show in the error toast
   */
  errorMessage: PropTypes.string,
  /**
   * Heading title for the modal.
   */
  heading: PropTypes.string,
  /**
   * Whether the CTA is disabled.
   */
  isCtaDisabled: PropTypes.bool,
  /**
   * Whether the loading indicator should be shown above the content.
   */
  isLoading: PropTypes.bool,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: requiredIf(PropTypes.func, (props) => props.cancelLabel),
  /**
   * Callback to invoke when the modal's error message is dismissed.
   */
  onCloseError: requiredIf(PropTypes.func, (props) => props.errorMessage),
  /**
   * Callback to invoke when the modal's cta button is pressed.
   */
  onCta: requiredIf(PropTypes.func, (props) => props.ctaLabel),
  /**
   * Callback to invoke when the modal's secondary button is pressed.
   */
  onSecondary: requiredIf(PropTypes.func, (props) => props.secondaryLabel),
  /**
   * Label for the secondary button.
   */
  secondaryLabel: PropTypes.string,
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
  /**
   * Description for the modal.
   */
  subheading: PropTypes.string,
};

export default SelfCancelBaseModal;
