import {observer} from 'mobx-react-lite';
import React from 'react';

import RemoveMemberButton from 'common/components/remove-member-button/RemoveMemberButton';
import UserOperationsMenu from 'features/users/components/user-operations-menu/UserOperationsMenu';

import NewUserGroupButton from './NewUserGroupButton';

/**
 * The button group for the 'UserGroupListTableSection'.
 */
const UserGroupListTableActions = observer(() => (
  // This is wrapped by <TableActions> which is a ButtonGroup.
  // Each button should decide if the admin has the permission for it to be shown.
  <>
    <NewUserGroupButton />
    <RemoveMemberButton />
    <UserOperationsMenu />
  </>
));

UserGroupListTableActions.displayName = 'UserGroupListTableActions';

export default UserGroupListTableActions;
