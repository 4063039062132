import {Breadcrumbs, Item, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import TopicIdentifierUtils from '../../core/TopicIdentifierUtils';

/**
 * The breadcrumbs component for the help rail
 */
const HelpRailBreadcrumbs = () => {
  const intl = useIntl();

  let crumbs;

  // only calculate/display if breadcrumbs available to show...
  const breadcrumbsExist = rootStore.helpStore.breadcrumbs.items?.length > 1;
  if (breadcrumbsExist) {
    crumbs = rootStore.helpStore.breadcrumbs.items.map((breadcrumb) => {
      const currentCrumbTopicId = TopicIdentifierUtils.getSelectedTopicId(breadcrumb);
      const title = rootStore.helpStore.topics.getById(currentCrumbTopicId)?.title;

      return {id: breadcrumb, title};
    });
  }

  return breadcrumbsExist ? (
    <View>
      <Breadcrumbs isMultiline onAction={(action) => rootStore.helpStore.goToTopic(action)}>
        {crumbs.map(({id, title}) => (
          <Item key={id}>{intl.formatMessage({id: title})}</Item>
        ))}
      </Breadcrumbs>
    </View>
  ) : null;
};

export default observer(HelpRailBreadcrumbs);
