import {log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import jilTrusts from 'features/settings/api/jilTrusts';
import {trustsBatchOperation} from 'features/settings/common/utils/trustUtils';

/**
 * Hook that helps with making calls to the JIL API for trust requests in an org.
 */
const useTrustRequests = () => {
  const sendTrustRequests = useCallback(async (directories) => {
    const orgId = rootStore.organizationStore.activeOrgId;

    try {
      const payloadDirectories = directories.map(({id}) => ({directoryId: id}));

      const response = await jilTrusts.postTrusts({
        directories: payloadDirectories,
        orgId,
      });

      const sent = response.data;
      const failedToSend = payloadDirectories.filter(
        (directory) =>
          !sent.find((sentRequest) => sentRequest.directoryId === directory.directoryId)
      );

      return {
        failedToSend,
        sent,
      };
    } catch (error) {
      log.error(`[ID][JIL] Error sending trust requests`, error);

      throw error;
    }
  }, []);

  const acceptTrustRequests = useCallback(async (trusts) => {
    const operations = trusts.map(({trusteeOrgId, directoryId, notifyAdmins}) => ({
      op: 'replace',
      path: `/${trusteeOrgId}/${directoryId}/ACCEPT/notifications/${notifyAdmins}`,
    }));

    try {
      const {failed, successful} = await trustsBatchOperation(operations);

      return {
        accepted: successful,
        failedToAccept: failed,
      };
    } catch (error) {
      log.error(`[ID][JIL] Error accepting trust requests`, error);

      throw error;
    }
  }, []);

  const rejectTrustRequests = useCallback(async (trusts, reason) => {
    const operations = trusts.map(({trusteeOrgId, directoryId}) => ({
      op: 'replace',
      path: reason
        ? `/${trusteeOrgId}/${directoryId}/REJECT/reason/${reason}`
        : `/${trusteeOrgId}/${directoryId}/REJECT`,
    }));

    try {
      const {failed, successful} = await trustsBatchOperation(operations);

      return {
        failedToReject: failed,
        rejected: successful,
      };
    } catch (error) {
      log.error(`[ID][JIL] Error rejecting trust requests`, error);

      throw error;
    }
  }, []);

  return {
    acceptTrustRequests,
    rejectTrustRequests,
    sendTrustRequests,
  };
};

export default useTrustRequests;
