import {Button, DialogContainer} from '@adobe/react-spectrum';
import {useModalDialog} from '@pandora/react-modal-dialog';
import {TableActions} from '@pandora/react-table-section';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import rootStore from 'core/RootStore';
import {useAppIntegrationTableContext} from 'features/products/app-integrations/hooks/AppIntegrationTableContext';

import AddAppIntegrationModal from '../add-app-integration/AddAppIntegrationModal';
import {AddAppIntegrationModalContextProvider} from '../add-app-integration/hooks/AddAppIntegrationModalContext';

/**
 * The set of table action buttons for manipulating the app integrations table
 */
const AppIntegrationsTableActions = () => {
  const {isOpen, openModal, closeModal} = useModalDialog();
  const {refreshList} = useAppIntegrationTableContext();
  return (
    <>
      <TableActions>
        <Button onPress={openModal} variant="accent">
          <FormattedMessage id="products.appIntegrations.appIntegrationsTableActions.button.addIntegrations" />
        </Button>
      </TableActions>
      <DialogContainer onDismiss={closeModal}>
        {isOpen && (
          <AddAppIntegrationModalContextProvider orgId={rootStore.organizationStore.activeOrgId}>
            <AddAppIntegrationModal closeModal={closeModal} onSuccess={refreshList} />
          </AddAppIntegrationModalContextProvider>
        )}
      </DialogContainer>
    </>
  );
};

AppIntegrationsTableActions.displayName = 'AppIntegrationsTableActions';

export default AppIntegrationsTableActions;
