import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import DirectoryStore from 'features/settings/stores/DirectoryStore';

/**
 * Private Context for the Directory Details page and its sub-components.
 * Used to pass the directoryStore between sub-components.
 */

const DirectoryContext = createContext({});
const useDirectoryContext = () => useContext(DirectoryContext);

const DirectoryContextProvider = ({children, directoryStore}) => {
  const value = useMemo(() => ({directoryStore}), [directoryStore]);
  return <DirectoryContext.Provider value={value}>{children}</DirectoryContext.Provider>;
};

DirectoryContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
  /**
   * The domain store for a Directory.
   */
  directoryStore: PropTypes.instanceOf(DirectoryStore).isRequired,
};

export {DirectoryContextProvider, useDirectoryContext};
