(function () {
  /**
   * @ngdoc module
   * @name app.widgets.scorecard
   * @description module that defines scorecard widgets
   */
  angular.module('app.widgets.scorecard', [
    'app.core.auth',
    'app.core.message',
    'app.core.stock',
    'app.widgets.bubble',
    'binky.core.lodash',
    'binky.widgets.common.carousel',
    'binky.widgets.common.feature',
    'binky.widgets.common.toast',
    'pascalprecht.translate',
  ]);
})();
