import PropTypes from 'prop-types';

import ConstructTopicCard from './ConstructTopicCard';
import ConstructTopicCardContent from './ConstructTopicCardContent';

const ConstructCardContent = ({intl, item, locale, topicIndex}) =>
  item.content?.length > 0
    ? ConstructTopicCardContent({intl, item, locale, topicIndex})
    : ConstructTopicCard({intl, item, locale, topicIndex});

ConstructCardContent.propTypes = {
  /**
   * Reference to intl for translations
   */
  intl: PropTypes.shape({formatMessage: PropTypes.func.isRequired}),
  /**
   * The help item content to build accordion content
   */
  item: PropTypes.shape({
    key: PropTypes.string,
    locator: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
    target: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * The two character code representing the current localization state
   */
  locale: PropTypes.string,
  /**
   * The topic index
   */
  topicIndex: PropTypes.number.isRequired,
};

export default ConstructCardContent;
