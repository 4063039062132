import {formatFileSize, getMemberDisplayName} from '@admin-tribe/binky-ui';
import {fetchReadableUnitsOfStorage} from '@pandora/react-formatted-file-size';
import {EN_DASH} from '@pandora/react-table-section';

/**
 * Function used to convert a number value to a readable string value (with Gb, Mb, etc)
 *
 * @param {Object} intl - react intl
 * @param {Number} value - number of bytes of data
 *
 * @returns {String} formatted storage
 */
const formatStorageForDisplay = (intl, value) => {
  if (value === undefined) {
    return EN_DASH;
  }

  const fileSize = fetchReadableUnitsOfStorage(value, 1);
  return formatFileSize(intl, fileSize);
};

/**
 * Formats the name of the UserFolder
 *
 * @param {Object} intl - react intl
 * @param {Object} userFolder - userFolder to format
 *
 * @returns {String} formatted name
 */
const formatName = (intl, userFolder) => {
  if (
    !userFolder.owners ||
    userFolder.owners.length === 0 ||
    userFolder.owners[0].firstName === 'DELETED'
  ) {
    return userFolder.name;
  }

  return getMemberDisplayName(intl, userFolder.owners[0], {fallbackToEmail: false});
};

export {formatStorageForDisplay, formatName};
