const PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS = 'quick-assign-products';
const PATH_SEGMENT_ASSIGN_PRODUCTS = 'assign-products';
const PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS = 'review-user-introductions';
const PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS = 'review-saved-user-introductions';

const PATH_OVERVIEW = '/:orgId/overview';
const PATH_OVERVIEW_ADD_PRODUCTS = `${PATH_OVERVIEW}/add-products`;
const PATH_OVERVIEW_ASSIGN_PRODUCTS = `${PATH_OVERVIEW}/${PATH_SEGMENT_ASSIGN_PRODUCTS}`;
const PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS = `${PATH_OVERVIEW}/${PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS}`;
const PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS = `${PATH_OVERVIEW}/${PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS}`;
const PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS = `${PATH_OVERVIEW}/${PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS}`;

export {
  PATH_OVERVIEW,
  PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_ADD_PRODUCTS,
  PATH_OVERVIEW_ASSIGN_PRODUCTS,
  PATH_SEGMENT_ASSIGN_PRODUCTS,
  PATH_SEGMENT_QUICK_ASSIGN_PRODUCTS,
  PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
  PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS,
  PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS,
};
