(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').component('appOrganizationDetails', {
    controller,
    templateUrl: 'app/account/organization-details/organization-details.component.html',
  });

  /* @ngInject */
  function controller($q, _, organizationAccess) {
    const vm = this;
    _.assign(vm, {
      $onInit,
    });

    ///////////////

    function $onInit() {
      _.assign(vm, {
        canViewOrganizationType: organizationAccess.canViewOrganizationType(),
        waitOnPage: $q.resolve(),
      });
    }
  }
})();
