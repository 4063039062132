(function () {
  const componentName = 'appRemoveDeveloperModal';
  /**
   * @deprecated by the users port
   *
   * @ngdoc component
   * @name app.widgets.developers.remove-developer-modal:appRemoveDeveloperModal
   *
   * @description A remove developer modal widget for developer admins.
   *
   * @param {String} confirmButtonLabel The label for the confirmation button.
   * @param {PageContext} pageContext The PageContext object passed to the modal
   * @param {Product} [product] The Product object passed to the modal
   * @param {ProductConfiguration} [productProfile] The ProductConfiguration object passed to the modal
   * @param {MemberList} userList The user list object (ProductConfigurationAdminList or OrganizationAdminList) passed to the modal
   * @param {MemberList} userListSelection The list object of selected users passed to the modal
   *
   */
  angular.module('app.widgets.developers.remove-developer-modal').component(componentName, {
    bindings: {
      confirmButtonLabel: '@',
      pageContext: '<',
      product: '<?',
      productProfile: '<?',
      userList: '<',
      userListSelection: '<',
    },
    controller,
    templateUrl: 'app/widgets/developers/remove-developer-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $document,
    $scope,
    $timeout,
    $translate,
    AnalyticsEvent,
    OrganizationUserList,
    panelManager,
    productAccess,
    PAGE_TARGET,
    REMOVE_DEVELOPER_MODAL_ID,
    ROLE,
    RESET_SEARCH_FILTER,
    toastManager
  ) {
    const vm = this;

    vm.showDevelopersSummary = false;
    vm.showRemoveDeveloperFromOrg = false;

    // Configure what needs to be displayed on the modal based on page target
    let targetLevel;
    if (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
      targetLevel = 'profile';
    } else if (vm.pageContext.target === PAGE_TARGET.ORGANIZATION) {
      targetLevel = 'org';
      vm.showDevelopersSummary = true;
      vm.showRemoveDeveloperFromOrg = true;
      vm.selectionDetailsForOrgRemove = $translate.instant(
        'widgets.developerConfirmationModal.removeFromOrganization'
      );
    }

    vm.selectionDetailsForDevAndUserRemove = $translate.instant(
      `widgets.developerConfirmationModal.removeDeveloperAndUserAccess.${targetLevel}.details`
    );
    vm.selectionDetailsForDevOnlyRemove = $translate.instant(
      `widgets.developerConfirmationModal.removeDeveloperAccessOnly.${targetLevel}.details`
    );
    vm.modalTitle = $translate.instant(
      `widgets.developerConfirmationModal.modalTitle.${targetLevel}`
    );

    _.assign(vm, {
      $onInit,
      isAPIOnlyProduct,
      onCancel,
      onConfirm,
      onModalOpen,
      showRemoveDevOptions,
      updateRemoveActionSelection,
    });

    function $onInit() {
      initializeSelection();
    }

    // Helper method that returns memberId and paths pairs to be used for developer's user access removal
    // in Member-List service's remove operation
    function getRemoveDeveloperUserAccessPaths(users) {
      const result = {};
      _.forEach(users, (user) => {
        const roles = _.find(user.savedState.roles, {type: ROLE.ADMIN.LICENSE_DEV});
        _.forEach(roles.targets, (target) => {
          const path = `/${user.id}/products/${target.parentId}/licenseGroups/${target.id}`;
          if (result[user.id]) {
            result[user.id].push(path);
          } else {
            result[user.id] = [path];
          }
        });
      });
      return result;
    }

    function initializeSelection() {
      // For API only product we don't have user access
      if (isAPIOnlyProduct()) {
        vm.removeActionSelection = 'removeDeveloperRightsOnly';
      } else {
        vm.removeActionSelection = 'removeDeveloperRightsAndUserAccess';
      }
    }

    function isAPIOnlyProduct() {
      return vm.product && productAccess.isAPIOnlyProduct(vm.product);
    }

    function onCancel() {
      initializeSelection();
    }

    function onConfirm() {
      // The selection on the remove developer modal determines the value of removeUserAccess (removeFromOrg to be added later)
      const removeOptions = {
        removeFromOrg: vm.removeActionSelection === 'removeFromOrg',
        removeUserAccess: vm.removeActionSelection === 'removeDeveloperRightsAndUserAccess',
      };
      triggerAnalytics();
      removeDevelopersAndToast(vm.userListSelection.items, removeOptions);
    }

    function onModalOpen() {
      // Coral-radio button labels are not being rendered correctly when using traditional template translations
      // So to workaround the problem, we programmatically set them instead
      $timeout(() => {
        setLabelOnRadioButton('user');
        setLabelOnRadioButton('developer');
        if (vm.pageContext.target === PAGE_TARGET.ORGANIZATION) {
          setLabelOnRadioButton('org');
        }

        function setLabelOnRadioButton(type) {
          $document[0].querySelector(`.remove-developer-modal .radio-${type}`).set({
            label: {
              innerHTML: `${$translate.instant(
                `widgets.developerConfirmationModal.selectionTitle.${type}`
              )}`,
            },
          });
        }
      });
    }

    function removeDevelopersAndToast(users, removeOptions) {
      panelManager.close(REMOVE_DEVELOPER_MODAL_ID);
      initializeSelection();
      if (removeOptions.removeFromOrg) {
        // We are not interested in the contents of the OrganizationUserList but just the save operation the model provides
        const orgUserList = new OrganizationUserList();
        orgUserList.remove(users);
        orgUserList.save().then(onRemoveUsersSuccess).catch(onRemoveUsersError);
      } else {
        removeDeveloperRole(users);
        vm.userList.addModifiedUsers(users);
        vm.userList.save(removeOptions).then(onRemoveUsersSuccess).catch(onRemoveUsersError);
      }
      // If we want to remove user access we instead use the OrganizationUserList
      if (vm.showRemoveDeveloperFromOrg && removeOptions.removeUserAccess) {
        const pathOverrides = getRemoveDeveloperUserAccessPaths(users);
        const orgUserList = new OrganizationUserList();
        orgUserList.remove(users);
        orgUserList
          .save({removeProductAndRolePathOverrides: pathOverrides})
          .catch(onRemoveUsersError);
      }

      function onRemoveUsersError() {
        // show the generic error message
        toastManager.showErrorToast();
      }

      function onRemoveUsersSuccess() {
        $scope.$emit(RESET_SEARCH_FILTER);
        toastManager.showSuccessToast();
        vm.userListSelection.deselectAllItems();
        vm.userList.refresh();
      }
    }

    // Helper function that removes the developer role from the user object, which is then passed to the respective services
    // for batch operation
    function removeDeveloperRole(users) {
      if (vm.pageContext.target === PAGE_TARGET.ORGANIZATION) {
        _.forEach(users, (user) => {
          user.roles = _.map(user.roles, (role) => {
            // Empty the targets of dev admin role when removing from the global developer list
            if (role.type === ROLE.ADMIN.LICENSE_DEV) {
              role.targets = [];
            }
            return role;
          });
        });
      } else if (vm.pageContext.target === PAGE_TARGET.PRODUCT_CONFIGURATION) {
        _.forEach(users, (user) => {
          const role = _.find(user.roles, {type: ROLE.ADMIN.LICENSE_DEV});
          role.targets = _.filter(
            role.targets,
            (target) =>
              !(
                vm.pageContext.targetId === target.id &&
                vm.pageContext.targetParentId === target.parentId
              )
          );
        });
      }
    }

    function showRemoveDevOptions() {
      return vm.showRemoveDeveloperFromOrg || productAccess.isHybridProduct(vm.product);
    }

    function triggerAnalytics() {
      const attributes = {};
      if (vm.product) {
        attributes.productOffer = _.get(vm.product, 'offerId');
      } else if (vm.productProfile) {
        attributes.productOffer = _.get(vm.productProfile, 'product.offerId');
      }
      attributes.usersRemovedCount = vm.userListSelection.items.length;
      attributes.selectedRemoveOption = vm.removeActionSelection;
      AnalyticsEvent.dispatch({
        attributes,
        componentMethod: 'removeDevelopers',
        componentMethodType: 'submit',
        componentName,
        pageContext: vm.pageContext,
      });
    }

    function updateRemoveActionSelection(event) {
      vm.removeActionSelection = event.target.value;
    }
  }
})();
