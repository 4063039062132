(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Idp
   * @description Model for an Idp.
   */
  angular.module('app.core.ims-federated').factory('Idp', getIdpModel);

  /* @ngInject */
  function getIdpModel($log, $q, _, IDP_STATUS, IDP_TYPES, imsFederated) {
    const DEFAULT_TYPE = IDP_TYPES.SAML;

    class Idp {
      /**
       *
       * @description Simple constructor for new Idp models.
       *
       * @param {Object} [options]                   Key/value options for Idp construction
       * @param {String} [options.authSourceId]      Id of the auth source this Idp belongs to
       * @param {String} [options.federationType]    The type of the Idp (See IDP_TYPES)
       * @param {String} [options.aadOrganization]   Id of the azure organization. This is only set for Azure Idps
       * @param {String} [options.defaultIdp]        The Id of the Idp that is used to perform
       *                                              federation, called default Idp.
       * @param {String} [options.testUrl]           The url the connection with the Idp can be tested with
       * @param {String} [options.providerName]      SOIDC IdPs need a provider name to distinguish between them.
       * @param {String} [options.mfaAuthnContextClasses] MFA AuthContext Classes for this IdP
       * @param {String} [options.reauthAuthnContextClasses] Reauth AuthContext Classes for this IdP
       */
      constructor(options = {}) {
        this.federationType = options.federationType || DEFAULT_TYPE;
        this.authSourceId = options.authSourceId;
        this.defaultIdp = options.defaultIdp;
        this.testUrl = options.testUrl;
        this.providerName = options.providerName;
        this.reauthAuthnContextClasses = options.reauthAuthnContextClasses;
        this.mfaAuthnContextClasses = options.mfaAuthnContextClasses;

        if (this.federationType === IDP_TYPES.AZURE) {
          // This mimicks the backend validation of requiring an Azure org ID
          // Might be removed in the future or replaced with a default value
          if (!options.aadOrganization) {
            throw new Error('Cannot create an Idp without an Azure organziation');
          }

          this.aadOrganization = options.aadOrganization;
        }
      }

      /**
       * @description Determines whether the Idp can become default. Idp should have
       *              ACTIVE status property but not the defaultIdp id.
       * @returns {boolean} true if it is, false otherwise
       */
      canBeDefault() {
        return this.isActive() && !this.isDefault();
      }

      /**
       * @description Composes the ACS url from the alias's value and the ims url
       *
       * @returns {String} ACS url
       */
      getAcsUrl() {
        return `${imsFederated.acsUrl}/${_.get(this, 'spAlias', '')}`;
      }

      /**
       * @description Gets the Idp's entity id if present
       *
       * @returns {String|null} The Idp's entity id or null otherwise
       */
      getEntityId() {
        return _.get(this, 'spEntityId', null);
      }

      /**
       * @description Gets the IdP's AuthnContextClassRef for MFA if present
       *
       * @return {[String]|null} An array of classes or null
       */
      getMfaAuthnContextClasses() {
        return _.get(this, 'mfaAuthnContextClasses', null);
      }

      /**
       * @description Gets the IdP's AuthnContextClassRef for Reauthentication if present
       *
       * @return {[String]|null} An array of classes or null
       */
      getReauthAuthnContextClasses() {
        return _.get(this, 'reauthAuthnContextClasses', null);
      }

      /**
       * Checks if the Idp is active based on the status property.
       *
       * @return {boolean} True if status is ACTIVE, false otherwise
       */
      isActive() {
        return this.status === IDP_STATUS.ACTIVE;
      }

      /**
       * Checks to see if this Idp is an Azure Idp or not
       *
       * @return {boolean} True if it is, false otherwise
       */
      isAzure() {
        return this.federationType === IDP_TYPES.AZURE;
      }

      /**
       * @description Checks to see if this Idp is the one used to perform
       *                              federation, called default Idp.
       *
       * @return {boolean} True if it is, false otherwise
       */
      isDefault() {
        return this.id === this.defaultIdp;
      }

      /**
       * @description Checks to see if this Idp is a Google Idp or not.
       *              The check is based on the `entityId` containing
       *              'google.com' in it.
       *
       * @return {boolean} True if it is, false otherwise
       */
      isGoogle() {
        return (
          this.federationType === IDP_TYPES.SAML &&
          _.includes(this.entityId, 'https://accounts.google.com')
        );
      }

      /**
       * Checks to see if this Idp is new or is already created (has an id)
       *
       * @return {boolean} True if it doesn't have an id, false otherwise
       */
      isNew() {
        return !this.id;
      }

      /**
       * Checks to see if this Idp is an Okta Idp or not
       *
       * @return {boolean} True if it is, false otherwise
       */
      isOkta() {
        return this.federationType === IDP_TYPES.OKTA;
      }

      /**
       * @description Determines whether the Idp can be removed or not. Idp
       *              should not have the defaultIdp id.
       * @returns {boolean} true if it is, false otherwise
       */
      isRemovable() {
        return !this.isDefault() && !this.isOkta();
      }

      /**
       * Checks to see if this Idp is a Generic SAML Idp or not
       *
       * @return {boolean} true if it is, false otherwise
       */
      isSaml() {
        return this.federationType === IDP_TYPES.SAML;
      }

      /**
       * Checks to see if this Idp is a Static OIDC Idp or not
       *
       * @return {boolean} True if it is, false otherwise
       */
      isSoidc() {
        return this.federationType === IDP_TYPES.SOIDC;
      }

      /**
       * @description Determines whether the connection with the Idp can be tested
       *              or not. Idp should have ACTIVE status and there should
       *              be a defined testUrl to the Idp, but it shouldn't have the
       *              defaultIdp id.
       * @returns {boolean} true if it is, false otherwise
       */
      isTestable() {
        return !!this.testUrl && this.isActive() && !this.isDefault();
      }

      /**
       * @description Determines whether an Idp has expired if its type is
       *              OKTA and its lastDefaultTime is longer than 7 days.
       *              This is done so that switching away from Okta is "final"
       *              after 7 days and we can consider this directory migrated
       *              off of Okta for good. 7 days should be enough to validate
       *              a new default configuration.
       *
       * @returns {boolean} True if it is, false otherwise
       */
      isUsageDeprecated() {
        if (!this.isOkta() || this.isDefault()) {
          return false;
        }
        // For how long after becoming non-default should card remain visible.
        const daysVisibleFor = 7;
        return Date.now() - this.lastDefaultTime >= daysVisibleFor * 24 * 60 * 60 * 1000;
      }

      /**
       * @description Saves a new Idp
       *
       * @returns {Promise<Idp>} A promise that resolves to the same Idp instance
       *                         with the new properties from the API response.
       */
      save() {
        return imsFederated
          .idps(this.authSourceId)
          .save({idpId: this.id}, this.toMinimumModel())
          .$promise.then((response) => _.assign(this, response))
          .catch((error) => {
            $log.error('Idp failed to save. Error:', error);
            return $q.reject(error);
          });
      }

      /**
       * @description Returns a simple Object that contains only the neccessary
       *              properties used by the API to deal with an Idp
       *
       * @returns {Object} Minimum necessary representation of model
       */
      toMinimumModel() {
        return this.isNew()
          ? _.pick(this, [
              'aadOrganization',
              'federationType',
              'id',
              'providerName',
              'reauthAuthnContextClasses',
              'mfaAuthnContextClasses',
            ])
          : _.pick(this, [
              'aadOrganization',
              'federationType',
              'reauthAuthnContextClasses',
              'mfaAuthnContextClasses',
            ]);
      }

      /**
       * Uploads the metadata file for a SAML Idp.
       * Metadata file is uploaded only if the Idp is not new.
       *
       * @param {File} metadata The metadata file to be uploaded
       *
       * @return {Promise} A promise that resolves or rejects based on the request
       *                   status code.
       */
      uploadMetadataFile(metadata) {
        if (this.isNew()) {
          return $q.reject(new Error('Cannot upload metadata for an Idp without an id'));
        }

        const formData = new FormData();
        formData.append('file', metadata);

        return imsFederated
          .idps(this.authSourceId)
          .uploadMetadata({idpId: this.id}, formData)
          .$promise.then((response) => _.assign(this, response))
          .catch((error) => {
            $log.error('Failed to upload Idp metadata. Error:', error);
            return $q.reject(error);
          });
      }

      /**
       * @description Creates a new Idp and saves it
       *
       * @param {Object} options The options for this new Idp, same as the constructor ones.
       *
       * @returns {Promise<Idp>} A promise that resolves to the newly created Idp
       */
      static create(options) {
        const idp = new Idp(options);
        return idp.save();
      }

      /**
       * Constructs an Idp model from a simple object representing an Idp
       *
       * @param {Object} data The simple object representing an Idp
       *
       * @return {Idp} The constructed model
       */
      static constructFromResponseObject(data) {
        const options = _.pick(data, [
          'aadOrganization',
          'federationType',
          'authSourceId',
          'createdAt',
          'defaultIdp',
          'sniffedProvider',
          'providerName',
          'reauthAuthnContextClasses',
          'mfaAuthnContextClasses',
        ]);
        const idp = new Idp(options);

        // Assign the other properties that are normally added on save
        _.assign(idp, data);

        return idp;
      }
    }

    return Idp;
  }
})();
