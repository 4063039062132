/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.purchase-total:appProductPurchaseSearchProducts
 * @description A widget to optionally select the plan type for the purchase flow and to search for
 *   specific products to narrow the offers shown.
 *
 * @binding {Function} onPlanChange. Callback when selector has changed. Param selection is CUSTOMER_SEGMENT.
 * @binding {Function} onProductSearch. Callback when search string has changed. Param value is the search text.
 * @binding {CUSTOMER_SEGMENT} planSelected. Two-way binding. If initially set, and showPlanSelector is true,
 *   it will be used to initialize the plan selector and it will be updated to reflect the selected CUSTOMER_SEGMENT.
 * @binding {Boolean} showPlanSelector. Default is undefined. If true, the plan selector will be shown.
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appProductPurchaseSearchProducts', {
    bindings: {
      onPlanChange: '&',
      onProductSearch: '&',
      planSelected: '=',
      showPlanSelector: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/purchase-search-products/product-purchase-search-products.component.html',
  });

  /* @ngInject */
  function controller($element, $translate, _, CUSTOMER_SEGMENT) {
    const vm = this;
    let searchValue = '';

    _.assign(vm, {
      $onInit,
      onSearchChange,
      onSelectChange,
      resetSearch,
    });

    function $onInit() {
      initPlanSelector();
      initProductSearch();

      updateTitle();
    }

    function onSearchChange(value) {
      searchValue = value;
      vm.onProductSearch({value});
    }

    function onSelectChange({selection}) {
      // If search has a value, clear it.
      if (searchValue) {
        vm.resetSearch(); // call using scope reference so method can be mocked for unit testing
      }

      vm.showSearch = true;
      updateTitle();

      vm.onPlanChange({selection});
    }

    // This is on scope so it can be mocked for unit testing.
    function resetSearch() {
      // This should clear both the input val and the `X` button to clear the field.
      // Hiding the clear button is only done in newer versions of Coral.
      $element.find('coral-search')[0].clear();
      onSearchChange('');
    }

    /////////////

    function initPlanSelector() {
      if (vm.showPlanSelector) {
        vm.customerSegmentPlaceholder = $translate.instant(
          'widgets.products.purchase.searchProducts.selectPlan.placeholder'
        );

        vm.customerSegments = _.map(CUSTOMER_SEGMENT, (segment) => ({
          name: $translate.instant(
            `widgets.products.purchase.searchProducts.selectPlan.customerSegmentName.${segment}`
          ),
          value: segment,
        }));
      }
    }

    function initProductSearch() {
      vm.searchPlaceholder = $translate.instant(
        'widgets.products.purchase.searchProducts.search.placeholder'
      );
    }

    function updateTitle() {
      const opKey = vm.planSelected ? 'search' : 'selectPlan';
      vm.title = $translate.instant(`widgets.products.purchase.searchProducts.${opKey}.title`);
    }
  }
})();
