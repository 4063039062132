/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
import {navBus} from '@admin-tribe/binky';

import externalAuthService from 'features/settings/services/ExternalAuthService';

/**
 * Redirects the user to the identity setup page if they are returning from an external source login attempt
 */
const redirectFromExternalLogin = () => {
  const returnUrl = externalAuthService.getReturnUrl();
  const toPathname = returnUrl || '/settings/identity';

  const hash = window.hashFromPageLoad;
  let decodedHash = '';
  if (hash) {
    const strippedHash = hash.startsWith('#') ? hash.slice(1) : hash;
    decodedHash = `#${decodeURIComponent(strippedHash)}`;
  }

  const toPath = `${toPathname}${window.location.search}${decodedHash}`;

  navBus.pushState({url: toPath});
};

/**
 * Checks for external login and redirects to the correct page if needed
 */
const checkForExternalLogin = () => {
  if (externalAuthService.isLoginInProgress()) {
    redirectFromExternalLogin();
  }
};

export {checkForExternalLogin, redirectFromExternalLogin};
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
