(function () {
  /**
   * @deprecated use src2 storageAccess
   *
   * @ngdoc service
   * @name storageAccess
   * @description storage access factory
   */
  angular.module('app.core.storage.access').factory('storageAccess', StorageAccess);

  /* @ngInject */
  function StorageAccess(onesieSrc2, promiseUtils) {
    const service = {
      activeOrgHasESM,
      canPurchaseAdditionalStorage,
      canViewIndividualUserFolders,
      canViewStorage,
    };

    return service;

    //////////////

    /**
     * @description Method to determine if the org has esm
     *
     * @returns {Promise} Resolves with true if the org has storage repo
     */
    function activeOrgHasESM() {
      const es6Promise = onesieSrc2.core.storage.access.activeOrgHasESM();
      return promiseUtils.toAngularPromise(es6Promise);
    }

    /**
     * @description Method to determine if the org has access to purchase additional storage
     *
     * @returns {Promise} Resolves with true if the org has access to purchase additional storage
     */
    function canPurchaseAdditionalStorage() {
      const es6Promise = onesieSrc2.core.storage.access.canPurchaseAdditionalStorage();
      return promiseUtils.toAngularPromise(es6Promise);
    }

    /**
     * @description Method to determine if the org has access to view Individual User Folders
     *
     * @returns {Promise} Resolves with true if the org has access to view Individual User Folders
     */
    function canViewIndividualUserFolders() {
      // this doesn't just return true like shared folders, because the check is used outside of routes
      // to determine whether to offer asset reclamation during user removal. It needs to check for the
      // presence of storage at all on the org, just like general storage access.
      const es6Promise = onesieSrc2.core.storage.access.canViewIndividualUserFolders();
      return promiseUtils.toAngularPromise(es6Promise);
    }

    /**
     * @description Method to determine if user and org has access to Storage related content
     *
     * @returns {Promise} Resolves with true if the user and org has access to Storage related content
     */
    function canViewStorage() {
      const es6Promise = onesieSrc2.core.storage.access.canViewStorage();
      return promiseUtils.toAngularPromise(es6Promise);
    }
  }
})();
