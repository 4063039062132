import {DIRECTORY_DOMAIN_ENFORCEMENT_STATUS} from '@admin-tribe/binky';
import {Flex, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Domain Enforcement Warnings for domain adding/linking
 */
const DomainEnforcementInlineWarnings = ({
  status,
  hasAnyDomainWithDomainEnforcementEnabled,
  activatedTextKey,
  deactivatedToActivatedTextKey,
  enforcedTextKey,
  ...styleProps
}) => {
  const intl = useIntl();

  return (
    <Flex direction="column" gap="size-50" {...styleProps}>
      {status === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.DEACTIVATED &&
        hasAnyDomainWithDomainEnforcementEnabled && (
          <Flex>
            <AlertIcon
              aria-label={intl.formatMessage({
                id: 'settings.directoryDomains.addDomains.warning.ariaLabel',
              })}
              color="notice"
              minWidth="size-225"
              size="S"
            />
            <Text marginStart="size-100">
              {intl.formatMessage(
                {
                  id: deactivatedToActivatedTextKey,
                },
                {
                  goUrl: (str) => <GoUrl name="aac_restricting_domains">{str}</GoUrl>,
                }
              )}
            </Text>
          </Flex>
        )}

      {(status === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED ||
        status === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED) && (
        <Flex>
          <AlertIcon
            aria-label={intl.formatMessage({
              id: 'settings.directoryDomains.addDomains.warning.ariaLabel',
            })}
            color="notice"
            minWidth="size-225"
            size="S"
          />
          <Text marginStart="size-100">
            {intl.formatMessage(
              {
                id: activatedTextKey,
              },
              {
                goUrl: (str) => <GoUrl name="aac_restricting_domains">{str}</GoUrl>,
              }
            )}
          </Text>
        </Flex>
      )}

      {status === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED && (
        <Flex>
          <AlertIcon
            aria-label={intl.formatMessage({
              id: 'settings.directoryDomains.addDomains.warning.ariaLabel',
            })}
            color="notice"
            minWidth="size-225"
            size="S"
          />
          <Text marginStart="size-100">
            {intl.formatMessage(
              {
                id: enforcedTextKey,
              },
              {
                goUrl: (str) => (
                  <GoUrl name="aac_restricting_domains_require_email_change">{str}</GoUrl>
                ),
              }
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

DomainEnforcementInlineWarnings.propTypes = {
  /**
   * The domain enforcement activated translation id
   */
  activatedTextKey: PropTypes.string.isRequired,
  /**
   * Translation id for the warning when transferring domains from activated to deactivated domain enforcement
   */
  deactivatedToActivatedTextKey: PropTypes.string.isRequired,
  /**
   * The domain enforcement enforced translation id
   */
  enforcedTextKey: PropTypes.string.isRequired,
  /**
   * Flag to indicate if there are any domains with domain enforcement already enabled
   */
  hasAnyDomainWithDomainEnforcementEnabled: PropTypes.bool.isRequired,
  /**
   * The domain enforcement status
   */
  status: PropTypes.string,
};

export default DomainEnforcementInlineWarnings;
