import {
  EVENT_ACTION,
  ProductList,
  dispatchUiEventAnalytics,
  feature,
  getUnassignedProducts,
} from '@admin-tribe/binky';
import {
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {ProductAssignedLicensesSummary} from '@pandora/react-product-assigned-licenses-summary';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import QuickAssignModalWrapper from 'features/mini-apps/quick-assign-modal-wrapper/QuickAssignModalWrapper';

import {
  ANALYTICS_MODAL_NAME,
  mapProductsToAnalytics,
} from './unassignedLicensesSummaryModalAnalyticsUtils';

const CUSTOM_DIALOG_STYLE = {};

// There is a modal divider attached to the ModalDescription component.
// However it applies unwanted padding on top of the divider line when there is no content.
// There is a theme in AC modals where descriptions are put above the divider line.
// UX follow the standard spectrum Dialog behaviour and want the content of a description to go below the divider line.
// As a result we are manually using a divider line here.
const CUSTOM_DESCRIPTION_STYLE = {
  dividerGap: 'size-0',
};

/**
 * Unassigned Licenses Summary Modal dialog. It lists the products owned by a user that are unassigned.
 * The modal provides a primary and secondary call to action. The original intentaion for call to actions was:
 * The primary displays the assign users modal, the secondary will direct them to the renewal flow.
 */
const UnassignedLicensesSummaryModal = ({
  ctaLabel,
  isOpen = false,
  onOpenAssignLicensesModal,
  onOpenRenewalFlow,
  secondaryLabel,
  productList,
}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isAssignUsersOpen, setIsAssignUsersOpen] = useState(false);

  const analyticsProductList = mapProductsToAnalytics(getUnassignedProducts(productList));

  const modalId = 'unassigned-licenses-modal';
  const primaryCtaLabel =
    ctaLabel || intl.formatMessage({id: `account.unassignedLicensesSummary.button.cta`});
  const secondaryCtaLabel =
    secondaryLabel ||
    intl.formatMessage({
      id: `account.unassignedLicensesSummary.button.secondary2`,
    });

  const content = ModalDialogModel.createEntry({
    ctaLabel: primaryCtaLabel,
    secondaryLabel: secondaryCtaLabel,
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onCta = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: `${ANALYTICS_MODAL_NAME}:AssignNow`,
      productList: analyticsProductList,
    });

    // when removing bug_fix_sqa_workaround please move setIsAssignUsersOpen(true) outisde the if block,
    // do not remove the call. We want to use react SQA by default when the issues are resolved.
    if (feature.isDisabled('bug_fix_sqa_workaround')) {
      // use src2 QuickAssignWrapper to load the Standalone QuickAssign mini app
      setIsAssignUsersOpen(true);
    }
    onOpenAssignLicensesModal?.();
    closeModal();
  };

  const onSecondary = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: `${ANALYTICS_MODAL_NAME}:NoThanks`,
      productList: analyticsProductList,
    });
    onOpenRenewalFlow();
    closeModal();
  };

  const onClose = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLOSE,
      eventName: `${ANALYTICS_MODAL_NAME}:Close`,
      productList: analyticsProductList,
    });
    closeModal();
  };

  return (
    <>
      {isModalOpen && (
        <ModalContainer>
          <ModalDialog
            content={content}
            dataTestId="unassigned-licenses-summary-modal"
            descriptionStyle={CUSTOM_DESCRIPTION_STYLE}
            dialogStyle={CUSTOM_DIALOG_STYLE}
            id={modalId}
            onCancel={onClose}
            onCta={onCta}
            onSecondary={onSecondary}
            size="M"
          >
            <ModalHeading level={2}>
              <FormattedMessage id="account.unassignedLicensesSummary.title" />
            </ModalHeading>
            <ModalDescription />
            <ModalContent>
              <FormattedMessage id="account.unassignedLicensesSummary.description" />
              <ProductAssignedLicensesSummary productList={productList.items} />
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
      )}
      {/* QuickAssignModalWrapper usage is currently disabled with bug_fix_sqa_workaround flag
       * we are pending fixes in SQA before re-enabling */}
      {isAssignUsersOpen && <QuickAssignModalWrapper onClose={() => setIsAssignUsersOpen(false)} />}
    </>
  );
};

UnassignedLicensesSummaryModal.propTypes = {
  /**
   * Translated Label for the CTA button. Defaults to 'account.unassignedLicensesSummary.button.cta'
   */
  ctaLabel: PropTypes.string,
  /**
   * Whether the modal dialog is shown or hidden. By default is hidden.
   */
  isOpen: PropTypes.bool,
  /**
   * Handler that is called when the user confirms they wish to open the assign licenses flow, cta.
   */
  onOpenAssignLicensesModal: PropTypes.func,
  /**
   * Handler that is called when the user confirms they wish to open the renewal flow, secondary.
   */
  onOpenRenewalFlow: PropTypes.func.isRequired,
  /**
   * List of products to that will be displayed in ProductAssignedLicensesSummary component
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * Translated Label for the secondary button. Defaults to 'account.unassignedLicensesSummary.button.secondary`
   */
  secondaryLabel: PropTypes.string,
};

export default UnassignedLicensesSummaryModal;
