import {ActionButton, Flex, Item, Menu, MenuTrigger, Text} from '@adobe/react-spectrum';
import Add from '@spectrum-icons/workflow/Add';
import Edit from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {ALL_ADDONS} from 'features/packages/packagesConstants';

import styles from './AddOns.pcss';
import AddOnsSummary from './add-ons-summary/AddOnsSummary';

// AddOns component gives functionality to select add-ons if there are any available for a product.
const AddOnsMenu = (props) => {
  const {productKey, productAddOns = [], preSelectedAddOns, setSelectedProductAddOnsMap} = props;
  const intl = useIntl();

  const addOnsList = productAddOns.map((productAddOn) => ({
    displayName: productAddOn.displayName,
    id: productAddOn.id,
  }));

  const [addOnsSelectedMap, setAddOnsSelectedMap] = useState({});

  const [addOnsSelectedList, setAddOnsSelectedList] = useState([]);

  // Populate addOnsSelectedMap and when selectedProductAddOnsMap is already populated
  // in packageCreateObj, show pre-selected addOns
  useEffect(() => {
    const tempAddOnsSelectedMap = {};

    productAddOns.forEach(({id}) => {
      tempAddOnsSelectedMap[id] = false;
    });

    const preSelectedProdAddOns = preSelectedAddOns[productKey];

    if (preSelectedProdAddOns?.length > 0) {
      preSelectedProdAddOns.forEach(({id}) => {
        tempAddOnsSelectedMap[id] = true;
      });
      tempAddOnsSelectedMap[ALL_ADDONS] = preSelectedProdAddOns.length === productAddOns.length;
    }

    const selectedAddOnsList = addOnsList.filter((addOn) => tempAddOnsSelectedMap[addOn.id]);

    setAddOnsSelectedMap(tempAddOnsSelectedMap);
    setAddOnsSelectedList(selectedAddOnsList);
    setSelectedProductAddOnsMap(productKey, selectedAddOnsList);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- show pre-selected addOns only when component mounts
  }, []);

  function onAddOnClick(addOnId) {
    let selectedAddOnsList = addOnsList;
    let addOnsMap = {};
    if (addOnId === ALL_ADDONS) {
      addOnsMap[ALL_ADDONS] = !addOnsSelectedMap[ALL_ADDONS];
      // If all add-ons option deselected, all options in menu should get deselected
      if (!addOnsMap[ALL_ADDONS]) {
        selectedAddOnsList = [];
      }

      // Set all add-ons options selection based on ALL_ADDONS selection status
      Object.keys(addOnsSelectedMap).forEach((productAddOnId) => {
        addOnsMap[productAddOnId] = addOnsMap[ALL_ADDONS];
      });
    } else {
      selectedAddOnsList = addOnsList.filter(({id}) =>
        id === addOnId ? !addOnsSelectedMap[addOnId] : addOnsSelectedMap[id]
      );
      addOnsMap = {...addOnsSelectedMap, [addOnId]: !addOnsSelectedMap[addOnId]};
    }
    // Set all add-ons option selection to true, if all add-ons have been selected
    addOnsMap[ALL_ADDONS] = selectedAddOnsList.length === addOnsList.length;

    setAddOnsSelectedMap(addOnsMap);
    setAddOnsSelectedList(selectedAddOnsList);
    setSelectedProductAddOnsMap(productKey, selectedAddOnsList);
  }

  return (
    <Flex data-testid="add-ons-container" direction="column" marginStart="size-50">
      <MenuTrigger closeOnSelect={addOnsList.length === 1}>
        <ActionButton
          data-testid="add-ons-button"
          height="size-300"
          isDisabled={addOnsList.length === 0}
          maxWidth="size-2000"
          UNSAFE_style={{width: 'fit-content'}}
        >
          {addOnsSelectedList.length > 0 ? (
            <Edit height="size-130" width="size-130" />
          ) : (
            <Add height="size-130" width="size-130" />
          )}
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for button text*/}
          <Text UNSAFE_className={styles['add-ons-add-button-text']}>
            {addOnsSelectedList.length > 0
              ? intl.formatMessage({id: 'packages.createPackage.addOnsMenu.editButtonLabel'})
              : intl.formatMessage({id: 'packages.createPackage.addOnsMenu.addButtonLabel'})}
          </Text>
        </ActionButton>
        <div className={styles['add-ons-menu']}>
          <Menu
            id="add-ons-menu"
            onAction={onAddOnClick}
            selectedKeys={Object.keys(addOnsSelectedMap).filter(
              (addOnId) => addOnsSelectedMap[addOnId]
            )}
            selectionMode="multiple"
          >
            {addOnsList.length > 1 && (
              <Item key={ALL_ADDONS}>
                {intl.formatMessage({id: 'packages.createPackage.addOnsMenu.allAddOnsLabel'})}
              </Item>
            )}
            {addOnsList.map((addOn) => (
              <Item key={addOn.id}>{addOn.displayName}</Item>
            ))}
          </Menu>
        </div>
      </MenuTrigger>
      {addOnsSelectedList.length > 0 && (
        <AddOnsSummary selectedAddOnsList={addOnsSelectedList} showHeading={false} />
      )}
    </Flex>
  );
};

AddOnsMenu.propTypes = {
  /**
   * Map of preselected add-ons for product keys.
   */
  preSelectedAddOns: PropTypes.instanceOf(Object).isRequired,
  /**
   * Array of objects contaning information related to add-ons for a product. Defaults to [].
   */
  productAddOns: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  /**
   * String representing the product name.
   */
  productKey: PropTypes.string.isRequired,
  /**
   * Function to set selectedProductAddOnsMap in package create object for a product.
   */
  setSelectedProductAddOnsMap: PropTypes.func.isRequired,
};

export default AddOnsMenu;
