const STORAGE_QUOTA_CONSTANTS = {
  CACHE_ID: 'StorageQuota',
  TYPE: {
    HARD: 'hard',
    SOFT: 'soft',
  },
  UNIT: {
    BYTES: 'BYTES',
    GIGABYTES: 'GIGABYTES',
    KILOBYTES: 'KILOBYTES',
    MEGABYTES: 'MEGABYTES',
    TERABYTES: 'TERABYTES',
  },
};
export default STORAGE_QUOTA_CONSTANTS;
