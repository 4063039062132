(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:analyticsPageToLaunchTranslator
   * @description Translate AnalyticsPageDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('analyticsPageToLaunchTranslator', factory);

  /* @ngInject */
  function factory($location, _, AEP_ANALYTICS_SITE_SECTION, locale) {
    return {processDescriptor};

    function processDescriptor(launchContext, analyticsPageDescriptor) {
      const {launchConfig, setProperty} = launchContext;
      const pageName = _.get(analyticsPageDescriptor, 'name');
      // Don't auto-delete page because it's required for upcoming events.
      // Also, prefer setting individual property because AC doesn't own 'page' object
      setProperty('page.autoTrack', false, false);
      setProperty('page.env', launchConfig.env, false);
      setProperty('page.language', locale.getCoralLocale(), false);
      const host = $location.host();
      setProperty('page.customPageName', `${host}:${pageName}`, false);
      launchContext.triggerStateChange(pageName);
      // 'siteSection' must be hard-coded otherwise Launch will ignore the 'state' change
      setProperty('page.siteSection', AEP_ANALYTICS_SITE_SECTION, false);
      setProperty('page.solution.name', 'aac', false);
    }
  }
})();
