/**
 * Defines the asset migration page under the settings tab.
 */
import {MIGRATION_POLICY_NAMES, MIGRATION_POLICY_VALUES, log} from '@admin-tribe/binky';
import {
  Subpage,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/binky-ui';
import {Switch, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {useId} from '@react-aria/utils';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import useMigrationPolicy from 'features/settings/hooks/use-migration-policy/useMigrationPolicy';

const AssetMigrationSubpage = () => {
  const intl = useIntl();
  const descriptionId = useId();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const orgId = rootStore.organizationStore.activeOrgId;
  // Retrieve organization's current policy
  const {
    error: errorLoadingPolicy,
    isLoading,
    policy,
  } = useMigrationPolicy({name: MIGRATION_POLICY_NAMES.STUDENT_ASSET, orgId});

  /**
   * Rerender the switch (or trigger an error) when the collaboration policies are loaded
   */
  useEffect(() => {
    if (policy) {
      setIsEnabled(policy.value === MIGRATION_POLICY_VALUES.ON);
    }
    if (errorLoadingPolicy) {
      log.error(`Failed to load asset migration policy due to error: ${errorLoadingPolicy}`);
    }
  }, [policy, errorLoadingPolicy]);

  async function onChange(value) {
    setIsSaving(true);
    try {
      policy.value = value ? MIGRATION_POLICY_VALUES.ON : MIGRATION_POLICY_VALUES.OFF;
      await policy.save();
      setIsEnabled(value);
      showSuccessToast();
    } catch (error) {
      log.error(`Failed to write asset migration policy due to error: ${error}`);
      showErrorToast();
    }
    setIsSaving(false);
  }

  return (
    <Subpage
      data-testid="asset-settings-migration-subpage"
      isBumpered={!!errorLoadingPolicy}
      isLoading={isLoading || isSaving}
    >
      <View marginBottom="size-100">
        <Text data-testid="description" id={descriptionId}>
          {intl.formatMessage(
            {id: 'settings.assetSettings.migration.description'},
            {
              goUrl: (str) => <GoUrl name="aac_student_migration">{str}</GoUrl>,
            }
          )}
        </Text>
      </View>
      <View>
        <Switch
          aria-describedby={descriptionId}
          isDisabled={errorLoadingPolicy}
          isSelected={isEnabled}
          onChange={onChange}
        >
          <FormattedMessage
            id={`settings.assetSettings.migration.status.${isEnabled ? 'enabled' : 'disabled'}`}
          />
        </Switch>
      </View>
    </Subpage>
  );
};

export default AssetMigrationSubpage;
