(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name observability
   * @description Onesie-specific observability event listener
   */

  angular
    .module('app.core.observability')
    .factory('observabilityEventListener', observabilityEventListener);

  /* @ngInject */
  function observabilityEventListener($transitions, _, binkySrc2, feature) {
    const service = {
      configureEventListener,
    };

    return service;

    ////////////

    function configureEventListener() {
      const {observabilityMetrics, OBSERVABILITY_BUMPER_FEATURE_FLAG} =
        binkySrc2.services.observability;

      if (
        feature.isEnabled(OBSERVABILITY_BUMPER_FEATURE_FLAG) ||
        feature.isDisabled('temp_observability')
      ) {
        return;
      }

      $transitions.onBefore({}, () => {
        // During the 1st (ever) page load, this won't be triggered,
        // thus we rely on `$transitions.onSuccess` for capturing transition data.
        // We still need `$transitions.onBefore` to reset states.
        observabilityMetrics.onBeforeRouteChange();
      });

      $transitions.onSuccess({}, (transition) => {
        const observabilityRoute = getRouteData(transition);
        observabilityMetrics.onAfterRouteChange({
          observabilityRoute,
        });
      });
    }

    /**
     * @description Create ObservabilityRoute instance based on the $transition information
     * @param {Object} transition Transition data
     * @returns {ObservabilityRoute} ObservabilityRoute instance that can uniquely identify a route
     */
    function getRouteData(transition) {
      const routeName = transition.to().name;
      // Clone to ensure changes don't affect the actual routing, and use transition.params()
      // to ensure we always get the params because transition.targetState().params() is not reliable.
      const routeParams = _.clone(transition.params());
      delete routeParams['#']; // Exclude the '#' property, which may exist in some cases

      return new binkySrc2.services.observability.route.ObservabilityRoute({
        routeName,
        routeParams,
      });
    }
  }
})();
