import binkyUI, {Page, PageContent, PageHeader, Subpage} from '@admin-tribe/binky-ui';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';

const InsightsDashboardWithPicker = binkyUI.components.etlaUsageReport.InsightsDashboardWithPicker;

/** Component which displays the Insights dashboard */
const InsightsDashboardPage = () => {
  const intl = useIntl();
  const {
    contractErrorStatus,
    topErrorStatus,
    contractInfoList,
    hasContractError,
    hasTopError,
    initialReportData,
  } = useLoaderData();

  useDocumentTitle({key: 'insights.dashboard.documentTitle'});

  return (
    <Page data-testid="app-insight-dashboard">
      <PageHeader title={intl.formatMessage({id: 'insights.dashboard.title'})} />
      <PageContent>
        <Subpage>
          <InsightsDashboardWithPicker
            contractInfoList={contractInfoList}
            contractSpecificDisplayErrorScreen={hasContractError}
            contractSpecificErrorStatus={contractErrorStatus}
            initialReportData={initialReportData}
            orgId={rootStore.organizationStore.activeOrgId}
            topDisplayErrorScreen={hasTopError}
            topErrorStatus={topErrorStatus}
          />
        </Subpage>
      </PageContent>
    </Page>
  );
};

InsightsDashboardPage.displayName = 'InsightsDashboardPage';

export default InsightsDashboardPage;
