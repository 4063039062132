import {DialogContainer, Flex, Switch} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import NotifyAdminsConfirmationModal from 'features/settings/common/components/notify-admins-confirmation-modal/NotifyAdminsConfirmationModal';

const NotifyAdminsSwitchButton = ({trustee}) => {
  const [showNotifyAdminsConfirmationModal, setShowNotifyAdminsConfirmationModal] = useState(false);
  const canManageIdentityConfig = canChangeIdentityConfig();

  return (
    <>
      <Flex alignItems="center">
        <Switch
          isDisabled={!canManageIdentityConfig}
          isEmphasized
          isSelected={trustee.notifyAdmins}
          onChange={() => {
            setShowNotifyAdminsConfirmationModal(true);
          }}
        >
          <FormattedMessage
            id={
              trustee.notifyAdmins
                ? 'settings.trusteeTable.actions.switch.On'
                : 'settings.trusteeTable.actions.switch.Off'
            }
          />
        </Switch>
        {!canManageIdentityConfig && <GlobalAdminPolicyActionDisabled />}
      </Flex>
      <DialogContainer onDismiss={() => setShowNotifyAdminsConfirmationModal(false)}>
        {showNotifyAdminsConfirmationModal && <NotifyAdminsConfirmationModal trustee={trustee} />}
      </DialogContainer>
    </>
  );
};

NotifyAdminsSwitchButton.propTypes = {
  trustee: PropTypes.shape({
    /**
     * The ID of the directory
     */
    directoryId: PropTypes.string,
    /**
     * The name of the directory
     */
    directoryName: PropTypes.string,
    /**
     * Denotes if the admin should be notified via email
     * when new users are created by administrators
     * in this trustee organization.
     */
    notifyAdmins: PropTypes.bool,
    /**
     * The ID of the Trustee Org
     */
    trusteeOrgId: PropTypes.string,
    /**
     * The name of the Trustee Org
     */
    trusteeOrgName: PropTypes.string,
    /**
     * The email of the org admin
     */
    trusteeOrgSysAdminEmail: PropTypes.string,
  }),
};

NotifyAdminsSwitchButton.displayName = 'NotifyAdminsSwitchButton';

export default NotifyAdminsSwitchButton;
