(function () {
  /**
   * @ngdoc module
   * @name app.core.organizations.user
   * @description defines organization user models
   */
  angular.module('app.core.organizations.user', [
    'app.core.model-cache',
    'app.core.product.configurations',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.core.storage-utils',
    'ngResource',
  ]);
})();
