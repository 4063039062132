import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const RequiresConfigurationConfirmModal = ({onCancel, onConfirmLeave}) => {
  const intl = useIntl();

  return (
    <ModalAlertDialog
      onCancel={onCancel}
      onPrimaryAction={onConfirmLeave}
      primaryActionLabel={intl.formatMessage({
        id: 'products.productProfilePermissions.editPermissionsConfirmationModal.leave',
      })}
      title={intl.formatMessage({
        id: 'products.productProfilePermissions.editPermissionsConfirmationModal.title',
      })}
      variant="warning"
    >
      {intl.formatMessage({
        id: 'products.productProfilePermissions.editPermissionsConfirmationModal.content',
      })}
    </ModalAlertDialog>
  );
};

RequiresConfigurationConfirmModal.propTypes = {
  /**
   * The callback function to run when the confirmation is cancelled, meaning the user does not want to leave.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * The callback function to run when the confirmation is confirmed, meaning the user wants to leave.
   */
  onConfirmLeave: PropTypes.func.isRequired,
};

export default RequiresConfigurationConfirmModal;
