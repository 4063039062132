const DOMAIN_REGEX =
  /^[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+(\.[^\s!#$%&'()*+,/:;<=>?@[\\\]^_`|~]+)+$/;
const DOMAIN_MAX_LEN = 255;
const DOMAIN_PART_MAX_LEN = 63;

/**
 * Validates a domain name by full length and parts length.
 *
 * @param {String} domainName
 * @return {boolean}
 */
function isValidDomainName(domainName) {
  return !!(
    DOMAIN_REGEX.test(domainName) &&
    domainName.length > 0 &&
    domainName.length <= DOMAIN_MAX_LEN &&
    domainName.split('.').every((part) => part.length > 0 && part.length <= DOMAIN_PART_MAX_LEN)
  );
}

/**
 * Gets an array of domains from a string list separated by comma.
 * Note that the values are trimmed.
 *
 * @param {String} value A domain list string separated by comma.
 *
 * @return {Array<String>} The domains in array form
 */
function parseDomains(value) {
  if (!value) {
    return [];
  }

  return value.split(',').map((domain) => domain.trim());
}

/**
 * Validates a list of domains.
 *
 * @param {Array<string>} domains The domains to be validated.
 *
 * @return {boolean} true if all are valid, false otherwise
 */
function validateDomains(domains) {
  return domains.every(isValidDomainName);
}

export {DOMAIN_REGEX, isValidDomainName, parseDomains, validateDomains};
