import {Flex, Heading, Switch, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {PACKAGE_TYPE_CONSTANTS} from 'features/packages/packagesConstants';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import ManagementOptionsPage from './ManagementOptionsPage';
import {showManagementOptions, showManagementOptionsSwitch} from './ManagementOptionsPageUtils';

const ManagementOptionsPageWrapper = observer(() => {
  const intl = useIntl();

  const {createPackageModalStore} = useCreatePackageModalContext();
  const {pkgSessionSettings} = createPackageModalStore;

  const [isManagementOptionsSwitchSelected, setIsManagementOptionsSwitchSelected] = useState(
    pkgSessionSettings.isManagementOptionsSwitchSelected
  );

  function handleShowManagementOptionToggle() {
    createPackageModalStore.setPkgSessionSettingValues(
      'isManagementOptionsSwitchSelected',
      !isManagementOptionsSwitchSelected
    );
    setIsManagementOptionsSwitchSelected(!isManagementOptionsSwitchSelected);
  }

  // Update isManagementOptionsSwitchSelected
  useEffect(() => {
    // If no plugins are selected, set isManagementOptionsSwitchSelected to false
    if (
      createPackageModalStore.pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
      createPackageModalStore.pkgSessionSettings.selectedPlugins.length === 0
    ) {
      createPackageModalStore.setPkgSessionSettingValues(
        'isManagementOptionsSwitchSelected',
        false
      );
    }
  }, [createPackageModalStore]);

  return (
    <View>
      {showManagementOptionsSwitch({
        packageType: createPackageModalStore.pkgSessionSettings.packageType,
        selectedPlugins: createPackageModalStore.pkgSessionSettings.selectedPlugins,
        selectedProducts: createPackageModalStore.selectedProducts,
      }) && (
        <Flex alignItems="center" direction="row">
          <Heading level={2} marginEnd="size-250">
            {intl.formatMessage({
              id: 'packages.createPackage.managementOptions.configurationOptions',
            })}
          </Heading>
          <Switch
            data-testid="management-options-toggle"
            isSelected={isManagementOptionsSwitchSelected}
            onChange={handleShowManagementOptionToggle}
          >
            {intl.formatMessage({
              id: 'packages.createPackage.managementOptions.enableManagementOptionsSwitch',
            })}
          </Switch>
        </Flex>
      )}
      {showManagementOptions({
        isManagementOptionsSwitchSelected,
        packageType: createPackageModalStore.pkgSessionSettings.packageType,
        selectedPlugins: createPackageModalStore.pkgSessionSettings.selectedPlugins,
        selectedProducts: createPackageModalStore.selectedProducts,
      }) && <ManagementOptionsPage />}
    </View>
  );
});

export default ManagementOptionsPageWrapper;
