import {Button, TextField} from '@adobe/react-spectrum';
import Alert from '@react/react-spectrum/Alert';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

import './ScimTokenInput.pcss';

const ScimTokenInput = observer(() => {
  const intl = useIntl();
  const [showToken, setShowToken] = useState(false);
  const directoryStoreContext = useDirectoryContext();
  const directorySyncStore = directoryStoreContext.directoryStore.sync;

  const toggleToken = () => {
    setShowToken(!showToken);
  };

  // load the scim token for the current auth source
  useEffect(() => {
    directorySyncStore.loadScimToken();
  }, [directorySyncStore]);

  if (directorySyncStore.hasError && !directorySyncStore.scimToken) {
    return (
      <Alert
        closeLabel={intl.formatMessage({
          id: 'settings.AzureScimConfigurationSteps.scimTokenErrorButtonLabel',
        })}
        header={intl.formatMessage({
          id: 'settings.AzureScimConfigurationSteps.scimTokenErrorHeader',
        })}
        onClose={() => directorySyncStore.loadScimToken()}
        variant="error"
      >
        {intl.formatMessage({id: 'settings.AzureScimConfigurationSteps.scimTokenErrorBody'})}
      </Alert>
    );
  }

  return (
    <div styleName="scim-token">
      <TextField
        data-testid="scim-token-field"
        description={intl.formatMessage({
          id: 'settings.AzureScimConfigurationSteps.scimTokenNote',
        })}
        id="scim-token-field"
        isDisabled
        label={intl.formatMessage({
          id: 'settings.AzureScimConfigurationSteps.scimTokenLabel',
        })}
        marginEnd="10px"
        type={showToken ? 'text' : 'password'}
        value={directorySyncStore.scimToken}
        width="100%"
      />
      <CopyToClipboardButton data-testid="scim-token-copy-btn" value={directorySyncStore.scimToken}>
        <FormattedMessage id="settings.AzureScimConfigurationSteps.copyToClipboard" />
      </CopyToClipboardButton>
      <Button data-testid="scim-token-toggle-btn" isQuiet onPress={toggleToken} variant="primary">
        {intl.formatMessage({
          id: showToken
            ? 'settings.AzureScimConfigurationSteps.hide'
            : 'settings.AzureScimConfigurationSteps.show',
        })}
      </Button>
    </div>
  );
});

export default ScimTokenInput;
