import {useCallback} from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';

import {
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_LIBRARIES,
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_REPORTS,
  PATH_STORAGE_REPORTS_SHARED,
  PATH_STORAGE_REPORTS_USERS,
  PATH_STORAGE_ROOT,
  PATH_STORAGE_SHARED_PROJECTS,
} from '../routing/storagePaths';

/** Hook which returns methods with generated Storage pathnames */
const useStoragePaths = () => {
  const individualUserFoldersPath = useCallback(
    () =>
      generatePath(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const librariesPath = useCallback(
    () => generatePath(PATH_STORAGE_LIBRARIES, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const overviewPath = useCallback(
    () => generatePath(PATH_STORAGE_OVERVIEW, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const reportsPath = useCallback(
    () => generatePath(PATH_STORAGE_REPORTS, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const rootPath = useCallback(
    () => generatePath(PATH_STORAGE_ROOT, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const sharedProjectsPath = useCallback(
    () =>
      generatePath(PATH_STORAGE_SHARED_PROJECTS, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const sharedReportsPath = useCallback(
    () =>
      generatePath(PATH_STORAGE_REPORTS_SHARED, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const userReportsPath = useCallback(
    () =>
      generatePath(PATH_STORAGE_REPORTS_USERS, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  return {
    individualUserFoldersPath,
    librariesPath, // remove with temp_storage_shared_projects
    overviewPath,
    reportsPath,
    rootPath,
    sharedProjectsPath,
    sharedReportsPath,
    userReportsPath,
  };
};

export default useStoragePaths;
