(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.api.stock').provider('stock', stockProvider);

  /* @ngInject */
  function stockProvider() {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(stockServiceConfig) {
      url = stockServiceConfig ? stockServiceConfig.url : '';
    }

    ////////////

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        productQuantities: getProductQuantities(),
      };

      return service;

      ////////////

      function getProductQuantities() {
        return $resource(`${url}/organization-usage/:orgId/product-quantities/:id`, {
          id: '@id',
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
