import {OrganizationEncryption} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the kmi api to fetch the encryption information
 * associated with an organization
 */
const useOrganizationEncryption = ({orgId}) => {
  const loadOrganizationEncryptionStatus = useCallback(async () => {
    const orgEncryptionStatus = await OrganizationEncryption.get({orgId});
    return orgEncryptionStatus;
  }, [orgId]);

  const {model, isLoading, error} = useAsyncModel({
    initState: [],
    loadFn: loadOrganizationEncryptionStatus,
  });

  return {encryptionInfo: model, error, isLoading};
};

export default useOrganizationEncryption;
