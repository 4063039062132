(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.api-organization')
    .factory('ApiOrganizationList', getApiOrganizationListModel);

  /* @ngInject */
  function getApiOrganizationListModel(
    _,
    adobeioConsoleOrganizations,
    API_ORGANIZATION_LIST_MODEL,
    apiUtils,
    modelCache
  ) {
    class ApiOrganizationList {
      constructor() {
        this.items = [];
      }

      getActiveAdobeioOrgId() {
        return _.chain(this.items)
          .find({code: apiUtils.getActiveOrg()})
          .get('id', undefined)
          .value();
      }

      refresh() {
        this.$resolved = false;
        this.$promise = adobeioConsoleOrganizations.organizations
          .query()
          .$promise.then(onOrganizationsFetchSuccess.bind(this))
          .finally(() => {
            this.$resolved = true;
          });
        return this.$promise;

        function onOrganizationsFetchSuccess(response) {
          const organizations = response;
          _.assignIn(this, {items: _.map(organizations, toMinimumObject)});
          modelCache.put(
            API_ORGANIZATION_LIST_MODEL.API_ORGANIZATION_LIST_CACHE_ID,
            this,
            API_ORGANIZATION_LIST_MODEL.API_ORGANIZATION_LIST_KEY
          );
          return this;
        }
      }

      static get() {
        let model = modelCache.get(
          API_ORGANIZATION_LIST_MODEL.API_ORGANIZATION_LIST_CACHE_ID,
          API_ORGANIZATION_LIST_MODEL.API_ORGANIZATION_LIST_KEY
        );
        if (!model) {
          model = new ApiOrganizationList();
          model.refresh();
        }
        return model;
      }
    }
    modelCache.register(API_ORGANIZATION_LIST_MODEL.API_ORGANIZATION_LIST_CACHE_ID, 1);
    return ApiOrganizationList;

    function toMinimumObject(object) {
      return _.pick(object, ['id', 'code']);
    }
  }
})();
