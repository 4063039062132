import {Flex, Text, TextField} from '@adobe/react-spectrum';
import {DividerPlacement} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PackagesSection from '../../packages-section/PackagesSection';
import {isMacPathValid, isWinPathValid} from '../preferencesPageUtils';

const InstallDirectorySection = ({paths, setPaths}) => {
  const intl = useIntl();

  const isValidPath = (isValid, path) => {
    if (!path || path.length === 0) {
      return undefined;
    }
    return isValid(path) ? 'valid' : 'invalid';
  };

  return (
    <PackagesSection
      dividerPlacement={DividerPlacement.TOP}
      headingId="packages-install-directory-heading"
      title={intl.formatMessage({id: 'packages.preferences.installDirectory.title'})}
    >
      <Flex direction="column" gap="size-200" marginTop="size-100">
        <Text>{intl.formatMessage({id: 'packages.preferences.installDirectory.description'})}</Text>
        <TextField
          aria-multiline
          data-testid="install-directory-win-textfield"
          label={intl.formatMessage({id: 'packages.preferences.installDirectory.labelWin'})}
          maxLength={255}
          onChange={(value) => {
            setPaths({...paths, userInstallDirectoryWin: value});
          }}
          validationState={isValidPath(isWinPathValid, paths.userInstallDirectoryWin)}
          value={paths.userInstallDirectoryWin}
          width="auto"
        />
        <TextField
          aria-multiline
          data-testid="install-directory-mac-textfield"
          label={intl.formatMessage({id: 'packages.preferences.installDirectory.labelMac'})}
          maxLength={1024}
          onChange={(value) => {
            setPaths({...paths, userInstallDirectoryMac: value});
          }}
          validationState={isValidPath(isMacPathValid, paths.userInstallDirectoryMac)}
          value={paths.userInstallDirectoryMac}
          width="auto"
        />
      </Flex>
    </PackagesSection>
  );
};

InstallDirectorySection.propTypes = {
  /**
   * Paths object
   */
  paths: PropTypes.shape({
    /**
     * Install path for mac
     */
    userInstallDirectoryMac: PropTypes.string,
    /**
     * Install path for windows
     */
    userInstallDirectoryWin: PropTypes.string,
  }).isRequired,

  /**
   * Function to be called with updated paths object when preferences.userInstallDirectoryMac and
   * preferences.userInstallDirectoryWin values are to be modified.
   */
  setPaths: PropTypes.func.isRequired,
};

export default InstallDirectorySection;
