(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.integrations')
    .constant(
      'INTEGRATION_DETAILS_DRAWER_ADD_USER_MODAL_ID',
      'integration-details-drawer-add-user-modal'
    )
    .constant('INTEGRATION_DETAILS_DRAWER_ID', 'integration-details-drawer');
})();
