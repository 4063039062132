(function () {
  /**
   * @deprecated please use @pandora/react-product-assigned-licenses-summary instead
   */
  angular.module('app.widgets.users').component('appQuickAssignProductLicenseCountTable', {
    bindings: {
      productDataList: '<',
      productToLicenseCountMap: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/quick-assign-product-license-count-table/quick-assign-product-license-count-table.component.html',
  });

  function controller(_, $translate) {
    const vm = this;
    _.assign(vm, {
      getAssignmentCount,
      getLicenseCountString,
      getTranslationForTrialProduct,
    });

    function getLicenseCountString(product) {
      return $translate.instant(
        'widgets.users.quickAssignModal2.productLicenseCountTable.licenseCounts',
        {
          licensesAssigned: getAssignmentCount(product.id),
          licensesAvailable: product.availableLicenses,
        },
        'messageformat'
      );
    }

    function getAssignmentCount(productId) {
      return vm.productToLicenseCountMap[productId] ? vm.productToLicenseCountMap[productId] : 0;
    }

    function getTranslationForTrialProduct(name) {
      return $translate.instant(
        'widgets.users.quickAssignModal2.productLicenseCountTable.productNameWithTrial',
        {
          productName: name,
        },
        'messageformat'
      );
    }
  }
})();
