import {Heading} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './SupportTicketDiscussionHeader.pcss';

/**
 * A widget to display the Support Ticket Attachment Header.
 */
const SupportTicketDiscussionHeader = ({'data-testid': dataTestId}) => {
  const intl = useIntl();
  return (
    <Heading
      data-testid={dataTestId || 'discussion-section-header'}
      level={3}
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to add styles to heading
      UNSAFE_className={styles['heading-3']}
    >
      {intl.formatMessage({id: 'support.supportTicketDiscussionSection.title'})}
    </Heading>
  );
};

SupportTicketDiscussionHeader.propTypes = {
  /**
   * data-testid is used as identifier of the component.
   * The default is discussion-section-header.
   */
  'data-testid': PropTypes.string,
};

export default SupportTicketDiscussionHeader;
