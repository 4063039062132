import {EditSecurityContactsModal} from '@admin-tribe/binky-ui';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to set security contacts
 */
class SecurityContactsHva extends HvaCard {
  /**
   * @description Method to fetch the SecurityContactsHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<SecurityContactsHva[]>} the fetched instance
   */
  static get({intl}) {
    if (
      auth.isUserOrgAdmin() &&
      !!rootStore.organizationStore.contactList &&
      typeof rootStore.organizationStore.contactList.isAllContactsComplete === 'function' &&
      !rootStore.organizationStore.contactList.isAllContactsComplete()
    ) {
      return Promise.resolve([new SecurityContactsHva({intl})]);
    }

    return Promise.reject();
  }

  /**
   * @description Creates a new SecurityContactsHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {SecurityContactsHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.securityContacts';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: false,
      goUrl: 'aac_pop_artt_security_contacts',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/policies.svg',
        style: {width: '40px'},
      },
      id: 'hva-security-contacts',
      isDismissible: false,
      modalConfig: {
        closeProp: 'onClose',
        Component: EditSecurityContactsModal,
        includeModalContainer: true,
        props: {contactList: rootStore.organizationStore.contactList},
      },
      priorityOrder: HVA_ORDER.SET_UP_SECURITY_CONTACTS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default SecurityContactsHva;
