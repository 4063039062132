(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories.sync').component('appRemoveSyncedDomainModal', {
    bindings: {
      onCancel: '&',
      onConfirm: '&',
      partnerType: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/sync/remove-synced-domain-modal/remove-synced-domain-modal.component.html',
  });

  /* @ngInject */
  function controller(_, PARTNER_TYPES, REMOVE_SYNCED_DOMAIN_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onConfirmClicked,
      onOpen,
      REMOVE_SYNCED_DOMAIN_MODAL_ID,
    });

    function $onInit() {
      vm.accessLostKey =
        vm.partnerType === PARTNER_TYPES.AZURE
          ? 'widgets.directories.sync.removeSyncedDomainModal.accessLostAzure'
          : 'widgets.directories.sync.removeSyncedDomainModal.accessLostGoogle';

      vm.usersKey =
        vm.partnerType === PARTNER_TYPES.GSUITE
          ? 'widgets.directories.sync.removeSyncedDomainModal.usersNotRemovedFromGoogle'
          : 'widgets.directories.sync.removeSyncedDomainModal.usersNotRemovedFromAzure';
    }

    function onConfirmClicked() {
      vm.isConfirmed = true;
      vm.onConfirm({domain: vm.domain});
    }

    function onOpen(params) {
      vm.isConfirmed = false;
      vm.domain = params.domain;
    }
  }
})();
