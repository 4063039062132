(function () {
  /**
   * @ngdoc module
   * @name app.widgets.trustees
   * @description module that defines trustee components
   */
  angular.module('app.widgets.trustees', [
    'app.core.organizations',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.core.trusts',
    'binky.shell.panels.modal',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.display-message',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.toast',
    'binky.widgets.common.toggle',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
