import {EVENT_SOURCES, Store, eventBus, log} from '@admin-tribe/binky';
import {TableConstants} from '@admin-tribe/binky-ui';
import {PAGE_SIZE_OPTIONS, SELECTION_MODE} from '@pandora/react-table-section';
import cloneDeep from 'lodash/cloneDeep';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {CREATE_PACKAGE} from '../packagesConstants';
import PackagesAdminService from '../services/PackagesAdminService';

import {fetchDataHelper} from './AllPackagesStoreUtils';

// Domain data for All Packages page
class AllPackagesStore extends Store {
  adminPackageList = [];
  allPackagesFUEScreenState = false;
  clearSearch = false;
  clearSelection = false;
  filteredAdminPackages = [];
  isCreatePackageDisabled = false;
  loadingPlugins = false;
  searchInputValue = '';
  selectedPackages = [];
  tableStore = {
    currentPage: 1,
    getKey: (item) => item.packageId,
    pageSize: 10,
    pageSizeOptions: PAGE_SIZE_OPTIONS.DEFAULT,
    rows: [],
    searchQuery: '',
    selectionMode: SELECTION_MODE.MULTIPLE,
    totalItems: 0,
    totalPages: 0,
  };

  visiblePackagesList = [];

  constructor() {
    super();
    makeObservable(this, {
      allPackagesFUEScreenState: observable,
      clearSearch: observable,
      clearSelection: observable,
      fetchData: action,
      goToNextPage: action.bound,
      goToPageNumber: action.bound,
      goToPreviousPage: action.bound,
      handleEvents: action.bound,
      isCreatePackageDisabled: observable,
      loadingPlugins: observable,
      onPageSizeChange: action.bound,
      search: action,
      searchInputValue: observable,
      selectedPackages: observable,
      setSelectedPackages: action.bound,
      tableStore: observable,
    });
  }

  /**
   * @async
   * @description Function to remove selected packages
   */
  async deleteSelectedPackages(selectedPackages, isCancelOperation = false) {
    try {
      this.isLoading = true;
      await PackagesAdminService.deleteSelectedPackages(selectedPackages, isCancelOperation);
      this.refreshAdminPackages(true);
    } catch (error) {
      this.isLoading = false;
      log.error(`deleteSelectedPackages: Unable to delete packages:`, error);
    } finally {
      this.setSelectedPackages([]);
    }
  }

  /**
   * @async
   * @description This is the callback for Store.load(). If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    this.isLoading = true;
    await fetchDataHelper();
    await PackagesAdminService.fetchAndProcessAdminPackages();
    const adminPackages = await PackagesAdminService.getAllAdminPackages();
    this.adminPackageList = adminPackages;
    runInAction(() => {
      this.allPackagesFUEScreenState = PackagesAdminService.getAdminPackagesCount() === 0;
    });
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function to fetch plugins for visible packages list
   */
  async fetchPluginMetadataForVisiblePackages() {
    runInAction(() => {
      this.loadingPlugins = true;
    });
    try {
      const promises = [];
      this.visiblePackagesList.forEach((pkg) => {
        promises.push(pkg.fetchPluginsData());
      });
      await Promise.all(promises);
    } catch (error) {
      log.error(`fetchPluginMetadataForVisiblePackages: Unable to retrieve plugins data:`, error);
    } finally {
      runInAction(() => {
        this.tableStore.rows = cloneDeep(this.visiblePackagesList);
        this.loadingPlugins = false;
      });
    }
  }

  /**
   * @description Function to filter the packages list
   */
  filterAdminPackagesList(fetchPlugins = true) {
    // if search query not empty, then add packages to list based on the name/platform matching search query
    if (this.tableStore.searchQuery) {
      this.filteredAdminPackages = this.adminPackageList.filter((item) => {
        const lowerCaseName = item.name.toLocaleLowerCase();
        const lowerCasePlatform = item.platform.toLocaleLowerCase();
        const lowerCaseNpdId = item.npdId?.toLocaleLowerCase();
        const lowerCaseSearchText = this.tableStore.searchQuery.toLocaleLowerCase();
        return (
          lowerCaseName.includes(lowerCaseSearchText) ||
          lowerCasePlatform.includes(lowerCaseSearchText) ||
          lowerCaseNpdId?.includes(lowerCaseSearchText)
        );
      });
    } else {
      this.filteredAdminPackages = this.adminPackageList;
    }
    this.visiblePackagesList = this.filteredAdminPackages?.slice(
      (this.tableStore.currentPage - 1) * this.tableStore.pageSize,
      this.tableStore.currentPage * this.tableStore.pageSize
    );
    runInAction(() => {
      this.tableStore.totalPages = Math.ceil(
        this.filteredAdminPackages.length / this.tableStore.pageSize
      );
      this.tableStore.totalItems = this.filteredAdminPackages.length;
      this.tableStore.rows = cloneDeep(this.visiblePackagesList);
      this.isLoading = false;
    });
    if (fetchPlugins) {
      this.fetchPluginMetadataForVisiblePackages();
    }
  }

  /**
   * @description Function to find package by packageId
   * @param {string} packageId
   * @returns {Object} admin package
   */
  getItem(key) {
    return this.adminPackageList?.find((item) => item.packageId === key);
  }

  /**
   * @description Function to jump to the next page in the table
   */
  goToNextPage() {
    runInAction(() => {
      this.updatesOnPageChange();
    });
    this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  /**
   * @description Function to Sets current page number and updates the list accordingly
   */
  goToPageNumber(newPageNumber) {
    this.tableStore.currentPage = newPageNumber;
    this.filterAdminPackagesList();
  }

  /**
   * @description Function to jump to the previous page in the table
   */
  goToPreviousPage() {
    runInAction(() => {
      this.updatesOnPageChange();
    });
    this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  /**
   * @description Function to handle package creation events
   */
  handleEvents(eventName, payload) {
    if (eventName === CREATE_PACKAGE.EVENTS.PROGRESS_STATUS) {
      const adminPackage = payload.adminPackage;
      const pkgIndex = this.adminPackageList?.findIndex(
        (pkg) => pkg.packageId === adminPackage.packageId
      );
      if (pkgIndex > -1) {
        this.adminPackageList[pkgIndex] = adminPackage;
        this.filterAdminPackagesList(false);
      } else {
        this.refreshAdminPackages(true);
      }
    } else if (eventName === CREATE_PACKAGE.EVENTS.PACKAGE_REQUESTED) {
      this.isLoading = true;
    } else if (eventName === CREATE_PACKAGE.EVENTS.NEW_PACKAGE_CREATED) {
      runInAction(() => {
        this.clearSelection = true;
        this.selectedPackages = [];
        this.tableStore.searchQuery = null;
        this.clearSearch = true;
      });
      this.refreshAdminPackages(true);
    } else if (eventName === CREATE_PACKAGE.EVENTS.IS_BUILD_DISABLED) {
      runInAction(() => {
        this.isCreatePackageDisabled = payload.isBuildDisabled;
      });
    }
  }

  /**
   * @description Function to register event listener to get create package progress data
   */
  invokeEventListener() {
    eventBus.registerEventHandler(this.handleEvents, EVENT_SOURCES.SRC2);
  }

  /**
   * @description Function to change the number of records per page
   */
  onPageSizeChange(pageSize) {
    runInAction(() => {
      this.updatesOnPageChange();
      this.tableStore.pageSize = pageSize;
    });
    // Reset page back to the first page and filter the list
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function to fetch latest package list
   */
  async refreshAdminPackages(isForced) {
    try {
      await PackagesAdminService.fetchAndProcessAdminPackages(isForced);
      const adminPackages = PackagesAdminService.getAllAdminPackages();
      this.adminPackageList = adminPackages;
      runInAction(() => {
        this.allPackagesFUEScreenState = PackagesAdminService.getAdminPackagesCount() === 0;
      });
    } catch (error) {
      log.error(`refreshAdminPackages: Unable to retrieve AdminPackages:`, error);
      this.hasLoadingError = true;
    } finally {
      this.goToPageNumber(TableConstants.FIRST_PAGE);
    }
  }

  resetClearSearch() {
    runInAction(() => {
      this.clearSearch = false;
    });
  }

  resetClearSelection() {
    runInAction(() => {
      this.clearSelection = false;
    });
  }

  async retryBuildingPackage(packageId) {
    this.isLoading = true;
    const adminPackage = this.adminPackageList.find((pkg) => pkg.packageId === packageId);
    await adminPackage.retryBuildingPackage();
    this.refreshAdminPackages(true);
  }

  /**
   * @description Function to deregister event listener
   */
  revokeEventListener() {
    eventBus.deregisterEventHandler(this.handleEvents, EVENT_SOURCES.SRC2);
  }

  /**
   * @description Function to search admin packages
   * @param {string} value search value
   */
  search(value) {
    runInAction(() => {
      this.tableStore.searchQuery = value;
      this.selectedPackages = [];
      this.clearSelection = true;
    });
    // Reset page back to the first page and filter the list based on search query
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function to set selected admin package
   */
  setSelectedPackages(packageIds) {
    this.selectedPackages = packageIds ? packageIds.map((pkg) => this.getItem(pkg)) : [];
  }

  /**
   * @description Function to update selectedPackages, clearSelection and isLoading varaibles
   */
  updatesOnPageChange() {
    this.selectedPackages = [];
    this.clearSelection = true;
    this.isLoading = true;
  }
}

export default AllPackagesStore;
