(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.tag:appTabview
   * @description A wraper component for coral-tabview
   *
   * @example <app-tabview>
   *            <tablist>
   *              <coral-tablist>
   *                <coral-tab><coral-tab-label><span translate>{{ key }}</span></coral-tab-label></coral-tab>
   *                <coral-tab ng-disabled="condition" ng-show="condition"><coral-tab-label><span translate>{{ key }}</span></coral-tab-label></coral-tab>
   *              </coral-tablist>
   *            </tablist>
   *            <panelstack>
   *              <coral-panelstack id="add-user-panel">
   *                <coral-panel><coral-panel-content>
   *                  content
   *                </coral-panel-content></coral-panel>
   *                <coral-panel ng-if="condition"><coral-panel-content>
   *                  content
   *                </coral-panel-content></coral-panel>
   *              </coral-panelstack>
   *            </panelstack>
   *          </app-tabview>
   * NOTE:
   * 1. Make sure coral-tab, coral-tab-label, and the content inside stay in one line
   * 2. Make sure to use ng-show instead of ng-if on coral-tab
   */
  angular.module('app.widgets.tabview').component('appTabview', {
    controller,
    templateUrl: 'app/widgets/common/tabview/tabview.component.html',
    transclude: {
      panelstack: 'panelstack',
      tablist: 'tablist',
    },
  });

  function controller(_, $element) {
    const vm = this;

    _.assign(vm, {
      $postLink,
    });

    function $postLink() {
      Coral.commons.ready(() => {
        // Coral-Spectrum now supports nesting within TabViews and expects the provided panelstack and
        // tablist to either not have a parent or to already be the child of the Tabview
        // See: https://github.com/adobe/coral-spectrum/commit/6afe60177ad6c9f609421cac1f9e15c118feee0b
        const tabviewElements = {
          panelStack: $element[0]
            .querySelector('panelstack')
            .removeChild($element[0].querySelector('coral-panelstack')),
          tabList: $element[0]
            .querySelector('tablist')
            .removeChild($element[0].querySelector('coral-tablist')),
        };

        vm.tabview = new Coral.TabView();
        vm.tabview.set(tabviewElements);
        $element.append(vm.tabview);
      });
    }
  }
})();
