(function () {
  /**
   * @ngdoc module
   * @name app.widgets.products.purchase
   * @description module that defines products purchase widgets
   */
  angular.module('app.widgets.products.purchase', [
    'angularMoment',
    'app.core.analytics',
    'app.core.chat',
    'app.core.contract.access',
    'app.core.organizations.organization-manager',
    'app.core.payment-info-editor',
    'app.core.payment-instrument',
    'app.widgets.scorecard',
    'app.widgets.section-with-icon',
    'app.widgets.support',
    'app.widgets.tag',
    'binky.core.contract',
    'binky.core.locale',
    'binky.core.lodash',
    'binky.core.price',
    'binky.core.user.authenticated-user',
    'binky.ui-migration',
    'binky.widgets.commerce',
    'binky.widgets.common.button',
    'binky.widgets.common.date-time',
    'binky.widgets.common.events',
    'binky.widgets.common.go-url',
    'binky.widgets.common.icon-tooltip',
    'binky.widgets.common.number-input',
    'binky.widgets.common.search',
    'binky.widgets.products.product-icon',
    'pascalprecht.translate',
  ]);
})();
