import {PROFILE_PERMISSIONS_SUB_CONTENT_TYPE} from '@admin-tribe/binky';
import {PageBanner, PageBannerConstants} from '@admin-tribe/binky-ui';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import React from 'react';
import {useIntl} from 'react-intl';

import {subContentPropType} from '../prop-types/configurationGroupsPropTypes';

const getSizeInvalidMessage = (size, min, max, intl) => {
  if (min && size < min) {
    return intl.formatMessage({
      id: 'products.productProfilePermissions.editPermissionsModal.invalidBanner.insufficientValuesWarning',
    });
  }
  if (max && size > max) {
    return intl.formatMessage({
      id: 'products.productProfilePermissions.editPermissionsModal.invalidBanner.excessValuesWarning',
    });
  }
  return null;
};

const getIncludeInvalidMessage = (subContent, intl) => {
  const includedPermissionsCount = subContent.values.filter(
    (permission) => permission.included
  ).length;
  return getSizeInvalidMessage(
    includedPermissionsCount,
    subContent.minimumValueCount,
    subContent.maximumValueCount,
    intl
  );
};

const getTextInvalidMessage = (subContent, intl) => {
  const textLinesCount = subContent.values.length;
  const sizeMessage = getSizeInvalidMessage(
    textLinesCount,
    subContent.minimumValueCount,
    subContent.maximumValueCount,
    intl
  );
  if (sizeMessage) return sizeMessage;

  if (subContent.regex) {
    const regex = new RegExp(subContent.regex);
    const invalidLine = subContent.values.find((permission) => !regex.test(permission.text));
    if (invalidLine) {
      return intl.formatMessage(
        {
          id: 'products.productProfilePermissions.editPermissionsModal.invalidBanner.invalidTextValueWarning',
        },
        {textValue: invalidLine.text}
      );
    }
  }
  return null;
};

const InvalidSubContentBanner = ({subContent}) => {
  const intl = useIntl();

  return (
    <SpectrumV2Provider>
      <PageBanner
        header={intl.formatMessage({
          id: 'products.productProfilePermissions.editPermissionsModal.invalidBanner.header',
        })}
        variant={PageBannerConstants.variant.WARNING}
      >
        {subContent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.INCLUDE &&
          getIncludeInvalidMessage(subContent, intl)}
        {subContent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.TEXT_FORM &&
          getTextInvalidMessage(subContent, intl)}
      </PageBanner>
    </SpectrumV2Provider>
  );
};

InvalidSubContentBanner.propTypes = {
  subContent: subContentPropType.isRequired,
};

export default InvalidSubContentBanner;
