import {LicenseGroup} from '@admin-tribe/binky';
import {ASSIGNMENT_MENU_CONSTANTS, Avatar, GoUrl} from '@admin-tribe/binky-ui';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';
import {canAssignDeveloper} from 'core/products/access/productAccess';
import AssignmentModalBase from 'features/users/components/assignment-modal-base/AssignmentModalBase';

const {TARGET_TYPE} = ASSIGNMENT_MENU_CONSTANTS;

/**
 * The 'Edit API credentials' modal for the Products detail section which is
 * in the drawer on the 'API credentials' page.
 */
const EditApiCredentialsModal = ({isOpen, onCancel, onSuccess, user}) => {
  const intl = useIntl();
  const products = useRef();

  const assignItemsToUser = (assignedItems) => {
    user.products = cloneDeep(assignedItems[TARGET_TYPE.PRODUCT_PROFILES]);
  };

  const hasUnsavedChanges = (assignedItems) => {
    const productChanges = assignedItems[TARGET_TYPE.PRODUCT_PROFILES] || [];

    const userProfiles = user.products?.flatMap((p) => p.licenseGroups?.map((lg) => lg.id));
    const pendingProfileChanges = productChanges?.flatMap((p) =>
      p.licenseGroups?.map((lg) => lg.id)
    );

    return !isEqual(userProfiles, pendingProfileChanges);
  };

  // Assigned items is an Array<LicenseGroup> with one for each profile assigned.
  const onItemAssignment = (assignedItems) => {
    const changes = {};
    changes[TARGET_TYPE.PRODUCT_PROFILES] = [];

    assignedItems.forEach((item) => {
      const licenseGroup = cloneDeep(item);
      // remove licenseGroupSummaries to prevent stack overflow in angular
      delete licenseGroup.product.licenseGroupSummaries;

      const existingProduct = changes[TARGET_TYPE.PRODUCT_PROFILES].find(
        ({id}) => id === item.product.id
      );
      if (existingProduct) {
        existingProduct.licenseGroups.push(licenseGroup);
      } else {
        const product = cloneDeep(item.product);
        product.licenseGroups = [];
        // remove licenseGroupSummaries to prevent stack overflow in angular
        delete product.licenseGroupSummaries;

        product.licenseGroups.push(licenseGroup);
        changes[TARGET_TYPE.PRODUCT_PROFILES].push(product);
      }
    });

    return changes;
  };

  const mapLicenseGroupProduct = (licenseGroup, product) =>
    new LicenseGroup({
      ...licenseGroup,
      product: products.current.find((p) => p.id === product.id),
    });

  // These are the profiles that are selected when the modal is opened.
  const processDefaultItemsNew = () => {
    const mappedTargets = {};

    if (products.current) {
      const licenseGroups = [];
      user.products.forEach((product) => {
        licenseGroups.push(
          ...product.licenseGroups.map((licenseGroup) =>
            mapLicenseGroupProduct(licenseGroup, product)
          )
        );
      });
      mappedTargets[TARGET_TYPE.PRODUCT_PROFILES] = licenseGroups;
    } else {
      return undefined;
    }

    return mappedTargets;
  };

  const onProductListResolve = (productList) => {
    products.current = productList.items.filter((product) => canAssignDeveloper(product));
    return products.current;
  };

  return (
    <AssignmentModalBase
      assignedItemLocId="common.editAPICredentialsModal.assigned"
      assignItemsToUser={assignItemsToUser}
      description={intl.formatMessage(
        {id: 'common.editAPICredentialsModal.description'},
        {goUrl: (str) => <GoUrl name="aac_api_prod_learn">{str}</GoUrl>}
      )}
      hasUnsavedChanges={hasUnsavedChanges}
      header={intl.formatMessage({id: 'common.editAPICredentialsModal.header'})}
      id="edit-api-credentials-modal"
      isOpen={isOpen}
      onCancel={onCancel}
      onItemAssignment={onItemAssignment}
      onProductListResolve={onProductListResolve}
      onSuccess={onSuccess}
      orgId={rootStore.organizationStore.activeOrgId}
      processDefaultItemsNew={processDefaultItemsNew}
      TagHeaderIconComponent={<Avatar alt="" member={user} size="S" />}
      tagHeaderText={user.getDisplayName()}
      targets={[TARGET_TYPE.PRODUCT_PROFILES, TARGET_TYPE.PRODUCTS]}
      user={user}
    />
  );
};

EditApiCredentialsModal.propTypes = {
  /**
   * True if modal is open.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Callback when the modal is closed with no changes to the model.
   * There are no parameters.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback when the modal is closed with changes to the model.
   * There are no parameters.
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * The API credential user.
   */
  user: PropTypes.instanceOf(ApiIntegrationUser).isRequired,
};

export default EditApiCredentialsModal;
