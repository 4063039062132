import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';

const MODAL_ID = 'set-default-certificate-confirmation-modal';

const SetDefaultCertificateConfirmationModal = ({onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {
    state: {isLoading},
  } = useSetupCertificatesContext();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      id={MODAL_ID}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.certificates.setDefaultCertificateModal.setDefault',
      })}
      title={intl.formatMessage({
        id: 'settings.certificates.setDefaultCertificateModal.title',
      })}
      variant="confirmation"
    >
      <Text>
        {intl.formatMessage({
          id: 'settings.certificates.setDefaultCertificateModal.description',
        })}
      </Text>
    </ModalAlertDialog>
  );
};

SetDefaultCertificateConfirmationModal.propTypes = {
  certificate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onConfirm: PropTypes.func.isRequired,
};

export default SetDefaultCertificateConfirmationModal;
