(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories')
    .constant('APP_ADD_EDU_PROVIDER_MODAL_ID', 'add-edu-provider-modal')
    .constant('SOIDC_PROVIDERS', {
      BLACKBOARD: 'Blackboard',
      CANVAS: 'Canvas',
      CLASSLINK: 'Classlink',
      CLEVER: 'Clever',
      GOOGLE: 'google',
    });
})();
