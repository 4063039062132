(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.directories.azure')
    .constant('AZURE_GROUP_LINK_STATUS', {
      FREE: 'FREE',
      INVALID: 'INVALID',
      LINKED: 'LINKED',
    })
    .constant('AZURE_GROUP_INVALID_REASON', {
      NAME_CLASH: 'NAME_CLASH',
      NAME_CONTAINS_INVALID_CHARACTERS: 'NAME_CONTAINS_INVALID_CHARACTERS',
    });
})();
