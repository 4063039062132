/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.organization-type:appOrganizationTypeModal
 *
 * @description The modal is to set the organization type (marketSubsegments). Once it is set it can't be changed.
 *  In the sunny day case, if marketSubsegments hasn't been set yet, any free offers haven't been provisioned yet
 *  but this is not always the case.
 *  AAUI allows the marketSubsegments to be reset.
 *  Free offers may be provisioned by other means than OC.
 */
(function () {
  const componentName = 'appOrganizationTypeModal';
  angular.module('app.widgets.organization-type').component(componentName, {
    bindings: {
      modalId: '@?',
      onClosed: '<?',
      onInitialized: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/organizations/organization-type/organization-type-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    onesieSrc2,
    organizationTypeHelper,
    ORGANIZATION_TYPE_MODAL_ID,
    panelManager,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onConfirmAndContinue,
      onFormChange,
      onModalClose,
      onModalOpen,
    });

    function onConfirmAndContinue() {
      vm.isBusy = true;

      vm.waitPromise = organizationTypeHelper
        .updateOrgMarketSubsegments(vm.marketSubsegments, {
          hasAdditionalTermsToAccept: vm.hasAdditionalTerms,
        })
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          vm.isBusy = false;
        });

      ////////

      function onSuccess() {
        toastManager.showSuccessToast(
          $translate.instant('widgets.organizations.organizationType.toasts.success')
        );

        onesieSrc2.core.rootStore.organizationStore.globalBannerStore.dismissBannerWithId(
          onesieSrc2.core.organizations.organizationSettings.ORGANIZATION_TYPE_BANNER_ID
        );
        panelManager.close(vm.id);
      }

      function onError() {
        panelManager.close(vm.id);
        toastManager.showErrorToast();
      }
    }

    function onFormChange(event) {
      _.assign(vm, {
        hasAdditionalTerms: event.hasAdditionalTerms,
        isConfirmAndContinueEnabled: event.areRequiredFieldsFilled,
        marketSubsegments: event.marketSubsegments,
      });
    }

    function onModalClose() {
      _.invoke(vm, 'onClosed');
    }

    function onModalOpen() {
      triggerAnalyticsOnOpen();
      initialize();
    }

    ///////////

    function $onInit() {
      initialize();

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function initialize() {
      _.assign(vm, {
        id: getModalId(),
        isBusy: false,
        isConfirmAndContinueEnabled: false,
        ORGANIZATION_TYPE_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    function getModalId() {
      if (vm.modalId) {
        return vm.modalId;
      }
      return ORGANIZATION_TYPE_MODAL_ID;
    }

    /**
     * Dispatches analytics for opening the Organization Type modal.
     */
    function triggerAnalyticsOnOpen() {
      AnalyticsEvent.dispatch({
        componentMethod: 'setOrgType',
        componentMethodType: 'display',
        componentName,
      });
    }
  }
})();
