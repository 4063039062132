import {DialogContainer, Item, Menu, MenuTrigger, Text} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {DeviceAction} from '@pandora/react-data-source-daco';
import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {DrawerTrigger} from '@pandora/react-drawer';
import {TABLE_SECTION_ACTIONS, useTableSectionContext} from '@pandora/react-table-section';
import {TooltipButton} from '@pandora/react-tooltip-button';
import MoreIcon from '@spectrum-icons/workflow/More';
import Remove from '@spectrum-icons/workflow/Remove';
import ViewDetailIcon from '@spectrum-icons/workflow/ViewDetail';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import DeviceDetailsDrawer from 'features/products/device-licenses/components/device-details-drawer/DeviceDetailsDrawer';
import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

import ConfirmationDialog from '../confirmation-dialog/ConfirmationDialog';

const namespace = 'products.deviceLicenses.deviceDetails.details.table';
const VIEW_ACTION = 'VIEW';

/**
 * DeviceActionsMenu will display a Menu on the device item row in the device list table.
 * The menu will display a list of actions like View Details and Remove device
 */
const DeviceActionsMenu = ({
  onTableSectionChange,
  licenseGroupId,
  deviceDetails,
  showIPAddress,
}) => {
  const intl = useIntl();
  const {tableSectionState} = useTableSectionContext();

  const openDrawer = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [action, setAction] = useState();

  /**
   * @description Method that tells if the device is activated.
   * @param {Device} device - device details
   * @returns {boolean}
   */
  const isDeviceActivated = (device) =>
    device.activationState === DEVICE_ACTIVATION_STATUS.ACTIVATED;

  /**
   * @description Method to retrieve the licensing mode.
   *
   * @returns {String}
   */
  const getLicensingMode = () => tableSectionState.items[0]?.licensingMode ?? '';

  const onDialogDismiss = () => {
    setOpen(false);
  };

  const onActionPress = (key) => {
    switch (key) {
      case DeviceAction.REMOVE:
        setOpen(true);
        setAction(DeviceAction.REMOVE);
        break;
      case VIEW_ACTION:
        openDrawer.current();
        break;
      default:
        // do nothing
        break;
    }
  };
  const {refetchHeaderData} = useDeviceLicensesPageContext();
  const onDeviceUpdated = () => {
    refetchHeaderData();
    onTableSectionChange({
      action: TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED,
      payload: null,
    });
  };
  return (
    <>
      <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
        {(open) => {
          openDrawer.current = open;
        }}
        {(close) => (
          <DeviceDetailsDrawer
            closeDeviceDetailsDrawer={close}
            deviceDetails={deviceDetails}
            onDeviceUpdated={onDeviceUpdated}
            showIPAddress={showIPAddress}
          />
        )}
      </DrawerTrigger>
      <MenuTrigger>
        <TooltipButton
          buttonAriaLabel={intl.formatMessage(
            {
              id: `${namespace}.actions.moreAriaLabel`,
            },

            {deviceId: deviceDetails.id}
          )}
          data-test-id="device-more-button"
          hoverText={intl.formatMessage({
            id: `${namespace}.actions.more`,
          })}
          isQuiet
          variant="action"
        >
          <MoreIcon />
        </TooltipButton>
        <Menu
          disabledKeys={isDeviceActivated(deviceDetails) ? [DeviceAction.REMOVE] : []}
          onAction={onActionPress}
        >
          <Item key={VIEW_ACTION}>
            <ViewDetailIcon size="S" />
            <Text>
              {intl.formatMessage({
                id: `${namespace}.actions.viewDetails`,
              })}
            </Text>
          </Item>
          <Item key={DeviceAction.REMOVE}>
            <Remove size="S" />
            <Text>
              {intl.formatMessage({
                id: `${namespace}.actions.removeDevice`,
              })}
            </Text>
          </Item>
        </Menu>
      </MenuTrigger>
      <DialogContainer onDismiss={onDialogDismiss}>
        {isOpen && (
          <ConfirmationDialog
            action={action}
            close={onDialogDismiss}
            deviceDetailsList={[deviceDetails]}
            licenseGroupId={licenseGroupId}
            licensingMode={getLicensingMode()}
            onDeviceUpdated={onDeviceUpdated}
          />
        )}
      </DialogContainer>
    </>
  );
};

DeviceActionsMenu.propTypes = {
  /**
   * Details of the given device
   */
  deviceDetails: PropTypes.instanceOf(Device).isRequired,

  /** licenseGroupId is optional for the case of SDL - equivalent to product profile ID */
  licenseGroupId: PropTypes.string,

  /**
   * Callback function to refetch device details
   */
  onTableSectionChange: PropTypes.func.isRequired,

  /**
   * showIPAddress - Flag which indicates to show/hide the IP Address column in the Device Table
   */
  showIPAddress: PropTypes.bool.isRequired,
};

export default DeviceActionsMenu;
