import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

const OfferSwitchMigrationContext = createContext({});

const useOfferSwitchMigrationContext = () => useContext(OfferSwitchMigrationContext);

const MIGRATION_DATA_ACTIONS = {
  CLEAR_ERROR: 'CLEAR_ERROR',
  ERROR: 'ERROR',
  INITIALIZE_ELIGIBLE_MIGRATIONS: 'INITIALIZE_ELIGIBLE_MIGRATIONS',
  INITIALIZE_PRODUCT_GROUPS: 'INITIALIZE_PRODUCT_GROUPS',
  SET_TARGET_PRODUCT: 'SET_TARGET_PRODUCT',
  SORT_MIGRATIONS: 'SORT_MIGRATIONS',
};

const OfferSwitchMigrationContextProvider = ({children, dispatchMigrationData, migrationData}) => {
  const value = useMemo(
    () => ({dispatchMigrationData, migrationData}),
    [dispatchMigrationData, migrationData]
  );
  return (
    <OfferSwitchMigrationContext.Provider value={value}>
      {children}
    </OfferSwitchMigrationContext.Provider>
  );
};

OfferSwitchMigrationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dispatchMigrationData: PropTypes.func.isRequired,
  migrationData: PropTypes.shape({
    /**
     * contract renewal window end date. Ex: June, 20, 2020
     */
    contractRenewalEndDate: PropTypes.string.isRequired,
    /**
     * List of eligible rows (user + source products + target products)
     */
    eligibleMigrations: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Source product information
         */
        source: PropTypes.shape({
          /**
           * Product display name
           */
          displayName: PropTypes.string.isRequired,
          /**
           * Icon source
           */
          iconUrl: PropTypes.string.isRequired,
          /**
           * Unique ID to identify a product
           */
          id: PropTypes.string.isRequired,
        }).isRequired,
        /**
         * Target product information
         */
        targets: PropTypes.arrayOf(
          PropTypes.shape({
            /**
             * Available count
             */
            count: PropTypes.number.isRequired,
            /**
             * Product display name
             */
            displayName: PropTypes.string.isRequired,
            /**
             * Icon source
             */
            iconUrl: PropTypes.string.isRequired,
            /**
             * Unique ID to identify a product
             */
            id: PropTypes.string.isRequired,
          })
        ),
        /**
         * User information
         */
        user: PropTypes.shape({
          /**
           * User's email
           */
          email: PropTypes.string.isRequired,
          /**
           * Function that returns the user name
           */
          getDisplayName: PropTypes.func.isRequired,
          /**
           * User ID
           */
          id: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
    /**
     * Modal's error message
     */
    errorMessage: PropTypes.string,
    /**
     * The product groups to display in product upgrade summary section
     */
    productGroups: PropTypes.arrayOf(
      PropTypes.shape({
        productList: PropTypes.arrayOf(
          PropTypes.shape({
            /**
             * The count of a product
             */
            count: PropTypes.number,
            /**
             * The display name of a product
             */
            displayName: PropTypes.string,
            /**
             * The icon url of a product
             */
            iconUrl: PropTypes.string,
            /**
             * The id of a product
             */
            id: PropTypes.string,
          })
        ),
        /**
         * The title of a product group
         */
        title: PropTypes.string,
      })
    ).isRequired,
    /**
     * Table sort descriptor
     */
    sortDescriptor: PropTypes.shape({
      /**
       * Column key to sort on
       */
      column: PropTypes.string.isRequired,
      /**
       * Sort direction, either 'ascending' or 'descending'
       */
      direction: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export {
  MIGRATION_DATA_ACTIONS,
  OfferSwitchMigrationContext,
  OfferSwitchMigrationContextProvider,
  useOfferSwitchMigrationContext,
};
