import {useContentEntry} from '@pandora/react-content-provider';
import {ProductIcon, ProductIconContentModel} from '@pandora/react-product-icon-list';
import PropTypes from 'prop-types';
import React from 'react';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import InfoCardBar from '../info-card-bar/InfoCardBar';
import InfoCard from '../info-card-bar/info-card/InfoCard';
import SupportTicketCaseType from '../support-ticket-case-type/SupportTicketCaseType';
import SupportTicketImpact from '../support-ticket-impact/SupportTicketImpact';
import SupportTicketPriority from '../support-ticket-priority/SupportTicketPriority';

const {INFO_CARD_TYPE, SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

/**
 * Component is used to show InfoBar for a SupportCase. It uses <InfoCardBar/> component to render <InfoCard/>
 * For typeCode it uses <SupportTicketCaseType/> which renders the text as per typeCode value
 * For priorityCode it uses <SupportTicketPriority/> which renders translated text based on priorityCode and isEscalated
 * For impactCode it uses <SupportTicketImpact/> which renders translated text based on impactCode value
 */
const SupportCaseInfoBar = ({
  'data-testid': dataTestId,
  dateCreated,
  id,
  impactCode,
  isEscalated,
  lastUpdated,
  priorityCode,
  productIconUrl = undefined,
  productName,
  supportLevel,
  typeCode,
  isMigrated = false,
}) => {
  const showLastUpdated = () => !isMigrated && lastUpdated > dateCreated;
  const content = useContentEntry(ProductIconContentModel);
  return (
    <InfoCardBar data-testid={dataTestId || 'support-case-info-bar'}>
      <InfoCard
        label="support.supportCaseInfoBar.fields.caseId"
        type={INFO_CARD_TYPE.STRING}
        value={id}
      />
      {typeCode && (
        <InfoCard
          data-testid="typeCode"
          label="support.supportCaseInfoBar.fields.caseType"
          type={INFO_CARD_TYPE.COMPONENT}
          value={typeCode}
        >
          <SupportTicketCaseType recordType={SUPPORT_TICKET_RECORD_TYPE.CASE} typeCode={typeCode} />
        </InfoCard>
      )}
      <InfoCard
        data-testid="productNameWithIcon"
        label="support.supportCaseInfoBar.fields.product"
        type={INFO_CARD_TYPE.COMPONENT}
        value={productName}
      >
        <ProductIcon content={content} iconUrl={productIconUrl} name={productName} />
      </InfoCard>
      {supportLevel && (
        <InfoCard
          data-testid="supportLevel"
          label="support.supportCaseInfoBar.fields.supportLevel"
          type={INFO_CARD_TYPE.STRING}
          value={supportLevel}
        />
      )}
      {priorityCode && (
        <InfoCard
          data-testid="priorityCode"
          label="support.supportCaseInfoBar.fields.priority"
          type={INFO_CARD_TYPE.COMPONENT}
          value={priorityCode}
        >
          <SupportTicketPriority isEscalated={isEscalated} priorityCode={priorityCode} />
        </InfoCard>
      )}
      {impactCode && (
        <InfoCard
          label="support.supportCaseInfoBar.fields.impact"
          type={INFO_CARD_TYPE.COMPONENT}
          value={impactCode}
        >
          <SupportTicketImpact impactCode={impactCode} />
        </InfoCard>
      )}
      <InfoCard
        label="support.supportCaseInfoBar.fields.dateCreated"
        type={INFO_CARD_TYPE.DATETIME}
        value={dateCreated}
      />
      {showLastUpdated() && (
        <InfoCard
          label="support.supportCaseInfoBar.fields.lastUpdated"
          type={INFO_CARD_TYPE.DATETIME}
          value={lastUpdated}
        />
      )}
    </InfoCardBar>
  );
};

SupportCaseInfoBar.propTypes = {
  /**
   * The specified data-testid for the component.
   * The default is 'support-case-info-bar'.
   */
  'data-testid': PropTypes.string,
  /**
   * CreatedOn DateTime
   */
  dateCreated: PropTypes.string,
  /**
   * id of SupportCase/expert session
   */
  id: PropTypes.string.isRequired,
  /**
   * impactCode of SupportCase
   */
  impactCode: PropTypes.number,
  /**
   * prop to indicate SupportCase is escalated or not
   */
  isEscalated: PropTypes.bool,
  /**
   * prop to indicate SupportCase is migrated or not
   */
  isMigrated: PropTypes.bool,
  /**
   * Last Modified DateTime
   */
  lastUpdated: PropTypes.string,
  /**
   * Priority code of SupportCase
   */
  priorityCode: PropTypes.number,
  /**
   * SupportCase Product Icon Url
   */
  productIconUrl: PropTypes.string,
  /**
   * Name of SupportCase Product
   */
  productName: PropTypes.string,
  /**
   * support Level
   */
  supportLevel: PropTypes.string,
  /**
   * SupportCase typeCode value, based on this value CaseType text is shown.
   */
  typeCode: PropTypes.number,
};

export default SupportCaseInfoBar;
