import {dispatchUiEventAnalytics} from '@admin-tribe/binky';

import {ANALYTICS_ID} from '../notifications.constants';

/**
 * @description Dispatches a notification-related analytics event.
 * @param {Object}  [options] - options for setting up an analytics event.
 *  If not provided, an empty event is sent which will be filtered out by the analytics service.
 *  Options contains some of the following:
 * @param {String}  [options.action] - the event action. For example: 'click'.
 * @param {String}  [options.name] - the event name. For example: 'NotificationsDrawer'.
 * @param {Object}  [options.notification] - optional notification that the analytic event is related to.
 * @param {Array}  [options.notificationList] - optional notification list that the analytic event is related to.
 */
function dispatchEvent(options) {
  const {action, name, notification, notificationList} = options;

  const responses = [];
  const interaction = {};
  if (notificationList) {
    let impressions = '';

    notificationList.forEach((item) => {
      const analyticsId = `${item.subType}${ANALYTICS_ID}`;
      if (impressions) {
        impressions += `, ${analyticsId}`;
      } else {
        impressions = analyticsId;
      }
      if (item.hasSophiaData()) {
        responses.push(item.getSophiaResponse());
      }
    });
    interaction.impression = impressions;
  } else if (notification) {
    responses.push(notification.getSophiaResponse());
    interaction.click = `${notification.subType}${ANALYTICS_ID}`;
  }

  dispatchUiEventAnalytics({
    eventAction: action,
    eventName: name,
    interaction,
    sophia: {responses},
  });
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {dispatchEvent};
