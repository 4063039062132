class PackagesAdobeUpdateEntity {
  /**
   * @description Method to check if this update has any special system requirements
   *
   * @returns {Boolean} Always false
   */
  // eslint-disable-next-line class-methods-use-this -- @ringold to fix
  hasSpecialSystemRequirements = () => false;

  /** Constructor for Adobe Update Service (Prototype function declaration) **/
  /**
   * @class
   * @description Creates a new AdobeUpdate for use.
   *
   * @param {Object} options - Initialization Object (params described below)
   * @param {String} options.refID - Unique update ID
   * @param {String} options.locale_name -  Update's name
   * @param {String} options.version - Update's version
   * @param {String} options.locale_description - Update's description
   */
  constructor(options) {
    this.refId = options.refID;
    this.localeName = options.locale_name;
    this.version = options.version;
    this.localeInfo = options.locale_description;
    this.systemRequirementURL = '';
    this.isRibs = true;
    this.isSTI = false;
    this.visible = true;
    this.querySelectorKey = this.refId.replace(/[./]/g, '-');
  }
}

export default PackagesAdobeUpdateEntity;
