import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import AddDevelopersToLicenseGroupModal from 'common/components/add-developer-modals/AddDevelopersToLicenseGroupModal';
import AddDevelopersToOrgModal from 'common/components/add-developer-modals/AddDevelopersToOrgModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';

// The 'Add developer' button, and the associated modal to add new developers to either
// the Developers page or the product profile developers sub-page.
const AddDevelopersButton = observer(() => {
  const {canAddMember, pageContext, store} = useMemberListContext();
  const [isOpen, setIsOpen] = useState(false);

  const commonProps = {
    isOpen,
    onCancel,
    onSuccess,
    orgId: store.orgId,
  };

  function onCancel() {
    setIsOpen(false);
  }

  // Filter/page params are not changed.
  function onSuccess() {
    store.load();
    setIsOpen(false);
  }

  // Render the 'Add developers' modal for the given pageConext.
  const renderModal = () => {
    if (pageContext.isOrganizationTarget()) {
      return <AddDevelopersToOrgModal {...commonProps} />;
    }

    if (pageContext.isProductConfigurationTarget()) {
      return (
        <AddDevelopersToLicenseGroupModal
          {...commonProps}
          licenseGroupId={pageContext.targetId}
          productId={pageContext.targetParentId}
        />
      );
    }

    throw new Error('AddDevelopersButton: unrecognized target');
  };

  return (
    canAddMember && (
      <>
        <Button data-testid="add-developer" onPress={() => setIsOpen(true)} variant="accent">
          <FormattedMessage id="common.adminListTableSection.button.addDevelopers" />
        </Button>
        {isOpen && renderModal()}
      </>
    )
  );
});

export default AddDevelopersButton;
