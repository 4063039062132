import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {PAGE_SIZE_OPTIONS} from '@pandora/react-pagination';
import {SORT_ORDER, TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import useFederatedDomainList from 'features/settings/hooks/api/useFederatedDomainList';
import FederatedDomainListModel from 'features/settings/models/FederatedDomainListModel';

const FEDERATED_DOMAINS_ERROR_CODES = {
  GENERIC_ERROR: 'GENERIC_ERROR',
  INVALID_TOKEN_ERROR: 'INVALID_TOKEN_ERROR',
};

/**
 * A hook that manages the state of the ImportFederatedDomainsModal and its domains table
 */
const useFederatedDomainsModalState = ({authSourceId, externalToken, federatedType}) => {
  const intl = useIntl();

  const {claimDomains, getFederatedDomainList} = useFederatedDomainList({
    authSourceId,
    externalToken,
    federatedType,
  });

  const [federatedDomainsList, setFederatedDomainsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [error, setError] = useState(null);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE_OPTIONS.DEFAULT[1].id);

  const [currentItems, setCurrentItems] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedDomains, setSelectedDomains] = useState([]);

  const isCtaDisabled = useMemo(
    () => isLoading || selectedDomains?.length === 0,
    [isLoading, selectedDomains?.length]
  );

  const sortAscending = (a, b) => (a.id > b.id ? 1 : -1);
  const sortDescending = (a, b) => (a.id > b.id ? -1 : 1);

  const sortItems = useCallback(
    (isDescending) =>
      isDescending ? filteredDomains.sort(sortDescending) : filteredDomains.sort(sortAscending),
    [filteredDomains]
  );

  const isSortDescending = (dataAttribute) => dataAttribute.sortedOrder === SORT_ORDER.DESC;

  const onPageSizeChange = useCallback(
    (payload) => {
      setCurrentPageSize(payload);
      setCurrentItems(filteredDomains?.slice(0, payload));
      setTotalPages(Math.ceil(totalItems / payload));
      setCurrentPageNumber(1);
    },
    [filteredDomains, totalItems]
  );

  const onSortBy = useCallback(
    (payload) => {
      const sortedItems = sortItems(isSortDescending(payload));

      setFilteredDomains(sortedItems);
      setCurrentItems(
        filteredDomains.slice(
          (currentPageNumber - 1) * currentPageSize,
          currentPageNumber * currentPageSize
        )
      );
    },
    [currentPageNumber, currentPageSize, filteredDomains, sortItems]
  );

  const goToPage = useCallback(
    (payload) => {
      let indexOfFirstDomain, indexOfLastDomain;

      if (payload > currentPageNumber) {
        // goNext
        indexOfLastDomain = currentPageSize * payload;
        indexOfFirstDomain = currentPageSize * currentPageNumber;
      } else {
        // goPrev
        indexOfLastDomain = currentPageSize * (payload - 1);
        indexOfFirstDomain = currentPageSize * (currentPageNumber - 1);
      }

      if (indexOfLastDomain > indexOfFirstDomain) {
        setCurrentItems(filteredDomains.slice(indexOfFirstDomain, indexOfLastDomain));
      } else {
        setCurrentItems(filteredDomains.slice(indexOfLastDomain, indexOfFirstDomain));
      }

      setCurrentPageNumber(payload);
    },
    [currentPageNumber, currentPageSize, filteredDomains]
  );

  const onSearch = useCallback(
    (value) => {
      if (value === '') {
        setFilteredDomains(federatedDomainsList);
        setCurrentItems(federatedDomainsList.slice(0, currentPageSize));
        setCurrentPageNumber(1);
      } else {
        const searchResult = federatedDomainsList.filter((domain) =>
          domain.name.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredDomains(searchResult);
        setCurrentItems(searchResult.slice(0, currentPageSize));
        setCurrentPageNumber(1);
      }
    },
    [currentPageSize, federatedDomainsList]
  );

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          onSearch(payload.value);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          onSortBy(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
          setSelectedDomains(payload);
          break;
        default:
          // Do nothing
          break;
      }
    },
    [goToPage, onPageSizeChange, onSearch, onSortBy]
  );

  /**
   * Function used to fetch a FederatedDomainList.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadFederatedDomains = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getFederatedDomainList();

      const domainList = FederatedDomainListModel.fromObject(response);

      // Pre-sort ascending
      domainList.items.sort(sortAscending);

      setFederatedDomainsList(domainList.items);
      setTotalItems(domainList.numberOfDomains);

      // Setting table
      setCurrentItems(domainList.items.slice(0, currentPageSize));
      setFilteredDomains(domainList.items);
      setTotalPages(Math.ceil(domainList.numberOfDomains / currentPageSize));

      setHasLoadingError(false);

      return federatedDomainsList;
    } catch (error_) {
      setHasLoadingError(true);

      if (error_.response?.status === 401) {
        setError(FEDERATED_DOMAINS_ERROR_CODES.INVALID_TOKEN_ERROR);
      } else {
        setError(FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR);
      }

      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [currentPageSize, federatedDomainsList, getFederatedDomainList]);

  /**
   * Function used to claim the selected federated domains
   */
  const claimFederatedDomains = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await claimDomains({domains: selectedDomains});

      showSuccessToast(
        intl.formatMessage({id: 'settings.directoryDomains.addDomainModal.toast.success'})
      );

      return response;
    } catch (error_) {
      setError(error_);
      showErrorToast();

      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, [claimDomains, intl, selectedDomains]);

  return {
    claimFederatedDomains,
    currentItems,
    currentPageNumber,
    error,
    federatedDomainsList,
    hasLoadingError,
    isCtaDisabled,
    isLoading,
    loadFederatedDomains,
    onTableSectionChange,
    totalPages,
  };
};

export default useFederatedDomainsModalState;
export {FEDERATED_DOMAINS_ERROR_CODES};
