(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories.sync')
    .constant('DIRECTORY_SYNC_DOMAINS_RETRY_GET_EVENT', 'directorySyncDomains:retry')
    .constant('DIRECTORY_SYNC_DOMAINS_SAVE_EVENT', 'directorySyncDomains:save')
    .constant('DIRECTORY_SYNC_DOMAINS_ERROR_TYPES', {
      FETCH_ERROR: 'fetchError',
      SUBMIT_ERROR: 'submitError',
      UNAUTHORIZED_ERROR: 'unauthorizedError',
    });
})();
