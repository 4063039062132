import {Cache, download, jilDirectories, jilDomains, log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import {DOMAIN_STATUSES} from 'common/entities/DomainEntity';
import rootStore from 'core/RootStore';

import {getCacheKey, mergeParamsWithDefaults} from './utils/hooksApiUtils';

const DOMAIN_LIST_CACHE_KEY = 'domainList';

const domainListCache = new Cache();

/**
 * Function that makes a patch call to the JIL Domains API. Use to implement operations that make PATCH calls: validate, link, remove
 *
 * @param {Array} domains - array of domains to be patched
 * @param {Function} domainsToOpsMapFn - function that maps a domain to an OrganizationDomainPatch. See docs linked below for details.
 * @return {Promise<{failed: Array, successful: Array}>} - promise that resolves to an object indicating which domains were patched successfully and which failed.
 *
 * @see {@link https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=JIL2&title=Domains+API|JIL Domains docs}
 */
const patchDomains = async (domains, domainsToOpsMapFn) => {
  const jilResponse = await jilDomains.patchDomains(
    {
      orgId: rootStore.organizationStore.activeOrgId,
    },
    domains.map(domainsToOpsMapFn)
  );

  const successful = jilResponse.data;
  const isDomainFailed = (domain) =>
    !successful.some((successfulDomain) => successfulDomain.domainName === domain.domainName);

  const failed = domains.filter(isDomainFailed);

  return {failed, successful};
};

/**
 * Gets the list of directory domains for the provided directory id.
 *
 * @returns {Promise<{data, pageSize, totalCount: *}|*>}
 */
const getDirectoryDomainList = async ({directoryId, ...restOfParams} = {}) => {
  if (!directoryId) {
    throw new Error('Directory ID is required');
  }

  const params = mergeParamsWithDefaults({directoryId, ...restOfParams});

  const cacheKey = getCacheKey(
    DOMAIN_LIST_CACHE_KEY,
    rootStore.organizationStore.activeOrgId,
    params
  );

  try {
    const cachedPromise = domainListCache.get(cacheKey);

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = jilDirectories.getDomains({
        directoryId: params.directoryId,
        orgId: rootStore.organizationStore.activeOrgId,
        page: params.page,
        page_size: params.pageSize,
        search_query: params.searchQuery,
        sort: params.sort,
        sort_order: params.sortOrder,
        status: params.status,
      });
      // cache the promise before returning so parallel calls can wait for the same call
      domainListCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error(`[ID][JIL] Error getting the list of domains for directory ${directoryId}`, error);

    domainListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * A hook that helps with making calls to the JIL API for domains.
 * Handles caching and logging.
 */
const getDomainList = async (options) => {
  const params = mergeParamsWithDefaults(options);

  const cacheKey = getCacheKey(
    DOMAIN_LIST_CACHE_KEY,
    rootStore.organizationStore.activeOrgId,
    params
  );

  try {
    const cachedPromise = domainListCache.get(cacheKey);

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = jilDomains.getDomains({
        orgId: rootStore.organizationStore.activeOrgId,
        page: params.page,
        page_size: params.pageSize,
        search_query: params.searchQuery,
        sort: params.sort,
        sort_order: params.sortOrder,
        status: params.status,
      });

      domainListCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error(`[ID][JIL] Error getting the list of domains`, error);

    // if there is an error, clear the cache, so we can try again
    domainListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * A hook that helps with making calls to the JIL API for domains.
 * Handles caching and logging.
 */
const useDomainList = () => {
  const getDomainListCallback = useCallback(getDomainList, []);

  const getDirectoryDomainListCallback = useCallback(getDirectoryDomainList, []);

  const exportDirectoryDomainsToCSV = useCallback(async (directoryData) => {
    try {
      const response = await jilDirectories.exportDirectoryDomainsToCSV({
        directoryId: directoryData.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      if (!response?.data?.file || response.data.file.size === 0) {
        throw new Error('Received empty directory domains list response from server');
      }

      download(response.data.file, `domains-${directoryData.directoryName}.csv`);
    } catch (error) {
      log.error(
        `[ID][JIL] Error exporting the domains for directory ${directoryData.directoryId} to CSV in org ${rootStore.organizationStore.activeOrgId}. Error:`,
        error
      );
    }
  }, []);

  // @TODO rewrite this to use the patchDomains helper
  /**
   * @description Removes a domain or a list of domains
   *
   * @param {Array<DomainEntity>} domains
   * @returns {Promise<AxiosResponse>} - The Axios response
   */
  const removeDomains = useCallback(async (domains) => {
    try {
      const {failed, successful} = await patchDomains(domains, (domain) => ({
        op: 'remove',
        path: `/${domain.domainName}/status`,
        value: 'WITHDRAWN',
      }));

      return {failedToRemove: failed, removed: successful};
    } catch (error) {
      log.error(`[ID][JIL] Error removing the domains`, error);

      throw error;
    }
  }, []);

  const clearDomainsCache = useCallback(() => domainListCache.clearAll(), []);

  const linkDomainsToDirectory = useCallback(
    async ({domains, directoryId}) => {
      try {
        const {failed, successful} = await patchDomains(domains, (domain) => ({
          op: 'replace',
          path: `/${domain.domainName}/status/ACTIVE/directoryId/${directoryId}`,
        }));

        clearDomainsCache();

        return {failedToLink: failed, linked: successful};
      } catch (error) {
        log.error(`[ID][JIL] Error linking domains to directory ${directoryId}`, error);

        throw error;
      }
    },
    [clearDomainsCache]
  );

  const validateDomains = useCallback(
    async (domains) => {
      try {
        const {failed, successful} = await patchDomains(domains, (domain) => ({
          op: 'replace',
          path: `/${domain.domainName}/status`,
          value: domain.directoryId ? DOMAIN_STATUSES.ACTIVE : DOMAIN_STATUSES.VALIDATED,
        }));

        clearDomainsCache();

        return {failedToValidate: failed, validated: successful};
      } catch (error) {
        log.error(`[ID][JIL] Error validating domains`, error);

        throw error;
      }
    },
    [clearDomainsCache]
  );

  return {
    clearDomainsCache,
    exportDirectoryDomainsToCSV,
    getDirectoryDomainList: getDirectoryDomainListCallback,
    getDomainList: getDomainListCallback,
    linkDomainsToDirectory,
    removeDomains,
    validateDomains,
  };
};

export {getDirectoryDomainList, getDomainList};
export default useDomainList;
