import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the Add Domains modal and its sub-components
 */
const ImportFederatedDomainsModalContext = createContext({});
const useImportFederatedDomainsModalContext = () => useContext(ImportFederatedDomainsModalContext);

const ImportFederatedDomainsModalContextProvider = ({children, state}) => {
  const value = useMemo(() => ({...state}), [state]);

  return (
    <ImportFederatedDomainsModalContext.Provider value={value}>
      {children}
    </ImportFederatedDomainsModalContext.Provider>
  );
};

ImportFederatedDomainsModalContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the ImportFederatedDomains modal
   */
  children: PropTypes.node,
  /**
   * The state of the ImportFederatedDomains modal used by the provider to keep state of the children components
   */
  state: PropTypes.shape({
    claimFederatedDomains: PropTypes.func,
    currentItems: PropTypes.arrayOf(
      PropTypes.shape({
        detailedStatus: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        status: PropTypes.string,
      })
    ),
    currentPageNumber: PropTypes.number,
    federatedDomainsList: PropTypes.arrayOf(
      PropTypes.shape({
        detailedStatus: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        status: PropTypes.string,
      })
    ),
    hasLoadingError: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadFederatedDomains: PropTypes.func,
    onTableSectionChange: PropTypes.func,
    setSelectedDomains: PropTypes.func,
    totalPages: PropTypes.number,
  }),
};

export {ImportFederatedDomainsModalContextProvider, useImportFederatedDomainsModalContext};
