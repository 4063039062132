/**
 * @deprecated ported to src2/app/features/users/components/directory-user-membership-section/DirectoryUserMembershipSection.jsx
 *
 * @ngdoc component
 * @name app.widgets.directories:appUserOrganizationMembershipSection
 *
 * @description The organization membership section in the user detail drawer.
 *
 * @binding {Array} orgList. A list of orgs.
 */
(function () {
  const componentName = 'appUserOrganizationMembershipSection';
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      user: '<',
    },
    templateUrl:
      'app/widgets/users/user-organization-membership-section/user-organization-membership-section.component.html',
  });
})();
