(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains2:domainsForm
   *
   * @description renders a form containing a textarea into which comma or whitespace separated
   * domains can be entered. Validates that the maximum number of allowed domains has not been
   * exceeded and that each domain is of the expected format. The form input is debounced meaning
   * there is a grace period before invalidation errors are shown. When the input changes the
   * onChange callback is invoked with a de-duplicated array of the inputted domains.
   *
   * @binding {Function} onChange - called when the textarea contents change, with two parameters:
   *   {Boolean} isValid - true if the form is valid
   *   {Array} domainNames - the array of domain names in the form, with duplicates removed
   */
  angular.module('app.widgets.domains2').component('appDomainsForm', {
    bindings: {
      onChange: '&',
    },
    controller,
    templateUrl: 'app/widgets/domains2/add-domains-modal/domains-form/domains-form.component.html',
  });

  function controller($translate, _, domainUtils, jsUtils) {
    const INVALID_INPUT_PREFIX = 'widgets.domains2.domainsForm.validationError';
    const MAX_NUM_DOMAINS = 15;
    // Domain names are considered valid up to 255 characters, so hopefully this limit is
    // sufficiently generous to communicate invalid domains to the user
    const DOMAIN_NAME_TRUNCATE_LENGTH = 300;

    const vm = this;

    _.assign(vm, {
      MAX_NUM_DOMAINS,
      onDomainsInputChange,
    });

    /////////////////

    function onDomainsInputChange() {
      const domainNames = parseDomainsInput();
      vm.invalidInputMsg = getInvalidInputMsg(domainNames);
      vm.onChange({
        $event: {
          domainNames,
          isValid: _.isEmpty(vm.invalidInputMsg),
        },
      });
    }

    //////////////

    function getInvalidInputMsg(domainNames) {
      if (domainNames.length > MAX_NUM_DOMAINS) {
        return $translate.instant(`${INVALID_INPUT_PREFIX}.tooManyDomains`, {
          maxNumDomains: MAX_NUM_DOMAINS,
        });
      }

      const invalidDomainName = _.find(domainNames, isInvalidDomainName);
      if (invalidDomainName) {
        // Truncate the domain name to prevent the error message becoming arbitrarily long
        const domainName = _.truncate(invalidDomainName, {length: DOMAIN_NAME_TRUNCATE_LENGTH});
        return $translate.instant(`${INVALID_INPUT_PREFIX}.invalidFormat`, {
          domainName,
        });
      }

      return '';
    }

    function isInvalidDomainName(domainName) {
      return !domainUtils.isValidDomainName(domainName);
    }

    function parseDomainsInput() {
      return jsUtils.splitList(vm.form.domainsInput, {removeDupes: true});
    }
  }
})();
