(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc provider
   * @name orgReadyProvider
   * @description
   */
  angular.module('app.core.ready.org').provider('orgReady', orgReadyProvider);
  function orgReadyProvider() {
    this.$get = $get;

    // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
    function $get(
      $injector,
      $location,
      $q,
      _,
      apiReady,
      authenticationReady,
      binkySrc2,
      feature,
      featureReady,
      onesieSrc2,
      ORG_MANAGER_ERROR,
      OrganizationList
    ) {
      const deferred = $q.defer();

      const service = {
        run,
        whenRunReady,
      };

      return service;

      ///////////////

      function run() {
        const promises = [
          authenticationReady.whenRunReady(),
          featureReady.whenRunReady(),
          apiReady.whenRunReady(),
        ];
        const runPromise = $q
          .all(promises)
          .then(() => $injector.get('OrganizationManager').loadOrganizations())
          .then((loadOrganizationsResult) => getPathOrDefaultOrgId(loadOrganizationsResult))
          .then((orgId) => $injector.get('OrganizationManager').loadOrganization(orgId));

        runPromise.then(deferred.resolve).catch(deferred.reject);
        return runPromise;
      }

      function whenRunReady() {
        return deferred.promise;
      }

      ///////////////

      function getOrgIdFromPath(path) {
        return _.find(path, (param) => param.indexOf('AdobeOrg') > 0);
      }

      function getPathParams() {
        return _($location.path()).split('/').compact().value();
      }

      /**
       * @description Determine an org to set as active.
       * @param {Object} orgData Organization data
       * @param {OrganizationList} orgData.organizationList Resolved OrganizationList instance
       * @param {LinkedUserAccountList} orgData.linkedUserAccountList Resolved LinkedUserAccountList instance
       * @returns {Promise} promise which will be resolved with the ID of the selected org, or
       * rejected with an error.
       */
      function getPathOrDefaultOrgId({linkedUserAccountList, organizationList}) {
        if (feature.isDisabled('temp_adobe_agent_access')) {
          return getPathOrDefaultOrgIdSrc1Behaviour({linkedUserAccountList, organizationList});
        }
        const activeOrganizationUtils =
          onesieSrc2.core.services.organization.activeOrganizationUtils;
        // This will check for the org from the path, then try to find a default
        const userAccount = activeOrganizationUtils.getActiveUserAccount({
          linkedUserAccountList,
          organizationList,
        });
        return processUserAccount({
          organizationList,
          userAccount,
        });
      }

      /**
       * @description Determine an org to set as active.
       * @param {Object} orgData Organization data
       * @param {OrganizationList} orgData.organizationList Resolved OrganizationList instance
       * @param {LinkedUserAccountList} orgData.linkedUserAccountList Resolved LinkedUserAccountList instance
       * @returns {Promise} promise which will be resolved with the ID of the selected org, or
       * rejected with an error.
       */
      // Can be removed with temp_adobe_agent_access
      function getPathOrDefaultOrgIdSrc1Behaviour({linkedUserAccountList, organizationList}) {
        // Assume that the user must go to the orgID if it's available from the URL.
        // Thus, find it from orgList first, then from linkedAccounts.
        // Otherwise, reject with ORG_NOT_FOUND.
        const selectedOrgId = getOrgIdFromPath(getPathParams());
        if (selectedOrgId) {
          const userAccount = binkySrc2.utils.authenticationUtils.getUserAccount({
            linkedUserAccountList,
            organizationList,
            orgId: selectedOrgId,
          });

          // Reject with NO_ORGS_AVAILABLE if the user has 0 org access
          if (!userAccount) {
            return $q.reject(ORG_MANAGER_ERROR.NO_ORGS_AVAILABLE);
          }

          return processUserAccountSrc1Behaviour({
            rejectWith: ORG_MANAGER_ERROR.ORG_NOT_FOUND,
            userAccount,
          });
        }

        // Check stored org ID only with orgList, do not check linkedAccounts.
        // OrgList contains org list accessible with the current token, i.e.
        // token switch is not needed.
        // Checking both orgList and linkedAccount would cause CAUIP-11119 where
        // the user attempted to login to org/profile B and ended up with org A
        // because org A is stored in the session storage.
        const storedOrgId = OrganizationList.getActiveOrgIdFromSessionStorage();
        if (storedOrgId && organizationList.has(storedOrgId)) {
          return $q.resolve(storedOrgId);
        }

        // There's no org available from the URL or from session storage.
        // Attempt to find any available org from orgList or linkedAccounts
        return processUserAccountSrc1Behaviour({
          rejectWith: ORG_MANAGER_ERROR.NO_ORGS_AVAILABLE,
          userAccount: binkySrc2.utils.authenticationUtils.findAnyUserAccount({
            linkedUserAccountList,
            organizationList,
          }),
        });
      }

      ////////

      function processUserAccount({organizationList, userAccount}) {
        const deferredForOrgId = $q.defer();

        if (userAccount) {
          const {actingAsAgent, currentUser, orgId, userId} = userAccount;
          // if we're only here because they're an agent, we need to simulate the org in the list response
          if (actingAsAgent) {
            organizationList.items.push(
              new binkySrc2.services.organization.Organization({id: orgId})
            );
            organizationList.setActive(orgId);
            const AuthenticatedUser = $injector.get('AuthenticatedUser');
            AuthenticatedUser.get().getRoles().setIsActingAsAdobeAgentForOrg(orgId);
            deferredForOrgId.resolve(orgId);
          } else if (currentUser) {
            deferredForOrgId.resolve(orgId);
          } else {
            // If the currentUser isn't the right one for the org, we'll have to redirect through IMS to them
            const authentication = $injector.get('authentication');
            authentication.switchTo(userId, $location.url());
          }
        } else {
          // If no user account was found, then the user has no orgs to display
          deferredForOrgId.reject(ORG_MANAGER_ERROR.NO_ORGS_AVAILABLE);
        }
        return deferredForOrgId.promise;
      }

      // Can be removed with temp_adobe_agent_access
      function processUserAccountSrc1Behaviour({rejectWith, userAccount}) {
        const deferredForOrgId = $q.defer();

        if (userAccount) {
          const {currentUser, userId} = userAccount;
          if (currentUser) {
            deferredForOrgId.resolve(userAccount.orgId);
          } else {
            const authentication = $injector.get('authentication');
            authentication.switchTo(userId, $location.url());
          }
        } else {
          deferredForOrgId.reject(rejectWith);
        }
        return deferredForOrgId.promise;
      }
    }
  }
})();
