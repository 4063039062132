import {Content, ContextualHelp, Flex, Heading} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

// The heading for each of the Sharing Options sections.
const SharingOptionsHeading = ({canSetSharingOptions, children, id}) => {
  const NAMESPACE = 'settings.assetSettings.assetSharing.sharingOptionsHeading.contextualHelp';
  const intl = useIntl();

  return (
    <Flex direction="row" marginBottom="static-size-200">
      <Heading
        data-testid="sharing-options-heading"
        id={id}
        level={2}
        marginY="static-size-0"
        UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-static-font-size-200)'}}
      >
        {children}
      </Heading>
      {!canSetSharingOptions && (
        <ContextualHelp alignSelf="center" marginStart="size-50" variant="info">
          <Heading>{intl.formatMessage({id: `${NAMESPACE}.heading`})}</Heading>
          <Content>{intl.formatMessage({id: `${NAMESPACE}.content`})}</Content>
        </ContextualHelp>
      )}
    </Flex>
  );
};

SharingOptionsHeading.propTypes = {
  /**
   * True if the radio group in the section is enabled.
   */
  canSetSharingOptions: PropTypes.bool.isRequired,
  /**
   * The heading content/text.
   */
  children: PropTypes.node.isRequired,
  /**
   * The id of the heading.
   */
  id: PropTypes.string.isRequired,
};

export default SharingOptionsHeading;
