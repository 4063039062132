import {Heading} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './IdpCard.pcss';

const IdpMetadataItemLabel = observer(({id}) => (
  <Heading
    level={4}
    marginBottom="size-100"
    marginTop="size-100"
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
    UNSAFE_className={styles.heading}
  >
    <FormattedMessage id={id} />
  </Heading>
));

export default IdpMetadataItemLabel;
