import {ActionButton, Divider, Flex, Text, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import Info from '@spectrum-icons/workflow/Info';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Info tooltip which is displayed next to the help rail header description.
 */

const HelpRailTooltip = () => {
  const intl = useIntl();

  const tooltipText = intl.formatMessage({id: 'shell.helpRail.header.tooltipText'});
  const headerDescription = intl.formatMessage({id: 'shell.helpRail.header.description'});

  return (
    <Flex direction="column" gap="size-65">
      <Flex alignItems="center" gap="size-65">
        <Text data-testid="helprail-header-title">{headerDescription}</Text>

        <TooltipTrigger delay={0}>
          <ActionButton
            aria-label={tooltipText}
            data-testid="tooltip-button-trigger"
            isQuiet
            UNSAFE_style={{cursor: 'pointer'}}
          >
            <Info size="S" />
          </ActionButton>

          <Tooltip data-testid="helprail-tooltip-content">{tooltipText}</Tooltip>
        </TooltipTrigger>
      </Flex>

      <Divider size="S" />
    </Flex>
  );
};

export default HelpRailTooltip;
