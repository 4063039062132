import pick from 'lodash/pick';

/**
 * Represents a single API integration.
 */
class ApiIntegration {
  static apiResponseTransformer(responseData) {
    return new ApiIntegration(responseData);
  }

  /**
   * @description Creates a new ApiIntegration model
   * @param {Object} [options] Initialization Object (params described below)
   * @param {String} [options.apiKey] ApiKey of the integration
   * @param {Number} [options.createdDate] The date this integration was created
   * @param {String} [options.description] Description for the integration
   * @param {String} [options.id] Integration id.
   *        This is not only the id returned by the back-end,
   *        but also the id used to select integration data from the back-end
   * @param {Number} [options.lastModifiedDate] The date this integration was last modified
   * @param {String} [options.name] Name of the integration
   * @param {Boolean} [options.namespaceEnabled] Indicates whether namespace is enabled
   * @param {String} [options.orgId] Organization id.
   *        This is not the Renga orgId this is the AdobeIO OrgId.
   *        This is not only the orgId returned by the back-end,
   *        but also the orgId used to select integration data from the back-end
   * @param {Array} [options.productIdList] Array of Strings representing the ids of products associated with this integration
   * @param {Boolean} [options.production] Indicates whether this integration is production or not
   * @param {Array} [options.sdkList] Array of Strings representing the names of sdks associated with this integration
   * @param {String} [options.status] Status for the integration (ex: "ENABLED")
   * @param {String} [options.technicalAccountEmail] Technical Account Email for this integration
   * @param {String} [options.technicalAccountId] Technical Account Id for this integration
   * @param {String} [options.type] Type of the integration (ex: "entp")
   */
  constructor(options = {}) {
    Object.assign(this, toMinimumObject(options));
  }
}

function toMinimumObject(object) {
  return pick(object, [
    'apiKey',
    'createdDate',
    'description',
    'id',
    'lastModifiedDate',
    'name',
    'namespaceEnabled',
    'orgId',
    'productIdList',
    'production',
    'sdkList',
    'status',
    'technicalAccountEmail',
    'technicalAccountId',
    'type',
  ]);
}

export default ApiIntegration;
