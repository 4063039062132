import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext, UserGroupAdminList} from '@admin-tribe/binky';
import {Subpage, useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useLoaderData} from 'react-router-dom';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {
  canAssignAdminToUserGroup,
  canRemoveAdminFromUserGroup,
} from 'core/user-group/access/userGroupAccess';

/**
 * The User group 'Admins' Subpage component which is displayed in a TabbedNav.
 * If the user group is externally managed, admins can not be removed.
 * The selection mode will be 'none' and 'getIsItemSelectable' is not called.
 */
const UserGroupAdminsSubpage = observer(() => {
  const {userGroup} = useLoaderData();

  const adminStore = useStore(
    () =>
      new AdminStore({
        immediateLoad: true,
        listClassRef: UserGroupAdminList,
        listOptions: {userGroupId: userGroup.id},
      })
  );

  const pageContext = new PageContext({
    target: PAGE_TARGET.USER_GROUP,
    targetId: userGroup.id,
    targetType: PAGE_TARGET_TYPE.ADMIN,
  });

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  return (
    <Subpage data-testid="user-group-admins-subpage">
      <MemberListContextProvider
        canAddMember={canAssignAdminToUserGroup(userGroup)}
        canRemoveMember={canRemoveAdminFromUserGroup(userGroup)}
        getIsItemSelectable={getIsItemSelectable}
        memberListDisplay={{
          email: true,
          idType: true,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={adminStore}
      >
        <AdminListTableSection />
      </MemberListContextProvider>
    </Subpage>
  );
});

export default UserGroupAdminsSubpage;
