import PropTypes from 'prop-types';
import React from 'react';

import styles from './OrdinalIcon.pcss';

/**
 * @description A component for displaying number icons inside of a circle.
 *   Due to time constraints, this component should currently only be used to
 *   display single digits. In the future, we may want to provide handling for
 *   multi-digit numbers (e.g. - 13, 409). However, before this can be
 *   achieved, care will need to be taken to either:
 *
 *   1. Find a dynamic solution to resizing the element dimensions based on
 *      the actual rendered number height/width (must support initial
 *      rendering being 0x0, since current use is inside of an accordion
 *      item), or...
 *   2. Determine a method to estimate the width of multi-digit numbers to
 *      dynamically set additional styles that will either enlarge or shrink
 *      the containing circle or the digits inside
 *
 * @param {Object} config - the configuration Object
 * @param {Number} config.number - the number to display inside of a circle
 *
 * @example <OrdinalIcon number="5"></OrdinalIcon>
 */
const OrdinalIcon = ({number}) => (
  <div className={styles['ordinal-icon']}>
    <div className={styles['ordinal-icon-border']} tabIndex="-1">
      <span className={styles['ordinal-icon-ordinal']} tabIndex="-1">
        {number}
      </span>
    </div>
  </div>
);

OrdinalIcon.propTypes = {
  /**
   * Number to be displayed as ordinal icon (must be single digit)
   */
  number: PropTypes.number.isRequired,
};

export default OrdinalIcon;
