/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase:appCartItem
 *
 * @description A single item in cart for purchase
 *
 * @param {Cart} [cart=undefined] The cart of items to use for the "purchase".
 * @param {Boolean} [isNumberToPurchaseReadOnly=false] If true, the quantity cannot be modified.
 * @param {Offer} offer The offer attached to the current item.
 * @param {Function} [onRemove] The callback to remove current offer. If not passed in, will hide the remove button.
 * @param {Function} [onSelectedChange] The callback signalling that the number of licenses to be added or renewed has
 *   changed. Param is offer. If the number selected is invalid offer.numberSelected will be NaN.
 * @param {Product} [productList] The list of products owned by the organization.
 * @param {Boolean} [restrictPromoGaming=false] If true, cart item will have number input section disabled when displaying promotional offers.
 * @param {Boolean} [showLicensePricing] Default is to show the license scorecard. Used to show the license pricing list instead.
 * @param {Boolean} [showFuturePricing=false] If true, the pricing at renewal will be used.
 * @param {Boolean} [useRenewalLabel=false] If true, cart item will use the renewal labels in the cart item.
 * @param {Boolean} [waitForTotal=false] If true, the wait string which is 2 en-dashes will be shown instead of the subtotal.
 *
 * @example <app-cart-item
 *            cart="$ctrl.cart"
 *            is-number-to-purchase-read-only="true"
 *            offer="$ctrl.offer"
 *            productList="$ctrl.productList"
 *            on-remove="$ctrl.onRemove"
 *            on-selected-change="$ctrl.onSelectedChange"
 *            restrict-promo-gaming="false",
 *            show-future-pricing="true"
 *            show-license-pricing="true"
 *            use-renewal-label="true"
 *            wait-for-total="true">
 *          </app-cart-item>
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appCartItem', {
    bindings: {
      cart: '<?',
      isNumberToPurchaseReadOnly: '<?',
      offer: '<',
      onRemove: '&?',
      onSelectedChange: '&?',
      positiveScorecard: '<?',
      productList: '<?',
      restrictPromoGaming: '<?',
      showFuturePricing: '<?',
      showLicensePricing: '<?',
      useRenewalLabel: '<?',
      waitForTotal: '<?',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/cart-item/cart-item.component.html',
  });

  /* @ngInject */
  function controller(
    $scope,
    $translate,
    _,
    feature,
    binkyPerTermPriceFilter,
    CONTRACT_BILLING_FREQUENCY,
    numberFilter,
    onesieSrc2,
    ProductPurchaseHelper,
    PURCHASE_WAITSTR
  ) {
    const vm = this;
    const MAXIMUM_PURCHASABLE_ITEMS = 500;

    let isPUF;

    _.assign(vm, {
      $onChanges,
      $onInit,
      getCartItemAction,
      getLicenseString,
      maxNumber: MAXIMUM_PURCHASABLE_ITEMS,
      onesieSrc2,
      onNumberInputChange,
      updateTotal,
      waitStr: PURCHASE_WAITSTR,
    });

    function $onInit() {
      $scope.$on('INPUT_VALUE_PENDING', onNumberInputPending);

      isPUF = vm.offer.term === CONTRACT_BILLING_FREQUENCY.ANNUAL;
      vm.showPufTotalTooltip = !vm.useRenewalLabel && isPUF;

      if (vm.offer.assignableLicenseCount) {
        // For Team Offers for owned products in the Add Products/License flows (showLicensePricing=true),
        // we should show the license pricing points and ownership. For other scenarios, we should show the
        // scorecard with the assigned licenses.
        if (vm.offer.isTeamOffer() && vm.showLicensePricing && vm.productList) {
          vm.ownedProduct = _.find(vm.productList.items, ['offerId', vm.offer.offer_id]);
        } else {
          vm.scorecardItem = _.isNumber(vm.offer.provisionedQuantity)
            ? ProductPurchaseHelper.constructLicenseScorecardItem(vm.offer, vm.positiveScorecard)
            : ProductPurchaseHelper.constructLicenseScorecardItem(vm.offer);
        }
      }

      if (feature.isEnabled('temp_renewal_reduce_gaming')) {
        vm.disableQuantityControls = vm.restrictPromoGaming && isItemAPromo();
      }

      vm.minNumber = vm.useRenewalLabel ? 0 : 1;
      vm.cartItemActionId = `id-${vm.offer.offer_id}`;

      resetNumberSelected();
    }

    function $onChanges() {
      if (feature.isEnabled('temp_renewal_reduce_gaming')) {
        vm.disableQuantityControls = vm.restrictPromoGaming && isItemAPromo();
      }
    }

    function getLicenseString() {
      return $translate.instant(
        `widgets.products.purchase.cartItem.readOnlyLicenses`,
        {
          count: numberFilter(vm.offer.numberSelected),
        },
        'messageformat'
      );
    }

    /**
     * @description determines if current cart item is a promo offer
     * @returns {Boolean} true if offer is promo, false otherwise.
     */
    function isItemAPromo() {
      return vm.offer.offer_type === 'PROMOTION';
    }

    // Callback is triggered by numberInput's change event which happens when the up/down arrows in the
    // numberInput are clicked.
    // Note that while we set numberToPurchase with an integer, Coral's numberinput converts it to a string.
    function onNumberInputChange() {
      updateOfferQty(_.toNumber(vm.numberToPurchase));
    }

    function resetNumberSelected() {
      vm.numberToPurchase = vm.offer.numberSelected;
    }

    function getCartItemAction() {
      const key = vm.useRenewalLabel ? 'renewing' : 'adding';
      if (vm.isNumberToPurchaseReadOnly || vm.disableQuantityControls) {
        return $translate.instant(`widgets.products.purchase.cartItem.${key}`);
      }
      return $translate.instant(`widgets.products.purchase.cartItem.${key}Licenses`);
    }

    function updateTotal() {
      if (vm.cart && vm.offer.numberSelected > 0) {
        const subTotalForOffer = vm.cart.getSubTotalPriceForOffer(vm.offer.offer_id, {
          includeTax: vm.offer.priceIncludesTax(),
          useProratedPricing: isPUF, // puf always points to prorated pricing. cart service will perform fallback
          useRenewalPricing: vm.showFuturePricing,
        });

        if (subTotalForOffer) {
          return binkyPerTermPriceFilter(subTotalForOffer, vm.offer.term);
        }
      }

      // 0 quantity, invalid quantity or cart error will show '--' for total price for item.
      return vm.waitStr;
    }

    ///////////////////

    // Event is emitted by binkyNumberInput when input is typed into the numberInput.
    function onNumberInputPending(valuePendingEvent, inputEvent) {
      let newValueAsNumber;

      // On IE, target.valueAsNumber isn't defined for this event so use value which is a string and
      // convert to number.
      const newValue = _.get(inputEvent, 'target.value');

      // Empty string when input is empty.
      if (newValue === '') {
        newValueAsNumber = NaN;
      } else {
        newValueAsNumber = _.toNumber(newValue);
        newValueAsNumber = _.clamp(newValueAsNumber, vm.minNumber, vm.maxNumber);

        // Updates the displayed input if number has been clamped.
        vm.numberToPurchase = newValueAsNumber;
      }

      updateOfferQty(newValueAsNumber);
    }

    function updateOfferQty(newValueAsNumber) {
      // NaN === NaN is false so we need an explicit check.
      if (_.isNaN(newValueAsNumber) && _.isNaN(vm.offer.numberSelected)) {
        return;
      }

      if (newValueAsNumber !== vm.offer.numberSelected) {
        vm.offer.numberSelected = newValueAsNumber;
        _.invoke(vm, 'onSelectedChange', {offer: vm.offer});
      }
    }
  }
})();
