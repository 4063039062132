(function () {
  /**
   * @ngdoc module
   * @name app.core.product.configurations.user-list
   * @description module that defines the ProductConfigurationUserList model
   */
  angular.module('app.core.product.configurations.user-list', [
    'app.core',
    'binky.core.api.jil',
    'binky.core.common.member',
    'binky.core.lodash',
  ]);
})();
