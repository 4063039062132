import {Flex, Text} from '@adobe/react-spectrum';
import {
  ModalContainer,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import Alert from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import DeactivateDevicesModalContent from './DeactivateDevicesModalContent';

// There is a modal divider attached to the ModalDescription component.
// However it applies unwanted padding on top of the divider line when there is no content.
// There is a theme in AC modals where descriptions are put above the divider line.
// UX follow the standard spectrum Dialog behaviour and want the content of a description to go below the divider line.
// As a result we are manually using a divider line here.
const CUSTOM_DESCRIPTION_STYLE = {
  dividerGap: 'size-0',
};

// ModalDialog has its own custom styling with width set to 1100px
// And in order to use size="M" we need to reset the styling
const CUSTOM_DIALOG_STYLE = {};

/**
 * Deactivate Devices Modal dialog. It lists the selected devices to deactivate.
 * The modal provides a primary and secondary call to action. The original intentaion for call to actions was:
 * The primary for deactivating selected devices, the secondary will cancel deactivation and close the modal.
 */
const DeactivateDevicesModal = ({
  isLoading,
  isOpen,
  onCancel,
  onDeactivate,
  selectedCount,
  selectedDevices,
}) => {
  const intl = useIntl();

  const cancelLabel = intl.formatMessage({
    id: 'products.details.devices.deactivateDevices.cancelButton',
  });
  const ctaLabel = intl.formatMessage({
    id: 'products.details.devices.deactivateDevices.messageFormat.deactivateButton',
  });

  const modalDialogContent = ModalDialogModel.createEntry({cancelLabel, ctaLabel});

  return (
    isOpen && (
      <ModalContainer>
        <ModalDialog
          content={modalDialogContent}
          dataTestId="deactivate-devices-modal"
          descriptionStyle={CUSTOM_DESCRIPTION_STYLE}
          dialogStyle={CUSTOM_DIALOG_STYLE}
          id="deactivate-devices-modal"
          isLoading={isLoading}
          onCancel={onCancel}
          onCta={onDeactivate}
          size="L"
        >
          <ModalHeading level={4}>
            <Flex alignItems="center">
              {intl.formatMessage(
                {id: 'products.details.devices.deactivateDevices.modalTitle'},
                {count: selectedCount}
              )}
              <Text marginEnd="0" marginStart="auto" />
              <Alert
                role="presentation"
                size="S"
                UNSAFE_style={{fill: 'var(--spectrum-semantic-notice-color-icon)'}}
              />
            </Flex>
          </ModalHeading>
          <ModalDescription />
          <ModalContent>
            <DeactivateDevicesModalContent
              selectedCount={selectedCount}
              selectedDevices={selectedDevices}
            />
          </ModalContent>
        </ModalDialog>
      </ModalContainer>
    )
  );
};

DeactivateDevicesModal.propTypes = {
  /**
   * Whether the component should display the loading indicator or not.
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Whether the modal dialog is shown or hidden.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Handler that is called when the user click on close button.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Handler that is called when the user click on deactivate button.
   */
  onDeactivate: PropTypes.func.isRequired,
  /**
   * count for number of devices selected to deactivate
   */
  selectedCount: PropTypes.number.isRequired,
  /**
   * list of selected devices name to deactivate
   */
  selectedDevices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeactivateDevicesModal;
