import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import DataCell from './DataCell';
import HeaderCell from './HeaderCell';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const Cell = observer(({header, children, ...cellProps}) =>
  header ? (
    <HeaderCell {...cellProps}>{children}</HeaderCell>
  ) : (
    <DataCell {...cellProps}>{children}</DataCell>
  )
);

Cell.propTypes = {
  header: PropTypes.bool,
};

export default Cell;
