import {JIL_CONSTANTS, JilModelList} from '@admin-tribe/binky';
import {useAsyncModel} from '@pandora/react-async-model';
import {SORT_ORDER, TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useReducer, useRef} from 'react';

import rootStore from 'core/RootStore';

/**
 * Custom hook that manages JilModelList state for non-alpha/non-hybrid lists
 * based on Pandora TableSection change events.
 * @param {Object} initStateOptions - to initialize the tableSectionChange reducer so it is in
 *   sync with the JilModelList.
 *   Options are: pageNumber, pageSize, sortExpression, sortOrder
 * @param {Class} ListClassRef - The JILModelList class
 * @param {Object} listOptions - Options to be added to the JILModelList get call in addition to
 *   these options: filterQuery, orgId, pageNumber, pageSize, sortExpression, sortOrder,
 *   which are set based on the listState.
 * @returns {Object} sortFields - This is needed to map the column keys to the JIL sort field.
 *   Object where each key is a Table column key and the value is
 *   the JIL sort field. For example, 'sortFields: {name: JIL_CONSTANTS.SORT.NAME}'
 */
const useJilModelList = ({
  initStateOptions = {},
  ListClassRef,
  listOptions = {},
  sortFields = {},
}) => {
  if (listOptions.state)
    throw new Error(`This hook only works for JilModelList with intrinsic pagination`);

  const isDescending = ({sortedOrder}) => sortedOrder === SORT_ORDER.DESC;

  // We reference an instance of JilModelList for providing default values
  const jilModelList = useRef(new JilModelList());

  const tableSectionChangeToJILModelListReducer = (state, {action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        return {
          ...state,
          pageNumber: state.pageNumber + 1,
        };
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        return {
          ...state,
          pageNumber: state.pageNumber - 1,
        };
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        return {
          ...state,
          pageNumber: 1,
          pageSize: payload,
        };
      case TABLE_SECTION_ACTIONS.ON_SORT_BY:
        return {
          ...state,
          pageNumber: 1,
          sortExpression: sortFields[payload.id],
          sortOrder: isDescending(payload) ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
        };
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        return {
          ...state,
          filterQuery: payload.value,
          pageNumber: 1,
        };
      case 'SET_DATA_ATTRIBUTES':
        return {
          ...state,
          sortExpression: sortFields[payload[0].id],
          sortOrder: isDescending(payload[0]) ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
        };
      case TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED:
        // The TableSection clears the selected items/keys before calling this.
        return {
          ...state,
          actionCounter: state.actionCounter + 1,
          pageNumber: 1,
        };
      default:
        return state;
    }
  };

  const orgId = rootStore.organizationStore.activeOrgId;

  const [listState, dispatchTableSectionChange] = useReducer(
    tableSectionChangeToJILModelListReducer,
    {
      actionCounter: 0,
      pageNumber: jilModelList.current.pagination.currentPage,
      pageSize: jilModelList.current.pagination.pageSize,
      sortExpression: jilModelList.current.sort.expression,
      sortOrder: jilModelList.current.sort.sortOrder,
      ...initStateOptions,
    }
  );

  const {actionCounter, filterQuery, pageNumber, pageSize, sortExpression, sortOrder} = listState;

  const fetchList = useCallback(async () => {
    const listInstance = await ListClassRef.get({
      filterQuery,
      orgId,
      pageNumber,
      pageSize,
      sortExpression,
      sortOrder,
      ...listOptions,
    });
    return listInstance;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We need actionCounter to refresh based on actions
  }, [
    actionCounter,
    filterQuery,
    ListClassRef,
    orgId,
    pageNumber,
    pageSize,
    sortExpression,
    sortOrder,
  ]);

  // We need initState so the first return value of the hook is compatible with the Table Section
  const {
    error,
    isLoading,
    model: listInstance,
  } = useAsyncModel({
    initState: {
      items: null, // null until initial load completes
      pagination: jilModelList.current.pagination,
      sort: jilModelList.current.sort,
    },
    loadFn: fetchList,
  });

  return {
    dispatchTableSectionChange,
    error,
    isLoading,
    list: listInstance,
    listState, // should only be used for testing
  };
};

export default useJilModelList;
