(function () {
  /**
   * @ngdoc module
   * @name app.widgets.offers.free-offer-details-form
   * @description module that defines the free offer details widget
   */
  angular.module('app.widgets.offers.free-offer-details-form', [
    'app.core.organizations.organization-manager',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.offers.fulfillable-items',
    'binky.core.product',
    'binky.widgets.products.product-icon',
  ]);
})();
