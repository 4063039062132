import {GoUrl, ImageIcon} from '@admin-tribe/binky-ui';
import {Heading} from '@adobe/react-spectrum';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './SelectIdpCard.pcss';

const SelectIdpCard = ({description, goUrlLabel, id, logo, selected, title}) => {
  const intl = useIntl();

  return (
    <Card allowsSelection={false} data-testid={id} selected={selected} styleName="card">
      <div styleName="card-body">
        <ImageIcon alt="" size="XL" src={logo} styleName="provider-card-logo" />
        <Heading marginX="0" marginY="8px">
          <FormattedMessage id={title} />
        </Heading>
        <p>
          <FormattedMessage
            id={description}
            values={{
              b:
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                // istanbul ignore next -- not testing this
                (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </p>

        <GoUrl aria-label={intl.formatMessage({id: goUrlLabel})} name="setup_directory">
          <FormattedMessage id={goUrlLabel} />
        </GoUrl>
      </div>
    </Card>
  );
};

SelectIdpCard.propTypes = {
  description: PropTypes.string.isRequired,
  goUrlLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default SelectIdpCard;
