/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSupportTicketActionModal
 *
 * @description Modal which allows the user to perform an action on a support ticket, providing a
 *  reason code and optional description why.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketActionModal', {
    bindings: {
      action: '@',
      modalHeaderKey: '@',
      modalId: '@',
      onSuccess: '&',
      reasons: '<',
      reasonsLabelKey: '@',
      reasonsPlaceholderKey: '@',
      reasonSummaryLabelKey: '@',
      reasonSummaryPlaceholderKey: '@',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-action-modal/support-ticket-action-modal.component.html',
  });

  function controller(_, $q, AnalyticsEvent, DisplayMessage, panelManager) {
    const vm = this;

    _.assign(vm, {
      displayMessage: null,
      isFormValid,
      onClickCancel,
      onClickSubmit,
      onOpen,
      onReasonChange,
      waitPromise: $q.resolve(),
    });

    function isFormValid() {
      return _.isNumber(vm.selectedReasonCode) && vm.reasonSummary;
    }

    function onClickCancel() {
      panelManager.close(vm.modalId);
      AnalyticsEvent.dispatch({
        componentMethod: 'cancelButton',
        componentMethodType: 'click',
        componentName: vm.modalId,
      });
    }

    function onClickSubmit() {
      vm.isBusy = true;
      hideErrorBanner();
      vm.waitPromise = vm.supportTicket
        .performAction({
          action: vm.action,
          reasonCode: vm.selectedReasonCode,
          reasonSummary: vm.reasonSummary,
        })
        .then(onSuccess, showErrorBanner)
        .finally(() => {
          vm.isBusy = false;
        });

      function onSuccess() {
        panelManager.close(vm.modalId);
        vm.onSuccess();
      }
    }

    function onOpen() {
      hideErrorBanner();
      _.assign(vm, {
        reasonSummary: '',
        selectedReasonCode: null,
      });
    }

    function onReasonChange(selectedReasonCode) {
      vm.selectedReasonCode = selectedReasonCode;
    }

    //////////////

    function hideErrorBanner() {
      vm.displayMessage = null;
    }

    function showErrorBanner() {
      vm.displayMessage = new DisplayMessage();
    }
  }
})();
