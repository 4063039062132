import rootStore from 'core/RootStore';
import {canViewValueDiscoveryTasksForEnterprise} from 'core/organizations/access/organizationAccess';
import {canAssignDeveloper} from 'core/products/access/productAccess';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

class VisitAdobeioHva extends HvaCard {
  static get({intl}) {
    if (
      !canViewValueDiscoveryTasksForEnterprise() ||
      !rootStore.organizationStore.productList.items.some(canAssignDeveloper)
    ) {
      return Promise.reject();
    }

    return Promise.resolve([new VisitAdobeioHva({intl})]);
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.visitAdobeIO';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/adobe-io.svg',
        style: {width: '40px'},
      },
      id: 'hva-visit-adobeio',
      isDismissible: true,
      onCTA: () => {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- callback will always run in browser
        window.open(
          'https://www.adobe.com/go/aac_pop_artt_developers',
          '_blank',
          'noopener,noreferrer'
        );
      },
      priorityOrder: HVA_ORDER.VISIT_ADOBE_IO,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default VisitAdobeioHva;
