// Item keys for the MoreOptionsMenu
const ACTIONS_MENU_ITEMS = {
  MANAGE_POLICY: 'managePolicy',
  VIEW_DETAILS: 'viewDetails',
};

const COLUMN_KEY = {
  ACTIONS: 'actions',
  NAME: 'name',
  PLATFORM: 'platform',
  PUBLISHER: 'publisher',
  STATUS: 'status',
};

const ALL_COLUMNS_ORDERED = [
  {
    key: COLUMN_KEY.NAME,
    locKey: 'products.appIntegrations.appIntegrationsTable.column.name',
  },
  {
    key: COLUMN_KEY.ACTIONS,
    locKey: 'products.appIntegrations.appIntegrationsTable.column.actions',
  },
  {
    key: COLUMN_KEY.PUBLISHER,
    locKey: 'products.appIntegrations.appIntegrationsTable.column.publisher',
  },
  {
    key: COLUMN_KEY.PLATFORM,
    locKey: 'products.appIntegrations.appIntegrationsTable.column.platform',
  },
  {
    key: COLUMN_KEY.STATUS,
    locKey: 'products.appIntegrations.appIntegrationsTable.column.status',
  },
];

// Get keys for all columns in correct order.
const ALL_COLUMN_KEYS = ALL_COLUMNS_ORDERED.map((col) => col.key);

// A subset of column keys to determine which columns will be displayed by default.
const DEFAULT_COLUMNS = [COLUMN_KEY.NAME, COLUMN_KEY.PUBLISHER, COLUMN_KEY.PLATFORM];

// Viewing modes of the AppIntegrationsTable.
// Each essentially represents the query params for /application-integrations:
// DEFAULT = no query params
// HAS_ACCEPTED_USERS = ?has_accepted_users=true
// HAS_POLICIES = ?has_policies=true
const TABLE_MODE = {
  DEFAULT: 'DEFAULT',
  HAS_ACCEPTED_USERS: 'HAS_ACCEPTED_USERS',
  HAS_POLICIES: 'HAS_POLICIES',
};

export {
  ACTIONS_MENU_ITEMS,
  ALL_COLUMNS_ORDERED,
  ALL_COLUMN_KEYS,
  COLUMN_KEY,
  DEFAULT_COLUMNS,
  TABLE_MODE,
};
