(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name app.core.storage-reports:StorageReportFolder
   * @description Model for a storage report folder
   */
  angular
    .module('app.core.storage-report-folder')
    .factory('StorageReportFolder', getStorageReportFolderModel);

  /* @ngInject */
  function getStorageReportFolderModel($q, _, MODEL, modelCache, STORAGE_QUOTA_TYPE, StorageQuota) {
    class StorageReportFolder {
      /**
       * @description Creates a new StorageReportFolder for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.id A StorageReportFolder's ID
       * @param {String} options.name A StorageReportFolder's name
       * @param {String} options.path the path of the StorageReportFolder
       * @param {StorageQuota} options.quota the storage quota of the StorageReportFolder
       * @param {Date} options.repositoryCreatedDate The creation date of the StorageReportFolder
       * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageReportFolder
       * @param {String} options.state the state of the StorageReportFolder
       * @param {String} options.type the type of the file which StorageReportFolder is stored
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        return {
          id: this.id,
        };
      }

      /**
       * @class
       * @description Transforms the /organizations StorageReportFolder response Object into a new
       *   StorageReportFolder instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageReportFolder(dataTransferObject);
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes Storage Report Folder data.
     *
     * @param {Object} model StorageReportFolder model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options = {}) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick([
            'id',
            'name',
            'path',
            'repositoryCreatedDate',
            'repositoryLastModifiedDate',
            'state',
            'type',
          ])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );

      if (!model.quota) {
        model.quota = new StorageQuota(
          _.defaults(options.quota, {enforcement: STORAGE_QUOTA_TYPE.HARD})
        );
      }

      // The name send back from the server could be encoded
      if (model.name) {
        model.name = decodeURI(model.name);
      }

      // setup model as already resolved which ensures it can be used
      // interchangably when fetched from the storage-report-folder list or instantiated
      // independently
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    // We register the cache size for this class
    modelCache.register(MODEL.STORAGEREPORTFOLDER, 10);
    modelCache.register(MODEL.STORAGEREPORTFOLDERMETADATA, 1);

    return StorageReportFolder;
  }
})();
