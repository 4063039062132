import {DialogContainer} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import ButtonWithContextualHelp from 'features/settings/common/components/button-with-contextual-help/ButtonWithContextualHelp';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import CreateDirectoryModal from 'features/settings/components/directories/create-directory-modal/CreateDirectoryModal';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

const CreateDirectoryButton = observer(({clearDirectoryCache, goToSetupDomains}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {canCreateDirectory} = useDirectoryListContext();

  const canManageIdentityConfig = canChangeIdentityConfig();

  // If we're in the middle of an external login
  // check to see what action is being done (adding azure IdP or editing an existing one)
  // and open the respective modal
  useEffect(() => {
    if (ExternalAuthService.isLoginInProgress()) {
      const stateData = ExternalAuthService.getStateData();

      if (stateData?.isAddingAzureIdp) {
        setIsDialogOpen(true);
      }
    }
  }, []);

  return (
    <>
      <ButtonWithContextualHelp
        contextualHelp={canManageIdentityConfig ? null : <GlobalAdminPolicyActionDisabled />}
        data-testid="create-directory-btn"
        isDisabled={!canManageIdentityConfig || !canCreateDirectory}
        onPress={() => setIsDialogOpen(true)}
        variant="accent"
      >
        <FormattedMessage id="settings.directories.createDirectoryButton.label" />
      </ButtonWithContextualHelp>

      <DialogContainer onDismiss={() => setIsDialogOpen(false)}>
        {isDialogOpen && (
          <CreateDirectoryModal
            clearDirectoryCache={clearDirectoryCache}
            goToSetupDomains={goToSetupDomains}
          />
        )}
      </DialogContainer>
    </>
  );
});

CreateDirectoryButton.propTypes = {
  clearDirectoryCache: PropTypes.func,
};

export default CreateDirectoryButton;
