(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc directive
   * @name app.widgets.common.ordinal-icon:appOrdinalIcon
   *
   * @description A directive for displaying number icons inside of a circle.
   *   Due to time constraints, this directive should currently only be used to
   *   display single digits. In the future, we may want to provide handling for
   *   multi-digit numbers (e.g. - 13, 409). However, before this can be
   *   achieved, care will need to be taken to either:
   *
   *   1. Find a dynamic solution to resizing the element dimensions based on
   *      the actual rendered number height/width (must support initial
   *      rendering being 0x0, since current use is inside of an accordion
   *      item), or...
   *   2. Determine a method to estimate the width of multi-digit numbers to
   *      dynamically set additional styles that will either enlarge or shrink
   *      the containing circle or the digits inside
   *
   * @param {Number} number the number to display inside of a circle
   *
   * @example <app-ordinal-icon number='5'></app-ordinal-icon>
   */
  angular
    .module('app.widgets.common.ordinal-icon')
    .directive('appOrdinalIcon', appOrdinalIconDirective);

  /* @ngInject */
  function appOrdinalIconDirective($compile) {
    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        number: '<',
      },
    };

    return directive;

    function linkFn(scope, element) {
      // initial ordinal icon creation
      createOrdinalIcon();

      // if our data changes (say on org switch), recreate the accordion
      scope.$watch('number', createOrdinalIcon);

      function createOrdinalIcon() {
        const cardContent = `<div class="border" tabindex="-1"><span class="ordinal" tabindex="-1">${scope.number}</span></div>`;

        const contentElement = $compile(cardContent)(scope);

        element.empty().append(contentElement);
      }
    }
  }
})();
