/**
 * @deprecated use src2 Support pages
 *
 * @description a filter to get the target audience ("For Administrators" or "For Product Users")
 *   for an expert session
 * @param {Object} expertSession the expert session
 * @returns {String} the localized target audience string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.support')
    .filter('appExpertSessionTargetAudience', appExpertSessionTargetAudience);

  /* @ngInject */
  function appExpertSessionTargetAudience($translate, _, SUPPORT_TICKET_CASE_TYPE) {
    const stringKeyMap = {
      [SUPPORT_TICKET_CASE_TYPE.ADMIN]: 'forAdministrators',
      [SUPPORT_TICKET_CASE_TYPE.PRODUCT]: 'forProductUsers',
    };
    return filterFn;

    function filterFn(expertSession) {
      const key = stringKeyMap[_.toUpper(expertSession.caseType)];
      return key
        ? $translate.instant(`widgets.support.expertSessionTargetAudienceFilter.${key}`)
        : '';
    }
  }
})();
