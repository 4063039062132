import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import ContentLogsStore from '../stores/ContentLogsStore';

/**
 * Private Context for the ContentLogsPage and its sub-components.
 * Used to pass the contentLogsStore between sub-components.
 */

const ContentLogsContext = createContext(null);
const useContentLogsContext = () => useContext(ContentLogsContext);

const ContentLogsContextProvider = ({children, contentLogsStore}) => {
  const value = useMemo(() => ({contentLogsStore}), [contentLogsStore]);

  return <ContentLogsContext.Provider value={value}>{children}</ContentLogsContext.Provider>;
};

ContentLogsContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
  /**
   * The store for Content Logs.
   */
  contentLogsStore: PropTypes.instanceOf(ContentLogsStore).isRequired,
};

export {ContentLogsContextProvider, useContentLogsContext};
