import {
  Column,
  Content,
  Heading,
  IllustratedMessage,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EMPTY_TABLE_TYPE} from '../profilePermissionsConstants';

/**
 * Displays an empty result when no permissions have been found.
 */
const PermissionsEmptyTable = ({tableType}) => {
  const intl = useIntl();
  const headingMessage = intl.formatMessage({
    id: `products.productProfilePermissions.emptyTable.${tableType}.heading`,
  });
  const contentMessage = intl.formatMessage({
    id: `products.productProfilePermissions.emptyTable.${tableType}.content`,
  });

  return (
    <TableView
      aria-label={intl.formatMessage({
        id: 'products.productProfilePermissions.emptyTable.ariaLabel',
      })}
      height="size-5000"
      marginTop="size-300"
      renderEmptyState={() => (
        <IllustratedMessage>
          {tableType === EMPTY_TABLE_TYPE.NO_PERMISSIONS ? <NotFound /> : <NoSearchResults />}
          <Heading>{headingMessage}</Heading>
          <Content>{contentMessage}</Content>
        </IllustratedMessage>
      )}
    >
      <TableHeader>
        <Column>
          {intl.formatMessage({
            id: 'products.productProfilePermissions.table.name',
          })}
        </Column>
        <Column hideHeader showDivider>
          {intl.formatMessage({
            id: 'products.productProfilePermissions.table.edit',
          })}
        </Column>
        <Column>
          {intl.formatMessage({
            id: 'products.productProfilePermissions.table.items',
          })}
        </Column>
        <Column>
          {intl.formatMessage({
            id: 'products.productProfilePermissions.table.description',
          })}
        </Column>
      </TableHeader>
      <TableBody>{[]}</TableBody>
    </TableView>
  );
};

PermissionsEmptyTable.propTypes = {
  /**
   * It decides what type of empty table should be displayed based on whether results are filtered by search
   */
  tableType: PropTypes.oneOf([EMPTY_TABLE_TYPE.NO_PERMISSIONS, EMPTY_TABLE_TYPE.NO_SEARCH_RESULTS])
    .isRequired,
};

export default PermissionsEmptyTable;
