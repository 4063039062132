const GLOBAL_BANNER_EVENT = {
  MODAL: {
    OPEN: 'GLOBAL_BANNER.MODAL.OPEN',
  },
};
const GLOBAL_BANNER_PRIORITY = {
  COMPLIANCE: {
    // These are arbitrary numbers just so that we can get the past due banners to
    // show up before the grace past due banners.
    GRACE_PAST_DUE_PRIORITY: 49,
    PAST_DUE_PRIORITY: 50,
  },
  CONTRACT_MIGRATIONS: 100,
};
const GLOBAL_BANNER_TYPES = {
  /** A red global banner */
  ERROR: 'error',
  /** A gray global banner */
  INFO: 'info',
  /** A blue global banner */
  WARNING: 'warning',
};
export {GLOBAL_BANNER_EVENT, GLOBAL_BANNER_PRIORITY, GLOBAL_BANNER_TYPES};
