(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.button-with-tooltip').component('appButtonWithTooltip', {
    bindings: {
      buttonDisabled: '<?',
      buttonIcon: '@?',
      buttonLabel: '@?',
      buttonVariant: '@?',
      onButtonClick: '&',
      tooltipContentString: '@',
      tooltipPlacement: '@?',
      tooltipVariant: '@?',
    },
    controller,
    templateUrl: 'app/widgets/common/button-with-tooltip/button-with-tooltip.component.html',
  });

  /* @ngInject */
  function controller(_, $element) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      $postLink,
      canTabToContainer,
      closeTooltip,
      isTooltipOpen: false,
      openTooltip,
    });

    function $onInit() {
      vm.tooltipTextId = vm.tooltipContentString ? _.uniqueId('button-tooltip-') : null;
    }

    function $postLink() {
      Coral.commons.ready(() => {
        const placement = vm.tooltipPlacement || 'bottom';
        const coralTooltip = _.head($element.find('coral-tooltip'));
        if (coralTooltip) {
          coralTooltip.placement = placement;
        }
      });
    }
    /**
     * If the button is disabled, but there is still a tooltip to be displayed, then allow the user
     * to tab to the button container in order to pop the tooltip.
     *
     * @returns {Boolean} true if the user should be able to tab to the button container
     */
    function canTabToContainer() {
      return vm.buttonDisabled && !_.isEmpty(vm.tooltipContentString);
    }

    function closeTooltip() {
      vm.isTooltipOpen = false;
    }

    function openTooltip() {
      vm.isTooltipOpen = true;
    }
  }
})();
