import {Content, Header} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React from 'react';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {findFulfillableItem} from 'core/services/product/product-group/product-list/ProductGroupProductListUtils';
import ProductDetailsDrawerHeader from 'features/products/components/product-details-drawer/product-details-drawer-header/ProductDetailsDrawerHeader';
import ProductLinksDetailSection from 'features/products/components/product-links-detail-section/ProductLinksDetailSection';

const PRODUCT_GROUPS_DETAILS_DRAWER_ID = 'product-groups-details-drawer-id';

/**
 * @description Drawer component for product group (consumable/transaction) details
 * @param {ProductGroup} productGroup - data model to build drawer
 * @returns {JSX} drawer to be used for product group details
 */
const ProductGroupsDetailsDrawer = ({productGroup}) => {
  const fiProductGroup = findFulfillableItem(productGroup.items, productGroup.id);
  const detailLinks = fiProductGroup?.links?.detailed_help_admin;

  return (
    <Drawer aria-labelledby={PRODUCT_GROUPS_DETAILS_DRAWER_ID}>
      <Header marginBottom="size-350">
        <ProductDetailsDrawerHeader
          id={PRODUCT_GROUPS_DETAILS_DRAWER_ID}
          productOrProductGroup={fiProductGroup}
        />
      </Header>
      <Content>
        {detailLinks?.length > 0 && <ProductLinksDetailSection links={detailLinks} />}
      </Content>
    </Drawer>
  );
};

ProductGroupsDetailsDrawer.propTypes = {
  /**
   * The product group product list that will be used to fetch a fulfillable
   * item from and populate drawer content (links)
   */
  productGroup: PropTypes.instanceOf(ProductGroupProductList).isRequired,
};

export default ProductGroupsDetailsDrawer;
