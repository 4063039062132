import {ImageIcon} from '@admin-tribe/binky-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import {TBody, TD, TH, TR, Table} from '@react/react-spectrum/Table';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import './ProductUpgradeSummarySection.pcss';

/**
 * A widget to display product upgrade summary
 */
const ProductUpgradeSummarySection = ({productGroups}) => {
  const intl = useIntl();

  const renderProductNameCell = (product) => (
    // the padding top of the first name cell should be 12px
    <TD>
      <Flex key={product.id} gap="size-100">
        <ImageIcon alt="" size="S" src={product.iconUrl} />
        <div styleName="product-name">
          <Text>{product.displayName}</Text>
          {Number.isInteger(product.count) && (
            <>
              <span>&nbsp;</span>
              <span styleName="count">{`(${intl.formatNumber(product.count)})`}</span>
            </>
          )}
        </div>
      </Flex>
    </TD>
  );

  const renderProductGroup = (productGroup) => {
    const productList = productGroup.productList;

    return (
      productList?.length > 0 && (
        <React.Fragment key={productList[0].id}>
          {/* the first row of a product group should contain header */}
          <TR>
            <TH rowSpan={productList.length} scope="rowgroup">
              <Text>{productGroup.title}</Text>
            </TH>
            {renderProductNameCell(productList[0])}
          </TR>
          {productList.slice(1).map((product) => (
            <TR key={product.id}>{renderProductNameCell(product)}</TR>
          ))}
        </React.Fragment>
      )
    );
  };

  return (
    <Table styleName="product-upgrade-summary-table">
      <caption>
        <FormattedMessage id="products.upgradeSummarySection.header" />
      </caption>
      <TBody>{productGroups.map((productGroup) => renderProductGroup(productGroup))}</TBody>
    </Table>
  );
};

ProductUpgradeSummarySection.propTypes = {
  /**
   * The product groups to display in product upgrade summary section
   */
  productGroups: PropTypes.arrayOf(
    PropTypes.shape({
      productList: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * The count of a product
           */
          count: PropTypes.number,
          /**
           * The display name of a product
           */
          displayName: PropTypes.string,
          /**
           * The icon url of a product
           */
          iconUrl: PropTypes.string,
          /**
           * The id of a product
           */
          id: PropTypes.string.isRequired,
        })
      ),
      /**
       * The title of a product group
       */
      title: PropTypes.string,
    })
  ),
};

export default ProductUpgradeSummarySection;
