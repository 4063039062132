import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const DismissChangesAlertModal = ({onConfirm}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.common.dismissChangesAlertModal.buttons.dismissChanges',
      })}
      title={intl.formatMessage({id: 'settings.common.dismissChangesAlertModal.title'})}
      variant="warning"
    >
      <Text>
        <FormattedMessage id="settings.common.dismissChangesAlertModal.description" />
      </Text>
    </ModalAlertDialog>
  );
};

DismissChangesAlertModal.propTypes = {
  onConfirm: PropTypes.func,
};

export default DismissChangesAlertModal;
