import {DialogContainer, Flex, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import SectionView from 'features/settings/common/components/section-view/SectionView';
import {checkDeIsEnabled} from 'features/settings/common/utils/domainEnforcementUtils';
import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import ContextualHelpButton from 'features/settings/components/directory/domain-enforcement/components/ContextualHelpButton';
import ExceptionListModal from 'features/settings/components/directory/domain-enforcement/exclude-users-section/ExceptionListModal';

const ExcludeUsersSection = () => {
  const intl = useIntl();
  const {domainEnforcement} = useDomainEnforcementContext();
  const isDeEnabled = checkDeIsEnabled(domainEnforcement.state);

  const [showExceptionListModal, setShowExceptionListModal] = useState(false);

  const buttonLabel = intl.formatMessage({
    id: 'settings.domainEnforcement.excludeUsersSection.button',
  });
  const titleLabel = intl.formatMessage({
    id: 'settings.domainEnforcement.excludeUsersSection.popover.title',
  });
  const descriptionLabel = intl.formatMessage({
    id: 'settings.domainEnforcement.excludeUsersSection.popover.description',
  });

  return (
    <SectionView>
      <Flex direction="column" gap="size-200" height="100%">
        <Text>
          <FormattedMessage
            id="settings.domainEnforcement.excludeUsersSection.description"
            values={{
              goUrl: (linkText) => (
                <GoUrl
                  name="aac_restricting_domains_exception_list"
                  UNSAFE_style={{color: 'inherit'}}
                >
                  {linkText}
                </GoUrl>
              ),
            }}
          />
        </Text>
        <ContextualHelpButton
          buttonLabel={buttonLabel}
          helpDescriptionLabel={descriptionLabel}
          helpTitleLabel={titleLabel}
          // Disabled if DE is not ENFORCED or ACTIVATED
          isDisabled={!isDeEnabled}
          onPress={() => setShowExceptionListModal(true)}
        />
      </Flex>
      <DialogContainer
        onDismiss={() => {
          setShowExceptionListModal(false);
        }}
      >
        {showExceptionListModal && <ExceptionListModal />}
      </DialogContainer>
    </SectionView>
  );
};

export default ExcludeUsersSection;
