(function () {
  /**
   * @deprecated Please use src2/app/core/chat/chatProvider.js
   *
   * @ngdoc provider and service
   * @name app.core.chat
   * @description downloads the Adobe chat client library and provides methods for interacting
   *   with it.
   * @see {@link https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1497396194}
   */

  angular.module('app.core.chat').provider('chat', chatProvider);

  /* @ngInject */
  function chatProvider(_) {
    let chatConfig, clientId;

    _.assign(this, {
      $get,
      configure,
    });

    ////////////

    function configure(chatConfigObj, imsClientId) {
      chatConfig = chatConfigObj;
      clientId = imsClientId;
    }

    /* @ngInject */
    function $get(
      $log,
      $q,
      $window,
      authentication,
      CHAT_APP_ID,
      CHAT_USER_ROLE,
      domUtils,
      locale,
      OrganizationManager,
      supportAccess
    ) {
      let isChatInitalized = false;
      let isInFakeMode = false;
      let appid = CHAT_APP_ID.ONESIE1;

      const service = {
        canDisplayChat,
        closeMessageWindow,
        initialize,
        isInitialized,
        openMessagingWindow,
        reinitialize,
      };

      return service;

      ////////////

      /**
       * @description Method to determine if the Jarvis chat can be displayed
       *
       * @returns {Boolean} true if chat can be displayed
       */
      function canDisplayChat() {
        return isInFakeMode
          ? isChatInitalized
          : isChatInitalized && _.invoke($window.adobePrivacy, 'hasUserProvidedConsent');
      }

      function initialize() {
        return downloadChatLib()
          .then(initializeChatLib)
          .then(() => {
            isChatInitalized = true;
          });
      }

      function isInitialized() {
        return isChatInitalized;
      }

      /**
       * Surfaces can use this API to close the Jarvis Chat window
       */
      function closeMessageWindow() {
        $window.AdobeMessagingExperienceClient.closeMessageWindow();
      }

      /**
       * Surfaces can use this API to open Jarvis window
       * Example:
       * sourceInfo: {
       *   componentid: 'utilnav', identifier of the section triggering cta click
       *   componentver: '1.0', version of the section triggering cta click
       *   sourceType: 'button', sourceType: button, link, img, etc.
       *   sourceText: 'Message Us' text on button, link, etc. that is passed to Jarvis
       * }
       *
       * componentid | Optional | If not specified, value specified in init call will be used
       * componentver | Optional |If not specified, value specified in init call will be used
       * sourceType | Mandatory | button, link, img
       * sourceText | Optional | Should specify 'alt' text for img sourceType
       *
       * NOTE: By Mandatory, we mean that events will not be sent unless this information is provided
       * On the first CTA click (or after a conversation end), this also makes a call to callbacks.getContextCallback method.
       * This is an opportunity for the surface to update certain parameters like appid, appver etc.
       * @param {Object} options - updates any information from the original initialize call
       * {String} options.accessToken - used to authenticate the chat request
       * {String} options.appid - unique identifier used to configure the chat request
       * {String} options.appver - version of the chat client to use, should be '1.0'
       * {Object} options.callbacks - object containing callback functions
       * {String} options.clientId - the IMS client id
       * {Object} options.context - object that usually contains the orgId (organization id),
       * {Boolean} options.cookiesEnabled - if cookies are enabled or not
       * {String} options.env - gets the environment config for stage, prod, etc.
       * {String} options.language - sets the language to determine what language the agent should converse in
       * {String} options.region - sets the region for where the request for assistance is coming from
       */
      function openMessagingWindow(options) {
        appid = options.appId || CHAT_APP_ID.ONESIE1;
        const sourceInfo = _.omit(options, 'appId');
        $window.AdobeMessagingExperienceClient.openMessagingWindow(sourceInfo);
      }

      /**
       * After sending the initialize, and before user starts the chat session,
       * if surface wants to update the data sent in initialize call like appid, accesstoken, language, region etc.,
       * they can call the reinitialize api and send only the parameters which needs to be updated.
       * (same as getContextCallback api)
       * @param {Object} options - updates any information from the original initialize call
       * {String} options.accessToken - used to authenticate the chat request
       * {String} options.appid - unique identifier used to configure the chat request
       * {String} options.appver - version of the chat client to use, should be '1.0'
       * {Object} options.callbacks - object containing callback functions
       * {String} options.clientId - the IMS client id
       * {Object} options.context - object that usually contains the orgId (organization id),
       * {Boolean} options.cookiesEnabled - if cookies are enabled or not
       * {String} options.env - gets the environment config for stage, prod, etc.
       * {String} options.language - sets the language to determine what language the agent should converse in
       * {String} options.region - sets the region for where the request for assistance is coming from
       * @returns {Promise} same return object as initialize
       */
      function reinitialize(options) {
        if (_.has($window, 'AdobeMessagingExperienceClient.reinitialize')) {
          appid = options.appId || CHAT_APP_ID.ONESIE1;
          options.appid = appid;
          const sourceInfo = _.omit(options, 'appId');
          return $window.AdobeMessagingExperienceClient.reinitialize(sourceInfo);
        }

        return $log.error('Error reinitialize chat: method not present', options);
      }

      ////////////

      function downloadChatLib() {
        if ($window.AdobeMessagingExperienceClient) {
          // If the chat library is already loaded then we are in fakes mode - do not load the
          // real library or wait for privacy consent
          isInFakeMode = true;
          return $q.resolve();
        } else if (_.invoke($window.adobePrivacy, 'hasUserProvidedConsent') && chatConfig) {
          // The chat library is not already loaded, so download and initialize it
          const cssUrl = `${chatConfig.url}/latest/AdobeMessagingClient.css`;
          const jsUrl = `${chatConfig.url}/latest/AdobeMessagingClient.js`;
          return $q.all([domUtils.loadCss(cssUrl), domUtils.loadJs(jsUrl)]);
        }
        // There is no chat config, meaning chat is not supported in this environment (e.g. QA)
        // or consent has not been given
        return $q.reject();
      }

      function initializeChatLib() {
        return $q((resolve, reject) => {
          const [language, region] = _.split(locale.getActiveLocaleCode(), '_');

          const options = {
            accessToken: `Bearer ${authentication.getAccessToken()}`,
            appid,
            appver: '1.0',
            callbacks: {
              getContextCallback,
              initCallback,
              initErrorCallback,
              onReadyCallback,
            },
            clientId,
            context: {orgId: OrganizationManager.getActiveOrgId()},
            cookiesEnabled: true,
            // When running in fakes mode on QA, chatConfig is undefined yet we'll still reach this
            // code. Avoid the NPE.
            env: _.get(chatConfig, 'env'),
            language,
            region,
          };

          $window.AdobeMessagingExperienceClient.initialize(options);

          ///////////

          function getContextCallback() {
            const userRole = supportAccess.canAccessEnterpriseSupport()
              ? CHAT_USER_ROLE.ENTERPRISE_ADMIN
              : CHAT_USER_ROLE.TEAM_ADMIN;
            return {
              appid,
              orgId: OrganizationManager.getActiveOrgId(),
              userRole,
            };
          }

          function initCallback() {
            if (!$window.AdobeMessagingExperienceClient.isAdobeMessagingClientEnabled()) {
              $log.info('Skipping chat initialization - chat client is currently disabled');
              reject();
            }
          }

          function initErrorCallback(err) {
            $log.error('Error intializing chat: initErrorCallback was invoked', err);
            reject();
          }

          function onReadyCallback() {
            resolve();
          }
        });
      }
    }
  }
})();
