(function () {
  /**
   * @deprecated ported to src2/app/common/components/migrate-device-license
   */
  angular.module('app.widgets.contracts.migrations').component('appProductLicensesQuantityList', {
    bindings: {
      isMigrationSource: '<',
      migrationLicenses: '<',
    },
    templateUrl:
      'app/widgets/contracts/migrations/product-licenses-quantity/product-licences-quantity-list.component.html',
  });
})();
