import {csvBlobTransformer, getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

let clientId, includeRoles, url;

/**
 * Configure JIL asset migrations API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL Products api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Fetches the asset migrations
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @returns {Promise} Resolves to the axios response object.
 */
const exportMigrations = async ({orgId}) => {
  const response = await axios.get(`${url}/${orgId}:migrate.assets`, {
    headers: {
      Accept: 'text/csv,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
  });
  return csvBlobTransformer(response);
};

const jilAssetMigrations = {
  configure,
  exportMigrations,
};

export default jilAssetMigrations;
