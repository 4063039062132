import {TooltipButton} from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Info tooltip which is displayed next to the billing history header.
 */
const BillingHistoryInfoTooltip = ({tooltipText}) => (
  <Flex>
    <TooltipButton hoverText={tooltipText} isQuiet variant="action">
      <InfoIcon size="S" />
    </TooltipButton>
  </Flex>
);

BillingHistoryInfoTooltip.propTypes = {
  /**
   * Text that appears on hovering over the button.
   */
  tooltipText: PropTypes.string.isRequired,
};

export default BillingHistoryInfoTooltip;
