import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {FormattedDateTime} from '@pandora/react-formatted-date-time';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import EduProviderLogo from 'features/settings/common/components/edu-provider-logo/EduProviderLogo';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const ResumeRosterSyncConfirmationModal = ({rosterSource, syncId}) => {
  const intl = useIntl();
  const dateToday = new Date();

  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);

  const {updateRosterSyncData} = useRosterSyncContext();
  const {resumeRosterSyncAction} = useEduRosterSync();

  const handleResumeSync = async () => {
    setIsLoading(true);

    try {
      const response = await resumeRosterSyncAction({syncId});
      const updatedSyncData = response.data;

      updateRosterSyncData({syncId, updatedSyncData});

      showSuccessToast(intl.formatMessage({id: `settings.sync.eduSync.toast.resumeSync`}));

      dialog.dismiss();
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.error'}));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.resumeSyncModal.buttons.cancel',
      })}
      data-test-id="resume-edu-roster-sync-modal"
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleResumeSync}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.resumeSyncModal.buttons.resumeSync',
      })}
      title={intl.formatMessage({
        id: 'settings.sync.eduSync.resumeSyncModal.title',
      })}
      variant="confirmation"
    >
      <View>
        <EduProviderLogo rosterSource={rosterSource} />
      </View>
      {intl.formatMessage(
        {id: 'settings.sync.eduSync.resumeSyncModal.description'},
        {
          b: (chunks) => <Text UNSAFE_style={{fontWeight: 'bold'}}>{chunks}</Text>,
          date: <FormattedDateTime customFormat={{weekday: 'long'}} date={dateToday} />,
        }
      )}
    </ModalAlertDialog>
  );
};

ResumeRosterSyncConfirmationModal.propTypes = {
  rosterSource: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
};

export default ResumeRosterSyncConfirmationModal;
