/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appSessionTypeSelectionModal
 *
 * @description The modal to select the type of expert session to request. Once the session type is selected, and
 *  the 'Request Expert Session' button is clicked, the Request Expert Session modal will open to allow a session to be created.
 */
(function () {
  const componentName = 'appSessionTypeSelectionModal';
  angular.module('app.widgets.support').component(componentName, {
    controller,
    templateUrl:
      'app/widgets/support/session-type-selection-modal/session-type-selection-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    AnalyticsEvent,
    REQUEST_EXPERT_SESSION_MODAL_ID,
    SESSION_TYPE_SELECTION_MODAL_ID,
    SUPPORT_TICKET_CASE_TYPE,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onClickCancel,
      onClickRequestExpertSession,
      onOpen,
      SESSION_TYPE_SELECTION_MODAL_ID,
    });

    function $onInit() {
      const ADMIN_CARD_POSSIBLE_ISSUE_COUNT = 5; // must be in sync with number of issues in str file
      const PRODUCT_CARD_POSSIBLE_ISSUE_COUNT = 5; // must be in sync with number of issues in str file

      vm.caseTypes = [
        {
          id: SUPPORT_TICKET_CASE_TYPE.ADMIN,
          issueKeys: getIssueKeys(ADMIN_CARD_POSSIBLE_ISSUE_COUNT),
          translateKey: '.admin',
        },
        {
          id: SUPPORT_TICKET_CASE_TYPE.PRODUCT,
          issueKeys: getIssueKeys(PRODUCT_CARD_POSSIBLE_ISSUE_COUNT),
          translateKey: '.product',
        },
      ];
    }

    function onClickCancel() {
      AnalyticsEvent.dispatch({
        componentMethod: 'cancelButton',
        componentMethodType: 'click',
        componentName,
      });
    }

    function onClickRequestExpertSession() {
      panelManager.close(SESSION_TYPE_SELECTION_MODAL_ID);
      panelManager.open(REQUEST_EXPERT_SESSION_MODAL_ID, {caseType: vm.selectedCardButtonId});
    }

    /**
     * @param {Object} options - options for the modal
     * @param {SUPPORT_TICKET_CASE_TYPE} [options.caseType] - Optional. Either ADMIN or PRODUCT. If not specified, neither
     *   is pre-selected.
     */
    function onOpen(options = {}) {
      if (
        options.caseType === SUPPORT_TICKET_CASE_TYPE.ADMIN ||
        options.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT
      ) {
        vm.selectedCardButtonId = options.caseType;
      }
    }

    /////////////////

    function getIssueKeys(count) {
      return _.times(count, (index) => `.li${index + 1}`);
    }
  }
})();
