import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';

// License Types: 1.Named user licensing(nul) 2.Shared device licensing(sdl/nuell) 3.Feature restricted licensing(frl)
const NUL = 'nul';
const FRL = 'frl';
const SDL = 'sdl';

// available license types
const LICENSE_TYPE_OPTIONS = [
  CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
  CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE,
  CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE,
];

/**
 * @description Returns package type for frl mode
 *
 * @return {String} package type
 */
const getPkgTypeForFrlMode = () => {
  let pkgType;
  if (PackagesEntitlements.hasFrlOnlineEntitlement) {
    pkgType = CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE;
  } else if (PackagesEntitlements.hasFrlLanEntitlement) {
    pkgType = CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE;
  } else if (PackagesEntitlements.hasFrlOfflineEntitlement) {
    pkgType = CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE;
  } else if (PackagesEntitlements.hasFrlIsolatedEntitlement) {
    pkgType = CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE;
  }
  return pkgType;
};

/**
 * @description Returns package session object for pkg type selected
 * @param {String} pkgType - selected package type
 *
 * @return {Object} pkgSessionSettings
 */
const getPkgSessionObjValuesOnSelect = (pkgType) => {
  const pkgSessionSettings = {};
  pkgSessionSettings.selectedPlugins = [];
  pkgSessionSettings.resetCreatePackageData = true;
  pkgSessionSettings.showAccInUI = true;

  switch (pkgType) {
    case CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE: {
      pkgSessionSettings.packagingMode = CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE;
      pkgSessionSettings.packageType = CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE;
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE: {
      pkgSessionSettings.packagingMode = CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE;
      pkgSessionSettings.packageType = CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE;
      PackagesEntitlements.resetOffers();
      pkgSessionSettings.selectedFrlOffers = [];
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE: {
      pkgSessionSettings.packagingMode = CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE;
      const pkgFrlType = getPkgTypeForFrlMode();
      if (pkgFrlType) {
        pkgSessionSettings.packageType = pkgFrlType;
      }
      PackagesEntitlements.resetOffers();
      pkgSessionSettings.selectedFrlOffers = [];
      pkgSessionSettings.showAccInUI = false;
      break;
    }
    default: {
      return {};
    }
  }
  return pkgSessionSettings;
};

/**
 * @description Returns subtype for pkg type selected
 * @param {Object} pkgType - selected package type
 *
 * @return {String} subtype
 */
const getSubType = (pkgType) => {
  let subType;
  switch (pkgType) {
    case CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE: {
      subType = NUL;
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE: {
      subType = SDL;
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE: {
      subType = FRL;
      break;
    }
    default: {
      break;
    }
  }
  return subType;
};

/**
 * @description Triggers Ingest Analytics for frl package mode
 *
 */
const sendDefaultIngestAnalyticsforFRL = () => {
  let subType;
  if (PackagesEntitlements.hasFrlOnlineEntitlement) {
    subType = 'online-activation';
  } else if (PackagesEntitlements.hasFrlLanEntitlement) {
    subType = 'connected-activation';
  } else if (PackagesEntitlements.hasFrlOfflineEntitlement) {
    subType = 'new-offline-activation';
  } else {
    subType = 'offline-activation';
  }
  PackagesIngestAnalytics.triggerAnalytics({
    subCategory: FRL.toUpperCase(),
    subType,
    type: 'click',
  });
};

/**
 * @description Triggers Ingest Analytics
 * @param {String} pkgType - selected package type
 *
 */
const triggerIngestAnalytics = (pkgType) => {
  const subType = getSubType(pkgType);
  if (subType) {
    PackagesIngestAnalytics.triggerAnalytics({
      subCategory: 'none',
      subType,
      type: 'click',
    });
  }
  if (subType === FRL) {
    sendDefaultIngestAnalyticsforFRL();
  }
};

/**
 * @description Returns boolean value to indicate if card should be displayed
 * @param {Object} pkgSessionSettings - Shared object for package creation session
 * @param {String} pkgType - selected package type
 *
 * @return {Boolean} if card should be displayed
 */
const showCard = (pkgSessionSettings, pkgType) => {
  switch (pkgType) {
    case CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE:
      return PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcEntitlement;
    case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE:
      return pkgSessionSettings.hasNuellEntitlement;
    case CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE:
      return pkgSessionSettings.hasFrlEntitlement;
    default:
      return false;
  }
};

export {
  getPkgSessionObjValuesOnSelect,
  getPkgTypeForFrlMode,
  getSubType,
  LICENSE_TYPE_OPTIONS,
  showCard,
  triggerIngestAnalytics,
};
