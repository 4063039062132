(function () {
  /**
   * @deprecated use binkySrc2.services.collaborationPolicies.CollaborationPolicies
   *
   * @ngdoc factory
   * @name app.core.asset-sharing:Policy
   * @description abstract representation of a policy object that resides in the CCX policy service.
   */
  angular.module('app.core.asset-sharing').factory('Policy', policy);

  function policy(_) {
    class Policy {
      /**
       * @description initializes Policy object from response object.
       * @param {Object} resource response object.
       */
      constructor(resource) {
        const errObj = parseErrors(resource);
        _.assign(this, fromApi(resource), errObj);
      }

      /**
       * @description gets the API formatted policy object.
       * @returns {Object} formatted for policyService.
       */
      toApiFormat() {
        return toApi(this);
      }
    }
    return Policy;

    ////////////

    function fromApi(resource) {
      const {etag} = resource;
      // If policy ID is given in etag field, move to ifMatch
      const minObj = toMinimumObject(_.defaults(resource, {ifMatch: etag}));
      const {value} = minObj;
      // Overwrite value with parsed version
      return _.assign(minObj, {value: JSON.parse(value)});
    }

    function parseErrors(resource) {
      const hasError = resource.code >= 400;
      const {code, status} = resource;
      return {err: {code, status}, hasError};
    }

    function toApi(model) {
      const minObj = toMinimumObject(model);
      const {value} = minObj;
      // Overwrite value field with stringified version
      return _.assign(minObj, {value: JSON.stringify(value)});
    }

    function toMinimumObject(object) {
      return _.pick(object, ['category', 'ifMatch', 'name', 'value']);
    }
  }
})();
