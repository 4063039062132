(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.hva.hva-card.assembler:HvaBuildAction
   * @description model that represents an HVA (High Value Action) Build Action
   */
  angular.module('app.widgets.hva.hva-card.assembler').factory('HvaBuildAction', hvaBuildAction);

  /* @ngInject */
  function hvaBuildAction(_) {
    /**
     * @class HvaBuildAction
     * @description Creates a new HvaBuildAction for use.
     *
     * @param {Object} options Initialization Object (params described below)
     * @param {Function} options.accessCallback A callback function to compute the logic for the HVA banner.
     * It should return a Promise that resolves to an array of HvaCard.
     * @param {Boolean} [options.isStackable] A boolean value to determine if HVA cards for this build action can be stacked
     * with the cards of the following build actions. Only cards with the same Type can be stacked.
     * @param {Number} options.priorityOrder The priority order for this build action.
     * @param {HVA_TYPE} [options.type] The type of the build action.
     */
    class HvaBuildAction {
      constructor(options) {
        _.assign(this, {
          accessCallback: options.accessCallback,
          isStackable: _.defaultTo(options.isStackable, false),
          priorityOrder: options.priorityOrder,
          type: options.type,
        });
      }

      isValid() {
        const requiredFields = ['accessCallback', 'priorityOrder'];
        return _.every(requiredFields, (requiredField) => !_.isUndefined(this[requiredField]));
      }
    }

    return HvaBuildAction;
  }
})();
