(function () {
  /**
   * @deprecated moved to src2 as <SupportTicketActionModal>
   *
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportCaseCloseModal
   *
   * @description Modal which allows a support case to be closed.
   */
  angular.module('app.widgets.support').component('appSupportCaseCloseModal', {
    bindings: {
      supportCase: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-case-close-modal/support-case-close-modal.component.html',
  });

  function controller(
    _,
    $filter,
    $translate,
    SUPPORT_CASE_CLOSE_MODAL_ID,
    SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID,
    SUPPORT_TICKET_ACTION,
    SUPPORT_TICKET_ACTION_REASON_CODE,
    panelManager,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onSuccess,
      onSurveyModalClose,
      SUPPORT_CASE_CLOSE_MODAL_ID,
      SUPPORT_TICKET_ACTION,
    });

    function $onInit() {
      vm.reasons = _.map(SUPPORT_TICKET_ACTION_REASON_CODE.CLOSE, (code) => ({
        code,
        label: $filter('appSupportCaseCloseReason')(code),
      }));
    }

    function onSuccess() {
      if (vm.supportCase.canTakeSurvey()) {
        panelManager.open(SUPPORT_CASE_CLOSED_SURVEY_MODAL_ID);
      } else {
        showToast();
      }
    }

    function onSurveyModalClose() {
      showToast();
    }

    function showToast() {
      const toastMsg = $translate.instant('widgets.support.supportCaseCloseModal.toast.success', {
        supportCaseId: vm.supportCase.id,
      });
      toastManager.showSuccessToast(toastMsg);
    }
  }
})();
