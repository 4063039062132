import {Divider, Flex, Link, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

// Sections with heading, optional link and description used in CreatePackageCard
const CreatePackageCardBodySection = ({sections}) => (
  <View>
    <Divider marginBottom="size-250" marginTop="size-250" size="S" />
    {sections.map((section) => (
      <Flex key={section.title} direction="column" gap="size-50" marginBottom="size-200">
        <Text UNSAFE_style={{fontWeight: 'bold'}}>{section.title}</Text>
        {section.handleLinkPress && (
          <View>
            <Link onPress={section.handleLinkPress}>{section.linkName}</Link>
          </View>
        )}
        <View>{section.content}</View>
      </Flex>
    ))}
  </View>
);

CreatePackageCardBodySection.propTypes = {
  /**
   * Array containing sections of the card
   */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Content of section
       */
      content: PropTypes.node.isRequired,
      /**
       * Function called when link presses
       */
      handleLinkPress: PropTypes.func,
      /**
       * Display string for link
       */
      linkName: PropTypes.string,
      /**
       * Title of section
       */
      title: PropTypes.string.isRequired,
    })
  ),
};

export default CreatePackageCardBodySection;
