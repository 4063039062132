import {PageBannerConstants} from '@admin-tribe/binky-ui';

import BannerNotification from './BannerNotification';

const THRESHOLD_STATUS_KEY = {
  RUNNING_LOW: 'runningLow',
  USED_ALL: 'usedAll',
};

// EXPORTED METHODS
/**
 * @description Method to compare two BannerNotification Objects
 * @param {BannerNotification} a - banner notification object
 * @param {BannerNotification} b - banner notification object to compare
 * @returns {Number} 0 if notifications are equivalent, -1 if a should be
 *   ordered before b, and 1 if b should be ordered before a
 */
function compareByVariant(a, b) {
  if (a.variant === b.variant) {
    return 0;
  }
  if (
    a.variant === PageBannerConstants.variant.ERROR ||
    (a.variant === PageBannerConstants.variant.WARNING &&
      b.variant === PageBannerConstants.variant.INFO)
  ) {
    return -1;
  }
  return 1;
}

/**
 * @description Method to return the string key segment for the appropriate
 *   threshold status, based on the consumable passed in. If the consumable
 *   passed in is not approaching any thresholds that we track (low, empty),
 *   then no value (undefined) is returned
 * @param {ConsumableItem} consumable - consumable to get key for
 * @returns {String|undefined} string key segment for the appropriate
 *   threshold status, or undefined if consumable is not approaching any
 *   thresholds
 */
function getThresholdStatusKey(consumable) {
  if (consumable.isThresholdStatusPercentageThreshold()) {
    return THRESHOLD_STATUS_KEY.RUNNING_LOW;
  }
  if (consumable.isThresholdStatusDepleted()) {
    return THRESHOLD_STATUS_KEY.USED_ALL;
  }
  return undefined;
}

/**
 * @description Method to configure a notification Object with an error variant
 *   set.
 * @param {Object} options - notification configuration Object
 * @returns {BannerNotification} configured BannerNotification with its variant
 *   set to error
 */
function notifyError(options) {
  return notifyAny({...options, variant: PageBannerConstants.variant.ERROR});
}

/**
 * @description Method to configure a notification Object with an info variant
 *   set.
 * @param {Object} options - notification configuration Object
 * @returns {BannerNotification} configured BannerNotification with its variant
 *   set to info
 */
function notifyInfo(options) {
  return notifyAny({...options, variant: PageBannerConstants.variant.INFO});
}

/**
 * @description Method to configure a notification Object with a warning variant
 *   set.
 * @param {Object} options - notification configuration Object
 * @returns {BannerNotification} configured BannerNotification with its variant
 *   set to warning
 */
function notifyWarning(options) {
  return notifyAny({...options, variant: PageBannerConstants.variant.WARNING});
}

// LOCAL (helper) METHODS
/**
 * @description Method to configure a notification Object that can be used to
 *   power a banner
 * @param {Object} options - notification configuration Object
 * @param {String} [options.body] - any body message for notification
 * @param {String} [options.buttonTxt] - any button text for notification
 * @param {Function} [options.handleButtonClick] - handler for any click interactions
 * @param {String} [options.header] - any header text for notification
 * @param {String} [options.name] - for notification
 * @param {String} [options.removeWithKey] - key to coordinate local storage
 *   show/hide behavior with any notifications (if key is present in local
 *   storage, notification should not be shown)
 * @param {String} options.variant - type of notification; one of: error,
 *   info, or warning
 * @returns {BannerNotification} configured BannerNotification
 */
function notifyAny({body, buttonTxt, handleButtonClick, header, name, removeWithKey, variant}) {
  return new BannerNotification({
    body,
    buttonTxt,
    handleButtonClick,
    header,
    name,
    removeWithKey,
    variant,
  });
}

export {compareByVariant, getThresholdStatusKey, notifyError, notifyInfo, notifyWarning};
