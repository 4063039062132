import {authentication, log} from '@admin-tribe/binky';
import {getProductDisplayName} from '@admin-tribe/binky-ui';

import API_CONSTANTS from 'common/api/ApiConstants';
import {PRODUCT_TABLE_ACTION_VARIANT} from 'common/services/product-summary-list/ProductSummaryListConstants';
import {
  canShowConsumableQuotaTypeItems,
  canViewCustomModelUsageCount,
  canViewProductLicenseCount,
} from 'core/products/access/productAccess';
import {isOrgAdminOrProductAdmin} from 'core/products/access/productProfileAccess';
import {getProductUsageQuantity} from 'core/products/utils/productUsageUtils';
import {getContractsForProductOrProductGroup} from 'features/products/utils/productGroupProductUtils';

import {
  getProductTableActionContent,
  getStatusContent,
  getTagContent,
} from './productSummaryListUtils';

/**
 * @description Method used to obtain the product summary list
 *
 * @param {Object} options - as described below
 * @param {Function} options.callback - a callback to be invoked when the
 *    product summary list data is loaded or updated
 * @param {ContractList} options.contractList - list of org contracts
 * @param {Boolean} [options.includeAction] - Flag indicating if actions should
 *   be determined and included in the response.
 * @param {Boolean} [options.includeStatus] - Flag indicating if the contract
 *   expiration status widget should be shown and included in the response (only
 *   applicable for ETLA and allocation contract not in NORMAL expiration
 *   phase).
 * @param {Boolean} [options.includeTags] - Flag indicating if tags should be
 *   determined and included in the response.
 * @param {intl} options.intl - Intl object to format the product name
 * @param {String} options.orgId - the org id
 * @param {Array<Product | ProductGroupProductList>} options.productAndProductGroupList
 *   - sorted list of products and productGroups to be shown
 *
 * @returns {Array<Object>} An array of objects with the following properties:
 *   {Array<Promise<Object> | Object>} [actions] -- Promise that resolves to an
 *      array of objects containing the relevant actions that can be performed.
 *   {Object} product -- The product object with the following properties:
 *      {icon} -- The product icon
 *      {cloud} -- The cloud that the product belongs to
 *      {model} -- Reference to the actual product model
 *      {name} -- The product long name
 *      {quantity} -- The product usage quantity. The quantity object has two
 *          properties - a promise object and an array of quantity tuples. The
 *          "promise" object will be only present if there are any quantity scores
 *          that may be returned later on. The "scores" may either be an empty array
 *          or may contain one or usage quantity tuples depending on whether the
 *          data will be available immediately
 *
 *          Here is what the data structure looks like.
 *
 *          {
 *            promise: <Object>
 *            scores: [
 *                {
 *                  total: <Integer>
 *                  unit: <String>
 *                  used: <Integer>
 *                },
 *                ...
 *            ]
 *          }
 *   {Object} status -- Status object that will be used to supply props to the
 *      ContractExpirationStatus widget.
 *      {contractStatus} -- Optional string indicating the status of the contract.
 *      {expirationPhase} -- Optional string indicating the expiration phase
 *          based on license tuple compliance symptoms.
 *      {shouldShowStatusContent} -- Flag indicating whether the widget should
 *          be shown at all.
 *   {Array<Object>} tags -- Array of objects containing the label to be shown
 *      in tags
 *   Reference:
 *   https://wiki.corp.adobe.com/display/BPS/Product+Summary+-+Data+Model
 *
 */
const ProductSummaryList = ({
  callback,
  contractList,
  includeAction = false,
  includeStatus = false,
  includeTags = false,
  intl,
  orgId,
  productAndProductGroupList,
}) => {
  const promiseList = [];

  // Populate Product summary list with all the necessary data
  // for rendering the UI
  const productSummaryList = productAndProductGroupList.map((productOrProductGroup) => {
    // Get the product usage quantity. If one or more quantity tuples are not
    // available immediately, a promise will be returned
    const apiKey = API_CONSTANTS.CLIENT_ID;
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
    const fetch = window.fetch.bind(window);
    const token = authentication.getAccessToken?.()?.token;
    const env = authentication.getAppEnv?.();
    const orgAdminOrProductAdmin = isOrgAdminOrProductAdmin(productOrProductGroup.targetExpression);
    const {promise, scores} = getProductUsageQuantity({
      apiKey,
      canShowConsumableQuotaTypeItems,
      canViewCustomModelUsageCount,
      canViewProductLicenseCount,
      env,
      fetch,
      isOrgOrProductAdmin: orgAdminOrProductAdmin,
      log,
      orgId,
      productOrProductGroup,
      token,
    });

    const productContracts = getContractsForProductOrProductGroup(
      contractList,
      productOrProductGroup
    );

    const getComplianceSymptoms = (item) => item.licenseTupleList?.items?.[0]?.complianceSymptoms;

    const result = {
      contracts: productContracts.map((contract) => ({
        // Handle unexpected situations where a contract can be null because it's not eligible to be displayed.
        name: contract?.getDisplayName(),
      })),
      product: {
        cloud: productOrProductGroup.cloud,
        complianceSymptoms: getComplianceSymptoms(productOrProductGroup),
        icon: productOrProductGroup.getIcon(),
        model: productOrProductGroup, // reference to the actual model itself
        name: getProductDisplayName(intl, productOrProductGroup),
        quantity: {promise, scores},
      },
    };

    let actionPromise, actions;
    if (includeAction) {
      ({actionPromise, actions} = getProductTableActionContent(productOrProductGroup, orgId));
      result.actions = actions;
    }

    if (includeStatus) {
      result.status = getStatusContent(productContracts, productOrProductGroup);
    }
    if (includeTags) {
      // Just looking at the first contract because for ETLA or allocation there
      // should only be one matching contract.
      result.tags = getTagContent({
        contracts: productContracts,
        productOrProductGroup,
      });
    }

    const updateAction = async () => {
      try {
        const actionContent = await actionPromise;
        if (actionContent) {
          if (
            result.actions.some(
              (action) => action.variant === PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES
            )
          ) {
            result.actions.unshift(actionContent);
          } else {
            result.actions.push(actionContent);
          }
        }
      } catch (error) {
        log.error(`fetchConsumables - orgId:${orgId} productId:${productOrProductGroup.id}`, error);
        throw new Error('Failed to fetch usage data for Consumables product.');
      }
    };

    // Async method to update the quantity score
    // once the promise is resolved
    const updateQuantityScore = async () => {
      try {
        const updatedScores = await promise;
        result.product.quantity.scores = updatedScores;
      } catch (error) {
        const contractIds = productContracts.map((contract) => contract.id).join(',');
        log.error(
          `ProductSummaryList: quantity resolve orgId:${orgId} contractIds:[${contractIds}]`,
          error
        );
      }
    };

    if (promise) {
      promiseList.push(updateQuantityScore());
    }

    if (actionPromise) {
      promiseList.push(updateAction());
    }

    return result;
  });

  /**
   * @description Method to invoke the given callback once all promises are resolved
   *
   * @param {Function} finalCallback - The callback function to invoke
   */
  const onPendingQuantitiesResolved = async ({finalCallback}) => {
    // wait for all promises to resolve before invoking the
    // callback to trigger the re-rendering of the DOM
    await Promise.allSettled(promiseList);
    finalCallback?.([...productSummaryList]);
  };

  onPendingQuantitiesResolved({finalCallback: callback});

  return productSummaryList;
};

export default ProductSummaryList;
