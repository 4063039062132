import {ProductsChange} from '@admin-tribe/binky';
import {useAsyncModel} from '@admin-tribe/binky-ui';
import {useCallback, useReducer, useRef} from 'react';

import {getUserIdsFromSelectedLicenses} from '../../SelfCancelUtils';

const ACTIONS = {
  SAVE_OFFERS: 'SAVE_OFFERS',
  SUBMIT: 'SUBMIT',
  SUBMIT_SAVE_OFFERS: 'SUBMIT_SAVE_OFFERS',
  UPDATE: 'UPDATE',
};

// Reducer method to store the params used by loadProductsChange. This method will transform the
// provided state into the param version needed by loadProductsChange. It is expected that the
// dispatch method for this reducer should carry the full set of params every time is called, as
// previous ones will not be saved between calls.
const productsChangeParamsReducer = (state, action) => {
  const {
    comment: reasonText,
    retentionId,
    selectedReasons: reasonCodes,
    selectedSeats,
    type = ACTIONS.UPDATE,
  } = action;

  const cancellationProducts = Object.keys(selectedSeats);

  if (cancellationProducts.length > 0) {
    const cancellationItems = cancellationProducts.map((productId) => {
      const quantity = selectedSeats[productId].length;
      const users = getUserIdsFromSelectedLicenses(selectedSeats[productId]);
      return {
        productId,
        quantity,
        users,
      };
    });

    return {
      ...state,
      action: type,
      cancellationItems,
      reasonCodes,
      reasonText,
      retentionId,
    };
  }
  return state;
};

/**
 * @description A helper hook to load ProductsChange model and update it when selectedSeats changes.
 *   It will also maintain the whether is loading and the error state.
 * @param {Object} options - set up options, described below
 * @param {String} options.contractId - the contract Id
 * @param {String} options.orgId - the Organization Id
 * @returns {Object} state - Object composed of state variables: productsChange, error, isLoading.
 *          {ProductsChange} state.productsChange - the array of users assigned to the Product in the Org.
 *          {Boolean} state.isLoading - Whether data is currently being loaded.
 *          {Object} state.error - If loading data failed, then this contains the error that occurred.
 *          {Function} state.dispatchProductsChange - The dispatch function to make requests.
 */
const useProductsChange = ({contractId, orgId}) => {
  const productsChangeInstance = useRef();
  // used to invalidate calls that come in after the desired network call
  const lastParams = useRef();
  const lastUpdate = useRef();

  const [params, dispatchProductsChange] = useReducer(productsChangeParamsReducer, {
    selectedSeats: {},
  });

  // Load ProductsChange for current action and state
  // eslint-disable-next-line complexity -- required for try/catch and masking the failure
  const loadProductsChange = useCallback(async () => {
    const {cancellationItems = [], reasonCodes, reasonText, retentionId} = params;

    let productsChange;

    try {
      // Wait for any pending productsChange instance creation
      productsChange = await productsChangeInstance.current;
    } catch {
      // if initial products change fails, let productsChange remain undefined, so order creation can be made in subsequent calls
    }

    if (cancellationItems.length > 0) {
      // The CREATE action is not exposed
      const action = productsChange instanceof ProductsChange ? params.action : 'CREATE';
      // always save the last params.
      lastParams.current = params;

      switch (action) {
        case 'CREATE':
          productsChangeInstance.current = ProductsChange.get({
            cancellationItems,
            contractId,
            orgId,
            reasonCodes,
            reasonText,
          });
          productsChange = await productsChangeInstance.current;
          break;
        case ACTIONS.SAVE_OFFERS:
          productsChange = await productsChange.update(
            {
              cancellationItems,
              reasonCodes,
              reasonText,
            },
            {includeRetention: true} // use retention
          );
          break;
        case ACTIONS.SUBMIT:
          productsChange = await productsChange.commitCancellation({
            cancellationItems,
            reasonCodes,
            reasonText,
          });
          break;
        case ACTIONS.SUBMIT_SAVE_OFFERS:
          productsChange = await productsChange.commitRetention(
            {
              cancellationItems,
              reasonCodes,
              reasonText,
            },
            retentionId
          );
          break;
        case ACTIONS.UPDATE:
        default:
          // Wait for current update in flight, if not resolved already
          try {
            await lastUpdate.current;
          } catch {
            // if previous call was an error, don't do anything
          }
          // If current request is superseded by a newer one, discard request
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- code added for api response timing, difficult to test
          /* istanbul ignore else  */
          if (lastParams.current === params) {
            lastUpdate.current = productsChange.update({
              cancellationItems,
              reasonCodes,
              reasonText,
            });
            productsChange = await lastUpdate.current;
          }
          break;
      }
    }

    return productsChange;
  }, [params, orgId, contractId]);

  // Configure useAsyncModel to load the ProductsChange model
  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadProductsChange,
  });

  return {dispatchProductsChange, error, isLoading, productsChange: model};
};

export default useProductsChange;
export {ACTIONS};
