import {GoUrl, PageBanner, PageBannerConstants} from '@admin-tribe/binky-ui';
import ProviderV2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const TransferDomainModalPageBanner = ({count, isError}) => {
  const intl = useIntl();

  const variant = isError ? PageBannerConstants.variant.ERROR : PageBannerConstants.variant.INFO;

  const body = isError
    ? 'settings.transferDomainModal.banners.error.body'
    : 'settings.transferDomainModal.banners.info.body';
  const title = isError
    ? 'settings.transferDomainModal.banners.error.title'
    : 'settings.transferDomainModal.banners.info.title';

  return (
    <ProviderV2>
      <PageBanner
        header={intl.formatMessage({
          id: title,
        })}
        showDefaultButton={false}
        variant={variant}
      >
        <FormattedMessage
          id={body}
          values={{
            count,
            goUrl:
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
              // istanbul ignore next -- not testing this
              (url) => <GoUrl name="aac_consolidate_domain">{url}</GoUrl>,
          }}
        />
      </PageBanner>
    </ProviderV2>
  );
};

TransferDomainModalPageBanner.propTypes = {
  count: PropTypes.number.isRequired,
  isError: PropTypes.bool.isRequired,
};
export default TransferDomainModalPageBanner;
