(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:cartToLaunchTranslator
   * @description Translate CartDescriptor into Launch data structure
   */
  angular.module('app.core.analytics.launch-descriptor').factory('cartToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_, CANCEL_REASONS, feature, jsUtils) {
    return {processDescriptor};

    function processDescriptor(launchContext, cartDescriptor) {
      const {setProperty} = launchContext;

      const items = _.get(cartDescriptor, 'items');
      const productList = _.map(items, (item) => {
        const product = {};
        jsUtils.translateJson(item, product, {
          assignableLicenseCount: 'attributes.totalLicense', // Purchased licenses
          customer_segment: 'attributes.customerSegment',
          numberSelected: 'quantity',
          offer_id: 'productInfo.offerId',
          pricing: {
            amount_with_tax: 'price.priceWithTax',
            amount_without_tax: 'price.basePrice',
            tax: 'price.tax',
          },
          productBucket: 'attributes.sourceOfferLocation',
          provisionedQuantity: 'attributes.assignedLicense', // Assigned licenses
        });

        // moved these product assignments out of translateJson due to problems feature flagging within the map variable
        if (feature.isEnabled('temp_renewal_analytcs_promos')) {
          _.set(product, 'price.currency', _.get(item, 'pricing.currency.code'));
          _.set(product, 'price.taxRate', _.get(item, 'pricing.tax_rate'));
        }

        return product;
      });

      if (feature.isEnabled('temp_renewal_analytcs_promos')) {
        setProperty('cart.attributes', _.get(cartDescriptor, 'attributes'));

        setProperty('cart.id', _.get(cartDescriptor, 'id'));

        setProperty('cart.item', productList);

        setProperty('cart.price.basePrice', _.get(cartDescriptor, 'total.subtotal'));

        setProperty('cart.price.cartTotal', _.get(cartDescriptor, 'total.total'));

        setProperty('cart.price.currency', _.get(cartDescriptor, 'total.currency.code'));

        setProperty('cart.price.priceWithTax', _.get(cartDescriptor, 'total.total'));
      } else {
        setProperty('product', productList);
      }

      setProperty('transaction.attributes.isNFR', _.get(cartDescriptor, 'contract.isNFR'));

      // Use '.contract' for clarification
      setProperty('transaction.attributes.contract.model', _.get(cartDescriptor, 'contract.model'));

      setProperty(
        'transaction.attributes.contract.salesChannel',
        _.get(cartDescriptor, 'contract.salesChannel')
      );

      setProperty(
        'transaction.attributes.contract.customerSegment',
        _.get(cartDescriptor, 'contract.customerSegment')
      );

      setProperty('transaction.attributes.contract.model', _.get(cartDescriptor, 'contract.model'));

      setProperty('transaction.attributes.contractID', _.get(cartDescriptor, 'contract.id'));

      setProperty(
        'transaction.attributes.offsetFromAnniversaryDate',
        _.get(cartDescriptor, 'contract.offsetFromAnniversaryDate')
      );

      setProperty(
        'transaction.attributes.inRenewalWindow',
        _.get(cartDescriptor, 'contract.inRenewalWindow')
      );

      setProperty('transaction.total.basePrice', _.get(cartDescriptor, 'total.subtotal'));

      setProperty('transaction.total.priceWithTax', _.get(cartDescriptor, 'total.total'));

      setProperty('currency.code', _.get(cartDescriptor, 'total.currency.code'));

      setProperty('transaction.transactionID', _.get(cartDescriptor, 'purchaseAuthId'));

      const cancellationReasons = _.get(cartDescriptor, 'cancellationReasons');
      // Don't send an empty array to '...userSelectedReason'
      if (!_.isEmpty(cancellationReasons)) {
        const reasonList = _.map(cancellationReasons, (reason) => {
          const reasonObj = {reasonID: reason};
          if (reason === CANCEL_REASONS.OTHER) {
            // Just set the value because Launch will eliminate empty string, null, and undefined values
            reasonObj.userReason = _.get(cartDescriptor, 'cancellationReasonComment');
          }
          return reasonObj;
        });

        setProperty('adminConsole.purchase.userSelectedReason', reasonList);
      }
    }
  }
})();
