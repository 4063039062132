(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').config(overview2Config);

  /* @ngInject */
  function overview2Config(
    $stateProvider,
    $transitionsProvider,
    navAssemblerProvider,
    OVERVIEW_WORKSPACE_NAV_ID
  ) {
    $stateProvider.state('overview.overview2', {
      component: 'appOverview2',
      data: {
        title: 'overview.title',
      },
      url: '',
    });

    // add the main overview section to the Overview side nav
    navAssemblerProvider.configure({
      navID: OVERVIEW_WORKSPACE_NAV_ID,
      navValue: [
        {
          disabled: false,
          hidden: false,
          name: 'overview.title',
          order: 0,
          state: {
            definition: {
              component: 'appOverview2',
              data: {
                title: 'overview.title',
              },
              url: '',
            },
            name: 'overview.overview2',
          },
        },
      ],
    });

    // redirect to quick assign if applicable
    $transitionsProvider.onEnter(
      // entering is needed as parent states could also fire this onEnter
      // otherwise
      {entering: 'overview.overview2', to: 'overview.overview2'},
      (trans) => {
        const $log = trans.injector().get('$log');
        const _ = trans.injector().get('_');

        const OrganizationManager = trans.injector().get('OrganizationManager');
        const productAccess = trans.injector().get('productAccess');
        const feature = trans.injector().get('feature');
        const onesieSrc2 = trans.injector().get('onesieSrc2');
        const previousStateParams = trans.injector().get('$state').params;

        // allow to redirect using react router when using the react quick assign
        if (feature.isDisabled('temp_standalone_quick_assign')) {
          // first time in this browser session for overview.overview2 state
          const isFirstSessionAccess = _.isEmpty(previousStateParams);
          const sophiaHelper = onesieSrc2.common.services.sophia.sophiaHelper;
          const SOPHIA_CONSTANTS = onesieSrc2.common.services.sophia.SOPHIA_CONSTANTS;

          if (feature.isEnabled('temp_quick_assign_sophia')) {
            if (isFirstSessionAccess) {
              const skipError = new Error('skip promise chain');
              return productAccess
                .canViewQuickAssignModal()
                .then((canView) => {
                  if (canView) {
                    return sophiaHelper.getSophiaContent({
                      surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_QUICK_ASSIGN,
                    });
                  }
                  throw skipError;
                })
                .then((sophiaContentList) => {
                  if (_.get(sophiaContentList, 'items.length') > 0) {
                    // type-1: show welcome screen
                    // type-2: show assignment screen
                    if (sophiaContentList.items[0].content === 'type-1') {
                      return redirectToQuickAssign(true);
                    } else if (sophiaContentList.items[0].content === 'type-2') {
                      return redirectToQuickAssign(false);
                    }
                    throw new Error('invalid data received from Sophia for quick assign');
                  }
                  throw skipError;
                })
                .catch((error) => {
                  if (error !== skipError) {
                    $log.error(
                      'Error in checking whether quick assign modal can be opened:',
                      error
                    );
                    return false;
                  }
                  return true;
                });
            }
            return true;
          }

          // eslint-disable-next-line no-else-return -- code will be removed with flag
          else {
            // eslint-disable-next-line no-lonely-if -- code will be removed with flag
            if (isFirstSessionAccess) {
              return productAccess.canViewQuickAssignModalInFirstSessionAccess().then((canView) => {
                if (canView) {
                  const stateName = 'overview.overview2.quick-assign-products';
                  return trans.router.stateService.target(stateName, {
                    orgId: OrganizationManager.getActiveOrgId(),
                  });
                }

                return true;
              });
            }
          }
        }
        return true;

        //////////////////
        /**
         * helper to redirect to the appropriate quick assign state
         *
         * @param {Boolean} showWelcome whether to show welcome screen of quick
         *     assign
         *
         * @returns {TargetState} target state for quick assign
         */
        function redirectToQuickAssign(showWelcome) {
          const stateName = 'overview.overview2.quick-assign-products';
          return trans.router.stateService.target(stateName, {
            orgId: OrganizationManager.getActiveOrgId(),
            step: showWelcome ? 'welcome' : 'assign',
          });
        }
      }
    );
  }
})();
