import {SELECTION_MODE, useTableSectionContext} from '@pandora/react-table-section';
import React from 'react';

import {isReadOnly} from 'core/organizations/access/organizationAccess';
import AssignProfileButton from 'features/products/product-profiles/product-profile-table-section/buttons/AssignProfileButton';
import DeleteProfilesButton from 'features/products/product-profiles/product-profile-table-section/buttons/DeleteProfilesButton';
import NewProfileButton from 'features/products/product-profiles/product-profile-table-section/buttons/NewProfileButton';
import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

/**
 * The button group for the ProductProfileTableSection.
 * If the app is in read-only mode the buttons will not be shown.
 * This component should be wrapped in a ProductProfileTableContextProvider.
 */
const ProductProfileTableActions = () => {
  const {pageContext} = useProductProfileTableContext();
  const {tableSectionState} = useTableSectionContext();

  return (
    !isReadOnly() && (
      <>
        {pageContext.isProductTarget() && (
          <>
            <NewProfileButton />
            {tableSectionState.selectionMode !== SELECTION_MODE.NONE && <DeleteProfilesButton />}
          </>
        )}
        {pageContext.isUserGroupTarget() && (
          <>
            <AssignProfileButton />
            {tableSectionState.selectionMode !== SELECTION_MODE.NONE && <DeleteProfilesButton />}
          </>
        )}
      </>
    )
  );
};

export default ProductProfileTableActions;
