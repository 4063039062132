(function () {
  /**
   * @deprecated ported to src2/app/features/overview/hva-cards/value-discovery-tasks/ViewReportsHva
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    organizationAccess,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.VIEW_REPORTS,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForEnterprise) {
          cache.canViewValueDiscoveryTasksForEnterprise =
            organizationAccess.canViewValueDiscoveryTasksForEnterprise();
        }

        const {canViewValueDiscoveryTasksForEnterprise} = cache;
        return canViewValueDiscoveryTasksForEnterprise.then((canView) => {
          if (canView) {
            const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.viewReports';
            return [
              new HvaCard({
                ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                description: $translate.instant(`${namespace}.description`),
                dismissOnCTAClick: true,
                header: $translate.instant(`${namespace}.header`),
                id: 'hva-view-reports',
                isDismissible: true,
                onCTAClick: () => {
                  $state.go('settings.content-logs');
                },
                sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                svgClass: SVG_CLASS.REPORTS,
              }),
            ];
          }

          return $q.reject();
        });
      }
    }
  }
})();
