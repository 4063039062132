(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    _,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.TAKE_TOUR,
        })
      );

      return result;

      function accessCallback() {
        if (feature.isDisabled('gainsight_tracking')) {
          // The tour is powered by gainsight, so this HVA should be hidden if our connection is disabled.
          return $q.reject();
        }

        const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.takeATour';

        return $q.resolve([
          new HvaCard({
            ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
            description: $translate.instant(`${namespace}.description`),
            dismissOnCTAClick: true,
            header: $translate.instant(`${namespace}.header`),
            id: 'hva-take-a-tour',
            isDismissible: true,
            sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
            svgClass: SVG_CLASS.TAKE_TOUR,
          }),
        ]);
      }
    }
  }
})();
