import {Content, Flex, Provider, View, darkTheme} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';

import HelpRailSearchTopics from 'common/components/search/search-help-topics/SearchHelpTopics';
import rootStore from 'core/RootStore';

import help from '../../constants/help';
import HelpRailFooter from '../help-rail-footer/HelpRailFooter';
import HelpRailHeader from '../help-rail-header/HelpRailHeader';
import HelpRailTab from '../help-rail-tab/HelpRailTab';
import HelpRailTooltip from '../help-rail-tooltip/HelpRailTooltip';

const {HELP_RAIL_HEADING_ID, HELP_RAIL_ID} = help;

/**
 * Help rail container
 */
const HelpRailContainer = () => {
  const containerRef = useRef(null);

  const closeHelp = useCallback(() => {
    rootStore.helpStore.ui.closeHelpRail();
  }, []);

  // Close help rail when escape key is pressed while focus is inside the help rail
  useEffect(() => {
    const handleKeyDown = (keyDownEvent) => {
      if (containerRef.current.contains(keyDownEvent.target) && keyDownEvent.key === 'Escape') {
        closeHelp();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeHelp]);

  return (
    <aside
      ref={containerRef}
      aria-labelledby={HELP_RAIL_HEADING_ID}
      // eslint-disable-next-line react/forbid-dom-props -- must use for aria-controls
      id={HELP_RAIL_ID}
      role="region"
    >
      <Content data-testid="helprail-container">
        {rootStore.helpStore.ui.isOpen && (
          <Provider colorScheme="dark" theme={darkTheme}>
            <Flex>
              <View data-testid="sidebar-isopen" width="size-5000">
                <View overflow="hidden auto">
                  <View data-testid="helprail-header" padding="size-150" paddingBottom={0}>
                    <HelpRailHeader onClose={closeHelp} />
                    <HelpRailTooltip />
                    <HelpRailSearchTopics />
                  </View>

                  <View padding="size-150">
                    <HelpRailTab />
                  </View>
                  <View
                    data-testid="helprail-footer"
                    marginBottom="size-675"
                    marginTop="size-400"
                    padding="size-150"
                  >
                    <HelpRailFooter />
                  </View>
                </View>
              </View>
            </Flex>
          </Provider>
        )}
      </Content>
    </aside>
  );
};

export default observer(HelpRailContainer);
