(function () {
  /**
   * @ngdoc module
   * @name app.core.asset-sharing
   * @description module that defines services that define an organization's asset sharing policy
   *    and invitation whitelisted domains from the Policy Service settings.
   */
  angular.module('app.core.asset-sharing', [
    'app.core.api',
    'app.core.model-cache',
    'binky.core.common.list',
    'binky.core.lodash',
  ]);
})();
