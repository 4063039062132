import binky, {
  Product,
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  constructAsyncAddAssignUserAnalytics,
  toPandoraProduct,
} from '@admin-tribe/binky';
import binkyUI, {getOrganizationUserErrorProps} from '@admin-tribe/binky-ui';
import {ProductRoleContext, ProductRoleLicenseGroupContext} from '@pandora/react-mini-cards';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {canAssignMember} from 'core/products/access/productAccess';
import {dispatchUserModalSuccessAnalytics} from 'features/users/services/user-modals/userModalAnalyticsUtils';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const {ERROR_STATUS} = binkyUI.common.components.addUser.MEMBER_AND_SELECTED_ITEMS_CONSTANTS;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;

const ImageIcon = binkyUI.common.components.ImageIcon;
const SEARCH_TYPE = binkyUI.common.components.UserPicker.SEARCH_TYPE;

const AddUsersToProductProfileModal = observer(
  ({isOpen, licenseGroupId, onClosed, onSuccess, orgId, productId}) => {
    const [modalError, setModalError] = React.useState();
    const [modalErrorProps, setModalErrorProps] = React.useState();
    const intl = useIntl();

    const [isModalOpen, setModalOpen] = React.useState(isOpen);
    const [orgUserList, setOrgUserList] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [product, setProduct] = React.useState();
    const [pandoraProduct, setPandoraProduct] = React.useState(undefined);
    const [licenseGroup, setLicenseGroup] = React.useState();
    const memberAndSelectedItemsListRef = React.useRef();

    const showModalError = React.useCallback(() => {
      setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      setModalErrorProps(undefined);
    }, [intl]);

    const onChange = (addUserFormTableData) => {
      setModalError(undefined);
      setModalErrorProps(undefined);
      const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
        addUserFormTableData,
        orgId,
        (memberAndSelectedItems) => {
          const member = memberAndSelectedItems.member;

          const getAppendedLicenseGroupInMemberProduct = () => {
            const currentMemberProduct =
              // clone the member's products here to fix a concurrency issue
              // when identical license group ids are appended to the current product.
              cloneDeep(
                member.savedState?.products?.find((memberProduct) => memberProduct.id === productId)
              ) || new Product({id: productId, ignoreEmptyFIs: true});

            currentMemberProduct.licenseGroups = currentMemberProduct.licenseGroups || [];
            currentMemberProduct.licenseGroups.push({id: licenseGroupId});

            return currentMemberProduct;
          };

          // keep existing products
          member.products =
            cloneDeep(
              member.savedState?.products?.filter((memberProduct) => memberProduct.id !== productId)
            ) || [];
          member.products.push(getAppendedLicenseGroupInMemberProduct());
          member.productRoles = memberAndSelectedItems.productRoles;
          return member;
        }
      );

      setOrgUserList(newOrgUserList);
      memberAndSelectedItemsListRef.current = addUserFormTableData;
    };

    const validateSelectedMemberForItem = (memberAndSelectedItems) => {
      if (
        memberAndSelectedItems.member &&
        !canAssignMember(product, memberAndSelectedItems.member)
      ) {
        memberAndSelectedItems.addErrorStatus(ERROR_STATUS.USER_PRODUCT_ERROR);
      } else {
        memberAndSelectedItems.clearErrorStatus(ERROR_STATUS.USER_PRODUCT_ERROR);
      }
    };

    const onConfirm = async () => {
      let isSuccess = false;
      setIsLoading(true);
      try {
        // Need to deep clone this collection as it is mutated by the save()
        const committedItems = cloneDeep(orgUserList.addedItems);
        const addedItems = [...orgUserList.addedItems];
        await orgUserList.save();
        await binky.services.member.helpers.saveMemberProductRoles(addedItems, {orgId});

        setModalOpen(false);
        isSuccess = true;
        await dispatchUserModalSuccessAnalytics({
          committedItems,
          eventName: 'AddUsersToProductProfileModal',
          orgId,
        });
        onSuccess?.();
      } catch (error) {
        const props = getOrganizationUserErrorProps(intl, error);
        setModalError(props.message);
        setModalErrorProps(props);
      } finally {
        setIsLoading(false);
      }
      return isSuccess;
    };

    const ctaToastGenerator = () =>
      intl.formatMessage(
        {id: 'common.toast.modal.usersOrGroupsUpdated'},
        {userCount: orgUserList.items.length}
      );

    React.useEffect(() => {
      let isCurrent = true;
      setIsLoading(true);
      const fetchProductAndLicenseGroup = async () => {
        let retrievedLicenseGroup, retrievedProduct;
        try {
          const retrievedProductPromise = binky.services.product.Product.get({
            id: productId,
            orgId,
          });
          const retrievedLicenseGroupPromise = binky.services.product.licenseGroup.LicenseGroup.get(
            {
              id: licenseGroupId,
              orgId,
              productId,
            }
          );
          retrievedProduct = await retrievedProductPromise;
          retrievedLicenseGroup = await retrievedLicenseGroupPromise;
        } catch (error) {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- khnguye@ to update
          // istanbul ignore else
          if (isCurrent) {
            showModalError();
          }
        }

        if (isCurrent) {
          setProduct(retrievedProduct);
          setPandoraProduct(toPandoraProduct(retrievedProduct));
          setLicenseGroup(retrievedLicenseGroup);
          setIsLoading(false);
        }
      };

      fetchProductAndLicenseGroup();

      return () => {
        isCurrent = false;
      };
    }, [intl, licenseGroupId, orgId, productId, showModalError]);

    const productRoleValue = useMemo(() => ({onError: showModalError}), [showModalError]);
    const productRoleLicenseGroupValue = useMemo(
      () => ({licenseGroupId, orgId, product: pandoraProduct}),
      [licenseGroupId, orgId, pandoraProduct]
    );

    return (
      <ModalContainer>
        {isModalOpen && (
          <ModalDialog
            analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
            cancelLabel={intl.formatMessage({
              id: 'common.addUsersToProductProfileModal.cancelButton',
            })}
            ctaLabel={intl.formatMessage({
              id: 'common.addUsersToProductProfileModal.confirmButton',
            })}
            ctaToastGenerator={ctaToastGenerator}
            errorMessage={modalError}
            errorToastProps={modalErrorProps}
            heightVariant="static"
            id="add-users-to-product-profile-modal"
            isCtaDisabled={
              !orgUserList?.hasUnsavedChanges() || memberAndSelectedItemsListRef.current.isInvalid()
            }
            isLoading={isLoading}
            onCancel={onClosed}
            onCta={onConfirm}
          >
            {product && licenseGroup && (
              <ModalTagHeader
                IconComponent={
                  <ImageIcon alt={licenseGroup.name} size="M" src={product.getIcon()} />
                }
              >
                {licenseGroup.name}
              </ModalTagHeader>
            )}
            <ModalHeading>
              {intl.formatMessage({id: 'common.addUsersToProductProfileModal.header'})}
            </ModalHeading>
            <ModalDescription>
              {intl.formatMessage({id: 'common.addUsersToProductProfileModal.description'})}
            </ModalDescription>
            <ModalContent>
              <ProductRoleContext.Provider value={productRoleValue}>
                <ProductRoleLicenseGroupContext.Provider value={productRoleLicenseGroupValue}>
                  <AddUserFormTableWrapper orgId={orgId}>
                    <AddUserFormTable
                      data-testid="add-user-form-table-testid"
                      onChange={onChange}
                      orgId={orgId}
                      searchType={SEARCH_TYPE.EXISTING_USER}
                      targetOptions={{
                        searchTargetType: SEARCH_TARGET_RESULT_TYPE.USER,
                        targetClass: SEARCH_TARGET_CLASS.PRODUCT_CONFIGURATION,
                        targetId: licenseGroupId,
                        targetParentId: productId,
                      }}
                      validateSelectedMemberForItem={validateSelectedMemberForItem}
                    />
                  </AddUserFormTableWrapper>
                </ProductRoleLicenseGroupContext.Provider>
              </ProductRoleContext.Provider>
            </ModalContent>
          </ModalDialog>
        )}
      </ModalContainer>
    );
  }
);

AddUsersToProductProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  licenseGroupId: PropTypes.string.isRequired,
  onClosed: PropTypes.func,
  /**
   * Optional callback when users have been added to the product profile. There is no parameter.
   */
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default AddUsersToProductProfileModal;
