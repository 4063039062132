import pick from 'lodash/pick';
import uniqueId from 'lodash/uniqueId';

class StorageTopFolder {
  /**
   * @description Creates a new StorageTopFolder for use with either assetsTopFolders or
   *   usersTopFolders.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {FileSize} options.consumed - The object containing info about the amount of quota that
   *   is being used.
   * @param {String} options.id - A unique id for this item. If one is not provided it will be
   *   generated.
   * @param {String} options.name - The name of the folder.
   * @param {String} options.percentage - The percentage of the quota that is being used as a string.
   * @param {FileSize} options.total - The size of the folder.
   */
  constructor(options = {}) {
    Object.assign(this, pick(options, ['consumed', 'id', 'name', 'percentage', 'total']));
    this.id = this.id || `storage-user-folder-${uniqueId()}`;
  }
}

export default StorageTopFolder;
