/* eslint-disable max-lines -- this store needs to return all the ui constants separately */
import {Store, log} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {getUiConstantsResource} from 'features/packages/api/tronCacheableApi';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

const DEFAULT_DAYS_TO_SHOW_SERVER_WARNING = 7;
const FICODE_SAPCODE_MAP = 'ficode.sapcode.map';

class PackagesUiConstantsStore extends Store {
  /**
   * @description Returns package create object for pkg type selected
   * @param {String} pkgType package type selected
   *
   * @return {Object} packageCreateObj
   */
  getPkgCreateObjValues = (pkgType) => {
    const packageCreateObj = {
      enableExtenstions: this.defaultExtensionsSetting,
      rumEnabled: this.defaultPackageRumSetting,
    };
    switch (pkgType) {
      case CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE: {
        packageCreateObj.packageType = CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE;
        packageCreateObj.appPanelEnabled = this.defaultPackageAppsPanelSetting;
        packageCreateObj.accAdminPrivileged = this.defaultPackageAdminPrivilegeSetting;
        packageCreateObj.filesPanelEnabled = this.defaultCoreSyncSetting;
        packageCreateObj.marketPanelEnabled = this.defaultCoreSyncSetting;
        packageCreateObj.selfServePluginsEnabled = this.defaultSelfServePluginsSetting;
        packageCreateObj.accDisableAutoAppUpdate = this.defaultPackageDisableAutoAppUpdateSetting;
        break;
      }
      case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE: {
        packageCreateObj.appPanelEnabled = true;
        packageCreateObj.accAdminPrivileged = false;
        packageCreateObj.filesPanelEnabled = false;
        packageCreateObj.marketPanelEnabled = false;
        packageCreateObj.selfServePluginsEnabled = false;
        packageCreateObj.accDisableAutoAppUpdate = true;
        break;
      }
      case CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE: {
        packageCreateObj.rumEnabled = false;
        packageCreateObj.enableExtenstions = false;
        break;
      }
      default: {
        return {};
      }
    }
    return packageCreateObj;
  };

  uiConstantsMap = {};

  constructor() {
    super();
    makeObservable(this, {
      adobeCodeGeneratorToolMacArmUrl: computed,
      adobeCodeGeneratorToolMacUrl: computed,
      adobeCodeGeneratorToolWinArmUrl: computed,
      adobeCodeGeneratorToolWinUrl: computed,
      ausstMacUrl: computed,
      ausstWinUrl: computed,
      ccpMacUrl: computed,
      ccpWinUrl: computed,
      daysLeftToShowServerExpiryWarning: computed,
      dcCustomizationWizardMacUrl: computed,
      dcCustomizationWizardWinUrl: computed,
      defaultBetaAppDownloadSetting: computed,
      defaultBrowserBasedAuthSetting: computed,
      defaultCoreSyncSetting: computed,
      defaultExtensionsSetting: computed,
      defaultPackageAdminPrivilegeSetting: computed,
      defaultPackageAppsPanelSetting: computed,
      defaultPackageDisableAutoAppUpdateSetting: computed,
      defaultPackageLocaleSetting: computed,
      defaultPackageOsLanguageDetectionSetting: computed,
      defaultPackageRumSetting: computed,
      defaultPackageUpdatesSetting: computed,
      defaultPluginExtensionIds: computed,
      defaultSelfServePluginsSetting: computed,
      exmanCmdMacUrl: computed,
      exmanCmdWinUrl: computed,
      fetchUiConstants: action.bound,
      fiCodeSapCodeMap: computed,
      frlFixedDnsIps: computed,
      frlIsolatedUnitCode: computed,
      frlLanUnitCode: computed,
      frlOfflineUnitCode: computed,
      frlOnlineUnitCode: computed,
      frlPreconditioningFiCode: computed,
      frlStaticDnsEndpoint: computed,
      iconBucketBaseUrl: computed,
      maxActivationCodeCount: computed,
      maxAtoFileSizeInMb: computed,
      maxDaysPackageLive: computed,
      maxLicenseIconCount: computed,
      maxPackageablePluginCount: computed,
      maxSimultaneousPackageBuildCount: computed,
      minNGLLibVersionFRLOffline: computed,
      nonCcProductSapCodes: computed,
      nuellUnitCode: computed,
      packageLocaleSelectionList: computed,
      passiveAppsFiCodes: computed,
      pollingFrequency: computed,
      rumMacArmUrl: computed,
      rumMacUrl: computed,
      rumWinArmUrl: computed,
      rumWinUrl: computed,
      serverConfigToolMacUrl: computed,
      serverConfigToolWinUrl: computed,
      tcsOfferFamilyName: computed,
      uiConstantsMap: observable,
      uninstallMacUniversalUrl: computed,
      uninstallWinUrl: computed,
    });
  }

  /**
   * @description Method to get Adobe Code Generator Tool MAC ARM Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get adobeCodeGeneratorToolMacArmUrl() {
    if (this.uiConstantsMap['deployment.tool.adobe.code.generator.macarm.link']) {
      return this.uiConstantsMap['deployment.tool.adobe.code.generator.macarm.link'];
    }
    log.error('UiConstantsStore.adobeCodeGeneratorToolMacArmUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Adobe Code Generator Tool Mac Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get adobeCodeGeneratorToolMacUrl() {
    if (this.uiConstantsMap['deployment.tool.adobe.code.generator.mac.link']) {
      return this.uiConstantsMap['deployment.tool.adobe.code.generator.mac.link'];
    }
    log.error('UiConstantsStore.adobeCodeGeneratorToolMacUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Adobe Code Generator Tool WIN ARM Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get adobeCodeGeneratorToolWinArmUrl() {
    if (this.uiConstantsMap['deployment.tool.adobe.code.generator.winarm.link']) {
      return this.uiConstantsMap['deployment.tool.adobe.code.generator.winarm.link'];
    }
    log.error('UiConstantsStore.adobeCodeGeneratorToolWinArmUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Adobe Code Generator Tool Win Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get adobeCodeGeneratorToolWinUrl() {
    if (this.uiConstantsMap['deployment.tool.adobe.code.generator.win.link']) {
      return this.uiConstantsMap['deployment.tool.adobe.code.generator.win.link'];
    }
    log.error('UiConstantsStore.adobeCodeGeneratorToolWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Ausst (Adobe Update Server Setup Tool) Mac Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get ausstMacUrl() {
    if (this.uiConstantsMap['deployment.tool.ausst.mac.link']) {
      return this.uiConstantsMap['deployment.tool.ausst.mac.link'];
    }
    log.error('UiConstantsStore.ausstMacUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Ausst (Adobe Update Server Setup Tool) Win Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get ausstWinUrl() {
    if (this.uiConstantsMap['deployment.tool.ausst.win.link']) {
      return this.uiConstantsMap['deployment.tool.ausst.win.link'];
    }
    log.error('UiConstantsStore.ausstWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get CCP (Creative Cloud Packager) Mac Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get ccpMacUrl() {
    if (this.uiConstantsMap['deployment.tool.ccp.mac.link']) {
      return this.uiConstantsMap['deployment.tool.ccp.mac.link'];
    }
    log.error('UiConstantsStore.ccpMacUrl: Unable to get');
    return '';
  }

  /**
   * @description Method to get CCP (Creative Cloud Packager) Win Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get ccpWinUrl() {
    if (this.uiConstantsMap['deployment.tool.ccp.win.link']) {
      return this.uiConstantsMap['deployment.tool.ccp.win.link'];
    }
    log.error('UiConstantsStore.ccpWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get number of days to start showing server expiry warning before
   *
   * @returns {Integer} Number of days to start showing server expiry warning before
   */
  get daysLeftToShowServerExpiryWarning() {
    if (this.uiConstantsMap['server.expiry.warning.days']) {
      return Number.parseInt(this.uiConstantsMap['server.expiry.warning.days'], 10);
    }
    log.error(
      'UiConstantsStore.daysLeftToShowServerExpiryWarning: Unable to get, falling back to default 7.'
    );
    return DEFAULT_DAYS_TO_SHOW_SERVER_WARNING;
  }

  /**
   * @description Method to get DC Customization Wizard Mac Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get dcCustomizationWizardMacUrl() {
    if (this.uiConstantsMap['deployment.tool.dcCustomizationWizard.mac.link']) {
      return this.uiConstantsMap['deployment.tool.dcCustomizationWizard.mac.link'];
    }
    log.error('UiConstantsStore.dcCustomizationWizardMacUrl: Unable to get');
    return '';
  }

  /**
   * @description Method to get DC Customization Wizard Win Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get dcCustomizationWizardWinUrl() {
    if (this.uiConstantsMap['deployment.tool.dcCustomizationWizard.win.link']) {
      return this.uiConstantsMap['deployment.tool.dcCustomizationWizard.win.link'];
    }
    log.error('UiConstantsStore.dcCustomizationWizardWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Default Beta Apps download Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultBetaAppDownloadSetting() {
    if (this.uiConstantsMap['ui.default.package.config.betaAppDownloadEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.betaAppDownloadEnabled']);
    }
    log.error(
      'UiConstantsStore.defaultBetaAppDownloadSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Default Package Browser Based Auth Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultBrowserBasedAuthSetting() {
    if (this.uiConstantsMap['ui.default.package.config.browserBasedAuthEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.browserBasedAuthEnabled']);
    }
    log.error(
      'UiConstantsStore.defaultBrowserBasedAuthSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Default core sync suppress setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultCoreSyncSetting() {
    if (this.uiConstantsMap['ui.default.package.config.filesPanelEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.filesPanelEnabled']);
    }
    log.error('UiConstantsStore.defaultCoreSyncSetting: Unable to get, falling back to default.');
    return false;
  }

  /**
   * @description Method to get Default Package Extensions Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultExtensionsSetting() {
    if (this.uiConstantsMap['ui.default.package.config.enableExtensions']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.enableExtensions']);
    }
    log.error('UiConstantsStore.defaultExtensionsSetting: Unable to get, falling back to default.');
    return false;
  }

  /**
   * @description Method to get Default Package Admin Privilage Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageAdminPrivilegeSetting() {
    if (this.uiConstantsMap['ui.default.package.config.accAdminPrivileged']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.accAdminPrivileged']);
    }
    log.error(
      'UiConstantsStore.defaultPackageAdminPrivilegeSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Default Package Apps Panel Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageAppsPanelSetting() {
    if (this.uiConstantsMap['ui.default.package.config.appPanelEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.appPanelEnabled']);
    }
    log.error(
      'UiConstantsStore.defaultPackageAppsPanelSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Default Package Auto App Update Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageDisableAutoAppUpdateSetting() {
    if (this.uiConstantsMap['ui.default.package.config.disableAutoAppUpdate']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.disableAutoAppUpdate']);
    }
    log.error(
      'UiConstantsStore.defaultPackageDisableAutoAppUpdateSetting: Unable to get, falling back to default.'
    );
    return true;
  }

  /**
   * @description Method to get Default Package Locale Setting
   *
   * @returns {String} locale_country. Defaults to 'en_US' in case of failure.
   */
  get defaultPackageLocaleSetting() {
    if (this.uiConstantsMap['ui.default.package.config.locale']) {
      return this.uiConstantsMap['ui.default.package.config.locale'];
    }
    log.error(
      'UiConstantsStore.defaultPackageLocaleSetting: Unable to get, falling back to default.'
    );
    return 'en_US';
  }

  /**
   * @description Method to get Default Package OS Language Detection Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageOsLanguageDetectionSetting() {
    if (this.uiConstantsMap['ui.default.package.config.osLangDetection']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.osLangDetection']);
    }
    log.error(
      'UiConstantsStore.defaultPackageOsLanguageDetectionSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Default Package Rum Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageRumSetting() {
    if (this.uiConstantsMap['ui.default.package.config.rumEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.rumEnabled']);
    }
    log.error('UiConstantsStore.defaultPackageRumSetting: Unable to get, falling back to default.');
    return false;
  }

  /**
   * @description Method to get Default Package Updates Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultPackageUpdatesSetting() {
    if (this.uiConstantsMap['ui.default.package.config.updatesEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.updatesEnabled']);
    }
    log.error(
      'UiConstantsStore.defaultPackageUpdatesSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get the list of default plugin extension IDs
   *
   * @returns {Array} The list of default plugin extension IDs
   */
  get defaultPluginExtensionIds() {
    if (this.uiConstantsMap['default.extensions.plugins.id']) {
      return this.uiConstantsMap['default.extensions.plugins.id'].split(',').slice(0, 50);
    }
    log.error(
      'UiConstantsStore.defaultPluginExtensionIds: Unable to get, falling back to default [].'
    );
    return [];
  }

  /**
   * @description Method to get Default Package Self Service Plugins Setting
   *
   * @returns {Boolean} enabled/disabled
   */
  get defaultSelfServePluginsSetting() {
    if (this.uiConstantsMap['ui.default.package.config.selfServePluginsEnabled']) {
      return JSON.parse(this.uiConstantsMap['ui.default.package.config.selfServePluginsEnabled']);
    }
    log.error(
      'UiConstantsStore.defaultSelfServePluginsSetting: Unable to get, falling back to default.'
    );
    return false;
  }

  /**
   * @description Method to get Exman Cmd Mac Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get exmanCmdMacUrl() {
    if (this.uiConstantsMap['deployment.tool.exmanCmd.mac.link']) {
      return this.uiConstantsMap['deployment.tool.exmanCmd.mac.link'];
    }
    log.error('UiConstantsStore.exmanCmdMacUrl: Unable to get');
    return '';
  }

  /**
   * @description Method to get Exman Cmd Win Url
   *
   *@returns {String} URL. Returns empty string if failure.
   */
  get exmanCmdWinUrl() {
    if (this.uiConstantsMap['deployment.tool.exmanCmd.win.link']) {
      return this.uiConstantsMap['deployment.tool.exmanCmd.win.link'];
    }
    log.error('UiConstantsStore.exmanCmdWinUrl: Unable to get.');
    return '';
  }

  /**
   * Method to fetch and process all UiConstants
   *
   */
  async fetchUiConstants() {
    if (Object.keys(this.uiConstantsMap).length === 0) {
      try {
        const response = await getUiConstantsResource();
        runInAction(() => {
          this.uiConstantsMap = response.data;
        });
      } catch (error) {
        // TODO: Remove `await` once binky upgraded past 3.6.8
        await this.fetchError(error);
        log.error(
          'UiConstantsStore.fetchUiConstants(): Unable to retrieve model from back-end. Error: ',
          error
        );
      } finally {
        this.fetchDone();
      }
    }
  }

  /**
   * @description Method to get Fi Code Sap Code Map
   *
   * @returns {Object} Fi Code Sap Code Map
   */
  get fiCodeSapCodeMap() {
    if (this.uiConstantsMap[FICODE_SAPCODE_MAP]) {
      try {
        return JSON.parse(this.uiConstantsMap[FICODE_SAPCODE_MAP]);
      } catch (error) {
        return false;
      }
    }
    log.error('UiConstantsStore.fiCodeSapCodeMap: Unable to get, defaulting to false.');
    return false;
  }

  /**
   * @description Method to get frl dns Ips
   *
   *@returns {Array} Dns IPs Returns empty array if failure
   */
  get frlFixedDnsIps() {
    if (this.uiConstantsMap['ui.frl.fixed.dns.ips']) {
      return this.uiConstantsMap['ui.frl.fixed.dns.ips'].split(',');
    }
    log.error('UiConstantsStore.frlFixedDnsIps: Unable to get');
    return [];
  }

  /**
   * @description Method to get FRL isolated unit code to identify FRL isolated offer
   *
   * @returns {String} FRL isolated unit code
   */
  get frlIsolatedUnitCode() {
    if (this.uiConstantsMap['frl.unit.code.isolated']) {
      return this.uiConstantsMap['frl.unit.code.isolated'];
    }
    log.error(
      'UiConstantsStore.frlIsolatedUnitCode: Unable to get, falling back to default "frl_isolated"'
    );
    return 'frl_isolated';
  }

  /**
   * @description Method to get FRL LAN unit code to identify FRL LAN offer
   *
   * @returns {String} FRL LAN unit code
   */
  get frlLanUnitCode() {
    if (this.uiConstantsMap['frl.unit.code.lan']) {
      return this.uiConstantsMap['frl.unit.code.lan'];
    }
    log.error('UiConstantsStore.frlLanUnitCode: Unable to get, falling back to default "frl_lan"');
    return 'frl_lan';
  }

  /**
   * @description Method to get FRL offline unit code to identify FRL offline offer
   *
   * @returns {String} FRL offline unit code
   */
  get frlOfflineUnitCode() {
    if (this.uiConstantsMap['frl.unit.code.offline']) {
      return this.uiConstantsMap['frl.unit.code.offline'];
    }
    log.error(
      'UiConstantsStore.frlOfflineUnitCode: Unable to get, falling back to default "frl_offline"'
    );
    return 'frl_offline';
  }

  /**
   * @description Method to get FRL online unit code to identify FRL online offer
   *
   * @returns {String} FRL online unit code
   */
  get frlOnlineUnitCode() {
    if (this.uiConstantsMap['frl.unit.code.online']) {
      return this.uiConstantsMap['frl.unit.code.online'];
    }
    log.error(
      'UiConstantsStore.frlOnlineUnitCode: Unable to get, falling back to default "frl_online"'
    );
    return 'frl_online';
  }

  /**
   * @description Method to get FRL preconditioning FI code to identify FRL offer
   *
   * @returns {String} FRL Preconditioning FI Code
   */
  get frlPreconditioningFiCode() {
    if (this.uiConstantsMap['frl.preconditioning.fi.code']) {
      return this.uiConstantsMap['frl.preconditioning.fi.code'];
    }
    log.error(
      'UiConstantsStore.frlPreconditioningFiCode: Unable to get, falling back to default "package_preconditioning"'
    );
    return 'package_preconditioning';
  }

  /**
   * @description Method to get frl static dns endpoint
   *
   *@returns {String} URL. Returns empty string if failure
   */
  get frlStaticDnsEndpoint() {
    if (this.uiConstantsMap['ui.frl.static.dns.endpoint']) {
      return this.uiConstantsMap['ui.frl.static.dns.endpoint'];
    }
    log.error('UiConstantsStore.frlStaticDnsEndpoint: Unable to get');
    return '';
  }

  /**
   * @description Method to get FI code for given sapCode
   *
   * @param {String} sapCode sapCode for product
   * @returns {String} FI code for given sapCode
   */
  getFiCodeFromSapCode(sapCode) {
    let fiSapCodeMap;
    try {
      fiSapCodeMap = JSON.parse(this.uiConstantsMap[FICODE_SAPCODE_MAP]);
    } catch (error) {
      return false;
    }
    if (fiSapCodeMap) {
      const fiCode = Object.keys(fiSapCodeMap).find((k) =>
        fiSapCodeMap[k].split(',').includes(sapCode)
      );
      return fiCode;
    }
    log.error('UiConstantsStore.getFiCodeFromSapCode(): Unable to get, defaulting to false.');
    return false;
  }

  /**
   * @description Method to get information of latest AAL for given platform
   *
   * @param {String} platform platform for AAL
   * @param {String} bits bits
   * @returns {Object} Latest name and version of AAL .
   */
  getLatestAALInformation(platform, bits) {
    let aalInfoMap;
    let aalPlatform = platform;
    if (aalPlatform === 'WIN') {
      aalPlatform += bits;
    }
    try {
      aalInfoMap = JSON.parse(this.uiConstantsMap['aal.latest.information.map']);
    } catch (error) {
      log.error('UiConstantsStore.getLatestAALInformation(): Json error ', error);
      return {name: '', version: '0'};
    }
    if (
      aalInfoMap &&
      aalInfoMap[aalPlatform] &&
      aalInfoMap[aalPlatform].name &&
      aalInfoMap[aalPlatform].version
    ) {
      return aalInfoMap[aalPlatform];
    }
    log.error('UiConstantsStore.getLatestAALInformation(): Unable to get for: ', platform, bits);
    return {name: '', version: '0'};
  }

  /**
   * @description Method to get sapCode for given FI code
   *
   * @param {String} fiCode fiCode for product
   * @returns {Array} array of SAP code for given fiCode
   */

  getSapCodeFromFiCode(fiCode) {
    let fiSapCodeMap;
    try {
      fiSapCodeMap = JSON.parse(this.uiConstantsMap[FICODE_SAPCODE_MAP]);
    } catch (error) {
      return false;
    }
    if (fiSapCodeMap && fiSapCodeMap[fiCode]) {
      return fiSapCodeMap[fiCode].split(',');
    }
    log.error('UiConstantsStore.getSapCodeFromFiCode(): Unable to get, defaulting to false.');
    return false;
  }

  /**
   * @description Method to check if product for given key has any special requirements
   *
   * @param {String} productKey 'sapCode/version/platform' product key
   * @returns {Boolean} true if it has any special system requirements
   */
  hasSpecialSystemRequirements(productKey) {
    if (this.uiConstantsMap['ui.special.system.requirement.product.keys']) {
      return this.uiConstantsMap['ui.special.system.requirement.product.keys'].includes(productKey);
    }
    log.error(
      'UiConstantsStore.hasSpecialSystemRequirements(): Unable to get, defaulting to false.'
    );
    return false;
  }

  /**
   * @description Method to get icon base URL.
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get iconBucketBaseUrl() {
    if (this.uiConstantsMap['s3.icon.bucket.name']) {
      return this.uiConstantsMap['s3.icon.bucket.name'];
    }
    log.error('UiConstantsStore.iconBucketBaseUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get max count allowed activation codes for FRL Isolated
   *
   * @returns {Integer} Max count of allowed activation codes for FRL Isolated. Defaults to 1000.
   */
  get maxActivationCodeCount() {
    if (this.uiConstantsMap['max.activation.code.count']) {
      return Number.parseInt(this.uiConstantsMap['max.activation.code.count'], 10);
    }
    log.error(
      'UiConstantsStore.maxActivationCodeCount: Unable to get, falling back to default 1000.'
    );
    return 1000;
  }

  /**
   * @description Method to get max ATO file size in MBs.
   *
   * @returns {Integer} Max ATO file size in MBs.
   */
  get maxAtoFileSizeInMb() {
    if (this.uiConstantsMap['ui.max.ato.file.size.allowed.mb']) {
      return Number.parseInt(this.uiConstantsMap['ui.max.ato.file.size.allowed.mb'], 10);
    }
    log.error('UiConstantsStore.maxAtoFileSizeInMb: Unable to get, falling back to default 2MB.');
    return 2;
  }

  /**
   * @description Method to get Max days Package is availabe to download from the date created
   *
   * @returns {Integer} number of days package is availabe to download. Defaults to 3.
   */
  get maxDaysPackageLive() {
    if (this.uiConstantsMap['max.days.pacakage.live']) {
      return Number.parseInt(this.uiConstantsMap['max.days.pacakage.live'], 10);
    }
    log.error('UiConstantsStore.maxDaysPackageLive: Unable to get, falling back to default.');
    return 3;
  }

  /**
   * @description Method to get max count of product/license icons to show
   *
   * @returns {Integer} Max count of product/license icons to show. Defaults to 4.
   */
  get maxLicenseIconCount() {
    if (this.uiConstantsMap['max.product.icon.count']) {
      return Number.parseInt(this.uiConstantsMap['max.product.icon.count'], 10);
    }
    log.error('UiConstantsStore.maxLicenseIconCount: Unable to get, falling back to default 4.');
    return 4;
  }

  /**
   * @description Method to get max count of allowed plugins in a package
   *
   * @returns {Integer} Max count of allowed plugins in a package. Defaults to 50.
   */
  get maxPackageablePluginCount() {
    if (this.uiConstantsMap['max.packageable.plugin.count']) {
      return Number.parseInt(this.uiConstantsMap['max.packageable.plugin.count'], 10);
    }
    log.error(
      'UiConstantsStore.maxPackageablePluginCount: Unable to get, falling back to default 50.'
    );
    return 50;
  }

  /**
   * @description Method to get Max Simultaneous Package Build Count per Org
   *
   * @returns {Integer} number of max packages allowed to be built simultaneously. Defaults to 2.
   */
  get maxSimultaneousPackageBuildCount() {
    if (this.uiConstantsMap['max.packages.simultaneous.per.org']) {
      return Number.parseInt(this.uiConstantsMap['max.packages.simultaneous.per.org'], 10);
    }
    log.error(
      'UiConstantsStore.maxSimultaneousPackageBuildCount: Unable to get, falling back to default.'
    );
    return 2;
  }

  /**
   * @description Method to get minimum NGL Lib version supported for FRL Offline
   *
   * @returns {String} FRL Offline supported minimum NGL Lib Version
   */
  get minNGLLibVersionFRLOffline() {
    if (this.uiConstantsMap['frl.offline.support.min.ngl.lib.version']) {
      return this.uiConstantsMap['frl.offline.support.min.ngl.lib.version'];
    }
    log.error(
      'UiConstantsStore.minNGLLibVersionFRLOffline: Unable to get, falling back to default "1.14"'
    );
    return '1.14';
  }

  /**
   * @description Method to get non CC product sap codes
   *
   * @returns {Array} array of non CC sap codes. Defaults to ['CPTV', 'CPTL'] in case of failure.
   */
  get nonCcProductSapCodes() {
    if (this.uiConstantsMap['noncc.sapcodes']) {
      return this.uiConstantsMap['noncc.sapcodes'].split(',');
    }
    log.error(
      'UiConstantsStore.nonCcProductSapCodes: Unable to get, falling back to default ["CPTV", "CPTL"].'
    );
    return ['CPTV', 'CPTL'];
  }

  /**
   * @description Method to get NUELL (shared license) unit code to identify NUELL offer
   *
   * @returns {String} NUELL unit code
   */
  get nuellUnitCode() {
    if (this.uiConstantsMap['nuell.unit.code']) {
      return this.uiConstantsMap['nuell.unit.code'];
    }
    log.error('UiConstantsStore.nuellUnitCode: Unable to get, falling back to default "lab_nul"');
    return 'lab_nul';
  }

  /**
   * @description Method to get Package Locale Selection List
   *
   * @returns {Array} array of 'locale_country' strings. Defaults to ['en_US'] in case of failure.
   */
  get packageLocaleSelectionList() {
    if (this.uiConstantsMap['packaging.language.list']) {
      return this.uiConstantsMap['packaging.language.list'].split(',');
    }
    log.error(
      'UiConstantsStore.packageLocaleSelectionList: Unable to get, falling back to default [en_US].'
    );
    return ['en_US'];
  }

  /**
   * @description Method to get list of FI codes  which should be considered non conflicting in case of FRL entitlement selection
   *
   * @returns {Array} array of FI codes which are not to be considered for entitlement conflicts
   */
  get passiveAppsFiCodes() {
    if (this.uiConstantsMap['nonconflicting.ficodes']) {
      return this.uiConstantsMap['nonconflicting.ficodes'].split(',');
    }
    log.error(
      'UiConstantsStore.passiveAppsFiCodes: Unable to get, falling back to default ["bridge_cc", "adobe_media_encoder"].'
    );
    return ['bridge_cc', 'adobe_media_encoder'];
  }

  /**
   * @description Method to get package progress polling frequency time in milli seconds.
   *
   * @returns {Integer} Time in ms. Defaults to 5000ms in case of faiure.
   */
  get pollingFrequency() {
    if (this.uiConstantsMap['status.polling.freaquency']) {
      return Number.parseInt(this.uiConstantsMap['status.polling.freaquency'], 10);
    }
    log.error('UiConstantsStore.pollingFrequency: Unable to get, falling back to default.');
    return 5000;
  }

  /**
   * @description Method to get Rum (Remote Update Manager) Mac ARM Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get rumMacArmUrl() {
    if (this.uiConstantsMap['deployment.tool.rum.macarm.link']) {
      return this.uiConstantsMap['deployment.tool.rum.macarm.link'];
    }
    log.error('UiConstantsStore.rumMacArmUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Rum (Remote Update Manager) Mac Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get rumMacUrl() {
    if (this.uiConstantsMap['deployment.tool.rum.mac.link']) {
      return this.uiConstantsMap['deployment.tool.rum.mac.link'];
    }
    log.error('UiConstantsStore.rumMacUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Rum (Remote Update Manager) WIN ARM Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get rumWinArmUrl() {
    if (this.uiConstantsMap['deployment.tool.rum.winarm.link']) {
      return this.uiConstantsMap['deployment.tool.rum.winarm.link'];
    }
    log.error('UiConstantsStore.rumWinArmUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Rum (Remote Update Manager) Win Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get rumWinUrl() {
    if (this.uiConstantsMap['deployment.tool.rum.win.link']) {
      return this.uiConstantsMap['deployment.tool.rum.win.link'];
    }
    log.error('UiConstantsStore.rumWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Server Config Tool Mac Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get serverConfigToolMacUrl() {
    if (this.uiConstantsMap['deployment.tool.server.config.mac.link']) {
      return this.uiConstantsMap['deployment.tool.server.config.mac.link'];
    }
    log.error('UiConstantsStore.serverConfigToolMacUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get Server Config Tool Win Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get serverConfigToolWinUrl() {
    if (this.uiConstantsMap['deployment.tool.server.config.win.link']) {
      return this.uiConstantsMap['deployment.tool.server.config.win.link'];
    }
    log.error('UiConstantsStore.serverConfigToolWinUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get family name for TCS suite
   *
   * @returns {Array} family name expected in TCS offer
   */
  get tcsOfferFamilyName() {
    if (this.uiConstantsMap['tcs.family.name']) {
      return this.uiConstantsMap['tcs.family.name'];
    }
    log.error(
      'UiConstantsStore.tcsOfferFamilyName: Unable to get, falling back to default "TECHNICAL_COMMUNICATION_SUITE".'
    );
    return 'TECHNICAL_COMMUNICATION_SUITE';
  }

  /**
   * @description Method to get 'Adobe uninstall tool' Mac Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get uninstallMacUniversalUrl() {
    if (this.uiConstantsMap['deployment.tool.uninstall.mac.universal.link']) {
      return this.uiConstantsMap['deployment.tool.uninstall.mac.universal.link'];
    }
    log.error('UiConstantsStore.uninstallMacUrl: Unable to get.');
    return '';
  }

  /**
   * @description Method to get 'Adobe uninstall tool' Win Url
   *
   * @returns {String} URL. Returns empty string if failure.
   */
  get uninstallWinUrl() {
    if (this.uiConstantsMap['deployment.tool.uninstall.win.link']) {
      return this.uiConstantsMap['deployment.tool.uninstall.win.link'];
    }
    log.error('UiConstantsStore.uninstallWinUrl: Unable to get.');
    return '';
  }
}

export default PackagesUiConstantsStore;
/* eslint-enable max-lines -- this store needs to return all the ui constants separately */
