import PropTypes from 'prop-types';
import React from 'react';

import DomainEnforcement from 'features/settings/components/directory/domain-enforcement/DomainEnforcement';
import {DomainEnforcementContextProvider} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import useDomainEnforcementState from 'features/settings/hooks/domain-enforcement/useDomainEnforcementState';

const DomainEnforcementWrapper = ({domainEnforcement, refreshDirectory}) => {
  const deState = useDomainEnforcementState({domainEnforcement});

  return (
    <DomainEnforcementContextProvider deState={deState} refreshDirectory={refreshDirectory}>
      <DomainEnforcement />
    </DomainEnforcementContextProvider>
  );
};

DomainEnforcementWrapper.propTypes = {
  /**
   * DomainEnforcement data
   */
  domainEnforcement: PropTypes.shape({
    /**
     * The ID of the Directory
     */
    authSrc: PropTypes.string.isRequired,
    /**
     * Date when opt-out phase ends
     */
    notifyAt: PropTypes.number,
    /**
     * Current status of the Policy
     */
    state: PropTypes.string.isRequired,
    /**
     *  Date when the current state phase ends
     */
    stateEndsAt: PropTypes.number,
  }),
  /**
   * Refreshes directory data
   */
  refreshDirectory: PropTypes.func.isRequired,
};

export default DomainEnforcementWrapper;
