/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated should use the binky OrganizationUser
   *
   * @ngdoc factory
   * @name OrganizationUser
   * @description Model for an organization user.
   */

  angular
    .module('app.core.organizations.user')
    .factory('OrganizationUser', getOrganizationUserModel);

  /* @ngInject */
  function getOrganizationUserModel(
    $log,
    $q,
    $rootScope,
    _,
    feature,
    jilUsers,
    jsUtils,
    Member,
    MEMBER_EVENT,
    MEMBER_TYPE,
    MemberType,
    MODEL,
    modelCache,
    organizationUserUtils,
    ROLE,
    User,
    UserRole
  ) {
    // the nested objects (products, roles, userGroups) are tracked separately
    const EDITABLE_FIELDS = ['countryCode', 'email', 'firstName', 'lastName', 'userName'];
    class OrganizationUser extends User {
      /**
       * @description Creates a new OrganizationUser.
       *
       * @param {Object} [options] options for the OrganizationUser as detailed below
       * @param {String} [options.id] An OrganizationUser's ID
       * @param {String} [options.countryCode] An OrganizationUser's country code
       * @param {Array} [options.developerProducts] An OrganizationUser's products that they have developer access to
       * @param {Boolean} [options.editable] True if User is editable
       * @param {String} [options.email] An OrganizationUser's email address
       * @param {String} [options.firstName] An OrganizationUser's first name
       * @param {String} [options.lastName] An OrganizationUser's last name
       * @param {Array} [options.licenseGroups] An OrganizationUser's configs.
       *       This field is only returned for Product Users
       * @param {String} [options.name] - name field (if User Group)
       * @param {Array} [options.products] An OrganizationUser's products, with nested configs.
       *       This field is returned for the org user list, and the single user fetch.
       * @param {Array} [options.roles] An OrganizationUser's roles. This is returned for the
       *       single user fetch.
       * @param {String} [options.type] An OrganizationUser's type
       * @param {Number} [options.userCount] - the number of users (if User Group)
       * @param {Array} [options.userGroups] An OrganizationUser's user groups. This is returned
       *       for the single user fetch.
       * @param {String} [options.userName] An OrganizationUser's user name
       * @param {Boolean} [options.externallyManaged] True if user was created from an external system
       * @param {Boolean} [options.removable] True if user can be removed
       */
      constructor(options = {}) {
        super(options);
        this.name = options.name;
        this.userCount = options.userCount;
        this.externallyManaged = options.externallyManaged;
        this.removable = options.removable;
        this.includeUserGroupProducts = options.includeUserGroupProducts;
        registerSavedState(this);
      }

      delete() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;
        this.$resolved = false;

        const operations = [{op: 'remove', path: `/${this.id}`}];

        this.performBatchOperation(operations).then(onSuccess.bind(this)).catch(onError.bind(this));

        return deferred.promise;

        function onError(error) {
          $log.error('OrganizationUser.delete(): Failed to delete user. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        function onSuccess() {
          this.$resolved = true;
          deferred.resolve(this);
        }
      }

      getDisplayName() {
        let fullName;

        if (this.getType().isUserGroup()) {
          fullName = this.name;
        } else {
          fullName = super.getDisplayName();
        }

        return fullName;
      }

      /**
       * @description Method to get any unsaved product changes to this object.
       *
       * @returns {Object} the altered state
       * @property {Array} added - the unsaved product adds, else empty
       * @property {Array} removed - the unsaved product removes, else empty
       */
      getUnsavedProductChanges() {
        const currentLicenseGroups = organizationUserUtils.explodeProductLicenseGroups(
          this.products,
          {onlyEditable: true}
        );
        const savedLicenseGroups = organizationUserUtils.explodeProductLicenseGroups(
          this.savedState.products,
          {onlyEditable: true}
        );
        // we have to compare products based on the license groups within them
        const addedLicenseGroups = _.differenceWith(
          currentLicenseGroups,
          savedLicenseGroups,
          organizationUserUtils.compareProductLicenseGroups
        );
        const removedLicenseGroups = _.differenceWith(
          savedLicenseGroups,
          currentLicenseGroups,
          organizationUserUtils.compareProductLicenseGroups
        );
        return {
          added: convertLicenseGroupsToProducts(addedLicenseGroups),
          removed: convertLicenseGroupsToProducts(removedLicenseGroups),
        };

        function convertLicenseGroupsToProducts(licenseGroups) {
          return _(licenseGroups)
            .groupBy('product.id')
            .map((groupedLicenseGroups) => ({
              id: licenseGroups[0].product.id,
              licenseGroups: _.map(groupedLicenseGroups, (groupedLicenseGroup) => ({
                id: groupedLicenseGroup.id,
              })),
            }))
            .value();
        }
      }

      /**
       * @description Method to get any unsaved role changes to this object.
       *
       * @returns {Object} the altered state
       * @property {Array} added - the unsaved role adds, else empty
       * @property {Array} removed - the unsaved role removes, else empty
       */
      getUnsavedRoleChanges() {
        return {
          added: _.differenceWith(
            this.roles,
            this.savedState.roles,
            organizationUserUtils.compareAdminRoles
          ),
          removed: _.differenceWith(
            this.savedState.roles,
            this.roles,
            organizationUserUtils.compareAdminRoles
          ),
        };
      }

      /**
       * @description Method to get any unsaved user group changes to this object.
       *
       * @returns {Object} the altered state
       * @property {Array} added - the unsaved user group adds, else empty
       * @property {Array} removed - the unsaved user group removes, else empty
       */
      getUnsavedUserGroupChanges() {
        return {
          added: _.differenceWith(
            this.userGroups,
            this.savedState.userGroups,
            organizationUserUtils.compareUserGroups
          ),
          removed: _.differenceWith(
            this.savedState.userGroups,
            this.userGroups,
            organizationUserUtils.compareUserGroups
          ),
        };
      }

      hasDisplayName() {
        return super.hasDisplayName() || !!this.name;
      }

      /**
       * @description Method to determine if there are any unsaved changes to this object.
       *
       * @returns {Boolean} true if there are unsaved changes, else false
       */
      hasUnsavedChanges() {
        const savedBaseState = _.pick(this.savedState, EDITABLE_FIELDS);
        return (
          !_.isEqual(savedBaseState, _.pick(this, EDITABLE_FIELDS)) ||
          !jsUtils.compareArrays(
            organizationUserUtils.explodeProductLicenseGroups(this.savedState.products, {
              onlyEditable: true,
            }),
            organizationUserUtils.explodeProductLicenseGroups(this.products, {onlyEditable: true}),
            organizationUserUtils.compareProductLicenseGroups
          ) ||
          !jsUtils.compareArrays(
            this.savedState.userGroups,
            this.userGroups,
            organizationUserUtils.compareUserGroups
          ) ||
          !jsUtils.compareArrays(
            organizationUserUtils.explodeAdminRoles(this.savedState.roles),
            organizationUserUtils.explodeAdminRoles(this.roles),
            organizationUserUtils.compareAdminRoles
          )
        );
      }

      /**
       * @description Method to determine if this user is a developer
       *
       * @returns {Boolean} true if the user has any role type set as LICENSE_DEV_ADMIN
       */
      isDeveloper() {
        return _.some(this.roles, {type: 'LICENSE_DEV_ADMIN'});
      }

      /**
       * @description Method to determine if this user has been created in an
       *              external system (e.g. Azure)
       *
       * @returns {Boolean|*} true if the user comes from an external system, false otherwise
       */
      isExternallyManaged() {
        return this.externallyManaged;
      }

      /**
       * @description Method to determine if the user is removable
       *
       * @return {Boolean} true if user is removable, false otherwise.
       */
      isRemovable() {
        return this.removable;
      }

      key() {
        return this.id;
      }

      /**
       * @description Method to perform batch operations against this user.
       *
       * @param {Array} operations - list of operations to perform in batch
       *
       * @returns {Promise} resolves if batch op succeeds, else rejects w/error msg
       */
      performBatchOperation(operations) {
        const deferred = $q.defer();
        const modelId = this.id;

        if (operations && operations.length > 0) {
          jilUsers.users.batchOperation(
            {},
            operations,
            onBatchOperationSuccess,
            onBatchOperationError
          );
        } else {
          deferred.resolve();
        }

        return deferred.promise;

        function onBatchOperationError(error) {
          logErrorAndRejectPromise(
            error,
            'OrganizationUser.performBatchOperation() failed to perform batch operation(s). Error: '
          );
        }

        function onBatchOperationSuccess() {
          $rootScope.$emit(MEMBER_EVENT.UPDATE, modelId);
          deferred.resolve();
        }
        function logErrorAndRejectPromise(error, message) {
          $log.error(message, error);
          deferred.reject(error);
        }
      }

      /**
       * @description Method to refresh this user's state from the back-end.
       *
       * @param {Object} options - optional parameters for the method
       *
       * @returns {Promise} resolves to fetched OrganizationUser instance, else
       *                    rejects with error message
       */
      refresh(options = {}) {
        const deferred = $q.defer();
        if (!options.delegatePromise) {
          this.$promise = deferred.promise;
          this.$resolved = false;
        }

        jilUsers.users.get(
          {
            id: this.id,
            includeUserGroupProducts: this.includeUserGroupProducts,
          },
          onSuccess.bind(this),
          onError.bind(this)
        );

        return deferred.promise;

        function onError(error) {
          $log.error('OrganizationUser.refresh(): Failed to retrieve user. Error: ', error);
          if (!options.delegatePromise) {
            this.$resolved = true;
          }
          deferred.reject(error);
        }

        function onSuccess(response) {
          angular.extend(this, OrganizationUser.apiResponseTransformer(response), {
            includeUserGroupProducts: this.includeUserGroupProducts,
          });
          registerSavedState(this);
          if (!options.delegatePromise) {
            this.$resolved = true;
          }
          modelCache.put(MODEL.ORGANIZATIONUSER, this, this.key());
          deferred.resolve(this);
        }
      }

      registerProductsSavedState() {
        this.savedState.products = _.cloneDeep(this.products);
      }

      /**
       * @description Restores the user from its saved state
       */
      restore() {
        _.assign(this, this.savedState);
      }

      /**
       * @description Method to save changes to the organization user to the back-end.
       *
       * @param {File} avatar user avatar to be uploaded
       * @param {Object} options - optional parameters for the method
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save(avatar, options = {}) {
        const deferred = $q.defer();
        this.$promise = deferred.promise;

        if (this.isNew()) {
          // this does not yet exist and is a create
          this.$resolved = false;
          createUser.call(this);
        } else if (this.hasUnsavedChanges() || avatar) {
          // this already exists and is an update
          this.$resolved = false;
          const promises = [];
          if (avatar) {
            const formdata = new FormData();
            formdata.append('image', avatar);
            promises.push(jilUsers.avatar.upload(_.omitBy({id: this.id}, _.isUndefined), formdata));
          }
          $q.all(promises).then(updateUser.bind(this)).catch(onError.bind(this));
        } else {
          // no changes to save
          deferred.resolve();
        }

        function createUser() {
          // we also allow type to be set, only on create
          const userModel = this.toMinimumModel();
          userModel.type = this.type;
          if (userModel.type === MEMBER_TYPE.TYPE1) {
            delete userModel.countryCode;
          }
          jilUsers.users.save(userModel, onSuccess.bind(this), onError.bind(this));
        }

        function updateUser() {
          // first we find any user state changes
          const operations = OrganizationUser.getPatchOperationsForUpdate(this, options);

          this.performBatchOperation(_.compact(operations))
            .then(onSuccess.bind(this))
            .catch(onError.bind(this));
        }

        function onSuccess(response) {
          const message = this.isNew() ? MEMBER_EVENT.CREATE : MEMBER_EVENT.UPDATE;
          // only the create returns data to us, the update is a 204
          if (this.isNew()) {
            angular.extend(this, OrganizationUser.apiResponseTransformer(response));
          }
          // on a successful write, we update the saved form
          registerSavedState(this);

          this.$resolved = true;
          deferred.resolve(this);

          $rootScope.$emit(message, this.id);
        }

        function onError(error) {
          $log.error('Failed to create/update selected organization user. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        return deferred.promise;
      }

      /**
       * @description Method to transform model into the smallest representation.
       *   This helps reduce the amount of traffic our server has to deal with, in
       *   addition to altering models to conform to server/API expectations (in
       *   many cases).
       *
       * @returns {Object} minimum necessary representation of model
       */
      toMinimumModel() {
        const minimumModel = _.pick(this, _.concat(EDITABLE_FIELDS));

        minimumModel.type = this.type;
        minimumModel.id = this.id;
        minimumModel.products = _.map(this.products, (product) =>
          toMinimumModelIfPossible(product)
        );
        minimumModel.roles = _(this.roles)
          .filter(
            (role) =>
              !UserRole.areTargetsRequiredForType(role.type) ||
              (role.targets && role.targets.length > 0)
          )
          .map((role) => toMinimumModelIfPossible(role))
          .value();
        minimumModel.userGroups = _.map(this.userGroups, (userGroup) =>
          toMinimumModelIfPossible(userGroup)
        );

        // The product's minimum model doesn't include license groups, add it back
        _.forEach(minimumModel.products, (product) => {
          product.licenseGroups = _.find(this.products, {id: product.id}).licenseGroups;
          product.licenseGroups = _.map(product.licenseGroups, (licenseGroup) =>
            toMinimumModelIfPossible(licenseGroup)
          );
        });

        // Remove products with no license groups
        _.remove(minimumModel.products, ['licenseGroups.length', 0]);

        // Since the user can have raw nested objects or model objects, only call toMinimumModel() if we can.
        function toMinimumModelIfPossible(obj) {
          if (_.isFunction(obj.toMinimumModel)) {
            return obj.toMinimumModel();
          }
          return obj;
        }

        return minimumModel;
      }

      /**
       * @description Method to construct a OrganizationUser from a data Object containing user information.
       *
       * @param {Object} responseData Object containing user data
       * @returns {OrganizationUser} Reference to OrganizationUser
       */
      static apiResponseTransformer(responseData) {
        // create new OrganizationUser from data Object
        const user = new OrganizationUser(responseData);
        if (feature.isDisabled('temp_parkour_mm')) {
          user.roles = _.reject(user.roles, {type: ROLE.ADMIN.PRODUCT_SUPPORT});
        }

        // update developerProducts using the roles field of response data with the type LICENSE_DEV_ADMIN
        user.developerProducts = organizationUserUtils.initDeveloperProducts(user.roles);

        // update product configurations
        user.licenseGroups = organizationUserUtils.initProductConfigurations(user.licenseGroups);

        // update products
        user.products = organizationUserUtils.initProducts(user.products);

        // update roles
        user.roles = organizationUserUtils.initRoles(user.roles);

        // update user groups
        user.userGroups = organizationUserUtils.initUserGroups(user.userGroups);

        return user;
      }

      static canTransform(data) {
        const memberType = new MemberType(_.get(data, 'type'), _.get(data, 'id'));
        return memberType.isUser();
      }

      /**
       * @description Method to retrieve an existing OrganizationUser from
       *              back-end data store.
       *
       * @param {Object} options options for the OrganizationUser as detailed below
       * @param {String} options.userId ID of the user to retrieve for this org
       *
       * @returns {OrganizationUser} Reference to pre-existing user
       */
      static get(options = {}) {
        let model = modelCache.get(MODEL.ORGANIZATIONUSER, options.userId);
        if (model) {
          return model;
        }

        model = new OrganizationUser({
          id: options.userId,
          includeUserGroupProducts: options.includeUserGroupProducts,
        });
        model.refresh();

        return model;
      }

      static getPatchOperationsForUpdate(user, options = {}) {
        let operations = _.map(EDITABLE_FIELDS, (fieldKey) => {
          if (!options.isIntegration && user[fieldKey] !== user.savedState[fieldKey]) {
            return {op: 'replace', path: `/${user.id}/${fieldKey}/${user[fieldKey]}`};
          }
          return undefined;
        });
        // then we get the PLC, user group, and role changes
        operations = _.union(
          operations,
          organizationUserUtils.getAdminRolePatches(user),
          organizationUserUtils.getProductLicensePatches(user),
          organizationUserUtils.getUserGroupPatches(user)
        );
        return operations;
      }

      /**
       * @description Method to retrieve an existing OrganizationUser from
       *              back-end data store.
       *
       * @param {Object} user Refresh existing OrganizationUser model
       *
       * @returns {OrganizationUser} Reference to pre-existing user
       */
      static getFromModel(user) {
        const cache = modelCache.get(MODEL.ORGANIZATIONUSER, user.id);

        if (cache) {
          return cache;
        }

        user.refresh();

        return user;
      }
    }

    // We register the cache size for this class
    Member.setupCache(MODEL.ORGANIZATIONUSER, 10);

    /**
     * Return the constructor function
     */
    return OrganizationUser;

    /**
     * @description Updates model with a nested form of itself recording state
     *     which may be later modified.
     *
     * @param {Object} model - organization user model to save state on
     */
    function registerSavedState(model) {
      if (model.isNew()) {
        model.savedState = {products: [], roles: [], userGroups: []};
      } else {
        model.savedState = _(model).pick(EDITABLE_FIELDS).cloneDeep();
        model.registerProductsSavedState();
        model.savedState.roles = _.cloneDeep(model.roles);
        model.savedState.userGroups = _.cloneDeep(model.userGroups);
      }
    }
  }
})();
/* eslint-enable */
