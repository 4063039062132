import {log} from '@admin-tribe/binky';
import {GoUrl, ModalDialog, OverlayWait, WizardStepList, useStore} from '@admin-tribe/binky-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import AddOidcToDirectory from 'features/settings/components/directories/add-oidc-to-directory/AddOidcToDirectory';
import {DirectorySetupContextProvider} from 'features/settings/components/directory/DirectorySetupContext';
import SetupJit from 'features/settings/components/setup-jit/SetupJit';
import {IDP_TYPES, UPDATE_STRATEGIES} from 'features/settings/entities/IdpEntity';
import useDirectorySetup from 'features/settings/hooks/directory-setup/useDirectorySetup';
import DirectorySetupStore, {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';

const MODAL_ID = 'add-oidc-from-deeplink-modal';

const AddOidcFromDeeplinkModal = ({directories, goToDirectory, defaultProviderName}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const directorySetupStore = useStore(() => new DirectorySetupStore());
  const {
    createIdp,
    setIdpInfo,
    idpInfo,
    setupAuthenticationStepManager,
    ctaButtonLabelId,
    saveJitSettings,
  } = useDirectorySetup(directorySetupStore, SETUP_SECTIONS.SETUP_AUTHENTICATION.id);
  const [isLoading, setIsLoading] = useState(false);

  const WIZARD_STEPS = Object.values(SETUP_AUTHENTICATION_STEPS).map((step) =>
    intl.formatMessage({id: `settings.setupDirectoryAuthentication.steps.${step.id}`})
  );
  const currentStepIndex = Object.values(SETUP_AUTHENTICATION_STEPS).findIndex(
    (step) => step.id === setupAuthenticationStepManager.currentStep.id
  );

  const onDirectorySelected = useCallback(
    (selectedDirectoryId) => {
      directorySetupStore.directoryStore.setDirectoryId(selectedDirectoryId);
    },
    [directorySetupStore.directoryStore]
  );

  const onProviderSelected = useCallback(
    (selectedProvider) => {
      setIdpInfo({
        jitConfig: {
          accountCreation: true,
          syncStrategy: UPDATE_STRATEGIES.DO_NOT_UPDATE,
        },
        providerName: selectedProvider,
        type: IDP_TYPES.SOIDC,
      });
    },
    [setIdpInfo]
  );

  // set the idpInfo with the provided name, if given
  useEffect(() => {
    if (defaultProviderName) {
      onProviderSelected(defaultProviderName);
    }
  }, [onProviderSelected, defaultProviderName]);

  // if only one directory available, mark it as selected
  useEffect(() => {
    if (directories.length === 1) {
      onDirectorySelected(directories[0].id);
    }
  }, [directories, onDirectorySelected]);

  const onAddIdp = async () => {
    try {
      setIsLoading(true);
      const idpCreated = await createIdp();

      setIsLoading(false);

      // if the idp was created, go to the next step
      if (idpCreated) {
        setupAuthenticationStepManager.goNext();
      }
    } catch (error) {
      setIsLoading(false);
      log.error('[ID][FED][SIMPLIFY_OIDC] Error creating IdP', error);
    }
  };

  const onSetupJitDone = async () => {
    try {
      setIsLoading(true);
      await saveJitSettings();

      goToDirectory(directorySetupStore.directoryStore.directoryId);
    } catch (error) {
      setIsLoading(false);
      log.error('[ID][FED][SIMPLIFY_OIDC] Error saving JIT settings', error);
    }
  };

  const onNextButtonPress = () => {
    if (setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id) {
      onAddIdp();
    } else {
      onSetupJitDone();
    }
  };

  return (
    <ModalDialog id={MODAL_ID} minHeight="80vh">
      <Heading data-test-id="modal-heading" level={2} marginBottom="size-200">
        <FormattedMessage id="settings.directories.addSoidcModal.title" />
      </Heading>
      <Divider />

      <Content data-testid="modal-content">
        <DirectorySetupContextProvider directorySetupStore={directorySetupStore}>
          <WizardStepList currentStep={currentStepIndex} steps={WIZARD_STEPS} />

          <OverlayWait isLoading={isLoading} showContent>
            <SteppedView currentStep={setupAuthenticationStepManager.currentStep.id}>
              <StepItem id={SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id}>
                <AddOidcToDirectory
                  defaultProviderName={defaultProviderName}
                  directories={directories}
                  onDirectorySelected={onDirectorySelected}
                  onProviderSelected={onProviderSelected}
                />
              </StepItem>
              <StepItem id={SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id}>
                <Text>
                  <FormattedMessage
                    id="settings.setupDirectoryAuthentication.setupJitDescription"
                    values={{
                      link: (linkText) => (
                        <GoUrl name="admin_auto_account_creation">{linkText}</GoUrl>
                      ),
                    }}
                  />
                </Text>
                <SetupJit />
              </StepItem>
            </SteppedView>
          </OverlayWait>
        </DirectorySetupContextProvider>
      </Content>

      <ButtonGroup>
        <Button data-testid="cancel-button" onPress={() => dialog.dismiss()} variant="primary">
          <FormattedMessage id="settings.directories.addSoidcModal.buttons.cancel" />
        </Button>

        <Button
          data-testid="cta-button"
          isDisabled={
            !directorySetupStore.directoryStore.directoryId || !idpInfo.providerName || isLoading
          }
          onPress={onNextButtonPress}
          variant="accent"
        >
          <FormattedMessage id={ctaButtonLabelId} />
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
};

AddOidcFromDeeplinkModal.propTypes = {
  defaultProviderName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- the value comes from angular as well
  directories: PropTypes.array,
  goToDirectory: PropTypes.func,
};

export default observer(AddOidcFromDeeplinkModal);
