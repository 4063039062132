import {Locale} from '@admin-tribe/binky';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {AdditionalProduct} from '@pandora/react-contract-additional-product';
import {ContractMiniAppContentModel} from '@pandora/react-mini-app-contract';
import PropTypes from 'prop-types';
import React from 'react';

import rootStore from 'core/RootStore';

/**
 * @description Represents a wrapper for Pandora Additional Product Info
 *
 */
const AdditionalProducts = ({contractId}) => {
  const AdditionalProductWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(AdditionalProduct);

  return (
    <div data-testid="additional-products-container">
      <AdditionalProductWithContent
        aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
        contractId={contractId}
        locale={Locale.get().activeLocaleCode}
        orgId={rootStore.organizationStore.activeOrgId}
      />
    </div>
  );
};

AdditionalProducts.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
};

export default AdditionalProducts;
