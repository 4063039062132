// The options for the asset credential selector. Two settings, activities and connected accounts, have the same options: allow or do not allow.
const allowOptions = [
  {
    textKey: 'settings.assetSettings.credentials.option.allow',
    value: 'TRUE',
  },
  {
    textKey: 'settings.assetSettings.credentials.option.doNotAllow',
    value: 'FALSE',
  },
];

// The content for asset credentials setting component. Updating this array will add additional selector settings to the asset credentials subpage.
const AssetCredentialsSettingsContent = [
  {
    apiKey: 'contentCredentialsIndividualName',
    descriptionKey: 'settings.assetSettings.credentials.identificationName.description',
    options: [
      {
        textKey: 'settings.assetSettings.credentials.option.individual',
        value: 'TRUE',
      },
      {
        textKey: 'settings.assetSettings.credentials.option.organization',
        value: 'FALSE',
      },
    ],
    titleKey: 'settings.assetSettings.credentials.identificationName.title',
  },
  {
    apiKey: 'contentCredentialsActivities',
    descriptionKey: 'settings.assetSettings.credentials.activities.description',
    options: allowOptions,
    titleKey: 'settings.assetSettings.credentials.activities.title',
  },
  {
    apiKey: 'contentCredentialsConnectedAccounts',
    descriptionKey: 'settings.assetSettings.credentials.connectedAccounts.description',
    options: allowOptions,
    titleKey: 'settings.assetSettings.credentials.connectedAccounts.title',
  },
];

export default AssetCredentialsSettingsContent;
