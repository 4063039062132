import {ActionButton, Flex, Heading, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import SectionView from 'features/settings/common/components/section-view/SectionView';
import {goToUsersSwitchIdentityType} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

/**
 * Domain Enforcement requirements section to enforce Single Sign-On
 */
const EnforceSsoSection = () => (
  <SectionView data-testid="enforce-sso-section">
    <Flex direction="column" gap="size-200" height="100%">
      <Heading level={4} margin="0">
        <FormattedMessage id="settings.domainEnforcement.requirementsSection.SSO.title" />
      </Heading>
      <Text>
        <FormattedMessage
          id="settings.domainEnforcement.requirementsSection.SSO.description"
          values={{
            goUrl: (linkText) => (
              <GoUrl
                name="aac_restricting_domains_additional_settings"
                UNSAFE_style={{color: 'inherit'}}
              >
                {linkText}
              </GoUrl>
            ),
          }}
        />
      </Text>
      <ActionButton
        data-testid="edit-user-type-button"
        onPress={goToUsersSwitchIdentityType}
        width="size-2000"
      >
        <FormattedMessage id="settings.domainEnforcement.requirementsSection.SSO.button" />
      </ActionButton>
    </Flex>
  </SectionView>
);

export default EnforceSsoSection;
