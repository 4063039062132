import {canAssignAdmin} from 'core/admin/access/adminAccess';
import {shouldForceAllowAddAdminsOrUsers} from 'core/organizations/access/organizationAccess';
import {canViewStorage} from 'core/storage/access/storageAccess';

/**
 * @description Method to determine whether member can edit admin rights or not.
 *
 * @param {OrganizationUser} member - the user or administrator
 * @returns {Boolean} whether member can edit admin rights or not.
 */
function canEditAdminRights(member) {
  return (
    canAssignAdmin() &&
    !member.getType().isTechnicalAccount() &&
    // Some migration types override the read-only state to allow admins to be added
    // while setting up for the migration, however do not allow admins to be edited
    // in this state.
    !shouldForceAllowAddAdminsOrUsers()
  );
}

/**
 * @description Method to find the SUPPORT_ADMIN role of the member.
 *
 * @param {Object} options
 * @param {String} options.role - particular user role
 * @param {UserRole} options.userRoles - roles assigned to the member
 * @returns {Promise} a promise which resolves with the found role.
 */
async function getStorageAdminRole({role, userRoles}) {
  let storageAdminRole;
  const checkAccess = async () => {
    if (await canViewStorage()) {
      storageAdminRole = userRoles?.find((el) => el?.type === role);
    }
  };
  await checkAccess();

  return storageAdminRole;
}

export {canEditAdminRights, getStorageAdminRole};
