import {DOMAIN_STATUS} from '@admin-tribe/binky';
import {Button, DialogContainer, Item, Picker} from '@adobe/react-spectrum';
import {TableActions, TableFilters, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import DomainListActionMenu from 'features/settings/common/components/domain-list-table/domain-list-table-actions/DomainListActionMenu';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import RemoveDomainConfirmationModal from 'features/settings/components/directory/domains/directory-domains-table/RemoveDomainConfirmationModal';
import AddDomainsModal from 'features/settings/components/domains/add-domains-modal/AddDomainsModal';
import {TABLE_SECTION_ACTIONS} from 'features/settings/hooks/useDirectoryTable';

const DOMAIN_STATUS_FILTER_OPTIONS = [
  {
    id: '',
    label: 'settings.domainsTable.statusPicker.allStatuses',
  },
  {
    id: DOMAIN_STATUS.ACTIVE,
    label: 'settings.domainsTable.statusPicker.active',
  },
  {
    id: DOMAIN_STATUS.CLAIMED,
    label: 'settings.domainsTable.statusPicker.needsApproval',
  },
  {
    id: DOMAIN_STATUS.VALIDATED,
    label: 'settings.domainsTable.statusPicker.needsDirectory',
  },
  {
    id: DOMAIN_STATUS.UNCLAIMED,
    label: 'settings.domainsTable.statusPicker.needsValidation',
  },
  {
    id: DOMAIN_STATUS.WITHDRAWN,
    label: 'settings.domainsTable.statusPicker.withdrawn',
  },
];

const DIALOGS = {
  ADD_DOMAIN: 'ADD_DOMAIN',
  REMOVE_DOMAIN: 'REMOVE_DOMAIN',
};

const DomainListTableActions = ({
  onTableSectionChange,
  onDomainsAdded,
  onDomainsLinked,
  onDomainsValidated,
  onDomainsRemoved,
  onDomainsTransferred,
}) => {
  const intl = useIntl();
  const {domainList, canAddDomains} = useDomainsListContext();
  const {tableSectionUtils, tableSectionState} = useTableSectionContext();

  const [dialog, setDialog] = useState();

  const handleDomainsLinked = () => {
    tableSectionUtils.clearSelectedItems();
    onDomainsLinked?.();
  };

  const handleDomainsRemoved = () => {
    tableSectionUtils.clearSelectedItems();
    onDomainsRemoved();
  };

  return (
    <>
      <TableFilters
        hasSearch
        isDisabled={domainList.hasLoadingError}
        label={intl.formatMessage({
          id: 'settings.domainsTable.search.label',
        })}
      >
        <Picker
          data-testid="domain-status-filter"
          defaultSelectedKey=""
          items={DOMAIN_STATUS_FILTER_OPTIONS}
          label={intl.formatMessage({
            id: 'settings.domainsTable.statusPicker.label',
          })}
          onSelectionChange={(id) =>
            onTableSectionChange({
              action: TABLE_SECTION_ACTIONS.FILTER_BY_STATUS,
              payload: id,
            })
          }
        >
          {(item) => (
            <Item>
              {intl.formatMessage({
                id: item.label,
              })}
            </Item>
          )}
        </Picker>
      </TableFilters>
      <TableActions>
        <Button
          data-testid="add-domain-button"
          isDisabled={!canAddDomains}
          onPress={() => setDialog(DIALOGS.ADD_DOMAIN)}
          variant="accent"
        >
          <FormattedMessage id="settings.domainsTable.button.addDomains" />
        </Button>
        <Button
          data-testid="remove-domain"
          isDisabled={tableSectionState.selectedItems.length === 0}
          onPress={() => setDialog(DIALOGS.REMOVE_DOMAIN)}
          variant="negative"
        >
          <FormattedMessage id="settings.directoryDomains.removeDomain" />
        </Button>
        <DomainListActionMenu
          onDomainsLinked={handleDomainsLinked}
          onDomainsTransferred={onDomainsTransferred}
          onDomainsValidated={onDomainsValidated}
        />
      </TableActions>

      <DialogContainer onDismiss={() => setDialog(undefined)}>
        {dialog === DIALOGS.ADD_DOMAIN && <AddDomainsModal onDomainsAdded={onDomainsAdded} />}
        {dialog === DIALOGS.REMOVE_DOMAIN && (
          <RemoveDomainConfirmationModal
            domains={tableSectionState.selectedItems}
            onConfirm={handleDomainsRemoved}
          />
        )}
      </DialogContainer>
    </>
  );
};

DomainListTableActions.displayName = 'DomainListTableActions';

DomainListTableActions.propTypes = {
  /**
   * Callback that is called when domains have been added
   */
  onDomainsAdded: PropTypes.func,
  /**
   * Callback that is called when a selection of domains have been linked to a directory
   */
  onDomainsLinked: PropTypes.func,
  /**
   * Callback that is called when domains have been removed
   */
  onDomainsRemoved: PropTypes.func,
  /**
   * Callback that is called when a selection of domains have been transferred from one directory to another
   */
  onDomainsTransferred: PropTypes.func,
  /**
   * Callback that is called when at least one domain has been successfully validated
   */
  onDomainsValidated: PropTypes.func,
  /**
   * Callback that represents a reducer for the domain list table actions
   */
  onTableSectionChange: PropTypes.func,
};

export default DomainListTableActions;
export {DOMAIN_STATUS_FILTER_OPTIONS};
