(function () {
  /**
   * @deprecated ported to src2/app/features/overview/hva-cards/value-discovery-tasks/CreateUserGroupsHva
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    _,
    $delegate,
    $q,
    $state,
    $translate,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    organizationAccess,
    SVG_CLASS,
    UserGroupList
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.CREATE_USER_GROUPS,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForEnterprise) {
          cache.canViewValueDiscoveryTasksForEnterprise =
            organizationAccess.canViewValueDiscoveryTasksForEnterprise();
        }

        const {canViewValueDiscoveryTasksForEnterprise} = cache;
        return canViewValueDiscoveryTasksForEnterprise
          .then((canView) => (canView ? UserGroupList.get().$promise : $q.reject()))
          .then((userGroupList) => {
            if (_.isEmpty(userGroupList.items)) {
              const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.createUserGroups';
              return [
                new HvaCard({
                  ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                  description: $translate.instant(`${namespace}.description`),
                  dismissOnCTAClick: true,
                  header: $translate.instant(`${namespace}.header`),
                  id: 'hva-create-user-groups',
                  isDismissible: true,
                  onCTAClick: () => {
                    $state.go('users.user-groups');
                  },
                  sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                  svgClass: SVG_CLASS.USER_GROUPS,
                }),
              ];
            }
            return $q.reject();
          });
      }
    }
  }
})();
