(function () {
  const componentName = 'appAddAdminModal';
  /**
   * @deprecated use src2 Admin modal
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      adminList: '<?',
      modalId: '@?',
      onAddAdmin: '<?',
      onClosed: '&?',
      onInitialized: '&?',
      pageContext: '<',
      user: '<?',
    },
    controller,
    templateUrl: 'app/widgets/users/add-admin-modal/add-admin-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    ADD_USER_FORM_VALIDITY_CHANGE,
    ADMIN_ROLES_FORM_ERROR,
    ADMIN_ROLES_FORM_VALIDITY_CHANGE,
    AnalyticsEvent,
    COMMON_EVENTS,
    DisplayMessage,
    OrganizationUser,
    panelManager,
    toastManager,
    WIZARD_CHANGE,
    WIZARD_NEXT,
    WIZARD_PREVIOUS
  ) {
    const vm = this;
    vm.$onInit = $onInit;

    /////////////

    function $onInit() {
      vm.closeModal = closeModal;
      vm.hasTargetUser = !!vm.user;
      vm.id = vm.modalId || 'add-admin-modal';
      vm.isWizardNextButtonDisabled = isWizardNextButtonDisabled;
      vm.onClose = onClose;
      vm.onOpen = onOpen;
      vm.user = vm.user || new OrganizationUser();
      vm.userBackup = null;
      vm.wizardId = 'add-admin-modal-wizard';
      vm.wizardNext = wizardNext;
      vm.wizardPrevious = wizardPrevious;
      vm.wizardStepTitles = _.concat(
        vm.hasTargetUser
          ? []
          : $translate.instant('widgets.users.addAdminModal.wizard.titles.basicInformation'),
        [
          $translate.instant('widgets.users.addAdminModal.wizard.titles.adminTypes'),
          $translate.instant('widgets.users.addAdminModal.wizard.titles.summary'),
        ]
      );
      vm.wizardStepCount = vm.wizardStepTitles.length;

      reset();
      onWizardChange(null, {
        stepIndex: 0,
        totalSteps: vm.wizardStepCount,
        wizardId: vm.wizardId,
      });

      $scope.$on(WIZARD_CHANGE, onWizardChange);
      $scope.$on(ADD_USER_FORM_VALIDITY_CHANGE, onAddUserFormChange);
      $scope.$on(ADMIN_ROLES_FORM_VALIDITY_CHANGE, onAdminTypesFormChange);
      $scope.$on(ADMIN_ROLES_FORM_ERROR, onError);

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function closeModal() {
      panelManager.close(vm.id);
    }

    function isWizardNextButtonDisabled() {
      return !vm.isCurrentWizardStepValid || vm.displayMessage || vm.refreshing || vm.isSubmitting;
    }

    function onAddUserFormChange(event, isValid) {
      vm.isAddUserFormValid = isValid;
      updateWizardNextButton();
    }

    function onAdminTypesFormChange(event, isValid) {
      vm.isAdminRolesFormValid = isValid;
      updateWizardNextButton();
    }

    function onClose() {
      reset();
      vm.isOpen = false;
      vm.user = vm.userBackup;
      vm.userBackup = null;

      _.invoke(vm, 'onClosed');
    }

    function onOpen() {
      vm.isOpen = true;
      vm.userBackup = vm.user;
      vm.user = _.cloneDeep(vm.user);
    }

    function onError() {
      vm.displayMessage = new DisplayMessage();
    }

    function onSuccess() {
      toastManager.showSuccessToast(
        $translate.instant(
          vm.wasSubmittedUserNew
            ? 'widgets.users.addAdminModal.toast.addedSuccess'
            : 'widgets.users.addAdminModal.toast.updatedSuccess',
          {
            name: vm.user.getDisplayNameOrEmail(),
          }
        )
      );
      if (vm.onAddAdmin) {
        vm.onAddAdmin();
      }
      closeModal();
      triggerAnalytics();
    }

    function onWizardChange(event, state) {
      if (state.wizardId !== vm.wizardId || !vm.isOpen) {
        return;
      }
      vm.wizardStep = state.stepIndex < 0 ? 0 : state.stepIndex;
      vm.nextButtonLabelKey =
        vm.wizardStep === state.totalSteps - 1
          ? 'widgets.users.addAdminModal.footerButtons.save'
          : 'widgets.users.addAdminModal.footerButtons.next';
      vm.isWizardPreviousButtonVisible = vm.wizardStep > 0;
      refreshUserIfNecessary();
      updateWizardNextButton();
    }

    function refreshUserIfNecessary() {
      const isOnAdminRolesStep = vm.hasTargetUser ? vm.wizardStep === 0 : vm.wizardStep === 1;
      if (
        isOnAdminRolesStep &&
        !vm.user.isNew() &&
        vm.refreshedUserId !== vm.user.id &&
        vm.user.roles.length === 0
      ) {
        vm.refreshing = true;
        vm.refreshedUserId = vm.user.id;
        vm.promiseToWait = vm.user.refresh();
        vm.promiseToWait.catch(onError).finally(() => {
          vm.refreshing = false;
        });
      }
    }

    function reset() {
      vm.isAddUserFormValid = !!vm.hasTargetUser;
      vm.isAdminRolesFormValid = false;
      vm.isCurrentWizardStepValid = false;
      vm.isSubmitting = false;
      vm.isWizardPreviousButtonVisible = false;
      vm.displayMessage = undefined;
      vm.promiseToWait = $q.resolve();
      vm.refreshedUserId = null;
      vm.refreshing = false;
      vm.wasSubmittedUserNew = false;
      vm.wizardStep = 0;
      $scope.$broadcast(COMMON_EVENTS.RESET);
    }

    function submit() {
      vm.isSubmitting = true;
      vm.wasSubmittedUserNew = vm.user.isNew();
      vm.analyticsState = {
        newUser: vm.user.isNew(),
        roles: vm.user.getUnsavedRoleChanges(),
      };
      if (vm.user.isNew() || !vm.adminList) {
        vm.promiseToWait = vm.user.save();
        if (vm.adminList) {
          // eslint-disable-next-line promise/catch-or-return
          vm.promiseToWait.then(() => {
            vm.adminList.filter.query = '';
            return vm.adminList.refresh();
          });
        }
      } else {
        vm.adminList.addModifiedUsers([vm.user]);
        vm.promiseToWait = vm.adminList.save();
      }
      vm.promiseToWait.then(onSuccess, onError).finally(() => {
        vm.isSubmitting = false;
      });
    }

    function updateWizardNextButton() {
      if (vm.hasTargetUser) {
        vm.isCurrentWizardStepValid = !(vm.wizardStep === 0 && !vm.isAdminRolesFormValid);
      } else {
        vm.isCurrentWizardStepValid = !(
          (vm.wizardStep === 0 && !vm.isAddUserFormValid) ||
          (vm.wizardStep === 1 && !vm.isAdminRolesFormValid)
        );
      }
    }

    function wizardNext() {
      if (vm.wizardStep === vm.wizardStepCount - 1) {
        submit();
      } else {
        $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
      }
    }

    function wizardPrevious() {
      $scope.$broadcast(WIZARD_PREVIOUS, vm.wizardId);
    }

    function triggerAnalytics() {
      const addedRoleTypes = _.map(vm.analyticsState.roles.added, 'type');
      const removedRoleTypes = _.map(vm.analyticsState.roles.removed, 'type');
      AnalyticsEvent.dispatch({
        attributes: {
          rolesAddedCount: _.get(vm.analyticsState.roles.added, 'length'),
          rolesAddedType: _.uniq(addedRoleTypes).join(','),
          rolesRemovedCount: _.get(vm.analyticsState.roles.removed, 'length'),
          rolesRemovedType: _.uniq(removedRoleTypes).join(','),
        },
        componentMethod: vm.analyticsState.newUser ? 'addAdmin' : 'editAdmin',
        componentMethodType: 'submit',
        componentName,
        pageContext: vm.pageContext,
      });
    }
  }
})();
