// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {
  SophiaPromoActions,
  SophiaPromoBasePrice,
  SophiaPromoCloseButton,
  SophiaPromoDescriptionWithTerms,
  SophiaPromoDiscountedPrice,
  SophiaPromoGradient,
  SophiaPromoHoverOverTooltip,
  SophiaPromoIcon,
  SophiaPromoTag,
  SophiaPromoTitle,
} from 'common/components/sophia/SophiaPromoHelper';

const badgeStyles = {
  borderStyles: {borderBottomRightRadius: 0, borderTopRightRadius: 0},
  position: {right: 'calc(size-0 - size-400)', top: 'size-50'},
};

/* eslint-disable complexity -- lots of checks as it has optional layout elements for multiple component wrappers */
const SophiaPromoRightRailView = ({sophiaContent, onDismiss}) => {
  const layoutOptions = {
    shouldRenderActions: () =>
      (!!sophiaContent.primaryCTAAction && !!sophiaContent.primaryCTALabel) ||
      (!!sophiaContent.secondaryCTAAction && !!sophiaContent.secondaryCTALabel),
    shouldRenderBasePrice: () => !!sophiaContent.regularPrice,
    shouldRenderClose: () => !!onDismiss,
    shouldRenderDescription: () => !!sophiaContent.description,
    shouldRenderDiscountedPrice: () => !!sophiaContent.discountedPrice,
    shouldRenderGradient: () => !!sophiaContent.backgroundImage,
    shouldRenderHoverOver: () => !!sophiaContent.disclosure,
    shouldRenderIcon: () => !!sophiaContent.imageUrl,
    shouldRenderTag: () => !!sophiaContent.tag && !!sophiaContent.badgeType,
    shouldRenderTermsAndConditions: () =>
      !!sophiaContent.termsAndConditions && !sophiaContent.disclosure,
    shouldRenderTitle: () => !!sophiaContent.title,
  };

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="one-console-promo-side"
      padding="size-400"
      position="relative"
      UNSAFE_style={{
        backgroundColor: sophiaContent.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
    >
      {layoutOptions.shouldRenderGradient() && (
        <SophiaPromoGradient sophiaContent={sophiaContent} />
      )}
      {layoutOptions.shouldRenderClose() && <SophiaPromoCloseButton onDismiss={onDismiss} />}

      <View data-testid="one-console-promo-side-header" position="relative">
        {layoutOptions.shouldRenderIcon() && <SophiaPromoIcon sophiaContent={sophiaContent} />}
        {layoutOptions.shouldRenderTag() && (
          <SophiaPromoTag badgeStyles={badgeStyles} sophiaContent={sophiaContent} />
        )}
      </View>

      <View
        borderBottomWidth="thin"
        borderColor="gray-300"
        data-testid="one-console-promo-side-content"
      >
        <Flex direction="column">
          {layoutOptions.shouldRenderTitle() && <SophiaPromoTitle sophiaContent={sophiaContent} />}
          {layoutOptions.shouldRenderDescription() && (
            <SophiaPromoDescriptionWithTerms
              displayTerms={layoutOptions.shouldRenderTermsAndConditions()}
              sophiaContent={sophiaContent}
            />
          )}

          {layoutOptions.shouldRenderHoverOver() && (
            <SophiaPromoHoverOverTooltip sophiaContent={sophiaContent} />
          )}
        </Flex>
      </View>

      <Flex
        alignItems="center"
        data-testid="one-console-promo-side-footer"
        gap={{base: 'size-100', S: 'size-0'}}
        justifyContent="space-between"
        marginTop="size-175"
      >
        <Flex alignItems="start" data-testid="one-console-promo-side-price" direction="column">
          {layoutOptions.shouldRenderBasePrice() && (
            <SophiaPromoBasePrice sophiaContent={sophiaContent} />
          )}
          {layoutOptions.shouldRenderDiscountedPrice() && (
            <SophiaPromoDiscountedPrice sophiaContent={sophiaContent} />
          )}
        </Flex>
        {layoutOptions.shouldRenderActions() && (
          <SophiaPromoActions sophiaContent={sophiaContent} />
        )}
      </Flex>
    </View>
  );
};
/* eslint-enable complexity -- re-enable */

SophiaPromoRightRailView.propTypes = {
  onDismiss: PropTypes.func,
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

export default SophiaPromoRightRailView;
