import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the AccessRequest page and its sub-components.
 */
const AccessRequestListContext = createContext({});
const useAccessRequestListContext = () => useContext(AccessRequestListContext);

const AccessRequestListContextProvider = ({children, listState}) => {
  const value = useMemo(
    () => ({
      ...listState,
      // this is trusteeList because we use the same table component for both trustee and access request
      trusteeList: listState.state,
    }),
    [listState]
  );

  return (
    <AccessRequestListContext.Provider value={value}>{children}</AccessRequestListContext.Provider>
  );
};

AccessRequestListContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the trustee list.
   */
  children: PropTypes.node,

  /**
   * The trust list state used by the provider to keep state of the children components.
   */
  listState: PropTypes.shape({
    loadTrusteeList: PropTypes.func,
    reloadTrusteeList: PropTypes.func,
    setTableOptions: PropTypes.func,
    state: PropTypes.shape({
      hasLoadingError: PropTypes.bool,
      isLoading: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types -- this is an instance so it makes it easier to test like this
      trusteeListData: PropTypes.object,
    }),
    tableOptions: PropTypes.shape({
      page: PropTypes.number,
      pageSize: PropTypes.number,
      searchQuery: PropTypes.string,
      sort: PropTypes.string,
      sortOrder: PropTypes.string,
      status: PropTypes.string,
    }),
    updateTrusteeList: PropTypes.func,
  }),
};

export {AccessRequestListContextProvider, useAccessRequestListContext};
