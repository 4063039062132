import {feature} from '@admin-tribe/binky';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import AddDirectoryDomainModal from 'features/settings/components/add-directory-domain-modal/AddDirectoryDomainModal';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import DnsImportDomainsModal from 'features/settings/components/dns-import-domains-modal/DnsImportDomainsModal';

const DIALOGS = {
  ADD_DOMAIN: Symbol('add-domain'),
  ADD_DOMAINS_BY_DNS: Symbol('add-domain-by-dns'),
};

/**
 * @deprecated Remove this when temp_react_port_settings is removed. AddDomainButtonPorted is the new component.
 * TODO: @neculaes - can we really remove this?  DirectoryDomains.jsx is currently importiing this
 */
const AddDomainButton = ({directoryId, refreshDomains}) => {
  const [dialog, setDialog] = useState(null);
  const {directoryStore} = useDirectoryContext();
  const {canAddDomains, reloadDomainList, directoryData} = useDomainsListContext();

  // Continue with importing domains when a new directory was created
  // and authentication config is complete.
  useEffect(() => {
    if (!URLSearchParams) {
      return;
    }

    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- neculaes@ to update
    const params = new URLSearchParams(window.location.search);

    if (params.has('setup') && params.get('setup')) {
      setDialog(DIALOGS.ADD_DOMAIN);
    }
  }, []);

  const handleAddDomain = () => {
    setDialog(DIALOGS.ADD_DOMAIN);
  };

  const handleAddDomainByDns = () => {
    setDialog(DIALOGS.ADD_DOMAINS_BY_DNS);
  };

  const onDomainsAdded = () => {
    if (feature.isDisabled('temp_refactor_directory_domains')) {
      directoryStore.domains.load();
      refreshDomains({clearCache: true});
      return;
    }

    reloadDomainList({}, {updateCount: true});
  };

  return (
    <>
      <Button isDisabled={!canAddDomains} onPress={handleAddDomain} variant="accent">
        <FormattedMessage id="settings.directoryDomains.addDomain" />
      </Button>

      <DialogContainer onDismiss={() => setDialog(null)}>
        {dialog === DIALOGS.ADD_DOMAINS_BY_DNS ? (
          <DnsImportDomainsModal
            directoryId={
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
              // istanbul ignore next -- not testing this
              feature.isDisabled('temp_refactor_directory_domains')
                ? directoryId
                : directoryData.directoryId
            }
            onDomainsAdded={onDomainsAdded}
          />
        ) : null}

        {dialog === DIALOGS.ADD_DOMAIN ? (
          <AddDirectoryDomainModal onAddManualDomainSelected={handleAddDomainByDns} />
        ) : null}
      </DialogContainer>
    </>
  );
};

AddDomainButton.propTypes = {
  directoryId: PropTypes.string,
  refreshDomains: PropTypes.func,
};

export default AddDomainButton;
