import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {isReadOnly} from 'core/organizations/access/organizationAccess';

import AllowListedDomainRemoveModal from './AllowListedDomainRemoveModal';

/**
 *  Trigger and button to open AllowlistedDomainRemoveModal
 */
const AllowlistedDomainRemoveTrigger = ({isDisabled, onCTA}) => {
  const intl = useIntl();

  const {tableSectionUtils} = useTableSectionContext();

  if (isReadOnly()) {
    return null;
  }

  return (
    <DialogTrigger>
      <Button isDisabled={isDisabled} variant="negative">
        {intl.formatMessage({
          id: 'settings.assetSettings.allowlistedDomain.table.removeButton',
        })}
      </Button>
      <AllowListedDomainRemoveModal
        onCTA={() => {
          onCTA();
          tableSectionUtils.clearSelectedItems();
          tableSectionUtils.deleteAllFilters();
        }}
      />
    </DialogTrigger>
  );
};

AllowlistedDomainRemoveTrigger.propTypes = {
  /**
   * True if button should be disabled
   */
  isDisabled: PropTypes.bool,

  /**
   * Callback to invoke when the remove confirmation is pressed.
   */
  onCTA: PropTypes.func.isRequired,
};

export default AllowlistedDomainRemoveTrigger;
