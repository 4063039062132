(function () {
  /**
   * @deprecated ported to src2/app/common/components/migrate-device-license
   */
  angular
    .module('app.widgets.contracts.migrations')
    .component('appTeamToEnterpriseMigrationModal', {
      bindings: {
        eligibleContract: '<',
      },
      controller,
      templateUrl:
        'app/widgets/contracts/migrations/team-to-enterprise-migration-modal.component.html',
    });

  /* @ngInject */
  function controller(
    $scope,
    _,
    COMMON_EVENTS,
    DisplayMessage,
    feature,
    OrganizationContractMigrations,
    panelManager,
    TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID,
    TEAM_TO_ENTERPRISE_MIGRATION_WIZARD_ID,
    WIZARD_CHANGE,
    WIZARD_NEXT
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      onClickCancel,
      onClickConfirm,
      onClickContinue,
      onClickOk,
      onOpen: reset,
    });

    //////////////////

    $scope.$on(WIZARD_CHANGE, onWizardChange);

    function onWizardChange(event, state) {
      const currentWizardStep = state.stepIndex + 1;
      vm.isWizardStep1 = currentWizardStep === 1;
      vm.isWizardStep2 = currentWizardStep === 2;
      vm.isWizardStep3 = currentWizardStep === 3;
    }

    function $onChanges(changesObj) {
      if (_.get(changesObj, 'eligibleContract')) {
        initialization();
      }
    }

    function initialization() {
      _.assign(vm, {
        hasErrors: false,
        modalId: TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID,
        wizardId: TEAM_TO_ENTERPRISE_MIGRATION_WIZARD_ID,
      });

      vm.contractMigration = getContractMigration({
        contractId: vm.eligibleContract.id,
        type: vm.eligibleContract.getTeamToEnterpriseEligibleMigration().type,
      });

      vm.contractMigration.$promise
        .then(initMigrationLicenses)
        .then(calculateTotalLicensesCount)
        .catch(onError);
    }

    function initMigrationLicenses(data) {
      vm.migrationLicenses = _.get(data, 'items[0].migrationContext.products');
    }

    function calculateTotalLicensesCount() {
      vm.totalLicensesCount = _.reduce(
        vm.migrationLicenses,
        (sum, entry) => sum + entry.quantity,
        0
      );
    }
    function getContractMigration(options) {
      return OrganizationContractMigrations.get(options);
    }

    function onClickOk() {
      panelManager.close(vm.modalId);
    }

    function onClickCancel() {
      panelManager.close(vm.modalId);
    }

    function onClickConfirm() {
      $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
      vm.contractMigrationPromise = vm.contractMigration.migrate();
    }

    function onClickContinue() {
      $scope.$broadcast(WIZARD_NEXT, vm.wizardId);
    }

    function onError() {
      vm.displayMessage = new DisplayMessage();
      vm.hasErrors = true;
    }

    function reset() {
      vm.isWizardStep1 = true;
      vm.isWizardStep2 = false;
      vm.isWizardStep3 = false;
      vm.contractMigrationPromise = undefined;
      vm.displayMessage = undefined;
      $scope.$broadcast(COMMON_EVENTS.RESET);
    }
  }
})();
