import {BULK_OPERATION_MODE} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';

// The operations supported by the UserOperations menu.
// The operations supported by the BulkOperations modal will use the bulk operations mode as the key.
const OPERATIONS = {
  ADD: BULK_OPERATION_MODE.ADD,
  EDIT: BULK_OPERATION_MODE.EDIT,
  EXPORT_ASSET_MIGRATIONS: 'EXPORT_ASSET_MIGRATIONS',
  EXPORT_USERS: 'EXPORT_USERS',
  LICENSE_DEFICIT_REPORT: 'LICENSE_DEFICIT_REPORT',
  LICENSE_STATUS_REPORT: 'LICENSE_STATUS_REPORT',
  OFFER_SWITCH_MIGRATION: 'OFFER_SWITCH_MIGRATION',
  OPERATION_RESULTS: 'OPERATION_RESULTS',
  REMOVE: BULK_OPERATION_MODE.REMOVE,
  SWITCH_IDENTITY_TYPE: BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE,
  UNASSIGN: BULK_OPERATION_MODE.UNASSIGN,
};

// These are the ordered sections of the Users Operations menu.
// These map the operation key to the UserOperations property which determines if the item should be shown.

const ORDERED_BULK_OPERATIONS = [
  {key: OPERATIONS.ADD, op: 'canAdd'},
  {key: OPERATIONS.EDIT, op: 'canEdit'},
  {key: OPERATIONS.SWITCH_IDENTITY_TYPE, op: 'canSwitchIdentityType'},
  {key: OPERATIONS.REMOVE, op: 'canRemove'},
  {key: OPERATIONS.OFFER_SWITCH_MIGRATION, op: 'canMigrateUsers'},
  {key: OPERATIONS.UNASSIGN, op: 'canUnassign'},
];

const ORDERED_EXPORT_OPERATIONS = [
  {key: OPERATIONS.EXPORT_USERS, op: 'canExportUsers'},
  {key: OPERATIONS.EXPORT_ASSET_MIGRATIONS, op: 'canDownloadAssetMigrations'},
];

const ORDERED_RESULT_OPERATIONS = [
  {key: OPERATIONS.OPERATION_RESULTS, op: 'canViewResults'},
  {key: OPERATIONS.LICENSE_DEFICIT_REPORT, op: 'canExportLicenseDeficitReport'},
  {key: OPERATIONS.LICENSE_STATUS_REPORT, op: 'canExportLicenseStatusReport'},
];

const OPERATION_DOWNLOADED_FILENAMES = {
  [OPERATIONS.EXPORT_USERS]: 'users.csv',
  [OPERATIONS.EXPORT_ASSET_MIGRATIONS]: 'asset_migrations.csv',
  [OPERATIONS.LICENSE_DEFICIT_REPORT]: 'license-deficit-report.csv',
  [OPERATIONS.LICENSE_STATUS_REPORT]: 'license-status-report.csv',
};

const CSV_MAX_ATTEMPTS_ERROR = 'Max attempts for retrieving CSV in one minute.';

export {
  CSV_MAX_ATTEMPTS_ERROR,
  OPERATIONS,
  ORDERED_BULK_OPERATIONS,
  OPERATION_DOWNLOADED_FILENAMES,
  ORDERED_EXPORT_OPERATIONS,
  ORDERED_RESULT_OPERATIONS,
};
