(function () {
  const componentName = 'appAddIntegrationModal';
  /**
   * @deprecated with 'temp_react_port_users'
   */
  angular.module('app.widgets.integrations').component(componentName, {
    bindings: {
      productProfile: '<',
      userList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/integrations/add-integration-modal/add-integration-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $q,
    $translate,
    ADD_INTEGRATION_MODAL_ID,
    AnalyticsEvent,
    DisplayMessage,
    OrganizationIntegrationList,
    PageContext,
    PAGE_TARGET_TYPE,
    panelManager,
    ProductConfigurationIntegrationList,
    toastManager,
    Selection
  ) {
    const vm = this;

    _.assign(vm, {
      closeModal,
      id: ADD_INTEGRATION_MODAL_ID,
      integrationDisplay: {
        avatar: false,
        checkbox: true,
        createdOn: true,
        lastModified: true,
        productIcons: true,
      },
      integrationIsEditable: _.stubTrue,
      isReadyToSubmit,
      onClose,
      onOpen,
      pageContext: new PageContext({targetType: PAGE_TARGET_TYPE.INTEGRATION}),
      selection: new Selection('id'),
      showErrorBanner: false,
      submit,
    });

    function closeModal() {
      panelManager.close(vm.id);
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function isReadyToSubmit() {
      return vm.selection && vm.selection.items.length > 0;
    }

    function onClose() {
      hidePageBanner();
    }

    function onOpen() {
      vm.promiseToWait = $q
        .all([
          OrganizationIntegrationList.get().$promise,
          vm.productProfile.$promise,
          vm.userList.$promise,
        ])
        .then((result) => {
          const [organizationIntegrationList, productProfile, userList] = result;
          vm.availableIntegrations = organizationIntegrationList;
          vm.availableIntegrations.items = _.filter(
            organizationIntegrationList.items,
            (orgIntegration) => !_.find(userList.items, {id: orgIntegration.id})
          );
          vm.availableIntegrations.pagination.itemCount = vm.availableIntegrations.items.length;
          vm.noIntegrationToAdd = vm.availableIntegrations.items.length === 0;
          vm.modalContextName = productProfile.name;
          vm.modalTitle = $translate.instant('widgets.integrations.addIntegrationModal.title2', {
            name: vm.modalContextName,
          });
        })
        .catch(showPageBanner);
      vm.noUserMessage = $translate.instant('widgets.users.all.noIntegrations2');
    }

    function onSubmitError() {
      showPageBanner();
      vm.selection.deselectAllItems();
    }

    function onSubmitSuccess() {
      toastManager.showSuccessToast();
      panelManager.close(vm.id);
      vm.selection.deselectAllItems();
    }

    function save() {
      _.forEach(vm.selection.items, (user) => {
        vm.userList.add([user.toMinimumModel()]);
      });
      return vm.userList.save();
    }

    function showPageBanner() {
      const params = {
        body: $translate.instant(
          'widgets.integrations.addIntegrationModal.pageBanner.errorMessage'
        ),
        header: $translate.instant(
          'widgets.integrations.addIntegrationModal.pageBanner.errorTitle'
        ),
        variant: 'error',
      };
      vm.displayMessage = new DisplayMessage(params);
    }

    function submit() {
      hidePageBanner();
      triggerAnalytics();
      vm.isSubmitting = true;
      vm.promiseToWait = save()
        .then(onSubmitSuccess)
        .catch(onSubmitError)
        .finally(() => {
          vm.isSubmitting = false;
        });
    }

    function triggerAnalytics() {
      const attributes = {};
      if (vm.productProfile) {
        attributes.productOffer = vm.modalContextName;
      }
      attributes.integrationsAddedCount = vm.selection.items.length;
      AnalyticsEvent.dispatch({
        attributes,
        componentMethod: 'addIntegration',
        componentMethodType: 'submit',
        componentName,
        pageContext: vm.pageContext,
      });
    }
  }
})();
