import {GoUrl} from '@admin-tribe/binky-ui';
import {Divider, Flex, Heading, View} from '@adobe/react-spectrum';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './DirectoryTypeCard.pcss';

const DirectoryTypeCard = ({
  title,
  selected,
  testId,
  description,
  goUrlName,
  learnMore,
  benefits,
}) => {
  const intl = useIntl();

  return (
    <Card allowsSelection={false} data-test-id={testId} selected={selected} styleName="card">
      <Heading>
        <FormattedMessage id={title} />
      </Heading>
      <Flex direction="column" gap="size-150">
        <FormattedMessage id={description} />
        <Divider size="S" />

        <View>
          <strong>
            <FormattedMessage id="settings.directories.createDirectorySection.benefits" />
          </strong>
          <ul styleName="benefits-list">
            {benefits.map((benefit) => (
              <li key={benefit}>
                <FormattedMessage id={benefit} />
              </li>
            ))}
          </ul>
        </View>
      </Flex>
      <GoUrl aria-label={intl.formatMessage({id: learnMore})} name={goUrlName}>
        <FormattedMessage id={learnMore} />
      </GoUrl>
    </Card>
  );
};

DirectoryTypeCard.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  goUrlName: PropTypes.string.isRequired,
  learnMore: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  testId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DirectoryTypeCard;
