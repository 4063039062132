import {getDaysLeft, getTaxTerm} from '@admin-tribe/binky';
import {useMemo} from 'react';

import {
  getAllCancellingLicensesCount,
  getCurrency,
  getDisplayNamesFromSelectedSeats,
  getETFAmount,
  getExistingBilling,
  getNextBilling,
  getRefundAmount,
  getTerminationDate,
  isFullCancel,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * @description A helper hook to calculate cancellation and pricing information to render the
 * cancellation details. Uses Products Change model provided useProductsChangeContext hook.
 * @param {Contract} contract - the contract to determine tax and recurrence terms
 * @returns {Object} state - Object composed of the following cancellation details properties
 *          {Number} [state.cancelFeeAmt] - Early Termination Fee amount, if present
 *          {Object} state.currency - Currency object used for the prices
 *          {Number} state.daysCount - Number of days from now until the termination date
 *          {Boolean} state.isFullCancelFlow - Whether the cancellation is full or partial
 *          {Number} state.licenseCount - Number of all cancelling licenses count
 *          {Number} state.originalInvoiceAmount - Current or original billing total without tax
 *          {Number} state.recurrenceTerm - Recurrence Term, i.e. MONTHLY
 *          {Number} state.reducedInvoiceAmount - New billing after the cancellation total without tax
 *          {Number} [state.refundAmount] - Refund amount, if present
 *          {String} state.taxTerm - Tax term, i.e. TAX
 *          {Date} state.terminationDate - The date when the cancellation takes effect
 *          {Array<String>} state.users - Array of assigned users affected by the cancellation
 */
const useCancellationDetails = ({contract, selectedSeats}) => {
  const {productsChange} = useProductsChangeContext();

  return useMemo(() => {
    const cancelFeeAmt = getETFAmount(productsChange)?.totalWithTax;

    // Refund amounts are returned as negative values
    let refundAmount = getRefundAmount(productsChange)?.totalWithTax;
    refundAmount = refundAmount && Math.abs(refundAmount);

    const terminationDate = getTerminationDate(productsChange);
    const daysCount =
      typeof terminationDate === 'undefined'
        ? undefined
        : getDaysLeft(new Date(terminationDate), {fractionalDays: 'round'});

    const currency = getCurrency(productsChange);

    const originalInvoiceAmount = getExistingBilling(productsChange)?.totalWithoutTax;

    const reducedInvoiceAmount = getNextBilling(productsChange)?.totalWithoutTax;

    const isFullCancelFlow = isFullCancel(productsChange);

    const licenseCount = getAllCancellingLicensesCount(productsChange);

    const taxTerm = getTaxTerm(contract?.getOwnerCountryCode());

    const recurrenceTerm = contract?.getBillingFrequency();

    const nextBillingDate = contract?.getNextBillingDate();

    let users;
    if (selectedSeats) {
      users = getDisplayNamesFromSelectedSeats(selectedSeats);
    }

    return {
      cancelFeeAmt,
      currency,
      daysCount,
      isFullCancelFlow,
      licenseCount,
      nextBillingDate,
      originalInvoiceAmount,
      recurrenceTerm,
      reducedInvoiceAmount,
      refundAmount,
      taxTerm,
      terminationDate,
      users,
    };
  }, [contract, productsChange, selectedSeats]);
};

export default useCancellationDetails;
