import {jilUsers} from '@admin-tribe/binky';

import jilJobs from 'common/api/jil/jilJobs';
import {CSV_MAX_ATTEMPTS_ERROR} from 'common/entities/user-operations/UserOperationsConstants';
import {JOB_STATUS} from 'common/services/job/JobConstants';
import rootStore from 'core/RootStore';

const CHECK_TIMEOUT = 60 * 1000; // 60s is the API timeout
const POLL_INTERVAL = 3000; // ms
const MAX_ATTEMPTS = CHECK_TIMEOUT / POLL_INTERVAL;

/**
 * Kicks off async anvil job and then polls for result
 * @returns {Promise} if CSV is ready within a minute, returns call to JIL to fetch results
 */
const exportAsyncCSV = async ({maxTries = MAX_ATTEMPTS, pollingInterval = POLL_INTERVAL} = {}) => {
  const orgId = rootStore.organizationStore.activeOrgId;

  // start async anvil job for exporting CSV
  const exportResponse = await jilUsers.exportUsersAsync({orgId});

  const jobId = exportResponse.data?.id;

  // if job completed within 1 minute, then fetch result immediately
  await pollExportReady({jobId, maxTries, orgId, pollingInterval});

  return jilJobs.getJobResults({
    acceptHeader: 'text/csv+organization-job-result',
    jobId,
    orgId,
  });
};

/* Internal methods */

/**
 * Polls the Job Status until COMPLETED or until timeout
 * @param {options} polling options
 * @param {options.pollingInterval} time duration between calls
 * @param {options.maxTries} number of times to poll
 * @returns {Promise} - that will resolve if the poller has completed the ticks, or reject if any error
 */
async function pollExportReady({jobId, orgId, pollingInterval, maxTries}) {
  let jobResponse, status;
  let tries = 0;

  // eslint-disable-next-line no-constant-condition -- Will break out of loop when exceeds maxTries or job is still in progress
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop -- Wait for status of job to come back before re-checking if status changed
      jobResponse = await jilJobs.getJob({jobId, orgId});
    } catch {
      // continue
    }
    status = jobResponse?.data?.status;
    tries += 1;

    if (status === JOB_STATUS.COMPLETED) {
      return Promise.resolve();
    }
    if (tries >= maxTries) {
      return Promise.reject(new Error(CSV_MAX_ATTEMPTS_ERROR));
    }
    if (!status || status === JOB_STATUS.QUEUED || status === JOB_STATUS.PROCESSING) {
      // eslint-disable-next-line no-await-in-loop -- pause for pollingInterval before retrying status check
      await new Promise((resolve) => {
        setTimeout(resolve, pollingInterval);
      });
    } else {
      return Promise.reject();
    }
  }
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {exportAsyncCSV};
