import qs from 'qs';
import {useCallback, useEffect, useState} from 'react';
import {useLocation, useRouteLoaderData} from 'react-router-dom';

/**
 * Hook to open the Add Products modal. Use with `populateAddProductsModalDataLoader` to
 * populate the data required for this hook to open the modal.
 * @param {string} routeId
 * @returns {{closeModal: function, isModalOpen: boolean, props: {}}}
 */
const useAddProductsModalFromDeeplink = (routeId) => {
  // This component can be used in Angular (when user hits /quick-assign-products), so we need to be
  // able to get data from within an Angular context as well as React.

  let data;
  // This does not need to get set when within Angular since it will not be used.
  let location = {};

  try {
    /* eslint-disable react-hooks/rules-of-hooks -- Needed because this can be used in Angular, temporary */
    data = useRouteLoaderData(routeId);
    location = useLocation();
    /* eslint-enable react-hooks/rules-of-hooks -- Temporary until Angular Overview code is fully ported, ONESIE-32429 */
  } catch (error) {
    // catch quick assign error on Overview page
    if (!error.message.includes('useRouteLoaderData must be used within a data router')) {
      throw error;
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [props, setProps] = useState({});

  /** If the user navigates to /add-products, open the Add Products modal */
  useEffect(() => {
    if (data?.openAddProductsModalFromDeeplink) {
      setIsModalOpen(true);
      const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});

      setProps({
        items:
          queryParams.items?.map((item) => ({
            offerId: item.id,
            quantity: Number.parseInt(item.q || 1, 10),
          })) ?? [],
        step: queryParams.step,
        tracking: queryParams.tracking,
        variation: queryParams.var,
        workflow: queryParams.wf,
      });
    }
  }, [data, location.search]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return {closeModal, isModalOpen, props};
};

export default useAddProductsModalFromDeeplink;
