import {CONTRACT_STATUS, Locale, configStore, log} from '@admin-tribe/binky';
import {OverlayWait} from '@admin-tribe/binky-ui';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MessageType} from '@pandora/react-app-context-provider';
import {SystemMessage} from '@pandora/react-commerce-core-app-context-provider';
import {ModalContainer, ModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';

import rootStore from 'core/RootStore';

import styles from './SelfCancelModalWrapper.pcss';

const SELF_CANCEL_MINI_APP_NAME = 'ManagePlan';
const SELF_CANCEL_MINI_APP_URL_ENV_PARAM = 'services.selfCancelMiniApp';

/**
 * If the client passes the contractId, use that.
 * If not, find the contractId for the org if it has only one contract and that too is active.
 *
 * @param {String} contractId - Deeplink with contract id
 *
 * @returns contractId if found otherwise undefined
 */
const getActiveContractIdForMiniApp = (contractId) => {
  if (contractId) {
    return contractId;
  }

  const {contractList} = rootStore.organizationStore;

  if (contractList.items.length === 1 && contractList.items[0].status === CONTRACT_STATUS.ACTIVE) {
    return contractList.items[0].id;
  }

  return undefined;
};

/**
 * Setup Self Cancel Mini App URL
 *
 * @param {String} contractId
 * @param {String} productId
 *
 * @returns Self Cancel Mini App URL
 */
const setUpMiniAppUrl = ({contractId, productId}) => {
  const {organizationStore} = rootStore;

  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = configStore.get(SELF_CANCEL_MINI_APP_URL_ENV_PARAM);
  const orgId = organizationStore.activeOrgId;

  // Basic parameters
  const params = {
    contractId: getActiveContractIdForMiniApp(contractId),
    ctx: 'if',
    // fullCancellation: true,
    intent: 'cancel',
    lang: localeLanguage,
    orgId,
    productId,
    surface: 'ADMIN_CONSOLE',
  };

  return `${url}?${qs.stringify(params, {skipNulls: true})}`;
};

/**
 * @description Hook to manage the state of Self Cancel modal
 */
const useSelfCancelModalContext = ({onClose}) => {
  // Identify mini app is ready
  const [appReady, setAppReady] = useState(false);

  // Show or close the modal dialog based on status of self cancel workflow
  const [showSelfCancelModal, setShowSelfCancelModal] = useState(true);

  // Message handler to process just the System events from Self Cancel Mini App
  const systemMessageHandler = useCallback(
    ({subType}) => {
      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case SystemMessage.APP_LOADED:
          setAppReady(true);
          break;

        case SystemMessage.CLOSE:
          setShowSelfCancelModal(false);
          onClose?.();
          break;
      }
    },
    [onClose]
  );

  return {
    appReady,
    showSelfCancelModal,
    systemMessageHandler,
  };
};

/**
 * @description Hook to handle the messages from Self Cancel mini app
 */
const useSelfCancelMessageHandler = ({systemMessageHandler}) => {
  // Message handler to process the events from Self Cancel Mini App
  const messageHandler = useCallback(
    ({app, type, subType, data}) => {
      // Ignore the message if it is not from Self Cancel Mini App
      if (app !== SELF_CANCEL_MINI_APP_NAME) return;

      log.info('Message from Self Cancel Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      } else if (type === MessageType.OPEN_URL) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
        window.open(data.externalUrl, data.target);
      }
    },
    [systemMessageHandler]
  );

  // Consume message from Self Cancel Mini App iframe
  useIframeMessage(messageHandler);
};

/**
 * @description Wrapper component for Self Cancel mini app
 */
const SelfCancelModalWrapper = ({contractId, onClose, productId}) => {
  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl({contractId, productId}),
    [contractId, productId]
  );

  const {appReady, showSelfCancelModal, systemMessageHandler} = useSelfCancelModalContext({
    onClose,
  });
  useSelfCancelMessageHandler({systemMessageHandler});

  if (!showSelfCancelModal) return null;

  return (
    <ModalContainer>
      <div className={styles['self-cancel-dialog']}>
        <ModalDialog
          dialogStyle={{
            width: '1200px',
          }}
        >
          <div styleName="dialog-content">
            <OverlayWait isLoading={!appReady} size="L">
              <iframe src={miniAppUrl} styleName="self-cancel-iframe" title="Self Cancel" />
            </OverlayWait>
          </div>
        </ModalDialog>
      </div>
    </ModalContainer>
  );
};

SelfCancelModalWrapper.propTypes = {
  /**
   * Optional - Id which determines the active contract in self cancel mini app
   */
  contractId: PropTypes.string,
  /**
   * Optional - Handler that is called when the self-cancel modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Id which determines the product to cancel in self cancel mini app
   */
  productId: PropTypes.string.isRequired,
};

export default SelfCancelModalWrapper;
