import {dispatchUiEventAnalytics, eventBus, feature, navBus} from '@admin-tribe/binky';
import {DATE_FORMATS, GoUrl} from '@admin-tribe/binky-ui';
import {Button, Flex, Link, Text, View} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import {observer} from 'mobx-react-lite';
import qs from 'qs';
import React from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import AssignOrganizationType from 'common/components/migrate-device-license/AssignOrgType';
import rootStore from 'core/RootStore';
import {ACCOUNT_STATE_PARAM} from 'core/account/account-meta/AccountMetaConstants';
import {ACCOUNT_PAGE_LINK} from 'core/contract/auto-renewal/AutoRenewalConstants';
import {BANNER_ORGANIZATION_TYPE_MODAL_ID} from 'core/organizations/organization-settings/organizationSettingsConstants';
import {
  findReactRouteByState,
  isReactRouteByState,
} from 'core/router/route-migration/routeMigrationUtils';
import StartChatButton from 'features/support/components/start-chat-button/StartChatButton';

import {GLOBAL_BANNER_EVENT, GLOBAL_BANNER_TYPES} from './GlobalBannerConstants';

/**
 * GlobalBannerMessageAndAction - Wrapper for global banner message and the
 * associated action that can be taken if dictated by the properties of the
 * constructed banner payload.
 */
// eslint-disable-next-line complexity -- Complex component
const GlobalBannerMessageAndAction = observer(() => {
  const intl = useIntl();
  const globalBannerStore = rootStore.organizationStore.globalBannerStore;
  const currentBanner = globalBannerStore.currentBanner;

  const formattedMessageDates = currentBanner.messageDates
    ? getFormattedDateOrTimeValues('messageDates', DATE_FORMATS.default)
    : {};
  const formattedMessageNumbers = currentBanner.messageNumbers
    ? getFormattedNumberValues('messageNumbers')
    : {};
  const formattedMessageTimes = currentBanner.messageTimes
    ? getFormattedDateOrTimeValues('messageTimes', DATE_FORMATS.timeOnly)
    : {};
  const translationRequiredMessageArgs = currentBanner.translationRequiredMessageArgs
    ? getTranslatedMessageArgs()
    : {};

  function getFormattedDateOrTimeValues(bannerKey, dateFormat) {
    const returnVal = {};
    Object.keys(currentBanner[bannerKey]).forEach((key) => {
      returnVal[key] = intl.formatDate(currentBanner[bannerKey][key], dateFormat);
    });
    return returnVal;
  }

  function getFormattedNumberValues(bannerKey) {
    const returnVal = {};
    Object.keys(currentBanner[bannerKey]).forEach((key) => {
      returnVal[key] = intl.formatNumber(currentBanner[bannerKey][key]);
    });
    return returnVal;
  }

  function getOpenModalButton(modalId) {
    switch (modalId) {
      case BANNER_ORGANIZATION_TYPE_MODAL_ID:
        return <AssignOrganizationType />;
      default:
        return (
          <Button
            onPress={() => {
              eventBus.emit(GLOBAL_BANNER_EVENT.MODAL.OPEN, {
                modalId: currentBanner.modalId,
              });
            }}
            staticColor="white"
            variant="primary"
          >
            {intl.formatMessage({id: currentBanner.buttonText})}
          </Button>
        );
    }
  }

  /**
   * Function to process and redirect to linkUiSref based on the current page
   * whether it is React or Angular.
   * navBus.go() does not work while moving from React to React route.
   * This function uses navBus.navigate() in that case.
   *
   * This function can be removed once Angular routing is gone: https://jira.corp.adobe.com/browse/ONESIE-32429
   */
  function goToSrefLink(linkUiSref, linkUiSrefArgs = {}) {
    if (isReactRouteByState(linkUiSref)) {
      const reactRoute = findReactRouteByState(linkUiSref);
      if (
        linkUiSref === 'account.account' &&
        linkUiSrefArgs?.context === ACCOUNT_PAGE_LINK.OPT_IN_SELF_SERVICE
      ) {
        dispatchUiEventAnalytics({
          eventName: 'adminConsole:optOutRenGlobalBannerDisplay',
          interaction: {
            impression:
              'optOutRenGlobalBanner:AccountPage|adminConsole|renewal,optOutRenGlobalBanner:LearnMore|adminConsole|renewal',
          },
        });
      }

      if (linkUiSrefArgs?.action === ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT) {
        // Instead of invoking the Angular payment-info-editor.service.js, we will invoke REBA on the Account page
        const pathname = generatePath(reactRoute?.url, {
          ...linkUiSrefArgs,
          orgId: rootStore.organizationStore.activeOrgId,
        });
        const search = `?${qs.stringify({action: linkUiSrefArgs.action})}`;
        const url = feature.isEnabled('use_routing_with_query_params')
          ? {pathname, search}
          : `${pathname}${search}`;

        navBus.navigate(url);
      } else {
        navBus.navigate(
          generatePath(reactRoute?.url, {
            ...linkUiSrefArgs,
            orgId: rootStore.organizationStore.activeOrgId,
          })
        );
      }
    } else {
      navBus.go(linkUiSref, linkUiSrefArgs);
    }
  }

  function goToHrefLink(messageArgs) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- dispatch analytics only for selected context
    // istanbul ignore else
    if (messageArgs?.linkUiHrefArgs?.context === ACCOUNT_PAGE_LINK.CONTACT_SUPPORT) {
      dispatchUiEventAnalytics({
        eventName: 'adminConsole:optOutRenGlobalBanner:customerSupport:click',
        interaction: {
          click: 'optOutRenGlobalBanner:customerSupport|adminConsole|renewal',
        },
      });
    }
  }

  function getTranslatedMessageArgs() {
    const returnVal = {};
    Object.keys(currentBanner.translationRequiredMessageArgs).forEach((key) => {
      returnVal[key] = currentBanner.translationRequiredMessageArgs[key](intl);
    });
    return returnVal;
  }

  const generateMessageArgs = () => ({
    ...currentBanner.messageArgs,
    ...translationRequiredMessageArgs,
    ...formattedMessageDates,
    ...formattedMessageNumbers,
    ...formattedMessageTimes,
    // eslint-disable-next-line react/forbid-elements -- Needed to bold banner content
    b: (chunks) => <b>{chunks}</b>,
    goUrl: (chunks) => (
      <GoUrl
        data-testid="inline-go-url"
        name={currentBanner.messageArgs?.goUrlName}
        variant="overBackground"
      >
        {chunks}
      </GoUrl>
    ),
    hrefLink: (chunks) => (
      <Link
        onPress={() => {
          goToHrefLink(currentBanner.messageArgs);
        }}
        variant="overBackground"
      >
        <a href={currentBanner.messageArgs?.linkHref} rel="noreferrer">
          {chunks}
        </a>
      </Link>
    ),
    srefLink: (chunks) => (
      <Link
        data-testid="inline-message-sref-link"
        onPress={() => {
          goToSrefLink(
            currentBanner.messageArgs?.linkUiSref,
            currentBanner.messageArgs?.linkUiSrefArgs
          );
        }}
        variant="overBackground"
      >
        <span>{chunks}</span>
      </Link>
    ),
  });

  const marginStart =
    currentBanner.type === GLOBAL_BANNER_TYPES.INFO ? {marginStart: 'size-150'} : {};
  const marginTop =
    currentBanner.type === GLOBAL_BANNER_TYPES.INFO &&
    currentBanner.modalId === undefined &&
    currentBanner.chatAppId === undefined
      ? {}
      : {marginTop: 'size-65'};

  const bannerMessage = intl.formatMessage({id: currentBanner.message}, generateMessageArgs());

  return (
    <Flex {...marginStart} flexGrow={1} justifyContent="end" wrap>
      <View {...marginTop} flexGrow={1}>
        <Text data-testid="message" UNSAFE_style={{color: 'white'}}>
          {bannerMessage}
        </Text>
      </View>
      <View {...marginTop} marginStart="size-100">
        {currentBanner.linkName && (
          <GoUrl data-testid="go-url" name={currentBanner.linkName} variant="overBackground">
            {intl.formatMessage({id: currentBanner.linkText})}
          </GoUrl>
        )}
        {currentBanner.linkUiSref && (
          <Link
            data-testid="link-ui-sref"
            onPress={() => {
              goToSrefLink(currentBanner.linkUiSref, currentBanner.linkUiSrefArgs);
            }}
            variant="overBackground"
          >
            {intl.formatMessage({id: currentBanner.linkText})}
          </Link>
        )}
        {currentBanner.linkHref && (
          <Link data-testid="link-href" flexGrow={1} variant="overBackground">
            <a href={currentBanner.linkHref} rel="noreferrer" target="_blank">
              <Flex flexGrow={1} gap="size-150">
                <Text flexGrow={1}>{intl.formatMessage({id: currentBanner.linkText})}</Text>
                <LinkOutIcon
                  aria-label={intl.formatMessage({id: 'globalBanner.action.link.opensWindow'})}
                  flexShrink={0}
                  size="S"
                />
              </Flex>
            </a>
          </Link>
        )}
        {currentBanner.linkText && currentBanner.modalId && (
          <Link
            onPress={() => {
              eventBus.emit(GLOBAL_BANNER_EVENT.MODAL.OPEN, {
                modalId: currentBanner.modalId,
              });
            }}
            variant="overBackground"
          >
            {intl.formatMessage({id: currentBanner.linkText})}
          </Link>
        )}
        {currentBanner.linkText && currentBanner.linkOnClick && (
          <Link
            data-testid="link-with-onclick"
            onPress={() => {
              currentBanner.linkOnClick();
            }}
            variant="overBackground"
          >
            {intl.formatMessage({id: currentBanner.linkText})}
          </Link>
        )}
      </View>
      {/* Moving the buttons outside, because they don't need the added top margin */}
      {currentBanner.buttonText && currentBanner.modalId && (
        <View data-testid="banner-button" marginLeft="size-100">
          {getOpenModalButton(currentBanner.modalId)}
        </View>
      )}
      {currentBanner.chatAppId && (
        <View marginLeft="size-100">
          <StartChatButton
            appId={currentBanner.chatAppId}
            onStartChatButtonPress={currentBanner.handleChatOnClick}
            variant="overBackground"
          />
        </View>
      )}
    </Flex>
  );
});

export default GlobalBannerMessageAndAction;
