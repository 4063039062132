import {DirectoryUser} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import DirectoryUserDetailsSection from 'features/users/components/directory-user-details-section/DirectoryUserDetailsSection';
import DirectoryUserMembershipSection from 'features/users/components/directory-user-membership-section/DirectoryUserMembershipSection';

/**
 * The Directory User details drawer content
 */
const DirectoryUserDetailsDrawerContent = ({directoryId, member}) => (
  <>
    <DirectoryUserDetailsSection member={member} />
    <DirectoryUserMembershipSection directoryId={directoryId} memberId={member.id} />
  </>
);

DirectoryUserDetailsDrawerContent.propTypes = {
  /**
   * The id of the directory.
   */
  directoryId: PropTypes.string.isRequired,
  /**
   * The directory user.
   */
  member: PropTypes.instanceOf(DirectoryUser).isRequired,
};

export default DirectoryUserDetailsDrawerContent;
