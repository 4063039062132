(function () {
  /**
   * @ngdoc module
   * @name app.widgets.asset-sharing
   * @description defines widgets for modifying sharing options/domain sharing whitelist.
   */
  angular.module('app.widgets.asset-sharing', [
    'app.core.asset-sharing',
    'app.widgets.card-button-list',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.shell.panels.modal',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.confirmation-modal',
    'binky.widgets.common.indeterminate',
    'binky.widgets.common.pagination-control-bar',
  ]);
})();
