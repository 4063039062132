import {OverlayWait, Subpage} from '@admin-tribe/binky-ui';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableSection,
  onTokenPaginatedTableSectionChange,
} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';

import useTableSectionPageCounter from 'common/hooks/useTableSectionPageCounter';

import {useAppIntegrationTableContext} from '../../hooks/AppIntegrationTableContext';

import AppIntegrationsTable from './AppIntegrationsTable';
import {COLUMN_KEY, DEFAULT_COLUMNS, TABLE_MODE} from './appIntegrationTableConstants';

const AppIntegrationsTableSection = ({
  children,
  hasPolicies,
  hasAcceptedUsers,
  onTableSelection,
  selectionMode,
  ...tableProps
}) => {
  const {appIntegrationList, error, isLoading, refreshList} = useAppIntegrationTableContext();

  const {currentPage, totalPages, onTableSectionChangePageReducer} = useTableSectionPageCounter({
    listState: appIntegrationList?.state,
  });

  const onTableSectionChange = async ({action, payload}) => {
    // Update appIntegrationList.state
    onTokenPaginatedTableSectionChange({
      action,
      payload,
      tokenPaginatedDataUtils: appIntegrationList?.state,
    });

    // Update page states
    onTableSectionChangePageReducer({action});

    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        await refreshList();
        break;
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE: {
        // Only one app can be selected
        const selectedAppUuid = payload[0];
        const selectedClientId = appIntegrationList.items.find(
          (integration) => integration.uuid === selectedAppUuid
        )?.clientIds[0];
        onTableSelection?.(selectedClientId);
        break;
      }
      default:
      // do nothing
    }
  };

  const mode = useMemo(() => {
    if (hasPolicies) {
      return TABLE_MODE.HAS_POLICIES;
    }
    if (hasAcceptedUsers) {
      return TABLE_MODE.HAS_ACCEPTED_USERS;
    }
    return TABLE_MODE.DEFAULT;
  }, [hasAcceptedUsers, hasPolicies]);

  const columns = useMemo(() => {
    if (hasPolicies) {
      return [
        COLUMN_KEY.NAME,
        COLUMN_KEY.ACTIONS,
        COLUMN_KEY.PUBLISHER,
        COLUMN_KEY.PLATFORM,
        COLUMN_KEY.STATUS,
      ];
    }
    if (hasAcceptedUsers) {
      return [COLUMN_KEY.NAME, COLUMN_KEY.ACTIONS, COLUMN_KEY.PUBLISHER, COLUMN_KEY.PLATFORM];
    }
    return DEFAULT_COLUMNS;
  }, [hasAcceptedUsers, hasPolicies]);

  const getItemKey = useCallback((item) => item.uuid, []);

  const isInitialLoadDone = appIntegrationList !== undefined;

  return (
    <Subpage isBumpered={!!error} isLoading={isLoading && !isInitialLoadDone}>
      <OverlayWait isLoading={isLoading} showContent>
        <TableSection
          getItemKey={getItemKey}
          isDisabled={isLoading}
          items={appIntegrationList?.items || []}
          onTableSectionChange={onTableSectionChange}
          pageNumber={currentPage}
          selectionMode={selectionMode}
          totalPages={totalPages}
        >
          {children}
          <AppIntegrationsTable columns={columns} mode={mode} {...tableProps} />
        </TableSection>
      </OverlayWait>
    </Subpage>
  );
};

AppIntegrationsTableSection.propTypes = {
  /** The children above the table. Intended to pass in the TableActions */
  children: PropTypes.node,
  /** True if Table is under the /user-accepted route */
  hasAcceptedUsers: PropTypes.bool,
  /** True if Table is under the /policies route */
  hasPolicies: PropTypes.bool,
  /** Callback for when an app integration selected. Passes the client id. */
  onTableSelection: PropTypes.func,
  /** The SELECTION_MODE of the Table */
  selectionMode: PropTypes.oneOf([SELECTION_MODE.NONE, SELECTION_MODE.SINGLE]),
};

AppIntegrationsTableSection.displayName = 'AppIntegrationsTableSection';

export default AppIntegrationsTableSection;
