(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.main.global-modals-manager:QuickAssignGlobalModal
   */
  angular
    .module('app.main.global-modals-manager')
    .factory('quickAssignGlobalModal', QuickAssignGlobalModal);

  /* @ngInject */
  function QuickAssignGlobalModal($rootScope, GLOBAL_MODALS) {
    const service = {
      open,
    };

    return service;

    //////////////

    /**
     * Initialize and open Quick Assign Modal
     */
    function open() {
      const componentBindings = {
        modalId: 'quick-assign-modal2',
      };

      $rootScope.$broadcast(GLOBAL_MODALS.OPEN, {
        componentBindings,
        componentName: 'appQuickAssignModal2',
      });
    }
  }
})();
