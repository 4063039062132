import {Divider, Grid, Text, View} from '@adobe/react-spectrum';
import {GoUrl, GoUrlContentModel} from '@pandora/react-go-url';
import {ModalContent, ModalHeading} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {REMOVE_USER_STORAGE_OPTIONS} from 'common/services/user-folder/userFolderConstants';

import RemoveUsersSummarySection from '../components/remove-users-summary-section/RemoveUsersSummarySection';
import StorageTransferSelection from '../components/storage-transfer-selection/StorageTransferSelection';
import {useDeleteUsers} from '../hooks/DeleteUsersModalContext';

const StorageSelectionStep = ({
  defaultUser,
  onSelectionChange,
  onTransferStorageUser,
  selectedOption,
}) => {
  const intl = useIntl();
  const {allUsers} = useDeleteUsers();

  const content = GoUrlContentModel.createEntry({
    linkOutAriaLabel: intl.formatMessage({id: 'common.removeUsersWithStorageModal.body.goUrl'}),
  });

  return (
    <>
      <ModalHeading>
        {intl.formatMessage(
          {id: 'common.removeUsersWithStorageModal.steps.storageSelection.heading'},
          {
            count: allUsers.length,
          }
        )}
      </ModalHeading>
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <ModalContent>
        <Grid areas={['selection summary']} columns={['2fr', 'size-3600']} gap="size-300">
          <View>
            <Text>
              {intl.formatMessage(
                {id: 'common.removeUsersWithStorageModal.body'},
                {
                  goUrl: (linkString) => (
                    <GoUrl content={content} name="aac_asset_reclamation">
                      {linkString}
                    </GoUrl>
                  ),
                }
              )}
            </Text>
            <StorageTransferSelection
              defaultUser={defaultUser}
              onSelectionChange={onSelectionChange}
              onTransferStorageUser={onTransferStorageUser}
              selectedOption={selectedOption}
            />
          </View>
          <RemoveUsersSummarySection />
        </Grid>
      </ModalContent>
    </>
  );
};

StorageSelectionStep.propTypes = {
  /**
   * Used to pre-populate the user selection field and set the selected user. This is useful for ensuring that transitioning through steps.
   * maintain the correct information.
   */
  defaultUser: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
  /**
   * Callback that passes the selected radio option
   */
  onSelectionChange: PropTypes.func.isRequired,
  /**
   * Callback that passes in either the selected user object or an empty object that signifies an invalid selection
   */
  onTransferStorageUser: PropTypes.func,
  /**
   * The selected action of what to do with the storage of the user to be deleted
   */
  selectedOption: PropTypes.oneOf(Object.values(REMOVE_USER_STORAGE_OPTIONS)).isRequired,
};

export default StorageSelectionStep;
