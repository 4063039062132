(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  // eslint-disable-next-line angular/provider-name
  angular
    .module('app.core.api.content-platform')
    .provider('contentPlatformVa6c2Direct', contentPlatformProvider);

  /* @ngInject */
  function contentPlatformProvider() {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(contentPlatformServiceConfig) {
      url = contentPlatformServiceConfig ? contentPlatformServiceConfig.url : '';
    }

    ////////////

    /* @ngInject */
    function $get($resource, apiUtils, authentication) {
      const service = {
        contentLog: getContentLog(),
      };

      return service;

      ////////////

      function getContentLog() {
        return $resource(
          `${url}/:path`,
          {
            path: '@path',
          },
          {
            download: {
              headers: {
                'org-id': apiUtils.getActiveOrg,
                'x-user-token': authentication.getAccessToken(),
              },
              method: 'GET',
              transformResponse: apiUtils.csvBlobTransformer,
            },
          }
        );
      }
    }
  }
})();
