(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.common.drag-and-drop-file.component:appDragAndDropFile
   * @description Component that encompasses a drag and drop file input/select
   *   view. This component supports a variety of display options, to support
   *   greater reuse. However, it is important that use includes passing along
   *   a reference Array for files to be stored in and used.
   * @param {Boolean} [displayLabel] - true to display default component title/label,
   *   else false if supplying own label for component (defaults to false)
   * @param {Array} files - empty Array to use as shared storage between this
   *   component and any using controller code
   * @param {String} [helpTooltipMsgKey] - key of String to display in tooltip
   *   of default title/label. Message should include any file size/type restrictions
   *   (defaults to no key, so if displayLabel is true, then you MUST include a
   *   value here)
   * @param {Number} [maxNumberFiles] - maximum number of files user can select
   *   via widget (default is no limit enforced by the client, so any system file
   *   input limits)
   * @param {Number} [maxSize] - max file size in bytes (default is no maximum
   *   file size enfoced by the client, so any system file size limits)
   * @param {String[]} [restrictedTypes] - list of file extensions/types not permitted
   *   (do not include preceding dot, just the extension; e.g. - 'exe'; defaults
   *   to no file type restrictions or any system file input restrictions)
   * @param {String} [restrictedTypesAttachErrorMsgKey] - key of String to display
   *   when file attachment fails due to the file type being restricted, must support
   *   passing the `fileName` placeholder to include name of file unable to add
   *   (defaults to no message, so if restrictedTypes are present, this must have
   *   a value assigned, or user experience will be impacted)
   */
  angular.module('app.widgets.common.drag-and-drop-file').component('appDragAndDropFile', {
    bindings: {
      displayLabel: '<?',
      files: '<',
      helpTooltipMsgKey: '<?',
      hideOnMaxFilesReached: '<?',
      maxNumberFiles: '<?',
      maxSize: '<?',
      restrictedTypes: '<?',
      restrictedTypesAttachErrorMsgKey: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/drag-and-drop-file/drag-and-drop-file.component.html',
  });

  function controller(_, $element, $scope, $translate, FileSize, toastManager, translationUtils) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      isMaxNumberOfFilesReached,
      onBlur,
      onFocus,
      removeFile,
    });

    /**
     * @description Method to deconstruct drag and drop component. Removes event
     *   listeners used to provide drag and drop/selection logic.
     */
    function $onDestroy() {
      // remove the event listeners registered on open
      vm.fileInputElement.off();
      vm.formElement.off();
    }

    /**
     * @description Method to initialize drag and drop component. Set a unique
     *   ID to allow for multiple instances in amalgamated views, constructs
     *   help tooltip messaging for default label/title, and registers listeners
     *   to provide for drag and drop/selection logic.
     */
    function $onInit() {
      vm.id = _.uniqueId('drag-and-drop-file-');
      if (vm.helpTooltipMsgKey) {
        vm.helpTooltipString = translationUtils.sanitizeSafeInstant(vm.helpTooltipMsgKey);
      }

      // register listeners for drag/drop events
      registerDragDropEventListeners();

      // register listeners for file selections
      registerFileSelectionListeners();
    }

    /**
     * @description Method to determine if the maximum number of selectable files
     *   has been reached. If no maximum is set or the number selected is less
     *   than the value bound to maxNumberFiles, then this method returns false.
     * @return {Boolean} true if user selected the maximum number set, false if
     *   no maximum set or user selected less than the maximum number
     */
    function isMaxNumberOfFilesReached() {
      return _.isInteger(vm.maxNumberFiles) && vm.files.length >= vm.maxNumberFiles;
    }

    /**
     * @description Method to provide focus ring behavior to the 'select' span/text
     *   in the drag and drop field. When keyboard navigation exits the input
     *   element, this method unsets the 'focused' class on nested span, allowing
     *   the focus ring to disappear.
     */
    function onBlur() {
      vm.focused = false;
    }

    /**
     * @description Method to provide focus ring behavior to the 'select' span/text
     *   in the drag and drop field. When keyboard navigation enters the input
     *   element, this method sets the 'focused' class on nested span, allowing
     *   the focus ring to appear.
     */
    function onFocus() {
      vm.focused = true;
    }

    /**
     * @description Method to allow users to deselect files that have been
     *   selected from either drag and drop or file input selection.
     * @param {File} file - file reference that should be removed from list of
     *   selected files
     */
    function removeFile(file) {
      _.pull(vm.files, file);
    }

    ////////

    /**
     * @description Method to handle the filelist gathered from either a drag
     *   and drop event, or manual selection of files from the file input system
     *   dialog. This method will not allow for duplicate files to be selected,
     *   enforces any max limit that has been set for selectable files, and
     *   further enforces any files size or type restrictions set.
     * @param {File[]} fileList - list of file references that should be added to
     *   list of selected files
     */
    function addFiles(fileList) {
      // attempt to add all requested files
      _.forEach(fileList, (file) => {
        if (!vm.isMaxNumberOfFilesReached()) {
          if (hasFileTypeError(file)) {
            // don't add if restricted file type
            toastManager.showErrorToast(getRestrictedFileTypeError(file));
          } else if (hasFileSizeError(file)) {
            // don't add if file size too large
            toastManager.showErrorToast(getMaxFileSizeError(file));
          } else {
            // don't add if already added
            const fileNotAlreadyExist = !_.find(vm.files, {
              lastModified: file.lastModified,
              name: file.name,
            });

            if (fileNotAlreadyExist) {
              vm.files.push(file);
            }
          }
        }
      });

      /**
       * @description Method to determine if the given file is larger than any
       *   file size limit set for this component.
       * @param {File} file - file references that should be checked for size
       * @return {Boolean} true if file size exceeds limit, else false
       */
      function hasFileSizeError(file) {
        if (_.isNil(vm.maxSize)) {
          return false;
        }
        return file.size > vm.maxSize;
      }

      /**
       * @description Method to determine if the given file is of a type that has
       *   been restricted for this component.
       * @param {File} file - file references that should be checked for type
       * @return {Boolean} true if file type is restricted, else false
       */
      function hasFileTypeError(file) {
        if (_.isNil(vm.restrictedTypes)) {
          return false;
        }
        return _.some(
          vm.restrictedTypes,
          (restrictedType) =>
            _.toLower(restrictedType) === _.last(_(file.name).toLower().split('.'))
        );
      }
    }

    /**
     * @description Method to construct/return a translation when a user attempts
     *   to select a file that is too large.
     * @param {File} file - reference to file that is too large
     * @return {String} translated error message, indicated file name that was
     *   attempted and a brief description of why it was not selected
     */
    function getMaxFileSizeError(file) {
      return $translate.instant('widgets.dragAndDropFile.maxSizeError', {
        fileName: file.name,
        maxSizeString: FileSize.bytesToString(vm.maxSize, 0),
      });
    }

    /**
     * @description Method to construct/return a translation when a user attempts
     *   to select a restricted file type.
     * @param {File} file - reference to file that has restricted type/extension
     * @return {String} translated error message, indicated file name that was
     *   attempted and a brief description of why it was not selected
     */
    function getRestrictedFileTypeError(file) {
      return translationUtils.sanitizeSafeInstant(vm.restrictedTypesAttachErrorMsgKey, {
        fileName: file.name,
      });
    }

    /**
     * @description Method to register listeners that appropriately detect and
     *   handle any drag and drop file selection events.
     */
    function registerDragDropEventListeners() {
      vm.formElement = $element.find('span');
      vm.formElement
        .on('drag dragstart dragend dragover dragenter dragleave drop', (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
        })
        .on('dragover dragenter', () => {
          vm.formElement.addClass('drag-over');
          $scope.$apply();
        })
        .on('dragleave dragend drop', () => {
          vm.formElement.removeClass('drag-over');
          $scope.$apply();
        })
        .on('drop', (evt) => {
          addFiles(evt.dataTransfer.files);
          $scope.$apply();
        });
    }

    /**
     * @description Method to register listeners that appropriately detect and
     *   handle any file input selection events.
     */
    function registerFileSelectionListeners() {
      vm.fileInputElement = $element.find('input');
      // See https://github.com/angular/angular.js/issues/1375 for the long
      // discussion about why 1.5 doesn't support change for file inputs in Angular
      vm.fileInputElement.on('change', (evt) => {
        addFiles(evt.target.files);
        // we reset the file input to ensure nothing is left behind after setting
        evt.target.value = null;
        $scope.$apply();
      });
    }
  }
})();
