import DirectoryEntity from 'common/entities/DirectoryEntity';

class DirectoryListModel {
  static fromObject(data) {
    return new DirectoryListModel(data);
  }

  constructor(directoryListData) {
    if (directoryListData) {
      this.items = directoryListData.data.map((directory) => new DirectoryEntity(directory));

      this.numberOfDirectories = directoryListData.totalCount;
      this.totalPages = Math.ceil(this.numberOfDirectories / directoryListData.pageSize);
    } else {
      // for the initial state directoryListData is null
      this.items = [];
      this.numberOfDirectories = 0;
      this.totalPages = 1;
    }
  }
}

export default DirectoryListModel;
