import {PAGE_SIZE_OPTIONS} from '@pandora/react-pagination';
import {SORT_ORDER} from '@pandora/react-table-section';

const DEFAULT_TABLE_OPTIONS = {
  page: 1,
  pageSize: PAGE_SIZE_OPTIONS.DEFAULT[1].id,
  searchQuery: '',
  sortOrder: SORT_ORDER.ASC,
};

export {DEFAULT_TABLE_OPTIONS};
