import {INSIGHTS_SECTION} from '@pandora/data-model-insights';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';

import {
  PATH_INSIGHTS_DASHBOARD,
  PATH_INSIGHTS_LOGS,
  PATH_INSIGHTS_LOGS_DETAILS,
  PATH_INSIGHTS_REPORTS,
  PATH_INSIGHTS_REPORTS_DETAILS,
} from './routing/insightsPaths';

/** Gets the translation given a key from Report Broker */
const getTranslationFromReportBroker = (intl, key, fallback) => {
  if (!intl || !key) {
    return fallback;
  }

  const newKey = key
    .replace('binky.widgets.', '') // Report Broker currently returns keys that expect to be translated in binky, just remove these
    .replace(/auditLogs\w+\./, 'auditLogs.'); // Any of the audit log report types should map their phrases to the one audit log content

  return intl.formatMessage({
    defaultMessage: fallback,
    id: newKey,
  });
};

/** Generates pathnames for Insights */
const getInsightsPaths = () => {
  const dashboard = () =>
    generatePath(PATH_INSIGHTS_DASHBOARD, {orgId: rootStore.organizationStore.activeOrgId});

  const reports = () =>
    generatePath(PATH_INSIGHTS_REPORTS, {orgId: rootStore.organizationStore.activeOrgId});

  const logs = () =>
    generatePath(PATH_INSIGHTS_LOGS, {orgId: rootStore.organizationStore.activeOrgId});

  const sectionDetails = (sectionName, insightsItemId, tabName) => {
    const path =
      sectionName === INSIGHTS_SECTION.LOGS
        ? PATH_INSIGHTS_LOGS_DETAILS
        : PATH_INSIGHTS_REPORTS_DETAILS;

    return generatePath(path, {
      insightsItemId,
      orgId: rootStore.organizationStore.activeOrgId,
      tabName,
    });
  };

  const reportDetails = (insightsItemId, tabName) =>
    sectionDetails(INSIGHTS_SECTION.REPORTS, insightsItemId, tabName);

  const logDetails = (insightsItemId, tabName) =>
    sectionDetails(INSIGHTS_SECTION.LOGS, insightsItemId, tabName);

  return {dashboard, logDetails, logs, reportDetails, reports, sectionDetails};
};

export {getTranslationFromReportBroker, getInsightsPaths};
