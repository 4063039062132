/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains2:appRemoveDomainModal
 *
 * @description The modal is to confirm the Remove Domain operation.
 *
 * @param {Array} domainItems. List of domains to be removed.
 * @param {Function} onConfirm. The remove domain operation confirmation callback.
 */
(function () {
  angular.module('app.widgets.domains2').component('appRemoveDomainModal', {
    bindings: {
      domainItems: '<',
      onConfirm: '&',
    },
    controller,
    templateUrl: 'app/widgets/domains2/remove-domain-modal/remove-domain-modal.component.html',
  });

  /* @ngInject */
  function controller(_, REMOVE_DOMAIN_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      domainName: '',
      onOpen,
      REMOVE_DOMAIN_MODAL_ID,
    });

    function onOpen() {
      // Used only if one domain is being removed.
      vm.domainName = _.get(vm, 'domainItems[0].domainName');
    }
  }
})();
