import ProductUsage from './ProductUsage';

class StockProductUsage extends ProductUsage {
  /**
     * @description Constructor for StockProductUsage model object.
     *
     * @param {Object} options - key/value options for StockProductUsage construction
     * @param {Number} options.consumed - Number of consumed quantities. If 
     *     presented with the used, the used represents the assigned quantity.
     * @param {Number} options.total - Number describing the total quantity of the product.
     * @param {String} options.unit - String describing the unit of the product usage (users, credits, licenses, etc).
     * @param {Number} options.used - Number describing the used quantity of the product.
     
     */
  constructor({consumed, total, unit, used}) {
    super({total, unit, used});
    this.consumed = consumed;

    if (this.consumed !== undefined) {
      const originalUnit = this.unit;
      this.unit = `${this.unit}_assigned`;
      this.consumedUnit = `${originalUnit}_consumed`;
    }
  }
}

export default StockProductUsage;
