import {LicenseGroup, LicenseGroupUserList, PageContext, feature} from '@admin-tribe/binky';
import {Subpage, useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import ProductUserStore from 'common/stores/user-store/ProductUserStore';
import {processLegacyRoles, saveProductRole} from 'common/utils/member-utils/memberProductUtils';
import rootStore from 'core/RootStore';
import {
  canAssignUser,
  canAssignUserIgnoringLicenseCounts,
  canRemoveUser,
} from 'core/products/access/productProfileAccess';
import TableProductRolePicker from 'features/products/components/table-product-role-picker/TableProductRolePicker';

/**
 * The subpage for the product profile/license configuration users.
 */
const ProfileUsersSubpage = observer(({licenseGroup, pageContext}) => {
  const intl = useIntl();

  const licenseGroupId = pageContext.targetId;
  const productId = pageContext.targetParentId;
  const product = rootStore.organizationStore.productList.items.find(
    (item) => item.id === productId
  );

  const [canAddMember, setCanAddMember] = useState(false);
  const [canAddMemberDisabled, setCanAddMemberDisabled] = useState(false);
  const [canRemoveMember, setCanRemoveMember] = useState(false);
  const [userOperations, setUserOperations] = useState();

  const productUserStore = useStore(
    () =>
      new ProductUserStore({
        listClassRef: LicenseGroupUserList,
        listOptions: {
          licenseGroupId,
          productId,
        },
        productId,
      })
  );

  // Finish initializing the store and then load the table list.
  useEffect(() => {
    const showMemberRoles = product.hasConfigurationSettingForLicenseGroupMember();
    const legacyRoles = processLegacyRoles({intl, licenseGroup});

    productUserStore.initProductRoles({
      legacyRoles,
      licenseGroupId: licenseGroup.id,
      showMemberRoles,
    });

    productUserStore.load();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- on init
  }, []);

  // Initialize the operations.
  // This will get re-called when the product is updated due to assign/unassign users.
  useEffect(() => {
    // Update the license group with the current state of the product.
    // The access methods use licenseGroup.product.
    licenseGroup.product = product;

    const canAdd = canAssignUserIgnoringLicenseCounts(licenseGroup);
    const canAddDisabled = !canAssignUser(licenseGroup);
    const canUnassign = canRemoveUser();

    setCanAddMember(canAdd);
    setCanAddMemberDisabled(canAddDisabled);
    setCanRemoveMember(canUnassign);

    const operations = UserOperations.getProfileUserOperations({
      canAdd,
      canAddDisabled,
      canUnassign: canRemoveMember,
      licenseGroupId: licenseGroup.id,
      offerId: licenseGroup?.product?.offerId,
      productId,
    });
    setUserOperations(operations);
  }, [product, productId, licenseGroup, canRemoveMember]);

  const onRoleChange = ({member, selectedId}) => {
    saveProductRole({
      pageContext,
      productUserStore,
      selectedItems: [member],
      selectedProductRole: selectedId,
    });
  };

  return (
    <Subpage>
      <MemberListContextProvider
        canAddMember={canAddMember}
        canAddMemberDisabled={canAddMemberDisabled}
        canRemoveMember={canRemoveMember}
        memberListDisplay={{
          email: true,
          idType: true,
          productRole: productUserStore.showProductRole,
          provisioningStatus: feature.isEnabled('temp_user_provisioning_status') ? true : undefined,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={productUserStore}
        userOperations={userOperations}
      >
        <UserListTableSection
          rolePicker={
            productUserStore.showProductRole && (
              <TableProductRolePicker
                // member prop is added in by MemberListTable
                onRoleChange={onRoleChange}
                roles={productUserStore.roles}
              />
            )
          }
        />
      </MemberListContextProvider>
    </Subpage>
  );
});

ProfileUsersSubpage.propTypes = {
  /**
   * The license group / product profile / license configuration model
   */
  licenseGroup: PropTypes.instanceOf(LicenseGroup).isRequired,
  /**
   * The PageContext object which describes the page target and targetType.
   * This is required for some of the analytics callback.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
};

export default ProfileUsersSubpage;
