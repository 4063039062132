import {View} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import {TooltipButton} from '@pandora/react-tooltip-button';
import ViewDetailIcon from '@spectrum-icons/workflow/ViewDetail';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';

import LegacyDeviceDetailsDrawer from './LegacyDeviceDetailsDrawer';

const namespace = 'products.deviceLicenses.deviceDetails.details.table';

/**
 * DeviceActionsMenu will display a Menu on the device item row in the device list table.
 * The menu will display a list of actions like View Details and Remove device
 */
const LegacyDevicesActionsMenu = ({device, poolId, updateTableChange}) => {
  const intl = useIntl();

  const openDrawer = useRef(null);

  const onActionPress = () => {
    openDrawer.current();
  };

  return (
    <>
      <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
        {(open) => {
          openDrawer.current = open;
        }}
        {() => (
          <LegacyDeviceDetailsDrawer
            device={device}
            poolId={poolId}
            updateTableChange={updateTableChange}
          />
        )}
      </DrawerTrigger>
      <View paddingEnd="size-100">
        <TooltipButton
          buttonAriaLabel={intl.formatMessage(
            {
              id: `${namespace}.actions.viewDetails`,
            },
            {deviceId: device.deviceId}
          )}
          data-testid="view-device-details-button"
          hoverText={intl.formatMessage({
            id: `${namespace}.actions.viewDetails`,
          })}
          isQuiet
          onPress={onActionPress}
          variant="action"
        >
          <ViewDetailIcon size="S" />
        </TooltipButton>
      </View>
    </>
  );
};

LegacyDevicesActionsMenu.propTypes = {
  /**
   * selected device details object
   */
  device: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
  }).isRequired,
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};

export default LegacyDevicesActionsMenu;
