import {useCallback, useMemo, useState} from 'react';

/**
 * This is a hook that manages a step list and provides functionality
 * for going to the next and previous step.
 *
 * @param {{steps: [{id: string}], initialStepId: string}} param0
 * @returns
 */
const useSteppedState = ({steps, initialStepId}) => {
  const [currentStep, setStep] = useState(steps.find((item) => item.id === initialStepId));
  const currentStepIndex = useMemo(() => steps.indexOf(currentStep), [steps, currentStep]);

  /**
   * Checks to see if a next step is available.
   */
  const canGoNext = useCallback(() => {
    if (currentStepIndex < steps.length - 1) {
      return true;
    }

    return false;
  }, [steps, currentStepIndex]);

  /**
   * Checks to see if a previous step is available.
   */
  const canGoPrevious = useCallback(() => {
    if (currentStepIndex !== 0 && currentStepIndex < steps.length) {
      return true;
    }

    return false;
  }, [steps, currentStepIndex]);

  /**
   * Goes to the next available step
   */
  const goNext = useCallback(() => {
    if (!canGoNext()) {
      return;
    }

    setStep(steps[currentStepIndex + 1]);
  }, [canGoNext, steps, setStep, currentStepIndex]);

  /**
   * Goes to the previous available step
   */
  const goPrevious = useCallback(() => {
    if (!canGoPrevious()) {
      return;
    }

    setStep(steps[currentStepIndex - 1]);
  }, [canGoPrevious, steps, setStep, currentStepIndex]);

  return {canGoNext, canGoPrevious, currentStep, goNext, goPrevious, goToStep: setStep};
};

export default useSteppedState;
