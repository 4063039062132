import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

const {SUPPORT_TICKET_IMPACT} = SUPPORT_CONSTANTS;
/**
 * Widget to display localized text for impact of SupportTicket.
 */
const SupportTicketImpact = ({impactCode}) => {
  const intl = useIntl();
  const stringKeyMap = {
    [SUPPORT_TICKET_IMPACT.LARGE]: 'large',
    [SUPPORT_TICKET_IMPACT.MEDIUM]: 'medium',
    [SUPPORT_TICKET_IMPACT.SMALL]: 'small',
  };
  const key = stringKeyMap[impactCode];
  return <View>{intl.formatMessage({id: `support.supportCase.impact.${key}`})}</View>;
};

SupportTicketImpact.propTypes = {
  /**
   * Prop used to get impactCode, this code is used to get localized text for impact
   */
  impactCode: PropTypes.number.isRequired,
};

export default SupportTicketImpact;
