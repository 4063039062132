(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc directive
   * @name app.widgets.disable-autocomplete:appDisableAutocomplete
   *
   * @description disable autocomplete on input elements
   *
   * @example <input app-disable-autocomplete></input>
   *
   */
  angular
    .module('app.widgets.disable-autocomplete')
    .directive('appDisableAutocomplete', ['$window', '_', appDisableAutocomplete]);

  function appDisableAutocomplete($window, _) {
    return {
      link: (scope, element) => {
        // Since the value 'nope' is not a valid one for the
        // 'autocomplete' attribute, the Chrome browser has no way
        // to match it and stops trying to autocomplete the field.
        element.attr('autocomplete', isChromeBrowser() ? 'nope' : 'off');
      },
      restrict: 'A',
    };

    /**
     * Check if the user browser is Google Chrome
     * source: https://stackoverflow.com/a/13348618
     * @return {Boolean} true if the user browser is Google Chrome
     */
    function isChromeBrowser() {
      const isChromium = $window.chrome;
      const winNav = $window.navigator;
      const vendorName = winNav.vendor;
      const isOpera = !_.isUndefined($window.opr);
      const isIEedge = _.includes(winNav.userAgent, 'Edge');

      return !_.isNil(isChromium) && _.includes(vendorName, 'Google') && !isOpera && !isIEedge;
    }
  }
})();
