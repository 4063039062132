import {FeaturesCache, feature} from '@admin-tribe/binky';
import {isFeatureEnabled} from '@pandora/feature-flag-vendors';
import {matchPath} from 'react-router-dom';

import routeMigration from './routeMigration';

const isArray = (object) => typeof object === 'object' && object.length > 0;

/**
 * Checks if a given route migration definition is enabled
 * @param {{stateName: string | string[], useReactRoute: boolean | string, url: string | string[]}} route
 * @returns {boolean}
 */
const isRouteEnabled = (route) => {
  if (typeof route.useReactRoute === 'boolean') {
    return route.useReactRoute;
  }

  if (typeof route.useReactRoute === 'string') {
    const featuresMap = FeaturesCache.get()?.map || {};
    return isFeatureEnabled(featuresMap, route.useReactRoute);
  }

  throw new Error('Invalid value for useReactRoute');
};

/**
 * Uses the list of migrated routes and filters out any routes where @see {useReactRoute} value
 * evaluates to false.
 * @returns {{stateName: string | string[], useReactRoute: boolean | string, url: string | string[]}[]} A subset of @see {routeMigration}
 */
const getAvailableReactRoutes = () => routeMigration.filter(isRouteEnabled);

/**
 * Will return a route with a single URL
 * @param {object} route
 * @returns
 */
const getRouteWithSingleUrl = (route) => {
  if (isArray(route.url)) {
    // Use the last URL in the list
    return {
      ...route,
      url: route.url.slice(-1)[0],
    };
  }

  return route;
};

/**
 * Checks if the given location is a React route and is active
 * @param {string} url
 * @returns {object | undefined}
 */
const findReactRouteByUrl = (url) => {
  const canRouteWithQueryParams = feature.isEnabled('use_routing_with_query_params');

  const availableReactRoutes = getAvailableReactRoutes();
  const path = canRouteWithQueryParams ? url?.pathname || url : url;

  const foundRoute = availableReactRoutes.find((value) => {
    if (typeof value.url === 'string') {
      return !!matchPath(value.url, path);
    }

    if (isArray(value.url)) {
      for (let i = 0, l = value.url.length; i < l; i++) {
        if (matchPath(value.url[i], path)) {
          return true;
        }
      }

      return false;
    }

    throw new Error('Invalid value for url');
  });

  return foundRoute ? getRouteWithSingleUrl(foundRoute) : undefined;
};

/**
 * Checks if a given Angular state name is ported to React and is active
 * @param {string} stateName
 * @returns {object | undefined}
 */
const findReactRouteByState = (stateName) => {
  const availableReactRoutes = getAvailableReactRoutes();

  // Angular states are sometimes prefixed with 'org.'
  const doStateNamesMatch = ({angularStateName, reactStateName}) =>
    [angularStateName, `org.${angularStateName}`].includes(reactStateName);

  const foundRoute = availableReactRoutes.find((value) => {
    if (typeof value.stateName === 'string') {
      return doStateNamesMatch({
        angularStateName: value.stateName,
        reactStateName: stateName,
      });
    }

    if (isArray(value.stateName)) {
      for (let i = 0, l = value.stateName.length; i < l; i++) {
        if (doStateNamesMatch({angularStateName: value.stateName[i], reactStateName: stateName})) {
          return true;
        }
      }

      return false;
    }

    throw new Error('Invalid value for stateName');
  });

  return foundRoute ? getRouteWithSingleUrl(foundRoute) : undefined;
};

/**
 * Determines if a given Angular state has a ported React route that is active
 * @param {string} stateName
 * @returns {boolean}
 */
const isReactRouteByState = (stateName) =>
  stateName === 'src2-route' || findReactRouteByState(stateName) !== undefined;

/**
 * Determines if a given URL has a ported React route that is active
 * @param {string} location
 * @returns {boolean}
 */
const isLocationReactRoute = (location) => findReactRouteByUrl(location) !== undefined;

/**
 * Determines if the current URL has a ported React route that is active
 * @returns {boolean}
 */
const isCurrentLocationReactRoute = () =>
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
  findReactRouteByUrl(window.location.pathname) !== undefined;

/**
 * Checks if a given path has a route that is enabled
 * @param {string} path
 * @returns {boolean}
 */
const isRouteEnabledByPath = (path) => {
  const foundRoute = routeMigration.find((migratedRoute) => {
    const url = migratedRoute.url;
    return isArray(url) ? url.includes(path) : url === path;
  });
  return foundRoute ? isRouteEnabled(foundRoute) : false;
};

/**
 * Given an array of React Router routes filter out the disabled ones.
 * NOTE: This method will modify the original array since we cannot deeply clone React elements to dereference
 *
 * @param {import('react-router-dom').RouteObject[]} routes
 * @param {string | null} parentPath - Used for recursion, should not be used outside of this function
 * @returns {import('react-router-dom').RouteObject[]}
 */
const filterDisabledReactRoutes = (routes, parentPath = null) =>
  routes.filter((route) => {
    // The URLs in routeMigration are the full path whereas the paths when defining a
    // route can be path segments that get appended onto parent path segments
    let fullRoutePath = '';
    if (route.path) {
      if (route.path.startsWith('/')) {
        fullRoutePath = route.path;
      } else {
        fullRoutePath = `${parentPath}/${route.path}`;
      }
    }

    if (route.children) {
      route.children = filterDisabledReactRoutes(route.children, fullRoutePath);
    }

    if (route.index) {
      if (!parentPath) {
        throw new Error(`Route was defined as an index route, but no parent exists: ${route.path}`);
      }

      // If a route is an index route then its enabled status is determined by the parent
      return isRouteEnabledByPath(parentPath);
    }

    return isRouteEnabledByPath(fullRoutePath);
  });

export {
  findReactRouteByUrl,
  findReactRouteByState,
  isCurrentLocationReactRoute,
  isLocationReactRoute,
  isReactRouteByState,
  filterDisabledReactRoutes,
};
