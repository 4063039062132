const LICENSE_GROUP_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.binkySrc2.services.product.licenseGroup.LICENSE_GROUP_CONSTANTS;
(function () {
  /**
   * @deprecated please use src2/app/services/product/licenseGroup/LicenseGroupConstants
   */
  angular
    .module('app.core.product.configurations')
    .constant(
      'PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE',
      LICENSE_GROUP_CONSTANTS.PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE
    )
    .constant(
      'PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT',
      LICENSE_GROUP_CONSTANTS.PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT
    )
    .constant(
      'PRODUCT_CONFIGURATION_QUOTA_UNLIMITED',
      LICENSE_GROUP_CONSTANTS.PRODUCT_CONFIGURATION_QUOTA_UNLIMITED
    );
})();
