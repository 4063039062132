import {feature, isOrganizationConsumable} from '@admin-tribe/binky';
import {OverlayWait} from '@admin-tribe/binky-ui';
import {View} from '@adobe/react-spectrum';
import {TableSection} from '@pandora/react-table-section';
import {TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ManagePlan from 'features/account/self-cancel/ManagePlan';

import useAcquisitionSummaryList from '../hooks/useAcquisitionSummaryList';

import SummaryItemsTable from './SummaryItemsTable';

// The table for displaying the SummaryItems (purchase details) from the acquisitions-summaries API.
// It is only rendered when an org has at least one product.
// For now, it will not render anything when the acquisitions cannot be fetched.
const SummaryItemsTableSection = ({contract, managePlanProps}) => {
  const intl = useIntl();
  const {acquisitionSummaryList, error, isLoading} = useAcquisitionSummaryList({
    contractId: contract.id,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  const shouldHideDivider =
    feature.isEnabled('temp_hide_account_page_product_table_header') ||
    feature.isEnabled('temp_show_account_page_product_change_plan');

  const titleSectionAdditionalProps = shouldHideDivider
    ? {
        dividerPlacement: 'NONE',
        headingLevel: 2,
      }
    : {
        headingLevel: 3,
      };

  let groupProductsSummaryItems, tableSectionLabel;
  let groupProductsByFamily = [];

  if (feature.isEnabled('temp_show_account_page_product_change_plan')) {
    tableSectionLabel =
      'account.acquisitions.summaryItemsTableSection.titledSection.tableLabelPlan';
  } else if (feature.isEnabled('temp_hide_account_page_product_table_header')) {
    tableSectionLabel = 'account.acquisitions.summaryItemsTableSection.titledSection.tableLabel';
  } else {
    tableSectionLabel = 'account.acquisitions.summaryItemsTableSection.titledSection.label';
  }

  // Exclude organization consumable items (e.g. Stock Credit Pack)
  const summaryItems = feature.isEnabled('temp_fix_subscription_based_products')
    ? acquisitionSummaryList
        ?.getSummaryItems()
        .filter(
          (item) =>
            !isOrganizationConsumable(item.offer?.fulfillableItemList) &&
            item.usesSeatBasedDelegation()
        )
    : acquisitionSummaryList
        ?.getSummaryItems()
        .filter((item) => !isOrganizationConsumable(item.offer?.fulfillableItemList));

  // Grouping products based on family and show sum of total quantity behind FF
  if (summaryItems && feature.isEnabled('temp_show_products_group_by_family')) {
    groupProductsByFamily = summaryItems.reduce((result, product) => {
      const productCode = product?.offer?.product_code;

      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else case needed
      // istanbul ignore else
      if (!result[productCode]) {
        product.totalQuantity = 0;
        result[productCode] = product;
      }
      result[productCode].totalQuantity += product.getTotalQuantity();
      return result;
    }, {});
    groupProductsSummaryItems = Object.values(groupProductsByFamily);
  } else {
    groupProductsSummaryItems = summaryItems;
  }

  const showManagePlanBtn = () => {
    if (!feature.isEnabled('trial_with_payment')) {
      return managePlanProps && <ManagePlan {...managePlanProps} />;
    }
    return null;
  };

  return (
    <TitledSection
      rightHeaderContent={showManagePlanBtn()}
      title={intl.formatMessage({
        id: tableSectionLabel,
      })}
      {...titleSectionAdditionalProps}
    >
      <View
        data-testid="acquisition-summary-item-table"
        marginY={
          feature.isEnabled('temp_hide_account_page_product_table_header') &&
          !feature.isEnabled('temp_show_account_page_product_change_plan')
            ? 'size-0'
            : 'size-200'
        }
      >
        <OverlayWait isLoading={isLoading} showContent={!!groupProductsSummaryItems}>
          <TableSection
            getItemKey={(item) => item.uuid}
            isServerError={!!error}
            items={groupProductsSummaryItems}
            // Setting pageNumber to 0 will hide the pagination buttons
            pageNumber={0}
          >
            <SummaryItemsTable managePlanProps={managePlanProps} />
          </TableSection>
        </OverlayWait>
      </View>
    </TitledSection>
  );
};

SummaryItemsTableSection.propTypes = {
  /** The contract model */
  contract: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  /**
   * The props for the ManagePlan component. When the account page is moved to src2, these
   * props should be lifted to that component, and SummaryItemsTableSection should accept children
   * so that we do not need to prop drill and can use composition instead. Only displays ManagePlan
   * when the props are provided.
   */
  managePlanProps: PropTypes.shape({
    contract: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }),
};

export default SummaryItemsTableSection;
