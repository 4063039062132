import {Store} from '@admin-tribe/binky';
import {TableConstants, TableStore} from '@admin-tribe/binky-ui';
import {action, makeObservable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';

import PackagesAdobeProducts from '../services/PackagesAdobeProductsService';
import PackagesAdobeProductsUi from '../services/PackagesAdobeProductsUiService';
import PackagesAdobeTemplatesList from '../services/PackagesAdobeTemplatesService';
import PackagesEntitlements from '../services/PackagesEntitlementsService';
import PackagesExtensions from '../services/PackagesExtensionsService';
import PackagesLanServers from '../services/PackagesLanServersService';
import PackagesPreferences from '../services/PackagesPreferencesService';

import {templateFilter} from './packagesAdobeTemplatesStoreUtils';

// Domain data for Packages Adobe Templates page
class PackagesAdobeTemplatesStore extends Store {
  filteredAdobeTemplates = undefined;
  packagesAdobeTemplatesList = [];

  tableStore;

  constructor() {
    super();

    makeObservable(this, {
      fetchData: action.bound,
      goToNextPage: action.bound,
      goToPageNumber: action.bound,
      goToPreviousPage: action.bound,
      onPageSizeChange: action.bound,
      search: action.bound,
    });

    // Set this to true right away so the table doesn't have a chance to think it is empty.
    this.isLoading = true;

    this.tableStore = new TableStore({
      getKey: (item) => item.id,
      pageSize: 10,
    });
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    // Fetch in this order:
    // 1. PackagesUiConstants
    // 2. PackagesPreferences
    // 3. PackagesEntitlements
    // 4. PackagesAdobeProducts
    // 5. PackagesAdobeProductsUi
    // 6. PackagesAdobeTemplates
    await rootStore.packagesUiConstantsStore.fetchUiConstants();
    await PackagesEntitlements.fetchEntitlements();
    await PackagesPreferences.fetchUserPreferences();
    if (
      PackagesEntitlements.hasCcEntitlement ||
      PackagesEntitlements.hasDcEntitlement ||
      PackagesEntitlements.hasSubstanceNamedEntitlement
    ) {
      await PackagesExtensions.initApis();
    }
    await PackagesAdobeProducts.fetchAndProcessAdobeProducts();
    await PackagesAdobeProductsUi.fetchAndProcessAdobeProducts();
    if (PackagesEntitlements.hasFrlLanEntitlement) {
      await PackagesLanServers.fetchAndProcessLanServers();
    }
    const templatesList = await PackagesAdobeTemplatesList.get({});
    this.packagesAdobeTemplatesList = templatesList.items.filter(templateFilter);
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  // Function to filter the templates list
  filterAdobeTemplatesList() {
    // if search query not empty, then add templates to list based on the name/platform matching search query
    if (this.tableStore.searchQuery) {
      this.filteredAdobeTemplates = this.packagesAdobeTemplatesList.filter((item) => {
        const lowerCaseName = item.name.toLocaleLowerCase();
        const lowerCasePlatform = item.platformString.toLocaleLowerCase();
        const lowerCaseSearchText = this.tableStore.searchQuery.toLocaleLowerCase();
        return (
          lowerCaseName.includes(lowerCaseSearchText) ||
          lowerCasePlatform.includes(lowerCaseSearchText)
        );
      });
    } else {
      this.filteredAdobeTemplates = this.packagesAdobeTemplatesList;
    }
    runInAction(() => {
      this.tableStore.setTotalItems(this.filteredAdobeTemplates.length);
      // Add templates as data to the rows visible in table, based on the current page and page size
      this.tableStore.mapDataToRows(
        this.filteredAdobeTemplates.slice(
          (this.tableStore.currentPage - 1) * this.tableStore.pageSize,
          this.tableStore.currentPage * this.tableStore.pageSize
        )
      );
    });
  }

  goToNextPage() {
    return this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  // Sets current page number and updates the list accordingly
  goToPageNumber(newPageNumber) {
    this.tableStore.setCurrentPage(newPageNumber);
    return this.filterAdobeTemplatesList();
  }

  goToPreviousPage() {
    return this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  onPageSizeChange(pageSize) {
    this.tableStore.setPageSize(pageSize);

    // Reset page back to the first page and filter the list
    return this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  search(value) {
    this.tableStore.searchQuery = value;

    // Reset page back to the first page and filter the list based on search query
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }
}

export default PackagesAdobeTemplatesStore;
