import {log, modelCache} from '@admin-tribe/binky';

import adobeioConsoleIntegrations from 'common/api/adobeio-console/adobeioConsoleIntegrations';
import ApiOrganizationList from 'core/api-organization/ApiOrganizationList';

import ApiIntegration from './ApiIntegration';

const API_INTEGRATION_LIST_CACHE_ID = 'ApiIntegrationList';

/**
 * The list of adobeio integrations for the specified Renga org.
 */
class ApiIntegrationList {
  /**
   * @description Method to retrieve an existing list of organization users
   *              from the back-end.
   *
   * @param {Object} options - Options for the constructor
   * @returns {Promise<ApiIntegrationList>} Promise that resolves to the instance when the refresh is successful
   */
  static get({rengaOrgId}) {
    const model = new ApiIntegrationList({rengaOrgId});

    const cachedPromise = modelCache.get(API_INTEGRATION_LIST_CACHE_ID, rengaOrgId);
    if (cachedPromise) {
      return cachedPromise;
    }

    // Item is not already cached.
    const promise = model.refresh();
    modelCache.put(API_INTEGRATION_LIST_CACHE_ID, rengaOrgId, promise);
    return promise;
  }

  /**
   * @description Constructor for ApiIntegrationList model .
   */
  constructor({rengaOrgId}) {
    this.adobeioOrgId = undefined;
    this.rengaOrgId = rengaOrgId;
    this.items = [];
  }

  /**
   * @description Method to refresh the contents of the list.
   * @returns {Promise<ApiIntegrationList>} promise - resolved when the list is refreshed.
   */
  async refresh() {
    let adobeioOrgs, apiIntegrationList;

    try {
      // Get the list of adobeio organizations.
      adobeioOrgs = await ApiOrganizationList.get();
    } catch (error) {
      return Promise.reject(error);
    }

    // Find the adobeio organization which maps to the current org.
    this.adobeioOrgId = adobeioOrgs.getAdobeioOrgId({rengaOrgId: this.rengaOrgId});

    try {
      // Get the list of intergrations for the adobeio organization.
      apiIntegrationList = await adobeioConsoleIntegrations.getOrganizationIntegrations({
        orgId: this.adobeioOrgId,
      });
    } catch (error) {
      log.error(
        `ApiIntegrationList.refresh() failed to load organization integrations. Error: ${error}`
      );
      return Promise.reject(error);
    }

    // Construct the list which is Array<ApiIntegration).
    this.items = apiIntegrationList.map((apiIntegration) =>
      ApiIntegration.apiResponseTransformer(apiIntegration)
    );

    return this;
  }
}

export default ApiIntegrationList;
