import {getHeaders} from '@admin-tribe/binky';
import axios from 'axios';

import rootStore from 'core/RootStore';

let clientId, includeRoles, url;

/**
 * Configure JIL storage quota APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Fetches the organization's storage quota.
 *
 * @return {Promise} a promise which will resolve with the response.
 */
const getStorageQuota = () =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-quota`, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilStorageQuota = {
  configure,
  getStorageQuota,
};

export default jilStorageQuota;
