(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSessionTopicFormSection
   *
   * @description The session topic section for an expert session form. Use for either the Admin topics, or
   *   the topics for a particular product.
   *
   *   For Admin, the topics will be fetched immediately.
   *   For Product, the topics will be fetched once the the fulfillableItemCode is set. Until then the selector will
   *   be disabled and show the placeholder text.
   *
   *   In both cases, while the topics are being retrieved from the backend, the selector will be disabled, show the
   *   placeholder text, and have a wait '...' over it.
   *
   * @binding {Array} [adminTopics]. If caseType ADMIN, the topics list which has already been fetched from the backend.
   * @binding {String} caseType. ADMIN or PRODUCT from SUPPORT_TICKET_CASE_TYPE constant.
   * @binding {String} [fulfillableItemCode]. If caseType PRODUCT, the fulfillable item code for the selected product.
   * @binding {Function} onChange. Callback for when the session topic changes. Param is expertTitleCode which is an
   *   Integer. Or if param is undefined, it means there was a problem retrieving the session topics from the backend.
   */
  angular.module('app.widgets.support').component('appSessionTopicFormSection', {
    bindings: {
      adminTopics: '<?',
      caseType: '<',
      fulfillableItemCode: '<?',
      onChange: '&',
      onFetchTopicsError: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/support/request-expert-session-modal/session-topic-form-section/session-topic-form-section.component.html',
  });

  function controller(
    $filter,
    $q,
    $translate,
    _,
    EXPERT_TITLE_CODE,
    ExpertSession,
    SUPPORT_TICKET_CASE_TYPE
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      hasNoTopics,
      onSessionTopicChange,
      waitPromise: $q.resolve(),
    });

    function $onChanges() {
      if (vm.caseType === SUPPORT_TICKET_CASE_TYPE.PRODUCT) {
        // If a product is selected, get the session topics for it.
        if (vm.fulfillableItemCode) {
          getProductSessionTopics();
        } else {
          // If the selected product is removed, re-init the session topics.
          // In the unlikely event there is an outstanding request, wait for it to complete before doing the
          // initialization so that topics doesn't get overwritten.
          // Since $resource cancellable seems to have issues this should be good enough.
          vm.waitPromise.finally(() => {
            initTopics();
          });
        }
      } else if (vm.adminTopics) {
        vm.topics = sortTopics(vm.adminTopics);
      } else {
        initTopics();
      }
    }

    function $onInit() {
      // Ensure placeholder has been translated before the select directive sets itself up.
      vm.placeholder = $translate.instant('widgets.support.sessionTopicFormSection.placeholder');
    }

    // Determines whether or not the select is disabled.
    function hasNoTopics() {
      return _.isEmpty(vm.topics);
    }

    function onSessionTopicChange(expertTitleCode) {
      vm.onChange({expertTitleCode: _.isInteger(expertTitleCode) ? expertTitleCode : undefined});
    }

    /////////////////

    function getProductSessionTopics() {
      vm.waitPromise = ExpertSession.getMetadata({
        caseType: vm.caseType,
        fulfillableItemCode: vm.fulfillableItemCode,
      })
        .then((response) => {
          vm.topics = sortTopics(response.topics);

          // We should at least get back an 'Other' topic for every product but check to be sure.
          if (hasNoTopics()) {
            throw new Error('no topics');
          }
        })
        .catch(() => {
          onSessionTopicChange();
          vm.onFetchTopicsError();
        });
    }

    // Note: if the binky-select items attribute has topics and then is set to have no topics, binky-select will call
    // its onChange handler which will in turn call our onSessionTopicChange so there is no need to call
    // onSessionTopicChange here.
    function initTopics() {
      vm.topics = []; // when topics are empty, the select just has the placeholder
    }

    // The list should be alpha-sorted with the exception of the 'Other' topic which should always
    // be the last entry in the list.
    function sortTopics(topics) {
      const sortedArray = $filter('orderBy')(topics, 'expertTitle');
      const otherArray = _.remove(sortedArray, ['expertTitleCode', EXPERT_TITLE_CODE.OTHER]);

      return _(sortedArray).concat(otherArray).compact().value();
    }
  }
})();
