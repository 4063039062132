import {LicenseGroupAdminList, PageContext} from '@admin-tribe/binky';
import {Subpage, useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {canAssignAdmin, canRemoveAdmin} from 'core/products/access/productProfileAccess';

/**
 * The subpage for the product profile/license configuration admins.
 */
const ProfileAdminsSubpage = observer(({pageContext}) => {
  const adminStore = useStore(
    () =>
      new AdminStore({
        immediateLoad: true,
        listClassRef: LicenseGroupAdminList,
        listOptions: {configurationId: pageContext.targetId, productId: pageContext.targetParentId},
      })
  );

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  return (
    <Subpage>
      <MemberListContextProvider
        canAddMember={canAssignAdmin()}
        canRemoveMember={canRemoveAdmin()}
        getIsItemSelectable={getIsItemSelectable}
        memberListDisplay={{
          email: true,
          idType: true,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={adminStore}
      >
        <AdminListTableSection />
      </MemberListContextProvider>
    </Subpage>
  );
});

ProfileAdminsSubpage.propTypes = {
  /**
   * The PageContext object which describes the page target and targetType.
   * This is required for some of the analytics callback.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
};

export default ProfileAdminsSubpage;
