(function () {
  /**
   * @deprecated please use {ACCOUNT_STATE_PARAM} from src2/app/core/account/account-meta/AccountMetaConstants.js
   */
  angular.module('app.account.meta').constant('ACCOUNT_STATE_PARAM', {
    ACTION: {
      EDIT_PAYMENT: 'edit-payment',
    },
    AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING: 'payInvoice',
  });
})();
