import binky from '@admin-tribe/binky';

import noAssignmentIcon from './NoAssignment.svg';
import {
  COLUMN_KEY,
  NO_ASSIGNMENT_ID,
  SORT_DIRECTION_ASCENDING,
} from './ProductMigrationTable.constants';

const Locale = binky.services.Locale;

/**
 * @description Return a list of product ID's that should be disabled
 * @param {Object} migration Migration data
 * @param {Array<Object>} migration.targets List of target products
 * @param {Number} migration.targets[n].count Available license count
 * @param {Object} [userTargetProducts] Map of each user's selected target products,
 * see the return value of getUserTargetProducts method.
 * @returns {Array<String>} List of product ID's that has 0 count, or
 * already assigned to the user
 */
function getDisabledProductIdList(migration, userTargetProducts = {}) {
  return migration.targets.reduce((disabledProductIdList, target) => {
    if (target.count === 0 || userHasTargetProductId(target)) {
      disabledProductIdList.push(target.id);
    }
    return disabledProductIdList;
  }, []);

  ////////

  function userHasTargetProductId(target) {
    const userId = migration.user.id;
    return userTargetProducts[userId]?.[target.id];
  }
}

/**
 * @description Returns a shallow copy of eligible migration rows sorted
 * @param {Array<Object>} eligibleMigrations Eligible migration rows to sort on,
 * @param {Object} sortDescriptor
 * @param {String} sortDescriptor.column Column key to sort on
 * @param {String} sortDescriptor.direction Sort direction, SORT_DIRECTION_ASCENDING for ascending sort, otherwise assume descending
 * @returns {Array<Object>} Sorted and shallow copy of eligibleMigrations
 */
function getSortedEligibleMigrations(eligibleMigrations, {column, direction}) {
  const language = Locale.get().activeLanguageBCP47Code;
  const primaryKey = column;
  const secondaryKey = column === COLUMN_KEY.NAME ? COLUMN_KEY.SOURCE_PRODUCT : COLUMN_KEY.NAME;

  // 'sort' is an in-place operation so shallow-copy and 'sort', thus React can detect changes
  return [...eligibleMigrations].sort((a, b) => {
    const primaryValueA = getValue(a, primaryKey);
    const primaryValueB = getValue(b, primaryKey);

    // Ignore the secondary key if the primary key doesn't match
    const primarySortResult = primaryValueA.localeCompare(primaryValueB, language);
    if (primarySortResult !== 0) {
      const directionMultiplier = direction === SORT_DIRECTION_ASCENDING ? 1 : -1;
      return primarySortResult * directionMultiplier;
    }

    // Secondary key is always ascending
    const secondaryValueA = getValue(a, secondaryKey);
    const secondaryValueB = getValue(b, secondaryKey);
    return secondaryValueA.localeCompare(secondaryValueB, language);
  });

  ////////

  function getValue(eligibleMigration, columnKey) {
    switch (columnKey) {
      case COLUMN_KEY.NAME:
        return eligibleMigration.user.getDisplayNameOrEmail();

      case COLUMN_KEY.SOURCE_PRODUCT:
        return eligibleMigration.source.displayName;

      default:
        return '';
    }
  }
}

/**
 * @description Return target product list for ProductPicker to render
 * @param {Object} intl React intl
 * @param {Array<Object>} targetList Array of target products (see ProductMigrationTable eligibleMigrations.targets prop)
 * @returns {Array<Object>} Array of Objects for ProductPicker.
 *   [
 *     {displayName, iconUrl, id},
 *     ...
 *   ]
 */
function getTargetProductList(intl, targetList) {
  const language = Locale.get().activeLanguageBCP47Code;

  const productList = targetList.sort((a, b) =>
    a.displayName.localeCompare(b.displayName, language)
  );

  // No assignment must be the 1st option
  return [
    {
      displayName: intl.formatMessage({
        id: 'products.productMigrationTable.productList.noAssignment',
      }),
      iconUrl: noAssignmentIcon,
      id: NO_ASSIGNMENT_ID,
    },
    ...productList,
  ];
}

/**
 * @description Get a map of assigned target products
 * @param {Array<Object>} migrations List of migration data
 * @returns {Object} Map of assigned target products to ease lookup.
 * Format:
 *   {
 *     [user.id]: {
 *       [target.id]: true,
 *       ...
 *     },
 *     ,,,
 *   }
 */
function getUserTargetProducts(migrations) {
  const result = {};

  migrations.forEach((migration) => {
    const userId = migration.user.id;
    const userProducts = result[userId] || {};

    const selectedTargetId = migration.selectedTargetId;
    if (selectedTargetId && selectedTargetId !== NO_ASSIGNMENT_ID) {
      userProducts[selectedTargetId] = true; // value doesn't matter
    }
    result[userId] = userProducts;
  });

  return result;
}

/**
 * @description Update the migration data with the new selected target product ID
 * @param {Object} options Required options
 * @param {Object} options.migration The eligible migration data that has the new target product ID
 * @param {String} options.selectedId New target product ID
 */
function updateMigrationTargetSelection({migration, selectedId}) {
  // Increase the previous product count
  const previousTargetId = migration.selectedTargetId;
  if (previousTargetId && previousTargetId !== NO_ASSIGNMENT_ID) {
    const previousTarget = migration.targets.find((target) => target.id === previousTargetId);
    previousTarget.count += 1; // previousTarget must exist since it was set before
  }

  // Decrease the new target product count
  if (selectedId !== NO_ASSIGNMENT_ID) {
    const selectedTarget = migration.targets.find((target) => target.id === selectedId);
    selectedTarget.count -= 1;
  }

  migration.selectedTargetId = selectedId;
}

export {
  getDisabledProductIdList,
  getSortedEligibleMigrations,
  getTargetProductList,
  getUserTargetProducts,
  updateMigrationTargetSelection,
};
