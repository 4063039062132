(function () {
  /**
   * @ngdoc module
   * @name app.widgets.offers.free-offer-modal
   * @description module that defines the free offer acquisition modal
   */
  angular.module('app.widgets.offers.free-offer-modal', [
    'app.core.cart',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.widgets.offers.free-offer-details-form',
    'app.widgets.organization-type-form',
    'app.widgets.organizations',
    'app.widgets.products',
    'binky.core.common.analytics',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.organizations',
    'binky.core.organizations.market-subsegments',
    'binky.shell.panels.modal',
    'binky.widgets.common.button',
    'binky.widgets.common.toast',
    'binky.widgets.common.wizard',
  ]);
})();
