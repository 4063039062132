(function () {
  /**
   * @deprecated with temp_port_react_invoice
   *
   * @ngdoc component
   *
   * @name app.widgets.account:appInvoiceListTable
   * @description display table of Invoice (using InvoiceList) for an organization.
   *
   * @param {invoiceList} Instance of InvoiceList, list of invoices to display
   * @param {Integer} [itemsPerPage] Number of items per page, specify to turn off pagination and sorting
   *
   *
   */
  angular.module('app.widgets.account').component('appInvoiceListTable', {
    bindings: {
      invoiceList: '<',
      itemsPerPage: '<?', // if undefined, both sorting and pagination are enabled.
      onPayNowClick: '&?',
      showPoNumberColumn: '<?',
    },
    controller,
    templateUrl: 'app/widgets/account/invoice-list-table/invoice-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    INVOICE_LIST_PAGINATION_ID,
    INVOICE_SORT,
    JIL_CONSTANTS,
    panelManager,
    INVOICE_PAYMENT_STATUS,
    price,
    Selection,
    SELECTION_STATE
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    ///////////////

    function $onInit() {
      _.assign(vm, {
        INVOICE_SORT,
        isAllChecked: false,
        isChecked,
        isSubsetChecked: false,
        onClickCheck,
        onClickCheckAll,
        onPageChange,
        onPageSizeChange,
        selection: new Selection('id'),
        setSortState,
        showPayNowLink,
        sortField: INVOICE_SORT.INVOICE_DATE,
        sortStates: [],
      });
      // if itemsPerPage is undefined, then sorting and pagination are enabled.
      vm.sortingIsEnabled = _.isUndefined(vm.itemsPerPage);
      vm.paginationId = vm.sortingIsEnabled ? INVOICE_LIST_PAGINATION_ID : undefined;
      vm.setSortState = vm.sortingIsEnabled ? setSortState : undefined;
      vm.itemsPerPage = vm.itemsPerPage || this.invoiceList.pagination.pageSize;

      updateHeaderCheckbox();
      initSortState(INVOICE_SORT.INVOICE_AMOUNT, false);
      initSortState(INVOICE_SORT.INVOICE_DATE, true);
    }

    function showPayNowLink(invoice) {
      return (
        vm.showPoNumberColumn &&
        (invoice.invoiceStatus === INVOICE_PAYMENT_STATUS.PAYMENT_DUE ||
          invoice.invoiceStatus === INVOICE_PAYMENT_STATUS.PAST_DUE)
      );
    }

    function isChecked(invoice) {
      return vm.selection.isItemSelected(invoice);
    }

    function onClickCheck(invoice) {
      vm.selection.toggleItemSelection(invoice);
    }

    function onClickCheckAll() {
      updateHeaderCheckbox();
      if (vm.isAllChecked) {
        vm.selection.deselectItems(vm.invoiceList.items);
      } else {
        vm.selection.selectItems(vm.invoiceList.items);
      }
    }

    function onPageChange(newPage) {
      vm.invoiceList.setPage(newPage - 1);
      refreshData();
    }

    function onPageSizeChange(pageSize) {
      vm.invoiceList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.invoiceList.setPage(0);
      refreshData();
    }

    function setSortState(desiredSort) {
      // if desired sort is the current sort, then reverse it
      if (vm.sortField === desiredSort) {
        vm.sortStates[desiredSort] = !vm.sortStates[desiredSort];
      } else {
        vm.sortField = desiredSort;
      }
      vm.invoiceList.sortBy(vm.sortField, vm.sortStates[desiredSort]);
      refreshData();
    }

    function initSortState(name, isDescending) {
      vm.sortStates[name] = isDescending;
    }

    ///////////////

    function refreshData(params = {}) {
      vm.invoiceList.refresh(params);
    }

    function updateHeaderCheckbox() {
      if (_.get(vm, 'invoiceList.items.length', 0) > 0) {
        const selectionState = vm.selection.getSelectionStateForItems(vm.invoiceList.items);
        vm.isAllChecked = selectionState === SELECTION_STATE.ALL;
        vm.isSubsetChecked = selectionState === SELECTION_STATE.SUBSET;
      } else {
        vm.isAllChecked = false;
        vm.isSubsetChecked = false;
      }
    }
  }
})();
