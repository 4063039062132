import {View} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';

const TermsAndConditionsHoverOverTooltip = ({htmlContent, showTerms, hoverOverText}) => {
  if (!htmlContent) {
    return null;
  }

  return (
    <View
      backgroundColor="gray-200"
      data-testid="one-console-hover-over-tooltip"
      marginBottom="18px"
      UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-font-size-50)'}}
    >
      <View UNSAFE_style={{margin: '10px 18px'}}>
        <SafeHtml dataTestId="one-console-tooltip-text" html={htmlContent} />
        {showTerms && (
          <TooltipButton
            data-testid="one-console-hover-over-button"
            hoverText={hoverOverText}
            isQuiet
            tooltipDelayMs={0}
            tooltipPlacement="bottom"
            UNSAFE_style={{height: '5%', top: '-2px', width: '5%'}}
            variant="action"
          >
            <InfoOutlineIcon UNSAFE_style={{width: '40%'}} />
          </TooltipButton>
        )}
      </View>
    </View>
  );
};

TermsAndConditionsHoverOverTooltip.propTypes = {
  hoverOverText: PropTypes.string,
  htmlContent: PropTypes.string,
  showTerms: PropTypes.bool,
};

export default TermsAndConditionsHoverOverTooltip;
