/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase:appProductPurchaseAccountTotal
 *
 * @description Component to display the cart total in the footer
 * @example
 *  <app-cart-total-footer
 *    contract="$ctrl.contract"
 *    is-renewal="$ctrl.isRenewal"
 *    wait-promise="$ctrl.waitPromise">
 *  </app-cart-total-footer>
 * @param {Contract} contract model instance.
 * @param {Boolean} [isRenewal] true if renewal version, to decide whether to show "Renewal total" or "Order total"
 * @param {Promise} waitPromise if a direct contract it is resolved with an object that contains the cart model and the
 *   totalSummary and if indirect it is resolved with an object which contains the 'license_count'
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appCartTotalFooter', {
    bindings: {
      contract: '<',
      isRenewal: '<?',
      waitPromise: '<',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/cart-total-footer/cart-total-footer.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    _,
    $translate,
    binkyContractPerTermPriceWithTaxFilter,
    binkyContractPriceWithTaxFilter,
    numberFilter,
    price,
    PURCHASE_WAITSTR
  ) {
    const vm = this;
    // In order to style the price, we pass in a dummy price and replace it with the formatted price with style.
    const pricePlaceholder = _.uniqueId('CART_TOTAL_FOOTER_PRICE_PLACEHOLDER');

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      vm.waitPromise = vm.waitPromise || $q.resolve();
      vm.waitPromise.then(updateCartTotal).catch(updateCartTotalWhenError);
    }

    ////////////

    function getTitle(response) {
      const titleKey = determineTitleKey();

      // For direct contract, we look at the cart modal.
      // For indirect contract, since we do not make any CCS calls when the user updates the license count, we rely on
      // the parent container to pass the license count through promise.
      const licenseCount = vm.contract.isDirectContract()
        ? _.invoke(response, 'cart.getUninvoicedLicenseCount')
        : _.get(response, 'license_count');

      return _.isUndefined(licenseCount)
        ? ''
        : $translate.instant(
            `widgets.products.purchase.cartTotalFooter.messageFormat.${titleKey}Title`,
            {
              count: numberFilter(licenseCount),
            },
            'messageformat'
          );
    }

    function getPriceWithTax({currency, proration, totalIncludesTax, total}) {
      // binkyContractPriceWithTaxFilter and binkyContractPerTermPriceWithTaxFilter don't allow the caller
      // to style the price in the tax string so we have to do it here by using a pricePlaceholder to get the
      // formatted string and then we replace the pricePlaceholder with the actual formatted price.
      const priceWithTax = vm.contract.isPUF()
        ? binkyContractPriceWithTaxFilter(vm.contract, totalIncludesTax, pricePlaceholder)
        : binkyContractPerTermPriceWithTaxFilter(vm.contract, totalIncludesTax, pricePlaceholder);

      const useProration = !_.isUndefined(proration) && vm.contract.isPUF();
      const priceValue = useProration ? proration.total : total;
      const currencyFormat = useProration ? proration.currency : currency;

      // Replace the interpolated unique string with formattedPrice HTML
      const formattedPrice = price.format(priceValue, currencyFormat);

      return _.replace(priceWithTax, pricePlaceholder, `<span>${formattedPrice}</span>`);
    }

    function determineTitleKey() {
      let titleKey;
      if (vm.contract.isDirectContract()) {
        titleKey = vm.isRenewal ? 'renewal' : 'purchase';
      } else {
        titleKey = 'indirect';
      }
      return titleKey;
    }

    // For a direct contract, cart is an object with a Cart modal and the totalSummary, and for indirect,
    // the response is an object which contains license_count.
    function updateCartTotal(response) {
      vm.title = getTitle(response);
      if (_.has(response, 'totalSummary') && vm.contract.isDirectContract()) {
        vm.price = getPriceWithTax(response.totalSummary);
      }
    }

    function updateCartTotalWhenError() {
      const titleKey = determineTitleKey();
      vm.title = $translate.instant(
        `widgets.products.purchase.cartTotalFooter.messageFormat.${titleKey}Title`,
        {
          count: PURCHASE_WAITSTR,
        },
        'messageformat'
      );
      const priceWithTax = vm.contract.isPUF()
        ? binkyContractPriceWithTaxFilter(vm.contract, vm.includesTax, pricePlaceholder)
        : binkyContractPerTermPriceWithTaxFilter(vm.contract, vm.includesTax, pricePlaceholder);
      vm.price = _.replace(priceWithTax, pricePlaceholder, `<span>${PURCHASE_WAITSTR}</span>`);
    }
  }
})();
