import {ActionButton, Flex, Text, View} from '@adobe/react-spectrum';
import ChevronLeftIcon from '@spectrum-icons/workflow/ChevronLeft';
import ChevronRightIcon from '@spectrum-icons/workflow/ChevronRight';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * CarouselControls - controls for cycling to the next and previous banner
 * message, and shows a label indicating the index of the banner currently being
 * viewed.
 */
const CarouselControls = observer(() => {
  const intl = useIntl();

  const globalBannerStore = rootStore.organizationStore.globalBannerStore;
  const currentBanner = globalBannerStore.currentBanner;

  return (
    // This View keeps the carousel inline with the dismiss button
    <View alignContent="start" marginEnd="size-150">
      <Flex alignItems="center" gap="size-75">
        <ActionButton
          aria-label={intl.formatMessage({id: 'globalBanner.controls.ariaLabel.previous'})}
          data-testid="previous-button"
          isQuiet
          onPress={() => {
            globalBannerStore.advanceToPreviousBanner();
          }}
          staticColor="white"
        >
          <ChevronLeftIcon />
        </ActionButton>
        <Flex flexShrink={0} justifyContent="center">
          <Text data-testid="current-banner-index" UNSAFE_style={{color: 'white'}}>
            {intl.formatMessage(
              {id: 'globalBanner.controls.carousel.label'},
              {
                currentIdx: currentBanner.index + 1,
                numberOfQueuedBanners: globalBannerStore.numberOfQueuedBanners,
              }
            )}
          </Text>
        </Flex>
        <ActionButton
          aria-label={intl.formatMessage({id: 'globalBanner.controls.ariaLabel.next'})}
          data-testid="next-button"
          isQuiet
          onPress={() => {
            globalBannerStore.advanceToNextBanner();
          }}
          staticColor="white"
        >
          <ChevronRightIcon />
        </ActionButton>
      </Flex>
    </View>
  );
});

export default CarouselControls;
