import {ConsumableUsageList} from '@admin-tribe/binky';
import {OverlayWait} from '@admin-tribe/binky-ui';
import {TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import useTableSectionPagination from 'common/hooks/useTableSectionPagination';

/**
 * @description Table for displaying lists of product group products that are
 *   consumable (i.e. - credit packs, transactions). Since the transactions API
 *   is not paginated (response contains all items; see:
 *   https://jira.corp.adobe.com/browse/DCES-4253556) and expired stock credit
 *   packs return an error when fetching, we use this class to avoid making any
 *   extra errant calls and to paginate results in the front-end.
 *
 *   Should not be used to display non-consumable grouped product data
 * @param {Consumable} consumable - summary to build table data
 * @param {ProductGroupProductList} productGroup - list to build table row names
 *   from
 */
const UnpaginatedConsumablesListTableSection = ({
  children,
  hasLoadingError = false,
  isLoading = false,
  usageList,
}) => {
  const usageListItems = useMemo(() => usageList?.items ?? [], [usageList]);
  const {currentItems, currentPageNumber, currentTotalPages, onTableSectionChange} =
    useTableSectionPagination({items: usageListItems, sortByKey: 'expirationDate'});

  return (
    <OverlayWait isLoading={isLoading} showContent={!isLoading} size="M">
      <TableSection
        getItemKey={(item) => item.id}
        isServerError={hasLoadingError}
        items={currentItems}
        onTableSectionChange={onTableSectionChange}
        pageNumber={currentPageNumber}
        totalPages={currentTotalPages}
      >
        {children}
      </TableSection>
    </OverlayWait>
  );
};

UnpaginatedConsumablesListTableSection.propTypes = {
  /**
   * Table to show, must be either a ConsumablesListTable or TransactionsListTable
   */
  children: PropTypes.element.isRequired,
  /**
   * If error loading table data this should be true, else false.
   * Defaults to false
   */
  hasLoadingError: PropTypes.bool,
  /**
   * Whether or not the list fetch method is still loading data. Defaults to false
   */
  isLoading: PropTypes.bool,
  /**
   * ConsumableUsageList to build table data from. Defaults to empty list
   */
  usageList: PropTypes.instanceOf(ConsumableUsageList),
};

export default UnpaginatedConsumablesListTableSection;
