const CONTRACT_TABLE_DATA_KEYS = {
  ACTIONS: 'actions',
  CAN_BUY_MORE: 'canBuyMore',
  CONTRACT_DISPLAY_NAME: 'contractDisplayName',
  CONTRACT_HREF: 'contractHref',
  CONTRACT_ID: 'contractId',
  DATE: 'date',
  EXPIRATION_PHASE: 'expirationPhase',
  EXPIRATION_TEXT: 'expirationText',
  IS_ETLA_OR_ALLOCATION: 'isEtlaOrAllocation',
  PRODUCTS: 'products',
  STATUS: 'status',
  TAGS: 'tags',
  TYPE: 'type',
};

export {CONTRACT_TABLE_DATA_KEYS};
