(function () {
  /**
   * @ngdoc module
   * @name app.widgets.user-groups
   * @description module that defines user-groups widgets
   */
  angular.module('app.widgets.user-groups', [
    'app.core.analytics',
    'app.core.organizations.organization-manager',
    'app.core.product.configurations',
    'app.core.user-group.admin-list',
    'app.core.user-group.configurations-list',
    'app.core.user-group.user-list',
    'app.widgets.admins',
    'app.widgets.products',
    'app.widgets.user-groups.detail-section',
    'app.widgets.users',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.shell.panels.modal',
    'binky.widgets.common.autocomplete',
    'binky.widgets.common.avatar',
    'binky.widgets.common.display-message',
    'binky.widgets.common.pagination-control-bar',
  ]);
})();
