(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilOrganizationsInvitations
   * @description defines service to manage jil contracts invitations
   */
  angular
    .module('app.core.api.jil')
    .provider('jilOrganizationsContractInvitations', jilOrganizationsContractInvitationsProvider);

  /* @ngInject */
  function jilOrganizationsContractInvitationsProvider() {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        changeOwnerInvitation: getchangeOwnerInvitationResource(),
        revokeInvitation: getRevokeInvitationResource(),
      };

      return service;

      ///////////

      function getchangeOwnerInvitationResource() {
        // https://wiki.corp.adobe.com/display/BPS/Send+Organization+Contract+Invitation
        return $resource(`${url}/:orgId/contracts/:contractId/invitations`, {
          contractId: '@contractId',
          orgId: apiUtils.getActiveOrg,
        });
      }

      function getRevokeInvitationResource() {
        return $resource(`${url}/:orgId/contracts/:contractId/invitations/:invitationCode`, {
          contractId: '@contractId',
          invitationCode: '@invitationCode',
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
