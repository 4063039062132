(function () {
  /**
   * @ngdoc module
   * @name app.core.organizations
   * @description defines organization and organization list models
   */
  angular.module('app.core.organizations', [
    'app.core.model-cache',
    'app.core.organizations.user',
    'app.core.product.configurations',
    'app.core.src2-migration',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.core.src2-migration',
    'binky.core.storage-utils',
    'ngResource',
  ]);
})();
