import {UserGroupList} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canViewValueDiscoveryTasksForEnterprise} from 'core/organizations/access/organizationAccess';
import auth from 'core/services/auth';
import {goToUserGroups} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * @description Function to determine if the organization has user groups assigned
 *
 * @returns {Promise<Boolean>} whether the user group list has user groups
 */
const hasUserGroups = async () => {
  const userGroupList = await UserGroupList.get({
    orgId: rootStore.organizationStore.activeOrgId,
  });
  return userGroupList?.items?.length > 0;
};

/**
 * Class to create HVA card allowing user to add user groups
 */
class CreateUserGroupsHva extends HvaCard {
  /**
   * @description Method to fetch the CreateUserGroupsHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<CreateUserGroupsHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (
      !auth.isUserOrgAdmin() ||
      !canViewValueDiscoveryTasksForEnterprise() ||
      (await hasUserGroups())
    ) {
      return Promise.reject();
    }
    return Promise.resolve([new CreateUserGroupsHva({intl})]);
  }

  /**
   * @description Creates a new CreateUserGroupsHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {CreateUserGroupsHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.createUserGroups';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_pop_artt_user_groups',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/user-groups.svg',
      },
      id: 'hva-create-user-groups',
      isDismissible: true,
      onCTA: () => goToUserGroups(),
      priorityOrder: HVA_ORDER.CREATE_USER_GROUPS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default CreateUserGroupsHva;
