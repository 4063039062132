/**
 * @deprecated should use the React Spectrum RangeSlider
 *
 * @ngdoc component
 * @name app.widgets.common:appRangedSlider
 *
 * @description Wrapper for Coral ranged slider
 * @param {Boolean} [disabled] boolean for disabling the slider
 * @param {Number} [endValue] the ending value of the slider
 * @param {Boolean} [invalid] boolean for the slider invalid state
 * @param {String} [invalidTooltipMessage] the tooltip message shown when the slider is not in a valid state
 * @param {String} [labelId] A space delimited set of ids for the HTML elements that provide a label for the formField
 * @param {Array} [labels] array of labels to be displayed for the notches
 * @param {Number} [max] the maximum value of the slider that can be slided to
 * @param {Number} [min] the minimum value of the slider, default to 0
 * @param {Number} [notchStep] the rate of change for displaying the notches under the slider,  default to 1
 * @param {Function} [onChange] callback when slider moves. The parameters are 'startValue' and 'endValue'
 * @param {Number} [startValue] the starting value of the slider
 * @param {Number} [step] the rate of change when slider being slided,  default to 1
 */
(function () {
  angular.module('app.widgets.ranged-slider').component('appRangedSlider', {
    bindings: {
      disabled: '@?',
      endValue: '<?',
      invalid: '<?',
      invalidTooltipMessage: '@?',
      labelId: '@?',
      labels: '<?',
      max: '<',
      min: '<?',
      notchStep: '<?',
      onChange: '&?',
      startValue: '<?',
      step: '<?',
    },
    controller,
    templateUrl: 'app/widgets/common/ranged-slider/ranged-slider.component.html',
  });

  function controller(_, $element, $timeout, $scope, RANGED_SLIDER_CHANGE) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
    });

    function $onChanges() {
      if (vm.rangedSlider) {
        updateBindings();
      }
    }

    function $onInit() {
      vm.rangedSlider = new Coral.RangedSlider();
      vm.min = _.isInteger(vm.min) ? vm.min : 0;
      updateBindings();

      $scope.$on(RANGED_SLIDER_CHANGE, onStateChange);

      vm.rangedSlider.on('change', () => {
        // Timeout in order to trigger an Angular digest cycle.
        $timeout(() => {
          if (vm.onChange) {
            vm.onChange({
              endValue: vm.rangedSlider.endValue,
              startValue: vm.rangedSlider.startValue,
            });
          }
        });
      });

      Coral.commons.ready(() => {
        // append the ranged slider
        $element.find('div').eq(0).append(vm.rangedSlider);
        // Create notches
        createNotches();
      });
    }

    //////////////

    function createNotches() {
      // Create notches to be displayed under the ranged slider along with labels under each notch.
      const notchLabelsContainer = $element[0].querySelector('.notch-labels');
      const notchLabelsContainerEl = angular.element(notchLabelsContainer);
      const notchesContainer = $element[0].querySelector('.notches');
      const notchesContainerEl = angular.element(notchesContainer);
      // Add notches based on the specified interval
      _.times(vm.max - vm.min, (i) => {
        const label = _.get(vm, `labels[${i}]`, vm.min + i);
        if (i === vm.max - vm.min - 1) {
          notchLabelsContainerEl.append(`<li>${vm.labels[i + 1]}</li>`);
        } else if (i % vm.notchStep === 0) {
          notchesContainerEl.append('<div></div>');
          notchLabelsContainerEl.append(`<li>${label}</li>`);
        }
      });
    }

    function onStateChange(event, isStateInvalid) {
      // If the slider is "invalid", update slider state to "invalid" and indicate that visually.
      if (vm.invalid !== isStateInvalid) {
        vm.invalid = isStateInvalid;
        vm.rangedSlider.invalid = isStateInvalid;
        $element.toggleClass('invalid', isStateInvalid);
        updateSelectionRangeLabel();
      }
    }

    function updateBindings() {
      _.assign(vm.rangedSlider, {
        disabled: vm.disabled,
        endValue: vm.endValue,
        invalid: vm.invalid,
        invalidTooltipMessage: vm.invalidTooltipMessage,
        labelledBy: vm.labelId,
        labels: vm.labels,
        max: vm.max,
        min: vm.min,
        notchStep: _.isInteger(vm.notchStep) ? vm.notchStep : 1,
        startValue: vm.startValue,
        step: _.isInteger(vm.step) ? vm.step : 1,
      });
      updateSelectionRangeLabel();
    }

    function updateSelectionRangeLabel() {
      if (vm.labels && !vm.invalid) {
        const leftSelectionIdx = vm.startValue - vm.min;
        const rightSelectionIdx = vm.endValue - vm.min;
        vm.rangeLabel = `${vm.labels[leftSelectionIdx]} - ${vm.labels[rightSelectionIdx]}`;
      }
    }
  }
})();
