import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {ERROR_STATES} from 'features/settings/components/domains/transfer-domain-modal/transferDomainModalConstants';

const TransferDomainErrorModal = ({count, error}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  return (
    <ModalAlertDialog
      onCancel={() => dialog.dismiss()}
      primaryActionLabel=""
      title={intl.formatMessage({id: 'settings.transferDomainModal.error.title'}, {count})}
    >
      <FormattedMessage
        id={
          error === ERROR_STATES.NO_ELIGIBLE_DIRECTORIES
            ? 'settings.transferDomainModal.error.description.noEligibleDirectories'
            : 'settings.transferDomainModal.error.description.noEligibleDomains'
        }
        values={{
          count,
        }}
      />
    </ModalAlertDialog>
  );
};

TransferDomainErrorModal.propTypes = {
  count: PropTypes.number.isRequired,
  error: PropTypes.oneOf(Object.values(ERROR_STATES)).isRequired,
};
export default TransferDomainErrorModal;
