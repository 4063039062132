(function () {
  /**
   * @ngdoc module
   * @name app.widgets.common
   * @description module that defines widgets that can be used multiple places
   */
  angular.module('app.widgets.link-card', [
    'binky.widgets.common.go-url',
    'pascalprecht.translate',
  ]);
})();
