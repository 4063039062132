(function () {
  /**
   * @ngdoc module
   * @name app.widgets.users.user-autocomplete
   * @description module that defines user autocomplete widgets
   */
  angular.module('app.widgets.users.user-autocomplete', [
    'app.core.organizations.organization-manager',
    'app.core.search.user-list',
    'app.core.user',
    'app.shell',
    'binky.core.search.available-type-list',
    'binky.widgets.common.events',
    'binky.widgets.common.member.member-name',
    'pascalprecht.translate',
  ]);
})();
