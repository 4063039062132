import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useEffect, useState} from 'react';

/**
 *
 * @description
 * Adapter hook for managing page state for TableSection and token-based APIs / list states.
 *
 * TableSection accepts a "totalPages" and "pageNumber" prop which it uses to manage the internal
 * disabled state of the "previous" and "next" buttons. Since token-based APIs (e.g. AlphaListState)
 * do not support an x-current-page/x-page-count HTTP header and TableSection has no native support
 * for token-based APIs, clients must "hack" TableSection into working by managing their
 * own page state and incrementing their totalPages when the x-next-page HTTP header signals
 * the existence of a next page.
 *
 * IMPORTANT: Any breaking changes to AlphaListState's paging will require this hook to be updated.
 *
 * @param {ListState} listState - The list state. e.g. AlphaListState.
 * @returns {Number} hook.currentPage - The current page. Pass into TableSection.
 * @returns {Function} hook.onTableSectionChangePageReducer - The function to be called via onTableSectionChange
 * @returns {Number} hook.totalPages - The simulated / "fake" totalPages. Pass into TableSection.
 */
const useTableSectionPageCounter = ({listState}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const resetPaging = () => {
    setCurrentPage(1);
    setTotalPages(1);
  };

  const onResetPaging = useCallback(() => {
    resetPaging();
  }, []);

  const onTableSectionChangePageReducer = useCallback(({action}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
      case TABLE_SECTION_ACTIONS.ON_SORT_BY:
        resetPaging();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        setCurrentPage((curr) => curr + 1);
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        setCurrentPage((curr) => curr - 1);
        break;
      default:
        // Plenty of valid TABLE_SECTION_ACTIONS will come through; no need to log or do anything.
        break;
    }
  }, []);

  // Increments totalPages when there is a next page.
  useEffect(
    () => {
      if (listState?.hasNextPage() && currentPage === totalPages) {
        setTotalPages(totalPages + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only when nextPage (the next page token) exists
    [listState?.nextPage]
  );

  return {currentPage, onResetPaging, onTableSectionChangePageReducer, totalPages};
};

export default useTableSectionPageCounter;
