import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const SteppedViewHeader = ({children, ...styleProps}) => <View {...styleProps}>{children}</View>;

SteppedViewHeader.propTypes = {
  children: PropTypes.node,
};

export default SteppedViewHeader;
