(function () {
  /**
   * @ngdoc module
   * @name app.core.user-group.admin-list
   * @description module that defines the UserGroupAdminList model
   */
  angular.module('app.core.user-group.admin-list', [
    'app.core',
    'app.core.api.jil',
    'app.core.organizations',
    'binky.core.common.member',
    'binky.core.lodash',
  ]);
})();
