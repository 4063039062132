import {Store} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import jilDnsToken from 'common/api/jil/jilDnsToken';
import {DOMAIN_STATUSES} from 'common/entities/DomainEntity';
import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import jilDirectoryDomains, {DOMAIN_CHECK_ERRORS} from 'features/settings/api/jilDirectoryDomains';

const STEPS = {
  ADD_DOMAINS: Symbol('ADD_DOMAINS'),
  REVIEW_DOMAINS: Symbol('REVIEW_DOMAINS'),
};

const ERROR_CODES = {
  DOMAIN_ALREADY_CLAIMED: Symbol('DOMAIN_ALREADY_CLAIMED'),
  DOMAIN_NAME_INVALID: Symbol('DOMAIN_NAME_INVALID'),
  DOMAIN_NOT_LINKED: Symbol('DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG'),
  DOMAIN_RESERVED: Symbol('DOMAIN_RESERVED'),
  ORG_PENDING_MIGRATION: Symbol('ORG_PENDING_MIGRATION'),
  ORGS_IN_DIFFERENT_HIERARCHY: Symbol('ORGS_IN_DIFFERENT_HIERARCHY'),
  UNKNOWN: Symbol('UNKNOWN'),
};

class AddDomainsByDnsStore extends Store {
  domains = [];
  domainsValue = [];
  isDomainValidating = false;
  step = STEPS.ADD_DOMAINS;

  token;

  constructor({directoryId}) {
    super();

    makeObservable(this, {
      canAddDomains: computed,
      checkDomains: action.bound,
      claimDomains: action.bound,
      domains: observable,
      domainsValue: observable,
      getValidationToken: action.bound,
      goToStep: action.bound,
      isDomainValidating: observable,
      removeDomain: action.bound,
      selectedStepIndex: computed,
      setDomainsValue: action.bound,
      step: observable,
      token: observable,
      validateDomain: action.bound,
    });

    this.directoryId = directoryId;
  }

  get canAddDomains() {
    return (
      this.domains.length > 0 &&
      this.domains.filter((domain) => domain.isAvailable).length === this.domains.length
    );
  }

  async checkDomains() {
    this.isLoading = true;

    try {
      const response = await jilDirectoryDomains.previewDomains({
        domains: this.domainsValue,
        orgId: AppConstants.orgId,
      });

      runInAction(() => {
        this.domains = mapResponse(response);
        this.goToStep(STEPS.REVIEW_DOMAINS);
      });
    } catch (error) {
      // TODO: Remove `await` once binky upgraded past 3.6.8
      await this.fetchError(error);
    } finally {
      this.fetchDone();
    }
  }

  async claimDomains() {
    this.isLoading = true;

    try {
      await jilDirectoryDomains.claimDomains({
        directoryId: this.directoryId,
        domains: this.domains.map((domain) => domain.domainName),
        orgId: AppConstants.orgId,
      });
    } catch (error) {
      // TODO: Remove `await` once binky upgraded past 3.6.8
      await this.fetchError(error);
    } finally {
      this.fetchDone();
    }
  }

  async getValidationToken() {
    this.isLoading = true;

    const response = await jilDnsToken.getToken(rootStore.organizationStore.activeOrgId);

    runInAction(() => {
      this.token = response.data.token;
      this.isLoading = false;
    });
  }

  goToStep(step) {
    this.step = step;
  }

  removeDomain(domainName) {
    this.domains = this.domains.filter((domain) => domain.domainName !== domainName);
  }

  get selectedStepIndex() {
    return Object.values(STEPS).indexOf(this.step);
  }

  setDomainsValue(domains) {
    this.domainsValue = [...new Set(domains)]; // Using a set to make entries unique;
  }

  async validateDomain(domainName) {
    this.isDomainValidating = true;

    try {
      const response = await jilDirectoryDomains.setDomainStatus({
        domainName,
        orgId: rootStore.organizationStore.activeOrgId,
        status: DOMAIN_STATUSES.ACTIVE,
      });

      const data = response.data;

      if (data.length === 0) {
        throw new Error('Could not validate domain');
      }

      const isDomainClaimed = data.find((domain) => domain.domainName === domainName);

      if (!isDomainClaimed) {
        throw new Error('Could not validate domain');
      }
    } finally {
      runInAction(() => {
        this.isDomainValidating = false;
      });
    }
  }
}

function mapResponse(response) {
  return response.data.map((domain) => ({
    domainName: domain.id,
    errorCode: mapError(domain.response.errorCode),
    isAvailable: !domain.response.errorCode,
  }));
}

// eslint-disable-next-line complexity -- necessary to handle all error codes
function mapError(errorCode) {
  switch (errorCode) {
    case DOMAIN_CHECK_ERRORS.DOMAIN_ALREADY_CLAIMED_ACTIVE:
    case DOMAIN_CHECK_ERRORS.DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN:
    case DOMAIN_CHECK_ERRORS.DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE:
    case DOMAIN_CHECK_ERRORS.ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST:
      return ERROR_CODES.DOMAIN_ALREADY_CLAIMED;
    case DOMAIN_CHECK_ERRORS.DOMAIN_NAME_INVALID:
      return ERROR_CODES.DOMAIN_NAME_INVALID;
    case DOMAIN_CHECK_ERRORS.DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG:
      return ERROR_CODES.DOMAIN_NOT_LINKED;
    case DOMAIN_CHECK_ERRORS.DOMAIN_RESERVED:
      return ERROR_CODES.DOMAIN_RESERVED;
    case DOMAIN_CHECK_ERRORS.ORG_PENDING_ESM_MIGRATION:
    case DOMAIN_CHECK_ERRORS.ORG_PENDING_T2E_MIGRATION:
      return ERROR_CODES.ORG_PENDING_MIGRATION;
    case DOMAIN_CHECK_ERRORS.ORGS_DO_NOT_SHARE_GTM:
      return ERROR_CODES.ORGS_IN_DIFFERENT_HIERARCHY;
    default:
      return ERROR_CODES.UNKNOWN;
  }
}

export {ERROR_CODES, STEPS};
export default AddDomainsByDnsStore;
