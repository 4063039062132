import {LICENSE_QUANTITY_STATUS} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Link} from '@adobe/react-spectrum';
import React from 'react';

import rootStore from 'core/RootStore';
import ProductPaymentStatusUserList from 'core/products/payment-status-users/ProductPaymentStatusUserList';

import {PAYMENT_STATUS_TYPE} from './paymentStatusDrawerConstants';

const getNeedPaymentMessageArgs = async ({contract, product}) => {
  let key;

  const users = await ProductPaymentStatusUserList.getNeedPaymentUsers({
    orgId: rootStore.organizationStore.activeOrgId,
    pageSize: 10,
    productId: product.id,
  });
  if (users.items.length === 0) {
    key = 'noUser';
  } else {
    key = contract.isInRenewalWindow() ? 'hasOverdueUser' : 'hasUser';
  }

  const endDate =
    product.licenseTupleList.getClosestEndDateForStatus?.(
      LICENSE_QUANTITY_STATUS.PENDING_PAYMENT
    ) ?? null;
  return {
    id: `${contract.isVIPMPContract() ? 'messageVIPMP' : 'message'}.${key}`,
    values: {
      endDate,
      hasEndDate: !!endDate,
      needPaymentLicensesQuantity: product.licenseTupleList.getNeedPaymentQuantity(),
      renewalWindowEndDate: contract.getRenewalWindowEndDate(),
      resellerName: contract.isVIPMPContract() ? undefined : contract.getResellerName(),
    },
  };
};

const getImpactedUsersMessageArgs = ({contract}) => {
  let paymentKey;
  if (contract.isPaymentCategoryOffline() || contract.isPaymentCategoryPO()) {
    paymentKey = 'offlinePurchaseOrder';
  } else if (contract.isPaymentCategoryDigitalRiver()) {
    paymentKey = 'digitalRiver';
  } else {
    paymentKey = 'creditCardPaypal';
  }
  return {
    id: `message.${paymentKey}`,
    values: {
      GoUrl: (str) => <GoUrl name="CCMTeamSupport">{str}</GoUrl>,
      MailTo: (address) => (
        <Link>
          <a href={`mailto:${address}`}>{address}</a>
        </Link>
      ),
    },
  };
};

const getNeedRenewalMessageArgs = ({contract}) => ({
  id: contract.isVIPMPContract() ? 'messageVIPMP' : 'message',
  values: {resellerName: contract.isVIPMPContract() ? undefined : contract.getResellerName()},
});

/**
 * @description Get message args for payment status drawer
 * @param {Contract} contract - the contract for the product
 * @param {String} paymentStatusType - One of PAYMENT_STATUS_TYPE
 * @param {Product} product - the product for the drawer
 * @returns {Promise} resolves to messageArgs object {id, values}
 * @throws {Error} Invalid paymentStatusType
 */
const getMessageArgs = ({contract, paymentStatusType, product}) => {
  switch (paymentStatusType) {
    case PAYMENT_STATUS_TYPE.NEED_PAYMENT:
      return getNeedPaymentMessageArgs({contract, product});
    case PAYMENT_STATUS_TYPE.NEED_RENEWAL:
      return getNeedRenewalMessageArgs({contract});
    case PAYMENT_STATUS_TYPE.IMPACTED_USERS:
      return getImpactedUsersMessageArgs({contract});
    default:
      throw new Error(`Invalid paymentStatusType: ${paymentStatusType}`);
  }
};

const getUserQuery = ({isGracePastDue, paymentStatusType}) => {
  switch (paymentStatusType) {
    case PAYMENT_STATUS_TYPE.NEED_PAYMENT:
      return ProductPaymentStatusUserList.getNeedPaymentUsers;
    case PAYMENT_STATUS_TYPE.NEED_RENEWAL:
      return ProductPaymentStatusUserList.getNeedRenewalUsers;
    case PAYMENT_STATUS_TYPE.IMPACTED_USERS:
      return isGracePastDue
        ? ProductPaymentStatusUserList.getUsersAtRisk
        : ProductPaymentStatusUserList.getUsersWithNoAccess;
    default:
      throw new Error(`Invalid paymentStatusType: ${paymentStatusType}`);
  }
};

/**
 * @description Get users for payment status drawer
 * @param {Boolean} isGracePastDue - true if grace period is past due
 * @param {Number} pageNumber - the (1 based) page number of user list
 * @param {String} paymentStatusType - One of PAYMENT_STATUS_TYPE
 * @param {String} productId - id of product the drawer is opened for
 * @returns {Promise} resolves to user list
 * @throws {Error} Invalid paymentStatusType
 */
const getUsers = ({isGracePastDue, pageNumber, paymentStatusType, productId}) =>
  getUserQuery({isGracePastDue, paymentStatusType}).bind(ProductPaymentStatusUserList)({
    orgId: rootStore.organizationStore.activeOrgId,
    pageNumber,
    pageSize: 10,
    productId,
  });

/**
 * @description Export drawer users as CSV data
 * @param {String} paymentStatusType - One of PAYMENT_STATUS_TYPE
 * @param {String} productId - id of product the drawer is opened for
 * @returns {Promise} resolves to csv data export of user list
 * @throws {Error} Invalid paymentStatusType
 */
const getUsersCSV = ({paymentStatusType, productId}) => {
  switch (paymentStatusType) {
    case PAYMENT_STATUS_TYPE.NEED_PAYMENT:
      return ProductPaymentStatusUserList.getNeedPaymentUsersCSV({
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
      });
    case PAYMENT_STATUS_TYPE.NEED_RENEWAL:
      return ProductPaymentStatusUserList.getNeedRenewalUsersCSV({
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
      });
    default:
      throw new Error('Invalid paymentStatusType: ', paymentStatusType);
  }
};

export {getMessageArgs, getUsers, getUsersCSV};
