import {Flex, Item, Picker} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const AssetCredentialsSetting = ({CASettings, content, isDisabled, onSelection}) => {
  const intl = useIntl();
  const {titleKey, descriptionKey, apiKey, options} = content;

  return (
    <Flex direction="column" gap="size-100" height="100%">
      <TitledSection
        dividerPlacement={DividerPlacement.NONE}
        headingLevel={4}
        ruleVariant="S"
        title={intl.formatMessage({id: titleKey})}
      />
      <FormattedMessage id={descriptionKey} />
      <Picker
        aria-label={intl.formatMessage({id: titleKey})}
        isDisabled={isDisabled}
        items={options}
        marginBottom="size-250"
        onSelectionChange={(selection) => {
          onSelection(apiKey, selection);
        }}
        selectedKey={CASettings[apiKey] ? 'TRUE' : 'FALSE'}
        width="size-3000"
      >
        {(item) => <Item key={item.value}>{intl.formatMessage({id: item.textKey})}</Item>}
      </Picker>
    </Flex>
  );
};

AssetCredentialsSetting.propTypes = {
  /**
   * The content authenticity settings used to set the selected key.
   */
  CASettings: PropTypes.shape(PropTypes.objectOf(PropTypes.bool.isRequired).isRequired),
  /**
   * The data and copy needed for each setting.
   */
  content: PropTypes.shape({
    /**
     * The identifier key used in the api call.
     */
    apiKey: PropTypes.string.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    /**
     * The dropdown options for the setting. Each option has a localization key and an identifier value.
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        textKey: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    /**
     * The localization key for the title of the setting.
     */
    titleKey: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Whether the setting is disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * The function handler when an option is selected.
   */
  onSelection: PropTypes.func.isRequired,
};

export default AssetCredentialsSetting;
