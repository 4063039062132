import {Carousel} from '@pandora/react-carousel';
import classname from 'classnames';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import {
  CAROUSEL_TYPE_TO_EVENT_NAME,
  STEP_TO_CAROUSEL_NAV_EVENT_NAME,
} from '../../SelfCancelAnalyticsUtils';
import {useSelfCancelAnalyticsContext} from '../self-cancel-analytics-context/SelfCancelAnalyticsContext';

import './SelfCancelCarousel.pcss';

const WAIT_UNTIL_PANDORA_CAROUSEL_HEIGHT_FINISHED = 600;

const CAROUSEL_OPTIONS = {
  cardMappings: {
    L: {
      breakpointL: {
        cardsPerRow: 3,
        gutter: 1,
      },
      breakpointLM: {
        cardsPerRow: 3,
        gutter: 1,
      },
      breakpointM: {
        cardsPerRow: 3,
        gutter: 1,
      },
      breakpointMS: {
        cardsPerRow: 2,
        gutter: 1,
      },
      breakpointS: {
        cardsPerRow: 2,
        gutter: 1,
      },
      breakpointSS: {
        cardsPerRow: 1,
        gutter: 1,
      },
      breakpointXS: {
        cardsPerRow: 1,
        gutter: 1,
      },
    },
  },
  cardSize: 'L',
  showPagerDot: true,
};

/**
 * Self Cancel Carousel component wrapping Pandora Carousel to apply 100% height
 */
const SelfCancelCarousel = ({
  cards,
  Component,
  onCarouselArrowClick,
  options = CAROUSEL_OPTIONS,
  step,
  ...props
}) => {
  const {dispatchSelfCancelUiEventAnalytics} = useSelfCancelAnalyticsContext();
  const [isLoaded, setIsLoaded] = useState(false);

  // Defer updating loading more than 500ms, until Pandora Carousel finishes updating heights
  // See https://git.corp.adobe.com/PandoraUI/commerce/blob/master/packages/react-carousel/src/js/Carousel.tsx
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, WAIT_UNTIL_PANDORA_CAROUSEL_HEIGHT_FINISHED);

    // Clear timeout when component unmount
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onCarouselArrowPress = useCallback(
    ({type}) => {
      const buttonLabel = CAROUSEL_TYPE_TO_EVENT_NAME[type];
      const navigationLabel = STEP_TO_CAROUSEL_NAV_EVENT_NAME[step];

      if (buttonLabel && navigationLabel) {
        dispatchSelfCancelUiEventAnalytics({
          eventName: `${navigationLabel}${buttonLabel}`,
        });
      }
      onCarouselArrowClick?.();
    },

    [dispatchSelfCancelUiEventAnalytics, step, onCarouselArrowClick]
  );

  const styleName = classname('self-cancel-carousel', {loaded: isLoaded});

  return (
    <div data-testid="self-cancel-carousel" styleName={styleName}>
      <Carousel
        {...props}
        data={cards}
        onCarouselArrowClick={onCarouselArrowPress}
        options={options}
        react_function={Component}
      />
    </div>
  );
};

SelfCancelCarousel.propTypes = {
  /**
   * The cards to render inside the Carousel, defined by its type.
   */
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * The Component that renders each type of card.
   */
  Component: PropTypes.func.isRequired,
  /**
   * A function that allows for extra behaviour to run after the dispatch analytics calls.
   */
  onCarouselArrowClick: PropTypes.func,
  /**
   * The Options passed to Pandora Carousel. Defaults to custom CAROUSEL_OPTIONS
   */
  options: PropTypes.shape({
    cardSize: PropTypes.string,
  }),
  /**
   * Current step in the flow where the carousel is rendered
   */
  step: PropTypes.string.isRequired,
};

export default SelfCancelCarousel;
