import help from '../../../../constants/help';

const {LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 * @description Method to return configuration object for the manage SDL
 *   help topic
 * @returns content for manage SDL help topic
 */
const sdlFaq = () => ({
  content: [
    {
      links: [
        {
          key: 'help.topics.sdlFaq.link.one.key',
          locator: 'aac_sdl_faq',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.sdlFaq.text',
    },
  ],
  id: TOPICS.SDL_FAQ,
  order: 1,
  ordered: false,
  parents: [
    {
      id: TOPICS.MANAGE_SDL,
    },
  ],
  title: 'help.topics.sdlFaq.title',
});

export default sdlFaq;
