import {useEffect, useState} from 'react';

import TableConstants from './TableConstants';

/**
 * @awaitingdeprecation - import Pagination from binky-ui
 */
const usePagination = (options = {}) => {
  const [currentPage, setCurrentPage] = useState(options.currentPage || TableConstants.FIRST_PAGE);
  const [hasNextToken, setHasNextToken] = useState(options.hasNextToken);
  const [hasPreviousToken, setHasPreviousToken] = useState(options.hasPreviousToken);
  const [pageSize, setPageSize] = useState(
    options.defaultPageSize || TableConstants.DEFAULT_PAGE_SIZE
  );
  const [totalItems, setTotalItems] = useState(options.totalItems);

  const resetPaging = () => {
    setCurrentPage(TableConstants.FIRST_PAGE);
  };

  // Reset paging when pageSize changes
  useEffect(() => {
    resetPaging();
  }, [pageSize]);

  return {
    currentPage,
    hasNextToken,
    hasPreviousToken,
    pageSize,
    resetPaging,
    setCurrentPage,
    setHasNextToken,
    setHasPreviousToken,
    setPageSize,
    setTotalItems,
    totalPages: totalItems ? Math.ceil(totalItems / pageSize) : undefined,
  };
};

export default usePagination;
