import {constructAsyncAddAssignUserAnalytics, download} from '@admin-tribe/binky';
import {showError, showInfo} from '@admin-tribe/binky-ui';
import {ActionMenu, Item, Section, Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import BulkOperationModal from 'common/components/bulk-operation/bulk-operation-modal/BulkOperationModal';
import {BULK_OPERATION_MODE} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import {
  CSV_MAX_ATTEMPTS_ERROR,
  OPERATIONS,
} from 'common/entities/user-operations/UserOperationsConstants';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';

import {getMenuItems, triggerAnalytics} from './userOperationsMenuUtils';

// The More options menu for Users page and the UserGroupUsers, ProfileUsers and ProductUsers subpages.
// The menu can have up to 3 setions: bulk operations, exports operations and result operations.
const UserOperationsMenu = observer(() => {
  const {pageContext, userOperations} = useMemberListContext();
  const [disabledKeys, setDisabledKeys] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState();
  const menuItems = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const disableMenuItem = useCallback(
    (key) => {
      disabledKeys.add(key);
      setDisabledKeys(new Set(disabledKeys));
    },
    [disabledKeys]
  );

  const enableMenuItem = useCallback(
    (key) => {
      disabledKeys.delete(key);
      setDisabledKeys(new Set(disabledKeys));
    },
    [disabledKeys]
  );

  const doExportOperation = useCallback(
    async ({analyticsKey, exportCallback, key}) => {
      // Disable menu item while operation in progress.
      disableMenuItem(key);

      showInfo(
        intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationForm.csvProcessing.alert.info',
        })
      );

      try {
        await exportCallback();
      } catch {
        showError(
          intl.formatMessage({
            id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.children',
          })
        );
      } finally {
        triggerAnalytics({analyticsKey, pageContext, userOperations});

        // Re-enable menu item now that operation is complete.
        enableMenuItem(key);
      }
    },
    [disableMenuItem, enableMenuItem, intl, pageContext, userOperations]
  );

  const downloadCSV = useCallback(
    async ({analyticsKey, exportFunction, filename, key}) => {
      const exportCallback = async () => {
        const response = await exportFunction({
          orgId,
        });
        download(response?.data?.file, filename);
      };

      await doExportOperation({analyticsKey, exportCallback, key});
    },
    [doExportOperation, orgId]
  );

  const exportUsers = useCallback(
    async (key) => {
      const {analyticsKey, exportFunction, exportParams} =
        userOperations.getDownloadReportConfig(key);

      const onError = (error) => {
        if (error?.message === CSV_MAX_ATTEMPTS_ERROR) {
          // So there is time to read toast, require admin to dismiss it.
          showInfo(
            intl.formatMessage({
              id: 'common.bulkOperation.bulkOperationForm.csvAsyncProcessing.alert.info',
            }),
            {
              timeout: 0,
            }
          );
        } else {
          showError(
            intl.formatMessage({
              id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.children',
            })
          );
        }
      };

      const exportCallback = async () => {
        await userOperations.exportUsersFunction({
          exportFunction,
          exportParams,
          onError,
          orgId,
        });
      };

      await doExportOperation({analyticsKey, exportCallback, key});
    },
    [doExportOperation, intl, orgId, userOperations]
  );

  const onItemSelected = useCallback(
    (key) => {
      if (Object.values(BULK_OPERATION_MODE).includes(key)) {
        setMode(key);
        setIsModalOpen(true);
        return;
      }

      if (key === OPERATIONS.EXPORT_USERS) {
        exportUsers(key);
        return;
      }

      if (key === OPERATIONS.OPERATION_RESULTS) {
        userOperations.viewResultsFunction?.();
        return;
      }

      const reportParams = userOperations.getDownloadReportConfig(key);
      if (reportParams) {
        downloadCSV({key, ...reportParams});
        return;
      }

      throw new Error(`OperationsMenu: unhandled selection ${key}`);
    },
    [downloadCSV, exportUsers, userOperations]
  );

  const onModalClose = () => {
    setIsModalOpen(false);
    setMode();

    userOperations.onBulkOperationClose?.();
  };

  // Check on keys whose state can change.
  const onOpenChange = (isOpen) => {
    if (isOpen) {
      if (userOperations.canAdd) {
        if (userOperations.canAddDisabled) {
          disableMenuItem(OPERATIONS.ADD);
        } else {
          enableMenuItem(OPERATIONS.ADD);
        }
      }
    }
  };

  // Setup the menu whenever userOperations changes.
  useEffect(() => {
    if (userOperations) {
      menuItems.current = getMenuItems({intl, userOperations});

      // Show the Menu button if there are any items, even if they are all disabled.
      setShowButton(Object.keys(menuItems.current).length > 0);

      // If requested, open the modal.
      if (userOperations.onInitBulkOperationMode) {
        onItemSelected(userOperations.onInitBulkOperationMode);
      }
    }
  }, [intl, onItemSelected, userOperations]);

  return (
    showButton && (
      <>
        <ActionMenu
          align="end"
          data-testid="user-operations-menu"
          disabledKeys={disabledKeys}
          items={menuItems.current}
          marginStart="size-200"
          onAction={onItemSelected}
          onOpenChange={onOpenChange}
        >
          {(section) => (
            <Section aria-label={section['aria-label']} items={section.children}>
              {(item) => (
                <Item textValue={item.name}>
                  <Text data-testid={`menu-item-${item.key}`}>{item.name}</Text>
                </Item>
              )}
            </Section>
          )}
        </ActionMenu>
        {isModalOpen && (
          <BulkOperationModal
            analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
            isOpen={isModalOpen}
            mode={mode}
            onCancel={onModalClose}
            onSuccess={onModalClose}
            pageContext={pageContext}
          />
        )}
      </>
    )
  );
});

export default UserOperationsMenu;
