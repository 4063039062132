(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc service
   * @name app.core.analytics:AnalyticsHelper
   * @description cart helper factory
   */
  angular.module('app.core.analytics').factory('AnalyticsHelper', AnalyticsHelper);
  /* @ngInject */
  function AnalyticsHelper(
    _,
    AnalyticsEvent,
    AnalyticsPageDescriptor,
    binkySrc2,
    CartDescriptor,
    feature,
    ProductDescriptor,
    UiEventDescriptor
  ) {
    const service = {
      customerSegmentToAnalyticsName,
      dispatchAnalyticsClickEventWithProduct,
      dispatchAnalyticsModalCloseEvent,
      dispatchPageAnalytics,
      dispatchUiEventAnalytics,
    };

    const {OrganizationInfoDescriptor} = binkySrc2.services.analytics;

    return service;

    //////////////

    /**
     * Translate customer segment to what analytics requires.
     * @param {string} customerSegment one of CUSTOMER_SEGMENT
     *
     * @returns {string} Analytics name for the customer segment
     */
    function customerSegmentToAnalyticsName(customerSegment) {
      const CUSTOMER_SEGMENT_TO_ANALYTICS_NAME = {
        ENTERPRISE: 'Enterprise',
        TEAM: 'Team',
      };

      return CUSTOMER_SEGMENT_TO_ANALYTICS_NAME[customerSegment];
    }

    /**
     * Trigger UiEvent click event for a product.
     * @param {String} eventName Analytics event name
     * @param {Offer} offer The product offer
     */
    function dispatchAnalyticsClickEventWithProduct({eventName, offer} = {}) {
      AnalyticsEvent.dispatch(
        constructProductAnalytics({
          eventAction: 'click',
          eventName,
          offer,
        })
      );
    }

    /**
     * Trigger AnalyticsPage analytics.
     * @param {Object} options analytics information
     * @param {Cart} [options.cart] Cart information
     * @param {String} [options.cancellationReasonComment] User entered cancellation reason
     * @param {Array} [options.cancellationReasons] List of cancellation ID's
     * @param {Array} [options.cartItems] List of offers in user's cart
     * @param {Boolean} [options.cartHasSubmitted] Whether the cart has been submitted. Default is false.
     * @param {Contract} [options.contract] Contract for the current cart
     * @param {String} options.pageName Analytics page name
     * @param {Object} [options.total] Cart total summary
     * @param {Boolean} [options.useRenewalPricing] Renewal pricing. Default is false.
     */
    function dispatchPageAnalytics(options) {
      const {cart, eventAction, eventName, interaction, pageName, productList} = options;

      const analyticsData = {
        descriptors: {
          analyticsPage: new AnalyticsPageDescriptor({name: pageName}),
        },
      };

      if (cart) {
        analyticsData.descriptors.cart = constructCartDescriptor(options);
      }

      if (feature.isEnabled('temp_renewal_analytcs_promos') && productList) {
        const {constructProductListDescriptor} =
          binkySrc2.services.product.productListAnalyticsUtils;

        analyticsData.descriptors.productList = constructProductListDescriptor(productList);
      }

      if (feature.isEnabled('temp_renewal_analytcs_promos') && eventAction && eventName) {
        analyticsData.descriptors.uiEvent = new UiEventDescriptor({
          eventAction,
          eventName,
          interaction,
        });
      }

      AnalyticsEvent.dispatch(analyticsData);
    }

    /**
     * Trigger UiEvent analytics.
     * @param {Object} options analytics information
     * @param {Object} [options.attributes] Generic attribute object (DTM backward compatibility)
     * @param {Cart} [options.cart] Cart information
     * @param {String} [options.cancellationReasonComment] User entered cancellation reason
     * @param {Array} [options.cancellationReasons] List of cancellation ID's
     * @param {Array} [options.cartItems] List of offers in user's cart
     * @param {Boolean} [options.cartHasSubmitted] Whether the cart has been submitted. Default is false.
     * @param {String} [options.componentMethod] Component method (DTM backward compatibility)
     * @param {String} [options.componentMethodType] Component method type (DTM backward compatibility)
     * @param {String} [options.componentName] Component name (DTM backward compatibility)
     * @param {Contract} [options.contract] Contract for the current cart
     * @param {String} options.eventAction Analytics event action
     * @param {String} options.eventName Analytics event name
     * @param {Object} [options.organizationInfo] Additional organization info
     * @param {Object} [options.pageContext] Generic page context (DTM backward compatibility)
     * @param {Object} [options.total] Cart total summary
     * @param {Boolean} [options.useRenewalPricing] Renewal pricing. Default is false.
     */
    function dispatchUiEventAnalytics(options) {
      const {
        attributes,
        cart,
        componentMethod,
        componentMethodType,
        componentName,
        eventAction,
        eventName,
        organizationInfo,
        pageContext,
      } = options;

      const analyticsData = {
        attributes,
        componentMethod,
        componentMethodType,
        componentName,
        descriptors: {
          uiEvent: new UiEventDescriptor({eventAction, eventName}),
        },
        pageContext,
      };

      if (cart) {
        analyticsData.descriptors.cart = constructCartDescriptor(options);
      }

      if (organizationInfo) {
        analyticsData.descriptors.organizationInfo = new OrganizationInfoDescriptor(
          organizationInfo
        );
      }

      AnalyticsEvent.dispatch(analyticsData);
    }

    /**
     * @description Updates analytics for modal close events. Provides the
     *              page that the user will return to when the modal is closed
     *              and the action of the modal closing
     * @param {String} pageName the name of the page that the user
     *                 lands on when the modal is closed.
     *
     * @param {String} modalId the modal ID of the closing modal
     */
    function dispatchAnalyticsModalCloseEvent(pageName, modalId) {
      const camelCaseModalId = _.camelCase(modalId);
      AnalyticsEvent.dispatch({
        descriptors: {
          analyticsPage: new AnalyticsPageDescriptor({name: pageName}),
          uiEvent: new UiEventDescriptor({
            eventAction: 'close',
            eventName: camelCaseModalId,
            name: `close${_.upperFirst(camelCaseModalId)}`,
          }),
        },
      });
    }

    ////////

    function constructCartDescriptor({
      cancellationReasonComment,
      cancellationReasons,
      cart,
      cartHasSubmitted = false,
      cartItems,
      contract,
      total,
      useRenewalPricing = false,
    }) {
      const items = _.map(cartItems, (cartItem) => {
        const item = _.pick(cartItem, [
          'assignableLicenseCount', // Purchased licenses
          'customer_segment',
          'numberSelected',
          'offer_id',
          feature.isEnabled('temp_renewal_analytcs_promos') ? 'pricing.currency.code' : undefined,
          'productBucket',
          'provisionedQuantity', // Assigned licenses
        ]);

        const billingData = cart.getBillingDataForOffer({
          hasSubmitted: cartHasSubmitted,
          offerId: cartItem.offer_id,
          useRenewalPricing,
        });

        if (feature.isEnabled('temp_renewal_analytcs_promos')) {
          _.assign(item.pricing, _.get(billingData, 'sub_total'));
        } else {
          item.pricing = _.get(billingData, 'sub_total');
        }

        return item;
      });

      return new CartDescriptor({
        cancellationReasonComment,
        cancellationReasons,
        contract: {
          customerSegment: contract.customerSegment,
          id: contract.id,
          inRenewalWindow: contract.isInRenewalWindow(),
          isNFR: contract.isNFR(),
          model: contract.model,
          offsetFromAnniversaryDate: contract.getOffsetFromAnniversaryDate(),
          salesChannel: contract.salesChannel,
        },
        // cart id
        id: feature.isEnabled('temp_renewal_analytcs_promos') ? cart.cartId : undefined,
        items,
        purchaseAuthId: cart.getPurchaseAuthId(),
        total,
      });
    }

    function constructProductAnalytics({eventAction, eventName, offer}) {
      return {
        descriptors: {
          product: new ProductDescriptor(offer),
          uiEvent: new UiEventDescriptor({eventAction, eventName}),
        },
      };
    }
  }
})();
