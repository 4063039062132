import {AlertDialog, Button, Content, DialogTrigger, Flex, Text} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {isReadOnly} from 'core/organizations/access/organizationAccess';

/**
 *  Delete button for removing archived inactive user folders
 */
const PermanentlyDeleteButton = ({onCTA}) => {
  const intl = useIntl();
  const {tableSectionUtils} = useTableSectionContext();
  const selectedItemCount = tableSectionUtils.getSelectedItemCount();

  const onPrimaryAction = () => {
    onCTA(tableSectionUtils);
  };

  return (
    !isReadOnly() && (
      <DialogTrigger>
        <Button isDisabled={selectedItemCount === 0} variant="negative">
          {intl.formatMessage({
            id: 'storage.individualUserFolders.inactiveUsers.permanentDeleteModal.confirm',
          })}
        </Button>
        <AlertDialog
          cancelLabel={intl.formatMessage({
            id: 'common.modal.buttons.cancel',
          })}
          onPrimaryAction={onPrimaryAction}
          primaryActionLabel={intl.formatMessage({
            id: 'storage.individualUserFolders.inactiveUsers.permanentDeleteModal.confirm',
          })}
          title={intl.formatMessage({
            id: 'storage.individualUserFolders.inactiveUsers.permanentDeleteModal.heading',
          })}
          variant="destructive"
        >
          <Content>
            <Flex direction="column" gap="size-150" marginY="size-150">
              <Text>
                {intl.formatMessage(
                  {
                    id: 'storage.individualUserFolders.inactiveUsers.permanentDeleteModal.text',
                  },
                  {count: selectedItemCount}
                )}
              </Text>
              <Text>
                {intl.formatMessage(
                  {
                    id: 'storage.individualUserFolders.inactiveUsers.permanentDeleteModal.confirmText',
                  },
                  {count: selectedItemCount}
                )}
              </Text>
            </Flex>
          </Content>
        </AlertDialog>
      </DialogTrigger>
    )
  );
};

PermanentlyDeleteButton.propTypes = {
  /**
   * Callback to invoke when the delete button is pressed.
   */
  onCTA: PropTypes.func.isRequired,
};

export default PermanentlyDeleteButton;
