(function () {
  /**
   * @ngdoc module
   * @name app.widgets.organization-type-form
   * @description module that defines the organization type form widget
   */
  angular.module('app.widgets.organization-type-form', [
    'app.core.organizations.organization-manager',
    'binky.core.lodash',
    'binky.core.organizations.market-subsegments',
    'binky.widgets.common.feature',
    'binky.widgets.common.go-url',
  ]);
})();
