import {Button} from '@adobe/react-spectrum';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import HowToDeployModal from 'features/products/components/how-to-deploy-modal/HowToDeployModal';

/**
 * Renders the settings button for product profile page which opens AddProductProfileModal.
 */
const HowToDeployButton = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const openerButton = useRef(null);

  return (
    <>
      <Button
        ref={openerButton}
        data-testid="how-to-deploy-button"
        onPress={() => {
          setShowModal(true);
        }}
        variant="accent"
      >
        {intl.formatMessage({
          id: 'products.details.devices.howToDeploy',
        })}
      </Button>
      <HowToDeployModal
        isOpen={showModal}
        onCancel={() => {
          openerButton.current.focus();
          setShowModal(false);
        }}
      />
    </>
  );
};

export default HowToDeployButton;
