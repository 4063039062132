(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.directories').constant('AZURE_SCOPES', {
    EMAIL: 'email',
    OPENID: 'openid',
    PROFILE: 'profile',
  });
})();
