import {PRODUCT_CHANNELS, compareDates, feature} from '@admin-tribe/binky';
import {getProductDisplayName} from '@admin-tribe/binky-ui';

import RootStore from 'core/RootStore';

/**
 * Method to get all contracts associated with a given product
 *
 * @param {Product} product - The product
 * @param {ContractList} contractList - Contract list whose items include only
 *     ACTIVE or EXPIRED status contracts. INACTIVE should not be included.
 * @returns {Contract[]} - list of all contract which the product belongs to
 */
const getProductContracts = (product, contractList) => {
  let contracts;

  if (product && contractList.items.length > 0) {
    if (feature.isEnabled('temp_pa_6055_bug_35390')) {
      contracts = product.contractIds
        ?.map((contractId) => contractList.items?.find((contract) => contract.id === contractId))
        .filter(Boolean);
    } else {
      contracts = product.contractIds?.map((contractId) =>
        contractList.items?.find((contract) => contract.id === contractId)
      );
    }
    // if there are multiple contracts for a given a product, sort the
    // contracts by startDate in ascending order so that
    // the oldest contract would always appear first in the array
    if (contracts) {
      if (contracts.length > 1) {
        const sortContractsByStartDate = (a, b) => compareDates(a.getStartDate(), b.getStartDate());
        return contracts?.sort(sortContractsByStartDate);
      }
      return contracts;
    }
    return [];
  }
  return [];
};

/**
 * @description Group a list by the key returned by keyGetter.
 *
 * @param {Array} list - an array of items
 * @param {Function} keyGetter - a function with an item param which returns the
 *   "grouping" key
 * @returns {Map} map of group items. If there are no items in the list the Map
 *   will be empty.
 */
function groupBy(list, keyGetter) {
  const map = new Map();
  list?.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (collection) {
      collection.push(item);
    } else {
      map.set(key, [item]);
    }
  });
  return map;
}

/**
 * @description Sort an array of "product" objects using the configured Intl locale
 *
 * @param {Intl.Collator} options.collator - A collator, usually from 'useCollator'
 * @param {Object} options.intl Intl object to format the product name.
 * @param {Array<Product>} options.products An array of "product" objects typically should have either iconTitle or longName set.
 *
 * @returns {Array<Product>} - Locale-aware sorted array of products based on either the iconTitle or the display name.
 */
function sortProducts({collator, intl, products}) {
  return products.sort((productA, productB) =>
    collator.compare(
      productA.iconTitle || getProductDisplayName(intl, productA),
      productB.iconTitle || getProductDisplayName(intl, productB)
    )
  );
}

/**
 * Method to determine if the Product should have a details page.
 *
 * @param {Product} product - The product
 * @returns {Boolean} - True if the product's channels include UI.
 */
function shouldShowProduct(product) {
  return product.customerUI?.some((ui) => Object.values(PRODUCT_CHANNELS).includes(ui)) ?? false;
}

/**
 *Returns Contract Display Names of a product if org has more than one contract
 * @param {Array} Array of contractIds of a product
 * @returns {Array} Array of ContractDisplayNames of a product
 */
const getContractDisplayNames = (contractIds) => {
  const contractList = RootStore.organizationStore.contractList?.items;

  if (!contractList || contractList.length <= 1 || !contractIds || contractIds.length === 0) {
    return [];
  }

  // Map contractIds to their corresponding display names, filtering out any undefined values
  return contractIds
    .map((contractId) => {
      const foundContract = contractList.find((contract) => contract.id === contractId);
      return foundContract ? foundContract.displayName : null;
    })
    .filter(Boolean);
};

export {groupBy, getProductContracts, shouldShowProduct, sortProducts, getContractDisplayNames};
