import {OrganizationList, OrganizationLocale, log} from '@admin-tribe/binky';
import {Page, PageContent, PageHeader} from '@admin-tribe/binky-ui';
import {Grid} from '@adobe/react-spectrum';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import OverlayWait from 'common/components/wait/OverlayWait';
import CustomDataList from 'common/entities/custom-data/CustomDataList';
import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

import EmailLanguageSection from './email-language-section/EmailLanguageSection';
import NoteSection from './note-section/NoteSection';
import OrgNameSection from './org-name-section/OrgNameSection';

/**
 * Defines the console settings page under the settings tab.
 */
const ConsoleSettingsPage = () => {
  const intl = useIntl();
  const isCurrentRef = useRef(true);

  // Replace with useStore when CustomDataList store is available
  const [notes, setNotes] = useState();
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  // Replace with useStore when OrganizationList store is available
  const [org, setOrg] = useState();
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  // Replace with useStore when OrganizationLocale store is available
  const [organizationLocale, setOrganizationLocale] = useState();
  const [isOrganizationLocaleLoading, setIsOrganizationLocaleLoading] = useState(false);

  const isLoading = () => isNotesLoading || isOrgLoading || isOrganizationLocaleLoading;

  const showNoteSection = () => auth.canViewNotesSection() && notes;

  const showOrgNameSection = () => rootStore.organizationStore.contractList && org;

  // Remove when service stores are available
  useEffect(() => {
    const fetchCustomDataList = async () => {
      try {
        setIsNotesLoading(true);
        const customDataList = await CustomDataList.get();
        if (isCurrentRef.current) {
          setNotes(customDataList);
        }
      } catch (error) {
        log.error('Error getting CustomDataList: ', error);
      } finally {
        if (isCurrentRef.current) {
          setIsNotesLoading(false);
        }
      }
    };
    const fetchOrganizationList = async () => {
      try {
        setIsOrgLoading(true);
        const orgs = await OrganizationList.get();
        if (isCurrentRef.current) {
          orgs.setActive(AppConstants.orgId);
          setOrg(orgs.getActive());
        }
      } catch (error) {
        log.error('Error getting OrganizationList: ', error);
      } finally {
        if (isCurrentRef.current) {
          setIsOrgLoading(false);
        }
      }
    };
    const fetchOrgLocale = async () => {
      try {
        setIsOrganizationLocaleLoading(true);
        const orgLocale = await OrganizationLocale.get({orgId: AppConstants.orgId});
        if (isCurrentRef.current) {
          setOrganizationLocale(orgLocale);
        }
      } catch (error) {
        log.error('Error getting OrganizationLocale: ', error);
      } finally {
        if (isCurrentRef.current) {
          setIsOrganizationLocaleLoading(false);
        }
      }
    };

    isCurrentRef.current = true;

    if (auth.canViewNotesSection()) {
      // Remove when CustomDataList store is available
      fetchCustomDataList();
    }
    // Remove when OrganizationList store is available
    fetchOrganizationList();
    // Remove when OrganizationLocale store is available
    fetchOrgLocale();

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  return (
    <Page data-testid="console-settings-page">
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.consoleSettings.title',
        })}
      />
      <PageContent>
        <OverlayWait isLoading={isLoading()}>
          <Grid autoColumns="size-6000">
            {showOrgNameSection() && (
              <OrgNameSection contractList={rootStore.organizationStore.contractList} org={org} />
            )}
            {showNoteSection() && <NoteSection notes={notes} />}
            {organizationLocale && <EmailLanguageSection organizationLocale={organizationLocale} />}
          </Grid>
        </OverlayWait>
      </PageContent>
    </Page>
  );
};

ConsoleSettingsPage.propTypes = {};

export default ConsoleSettingsPage;
