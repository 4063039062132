import {Contract} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';

import CancellationFeeCard from './CancellationFeeCard';
import LoseAccessCard from './LoseAccessCard';
import TeamStorageCard from './TeamStorageCard';

const cardMap = {
  CancellationFeeCard,
  LoseAccessCard,
  TeamStorageCard,
};

/**
 * Selects a card to render based on the type passed in via props.
 * @param {String} type of the card to render
 * @returns The card component instance specified by type prop
 */
const LossAversionCardFactory = ({type, contract, selectedSeats}) => {
  const Card = cardMap[type];
  return <Card contract={contract} selectedSeats={selectedSeats} />;
};

LossAversionCardFactory.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract),
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * Type of the card to render
   */
  type: PropTypes.oneOf(Object.keys(cardMap)).isRequired,
};

export default LossAversionCardFactory;
