import {GLOBAL_ADMIN_POLICY, feature} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';

import {
  ADMIN_CONTEXT,
  USERS_CONTEXT,
  USER_GROUPS_CONTEXT,
  USER_GROUP_DETAILS_CONTEXT,
} from './globalAdminBannerConstants';

const CONTEXT_TO_ACTION_LIST_MAP = {
  [ADMIN_CONTEXT]: [
    {
      actions: ['addAdmins', 'removeAdmins'],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
    },
  ],
  [USER_GROUP_DETAILS_CONTEXT]: [
    {
      actions: ['addUsers', 'unassignUsers'],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS,
    },
    {
      actions: ['addAdmins', 'removeAdmins'],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
    },
  ],
  [USER_GROUPS_CONTEXT]: [
    {
      actions: ['addUserGroups', 'editUserGroups'],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS,
    },
  ],
  [USERS_CONTEXT]: [
    {
      actions: ['addAdminUsers', 'removeAdminUsers'],
      policy: GLOBAL_ADMIN_POLICY.MANAGE_ADMINS,
    },
  ],
};

/**
 * @description Check global admin policies and return a list of
 * disallowed actions.
 * @param {Object} options Options
 * @param {Object} options.collator Intl.Collator object - can get from useCollator
 * @param {String} options.context The banner context. One of the globalAdminPolicyBanner constants
 *  ADMIN_CONTEXT, USER_GROUP_DETAILS_CONTEXT, USER_GROUPS_CONTEXT, or USERS_CONTEXT
 * @param {intl} options.intl Result of useIntl hook
 *
 * @returns {String} String of sorted disallowed actions if any policies are disallowed.
 *   Otherwise, the empty string.
 * @throws {Error} if context isn't a known value
 */
function getPolicyDisabledActions({collator, context, intl}) {
  const disabledActions = [];
  let disabledActionsStr = '';
  const globalAdminPolicyList = rootStore.organizationStore.globalAdminPolicyList;
  const policyActionList = CONTEXT_TO_ACTION_LIST_MAP[context];

  if (!policyActionList) {
    throw new Error(`getPolicyDisabledActions: unknown context ${context}`);
  }

  // Until temp_global_admin_user_groups is enabled, ignore the GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS policy.
  policyActionList.forEach(({policy, actions}) => {
    if (
      feature.isEnabled('temp_global_admin_user_groups') ||
      policy !== GLOBAL_ADMIN_POLICY.MANAGE_USER_GROUPS
    ) {
      if (!globalAdminPolicyList.getPolicyValue(policy)) {
        disabledActions.push(...actions);
      }
    }
  });

  // Translate & sort the actions and put them in a formatted list.
  if (disabledActions.length > 0) {
    const actions = disabledActions
      .map((action) => intl.formatMessage({id: `common.globalAdmin.disabledActions.${action}`}))
      .sort((a, b) => collator.compare(a, b));
    disabledActionsStr = intl.formatList(actions);
  }

  return disabledActionsStr;
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getPolicyDisabledActions};
