/* eslint-disable complexity -- disable for entire file as logic is exceeding lines allowed */
/* eslint-disable @admin-tribe/admin-tribe/extract-large-computations -- disabling for greater logic */
import {ImageIcon} from '@admin-tribe/binky-ui';
import {
  ActionButton,
  Cell,
  Column,
  Flex,
  ProgressCircle,
  Row,
  TableBody,
  TableHeader,
  Text,
} from '@adobe/react-spectrum';
import {Table, useTableSectionContext} from '@pandora/react-table-section';
import {TooltipButton} from '@pandora/react-tooltip-button';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import CloseIcon from '@spectrum-icons/workflow/Close';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ReauthorizeServerLink from 'features/packages/components/servers-tab/create-server-modal/screens/reauthorize-server/ReauthorizeServerLink';
import {useServerPageContext} from 'features/packages/pages/servers-page/ServerPageContext';

import {SERVERS_CONSTANTS} from '../../packagesConstants';

import ServerSummaryButton from './ServerSummaryButton';

/**
 * Servers list table.
 */
const ServerListTable = observer(() => {
  const {tableSectionState} = useTableSectionContext();
  const {packagesServerStore} = useServerPageContext();

  const intl = useIntl();
  const dateFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const maxLicenseCount = rootStore.packagesUiConstantsStore.maxLicenseIconCount;
  return (
    <Table
      aria-label={intl.formatMessage({
        id: 'packages.servers.title',
      })}
      noItemsFoundContentMessage={null}
      noItemsFoundHeadingMessage={intl.formatMessage({
        id: 'packages.servers.table.empty.noServers.heading',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'packages.servers.table.empty.noSearchResults.content',
      })}
      noSearchResultsHeadingMessage={intl.formatMessage({
        id: 'packages.servers.table.empty.noSearchResults.heading',
      })}
      serverErrorContentMessage={intl.formatMessage({
        id: 'packages.servers.table.empty.networkError.content',
      })}
      serverErrorHeadingMessage={intl.formatMessage({
        id: 'packages.servers.table.empty.networkError.heading',
      })}
    >
      <TableHeader marginBottom="size-200">
        <Column key="serverName" allowsSorting>
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.serverName'})}
        </Column>
        <Column
          key="summaryButton"
          hideHeader
          name={intl.formatMessage({id: 'packages.servers.table.headerLabels.viewDetails'})}
          showDivider
        >
          <FormattedMessage id="packages.servers.table.headerLabels.action" />
        </Column>
        <Column key="entitlements">
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.entitlements'})}
        </Column>
        <Column key="organization">
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.organization'})}
        </Column>
        <Column key="createdOn">
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.createdOn'})}
        </Column>
        <Column key="AuthorizedUntil">
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.authorizedUntil'})}
        </Column>
        <Column key="action">
          {intl.formatMessage({id: 'packages.servers.table.headerLabels.action'})}
        </Column>
      </TableHeader>

      <TableBody items={tableSectionState.items}>
        {(server) => (
          <Row key={server.data.serverId}>
            {/* <!-- Server name --> */}
            <Cell>{server.data.name}</Cell>
            {/* <!-- Server summary button --> */}
            <Cell>
              <ServerSummaryButton server={server.data} />
            </Cell>
            {/* <!-- Server entitlements --> */}
            <Cell>
              <Flex alignItems="center">
                {server.data.licenses.slice(0, maxLicenseCount).map((license) => (
                  <TooltipButton
                    key={license.offerId}
                    hoverText={license.longName}
                    isQuiet
                    variant="action"
                  >
                    <ImageIcon alt="" size="M" src={license.getIconUrl()} />
                  </TooltipButton>
                ))}
                <span>
                  {server.data.licenses.length > maxLicenseCount
                    ? `+${server.data.licenses.length - maxLicenseCount}`
                    : null}
                </span>
              </Flex>
            </Cell>
            {/* <!-- Server organization/branding --> */}
            <Cell>{server.data.branding}</Cell>
            {/* <!-- Server creation timestamp --> */}
            <Cell>
              {!!server.data.creationTimestamp && (
                <Flex>{intl.formatDate(server.data.creationTimestamp, dateFormat)}</Flex>
              )}
            </Cell>
            {/* <!-- Server expiry --> */}
            <Cell>
              {!server.data.isCreationInProgress() && !!server.data.expiryTimestamp && (
                <Flex>
                  {server.data.isActive() && (
                    <Flex>{intl.formatDate(server.data.expiryTimestamp, dateFormat)}</Flex>
                  )}
                  {server.data.isExpired() && (
                    <Flex>
                      <Text
                        marginTop="size-100"
                        UNSAFE_style={{color: 'var(--color-red-600)', fontStyle: 'italic'}}
                      >
                        {intl.formatMessage(
                          {id: 'packages.servers.table.status.expired'},
                          {
                            expiryDate: intl.formatDate(server.data.expiryTimestamp, dateFormat),
                          }
                        )}
                      </Text>
                      <TooltipButton
                        buttonAriaLabel={intl.formatMessage({
                          id: 'packages.servers.table.status.expiredTooltip',
                        })}
                        hoverText={intl.formatMessage({
                          id: 'packages.servers.table.status.expiredTooltip',
                        })}
                        isQuiet
                        variant="action"
                      >
                        <AlertIcon
                          color="negative"
                          size="S"
                          UNSAFE_style={{fill: 'var(--spectrum-global-color-red-600)'}}
                        />
                      </TooltipButton>
                    </Flex>
                  )}
                  {server.data.isExpiring() && (
                    <Flex>
                      <Text
                        marginTop="size-100"
                        UNSAFE_style={{color: 'var(--color-grey-600)', fontStyle: 'italic'}}
                      >
                        {intl.formatDate(server.data.expiryTimestamp, dateFormat)}
                      </Text>
                      <TooltipButton
                        buttonAriaLabel={intl.formatMessage({
                          id: 'packages.servers.table.status.expiringTooltip',
                        })}
                        hoverText={intl.formatMessage({
                          id: 'packages.servers.table.status.expiringTooltip',
                        })}
                        isQuiet
                        variant="action"
                      >
                        <AlertIcon
                          color="negative"
                          size="S"
                          UNSAFE_style={{fill: 'var(--spectrum-global-color-red-600)'}}
                        />
                      </TooltipButton>
                    </Flex>
                  )}
                </Flex>
              )}
              {server.data.getActionState() === SERVERS_CONSTANTS.STATE.PREPARING && (
                <Flex alignItems="center" direction="row" justifyContent="space-between">
                  <Flex direction="column">
                    <ProgressCircle
                      aria-label={intl.formatMessage({
                        id: 'packages.servers.table.status.preparing',
                      })}
                      isIndeterminate
                    />
                    <Text UNSAFE_style={{color: 'var(--color-grey-600)', fontStyle: 'italic'}}>
                      {intl.formatMessage({id: 'packages.servers.table.status.preparing'})}
                    </Text>
                  </Flex>
                  {!server.data.hasLastAtoDetails() && (
                    <ActionButton
                      aria-label={intl.formatMessage({
                        id: 'packages.servers.table.status.preparing',
                      })}
                      data-testid="cancel-preparing-package-button"
                      isQuiet
                      onPress={() => {
                        packagesServerStore.deleteSelectedServers([server.data], true);
                      }}
                    >
                      <CloseIcon />
                    </ActionButton>
                  )}
                </Flex>
              )}
              {server.data.getActionState() === SERVERS_CONSTANTS.STATE.BUILDING && (
                <Flex alignItems="center" direction="row" justifyContent="space-between">
                  <Flex direction="column">
                    <ProgressCircle
                      aria-label={intl.formatMessage({
                        id: 'packages.servers.table.status.building',
                      })}
                      isIndeterminate
                    />
                    <Text UNSAFE_style={{color: 'var(--color-grey-600)', fontStyle: 'italic'}}>
                      {intl.formatMessage({id: 'packages.servers.table.status.building'})}
                    </Text>
                  </Flex>
                  {!server.data.hasLastAtoDetails() && (
                    <ActionButton
                      aria-label={intl.formatMessage({
                        id: 'packages.servers.table.status.building',
                      })}
                      data-testid="cancel-building-package-button"
                      isQuiet
                      onPress={() => {
                        packagesServerStore.deleteSelectedServers([server.data], true);
                      }}
                    >
                      <CloseIcon />
                    </ActionButton>
                  )}
                </Flex>
              )}
            </Cell>
            {/* <!-- Server state/actions --> */}
            <Cell>
              {server.data.getActionState() === SERVERS_CONSTANTS.STATE.REAUTHORIZE && (
                <Flex>
                  <ReauthorizeServerLink serverObject={server.data} />
                </Flex>
              )}
            </Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
});

ServerListTable.propTypes = {};
ServerListTable.displayName = 'ServerListTable';
export default ServerListTable;
/* eslint-enable complexity -- disable for entire file as logic is exceeding lines allowed */
/* eslint-enable @admin-tribe/admin-tribe/extract-large-computations -- disabling for greater logic */
