/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ProductConfiguration
   * @description Model for the product license configuration view embedded within
   *              the larger product management page. Sometimes referred to as 'PLC'.
   */

  angular
    .module('app.core.product.configurations')
    .factory('ProductConfiguration', getProductConfigurationModel);

  /* @ngInject */
  function getProductConfigurationModel(
    $log,
    $q,
    $rootScope,
    $translate,
    _,
    binkyProductNameLabelFilter,
    binkySrc2,
    MODEL,
    modelCache,
    OrganizationManager,
    Product,
    // eslint-disable-next-line id-length
    PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE,
    PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT,
    PRODUCT_CONFIGURATIONS_EVENT
  ) {
    // We register the cache size for this class
    modelCache.register(MODEL.LICENSECONFIGURATION, 10);

    class LicenseGroupSrc2 extends binkySrc2.services.product.licenseGroup.LicenseGroup {
      constructor(options) {
        super(
          _.assign(
            {
              orgId: OrganizationManager.getActiveOrgId(),
            },
            options
          )
        );
      }

      key() {
        return `${this.product.id}/${this.id}`;
      }

      /**
       * @description Reloads the license group from the license group resource
       * @return {Promise<LicenseGroup>} a promise
       */
      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => {
              resolve(response);
              this.$resolved = true;
              modelCache.put(MODEL.LICENSECONFIGURATION, this, this.key());
            })
            .catch((error) => {
              reject(error.response);
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Method to save changes to the license group to the back-end.
       * @param {Object} options - configuration object.
       * @returns {Promise<LicenseGroup>} resolves if changes successfully saved, else rejects with error message
       */
      save(options = {}) {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .save(options)
            .then((response) => {
              resolve(response);
              this.$resolved = true;
              modelCache.put(MODEL.LICENSECONFIGURATION, this, this.key());
            })
            .catch((error) => {
              reject(error.response);
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Method to retrieve a copy of this model that represents the
       *              absolute minimum amount of data that the back-end API needs
       *              to operate. This should be removed when the following issue
       *              in Git is resolved: https://git.corp.adobe.com/Blue-Team/customer-admin-issues/issues/1980
       *              Updated because of: https://git.corp.adobe.com/Blue-Team/customer-admin-issues/issues/2074
       *
       * @return {Object} bare minimum LicenseGroup model for back-end
       */
      toMinimumModel() {
        const minimumModel = {
          id: this.id,
        };

        if (_.get(this, 'product.id', false)) {
          minimumModel.product = {
            id: this.product.id,
          };
        }

        return minimumModel;
      }

      /**
       * @description Method to transform an api response item to a LicenseGroupSrc2.
       * @param {Object} item the item to transform
       * @param {Object} listRef the list this item is being generated for
       * @returns {LicenseGroupSrc2} The transformed item
       */
      static apiResponseTransformer(item, listRef) {
        let product = _.invoke(listRef, 'getProduct');
        if (!product && item.product) {
          const productList = OrganizationManager.getProductsForActiveOrg();
          product = _.find(productList.items, {id: item.product.id});
        }

        // If the product list singleton doesn't have the product,
        // then use the skeleton product
        if (!product && item.product) {
          product = new Product(item.product);
        }
        return new LicenseGroupSrc2(_.assignIn({}, item, {product}));
      }

      /**
       * @description Method to retrieve an existing license group.
       *
       * @param {Product} product reference to the Product to retrieve a configuration for
       * @param {Number} licenseConfigId ID of the license configuration to retrieve for this product
       * @returns {Promise<LicenseGroup>} promise
       */
      static get(product, licenseConfigId) {
        const model = new LicenseGroupSrc2({
          id: licenseConfigId,
          product,
        });

        const cachedModel = modelCache.get(MODEL.LICENSECONFIGURATION, model.key());
        if (cachedModel) {
          return cachedModel;
        }

        model.refresh();

        return model;
      }

      /**
       * @description Method to add the default Product License Configuration(s) where one
       *              does not already exist.
       *              If the product doesn't have SingleDesktopApplicationConfig FI,
       *              one default product profile will be created.
       *              If the product has SingleDesktopApplicationConfig FI,
       *              we loop through its desktop FIs and create one product profile for each of the FI.
       *
       * @param {LicenseGroupSrc2} defaultProductConfiguration reference to the LicenseGroupSrc2 to create
       * @returns {Promise} If the product doesn't have SingleDesktopApplicationConfig FI,
       *              Fulfilled with reference to actual, created ProductConfiguration
       *              If the product has SingleDesktopApplicationConfig FI,
       *              resolves if successfully add at least one default ProductConfiguration,
       *              rejected if all profile creation fails.
       */
      static addDefaultProductConfiguration(defaultProductConfiguration) {
        // track/communicate completion of this method
        const deferred = $q.defer();
        const {promise} = deferred;

        const product = Product.get({id: defaultProductConfiguration.product.id});
        product.$promise.then(getProductComplete).catch(deferred.reject);

        return promise;

        ////////////

        function getProductComplete(productResult) {
          if (productResult.fulfillableItemList.hasSingleDesktopApplicationConfig()) {
            const promises = preparePromisesForDesktopFIs(productResult);
            $q.all(promises)
              .then((response) => onPLCResourceBulkCreation(response))
              .finally(_.noop);
          } else {
            const productConfig = LicenseGroupSrc2.createFromProductDefaults(productResult);
            const defaultProductConfigurationName = $translate.instant(
              'core.product.defaultConfigurationWithInput',
              {
                key0: binkyProductNameLabelFilter(productResult),
              }
            );

            const postData = _.assign(toEditable(productConfig), {
              name: defaultProductConfigurationName,
              publicName: defaultProductConfigurationName,
              totalQuantity: productResult.getAssignableLicenseCount(),
            });
            // Log default profile creation to New Relic to prepare for default profile creation code removal
            $log.error('ProductConfiguration: Creating default profile');
            binkySrc2.api.jil.jilOrganizationsProductsLicenseGroups
              .postLicenseGroups(
                {orgId: OrganizationManager.getActiveOrgId(), productId: productResult.id},
                postData
              )
              .then(onPLCResourceCreationSuccess)
              .catch((error) => {
                onPLCResourceCreationError(error.response, 2);
              });
          }
        }

        function onPLCResourceBulkCreation(response) {
          const succeedRequests = _.filter(response, {$resolved: true});
          // If at least one request succeed, resolve the promise;
          // otherwise reject the promise.
          if (succeedRequests.length > 0) {
            onPLCResourceCreationSuccess();
          } else {
            onPLCResourceCreationRetryError(PRODUCT_CONFIGURATION_BULK_CREATION_FAILURE_MESSAGE);
          }
        }

        function onPLCResourceCreationSuccess(response = {}) {
          defaultProductConfiguration.id = response.id;
          deferred.resolve(response);
        }

        function onPLCResourceCreationError(error, defaultPLCCreationAttemptCount) {
          if (
            error.data.errorCode === 'LICENSE_GROUP_DUPLICATE_GROUP_NAME' ||
            error.data.errorCode === 'LICENSE_GROUP_DUPLICATE_GROUP_PUBLIC_NAME'
          ) {
            if (defaultPLCCreationAttemptCount <= PRODUCT_CONFIGURATION_CREATION_ATTEMPT_LIMIT) {
              // Try again with defaultPLCCreationAttemptCount appended to the name
              const defaultProductConfigurationName = `${$translate.instant(
                'core.product.defaultConfigurationWithInput',
                {
                  key0: binkyProductNameLabelFilter(product),
                }
              )} ${defaultPLCCreationAttemptCount}`;

              const postData = {
                name: defaultProductConfigurationName,
                publicName: defaultProductConfigurationName,
                totalQuantity: product.getAssignableLicenseCount(),
              };
              // eslint-disable-next-line promise/no-promise-in-callback
              binkySrc2.api.jil.jilOrganizationsProductsLicenseGroups
                .postLicenseGroups(
                  {orgId: OrganizationManager.getActiveOrgId(), productId: product.id},
                  postData
                )
                .then(onPLCResourceCreationSuccess)
                .catch((error_) => {
                  onPLCResourceCreationError(error_.response, defaultPLCCreationAttemptCount + 1);
                });
            } else {
              onPLCResourceCreationRetryError(error);
            }
          } else {
            deferred.reject(error);
          }
        }

        function onPLCResourceCreationRetryError(error) {
          $log.error('ProductConfiguration.addDefaultProductConfiguration(): Error: ', error);
          deferred.reject(error);
        }

        function preparePromisesForDesktopFIs(productResult) {
          const desktopFIs = toMinimumFulfilledItems(
            productResult.fulfillableItemList.getDesktopTypeItems()
          );
          // set all desktopFI's selected attribute to false.
          _.forEach(desktopFIs, (item) => {
            item.selected = false;
          });

          // build a promise array which contains promises to create default profile for every desktop FI.
          return _.map(desktopFIs, (desktopFI) => {
            const defaultProductConfigurationName = $translate.instant(
              'core.product.defaultConfigurationWithInputForSingleDesktopApplicationConfig',
              {
                key0: productResult.getFulfillableItemNameByCode(desktopFI.code),
                key1: _.get(productResult, 'longName'),
              }
            );
            const fulfilledItems = _.cloneDeep(desktopFIs);
            // set selected to true for the desktop FI that we are creating default profile for.
            _.find(fulfilledItems, {code: desktopFI.code}).selected = true;
            const postData = {
              fulfilledItems,
              name: defaultProductConfigurationName,
              publicName: defaultProductConfigurationName,
              // totalQuantity is the quota for the product profile and it's a soft quota for ETLA and hard quota for EVIP.
              // We couldn't find a good way to set the default quota for both ETLA and EVIP,
              // and the Single App offer is only available for ETLA customers initially,
              // we decide to put 0 as the quota and come back to fix it if we have to support EVIP.
              totalQuantity: 0,
            };

            // Log default profile creation to New Relic to prepare for default profile creation code removal
            $log.error('ProductConfiguration: Creating default profile');
            return binkySrc2.api.jil.jilOrganizationsProductsLicenseGroups
              .postLicenseGroups(
                {orgId: OrganizationManager.getActiveOrgId(), productId: productResult.id},
                postData
              )
              .then(() => ({
                $resolved: true,
              }))
              .catch((error) => error); // The promise returned by $q.all is rejected once any of the promise in the array is rejected.
            // we don't want to reject the $q.all promise unless all promises fail, thus catching the error here
          });
        }
      }

      /**
       * @description Method to ensure that all ProductConfigurations have actually been created
       *              (can not edit default ProductConfigurations).
       *
       * @param {Array} configsToCheck list of ProductConfigurations to check
       * @returns {Promise} resolves if successfully add any/all default ProductConfigurations
       *                    found, else rejected with error message
       */
      static createAnyDefaultProductConfigurations(configsToCheck) {
        // track/communicate completion of this method
        const deferred = $q.defer();
        const {promise} = deferred;

        // track completion of *all* calls that may get made

        // go through ProductConfigurations looking for default configurations
        const promises = _(configsToCheck).reject('id').map(checkConfigForDefault).value();

        // make sure all promises complete before resolving this method's promise
        $q.all(promises).then(onAllPromisesResolved).catch(onSomePromisesRejected);

        return promise;

        ////////////

        function checkConfigForDefault(config) {
          // default configuration
          return LicenseGroupSrc2.addDefaultProductConfiguration(config).then(emitCreateConfig);
        }

        function emitCreateConfig() {
          $rootScope.$emit(PRODUCT_CONFIGURATIONS_EVENT.CREATE);
        }

        function onAllPromisesResolved() {
          deferred.resolve();
        }

        function onSomePromisesRejected(error) {
          $log.error('ProductConfiguration - failed to create all default configs. Error: ', error);
          deferred.reject();
        }
      }

      /**
       * @description Method to create a new Product Configuration, loading
       *              in default services information from the Product defaults.
       *
       * @param {Product} product reference to the Product to retrieve a configuration for
       * @returns {LicenseGroupSrc2} Reference to pre-existing ProductConfiguration
       */
      static createFromProductDefaults(product) {
        const defaultName = $translate.instant('core.product.defaultConfigurationWithInput', {
          key0: binkyProductNameLabelFilter(product),
        });
        const fulfilledItems = _.concat(
          product.fulfillableItemList.getServiceTypeItemsWithSelectedInitialized(),
          product.fulfillableItemList.getConsumableQuotaTypeItems()
        );
        const props = {
          fulfilledItems,
          name: defaultName,
          notifications: true,
          product,
          publicName: defaultName,
          totalQuantity: _.get(product, 'totalQuantity'),
        };

        return new LicenseGroupSrc2(props);
      }
    }

    return LicenseGroupSrc2;

    ////////////

    /**
     * @description Filter the provided model to those fields which may have been edited
     *
     * @param {Object} model - product configuration model to filter
     * @returns {Object} the filtered editable state
     */
    function toEditable(model) {
      const response = _.pick(model, [
        'delegatableCreditQuota',
        'delegatableImageQuota',
        'delegatableVideoQuota',
        'description',
        'name',
        'notifications',
        'publicName',
        'solutionGroup',
        'totalQuantity',
      ]);
      // Note, while the code isn't actually editable, it's how we match the item
      response.fulfilledItems = toMinimumFulfilledItems(model.fulfilledItems);
      return response;
    }

    /**
     * @description Method to retrieve a copy of the fulfilledItems that represents the
     *              absolute minimum amount of data that the back-end API needs
     * @param {Object} fulfilledItems - the fulfilled items
     * @return {Object} the fulfilledItems that only contain code, selected, and chargingModel.cap
     */
    function toMinimumFulfilledItems(fulfilledItems) {
      return _.map(fulfilledItems, (fulfilledItem) =>
        _.pick(fulfilledItem, ['code', 'selected', 'chargingModel.cap'])
      );
    }
  }
})();
/* eslint-enable max-lines */
