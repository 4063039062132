import {getSeatBasedTotalProvisionedQuantity} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canViewQuickAssignModal} from 'core/products/access/productAccess';
import auth from 'core/services/auth';
import {goToQuickAssignProducts} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to start assigning licenses
 */
class StartAssigningLicensesHva extends HvaCard {
  /**
   * @description Method to fetch the StartAssigningLicensesHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<StartAssigningLicensesHva[]>} the fetched instance
   */
  static get({intl}) {
    if (
      auth.isUserOrgAdmin() &&
      canViewQuickAssignModal() &&
      getSeatBasedTotalProvisionedQuantity(rootStore.organizationStore.productList) === 0
    ) {
      return Promise.resolve([new StartAssigningLicensesHva({intl})]);
    }

    return Promise.reject();
  }

  /**
   * @description Creates a new StartAssigningLicensesHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {StartAssigningLicensesHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.startAssigningLicenses';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: false,
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/assign-licenses.svg',
      },
      id: 'hva-start-assigning-licenses',
      isDismissible: false,
      onCTA: goToQuickAssignProducts,
      priorityOrder: HVA_ORDER.START_ASSIGN_LICENSES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default StartAssigningLicensesHva;
