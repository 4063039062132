import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const StepItem = ({children, id, ...styleProps}) => <View {...styleProps}>{children}</View>;

StepItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default StepItem;
