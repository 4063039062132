(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilContracts
   * @description defines service to manage jil contracts resources
   */
  angular.module('app.core.api.jil').provider('jilContracts', jilContractsProvider);

  let url;

  /* @ngInject */
  function jilContractsProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        contract: getContractResource(),
      };

      return service;

      ///////////

      function getContractResource() {
        return $resource(
          `${url}/:orgId/contracts`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            // If the contract owner is undefined on the first acceptance,
            // the contract owner (enrollee) will be set
            acceptTerms: {
              method: 'PUT',
              params: {contractId: '@contractId'},
              url: `${url}/:orgId/contracts/:contractId%3AacceptTerms`,
            },
          }
        );
      }
    }
  }
})();
