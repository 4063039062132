(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.search:searchTopicsHelper
   * @description
   */
  angular.module('app.widgets.search').factory('searchTopicsHelper', searchTopicsHelper);

  /* @ngInject */
  function searchTopicsHelper($translate, $window, _, locale) {
    const DEFAULT_TOPICS = [
      {
        sourceKey: 'enterprise/using/admin-console.html#Sign-in',
        titleKey: 'signIn',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Overview',
        titleKey: 'overview',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Products',
        titleKey: 'products',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Users',
        titleKey: 'users',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Packages',
        titleKey: 'packages',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Accounts',
        titleKey: 'accounts',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Settings',
        titleKey: 'settings',
      },
      {
        sourceKey: 'enterprise/using/admin-console.html#Support',
        titleKey: 'support',
      },
    ];

    const NAMESPACE = 'widgets.search.searchTopicsHelper.defaultTopics.';
    const rootUrl = getRootUrl();

    const service = {
      getDefaultSearchTopics,
      getHelpxUrl,
    };

    return service;

    //////////////

    function getDefaultSearchTopics() {
      return _.map(DEFAULT_TOPICS, (topic) => {
        const {sourceKey, titleKey} = topic;
        return {
          source: rootUrl + sourceKey,
          title: $translate.instant(`${NAMESPACE}${titleKey}`),
        };
      });
    }

    function getHelpxUrl(query) {
      const q = $window.encodeURIComponent(query);
      const facetsFields = $window.encodeURIComponent('["applicable_products"]');
      const postFacetsFields = $window.encodeURIComponent(
        '{"applicable_products":["Adobe Enterprise & Teams"]}'
      );
      const scope = $window.encodeURIComponent('["helpx"]');
      return (
        `${rootUrl}search-results.html?q=${q}&facets_fields=${facetsFields}` +
        `&post_facet_filters=${postFacetsFields}&scope=${scope}`
      );
    }

    /* Private functions */
    function getRootUrl() {
      const localeCode = _.chain(locale.getActiveLocaleCode())
        .split('_')
        .get('[1]')
        .toLower()
        .value();

      let retVal = 'https://helpx.adobe.com/';
      if (localeCode !== 'us') {
        retVal += `${localeCode}/`;
      }

      return retVal;
    }
  }
})();
