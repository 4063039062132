import {Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {groupPropType} from '../edit-product-profile-permissions/prop-types/configurationGroupsPropTypes';
import {
  PROFILE_PERMISSIONS_GROUP_TYPE,
  PROFILE_PERMISSIONS_SUB_CONTENT_TYPE,
} from '../profilePermissionsConstants';

const ProfilePermissionDescription = ({group}) => {
  const intl = useIntl();

  const getDescriptionForItem = (item) => (
    <Text>
      {item === undefined || item.description === undefined
        ? intl.formatMessage({id: 'products.productProfilePermissions.noDescription'})
        : item.description}
    </Text>
  );

  const getDescriptionForSelectedItem = (items) => {
    const selectedItem = items.find((item) => item.selected);
    return getDescriptionForItem(selectedItem);
  };

  const isDisabled =
    group.canDisable !== undefined &&
    group.canDisable &&
    group.disabled !== undefined &&
    group.disabled;
  const isEmpty = group.content === undefined || group.content.length === 0;
  if (!isDisabled && !isEmpty) {
    if (group.type === PROFILE_PERMISSIONS_GROUP_TYPE.EXPANDABLE_SELECT) {
      return getDescriptionForSelectedItem(group.content);
    }
    const subcontent = group.content[0];
    if (subcontent.type === PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.SELECT) {
      return getDescriptionForSelectedItem(subcontent.values);
    }
  }
  return getDescriptionForItem(group);
};

ProfilePermissionDescription.propTypes = {
  /**
   * The group (also called the content) of a section.
   */
  group: groupPropType.isRequired,
};

export default ProfilePermissionDescription;
