import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

/**
 * @description Fetches articles & helpful links from sophia
 * @returns {Object[]} tuple - [0] is sophia popular articles, [1] is sophia helpful links
 */
const getHelpfulResources = async () => {
  // if this errors, it will be handled by useAsyncModel
  const [sophiaArticlesList, sophiaLinksList] = await Promise.all([
    sophiaHelper.getSophiaCards({
      surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_HELPFUL_RESOURCES_ARTICLES,
    }),
    sophiaHelper.getSophiaCards({
      surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_HELPFUL_RESOURCES_LINKS,
    }),
  ]);

  const sophiaArticles = sophiaArticlesList.items;
  const sophiaLinks = sophiaLinksList.items;

  return [
    // map id out of the card so that it can be easily used for the key prop
    sophiaArticles.map((item) => ({...item.card, id: item.getCardId()})),
    sophiaLinks.map((item) => ({...item.card})),
  ];
};

// eslint-disable-next-line import/prefer-default-export -- utils
export {getHelpfulResources};
