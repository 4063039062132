(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  angular
    .module('app.widgets.products')
    .constant('PRODUCT_SELECT_CHANGE', 'product-select:change')
    .constant('PRODUCT_SELECT_ERROR', 'product-select:error')
    .constant('PRODUCT_SELECT_VALIDITY_CHANGE', 'product-select:validity-change');
})();
