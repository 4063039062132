/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.bulk-operations:appInitiatedByDetailSection
 *
 * @description The initiated by detail section in the Operation Result details
 *   drawer.
 *
 * @binding {Job} job. Job reference to display details for.
 *
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appInitiatedByDetailSection', {
    bindings: {
      job: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/detail-sections/initiated-by-detail-section/initiated-by-detail-section.component.html',
  });

  /* @ngInject */
  function controller($stateParams, _, auth) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      canViewUserDetails,
      orgId: $stateParams.orgId,
    });

    function canViewUserDetails() {
      return auth.canViewUserDetails();
    }

    function $onInit() {
      _.noop();
    }
  }
})();
