import {navBus} from '@admin-tribe/binky';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const determineKeyByPath = (location) =>
  location.pathname.includes('payment-needed-devices') ? 'paymentNeeded' : 'allDevices';

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

const LegacyDevicesTabs = ({tabs, 'aria-label': ariaLabel}) => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(determineKeyByPath(location));

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    setSelectedKey(determineKeyByPath(location));
  }, [location]);

  const onChange = useCallback(
    (key) => {
      if (key === selectedKey) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Just modify the URL, the key will get updated in the useEffect above
      navBus.pushState({url: findTabByKey(tabs, key).pathname});
    },
    [selectedKey, tabs]
  );

  return (
    <Tabs aria-label={ariaLabel} onSelectionChange={onChange} selectedKey={selectedKey}>
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.name}</Item>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.content}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
};

LegacyDevicesTabs.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LegacyDevicesTabs;
