(function () {
  /**
   * @ngdoc module
   * @name app.widgets.admins
   * @description module that defines admins widgets
   */
  angular.module('app.widgets.admins', [
    'app.core.organizations',
    'binky.core.lodash',
    'binky.widgets.common.detail-section',
    'binky.widgets.common.member.member-name',
    'ui.router',
  ]);
})();
