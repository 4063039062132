const MAX_MIGRATION_NUMBER = 50;
// Order matters for this constant since we iterate it and get the data with this order.
// Please see getOfferSwitchMigrationSummary() in offerSwitchMigrationHelper.js
const PRODUCT_TYPES_FOR_CONTRACT_MIGRATION = {SOURCE: 'source', TARGET: 'target'};

const SOURCE_PRODUCT_INDEX = 0;
const TARGET_PRODUCT_INDEX = 1;

const WIZARD_STEPS = {
  ASSIGN_PRODUCTS: 1,
  INTRO: 0,
  SUMMARY: 2,
};

export {
  MAX_MIGRATION_NUMBER,
  PRODUCT_TYPES_FOR_CONTRACT_MIGRATION,
  SOURCE_PRODUCT_INDEX,
  TARGET_PRODUCT_INDEX,
  WIZARD_STEPS,
};
