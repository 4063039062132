import {ModelList, eventBus, feature, log, modelCache} from '@admin-tribe/binky';
import {showError, showSuccess, translateString} from '@admin-tribe/binky-ui';
import axios from 'axios';
import {reaction} from 'mobx';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

import {deletePackages, getAdminPackages} from '../api/tronData';
import PackagesAdminPackageEntity from '../entities/PackagesAdminPackageEntity';
import {ADMIN_PACKAGES, CREATE_PACKAGE, CUSTOMIZER_PROGRESS} from '../packagesConstants';

import PackagesEntitlementsService from './PackagesEntitlementsService';

let adminPackagesGetterResourceResponse,
  adminPackagesModel,
  cancelTokenPackagesSource,
  pollerPromise;

let pollingQueue = [];
let pollingFailureCountMap = {};
let isModelDirty = false;
let isBuildDisabled = false;
let isBuildDisabledForSession = false;
let isBuildDisabledPermanently = false;

/**
 * @description Method to reset all Packages
 */
function configure() {
  reaction(
    () => rootStore.organizationStore.activeOrg,
    () => {
      resetAdminPackages();
    }
  );
}

configure();

class PackagesAdminService extends ModelList {
  //////////////////////////////////////////
  /** Constructor for Admin Package (Prototype function declaration) **/
  /**
   * @description Method to fetch and process all Admin Packages
   *
   * @param {Boolean} forcedFetch force fetch from back-end ignoring model-cache. Default false
   * @returns {Promise} returns promise
   */
  static async fetchAndProcessAdminPackages(forcedFetch = false) {
    const authuserid = await auth.getUserId();

    const cachedAdminPackages = await modelCache.get(ADMIN_PACKAGES, 'ADMIN_PACKAGES');
    if (!isModelDirty && !forcedFetch && cachedAdminPackages) {
      onAdminPackagesFetchSuccess.call(this, authuserid, cachedAdminPackages);
      return Promise.resolve('Promise resolved successfully');
    }

    try {
      cancelTokenPackagesSource = axios.CancelToken.source();
      const queryParams = {
        add_kccc: true,
        show_customized_only: !(
          feature.isEnabled('packages-ccp-created') &&
          feature.isEnabled('packages-ccp-created-allowed')
        ),
      };

      queryParams.isEnterprise = PackagesEntitlementsService.isEnterpriseOrg;

      adminPackagesGetterResourceResponse = await getAdminPackages(
        queryParams,
        cancelTokenPackagesSource
      );

      if (adminPackagesGetterResourceResponse) {
        pollingQueue = [];
        pollingFailureCountMap = {};
        adminPackagesModel = new PackagesAdminService(
          authuserid,
          adminPackagesGetterResourceResponse.data
        );
        initializePackageProgressPoller();
        modelCache.put(ADMIN_PACKAGES, 'ADMIN_PACKAGES', adminPackagesGetterResourceResponse);
        isModelDirty = false;
      }
      return adminPackagesGetterResourceResponse;
    } catch (error) {
      log.error(
        'PackagesAdminService.fetchAndProcessAdminPackages(): Unable to retrieve model from back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to get all Admin Packages.
   *
   * @returns {Array} Array of all Admin Packages.
   */
  static getAllAdminPackages() {
    return adminPackagesModel.items;
  }
  /**
   * @description Method to get number of Admin Packages.
   *
   * @returns {Integer} number of Admin Packages.
   */

  static getAdminPackagesCount() {
    return adminPackagesModel?.items.length;
  }
  /**
   * @description Method to mark the model dirty
   */

  static markModelDirty() {
    isModelDirty = true;
    return isModelDirty;
  }
  /**
   * @description Method to disable build button permanently for current session
   */

  static disableBuildPermanently() {
    isBuildDisabledPermanently = true;
    return isBuildDisabledPermanently;
  }
  /**
   * @description Method to disable build button for current session
   */

  static disableBuildForSession() {
    isBuildDisabledForSession = true;
    return isBuildDisabledForSession;
  }
  /**
   * @description Method to enable build button for current session
   */

  static enableBuildForSession() {
    isBuildDisabledForSession = false;
    return isBuildDisabledForSession;
  }

  /**
   * @description Method to check if build/create package buttons should be disabled
   *
   * @returns {Boolean} Should disable or not
   */
  static isBuildDisabled() {
    return (
      (feature.isEnabled('packages-ccp-created') &&
        feature.isEnabled('packages-ccp-created-allowed')) ||
      isBuildDisabled ||
      isBuildDisabledForSession ||
      isBuildDisabledPermanently
    );
  }
  /**
   * @description Method to delete selected packages
   *
   * @param {Array} selectedPackages Array of selected AdminPackages
   * @param {Boolean} isCancelOperation Flag if the given package is to be cancelled or removed
   * @returns {Promise} returns promise
   */

  static async deleteSelectedPackages(selectedPackages, isCancelOperation) {
    // form an array of selected packageIDs

    const selectedPackageIds = selectedPackages.map((selectedPackage) => selectedPackage.packageId);

    const selectedPackageIdsSize = Object.keys(selectedPackageIds).length;

    try {
      const adminPackgeDeleteResourceResponse = await deletePackages(selectedPackageIds);
      const responseSize = Object.keys(adminPackgeDeleteResourceResponse.data).length;

      if (responseSize < selectedPackageIdsSize) {
        showDeletePackagesErrorToast(selectedPackageIdsSize - responseSize, isCancelOperation);
        return Promise.reject(new Error('Error in deleting some packages'));
      }
      // show success if atleast 1 server deleted successfully
      if (responseSize > 0) {
        if (isCancelOperation) {
          showSuccess(
            translateString(
              {id: 'packages.alerts.allPackages.cancelPackageSuccess'},
              {count: responseSize}
            )
          );
        } else {
          showSuccess(
            translateString(
              {id: 'packages.alerts.allPackages.removePackageSuccess'},
              {count: responseSize}
            )
          );
        }
      }
      return Promise.resolve('Promise resolved successfully');
    } catch (error) {
      log.error(
        'PackagesAdminService.deleteSelectedPackages(): Unable to delete packages on back-end. Error: ',
        error
      );
      showDeletePackagesErrorToast(selectedPackageIdsSize, isCancelOperation);
      return Promise.reject(error);
    }
  }
  /**
   * @class
   * @description Creates a new AdminPackagesList for use.
   *
   * @param {Array} packages Array of Admin Packages as received from back-end
   * @param {string} authuserid authid of user creating the admin package list
   */

  constructor(authuserid, packages) {
    const items = packages
      .map((packageInfoWrapper) => {
        const newAdminPackage = new PackagesAdminPackageEntity(authuserid, packageInfoWrapper);
        if (isPackageInProgress(newAdminPackage.customizerProgress)) {
          pollingQueue.push(newAdminPackage);
        }
        return newAdminPackage;
      })
      .filter((item) => item !== undefined);
    super({
      items: items.filter(
        (item) =>
          item.isCcpCreated ===
          (feature.isEnabled('packages-ccp-created') &&
            feature.isEnabled('packages-ccp-created-allowed'))
      ),
    });
  }
}

/**
 * @description Method to check if adminPackage building is in progress
 *
 * @param {Object} customizerProgress customizerProgress object for package
 * @returns {Boolean} if in-progress
 */
function isPackageInProgress(customizerProgress) {
  if (!customizerProgress) {
    return false;
  }
  if (
    customizerProgress.message !== CUSTOMIZER_PROGRESS.ERROR &&
    customizerProgress.message !== CUSTOMIZER_PROGRESS.CANCELLED &&
    customizerProgress.message !== CUSTOMIZER_PROGRESS.UPLOAD_COMPLETE
  ) {
    return true;
  }
  return false;
}

function clearPollerPromise() {
  clearInterval(pollerPromise);
  pollerPromise = undefined;
}

function checkPollingFailureCountMap(server) {
  return pollingFailureCountMap[server.serverId] ?? 0 < 5;
}

/**
 * @description Method to initialize Package Progress Poller
 */
function initializePackageProgressPoller() {
  if (pollerPromise) {
    clearPollerPromise();
  }
  if (pollingQueue.length === 0) {
    isBuildDisabled = false;
    return;
  }

  isBuildDisabled =
    pollingQueue.length >= rootStore.packagesUiConstantsStore.maxSimultaneousPackageBuildCount;

  pollerPromise = setInterval(async () => {
    const filteredPollingQueue = pollingQueue.filter(checkPollingFailureCountMap);
    // eslint-disable-next-line no-restricted-syntax -- added comment
    for (const element of filteredPollingQueue) {
      element.packageID = element.packageId;
      try {
        // eslint-disable-next-line no-await-in-loop -- to ensure that the statements are executed sequentially
        await element.fetchAndProcessPackageProgress();
        pollingFailureCountMap[element.packageId] = 0;

        if (!isPackageInProgress(element.customizerProgress)) {
          pollingQueue = pollingQueue.filter((pkg) => pkg.packageId !== element.packageId);
        }
      } catch (error) {
        log.error(`Failed to fetch progress for server ${element.serverId}`, error);
        // eslint-disable-next-line no-plusplus -- unary operator is used
        pollingFailureCountMap[element.serverId] = ++pollingFailureCountMap[element.serverId] || 1;
      }
    }

    isBuildDisabled =
      pollingQueue.length >= rootStore.packagesUiConstantsStore.maxSimultaneousPackageBuildCount;
    eventBus.emit(CREATE_PACKAGE.EVENTS.IS_BUILD_DISABLED, {
      isBuildDisabled,
    });
    if (pollingQueue.filter(checkPollingFailureCountMap).length === 0) {
      clearPollerPromise();
    }
  }, rootStore.packagesUiConstantsStore.pollingFrequency);
}

/**
 * @description Method to reset all admin package data
 */
function resetAdminPackages() {
  cancelTokenPackagesSource?.cancel();

  adminPackagesModel = undefined;
  pollingQueue = [];
  pollingFailureCountMap = {};
  isModelDirty = false;
  isBuildDisabled = false;
  isBuildDisabledForSession = false;
  isBuildDisabledPermanently = false;
  modelCache.clear(ADMIN_PACKAGES);
}

function onAdminPackagesFetchSuccess(authuserid, response) {
  pollingQueue = [];
  pollingFailureCountMap = {};
  adminPackagesModel = new PackagesAdminService(authuserid, response.data);
  initializePackageProgressPoller();
  isModelDirty = false;
}

function showDeletePackagesErrorToast(count, isCancelOperation) {
  if (isCancelOperation) {
    showError(translateString({id: 'packages.errors.allPackages.cancelPackageError'}, {count}));
  } else {
    showError(translateString({id: 'packages.errors.allPackages.removePackageError'}, {count}));
  }
}

export default PackagesAdminService;
export {resetAdminPackages, configure};
