import {Cache, log, navBus} from '@admin-tribe/binky';
import {
  DetailsButton,
  InfoItem,
  InfoItemScorecard,
  Page,
  PageActions,
  PageContent,
  PageHeader,
  PageInfoBar,
  formatFileSize,
} from '@admin-tribe/binky-ui';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {useSharedAssetModel} from '@pandora/data-model-shared-asset';
import {SharedAssetProvider} from '@pandora/react-shared-asset';
import {
  SHARED_PROJECT_ACTION,
  SHARED_PROJECT_STATE,
  SharedProjectListTableSection,
} from '@pandora/react-shared-projects';
import {EN_DASH} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import StorageStats from 'common/services/storage-stats/StorageStats';
import rootStore from 'core/RootStore';
import {PATH_STORAGE_SHARED_PROJECT_DETAILS} from 'features/storage/routing/storagePaths';
import SharedProjectsDetailsDrawer from 'features/storage/sharedProjects/SharedProjectsDetailDrawer';
import {
  SHARED_PROJECT_COUNT_CACHE_ID,
  getActiveSharedProjectCount,
} from 'features/storage/sharedProjects/sharedProjectsUtils';

const sharedProjectsPageCache = new Cache();

// The Storage "Projects" page. This was formerly "Libraries" and before that "CC workspaces".
const SharedProjectsPage = observer(() => {
  const {state = SHARED_PROJECT_STATE.ACTIVE} = useLocation();

  const intl = useIntl();
  const {getSharedAssetModel} = useSharedAssetModel();

  const [activeProjectCount, setActiveProjectCount] = useState();
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sharedAssetModel, setSharedAssetModel] = useState();
  const [totalStorage, setTotalStorage] = useState(EN_DASH);
  const [usedStorage, setUsedStorage] = useState(EN_DASH);

  const title = intl.formatMessage({id: 'storage.sharedProjects.title'});

  useDocumentTitle({defaultTitle: title});

  // get projects data to display in page info bar
  useEffect(() => {
    setIsLoading(true);

    const getSharedProjectData = async () => {
      try {
        const storageStats = await StorageStats.get();
        const usageData = storageStats.getUsageData({shouldGetUserFolders: false});
        setUsedStorage(formatFileSize(intl, usageData[0].displayAmount)); // For shared folder usage type
        setTotalStorage(formatFileSize(intl, usageData[2].displayAmount)); // For unused usage type
      } catch (error) {
        log.error(`Error getting storage stats data: ${error}`);
      }
      try {
        const sam = await getSharedAssetModel({
          applicationId: 'AdminConsole',
          applicationLocation: 'AdminConsole/Projects',
          clientId: 'ONESIE1',
        });
        const count = await getActiveSharedProjectCount(sam);

        setSharedAssetModel(sam);
        setActiveProjectCount(count);
      } catch (error) {
        log.error(`Error getting project data: ${error}`);
        setIsBumpered(true);
      } finally {
        setIsLoading(false);
      }
    };

    getSharedProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on load
  }, []);

  const onUpdateSharedProjectList = (action) => {
    if (action === SHARED_PROJECT_ACTION.CREATE || action === SHARED_PROJECT_ACTION.RESTORE) {
      setActiveProjectCount(activeProjectCount + 1);
      sharedProjectsPageCache.clear(SHARED_PROJECT_COUNT_CACHE_ID);
    } else if (action === SHARED_PROJECT_ACTION.DELETE) {
      setActiveProjectCount(activeProjectCount - 1);
      sharedProjectsPageCache.clear(SHARED_PROJECT_COUNT_CACHE_ID);
    }
  };

  // When this page is moved, this method should be renamed and moved to
  // settings/routing/navigationCallbacks.
  const goToSharedProjectDetails = (project, projectState) => {
    const path = generatePath(PATH_STORAGE_SHARED_PROJECT_DETAILS, {
      orgId: rootStore.organizationStore.activeOrgId,
      projectId: project.id,
    });
    navBus.navigate(path, {state: {projectState}});
  };

  const getSharedProjectListTableSection = (projectState) =>
    sharedAssetModel ? (
      <SharedAssetProvider sharedAssetModel={sharedAssetModel}>
        <SharedProjectListTableSection
          onUpdateSharedProjectList={onUpdateSharedProjectList}
          onViewSharedProject={(project) => {
            goToSharedProjectDetails(project, projectState);
          }}
          orgId={rootStore.organizationStore.activeOrgId}
          state={projectState}
        />
      </SharedAssetProvider>
    ) : null;

  return (
    <Page data-testid="shared-projects-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageHeader title={title} />
      <PageActions>
        <DetailsButton detailsDrawer={<SharedProjectsDetailsDrawer />} />
      </PageActions>
      <PageInfoBar>
        <InfoItem
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.used'})}
          value={usedStorage}
        />
        <InfoItem
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.available'})}
          value={totalStorage}
        />
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.active'})}
          value={activeProjectCount}
        />
      </PageInfoBar>
      <PageContent>
        <Tabs aria-label={title} defaultSelectedKey={state}>
          <TabList>
            <Item key="active">
              {intl.formatMessage({id: 'storage.sharedProjects.tab.active'})}
            </Item>
            <Item key="discarded">
              {intl.formatMessage({id: 'storage.sharedProjects.tab.inactive'})}
            </Item>
          </TabList>
          <TabPanels>
            <Item key="active">
              {getSharedProjectListTableSection(SHARED_PROJECT_STATE.ACTIVE)}
            </Item>
            <Item key="discarded">
              {getSharedProjectListTableSection(SHARED_PROJECT_STATE.DISCARDED)}
            </Item>
          </TabPanels>
        </Tabs>
      </PageContent>
    </Page>
  );
});

export default SharedProjectsPage;
