import {log} from '@admin-tribe/binky';
import {useStore} from '@admin-tribe/binky-ui';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import {useModalDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import CreatePackageFactory from '../../services/CreatePackageService';
import AdminPackages from '../../services/PackagesAdminService';
import PackagesEntitlements from '../../services/PackagesEntitlementsService';
import CreatePackageModalStore from '../../stores/CreatePackageModalStore';

import CreatePackageModal from './CreatePackageModal';
import {CreatePackageModalContextProvider} from './CreatePackageModalContext';

// Create Package Button is the trigger point for the Create package flow
const CreatePackageButton = observer(
  ({buttonLabel, isDisabled = false, setCreatePackageFactoryValues, variant = 'cta'}) => {
    const {isOpen, openModal, closeModal} = useModalDialog();
    const createPackageModalStore = useStore(() => new CreatePackageModalStore());

    const onPress = () => {
      setCreatePackageFactoryValues();
      createPackageModalStore.setStoreValuesforCreatePackageOpen();
      openModal();
    };

    const isOpenCreatePackageDisabled = () => {
      if (isDisabled) {
        log.info('CreatePackageButtonDisabled: Disabled by props.');
        return isDisabled;
      }
      if (AdminPackages.isBuildDisabled()) {
        log.info(
          'CreatePackageButtonDisabled: Build disabled because of simultaneous package count or no product available.'
        );
        return true;
      }
      const hasFrlOffers = CreatePackageFactory.hasApplicableFrlOffers();
      const hasCcOrDcEntitlement = PackagesEntitlements.hasCcOrDcEntitlement;

      log.info('CreatePackageButtonDisabled: Does org have FRL Offers:', hasFrlOffers);
      log.info(
        'CreatePackageButtonDisabled: Does org have CC or DC entitlements:',
        hasCcOrDcEntitlement
      );

      return !(hasFrlOffers || hasCcOrDcEntitlement);
    };

    return (
      <>
        <Button
          data-testid="create-package-button"
          isDisabled={isOpenCreatePackageDisabled()}
          onPress={onPress}
          variant={variant}
        >
          {buttonLabel}
        </Button>
        <DialogContainer onDismiss={closeModal}>
          {isOpen && (
            <CreatePackageModalContextProvider createPackageModalStore={createPackageModalStore}>
              <CreatePackageModal onCancel={closeModal} />
            </CreatePackageModalContextProvider>
          )}
        </DialogContainer>
      </>
    );
  }
);

CreatePackageButton.propTypes = {
  /**
   * Label that should appear on the button
   */
  buttonLabel: PropTypes.string.isRequired,
  /**
   * Indicates if the the button should be disabled. Defaults to false.
   */
  isDisabled: PropTypes.bool,
  /**
   * Function called to intialize or set required values in Create Package Factory
   */
  setCreatePackageFactoryValues: PropTypes.func.isRequired,
  /**
   * Visual style of the button. Defaults to cta.
   */
  variant: PropTypes.string,
};
export default CreatePackageButton;
