(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.hva.hva-card.assembler:HvaCardList
   * @description
   */
  angular.module('app.widgets.hva.hva-card.assembler').factory('HvaCardList', hvaCardList);

  /* @ngInject */
  function hvaCardList(_, OrganizationManager, storageUtils) {
    const DISMISSED_HVA_CARD_IDS = 'dismissedHvaCardIds';
    const COMPLETED_HVA_TASK_IDS = 'completedHvaTaskIds';
    const ARE_ALL_HVA_TASKS_COMPLETE_ID = 'areAllHvaTasksCompleteId';

    class HvaCardList {
      constructor() {
        _.assign(this, {
          completedHvaTaskSet: new Set(
            JSON.parse(storageUtils.getLocalStorageItem(COMPLETED_HVA_TASK_IDS))
          ),
          dismissedHvaCardSet: new Set(
            JSON.parse(storageUtils.getLocalStorageItem(DISMISSED_HVA_CARD_IDS))
          ),
          items: [],
          orgId: OrganizationManager.getActiveOrgId(),
        });
      }

      add(hvaCard) {
        if (!_.invoke(hvaCard, 'isValid')) {
          throw new Error('The input HvaCard is invalid.');
        }

        this.items.push(hvaCard);

        if (this.isTaskCompleted(this.type)) {
          resetHVAStateInLocalStorage(
            COMPLETED_HVA_TASK_IDS,
            getLocalStorageItemId(this.orgId, this.type),
            this.completedHvaTaskSet
          );
        }
      }

      addAll(hvaCards = []) {
        _.forEach(hvaCards, this.add.bind(this));
      }

      areAllTasksCompleteInPreviousLoad() {
        return storageUtils.getLocalStorageItem(
          getLocalStorageItemId(this.orgId, ARE_ALL_HVA_TASKS_COMPLETE_ID)
        );
      }

      getCardAtIndex(index = 0) {
        return _.get(this.items, `[${index}]`);
      }

      isCardDismissed(hvaCard = {}) {
        const dismissedCardId = getLocalStorageItemId(this.orgId, hvaCard.id);
        return this.dismissedHvaCardSet.has(dismissedCardId);
      }

      isEmpty() {
        return _.isEmpty(this.items);
      }

      isTaskCompleted(taskType) {
        if (taskType) {
          const completedHvaTaskId = getLocalStorageItemId(this.orgId, taskType);
          return this.completedHvaTaskSet.has(completedHvaTaskId);
        }

        return false;
      }

      /**
       * If there is no HVA found on load, it will save the state in local storage.
       * This logic is used to stop rendering a placeholder during the loading state
       * if there is NO HVA tile found in the previous load.
       */
      registerSavedState() {
        storageUtils.setLocalStorageItem(
          getLocalStorageItemId(this.orgId, ARE_ALL_HVA_TASKS_COMPLETE_ID),
          _.isEmpty(this.items)
        );
      }

      remove(hvaCard) {
        if (_.find(this.items, hvaCard)) {
          _.remove(this.items, hvaCard);

          storeHVAStateInLocalStorage(
            DISMISSED_HVA_CARD_IDS,
            getLocalStorageItemId(this.orgId, hvaCard.id),
            this.dismissedHvaCardSet
          );

          const isTaskCompleted = _.isEmpty(this.items) && this.type;
          if (isTaskCompleted) {
            storeHVAStateInLocalStorage(
              COMPLETED_HVA_TASK_IDS,
              getLocalStorageItemId(this.orgId, this.type),
              this.completedHvaTaskSet
            );
          }
        }
      }

      setType(type) {
        this.type = type;
      }

      static isTaskCompleted(taskType) {
        const model = new HvaCardList();
        return model.isTaskCompleted(taskType);
      }

      static areAllTasksCompleteInPreviousLoad() {
        const model = new HvaCardList();
        return model.areAllTasksCompleteInPreviousLoad();
      }
    }

    return HvaCardList;

    /* Private Functions */

    function getLocalStorageItemId(orgId, itemId) {
      return `${orgId}_${itemId}`;
    }

    function storeHVAStateInLocalStorage(storageKey, itemKey, set) {
      updateHVAStateInLocalStorage(storageKey, itemKey, set, 'add');
    }

    function resetHVAStateInLocalStorage(storageKey, itemKey, set) {
      updateHVAStateInLocalStorage(storageKey, itemKey, set, 'delete');
    }

    function updateHVAStateInLocalStorage(storageKey, itemKey, set, operationKey) {
      _.invoke(set, operationKey, itemKey);
      storageUtils.setLocalStorageItem(storageKey, JSON.stringify([...set]));
    }
  }
})();
