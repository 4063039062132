(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets:appSupportCaseListTable
   * @description Component to display list of support cases, either open or closed, in a table.
   *   The search, is a simple case-insensitive, one-column search.
   *
   * @binding {Object} display. The columns to show.
   * @binding {Integer} [itemsPerPage]. Specify if there should be NOT be a paginator at the bottom of the table.
   *  Do not specify this if paginationId is specified.
   * @binding {String} [paginationId]. Specify if there should be a paginator at the bottom of the table.
   * @binding {String} [searchText]. Default is no search. The string to search for. Whitespace is assumed to have
   *   been trimmed already.
   * @binding {SUPPORT_CASE_SORT_ORDER} [sortOrder]. Default is DEFAULT which is by date created.
   *   Open cases should be sorted by priority and should specify 'PRIORITY'.
   * @binding {Object} supportCaseList. A support case list. It could be a list of open cases, a list of open cases
   *   filtered for the logged-in admin or a list of closed cases. No assumptions are made here about its contents.
   */
  angular.module('app.widgets.support').component('appSupportCaseListTable', {
    bindings: {
      display: '<?',
      itemsPerPage: '<?',
      paginationId: '<?',
      searchText: '<?',
      sortOrder: '@?',
      supportCaseList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-case-list-table/support-case-list-table.component.html',
  });

  /* @ngInject */
  function controller(
    binkyDateTimeFilter,
    $filter,
    _,
    onesieSrc2,
    LIST_DEFAULT_PAGE_SIZE,
    CLOSED_SUPPORT_CASE_LIST_PAGINATION_ID,
    OPEN_SUPPORT_CASE_LIST_PAGINATION_ID,
    SUPPORT_CASE_PRIORITY,
    SUPPORT_CASE_SORT_ORDER,
    SUPPORT_TICKET_CASE_TYPE,
    SUPPORT_TICKET_UI_STATUS
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      closeTooltip,
      currentPage: 1,
      getCaseAdminText,
      getCaseDateCreatedText,
      getCasePriorityText,
      getCaseProductNameText,
      getCaseStatusText,
      onClickRow,
      onColumnSort,
      onPageSizeChange,
      openTooltip,
      SORT_ADMIN: 'adminFullname',
      SORT_DATE_CREATED: 'createdOn',
      SORT_PRIORITY: 'sortValuePriority',
      SORT_PRODUCT: 'productName',
      SORT_STATUS: 'sortValueStatus',
      sortAscending: undefined,
      SUPPORT_TICKET_CASE_TYPE,
      SupportTicketStatus: onesieSrc2.support.components.SupportTicketStatus,
      tableFilterFn,
      tooltipOpenIndex: -1,
    });

    function $onInit() {
      vm.columnCount = _.keys(vm.display).length; // equals 0 if vm.display is undefined
      vm.itemsPerPage = vm.itemsPerPage || LIST_DEFAULT_PAGE_SIZE;
      vm.sortOrder = vm.sortOrder || SUPPORT_CASE_SORT_ORDER.DEFAULT;

      if (vm.sortOrder === SUPPORT_CASE_SORT_ORDER.PRIORITY) {
        // Default ordering of the open case table should be based on the following by order of importance
        // 1) Status: Sort value status for support cases
        // 2) Priority: Escalated, P1, P2, P3, P4.
        // 3) Date created: most recent first - ISO 8601 dates are designed to be sorted as strings.
        // 4) Admin name: admin fullname
        // See the documentation of orderByFilter expression to learn how this works.
        vm.orderByExpression = [
          getSortValueStatus,
          getSortValuePriority,
          `-${vm.SORT_DATE_CREATED}`,
          setSortValueAdminName,
        ];
      } else {
        // Default ordering of the closed case table should be based on:
        // 1) Date created: most recent first - ISO 8601 dates are designed to be sorted as strings.
        // 2) Status: Sort value status for support cases
        // 3) Priority: Escalated, P1, P2, P3, P4.
        // 4) Admin name: admin fullname
        vm.orderByExpression = [
          `-${vm.SORT_DATE_CREATED}`,
          getSortValueStatus,
          getSortValuePriority,
          setSortValueAdminName,
        ];
      }
    }

    function getCaseAdminText(supportCase) {
      return $filter('appSupportTicketPersonName')(supportCase.admin);
    }

    function getCaseDateCreatedText(supportCase) {
      return binkyDateTimeFilter(supportCase.createdOn, {dateOnly: true});
    }

    function getCasePriorityText(supportCase) {
      return $filter('appSupportCasePriority')(supportCase.priority, supportCase.isEscalated);
    }

    function getCaseStatusText(supportCase) {
      return $filter('appSupportTicketStatus')(supportCase);
    }

    function getCaseProductNameText(supportCase) {
      supportCase.productName = supportCase.getProductName();
      return supportCase.productName;
    }

    function onClickRow(event /* supportCase */) {
      if (wasClickOnDetailsLink(event)) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      // open drawer here
    }

    // The currently sorted column is vm.sortField. vm.sortAscending applies to the current vm.sortField.
    // sortAscending is used to add classes to the currently sorted column header to control the display of the
    // up-arrow/down-arrow, and the hover state.
    function onColumnSort(field, options = {}) {
      const opts = _.defaults(options, {
        sortAscending: field !== vm.SORT_DATE_CREATED, // dates default to descending, all others to ascending
      });
      if (vm.sortField === field) {
        vm.sortAscending = !vm.sortAscending;
      } else {
        vm.sortAscending = opts.sortAscending;
        vm.sortField = field;
      }

      // Once a column header is clicked to sort on a column the default sort is no longer in play and
      // the sort is just on this column.
      vm.orderByExpression = vm.sortAscending ? `+${field}` : `-${field}`;
    }

    function onPageSizeChange(pageSize) {
      vm.currentPage = 1;
      vm.itemsPerPage = pageSize;
    }

    // Called by the filterFilter to search for the searchText string in a support case.
    // Match is case-insensitive and does not span columns. Multiple words are treated as one string.
    // Return true to include item/row in the results.
    function tableFilterFn(supportCase) {
      if (!vm.searchText) {
        return true;
      }

      vm.searchText = _.toLower(vm.searchText); // whitespace stripped by search component

      // Should be in order of what we think will be searched for most often.
      return (
        includesText(getCaseAdminText(supportCase), vm.searchText) ||
        includesText(supportCase.title, vm.searchText) ||
        includesText(getCasePriorityText(supportCase), vm.searchText) ||
        includesText(supportCase.getProductName(), vm.searchText) ||
        includesText(getCaseStatusText(supportCase), vm.searchText) ||
        includesText(getCaseDateCreatedText(supportCase), vm.searchText) ||
        includesText(supportCase.id, vm.searchText)
      );
    }

    ////////////

    // Priority: Escalated, P1, P2, P3, P4.
    // Return the numeric sort value for this item's priority.
    function getSortValuePriority(supportCase) {
      if (supportCase.isEscalated) {
        supportCase.sortValuePriority = 0;
        return supportCase.sortValuePriority;
      }

      const prioritySortOrder = [
        SUPPORT_CASE_PRIORITY.P1,
        SUPPORT_CASE_PRIORITY.P2,
        SUPPORT_CASE_PRIORITY.P3,
        SUPPORT_CASE_PRIORITY.P4,
      ];

      // Returns 1-4 or null.
      // The default angular sort, sorts null values last. Ie. null is considered '>' any other value.
      supportCase.sortValuePriority = _.includes(prioritySortOrder, supportCase.priority)
        ? _.indexOf(prioritySortOrder, supportCase.priority) + 1
        : null;
      return supportCase.sortValuePriority;
    }

    // Sort value status for support cases
    // Return the numeric sort value for this items's status.
    function getSortValueStatus(supportCase) {
      const uiStatusSortOrder = [
        SUPPORT_TICKET_UI_STATUS.PENDING_RESPONSE,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_PROVIDED,
        SUPPORT_TICKET_UI_STATUS.AWAITING_ASSIGNMENT,
        SUPPORT_TICKET_UI_STATUS.IN_PROGRESS,
        SUPPORT_TICKET_UI_STATUS.SESSION_SCHEDULED,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_CONFIRMED,
        SUPPORT_TICKET_UI_STATUS.RESOLUTION_IRRELEVANT,
        SUPPORT_TICKET_UI_STATUS.SESSION_COMPLETED,
        SUPPORT_TICKET_UI_STATUS.ISSUE_OBSOLETE,
        SUPPORT_TICKET_UI_STATUS.MERGED,
        SUPPORT_TICKET_UI_STATUS.AUTO_CLOSED,
        SUPPORT_TICKET_UI_STATUS.CANCEL_MISROUTE,
      ];

      const uiStatus = supportCase.getUiStatus();
      supportCase.sortValueStatus = _.includes(uiStatusSortOrder, uiStatus)
        ? _.indexOf(uiStatusSortOrder, uiStatus)
        : uiStatusSortOrder.length;
      return supportCase.sortValueStatus;
    }

    // AdminName: get first and last name from support.admin
    // Set the filtered value for this items's adminFullname.
    function setSortValueAdminName(supportCase) {
      supportCase.adminFullname = $filter('appSupportTicketPersonName')(supportCase.admin);
    }

    // Returns true if case-insensitive search finds 'value' includes 'searchText'.
    function includesText(value, searchText) {
      return _(value).toLower().includes(searchText);
    }

    function wasClickOnDetailsLink(event) {
      if (event.target) {
        const targetEl = angular.element(event.target);
        return targetEl.hasClass('details-link');
      }
      return false;
    }

    function closeTooltip() {
      vm.tooltipOpenIndex = -1;
    }

    function openTooltip(index) {
      vm.tooltipOpenIndex = index;
    }
  }
})();
