(function () {
  /**
   * @ngdoc module
   * @name app.widgets.identity
   * @description module that defines app.widgets.identity widgets
   */
  angular.module('app.widgets.identity', [
    'app.widgets.directories',
    'binky.core.lodash',
    'binky.shell.panels.manager',
    'pascalprecht.translate',
  ]);
})();
