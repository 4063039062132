/**
 * @deprecated with temp_react_port_users
 *
 * @ngdoc component
 * @name app.widgets.users:appRemoveUserStorageInfoSection
 *
 * @description The section for remove user storage modal, display the list of user to be removed.
 *
 */
(function () {
  angular.module('app.widgets.users').component('appRemoveUserStorageInfoSection', {
    bindings: {
      userList: '<',
      userStorageList: '<',
    },
    controller,
    templateUrl:
      'app/widgets/users/remove-user-storage-modal/info-section/remove-user-storage-info-section.component.html',
  });

  /* @ngInject */
  function controller(_, FileSize) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      _.assign(vm, {
        getStorageQuota,
        hasStorage,
      });
    }

    function getStorageQuota(user) {
      return FileSize.bytesToString(
        _.get(_.find(vm.userStorageList, {name: user.id}), 'quota.consumed'),
        1
      );
    }

    function hasStorage(user) {
      return _.find(vm.userStorageList, {name: user.id});
    }
  }
})();
