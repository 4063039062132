(function () {
  /**
   * @ngdoc module
   * @name app.core.search.user-list
   * @description module that defines the SearchUserList model
   */
  angular.module('app.core.search.user-list', [
    'app.core',
    'app.core.organizations',
    'binky.core.api.jil',
    'binky.core.common.member',
    'binky.core.lodash',
    'binky.core.page-context',
  ]);
})();
