import {Page, PageBreadcrumbs, PageContent, PageHeader, PageNav} from '@admin-tribe/binky-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {TableActions, TableFilters, TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import {JOB_RESULTS_FILTER} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import Job from 'common/services/job/Job';
import JobResultList from 'common/services/job-result-list/JobResultList';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import JobResultsFilter from 'features/bulk-operations/components/job-results/JobResultsFilter';
import JobResultsListTable from 'features/bulk-operations/components/job-results/JobResultsListTable';
import JobResultsTableActions from 'features/bulk-operations/components/job-results/JobResultsTableActions';
import useBulkOpsBreadcrumbs from 'features/bulk-operations/hooks/useBulkOpsBreadcrumbs';

/**
 * The details for a bulk operation job.
 * Since the JobResultList isn't paginated this table is not paginated.
 */
const JobResultsPage = ({path}) => {
  const pathParams = useParams();
  const {jobId} = pathParams;
  const intl = useIntl();
  const [resultItems, setResultItems] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);

  useDocumentTitle({key: 'bulkOperations.results.title'});

  const fetchJobResultsList = useCallback(
    () =>
      JobResultList.get({
        jobId,
      }),
    [jobId]
  );
  const {
    model: jobResults,
    isLoading: isLoadingJobResults,
    error: errorJobResults,
  } = useAsyncModel({loadFn: fetchJobResultsList, modelLogId: 'JobResultList'});

  const fetchJob = useCallback(
    () =>
      Job.get({
        id: jobId,
      }),
    [jobId]
  );
  const {
    model: job,
    isLoading: isLoadingJobs,
    error: errorJobs,
  } = useAsyncModel({loadFn: fetchJob, modelLogId: 'Job'});

  // Hook returns null for each property until breadcrumbs are returned.
  const {breadcrumbs, onAction: onBreadcrumbsAction} = useBulkOpsBreadcrumbs({
    intl,
    jobResultsLabel: job?.getResultHeading(intl) ?? null,
    path,
  });

  const isLoading = isLoadingJobResults || isLoadingJobs || breadcrumbs === null;
  const error = errorJobResults ?? errorJobs;

  // Set the table items depending on the value of the results filter.
  const filterResults = useCallback(
    (key) => {
      setSelectedKey(key);
      if (key === JOB_RESULTS_FILTER.FAILURES) {
        setResultItems(jobResults.getFailedResultItems());
      } else {
        setResultItems(jobResults.items);
      }
    },
    [jobResults]
  );

  // Once the both lists load, intialize the filter and table items.
  useEffect(() => {
    if (!isLoading && !error && jobResults) {
      filterResults(JOB_RESULTS_FILTER.ALL);
    }
  }, [error, filterResults, isLoading, jobResults]);

  return (
    <Page data-testid="job-results-report-page" isBumpered={!!error} isLoading={isLoading}>
      <PageHeader title={job?.getResultHeading(intl) ?? ''} />
      {breadcrumbs && (
        <PageBreadcrumbs
          isDisabled={false}
          items={breadcrumbs}
          onAction={(key) => onBreadcrumbsAction({breadcrumbs, key})}
        />
      )}
      <PageNav />
      <PageContent>
        {resultItems && (
          <TableSection items={resultItems} pageNumber={0}>
            <TableFilters hasSearch={false}>
              <JobResultsFilter onSelectionChange={filterResults} selectedKey={selectedKey} />
            </TableFilters>
            {!isReadOnly() && (
              <TableActions>
                <JobResultsTableActions path={path} />
              </TableActions>
            )}
            <JobResultsListTable path={path} pathParams={pathParams} />
          </TableSection>
        )}
      </PageContent>
    </Page>
  );
};

JobResultsPage.propTypes = {
  /**
   * The React router path used to reach this page.
   * For example, PATH_USERS_BULK_OPERATIONS_RESULTS.
   */
  path: PropTypes.string.isRequired,
};

export default JobResultsPage;
