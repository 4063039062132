import {download, jilDirectories, log} from '@admin-tribe/binky';
import {
  OverlayWait,
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
  useStore,
} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup, DialogContainer, SearchField} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {DirectoryContextProvider} from 'features/settings/components/directory/DirectoryContext';
import AddDomainButton from 'features/settings/components/directory/domains/add-domain-button/AddDomainButton';
import DirectoryDomainsTable from 'features/settings/components/directory/domains/directory-domains-table/DirectoryDomainsTable';
import ImportFederatedDomainsModal from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModal';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

import './DirectoryDomains.pcss';

const DirectoryDomains = observer(
  ({directoryId, domainEnforcement, directoryName, refreshDomains, isType2E}) => {
    const directoryStore = useStore(() => new DirectoryStore(directoryId));
    const [federatedType, setFederatedType] = useState('');
    const [authData, setAuthData] = useState({});
    const [searchInputValue, setSearchInputValue] = useState('');
    const [isImportFederatedDomainsModalOpen, setIsImportFederatedDomainsModalOpen] =
      useState(null);
    const [isExportingToCsv, setIsExportingToCsv] = useState(false);
    const initialLoad = useRef(true);
    const intl = useIntl();

    // Load data needed for the component
    useEffect(() => {
      const loadData = async () => {
        await directoryStore.domains.load();
        initialLoad.current = false;
      };

      loadData();
    }, [directoryStore]);

    // Check if we are in the middle of a return
    // from a federated login and open the import
    // federated domains modal if so
    useEffect(() => {
      if (ExternalAuthService.isLoginInProgress()) {
        const authenticationData = ExternalAuthService.completeAuthentication();
        setFederatedType(authenticationData.data?.federationType);

        setAuthData(authenticationData);
        setIsImportFederatedDomainsModalOpen(true);
      }
    }, []);

    // Event handlers
    const onSearchSubmit = (value) => {
      directoryStore.domains.search(value);
    };

    const onSearchValueChange = (value) => {
      setSearchInputValue(value);
    };

    const onSearchValueCleared = () => {
      onSearchSubmit('');
    };

    const onImportSuccess = () => {
      directoryStore.domains.load();
      refreshDomains({clearCache: true});
    };

    const onExportToCsv = useCallback(async () => {
      setIsExportingToCsv(true);
      const orgId = rootStore.organizationStore.activeOrgId;

      try {
        showInfoToast(intl.formatMessage({id: 'common.toast.csvDownloadBeingPrepared'}));

        const response = await jilDirectories.exportDirectoryDomainsToCSV({
          directoryId,
          orgId: rootStore.organizationStore.activeOrgId,
        });

        if (!response?.data?.file || response.data.file.size === 0) {
          throw new Error('Received empty directory domains list response from server');
        }

        download(response.data.file, `domains-${directoryName}.csv`);

        showSuccessToast(intl.formatMessage({id: 'common.toast.csvDownloadSuccess'}));
      } catch (error) {
        log.error(
          `Failed to download domain list CSV for directory ${directoryId} in org ${orgId}. Error: `,
          error
        );
        showErrorToast(intl.formatMessage({id: 'common.toast.downloadError'}));
      } finally {
        setIsExportingToCsv(false);
      }
    }, [directoryId, directoryName, intl]);

    return (
      <DirectoryContextProvider directoryStore={directoryStore}>
        <div styleName="domains-header">
          <div data-testid="directory-domains-search">
            <SearchField
              aria-label={intl.formatMessage({
                id: 'settings.directoryDomains.searchPlaceholder',
              })}
              onChange={onSearchValueChange}
              onClear={onSearchValueCleared}
              onSubmit={onSearchSubmit}
              placeholder={intl.formatMessage({
                id: 'settings.directoryDomains.searchPlaceholder',
              })}
              value={searchInputValue}
            />
          </div>
          <ButtonGroup>
            {!isType2E && (
              <AddDomainButton
                directoryId={directoryStore.directoryId}
                refreshDomains={refreshDomains}
              />
            )}
            <Button
              data-testid="export-to-csv"
              isDisabled={isExportingToCsv}
              onPress={() => onExportToCsv()}
              variant="primary"
            >
              <FormattedMessage id="settings.directoryDomains.exportToCsv" />
            </Button>
          </ButtonGroup>
        </div>

        <OverlayWait
          isLoading={directoryStore.domains.isLoading}
          showContent={!initialLoad.current}
          size={(initialLoad.current && 'L') || undefined}
        >
          <DirectoryDomainsTable refreshDomains={refreshDomains} />
        </OverlayWait>
        <DialogContainer
          onDismiss={() => {
            setIsImportFederatedDomainsModalOpen(false);
          }}
        >
          {isImportFederatedDomainsModalOpen && (
            <ImportFederatedDomainsModal
              authSourceId={directoryStore.directoryId}
              domainEnforcement={domainEnforcement}
              externalToken={authData.token}
              federatedType={federatedType}
              onImportSuccess={onImportSuccess}
            />
          )}
        </DialogContainer>
      </DirectoryContextProvider>
    );
  }
);

DirectoryDomains.propTypes = {
  directoryId: PropTypes.string,
  directoryName: PropTypes.string,
  isType2E: PropTypes.bool,
  refreshDomains: PropTypes.func,
};

export default DirectoryDomains;
