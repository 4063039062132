import React from 'react';

/**
 * @deprecated. Use binky-ui's useModalDialog.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- khnguye@ to update
const useModal = (initialMode = false) => {
  const [isOpen, setIsOpen] = React.useState(initialMode);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  return [isOpen, openModal, closeModal];
};

export default useModal;
