(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectoryCreateEnhancedSamlModal', {
    controller,
    templateUrl:
      'app/widgets/directories/create-enhanced-saml/directory-create-enhanced-saml-modal.component.html',
  });

  function controller(
    $scope,
    $state,
    $timeout,
    $q,
    _,
    APP_CREATE_DIRECTORY_MODAL_ID,
    APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID,
    ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS,
    panelManager
  ) {
    // Stores opacity transition duration for `._coral-Dialog` (delay + transition)
    const MODAL_TRANSITION_DURATION = 410;

    const vm = this;

    _.assign(vm, {
      $onInit,
      close,
      onConfigurationComplete,
      onConfigurationProcessingError,
      onConfigurationProcessingStarted,
      onConfigurationValidationStatusChanged,
      onOpen,
      save,
    });

    function $onInit() {
      _.assign(vm, {
        modalId: APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID,
        waitPromise: $q.resolve(),
      });
    }

    function onOpen(params = {}) {
      if (vm.authSource) {
        resetModal();
      }

      vm.authSource = params.authSource;

      // Close create directory modal if it's still open underneath.
      // Wrapping it in a timeout call in order to prevent flicker while modal is animating in.
      if (panelManager.isOpen(APP_CREATE_DIRECTORY_MODAL_ID)) {
        $timeout(
          () => panelManager.close(APP_CREATE_DIRECTORY_MODAL_ID),
          MODAL_TRANSITION_DURATION
        );
      }
    }

    function close() {
      panelManager.close(APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID);

      // Reset modal in the next digest to prevent user from briefly seeing
      // the reset state of the modal upon closing it.
      $timeout(resetModal, MODAL_TRANSITION_DURATION);
    }

    function save() {
      $scope.$broadcast(ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS.SAVE_ENHANCED_SAML_CONFIGURATION);
    }

    // Configuration events handlers
    function onConfigurationProcessingStarted() {
      vm.waitPromise = $q.defer().promise;
    }

    function onConfigurationProcessingError() {
      vm.waitPromise = $q.resolve();
    }

    function onConfigurationComplete() {
      const directoryId = vm.authSource.id;
      $state.go('settings.directory-details', {directoryId}).finally(close);
    }

    function onConfigurationValidationStatusChanged(isValid) {
      vm.isValid = isValid;
    }

    /////////////

    function resetModal() {
      _.assign(vm, {
        authSource: null,
        isValid: false,
        waitPromise: $q.resolve(),
      });
    }
  }
})();
