import {Product} from '@admin-tribe/binky';
import {Header, Heading, Text} from '@adobe/react-spectrum';
import {DateFormat, FormattedDateTime} from '@pandora/react-formatted-date-time';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {getRenewalSummary} from 'core/contract/contract-renewal/contractRenewalUtils';

/**
 * Top portion of drawer above list of devices that need renewal
 */
const DeviceRenewalsDrawerHeader = ({product}) => {
  const intl = useIntl();
  const renewalSummary = getRenewalSummary(product);
  const {isInPreAnniversaryRenewalWindow, anniversaryDate, renewalWindowEndDate, resellerName} =
    renewalSummary;
  const renewalSummaryDate = isInPreAnniversaryRenewalWindow
    ? anniversaryDate
    : renewalWindowEndDate;

  const resellerId = `products.devices.renewalsDrawer.${
    renewalSummary.isInPreAnniversaryRenewalWindow
      ? 'preAnniversaryPartialRenewal.additionalRenewal'
      : 'postAnniversaryPartialRenewal.placeAnOrder'
  }`;

  return (
    <Header>
      <Heading level={2}>
        {intl.formatMessage({id: 'products.devices.renewalsDrawer.title'})}
      </Heading>
      {renewalSummaryDate && (
        <Text>
          <FormattedMessage
            id="products.devices.renewalsDrawer.paragraph"
            values={{
              date: <FormattedDateTime date={renewalSummaryDate} format={DateFormat.DateAndTime} />,
            }}
          />
        </Text>
      )}
      <br />
      <br />
      <Text>
        <FormattedMessage
          id={resellerId}
          values={{
            sellerName: resellerName,
          }}
        />
      </Text>
    </Header>
  );
};

DeviceRenewalsDrawerHeader.propTypes = {
  /**
   * A Product instance
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default DeviceRenewalsDrawerHeader;
