import {Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';

import IdpCard from '../idp-card/IdpCard';

const IdpList = observer(({idps, refreshData, ...actions}) => (
  <Flex gap="size-150" wrap>
    {idps.map((idp) => (
      <IdpCard
        key={idp.id}
        editIdp={actions.editIdp}
        idp={idp}
        makeDefaultIdp={actions.makeDefaultIdp}
        refreshData={refreshData}
        removeIdp={actions.removeIdp}
        updateIdpStatusConfirm={actions.updateIdpStatusConfirm}
      />
    ))}
  </Flex>
));

export default IdpList;
