(function () {
  const componentName = 'appSophiaCardRenewalChat';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.sophia:appSophiaCardRenewalChat
   * @description component to display content from a Sophia card returned for the start chat banner in the renew products modal.
   * @param {Object} card object containing description of display properties (content and style)
   * See [API Spec]{@link https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ADBPersonalization&title=Execution+Layer+API+Spec} for card schema
   */
  angular.module('app.widgets.sophia').component(componentName, {
    bindings: {
      card: '<',
    },
    controller,
    templateUrl: 'app/widgets/sophia/card/renewal-chat/sophia-card-renewal-chat.component.html',
  });

  /* @ngInject */
  function controller(_, $translate, auth, CHAT_APP_ID, feature, onesieSrc2, SophiaCardHelper) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      CHAT_APP_ID,
      isEnabled: feature.isEnabled,
      useDefaultBanner: shouldUseDefaultBanner(vm.card.getCard()),
    });

    /////////////

    function $onInit() {
      const card = vm.useDefaultBanner ? getDefaultBanner() : vm.card.getCard();
      _.assign(vm, {
        cardContent: SophiaCardHelper.getContentObject(card),
        StartChatButton: onesieSrc2.support.components.StartChatButton,
        style: SophiaCardHelper.getStyleObj(card),
      });
      vm.analyticsEvent = getAnalyticsEvent();
    }

    /////////////

    function getAnalyticsEvent() {
      const {cardControlAnalyticsParams, containerAnalyticsParams = {}} = vm.card;
      containerAnalyticsParams.cardId = vm.card.getCard().cardID;
      const event = {
        attributes: {
          cardControlAnalyticsParams,
          containerAnalyticsParams,
        },
        cgen: 'KCJMVN16',
        componentMethod: 'sophia-banner-click',
        componentMethodType: 'display',
        componentName,
      };
      return event;
    }

    function getDefaultBanner() {
      const userProfile = auth.getUserProfile();
      const firstName = _.get(userProfile, 'first_name');
      const displayText = _.isEmpty(firstName)
        ? $translate.instant('widgets.sophia.renewalChat.bannerWithoutFirstName')
        : $translate.instant('widgets.sophia.renewalChat.bannerWithFirstName', {firstName});
      return {
        ctaAlignment: 'right',
        displayText,
        displayTextAlignment: 'left',
      };
    }

    function shouldUseDefaultBanner(card) {
      return (
        card.cardID ===
        // Due to time constraint, we decided to store the banner locally instead of creating them in AEM (Adobe Experience Manager).
        // In order to use Sophia to decide whom to show the banner, we need to return an AEM card from the Sophia campaign.
        // The current setup is to return a fake AEM card and the UI will check whether the returned card matches the one we setup.
        // If it does, we will use the local stored banner.
        '/content/help/en/ccx/v1/offers/trial/width/2/segment/default/oneconsole-chat-for-renewal-fake'
      );
    }
  }
})();
