(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name DirectoryUserListCount
   * @description Model for directory user list count
   */
  angular
    .module('app.core.directories.user-list')
    .factory('DirectoryUserListCount', getDirectoryUserListCount);

  /* @ngInject */
  function getDirectoryUserListCount(
    $log,
    $q,
    $rootScope,
    _,
    binkySrc2,
    JIL_CONSTANTS,
    MESSAGE,
    MODEL,
    modelCache,
    modelCacheUtils
  ) {
    class DirectoryUserListCount {
      /**
       * @description Constructor for DirectoryUserListCount model Objects.
       * @param {Object} options - Options
       * @param {String} options.directoryId - Directory ID
       * @param {String} options.orgId - Organization ID
       */
      constructor(options = {}) {
        _.assignIn(this, toMinimumObject(options));
        this.totalUserCount = undefined;
      }

      /**
       * @description Generate a unique key based on select properties, see toMinimumObject()
       *
       * @returns {String} Unique key
       */
      key() {
        return modelCacheUtils.getParameterizedKey(toMinimumObject(this));
      }

      /**
       * @description Refresh the current instance
       *
       * @returns {Promise} Return a Promise, which will resolve with this instance
       * and totalUserCount is set to the directory user count
       */
      refresh() {
        return $q((resolve, reject) => {
          binkySrc2.api.jil.jilDirectories
            .headUsers({directoryId: this.directoryId, orgId: this.orgId})
            .then((response) => {
              // Ensure totalCount to either be a Number (including 0) or undefined (avoid NaN)
              const parsedNumber = Number.parseInt(
                response.headers[JIL_CONSTANTS.HEADERS.TOTAL_COUNT],
                10
              );
              this.totalUserCount = _.isNaN(parsedNumber) ? undefined : parsedNumber;

              modelCache.put(MODEL.DIRECTORYUSERLISTCOUNT, this, this.key());

              $rootScope.$emit(MESSAGE.UPDATE.DIRECTORYUSERS.COUNT, this.totalUserCount);

              resolve(this);
            })
            .catch((error) => {
              this.totalUserCount = undefined;

              $log.error(`Failed to obtain count for directory ${this.directoryId}. Error:`, error);

              reject(error);
            });
        });
      }

      /**
       * Return an instance based on options
       * @param {Object} options - Options
       * @param {String} options.directoryId - Directory ID
       * @param {String} options.orgId - Organization ID
       *
       * @returns {Promise} Return a Promise, which will resolve with this instance
       * and totalUserCount is set to the directory user count
       */
      static get(options = {}) {
        const model = new DirectoryUserListCount(options);
        const cachedModel = modelCache.get(MODEL.DIRECTORYUSERLISTCOUNT, model.key());

        if (cachedModel) {
          return $q.resolve(cachedModel);
        }

        return model.refresh();
      }

      /**
       * Get the actual total user count
       * @param {Object} options - Options
       * @param {String} options.directoryId - Directory ID
       * @param {String} options.orgId - Organization ID
       *
       * @returns {Promise} Return a Promise which will resolve to the count
       * , or undefined if there's something wrong
       */
      static getTotalUserCount(options) {
        return DirectoryUserListCount.get(options)
          .then((model) => model.totalUserCount)
          .catch(_.noop);
      }
    }

    modelCache.register(MODEL.DIRECTORYUSERLISTCOUNT, 2);

    // This is a very simple caching strategy.
    // It could be enhanced to add/update a cache entry for a particular orgId/directoryId.
    modelCache.removeAllOnEvent(MODEL.DIRECTORYUSERLISTCOUNT, [
      MESSAGE.UPDATE.DIRECTORYUSERS.COUNT,
    ]);

    return DirectoryUserListCount;

    ////////

    function toMinimumObject(object) {
      return _.pick(object, ['directoryId', 'orgId']);
    }
  }
})();
