import {OrganizationUserList, feature} from '@admin-tribe/binky';
import {useAsyncModel} from '@admin-tribe/binky-ui';
import {useCallback} from 'react';

import ProductUserList from 'core/services/product/ProductUserList';

import {isUserCancellable} from '../../SelfCancelUtils';

/**
 * @description A helper hook to load ProductUserList model and retrieve the array of users that can
 * be removed. It will also maintain the whether is loading and the error state.
 * @param {Object} options - set up options, described below
 * @param {String} options.orgId - the Organization Id
 * @param {String} options.productId - the Product for which return user, described by its id.
 * @returns {Object} state - Object composed of state variables: assignedUsers, error, isLoading.
 *          {User[]} state.assignedUsers - the array of users assigned to the Product in the Org.
 *          {Boolean} state.isLoading - Whether data is currently being loaded.
 *          {Object} state.error - If loading data failed, then this contains the error that occurred.
 */
const useProductUsersForCancellation = ({orgId, productId}) => {
  // We need to use useCallback so that React doesn't create a new function on every render cycle.
  // Calling APIs in this manner to run these awaits concurrently.
  const loadProductUserList = useCallback(async () => {
    // Load Product User List for current contract and product id
    const retrievedProductUserList = await ProductUserList.get({
      orgId,
      productId,
    });

    // Load Organization User list to get products assign info
    const orgUserList = await OrganizationUserList.get({orgId});

    // Check whether a user has a current product assigned. Return true if user has a current product assigned.
    const hasProductAssigned = (user) =>
      feature.isDisabled('temp_filter_no_product_assigned_user') ||
      !!orgUserList.items?.some(
        (orgUser) =>
          user.id === orgUser.id && orgUser.products?.some((product) => product.id === productId)
      );

    // Remove markedForRemoval users
    return retrievedProductUserList.items.filter(isUserCancellable).filter(hasProductAssigned);
  }, [orgId, productId]);

  // Configure useAsyncModel to load the ProductUserList model
  const {model, isLoading, error} = useAsyncModel({
    initState: [],
    loadFn: loadProductUserList,
  });

  return {cancellableUsers: model, error, isLoading};
};

export default useProductUsersForCancellation;
