import {differenceInMilliseconds} from 'date-fns';

import samlCertificates from 'features/settings/api/samlCertificates';
import {
  CERTIFICATE_TYPES,
  SAML_CERTIFICATE_STATUS,
  TIME_UNTIL_EXPIRATION,
} from 'features/settings/common/constants/samlCertificatesConstants';

const ADOBE_ISSUERS = [
  'federatedid-na1.services.adobe.com',
  'federatedid-na1-stg1.services.adobe.com',
];

class CertificateModel {
  static fromObject(data) {
    return new CertificateModel(data);
  }

  constructor(certificateData) {
    this.id = certificateData.id;
    this.directoryId = certificateData.directoryId;
    this.idpId = certificateData.idpId;
    this.status = certificateData.status;
    this.certificateType = certificateData.type;
    this.certificateCreatedAt = certificateData.certificateCreatedAt;
    this.certificateExpiresAt = certificateData.certificateExpiresAt;
    this.createdAt = certificateData.createdAt;
    this.certificate = certificateData.certificate;
  }

  checkIsDefault(idpSpDefaultKey) {
    return this.id === idpSpDefaultKey;
  }

  get downloadUrl() {
    return samlCertificates.getCertificateDownloadUrl({
      certificateId: this.id,
      directoryId: this.directoryId,
      idpId: this.idpId,
    });
  }

  get isActive() {
    return this.status === SAML_CERTIFICATE_STATUS.ACTIVE;
  }

  isAdobeSigned() {
    try {
      const decodedCertificate = atob(this.certificate);

      return ADOBE_ISSUERS.some((issuer) => decodedCertificate.includes(issuer));
    } catch {
      // if something happens, and we cannot decode the certificate, we assume it is adobe signed
      return true;
    }
  }

  get isDisabled() {
    return this.status === SAML_CERTIFICATE_STATUS.DISABLED;
  }

  get isExpired() {
    return differenceInMilliseconds(this.certificateExpiresAt, Date.now()) <= 0;
  }

  get isExpiring() {
    return differenceInMilliseconds(this.certificateExpiresAt, Date.now()) <= TIME_UNTIL_EXPIRATION;
  }

  get type() {
    if (this.certificateType !== CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST) {
      if (this.isAdobeSigned()) {
        return CERTIFICATE_TYPES.ADOBE_SIGNED;
      }

      return CERTIFICATE_TYPES.CA_SIGNED;
    }

    return this.certificateType;
  }
}

export default CertificateModel;
