import {Heading} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {ImageIcon} from '@pandora/react-image-icon';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './SyncSourceCard.pcss';

const SyncSourceCard = ({logo, name, selected, syncType}) => {
  const intl = useIntl();

  return (
    <Card
      allowsSelection={false}
      data-testid={`sync-card-${syncType.toLowerCase()}`}
      selected={selected}
      size="L"
      styleName="provider-card"
    >
      <div styleName="provider-card-body">
        <ImageIcon alt="" size="XL" src={logo} styleName="provider-card-logo" />
        <Heading marginBottom="0">
          <FormattedMessage id={`settings.sync.syncSourceCards.${name}.title`} />
        </Heading>
        <p>
          <FormattedMessage id={`settings.sync.syncSourceCards.${name}.description`} />
        </p>
        <GoUrl
          name="edu_roster_sync"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need special styles
          UNSAFE_className={styles['provider-card-go-url']}
        >
          {intl.formatMessage({
            id: `settings.sync.syncSourceCards.${name}.learnMore`,
          })}
        </GoUrl>
      </div>
    </Card>
  );
};

SyncSourceCard.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  syncType: PropTypes.string.isRequired,
};

export default SyncSourceCard;
