/**
 * @deprecated ported to src2/app/common/components/users-details-drawer/UsersDetailsDrawer.jsx
 *
 * @ngdoc component
 * @name app.widgets.users:appUserDetailsDrawer
 *
 * @description The drawer for the Users page.
 *
 * @binding {String} orgId.
 * @binding {PageContext} pageContext. To determine if View should go to User or Admin detail page.
 * @binding {OrganizationUser} user. user does not have a promise and the
 *   userGroups field is not populated.
 */
(function () {
  angular.module('app.widgets.users').component('appUserDetailsDrawer', {
    bindings: {
      orgId: '@',
      pageContext: '<',
      user: '<',
    },
    controller,
    templateUrl: 'app/widgets/users/user-details-drawer/user-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $rootScope,
    $state,
    $translate,
    $uiRouterGlobals,
    _,
    binkyMemberNameLabelFilter,
    binkyMemberTypeLabelFilter,
    feature,
    onesieSrc2,
    OrganizationManager,
    OrganizationUser,
    panelManager,
    PageContext,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    productAccess,
    MEMBER_TYPE,
    MemberType,
    PRODUCT_DELEGATION_TARGET,
    USER_DETAILS_DRAWER_ADD_USER_MODAL_ID,
    USER_DETAILS_DRAWER_ID,
    userGroupAccess
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      addUserModalPageContext: new PageContext({
        target: PAGE_TARGET.ORGANIZATION,
        targetId: $uiRouterGlobals.params.orgId,
        targetType: PAGE_TARGET_TYPE.USER,
      }),
      canEditProducts,
      canEditUserGroups,
      EditDeveloperAccessModal: onesieSrc2.users.components.EditDeveloperAccessModal,
      EditProductsAndUserGroupsModal: onesieSrc2.users.components.EditProductsAndUserGroupsModal,
      feature,
      onClickViewBtn,
      onEditDeveloperAccessModalClose,
      onEditDeveloperAccessModalSuccess,
      onEditDeveloperProducts,
      onEditProducts,
      onEditProductsAndUserGroupsModalClose,
      onEditUserGroups,
    });

    function $onInit() {
      _.assign(vm, {
        addUserModalId: USER_DETAILS_DRAWER_ADD_USER_MODAL_ID,
        drawerId: USER_DETAILS_DRAWER_ID,
        isEditDeveloperAccessModalOpen: false,
        isEditProductsAndUserGroupsModalOpen: false,
        orgId: OrganizationManager.getActiveOrgId(),
        productList: OrganizationManager.getProductsForActiveOrg(),
        targetType: PAGE_TARGET_TYPE.DEVELOPER,
      });
    }

    function $onChanges(changesObj) {
      vm.user = _.get(changesObj, 'user.currentValue');
      if (vm.user) {
        // The user from the OrganizationUserList does not have the user groups.
        vm.fetchedUser = OrganizationUser.get({
          includeUserGroupProducts: true,
          userId: vm.user.id,
        });
        vm.displayUserEditModal = true;

        let memberDetailValue;
        if (vm.user.type === MEMBER_TYPE.TYPE2E) {
          memberDetailValue = _.has(vm.user, 'authenticatingAccount.type')
            ? new MemberType(vm.user.authenticatingAccount.type, vm.user.authenticatingAccount.id)
            : new MemberType(MEMBER_TYPE.NOT_AVAILABLE);
        } else {
          memberDetailValue = vm.user.getType();
        }

        vm.detailItems = [
          {
            class: 'member-type',
            label: $translate.instant('widgets.users.userDetailsDrawer.label.idType'),
            value: binkyMemberTypeLabelFilter(memberDetailValue),
          },
          {
            class: 'email',
            label: $translate.instant('widgets.users.userDetailsDrawer.label.email'),
            value: vm.user.email,
          },
        ];

        if (vm.user.userName && vm.user.type !== 'TYPE1') {
          vm.detailItems.push({
            class: 'username',
            label: $translate.instant('widgets.users.userDetailsDrawer.label.username'),
            value: vm.user.userName,
          });
        }

        vm.drawerTitle = binkyMemberNameLabelFilter(this.user);
      } else if (_.get(changesObj, 'user.previousValue')) {
        // user was previously defined but not now.
        // This means the user was removed from this view, for example if the drawer is opened
        // from the product users table and the user is removed from the product.
        closeDrawer();
        vm.displayUserEditModal = false;
      }

      vm.productList = OrganizationManager.getProductsForActiveOrg();
      vm.hasAPIProducts = _.some(vm.productList.items, (product) =>
        product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
      );
    }

    function canEditProducts() {
      return (
        productAccess.canEditProducts() &&
        !vm.user.getType().isTechnicalAccount() &&
        vm.productList.hasUserDelegatableProduct()
      );
    }

    function canEditUserGroups() {
      return (
        userGroupAccess.canEditUserGroup() &&
        !vm.user.getType().isTechnicalAccount() &&
        // Try to call the function if it exists on the provided model
        // else return false.
        !_.result(vm.user, 'isExternallyManaged', false)
      );
    }

    function onClickViewBtn() {
      const state = vm.pageContext.isAdminTargetType()
        ? 'users.administrator-details'
        : 'users.user-details';
      $state.go(state, {userId: vm.user.id});
    }

    function onEditDeveloperProducts() {
      vm.isEditDeveloperAccessModalOpen = true;
    }

    function onEditProducts() {
      vm.isEditProductsAndUserGroupsModalOpen = true;
    }

    function onEditUserGroups() {
      vm.isEditProductsAndUserGroupsModalOpen = true;
    }

    function onEditProductsAndUserGroupsModalClose() {
      vm.isEditProductsAndUserGroupsModalOpen = false;
      $rootScope.$apply();
    }

    function onEditDeveloperAccessModalSuccess() {
      onEditDeveloperAccessModalClose();
      vm.user.refresh();
    }

    function onEditDeveloperAccessModalClose() {
      vm.isEditDeveloperAccessModalOpen = false;
      $rootScope.$apply();
    }

    /////////////////

    function closeDrawer() {
      panelManager.close(vm.drawerId);
    }
  }
})();
