import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const SectionView = ({children, ...styleProps}) => (
  <View
    backgroundColor="gray-50"
    borderColor="gray-200"
    borderRadius="small"
    borderWidth="thin"
    flexGrow="1"
    height="auto"
    marginBottom="size-100"
    padding="size-200"
    width="100%"
    {...styleProps}
  >
    {children}
  </View>
);

SectionView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionView;
