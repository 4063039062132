(function () {
  /**
   * @deprecated should not be needed in ES6/React
   */
  // eslint-disable-next-line angular/provider-name
  angular.module('app.core.src2.ready').provider('src2Ready', src2Ready);

  /* @ngInject */
  function src2Ready($provide, _, binkySrc2, configurationReadyProvider) {
    _.assign(this, {
      $get,
      configure,
    });

    function configure() {
      $provide.decorator('$rootScope', eventBridgeDecorator);
      return configurationReadyProvider.whenConfigReady();
    }

    // This passthrough function is necessary to fix a minifier issue.
    function eventBridgeDecorator($delegate) {
      return binkySrc2.services.events.eventBridgeDecorator($delegate);
    }

    ////////////

    /* @ngInject */
    function $get(
      $location,
      $rootScope,
      $timeout,
      $urlService,
      $window,
      $q,
      $state,
      $injector,
      authentication,
      authenticationReady,
      // eslint-disable-next-line no-shadow -- Needed because mocking isn't available for injections in config
      binkySrc2,
      configurationReady,
      featureReady,
      idpStateManager,
      locale,
      localeReady,
      MESSAGE,
      onesieSrc2,
      orgReady
    ) {
      return {
        run,
      };

      function run() {
        const promises = [
          authenticationReady.whenRunReady(),
          featureReady.whenRunReady(),
          localeReady.whenRunReady(),
          orgReady.whenRunReady(),
        ];

        return $q.all(promises).then(() => {
          const orgList = $injector.get('OrganizationList');

          // Listen to the organization change success message so react code
          // has the correct org id when calls are made
          // eslint-disable-next-line angular/on-watch
          $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.SUCCESS, ($event, activeOrg) => {
            onesieSrc2.common.services.AppConstants.setOrgId(activeOrg.id);
            onesieSrc2.common.services.AppConstants.setCountryCode(activeOrg.countryCode);
          });

          // Event routing between $rootScope and eventBus is described in
          // https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=BPS&title=Admin+Tribe+Event+Architecture+Migration

          // Relay src2 events from eventBus to $rootScope
          binkySrc2.services.events.eventBus.registerEventHandler((eventId, eventObj) => {
            $rootScope.$emit(eventId, eventObj, binkySrc2.services.events.EVENT_SOURCES.SRC2);
          }, binkySrc2.services.events.EVENT_SOURCES.SRC2);

          binkySrc2.services.navBus.registerStateGo((routeId, context) =>
            $state.go(routeId, context)
          );

          binkySrc2.services.navBus.registerStateHref((routeId, context) =>
            $state.href(routeId, context)
          );

          // Registers an event handler so that navBus.navigate() can tell Angular to go a state
          // by URL instead of by state name
          binkySrc2.services.navBus.registerNavigateAngular((href, replace) => {
            // Decoding the URL here because if any encoded characters are in the URL then Angular will rewrite the URL
            // with the decoded characters, which then causes 2 history entries for essentially the same endpoint
            const decoded = decodeURIComponent(href);
            if (replace) {
              $window.history.replaceState({}, '', decoded);
            } else {
              $window.history.pushState({}, '', decoded);
            }
            // Force Angular to load the state associated with the new URL
            $timeout(() => {
              $urlService.sync();
            });
          });

          // Relay src events from $rootScope to eventBus
          binkySrc2.services.events.enableEventBridge();

          // Prevent Coral from intercepting the Esc key event
          // eslint-disable-next-line no-underscore-dangle
          $window.Coral.keys._stopPropagation = false;

          return onesieSrc2.core.services.bootstrap({
            accessToken: authentication.getAccessToken(),
            configuration: configurationReady.getConfig(),
            idpStateManager,
            language: locale.getActiveLanguage(),
            org: orgList.get().activeOrg,
          });
        });
      }
    }
  }
})();
