import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

let environment, url;

/**
 * Configures the package ingest service with the required parameters.
 *
 * @param {Object} config Config for package ingest service.
 * @param {String} config.env environment for the package ingest service.
 * @param {String} config.url Url for the package ingest service.
 */
const configure = (config) => {
  url = config.url;
  environment = config.env;
};

// ///////// API Utility methods ////////////

function getHeaderObject() {
  return {
    Authorization: authentication.getAccessToken().token,
    'x-api-key': 'aconsole-web-service',
  };
}

/**
 * Configures the package ingest service with the required parameters.
 *
 * @returns {String} environment
 *
 */
function getIngestEnvironment() {
  return environment;
}

// ///////// Resources ////////////
/**
 * Configures the package ingest service with the required parameters.
 *
 * @param {Object} analyticsObject analyticsObject for package ingest service.
 * @returns {Promise} resolved with response
 *
 */
function sendAnalytics(analyticsObject) {
  return axios.post(url, analyticsObject, {
    headers: getHeaderObject(),
  });
}

export {configure, getIngestEnvironment, sendAnalytics};
