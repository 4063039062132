(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('app.core.chat.ready').provider('chatReady', chatReady);

  /* @ngInject */
  function chatReady(_, configurationReadyProvider, onesieSrc2) {
    _.assign(this, {
      $get,
      configure,
    });

    function configure() {
      return configurationReadyProvider.whenConfigReady().then((configData) => {
        onesieSrc2.core.services.chat.chatProvider.configure(
          _.get(configData, 'services.chat'),
          _.get(configData, 'services.ims.clientId')
        );
      });
    }

    /* @ngInject */
    function $get(
      $injector,
      $log,
      $q,
      $window,
      authenticationReady,
      feature,
      featureReady,
      localeReady,
      orgReady
    ) {
      const deferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      function run() {
        // Resolve immediately; chat is nice to have so its initialization should not block
        // Onesie from starting up
        deferred.resolve();

        const promises = [
          // Allows chat to access the user's access token
          authenticationReady.whenRunReady(),
          // Allows chat to use the feature service
          featureReady.whenRunReady(),
          // Allows chat to access the active UI locale
          localeReady.whenRunReady(),
          // Allows chat to access the active org
          orgReady.whenRunReady(),
        ];

        // Disable rule since there's no action to take if any of the ready providers reject - it
        // does not even make sense to log, as with that approach, the same error could be logged
        // by each ready provider client.
        // eslint-disable-next-line promise/catch-or-return
        $q.all(promises).then(() => {
          if (feature.isDisabled('disable_jarvis')) {
            // not dependent on privacy consent anymore with flag temp_chat_unlink_cookies
            if (
              feature.isEnabled('temp_chat_unlink_cookies') ||
              $window.AdobeMessagingExperienceClient
            ) {
              // If the chat library is already loaded then we are in fakes mode - do not set the event listener
              initChat();
            } else {
              // Must not initialize Jarvis chat until user gives their consent
              $window.addEventListener('adobePrivacy:PrivacyConsent', () => {
                // User consent has been given.
                // Inject chat here rather than as a param to $get, because some of chat's dependencies
                // should not be constructed till after the above 'ready' events.
                initChat();
              });
            }
          }
        });

        return deferred.promise;
      }

      function whenRunReady() {
        return deferred.promise;
      }

      /////////

      function initChat() {
        try {
          // We need to use $q and resolve so that the src2 promise resolution is signaled to Angular's digest cycle
          // eslint-disable-next-line promise/catch-or-return
          onesieSrc2.core.services.chat.chatProvider.initialize().then(() => $q.resolve());
        } catch (error) {
          $log.error('Error in chatReadyProvider when initializing chat', error);
        }
      }
    }
  }
})();
