import {Button} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {goToAllPackages} from 'features/packages/routing/navigation-callbacks/navigationCallbacks';

const GoToPackagesButton = () => {
  const intl = useIntl();
  const namespace = 'products.deviceLicenses';

  return (
    <Button
      data-testid="go-to-Packages-button"
      isDisabled={false}
      onPress={goToAllPackages}
      variant="accent"
    >
      {intl.formatMessage({id: `${namespace}.goToPackages.button`})}
    </Button>
  );
};

export default GoToPackagesButton;
