import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/binky-ui';
import {Button, ButtonGroup, DialogContainer, View} from '@adobe/react-spectrum';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import {SetupCertificatesContextProvider} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';
import CreateCsrModal from 'features/settings/components/setup-directory-authentication/setup-saml-certs/CreateCsrModal';
import SamlCertificatesTable from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SamlCertificatesTable';
import useSetupCertificatesState from 'features/settings/components/setup-directory-authentication/useSetupCertificatesState';

const SetupCertificatesStep = () => {
  const intl = useIntl();
  const [showCreateSamlCertificateModal, setShowCreateSamlCertificateModal] = useState(false);

  const {directorySetupStore} = useDirectorySetupContext();
  const {idp} = directorySetupStore;

  const directoryId = directorySetupStore.directoryStore.directoryId;
  const idpId = idp.id;

  const state = useSetupCertificatesState({
    directoryId,
    idpId,
    idpSpDefaultKey: idp.spDefaultKey,
  });

  const {
    state: {isLoading},
    loadCertificatesList,
    createNewCertificate,
  } = state;

  // Get the list of certificates
  useEffect(() => {
    loadCertificatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this will run only during the initial render
  }, []);

  const handleCreateNewCertificate = async () => {
    try {
      await createNewCertificate();

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.createNewCertificate.toast.success'})
      );
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.certificates.toast.error'}));
    }
  };

  return (
    <SetupCertificatesContextProvider listState={state}>
      <View data-testid="setup-certificates-step" marginBottom="size-200" marginTop="size-200">
        <ButtonGroup>
          <Button
            data-testid="create-new-certificate"
            isDisabled={isLoading}
            onPress={handleCreateNewCertificate}
            variant="primary"
          >
            {intl.formatMessage({
              id: 'settings.certificates.buttons.createNewCertificate',
            })}
          </Button>
          <Button
            data-testid="create-new-csr"
            isDisabled={isLoading}
            onPress={() => setShowCreateSamlCertificateModal(true)}
            variant="secondary"
          >
            {intl.formatMessage({
              id: 'settings.certificates.buttons.createCertificateSigningRequest',
            })}
          </Button>
        </ButtonGroup>
      </View>

      <SamlCertificatesTable />

      <DialogContainer
        data-testid="create-csr-modal"
        onDismiss={() => {
          setShowCreateSamlCertificateModal(false);
        }}
      >
        {showCreateSamlCertificateModal && <CreateCsrModal />}
      </DialogContainer>
    </SetupCertificatesContextProvider>
  );
};

export default SetupCertificatesStep;
