import {feature} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Heading, StatusLight, Text, View} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useStatusData from 'features/notifications/hooks/use-status-data/useStatusData';

/**
 * A widget to display the Adobe Status Summary.
 */
const AdobeStatusSummary = () => {
  const intl = useIntl();
  const POLLING_EXPIRY_TIME = 300000;
  const {error, statusData} = useStatusData();
  const [statusVariant, setStatusVariant] = useState('positive');
  const [statusLabel, setStatusLabel] = useState(
    intl.formatMessage({id: 'support.adobeStatus.summary.messages.allNormal'})
  );

  statusData?.startPolling();
  setTimeout(() => statusData?.stopPolling(), POLLING_EXPIRY_TIME);

  // Side effect to fetch statusData
  useEffect(() => {
    if (statusData?.hasOngoingEvents()) {
      setStatusVariant('negative');
      setStatusLabel(
        intl.formatMessage({id: 'support.adobeStatus.summary.messages.ongoingEvents'})
      );
    } else if (statusData?.hasOngoingMaintenance()) {
      setStatusVariant('neutral');
      setStatusLabel(
        intl.formatMessage({id: 'support.adobeStatus.summary.messages.ongoingMaintenance'})
      );
    }
  }, [intl, statusData]);

  const statusEventsMessage = intl.formatMessage({
    id: 'support.adobeStatus.summary.messages.networkError',
  });

  const errorMessage = feature.isEnabled('temp_bug_fix_65293') ? statusEventsMessage : error;

  return (
    <Flex data-testid="adobe-status-summary" direction="column">
      <Heading data-testid="adobe-status-header" marginBottom="size-125" marginTop="size-400">
        {intl.formatMessage({id: 'support.adobeStatus.summary.title'})}
      </Heading>
      <GoUrl
        alignSelf="flex-start"
        data-testid="adobe-status-link"
        isQuiet
        marginStart="static-size-65"
        marginY="size-200"
        name="aac_status"
      >
        <Flex>
          <Text>
            {intl.formatMessage({
              id: 'support.adobeStatus.summary.detailsLink',
            })}
          </Text>
          <LinkOutLight isQuiet marginStart="size-100" marginTop="size-10" size="S" />
        </Flex>
      </GoUrl>

      <View data-testid="adobe-status-error" marginTop="size-200">
        {error ? (
          <>
            <Alert color="negative" size="S" />
            <Text>{errorMessage}</Text>
          </>
        ) : (
          <StatusLight data-testid="adobe-status-error-text" variant={statusVariant}>
            {statusLabel}
          </StatusLight>
        )}
      </View>
    </Flex>
  );
};

AdobeStatusSummary.propTypes = {};

export default AdobeStatusSummary;
