import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';

import {SERVER_SCREEN_NAME_CONSTANTS} from 'features/packages/packagesConstants';

import {useCreateServerModalContext} from './CreateServerModalContext';
import AuthorizeServer from './screens/authorize-server/AuthorizeServer';
import ConfigureServer from './screens/configure-server/ConfigureServer';
import ServerEntitlements from './screens/server-entitlements/ServerEntitlements';
import ServerFinalize from './screens/server-finalize/ServerFinalize';
import ServerInfo from './screens/server-info/ServerInfo';
// Wizard API will be hoisted so we can control it.
let wizard;

// Wizard contains 5 screens: AUTHORIZE_SERVER,CONFIGURE_SERVER,SERVER_INFO,SERVER_ENTITLEMENTS,SERVER_FINALIZE,
// Create server flow's screens are shown in this wizard
// In this wizard for all the flows, the final screen where admin is prompted to create server is the finalize screen
const CreateServerWizard = observer(({closeCreateServerModal}) => {
  const intl = useIntl();
  const {packagesServerStore} = useCreateServerModalContext();
  const {serverSessionSettings} = packagesServerStore;
  const nextButton = intl.formatMessage({id: 'packages.createServer.footer.next'});
  const backButton = intl.formatMessage({id: 'packages.createServer.footer.previous'});
  const cancelButton = intl.formatMessage({id: 'packages.createServer.footer.cancel'});
  const createButton = intl.formatMessage({id: 'packages.createServer.footer.createServer'});

  const content = ModalDialogModel.createEntry({
    cancelLabel: cancelButton,
    ctaLabel:
      serverSessionSettings.currentScreen === SERVER_SCREEN_NAME_CONSTANTS.SERVER_FINALIZE
        ? createButton
        : nextButton,
    secondaryLabel: backButton,
  });

  const stepToScreenMapping = {
    [SERVER_SCREEN_NAME_CONSTANTS.AUTHORIZE_SERVER]: {
      displayName: intl.formatMessage({
        id: 'packages.createServer.wizard.authorize',
      }),
      screen: <AuthorizeServer />,
    },
    [SERVER_SCREEN_NAME_CONSTANTS.CONFIGURE_SERVER]: {
      displayName: intl.formatMessage({
        id: 'packages.createServer.wizard.configure',
      }),
      screen: <ConfigureServer />,
    },
    [SERVER_SCREEN_NAME_CONSTANTS.SERVER_INFO]: {
      displayName: intl.formatMessage({
        id: 'packages.createServer.wizard.serverInfo',
      }),
      screen: <ServerInfo />,
    },
    [SERVER_SCREEN_NAME_CONSTANTS.SERVER_ENTITLEMENTS]: {
      displayName: intl.formatMessage({
        id: 'packages.createServer.wizard.entitlements',
      }),
      screen: <ServerEntitlements />,
    },
    [SERVER_SCREEN_NAME_CONSTANTS.SERVER_FINALIZE]: {
      displayName: intl.formatMessage({
        id: 'packages.createServer.wizard.finalize',
      }),
      screen: <ServerFinalize />,
    },
  };

  const wizardConfig = useRef({
    stepIndex: serverSessionSettings.currentScreenIndex,
    steps: Object.values(stepToScreenMapping).map((step) => ({
      name: step.displayName,
    })),
  }).current;

  return (
    <ModalDialog
      cancelLabel={cancelButton}
      content={content}
      ctaLabel={nextButton}
      descriptionStyle={{dividerGap: 'size-125', marginBottom: 'size-200', marginTop: 'size-0'}}
      dialogStyle={{spaceX: 'size-0', width: '1100px'}}
      heightVariant="static"
      id={serverSessionSettings.createServerModalId}
      isCtaDisabled={!serverSessionSettings.nextButtonEnabled}
      isSecondaryDisabled={serverSessionSettings.isPreviousButtonDisabled}
      onCancel={closeCreateServerModal}
      onCta={() => {
        packagesServerStore.gotoNextScreen(wizard.goToNextStep, closeCreateServerModal);
      }}
      onSecondary={() => {
        packagesServerStore.gotoPreviousScreen(wizard.goToPreviousStep);
      }}
      secondaryLabel={backButton}
    >
      <ModalHeading marginBottom="size-150">{serverSessionSettings.modalTitle}</ModalHeading>
      <ModalContent>
        {/* Passing empty function to onStepChange as it is a required field */}
        <WizardView initConfig={wizardConfig} onStepChange={() => {}} showStepList>
          <ModalDescription />
          {stepToScreenMapping[serverSessionSettings.currentScreen]?.screen}
          <WizardDriver
            onInit={(inputWizard) => {
              wizard = inputWizard;
            }}
          />
        </WizardView>
      </ModalContent>
    </ModalDialog>
  );
});

CreateServerWizard.propTypes = {
  /**
   * Function called to close create server modal
   */
  closeCreateServerModal: PropTypes.func.isRequired,
};

export default CreateServerWizard;
