import {Flex, Link, View} from '@adobe/react-spectrum';
import {DirectoryDomainEnforcementStatus} from '@pandora/react-directory-domain-enforcement-status';
import {EnDashDefaultContent} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './DirectoryOverviewCard.pcss';

const DirectoryDomainEnforcementStatusWithLink = observer(
  ({goToTab, state, enablementDate, title, hasLink}) => {
    const intl = useIntl();

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- conditional event handler
      <div
        aria-label={intl.formatMessage({id: `${title}.ariaLabel`})}
        data-testid="directory-status-row"
        onClick={hasLink ? goToTab : undefined}
        onKeyDown={hasLink ? goToTab : undefined}
        role={hasLink ? 'button' : null}
        styleName="directory-status-row"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- declared only when interactive
        tabIndex={hasLink ? 0 : undefined}
      >
        <Flex
          alignItems="baseline"
          direction="row"
          justifyContent="space-between"
          minHeight="size-450"
        >
          <View width="45%">
            {hasLink ? (
              <Link
                // TBD: remove `onPress` prop when introducing React Routing as we want the
                // whole row to be clickable while maintaining the Link aspect of the label
                onPress={goToTab}
              >
                {intl.formatMessage({id: title})}
              </Link>
            ) : (
              <FormattedMessage id={title} />
            )}
          </View>
          <View width="45%">
            {state ? (
              <DirectoryDomainEnforcementStatus
                enablementDate={enablementDate}
                state={state}
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- clear formatting
                UNSAFE_className={styles['directory-status']}
              />
            ) : (
              <View marginStart="size-150">
                <EnDashDefaultContent />
              </View>
            )}
          </View>
        </Flex>
      </div>
    );
  }
);

export default DirectoryDomainEnforcementStatusWithLink;
