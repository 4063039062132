import {Provider} from '@adobe/react-spectrum';
import {useResizeObserver} from '@react-aria/utils';
import {useProvider} from '@react-spectrum/provider';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

// Provider content for Overview CSS grid layout that handles resizing with various breakpoints
const ResponsiveProviderContent = ({
  children,
  containerRef,
  'data-testid': dataTestId = 'info-bar',
}) => {
  const {breakpoints: parentBreakpoints} = useProvider();

  const [containerWidth, setContainerWidth] = useState();
  const [childBreakpoints, setChildBreakpoints] = useState(parentBreakpoints);

  const onResize = useCallback(() => {
    const container = containerRef.current;
    if (container?.getBoundingClientRect()?.width > 0) {
      setContainerWidth(container.getBoundingClientRect().width);
    }
  }, [containerRef]);

  useResizeObserver({onResize, ref: containerRef});

  // when container size changes, update the local container width
  useEffect(() => {
    onResize();
  }, [setContainerWidth, onResize]);

  // when container width changes, recalculate the responsive breakpoints
  useEffect(() => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- useEffect runs client side
    const responsiveProviderOffset = window.innerWidth - containerWidth;

    setChildBreakpoints({
      L: parentBreakpoints.L + responsiveProviderOffset,
      M: parentBreakpoints.M + responsiveProviderOffset,
      S: parentBreakpoints.S + responsiveProviderOffset,
      XL: parentBreakpoints.XL + responsiveProviderOffset,
      XXL: parentBreakpoints.XXL + responsiveProviderOffset,
    });
  }, [
    parentBreakpoints.S,
    parentBreakpoints.M,
    parentBreakpoints.L,
    parentBreakpoints.XL,
    parentBreakpoints.XXL,
    containerWidth,
  ]);

  return (
    <Provider breakpoints={childBreakpoints} data-testid={dataTestId}>
      {children}
    </Provider>
  );
};

ResponsiveProviderContent.propTypes = {
  /**
   * The child components to render inside of this provider context.
   */
  children: PropTypes.node.isRequired,
  /**
   * The reference to the container element for this provider.
   */
  // eslint-disable-next-line react/forbid-prop-types -- regular DOM reference, using object to avoid SSR issues (Element would be undefined)
  containerRef: PropTypes.object.isRequired,
  /**
   * The specified data-testid string.
   */
  'data-testid': PropTypes.string,
};

export default ResponsiveProviderContent;
