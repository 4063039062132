(function () {
  const componentName = 'appChangeContractOwnerModal';
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.account').component(componentName, {
    bindings: {
      adminList: '<',
      contract: '<',
      modalId: '@?',
      onClosed: '&?',
      onInitialized: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/account/change-contract-owner/change-contract-owner-modal.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $element,
    $q,
    $stateParams,
    $timeout,
    $translate,
    AnalyticsEvent,
    CHANGE_CONTRACT_OWNER_MODAL_ID,
    CONTRACT_OWNER_REQUIRES_ADOBE_ID,
    ContractOwnerInvite,
    DisplayMessage,
    feature,
    INVALID_COUNTRY_CODE,
    panelManager,
    PRIMARY_ADMIN_T2E_NOT_ACTIVE,
    User,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      closeModal,
      id: vm.modalId || CHANGE_CONTRACT_OWNER_MODAL_ID,
      isContractOwner,
      isPending: true,
      isSelected,
      onClickRow,
      onClose,
      onPageChange,
      onPageSizeChange,
      onSave,
      onSearch,
      orgId: $stateParams.orgId,
      paginationId: 'adminListPaginationId',
      promiseToWait: $q.resolve(),
      selectedOrgUserId: '',
    });

    /////////////

    function $onInit() {
      vm.ownerInfo = new User(
        _.assign({type: vm.contract.ownerInfo.userType}, vm.contract.ownerInfo)
      );

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    function closeModal() {
      panelManager.close(vm.id);
      vm.isPending = true;
    }

    function isContractOwner(orgUser) {
      return vm.contract.isContractOwner({userId: _.get(orgUser, 'id')});
    }

    function isSelected(orgUser) {
      return _.get(orgUser, 'id') === vm.selectedOrgUserId;
    }

    function onClickRow(orgUser) {
      vm.selectedOrgUserId = orgUser.id;
      vm.isPending = false;
    }

    function onClose() {
      vm.displayMessage = undefined;
      vm.selectedOrgUserId = '';
      $element.find('binky-search').find('input').val('');
      vm.adminList.filter.query = '';
      vm.adminList.refresh();

      _.invoke(vm, 'onClosed');
    }

    function onPageChange(newPage) {
      vm.adminList.setPage(newPage - 1);
      vm.adminList.refresh();
      vm.promiseToWait = vm.adminList.$promise;
    }

    function onPageSizeChange(pageSize) {
      vm.adminList.setPageSize(pageSize);
      // Go back to the beginning of the pages when we change size
      vm.adminList.setPage(0);
      vm.adminList.refresh();
      vm.promiseToWait = vm.adminList.$promise;
    }

    function onSearch(value) {
      if (value !== vm.adminList.filter.query) {
        vm.adminList.filter.query = value;
        vm.adminList.refresh();
      }
      vm.promiseToWait = vm.adminList.$promise;
    }

    function onSave() {
      const selectedAdmin = _.find(vm.adminList.items, ['id', vm.selectedOrgUserId]);
      const contractOwnerInvite = new ContractOwnerInvite({
        contractId: vm.contract.id,
        userId: selectedAdmin.id,
      });

      vm.displayMessage = undefined;

      vm.isPending = true;
      vm.promiseToWait = contractOwnerInvite
        .save()
        .then(onSuccess)
        .catch((error) => {
          vm.displayMessage = new DisplayMessage(processSaveError(error));
          vm.isPending = false;
        });

      function onSuccess() {
        triggerAnalytics();
        vm.closeModal();
        toastManager.showSuccessToast();
      }
    }

    function processSaveError(errObj) {
      let errorKey;
      const failureReason = _.get(errObj, 'data.errorCode');

      if (
        failureReason === INVALID_COUNTRY_CODE ||
        failureReason === PRIMARY_ADMIN_T2E_NOT_ACTIVE
      ) {
        errorKey = 'countryConflict2';
      } else if (failureReason === CONTRACT_OWNER_REQUIRES_ADOBE_ID) {
        errorKey = 'requiresAdobeId';
      }

      return errorKey
        ? {body: $translate.instant(`widgets.account.changeContractOwner.error.${errorKey}`)}
        : undefined; // 'DisplayMessage' class needs 'undefined' for default options
    }

    function triggerAnalytics() {
      AnalyticsEvent.dispatch({
        componentMethod: 'changeContractOwner',
        componentMethodType: 'submit',
        componentName,
      });
    }
  }
})();
