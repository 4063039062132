(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    auth,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.SET_POLICIES,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.setPolicies';
          return $q.resolve([
            new HvaCard({
              ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
              description: $translate.instant(`${namespace}.description`),
              dismissOnCTAClick: true,
              header: $translate.instant(`${namespace}.header`),
              id: 'hva-set-policies',
              isDismissible: true,
              onCTAClick: () => {
                $state.go('settings.console');
              },
              sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
              svgClass: SVG_CLASS.POLICIES,
            }),
          ]);
        }
        return $q.reject();
      }
    }
  }
})();
