import {useRef} from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_ADMINISTRATORS,
  PATH_API_CREDENTIALS,
  PATH_DEVELOPERS,
  PATH_DIRECTORIES,
  PATH_USERS,
  PATH_USER_GROUPS,
} from 'features/users/routing/usersPaths';

/** Hook which returns methods with generated Users pathnames */
const useUsersPaths = () => {
  const administratorsPath = useRef(() =>
    generatePath(PATH_ADMINISTRATORS, {orgId: rootStore.organizationStore.activeOrgId})
  ).current;

  const apiCredentialsPath = useRef(() =>
    generatePath(PATH_API_CREDENTIALS, {orgId: rootStore.organizationStore.activeOrgId})
  ).current;

  const developersPath = useRef(() =>
    generatePath(PATH_DEVELOPERS, {orgId: rootStore.organizationStore.activeOrgId})
  ).current;

  const directoryUsersPath = useRef(() =>
    generatePath(PATH_DIRECTORIES, {orgId: rootStore.organizationStore.activeOrgId})
  ).current;

  const usersPath = useRef(() =>
    generatePath(PATH_USERS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  ).current;

  const userGroupsPath = useRef(() =>
    generatePath(PATH_USER_GROUPS, {orgId: rootStore.organizationStore.activeOrgId})
  ).current;

  return {
    administratorsPath,
    apiCredentialsPath,
    developersPath,
    directoryUsersPath,
    userGroupsPath,
    usersPath,
  };
};

export default useUsersPaths;
