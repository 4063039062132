import {MEMBER_TECH_ACCOUNT_DOMAIN, OrganizationUserList, modelCache} from '@admin-tribe/binky';

import ApiIntegrationList from 'core/api-integration/ApiIntegrationList';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';

/**
 * The list of Api integration/credentials for an organization.
 * It is composed of organization users with emails in the technical account domain
 * joined with the details of each integration.
 */
class OrganizationIntegrationList extends OrganizationUserList {
  /**
   * @description Constructor for OrganizationIntegrationList model .
   * @param {Object} options - options passed down to OrganizationUserList and MemberList.
   * @param {String} options.orgId - Org ID
   */
  constructor(options) {
    super({
      filterExcludeDomain: undefined,
      filterIncludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
      itemClassRef: ApiIntegrationUser,
      modelCacheId: 'OrganizationIntegrationList',
      ...options,
    });

    // Because this list does a join after the refresh API call completes and the response is
    // cached, we have to manually update the cached entry.
    this.key = this.getModelKey(options);
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      filterExcludeDomain: undefined,
      filterIncludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
    };
  }

  /**
   * @description Method to execute after a successful refresh.
   *   Adds the intergration details to each organinization integration.
   * @returns {Promise<undefined>} resolves with promise when integration details
   *   have been added
   */
  async onRefreshSuccess() {
    // The cache entry for this key currently only contains the refresh reponse so remove it
    // since it is incomplete.  If the join is successful the cache entry will be updated.
    modelCache.remove(this.modelCacheId, this.key);

    // The integrations for this organization.
    const orgIntegrations = await ApiIntegrationList.get({rengaOrgId: this.orgId});

    // To each user, add the org id needed in case of refresh,
    // and their integration if one is found.
    this.items.forEach((item) => {
      item.orgId = this.orgId;
      item.addIntegration(orgIntegrations.items);
    });

    // Now that the join is complete, use the saved key, to update value cached in
    // ModelList's `onQueryResponse`.
    modelCache.put(this.modelCacheId, this.key, Promise.resolve(this));
  }

  // Override since filter.query is always set to MEMBER_TECH_ACCOUNT_DOMAIN
  shouldUpdateTotalItemCount() {
    return this.pagination?.itemCount >= 0;
  }
}

export default OrganizationIntegrationList;
