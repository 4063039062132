import {Bumper} from '@admin-tribe/binky-ui';
import {Image} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import trialHelper from 'core/products/trial-helper/trialHelper';

import LockedState from './LockedState.svg';

/**
 * @description Represents the account page bumper component
 *
 */
const AccountBumper = ({bumperMessageHeader, bumperMessageBody, showBuyNowButton}) => {
  const intl = useIntl();

  const handleTrialNow = () => {
    const product = trialHelper.getTrialProduct();
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
    /* istanbul ignore else  */
    if (product?.id) {
      trialHelper.openBuyNowUrl(product.id);
    }
  };

  return (
    <Bumper
      ctaButtonLabel={showBuyNowButton ? intl.formatMessage({id: 'account.linkText.trials'}) : ''}
      details={bumperMessageBody}
      icon={<Image alt="" src={LockedState} />}
      onButtonClick={showBuyNowButton ? handleTrialNow : null}
      title={bumperMessageHeader}
    />
  );
};

AccountBumper.propTypes = {
  /**
   * Optional body to be shown for the bumper, defaults to ''
   */
  bumperMessageBody: PropTypes.string,
  /**
   * Title to be shown for the bumper
   */
  bumperMessageHeader: PropTypes.string.isRequired,
  /**
   * Option - show buy now CTA, defaults to false.
   */
  showBuyNowButton: PropTypes.bool,
};

export default AccountBumper;
