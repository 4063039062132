import {Flex, Heading, SearchField, Text, View} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useIncludeListFormContext} from '../IncludeListFormContext';

import IncludePaneAllButton from './IncludePaneAllButton';
import IncludeItemsList from './items-list/IncludeItemsList';

const getEmptyMessage = ({
  type,
  itemsLength,
  filteredItemsLength,
  availableEmptyMessage,
  includeEmptyMessage,
  noResultsMessage,
}) => {
  if (itemsLength === 0) {
    return type === 'available' ? availableEmptyMessage : includeEmptyMessage;
  }
  if (filteredItemsLength === 0) {
    return noResultsMessage;
  }
  return null;
};

const IncludePane = ({addRemove, type, items, searchRef}) => {
  const intl = useIntl();

  const {
    availableEmptyMessage,
    includeEmptyMessage,
    noResultsMessage,
    includeTitle,
    availableTitle,
    readOnly,
    searchItem,
  } = useIncludeListFormContext();
  const title = type === 'available' ? availableTitle : includeTitle;

  const includePaneId = useId();
  const [searchValue, setSearchValue] = useState('');
  const notSearching = !searchValue || /^\s*$/.test(searchValue);
  const filteredItems = items.filter((item) => notSearching || searchItem(item, searchValue));

  const emptyMessage = getEmptyMessage({
    availableEmptyMessage,
    filteredItemsLength: filteredItems.length,
    includeEmptyMessage,
    itemsLength: items.length,
    noResultsMessage,
    type,
  });

  return (
    <Flex data-testid="include-list-pane" direction="column" gap="size-100" width="100%">
      <Heading data-testid="include-title" id={includePaneId} level={3} marginBottom="size-0">
        {`${title} (${items.length})`}
      </Heading>
      <Flex alignItems="end" direction="row" gap="size-100">
        <View flexGrow="1">
          <SearchField
            ref={searchRef}
            aria-label={`${intl.formatMessage({
              id: 'common.includeListForm.searchLabel',
            })} ${title}`}
            data-testid="include-list-search"
            isDisabled={items.length === 0}
            label={intl.formatMessage({
              id: 'common.includeListForm.searchLabel',
            })}
            onChange={setSearchValue}
            value={searchValue}
          />
        </View>
        <IncludePaneAllButton
          isDisabled={readOnly || filteredItems.length === 0}
          onPress={() => addRemove(filteredItems)}
          title={title}
          type={type}
        />
      </Flex>
      {emptyMessage && (
        <View
          backgroundColor="static-white"
          borderColor="gray-300"
          borderRadius="regular"
          borderWidth="thin"
          padding="size-250"
        >
          <Flex alignSelf="center" justifyContent="center" width="100%">
            <Text data-testid="include-list-no-results">{emptyMessage}</Text>
          </Flex>
        </View>
      )}
      {filteredItems.length > 0 && (
        <IncludeItemsList
          addRemove={addRemove}
          aria-labelledby={includePaneId}
          items={filteredItems}
          type={type}
        />
      )}
    </Flex>
  );
};

IncludePane.propTypes = {
  /**
   * The callback function when items are being added/removed. Takes an argument of an array of items to add/remove.
   */
  addRemove: PropTypes.func.isRequired,
  /**
   * The array of items. Items is a generic object as an item can be any object, but it must have a key.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /**
   * Reference to the search button for this pane, used to set focus.
   */
  searchRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }).isRequired,
  /**
   *  The typeof this include pane. Current possible values are 'avaiable' and 'included'.
   */
  type: PropTypes.oneOf(['available', 'included']).isRequired,
};

export default IncludePane;
