(function () {
  /**
   * @ngdoc module
   * @name app.widgets.search-list
   * @description module that wraps the Coral button list
   */
  angular.module('app.widgets.search-list', [
    'app.widgets.disable-autocomplete',
    'binky.core.lodash',
    'binky.widgets.common.icon-tooltip',
  ]);
})();
