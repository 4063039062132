/* eslint-disable max-lines -- simply too many methods */
import {
  AuthenticatedUser,
  Locale,
  OrganizationUser,
  PRODUCT_APPLICABLE_OFFER_TYPE,
  configStore,
  feature,
  getContractsForProduct,
  getTotalProvisionedQuantity,
  getTrialContract,
  hasTrialContractForId,
  ims,
  log,
} from '@admin-tribe/binky';
import {differenceInDays, parseISO} from 'date-fns';

import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';

import {COMPLIANCE_SYMPTOMS, TRIAL_STATUS} from './trialHelperConstants';

/**
 * @description Returns a promise that resolves to true if user is trial admin and if the only product provisioned
 * is the trials product provisioned to the trial admin.
 *
 * @returns {Promise} resolves to true if user is trial admin and if the only product provisioned is the trials product
 * to them. Resolves to false otherwise.
 */
async function canTrialAdminViewQAModalInFirstSession() {
  const productList = rootStore.organizationStore.productList;
  if (!isLoggedInUserTrialOwner() || getTotalProvisionedQuantity(productList) !== 1) {
    return false;
  }
  const trialProduct = productList.items.find(
    (product) => isTrialProduct(product) && product.provisionedQuantity === 1
  );

  if (trialProduct) {
    const trialAdminId = getTrialOwnerUserId();
    const trialAdmin = await OrganizationUser.get({
      orgId: rootStore.organizationStore.activeOrgId,
      userId: trialAdminId,
    });

    const matchedProduct = trialAdmin.products.find(({id}) => id === trialProduct.id);
    return matchedProduct !== undefined;
  }
  return false;
}

/**
 * @description Returns true if the logged in user is the trial contract owner.
 *
 * @returns {Boolean} returns true if the logged in user is the trial contract owner, else false.
 */
function isLoggedInUserTrialOwner() {
  return getTrialOwnerUserId() === AuthenticatedUser.get().getId();
}

/**
 * @description Returns the user id of the trial contract owner.
 *
 * @returns {String} returns the user id of trial contract owner, else undefined if trial contract does not exist.
 */
function getTrialOwnerUserId() {
  const trialContract = getTrialContract(rootStore.organizationStore.contractList);
  return trialContract?.getOwnerUserId();
}

/**
 * @description Returns the first trial contract that also has buying program set to ENTERPRISE_PRODUCT.
 * The contract must be from the Contract Service so that it uses the model property.
 * @returns {Contract} returns the contract or undefined if not found.
 */
function getDxTrialContract() {
  return rootStore.organizationStore.contractList.items.find(
    (contract) => contract.isBuyingProgramEnterpriseProduct() && contract.isTrial()
  );
}

/**
 * @description Method to retrieve a reference to the first DX trial product for
 *   an organization.
 * @returns {Product||undefined} first DX Trial Product, or undefined if none found
 */
function getDxTrialProduct() {
  let result;
  const dxTrialContract = getDxTrialContract();
  if (dxTrialContract) {
    const productList = rootStore.organizationStore.productList;
    result = productList.items.find((product) => product.hasContractId(dxTrialContract.id));
  }
  return result;
}

/**
 * @description Determines the length of the trial, in days.
 *
 * @param {Contract} trialContract - The trial contract.
 * @returns {Integer} returns the trial duration in days if there is a trial contract with valid start and end
 *  dates, otherwise returns 0.
 */
function getTrialDuration(trialContract) {
  if (trialContract) {
    const startDateISOString = trialContract.getStartDate();
    const endDateISOString = trialContract.getEndDate();

    if (startDateISOString && endDateISOString) {
      const startDate = parseISO(startDateISOString);
      const endDate = parseISO(endDateISOString);
      return differenceInDays(endDate, startDate);
    }
  }

  return 0;
}

/**
 * @description Returns the first trial product found on the contract.
 *   Note: this will return undefined if the product still has a virtual license, ie if
 *   isTrialVirtualLicense(product) returns true.
 *
 * @param {Contract} [trialContract] - The trial contract. Default is the first trial contract found.
 * @returns {Product} returns the first trial product it finds on the contract, else undefined.
 */
function getTrialProduct(trialContract) {
  const contract = trialContract || getTrialContract(rootStore.organizationStore.contractList);
  if (contract) {
    const productList = rootStore.organizationStore.productList;
    return productList.items.find((product) => product.hasContractId(contract.id));
  }
  return undefined;
}

/**
 * @description Returns the trial status for a given product.
 *     Note: this will return TRIAL_STATUS.NOT_TRIAL if the product still has a
 *     virtual license, ie if isTrialVirtualLicense(product) returns true.
 *
 * @param {Product} [product] - The product to use to determine trial status
 * @returns {Object} with status of TRIAL_STATUS, endDate and daysLeft.
 * @property {String} endDate - is the date the trial contract ends.
 * @property {Number} daysLeft - is negative for an expired trial, 0 for the
 *   last day of a trial and positive for the days remaining in the trial.
 *   If the product is a trial the return object will be:
 *      {daysLeft: {Integer}, endDate: {Date}, status: {any TRIAL_STATUS except NOT_TRIAL}}.
 *   If product isn't specified or is not a trial, the return will be:
 *      {daysLeft: NaN, endDate: NaN, status: TRIAL_STATUS.NOT_TRIAL}.
 */
function getTrialStatusInfo(product) {
  let daysLeft = Number.NaN;
  let endDate = Number.NaN;
  let status = TRIAL_STATUS.NOT_TRIAL;

  const contractList = rootStore.organizationStore.contractList;
  const contractIds = product.contractIds || [];
  // eslint-disable-next-line unicorn/no-for-loop -- Want a for-loop here so we can break out after the first entry is found
  for (let i = 0; i < contractIds.length; i++) {
    const contract = getTrialContract(contractList, contractIds[i]);
    if (contract) {
      daysLeft = contract?.getDaysLeft?.() || Number.NaN;
      endDate = contract?.getEndDate?.() || Number.NaN;
      break;
    }
  }

  if (Number.isInteger(daysLeft)) {
    if (daysLeft <= 0) {
      status = TRIAL_STATUS.EXPIRED;
    } else if (daysLeft === 1) {
      status = TRIAL_STATUS.LAST_DAY;
    } else if (daysLeft <= 3) {
      status = TRIAL_STATUS.DAYS_LEFT_WARNING;
    } else {
      status = TRIAL_STATUS.DAYS_LEFT_INFO;
    }
  }
  return {daysLeft, endDate, status};
}

/**
 * @description Returns true if product is a trial and it has expired.
 *
 * @param {Product} [product] - The product.
 * @returns {Boolean} returns true if the product is a trial and the trial has expired, else false.
 */
function isExpiredTrial(product) {
  const contractList = rootStore.organizationStore.contractList;

  return getContractsForProduct(contractList, product)?.some(
    (contract) => contract?.isTrial() && contract.isStatusExpired()
  );
}

/**
 * @description Determines if the product is a trial license (vs a paid license).
 *
 * @param {Product} [product] - The product to use to determine trial status.
 * @returns {Boolean} returns true if the product is a trial, else false.
 */
function isTrialProduct(product) {
  const contractList = rootStore.organizationStore.contractList;

  const contractIds = product?.contractIds;
  if (!contractIds || contractIds.length === 0) {
    return isTrialVirtualLicense(product);
  }

  if (feature.isEnabled('temp_trial_compliance_symptoms') && product?.licenseTupleList) {
    return (
      product.licenseTupleList.hasTrialLicenses() ||
      contractIds.some((contractId) => hasTrialContractForId(contractList, contractId))
    );
  }

  return contractIds.some((contractId) => hasTrialContractForId(contractList, contractId));
}

// When the trial is created, before the actual trial contract has finished being created
// there is a virtual license in Renga so the Quick Assign modal works immediately.
// The virtual license has a targetExpression of the form 'guid:delegation_intent' and since it
// is not yet associated with the trial contract, its contractId is still undefined.
// Post-fulfillment, the actual trial license is created with targetExpression 'delegation'
// that is associated with trial contractId.
//
// The decision has been made that checking just for the absence of a contractId and an
// applicableOfferType of TRIAL is enough since the check for targetExpression could be brittle.
function isTrialVirtualLicense(product) {
  const contractIds = product?.contractIds ?? [];

  return !!(
    product &&
    contractIds.length === 0 &&
    product.applicableOfferType === PRODUCT_APPLICABLE_OFFER_TYPE.TRIAL
  );
}

/**
 * @description Constructs the jump url for the trial conversion page and then opens it in a separate tab.
 *   The trial conversion page allows the admin to covert the given trial to a paid product.
 *   The jump url allows the admin to switch contexts to the trial conversion page and maintain their
 *   authorization session so that they don't have to re-login.
 *
 *  Business trials conversion page spec: https://wiki.corp.adobe.com/x/9bgvd
 *    Note: Commerce does not support traditional Chinese (zh-hans) so if lang="zh-hans" it defaults to English.
 *  IMS Jumptoken API: https://wiki.corp.adobe.com/x/EAXEIg
 *
 * @param {String} productId - The product id/license id for the trial product to buy now.
 * @returns {Promise} If resolved, the trial conversion page has been opened in a new tab.
 */
async function internalOpenBuyNowUrl(productId, redirectEndpoint, targetClientId, targetScope) {
  /* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- false positive */

  const oid = window.encodeURIComponent(rootStore.organizationStore.activeOrgId);
  const pid = window.encodeURIComponent(productId);
  const lang = Locale.get().activeLanguageBCP47Code;
  const targetRedirectUri = `${redirectEndpoint}&oid=${oid}&pid=${pid}&lang=${lang}`;

  let postJumpTokenResponse;
  try {
    postJumpTokenResponse = await ims
      .postJumpToken({
        targetClientId,
        targetRedirectUri,
        targetScope,
      })
      .then((response) => response.data);
  } catch (error) {
    const {
      response: {data, status},
    } = error;
    log.error(`Could not get jumptoken for trial 'Buy now': ${JSON.stringify({data, status})}`);
    return Promise.reject(
      new Error(`Could not get jumptoken for trial 'Buy now': ${JSON.stringify({data, status})}`)
    );
  }

  if (window.open(postJumpTokenResponse.jump, '_blank') === null) {
    log.error(`Could not open new tab for trial 'Buy now': ${postJumpTokenResponse.jump}`);
    return Promise.reject(
      new Error(`Could not open new tab for trial 'Buy now': ${postJumpTokenResponse.jump}`)
    );
  }

  return Promise.resolve();
  /* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- false positive */
}

function openBuyNowUrl(productId) {
  const conf = AppConstants.configuration;
  const trialConversionPageUrl = conf.services?.commerce?.url;

  if (typeof window === 'undefined' || typeof productId !== 'string' || !trialConversionPageUrl) {
    return Promise.reject(new Error(`Error: trialConversionPageUrl not defined`));
  }

  const redirectEndpoint = `${trialConversionPageUrl}/business-trial/conversion?cli=admin_console`;
  const targetClientId = 'commerce_apps_amsterdam_client';
  const targetScope = 'AdobeID,openid,additional_info.roles,read_organizations';

  return internalOpenBuyNowUrl(productId, redirectEndpoint, targetClientId, targetScope);
}

function openTrialToPaidBuyNowUrl(productId) {
  const redirectEndpoint = `${configStore.get('services.addProductMiniApp').url}?cli=ONESIE1`;
  const targetClientId = 'pandora_commerce_mini_app_client';
  const targetScope = 'AdobeID,openid,additional_info.roles';

  return internalOpenBuyNowUrl(productId, redirectEndpoint, targetClientId, targetScope);
}

function isTrialOffer(product) {
  return !!(product && product.applicableOfferType === PRODUCT_APPLICABLE_OFFER_TYPE.TRIAL);
}

// Based on compliance symbol return the trial with payment status
function getComplianceSymptomsForTrialWithPayments(complianceSymptoms) {
  const activeTrial =
    complianceSymptoms?.find(
      (el) => el.name === COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION
    )?.value === 'true';

  const licenseExpireDate = complianceSymptoms?.find(
    (el) => el.name === COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION_AT
  )?.value;

  let daysLeft;
  if (licenseExpireDate) {
    daysLeft = differenceInDays(new Date(licenseExpireDate), new Date(Date.now()));
  }

  return {
    activeTrial,
    daysLeft,
    licenseExpireDate,
  };
}

/**
 * @description Method to extract compliance symptoms from the data
 *
 * @param {data} data
 * @returns {Array} complianceSymptoms
 */
function extractComplianceSymptoms(data) {
  return data?.flatMap((obj) => obj.complianceSymptoms || []);
}

/**
 * @description Method to retrieve a dayleft and expiry date of Trial with payment product
 *
 * @param {product or acquisition} product item
 * @returns {activeTrial, daysLeft, licenseExpireDate}
 */
function getTrialWithPaymentStatus(product) {
  let activeTrial, daysLeft, licenseExpireDate;

  // License level compliance symptoms
  const licenseLevelComplianceSymptomsTuples = extractComplianceSymptoms(
    product?.model?.licenseTupleList?.items
  );
  ({activeTrial, daysLeft, licenseExpireDate} = getComplianceSymptomsForTrialWithPayments(
    licenseLevelComplianceSymptomsTuples
  ));

  // If not found, then Product level compliance symptoms
  if (!activeTrial) {
    ({activeTrial, daysLeft, licenseExpireDate} = getComplianceSymptomsForTrialWithPayments(
      product?.complianceSymptoms
    ));
  }

  // If not found, then Acquisition item level compliance symptoms
  if (!activeTrial) {
    product?.acquiredOfferDistributions?.filter((acquiredOfferDistribution) => {
      const complianceSymptomsTuples = extractComplianceSymptoms(
        acquiredOfferDistribution?.acquiredOfferTuples
      );
      ({activeTrial, daysLeft, licenseExpireDate} =
        getComplianceSymptomsForTrialWithPayments(complianceSymptomsTuples));
      return activeTrial;
    });
  }

  // If not found, then Acquisition summary level compliance symptoms
  if (!activeTrial) {
    const summaryLevelComplianceSymptomsTuples = extractComplianceSymptoms(
      product?.complianceDistributions
    );
    ({activeTrial, daysLeft, licenseExpireDate} = getComplianceSymptomsForTrialWithPayments(
      summaryLevelComplianceSymptomsTuples
    ));
  }

  return {activeTrial, daysLeft, licenseExpireDate};
}

/**
 * @description Method to get total trial days for trial with payment product
 *
 * @returns {trialDays}
 */
function getTrialWithPaymentOfferPeriod(product) {
  let trialDays;
  const termValue = product?.offer?.promotion?.term_value;
  trialDays = Number.parseInt(termValue, 10);

  // If offer.promotion.term_value is undefined, then get the trial days from price point(workaround)
  if (termValue === undefined) {
    const trialDaysFromPricePoint = product?.model?.pricePoint?.startsWith('TRIAL')
      ? product?.model?.pricePoint?.split('_')[1]
      : null;
    trialDays = Number.parseInt(trialDaysFromPricePoint, 10);
  }

  return trialDays;
}

/**
 * @description Method to get trial with payment info, including activeTrial, daysLeft, periodInDays
 *
 * @param {product} product or acquisition summary
 * @returns {activeTrial, daysLeft, periodInDays}
 */
function getTrialWithPaymentInfo(product) {
  let activeTrial = false;
  let daysLeft = 0;
  let periodInDays = 0;

  if (feature.isEnabled('trial_with_payment')) {
    ({activeTrial, daysLeft} = getTrialWithPaymentStatus(product));
    periodInDays = getTrialWithPaymentOfferPeriod(product);
  }

  return {
    activeTrial,
    daysInBadge: feature.isEnabled('temp_show_days_left_in_trial_badge') ? daysLeft : periodInDays,
    daysLeft,
    periodInDays,
  };
}

const trialHelper = {
  canTrialAdminViewQAModalInFirstSession,
  getComplianceSymptomsForTrialWithPayments,
  getDxTrialContract,
  getDxTrialProduct,
  getTrialDuration,
  getTrialProduct,
  getTrialStatusInfo,
  getTrialWithPaymentInfo,
  getTrialWithPaymentOfferPeriod,
  getTrialWithPaymentStatus,
  isExpiredTrial,
  isTrialOffer,
  isTrialProduct,
  openBuyNowUrl,
  openTrialToPaidBuyNowUrl,
};

export default trialHelper;
/* eslint-enable max-lines -- simply too many methods */
