(function () {
  /**
   * @deprecated
   */
  angular.module('app.account').config(billingHistoryConfig);

  /* @ngInject */
  function billingHistoryConfig(ACCOUNT_WORKSPACE_NAV_ID, navAssemblerProvider, ROLE) {
    // add the Billing History section to the Account side nav
    navAssemblerProvider.configure({
      navID: ACCOUNT_WORKSPACE_NAV_ID,
      navValue: [
        {
          access: {
            callback: ($q, injector) =>
              injector.get('organizationAccess').canViewDirectContractBillingHistory(),
            roles: [ROLE.ADMIN.ORG],
          },
          disabled: false,
          hidden: false,
          name: 'account.billingHistory.title',
          order: 4,
          state: {
            definition: {
              component: 'binkyReactContainer',
              data: {
                title: 'account.billingHistory.title',
              },
              resolve: {
                /* @ngInject */
                component: (onesieSrc2) => onesieSrc2.account.BillingHistoryPage,
                /* @ngInject */
                props: (OrganizationManager, paymentInfoEditor) => ({
                  contract:
                    OrganizationManager.getContractsForActiveOrg().getDirectOrIndirectContract(),
                  onOpenOneTimePayment: (options = {}) => {
                    paymentInfoEditor.loadOneTimePayment({
                      contract:
                        OrganizationManager.getContractsForActiveOrg().getDirectOrIndirectContract(),
                      invoiceId: options.invoiceId,
                    });
                  },
                }),
              },
              url: '/billing-history',
            },
            name: 'account.billing-history',
          },
        },
      ],
    });
  }
})();
