(function () {
  angular.module('app.core.analytics', [
    'app.core.analytics.launch-descriptor',
    'app.core.auth',
    'app.core.cart',
    'binky.core.common.analytics',
    'binky.core.feature',
    'binky.core.js-utils',
    'binky.core.locale',
    'binky.core.src2-migration',
    'binky.shell.panels.manager',
    'ui.router',
  ]);
})();
