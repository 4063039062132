(function () {
  /**
   * @ngdoc module
   * @name app.widgets.review-organization-types
   * @description module that defines the appReviewOrganizationTypes widget
   */
  angular.module('app.widgets.review-organization-types', [
    'binky.core.lodash',
    'binky.core.organizations.market-subsegments',
    'pascalprecht.translate',
  ]);
})();
