(function () {
  /**
   * @ngdoc module
   * @name app.widgets.notifications
   * @description module that defines notifications widgets
   */
  angular.module('app.widgets.notifications', [
    'app.core.message',
    'app.core.src2-migration',
    'app.widgets.adobe-status',
    'binky.shell.navigation.tab-nav',
    'binky.shell.panels.drawer',
    'binky.ui-migration',
    'binky.widgets.common.drawer-header.drawer-header-description',
    'binky.widgets.common.feature',
    'binky.widgets.common.go-url',
    'binky.widgets.common.indeterminate',
    'ngAnimate',
  ]);
})();
