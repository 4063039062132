import PropTypes from 'prop-types';
import React, {createContext, useMemo} from 'react';

const PackageSummaryDrawerContext = createContext(null);

const PackageSummaryDrawerContextProvider = ({attributes, children}) => {
  const value = useMemo(() => ({attributes}), [attributes]);

  return (
    <PackageSummaryDrawerContext.Provider value={value}>
      {children}
    </PackageSummaryDrawerContext.Provider>
  );
};

PackageSummaryDrawerContextProvider.propTypes = {
  /**
   * Attributes used in Summary Drawer
   */
  attributes: PropTypes.shape({
    /**
     * Package details if package summary is opened
     */
    package: PropTypes.shape({
      /**
       * Package name
       */
      name: PropTypes.string,
      /**
       * Package bundle id
       */
      packageBundleId: PropTypes.string,
      /**
       * Package id
       */
      packageId: PropTypes.string,
    }),
    /**
     * Template details if template summary is opened
     */
    template: PropTypes.shape({
      /**
       * Template name
       */
      name: PropTypes.string,
      /**
       * Template bundle id
       */
      packageBundleId: PropTypes.string,
      /**
       * Template id
       */
      packageId: PropTypes.string,
    }),
  }),
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node.isRequired,
};

export default PackageSummaryDrawerContextProvider;
export {PackageSummaryDrawerContext};
