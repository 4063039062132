(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name UserRole
   * @description Model for a user's role - for example: license admin, org admin, or deployment admin
   */
  angular.module('app.core.user').factory('UserRole', userRole);

  /* @ngInject */
  function userRole(
    $translate,
    _,
    binkyProductNameLabelFilter,
    feature,
    OrganizationManager,
    ROLE
  ) {
    class UserRole {
      /**
       * @description Creates a new UserRole for use.
       *
       * @param {Object} options Options to initialize user role
       * @param {String} [options.name] - name of role, if applicable
       * @param {String} [options.type] - type of role (e.g. - LICENSE_ADMIN, ORG_ADMIN, DEPLOYMENT_ADMIN)
       * @param {Array} [options.targets] - an array of targets the user has this role for
       */
      constructor(options = {}) {
        this.name = options.name;
        this.type = options.type;
        this.targets = options.targets;
      }

      /**
       * @description Method to determine if this UserRole represents a Deployment Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a Deployment Admin, else false
       */
      isDeploymentAdmin() {
        return this.type === UserRole.DEPLOYMENT_ADMIN;
      }

      /**
       * @description Method to determine if this UserRole represents a License Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a License Admin, else false
       */
      isLicenseAdmin() {
        return this.type === UserRole.LICENSE_ADMIN;
      }

      /**
       * @description Method to determine if this UserRole represents a Org Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a Org Admin, else false
       */
      isOrgAdmin() {
        return this.type === UserRole.ORG_ADMIN;
      }

      /**
       * @description Method to determine if this UserRole represents a Product Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a Product Admin, else false
       */
      isProductAdmin() {
        return this.type === UserRole.PRODUCT_ADMIN;
      }

      /**
       * @description Method to determine if this UserRole represents a Storage Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a Storage Admin, else false
       */
      isStorageAdmin() {
        return this.type === UserRole.STORAGE_ADMIN;
      }

      /**
       * @description Method to determine if this UserRole represents a User Group Admin role or not.
       *
       * @returns {Boolean} true if this UserRole represents a User Group Admin, else false
       */
      isUserGroupAdmin() {
        return this.type === UserRole.USER_GROUP_ADMIN;
      }

      /**
       * @description returns plain JSON object containing only API relevant fields.
       * @returns {Object} minimum model object.
       */
      toMinimumModel() {
        const minModel = _(this).pick(['type', 'targets']).omitBy(_.isUndefined).value();
        if (minModel.targets) {
          minModel.targets = _.map(minModel.targets, (target) =>
            _(target).pick(['id', 'parentId']).omitBy(_.isUndefined).value()
          );
        }
        return minModel;
      }

      /**
       * @description Method to construct a UserRole for one of the top-level types
       *
       * @param {String} type of role to create (e.g. - DEPLOYMENT_ADMIN, ORG_ADMIN, etc)
       * @returns {UserRole} the requested UserRole
       */
      static get(type) {
        let role;
        switch (type) {
          case UserRole.COMPARTMENT_ADMIN:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.compartmentAdmin'),
              type,
            });
            break;
          case UserRole.COMPARTMENT_VIEWER:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.compartmentViewer'),
              type,
            });
            break;
          case UserRole.DEPLOYMENT_ADMIN:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.deployment'),
              type,
            });
            break;
          case UserRole.ORG_ADMIN:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.org'),
              type,
            });
            break;
          case UserRole.STORAGE_ADMIN:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.storage'),
              type,
            });
            break;
          case UserRole.SUPPORT_ADMIN:
            role = new UserRole({
              name: $translate.instant('core.user.role.admin.support'),
              type,
            });
            break;
          default:
            throw new Error(`Unable to resolve type (${type}) to create UserRole`);
        }
        return role;
      }

      /**
       * @description Method to construct a UserRole object for developer admins from an existing set of product license configuration (PLCs).
       *
       * @param {Array} productConfigurations instances to create UserRole from
       * @returns {UserRole} new UserRole
       */
      static getDeveloperFromPLCs(productConfigurations) {
        return new UserRole({
          targets: _.map(productConfigurations, (productConfiguration) => ({
            id: productConfiguration.id,
            name: productConfiguration.name,
            parentId: productConfiguration.parentId,
            parentName: getProductName(productConfiguration.parentId),
          })),
          type: UserRole.LICENSE_DEV_ADMIN,
        });
      }

      /**
       * @description Method to construct a UserRole from an existing set of product license configuration (PLCs).
       *
       * @param {Array} productConfigurations instances to create UserRole from
       * @returns {UserRole} new UserRole
       */
      static getFromPLCs(productConfigurations) {
        return new UserRole({
          targets: _.map(productConfigurations, (productConfiguration) => ({
            id: productConfiguration.id,
            name: productConfiguration.name,
            parentId: productConfiguration.parentId,
            parentName: getProductName(productConfiguration.parentId),
          })),
          type: UserRole.LICENSE_ADMIN,
        });
      }

      /**
       * @description Method to construct a UserRole from an existing set of products
       *
       * @param {Array} products instances to create UserRole from
       * @param {String} type role type, PRODUCT_ADMIN or PRODUCT_SUPPORT_ADMIN
       * @returns {UserRole} new UserRole
       */
      static getFromProducts(products, type) {
        return new UserRole({
          targets: _.map(products, (product) => ({
            id: product.id,
            name: getProductName(product.id),
          })),
          type: feature.isEnabled('temp_parkour_mm') ? type : UserRole.PRODUCT_ADMIN,
        });
      }

      /**
       * @description Method to construct a UserRole from an existing set of user groups
       *
       * @param {Array} userGroups instances to create UserRole from
       * @returns {UserRole} new UserRole
       */
      static getFromUserGroups(userGroups) {
        return new UserRole({
          targets: _.map(userGroups, (userGroup) => ({
            id: userGroup.id,
            name: userGroup.name,
          })),
          type: UserRole.USER_GROUP_ADMIN,
        });
      }

      static areTargetsRequiredForType(type) {
        return (
          type === UserRole.LICENSE_ADMIN ||
          type === UserRole.LICENSE_DEV_ADMIN ||
          type === UserRole.PRODUCT_ADMIN ||
          (feature.isEnabled('temp_parkour_mm') && type === UserRole.PRODUCT_SUPPORT_ADMIN) ||
          type === UserRole.USER_GROUP_ADMIN
        );
      }
    }

    UserRole.COMPARTMENT_ADMIN = ROLE.ADMIN.COMPARTMENT_ADMIN;
    UserRole.COMPARTMENT_VIEWER = ROLE.ADMIN.COMPARTMENT_VIEWER;
    UserRole.DEPLOYMENT_ADMIN = ROLE.ADMIN.DEPLOYMENT;
    UserRole.PRODUCT_ADMIN = ROLE.ADMIN.PRODUCT;
    UserRole.PRODUCT_SUPPORT_ADMIN = ROLE.ADMIN.PRODUCT_SUPPORT;
    UserRole.LICENSE_DEV_ADMIN = ROLE.ADMIN.LICENSE_DEV;
    UserRole.LICENSE_ADMIN = ROLE.ADMIN.LICENSE;
    UserRole.USER_GROUP_ADMIN = ROLE.ADMIN.USER_GROUP;
    UserRole.ORG_ADMIN = ROLE.ADMIN.ORG;
    UserRole.STORAGE_ADMIN = ROLE.ADMIN.STORAGE;
    UserRole.SUPPORT_ADMIN = ROLE.ADMIN.SUPPORT;

    /**
     * Return the constructor function
     */
    return UserRole;

    function getProductName(productId) {
      const productList = OrganizationManager.getProductsForActiveOrg();
      const product = _.find(productList.items, {id: productId});
      return product ? binkyProductNameLabelFilter(product) : '';
    }
  }
})();
