import {Button, DialogContainer, Heading} from '@adobe/react-spectrum';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import ScimConfigModal from 'features/settings/components/scim-config-modal/ScimConfigModal';

const EditSyncSection = () => {
  const [isScimConfigModalOpened, toggleScimConfigDialog] = useState(false);

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.sync.syncSettingsModal.editSync.title" />
      </Heading>
      <p>
        <FormattedMessage id="settings.sync.syncSettingsModal.editSync.description" />
      </p>
      <Button
        data-test-id="setup-sync-btn"
        onPress={() => toggleScimConfigDialog(true)}
        variant="secondary"
      >
        <FormattedMessage id="settings.sync.syncSettingsModal.editSync.button.syncSetup" />
      </Button>

      <DialogContainer onDismiss={() => toggleScimConfigDialog(false)}>
        {isScimConfigModalOpened && <ScimConfigModal />}
      </DialogContainer>
    </>
  );
};

export default EditSyncSection;
