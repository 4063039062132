import {ActionButton, Flex, Item, Menu, MenuTrigger, Text, View} from '@adobe/react-spectrum';
import Download from '@spectrum-icons/workflow/Download';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {downloadSAT} from 'features/packages/adminToolsUtils';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import {sendIngestAnalytics} from 'features/packages/pages/tools-page/toolsPageUtils';

// Download Button on card which opens up with option of downloading package for different platforms
const ToolsButton = ({
  cardButton,
  cardTitle,
  downloadUrlLinux,
  downloadUrlMac,
  downloadUrlMacArm,
  downloadUrlMacUniversal,
  downloadUrlWin,
  downloadUrlWinArm,
  isSatDownload,
  showErrorPage,
  version,
}) => {
  const intl = useIntl();

  /**
   * @description opens a url using a guard for window.open
   * @param {String} platform platform selected from the menu
   */
  function openLink(platform) {
    let url;
    if (platform === CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM) {
      url = downloadUrlMac;
    } else if (platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM) {
      url = downloadUrlMacArm;
    } else if (platform === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM) {
      url = downloadUrlMacUniversal;
    } else if (platform === CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM) {
      url = downloadUrlLinux;
    } else if (platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM) {
      url = downloadUrlWin;
    } else {
      url = downloadUrlWinArm;
    }
    if (typeof window !== 'undefined') {
      window.open(url, '_self', 'noopener,noreferrer');
    }
  }

  // method which gets called on pressing download for respective platform and redirects to downloading the package for that platform in the same tab
  const onClickLink = async (platform) => {
    sendIngestAnalytics(cardTitle, 'Download', {platform});

    // In case of Substance Automation Toolkit, we need to obtain url from backend for downloading
    if (isSatDownload) {
      try {
        await downloadSAT(platform.toLocaleLowerCase(), version?.toLocaleLowerCase());
      } catch (error) {
        showErrorPage(true);
      }
    } else {
      // In case of tools other than SAT, the url is passed and can be opened with window.open
      openLink(platform);
    }
  };
  // Number of items in menu, Boolean() constructor returns true on truthy values and false on any falsy value
  const itemsLength = [
    downloadUrlLinux,
    downloadUrlMac,
    downloadUrlMacArm,
    downloadUrlWin,
    downloadUrlWinArm,
    downloadUrlMacUniversal,
  ].filter(Boolean).length;
  if (itemsLength === 0) {
    return null;
  }
  return (
    <MenuTrigger>
      <ActionButton aria-haspopup="menu" aria-label={cardButton}>
        <Flex alignItems="center">
          <Download size="S" />
          <View paddingEnd="size-150" paddingStart="size-85">
            <FormattedMessage id="packages.tools.button.cardButton" />
          </View>
        </Flex>
      </ActionButton>
      <Menu
        aria-label={intl.formatMessage(
          {
            id: 'packages.tools.button.ariaLabel',
          },
          {numItems: itemsLength}
        )}
        onAction={(key) => onClickLink(key)}
        width="size-1700"
      >
        {downloadUrlMacUniversal && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.macUniversal',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.macUniversal" />
            </Text>
          </Item>
        )}
        {downloadUrlMac && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.mac',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.mac" />
            </Text>
          </Item>
        )}
        {downloadUrlMacArm && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.macarm',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.macarm" />
            </Text>
          </Item>
        )}
        {downloadUrlLinux && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.linux',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.linux" />
            </Text>
          </Item>
        )}
        {downloadUrlWin && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.win',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.win" />
            </Text>
          </Item>
        )}
        {downloadUrlWinArm && (
          <Item
            key={CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM}
            textValue={intl.formatMessage({
              id: 'packages.tools.adminTools.downloadPlatforms.winarm',
            })}
          >
            <Text>
              <FormattedMessage id="packages.tools.adminTools.downloadPlatforms.winarm" />
            </Text>
          </Item>
        )}
      </Menu>
    </MenuTrigger>
  );
};
ToolsButton.propTypes = {
  /**
   * The title of the button which can be read during voice over
   */
  cardButton: PropTypes.string.isRequired,
  /**
   * The title of the card
   */
  cardTitle: PropTypes.string.isRequired,
  /**
   * Link which redirects to downloading product for linux platform
   */
  downloadUrlLinux: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC system
   */
  downloadUrlMac: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC ARM system
   */
  downloadUrlMacArm: PropTypes.string,
  /**
   * Link which redirects to downloading product for MAC Universal
   */
  downloadUrlMacUniversal: PropTypes.string,
  /**
   * Link which redirects to downloading product for Windows system
   */
  downloadUrlWin: PropTypes.string,
  /**
   * Link which redirects to downloading product for Windows ARM system
   */
  downloadUrlWinArm: PropTypes.string,
  /**
   * Boolean value that tells if the button would download SAT
   */
  isSatDownload: PropTypes.bool.isRequired,
  /**
   * when downloadSAT function fails, a page with bumper is rendered through this prop
   */
  showErrorPage: PropTypes.func.isRequired,
  /**
   * The specific version of SAT build to download.  ('v1', 'v2', etc)
   */
  version: PropTypes.string,
};
export default ToolsButton;
