import {useMemo} from 'react';
import {useIntl} from 'react-intl';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const useIdpProtocol = (idp) => {
  const intl = useIntl();

  const protocol = useMemo(() => {
    if (!idp) {
      return intl.formatMessage({id: 'settings.authentication.protocols.unknown'});
    }

    return intl.formatMessage({id: `settings.authentication.protocols.${idp.federationType}`});
  }, [intl, idp]);

  return protocol;
};

export default useIdpProtocol;
