/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.users:appRemoveUserStorageUserListSectionTable
 *
 * @description The table that display list of users, with their name and email address.
 * @binding {Array} users, an array of OrganizationUser
 */
(function () {
  angular.module('app.widgets.users').component('appRemoveUserStorageUserListSectionTable', {
    bindings: {
      users: '<',
    },
    templateUrl:
      'app/widgets/users/remove-user-storage-modal/user-list-section/table/remove-user-storage-user-list-section-table.component.html',
  });
})();
