import {Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {Table, useTableSectionContext} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import PackageSummaryDrawer from '../package-summary-drawer/PackageSummaryDrawer';

/**
 * Templates list table.
 */
const TemplateListTable = observer(() => {
  const {tableSectionState} = useTableSectionContext();

  const intl = useIntl();

  return (
    <Table
      aria-label={intl.formatMessage({id: 'packages.adobeTemplates.title'})}
      noItemsFoundContentMessage={null}
      noItemsFoundHeadingMessage={intl.formatMessage({
        id: 'packages.adobeTemplates.table.empty.noTemplates.heading',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'packages.adobeTemplates.table.empty.noSearchResults.content',
      })}
      noSearchResultsHeadingMessage={intl.formatMessage({
        id: 'packages.adobeTemplates.table.empty.noSearchResults.heading',
      })}
      serverErrorContentMessage={intl.formatMessage({
        id: 'packages.adobeTemplates.table.empty.networkError.content',
      })}
      serverErrorHeadingMessage={intl.formatMessage({
        id: 'packages.adobeTemplates.table.empty.networkError.heading',
      })}
    >
      <TableHeader>
        <Column key="packageName" minWidth="50%">
          <FormattedMessage id="packages.adobeTemplates.table.headerLabels.packageName" />
        </Column>
        <Column key="summaryButton" hideHeader showDivider />
        <Column key="platformString" align="start">
          <FormattedMessage id="packages.adobeTemplates.table.headerLabels.platform" />
        </Column>
        <Column key="appsCount" align="end">
          <FormattedMessage id="packages.adobeTemplates.table.headerLabels.applications" />
        </Column>
      </TableHeader>

      <TableBody items={tableSectionState.items}>
        {(item) => (
          <Row key={item.data.id}>
            <Cell>{item.data.name}</Cell>
            <Cell>
              <PackageSummaryDrawer isTemplate summaryObj={item.data} />
            </Cell>
            <Cell>{item.data.platformString}</Cell>
            <Cell>{intl.formatNumber(item.data.getPackagedAppsCount())}</Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
});

// Needs to be set for TableSection to work.
TemplateListTable.displayName = 'TemplateListTable';
export default TemplateListTable;
