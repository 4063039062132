/* eslint-disable max-lines -- this file requires more lines */
import {
  CONTRACT_LIST_CACHE_ID,
  PRODUCT_LIST_CACHE_ID,
  PRODUCT_USER_LIST_CACHE_ID,
  feature,
  modelCache,
} from '@admin-tribe/binky';
import {getMemberDisplayName} from '@admin-tribe/binky-ui';
import {OfferType} from '@pandora/commerce-core-types';
import invokeMap from 'lodash/invokeMap';

import {COMPLIANCE_SYMPTOMS, SELF_CANCEL_MAX_LICENSES_THRESHOLD} from './SelfCancelConstants';

const UNASSIGNED_PREFIX = 'unassigned_';
const USER_ID_DIVIDER = '|';

/**
 * @description Clears the ContractList cache, ProductList cache and ProductUserList cache so the UI will update
 * after licenses have been cancelled.
 * @deprecated This method will eventually be removed.  Consumers should instead send the appropriate cacheClearingEvents
 */
const clearCaches = () => {
  modelCache.clear(CONTRACT_LIST_CACHE_ID);
  modelCache.clear(PRODUCT_LIST_CACHE_ID);
  modelCache.clear(PRODUCT_USER_LIST_CACHE_ID);
};

/**
 * @description A sort compare function for Array.sort which compares 2 items with seat
 * information and does a sort based on whether their are unassigned, the ids and the text "column".
 * It uses the item's id for a tie-breaker when both items are unassigned.
 * It uses the String.prototype.localeCompare to compare the "column" text.
 * @param {Object} a object which contains the properties "isUnassigned", "[column]" and the "id"
 * @param {Object} b object which contains the properties "isUnassigned", "[column]" and the "id"
 * @param {Object} sortDescriptor - the Spectrum v3 table sortDescriptor
 * @param {String} sortDescriptor.column - the property to use for the text sort
 * @param {Object} sortDescriptor.direction - 'ascending' or 'descending'
 * @returns {Integer} -1 if a sorts before b, 1 if b sorts before a, else 0, if a and b are considered equal.
 */
function compareFunction(a, b, {column, direction}) {
  let cmp;
  // Compare the items first by isUnassigned flag and then by the sorted column
  if (a.isUnassigned && b.isUnassigned) {
    // If both items are unassigned use id to compare and maintain relative position
    cmp = `${a.id}`.localeCompare(b.id);
  } else if (a.isUnassigned && !b.isUnassigned) {
    cmp = -1;
  } else if (!a.isUnassigned && b.isUnassigned) {
    cmp = 1;
  } else {
    cmp = `${a[column]}`.localeCompare(b[column]);
  }
  // Flip the direction if descending order is specified.
  if (direction === 'descending') {
    cmp *= -1;
  }
  return cmp;
}

/**
 * @description Returns the generated user id for cancellation.
 * If a user is passed, the generated id will have the format for assigned user id, consisting of
 * the userId and the display name. This assigned user id serialization will be used in order to
 * reduce the amount of data to be passed through the various components and the state needed to
 * show the display name in only one step.
 * If an index is passed, the generated id will have the format for the unassigned user id. This
 * unassigned user id format, will be used to decide if the user is unassigned or not.
 * @param {Object} intl - React-Intl object coming from the useIntl hook
 * @param {Object} source - The source options to generate the used id
 *        {User} source.user - The User information. If passed, the generated Id will have the
 *               assigned user id format.
 *        {Number} [source.index] - A number identifying the order for the unassigned user.
 */
const generateUserId = (intl, {user, index = 1}) =>
  user
    ? `${user.id}${USER_ID_DIVIDER}${getMemberDisplayName(intl, user, {fallbackToEmail: true})}`
    : `${UNASSIGNED_PREFIX}${index}`;

/**
 * @description Gets the cancellation details, which will be present except after a retention
 * submission.
 * @param {ProductsChange} productsChange - the products change instance containing all the necssary information
 * @returns {Object} details - The cancellation details that contains the following
 *          {Array} details.cancellingItems - the list items marked to be cancelled
 *          {Object} details.contractData - the information of the contract where the cancellation is being made
 *          {Object} details.currency - the currency information including iso code and currency format
 *          {String} details.orderNumber - the order number of this order
 *          {Number} details.orderPrice - the price of the order after cancellation is complete
 */
const getCancellationDetails = (productsChange) => productsChange?.response?.cancellation;

/**
 * @description Gets the submitted retention details, which will be only present after a retention
 * submission.
 * @param {ProductsChange} productsChange - the products change instance containing all the necssary information
 * @returns {Object} details - The submitted retention details that contains the following
 *          {String} details.id - the save offer id
 *          {Object} details.contractDetails - The details of the save offer applied to the contract
 *          {Object} details.promotion - The static definition of the promotion
 */
const getSubmittedRetentionDetails = (productsChange) =>
  productsChange?.submitted ? productsChange?.response?.retention?.[0] : undefined;

/**
 * @description Returns the user id from a given assigned user id serialization, generated by
 * generateUserId method.
 * @param {String} generatedUserId - The serialization string for a user using generateUserId
 * @returns {String} The deserialized userId
 */
const extractUserId = (generatedUserId) => generatedUserId.split(USER_ID_DIVIDER)?.[0];

/**
 * @description Returns the display name from a given assigned user id serialization, generated by
 * generateUserId method.
 * @param {String} generatedUserId - The serialization string for a user using generateUserId
 * @returns {String} The deserialized display name
 */
const extractDisplayName = (generatedUserId) => generatedUserId.split(USER_ID_DIVIDER)?.[1];

/**
 * @description Returns whether a userId string is unassigned or not.
 * @param {String} userId
 * @returns {Boolean} whether the userId is unassigned
 */
const isUnassignedUser = (userId) => `${userId}`.startsWith(UNASSIGNED_PREFIX);

/**
 * @description Returns the number of assigned licenses that can be cancelled, based on the
 * cancellation information when include_cancellation_data flag is passed to JIL Products request.
 * If not present, it will default to 0.
 * @param {Product} product the product to check
 * @returns {Number} number of assigned licenses that can be cancelled
 */
const getAssignedCancellableLicensesCount = (product) => {
  const remainingDelegations = product?.cancellation?.remainingDelegations;

  return Number.isInteger(remainingDelegations) ? remainingDelegations : 0;
};

/**
 * @description Returns the list of display names for the provided selectedSeats hash map. The array
 * will contain unique userIds matched to the display names sorted alphabetically. If multiple
 * different userIds have the same display name, it will be present as many times as unique userIds.
 * @param {Object} selectedSeats - The selected seats hash map
 * @returns {Array<strings>} - The array of display names sorted alphabetically
 */
const getDisplayNamesFromSelectedSeats = (selectedSeats) => {
  const selectedUniqueUserIdsToDisplayNames = {};
  Object.keys(selectedSeats).forEach((productId) => {
    selectedSeats[productId].forEach((generatedUserId) => {
      const displayName = extractDisplayName(generatedUserId);
      if (displayName) {
        const userId = extractUserId(generatedUserId);
        selectedUniqueUserIdsToDisplayNames[userId] = displayName;
      }
    });
  });
  return Object.values(selectedUniqueUserIdsToDisplayNames).sort();
};

/**
 * @description Returns the number of licenses that can be cancelled, based on the
 * cancellation information when include_cancellation_data flag is passed to JIL Products request.
 * If not present, it will default to 0.
 * @param {Product} product the product to check
 * @returns {Number} number of licenses that can be cancelled
 */
const getCancellableLicensesCount = (product) => {
  const cancellableQuantity = product?.cancellation?.cancellableQuantity;

  return Number.isInteger(cancellableQuantity) ? cancellableQuantity : 0;
};

/**
 * @description Returns the list of all the items of the contract during and after cancellation. If found, the
 * returned array will have an object per cancellable product containing: offer id, existing
 * quantity before the cancellation, new quantity after the cancellation, unit price and total price
 * of the line item of the total quantity and the unit price and total price of the line item of
 * remaining quantity. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Array<Object>} cancellationItems - The list of all the items of the contract after
 * cancellation. Each item will have the following
 *          {String} cancellationItems[].offerId - the offer id
 *          {Number} cancellationItems[].totalQuantity - Existing quantity before the cancellation
 *          {Number} cancellationItems[].newQuantity - New quantity after the cancellation
 *          {Object} cancellationItems[].existingPrices - Unit price and total price of the line item
 *                   of total quantity
 *          {Number} cancellationItems[].existingPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} cancellationItems[].existingPrices.unitPriceWithTax - unit price with tax
 *          {Number} cancellationItems[].existingPrices.unitTax - unit price tax
 *          {Number} cancellationItems[].existingPrices.totalWithoutTax - total price without tax
 *          {Number} cancellationItems[].existingPrices.totalWithTax - total price with tax
 *          {Number} cancellationItems[].existingPrices.totalTax - total price tax
 *          {Number} cancellationItems[].existingPrices.taxRate - tax rate
 *          {Object} cancellationItems[].newPrices - Unit price and total price of the line item of
 *                   remaining quantity
 *          {Number} cancellationItems[].newPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} cancellationItems[].newPrices.unitPriceWithTax - unit price with tax
 *          {Number} cancellationItems[].newPrices.unitTax - unit price tax
 *          {Number} cancellationItems[].newPrices.totalWithoutTax - total price without tax
 *          {Number} cancellationItems[].newPrices.totalWithTax - total price with tax
 *          {Number} cancellationItems[].newPrices.totalTax - total price tax
 *          {Number} cancellationItems[].newPrices.taxRate - tax rate
 */
const getCancellationItems = (productsChange) =>
  getCancellationDetails(productsChange)?.contractData?.contractItems;

/**
 * @description Returns the number of licenses that are cancelling, based on the given products
 * change model. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Number} number of licenses that products change model is cancelling
 */
const getAllCancellingLicensesCount = (productsChange) =>
  getCancellationItems(productsChange)?.reduce(
    (cancellingQuantity, {newQuantity, totalQuantity}) =>
      cancellingQuantity + Math.max(totalQuantity - newQuantity, 0),
    0
  );

/**
 * @description Returns the number of licenses that are remaining, based on the given products
 * change model. For full cancellation, this value will be 0. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Number} number of licenses that products change model is maintaining
 */
const getAllRemainingLicensesCount = (productsChange) =>
  getCancellationItems(productsChange)?.reduce(
    (remainingQuantity, {newQuantity}) => remainingQuantity + newQuantity,
    0
  );

/**
 * @description Returns the number of licenses that are cancelling, based on the given products
 * change model. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Number} number of assigned licenses that products change model is cancelling
 */
const getAllAssignedCancellingLicensesCount = (productsChange) =>
  productsChange?.response?.context?.cancellationItems?.reduce(
    (cancellingQuantity, {users = []}) => cancellingQuantity + users.length,
    0
  );

/**
 * @description Returns the details of a particular product in the contract after cancellation, from
 * the given products change model and the offer Id. This detail will containing: offer id, existing
 * quantity before the cancellation, new quantity after the cancellation, unit price and total price
 * of the line item of the total quantity and the unit price and total price of the line item of
 * remaining quantity. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @param {ProductOfferId} productOfferId - the offer id of the product
 * @returns {Object} cancellationItem - The details of an item of the contract after cancellation.
 *          {String} cancellationItem.offerId - the offer id
 *          {Number} cancellationItem.totalQuantity - Existing quantity before the cancellation
 *          {Number} cancellationItem.newQuantity - New quantity after the cancellation
 *          {Object} cancellationItem.existingPrices - Unit price and total price of the line item
 *                   of total quantity
 *          {Number} cancellationItem.existingPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} cancellationItem.existingPrices.unitPriceWithTax - unit price with tax
 *          {Number} cancellationItem.existingPrices.unitTax - unit price tax
 *          {Number} cancellationItem.existingPrices.totalWithoutTax - total price without tax
 *          {Number} cancellationItem.existingPrices.totalWithTax - total price with tax
 *          {Number} cancellationItem.existingPrices.totalTax - total price tax
 *          {Number} cancellationItem.existingPrices.taxRate - tax rate
 *          {Object} cancellationItem.newPrices - Unit price and total price of the line item of
 *                   remaining quantity
 *          {Number} cancellationItem.newPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} cancellationItem.newPrices.unitPriceWithTax - unit price with tax
 *          {Number} cancellationItem.newPrices.unitTax - unit price tax
 *          {Number} cancellationItem.newPrices.totalWithoutTax - total price without tax
 *          {Number} cancellationItem.newPrices.totalWithTax - total price with tax
 *          {Number} cancellationItem.newPrices.totalTax - total price tax
 *          {Number} cancellationItem.newPrices.taxRate - tax rate
 */
const getCancellationItemByOfferId = (productsChange, productOfferId) => {
  const cancellationItems = getCancellationItems(productsChange);
  return cancellationItems?.find(({offerId}) => productOfferId === offerId);
};

/**
 * @description Returns the details of a particular cancelling product, from the given products change
 * model and the offer Id. This details will containing: offer id, cancelling quantity, termination date,
 * cancelling unit price and total price. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @param {ProductOfferId} productOfferId - the offer id of the product
 * @returns {Object} cancellingItem - The details of cancelling item of the contract.
 *          {String} cancellingItem.offerId - The offer id
 *          {Number} cancellingItem.quantity - Cancelling quantity
 *          {Object} cancellingItem.terminationData - The termination data
 *          {Date} cancellingItem.terminationData.terminationDate - The termination date
 *          {Object} cancellingItem.cancellingPrices - Unit price and total price of the line item
 *                   of total quantity
 *          {Number} cancellingItem.cancellingPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} cancellingItem.cancellingPrices.unitPriceWithTax - unit price with tax
 *          {Number} cancellingItem.cancellingPrices.unitTax - unit price tax
 *          {Number} cancellingItem.cancellingPrices.totalWithoutTax - total price without tax
 *          {Number} cancellingItem.cancellingPrices.totalWithTax - total price with tax
 *          {Number} cancellingItem.cancellingPrices.totalTax - total price tax
 *          {Number} cancellingItem.cancellingPrices.taxRate - tax rate
 */
const getCancellingItemByOfferId = (productsChange, productOfferId) => {
  const cancellingItems = getCancellationDetails(productsChange)?.cancellingItems;
  return cancellingItems?.find(({offerId}) => productOfferId === offerId);
};

/**
 * @description Returns the currency for the prices inside the given products change model. If not
 * found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} currency - The currency details
 *          {String} currency.formatString - the currency format string
 *          {Boolean} currency.usePrecision - whether the currency is shown with decimal places
 */
const getCurrency = (productsChange) => getCancellationDetails(productsChange)?.currency;

/**
 * @description Returns the Early Termination Fee (ETF) prices from the given products change
 * model. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} totals - The total details for ETF amounts
 *          {Number} totals.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} totals.totalWithtTax - the amount with tax (total)
 *          {string} totals.totalTax - the tax amount
 */
const getETFAmount = (productsChange) => {
  const cancellation = getCancellationDetails(productsChange)?.orderPrice?.cancellation;
  return cancellation?.[0] || cancellation;
};

/**
 * @description Returns the existing or original billing, previous to the cancellation from the given
 * products change model. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} totals - The total details for ETF amounts
 *          {Number} totals.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} totals.totalWithtTax - the amount with tax (total)
 *          {string} totals.totalTax - the tax amount
 */
const getExistingBilling = (productsChange) =>
  getCancellationDetails(productsChange)?.contractData?.existingBilling;

/**
 * @description Returns the next or new billing, after the cancellation from the given products
 * change model. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} totals - The total details for ETF amounts
 *          {Number} totals.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} totals.totalWithtTax - the amount with tax (total)
 *          {string} totals.totalTax - the tax amount
 */
const getNextBilling = (productsChange) =>
  getCancellationDetails(productsChange)?.contractData?.nextBilling;

/**
 * @description Extracts currency, totalTax, totalWithoutTax, and totalWithTax
 * from the contract
 * @param {Contract} contract - The org's contract.
 * @returns {Object} nextBillingAmount
 *          {Object} nextBillingAmount.currency - The currency for the prices
 *          {Number} nextBillingAmount.totalTax - the tax amount
 *          {Number} nextBillingAmount.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} nextBillingAmount.totalWithTax - the amount with tax (total)
 */
const getNextBillingFromContract = (contract) => {
  const nextBillingAmount = contract?.getNextBillingAmountInfo?.();
  return {
    currency: nextBillingAmount?.currency,
    totalTax: nextBillingAmount?.total?.tax,
    totalWithoutTax: nextBillingAmount?.total?.priceWithoutTax,
    totalWithTax: nextBillingAmount?.total?.priceWithTax,
  };
};

/**
 * @description Returns the next billing after an offer has been accepted. If not found or
 *          if offer has not been accepted, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} totals - The total details for ETF amounts
 *          {Number} totals.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} totals.totalWithtTax - the amount with tax (total)
 *          {string} totals.totalTax - the tax amount
 */
const getNextBillingForRetention = (productsChange) =>
  getSubmittedRetentionDetails(productsChange)?.contractDetails?.contractData?.nextBilling;

/**
 * @description Retrieves the order number from the products change response based on the whether submission has
 *        been completed.
 * @param {ProductsChange} productsChange - the products change model to retrieve the information from
 * @returns {String} The order number. Undefined if it does not exist.
 */
const getOrderNumber = (productsChange) =>
  getSubmittedRetentionDetails(productsChange)?.contractDetails?.referenceCancelOrderNumber ||
  getCancellationDetails(productsChange)?.orderNumber;

/**
 * @description Retrieves the retention order number from the products change response based on
 *        whether submission has been completed.
 * @param {ProductsChange} productsChange - the products change model to retrieve the information from
 * @returns {String} The order number for the retention. Undefined if it does not exist.
 */
const getOrderNumberForRetention = (productsChange) =>
  getSubmittedRetentionDetails(productsChange)?.contractDetails?.orderNumber;

/**
 * @description Uses the offer list and the product list to find the product associated with the
 * offer id. To do this, an offer corresponding to the offer id is found from the offer list, and
 * that offer is then associated with a product from the product list using the product arrangement
 * code.
 *
 * @param {ProductList} productList Product list to search from
 * @param {String} offerId Offer id to associate with an offer from the offer list
 * @param {OfferList} offerList Offer list to search from
 *
 * @returns The product associated with the offer id. If not found, returns undefined.
 */
const getProductByPac = (offerId, offerList, productList) => {
  const offer = offerList?.find((offerItem) => offerItem.offer_id === offerId);
  return productList?.items?.find(
    (product) => product.productArrangementCode === offer?.product_arrangement_code
  );
};

/**
 * @description Returns the refund prices from the given products change model. If not found,
 * returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Object} refund - The total details for refund amounts
 *          {Number} refund.totalWithoutTax - the amount without tax (subtotal)
 *          {Number} refund.totalWithTax - the amount with tax (total)
 *          {String} refund.totalTax - the tax amount
 */
const getRefundAmount = (productsChange) => {
  const refund = getCancellationDetails(productsChange)?.orderPrice?.refund;
  return refund?.[0] || refund;
};

/**
 * @description Returns the list of all the save offers from the productsChange. If found, the
 * returned array will have an object per retention. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Array<Object>} retention - The list of all the items of the contract after
 * cancellation. Each item will have the following (see getRetentionItemById for more details)
 *          {String} retention[].id - the save offer id
 *          {Object} retention[].contractDetails - The details of the save offer applied to the contract
 *          {Object} retention[].promotion - The static definition of the promotion
 */
const getRetentionItems = (productsChange) => productsChange?.response?.retention;

/**
 * @description Returns the rentention object according to the rententionId. If productsChance is pre-submit,
 * returns the item from the retention array in the response. If productsChange is post submit, returns
 * the response. If the retention id does not match or if not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @param {String} retentionId - the retention Id to look up
 * @returns {Object} - The retention details, as follows:
 *          {String} retention.id - the save offer id
 *          {Object} retention.contractDetails - The details of the save offer applied to the contract
 *          {Object} retention.promotion - The static definition of the promotion
 *          {Object} retention.promotion.merchandising - The copy texts and assets
 */
const getRetentionItemById = (productsChange, retentionId) => {
  // double check that the promotions in the UI matches one in the response
  const responseId = productsChange?.response?.promotion?.id;
  if (productsChange?.submitted && responseId === retentionId) {
    return productsChange?.response;
  }
  const cancellationItems = getRetentionItems(productsChange);
  return cancellationItems?.find(({id}) => retentionId === id);
};

/**
 * @description Returns the retention's contract details from the given retention data, as defined
 * in Products Change model.
 * @param {Object} retention - the retention data to check
 * @returns {Object} - cancellation details for the Save Offer/concession
 *          {Object} contractData - Details contract after the above concession is applied
 *          {Array} orderItems - List of all the items for which concession wants to be applied
 *          {Array} concession - Metadata about the promotion
 *          {String} orderNumber - The Order Number for the  Save Offer
 */
const getRetentionDetails = (retention) => retention?.contractDetails;

/**
 * @description Returns the save offer type from the given retention data, as defined in Products
 * Change model.
 * @param {Object} retention - the retention data to check
 * @returns {String} - Save offer type, i.e. PERCENTAGE_DISCOUNT or FREE_TIME
 */
const getRetentionType = (retention) => retention?.promotion?.outcomes?.[0]?.type;

/**
 * @description Returns the save offer promotion ID from the given retention data, as defined in Products
 * Change model.
 * @param {Object} retention - the retention data to check
 * @returns {String} - Save offer promotion ID
 */
const getPromotionId = (retention) => retention?.promotion?.id;

/**
 * @description Returns the contract items from the retention information based on a retentionId. If found, the
 * returned array will have an object containing: offer id, existing quantity before the cancellation,
 * unit price and total price of the line item of the total quantity and the unit price and total price of the
 * line item of remaining quantity. If not found, returns undefined.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Array<Object>} retentionContractItems - The list of all the items of the contract after
 * cancellation. Each item will have the following
 *          {String} retentionContractItems.offerId - the offer id
 *          {Number} retentionContractItems.totalQuantity - Existing quantity before the cancellation
 *          {Object} cancellationItems.existingPrices - Unit price and total price of the line item
 *                   of total quantity
 *          {Number} retentionContractItems.existingPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} retentionContractItems.existingPrices.unitPriceWithTax - unit price with tax
 *          {Number} retentionContractItems.existingPrices.unitTax - unit price tax
 *          {Number} retentionContractItems.existingPrices.totalWithoutTax - total price without tax
 *          {Number} retentionContractItems.existingPrices.totalWithTax - total price with tax
 *          {Number} retentionContractItems.existingPrices.totalTax - total price tax
 *          {Number} retentionContractItems.existingPrices.taxRate - tax rate
 *          {Object} retentionContractItems.newPrices - Unit price and total price of the line item of
 *                   remaining quantity
 *          {Number} retentionContractItems.newPrices.unitPriceWithoutTax - unit price without tax
 *          {Number} retentionContractItems.newPrices.unitPriceWithTax - unit price with tax
 *          {Number} retentionContractItems.newPrices.unitTax - unit price tax
 *          {Number} retentionContractItems.newPrices.totalWithoutTax - total price without tax
 *          {Number} retentionContractItems.newPrices.totalWithTax - total price with tax
 *          {Number} retentionContractItems.newPrices.totalTax - total price tax
 *          {Number} retentionContractItems.newPrices.taxRate - tax rate
 */
const getRetentionContractItems = (retention) =>
  getRetentionDetails(retention)?.contractData?.contractItems;

/**
 * @description Returns the save offer merchandising from the given retention data, as defined in
 * Products Change model.
 * @param {Object} retention - the retention data to check
 * @returns {Object} - Merchandising information for the Save offer
 *          {Object} retention.copy - The texts associated with the merchandising for the save offer
 *          {Object} retention.assets - The URLs with the merchandising images for the save offer
 *          {Object} retention.links - The links associated with the save offer
 */
const getRetentionMerchandising = (retention) => retention?.promotion?.merchandising?.content;

/**
 * @description Returns whether this retention has the best total discount from the returned list of
 * save offers, as defined in Products Change model.
 * @param {Object} retention - the retention data to check
 * @returns {Boolean} - Whether this Save Offer has the best total discount
 */
const isRetentionBestValue = (retention) => getRetentionDetails(retention)?.bestValue;

/**
 * @description Returns the date when the cancellation will take effect from the given products
 * change model. If not found, returns undefined. Uses the termination date for the first cancelling
 * item, assuming all others will have the same value.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Date} the termination date
 */
const getTerminationDate = (productsChange) => {
  // Find the first item with terminationDate, discarding undefined
  const cancellingItem = getCancellationDetails(productsChange)?.cancellingItems?.find(
    (item) => item?.terminationData?.terminationDate
  );
  return cancellingItem?.terminationData?.terminationDate;
};

/**
 * @description Returns the number of unassigned licenses that can be cancelled, based on the
 * cancellation information when include_cancellation_data flag is passed to JIL Products request.
 * If not present, it will use the method to return the number of available (unassigned)
 * licenses, from the product.
 * @param {Product} product the product to check
 * @returns {Number} number of unassigned licenses that can be cancelled
 */
const getUnassignedCancellableLicensesCount = (product) => {
  const cancellableQuantity = getCancellableLicensesCount(product);
  const remainingDelegations = getAssignedCancellableLicensesCount(product);

  return Math.max(0, cancellableQuantity - remainingDelegations);
};

/**
 * @description Returns the list of display names for the provided selectedSeats hash map. The array
 * will contain unique display names sorted alphabetically.
 * @param {Object} selectedSeats - The selected seats hash map
 * @returns {Array<strings>} - The array of display names sorted alphabetically
 */
const getUserIdsFromSelectedLicenses = (selectedLicenses = []) =>
  selectedLicenses.reduce(
    (memo, userId) => (isUnassignedUser(userId) ? memo : [...memo, extractUserId(userId)]),
    []
  );

/**
 * @description Returns whether the error is a CCT Pro error or not.
 * @param {Object} error - the error to check
 * @returns {Boolean} true if error code is CCTPRO_ERROR
 */
const isCCTProError = (error) => error?.response?.data?.code === 'CCTPRO_ERROR';

/**
 * @description Returns whether the contract has a FREE_TIME offer applied and is in the discount
 * period. When a FREE_TIME offer applied and is in the discount period the subtotals for all the
 * products should be 0.
 * @param {ProductList} productList the productList to check
 * @returns {Boolean} whether the contract has a FREE_TIME offer applied
 */
const isContractInAFreeTimeOffer = (productList) =>
  productList?.items?.every((product) => product?.pricing?.total?.amountWithoutTax === 0);

/**
 * @description Returns whether the cancellation is a complete or full one from the given products
 * change model.
 * @param {ProductsChange} productsChange - the products change model to check
 * @returns {Boolean} true if all licenses are being canceled, false otherwise
 */
const isFullCancel = (productsChange) => {
  const remainingLicensesCount = getAllRemainingLicensesCount(productsChange);
  return remainingLicensesCount === 0;
};

/**
 * @description Returns whether or not all items have the same tax rate
 *
 * @param {Array} items - An array of cart item with price details inclusive of the tax rate
 * @param {Number} items[].newPrices.taxRate - the tax rate for a individual cart item
 *
 * @returns Whether or not all items in the cart have the same tax rate. If items is empty, returns true.
 */
const isMultipleTaxRate = (items) => {
  const firstItemTax = items[0]?.newPrices?.taxRate;
  // if items is empty, operates as a single tax rate. If one tax rate is missing, treat as a different rate
  // and if all tax rates are undefined, treat as a single rate and tax is undefined as with default behavior.
  return items.some((item) => item?.newPrices?.taxRate !== firstItemTax);
};

/**
 * @description Returns whether a product is cancellable, based on the cancellation information when
 * include_cancellation_data flag is passed to JIL Products request.
 * @param {Product} product the product to check
 * @returns {Boolean} whether the product is cancellable
 */
const isProductCancellable = (product) => product?.cancellation?.cancellable === true;

/**
 * @description Returns whether a product has multi price which will happen when it has any discount
 * applied. The isMultiPrice will be set when item has any discount applied to any of the purchased
 * items, which will make unitWithoutDiscount to be different than unit
 * @param {Product} product the product to check
 * @returns {Boolean} whether the product has multiple prices or has a discount applied
 */
const isProductWithMultiPriceOrDiscount = (product) =>
  product?.pricing?.items?.some(
    (item) =>
      item?.priceDetails?.unitWithoutDiscount?.amountWithoutTax !==
      item?.priceDetails?.unit?.amountWithoutTax
  ) || false;

/**
 * Return the filtered array of products from a productList that can be cancelled
 * @param {ProductList} productList the productList to check
 * @returns {Product[]} array of products that can be cancelled from the productList
 */
const getCancellableProducts = (productList) => productList?.items?.filter(isProductCancellable);

/**
 * @description Returns licenses count .
 * @param {ProductList} productList - the productList to check
 * @param {OfferType} offerType - the offerType
 * @returns {Number} the assignable license count.
 */
function getAssignableLicenseCount(productList, offerType) {
  const productListItems = offerType
    ? productList.items.filter((item) => item?.applicableOfferType === offerType)
    : productList.items;

  return invokeMap(productListItems, 'getAssignableLicenseCount').reduce(
    (sum, value) => sum + value,
    0
  );
}

/**
 * @description Check license eligible for cancel .
 * @param {ProductList} productList - the productList to check
 * @returns {Boolean} true if assigned license count <= 10 .
 */
const isLicenseCountEligibleForCancel = (productList) => {
  if (feature.isEnabled('temp_trial_offer_self_cancel_eligibility')) {
    return (
      getAssignableLicenseCount(productList) -
        getAssignableLicenseCount(productList, OfferType.TRIAL) <=
      SELF_CANCEL_MAX_LICENSES_THRESHOLD
    );
  }
  return getAssignableLicenseCount(productList) <= SELF_CANCEL_MAX_LICENSES_THRESHOLD;
};

/**
 * @description Returns whether the contract is in the cancellation period.
 * When temp_self_cancel_us_paid_expand_14_days is on, the cancellation period is always true.
 *
 * @param {Contract} contract - contract
 * @returns {Boolean} true if withing cancellation period
 */
const isInCancelEligiblePeriod = (contract) =>
  feature.isEnabled('temp_self_cancel_us_paid_expand_14_days') || contract.isInMidCycle();

/**
 * @description Basic eligibility check to allow or deny access to self-cancel workflow
 *
 * @param {Contract} contract the contract to check
 * @param {ProductList} productList the productList to check
 * @returns {Boolean} whether the eligibility check for self-cancel workflow is met
 */
const isSelfCancelEligible = (contract, productList) =>
  !contract.isInRenewalWindow() &&
  contract.isDirectContract() &&
  !contract.isDrContract &&
  contract.canCancelPromises() &&
  contract.hasActivePaymentInstrument() &&
  getCancellableProducts(productList).length > 0 &&
  !isContractInAFreeTimeOffer(productList);

/**
 * @description Eligibility check to allow or deny access to self-cancel trial workflow
 *
 * This check will be done on top of canManagePlan from src/app/account/access/account-access.service.js
 * where self-cancel manage licenses shows when Admin is allowed to add products.
 *
 * @param {Contract} contract the contract to check
 * @param {ProductList} productList the productList to check
 * @returns {Boolean} whether the eligibility check for Self Cancel workflow is met
 */
const isTrialSelfCancelEligible = (contract, productList) =>
  isSelfCancelEligible(contract, productList);

/**
 * @description Eligibility check to allow or deny access to self-cancel paid workflow, based on type of
 * direct contract and assignable license count.
 *
 * This check will be done on top of canManagePlan from src/app/account/access/account-access.service.js
 * where self cancel manage licenses shows for Direct Teams (not DR) between months 0-11 when Admin
 * is allowed to add products.
 *
 * This check ensures that only ABM with a maximum amount of licenses can access the self cancel
 * workflow. Otherwise, a bumper is shown.
 *
 * @param {Contract} contract the contract to check
 * @param {ProductList} productList the productList to check
 * @returns {Boolean} whether the eligibility check for self cancel workflow is met
 */
const isPaidSelfCancelEligible = (contract, productList) =>
  isSelfCancelEligible(contract, productList) &&
  contract.isM2M() &&
  !contract.isPUF() &&
  isInCancelEligiblePeriod(contract) &&
  isLicenseCountEligibleForCancel(productList);

/**
 * @description Eligibility check to allow or deny access to self cancel paid workflow with bumper
 * enabled. If the checks pass, the user is presented with self cancel option for paid products.
 *
 * @param {Contract} contract the contract to check
 * @param {ProductList} productList the productList to check
 * @returns {Boolean} whether the eligibility check for self cancel workflow with bumper enabled is met
 */
const isPaidSelfCancelEligibleWithBumper = (contract, productList) =>
  isSelfCancelEligible(contract, productList) && isInCancelEligiblePeriod(contract);

/**
 * @description Returns if a given user is cancellable, based on if it was already marked for
 * removal
 * @param {User} user the user to check
 * @returns {Boolean} whether the user is cancellable
 */
const isUserCancellable = (user) => user.markedForRemoval !== true;

/**
 * @description Retrieves the discount percentage associated with a retention's promotion
 * @param {Object} retention - the retention data to check
 * @returns {Number} The promotional percentage discount off of the total price
 */
const getRetentionDiscountPercent = (retention) =>
  retention?.promotion?.outcomes?.[0]?.discounts?.[0]?.amount;

/**
 * @description Retrieves the duration object of a given promotion when given a retentionId
 * @param {Object} retention - the retention data to check.
 * @returns {Object} result - object containing the dates and duration of the promotion
 *          {String} result.discountPeriod - amount of time in ISO 8601 format the promotion will last
 *          {Date} result.endDate - Date when the promotional price decrease ends
 *          {Date} result.startDate - Date when the promotional price decrease begins
 */
const getRetentionDuration = (retention) => {
  const orderItems = getRetentionDetails(retention)?.orderItems;
  return orderItems
    ?.map((item) => item.concession?.[0]?.outcomes)
    ?.find((outcomes) => !!outcomes)?.[0]?.duration;
};

/**
 * @description Retrieves the date when the promotional price ends
 * @param {Object} retention - the retention data to check
 * @returns {Date} result - Date when the promotional price ends
 */
const getRetentionDueLaterBillingDate = (retention) =>
  getRetentionDetails(retention)?.orderPrice?.dueLater?.billingDate;

/**
 * @description Return if licenses can be switched based on compliance symptom
 * @param {product} product
 * @returns {Boolean} if the licenses can be switched
 */
const isEligibleForSwitchPlan = (product) => {
  // Ignore tuple list item that have can_delete compliance symptom as true
  const eligibleItems = product?.licenseTupleList?.items?.filter(
    (item) =>
      !item.complianceSymptoms.some(
        (symptom) => symptom.name === COMPLIANCE_SYMPTOMS.CAN_DELETE && symptom.value === 'true'
      )
  );
  // Check for all remaining tuple list items to have can_switch compliance symptom as true
  return eligibleItems?.every((item) =>
    item.complianceSymptoms.some(
      (symptom) => symptom.name === COMPLIANCE_SYMPTOMS.CAN_SWITCH && symptom.value === 'true'
    )
  );
};

export {
  clearCaches,
  compareFunction,
  extractDisplayName,
  extractUserId,
  generateUserId,
  getAllAssignedCancellingLicensesCount,
  getAllCancellingLicensesCount,
  getAllRemainingLicensesCount,
  getAssignableLicenseCount,
  getAssignedCancellableLicensesCount,
  getCancellableLicensesCount,
  getCancellableProducts,
  getCancellationItemByOfferId,
  getCancellationItems,
  getCancellingItemByOfferId,
  getCurrency,
  getDisplayNamesFromSelectedSeats,
  getETFAmount,
  getExistingBilling,
  getNextBilling,
  getNextBillingForRetention,
  getNextBillingFromContract,
  getOrderNumber,
  getOrderNumberForRetention,
  getProductByPac,
  getPromotionId,
  getRefundAmount,
  getRetentionContractItems,
  getRetentionDetails,
  getRetentionDiscountPercent,
  getRetentionDuration,
  getRetentionDueLaterBillingDate,
  getRetentionItemById,
  getRetentionItems,
  getRetentionMerchandising,
  getRetentionType,
  getTerminationDate,
  getUnassignedCancellableLicensesCount,
  getUserIdsFromSelectedLicenses,
  isCCTProError,
  isEligibleForSwitchPlan,
  isFullCancel,
  isLicenseCountEligibleForCancel,
  isPaidSelfCancelEligible,
  isPaidSelfCancelEligibleWithBumper,
  isProductCancellable,
  isProductWithMultiPriceOrDiscount,
  isRetentionBestValue,
  isTrialSelfCancelEligible,
  isMultipleTaxRate,
  isUnassignedUser,
  isUserCancellable,
};
/* eslint-enable max-lines -- this file requires more lines */
