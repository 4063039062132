import {LinkButton, Pagination} from '@admin-tribe/binky-ui';
import {
  Cell,
  Column,
  DialogContainer,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import DomainStatus from 'features/settings/common/components/domain-status/DomainStatus';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import ValidateDomainModal from 'features/settings/components/domains/validate-domain-modal/ValidateDomainModal';

import EmptyStateMessage from './EmptyStateMessage';

const DirectoryDomainsTable = observer(({refreshDomains}) => {
  const {directoryStore} = useDirectoryContext();
  const [validateDomainModal, setValidateDomainModal] = useState({
    domain: undefined,
    isOpen: false,
  });

  const intl = useIntl();

  // Event handlers
  const onGoNext = () => {
    directoryStore.domains.goToNextPage();
  };

  const onGoPrevious = () => {
    directoryStore.domains.goToPreviousPage();
  };

  const onPageSizeChange = (newPageSize) => {
    directoryStore.domains.onPageSizeChange(newPageSize);
  };

  const onSortChange = (descriptor) => {
    directoryStore.domains.sortBy(descriptor);
  };

  const onValidatePressed = (domain) => {
    setValidateDomainModal({domain, isOpen: true});
  };

  const onDomainValidated = () => {
    directoryStore.domains.load();
    refreshDomains({clearCache: true});
  };

  return (
    <>
      <TableView
        aria-label={intl.formatMessage({id: 'settings.directoryDomains.table.ariaLabel'})}
        data-testid="directory-domains-table"
        density={directoryStore.domains.tableStore.density}
        disabledKeys={directoryStore.domains.tableStore.disabledKeys}
        height={directoryStore.domains.tableStore.isEmptyTable ? '400px' : undefined}
        onSortChange={onSortChange}
        renderEmptyState={() => <EmptyStateMessage />}
        sortDescriptor={directoryStore.domains.tableStore.sortDescriptor}
      >
        <TableHeader>
          <Column key="domainName" allowsSorting minWidth="50%">
            <FormattedMessage id="settings.directoryDomains.nameHeader" />
          </Column>

          <Column key="status">
            <FormattedMessage id="settings.directoryDomains.statusHeader" />
          </Column>

          <Column key="actions" />
        </TableHeader>

        <TableBody items={directoryStore.domains.tableStore.rows}>
          {directoryStore.domains.tableStore.rows.map((item) => (
            <Row key={item.data.domainName}>
              <Cell>{item.data.domainName}</Cell>
              <Cell>
                <DomainStatus status={item.data.status} />
              </Cell>
              <Cell>
                {item.data.needsValidation && (
                  <LinkButton onClick={() => onValidatePressed(item.data)}>
                    <FormattedMessage id="settings.directoryDomains.validateAction" />
                  </LinkButton>
                )}
              </Cell>
            </Row>
          ))}
        </TableBody>
      </TableView>

      {!directoryStore.domains.tableStore.isEmptyTable && (
        <Pagination
          currentPage={directoryStore.domains.tableStore.currentPage}
          onGoNext={onGoNext}
          onGoPrevious={onGoPrevious}
          onPageSizeChange={onPageSizeChange}
          pageSize={directoryStore.domains.tableStore.pageSize}
          selectedItemCount={directoryStore.domains.tableStore.selectedItemCount}
          totalPages={directoryStore.domains.tableStore.totalPages}
        />
      )}

      <DialogContainer onDismiss={() => setValidateDomainModal({isOpen: false})}>
        {validateDomainModal.isOpen && (
          <ValidateDomainModal
            domains={[validateDomainModal.domain]}
            onDomainsValidated={onDomainValidated}
          />
        )}
      </DialogContainer>
    </>
  );
});

export default DirectoryDomainsTable;
