const TRIAL_STATUS = {
  DAYS_LEFT_INFO: 'DAYS_LEFT_INFO',
  DAYS_LEFT_WARNING: 'DAYS_LEFT_WARNING',
  EXPIRED: 'EXPIRED',
  LAST_DAY: 'LAST_DAY',
  NOT_TRIAL: 'NOT_TRIAL',
};

const COMPLIANCE_SYMPTOMS = {
  CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION: 'can_message_upcoming_trial_expiration',
  CAN_MESSAGE_UPCOMING_TRIAL_EXPIRATION_AT: 'can_message_upcoming_trial_expiration_at',
};

export {COMPLIANCE_SYMPTOMS, TRIAL_STATUS};
