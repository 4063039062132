(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.sophia:SophiaCardHelper
   * @description cart helper factory
   */
  angular.module('app.widgets.sophia').factory('SophiaCardHelper', SophiaCardHelper);
  /* @ngInject */
  function SophiaCardHelper(
    $location,
    $log,
    $stateParams,
    $window,
    _,
    addUserGlobalModal,
    quickAssignGlobalModal,
    SURFACE_ID,
    trialHelper,
    urlUtils,
    videoGlobalModal
  ) {
    const BUTTON_VARIANTS = {
      CTA: 'cta',
      DEFAULT: '',
      QUIET_SECONDARY: 'quietsecondary',
      SECONDARY: 'secondary',
    };
    const COLORS = {
      COLOR_GREY_50: '#ffffff',
      COLOR_GREY_800: '#4b4b4b',
      COLOR_GREY_900: '#2c2c2c',
    };

    const service = {
      getClassesForCard,
      getContentObject,
      getCtaClickEventCallback,
      getPrimaryCTAButtonVariant,
      getSecondaryCTAButtonVariant,
      getStyleObj,
    };

    return service;

    //////////////

    function getContentObject(card) {
      const cardProperties = [
        'bodyCopy',
        'cardLabel',
        'ctaLabel',
        'displayText',
        'eventData',
        'secondaryCTALabel',
      ];

      return _.pick(card, cardProperties);
    }

    /**
     * The callback function to handle a sophia button's click event
     * @param {String} event The global modal ids start with https://adminconsole.adobe.com/#, special case
     *   callbacks start with #. Otherwise, it is a regular url.
     * @returns {Function} Returns a callback function based on the input event.
     */
    function getCtaClickEventCallback(event) {
      if (_.startsWith(event, 'https://adminconsole.adobe.com/#')) {
        const {modal, params} = getGlobalModal(event);
        return () => _.invoke(modal, 'open', params);
      }
      if (_.startsWith(event, 'https://adminconsole.adobe.com/link#')) {
        return getSpecialCaseCallback(event);
      }
      // indicates that the intent is to remain in admin console after other preceding cases have been exhausted
      if (_.startsWith(event, 'https://adminconsole.adobe.com')) {
        return navigateToUrlCallback(event);
      }

      return () => $window.open(event, '_blank');
    }

    function getClassesForCard(card = {}) {
      const cardClasses = ['sophia-card'];
      const surfaceId = card.surfaceId;

      switch (surfaceId) {
        case SURFACE_ID.ONE_CONSOLE:
          if (card.containerId === '2') {
            // Sophia banner for the First Mile project
            cardClasses.push('overview-tutorial');
          }
          break;
        case SURFACE_ID.ONE_CONSOLE_ACCOUNT:
        case SURFACE_ID.ONE_CONSOLE_PACKAGES:
        case SURFACE_ID.ONE_CONSOLE_PRODUCTS:
        case SURFACE_ID.ONE_CONSOLE_SETTINGS:
        case SURFACE_ID.ONE_CONSOLE_SUPPORT:
        case SURFACE_ID.ONE_CONSOLE_USERS:
          // Sophia banner for the First Mile project
          cardClasses.push('tutorial');
          break;
        case SURFACE_ID.ONE_CONSOLE_PROMOTION:
          cardClasses.push('promotion');
          break;
        default:
          break;
      }

      return cardClasses;
    }

    function getPrimaryCTAButtonVariant(card) {
      const {eventData} = card.getCard();

      const ctaButtonVariant = _.get(eventData, 'ctaButtonVariant');

      if (_.isEmpty(ctaButtonVariant)) {
        return getDefaultCTAButtonVariant(card);
      }
      return ctaButtonVariant;
    }

    function getSecondaryCTAButtonVariant(card) {
      const {eventData} = card.getCard();
      const secondaryCTAButtonVariant = _.get(eventData, 'secondaryCTAButtonVariant');
      if (_.isEmpty(secondaryCTAButtonVariant)) {
        return getDefaultSecondaryCTAButtonVariant(card);
      }
      return secondaryCTAButtonVariant;
    }

    function getStyleObj(card) {
      const {
        backgroundFillColor,
        backgroundImage,
        bodyCopyAlignment,
        ctaAlignment,
        displayTextAlignment,
        eventData,
      } = card;

      return _.pickBy({
        bodyCopy: {
          color: assignWithDefault(
            _.get(eventData, 'headerFontColor'),
            getDefaultHeaderFontColor(card)
          ),
          'text-align': assignWithDefault(bodyCopyAlignment, 'left'),
        },
        cta: {
          'text-align': assignWithDefault(ctaAlignment, 'left'),
        },
        displayText: {
          color: assignWithDefault(
            _.get(eventData, 'descriptionFontColor'),
            getDefaultDescriptionFontColor(card)
          ),
          'text-align': assignWithDefault(displayTextAlignment, 'center'),
        },
        global: {
          'background-color': assignWithDefault(backgroundFillColor, null),
          'background-image': backgroundImage ? `url('${backgroundImage}')` : null,
        },
      });
    }

    /* Private functions */

    function assignWithDefault(value, defaultValue) {
      return _.isEmpty(value) ? defaultValue : value;
    }

    function getGlobalModal(eventName) {
      const baseUrl = _.split(eventName, '?')[0];
      switch (baseUrl) {
        case 'https://adminconsole.adobe.com/#add-user':
          return {modal: addUserGlobalModal};
        case 'https://adminconsole.adobe.com/#quick-assign':
          return {modal: quickAssignGlobalModal};
        case 'https://adminconsole.adobe.com/#video-modal':
          return {
            modal: videoGlobalModal,
            params: {videoUrl: urlUtils.getValueForQueryParam(eventName, 'url')},
          };
        default:
          return {};
      }
    }

    function navigateToUrlCallback(eventName) {
      const eventUrl = new URL(eventName);

      const orgId = $stateParams.orgId;

      // // navigate to relative path with orgId and eventUrl path and any query params
      // // if no path admin console redirects to overview
      return () => $location.path(`/${orgId}${eventUrl.pathname}${eventUrl.search}`);
    }

    //
    function getSpecialCaseCallback(eventName) {
      switch (eventName) {
        case 'https://adminconsole.adobe.com/link#biz-trial-buy-now': {
          // This uses a jumpUrl which has to be constructed right before use since it is based on our current auth
          // token as well as the current org, the trial product id and the current locale.
          const productId = _.get(trialHelper.getTrialProduct(), 'id');
          if (productId) {
            return () => {
              trialHelper.openBuyNowUrl(_.get(trialHelper.getTrialProduct(), 'id'));
            };
          }
          return errorCase();
        }
        default:
          return errorCase();
      }

      ////////////

      function errorCase() {
        $log.error(`sophiaCardHelper: no callback for ${eventName}`);
        return _.noop;
      }
    }

    function getDefaultCTAButtonVariant(card) {
      switch (card.surfaceId) {
        case SURFACE_ID.ONE_CONSOLE_PROMOTION:
          return BUTTON_VARIANTS.DEFAULT;
        default:
          return BUTTON_VARIANTS.CTA;
      }
    }

    function getDefaultSecondaryCTAButtonVariant(card) {
      switch (card.surfaceId) {
        case SURFACE_ID.ONE_CONSOLE_PROMOTION:
          return BUTTON_VARIANTS.QUIET_SECONDARY;
        default:
          return BUTTON_VARIANTS.SECONDARY;
      }
    }

    function getDefaultHeaderFontColor(card) {
      switch (card.surfaceId) {
        case SURFACE_ID.ONE_CONSOLE:
          return card.containerId === '2' ? COLORS.COLOR_GREY_900 : COLORS.COLOR_GREY_50;
        default:
          return COLORS.COLOR_GREY_900;
      }
    }

    function getDefaultDescriptionFontColor(card) {
      switch (card.surfaceId) {
        case SURFACE_ID.ONE_CONSOLE:
          return card.containerId === '2' ? COLORS.COLOR_GREY_800 : COLORS.COLOR_GREY_50;
        default:
          return COLORS.COLOR_GREY_800;
      }
    }
  }
})();
