import {Page, PageBanners, PageContent, PageHeader, PageNav} from '@admin-tribe/binky-ui';
import {Grid, repeat} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';

import ToolsCard from '../../components/ToolsComponent/tools-card/ToolsCard';
import {goBack} from '../../components/preferences-page/preferencesPageUtils';
import PackagesEntitlements from '../../services/PackagesEntitlementsService';

import {getCardDetails} from './toolsPageUtils';

// Tools page component
const ToolsPage = observer(({constantsStore = rootStore.packagesUiConstantsStore}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(
    Object.keys(constantsStore.uiConstantsMap).length === 0 ||
      !PackagesEntitlements.hasFetchedAllEntitlements
  );

  const [isBumpered, setIsBumpered] = useState(false);

  // this side effect is to fetch constants and fetch entitlements when a category selected
  useEffect(() => {
    let isMounted = true;
    let entitlementsError = false;
    const fetchData = async () => {
      setIsLoading(true);
      await constantsStore.fetchUiConstants();
      try {
        await PackagesEntitlements.fetchEntitlements();
      } catch (error) {
        entitlementsError = true;
      } finally {
        if (isMounted) {
          setIsBumpered(constantsStore.hasLoadingError || entitlementsError);
          setIsLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, []);

  return (
    <Page
      bumperCtaButtonLabel={intl.formatMessage({id: 'packages.preferences.bumper.goBack'})}
      data-testid="tools-page"
      isBumpered={isBumpered}
      isLoading={isLoading}
      onClickBumper={goBack}
    >
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PACKAGES} />
      </PageBanners>
      <PageHeader
        title={intl.formatMessage({
          id: 'packages.tools.title',
        })}
      />
      <PageNav />
      <PageContent>
        <TitledSection
          data-testid="tools-titled-section"
          dividerPlacement={DividerPlacement.NONE}
          headingLevel={2}
          marginBottom="size-150"
          ruleVariant="S"
          title={intl.formatMessage({id: 'packages.tools.toolsHeading'})}
        />

        <Grid
          autoRows="size-4600"
          columns={repeat('auto-fit', 'calc(size-3600 + size-150)')}
          gap="size-200"
          marginTop="size-200"
        >
          {getCardDetails(intl).map(
            (card) =>
              card.showCard() && (
                <ToolsCard
                  key={card.title}
                  aria-label={card.ariaLabel}
                  details={card.details}
                  downloadButton={card.downloadButton}
                  downloadUrlLinux={card.downloadUrlLinux}
                  downloadUrlMac={card.downloadUrlMac}
                  downloadUrlMacArm={card.downloadUrlMacArm}
                  downloadUrlMacUniversal={card.downloadUrlMacUniversal}
                  downloadUrlWin={card.downloadUrlWin}
                  downloadUrlWinArm={card.downloadUrlWinArm}
                  icons={card.icons}
                  isSatDownload={card.isSatDownload}
                  name={card.name}
                  showErrorPage={setIsBumpered}
                  showLearnMore={card.showLearnMore}
                  title={card.title}
                  version={card.version}
                />
              )
          )}
        </Grid>
      </PageContent>
    </Page>
  );
});
export default ToolsPage;
