import {
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ExportDeactivatedDevicesButton from 'common/components/export-deactivated-devices-button/ExportDeactivatedDevicesButton';
import auth from 'core/services/auth';

import useDeactivatedTableSorter from '../../hooks/useDeactivatedTableSorter';

import DeactivatedDevicesTable from './DeactivatedDevicesTable';

/**
 * product deactivated devices section table
 */
const ProductDeactivatedDevicesTable = ({deactivatedDevicesList}) => {
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentTotalPages, setCurrentTotalPages] = useState(1);
  const {sorter} = useDeactivatedTableSorter(deactivatedDevicesList);
  const intl = useIntl();

  const onTableSectionChange = ({action, payload}) => {
    let currentRecords, indexOfFirstRecord, indexOfLastRecord, sortedList;
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        indexOfLastRecord = payload * currentPageSize;
        indexOfFirstRecord = indexOfLastRecord - currentPageSize;
        // Records to be displayed on the current page
        currentRecords = deactivatedDevicesList.slice(indexOfFirstRecord, indexOfLastRecord);
        setCurrentItems(currentRecords);
        setCurrentPageNumber(payload);
        return payload;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        setCurrentTotalPages(Math.ceil(deactivatedDevicesList.length / payload));
        setCurrentPageSize(payload);
        setCurrentPageNumber(1);
        return payload;
      case TABLE_SECTION_ACTIONS.ON_SORT_BY:
        sortedList = sorter(payload);
        indexOfLastRecord = currentPageNumber * currentPageSize;
        indexOfFirstRecord = indexOfLastRecord - currentPageSize;
        // Records to be displayed on the current page
        currentRecords = sortedList.slice(indexOfFirstRecord, indexOfLastRecord);
        setCurrentItems(currentRecords);
        return payload;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        setCurrentItems(deactivatedDevicesList.filter((item) => item.deviceName === payload.value));
        return payload;
      default: {
        setCurrentItems(deactivatedDevicesList.slice(0, currentPageSize));
        return payload;
      }
    }
  };

  // update the total pages and current items when pagesize and deactivated devices changes
  React.useEffect(() => {
    const sortedList = sorter({id: 'lastActivityDate', sortedOrder: 'ascending'});
    setCurrentItems(sortedList.slice(0, currentPageSize));
    setCurrentTotalPages(Math.ceil(deactivatedDevicesList.length / currentPageSize));
  }, [currentPageSize, deactivatedDevicesList, sorter]);

  return (
    <TableSection
      getItemKey={(item) => item.poolId}
      isServerError={false}
      items={currentItems.map((item, index) => ({
        ...item,
        id: index,
      }))}
      onTableSectionChange={onTableSectionChange}
      pageNumber={currentPageNumber}
      totalPages={currentTotalPages}
    >
      <TableFilters
        hasSearch
        label={intl.formatMessage({id: 'products.devices.search'})}
        noSearchResultsContentMessage={intl.formatMessage({
          id: 'products.details.deactivated.devices.noSearchResultsContentMessage',
        })}
      />
      <TableActions>{auth.canExportDevices() && <ExportDeactivatedDevicesButton />}</TableActions>
      <DeactivatedDevicesTable />
    </TableSection>
  );
};

ProductDeactivatedDevicesTable.propTypes = {
  /**
   * The list of devices to be populated in the table
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  deactivatedDevicesList: PropTypes.arrayOf(PropTypes.object),
};

export default ProductDeactivatedDevicesTable;
