import React from 'react';

/**
 * Context for the widget
 *
 * It would contain the following properties:
 *
 * @property {Function} closeModal to close the modal
 * @property {Boolean} isLoading if the widget is loading
 * @property {Boolean} notificationsEnabled if notifications are enabled
 * @property {Function} onSubmit to create the rule
 * @property {String} productName name of the matched product
 * @property {Date} ruleDate when the rule is scheduled to be active
 * @property {Function} setNotificationsEnabled to set notificationsEnabled
 */
const JitRuleAutoCreateContext = React.createContext(null);

export default JitRuleAutoCreateContext;
