import {Flex, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

// EmptyAvailableProducts displayed when available products section is empty
const EmptyAvailableProducts = ({content, title}) => (
  <Flex
    alignItems="center"
    direction="column"
    height="100%"
    justifyContent="center"
    UNSAFE_style={{textAlign: 'center'}}
  >
    <Text UNSAFE_style={{fontWeight: 'bold'}}>{title}</Text>
    <View marginEnd="size-250" marginStart="size-250">
      {content}
    </View>
  </Flex>
);

EmptyAvailableProducts.propTypes = {
  /**
   * Content to be displayed in empty available products section
   */
  content: PropTypes.string.isRequired,
  /**
   * Title to be displayed in empty available products section
   */
  title: PropTypes.string.isRequired,
};
export default EmptyAvailableProducts;
