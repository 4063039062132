/**
 * @deprecated use src2 Overview page
 *
 * @ngdoc component
 * @name appUpdatesSummarySection
 *
 * @description The Updates Summary info section in the Overview page.
 */
(function () {
  const componentName = 'appUpdatesSummarySection';

  angular.module('app.overview').component(componentName, {
    controller,
    templateUrl:
      'app/overview/overview/updates-summary-section/updates-summary-section.component.html',
  });
  /* @ngInject */
  function controller(_, $filter, $window, AnalyticsEvent, locale, OrganizationManager) {
    const UPDATE_SUMMARY_LINKS = [
      {
        goUrlName: 'aac_overview_cc_updates',
        iconClass: 'cc-updates-icon',
        id: 'overview-updates-cc-link',
        translateKey: '.creativeCloudUpdates',
      },
      {
        goUrlName: 'aac_overview_dc_updates',
        iconClass: 'dc-updates-icon',
        id: 'overview-updates-dc-link',
        translateKey: '.documentCloudUpdates',
      },
      {
        goUrlName: 'aac_overview_xc_updates',
        iconClass: 'xc-updates-icon',
        id: 'overview-updates-xc-link',
        translateKey: '.experienceCloudUpdates',
      },
    ];
    const CHINESE_LANGUAGE_CODES = ['zh-cn', 'zh-tw'];

    const vm = this;
    _.assign(vm, {
      openGoUrl,
      showUpdatesSummarySection: showUpdatesSummarySection(),
      UPDATE_SUMMARY_LINKS,
    });

    function openGoUrl(goUrlName) {
      triggerAnalytics(goUrlName);
      $window.open($filter('goUrl')(goUrlName), '_blank');
    }

    /////

    function showUpdatesSummarySection() {
      // This condition is due to legal guidance. Adobe's cloud features are not supposed to be
      // actively communicated or promoted to anyone in China.
      // To make things easier, we will also hide this when the site language is set to Chinese.
      // See https://git.corp.adobe.com/admin-tribe/onesie/issues/12083 for details.
      return (
        !_.includes(CHINESE_LANGUAGE_CODES, locale.getActiveLanguage()) &&
        !OrganizationManager.activeOrgHasChinaContract()
      );
    }

    function triggerAnalytics(goUrlName) {
      AnalyticsEvent.dispatch({
        attributes: {
          goUrlName,
        },
        componentMethod: 'goUrl',
        componentMethodType: 'click',
        componentName,
      });
    }
  }
})();
