import {Consumable} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {notify} from 'core/products/notifier/productGroupNotifier';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';

import Banners from './Banners';

/**
 * ProductGroupBanners display a PageBanner for each message provided by the productGroupNotifier
 */
const ProductGroupBanners = ({consumable, productGroup}) => {
  const intl = useIntl();

  const messagesToDisplay = notify({consumable, intl, productGroup});

  return <Banners messagesToDisplay={messagesToDisplay} />;
};

ProductGroupBanners.propTypes = {
  /**
   * Consumable to construct banners from
   */
  consumable: PropTypes.instanceOf(Consumable),
  /**
   * Product group product list to construct banners from
   */
  productGroup: PropTypes.instanceOf(ProductGroupProductList).isRequired,
};

export default ProductGroupBanners;
