// Imports each Angular components/directive HTML template from src/app and adds
// adds it to Angular's $templateCache at the URL expected by the legacy Angular
// source.
const angularTemplateCacheLoader = [
  '$templateCache',
  ($templateCache) => {
    // eslint-disable-next-line no-undef -- this is a valid use of webpack's require function
    const context = require.context('../../../../src/app/', true, /.*\.html$/);
    context.keys().forEach((key) => {
      const templateUrl = key.replace(/^.\//, 'app/');
      const html = context(key);
      $templateCache.put(templateUrl, html);
    });
  },
];

export default angularTemplateCacheLoader;
