import {action, computed, makeObservable, observable} from 'mobx';

import TableConstants from '../components/table/TableConstants';

/**
 * @deprecated use Pandora TableSection that has table state management built-in:
 *  https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-table-section
 *  The built-in state management uses the ListContainer package:
 *  https://git.corp.adobe.com/PandoraUI/ui-components/tree/master/packages/react-list-container
 */
class TableStore {
  currentPage = 1;
  pageSize;
  rows = [];
  selectedItems = {};
  sortedColumns = {};

  totalItems;

  constructor() {
    makeObservable(this, {
      canSelectAll: computed,
      currentPage: observable,
      goNext: action,
      goPrevious: action,
      pageSize: observable,
      rows: observable,
      selectableRowsCount: computed,
      selectAll: action,
      selectedItems: observable,
      selectedRowsCount: computed,
      selectionStatus: computed,
      selectItem: action,
      setCurrentPage: action,
      setPageSize: action,
      setTotalItems: action,
      sortColumn: action,
      sortedColumns: observable,
      totalItems: observable,
      totalPages: computed,
    });
  }

  get canSelectAll() {
    return this.rows.filter((row) => row.selectable).length > 0;
  }

  goNext() {
    this.currentPage += 1;
  }

  goPrevious() {
    this.currentPage -= 1;
  }

  mapDataToRows(data, isSelectable = () => {}) {
    this.rows = data.map((item) => ({
      data: item,
      id: item.id,
      selectable: isSelectable(item) ?? true,
      selected: !!this.selectedItems[item.id],
    }));
  }

  get selectableRowsCount() {
    return this.rows.filter((row) => row.selectable).length;
  }

  selectAll() {
    const isSelected =
      this.selectionStatus === TableConstants.SELECTION_STATUS.NONE ||
      this.selectionStatus === TableConstants.SELECTION_STATUS.PARTIAL;

    this.rows = this.rows.map((row) => {
      if (row.selectable) {
        this.selectedItems[row.id] = isSelected;
        return {...row, selected: isSelected};
      }

      return row;
    });
  }

  get selectedRowsCount() {
    return this.rows.filter((row) => row.selected).length;
  }

  get selectionStatus() {
    if (!this.selectableRowsCount) {
      return TableConstants.SELECTION_STATUS.NONE;
    }

    if (this.rows.length > 0 && this.selectedRowsCount === this.selectableRowsCount) {
      return TableConstants.SELECTION_STATUS.FULL;
    }

    // if we have some rows selected or we have a partial selection
    if (this.selectedRowsCount > 0) {
      return TableConstants.SELECTION_STATUS.PARTIAL;
    }

    return TableConstants.SELECTION_STATUS.NONE;
  }

  selectItem(id) {
    const row = this.rows.find((item) => item.id === id);
    row.selected = !row.selected;
    this.selectedItems[row.id] = row.selected;
  }

  setCurrentPage(currentPage) {
    this.currentPage = currentPage;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize;
  }

  setTotalItems(totalItems) {
    this.totalItems = totalItems;
  }

  sortColumn(id) {
    this.sortedColumns[id] = reverseSortDirection(
      this.sortedColumns[id] || TableConstants.SORTING_DIRECTIONS.ASC
    );
  }

  get totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }
}

// Helpers
function reverseSortDirection(direction) {
  return direction === TableConstants.SORTING_DIRECTIONS.ASC
    ? TableConstants.SORTING_DIRECTIONS.DESC
    : TableConstants.SORTING_DIRECTIONS.ASC;
}

export default TableStore;
