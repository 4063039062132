(function () {
  angular.module('app.core.organizations.user').factory('organizationUserUtils', utils);
  /**
   * @deprecated Portions of this file have been ported to src2. Future implementation should be completed there.
   */
  /* @ngInject */
  function utils(
    $log,
    _,
    binkySrc2,
    OrganizationManager,
    Product,
    ProductConfiguration,
    ROLE,
    UserGroup,
    UserRole
  ) {
    let service = {
      initDeveloperProducts,
      initProductConfigurations,
      initProducts,
      initRoles,
      initUserGroups,
    };

    service = _.assign(
      service,
      _.pick(binkySrc2.services.organization.OrganizationUserUtils, [
        'compareProductLicenseGroups',
        'compareUserGroups',
        'explodeProductLicenseGroups',
        'getProductLicensePatches',
        'getUserGroupPatches',
      ]),
      _.pick(binkySrc2.models.user.UserRoleUtils, [
        'compareAdminRoles',
        'explodeAdminRoles',
        'getAdminRolePatches',
      ])
    );

    return service;

    /**
     * @description Method to initialize a User's developer products.
     *
     * @param {Array} roles - Array containing user roles data
     *
     * @return {Array} the list of created Products
     */
    function initDeveloperProducts(roles) {
      const developerProducts = _(roles)
        .filter({type: ROLE.ADMIN.LICENSE_DEV})
        .flatMap('targets')
        .groupBy('parentId')
        .map((lgArray) => ({
          id: lgArray[0].parentId,
          licenseGroups: lgArray,
        }))
        .value();
      return developerProducts ? initProducts(developerProducts) : [];
    }

    /**
     * @description Method to initialize a User's products.
     *
     * @param {Array} products - Array containing user product data
     *
     * @return {Array} the list of created Products
     */
    function initProducts(products) {
      const orgProducts = OrganizationManager.getProductsForActiveOrg();
      return _.map(products, (product) => {
        const foundProduct = _.find(orgProducts.items, {id: product.id});
        if (foundProduct) {
          return new Product(
            _.assignIn({}, foundProduct, {
              licenseGroups: _.map(
                product.licenseGroups,
                (licenseGroup) => new ProductConfiguration(licenseGroup)
              ),
            })
          );
        }
        if (product instanceof Product) {
          return product;
        }
        $log.error(`organizationUserUtils.initProducts(): product ${product.id} not found`);
        return _.assignIn({}, product, {initFailed: true});
      });
    }

    /**
     * @description Method to initialize a User's product configurations so
     *              that each Product listed contains the full Product name to
     *              be used when displaying that Product via the UI.
     *
     * @param {Array} productConfigurations - Array containing user product config data
     *
     * @return {Array} the list of created ProductConfigurations
     */
    function initProductConfigurations(productConfigurations) {
      const orgProducts = OrganizationManager.getProductsForActiveOrg();
      return _.map(
        productConfigurations,
        (productConfiguration) =>
          new ProductConfiguration(
            _.assignIn({}, productConfiguration, {
              product: _.find(orgProducts.items, {id: _.get(productConfiguration, 'product.id')}),
            })
          )
      );
    }

    /**
     * @description Method to initialize a User's roles so that each Role listed
     *              contains an actual Role Object/prototype to use.
     *
     * @param {Array} roles - Array containing user role data
     *
     * @return {Array} the list of created UserRoles
     */
    function initRoles(roles) {
      const orgProducts = OrganizationManager.getProductsForActiveOrg();
      return _(roles)
        .map((role) => {
          // we default to this to allow us to filter invalid elements
          // (for example, a PLC_ADMIN with no PLC details)
          let foundRole = 'NOT_FOUND';
          // check for 'role' attribute since that is what API returns (we cast
          // to Role.type attribute when creating a Role Object in the UI)
          switch (role.type) {
            case UserRole.LICENSE_DEV_ADMIN:
              if (role.targets) {
                foundRole = UserRole.getDeveloperFromPLCs(role.targets);
              } else {
                $log.error(`Failed to process LICENSE_DEV_ADMIN role: ${role}`);
              }
              break;
            case UserRole.LICENSE_ADMIN:
              if (role.targets) {
                foundRole = UserRole.getFromPLCs(role.targets, orgProducts.items);
              } else {
                $log.error(`Failed to process LICENSE_ADMIN role: ${role}`);
              }
              break;
            case UserRole.PRODUCT_ADMIN:
            case UserRole.PRODUCT_SUPPORT_ADMIN:
              if (role.targets) {
                foundRole = UserRole.getFromProducts(role.targets, role.type);
              } else {
                $log.error(`Failed to process ${role.type} role: ${role}`);
              }
              break;
            case UserRole.USER_GROUP_ADMIN:
              if (role.targets) {
                foundRole = UserRole.getFromUserGroups(role.targets);
              } else {
                $log.error(`Failed to process USER_GROUP_ADMIN role: ${role}`);
              }
              break;
            default: {
              // org, support, or deployment admin role
              foundRole = UserRole.get(role.type);
            }
          }
          return foundRole;
        })
        .without('NOT_FOUND')
        .value();
    }

    /**
     * @description Method to initialize a User's user groups so that each
     *              group listed contains an actual UserGroup Object/prototype
     *              to use.
     *
     * @param {Array} userGroups - Array containing user's group data
     *
     * @return {Array} the list of created UserGroups
     */
    function initUserGroups(userGroups) {
      return _.map(userGroups, (userGroup) => UserGroup.apiResponseTransformer(userGroup));
    }
  }
})();
