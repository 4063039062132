import {Checkbox, Flex, Text, View} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {useFocusRing} from '@react-aria/focus';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import './EntitlementCard.pcss';

// Checkbox Card that will be used in the Choose Entitlements Page
const EntitlementCard = ({
  autoFocus = false,
  expiryDate,
  imgSrc,
  index,
  isCardDisabled,
  isCardSelected,
  onChange,
  titleString,
}) => {
  const intl = useIntl();
  const {isFocusVisible, focusProps} = useFocusRing({autoFocus});
  const expiryDateFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const expiryString = intl.formatMessage(
    {id: 'packages.createPackage.chooseEntitlement.expiryDate'},
    {
      offerExpiryDate: intl.formatDate(expiryDate, expiryDateFormat),
    }
  );

  return (
    <label htmlFor={`${titleString}EntitlementCardCheckbox`}>
      <VisuallyHidden>
        <Checkbox
          aria-label={titleString}
          autoFocus={autoFocus}
          id={`${titleString}EntitlementCardCheckbox`}
          isDisabled={isCardDisabled}
          isSelected={isCardSelected}
          onChange={onChange}
          {...focusProps}
        />
      </VisuallyHidden>
      <div
        data-testid="entitlement-card"
        styleName={`entitlement-card ${isCardSelected ? 'selected' : ''} ${
          isFocusVisible ? 'focused' : ''
        } ${index === 0 ? 'first-card' : ''}`}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap="size-150" UNSAFE_style={isCardDisabled ? {opacity: 0.5} : {}}>
            <Flex alignItems="center" height="100%">
              <ImageIcon alt="" size="L" src={imgSrc} />
            </Flex>
            <Flex direction="column">
              <Text UNSAFE_style={{fontWeight: 'bold'}}>{titleString}</Text>
              <View>{expiryString}</View>
            </Flex>
          </Flex>
          {isCardDisabled ? (
            <TooltipButton
              hoverText={intl.formatMessage({
                id: 'packages.createPackage.chooseEntitlement.offerError',
              })}
              isQuiet
              marginEnd="size-50"
              variant="action"
              width="size-500"
            >
              <InfoOutline size="S" />
            </TooltipButton>
          ) : (
            <div aria-hidden="true">
              <Checkbox
                alignSelf="center"
                aria-label={titleString}
                excludeFromTabOrder
                isDisabled={isCardDisabled}
                isEmphasized
                isSelected={isCardSelected}
                onChange={onChange}
              />
            </div>
          )}
        </Flex>
      </div>
    </label>
  );
};

EntitlementCard.propTypes = {
  /**
   * Whether the element will be auto focused. Defaults to false.
   */
  autoFocus: PropTypes.bool,
  /**
   * Date the entitlement expires
   */
  expiryDate: PropTypes.instanceOf(Date).isRequired,
  /**
   * Url of the image displayed
   */
  imgSrc: PropTypes.string.isRequired,
  /**
   * Index of the card in the Checkbox group
   */
  index: PropTypes.number.isRequired,
  /**
   * Boolean value to indicated if the card is to be disabled
   */
  isCardDisabled: PropTypes.bool.isRequired,
  /**
   * Boolean value to indicated if the card is to be selected
   */
  isCardSelected: PropTypes.bool.isRequired,
  /**
   * Function called when checkbox selection value changed
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Title string of the card
   */
  titleString: PropTypes.string.isRequired,
};

export default EntitlementCard;
