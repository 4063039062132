(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc service
   * @name supportTicketTimezone
   * @description business logic relating to support ticket timezones
   */
  angular.module('app.core.support').factory('supportTicketTimezone', supportTicketTimezone);

  /* @ngInject */
  function supportTicketTimezone(_, moment, SUPPORT_TICKET_TIMEZONE) {
    const service = {
      guess,
    };

    return service;

    //////////////

    /**
     * @description Tries to guess the user's current SUPPORT_TICKET_TIMEZONE, by mapping their
     *   IANA timezone (if known) to a support ticket timezone. Returns the DYNAMICS_ID for the
     *   support ticket timezone, if it can be determined.
     *
     * @returns {Number} the guessed support ticket timezone DYNAMICS_ID, or null if no timezone
     *   could be guessed. Note that 0 is a valid SUPPORT_TICKET_TIMEZONE ID.
     */
    function guess() {
      const ianaTimezoneId = moment.tz.guess();
      return _.chain(SUPPORT_TICKET_TIMEZONE)
        .find((zone) => _.includes(zone.IANA_IDS, ianaTimezoneId))
        .get('DYNAMICS_ID', null)
        .value();
    }
  }
})();
