/* eslint-disable max-lines -- over line limit */
import {
  DEVICE_ACTIVATION_STATUS,
  getDevicesFetch,
  getTotalDevicesByStatus,
} from '@pandora/react-data-source-device-activation';

import AppConstants from 'common/services/AppConstants';
import {decodeBase64} from 'common/utils/orgUtils';
import {notifyAccessingAsAdobeAgentBanner} from 'core/global-banners/adobe-agents/adobeAgentAccessNotifier';
import {getActiveUserAccount} from 'core/services/organization/activeOrganizationUtils';
import UnassignedLicensesSummaryModal from 'features/account/unassigned-licenses-summary/UnassignedLicensesSummaryModal';
import {
  getFRLIsolatedUsedLicensesCount,
  getFRLLanUsedLicensesCount,
  getNonFRLOnlineUsedLicenses,
  isProductFRLOfflineOnly,
  isProductFRLOnlineOnly,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';
import UserNotificationsDetailSection from 'features/products/product-profiles/user-notifications-detail-section/UserNotificationsDetailSection';
// eslint-disable-next-line import/no-namespace -- @neculaes to update
import * as directorySync from 'features/settings/api/directory-sync';
import eduRosterSync from 'features/settings/api/eduRosterSync';
// eslint-disable-next-line import/no-namespace -- @neculaes to update
import * as imsFederated from 'features/settings/api/ims-federated';
import AccessRequestsList from 'features/settings/components/access-requests/AccessRequestsList';
import CreateDirectoryButton from 'features/settings/components/directories/create-directory-button/CreateDirectoryButton';
import DomainEnforcementWrapper from 'features/settings/components/directory/domain-enforcement/DomainEnforcementWrapper';
import DomainEnforcementDirectoryDetailsBanner from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementDirectoryDetailsBanner';
import DomainEnforcementSettingsBanner from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementSettingsBanner';
import DirectoryTrustees from 'features/settings/components/directory/trustees/DirectoryTrustees';
import DomainsList from 'features/settings/components/domains/DomainsList';
import GoogleSamlConfigurationSteps from 'features/settings/components/google-saml-configuration-steps/GoogleSamlConfigurationSteps';
import SamlConfigurationSteps from 'features/settings/components/saml-configuration-steps/SamlConfigurationSteps';
import {IDP_TYPES, SOIDC_NAMES} from 'features/settings/entities/IdpEntity';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import {
  configure as configureTermsRedirect,
  redirectToAccept3YC,
  redirectToAcceptTC,
} from 'features/termsRedirect/termsRedirect';

import adobeioConsoleIntegrations from './app/common/api/adobeio-console/adobeioConsoleIntegrations';
import jilAssetMigrations from './app/common/api/jil/jilAssetMigrations';
import jilContentLogs from './app/common/api/jil/jilContentLogs';
import jilCustomData from './app/common/api/jil/jilCustomData';
import jilJobs from './app/common/api/jil/jilJobs';
import {
  JIL_STORAGE_FOLDER_PATH,
  JIL_STORAGE_FOLDER_SORT,
} from './app/common/api/jil/jilStorageFolderConstants';
import jilStorageFolders from './app/common/api/jil/jilStorageFolders';
import jilStorageQuota from './app/common/api/jil/jilStorageQuota';
import jilStorageRepositories from './app/common/api/jil/jilStorageRepositories';
import jilTeams from './app/common/api/jil/jilTeams';
import sophia from './app/common/api/sophia';
import sophiaV2 from './app/common/api/sophiaV2';
import AddAdminsToLicenseGroupModal from './app/common/components/add-admin-modals/AddAdminsToLicenseGroupModal';
import AddAdminsToProductModal from './app/common/components/add-admin-modals/AddAdminsToProductModal';
import AddAdminsToUserGroupModal from './app/common/components/add-admin-modals/AddAdminsToUserGroupModal';
import AddDevelopersToLicenseGroupModal from './app/common/components/add-developer-modals/AddDevelopersToLicenseGroupModal';
import AddDevelopersToOrgModal from './app/common/components/add-developer-modals/AddDevelopersToOrgModal';
/* eslint-disable import/no-cycle -- disabling import */
import AddUsersToOrgModal from './app/common/components/add-user-modals/AddUsersToOrgModal';
import AddUsersToProductModal from './app/common/components/add-user-modals/AddUsersToProductModal';
/* eslint-enable import/no-cycle -- disabling import */
import AddUsersToProductProfileModal from './app/common/components/add-user-modals/AddUsersToProductProfileModal';
import AddUsersToUserGroupModal from './app/common/components/add-user-modals/add-users-to-user-group/AddUsersToUserGroupModal';
import AuthenticatingUserModal from './app/common/components/authenticating-user-modal/AuthenticatingUserModal';
import BulkOperationModal from './app/common/components/bulk-operation/bulk-operation-modal/BulkOperationModal';
import {
  BULK_OPERATION_MODAL_ID,
  BULK_OPERATION_MODE,
  CSV_HEADER,
  CSV_ROW_LIMIT,
  CSV_STANDARD_TEMPLATES,
  JOB_RESULT_FILTER,
} from './app/common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import DeactivateDevicesButton from './app/common/components/deactivate-devices-button/DeactivateDevicesButton';
import ExportDevicesButton from './app/common/components/export-devices-button/ExportDevicesButton';
import GlobalAdminPolicyBanner from './app/common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import HeaderNavWrapper from './app/common/components/header/HeaderNavWrapper';
import SiteHeaderLeft from './app/common/components/header/SiteHeaderLeft';
import HowToDeployButton from './app/common/components/how-to-deploy-button/HowToDeployButton';
import SecurityContactsHvaCard from './app/common/components/hva/SecurityContactsHvaCard';
import SophiaCardView from './app/common/components/sophia/SophiaCardView';
import SophiaBanner from './app/common/components/sophia/banner/SophiaBanner';
import ThreeYearCommitBanner from './app/common/components/three-year-commit-banner/ThreeYearCommitBanner';
import UserGroupModal from './app/common/components/user-groups/UserGroupModal';
import CustomDataList from './app/common/entities/custom-data/CustomDataList';
import SophiaCard from './app/common/services/sophia/SophiaCard';
import SophiaCardList from './app/common/services/sophia/SophiaCardList';
import SOPHIA_CONSTANTS from './app/common/services/sophia/SophiaConstants';
import SophiaContextualParams from './app/common/services/sophia/SophiaContextualParams';
import SharedContextualParams, {
  CONTEXTUAL_PARAMS_SHARED,
} from './app/common/services/sophia/shared-contextual-params/SharedContextualParams';
import sophiaHelper from './app/common/services/sophia/sophiaHelper';
import StorageRepositoryList from './app/common/services/storage-repository-list/StorageRepositoryList';
import rootStore from './app/core/RootStore';
import {canUseSelfCancelDeepLink} from './app/core/account/access/accountAccess';
import contentPlatformVa6Direct from './app/core/content-platform/contentPlatformVa6Direct';
import {notifyContractComplianceBanners} from './app/core/contract/contract-compliance/contractComplianceNotifier';
import {notifyContractComplianceTrialBanners} from './app/core/contract/contract-compliance-trial/contractComplianceTrialNotifier';
import {notifyContractRenewalBanners} from './app/core/contract/contract-renewal/contractRenewalNotifier';
import {notifyContractStateBanners} from './app/core/contract/contract-state/contractStateNotifier';
import {notifyContractMigrationBanners} from './app/core/contract/migrations/contractMigrationNotifier';
import {notifyTermsAndConditionsBanners} from './app/core/contract/terms-and-conditions/termsAndConditionsNotifier';
import {notifyThreeYearCommitGlobalBanner} from './app/core/contract/three-year-commit-global-banner/threeYearCommitGlobalBannerNotifier';
import {canAddDomains} from './app/core/directories/access/directoryAccess';
import {notifyOrganizationMigrationBanners} from './app/core/migration/organizationMigrationNotifier';
import {OBSERVABILITY_SPAN_LIST} from './app/core/observability/observability.constants';
import {
  isOrgAdmin,
  mustAcceptContractTerms,
} from './app/core/organizations/access/organizationAccess';
import {
  BANNER_ORGANIZATION_TYPE_MODAL_ID,
  ORGANIZATION_TYPE_BANNER_ID,
  ORGANIZATION_TYPE_CHANGED,
} from './app/core/organizations/organization-settings/organizationSettingsConstants';
import {notifyOrganizationSettingsBanners} from './app/core/organizations/organization-settings/organizationSettingsNotifier';
import {
  canPreventAddProductDefaultRouteForMiniApp,
  canShowBuyingProgram,
  canUseAddProductMiniApp,
  canUseAddProductMiniAppForAll,
  canUseAddProductMiniAppForDeepLink,
  canUseAddProductMiniAppForUSDirect,
  canViewAutoAssignmentRules,
} from './app/core/products/access/productAccess';
import {notifyAutoAssignRuleErrorBanners} from './app/core/products/auto-assignment/productAutoAssignmentNotifier';
import {
  findReactRouteByState,
  findReactRouteByUrl,
  isReactRouteByState,
} from './app/core/router/route-migration/routeMigrationUtils';
import GlobalAdminRoles from './app/core/services/GlobalAdminRoles';
import auth from './app/core/services/auth';
import bootstrap from './app/core/services/bootstrap';
import chatProvider from './app/core/services/chat/chatProvider';
import AutoAssignRulesCache from './app/core/services/product/AutoAssignRulesCache';
import ProductUserList from './app/core/services/product/ProductUserList';
import {canViewSharedProjects} from './app/core/settings/access/sharedProjectsAccess';
import {notifyCertificatesBanners} from './app/core/settings/certificates/certificatesNotifier';
import {
  activeOrgHasESM,
  canPurchaseAdditionalStorage,
  canViewIndividualUserFolders,
  canViewStorage,
} from './app/core/storage/access/storageAccess';
import SummaryItemsTableSection from './app/features/account/acquisitions/table/SummaryItemsTableSection';
import AdditionalProducts from './app/features/account/additional-products/AdditionalProducts';
import hendrix from './app/features/account/billing-history/api/hendrix';
import BillingHistoryInfoTooltip from './app/features/account/billing-history/components/billing-history-tooltip/BillingHistoryInfoTooltip';
import BillingHistoryPage from './app/features/account/billing-history/pages/billing-history-page/BillingHistoryPage';
import ChangeResellerModal from './app/features/account/change-reseller-modal/ChangeResellerModal';
import ContractBanner from './app/features/account/contract-banner/ContractBanner';
import ContractInfoMiniAppWrapper from './app/features/account/contract-info/ContractInfoMiniAppWrapper';
import AccountOverviewPage from './app/features/account/overview/OverviewPage';
import PaymentInfoMiniAppWrapper from './app/features/account/payment-info/PaymentInfoMiniAppWrapper';
import PriceChangePopoverTrigger from './app/features/account/price-change-popover-trigger/PriceChangePopoverTrigger';
import ProductLicensePricingWrapper from './app/features/account/product-license-pricing-wrapper/ProductLicensePricingWrapper';
import ManagePlan from './app/features/account/self-cancel/ManagePlan';
import SelfCancelModal from './app/features/account/self-cancel/SelfCancelModal';
import StoragePlans from './app/features/account/storage-plans/StoragePlans';
import jilOrganizationContractMigration from './app/features/contract/api/jilOrganizationContractMigration';
import GlobalBanner from './app/features/global-banner/GlobalBanner';
import {GLOBAL_BANNER_EVENT} from './app/features/global-banner/GlobalBannerConstants';
import InsightsItemDetailListTableWrapper from './app/features/insights/components/InsightsItemDetailListTableWrapper';
import InsightsItemListTable from './app/features/insights/components/InsightsItemListTable';
import QuickAssignModalWrapper from './app/features/mini-apps/quick-assign-modal-wrapper/QuickAssignModalWrapper';
import SelfCancelModalWrapper from './app/features/mini-apps/self-cancel-modal-wrapper/SelfCancelModalWrapper';
import ans from './app/features/notifications/api/ans';
import NotificationsPanel from './app/features/notifications/components/panel/NotificationsPanel';
import StatusPanel from './app/features/notifications/components/panel/status-panel/StatusPanel';
import StatusList from './app/features/notifications/components/panel/status-panel/status-list/StatusList';
import StatusCard from './app/features/notifications/components/panel/status-panel/status-list/status-card/StatusCard';
import NotificationList from './app/features/notifications/models/list/NotificationList';
import {dispatchEvent} from './app/features/notifications/models/notificationUtils';
import FreeOfferModal from './app/features/offers/FreeOfferModal';
import OverviewPage from './app/features/overview/pages/OverviewPage';
import ChooseApps from './app/features/packages/components/create-package-modal/screens/choose-apps/ChooseApps';
import AddOnsSummary from './app/features/packages/components/create-package-modal/screens/choose-apps/add-ons/add-ons-summary/AddOnsSummary';
import PreferencesPage from './app/features/packages/components/preferences-page/PreferencesPage';
import {CREATE_PACKAGE} from './app/features/packages/packagesConstants';
import AllPackagesPage from './app/features/packages/pages/all-packages/AllPackagesPage';
import ServersPage from './app/features/packages/pages/servers-page/ServersPage';
import TemplatesPage from './app/features/packages/pages/templates-page/TemplatesPage';
import ToolsPage from './app/features/packages/pages/tools-page/ToolsPage';
import PackagesEntitlementsService from './app/features/packages/services/PackagesEntitlementsService';
import PackagesPreferencesService from './app/features/packages/services/PackagesPreferencesService';
import ngl from './app/features/products/api/ngl';
import AppIntegrationsPage from './app/features/products/app-integrations/pages/AppIntegrationsPage';
import AppIntegrationsHeaderSubpage from './app/features/products/app-integrations/pages/subpages/AppIntegrationsHeaderSubpage';
import AppIntegrationsPoliciesSubpage from './app/features/products/app-integrations/pages/subpages/AppIntegrationsPoliciesSubpage';
import AppIntegrationsUserAcceptedSubpage from './app/features/products/app-integrations/pages/subpages/AppIntegrationsUserAcceptedSubpage';
import AddProductModalWrapper from './app/features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import BadgePopover from './app/features/products/components/badge-popover/BadgePopover';
import ProductBanners from './app/features/products/components/banners/ProductBanners';
import ContractRenewalWrapper from './app/features/products/components/contract-renewal-wrapper/ContractRenewalWrapper';
import ProductSupportLevelDetailSection from './app/features/products/components/product-support-detail-section/ProductSupportLevelDetailSection';
import FeatureRestrictedPage from './app/features/products/device-licenses/pages/feature-restricted/FeatureRestrictedPage';
import ProductAppAddOnsPage from './app/features/products/pages/app-add-ons/ProductAppAddOnsPage';
import AutoAssignmentRulesPage from './app/features/products/pages/auto-assignment-rules-page/AutoAssignmentRulesPage';
import LegacyDevicesPage from './app/features/products/pages/legacy-devices/LegacyDevicesPage';
import ProductDeactivatedDevicesPage from './app/features/products/pages/legacy-devices/product-deactivated-devices-page/ProductDeactivatedDevicesPage';
import ProductProfileApiCredentialsSubpage from './app/features/products/pages/product-profile-api-credentials-subpage/ProductProfileApiCredentialsSubpage';
import ProductProfilesSubpage from './app/features/products/pages/product-profiles-subpage/ProductProfilesSubpage';
import ProductUsersSubpage from './app/features/products/pages/product-users-subpage/ProductUsersSubpage';
import ProfileAdminsSubpage from './app/features/products/pages/profile-admins-subpage/ProfileAdminsSubpage';
import ProfileDevelopersSubpage from './app/features/products/pages/profile-developers-subpage/ProfileDevelopersSubpage';
import ProfileUsersSubpage from './app/features/products/pages/profile-users-subpage/ProfileUsersSubpage';
import ProductRequestsPage from './app/features/products/pages/requests/ProductRequestsPage';
import AddProductProfileModal from './app/features/products/product-profiles/add-product-profile-modal/AddProductProfileModal';
import ProductProfileDetailDrawer from './app/features/products/product-profiles/product-profile-detail-drawer/ProductProfileDetailDrawer';
import ProductProfilePermissions from './app/features/products/product-profiles/product-profile-permissions/ProductProfilePermissions';
import AuthSettingsPage from './app/features/settings/components/auth-settings-page/AuthSettingsPage';
import ConsoleSettingsPage from './app/features/settings/components/console-settings-page/ConsoleSettingsPage';
import AddOidcFromDeeplink from './app/features/settings/components/directories/add-oidc-from-deeplink/AddOidcFromDeeplink';
import DirectoryDomains from './app/features/settings/components/directory/domains/DirectoryDomains';
import DirectoryDomains2 from './app/features/settings/components/directory/domains/DirectoryDomains2';
import DirectoryOverview from './app/features/settings/components/directory/overview/DirectoryOverview';
import DirectorySync from './app/features/settings/components/directory/sync/DirectorySync';
import DirectoryAuthentication from './app/features/settings/components/directory-authentication/DirectoryAuthentication';
import SecurityContactsPage from './app/features/settings/components/security-contacts-page/SecurityContactsPage';
import TrusteeList from './app/features/settings/components/trustee/TrusteeList';
import AssetCredentialsSubpage from './app/features/settings/pages/asset-settings/asset-credentials-subpage/AssetCredentialsSubpage';
import ContentLogsPage from './app/features/settings/pages/content-logs-page/ContentLogsPage';
import EncryptionSettingsPage from './app/features/settings/pages/encryption-settings-page/EncryptionSettingsPage';
import SharedProjectPoliciesPage from './app/features/settings/pages/shared-projects/SharedProjectPoliciesPage';
import ActiveUsersSubpage from './app/features/storage/pages/individual-users-folders-page/ActiveUsersSubpage';
import InactiveUsersSubpage from './app/features/storage/pages/individual-users-folders-page/InactiveUsersSubpage';
import StorageOverviewPage from './app/features/storage/pages/overview-page/StorageOverviewPage';
import StorageReportsDetailsPage from './app/features/storage/pages/reports-details-page/StorageReportsDetailsPage';
import StorageReportsPage from './app/features/storage/pages/reports-page/StorageReportsPage';
import milo from './app/features/support/api/milo';
import AdobeStatusSummary from './app/features/support/components/adobe-status-summary/AdobeStatusSummary';
import ExpertSessionInfoBar from './app/features/support/components/expert-session-info-bar/ExpertSessionInfoBar';
import HelpTopicTabs from './app/features/support/components/help-topic-tabs/HelpTopicTabs';
import PremierSupportContactsSection from './app/features/support/components/premier-support-contacts-section/PremierSupportContactsSection';
import StartChatButton from './app/features/support/components/start-chat-button/StartChatButton';
import SummaryRequestExpertSessionButton from './app/features/support/components/summary-request-expert-session-button/SummaryRequestExpertSessionButton';
import SupportCaseInfoBar from './app/features/support/components/support-case-info-bar/SupportCaseInfoBar';
import SupportDetailsDrawer from './app/features/support/components/support-details-drawer/SupportDetailsDrawer';
import SupportHelpTopics from './app/features/support/components/support-help-topics/SupportHelpTopics';
import SupportHighOverview from './app/features/support/components/support-high-overview/SupportHighOverview';
import SupportSummaryQuickActions from './app/features/support/components/support-summary-quick-actions/SupportSummaryQuickActions';
import SupportTicketActionSection from './app/features/support/components/support-ticket-action-section/SupportTicketActionSection';
import SupportContactInfo from './app/features/support/components/support-ticket-contact-info/SupportContactInfo';
import SupportTicketContactsModalWrapper from './app/features/support/components/support-ticket-contacts-modal-wrapper/SupportTicketContactsModalWrapper';
import SupportTicketContactsSection from './app/features/support/components/support-ticket-contacts-section/SupportTicketContactsSection';
import SupportTicketCreateButton from './app/features/support/components/support-ticket-create-button/SupportTicketCreateButton';
import SupportTicketAddDiscussionItemFormSection from './app/features/support/components/support-ticket-discussion-form-section/SupportTicketAddDiscussionItemFormSection';
import SupportTicketDiscussionHeader from './app/features/support/components/support-ticket-discussion-form-section/SupportTicketDiscussionHeader';
import SupportTicketDiscussionItems from './app/features/support/components/support-ticket-discussion-section/SupportTicketDiscussionItems';
import SupportTicketExport from './app/features/support/components/support-ticket-export/SupportTicketExport';
import SupportTicketMiniappWrapper from './app/features/support/components/support-ticket-miniapp-wrapper/SupportTicketMiniappWrapper';
import SupportTicketStatus from './app/features/support/components/support-ticket-status/SupportTicketStatus';
import SupportTicketStatusTag from './app/features/support/components/support-ticket-status-tag/SupportTicketStatusTag';
import TeamQuickActions from './app/features/support/components/team-quick-actions/TeamQuickActions';
import SupportTicketDetailsPage from './app/features/support/pages/support-ticket-details-page/SupportTicketDetailsPage';
import SupportTicketsPage from './app/features/support/pages/support-tickets-page/SupportTicketsPage';
import {SUPPORT_CONSTANTS} from './app/features/support/support.constants';
import AddAdminModal from './app/features/users/components/add-admin-modal/AddAdminModal';
import EditDeveloperAccessModal from './app/features/users/components/edit-developer-access-modal/EditDeveloperAccessModal';
import EditProductsAndUserGroupsModal from './app/features/users/components/edit-products-and-user-groups-modal/EditProductsAndUserGroupsModal';
import {PRODUCT_TYPES_FOR_CONTRACT_MIGRATION} from './app/features/users/components/offer-switch-migration/OfferSwitchMigrationConstants';
import OfferSwitchMigrationModal from './app/features/users/components/offer-switch-migration/OfferSwitchMigrationModal';
import EditUserDetailsModal from './app/features/users/edit-user-details-modal/EditUserDetailsModal';
import {
  dispatchHvaClickAnalytics,
  dispatchHvaLoadedAnalytics,
} from './app/features/users/services/offer-switch-migration/offerSwitchMigrationAnalyticsUtils';
import {
  getOfferSwitchMigration,
  getProductsByContractMigrationList,
} from './app/features/users/services/offer-switch-migration/offerSwitchMigrationHelper';
import {dispatchUserModalSuccessAnalytics} from './app/features/users/services/user-modals/userModalAnalyticsUtils';
import AssignProductProfilesToUserGroupsModal from './app/features/users/user-groups/assign-product-profiles-to-user-groups/AssignProductProfilesToUserGroupsModal';
import GlobalHeader from './app/shell/components/global-header/GlobalHeader';
import OrganizationSwitcherContainer from './app/shell/components/organization-switcher-container/OrganizationSwitcherContainer';
import PortalContainerInDocumentBody from './app/shell/components/portal-container-in-document-body/PortalContainerInDocumentBody';
import UserPullDown from './app/shell/components/user-profile-pulldown/UserPullDown';
import HelpRailButton from './app/shell/help/rail/help-rail-button/HelpRailButton';
import HelpRailContainer from './app/shell/help/rail/help-rail-container/HelpRailContainer';
import NotificationsContainer from './app/shell/notifications/NotificationsContainer';
import {HIDE_ANGULAR} from './app/shell/utils/angularUtils';

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- excluding to improve jest performance
// istanbul ignore next -- excluding to improve jest performance
const onesieSrc2 = {
  account: {
    AccountOverviewPage,
    api: {hendrix},
    BillingHistoryPage,
    components: {
      AdditionalProducts,
      BillingHistoryInfoTooltip,
      ChangeResellerModal,
      ContractBanner,
      ContractInfoMiniAppWrapper,
      ContractRenewalWrapper,
      ManagePlan,
      PaymentInfoMiniAppWrapper,
      PriceChangePopoverTrigger,
      ProductLicensePricingWrapper,
      SelfCancelModal,
      StoragePlans,
      SummaryItemsTableSection,
      UnassignedLicensesSummaryModal,
    },
  },
  analyticsData: {},
  bulkOperation: {
    BULK_OPERATION_CONSTANTS: {
      BULK_OPERATION_MODAL_ID,
      BULK_OPERATION_MODE,
      CSV_HEADER,
      CSV_ROW_LIMIT,
      CSV_STANDARD_TEMPLATES,
      JOB_RESULT_FILTER,
    },
    components: {
      BulkOperationModal,
    },
  },
  common: {
    api: {
      adobeioConsole: {
        adobeioConsoleIntegrations,
      },
      jil: {
        jilAssetMigrations,
        jilContentLogs,
        jilCustomData,
        jilJobs,
        jilStorageFolders,
        jilStorageFoldersConstants: {
          JIL_STORAGE_FOLDER_PATH,
          JIL_STORAGE_FOLDER_SORT,
        },
        jilStorageQuota,
        jilStorageRepositories,
        jilTeams,
      },
      sophia,
      sophiaV2,
    },
    components: {
      AddAdminsToLicenseGroupModal,
      AddAdminsToProductModal,
      AddAdminsToUserGroupModal,
      AddDevelopersToLicenseGroupModal,
      AddDevelopersToOrgModal,
      AddProductProfileModal,
      AddUsersToOrgModal,
      AddUsersToProductModal,
      AddUsersToProductProfileModal,
      AddUsersToUserGroupModal,
      AssignProductProfilesToUserGroupsModal,
      AuthenticatingUserModal,
      DeactivateDevicesButton,
      ExportDevicesButton,
      FreeOfferModal,
      GlobalAdminPolicyBanner,
      HeaderNavWrapper,
      HowToDeployButton,
      OrganizationSwitcherContainer,
      SecurityContactsHvaCard,
      SiteHeaderLeft,
      SophiaBanner,
      SophiaCardView,
      ThreeYearCommitBanner,
      UserGroupModal,
      UserPullDown,
    },
    entities: {
      CustomDataList,
    },
    services: {
      AppConstants,
      sophia: {
        CONTEXTUAL_PARAMS_SHARED,
        SharedContextualParams,
        SOPHIA_CONSTANTS,
        SophiaCard,
        SophiaCardList,
        SophiaContextualParams,
        sophiaHelper,
      },
      StorageRepositoryList,
      termsRedirect: {
        configureTermsRedirect,
        redirectToAccept3YC,
        redirectToAcceptTC,
      },
    },
    utils: {
      decodeBase64,
    },
  },
  contract: {
    api: {
      jilOrganizationContractMigration,
    },
  },
  core: {
    account: {
      access: {
        canUseSelfCancelDeepLink,
      },
    },
    directories: {
      access: {
        canAddDomains,
      },
    },
    notifier: {
      notifyAccessingAsAdobeAgentBanner,
      notifyAutoAssignRuleErrorBanners,
      notifyCertificatesBanners,
      notifyContractComplianceBanners,
      notifyContractComplianceTrialBanners,
      notifyContractMigrationBanners,
      notifyContractRenewalBanners,
      notifyContractStateBanners,
      notifyOrganizationMigrationBanners,
      notifyOrganizationSettingsBanners,
      notifyTermsAndConditionsBanners,
      notifyThreeYearCommitGlobalBanner,
    },
    observability: {
      constants: {OBSERVABILITY_SPAN_LIST},
    },
    organizations: {
      access: {
        isOrgAdmin,
        mustAcceptContractTerms,
      },
      organizationSettings: {
        BANNER_ORGANIZATION_TYPE_MODAL_ID,
        ORGANIZATION_TYPE_BANNER_ID,
        ORGANIZATION_TYPE_CHANGED,
      },
    },
    products: {
      access: {
        canPreventAddProductDefaultRouteForMiniApp,
        canShowBuyingProgram,
        canUseAddProductMiniApp,
        canUseAddProductMiniAppForAll,
        canUseAddProductMiniAppForDeepLink,
        canUseAddProductMiniAppForUSDirect,
        canViewAutoAssignmentRules,
      },
    },
    rootStore,
    routeMigration: {
      findReactRouteByState,
      findReactRouteByUrl,
      isReactRouteByState,
    },
    services: {
      auth,
      bootstrap,
      chat: {
        chatProvider,
      },
      contentPlatform: {contentPlatformVa6Direct},
      GlobalAdminRoles,
      organization: {
        activeOrganizationUtils: {getActiveUserAccount},
      },
      product: {
        AutoAssignRulesCache,
        DEVICE_ACTIVATION_STATUS,
        getDevicesFetch,
        getFRLIsolatedUsedLicensesCount,
        getFRLLanUsedLicensesCount,
        getNonFRLOnlineUsedLicenses,
        getTotalDevicesByStatus,
        isProductFRLOfflineOnly,
        isProductFRLOnlineOnly,
        ProductUserList,
      },
    },
    storage: {
      access: {
        activeOrgHasESM,
        canPurchaseAdditionalStorage,
        canViewIndividualUserFolders,
        canViewStorage,
      },
    },
  },
  insights: {
    components: {
      InsightsItemDetailListTableWrapper,
      InsightsItemListTable,
    },
  },
  notifications: {
    api: {
      ans,
    },
    components: {
      NotificationsPanel,
      StatusCard,
      StatusList,
      StatusPanel,
    },
    models: {
      dispatchEvent,
      list: {
        NotificationList,
      },
    },
  },
  overview: {
    pages: {
      OverviewPage,
    },
  },
  packages: {
    components: {
      AddOnsSummary,
      ChooseApps,
      PreferencesPage,
    },
    constants: {CREATE_PACKAGE},
    pages: {
      AllPackagesPage,
      ServersPage,
      TemplatesPage,
      ToolsPage,
    },
    services: {
      PackagesEntitlementsService,
      PackagesPreferencesService,
    },
  },
  products: {
    api: {
      ngl,
    },
    components: {
      AddProductModalWrapper,
      BadgePopover,
      ProductBanners,
      ProductProfileDetailDrawer,
      ProductProfilePermissions,
      ProductSupportLevelDetailSection,
      QuickAssignModalWrapper,
      SelfCancelModalWrapper,
      UserNotificationsDetailSection,
    },
    pages: {
      AppIntegrationsHeaderSubpage,
      AppIntegrationsPage,
      AppIntegrationsPoliciesSubpage,
      AppIntegrationsUserAcceptedSubpage,
      AutoAssignmentRulesPage,
      FeatureRestrictedPage,
      LegacyDevicesPage,
      ProductAppAddOnsPage,
      ProductDeactivatedDevicesPage,
      ProductProfileApiCredentialsSubpage,
      ProductProfilesSubpage,
      ProductRequestsPage,
      ProductUsersSubpage,
      ProfileAdminsSubpage,
      ProfileDevelopersSubpage,
      ProfileUsersSubpage,
    },
  },
  settings: {
    components: {
      AccessRequestsList,
      AddOidcFromDeeplink,
      AuthSettingsPage,
      ConsoleSettingsPage,
      CreateDirectoryButton,
      DirectoryAuthentication,
      DirectoryDomains,
      DirectoryDomains2,
      DirectoryOverview,
      DirectorySync,
      DirectoryTrustees,
      DomainEnforcementDirectoryDetailsBanner,
      DomainEnforcementSettingsBanner,
      DomainEnforcementWrapper,
      DomainsList,
      EncryptionSettingsPage,
      GoogleSamlConfigurationSteps,
      SamlConfigurationSteps,
      SecurityContactsPage,
      TrusteeList,
    },
    constants: {
      IDP_TYPES,
      SOIDC_NAMES,
    },
    pages: {
      AssetCredentialsSubpage,
      ContentLogsPage,
      EncryptionSettingsPage,
      SharedProjectPoliciesPage,
    },
    services: {
      directorySync,
      eduRosterSync,
      ExternalAuthService,
      imsFederated,
    },
  },
  shell: {
    components: {
      GLOBAL_BANNER_EVENT,
      GlobalBanner,
      GlobalHeader,
      PortalContainerInDocumentBody,
    },
    help: {
      rail: {
        HelpRailButton,
        HelpRailContainer,
      },
    },
    notifications: {
      NotificationsContainer,
    },
    utils: {
      HIDE_ANGULAR,
    },
  },
  storage: {
    components: {
      StorageReportsDetailsPage,
    },
    pages: {
      ActiveUsersSubpage,
      InactiveUsersSubpage,
      StorageOverviewPage,
      StorageReportsPage,
    },
  },
  support: {
    api: {
      milo,
    },
    components: {
      AdobeStatusSummary,
      ExpertSessionInfoBar,
      HelpTopicTabs,
      PremierSupportContactsSection,
      StartChatButton,
      SummaryRequestExpertSessionButton,
      SupportCaseInfoBar,
      SupportContactInfo,
      SupportDetailsDrawer,
      SupportHelpTopics,
      SupportHighOverview,
      SupportSummaryQuickActions,
      SupportTicketActionSection,
      SupportTicketAddDiscussionItemFormSection,
      SupportTicketContactsModalWrapper,
      SupportTicketContactsSection,
      SupportTicketCreateButton,
      SupportTicketDiscussionHeader,
      SupportTicketDiscussionItems,
      SupportTicketExport,
      SupportTicketMiniappWrapper,
      SupportTicketStatus,
      SupportTicketStatusTag,
      TeamQuickActions,
    },
    constants: SUPPORT_CONSTANTS,
    pages: {
      SupportTicketDetailsPage,
      SupportTicketsPage,
    },
  },
  users: {
    components: {
      AddAdminModal,
      EditDeveloperAccessModal,
      EditProductsAndUserGroupsModal,
      EditUserDetailsModal,
      OfferSwitchMigrationModal,
    },
    constants: {
      offerSwitchMigration: {PRODUCT_TYPES_FOR_CONTRACT_MIGRATION},
    },
    services: {
      offerSwitchMigration: {
        analyticsUtils: {
          dispatchHvaClickAnalytics,
          dispatchHvaLoadedAnalytics,
        },
        helper: {
          getOfferSwitchMigration,
          getProductsByContractMigrationList,
        },
      },
      userModals: {
        analyticsUtils: {
          dispatchUserModalSuccessAnalytics,
        },
      },
    },
    sharedProjects: {
      canViewSharedProjects,
    },
  },
};

export default onesieSrc2;
/* eslint-enable max-lines -- over line limit */
