import {Button, ButtonGroup, Text} from '@adobe/react-spectrum';
import {USER_ROLES} from '@pandora/administration-core-types';
import {useAuth} from '@pandora/react-auth-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';

import GoToProductButton from 'common/components/go-to-product-button/GoToProductButton';
import rootStore from 'core/RootStore';

const ProductDetailsDrawerButtons = ({goToHelpLink, goToProductLink}) => {
  const auth = useAuth();
  const {activeOrgId} = rootStore.organizationStore;
  const isProductAdmin = userRoleUtils.anyOfForOrg(
    auth.getUserProfile().roles,
    [USER_ROLES.PRODUCT_ADMIN],
    activeOrgId
  );
  return (
    <ButtonGroup data-testid="details-drawer-buttons" marginStart="size-50" marginTop="size-200">
      {goToHelpLink && (
        <Button
          data-testid="help-link-button"
          elementType="a"
          href={goToHelpLink.href}
          rel="noopener noreferrer"
          target="_blank"
          variant="accent"
        >
          <LinkOutIcon />
          <Text>{goToHelpLink.text}</Text>
        </Button>
      )}
      {goToProductLink && isProductAdmin && <GoToProductButton goToProductLink={goToProductLink} />}
    </ButtonGroup>
  );
};

ProductDetailsDrawerButtons.propTypes = {
  /**
   * Go to product help link.
   */
  goToHelpLink: PropTypes.shape({
    /**
     * URL of the link.
     */
    href: PropTypes.string.isRequired,
    /**
     * Text of the link.
     */
    text: PropTypes.string.isRequired,
  }),
  /**
   *  Go to product link.
   */
  goToProductLink: PropTypes.shape({
    /**
     * URL of the link.
     */
    href: PropTypes.string.isRequired,
    /**
     * Text of the link.
     */
    text: PropTypes.string.isRequired,
  }),
};

export default ProductDetailsDrawerButtons;
