import {PATH_USER_GROUPS} from 'features/users/routing/usersPaths';

import help from '../../constants/help';
import {hasEnterpriseCreateUserGroupsAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> enterprise create user groups
 */
const enterpriseCreateUserGroups = () => ({
  access: hasEnterpriseCreateUserGroupsAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.enterpriseCreateUserGroups.link.one.key',
          locator: PATH_USER_GROUPS,
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
        {
          key: 'help.topics.enterpriseCreateUserGroups.link.two.key',
          locator: 'aac_learn_enterprise_groups',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.enterpriseCreateUserGroups.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.ENTERPRISE_CREATE_USER_GROUPS,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.enterpriseCreateUserGroups.title',
});
export default enterpriseCreateUserGroups;
