import {Button, View} from '@adobe/react-spectrum';
import {useSlotProps} from '@react-spectrum/utils';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ButtonWithContextualHelp.pcss';

/**
 * Encapsulates a Button and a ContextualHelp without breaking the styling of react-spectrum's ButtonGroup.
 * Accepts all the props that Spectrum's Button accepts (https://react-spectrum.adobe.com/react-spectrum/Button.html),
 * plus a `contextualHelp` prop. See Spectrum ContextualHelp docs (https://react-spectrum.adobe.com/react-spectrum/ContextualHelp.html)
 */
const ButtonWithContextualHelp = ({children, contextualHelp, ...buttonProps}) => {
  const {UNSAFE_className: buttonSlotClassName} = useSlotProps({}, 'button');
  const className = [styles['button-with-contextual-help'], buttonSlotClassName].join(' ').trim();

  return (
    /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to pass class to View */
    <View UNSAFE_className={className}>
      <Button {...buttonProps}>{children}</Button>
      {contextualHelp}
    </View>
  );
};
ButtonWithContextualHelp.propTypes = {
  children: PropTypes.node.isRequired,
  contextualHelp: PropTypes.element,
};
export default ButtonWithContextualHelp;
