(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.review-organization-types').component('appReviewOrganizationTypes', {
    bindings: {
      marketSubsegments: '<',
    },
    controller,
    templateUrl:
      'app/widgets/organizations/review-organization-types/review-organization-types.component.html',
  });

  /* @ngInject */
  function controller($translate, _, ORG_MARKET_SUBSEGMENT) {
    const vm = this;

    _.assign(vm, {
      $onInit,
    });

    function $onInit() {
      const namespace = 'widgets.account.organizationTypeSection.type';
      vm.subsegments = [];

      if (_.includes(vm.marketSubsegments, ORG_MARKET_SUBSEGMENT.K_12)) {
        vm.subsegments.push({label: $translate.instant(`${namespace}.k12`)});
      }

      if (_.includes(vm.marketSubsegments, ORG_MARKET_SUBSEGMENT.HIGHER_ED)) {
        vm.subsegments.push({label: $translate.instant(`${namespace}.higherEd`)});
      }

      if (_.includes(vm.marketSubsegments, ORG_MARKET_SUBSEGMENT.NON_PROFIT)) {
        vm.subsegments.push({
          label: $translate.instant(`${namespace}.nonProfit`),
          showTooltip: true,
        });
      }

      if (_.isEmpty(vm.subsegments)) {
        vm.subsegments.push({label: $translate.instant(`${namespace}.notAvailable`)});
      }
    }
  }
})();
