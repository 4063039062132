(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support::appSupportTicketFileAttachment
   *
   * @description displays a file attached to a support ticket, including the filename and
   *   download button.
   *
   * @binding {Object} attachment - the attachment to display (one of the items from the
   *   supportTicket.attachments array)
   * @binding {SupportTicket} supportTicket - the ticket whose attachment should be displayed
   * @binding {String} [variant] - the display variant to use; 'default' or 'quiet'. If
   *   unspecified, the 'default' appearance is used.
   */
  angular.module('app.widgets.support').component('appSupportTicketFileAttachment', {
    bindings: {
      attachment: '<',
      supportTicket: '<',
      variant: '@?',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-ticket-file-attachment/support-ticket-file-attachment.component.html',
  });

  /* @ngInject */
  function controller(_, $translate, downloadUtils, feature, TOAST_TYPES, toastManager) {
    const vm = this;

    _.assign(vm, {
      disableDownloadButton: false,
      download,
    });

    function download() {
      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.support.supportTicketFileAttachment.downloadBeingPrepared')
      );

      vm.disableDownloadButton = true;

      vm.supportTicket
        .downloadAttachment(vm.attachment.attachmentId)
        .then((data) => downloadUtils.download(data, vm.attachment.fileName))
        .catch(() =>
          toastManager.showErrorToast(
            $translate.instant('widgets.support.supportTicketFileAttachment.unableToDownload')
          )
        )
        .finally(() => {
          vm.disableDownloadButton = false;
        });
    }
  }
})();
