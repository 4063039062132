import {Content, Heading, IllustratedMessage, Image, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import AddItem from 'common/svgs/AddItem.svg';

/**
 * A empty state component that can be used in a TableView or on a page when there are no items.
 * It renders the AddItem image, with an IllustratedMessage which has a header and an optional message.
 *
 * If used in a TableView:
 *  - NOTE the TableView must have a minHeight or height set on it for this to be visible.
 *  - any buttons should should be placed in the TableAction section rather than here.
 *  - this message will fit nicely in 'size-6000' as long as there is not an excessive amount of text
 */
const AddItemEmptyState = ({heading, level = 2, message}) => (
  <View>
    {/* Size of svg is 160px by 160px */}
    <Image alt="" height="size-2000" marginX="auto" src={AddItem} width="size-2000" />
    <IllustratedMessage>
      <Heading level={level}>{heading}</Heading>
      {message && <Content>{message}</Content>}
    </IllustratedMessage>
  </View>
);

AddItemEmptyState.propTypes = {
  /**
   * The heading for the IllustratedMeage
   */
  heading: PropTypes.string.isRequired,
  /**
   * The heading level. The default is 2.
   */
  level: PropTypes.number,
  /**
   * The optional message for the IllustratedMeage
   */
  message: PropTypes.string,
};

export default AddItemEmptyState;
