import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the DomainsList page and its sub-components.
 */
const DomainsListContext = createContext({});
const useDomainsListContext = () => useContext(DomainsListContext);

const DomainsListContextProvider = ({children, listState}) => {
  const value = useMemo(
    () => ({
      ...listState,
      domainList: listState.state,
    }),
    [listState]
  );

  return <DomainsListContext.Provider value={value}>{children}</DomainsListContext.Provider>;
};

DomainsListContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the domain list.
   */
  children: PropTypes.node,

  /**
   * The domain list state used by the provider to keep state of the children components.
   */
  listState: PropTypes.shape({
    canAddDomains: PropTypes.bool,
    loadDomainList: PropTypes.func,
    reloadDomainList: PropTypes.func,
    setTableOptions: PropTypes.func,
    state: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- this is an instance so it makes it easier to test like this
      domainListData: PropTypes.object,
      hasLoadingError: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
    tableOptions: PropTypes.shape({
      page: PropTypes.number,
      pageSize: PropTypes.number,
      searchQuery: PropTypes.string,
      sort: PropTypes.string,
      sortOrder: PropTypes.string,
      status: PropTypes.string,
    }),
    updateDomainList: PropTypes.func,
  }),
};

export {DomainsListContextProvider, useDomainsListContext};
