import {feature} from '@admin-tribe/binky';
import {NavigationAnchor} from '@admin-tribe/binky-ui';
import {
  Button,
  Cell,
  Column,
  Flex,
  Provider,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  lightTheme,
} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductNameCell} from '@pandora/react-product-name-cell';
import {ProductUsage, ProductUsageContentModel} from '@pandora/react-product-usage';
import {useCollator} from '@react-aria/i18n';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ProductSummaryList from 'common/services/product-summary-list/ProductSummaryList';
import {getProductContractNames} from 'common/services/product-summary-list/productSummaryListUtils';
import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {sortProducts} from 'core/products/utils/productUtils';
import ManageButton from 'features/overview/components/manage-button/ManageButton';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';
import {
  getHrefForProductOrProductGroup,
  goToProductOrProductGroupDetails,
  goToProducts,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import TrialOfferBadge from 'features/trial-offer-badge/TrialOfferBadge';

import styles from './ProductsSummarySection.pcss';
import {
  canClickItem,
  canViewProductsSummarySection,
  canViewUsageQuantity,
  shouldShowProductOrProductGroup,
} from './productsSummarySectionUtils';

const MAX_PRODUCTS_BEFORE_EXPAND = 5;

/**
 * The Products Summary Section component on the Overview page
 */
const ProductsSummarySection = () => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const collator = useCollator();
  const [productSummaryList, setProductSummaryList] = useState([]);
  const productUsageContent = useContentEntry(ProductUsageContentModel);
  const orgId = rootStore.organizationStore.activeOrgId;
  const contractList = rootStore.organizationStore.contractList;
  const showTrial = feature.isEnabled('trial_with_payment');

  const allItems = useMemo(() => {
    const productsAndProductGroups = rootStore.organizationStore.orgConsumables
      .getProductsAndProductGroups()
      .filter(shouldShowProductOrProductGroup);
    return sortProducts({collator, intl, products: productsAndProductGroups});
  }, [collator, intl]);

  // Get the initial productSummaryList and update it again
  // upon the promise resolving and callback getting invoked
  useEffect(() => {
    const callback = (updatedProductSummaryList) => {
      setProductSummaryList(updatedProductSummaryList);
    };

    // Get the initial ProductSummaryList
    setProductSummaryList(
      ProductSummaryList({
        callback,
        contractList,
        intl,
        orgId,
        productAndProductGroupList: allItems,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run on setup and takedown, not every render
  }, []);

  const productSummaryItems = useMemo(
    () =>
      isExpanded ? productSummaryList : productSummaryList.slice(0, MAX_PRODUCTS_BEFORE_EXPAND),
    [productSummaryList, isExpanded]
  );

  const renderTrialBadgesTableCell = useCallback(
    (item) => {
      const {activeTrial, daysInBadge} = trialHelper.getTrialWithPaymentInfo(item.product);
      return (
        showTrial &&
        activeTrial &&
        daysInBadge > 0 && <TrialOfferBadge className={styles['trial-badges']} days={daysInBadge} />
      );
    },
    [showTrial]
  );

  const expandButtonText = useMemo(
    () =>
      isExpanded
        ? intl.formatMessage({id: 'overview.products.pod.showLess'})
        : intl.formatMessage({id: 'overview.products.pod.showAll'}),
    [intl, isExpanded]
  );

  // Don't render the pod when there is no products tab / the user cannot view products
  if (!canViewProductsSummarySection()) {
    return null;
  }

  const manageButtonComponent = (
    <ManageButton
      aria-label={intl.formatMessage({id: 'overview.products.pod.manageAriaLabel'})}
      data-testid="manage-products-link"
      onPress={() => goToProducts()}
    />
  );

  const getRightHeaderContent = () => {
    if (canViewUsageQuantity(orgId)) {
      return manageButtonComponent;
    }
    return undefined;
  };

  const showLicenses = canViewUsageQuantity(orgId);

  // Disable row selection for any items which aren't clickable.
  const disabledKeys = allItems.filter((item) => !canClickItem(item)).map((item) => item.id);

  const onRowAction = (productId) => {
    if (disabledKeys.includes(productId)) return;
    const productOrProductGroup = allItems.find((item) => item.id === productId);
    goToProductOrProductGroupDetails({
      productOrProductGroup,
    });
  };

  const renderLinkCallback = (linkText, productOrProductGroup) => (
    <NavigationAnchor href={getHrefForProductOrProductGroup(productOrProductGroup.model)}>
      {linkText}
    </NavigationAnchor>
  );

  const getProduct = (item) => (
    <ProductNameCell
      contextualHelp={showTrial && renderTrialBadgesTableCell(item)}
      iconSize="M"
      iconSrc={item.product.icon}
      productName={item.product.name}
      renderLink={
        disabledKeys.includes(item.product.model.id)
          ? undefined
          : (productName) => renderLinkCallback(productName, item.product)
      }
      showTooltip={!!feature.isEnabled('temp_add_contract_display_name')}
      subTextLinkSuffix={intl.formatMessage({
        id: `overview.products.pod.contractNamesList.subTextLinkSuffix`,
      })}
      subTextList={getProductContractNames(item.contracts)}
    />
  );

  const getQuantity = ({product}) => {
    const firstScore = product.quantity.scores[0];

    /* the firstScore will be null initially when the data is loading so we need to check */
    return (
      showLicenses &&
      product.quantity !== undefined && (
        <ProductUsage
          content={productUsageContent}
          totalQuantity={firstScore?.total}
          unitName={firstScore?.unit}
          usedQuantity={firstScore?.used}
        />
      )
    );
  };

  const columnDescriptor = [
    {
      key: 'products',
    },
  ];

  if (showLicenses) {
    columnDescriptor.push({
      key: 'quantity',
      props: {align: 'end', width: '30%'},
    });
  }

  const renderers = {
    products: getProduct,
    quantity: getQuantity,
  };

  return (
    <OverviewPod
      data-testid="products-summary-section"
      rightHeaderContent={getRightHeaderContent()}
      title={intl.formatMessage({id: 'overview.products.pod.title'})}
    >
      {allItems.length > 0 ? (
        <>
          {/* The provider wrapper below is needed to meet the XD requirement
          and apply the white background color on the table */}
          <Provider colorScheme="light" theme={lightTheme}>
            <TableView
              aria-label={intl.formatMessage({
                id: 'overview.products.pod.productSummaryAriaLabel',
              })}
              data-testid="product-summary-table"
              density="compact"
              disabledKeys={disabledKeys}
              isQuiet
              onAction={onRowAction}
              overflowMode="wrap"
              selectionMode="single"
              selectionStyle="highlight"
            >
              <TableHeader columns={columnDescriptor}>
                {(column) => (
                  <Column key={column.key} {...column.props}>
                    <FormattedMessage id={`overview.products.pod.column.${column.key}`} />
                  </Column>
                )}
              </TableHeader>
              <TableBody>
                {/* Using the "items" prop on TableBody does not behave
                   correctly when working with asynchronously fetched data
                   through promises, as it would not update as expected when
                   the promises are resolved later. */}
                {productSummaryItems.map((item) => (
                  <Row key={item.product.model.id}>
                    {(columnKey) => <Cell>{renderers[columnKey](item)}</Cell>}
                  </Row>
                ))}
              </TableBody>
            </TableView>
          </Provider>
          {allItems.length > MAX_PRODUCTS_BEFORE_EXPAND && (
            <Flex justifyContent="center" marginY="size-100">
              <Button
                data-testid="products-summary-section-expand-button"
                onPress={() => setIsExpanded((expanded) => !expanded)}
                variant="secondary"
              >
                {expandButtonText}
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Flex alignItems="center" height="size-900" justifyContent="center">
          <Text
            UNSAFE_style={{
              color: 'var(--spectrum-alias-label-text-color)',
              fontStyle: 'var(--spectrum-global-font-style-italic,italic)',
            }}
          >
            <FormattedMessage id="overview.products.pod.noProducts" />
          </Text>
        </Flex>
      )}
    </OverviewPod>
  );
};

export default ProductsSummarySection;
