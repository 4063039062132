(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilToken
   * @description defines service to manage jil DNS token resources
   */
  angular.module('app.core.api.jil').provider('jilToken', jilTokenProvider);

  let url;

  /* @ngInject */
  function jilTokenProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        token: getTokenResource(),
      };

      return service;

      ///////////

      function getTokenResource() {
        return $resource(`${url}/:orgId/token`, {
          orgId: apiUtils.getActiveOrg,
        });
      }
    }
  }
})();
