/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase:appProratedExpandableCalculation
 *
 * @description The expandable component used to view prorated price breakdown.
 * @example
 *  <app-prorated-expandable-calculation
 *     contract="$ctrl.contract"
 *     proration-summary="$ctrl.cart.getProrationSummary()">
 *  </app-prorated-expandable-calculation>
 *
 * @binding {Contract} contract - the contract to show total for
 * @binding {ProrationSummary} prorationSummary - contains information about the
 * billing period and prorated totals
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appProratedExpandableCalculation', {
    bindings: {
      contract: '<',
      prorationSummary: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/prorated-expandable-calculation/prorated-expandable-calculation.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $translate,
    binkyContractTaxRateFilter,
    binkyDateTimeFilter,
    numberFilter,
    price,
    PURCHASE_WAITSTR
  ) {
    const vm = this;
    const {billingPeriodEnd, billingPeriodStart, currency, days, subtotal, tax, taxRate, total} =
      vm.prorationSummary;
    _.assign(vm, {
      header: _.unescape(
        $translate.instant(
          'widgets.products.purchase.addProductsModal.reviewOrderSections.direct.proratedExpandableSection.header',
          {
            billingPeriodEnd: billingPeriodEnd
              ? binkyDateTimeFilter(billingPeriodEnd, {dateOnly: true})
              : PURCHASE_WAITSTR,
            billingPeriodStart: billingPeriodStart
              ? binkyDateTimeFilter(billingPeriodStart, {dateOnly: true})
              : PURCHASE_WAITSTR,
          }
        )
      ),
      subtotal: getPrice(subtotal, currency),
      subtotalLabel: $translate.instant(
        'widgets.products.purchase.addProductsModal.reviewOrderSections.direct.proratedExpandableSection.messageFormat.subtotalProration',
        {days: getNumber(days)},
        'messageformat'
      ),
      tax: getPrice(tax, currency),
      taxRate: _.isNil(taxRate)
        ? PURCHASE_WAITSTR
        : binkyContractTaxRateFilter(vm.contract, numberFilter(taxRate)),
      total: getPrice(total, currency),
    });

    function getNumber(number) {
      return _.isNil(number) ? PURCHASE_WAITSTR : numberFilter(number);
    }

    function getPrice(value, currencyUnit) {
      return _.isNil(value) ? PURCHASE_WAITSTR : price.format(value, currencyUnit);
    }
  }
})();
