import {
  PATH_ADOBE_TEMPLATES,
  PATH_ALL_PACKAGES,
  PATH_PACKAGES_PREFERENCES,
  PATH_PACKAGES_ROOT,
  PATH_PACKAGES_SERVERS,
  PATH_PACKAGES_TOOLS,
} from 'features/packages/routing/packagesPaths';

const packagesRouteMigration = [
  {
    stateName: 'packages',
    url: PATH_PACKAGES_ROOT,
    useReactRoute: true,
  },
  {
    stateName: 'packages.all-packages',
    url: PATH_ALL_PACKAGES,
    useReactRoute: true,
  },
  {
    stateName: 'packages.adobe-templates',
    url: PATH_ADOBE_TEMPLATES,
    useReactRoute: true,
  },
  {
    stateName: 'packages.preferences',
    url: PATH_PACKAGES_PREFERENCES,
    useReactRoute: true,
  },
  {
    stateName: 'packages.servers',
    url: PATH_PACKAGES_SERVERS,
    useReactRoute: true,
  },
  {
    stateName: 'packages.tools',
    url: PATH_PACKAGES_TOOLS,
    useReactRoute: true,
  },
];

export default packagesRouteMigration;
