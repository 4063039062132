(function () {
  /**
   * @deprecated Please use {COMPLIANCE_CONTEXT, COMPLIANCE_GRACE_PAST_DUE_BANNER_ID} from src2/app/core/contract/contract-compliance/ContractComplianceConstants.js
   */
  angular
    .module('app.core.contract.contract-compliance')
    .constant('COMPLIANCE_CONTEXT', {
      // Contract is indirect
      INDIRECT: 'INDIRECT',
      // Contract is direct, has payment category set to credit card or paypal, and
      // the billing frequency is monthly (Annual Paid Monthly; APM)
      TEAM_DIRECT_CCPAYPAL_APM: 'TEAM_DIRECT_CCPAYPAL_APM',
      // Contract is direct, has payment category set to credit card or paypal, and
      // the billing frequency is annual (Paid Up Front; PUF)
      TEAM_DIRECT_CCPAYPAL_PUF: 'TEAM_DIRECT_CCPAYPAL_PUF',
      // Contract has payment category set to VENDOR_DIGITAL_RIVER_PAYMENT
      TEAM_DIRECT_DIGITAL_RIVER: 'TEAM_DIRECT_DIGITAL_RIVER',
      // Contract has payment category set to purchase order or OFFLINE
      TEAM_DIRECT_PO_OFFLINE: 'TEAM_DIRECT_PO_OFFLINE',
      // Contract has a context that is unrecognized
      UNKNOWN: 'UNKNOWN',
    })
    .constant('COMPLIANCE_GRACE_PAST_DUE_BANNER_ID', 'COMPLIANCE_GRACE_PAST_DUE_BANNER_ID');
})();
