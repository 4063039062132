import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useContentLogsContext} from '../ContentLogsContext';

import RemoveContentLogModal from './RemoveContentLogModal';

/**
 * @description the button for opening the modal to remove one/more content logs from the table.
 */
const RemoveContentLogButton = observer(() => {
  const {contentLogsStore} = useContentLogsContext();

  const intl = useIntl();
  const selectedCount = contentLogsStore.tableStore.selectedItemCount;
  const removeReportLabel = intl.formatMessage(
    {id: 'settings.contentLogs.removeReport'},
    {count: selectedCount}
  );

  return (
    <DialogTrigger>
      <Button
        data-testid="remove-content-log-button"
        isDisabled={selectedCount === 0}
        variant="negative"
      >
        {removeReportLabel}
      </Button>
      {(close) => <RemoveContentLogModal onClose={close} />}
    </DialogTrigger>
  );
});

export default RemoveContentLogButton;
