import {Flex, View} from '@adobe/react-spectrum';
import {useFocusWithin} from '@react-aria/interactions';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {GLOBAL_BANNER_TYPES} from './GlobalBannerConstants';
import GlobalBannerControls from './GlobalBannerControls';
import GlobalBannerMessageAndAction from './GlobalBannerMessageAndAction';
import GlobalBannerRedirectUrlButton from './GlobalBannerRedirectUrlButton';

import './GlobalBanner.pcss';

const typeToBackgroundColorMap = {
  [GLOBAL_BANNER_TYPES.ERROR]: 'negative',
  [GLOBAL_BANNER_TYPES.INFO]: 'gray-700',
  [GLOBAL_BANNER_TYPES.WARNING]: 'informative',
};

/**
 * Top level wrapper for the global banner widget.
 */
const GlobalBanner = observer(() => {
  const intl = useIntl();
  const globalBannerStore = rootStore.organizationStore.globalBannerStore;
  const currentBanner = globalBannerStore.currentBanner;
  const numberOfQueuedBanners = globalBannerStore.numberOfQueuedBanners;
  const {focusWithinProps} = useFocusWithin({
    onFocusWithinChange: (isFocusWithinVal) => {
      document
        .querySelector('#global-banner-message')
        .setAttribute('aria-live', isFocusWithinVal ? 'polite' : 'off');
    },
  });

  const getBannerIcon = () => {
    const commonIconProps = {
      marginTop: 'size-85',
      size: 'S',
      UNSAFE_style: {color: 'white'},
    };
    if (currentBanner?.type === GLOBAL_BANNER_TYPES.ERROR) {
      return (
        <AlertIcon
          {...commonIconProps}
          aria-label={intl.formatMessage({id: 'globalBanner.icon.ariaLabel.error'})}
        />
      );
    }
    if (currentBanner?.type === GLOBAL_BANNER_TYPES.WARNING) {
      return (
        <InfoOutlineIcon
          {...commonIconProps}
          aria-label={intl.formatMessage({id: 'globalBanner.icon.ariaLabel.info'})}
        />
      );
    }
    return null;
  };

  const hasButtonWithRedirectUrl =
    currentBanner && currentBanner.buttonText && currentBanner.buttonUrl;
  const bannerIcon = getBannerIcon();

  return (
    numberOfQueuedBanners > 0 && (
      <section
        {...focusWithinProps}
        aria-label={intl.formatMessage(
          {id: 'globalBanner.ariaLabel'},
          {
            currentIdx: currentBanner.index + 1,
            numberOfQueuedBanners: globalBannerStore.numberOfQueuedBanners,
          }
        )}
        data-testid="global-banner-section"
      >
        <View
          backgroundColor={typeToBackgroundColorMap[currentBanner.type]}
          height="100%"
          // Wanting to vertically center the child element, but also apply the background color.
          // Additionally applying non-RS minWidth to get it to match
          UNSAFE_style={{alignItems: 'center', display: 'flex', minHeight: '48px'}}
          width="100%"
        >
          <Flex
            alignItems="center"
            height="100%"
            marginX="size-50"
            marginY={{base: 'size-200', L: 'size-100'}}
            width="100%"
          >
            <div
              aria-live="off"
              aria-relevant="additions text"
              data-testid="global-banner-message"
              // eslint-disable-next-line react/forbid-dom-props -- Id required
              id="global-banner-message"
              role="alert"
              styleName="global-banner-message"
            >
              <Flex direction="row" gap="size-150">
                {bannerIcon && (
                  <View marginStart="size-175" minWidth="size-225">
                    {bannerIcon}
                  </View>
                )}
                <GlobalBannerMessageAndAction />
              </Flex>
            </div>
            {hasButtonWithRedirectUrl && (
              <View
                data-testid="banner-button"
                marginX="size-175"
                UNSAFE_style={{flexGrow: 1, textAlign: 'end'}}
              >
                <GlobalBannerRedirectUrlButton
                  buttonUrl={currentBanner.buttonUrl}
                  currentBanner={currentBanner}
                />
              </View>
            )}
            <View marginStart="auto">
              <GlobalBannerControls />
            </View>
          </Flex>
        </View>
      </section>
    )
  );
});

export default GlobalBanner;
