const ENCRYPTION_SETTINGS_CONSTANTS = {
  ENCRYPTION_MODAL: {
    ENABLE: 'ENABLE',
    REVOKE: 'REVOKE',
  },

  ENCRYPTION_PROGRESS_STATUS: {
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    IN_PROGRESS: 'IN_PROGRESS',
    UNKNOWN: 'UNKNOWN',
  },

  ENCRYPTION_STATUS: {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
    REVOKED: 'REVOKED',
  },
};

export {ENCRYPTION_SETTINGS_CONSTANTS};
