(function () {
  /**
   * @ngdoc module
   * @name app.widgets.contracts
   * @description module that defines content logs related widgets
   */
  angular.module('app.widgets.content-logs', [
    'app.core.api.jil',
    'binky.core.common.selection',
    'binky.core.lodash',
    'binky.shell.panels.modal',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.date-time',
    'binky.widgets.common.datepicker',
    'binky.widgets.common.feature',
    'binky.widgets.common.pagination-control-bar',
    'binky.widgets.common.status',
    'binky.widgets.common.toast',
    'pascalprecht.translate',
    'ui.router',
  ]);
})();
