import {View} from '@adobe/react-spectrum';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import PropTypes from 'prop-types';
import React from 'react';

import ProductTableSection from '../product-table/ProductTableSection';

/**
 * A component that displays a ProductTableSection in a collapsible accordion, nothing
 * will be rendered if there are no items.
 */
const ProductTableSectionAccordion = ({ariaLabel, ariaLevel = 2, cloud, header, items}) =>
  items.length > 0 && (
    <View data-adobe-cloud={cloud}>
      <Accordion ariaLevel={ariaLevel} defaultSelectedIndex={0}>
        <AccordionItem header={header}>
          <ProductTableSection ariaLabel={ariaLabel} items={items} />
        </AccordionItem>
      </Accordion>
    </View>
  );

ProductTableSectionAccordion.propTypes = {
  /**
   * Aria label to apply to this accordion's product table.
   */
  ariaLabel: PropTypes.string.isRequired,
  /**
   * Level to assign to the accordion. Defaults to 2.
   */
  ariaLevel: PropTypes.number,

  /**
   * Adobe cloud for the accordion
   * @see getKeySuffix in
   * src2/app/features/products/pages/product-all-products-page/AllProductsPage.jsx
   */
  cloud: PropTypes.string.isRequired,
  /**
   * Localized string to use as this accordion's displayed header.
   */
  header: PropTypes.string.isRequired,
  /**
   * Array of items that are relevant for this accordion's table to display.
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductTableSectionAccordion;
