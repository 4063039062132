/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Admin modal
   */
  angular.module('app.widgets.users').component('appAdminRolesForm', {
    bindings: {
      promiseToWait: '<',
      readOnly: '<',
      user: '<',
    },
    controller,
    templateUrl: 'app/widgets/users/admin-roles-form/admin-roles-form.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $scope,
    _,
    ADMIN_ROLES_FORM_ERROR,
    ADMIN_ROLES_FORM_VALIDITY_CHANGE,
    auth,
    feature,
    OrganizationManager,
    ProductConfigurationList,
    binkyProductNameLabelFilter,
    ROLE,
    productAccess,
    productConfigurationAccess,
    storageAccess,
    UserGroupList,
    UserRole
  ) {
    const vm = this;
    vm.$onInit = $onInit;
    vm.$doCheck = $doCheck;

    function $onInit() {
      _.assign(vm, {
        canEditRole,
        canViewRole,
        getKeyForRole,
        getRoleCount,
        hasRole,
        hasSetRoles: vm.user.roles.length > 0,
        hasTooltip,
        isTopLevelRole,
        onAllProductProfileAutocompleteChange,
        onProductAutocompleteChange,
        onProductProfileAutocompleteChange,
        onSingleProductAutocompleteChange,
        onUserGroupAutocompleteChange,
        ROLE,
        selectedProduct: null,
        titleKey: vm.readOnly ? '.readOnlyTitle' : '.editTitle',
        toggleRole,
      });

      vm.waitStorageAccess = storageAccess
        .canViewStorage()
        .then((result) => {
          vm.canViewStorage = result;
        })
        .catch(() => {
          vm.canViewStorage = false;
        });

      // Fetch product list
      vm.productList = OrganizationManager.getProductsForActiveOrg();
      vm.waitProductList = vm.productList.$promise;

      $q.all([vm.waitStorageAccess, vm.waitProductList])
        .then(() => {
          vm.productAutocompleteItems = _(vm.productList.items)
            .filter((item) => productAccess.canShowAdmins(item))
            .map((item) => ({
              display: binkyProductNameLabelFilter(item),
              value: item.id,
            }))
            .value();
          vm.productAutocompleteItemsForProfiles = _(vm.productList.items)
            .filter(
              // eslint-disable-next-line lodash/prefer-over-quantifier
              (item) =>
                productConfigurationAccess.canShowAdmins(item) &&
                productConfigurationAccess.canViewProductProfiles(item)
            )
            .map((item) => ({
              display: binkyProductNameLabelFilter(item),
              value: item.id,
            }))
            .value();
          setProductAutocompleteValues();
          vm.roles = _.filter(
            [
              ROLE.ADMIN.ORG,
              ROLE.ADMIN.PRODUCT,
              ROLE.ADMIN.LICENSE,
              ROLE.ADMIN.USER_GROUP,
              ROLE.ADMIN.DEPLOYMENT,
              ROLE.ADMIN.STORAGE,
              ROLE.ADMIN.SUPPORT,
            ],
            (role) => (!vm.readOnly || vm.getRoleCount(role) > 0) && canViewRole(role)
          );
        })
        .catch(emitError);

      // Fetch user group list
      const getAllGroups = true;
      vm.userGroupList = UserGroupList.get(getAllGroups);
      vm.waitUserGroupList = vm.userGroupList.$promise;
      vm.waitUserGroupList
        .then(() => {
          vm.userGroupAutocompleteItems = _(vm.userGroupList.items)
            .filter(
              (item) =>
                (auth.isUserOrgAdmin() || auth.isUserUserGroupAdminForUserGroup(item.id)) &&
                !item.isExternallyManaged()
            )
            .map((item) => ({
              display: item.name,
              value: item.id,
            }))
            .value();
          setUserGroupAutocompleteValues();
        })
        .catch(emitError);

      resetSelectedProduct();
      setAllProductProfileAutocompleteItemsValues();
      if (vm.readOnly) {
        calculateProfileSummaryAutocompleteData();
      }
    }

    function $doCheck() {
      // Watch for changes to the user's roles and update the autocomplete
      if (!vm.hasSetRoles && vm.user.roles.length > 0) {
        vm.hasSetRoles = true;
        setProductAutocompleteValues();
        setUserGroupAutocompleteValues();
      }
    }

    /////////////

    function calculateProfileSummaryAutocompleteData() {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE});
      if (role) {
        vm.profileSummaryAutocompleteData = _(role.targets)
          .groupBy('parentId')
          .map((product) => ({
            items: _.map(product, (target) => ({
              display: target.name,
              value: target.id,
            })),
            name: product[0].parentName,
            values: _.map(product, 'id'),
          }))
          .value();
      } else {
        vm.profileSummaryAutocompleteData = [];
      }
    }

    function canEditRole(role) {
      return !(_.get(vm, 'user.id') === auth.getUserId() && role === auth.getHighestRole());
    }

    // eslint-disable-next-line complexity
    function canViewRole(role) {
      if (
        role !== ROLE.ADMIN.ORG &&
        (vm.productList.hasOnlyTeamProducts() ||
          // Some migration types override the read-only state to allow admins to be
          // added while setting up for the migration. The only role that is allowed
          // in this state is org admin, do not show any other role.
          OrganizationManager.getMigrationsForActiveOrg().shouldForceAllowAddAdminsOrUsers())
      ) {
        return false;
      }

      if (role === ROLE.ADMIN.DEPLOYMENT && !vm.productList.hasPackageSupport()) {
        return false;
      }

      if (role === ROLE.ADMIN.LICENSE && vm.productAutocompleteItemsForProfiles.length === 0) {
        return false;
      }

      if (role === ROLE.ADMIN.STORAGE && !vm.canViewStorage) {
        return false;
      }

      if (role === ROLE.ADMIN.SUPPORT && !vm.productList.hasSupportRoleAssignmentAllowed()) {
        return false;
      }

      return (
        auth.isUserOrgAdmin() ||
        (role === ROLE.ADMIN.PRODUCT && auth.isUserProductAdmin()) ||
        (role === ROLE.ADMIN.LICENSE && (auth.isUserProductAdmin() || auth.isUserPlcAdmin())) ||
        (role === ROLE.ADMIN.USER_GROUP && auth.isUserUserGroupAdmin()) ||
        (role === ROLE.ADMIN.DEPLOYMENT && auth.isUserDeploymentAdmin()) ||
        (role === ROLE.ADMIN.STORAGE && auth.isUserStorageAdmin()) ||
        (role === ROLE.ADMIN.SUPPORT && auth.isUserSupportDelegate())
      );
    }

    function emitError() {
      $scope.$emit(ADMIN_ROLES_FORM_ERROR);
    }

    function getKeyForRole(role) {
      let keySuffix = null;
      switch (role) {
        case ROLE.ADMIN.DEPLOYMENT:
          keySuffix = 'deployment';
          break;
        case ROLE.ADMIN.LICENSE:
          keySuffix = 'productProfile';
          break;
        case ROLE.ADMIN.ORG:
          keySuffix = 'system';
          break;
        case ROLE.ADMIN.PRODUCT:
          keySuffix = 'product';
          break;
        case ROLE.ADMIN.STORAGE:
          keySuffix = 'storage';
          break;
        case ROLE.ADMIN.SUPPORT:
          keySuffix = 'support';
          break;
        case ROLE.ADMIN.USER_GROUP:
          keySuffix = 'userGroup';
          break;
        default:
          break;
      }
      return `.adminRoles.${keySuffix}`;
    }

    function getRoleCount(role) {
      const foundRole = _.find(vm.user.roles, {type: role});
      if (foundRole) {
        if (isTopLevelRole(role)) {
          return 1;
        }
        return _.get(foundRole, 'targets.length') || 0;
      }
      return 0;
    }

    function hasRole(role) {
      return _.some(vm.user.roles, ['type', role]);
    }

    function hasTooltip(role) {
      return role === ROLE.ADMIN.ORG;
    }

    function isTopLevelRole(role) {
      return (
        role === ROLE.ADMIN.ORG ||
        role === ROLE.ADMIN.STORAGE ||
        role === ROLE.ADMIN.SUPPORT ||
        role === ROLE.ADMIN.DEPLOYMENT
      );
    }

    function onProductAutocompleteChange(values) {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.PRODUCT});
      role.targets = _(values)
        .filter((value) => _.some(vm.productList.items, ['id', value]))
        .map((id) => ({
          id,
          name: binkyProductNameLabelFilter(_.find(vm.productList.items, {id})),
        }))
        .value();
      setProductAutocompleteValues();
      sendValidityChangeEvent();
    }

    function onAllProductProfileAutocompleteChange(values) {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE});
      role.targets = _.filter(role.targets, (target) => _.includes(values, target.id));
      setProductProfileAutocompleteValues();
      sendValidityChangeEvent();
    }

    function onProductProfileAutocompleteChange(values) {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE});
      _.remove(role.targets, ['parentId', vm.selectedProduct.id]);
      role.targets = _(values)
        .filter((value) => _.some(vm.productProfileList.items, ['id', value]))
        .map((id) => ({
          id,
          name: _.find(vm.productProfileList.items, {id}).name,
          parentId: vm.selectedProduct.id,
          parentName: binkyProductNameLabelFilter(vm.selectedProduct),
        }))
        .concat(role.targets)
        .value();

      setProductProfileAutocompleteValues();
      sendValidityChangeEvent();
    }

    function onSingleProductAutocompleteChange(value) {
      vm.selectedProduct = _.find(vm.productList.items, {id: value});
      if (!vm.selectedProduct) {
        resetSelectedProduct();
        return;
      }
      const pageSize = 100;
      vm.productProfileList = ProductConfigurationList.get(vm.selectedProduct, pageSize);
      vm.waitProductProfileList = vm.productProfileList.$promise;
      vm.waitProductProfileList
        .then(() => {
          vm.productProfileAutocompleteItems = _(vm.productProfileList.items)
            .filter(
              (item) =>
                auth.isUserOrgAdmin() ||
                auth.isUserPlcAdminForPLCContext(item.id) ||
                auth.isUserProductAdminForTarget(vm.selectedProduct.targetExpression)
            )
            .map((item) => ({
              display: item.name,
              value: item.id,
            }))
            .value();
          setProductProfileAutocompleteValues();
        })
        .catch(emitError);
    }

    function onUserGroupAutocompleteChange(values) {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.USER_GROUP});
      role.targets = _(values)
        .filter((value) => _.some(vm.userGroupList.items, ['id', value]))
        .map((id) => ({
          id,
          name: _.find(vm.userGroupList.items, {id}).name,
        }))
        .value();
      setUserGroupAutocompleteValues();
      sendValidityChangeEvent();
    }

    function resetSelectedProduct() {
      vm.selectedProduct = null;
      vm.waitProductProfileList = $q.resolve();
      setProductAutocompleteValues();
    }

    function sendValidityChangeEvent() {
      const valid =
        (!vm.user.isNew() && vm.user.hasUnsavedChanges()) ||
        (vm.user.isNew() && vm.user.toMinimumModel().roles.length > 0);
      if (valid !== vm.isValid) {
        vm.isValid = valid;
        $scope.$emit(ADMIN_ROLES_FORM_VALIDITY_CHANGE, vm.isValid);
      }
    }

    function setAllProductProfileAutocompleteItemsValues() {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE});
      vm.allProductProfileAutocompleteItems = role
        ? _.map(role.targets, (target) => ({
            display: target.name,
            value: target.id,
          }))
        : [];
      vm.allProductProfileAutocompleteValues = role ? _.map(role.targets, 'id') : [];
    }

    function setProductAutocompleteValues() {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.PRODUCT});
      vm.productAutocompleteValues = role ? _.map(role.targets, 'id') : [];
      setProductProfileAutocompleteValues();
    }

    function setProductProfileAutocompleteValues() {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.LICENSE});
      vm.productProfileAutocompleteValues =
        role && vm.selectedProduct
          ? _(role.targets).filter(['parentId', vm.selectedProduct.id]).map('id').value()
          : [];
      setAllProductProfileAutocompleteItemsValues();
    }

    function setUserGroupAutocompleteValues() {
      const role = _.find(vm.user.roles, {type: ROLE.ADMIN.USER_GROUP});
      vm.userGroupAutocompleteValues = role ? _.map(role.targets, 'id') : [];
    }

    function toggleRole(role) {
      if (hasRole(role)) {
        _.remove(vm.user.roles, ['type', role]);

        if (role === ROLE.ADMIN.LICENSE) {
          resetSelectedProduct();
        } else if (role === ROLE.ADMIN.PRODUCT) {
          setProductAutocompleteValues();
        } else if (role === ROLE.ADMIN.USER_GROUP) {
          setUserGroupAutocompleteValues();
        }
      } else {
        const userRoleOptions = {
          targets: isTopLevelRole(role) ? undefined : [],
          type: role,
        };
        vm.user.roles.push(new UserRole(userRoleOptions));
      }
      sendValidityChangeEvent();
    }
  }
})();
/* eslint-enable max-lines */
