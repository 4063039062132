import {DIRECTORY_OWNERSHIP_STATUS, feature} from '@admin-tribe/binky';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback} from 'react';

import {DIRECTORY_STATUSES} from 'common/entities/DirectoryEntity';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';

TABLE_SECTION_ACTIONS.FILTER_BY_STATUS = 'filterByStatus';

const ALL_STATUSES = 'ALL_STATUSES';

/**
 * A hook that helps with managing the Directories table
 */
const useDirectoryTable = ({isDirectoryListPage} = false) => {
  const {tableOptions, loadDirectoryList, loadDirectoryListWithCertificates} =
    useDirectoryListContext();

  const loadDirectories = useCallback(
    (options) =>
      feature.isEnabled('temp_saml_certs') && !!isDirectoryListPage
        ? loadDirectoryListWithCertificates(options)
        : loadDirectoryList(options),
    [isDirectoryListPage, loadDirectoryList, loadDirectoryListWithCertificates]
  );

  const filterByStatus = useCallback(
    ({value}) => {
      const computedOwnershipStatus = () => {
        if (value === DIRECTORY_STATUSES.ACTIVE) {
          return DIRECTORY_OWNERSHIP_STATUS.OWNED;
        }

        return value === DIRECTORY_OWNERSHIP_STATUS.TRUSTED ? value : undefined;
      };

      const computedStatus = () => {
        if (value === ALL_STATUSES) {
          return undefined;
        }

        return value === DIRECTORY_OWNERSHIP_STATUS.TRUSTED ? DIRECTORY_STATUSES.ACTIVE : value;
      };

      const updatedTabledOptions = {
        ...tableOptions,
        ownershipStatus: computedOwnershipStatus(),
        // reset the page when searching
        page: 1,
        status: computedStatus(),
      };

      loadDirectories(updatedTabledOptions);
    },
    [loadDirectories, tableOptions]
  );

  const goToPage = useCallback(
    (page) => {
      const updatedTabledOptions = {
        ...tableOptions,
        page,
      };

      loadDirectories(updatedTabledOptions);
    },
    [loadDirectories, tableOptions]
  );

  const search = useCallback(
    ({value}) => {
      const updatedTabledOptions = {
        ...tableOptions,
        // reset the page when searching
        page: 1,
        searchQuery: value,
      };

      loadDirectories(updatedTabledOptions);
    },
    [loadDirectories, tableOptions]
  );

  const sortBy = useCallback(
    ({id, sortedOrder}) => {
      const updatedTabledOptions = {
        ...tableOptions,
        sortColumn: id,
        sortOrder: sortedOrder,
      };

      loadDirectories(updatedTabledOptions);
    },
    [loadDirectories, tableOptions]
  );

  const onPageSizeChange = useCallback(
    (pageSize) => {
      const updatedTabledOptions = {
        ...tableOptions,
        // always reset the current page to 1 when the page size changes
        page: 1,
        pageSize,
      };

      loadDirectories(updatedTabledOptions);
    },
    [loadDirectories, tableOptions]
  );

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          search(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          sortBy(payload);
          break;
        case TABLE_SECTION_ACTIONS.FILTER_BY_STATUS:
          filterByStatus(payload);
          break;
        default:
          // Do nothing
          break;
      }
    },
    [search, goToPage, onPageSizeChange, sortBy, filterByStatus]
  );

  return {
    filterByStatus,
    goToPage,
    onPageSizeChange,
    onTableSectionChange,
    search,
    sortBy,
  };
};

export default useDirectoryTable;
export {TABLE_SECTION_ACTIONS};
