(function () {
  /**
   * @ngdoc module
   * @name app.core.user-group.configurations-list
   * @description module that defines the UserGroupConfigurationsList model
   */
  angular.module('app.core.user-group.configurations-list', [
    'app.core',
    'app.core.api.jil',
    'app.core.model-cache',
    'app.core.product.configurations',
    'binky.core.lodash',
  ]);
})();
