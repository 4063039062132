import HelpItem from './HelpItem';

export default class HelpTopic extends HelpItem {
  /**
   * @description Comparator for HelpTopic instances (sorting, etc)
   * @param {HelpTopic} a first help topic to compare
   * @param {HelpTopic} b second help topic to compare
   * @returns {number} negative if b comes after a, zero if b is equivalent to
   * a, and positive if b comes before a
   */
  static compareTopicsByOrder(a, b) {
    return a.order - b.order;
  }

  /**
   * @description Creates a new HelpTopic for use. The HelpTopic is a type
   *   of HelpItem that is navigable within the help content section and
   *   contains other HelpTopics or HelpItems (content).
   * @param {Object} options - Initialization Object (params described
   *   below)
   * @param {Function} [options.access] - Callback to see if signed
   *   in user should have access to this item. If omitted, user will always
   *   have access. If provided, method should return a Promise
   *   to allow chaining and async access calculations
   * @param {String} [options.breadcrumbName] - Name to use to link back to
   *   this HelpTopic from the Help Rail breadcrumbs
   * @param {HelpItem[]} options.content - Any help items that should be
   *   included within this topic
   * @param {String} options.id - Unique identifier for this HelpTopic
   * @param {Number} options.order - The numerical order that this topic
   *   should be placed in, when rendered within a list of other topics
   * @param {Boolean} options.ordered - Whether or not the content contained
   *   in this HelpTopic should be ordered or not (unordered)
   * @param {String[]} [options.parents] - An array of any parent HelpTopic
   *   IDs that contain the current HelpTopic. Required - root
   *   HelpTopic will not contain a parent reference, but should declare an
   *   empty Array set here
   * @param {String} [options.text] - Any textual content that should be
   *   associated with this help item. Optional - may be excluded
   * @param {String} [options.title] - The String translation key for the
   *   title of this help item. Optional - some items do not contain titles
   *   (e.g. - info items)
   */
  constructor(options = {}) {
    super(options);

    const classAttrs = ['breadcrumbName', 'content', 'id', 'order', 'ordered', 'parents'];

    classAttrs.forEach((attr) => {
      if (options[attr] !== undefined && options[attr] !== null) {
        Object.assign(this, {[attr]: options[attr]});
      }
    });
  }
}
