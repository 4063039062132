/* eslint-disable max-lines -- ff'd code */
import {EVENT_ACTION, PAGE_TARGET, PAGE_TARGET_TYPE, PageContext, log} from '@admin-tribe/binky';
import {
  ModalContainer,
  ModalDialog,
  WIZARD_DISPATCH_ACTION,
  WizardTrigger,
  WizardView,
} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React, {useEffect, useReducer, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import BulkOperationModal from 'common/components/bulk-operation/bulk-operation-modal/BulkOperationModal';
import {BULK_OPERATION_MODE} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import {WizardAnalyticsContextProvider} from 'common/components/wizard/WizardAnalyticsContext';
import rootStore from 'core/RootStore';
import OrganizationContractMigrationList from 'features/contract/models/OrganizationContractMigrationList';
import {
  COLUMN_KEY,
  SORT_DIRECTION_ASCENDING,
} from 'features/products/product-migration-table/ProductMigrationTable.constants';
import {
  getSortedEligibleMigrations,
  updateMigrationTargetSelection,
} from 'features/products/product-migration-table/ProductMigrationTableUtils';
import {
  dispatchErrorAnalytics,
  dispatchProductListAnalytics,
  dispatchWizardStepAnalytics,
} from 'features/users/services/offer-switch-migration/offerSwitchMigrationAnalyticsUtils';
import {
  getEligibleMigrations,
  getMigrationSummaryData,
  getOfferSwitchMigrationSummary,
  getRequestBodyByEligibleMigrations,
} from 'features/users/services/offer-switch-migration/offerSwitchMigrationHelper';

import OfferSwitchMigrationAssignProducts from './OfferSwitchMigrationAssignProducts';
import {
  MAX_MIGRATION_NUMBER,
  SOURCE_PRODUCT_INDEX,
  TARGET_PRODUCT_INDEX,
  WIZARD_STEPS,
} from './OfferSwitchMigrationConstants';
import OfferSwitchMigrationIntro from './OfferSwitchMigrationIntro';
import OfferSwitchMigrationSummary from './OfferSwitchMigrationSummary';
import {
  MIGRATION_DATA_ACTIONS,
  OfferSwitchMigrationContextProvider,
} from './context/OfferSwitchMigrationContext';

// The actual content of each entry doesn't matter, just need to have the same # of entries as the wizard steps
const STEPS = [
  WIZARD_STEPS.INTRO.toString(),
  WIZARD_STEPS.ASSIGN_PRODUCTS.toString(),
  WIZARD_STEPS.SUMMARY.toString(),
];

// Assume being launched from globalModalsManager, thus onClosed is injected
// and needs to be called when this modal is closed
const OfferSwitchMigrationModal = ({
  contractRenewalEndDate,
  isOpen = false,
  onClosed,
  onOfferMigrationCanceled,
  onBulkOperationSuccess,
}) => {
  const [isOfferSwitchMigrationModalOpen, setIsOfferSwitchMigrationModalOpen] = useState(isOpen);
  const [isBulkOpModalOpen, setIsBulkOpModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);

  const intl = useIntl();

  const closeModal = () => {
    setIsOfferSwitchMigrationModalOpen(false);
    onClosed();
  };

  const closeBulkOpModal = (isSuccess) => {
    setIsBulkOpModalOpen(false);
    onClosed();
    if (isSuccess) {
      onBulkOperationSuccess?.();
    }
  };

  const closeModalAndRedirect = () => {
    setIsOfferSwitchMigrationModalOpen(false);
    setIsBulkOpModalOpen(true);
  };

  const migrationDataReducer = (migrationData, action) => {
    switch (action.type) {
      case MIGRATION_DATA_ACTIONS.CLEAR_ERROR:
        return {
          ...migrationData,
          errorMessage: undefined,
        };

      case MIGRATION_DATA_ACTIONS.ERROR:
        return {
          ...migrationData,
          errorMessage: intl.formatMessage({id: 'users.offerSwitchMigrationModal.errorMessage'}),
        };

      case MIGRATION_DATA_ACTIONS.INITIALIZE_ELIGIBLE_MIGRATIONS:
        return {
          ...migrationData,
          eligibleMigrations: getSortedEligibleMigrations(
            action.initEligibleMigrations,
            migrationData.sortDescriptor
          ),
        };

      case MIGRATION_DATA_ACTIONS.INITIALIZE_PRODUCT_GROUPS:
        return {
          ...migrationData,
          productGroups: action.initProductGroups,
        };

      case MIGRATION_DATA_ACTIONS.SET_TARGET_PRODUCT:
        updateMigrationTargetSelection(action);
        return {
          ...migrationData,
          eligibleMigrations: [...migrationData.eligibleMigrations], // Re-render
        };

      case MIGRATION_DATA_ACTIONS.SORT_MIGRATIONS:
        return {
          ...migrationData,
          eligibleMigrations: getSortedEligibleMigrations(
            migrationData.eligibleMigrations,
            action.sortDescriptor
          ),
          sortDescriptor: action.sortDescriptor,
        };

      default:
        throw new Error(`Invalid action ${action.type}`);
    }
  };

  const [migrationData, dispatchMigrationData] = useReducer(migrationDataReducer, {
    contractRenewalEndDate,
    eligibleMigrations: [],
    productGroups: [],
    sortDescriptor: {
      column: COLUMN_KEY.NAME,
      direction: SORT_DIRECTION_ASCENDING,
    },
  });

  const {targetProducts, usersToGetAccess} = getMigrationSummaryData(
    migrationData.eligibleMigrations
  );

  const dispatchButtonPressAnalytics = (eventAction) => {
    dispatchProductListAnalytics({
      eligibleMigrations: migrationData.eligibleMigrations,
      eventAction,
      sourceProducts: migrationData.productGroups[SOURCE_PRODUCT_INDEX]?.productList,
      targetProducts: migrationData.productGroups[TARGET_PRODUCT_INDEX]?.productList,
    });
  };

  const onDispatchPageAnalytics = ({wizardStep}) => {
    // Let the data load triggers analytics during the initialization
    if (wizardStep === WIZARD_STEPS.INTRO && isLoading) {
      return;
    }

    dispatchWizardStepAnalytics({
      eligibleMigrations: migrationData.eligibleMigrations,
      sourceProducts: migrationData.productGroups[SOURCE_PRODUCT_INDEX]?.productList,
      targetProducts: migrationData.productGroups[TARGET_PRODUCT_INDEX]?.productList,
      wizardStep,
    });
  };

  const getButtonText = (type) => intl.formatMessage({id: `common.modal.buttons.${type}`});

  const getBackButtonText = (currentStep) =>
    currentStep === WIZARD_STEPS.INTRO ? undefined : getButtonText('back');

  const getCtaButtonText = (currentStep) => {
    if (currentStep === WIZARD_STEPS.SUMMARY) {
      return getButtonText('confirm');
    }
    return getButtonText(
      migrationData.eligibleMigrations.length > MAX_MIGRATION_NUMBER
        ? 'assignLicensesByCSV'
        : 'next'
    );
  };

  const getModalString = (suffix) =>
    intl.formatMessage(
      {id: `users.offerSwitchMigrationModal.${suffix}`},
      {
        userCount: usersToGetAccess.length,
      }
    );

  const isCtaDisabled = (currentStep) =>
    currentStep === WIZARD_STEPS.ASSIGN_PRODUCTS ? targetProducts.length === 0 : isLoading;

  const migrateUsers = async () => {
    const eventName = 'licenseAssignmentMigration';

    dispatchButtonPressAnalytics('confirm');
    setIsLoading(true);
    try {
      const requestBody = getRequestBodyByEligibleMigrations(migrationData.eligibleMigrations);
      await OrganizationContractMigrationList.migrate(requestBody);
      dispatchProductListAnalytics({
        eligibleMigrations: migrationData.eligibleMigrations,
        eventAction: EVENT_ACTION.SUCCESS,
        eventName,
        sourceProducts: migrationData.productGroups[SOURCE_PRODUCT_INDEX]?.productList,
        targetProducts: migrationData.productGroups[TARGET_PRODUCT_INDEX]?.productList,
      });
      closeModal();
      return true; // Return true shows a toast
    } catch (error) {
      setIsLoading(false);
      dispatchMigrationData({type: MIGRATION_DATA_ACTIONS.ERROR});
      dispatchErrorAnalytics({
        eligibleMigrations: migrationData.eligibleMigrations,
        errorDetail: error,
        eventName,
        sourceProducts: migrationData.productGroups[SOURCE_PRODUCT_INDEX]?.productList,
        targetProducts: migrationData.productGroups[TARGET_PRODUCT_INDEX]?.productList,
      });
      return false;
    }
  };

  const onCloseError = (currentStep) => {
    if (currentStep === WIZARD_STEPS.SUMMARY) {
      // only re-enable cta button in summary step so that user can try migrating again
      dispatchMigrationData({type: MIGRATION_DATA_ACTIONS.CLEAR_ERROR});
    }
  };

  const onModalCancel = () => {
    // The order matters, send analytics first, then the onOfferMigrationCanceled callback
    dispatchButtonPressAnalytics('cancel');
    onOfferMigrationCanceled();
    closeModal();
  };

  const onModalCta = async ({currentStep, dispatch}) => {
    if (
      currentStep === WIZARD_STEPS.INTRO &&
      migrationData.eligibleMigrations.length > MAX_MIGRATION_NUMBER
    ) {
      dispatchButtonPressAnalytics('assigneLicensesByCSV');
      closeModalAndRedirect();
      return false;
    }
    if (currentStep === WIZARD_STEPS.SUMMARY) {
      const isSuccess = await migrateUsers();
      return isSuccess;
    }
    dispatchButtonPressAnalytics('next');
    dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
    return false;
  };

  const onSecondary = ({currentStep, dispatch}) =>
    // To avoid console warning, we should return undefined when secondaryLabel is undefined
    currentStep === WIZARD_STEPS.INTRO
      ? undefined
      : () => {
          dispatchButtonPressAnalytics('back');
          // Should remove error msg when back button is clicked so that user can try migrating again
          dispatchMigrationData({type: MIGRATION_DATA_ACTIONS.CLEAR_ERROR});
          dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
        };
  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- jwu@ to update
  useEffect(() => {
    setIsLoading(true);

    const getMigrationData = async () => {
      try {
        const migrationSummary = await getOfferSwitchMigrationSummary(intl);
        if (isMounted.current) {
          dispatchMigrationData({
            initProductGroups: migrationSummary,
            type: MIGRATION_DATA_ACTIONS.INITIALIZE_PRODUCT_GROUPS,
          });
        }

        const migrations = await getEligibleMigrations(intl);
        if (isMounted.current) {
          // calling dispatch twice because we still want to show migration summary when failed to fetch eligible migrations
          dispatchMigrationData({
            initEligibleMigrations: migrations,
            type: MIGRATION_DATA_ACTIONS.INITIALIZE_ELIGIBLE_MIGRATIONS,
          });

          dispatchWizardStepAnalytics({
            eligibleMigrations: migrations,
            sourceProducts: migrationSummary[SOURCE_PRODUCT_INDEX]?.productList,
            targetProducts: migrationSummary[TARGET_PRODUCT_INDEX]?.productList,
            wizardStep: WIZARD_STEPS.INTRO,
          });
        }
      } catch (error) {
        log.error('Failed to get offer switch migration summary. Error: ', error);
        dispatchMigrationData({type: MIGRATION_DATA_ACTIONS.ERROR});
        dispatchErrorAnalytics({errorDetail: error, eventName: 'dataLoad'});
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    getMigrationData();

    return () => {
      isMounted.current = false;
    };
  }, [intl]);

  return (
    <>
      {isOfferSwitchMigrationModalOpen && (
        <ModalContainer>
          <OfferSwitchMigrationContextProvider
            dispatchMigrationData={dispatchMigrationData}
            migrationData={migrationData}
          >
            <WizardTrigger steps={STEPS}>
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- jwu@ to update */}
              {({currentStep, dispatch}) => (
                <WizardAnalyticsContextProvider onDispatchPageAnalytics={onDispatchPageAnalytics}>
                  <ModalDialog
                    cancelLabel={getButtonText('cancel')}
                    ctaLabel={getCtaButtonText(currentStep)}
                    ctaToastGenerator={() => getModalString('toast.success')}
                    errorMessage={migrationData.errorMessage}
                    heightVariant="static"
                    id="offer-switch-migration"
                    isCtaDisabled={isCtaDisabled(currentStep)}
                    isLoading={isLoading}
                    onCancel={onModalCancel}
                    onCloseError={() => onCloseError(currentStep)}
                    onCta={() => onModalCta({currentStep, dispatch})}
                    onSecondary={onSecondary({currentStep, dispatch})}
                    secondaryLabel={getBackButtonText(currentStep)}
                    title={getModalString('title')}
                  >
                    <WizardView>
                      <OfferSwitchMigrationIntro />
                      <OfferSwitchMigrationAssignProducts />
                      <OfferSwitchMigrationSummary />
                    </WizardView>
                  </ModalDialog>
                </WizardAnalyticsContextProvider>
              )}
            </WizardTrigger>
          </OfferSwitchMigrationContextProvider>
        </ModalContainer>
      )}
      <BulkOperationModal
        isOpen={isBulkOpModalOpen}
        mode={BULK_OPERATION_MODE.OFFER_SWITCH_MIGRATION}
        onCancel={() => closeBulkOpModal(false)}
        onSuccess={() => closeBulkOpModal(true)}
        pageContext={
          new PageContext({
            target: PAGE_TARGET.ORGANIZATION,
            targetId: rootStore.organizationStore.activeOrgId,
            targetType: PAGE_TARGET_TYPE.USER,
          })
        }
      />
    </>
  );
};

OfferSwitchMigrationModal.propTypes = {
  /**
   * contract renewal window end date. Ex: June, 20, 2020
   */
  contractRenewalEndDate: PropTypes.string.isRequired,
  /**
   * Flag to show or hide this modal.
   * true: show the modal, false: hide the modal
   */
  isOpen: PropTypes.bool,
  /**
   * Callback when both offer switch flow and bulk op flow are completed
   */
  onBulkOperationSuccess: PropTypes.func,
  /**
   * A callback injected by global-modals.component.js
   * Required because this modal must be triggered by global-modals
   */
  onClosed: PropTypes.func.isRequired,
  /**
   * Callback called when the user cancels from this modal.
   */
  onOfferMigrationCanceled: PropTypes.func.isRequired,
};

export default OfferSwitchMigrationModal;
/* eslint-enable max-lines -- ff'd code */
