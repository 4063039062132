import {View} from '@adobe/react-spectrum';
import {SUPPORT_CONSTANTS} from '@pandora/data-model-support-ticket';
import Label from '@react/react-spectrum/Label';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * TODO: For backward compatibility with angular’s supportCase added
 * default, success & warning. These will be removed once we fully migrated away from angular code
 */
const VARIANT_TO_COLOR_MAP = {
  default: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.DEFAULT,
  info: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.INFO,
  neutral: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.DEFAULT,
  notice: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.WARNING,
  positive: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.SUCCESS,
  success: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.SUCCESS,
  warning: SUPPORT_CONSTANTS.VARIANT_TO_COLOR_MAP.WARNING,
};

/**
 * A widget to display the status tag of SupportTicket.
 */
const SupportTicketStatusTag = ({'data-testid': dataTestId, stringKey, variant}) => (
  <View marginTop="size-300">
    <Label
      data-testid={dataTestId || 'status-tag-label'}
      size="L"
      variant={VARIANT_TO_COLOR_MAP[variant]}
    >
      {useIntl().formatMessage({id: `support.supportStatusTag.label.${stringKey.toLowerCase()}`})}
    </Label>
  </View>
);

SupportTicketStatusTag.propTypes = {
  /**
   * data-testid is used as a identifier between angular and react compoments.
   * The default is 'status-tag-label'.
   */
  'data-testid': PropTypes.string,
  /**
   * stringKey is used to define the label of component.
   */
  stringKey: PropTypes.string.isRequired,
  /**
   * variant is used to define background color of the component.
   * values are default, info, success, warning
   *
   * TODO: For backward compatibility with angular’s supportCase added
   * default, success & warning. These will be removed once we fully migrated away from angular code
   */
  variant: PropTypes.oneOf([
    'info',
    'neutral',
    'notice',
    'positive',
    'warning',
    'success',
    'default',
  ]).isRequired,
};

export default SupportTicketStatusTag;
