// Temporarily disable angular/constant name until we rename RENEW_PRODUCTS_MODAL2_STEPS to RENEW_PRODUCTS_MODAL_STEPS
/* eslint-disable angular/constant-name */
(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.products.purchase.renew-products-modal2')
    .constant('PO_NUMBER_DISMISS_CONFIRM_MODAL_ID', 'po-number-modal-id')
    .constant('RENEW_PRODUCTS_MODAL2_ID', 'renew-products-modal2-id')
    .constant('RENEW_PRODUCTS_MODAL2_STATES', {
      MODIFY_RENEWAL_ORDER: 'ModifyRenewalOrder',
      REVIEW_RENEWAL_ORDER: 'ReviewRenewalOrder',
      SELECT_CANCELLATION_REASON: 'SelectCancellationReason',
      SELECT_SPECIAL_OFFERS: 'SelectSpecialOffers',
      SHOW_PAGE_BANNER: 'ShowPageBanner',
      VIEW_RENEWAL_ORDER: 'ViewRenewalOrder',
    })
    .constant('RENEW_PRODUCTS_MODAL2_STEPS', [
      'ViewRenewalOrder',
      'ModifyRenewalOrder',
      'SelectCancellationReason',
      'SelectSpecialOffers',
      'ReviewRenewalOrder',
      'ShowPageBanner',
    ])
    .constant('RENEW_PRODUCTS_MODAL2_ROUTE_ALLOWED_STEPS', {
      ModifyRenewalOrder: true,
      ReviewRenewalOrder: true,
      SelectCancellationReason: false,
      SelectSpecialOffers: false,
      ShowPageBanner: true,
      ViewRenewalOrder: true,
    })
    .constant('RENEW_PRODUCTS_MODAL2_CART_PROPS', {
      responseBillingSummaryData: {
        total: 'responseBillingSummaryData.total',
      },
    });
})();
/* eslint-enable angular/constant-name */
