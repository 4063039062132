(function () {
  /**
   * @deprecated Please use src2/core/services/stock/StockQuantitiesList.js
   *
   * @ngdoc factory
   * @name StockQuantitiesList
   * @description Model for a stock quantity list
   */
  angular.module('app.core.stock').factory('StockQuantitiesList', getStockQuantitiesModel);

  /* @ngInject */
  function getStockQuantitiesModel($q, _, binkySrc2, MODEL, modelCache, OrganizationManager) {
    class StockQuantitiesList extends binkySrc2.services.stock.StockQuantitiesList {
      constructor(params) {
        super(
          _.assign(
            {
              orgId: OrganizationManager.getActiveOrgId(),
            },
            params
          )
        );
        this.$resolved = false;
      }

      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          super
            .refresh()
            .then((response) => {
              resolve(response);
              this.$resolved = true;
              modelCache.put(MODEL.STOCKQUANTITYLIST, this, this.id);
            })
            .catch((error) => {
              reject(error.response);
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      replaceQuotas(id, fulfilledItems) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          try {
            _.forEach(this.items, (item) => {
              const fulfilledItem = _.find(fulfilledItems, ['code', item.jilType]);
              if (_.isUndefined(fulfilledItem)) {
                throw new TypeError(
                  'fulfilledItem code does not match an existing stock quantity list items jilType'
                );
              } else if (item.totalQuantity !== _.parseInt(fulfilledItem.chargingModel.cap)) {
                item.totalQuantity = _.parseInt(fulfilledItem.chargingModel.cap);
              }
            });

            resolve(this);
            this.$resolved = true;
            modelCache.put(MODEL.STOCKQUANTITYLIST, this, id);
          } catch (error) {
            reject(error);
            this.$resolved = true;
          }
        });
        return this.$promise;
      }

      static get(options = {}) {
        let model;

        model = modelCache.get(MODEL.STOCKQUANTITYLIST, options.id);
        if (model) {
          return model;
        }

        model = new StockQuantitiesList(options);
        model.refresh();

        return model;
      }

      /**
       * To be used by the scorecards-stock component that resides on the
       * product profile details view.
       * @param {number} id The id that corresponds to a product profile.
       * @param {array} fulfilledItems fulfilledItems the cap's of which will be
       *  used to update the modelcache's stored values.
       * @returns {Model} Model of a StockQuantitiesList with a promise that
       *  will resolve when the model cache updates are complete or reject when
       *  they error out.
       * @returns {Promise} rejected promise if the modelCache does not have
       *  the requested entry based on id.
       */
      static updateQuotas(id, fulfilledItems) {
        const model = modelCache.get(MODEL.STOCKQUANTITYLIST, id);
        // Temporarily disable for https://github.com/Gillespie59/eslint-plugin-angular/issues/449

        const stockFulfilledItems = _.filter(fulfilledItems, (item) =>
          _.includes(
            [
              'stock_credit',
              'stock_image',
              'stock_standard_credits',
              'stock_premium_credits',
              'stock_universal_credits',
            ],
            item.code
          )
        );
        if (_.isUndefined(model)) {
          return {
            $promise: $q((resolve, reject) => {
              reject(`modelCache does not have an entry corresponding to id: ${id}`);
            }),
          };
        }
        model.replaceQuotas(id, stockFulfilledItems);

        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.STOCKQUANTITYLIST, 1);
    return StockQuantitiesList;
  }
})();
