import {StatusLight, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './SupportTicketStatus.pcss';

const VARIANT_TO_STATUS_MAP = {
  default: 'neutral',
  info: 'info',
  success: 'positive',
  warning: 'notice',
};
/**
 * A widget to display the status of SupportTicket.
 */
const SupportTicketStatus = ({'data-testid': dataTestId, stringKey, variant = 'default'}) => (
  <View>
    <StatusLight
      data-testid={dataTestId || 'status-ticket-label'}
      size="L"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need the font-style & margin
      UNSAFE_className={styles['spectrum-status-light']}
      variant={VARIANT_TO_STATUS_MAP[variant]}
    >
      {useIntl().formatMessage({id: `support.supportStatusTag.label.${stringKey.toLowerCase()}`})}
    </StatusLight>
  </View>
);

SupportTicketStatus.propTypes = {
  /**
   * data-testid is used as a identifier between angular and react components.
   * The default is 'status-ticket-label'.
   */
  'data-testid': PropTypes.string,
  /**
   * stringKey is used to define the label of component.
   */
  stringKey: PropTypes.string.isRequired,
  /**
   * variant is used to define background color of the component.
   * values are default, info, success, warning
   */
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning']),
};

export default SupportTicketStatus;
