import {Radio, RadioGroup, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

// Reusable component used in the ManagmentOptionsPage
// Has a title, checkbox, description and learn more url as part of its template
const DeviceViewConsent = observer(({pkgSessionSettings, setPkgSessionSettingValues}) => {
  const intl = useIntl();
  const handleDeviceViewRadioSelection = (consent) => {
    if (consent === CREATE_PACKAGE_CONSTANTS.DEVICE_INFO_SHARED) {
      setPkgSessionSettingValues('deviceInformationShared', true);
    } else {
      setPkgSessionSettingValues('deviceInformationShared', false);
    }
  };

  return (
    <View>
      <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
        {intl.formatMessage({
          id: 'packages.createPackage.managementOptions.deviceView.heading',
        })}
      </Text>
      <View>
        {intl.formatMessage({
          id: 'packages.createPackage.managementOptions.deviceView.desc',
        })}
        <GoUrl
          aria-label={intl.formatMessage({
            id: 'packages.createPackage.managementOptions.deviceView.learnMoreAriaLabel',
          })}
          marginStart="size-65"
          name="ent_learnmore_device_information"
        >
          {intl.formatMessage({
            id: 'packages.createPackage.managementOptions.learnMoreAboutDeviceVerificationOptions',
          })}
        </GoUrl>
      </View>
      <RadioGroup
        aria-label={intl.formatMessage({
          id: 'packages.createPackage.managementOptions.deviceView.desc',
        })}
        isEmphasized
        marginTop="size-125"
        onChange={handleDeviceViewRadioSelection}
        value={
          pkgSessionSettings.deviceInformationShared
            ? CREATE_PACKAGE_CONSTANTS.DEVICE_INFO_SHARED
            : CREATE_PACKAGE_CONSTANTS.DEVICE_INFO_PROTECTED
        }
      >
        <Radio value={CREATE_PACKAGE_CONSTANTS.DEVICE_INFO_SHARED}>
          {intl.formatMessage({
            id: 'packages.createPackage.managementOptions.deviceView.accepted',
          })}
        </Radio>
        <Radio value={CREATE_PACKAGE_CONSTANTS.DEVICE_INFO_PROTECTED}>
          {intl.formatMessage({
            id: 'packages.createPackage.managementOptions.deviceView.rejected',
          })}
        </Radio>
      </RadioGroup>
    </View>
  );
});

DeviceViewConsent.propTypes = {
  /**
   * Shared object for package creation session request
   */
  pkgSessionSettings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function to set package session setting values
   */
  setPkgSessionSettingValues: PropTypes.func,
};

export default DeviceViewConsent;
