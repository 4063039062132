// eslint-disable-next-line angular/window-service
const BULK_OPERATION_CONSTANTS = window.onesieSrc2.bulkOperation.BULK_OPERATION_CONSTANTS;
(function () {
  /**
   * @deprecated - Please use src2/app/bulk-operation/bulk-operation-utils/bulkOperationConstants
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.bulk-operations.constants')
    .constant('BULK_OPERATION_MODE', BULK_OPERATION_CONSTANTS.BULK_OPERATION_MODE)
    .constant('BULK_OPERATION_CSV', BULK_OPERATION_CONSTANTS.CSV_STANDARD_TEMPLATES)
    .constant('BULK_OPERATION_CSV_HEADER', BULK_OPERATION_CONSTANTS.CSV_HEADER)
    .constant('BULK_OPERATION_ERRORS', BULK_OPERATION_CONSTANTS.BULK_OPERATION_ERRORS)
    .constant('BULK_OPERATION_ROW_LIMIT', BULK_OPERATION_CONSTANTS.CSV_ROW_LIMIT)
    // Mappings from the CSV headers that JIL outputs and expects, to the localized callsign
    // It's a good idea to specify what strings JIL uses rather than rely on the English
    // localization text, to allow the UI to be less coupled to the backend implementation.
    .constant('CSV_HEADER_MAP', [
      // NOTE: This is still used in quick-assign-csv-form. Remove with temp_standalone_quick_assign.
      {
        jil: 'Admin Roles',
        localizationKey: 'widgets.bulkOperations.csvHeaders.adminRoles',
      },
      {
        jil: 'Auto Assigned Products',
        localizationKey: 'widgets.bulkOperations.csvHeaders.autoAssignedProducts',
      },
      {
        jil: 'Country Code',
        localizationKey: 'widgets.bulkOperations.csvHeaders.countryCode',
      },
      {
        jil: 'Domain',
        localizationKey: 'widgets.bulkOperations.csvHeaders.domain',
      },
      {
        jil: 'Developer Access',
        localizationKey: 'widgets.bulkOperations.csvHeaders.developerAccess',
      },
      {
        jil: 'Email',
        localizationKey: 'widgets.bulkOperations.csvHeaders.email',
      },
      {
        jil: 'First Name',
        localizationKey: 'widgets.bulkOperations.csvHeaders.firstName',
      },
      {
        jil: 'Id',
        localizationKey: 'widgets.bulkOperations.csvHeaders.id',
      },
      {
        jil: 'ID',
        localizationKey: 'widgets.bulkOperations.csvHeaders.id',
      },
      {
        jil: 'Identity Type',
        localizationKey: 'widgets.bulkOperations.csvHeaders.identityType',
      },
      {
        jil: 'New Country Code',
        localizationKey: 'widgets.bulkOperations.csvHeaders.newCountryCode',
      },
      {
        jil: 'New Email',
        localizationKey: 'widgets.bulkOperations.csvHeaders.newEmail',
      },
      {
        jil: 'New Identity Type',
        localizationKey: 'widgets.bulkOperations.csvHeaders.newIdentityType',
      },
      {
        jil: 'New Username',
        localizationKey: 'widgets.bulkOperations.csvHeaders.newUsername',
      },
      {
        jil: 'Last Name',
        localizationKey: 'widgets.bulkOperations.csvHeaders.lastName',
      },
      {
        jil: 'Product Configurations',
        localizationKey: 'widgets.bulkOperations.csvHeaders.productConfigurations',
      },
      {
        jil: 'Product Configurations Administered',
        localizationKey: 'widgets.bulkOperations.csvHeaders.productConfigurationsAdministered',
      },
      {
        jil: 'Product Role',
        localizationKey: 'widgets.bulkOperations.csvHeaders.productRole',
      },
      {
        jil: 'Products Administered',
        localizationKey: 'widgets.bulkOperations.csvHeaders.productsAdministered',
      },
      {
        jil: 'Team Products',
        localizationKey: 'widgets.bulkOperations.csvHeaders.teamProducts',
      },
      {
        jil: 'User Groups',
        localizationKey: 'widgets.bulkOperations.csvHeaders.userGroups',
      },
      {
        jil: 'User Groups Administered',
        localizationKey: 'widgets.bulkOperations.csvHeaders.userGroupsAdministered',
      },
      {
        jil: 'Username',
        localizationKey: 'widgets.bulkOperations.csvHeaders.username',
      },
    ])
    .constant('JOB_RESULT_FILTER', BULK_OPERATION_CONSTANTS.JOB_RESULT_FILTER);
})();
