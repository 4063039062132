import {Store, jilAuthenticationPolicies, log} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';
import {
  FINAL_MAX_SESSION_IDLE_TIME_OPTIONS,
  MAX_SESSION_LIFE_OPTIONS,
  NOT_SET,
  SOCIAL_PROVIDERS,
} from 'features/settings/components/auth-settings-page/AuthenticationPolicies.constants';
import AuthenticationPolicyEntity from 'features/settings/entities/AuthenticationPolicyEntity';

const AUTHENTICATION_POLICIES = {
  DENIED_CREDENTIAL_TYPES: 'DENIED_CREDENTIAL_TYPES',
  IS_MFA_REQUIRED: 'IS_MFA_REQUIRED',
  MAX_LEN_SINCE_LAST_CRED_ENTRY: 'MAX_LEN_SINCE_LAST_CRED_ENTRY',
  MAX_SESSION_IDLE_TIME: 'MAX_SESSION_IDLE_TIME',
};

export default class AuthenticationPoliciesStore extends Store {
  isLoadingMaxIdleTime = false;
  isLoadingMaxSession = false;
  isLoadingMFARequired = false;
  isLoadingSocialProviders = false;
  policies = [];

  constructor() {
    super();

    makeObservable(this, {
      allowSocialProvider: action,
      deniedSocialProviders: computed,
      denySocialProvider: action,
      isAppleAllowed: computed,
      isFacebookAllowed: computed,
      isGoogleAllowed: computed,
      isLoadingMaxIdleTime: observable,
      isLoadingMaxSession: observable,
      isLoadingMFARequired: observable,
      isLoadingSocialProviders: observable,
      maxReauthenticationTime: computed,
      maxSessionIdleTime: computed,
      policies: observable,
      removeAuthenticationPolicy: action,
      setAuthenticationPolicy: action,
      setIsLoading: action,
    });
  }

  async allowSocialProvider(socialProvider) {
    const newDeniedSocialProviders = this.deniedSocialProviders.value.filter(
      (provider) => provider !== socialProvider
    );

    // if at least one provider is still denied update the deniedProviders list
    if (newDeniedSocialProviders.length > 0) {
      await this.setAuthenticationPolicy(
        AUTHENTICATION_POLICIES.DENIED_CREDENTIAL_TYPES,
        newDeniedSocialProviders
      );
    } else {
      // remove the policy when all the providers are allowed
      await this.removeAuthenticationPolicy(AUTHENTICATION_POLICIES.DENIED_CREDENTIAL_TYPES);
    }
  }

  get deniedSocialProviders() {
    const emptySocialProvidersList = {
      value: [],
    };

    const deniedSocialProviders = this.policies.find(
      (policy) => policy.name === AUTHENTICATION_POLICIES.DENIED_CREDENTIAL_TYPES
    );

    return deniedSocialProviders || emptySocialProvidersList;
  }

  async denySocialProvider(socialProvider) {
    await this.setAuthenticationPolicy(AUTHENTICATION_POLICIES.DENIED_CREDENTIAL_TYPES, [
      ...this.deniedSocialProviders.value,
      socialProvider,
    ]);
  }

  async fetchData() {
    const response = await jilAuthenticationPolicies.getAuthenticationPolicies({
      orgId: rootStore.organizationStore.activeOrgId,
    });

    runInAction(() => {
      this.policies = response.data.policies.map(
        (policy) => new AuthenticationPolicyEntity(policy)
      );
    });
  }

  get isAppleAllowed() {
    return !this.deniedSocialProviders.value.includes(SOCIAL_PROVIDERS.APPLE);
  }

  get isFacebookAllowed() {
    return !this.deniedSocialProviders.value.includes(SOCIAL_PROVIDERS.FACEBOOK);
  }

  get isGoogleAllowed() {
    return !this.deniedSocialProviders.value.includes(SOCIAL_PROVIDERS.GOOGLE);
  }

  get isMFARequired() {
    return !!this.policies.find(
      (policy) => policy.name === AUTHENTICATION_POLICIES.IS_MFA_REQUIRED
    );
  }

  get maxReauthenticationTime() {
    const maxReauthenticationTimePolicy = this.policies.find(
      (policy) => policy.name === AUTHENTICATION_POLICIES.MAX_LEN_SINCE_LAST_CRED_ENTRY
    );

    if (maxReauthenticationTimePolicy) {
      const maxSessionLifeOption = MAX_SESSION_LIFE_OPTIONS.find(
        (option) => option.value === maxReauthenticationTimePolicy.value
      );

      // If values were manually set to anything other than UI options
      return maxSessionLifeOption || NOT_SET;
    }

    return NOT_SET;
  }

  get maxSessionIdleTime() {
    const maxSessionIdleTimePolicy = this.policies.find(
      (policy) => policy.name === AUTHENTICATION_POLICIES.MAX_SESSION_IDLE_TIME
    );

    if (maxSessionIdleTimePolicy) {
      const maxSessionIdleTimeOption = FINAL_MAX_SESSION_IDLE_TIME_OPTIONS.find(
        (option) => option.value === maxSessionIdleTimePolicy.value
      );

      // If values were manually set to anything other than UI options
      return maxSessionIdleTimeOption || NOT_SET;
    }

    return NOT_SET;
  }

  async removeAuthenticationPolicy(name) {
    const isPolicyAbsent = !this.policies.find((policy) => policy.name === name);

    if (isPolicyAbsent) {
      return;
    }

    this.setIsLoading(name, true);

    try {
      await jilAuthenticationPolicies.removeAuthenticationPolicy({
        name,
        orgId: rootStore.organizationStore.activeOrgId,
      });
      runInAction(() => {
        this.policies = this.policies.filter((policy) => policy.name !== name);
      });
    } catch (error) {
      log.error('[ID][PBA] Error deleting authentication policy: ', error);
      runInAction(() => {
        throw error;
      });
    } finally {
      runInAction(() => {
        this.setIsLoading(name, false);
      });
    }
  }

  async setAuthenticationPolicy(name, value) {
    this.setIsLoading(name, true);

    try {
      await jilAuthenticationPolicies.setAuthenticationPolicy({
        name,
        orgId: rootStore.organizationStore.activeOrgId,
        value,
      });
      runInAction(() => {
        const indexOfPolicy = this.policies.findIndex((policy) => policy.name === name);
        if (indexOfPolicy === -1) {
          this.policies.push(new AuthenticationPolicyEntity({name, value}));
        } else {
          this.policies[indexOfPolicy].value = value;
        }
      });
    } catch (error) {
      log.error('[ID][PBA] Error updating authentication policy: ', error);
      runInAction(() => {
        throw error;
      });
    } finally {
      runInAction(() => {
        this.setIsLoading(name, false);
      });
    }
  }

  setIsLoading(name, value) {
    switch (name) {
      case AUTHENTICATION_POLICIES.DENIED_CREDENTIAL_TYPES:
        this.isLoadingSocialProviders = value;
        break;
      case AUTHENTICATION_POLICIES.IS_MFA_REQUIRED:
        this.isLoadingMFARequired = value;
        break;
      case AUTHENTICATION_POLICIES.MAX_LEN_SINCE_LAST_CRED_ENTRY:
        this.isLoadingMaxSession = value;
        break;
      case AUTHENTICATION_POLICIES.MAX_SESSION_IDLE_TIME:
        this.isLoadingMaxIdleTime = value;
        break;

      default:
        break;
    }
  }
}

export {AUTHENTICATION_POLICIES};
