/**
 * @deprecated binky src2/core/models/user/roles/UserRolesConstants.js should be used instead
 */
const ROLE = {
  ADMIN: {
    COMPARTMENT_ADMIN: 'COMPARTMENT_ADMIN',
    COMPARTMENT_VIEWER: 'COMPARTMENT_VIEWER',
    CONTRACT: 'CONTRACT_ADMIN',
    DEPLOYMENT: 'DEPLOYMENT_ADMIN',
    LICENSE: 'LICENSE_ADMIN',
    LICENSE_DEV: 'LICENSE_DEV_ADMIN',
    ORG: 'ORG_ADMIN',
    PRODUCT: 'PRODUCT_ADMIN',
    PRODUCT_SUPPORT: 'PRODUCT_SUPPORT_ADMIN',
    STORAGE: 'STORAGE_ADMIN',
    SUPPORT: 'SUPPORT_ADMIN',
    SUPPORTS: 'SUPPORT_ADMINS',
    USER_GROUP: 'USER_GROUP_ADMIN',
  },
  AGENT: {
    ADOBE_AGENT_ADMIN: 'ADOBE_AGENT_ADMIN',
    ADOBE_AGENT_CUSTOMER_CARE: 'ADOBE_AGENT_CUSTOMER_CARE',
    ADOBE_AGENT_PROFESSIONAL_SERVICES: 'ADOBE_AGENT_PROFESSIONAL_SERVICES',
    ADOBE_AGENT_PROVISIONER: 'ADOBE_AGENT_PROVISIONER',
    ADOBE_AGENT_READ: 'ADOBE_AGENT_READ',
    ADOBE_AGENT_RESELLER_LICENSING: 'ADOBE_AGENT_RESELLER_LICENSING',
  },
  NONE: 'NONE',
};

const ROLE_KEY = {
  CONTRACT_ADMIN: 'contractAdmin',
};

const USER_DETAILS_MODAL_NAMES = {
  EDIT_ADMIN_RIGHTS: 'EDIT_ADMIN_RIGHTS',
  EDIT_API_CREDENTIALS: 'EDIT_API_CREDENTIALS',
  EDIT_DEVELOPER_PRODUCTS: 'EDIT_DEVELOPER_PRODUCTS',
  EDIT_PRODUCTS_AND_GROUPS: 'EDIT_PRODUCTS_AND_GROUPS',
  EDIT_USER_PROFILE: 'EDIT_USER_PROFILE',
};

const EDU_USER_TAGS = {
  EDU_STAFF: 'edu_staff',
  EDU_STUDENT: 'edu_student',
  EDU_TEACHER: 'edu_teacher',
};

export {EDU_USER_TAGS, USER_DETAILS_MODAL_NAMES, ROLE, ROLE_KEY};
