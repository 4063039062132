import {navBus} from '@admin-tribe/binky';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
  PATH_STORAGE_LIBRARIES,
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_REPORTS,
  PATH_STORAGE_REPORTS_SHARED,
  PATH_STORAGE_REPORTS_USERS,
  PATH_STORAGE_SHARED_PROJECTS,
} from 'features/storage/routing/storagePaths';

const goTo = (path) => {
  navBus.navigate(generatePath(path, {orgId: rootStore.organizationStore.activeOrgId}));
};

/**
 * @description Method to navigate to the Active Individual Folders page for the current organization
 */
const goToIndividualUserFolders = () => goTo(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS);

/**
 * @description Method to navigate to the Deleted Individual Folders page for the current organization
 */
const goToDeletedIndividualUserFolders = () => goTo(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED);

/**
 * @deprecated with temp_storage_shared_libraries
 * @description Method to navigate to the Libraries page for the current organization
 */
const goToLibraries = (locationState) => {
  const path = generatePath(PATH_STORAGE_LIBRARIES, {
    orgId: rootStore.organizationStore.activeOrgId,
  });
  navBus.navigate(path, locationState);
};

/**
 * @description Method to navigate to the Storage Reports page for the current organization
 */
const goToReports = () => goTo(PATH_STORAGE_REPORTS);

/**
 * @description Method to navigate to the Projects page for the current organization
 */
const goToSharedProjects = (locationState) => {
  const path = generatePath(PATH_STORAGE_SHARED_PROJECTS, {
    orgId: rootStore.organizationStore.activeOrgId,
  });
  navBus.navigate(path, locationState);
};

/**
 * @description Method to navigate to the Shared Reports page for the current organization
 */
const goToSharedReports = () => goTo(PATH_STORAGE_REPORTS_SHARED);

/**
 * @description Method to navigate to the Storage Overview page for the current organization
 */
const goToStorageOverview = () => goTo(PATH_STORAGE_OVERVIEW);

/**
 * @description Method to navigate to the User Reports page for the current organization
 */
const goToUserReports = () => goTo(PATH_STORAGE_REPORTS_USERS);

export {
  goToDeletedIndividualUserFolders,
  goToIndividualUserFolders,
  goToLibraries,
  goToReports,
  goToSharedProjects,
  goToSharedReports,
  goToStorageOverview,
  goToUserReports,
};
