import {ActionButton, Flex, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {TruncatedText} from '@pandora/react-truncated-text';
import Alert from '@spectrum-icons/workflow/Alert';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import CloseIcon from '@spectrum-icons/workflow/Close';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ActivationCard = observer(({children, onDismiss, file}) => {
  const intl = useIntl();

  const negativeAlertIcon = () => (
    <Alert size="S" UNSAFE_style={{fill: 'var(--spectrum-global-color-red-600)'}} />
  );

  return (
    <View
      backgroundColor="gray-50"
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      height="size-550"
      maxWidth="size-6000"
      width="100%"
    >
      <Flex alignItems="center" data-testid="text-card-wrapper" height="size-550">
        <View data-testid="left-icon" marginStart="size-150" marginTop="size-50">
          <AttachIcon size="S" />
        </View>
        <View flex={1} marginStart="size-125" overflow="hidden">
          <TruncatedText dataTestId="text" textContent={file.name} />
          {children}
        </View>
        {file.hasFileTypeError && (
          <TooltipButton
            data-testid="file-type-error"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.challengeCodes.fileUpload.invalidFileType',
            })}
            isQuiet
            marginStart="size-65"
            variant="action"
          >
            {negativeAlertIcon()}
          </TooltipButton>
        )}
        {file.hasFileSizeError && !file.hasFileTypeError && (
          <TooltipButton
            data-testid="file-size-error"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.challengeCodes.fileUpload.invalidFileSize',
            })}
            isQuiet
            marginStart="size-65"
            variant="action"
          >
            {negativeAlertIcon()}
          </TooltipButton>
        )}
        {file.hasFileParsingError && (
          <TooltipButton
            data-testid="file-parse-error"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.challengeCodes.fileUpload.fileParseFailed',
            })}
            isQuiet
            marginStart="size-65"
            variant="action"
          >
            {negativeAlertIcon()}
          </TooltipButton>
        )}
        {file.hasInvalidCodes && !file.hasFileParsingError && (
          <TooltipButton
            data-testid="invalid-code-error"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.challengeCodes.fileUpload.invalidCodesInFile',
            })}
            isQuiet
            marginStart="size-65"
            variant="action"
          >
            {negativeAlertIcon()}
          </TooltipButton>
        )}
        {file.isValid && file.codeCount > 0 && (
          <>
            <Checkmark color="positive" marginEnd="size-65" size="S" />
            <Text
              marginEnd="size-100"
              UNSAFE_style={{color: 'var(--spectrum-semantic-positive-color-icon)'}}
            >
              {intl.formatMessage(
                {id: 'packages.createPackage.challengeCodes.fileUpload.codeCount'},
                {count: file.codeCount}
              )}
            </Text>
          </>
        )}
        <ActionButton
          data-testid="right-button"
          isQuiet
          marginEnd="size-100"
          marginStart="auto"
          onPress={onDismiss}
        >
          <CloseIcon aria-label={intl.formatMessage({id: 'common.modal.buttons.close'})} size="S" />
        </ActionButton>
      </Flex>
    </View>
  );
});

ActivationCard.propTypes = {
  /**
   * children object
   */
  children: PropTypes.instanceOf(Object),
  /**
   * file object
   */
  file: PropTypes.instanceOf(Object),
  /**
   * Function to when activation card is dismissed
   */
  onDismiss: PropTypes.func.isRequired,
};

export default ActivationCard;
