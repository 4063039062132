import {showError, showSuccess} from '@admin-tribe/binky-ui';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {TableActions, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import JobList from 'common/services/job-list/JobList';
import {isReadOnly} from 'core/organizations/access/organizationAccess';

// Remove results and Clear all results button for bulk ops results table
const BulkOperationsTableActions = ({jobList, onRemovalConfirmed}) => {
  const intl = useIntl();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();

  const {clearSelectedItemKeys, getSelectableItemKeys, getSelectedItemCount} = tableSectionUtils;

  const [clearAllSelected, setClearAllSelected] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const [dialogTitleAndLabel, setDialogTitleAndLabel] = useState('');
  const [dialogContent, setDialogContent] = useState('');

  // can only perform job removal if there is a jobList
  const isRemoveResultsDisabled = !jobList || getSelectedItemCount() === 0;
  const isClearAllDisabled = !jobList || getSelectableItemKeys().length === 0;

  const removeResultsActionLabel = intl.formatMessage({
    id: 'bulkOperations.results.actions.remove',
  });
  const clearAllActionLabel = intl.formatMessage({id: 'bulkOperations.results.actions.clearAll'});

  const openDialog = ({isClearAll}) => {
    setClearAllSelected(isClearAll);

    setDialogTitleAndLabel(isClearAll ? clearAllActionLabel : removeResultsActionLabel);
    if (isClearAll) {
      setDialogContent(intl.formatMessage({id: 'bulkOperations.results.dialog.clearAll.message'}));
    } else {
      setDialogContent(
        intl.formatMessage(
          {id: 'bulkOperations.results.dialog.remove.message'},
          {jobCount: getSelectedItemCount()}
        )
      );
    }

    setIsDialogOpen(true);
  };

  const onConfirm = async () => {
    try {
      setIsInProgress(true);

      let jobsToRemove;
      if (clearAllSelected) {
        jobsToRemove = getSelectableItemKeys();
      } else {
        jobsToRemove = tableSectionState.selectedItemKeys;
      }
      await JobList.deleteJobs(jobsToRemove);

      // after removal, the selected item keys persist even when not on the table
      // clear all after deleting job was successful
      clearSelectedItemKeys();
      showSuccess();

      onRemovalConfirmed?.();
    } catch {
      showError();
    } finally {
      setIsInProgress(false);
      setIsDialogOpen(false);
    }
  };

  return (
    !isReadOnly() && (
      <TableActions>
        <Button
          isDisabled={isRemoveResultsDisabled}
          onPress={() => openDialog({isClearAll: false})}
          variant="negative"
        >
          {removeResultsActionLabel}
        </Button>
        <Button
          isDisabled={isClearAllDisabled}
          onPress={() => openDialog({isClearAll: true})}
          variant="negative"
        >
          {clearAllActionLabel}
        </Button>
        <DialogContainer>
          {isDialogOpen && (
            <ModalAlertDialog
              cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
              isLoading={isInProgress}
              onCancel={() => setIsDialogOpen(false)}
              onPrimaryAction={onConfirm}
              primaryActionLabel={dialogTitleAndLabel}
              title={dialogTitleAndLabel}
              variant="destructive"
            >
              {dialogContent}
            </ModalAlertDialog>
          )}
        </DialogContainer>
      </TableActions>
    )
  );
};

BulkOperationsTableActions.displayName = 'BulkOperationsTableActions';

BulkOperationsTableActions.propTypes = {
  /**
   * The JobList class that manages the list of jobs. When undefined, disables all buttons.
   */
  jobList: PropTypes.instanceOf(JobList),
  /**
   * Callback to signal when job removal is in progress.
   */
  onRemovalConfirmed: PropTypes.func,
};

export default BulkOperationsTableActions;
