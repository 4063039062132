import binky from '@admin-tribe/binky';
import axios from 'axios';

const {authentication} = binky.services;
const {getUrlSearchParams} = binky.utils.urlUtils;

const REQUEST_TIMEOUT = 5000;

let clientId, url;

/**
 * Calls MILO api to create a Customer Note on Support Ticket
 *
 * @param {Object} options - options object
 * @param {Object} [options.comment]  - JSON Object for CustomerNote that needs to be added to Support Ticket
 * @returns {Promise} - a promise which will resolve with the response data
 */
const addCustomerNote = ({comment}) =>
  axios.post(`${url}/casemgt/c14e/activities/customernote/v1`, comment, {
    headers: buildHeaders(),
  });

/**
 * Configure MILO APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.clientId - The identifier for the consuming application
 * @param {String} config.url - The root url for Milo api
 */
const configure = (config) => {
  ({clientId, url} = config);
};

/**
 *
 * @param {String} recordType - Record type of support ticket (e.g. case, expert)
 * @param {object} supportTicket - JSON object of support ticket
 * @returns a promise which will resolve with the response data
 */
const createTicket = ({recordType, supportTicket}) =>
  axios.post(`${url}/casemgt/c14e/${recordType}/v1`, supportTicket, {
    headers: buildHeaders(),
  });

/**
 * Calls MILO APIs to download a attachment.
 *
 * @param {String} attachmentId - unique identifier of the attachment
 * @param {String} id - CaseID, e.g. E-12569539
 * @param {String} orgId - Id of the org the regarding object belongs to
 * @param {String} regarding - Name of the CRM entity
 * @return {Promise} a promise which will resolve with the response data
 */
const downloadAttachment = ({attachmentId, id, orgId, regarding}) =>
  axios.get(`${url}/attachments/c14e/file/${attachmentId}/v1`, {
    headers: buildHeaders(),
    params: getUrlSearchParams({id, orgId, regarding}),
    responseType: 'blob',
  });

/**
 * Calls MILO APIs to retrieve External Notes and optionally latest NonSystemEmail
 *
 * @param {String} id - CaseID, e.g. E-12569539
 * @param {String} orgId - Id of the org the regarding object belongs to
 * @param {String} regarding - Name of the CRM entity
 * @param {Boolean} [includeNonSystemEmail] - Boolean value to include latest NonSystemEmail
 * @return {Promise} a promise which will resolve with the response data
 */
const getActivities = ({id, orgId, regarding, includeNonSystemEmail = false}) => {
  const params = getUrlSearchParams({
    activityType: includeNonSystemEmail
      ? 'customernote,email(filter=nonSystem:limit=1)'
      : 'customernote',
    id,
    orgId,
    regarding,
  });
  return axios.get(`${url}/casemgt/c14e/activities/v1`, {
    headers: buildHeaders(),
    params,
  });
};

/**
 * Calls MILO APIs to retrieve a list of attachments.
 *
 * @param {String} id - CaseID, e.g. E-12569539
 * @param {Array} of - Type of attachments being requested,
 * if "of" param is not passed by default, API will return the attachments of regarding param.
 * @param {String} orgId - Id of the org the regarding object belongs to
 * @param {String} regarding - Name of the CRM entity
 * @return {Promise} a promise which will resolve with the response data
 */
const getAttachments = ({id, of, orgId, regarding}) =>
  axios.get(`${url}/attachments/c14e/files/v1`, {
    headers: buildHeaders(),
    params: getUrlSearchParams({id, of, orgId, regarding}),
    timeout: REQUEST_TIMEOUT,
  });

/**
 * Calls MILO APIs to get expert session matadata.
 *
 * @param {String} locale - Locale, e.g. en_US
 * @param {String} orgId - Org Id of the api caller
 * @param {String} productCode - code for the product that was searched
 * @param {Integer} typeCode - type of case i.e 1 for ADMIN and 2 for PRODUCT
 * @return {Promise} a promise which will resolve with the response data
 */
const getExpertSessionMetadata = ({locale, orgId, productCode, typeCode}) =>
  axios.get(`${url}/metadata/c14e/expertsessions/v1`, {
    headers: buildHeaders(),
    params: getUrlSearchParams({locale, orgId, productCode, typeCode}),
  });

/**
 * Calls MILO APIs to retrieve product metadata.
 *
 * @param {String} fulfillableItemCode - fulfillable item code for a product
 * @param {String} orgId - Id of the org the regarding object belongs to
 * @return {Promise} a promise which will resolve with the response data
 */
const getProductMetadata = ({fulfillableItemCode, orgId}) =>
  axios.get(`${url}/metadata/c14e/product/${fulfillableItemCode}/v1`, {
    headers: buildHeaders(),
    params: getUrlSearchParams({orgId}),
  });

/**
 * Calls MILO API to retrieve a Support Ticket based on CaseId
 *
 * @param {String} caseId - Case Identifier
 * @param {String} orgId - Id of the organization for which a case to be retrieved
 * @param {Object} params - Query Parameter object
 * @param {String} recordType - Record Type of the case to be retrieved, e.g. 'case' or 'expert'
 *
 * @returns {Promise} a promise which will resolve with the response data
 */
const getTicket = ({caseId, orgId, params, recordType}) =>
  axios.get(`${url}/casemgt/c14e/${recordType}/v1`, {
    headers: buildHeaders(),
    params: getUrlSearchParams({...params, caseId, orgId}),
  });

/**
 * Calls MILO API to retrieve case list.
 * @param {String} orgId  - Id of the organization for which cases to be retrieved
 * @param {Object} params - Query Parameter object
 * @param {String} recordType - Record Type of cases to be retrieved
 * @param {Object} options - Options for additional header, e.g. options.isCsv
 * @returns {Promise} a promise which will resolve with the response data
 */
const getTicketList = ({orgId, params, recordType, options}) =>
  axios.get(`${url}/casemgt/c14e/${recordType}/v1`, {
    headers: buildHeaders(options),
    params: getUrlSearchParams({...params, orgId}),
  });

/**
 * Calls MILO API to perform actions (Close, Escalate, Reopen) on Support Ticket
 * @param {Object} option.action - JSON object for performing action on Support Ticket
 * @returns {Promise} a promise which will resolve with the response data
 */
const performTicketAction = ({action}) =>
  axios.post(`${url}/casemgt/c14e/case/action/v1`, action, {
    headers: buildHeaders(),
  });

/**
 * Calls MILO APIs to upload an attachment.
 *
 * @param {Blob} file - blob object which needs to be uploaded
 * @param {String} id - CaseID, e.g. E-12569539
 * @param {String} orgId - Id of the org the regarding object belongs to
 * @param {String} regarding - Name of the CRM entity. e.g. incident
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const uploadAttachment = ({file, id, orgId, regarding}) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${url}/attachments/c14e/file/v1`, formData, {
    headers: buildHeaders(),
    params: getUrlSearchParams({id, orgId, regarding}),
  });
};

/**
 * Calls MILO API to update Support Case or Expert Session
 *
 * @param {String} recordType - type of ticket, possible values are case or expert
 * @param {Object} supportTicket - ticket object which needs to be updated
 *
 * @returns {Promise} a promise which will resolve with the response data
 */
const updateTicket = ({recordType, supportTicket}) =>
  axios.put(`${url}/casemgt/c14e/${recordType}/v1`, supportTicket, {
    headers: buildHeaders(),
  });
// Helpers

function buildHeaders(options) {
  const headers = {
    'X-Api-Key': clientId,
  };
  const token = authentication.getAccessToken();
  if (token?.token) {
    headers.Authorization = `Bearer ${token.token}`;
  }
  if (options?.isCsv) {
    headers.Accept = 'text/csv';
  }
  return headers;
}

const milo = {
  addCustomerNote,
  configure,
  createTicket,
  downloadAttachment,
  getActivities,
  getAttachments,
  getExpertSessionMetadata,
  getProductMetadata,
  getTicket,
  getTicketList,
  performTicketAction,
  updateTicket,
  uploadAttachment,
};

export default milo;
