(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:sophiaToLaunchTranslator
   * @description Translate SophiaDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('sophiaToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, sophiaDescriptor) {
      const {setProperty} = launchContext;
      setProperty('sophiaResponse', {
        fromPage: _.get(sophiaDescriptor, 'responses'),
      });
    }
  }
})();
