import {log} from '@admin-tribe/binky';
import {Button, ButtonGroup, View} from '@adobe/react-spectrum';
import {useSupportTicketHook} from '@pandora/data-model-support-ticket';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  SupportTicketAttachmentsButtonFormSection,
  SupportTicketAttachmentsButtonFormSectionContentModel,
  SupportTicketCommentFormSection,
  SupportTicketCommentFormSectionContentModel,
} from '@pandora/react-support-ticket';
import {error, success} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * A widget to display Add Comments and Upload Attachments
 */

const SupportTicketAddDiscussionItemFormSection = ({
  'data-testid': dataTestId,
  discussionItemsLength,
  isClosed,
  refresh,
  ticketId,
}) => {
  const [isAddAttachmentViewOpen, setIsAddAttachmentViewOpen] = useState(false);
  const [isAddAttachmentLoading, setIsAddAttachmentLoading] = useState(false);
  const [isAddCommentViewOpen, setIsAddCommentViewOpen] = useState(false);
  const [isAddCommentLoading, setIsAddCommentLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [attachments, setAttachments] = useState([]);
  const intl = useIntl();

  const commentContent = useContentEntry(SupportTicketCommentFormSectionContentModel);
  const attachmentContent = useContentEntry(SupportTicketAttachmentsButtonFormSectionContentModel);

  const {addAttachments, addComment} = useSupportTicketHook();
  const orgId = rootStore.organizationStore.activeOrgId;

  const onAddAttachments = async () => {
    let errorAttachments = [];
    try {
      setIsAddAttachmentLoading(true);
      await addAttachments({
        attachments,
        id: ticketId,
        onAttachmentError: (file) => {
          errorAttachments = [...errorAttachments, file];
          error(
            intl.formatMessage(
              {
                id: 'support.supportTicketAddDiscussionItemFormSection.add.attachment.error',
              },
              {fileName: file.name}
            ),
            {
              timeout: 0,
            }
          );
        },
        onAttachmentSuccess: ({name: fileName}) => {
          success(
            intl.formatMessage(
              {
                id: 'support.supportTicketAddDiscussionItemFormSection.add.attachment.success',
              },
              {fileName}
            )
          );
        },
        orgId,
      });
    } catch (attachmentError) {
      log.error(`Failed to add attachment for ${ticketId}. Error: `, attachmentError);
    } finally {
      refresh();
      setAttachments(errorAttachments);
      setIsAddAttachmentViewOpen(errorAttachments.length > 0);
      setIsAddAttachmentLoading(false);
    }
  };

  const onAddComment = async () => {
    try {
      setIsAddCommentLoading(true);
      await addComment({comment, id: ticketId, orgId});
      success(
        intl.formatMessage({
          id: 'support.supportTicketAddDiscussionItemFormSection.add.comment.success',
        })
      );
      refresh();
      setIsAddCommentViewOpen(false);
      setComment('');
    } catch (commentError) {
      log.error(`Failed to add comment for ${ticketId}. Error: `, commentError);
      error(
        intl.formatMessage({
          id: 'support.supportTicketAddDiscussionItemFormSection.add.comment.error',
        }),
        {
          timeout: 0,
        }
      );
    } finally {
      setIsAddCommentLoading(false);
    }
  };

  const isNoDiscussionViewEnabled = () =>
    !isAddAttachmentViewOpen && !isAddCommentViewOpen && !isClosed && discussionItemsLength === 0;

  return (
    <View data-testid={dataTestId || 'support-ticket-add-discussion-section'}>
      <ButtonGroup align="start">
        <Button
          data-testid="add-comment-button"
          isDisabled={isAddCommentViewOpen || isClosed}
          onPress={() => {
            setIsAddAttachmentViewOpen(false);
            setIsAddCommentViewOpen(true);
          }}
          variant="accent"
        >
          <FormattedMessage id="support.supportTicketAddDiscussionItem.buttons.add.comment" />
        </Button>
        <Button
          data-testid="add-attachment-button"
          isDisabled={isAddAttachmentViewOpen || isClosed}
          onPress={() => {
            setIsAddAttachmentViewOpen(true);
            setIsAddCommentViewOpen(false);
          }}
          variant="secondary"
        >
          <FormattedMessage id="support.supportTicketAddDiscussionItem.buttons.add.attachment" />
        </Button>
      </ButtonGroup>
      {isAddAttachmentViewOpen && (
        <SupportTicketAttachmentsButtonFormSection
          content={attachmentContent}
          isLoading={isAddAttachmentLoading}
          onAttach={onAddAttachments}
          onCancel={() => {
            setAttachments([]);
            setIsAddAttachmentViewOpen(false);
          }}
          onUpdateFiles={setAttachments}
          uploadedFiles={attachments}
        />
      )}
      {isAddCommentViewOpen && (
        <SupportTicketCommentFormSection
          comment={comment}
          content={commentContent}
          isLoading={isAddCommentLoading}
          maxLength={5000}
          onCancel={() => {
            setComment('');
            setIsAddCommentViewOpen(false);
          }}
          onInputChange={setComment}
          onSubmit={onAddComment}
        />
      )}
      {isNoDiscussionViewEnabled() && (
        <p data-testid="no-discussion-items">
          <FormattedMessage id="support.supportTicketAddDiscussionItem.noDiscussionItemsMessage" />
        </p>
      )}
    </View>
  );
};

SupportTicketAddDiscussionItemFormSection.propTypes = {
  /**
   * data-testid is used as a identifier between angular and react components.
   * The default is 'support-ticket-add-discussion-section'.
   */
  'data-testid': PropTypes.string,
  /**
   * stringKey is used to define the label of component.
   */
  discussionItemsLength: PropTypes.number.isRequired,
  isClosed: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  ticketId: PropTypes.string.isRequired,
};

export default SupportTicketAddDiscussionItemFormSection;
