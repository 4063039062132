import {StatusLight} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './DnsImportDomainsModal.pcss';

const ImportedDomainStatus = ({domain}) => {
  const variant = domain.isAvailable ? 'positive' : 'negative';
  const statusTextId = domain.isAvailable
    ? 'settings.directoryDomains.importedDomainStatus.available'
    : 'settings.directoryDomains.importedDomainStatus.notAvailable';

  return (
    <StatusLight
      // Needed UNSAFE_className because the padding of the
      // StatusLight makes the table row's height grow
      // and it looks weird. Padding is not something
      // we can change with layout props.
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to fix alignment
      UNSAFE_className={styles['domain-status-light']}
      variant={variant}
    >
      <FormattedMessage id={statusTextId} />
    </StatusLight>
  );
};

ImportedDomainStatus.propTypes = {
  domain: PropTypes.shape({
    isAvailable: PropTypes.bool,
  }),
};

export default ImportedDomainStatus;
