import {DIRECTORY_STATUS, OrganizationEncryptionMigration} from '@admin-tribe/binky';
import {Heading, useDialogContainer} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link, generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import DirectoryNameSection from 'features/settings/components/directory/directory-settings-modal/directory-name-section/DirectoryNameSection';
import EncryptionKeySection from 'features/settings/components/directory/directory-settings-modal/encryption-key-section/EncryptionKeySection';
import TeacherAccessPolicySection from 'features/settings/components/directory/directory-settings-modal/teacher-access-policy-section/TeacherAccessPolicySection';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';
import {PATH_SUPPORT} from 'features/support/routing/supportPaths';

const MODAL_ID = 'directory-settings-modal';

const DirectorySettingsModal = () => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {directory} = useDirectoryDetailsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [canViewEncryptionKeySection, setCanViewEncryptionKeySection] = useState(false);
  const canViewTeacherAccessPolicySection =
    rootStore.organizationStore.isActiveOrgEdu &&
    rootStore.organizationStore.activeOrg.countryCode === 'US';

  // check org encryption status on initial load to determine whether directory encryption key settings are available.
  // this needs to be removed with temp_org_asset_encryption_migrated
  useEffect(
    () => {
      const loadOrgEncryptionStatus = async () => {
        setIsLoading(true);

        const response = await OrganizationEncryptionMigration.get({
          orgId: rootStore.organizationStore.activeOrgId,
        }); // this never throws -- an error is treated as "not migrated"

        setCanViewEncryptionKeySection(
          [DIRECTORY_STATUS.ACTIVE, DIRECTORY_STATUS.NEEDS_DOMAIN].includes(directory.status) &&
            !response.isMigrated
        );

        setIsLoading(false);
      };

      loadOrgEncryptionStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
    []
  );

  const supportPath = generatePath(PATH_SUPPORT, {orgId: rootStore.organizationStore.activeOrgId});

  return (
    <ModalDialog id={MODAL_ID} isLoading={isLoading}>
      <ModalHeading>
        <FormattedMessage id="settings.directorySettingsModal.title" />
      </ModalHeading>

      <ModalContent>
        <DirectoryNameSection setIsLoading={setIsLoading} />

        <Heading>
          <FormattedMessage id="settings.directorySettingsModal.moveDirectoryToOtherOrgSection.heading" />
        </Heading>
        <FormattedMessage
          id="settings.directorySettingsModal.moveDirectoryToOtherOrgSection.description"
          values={{
            link: (text) => <Link to={supportPath}>{text}</Link>,
          }}
        />

        {canViewEncryptionKeySection && <EncryptionKeySection setIsLoading={setIsLoading} />}

        {canViewTeacherAccessPolicySection && (
          <TeacherAccessPolicySection setIsLoading={setIsLoading} />
        )}
      </ModalContent>

      <ModalButtonGroup
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
        onCancel={() => dialog.dismiss()}
      />
    </ModalDialog>
  );
};

export default DirectorySettingsModal;
