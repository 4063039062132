import FederatedDomainEntity from 'common/entities/FederatedDomainEntity';

class FederatedDomainListModel {
  static fromObject(data) {
    return new FederatedDomainListModel(data);
  }

  constructor(federatedDomainListData) {
    if (federatedDomainListData) {
      this.items = federatedDomainListData.items.map((domain) => new FederatedDomainEntity(domain));

      this.numberOfDomains = federatedDomainListData.pageMetadata.size;
    } else {
      // for the initial state federatedDomainListData is null
      this.items = [];

      this.numberOfDomains = 0;
    }
  }
}

export default FederatedDomainListModel;
