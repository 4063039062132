import {DIRECTORY_TYPE, feature} from '@admin-tribe/binky';
import {OverlayWait, SyncIconActionButton, TableSectionTable} from '@admin-tribe/binky-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {DirectoryDomainEnforcementStatus} from '@pandora/react-directory-domain-enforcement-status';
import {EnDashDefaultContent, SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import DirectoryType from 'features/settings/common/components/directory-type/DirectoryType';
import DomainListTableActions from 'features/settings/common/components/domain-list-table/domain-list-table-actions/DomainListTableActions';
import DomainListTableRowActions from 'features/settings/common/components/domain-list-table/domain-list-table-row-actions/DomainListTableRowActions';
import useDomainsTable from 'features/settings/common/components/domain-list-table/hooks/useDomainsTable';
import TrustedIconActionButton from 'features/settings/common/components/domain-list-table/trusted-icon-action-button/TrustedIconActionButton';
import DomainStatus from 'features/settings/common/components/domain-status/DomainStatus';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';

import styles from './DomainListTable.pcss';

const DomainListTable = () => {
  const intl = useIntl();
  const {domainList, loadDomainList, tableOptions, reloadDomainList} = useDomainsListContext();
  const {onTableSectionChange} = useDomainsTable();
  const {clearDirectoryCache} = useDirectoryList();

  const columnDescriptor = [
    {key: 'domainName', props: {allowsSorting: true}},
    {key: 'actions', props: {hideHeader: true, showDivider: true}},
    {key: 'directory'},
    {key: 'directoryType'},
    {key: 'status'},
  ];

  const isItemSelectable = (itemKey) => {
    const foundDomain = domainList.domainListData?.items.find(
      (domain) => domain.domainName === itemKey
    );

    return !foundDomain?.isTrusted;
  };

  const reloadDomainsAndClearDirectoryCache = () => {
    // we want to keep the current tableOptions
    reloadDomainList(tableOptions, {updateCount: true});

    // clear the directory cache as some directories might have a change of status
    clearDirectoryCache();
  };

  let renderers = {
    actions: ({item}) => (
      <DomainListTableRowActions
        domain={item}
        onDomainsLinked={reloadDomainsAndClearDirectoryCache}
        onDomainsTransferred={reloadDomainsAndClearDirectoryCache}
        onDomainsValidated={reloadDomainsAndClearDirectoryCache}
      />
    ),
    directory: ({item}) => (
      <EnDashDefaultContent>
        {item.directoryName && <Text>{item.directoryName}</Text>}
      </EnDashDefaultContent>
    ),
    directoryType: ({item}) => (
      <EnDashDefaultContent>
        {item.directoryType && (
          <DirectoryType directoryType={item.directoryType} marginBottom={0} marginTop={0} />
        )}
      </EnDashDefaultContent>
    ),
    domainName: ({item}) => (
      <Flex alignItems="center">
        <Text>{item.domainName}</Text>
        {item.isSynced && (
          <SyncIconActionButton
            tooltipText={intl.formatMessage({id: 'settings.domainsTable.externallyManagedLabel'})}
          />
        )}
        {item.isTrusted && <TrustedIconActionButton />}
      </Flex>
    ),
    status: ({item}) => <DomainStatus status={item.status} />,
  };

  if (feature.isEnabled('temp_domain_enforcement')) {
    columnDescriptor.splice(2, 0, {key: 'domainEnforcement', props: {minWidth: '20%'}});
    renderers = {
      ...renderers,
      domainEnforcement: ({item}) => {
        const hasDirectoryDE =
          (item.directoryType === DIRECTORY_TYPE.TYPE3 ||
            item.directoryType === DIRECTORY_TYPE.TYPE2) &&
          typeof item.domainEnforcement !== 'undefined' &&
          item.domainEnforcement !== null;

        return hasDirectoryDE ? (
          <DirectoryDomainEnforcementStatus
            // stateEndsAt is when the notify state ends and domainEnforcement is enabled
            enablementDate={item?.domainEnforcement?.stateEndsAt}
            state={item?.domainEnforcement?.state}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- remove formatting
            UNSAFE_className={styles['domain-enforcement-status']}
          />
        ) : (
          <View marginStart="size-400">
            <EnDashDefaultContent />
          </View>
        );
      },
    };
  }

  // get the list of domains
  useEffect(() => {
    loadDomainList({}, {updateCount: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- will run only once during the initial render
  }, []);

  return (
    <OverlayWait isLoading={domainList.isLoading} showContent>
      <TableSection
        getIsItemSelectable={isItemSelectable}
        getItemKey={
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing this
          // istanbul ignore next -- not testing the internal getItemKey
          (item) => item.domainName
        }
        id="domain-list-table"
        isServerError={domainList.hasLoadingError}
        items={domainList.domainListData?.items.map((item) => {
          item.key = item.domainName;
          return item;
        })}
        onTableSectionChange={onTableSectionChange}
        pageNumber={tableOptions.page}
        pageSizeOptions={tableOptions.pageSizeOptions}
        selectionMode={SELECTION_MODE.MULTIPLE}
        totalPages={tableOptions.totalPages}
      >
        <DomainListTableActions
          onDomainsAdded={reloadDomainsAndClearDirectoryCache}
          onDomainsLinked={reloadDomainsAndClearDirectoryCache}
          onDomainsRemoved={reloadDomainsAndClearDirectoryCache}
          onDomainsTransferred={reloadDomainsAndClearDirectoryCache}
          onDomainsValidated={reloadDomainsAndClearDirectoryCache}
          onTableSectionChange={onTableSectionChange}
        />
        <TableSectionTable
          aria-label={intl.formatMessage({id: 'settings.domainsTable.ariaLabel'})}
          columnDescriptor={columnDescriptor}
          columnNamespace="settings.domainsTable"
          noItemsFoundContentMessage={intl.formatMessage({
            id: 'settings.domainsTable.noItemsFoundContentMessage',
          })}
          noSearchResultsContentMessage={intl.formatMessage({
            id: 'settings.domainsTable.noSearchResultsContentMessage',
          })}
          renderers={renderers}
        />
      </TableSection>
    </OverlayWait>
  );
};

export default DomainListTable;
