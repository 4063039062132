(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appPromoCatalogItem
   * @description A component to display an icon in image, heading, one-line description
   *              with button click callback.
   *
   * @param {Offer}     offer The offer to display.
   * @param {Function}  onButtonClick(offer_id) The callback with an offer_id prameter.
   *                                            The callback will recieve the offer id as a parameter once called.
   *
   * @example
   *       <app-promo-catalog-item
   *         offer="$ctrl.offer"
   *         on-button-click="$ctrl.onButtonClick(offer_id)">
   *       </app-promo-catalog-item>
   */

  angular.module('app.widgets.products.purchase').component('appPromoCatalogItem', {
    bindings: {
      offer: '<',
      onButtonClick: '&',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/catalog/promo-item/promo-catalog-item.component.html',
    transclude: {
      'promo-catalog-third-line': '?promoCatalogThirdLine',
    },
  });

  /* @ngInject */
  function controller(_) {
    const defaultBackgroundImage =
      'https://helpx.adobe.com/content/dam/help/en/ccx/one-console/purchase/low_res/2020_cc_sq_crop-1x.jpg';

    const vm = this;
    _.assign(vm, {
      $onInit,
      defaultBackgroundImage,
    });

    function $onInit() {
      // IE11 does not support srcset. The fallback src image is loaded.
      const lowRes = _.get(
        vm.offer,
        'merchandising.assets.background_images.80x80',
        defaultBackgroundImage
      );
      const highRes = _.get(vm.offer, 'merchandising.assets.background_images.160x160', lowRes);

      vm.imgSrc = lowRes;
      vm.imgSrcset = `${lowRes} 1x, ${highRes} 2x`;
    }
  }
})();
