(function () {
  const componentName = 'appBulkOperationForm';
  /**
   * @deprecated. See src2 BulkOperationForm.jsx
   * Remove this file and whole module temp_standalone_quick_assign
   * since this form component is used within quick assign modal2
   */
  angular.module('app.widgets.bulk-operations.form').component(componentName, {
    bindings: {
      displayMessage: '<?',
      files: '<',
      modalId: '<',
      mode: '<',
      pageContext: '<',
    },
    controller,
    templateUrl: 'app/widgets/bulk-operations/form/bulk-operation-form.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $document,
    $element,
    $filter,
    $log,
    $scope,
    $translate,
    bulkOperationContext,
    bulkOperationLocalization,
    BULK_OPERATION_ROW_LIMIT,
    DisplayMessage,
    downloadUtils,
    TOAST_TYPES,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onDestroy,
      $onInit,
      BULK_OPERATION_ROW_LIMIT,
    });

    /////////////

    function $onInit() {
      _.assign(vm, {
        downloadingUserGroupsEnabled: true,
        downloadingUsersEnabled: true,
        downloadSample,
        downloadUserGroups,
        downloadUsers,
        removeEventListeners,
        removeFile,
        translatedSize,
      });

      vm.downloadCSVLabel = $translate.instant('widgets.bulkOperations.downloadCSV2');
      vm.dragAndDropLargeText = $translate.instant('widgets.bulkOperations.dragAndDropLarge2');
      vm.dragAndDropSmallText = $translate.instant('widgets.bulkOperations.dragAndDropSmall2');
      vm.selectFileText = $translate.instant('widgets.bulkOperations.select2');

      addEventListeners();
    }

    function addEventListeners() {
      if (!vm.formElement) {
        vm.formElement = $element.find('form');
      }
      registerFileUploadDragDropHandlers(vm.formElement);

      if (!vm.modalElement) {
        vm.modalElement = angular.element($document[0].getElementById('bulk-operation-modal2'));
      }
      registerFileUploadDragDropHandlers(vm.modalElement);

      if (!vm.fileInputElement) {
        vm.fileInputElement = $element.find('input');
      }
      // See https://github.com/angular/angular.js/issues/1375 for the long
      // discussion about why 1.5 doesn't support change for file inputs in Angular
      vm.fileInputElement.on('change', (evt) => {
        vm.displayMessage = undefined;
        addFiles(evt.target.files);
        // we reset the file input to ensure nothing is left behind after setting
        evt.target.value = null;
        $scope.$apply();
      });
    }

    function addFiles(fileList) {
      for (let i = 0; i < fileList.length; i++) {
        // we push all selected files to the array, filtering those already picked
        if (
          !_.find(vm.files, {
            lastModified: fileList[i].lastModified,
            name: fileList[i].name,
          })
        ) {
          vm.files.push(fileList[i]);
        }
      }
    }

    function downloadSample() {
      if (_.get(vm, 'pageContext.targetType') === 'USER') {
        bulkOperationLocalization
          .localizeCsvFile(vm.sampleContent)
          .then((localizedBlob) => {
            downloadUtils.download(localizedBlob, 'sample.csv');
          })
          .catch((error) => {
            $log.error(`Error downloading localized CSV sample`, error);
            showPageBanner($translate.instant('widgets.bulkOperations.download.unableToDownload'));
          });
      } else {
        downloadUtils.download(vm.sampleContent, 'sample.csv');
      }
    }

    function downloadUserGroups() {
      vm.downloadingUserGroupsEnabled = false;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      vm.exportResource[vm.exportFunction]({}, onSuccess.bind(this), onError.bind(this));

      function onSuccess(response) {
        vm.downloadingUserGroupsEnabled = true;
        downloadUtils.download(response.file, 'user-groups.csv');
      }

      function onError() {
        vm.downloadingUserGroupsEnabled = true;
        showPageBanner($translate.instant('widgets.bulkOperations.download.unableToDownload'));
      }
    }

    function downloadUsers(domainName) {
      vm.downloadingUsersEnabled = false;
      const downloadFilename = domainName ? `${domainName}-users.csv` : 'users.csv';
      const uploadParams = domainName
        ? _.assignIn({}, vm.uploadParams, {
            emailDomain: domainName,
          })
        : vm.exportParams;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      vm.exportResource[vm.exportFunction](
        uploadParams || {},
        onSuccess.bind(this),
        onError.bind(this)
      );

      function onSuccess(response) {
        if (_.get(vm, 'pageContext.targetType') === 'USER') {
          bulkOperationLocalization
            .localizeCsvFile(response.file)
            .then((localizedBlob) => {
              downloadUtils.download(localizedBlob, downloadFilename);
            })
            .catch((error) => {
              $log.error(`Error downloading localized CSV file`, error);
              showPageBanner(
                $translate.instant('widgets.bulkOperations.download.unableToDownload')
              );
            })
            .finally(() => {
              vm.downloadingUsersEnabled = true;
            });
        } else {
          vm.downloadingUsersEnabled = true;
          downloadUtils.download(response.file, downloadFilename);
        }
      }

      function onError() {
        vm.downloadingUsersEnabled = true;
        showPageBanner($translate.instant('widgets.bulkOperations.download.unableToDownload'));
      }
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function removeEventListeners() {
      if (vm.fileInputElement) {
        vm.fileInputElement.off();
      }
      if (vm.formElement) {
        vm.formElement.off();
      }
      if (vm.modalElement) {
        vm.modalElement.off();
      }
    }

    function registerFileUploadDragDropHandlers(elem) {
      elem
        .on('drag dragstart dragend dragover dragenter dragleave drop', (evt) => {
          evt.preventDefault();
          evt.stopPropagation();
        })
        .on('dragover dragenter', () => {
          vm.formElement.addClass('drag-over');
          $scope.$apply();
        })
        .on('dragleave dragend drop', () => {
          vm.formElement.removeClass('drag-over');
          $scope.$apply();
        })
        .on('drop', (evt) => {
          hidePageBanner();
          addFiles(evt.dataTransfer.files);
          $scope.$apply();
        });
    }

    function removeFile(file) {
      hidePageBanner();
      _.pull(vm.files, file);
    }

    function translatedSize(bytes) {
      let i = 0;
      let size = 0;
      if (bytes > 0) {
        i = Math.floor(Math.log(bytes) / Math.log(1000));
        size = parseFloat((bytes / Math.pow(1000, i)).toFixed(2));
      }
      const sizeKeys = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
      return $translate.instant(`widgets.bulkOperations.size.${sizeKeys[i]}`, {
        size: $filter('number')(size),
      });
    }

    function showPageBanner(message, header) {
      vm.displayMessage = new DisplayMessage({
        body: message,
        header,
      });
    }

    function assignData() {
      _.assign(
        vm,
        _.pick(vm.bulkContext, [
          'exportFunction',
          'exportParams',
          'exportResource',
          'modalDescriptionKey',
          'uploadFunction',
          'uploadParams',
          'uploadResource',
        ])
      );

      vm.bulkContext
        .getSample()
        .then((sample) => {
          if (!_.isEmpty(sample)) {
            vm.sampleContent = new Blob([sample], {type: 'text/csv'});
          }
        })
        .catch((error) => {
          $log.error('Failed to fetch sample content', error);
        });

      vm.domains = [];
      if (vm.bulkContext.$domainsPromise) {
        vm.bulkContext.$domainsPromise
          .then((domains) => {
            vm.domains = domains;
          })
          .catch(() => {
            showPageBanner($translate.instant('widgets.bulkOperations.error.generic'));
          });
      }
    }

    function $onChanges() {
      bulkOperationContext
        .initialize(vm.mode, vm.pageContext)
        .then((response) => {
          vm.bulkContext = response;
        })
        .catch(_.noop)
        .finally(() => {
          assignData();
          hidePageBanner();
        });
    }

    function $onDestroy() {
      // remove the event listeners registered on open
      vm.removeEventListeners();
    }
  }
})();
