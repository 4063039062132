import {useEffect, useState} from 'react';

import TableConstants from './TableConstants';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const useSelection = (options = {}) => {
  // we might have cases where no items are selectable
  // so we check for zero since it's falsy
  if (options.selectableItems !== 0 && !options.selectableItems) {
    throw new Error('useSelection: Given options does not have required field: selectableItems');
  }

  const [selectableItems, setSelectableItems] = useState(options.selectableItems);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectionStatus, setSelectionStatus] = useState(TableConstants.SELECTION_STATUS.NONE);
  const [trackBy, setTrackBy] = useState('id');

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- neculaes@ to update
  useEffect(() => {
    if (selectedItems.length === 0 || selectableItems.length === 0) {
      setSelectionStatus(TableConstants.SELECTION_STATUS.NONE);
      return;
    }

    if (selectedItems.length === selectableItems.length) {
      setSelectionStatus(TableConstants.SELECTION_STATUS.FULL);
      return;
    }

    setSelectionStatus(TableConstants.SELECTION_STATUS.PARTIAL);
  }, [selectableItems, selectedItems]);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- neculaes@ to update
  useEffect(() => {
    if (options.trackBy) {
      setTrackBy(options.trackBy);
    }
  }, [options.trackBy]);

  const deselectItem = (selectedItem) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) => !isEqual(item, selectedItem, trackBy))
    );
  };

  const deselectAllItems = () => {
    setSelectedItems([]);
  };

  const isSelected = (item) => selectedItems.some((i) => isEqual(i, item, trackBy));

  const selectAllItems = () => {
    setSelectedItems(selectableItems);
  };

  const selectItem = (selectedItem) => {
    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, selectedItem]);
  };

  const toggleItem = (item) => {
    if (isSelected(item)) {
      deselectItem(item);
    } else {
      selectItem(item);
    }
  };

  return {
    deselectAllItems,
    deselectItem,
    isSelected,
    selectableItems,
    selectAllItems,
    selectedItems,
    selectionStatus,
    selectItem,
    setSelectableItems,
    setSelectedItems,
    toggleItem,
  };
};

function isEqual(item1, item2, trackBy) {
  return item1[trackBy] === item2[trackBy];
}

export default useSelection;
