import {getMemberDisplayName} from '@admin-tribe/binky-ui';
import {Heading, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDeleteUsers} from 'common/components/remove-users-with-storage-modal//hooks/DeleteUsersModalContext';

const RemoveUsersSummarySection = () => {
  const intl = useIntl();
  const {allUsers} = useDeleteUsers();

  return (
    <View>
      <Heading level={3}>
        {intl.formatMessage(
          {id: 'common.removeUsersWithStorageModal.steps.storageSelection.summary.title'},
          {count: allUsers.length}
        )}
      </Heading>
      {allUsers.map((user) => (
        <View key={user.id} data-testid={user.id} marginBottom="size-200">
          <View UNSAFE_style={{fontWeight: 'bold'}}>
            {getMemberDisplayName(intl, user, {fallbackToEmail: true})}
          </View>
          <View UNSAFE_style={{overflowWrap: 'break-word'}}>{user.email}</View>
          <View>
            {user.storageConsumed
              ? `${user.storageConsumed.visualCount} ${user.storageConsumed.units}`
              : intl.formatMessage({
                  id: 'common.removeUsersWithStorageModal.steps.storageSelection.summary.noFoldersOrFiles',
                })}
          </View>
        </View>
      ))}
    </View>
  );
};

export default RemoveUsersSummarySection;
