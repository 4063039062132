import {
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_TYPE,
  Offer,
  PRODUCT_BUYING_PROGRAM,
  feature,
  log,
  navBus,
} from '@admin-tribe/binky';
import {
  ButtonLink,
  Chiclet,
  ImageIcon,
  ModalContent,
  ModalDescription,
  ModalHeading,
} from '@admin-tribe/binky-ui';
import {Checkbox, Divider, Heading, View} from '@adobe/react-spectrum';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_SUPPORT} from 'features/support/routing/supportPaths';

import styles from './FreeOfferDetailsPage.pcss';
import {useFreeOfferModalContext} from './FreeOfferModalContext';

const FreeOfferDetailsPage = ({offer}) => {
  const intl = useIntl();
  const {orgHasESM, setIsValid, setModalError} = useFreeOfferModalContext();

  const [termsKey, setTermsKey] = useState('');

  // disable CTA on first render
  useEffect(() => {
    setIsValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- first render only
  }, []);

  // set the terms key based on buying program
  useEffect(() => {
    const buyingProgram = offer.buying_program;
    switch (buyingProgram) {
      case PRODUCT_BUYING_PROGRAM.VIP:
        setTermsKey('offers.freeOfferModal.offerDetails.vipTerms');
        break;
      case PRODUCT_BUYING_PROGRAM.ETLA:
        setTermsKey('offers.freeOfferModal.offerDetails.etlaTerms');
        break;
      case PRODUCT_BUYING_PROGRAM.VIPMP:
        // Remove the if line on enabling FF bug_fix_38518
        if (feature.isEnabled('bug_fix_38518')) {
          setTermsKey('offers.freeOfferModal.offerDetails.vipmpTerms');
          break;
        }
      // eslint-disable-next-line no-fallthrough -- The break statement is avoided here to execute default block if FF bug_fix_38518 is disabled
      default:
        log.error(`Terms not found for buying program: ${buyingProgram}`);
        setTermsKey('');
    }
  }, [offer]);

  const fulfillableItemPairs = useMemo(() => {
    const merchItems = offer.merchandising?.fulfillable_items;
    const paItems = offer.product_arrangement?.fulfillable_items;

    let pairs = paItems?.filter(
      (fItem) =>
        fItem.ui?.visible &&
        (fItem.type === FULFILLABLE_ITEM_TYPE.DESKTOP ||
          fItem.type === FULFILLABLE_ITEM_TYPE.QUOTA ||
          fItem.type === FULFILLABLE_ITEM_TYPE.SERVICE)
    );

    pairs = pairs.map((fItem) => ({
      merch: merchItems.find((item) => item.code === fItem.code),
      pa: fItem,
      typeSort: getTypeSort(fItem),
    }));

    pairs = sortBy(pairs, ['typeSort', 'merch.copy.name']);

    // Further filters the FI list
    // VIP offers include both ESM and USM FIs, and expect all clients to have to know
    // which are valid based on org state. Once we stop selling USM, this can go away.
    if (
      paItems.some((item) => item.code === FULFILLABLE_ITEM_CODE.CC_STORAGE) &&
      paItems.some((item) => item.code === FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE)
    ) {
      pairs = pairs.filter((itemPair) => {
        // we filter out either ESM Storage or CC Storage, based on which the org is allowed
        if (orgHasESM) {
          return itemPair.pa.code !== FULFILLABLE_ITEM_CODE.CC_STORAGE;
        }
        return itemPair.pa.code !== FULFILLABLE_ITEM_CODE.ESM_USER_STORAGE;
      });
    }

    return pairs;

    function getTypeSort(fItem) {
      switch (fItem.type) {
        case FULFILLABLE_ITEM_TYPE.DESKTOP:
          return 1;
        case FULFILLABLE_ITEM_TYPE.QUOTA:
          return 2;
        default:
          // for FULFILLABLE_ITEM_TYPE.SERVICE
          return 3;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run on mount only
  }, []);

  return (
    <>
      <ModalHeading>
        {intl.formatMessage(
          {id: 'offers.freeOfferModal.offerDetails.accept'},
          {offerName: offer.merchandising.copy.short_name}
        )}
      </ModalHeading>
      <ModalDescription>{offer.merchandising.copy.description}</ModalDescription>
      <ModalContent>
        {fulfillableItemPairs?.length > 0 && (
          <>
            <Heading
              level={3}
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change text size
              UNSAFE_className={styles['includes-heading']}
            >
              {intl.formatMessage({id: 'offers.freeOfferModal.offerDetails.includes'})}
            </Heading>

            {fulfillableItemPairs?.map((fiPair) => (
              <Chiclet
                key={fiPair.pa.code}
                IconComponent={<ImageIcon alt="" size="M" src={fiPair.merch?.assets.icons.svg} />}
                name={fiPair.merch?.copy.name}
              />
            ))}
            <Divider marginBottom="size-250" marginTop="size-250" size="S" />
          </>
        )}

        {termsKey &&
          intl.formatMessage(
            {id: termsKey},
            {
              link: ([linkText]) => (
                <ButtonLink
                  onPress={() => {
                    navBus.navigate(
                      generatePath(PATH_SUPPORT, {
                        orgId: rootStore.organizationStore.activeOrgId,
                      })
                    );
                  }}
                >
                  {linkText}
                </ButtonLink>
              ),
            }
          )}
        <View>
          <Checkbox
            data-testid="accept-terms-checkbox"
            onChange={(value) => {
              setModalError(null);
              setIsValid(value);
            }}
          >
            {intl.formatMessage({id: 'offers.freeOfferModal.offerDetails.agree'})}
          </Checkbox>
        </View>
      </ModalContent>
    </>
  );
};

FreeOfferDetailsPage.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.instanceOf(Offer), PropTypes.object]),
};

export default FreeOfferDetailsPage;
