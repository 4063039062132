/**
 * Defines the asset credentials page under the settings tab.
 */
import {feature, log} from '@admin-tribe/binky';
import {
  OverlayWait,
  Subpage,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/binky-ui';
import {Flex, Heading, Switch, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {useId} from '@react-aria/utils';
import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import useCollaborationPolicies from 'features/settings/hooks/use-collaboration-policies/useCollaborationPolicies';

import AssetCredentialsSetting from './AssetCredentialsSetting';
import AssetCredentialsSettingsContent from './AssetCredentialsSettingsContent';

const AssetCredentialsSubpage = () => {
  const intl = useIntl();
  const descriptionId = useId();
  const [isEnabled, setIsEnabled] = useState(false); // Remove with temp_newcapolicy
  const [isSaving, setIsSaving] = useState(false);
  const [CASettings, setCASettings] = useState({
    contentAuthenticity: true,
    contentCredentialsActivities: true,
    contentCredentialsConnectedAccounts: true,
    contentCredentialsIndividualName: true,
  });

  const orgId = rootStore.organizationStore.activeOrgId;
  // Retrieve organization's current policy
  const {
    collaborationPolicies,
    error: errorLoadingPolicies,
    isLoading,
  } = useCollaborationPolicies({orgId});

  /**
   * Rerender the switch (or trigger an error) when the collaboration policies are loaded
   */
  useEffect(() => {
    if (collaborationPolicies) {
      if (feature.isEnabled('temp_newcapolicy')) {
        setCASettings(collaborationPolicies.getContentAuthenticityEnabled());
      } else {
        setIsEnabled(collaborationPolicies.getContentAuthenticityEnabled());
      }
    }
    if (errorLoadingPolicies) {
      log.error(`Failed to load content authenticity due to error: ${errorLoadingPolicies}`);
    }
  }, [collaborationPolicies, errorLoadingPolicies]);

  const onChange = useCallback(
    async (value) => {
      setIsSaving(true);
      try {
        await collaborationPolicies.setContentAuthenticityEnabled(value);
        if (feature.isEnabled('temp_newcapolicy')) {
          setCASettings(value);
        } else {
          setIsEnabled(value);
        }
        showSuccessToast();
      } catch (error) {
        log.error(`Failed to write content authenticity due to error: ${error}`);
        showErrorToast();
      }
      setIsSaving(false);
    },
    [collaborationPolicies]
  );

  const onToggle = useCallback(
    (value) => {
      const updatedSettings = {...CASettings, contentAuthenticity: value};
      onChange(updatedSettings);
    },
    [CASettings, onChange]
  );

  const handleSelect = useCallback(
    (apiKey, value) => {
      const updatedSettings = {...CASettings, [apiKey]: value === 'TRUE'};
      onChange(updatedSettings);
    },
    [CASettings, onChange]
  );

  if (feature.isEnabled('temp_newcapolicy')) {
    return (
      <Subpage
        data-testid="asset-settings-credentials-subpage"
        isBumpered={!!errorLoadingPolicies}
        isLoading={isLoading}
      >
        <OverlayWait isLoading={isSaving} showContent>
          <Switch
            aria-describedby={descriptionId}
            isSelected={CASettings.contentAuthenticity}
            onChange={onToggle}
          >
            <Heading level={3} marginBottom="size-50" marginTop="size-0">
              {intl.formatMessage({
                id: 'settings.assetSettings.credentials.contentCredentialsEnable.title',
              })}
            </Heading>
          </Switch>
          <View marginBottom="size-200" marginX="size-450">
            <View marginBottom="size-300">
              <FormattedMessage
                data-testid="description"
                id="settings.assetSettings.credentials.contentCredentialsEnable.description"
                values={{
                  goUrl: (str) => <GoUrl name="aac_learn_cai">{str}</GoUrl>,
                }}
              />
            </View>
            <Flex direction="column" gap="size-50">
              {AssetCredentialsSettingsContent.map((content) => {
                const props = {
                  CASettings,
                  content,
                  isDisabled: !CASettings.contentAuthenticity,
                  onSelection: handleSelect,
                };
                return <AssetCredentialsSetting key={content.titleKey} {...props} />;
              })}
            </Flex>
          </View>
        </OverlayWait>
      </Subpage>
    );
  }

  return (
    <Subpage
      data-testid="asset-settings-credentials-subpage"
      isBumpered={!!errorLoadingPolicies}
      isLoading={isLoading || isSaving}
    >
      <View marginBottom="size-100">
        <Text data-testid="description" id={descriptionId}>
          {intl.formatMessage(
            {id: 'settings.assetSettings.credentials.description'},
            {
              goUrl: (str) => <GoUrl name="aac_learn_cai">{str}</GoUrl>,
            }
          )}
        </Text>
      </View>
      <View>
        <Switch
          aria-describedby={descriptionId}
          isDisabled={errorLoadingPolicies}
          isSelected={isEnabled}
          onChange={onChange}
        >
          <FormattedMessage
            id={`settings.assetSettings.credentials.status.${isEnabled ? 'enabled' : 'disabled'}`}
          />
        </Switch>
      </View>
    </Subpage>
  );
};

export default AssetCredentialsSubpage;
