import {getStorageOnlyProducts} from '@admin-tribe/binky';
import {Link, View} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import StorageStats from 'common/services/storage-stats/StorageStats';
import rootStore from 'core/RootStore';

import StoragePlansDrawer from '../storage-plans-drawer/StoragePlansDrawer';

import UsageBarChart from './components/UsageBarChart';
import UsageBarRightHeaderContent from './components/UsageBarRightHeaderContent';
import UsageBarTable from './components/UsageBarTable';

/**
 * Shows the storage usage in both chart and tabular form.
 * The later allows someone using a screen-reader access to the same information in the chart.
 */
const UsageBarSection = ({marginBottom, storageStats}) => {
  const tableId = 'storage-plan-table-id';

  const intl = useIntl();

  const productList = rootStore.organizationStore.productList;
  const storageOnlyProducts = getStorageOnlyProducts(productList);
  const usageData = storageStats.getUsageData();

  return (
    <TitledSection
      dividerPlacement={DividerPlacement.NONE}
      headingLevel={2}
      id="storage-usage-bar"
      marginBottom={marginBottom}
      rightHeaderContent={<UsageBarRightHeaderContent storageStats={storageStats} />}
      title={intl.formatMessage({
        id: 'storage.usageBarSection.title',
      })}
    >
      {storageOnlyProducts.length > 0 && (
        <View marginTop="size-100">
          <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
            <Link>{intl.formatMessage({id: 'storage.usageBarSection.link.viewStoragePlans'})}</Link>
            <StoragePlansDrawer storageProducts={storageOnlyProducts} />
          </DrawerTrigger>
        </View>
      )}
      <UsageBarChart tableId={tableId} usageData={usageData} />
      <UsageBarTable tableId={tableId} usageData={usageData} />
    </TitledSection>
  );
};

UsageBarSection.propTypes = {
  /**
   * Margin to add after the section. Should be specified with Spectrum sizes.
   * The default is undefined.
   */
  marginBottom: PropTypes.string,
  /**
   * A resolved StorageStats object.
   */
  storageStats: PropTypes.instanceOf(StorageStats).isRequired,
};

export default UsageBarSection;
