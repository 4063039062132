(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular
    .module('app.core.support')
    .constant('SUPPORT_TICKET_ACTION', {
      CLOSE: 'close',
      ESCALATE: 'escalate',
      REOPEN: 'reopen',
    })
    .constant('SUPPORT_TICKET_ACTION_REASON_CODE', {
      CLOSE: {
        // These keys are used as string keys by widgets.support.supportCaseCloseReasonFilter.*
        // When updating these constants you must also update the strings.
        ISSUE_OBSOLETE_OR_NO_LONGER_OCCURS: 2,
        RESOLUTION_CONFIRMED: 1,
        RESOLUTION_IRRELEVANT_OR_NO_LONGER_REQUIRED: 3,
      },
      ESCALATE: {
        // These keys are used as string keys by widgets.support.supportCaseEscalateReasonFilter.*
        // When updating these constants you must also update the strings.
        AGENT_COMMUNICATION_SKILLS: 5,
        AGENT_TECHNICAL_KNOWLEDGE: 6,
        AWAITING_CALLBACK: 3,
        CHANGE_IN_URGENCY: 1,
        RESOLUTION_DID_NOT_MEET_EXPECTATIONS: 4,
        TIME_TO_RESOLUTION: 2,
      },
      REOPEN: {
        // These keys are used as string keys by widgets.support.supportCaseReopenReasonFilter.*
        // When updating these constants you must also update the strings.
        ISSUE_HAS_REOCCURED: 3,
        RESOLUTION_DID_NOT_SOLVE_ISSUE: 1,
        RESOLUTION_PARTIALLY_SOLVED_ISSUE: 2,
      },
    })
    .constant('SUPPORT_TICKET_ANALYTIC_EVENT_NAME', {
      EXPERT_SESSION: 'expertSessionsRequestExpertSession',
      SUMMARY_EXPERT_SESSION: 'supportSummaryRequestExpertSession',
    })
    .constant('SUPPORT_TICKET_CASE_TYPE', {
      ADMIN: 'ADMIN',
      PRODUCT: 'PRODUCT',
    })
    .constant('SUPPORT_TICKET_COMMENT_TYPE', {
      ACTION_PENDING_RESPONSE: 'Action - Pending Response',
      ACTION_RESOLUTION_PROVIDED: 'Action - Resolution Provided',
      COMMENT: 'Comment',
    })
    .constant('SUPPORT_TICKET_DISCUSSION_DIRECTION', {
      INBOUND: 'Inbound', // comment or attachment created by customer
      OUTBOUND: 'Outbound', // comment or attachment created by Adobe agent
      UNKNOWN: 'Unknown', // attachment creator unknown. Not expected for comments.
    })
    .constant('SUPPORT_TICKET_RECORD_TYPE', {
      CASE: 'CASE',
      EXPERT: 'EXPERT',
    })
    .constant('SUPPORT_TICKET_QUERY_PARAM', {
      ACTIVE: 'ACTIVE',
      ALL: 'ALL',
      INACTIVE: 'INACTIVE',
    })
    .constant('SUPPORT_TICKET_STATUS', {
      ACTIVE: 0,
      CANCELED: 2,
      RESOLVED: 1,
    })
    .constant('SUPPORT_TICKET_STATUS_REASON', {
      AUTO_CLOSED: 117710004,
      AWAITING_ASSIGNMENT: 117710001,
      CANCEL_MISROUTE: 6,
      IN_PROGRESS: 1,
      ISSUE_OBSOLETE: 117710002,
      MERGED: 2000,
      PENDING_ENGINEERING: 279250000,
      PENDING_MANAGED_SERVICES: 279250001,
      PENDING_RESPONSE: 3,
      RESOLUTION_CONFIRMED: 5,
      RESOLUTION_IRRELEVANT: 117710003,
      RESOLUTION_PROVIDED: 2,
      SESSION_COMPLETED: 1000,
      SESSION_SCHEDULED: 4,
    })
    // The 'stringKey' fields are used as string keys widgets.support.supportTicketStatusFilter.*
    // When updating these constants you must also update the strings.
    // The 'variant' fields correspond to <binky-status> variants and determine what colors are used
    // when displaying the status.
    .constant('SUPPORT_TICKET_UI_STATUS', {
      AUTO_CLOSED: {
        stringKey: 'AUTO_CLOSED',
        variant: 'default',
      },
      AWAITING_ASSIGNMENT: {
        stringKey: 'AWAITING_ASSIGNMENT',
        variant: 'info',
      },
      CANCEL_MISROUTE: {
        stringKey: 'CANCEL_MISROUTE',
        variant: 'default',
      },
      IN_PROGRESS: {
        stringKey: 'IN_PROGRESS',
        variant: 'success',
      },
      ISSUE_OBSOLETE: {
        stringKey: 'ISSUE_OBSOLETE',
        variant: 'default',
      },
      MERGED: {
        stringKey: 'MERGED',
        variant: 'default',
      },
      PENDING_ENGINEERING: {
        stringKey: 'IN_PROGRESS',
        variant: 'success',
      },
      PENDING_MANAGED_SERVICES: {
        stringKey: 'IN_PROGRESS',
        variant: 'success',
      },
      PENDING_RESPONSE: {
        stringKey: 'PENDING_RESPONSE',
        variant: 'warning',
      },
      RESOLUTION_CONFIRMED: {
        stringKey: 'RESOLUTION_CONFIRMED',
        variant: 'default',
      },
      RESOLUTION_IRRELEVANT: {
        stringKey: 'RESOLUTION_IRRELEVANT',
        variant: 'default',
      },
      RESOLUTION_PROVIDED: {
        stringKey: 'RESOLUTION_PROVIDED',
        variant: 'warning',
      },
      SESSION_COMPLETED: {
        stringKey: 'SESSION_COMPLETED',
        variant: 'default',
      },
      SESSION_SCHEDULED: {
        stringKey: 'SESSION_SCHEDULED',
        variant: 'success',
      },
      UNKNOWN: {
        stringKey: 'UNKNOWN',
        variant: 'info',
      },
    });
})();
