import {Flex, Heading, SearchField, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import RailRightOpenIcon from '@spectrum-icons/workflow/RailRightOpen';
import SearchIcon from '@spectrum-icons/workflow/Search';
import TreeCollapseAllIcon from '@spectrum-icons/workflow/TreeCollapseAll';
import TreeExpandAllIcon from '@spectrum-icons/workflow/TreeExpandAll';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import OtherVersionsFilterButton from '../other-versions-filter-button/OtherVersionsFilterButton';
import {focusSelectedAppsSearchButton} from '../productSelectionPageUtils';

// AvailableProductsHeader is the header section for available products and applies filters on
// the products appearing in available products sections
const AvailableProductsHeader = ({
  accordionRefs,
  addAllProducts,
  adobeProductsUi,
  appFilters,
  areAllProductsExpanded,
  availableProductsCount,
  currentExpandedProductSapCode,
  isEnterpriseSupportPolicyApplicable,
  isItemPresentInAvailableProductsSection,
  selectedProductsSearchFieldRef,
  setAppFilters,
  setCurrentExpandedProductSapCode,
  showExtraProductsInTemplates,
  toggleExpandProducts,
}) => {
  const intl = useIntl();
  const [showAvailableProductSearchBar, setShowAvailableProductSearchBar] = useState(
    !!appFilters.searchText
  );
  const onSearchTextChange = (searchText) => {
    setAppFilters({
      ...appFilters,
      searchText,
    });
  };

  const focusOnFirstAccordion = () => {
    // focus on the first accordion in available products list if present
    if (accordionRefs[0]?.current?.headerId) {
      document.querySelector(`[id = ${accordionRefs[0].current.headerId}]`)?.focus();
    }
  };

  const onAddAllBtnPress = () => {
    // Shift focus to selectedAppsSearchBtn/ selectedAppsSearchField else, focus ring stays on the disabled button
    if (selectedProductsSearchFieldRef.current) {
      selectedProductsSearchFieldRef.current?.focus();
    } else {
      focusSelectedAppsSearchButton();
    }

    addAllProducts();

    // if button not disabled, then accordions might be present in available products list
    // then, focus on first accordion
    focusOnFirstAccordion();
  };

  return (
    <Flex direction="column">
      <View>
        <Heading level={3} marginTop="size-0">
          {intl.formatMessage(
            {
              id: 'packages.createPackage.productSelection.availableApps.title',
            },
            {productsLength: availableProductsCount}
          )}
        </Heading>
        {intl.formatMessage({
          id: 'packages.createPackage.chooseApps.availableApps.desc',
        })}
      </View>
      {(showAvailableProductSearchBar || !!appFilters.searchText) && (
        <Flex height="size-800" justifyContent="space-between">
          <SearchField
            autoFocus
            label={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.searchPlaceholder',
            })}
            marginTop="size-100"
            onChange={onSearchTextChange}
            onClear={() => onSearchTextChange('')}
            value={appFilters.searchText}
          />
          <TooltipButton
            alignSelf="end"
            aria-label={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.availableApps.collapseAvailableApps',
            })}
            data-testid="search-close-button"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.chooseApps.availableApps.collapseAvailableApps',
            })}
            onPress={() => {
              setShowAvailableProductSearchBar(false);
              onSearchTextChange('');
            }}
            variant="action"
          >
            <RailRightOpenIcon />
          </TooltipButton>
        </Flex>
      )}
      {!showAvailableProductSearchBar && !appFilters.searchText && (
        <Flex alignItems="end" height="size-800" justifyContent="space-between">
          <TooltipButton
            autoFocus
            data-testid="search-open-button"
            hoverText={intl.formatMessage({
              id: 'packages.createPackage.productSelection.selectedApps.searchSelectedApps',
            })}
            onPress={() => {
              setShowAvailableProductSearchBar(true);
            }}
            variant="action"
          >
            <SearchIcon />
          </TooltipButton>
          <Flex gap="size-75">
            <TooltipButton
              data-testid="toggle-expand-button"
              hoverText={
                areAllProductsExpanded
                  ? intl.formatMessage({
                      id: 'packages.createPackage.productSelection.availableApps.collapseAll',
                    })
                  : intl.formatMessage({
                      id: 'packages.createPackage.productSelection.availableApps.expandAll',
                    })
              }
              isDisabled={!isItemPresentInAvailableProductsSection()}
              onPress={toggleExpandProducts}
              variant="action"
            >
              {areAllProductsExpanded ? (
                <TreeCollapseAllIcon data-testid="tree-collapse-all" />
              ) : (
                <TreeExpandAllIcon data-testid="tree-expand-all" />
              )}
            </TooltipButton>
            <OtherVersionsFilterButton
              adobeProductsUi={adobeProductsUi}
              appFilters={appFilters}
              currentExpandedProductSapCode={currentExpandedProductSapCode}
              isEnterpriseSupportPolicyApplicable={isEnterpriseSupportPolicyApplicable}
              setAppFilters={setAppFilters}
              setCurrentExpandedProductSapCode={setCurrentExpandedProductSapCode}
              showExtraProductsInTemplates={showExtraProductsInTemplates}
            />
            <TooltipButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.productSelection.availableApps.addAll',
              })}
              data-testid="add-all-button"
              hoverText={intl.formatMessage({
                id: 'packages.createPackage.productSelection.availableApps.addAll',
              })}
              isDisabled={!isItemPresentInAvailableProductsSection()}
              onPress={onAddAllBtnPress}
              variant="action"
            >
              {intl.formatMessage({
                id: 'packages.createPackage.chooseApps.availableApps.addAll',
              })}
            </TooltipButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

AvailableProductsHeader.propTypes = {
  /**
   * References to accordions in available products list
   */
  accordionRefs: PropTypes.arrayOf(
    PropTypes.shape({
      current: PropTypes.shape({headerId: PropTypes.string}),
    })
  ).isRequired,
  /**
   * Add all products to the selected products list
   */
  addAllProducts: PropTypes.func.isRequired,
  /**
   * Exhaustive list of packageable products for the current platform
   */
  adobeProductsUi: PropTypes.instanceOf(Array).isRequired,
  /**
   * App filters that can be applied on the available products list
   */
  appFilters: PropTypes.shape({
    /**
     * Search text
     */
    searchText: PropTypes.string,
    /**
     * If archived products should be shown
     */
    showArchived: PropTypes.bool,
    /**
     * If beta products should be shown
     */
    showBeta: PropTypes.bool,
    /**
     * If latest products should be shown
     */
    showLatest: PropTypes.bool,
    /**
     * If long term supported products should be shown
     */
    showLTS: PropTypes.bool,
    /**
     * If older products should be shown
     */
    showOlder: PropTypes.bool,
    /**
     * If pre-release products should be shown
     */
    showPreRelease: PropTypes.bool,
  }).isRequired,
  /**
   * True if all products accordions are expanded
   */
  areAllProductsExpanded: PropTypes.bool.isRequired,
  /**
   * Number of available products
   */
  availableProductsCount: PropTypes.number.isRequired,
  /**
   * Expanded product accordions sap codes
   */
  currentExpandedProductSapCode: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * If enterprise support policy should be applied or not
   */
  isEnterpriseSupportPolicyApplicable: PropTypes.bool.isRequired,
  /**
   * Indicates if item present in available products section
   */
  isItemPresentInAvailableProductsSection: PropTypes.func.isRequired,
  /**
   * Reference to selected products search field
   */
  selectedProductsSearchFieldRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }),
  /**
   * Function to set app filtera
   */
  setAppFilters: PropTypes.func.isRequired,
  /**
   * Function to set current expanded product sapcode accordions
   */
  setCurrentExpandedProductSapCode: PropTypes.func.isRequired,
  /**
   * Indicates if extra products should be shown in templates
   */
  showExtraProductsInTemplates: PropTypes.bool.isRequired,
  /**
   * Toggles expanded/collapsed products accordions
   */
  toggleExpandProducts: PropTypes.func.isRequired,
};

export default AvailableProductsHeader;
