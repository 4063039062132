(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets:appOpenSupportCases
   * @description Shows up to 3 open expert sessions for the logged-in admin.
   *
   * @binding {Object} expertSessionList. The list of open expert sessions for this admin.
   */
  angular.module('app.widgets.support').component('appExpertSessionSummary', {
    bindings: {
      expertSessionList: '<',
    },
    controller,
    templateUrl: 'app/widgets/support/expert-session-summary/expert-session-summary.component.html',
  });

  /* @ngInject */
  function controller($rootScope, _, MESSAGE) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      expertSessionListDisplay: {
        sessionTopic: true,
      },
      messageKey: undefined,
    });

    function $onInit() {
      vm.expertSessionList.$promise
        .then(() => {
          $rootScope.$emit(
            MESSAGE.UPDATE.EXPERTSESSIONSUMMARY,
            vm.expertSessionList.items.length > 0 || vm.expertSessionList.unfilteredTicketCount > 0
          );
        })
        .catch(() => {
          vm.messageKey = 'widgets.support.expertSessionSummary.fetchError';
        });
    }
  }
})();
