import {Content, ContextualHelp, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const DeviceDetailsContextualHelp = ({isVisible, nameSpace}) => {
  const intl = useIntl();
  return isVisible ? (
    <ContextualHelp alignSelf="center" placement="top" variant="info">
      <Content>
        <Text>{intl.formatMessage({id: `${nameSpace}.activationSection.contextualHelp`})}</Text>
      </Content>
    </ContextualHelp>
  ) : null;
};

DeviceDetailsContextualHelp.propTypes = {
  /**
   * Whether to render ContextualHelp component
   */
  isVisible: PropTypes.bool.isRequired,

  /**
   * A common translation namespace
   */
  nameSpace: PropTypes.string.isRequired,
};

export default DeviceDetailsContextualHelp;
