import {useEffect, useState} from 'react';

import {
  canViewAssetSettings,
  canViewAuthentication,
  canViewConsole,
  canViewEncryptionSettings,
  canViewIdentity,
  canViewSecurityContacts,
  canViewSettings,
  canViewSharedProjectPolicies,
} from 'core/settings/access/settingsAccess';

/** @description Provides access rights for settings */
const useSettingsAccessors = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [canViewAssetSettingsValue, setCanViewAssetSettingsValue] = useState(false);
  const [canViewAuthenticationValue, setCanViewAuthenticationValue] = useState(false);
  const [canViewConsoleValue, setCanViewConsoleValue] = useState(false);
  const [canViewEncryptionSettingsValue, setCanViewEncryptionValue] = useState(false);
  const [canViewIdentityValue, setCanViewIdentityValue] = useState(false);
  const [canViewSecurityContactsValue, setCanViewSecurityContactsValue] = useState(false);
  const [canViewSettingsValue, setCanViewSettingsValue] = useState(false);
  const [canViewSharedProjectPoliciesValue, setCanViewSharedProjectPoliciesValue] = useState(false);

  // Saves results from accessor async functions
  useEffect(() => {
    (async () => {
      setCanViewAssetSettingsValue(await canViewAssetSettings());
      setCanViewAuthenticationValue(await canViewAuthentication());
      setCanViewConsoleValue(await canViewConsole());
      setCanViewEncryptionValue(await canViewEncryptionSettings());
      setCanViewIdentityValue(await canViewIdentity());
      setCanViewSecurityContactsValue(await canViewSecurityContacts());
      setCanViewSettingsValue(await canViewSettings());
      setCanViewSharedProjectPoliciesValue(await canViewSharedProjectPolicies());
      setIsLoading(false);
    })();
  }, []);

  return {
    canViewAssetSettings: canViewAssetSettingsValue,
    canViewAuthentication: canViewAuthenticationValue,
    canViewConsole: canViewConsoleValue,
    canViewEncryptionSettings: canViewEncryptionSettingsValue,
    canViewIdentity: canViewIdentityValue,
    canViewSecurityContacts: canViewSecurityContactsValue,
    canViewSettings: canViewSettingsValue,
    canViewSharedProjectPolicies: canViewSharedProjectPoliciesValue,
    isLoading,
  };
};

export default useSettingsAccessors;
