(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name jobResultListTableRow
   * @description Component which displays a table row for a job-result-list-table
   *
   * @binding {JobResultItem} jobResultItem - job result item data to display in row
   * @binding {PageContext} pageContext - the pageContext controlling where links to
   *   deficit reports are sent to
   */
  angular
    .module('app.widgets.bulk-operations')
    .directive('appJobResultListTableRow', jobResultListTableRow);

  /* @ngInject */
  function jobResultListTableRow(
    $log,
    $state,
    $uiRouterGlobals,
    _,
    JOB_ITEM_FAILURE_TYPE,
    PAGE_TARGET,
    PAGE_TARGET_TYPE
  ) {
    const directive = {
      link: linkFn,
      restrict: 'A',
      scope: {
        jobResultItem: '<',
        pageContext: '<',
      },
      templateUrl:
        'app/widgets/bulk-operations/jobs/job-result-list-table/job-result-list-table-row.directive.html',
    };

    return directive;

    ////////

    function linkFn(scope) {
      _.assign(scope, {
        getDeficitReportLink,
        isItemLicenseCountExceeded,
        messages: scope.jobResultItem.getMessages(),
      });

      ////////

      function getDeficitReportLink() {
        let pageLink;
        switch (scope.pageContext.target) {
          case PAGE_TARGET.PRODUCT:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                throw new Error('Angular products has been removed');
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.PRODUCT_CONFIGURATION:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                throw new Error('Angular products has been removed');
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.USER_GROUP:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                pageLink = $state.href('users.user-group-license-deficit-report', {
                  jobId: $uiRouterGlobals.params.jobId,
                  orgId: $uiRouterGlobals.params.orgId,
                  userGroupId: scope.pageContext.targetId,
                });
                break;
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.ORGANIZATION:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                pageLink = $state.href('users.license-deficit-report', {
                  jobId: $uiRouterGlobals.params.jobId,
                  orgId: $uiRouterGlobals.params.orgId,
                });
                break;
              default:
                logUnimplementedError();
            }
            break;
          default:
            logUnimplementedError();
        }

        return pageLink;

        ////////

        function logUnimplementedError() {
          $log.debug(
            `jobResultListTableRow: License deficit report view unimplemented for provided pageContext: ${scope.pageContext}`
          );
        }
      }

      function isItemLicenseCountExceeded() {
        return _.some(scope.jobResultItem.failureDetails, {
          type: JOB_ITEM_FAILURE_TYPE.ALLOWABLE_LICENSE_COUNT_EXCEEDED,
        });
      }
    }
  }
})();
