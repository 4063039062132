import {Flex, Link, StatusLight, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {EDU_SYNC_STATUS_LIGHT} from 'features/settings/components/edu-sync-card/EduSyncStatus';
import {
  AUTHENTICATION_SETUP_STATUSES,
  AUTO_ACCOUNT_CREATION_STATUSES,
} from 'features/settings/stores/DirectoryStore';
import {SYNC_SETUP_STATUSES} from 'features/settings/stores/DirectorySyncStore';

import styles from './DirectoryOverviewCard.pcss';

const DIRECTORY_STATUS_LIGHT = {
  [AUTHENTICATION_SETUP_STATUSES.COMPLETED]: {
    label: 'settings.directoryOverview.card.status.COMPLETED',
    variant: 'positive',
  },
  [AUTO_ACCOUNT_CREATION_STATUSES.DISABLED]: {
    label: 'settings.directoryOverview.card.status.DISABLED',
    variant: 'neutral',
  },
  [AUTO_ACCOUNT_CREATION_STATUSES.ENABLED]: {
    label: 'settings.directoryOverview.card.status.ENABLED',
    variant: 'positive',
  },
  [SYNC_SETUP_STATUSES.ERROR]: {
    label: 'settings.directoryOverview.card.status.ERROR',
    variant: 'negative',
  },
  [AUTHENTICATION_SETUP_STATUSES.IN_PROGRESS]: {
    label: 'settings.directoryOverview.card.status.IN_PROGRESS',
    variant: 'notice',
  },
  [AUTHENTICATION_SETUP_STATUSES.UNCONFIGURED]: {
    label: 'settings.directoryOverview.card.status.UNCONFIGURED',
    variant: 'neutral',
  },
  [AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRED]: {
    label: 'settings.directory.status.CERTIFICATE_EXPIRED',
    variant: 'negative',
  },
  [AUTHENTICATION_SETUP_STATUSES.CERTIFICATE_EXPIRING]: {
    label: 'settings.directory.status.CERTIFICATE_EXPIRING',
    variant: 'notice',
  },
  UNKNOWN: {
    label: 'settings.directoryOverview.card.status.UNKNOWN',
    variant: 'neutral',
  },
  ...EDU_SYNC_STATUS_LIGHT,
};

const DirectoryStatus = observer(({count, goToTab, status, title}) => {
  const intl = useIntl();

  const {label, variant} = DIRECTORY_STATUS_LIGHT[status] ?? DIRECTORY_STATUS_LIGHT.UNKNOWN;

  return (
    <div
      aria-label={intl.formatMessage({id: `${title}.ariaLabel`})}
      data-testid="directory-status-row"
      onClick={goToTab}
      onKeyDown={goToTab}
      role="button"
      styleName="directory-status-row"
      tabIndex={0}
    >
      <Flex
        alignItems="baseline"
        direction="row"
        justifyContent="space-between"
        minHeight="size-450"
      >
        <View width="45%">
          <Link
            // TBD: remove `onPress` prop when introducing React Routing as we want the
            // whole row to be clickable while maintaining the Link aspect of the label
            onPress={goToTab}
          >
            {intl.formatMessage({id: title})}
          </Link>
        </View>
        <View width="45%">
          <StatusLight
            role="status"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- clear formatting
            UNSAFE_className={styles['directory-status']}
            variant={variant}
          >
            {intl.formatMessage({id: label}, {count})}
          </StatusLight>
        </View>
      </Flex>
    </div>
  );
});

export default DirectoryStatus;
export {DIRECTORY_STATUS_LIGHT};
