import {
  NavigationAnchor,
  TableIconNameCell,
  TableSectionTable,
  ViewDetailsDrawerTrigger,
} from '@admin-tribe/binky-ui';
import {Link, Text} from '@adobe/react-spectrum';
import DataIcon from '@spectrum-icons/workflow/Data';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductDetailsDrawer from 'features/products/components/product-details-drawer/ProductDetailsDrawer';
import {getHrefForProductDetails} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import {getProductInstanceName} from './utils/ProductInstancesTableUtils';

/**
 * Displays tabular details for DX Product instances for a specific product group, grouped via FigProductGroupProductList
 */
const ProductInstancesTable = () => {
  const intl = useIntl();

  const columnDescriptor = [
    {
      key: 'key',
      props: {allowsSorting: true},
    },
    {
      key: 'viewDetails',
      props: {align: 'end', hideHeader: true, showDivider: true},
    },
    {
      key: 'provisionedQuantity',
      props: {align: 'end', allowsSorting: true},
    },
    {
      key: 'adminCount',
      props: {align: 'end', allowsSorting: true},
    },
  ];

  const getIcon = () => (
    <Text
      height="var(--spectrum-global-dimension-size-300)"
      marginEnd="size-250"
      UNSAFE_style={{
        backgroundColor: 'var(--color-grey-300)',
        borderRadius: '50%',
        padding:
          'var(--spectrum-global-dimension-size-50) var(--spectrum-global-dimension-size-100)',
      }}
      width="var(--spectrum-global-dimension-size-300)"
    >
      <DataIcon size="XS" />
    </Text>
  );

  const renderers = {
    adminCount: renderFormattedNumber,
    key: renderName,
    provisionedQuantity: renderFormattedNumber,
    viewDetails: renderViewDetailsDrawerTrigger,
  };

  function renderFormattedNumber({item, key}) {
    const value = item[key];
    if (value === undefined) {
      return value;
    }
    return intl.formatNumber(value);
  }

  function renderName({item}) {
    return (
      <TableIconNameCell IconComponent={getIcon()}>
        {item.isAdministerable() ? (
          <Link>
            <NavigationAnchor
              href={getHrefForProductDetails({
                id: item.id,
              })}
            >
              {getProductInstanceName(item)}
            </NavigationAnchor>
          </Link>
        ) : (
          getProductInstanceName(item)
        )}
      </TableIconNameCell>
    );
  }

  function renderViewDetailsDrawerTrigger({item}) {
    return (
      item.isAdministerable() && (
        <ViewDetailsDrawerTrigger tooltipName={getProductInstanceName(item)}>
          {() => <ProductDetailsDrawer product={item} />}
        </ViewDetailsDrawerTrigger>
      )
    );
  }

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({
        id: 'products.productInstancesTable.ariaLabel',
      })}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.productInstancesTable.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'products.productInstancesTable.noItemsFoundContentMessage',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'products.productInstancesTable.noSearchResultsContentMessage',
      })}
      renderers={renderers}
    />
  );
};

ProductInstancesTable.displayName = 'ProductInstancesTable';
export default ProductInstancesTable;
