/**
 * @class
 * @description Front-end model that allows us to organize, verify, and reason
 *   with banner data to power product  banners in our application
 */
class BannerNotification {
  body;
  buttonTxt;
  handleButtonClick;
  header;
  name;
  removeWithKey;
  variant;

  /**
   * @description Constructor for new banner notifications. A banner
   *   notification is a front-end model that allows us to organize, verify, and
   *   reason with banner data to power product  banners in our application
   * @param {Object} options - notification configuration Object
   * @param {String} [options.body] - any body message for notification
   * @param {String} [options.buttonTxt] - any button text for notification
   * @param {Function} [options.handleButtonClick] - handler for any click interactions
   * @param {String} [options.header] - any header text for notification
   * @param {String} [options.name] - for notification
   * @param {String} [options.removeWithKey] - key to coordinate local storage
   *   show/hide behavior with any notifications (if key is present in local
   *   storage, notification should not be shown)
   * @param {String} options.variant - type of notification; one of: error,
   *   info, or warning
   */
  constructor({body, buttonTxt, handleButtonClick, header, name, removeWithKey, variant}) {
    this.body = body;
    this.buttonTxt = buttonTxt;
    this.handleButtonClick = handleButtonClick;
    this.header = header;
    this.name = name;
    this.removeWithKey = removeWithKey;
    this.variant = variant;
  }
}

export default BannerNotification;
