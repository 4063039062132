import {feature} from '@admin-tribe/binky';
import {Divider, Flex, Radio, RadioGroup, Text, View, Well} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  PICKER_TYPE,
  SEARCH_TYPE,
  UserPicker,
  UserPickerContentModel,
  UserPickerContextProvider,
  UserPickerV3,
  UserPickerV3ContentModel,
} from '@pandora/react-user-picker';
import Info from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {REMOVE_USER_STORAGE_OPTIONS} from 'common/services/user-folder/userFolderConstants';
import RootStore from 'core/RootStore';

import {useDeleteUsers} from '../../hooks/DeleteUsersModalContext';

const StorageTransferSelection = ({
  defaultUser,
  onSelectionChange,
  onTransferStorageUser,
  selectedOption,
}) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');
  const [transferStorageUser, setTransferStorageUser] = useState(defaultUser || {});

  const {allUsers} = useDeleteUsers();

  const orgId = RootStore.organizationStore.activeOrgId;
  const content = UserPickerContentModel.createEntry({
    label: intl.formatMessage({
      id: 'common.removeUsersWithStorageModal.steps.storageSelection.selectUserTransferLabel',
    }),
    noResultsNoAddText: intl.formatMessage({id: 'common.UserPicker.noResults.noAdd'}),
  });
  // rename to content when removing temp_new_user_picker
  const contentV3 = useContentEntry(UserPickerV3ContentModel);
  const infoContentKeys = {
    [REMOVE_USER_STORAGE_OPTIONS.SHARE]:
      'common.removeUsersWithStorageModal.steps.storageSelection.transferContentLater.info',
    [REMOVE_USER_STORAGE_OPTIONS.DISCARD]:
      'common.removeUsersWithStorageModal.steps.storageSelection.transferContentNow.info',
    [REMOVE_USER_STORAGE_OPTIONS.DELETE]:
      'common.removeUsersWithStorageModal.steps.storageSelection.permanentlyDeleteContent.info',
  };

  const onInputChange = (input) => {
    if (feature.isEnabled('temp_new_user_picker')) {
      setSearchQuery(input);
    }
    // check if a selection has already been made, clear selection until a new one is selected
    const email = transferStorageUser?.email;
    if (email && email !== input) {
      setTransferStorageUser({});
      onTransferStorageUser({});
    }
  };

  const onUserSelection = (selectedUser) => {
    if (
      feature.isDisabled('temp_new_user_picker') &&
      allUsers.find((user) => user.id === selectedUser.id)
    ) {
      setTransferStorageUser();
    } else {
      // clear the user id to allow for the error state in the field to reset
      setTransferStorageUser(selectedUser);
      onTransferStorageUser(selectedUser);
    }
  };

  // for the situation when focus is on the textfield and does not allow keyboarding to some of the radio buttons
  useEffect(() => {
    if (onTransferStorageUser) {
      const selectUserField = document.querySelector(
        '[data-testid="transfer-storage-user-picker"]'
      );
      const transferStorageNowOption = document.querySelector(
        '[data-testid="transfer-storage-now-option"]'
      );
      const transfetStorageLaterOpton = document.querySelector(
        '[data-testid="transfer-storage-later-option"]'
      );

      const handleKeyDown = (keyDownEvent) => {
        if (keyDownEvent.key === 'ArrowUp') {
          transferStorageNowOption.focus();
          onSelectionChange(REMOVE_USER_STORAGE_OPTIONS.SHARE);
        } else if (keyDownEvent.key === 'ArrowDown') {
          transfetStorageLaterOpton.focus();
          onSelectionChange(REMOVE_USER_STORAGE_OPTIONS.DISCARD);
        }
      };

      // we still want to navigate user picker row by arrow if the picker is being used
      if (searchQuery.length === 0) {
        selectUserField.addEventListener('keydown', handleKeyDown);
      }

      return () => {
        selectUserField.removeEventListener('keydown', handleKeyDown);
      };
    }
    return null;
  }, [onSelectionChange, onTransferStorageUser, searchQuery]);

  const renderAdditionalInfo = (value) =>
    feature.isEnabled('temp_show_more_reclaim_info') && (
      <Well marginTop="size-150">
        <Flex alignContent="stretch" alignItems="center" gap="size-150">
          <Info size="S" />
          <Text>{intl.formatMessage({id: infoContentKeys[value]})}</Text>
        </Flex>
      </Well>
    );

  return (
    <RadioGroup
      label={intl.formatMessage({
        id: 'common.removeUsersWithStorageModal.steps.storageSelection.selectionLabel',
      })}
      marginTop="size-100"
      onChange={onSelectionChange}
      value={selectedOption}
    >
      <Radio
        aria-describedby="transfer-storage-now-option-description"
        data-testid="transfer-storage-now-option"
        value={REMOVE_USER_STORAGE_OPTIONS.SHARE}
      >
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.transferContentNow.label',
        })}
      </Radio>
      <Text id="transfer-storage-now-option-description">
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.transferContentNow.description',
        })}
        {selectedOption === REMOVE_USER_STORAGE_OPTIONS.SHARE &&
          renderAdditionalInfo(REMOVE_USER_STORAGE_OPTIONS.SHARE)}
      </Text>
      {onTransferStorageUser && (
        <View data-testid="transfer-storage-user-picker" marginTop="size-150">
          <UserPickerContextProvider canAddUser={false}>
            {feature.isEnabled('temp_new_user_picker') ? (
              <UserPickerV3
                content={contentV3}
                defaultValue={defaultUser?.email}
                disabledIds={allUsers.map((user) => user.id)}
                label={intl.formatMessage({
                  id: 'common.removeUsersWithStorageModal.steps.storageSelection.selectUserTransferLabel',
                })}
                onInputChange={onInputChange}
                onSelect={onUserSelection}
                orgId={orgId}
                pickerType={PICKER_TYPE.USERS_ONLY}
                searchType={SEARCH_TYPE.EXISTING_OR_NEW_USER}
                width="size-3600"
              />
            ) : (
              <UserPicker
                content={content}
                defaultValue={defaultUser?.email}
                message={
                  !transferStorageUser && selectedOption === REMOVE_USER_STORAGE_OPTIONS.SHARE
                    ? {
                        value: intl.formatMessage({
                          id: 'common.removeUsersWithStorageModal.steps.storageSelection.userSelectionError',
                        }),
                        variant: 'error',
                      }
                    : undefined
                }
                onInputChange={onInputChange}
                onSelect={onUserSelection}
                orgId={orgId}
                pickerType={PICKER_TYPE.USERS_ONLY}
                searchType={SEARCH_TYPE.EXISTING_OR_NEW_USER}
              />
            )}
          </UserPickerContextProvider>
        </View>
      )}
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <Radio
        aria-describedby="transfer-storage-later-option-description"
        data-testid="transfer-storage-later-option"
        value={REMOVE_USER_STORAGE_OPTIONS.DISCARD}
      >
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.transferContentLater.label',
        })}
      </Radio>
      <Text id="transfer-storage-later-option-description">
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.transferContentLater.description',
        })}
        {selectedOption === REMOVE_USER_STORAGE_OPTIONS.DISCARD &&
          renderAdditionalInfo(REMOVE_USER_STORAGE_OPTIONS.DISCARD)}
      </Text>
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <Radio
        aria-describedby="delete-content-option-description"
        value={REMOVE_USER_STORAGE_OPTIONS.DELETE}
      >
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.permanentlyDeleteContent.label',
        })}
      </Radio>
      <Text id="delete-content-option-description">
        {intl.formatMessage({
          id: 'common.removeUsersWithStorageModal.steps.storageSelection.permanentlyDeleteContent.description',
        })}
        {selectedOption === REMOVE_USER_STORAGE_OPTIONS.DELETE &&
          renderAdditionalInfo(REMOVE_USER_STORAGE_OPTIONS.DELETE)}
      </Text>
    </RadioGroup>
  );
};

StorageTransferSelection.propTypes = {
  /**
   * Used to pre-populate the user selection field and set the selected user. This is useful for ensuring that transitioning through steps.
   * mainiain the correct information.
   */
  defaultUser: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
  /**
   * Callback that passes the selected radio option
   */
  onSelectionChange: PropTypes.func.isRequired,
  /**
   * Callback that passes in either the selected user object or an empty object that signifies an invalid selection
   */
  onTransferStorageUser: PropTypes.func,
  /**
   * The selected action of what to do with the storage of the user to be deleted
   */
  selectedOption: PropTypes.oneOf(Object.values(REMOVE_USER_STORAGE_OPTIONS)).isRequired,
};

export default StorageTransferSelection;
