(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.identity')
    .constant('SETUP_IDENTITY_MODAL_ID', 'setup-identity-modal-id')
    .constant('SETUP_IDENTITY_MODAL_SECTION_CONTEXT', {
      DIRECTORY: 'DIRECTORY',
      DOMAIN: 'DOMAIN',
      LINK: 'LINK',
    });
})();
