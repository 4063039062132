import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

let clientId, url;

/**
 * Configure Sophia API
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for the Sophia api
 * @param {String} config.clientId - The identifier for application
 */
const configure = (config) => {
  ({clientId, url} = config);
};

const getHeaders = () => {
  const authenticationToken = authentication.getAccessToken();

  const headers = {
    'X-Api-Key': clientId,
  };

  if (authenticationToken?.token) {
    headers.Authorization = `Bearer ${authenticationToken.token}`;
  }

  return headers;
};

/**
 * @description Fetches sophia content
 *
 * @param {Object} params - Sophia fetchContent params
 * @param {String} params.clientCode - The sophia client code
 * @param {ContextualParams} params.contextualParams - contextual params required by Sophia
 * @param {Array<String>} params.surfaceID - Array of surface IDs
 *
 * @returns {Promise} A promise which will resolve with the response data
 */
const postContent = (params) =>
  axios.post(url, params, {
    headers: getHeaders(),
  });

/**
 * @deprecated Use SophiaV2 API instead
 */
const sophia = {
  configure,
  postContent,
};

export default sophia;
