import {Flex} from '@adobe/react-spectrum';
import {useCheckboxGroup} from '@react-aria/checkbox';
import {useCheckboxGroupState} from '@react-stately/checkbox';
import PropTypes from 'prop-types';
import React from 'react';

import CreatePackageCardCheckboxGroupContext from './CreatePackageCardCheckboxGroupContext';

const CreatePackageCardCheckboxGroup = (props) => {
  const {children, gap = 'size-400', label} = props;
  const state = useCheckboxGroupState(props);
  const {groupProps, labelProps} = useCheckboxGroup(props, state);

  return (
    <div {...groupProps}>
      <span {...labelProps}>{label}</span>
      <Flex gap={gap}>
        <CreatePackageCardCheckboxGroupContext.Provider value={state}>
          {children}
        </CreatePackageCardCheckboxGroupContext.Provider>
      </Flex>
    </div>
  );
};

CreatePackageCardCheckboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Gap between cards. Defaults to size-400.
   */
  gap: PropTypes.string,
  /**
   * Label of card radio group
   */
  label: PropTypes.string,
};
export default CreatePackageCardCheckboxGroup;
