(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name Link
   * @description Model for a link
   */
  angular.module('app.shell.navigation.links').factory('Link', getLinkModel);

  /* @ngInject */
  function getLinkModel(_) {
    class Link {
      /**
       * @description Creates a new Link for use.
       * @param {Object} options - Initialization Object (params described below)
       * @param {String} options.key - String translation key of text for link
       * @param {String|String[]|Object} options.locator - Unique ID(s) for target of link,
       *   depending on the instance, this could be a state ID or a getting
       *   started topic ID. Can also accept an Object specifying global modal configurations
       * @param {LINK_TARGET_TYPE} options.target - The target of this link, one
       *   of: LINK_TARGET_TYPE.APPLICATION, LINK_TARGET_TYPE.EXTERNAL,
       *   LINK_TARGET_TYPE.GO_URL, LINK_TARGET_TYPE.HELP, or
       *   LINK_TARGET_TYPE.MODAL
       * @param {String} [options.translateInterpretation] - if messageformat is
       *   used to support pluralization in translation key, then this is where
       *   you would set the interpolation to be passed along to $translate
       *   (optional; default is '')
       * @param {Object} [options.translateValues] - key/value pairs as Object
       *   attributes that should be passed along to aid in translation provided
       *   by options.key (optional; default is {})
       * @param {LINK_TYPE} options.type - The type of link, one of:
       *   LINK_TYPE.BUTTON, LINK_TYPE.ICON, LINK_TYPE.TAB, or LINK_TYPE.TEXT
       */
      constructor(options = {}) {
        _.assign(
          this,
          _(options)
            .pick([
              'key',
              'locator',
              'target',
              'translateInterpretation',
              'translateValues',
              'type',
            ])
            .defaults({translateInterpretation: '', translateValues: {}})
            .value()
        );
      }

      /* eslint-disable class-methods-use-this */
      /**
       * @description Method to navigate to the locator described by this link.
       *   Must be implemented by any classes that extend this class (children).
       */
      goToLink() {
        throw new Error('Link: goToLink() must be implemented');
      }

      /**
       * @description Method to determine whether or not the locator described
       *   by this link is available (navigable) by the current user. Must be
       *   implemented by any classes that extend this class (children).
       *   Implemented method must return type: Boolean (true if available, else
       *   false).
       */
      isAvailable() {
        throw new Error('Link: isAvailable() must be implemented');
      }
      /* eslint-enable class-methods-use-this */
    }

    return Link;
  }
})();
