import onesieSrc2 from '../../../../src2/onesieSrc2';

(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name digitalData
   * @description Provider and service for digitalData analytics. This provider is responsible for
   * listening to some events, and translate the event data into $window.digitalData.
   */

  angular.module('app.core.analytics').factory('digitalData', digitalData);

  /* @ngInject */
  function digitalData(_) {
    const factory = {
      analyticsData: {},
      analyticsDataPropertiesToDelete: [],
      createEventDataAndCleanUp,
      onesieSrc2,
      setProperty,
    };

    return factory; // Return the factory object

    function setProperty(propName, data, deleteProperty = true) {
      if (!_.isUndefined(data)) {
        _.set(this.analyticsData, propName, data);

        if (deleteProperty) {
          this.analyticsDataPropertiesToDelete.push(propName);
        }
      }
      onesieSrc2.analyticsData = this.analyticsData;
    }

    function cleanUpProperties() {
      _.forEach(this.analyticsDataPropertiesToDelete, (propName) => {
        _.unset(this.analyticsData, propName);
      });
      this.analyticsDataPropertiesToDelete = [];
    }

    function snapshot() {
      return _.cloneDeep(this.analyticsData);
    }

    function createEventDataAndCleanUp() {
      const snapshotData = {
        _adobe_corpnew: snapshot.call(this),
        xdm: {},
      };
      cleanUpProperties.call(this);
      return snapshotData;
    }
  }
})();
