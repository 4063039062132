/* eslint-disable max-lines */
(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name aepAnalytics
   * @description Provider and service for Launch analytics. This provider is responsible for
   * listening to some events, and translate the event data into $window.digitalData.
   */

  angular.module('app.core.analytics').provider('aepAnalytics', aepAnalytics);

  /* @ngInject */
  function aepAnalytics() {
    let launchConfig;
    // See chapter 5c from https://git.corp.adobe.com/AdobeAnalyticsEnablement/launch-code/blob/master/docs/Adobe%20Experience%20Cloud%20Implementation%20Guide.docx

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(configData) {
      launchConfig = configData;
    }

    /* @ngInject */
    function $get(
      _,
      $injector,
      $location,
      $log,
      $rootScope,
      $transitions,
      $window,
      ANALYTICS_EVENT,
      ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS,
      auth,
      binkySrc2,
      feature,
      jsUtils,
      AEP_ANALYTICS_SITE_SECTION,
      locale,
      moment,
      OrganizationList,
      PANEL_MANAGER,
      PRODUCT_APPLICABLE_OFFER_TYPE,
      digitalData
    ) {
      const service = {
        initialize,
      };

      return service;

      ////////////

      function initialize() {
        if (feature.isDisabled('bumper_analytics')) {
          $transitions.onSuccess({}, (transition) => {
            const transitionTo = transition.to();
            if (!shouldTriggerPageChange(transition)) {
              return;
            }
            const targetName = transitionTo.name;
            onPageChange(_.replace(targetName, /\./g, ':'));
          });
          // As this is a singleton, we don't need to worry about destroy
          /* eslint-disable angular/on-watch */
          $rootScope.$on(PANEL_MANAGER.DRAWER.OPEN, (evt, id) => {
            onPanelOpen(id, 'drawer');
          });
          $rootScope.$on(PANEL_MANAGER.MODAL.OPEN, (evt, id) => {
            onPanelOpen(id, 'modal');
          });
          $rootScope.$on(PANEL_MANAGER.PULLDOWN.OPEN, (evt, id) => {
            onPanelOpen(id, 'pulldown');
          });
          $rootScope.$on(PANEL_MANAGER.RAIL.OPEN, (evt, id) => {
            onPanelOpen(id, 'rail');
          });

          $rootScope.$on(ANALYTICS_EVENT, (evt, analyticsEvent) => {
            onAnalyticsEvent(analyticsEvent);
          });
          /* eslint-enable angular/on-watch */
        }
      }

      /**
       * @description Allow certain routes to skip analytics, otherwise there will be multiple "state" triggers
       * @param {Object} transition the state transition object
       * @returns {Boolean} false if the route is configured to skip analytics, true otherwise
       */
      function shouldTriggerPageChange(transition) {
        const transitionTo = transition.to();
        if (_.get(transitionTo, 'data.skipAnalytics')) {
          return false;
        }

        // Allow certain routes to skip analytics for transition reload events
        const transitionFrom = transition.from();
        if (
          _.get(transitionFrom, 'name') === _.get(transitionTo, 'name') &&
          _.get(transitionTo, 'data.skipReloadAnalytics')
        ) {
          return false;
        }
        return true;
      }

      function onPageChange(topNavName) {
        collectPageInfo(topNavName);
        collectFloodgateInfo();
        collectOrganizationInfo();
        track('state');
      }

      function onPanelOpen(panelId, panelType) {
        // Send the existing event data for backward compatibility with DTM
        setProperty('attributes', {
          action: 'display',
          element: panelId,
          feature: panelId,
          type: panelType,
          widget: {
            name: panelId,
            type: panelType,
          },
        });

        collectFloodgateInfo();

        trackEvent(_.camelCase(panelId), 'open');
      }

      function onAnalyticsEvent(evt) {
        const eventName = _.camelCase(
          _.get(evt, 'descriptors.uiEvent.eventName') || _.get(evt, 'componentMethod')
        );
        const eventAction =
          _.get(evt, 'descriptors.uiEvent.eventAction') || _.get(evt, 'componentMethodType');
        const componentName =
          _.get(evt, 'descriptors.uiEvent.componentName') || _.get(evt, 'componentName');

        // Send the existing event data for backward compatibility with DTM
        setProperty('attributes', {
          action: eventAction,
          attributes: evt.attributes || {},
          cgen: evt.cgen,
          element: eventName,
          feature: eventName,
          type: componentName,
          widget: {
            name: eventName,
            type: componentName,
          },
        });

        const launchContext = {
          launchConfig,
          pageName: undefined,
          setProperty: setProperty.bind(this),
          triggerStateChange: (value) => {
            launchContext.pageName = value;
          },
        };

        collectProductOffer(evt);
        collectPurchasedProducts(evt);
        collectFloodgateInfo();
        collectDescriptors(evt, launchContext);

        if (launchContext.pageName) {
          collectOrganizationInfo();
          track('state');
        } else {
          trackEvent(eventName, eventAction);
        }
      }

      ////////

      function collectDescriptors(eventData, launchContext) {
        _.forEach(eventData.descriptors, (descriptor, descriptorName) => {
          let descriptorService;
          try {
            descriptorService = $injector.get(`${descriptorName}ToLaunchTranslator`);
          } catch (error) {
            $log.error(`Missing Launch translator for ${descriptorName}`, error);
          }
          _.invoke(descriptorService, 'processDescriptor', launchContext, descriptor);
        });
      }

      function collectPageInfo(pageName) {
        // Don't auto-delete page because it's required for upcoming events.
        // Also, prefer setting individual property because AC doesn't own 'page' object
        setProperty('page.autoTrack', false, false);
        setProperty('page.env', launchConfig.env, false);
        setProperty('page.language', locale.getCoralLocale(), false);
        const host = $location.host();
        setProperty('page.customPageName', `${host}:${pageName}`, false);
        // 'siteSection' must be hard-coded otherwise Launch will ignore the 'state' change
        setProperty('page.siteSection', AEP_ANALYTICS_SITE_SECTION, false);
        setProperty('page.solution.name', 'aac', false);
      }

      function collectOrganizationInfo() {
        const orgManager = $injector.get('OrganizationManager');
        const activeOrg = OrganizationList.get().activeOrg;

        const organizationInfo = _({
          accountType: auth.getHighestRole(),
          authOrigin: 'ims',
          authSystem: 'ims',
          corpId: activeOrg.id,
          corpName: activeOrg.name,
        })
          .defaults(collectOrgContracts(orgManager))
          .defaults(collectOrgProducts(orgManager))
          .pickBy()
          .value();

        setProperty('organization', setOrganizationProps(organizationInfo), false);
        setProperty('contract', organizationInfo.contract, false);
        setProperty('productCodes', organizationInfo.productCodes, false);
      }

      function setOrganizationProps(organizationInfo) {
        return {
          accountType: organizationInfo.accountType,
          authOrigin: organizationInfo.authOrigin,
          authSystem: organizationInfo.authSystem,
          corpId: organizationInfo.corpId,
          corpName: organizationInfo.corpName,
          product: organizationInfo.product,
        };
      }

      function collectOrgContracts(orgManager) {
        const contractList = _.get(orgManager.getContractsForActiveOrg(), 'items');
        const contractAnniversaryDates = [];
        const contractAutoRenewStatus = [];
        const contractAutoRenewUpdateChannels = [];
        const contractModels = [];
        const contractSalesChannels = [];
        const contractCustomerSegments = [];
        const contractOffsetFromAnniversaryDates = [];
        const contractOffsetFromRenewalStartDates = [];
        const contractOffsetFromContractStartDates = [];
        const contractIds = [];
        const contractBillingTypes = [];
        const contractBuyingProgram = [];
        const contractInRenewalWindows = [];
        const contractMustAcceptTerms = [];
        _.forEach(contractList, (contract) => {
          contractBillingTypes.push(contract.getBillingFrequency());
          contractIds.push(_.get(contract, 'id'));
          contractInRenewalWindows.push(contract.isInRenewalWindow());
          contractOffsetFromAnniversaryDates.push(contract.getOffsetFromAnniversaryDate());
          const renewalOffset = contract.getOffsetFromRenewalWindowStartDate();
          const contractOffset = contract.getOffsetFromStartDate();
          contractOffsetFromRenewalStartDates.push(_.isNaN(renewalOffset) ? '' : renewalOffset);
          contractOffsetFromContractStartDates.push(_.isNaN(contractOffset) ? '' : contractOffset);
          contractBuyingProgram.push(_.get(contract, 'buyingProgram'));
          contractModels.push(_.get(contract, 'model', ''));
          contractSalesChannels.push(_.get(contract, 'salesChannel', ''));
          contractCustomerSegments.push(_.get(contract, 'customerSegment', ''));
          const anniversaryDate = contract.getAnniversaryDate();
          contractAnniversaryDates.push(
            anniversaryDate ? moment(anniversaryDate).format('MM|YYYY') : ''
          );
          contractAutoRenewStatus.push(_.get(contract, 'autoRenewalMode', ''));
          contractAutoRenewUpdateChannels.push('renew');
          contractMustAcceptTerms.push(contract.mustAcceptTerms());
        });

        if (_.isEmpty(contractModels)) {
          return {}; // Make sure there's no extra data sent to analytics
        }

        return {
          contract: {
            anniversaryDate: _.join(contractAnniversaryDates, ','),
            autoRenewStatus: _.join(contractAutoRenewStatus, ','),
            autoRenewUpdateChannel: _.join(contractAutoRenewUpdateChannels, ','),
            billingType: _.join(contractBillingTypes, ','),
            buyingProgram: _.join(contractBuyingProgram, ','),
            customerSegment: _.join(contractCustomerSegments, ','),
            id: _.join(contractIds, ','),
            inRenewalWindow: _.join(contractInRenewalWindows),
            model: _.join(contractModels, ','),
            mustAcceptTerms: _.join(contractMustAcceptTerms, ','),
            offsetFromAnniversaryDate: _.join(contractOffsetFromAnniversaryDates, ','),
            offsetFromContractStartDate: _.join(contractOffsetFromContractStartDates, ','),
            offsetFromRenewalStartDate: _.join(contractOffsetFromRenewalStartDates, ','),
            salesChannel: _.join(contractSalesChannels, ','),
          },
        };
      }

      function getSubscriptionStatus(Offer) {
        return Offer === PRODUCT_APPLICABLE_OFFER_TYPE.TRIAL ? 'Trial' : 'Paid';
      }

      function collectOrgProducts(orgManager) {
        const productList = _.get(orgManager.getProductsForActiveOrg(), 'items');
        const productCodes = _(productList).map('code').uniq().sort().join(',');

        if (_.size(productCodes) === 0) {
          return {}; // Make sure there's no extra data sent to analytics
        }

        const ownedProducts = _.map(productList, (product) => ({
          attributes: {
            assignedLicense: _.get(product, 'delegatedQuantity', ''),
            contractId: _.get(product, 'contractId', ''),
            totalLicense: product.hasUnlimitedLicenses()
              ? _.get(product, 'delegatedQuantity', '')
              : product.getAssignableLicenseCount(),
            // A boolean primitive with value 'false' will be ignored by Launch Analytics.
            // A coerced string value is used here to ensure it is sent.
            unlimitedLicenses: product.hasUnlimitedLicenses().toString(),
          },
          productInfo: {
            offerId: _.get(product, 'offerId', ''),
            subscriptionGUID: _.get(product, 'id', ''),
            subscriptionStatus: getSubscriptionStatus(_.get(product, 'applicableOfferType', '')),
          },
        }));

        return {
          product: ownedProducts,
          productCodes,
        };
      }

      function collectProductOffer(eventData) {
        dataMapper(eventData, {
          attributes: {
            productOffer: 'primaryProduct.productInfo.offerId',
            profilesChangedCount: 'primaryProduct.attributes.profilesChangedCount',
            profilesRemovedCount: 'primaryProduct.attributes.profilesRemovedCount',
          },
        });
      }

      function collectPurchasedProducts(eventData) {
        const purchasedProducts = _.get(eventData, 'attributes.productsPurchased');
        if (purchasedProducts) {
          const productList = _.map(purchasedProducts, (product) =>
            _.pickBy({
              category: {
                primaryCategory: product.category,
              },
              price: collectPurchasedProductsPrice(product),
              productInfo: {offerId: product.offer},
              quantity: product.quantity,
            })
          );
          setProperty('product', productList);

          dataMapper(eventData, {
            attributes: {
              transaction: {
                amountWithoutTax: 'transaction.total.basePrice',
                amountWithTax: 'transaction.total.priceWithTax',
                currencyCode: 'currency.code',
                purchaseAuthorizationId: 'transaction.transactionID',
              },
            },
          });
        }
      }

      function collectPurchasedProductsPrice(product) {
        // Any of the price components may be missing
        const basePrice = product.priceWithoutTax;
        let priceWithTax;
        if (!_.isUndefined(basePrice) && !_.isUndefined(product.tax)) {
          priceWithTax = _.toNumber(basePrice) + _.toNumber(product.tax);
        }
        let price = _.pickBy({basePrice, priceWithTax});
        price = _.isEmpty(price) ? undefined : price;
        return price;
      }

      ////////

      /**
       * @description Collect floodgate analytcs data
       */
      function collectFloodgateInfo() {
        if (feature.isEnabled('temp_variant_info_analytics')) {
          const variantInfo = binkySrc2.services.analytics.analyticsUtils.getFloodgateVariantData(
            ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS
          );

          setProperty('attributes.variantInfo', variantInfo, false);
        }
      }

      /**
       * @description Convenience method to easily map data from 'sourceObj' into the `digitalData`.
       *
       * @param {*} sourceObj the object that has the source data
       * @param {*} dataMap  the object mapping to follow
       * @param {*} prefix (optional) allow getting 'sourceObject' data with different starting property.
       */
      function dataMapper(sourceObj, dataMap) {
        const flattenDataMap = jsUtils.flattenKeys(dataMap);
        _.forEach(flattenDataMap, (sourceObjKey) => {
          const destPropName = _.get(dataMap, sourceObjKey);
          setProperty(destPropName, _.get(sourceObj, sourceObjKey));
        });
      }

      function setProperty(propName, data, deleteProperty = true) {
        digitalData.setProperty(propName, data, deleteProperty);
      }

      function trackEvent(name, action) {
        setProperty('primaryEvent.eventInfo.eventName', `adminConsole:${name}:${action}`);
        setProperty('primaryEvent.eventInfo.eventAction', name);

        track();
      }

      function track(type = 'event') {
        try {
          /* eslint-disable no-underscore-dangle */
          if ($window._satellite) {
            $window._satellite.track(type, {
              data: digitalData.createEventDataAndCleanUp(),
            });
          }
          /* eslint-enable no-underscore-dangle */
        } catch (error) {
          $log.error('Analytics: Unable to file analytics data.', error);
        }
      }
    }
  }
})();
/* eslint-enable max-lines */
