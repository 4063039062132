// eslint-disable-next-line angular/window-service
const POLICY_CONSTANTS = window.binkySrc2.api.policy.POLICY_CONSTANTS;

(function () {
  /**
   * @deprecated Use binkySrc2.api.policy.POLICY_CONSTANTS
   */
  angular
    .module('app.core.api')
    .constant('INVITATION_DOMAIN_SHARING', POLICY_CONSTANTS.INVITATION_DOMAIN_SHARING)
    .constant('POLICY', POLICY_CONSTANTS.POLICY)
    .constant('GLOBAL_ADMIN_POLICY', POLICY_CONSTANTS.GLOBAL_ADMIN_POLICY);
})();
