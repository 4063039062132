(function () {
  const factoryName = 'organizationTypeHelper';
  /**
   * @deprecated use the OrgTypeHelper in src2
   *
   * @ngdoc factory
   * @name aapp.widgets.organizations:organizationtypeHelper
   * @description an org type helper factory
   */
  angular.module('app.widgets.organizations').factory(factoryName, getOrganizationTypeHelper);

  /* @ngInject */
  function getOrganizationTypeHelper(
    $q,
    $rootScope,
    _,
    AnalyticsEvent,
    ContractTerms,
    OFFER_LIST_MODE,
    onesieSrc2,
    ORGANIZATION_TYPE_ETLA_K12_TERMS_SERVICE_NAME,
    OrganizationList,
    OrganizationManager,
    PRICE_POINT
  ) {
    const service = {
      setupFreeOffers,
      updateOrgMarketSubsegments,
    };

    return service;

    /**
     * @deprecated Use src2 OrganizationTypeHelper updateOrgMarketSubsegments()
     * @description Method to provision offers for organization types
     * @param {Array} marketSubsegments an array of market subsegments
     * @param {Object} options options to provision offers
     * @param {Boolean} options.hasAdditionalTermsToAccept the boolean value to accept additional terms
     * @returns {Promise} a promise fulfilled when successfully updating the org's properties and provisioning offers
     */
    function updateOrgMarketSubsegments(marketSubsegments, options = {}) {
      // For an ETLA org if selecting K-12, we have to successfully save agreement to terms, before storing the
      // marketSubsegment.
      // If the marketSubsegment has been reset in one of the management consoles it is possible that the terms have
      // already been accepted but for simplicity we'll just require agreement again.
      if (options.hasAdditionalTermsToAccept) {
        return ContractTerms.get({
          orgId: OrganizationList.get().activeOrg.id,
          serviceName: ORGANIZATION_TYPE_ETLA_K12_TERMS_SERVICE_NAME,
        })
          .$promise.then((terms) => {
            if (terms.mustAcceptTerms()) {
              return terms.acceptTerms();
            }
            return $q.resolve(terms);
          })
          .then(performUpdate);
      }
      return performUpdate();

      ////////

      function performUpdate() {
        const updateDeferred = $q.defer();
        OrganizationList.get()
          .activeOrg.update({marketSubsegments})
          .then(triggerAnalyticsAndBroadcastTypeChange)
          .then(updateDeferred.resolve)
          .catch((error) => updateDeferred.reject(error.response));
        return updateDeferred.promise;
      }
    }

    function setupFreeOffers() {
      const productList = OrganizationManager.getProductsForActiveOrg();
      const freeOfferList = OrganizationManager.getOffersForActiveOrg({
        mode: OFFER_LIST_MODE.PURCHASE,
        price_point: PRICE_POINT.FREE,
      });

      const resourcePromises = [productList.$promise, freeOfferList.$promise];
      return $q.all(resourcePromises).then(() => {
        freeOfferList.filterOnQualifyingFreeOffers({
          organization: OrganizationList.get().activeOrg,
          ownedProducts: productList,
        });
        return freeOfferList;
      });
    }

    function triggerAnalyticsAndBroadcastTypeChange(response) {
      triggerAnalyticsOnSubmit({marketSubsegments: response.marketSubsegments});
      $rootScope.$broadcast(
        onesieSrc2.core.organizations.organizationSettings.ORGANIZATION_TYPE_CHANGED
      );
      return response;
    }

    /**
     * Dispatches analytics for setting the organization's marketSubsegment.
     * @param {Object} options - analytics event options
     * @param {Array} options.marketSubsegments the marketSubsegment(s) that was/were submitted
     */
    function triggerAnalyticsOnSubmit(options) {
      AnalyticsEvent.dispatch({
        attributes: {
          marketSubsegments: _.join(options.marketSubsegments), // convert to a string type
        },
        componentMethod: 'setOrgType',
        componentMethodType: 'submit',
        componentName: factoryName,
      });
    }
  }
})();
