(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories')
    .component('appDirectorySettingsRemoveDirectorySection', {
      templateUrl:
        'app/widgets/directories/directory-settings/remove-directory-section/directory-settings-remove-directory-section.component.html',
    });
})();
