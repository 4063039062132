(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.core.jobs')
    // The failures that can occur for a particular job item (e.g. a particular
    // row in a CSV). For now this list only contains the small number of failure
    // types we need to reference from within our UI code, rather than all the
    // types which can be returned by JIL
    .constant('JOB_ITEM_FAILURE_TYPE', {
      ALLOWABLE_LICENSE_COUNT_EXCEEDED: 'ALLOWABLE_LICENSE_COUNT_EXCEEDED',
      INVALID_USER_TYPE: 'INVALID_USER_TYPE',
      UNKNOWN_USER_TYPE: 'UNKNOWN_USER_TYPE',
    })
    // The expected types of operation returned by JIL
    .constant('JOB_OPERATION', {
      ADD_ENTERPRISE_USERS: 'ADD_ENTERPRISE_USERS',
      ADD_ENTERPRISE_USERS_PLC: 'ADD_ENTERPRISE_USERS_PLC',
      ADD_ENTERPRISE_USERS_UG: 'ADD_ENTERPRISE_USERS_UG',
      ADD_ORGANIZATION_USERS: 'ADD_ORGANIZATION_USERS',
      ADD_ORGANIZATION_USERS_LG: 'ADD_ORGANIZATION_USERS_LG',
      ADD_ORGANIZATION_USERS_UG: 'ADD_ORGANIZATION_USERS_UG',
      ADD_USER_GROUPS: 'ADD_USER_GROUPS',
      ASSIGN_LICENSES: 'ASSIGN_LICENSES',
      EDIT_ENTERPRISE_USERS: 'EDIT_ENTERPRISE_USERS',
      EDIT_ORGANIZATION_USER_GROUPS: 'EDIT_ORGANIZATION_USER_GROUPS',
      EDIT_ORGANIZATION_USERS: 'EDIT_ORGANIZATION_USERS',
      OFFER_MIGRATION: 'OFFER_MIGRATION',
      REMOVE_ENTERPRISE_USERS: 'REMOVE_ENTERPRISE_USERS',
      REMOVE_ENTERPRISE_USERS_DU: 'REMOVE_ENTERPRISE_USERS_DU',
      REMOVE_ENTERPRISE_USERS_PLC: 'REMOVE_ENTERPRISE_USERS_PLC',
      REMOVE_ENTERPRISE_USERS_UG: 'REMOVE_ENTERPRISE_USERS_UG',
      REMOVE_ORGANIZATION_USERS: 'REMOVE_ORGANIZATION_USERS',
      REMOVE_ORGANIZATION_USERS_LG: 'REMOVE_ORGANIZATION_USERS_LG',
      REMOVE_ORGANIZATION_USERS_UG: 'REMOVE_ORGANIZATION_USERS_UG',
      REVOKE_ENTERPRISE_INVITES: 'REVOKE_ENTERPRISE_INVITES',
      SWITCH_ENTERPRISE_USERS: 'SWITCH_ENTERPRISE_USERS',
      SWITCH_ORGANIZATION_USERS: 'SWITCH_ORGANIZATION_USERS',
    })
    // The potential values of a job result item's status property. The item's
    // status indicates its overall outcome; e.g. if a CSV row's cells were all
    // processed successfully except for one, its status would be FAILURE. The
    // status might be NOT_PROCESSED if the job was cancelled before the item
    // was processed.
    .constant('JOB_RESULT_ITEM_STATUS', {
      FAILURE: 'FAILURE',
      NOT_PROCESSED: 'NOT_PROCESSED',
      SUCCESS: 'SUCCESS',
    })
    // The expected job statuses returned by JIL. The statuses and their meanings are documented in
    // https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=JIL2&title=Async+Workers+in+JIL
    .constant('JOB_STATUS', {
      CANCELLED: 'CANCELLED',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      PROCESSING: 'PROCESSING',
      QUEUED: 'QUEUED',
      REJECTED: 'REJECTED',
      UNKNOWN: 'UNKNOWN',
      UNKNOWN_JOB_TYPE: 'UNKNOWN_JOB_TYPE',
    });
})();
