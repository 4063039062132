(function () {
  const componentName = 'addUser2GlobalModal';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.main.global-modals-manager:AddUser2GlobalModal
   */
  angular.module('app.main.global-modals-manager').factory(componentName, AddUser2GlobalModal);

  /* @ngInject */
  function AddUser2GlobalModal(
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    onesieSrc2,
    OrganizationManager
  ) {
    const service = {
      open,
    };

    return service;

    //////////////

    /**
     * Initialize and open React Add User Modal
     * @param {Object} options options for this method
     * @param {String} [options.licenseGroupId] the product profile id
     * @param {String} [options.modalName] the modal name
     * @param {String} [options.productId] the product id
     * @param {String} [options.userGroupId] the user group id
     */
    function open({licenseGroupId, modalName, productId, userGroupId}) {
      if (!modalName) {
        throw new Error('Must define modalName in AddUser2GlobalModal');
      }

      globalModalsManager.open({
        componentBindings: [
          {
            attributeName: 'component',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: onesieSrc2.common.components[modalName],
          },
          {
            attributeName: 'props',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: {
              isOpen: true,
              licenseGroupId,
              orgId: OrganizationManager.getActiveOrgId(),
              productId,
              userGroupId,
            },
          },
        ],
        componentName: 'binkyReactContainer',
      });
    }
  }
})();
