const ContractRenewalMiniApp = 'ContractRenewalMiniApp';

// Mini App Runtime Context
const MiniAppContext = {
  IN_APP: 'a',
  IN_WEB: 'if',
};

const MiniAppMessageTypes = {
  ASSIGN_LICENSE: 'assign-license',
  OPT_IN: 'opt-in',
  RENEWAL: 'renewal',
  RESIZE: 'resize',
};

const OptInMessageTypes = {
  SUCCESS: 'success',
};

const RenewalMessageTypes = {
  UPDATE: 'update',
  VIEW: 'view',
};

// Workflow in Add Product Mini App (consider importing when tree-shakeable from @pandora/react-mini-app-add-product)
const WorkflowType = {
  ADD_LICENSE: 'add-license',
  ADD_PRODUCT: 'add-product',
  UPDATE_RENEW: 'update-renew',
  VIEW_RENEW: 'view-renew',
};

// Step of Renewal Order in Add Product Mini App (consider importing when tree-shakeable from @pandora/react-mini-app-add-product)
const StepType = {
  REVIEW_ORDER: 'review-order',
  SELECT_PRODUCT: 'select-product',
};

export {
  ContractRenewalMiniApp,
  MiniAppContext,
  MiniAppMessageTypes,
  RenewalMessageTypes,
  WorkflowType,
  StepType,
  OptInMessageTypes,
};
