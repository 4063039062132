(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories')
    .constant(
      'APP_CREATE_ENHANCED_SAML_DIRECTORY_MODAL_ID',
      'create-enhanced-saml-directory-modal'
    );
})();
