import binkyUI from '@admin-tribe/binky-ui';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Tooltip from '@react/react-spectrum/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import AutoAccountCreationStatus from 'features/settings/components/idp-card/AutoAccountCreationStatus';
import IdpMetadataItemLabel from 'features/settings/components/idp-card/IdpMetadataItemLabel';
import UpdateStrategy from 'features/settings/components/idp-card/UpdateStrategy';

import IdpEntity from '../../entities/IdpEntity';
import useIdpProtocol from '../../hooks/useIdpProtocol';

import './IdpCard.pcss';

const FormattedDateAndTime = binkyUI.common.components.FormattedDateAndTime;

const IdpCardMetadata = ({idp}) => {
  const APRIL_15 = 1586949577000;
  const protocol = useIdpProtocol(idp);

  const createdAt = idp.createdAt ? (
    <FormattedDateAndTime value={new Date(idp.createdAt)} />
  ) : (
    <FormattedMessage
      id="settings.authentication.idpCreationDateUnknown"
      values={{
        date: <FormattedDateAndTime value={new Date(APRIL_15)} />,
      }}
    />
  );

  const azureTenantId = idp.isAzure ? (
    <div data-testid="tenant-id" styleName="idp-metadata-item">
      <dt data-testid="tenant-id-label">
        <IdpMetadataItemLabel id="settings.authentication.tenantIdLabel" />
      </dt>
      <dd>
        <OverlayTrigger placement="top">
          <p data-testid="tenant-id-value" styleName="ellipsis">
            {idp.aadOrganization}
          </p>
          <Tooltip role="tooltip">{idp.aadOrganization}</Tooltip>
        </OverlayTrigger>
      </dd>
    </div>
  ) : null;

  const certificateType =
    idp.isOkta || idp.isSaml ? (
      <div data-testid="certificate-type" styleName="idp-metadata-item">
        <dt data-testid="certificate-type-label">
          <IdpMetadataItemLabel id="settings.authentication.certificateTypeLabel" />
        </dt>
        {idp.isOkta ? (
          <dd data-testid="certificate-type-value">
            <FormattedMessage id="settings.authentication.sha1" />
          </dd>
        ) : (
          <dd data-testid="certificate-type-value">
            <FormattedMessage id="settings.authentication.sha256" />
          </dd>
        )}
      </div>
    ) : null;

  return (
    <dl styleName="idp-metadata-list">
      <div data-testid="protocol" styleName="idp-metadata-item full-width">
        <dt data-testid="protocol-label">
          <IdpMetadataItemLabel id="settings.authentication.protocolLabel" />
        </dt>
        <dd data-testid="protocol-value">{protocol}</dd>
      </div>
      {certificateType}
      {azureTenantId}
      <div data-testid="created-at" styleName="idp-metadata-item">
        <dt data-testid="created-at-label">
          <IdpMetadataItemLabel id="settings.authentication.createdAtLabel" />
        </dt>
        <dd data-testid="created-at-value">{createdAt}</dd>
      </div>
      <div data-testid="account-creation" styleName="idp-metadata-item">
        <dt data-testid="account-creation-label">
          <IdpMetadataItemLabel id="settings.authentication.autoAccountCreationLabel" />
        </dt>
        <dd data-testid="account-creation-value">
          <AutoAccountCreationStatus jitStatus={idp.jitStatus} />
        </dd>
      </div>
      <div data-testid="sync-strategy" styleName="idp-metadata-item">
        <dt data-testid="sync-strategy-label">
          <IdpMetadataItemLabel id="settings.authentication.updateStrategyLabel" />
        </dt>
        <dd data-testid="sync-strategy-value">
          <UpdateStrategy updateStrategy={idp.updateStrategy} />
        </dd>
      </div>
    </dl>
  );
};

IdpCardMetadata.propTypes = {
  idp: PropTypes.instanceOf(IdpEntity),
};

export default IdpCardMetadata;
