import {feature} from '@admin-tribe/binky';
import {WorkspaceNav} from '@admin-tribe/binky-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import useUsersAccessors from 'features/users/hooks/useUsersAccessors';
import useUsersPaths from 'features/users/hooks/useUsersPaths';

/** Renders the workspace nav (sidenav) for the Users section */
const UsersWorkspaceNav = () => {
  const intl = useIntl();

  const {
    canViewAdmins,
    canViewApiCredentials,
    canViewDevelopers,
    canViewDirectories,
    canViewUserGroups,
    canViewUsers,
    isLoading,
  } = useUsersAccessors();

  const {
    administratorsPath,
    apiCredentialsPath,
    developersPath,
    directoryUsersPath,
    userGroupsPath,
    usersPath,
  } = useUsersPaths();

  const navItems = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const allNavItems = [
      {
        canAccess: canViewUsers,
        href: usersPath,
        label: 'users.users.title',
        testId: 'nav-users',
      },
      {
        canAccess: canViewUserGroups,
        href: userGroupsPath,
        label: 'users.usersGroups.title',
        testId: 'nav-user-groups',
      },
      {
        canAccess: canViewAdmins,
        href: administratorsPath,
        label: 'users.administrators.title',
        testId: 'nav-administrators',
      },
      {
        canAccess: canViewDevelopers,
        href: developersPath,
        label: 'users.developers.title',
        testId: 'nav-developers',
      },
      {
        canAccess: canViewDirectories,
        href: directoryUsersPath,
        label: 'users.ownedDirectoryListPage.title',
        testId: 'nav-owned-directory-users',
      },
      {
        canAccess: canViewApiCredentials,
        href: apiCredentialsPath,
        label: 'users.APICredentials.title',
        testId: 'nav-api-credentials',
      },
    ];

    const items = allNavItems
      .filter((item) => item.canAccess)
      .map((item, index) => ({
        href: item.href(),
        name: intl.formatMessage({id: item.label}),
        order: index,
        ...(item.testId && feature.isEnabled('temp_navitem_testids') ? {testId: item.testId} : {}),
      }));

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- intl and paths are constant
  }, [
    canViewAdmins,
    canViewApiCredentials,
    canViewDevelopers,
    canViewDirectories,
    canViewUserGroups,
    canViewUsers,
    isLoading,
  ]);

  return <WorkspaceNav navItems={navItems} />;
};

UsersWorkspaceNav.displayName = 'UsersWorkspaceNav';

export default UsersWorkspaceNav;
