import {showError, showSuccess} from '@admin-tribe/binky-ui';
import {useRuleListPatch} from '@pandora/data-model-acrs';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import rootStore from 'core/RootStore';

import JitRuleContext from './JitRuleContext';
import {getAdobeRuleAndProduct} from './jitRuleUtils';

const JitRuleProvider = ({children, closeModal, widgetData}) => {
  const {productArrangementCode} = widgetData;
  const [ruleEnabled, setRuleEnabled] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [productName, setProductName] = useState(null);
  const [ruleDate, setRuleDate] = useState(null);

  const apiHasError = useRef(false);
  const ruleId = useRef(null);

  const onApiError = useCallback(() => {
    showError('Auto assign rule could not be updated.');
    apiHasError.current = true;
  }, []);

  const onApiFinally = useCallback(() => {
    if (!apiHasError.current) {
      showSuccess('Auto assign rule updated successfully.');
    }
    closeModal();
  }, [closeModal]);

  const onSubmit = useCallback(
    (mutate) => {
      apiHasError.current = false;
      const operations = [
        {
          op: 'replace',
          path: `/${ruleId.current}/eligibleForNotifications`,
          value: notificationsEnabled,
        },
      ];
      if (ruleEnabled) {
        operations.push({
          op: 'replace',
          path: `/${ruleId.current}/status`,
          value: 'ACTIVE',
        });
      }
      mutate(operations);
    },
    [notificationsEnabled, ruleEnabled]
  );

  const {isLoading, mutate} = useRuleListPatch({
    onError: onApiError,
    onFinally: onApiFinally,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  // obtain the correct ACRS rule
  useEffect(() => {
    const result = getAdobeRuleAndProduct(productArrangementCode);
    if (result === undefined) {
      // there was no matching rule, and the modal is meaningless without this
      // widget, so let's close it.
      closeModal();
      return;
    }

    const [rule, product] = result;
    ruleId.current = rule.ruleId;
    setRuleEnabled(true);
    setNotificationsEnabled(!!rule.eligibleForNotifications);
    setProductName(product.shortName);
    setRuleDate(new Date(rule.waitingUntilDate));
  }, [closeModal, productArrangementCode]);

  const contextValue = useMemo(
    () => ({
      closeModal,
      isLoading,
      notificationsEnabled,
      onSubmit: () => onSubmit(mutate),
      productName,
      ruleDate,
      ruleEnabled,
      setNotificationsEnabled,
      setRuleEnabled,
      variant: widgetData.variant,
    }),
    [
      closeModal,
      isLoading,
      mutate,
      notificationsEnabled,
      onSubmit,
      productName,
      ruleDate,
      ruleEnabled,
      widgetData.variant,
    ]
  );
  return <JitRuleContext.Provider value={contextValue}>{children}</JitRuleContext.Provider>;
};

JitRuleProvider.propTypes = {
  /**
   * children provided by parent to render under this component
   */
  children: PropTypes.node.isRequired,

  /**
   * callback to close the modal
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * options provided for the JIT rule widget
   */
  widgetData: PropTypes.shape({
    /**
     * product arrangement code for which to search for an applicable JIT rule
     */
    productArrangementCode: PropTypes.string.isRequired,

    /**
     * Which toggles to show.
     * @example variant="status,notifications"
     * @example variant="notifications"
     */
    variant: PropTypes.string.isRequired,
  }).isRequired,
};

export default JitRuleProvider;
