import UserCheckedOutIcon from '@spectrum-icons/workflow/UserCheckedOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getNumberOfPendingProductRequests} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import {canViewProductAccessRequests} from 'core/products/access/productAccess';
import {goToProductRequests} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import QuickLink from '../QuickLink';

const ProductAccessRequestQuickLink = ({count}) => {
  const intl = useIntl();

  return (
    <QuickLink
      analyticsOptions={{
        analyticsAction: 'product-request-access',
      }}
      data-testid="quick-link-product-requests"
      Icon={<UserCheckedOutIcon size="M" />}
      label={intl.formatMessage({id: 'overview.quickLinks.productAccessRequests.title'}, {count})}
      onPress={() => goToProductRequests()}
    />
  );
};

ProductAccessRequestQuickLink.propTypes = {
  /**
   * The number of pending product access requests in the organization
   */
  count: PropTypes.number,
};

ProductAccessRequestQuickLink.get = async () => {
  if (canViewProductAccessRequests()) {
    const numberOfPendingProductRequests = await getNumberOfPendingProductRequests();
    if (numberOfPendingProductRequests > 0) {
      return Promise.resolve(
        <ProductAccessRequestQuickLink
          key="ProductAccessRequestQuickLink"
          count={numberOfPendingProductRequests}
        />
      );
    }
  }
  return Promise.reject(new Error('There are no product requests to review'));
};

export default ProductAccessRequestQuickLink;
