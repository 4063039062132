import {OverlayWait} from '@admin-tribe/binky-ui';
import {Button, Checkbox, TextField} from '@adobe/react-spectrum';
import AlertCircle from '@spectrum-icons/workflow/AlertCircle';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import AppConstants from 'common/services/AppConstants';
import ConfigurationStep from 'features/settings/components/configuration-step/ConfigurationStep';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import ScimTokenInput from 'features/settings/components/scim-token-input/ScimTokenInput';

import styles from './GoogleScimConfigurationSteps.pcss';

const GoogleScimConfigurationSteps = observer(({validateSteps, onValidate = () => {}}) => {
  const intl = useIntl();
  const directoryStoreContext = useDirectoryContext();
  const directoryStore = directoryStoreContext.directoryStore;
  const directorySyncStore = directoryStoreContext.directoryStore.sync;

  // Using useRef to get the value of this the first time and not recompute it on re-render.
  // If we re-compute we might get true after we create the saml IdP.
  const hasExistingSaml = useRef(!!directoryStore.samlIdp);

  // Create a SAML IdP when this component loads if one does not exist
  useEffect(() => {
    // if we have all we need, do nothing
    if (hasExistingSaml.current) {
      return;
    }

    directoryStore.createSamlIdp();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want this to only run once when the modal opens
  }, []);

  return (
    <OverlayWait isLoading={directorySyncStore.isLoading || directoryStore.isLoading} showContent>
      <ConfigurationStep
        title={intl.formatMessage({
          id: 'settings.sync.googleScimConfigurationSteps.authenticateStep',
        })}
      >
        <p>
          <FormattedMessage id="settings.sync.googleScimConfigurationSteps.login" />
        </p>
        <Button
          elementType="a"
          href={AppConstants.configuration.services.directorySync.googlePortalUrl}
          target="_blank"
          variant="secondary"
        >
          <FormattedMessage id="settings.sync.googleScimConfigurationSteps.goToGoogle" />
        </Button>
      </ConfigurationStep>

      <div data-test-id="saml-app-alert" styleName="saml-app-alert">
        <AlertCircle
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed more control than available props
          UNSAFE_className={styles['alert-icon']}
        />
        <p styleName="alert-text">
          {hasExistingSaml.current ? (
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.addSamlAppWarning" />
          ) : (
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.createdSamlIdpWarning" />
          )}
        </p>
      </div>

      <ConfigurationStep
        collapsible={hasExistingSaml.current}
        defaultCollapsed
        styleName="saml-app-step"
        title={intl.formatMessage({
          id: 'settings.sync.googleScimConfigurationSteps.addAdobeSamlApp',
        })}
      >
        <ol>
          <li>
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.selectAdobeFromList" />
          </li>
          <li>
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.copyAndPasteInformation" />
          </li>
          <li>
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.defineNameId" />
          </li>
        </ol>

        <div styleName="copy-to-clipboard-input">
          <TextField
            data-testid="acs-url-textfield"
            isDisabled
            label={intl.formatMessage({
              id: 'settings.sync.googleScimConfigurationSteps.acsUrlLabel',
            })}
            marginEnd="10px"
            value={directoryStore?.samlIdp?.acsUrl}
            width="85%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.sync.googleScimConfigurationSteps.copyAcsUrl',
            })}
            data-testid="acs-url-copy-btn"
            value={directoryStore?.samlIdp?.acsUrl}
          >
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>
        </div>

        <div styleName="copy-to-clipboard-input">
          <TextField
            data-testid="entity-id-textfield"
            isDisabled
            label={intl.formatMessage({
              id: 'settings.sync.googleScimConfigurationSteps.entityIdLabel',
            })}
            marginEnd="10px"
            value={directoryStore?.samlIdp?.entityId}
            width="85%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.sync.googleScimConfigurationSteps.copyEntityId',
            })}
            data-testid="entity-id-copy-btn"
            value={directoryStore?.samlIdp?.entityId}
          >
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>
        </div>
      </ConfigurationStep>

      <ConfigurationStep
        title={intl.formatMessage({
          id: 'settings.sync.googleScimConfigurationSteps.authorizeAndSetupProvisioningStep',
        })}
      >
        <p>
          <FormattedMessage id="settings.sync.googleScimConfigurationSteps.useTokenBelow" />
        </p>

        <ScimTokenInput authSourceId={directoryStore.directoryId} />

        <div styleName="copy-to-clipboard-input">
          <TextField
            data-testid="endpoint-url-field"
            isDisabled
            label={intl.formatMessage({
              id: 'settings.sync.googleScimConfigurationSteps.endpointUrl',
            })}
            marginEnd="10px"
            value={directorySyncStore.scimUrl}
            width="85%"
          />

          <CopyToClipboardButton
            data-testid="endpoint-url-copy-btn"
            value={directorySyncStore.scimUrl}
          >
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>
        </div>
      </ConfigurationStep>

      <ConfigurationStep
        title={intl.formatMessage({
          id: 'settings.sync.googleScimConfigurationSteps.enableAndActivateProvisioningStep',
        })}
      >
        <p>
          <FormattedMessage id="settings.sync.googleScimConfigurationSteps.enableAndActivateProvisioningDescription" />
        </p>
      </ConfigurationStep>

      {validateSteps && (
        <ConfigurationStep
          title={intl.formatMessage({
            id: 'settings.sync.googleScimConfigurationSteps.setupCompleteTitle',
          })}
        >
          <Checkbox onChange={onValidate}>
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.setupCompleteLabel" />
          </Checkbox>
          <p>
            <FormattedMessage id="settings.sync.googleScimConfigurationSteps.setupCompleteAgreement" />
          </p>
        </ConfigurationStep>
      )}
    </OverlayWait>
  );
});

GoogleScimConfigurationSteps.propTypes = {
  onValidate: PropTypes.func,
  validateSteps: PropTypes.bool,
};

export default GoogleScimConfigurationSteps;
