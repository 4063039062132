import {Product} from '@admin-tribe/binky';
import {getProductDisplayName} from '@admin-tribe/binky-ui';
import {Heading, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import ProductDetailsDrawerButtons from 'features/products/components/product-details-drawer/product-details-drawer-buttons/ProductDetailsDrawerButtons';

const ProductDetailsDrawerHeader = ({id, productOrProductGroup}) => {
  const intl = useIntl();

  const goToHelpLink = productOrProductGroup.links?.help_admin?.[0];
  const goToProductLink = productOrProductGroup.links?.product_admin?.[0];
  const showGoButtons = !!goToHelpLink || !!goToProductLink;

  return (
    <>
      <Heading id={id} level={2} marginBottom="size-125" marginTop="size-0">
        {getProductDisplayName(intl, productOrProductGroup)}
      </Heading>
      <View>
        <Text>{productOrProductGroup.longDescription}</Text>
      </View>
      {showGoButtons && (
        <ProductDetailsDrawerButtons
          goToHelpLink={goToHelpLink}
          goToProductLink={goToProductLink}
        />
      )}
    </>
  );
};

ProductDetailsDrawerHeader.propTypes = {
  /**
   * Unique identifier for this drawer header, must be passed in to connect aria
   * labelling with parent component
   */
  id: PropTypes.string.isRequired,
  /**
   * The product or product group with product name, description etc.
   */
  productOrProductGroup: PropTypes.oneOfType([
    PropTypes.instanceOf(Product),
    PropTypes.instanceOf(ProductGroupProductList),
  ]).isRequired,
};

export default ProductDetailsDrawerHeader;
