(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.common.image-banner:appImageBanner
   * @description A bannder that has an image, title, and description
   *
   * @param {String} description - The descritipn of the banner
   *    * @param {String} imageId - The id for the image, options are ['getting-started']
   * @param {String} title - The title of the banner
   *
   * @example <app-image-banner
   *            description="To get started with your products and licenses, please accept the terms and conditions."
   *            image-id="getting-started"
   *            title="Welcome to the Admin Console, Alex.">
   *          </app-image-banner>
   */
  angular.module('app.widgets.common.image-banner').component('appImageBanner', {
    bindings: {
      description: '@',
      imageId: '@',
      title: '@',
    },
    templateUrl: 'app/widgets/common/image-banner/image-banner.component.html',
  });
})();
