import React, {useState} from 'react';

import SophiaBannerView from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

const {SURFACE_ID} = SOPHIA_CONSTANTS;

/**
 * The Sophia Banner component
 */
const SophiaBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const onSophiaFetch = (sophiaCardList) => setShowBanner(sophiaCardList?.items?.length > 0);

  if (!showBanner) {
    return null;
  }

  return (
    <OverviewPod
      columnSpan={{
        base: 1,
        L: 2,
        M: 2,
      }}
      data-testid="sophia-banner"
    >
      <SophiaBannerView
        onSophiaFetch={onSophiaFetch}
        surfaceId={SURFACE_ID.ONE_CONSOLE_PROMOTION}
      />
    </OverviewPod>
  );
};

SophiaBanner.propTypes = {};

export default SophiaBanner;
