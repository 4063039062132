import {useMemo} from 'react';

import {
  getAssignedCancellableLicensesCount,
  getCancellableLicensesCount,
  getCancellationItemByOfferId,
  getCurrency,
  isProductWithMultiPriceOrDiscount,
  isUnassignedUser,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * @description A helper hook to calculate cancellation and pricing information to render a Product
 * line item.
 * @param {Object} options - set up options, described below
 * @param {Product} options.product - the Product for which to return associated users/licenses,
 * described by its id.
 * @param {String[]} options.selectedLicenses - the selected licenses for the product.
 * @returns {Object} result - Object composed of properties for the given product and licenses:
 *          {Number} result.cancellableQuantity - The original cancellable quantity
 *          {Object} result.currency - The currency object for rendering prices
 *          {Boolean} result.isMultiPrice - Whether the product has multiple prices
 *          {Number} result.pricePerUnit - Unit price for the product
 *          {Number} result.priceTotal - Subtotal price for selected licenses
 *          {Number} result.remainingDelegations - The original assigned users quantity
 *          {Number} result.unitsAssigned - Count of remaining assigned licenses
 *          {Number} result.unitsTotal - Count of remaining licenses
 */
const useCancellationItem = ({product, selectedLicenses}) => {
  const {error, isLoading, productsChange} = useProductsChangeContext();
  // Calculate initial data from Product
  const initial = useMemo(() => {
    const pricingData = product?.pricing || {};

    return {
      cancellableQuantity: getCancellableLicensesCount(product),
      currency: pricingData.currency,
      // The isMultiPrice flag will be set when multiple item prices or it has any discount applied
      isMultiPrice: isProductWithMultiPriceOrDiscount(product),
      // Initial pricePerUnit will be the unit price without discount from any item
      pricePerUnit: pricingData.items?.[0]?.priceDetails?.unitWithoutDiscount?.amountWithoutTax,
      priceTotal: pricingData.total?.amountWithoutTax,
      remainingDelegations: getAssignedCancellableLicensesCount(product),
    };
  }, [product]);

  return useMemo(() => {
    // If there is not selection use initial data from Product
    if (selectedLicenses.length === 0) {
      return {
        ...initial,
        error,
        isLoading,
        unitsAssigned: initial.remainingDelegations,
        unitsTotal: initial.cancellableQuantity,
      };
    }

    // Try to find cancellationItem using Products Change
    const cancellationItem = getCancellationItemByOfferId(productsChange, product.offerId);

    // If there is an error or is loading or there is not Products Change information for the
    // product, derive the units using initial data from Product and selected licenses
    if (error || isLoading || !cancellationItem) {
      const selectedAssignedLicenses = selectedLicenses.filter(
        (userId) => !isUnassignedUser(userId)
      );
      return {
        ...initial,
        error,
        isLoading,
        priceTotal: undefined, // The inital total price is not the correct one
        unitsAssigned: Math.max(0, initial.remainingDelegations - selectedAssignedLicenses.length),
        unitsTotal: Math.max(0, initial.cancellableQuantity - selectedLicenses.length),
      };
    }

    // If there is a selection and Products Change information for the product, use it
    const unitsTotal = cancellationItem.newQuantity;
    // If item has multi price or any discount applied, use the unit without discount price
    const pricePerUnit = initial.isMultiPrice
      ? initial.pricePerUnit
      : cancellationItem.newPrices?.unitPriceWithoutTax;
    const priceTotal = cancellationItem.newPrices?.totalWithoutTax;
    const contextItem = productsChange.response?.context?.cancellationItems?.find(
      ({productId}) => productId === product.id
    );
    const unitsAssigned = Math.max(
      0,
      initial.remainingDelegations - (contextItem?.users.length || 0)
    );
    const currency = initial.currency || getCurrency(productsChange);

    return {
      ...initial,
      ...cancellationItem,
      currency,
      error,
      isLoading,
      pricePerUnit,
      priceTotal,
      unitsAssigned,
      unitsTotal,
    };
  }, [error, initial, isLoading, product.id, product.offerId, productsChange, selectedLicenses]);
};

export default useCancellationItem;
