(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name ProductConfigurationUserList
   * @description product configuration user list model
   */
  angular
    .module('app.core.product.configurations.user-list')
    .factory('ProductConfigurationUserList', getProductConfigurationUserListFactory);

  /* @ngInject */
  function getProductConfigurationUserListFactory(
    $log,
    $q,
    $rootScope,
    _,
    jilProducts,
    MEMBER_EVENT,
    MEMBER_TECH_ACCOUNT_DOMAIN,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache,
    OrganizationUser
  ) {
    class ProductConfigurationUserList extends MemberList {
      /**
       * @class
       * @description Constructor for ProductConfigurationUserList model Objects.
       * @param {Object} options - options passed to the List constructor
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.LICENSECONFIGURATIONUSERLIST,
              memberClassRef: getMemberRef(),
              resource: jilProducts.licenseGroupUsers,
            },
            options
          )
        );
        function getMemberRef() {
          if (options.memberClassRef) {
            return options.memberClassRef;
          }
          return OrganizationUser;
        }
        this.filter.excludeDomain = MEMBER_TECH_ACCOUNT_DOMAIN;
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return super.key({
          groupId: this.configurationId,
          productId: this.productId,
        });
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @param {Object} options - options for refresh method of super class
       * @return {Promise} resolves to refreshed ProductConfigurationUserList model, else rejects with error message
       */
      refresh(options = {}) {
        return super
          .refresh(
            {
              groupId: this.configurationId,
              productId: this.productId,
            },
            options
          )
          .then((userList) => {
            $rootScope.$emit(MESSAGE.UPDATE.CONFIGURATIONUSERS.LIST, this.configurationId);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(MESSAGE.UPDATE.CONFIGURATIONUSERS.COUNT, this.pagination.itemCount);
            }
            return userList;
          });
      }

      /**
       * @description Method to save changes to user list to back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        return super.save({
          groupId: this.configurationId,
          productId: this.productId,
        });
      }

      /**
       * @description Method to update the role for a set of members in the list on the back-end.
       * @param {Array} members - members within this list to perform the operation on
       * @param {Number} role - the role to assign to those members for this list
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      setMemberProductRole(members, role) {
        const deferred = $q.defer();

        const operations = _.map(members, (member) => ({
          op: 'replace',
          path: `/${member.id}/${role.id}`,
        }));

        if (operations.length > 0) {
          this.$resolved = false;

          performBatchOperation(this.productId, this.configurationId, operations)
            .then(() => {
              _.forEach(members, (member) => {
                member.productRole = role;
                $rootScope.$emit(MEMBER_EVENT.UPDATE, member.id);
              });
              this.$resolved = true;
              deferred.resolve(this);
            })
            .catch((error) => {
              $log.error(
                'Failed to update role for selected users in configuration. Error: ',
                error
              );
              this.$resolved = true;
              deferred.reject(error);
            });
        } else {
          // no changes to save
          deferred.resolve();
        }

        return deferred.promise;
      }

      /**
       * @description Method to retrieve an existing ProductConfigurationUserList from the back-end.
       *
       * @param {String} productId ID of product the configuration is for
       * @param {String} configurationId ID of configuration to fetch users for
       * @param {Object} [options] options to JIL API call
       * @param {Number} [options.pageSize] number of configuration users to display per page
       *
       * @returns {ProductConfigurationUserList} new ProductConfigurationUserList Object reference
       */
      static get(productId, configurationId, options) {
        let model = new ProductConfigurationUserList(options);
        model.productId = productId;
        model.configurationId = configurationId;

        const cachedModel = modelCache.get(MODEL.LICENSECONFIGURATIONUSERLIST, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    MemberList.setupCache(MODEL.LICENSECONFIGURATIONUSERLIST, 10);

    return ProductConfigurationUserList;

    ////////////

    /**
     * @description Method to perform batch operations against users in a product.
     *
     * @param {String} productId ID of product the configuration is for
     * @param {String} groupId ID of configuration to perform operations on
     * @param {Array} operations list of operations to perform in batch
     * @returns {Promise} resolves if batch operation succeeds, else rejected with error message
     */
    function performBatchOperation(productId, groupId, operations) {
      const deferred = $q.defer();

      const options = {groupId, productId};

      jilProducts.licenseGroupUsers.batchOperation(
        options,
        operations,
        deferred.resolve,
        deferred.reject
      );

      return deferred.promise;
    }
  }
})();
