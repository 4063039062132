import PropTypes from 'prop-types';
import React from 'react';

import EditProductProfilePermissionsModalContent from './EditProductProfilePermissionsModalContent';
import {EditProductProfilePermissionsContextProvider} from './context/EditProductProfilePermissionsContext';
import {groupPropType} from './prop-types/configurationGroupsPropTypes';

const EditProductProfilePermissionsModal = ({isOpen = false, ...props}) =>
  isOpen && (
    <EditProductProfilePermissionsContextProvider {...props}>
      <EditProductProfilePermissionsModalContent />
    </EditProductProfilePermissionsContextProvider>
  );

EditProductProfilePermissionsModal.propTypes = {
  /**
   * The array of configuration groups to edit.
   */
  configurationGroups: PropTypes.arrayOf(groupPropType).isRequired,
  /**
   * An optional id of the configuration group to initially show, rather than the default of the first one in configurationGroups.
   */
  initialGroupId: PropTypes.string,
  /**
   * Whether the modal should be displayed.
   */
  isOpen: PropTypes.bool,
  /**
   * The name of the license group.
   */
  licenseGroupName: PropTypes.string.isRequired,
  /**
   * The callback function to run on close.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The callback function to run on save. Takes a single argument of the modified configuration groups.
   */
  onSave: PropTypes.func.isRequired,
  /**
   * The name of the product. This is used for the text of the help link on the PermissionsIncludeListForm.
   */
  productName: PropTypes.string.isRequired,
};

export default EditProductProfilePermissionsModal;
