/* eslint-disable complexity -- 1 over max */
import {SolutionGroupList, log} from '@admin-tribe/binky';
import {
  GoUrl,
  ModalContent,
  ModalDescription,
  ModalWizardStepList,
  ToggleSection,
} from '@admin-tribe/binky-ui';
import {Flex, Heading, Item, Picker, Text, TextArea, TextField, View} from '@adobe/react-spectrum';
import orderBy from 'lodash/orderBy';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ValidatedSearch from '../../../../../common/components/validated-search/ValidatedSearch';
import {useProductProfileModalContext} from '../AddProductProfileModalContext';

import styles from './AddProductProfileModalProfileDetailsPage.pcss';

const PRODUCT_NAME_MAX_LENGTH = 255;

const AddProductProfileModalProfileDetailsPage = () => {
  const intl = useIntl();
  const {
    currentProductProfile,
    product,
    selectedSingleProductCode,
    setIsValid,
    orgId,
    setModalError,
    setIsLoading,
    showStepList,
  } = useProductProfileModalContext();

  const [profileNameVariant, setProfileNameVariant] = useState();
  const [profileNameValidationMessage, setProfileNameValidationMessage] = useState();
  const [currentName, setCurrentName] = useState(currentProductProfile.name);
  const [currentPublicName, setCurrentPublicName] = useState(
    currentProductProfile.name === currentProductProfile.publicName
      ? ''
      : currentProductProfile.publicName
  );
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [currentSolutionGroup, setCurrentSolutionGroup] = useState(
    currentProductProfile.solutionGroup
  );

  const showPermissionGroupSection =
    product && product.isMarketingCloudProduct() && !product.isMigrationComplete();
  const showNotifications = !product.isSharedDeviceLicense();
  const showDisplayName = !product.isSharedDeviceLicense();

  React.useEffect(() => {
    let isCurrent = true;
    setIsLoading(true);

    async function fetchSolutionGroupList() {
      let solutionGroupList;

      try {
        solutionGroupList = await SolutionGroupList.get(product.id, orgId);
      } catch (error) {
        log.error('Failed to get solution groups. Error: ', error);
      }

      if (isCurrent) {
        setIsLoading(false);
        if (solutionGroupList && solutionGroupList.items.length > 0) {
          setPermissionGroups(orderBy(solutionGroupList.items, 'name'));
        }
      }
    }

    if (showPermissionGroupSection && !currentProductProfile.id) {
      fetchSolutionGroupList();
    } else {
      setIsLoading(false);
    }

    return () => {
      isCurrent = false;
    };
  }, [product.id, orgId, setIsLoading, currentProductProfile.id, showPermissionGroupSection]);

  React.useEffect(() => {
    if (currentProductProfile.name) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (currentProductProfile.name === currentProductProfile.publicName) {
      currentProductProfile.publicName = '';
    }
  }, [setIsValid, currentProductProfile]);

  const onProfileNameInputChange = (input) => {
    setModalError(null);
    let newValidationMessage, newVariant;
    const trimmed = input.trim();

    setCurrentName(input);
    if (input && input.length > 0 && trimmed.length > 0) {
      currentProductProfile.name = input;
      setCurrentName(input);
      setIsValid(true);
      currentProductProfile.publicName = currentPublicName;
    } else {
      newVariant = 'error';
      newValidationMessage = intl.formatMessage({
        id: 'products.addProductProfileModal.profileDetails.nameLabelRequiredError',
      });
      setIsValid(false);
    }
    setProfileNameVariant(newVariant);
    setProfileNameValidationMessage(newValidationMessage);
  };

  const onSolutionGroupSelectionChange = (selectedId) => {
    const selectedPermissionGroupObj = permissionGroups.find((item) => item.id === selectedId);
    setCurrentSolutionGroup(selectedPermissionGroupObj);
    currentProductProfile.solutionGroup = selectedPermissionGroupObj;
  };

  const productNamePrefix = product.getFulfillableItemNameByCode(selectedSingleProductCode);
  let productNameMaxLength = PRODUCT_NAME_MAX_LENGTH;
  if (selectedSingleProductCode) {
    // we concat the product name prefix and the profile with ': ', thus we need to deduct 2 from the max length
    productNameMaxLength = PRODUCT_NAME_MAX_LENGTH - productNamePrefix.length - 2;
  }
  return (
    <>
      <ModalDescription>
        {showStepList && <ModalWizardStepList />}
        <Text data-testid="description-text">
          {intl.formatMessage(
            {
              id: 'products.addProductProfileModal.profileDetails.description',
            },
            {
              goUrl: (str) => <GoUrl name="aac_manage_product">{str}</GoUrl>,
            }
          )}
        </Text>
      </ModalDescription>
      <ModalContent>
        <Heading data-testid="profile-details-subheading" level={3} marginTop="size-0">
          {intl.formatMessage({
            id: 'products.addProductProfileModal.profileDetails.title',
          })}
        </Heading>
        <Flex>
          {selectedSingleProductCode && !currentProductProfile.id && (
            <TextField
              data-testid="selected-single-product-name-field"
              isDisabled
              label={intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.productLabel',
              })}
              marginBottom="size-250"
              value={productNamePrefix}
            />
          )}
          <View
            marginStart={selectedSingleProductCode && !currentProductProfile.id && 'size-150'}
            width="100%"
          >
            <ValidatedSearch
              label={intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.nameLabel',
              })}
              marginBottom="size-250"
              maxLength={productNameMaxLength}
              onChange={onProfileNameInputChange}
              validationMessage={profileNameValidationMessage}
              value={currentName}
              variant={profileNameVariant}
            />
          </View>
        </Flex>
        {showDisplayName && (
          <TextField
            data-testid="display-name-field"
            label={intl.formatMessage({
              id: 'products.addProductProfileModal.profileDetails.displayNameLabel',
            })}
            marginBottom="size-250"
            maxLength={PRODUCT_NAME_MAX_LENGTH}
            necessityIndicator="label"
            onChange={(value) => {
              currentProductProfile.publicName = value;
              setCurrentPublicName(value);
            }}
            value={currentPublicName}
            width="50%"
          />
        )}
        <TextArea
          data-testid="description-field"
          defaultValue={currentProductProfile.description}
          label={intl.formatMessage({
            id: 'products.addProductProfileModal.profileDetails.descriptionLabel',
          })}
          necessityIndicator="label"
          onChange={(value) => {
            currentProductProfile.description = value;
          }}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set classes
          UNSAFE_className={styles.textarea}
          width="100%"
        />
        {showPermissionGroupSection && (permissionGroups.length > 0 || currentSolutionGroup) && (
          <>
            <Heading level={3} marginTop="size-300">
              {intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.permissionGroupTitle',
              })}
            </Heading>
            {!currentProductProfile.id && (
              <Picker
                data-testid="permission-group-picker"
                items={permissionGroups}
                label={intl.formatMessage({
                  id: 'products.addProductProfileModal.profileDetails.permissionGroupLabel',
                })}
                necessity="optional"
                necessityIndicator="label"
                onSelectionChange={onSolutionGroupSelectionChange}
                placeholder={intl.formatMessage({
                  id: 'products.addProductProfileModal.profileDetails.permissionGroupPlaceholder',
                })}
                selectedKey={currentSolutionGroup && currentSolutionGroup.id}
              >
                {permissionGroups.map((group) => (
                  <Item key={group.id}>{group.name}</Item>
                ))}
              </Picker>
            )}
            {currentProductProfile.id && (
              <Picker
                data-testid="selected-permission-group-picker"
                isDisabled
                selectedKey={currentSolutionGroup.id}
              >
                <Item key={currentSolutionGroup.id}>{currentSolutionGroup.name}</Item>
              </Picker>
            )}
          </>
        )}
        {showNotifications && (
          <>
            <Heading level={3} marginTop="size-300">
              {intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.notificationTitle',
              })}
            </Heading>
            <ToggleSection
              defaultChecked={currentProductProfile.notifications}
              onToggle={(value) => {
                currentProductProfile.notifications = value;
              }}
              toggleDescription={intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.notifyToggleDescription',
              })}
              toggleLabel={intl.formatMessage({
                id: 'products.addProductProfileModal.profileDetails.notifyToggleLabel',
              })}
            />
          </>
        )}
      </ModalContent>
    </>
  );
};

export default AddProductProfileModalProfileDetailsPage;
/* eslint-enable complexity -- 1 over max */
