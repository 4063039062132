(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name UserGroupConfigurationsList
   * @description Model for the license configuration list view for user groups
   */
  angular
    .module('app.core.user-group.configurations-list')
    .factory('UserGroupConfigurationsList', getUserGroupConfigurationsList);

  /* @ngInject */
  function getUserGroupConfigurationsList(
    $log,
    $q,
    $rootScope,
    _,
    JIL_CONSTANTS,
    jilUserGroups,
    List,
    MEMBER_EVENT,
    MESSAGE,
    MODEL,
    modelCache,
    ProductConfiguration
  ) {
    class UserGroupConfigurationsList extends List {
      /**
       * @class
       * @description Method to create a new instance of a UserGroupConfigurationsList.
       *
       * @param {Number} pageSize - number of configs to display per page
       * @param {Array} configs - list of configs to display in this page
       */
      constructor(pageSize, configs) {
        super({
          cacheType: MODEL.USERGROUPPRODUCTCONFIGURATIONLIST,
          itemClassRef: ProductConfiguration,
          items: configs,
          pageSize,
          resource: jilUserGroups.userGroupLicenseGroups,
          sortExpression: JIL_CONSTANTS.SORT.NAME,
          sortOrder: JIL_CONSTANTS.ORDER.ASC,
        });
      }

      filterList(query) {
        this.filter.query = query;
      }

      key() {
        return super.key({userGroupId: this.groupId});
      }

      /**
       * @description Method to refresh the current model state against what is stored in the back-end.
       *
       * @return {Promise} resolves to refreshed UserGroupConfigurationsList model, else rejects with error message
       */
      refresh() {
        return super
          .refresh({
            userGroupId: this.groupId,
          })
          .then((userList) => {
            $rootScope.$emit(MESSAGE.UPDATE.USERGROUPLICENSECONFIGURATIONS.LIST, this.groupId);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(
                MESSAGE.UPDATE.USERGROUPLICENSECONFIGURATIONS.COUNT,
                this.pagination.itemCount
              );
            }
            return userList;
          });
      }

      /**
       * @description Method to save changes to configuration list to the back-end.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      save() {
        const deferred = $q.defer();
        this.$promise = deferred.promise;

        if (this.hasUnsavedChanges()) {
          const addOperations = _.map(this.addedItems, (config) => ({
            op: 'add',
            path: `/${config.id}/product/${config.product.id}`,
          }));
          const removeOperations = _.map(this.removedItems, (config) => ({
            op: 'remove',
            path: `/${config.id}/product/${config.product.id}`,
          }));
          const operations = _.concat(addOperations, removeOperations);

          if (operations.length > 0) {
            this.$resolved = false;

            this.resource.batchOperation(
              {
                userGroupId: this.groupId,
              },
              operations,
              () => {
                this.resetAddedItems();
                this.resetRemovedItems();
                this.$resolved = true;

                this.filter.lastQuery = this.filter.query;
                this.filter.query = '';
                this.refresh()
                  .then(() => {
                    deferred.resolve(this);
                  })
                  .catch(onError.bind(this));
              },
              onError.bind(this)
            );
          } else {
            // no changes to save
            deferred.resolve();
          }
        } else {
          // no changes to save
          deferred.resolve();
        }

        function onError(error) {
          $log.error('Failed to add/remove configs from list. Error: ', error);
          this.$resolved = true;
          deferred.reject(error);
        }

        return deferred.promise;
      }

      sortBy(property, desc) {
        this.sort.expression = _.toUpper(property);
        this.sort.order = desc ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;
      }

      /**
       * @description Method to instantiate UserGroupConfigurationsList
       *
       * @param {Object} options - options
       * @param {String} options.groupId - ID of the group to perform operations on
       * @param {Number} options.pageSize - page size for the list
       * @returns {UserGroupConfigurationsList} userGroupConfigurationList - new UserGroupConfigurationsList
       */
      static get(options = {}) {
        let model = new UserGroupConfigurationsList(options.pageSize);
        model.groupId = options.groupId;
        const cachedModel = modelCache.get(MODEL.USERGROUPPRODUCTCONFIGURATIONLIST, model.key());

        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }

        return model;
      }
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MEMBER_EVENT.CREATE, removeCachedLists);
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MEMBER_EVENT.DELETE, removeCachedLists);
    // eslint-disable-next-line angular/on-watch
    $rootScope.$on(MEMBER_EVENT.UPDATE, removeCachedLists);

    /**
     * @description Method to remove cached UserGroupConfigurationsList's
     *
     * @param {Event} event - The event which triggered the cache removal
     * @param {String} id - The id of the user being changed
     */
    function removeCachedLists(event, id) {
      const model = new UserGroupConfigurationsList();
      model.groupId = id;
      modelCache.remove(MODEL.USERGROUPPRODUCTCONFIGURATIONLIST, model.key());
    }

    // We register the cache size for this class
    modelCache.register(MODEL.USERGROUPPRODUCTCONFIGURATIONLIST, 10);

    return UserGroupConfigurationsList;
  }
})();
