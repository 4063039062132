/**
 * @description Transforms sophia card response. By default, Sophia returns backgroundFillColor
 *    with the format 0xXXXXXX, which needs to be transformed to #XXXXXX. If no backgroundFillColor
 *    exists, then return the original object.
 * @param {Object} card object from Sophia response containing unformatted backgroundFillColor.
 * @returns {Object} card with formatted backgroundFillColor.
 */
function transformBackgroundFillColorHexFormat(card) {
  return card?.backgroundFillColor
    ? Object.assign(card, {
        backgroundFillColor: replaceHexPrefix(card.backgroundFillColor),
      })
    : card;
}

/**
 * @description Transforms sophia card response
 * @param {Object} card object from Sophia response
 * @returns {Object} card with formatted eventData and backgroundFillColor
 */
function transformCard(card) {
  let newCard = transformCardEventData(card);
  newCard = transformBackgroundFillColorHexFormat(newCard, 'backgroundFillColor');

  return newCard;
}

/**
 * @description Transforms sophia card response eventData
 * @param {Object} card object from Sophia response
 * @returns {Object} card with formatted eventData
 */
function transformCardEventData(card) {
  let newCard = card;
  if (card?.eventData) {
    const parsedData = JSON.parse(card.eventData);
    newCard = Object.assign(card, {
      eventData: parsedData,
    });

    const eventColorAttributes = ['descriptionFontColor', 'headerFontColor'];
    eventColorAttributes.forEach((attribute) => {
      if (parsedData?.[attribute]) {
        const newEventData = Object.assign(parsedData, {
          [attribute]: replaceHexPrefix(parsedData[attribute]),
        });
        newCard = Object.assign(newCard, {
          eventData: newEventData,
        });
      }
    });
  }
  return newCard;
}

function replaceHexPrefix(attribute) {
  return attribute.replace('0x', '#');
}

export {transformBackgroundFillColorHexFormat, transformCard, transformCardEventData};
