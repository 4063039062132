/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.cart-total:appCartTotal
 *
 * @description The shopping cart total component used by direct teams.
 * @example
 *  <app-cart-total
 *     contract="$ctrl.contract"
 *     is-renewal="$ctrl.isRenewal"
 *     wait-promise="$ctrl.waitPromise">
 *  </app-cart-total>
 *
 * @binding {Contract} contract - the contract to show total for
 * @binding {Boolean} [isRenewal] - true when the component is used in the renewal workflow
 * @param {Promise} waitPromise since this is only used if a direct contract it is resolved with an object that
 *  contains the cart model and the totalSummary
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appCartTotal', {
    bindings: {
      contract: '<',
      isRenewal: '<?',
      waitPromise: '<',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/cart-total/cart-total.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    binkyContractPerTermPriceFilter,
    binkyContractPriceWithTaxFilter,
    binkyContractTaxRateFilter,
    binkyDateTimeFilter,
    feature,
    numberFilter,
    price,
    PURCHASE_WAITSTR
  ) {
    const vm = this;

    _.assign(vm, {$onChanges});

    function $onChanges() {
      vm.waitPromise
        .then(({totalSummary} = {}) => {
          if (totalSummary) {
            updateCartTotal(totalSummary);
          } else {
            hidePricing();
          }
        })
        .catch(hidePricing);
    }

    ////////

    function formatPriceByBillingFrequency(value, currency) {
      const formattedValue = price.format(value, currency);
      return vm.contract.isM2M()
        ? binkyContractPerTermPriceFilter(vm.contract, false, formattedValue)
        : // do not show billing frequency (/yr) for PUF teams
          formattedValue;
    }

    function formatStyledPriceWithTax({total, currency, totalIncludesTax} = {}) {
      const dummyPrice = _.uniqueId('dummyPrice_');
      const totalTaxStringWithDummyPrice = binkyContractPriceWithTaxFilter(
        vm.contract,
        totalIncludesTax,
        dummyPrice
      );
      const formattedPrice = price.format(total, currency);
      // binkyContractPriceWithTaxFilter doesn't allow the caller to style the price in the tax string.
      // In order to style the price, we pass in a dummy price and replace it with the formatted price with style.
      return _.replace(
        totalTaxStringWithDummyPrice,
        dummyPrice,
        `<span class="price">${formattedPrice}</span>`
      );
    }

    function getSubtext(nextInvoiceDate) {
      let subtext;
      if (vm.contract.isM2M()) {
        subtext = $translate.instant('widgets.purchase.cartTotal.starting', {
          date: binkyDateTimeFilter(nextInvoiceDate || vm.contract.getNextBillingDate(), {
            dateOnly: true,
          }),
        });
      } else if (vm.isRenewal) {
        // PUF in renewal
        subtext = $translate.instant('widgets.purchase.cartTotal.dueOn', {
          date: binkyDateTimeFilter(vm.contract.getAnniversaryDate(), {dateOnly: true}),
        });
      } else {
        // PUF not in renewal
        subtext = $translate.instant('widgets.purchase.cartTotal.dueNow');
      }
      return subtext;
    }

    function hidePricing() {
      if (vm.contract.isDrContract) {
        vm.totalTaxString = PURCHASE_WAITSTR;
      } else {
        _.assign(vm, {
          subtotalString: PURCHASE_WAITSTR,
          taxRateString: binkyContractTaxRateFilter(vm.contract, PURCHASE_WAITSTR),
          taxString: PURCHASE_WAITSTR,
          totalString: PURCHASE_WAITSTR,
        });
      }
    }

    function updateCartTotal(totalSummary) {
      const pricingSource =
        _.isEmpty(totalSummary.proration) || vm.contract.isM2M()
          ? totalSummary
          : totalSummary.proration;

      const totalIncludesTax = totalSummary.totalIncludesTax;
      const {currency, subtotal, tax, taxRate, total} = pricingSource;

      if (vm.contract.isDrContract) {
        vm.totalTaxString = formatStyledPriceWithTax({
          currency,
          total: totalIncludesTax ? total : subtotal,
          totalIncludesTax,
        });
      } else {
        const {proration} = totalSummary;
        _.assign(vm, {
          subtotalString: formatPriceByBillingFrequency(subtotal, currency),
          taxRateString: binkyContractTaxRateFilter(vm.contract, numberFilter(taxRate)),
          taxString: formatPriceByBillingFrequency(tax, currency),
          totalString: formatPriceByBillingFrequency(total, currency),
        });

        if (vm.contract.isM2M() && !_.isEmpty(proration)) {
          _.assign(vm, {
            proratedSubtext: $translate.instant(
              'widgets.purchase.cartTotal.messageFormat.proratedDays',
              {
                count: proration.days,
              },
              'messageformat'
            ),
            proratedTotalString: formatStyledPriceWithTax({
              currency: proration.currency,
              total: proration.total,
              totalIncludesTax: true, // Due now total should include tax
            }),
          });
        }
      }

      vm.subtext = getSubtext(totalSummary.nextInvoiceDate);
    }
  }
})();
