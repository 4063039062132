import {
  Cache,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  DomainList,
  IDP_STATUS,
  jilDirectories,
  log,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {canAddDomains} from 'core/directories/access/directoryAccess';
import {canViewValueDiscoveryTasksForEnterprise} from 'core/organizations/access/organizationAccess';
import auth from 'core/services/auth';
import {goToIdentitySettings} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

const directoryListCache = new Cache();
const DIRECTORY_LIST_CACHE_KEY = 'directoryList';

const getDirectoryCacheKey = ({orgId}) => `${DIRECTORY_LIST_CACHE_KEY}/${orgId}`;

/**
 * @async
 *
 * @description Method to fetch directory list
 *
 * @returns {Object} list of directories
 */
async function fetchDirectoryList() {
  try {
    // check the cache
    const cacheKey = getDirectoryCacheKey({
      orgId: rootStore.organizationStore.activeOrgId,
    });

    const cachedResponse = directoryListCache.get(cacheKey);
    if (cachedResponse) {
      return Promise.resolve(cachedResponse);
    }

    const response = await jilDirectories.getDirectories({
      orgId: rootStore.organizationStore.activeOrgId,
    });

    // store the response in cache
    directoryListCache.put(cacheKey, response.data);

    return response.data;
  } catch (error) {
    log.error('[ID][JIL] Error getting the list of directories', error);

    throw error;
  }
}

/**
 * @async
 *
 * @description Method to check if the current OrgId has domains
 *
 * @returns {Boolean} whether or not the current OrgId has domains
 */
async function hasDomains() {
  const domainList = await DomainList.get({orgId: rootStore.organizationStore.activeOrgId});
  return domainList.items?.length > 0;
}

/**
 * @description Method to check whether or not the given directory is configured.
 * Assumption as in https://git.corp.adobe.com/admin-tribe/binky/blob/master/src/core/directories/directory.service.js#L99-L110
 * that defaultIdp will be found in an non-empty idpList
 * Note: link may not be available in the future since src in @admin-tribe/binky would be removed soon
 *
 * @returns {Boolean} if the current directory is configured
 */
function isDirectoryConfigured(directory) {
  if (directory.status !== DIRECTORY_STATUS.ACTIVE) {
    return false;
  }

  if (directory.type === DIRECTORY_TYPE.TYPE3) {
    const defaultIdpId = directory.tenantInfo.imsTenantInfo.defaultIdp;
    const idpList = directory.tenantInfo.imsTenantInfo.idps;
    const defaultIdp = idpList.find((item) => item.id === defaultIdpId);
    return defaultIdp.status === IDP_STATUS.ACTIVE;
  }
  return true;
}

/**
 * This class creates a HVA card allowing the user to set up identity
 */
class SetUpIdentityHva extends HvaCard {
  /**
   * @async
   *
   * @description Method to fetch the SetUpIdentityHva
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @returns {Promise<SetUpIdentityHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (
      !canViewValueDiscoveryTasksForEnterprise() ||
      !auth.isUserOrgAdmin() ||
      (!canAddDomains() && !(await hasDomains())) // can create directory
    ) {
      return Promise.reject();
    }
    // @neculaes - Update this logic once the necessary accessors are available
    const directoryList = await fetchDirectoryList();
    if (
      directoryList.every(
        (item) =>
          item.type === DIRECTORY_TYPE.TYPE2E ||
          (!isDirectoryConfigured(item) &&
            item.ownershipStatus !== DIRECTORY_OWNERSHIP_STATUS.TRUSTED)
      )
    ) {
      return Promise.resolve([new SetUpIdentityHva({intl})]);
    }
    return Promise.reject();
  }

  /**
   * @description Method to create an SetUpIdentityHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @return {SetUpIdentityHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.setUpIdentity';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      goUrl: 'ac_identity',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/identity.svg',
        style: {width: '100px'},
      },
      id: 'hva-set-up-identity',
      onCTA: goToIdentitySettings,
      priorityOrder: HVA_ORDER.SET_UP_IDENTITY,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default SetUpIdentityHva;

// Exported to be used in unit tests
export {directoryListCache};
