import {LIST_DEFAULT_PAGE_SIZE, compareDates} from '@admin-tribe/binky';
import {TableConstants} from '@admin-tribe/binky-ui';
import {useCollator} from '@adobe/react-spectrum';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useEffect, useState} from 'react';

/**
 * @description A hook that manages pagination state for TableSection. Takes a
 *   full list of items and paginates the items based on sort, search, and page
 *   size parameters. Note: Items passed in can be undefined or an empty list,
 *   hook is able to handle either scenario
 * @param {String} defaultSortOrder - default table sorting option on page load,
 *   default will be ascending if none provided
 * @param {Array<Object>} items - table list items array. May be undefined or an
 *   empty list, hook is able to manage either state
 * @param {String} sortByKey - default key by which table should be sorted on
 *   page load
 * @returns sorted object with table items, current page number, page size,
 *   total pages and action for sorting/searching/pagination
 */
const useTableSectionPagination = ({
  defaultSortOrder = TableConstants.SORT_DIRECTION.ASCENDING,
  items,
  sortByKey,
}) => {
  const collator = useCollator();
  const [currentPageSize, setCurrentPageSize] = useState(LIST_DEFAULT_PAGE_SIZE);
  const [currentSortedList, setCurrentSortedList] = useState(items);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentTotalPages, setCurrentTotalPages] = useState(
    calcTotalPages(items, currentPageSize)
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  /** function to sort list in ascending or descending order */
  const sorter = useCallback(
    ({id, sortedOrder, sortList}) => {
      if (!sortList) {
        return []; // list is undefined, so set to empty list internally
      }
      return sortList.sort((a, b) => {
        const first = a[id];
        const second = b[id];
        let cmp;
        if (first instanceof Date) {
          cmp = compareDates(first, second);
        } else {
          cmp = collator.compare(first, second);
        }
        if (sortedOrder === TableConstants.SORT_DIRECTION.DESCENDING) {
          cmp *= -1;
        }
        return cmp;
      });
    },
    [collator]
  );

  /** to sort list on page load */
  useEffect(() => {
    const sortedList = sorter({
      id: sortByKey,
      sortedOrder: defaultSortOrder,
      sortList: items,
    });
    setCurrentSortedList(sortedList);
    setCurrentItems(sortedList.slice(0, currentPageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not added currentPageSize to prevent data reset on changing page size
  }, [defaultSortOrder, items, sorter, sortByKey]);

  /** to set current page items, total pages */
  const setCurrentData = (list, pageNumber, pageSize) => {
    const indexOfLastRecord = pageNumber * pageSize;
    const indexOfFirstRecord = indexOfLastRecord - pageSize;
    // Records to be displayed on the current page
    const currentRecords = list.slice(indexOfFirstRecord, indexOfLastRecord);
    setCurrentItems(currentRecords);
    setCurrentTotalPages(calcTotalPages(list, pageSize));
  };

  /** for previous and next buttons */
  const goToPage = useCallback(
    (page) => {
      setCurrentPageNumber(page);
      setCurrentData(currentSortedList, page, currentPageSize);
    },
    [currentSortedList, currentPageSize]
  );

  /** function to search list items */
  const onSearch = useCallback(
    ({value}) => {
      const searchList = items.filter((item) =>
        item[sortByKey].toLowerCase().includes(value.toLowerCase())
      );
      const sortedList = value ? searchList : items;
      setCurrentSortedList(sortedList);
      setCurrentPageNumber(1);
      setCurrentData(sortedList, 1, currentPageSize);
    },
    [currentPageSize, items, sortByKey]
  );

  /** function to sort list */
  const onSortBy = useCallback(
    ({id, sortedOrder}) => {
      // sort list
      const sortByList = sorter({id, sortedOrder, sortList: currentSortedList});
      setCurrentSortedList(sortByList);
      setCurrentPageNumber(1);
      setCurrentData(sortByList, 1, currentPageSize);
    },
    [currentPageSize, currentSortedList, sorter]
  );

  /** function to change page size */
  const onPageSizeChange = useCallback(
    (pageSize) => {
      setCurrentTotalPages(calcTotalPages(currentSortedList, pageSize));
      setCurrentPageSize(pageSize);
      setCurrentPageNumber(1);
      setCurrentItems(currentSortedList.slice(0, pageSize));
    },
    [currentSortedList]
  );

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          onSearch(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          onSortBy(payload);
          break;
        default:
          // Do nothing
          break;
      }
    },
    [onSearch, goToPage, onPageSizeChange, onSortBy]
  );

  return {
    currentItems,
    currentPageNumber,
    currentPageSize,
    currentTotalPages,
    onTableSectionChange,
  };
};

function calcTotalPages(items, pageSize) {
  const itemCount = items?.length ?? 0;
  return Math.ceil(itemCount / pageSize) || 1;
}

export default useTableSectionPagination;
