/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.integrations:appIntegrationsDrawer
 *
 * @description The drawer for the integrations page.
 *
 * @binding {ApiIntegrationUser} user
 */
(function () {
  angular.module('app.widgets.integrations').component('appIntegrationDetailsDrawer', {
    bindings: {
      pageContext: '<',
      user: '<',
    },
    controller,
    templateUrl:
      'app/widgets/integrations/integration-details-drawer/integration-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $filter,
    $q,
    _,
    ADD_USER_MODAL_TABS,
    AnalyticsEvent,
    ApiIntegrationUser,
    configurationReady,
    INTEGRATION_DETAILS_DRAWER_ID,
    INTEGRATION_DETAILS_DRAWER_ADD_USER_MODAL_ID,
    PAGE_TARGET_TYPE,
    panelManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      onEditProducts,
      triggerAnalytics,
    });

    function $onInit() {
      _.assign(vm, {
        addUserModalId: INTEGRATION_DETAILS_DRAWER_ADD_USER_MODAL_ID,
        drawerId: INTEGRATION_DETAILS_DRAWER_ID,
      });
      vm.conf = configurationReady.getConfig();
    }

    function $onChanges(changesObj) {
      vm.user = _.get(changesObj, 'user.currentValue');
      if (vm.user) {
        vm.fetchedUser = ApiIntegrationUser.get({userId: vm.user.id});

        if (_.has(vm.conf, 'services.adobeioConsole.url')) {
          vm.linkToAdobeIO = `${vm.conf.services.adobeioConsole.url}/integrations/${vm.user.integrationOrgId}/${vm.user.integrationId}/overview`;
        }
        vm.displayUserEditModal = true;
        vm.drawerTitle = $filter('binkyMemberNameLabel')(vm.user);
      } else if (_.get(changesObj, 'user.previousValue')) {
        closeDrawer();
        vm.displayUserEditModal = false;
      }
    }

    function onEditProducts() {
      panelManager.open(INTEGRATION_DETAILS_DRAWER_ADD_USER_MODAL_ID, {
        defaultTab: ADD_USER_MODAL_TABS.PRODUCTS,
        targetTab: PAGE_TARGET_TYPE.INTEGRATION,
      });
    }

    function triggerAnalytics() {
      AnalyticsEvent.dispatch({
        componentMethod: 'goToAdobeIOLink',
        componentMethodType: 'click',
        componentName: 'appIntegrationDetailsDrawer',
      });
    }

    /////////////////

    function closeDrawer() {
      panelManager.close(vm.drawerId);
    }
  }
})();
