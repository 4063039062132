import {Content, Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import HowToDeployButton from 'common/components/how-to-deploy-button/HowToDeployButton';

/**
 * The IllustratedMessage to show when the no devices assigned.
 */
const NoDevicesAssigned = () => {
  const intl = useIntl();

  return (
    <View paddingY="size-800">
      <IllustratedMessage>
        <Heading level="2">
          {intl.formatMessage({id: 'products.details.deactivated.devices.noDevicesAssigned.title'})}
        </Heading>
        <Content>
          <View marginBottom="size-200">
            {intl.formatMessage({
              id: 'products.details.deactivated.devices.noDevicesAssigned.description',
            })}
          </View>
          <HowToDeployButton key="deployButton" />
        </Content>
      </IllustratedMessage>
    </View>
  );
};
NoDevicesAssigned.displayName = 'NoDevicesAssigned';

export default NoDevicesAssigned;
