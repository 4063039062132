import {
  IllustratedMessage,
  NoSearchResultsIllustratedMessage,
  ServerErrorIllustratedMessage,
} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

const EmptyStateMessage = observer(() => {
  const {directoryStore} = useDirectoryContext();
  const intl = useIntl();

  if (directoryStore.domains.hasLoadingError) {
    return <ServerErrorIllustratedMessage />;
  }

  // No search results so use Search illustration.
  if (directoryStore.domains.tableStore.searchQuery) {
    return (
      <NoSearchResultsIllustratedMessage
        content={intl.formatMessage({
          id: 'settings.directoryDomains.table.empty.noSearchResults.content',
        })}
        heading={intl.formatMessage({
          id: 'settings.directoryDomains.table.empty.noSearchResults.heading',
        })}
      />
    );
  }

  // No domains exist so use 'Not found' illustration.
  return (
    <IllustratedMessage
      heading={intl.formatMessage({id: 'settings.directoryDomains.table.empty.noDomains.heading'})}
    />
  );
});

export default EmptyStateMessage;
