(function () {
  /**
   * @deprecated ported to src2/app/common/components/migrate-device-license
   */
  angular
    .module('app.widgets.contracts.migrations')
    .component('appMigrationWizardProcessingPanel', {
      bindings: {
        contractMigrationPromise: '<',
      },
      controller,
      templateUrl:
        'app/widgets/contracts/migrations/team-to-enterprise-migration-wizard-panels/migration-wizard-processing-panel.component.html',
    });

  /* @ngInject */
  function controller(
    _,
    $state,
    panelManager,
    t2eMigrationManager,
    TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      isT2E: t2eMigrationManager.isT2EClean(),
      onManageUsersButtonClick,
      onSetupIdentityButtonClick,
    });

    /////////////////

    function $onInit() {
      if (vm.contractMigrationPromise) {
        vm.contractMigrationPromise.then(showSuccessBanner).catch(showErrorBanner);
      } else {
        showErrorBanner();
      }
    }

    function showSuccessBanner() {
      vm.showSuccessBanner = true;
    }

    function showErrorBanner() {
      vm.showErrorBanner = true;
    }

    function onSetupIdentityButtonClick() {
      $state.go('settings.identity');
      panelManager.close(TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID);
    }

    function onManageUsersButtonClick() {
      $state.go('users.user-groups');
      panelManager.close(TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID);
    }
  }
})();
