import {Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {groupPropType} from '../edit-product-profile-permissions/prop-types/configurationGroupsPropTypes';
import {
  PROFILE_PERMISSIONS_GROUP_TYPE,
  PROFILE_PERMISSIONS_SUB_CONTENT_TYPE,
} from '../profilePermissionsConstants';

const ProfilePermissionSummary = ({group}) => {
  const intl = useIntl();

  const getPermissionSummaryForIncludeType = (content) => {
    const allSelected =
      group.type === PROFILE_PERMISSIONS_GROUP_TYPE.EXPANDABLE_SELECT
        ? content.allSelected
        : group.allSelected;

    if (allSelected) {
      return intl.formatMessage({id: 'products.productProfilePermissions.summary.autoIncluded'});
    }

    if (content.values.length === 0) {
      return intl.formatMessage({id: 'products.productProfilePermissions.summary.noneConfigured'});
    }

    const includedCount = content.values.filter((value) => value.included).length;

    return intl.formatMessage(
      {id: 'products.productProfilePermissions.summary.includedMessage'},
      {includedCount, total: content.values.length}
    );
  };

  const getPermissionSummaryForSelectType = (content) => {
    const selectedValue = content.values.find((value) => value.selected);
    return selectedValue === undefined ? '' : selectedValue.name;
  };

  const getPermissionSummaryForTextFormType = (content) => {
    const count = content.values.length;
    if (count === 1) {
      return content.values[0].text;
    }
    return intl.formatMessage(
      {id: 'products.productProfilePermissions.summary.textFormMessage'},
      {count}
    );
  };

  const getPermissionSummary = () => {
    const isDisabled =
      group.canDisable !== undefined &&
      group.canDisable &&
      group.disabled !== undefined &&
      group.disabled;

    if (isDisabled) {
      return intl.formatMessage({id: 'products.productProfilePermissions.summary.off'});
    }
    if (group.content === undefined || group.content.length === 0) {
      return intl.formatMessage({id: 'products.productProfilePermissions.summary.emptySummary'});
    }
    const content =
      group.type === PROFILE_PERMISSIONS_GROUP_TYPE.EXPANDABLE_SELECT
        ? group.content.find((currentContent) => currentContent.selected === true)
        : group.content[0];
    switch (content.type) {
      case PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.INCLUDE:
        return getPermissionSummaryForIncludeType(content);
      case PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.SELECT:
        return getPermissionSummaryForSelectType(content);
      default:
        // PROFILE_PERMISSIONS_SUB_CONTENT_TYPE.TEXT_FORM
        return getPermissionSummaryForTextFormType(content);
    }
  };

  return <Text>{getPermissionSummary()}</Text>;
};

ProfilePermissionSummary.propTypes = {
  /**
   * The group (also called the content) of a section.
   */
  group: groupPropType.isRequired,
};

export default ProfilePermissionSummary;
