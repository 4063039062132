import {OrganizationUser} from '@admin-tribe/binky';

/**
 * Class which represents an Api integration (credentials) user.
 * This is an OrganizationUser filtered on MEMBER_TECH_ACCOUNT_DOMAIN with
 * some additional integration data added.
 *
 * Note that the model's products is an Array of sparse Product objects, each with only an 'id'.
 */
class ApiIntegrationUser extends OrganizationUser {
  /**
   * @description Method to add integration data to this user.
   * @params {Array<ApiIntegration>} integrations list of API integrations for this organization
   */
  addIntegration(integrations) {
    // Look thru the array of organization integrations to see if there is one for this integraton user.
    const userIntegration = integrations?.find((item) => item.technicalAccountId === this.id);

    if (userIntegration) {
      this.apiKey = userIntegration.apiKey;
      this.createdDate = userIntegration.createdDate;
      this.description = userIntegration.description;
      this.integrationId = userIntegration.id;
      this.integrationOrgId = userIntegration.orgId;
      this.firstName = userIntegration.name; // the table shows this in the 'NAME' column
      this.lastModifiedDate = userIntegration.lastModifiedDate;
      this.name = userIntegration.name;
    } else {
      this.name = this.email;
    }
  }

  /**
   * @description Method to retrieve the display name of this user.
   *
   * @returns {String} Name of the integration user
   */
  getDisplayName() {
    return this.name;
  }
}

export default ApiIntegrationUser;
