(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketAttachmentsFormSection
   *
   * @description The attachments section for a support form which contains a
   *   dragAndDropFile area.
   * @param {Array} attachments - Array reference to store attachments in
   * @param {String} headerKey - the key for the header for this section.
   */
  angular.module('app.widgets.support').component('appSupportTicketAttachmentsFormSection', {
    bindings: {
      attachments: '<',
      headerKey: '@',
    },
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-attachments-form-section/support-ticket-attachments-form-section.component.html',
  });
})();
