(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    auth,
    feature,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    HVA_ORDER,
    HvaBuildAction,
    HvaCard,
    onesieSrc2,
    OrganizationManager,
    productAccess,
    quickAssignGlobalModal,
    SVG_CLASS
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          priorityOrder: HVA_ORDER.START_ASSIGN_LICENSES,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin()) {
          const getProductsPromise = OrganizationManager.getProductsForActiveOrg().$promise;
          const canViewQuickAssignModalPromise = productAccess.canViewQuickAssignModal();

          return $q.all([getProductsPromise, canViewQuickAssignModalPromise]).then((results) => {
            const productList = results[0];
            const canViewQuickAssignModal = results[1];

            const provisionedLicenses = productList.getSeatBasedTotalProvisionedQuantity();
            if (canViewQuickAssignModal && provisionedLicenses === 0) {
              return [startAssigningLicensesHVACard()];
            }
            return $q.reject();
          });
        }
        return $q.reject();
      }
    }

    /* Private functions */

    // Returns HVA card that encourages admin to START assigning licenses
    function startAssigningLicensesHVACard() {
      const namespace = 'overview.highValueActionArea.setUpTasks.startAssigningLicenses';
      return new HvaCard({
        ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
        description: $translate.instant(`${namespace}.description`),
        header: $translate.instant(`${namespace}.header`),
        id: 'hva-start-assigning-licenses',
        onCTAClick: () => {
          if (feature.isEnabled('temp_standalone_quick_assign')) {
            openStandAloneQuickAssignModal();
          } else {
            quickAssignGlobalModal.open();
          }
        },
        sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
        svgClass: SVG_CLASS.ASSIGN_LICENSES,
      });
    }

    function openStandAloneQuickAssignModal() {
      globalModalsManager.open({
        componentBindings: [
          {
            attributeName: 'component',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: onesieSrc2.products.components.QuickAssignModalWrapper,
          },
        ],
        componentName: 'binkyReactContainer',
      });
    }
  }
})();
