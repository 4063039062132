(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $state,
    $translate,
    auth,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    SVG_CLASS,
    OrganizationManager
  ) {
    // store the original filter
    const MINIMUM_SEAT_REQUIRED = 30;
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.CREATE_PACKAGES,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback() {
        if (auth.isUserOrgAdmin() || auth.isUserDeploymentAdmin()) {
          const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.createPackages';
          const productList = OrganizationManager.getProductsForActiveOrg();
          const meetMinimumSeatRequired =
            productList.getSeatBasedAssignableLicenseCount() >= MINIMUM_SEAT_REQUIRED;
          if (meetMinimumSeatRequired && productList.hasPackageSupport()) {
            return $q.resolve([
              new HvaCard({
                ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                description: $translate.instant(`${namespace}.description`),
                dismissOnCTAClick: true,
                header: $translate.instant(`${namespace}.header`),
                id: 'hva-create-packages',
                isDismissible: true,
                onCTAClick: () => {
                  $state.go('packages.all-packages');
                },
                sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                svgClass: SVG_CLASS.CREATE_PACKAGES,
              }),
            ]);
          }
          return $q.reject();
        }
        return $q.reject();
      }
    }
  }
})();
