import {Checkbox, CheckboxGroup, Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const EduApplicationSelection = ({
  defaultSelectedProducts,
  products,
  label,
  onChange,
  isDisabled,
  defaultValidationState,
  ...styleProps
}) => {
  const intl = useIntl();

  const isAtLeastOneProductAvailable = products.some((product) => product.deployed);
  const errorMessageId = isAtLeastOneProductAvailable
    ? 'settings.sync.eduSyncConfirmationModal.errors.noProductsSelected'
    : 'settings.sync.eduSyncConfirmationModal.errors.noProductsAvailable';

  const [validationState, setValidationState] = useState(
    defaultValidationState || (isAtLeastOneProductAvailable ? 'valid' : 'invalid')
  );
  const onItemSelected = (selectedItems) => {
    if (selectedItems.length === 0) {
      setValidationState('invalid');
    } else {
      setValidationState('valid');
    }

    onChange(selectedItems);
  };

  return (
    <Flex direction="column" {...styleProps}>
      <Text>
        <strong>
          {label || (
            <FormattedMessage id="settings.sync.eduSyncConfirmationModal.labels.products" />
          )}
        </strong>
      </Text>
      <CheckboxGroup
        aria-label={intl.formatMessage({
          id: 'settings.sync.eduSyncConfirmationModal.ariaLabels.products',
        })}
        defaultValue={defaultSelectedProducts}
        errorMessage={
          (validationState === 'invalid' || !isAtLeastOneProductAvailable) &&
          intl.formatMessage({
            id: errorMessageId,
          })
        }
        isDisabled={isDisabled}
        isRequired
        onChange={onItemSelected}
        validationState={validationState}
      >
        {products.map((productIntegration) => (
          <Checkbox
            key={productIntegration.product}
            isDisabled={!productIntegration.deployed}
            value={productIntegration.product}
          >
            {productIntegration.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Flex>
  );
};

EduApplicationSelection.propTypes = {
  /**
   * Selected products of the provided sync which were already set up
   */
  defaultSelectedProducts: PropTypes.arrayOf(PropTypes.string),
  /**
   * The component's initial validation state
   */
  defaultValidationState: PropTypes.oneOf(['valid', 'invalid']),
  /**
   * Whether the application selection is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Label for application selection
   */
  label: PropTypes.string,
  /**
   * Callback to change selected products in the modals
   */
  onChange: PropTypes.func,
  /**
   * Selected products of the provided sync
   */
  products: PropTypes.arrayOf(
    PropTypes.shape({
      deployed: PropTypes.bool,
      name: PropTypes.string,
      product: PropTypes.string,
    })
  ),
};

export default EduApplicationSelection;
