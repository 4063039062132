import {Organization} from '@admin-tribe/binky';
import {ActionButton, DialogTrigger} from '@adobe/react-spectrum';
import EditIcon from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import EditOrgNameModal from '../modal/EditOrgNameModal';

/**
 * The button to open the modal used to update the organization's name.
 */
const EditOrgNameButton = ({onSuccess, org}) => {
  const intl = useIntl();

  return (
    <DialogTrigger>
      <ActionButton
        aria-label={intl.formatMessage({
          id: 'settings.consoleSettings.organization.edit',
        })}
        data-testid="edit-org-name-btn"
        isQuiet
      >
        <EditIcon />
      </ActionButton>
      {(close) => <EditOrgNameModal onClose={close} onSuccess={onSuccess} org={org} />}
    </DialogTrigger>
  );
};

EditOrgNameButton.propTypes = {
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func,
  /**
   * The Organization whose name is being updated.
   */
  org: PropTypes.instanceOf(Organization).isRequired,
};

export default EditOrgNameButton;
