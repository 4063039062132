import {Grid, Text, View} from '@adobe/react-spectrum';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

import styles from '../PackageDetails.pcss';

import {
  getFrlOnlineActivationType,
  isAccUnsupressed,
  isDeviceInformationShared,
  showAUSSTOption,
  showAppsPanelOption,
  showAutoAppUpdateOption,
  showBetaAppDownloadOption,
  showBrowserBasedAuthOption,
  showCustomDNSAddress,
  showDisableUpdateOption,
  showExtenstionsOption,
  showInstallDirectoryOption,
  showOptionsTab,
  showRUMOption,
  showSelfServePluginsOption,
} from './PackageDetailsOptionsUtils';

const PackageDetailsOptions = () => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);

  const detailsOptionsMap = [
    {
      field: 'disableAppTrials',
      isVisible: attributes.disableAppTrial,
      param: {},
      testId: 'disable-app-trial',
    },
    {
      field: 'deviceInformationShared',
      isVisible: isDeviceInformationShared(attributes),
      param: {},
      testId: 'device-info-shared',
    },
    {
      field: 'enableFixedDnsLicenseServer',
      isVisible: getFrlOnlineActivationType(attributes) === CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS,
      param: {},
      testId: 'enable-fixed-dns-license-server',
    },
    {
      field: 'enableFixedIpLicenseServer',
      isVisible: getFrlOnlineActivationType(attributes) === CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP,
      param: {},
      testId: 'enable-fixed-ip-license-server',
    },
    {
      extraField: attributes.package?.frlPackagingInfo?.profileServerUrl,
      field: 'enableCustomDnsLicenseServer',
      isVisible: showCustomDNSAddress(attributes),
      testId: 'enable-custom-dns-license-server',
    },
    {
      field: 'appsPanel',
      isVisible: showAppsPanelOption(attributes),
      param: {
        appsPanel: attributes.appPanelEnabled,
      },
      testId: 'apps-panel',
    },
    {
      field: 'adminPrivileges',
      isVisible: showAppsPanelOption(attributes),
      param: {
        adminPrivileges: attributes.accAdminPrivileged,
      },
      testId: 'admin-privileges',
    },
    {
      field: 'accDisableAutoAppUpdate',
      isVisible: showAutoAppUpdateOption(attributes),
      param: {
        accDisableAutoAppUpdate: attributes.accDisableAutoAppUpdate,
      },
      testId: 'acc-disable-auto-app-update',
    },
    {
      field: 'selfServePluginsEnabled',
      isVisible: showSelfServePluginsOption(attributes),
      param: {
        selfServePluginsEnabled: attributes.selfServePluginsEnabled,
      },
      testId: 'self-serve-plugins-enabled',
    },
    {
      field: 'enableBetaAppDownload',
      isVisible: showBetaAppDownloadOption(attributes),
      param: {
        enableBetaAppDownload: attributes.enableBetaAppDownload,
      },
      testId: 'enable-beta-app-download',
    },
    {
      field: 'coreSyncSupress',
      isVisible: isAccUnsupressed(attributes),
      param: {
        coreSyncSupress: attributes.coreSyncSupress,
      },
      testId: 'core-sync-supress',
    },
    {
      field: 'rum',
      isVisible: showRUMOption(attributes),
      param: {
        rum: attributes.rumEnabled,
      },
      testId: 'rum',
    },
    {
      field: 'extensions',
      isVisible: showExtenstionsOption(attributes),
      param: {
        extensions: attributes.extensionsEnabled,
      },
      testId: 'extensions',
    },
    {
      field: 'assetLink',
      isVisible: false,
      param: {
        assetLink: attributes.enableAalExtension,
      },
      testId: 'asset-link',
    },
    {
      field: 'ausstXmlIncluded',
      isVisible: showAUSSTOption(attributes),
      param: {
        ausstXmlIncluded: attributes.ausstXmlIncluded,
      },
      testId: 'ausst-xml-included',
    },
    {
      field: 'userInstallDirectoryIncluded',
      isVisible: showInstallDirectoryOption(attributes),
      param: {
        userInstallDirectoryIncluded: attributes.userInstallDirectoryIncluded,
      },
      testId: 'user-install-directory-included',
    },
    {
      field: 'browserBasedAuthEnabled',
      isVisible: showBrowserBasedAuthOption(attributes),
      param: {
        browserBasedAuthEnabled: attributes.browserBasedAuthEnabled,
      },
      testId: 'browser-based-auth-enabled',
    },
    {
      field: 'updateEnabled',
      isVisible: showDisableUpdateOption(attributes),
      param: {
        updateEnabled: attributes.updateEnabled,
      },
      testId: 'updates-enabled',
    },
  ];

  return (
    <View>
      {showOptionsTab(attributes) && (
        <Grid
          areas={['field value']}
          columns={['1fr', '3fr']}
          data-testid="options"
          gap="size-500"
          marginStart="size-150"
          marginTop="size-350"
          rows={['auto']}
        >
          <Text
            gridArea="field"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling
            UNSAFE_className={styles['package-details-field']}
          >
            {intl.formatMessage({id: 'packages.summaryDrawerBody.packageDetails.options'})}
          </Text>

          <Text
            gridArea="value"
            marginStart="size-150"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- font styling
            UNSAFE_className={styles['package-details-value']}
          >
            <ul className={styles['package-details-container']}>
              {detailsOptionsMap.map(
                (detailsOption) =>
                  detailsOption.isVisible && (
                    <li key={detailsOption.testId} data-testid={detailsOption.testId}>
                      {intl.formatMessage(
                        {
                          id: `packages.summaryDrawerBody.packageDetails.${detailsOption.field}`,
                        },
                        detailsOption.param
                      )}
                      {detailsOption.extraField || ''}
                    </li>
                  )
              )}
            </ul>
          </Text>
        </Grid>
      )}
    </View>
  );
};

export default PackageDetailsOptions;
