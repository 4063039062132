/* eslint-disable max-lines -- required to add self cancel feature */
(function () {
  /**
   * @deprecated
   *
   * Note: this will only be accessed when accountAccess.canAccessAccountPage()
   * is true. Only orgs which have at least one team contract and/or a business trial will see the
   * Account tab.
   */
  angular.module('app.account').component('appAccount', {
    bindings: {
      contractList: '<', // since this page is hidden if there are no contracts there will be at least one contract
      invoiceList: '<',
      productList: '<',
    },
    controller,
    templateUrl: 'app/account/account/account.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $element,
    $location,
    $q,
    $rootScope,
    $state,
    $translate,
    accountAccess,
    ACCOUNT_STATE_PARAM,
    AnalyticsHelper,
    appAccountProductsFilter,
    binkySrc2,
    CART_EVENT,
    chat,
    CHAT_APP_ID,
    ConsumableSummarizationList,
    contractAccess,
    contractUtils,
    CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
    feature,
    FULFILLABLE_ITEM_DELEGATION_TYPE,
    GLOBAL_MODAL_BINDING_TYPE,
    globalModalsManager,
    InvoiceList,
    INVOICE_UNPAID_STATUSES,
    INVOICE_LIST_EVENT,
    onesieSrc2,
    OrganizationList,
    OrganizationManager,
    panelManager,
    PAYMENT_INFO_EDITOR_EVENT,
    productAccess,
    ProductPurchaseHelper,
    RENEW_PRODUCTS_MODAL2_ID,
    RENEW_PRODUCTS_MODAL2_STATES,
    trialHelper
  ) {
    const vm = this;
    const orgId = OrganizationList.get().activeOrg.id;

    const {dispatchPageAnalytics, dispatchUiEventAnalytics} =
      binkySrc2.services.analytics.analyticsUtils;
    const {EVENT_ACTION} = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;

    const {ACCOUNT, ADDITIONAL_PRODUCTS_LIST, CONTRACT_INFO, PRODUCTS_AND_LICENSES_LIST} =
      onesieSrc2.core.observability.constants.OBSERVABILITY_SPAN_LIST;
    const {completeSpan, registerSpans, startSpan} =
      binkySrc2.services.observability.observabilityMetrics;

    _.assign(vm, {
      $doCheck,
      $onDestroy,
      $onInit,
      contractUtils,
      feature,
      onBuyNowClick,
      onesieSrc2,
      onUnpaidInvoicesBannerClick,
      openAddPONumberModal,
      openAddProductsModal,
      showAdditionalProductsAndPlans,
      showStoragePlanList,
      showTeamContainer,
    });

    const deregisterHandles = [];

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    function $onInit() {
      if (feature.isEnabled('temp_observability')) {
        registerSpans([ACCOUNT, CONTRACT_INFO]);
        startSpan(ACCOUNT);
      }
      // Called when user refresh the page having /add-products in url and temp_add_product_mini_app is enabled
      if (productAccess.canViewAddProductMiniApp()) {
        openAddProductsModal();
      }

      vm.waitOnPage = $q.defer();
      vm.productList = OrganizationManager.getProductsForActiveOrg();

      vm.contractList.$promise
        // eslint-disable-next-line complexity, max-statements
        .then(() => {
          processContracts();

          vm.canAccessAccountPage = canAccessAccountPage();
          if (vm.canAccessAccountPage) {
            if (vm.primaryContract) {
              vm.orgName = vm.primaryContract.orgName;
              vm.products = _.filter(vm.productList.items, (product) =>
                product.hasContractId(vm.primaryContract.id)
              );

              if (
                vm.primaryContract.isDirectContract() &&
                vm.primaryContract.isPaymentCategoryPO()
              ) {
                if (vm.primaryContract.isInRenewalWindow()) {
                  deregisterHandles.push(
                    // eslint-disable-next-line angular/on-watch
                    $rootScope.$on(CART_EVENT.RENEWAL_ORDER_REFRESHED, (event, cart) => {
                      vm.showAddPONumberBanner = !cart.getPONumber();
                      if (vm.showAddPONumberBanner) {
                        vm.addPONumberBannerBody = $translate.instant(
                          'widgets.account.addPONumberBanner.body'
                        );
                      }
                    })
                  );
                }

                // Unpaid invoices banner should only be shown for Direct PO contracts.
                // Having vm.invoiceList means the authenticated user is the contract owner and is
                // authorized to see the invoices, even if there are no invoices.
                if (vm.invoiceList) {
                  vm.invoiceList.$promise.finally(() => {
                    getUnpaidInvoices();

                    deregisterHandles.push(
                      // eslint-disable-next-line angular/on-watch
                      $rootScope.$on(PAYMENT_INFO_EDITOR_EVENT.SAVED, () => {
                        vm.invoiceList.refresh();
                      }),
                      // eslint-disable-next-line angular/on-watch
                      $rootScope.$on(INVOICE_LIST_EVENT.UPDATE, (event, updatedInvoiceList) => {
                        // Update unpaid invoices only if the invoice list was updated
                        if (
                          !_.isEqual(updatedInvoiceList.invoiceStatuses, INVOICE_UNPAID_STATUSES)
                        ) {
                          getUnpaidInvoices();
                        }
                      })
                    );
                  });
                }
              }

              // The flag temp_self_cancel is checked inside accountAccess.canManagePlan
              if (accountAccess.canManagePlan(vm.primaryContract)) {
                vm.managePlanProps = {
                  contract: vm.primaryContract,
                  onCancel: onCancelLicensesExit,
                  onConfirm: onCancelLicensesConfirm,
                  onOpenAddProducts: () => openAddProductsModal({sendAnalytics: false}),
                  onOpenManageUsers,
                  onStartChat,
                  productList: vm.productList,
                };

                // temp_self_cancel_deep_link is checked implicitly in accountAccess.canViewSelfCancelModalFromDeepLink()
                if (accountAccess.canViewSelfCancelModalFromDeepLink()) {
                  openSelfCancelModalDeepLink();
                } else {
                  checkForSelfCancelRedirect();
                }
              } else {
                checkForSelfCancelRedirect();
              }

              vm.summaryItemsTableSectionProps = {
                contract: vm.primaryContract,
                managePlanProps: vm.managePlanProps,
              };
            }

            if (vm.isEnterpriseContract) {
              constructBumperPageMessage();
            } else {
              const productsAndLicenses = appAccountProductsFilter(vm.products);
              const contract = vm.contractList.getDirectOrIndirectContract();
              vm.showProductsAndLicenses = contractAccess.canViewProductAndServices(
                contract,
                productsAndLicenses
              );
              vm.additionalProducts = _.difference(vm.products, productsAndLicenses);
              if (!_.isEmpty(vm.additionalProducts)) {
                getSummarizationListByOrgId();
              }
              vm.storageOnlyProducts = _.filter(vm.products, (product) => product.isStorageOnly());
            }

            // Check the list of products with the org consumables removed, to see if there are any remaining
            // consumables.
            if (
              !vm.isEnterpriseContract &&
              _.some(this.products, (product) => product.isGroupConsumable())
            ) {
              getSummarizationListByLicenseId();
            }
          } else {
            constructBumperPageMessage();
          }
          vm.waitOnPage.resolve();
        })
        .catch((error) => {
          vm.waitOnPage.reject(error);
        })
        .finally(() => {
          if (feature.isEnabled('temp_observability')) {
            completeSpan(ACCOUNT);
          }
        });

      if (feature.isEnabled('temp_manage_plan_impressions')) {
        vm.waitOnPage.promise.finally(() => {
          // When this promise has resolved, vm.managePlanProps will have been defined in $onInit
          // inside the vm.contractList.$promise block if the user is eligible for Manage Plan.
          // We wait for vm.waitOnPage rather than vm.contractList since there may be other promises.
          const impression = vm.managePlanProps
            ? 'managePlanButton|adminConsole|add-cancelFlow'
            : '';

          dispatchUiEventAnalytics({
            eventAction: EVENT_ACTION.DISPLAY,
            eventName: 'managePlanButton',
            interaction: {impression},
          });
        });
      }

      /**
       * @description Bumper page if Enterprise-only contract or only contracts without a
       * contract type (i.e. Trial or Allocation contracts).
       *
       * @returns {Boolean} True if user can access account page, false otherwise.
       */
      function canAccessAccountPage() {
        return !vm.isEnterpriseContract && !vm.hasOnlyTrialOrAllocationContracts;
      }

      /**
       * @description If there are multiple contract types (TRIAL, ENTERPRISE, ALLOCATION)
       *   associated to an Admin Console org, then the precedence to display the specific contract
       *   type message in the account tab are: TRAIL, ENTERPRISE, ALLOCATION.
       */
      function constructBumperPageMessage() {
        if (vm.hasAnyTrialContracts) {
          vm.bumperMessageHeader = 'account.trialMessage.header';
          vm.bumperMessageBody = 'account.trialMessage.body';
          vm.showBuyNowButton = productAccess.canBuyTrialProduct(trialHelper.getTrialProduct());
        } else if (vm.isEnterpriseContract) {
          vm.bumperMessageHeader = 'account.enterpriseMessage.header';
        } else if (vm.hasAnyTrialOrAllocationContracts) {
          vm.bumperMessageHeader = 'account.allocationMessage.header';
        }
      }

      function processContracts() {
        vm.hasOnlyTrialContracts = vm.contractList.hasOnlyTrialContracts();
        vm.hasAnyTrialContracts = vm.contractList.hasAnyTrialContracts();

        vm.hasOnlyTrialOrAllocationContracts = vm.contractList.hasOnlyTrialOrAllocationContracts();
        vm.hasAnyTrialOrAllocationContracts = vm.contractList.hasAnyTrialOrAllocationContracts();

        if (vm.contractList.items.length === 1) {
          vm.primaryContract = vm.contractList.items[0];
          vm.isEnterpriseContract = vm.primaryContract.isEnterpriseContract();
          return;
        }

        // There is more than one contract.
        const hasDirectContract = vm.contractList.hasDirectContract();
        const hasEnterpriseContract = vm.contractList.hasEnterpriseContract();
        const hasIndirectContract = vm.contractList.hasIndirectContract();

        if (hasIndirectContract) {
          vm.primaryContract = _.find(vm.contractList.items, (contract) =>
            contract.isIndirectContract()
          );
          if (hasEnterpriseContract) {
            vm.secondContractMessage = $translate.instant(
              'account.secondContractMessage.indirectEnterprise'
            );
          }
        } else if (hasDirectContract) {
          vm.primaryContract = _.find(vm.contractList.items, (contract) =>
            contract.isDirectContract()
          );
          if (hasEnterpriseContract) {
            vm.secondContractMessage = $translate.instant(
              'account.secondContractMessage.directEnterprise'
            );
          }
        } else if (hasEnterpriseContract) {
          vm.isEnterpriseContract = true;
        }
      }
    }

    // It seems to take a couple of digest cycles before these buttons exist.
    function $doCheck() {
      if (!vm.addProductsLinkSet) {
        const addProductsLink = $element[0].querySelectorAll(
          '.add-products-button coral-button-label'
        )[0];
        if (addProductsLink) {
          addProductsLink.innerText = $translate.instant('account.addProducts');
          vm.addProductsLinkSet = true;
        }
      }
    }

    function onBuyNowClick() {
      const product = trialHelper.getTrialProduct();
      trialHelper.openBuyNowUrl(product.id);
    }

    function onCancelLicensesExit() {
      // Return the analytics state to the accounts page when the modal has closed without changes
      dispatchPageAnalytics({
        name: 'account',
      });
    }

    function onCancelLicensesConfirm({nextBilling} = {}) {
      // for immediate UI update of prices that are reflected in Self cancel flow. Contract
      // does not update immediately after so AC saves it locally to display throught the app
      // until the contract api comes back with the expected amount from self cancel
      if (nextBilling) {
        const {
          nextBillingDate,
          totalWithoutTax: priceWithoutTax,
          totalWithTax: priceWithTax,
        } = nextBilling;

        // When a free time offer is accepted, we will only have nextBillingDate called back,
        // while cancelling or discount offer acceptance will call back prices. This means
        // we will storage exclusively the dates or prices and assumes the latest action
        // as the most current for both prices and date.
        vm.primaryContract.setSubmittedNextBilling({
          nextBillingDate,
          priceWithoutTax,
          priceWithTax,
        });
      }

      // Firing event to trigger a refresh for Contracts and Products
      $rootScope.$emit(CART_EVENT.SUBMIT);
    }

    function onOpenManageUsers() {
      $state.go('users.users.all-users');
    }

    function onUnpaidInvoicesBannerClick() {
      if (vm.unpaidInvoiceList.length === 1) {
        $state.go(
          'account.account',
          {
            [ACCOUNT_STATE_PARAM.AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING]:
              vm.unpaidInvoiceList[0].id,
          },
          {
            inherit: true,
          }
        );
      } else {
        $state.go('account.billing-history');
      }
    }

    function onStartChat(options) {
      let appId;
      switch (options.step) {
        case 'BumperStep':
          appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_BUMPER;
          break;
        case 'CancelReviewStep':
          appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_REVIEW;
          onesieSrc2.core.services.chat.chatProvider.reinitialize({appId});
          // we do not want to call chat.openMessagingWindow in this scenario
          // Jarvis will be handling the window automatically opening based on appId
          return;
        case 'SaveOffersStep':
          appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_SAVE_OFFERS;
          break;
        default:
          appId = CHAT_APP_ID.ONESIE1;
          onesieSrc2.core.services.chat.chatProvider.reinitialize({appId});
          // we do not want to call chat.openMessagingWindow in this scenario
          // Jarvis will reset the appId to the previous one on modal close
          return;
      }
      onesieSrc2.core.services.chat.chatProvider.openMessagingWindow({
        appId,
        sourceText: options.step,
        sourceType: 'button',
      });
    }

    function openAddPONumberModal() {
      $state.go('account.account.renew-products', {
        contractId: vm.primaryContract.id,
        step: RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER,
      });
    }

    function openAddProductsModal({sendAnalytics = true} = {}) {
      if (sendAnalytics) {
        AnalyticsHelper.dispatchUiEventAnalytics({
          eventAction: 'click',
          eventName: 'addProducts',
        });
      }
      if (onesieSrc2.core.products.access.canUseAddProductMiniApp()) {
        productAccess.setAddProductUrl();
        const cartItems = ProductPurchaseHelper.convertLocationSearchToCartItems(
          $location.search()
        );
        globalModalsManager.open({
          componentBindings: [
            {
              attributeName: 'component',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: onesieSrc2.products.components.AddProductModalWrapper,
            },
            {
              attributeName: 'props',
              type: GLOBAL_MODAL_BINDING_TYPE.DATA,
              value: {
                chat,
                items: cartItems,
              },
            },
          ],
          componentName: 'binkyReactContainer',
        });
      } else {
        $state.go('account.account.add-products', {}, {inherit: true});
      }
    }

    function openSelfCancelModalDeepLink() {
      globalModalsManager.open({
        componentBindings: [
          {
            attributeName: 'component',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: onesieSrc2.account.components.SelfCancelModal,
          },
          {
            attributeName: 'props',
            type: GLOBAL_MODAL_BINDING_TYPE.DATA,
            value: {
              contract: vm.primaryContract,
              isOpen: true,
              onCancel: onCancelLicensesExit,
              onConfirm: onCancelLicensesConfirm,
              onOpenAddProducts: () => openAddProductsModal({sendAnalytics: false}),
              onOpenManageUsers,
              onStartChat,
              productList: vm.productList,
            },
          },
          {
            attributeName: 'onClosed',
            type: GLOBAL_MODAL_BINDING_TYPE.FUNCTION,
            value: () => {
              $state.go('account.account', {});
            },
          },
        ],
        componentName: 'binkyReactContainer',
      });
    }

    /**
     * If the user is not able to enter the Self-Cancel workflow, this function will determine if the
     * user attempted to enter the flow using the Self-Cancel deep link state. If so, the user needs
     * to be redirected to the parent state.
     */
    function checkForSelfCancelRedirect() {
      if (
        feature.isEnabled('temp_self_cancel_deep_link') &&
        $state.current.name === 'account.account.cancel-licenses'
      ) {
        $state.go('account.account', {});
      }
    }

    function showAdditionalProductsAndPlans() {
      return vm.showTeamContainer() && !vm.primaryContract.isVIPMPContract();
    }

    function showStoragePlanList() {
      return vm.storageOnlyProducts.length > 0;
    }

    function showTeamContainer() {
      return vm.canAccessAccountPage && !vm.isEnterpriseContract;
    }

    //////////

    function getSummarizationListByLicenseId() {
      if (feature.isEnabled('temp_observability')) {
        registerSpans([PRODUCTS_AND_LICENSES_LIST]);
        startSpan(PRODUCTS_AND_LICENSES_LIST);
      }

      vm.summarizationListByLicenseId = ConsumableSummarizationList.get({
        contract_id: _.get(vm, 'primaryContract.id'), // if enterprise, will be undefined
        include_usage: false,
        organization_id: orgId,
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
      });
    }

    function getSummarizationListByOrgId() {
      if (feature.isEnabled('temp_observability')) {
        registerSpans([ADDITIONAL_PRODUCTS_LIST]);
        startSpan(ADDITIONAL_PRODUCTS_LIST);
      }

      vm.summarizationListByOrgId = ConsumableSummarizationList.get({
        contract_id: _.get(vm, 'primaryContract.id'), // if enterprise, will be undefined
        include_usage: false,
        organization_id: orgId,
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.ORGANIZATION_ID,
      });
    }

    function getUnpaidInvoices() {
      let cachedUnpaidInvoices = [];

      vm.invoiceList.$promise
        .then(() => {
          cachedUnpaidInvoices = _.filter(vm.invoiceList.items, (invoice) => invoice.isUnpaid());
          // If the current cached invoice list is empty or contains more than one unpaid invoice,
          // we don't need to request the complete unpaid list, since the UI behavior will be
          // the same regardless. If there are 0 or one unpaid cached invoice, we need to fetch
          // filtered unpaid invoices
          return vm.invoiceList.items.length === 0 || cachedUnpaidInvoices.length > 1
            ? {items: cachedUnpaidInvoices}
            : InvoiceList.get({
                invoiceStatuses: INVOICE_UNPAID_STATUSES,
                useCache: false,
              }).$promise;
        })
        .then(({items = []} = {}) => {
          vm.unpaidInvoiceList = items;
          vm.unpaidInvoicesBannerHeader = $translate.instant(
            'widgets.account.unpaidInvoicesBanner.messageFormat.header',
            {count: vm.unpaidInvoiceList.length},
            'messageformat'
          );
          vm.unpaidInvoicesBannerBody = $translate.instant(
            'widgets.account.unpaidInvoicesBanner.messageFormat.body',
            {count: vm.unpaidInvoiceList.length},
            'messageformat'
          );
        })
        .catch(() => {
          // In case of failure, cached invoice list will be empty or containing one unpaid invoice
          vm.unpaidInvoiceList = cachedUnpaidInvoices;
        });
    }
  }
})();
/* eslint-enable max-lines -- required to add self cancel feature */
