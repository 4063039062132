(function () {
  /**
   * @ngdoc module
   * @name app.core.termsRedirect.ready
   * @description Module which defines terms redirect ready related model(s)
   */
  angular.module('app.core.termsRedirect.ready', [
    'app.core.src2.ready',
    'binky.core.configuration.ready',
    'binky.core.feature',
    'binky.core.feature.ready',
    'binky.core.lodash',
  ]);
})();
