(function () {
  /**
   * @ngdoc module
   * @name app.widgets.button-with-tooltip
   * @description module that defines the button with tooltip widget
   */
  angular.module('app.widgets.button-with-tooltip', [
    'binky.core.lodash',
    'binky.widgets.common.button',
    'ngSanitize',
  ]);
})();
