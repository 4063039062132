import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import LinuxIcon from '../../../svgs/LinuxIcon.svg';
import WindowsIcon from '../../../svgs/WindowsIcon.svg';
import DownloadButton from '../download-button/DownloadButton';

import styles from './StepTitleAndDescription.pcss';

const StepTitleAndDescription = ({stepNumber, title, description}) => {
  const intl = useIntl();
  const macUrl = rootStore.packagesUiConstantsStore.serverConfigToolMacUrl;
  const winUrl = rootStore.packagesUiConstantsStore.serverConfigToolWinUrl;

  return (
    <Flex>
      <Flex>
        <Text
          marginStart="size-200"
          marginTop="size-100"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- set style for step number
          UNSAFE_className={styles['span-step-number']}
        >
          {stepNumber}
        </Text>
      </Flex>
      <Flex direction="column" marginStart="size-100">
        <Heading level={4} marginTop="size-100">
          {title}
        </Heading>
        <Text>{description}</Text>
        {stepNumber === '1' && (
          <Flex direction="column" marginTop="size-100">
            <DownloadButton
              icon={LinuxIcon}
              label={intl.formatMessage({
                id: 'packages.serverSetupGuide.downloadLinux',
              })}
              marginTop="size-100"
              url={macUrl}
            />
            <DownloadButton
              icon={WindowsIcon}
              label={intl.formatMessage({
                id: 'packages.serverSetupGuide.downloadWindows',
              })}
              url={winUrl}
            />
          </Flex>
        )}
        {stepNumber === '2' && (
          <View marginTop="size-100">
            <GoUrl name="ent_FRL_Config_Ser">
              {intl.formatMessage({
                id: 'packages.serverSetupGuide.generateAuthReportLink',
              })}
            </GoUrl>
          </View>
        )}
        {stepNumber === '4' && (
          <View marginTop="size-100">
            <GoUrl name="ent_FRL_Upload_ATO">
              {intl.formatMessage({
                id: 'packages.serverSetupGuide.uploadATOLink',
              })}
            </GoUrl>
          </View>
        )}
      </Flex>
    </Flex>
  );
};

StepTitleAndDescription.propTypes = {
  /**
   * Description of the step
   */
  description: PropTypes.string,
  /**
   * Description of the step number
   */
  stepNumber: PropTypes.string,
  /**
   * Title of the step
   */
  title: PropTypes.string,
};

export default StepTitleAndDescription;
