/**
 * @deprecated
 *
 * @ngdoc component
 * @name app.widgets.products:appStoragePlansList
 *
 *  The list of storage plans, which displays the product icon, name, quota per license, and number
 *  of license.
 *
 * @description The Storage Plans section of the Account page.
 *
 * @binding {Array<Product>} products - the products to be displayed, should have at least one,
 *                                      since there is no placeholder text
 */
(function () {
  angular.module('app.widgets.products').component('appStoragePlansList', {
    bindings: {
      products: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/products-list/storage-plans-list/storage-plans-list.component.html',
  });

  /* @ngInject */
  function controller(_, feature, FileSize, FULFILLABLE_ITEM_CODE) {
    const vm = this;

    _.assign(vm, {
      getLicenseQuantity,
      getQuotaPerLicense,
      showQuotaPerLicense,
    });

    function showQuotaPerLicense(product) {
      const fi = product.getFulfillableItemByCode(FULFILLABLE_ITEM_CODE.ESM_SHARED_STORAGE);
      return (
        _.chain(fi).get('chargingModel.cap').isNumber().value() && _.get(fi, 'chargingModel.unit')
      );
    }

    function getLicenseQuantity(product) {
      return product.licenseTupleList.getAssignableQuantity();
    }

    function getQuotaPerLicense(product) {
      const fi = product.getFulfillableItemByCode(FULFILLABLE_ITEM_CODE.ESM_SHARED_STORAGE);
      return FileSize.bytesToString(
        FileSize.getNumBytes(_.get(fi, 'chargingModel.cap'), _.get(fi, 'chargingModel.unit')),
        0
      );
    }
  }
})();
