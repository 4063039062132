/**
 * Contains the business logic for the encryption settings page under the settings tab.
 */
import {OrganizationEncryption} from '@admin-tribe/binky';
import {PageBanner} from '@admin-tribe/binky-ui';
import {Flex, View} from '@adobe/react-spectrum';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {ENCRYPTION_SETTINGS_CONSTANTS} from '../EncryptionSettingsPageConstants';
import EncryptionContentArea from '../encryption-content-area/EncryptionContentArea';
import EncryptionProgressCalc from '../encryption-progress-calc/EncryptionProgressCalc';
import EncryptionSettingsTriggerButton from '../encryption-settings-trigger-button/EncryptionSettingsTriggerButton';

const namespace = 'settings.encryptionSettings';

const ENCRYPTION_STATUS_CHECK_INTERVAL_FREQUENCY = 20000;
const ENCRYPTION_STATUS_CHECK_MAX_RETRIES = 30;

const EncryptionSettings = ({encryptionInfo, isError}) => {
  const [encryptionDescription, setEncryptionDescription] = useState();
  const [encryptionStatus, setEncryptionStatus] = useState();
  const [errorDescriptionKey, setErrorDescriptionKey] = useState();

  // hasError is true if there is an error while retrieving organization's encryption information
  const [hasError, setHasError] = useState();

  // isBusy is true if encryption enabling/revoking is in progress
  const [isBusy, setIsBusy] = useState();
  const [isEncryptionEnabled, setIsEncryptionEnabled] = useState(false);
  const [processedPercentage, setProcessedPercentage] = useState(0);

  const intervalPercentage = useRef(0);
  const intervalCount = useRef(0);
  const maxPollingReached = useRef(false);
  const shouldPollForStatus = useRef(
    encryptionInfo.encryptionProgressStatus ===
      ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.IN_PROGRESS
  );

  const intl = useIntl();

  const generateEncryptionDescription = () => {
    const statusKey = camelCase(encryptionInfo.encryptionStatus);
    const progressKey = camelCase(
      encryptionInfo.encryptionProgressStatus
        ? encryptionInfo.encryptionProgressStatus
        : ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.COMPLETED
    );

    return intl.formatMessage({
      id: `${namespace}.description.${progressKey}.${statusKey}`,
    });
  };

  const hasFailedOrUnknownStatus = () =>
    hasError ||
    encryptionInfo.encryptionProgressStatus ===
      ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.FAILED ||
    encryptionInfo.encryptionProgressStatus ===
      ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.UNKNOWN;

  const setErrorView = (errorId) => {
    setHasError(true);
    setErrorDescriptionKey(intl.formatMessage({id: errorId}));
  };

  const updateEncryptionSettingsView = () => {
    if (hasError) {
      return;
    }

    const statusKey = camelCase(encryptionInfo.encryptionStatus);
    const progressKey = camelCase(
      encryptionInfo.encryptionProgressStatus
        ? encryptionInfo.encryptionProgressStatus
        : ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.COMPLETED
    );

    setEncryptionStatus(
      intl.formatMessage({
        id: `${namespace}.status.${progressKey}.${statusKey}`,
      })
    );

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- broken by update to jest 28 @ringold to fix
    // istanbul ignore if
    if (maxPollingReached.current) {
      setEncryptionDescription(
        intl.formatMessage({
          id: `${namespace}.description.maxPolling`,
        })
      );
    }
  };

  // Update view anytime there is a change to
  // the org's encryption status/loading state
  useEffect(() => {
    setHasError(hasFailedOrUnknownStatus() || isError);
    if (hasError) {
      setErrorView(`${namespace}.error.getStatus`);
    }
    updateEncryptionSettingsView();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore error, setErrorView and updateEncryptionSettingsView
  }, [encryptionInfo, isBusy, isEncryptionEnabled]);

  // Update status and isBusy when encryptionInfo changes
  useEffect(() => {
    setIsBusy(
      encryptionInfo.encryptionProgressStatus ===
        ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.IN_PROGRESS
    );
    setIsEncryptionEnabled(
      encryptionInfo.encryptionStatus === ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_STATUS.ENABLED
    );
    setEncryptionDescription(generateEncryptionDescription());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore generateEncryptionDescription
  }, [encryptionInfo]);

  // Sets up an interval that performs status checks if encryption/revoking is in progress
  useEffect(() => {
    // Sets state/view when number of polling tries has maxed out
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- broken by update to jest 28 @ringold to fix
    // istanbul ignore next
    const onMaxPollingReached = () => {
      maxPollingReached.current = true;
      setIsBusy(false);
      shouldPollForStatus.current = false;
      intervalPercentage.current = 0;
      setProcessedPercentage(0);
      updateEncryptionSettingsView();
    };

    // Calculate the percentage complete (encrypted units/total encrypted units)
    // to display with the progress spinner while encryption/revoking
    // is in progress
    const calculatePercentageComplete = () => {
      const numRegions = encryptionInfo.orgEncryptionRegionsStatus.length;

      encryptionInfo.orgEncryptionRegionsStatus.forEach((region) => {
        if (region.totalUnits > 0) {
          const encryptedUnitPercentage = region.encryptedUnits / region.totalUnits;
          const regionPercentage = encryptedUnitPercentage / numRegions;
          intervalPercentage.current += regionPercentage;
        }
      });

      setProcessedPercentage(Math.round(intervalPercentage.current * 100));
    };

    // If encryption is in progress, continue checking status
    // Stops checking status as soon as encryption is no longer in progress/maximum
    // number of retries has been reached
    const performIntervalCheck = async () => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- broken by update to jest 28 @ringold to fix
      // istanbul ignore if
      if (intervalCount.current === ENCRYPTION_STATUS_CHECK_MAX_RETRIES) {
        onMaxPollingReached();
        return;
      }

      try {
        // Fetch updated encryption status once per interval
        await encryptionInfo.getOrgEncryptionStatusInfo();
        calculatePercentageComplete();

        if (
          encryptionInfo.encryptionProgressStatus !==
          ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_PROGRESS_STATUS.IN_PROGRESS
        ) {
          setIsBusy(false);
          shouldPollForStatus.current = false;

          setEncryptionDescription(generateEncryptionDescription());
          intervalPercentage.current = 0;
          setProcessedPercentage(0);

          if (hasFailedOrUnknownStatus()) {
            setErrorView(`${namespace}.error.getStatus`);
          } else {
            updateEncryptionSettingsView();
          }
        }
      } catch {
        setErrorView(`${namespace}.error.getStatus`);
      }
    };

    let intervalId = setInterval(async () => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- broken by update to jest 28 @ringold to fix
      // istanbul ignore next
      if (shouldPollForStatus.current) {
        try {
          await performIntervalCheck();
        } finally {
          intervalCount.current += 1;
        }
      }
    }, ENCRYPTION_STATUS_CHECK_INTERVAL_FREQUENCY);

    return () => {
      clearInterval(intervalId);
      intervalId = undefined;
    };
  });

  const enableOrgLevelEncryption = async () => {
    setEncryptionDescription(
      intl.formatMessage({
        id: `${namespace}.description.inProgress.enabled`,
      })
    );
    setIsEncryptionEnabled(true);
    setIsBusy(true);
    shouldPollForStatus.current = true;

    try {
      await encryptionInfo.enableOrgEncryption();
      if (hasFailedOrUnknownStatus()) {
        setErrorView(`${namespace}.error.enabling`);
      }
    } catch {
      setErrorView(`${namespace}.error.enabling`);
    }
  };

  const revokeOrgLevelEncryption = async () => {
    setEncryptionDescription(
      intl.formatMessage({
        id: `${namespace}.description.inProgress.revoked`,
      })
    );
    setIsEncryptionEnabled(false);
    setIsBusy(true);
    shouldPollForStatus.current = true;

    try {
      await encryptionInfo.revokeOrgEncryption();
      if (hasFailedOrUnknownStatus()) {
        setErrorView(`${namespace}.error.revoking`);
      }
    } catch {
      setErrorView(`${namespace}.error.revoking`);
    }
  };

  const canShowStatusAndTriggerButton = () =>
    !isBusy && !hasFailedOrUnknownStatus() && !maxPollingReached.current;

  return (
    <View>
      {hasError && errorDescriptionKey && (
        <PageBanner
          header={intl.formatMessage({id: 'settings.encryptionSettings.error.header'})}
          variant="error"
        >
          {errorDescriptionKey}
        </PageBanner>
      )}
      <View backgroundColor="gray-50" borderColor="gray-200" borderRadius="small">
        <Flex alignItems="center" justifyContent="space-between" margin="size-300">
          {encryptionStatus && encryptionDescription && (
            <EncryptionContentArea
              canShowStatus={canShowStatusAndTriggerButton()}
              encryptionDescription={encryptionDescription}
              encryptionInfo={encryptionInfo}
              encryptionStatus={encryptionStatus}
            />
          )}
          <Flex>
            {canShowStatusAndTriggerButton() && (
              <EncryptionSettingsTriggerButton
                onEnableConfirm={enableOrgLevelEncryption}
                onRevokeConfirm={revokeOrgLevelEncryption}
                setting={(isEncryptionEnabled
                  ? ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_MODAL.REVOKE
                  : ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_MODAL.ENABLE
                ).toLowerCase()}
              />
            )}
            {isBusy && !hasError && (
              <View height="size-400">
                <EncryptionProgressCalc
                  encryptionInfo={encryptionInfo}
                  processedPercentage={processedPercentage}
                />
              </View>
            )}
          </Flex>
        </Flex>
      </View>
    </View>
  );
};

EncryptionSettings.propTypes = {
  /**
   * Contains information regarding encryption
   * status for an organization
   */
  encryptionInfo: PropTypes.instanceOf(OrganizationEncryption).isRequired,
  /**
   * True if there is an error while retrieving
   * encryption information
   */
  isError: PropTypes.bool,
};

export default EncryptionSettings;
