import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useParams} from 'react-router-dom';

import UserGroupModal from 'common/components/user-groups/UserGroupModal';
import {canEditUserGroupNameDescription} from 'core/user-group/access/userGroupAccess';

/**
 * User group details page actions.
 */
const UserGroupDetailsPageActions = ({setUserGroupName}) => {
  const {orgId} = useParams();
  const {userGroup} = useLoaderData();
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const onSave = async () => {
    setIsOpen(false);

    try {
      await userGroup.refresh();
      setUserGroupName(userGroup.name);
    } catch (apiError) {
      // userGroup.refresh logs an error
    }
  };

  return (
    canEditUserGroupNameDescription(userGroup) && (
      <>
        <Button data-testid="edit-user-group-btn" onPress={() => setIsOpen(true)} variant="accent">
          {intl.formatMessage({id: 'users.userGroupDetails.action.editUserGroupBtn'})}
        </Button>
        {isOpen && (
          <UserGroupModal
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            onSave={onSave}
            orgId={orgId}
            userGroup={userGroup}
          />
        )}
      </>
    )
  );
};

UserGroupDetailsPageActions.propTypes = {
  /**
   * The setter to update the user group name.
   */
  setUserGroupName: PropTypes.func.isRequired,
};

export default UserGroupDetailsPageActions;
