/**
 * @description Method to return the firefly_cme_quota item from the product (or null if not found).
 * @param {Product} product
 * @returns {FulfillableItem|{chargingModel: {unit: string, cap: number}}|*|{chargingModel: {cap: number}}}
 */
function getFireflyCmeQuotaFulfillableItem(product) {
  return product.getFulfillableItemByCode('firefly_cme_quota');
}

/**
 * @description Method to find if the product has the firefly_cme_quota item.
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {boolean} - True/False
 */
function isCustomModelProduct(product) {
  return !!getFireflyCmeQuotaFulfillableItem(product);
}

export {getFireflyCmeQuotaFulfillableItem, isCustomModelProduct};
