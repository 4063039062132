/**
 * @deprecated ported to src2/app/common/components/user-details-section/UserDetailsSection.jsx
 *
 * @ngdoc component
 * @name app.widgets.users:appUserDetailSection
 *
 * @description The User info section in the User Detail page.
 *
 * @binding {int} [headingLevel=3]. Defines the heading level for the detail section. Supports
 *   only levels 2, 3 or 4. If an invalid level is supplied, it will be reset to the default 3.
 * @binding {Object} user. An OrganizationUser that has a promise.
 */
(function () {
  const componentName = 'appUserDetailSection';
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      headingLevel: '<?',
      onRefresh: '<?',
      pageContext: '<?',
      user: '<',
    },
    controller,
    templateUrl: 'app/widgets/users/detail-sections/user/user-detail-section.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $rootScope,
    auth,
    binkyUISrc2,
    feature,
    MemberType,
    MEMBER_TYPE,
    onesieSrc2,
    organizationAccess,
    OrganizationManager
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      getUserType,
    });

    function $onInit() {
      vm.orgId = OrganizationManager.getActiveOrgId();
      vm.EditUserDetailsModal = onesieSrc2.users.components.EditUserDetailsModal;
      vm.isEditUserDetailsModalOpen = false;

      /* Detail section will almost always be below an h2 sub-heading, so default the heading level to 3 */
      vm.headingLevel = vm.headingLevel || 3;
      if (vm.headingLevel < 2 || vm.headingLevel > 4) {
        vm.headingLevel = 3;
      }

      // eslint-disable-next-line promise/catch-or-return
      vm.user.$promise.then(() => {
        vm.canDisplayButtonBar =
          organizationAccess.canEditUser() &&
          auth.isUserOrgAdmin() &&
          !vm.user.getType().isTechnicalAccount() &&
          auth.getUserId() !== vm.user.id &&
          isUserEditable() &&
          !vm.user.isExternallyManaged();
      });

      vm.openEditUserProfileModal = function () {
        vm.isEditUserDetailsModalOpen = true;
      };

      vm.onEditUserDetailsModalSuccess = function () {
        vm.isEditUserDetailsModalOpen = false;
        vm.user.refresh();
        $rootScope.$apply();
      };

      vm.onEditUserDetailsModalCancel = function () {
        vm.isEditUserDetailsModalOpen = false;
        $rootScope.$apply();
      };
    }

    function getUserType() {
      if (vm.user.type === MEMBER_TYPE.TYPE2E) {
        return _.has(vm.user, 'authenticatingAccount.type')
          ? new MemberType(vm.user.authenticatingAccount.type, vm.user.authenticatingAccount.id)
          : new MemberType(MEMBER_TYPE.NOT_AVAILABLE, undefined);
      }
      return vm.user.getType();
    }

    function isUserEditable() {
      if (binkyUISrc2.member.memberDisplayUtils.isT2EUser(vm.user)) {
        return _.has(vm.user, 'authenticatingAccount.editable')
          ? vm.user.authenticatingAccount.editable
          : false;
      }
      return vm.user.editable;
    }
  }
})();
