const PATH_INSIGHTS_SEGMENT_DASHBOARD = 'dashboard';
const PATH_INSIGHTS_SEGMENT_REPORTS = 'reports';
const PATH_INSIGHTS_SEGMENT_LOGS = 'logs';
const PATH_INSIGHTS_SEGMENT_DETAILS = ':insightsItemId/:tabName';

const PATH_INSIGHTS_ROOT = '/:orgId/insights';
const PATH_INSIGHTS_DASHBOARD = `${PATH_INSIGHTS_ROOT}/${PATH_INSIGHTS_SEGMENT_DASHBOARD}`;
const PATH_INSIGHTS_REPORTS = `${PATH_INSIGHTS_ROOT}/${PATH_INSIGHTS_SEGMENT_REPORTS}`;
const PATH_INSIGHTS_REPORTS_DETAILS = `${PATH_INSIGHTS_REPORTS}/${PATH_INSIGHTS_SEGMENT_DETAILS}`;
const PATH_INSIGHTS_LOGS = `${PATH_INSIGHTS_ROOT}/${PATH_INSIGHTS_SEGMENT_LOGS}`;
const PATH_INSIGHTS_LOGS_DETAILS = `${PATH_INSIGHTS_LOGS}/${PATH_INSIGHTS_SEGMENT_DETAILS}`;

export {
  PATH_INSIGHTS_DASHBOARD,
  PATH_INSIGHTS_REPORTS,
  PATH_INSIGHTS_LOGS,
  PATH_INSIGHTS_LOGS_DETAILS,
  PATH_INSIGHTS_REPORTS_DETAILS,
  PATH_INSIGHTS_ROOT,
  PATH_INSIGHTS_SEGMENT_DASHBOARD,
  PATH_INSIGHTS_SEGMENT_REPORTS,
  PATH_INSIGHTS_SEGMENT_DETAILS,
  PATH_INSIGHTS_SEGMENT_LOGS,
};
