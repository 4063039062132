import {useEffect, useState} from 'react';

import {
  canViewIndividualUserFolders,
  canViewLibraries,
  canViewSharedProjects,
  canViewStorage,
} from 'core/storage/access/storageAccess';

/** @description Provides access rights for storage */
const useStorageAccessors = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [canViewIndividualUserFoldersValue, setCanViewIndividualUserFoldersValue] = useState(false);
  const [canViewLibrariesValue, setCanViewLibrariesValue] = useState(false);
  const [canViewStorageValue, setCanViewStorageValue] = useState(false);
  const [canViewSharedProjectsValue, setCanViewSharedProjectsValue] = useState(false);

  // Saves results from accessor async functions
  useEffect(() => {
    (async () => {
      // These methods always resolve with Promise<Boolean>.
      const values = await Promise.all([
        canViewIndividualUserFolders(),
        canViewLibraries(),
        canViewStorage(),
        canViewSharedProjects(),
      ]);
      setCanViewIndividualUserFoldersValue(values[0]);
      setCanViewLibrariesValue(values[1]);
      setCanViewStorageValue(values[2]);
      setCanViewSharedProjectsValue(values[3]);

      setIsLoading(false);
    })();
  }, []);

  return {
    canViewIndividualUserFolders: canViewIndividualUserFoldersValue,
    canViewLibraries: canViewLibrariesValue,
    canViewSharedProjects: canViewSharedProjectsValue,
    canViewStorage: canViewStorageValue,
    isLoading,
  };
};

export default useStorageAccessors;
