// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import {MultiFormatJson} from '@pandora/react-multi-format-text';
import {Price} from '@pandora/react-price';
import PropTypes from 'prop-types';
import React from 'react';

import {getPriceProp} from 'common/components/sophia/sophia-price/util';

/**
 * @description Component for displaying strikethrough price info for Sophia cards and banners
 */
const StrikethroughPriceInfo = ({price, frequency, strikethroughColor}) => {
  const priceProps = getPriceProp(price, frequency, false);

  const priceJsonProps = {
    dataTestId: 'strikethrough-price',
    json: [
      {
        content: [
          {
            nodeType: 'text',
            value: '{price}',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    variables: {
      price: (
        <View
          key="strikethrough-price"
          UNSAFE_style={{
            display: 'inline-block',
            textDecoration: 'line-through',
            textDecorationColor: strikethroughColor,
            textDecorationThickness: '0.063rem',
          }}
        >
          <Price {...priceProps} />
        </View>
      ),
    },
  };

  return (
    <View data-testid="one-console-strikethrough-price">
      <MultiFormatJson {...priceJsonProps} />
    </View>
  );
};

StrikethroughPriceInfo.propTypes = {
  // https://git.corp.adobe.com/admin-tribe/onesie/wiki/Integration-with-Sophia
  // eslint-disable-next-line react/forbid-prop-types -- see wiki above for options
  content: PropTypes.object,

  frequency: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- see wiki above for options
  price: PropTypes.object,
  strikethroughColor: PropTypes.string,
};

export default StrikethroughPriceInfo;
