import {feature} from '@admin-tribe/binky';
import {WorkspaceNav} from '@admin-tribe/binky-ui';
import React from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_PRODUCTS, PATH_PRODUCT_DETAILS} from 'features/products/routing/productsPaths';
import {
  getAppAddOnNavItems,
  getAutoAssignmentRulesNavItems,
  getDeviceLicensesNavItems,
  getProductRequestsNavItems,
  getProductsNavList,
} from 'features/products/utils/productGroupProductUtils';

/** Renders the workspace navbar for products */
const ProductsWorkspaceNav = () => {
  const intl = useIntl();

  const {activeOrgId: orgId, productList} = rootStore.organizationStore;
  const {navItems: productNavItems} = getProductsNavList(productList, intl);
  const navItems = [];
  const isAddAnalysisId = feature.isEnabled('temp_add_analysis_id_to_product_nav');

  productNavItems.forEach((navItem) => {
    if (navItem.group) {
      navItems.push({
        ...(isAddAnalysisId ? {analysisId: `nav-product-${navItem.state.params.productId}`} : {}),
        group: intl.formatMessage({id: navItem.group}),
        href: generatePath(PATH_PRODUCT_DETAILS, {
          orgId,
          productId: navItem.state.params.productId,
        }),
        name: navItem.name,
        order: navItem.order,
        pattern: navItem.pattern,
      });
    }
  });

  navItems.push({
    ...(isAddAnalysisId ? {analysisId: 'nav-all-product-and-services'} : {}),
    href: generatePath(PATH_PRODUCTS, {
      orgId,
    }),
    icon: undefined,
    name: intl.formatMessage({
      id: 'products.productsWorkspaceNav.navItem.allProductsAndServices',
    }),
    order: 0,
    ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-all-products-and-services'} : {}),
  });

  const sidenavItems = [
    ...navItems,
    ...getAppAddOnNavItems(orgId, intl, productList),
    ...getAutoAssignmentRulesNavItems(orgId, intl),
    ...getDeviceLicensesNavItems(orgId, intl),
    ...getProductRequestsNavItems(orgId, intl),
  ];

  return <WorkspaceNav navItems={sidenavItems} />;
};

ProductsWorkspaceNav.displayName = 'ProductsWorkspaceNav';

export default ProductsWorkspaceNav;
