(function () {
  /**
   * @ngdoc module
   * @name app.core.sophia
   * @description Module which defines sophia (Test & Target) models.
   */
  angular.module('app.core.sophia', [
    'app.core.api.sophia',
    'app.core.organizations.organization-manager',
    'app.core.src2-migration',
    'binky.core.contract',
    'binky.core.lodash',
    'binky.core.product',
  ]);
})();
