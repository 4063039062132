// Deprecated. Need to keep it around for src1. Can be removed when src1 bulk-operation-utils is removed.
const BULK_OPERATION_ERRORS = [
  'CSV_FORMAT_ADMIN',
  'CSV_FORMAT_ADMIN_ROLE',
  'CSV_FORMAT_GROUP_NAME',
  'CSV_FORMAT_LICGROUP_NAME',
  'CSV_FORMAT_PRODUCT_NAME',
  'CSV_FORMAT_USER_TYPE',
  'CSV_READ_FAILED',
  'INVALID_CSV_DATA',
  'INVALID_CSV_INPUT',
  'INVALID_DUPLICATE_USER',
  'INVALID_STREAM_CSV_HEADER',
  'INVALID_USER_TYPE',
];

const BULK_OPERATION_UPLOAD_ERRORS = {
  BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT: 'BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT',
  CSV_FORMAT_ADMIN: 'CSV_FORMAT_ADMIN',
  CSV_FORMAT_ADMIN_ROLE: 'CSV_FORMAT_ADMIN_ROLE',
  CSV_FORMAT_GROUP_NAME: 'CSV_FORMAT_GROUP_NAME',
  CSV_FORMAT_LICGROUP_NAME: 'CSV_FORMAT_LICGROUP_NAME',
  CSV_FORMAT_PRODUCT_NAME: 'CSV_FORMAT_PRODUCT_NAME',
  CSV_FORMAT_USER_TYPE: 'CSV_FORMAT_USER_TYPE',
  CSV_READ_FAILED: 'CSV_READ_FAILED',
  INVALID_CSV_DATA: 'INVALID_CSV_DATA',
  INVALID_CSV_INPUT: 'INVALID_CSV_INPUT',
  INVALID_DUPLICATE_USER: 'INVALID_DUPLICATE_USER',
  INVALID_STREAM_CSV_HEADER: 'INVALID_STREAM_CSV_HEADER',
  INVALID_USER_TYPE: 'INVALID_USER_TYPE',
};

const BULK_OPERATION_MODAL_ID = 'bulk-operation-modal';

const BULK_OPERATION_MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  OFFER_SWITCH_MIGRATION: 'OFFER_SWITCH_MIGRATION',
  REMOVE: 'REMOVE',
  SWITCH_IDENTITY_TYPE: 'SWITCH_IDENTITY_TYPE',
  UNASSIGN: 'UNASSIGN',
};

const CSV_HEADER = {
  ORG_USERS_TEAM: `Email,First Name,Last Name,Admin Roles,User Groups,Team Products`,
};

// Mappings from the CSV headers that JIL outputs and expects, to the localized callsign
// It's a good idea to specify what strings JIL uses rather than rely on the English
// localization text, to allow the UI to be less coupled to the backend implementation.
const CSV_HEADER_LOCALIZATION_MAP = [
  {
    jil: 'Account Status',
    localizationKey: 'common.bulkOperation.csvHeaders.accountStatus',
  },
  {
    jil: 'Admin Roles',
    localizationKey: 'common.bulkOperation.csvHeaders.adminRoles',
  },
  {
    jil: 'Auto Assigned Products',
    localizationKey: 'common.bulkOperation.csvHeaders.autoAssignedProducts',
  },
  {
    jil: 'Contracts Administered',
    localizationKey: 'common.bulkOperation.csvHeaders.contractsAdministered',
  },
  {
    jil: 'Country Code',
    localizationKey: 'common.bulkOperation.csvHeaders.countryCode',
  },
  {
    jil: 'Domain',
    localizationKey: 'common.bulkOperation.csvHeaders.domain',
  },
  {
    jil: 'Developer Access',
    localizationKey: 'common.bulkOperation.csvHeaders.developerAccess',
  },
  {
    jil: 'Email',
    localizationKey: 'common.bulkOperation.csvHeaders.email',
  },
  {
    jil: 'First Name',
    localizationKey: 'common.bulkOperation.csvHeaders.firstName',
  },
  {
    jil: 'Id',
    localizationKey: 'common.bulkOperation.csvHeaders.id',
  },
  {
    jil: 'ID',
    localizationKey: 'common.bulkOperation.csvHeaders.id',
  },
  {
    jil: 'Identity Type',
    localizationKey: 'common.bulkOperation.csvHeaders.identityType',
  },
  {
    jil: 'New Country Code',
    localizationKey: 'common.bulkOperation.csvHeaders.newCountryCode',
  },
  {
    jil: 'New Email',
    localizationKey: 'common.bulkOperation.csvHeaders.newEmail',
  },
  {
    jil: 'New Identity Type',
    localizationKey: 'common.bulkOperation.csvHeaders.newIdentityType',
  },
  {
    jil: 'New Username',
    localizationKey: 'common.bulkOperation.csvHeaders.newUsername',
  },
  {
    jil: 'Last Name',
    localizationKey: 'common.bulkOperation.csvHeaders.lastName',
  },
  {
    jil: 'Product Configurations',
    localizationKey: 'common.bulkOperation.csvHeaders.productConfigurations',
  },
  {
    jil: 'Product Configurations Administered',
    localizationKey: 'common.bulkOperation.csvHeaders.productConfigurationsAdministered',
  },
  {
    jil: 'Product Role',
    localizationKey: 'common.bulkOperation.csvHeaders.productRole',
  },
  {
    jil: 'Products Administered',
    localizationKey: 'common.bulkOperation.csvHeaders.productsAdministered',
  },
  {
    jil: 'Team Products',
    localizationKey: 'common.bulkOperation.csvHeaders.teamProducts',
  },
  {
    jil: 'Tags',
    localizationKey: 'common.bulkOperation.csvHeaders.tags',
  },
  {
    jil: 'User Groups',
    localizationKey: 'common.bulkOperation.csvHeaders.userGroups',
  },
  {
    jil: 'User Groups Administered',
    localizationKey: 'common.bulkOperation.csvHeaders.userGroupsAdministered',
  },
  {
    jil: 'Username',
    localizationKey: 'common.bulkOperation.csvHeaders.username',
  },
];

const CSV_ROW_LIMIT = 25000;

// Note: CSV template strings are formatted to have leading whitespace removed
const CSV_STANDARD_TEMPLATES = {
  ORG_USERS: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access,Contracts Administered
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison,US,123@AdobeId,"Audition - 1000 GB,Photoshop for New York office",,Photoshop for New York office,New York office,,Photoshop,Photoshop for New York office,
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,234@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office,
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,345@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop",,ETLA - F74416AB8E9AAC34BFFB`,
  ORG_USERS_RESTRICT_T1: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access,Contracts Administered
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,123@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office,
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,234@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop",,ETLA - F74416AB8E9AAC34BFFB`,
  ORG_USERS_RESTRICT_T1_WITHOUT_CONTRACTS: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,123@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,234@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop"`,
  ORG_USERS_T2E: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access,Contracts Administered
Adobe ID,alice@my-domain.com,my-domain.com,alice@my-domain.com,Alice,Alison,US,123@my-domain.com,"Audition - 1000 GB,Photoshop for New York office",,Photoshop for New York office,New York office,,Photoshop,Photoshop for New York office,
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,234@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office,
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,345@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop",,ETLA - F74416AB8E9AAC34BFFB`,
  ORG_USERS_T2E_WITHOUT_CONTRACTS: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access
Adobe ID,alice@my-domain.com,my-domain.com,alice@my-domain.com,Alice,Alison,US,123@my-domain.com,"Audition - 1000 GB,Photoshop for New York office",,Photoshop for New York office,New York office,,Photoshop,Photoshop for New York office
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,234@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,345@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop"`,
  ORG_USERS_WITHOUT_CONTRACTS: `Identity Type,Username,Domain,Email,First Name,Last Name,Country Code,ID,Product Configurations,Admin Roles,Product Configurations Administered,User Groups,User Groups Administered,Products Administered,Developer Access
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison,US,123@AdobeId,"Audition - 1000 GB,Photoshop for New York office",,Photoshop for New York office,New York office,,Photoshop,Photoshop for New York office
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,FR,234@my-domain.com,Audition - 1000 GB,Deployment,,"New York office,Paris office",New York office,,Photoshop for New York office
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,IT,345@my-domain.com,,System,"Audition - 1000 GB,Photoshop for New York office",,"New York office,Paris office","Audition,Photoshop"`,
  PROFILE_USERS: {
    WITH_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name,Product Role
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison,{PRODUCT_ROLE}
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,{PRODUCT_ROLE}
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,`,
    WITHOUT_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
  },
  PROFILE_USERS_RESTRICT_T1: {
    WITH_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name,Product Role
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,{PRODUCT_ROLE}
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,{PRODUCT_ROLE}`,
    WITHOUT_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
  },
  PROFILE_USERS_T2E: {
    WITH_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name,Product Role
Adobe ID,alice@my-domain.com,my-domain.com,alice@my-domain.com,Alice,Alison,{PRODUCT_ROLE}
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker,{PRODUCT_ROLE}
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester,`,
    WITHOUT_ROLES: `Identity Type,Username,Domain,Email,First Name,Last Name
Adobe ID,alice@my-domain.com,my-domain.com,alice@my-domain.com,Alice,Alison
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
  },
  SWITCH_IDENTITY_TYPE: `Identity Type,Username,Domain,New Identity Type,New Email,New Username,New Country Code
Adobe ID,alice@my-domain.com,N/A,Enterprise ID,alice@my-domain.com,,
Enterprise ID,bob@my-other-domain.com,my-domain.com,Adobe ID,bob@my-other-domain.com,bob,US
Federated ID,cchester,my-other-domain.com,Adobe ID,cchester@my-other-domain.com,,`,
  SWITCH_IDENTITY_TYPE_RESTRICT_T1: `Identity Type,Username,Domain,New Identity Type,New Email,New Username,New Country Code
Adobe ID,alice@my-domain.com,N/A,Enterprise ID,alice@my-domain.com,,
Adobe ID,alice@my-domain.com,N/A,Federated ID,alice@my-domain.com,,`,
  SWITCH_IDENTITY_TYPE_T2E: `Identity Type,Username,Domain,New Identity Type,New Email,New Username,New Country Code
Adobe ID,alice@my-domain.com,my-domain.com,Enterprise ID,alice@my-domain.com,alice,US,
Adobe ID,bob@my-domain.com,my-domain.com,Enterprise ID,bob@my-domain.com,bob,US
Adobe ID,cchester,my-other-domain.com,Federated ID,cchester@my-other-domain.com,chester,US,`,
  USER_GROUP: `User Group Name,Description,User Group Admins,Assigned Product Profiles
Admin Console Test 1,Example User Group,"ADOBE ID-alice@my-domain.com, ADOBE ID-bob@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"
Admin Console Test 2,Example User Group 2,"ADOBE ID-alice@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"`,
  USER_GROUP_RESTRICT_T1: `User Group Name,Description,User Group Admins,Assigned Product Profiles
Admin Console Test 1,Example User Group,"Enterprise ID-alice@my-domain.com, Enterprise ID-bob@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"
Admin Console Test 2,Example User Group 2,"Federated ID-alice@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"`,
  USER_GROUP_T2E: `User Group Name,Description,User Group Admins,Assigned Product Profiles
Admin Console Test 1,Example User Group,"ADOBE ID-alice@my-domain.com, ADOBE ID-bob@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"
Admin Console Test 2,Example User Group 2,"ADOBE ID-alice@my-domain.com","Default Adobe Target configuration 2, Default Adobe Campaign configuration 2"`,
  USER_GROUP_USERS: `Identity Type,Username,Domain,Email,First Name,Last Name
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
  USER_GROUP_USERS_RESTRICT_T1: `Identity Type,Username,Domain,Email,First Name,Last Name
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
  USER_GROUP_USERS_T2E: `Identity Type,Username,Domain,Email,First Name,Last Name
Adobe ID,alice@my-domain.com,N/A,alice@my-domain.com,Alice,Alison
Enterprise ID,bob@my-domain.com,my-domain.com,bob@my-domain.com,Bob,Becker
Federated ID,cchester,my-other-domain.com,charles@my-other-domain.com,Charles,Chester`,
};

// @awaitingdeprectation - Remove with /src JobResults pages
const JOB_RESULT_FILTER = {
  ERRORS: 'ERRORS',
  VIEW_ALL: 'VIEW_ALL',
};

const JOB_RESULTS_FILTER = {
  ALL: 'ALL',
  FAILURES: 'FAILURES',
};

const BULK_OPERATIONS_RESULTS_FILENAME = {
  DIRECTORY_USERS: 'users-results.csv',
  PRODUCT_USERS: 'users-results.csv',
  PROFILE_USERS: 'profile-users-results.csv',
  USER_GROUP_DETAILS: 'user-group-users-results.csv',
  USER_GROUPS_USERS: 'user-group-users-results.csv',
  USERS: 'org-users-results.csv',
};

export {
  BULK_OPERATION_ERRORS,
  BULK_OPERATION_UPLOAD_ERRORS,
  BULK_OPERATION_MODAL_ID,
  BULK_OPERATION_MODE,
  BULK_OPERATIONS_RESULTS_FILENAME,
  CSV_HEADER,
  CSV_HEADER_LOCALIZATION_MAP,
  CSV_ROW_LIMIT,
  CSV_STANDARD_TEMPLATES,
  JOB_RESULT_FILTER, // remove with /src
  JOB_RESULTS_FILTER,
};
