const ADD_PRODUCT_WORKFLOWS = {
  ADD_LICENSE: 'add-license',
  ADD_PRODUCT: 'add-product',
  SELECT_PLAN: 'select-plan',
  UPDATE_RENEW: 'update-renew',
  VIEW_RENEW: 'view-renew',
};

const STATUS_CODE = {
  CANCEL_WITHOUT_PROGRESS: 0,
  FULLY_COMPLETE: 10,
  GO_BACK: -1,
};

const ADD_PRODUCT_RTP2_FEATURES = [
  'share_apollo_client',
  'support_search_contracts',
  'temp_add_product_mini_app_rtp2',
  'temp_add_product_rtp2_disable_by_cliend_id',
  'use_auth_user_for_cart',
  'use_mat_steps',
];

export {ADD_PRODUCT_RTP2_FEATURES, ADD_PRODUCT_WORKFLOWS, STATUS_CODE};
