import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';

import useProductsChange, {ACTIONS} from '../../hooks/use-products-change/useProductsChange';

/**
 * The Private Context for ProductsChange Model. When React renders a component that subscribes to
 * this Context it will read the current context value from the closest matching Provider above.
 */
const ProductsChangeContext = createContext({});

/**
 * @description A helper hook to consume the provided ProductsChange model with the loading and
 * error state.
 * @returns {Object} state - Object composed of state variables: productschange, error, isLoading.
 *          {ProductsChange} state.productsChange - the array of users assigned to the Product in the Org.
 *          {Boolean} state.isLoading - Whether data is currently being loaded.
 *          {Object} state.error - If loading data failed, then this contains the error that occurred.
 *          {Function} state.dispatchProductsChange - The dispatch function to make requests.
 */
const useProductsChangeContext = () => useContext(ProductsChangeContext);

/**
 * Private Self Cancel Context Provider used to pass the ProductsChange Model along with its error
 * and loading state between all the sub-components.
 */
const ProductsChangeContextProvider = ({children, contractId, orgId}) => {
  const value = useProductsChange({contractId, orgId});

  return <ProductsChangeContext.Provider value={value}>{children}</ProductsChangeContext.Provider>;
};

ProductsChangeContextProvider.propTypes = {
  /**
   * The contents of the provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * The Contract Id.
   */
  contractId: PropTypes.string.isRequired,
  /**
   * The Organization Id.
   */
  orgId: PropTypes.string.isRequired,
};

export {ACTIONS, ProductsChangeContext, ProductsChangeContextProvider, useProductsChangeContext};
