import {Contract, EVENT_ACTION, feature} from '@admin-tribe/binky';
import {ImageIcon} from '@admin-tribe/binky-ui';
import {Button, Flex, ProgressCircle, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import {PRICE_FAILURE} from '../../SelfCancelConstants';
import useSophiaPersonalOfferGivenCancelReasons from '../../hooks/use-cancellation-reasons/useSophiaPersonalOfferGivenCancelReasons';
import useRetentionDetails from '../../hooks/use-retention-details/useRetentionDetails';
import SelfCancelCard from '../card/SelfCancelCard';
import MerchandisingText from '../merchandising-text/MerchandisingText';
import {useSelfCancelAnalyticsContext} from '../self-cancel-analytics-context/SelfCancelAnalyticsContext';

import styles from './Card.pcss';
import SaveOffersHeader from './SaveOffersHeader';

/**
 * Card component to render Percentage Discount Offer type.
 */
const PercentageDiscountOfferCard = ({index, onCTA, contract, retentionId, selectedReasons}) => {
  const intl = useIntl();

  const {
    banner,
    currency,
    icon,
    isBestValue,
    headline: title,
    description,
    discountPeriod,
    promotionId,
    nextPrice,
    recurrenceTerm,
    shortDescription,
  } = useRetentionDetails({contract, retentionId});

  // remove discountPeriod as this will cause intl.formatMessage to fail, will be reinserted later
  const bodyDescription = shortDescription.replace('{{discountPeriod}}', '');

  const [cardText, setCardText] = useState({
    cardBodyDescription: '',
    cardDescription: '',
    cardTitle: intl.formatMessage({
      id: 'common.selfCancelFooterTotal.loadingLabel',
    }),
  });

  const {isLoading, sophiaMerchandisingData} = useSophiaPersonalOfferGivenCancelReasons({
    promotionId,
    selectedReasons: [...selectedReasons].sort().join(','),
    type: 'PERCENTAGE_DISCOUNT',
  });

  const {dispatchSelfCancelUiEventAnalytics} = useSelfCancelAnalyticsContext();

  const onGetDetailsPress = () => {
    dispatchSelfCancelUiEventAnalytics({
      card: {index, retentionId},
      eventAction: EVENT_ACTION.CLICK,
      eventName: `cancelFlowOffersOfferDetails`,
      retentionId,
    });

    onCTA(retentionId);
  };

  const loadingCard = () => (
    <SelfCancelCard title={intl.formatMessage({id: 'common.selfCancelFooterTotal.loadingLabel'})}>
      <ProgressCircle
        aria-label={intl.formatMessage({id: 'common.selfCancelFooterTotal.loadingLabel'})}
        data-testid="progressCircle"
        isIndeterminate
        size="S"
      />
    </SelfCancelCard>
  );

  // set card text when sophia merchendising API call is complete
  React.useEffect(() => {
    if (
      feature.isEnabled('temp_sophia_promotional_offer') &&
      !isLoading &&
      sophiaMerchandisingData &&
      Object.entries(sophiaMerchandisingData).length > 0
    ) {
      // Delivering AEM content through sophia results in curly bracket placeholders being parsed as query params rather than content.
      // As a result we are using [[ ]] syntax instead. This does not affect the existing curly bracket used in JIL
      setCardText({
        cardBodyDescription: sophiaMerchandisingData?.shortDescription?.replace(
          '[[discountPeriod]]',
          ''
        ),
        cardDescription: sophiaMerchandisingData?.description,
        cardTitle: sophiaMerchandisingData?.headline,
      });
    }

    // fall back to product change merchendising data when sophia merchendising data is not returned
    if (
      feature.isEnabled('temp_sophia_promotional_offer') &&
      !isLoading &&
      (!sophiaMerchandisingData || Object.entries(sophiaMerchandisingData).length === 0)
    ) {
      setCardText({
        cardBodyDescription: bodyDescription,
        cardDescription: description,
        cardTitle: title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when these change
  }, [isLoading, sophiaMerchandisingData]);

  return (
    <>
      {isLoading && feature.isEnabled('temp_sophia_promotional_offer') && loadingCard()}
      {!isLoading && (
        <SelfCancelCard
          headerComponent={
            <SaveOffersHeader
              headerImage={banner}
              isBestValue={isBestValue}
              logo={icon && <ImageIcon alt="" size="L" src={icon} />}
            />
          }
          testId="percentage-discount-offer-card"
          title={
            feature.isEnabled('temp_sophia_promotional_offer') ? (
              <span styleName="title-text">{cardText.cardTitle}</span>
            ) : (
              <span styleName="title-text">{title}</span>
            )
          }
        >
          <View
            minHeight="size-800"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles['description-text']}
          >
            <MerchandisingText
              data-testid="body-description"
              message={
                feature.isEnabled('temp_sophia_promotional_offer')
                  ? cardText.cardBodyDescription
                  : bodyDescription
              }
              values={{
                b: () => (
                  <span styleName="bolded-text">
                    {discountPeriod
                      ? intl.formatMessage(
                          {
                            id: 'account.selfCancel.components.saveOfferCard.percentageDiscount.discountPeriod',
                          },
                          {discountPeriod}
                        )
                      : PRICE_FAILURE}
                  </span>
                ),
              }}
            />
          </View>
          <Flex direction="column" marginBottom="size-150" minHeight="size-800">
            <Text
              data-testid="card-description"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles['green-text']}
            >
              {feature.isEnabled('temp_sophia_promotional_offer') ? (
                <MerchandisingText message={cardText.cardDescription} />
              ) : (
                <MerchandisingText message={description} />
              )}
            </Text>
            <SafePrice
              currency={currency}
              overrideStyles={styles}
              price={nextPrice}
              recurrenceTerm={recurrenceTerm}
            />
          </Flex>
          <Button
            alignSelf="flex-end"
            data-testid="get-details-btn"
            justifySelf="flex-end"
            marginTop="auto"
            onPress={onGetDetailsPress}
            variant="primary"
          >
            {intl.formatMessage({
              id: 'account.selfCancel.components.saveOfferCard.percentageDiscount.getDetailsButton',
            })}
          </Button>
        </SelfCancelCard>
      )}
    </>
  );
};

PercentageDiscountOfferCard.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * The location of the card in the carousel.
   */
  index: PropTypes.number,
  /**
   * Handler that is called when the user press the CTA.
   */
  onCTA: PropTypes.func.isRequired,
  /**
   * The retention id for the Save Offer, used by the component to look it up in ProductsChange
   * response.
   */
  retentionId: PropTypes.string.isRequired,
  /**
   * The cancellation reasons selected
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PercentageDiscountOfferCard;
