/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to get the directory sync domain status
 * @param {Object} directorySyncDomain The imported domain
 * @returns {String} the localized domain status string
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular
    .module('app.widgets.directories.sync')
    .filter('appDirectorySyncDomainStatus', appDirectorySyncDomainStatus);

  /* @ngInject */
  function appDirectorySyncDomainStatus(translationUtils) {
    // Object that maps API status values to localization keys.
    const STATUS_LABELS_MAP = {
      INVALID: 'widgets.directories.sync.directorySyncDomainStatusFilter.status.invalid',
      NOTVERIFIED: 'widgets.directories.sync.directorySyncDomainStatusFilter.status.notVerified',
      UNKNOWN: 'widgets.directories.sync.directorySyncDomainStatusFilter.status.unknown',
      VERIFIED: 'widgets.directories.sync.directorySyncDomainStatusFilter.status.verified',
    };

    return getStatusLabel;

    function getStatusLabel(directorySyncDomain) {
      const key = directorySyncDomain.isInvalid
        ? STATUS_LABELS_MAP.INVALID
        : STATUS_LABELS_MAP[directorySyncDomain.verificationStatus] || STATUS_LABELS_MAP.UNKNOWN;

      return translationUtils.sanitizeSafeInstant(key);
    }
  }
})();
