(function () {
  /**
   * @ngdoc module
   * @name app.core.organizations.access
   * @description defines organization access service
   */
  angular.module('app.core.organizations.access', [
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'binky.core.feature',
    'binky.core.organizations',
  ]);
})();
