import {authentication} from '@admin-tribe/binky';
import {Divider} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * The modal used to sign in.
 */
const AuthenticatingUserModal = ({authenticatedUserId, onClose, unpaidInvoices}) => {
  const intl = useIntl();

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'common.authenticatingUser.cancel',
    }),
    ctaLabel: intl.formatMessage({id: 'common.authenticatingUser.signIn'}),
  });

  const onCta = () => {
    if (unpaidInvoices.length === 1) {
      authentication.switchTo(authenticatedUserId, `${authenticatedUserId}/account`);
    } else {
      authentication.switchTo(
        authenticatedUserId,
        `${authenticatedUserId}/account/billing-history`
      );
    }
  };

  return (
    <ModalDialog
      content={modalDialogContent}
      id="authenticating-user-modal"
      onCancel={onClose}
      onCta={onCta}
      size="L"
    >
      <ModalHeading>{intl.formatMessage({id: 'common.authenticatingUser.header'})}</ModalHeading>
      <Divider />
      <ModalContent>
        {intl.formatMessage({id: 'common.authenticatingUser.description'})}
      </ModalContent>
    </ModalDialog>
  );
};

AuthenticatingUserModal.displayName = 'AuthenticatingUserModal';
AuthenticatingUserModal.propTypes = {
  /**
   * The ID of current authenticated user.
   */
  authenticatedUserId: PropTypes.string.isRequired,
  /**
   * Callback to invoke when the cancel or cta button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * The array of invoices.
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  unpaidInvoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AuthenticatingUserModal;
