import {useStore} from '@admin-tribe/binky-ui';
import {Flex, Text, useDialogContainer} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import {PropTypes} from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import IdpEntity from 'features/settings/entities/IdpEntity';
import useAuthentication from 'features/settings/hooks/directory-setup/useAuthentication';
import useIdpName from 'features/settings/hooks/useIdpName';
import DirectorySetupStore from 'features/settings/stores/DirectorySetupStore';

import './SetDefaultIdpModal.pcss';

const SetDefaultIdpModal = observer(({idp, refreshData}) => {
  const {directoryStore} = useDirectoryContext();
  const dialog = useDialogContainer();
  const directorySetupStore = useStore(() => new DirectorySetupStore(directoryStore));
  const {setDefaultIdp} = useAuthentication(directorySetupStore);

  const currentDefaultIdpName = useIdpName(
    directoryStore.idps.find((idpEntity) => idpEntity.id === idp.defaultIdp)
  );
  const newDefaultIdpName = useIdpName(idp);

  const intl = useIntl();

  const onSetDefaultIdp = async () => {
    const isSuccessful = await setDefaultIdp({idpId: idp.id, idpName: newDefaultIdpName});

    if (isSuccessful) {
      refreshData?.();
      dialog.dismiss();
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      data-test-id="set-default-idp-modal"
      isLoading={directoryStore.isLoading}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={onSetDefaultIdp}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.directories.setDefaulIdpModal.buttons.setDefault',
      })}
      title={intl.formatMessage(
        {id: 'settings.directories.setDefaultIdpModal.title'},
        {idpName: newDefaultIdpName}
      )}
      variant="confirmation"
    >
      <Flex direction="column">
        <Text>
          {intl.formatMessage(
            {id: 'settings.directories.setDefaultIdpModal.description.newIdp'},
            {idpName: newDefaultIdpName}
          )}
        </Text>
        <Text marginTop="size-200">
          {intl.formatMessage(
            {id: 'settings.directories.setDefaultIdpModal.description.currentIdp'},
            {idpNameCurrent: currentDefaultIdpName, idpNameNew: newDefaultIdpName}
          )}
        </Text>
        <GoUrl marginTop="size-200" name="aac_create_directory_federated">
          {intl.formatMessage({
            id: 'settings.directories.setDefaultIdpModal.description.learnMore',
          })}
        </GoUrl>
      </Flex>
    </ModalAlertDialog>
  );
});

SetDefaultIdpModal.propTypes = {
  idp: PropTypes.instanceOf(IdpEntity).isRequired,
  refreshData: PropTypes.func,
};

export default SetDefaultIdpModal;
