/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
import {eventBus} from '@admin-tribe/binky';

let angularListeningEnabled = true;

const HIDE_ANGULAR = 'HIDE_ANGULAR';

/** Waits for the Angular namespace to become available */
const getAngularInstance = () =>
  new Promise((resolve, reject) => {
    let angularInstance = window.angular.element(document.querySelector('.angular-app'));

    if (angularInstance) {
      resolve(angularInstance);
      return;
    }

    let attempts = 0;
    const maxAttempts = 40;
    // Need to poll for this if it's not immediately available
    const interval = setInterval(() => {
      angularInstance = window.angular.element(document.querySelector('.angular-app'));

      if (angularInstance) {
        resolve(angularInstance);
        clearInterval(interval);
      }

      attempts += 1;

      if (attempts > maxAttempts) {
        clearInterval(interval);
        reject(new Error('Angular instance never became available'));
      }
    }, 50);
  });

/** Gets an injectable object from Angular */
const getAngularInjectable = async (injectable) =>
  (await getAngularInstance()).injector().get(injectable);

/** Tells Angular to load a state that is associated with the current browser URL */
const syncUrl = async () => {
  (await getAngularInjectable('$urlService')).sync();
};

/** Tells Angular to listen to URL changes and render states associated with the URL */
const listenToUrl = async (listen) => {
  (await getAngularInjectable('$urlService')).listen(listen);
};

/** Calls a given function with Angular's $timeout so it executes in it's digest loop */
const timeout = async (fn) => {
  (await getAngularInjectable('$timeout'))(fn);
};

/** Emits an event that shell.component.js will use to hide its contents */
const hideAngular = () => {
  eventBus.emit(HIDE_ANGULAR);
};

/** Enables Angular routing and syncs the current URL to an Angular state */
const enableRouting = async () => {
  if (angularListeningEnabled) {
    return;
  }

  angularListeningEnabled = true;

  // Timeout needed so that Angular doesn't load the previous state it was aware of
  // Without this you'll bounce between the last state's URL and next/current state's URL
  await timeout(async () => {
    listenToUrl(true);
    await syncUrl();
  });
};

/** Disables Angular routing */
const disableRouting = async () => {
  if (!angularListeningEnabled) {
    return;
  }

  hideAngular();

  await listenToUrl(false);
  angularListeningEnabled = false;
};

export {enableRouting, disableRouting, HIDE_ANGULAR};
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- In browser */
