import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext, feature} from '@admin-tribe/binky';
import React from 'react';
import {generatePath, matchPath} from 'react-router-dom';

import {
  canShowAdmins,
  canShowUsers,
  canViewDevelopers,
  canViewPermissions,
} from 'core/products/access/productProfileAccess';
import DeviceList from 'features/products/device-licenses/components/device-list/DeviceList';
import ProductProfileApiCredentialsSubpage from 'features/products/pages/product-profile-api-credentials-subpage/ProductProfileApiCredentialsSubpage';
import ProfileAdminsSubpage from 'features/products/pages/profile-admins-subpage/ProfileAdminsSubpage';
import ProfileDevelopersSubpage from 'features/products/pages/profile-developers-subpage/ProfileDevelopersSubpage';
import ProfileUsersSubpage from 'features/products/pages/profile-users-subpage/ProfileUsersSubpage';
import ProductProfilePermissions from 'features/products/product-profiles/product-profile-permissions/ProductProfilePermissions';
import {
  PATH_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_PRODUCT_PROFILE_DETAILS_USERS,
} from 'features/products/routing/productsPaths';

const PRODUCT_PROFILE_DETAILS_TABS_KEYS = {
  ADMINS: 'admins',
  API_CREDENTIALS: 'apiCredentials',
  DEVELOPERS: 'developers',
  DEVICES: 'devices',
  PERMISSIONS: 'permissions',
  USERS: 'users',
};

function getAdminsTab({orgId, product, licenseGroup, intl}) {
  return {
    content: (
      <ProfileAdminsSubpage
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT_CONFIGURATION,
            targetId: licenseGroup.id,
            targetParent: PAGE_TARGET.PRODUCT,
            targetParentId: product.id,
            targetType: PAGE_TARGET_TYPE.ADMIN,
          })
        }
      />
    ),
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.ADMINS,
    name: intl.formatMessage({id: 'products.productProfiles.tab.admins.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_ADMINS, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

// This subpage is visible as /profiles/:profileId/integrations
function getAPICredentialsTab({orgId, product, licenseGroup, intl}) {
  return {
    content: (
      <ProductProfileApiCredentialsSubpage
        licenseGroup={licenseGroup}
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT_CONFIGURATION,
            targetId: licenseGroup.id,
            targetParent: PAGE_TARGET.PRODUCT,
            targetParentId: product.id,
            targetType: PAGE_TARGET_TYPE.INTEGRATION,
          })
        }
      />
    ),
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.API_CREDENTIALS,
    name: intl.formatMessage({id: 'products.productProfiles.tab.APICredentials.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

function getDevelopersTab({orgId, product, licenseGroup, intl}) {
  return {
    content: (
      <ProfileDevelopersSubpage
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT_CONFIGURATION,
            targetId: licenseGroup.id,
            targetParent: PAGE_TARGET.PRODUCT,
            targetParentId: product.id,
            targetType: PAGE_TARGET_TYPE.DEVELOPER,
          })
        }
      />
    ),
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.DEVELOPERS,
    name: intl.formatMessage({id: 'products.productProfiles.tab.developers.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

function getDevicesTab({orgId, product, licenseGroup, intl}) {
  return {
    content: (
      <DeviceList
        isSdl={product.isSharedDeviceLicense()}
        licenseGroupId={licenseGroup.id}
        productId={product.id}
      />
    ),
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.DEVICES,
    name: intl.formatMessage({id: 'products.productProfiles.tab.devices.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_DEVICES, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

function getUsersTab({orgId, product, licenseGroup, intl}) {
  return {
    content: (
      <ProfileUsersSubpage
        licenseGroup={licenseGroup}
        pageContext={
          new PageContext({
            target: PAGE_TARGET.PRODUCT_CONFIGURATION,
            targetId: licenseGroup.id,
            targetParent: PAGE_TARGET.PRODUCT,
            targetParentId: product.id,
            targetType: PAGE_TARGET_TYPE.USER,
          })
        }
      />
    ),
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.USERS,
    name: intl.formatMessage({id: 'products.productProfiles.tab.users.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_USERS, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

function getPermissionsTab({orgId, product, licenseGroup, intl}) {
  return {
    content: <ProductProfilePermissions licenseGroup={licenseGroup} product={product} />,
    key: PRODUCT_PROFILE_DETAILS_TABS_KEYS.PERMISSIONS,
    name: intl.formatMessage({id: 'products.productProfiles.tab.permissions.title'}),
    pathname: generatePath(PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS, {
      orgId,
      productId: product.id,
      profileId: licenseGroup.id,
    }),
  };
}

/**
 * Retrieve the tabs for a Product Profile.
 *
 * @param {Object} params - params for getting tabs
 * @param {String} params.orgId - the ID of the org
 * @param {Product} params.product - a Product model for the Product Profile
 * @param {LicenseGroup} params.licenseGroup - a LicenseGroup model for the Product Profile
 * @param {Object} params.intl - the intl object
 */
function getTabs(params) {
  const tabs = [];

  const {product} = params;

  // Devices tab
  if (feature.isEnabled('temp_enable_react_sdl') && product.isSharedDeviceLicense()) {
    tabs.push(getDevicesTab(params));
  }

  // Users tab
  if (canShowUsers(product)) {
    tabs.push(getUsersTab(params));
  }

  // Admins tab
  if (canShowAdmins(product)) {
    tabs.push(getAdminsTab(params));
  }

  // Permissions tab
  if (canViewPermissions(product)) {
    tabs.push(getPermissionsTab(params));
  }

  // Developers and API credentials tabs
  if (canViewDevelopers(product)) {
    tabs.push(getDevelopersTab(params), getAPICredentialsTab(params));
  }

  return tabs;
}

/**
 * Determine the key for the Product Details Page tab based on the given path
 * @returns {string} The tab key which matches the path
 * @throws {Error} if the path does not match any of the known paths
 */
function findKeyByPath(location) {
  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_ADMINS, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.ADMINS;
  }

  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.API_CREDENTIALS;
  }

  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.DEVELOPERS;
  }

  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_DEVICES, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.DEVICES;
  }

  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_USERS, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.USERS;
  }

  if (matchPath(PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS, location.pathname)) {
    return PRODUCT_PROFILE_DETAILS_TABS_KEYS.PERMISSIONS;
  }

  throw new Error('Unable to determine product profile details tabs key by path');
}

export {findKeyByPath, getTabs};
