import {PageBanner, PageBannerConstants} from '@admin-tribe/binky-ui';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {
  checkDeIsEnabled,
  getBannerVariant,
} from 'features/settings/common/utils/domainEnforcementUtils';

/**
 * Banner for Domain Enforcement, displayed in the directory details page
 */

const DomainEnforcementDirectoryDetailsBanner = ({state, stateEndsAt}) => {
  const intl = useIntl();

  const isDeEnabled = checkDeIsEnabled(state);

  const getBannerData = () => {
    // if Domain Enforcement is enabled(activated or enforced), show the success variant
    if (isDeEnabled) {
      return {
        description: 'settings.domainEnforcement.directoryDetailsBanner.activated.description',
        title: 'settings.domainEnforcement.directoryDetailsBanner.activated.title',
        variant: PageBannerConstants.variant.SUCCESS,
      };
    }

    return {
      description: 'settings.domainEnforcement.directoryDetailsBanner.description',
      title: 'settings.domainEnforcement.directoryDetailsBanner.title',
      variant: getBannerVariant(stateEndsAt),
    };
  };

  const bannerData = getBannerData();

  return (
    <PageBanner
      header={intl.formatMessage({
        id: bannerData.title,
      })}
      showDefaultButton={false}
      variant={bannerData.variant}
    >
      <FormattedMessage
        id={bannerData.description}
        values={{
          b: (text) => <strong>{text}</strong>,
          goUrl: (linkText) => <GoUrl name="aac_restricting_domains">{linkText}</GoUrl>,
        }}
      />
    </PageBanner>
  );
};

DomainEnforcementDirectoryDetailsBanner.propTypes = {
  /**
   * Current status of the Policy
   */
  state: PropTypes.string.isRequired,
  /**
   *  Date when the current state phase ends
   */
  stateEndsAt: PropTypes.number,
};

export default DomainEnforcementDirectoryDetailsBanner;
