(function () {
  /**
   * @deprecated Please use src2/app/core/products/trial-helper/trialHelper.js
   *
   * @ngdoc service
   * @name app.core.product.trial-helper
   * @description business team trial helper factory
   */
  angular.module('app.core.product.trial-helper').factory('trialHelper', getTrialHelper);

  /* @ngInject */
  function getTrialHelper(
    $log,
    $q,
    $window,
    _,
    AuthenticatedUser,
    binkyDateTimeFilter,
    binkySrc2,
    configurationReady,
    locale,
    moment,
    OrganizationManager,
    OrganizationUser,
    PRODUCT_APPLICABLE_OFFER_TYPE,
    TRIAL_STATUS
  ) {
    const service = {
      canTrialAdminViewQAModalInFirstSession,
      getDxTrialContract,
      getTrialDuration,
      getTrialEndDateString,
      getTrialOwnerUserId,
      getTrialProduct,
      getTrialStatusInfo,
      isExpiredTrial,
      isLoggedInUserTrialOwner,
      isTrialProduct,
      openBuyNowUrl,
    };

    return service;

    //////////////

    /**
     * @description Returns a promise that resolves to true if user is trial admin and if the only product assigned
     * is the trials product assigned to the trial admin.
     *
     * @returns {Promise} resolves to true if user is trial admin and if the only product assigned is the trials product
     * to them. Resolves to false otherwise.
     */
    function canTrialAdminViewQAModalInFirstSession() {
      const productList = OrganizationManager.getProductsForActiveOrg();
      if (!isLoggedInUserTrialOwner() || productList.getTotalProvisionedQuantity() !== 1) {
        return $q.resolve(false);
      }

      const trialProduct = _.find(
        productList.items,
        (product) => isTrialProduct(product) && product.provisionedQuantity === 1
      );

      if (trialProduct) {
        const trialAdminId = getTrialOwnerUserId();
        const trialAdmin = OrganizationUser.get({userId: trialAdminId});

        return trialAdmin.$promise.then(() => {
          const matchedProduct = _.find(trialAdmin.products, {id: trialProduct.id});
          return !_.isUndefined(matchedProduct);
        });
      }
      return $q.resolve(false);
    }

    /**
     * @description Returns the first trial contract that also has buying program set to ENTERPRISE_PRODUCT.
     * The contract must be from the Contract Service so that it uses the model property.
     * @param {ContractList} contractList list of contracts used to find
     * @returns {Contract} returns the contract or undefined if not found.
     */
    function getDxTrialContract(contractList) {
      return contractList.items.find(
        (contract) => contract.isBuyingProgramEnterpriseProduct() && contract.isTrial()
      );
    }

    /**
     * @description Determines the length of the trial, in days.
     *
     * @deprecated moved to src2
     *
     * @param {Contract} trialContract - The trial contract.
     * @returns {Integer} returns the trial duration in days if there is a trial contract with valid start and end
     *  dates, otherwise returns 0.
     */
    function getTrialDuration(trialContract) {
      if (trialContract) {
        const startDateISOString = trialContract.getStartDate();
        const endDateISOString = trialContract.getEndDate();

        if (startDateISOString && endDateISOString) {
          const startDate = moment(startDateISOString);
          const endDate = moment(endDateISOString);
          return endDate.diff(startDate, 'days');
        }
      }

      return 0;
    }

    /**
     * @description Returns the formatted end date of the trial contract.
     *
     * @param {Product} product - The product to use to determine the trial's end date.
     * @returns {String} returns the end date of the trial.
     *    If the product is not a trial or the end date is not defined, returns undefined.
     */
    function getTrialEndDateString(product) {
      const trialStatus = getTrialStatusInfo(product);
      return _.isNaN(trialStatus.endDate) || _.isUndefined(trialStatus.endDate)
        ? undefined
        : binkyDateTimeFilter(trialStatus.endDate, {dateOnly: true});
    }

    /**
     * @description Returns the user id of the trial contract owner.
     *
     * @returns {String} returns the user id of trial contract owner, else undefined if trial contract does not exist.
     */
    function getTrialOwnerUserId() {
      const trialContract = OrganizationManager.getContractsForActiveOrg().getTrialContract();
      return trialContract ? _.invoke(trialContract, 'getOwnerUserId') : undefined;
    }

    /**
     * @description Returns the first trial product found on the contract.
     *   Note: this will return undefined if the product still has a virtual license, ie if
     *   isTrialVirtualLicense(product) returns true.
     *
     * @deprecated moved to src2
     *
     * @param {Contract} [trialContract] - The trial contract. Default is the first trial contract found.
     * @returns {Product} returns the first trial product it finds on the contract, else undefined.
     */
    function getTrialProduct(trialContract) {
      const contract =
        trialContract || OrganizationManager.getContractsForActiveOrg().getTrialContract();

      if (contract) {
        const productList = OrganizationManager.getProductsForActiveOrg();
        return _.find(productList.items, (product) =>
          _.invoke(product, 'hasContractId', contract.id)
        );
      }
      return undefined;
    }

    /**
     * @description Returns the trial status for a given product.
     *   Note: this will return TRIAL_STATUS.NOT_TRIAL if the product still has a virtual license, ie if
     *   isTrialVirtualLicense(product) returns true.
     *
     * @param {Product} [product] - The product to use to determine trial status
     * @returns {Object} with status of TRIAL_STATUS, endDate and daysLeft.
     *   The 'endDate' is the date the trial contract ends.
     *   The 'daysLeft' is negative for an expired trial, 0 for the
     *   last day of a trial and positive for the days remaining in the trial.
     *   If the product is a trial the return object will be:
     *      {daysLeft: {Integer}, endDate: {Date}, status: {any TRIAL_STATUS except NOT_TRIAL}}.
     *   If product isn't specified or is not a trial, the return will be:
     *      {daysLeft: NaN, endDate: NaN, status: TRIAL_STATUS.NOT_TRIAL}.
     */
    function getTrialStatusInfo(product) {
      let daysLeft = NaN;
      let endDate = NaN;
      let status = TRIAL_STATUS.NOT_TRIAL;

      const contractList = OrganizationManager.getContractsForActiveOrg();
      const contractIds = _.get(product, 'contractIds', []);
      let trialContract;
      _.forEach(contractIds, (contractId) => {
        const contract = contractList.getTrialContract(contractId);
        if (contract) {
          trialContract = contract;
        }
      });
      daysLeft = _.result(trialContract, 'getDaysLeft', NaN);
      endDate = _.result(trialContract, 'getEndDate', NaN);
      if (_.isInteger(daysLeft)) {
        if (daysLeft <= 0) {
          status = TRIAL_STATUS.EXPIRED;
        } else if (daysLeft === 1) {
          status = TRIAL_STATUS.LAST_DAY;
        } else if (daysLeft <= 3) {
          status = TRIAL_STATUS.DAYS_LEFT_WARNING;
        } else {
          status = TRIAL_STATUS.DAYS_LEFT_INFO;
        }
      }
      return {daysLeft, endDate, status};
    }

    /**
     * @description Returns true if product is a trial and it has expired.
     *
     * @deprecated moved to src2
     *
     * @param {Product} [product] - The product.
     * @returns {Boolean} returns true if the product is a trial and the trial has expired, else false.
     */
    function isExpiredTrial(product) {
      const contractList = OrganizationManager.getContractsForActiveOrg();
      return _.some(
        contractList.getContractsForProduct(product),
        (contract) => !!contract && contract.isTrial() && contract.isStatusExpired()
      );
    }

    /**
     * @description Returns true if the logged in user is the trial contract owner.
     *
     * @returns {Boolean} returns true if the logged in user is the trial contract owner, else false.
     */
    function isLoggedInUserTrialOwner() {
      return getTrialOwnerUserId() === AuthenticatedUser.get().getId();
    }

    /**
     * @description Determines if the product is a trial license (vs a paid license).
     *
     * @deprecated moved to src2
     *
     * @param {Product} [product] - The product to use to determine trial status.
     * @returns {Boolean} returns true if the product is a trial, else false.
     */
    function isTrialProduct(product) {
      const contractIds = _.get(product, 'contractIds');
      if (!contractIds || contractIds.length === 0) {
        return isTrialVirtualLicense(product);
      }
      return _.some(contractIds, (contractId) =>
        OrganizationManager.getContractsForActiveOrg().hasTrialContractForId(contractId)
      );
    }

    /**
     * @description Constructs the jump url for the trial conversion page and then opens it in a separate tab.
     *   The trial conversion page allows the admin to covert the given trial to a paid product.
     *   The jump url allows the admin to switch contexts to the trial conversion page and maintain their
     *   authorization session so that they don't have to re-login.
     *
     *  Business trials conversion page spec: https://wiki.corp.adobe.com/x/9bgvd
     *    Note: Commerce does not support traditional Chinese (zh-hant) so if lang="zh-hant" it defaults to English.
     *  IMS Jumptoken API: https://wiki.corp.adobe.com/x/EAXEIg
     *
     * @deprecated moved to src2
     *
     * @param {String} productId - The product id/license id for the trial product to buy now.
     * @returns {Promise} If resolved, the trial conversion page has been opened in a new tab.
     */
    function openBuyNowUrl(productId) {
      const conf = configurationReady.getConfig();
      const trialConversionPageUrl = _.get(conf, 'services.commerce.url');

      if (_.isString(productId) && trialConversionPageUrl) {
        const oid = $window.encodeURIComponent(OrganizationManager.getActiveOrgId());
        const pid = $window.encodeURIComponent(productId);
        const lang = locale.getActiveLanguageBCP47Code();

        const targetRedirectUri =
          `${trialConversionPageUrl}/business-trial/conversion?cli=admin_console` +
          `&oid=${oid}` +
          `&pid=${pid}` +
          `&lang=${lang}`;

        return $q((resolve, reject) => {
          binkySrc2.api.ims
            .postJumpToken({
              targetClientId: 'commerce_apps_amsterdam_client',
              targetRedirectUri,
              targetScope: 'AdobeID,openid,additional_info.roles,read_organizations',
            })
            .then((response) => onSuccess(response.data))
            .catch((error) => onError(error.response));

          function onError(error) {
            $log.error(
              `Could not get jumptoken for trial 'Buy now': ${JSON.stringify(
                _.pick(error, ['data', 'status'])
              )}`
            );
            reject();
          }

          function onSuccess(response) {
            // Open up a new tab using the jump url.
            if ($window.open(response.jump, '_blank') === null) {
              $log.error(`Could not open new tab for trial 'Buy now': ${response.jump}`);
              reject();
            }
            resolve();
          }
        });
      }

      return $q.reject();
    }

    /////////////

    // When the trial is created, before the actual trial contract has finished being created
    // there is a virtual license in Renga so the Quick Assign modal works immediately.
    // The virtual license has a targetExpression of the form 'guid:delegation_intent' and since it
    // is not yet associated with the trial contract, its contractId is still undefined.
    // Post-fulfillment, the actual trial license is created with targetExpression 'delegation'
    // that is associated with trial contractId.
    //
    // The decision has been made that checking just for the absence of a contractId and an
    // applicableOfferType of TRIAL is enough since the check for targetExpression could be brittle.

    /**
     * @deprecated moved to src2
     * @param {Product} product the product
     * @return {Boolean} whether its a virtual license
     */
    function isTrialVirtualLicense(product) {
      return !!(
        product &&
        _.isUndefined(product.contractId) &&
        product.applicableOfferType === PRODUCT_APPLICABLE_OFFER_TYPE.TRIAL
      );
    }
  }
})();
