(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular
    .module('app.widgets.directories.enhanced-saml')
    .component('appEnhancedSamlDirectoryEditModal', {
      bindings: {
        configurationUpdated: '&?',
      },
      controller,
      templateUrl:
        'app/widgets/directories/enhanced-saml/enhanced-saml-directory-edit-modal/enhanced-saml-directory-edit-modal.component.html',
    });

  // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
  function controller(
    $scope,
    $timeout,
    $translate,
    $q,
    _,
    APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID,
    ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS,
    feature,
    IDP_ERROR_CODES,
    onesieSrc2,
    panelManager
  ) {
    // Stores opacity transition duration for `._coral-Dialog` (delay + transition)
    const MODAL_TRANSITION_DURATION = 410;

    // Error code used when we're not sure what happened
    const GENERIC_ERROR = 'GENERIC_ERROR';

    // Object holding error translation key for the types of
    // error that could occur at save
    const ERRORS = {
      [IDP_ERROR_CODES.CANNOT_READ_METADATA]: 'cannotReadMetadata',
      [IDP_ERROR_CODES.EMPTY_METADATA]: 'emptyMetadata',
      [IDP_ERROR_CODES.INVALID_METADATA]: 'invalidMetadata',
      [IDP_ERROR_CODES.INVALID_XML]: 'invalidXml',
      [IDP_ERROR_CODES.MULTIPLE_ENTITY_DESCRIPTORS]: 'multipleEntityDescriptors',
      [IDP_ERROR_CODES.NO_IDP_SSO_DESCRIPTOR]: 'noIdpSsoDescriptor',
      [IDP_ERROR_CODES.UNRECOGNIZED_METADATA_FORMAT]: 'unrecognizedMetadataFormat',
      [IDP_ERROR_CODES.MALFORMED_SSO_URL]: 'malformedSsoUrl',
      [IDP_ERROR_CODES.NO_SSO_SERVICE_PRESENT]: 'noSsoServicePresent',
      [GENERIC_ERROR]: 'uploadFailed',
    };

    const vm = this;

    _.assign(vm, {
      $onInit,
      _,
      close,
      isGoogleIdp,
      isMfaSamlControlsEnabled,
      isSaveButtonDisabled,
      onAttributesChanged,
      onConfigurationComplete,
      onConfigurationFileChanged,
      onConfigurationProcessingError,
      onConfigurationProcessingStarted,
      onConfigurationValidationStatusChanged,
      onesieSrc2,
      onFileChanged,
      onOpen,
      save,
      showGenericSamlConfiguration,
      showGoogleSamlConfiguration,
    });

    function $onInit() {
      _.assign(vm, {
        error: null,
        file: null,
        modalId: APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID,
        waitPromise: $q.resolve(),
      });

      vm.configurationUpdated = vm.configurationUpdated || _.noop;
    }

    function onOpen(params = {}) {
      if (vm.authSource) {
        resetModal();
      }

      vm.authSource = params.authSource;
      vm.isGoogleSelected = params.isGoogleSelected;
      vm.configurationUpdated = params.configurationUpdated || vm.configurationUpdated;
      vm.attributesChanged = false;

      if (isGoogleIdp() || isMfaSamlControlsEnabled()) {
        const idp = vm.authSource.getSamlIdp();

        vm.entityId = idp.getEntityId();
        vm.acsUrl = idp.getAcsUrl();
        vm.isIdpActive = idp.isActive();

        vm.attributes = {
          mfaAuthnContextClasses: idp.getMfaAuthnContextClasses(),
          reauthAuthnContextClasses: idp.getReauthAuthnContextClasses(),
        };

        // Add an empty metadata file to simulate a selection
        if (idp.isActive()) {
          vm.file = new File([], '');
        }
      }
    }

    function close() {
      panelManager.close(APP_ENHANCED_SAML_DIRECTORY_EDIT_MODAL_ID);

      // Reset modal in the next digest to prevent user from briefly seeing
      // the reset state of the modal upon closing it.
      $timeout(resetModal, MODAL_TRANSITION_DURATION);
    }

    function save() {
      if (isGoogleIdp() || isMfaSamlControlsEnabled()) {
        const idp = vm.authSource.getSamlIdp();

        vm.waitPromise = $q.resolve();

        vm.waitPromise
          .then(() => {
            if (vm.file.size > 0) {
              return idp.uploadMetadataFile(vm.file);
            }
            return $q.resolve();
          })
          .then(() => {
            if (
              vm.attributes.reauthAuthnContextClasses !== idp.getReauthAuthnContextClasses() ||
              vm.attributes.mfaAuthnContextClasses !== idp.getMfaAuthnContextClasses()
            ) {
              idp.mfaAuthnContextClasses = vm.attributes.mfaAuthnContextClasses;
              idp.reauthAuthnContextClasses = vm.attributes.reauthAuthnContextClasses;

              return idp.save();
            }

            return $q.resolve();
          })
          .then(onConfigurationComplete)
          .catch((error) => {
            showErrorBanner(_.get(error, 'data.error'));
          });
      } else {
        $scope.$broadcast(
          ENHANCED_SAML_CONFIGURATION_STEPS_EVENTS.SAVE_ENHANCED_SAML_CONFIGURATION
        );
      }
    }

    // Configuration events handlers
    function onConfigurationProcessingStarted() {
      vm.waitPromise = $q.defer().promise;
    }

    function onConfigurationProcessingError() {
      vm.waitPromise = $q.resolve();
    }

    function onConfigurationComplete() {
      // Get a promise from configuration update or resolve immediately
      // if no promise provided
      const resultPromise = vm.configurationUpdated() || $q.resolve();

      // close the modal only after the promise finished
      // regardless of the result of the promise
      return resultPromise.finally(close);
    }

    function onConfigurationValidationStatusChanged(isValid) {
      vm.isValid = isValid;
    }

    function onConfigurationFileChanged() {
      vm.hasChanges = true;
    }

    function isGoogleIdp() {
      if (!vm.authSource) {
        return false;
      }

      const idp = vm.authSource.getSamlIdp();
      return vm.isGoogleSelected || idp.isGoogle();
    }

    function isSaveButtonDisabled() {
      return !vm.hasChanges || !vm.isValid;
    }

    function showGenericSamlConfiguration() {
      return vm.authSource && !isGoogleIdp();
    }

    function isMfaSamlControlsEnabled() {
      return feature.isEnabled('temp_mfa_saml_controls');
    }

    function showGoogleSamlConfiguration() {
      return vm.authSource && isGoogleIdp();
    }

    function onFileChanged(file) {
      $scope.$evalAsync(() => {
        vm.file = file;

        if (file && file.size > 0) {
          vm.isValid = true;
          vm.hasChanges = true;
        } else if (_.isNull(file)) {
          vm.isValid = false;
        }
      });
    }

    function onAttributesChanged(value) {
      $scope.$evalAsync(() => {
        vm.attributes = _.assign({}, value);
        vm.hasChanges = true;
        vm.isValid = true;
      });
    }

    /////////////

    /**
     * Shows the error banner with the corresponding message.
     *
     * @param {string} errorCode A value from the ERROR_CODES map or
     *                           a returned error code
     */
    function showErrorBanner(errorCode) {
      const errorTranslationKey = ERRORS[errorCode] || ERRORS[GENERIC_ERROR];

      vm.error = {
        body: `widgets.directories.enhancedSaml.configureSaml.errors.${errorTranslationKey}.body`,
        title: `widgets.directories.enhancedSaml.configureSaml.errors.${errorTranslationKey}.title`,
      };
    }

    function resetModal() {
      _.assign(vm, {
        authSource: null,
        file: null,
        hasChanges: false,
        isValid: false,
        waitPromise: $q.resolve(),
      });
    }
  }
})();
