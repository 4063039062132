import {
  EVENT_ACTION,
  ProductList,
  dispatchPageAnalytics,
  dispatchUiEventAnalytics,
  log,
} from '@admin-tribe/binky';
import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef} from 'react';

import {
  generateClickEventName,
  getCardImpressions,
  mapProductListToAnalytics,
  mapSelfCancelStateToAnalytics,
} from '../../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../../SelfCancelConstants';
import {useProductsChangeContext} from '../products-change-context/ProductsChangeContext';

// A list of steps that will not automatically dispatch an analytics page event
const EXCLUDE_AUTO_PAGE_EVENT = {
  [SELF_CANCEL_STEPS.INITIAL_LOADING_STEP]: true,
  [SELF_CANCEL_STEPS.LOSS_AVERSION_STEP]: true,
  [SELF_CANCEL_STEPS.SAVE_OFFERS_STEP]: true,
  [SELF_CANCEL_STEPS.SAVE_OFFER_REVIEW_STEP]: true,
  [SELF_CANCEL_STEPS.DONE_DISCOUNT_OFFER_STEP]: true,
  [SELF_CANCEL_STEPS.DONE_FREE_MONTHS_OFFER_STEP]: true,
};

const SELF_CANCEL_ANALYTICS_STATES = {
  [SELF_CANCEL_STEPS.BUMPER_STEP]: 'entryExclusionBanner',
  [SELF_CANCEL_STEPS.CANCEL_REVIEW_STEP]: 'cancelReview',
  [SELF_CANCEL_STEPS.CANCELLATION_REASONS_STEP]: 'feedBack',
  [SELF_CANCEL_STEPS.DONE_DISCOUNT_OFFER_STEP]: 'discountOffer',
  [SELF_CANCEL_STEPS.DONE_FREE_MONTHS_OFFER_STEP]: 'freeOffer',
  [SELF_CANCEL_STEPS.DONE_FULL_CANCEL_STEP]: 'fullCancelComplete',
  [SELF_CANCEL_STEPS.DONE_PARTIAL_CANCEL_STEP]: 'partialCancelComplete',
  [SELF_CANCEL_STEPS.ERROR_STEP]: 'errorPage',
  [SELF_CANCEL_STEPS.EXIT_STEP]: 'exitPage',
  [SELF_CANCEL_STEPS.LOSS_AVERSION_STEP]: 'details',
  [SELF_CANCEL_STEPS.SAVE_OFFER_REVIEW_STEP]: 'discountOfferReview',
  [SELF_CANCEL_STEPS.SAVE_OFFERS_STEP]: 'offers',
  [SELF_CANCEL_STEPS.SELECT_LICENSES_STEP]: 'cancelLicencesModal',
};

/**
 * The Context for Self Cancel shared dispatch functions. When React renders a component that subscribes to
 * this Context it will read the current context value from the closest matching Provider above.
 */
const SelfCancelAnalyticsContext = createContext({});

/**
 * @description A helper hook to retrieve analytics functions for the Self Cancel Modal.
 * @returns {Object} object - An object with helper functions
 *          {Function} object.dispatchSelfCancelUiEventAnalytics - Dispatch a UI Event with the necessary context.
 *          {Function} object.dispatchNavigationAnalyticsEvent - Dispatch a Navigational UI Event with the necessary context.
 */
const useSelfCancelAnalyticsContext = () => useContext(SelfCancelAnalyticsContext);

/**
 * Self Cancel Analytics Provider used to dispatch page events during step changes in the modal,
 * and to surface a helper function to dispatch enriched analytics UI events.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- Better to keep these together
const SelfCancelAnalyticsContextProvider = ({
  children,
  comment,
  currentStep,
  productList,
  selectedReasons,
}) => {
  const {productsChange} = useProductsChangeContext();
  const lastStep = useRef();

  const dispatchSelfCancelPageAnalytics = useCallback(
    ({cards, retentionId, step, ...state}) => {
      const stepPageName = SELF_CANCEL_ANALYTICS_STATES[step] ?? step;

      let options = {
        ...state,
      };

      if (cards) {
        options.interaction = {
          impression: getCardImpressions({cards}),
        };
      }

      // The Select Licenses step requires the product list context
      if (step === SELF_CANCEL_STEPS.SELECT_LICENSES_STEP && productList) {
        const productListContext = mapProductListToAnalytics({productList});
        options = {
          ...options,
          ...productListContext,
        };
      } else if (step !== SELF_CANCEL_STEPS.SELECT_LICENSES_STEP) {
        const productsChangeContext = mapSelfCancelStateToAnalytics({
          comment,
          productsChange,
          retentionId,
          selectedReasons,
        });
        options = {
          ...options,
          ...productsChangeContext,
        };
      }

      try {
        dispatchPageAnalytics({
          ...options,
          eventAction: 'load',
          eventName: 'selfCancelModal',
          name: `cancelFlow:${stepPageName}:load`,
        });
      } catch (error) {
        log.error('Self Cancel Analytics: Unable to dispatch page analytics', error);
      }
    },
    [comment, productList, productsChange, selectedReasons]
  );

  const dispatchSelfCancelUiEventAnalytics = useCallback(
    ({card, eventAction = EVENT_ACTION.CLICK, eventName, retentionId}) => {
      const options = mapSelfCancelStateToAnalytics({
        comment,
        productsChange,
        retentionId,
        selectedReasons,
      });

      if (card) {
        options.interaction = {
          click: getCardImpressions({cards: [card]}),
        };
      }

      try {
        dispatchUiEventAnalytics({
          ...options,
          eventAction,
          eventName,
        });
      } catch (error) {
        log.error('Self Cancel Analytics: Unable to dispatch ui event analytics', error);
      }
    },
    [comment, productsChange, selectedReasons]
  );

  const dispatchNavigationAnalyticsEvent = useCallback(
    ({clickType, eventAction = EVENT_ACTION.CLICK, retentionId, interaction} = {}) => {
      if (clickType && currentStep) {
        const options = mapSelfCancelStateToAnalytics({
          comment,
          productsChange,
          retentionId,
          selectedReasons,
        });
        const eventName = generateClickEventName(clickType, currentStep);

        try {
          dispatchUiEventAnalytics({
            interaction,
            ...options,
            eventAction,
            eventName,
          });
        } catch (error) {
          log.error('Self Cancel Analytics: Unable to dispatch ui event analytics', error);
        }
      } else {
        log.error(
          'Unable to dispatch navigational click analytics event',
          currentStep,
          clickType,
          EVENT_ACTION.CLICK
        );
      }
    },
    [comment, currentStep, productsChange, selectedReasons]
  );

  // Send state event when current step changes
  useEffect(() => {
    if (lastStep.current === currentStep) {
      return;
    }

    lastStep.current = currentStep;

    // These steps are either ignored for page analytics, or are responsible for
    // dispatching their own page analytics.
    if (EXCLUDE_AUTO_PAGE_EVENT[currentStep]) {
      return;
    }

    // This should be moved into the EXCLUDE_AUTO_PAGE_EVENT once the feature flag
    // is removed.
    if (currentStep === SELF_CANCEL_STEPS.CANCELLATION_REASONS_STEP) {
      return;
    }

    dispatchSelfCancelPageAnalytics({step: currentStep});
  }, [currentStep, dispatchSelfCancelPageAnalytics]);

  const value = useMemo(
    () => ({
      dispatchNavigationAnalyticsEvent,
      dispatchSelfCancelPageAnalytics,
      dispatchSelfCancelUiEventAnalytics,
    }),
    [
      dispatchNavigationAnalyticsEvent,
      dispatchSelfCancelPageAnalytics,
      dispatchSelfCancelUiEventAnalytics,
    ]
  );

  return (
    <SelfCancelAnalyticsContext.Provider value={value}>
      {children}
    </SelfCancelAnalyticsContext.Provider>
  );
};

SelfCancelAnalyticsContextProvider.propTypes = {
  /**
   * The contents of the provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * The user-provided cancellation reason text.
   */
  comment: PropTypes.string,
  /**
   * The current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * The product list for the organization entering the self cancel flow.
   */
  productList: PropTypes.instanceOf(ProductList),
  /**
   * The cancellation reasons selected by the user.
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
};

export {
  useSelfCancelAnalyticsContext,
  SelfCancelAnalyticsContext,
  SelfCancelAnalyticsContextProvider,
};
