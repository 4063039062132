import {JilModelList, log, modelCache} from '@admin-tribe/binky';

import jilStorageRepositories from '../../api/jil/jilStorageRepositories';
import StorageRepository from '../../entities/storage-repository/StorageRepository';

import STORAGE_REPOSITORY_LIST_CONSTANTS from './StorageRepositoryListConstants';

const {CACHE_ID} = STORAGE_REPOSITORY_LIST_CONSTANTS;

class StorageRepositoryList extends JilModelList {
  /**
   * @description Creates a new StorageRepositoryList for use.
   *  For now, there will only be at most one repository per org.
   *  The API calls are designed to account for more so this list
   *  aligns with that in the case that more repositories exist
   *  in the future.
   *
   * @param {Object} options Initialization Object containing params to use with JilModelList
   */
  constructor(options = {}) {
    super({
      isCacheable: true,
      itemClassRef: StorageRepository,
      modelCacheId: CACHE_ID,
      resource: jilStorageRepositories.getStorageRepositories,
      ...options, // Override defaults
    });
  }

  /**
   * @description Saves the list of repository names that have been modified to the back-end.
   *
   * @param {Array<Repository>} repositories The array of repositories that have been modified.
   * @returns {Promise} resolves to StorageQuota on success, else rejects with error.
   */
  async save(repositories) {
    if (repositories.some((repository) => !(repository instanceof StorageRepository))) {
      const error =
        'StorageRepositoryList failed to save. Error: not all array items are storage repositories.';
      log.error(error);
      return Promise.reject(error);
    }

    const operations = repositories.map((repository) => ({
      op: 'replace',
      path: `${repository.id}/name`,
      value: repository.name,
    }));

    try {
      await jilStorageRepositories.patchStorageRepositories(operations);
      // Remove outdated repositories
      this.items = this.items.filter((item) => !isModified(item, repositories));
      // Add updated repositories
      this.items = [...this.items, ...repositories];
      // Remove when https://jira.corp.adobe.com/browse/ONESIE-25876 is fixed.
      // Remove the stale list first because updating an existing entry may not work.
      modelCache.remove(CACHE_ID, this.getKey());
      modelCache.put(CACHE_ID, this.getKey(), this);
    } catch (error) {
      log.error('StorageRepositoryList failed to save. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

function isModified(currentRepository, modifiedRepositories) {
  return modifiedRepositories.some((repository) => repository.id === currentRepository.id);
}

export default StorageRepositoryList;
