import {AnalyticsEvent} from '@admin-tribe/binky';

import {
  ORDERED_BULK_OPERATIONS,
  ORDERED_EXPORT_OPERATIONS,
  ORDERED_RESULT_OPERATIONS,
} from 'common/entities/user-operations/UserOperationsConstants';

/**
 * @description Method to construct the More options operations menu for any of the User pages.
 *
 * @param {Object} intl - for messages
 * @param {UserOperation} userOperations - used to customize the menu for the particular user
 * @returns {Array} menuItems - Formatted for ActionMenu items for a sectioned menu.
 */
function getMenuItems({intl, userOperations}) {
  const menuItems = [];

  // These are the ordered sections of the Users Operations menu.
  // There will be a seperator between each section.
  const bulkOpsChildren = [];
  const exportChildren = [];
  const resultChildren = [];

  function constructSection({'aria-label': ariaLabel, children, operationToKeyMap, sectionId}) {
    operationToKeyMap.forEach((item) => {
      if (userOperations[item.op]) {
        const key = item.key;
        children.push({
          key,
          name: intl.formatMessage({
            id: `${userOperations.msgNamespace}.${key}`,
          }),
        });
      }
    });

    if (children.length > 0) {
      menuItems.push({'aria-label': ariaLabel, children, id: sectionId});
    }
  }

  constructSection({
    'aria-label': intl.formatMessage({
      id: 'common.userListTableActions.userOperationsMenu.section.bulkOperations',
    }),
    children: bulkOpsChildren,
    operationToKeyMap: ORDERED_BULK_OPERATIONS,
    sectionId: 'bulk-operations',
  });
  constructSection({
    'aria-label': intl.formatMessage({
      id: 'common.userListTableActions.userOperationsMenu.section.exportOperations',
    }),
    children: exportChildren,
    operationToKeyMap: ORDERED_EXPORT_OPERATIONS,
    sectionId: 'export-operations',
  });
  constructSection({
    'aria-label': intl.formatMessage({
      id: 'common.userListTableActions.userOperationsMenu.section.resultOperations',
    }),
    children: resultChildren,
    operationToKeyMap: ORDERED_RESULT_OPERATIONS,
    sectionId: 'result-operations',
  });

  return menuItems;
}

/**
 * @description Method to call to trigger an analytics event.
 */
function triggerAnalytics({analyticsKey, attributes = {}, pageContext, userOperations}) {
  if (userOperations.offerId) {
    Object.assign(attributes, {offerId: userOperations.offerId});
  }

  AnalyticsEvent.dispatch({
    attributes,
    componentMethod: analyticsKey,
    componentMethodType: 'submit',
    componentName: 'appUsersAll',
    pageContext,
  });
}

export {getMenuItems, triggerAnalytics};
