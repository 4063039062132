import {Store} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable} from 'mobx';

import TopicIdentifierUtils from '../../core/TopicIdentifierUtils';

// Domain data for help breadcrumbs
class BreadcrumbStore extends Store {
  constructor() {
    super();

    Object.assign(this, {
      items: [], // String[]
    });

    makeObservable(this, {
      count: computed,
      currentCrumb: computed,
      items: observable,
      previousCrumb: computed,
      set: action.bound,
      update: action.bound,
    });
  }

  get count() {
    return this.items.length;
  }

  get currentCrumb() {
    if (this.count > 0) {
      return this.items[this.items.length - 1];
    }
    return undefined;
  }

  get previousCrumb() {
    if (this.count > 1) {
      return this.items[this.items.length - 2];
    }
    return undefined;
  }

  /**
   * @description Method to set a new path to the Help section breadcrumbs.
   *   Setting a path differs from updating (i.e. - update() method) in that
   *   the existing breadcrumbs are overwritten by the new path. If this
   *   behavior is not desired, then the update method should be used instead.
   * @param {TopicIdentifier} topicIdentifier - dot-separated path into help
   *   content that breacrumbs should be created/set to
   */
  set(topicIdentifier) {
    this.items = TopicIdentifierUtils.getCrumbs(topicIdentifier);
  }

  /**
   * @description Method to update a new path to the Help section breadcrumbs.
   *   Updating a path differs from setting (i.e. - set() method) in that the
   *   existing breadcrumbs are retained, unless the new topic identifier is
   *   equal to the last crumb (i.e. - the user has traversed up the
   *   breadcrumb trail). If this behavior is not desired, then the set method
   *   should be used instead.
   * @param {TopicIdentifier} topicIdentifier - dot-separated path into help
   *   content that should be appended to breadcrumbs
   */
  update(topicIdentifier) {
    if (this.items.length === 0) {
      // no breadcrumbs set, so set to this path
      this.set(topicIdentifier);
    } else if (
      this.items.length > 1 &&
      TopicIdentifierUtils.getPath(topicIdentifier) === this.items[this.items.length - 2]
    ) {
      // update is parent breadcrumb, so drop last crumb (child crumb)
      this.items = this.items.slice(0, -1); // drop last breadcrumb
    } else {
      // update is child breacrumb, so append to crumbs
      this.items.push(TopicIdentifierUtils.getPath(topicIdentifier));
    }
  }
}

export default BreadcrumbStore;
