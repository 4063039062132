import {
  WIDGET_ID as jitRuleAutoCreateId,
  widgetComponents as jitRuleAutoCreateWidgetComponents,
} from './jit-rule-auto-create/jitRuleAutoCreate';
import {
  WIDGET_ID as jitRuleId,
  widgetComponents as jitRuleWidgetComponents,
} from './jit-rule-widget/jitRule';

/**
 * a map of widget ids to a set of widget specific modules
 *
 * Each set of widget modules has the following properties
 *
 * @property {Array<React.Element>} buttons an array of button elements. These
 *     buttons would be put into the footer area of the modal
 * @property {React.Component} Provider This is the component that is rendered
 *    such that it is a common ancestor of both the widget component and the
 *    button elements. It can be used to pass state between the two. It would
 *    be provided with 2 props:
 *    @param {function} closeModal: close the modal
 *    @param {Object} widgetData: widget specific data passed to the modal
 * @property {React.Component} Widget the component that will be rendered in the
 *     modal.
 */
const widgetIndex = {
  [jitRuleAutoCreateId]: jitRuleAutoCreateWidgetComponents,
  [jitRuleId]: jitRuleWidgetComponents,
};
export default widgetIndex;
