/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseP1CriticalFormSection
 *
 * @description The P1 Critical subsection in the Priority section of the Issue Description form.
 */
(function () {
  angular.module('app.widgets.support').component('appCaseP1CriticalFormSection', {
    bindings: {
      p1CaseAllowed: '<',
    },
    templateUrl:
      'app/widgets/support/create-case-modal/case-p1-critical-form-section/case-p1-critical-form-section.component.html',
  });
})();
