(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').config(overviewConfig);

  /* @ngInject */
  function overviewConfig(
    $stateProvider,
    $urlRouterProvider,
    APP_SHELL_NAV_ID,
    navAssemblerProvider,
    OVERVIEW_WORKSPACE_NAV_ID,
    ROLE
  ) {
    const state = {
      name: 'overview',
      parent: 'org',
      url: '/overview',
    };

    $stateProvider.state(state.name, {
      abstract: true,
      component: 'binkyWorkspace',
      data: {
        title: 'overview.title',
      },
      parent: state.parent,
      url: state.url,
    });

    // add the Overview section to the top nav
    navAssemblerProvider.configure({
      navID: APP_SHELL_NAV_ID,
      navValue: {
        access: {
          feature: {
            name: 'bumper_overview',
          },
          roles: [
            ROLE.ADMIN.CONTRACT,
            ROLE.ADMIN.DEPLOYMENT,
            ROLE.ADMIN.LICENSE,
            ROLE.ADMIN.ORG,
            ROLE.ADMIN.PRODUCT,
            ROLE.ADMIN.PRODUCT_SUPPORT,
            ROLE.ADMIN.STORAGE,
            ROLE.ADMIN.SUPPORT,
            ROLE.ADMIN.USER_GROUP,
            ROLE.AGENT.ADOBE_AGENT_ADMIN,
            ROLE.AGENT.ADOBE_AGENT_CUSTOMER_CARE,
            ROLE.AGENT.ADOBE_AGENT_PROFESSIONAL_SERVICES,
            ROLE.AGENT.ADOBE_AGENT_PROVISIONER,
            ROLE.AGENT.ADOBE_AGENT_READ,
            ROLE.AGENT.ADOBE_AGENT_RESELLER_LICENSING,
          ],
        },
        child: OVERVIEW_WORKSPACE_NAV_ID,
        disabled: false,
        hidden: false,
        name: 'overview.title',
        order: 0,
        state,
      },
    });

    $urlRouterProvider.when('/overview', ($injector, $state, orgReady) => {
      // eslint-disable-next-line promise/catch-or-return
      orgReady.whenRunReady().then((activeOrg) => {
        $state.go('overview.overview2', {
          orgId: activeOrg.id,
        });
      });
    });
  }
})();
