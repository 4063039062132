import {User} from '@admin-tribe/binky';
import {Flex, Link, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {goToUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import getNamesOfOwners from '../utils/getNamesOfOwners';

/**
 * The LinksList component is list of links pointing to user profiles.
 */
const LinksList = ({marginTop, showProcessingMessage = false, users}) => {
  const intl = useIntl();

  const navigationCallback = (user) => {
    goToUserDetails({userId: user.id});
  };

  return (
    <Flex direction="column" gap="size-50" marginTop={marginTop}>
      {users.map((user, index) => (
        <Flex key={`user-${index + 1}`} gap="size-50">
          <Link onPress={() => navigationCallback(user)}>
            {`${getNamesOfOwners(intl, [user])}`}
          </Link>
          {showProcessingMessage && (
            <Text>
              <FormattedMessage id="storage.sharedUserFolderDetailsDrawer.processing.default" />
            </Text>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

LinksList.propTypes = {
  /**
   * Margin top of the LinksList
   */
  marginTop: PropTypes.string,

  /**
   * Processing message text if the storage folder is being processed. Default is false.
   */
  showProcessingMessage: PropTypes.bool,

  /**
   * The list of users objects to be shown in the Links List
   */

  users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
};

export default LinksList;
