(function () {
  /**
   * @ngdoc module
   * @name app.core.organizations.organization-manager
   * @description defines organization manager service
   */
  angular.module('app.core.organizations.organization-manager', [
    'app.core.auth',
    'app.core.message',
    'app.core.organizations',
    'app.core.product.org-consumables',
    'binky.core.api.utils',
    'binky.core.contact',
    'binky.core.contract',
    'binky.core.feature',
    'binky.core.globalAdmin',
    'binky.core.lodash',
    'binky.core.offers',
    'binky.core.product',
    'binky.core.storage-utils',
    'binky.core.user.authenticated-user',
    'binky.core.user.linked-accounts',
  ]);
})();
