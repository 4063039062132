import {generatePath, redirect} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {buildAccessCheckLoader, buildDefaultSectionRedirectLoader} from 'core/router/loaders';
import {
  canViewIndividualUserFolders,
  canViewLibraries,
  canViewSharedProjects,
  canViewStorage,
} from 'core/storage/access/storageAccess';

import {
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_ROOT,
} from './storagePaths';

/**
 * Redirects to the correct URL if the user landed on /storage.
 * Currently, this is always the storage overview page.
 * @param {import('react-router-dom').LoaderFunctionArgs} args - The incoming loader data, which must contain a Request with a `url` field
 * @returns {Response | null}
 */
const defaultSectionLoader = ({request}) => {
  const destination = generatePath(PATH_STORAGE_OVERVIEW, {
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return buildDefaultSectionRedirectLoader(PATH_STORAGE_ROOT, destination)({request});
};

/**
 * Checks if the user can access individual user folders
 * @returns {Promise<Response>}
 */
const individualFoldersAccessCheck = buildAccessCheckLoader(canViewIndividualUserFolders);

/**
 * @deprecated with temp_storage_shared_projects
 * Checks if the user can access libraries
 * @returns {Promise<Response>}
 */
const librariesAccessCheck = buildAccessCheckLoader(canViewLibraries);

/**
 * Redirect from /user to /user/folders
 * @param {import('react-router-dom').LoaderFunctionArgs} args - The incoming loader data, which must contain a Request with a `url` field
 * @returns {Response | null}
 */
const redirectToIndividualUserFolders = ({request}) => {
  const redirectUrl = generatePath(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS, {
    orgId: rootStore.organizationStore.activeOrgId,
  });

  if (request.url.includes(redirectUrl)) {
    return null;
  }
  return redirect(redirectUrl);
};

/**
 * Checks if the user can access projects
 * @returns {Promise<Response>}
 */
const sharedProjectsAccessCheck = buildAccessCheckLoader(canViewSharedProjects);

/**
 * Checks if the user can access storage
 * @returns {Promise<Response>}
 */
const storageAccessCheck = buildAccessCheckLoader(canViewStorage);

export {
  defaultSectionLoader,
  individualFoldersAccessCheck,
  librariesAccessCheck,
  redirectToIndividualUserFolders,
  sharedProjectsAccessCheck,
  storageAccessCheck,
};
