(function () {
  /**
   * @ngdoc module
   * @name app.core.storage-reports
   * @description Module which defines storage-reports related models
   */
  angular.module('app.core.storage-reports', [
    'app.core.api.content-platform',
    'app.core.api.jil',
    'app.core.message',
    'app.core.model-cache',
    'app.core.storage-file',
    'app.core.user',
    'binky.core.common.list',
    'binky.core.common.list.state.hybrid',
    'binky.core.lodash',
  ]);
})();
