import {FULFILLABLE_ITEM_CODE, StockQuantitiesList} from '@admin-tribe/binky';
import binkyUI, {
  ModalContent,
  ModalDescription,
  ModalHeading,
  ModalWizardStepList,
} from '@admin-tribe/binky-ui';
import {Heading, Text, TextField} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 NumberInput should be replaced with v3 NumberField
import NumberInput from '@react/react-spectrum/NumberInput';
import parseInt from 'lodash/parseInt';
import pick from 'lodash/pick';
import React from 'react';
import {useIntl} from 'react-intl';

import {useProductProfileModalContext} from '../AddProductProfileModalContext';

const GoUrl = binkyUI.common.components.GoUrl;

const QUOTA_VALUES = {
  NOT_AVAILABLE: 'Not Available',
  UNLIMITED: 'UNLIMITED',
};

const AddProductProfileModalStockQuotaPage = () => {
  const intl = useIntl();
  const {
    currentProductProfile,
    isLoading,
    orgId,
    product,
    setModalError,
    setIsLoading,
    setIsValid,
  } = useProductProfileModalContext();
  let PRODUCT_QUOTA_MAX = 100000000000;
  const stockQuotaLocId = 'products.addProductProfileModal.stockQuota';
  const SEPARATOR = '/';

  const getLocPrefix = (code) => {
    switch (code) {
      case FULFILLABLE_ITEM_CODE.STOCK_CREDIT:
        return 'credits';
      case FULFILLABLE_ITEM_CODE.STOCK_IMAGE:
        return 'images';
      case FULFILLABLE_ITEM_CODE.STOCK_PREMIUM_CREDITS:
        return 'premiumCredits';
      case FULFILLABLE_ITEM_CODE.STOCK_STANDARD_CREDITS:
        return 'standardCredits';
      case FULFILLABLE_ITEM_CODE.STOCK_UNIVERSAL_CREDITS:
        return 'universalCredits';
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
      // istanbul ignore next
      default:
        return undefined;
    }
  };

  const attachQuotaLabel = (quota) => {
    const locPrefix = getLocPrefix(quota.code);

    let displayQuota = parseInt(quota.quotaValue);
    let isANumber = Number.isInteger(displayQuota);

    if (!isANumber) {
      // fetch and translate the appropriate string that will appear in the textfield
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
      // istanbul ignore else -- quotaValue will be one of these two values if NaN
      if (quota.quotaValue === QUOTA_VALUES.NOT_AVAILABLE) {
        displayQuota = intl.formatMessage({
          id: `${stockQuotaLocId}.notAvailable`,
        });
      } else if (quota.quotaValue === null || quota.quotaValue === QUOTA_VALUES.UNLIMITED) {
        // With SLS M3, the previous 'UNLIMITED' quota cap was replaced with 'null'
        // This has caused customer escalations, due to it breaking administration of Stock profiles
        //
        // defaulting the display value to 0
        displayQuota = 0;
        // resetting isANumber so the numberInputs will appear
        isANumber = true;
      }
    } else if (PRODUCT_QUOTA_MAX < displayQuota) {
      PRODUCT_QUOTA_MAX = quota.displayQuota;
    }

    return Object.assign(quota, {
      available: `.${locPrefix}.available`,
      consumed: `.${locPrefix}.consumed`,
      description: `.${locPrefix}.description`,
      displayQuota,
      header: `.${locPrefix}.header`,
      index: currentProductProfile.fulfilledItems.findIndex((item) => item.code === quota.code),
      isANumber,
    });
  };

  const [stockQuotas, setStockQuotas] = React.useState(
    currentProductProfile.fulfilledItems
      .filter((item) =>
        [
          FULFILLABLE_ITEM_CODE.STOCK_CREDIT,
          FULFILLABLE_ITEM_CODE.STOCK_IMAGE,
          FULFILLABLE_ITEM_CODE.STOCK_STANDARD_CREDITS,
          FULFILLABLE_ITEM_CODE.STOCK_PREMIUM_CREDITS,
          FULFILLABLE_ITEM_CODE.STOCK_UNIVERSAL_CREDITS,
        ].includes(item.code)
      )
      .map((foundItem) => ({
        code: foundItem.code,
        quotaValue: foundItem?.chargingModel?.cap,
      }))
      .map(attachQuotaLabel)
  );

  const [invalidInputs, setInvalidInputs] = React.useState({});

  React.useEffect(() => {
    let isCurrent = true;

    async function fetchProductQuantities() {
      let productQuantitiesResponse;
      setIsLoading(true);
      try {
        productQuantitiesResponse = await StockQuantitiesList.get(orgId, currentProductProfile.id);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
        // istanbul ignore else
        if (isCurrent) {
          if (productQuantitiesResponse.items && productQuantitiesResponse.items.length > 0) {
            const quotas = stockQuotas.map((quota) => {
              // we only show quotas if they're UNLIMITED, or the total quantity is above 0
              const jilQuota = product.fulfillableItemList.items.find(
                (item) => item.code === quota.code
              );
              const jilTotal = jilQuota?.chargingModel?.cap;
              if (jilTotal === null || jilTotal === QUOTA_VALUES.UNLIMITED || jilTotal > 0) {
                const stockItem = productQuantitiesResponse.items.find(
                  (item) => item.jilType === quota.code
                );
                const stockItemLimited = pick(stockItem, ['consumedQuantity', 'totalQuantity']);
                return Object.assign(quota, stockItemLimited);
              }
              return undefined;
            });

            setStockQuotas(quotas.filter((item) => item));
          }
        }
      } catch (error) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
        // istanbul ignore else
        if (isCurrent) {
          setModalError(
            intl.formatMessage({
              id: 'products.addProductProfileModal.stockQuota.errorMessage.loadFailure',
            })
          );
        }
      } finally {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
        // istanbul ignore else
        if (isCurrent) {
          setIsLoading(false);
        }
      }
    }

    fetchProductQuantities();
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
    // istanbul ignore next
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't call api every time stockQuotas is changed
  }, [intl, orgId, product.fulfillableItemList.items, setModalError]);

  return (
    <>
      <ModalHeading>
        {intl.formatMessage({
          id: currentProductProfile.id
            ? 'products.addProductProfileModal.editTitle'
            : 'products.addProductProfileModal.createTitle',
        })}
      </ModalHeading>
      <ModalDescription>
        <ModalWizardStepList />
        <Text data-testid="description-text">
          {intl.formatMessage(
            {
              id: 'products.addProductProfileModal.stockQuota.learnMore',
            },
            {
              goUrl: (str) => <GoUrl name="aac_legacy_stock_credit">{str}</GoUrl>,
            }
          )}
        </Text>
      </ModalDescription>
      <ModalContent>
        {stockQuotas &&
          stockQuotas.map((quota, index) => (
            <div key={quota.code} data-testid={quota.code}>
              <Heading data-testid="quota-heading" level={3}>
                {intl.formatMessage({
                  id: `${stockQuotaLocId}${quota.header}`,
                })}
              </Heading>
              <p
                // eslint-disable-next-line react/forbid-dom-props -- aria needs id
                id="quota-description"
              >
                {intl.formatMessage({
                  id: `${stockQuotaLocId}${quota.description}`,
                })}
              </p>
              {quota.isANumber && (
                <FieldLabel
                  label={intl.formatMessage({
                    id: `${stockQuotaLocId}${quota.available}`,
                  })}
                >
                  <NumberInput
                    aria-describedby="quota-description"
                    data-testid="available-input"
                    disabled={isLoading}
                    max={PRODUCT_QUOTA_MAX}
                    min={0}
                    /* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- zaratan@ to update*/
                    onChange={(value) => {
                      const fulfilledItem = currentProductProfile.fulfilledItems.find(
                        (item) => item.code === quota.code
                      );
                      setStockQuotas((prevStockQuota) => {
                        const newStockQuotas = [...prevStockQuota];
                        newStockQuotas[index].displayQuota = value;
                        return newStockQuotas;
                      });

                      // disable cta if input is invalid
                      const isInvalidInput =
                        value === null || value > PRODUCT_QUOTA_MAX || value < 0;

                      if (!isInvalidInput) {
                        fulfilledItem.chargingModel.cap = value;
                      }
                      const newInvalidInputs = {...invalidInputs, [quota.code]: isInvalidInput};
                      setInvalidInputs(newInvalidInputs);
                      setIsValid(!Object.values(newInvalidInputs).some(Boolean));
                    }}
                    value={quota.displayQuota}
                  />
                </FieldLabel>
              )}
              {!quota.isANumber && (
                <TextField
                  data-testid="unavailable-input"
                  isDisabled
                  label={intl.formatMessage({
                    id: `${stockQuotaLocId}${quota.available}`,
                  })}
                  maxLength={255}
                  value={quota.displayQuota}
                />
              )}
              {quota.consumedQuantity !== undefined && (
                <>
                  <p>
                    {intl.formatMessage({
                      id: `${stockQuotaLocId}${quota.consumed}`,
                    })}
                  </p>
                  <p>
                    <span data-testid="consumed-quantity">{quota.consumedQuantity}</span>
                    {quota.totalQuantity >= 0 && (
                      <span>
                        <span>{SEPARATOR}</span>
                        <span data-testid="display-quota">{quota.displayQuota}</span>
                      </span>
                    )}
                  </p>
                </>
              )}
            </div>
          ))}
      </ModalContent>
    </>
  );
};

export default AddProductProfileModalStockQuotaPage;
