import {PageBanner} from '@admin-tribe/binky-ui';
import PropTypes from 'prop-types';
import React from 'react';

import BannerNotification from 'core/products/notifier/BannerNotification';

/**
 * Banners display a PageBanner for each banner notification provided to it
 */
const Banners = ({messagesToDisplay = []}) => (
  <>
    {messagesToDisplay.map((message) => (
      <PageBanner
        key={message.name}
        closeLabel={message.buttonTxt}
        data-testid={message.name}
        header={message.header}
        onClose={message.handleButtonClick}
        removeWithKey={message.removeWithKey}
        variant={message.variant}
      >
        {message.body}
      </PageBanner>
    ))}
  </>
);

Banners.propTypes = {
  /**
   * List of banner notifications to be displayed
   */
  messagesToDisplay: PropTypes.arrayOf(PropTypes.instanceOf(BannerNotification)),
};

export default Banners;
