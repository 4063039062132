import {Locale} from '@admin-tribe/binky';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {ContractMiniAppContentModel, OrganizationDetails} from '@pandora/react-mini-app-contract';
import React from 'react';

import rootStore from 'core/RootStore';

import styles from './OrganizationDetails.pcss';

/**
 * @description Represents a wrapper for Pandora Organization Details
 *
 */
const OrganizationDetailsInfo = () => {
  const marketSubSegments = rootStore.organizationStore.activeOrg.marketSubsegments;

  const OrganizationDetailsWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(OrganizationDetails);

  return (
    <div className={styles['organization-details']} data-testid="organization-details-container">
      <OrganizationDetailsWithContent
        aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
        marketSubsegments={marketSubSegments}
      />
    </div>
  );
};

export default OrganizationDetailsInfo;
