(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.shell.navigation.links:LinkFactory
   */
  angular.module('app.shell.navigation.links').factory('LinkFactory', getLinkFactory);

  /* @ngInject */
  function getLinkFactory(ApplicationLink, ExternalLink, HelpLink, LINK_TARGET_TYPE) {
    const service = {createLink};

    return service;

    /**
     * @description Method to create a new Link instance from the provided
     *   configuration Object.
     * @param {Object} options - configuration Object for a Link see the Link
     *   service for additional information
     * @param {LINK_TARGET_TYPE} options.target - the type of link being
     *   created
     * @returns {Link} new Link instance
     */
    function createLink(options) {
      switch (options.target) {
        case LINK_TARGET_TYPE.APPLICATION:
        case LINK_TARGET_TYPE.APPLICATION_MODAL:
        case LINK_TARGET_TYPE.GLOBAL_MODAL:
          return new ApplicationLink(options);
        case LINK_TARGET_TYPE.EXTERNAL:
        case LINK_TARGET_TYPE.GO_URL:
          return new ExternalLink(options);
        case LINK_TARGET_TYPE.HELP:
        default:
          return new HelpLink(options);
      }
    }
  }
})();
