/* eslint-disable max-lines */
(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.organizations.organization-manager:OrganizationManager
   * @description model that manages an Organization and related state
   */
  angular
    .module('app.core.organizations.organization-manager')
    .factory('OrganizationManager', getOrganizationManager);

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function getOrganizationManager(
    $location,
    $q,
    $rootScope,
    $window,
    _,
    auth,
    AuthenticatedUser,
    binkySrc2,
    CART_EVENT,
    Contact,
    ContactList,
    Contract,
    CONTRACT_BUYING_PROGRAM,
    ContractList,
    DELEGATION_GROUP_MIGRATION_STATUS,
    feature,
    GlobalAdminPolicyList,
    LinkedUserAccountList,
    MEMBER_EVENT,
    MEMBER_TYPE,
    MESSAGE,
    MigrationList,
    modelCache,
    OFFER_LIST_INTENT,
    OFFER_LIST_SERVICE_PROVIDER,
    OfferList,
    onesieSrc2,
    ORG_MANAGER_ERROR,
    ORGANIZATION_MARKET_SEGMENT,
    OrganizationList,
    OrgConsumables,
    PAYMENT_INFO_EDITOR_EVENT,
    PoresOfferList,
    PRICE_POINT,
    Product,
    PRODUCT_LIST_PI_CODE,
    ProductList,
    promiseUtils,
    ROLE
  ) {
    let contactListSingleton,
      contractListIncludingInactiveSingleton,
      contractListSingleton,
      globalAdminPolicyListSingleton,
      linkedUserAccountsSingleton,
      migrationListSingleton,
      orgConsumablesSingleton,
      productListSingleton;

    const service = {
      activeOrgDelegationGroupIsMigrated,
      activeOrgHasChinaContract,
      activeOrgHasContractInCountry,
      getActiveOrgId,
      getContactsForActiveOrg,
      getContractsForActiveOrg,
      getLinkedUserAccounts,
      getMigrationsForActiveOrg,
      getOffersForActiveOrg,
      getOrgConsumablesForActiveOrg,
      getOrgIdByPath,
      getPolicyValue,
      getProductsForActiveOrg,
      isActiveOrgEdu,
      isActiveOrgIndirectEdu,
      loadOrganization,
      loadOrganizations,
    };

    /** *** Event listeners/handlers *** **/
    // Note, these do not have de-register callbacks intentionally
    /* eslint-disable angular/on-watch */
    _.forEach([CART_EVENT.SUBMIT, MEMBER_EVENT.CREATE, MEMBER_EVENT.UPDATE], (message) => {
      $rootScope.$on(message, () => {
        // update src2 RootStore on events. Remove when angular event passing is implemented
        onesieSrc2.core.rootStore.organizationStore.updateProductListSingleton();
        productListSingleton.refresh();
        orgConsumablesSingleton.refresh();
      });
    });
    _.forEach(
      [MESSAGE.UPDATE.CONTRACTOWNER, CART_EVENT.SUBMIT, PAYMENT_INFO_EDITOR_EVENT.SAVED],
      (message) => {
        $rootScope.$on(message, () => {
          if (feature.isEnabled('temp_contract_info_mini_app')) {
            onesieSrc2.core.rootStore.organizationStore.updateContractListSingleton({
              forceRefresh: true,
            });
          } else {
            onesieSrc2.core.rootStore.organizationStore.updateContractListSingleton();
            contractListSingleton.refresh();
          }
        });
      }
    );
    /* eslint-enable angular/on-watch */

    return service;

    /**
     * @description Returns true if the active org has a contract in China. This will change some UI elements.
     * @returns {Boolean} true if the active org has a contract in China.
     */
    function activeOrgHasChinaContract() {
      const contractList = getContractsForActiveOrg();
      return _.some(contractList.items, (contract) => contract.getOwnerCountryCode() === 'CN');
    }

    /**
     * @description Returns true if the active org has a contract in one of the provided countries.
     * @param {String[]} countryCodes - the array of two letter country codes which could be one.
     * @returns {Boolean} true if the active org has a contract in any country corresponding to the countryCodes list.
     */
    function activeOrgHasContractInCountry(countryCodes) {
      const contractList = getContractsForActiveOrg();
      return _.some(contractList.items, (contract) =>
        _.includes(countryCodes, contract.getOwnerCountryCode())
      );
    }

    /**
     * @description Determine wheter an active org's delegation group migration
     *              is done.
     *
     * @return {boolean} true if migrated, false otherwise.
     */
    function activeOrgDelegationGroupIsMigrated() {
      return (
        _.get(OrganizationList.get(), 'activeOrg.delegationGroupMigrationStatus') ===
        DELEGATION_GROUP_MIGRATION_STATUS.MIGRATED
      );
    }

    /**
     * @description determines the error message
     * @param {String} error - the error
     * @returns {String} the modified error
     */
    function getMessageFromError(error) {
      const message = _.get(error, 'response.data.message');
      return message === ORG_MANAGER_ERROR.MIGRATING_TO_DELEGATION_GROUPS ||
        message === ORG_MANAGER_ERROR.DELEGATION_GROUPS_MIGRATION_ERROR
        ? message
        : error;
    }

    /**
     * @description Method to fetch the current activeOrg ID
     * @returns {String} the activeOrg ID
     */
    function getActiveOrgId() {
      return _.get(OrganizationList.get(), 'activeOrg.id');
    }

    /**
     * @description Method to fetch the ContactList.
     * @returns {ContactList} singleton ContactList.
     */
    function getContactsForActiveOrg() {
      if (!contactListSingleton) {
        updateContactListSingleton();
      }
      return contactListSingleton;
    }

    /**
     * @description Method to fetch the ContractList. It will be refreshed on
     *     registering the active org
     * @param {Object} options - Top level wrapper object.
     * @param {boolean} options.includeInactive - Flag indicating whether
     *     inactive contracts should be included.
     * @returns {ContractList} singleton ContractList.
     */
    function getContractsForActiveOrg({includeInactive = false} = {}) {
      if (!contractListSingleton) {
        updateContractListSingleton(_.get(OrganizationList.get(), 'activeOrg'));
      }
      return includeInactive ? contractListIncludingInactiveSingleton : contractListSingleton;
    }

    /**
     * @description Returns the linked user accounts list for this user
     * @returns {LinkedUserAccountList} the singleton LinkedUserAccountList
     */
    function getLinkedUserAccounts() {
      return linkedUserAccountsSingleton;
    }

    /**
     * @description Fetches the org's migrations from JIL and stores the resulting MigrationList
     *   as a singleton.
     * @returns {MigrationList} the singleton MigrationList
     */
    function getMigrationsForActiveOrg() {
      if (!migrationListSingleton) {
        updateMigrationListSingleton(_.get(OrganizationList.get(), 'activeOrg'));
      }
      return migrationListSingleton;
    }

    /**
     * @description Fetches a list of available offers based on the provided params
     *   for the specified contract, or the default contract within an org.
     * @param {Object} queryParams - the list of params to pass through to OfferList.
     *   This is also used to determine the default contract to select
     * @param {String} contractId - the contract to get offers for. If none is provided
     *   a default will be selected.
     * @returns {OfferList} the OfferList for the provided parameters
     */
    function getOffersForActiveOrg(queryParams, contractId) {
      return feature.isEnabled('temp_ctir_21074_oms_removal')
        ? PoresOfferList.get({
            contractId: contractId || getContractId(),
            orgId: getActiveOrgId(),
            queryParams: _.assign({}, queryParams, {
              intent: OFFER_LIST_INTENT.ADD_SEATS_OFFER_DISCOVERY,
              service_providers: [
                OFFER_LIST_SERVICE_PROVIDER.MERCHANDISING,
                OFFER_LIST_SERVICE_PROVIDER.PRODUCT_ARRANGEMENT,
              ],
            }),
          })
        : OfferList.get({
            contractId: contractId || getContractId(),
            orgId: getActiveOrgId(),
            queryParams,
          });

      function getContractId() {
        const contractList = getContractsForActiveOrg();
        let foundId;

        if (_.get(queryParams, 'price_point') === PRICE_POINT.FREE) {
          const contract =
            contractList.getIndirectContract() ||
            contractList.getEnterpriseContract(CONTRACT_BUYING_PROGRAM.ETLA);
          foundId = _.get(contract, 'id');
        } else {
          foundId = contractList.getDirectOrIndirectContractId();
        }

        return foundId;
      }
    }

    /**
     * @description Method to fetch the OrgConsumables. It will be refreshed on
     *     registering the active org
     * @returns {OrgConsumable} singleton OrgConsumable.
     */
    function getOrgConsumablesForActiveOrg() {
      if (!orgConsumablesSingleton) {
        updateOrgConsumablesSingleton(_.get(OrganizationList.get(), 'activeOrg'));
      }
      return orgConsumablesSingleton;
    }

    /**
     * @description Retrieves the org ID currently set in the browser URL path. Since the
     *   path will always begin with a forward slash character (/), we can grab the org ID by
     *   splitting the path on this character and returning the second match
     * @returns {String} the current org ID shown in the browser URL
     */
    function getOrgIdByPath() {
      const path = $window.location.pathname;
      return _(path).split('/', 2).last();
    }

    /**
     * @description Method to find a global admin policy from the GlobalAdminPolicyList
     * @param {String} name - the name of the policy to retreive. see policy-service.constants.js for options
     * @returns {Boolean} The value that describes whether the policy name is on or off
     */
    function getPolicyValue(name) {
      return globalAdminPolicyListSingleton.getPolicyValue(name);
    }

    /**
     * @description Method to fetch the ProductList. It will be refreshed on
     *     registering the active org
     * @returns {ProductList} singleton ProductList.
     */
    function getProductsForActiveOrg() {
      if (!productListSingleton) {
        updateProductListSingleton(_.get(OrganizationList.get(), 'activeOrg'));
      }
      return productListSingleton;
    }

    /**
     * @description Determine whether an active org is an Edu org.
     * @returns {Boolean} true if the active org is an Edu org.
     */
    function isActiveOrgEdu() {
      return (
        _.get(OrganizationList.get(), 'activeOrg.marketSegment') ===
        ORGANIZATION_MARKET_SEGMENT.EDUCATION
      );
    }

    /**
     * @description Determine whether an active org is an indirect Edu org.
     * @returns {Boolean} true if the active org is an indirect Edu org.
     */
    function isActiveOrgIndirectEdu() {
      return (
        (_.get(OrganizationList.get(), 'activeOrg.marketSegment') ===
          ORGANIZATION_MARKET_SEGMENT.EDUCATION ||
          feature.isEnabled('force_edu_market_segment')) &&
        getContractsForActiveOrg().hasIndirectContract()
      );
    }

    /**
     * @description Method to init the org list
     * @returns {Promise} promise - promise object, resolved when org data is loaded.
     */
    function loadOrganizations() {
      const orgList = OrganizationList.get();
      linkedUserAccountsSingleton = LinkedUserAccountList.get({
        includePaths: true,
        userId: AuthenticatedUser.get().getId(),
      });
      return $q.all([orgList.$promise, linkedUserAccountsSingleton.$promise]).then(() => ({
        linkedUserAccountList: linkedUserAccountsSingleton,
        organizationList: orgList,
      }));
    }

    /**
     * @description Method to load the data for the specified org
     * @param {String} orgId - the org to switch to
     * @returns {Promise} promise - promise object, resolved when org data is loaded.
     */
    function loadOrganization(orgId) {
      const deferred = $q.defer();

      const org = findOrg(orgId);
      $rootScope.$emit(MESSAGE.CHANGE.ACTIVEORGANIZATION.START, org);
      setActiveOrg(org).then(resolveActiveOrg).then(onSuccess.bind(this)).catch(onError.bind(this));

      function onSuccess(results) {
        deferred.resolve(results[0]);
        $rootScope.$emit(MESSAGE.CHANGE.ACTIVEORGANIZATION.SUCCESS, results[0]);
      }

      function onError(error) {
        deferred.reject(error);
        $rootScope.$emit(MESSAGE.CHANGE.ACTIVEORGANIZATION.ERROR, error, orgId);
      }

      return deferred.promise;
    }

    ///////////////

    /**
     * @description resolve the specified org
     * @param {Object} activeOrg the org that has been set as active
     * @returns {Promise} resolved if active org is properly set.
     */
    function resolveActiveOrg(activeOrg) {
      const promises = [
        $q.resolve(activeOrg),
        wrapContractsPromise(activeOrg),
        wrapProductListPromise(activeOrg),
        wrapPolicyListPromise(activeOrg),
      ];

      migrationListSingleton = new MigrationList({orgId: activeOrg.id});
      _.assign(
        migrationListSingleton,
        onesieSrc2.core.rootStore.organizationStore.migrationList.toSrc1List(
          // MigrationList has an array of itemClassRef, not just one.
          migrationListSingleton.itemClassRef,
          MigrationList
        ),
        {
          $resolved: true,
        }
      );
      migrationListSingleton.$promise = $q.resolve(migrationListSingleton);

      if (auth.hasAnyRole([ROLE.ADMIN.ORG])) {
        contactListSingleton = new ContactList();
        _.assign(contactListSingleton, {
          $resolved: true,
          dataAccessAdministrator: new Contact(
            onesieSrc2.core.rootStore.organizationStore.contactList.dataAccessAdministrator
          ),
          dataProtectionOfficer: new Contact(
            onesieSrc2.core.rootStore.organizationStore.contactList.dataProtectionOfficer
          ),
          securityOfficer: new Contact(
            onesieSrc2.core.rootStore.organizationStore.contactList.securityOfficer
          ),
        });
        contactListSingleton.$promise = $q.resolve(contactListSingleton);
      }

      return $q.all(promises);
    }

    function wrapPolicyListPromise(orgPromise) {
      return $q((resolve, reject) => {
        GlobalAdminPolicyList.get({orgId: orgPromise.id})
          .then((model) => {
            globalAdminPolicyListSingleton = model;
            resolve();
          })
          .catch(reject);
      });
    }

    /**
     * @description find the specified org
     * @param {String} orgId the org to find
     * @returns {Organization} the organization pulled from the list
     */
    function findOrg(orgId) {
      return _.find(OrganizationList.get().items, {id: orgId});
    }

    /**
     * @description switch to the specified org
     * @param {Organization} org the org to switch to
     * @returns {Promise} resolved when active org loaded
     */
    function setActiveOrg(org) {
      const orgList = OrganizationList.get();

      let activeOrgPromise;
      if (
        (feature.isEnabled('temp_adobe_agent_access') &&
          AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(org.id)) ||
        (org && orgList.has(org.id))
      ) {
        modelCache.removeAll();
        binkySrc2.models.cache.modelCache.clearAll();

        auth.setActiveOrganization(org.id);

        const src2RootStorePromise = promiseUtils.toAngularPromise(
          onesieSrc2.core.rootStore.organizationStore.setActiveOrg(org)
        );

        const featuresCachePromise = promiseUtils.toAngularPromise(
          new Promise((resolve, reject) => {
            const FeaturesCache = binkySrc2.services.FeaturesCache;
            const cacheInstance = FeaturesCache.get();

            // As we initialize an instance in bootstrap that is ran after this
            // we only setOrgId if we have an instance created, i.e. org is changed
            // from the UI
            if (cacheInstance) {
              return cacheInstance.setOrgId(org.id).then(resolve).catch(reject);
            }
            return resolve();
          })
        );

        const autoAssignRulesCachePromise = promiseUtils.toAngularPromise(
          new Promise((resolve) => {
            const AutoAssignRulesCache = onesieSrc2.core.services.product.AutoAssignRulesCache;
            const cacheInstance = AutoAssignRulesCache.get();

            // As we initialize an instance in bootstrap that is ran after this
            // we only setOrgId if we have an instance created, i.e. org is changed
            // from the UI
            if (cacheInstance) {
              return cacheInstance.setOrgId(org.id).then(resolve);
            }

            return resolve();
          })
        );

        const orgPromise = $q
          .all([
            orgList.setActive(org.id),
            src2RootStorePromise,
            autoAssignRulesCachePromise,
            featuresCachePromise,
          ])
          .then(([activeOrg]) => activeOrg);

        activeOrgPromise = wrapOrgPromise(orgPromise, orgList);
      } else {
        activeOrgPromise = $q.reject(ORG_MANAGER_ERROR.ORG_NOT_FOUND);
      }
      return activeOrgPromise;
    }

    function wrapOrgPromise(orgPromise, orgList) {
      if (orgPromise) {
        const orgDeferred = $q.defer();
        orgPromise
          .then((org) => {
            orgDeferred.resolve(org);
          })
          .catch((error) => {
            if (
              onesieSrc2.core.rootStore.organizationStore.hasLoadingError ||
              _.get(error, 'response.status') === 423
            ) {
              orgDeferred.reject(getMessageFromError(error));
            } else {
              orgDeferred.resolve(orgList.getActive());
            }
          });
        return orgDeferred.promise;
      }
      return $q.reject(ORG_MANAGER_ERROR.ORG_NOT_FOUND);
    }

    function wrapContractsPromise(org) {
      contractListSingleton = new ContractList({orgId: org.id});
      _.assign(
        contractListSingleton,
        onesieSrc2.core.rootStore.organizationStore.contractList.toSrc1List(Contract, ContractList),
        {
          $resolved: true,
        }
      );
      contractListSingleton.$promise = $q.resolve(contractListSingleton);
      if (_.get(onesieSrc2, 'core.rootStore.organizationStore.contractListIncludingInactive')) {
        contractListIncludingInactiveSingleton = new ContractList({orgId: org.id});
        _.assign(
          contractListIncludingInactiveSingleton,
          onesieSrc2.core.rootStore.organizationStore.contractListIncludingInactive.toSrc1List(
            Contract,
            ContractList
          ),
          {$resolved: true}
        );
        contractListIncludingInactiveSingleton.$promise = $q.resolve(
          contractListIncludingInactiveSingleton
        );
      }
      const contractsDeferred = $q.defer();
      if (feature.isEnabled('temp_terms_redirect')) {
        onSuccess(contractListSingleton);
      } else {
        contractListSingleton.$promise
          .then(fetchContractTerms)
          .then(onSuccess)
          .catch(contractsDeferred.reject);
      }
      return contractsDeferred.promise;

      /////////////

      function contractWithTermsToAcceptHasEnrollee(contractList) {
        let hasEnrollee;
        const contractWithTerms = _.find(contractList.items, (contract) =>
          contract.mustAcceptTerms()
        );
        if (contractWithTerms) {
          hasEnrollee = _.has(contractWithTerms, 'ownerInfo');
        }
        return hasEnrollee;
      }

      // Remove this with temp_terms_redirect
      function fetchContractTerms(contracts) {
        const requiredTermPromises = _(contracts.items)
          .map((contract) => {
            const terms = contract.getTermsAndConditions();
            return terms ? terms.$promise : undefined;
          })
          .compact()
          .value();

        return $q.all(requiredTermPromises).then(() => contracts);
      }

      function hasBackingT1Account(authenticatedUser) {
        const {userId} = authenticatedUser.profile;
        const linkedUserAccount = _.find(linkedUserAccountsSingleton.items, [
          'entitlement_user_id',
          userId,
        ]);
        return linkedUserAccount.getAuthenticatingUserType().type === MEMBER_TYPE.TYPE1;
      }

      function onSuccess(contracts) {
        // Remove userCanAcceptTermsWithNoEnrollee() check when T2 or T3 can accept terms.

        if (
          feature.isEnabled('temp_terms_redirect') &&
          onesieSrc2.core.organizations.access.mustAcceptContractTerms(contracts)
        ) {
          if (
            feature.isEnabled('temp_delegate_without_provisioning_pending_tc') &&
            isAllTncContractsOptedAcceptLater(contracts)
          ) {
            contractsDeferred.resolve(contracts);
          } else {
            onesieSrc2.common.services.termsRedirect.redirectToAcceptTC({
              orgId: contracts.orgId,
            });
            // Throw a reject to make sure the contractDeferred is resolved.
            // But, the error will not be handled to allow wait spinner to show up
            // while the user is being redirected.
            contractsDeferred.reject(ORG_MANAGER_ERROR.TERMS_REDIRECT);
          }
        } else if (
          feature.isDisabled('temp_terms_redirect') &&
          contracts.mustAcceptTerms() &&
          (contractWithTermsToAcceptHasEnrollee(contracts) || userCanAcceptTermsWithNoEnrollee())
        ) {
          // if acceptance is required and the user can accept terms, we reject
          // start, to force terms acceptance
          contractsDeferred.reject(ORG_MANAGER_ERROR.TERMS_ACCEPTANCE_REQUIRED);
        } else {
          contractsDeferred.resolve(contracts);
        }

        if (feature.isEnabled('temp_observability')) {
          setObservabilityContractAttributes();
        }

        ////////

        function setObservabilityContractAttributes() {
          // Set each flag to true if the org has such contract type
          const contractAttributes = {
            ContractCount: contracts.items.length,
            ContractDirect: false,
            ContractEnterprise: false,
            ContractIndirect: false,
          };
          _.forEach(contracts.items, (contract) => {
            contractAttributes.ContractDirect =
              contractAttributes.ContractDirect || contract.isDirectContract();
            contractAttributes.ContractEnterprise =
              contractAttributes.ContractEnterprise || contract.isEnterpriseContract();
            contractAttributes.ContractIndirect =
              contractAttributes.ContractIndirect || contract.isIndirectContract();
          });

          binkySrc2.services.observability.observabilityMetrics.setCustomAttributes(
            contractAttributes
          );
        }
      }

      function userCanAcceptTermsWithNoEnrollee() {
        const authenticatedUser = AuthenticatedUser.get();
        // must be a T1 user or a T2E with a backing T1 account
        return (
          authenticatedUser.getType().type === MEMBER_TYPE.TYPE1 ||
          (authenticatedUser.getType().type === MEMBER_TYPE.TYPE2E &&
            hasBackingT1Account(authenticatedUser))
        );
      }
    }

    function wrapProductListPromise(org) {
      productListSingleton = new ProductList({
        orgId: org.id,
        processingInstructionCodes: [PRODUCT_LIST_PI_CODE.GLOBAL_ADMINISTRATION],
      });
      _.assign(
        productListSingleton,
        onesieSrc2.core.rootStore.organizationStore.productList.toSrc1List(Product, ProductList),
        {
          $resolved: true,
        }
      );
      productListSingleton.$promise = $q.resolve(productListSingleton);
      return updateOrgConsumablesSingleton(org).$promise;
    }

    function updateContactListSingleton() {
      if (!auth.hasAnyRole([ROLE.ADMIN.ORG])) {
        return undefined;
      }

      contactListSingleton = new ContactList();
      contactListSingleton.$resolved = false;
      contactListSingleton.$promise = promiseUtils
        .toAngularPromise(onesieSrc2.core.rootStore.organizationStore.updateContactListSingleton())
        .then((src2ContactList) => {
          _.assign(contactListSingleton, {
            $resolved: true,
            dataAccessAdministrator: new Contact(src2ContactList.dataAccessAdministrator),
            dataProtectionOfficer: new Contact(src2ContactList.dataProtectionOfficer),
            securityOfficer: new Contact(src2ContactList.securityOfficer),
          });
          return contactListSingleton;
        });
      return contactListSingleton.$promise;
    }

    function updateContractListSingleton(org) {
      contractListSingleton = new ContractList({orgId: org.id});
      contractListSingleton.$resolved = false;
      contractListSingleton.$promise = promiseUtils
        .toAngularPromise(onesieSrc2.core.rootStore.organizationStore.updateContractListSingleton())
        .then((src2ContractList) => {
          _.assign(contractListSingleton, src2ContractList.toSrc1List(Contract, ContractList), {
            $resolved: true,
          });
          if (_.get(onesieSrc2, 'core.rootStore.organizationStore.contractListIncludingInactive')) {
            // At this point the contractList with inactive properties should be available to use
            contractListIncludingInactiveSingleton = new ContractList({orgId: org.id});
            _.assign(
              contractListIncludingInactiveSingleton,
              onesieSrc2.core.rootStore.organizationStore.contractListIncludingInactive.toSrc1List(
                Contract,
                ContractList
              ),
              {$resolved: true}
            );
          }
          return contractListSingleton;
        });

      return contractListSingleton;
    }

    function updateMigrationListSingleton(org) {
      migrationListSingleton = new MigrationList({orgId: org.id});
      migrationListSingleton.$resolved = false;
      migrationListSingleton.$promise = promiseUtils
        .toAngularPromise(
          onesieSrc2.core.rootStore.organizationStore.updateMigrationListSingleton()
        )
        .then((src2MigrationList) => {
          _.assign(
            migrationListSingleton,
            src2MigrationList.toSrc1List(
              // MigrationList has an array of itemClassRef, not just one.
              migrationListSingleton.itemClassRef,
              MigrationList
            ),
            {$resolved: true}
          );
          return migrationListSingleton;
        });

      return migrationListSingleton;
    }

    function updateOrgConsumablesSingleton(org) {
      const productList = getProductsForActiveOrg();
      orgConsumablesSingleton = OrgConsumables.get({
        orgId: org.id,
        productList,
      });
      return orgConsumablesSingleton;
    }

    function updateProductListSingleton(org) {
      productListSingleton = new ProductList({
        orgId: org.id,
        processingInstructionCodes: [PRODUCT_LIST_PI_CODE.GLOBAL_ADMINISTRATION],
      });
      productListSingleton.$resolved = false;

      productListSingleton.$promise = promiseUtils
        .toAngularPromise(onesieSrc2.core.rootStore.organizationStore.updateProductListSingleton())
        .then((src2ProductList) => {
          _.assign(productListSingleton, src2ProductList.toSrc1List(Product, ProductList), {
            $resolved: true,
          });
          return productListSingleton;
        });

      return productListSingleton;
    }

    // this method stores accept later info if found in query param saveIfAcceptLaterForContractTnc
    // then check if current user-org combination has opted of accept later for all contracts with new tnc
    function isAllTncContractsOptedAcceptLater(contracts) {
      onesieSrc2.core.rootStore.contractsStore.saveIfAcceptLaterForContractTnc(
        contracts,
        $location,
        AuthenticatedUser.get().getId()
      );
      return onesieSrc2.core.rootStore.contractsStore.isContractsWithAcceptLater(
        contracts,
        contracts.orgId,
        AuthenticatedUser.get().getId()
      );
    }
  }
})();
/* eslint-enable max-lines */
