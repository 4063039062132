/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.directories:appRemoveIdpModal
 *
 * @description The modal is to confirm the IdP remove operation.
 * @param {Function} onConfirm The callback to confirm the IdP remove operation.
 */

(function () {
  angular.module('app.widgets.directories').component('appRemoveIdpModal', {
    bindings: {
      onConfirm: '&',
    },
    controller,
    templateUrl: 'app/widgets/directories/remove-idp-modal/remove-idp-modal.component.html',
  });

  /* @ngInject */
  function controller(_, APP_REMOVE_IDP_MODAL_ID, appIdpTitleFilter) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      getDescriptionFilterObject,
      onConfirmClicked,
      onOpen,
    });

    function $onInit() {
      _.assign(vm, {
        idp: null,
        modalId: APP_REMOVE_IDP_MODAL_ID,
      });
    }

    // Modal handlers
    function onConfirmClicked() {
      vm.onConfirm({toRemoveIdp: vm.idp});
    }

    function onOpen(params = {}) {
      vm.idp = params.idp;
    }

    function getDescriptionFilterObject() {
      return {toRemoveIdpTitle: appIdpTitleFilter(vm.idp)};
    }
  }
})();
