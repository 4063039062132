import {Locale} from '@admin-tribe/binky';

import {getApiResult} from './searchTopicHelper';

/**
 *
 * @param {string} searchInputValue
 * @returns {array} searched help topics data
 */
// eslint-disable-next-line import/prefer-default-export -- utils file
export const useFetchHelpTopicsData = async (searchInputValue) => {
  // API expects five character code and will normalize on its end for
  // usage/look-ups (i.e. - will replace dashes with underscores and handle
  // capitalization inconsitencies)
  const locale = Locale.get().activeLocaleCode;

  const requestBody = {
    enable_spelling_correction: true,
    facets_fields: ['applicable_products'],
    limit: 10,
    locale,
    post_facet_filters: {
      applicable_products: ['Adobe Enterprise & Teams'],
    },
    q: searchInputValue,
    scope: ['helpx'],
    sort_order: 'desc',
    sort_orderby: 'relevancy',
    start_index: 0,
    subscope: [],
  };

  const searchUrl =
    'https://adobesearch-uss-enterprise-stage.adobe.io/universal-search-enterprise/search';

  const apiResult = await getApiResult(searchUrl, requestBody);

  const searchResultData = apiResult?.data?.result_sets[0]?.items;

  const searchData = searchResultData?.map((data, dataIndex) => ({
    id: String(dataIndex + 1), // match type returned in browser
    // eslint-disable-next-line no-underscore-dangle -- the value comes from endpoint
    link: data?._links?.source?.href,
    title: data?.asset_name,
  }));

  return searchData;
};
