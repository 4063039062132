import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import CloudOutlineIcon from '@spectrum-icons/workflow/CloudOutline';
import React from 'react';
import {useIntl} from 'react-intl';

import SelfCancelCard from '../card/SelfCancelCard';

import styles from './Card.pcss';

const TeamStorageCard = () => {
  const intl = useIntl();
  return (
    <SelfCancelCard
      headerComponent={
        <Flex alignItems="center" height="100%" justifyContent="center">
          <CloudOutlineIcon alt="" size="XL" />
        </Flex>
      }
      testId="team-storage-card"
      title={intl.formatMessage({
        id: 'account.selfCancel.components.lossAversionCard.teamStorage.title',
      })}
    >
      <Flex direction="column" gap="size-300" justifyContent="space-between">
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change text size */}
        <Text alignSelf="center" justifySelf="center" UNSAFE_className={styles['main-text']}>
          {intl.formatMessage({
            id: 'account.selfCancel.components.lossAversionCard.teamStorage.contentTop',
          })}
          &nbsp;&darr;
        </Text>
        <Text>
          {intl.formatMessage(
            {id: 'account.selfCancel.components.lossAversionCard.teamStorage.contentBottom'},
            {
              goUrl: (urlText) => (
                /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change link color */
                <GoUrl name="cloud_storage" noWrap={false} UNSAFE_className={styles['card-go-url']}>
                  {urlText}
                </GoUrl>
              ),
            }
          )}
        </Text>
      </Flex>
    </SelfCancelCard>
  );
};

export default TeamStorageCard;
