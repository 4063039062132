import {feature} from '@admin-tribe/binky';
import {ModalDialog, OverlayWait} from '@admin-tribe/binky-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  Provider,
  useDialogContainer,
} from '@adobe/react-spectrum';
import Alert from '@react/react-spectrum/Alert';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {FEDERATED_TYPES} from 'features/settings/api/ims-federated';
import DomainEnforcementInlineWarnings from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementInlineWarnings';
import useStore from 'features/settings/hooks/useStore';
import FederatedDomainsStore, {
  FEDERATED_DOMAINS_ERROR_CODES,
} from 'features/settings/stores/FederatedDomainsStore';

import FederatedDomainsTable from './FederatedDomainsTable';

import './ImportFederatedDomainsModal.pcss';

const ERRORS = {
  [FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR]: {
    body: 'settings.directoryDomains.addDomainsModalLoadError.body',
    header: 'settings.directoryDomains.addDomainsModalLoadError.header',
  },
  [FEDERATED_DOMAINS_ERROR_CODES.INVALID_TOKEN_ERROR]: {
    body: 'settings.directoryDomains.addDomainsModalTokenError.body',
    header: 'settings.directoryDomains.addDomainsModalTokenError.header',
  },
};

const MODAL_ID = 'import-federated-domains-modal';

// eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition -- iteodore@ to update
const renderAlert = ({authSourceId, externalToken, federatedType, store, intl}) => {
  const errorCode = store.error || FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR;
  const error = ERRORS[errorCode] || ERRORS[FEDERATED_DOMAINS_ERROR_CODES.GENERIC_ERROR];

  return (
    <OverlayWait isLoading={store.loading} size="S">
      <Alert
        closeLabel={intl.formatMessage({
          id: 'settings.directoryDomains.addDomainModal.retryButton',
        })}
        header={intl.formatMessage({
          id: error.header,
        })}
        onClose={() => store.loadData(authSourceId, externalToken, federatedType)}
        variant="error"
      >
        {intl.formatMessage({id: error.body})}
      </Alert>
    </OverlayWait>
  );
};

const ImportFederatedDomainsModal = observer(
  ({
    authSourceId,
    domainEnforcement,
    externalToken,
    federatedType,
    onImportError = () => {},
    onImportSuccess = () => {},
  }) => {
    const store = useStore(() => new FederatedDomainsStore());
    const intl = useIntl();

    const modalHeadingId = useId();
    const dialog = useDialogContainer();

    const handleImportFederatedDomainsModalClose = () => {
      dialog.dismiss();
    };

    // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- neculaes@ to update
    useEffect(() => {
      store.loadData(authSourceId, externalToken, federatedType);
    }, [store, authSourceId, externalToken, federatedType]);

    const dialogTitle =
      federatedType === FEDERATED_TYPES.AZURE
        ? 'settings.directoryDomains.addAzureDomainsTitle'
        : 'settings.directoryDomains.addGoogleDomainsTitle';
    const dialogDescription =
      federatedType === FEDERATED_TYPES.AZURE
        ? 'settings.directoryDomains.addAzureDomainsDescription'
        : 'settings.directoryDomains.addGoogleDomainsDescription';

    // event handlers
    const claimDomains = async () => {
      try {
        await store.claimDomains(authSourceId, externalToken, federatedType);
        onImportSuccess();

        dialog.dismiss();
      } catch (error) {
        onImportError(error);
      }
    };

    return (
      <ModalDialog height="100vh" id={MODAL_ID}>
        <Heading id={modalHeadingId}>
          <FormattedMessage id={dialogTitle} />
        </Heading>
        <Divider />
        <Content>
          {/* This is needed for the v2 components to render properly */}
          <Provider2>
            <Provider>
              <p>{intl.formatMessage({id: dialogDescription})}</p>
              {store.hasError ? (
                renderAlert({authSourceId, externalToken, federatedType, intl, store})
              ) : (
                <>
                  {feature.isEnabled('temp_domain_enforcement') && (
                    <DomainEnforcementInlineWarnings
                      activatedTextKey="settings.directoryDomains.addDomains.warning.domainEnforcement"
                      enforcedTextKey="settings.directoryDomains.addDomains.warning.requireEmailChange"
                      marginBottom="size-200"
                      status={domainEnforcement?.state}
                    />
                  )}
                  <FederatedDomainsTable store={store} />
                </>
              )}
            </Provider>
          </Provider2>
        </Content>
        <ButtonGroup>
          <Button
            data-test-id="close-btn"
            isDisabled={store.loading}
            onPress={handleImportFederatedDomainsModalClose}
            variant="primary"
          >
            <FormattedMessage id="settings.directoryDomains.cancel" />
          </Button>
          <Button
            data-test-id="cta-btn"
            isDisabled={store.hasError || !store.selectedDomainsCount}
            onPress={claimDomains}
            variant="accent"
          >
            <FormattedMessage id="settings.directoryDomains.confirm" />
          </Button>
        </ButtonGroup>
      </ModalDialog>
    );
  }
);

export default ImportFederatedDomainsModal;
export {ERRORS};
