import {navBus} from '@admin-tribe/binky';
import {Page, PageContent, PageHeader, Subpage} from '@admin-tribe/binky-ui';
import {INSIGHTS_SECTION} from '@pandora/data-model-insights';
import {InsightsProvider as PandoraInsightsProvider} from '@pandora/react-insights';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useRouteLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';

import InsightsItemListTable from '../components/InsightsItemListTable';
import useInsightsPaths from '../hooks/useInsightsPaths';

/** Component which displays a single Insights section, showing all Insights Items in a table */
const InsightsSectionPage = ({insightsSection}) => {
  const intl = useIntl();
  const {accessOptions} = useRouteLoaderData('insights');
  const {insightsItemList} = useLoaderData();

  const {reportDetails, logDetails} = useInsightsPaths();

  const title = intl.formatMessage({id: `insights.${insightsSection}.title`});

  useDocumentTitle({defaultTitle: title});

  const onNavigate = useCallback(
    (insightsItem, isNew) => {
      let viewType;
      if (isNew) {
        viewType = 'files';
      } else {
        viewType = insightsItem.tabs[0].id;
      }

      const pathMethod =
        insightsItem.insightsSection === INSIGHTS_SECTION.LOGS ? logDetails : reportDetails;

      navBus.navigate(pathMethod(insightsItem.id, viewType));
    },
    [logDetails, reportDetails]
  );

  return (
    <Page data-testid={`app-insights-${insightsSection}`}>
      <PageHeader title={title} />
      <PageContent>
        <Subpage>
          <PandoraInsightsProvider>
            {/* No content is given because it is provided by the pandoraContentModel */}
            <InsightsItemListTable
              accessOptions={accessOptions}
              insightsItemList={insightsItemList}
              navigateToDetails={onNavigate}
            />
          </PandoraInsightsProvider>
        </Subpage>
      </PageContent>
    </Page>
  );
};

InsightsSectionPage.propTypes = {
  /** The Insights Section to to render the Insights Items of */
  insightsSection: PropTypes.string,
};

export default InsightsSectionPage;
