import {log} from '@admin-tribe/binky';
import {useCallback} from 'react';

import eduRosterSync from 'features/settings/api/eduRosterSync';
import {canAddEdu} from 'features/settings/common/utils/eduUtils';
import {CONSOLE_URL} from 'features/settings/services/ExternalAuthService';

const EDU_SYNC_ACTIONS = {
  QUEUE: 'queue',
  RESUME: 'resume',
  STOP: 'stop',
};

/**
 * Get a list of syncs for which the EDU roster sync is configured on an org
 */
const getAllOrgRosterSyncs = async () => {
  // get roster syncs only if the organization is EDU
  if (!canAddEdu()) {
    return [];
  }

  try {
    return (await eduRosterSync.getOrgRosterSyncs()).data.syncConfigs;
  } catch (error) {
    log.error('[ID][EDU_ROSTER] Error fetching the list of roster syncs for the org');

    throw error;
  }
};

/**
 * Hook to use the EDU roster sync API inside React components
 */
const useEduRosterSync = () => {
  const getAccessToken = useCallback(async ({syncProvider, authCode}) => {
    try {
      return await eduRosterSync.getAccessToken({
        authCode,
        redirectUri: CONSOLE_URL,
        rosterProvider: syncProvider,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting token for sync provider', syncProvider);

      throw error;
    }
  }, []);

  const getRosterTenants = useCallback(async ({syncProvider, accessToken}) => {
    try {
      return await eduRosterSync.getRosterTenants({accessToken, rosterProvider: syncProvider});
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error getting tenants for syncProvider', syncProvider);

      throw error;
    }
  }, []);

  const createRosterSync = useCallback(
    async ({directoryId, districtId, syncProvider, accessToken, syncSchedule, products}) => {
      try {
        return await eduRosterSync.createRosterSync({
          accessToken,
          directoryId,
          districtId,
          products: products || ['CCX'],
          rosterProvider: syncProvider,
          syncSchedule,
        });
      } catch (error) {
        log.error(
          '[ID][EDU_ROSTER] Error creating roster sync for directoryId',
          directoryId,
          'with sync provider',
          syncProvider
        );

        throw error;
      }
    },
    []
  );

  const getAllDirectoryRosterSyncs = useCallback(async ({directoryId, accessToken}) => {
    // get roster syncs only if the organization is EDU
    if (!canAddEdu()) {
      return {data: []};
    }

    try {
      return await eduRosterSync.getAllDirectoryRosterSyncs({
        accessToken,
        directoryId,
      });
    } catch (error) {
      log.error(
        '[ID][EDU_ROSTER] Error fetching the list of roster syncs for directory: ',
        directoryId
      );

      throw error;
    }
  }, []);

  const getAllOrgRosterSyncsCallback = useCallback(getAllOrgRosterSyncs, []);

  const queueRosterSync = useCallback(async ({syncId, accessToken}) => {
    try {
      return await eduRosterSync.queueRosterSync({
        accessToken,
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error adding the sync to the queue. Sync id: ', syncId);

      throw error;
    }
  }, []);

  const stopRosterSyncAction = useCallback(async ({syncId, accessToken}) => {
    try {
      return await eduRosterSync.putRosterSyncAction({
        accessToken,
        syncAction: EDU_SYNC_ACTIONS.STOP,
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error stopping the scheduled sync. Sync id: ', syncId);

      throw error;
    }
  }, []);

  const queueRosterSyncAction = useCallback(async ({syncId, accessToken}) => {
    try {
      return await eduRosterSync.putRosterSyncAction({
        accessToken,
        syncAction: EDU_SYNC_ACTIONS.QUEUE,
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error adding the sync to the queue. Sync id: ', syncId);

      throw error;
    }
  }, []);

  const resumeRosterSyncAction = useCallback(async ({syncId, accessToken}) => {
    try {
      return await eduRosterSync.putRosterSyncAction({
        accessToken,
        syncAction: EDU_SYNC_ACTIONS.RESUME,
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error resuming the scheduled sync. Sync id: ', syncId);

      throw error;
    }
  }, []);

  const removeRosterSync = useCallback(async ({syncId}) => {
    try {
      return await eduRosterSync.removeRosterSync({
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error deleting the sync with id: ', syncId);

      throw error;
    }
  }, []);

  const fetchRosterSyncInfo = useCallback(async ({syncId, fetchIntegration}) => {
    try {
      return await eduRosterSync.fetchRosterSyncInfo({
        fetchIntegration,
        syncId,
      });
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error fetching the sync with id: ', syncId);

      throw error;
    }
  }, []);

  const updateRosterSync = useCallback(async ({syncId, data}) => {
    try {
      return await eduRosterSync.patchRosterSync({data, syncId});
    } catch (error) {
      log.error('[ID][EDU_ROSTER] Error updating the sync with id: ', syncId, error);

      throw error;
    }
  }, []);

  return {
    createRosterSync,
    fetchRosterSyncInfo,
    getAccessToken,
    getAllDirectoryRosterSyncs,
    getAllOrgRosterSyncs: getAllOrgRosterSyncsCallback,
    getRosterTenants,
    queueRosterSync,
    queueRosterSyncAction,
    removeRosterSync,
    resumeRosterSyncAction,
    stopRosterSyncAction,
    updateRosterSync,
  };
};

export default useEduRosterSync;
export {EDU_SYNC_ACTIONS, getAllOrgRosterSyncs};
