import React from 'react';
import {useIntl} from 'react-intl';

import GenericErrorBumper from 'shell/components/generic-error-bumper/GenericErrorBumper';

/** An error bumper that indicates the user does not have access to a page or that it is missing */
const NotFoundBumper = () => {
  const intl = useIntl();

  return (
    <GenericErrorBumper
      details={intl.formatMessage({id: 'shell.bumper.notFound.details'})}
      title={intl.formatMessage({id: 'shell.bumper.notFound.title'})}
    />
  );
};

export default NotFoundBumper;
