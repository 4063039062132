import {PATH_PRODUCT_DETAILS_USERS} from 'features/products/routing/productsPaths';

import help from '../../constants/help';
import {getTeamSignParams} from '../../utils/helpUtils';

/**
 *
 * @returns content for product tab -> team sign
 */
const teamSign = () => {
  const {TOPICS, LINK_TARGET_TYPE, LINK_TYPE, HELP_ITEM_TYPE} = help;
  const {access, id} = getTeamSignParams();
  return {
    access,
    content: [
      {
        links: [
          {
            key: 'help.topics.teamSign.link.one.key',
            locator: PATH_PRODUCT_DETAILS_USERS,
            migrated: true,
            params: {productId: id},
            target: LINK_TARGET_TYPE.APPLICATION,
            type: LINK_TYPE.BUTTON,
          },
          {
            key: 'help.topics.teamSign.link.two.key',
            locator: 'aac_adobesign_team_admin_setup',
            target: LINK_TARGET_TYPE.GO_URL,
            type: LINK_TYPE.TEXT,
          },
        ],
        text: 'help.topics.teamSign.text',
        type: HELP_ITEM_TYPE.STEP,
      },
    ],
    id: TOPICS.TEAM_SIGN,
    parents: [
      {
        id: TOPICS.PRODUCTS,
      },
    ],
    title: 'help.topics.teamSign.title',
  };
};
export default teamSign;
