(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support::appSupportCaseDetailsDrawer
   *
   * @description displays information about a support case which doesn't fit on the details page
   *
   * @binding {SupportCase} supportCase - the support case to display information for
   */
  angular.module('app.widgets.support').component('appSupportCaseDetailsDrawer', {
    bindings: {
      supportCase: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-case-details-drawer/support-case-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(_, SUPPORT_CASE_DETAILS_DRAWER_ID) {
    const vm = this;

    _.assign(vm, {
      SUPPORT_CASE_DETAILS_DRAWER_ID,
    });
  }
})();
