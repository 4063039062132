import {Item, Picker} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {JOB_RESULTS_FILTER} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';

/**
 * A filter for the Job results page - either all results or just failures.
 */
const JobResultsFilter = ({onSelectionChange, selectedKey}) => {
  const intl = useIntl();

  return (
    // Workaround for https://jira.corp.adobe.com/browse/PNDR-859.
    // The Search component has the marginTop and minHeight that keeps the TableFilters
    // in alignment with the TableActions
    // Because there is no Search, the Picker is out of alignment with the button.
    // marginTop and minHeight are to apply what is normally applied to the SearchField.
    <Picker
      label={intl.formatMessage({
        id: 'users.jobResultsFilter.picker.label',
      })}
      marginTop="size-200"
      minHeight="size-1200"
      onSelectionChange={onSelectionChange}
      selectedKey={selectedKey}
    >
      <Item key={JOB_RESULTS_FILTER.ALL}>
        {intl.formatMessage({
          id: 'users.jobResultsFilter.picker.key.all',
        })}
      </Item>
      <Item key={JOB_RESULTS_FILTER.FAILURES}>
        {intl.formatMessage({
          id: 'users.jobResultsFilter.picker.key.failures',
        })}
      </Item>
    </Picker>
  );
};

JobResultsFilter.propTypes = {
  /**
   * The method that is called when the filter changes. It takes one parameter which is the key.
   */
  onSelectionChange: PropTypes.func.isRequired,
  /**
   * The key currently selected in the filter.
   */
  selectedKey: PropTypes.oneOf(Object.values(JOB_RESULTS_FILTER)),
};

export default JobResultsFilter;
