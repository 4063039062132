import {Text} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationIconDetail from './BulkOperationIconDetail';

/* displays why the bulk operation failed */
const JobFailedSummary = () => {
  const intl = useIntl();
  const {getJobStatusReason} = useBulkOperationJob();
  const operationFailedText = intl.formatMessage({
    id: 'bulkOperations.results.drawer.section.result.operationFailed',
  });

  return (
    <>
      <BulkOperationIconDetail aria-label={operationFailedText} color="negative" Icon={AlertIcon}>
        {operationFailedText}
      </BulkOperationIconDetail>
      <Text UNSAFE_style={{color: 'var(--spectrum-semantic-negative-color-default)'}}>
        {getJobStatusReason()}
      </Text>
    </>
  );
};

export default JobFailedSummary;
