import {useState} from 'react';

import {DEFAULT_TABLE_OPTIONS} from './jilTableOptionsConstants';

/**
 * Hook that helps with managing the state of parameters given to a JIL table.
 * The table options are also used to make the JIL API call.
 *
 * @param {Object} options config object merged with constant DEFAULT_TABLE_OPTIONS
 * @return [tableOptions, setTableOptions] standard React useState syntax
 */
const useJilTableOptions = (options = {}) => {
  const [tableOptions, setTableOptions] = useState({
    ...DEFAULT_TABLE_OPTIONS,
    ...options,
  });

  return [tableOptions, setTableOptions];
};

export default useJilTableOptions;
