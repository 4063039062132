import {log} from '@admin-tribe/binky';
import {useCallback, useRef, useState} from 'react';

import sophiaHelper from 'common/services/sophia/sophiaHelper';

// flag to store whether we have seen the modal. This is to prevent re-mounting
// the component
let modalShown = false;

/**
 * function to reset the modal shown state, so that it is allowed to be shown
 *     again. Otherwise, calling trigger, re-using the hook, or re-mounting the
 *     component using the hook would not call the Sophia API again, and not
 *     show the modal.
 */
const resetModalShown = () => {
  modalShown = false;
};

/**
 * hook for getting render information for Sophia Modal
 *
 * @returns an object containing the following properties:
 * - closeModal: function to close the modal
 * - isOpen: boolean indicating whether the modal is open
 * - sophiaModalContent: object containing the content to render in the modal
 * - trigger: no params, no return method to trigger calling the Sophia API and
 *       potentially update state. Note - It would not make an API call if the
 *       modal has already been shown, unless resetModalShown is called.
 */
const useSophiaModal = () => {
  const [sophiaModalContent, setSophiaModalContent] = useState(null);
  const [showSophiaModal, setShowSophiaModal] = useState(false);
  const isLoading = useRef(false);

  const trigger = useCallback(async () => {
    if (isLoading.current || modalShown) {
      return;
    }

    let sophiaContentList;
    isLoading.current = true;

    try {
      sophiaContentList = await sophiaHelper.getSophiaContent({
        surfaceId: 'one_console_overview_modal',
      });
    } catch (error) {
      log.error('Error calling Sophia:', error);
      return;
    }

    if (sophiaContentList.items.length > 0) {
      const sophiaContent = sophiaContentList.items[0];
      if (sophiaContent.isJson()) {
        const sophiaContentJSON = JSON.parse(sophiaContent.getContent());
        setSophiaModalContent(sophiaContentJSON);
        setShowSophiaModal(true);
        modalShown = true;
      } else {
        log.error('Sophia data is not JSON', sophiaContent);
      }
    }
    isLoading.current = false;
  }, []);

  return {
    closeModal: () => setShowSophiaModal(false),
    isOpen: showSophiaModal,
    sophiaModalContent,
    trigger,
  };
};

export {resetModalShown};
export default useSophiaModal;
