/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to display the label string for a scorecard.
 * @param {Object} ScorecardGroupItem Data to build scorecard.
 * @returns {String} if specified, item.label, else a title-case label string, for example,
 *   'Credits Used' or 'Integrations'
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.scorecard').filter('appScorecardLabel', appScorecardLabelFilter);

  /* @ngInject */
  function appScorecardLabelFilter($log, $translate, _, SCORECARD_LABEL_UNIT) {
    return getScorecardLabel;

    function getScorecardLabel(item) {
      if (item.showUsageUnavailable && _.isUndefined(item.score) && _.isUndefined(item.total)) {
        return $translate.instant(`widgets.common.scorecard.label.${item.usageUnavailableKey}`);
      }

      if (item.label) {
        return item.label;
      }

      const unit = item.unit;

      if (_.includes(SCORECARD_LABEL_UNIT, unit)) {
        return $translate.instant(
          `widgets.common.scorecard.label.messageFormat.${unit}`,
          {
            count: item.score,
          },
          'messageformat'
        );
      }

      $log.debug(`appScorecardLabelFilter: unknown unit '${unit}'`);

      return '';
    }
  }
})();
