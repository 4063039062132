import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';

const {SUPPORT_CASE_TYPE, SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

/**
 * Widget to display localized text for SupportTicket typeCode value based on the recordType
 */
const SupportTicketCaseType = ({typeCode, recordType}) => {
  const intl = useIntl();
  const stringKeyMap = {
    [SUPPORT_CASE_TYPE.ADMIN]: 'administration',
    [SUPPORT_CASE_TYPE.PRODUCT]: 'product',
  };
  const key = stringKeyMap[typeCode];
  const value =
    recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
      ? intl.formatMessage({id: `support.supportCase.caseType.${key}`})
      : intl.formatMessage({id: `support.expertSession.caseType.${key}`});
  return <View>{value}</View>;
};

SupportTicketCaseType.propTypes = {
  /**
   * Prop to get recordType
   */
  recordType: PropTypes.oneOf([SUPPORT_TICKET_RECORD_TYPE.CASE, SUPPORT_TICKET_RECORD_TYPE.EXPERT])
    .isRequired,
  /**
   * Prop to get typeCode
   */
  typeCode: PropTypes.number.isRequired,
};

export default SupportTicketCaseType;
