import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

import HelpTopicList from '../help-topic-lists/HelpTopicList';
import CreativeCloud from '../help-topic-lists/content-models/CreativeCloud';
import DocumentCloud from '../help-topic-lists/content-models/DocumentCloud';
import ExperienceCloud from '../help-topic-lists/content-models/ExperienceCloud';
import GeneralAdministration from '../help-topic-lists/content-models/GeneralAdministration';
import MoreProducts from '../help-topic-lists/content-models/MoreProducts';
import Team from '../help-topic-lists/content-models/Team';

/**
 * HelpTopicTabs shows a collection of help topic cards for each cloud
 */
const HelpTopicTabs = ({tabs, onTabChanged}) => {
  const intl = useIntl();
  const tabMapping = {
    'org.support.summary.team': {
      contentModel: Team,
      key: SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.TEAM,
      name: 'team-cloud',
    },
    'support.summary.administration.title': {
      contentModel: GeneralAdministration,
      key: SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL,
      name: intl.formatMessage({id: 'support.supportHelpTopics.tabs.headings.administration'}),
    },
    'support.summary.creativeCloud.title': {
      contentModel: CreativeCloud,
      key: CLOUD.CREATIVE,
      name: intl.formatMessage({id: 'support.supportHelpTopics.tabs.headings.creativeCloud'}),
    },
    'support.summary.documentCloud.title': {
      contentModel: DocumentCloud,
      key: CLOUD.DOCUMENT,
      name: intl.formatMessage({id: 'support.supportHelpTopics.tabs.headings.documentCloud'}),
    },
    'support.summary.experienceCloud.title': {
      contentModel: ExperienceCloud,
      key: CLOUD.EXPERIENCE,
      name: intl.formatMessage({id: 'support.supportHelpTopics.tabs.headings.experienceCloud'}),
    },
    'support.summary.moreProducts.title': {
      contentModel: MoreProducts,
      key: CLOUD.OTHERS,
      name: intl.formatMessage({id: 'support.supportHelpTopics.tabs.headings.moreProducts'}),
    },
  };

  const tabsToShow = tabs.reduce((filteredTabs, tab) => {
    if (!tab.hidden) {
      const name = tab.name || tab.state.name;
      filteredTabs.push({
        name,
        tabInfo: tabMapping[name],
      });
    }
    return filteredTabs;
  }, []);

  if (tabsToShow.length > 1) {
    return (
      <Tabs
        aria-label={intl.formatMessage({id: 'support.helpTopicTabs.ariaLabel'})}
        items={tabsToShow}
        onSelectionChange={onTabChanged}
      >
        <TabList>{(item) => <Item key={item.tabInfo.key}>{item.tabInfo.name}</Item>}</TabList>
        <TabPanels>
          {(item) => (
            <Item key={item.tabInfo.key}>
              <HelpTopicList
                cloudType={item.tabInfo.key}
                contentModel={item.tabInfo.contentModel}
              />
            </Item>
          )}
        </TabPanels>
      </Tabs>
    );
  }
  return <HelpTopicList contentModel={tabsToShow[0].tabInfo.contentModel} />;
};

HelpTopicTabs.propTypes = {
  /**
   * Callback function that gets called when tab changes. The new cloud is passed
   */
  onTabChanged: PropTypes.func,
  /**
   * List of tabs which are used to configure Tabs in this component
   */
  tabs: PropTypes.arrayOf(PropTypes.shape({hidden: PropTypes.bool})).isRequired,
};

export default HelpTopicTabs;
