import {
  DATE_FORMATS,
  FormattedDateAndTime,
  StatusLight,
  Table,
  TableActions,
  TableSection,
  TokenPagination,
  getMemberDisplayName,
  showError,
  showSuccess,
  useTableSectionContext,
} from '@admin-tribe/binky-ui';
import {ButtonGroup, Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {REPORT_STATUS} from 'common/services/storage-report/StorageReportConstants';

import CreateReportButton from '../create-report/button/CreateReportButton';
import DeleteReportButton from '../delete-report-button/DeleteReportButton';
import DownloadReportButton from '../download-report-button/DownloadReportButton';

/**
 * The table for an "Individual user" or "Shared folder" storage report table.
 */
const ReportListTable = observer(() => {
  const {listStore} = useTableSectionContext(); // a StorageReportListStore
  const intl = useIntl();

  const getVariant = (status) => {
    if (status === REPORT_STATUS.ERROR) {
      return 'negative';
    }
    if (status === REPORT_STATUS.INPROGRESS) {
      return 'notice';
    }
    return 'positive';
  };

  const onDeleteReport = async () => {
    try {
      // This will trigger the OverlayWait over the table.
      // It will be set to false when listStore.load completes or if there is an error.
      listStore.isLoading = true;
      await listStore.tokenService.permanentDelete(listStore.tableStore.selectedItems);
      listStore.load();
      showSuccess(
        intl.formatMessage(
          {id: 'storage.storageReports.deleteReportModal.toast.success'},
          {count: listStore.tableStore.selectedItemCount}
        )
      );
      // Clear this after we use the count for the toast above.
      listStore.tableStore.clearSelection();
    } catch {
      showError();
      listStore.isLoading = false;
    }
  };

  return (
    <TableSection>
      <TableActions>
        <ButtonGroup align="end" isDisabled={listStore.hasLoadingError} width="100%">
          <DeleteReportButton onDeleteReport={onDeleteReport} />
          <CreateReportButton
            folderType={listStore.folderType}
            onCreateReport={() => listStore.load()}
          />
        </ButtonGroup>
      </TableActions>
      <Table
        ariaLabel={intl.formatMessage({
          id: 'storage.storageReports.details.table.ariaLabel',
        })}
        noItemsFoundMessage={intl.formatMessage({
          id: 'storage.storageReports.details.table.noItems',
        })}
      >
        <TableHeader>
          <Column key="report-name" minWidth="30%">
            <FormattedMessage id="storage.storageReports.details.table.column.reportName" />
          </Column>
          <Column key="creator">
            <FormattedMessage id="storage.storageReports.details.table.column.creator" />
          </Column>
          <Column key="creation-date">
            <FormattedMessage id="storage.storageReports.details.table.column.creationDate" />
          </Column>
          <Column key="status">
            <FormattedMessage id="storage.storageReports.details.table.column.status" />
          </Column>
          <Column key="action">
            <FormattedMessage id="storage.storageReports.details.table.column.action" />
          </Column>
        </TableHeader>
        <TableBody items={listStore.tableStore.rows}>
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- table must be inline */}
          {(item) => (
            <Row key={item.id}>
              <Cell>{item.data.name}</Cell>
              <Cell>
                {getMemberDisplayName(intl, item.data.createdBy, {fallbackToEmail: true})}
              </Cell>
              <Cell>
                <FormattedDateAndTime
                  format={DATE_FORMATS.dateTimeMedium}
                  value={item.data.repositoryCreatedDate}
                />
              </Cell>
              <Cell>
                <StatusLight alignStart variant={getVariant(item.data.status)}>
                  <FormattedMessage
                    id={`storage.storageReports.details.status.variant.${getVariant(
                      item.data.status
                    )}`}
                  />
                </StatusLight>
              </Cell>
              <Cell>
                {item.data.status === REPORT_STATUS.SUCCESS && (
                  <DownloadReportButton report={item.data} />
                )}
              </Cell>
            </Row>
          )}
        </TableBody>
      </Table>
      <TokenPagination />
    </TableSection>
  );
});

export default ReportListTable;
