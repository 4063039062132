import rootStore from 'core/RootStore';
import {canSetUpOrganizationTypes} from 'core/organizations/access/organizationAccess';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

import {
  BANNER_ORGANIZATION_TYPE_MODAL_ID,
  ORGANIZATION_TYPE_BANNER_ID,
} from './organizationSettingsConstants';

/**
 * @description Notifier method to check if the current org can assign market
 *     subsegments, and if so we'll show a banner that can launch the modal to
 *     make that assignment.
 */
function notifyOrganizationSettingsBanners() {
  if (canSetUpOrganizationTypes()) {
    rootStore.organizationStore.globalBannerStore.add({
      buttonText: 'globalBanner.buttonText.organizationTypeSelector',
      id: ORGANIZATION_TYPE_BANNER_ID,
      message: 'globalBanner.messages.organizationTypeSelector',
      modalId: BANNER_ORGANIZATION_TYPE_MODAL_ID,
      type: GLOBAL_BANNER_TYPES.INFO,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyOrganizationSettingsBanners};
