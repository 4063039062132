import {ConsumableSummarizationList, Product, log} from '@admin-tribe/binky';
import {InfoBar} from '@admin-tribe/binky-ui';
import {Rule} from '@pandora/data-model-acrs';
import {useIsMounted} from '@pandora/react-is-mounted';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {
  getGroupConsumableItems,
  getLicenseItems,
} from 'features/products/components/product-page-info-bar/utils/productPageInfoBarUtils';

/**
 * Info bar for product with scorecard data.\
 */
const ProductPageInfoBar = ({
  consumableSummarizationList,
  product,
  productProfileRule = undefined,
}) => {
  const intl = useIntl();
  const isMounted = useIsMounted();

  const [infoCards, setInfoCards] = useState([]);

  // Get all scorecards to display
  useEffect(() => {
    const getInfoCards = async () => {
      try {
        // This path is not used by Enterprise Adobe Stock which uses its own proprietary API and scorecard.
        let resolvedInfoCard;
        if (product.isGroupConsumable()) {
          resolvedInfoCard = await getGroupConsumableItems({
            consumableSummarizationList,
            intl,
            product,
          });
        } else if (!product.isLegacyStock()) {
          resolvedInfoCard = await getLicenseItems({
            intl,
            product,
            productProfileRule,
          });
        }
        if (isMounted) {
          setInfoCards(resolvedInfoCard || []);
        }
      } catch (error) {
        log.error('Failed to load scorecards, error:', error);
      }

      return infoCards;
    };

    getInfoCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run when below changes
  }, [consumableSummarizationList, product.id, productProfileRule]);

  return infoCards.length > 0 && <InfoBar>{infoCards}</InfoBar>;
};

ProductPageInfoBar.propTypes = {
  /**
   * The ConsumableSummarizationList model
   */
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList),
  /**
   * The product to display info for
   */
  product: PropTypes.instanceOf(Product).isRequired,
  /**
   * The auto assignment rule, if applicable.
   * If auto assignment is supported but there is no rule, productProfileRule will be an empty object.
   * If auto assignment is not supported this will be undefined.
   */
  productProfileRule: PropTypes.oneOfType([PropTypes.instanceOf(Rule), PropTypes.object]),
};
export default ProductPageInfoBar;
