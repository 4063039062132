import {feature} from '@admin-tribe/binky';
import binkyUI from '@admin-tribe/binky-ui';
import {ActionButton, Button, Flex, TextField} from '@adobe/react-spectrum';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import Close from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import AppConstants from 'common/services/AppConstants';
import ConfigurationStep from 'features/settings/components/configuration-step/ConfigurationStep';
import SamlCustomAttributes from 'features/settings/components/saml-custom-attributes/SamlCustomAttributes';

import './GoogleSamlConfigurationSteps.pcss';

const FileDropZone = binkyUI.common.components.FileDropZone;

const METADATA_ACCEPTED_TYPES = ['application/xml', 'text/xml'];

const GoogleSamlConfigurationSteps = ({
  acsUrl,
  entityId,
  customAttributes,
  isIdpActive,
  onFileChanged,
  onAttributesChanged,
}) => {
  const intl = useIntl();
  const [selectedFile, setSelectedFile] = useState(
    isIdpActive
      ? new File(
          [],
          intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.placeholderFileName'})
        )
      : null
  );

  const handleFilesAdded = (filesAdded) => {
    const file = filesAdded.length > 0 && filesAdded[0];
    setSelectedFile(file);
    onFileChanged(file);
  };

  const handleRemoveMetadata = () => {
    setSelectedFile(null);
    onFileChanged(null);
  };

  return (
    <>
      <ConfigurationStep
        title={intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.step1'})}
      >
        <p>
          <FormattedMessage id="settings.googleSamlConfigurationSteps.loginToGoogle" />
        </p>
        <Button
          data-test-id="login-to-google-btn"
          elementType="a"
          href={AppConstants.configuration.services.directorySync.googlePortalUrl}
          target="_blank"
          variant="secondary"
        >
          <FormattedMessage id="settings.googleSamlConfigurationSteps.goToGoogle" />
        </Button>
      </ConfigurationStep>

      <ConfigurationStep
        title={intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.step2'})}
      >
        <ol>
          <li>
            <FormattedMessage id="settings.googleSamlConfigurationSteps.selectAdobeFromList" />
          </li>
          <li>
            <FormattedMessage id="settings.googleSamlConfigurationSteps.downloadGoogleMetadata" />
          </li>
          <li>
            <FormattedMessage id="settings.googleSamlConfigurationSteps.copyAndPasteInformation" />
          </li>
          <li>
            <FormattedMessage id="settings.googleSamlConfigurationSteps.defineNameId" />
          </li>
        </ol>

        <Flex alignItems="flex-start" direction="column" rowGap="16px">
          <TextField
            data-test-id="acs-url-textfield"
            isDisabled
            label={intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.acsUrlLabel'})}
            value={acsUrl}
            width="100%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.googleSamlConfigurationSteps.copyAcsUrl',
            })}
            data-test-id="acs-url-copy-btn"
            value={acsUrl}
          >
            <FormattedMessage id="settings.googleSamlConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>

          <TextField
            data-test-id="entity-id-textfield"
            isDisabled
            label={intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.entityIdLabel'})}
            value={entityId}
            width="100%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.googleSamlConfigurationSteps.copyEntityId',
            })}
            data-test-id="entity-id-copy-btn"
            value={entityId}
          >
            <FormattedMessage id="settings.googleSamlConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>
        </Flex>
      </ConfigurationStep>

      <ConfigurationStep
        title={intl.formatMessage({id: 'settings.googleSamlConfigurationSteps.step3'})}
      >
        <p>
          <FormattedMessage id="settings.googleSamlConfigurationSteps.uploadMetadata" />
        </p>

        {!selectedFile && (
          <FileDropZone
            acceptedTypes={METADATA_ACCEPTED_TYPES}
            className="fileDropZone"
            onFileAdded={handleFilesAdded}
          />
        )}

        {selectedFile && (
          <div data-test-id="file-card" styleName="file-card">
            <div data-test-id="file-name" styleName="file-name">
              <AttachIcon />
              {selectedFile.name}
            </div>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'settings.googleSamlConfigurationSteps.removeMetadata',
              })}
              data-test-id="remove-file-btn"
              isQuiet
              onPress={handleRemoveMetadata}
            >
              <Close />
            </ActionButton>
          </div>
        )}
      </ConfigurationStep>

      {feature.isEnabled('temp_mfa_saml_controls') && (
        <SamlCustomAttributes
          mfaAuthnContextClasses={customAttributes.mfaAuthnContextClasses}
          onChange={onAttributesChanged}
          reauthAuthnContextClasses={customAttributes.reauthAuthnContextClasses}
        />
      )}
    </>
  );
};

GoogleSamlConfigurationSteps.propTypes = {
  acsUrl: PropTypes.string,
  customAttributes: PropTypes.shape({
    mfaAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
    reauthAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  entityId: PropTypes.string,
  isIdpActive: PropTypes.bool,
  onAttributesChanged: PropTypes.func.isRequired,
  onFileChanged: PropTypes.func,
};

export default GoogleSamlConfigurationSteps;
