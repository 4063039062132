(function () {
  angular.module('app.core.analytics.launch-descriptor', [
    'app.widgets.products.purchase',
    'binky.core.analytics-page',
    'binky.core.common.analytics',
    'binky.core.js-utils',
    'binky.core.locale',
    'binky.core.ui-event',
  ]);
})();
