(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.api.sophia').provider('sophia', sophiaProvider);

  /* @ngInject */
  function sophiaProvider() {
    let url;

    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = config ? config.url : '';
    }

    ////////////

    /* @ngInject */
    function $get($resource) {
      const service = {
        fetchContent: getFetchContent(),
      };

      return service;

      ////////////

      function getFetchContent() {
        return $resource(url);
      }
    }
  }
})();
