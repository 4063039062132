import {hasIndirectContract} from '@admin-tribe/binky';
import {parseISO} from 'date-fns';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

/**
 * Determines if the current logged-in user has enterprise contract access.
 * @returns {Boolean} true if logged in user has access
 */
function hasEnterpriseContractAccess() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  const contractList = rootStore.organizationStore.contractList;

  if (!isUserOrgAdmin || !contractList) return false;

  return !!contractList?.items?.find((item) => item.isEnterpriseContract());
}

/**
 * Determines if the current logged-in user has access to create enterprise create user groups.
 * @returns {Boolean} true if logged in user has access
 */
function hasEnterpriseCreateUserGroupsAccess() {
  // to check whether logged in user is user group admin or not
  const isUserUserGroupAdmin = auth.isUserUserGroupAdmin();
  const contractList = rootStore.organizationStore.contractList;

  if (!isUserUserGroupAdmin || !contractList) return false;

  return !!contractList?.items?.find((item) => item.isEnterpriseContract());
}

/**
 * Determines if the current logged-in user has access for evip licence migration.
 * @returns {Boolean} true if logged in user has access
 */
function hasEvipLicenceMigrationAccess() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  const {contractList, productList} = rootStore.organizationStore;

  if (!isUserOrgAdmin || !contractList || !productList) return false;

  const hasAnyProducts = typeof productList === 'object' && productList.items.length > 0;

  return hasIndirectContract(contractList) && hasAnyProducts && productList.hasEVIPProducts;
}

/**
 * Determines if the current logged-in user has access for manual licence migration.
 * @returns {Boolean} true if logged in user has access
 */
function getLicenseMigrationManualParams() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  if (isUserOrgAdmin) {
    const {activeOrgId, contractList} = rootStore.organizationStore;
    const eligibleContract = contractList?.items?.find(
      (contract) => contract.hasEligibleManualMigration() && contract.isIndirectContract()
    );
    if (eligibleContract) {
      const {contractExpirationDate} = eligibleContract;
      return {
        access: true,
        activeOrgId,
        contractExpirationDate,
      };
    }
  }
  return {
    access: false,
  };
}

/**
 * Determines if the current logged-in user has access for auto licence migration.
 * @returns {Boolean} true if logged in user has access
 */
function getLicenseMigrationAutoParams() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  if (isUserOrgAdmin) {
    const {activeOrgId, contractList} = rootStore.organizationStore;
    const eligibleContract = contractList?.items?.find(
      (contract) => contract.hasTeamToEnterpriseEligibleMigration() && contract.isIndirectContract()
    );
    if (eligibleContract) {
      const contractExpirationDate = parseISO(eligibleContract.getRenewalWindowEndDate());
      return {
        access: true,
        activeOrgId,
        contractExpirationDate,
      };
    }
  }
  return {
    access: false,
  };
}

export {
  hasEnterpriseContractAccess,
  hasEnterpriseCreateUserGroupsAccess,
  hasEvipLicenceMigrationAccess,
  getLicenseMigrationAutoParams,
  getLicenseMigrationManualParams,
};
