(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationToken
   * @description Model that represents the DNS token for the org.
   */
  angular
    .module('app.core.organizations.token')
    .factory('OrganizationToken', getOrganizationTokenModel);

  /* @ngInject */
  function getOrganizationTokenModel($log, $q, jilToken, MODEL, modelCache) {
    class OrganizationToken {
      /**
       * @description Creates a new OrganizationToken.
       *
       * @param {Object} options - options for the new organization token
       * @param {String} [options.id] - the org id
       */
      constructor(options) {
        this.id = options.id;
        updateModel(this, options);
      }

      /**
       * @description Method to return the current unique key for this SAML config.
       *
       * @returns {String} key to uniquely identify this object
       */
      key() {
        return this.id;
      }

      /**
       * @description Method to refresh the current model state against what is
       *              stored in the back-end.
       *
       * @return {Promise} resolves to refreshed OrganizationToken model, else rejects
       *                   with error message
       */
      refresh() {
        const deferred = $q.defer();

        this.$promise = deferred.promise;
        this.$resolved = false;

        jilToken.token.get(
          {},
          (response) => {
            updateModel(this, response);
            this.$resolved = true;
            deferred.resolve(this);
            modelCache.put(MODEL.ORGANIZATIONTOKEN, this, this.key());
          },
          (error) => {
            $log.error('OrganizationToken failed to load. Error: ', error);
            this.$resolved = true;
            deferred.reject(error);
          }
        );

        return deferred.promise;
      }

      /** Static Methods **/
      /**
       * @class
       * @description Transforms a data response Object into a new Contract
       *   instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       * @param {String} dataTransferObject.token - the DNS token
       */
      static apiResponseTransformer(dataTransferObject) {
        return new OrganizationToken(dataTransferObject);
      }

      /**
       * @description Method to retrieve the locale for the currently active
       *              organization.
       *
       * @param {String} [options] - options
       * @param {String} [options.id] - the org id
       * @returns {OrganizationToken} Reference to OrganizationToken model
       */
      static get(options) {
        let model = modelCache.get(MODEL.ORGANIZATIONTOKEN, options.id);
        if (model) {
          return model;
        }

        model = new OrganizationToken({id: options.id});
        model.refresh();

        return model;
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.ORGANIZATIONTOKEN, 1);

    return OrganizationToken;

    /**
     * @description Updates model values with values from data object. This
     *              method is particularly useful when an API response
     *              delivers back-end changes that need to sync with the
     *              front-end model state.
     *
     * @param {Object} model - response data from get or save call
     * @param {Object} dataObject - object whose properties to update from
     * @returns {void}
     */
    function updateModel(model, dataObject) {
      model.token = dataObject.token;
    }
  }
})();
