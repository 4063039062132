import {LICENSE_QUANTITY_STATUS, MemberType, User} from '@admin-tribe/binky';

class ComplianceStatusUser extends User {
  /**
   * @description Method to construct a ComplianceStatusUser from a data Object containing user information.
   *
   * @param {Object} responseData Object containing user data
   * @returns {ComplianceStatusUser} Reference to ComplianceStatusUser
   */
  static apiResponseTransformer(responseData) {
    return new ComplianceStatusUser(responseData);
  }

  static canTransform(data) {
    const memberType = new MemberType(data?.type, data?.id);
    return memberType.isUser();
  }

  /**
   * @description Creates a new ComplianceStatusUser.
   * @augments User
   *
   * @param {Object} [options] options for the ComplianceStatusUser as detailed below
   * @param {String} [options.licenseEndDate] An ComplianceStatusUser's license end date
   * @param {String} [options.paymentStatus] An ComplianceStatusUser's payment status
   */
  constructor(options = {}) {
    super(options);
    this.licenseEndDate = options.licenseEndDate;
    this.paymentStatus = options.paymentStatus;
  }

  /**
   * @description Method to determine if this Member has overdue licenses, in the context
   *  of the product the member was fetched for
   *
   * @returns {Boolean} true if Member has overdue licenses, else false
   */
  hasOverdueLicenses() {
    return this.paymentStatus === LICENSE_QUANTITY_STATUS.GRACE_PAST_DUE;
  }
}

export default ComplianceStatusUser;
