import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import ProductsWorkspaceNav from 'features/products/components/products-workspace-nav/ProductsWorkspaceNav';

/**
 * The Products workspace displays the ProductsWorkspaceNav and
 * any applicable nested children routes specified in productsRoutes.jsx
 */
const ProductsWorkspace = () => (
  <Workspace useOutlet>
    <ProductsWorkspaceNav />
  </Workspace>
);

export default ProductsWorkspace;
