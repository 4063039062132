(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.identity').component('appSetupIdentityModal', {
    bindings: {
      directoryList: '<',
      domainList: '<',
    },
    controller,
    templateUrl: 'app/widgets/identity/setup-identity-modal/setup-identity-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $state,
    _,
    panelManager,
    SETUP_IDENTITY_MODAL_ID,
    SETUP_IDENTITY_MODAL_SECTION_CONTEXT
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      onClose,
      onOpen,
    });

    //////////////////

    function $onInit() {
      _.assign(vm, {
        modalId: SETUP_IDENTITY_MODAL_ID,
      });
    }

    function onClose() {
      if (!vm.directoryList.isEmpty()) {
        $state.go('settings.identity.directories');
      } else if (!vm.domainList.isEmpty()) {
        $state.go('settings.identity.domains');
      }
      panelManager.close(vm.modalId);
    }

    function onOpen() {
      vm.contextConstants = _.values(SETUP_IDENTITY_MODAL_SECTION_CONTEXT);
    }
  }
})();
