(function () {
  /**
   * @ngdoc module
   * @name app.widgets.products.purchase.add-products-modal
   * @description module that defines add-products-modal2 component
   */
  angular.module('app.widgets.products.purchase.add-products-modal', [
    'app.core.cart',
    'app.core.chat',
    'app.core.contract.access',
    'app.core.migration.t2e',
    'app.core.organizations.access',
    'app.core.organizations.organization-manager',
    'app.core.payment-instrument',
    'app.core.product.access',
    'app.core.product.purchase',
    'app.main.global-modals-manager',
    'app.widgets.organization-type-form',
    'app.widgets.products',
    'app.widgets.products.purchase',
    'app.widgets.support',
    'binky.core.analytics-page',
    'binky.core.lodash',
    'binky.core.organizations',
    'binky.core.translation-utils',
    'binky.core.ui-event',
    'binky.core.user.authenticated-user',
    'binky.shell.panels.manager',
    'binky.shell.panels.modal',
    'binky.ui-migration',
    'binky.widgets.commerce',
    'binky.widgets.common.button',
    'binky.widgets.common.button-bar',
    'binky.widgets.common.date-time',
    'binky.widgets.common.events',
    'binky.widgets.common.wait',
    'pascalprecht.translate',
  ]);
})();
