(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OnesieExtendedUserProfile
   * @description Model for an extended user profile used by Onesie.
   */
  angular
    .module('app.core.extended-user-profile')
    .factory('OnesieExtendedUserProfile', getExtendedUserProfileModel);

  /* @ngInject */
  function getExtendedUserProfileModel(
    $q,
    _,
    auth,
    ExtendedUserProfile,
    onesieExtendedUserProfileAccess
  ) {
    class OnesieExtendedUserProfile extends ExtendedUserProfile {
      /**
       * @description Creates a new ExtendedUserProfile.
       *
       * @param {Object} options options for the ExtendedUserProfile as detailed below
       * @param {String} options.id An ExtendedUserProfile's ID
       * @param {Date} [options.adminConsoleFirstLoginTime] An ExtendedUserProfile's first login time for the Admin Console website, as a date object
       */
      constructor(options = {}) {
        super(options);
        _.assignIn(this, _.pick(options, ['adminConsoleFirstLoginTime']));
      }

      /**
       * @description Method to update the current object with data object response.
       *
       * @param {Object} response - Object containing user data (e.g. - DTO)
       * @param {String} [response.adminConsoleFirstLoginTime] An ExtendedUserProfile's first login time for the Admin Console website, as a string
       */
      apiResponseUpdater(response) {
        const firstLoginTimeValue = _.get(response, 'adminConsoleFirstLoginTime');
        if (firstLoginTimeValue) {
          this.adminConsoleFirstLoginTime = new Date(firstLoginTimeValue);
        }
      }

      /**
       * @description Method to run during the run-ready startup phase of Onesie.
       *
       * @returns {Promise} resolves when all actions are complete.
       */
      runReady() {
        if (this.adminConsoleFirstLoginTime) {
          this.$promise = $q.resolve();
        } else {
          this.$promise = this.updateFirstLoginTimeToNow();
        }

        return this.$promise;
      }

      /**
       * @description Method to update an existing ExtendedUserProfile's
       *              AdminConsole's first login time on the back-end data store.
       *
       * @returns {Promise} resolves if changes successfully saved, else rejects with error message
       */
      updateFirstLoginTimeToNow() {
        if (onesieExtendedUserProfileAccess.canPatchUserProfile()) {
          this.adminConsoleFirstLoginTime = new Date(); // now
          this.$promise = this.update(generatePatchPayload.call(this));
        } else {
          this.$promise = $q.resolve();
        }

        return this.$promise;

        function generatePatchPayload() {
          return [
            {
              op: 'replace',
              path: 'adminConsoleFirstLoginTime',
              value: this.adminConsoleFirstLoginTime.toISOString(),
            },
          ];
        }
      }

      /**
       * @description Method to retrieve an existing OnesieExtendedUserProfile from the
       *              back-end data store.
       *
       * @returns {ExtendedUserProfile} Reference to the retrieved user profile, or a blank one if it doesn't exist on the backend
       */
      static get() {
        const id = auth.getUserId();
        return ExtendedUserProfile.getWithId(OnesieExtendedUserProfile, id);
      }
    }

    return OnesieExtendedUserProfile;
  }
})();
