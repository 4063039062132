/**
 * A wrapper for the help topic content models which are copied from @pandora/react-help-topic-cards.  Expects a localized JSON document
 * and adds get() to each card content model.  Its main job is to expose the search method on these local copies of the cloud-specific
 * HelpTopicCardList content models.
 */
const CardListContentModel = (cardsRichTextJson) => {
  // The content models for each card collection are accessed through ContentEntryInterface.get() so expose the richTextJson through a get method
  const cardContentWithGet = cardsRichTextJson.helpTopicCards.map((c) => ({
    get: () => c.richTextJson,
  }));

  return {
    search: () => cardContentWithGet,
  };
};

export default CardListContentModel;
