import {MINIMUM_PERMISSIONS_FOR_SEARCH} from './profilePermissionsConstants';

/**
 * @description Method to determine if the copy permissions button should be shown.
 * @param {Object} licenseGroupConfigurations - licenseGroupConfigurations object with configurations
 * @returns {Boolean} returns true if the copy permissions button should be shown.
 */
const canCopyPermissions = (licenseGroupConfigurations) => {
  if (licenseGroupConfigurations?.configurations?.length > 0) {
    return licenseGroupConfigurations.configurations.every(
      (configuration) => configuration.duplicable === undefined || configuration.duplicable
    );
  }
  return false;
};

/**
 * @description Method to determine if there are enough permissions to display the permissions search.
 * @param {Object} licenseGroupConfigurations - object with configurations
 * @param {int} minimumPermissions - minimum number of permissions
 * @returns {Boolean} returns true if there are enough permissions to display the permissions search.
 */
function canSearchPermissions(
  licenseGroupConfigurations,
  minimumPermissions = MINIMUM_PERMISSIONS_FOR_SEARCH
) {
  if (licenseGroupConfigurations?.configurations?.length > 0) {
    const flatPermissions = licenseGroupConfigurations.configurations.flatMap((configuration) =>
      configuration.sections.flatMap((section) =>
        section.content.flatMap((contentObject) => contentObject)
      )
    );
    return flatPermissions.length >= minimumPermissions;
  }
  return false;
}

/**
 * @description Method to check if a given searchValue represents an active search
 * @param {Object} searchValue - string to match
 * @returns {Object} returns true if searching
 */
function isSearching(searchValue) {
  return searchValue !== undefined && !/^\s*$/.test(searchValue);
}

/**
 * @description Method to filter only the configurations which have the group name matching the searchValue
 * @param {Object} licenseGroupConfigurations - object with configurations
 * @param {Object} searchValue - string to match
 * @returns {Object} array containing filtered configurations
 */
function getFilteredConfigurations(licenseGroupConfigurations, searchValue) {
  if (licenseGroupConfigurations?.configurations?.length > 0) {
    if (!isSearching(searchValue)) {
      return licenseGroupConfigurations.configurations;
    }
    return licenseGroupConfigurations.configurations
      .map((configuration) => {
        const filteredSections = configuration.sections
          .map((section) => {
            const filteredContent = section.content.filter((contentObject) =>
              contentObject.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            return {...section, content: filteredContent};
          })
          .filter((section) => section.content.length > 0);
        return {...configuration, sections: filteredSections};
      })
      .filter((configuration) => configuration.sections.length > 0);
  }
  return [];
}
export {canCopyPermissions, canSearchPermissions, getFilteredConfigurations, isSearching};
