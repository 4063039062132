(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.core.product.org-consumables
   * @description model to hold the list of organization consumable objects
   */
  angular.module('app.core.product.org-consumables').factory('OrgConsumables', getModel);

  /* @ngInject */
  function getModel(
    $q,
    _,
    binkyProductNameLabelFilter,
    FiProductGroupProductList,
    FULFILLABLE_ITEM_CHARGING_MODEL_TYPE,
    FULFILLABLE_ITEM_DELEGATION_TYPE,
    FULFILLABLE_ITEM_TYPE
  ) {
    class OrgConsumables {
      /**
       * @description instantiate an OrgConsumables object for an organization.
       *  This model has an orgId and a list of fiProductGroupProductLists, each one representing
       *  an organization-level consumable. Each fiProductGroupProductList is an aggregation of 1
       *  or more products from the productList.
       *
       * @param {Object} options - options to configure the entity.
       * @param {Object} options.orgId - id of organization.
       * @param {Object} options.productList - the productList for this organization.
       */
      constructor(options) {
        _.assignIn(this, _.pick(options, ['orgId', 'productList']));
        this.fiProductGroupProductLists = [];
      }

      /**
       * @description Method to find an fiProductGroupProductList by the FI code.
       *
       * @param {String} fiCode - the fulfillable item code
       * @returns {Object} fiProductGroupProductList. If found, returns the object, else returns undefined.
       */
      getByFiCode(fiCode) {
        return _.find(this.fiProductGroupProductLists, ['id', fiCode]);
      }

      /**
       * @description The individual org consumable products will be filtered out of 'products'. In
       * addition to the user's products, any auto-assigned products will be added. The
       * auto-assigned products are the aggregated org consumable productGroupProductList items in
       * this model and any org delegatable products owned by the organization .
       *
       * @param {Array} products - array of product items to display. An item may be undefined, and
       * if so, should be sorted to the end of the list rather than being removed.
       * @returns {Array} products|productGroupProductList - sorted array of Products and FiProductGroupProductList
       */
      getProductDisplayList(products) {
        return _(products)
          .filter(
            (product) => !_.result(product, 'fulfillableItemList.hasOrgOnDemandConsumable', false)
          )
          .concat(getAutoAssignedProducts.apply(this))
          .sortBy((product) => _.get(product, 'iconTitle') || binkyProductNameLabelFilter(product))
          .value();

        // Add in org consumables and org delegatable products.
        function getAutoAssignedProducts() {
          return _(this.productList.items)
            .filter((product) => _.result(product, 'fulfillableItemList.hasOrgDelegatable', false))
            .concat(this.fiProductGroupProductLists)
            .value();
        }
      }

      /**
       * @description Method to determine if this organization has any organization consumables or
       * organization delegatables.
       *
       * @returns {Boolean} returns true if this organization has any organization consumables or
       * organization delegatables.
       */
      hasAutoAssignedProducts() {
        return (
          !_.isEmpty(this.fiProductGroupProductLists) ||
          _.some(this.productList.items, (product) =>
            product.fulfillableItemList.hasOrgDelegatable()
          )
        );
      }

      /**
       * @description Method to determine if this organization has any organization consumables.
       *
       * @returns {Boolean} returns true if this organization has any organization consumables.
       */
      hasConsumables() {
        return !_.isEmpty(this.fiProductGroupProductLists);
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return this.orgId;
      }

      /**
       * @description When the organization's productList refreshes this should be called to rebuild
       *  the fiProductGroupProductLists based on the current productList.
       *
       * @returns {Promise} resolves when the model is refreshed, else rejects w/error msg
       */
      refresh() {
        this.$resolved = false;

        this.$promise = $q((resolve, reject) => {
          this.productList.$promise
            .then((productList) => {
              this.fiProductGroupProductLists = getFiProductGroupProductLists(productList);
              resolve();
            })
            .catch(reject)
            .finally(() => {
              this.$resolved = true;
            });
        });

        return this.$promise;
      }

      /**
       * @description Method for OrganizationManager to fetch the OrgConsumables singleton.
       *
       * @param {Object} options - options to configure the entity.
       * @param {Object} options.orgId - id of org of which products should be fetched.
       * @param {Object} options.productList - the productList for this organization.
       *
       * @returns {OrgConsumables} the constructed OrgConsumable.
       */
      static get(options) {
        const model = new OrgConsumables(options);
        model.refresh();
        return model;
      }
    }

    return OrgConsumables;

    //////////////

    // Returns an array which may be empty.
    function getFiProductGroupProductLists(productList) {
      // Find all products which have an organization-level FI consumable.
      const products = _.filter(productList.items, (product) =>
        product.fulfillableItemList.hasOrgOnDemandConsumable()
      );

      // Group products with org consumables by their FI code.
      // Allow for there to be more than one org consumable per "product".
      const groupedByFiCode = {};
      _.forEach(products, (product) => {
        const consumables = getOrgOnDemandConsumables(product.fulfillableItemList);
        _.forEach(consumables, (item) => {
          if (!groupedByFiCode[item.code]) {
            groupedByFiCode[item.code] = [];
          }
          groupedByFiCode[item.code].push(product);
        });
      });

      // Create a FiProductGroupProductList object for each FI code.
      return _.map(
        groupedByFiCode,
        (items, fiCode) =>
          new FiProductGroupProductList({
            fiCode,
            items,
          })
      );
    }

    //////////////

    // Ultimately move to fulfillableItemList service.
    function getOrgOnDemandConsumables(fulfillableItemList) {
      return _.filter(
        fulfillableItemList.items,
        (item) =>
          item.delegationType === FULFILLABLE_ITEM_DELEGATION_TYPE.ORGANIZATION &&
          item.fulfillableItemType === FULFILLABLE_ITEM_TYPE.QUOTA &&
          _.get(item, 'chargingModel.model') === FULFILLABLE_ITEM_CHARGING_MODEL_TYPE.ON_DEMAND
      );
    }
  }
})();
