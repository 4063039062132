(function () {
  angular.module('app.widgets.terms-and-conditions', [
    'app.core.auth',
    'app.core.organizations',
    'app.core.organizations.organization-manager',
    'app.core.user',
    'app.widgets.common.image-banner',
    'binky.core.lodash',
    'binky.widgets.common.member.member-name',
    'binky.widgets.common.toast',
    'ngSanitize',
    'pascalprecht.translate',
  ]);
})();
