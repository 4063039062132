const CHANGE_RESELLER_ERROR_CODE = {
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID',
};

const CHANGE_RESELLER_ERROR_MESSAGE_KEY = {
  EXPIRED: 'expired',
  GENERIC: 'generic',
  INVALID: 'invalid',
};

export {CHANGE_RESELLER_ERROR_CODE, CHANGE_RESELLER_ERROR_MESSAGE_KEY};
