import binky, {eventBus} from '@admin-tribe/binky';
import {Button, View} from '@adobe/react-spectrum';
import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';
import {ContentContext} from '@pandora/react-content-provider';
import {
  RequestExpertSessionTeamModal,
  RequestExpertSessionTeamModalContentModel,
} from '@pandora/react-expert-session';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {
  EXPERT_SESSION_TEAM_TIMEFRAME,
  EXPERT_SESSION_TEAM_TOPICS,
  MESSAGE,
} from '../../support.constants';

const {organizationStore} = rootStore;
const {hasOnlyTrialContracts} = binky.services.contract.ContractListUtils;

const SummaryRequestExpertSessionButton = ({variant = 'primary'}) => {
  const intl = useIntl();
  const {locale} = useContext(ContentContext);
  const buttonLabel = intl.formatMessage({
    id: 'support.expertSession.buttons.requestExpertSession',
  });
  const [showModal, setShowModal] = useState(false);
  const hasPaidSubscription = !hasOnlyTrialContracts(organizationStore.contractList);

  const ctaHandler = (value) => {
    eventBus.emit(MESSAGE.OPEN.TEAM_EXPERT_SESSION_BANNER, {
      componentBindings: null,
      componentName: 'binky-page-banner',
      version: 2,
    });
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- need window
    window.open(
      `https://www.adobe.com/go/${value.topic}${value.timeframe}_${SPECTRUM_LOCALE_TO_URL_SUFFIX[locale]}`,
      '_blank'
    );
    setShowModal(false);
  };

  const content = RequestExpertSessionTeamModalContentModel.createEntry({
    description: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.description',
    }),
    descriptionAnswer: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.descriptionAnswer',
    }),
    descriptionAnswerForPaidSubscription: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.descriptionAnswerForPaidSubscription',
    }),
    descriptionQuestion: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.descriptionQuestion',
    }),
    goUrlText: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.goUrlText',
    }),
    header: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.introSection.header',
    }),
    modalDialogContent: [
      // Do not change order of array items
      {
        cancelLabel: intl.formatMessage({
          id: 'support.requestExpertSessionTeamModal.cancel',
        }),
        ctaLabel: intl.formatMessage({
          id: 'support.requestExpertSessionTeamModal.ctaLabel',
        }),
      },
      {
        // require to hide submit/cta button
        cancelLabel: intl.formatMessage({
          id: 'support.requestExpertSessionTeamModal.cancel',
        }),
        ctaLabel: '',
      },
    ],
    sessionTimeframe: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.sessionTimeframe',
    }),
    sessionTimeframeLabel: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.sessionTimeframeLabel',
    }),
    sessionTopicHeading: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.sessionTopicHeading',
    }),
    sessionTopicLabel: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.sessionTopicLabel',
    }),
    timeframeLabel: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.timeframeLabel',
    }),
    topicLabel: intl.formatMessage({
      id: 'support.requestExpertSessionTeamModal.topicLabel',
    }),
  });

  return (
    <View elementType="span" paddingX="size-65">
      <Button
        aria-label={buttonLabel}
        data-testid="request-expert-session-button"
        marginBottom="size-65"
        onPress={() => {
          setShowModal(true);
        }}
        variant={variant}
      >
        {buttonLabel}
      </Button>
      {showModal && (
        <ModalContainer data-testid="request-expert-session-team-modal">
          <RequestExpertSessionTeamModal
            content={content}
            ctaHandler={ctaHandler}
            hasPaidSubscription={hasPaidSubscription}
            onClose={() => setShowModal(false)}
            sessionTopics={EXPERT_SESSION_TEAM_TOPICS.map((session) => ({
              name: intl.formatMessage({id: session.name}),
              url: session.url,
            }))}
            timeframes={EXPERT_SESSION_TEAM_TIMEFRAME.map((timeframe) => ({
              name: intl.formatMessage({id: timeframe.name}),
              value: timeframe.value,
            }))}
          />
        </ModalContainer>
      )}
    </View>
  );
};

SummaryRequestExpertSessionButton.propTypes = {
  /**
   * Variant for the button. Default is primary
   */
  variant: PropTypes.oneOf(['cta', 'primary', 'secondary']),
};

export default SummaryRequestExpertSessionButton;
