import {ActionButton, Text, View} from '@adobe/react-spectrum';
import BackIcon from '@react/react-spectrum/Icon/Back';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

// ShowSelectedPluginsTopSection contains the Back button and Selected plugins string
// displayed above Plugin cards
const ShowSelectedPluginsTopSection = ({onPress, selectedPluginsLength}) => {
  const intl = useIntl();

  return (
    <View>
      <ActionButton marginBottom="size-550" marginTop="size-300" onPress={onPress} width="auto">
        <View paddingX="size-85">
          <BackIcon size="S" />
        </View>
        <Text>{intl.formatMessage({id: 'common.modal.buttons.back'})}</Text>
      </ActionButton>
      <div role="status">
        {intl.formatMessage(
          {id: 'packages.createPackage.choosePlugins.selectedLabel'},
          {
            selectedPluginsLength,
          }
        )}
      </div>
    </View>
  );
};

ShowSelectedPluginsTopSection.propTypes = {
  /**
   * Function to call on pressing back button
   */
  onPress: PropTypes.func.isRequired,
  /**
   * Size of selected plugins
   */
  selectedPluginsLength: PropTypes.number.isRequired,
};
export default ShowSelectedPluginsTopSection;
