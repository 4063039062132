(function () {
  /**
   * @ngdoc module
   * @name app.shell.navigation.links
   * @description module that defines links
   */
  angular.module('app.shell.navigation.links', [
    'binky.core.lodash',
    'binky.shell.navigation.state-manager',
  ]);
})();
