(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name OrganizationProductMigration
   * @description Model for a OrganizationProductMigration object
   */
  angular
    .module('app.core.contract.migrations')
    .factory('OrganizationProductMigration', getOrganizationProductMigrationModel);

  /* @ngInject */
  function getOrganizationProductMigrationModel(_, Product) {
    class OrganizationProductMigration {
      /**
       * @class
       * @description Creates a new OrganizationContractMigrationContext instance
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Number} options.quantity the total licenses of the product
       * @param {Product} options.source the source product - before the migration
       * @param {Product} options.target the target product - after the migration
       */
      constructor(options) {
        initModel(this, options);
      }

      getTotalLicenses() {
        return this.quantity;
      }

      static apiResponseTransformer(organizationProductMigration) {
        return new OrganizationProductMigration(organizationProductMigration);
      }
    }

    return OrganizationProductMigration;

    ////////

    function initModel(model, options) {
      _.assignIn(model, {
        quantity: options.quantity,
        source: new Product(options.source),
        target: new Product(options.target),
      });
    }
  }
})();
