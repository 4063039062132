import {OrganizationUser, UserGroup} from '@admin-tribe/binky';
import {getMemberDisplayName} from '@admin-tribe/binky-ui';
import {Content, ContextualHelp, Flex, Heading, Item, Picker, Text} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import LegacyProductRole from 'common/entities/legacy-product-role/LegacyProductRole';

import LegacyProductRolePicker from '../legacy-product-role-picker/LegacyProductRolePicker';

// The picker to use in tables for product roles for either legacy product roles (Target) or
// license group member roles (Adobe Sign).
const TableProductRolePicker = ({
  isDisabled = false,
  member,
  onRoleChange,
  roles,
  ...styleProps
}) => {
  const intl = useIntl();
  const tooltip = member?.productRoleTooltip;

  // The legacy product role picker is controlled.
  if (roles?.length && roles[0] instanceof LegacyProductRole) {
    return (
      <LegacyProductRolePicker
        isDisabled={isDisabled}
        member={member}
        onRoleChange={onRoleChange}
        roles={roles}
        {...styleProps}
      />
    );
  }

  // If member is a user group, don't show the switcher.
  if (member?.getType().isUserGroup()) {
    return <Text>{EN_DASH}</Text>;
  }

  // This picker is uncontrolled.
  // This means that if a role is selected and the API call to update the role ultimately fails
  // the value show in the Picker will be out of sync with the actual role.
  return (
    <Flex alignItems="center" data-testid="table-product-role-picker" {...styleProps}>
      <Picker
        aria-label={intl.formatMessage(
          {id: 'products.tableProductRolePicker.ariaLabel'},
          {
            displayName: getMemberDisplayName(intl, member, {
              fallbackToEmail: true,
            }),
          }
        )}
        defaultSelectedKey={member?.productRole?.id || roles.find((role) => role.preferred)?.id}
        isDisabled={isDisabled}
        isQuiet
        items={roles}
        labelPosition="side"
        onSelectionChange={(selectedId) => onRoleChange({member, selectedId})}
      >
        {(item) => <Item>{item.name}</Item>}
      </Picker>
      {tooltip && (
        <ContextualHelp marginStart="size-50" variant="info">
          <Heading>
            {intl.formatMessage({id: 'products.tableProductRolePicker.contextualHelp.heading'})}
          </Heading>
          <Content>
            <Flex direction="column" gap="size-100" UNSAFE_style={{whiteSpace: 'pre-wrap'}}>
              {tooltip.description && <Text>{tooltip.description}</Text>}
              {tooltip.learnMoreHref && (
                <a href={tooltip.learnMoreHref} rel="noreferrer" target="_blank">
                  {intl.formatMessage({
                    id: 'products.tableProductRolePicker.contextualHelp.learnMore',
                  })}
                </a>
              )}
            </Flex>
          </Content>
        </ContextualHelp>
      )}
    </Flex>
  );
};

TableProductRolePicker.propTypes = {
  /**
   * Whether the picker should show as disabled. The default is false.
   */
  isDisabled: PropTypes.bool,
  /**
   * The user or user group.
   * If a user and the roles are items returned by MemberConfigurationRoles, the user may have
   * a 'productRoleTooltip' property which will include at least one of 'description' or 'learnMoreHref'.
   * Note: Because this component is initially created without a member, and then the component is cloned
   * and the member is injected, don't require member to avoid console errors.
   */
  member: PropTypes.oneOfType([
    PropTypes.instanceOf(OrganizationUser),
    PropTypes.instanceOf(UserGroup),
  ]),
  /**
   * Callback when the selection changes. There is one param which is an object
   * which has member and selectedId properties.
   * member may be null if this is being used in multi-select case.
   * selectedId is the id of the selected role.
   */
  onRoleChange: PropTypes.func.isRequired,
  /**
   * The roles, sorted by name.
   * These are either LegacyProductRole (for Target) or
   * the items returned by MemberConfigurationRoles (for example Adobe Sign).
   */
  roles: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        // item returned by MemberConfigurationRoles
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        preferred: PropTypes.bool.isRequired,
      })
    ),
    PropTypes.arrayOf(PropTypes.instanceOf(LegacyProductRole)),
  ]).isRequired,
};

export default TableProductRolePicker;
