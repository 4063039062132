import {useStore} from '@admin-tribe/binky-ui';
import {Button, DialogTrigger} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

import PackagesServerStore from 'features/packages/stores/PackagesServerStore';

import CreateServerModal from './CreateServerModal';
import {CreateServerModalContextProvider} from './CreateServerModalContext';

// Create Server Button is the trigger point for the Create server flow
const CreateServerButton = observer(({buttonLabel, isDisabled, variant = 'cta'}) => {
  const packagesServerStore = useStore(() => new PackagesServerStore());

  return (
    <DialogTrigger>
      <Button data-testid="create-server-button" isDisabled={isDisabled} variant={variant}>
        {buttonLabel}
      </Button>

      {(close) => (
        <CreateServerModalContextProvider packagesServerStore={packagesServerStore}>
          <CreateServerModal data-testid="create-server-modal" onCancel={close} />
        </CreateServerModalContextProvider>
      )}
    </DialogTrigger>
  );
});

CreateServerButton.propTypes = {
  /**
   * Label that should appear on the button
   */
  buttonLabel: PropTypes.string.isRequired,
  /**
   * Indicates if the the button should be disabled. Defaults to false in React Spectrum.
   */
  isDisabled: PropTypes.bool,
  /**
   * Visual style of the button. Defaults to cta in React Spectrum.
   */
  variant: PropTypes.string,
};

export default CreateServerButton;
