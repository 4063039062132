import {AlertDialog, Content, Form, TextArea} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {parseDomains, validateDomains} from 'common/utils/domainNameUtils';

import styles from './AllowlistedDomainAddModal.pcss';

const AllowlistedDomainAddModal = ({onCTA}) => {
  const intl = useIntl();
  const [domainNames, setDomainNames] = useState([]);
  const [areDomainNamesValid, setAreDomainNamesValid] = useState(true);

  return (
    <AlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      isPrimaryActionDisabled={domainNames.length === 0 || !areDomainNamesValid}
      onPrimaryAction={() => {
        onCTA(domainNames);
      }}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.assetSettings.allowlistedDomainAddModal.addButton',
      })}
      title={intl.formatMessage({
        id: 'settings.assetSettings.allowlistedDomainAddModal.heading',
      })}
      variant="confirmation"
    >
      <Content>
        <Form>
          <TextArea
            description={intl.formatMessage({
              id: `settings.assetSettings.allowlistedDomainAddModal.textArea.description`,
            })}
            errorMessage={
              !areDomainNamesValid &&
              intl.formatMessage({
                id: `settings.assetSettings.allowlistedDomainAddModal.errorMessage`,
              })
            }
            label={intl.formatMessage({
              id: `settings.assetSettings.allowlistedDomainAddModal.textArea.label`,
            })}
            onChange={(value) => {
              const domains = parseDomains(value);
              setDomainNames(domains);
              setAreDomainNamesValid(validateDomains(domains));
            }}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to disable resizing
            UNSAFE_className={styles.textarea}
            validationState={!areDomainNamesValid && 'invalid'}
          />
        </Form>
      </Content>
    </AlertDialog>
  );
};

AllowlistedDomainAddModal.propTypes = {
  /**
   * Callback to invoke when domains are submitted.
   */
  onCTA: PropTypes.func.isRequired,
};

export default AllowlistedDomainAddModal;
