/**
 * @description Method to compare string versions.
 * @param {String}version1 - 1st version to compare
 * @param {String}version2 - 2nd version to compare
 * @returns {Boolean} true if version 1>= version2.
 */
function stringVersionCompare(version1, version2) {
  const version1Parts = version1 ? version1.split('.') : [];
  const version2Parts = version2 ? version2.split('.') : [];
  const loopCounter =
    version1Parts.length > version2Parts.length ? version1Parts.length : version2Parts.length;
  for (let i = 0; i < loopCounter; i++) {
    const version1Part = Number(version1Parts[i]) || 0;
    const version2Part = Number(version2Parts[i]) || 0;
    if (version1Part < version2Part) {
      return false;
    }
    if (version1Part > version2Part) {
      return true;
    }
  }
  return true;
}

/**
 * @description Method to calculate remaining days to download package.
 * @param {object}customizerProgress - 1st version to compare
 * @returns {number}
 */
function getRemainingDays(customizerProgress) {
  return Math.round(
    Number.parseInt(customizerProgress.onCallExpireTimeRemaining, 10) / (24 * 60 * 60 * 1000)
  );
}

export {stringVersionCompare, getRemainingDays};
