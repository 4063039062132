import {getDate} from '@admin-tribe/binky';
import {getProductDisplayName} from '@admin-tribe/binky-ui';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {PRODUCT_TYPES_FOR_CONTRACT_MIGRATION} from 'features/users/components/offer-switch-migration/OfferSwitchMigrationConstants';
import OfferSwitchMigrationModal from 'features/users/components/offer-switch-migration/OfferSwitchMigrationModal';
import {
  dispatchHvaClickAnalytics,
  dispatchHvaLoadedAnalytics,
} from 'features/users/services/offer-switch-migration/offerSwitchMigrationAnalyticsUtils';
import {
  getOfferSwitchMigration,
  getProductsByContractMigrationList,
} from 'features/users/services/offer-switch-migration/offerSwitchMigrationHelper';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to get offer switch migration
 */
class OfferSwitchMigrationAssignLicensesHva extends HvaCard {
  /**
   * @description Method to fetch OfferSwitchMigrationAssignLicensesHva
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @returns {Promise<OfferSwitchMigrationAssignLicensesHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (!auth.isUserOrgAdmin()) {
      return Promise.reject();
    }

    const {contract, contractMigrationList, isEligible} = await getOfferSwitchMigration();

    if (isEligible) {
      return [generateOfferSwitchHVA({contract, contractMigrationList, intl})];
    }
    return Promise.reject();
  }

  /**
   * @description Method to create an OfferSwitchMigrationAssignLicensesHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @param {String} obj.productName - Name of the target product
   *
   * @param {String} obj.renewalEndDate - renewal end date
   *
   * @param {Product[]} obj.sourceProducts - source products
   *
   * @param {Product[]} obj.targetProducts - target products
   *
   * @param {Number} obj.totalAvailableLicenses - total number of available licenses
   *
   * @param {String} obj.translationSuffix - suffix of translation
   *
   * @returns {OfferSwitchMigrationAssignLicensesHva} the fetched instance
   */
  constructor({
    intl,
    productName,
    renewalEndDate,
    sourceProducts,
    targetProducts,
    totalAvailableLicenses,
    translationSuffix,
  }) {
    const namespace = 'overview.highValueActionArea.setUpTasks.offerSwitch.messageFormat';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}, {totalAvailableLicenses}),
      description: intl.formatMessage({id: `${namespace}.${translationSuffix}.description`}),
      goUrl: 'aac_offer_migration',
      header: intl.formatMessage(
        {id: `${namespace}.${translationSuffix}.header`},
        {productName, totalAvailableLicenses}
      ),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/assign-licenses.svg',
      },
      id: 'hva-offer-switch-migration-assign-licenses',
      intlValues: {
        productName,
        renewalEndDate,
        totalAvailableLicenses,
      },
      isDismissible: false,
      modalConfig: {
        closeProp: 'onClosed',
        Component: OfferSwitchMigrationModal,
        props: {
          callBeforeOpeningModal: () =>
            dispatchHvaClickAnalytics({
              sourceName: 'AssignLicenses',
              sourceProducts,
              targetProducts,
            }),
          contractRenewalEndDate: renewalEndDate,
          includeIsOpen: true,
          onOfferMigrationCanceled: () =>
            dispatchHvaLoadedAnalytics(sourceProducts, targetProducts),
        },
      },
      priorityOrder: HVA_ORDER.OFFER_SWITCH_MIGRATION_ASSIGN_LICENSES,
      sectionTitle: intl.formatMessage(
        {id: `${namespace}.${translationSuffix}.sectionTitle`},
        {productName, totalAvailableLicenses}
      ),
      type: HVA_TYPE.SET_UP,
    });
  }
}

/**
 *
 * @description This method creates an OfferSwitchMigrationAssignLicensesHva instance
 *
 * @param {Object} obj
 *
 * @param {Contract} obj.contract - contract
 *
 * @param {OrganizationContractMigrationList} obj.contractMigrationList - list of contract migrations
 *
 * @param {Object} obj.intl - react-intl
 *
 * @returns {OfferSwitchMigrationAssignLicensesHva} an OfferSwitchMigrationAssignLicensesHva instance
 */
function generateOfferSwitchHVA({contract, contractMigrationList, intl}) {
  let renewalEndDate = getDate(contract.getRenewalWindowEndDate());
  const options = {day: '2-digit', month: 'long', year: 'numeric'};
  renewalEndDate = renewalEndDate.toLocaleDateString(intl.locale, options);
  const targetProducts = getTrimmedProducts(
    intl,
    getProductsByContractMigrationList({
      contractMigrationList,
      productList: rootStore.organizationStore.productList,
      type: PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.TARGET,
    })
  );

  const productName = targetProducts[0].displayName; // only used for 1 target product
  const translationSuffix = targetProducts.length > 1 ? 'withoutProductName' : 'withProductName';
  const totalAvailableLicenses = targetProducts.reduce(
    (total, product) => total + product.count,
    0
  );

  const sourceProducts = getTrimmedProducts(
    intl,
    getProductsByContractMigrationList({
      contractMigrationList,
      productList: rootStore.organizationStore.productList,
      type: PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.SOURCE,
    })
  );

  dispatchHvaLoadedAnalytics(sourceProducts, targetProducts);

  // Opting for separating single and multiple products because
  // product name is shown only for single target product (simpler for translation)
  return new OfferSwitchMigrationAssignLicensesHva({
    intl,
    productName,
    renewalEndDate,
    sourceProducts,
    targetProducts,
    totalAvailableLicenses,
    translationSuffix,
  });
}

/**
 *
 * @description: This method trims attributes of products so they only include count, displayName, and id attributes
 *
 * @param {Object} intl - react-intl
 *
 * @param {Product[]} targetProducts - list of target products
 *
 * @returns {Product[]} list of trimmed source/target products
 */
function getTrimmedProducts(intl, products) {
  const productsWithoutInfiniteDelagatedQuantity = products.filter(
    (product) =>
      product.delegatedQuantity !== Number.POSITIVE_INFINITY &&
      product.delegatedQuantity !== Number.NEGATIVE_INFINITY
  );
  return productsWithoutInfiniteDelagatedQuantity.map((product) => ({
    count: product.getAssignableLicenseCount() - (product.delegatedQuantity || 0),
    displayName: getProductDisplayName(intl, product),
    id: product.offerId,
  }));
}

export default OfferSwitchMigrationAssignLicensesHva;
