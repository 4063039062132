import {feature} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {
  ALL_PACKAGES_CONSTANTS,
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
  SERVERS_CONSTANTS,
} from 'features/packages/packagesConstants';
import PackagesAdobeProductsUiService from 'features/packages/services/PackagesAdobeProductsUiService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

function setPlatformStr(platform, bits) {
  let platformStr = '';
  platformStr = platform;
  if (platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM) {
    platformStr += bits;
  }

  return platformStr;
}

/**
 * @description Method to set template attributes used in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Object} update attributes
 */
// eslint-disable-next-line complexity -- set package attributes
const setTemplateAttributes = (attributes) => {
  const attrs = attributes;
  attrs.packageName = attributes.template.name;
  attrs.platform = attributes.template.platform;
  attrs.bits = attributes.template.bits;
  attrs.isNotELearningTemplate = !(
    attributes.template.isCaptivateTemplate ||
    attributes.template.isPremierElementsTemplate ||
    attributes.template.isPhotoshopElementsTemplate ||
    attributes.template.isPresenterTemplate ||
    attributes.template.isRoboHelpTemplate ||
    attributes.template.isFramemakerTemplate ||
    attributes.template.isTechCommSuiteTemplate ||
    attributes.template.isRoboHelpServerTemplate ||
    (feature.isDisabled('temp_packages_acrobat_only_flow') &&
      attributes.template.isAcrobatOnlyTemplate &&
      !PackagesEntitlements.hasCcEntitlement)
  );
  attrs.platformStr = setPlatformStr(attributes.platform, attributes.bits);
  attrs.language = attributes.template.configurations?.locale;
  attrs.accSupressed =
    attributes.template.shouldNotAddCCD ||
    !(PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasSubstanceNamedEntitlement);
  attrs.osLangDetection = attributes.template.configurations?.osLangDetection;
  attrs.appPanelEnabled = attributes.template.configurations?.appPanelEnabled;
  attrs.accAdminPrivileged = attributes.template.configurations?.accAdminPrivileged;
  attrs.disableAppTrial = attributes.template.configurations?.disableAppsTrial;
  attrs.accDisableAutoAppUpdate = attributes.template.configurations?.accDisableAutoAppUpdate;
  attrs.coreSyncSupress = !attributes.template.configurations?.filesPanelEnabled;
  attrs.rumEnabled = attributes.template.configurations?.rumEnabled;
  attrs.extensionsEnabled = !!attributes.template.configurations?.enableExtenstions;
  attrs.enableAalExtension = !!attributes.template.configurations?.enableExtensionsAal;
  attrs.ausstXmlIncluded = !!attributes.template.configurations?.ausstOverride;
  if (attributes.template.nativeProductsList) {
    attrs.includedApplications = attributes.template.nativeProductsList;
  } else {
    attrs.includedApplications =
      attributes.template.getPackagedAppsForUi === undefined
        ? []
        : attributes.template.getPackagedAppsForUi();
  }
  attrs.userInstallDirectoryIncluded = !!attributes.template.configurations?.userInstallDirectory;
  attrs.enableBetaAppDownload = !!attributes.template.configurations?.betaAppDownloadEnabled;
  attrs.browserBasedAuthEnabled = !!attributes.template.configurations?.browserBasedAuthEnabled;
  attrs.selfServePluginsEnabled = !!attributes.template.configurations?.selfServePluginsEnabled;
  attrs.includedUpdates = [];
  attrs.ccdVersionInfoAvailable = true;
  return attrs;
};

/**
 * @description Method to set package attributes used in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Object} update attributes
 */

// eslint-disable-next-line max-statements -- set package attributes
const setPackageAttributes = (attributes) => {
  const attrs = attributes;
  attrs.packageName = attributes.package.name;
  attrs.createdBy = attributes.package.userName;
  attrs.packageBundleId = attributes.package.packageBundleId;
  attrs.npdId = attributes.package.npdId;
  attrs.platform = attributes.package.platform;
  attrs.bits = attributes.package.bits;
  attrs.includedApplications = attributes.package.packagedProducts;
  attrs.platformStr = setPlatformStr(attributes.platform, attributes.bits);
  attrs.language = attributes.package.language;
  attrs.accSupressed = attributes.package.configurations.accSuppress;
  attrs.osLangDetection = attributes.package.configurations.OSLangDetection;
  attrs.appPanelEnabled = attributes.package.configurations.appsPanelVisible;
  attrs.disableAppTrial = attributes.package.configurations.disableAppsTrial;
  attrs.accAdminPrivileged = attributes.package.configurations.accAdminPrivileged;
  attrs.ccdVersionInfoAvailable = attributes.package.ccdVersionInfoAvailable;
  attrs.accDisableAutoAppUpdate = attributes.package.configurations.accDisableAutoAppUpdate;
  attrs.rumEnabled = attributes.package.configurations.rumEnabled;
  attrs.updateEnabled = attributes.package.configurations.updateEnabled;
  attrs.ausstXmlIncluded = !!attributes.package.configurations.ausstOverride;
  attrs.userInstallDirectoryIncluded = !!attributes.package.configurations.userInstallDirectory;
  attrs.coreSyncSupress = !attributes.package.configurations.filesPanelEnabled;
  attrs.includedApplications = attributes.package.packagedProducts;
  attrs.includedUpdates = attributes.package.packagedUpdates;
  attrs.extensionsEnabled = !!attributes.package.configurations.enableExtenstions;
  attrs.enableAalExtension = !!attributes.package.configurations.enableExtensionsAal;
  attrs.productAddOnMap = attributes.package.productAddOnMap;
  if (
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.NUELL
  ) {
    attrs.enableBetaAppDownload = !!attributes.package.configurations.betaAppDownloadEnabled;
  }
  attrs.browserBasedAuthEnabled = !!attributes.package.configurations.browserBasedAuthEnabled;
  attrs.selfServePluginsEnabled = !!attributes.package.configurations.selfServePluginsEnabled;
  attrs.maxDaysPackageLive = rootStore.packagesUiConstantsStore.maxDaysPackageLive;
  attrs.aalInformation = attributes.package.aalInformation;
  if (attributes.aalInformation && !attributes.package.isAALUpToDate) {
    attrs.latestAALInformation = rootStore.packagesUiConstantsStore.getLatestAALInformation(
      attributes.platform,
      attributes.bits
    );
  }
  attrs.showPluginsSection =
    attributes.aalInformation ||
    (attributes.package && attributes.package.pluginsInfo.pluginVersionIdList.length > 0);

  if (feature.isEnabled('temp_packages_flat_templates')) {
    attrs.flatPackageEnabled = !!attributes.package.flatPackageEnabled;
  }
  return attrs;
};

/**
 * @description Method to set attributes used in summary drawer
 * @param {Object} summaryObj - package/template object
 * @param {boolean} isTemplate - boolean to tell if summaryObj is a template object
 * @param {Object} intl - Result of useIntl hook
 * @returns {Object} attributes
 */
const setAttributes = (summaryObj, isTemplate, intl) => {
  let attributes = {
    package: {},
    template: {},
  };
  attributes.package = isTemplate ? undefined : summaryObj;
  attributes.template = isTemplate ? summaryObj : undefined;
  attributes.isTemplate = isTemplate;
  attributes.showDaysLeft = isTemplate
    ? false
    : summaryObj.downloadState === ALL_PACKAGES_CONSTANTS.ACTION_DOWNLOAD;
  attributes.showFailure = isTemplate
    ? false
    : summaryObj.statusState === ALL_PACKAGES_CONSTANTS.STATE_ERROR;
  attributes.showDownload = isTemplate
    ? true
    : summaryObj.downloadState === ALL_PACKAGES_CONSTANTS.ACTION_DOWNLOAD;
  attributes.showRetry = isTemplate
    ? false
    : summaryObj.downloadState === ALL_PACKAGES_CONSTANTS.ACTION_RETRY;
  attributes.showCustomize = isTemplate;
  attributes.showCcpCreatedPackages =
    feature.isEnabled('packages-ccp-created') && feature.isEnabled('packages-ccp-created-allowed');
  attributes.showUpdatePackage = showUpdatePackage(attributes);
  attributes.showMissingPluginsTooltip = showMissingPluginsTooltip(attributes);
  attributes.showPluginsErrorTooltip = showPluginsErrorTooltip(attributes);
  attributes.templateType = summaryObj.templateType;
  if (isTemplate) {
    attributes = setTemplateAttributes(attributes);
  } else {
    attributes = setPackageAttributes(attributes);
  }
  updatePlatformForProducts(attributes, intl);

  return attributes;
};

/**
 * @description Implementation of _.countBy function
 */
function counter(str) {
  return [...str].reduce((total, letter) => {
    if (!total[letter]) {
      return {
        ...total,
        [letter]: 1,
      };
    }
    return {
      ...total,
      [letter]: total[letter] + 1,
    };
  }, {});
}

/**
 * @description In case we have update available for both platform STIs , we will have to show platform information.
 * Else, we will not show platform.
 * @param {Object} attributes - Summary drawer attributes
 * @param {Object} intl - Intl object
 */
function updatePlatformForProducts(attributes, intl) {
  // allPackagedProducts - all products including STIs in package
  // includedApplications - all application for which update is available
  if (!attributes.package) {
    return;
  }

  const packagedSTIsSapCodes = [];
  attributes.package.allPackagedProducts.forEach((product) => {
    if (product && product.isSTI) {
      product.showPlatformOnUI = false;

      product.uiPlatform = '';
      packagedSTIsSapCodes.push(product.sapCode);
    }
  });
  const sapCodeCountMap = counter(packagedSTIsSapCodes);

  attributes.includedApplications.forEach((product) => {
    if (sapCodeCountMap[product.sapCode] > 1) {
      product.showPlatformOnUI = true;

      product.uiPlatform =
        product.platform === 'macarm64'
          ? intl.formatMessage({id: 'packages.summary.includedApplications.armPlatform'})
          : intl.formatMessage({id: 'packages.summary.includedApplications.intelPlatform'});
    }
  });
}

/**
 * @description Check if we need to show update package button
 * @param {Object} attributes
 * @returns true if we need to show update package button, false otherwise
 */
function showUpdatePackage(attributes) {
  if (
    !attributes.showCcpCreatedPackages &&
    !attributes.isTemplate &&
    (attributes.package.downloadState === ALL_PACKAGES_CONSTANTS.ACTION_DOWNLOAD ||
      attributes.package.downloadState === ALL_PACKAGES_CONSTANTS.ACTION_EXPIRED) &&
    !attributes.package.isUpToDate
  ) {
    // check if all notUpToDate products have their update available to be packaged
    // excluding KCCC and invisible apps
    const areAllProductsAvailableForUpdate = !attributes.package.allPackagedProducts.some(
      (product) =>
        (product.sapCode !== 'KCCC' || product.visible) &&
        !PackagesAdobeProductsUiService.getAdobeProductBySapCodeVersionPlatform(
          product.getLatestProductKey()
        )
    );

    const isActiveServerAvailableIfFrlLanPackage =
      hasActiveServerAvailableIfFrlLanPackage(attributes);

    // check of updates for included plugins
    const arePluginsUpdatableIfApplicable = hasUpdatablePluginsIfApplicable(attributes);

    return (
      areAllProductsAvailableForUpdate &&
      isActiveServerAvailableIfFrlLanPackage &&
      arePluginsUpdatableIfApplicable
    );
  }
  return false;
}

/**
 * @description Check if it is FRL LAN package and it has an active server associated with it
 * @param {Object} attributes - Summary Drawer attributes
 * @returns {Boolean} true if package is not FRL LAN and package has an active server associated with it, false otherwise.
 */
function hasActiveServerAvailableIfFrlLanPackage(attributes) {
  return (
    attributes.package.packageType !== PACKAGE_TYPE_CONSTANTS.FRL_LAN ||
    !attributes.package.frlPackagingInfo ||
    (attributes.package.frlPackagingInfo.server &&
      attributes.package.frlPackagingInfo.server.status === SERVERS_CONSTANTS.STATUS.ACTIVE)
  );
}

/**
 * @description Method to check if we need to show updates for plugins
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} true if we need to show updates for plugins, false otherwise
 */
function hasUpdatablePluginsIfApplicable(attributes) {
  if (!attributes.package.pluginsInfo.isResolved || attributes.package.pluginsInfo.hasError) {
    return false;
  }
  return true;
}

/**
 * @description Method to check if we need to show missing plugins tooltip
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} true if we need to show missing plugins tooltip, false otherwise
 */
function showMissingPluginsTooltip(attributes) {
  return (
    attributes.package &&
    attributes.package.pluginsInfo.numOfMissingPlugins > 0 &&
    !attributes.package.pluginsInfo.hasError
  );
}

/**
 * @description Method to check if we need to show error tooltip in plugins
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} true if we need to show error tooltip in plugins, false otherwise
 */
function showPluginsErrorTooltip(attributes) {
  return attributes.package && attributes.package.pluginsInfo.hasError;
}

export {setAttributes, showUpdatePackage};
