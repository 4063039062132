(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.directories').component('appDirectorySettingsTeacherAccessSection', {
    bindings: {
      directory: '<',
      onHidePageBanner: '&',
      onShowPageBanner: '&',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-settings/directory-teacher-access-section/directory-settings-teacher-access-section.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    _.assign(vm, {
      allowTeacherAccess,
      onTeacherAccessToggle,
      waitPromise: vm.directory.$promise,
    });

    /////////////////

    function allowTeacherAccess() {
      return !vm.directory.isPolicyEnabled('POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE');
    }

    function onTeacherAccessToggle() {
      vm.onHidePageBanner();

      vm.waitPromise = vm.directory
        .togglePolicy('POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE')
        .catch(() => {
          showErrorPageBanner();
        });
    }

    function showErrorPageBanner() {
      const options = {
        variant: 'error',
      };
      vm.onShowPageBanner({options});
    }
  }
})();
