(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.hva.hva-card.assembler').decorator('hvaAssembler', moduleDecorator);

  /* @ngInject */
  function moduleDecorator(
    $delegate,
    $q,
    $translate,
    feature,
    HvaBuildAction,
    HvaCard,
    HVA_ORDER,
    HVA_TYPE,
    organizationAccess,
    SVG_CLASS,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    PageContext,
    OrganizationManager,
    globalModalsManager,
    GLOBAL_MODAL_BINDING_TYPE
  ) {
    // store the original filter
    const oldBuildActions = $delegate.buildActionsForHVA;
    $delegate.buildActionsForHVA = newBuildActions;

    return $delegate;

    function newBuildActions(...args) {
      const result = oldBuildActions.apply($delegate, args);

      result.add(
        new HvaBuildAction({
          accessCallback,
          isStackable: true,
          priorityOrder: HVA_ORDER.ADD_ADMINS,
          type: HVA_TYPE.VALUE_DISCOVERY,
        })
      );

      return result;

      function accessCallback(cache) {
        if (!cache.canViewValueDiscoveryTasksForTeam) {
          cache.canViewValueDiscoveryTasksForTeam =
            organizationAccess.canViewValueDiscoveryTasksForTeam();
        }

        const {canViewValueDiscoveryTasksForTeam} = cache;
        return canViewValueDiscoveryTasksForTeam.then((canView) => {
          if (canView) {
            const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.addAdmins';
            return [
              new HvaCard({
                ctaLabel: $translate.instant(`${namespace}.ctaLabel`),
                description: $translate.instant(`${namespace}.description`),
                dismissOnCTAClick: true,
                header: $translate.instant(`${namespace}.header`),
                id: 'hva-add-admins',
                isDismissible: true,
                onCTAClick: () => {
                  globalModalsManager.open({
                    componentBindings: [
                      {
                        attributeName: 'pageContext',
                        type: GLOBAL_MODAL_BINDING_TYPE.DATA,
                        value: new PageContext({
                          target: PAGE_TARGET.ORGANIZATION,
                          targetId: OrganizationManager.getActiveOrgId(),
                          targetType: PAGE_TARGET_TYPE.ADMIN,
                        }),
                      },
                    ],
                    componentName: 'appAddAdminModal',
                  });
                },
                sectionTitle: $translate.instant(`${namespace}.sectionTitle`),
                svgClass: SVG_CLASS.ADD_ADMINS,
              }),
            ];
          }

          return $q.reject();
        });
      }
    }
  }
})();
