(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:primaryProductToLaunchTranslator
   * @description Translate primaryProduct into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('primaryProductToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, primaryProductDescriptor) {
      const {setProperty} = launchContext;

      setProperty(
        'primaryProduct.productInfo.offerId',
        _.get(primaryProductDescriptor, 'offer_id')
      );

      setProperty('primaryProduct.quantity', _.get(primaryProductDescriptor, 'numberSelected'));

      setProperty(
        'primaryProduct.attributes.sourceOfferLocation',
        _.get(primaryProductDescriptor, 'productBucket')
      );
    }
  }
})();
