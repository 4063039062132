(function () {
  /**
   * @ngdoc module
   * @name app.core.payment-info-editor
   * @description Module which defines Payment Info Editor (PIE) model
   */
  angular.module('app.core.payment-info-editor', [
    'binky.core.lodash',
    'binky.widgets.common.toast',
  ]);
})();
