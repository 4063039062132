import {DATE_FORMATS} from '@admin-tribe/binky-ui';
import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {EN_DASH} from '@pandora/react-table-section';

/**
 * @description A function to return string 'True' / 'False' if value is a boolean. Otherwise, returns the given value
 * @param {Object} intl - react-intl
 * @param {String || Boolean} value - current device details value
 * @param {String} namespace - namespace used for translation
 * @returns {String || Boolean} return 'True' / 'False' if value is a boolean or the given value in other cases
 */
const getValue = ({intl, value, namespace}) => {
  if (typeof value === 'boolean') {
    return intl.formatMessage({id: `${namespace}.boolean.${value}`});
  }
  return value;
};

/**
 * @description A function to return the translated text of the activation status
 * @param {String} activationState - activation state
 * @param {Object} intl - react-intl
 * @param {String} namespace - namespace used for text translation
 * @returns {String} translate status
 */
const getTranslatedStatus = ({activationState, intl, namespace}) => {
  if (activationState === DEVICE_ACTIVATION_STATUS.ACTIVATED) {
    return intl.formatMessage({
      id: `${namespace}.activated`,
    });
  }
  return intl.formatMessage({
    id: `${namespace}.blocked`,
  });
};

/**
 * @description A function to translate the status and date of status update
 * @param {String} activationState - activation state
 * @param {String} eventDate - date of status update
 * @param {Object} intl - react-intl
 * @param {String} namespace - namespace used for text translation
 * @returns {String} translated status with date of status update
 */
const getTranslatedStatusWithDate = ({activationState, eventDate, intl, namespace}) => {
  let statusWithDate;
  if (eventDate) {
    const date = intl.formatDate(new Date(eventDate).getTime(), DATE_FORMATS.default);
    const status = getTranslatedStatus({
      activationState,
      intl,
      namespace: `${namespace}.activationSection.status`,
    });
    statusWithDate = intl.formatMessage({id: `${namespace}.statusUpdateMessage`}, {date, status});
  }
  return statusWithDate;
};

/**
 * @description A function to translate date of when the device was last updated
 * @param {String} eventDateInMilliseconds - date of when the device was last updated
 * @param {Object} intl - react-intl
 * @param {String} namespace - namespace used for text translation
 * @returns {String} translated date of when the device was last updated
 */
const getTranslatedLastUpdate = ({eventDateInMilliseconds, intl, namespace}) => {
  let lastUpdate;
  if (eventDateInMilliseconds) {
    const date = intl.formatDate(new Date(eventDateInMilliseconds).getTime(), DATE_FORMATS.default);
    lastUpdate = `(${intl.formatMessage({id: `${namespace}.details.lastUpdate`}, {date})})`;
  }
  return lastUpdate;
};

/**
 * @description A function to return an action Id used in react-intl
 * @param {String} namespace - given namespace
 * @param {String} action - given action
 * @return {String} action Id used in react-intl
 */
const getIntlActionId = ({namespace, action}) => `${namespace}.action.${action.toLowerCase()}`;

/**
 * @description Method to get the latest activation timestamp
 * @param {Device} device
 * @returns {String} latest activation timestamp, or '0' if none available
 */
const getLatestStateChangeTimestamp = (device) => {
  // If both dates come back as EN_DASH return '0'
  // This should never happen but it does in the test data
  if (
    device.initialActivationTimestamp === EN_DASH &&
    device.activationStateChangeTimestamp === EN_DASH
  )
    return '0';

  // When we have an activationStateChangeTimestamp return that
  // Otherwise return initialActivationTimestamp
  return device.activationStateChangeTimestamp === EN_DASH
    ? device.initialActivationTimestamp
    : device.activationStateChangeTimestamp;
};

export {
  getTranslatedStatus,
  getTranslatedStatusWithDate,
  getTranslatedLastUpdate,
  getValue,
  getIntlActionId,
  getLatestStateChangeTimestamp,
};
