(function () {
  /**
   * @deprecated use src2 User modal
   */
  angular.module('app.widgets.users').component('appEditUserForm', {
    bindings: {
      displayMessage: '=',
      user: '=',
      userInfoForm: '=',
    },
    controller,
    templateUrl: 'app/widgets/users/edit-user-form/edit-user-form.component.html',
  });

  /* @ngInject */
  function controller(
    $scope,
    $translate,
    _,
    CountryList,
    DisplayMessage,
    EDIT_USER_PROFILE_OPEN,
    feature,
    locale
  ) {
    this.$onInit = $onInit;

    /////////////
    function $onInit() {
      const vm = this;

      vm.countryPlaceholder = $translate.instant('widgets.users.addUserForm.countryPlaceholder');
      vm.isSurnameFirstNameLocale = locale.isSurnameFirstNameLocale();
      vm.updateCountryCode = updateCountryCode;
      vm.showEditUserDomainPageBanner = false;

      const countryList = CountryList.get();
      countryList.$promise
        .then(() => {
          vm.userInfoForm.countriesData = _.map(countryList.items, filterCountry);
        })
        .catch(() => {
          showPageBanner();
        });

      function filterCountry(country) {
        return {display: country.countryName, value: country.countryCode};
      }

      function showPageBanner() {
        vm.displayMessage = new DisplayMessage();
      }

      function updateCountryCode(newVal) {
        if (vm.user.countryCode !== newVal) {
          vm.userInfoForm.$setDirty();
        }
        vm.user.countryCode = newVal;
      }

      $scope.$watch(
        () => vm.user.email,
        (newValue, oldValue) => {
          vm.showEditUserDomainPageBanner =
            newValue && oldValue && getDomainFromEmail(newValue) !== getDomainFromEmail(oldValue);
        }
      );

      $scope.$on(EDIT_USER_PROFILE_OPEN, () => {
        vm.showEditUserDomainPageBanner = false;
      });
    }

    function getDomainFromEmail(email) {
      const emailElements = _.split(email, '@');
      return emailElements.length >= 2 ? emailElements[emailElements.length - 1] : undefined;
    }
  }
})();
