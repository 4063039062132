/**
 * @deprecated ported to src2 or no longer required
 *
 * @description a filter to display the title string for an IdP.
 *              In the case of a known sniffed provider, the order for
 *              the concatenated strings is sniffedProvider + protocol
 *              (e.g. Google SAML); for IdPs without sniffedProviders,
 *              the order is protocol + "Provider" (e.g. SAML Provider)
 *
 * @param {String} idp an Idp instance
 *
 * @returns {String} IdP title based on federation type mapping
 */
(function () {
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.directories').filter('appIdpTitle', appIdpTitleFilter);

  /* @ngInject */
  function appIdpTitleFilter($translate, appIdpProtocolFilter, IDP_TYPES, translationUtils) {
    return getIdpTitle;

    function getIdpTitle(idp) {
      let provider = '';
      const protocol = appIdpProtocolFilter(idp);

      if (!idp) {
        provider = $translate.instant(`widgets.directories.idpList.provider`);
        return `${protocol} ${provider}`; // Unknown Provider
      }

      const sniffedProviderTranslationId = `widgets.directories.idpList.idpSniffedProviders.${idp.sniffedProvider}`;
      const isKnownSniffedProvider = $translate.getTranslationTable()[sniffedProviderTranslationId];

      // This check is necessary for the situation where a new sniffed provider is added
      // on the backend side, but no localization key is updated yet
      if (isKnownSniffedProvider) {
        provider = translationUtils.sanitizeSafeInstant(sniffedProviderTranslationId);
        return `${provider} ${protocol}`; // Google SAML
      }

      if (idp.federationType === IDP_TYPES.AZURE) {
        provider = $translate.instant(`widgets.directories.idpList.providerAzure`);
        return `${provider} ${protocol}`; // Azure OIDC
      }

      if (idp.federationType === IDP_TYPES.OKTA) {
        provider = $translate.instant(`widgets.directories.idpList.providerDeprecated`);
        return `${protocol} ${provider}`; // SAML Provider (Deprecated)
      }

      if (idp.federationType === IDP_TYPES.SOIDC) {
        provider = translationUtils.sanitizeSafeInstant(
          `widgets.directories.idpList.idpNamedProviders.${idp.providerName}`
        );
        return `${provider}${idp.providerName === 'google' ? ` ${protocol}` : ``}`; // Classlink
      }

      provider = $translate.instant(`widgets.directories.idpList.provider`);
      return `${protocol} ${provider}`; // SAML Provider
    }
  }
})();
