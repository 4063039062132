import {DIRECTORY_OWNERSHIP_STATUS} from '@admin-tribe/binky';
import {computed, makeObservable} from 'mobx';

const DOMAIN_STATUSES = {
  ACTIVE: 'ACTIVE',
  CLAIMED: 'CLAIMED',
  UNCLAIMED: 'UNCLAIMED',
  VALIDATED: 'VALIDATED',
};

class DomainEntity {
  constructor(domainData) {
    makeObservable(this, {
      needsValidation: computed,
    });

    this.id = domainData.domainName;
    this.domainName = domainData.domainName;
    this.status = domainData.status;
    this.directoryId = domainData.directoryId;
    this.directoryName = domainData.directoryName;
    this.directoryType = domainData.directoryType;
    this.domainEnforcement = domainData.domainEnforcement;
    this.externallyManaged = domainData.externallyManaged;
    this.ownershipStatus = domainData.ownershipStatus;
  }

  get isActive() {
    return this.status === DOMAIN_STATUSES.ACTIVE;
  }

  get isSynced() {
    return this.externallyManaged;
  }

  get isTrusted() {
    return this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED;
  }

  get needsDirectory() {
    return this.status === DOMAIN_STATUSES.VALIDATED;
  }

  get needsValidation() {
    return this.status === DOMAIN_STATUSES.UNCLAIMED;
  }
}

export default DomainEntity;
export {DOMAIN_STATUSES};
