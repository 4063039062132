/**
 * @deprecated with temp_react_port_jobs
 *
 * @ngdoc component
 * @name app.widgets.users:appJobDetailsDrawer
 *
 * @description The drawer for the Bulk Operation Results page.
 *
 * @binding {Object} job - reference to job to display job details for
 * @binding {String} orgId - id of organization
 * @binding {String} pageContext - context data about parent page rendering this component
 */
(function () {
  angular.module('app.widgets.bulk-operations').component('appJobDetailsDrawer', {
    bindings: {
      job: '<',
      orgId: '@',
      pageContext: '<',
    },
    controller,
    templateUrl:
      'app/widgets/bulk-operations/jobs/job-details-drawer/job-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $log,
    $q,
    $state,
    $translate,
    _,
    feature,
    JOB_DETAILS_DRAWER_ID,
    JobResult,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    toastManager,
    TOAST_TYPES
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      cancelProcessing,
      downloadResults,
      feature,
      getJobResultsPageLink,
    });

    function $onInit() {
      vm.drawerId = JOB_DETAILS_DRAWER_ID;
    }

    function $onChanges(changesObj) {
      vm.job = _.get(changesObj, 'job.currentValue');
      if (vm.job) {
        vm.drawerTitle = $translate.instant(`${vm.job.getOperationDisplayName()}`);
      }
    }

    function cancelProcessing() {
      vm.cancelInProgress = true;
      vm.job.cancel().then(onSuccess).catch(onError);

      ////////

      function onSuccess() {
        const message = $translate.instant('widgets.bulkOperations.cancel.success');
        toastManager.showSuccessToast(message);
        vm.cancelInProgress = false;
      }
      function onError() {
        const message = $translate.instant('widgets.bulkOperations.cancel.error');
        toastManager.showErrorToast(message);
        vm.cancelInProgress = false;
      }
    }

    function downloadResults() {
      vm.downloadInProgress = true;

      toastManager.showToast(
        TOAST_TYPES.INFO,
        $translate.instant('widgets.bulkOperations.downloadBeingPrepared')
      );

      return $q((resolve, reject) => {
        JobResult.export(vm.job.id, vm.pageContext)
          .then(onFetchCSVSuccess, onFetchCSVError)
          .finally(onFetchCSVComplete);

        ////////

        function onFetchCSVComplete() {
          vm.downloadInProgress = false;
        }

        function onFetchCSVError() {
          const message = $translate.instant('widgets.bulkOperations.download.unableToDownload');
          toastManager.showErrorToast(message);
          reject();
        }

        function onFetchCSVSuccess(filename) {
          const message = $translate.instant('widgets.bulkOperations.download.downloadSuccess', {
            filename,
          });
          toastManager.showSuccessToast(message);
          resolve();
        }
      });
    }

    // eslint-disable-next-line complexity
    function getJobResultsPageLink() {
      let pageLink;
      switch (vm.pageContext.target) {
        case PAGE_TARGET.PRODUCT:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              throw new Error('Angular products has been removed');
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.PRODUCT_CONFIGURATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              throw new Error('Angular products has been removed');
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.DIRECTORY:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              pageLink = $state.href('users.directory-user-results', {
                directoryId: vm.pageContext.targetId,
                jobId: vm.job.id,
                orgId: vm.orgId,
              });
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.USER_GROUP:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              if (vm.pageContext.targetId) {
                pageLink = $state.href('users.user-group-results', {
                  jobId: vm.job.id,
                  orgId: vm.orgId,
                  userGroupId: vm.pageContext.targetId,
                });
              } else {
                pageLink = $state.href('users.user-group-bulk-operations-results', {
                  jobId: vm.job.id,
                  orgId: vm.orgId,
                });
              }
              break;
            default:
              logUnimplementedError();
          }
          break;
        case PAGE_TARGET.ORGANIZATION:
          switch (vm.pageContext.targetType) {
            case PAGE_TARGET_TYPE.USER:
              pageLink = $state.href('users.results', {
                jobId: vm.job.id,
                orgId: vm.orgId,
              });
              break;
            default:
              logUnimplementedError();
          }
          break;
        default:
          logUnimplementedError();
      }

      return pageLink;

      ////////

      function logUnimplementedError() {
        $log.debug(
          `AppJobDetailsDrawer: Results view unimplemented for provided pageContext: ${vm.pageContext}`
        );
      }
    }
  }
})();
