(function () {
  /**
   * @deprecated moved to src2 as <SupportTicketStatus>
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportTicketStatus
   * @description renders a support status icon/label for a support ticket using the binkyStatus component.
   */
  angular.module('app.widgets.support').component('appSupportTicketStatus', {
    bindings: {
      supportTicket: '<',
    },
    controller,
    templateUrl: 'app/widgets/support/support-ticket-status/support-ticket-status.component.html',
  });

  function controller($filter, _) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      if (vm.supportTicket) {
        vm.variant = vm.supportTicket.getUiStatus().variant;
        vm.statusLabel = $filter('appSupportTicketStatus')(vm.supportTicket);
      }
    }
  }
})();
