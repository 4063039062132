/* eslint-disable max-lines -- Lots of routes that need loaders */
import {PRODUCT_DELEGATION_TARGET, feature, hasLegacyDeviceLicenses} from '@admin-tribe/binky';
import {generatePath, redirect} from 'react-router-dom';

import {isExactRouteMatch} from 'common/utils/routerUtils';
import rootStore from 'core/RootStore';
import {
  canShowAdmins as canShowProductAdmins,
  canViewLegacyDevices,
  canViewOrgDelegatableUnlimited,
  canViewProductBulkOperations,
  canViewTransactions,
  canViewUsers,
} from 'core/products/access/productAccess';
import {
  canShowAdmins,
  canShowUsers,
  canViewDevelopers,
  canViewDevices,
  canViewPermissions,
  canViewProductProfiles,
} from 'core/products/access/productProfileAccess';
import {throwLoaderNoAccessError} from 'core/router/loaders';

import {
  PATH_PRODUCTS_APP_INTEGRATIONS,
  PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
  PATH_PRODUCTS_DEVICES,
  PATH_PRODUCTS_DEVICES_DEACTIVATED,
  PATH_PRODUCTS_DEVICES_LEARN_MORE,
  PATH_PRODUCT_DETAILS,
  PATH_PRODUCT_DETAILS_ADMINS,
  PATH_PRODUCT_DETAILS_DEVICES,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED,
  PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
  PATH_PRODUCT_DETAILS_PROFILES,
  PATH_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_PRODUCT_DETAILS_USERS,
  PATH_PRODUCT_PROFILE_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_PRODUCT_PROFILE_DETAILS_USERS,
} from '../productsPaths';

/**
 * @description Returns the corresponding product from the productId for the admins tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productAdminsLoader({params: {productId}, request}) {
  return getProductIfAccessible({accessCallback: canShowProductAdmins, productId, request});
}

/**
 * @description Returns the corresponding product from the productId for the product bulk operation pages.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productBulkOperationsLoader({params: {productId}, request}) {
  return getProductIfAccessible({accessCallback: canViewProductBulkOperations, productId, request});
}

/**
 * @description Returns the corresponding product group from the groupId
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.groupId - the groupId from the url
 *
 * @returns {{productGroup: ProductGroupProductList}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productGroupsLoader({params: {groupId}, request}) {
  const productGroup = rootStore.organizationStore.orgConsumables.getByGroupId(groupId);
  if (!productGroup) {
    throwLoaderNoAccessError(request);
  }
  return {productGroup};
}

/**
 * Determines the correct tab to redirect to for the product details page.
 * @returns {Response | null}
 */
const handleProductDetailsPageTabRedirects = ({product, orgId}) => {
  const productId = product.id;
  if (canViewProductProfiles(product)) {
    return redirect(generatePath(PATH_PRODUCT_DETAILS_PROFILES, {orgId, productId}));
  }

  if (canViewUsers(product)) {
    return redirect(generatePath(PATH_PRODUCT_DETAILS_USERS, {orgId, productId}));
  }

  if (canShowProductAdmins(product)) {
    return redirect(generatePath(PATH_PRODUCT_DETAILS_ADMINS, {orgId, productId}));
  }

  if (canViewTransactions(product)) {
    return redirect(generatePath(PATH_PRODUCT_DETAILS_TRANSACTIONS, {orgId, productId}));
  }

  return null;
};

/**
 * @description Redirects to the correct tab for the product details page.
 * @param {{request: Request, params: {orgId: string, productId: string}}} loaderData
 * React router loader data - params, request.
 * @returns {Response | null}
 *
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productDetailsLoader({params: {orgId, productId}, request}) {
  const product = rootStore.organizationStore.productList.items.find((p) => p.id === productId);
  if (!product || !product.isAdministerable()) {
    throwLoaderNoAccessError(request);
  }

  if (isExactRouteMatch(request, PATH_PRODUCT_DETAILS)) {
    if (product.isFeatureRestrictedLicense()) {
      return redirect(generatePath(PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED, {orgId, productId}));
    }

    if (product.isLegacyDeviceLicense()) {
      return redirect(generatePath(PATH_PRODUCT_DETAILS_DEVICES, {orgId, productId}));
    }

    if (product.isSharedDeviceLicense()) {
      return handleProductDetailsPageTabRedirects({orgId, product});
    }

    if (product.isOrgDelegatableUnlimited()) {
      return redirect(
        generatePath(PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED, {orgId, productId})
      );
    }

    return handleProductDetailsPageTabRedirects({orgId, product});
  }

  return null;
}

/**
 * Redirects to the default tab for the application integrations page
 * @param {{request: Request, params: {orgId: string}}}
 * @returns {Response | null}
 */
const appIntegrationLoader = ({params: {orgId}, request}) => {
  if (isExactRouteMatch(request, PATH_PRODUCTS_APP_INTEGRATIONS)) {
    return redirect(generatePath(PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES, {orgId}));
  }

  return null;
};

/**
 * Redirects to the default tab for the dbl page
 * @param {{request: Request, params: {orgId: string}}}
 * @returns {Response | null}
 */
const dblLoader = ({params: {orgId}, request}) => {
  if (isExactRouteMatch(request, PATH_PRODUCTS_DEVICES)) {
    if (hasLegacyDeviceLicenses(rootStore.organizationStore.productList)) {
      return redirect(generatePath(PATH_PRODUCTS_DEVICES_DEACTIVATED, {orgId}));
    }

    return redirect(generatePath(PATH_PRODUCTS_DEVICES_LEARN_MORE, {orgId}));
  }

  return null;
};

/**
 * @description Returns the corresponding product from the productId
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productOrgDelegatableUnlimitedLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewOrgDelegatableUnlimited,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productTransactionsLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewTransactions,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding device product from the productId
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 */
function productDevicesDetailsLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewLegacyDevices,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId for the admins tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {Product}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfileAdminsLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canShowAdmins,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId for the devices tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfileDetailsLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewProductProfiles,
    productId,
    request,
  });
}

/**
 * Redirects to the correct tab for the product profile details page.
 * @param {{request: Request, params: {orgId: string, productId: string, profileId: string}}} param0
 * @returns {Response | null}
 * @throws {Error} When unable to determine the product profile details tab
 */
function productProfileDetailsRedirectLoader({params: {orgId, productId, profileId}, request}) {
  if (isExactRouteMatch(request, PATH_PRODUCT_PROFILE_DETAILS)) {
    const product = rootStore.organizationStore.productList.items.find((p) => p.id === productId);

    // Devices tab
    if (feature.isEnabled('temp_enable_react_sdl') && product.isSharedDeviceLicense()) {
      return redirect(
        generatePath(PATH_PRODUCT_PROFILE_DETAILS_DEVICES, {orgId, productId, profileId})
      );
    }

    // Users tab
    if (canShowUsers(product)) {
      return redirect(
        generatePath(PATH_PRODUCT_PROFILE_DETAILS_USERS, {orgId, productId, profileId})
      );
    }

    // Admins tab
    if (canShowAdmins(product)) {
      return redirect(
        generatePath(PATH_PRODUCT_PROFILE_DETAILS_ADMINS, {orgId, productId, profileId})
      );
    }

    // Permissions tab
    if (canViewPermissions(product)) {
      return redirect(
        generatePath(PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS, {orgId, productId, profileId})
      );
    }

    // Developers and API credentials tabs
    if (product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)) {
      return redirect(
        generatePath(PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS, {orgId, productId, profileId})
      );
    }

    throw new Error('Unable to determine the product profile details tab');
  }

  return null;
}

/**
 * @description Returns the corresponding product from the productId for the developers/integrations tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfileDevelopersLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewDevelopers,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId for the devices tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfileDevicesLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewDevices,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId for the permissions tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfilePermissionsLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewPermissions,
    productId,
    request,
  });
}

/**
 * @description Returns the corresponding product from the productId for the users tab.
 * @param {{request: Request, params: {}}} loaderData
 * React router loader data - params, request.
 * @param {String} loaderData.params.productId - the productId from the url
 *
 * @returns {{product: Product}}
 * @throws loader access error with status 403 if admin does not have access or access can't be determined
 */
function productProfileUsersLoader({params: {productId}, request}) {
  return getProductIfAccessible({
    accessCallback: canViewUsers,
    productId,
    request,
  });
}

/** Private methods */

/**
 *
 * @param {String} productId The product id
 * @param {Function} accessCallback - The access callback fn to execute
 * @param {Request} request - The request object from React router loader
 * @returns {{product: Product}} - The product model.
 */
function getProductIfAccessible({productId, accessCallback, request}) {
  const product = rootStore.organizationStore.productList.items.find((p) => p.id === productId);
  if (!product || !accessCallback(product)) {
    throwLoaderNoAccessError(request);
  }
  return {product};
}

export {
  appIntegrationLoader,
  dblLoader,
  productAdminsLoader,
  productBulkOperationsLoader,
  productDetailsLoader,
  productDevicesDetailsLoader,
  productProfileDetailsRedirectLoader,
  productGroupsLoader,
  productOrgDelegatableUnlimitedLoader,
  productProfileAdminsLoader,
  productProfileDetailsLoader,
  productProfileDevelopersLoader,
  productProfileDevicesLoader,
  productProfilePermissionsLoader,
  productProfileUsersLoader,
  productTransactionsLoader,
};
/* eslint-enable max-lines -- Lots of routes that need loaders */
