import {ActionButton, Flex, Text, TextArea, View, Well} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import AddIcon from '@spectrum-icons/workflow/Add';
import EditIcon from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import EditNoteModal from 'common/components/edit-note-modal/EditNoteModal';
import useModal from 'common/components/modal/useModal';
import CustomData from 'common/entities/custom-data/CustomData';
import CUSTOM_DATA_CONSTANTS from 'common/entities/custom-data/CustomDataConstants';
import CustomDataList from 'common/entities/custom-data/CustomDataList';

/**
 * Defines the note section of the console settings page.
 */
const NoteSection = ({notes}) => {
  const [note, setNote] = useState(
    notes.getAllDelegatesNote() ||
      new CustomData({name: CUSTOM_DATA_CONSTANTS.NAME.ALL_DELEGATES, value: ''})
  );
  const [isEditNoteModalOpen, openEditNoteModal, closeEditNoteModal] = useModal();
  const intl = useIntl();
  const notesHeadingId = useId();
  const notesDescriptionId = useId();

  const onSuccess = (newNote) => {
    setNote(newNote);
    closeEditNoteModal();
  };

  return (
    <View data-testid="note-section" marginBottom="size-200">
      <TitledSection
        dividerPlacement={DividerPlacement.TOP}
        headingLevel={2}
        id={notesHeadingId}
        title={intl.formatMessage({
          id: 'settings.consoleSettings.note.title',
        })}
      >
        <EditNoteModal
          description={intl.formatMessage({
            id: 'settings.consoleSettings.editNoteModal.description',
          })}
          header={intl.formatMessage({
            id: `settings.consoleSettings.editNoteModal.header.${
              note.isNewCustomData() ? 'add' : 'edit'
            }`,
          })}
          isOpen={isEditNoteModalOpen}
          note={note}
          onCancel={closeEditNoteModal}
          onSuccess={onSuccess}
        />
        <View marginBottom="size-200">
          <Text data-testid="description" id={notesDescriptionId}>
            {intl.formatMessage({
              id: 'settings.consoleSettings.note.description',
            })}
          </Text>
        </View>
        {note.isNewCustomData() ? (
          <Flex>
            <ActionButton data-testid="add-note-btn" onPress={openEditNoteModal}>
              <AddIcon />
              <Text>
                {intl.formatMessage({
                  id: 'settings.consoleSettings.note.add',
                })}
              </Text>
            </ActionButton>
          </Flex>
        ) : (
          <Flex gap="size-100">
            <Well width="90%">
              <TextArea
                aria-describedby={notesDescriptionId}
                aria-labelledby={notesHeadingId}
                data-testid="note"
                isQuiet
                isReadOnly
                value={note.value}
                width="100%"
              />
            </Well>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'settings.consoleSettings.note.edit',
              })}
              data-testid="edit-note-btn"
              isQuiet
              onPress={openEditNoteModal}
            >
              <EditIcon />
            </ActionButton>
          </Flex>
        )}
      </TitledSection>
    </View>
  );
};

NoteSection.propTypes = {
  /**
   * CustomDataList which is used to retrieve the ALL_DELEGATES note, if it exists.
   */
  notes: PropTypes.instanceOf(CustomDataList).isRequired,
};

export default NoteSection;
