/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains2:appDeleteDirectoryErrorModal
 *
 * @description The modal to show errors from the Delete Directory operation.
 */
(function () {
  angular.module('app.widgets.directories').component('appDeleteDirectoryErrorModal', {
    controller,
    templateUrl:
      'app/widgets/directories/delete-directory-error-modal/delete-directory-error-modal.component.html',
  });

  /* @ngInject */
  function controller(_, DELETE_DIRECTORY_ERROR_MODAL_ID) {
    const vm = this;

    _.assign(vm, {
      DELETE_DIRECTORY_ERROR_MODAL_ID,
      onModalOpen,
    });

    function onModalOpen(params) {
      vm.requestCount = params.requestCount; // number of directories attempted to be deleted
      vm.errorItems = params.errorItems; // directories that could not be deleted
    }
  }
})();
