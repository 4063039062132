(function () {
  /**
   * @ngdoc module
   * @name app.widgets.user-groups.detail-section
   * @description module that defines app.widgets.user-groups.detail-section widgets
   */
  angular.module('app.widgets.user-groups.detail-section', [
    'app.core.auth',
    'app.shell',
    'binky.core.lodash',
    'binky.widgets.common.detail-section',
    'pascalprecht.translate',
  ]);
})();
