import {ActionButton, Text} from '@adobe/react-spectrum';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import RemoveCircle from '@spectrum-icons/workflow/RemoveCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const IncludePaneAllButton = ({onPress, isDisabled, type, title}) => {
  const intl = useIntl();

  return (
    <ActionButton
      aria-label={`${intl.formatMessage({
        id: 'common.includeListForm.addAll',
      })} ${title}`}
      data-testid="include-pane-all-button"
      isDisabled={isDisabled}
      onPress={onPress}
      variant="primary"
    >
      {type === 'available' && (
        <>
          <AddCircle />
          <Text>
            {intl.formatMessage({
              id: 'common.includeListForm.addAll',
            })}
          </Text>
        </>
      )}
      {type === 'included' && (
        <>
          <RemoveCircle />
          <Text>
            {intl.formatMessage({
              id: 'common.includeListForm.removeAll',
            })}
          </Text>
        </>
      )}
    </ActionButton>
  );
};

IncludePaneAllButton.propTypes = {
  /**
   * True if the add/remove all button should be disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * The function to run on button press.
   */
  onPress: PropTypes.func.isRequired,
  /**
   * The title to use for the aria label.
   */
  title: PropTypes.string.isRequired,
  /**
   *  The typeof this include pane. Current possible values are 'avaiable' and 'included'.
   */
  type: PropTypes.oneOf(['available', 'included']),
};

export default IncludePaneAllButton;
