/**
 * @deprecated ported to src2/app/features/users/components/directory-user-details-drawer-content/DirectoryUserDetailsDrawerContent.jsx and
 *  src2/app/common/components/users-details-drawer/UsersDetailsDrawer.jsx
 *
 * @ngdoc component
 * @name app.widgets.directories:appDirectoryUserDetailsDrawer
 *
 * @description The drawer for the directory user page.
 *
 * @binding {PageContext} pageContext. To determine if View should go to User or Admin detail page.
 * @binding {DirectoryUser} user. user does not have a promise and the
 *   userGroups field is not populated.
 */
(function () {
  const componentName = 'appDirectoryUserDetailsDrawer';
  angular.module('app.widgets.directories').component(componentName, {
    bindings: {
      pageContext: '<',
      user: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/directory-user-details-drawer/directory-user-details-drawer.component.html',
  });

  /* @ngInject */
  function controller(
    $filter,
    $state,
    _,
    binkyMemberTypeLabelFilter,
    DIRECTORY_USER_DETAILS_DRAWER_ID,
    DirectoryUser,
    feature,
    OrganizationList
  ) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      $onInit,
      feature,
      onClickViewProfileBtn,
      userOfCurrentOrg,
    });

    /////////////////

    function $onInit() {
      _.assign(vm, {
        drawerId: DIRECTORY_USER_DETAILS_DRAWER_ID,
      });
    }

    function $onChanges(changesObj) {
      vm.user = _.get(changesObj, 'user.currentValue');
      if (vm.user) {
        vm.user = DirectoryUser.get(vm.user);
        vm.detailItems = [
          {class: 'member-type', value: binkyMemberTypeLabelFilter(vm.user.getType())},
          {class: 'email', value: vm.user.email},
        ];
        vm.drawerTitle = $filter('binkyMemberNameLabel')(vm.user);
      }
    }

    function onClickViewProfileBtn() {
      $state.go('users.directory-user-details', {
        directoryId: vm.user.directoryId,
        userId: vm.user.id,
      });
    }

    function userOfCurrentOrg() {
      return _.find(vm.user.organizations, ['id', OrganizationList.get().activeOrg.id]);
    }
  }
})();
