import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {getContentObject, getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import useGlobalModal from 'common/hooks/useGlobalModal';
import SophiaCard from 'common/services/sophia/SophiaCard';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

import QuickLink from '../QuickLink';

const SophiaQuickLink = ({sophiaCard}) => {
  const [modalData, setModalData] = useState();
  const {openModal} = useGlobalModal(modalData);

  // open modal when loaded
  useEffect(() => {
    if (modalData) {
      openModal();
    }
  }, [modalData, openModal]);

  const card = sophiaCard.getCard();
  const cardContent = getContentObject(card);

  return (
    <QuickLink
      key={sophiaCard.getCardId()}
      analyticsOptions={{
        analyticsAction: 'sophia-campaign',
        sophia: {
          responses: [sophiaCard.getAnalyticsParams()],
        },
      }}
      data-sophia-campaign-id={sophiaCard.getCampaignId()}
      data-testid="quick-link-sophia"
      iconSrc={card.backgroundImage}
      label={cardContent.cardLabel}
      onPress={() => {
        const sophiaCallback = getCtaClickEventCallback(card.actionURL)();
        // if getCtaClickEventCallback returns data, it means a modal should be opened
        if (sophiaCallback) {
          setModalData(sophiaCallback);
        }
      }}
    />
  );
};

SophiaQuickLink.propTypes = {
  sophiaCard: PropTypes.instanceOf(SophiaCard),
};

async function getSophiaCards() {
  const sophiaCardList = await sophiaHelper.getSophiaCards({
    surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_QUICK_LINKS,
  });

  return sophiaCardList.items;
}

SophiaQuickLink.get = async () => {
  const sophiaCards = await getSophiaCards();

  if (sophiaCards.length > 0) {
    return sophiaCards.map((card) => <SophiaQuickLink key={card.getCardId()} sophiaCard={card} />);
  }

  throw new Error('No sophia cards were fetched');
};

export default SophiaQuickLink;
