import {log} from '@admin-tribe/binky';
import {LIST_CONTAINER_ACTIONS} from '@pandora/react-list-container';
import {SORT_ORDER, TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';

/**
 * Implements the OnTableSectionChangeParams interface for a Pandora Table Section.
 * It coverts the actions into store actions which update the JILModelListState.
 *
 * @param {String} action. The table section action that occurred. One of TABLE_SECTION_ACTIONS.
 * @param {any} payload. The payload of data to send back specific to the action that occurred.
 * @param {MemberStore} store. The store which manages the JIL query parameters and the list.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions, complexity -- switch
function updateMemberStore({action, payload, state, store}) {
  switch (action) {
    case LIST_CONTAINER_ACTIONS.CLEAR_SELECTED_KEYS:
      store.setSelectedItems([]);
      break;
    case LIST_CONTAINER_ACTIONS.SET_PAGE_NUMBER:
      store.onPageNumberChange({pageNumber: payload});
      break;
    case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
      store.setSelectedItems(state.selectedItems);
      break;
    case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      store.onPageNumberChange({goToNext: true});
      break;
    case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
      store.onPageNumberChange({goToPrevious: true});
      break;
    case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
      store.onPageSizeChange(payload);
      break;
    case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
      store.onFilterQuery(payload.value);
      break;
    case TABLE_SECTION_ACTIONS.ON_SORT_BY:
      store.onSortBy(payload.id, payload.sortedOrder === SORT_ORDER.DESC);
      break;
    case LIST_CONTAINER_ACTIONS.DELETE_FILTER:
    case LIST_CONTAINER_ACTIONS.DELETE_ALL_FILTERS:
      store.onFilterQuery('');
      break;
    case LIST_CONTAINER_ACTIONS.SET_DATA_ATTRIBUTES:
      // Emitted when the TableSection initializes itself.
      // It is an array whose 1st item is the sort descriptor set on the Table
      // in MemberListTable.
      break;
    default:
      log.error(`updateMemberStore: action '${action}' not handled`);
      break;
  }
}

export default updateMemberStore;
