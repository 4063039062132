/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase.renew-products-modal:appRenewProductsModal2
   *
   * @description the modal to renew products for direct contracts
   *
   * @param {Contract} contract the contract we want to update the renewal order for
   * @example <app-renew-products-modal2
   *            contract="$ctrl.contract"
   *          </app-renew-products-modal2>
   */
  const componentName = 'appRenewProductsModal2';
  angular.module('app.widgets.products.purchase.renew-products-modal2').component(componentName, {
    bindings: {
      contract: '<',
      onCartItemsChange: '&?',
      onClosed: '&?',
      onInitialized: '&?',
      onModalStateChange: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/renew-products-modal2/renew-products-modal2.component.html',
  });

  /* @ngInject */
  // eslint-disable-next-line max-statements
  function controller(
    $document,
    $log,
    $q,
    $rootScope,
    $timeout,
    $translate,
    _,
    AnalyticsEvent,
    AnalyticsHelper,
    AuthenticatedUser,
    binkySrc2,
    Cart,
    CART_TYPE,
    CHAT_APP_ID,
    COMMON_EVENTS,
    DISPLAY_MESSAGE_VARIANT,
    DisplayMessage,
    feature,
    OFFER_LIST_INTENT,
    onesieSrc2,
    OrganizationManager,
    PO_NUMBER_DISMISS_CONFIRM_MODAL_ID,
    PRODUCT_BUCKET,
    RENEW_PRODUCTS_MODAL2_CART_PROPS,
    RENEW_PRODUCTS_MODAL2_ID,
    RENEW_PRODUCTS_MODAL2_STATES,
    RENEW_PRODUCTS_MODAL2_STEPS,
    panelManager,
    ProductPurchaseHelper,
    SophiaHelper,
    SURFACE_ID,
    toastManager,
    UiEventDescriptor
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      feature,
      isEnabled: feature.isEnabled,
      onAddCatalogItem,
      onBack,
      onCancellationReasonChange,
      onCartItemLicensesChange,
      onClose,
      onCloseIntent,
      onConfirmPONumberDismissModal,
      onNext,
      onOpen,
      onPromoRedeemed,
      onRemoveFromCart,
      onStartChatButtonClick,
      RENEW_PRODUCTS_MODAL2_STATES,
      savePONumber,
      showCartTotalInFooter,
      showPONumberDismissModal,
      submit,
      updateTotals,
    });

    let analyticsSourcePage, initialRenewalCartItems, initialRenewalTotal, stateHistory;
    const debouncedFetchBillableItems = _.debounce(fetchBillableItems, 500);

    const {
      MODIFY_RENEWAL_ORDER,
      REVIEW_RENEWAL_ORDER,
      SELECT_CANCELLATION_REASON,
      SELECT_SPECIAL_OFFERS,
      SHOW_PAGE_BANNER,
      VIEW_RENEWAL_ORDER,
    } = RENEW_PRODUCTS_MODAL2_STATES;

    /////////////

    function $onInit() {
      _.assign(vm, {
        CHAT_APP_ID,
        descriptors: {},
        displayMessage: undefined,
        fetchingCartPreview: false,
        isSubmitting: false,
        PO_NUMBER_DISMISS_CONFIRM_MODAL_ID,
        promiseToWait: $q.resolve(),
        RENEW_PRODUCTS_MODAL2_ID,
        RENEW_PRODUCTS_MODAL2_STATES,
        StartChatButton: onesieSrc2.support.components.StartChatButton,
      });

      // Allow this modal to complete its initialization before attempting to open it.
      $timeout(() => {
        _.invoke(vm, 'onInitialized');
      });
    }

    // Add an item to the end of the cart.
    function onAddCatalogItem(offerId) {
      const foundOwnedOffer = _.remove(vm.ownedOffers, {offer_id: offerId});
      const foundRemainingOffer = _.remove(vm.unownedWithoutRecOffers, {
        offer_id: offerId,
      });
      const foundRecommendedOffer = _.remove(vm.unownedWithRecOffers, {offer_id: offerId});
      const result = _.concat(foundRemainingOffer, foundOwnedOffer, foundRecommendedOffer);

      if (result.length > 1) {
        $log.error(
          `RenewProductsModal2.onAddCatalogItem(): duplicate offers for offerid=${offerId}`
        );
        return;
      }
      // should only be one result with corresponding id
      const offerToAdd = _.head(result);

      // Used to initialize the cart-item qty selector.
      _.assign(offerToAdd, {
        numberSelected: _.get(offerToAdd, 'assignableLicenseCount', 1),
      });

      vm.cartItems.push(offerToAdd);
      updateTotals();

      AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({
        eventName: 'addCart',
        offer: offerToAdd,
      });
    }

    function onBack() {
      // Capture analytics before modal state changes
      dispatchAnalyticsClickEventWithCart({
        eventName: vm.modalState.settings.analytics.backButtonEventName || 'previous',
      });

      setModalState(stateHistory.pop());
    }

    function onCancellationReasonChange({reasons, isValid}) {
      // Array of CANCEL_REASONS from appCancellationCheckList
      vm.cancellationReason = reasons;
      vm.cancellationReasonIsValid = isValid;
    }

    function onCartItemLicensesChange(offer) {
      updateTotals();

      AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({eventName: 'changeLicense', offer});
    }

    function onClose({dispatchEvent = true} = {}) {
      resetState();
      panelManager.close(RENEW_PRODUCTS_MODAL2_ID);

      if (dispatchEvent) {
        dispatchAnalyticsClickEventWithCart({eventName: 'close'});
      }

      if (analyticsSourcePage) {
        const camelCaseModalId = _.camelCase(RENEW_PRODUCTS_MODAL2_ID);
        AnalyticsHelper.dispatchUiEventAnalytics({
          eventAction: 'close',
          eventName: camelCaseModalId,
          name: `close${_.upperFirst(camelCaseModalId)}`,
        });
      }

      _.invoke(vm, 'onClosed');
    }

    function onCloseIntent() {
      if (vm.poNumber) {
        onClose({dispatchEvent: false});
      } else {
        panelManager.open(PO_NUMBER_DISMISS_CONFIRM_MODAL_ID);
      }
    }

    function onConfirmPONumberDismissModal() {
      panelManager.close(PO_NUMBER_DISMISS_CONFIRM_MODAL_ID);
      try {
        $document[0].querySelector('app-renew-products-review-renewal-order .po-input').focus();
      } catch (error) {
        $log.error(error);
      }
    }

    function onNext(isUpdatePlan) {
      // Capture analytics before modal state changes - except for REVIEW_RENEWAL_ORDER because the submit
      // dispatches its own analytics.
      if (vm.modalState.state !== REVIEW_RENEWAL_ORDER) {
        dispatchAnalyticsClickEventWithCart({
          eventName: vm.modalState.settings.analytics.ctaButtonEventName || 'continue',
        });
      }

      stateHistory.push(vm.modalState.state);
      switch (vm.modalState.state) {
        case MODIFY_RENEWAL_ORDER:
          setNextStateAfterModifyRenewalOrder();
          break;
        case SELECT_CANCELLATION_REASON:
          setNextStateAfterSelectCancellationReason();
          break;
        case REVIEW_RENEWAL_ORDER:
          submit();
          break;
        case VIEW_RENEWAL_ORDER:
          if (vm.contract.isPaymentCategoryPO()) {
            if (isUpdatePlan) {
              setModalState(getNextState());
            } else if (vm.poNumber) {
              savePONumber();
            } else {
              panelManager.open(PO_NUMBER_DISMISS_CONFIRM_MODAL_ID);
            }
          } else {
            setModalState(getNextState());
          }
          break;
        default:
          setModalState(getNextState());
          break;
      }
    }

    /**
     * @description setup the renew products modal dynamically based on the passed in params.
     *
     * @param {Object} params - the passed in params when opening the modal
     * @param {String} [params.analyticsModalSourcePage] - used by analytics, to inform the name of the page that the user
     *    lands on when the modal is closed.
     * @param {RENEW_PRODUCTS_MODAL2_STATES} [params.landingState] - the landing state for the modal
     *   Defaults to VIEW_RENEWAL_ORDER.
     * @param {Array} [params.deepLinkingCartItems] - an array of cart items to be populated to the shopping cart.
     *    The cart item is an object containing offerId, productArrangementCode, and quantity (it must contain either
     *    offerId or productArrangementCode). We'll look up the offer by offerId first, and then fallback to using the productArrangementCode.
     *    If undefined, the shopping cart will be empty. All items should be for the same CUSTOMER_SEGMENT.
     *
     *    Example: [{
     *                 offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                 productArrangementCode: 'ilst_direct_indirect_team',
     *                 quantity: 2
     *              }, {
     *                 offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                 productArrangementCode: 'phsp_direct_indirect_team',
     *                 quantity: 5
     *              }]
     */
    function onOpen(params = {}) {
      resetState();
      // Used for maintaining all of the previous visited states as a stack. Each time the user calls to transition state,
      // add the current state to the list first, then move to the desired state (see onSetModalState function). To go
      // back to the state that the user came from, the stack will have the listing of the previous state on top and will pop
      // off the top of the stack to move to the previous state and expose the state that came before. Initialized as empty
      // because landing states are considered starting states, users cannot go back beyond the start. This is protected in UI.
      stateHistory = [];
      const {analyticsModalSourcePage, deepLinkingCartItems, landingState} = params;
      analyticsSourcePage = analyticsModalSourcePage;
      setupAndTranslateStateSettings(landingState);

      vm.promiseToWait = setupProductBuckets()
        .then(() => updateDeepLinkingCartItemsAndStateHistory(landingState, deepLinkingCartItems))
        .then((initialState) => {
          setModalState(initialState ? initialState : landingState);
        })
        .catch((error) => {
          $log.error(
            `RenewProductsModal.onOpen(org: ${OrganizationManager.getActiveOrgId()}, contract: ${
              vm.contract.id
            }): ${error}`
          );
          // Only a page banner with a Close button will be visible.
          setModalState(SHOW_PAGE_BANNER);
        })
        .finally(() => {
          vm.showContent = true;
        });
    }

    function onPromoRedeemed(promoOfferId) {
      // Find offer in the promoOfferList and remove it.
      const promoOffer = _.find(vm.promoOffers, {offer_id: promoOfferId});
      _.pull(vm.promoOffers, promoOffer);

      // Find the base offer. It should be in the list of ownedOffers or in the cart.
      // It is possible a baseOffer will not be found if the promo is for a product the org doesn't already own.
      const baseOffer = _(vm.cartItems)
        .concat(vm.ownedOffers)
        .find(['product_arrangement_code', promoOffer.product_arrangement_code]);

      // If there is a base offer and it is in the cart, remove it since it will be replaced.
      if (baseOffer) {
        onRemoveFromCart(baseOffer.offer_id, {dispatchEvent: false});
      }

      // If a base offer exists, carry over assignableLicenseCount and provisionedQuantity to be used for cart item
      // scorecard. numberSelected which is the cart-item quantity should be the original qty, not the reduced
      // qty that triggered the promo offer.
      // If no base offer exists, assign initial values.
      _.assign(promoOffer, {
        assignableLicenseCount: _.get(baseOffer, 'assignableLicenseCount', 0),
        numberSelected: _.get(baseOffer, 'currentQuantity', 1),
        provisionedQuantity: _.get(baseOffer, 'provisionedQuantity', 0),
      });

      // Per the callout in the XD, insert promo at the head of the cart for greater visibility, and update the
      // cart total.
      vm.cartItems.unshift(promoOffer);
      updateTotals();

      if (feature.isEnabled('temp_renewal_analytcs_promos')) {
        const ANALYTICS_CONSTANTS = binkySrc2.services.analytics.ANALYTICS_CONSTANTS;
        const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;
        const {getAnalyticsPromoOfferInteraction} =
          binkySrc2.services.product.productListAnalyticsUtils;

        const promoOffers = {items: [promoOffer]};
        const eventAction = ANALYTICS_CONSTANTS.EVENT_ACTION.CLICK;

        dispatchUiEventAnalytics({
          eventAction,
          eventName: ANALYTICS_CONSTANTS.EVENT_NAME.ACCEPT_OFFER,
          interaction: getAnalyticsPromoOfferInteraction(promoOffers.items, eventAction),
          productList: promoOffers,
        });
      } else {
        AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({
          eventName: 'acceptOffer',
          offer: promoOffer,
        });
      }
    }

    function onRemoveFromCart(offerIdToRemove, {dispatchEvent = true} = {}) {
      const cartItemRemoved = _.remove(vm.cartItems, {offer_id: offerIdToRemove})[0];
      if (cartItemRemoved) {
        const bucketToAddCartItem = _.get(vm, cartItemRemoved.productBucket);

        const sortProperty = ProductPurchaseHelper.getSortPropertyForBucket(
          cartItemRemoved.productBucket
        );
        // get index where the name of the removed offer would be inserted
        const indexToAddOffer = _.sortedIndexBy(bucketToAddCartItem, cartItemRemoved, sortProperty);
        // insert at index without replacement
        bucketToAddCartItem.splice(indexToAddOffer, 0, cartItemRemoved);
        updateTotals();

        if (dispatchEvent) {
          AnalyticsHelper.dispatchAnalyticsClickEventWithProduct({
            eventName: 'removeCartItem',
            offer: cartItemRemoved,
          });
        }
      }

      return !!cartItemRemoved;
    }

    function onStartChatButtonClick() {
      AnalyticsEvent.dispatch({
        descriptors: {
          uiEvent: new UiEventDescriptor({
            eventAction: 'click',
            eventName: 'chat',
            name: 'startChatButtonForTeamInRenewal',
          }),
        },
      });
    }

    ////////

    function dispatchAnalyticsClickEventWithCart({cartHasSubmitted = false, eventName} = {}) {
      if (feature.isEnabled('temp_analytics_cart_src2')) {
        const {dispatchUiEventAnalytics} = binkySrc2.services.analytics.analyticsUtils;

        const {billingData, purchaseAuthId} = getAnalyticsInfoFromCartData({
          cartHasSubmitted,
          cartItems: vm.cartItems,
        });

        dispatchUiEventAnalytics({
          cart: {
            billingData,
            cancellationReasonComment: vm.cancellationReasonComment,
            cancellationReasons: vm.cancellationReason,
            cartId: vm.cart.cartId,
            cartItems: vm.cartItems,
            contract: vm.contract,
            purchaseAuthId,
            total: vm.totalSummary,
          },
          eventAction: 'click',
          eventName,
        });
      } else {
        AnalyticsHelper.dispatchUiEventAnalytics({
          cancellationReasonComment: vm.cancellationReasonComment,
          cancellationReasons: vm.cancellationReason,
          cart: vm.cart,
          cartHasSubmitted,
          cartItems: vm.cartItems,
          contract: vm.contract,
          eventAction: 'click',
          eventName,
          total: vm.totalSummary,
          useRenewalPricing: false,
        });
      }
    }

    /**
     * @description Wrapper around renewal order preview call. This function is debounced
     * and is thus not intended for direct invocation.
     *
     * @param {Array} billableItems - subsection of the request body for CCS
     */
    function fetchBillableItems(billableItems) {
      vm.previewPromise = vm.cart
        .previewRenewalOrder({billableItems})
        .then(onPreviewSuccess, (error) => {
          vm.totalSummary = undefined;
          // Note vm.fetchingCartPreview stays true so that the CTA remains disabled.
          showPageBanner(error);
          // rejection is passed to the footer to show '--'
          return $q.reject();
        });

      ////////////

      // The arg to this is the cartPreviewResponse which contains the billing summary but not the
      // responseBillingSummary.
      function onPreviewSuccess() {
        // to catch the case when the product selection goes to zero while api response is pending
        if (isNumberSelectedZero()) {
          vm.totalSummary = undefined;
          return undefined;
        }

        // Analytics needs this information.
        if (vm.contract.isDirectContract()) {
          vm.totalSummary = vm.cart.getTotalSummary({isRenewal: true});
        }

        vm.fetchingCartPreview = false;

        // Return the cart itself since it contains the transformed billingSummary called responseBillingSummary
        // and return the totalSummary so all components are using the same information.
        return {cart: vm.cart, totalSummary: vm.totalSummary};
      }
    }

    function getNextState() {
      const currentIdx = _.indexOf(RENEW_PRODUCTS_MODAL2_STEPS, vm.modalState.state);
      return RENEW_PRODUCTS_MODAL2_STEPS[currentIdx + 1];
    }

    function hasInvalidInput() {
      return _.chain(vm.cartItems).sumBy('numberSelected').isNaN().value();
    }

    function isNumberSelectedZero() {
      return _.sumBy(vm.cartItems, 'numberSelected') === 0;
    }

    function resetState() {
      _.assign(vm, {
        cancellationReason: undefined,
        cancellationReasonComment: undefined,
        cancellationReasonIsValid: false,
        displayMessage: undefined,
        fetchingCartPreview: false,
        isSubmitting: false,
        previewPromise: undefined,
        promoOffers: false,
        showContent: false,
        totalSummary: undefined,
      });
    }

    /**
     * @description get billingInfo and purchaseAuthId from Cart service Data
     * NOTE: This helper function is a compromise because the Cart service functions are not in React yet.
     *       Ideally it would have went in binky's analyticsUtils.js however we want to avoid calling onesie code from binky.
     *       Adding this helper function into analyticsUtils.js should be consisedered when the cart service react transition happens.
     * @param {Object} options - list of options to customise cart service functions
     * @param {Array} options.cartItems - list of items object that represent items in the cart
     * @param {Boolean} options.cartHasSubmitted - state that determines if the cart has been submitted or not, defaults: false.
     * @param {Boolean} options.useRenewalPricing - state that determines if the cart uses renewal pricing, defaults: false.
     * @returns {Object} - containing billingData and purchaseAuthId
     */
    function getAnalyticsInfoFromCartData({
      cartItems,
      cartHasSubmitted = false,
      useRenewalPricing = false,
    }) {
      const billingData = [];

      _.forEach(cartItems, (cartItem) => {
        const cartItemBillingData = vm.cart.getBillingDataForOffer({
          hasSubmitted: cartHasSubmitted,
          offerId: cartItem.offer_id,
          useRenewalPricing,
        });

        billingData.push(cartItemBillingData);
      });

      const purchaseAuthId = vm.cart.getPurchaseAuthId();

      return {billingData, purchaseAuthId};
    }

    function setModalState(landingState) {
      const modalState = getValidModalState(landingState);

      vm.modalState = {
        settings: _.find(vm.translatedStateSettings, {state: modalState}),
        state: modalState,
      };

      $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, vm.RENEW_PRODUCTS_MODAL2_ID);
      _.invoke(vm.modalState.settings, 'initStateFn');

      dispatchPageAnalyticsWithCart();

      // The modalState may have changed
      // Firing onModalStateChange to signal possible change
      _.invoke(vm, 'onModalStateChange', {modalState: vm.modalState.state});

      ////////
      // eslint-disable-next-line complexity -- due to feature flags
      function dispatchPageAnalyticsWithCart() {
        if (feature.isEnabled('temp_analytics_cart_src2')) {
          const eventAction = 'load';
          const {dispatchPageAnalytics} = binkySrc2.services.analytics.analyticsUtils;
          const {getAnalyticsPromoOfferInteraction} =
            binkySrc2.services.product.productListAnalyticsUtils;

          const {billingData, purchaseAuthId} = getAnalyticsInfoFromCartData({
            cartItems: vm.cartItems,
          });

          const analyticsPageOptions = {
            cart: {
              billingData,
              cancellationReasonComment: vm.cancellationReasonComment,
              cancellationReasons: vm.cancellationReason,
              cartId: vm.cart.cartId,
              cartItems: vm.cartItems,
              contract: vm.contract,
              purchaseAuthId,
              total: vm.totalSummary,
            },
            eventAction,
            eventName: vm.modalState.settings.analytics.eventName,
            interaction:
              feature.isEnabled('temp_renewal_analytcs_promos') &&
              vm.promoOffers &&
              landingState === RENEW_PRODUCTS_MODAL2_STATES.SELECT_SPECIAL_OFFERS
                ? getAnalyticsPromoOfferInteraction(vm.promoOffers, eventAction)
                : undefined,
            name: vm.modalState.settings.analytics.pageName,
            pageName: vm.modalState.settings.analytics.pageName,
            productList:
              feature.isEnabled('temp_renewal_analytcs_promos') &&
              vm.promoOffers &&
              landingState === RENEW_PRODUCTS_MODAL2_STATES.SELECT_SPECIAL_OFFERS
                ? {items: vm.promoOffers}
                : undefined,
          };

          dispatchPageAnalytics(analyticsPageOptions);
        } else {
          const eventAction = 'load';
          AnalyticsHelper.dispatchPageAnalytics({
            cancellationReasonComment: vm.cancellationReasonComment,
            cancellationReasons: vm.cancellationReason,
            cart: vm.cart,
            cartItems: vm.cartItems,
            contract: vm.contract,
            eventAction: feature.isEnabled('temp_renewal_analytcs_promos')
              ? eventAction
              : undefined,
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? vm.modalState.settings.analytics.eventName
              : undefined,
            interaction:
              feature.isEnabled('temp_renewal_analytcs_promos') && vm.promoOffers
                ? binkySrc2.services.product.productListAnalyticsUtils.getAnalyticsPromoOfferInteraction(
                    vm.promoOffers,
                    eventAction
                  )
                : undefined,
            pageName: vm.modalState.settings.analytics.pageName,
            productList:
              feature.isEnabled('temp_renewal_analytcs_promos') && vm.promoOffers
                ? {items: vm.promoOffers}
                : undefined,
            total: vm.totalSummary,
            useRenewalPricing: false,
          });
        }
      }

      function getValidModalState(state) {
        return _.includes(RENEW_PRODUCTS_MODAL2_STEPS, state) ? state : VIEW_RENEWAL_ORDER;
      }
    }

    function savePONumber() {
      vm.isSubmitting = true;
      const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
      vm.waitPromise = vm.cart
        .submitRenewalOrder({
          billableItems,
          ownerId: vm.contract.getOwnerUserId(),
          poNumber: vm.poNumber,
        })
        .then(onSaveSuccess, showPageBanner)
        .finally(() => {
          vm.isSubmitting = false;
        });

      ////////////

      function onSaveSuccess() {
        toastManager.showSuccessToast(
          $translate.instant('widgets.products.purchase.renewProductsModal.toast.poNumber')
        );

        onClose({dispatchEvent: false});
      }
    }

    // eslint-disable-next-line complexity -- due to feature flags
    function setupAndTranslateStateSettings(landingState) {
      /**
       * | state                      | cancel btn | back btn | cta btn      |
       * |----------------------------|------------|----------|--------------|
       * | VIEW_RENEWAL_ORDER         | Close      | N/A      | Update plan  |
       * | MODIFY_RENEWAL_ORDER       | Close      | N/A      | Continue     |
       * | SELECT_CANCELLATION_REASON | Close      | Previous | Continue     |
       * | SELECT_SPECIAL_OFFERS      | Close      | Previous | Continue     |
       * | REVIEW_RENEWAL_ORDER       | Close      | Previous | Save changes |
       * | SHOW_PAGE_BANNER           | Close      | N/A      | N/A          |
       */
      const STATE_SETTINGS = [
        {
          analytics: {
            ctaButtonEventName: 'updatePlan',
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewalDetail'
              : undefined,
            pageName: 'renewDetail',
          },
          backBtn: undefined,
          ctaBtn: vm.contract.isPaymentCategoryPO() ? 'buttons.save' : 'buttons.updatePlan',
          ctaDisabled: _.stubFalse,
          isCartReadOnly: true,
          modalTitle: 'title.viewRenewalDetails',
          showCartTotalFooter: _.stubFalse,
          showTotalInCart: true,
          state: VIEW_RENEWAL_ORDER,
          updatePlanButton: vm.contract.isPaymentCategoryPO() ? 'buttons.updatePlan' : undefined,
        },
        {
          analytics: {
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewProductSelection'
              : undefined,
            pageName: 'renewProductSelection',
          },
          backBtn: undefined,
          ctaBtn: 'buttons.continue',
          ctaDisabled: () => vm.isSubmitting || vm.fetchingCartPreview,
          isCartReadOnly: false,
          modalTitle: 'title.updateRenewalPlan',
          showCartTotalFooter: vm.showCartTotalInFooter,
          showTotalInCart: false,
          state: MODIFY_RENEWAL_ORDER,
        },
        {
          analytics: {
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewCancelReason'
              : undefined,
            pageName: 'renewCancelReason',
          },
          backBtn: 'buttons.previous',
          ctaBtn: 'buttons.continue',
          ctaDisabled: () => !vm.cancellationReasonIsValid || vm.fetchingCartPreview,
          heading: () => {
            const firstName = _.result(AuthenticatedUser.get(), 'getFirstName');
            return {
              key: _.isEmpty(firstName)
                ? 'heading.cancellationReason'
                : 'heading.cancellationReasonWithFirstName',
              values: {firstName},
            };
          },
          isCartReadOnly: true,
          modalTitle: 'title.updateRenewalPlan',
          showCartTotalFooter: vm.showCartTotalInFooter,
          showTotalInCart: false,
          state: SELECT_CANCELLATION_REASON,
        },
        {
          analytics: {
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewSpecialOffer'
              : undefined,
            pageName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'account:offerPage'
              : 'renewSpecialOffer',
          },
          backBtn: 'buttons.previous',
          ctaBtn: 'buttons.continue',
          ctaDisabled: () => vm.fetchingCartPreview,
          heading: () => ({key: 'heading.specialOffers'}),
          isCartReadOnly: false,
          modalTitle: 'title.updateRenewalPlan',
          showCartTotalFooter: vm.showCartTotalInFooter,
          showTotalInCart: false,
          state: SELECT_SPECIAL_OFFERS,
        },
        {
          analytics: {
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewReviewOrder'
              : undefined,
            pageName: 'renewReviewOrder',
          },
          backBtn: 'buttons.previous',
          ctaBtn: 'buttons.saveChanges',
          ctaDisabled: () => vm.isSubmitting || vm.fetchingCartPreview,
          heading: () => ({key: 'heading.reviewOrder'}),
          isCartReadOnly: true,
          modalTitle: 'title.updateRenewalPlan',
          showCartTotalFooter: _.stubFalse,
          showTotalInCart: true,
          state: REVIEW_RENEWAL_ORDER,
        },
        {
          // Only modal title and a page banner with a Close button will be visible.
          analytics: {
            eventName: feature.isEnabled('temp_renewal_analytcs_promos')
              ? 'renewShowError'
              : undefined,
            pageName: 'renewShowError',
          },
          backBtn: undefined,
          ctaBtn: undefined,
          heading: undefined,
          initStateFn: showPageBanner,
          modalTitle:
            landingState === MODIFY_RENEWAL_ORDER
              ? 'title.updateRenewalPlan'
              : 'title.viewRenewalDetails',
          state: SHOW_PAGE_BANNER,
        },
      ];

      vm.translatedStateSettings = translateStateSettings(STATE_SETTINGS);
    }

    function setupProductBuckets() {
      vm.offerList = OrganizationManager.getOffersForActiveOrg(
        {
          intent: OFFER_LIST_INTENT.AAC_OFFER_DISCOVERY_RENEWAL,
        },
        vm.contract.id
      );

      vm.cart = Cart.get({
        cartType: CART_TYPE.RENEWAL,
        contractId: vm.contract.id,
        lastModifiedById: AuthenticatedUser.get().getId(),
      });

      return $q.all([vm.cart.$promise, vm.offerList.$promise]).then(() => {
        const productList = OrganizationManager.getProductsForActiveOrg();
        vm.ownedProductItems = _.filter(productList.items, {
          contractId: vm.contract.id,
        });
        // filter the offer list items to include only those which can have a PA created for them
        vm.offerList.filterOnCanCreatePurchaseAuthorization();

        const productBuckets = ProductPurchaseHelper.getProductBuckets({
          cartItems: vm.cart.getBillableItemsAsCartItems(),
          offerList: vm.offerList,
          productItems: vm.ownedProductItems,
        });

        _.assign(
          vm,
          _.pick(productBuckets, [
            'cartItems',
            'ownedOffers',
            'unownedWithoutRecOffers',
            'unownedWithRecOffers',
          ])
        );

        // Initial count of the owned offers. This should stay constant as offers are moved between the
        // ownedOffer bucket/section and the shopping cart. In renewal workflow, all owned offers should be in cart
        // on open
        vm.ownedOfferCount = _.size(vm.cartItems);

        // Save the total and cartItems that were in place on entry.
        // This may be different than vm.contract.getNextBillingAmountInfo() if the renewal order has already been
        // modified and this is a re-entry into Update Plan.
        initialRenewalTotal = _.get(
          vm.cart,
          RENEW_PRODUCTS_MODAL2_CART_PROPS.responseBillingSummaryData.total
        );
        initialRenewalCartItems = _.cloneDeep(vm.cartItems);
        vm.poNumber = vm.cart.getPONumber();
        // Analytics needs this information.
        vm.totalSummary = vm.cart.getTotalSummary({isRenewal: true});

        // Renewal order has been retrieved by Cart.get, we are using the previewPromise to pass the cart
        // to the components that are waiting for the previewPromise to be resolved.
        vm.previewPromise = $q.resolve({cart: vm.cart, totalSummary: vm.totalSummary});
      });
    }

    // If there is a Sophia campaign for ARRDroppedPercentage go to SELECT_CANCELLATION_REASON else
    // go to REVIEW_RENEWAL_ORDER.
    function setNextStateAfterModifyRenewalOrder() {
      const ARRDroppedPercentage = ProductPurchaseHelper.getARRDroppedPercentageForRenewalOrder({
        currentTotal: initialRenewalTotal,
        newTotal: _.get(vm.cart, RENEW_PRODUCTS_MODAL2_CART_PROPS.responseBillingSummaryData.total),
      });

      let hasRenewalCampaign = false;

      vm.isSubmitting = true;
      vm.promiseToWait = SophiaHelper.getSophiaCards({
        contextualParamsOptions: {ARRDroppedPercentage},
        surfaceId: SURFACE_ID.ONE_CONSOLE_RENEWAL,
      })
        .then((sophiaCardList) => {
          const cards = sophiaCardList.items;
          if (!_.isEmpty(cards) && SophiaHelper.isDefaultRenewalCampaign(cards[0])) {
            SophiaHelper.onDisplayCard(cards, 0);
            hasRenewalCampaign = true;
          }
        })
        .finally(() => {
          // default to skipping the SELECT_CANCELLATION_REASON state
          const nextState = hasRenewalCampaign ? getNextState() : REVIEW_RENEWAL_ORDER;
          setModalState(nextState);
          vm.isSubmitting = false;
        });
    }

    function setNextStateAfterSelectCancellationReason() {
      const droppedPercentageForOffers = ProductPurchaseHelper.getDroppedPercentageForOffers(
        vm.cartItems,
        initialRenewalCartItems
      );

      // PORES API spec https://wiki.corp.adobe.com/x/Q_Jjag
      vm.isSubmitting = true;
      const promoOfferList = OrganizationManager.getOffersForActiveOrg(
        {
          intent: OFFER_LIST_INTENT.AAC_SELF_SERVICE_PROMOS,
          offerARRDroppedPercentage: droppedPercentageForOffers, // could be empty string if all offers are promos
          offerQuantityDroppedPercentage: droppedPercentageForOffers,
          reason: vm.cancellationReason.join(','),
        },
        vm.contract.id
      );
      // Handling vm.cancellationReasonComment to be implemented in https://jira.corp.adobe.com/browse/ONESIE-16283

      const waitForPromiseCollection = [];

      waitForPromiseCollection.push(
        promoOfferList.$promise.finally(() => {
          // On failure, vm.promoOffers will be an empty.
          vm.promoOffers = promoOfferList.items;
        })
      );

      // Determine if there is an active campaign that removes the chat banner on the special offers modal page. If a campaign
      // is found, the banner should not be rendered to the user. The existence of a campaign only determines if the static card
      // should be rendered or not - it does not vary the content or design of the chat banner itself.
      //
      // This chat banner is usually rendered under the list of offers presented in <app-promo-catalog-table />.
      //
      // Note that the default behavior is to display the chat banner.
      const ARRDroppedPercentage = ProductPurchaseHelper.getARRDroppedPercentageForRenewalOrder({
        currentTotal: initialRenewalTotal,
        newTotal: _.get(vm.cart, RENEW_PRODUCTS_MODAL2_CART_PROPS.responseBillingSummaryData.total),
      });

      vm.showPromoChatBanner = true;

      // Determine if this user is in a campaign that will suppress the renewal promo chat banner.
      const waitForRenewalPromoChatResolve = fetchRenewalPromoCard({ARRDroppedPercentage}).then(
        (isInCampaign) => {
          vm.showPromoChatBanner = isInCampaign === false;
        }
      );
      waitForPromiseCollection.push(waitForRenewalPromoChatResolve.promise);

      vm.promiseToWait = $q.all(waitForPromiseCollection).finally(() => {
        const hasPromoOffers = !_.isEmpty(vm.promoOffers);
        if (hasPromoOffers) {
          setupPromoOffers();
        }

        const nextState = hasPromoOffers ? getNextState() : REVIEW_RENEWAL_ORDER;
        setModalState(nextState);
        vm.isSubmitting = false;
      });

      /////////

      function setupPromoOffers() {
        // Remove any promo offers that are already in the cart. This can happen if the renewal order already contains
        // a promo or if the admin navigates forward to the SELECT_SPECIAL_OFFERS view, adds a promo to the cart and
        // then navigates back and then forward again to the SELECT_SPECIAL_OFFERS view which would re-populate the
        // promos.
        _.pullAllBy(vm.promoOffers, vm.cartItems, 'offer_id'); // eslint-disable-line lodash/collection-method-value

        _.forEach(vm.promoOffers, (offer) => {
          offer.productBucket = PRODUCT_BUCKET.PROMO_OFFERS;
          offer.ignoreOfferMapping = true; // for billable items
        });
      }

      function fetchRenewalPromoCard(contextualParamsOptions) {
        // Return a promise that will resolve true if a Sophia card is returned on the `one_console_renewal_promo_chat`
        // surface based on the contextual parameters provided. Note that this promise will always resolve to ensure
        // that the state change to the REVIEW_RENEWAL_ORDER state continues.
        return $q((resolve) => {
          SophiaHelper.getSophiaCards({
            contextualParamsOptions,
            surfaceId: SURFACE_ID.ONE_CONSOLE_RENEWAL_PROMO_CHAT,
          })
            .then((sophiaCardList) => {
              const cards = sophiaCardList.items;
              if (!_.isEmpty(cards) && SophiaHelper.isDefaultRenewalCampaign(cards[0])) {
                SophiaHelper.onDisplayCard(cards, 0);
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(false);
            });
        });
      }
    }

    function showCartTotalInFooter() {
      return !isNumberSelectedZero();
    }

    function showPageBanner(options = {}) {
      const {ctaText, message, showDefaultButton = false, title, variant} = options;
      vm.displayMessage = new DisplayMessage({
        body: message,
        ctaText,
        header: title,
        showDefaultButton,
        variant,
      });
      // Make sure the message is visible.
      $rootScope.$emit(COMMON_EVENTS.SCROLL_TO_TOP, RENEW_PRODUCTS_MODAL2_ID);
    }

    function showPONumberDismissModal() {
      const state = _.get(vm, 'modalState.state');
      return (
        vm.contract.isDirectContract() &&
        vm.contract.isPaymentCategoryPO() &&
        state === RENEW_PRODUCTS_MODAL2_STATES.VIEW_RENEWAL_ORDER
      );
    }

    function submit() {
      vm.isSubmitting = true;
      const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
      vm.waitPromise = vm.cart
        .submitRenewalOrder({
          billableItems,
          ownerId: vm.contract.getOwnerUserId(),
          poNumber: vm.poNumber,
        })
        .then(onSubmitSuccess, showPageBanner)
        .finally(() => {
          vm.isSubmitting = false;
        });

      ////////////

      function onSubmitSuccess() {
        toastManager.showSuccessToast(
          $translate.instant('widgets.products.purchase.renewProductsModal.toast.success')
        );

        dispatchAnalyticsClickEventWithCart({cartHasSubmitted: true, eventName: 'completeOrder'});

        onClose({dispatchEvent: false});
      }
    }

    function translateStateSettings(stateSettings) {
      return _.map(stateSettings, (stateSetting) => {
        const {
          analytics,
          backBtn,
          ctaBtn,
          ctaDisabled,
          initStateFn,
          isCartReadOnly,
          modalTitle,
          showCartTotalFooter,
          showTotalInCart,
          state,
          updatePlanButton,
        } = stateSetting;
        const headingResult = _.result(stateSetting, 'heading');
        return {
          analytics,
          backBtn: translateWhenDefined({key: backBtn}),
          ctaBtn: translateWhenDefined({key: ctaBtn}),
          ctaDisabled,
          heading: translateWhenDefined(headingResult),
          initStateFn,
          isCartReadOnly,
          modalTitle: translateWhenDefined({key: modalTitle}),
          showCartTotalFooter,
          showTotalInCart,
          state,
          updatePlanButton: translateWhenDefined({key: updatePlanButton}),
        };
      });

      ////////

      function translateWhenDefined({key, values} = {}) {
        return key
          ? $translate.instant(`widgets.products.purchase.renewProductsModal.${key}`, values)
          : undefined;
      }
    }

    /**
     * Update cart items with initial used selection. This method should be called after setupProductBuckets
     * which will initialize renewal cart and internal variables associated. This method will mimic the user
     * selection for the deep linking cart items as well as updating the state history accordingly.
     * @param {RENEW_PRODUCTS_MODAL2_STATES} landingState - the landing state for the modal
     *   Defaults to VIEW_RENEWAL_ORDER.
     * @param {Array} deepLinkingCartItems - an array of cart items to be populated to the shopping cart.
     *    The cart item is an object containing offerId, productArrangementCode, and quantity (it must contain either
     *    offerId or productArrangementCode). We'll look up the offer by offerId first, and then fallback to using the productArrangementCode.
     *    If undefined, the shopping cart will be empty. All items should be for the same CUSTOMER_SEGMENT.
     *
     *    Example: [{
     *                 offerId: '0159BEA90DDD83C74FC928EE249071B1',
     *                 productArrangementCode: 'ilst_direct_indirect_team',
     *                 quantity: 2
     *              }, {
     *                 offerId: '2FF660A1769B01C1D8E0CF2A438B08E8',
     *                 productArrangementCode: 'phsp_direct_indirect_team',
     *                 quantity: 5
     *              }]
     * @return {Promise} the preview promise triggered by vm.cart.previewRenewalOrder() or undefined if
     *    deepLinkingCartItems is empty
     */
    function updateDeepLinkingCartItemsAndStateHistory(landingState, deepLinkingCartItems) {
      let deepLinkingLandingState = landingState;

      if (landingState !== VIEW_RENEWAL_ORDER && !_.isEmpty(deepLinkingCartItems)) {
        // Try to Update productBuckets to reflect deep linking cart items selection
        try {
          // Calculate new buckets based on deep linking cart items
          const productBuckets = ProductPurchaseHelper.getProductBuckets({
            cartItems: deepLinkingCartItems,
            offerList: vm.offerList,
            productItems: vm.ownedProductItems,
          });

          _.assign(
            vm,
            _.pick(productBuckets, [
              'cartItems',
              'ownedOffers',
              'unownedWithoutRecOffers',
              'unownedWithRecOffers',
            ])
          );

          // If landing state is not one of the initial states, we need to push MODIFY_RENEWAL_ORDER
          // as previous state, so navigation will continue working
          if (deepLinkingLandingState !== MODIFY_RENEWAL_ORDER) {
            stateHistory.push(MODIFY_RENEWAL_ORDER);
          }

          // Update totals to reflect deep linking cart items selection.
          // This will trigger onCartItemsChange
          updateTotals();

          // Immediately invoke the fetchBillableItems method to get a new promise, for vm.previewPromise.
          // debouncedFetchBillableItems was called inside updateTotals
          debouncedFetchBillableItems.flush();

          // Return previewPromise and then resolve with deepLinkingLandingState
          return vm.previewPromise.then(() => deepLinkingLandingState);
        } catch (error) {
          // If ProductPurchaseHelper.getProductBuckets throw an error when a cart item was not matched,
          // we'll fall back to the renewal cart items already set, instead of rendering an error and
          // land at MODIFY_RENEWAL_ORDER with default cart items
          deepLinkingLandingState = MODIFY_RENEWAL_ORDER;
        }
      }

      _.invoke(vm, 'onCartItemsChange', {cartItems: vm.cartItems});

      return deepLinkingLandingState;
    }

    function updateTotals() {
      vm.displayMessage = undefined;
      // Setting to true here because we don't want it in the async/debounce call and will also disable the CTA.
      vm.fetchingCartPreview = true;

      if (isNumberSelectedZero()) {
        showCancelPlanBanner();
      } else if (hasInvalidInput()) {
        vm.previewPromise = $q.reject();
      } else {
        const billableItems = Cart.constructBillableItemsFromOffers(vm.cartItems);
        debouncedFetchBillableItems(billableItems);
      }

      // The cart items or the quantities may have changed
      // Firing onCartItemsChange to signal possible change
      _.invoke(vm, 'onCartItemsChange', {cartItems: vm.cartItems});

      ///////////

      function showCancelPlanBanner() {
        const message = $translate.instant(
          'widgets.products.purchase.renewProductsModal.banners.cancelEntirePlan.message'
        );
        const title = $translate.instant(
          'widgets.products.purchase.renewProductsModal.banners.cancelEntirePlan.title'
        );
        showPageBanner({
          message,
          title,
          variant: DISPLAY_MESSAGE_VARIANT.INFO,
        });
      }
    }
  }
})();
/* eslint-enable max-lines */
