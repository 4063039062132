(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc component
   * @name app.widgets.support:appSupportSummarySubPage
   * @description renders the content of a Support Summary page tab, including the buttons and
   *   popular help topics.
   *
   * @binding {SUPPORT_TICKET_CASE_TYPE} caseType - what kind of case this tab is about
   * @binding {String} [cloud] - what product cloud this tab is about. Applies only if caseType is
   *   SUPPORT_TICKET_CASE_TYPE.PRODUCT.
   * @binding {String} helpGoUrl - the go URL to use in the "Browse Adobe Support..." link
   */
  angular.module('app.widgets.support').component('appSupportSummarySubPage', {
    bindings: {
      caseType: '@',
      cloud: '@?',
      helpGoUrl: '@',
    },
    controller,
    templateUrl:
      'app/widgets/support/support-summary-sub-page/support-summary-sub-page.component.html',
    transclude: {'help-topics-slot': 'helpTopics'},
  });
  function controller(_, $rootScope) {
    const vm = this;

    $rootScope.$emit('UPDATE_CLOUD_TYPE', vm.cloud);
  }
})();
