import {Product} from '@admin-tribe/binky';
import {Text, View} from '@adobe/react-spectrum';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import UserGroupLinkMessage from 'common/components/user-group-link-message/UserGroupLinkMessage';

import styles from './ProductDetailAccordion.pcss';

/**
 * An accordion to hold the licenses groups for a product.
 * The license groups are displayed as listed. No sorting is done.
 * This will render nothing if there are no license groups.
 */
const ProductDetailAccordion = observer(
  ({'aria-labelledby': ariaLabelledby, ariaLevel = 3, product}) => {
    const intl = useIntl();

    const getAccordionHeader = () =>
      intl.formatMessage(
        {id: 'common.productsDetailSection.label'},
        {count: product.licenseGroups.length}
      );

    return (
      product?.licenseGroups?.length > 1 && (
        /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- make quiet */
        <View UNSAFE_className={styles.accordion}>
          <Accordion aria-labelledby={ariaLabelledby} ariaLevel={ariaLevel}>
            <AccordionItem header={getAccordionHeader()}>
              {product.licenseGroups.map((licenseGroup) => (
                <View key={licenseGroup.id} marginBottom="size-100">
                  <Text>{licenseGroup.name}</Text>
                  {licenseGroup?.userGroup && (
                    <View data-testid="license-group-user-group">
                      <UserGroupLinkMessage
                        msgKeyNamespace="common.productsDetailSection"
                        userGroup={licenseGroup.userGroup}
                      />
                    </View>
                  )}
                </View>
              ))}
            </AccordionItem>
          </Accordion>
        </View>
      )
    );
  }
);

ProductDetailAccordion.propTypes = {
  /**
   * Id of the label for the Accordion.
   */
  'aria-labelledby': PropTypes.string.isRequired,
  /**
   * The aria level for the Accordion. The default is 3. The Accordion Item aria level will be
   * one more than this.
   */
  ariaLevel: PropTypes.number,
  /**
   * The product which should have at least one license group.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default ProductDetailAccordion;
