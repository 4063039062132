import {INVOICE_STATUS, Invoice} from '@admin-tribe/binky';
import {StatusLight} from '@pandora/react-status-light';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Checks payment status of an invoice and displays an appropriate label and color
 */
const InvoiceStatus = ({invoice}) => {
  const intl = useIntl();
  let label, variant;

  // Determines invoice payment status label
  switch (invoice.invoiceStatus) {
    case INVOICE_STATUS.CLOSED:
      label = intl.formatMessage({id: 'account.billingHistory.invoiceStatus.closed'});
      variant = 'positive';
      break;
    case INVOICE_STATUS.OPEN:
      label = intl.formatMessage({id: 'account.billingHistory.invoiceStatus.open'});
      variant = 'positive';
      break;
    case INVOICE_STATUS.PAST_DUE:
      label = intl.formatMessage(
        {id: 'account.billingHistory.invoiceStatus.pastDue'},
        {count: invoice.noOfDays}
      );
      variant = 'negative';
      break;
    case INVOICE_STATUS.PAYMENT_DUE:
      label = intl.formatMessage(
        {id: 'account.billingHistory.invoiceStatus.paymentDue'},
        {count: invoice.noOfDays}
      );
      variant = 'notice';
      break;
    case INVOICE_STATUS.PROCESSING:
      label = intl.formatMessage({id: 'account.billingHistory.invoiceStatus.processing'});
      variant = 'notice';
      break;
    case INVOICE_STATUS.PAID:
    default:
      label = intl.formatMessage({id: 'account.billingHistory.invoiceStatus.paid'});
      variant = 'positive';
      break;
  }

  return <StatusLight variant={variant}>{label}</StatusLight>;
};

InvoiceStatus.propTypes = {
  /**
   * Invoice that contains payment status information to display
   */
  invoice: PropTypes.instanceOf(Invoice),
};

export default InvoiceStatus;
