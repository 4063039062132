import {GoUrl} from '@admin-tribe/binky-ui';
import {Image} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {PRICE_FAILURE} from '../SelfCancelConstants';
import fullCancelIcon from '../assets/cc_appicon_256.svg';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelDoneModal from '../components/self-cancel-done-modal/SelfCancelDoneModal';
import useProductsChangeConfirmation from '../hooks/use-products-change-confirmation/useProductsChangeConfirmation';

/**
 * Self cancel workflow step component used as a final success confirmation when the user performs a
 * full cancel of all licenses.
 */
const DoneFullCancelStep = ({onAddProducts, onDone, onManageUsers}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();
  const {confirmationNumber, licenseCount, nextBillingAmount} = useProductsChangeConfirmation();

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.DONE});
    onDone?.(nextBillingAmount);
  };

  return (
    <SelfCancelDoneModal
      bubbleContent={intl.formatMessage(
        {id: 'account.selfCancel.doneFullCancel.bubbleText'},
        {
          goUrl: (linkText) => (
            <GoUrl name="free_cc_membership" noWrap={false}>
              {linkText}
            </GoUrl>
          ),
        }
      )}
      bubbleImage={<Image alt="" height="192px" marginTop="size-300" src={fullCancelIcon} />}
      heading={intl.formatMessage({id: 'account.selfCancel.doneFullCancel.title'})}
      onAddProducts={onAddProducts}
      onCta={onCta}
      onManageUsers={onManageUsers}
      successMessage={intl.formatMessage(
        {id: 'account.selfCancel.doneFullCancel.description'},
        {confirmationNumber: confirmationNumber || PRICE_FAILURE, licenseCount}
      )}
    />
  );
};

DoneFullCancelStep.propTypes = {
  /**
   * Handler that is called when the user clicks on Add Products link.
   */
  onAddProducts: PropTypes.func,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onDone: PropTypes.func,
  /**
   * Handler that is called when the user clicks on Manage Users.
   */
  onManageUsers: PropTypes.func,
};

export default DoneFullCancelStep;
