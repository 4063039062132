import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';

import DeviceListTable from 'features/products/device-licenses/components/device-list/device-list-table/DeviceListTable';
import useDeviceList from 'features/products/hooks/useDeviceList';

const TABLE_NAMESPACE = 'products.deviceLicenses.deviceDetails.details';

/**
 * Defines the list of devices
 */
const DeviceList = ({
  isSdl,
  productId,
  licenseGroupId,
  showIPAddress = true,
  totalLicensesCount,
}) => {
  const {
    currentPage,
    deviceList,
    goToNextPage,
    goToPrevPage,
    isLoading,
    hasLoadingError,
    loadInitialData,
    onPageSizeChange,
    search,
    sortBy,
    filterByStatus,
    tableOptions,
  } = useDeviceList({
    licenseGroupId,
    productId,
  });

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          search(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
          goToNextPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPrevPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          sortBy(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED:
          loadInitialData();
          break;
        case TABLE_SECTION_ACTIONS.FILTER_BY_STATUS:
          filterByStatus(payload);
          break;
        default:
          // Do nothing
          break;
      }
    },
    [filterByStatus, loadInitialData, search, goToNextPage, goToPrevPage, onPageSizeChange, sortBy]
  );

  // Get the list of devices
  useEffect(() => {
    loadInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- this will run only during the initial render.
  }, []);

  return (
    <DeviceListTable
      deviceList={deviceList}
      hasLoadingError={hasLoadingError}
      isLoading={isLoading}
      isSdl={isSdl}
      licenseGroupId={licenseGroupId}
      namespace={TABLE_NAMESPACE}
      onTableSectionChange={onTableSectionChange}
      pageNumber={currentPage}
      productId={productId}
      showIPAddress={showIPAddress}
      tableOptions={tableOptions}
      totalLicensesCount={totalLicensesCount}
    />
  );
};

DeviceList.propTypes = {
  /**
   * isSdl - Flag which indicates if product is sdl or not
   */
  isSdl: PropTypes.bool,

  /**
   * licenseGroupId - Id of the current license group
   */
  licenseGroupId: PropTypes.string,

  /**
   * productId - Id of the current product
   */
  productId: PropTypes.string.isRequired,

  /**
   * showIPAddress - Flag which indicates to show/hide the IP Address column in the Device Table
   */
  showIPAddress: PropTypes.bool,

  /**
   * The total number of licenses. This will be initially undefined until it is resolved
   */
  totalLicensesCount: PropTypes.number,
};

export default DeviceList;
