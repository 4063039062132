const ANALYTICS_MODAL_NAME = 'unassignedLicensesSummaryModal';

/**
 * Generates an object containing products that are more compliant with analytics structure.
 * @param {Object} products - containing a collection of Items
 *        {Array<Product>} products.items - list of products
 * @returns {Object} A representation of the ProductList that can be interpreted by the ProductListDescriptor
 *          {Object.items} a representation of a Product that can be interpreted by the ProductDescriptor
 */
const mapProductsToAnalytics = (products) => {
  if (!products?.items?.length > 0) {
    return {items: []};
  }

  return {
    items: products.items.map((product) => ({
      licenseCount: product.getSeatBasedAssignableLicenseCount(),
      numberUnassigned: product?.getAvailableLicenseCount?.(),
      offer_id: product?.id,
    })),
  };
};

export {ANALYTICS_MODAL_NAME, mapProductsToAnalytics};
