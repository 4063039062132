(function () {
  /**
   * @deprecated should use the React Spectrum Button
   *
   * @ngdoc directive
   * @name app.widgets.common.links.button-link.directive:appButtonLink
   *
   * @description Directive to create a button link in the application. Note:
   *   this implementation currently uses one-way binding that only watches
   *   the link binding, so any changes to other bindings will not be detected
   *   and the button will not change accordingly. If additional bindings need
   *   to respond to runtime changes, simply follow the example on the link
   *   binding, add a watch expression for the values to track, and update code
   *   to regenerate button and compile contents again. (then, update this
   *   description!)
   *
   * @param {Boolean} [block] - Expands the button to the full width of the
   *   parent. Default: false
   * @param {Boolean} [disabled] - Renders button inactive. Default: false
   * @param {String} [icon] - Specifies the icon name used inside the button. See
   *   Coral.Icon for valid icon names. Default: '' (no icon)
   * @param {String} [iconPosition] - Position of the icon relative to the label.
   *   If no iconPosition is provided, it will be set on the left side by
   *   default. Only applicable if icon value passed in. Default: left
   * @param {String} [iconSize] - Size of the icon. It accepts both lower and
   *   upper case sizes. Default: Coral.Icon.size.SMALL
   * @param {Link} link - Model representing the link contained in this button.
   *   Required.
   * @param {Boolean} [selected] - Whether the button is selected. Default: false
   * @param {String} [size] - The size of the button. It accepts both lower and
   *   upper case sizes. Currently only "M" (the default) and "L" are available.
   *   Default: Coral.Button.size.MEDIUM
   * @param {String} [variant] - The button's variant. Default:
   *   Coral.Button.variant.SECONDARY
   */

  angular
    .module('app.widgets.common.links.button-link')
    .directive('appButtonLink', appButtonLinkDirective);

  function appButtonLinkDirective($compile, _, AnalyticsEvent, translationUtils) {
    const OPTIONAL_ATTRIBUTES = [
      'block',
      'disabled',
      'icon',
      'iconPosition',
      'iconSize',
      'selected',
      'size',
      'variant',
    ];

    const directive = {
      link: linkFn,
      restrict: 'E',
      scope: {
        block: '<?',
        disabled: '<?',
        icon: '@?',
        iconPosition: '@?',
        iconSize: '@?',
        link: '<',
        selected: '<?',
        size: '@?',
        variant: '@?',
      },
    };

    return directive;

    /////////////////

    function linkFn(scope, element) {
      // create click handler function
      _.assign(scope, {clickHandler});

      // initialize button link
      createButtonLink();

      // if our data changes, recreate the button link
      scope.$watch('link', createButtonLink);

      ////////

      function clickHandler($event) {
        scope.link.goToLink();

        triggerAnalytics();
        // we stop the event from bubbling up any further
        $event.stopPropagation();
      }

      function triggerAnalytics() {
        AnalyticsEvent.dispatch({
          attributes: {
            goLinkName: scope.link.locator,
          },
          componentMethod: 'goToLink',
          componentMethodType: 'click',
          componentName: 'appButtonLink',
        });
      }

      function createButtonLink() {
        const coralButton = getCoralButton();

        element.empty().append(coralButton);

        $compile(element.contents())(scope);

        function getCoralButton() {
          const button = new Coral.Button().set(
            _.assign(
              {
                label: {
                  innerHTML: translationUtils.sanitizeSafeInstant(
                    scope.link.key,
                    scope.link.translateValues,
                    scope.link.translateInterpolation
                  ),
                },
              },
              _.pick(scope, OPTIONAL_ATTRIBUTES)
            )
          );

          button.setAttribute('ng-click', 'clickHandler($event)');

          scope.link
            .isAvailable()
            .then((availability) => {
              scope.isAvailable = availability;
            })
            .catch((error) => error);
          button.setAttribute('ng-if', 'isAvailable');

          return button;
        }
      }
    }
  }
})();
