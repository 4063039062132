import {View} from '@adobe/react-spectrum';
import React from 'react';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';

import OverviewPage from '../overview/OverviewPage';

import AccountPageWrapper from './AccountPageWrapper';

/**
 * The Account overview page
 */
const AccountOverviewPage = () => {
  const overviewTitleKey = 'account.overview.page.title';

  useDocumentTitle({key: overviewTitleKey});
  usePageAnalytics({name: 'account:overview'});

  return (
    <AccountPageWrapper pageTestId="account-overview-page-wrapper">
      <View
        // Add negative margin-top to this View as AccountPageWrapper adds additional margin to this PageContent
        // And because of lack of PageHeader on this page, the content needs to be moved up a bit.
        UNSAFE_style={{
          marginTop:
            'calc(var(--spectrum-global-dimension-size-400, var(--spectrum-alias-size-400)) * -1)',
        }}
      >
        <OverviewPage />
      </View>
    </AccountPageWrapper>
  );
};

export default AccountOverviewPage;
