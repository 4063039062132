(function () {
  /**
   * @ngdoc module
   * @name app.core.adobe-status
   * @description defines adobestatus-data and adobestatus data list models
   */
  angular.module('app.core.adobe-status', [
    'app.core.api.adobestatus',
    'binky.core.common.list',
    'binky.core.lodash',
  ]);
})();
