import {EVENT_SOURCES, eventBus} from '@admin-tribe/binky';

import jilJobs from 'common/api/jil/jilJobs';
import Job from 'common/services/job/Job';
import {JOB_EVENTS} from 'common/services/job/JobConstants';
import rootStore from 'core/RootStore';

const JOB_TYPES = {
  ADD_USER_GROUPS: 'add-user-groups',
  ADD_USERS: 'add-users',
  DIRECTORY_USER_REMOVE_USERS: 'du-remove-users',
  EDIT_USER_GROUPS: 'edit-user-groups',
  EDIT_USERS: 'edit-users',
  EXPORT_USERS_CSV: 'export-users-csv',
  LICENSE_GROUP_ADD_USERS: 'lg-add-users',
  LICENSE_GROUP_REMOVE_USERS: 'lg-remove-users',
  OFFER_SWITCH_MIGRATION: 'offer-switch-migration',
  REMOVE_USERS: 'remove-users',
  REVOKE_INVITES: 'revoke-invites',
  SWITCH_USERS: 'switch-users',
  TEAM_EVIP_MIGRATION: 'team-evip-migration',
  USER_GROUP_ADD_USERS: 'ug-add-users',
  USER_GROUP_REMOVE_USERS: 'ug-remove-users',
};

class JobList {
  /**
   * @description Performs the API call to remove jobs from the joblist
   * @param {String[]} jobIds - Selected job ids that correspond to the jobs
   *   that have been checked in the UI
   * @returns {Promise} result of the operation so the UI can either refresh
   *   or show an alert
   */
  static async deleteJobs(jobIds) {
    const ops = getDeleteJobsPayload(jobIds);
    const response = await jilJobs.patchJobs({
      ops,
      orgId: rootStore.organizationStore.activeOrgId,
    });
    eventBus.emit(JOB_EVENTS.DELETE_JOB);
    return response.data;
  }

  /**
   * @description Returns a new JobList representing a list of jobs which were
   *   performed on the specified directory. The JobList will populate when
   *   the back-end call completes.
   * @param {String} directoryId - the user group ID to fetch jobs for
   * @returns {JobList} a JobList representing a list of jobs performed on the directory
   */
  static getDirectoryUserJobList(directoryId) {
    const {DIRECTORY_USER_REMOVE_USERS} = JOB_TYPES;

    const queryParams = {directoryId, typeList: [DIRECTORY_USER_REMOVE_USERS]};

    return get({
      queryParams,
    });
  }

  /**
   * @description Returns a new JobList representing a list of jobs which were
   *   performed on the specified product profile. The JobList will populate
   *   when the back-end call completes.
   * @param {String} profileId - the profile ID to fetch jobs for
   * @param {String} productId - the product ID to fetch jobs for
   * @returns {JobList} a JobList representing a list of jobs performed on the profile
   */
  static getLicenseConfigJobList(profileId, productId) {
    const {LICENSE_GROUP_ADD_USERS, LICENSE_GROUP_REMOVE_USERS} = JOB_TYPES;

    return get({
      queryParams: {
        licenseGroupId: profileId,
        productId,
        typeList: [LICENSE_GROUP_ADD_USERS, LICENSE_GROUP_REMOVE_USERS],
      },
    });
  }

  /**
   * @description Returns a new JobList representing a list of jobs which were
   *   performed on the specified user group. The JobList will populate when
   *   the back-end call completes.
   * @param {String} userGroupId - the user group ID to fetch jobs for
   * @returns {JobList} a JobList representing a list of jobs performed on the
   *   user group
   */
  static getUserGroupJobList(userGroupId) {
    const {ADD_USER_GROUPS, EDIT_USER_GROUPS, USER_GROUP_ADD_USERS, USER_GROUP_REMOVE_USERS} =
      JOB_TYPES;

    if (userGroupId) {
      return get({
        queryParams: {
          typeList: [USER_GROUP_ADD_USERS, USER_GROUP_REMOVE_USERS],
          userGroupId,
        },
      });
    }
    return get({
      queryParams: {
        typeList: [ADD_USER_GROUPS, EDIT_USER_GROUPS],
      },
    });
  }

  /**
   * @description Returns a new JobList representing a list of jobs which were
   * performed on the active organization's users. The JobList will populate when the
   * back-end call completes.
   *
   * @returns {JobList} a JobList representing a list of jobs performed on users
   */
  static getUsersJobList() {
    const {
      ADD_USERS,
      EDIT_USERS,
      EXPORT_USERS_CSV,
      OFFER_SWITCH_MIGRATION,
      REMOVE_USERS,
      REVOKE_INVITES,
      SWITCH_USERS,
      TEAM_EVIP_MIGRATION,
    } = JOB_TYPES;

    return get({
      queryParams: {
        typeList: [
          ADD_USERS,
          EDIT_USERS,
          EXPORT_USERS_CSV,
          OFFER_SWITCH_MIGRATION,
          REMOVE_USERS,
          REVOKE_INVITES,
          SWITCH_USERS,
          TEAM_EVIP_MIGRATION,
        ],
      },
    });
  }

  /**
   * @description Constructs a new Job
   * @param {Object} [options] - options for JobList
   * @param {Object} [options.queryParams] - key/value pairs of query params
   */
  constructor(options = {}) {
    this.queryParams = options.queryParams;
    this.jobs = [];
    this.deletableJobs = [];

    eventBus.registerEventHandler(this.#onJobUpdate.bind(this), EVENT_SOURCES.SRC2);
  }

  /**
   * @private
   * @description Handler for job list event listeners
   * @param {String} id - id of the event
   */
  #onJobUpdate(id) {
    if (id === JOB_EVENTS.UPDATE_JOB || id === JOB_EVENTS.DELETE_JOB) {
      this.refresh();
    }
  }

  /**
   * @description Method that verifies whether or not a job can be removed.
   *
   * @param {String} jobId - the id of the job to check if it can be removed.
   *
   * @returns Whether or not the job with jobId can be removed from the list.
   */
  canJobBeDeleted(jobId) {
    return this.deletableJobs.find((job) => job.id === jobId);
  }

  /**
   * @description Method to free up any resources that need to be explicitly
   *   removed to prevent memory leaks (i.e. - listeners/handlers, etc).
   */
  destroy() {
    eventBus.deregisterEventHandler(this.#onJobUpdate.bind(this), EVENT_SOURCES.SRC2);
  }

  /**
   * @description Refreshes this JobList instance by fetching the jobs from
   *   the back-end.
   * @return {Promise} a promise which resolves with the refreshed JobList
   *   model, or rejects with an error message
   */
  async refresh() {
    const response = await jilJobs.getOrganizationJobs({
      ...this.queryParams,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    this.jobs = response.data
      .map(Job.apiResponseTransformer)
      .sort((jobA, jobB) => jobB.timeStarted - jobA.timeStarted);

    this.deletableJobs = this.jobs.filter((job) => job.canBeDeleted());

    eventBus.emit(JOB_EVENTS.REFRESH_JOB_LIST, this);

    return this;
  }
}

////////

/**
 * @description Method to fetch/refresh job list from back-end.
 * @param {Object} [options] - options for back-end fetch
 * @param {Object} [options.queryParams] - key/value pairs of query params
 * @returns {JobList} fetched instance from back-end
 */
function get(options) {
  const model = new JobList(options);
  return model.refresh();
}

/**
 * @description Method to construct/return a list of delete operations for a
 *   given list of jobs to delete.
 * @param {Array} jobs - list of jobs to delete
 * @returns {Array} list of delete operations to delete jobs
 */
function getDeleteJobsPayload(jobsIds) {
  return jobsIds.map((job) => ({
    op: 'remove',
    path: `/${job}`,
  }));
}

export default JobList;
