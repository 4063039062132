(function () {
  /**
   * @deprecated with temp_react_port_jobs
   *
   * @ngdoc component
   * @name jobListTableRow
   * @description Component which displays a table row for a job-list-table
   *
   * @binding {Function} isChecked - method to determine if row is selected (or not)
   * @binding {Job} job - job data to display in row
   * @binding {Function} onCheckChanged - call back to handle changes to selection state
   * @binding {Object} pageContext - the pageContext controlling bulk operations
   *   to fetch/display
   */
  angular.module('app.widgets.bulk-operations').directive('appJobListTableRow', JobListTableRow);

  /* @ngInject */
  function JobListTableRow(
    $log,
    $state,
    $translate,
    _,
    JOB_STATUS,
    OrganizationList,
    PAGE_TARGET,
    PAGE_TARGET_TYPE,
    toastManager
  ) {
    const directive = {
      link: linkFn,
      restrict: 'A',
      scope: {
        isChecked: '&',
        job: '<',
        onCheckChanged: '&',
        pageContext: '<',
      },
      templateUrl:
        'app/widgets/bulk-operations/jobs/job-list-table/job-list-table-row.directive.html',
    };

    return directive;

    ////////

    function linkFn(scope) {
      const CLASSNAME_ERROR = 'error';
      const CLASSNAME_SUCCESS = 'success';
      const CLASSNAME_WARNING = 'warning';
      const STATUS_CLASSNAME_MAP = {
        [JOB_STATUS.CANCELLED]: CLASSNAME_SUCCESS,
        [JOB_STATUS.COMPLETED]: CLASSNAME_SUCCESS,
        [JOB_STATUS.FAILED]: CLASSNAME_ERROR,
        [JOB_STATUS.PROCESSING]: CLASSNAME_WARNING,
        [JOB_STATUS.QUEUED]: CLASSNAME_WARNING,
        [JOB_STATUS.REJECTED]: CLASSNAME_ERROR,
        [JOB_STATUS.UNKNOWN]: CLASSNAME_WARNING,
        [JOB_STATUS.UNKNOWN_JOB_TYPE]: CLASSNAME_WARNING,
      };

      _.assign(scope, {
        getJobResultsPageLink,
        getStatusIconClassname,
        getStatusTextClassname,
        orgId: OrganizationList.get().activeOrg.id,
      });

      renderActionColumn();
      scope.$watch('job', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          renderActionColumn();
        }
      });

      ////////

      function cancelJob() {
        scope.actionBusy = true;
        scope.job.cancel().then(onSuccess, onError).finally(onComplete);

        ////////

        function onComplete() {
          scope.actionBusy = false;
          renderActionColumn();
        }

        function onError() {
          const message = $translate.instant('widgets.bulkOperations.cancel.error');
          toastManager.showErrorToast(message);
        }

        function onSuccess() {
          const message = $translate.instant('widgets.bulkOperations.cancel.success');
          toastManager.showSuccessToast(message);
        }
      }

      // eslint-disable-next-line complexity
      function getJobResultsPageLink() {
        let pageLink;
        switch (scope.pageContext.target) {
          case PAGE_TARGET.PRODUCT:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                throw new Error('Angular products has been removed');
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.PRODUCT_CONFIGURATION:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                throw new Error('Angular products has been removed');
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.DIRECTORY:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                pageLink = $state.href('users.directory-user-results', {
                  directoryId: scope.pageContext.targetId,
                  jobId: scope.job.id,
                  orgId: scope.orgId,
                });
                break;
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.USER_GROUP:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                if (scope.pageContext.targetId) {
                  pageLink = $state.href('users.user-group-results', {
                    jobId: scope.job.id,
                    orgId: scope.orgId,
                    userGroupId: scope.pageContext.targetId,
                  });
                } else {
                  pageLink = $state.href('users.user-group-bulk-operations-results', {
                    jobId: scope.job.id,
                    orgId: scope.orgId,
                  });
                }
                break;
              default:
                logUnimplementedError();
            }
            break;
          case PAGE_TARGET.ORGANIZATION:
            switch (scope.pageContext.targetType) {
              case PAGE_TARGET_TYPE.USER:
                pageLink = $state.href('users.results', {
                  jobId: scope.job.id,
                  orgId: scope.orgId,
                });
                break;
              default:
                logUnimplementedError();
            }
            break;
          default:
            logUnimplementedError();
        }

        return pageLink;

        ////////

        function logUnimplementedError() {
          $log.debug(
            `appJobListTableRow: Results view unimplemented for provided pageContext: ${scope.pageContext}`
          );
        }
      }

      function getStatusIconClassname() {
        if (scope.job.hasTerminatedWithErrors()) {
          return CLASSNAME_ERROR;
        }
        return STATUS_CLASSNAME_MAP[scope.job.status]
          ? STATUS_CLASSNAME_MAP[scope.job.status]
          : undefined;
      }

      function getStatusTextClassname() {
        if (scope.job.hasTerminatedWithErrors()) {
          return CLASSNAME_ERROR;
        }
        if (STATUS_CLASSNAME_MAP[scope.job.status] === CLASSNAME_ERROR) {
          return STATUS_CLASSNAME_MAP[scope.job.status];
        }
        return undefined;
      }

      function renderActionColumn() {
        let action;
        if (scope.job.isCancelable()) {
          action = {
            onClick: cancelJob,
            translateKey: 'widgets.bulkOperations.actions.cancel',
          };
        }
        scope.action = action;
      }
    }
  }
})();
