import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import DirectorySetupStore from 'features/settings/stores/DirectorySetupStore';

/**
 * Private Context for the SetupDirectory Store.
 * Used to manage the setup of a directory.
 */
const DirectorySetupContext = createContext({});

/**
 * Returns the DirectorySetupConetxt. To accomodate angular it will return
 * a new instance of the DirectorySetupStore if one was not found on the context.
 *
 * @returns A DirectorySetupContext object
 */
const useDirectorySetupContext = () => {
  const context = useContext(DirectorySetupContext);

  // TODO: Remove everything below when we move off of angular completely.
  if (context.directorySetupStore instanceof DirectorySetupStore) {
    return context;
  }

  return {directorySetupStore: new DirectorySetupStore()};
};

const DirectorySetupContextProvider = ({children, directorySetupStore}) => {
  const value = useMemo(() => ({directorySetupStore}), [directorySetupStore]);

  return <DirectorySetupContext.Provider value={value}>{children}</DirectorySetupContext.Provider>;
};

DirectorySetupContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node,
  /**
   * The store for a directory setup.
   */
  directorySetupStore: PropTypes.instanceOf(DirectorySetupStore),
};

export {DirectorySetupContextProvider, useDirectorySetupContext};
