import cloneDeep from 'lodash/cloneDeep';

class StorageRepository {
  /**
   * @description Creates a new StorageRepository.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Boolean} options.entitled Set to true if repository is 'entitled'
   * @param {String} options.id Org ID associated with the repository
   * @param {String} options.name The name of the repository
   * @param {Boolean} options.provisioned Set to true if repository is 'provisioned'
   */
  constructor(options = {}) {
    const clonedOptions = cloneDeep(options);
    const {entitled, id, name, provisioned} = clonedOptions;
    Object.assign(this, {entitled, id, name, provisioned});
  }
}

export default StorageRepository;
