import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * Private Context for the DirectoryList page and its sub-components.
 * Used to pass the directoryListStore between sub-components.
 */
const DirectoryListContext = createContext({});
const useDirectoryListContext = () => useContext(DirectoryListContext);

const DirectoryListContextProvider = ({children, listState}) => {
  const value = useMemo(
    () => ({
      ...listState,
      directoryList: listState.state,
    }),
    [listState]
  );

  return <DirectoryListContext.Provider value={value}>{children}</DirectoryListContext.Provider>;
};

DirectoryListContextProvider.propTypes = {
  /**
   * The content to be passed through the context of the directory list .
   */
  children: PropTypes.node,
  /**
   * The directory list state used by the provider to keep state of the children components.
   */
  listState: PropTypes.shape({
    canCreateDirectory: PropTypes.bool,
    loadDirectoryList: PropTypes.func,
    setTableOptions: PropTypes.func,
    state: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- this is an instance so it makes it easier to test like this
      directoryListData: PropTypes.object,
      hasLoadingError: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
    tableOptions: PropTypes.shape({
      page: PropTypes.number,
      pageSize: PropTypes.number,
      searchQuery: PropTypes.string,
      sort: PropTypes.string,
      sortOrder: PropTypes.string,
      status: PropTypes.string,
    }),
    updateDirectoryList: PropTypes.func,
  }),
};

export {DirectoryListContextProvider, useDirectoryListContext};
