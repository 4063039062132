import {feature, navBus} from '@admin-tribe/binky';
import {
  OverlayWait,
  Subpage,
  TableSectionTable,
  showError,
  showSuccess,
} from '@admin-tribe/binky-ui';
import {Link, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {useId} from '@react-aria/utils';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canAddDomains} from 'core/directories/access/directoryAccess';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import AllowlistedDomainAddTrigger from 'features/settings/components/add-domain-modal/AllowlistedDomainAddTrigger';
import AllowlistedDomainRemoveTrigger from 'features/settings/components/remove-domain-modal/AllowlistedDomainRemoveTrigger';
import useCollaborationPolicies from 'features/settings/hooks/use-collaboration-policies/useCollaborationPolicies';
import {PATH_SETTINGS_ASSET_SHARING_OPTIONS} from 'features/settings/routing/settingsPaths';

const AuthorizedDomainsSubpage = () => {
  const intl = useIntl();
  const descriptionId = useId();

  const [arePoliciesReloading, setArePoliciesReloading] = useState(false);
  const [descriptionText, setDescriptionText] = useState(null);
  const [listItems, setListItems] = useState(null);
  const [filteredListItems, setFilteredListItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const orgId = rootStore.organizationStore.activeOrgId;

  const {
    collaborationPolicies,
    error: errorLoadingPolicies,
    isLoading: arePoliciesLoading,
  } = useCollaborationPolicies({orgId});

  const renderers = {
    domain: ({item}) => item.domain,
  };

  const getAuthorizedDomains = async (policies) => {
    const domains = await (feature.isEnabled('temp_use_allowlist')
      ? policies.getAllowlistedDomains()
      : policies.getWhitelistedDomains());
    return (domains ?? []).sort().map((domain) => ({domain, id: domain}));
  };

  // get domains from collaboration policies
  useEffect(() => {
    const getListItems = async () => {
      setListItems(await getAuthorizedDomains(collaborationPolicies));
    };
    if (!arePoliciesLoading) {
      getListItems();
    }
  }, [collaborationPolicies, arePoliciesLoading]);

  const onTableSectionChange = ({action, payload}) => {
    let searchTerm;
    switch (action) {
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        searchTerm = payload.value.trim();
        setFilteredListItems(
          searchTerm ? listItems.filter((item) => item.domain.includes(searchTerm)) : null
        );
        break;
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
        setSelectedItems(payload);
        break;
      default:
      // do nothing
    }
  };

  // set description text
  useEffect(
    () => {
      const load = async () => {
        const sharingOptions = generatePath(PATH_SETTINGS_ASSET_SHARING_OPTIONS, {orgId});
        setDescriptionText(
          intl.formatMessage(
            {
              id: `settings.assetSettings.allowlistedDomain.table.${
                (await canAddDomains()) ? 'description2' : 'description'
              }`,
            },
            {
              goUrl: (str) => <GoUrl name="asset_settings">{str}</GoUrl>,
              link: (text) => (
                <Link
                  data-testid="share-to-domain-users-link"
                  onPress={() => navBus.navigate(sharingOptions)}
                >
                  {text[0]}
                </Link>
              ),
            }
          )
        );
      };
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on init
    []
  );

  const isLoaded = !arePoliciesLoading && descriptionText && listItems !== null; // Is the initial pageload complete

  /**
   * Callback function for table action tirggers.
   * @param {Promise} actionResponse - Promise returned from action
   * @param {string} successMessageDescriptor - Formatted message descriptor for success toast
   * @param {object} successMessageValues - Values for formatted message descriptor
   */
  const onActionCTA = async ({actionResponse, successMessageDescriptor, successMessageValues}) => {
    try {
      setArePoliciesReloading(true);
      await actionResponse;
      setListItems(await getAuthorizedDomains(collaborationPolicies));
      setSelectedItems([]);
      showSuccess(intl.formatMessage(successMessageDescriptor, successMessageValues));
    } catch {
      showError(intl.formatMessage({id: 'common.toast.default.error'}));
    } finally {
      setArePoliciesReloading(false);
    }
  };

  return (
    <Subpage isLoading={!isLoaded}>
      <OverlayWait isLoading={!isLoaded || arePoliciesReloading} showContent={isLoaded} size="M">
        {isLoaded && (
          <>
            <View marginBottom="size-100">
              <Text data-testid="description" id={descriptionId}>
                {descriptionText}
              </Text>
            </View>
            <TableSection
              id="active-user-folders-table"
              isServerError={!!errorLoadingPolicies}
              items={filteredListItems ?? listItems}
              onTableSectionChange={onTableSectionChange}
              pageNumber={0} // not paginated
              selectionMode={isReadOnly() ? SELECTION_MODE.NONE : SELECTION_MODE.MULTIPLE}
            >
              <TableFilters
                label={intl.formatMessage({id: 'settings.assetSettings.allowlistedDomain.search'})}
              />
              <TableActions>
                <AllowlistedDomainAddTrigger
                  onCTA={(domainNames) =>
                    onActionCTA({
                      actionResponse: feature.isEnabled('temp_use_allowlist')
                        ? collaborationPolicies.addAllowlistedDomains(domainNames)
                        : collaborationPolicies.addWhitelistedDomains(domainNames),
                      successMessageDescriptor: {
                        id: 'settings.assetSettings.allowlistedDomainAddModal.successAlert',
                      },
                      successMessageValues: {domainCount: domainNames.length},
                    })
                  }
                />
                <AllowlistedDomainRemoveTrigger
                  isDisabled={selectedItems.length === 0}
                  // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- complexity due to feature flag
                  onCTA={() => {
                    setFilteredListItems(null);
                    return onActionCTA({
                      actionResponse: feature.isEnabled('temp_use_allowlist')
                        ? collaborationPolicies.removeAllowlistedDomains(selectedItems)
                        : collaborationPolicies.removeWhitelistedDomains(selectedItems),
                      successMessageDescriptor: {
                        id: 'settings.assetSettings.allowListedDomainRemoveModal.successAlert',
                      },
                      successMessageValues: {domainCount: selectedItems.length},
                    });
                  }}
                />
              </TableActions>
              <TableSectionTable
                aria-label={intl.formatMessage({
                  id: 'settings.assetSettings.allowlistedDomain.table.ariaLabel',
                })}
                columnDescriptor={[{key: 'domain'}]}
                columnNamespace="settings.assetSettings.allowlistedDomain.table.column"
                noItemsFoundContentMessage={intl.formatMessage({
                  id: 'settings.assetSettings.allowlistedDomain.table.setNoItemsFoundContentMessage',
                })}
                renderers={renderers}
              />
            </TableSection>
          </>
        )}
      </OverlayWait>
    </Subpage>
  );
};

export default AuthorizedDomainsSubpage;
