(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.file-attachment').component('appFileAttachment', {
    bindings: {
      disableDownloadButton: '<?',
      filename: '@',
      onDownload: '&?',
      onRemove: '&?',
      variant: '@?', // 'default' or 'quiet'; if unspecified, the 'default' appearance is used
    },
    controller,
    templateUrl: 'app/widgets/common/file-attachment/file-attachment.component.html',
  });

  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      onClickDownload,
      onClickRemove,
    });

    function $onChanges() {
      vm.disableDownloadButton = vm.disableDownloadButton || false;
    }

    function onClickDownload() {
      vm.onDownload();
    }

    function onClickRemove() {
      vm.onRemove();
    }
  }
})();
