(function () {
  /**
   * @ngdoc module
   * @name app.core.storage-quota
   * @description Module which defines storage-quota related models
   */
  angular.module('app.core.storage-quota', [
    'app.core.api.jil',
    'app.core.model-cache',
    'binky.core.lodash',
  ]);
})();
