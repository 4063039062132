import {ActionButton, Flex, Image, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

const DownloadButton = ({icon, label, url}) => {
  const downloadFile = () => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- disable forr window
    window.open(url);
  };

  return (
    <Flex marginTop="size-100">
      <ActionButton onPress={() => downloadFile()}>
        <Image alt="" height="size-300" marginStart="size-50" src={icon} width="size-300" />
        <Text marginStart="size-75">{label}</Text>
      </ActionButton>
    </Flex>
  );
};

DownloadButton.propTypes = {
  /**
   * Icon of the button
   */
  icon: PropTypes.string,
  /**
   * Description of the button
   */
  label: PropTypes.string,
  /**
   * Url of the button
   */
  url: PropTypes.string,
};

export default DownloadButton;
