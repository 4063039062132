import help from '../constants/help';

const {TOPICS} = help;

/**
 * @description Method to return configuration object for the get started (help)
 *   help topic
 * @returns content for get started (help) help topic
 */
const getStarted = () => ({
  content: [],
  id: TOPICS.GET_STARTED,
  parents: [],
  title: 'shell.helpRail.header.label',
});

export default getStarted;
