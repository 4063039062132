import {feature} from '@admin-tribe/binky';
import React, {useEffect} from 'react';
import {useLoaderData} from 'react-router-dom';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import AddOidcFromDeeplink from 'features/settings/components/directories/add-oidc-from-deeplink/AddOidcFromDeeplink';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import DirectoryListTable from 'features/settings/components/directories/directory-list/directory-list-table/DirectoryListTable';
import {
  goToDirectoryDetails,
  goToSetupDomains,
} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

/**
 * Defines the list of directories
 * It is a ported version for react-router of src2/app/features/settings/components/directories/directory-list/DirectoryList.jsx
 */
const DirectoryListPorted = () => {
  const {directoryList, loadDirectoryList, loadDirectoryListWithCertificates} =
    useDirectoryListContext();
  const {scimSyncs, eduRosterSyncs} = useLoaderData();

  const {clearDirectoryCache} = useDirectoryList();

  // get the list of directories
  useEffect(() => {
    const loadDirectories = () =>
      feature.isEnabled('temp_saml_certs')
        ? loadDirectoryListWithCertificates({}, {updateCount: true})
        : loadDirectoryList({}, {updateCount: true});

    loadDirectories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this will run only during the initial render.
  }, []);

  return (
    <>
      <DirectoryListTable
        directoryLinks={scimSyncs}
        goToDirectoryDetails={goToDirectoryDetails}
        goToSetupDomains={goToSetupDomains}
        rosterSyncs={eduRosterSyncs}
      />

      <AddOidcFromDeeplink
        clearDirectoryCache={clearDirectoryCache}
        directories={directoryList?.directoryListData.items}
        goToDirectory={goToDirectoryDetails}
        goToSetupDomains={goToSetupDomains}
      />
    </>
  );
};

DirectoryListPorted.displayName = 'DirectoryListPorted';

export default DirectoryListPorted;
