(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name DirectoryUserList
   * @description Model for a list of directory users
   */
  angular
    .module('app.core.directories.user-list')
    .factory('DirectoryUserList', getDirectoryUserListModel);

  /* @ngInject */
  function getDirectoryUserListModel(
    $rootScope,
    _,
    DirectoryUser,
    jilDirectories,
    MemberList,
    MESSAGE,
    MODEL,
    modelCache
  ) {
    class DirectoryUserList extends MemberList {
      /**
       * @description Constructor for DirectoryUserList model Objects.
       * @param {Object} options - options passed to the List constructor
       * @param {Object} directoryId - the unique identifier for this list
       */
      constructor(options = {}) {
        super(
          _.assign(
            {
              cacheType: MODEL.DIRECTORYUSERLIST,
              memberClassRef: DirectoryUser,
              resource: jilDirectories.directoryUsers,
            },
            options
          )
        );
        this.directoryId = options.directoryId;
      }

      /**
       * @description Method to return the current unique key for this list.
       *
       * @returns {String} key to uniquely identify this list
       */
      key() {
        return super.key({directoryId: this.directoryId});
      }

      /**
       * @description
       * Method to refresh the contents of the directory user list
       *
       * @returns {Promise} promise - resolved when the list is refreshed
       */
      refresh() {
        return super
          .refresh({
            directoryId: this.directoryId,
          })
          .then((userList) => {
            _.forEach(userList.items, (user) => {
              user.directoryId = this.directoryId;
            });
            // These events should be kept in sync with reset.
            $rootScope.$emit(MESSAGE.UPDATE.DIRECTORYUSERS.LIST);
            if (this.shouldUpdateTotalItemCount()) {
              $rootScope.$emit(MESSAGE.UPDATE.DIRECTORYUSERS.COUNT, this.pagination.itemCount);
            }
            return userList;
          });
      }

      /**
       * @description Method to reset the directory user list to its collapsed state.
       *
       * @returns {Promise} promise - resolved when the list is reset
       */
      reset() {
        return super.reset().then((userList) => {
          // These events should be kept in sync with refresh.
          // Dispatch undefined rather than 0 for the count so that components which use it
          // (such as the scorecard) can provide distinct behaviour from the refresh driven change.
          $rootScope.$emit(MESSAGE.UPDATE.DIRECTORYUSERS.LIST);
          $rootScope.$emit(MESSAGE.UPDATE.DIRECTORYUSERS.COUNT, undefined);
          return userList;
        });
      }

      /**
       * @description
       * Method to save the directory user list when removing users.
       * Overriding the original userList.save because we need to provide the
       * directoryId to the resource performing the API call so the generated
       * yurel includes the directoryId.
       *
       * @param {Object} options - options to alter the behavior of save
       *
       * @returns {Promise} promise - resolved after the API call is executed
       */
      save(options = {}) {
        return super.save({directoryId: this.directoryId}, options);
      }

      /**
       * @description Method to retrieve an existing list of directory users
       *              from the back-end.
       *
       * @param {String} directoryId - the directory to fetch users for
       * @param {Number} pageSize - number of users to display per page
       *
       * @returns {DirectoryUserList} new DirectoryUserList Object reference
       */
      static get(directoryId, pageSize) {
        let model = new DirectoryUserList({directoryId, pageSize});

        const cachedModel = modelCache.get(MODEL.DIRECTORYUSERLIST, model.key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }
    }

    MemberList.setupCache(MODEL.DIRECTORYUSERLIST, 1);

    return DirectoryUserList;
  }
})();
