import binky from '@admin-tribe/binky';
import axios from 'axios';

import AppConstants from '../../services/AppConstants';

const {getHeaders} = binky.api.jil.jilApiUtils;
let clientId, includeRoles, url;

/**
 * Configure JIL custom data APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({clientId, includeRoles} = config);
  url = `${config.url}/v2/organizations`;
};

/**
 * @description Fetches custom data notes
 *
 * @return {Promise} a promise which will resolve with the response
 */
const getDataResource = async () => {
  const response = await axios.get(`${url}/${AppConstants.orgId}/custom-data`, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response;
};

/**
 * @description Creates a new custom data note
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const postDataResource = async (dataResource) => {
  const response = await axios.post(`${url}/${AppConstants.orgId}/custom-data`, dataResource, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response.data;
};

/**
 * @description Updates a custom data note
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const putDataResource = async (dataResource) => {
  const response = await axios.put(`${url}/${AppConstants.orgId}/custom-data`, dataResource, {
    headers: getHeaders({clientId, includeRoles}),
  });
  return response.data;
};

const jilCustomData = {
  configure,
  getDataResource,
  postDataResource,
  putDataResource,
};

export default jilCustomData;
