import {OrganizationUser, UserGroup} from '@admin-tribe/binky';
import {getMemberDisplayName} from '@admin-tribe/binky-ui';
import {Flex, Item, Picker} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import LegacyProductRole from 'common/entities/legacy-product-role/LegacyProductRole';
import requiredIf from 'common/prop-types/requiredIf';

// The picker to use in tables for product roles for legacy product roles (Target).
// Legacy product roles do support user groups.
// Spec: https://wiki.corp.adobe.com/x/WGWbZ
// When member is undefined this assumes it is being used in "buk-edit" mode at the top of the table for all the selected rows.
const LegacyProductRolePicker = ({
  isDisabled = false,
  isMultiSelect = false,
  member,
  onRoleChange,
  roles,
  ...styleProps // applied to Flex which wraps Picker
}) => {
  const intl = useIntl();

  let ariaLabel, label, placeholder, selectedKey;

  // For multi-select edit, display a placeholder. Initially there is not a selected key.
  if (isMultiSelect) {
    ariaLabel = intl.formatMessage({id: 'products.legacyProductRolePicker.multiSelect.ariaLabel'});
    label = intl.formatMessage({id: 'products.legacyProductRolePicker.label'});
    placeholder = intl.formatMessage({
      id: 'products.legacyProductRolePicker.multiSelect.placeholder',
    });
  } else {
    ariaLabel = intl.formatMessage(
      {id: 'products.legacyProductRolePicker.ariaLabel'},
      {
        displayName: getMemberDisplayName(intl, member, {
          fallbackToEmail: true,
        }),
      }
    );
    selectedKey = member?.productRole?.id || roles.find((role) => role.default)?.id;
  }

  // This is a controlled Picker.
  return (
    <Flex alignItems="center" {...styleProps}>
      <Picker
        aria-label={ariaLabel}
        data-testid="legacy-product-role-picker"
        isDisabled={isDisabled}
        isQuiet={!isMultiSelect}
        items={roles}
        label={label}
        labelPosition="side"
        onSelectionChange={(selectedId) => onRoleChange({member, selectedId})}
        placeholder={placeholder}
        selectedKey={selectedKey}
      >
        {(item) => <Item>{item.name}</Item>}
      </Picker>
    </Flex>
  );
};

LegacyProductRolePicker.propTypes = {
  /**
   * Whether the picker should show as disabled. The default is false.
   */
  isDisabled: PropTypes.bool,
  /**
   * If true, the picker is configured for placement at the top of the table with a label and an initial placeholder.
   * The picker is disabled until one or more rows is selected.
   * The selected role will be applied to all the selected rows.
   * The default is false.
   */
  isMultiSelect: PropTypes.bool,
  /**
   * The user or user group. This is required if isMultiSelect is false.
   */
  member: requiredIf(
    PropTypes.oneOfType([PropTypes.instanceOf(OrganizationUser), PropTypes.instanceOf(UserGroup)]),
    (props) => !props.isMultiSelect
  ),
  /**
   * Callback when the selection changes. There is one param which is an object
   * which has member and selectedId properties.
   * member may be null if this is being used in multi-select case.
   * selectedId is the id of the selected role.
   */
  onRoleChange: PropTypes.func.isRequired,
  /**
   * The legacy roles, sorted by name.
   */
  roles: PropTypes.arrayOf(PropTypes.instanceOf(LegacyProductRole)).isRequired,
};

export default LegacyProductRolePicker;
