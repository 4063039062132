/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseTimezoneSection
 *
 * @description The timezone section for a support form.
 *
 * @binding {String} descriptionKey. The key for the description for this section.
 * @binding {Function} onChange. Callback for when the timezone changes.  Callback param is timezone.
 * @binding {String} [timezone]. The initial timezone.
 */
(function () {
  angular.module('app.widgets.support').component('appSupportTicketTimezoneFormSection', {
    bindings: {
      descriptionKey: '@',
      onChange: '&',
      timezone: '@?',
    },
    templateUrl:
      'app/widgets/support/form-sections/support-ticket-timezone-form-section/support-ticket-timezone-form-section.component.html',
  });
})();
