(function () {
  /**
   * @awaitingdeprecation
   *
   * @ngdoc factory
   * @name app.core.analytics.launch-descriptor:productToLaunchTranslator
   * @description Translate ProductDescriptor into Launch data structure
   */
  angular
    .module('app.core.analytics.launch-descriptor')
    .factory('productToLaunchTranslator', factory);

  /* @ngInject */
  function factory(_) {
    return {processDescriptor};

    function processDescriptor(launchContext, productDescriptor) {
      const {setProperty} = launchContext;
      setProperty('primaryProduct.productInfo.offerId', _.get(productDescriptor, 'offer_id'));
      setProperty('primaryProduct.quantity', _.get(productDescriptor, 'numberSelected'));
      setProperty(
        'primaryProduct.attributes.sourceOfferLocation',
        _.get(productDescriptor, 'productBucket')
      );
      setProperty('search.searchInfo.keyword', _.get(productDescriptor, 'searchTerm'));
    }
  }
})();
