import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import SupportWorkspaceNav from 'features/support/components/support-workspace/SupportWorkspaceNav';

/**
 * Support workspace page wrapper for side nav
 */
const SupportWorkspaceWrapper = () => (
  <Workspace useOutlet>
    <SupportWorkspaceNav />
  </Workspace>
);

export default SupportWorkspaceWrapper;
