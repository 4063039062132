import {log, modelCache} from '@admin-tribe/binky';

import {ADOBE_PRODUCTS_UI, CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

import PackagesAdobeProductUiEntity from '../entities/PackagesAdobeProductUiEntity';
import {stringVersionCompare} from '../entities/packagesAdobeProductEntityUtils';

import PackagesAdminService from './PackagesAdminService';
import PackagesAdobeProductsService from './PackagesAdobeProductsService';
import PackagesEntitlementsService from './PackagesEntitlementsService';

class PackagesAdobeProductsUiService {
  //////////////////////////////////////////
  /* Static methods */

  /**
   * @description Method to fetch and process all Adobe Products
   *
   * @throws {Error}
   */
  static async fetchAndProcessAdobeProducts() {
    const cachedAdobeProductsUi = await modelCache.get(ADOBE_PRODUCTS_UI, 'ADOBE_PRODUCTS_UI');
    if (cachedAdobeProductsUi) {
      return;
    }

    this.adobeProductsUIMap = {};
    this.adobeProductsUIObj = {};
    this.latestCreativeCloudApp = {};

    const response = packagesApiOptimizationsResponse();

    const products = processProducts(response);
    this.adobeProductsUIObj = products;
    // disable create package buttons if adobeProductsUI is empty
    if (isNoProductAvailableForCreatePackage(products)) {
      log.info('CreatePackageButtonDisabled: No product is available for creating package.');
      PackagesAdminService.disableBuildPermanently();
    }
    // build adobeProductsUIMap
    products?.Pid2ShowList?.forEach((productPlatformObject) => {
      productPlatformObject.productList.forEach((product) => {
        this.adobeProductsUIMap[product.product_key] = product;
        this.adobeProductsUIMap[`${product.sapCode}/${product.version}`] = product;
      });
    });
    // set latest Creative Cloud App
    products?.Pid2ShowList?.forEach((productPlatformObject) => {
      const filteredProductList = productPlatformObject.productList.filter(
        (product) => product.sapCode === 'KCCC'
      );
      const productList = filteredProductList.sort((a, b) =>
        stringVersionCompare(a.version, b.version) ? 1 : -1
      );
      this.latestCreativeCloudApp[productPlatformObject.platform] =
        productList[productList.length - 1];
    });

    // add macuniversal kccc to list.
    const latestMacArmCreativeCloudApp =
      PackagesAdobeProductsUiService.getLatestCreativeCloudApp('macarm64');
    // replace mac arm with mac universal
    if (latestMacArmCreativeCloudApp) {
      const list = ['product_key', 'platform'];
      list.forEach((field) => {
        latestMacArmCreativeCloudApp[field] = latestMacArmCreativeCloudApp[field].replace(
          'macarm64',
          'macuniversal'
        );
      });

      this.adobeProductsUIObj.Pid2ShowList.find(
        (item) => item.platform === 'macuniversal'
      )?.productList.push(latestMacArmCreativeCloudApp);
    }

    modelCache.put(ADOBE_PRODUCTS_UI, 'ADOBE_PRODUCTS_UI', true);
  }

  /**
   * @description Method to get Adobe Product by 'sapCode/version'.
   *
   * @param {String} sapCodeVersionKey 'sapCode/version' for AdobeProduct
   * @returns {AdobeProduct} Reference to corresponding AdobeProduct object if found, else false.
   */
  static getAdobeProductBySapCodeVersion(sapCodeVersionKey) {
    if (this.adobeProductsUIMap[sapCodeVersionKey]) {
      return this.adobeProductsUIMap[sapCodeVersionKey];
    }
    return false;
  }

  /**
   * @description Method to get Adobe Product by 'sapCode/version/platform'.
   *
   * @param {String} sapCodeVersionPlatformKey 'sapCode/version/platform' for AdobeProduct
   * @returns {AdobeProduct} Reference to corresponding AdobeProduct object if found, else false.
   */
  static getAdobeProductBySapCodeVersionPlatform(sapCodeVersionPlatformKey) {
    if (this.adobeProductsUIMap[sapCodeVersionPlatformKey]) {
      return this.adobeProductsUIMap[sapCodeVersionPlatformKey];
    }
    return false;
  }

  /**
   * @description method to get the Adobe products as a list
   *
   * @returns {Array} products array of products
   */
  static getAdobeProducts() {
    return this.adobeProductsUIObj;
  }

  /**
   * @description Method to get all Adobe products that can be packaged
   *
   * @returns {Array} All Adobe products that can be packaged
   */
  static getAllPackageableAdobeProducts() {
    return Object.values(this.adobeProductsUIMap);
  }

  /**
   * @description method to get the Creative Cloud App object for given platform key
   *
   * @param {String} platform Platform key from [win32, win64, mac]
   * @returns {Object} Creative Cloud App product object. False if not found.
   */
  static getLatestCreativeCloudApp(platform) {
    if (this.latestCreativeCloudApp[platform]) {
      return this.latestCreativeCloudApp[platform];
    }
    return false;
  }

  /**
   * @description method to get the platform specific products
   *
   * @param {String} platform Platform key from [win32, win64, mac].
   * @param {String} bits and bits from 32 or 64
   * @returns {Object} Creative Cloud App product object. False if not found.
   */

  static getProductsForPlatform(platform, bits) {
    switch (platform) {
      case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
        return this.adobeProductsUIObj.Pid2ShowList.find(
          (pis2ShowElement) => pis2ShowElement.platform === 'mac'
        )?.productList;
      case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
        return this.adobeProductsUIObj.Pid2ShowList.find(
          (pis2ShowElement) => pis2ShowElement.platform === 'macarm64'
        )?.productList;
      case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
        return this.adobeProductsUIObj.Pid2ShowList.find(
          (pis2ShowElement) => pis2ShowElement.platform === 'winarm64'
        )?.productList;
      case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
        return bits === CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT
          ? this.adobeProductsUIObj.Pid2ShowList.find(
              (pis2ShowElement) => pis2ShowElement.platform === 'win64'
            )?.productList
          : this.adobeProductsUIObj.Pid2ShowList.find(
              (pis2ShowElement) => pis2ShowElement.platform === 'win32'
            )?.productList;
      case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
        return this.adobeProductsUIObj.Pid2ShowList.find(
          (pis2ShowElement) => pis2ShowElement.platform === 'macuniversal'
        )?.productList;
      default:
        return [];
    }
  }
}

//////////////////////////////////////////
/* Private methods */

/**
 * @description Method to check if adobeProductsUI is empty (ie. no products for create package)
 *
 * @param {Object} adobeProductsUI adobeProductsUI object as received
 * @returns {Boolean} true if adobeProductsUI is empty
 */
function isNoProductAvailableForCreatePackage(adobeProductsUI) {
  let isNoProductAvailable = true;

  adobeProductsUI.Pid2ShowList.forEach((productPlatformObject) => {
    if (Object.keys(productPlatformObject.productList).length > 0) {
      isNoProductAvailable = false;
    }
  });

  // special case:isNoProductAvailableForCreatePackage
  // non-archived acrobat must be present for atleast one platform in case of DC only entitlement
  let isAcrobatPresent = false;
  adobeProductsUI.Pid2ShowList.forEach((productPlatformObject) => {
    isAcrobatPresent =
      isAcrobatPresent ||
      productPlatformObject.productList.some(
        (product) => product.sapCode === 'APRO' && !product.archive
      );
  });

  return (
    isNoProductAvailable || (PackagesEntitlementsService.hasDcOnlyEntitlement && !isAcrobatPresent)
  );
}

function packagesApiOptimizationsResponse() {
  return {
    data: {
      Pid2ShowList: [
        {
          platform: 'mac',
          productList: PackagesAdobeProductsService.getPackageableProductsByPlatform('mac'),
        },
        {
          platform: 'macarm64',
          productList: PackagesAdobeProductsService.getPackageableProductsByPlatform('macarm64'),
        },
        {
          platform: 'win32',
          productList: PackagesAdobeProductsService.getPackageableProductsByPlatform('win32'),
        },
        {
          platform: 'win64',
          productList: PackagesAdobeProductsService.getPackageableProductsByPlatform('win64'),
        },
        {
          platform: 'winarm64',
          productList: PackagesAdobeProductsService.getPackageableProductsByPlatform('winarm64'),
        },
        {
          platform: 'macuniversal',
          productList:
            PackagesAdobeProductsService.getPackageableProductsByPlatform('macuniversal'),
        },
      ],
    },
  };
}

function processProducts(response) {
  const products = response?.data;
  for (let i = 0; i < products?.Pid2ShowList?.length; i++) {
    const platformSpecificAdobeProductsUI = products.Pid2ShowList[i].productList;
    const prodList = platformSpecificAdobeProductsUI?.map(
      (product) => new PackagesAdobeProductUiEntity(product)
    );
    products.Pid2ShowList[i].productList = prodList;
  }
  return products;
}
export default PackagesAdobeProductsUiService;
