import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

let apiKey, url, urlToGetExtensionList;

/**
 * @description Configures the packageCces service with the required parameters.
 *
 * @param {Object} config Config for package ingest service.
 * @param {String} config.url Url for the package ingest service.
 */
const configure = (config) => {
  apiKey = config.apiKey;
  url = config.url;
};

// ///////// API Utility methods ////////////

/**
 * @description Retrieves the required headers for cces apis.
 *
 * @param {Boolean} forExtensionList if true returns header object for extensionlist api else for discover api
 *
 * @returns {Object} request header for apis
 */
function getHeaderObject(forExtensionList = false) {
  const header = {
    Authorization: `Bearer ${authentication.getAccessToken().token}`,
    'X-Api-Key': apiKey,
  };
  return forExtensionList
    ? {
        Accept: 'application/vnd.adobe-ccextension.extension-list-v3+json',
        'Content-Type': 'application/vnd.adobe-ccextension.extension-filter-v3+json',
        ...header,
      }
    : header;
}

/**
 * @description Function to parse and get the download url
 *
 * @param {String} linkHeader String
 * @returns {String} return download URL
 */
function parseLinkHeaderAndGetDownloadUrl(linkHeader) {
  const linkMap = {};
  linkHeader.split('",').forEach((link) => {
    let normalizedLink = link;
    if (normalizedLink.slice(-1) !== '"') {
      normalizedLink += '"';
    }
    let [linkUrl, linkRelation] = normalizedLink.split(';');

    // remove ` rel="` from start, and `"` from end
    linkRelation = linkRelation.slice(6, linkRelation.length - 1);

    // remove `<` from start and `>` from end
    linkUrl = linkUrl.slice(1, linkUrl.length - 1);

    linkMap[linkRelation] = linkUrl;
  });

  // set the listing URL
  urlToGetExtensionList = linkMap['public-extension-list-v3'];

  // return download URL
  return linkMap['download-extensions-enterprise'];
}

// ///////// Resources //////////

/**
 * @description Function to init apis
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
const discover = () => axios.head(url, {headers: getHeaderObject()});

/**
 * @description Function to fetch extensions
 *
 * @param {Object} apiFilter Object to filter the API to fetch extensions
 * @returns {Promise<AxiosResponse<any>>} resolved with response
 */
const getExtensionList = (apiFilter) =>
  axios.post(urlToGetExtensionList, apiFilter, {
    headers: getHeaderObject(true),
  });

export {configure, parseLinkHeaderAndGetDownloadUrl, discover, getExtensionList};
