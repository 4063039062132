import {StatusLight} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

// Replace with 'common/entities/FederatedDomainEntity' when temp_refactor_federated_domains deleted
import {
  DETAILED_DOMAIN_STATUS,
  DOMAIN_STATUS,
} from 'features/settings/entities/FederatedDomainEntity';

import styles from './FederatedDomainStatus.pcss';

const DOMAIN_STATUS_LIGHT = {
  [DOMAIN_STATUS.FREE]: {
    label: 'settings.directoryDomains.status.AVAILABLE',
    lightVariant: 'positive',
  },
  [DETAILED_DOMAIN_STATUS.DOMAIN_ALREADY_CLAIMED]: {
    label: 'settings.directoryDomains.status.DOMAIN_ALREADY_CLAIMED',
    lightVariant: 'positive',
  },
  [DETAILED_DOMAIN_STATUS.DOMAIN_HAS_INVALID_AUTHSRC]: {
    label: 'settings.directoryDomains.status.DOMAIN_HAS_INVALID_AUTHSRC',
    lightVariant: 'negative',
  },
  [DETAILED_DOMAIN_STATUS.DOMAIN_HAS_INVALID_OWNER]: {
    label: 'settings.directoryDomains.status.DOMAIN_HAS_INVALID_OWNER',
    lightVariant: 'negative',
  },
  NOT_AVAILABLE: {
    label: 'settings.directoryDomains.status.NOT_AVAILABLE',
    lightVariant: 'negative',
  },
};

const FederatedDomainStatus = observer(({status, detailedStatus}) => {
  let statusLightConfig;

  if (status === DOMAIN_STATUS.FREE) {
    statusLightConfig = DOMAIN_STATUS_LIGHT[DOMAIN_STATUS.FREE];
  } else {
    statusLightConfig = DOMAIN_STATUS_LIGHT[detailedStatus] || DOMAIN_STATUS_LIGHT.NOT_AVAILABLE;
  }

  return (
    <StatusLight
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needs more props than available
      UNSAFE_className={styles['domain-status']}
      variant={statusLightConfig.lightVariant}
    >
      <FormattedMessage id={statusLightConfig.label} />
    </StatusLight>
  );
});

export default FederatedDomainStatus;
export {DOMAIN_STATUS_LIGHT};
