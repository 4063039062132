/* eslint-disable max-lines -- this file requires more lines*/
import {
  CONTRACT_BUYING_PROGRAM,
  LICENSE_QUANTITY_RENEWAL_STATUS,
  ProductNoAccessUserList,
  feature,
  getDirectContract,
  navBus,
} from '@admin-tribe/binky';
import {DATE_FORMATS, GoUrl} from '@admin-tribe/binky-ui';
import {Link} from '@adobe/react-spectrum';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import AddProductModalLink from 'common/components/product-notification-banner/AddProductModalLink';
import rootStore from 'core/RootStore';
import {ACCOUNT_STATE_PARAM} from 'core/account/account-meta/AccountMetaConstants';
import {COMPLIANCE_CONTEXT} from 'core/contract/contract-compliance/ContractComplianceConstants';
import {
  hasGracePastDueLicensesForProduct,
  hasPastDueLicensesForProduct,
  hasPendingPaymentLicensesForProduct,
} from 'core/contract/contract-compliance/contractComplianceUtils';
import {getRenewalSummary} from 'core/contract/contract-renewal/contractRenewalUtils';
import {canAssignType1User} from 'core/products/access/productAccess';
import {canViewSetQuotaButton} from 'core/products/access/productProfileAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {goToBillingHistory} from 'features/account/routing/navigation-callbacks/navigationCallbacks';
import {DEVICE_STATUS} from 'features/products/device-licenses/DeviceConstants';

import {getThresholdStatusKey, notifyError, notifyInfo, notifyWarning} from './notifierUtils';

/**
 *
 * @param {Object} options - params; any optional params are not needed by
 *   calculations and represent product traits that are not universally
 *   applicable
 * @param {Consumable[]} [options.consumables] - Array of Consumable entities;
 *   will be undefined if product is not a group consumable
 * @param {Object} [options.deviceData] - The object representing device data if
 *   banner is to be displayed for device based licensing
 * @param {Object} options.intl - The React intl object
 * @param {LicenseGroupList} [options.licenseGroupList] - The product profile
 *   list. If a team product this will be omitted.
 * @param {Function} [options.openDrawer] - Call back method for when drawer
 *   opens
 * @param {Product} options.product - The product for which messages are being
 *   displayed
 * @returns {Object[]} - Banner messages to be displayed
 */
async function notify({consumables, deviceData, intl, licenseGroupList, openDrawer, product}) {
  const messages = [
    notifyForPendingPaymentLicenses({intl, product}),
    notifyForGracePastDueLicenses({intl, product}),
    notifyForPastDueLicenses({intl, product}),
  ];
  if (product.isLegacyDeviceLicense()) {
    messages.push(notifyForOverDeployedDeviceLicenses({deviceData, intl}));
    if (shouldRenewDevices({deviceData})) {
      messages.push(notifyForPreAnniversaryPartialRenewal({deviceData, intl, openDrawer, product}));
      messages.push(
        notifyForPostAnniversaryPartialRenewal({deviceData, intl, openDrawer, product})
      );
    }
  } else {
    messages.push(notifyForNoAvailableLicenses({intl, product}));
    messages.push(notifyForPostAnniversaryNoRenewal({intl, product}));
    messages.push(notifyForUnallocatedProductQuota({intl, licenseGroupList, product}));
    messages.push(notifyForUnassignUsersBeforeAnniversary({intl, product}));
    messages.push(notifyForNoAccessUsers({intl, product}));
    messages.push(notifyForPreAnniversaryPartialRenewal({deviceData, intl, openDrawer, product}));
    messages.push(notifyForPostAnniversaryPartialRenewal({deviceData, intl, openDrawer, product}));
  }

  if (product.isGroupConsumable() && consumables) {
    messages.push(notifyForLowOrDepletedConsumables({consumables, intl, product}));
  }

  const bannersToDisplay = await Promise.all(messages);

  // sort the messages by variant: error, warning, info
  return compact(bannersToDisplay).sort((a, b) => {
    if (a.variant === b.variant) {
      return 0;
    }
    if (a.variant === 'error' || (a.variant === 'warning' && b.variant === 'info')) {
      return -1;
    }
    return 1;
  });
}

// Private methods

// Only if there are devices to renew should the renewalSummary be used.
function shouldRenewDevices({deviceData}) {
  if (deviceData) {
    const devices = deviceData?.data.devices;
    const summary = deviceData?.data.packagePool.summary;

    const {activated} = summary.activation;
    const {entitled} = summary.payment;
    const {numRenewalLicenses, numRenewingLicenses} = summary.renewal;

    const numDevicesToRemove = Math.max(
      numRenewalLicenses - numRenewingLicenses - entitled + activated,
      0
    );

    const devicesRequiringRenewal = devices
      .filter((device) => device.status !== DEVICE_STATUS.OVER_ACTIVATED) // filter out over-activated devices
      .slice(0, numDevicesToRemove);

    return devicesRequiringRenewal.length > 0;
  }
  return false;
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalAPMGracePastDuePageBanner(notificationPayload, intl) {
  return createTeamDirectCCPaypalPageBanner(
    {
      billingFrequency: 'monthly',
      complianceStatusKey: 'gracePastDueLicenses',
      handleButtonClick: goToEditPayment,
      name: 'grace-past-due-licenses',
      notificationPayload,
    },
    intl
  );
}

function createTeamDirectCCPaypalAPMPastDuePageBanner(notificationPayload, intl) {
  return createTeamDirectCCPaypalPageBanner(
    {
      billingFrequency: 'monthly',
      complianceStatusKey: 'pastDueLicenses',
      name: 'past-due-licenses',
      notificationPayload,
    },
    intl
  );
}

function createTeamDirectCCPaypalPageBanner(options, intl) {
  const {billingFrequency, complianceStatusKey, name, notificationPayload, handleButtonClick} =
    options;
  return notifyError({
    body: intl.formatMessage(
      {
        id: `product.pageBanner.contract.compliance.${complianceStatusKey}.teamDirect.creditCardPaypal.${billingFrequency}.body`,
      },
      {goUrl: (str) => <GoUrl name="CCMTeamSupport">{str}</GoUrl>}
    ),
    buttonTxt: handleButtonClick
      ? intl.formatMessage({
          id: `product.pageBanner.contract.compliance.${complianceStatusKey}.teamDirect.creditCardPaypal.${billingFrequency}.link`,
        })
      : undefined,
    handleButtonClick,
    header: intl.formatMessage(
      {
        id: `product.pageBanner.contract.compliance.${complianceStatusKey}.teamDirect.creditCardPaypal.${billingFrequency}.messageFormat.title`,
      },
      notificationPayload
    ),
    name,
  });
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalPUFGracePastDuePageBanner(notificationPayload, intl) {
  return createTeamDirectCCPaypalPageBanner(
    {
      billingFrequency: 'annual',
      complianceStatusKey: 'gracePastDueLicenses',
      handleButtonClick: goToEditPayment,
      name: 'grace-past-due-licenses',
      notificationPayload,
    },
    intl
  );
}

function createTeamDirectCCPaypalPUFPastDuePageBanner(notificationPayload, intl) {
  return createTeamDirectCCPaypalPageBanner(
    {
      billingFrequency: 'annual',
      complianceStatusKey: 'pastDueLicenses',
      name: 'past-due-licenses',
      notificationPayload,
    },
    intl
  );
}

function getMsgWithAnniversaryDate(intl, product, renewalSummary, keyPrefix) {
  return getTranslatedMessage(intl, product, keyPrefix, {
    anniversaryDate: intl.formatDate(
      new Date(renewalSummary.anniversaryDate),
      DATE_FORMATS.default
    ),
    anniversaryTime: intl.formatDate(
      new Date(renewalSummary.anniversaryDate),
      DATE_FORMATS.timeOnly
    ),
    resellerName: renewalSummary.resellerName,
  });
}

function getMsgWithRenewalWindowEndDate(intl, product, renewalSummary, keyPrefix) {
  return getTranslatedMessage(intl, product, keyPrefix, {
    endDate: intl.formatDate(new Date(renewalSummary.renewalWindowEndDate), DATE_FORMATS.default),
    endTime: intl.formatDate(new Date(renewalSummary.renewalWindowEndDate), DATE_FORMATS.timeOnly),
    resellerName: renewalSummary.resellerName,
  });
}

function getTranslatedMessage(intl, product, keyPrefix, values) {
  const subKey = product.fulfillableItemList.hasOverdelegationAllowed() ? 'nonUser' : 'user';
  return intl.formatMessage(
    {id: `product.pageBanner.contract.compliance.${keyPrefix}.${subKey}`},
    values
  );
}

// @ToDo - port this route to React
function goToEditPayment() {
  navBus.go('account.account', {action: ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT});
}

function notifyForGracePastDueLicenses({intl, product}) {
  const notificationPayload = hasGracePastDueLicensesForProduct(product);

  if (notificationPayload) {
    notificationPayload.totalGracePastDueLicenses = notificationPayload.count;
    switch (notificationPayload.complianceContext) {
      case COMPLIANCE_CONTEXT.INDIRECT:
        return notifyWarning({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.indirect.messageFormat.body',
            },
            {
              endDate: intl.formatDate(new Date(notificationPayload.endDate), DATE_FORMATS.default),
              hasEndDate: notificationPayload.endDate !== undefined,
              resellerName: notificationPayload.resellerName,
            }
          ),
          name: 'grace-past-due-licenses',
        });

      case COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE: {
        return notifyError({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectPO.body',
            },
            {
              link: (str) => (
                <Link>
                  <a className="\href-link\" href="\mailto:CTTPO-Credit@adobe.com\">
                    {str}
                  </a>
                </Link>
              ),
            }
          ),
          buttonTxt: intl.formatMessage({
            id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectPO.link',
          }),
          handleButtonClick: goToBillingHistory,
          header: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectPO.messageFormat.title',
            },
            notificationPayload
          ),
          name: 'grace-past-due-licenses',
        });
      }
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM:
        return createTeamDirectCCPaypalAPMGracePastDuePageBanner(notificationPayload, intl);
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF:
        return createTeamDirectCCPaypalPUFGracePastDuePageBanner(notificationPayload, intl);
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER:
        return notifyError({
          body: intl.formatMessage({
            id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectDR.body',
          }),
          buttonTxt: intl.formatMessage({
            id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectDR.link',
          }),
          handleButtonClick: goToEditPayment,
          header: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.gracePastDueLicenses.teamDirectDR.messageFormat.title',
            },
            notificationPayload
          ),
          name: 'grace-past-due-licenses',
        });
      default:
        return undefined;
    }
  }
  return undefined;
}

function notifyForLowOrDepletedConsumables({consumables, intl, product}) {
  if (!isEmpty(consumables)) {
    const consumable = consumables[0];
    const key = getThresholdStatusKey(consumable);
    if (!key) {
      return undefined;
    }
    // Assumes product is indirect or EVIP.
    if (product.includesBusinessOrEnterpriseSign()) {
      const contractList = rootStore.organizationStore.contractList;
      const targetContract = contractList.getFirstContractForProduct(product);
      const resellerName = targetContract.getResellerName();
      return notifyInfo({
        body: intl.formatMessage(
          {id: `product.pageBanner.contract.compliance.signTransactions.${key}.body`},
          {resellerName}
        ),
        header: intl.formatMessage({
          id: `product.pageBanner.contract.compliance.signTransactions.${key}.title`,
        }),
        name: `sign-transactions-${key}`,
      });
    }
  }
  return undefined;
}

async function notifyForNoAccessUsers({intl, product}) {
  const hasDomainClaiming = product.fulfillableItemList.hasDomainClaiming();

  if (hasDomainClaiming) {
    const canAssignType1Users = canAssignType1User(product);
    if (!canAssignType1Users) {
      const noAccessUsers = await ProductNoAccessUserList.get({
        orgId: rootStore.organizationStore.activeOrgId,
        pageSize: 10, // To match page size params for no access scorecard and drawer for caching purposes
        productId: product.id,
      });
      return noAccessUsers.hasContent()
        ? notifyInfo({
            body: intl.formatMessage(
              {
                id: 'product.pageBanner.contract.compliance.noAccessUsers.messageFormat.body',
              },
              {goUrl: (str) => <GoUrl name="ac_identity">{str}</GoUrl>}
            ),
            name: 'no-access-user',
          })
        : undefined;
    }
    return undefined;
  }
  return undefined;
}

function notifyForNoAvailableLicenses({intl, product}) {
  // Even if there are no available licenses, suppress the message do not show the no available licenses banner if a
  // trial product or a direct team that has no available licenses due to all licenses being in the PAST_DUE state.
  if (product.hasNoAvailableLicenses()) {
    if (
      trialHelper.isTrialProduct(product) ||
      (product.isTeamDirect() && product.licenseTupleList.hasPastDueLicenses())
    ) {
      return undefined;
    }

    if (product.isOveruseCustomerControllable()) {
      return notifyInfo({
        body: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.noLicensesOveruseLimitMessage',
        }),
        name: 'no-licenses',
      });
    }

    if (feature.isEnabled('temp_add_licenses_cta_blue')) {
      const cartItems = [
        {
          offerId: product.offerId,
          quantity: 1,
        },
      ];
      return notifyInfo({
        body: intl.formatMessage(
          {
            id: 'product.pageBanner.contract.compliance.noLicensesMessageWithLink',
          },
          {
            link: (str) => <AddProductModalLink items={cartItems} linkText={str} />,
          }
        ),

        name: 'no-licenses',
      });
    }
    return notifyInfo({
      body: intl.formatMessage({
        id: 'product.pageBanner.contract.compliance.noLicensesMessage',
      }),
      name: 'no-licenses',
    });
  }
  return undefined;
}

function notifyForOverDeployedDeviceLicenses({deviceData, intl}) {
  if (deviceData) {
    const overActivated = deviceData?.data.packagePool.summary.activation.overActivated;
    if (overActivated > 0) {
      const notificationsPayload = {count: overActivated};
      return notifyError({
        body: intl.formatMessage(
          {
            id: 'product.pageBanner.contract.compliance.overDeploymentMessage.messageFormat.content',
          },
          notificationsPayload
        ),
        header: intl.formatMessage(
          {
            id: 'product.pageBanner.contract.compliance.overDeploymentMessage.messageFormat.title',
          },
          notificationsPayload
        ),
        name: 'over-deployment',
      });
    }
  }
  return undefined;
}

function notifyForPastDueLicenses({intl, product}) {
  const notificationPayload = hasPastDueLicensesForProduct(product);

  if (notificationPayload) {
    switch (notificationPayload.complianceContext) {
      case COMPLIANCE_CONTEXT.INDIRECT:
        return notifyInfo({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pastDueLicenses.indirect.messageFormat.body',
            },
            notificationPayload
          ),
          name: 'past-due-licenses',
        });
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE: {
        return notifyError({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pastDueLicenses.teamDirectPO.body',
            },
            {
              link: (str) => (
                <Link>
                  <a href="mailto:CTTPO-Credit@adobe.com" rel="noreferrer" target="_blank">
                    {str}
                  </a>
                </Link>
              ),
            }
          ),
          buttonTxt: intl.formatMessage({
            id: 'product.pageBanner.contract.compliance.pastDueLicenses.teamDirectPO.link',
          }),
          handleButtonClick: goToBillingHistory,
          header: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pastDueLicenses.teamDirectPO.messageFormat.title',
            },
            notificationPayload
          ),
          name: 'past-due-licenses',
        });
      }
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM:
        return createTeamDirectCCPaypalAPMPastDuePageBanner(notificationPayload, intl);
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF:
        return createTeamDirectCCPaypalPUFPastDuePageBanner(notificationPayload, intl);
      case COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER:
        return notifyError({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pastDueLicenses.teamDirectDR.body',
            },
            {goUrl: (str) => <GoUrl name="CCMTeamSupport">{str}</GoUrl>}
          ),
          header: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pastDueLicenses.teamDirectDR.messageFormat.title',
            },
            notificationPayload
          ),
          name: 'past-due-licenses',
        });
      default:
        return undefined;
    }
  }
  return undefined;
}

function notifyForPendingPaymentLicenses({intl, product}) {
  const notificationPayload = hasPendingPaymentLicensesForProduct(product);

  if (notificationPayload) {
    if (notificationPayload.complianceContext === COMPLIANCE_CONTEXT.INDIRECT) {
      if (notificationPayload.buyingProgram === CONTRACT_BUYING_PROGRAM.VIPMP) {
        return notifyInfo({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.pendingPaymentLicensesVIPMP.messageFormat.body',
            },
            {
              count: notificationPayload.count,
              endDate: intl.formatDate(new Date(notificationPayload.endDate), DATE_FORMATS.default),
              hasEndDate: notificationPayload.endDate !== undefined,
            }
          ),
          header: intl.formatMessage({
            id: 'product.pageBanner.contract.compliance.pendingPaymentLicensesVIPMP.header',
          }),
          name: 'pending-payment-licenses-vipmp',
        });
      }
      return notifyInfo({
        body: intl.formatMessage(
          {id: 'product.pageBanner.contract.compliance.pendingPaymentLicenses.messageFormat.body'},
          {
            endDate: intl.formatDate(new Date(notificationPayload.endDate), DATE_FORMATS.default),
            hasEndDate: notificationPayload.endDate !== undefined,
            resellerName: notificationPayload.resellerName,
          }
        ),
        name: 'pending-payment-licenses',
      });
    }
  }
  return undefined;
}

function notifyForPostAnniversaryNoRenewal({intl, product}) {
  const renewalSummary = getRenewalSummary(product);

  if (
    renewalSummary.isInPostAnniversaryRenewalWindow &&
    renewalSummary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.NONE
  ) {
    return notifyWarning({
      body: getMsgWithRenewalWindowEndDate(
        intl,
        product,
        renewalSummary,
        renewalSummary.resellerName
          ? 'postAnniversary.noRenewal'
          : 'postAnniversary.noRenewalNoReseller'
      ),
      name: 'post-anniversary-no-renewal',
    });
  }
  return undefined;
}

function notifyForPostAnniversaryPartialRenewal({intl, openDrawer, product}) {
  const renewalSummary = getRenewalSummary(product);
  if (
    renewalSummary.isInPostAnniversaryRenewalWindow &&
    renewalSummary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.PARTIAL
  ) {
    if (product.isLegacyDeviceLicense()) {
      return notifyWarning({
        body: intl.formatMessage(
          {
            id: 'product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.partial.postAnniversary',
          },
          {
            renewalWindowEndDate: intl.formatDate(
              new Date(renewalSummary.renewalWindowEndDate),
              DATE_FORMATS.default
            ),
            resellerName: renewalSummary.resellerName,
          }
        ),

        buttonTxt: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.buttonTxt',
        }),
        handleButtonClick: openDrawer.current,
        header: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.partial.title',
        }),
        name: 'post-anniversary-partial-renewal',
      });
    }
    const keyPrefix = renewalSummary.resellerName
      ? 'postAnniversary.partialRenewal'
      : 'postAnniversary.partialRenewalNoReseller';
    return notifyWarning({
      body: getMsgWithRenewalWindowEndDate(intl, product, renewalSummary, keyPrefix),
      name: 'post-anniversary-partial-renewal',
    });
  }
  return undefined;
}

function notifyForPreAnniversaryPartialRenewal({intl, openDrawer, product}) {
  const renewalSummary = getRenewalSummary(product);
  if (
    renewalSummary.isInPreAnniversaryRenewalWindow &&
    renewalSummary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.PARTIAL
  ) {
    if (product.isLegacyDeviceLicense()) {
      return notifyWarning({
        body: intl.formatMessage(
          {
            id: `product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.partial.preAnniversary`,
          },
          {
            anniversaryDate: intl.formatDate(
              new Date(renewalSummary.anniversaryDate),
              DATE_FORMATS.default
            ),
            resellerName: renewalSummary.resellerName,
          }
        ),
        buttonTxt: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.buttonTxt',
        }),
        handleButtonClick: openDrawer.current,
        header: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.deviceLicenseRenewalPageBanner.partial.title',
        }),
        name: 'pre-anniversary-partial-renewal',
      });
    }
    if (renewalSummary.isVIPMPContract) {
      const subKey = product.fulfillableItemList.hasOverdelegationAllowed() ? 'nonUser' : 'user';
      return notifyInfo({
        body: intl.formatMessage(
          {
            id: `product.pageBanner.contract.compliance.preAnniversary.partialRenewalVIPMP.messageFormat.${subKey}`,
          },
          {
            anniversaryDate: intl.formatDate(
              new Date(renewalSummary.anniversaryDate),
              DATE_FORMATS.default
            ),
            anniversaryTime: intl.formatDate(
              new Date(renewalSummary.anniversaryDate),
              DATE_FORMATS.timeOnly
            ),
            licenseCount: renewalSummary.needRenewalQuantity,
            // https://git.corp.adobe.com/admin-tribe/onesie/issues/9764
            // The user count should have a dedicated value for it
            userCount: renewalSummary.needRenewalQuantity,
          }
        ),
        name: 'pre-anniversary-partial-renewal',
      });
    }
    return notifyInfo({
      body: getMsgWithAnniversaryDate(
        intl,
        product,
        renewalSummary,
        'preAnniversary.partialRenewal'
      ),
      name: 'pre-anniversary-partial-renewal',
    });
  }
  return undefined;
}

function notifyForUnallocatedProductQuota({intl, licenseGroupList, product}) {
  if (licenseGroupList && canViewSetQuotaButton(product)) {
    const assignableQuota = product.getAssignableLicenseCount();
    const totalAllocatedQuota = licenseGroupList.getTotalAllocatedQuota();
    if (assignableQuota > totalAllocatedQuota) {
      return notifyInfo({
        body: intl.formatMessage({
          id: 'product.pageBanner.contract.compliance.unallocatedProductQuota',
        }),
        name: 'unallocated-product-quota',
        removeWithKey: `${rootStore.organizationStore.activeOrgId}_unallocatedProductQuotaBanner`,
      });
    }
  }
  return undefined;
}

function notifyForUnassignUsersBeforeAnniversary({intl, product}) {
  // Do not show the unassign users banner for quota products
  const productHasConsumableQuotaTypeItems =
    product.fulfillableItemList.hasConsumableQuotaTypeItems({
      includeDelegationTypePerson: false,
      requireDelegationConfigurable: false,
    });

  if (!productHasConsumableQuotaTypeItems) {
    const contractList = rootStore.organizationStore.contractList;
    const contract = getDirectContract(contractList);
    if (contract?.isInRenewalWindow()) {
      const provisionedQuantity = product.provisionedQuantity ?? 0;
      const renewedQuantity = product.licenseTupleList.getRenewedTotal(
        contract.getRenewalWindowEndDate()
      );
      // If there are more provisioned quantities than renewed, then we show banner
      const hasLessRenewedThanProvisioned = renewedQuantity < provisionedQuantity;
      const hasAnniversaryDate = contract.getAnniversaryDate() !== undefined;
      if (hasLessRenewedThanProvisioned && hasAnniversaryDate) {
        return notifyInfo({
          body: intl.formatMessage(
            {
              id: 'product.pageBanner.contract.compliance.unassignUsersBeforeAnniversaryMessage.content',
            },
            {
              anniversaryDate: intl.formatDate(
                new Date(contract.getAnniversaryDate()),
                DATE_FORMATS.default
              ),
              goUrl: (str) => <GoUrl name="aac_direct_renewals">{str}</GoUrl>,
            }
          ),
          name: 'unassign-users-before-anniversary',
        });
      }
    }
    return undefined;
  }
  return undefined;
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notify};
/* eslint-enable max-lines -- this file requires more lines*/
