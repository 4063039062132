import {Flex, Link, Radio, RadioGroup, Text} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {useIntl} from 'react-intl';

import EditSubContent from '../../../edit-sub-content/EditSubContent';
import {expandableSelectGroupPropType} from '../../../prop-types/configurationGroupsPropTypes';

const radioButtonIdPrefix = 'sub-content-id-';

const displaySubContentDescription = (subContent) => subContent.description || subContent.linkHref;

const ExpandableSelectPermissionsForm = ({group, onChange}) => {
  const intl = useIntl();

  const selectedSubContent = group.content.find((subContent) => subContent.selected);
  const changeSelectedSubContentId = (newSelectedId) => {
    // onSelectChange is called when tabbing over all children of a RadioGroup
    // therefore we need to check that the item that has been tabbed over is indeed a radio button
    if (!newSelectedId || !newSelectedId.startsWith(radioButtonIdPrefix)) return;
    const newGroup = {
      ...group,
      content: group.content.map((subContent) => ({
        ...subContent,
        selected: subContent.id === newSelectedId.slice(radioButtonIdPrefix.length),
      })),
    };
    onChange(newGroup);
  };

  const onSubContentChange = (newSubContent, newAutoInclude) => {
    onChange({
      ...group,
      content: group.content.map((subContent) => ({
        ...(subContent.id === newSubContent.id
          ? {
              ...newSubContent,
              ...('allSelected' in subContent && {allSelected: newAutoInclude}),
            }
          : subContent),
      })),
    });
  };

  if (!selectedSubContent) return null;

  return (
    <RadioGroup
      aria-label={group.name}
      data-testid="permissions-expandable-select-form"
      onChange={changeSelectedSubContentId}
      value={`${radioButtonIdPrefix}${selectedSubContent.id}`}
    >
      {group.content.map((subContent) => (
        <Fragment key={subContent.id}>
          {/* overriding the onKeyDown gives desired focus behaviour of keeping it within the radio group when arrow keys are used */}
          <Radio onKeyDown={() => {}} value={`${radioButtonIdPrefix}${subContent.id}`}>
            <Text>{subContent.name}</Text>
          </Radio>
          <Flex direction="column" gap="size-0" marginStart="size-300">
            {displaySubContentDescription(subContent) && (
              <Text>
                {subContent.description}
                {subContent.description && subContent.linkHref && ' '}
                {subContent.linkHref && (
                  <Text>
                    <Link marginX="size-40">
                      <a href={subContent.linkHref} rel="noreferrer" target="_blank">
                        <Flex
                          alignItems="center"
                          direction="row"
                          gap="size-50"
                          UNSAFE_style={{display: 'inline-flex'}}
                        >
                          {intl.formatMessage(
                            {
                              id: 'products.productProfilePermissions.editPermissionsModal.learnMoreAbout',
                            },
                            {name: subContent.name}
                          )}
                          <LinkOutIcon
                            aria-label={intl.formatMessage({
                              id: 'products.productProfilePermissions.editPermissionsModal.opensWindow',
                            })}
                            flexShrink={0}
                            size="XS"
                          />
                        </Flex>
                      </a>
                    </Link>
                  </Text>
                )}
              </Text>
            )}
            {selectedSubContent.id === subContent.id && (
              <EditSubContent
                allowAutoInclude={'allSelected' in selectedSubContent}
                autoInclude={'allSelected' in selectedSubContent && selectedSubContent.allSelected}
                groupHelpLink={group.linkHref}
                groupReadOnly={'editable' in group && !group.editable}
                onChange={onSubContentChange}
                subContent={subContent}
              />
            )}
          </Flex>
        </Fragment>
      ))}
    </RadioGroup>
  );
};

ExpandableSelectPermissionsForm.propTypes = {
  /**
   * The group to edit.
   */
  group: expandableSelectGroupPropType.isRequired,

  /**
   * Callback when the group is changed. This takes an argument of the new group.
   */
  onChange: PropTypes.func.isRequired,
};

export default ExpandableSelectPermissionsForm;
