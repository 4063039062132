(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.directories.sync').factory('DirectoryLink', directoryLink);

  /* @ngInject */
  function directoryLink(_, directorySync, PARTNER_TYPES, SYNC_TYPE) {
    class DirectoryLink {
      constructor(options = {}) {
        _.assign(this, options);
      }

      /**
       * Checks to see if the partner type is azure.
       *
       * @return {boolean} True if azure, false otherwise
       */
      isAzure() {
        return this.partnerType === PARTNER_TYPES.AZURE;
      }

      /**
       * Checks to see if the partner type is gsuite.
       *
       * @return {boolean} True if gsuite, false otherwise
       */
      isGoogle() {
        return this.partnerType === PARTNER_TYPES.GSUITE;
      }

      /**
       * Checks to see if the sync type is group centric
       *
       * @return {boolean} True if group centric, false otherwise
       */
      isGroupCentric() {
        return this.synchronizationType === SYNC_TYPE.GROUP_CENTRIC;
      }

      /**
       * Checks to see if the sync type is scim
       *
       * @return {boolean} True if group scim, false otherwise
       */
      isScim() {
        return this.synchronizationType === SYNC_TYPE.SCIM;
      }

      /**
       * Checks to see if the sync type is scim2
       *
       * @return {boolean} True if group scim2, false otherwise
       */
      isScim2() {
        return this.synchronizationType === SYNC_TYPE.SCIM2;
      }

      /**
       * Checks to see if the directory is a synced one
       *
       * @return {boolean} True if we have a link with partnerType and
       *                   synchronizationType present, false otherwise
       */
      isSyncedDirectory() {
        return !(_.isUndefined(this.partnerType) && _.isUndefined(this.synchronizationType));
      }

      /**
       * Gets a directory link by querying the directory sync service. If one
       * is found we can then interrogate the instance and ask about what type of
       * sync is used and what partner. If no link is present all the methods will
       * return null. Check `isSyncedDirectory` for `true` to know if the link
       * exists or not.
       *
       * @param {String} directoryId The directory id expected to have a link
       *
       * @returns {Promise<DirectoryLink>} An instance of the directory link
       */
      static get(directoryId) {
        return directorySync.linkedOrgs.query().$promise.then((links) => {
          const link = _.find(links, {imsId: directoryId});
          return new DirectoryLink(link);
        });
      }

      /**
       * Gets an array of directory links by querying the directory sync service.
       * If one is found we can then interrogate the individual instance and ask
       * about what type of sync is used and what partner. If no link is present
       * all the methods will return an empty array.
       *
       * @param {Object} directories The directory id expected to have a link
       *
       * @returns {Array<Promise<DirectoryLink>>} An array of instances of directory links
       */
      static getAll(directories) {
        const directoryListLinks = [];
        return directorySync.linkedOrgs.query().$promise.then((links) => {
          _.forEach(directories, (directory) => {
            const link = _.find(links, {imsId: directory.id});
            if (link) {
              directoryListLinks.push(new DirectoryLink(link));
            }
          });
          return directoryListLinks;
        });
      }
    }

    return DirectoryLink;
  }
})();
