import rootStore from 'core/RootStore';
import {PATH_PRODUCT_DETAILS_ADMINS} from 'features/products/routing/productsPaths';

import help from '../../constants/help';
import {
  hasEnterpriseCloudProductAccess,
  isDMA,
  isDMAWithAnalyticsAndAudience,
} from '../../utils/helpUtilsProducts';

const {TOPICS, LINK_TARGET_TYPE, LINK_TYPE, HELP_ITEM_TYPE} = help;

function goToProductLink(product) {
  const productLink =
    product?.links?.product_admin[0]?.href || product?.links?.provisioned_user[0]?.href;

  if (productLink) {
    return {
      key: 'help.topics.enterpriseMarketingCloud2.link.one.key',
      locator: productLink,
      target: LINK_TARGET_TYPE.EXTERNAL,
      type: LINK_TYPE.BUTTON,
    };
  }

  return undefined;
}

/**
 *
 * @returns content for product tab -> enterprise cloud product help
 * DMA products: Campaign, AEM, Media Optimizer, Primetime, Social, Target
 */
const enterpriseCloud = () => {
  const access = hasEnterpriseCloudProductAccess;
  const {productList} = rootStore.organizationStore;

  return productList?.items?.map((product) => {
    const codeLC = product?.code?.toLowerCase();

    if (isDMAWithAnalyticsAndAudience(codeLC)) {
      return {
        access,
        content: [
          {
            links: [
              {
                key: 'help.topics.enterpriseMarketingCloud.link.one.key',
                locator: PATH_PRODUCT_DETAILS_ADMINS,
                migrated: true,
                params: {productId: product.id},
                target: LINK_TARGET_TYPE.APPLICATION,
                type: LINK_TYPE.BUTTON,
              },
            ],
            text: 'help.topics.enterpriseMarketingCloud.text',
            translateValues: {
              productName: product.shortName,
            },
            type: HELP_ITEM_TYPE.STEP,
          },
        ],

        id: `${TOPICS.ENTERPRISE_MARKETING_CLOUD_PREFIX}-${product.id}`,
        parents: [
          {
            id: TOPICS.PRODUCTS,
          },
        ],
        title: 'help.topics.enterpriseMarketingCloud.title',

        translateValues: {
          productName: product.shortName,
        },
      };
    }

    if (isDMA(codeLC)) {
      const dmaTopic = {
        access,
        content: [
          {
            text: 'help.topics.enterpriseMarketingCloud2.text',
            translateValues: {
              productName: product.shortName,
            },
            type: HELP_ITEM_TYPE.STEP,
          },
        ],

        id: `${TOPICS.ENTERPRISE_MARKETING_CLOUD_PREFIX2}-${product.id}`,
        parents: [
          {
            id: TOPICS.PRODUCTS,
          },
        ],
        title: 'help.topics.enterpriseMarketingCloud2.title',
        translateValues: {
          productName: product.shortName,
        },
      };

      const productLink = goToProductLink(product);
      if (productLink) {
        dmaTopic.content[0].links = [productLink];
      }

      return dmaTopic;
    }

    return {access: () => false};
  });
};

export default enterpriseCloud;
