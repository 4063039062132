/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase.payment-icons:appPaymentIcon
 *
 * @description A component to show the payment icon.
 * @example
 *  <app-payment-icon payment-type='paypal'></app-payment-icon>
 * @binding {String} [paymentType]. Should be one of the keys in MAP_PAYMENT_TYPE_TO_ICON_CLASS.
 *   The paymentType is case-insensitive.
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appPaymentIcon', {
    bindings: {
      paymentType: '@',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/payment-icon/payment-icon.component.html',
  });

  /* @ngInject */
  function controller(_) {
    const vm = this;

    /**
     * Available icons as defined in
     * https://git.corp.adobe.com/Adobe-Anyware/payment-info-editor/blob/develop/packages/sprite/src/components/v-commerce-sprite.js
     * where keys are lower case
     */
    const MAP_PAYMENT_TYPE_TO_ICON_CLASS = {
      amex: 'payment-icon-amex',
      bc: 'payment-icon-bc',
      boleto: 'payment-icon-boleto',
      bt: 'payment-icon-bt',
      chinaunionpay: 'payment-icon-china-union-pay',
      cvs: 'payment-icon-cvs',
      directdebit: 'payment-icon-direct-debit',
      discover: 'payment-icon-discover',
      hana: 'payment-icon-hana',
      hyundai: 'payment-icon-hyundai',
      jcb: 'payment-icon-jcb',
      kb: 'payment-icon-kb',
      keb: 'payment-icon-keb',
      krlc: 'payment-icon-krlc',
      lotte: 'payment-icon-lotte',
      mastercard: 'payment-icon-mastercard',
      nh: 'payment-icon-nh',
      paypal: 'payment-icon-paypal',
      po: 'payment-icon-purchase-order',
      samsung: 'payment-icon-samsung',
      shinhan: 'payment-icon-shinhan',
      visa: 'payment-icon-visa',
    };

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges() {
      // While paymentType is required, if invalid, icon may still take up space but will not show the entire sprite.
      // paymentIconClass will be undefined if paymentType is undefined or unknown.
      vm.paymentIconClass = MAP_PAYMENT_TYPE_TO_ICON_CLASS[_.toLower(vm.paymentType)];
    }
  }
})();
