import {Content, Header} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {Drawer} from '@pandora/react-drawer';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';

import ActivationSection from './activation-section/ActivationSection';
import {DeviceDetailsContextProvider} from './context/DeviceDetailsContext';
import Details from './details/Details';
import DetailsHeader from './header/Header';

/**
 * This component shows the device details in a drawer
 */
const DeviceDetailsDrawer = ({
  closeDeviceDetailsDrawer,
  deviceDetails,
  onDeviceUpdated,
  showIPAddress,
}) => {
  const nameId = useId();
  const [isDialogConfirmedAndClosed, setIsDialogConfirmedAndClosed] = React.useState(false);
  const currentDeviceDetails = React.useRef(deviceDetails);
  const isSuccess = React.useRef();
  const isOnDeviceAlreadyUpdated = React.useRef();
  // Side effect to close the device details drawer
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && isDialogConfirmedAndClosed) {
      closeDeviceDetailsDrawer();
    }

    return () => {
      isMounted = false;
      if (isSuccess.current && isDialogConfirmedAndClosed && !isOnDeviceAlreadyUpdated.current) {
        isOnDeviceAlreadyUpdated.current = true;
        onDeviceUpdated();
      }
    };
  }, [
    closeDeviceDetailsDrawer,
    isDialogConfirmedAndClosed,
    onDeviceUpdated,
    deviceDetails,
    isSuccess,
    isOnDeviceAlreadyUpdated,
  ]);

  return (
    <DeviceDetailsContextProvider
      value={{
        closeDeviceDetailsDrawer,
        deviceDetails: currentDeviceDetails.current,
        onDeviceUpdated: () => {
          isSuccess.current = true;
        },
        onDialogUpdated: () => setIsDialogConfirmedAndClosed(true),
        showIPAddress,
      }}
    >
      <Drawer aria-labelledby={nameId}>
        <Header>
          <DetailsHeader />
        </Header>
        <Content>
          <ActivationSection />
          <Details />
        </Content>
      </Drawer>
    </DeviceDetailsContextProvider>
  );
};

DeviceDetailsDrawer.propTypes = {
  /**
   * Callback function to close device details drawer
   */
  closeDeviceDetailsDrawer: PropTypes.func.isRequired,

  /**
   * Details of the current device
   */
  deviceDetails: PropTypes.instanceOf(Device).isRequired,

  /**
   * Callback function to refetch FRL device details
   */
  onDeviceUpdated: PropTypes.func.isRequired,

  /**
   * showIPAddress - Flag which indicates to show/hide the IP Address column in the Device Table
   */
  showIPAddress: PropTypes.bool.isRequired,
};

export default DeviceDetailsDrawer;
