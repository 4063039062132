(function () {
  /**
   * @ngdoc module
   * @name app.core.directories.auzre
   * @description module that defines azure related services
   */
  angular.module('app.core.directories.azure', [
    'app.core.api.directory-sync',
    'app.widgets.directories.azure',
    'binky.core.lodash',
    'binky.core.storage-utils',
  ]);
})();
