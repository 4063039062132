import {useCallback} from 'react';

import useDomainList from 'features/settings/hooks/api/useDomainList';
import useDomainListState from 'features/settings/hooks/domains/useDomainListState';
import useJilTableOptions from 'features/settings/hooks/useJilTableOptions';

/**
 * A hook that holds the state for the list of directory domains.
 */
const useDirectoryDomainsListState = (directoryData, initialData) => {
  const [tableOptions, setTableOptions] = useJilTableOptions({
    sort: 'NAME',
  });

  const {getDirectoryDomainList} = useDomainList();

  // add the directoryId to the params as a default
  const getDirectoryDomains = useCallback(
    (params = tableOptions) => getDirectoryDomainList({directoryId: directoryData.id, ...params}),
    [directoryData.id, getDirectoryDomainList, tableOptions]
  );

  const directoryDomainsListState = useDomainListState(
    {
      fetchFn: getDirectoryDomains,
      setTableOptions,
      tableOptions,
    },
    initialData
  );

  // TODO: Send only the directoryId when directory details implemented with hooks instead of MobX.
  return {...directoryDomainsListState, directoryData, setTableOptions, tableOptions};
};

export default useDirectoryDomainsListState;
