import {DATE_FORMATS} from '@admin-tribe/binky-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './StatusCard.pcss';

const MINUTES_PER_HOUR = 60;
const MILLISECONDS_PER_SECOND = 1000;

const util = {
  /**
   * Returns a date object that represents the local time
   * of the given date object
   * @param {Date} date - date object to convert to local time
   * @returns {Date} - date object that represents the local time of the given date object
   */
  getLocalDateTime: (date) => {
    const offsetInMilliseconds =
      date.getTimezoneOffset() * MINUTES_PER_HOUR * MILLISECONDS_PER_SECOND;
    return new Date(date.getTime() - offsetInMilliseconds);
  },
};

/**
 * Represents an individual card that displays information
 * about a status event
 */
const StatusCard = ({event}) => {
  const intl = useIntl();

  const hasProductsAndHistory =
    event.products && event.products.length > 0 && event.products[0]?.history?.length > 0;
  const eventType = event.incidentSeverity ? 'incident' : 'maintenance';
  let statusToDisplay;
  if (eventType === 'incident' && event.status !== 'Closed') {
    statusToDisplay = event.incidentSeverity;
  } else if (eventType === 'maintenance' && hasProductsAndHistory) {
    const latestCmrStatusFromHistoryInProducts = event.products[0].history[0].cmrStatus;
    statusToDisplay = latestCmrStatusFromHistoryInProducts;
  } else {
    statusToDisplay = event.status;
  }

  const getMessage = () =>
    intl.formatMessage(
      {
        id: 'status.card.body',
      },
      {
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- bold event status
        bold: (str) => <Text UNSAFE_className={styles['status-bold']}>{str}</Text>,
        id: event.incidentId || event.id,
        service: event.name,
        status: statusToDisplay,
      }
    );

  const localDateTime = util.getLocalDateTime(new Date(event.latestMessageTime));

  return (
    <View
      borderBottomColor="gray-300"
      borderBottomWidth="size-10"
      borderRightColor="gray-300"
      borderRightWidth="size-10"
      borderTopColor="gray-300"
      borderTopWidth="size-10"
      data-eventtype={eventType}
      data-testid="status-card"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- color border
      UNSAFE_className={styles[`status-card-${statusToDisplay.toLowerCase()}`]}
    >
      {event && (
        <Flex direction="column" marginX="size-400" marginY="size-200">
          <Text
            UNSAFE_style={{
              fontSize: 'var(--spectrum-global-dimension-font-size-75)',
            }}
          >
            {intl.formatDate(localDateTime, DATE_FORMATS.dateTime)}
          </Text>
          <Text
            marginTop="size-100"
            UNSAFE_style={{
              fontSize: 'var(--spectrum-global-dimension-font-size-175)',
            }}
          >
            {getMessage()}
          </Text>
        </Flex>
      )}
    </View>
  );
};

StatusCard.propTypes = {
  /**
   * Represents a status event (either an incident or a maintenance event)
   */
  // eslint-disable-next-line react/forbid-prop-types -- skms objects subject to change
  event: PropTypes.object.isRequired,
};
export default StatusCard;
export {util};
