import {GoUrl} from '@admin-tribe/binky-ui';
import {ActionButton, DialogTrigger, Flex, Text} from '@adobe/react-spectrum';
import {TitledSection} from '@pandora/react-titled-section';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const PriceChangePopoverTrigger = () => {
  const intl = useIntl();

  return (
    <DialogTrigger crossOffset={-30} placement="left top" type="popover">
      <ActionButton
        aria-label={intl.formatMessage({id: 'account.priceChangePopover.infoAria'})}
        height="size-250"
        isQuiet
        minWidth="size-250"
        width="size-250"
      >
        <InfoOutline />
      </ActionButton>
      <TitledSection
        gap="size-75"
        margin="size-200"
        maxWidth="size-3600"
        rightHeaderContent={<InfoOutline />}
        title={intl.formatMessage({id: 'account.priceChangePopover.heading'})}
      >
        <Flex direction="column" gap="size-100">
          <Text>
            <FormattedMessage id="account.priceChangePopover.description" />
          </Text>
          <Text>
            <GoUrl name="subscription-price-increase-2022-business" noWrap={false}>
              <FormattedMessage id="account.priceChangePopover.link" />
            </GoUrl>
          </Text>
        </Flex>
      </TitledSection>
    </DialogTrigger>
  );
};

export default PriceChangePopoverTrigger;
