const SOPHIA_CONSTANTS =
  // eslint-disable-next-line angular/window-service
  window.onesieSrc2.common.services.sophia.SOPHIA_CONSTANTS;

(function () {
  /**
   * @deprecated should use src2/app/common/services/sophia/SophiaConstants
   */
  angular
    .module('app.core.sophia')
    .constant('ANALYTICS_ID', SOPHIA_CONSTANTS.ANALYTICS_ID)
    .constant('CLIENT_CODE', SOPHIA_CONSTANTS.CLIENT_CODE)
    .constant('DISMISSED_SOPHIA_CARD_IDS', SOPHIA_CONSTANTS.DISMISSED_SOPHIA_CARD_IDS)
    .constant('SOPHIA_REQUIRED_PARAMS', SOPHIA_CONSTANTS.SOPHIA_REQUIRED_PARAMS)
    .constant('SURFACE_ID', SOPHIA_CONSTANTS.SURFACE_ID);
})();
