import {OverlayWait} from '@admin-tribe/binky-ui';
import {
  SELECTION_MODE,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import updateMemberStore from 'common/stores/member-store/updateMemberStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';

import UserGroupListTable from './UserGroupListTable';
import UserGroupListTableActions from './UserGroupListTableActions';

/**
 * A table to display a JIL user list.
 * This should be wrapped in an MemberListContextProvider.
 */
const UserGroupListTableSection = () => {
  const {canRemoveMember, pageContext, store} = useMemberListContext();
  const intl = useIntl();
  const selectionMode = canRemoveMember ? SELECTION_MODE.MULTIPLE : SELECTION_MODE.NONE;

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: store.getItem(itemKey), pageContext});

  return (
    <OverlayWait isLoading={store.isLoading} showContent={!!store.currentItems} size="M">
      <TableSection
        getIsItemSelectable={getIsItemSelectable}
        getItemKey={(item) => item.id}
        isServerError={store.hasLoadingError}
        items={store.currentItems}
        onTableSectionChange={(params) => updateMemberStore({...params, store})}
        pageNumber={store.pageNumber}
        selectionMode={selectionMode}
        totalPages={store.totalPages}
      >
        <TableFilters
          hasSearch
          label={intl.formatMessage({id: 'common.userGroupListTableSection.search'})}
        />
        <TableActions>
          <UserGroupListTableActions />
        </TableActions>
        {(store.currentItems || store.hasLoadingError) && <UserGroupListTable />}
      </TableSection>
    </OverlayWait>
  );
};

// This syntax necessary to work around React 16 bug with displayName.
// ref: https://github.com/facebook/react/issues/18026
// ref: https://mobx.js.org/react-integration.html#displayname
export default observer(UserGroupListTableSection);
