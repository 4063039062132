import React from 'react';
import {Navigate, generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  canViewPackages,
  canViewServers,
  canViewTemplates,
} from 'core/packages/access/packagesAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import PreferencesPage from 'features/packages/components/preferences-page/PreferencesPage';
import AllPackagesPage from 'features/packages/pages/all-packages/AllPackagesPage';
import ServersPage from 'features/packages/pages/servers-page/ServersPage';
import TemplatesPage from 'features/packages/pages/templates-page/TemplatesPage';
import ToolsPage from 'features/packages/pages/tools-page/ToolsPage';
import PackagesWorkspace from 'features/packages/workspace/PackagesWorkspace';

import {PATH_ALL_PACKAGES, PATH_PACKAGES_ROOT} from './packagesPaths';

const allPackages = () =>
  generatePath(PATH_ALL_PACKAGES, {orgId: rootStore.organizationStore.activeOrgId});

/** Gathers all of the packages routes */
function packagesRoutes() {
  return [
    {
      children: [
        {
          element: <Navigate to={allPackages()} />,
          index: true,
        },
        {
          element: <TemplatesPage />,
          loader: buildAccessCheckLoader(canViewTemplates),
          path: 'adobe-templates',
        },
        {
          element: <AllPackagesPage />,
          path: 'all-packages',
        },
        {
          element: <PreferencesPage />,
          path: 'preferences',
        },
        {
          element: <ToolsPage />,
          path: 'tools',
        },
        {
          element: <ServersPage />,
          loader: buildAccessCheckLoader(canViewServers),
          path: 'servers',
        },
      ],
      element: <PackagesWorkspace />,
      loader: buildAccessCheckLoader(canViewPackages),
      path: PATH_PACKAGES_ROOT,
    },
  ];
}

export default packagesRoutes;
