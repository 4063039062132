(function () {
  const componentName = 'appOrganizationsSwitcherPulldown';
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.organizations:appOrganizationsSwitcherPulldown
   *
   * @description the org switcher pulldown widget
   *
   * @param {function} onClose The callback function to register for the close event.
   * @param {function} onOpen The callback function to register for the open event.
   *
   * @example <app-organizations-switcher-pulldown></app-organizations-switcher-pulldown>
   *
   */
  angular.module('app.widgets.organizations').component(componentName, {
    bindings: {
      onClose: '<?',
      onOpen: '<?',
    },
    controller,
    templateUrl: 'app/widgets/organizations/organizations-switcher-pulldown.component.html',
  });

  /* @ngInject */
  function controller(
    $translate,
    _,
    AuthenticatedUser,
    authentication,
    binkyUISrc2,
    feature,
    OrganizationList,
    OrganizationManager,
    ORGANIZATION_TYPE
  ) {
    const vm = this;
    const ORG_SWITCHER_SEARCH_ID_PREFIX = `${componentName}-`;

    _.assign(vm, {
      $onInit,
      binkyUISrc2,
      feature,
      filterToOrgTypes: [ORGANIZATION_TYPE.DIRECT, ORGANIZATION_TYPE.ENTERPRISE],
      onOrgSwitcherOpen,
      onSearch,
      searchLabelId: _.uniqueId(ORG_SWITCHER_SEARCH_ID_PREFIX),
      setActiveOrg,
    });

    /////////////////
    function $onInit() {
      _.assign(vm, {
        anniversaryDateLabel: $translate.instant('widgets.organizations.anniversaryDateLabel'),
        authenticatedUserId: AuthenticatedUser.get().getId(),
        linkedUserAccounts: OrganizationManager.getLinkedUserAccounts().items,
      });
      // we fetch the contracts on first load, to ensure the date is present on open
      refreshContracts();
    }

    function onOrgSwitcherOpen() {
      // We cache the activeOrg id on open, to avoid refreshing when it changes
      vm.activeOrgId = OrganizationList.get().activeOrg.id;
      if (vm.onOpen) {
        vm.onOpen();
      }
      vm.search = {name: ''};
    }

    function onSearch(value) {
      vm.search.name = value;
    }

    function setActiveOrg(selectedOrg) {
      authentication.switchTo(selectedOrg.userId, `${selectedOrg.orgId}/overview`);
    }

    function refreshContracts() {
      vm.anniversaryDate = undefined;
      vm.contracts = OrganizationManager.getContractsForActiveOrg();
      // we only handle the success case, as on error we simply hide the date
      // eslint-disable-next-line promise/catch-or-return
      vm.contracts.$promise.then(() => {
        // For convenience we cache the single earliest anniversary date
        vm.anniversaryDate = vm.contracts.getEarliestAnniversaryDate();
      });
    }
  }
})();
