import {Divider, Form, Text} from '@adobe/react-spectrum';
import {
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {ValidatedTextField} from '@pandora/react-validated-text-field';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

const RESELLER_CHANGE_CODE_REGEX = /^\d*$/;

// ModalDialog has its own custom styling with width set to 1100px
// And in order to use size="M" we need to reset the styling
const CUSTOM_DIALOG_STYLE = {};

// See: https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=bizarch&title=Proposal%3A+Support+Reseller-Initiated+Reseller+Changes+in+VIP
const CHANGE_CODE_LENGTH = 8;

/**
 * The modal opened by the clicking on Change Reseller which is on contract-info.
 */
const ChangeResellerModal = ({isOpen, onCancel, onSubmit}) => {
  const intl = useIntl();

  const [resellerChangeCode, setResellerChangeCode] = useState('');
  const [isCtaClicked, setIsCtaClicked] = useState(false);

  const onCta = () => {
    setIsCtaClicked(true);
    if (isValidResellerChangeCode(resellerChangeCode)) {
      onSubmit(resellerChangeCode);
    }
  };

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'common.modal.buttons.cancel',
    }),
    ctaLabel: intl.formatMessage({id: 'account.planDetails.changeResellerModal.ctaButton'}),
  });

  const isCtaDisabled = !hasExpectedLength(resellerChangeCode);
  const isSubmitting = isCtaClicked && isValidResellerChangeCode(resellerChangeCode);
  const isShowError = isCtaClicked && !isValidResellerChangeCode(resellerChangeCode);

  return isOpen ? (
    <ModalContainer>
      <ModalDialog
        analyticsModalId="change-reseller-modal"
        content={modalDialogContent}
        dataTestId="change-reseller-modal"
        dialogStyle={CUSTOM_DIALOG_STYLE}
        isCtaDisabled={isCtaDisabled || isShowError}
        isLoading={isSubmitting}
        onCancel={onCancel}
        onCta={onCta}
        size="M"
      >
        <ModalHeading>
          {intl.formatMessage({id: 'account.planDetails.changeResellerModal.title'})}
        </ModalHeading>
        <Divider size="M" />
        <ModalContent>
          <Text data-testid="description">
            {intl.formatMessage({id: 'account.planDetails.changeResellerModal.description'})}
          </Text>
          <Form
            aria-label={intl.formatMessage({
              id: 'account.planDetails.changeResellerModal.resellerChangeCodeForm.ariaLabel',
            })}
            isRequired
            necessityIndicator="label"
          >
            <ValidatedTextField
              description={intl.formatMessage({
                id: 'account.planDetails.changeResellerModal.resellerChangeCode.hint',
              })}
              errorMessage={
                isShowError &&
                intl.formatMessage({
                  id: 'account.planDetails.changeResellerModal.resellerChangeCodeForm.input.error',
                })
              }
              label={intl.formatMessage({
                id: 'account.planDetails.changeResellerModal.resellerChangeCodeForm.input',
              })}
              maxLength={CHANGE_CODE_LENGTH}
              onChange={(value) => {
                setIsCtaClicked(false);
                setResellerChangeCode(value);
              }}
              validationState={isShowError ? 'invalid' : ''}
              value={resellerChangeCode}
              width="60%"
            />
          </Form>
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  ) : null;
};

ChangeResellerModal.propTypes = {
  /**
   * Boolean flag to control if modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's action is submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

/**
 * Validates the format of the reseller change code.
 *
 * @param {String} changeCode - The reseller change code
 * @return {boolean} - True if reseller change code is valid
 */
function isValidResellerChangeCode(changeCode) {
  return hasExpectedLength(changeCode) && RESELLER_CHANGE_CODE_REGEX.test(changeCode);
}

/**
 * Ensures the reseller change code has the expected number of characters.
 *
 * @param {String} changeCode - The reseller change code
 * @return {boolean} - True if reseller change code has expected number of characters.
 */
function hasExpectedLength(changeCode) {
  return changeCode?.length === CHANGE_CODE_LENGTH;
}

export default ChangeResellerModal;
