import {showError as showErrorToast} from '@admin-tribe/binky-ui';
import {View, useDialogContainer} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AccessDnsToken from 'features/settings/common/components/access-dns-token/AccessDnsToken';

const AccessDnsTokenModal = () => {
  const intl = useIntl();

  const dialog = useDialogContainer();

  const handleError = () => {
    showErrorToast(intl.formatMessage({id: 'settings.accessDnsTokenModal.error'}));
    dialog.dismiss();
  };

  return (
    <ModalDialog size="M">
      <ModalHeading>
        <FormattedMessage id="settings.accessDnsTokenModal.title" />
      </ModalHeading>

      <ModalContent showDivider={false}>
        <View marginTop="size-125">
          <AccessDnsToken onError={handleError} />
        </View>
      </ModalContent>

      <ModalButtonGroup
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
        closeModal={() => dialog.dismiss()}
      />
    </ModalDialog>
  );
};

export default AccessDnsTokenModal;
