(function () {
  /**
   * @deprecated use src2 Storage pages
   *
   * @ngdoc factory
   * @name StorageStats
   * @description Model for storage usage stats
   */
  angular.module('app.core.storage-stats').factory('StorageStats', getStorageStatsModel);

  /* @ngInject */
  function getStorageStatsModel(
    $filter,
    $q,
    $translate,
    _,
    contentPlatformVa6Direct,
    CUSTOMER_SEGMENT,
    FileSize,
    MODEL,
    modelCache,
    modelCacheUtils,
    OrganizationManager,
    PRODUCT_CODE,
    USAGE_THRESHOLD
  ) {
    class StorageStats {
      /**
       * @description Creates a new StorageStats for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {Array} options.assetsTopFolder An list of shared folders with the highest usage
       *                                        among the org
       * @param {Object} options.orgAssetsQuota The shared folder quota usage of the current org
       * @param {Object} options.orgDeletedQuota The deleted quota usage of the current org
       * @param {Object} options.orgReclaimedQuota The reclaimed quota usage of the current org
       * @param {Object} options.orgReportsQuota The reports quota usage of the current org
       * @param {Object} options.orgTotalQuota The total quota of the current org
       * @param {Object} options.orgUsersQuota The user folder quota usage of the current org
       * @param {Array} options.usersTopFolder An list of user folders with the highest usage
       *                                       among the org
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      /**
       * @returns {Object[]} user folders with top usage among the current org
       * @returns {String} [].consumed - The amount of quota that is being used
       * @returns {String} [].name - The name of the folder
       * @returns {String} [].percentage - The percentage of the quota that is being used
       * @returns {String} [].total - The size of the folder
       */
      getTopUserFolders() {
        return _.map(this.usersTopFolder, (folder) => ({
          consumed: FileSize.bytesToString(folder.consumedQuota.value, 1),
          name: folder.folderName,
          percentage: $translate.instant('core.storageStats.usage', {
            utilizedQuota: $filter('number')(folder.utilizedQuota.value),
          }),
          total: FileSize.bytesToString(
            FileSize.getNumBytes(folder.totalQuota.value, folder.totalQuota.unit),
            1
          ),
        }));
      }

      /**
       * @description Fetch the usage data for displaying with usage-bar
       *
       * @param {Object} options - dictates what data to fetch
       * @param {Boolean} options.shouldGetUserFolders - whether to get user folder data or not
       * @param {Boolean} options.shouldGetSharedFolders - whether to get shared folder data or not
       *
       * @returns {Object[]}
       * @returns {Number} [].amount - The amount of the usage in byte.
       * @returns {String} [].color - The color to be shown in the usage bar
       * @returns {String} [].legendText - The legend text to be shown.
       */
      getUsageData(options = {}) {
        const unusedAmount = getUnusedAmount(this);
        const othersAmount = getOthersAmount(this);
        const result = [];
        if (options.shouldGetUserFolders) {
          result.push({
            amount: this.orgUsersQuota.value,
            color: 'chartreuse',
            legendText: $translate.instant('core.storageStats.labels.userFolders', {
              size: FileSize.bytesToString(this.orgUsersQuota.value, 1),
            }),
          });
        }
        if (options.shouldGetSharedFolders) {
          result.push({
            amount: this.orgAssetsQuota.value,
            color: 'blue',
            legendText: $translate.instant('core.storageStats.labels.sharedStorage', {
              size: FileSize.bytesToString(this.orgAssetsQuota.value, 1),
            }),
          });
        }
        result.push(
          {
            amount: othersAmount,
            color: 'grey',
            legendText: $translate.instant('core.storageStats.labels.others', {
              size: FileSize.bytesToString(othersAmount, 1),
            }),
          },
          {
            amount: unusedAmount,
            color: 'white',
            legendText: $translate.instant('core.storageStats.labels.unused', {
              size: FileSize.bytesToString(unusedAmount, 1),
            }),
          }
        );
        return result;
      }

      /**
       * @description Fetch the percentage of available storage used
       *
       * @returns {Number} The percentage of bytes used
       */
      getUsagePercentage() {
        if (this.orgTotalQuota) {
          return _.round((getUsedAmount(this) / getTotalAmount(this)) * 100);
        }
        return 0;
      }

      /**
       * @description Fetch the usage summary
       *
       * @returns {String} "Using {{amount}} of {{total}}"
       */
      getUsageSummary() {
        return $translate.instant('core.storageStats.summary', {
          amount: FileSize.bytesToString(getUsedAmount(this), 1),
          total: FileSize.bytesToString(getTotalAmount(this), 1),
        });
      }

      isOverUsage() {
        return getUsedAmount(this) > getTotalAmount(this);
      }

      isStorageLow() {
        return this.getUsagePercentage() >= USAGE_THRESHOLD;
      }

      /**
       * @description
       * Method to refresh the contents of the Storage Stats
       *
       * @returns {Promise} promise - resolved when the storage stats is refreshed
       */
      refresh() {
        this.$resolved = false;

        this.$promise = contentPlatformVa6Direct.storageStats
          .get({
            top: 5,
          })
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this));

        function onError(error) {
          this.$resolved = true;
          return $q.reject(error);
        }

        function onSuccess(response) {
          this.$resolved = true;
          updateModel(this, response);
          modelCache.put(MODEL.STORAGESTATS, this, key());
          return this;
        }

        return this.$promise;
      }

      /**
       * @class
       * @description Transforms the /organizations StorageStats response Object into a new
       *   StorageStats instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageStats(dataTransferObject);
      }

      /**
       * @description Method to fetch the StorageStats. It will be refreshed on
       *     registering the active org
       * @returns {StorageStats} singleton StorageStats.
       */
      static get() {
        let model = new StorageStats();
        const cachedModel = modelCache.get(MODEL.STORAGESTATS, key());
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }

      /**
       * @description Method to fetch id for storage only offer.
       *
       * @returns {Promise} promise - resolved with offer id when storage only offer can be found
       */
      static getStorageOnlyOfferId() {
        const productList = OrganizationManager.getProductsForActiveOrg();

        // get the offer id from storage only product if there is one
        if (productList.getStorageOnlyProducts().length > 0) {
          return $q.resolve(productList.getStorageOnlyProducts()[0].offerId);
        }
        return (
          OrganizationManager.getOffersForActiveOrg()
            .$promise.then((offerList) => {
              const offers = offerList.getOffersForSegment(
                productList.hasOnlyTeamProducts()
                  ? CUSTOMER_SEGMENT.TEAM
                  : CUSTOMER_SEGMENT.ENTERPRISE
              );
              return _.get(_.find(offers, {product_code: PRODUCT_CODE.CSAS}), 'offer_id');
            })
            // resolve with undefined so that storage overview page can still load properly
            .catch(() => $q.resolve(undefined))
        );
      }
    }

    /** Private Methods **/

    /**
     * @description Calculate the quota used by "other" content: deleted, reclaimed, and reports
     * @param {StorageStats} model StorageStats that contains all the usage data info
     *
     * @returns {Number} number of bytes taken by deleted, reclaimed, and reports content
     */
    function getOthersAmount(model) {
      // The 'value' of each quota is forced to be a float in updateModel, so we know it will be a number
      return (
        _.get(model, 'orgReclaimedQuota.value', 0) +
        _.get(model, 'orgDeletedQuota.value', 0) +
        _.get(model, 'orgReportsQuota.value', 0)
      );
    }

    /**
     * @description Fetch the total amount of allocated bytes
     * @param {StorageStats} model StorageStats that contains all the usage data info
     *
     * @returns {Number} total number of bytes
     */
    function getTotalAmount(model) {
      return FileSize.getNumBytes(model.orgTotalQuota.value, model.orgTotalQuota.unit);
    }

    /**
     * @description Fetch the amount of consumed quota in byte
     * @param {StorageStats} model StorageStats that contains all the usage data info
     *
     * @returns {Number} number of used byte
     */
    function getUsedAmount(model) {
      return _(model).filter(['unit', 'B']).sumBy('value');
    }

    /**
     * @description Fetch the amount of unused quota in byte
     * @param {StorageStats} model StorageStats that contains all the usage data info
     *
     * @returns {Number} number of unused byte
     */
    function getUnusedAmount(model) {
      const totalUsedQuota = _(model).filter(['unit', 'B']).sumBy('value');
      return _.max([
        FileSize.getNumBytes(model.orgTotalQuota.value, model.orgTotalQuota.unit) - totalUsedQuota,
        0,
      ]);
    }

    /**
     * @description Key for caching, based on org ID.
     * @returns {String} key for caching
     */
    function key() {
      return modelCacheUtils.getParameterizedKey({
        orgId: OrganizationManager.getActiveOrgId(),
      });
    }

    /**
     * @description Initializes Storage Stats data.
     *
     * @param {StorageStats} model StorageStats model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options = {}) {
      _.assignIn(
        model,
        _(options)
          .pick([
            'assetsTopFolder',
            'orgAssetsQuota',
            'orgDeletedQuota',
            'orgReclaimedQuota',
            'orgReportsQuota',
            'orgTotalQuota',
            'orgUsersQuota',
            'usersTopFolder',
          ])
          .cloneDeep()
      );

      // Convert all the strings to number
      _.forEach(model, (quota) => {
        if (quota.value) {
          quota.value = parseFloat(quota.value);
        } else if (_.isArray(quota)) {
          _.forEach(quota, (folder) => {
            _(folder)
              .filter('value')
              .forEach((folderQuota) => {
                folderQuota.value = parseFloat(folderQuota.value);
              });
          });
        }
      });

      // setup model as already resolved which ensures it can be used
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    // // We register the cache size for this class
    modelCache.register(MODEL.STORAGESTATS, 1);

    return StorageStats;
  }
})();
