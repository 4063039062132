import rootStore from 'core/RootStore';
import {ONE_MEGABYTE, SERVER_API_MESSAGE} from 'features/packages/packagesConstants';
import CreateServerService from 'features/packages/services/CreateServerService';
/**
 * @description Method to upload server authorization file
 * @param {Object} addedFile File that is added to DragAndDrop component
 * @param {Object} packagesServerStore Server store object
 * @param {Ref} isMounted it current value denotes if the DragAndDropAuthFile component is mounted
 * @returns {Object} updated file object with validation errors if any
 */
async function onFileAddHelper(addedFile, packagesServerStore, isMounted) {
  const localFileObject = addedFile[0];
  // Check for file type and file size errors
  localFileObject.hasFileTypeError =
    localFileObject.name.toLowerCase().split('.').slice(-1)[0] !== 'json';
  localFileObject.hasFileSizeError =
    localFileObject.size > rootStore.packagesUiConstantsStore.maxAtoFileSizeInMb * ONE_MEGABYTE;

  localFileObject.isValid = !localFileObject.hasFileTypeError && !localFileObject.hasFileSizeError;

  const fileContent = await localFileObject.text();
  try {
    JSON.parse(fileContent);
  } catch (error) {
    localFileObject.isValid = false;
    localFileObject.hasValidationError = true;
  }

  // Call uploadAuthReport function only if file is not having file type and file size errors
  if (localFileObject.isValid && isMounted()) {
    // Set loading to true
    packagesServerStore.setServerSessionDataValues('isAuthFileUploading', true);
    try {
      const fileId = await CreateServerService.uploadAuthReport(
        localFileObject,
        packagesServerStore.serverSessionData.serverId
      );
      localFileObject.hasValidationError = false;
      localFileObject.hasUploadError = false;
      packagesServerStore.setServerSessionDataValues('authorizationFileIdList', [
        ...packagesServerStore.serverSessionData.authorizationFileIdList,
        fileId,
      ]);

      packagesServerStore.setServerSessionSettingValues('nextButtonEnabled', true);
    } catch (error) {
      if (error === SERVER_API_MESSAGE.ERROR_VALIDATION) {
        localFileObject.hasValidationError = true;
      } else if (error !== SERVER_API_MESSAGE.WIZARD_CLOSED) {
        localFileObject.hasUploadError = true;
      }
    } finally {
      // Finally, set loading to false
      packagesServerStore.setServerSessionDataValues('isAuthFileUploading', false);
    }
  }
  if (isMounted()) {
    packagesServerStore.setServerSessionDataValues('authorizationFile', localFileObject);
  }

  return localFileObject;
}

// eslint-disable-next-line import/prefer-default-export -- only one utility export
export {onFileAddHelper};
