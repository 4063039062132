(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AdobeStatusStrings
   * @description model that fetches, stores, and looks up adobe status strings
   */
  angular.module('app.core.adobe-status').factory('AdobeStatusStrings', getAdobeStatusStrings);

  /* @ngInject */
  function getAdobeStatusStrings($q, _, adobestatus, locale) {
    class AdobeStatusStrings {
      /**
       * @description Method to create a new instance of a AdobeStatusStrings.
       */
      constructor() {
        this.$resolved = false;
      }

      getStringForKey(key) {
        return _.get(this.localeStrings, key, _.get(this.backupStrings, key));
      }

      /**
       * @description Method to load and process the adobe status strings.
       * @returns {Promise} promise resolved when adobe status strings are loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          const promises = [];
          // Status uses newer zh language codes (based on BCP47), and we need to make sure to use them
          const language = locale.getActiveLanguageBCP47Code();
          const localePromise = adobestatus.statusStrings.get({language}).$promise;
          localePromise
            .then(onLocaleStringsSuccess.bind(this))
            .catch(onLocaleStringsError.bind(this));
          promises.push(localePromise);
          if (language !== 'en') {
            // we need to load English as a backup language (for service names mostly)
            const enPromise = adobestatus.statusStrings.get({language: 'en'}).$promise;
            enPromise
              .then(onBackupStringsSuccess.bind(this))
              .catch(onBackupStringsError.bind(this));
            promises.push(enPromise);
          }
          $q.all(promises).then(onSuccess.bind(this)).catch(onError.bind(this));

          function onSuccess() {
            this.$resolved = true;
            resolve(this);
          }

          function onError(error) {
            this.$resolved = true;
            reject(error);
          }

          function onLocaleStringsSuccess(response) {
            this.localeStrings = response.localizeValues;
          }

          function onLocaleStringsError() {
            this.localeStrings = {};
          }

          function onBackupStringsSuccess(response) {
            this.backupStrings = response.localizeValues;
          }

          function onBackupStringsError() {
            this.backupStrings = {};
          }
        });

        return this.$promise;
      }

      /**
       * @description Method to fetch the singleton AdobeStatusStrings.
       * @returns {AdobeStatusStrings} the AdobeStatusStrings.
       */
      static get() {
        if (!AdobeStatusStrings.model) {
          AdobeStatusStrings.model = new AdobeStatusStrings();
          AdobeStatusStrings.model.refresh();
        }
        return AdobeStatusStrings.model;
      }
    }

    return AdobeStatusStrings;
  }
})();
