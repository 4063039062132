(function () {
  /**
   * @ngdoc module
   * @name app.widgets.ranged-slider
   * @description module that wraps the Coral ranged slider
   */
  angular.module('app.widgets.ranged-slider', [
    'binky.core.lodash',
    'binky.widgets.common.icon-tooltip',
  ]);
})();
