import {GoUrl} from '@admin-tribe/binky-ui';
import {Text} from '@adobe/react-spectrum';
import ProviderV2 from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import ConfigureAzureIdp from 'features/settings/components/directory/authentication/configure-azure-idp/ConfigureAzureIdp';
import EduIdpChooser from 'features/settings/components/edu-idp-chooser/EduIdpChooser';
import GoogleSamlConfigurationSteps from 'features/settings/components/google-saml-configuration-steps/GoogleSamlConfigurationSteps';
import SamlConfigurationSteps from 'features/settings/components/saml-configuration-steps/SamlConfigurationSteps';

const SetupIdpStep = observer(
  ({isGoogleSelected, isSoidcSelected, updateProvider, onLoginToAzure}) => {
    const {directorySetupStore} = useDirectorySetupContext();
    const {idp} = directorySetupStore;

    return (
      <>
        <ProviderV2>
          {(idp?.isGoogleSaml || isGoogleSelected) && (
            <>
              <Text>
                <FormattedMessage id="settings.setupDirectoryAuthentication.setupIdpDescription" />
              </Text>
              <GoogleSamlConfigurationSteps
                acsUrl={idp.acsUrl}
                customAttributes={directorySetupStore.data}
                entityId={idp.entityId}
                isIdpActive={idp.isActive}
                onAttributesChanged={(attributes) =>
                  directorySetupStore.setSamlIdpAttributes(attributes)
                }
                onFileChanged={(file) => directorySetupStore.setIdpMetadataFile(file)}
              />
            </>
          )}

          {idp?.isSaml && !idp?.isGoogleSaml && !isGoogleSelected && (
            <>
              <Text>
                <FormattedMessage id="settings.setupDirectoryAuthentication.setupIdpDescription" />
              </Text>

              <SamlConfigurationSteps
                customAttributes={directorySetupStore.data}
                idp={idp}
                onAttributesChanged={(attributes) =>
                  directorySetupStore.setSamlIdpAttributes(attributes)
                }
                onFileChanged={(file) => directorySetupStore.setIdpMetadataFile(file)}
              />
            </>
          )}
        </ProviderV2>

        {isSoidcSelected && (
          <>
            <Text>
              <FormattedMessage
                id="settings.setupDirectoryAuthentication.setupSoidcIdpDescription"
                values={{
                  goUrl: (linkText) => <GoUrl name="setup_directory">{linkText}</GoUrl>,
                }}
              />
            </Text>
            <EduIdpChooser onSelectProvider={updateProvider} />
          </>
        )}

        {idp?.isAzure && <ConfigureAzureIdp onLoginToAzure={onLoginToAzure} />}
      </>
    );
  }
);

export default SetupIdpStep;
