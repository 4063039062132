import {Cache, log} from '@admin-tribe/binky';
import {
  PRODUCT_ENTITLEMENT_SOURCE_TYPE,
  useProductEntitlementListHook,
} from '@pandora/data-model-product';

import rootStore from 'core/RootStore';

const productEntitlementListCache = new Cache();

/** Hook which returns JilModel product entitlement list */
const useProductEntitlementList = () => {
  const {getProductEntitlementList} = useProductEntitlementListHook();
  const cacheKey = `${rootStore.organizationStore.activeOrgId}.productEntitlementList`;

  const fetchProductEntitlementList = async () => {
    let productEntitlementList = productEntitlementListCache.get(cacheKey);
    if (!productEntitlementList) {
      try {
        productEntitlementList = await getProductEntitlementList({
          orgId: rootStore.organizationStore.activeOrgId,
          source: PRODUCT_ENTITLEMENT_SOURCE_TYPE.IMS,
        });
        productEntitlementListCache.put(cacheKey, productEntitlementList);
      } catch (error) {
        log.error('Error getting product entitlement list', error);
        throw error;
      }
    }
    return productEntitlementList;
  };

  return {fetchProductEntitlementList};
};

export default useProductEntitlementList;
