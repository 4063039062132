/* eslint-disable max-lines -- RichTextJson content models are pretty big */
import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';

import CardListContentModel from './CardListContentModel';

/**
 * Create a localized copy of RichTextJSON that will be used by the (general) Administration help topic card list content model.
 * Gets the strings themselves from xx.json.
 */
const LocalizeGeneralCloudRichTextJson = (intl, locale) => {
  const shortLocale = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale];
  return {
    helpTopicCards: [
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_getting_started_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.1.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.1.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_identity_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.1.richTextJson.link1.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_add_domain_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.1-2.richTextJson.link2.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.2.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.2.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_roles_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.2.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.3.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.3.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_manage_users_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.3.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.4.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.4.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_manage_product_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.4.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.5.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.5.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_bulk_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'generalAdministrationHelpTopicListContentModel.helpTopicCards.5.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
    ],
  };
};

/**
 * Definition of the "Administration" Cloud help topics content model
 */
const GeneralAdministration = (intl, locale) =>
  CardListContentModel(LocalizeGeneralCloudRichTextJson(intl, locale));

export default GeneralAdministration;
/* eslint-enable max-lines -- RichTextJson content models are pretty big */
