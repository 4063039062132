const PATH_STORAGE_INDIVIDUAL_USER = '/:orgId/storage/user';
const PATH_STORAGE_INDIVIDUAL_USER_FOLDERS = '/:orgId/storage/user/folders';
const PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED = '/:orgId/storage/user/folders/deleted';
const PATH_STORAGE_LIBRARIES = '/:orgId/storage/libraries';
const PATH_STORAGE_LIBRARY_DETAILS = '/:orgId/storage/libraries/:libraryId';
const PATH_STORAGE_OVERVIEW = '/:orgId/storage/overview';
const PATH_STORAGE_REPORTS = '/:orgId/storage/reports';
const PATH_STORAGE_REPORTS_SHARED = '/:orgId/storage/reports/assets';
const PATH_STORAGE_REPORTS_USERS = '/:orgId/storage/reports/users';
const PATH_STORAGE_ROOT = '/:orgId/storage';
const PATH_STORAGE_SHARED_PROJECTS = '/:orgId/storage/projects';
const PATH_STORAGE_SHARED_PROJECT_DETAILS = '/:orgId/storage/projects/:projectId';

export {
  PATH_STORAGE_INDIVIDUAL_USER,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS,
  PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED,
  PATH_STORAGE_LIBRARIES, // remove with temp_storage_shared_projects
  PATH_STORAGE_LIBRARY_DETAILS, // remove with temp_storage_shared_projects
  PATH_STORAGE_OVERVIEW,
  PATH_STORAGE_REPORTS,
  PATH_STORAGE_REPORTS_SHARED,
  PATH_STORAGE_REPORTS_USERS,
  PATH_STORAGE_ROOT,
  PATH_STORAGE_SHARED_PROJECTS,
  PATH_STORAGE_SHARED_PROJECT_DETAILS,
};
