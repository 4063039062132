import {feature} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Content, ContextualHelp, Heading, Text} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import React from 'react';

import {EDU_USER_TAGS} from 'features/users/users.constants';

/**
 * @description Method to format the list of edu tags.
 *
 * @returns {String} - Comma separated list of localized edu tags.
 */
const getEduTags = (tags, intl) => {
  const userTags = [];
  tags?.forEach((tag) => {
    // eslint-disable-next-line default-case -- no default case
    switch (tag) {
      case EDU_USER_TAGS.EDU_STAFF:
        userTags.push(
          intl.formatMessage({
            id: 'common.eduTags.tag.staff',
          })
        );
        break;
      case EDU_USER_TAGS.EDU_STUDENT:
        userTags.push(
          intl.formatMessage({
            id: 'common.eduTags.tag.student',
          })
        );
        break;
      case EDU_USER_TAGS.EDU_TEACHER:
        if (feature.isEnabled('temp_edu_educator')) {
          userTags.push(
            intl.formatMessage({
              id: 'common.eduTags.tag.educator',
            })
          );
        } else {
          // remove common.eduTags.tag.teacher translation with temp_edu_educator
          userTags.push(
            intl.formatMessage({
              id: 'common.eduTags.tag.teacher',
            })
          );
        }
        break;
    }
  });

  if (userTags.length === 0) userTags.push(EN_DASH);

  return userTags.join();
};

/**
 *
 * @returns {JSXElement} - Returns the contextual help for edu tags.
 */
const getEduTagHelp = (intl) => (
  <ContextualHelp variant="info">
    <Heading>
      {intl.formatMessage({
        id: 'common.eduTags.header',
      })}
    </Heading>
    <Content>
      <Text>
        {intl.formatMessage(
          {id: 'common.eduTags.description'},
          {
            goUrl: (str) => <GoUrl name="aac_learn_more_usergroups">{str}</GoUrl>,
          }
        )}
      </Text>
    </Content>
  </ContextualHelp>
);

export {getEduTags, getEduTagHelp};
