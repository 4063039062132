import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext, UserGroupUserList} from '@admin-tribe/binky';
import {Subpage, useStore} from '@admin-tribe/binky-ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useLoaderData} from 'react-router-dom';

import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import UserStore from 'common/stores/user-store/UserStore';
import {
  canAssignUserToUserGroup,
  canRemoveUserFromUserGroup,
  canViewUserGroupDetailsBulkOperations,
} from 'core/user-group/access/userGroupAccess';

/**
 * The User group 'Users' Subpage component which is displayed in a TabbedNav.
 * If the user group is externally managed, users can not be removed.
 * The selection mode will be 'none' and 'getIsItemSelectable' is not called.
 */
const UserGroupUsersSubpage = observer(() => {
  const {userGroup} = useLoaderData();

  const [userOperations, setUserOperations] = useState();
  const [canAddMember, setCanAddMember] = useState(false);
  const [canRemoveMember, setCanRemoveMember] = useState(false);

  const userGroupStore = useStore(
    () =>
      new UserStore({
        immediateLoad: true,
        listClassRef: UserGroupUserList,
        listOptions: {
          userGroupId: userGroup.id,
        },
      })
  );

  const pageContext = new PageContext({
    target: PAGE_TARGET.USER_GROUP,
    targetId: userGroup.id,
    targetType: PAGE_TARGET_TYPE.USER,
  });

  // Initialize the user operations.
  useEffect(() => {
    const canAdd = canAssignUserToUserGroup(userGroup);
    setCanAddMember(canAdd);

    const canUnassign = canRemoveUserFromUserGroup(userGroup);
    setCanRemoveMember(canUnassign);
    const operations = UserOperations.getUserGroupUserOperations({
      canAdd,
      canUnassign,
      canViewResults: canViewUserGroupDetailsBulkOperations(userGroup),
      userGroupId: userGroup.id,
    });

    setUserOperations(operations);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- init
  }, []);

  return (
    <Subpage data-testid="user-group-users-subpage">
      <MemberListContextProvider
        canAddMember={canAddMember}
        canRemoveMember={canRemoveMember}
        memberListDisplay={{
          email: true,
          idType: true,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={userGroupStore}
        userOperations={userOperations}
      >
        <UserListTableSection />
      </MemberListContextProvider>
    </Subpage>
  );
});

export default UserGroupUsersSubpage;
