import {useContentEntry} from '@pandora/react-content-provider';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {
  SupportTicketContactsModal,
  SupportTicketContactsModalContentModel,
} from '@pandora/react-support-ticket';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import rootStore from 'core/RootStore';

import styles from './SupportTicketContactsModalWrapper.pcss';

/**
 * @description This represents a wrapper for SupportTicketContactsModal in pandora library
 */
const SupportTicketContactsModalWrapper = ({isOpen, onClose, onSuccess, supportTicket}) => {
  const {organizationStore} = rootStore;
  const [showContactsModal, setShowContactsModal] = useState(isOpen);

  const onCancel = () => {
    setShowContactsModal(false);
    onClose();
  };

  const onSave = () => {
    setShowContactsModal(false);
    onSuccess();
  };

  const content = useContentEntry(SupportTicketContactsModalContentModel);

  return (
    showContactsModal && (
      <ModalContainer data-testid="support-ticket-contacts-modal-wrapper">
        <SupportTicketContactsModal
          content={content}
          onCancel={onCancel}
          onUpdateContacts={onSave}
          orgId={organizationStore.activeOrgId}
          supportTicket={supportTicket}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need line-height
          UNSAFE_className={styles['flag-icon']}
        />
      </ModalContainer>
    )
  );
};

SupportTicketContactsModalWrapper.propTypes = {
  /**
   * A boolean which indicates whether the modal should be opened. The default is false.
   */
  isOpen: PropTypes.bool,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onClose: PropTypes.func,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func,
  /**
   * The support ticket to send to modal.
   */
  supportTicket: PropTypes.instanceOf(Object),
};

export default SupportTicketContactsModalWrapper;
