(function () {
  /**
   * @deprecated
   */
  angular.module('app.widgets.products').component('appProductProfileSelect', {
    bindings: {
      description: '@?',
      developerTabContext: '<?',
      pageContext: '<?',
      productContext: '<?',
      user: '<',
    },
    controller,
    templateUrl:
      'app/widgets/products/product-profiles/select/product-profile-select.component.html',
  });

  /* @ngInject */
  function controller(
    $element,
    $q,
    $scope,
    $timeout,
    $translate,
    _,
    binkyProductNameLabelFilter,
    feature,
    OrganizationManager,
    PRODUCT_PROFILE_SELECT_CHANGE,
    PRODUCT_PROFILE_SELECT_ERROR,
    PRODUCT_PROFILE_SELECT_VALIDITY_CHANGE,
    PRODUCT_SELECT_CHANGE,
    PRODUCT_SELECT_ERROR,
    PRODUCT_SELECT_VALIDITY_CHANGE,
    PAGE_TARGET_TYPE,
    productAccess,
    ProductConfigurationList,
    UserGroupConfigurationsList
  ) {
    const vm = this;
    let deferred;

    _.assign(vm, {
      $doCheck,
      canAssignUser: true,
      developerTabContext: _.get(vm, 'developerTabContext', false),
      enableAutocomplete,
      getAssignedProfileTotal,
      getUserProduct,
      invalidProducts: [],
      isAnyProductEnterprise,
      isIntegrationContext: _.get(vm, 'pageContext.targetType') === PAGE_TARGET_TYPE.INTEGRATION,
      isTabDisabled,
      isTabSelected,
      isValid: true,
      onAutocompleteChange,
      onClickRemoveAll,
      onClickTab,
      previousUserProducts: null,
      provisioningMessage: null,
      selectedProduct: null,
      showNoLicensesMsg,
      showProductProfileSelection,
    });

    $scope.$on(PRODUCT_SELECT_CHANGE, emitChange);
    $scope.$on(PRODUCT_SELECT_ERROR, emitError);
    $scope.$on(PRODUCT_SELECT_VALIDITY_CHANGE, onProductSelectValidityChange);

    /////////////

    function $doCheck() {
      // Watch changes to the user and the user's products
      if (vm.previousUserProducts !== vm.user.products) {
        onChanges();
        vm.previousUserProducts = vm.user.products;
      }
    }

    function emitChange() {
      $scope.$emit(PRODUCT_PROFILE_SELECT_CHANGE);
    }

    function emitError() {
      deferred.resolve();
      $scope.$emit(PRODUCT_PROFILE_SELECT_ERROR);
    }

    function emitValidityChange() {
      $scope.$emit(PRODUCT_PROFILE_SELECT_VALIDITY_CHANGE, vm.isValid);
    }

    function enableAutocomplete() {
      return vm.canAssignUser;
    }

    function getAssignedProfileTotal() {
      if (!vm.selectedProduct) {
        return 0;
      }
      return _.get(getUserProduct(), 'licenseGroups.length') || 0;
    }

    function getUserProduct() {
      let product;
      if (vm.developerTabContext) {
        product = _.find(vm.user.developerProducts, {id: vm.selectedProduct.id});
      } else {
        product = _.find(vm.user.products, {id: vm.selectedProduct.id});
      }
      if (!product) {
        product = _.clone(vm.selectedProduct);
        product.licenseGroups = [];
        if (!vm.user.products && !vm.developerTabContext) {
          vm.user.products = [];
        }
        if (!vm.developerTabContext) {
          vm.user.products.push(product);
        }
        if (vm.developerTabContext && !vm.user.developerProducts) {
          vm.user.developerProducts = [];
        }
        if (vm.developerTabContext) {
          vm.user.developerProducts.push(product);
        }
      }
      return product;
    }

    function isAnyProductEnterprise() {
      return _.some(vm.productListItems, (item) => item.isEnterprise());
    }

    function isTabDisabled(product) {
      const userType = vm.user.getType();
      return (
        product.isTeam() && (userType.isUserGroup() || userType.isType2() || userType.isType3())
      );
    }

    function isTabSelected(product) {
      return vm.selectedProduct.id === product.id;
    }

    function onAutocompleteChange(values) {
      const product = getUserProduct();
      product.licenseGroups = _.filter(vm.productConfigurationList.items, (item) =>
        _.some(values, (value) => value === item.id)
      );
      updateAutocomplete();
      emitChange();
      // Give add-user-modal access to the Product object (with PLC info) selected to add a developer to a license group
      if (vm.developerTabContext) {
        $scope.$emit('PROFILE_SELECTION_VALUES_FOR_DEVELOPER_UPDATED', product);
      }
    }

    function onChanges() {
      resetPromiseToWait();
      const productContextId = _.get(vm, 'productContext.id');
      // Fetch all the product profiles
      const productList = OrganizationManager.getProductsForActiveOrg();
      if (productList.items.length === 0) {
        emitError();
        return;
      }

      vm.productListItems = _(productList.items)
        .filter((item) => {
          const apiContext = vm.developerTabContext || vm.isIntegrationContext;
          if (apiContext) {
            return productAccess.canAssignDeveloper(item);
          }
          return productAccess.canAssignUserIgnoringLicenseCounts(item);
        })
        .sortBy((item) => binkyProductNameLabelFilter(item))
        .value();

      if (productContextId) {
        vm.selectedProduct = _.find(vm.productListItems, ['id', productContextId]);
      } else {
        vm.selectedProduct = _.find(vm.productListItems, (product) => !isTabDisabled(product));
        setProductSelectionCoralTabLabels();
      }
      if (!vm.selectedProduct) {
        // If there isn't a selected product, then there is nothing to fetch
        return;
      }

      productAccess
        .canAssign(vm.selectedProduct, vm.user)
        .then((result) => {
          vm.canAssignUser = result;
        })
        .catch((error) => error);

      updateProductConfigurationList();

      // Fetch the user's product profiles
      vm.fetchedUserProductProfiles = false;
      if (vm.user.isNew()) {
        vm.fetchedUserProductProfiles = true;
      } else if (vm.user.getType().isUserGroup()) {
        // A bit tricky. The "user" can actually be a user group. In that case, we have to make a different API call
        const userGroupConfigurationsList = UserGroupConfigurationsList.get({groupId: vm.user.id});
        userGroupConfigurationsList.$promise
          .then(() => {
            vm.fetchedUserProductProfiles = true;
            vm.user.products = [];
            // User group profiles come back in a flat list, add them under products
            _.forEach(userGroupConfigurationsList.items, (licenseGroup) => {
              let product = _.find(vm.user.products, {
                id: licenseGroup.product.id,
              });
              if (!product) {
                product = {
                  id: licenseGroup.product.id,
                  licenseGroups: [],
                };
                vm.user.products.push(product);
              }
              product.licenseGroups.push(licenseGroup);
            });

            _.invoke(vm.user, 'registerProductsSavedState');

            // Reset the previous product otherwise can get in an infinite loop with $doCheck call
            vm.previousUserProducts = vm.user.products;

            updateAutocomplete();
          })
          .catch(emitError);
      } else {
        vm.user
          .refresh()
          .then(() => {
            vm.fetchedUserProductProfiles = true;

            // Reset the previous product otherwise can get in an infinite loop with $doCheck call
            vm.previousUserProducts = vm.user.products;
            updateAutocomplete();
          })
          .catch(emitError);
      }
    }

    function onClickRemoveAll() {
      const product = getUserProduct();
      product.licenseGroups = [];
      updateAutocomplete();
      emitChange();
      // Give add-user-modal access to the cleared Product object
      if (vm.developerTabContext) {
        $scope.$emit('PROFILE_SELECTION_VALUES_FOR_DEVELOPER_UPDATED', product);
      }
    }

    function onClickTab(product) {
      if (isTabDisabled(product)) {
        return;
      }
      vm.selectedProduct = product;
      productAccess
        .canAssign(vm.selectedProduct, vm.user)
        .then((result) => {
          vm.canAssignUser = result;
        })
        .catch((error) => error);
      updateProductConfigurationList();
    }

    function onProductSelectValidityChange(event, product, isProductSelectValid) {
      if (isProductSelectValid) {
        _.remove(vm.invalidProducts, ['id', product.id]);
      } else {
        vm.invalidProducts.push(product);
      }
      const isValid = vm.invalidProducts.length === 0;
      if (isValid !== vm.isValid) {
        vm.isValid = isValid;
        emitValidityChange();
      }
    }

    function resetPromiseToWait() {
      deferred = $q.defer();
      vm.promiseToWait = deferred.promise;
    }

    // Setting the coral-tab-label inline is only working on Chrome.
    function setProductSelectionCoralTabLabels() {
      $timeout(() => {
        const coralTabLabels = $element[0].querySelectorAll('coral-tab-label.product-name');
        if (_.get(coralTabLabels, 'length') === _.get(vm.productListItems, 'length')) {
          _.forEach(coralTabLabels, (label, index) => {
            label.innerText = binkyProductNameLabelFilter(vm.productListItems[index]);
          });
        }
      });
    }

    function showNoLicensesMsg() {
      const currentProducts = _.get(vm, 'user.savedState.products') || vm.user.products;
      // For developer workflows we do not require that the product
      // has available licenses (API only products, for instance) so we don't show the
      // error banner in developer context and allow profile selection
      return (
        vm.selectedProduct.hasNoAvailableLicenses() &&
        !vm.developerTabContext &&
        angular.isUndefined(
          _.find(currentProducts, {
            id: vm.selectedProduct.id,
          })
        )
      );
    }

    function showProductProfileSelection() {
      return (
        vm.fetchedUserProductProfiles && vm.fetchedProductConfigurationList && !showNoLicensesMsg()
      );
    }

    function updateProductConfigurationList() {
      resetPromiseToWait();
      vm.sortedTags = false;
      // Temporary quick fix for https://git.corp.adobe.com/admin-tribe/onesie/issues/3779, will be removed when Remote search autocomplete is implemented.
      const pageSize = 4000;
      vm.fetchedProductConfigurationList = false;
      vm.productConfigurationList = ProductConfigurationList.get(vm.selectedProduct, pageSize);
      vm.productConfigurationList.$promise
        .then(() => {
          $timeout(() => {
            vm.fetchedProductConfigurationList = true;
            updateAutocomplete();
          });
        })
        .catch(emitError);

      vm.provisioningMessage = $translate.instant(
        _.invoke(vm, 'selectedProduct.isPricePointFree')
          ? 'widgets.products.productProfiles.select.productProfileSelect.provisioningTakesFewHours2'
          : 'widgets.products.productProfiles.select.productProfileSelect.provisioningTakesFewMinutes2'
      );
    }

    function updateAutocomplete() {
      if (vm.fetchedUserProductProfiles && vm.fetchedProductConfigurationList) {
        deferred.resolve();
        const profiles = getUserProduct().licenseGroups;
        vm.autocompleteItems = _(vm.productConfigurationList.items)
          .filter((item) => item.isAdministerable())
          .map((item) => ({
            display: item.name,
            value: item.id,
          }))
          .value();
        vm.autocompleteValues = _(profiles).sortBy(['name']).map('id').value();
      }
    }
  }
})();
