import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import DeactivateDevicesButton from 'common/components/deactivate-devices-button/DeactivateDevicesButton';
import ExportDevicesButton from 'common/components/export-devices-button/ExportDevicesButton';
import HowToDeployButton from 'common/components/how-to-deploy-button/HowToDeployButton';
import auth from 'core/services/auth';
import NoDevicesAssigned from 'features/products/components/no-devices-assigned/NoDevicesAssigned';

import useDeactivatedTableSorter from '../../hooks/useDeactivatedTableSorter';

import LegacyDevicesTableSection from './LegacyDevicesTableSection';

/**
 * Legacy device table container
 */
const LegacyDevicesTableContainer = ({deviceCollection, productId, poolId, updateTableChange}) => {
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isDeactivateButtonVisible, setDeactivateButtonVisible] = useState(false);
  const [deactivatedDevicesList, setDeactivatedDevicesList] = useState(deviceCollection);

  const {sorter} = useDeactivatedTableSorter(deactivatedDevicesList);
  const intl = useIntl();
  const sortedList = sorter({id: 'lastActivityDate', sortedOrder: 'ascending'});
  const [currentItems, setCurrentItems] = useState(sortedList.slice(0, currentPageSize));
  const [currentTotalPages, setCurrentTotalPages] = useState(
    Math.ceil(deactivatedDevicesList.length / currentPageSize)
  );

  const onTableSectionChange = ({action, payload, state}) => {
    let currentRecords, indexOfFirstRecord, indexOfLastRecord, searchResult;
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE: {
        indexOfLastRecord = payload * currentPageSize;
        indexOfFirstRecord = indexOfLastRecord - currentPageSize;
        // Records to be displayed on the current page
        currentRecords = deactivatedDevicesList.slice(indexOfFirstRecord, indexOfLastRecord);
        setCurrentItems(currentRecords);
        setCurrentPageNumber(payload);
        return payload;
      }
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE: {
        setCurrentTotalPages(Math.ceil(deactivatedDevicesList.length / payload));
        setCurrentPageSize(payload);
        setCurrentPageNumber(1);
        setCurrentItems(deactivatedDevicesList.slice(0, payload));
        return payload;
      }
      case TABLE_SECTION_ACTIONS.ON_SORT_BY: {
        indexOfLastRecord = currentPageNumber * currentPageSize;
        indexOfFirstRecord = indexOfLastRecord - currentPageSize;
        // Records to be displayed on the current page
        currentRecords = sorter(payload).slice(indexOfFirstRecord, indexOfLastRecord);
        setCurrentItems(currentRecords);
        return payload;
      }
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        searchResult = deactivatedDevicesList.filter((item) =>
          item.deviceName.toLowerCase().includes(payload.value.toLowerCase())
        );
        setCurrentItems(searchResult);
        return searchResult;
      case TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED: {
        const filteredList = deactivatedDevicesList.filter(
          (item) => !payload.includes(item.deviceId)
        );
        setCurrentPageNumber(1);
        setDeactivatedDevicesList(filteredList);
        currentRecords = filteredList.slice(0, currentPageSize);
        setCurrentItems(currentRecords);
        setDeactivateButtonVisible(false);
        setCurrentTotalPages(Math.floor(deactivatedDevicesList.length / currentPageSize));
        return payload;
      }
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE: {
        setDeactivateButtonVisible(state.selectedItems.length > 0);
        return payload;
      }
      default: {
        setCurrentItems(deactivatedDevicesList.slice(0, currentPageSize));
        return payload;
      }
    }
  };

  return deactivatedDevicesList.length > 0 ? (
    <TableSection
      getItemKey={(item) => item.deviceId}
      isServerError={false}
      items={currentItems}
      onTableSectionChange={onTableSectionChange}
      pageNumber={currentPageNumber}
      selectionMode={SELECTION_MODE.MULTIPLE}
      totalPages={currentTotalPages}
    >
      <TableFilters hasSearch label={intl.formatMessage({id: 'products.devices.search'})} />
      <TableActions>
        {isDeactivateButtonVisible ? (
          <DeactivateDevicesButton poolId={poolId} updateTableChange={updateTableChange} />
        ) : (
          [
            <HowToDeployButton key="deployButton" />,
            auth.canExportDevices() && (
              <ExportDevicesButton key="exportButton" productId={productId} />
            ),
          ]
        )}
      </TableActions>
      <LegacyDevicesTableSection poolId={poolId} updateTableChange={updateTableChange} />
    </TableSection>
  ) : (
    <NoDevicesAssigned />
  );
};

LegacyDevicesTableContainer.propTypes = {
  /** The list of devices to be populated in the table  */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  deviceCollection: PropTypes.arrayOf(PropTypes.object),
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** The product ID to query for license groups */
  productId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};

export default LegacyDevicesTableContainer;
