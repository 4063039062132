(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.directories:azureDirectorySyncDetails
   * @description Azure directory sync details component shows sync status and allows for starting and pausing it.
   */
  angular.module('app.widgets.directories.azure').component('appAzureDirectorySyncDetails', {
    bindings: {
      directory: '<',
      directoryLink: '<',
    },
    controller,
    templateUrl:
      'app/widgets/directories/azure/azure-directory-sync-details/azure-directory-sync-details.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $q,
    $log,
    $translate,
    directorySync,
    feature,
    panelManager,
    SYNC_TASK_STATUSES,
    toastManager
  ) {
    const vm = this;
    _.assign(vm, {
      $onInit,
      retry,
      showStatus,
      toggleSync,
      triggerSync,
    });

    function $onInit() {
      _.assign(vm, {
        hasStatusFetchError: false,
        isProcessing: false,
        showContentWhileLoading: false,
        syncStatusLabel: 'widgets.directories.azure.azureDirectorySyncDetails.status.unknown',
        syncStatusVariant: 'default',
        waitPromise: $q.resolve(),
      });

      getSyncDetails();
    }

    function toggleSync(event) {
      event.preventDefault(); // Don't change toggle state until API reponds with the correct state.

      if (!vm.isProcessing) {
        vm.isProcessing = true;

        directorySync.toggleSync
          .save({authSrcCode: vm.directory.id}, !vm.isSyncEnabled) // We're saving the opposite state of what we have.
          .$promise.then((data) => {
            if (!('enabled' in data)) {
              return $q.reject();
            }

            vm.isSyncEnabled = data.enabled;
            return getSyncDetails();
          })
          .catch((error) => {
            $log.error('[AD Sync] Toggle directory sync failed.', error);

            toastManager.showErrorToast(
              $translate.instant(
                'widgets.directories.azure.azureDirectorySyncDetails.toggleSyncError'
              )
            );
          })
          .finally(() => {
            vm.isProcessing = false;
          });
      }
    }

    function retry() {
      getSyncDetails();
    }

    function triggerSync() {
      vm.isProcessing = true;
      directorySync.syncEngine
        .start({authSrcCode: vm.directory.id})
        .$promise.then(
          () => getSyncDetails(),
          (error) => {
            toastManager.showErrorToast(
              $translate.instant(
                'widgets.directories.azure.azureDirectorySyncDetails.triggerSyncError'
              )
            );
            $log.error('[AD Sync] Error triggering sync.', error);
          }
        )
        .finally(() => {
          vm.isProcessing = false;
        });
    }

    function showStatus() {
      // This will not be valid in the future when directories will have
      // any idp + sync. For now we diferentiate between the two
      // using the primary idp.
      return feature.isEnabled('temp_hide_status_for_scim') ? !vm.directory.isPrimaryIdpSaml : true;
    }

    ///////////////

    function getSyncDetails() {
      vm.isProcessing = true;
      vm.waitPromise = $q
        .all([
          directorySync.linkedOrgs.query().$promise,
          directorySync.syncStatus.get({authSrcCode: vm.directory.id}).$promise,
        ])
        .then(([linkedOrgs, syncStatus]) => {
          vm.hasStatusFetchError = false;

          // Check if sync is enabled or not.
          const linkedOrg = _.find(linkedOrgs, {imsId: vm.directory.id});
          vm.isSyncEnabled = _.get(linkedOrg, 'enabled');

          // Check if sync is disabled and admin shouldn't control it
          vm.isAdminSyncEnabled = _.get(linkedOrg, 'adminEnabled');

          // Check time and status of latest sync.
          vm.syncStartTime = syncStatus.start;

          setSyncStatus(syncStatus);
        })
        .catch((error) => {
          $log.error('[AD Sync] Getting directory sync information failed.', error);
          vm.hasStatusFetchError = true;
        })
        .finally(() => {
          vm.isProcessing = false;

          // We want to show contents while loading after the first data load.
          vm.showContentWhileLoading = true;
        });

      return vm.waitPromise;
    }

    function setSyncStatus(syncStatus) {
      if (!vm.isAdminSyncEnabled) {
        vm.syncStatusLabel = 'widgets.directories.azure.azureDirectorySyncDetails.status.adminOff';
        vm.syncStatusVariant = 'warning';
        return;
      }
      if (vm.isSyncEnabled) {
        if (syncStatus.syncTaskStatus === SYNC_TASK_STATUSES.RUNNING) {
          vm.syncStatusLabel =
            'widgets.directories.azure.azureDirectorySyncDetails.status.inProgress';
          vm.syncStatusVariant = 'info';
        } else if (syncStatus.syncTaskStatus === SYNC_TASK_STATUSES.DONE) {
          vm.syncDone = true;
          vm.syncStatusLabel = 'widgets.directories.azure.azureDirectorySyncDetails.status.inSync';
          vm.syncStatusVariant = 'success';
        } else if (syncStatus.syncTaskStatus === SYNC_TASK_STATUSES.NOT_STARTED) {
          vm.syncStatusLabel = 'widgets.directories.azure.azureDirectorySyncDetails.status.pending';
          vm.syncStatusVariant = 'default';
        } else {
          vm.syncStatusLabel = 'widgets.directories.azure.azureDirectorySyncDetails.status.unknown';
          vm.syncStatusVariant = 'default';
        }
      } else {
        vm.syncStatusLabel = 'widgets.directories.azure.azureDirectorySyncDetails.status.off';
        vm.syncStatusVariant = 'warning';
      }
    }
  }
})();
