const defaultTopics = [
  {
    children: [
      {
        id: 1,
        link: 'enterprise/using/admin-console.html#Sign-in',
        titleKey: 'search.defaultTopics.signIn',
      },
      {
        id: 2,
        link: 'enterprise/using/admin-console.html#Overview',
        titleKey: 'search.defaultTopics.overview',
      },
      {
        id: 3,
        link: 'enterprise/using/admin-console.html#Products',
        titleKey: 'search.defaultTopics.products',
      },
      {
        id: 4,
        link: 'enterprise/using/admin-console.html#Users',
        titleKey: 'search.defaultTopics.users',
      },
      {
        id: 5,
        link: 'enterprise/using/admin-console.html#Packages',
        titleKey: 'search.defaultTopics.packages',
      },
      {
        id: 6,
        link: 'enterprise/using/admin-console.html#Accounts',
        titleKey: 'search.defaultTopics.accounts',
      },
      {
        id: 7,
        link: 'enterprise/using/admin-console.html#Settings',
        titleKey: 'search.defaultTopics.settings',
      },
      {
        id: 8,
        link: 'enterprise/using/admin-console.html#Support',
        titleKey: 'search.defaultTopics.support',
      },
    ],
    id: 'defaultTopic',
    titleKey: 'search.defaultTopics.browseCategories',
  },
];

export default defaultTopics;
