/* eslint-disable max-lines */
(function () {
  /**
   * @deprecated use src2 Support pages
   *
   * @ngdoc factory
   * @name ExpertSession
   * @description Model for a support ticket. This is intended to be used as the base class for
   *   the different kinds of support ticket, i.e. support cases and expert sessions. This
   *   class provides functionality common to all kinds of ticket.
   */
  angular.module('app.core.support').factory('SupportTicket', SupportTicketModel);

  /* @ngInject */
  function SupportTicketModel(
    $log,
    $q,
    $rootScope,
    _,
    auth,
    locale,
    MESSAGE,
    modelCache,
    onesieSrc2,
    OrganizationManager,
    SUPPORT_TICKET_COMMENT_TYPE,
    SUPPORT_TICKET_RECORD_TYPE,
    SUPPORT_TICKET_STATUS,
    SUPPORT_TICKET_STATUS_REASON,
    SUPPORT_TICKET_UI_STATUS
  ) {
    const miloApi = onesieSrc2.support.api.milo;
    const SUPPORT_CONSTANTS = onesieSrc2.support.constants;
    const orgId = OrganizationManager.getActiveOrgId();

    class SupportTicket {
      /**
       * @class
       * @description Creates a new SupportTicket. All options are expected to come from the
       *   Support Anyware API, except cacheType which is expected to be defined by the subclass.
       *
       * @param {Object} ticketConfig - options related to the type of ticket being created (e.g.
       *   support case or expert session), as described below
       * @param {String} ticketConfig.cacheType - the cache to use for this type of ticket, e.g. MODEL.EXPERTSESSION
       * @param {Array} ticketConfig.fields - the extra fields to expect for this type of ticket
       * @param {MESSAGE} ticketConfig.updateItemMessage - the message to emit when the ticket is
       *   updated, useful for example to indicate that the list cache should be cleared.
       * @param {Object} options - the ticket JSON as returned by the API; described below
       * @param {Object} options.admin - customer admin assigned to the ticket, as described below
       * @param {String} options.admin.email - the admin's email address
       * @param {String} options.admin.firstName - the admin's first name
       * @param {String} options.admin.lastName - the admin's last name
       * @param {String} options.admin.rengaId - the admin's Renga GUID
       * @param {Object} [options.agentAssigned] - the support agent assigned to the case, if it
       *   has been assigned, as described below, otherwise undefiend
       * @param {String} options.agentAssigned.firstName - the admin's first name
       * @param {String} options.agentAssigned.lastName - the admin's last name
       * @param {Object[]} [options.attachments] - any attachments that have been added to ticket
       * @param {Object[]} [options.comments] - any commments that have been added to the ticket
       * @param {String} options.createdOn - when the ticket was created, as an ISO8601 date
       * @param {String} options.id - the ticket's unique identifier
       * @param {String} options.status - the ticket's status
       * @param {String} [options.surveyUrl] - the URL of the survey for this ticket; returned by
       *   Support Anyware if the ticket has a survey ready to take (e.g. due to case closure) and
       *   it has not already been completed
       * @param {Integer} options.timezone - the ticket's Dynamics timezone ID
       * @param {String} options.title - the user-provided title for the case, or the expert
       *   session topic
       * @param {SUPPORT_TICKET_STATUS_REASON} options.statusReason - the ticket's status reason
       */
      constructor(ticketConfig, options) {
        const expectedTicketConfig = _.pick(ticketConfig, [
          'cacheType',
          'fields',
          'updateItemMessage',
        ]);
        _.assign(this, expectedTicketConfig);
        initModel.call(this, options);
      }

      /**
       * @description Method to add attachments to a given support ticket. Using
       *   code will depend on the results matching the following format:
       *
       *   Object ({
       *     {String} error - error message returned from server request
       *     {Boolean failed - true if attachment failed to upload; else false
       *     {File} file - reference to file that was attempted to attach
       *   })
       *
       * @param {Object} options - as described below
       * @param {File[]} options.attachments - list of files to attach to support ticket
       * @param {Function} [options.onAttachmentError] - callback method to handle individual
       *   attachment upload errors
       * @param {Function} [options.onAttachmentSuccess] - callback method to handle individual
       *   attachment upload successes
       * @param {Boolean} [options.refreshAfterUpload] - true if the support ticket should be
       *   refreshed after one or more attachments were successfully uploaded. Defaults to true.
       *   Note if this is set to false, the model's attachments property will be stale after
       *   the attach operation completes.
       * @returns {Promise} a promise which resolves when all attachments have been uploaded
       *   successfully; rejects when any attachment fails to upload
       */
      addAttachments({
        attachments,
        onAttachmentError,
        onAttachmentSuccess,
        refreshAfterUpload = true,
      }) {
        const uploadResults = [];

        return $q((resolve, reject) => {
          uploadAttachments(this, attachments).finally(onComplete.bind(this));

          function onComplete() {
            if (_.some(uploadResults, 'failed')) {
              reject(uploadResults);
            } else {
              resolve(uploadResults);
            }
            if (refreshAfterUpload && !_.every(uploadResults, 'failed')) {
              this.refresh();
            }
          }
        });

        ////////

        function uploadAttachments(model, files) {
          return $q((resolve) => {
            const firstFile = _.head(files);

            if (_.isNil(firstFile)) {
              resolve();
            } else {
              uploadAttachment(firstFile)
                .then(uploadSuccess.bind(model), uploadError.bind(model))
                .finally(uploadNext.bind(model));
            }

            function uploadAttachment(file) {
              return $q((success, failure) => {
                miloApi
                  .uploadAttachment({
                    file,
                    id: model.id,
                    orgId: OrganizationManager.getActiveOrgId(),
                    regarding: SUPPORT_CONSTANTS.ENTITY.INCIDENT,
                  })
                  .then((response) => {
                    success(response);
                  })
                  .catch((error) => failure(error));
              });
            }

            function uploadError(error) {
              logUploadResult({error, failed: true, file: firstFile});
              if (_.isFunction(onAttachmentError)) {
                onAttachmentError(firstFile);
              }
            }

            function uploadNext() {
              uploadAttachments(this, _.drop(files)).finally(resolve);
            }

            function uploadSuccess() {
              $rootScope.$emit(MESSAGE.UPDATE.SUPPORTCASE, model.id);
              logUploadResult({failed: false, file: firstFile});
              if (_.isFunction(onAttachmentSuccess)) {
                onAttachmentSuccess(firstFile);
              }
            }

            function logUploadResult(options) {
              uploadResults.push(options);
            }
          });
        }
      }

      /**
       * @description Method to add comments to a given support ticket.
       * @param {String} comment - comment to add to support ticket
       * @returns {Promise} a promise which resolves when comment has been
       *   added successfully; rejects if comment fails to be added
       */
      addComment(comment) {
        const commentBody = {
          customerNote: {description: comment},
          id: this.id,
          orgId,
          regarding: SUPPORT_CONSTANTS.ENTITY.INCIDENT,
        };

        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          miloApi
            .addCustomerNote({comment: commentBody})
            .then(() => {
              emitUpdateItemMessage.call(this);
              return this.refresh();
            })
            .then(() => resolve(this))
            .catch((error) => {
              const returnError = error.response || error;
              $log.error(
                `SupportTicket.addComment(): Failed to add comment for ${this.constructor.name} "${this.id}". Error: `,
                returnError
              );
              reject(returnError);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Returns true if the user can take the survey for this ticket.
       *   The survey can only be taken if the API returned a survey URL, if the ticket is closed,
       *   and if the ticket belongs to the current Admin Console user.
       *
       * @returns {Boolean} true if the user can take the survey for this ticket
       */
      canTakeSurvey() {
        return !_.isEmpty(this.surveyUrl) && this.isClosed() && this.isAssignedToLoggedInUser();
      }

      /**
       * Returns true if the specified comment requires a response from the customer; else returns
       * false. A comment is determined to require a response if the overall ticket is pending
       * response, and it is the most recent "pending response" comment.
       *
       * @param {Object} comment - the comment to check
       * @returns {Boolean} true if the comment is waiting for a response from the customer
       */
      commentRequiresResponse(comment) {
        if (this.statusReason === SUPPORT_TICKET_STATUS_REASON.PENDING_RESPONSE) {
          const mostRecentPendingResponseComment = _(this.comments)
            .filter({type: SUPPORT_TICKET_COMMENT_TYPE.ACTION_PENDING_RESPONSE})
            .sortBy('createdOn')
            .last();
          return comment === mostRecentPendingResponseComment;
        }
        return false;
      }

      downloadAttachment(attachmentId) {
        this.$resolved = false;
        return $q((resolve, reject) => {
          miloApi
            .downloadAttachment({
              attachmentId,
              id: this.id,
              orgId,
              regarding: SUPPORT_CONSTANTS.ENTITY.INCIDENT,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
      }

      /**
       * @description Method to retrieve a list (Array) of discussion items
       *   associated with this SupportTicket. Discussion items are either
       *   attachments or comments that are associated (stored as attributes) on
       *   the model. Items are returned in descending order, based on their
       *   'createdOn' date value (i.e. - most recent items first).
       * @returns {Object[]} list of attachments and comments in descending date order
       */
      getDiscussionItems() {
        return _(this.attachments || [])
          .concat(this.comments || [])
          .orderBy(['createdOn'], ['desc'])
          .value();
      }

      /**
       * @description Returns the image asset of the product that this ticket is about.
       *
       * @returns {Object} the icon object this product is about, 16x16, 48x48, 128x128 and svg.
       */
      getProductIcon() {
        const productList = OrganizationManager.getProductsForActiveOrg();
        const code = _.get(this, 'productCode') || _.get(this, 'product.fulfillableItemCode');
        const productInfo = productList.findFulfillableItem(code);

        return _.get(productInfo, 'assets.icons');
      }

      /**
       * @description Returns the name of the product that this ticket is about, or null if a name
       *   is not found.
       *
       *   The returned value is one of:
       *   1. The enterpriseName of any fulfillable item matching the ticket's fulfillableItemCode.
       *   2. The longName of any product matching the ticket's fulfillableItemCode. This applies
       *      to migrated cases, where the so-called fulfillableItemCode returned by the API is
       *      actually a four character product code like "PHSP".
       *   3. The name returned by the Support Anyware API. This name is not localized, hence we
       *      try to find the above names first. This helps in the case where the org no longer has
       *      access to the FI/product, which would mean the product list no longer contains them,
       *      causing 1 and 2 above to fail.
       *   4. If all else fails, null
       *
       * @returns {String} the product this ticket is about, or null
       */
      getProductName() {
        const code = _.get(this, 'productCode');
        const nameFromApi = _.get(this, 'productName');

        const productList = OrganizationManager.getProductsForActiveOrg();

        return getFulfillableItemName() || getProductName() || nameFromApi || null;

        /////////////

        function getFulfillableItemName() {
          const fi = productList.findFulfillableItem(code);
          return _.get(fi, 'enterpriseName');
        }

        function getProductName() {
          return _.chain(productList.items).find({code}).get('longName').value();
        }
      }

      getUiStatus() {
        const statusReasonName = _.findKey(
          SUPPORT_TICKET_STATUS_REASON,
          (statusReason) => statusReason === this.statusReason
        );
        return SUPPORT_TICKET_UI_STATUS[statusReasonName] || SUPPORT_TICKET_UI_STATUS.UNKNOWN;
      }

      hasBeenUpdatedSinceCreation() {
        return this.lastModified > this.createdOn;
      }

      /**
       * @description Returns true if an Adobe support agent is assigned to this ticket, else
       * returns false.
       *
       * Confusingly the API returns an agentAssigned object with the name "Not Assigned" (due to
       * MS Dynamics limitations) when the ticket is awaiting assignment. Hence this function
       * considers not only the ticket's agentAssigned value but also its name. We also always
       * consider a ticket unassigned if its status reason is AWAITING_ASSIGNMENT, regardless of
       * agentAssigned value. See https://jira.corp.adobe.com/browse/SR-48689 for context.
       *
       * @returns {Boolean} true if an agent is assigned to this ticket, else false
       */
      isAgentAssigned() {
        const isAgentNameNotAssigned =
          _.get(this, 'agentAssigned.firstName') === 'Not' &&
          _.get(this, 'agentAssigned.lastName') === 'Assigned';
        return !!(
          this.statusReason !== SUPPORT_TICKET_STATUS_REASON.AWAITING_ASSIGNMENT &&
          this.agentAssigned &&
          !isAgentNameNotAssigned
        );
      }

      /**
       * @description Returns true if this ticket is assigned to the logged in user.
       *
       * @returns {Boolean} true if this ticket is assigned to the logged in user
       */
      isAssignedToLoggedInUser() {
        return this.admin.rengaId === auth.getUserId();
      }

      /**
       * @description Returns true if this ticket has one of the closed statuses.
       *
       * @returns {Boolean} returns true if this ticket is closed, or false if this ticket is open.
       */
      isClosed() {
        return !this.isOpen();
      }

      /**
       * @description Returns true if this ticket's record type is an expert session.
       *
       * @returns {Boolean} returns true if this ticket is an expert session
       */
      isExpertSession() {
        return _.toUpper(this.recordType) === SUPPORT_TICKET_RECORD_TYPE.EXPERT;
      }

      /**
       * @description Returns true if this ticket has an open status.
       *
       * @returns {Boolean} returns true if this ticket is open, or false if this ticket is closed.
       */
      isOpen() {
        return this.status === SUPPORT_TICKET_STATUS.ACTIVE;
      }

      /**
       * @description Returns true if this ticket's record type is a support case.
       *
       * @returns {Boolean} returns true if this ticket is a support case
       */
      isSupportCase() {
        return _.toUpper(this.recordType) === SUPPORT_TICKET_RECORD_TYPE.CASE;
      }

      /**
       * @description Performs an action such as escalate, close or reopen on this support ticket.
       *
       * @param {Object} options - options which define the action to perform
       * @param {SUPPORT_TICKET_ACTION} options.action - the action to perform on the ticket
       * @param {SUPPORT_TICKET_ACTION_REASON_CODE} options.reasonCode - a code representing the
       *   reason the action is being performed
       * @param {String} [options.reasonSummary] - a user-provided description of the reason
       * @returns {promise} promise that resolves with this support ticket when the action has been
       *   performed and this ticket has been refreshed
       */
      performAction(options) {
        const action = _.pick(options, ['reasonCode', 'reasonSummary']);
        _.assign(action, {
          caseId: this.id,
          orgId: OrganizationManager.getActiveOrgId(),
          type: _.toUpper(options.action),
        });

        this.$promise = $q((resolve, reject) => {
          miloApi
            .performTicketAction({action})
            .then(() => {
              emitUpdateItemMessage.call(this);
              return this.refresh();
            })
            .then(() => resolve(this))
            .catch((error) => {
              const returnError = error.response || error;
              $log.error(
                `SupportTicket.performAction(): Failed to perform ${options.action} for ${this.constructor.name} "${this.id}". Error: `,
                returnError
              );
              reject(returnError);
            });
        });
        return this.$promise;
      }

      /**
       * @description Refreshes this support ticket.
       *
       * @returns {Promise} a promise which is resolved when the ticket is refreshed
       */
      refresh() {
        // id, caseType, priority are deprecated from MILO retrieve case and retrieve caseList api and replaced with caseId, typeCodeText, priorityCode respectively.
        // Following code should be modified when AngularJS UI components are replaced with React components.
        this.$resolved = false;
        this.$promise = $q
          .all([
            miloApi.getTicket({
              caseId: this.id,
              orgId,
              params: {locale: locale.getActiveLocaleCode()},
              recordType: _.toUpper(this.recordType),
            }),
            miloApi.getActivities({
              id: this.id,
              includeNonSystemEmail: true,
              orgId,
              regarding: SUPPORT_CONSTANTS.ENTITY.INCIDENT,
            }),
            miloApi.getAttachments({
              id: this.id,
              of: [SUPPORT_CONSTANTS.ENTITY.INCIDENT, SUPPORT_CONSTANTS.ENTITY.EMAIL],
              orgId,
              regarding: SUPPORT_CONSTANTS.ENTITY.INCIDENT,
            }),
          ])
          .then((responses) => {
            const comments = _.concat(responses[1].data.customerNotes, responses[1].data.emails);
            _.assign(responses[0].data, {comments}, responses[2].data, {
              caseType:
                _.toUpper(responses[0].data.typeCodeText) || _.toUpper(responses[0].data.caseType),
              id: responses[0].data.caseId || responses[0].data.id,
              impact: responses[0].data.impactCodeText || responses[0].data.impact,
              priority: responses[0].data.priorityCode || responses[0].data.priority,
            });
            return onSuccess.call(this, responses[0].data);
          })
          .catch(onError.bind(this));
        return this.$promise;

        ///////////////

        function onSuccess(response) {
          initModel.call(this, response);
          this.$resolved = true;
          modelCache.put(this.cacheType, this, this.id);
          return this;
        }

        function onError(error) {
          $log.error(
            `SupportTicket.refresh(): Failed to refresh data from back-end for ${this.constructor.name}. Error: `,
            error
          );
          this.$resolved = true;
          throw error;
        }
      }

      /**
       * @description Method to update support ticket.
       *
       * @param {Object} options support ticket properties that should be updated.
       * @param {Object[]} [options.comments] - updated comment Objects of the form: {"description": "My new comment"}
       * @param {Object} [options.comments.Object] - unnamed Object containing 'description' key/value (see below)
       * @param {String} [options.comments.Object.description] - new comment to add to support ticket
       * @param {String} [options.email] - updated email address string
       * @param {String} [options.phone] - updated contact phone number string
       * @returns {promise} promise that resolves when requested ticket is updated and refreshed.
       *   The promise will resolve with 'this' or will reject with the error.
       */
      update(options) {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          miloApi
            .updateTicket({recordType: _.toUpper(this.recordType), supportTicket: options})
            .then(() => {
              emitUpdateItemMessage.call(this);
              return this.refresh();
            })
            .then(() => resolve(this))
            .catch((error) => {
              const returnError = error.response || error;
              $log.error(
                `SupportTicket.update(): Failed to update ticket for ${this.constructor.name} "${this.id}". Error: `,
                returnError
              );
              reject(returnError);
            })
            .finally(() => {
              this.$resolved = true;
            });
        });
        return this.$promise;
      }

      /**
       * @description Method to create support ticket.
       *
       * @param {Class} ItemClassRef the type of ticket to create (must be a subclass of
       *   SupportTicket)
       * @param {Object} supportTicket properties that describe new support ticket.
       *
       * @returns {promise} promise that resolves when requested ticket is created. The promise will resolve with
       *   an object that contains the id of the support ticket that was just created or will reject with the error.
       */
      static create(ItemClassRef, supportTicket) {
        const ticket = _()
          .defaults(supportTicket, {
            channelCode: SUPPORT_CONSTANTS.SUPPORT_CASE_ORIGIN.WEB,
            impactCode: supportTicket.impact,
            language: lookupDynamicsLocale(locale.getActiveLocaleCode()),
            orgId: OrganizationManager.getActiveOrgId(),
            priorityCode: supportTicket.priority,
            productCode: supportTicket.product
              ? supportTicket.product.fulfillableItemCode
              : undefined,
            productMetadata: supportTicket.caseMetadata,
          })
          .omit(['allowP1', 'caseMetadata', 'impact', 'priority', 'product']) // caseType and recordType is used in SupportTicketEngine.js hence cannot be removed from there.
          .value();

        return $q((resolve, reject) => {
          miloApi
            .createTicket({
              recordType: _.toLower(supportTicket.recordType),
              supportTicket: ticket,
            })
            .then((response) => {
              resolve({id: response.data.caseId});
            })
            .catch((error) => {
              const errorObject = error.response || error;
              $log.error(
                `SupportTicket.create(): Failed to create ticket for ${ItemClassRef.name}. Error: `,
                errorObject
              );
              reject(errorObject);
            });
        });
      }

      /**
       * @description Method to fetch the specified kind of support ticket, either from the cache
       *  if available, else from the backend.
       *
       * @param {Class} ItemClassRef the type of ticket to fetch (must be a subclass of
       *   SupportTicket)
       * @param {Object} options - options to pass to the constructor of the specified subclass
       * @param {String} options.id - required - unique ID of support ticket to retrieve
       * @param {String} options.recordType - required - recordType of the expert session to retrieve
       *
       * @returns {Object} an instance of the specified subclass
       */
      static get(ItemClassRef, options = {}) {
        const model = new ItemClassRef({id: options.id, recordType: options.recordType});
        const cachedModel = modelCache.get(model.cacheType, model.id);
        if (cachedModel) {
          return cachedModel;
        }
        model.refresh();
        return model;
      }
    }

    return SupportTicket;

    /////////////

    function emitUpdateItemMessage() {
      $rootScope.$emit(this.updateItemMessage, this);
    }

    function initModel(options = {}) {
      const expectedOptions = [
        'admin',
        'agentAssigned',
        'attachments',
        'caseId',
        'caseType',
        'comments',
        'createdOn',
        'description',
        'email',
        'id',
        'lastModified',
        'phone',
        'product',
        'productCode',
        'productName',
        'recordType',
        'status',
        'statusReason',
        'surveyUrl',
        'timezone',
        'title',
        'typeCode',
        'typeCodeText',
        ...this.fields,
      ];

      // The API may not always return the expected options listed
      // above. So if a property is missing in the API response we
      // should make sure to remove it from the model as well to
      // ensure the view will be updated as expected
      _.forEach(expectedOptions, (optionName) => {
        if (_.has(options, optionName)) {
          this[optionName] = options[optionName];
        } else {
          delete this[optionName];
        }
      });
    }

    function lookupDynamicsLocale(userLocale) {
      switch (userLocale) {
        case 'de_DE':
        case 'fr_FR':
        case 'ja_JP':
        case 'ko_KR':
          return userLocale;
        case 'zh_CN':
          return 'zh_HANS';
        default:
          return 'en_US';
      }
    }
  }
})();
/* eslint-enable max-lines */
