(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.overview').config(overviewOverview2QuickAssignProductsConfig);

  /* @ngInject */
  function overviewOverview2QuickAssignProductsConfig(
    $stateProvider,
    $transitionsProvider,
    $urlRouterProvider
  ) {
    const quickAssignId2 = 'quick-assign-modal2';
    $stateProvider.state('overview.overview2.quick-assign-products', {
      data: {
        title: 'widgets.users.quickAssignModal2.title',
      },
      params: {
        // optional URL param to force one step
        // steps - "welcome", "assign"
        step: {
          default: undefined,
          squash: true, // won't be shown if not provided
        },
      },
      resolve: {
        isFirstSessionAccess: ($stateParams, feature, productAccess) =>
          showWelcomeScreen($stateParams.step, feature, productAccess),
        modalId: () => quickAssignId2,
        onClosed: ['$state', ($state) => () => $state.go('overview.overview2', $state.params)],
        onInitialized: [
          'feature',
          'panelManager',
          (feature, panelManager) => () =>
            feature.isDisabled('temp_standalone_quick_assign') && panelManager.open(quickAssignId2),
        ],
        preassignedUserIds: [
          'trialHelper',
          function (trialHelper) {
            if (trialHelper.isLoggedInUserTrialOwner()) {
              return [trialHelper.getTrialOwnerUserId()];
            }
            return [];
          },
        ],
      },
      url: '/quick-assign-products?step',
      views: {
        'modal@overview.overview2': {
          component: 'appQuickAssignModal2',
        },
      },
    });

    $stateProvider.state('overview.overview2.quick-assign-products2-react', {
      data: {
        title: 'widgets.users.quickAssignModal2.title',
      },
      params: {
        // optional URL param to force one step
        // steps - "welcome", "assign"
        step: {
          default: undefined,
          squash: true, // won't be shown if not provided
        },
      },
      resolve: {
        /* @ngInject */
        component: (onesieSrc2) => onesieSrc2.products.components.QuickAssignModalWrapper,
        modalId: () => quickAssignId2,
        props: ($q, $stateParams, feature, productAccess) =>
          $q
            .resolve(showWelcomeScreen($stateParams.step, feature, productAccess))
            .then((isFirstSessionAccess) => ({
              isFirstSessionAccess,
            })),
      },
      // Should rename to /quick-assign-products when we remove flag temp_standalone_quick_assign
      url: '/quick-assign-products-react?step',
      views: {
        'modal@overview.overview2': {
          component: 'binkyReactContainer',
        },
      },
    });

    // Redirect to overview page if admin doesn't have access to the quick assign modal
    $transitionsProvider.onEnter({to: 'overview.overview2.quick-assign-products'}, (trans) => {
      const OrganizationManager = trans.injector().get('OrganizationManager');
      const binkySrc2 = trans.injector().get('binkySrc2');
      const productAccess = trans.injector().get('productAccess');
      const feature = trans.injector().get('feature');

      function routeToOverviewPage() {
        if (feature.isEnabled('temp_fix_quick_assign_redirect')) {
          binkySrc2.services.navBus.navigate(`/${OrganizationManager.getActiveOrgId()}/overview`);
          return false;
        }

        return trans.router.stateService.target('overview.overview2', {
          orgId: OrganizationManager.getActiveOrgId(),
        });
      }

      return productAccess
        .canViewQuickAssignModal()
        .then((canView) => {
          if (canView) {
            return true;
          }
          return routeToOverviewPage();
        })
        .catch(() => routeToOverviewPage());
    });

    // Redirect to overview page if admin doesn't have access to the quick assign modal
    $transitionsProvider.onEnter(
      {to: 'overview.overview2.quick-assign-products2-react'},
      (trans) => {
        const OrganizationManager = trans.injector().get('OrganizationManager');
        const productAccess = trans.injector().get('productAccess');

        function routeToOverviewPage() {
          return trans.router.stateService.target('overview.overview2', {
            orgId: OrganizationManager.getActiveOrgId(),
          });
        }

        return productAccess
          .canViewQuickAssignModal()
          .then((canView) => canView || routeToOverviewPage())
          .catch(() => routeToOverviewPage());
      }
    );

    $urlRouterProvider.when(
      '/overview/quick-assign-products?step',
      ($location, $state, orgReady) => {
        const queryParams = $location.search();
        // eslint-disable-next-line promise/catch-or-return
        orgReady.whenRunReady().then((activeOrg) => {
          $state.go('overview.overview2.quick-assign-products', {
            orgId: activeOrg.id,
            step: queryParams.step,
          });
        });
      }
    );

    //////////////

    /**
     * determine whether to show welcome screen
     * @param {String} step "welcome" or "assign" coming from the URL param
     * @param {Object} feature feature library
     * @param {Object} productAccess productAccess utility
     * @returns {Boolean|Promise<Boolean>} - true/false or a promise that
     *     resolves to true/false
     */
    const showWelcomeScreen = (step, feature, productAccess) => {
      if (feature.isEnabled('temp_quick_assign_step')) {
        if (step) {
          return step === 'welcome';
        }
        return productAccess.canViewQuickAssignModalInFirstSessionAccess();
      }
      return productAccess.canViewQuickAssignModalInFirstSessionAccess();
    };
  }
})();
