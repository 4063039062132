import PropTypes from 'prop-types';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const requiredIf =
  (propType, predicate = () => false) =>
  (props, propName, componentName) => {
    const test = predicate(props) ? propType.isRequired : propType;
    return PropTypes.checkPropTypes({[propName]: test}, props, propName, componentName);
  };

export default requiredIf;
