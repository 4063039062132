(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service/provider
   * @name jilStorageRepositories
   * @description defines service to manage jil storage-repositories resources
   */
  angular
    .module('app.core.api.jil')
    .provider('jilStorageRepositories', jilStorageRepositoriesProvider);

  let url;

  /* @ngInject */
  function jilStorageRepositoriesProvider() {
    this.configure = configure;
    this.$get = $get;

    ////////////

    function configure(config) {
      url = `${config.url}/v2/organizations`;
    }

    /* @ngInject */
    function $get($resource, apiUtils) {
      const service = {
        storageRepos: getStorageReposResource(),
      };

      return service;

      ///////////

      function getStorageReposResource() {
        return $resource(
          `${url}/:orgId/storage-repositories`,
          {
            orgId: apiUtils.getActiveOrg,
          },
          {
            batchOperation: {
              method: 'PATCH',
            },
          }
        );
      }
    }
  }
})();
