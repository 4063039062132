import {Divider, Flex, Heading, useDialogContainer} from '@adobe/react-spectrum';
import {ModalContent, ModalDialog} from '@pandora/react-modal-dialog';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const MODAL_ID = 'remove-domain-error-modal';

const RemoveDomainErrorModal = ({selectedDomainsCount, unremovableDomains}) => {
  const dialog = useDialogContainer();

  return (
    <ModalDialog
      dialogStyle={{
        spaceX: 'size-0',
      }}
      id={MODAL_ID}
      onCancel={() => dialog.dismiss()}
      role="alertdialog"
      size="M"
    >
      <Heading level={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <FormattedMessage
            id="settings.domains.removeDomainsErrorModal.title"
            values={{count: selectedDomainsCount}}
          />
          <AlertIcon color="negative" size="M" />
        </Flex>
      </Heading>
      <Divider marginTop="size-250" size="M" />
      <ModalContent>
        <FormattedMessage
          id="settings.domains.removeDomainsErrorModal.description"
          values={{count: selectedDomainsCount}}
        />
        {selectedDomainsCount > 1 && (
          <ul>
            {unremovableDomains.map((domain) => (
              <li key={domain.id}>{domain.domainName}</li>
            ))}
          </ul>
        )}
      </ModalContent>
    </ModalDialog>
  );
};

RemoveDomainErrorModal.propTypes = {
  selectedDomainsCount: PropTypes.number.isRequired,
  unremovableDomains: PropTypes.arrayOf(
    PropTypes.shape({
      domainName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default RemoveDomainErrorModal;
