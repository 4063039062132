(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.domains2').component('appDomainLinkModal', {
    bindings: {
      domainList: '<',
      domainsToLink: '<',
      onCloseLinkModal: '&?',
      onLinkDomains: '&?',
    },
    controller,
    templateUrl: 'app/widgets/domains2/link/domain-link-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $filter,
    $state,
    $translate,
    _,
    APP_LINK_DOMAIN_MODAL_ID,
    DIRECTORY_OWNERSHIP_STATUS,
    DIRECTORY_STATUS,
    DisplayMessage,
    jilDirectories,
    MEMBER_TYPE,
    panelManager,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      $onInit,
      fetchDirectories,
      hidePageBanner,
      onCancel,
      onGoToDirectories,
      onLink,
      onSelectDirectoryListItem,
      showPageBanner,
    });

    /////////////////

    function $onInit() {
      _.assign(vm, {
        directoryPlaceholder: $translate.instant('widgets.domains2.linkDomain.select.placeholder'),
        isBusy: false,
        modalId: APP_LINK_DOMAIN_MODAL_ID,
      });
    }

    // Called when the modal opens.
    function fetchDirectories() {
      vm.waitPromise = jilDirectories.directories
        .query()
        .$promise.then((items) => {
          // Only OWNED and ACTIVE/NEEDS_DOMAINS directories can be linked

          vm.directoryList = _.filter(items, (item) => includeDirectory(item));
          if (vm.directoryList.length > 0) {
            vm.activeDirectoriesPresent = true;
            hidePageBanner();
          } else {
            vm.activeDirectoriesPresent = false;
            showPageBanner({
              message: $translate.instant(
                'widgets.domains2.linkDomain.pageBanner.noActiveDirectories.message'
              ),
              title: $translate.instant(
                'widgets.domains2.linkDomain.pageBanner.noActiveDirectories.title'
              ),
              variant: 'warning',
            });
          }
        })
        .catch(() => {
          vm.activeDirectoriesPresent = false;
          showPageBanner({
            message: $translate.instant(
              'widgets.domains2.linkDomain.pageBanner.directoryListFetchError'
            ),
          });
        });

      ///////////////

      function includeDirectory(item) {
        return (
          item.type !== MEMBER_TYPE.TYPE2E &&
          item.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
          (item.status === DIRECTORY_STATUS.ACTIVE || item.status === DIRECTORY_STATUS.NEEDS_DOMAIN)
        );
      }
    }

    function hidePageBanner() {
      vm.displayMessage = undefined;
    }

    function onCancel() {
      hidePageBanner();
      vm.selectedDirectory = undefined;
      panelManager.close(APP_LINK_DOMAIN_MODAL_ID);
    }

    function onGoToDirectories() {
      $state.go('settings.identity.directories');
      hidePageBanner();
      _.result(vm, 'onCloseLinkModal');
    }

    function onLink() {
      hidePageBanner();

      vm.waitPromise = vm.domainList
        .linkDomainsToDirectory({
          directoryId: vm.selectedDirectory,
          domains: vm.domainsToLink,
        })
        .then(onSuccess)
        .catch(onError);

      function onSuccess(response) {
        const {difference} = response;
        if (difference.length === 0) {
          panelManager.close(APP_LINK_DOMAIN_MODAL_ID);
          toastManager.showSuccessToast(
            $translate.instant(
              'widgets.domains2.linkDomain.toast.messageFormat.success',
              {
                count: vm.domainsToLink.length,
                directoryName: _.find(vm.directoryList, ['id', vm.selectedDirectory]).name,
                formattedDomainCount: $filter('number')(vm.domainsToLink.length),
              },
              'messageformat'
            )
          );
          vm.selectedDirectory = undefined;
          _.result(vm, 'onLinkDomains');
        } else {
          // Grab the domainNames that were not linked
          const domainsToFilter = _.map(difference, (item) => {
            const splitPath = _.split(item.path, '/');
            const domainName = splitPath[1];
            return domainName;
          });

          // Reset the domainsToLink array with ONLY the domains that were not linked
          vm.domainsToLink = _.filter(vm.domainsToLink, (domain) =>
            _.includes(domainsToFilter, domain.domainName)
          );

          // Show the page banner so the user can decide to try again.
          showPageBanner({
            message: $translate.instant(
              'widgets.domains2.linkDomain.pageBanner.messageFormat.linkError',
              {
                count: vm.domainsToLink.length,
              },
              'messageformat'
            ),
          });
        }
      }
      function onError() {
        showPageBanner({
          message: $translate.instant(
            'widgets.domains2.linkDomain.pageBanner.messageFormat.linkError',
            {
              count: vm.domainsToLink.length,
            },
            'messageformat'
          ),
        });
      }
    }

    function onSelectDirectoryListItem(directoryId) {
      vm.selectedDirectory = directoryId;
    }

    function showPageBanner(options) {
      vm.displayMessage = new DisplayMessage({
        body: options.message,
        header: options.title,
        variant: options.variant,
      });
    }
  }
})();
