(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.core.user.available-type-helper
   * @description Available Type helper methods
   */
  angular.module('app.core.user').factory('availableTypeHelper', getAvailableTypeHelper);
  /* @ngInject */
  function getAvailableTypeHelper($translate, _) {
    const service = {
      generateNoSelectableTypesMessage,
    };

    return service;

    //////////////

    function generateNoSelectableTypesMessage(availableTypes) {
      const disabledTypes = _(availableTypes)
        .filter('available')
        .filter(['allowed', false])
        .map('userType')
        .map((userType) => _.toLower(userType))
        .map((userType) =>
          $translate.instant(`binky.widgets.members.memberType.lowercase.${userType}`)
        )
        .sort()
        .value();
      const len = _.size(disabledTypes);
      let message;
      if (len === 1) {
        message = $translate.instant(
          'widgets.users.globalAdmin.addNewUser.messageformat.noSelectableTypes',
          {
            count: len,
            disabledType0: disabledTypes[0],
          },
          'messageformat'
        );
      } else if (len > 1) {
        message = $translate.instant(
          'widgets.users.globalAdmin.addNewUser.messageformat.noSelectableTypes',
          {
            count: len,
            disabledType0: disabledTypes[0],
            disabledType1: disabledTypes[1],
          },
          'messageformat'
        );
      }

      return message;
    }
  }
})();
