(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.hva.hva-card.assembler:HvaBuildActionList
   * @description model that represents a list of HVA (High Value Action) Build Action
   */
  angular
    .module('app.widgets.hva.hva-card.assembler')
    .factory('HvaBuildActionList', hvaBuildActionList);

  /* @ngInject */
  function hvaBuildActionList(_) {
    class HvaBuildActionList {
      constructor() {
        this.priorityOrderSet = new Set();
        this.items = [];
      }

      /**
       * Add new HVA Build Action in to the list
       * @param {HvaBuildAction} hvaBuildAction The build action to be added
       * @throws {Error} an error will be thrown for invalid build actions, or an action that has
       * duplicate priority order.
       */
      add(hvaBuildAction) {
        if (_.invoke(hvaBuildAction, 'isValid')) {
          validateAndUpdatePriorityOrder(this.priorityOrderSet, hvaBuildAction);
          this.items.push(hvaBuildAction);
        } else {
          throw new Error('Attempted to add an invalid HVA build action', hvaBuildAction);
        }
      }

      /**
       * Returns a sorted list of build actions. It's sorted by the key - priority order.
       * @returns {HvaBuildAction[]} a sorted array of build actions.
       */
      getSortedBuildActions() {
        return _.orderBy(this.items, ['priorityOrder']);
      }
    }

    return HvaBuildActionList;

    // Private Functions

    function validateAndUpdatePriorityOrder(priorityOrderSet, hvaCard) {
      const priorityOrder = hvaCard.priorityOrder;
      if (priorityOrderSet.has(priorityOrder)) {
        throw new Error(
          'The input hvaCard with the same priority order has been registered',
          hvaCard
        );
      } else {
        priorityOrderSet.add(priorityOrder);
      }
    }
  }
})();
