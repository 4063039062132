import {useMemo} from 'react';

import {getDisplayNamesFromSelectedSeats} from '../../SelfCancelUtils';
/**
 * @description Hook that retrieves the number of users losing access
 * to licenses to populate the LoseAccessCard
 * @param {Object} selectedSeats the hash map of initially selected licenses per product id
 * @returns {Number} the number of users losing access to licenses
 */
const useLoseAccessCard = ({selectedSeats}) =>
  useMemo(
    () => ({
      numberOfUsers: getDisplayNamesFromSelectedSeats(selectedSeats)?.length,
    }),
    [selectedSeats]
  );

export default useLoseAccessCard;
