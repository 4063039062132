/**
 * @deprecated use Pandora Add Products Modal
 *
 * @ngdoc component
 * @name app.widgets.products.purchase:appPurchaseCart
 *
 * @description A cart component that displays a list of cart items to purchase and the total cost of those items
 *
 * @param {Cart} [cart] Default is undefined. The cart of items to use for the "purchase".
 * @param {Contract} contract The contract that the admin is currently observing.
 * @param {Boolean} [isCartReadOnly] Default is not read only. Tells the cart items if they can be modified.
 * @param {OfferList} offerList The list offers to purchase. When empty, component displays an empty cart message
 * @param {Function} [onItemRemove] Default is undefined. The callback to remove an offer when offer signals it should be removed. When undefined, Cart items should not be removeable.
 * @param {Function} [onItemSelectedChange] Default is undefined. The callback signalling that the number of licenses of a particular cart item should be added or renewed has changed.
 * @param {ProductList} [productList] The list of products owned by the organization. Used to show current license ownership and price points.
 * @param {Boolean} [showFuturePricing] Default is not renewing. Tells the cart items to use pricing when admin renews.
 * @param {Boolean} [showLicensePricing] Default is to show the license scorecard. Used to show the license pricing list instead.
 * @param {Boolean} [showTotal] Default is not to show the total. Deterimines to show total if the contract is direct, the offer list is not empty and the cart is defined
 * @param {Boolean} [restrictPromoGaming] Default is false. If true, cart item will have number input section disabled when displaying promotional offers.
 * @param {Boolean} [useRenewalLabel] Default is to not use renewal labels If true, will pass along to child components to use renewal labels.
 * @param {Boolean} [waitForTotal] Default is not to wait for total. If true, cart items will not display their totals.
 * @param {Promise} waitPromise if a direct contract it is resolved with an object that contains the cart model and the
 *   totalSummary and if indirect it is resolved with an object which contains the 'license_count'
 *
 * @example <app-purchase-cart
 *            cart="$ctrl.cart"
 *            contract="$ctrl.contract"
 *            is-cart-read-only="false"
 *            offer-list="$ctrl.offerList"
 *            on-item-remove="$ctrl.onItemRemove(offerId)"
 *            on-item-selected-change="$ctrl.onItemSelectedChange(offer)"
 *            product-list="$ctrl.productList"
 *            show-future-pricing="false"
 *            show-license-pricing="true"
 *            show-total="true"
 *            restrict-promo-gaming="false"
 *            use-renewal-label=true
 *            wait-for-total="false"
 *            wait-promise="$ctrl.somePromise">
 *          </app-purchase-cart>
 */
(function () {
  angular.module('app.widgets.products.purchase').component('appPurchaseCart', {
    bindings: {
      cart: '<?',
      contract: '<',
      isCartReadOnly: '<?',
      offerList: '<',
      onItemRemove: '&?',
      onItemSelectedChange: '&?',
      positiveScorecard: '<?',
      productList: '<?',
      restrictPromoGaming: '<?',
      showFuturePricing: '<?',
      showLicensePricing: '<?',
      showTotal: '<?',
      useRenewalLabel: '<?',
      waitForTotal: '<?',
      waitPromise: '<',
    },
    controller,
    templateUrl: 'app/widgets/products/purchase/purchase-cart/purchase-cart.component.html',
  });

  /* @ngInject */
  function controller($translate, _) {
    const vm = this;

    _.assign(vm, {
      getCartHeader,
      showTotalSection,
    });

    function getCartHeader() {
      let headerKey;
      if (vm.useRenewalLabel) {
        headerKey = 'yourPlan';
      } else {
        headerKey = vm.contract.isDrContract ? 'productsForTeam' : 'adding';
      }
      return $translate.instant(`widgets.products.purchase.purchaseCart.${headerKey}`);
    }

    function showTotalSection() {
      return vm.showTotal && vm.cart && !_.isEmpty(vm.offerList) && vm.contract.isDirectContract();
    }
  }
})();
