import {MemberManagement, UserPicker, showError, showSuccess} from '@admin-tribe/binky-ui';
import {Heading} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import UserFolder from 'common/services/user-folder/UserFolder';
import {ACTIONS} from 'common/services/user-folder/userFolderConstants';
import rootStore from 'core/RootStore';

const EditFolderAccessModal = ({folder, onCancel, onSuccess, onError}) => {
  const intl = useIntl();
  const [operations, setOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'common.modal.buttons.cancel',
    }),
    ctaLabel: intl.formatMessage({id: 'common.modal.buttons.save'}),
  });

  // This method is called to update the zipAssignedUsers for the current storage folder
  const updateFolder = async () => {
    const usersToAdd = [];
    const usersToRemove = [];

    operations.forEach((operation) => {
      if (operation.type === ACTIONS.ADD) return usersToAdd.push(operation.member);
      return usersToRemove.push(operation.member);
    });

    try {
      setIsLoading(true);
      await folder.save({usersToAdd, usersToRemove});
      showSuccess(intl.formatMessage({id: 'storage.editFolderAccessModal.cta.successMessage'}));
      onSuccess();
    } catch (error_) {
      onError(error_);
      showError(intl.formatMessage({id: 'storage.editFolderAccessModal.cta.errorMessage'}));
    }
    setIsLoading(false);
  };

  return (
    <ModalDialog
      analyticsModalId="edit-access-folder-modal"
      content={modalDialogContent}
      isCtaDisabled={operations.length === 0}
      isLoading={isLoading}
      onCancel={onCancel}
      onCta={updateFolder}
    >
      <ModalHeading level={2}>
        {intl.formatMessage({id: 'storage.editFolderAccessModal.headline'})}
      </ModalHeading>
      <ModalDescription showDivider />
      <ModalContent>
        <Heading level={3} marginStart="size-0">
          {intl.formatMessage({id: 'storage.editFolderAccessModal.headline.secondary'})}
        </Heading>
        <MemberManagement
          existingMembers={folder.zipAssignedUsers}
          onChange={setOperations}
          orgId={rootStore.organizationStore.activeOrgId}
          pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
        />
      </ModalContent>
    </ModalDialog>
  );
};

EditFolderAccessModal.propTypes = {
  /**
   * UserFolder instance passed into the Modal where zipAssignedUsers are hydrated into the TableView
   */
  folder: PropTypes.instanceOf(UserFolder).isRequired,
  /**
   * onCancel callback, to let the caller know when to hide modal because of a no-op
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * onError callback called if we fail to persist the folder
   */
  onError: PropTypes.func.isRequired,
  /**
   * onSuccess callback called after we persist the operations, so that caller can hide the modal
   */
  onSuccess: PropTypes.func.isRequired,
};

export default EditFolderAccessModal;
