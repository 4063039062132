(function () {
  /**
   * @ngdoc module
   * @name app.widgets.bulk-operations.utils
   * @description module that defines utility functions for bulk operations
   */
  angular.module('app.widgets.bulk-operations.utils', [
    'app.widgets.bulk-operations.constants',
    'app.widgets.bulk-operations.localization',
    'binky.core.feature',
    'binky.core.lodash',
    'binky.core.page-context',
    'binky.widgets.common.display-message',
  ]);
})();
