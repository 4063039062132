(function () {
  /**
   * @deprecated use src2 Support pages
   */
  angular.module('app.widgets.support').constant('SUPPORT_CASE_PRIORITY', {
    P1: 1,
    P2: 2,
    P3: 3,
    P4: 4,
  });
})();
