import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import CreatePackageButton from '../../create-package-modal/CreatePackageButton';

import styles from './EmptyPackageList.pcss';

const PackageTypeCard = observer(
  ({
    description,
    isCreatePkgBtnDisabled = false,
    learnMoreLink,
    setCreatePackageFactoryValues,
    title,
  }) => {
    const intl = useIntl();
    return (
      <div className={styles['package-type-card']}>
        <Flex direction="column" gap="size-250" justifyContent="space-between" width="100%">
          <Heading level={2}>
            {intl.formatMessage({
              id: `packages.allPackages.firstUserExperience.${title}`,
            })}
          </Heading>

          <View minHeight="size-1250">
            {intl.formatMessage({id: `packages.allPackages.firstUserExperience.${description}`})}
          </View>

          <View>
            <View
              borderBottomColor="gray-200"
              borderBottomWidth="size-100"
              marginBottom="size-250"
              paddingY="size-175"
            >
              <GoUrl name={learnMoreLink} noWrap={false}>
                {intl.formatMessage({id: `packages.allPackages.firstUserExperience.learnMore`})}
              </GoUrl>
            </View>
            <CreatePackageButton
              buttonLabel={intl.formatMessage({
                id: 'packages.allPackages.firstUserExperience.getStarted',
              })}
              data-testid="create-package-button"
              isDisabled={isCreatePkgBtnDisabled}
              setCreatePackageFactoryValues={setCreatePackageFactoryValues}
              variant="primary"
            />
          </View>
        </Flex>
      </div>
    );
  }
);

PackageTypeCard.displayName = 'PackageTypeCard';
PackageTypeCard.propTypes = {
  /**
   * description of the package type card
   */
  description: PropTypes.string.isRequired,
  /**
   * Boolean to tell if needs to disable the create package button
   * By default it will be false
   */
  isCreatePkgBtnDisabled: PropTypes.bool,
  /**
   * link to learn more page
   */
  learnMoreLink: PropTypes.string.isRequired,
  /**
   * Funtion to set create package values
   */
  setCreatePackageFactoryValues: PropTypes.func.isRequired,
  /**
   * title of the package type card
   */
  title: PropTypes.string.isRequired,
};
export default PackageTypeCard;
