(function () {
  /**
   * @deprecated with the SLS 2.3 move to use React for this table
   *
   * @description Returns list of products that should be shown in the Products section of the Account page.
   * @param {Array} products. Array of Products.
   * @returns {Object} Array of Products should be shown in the Products section.
   */
  // eslint-disable-next-line lodash/prefer-lodash-method
  angular.module('app.widgets.account').filter('appAccountProducts', appAccountProductsFilter);

  /* @ngInject */
  function appAccountProductsFilter(_) {
    return filterFn;

    function filterFn(products) {
      return _.filter(
        products,
        (product) =>
          product.isAdministerable() && !product.fulfillableItemList.hasOrgOnDemandConsumable()
      );
    }
  }
})();
