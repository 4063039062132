(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.core.payment-info-editor').constant('PAYMENT_INFO_EDITOR_EVENT', {
    LOADING: 'paymentInfoEditorLoadingEvent',
    READY: 'paymentInfoEditorReadyEvent',
    SAVED: 'paymentInfoEditorUpdateSuccessEvent',
    STOPPED: 'paymentInfoEditorStoppedEvent',
  });
})();
