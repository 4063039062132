import {computed, makeObservable, observable} from 'mobx';

import {SYNC_SOURCES} from 'features/settings/api/directory-sync';

class DirectoryLink {
  adminEnabled;
  enabled;
  lastSyncDate;
  partnerType;
  status;

  constructor(directoryLinkData) {
    makeObservable(this, {
      adminEnabled: observable,
      enabled: observable,
      isAzure: computed,
      isGoogle: computed,
      lastSyncDate: observable,
      partnerType: observable,
      status: observable,
    });

    this.adminEnabled = directoryLinkData.adminEnabled;
    this.enabled = directoryLinkData.enabled;
    this.status = directoryLinkData.status.syncTaskStatus;
    this.lastSyncDate = directoryLinkData.status.start;
    this.partnerType = directoryLinkData.partnerType;
  }

  get isAzure() {
    return this.partnerType === SYNC_SOURCES.AZURE;
  }

  get isGoogle() {
    return this.partnerType === SYNC_SOURCES.GOOGLE;
  }
}

export default DirectoryLink;
