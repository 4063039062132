(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   *
   * @ngdoc component
   * @name app.widgets.products.purchase:appPaymentMethodSection
   * @description Payment Method Section component, which displays a section with icon,
   *   a Wallet component and integrates with Payment Info Editor to update the payment
   *   instrument for a given contract.
   * @example
   *   <app-payment-method-section contract="$ctrl.contract" wallet-only="true"></app-payment-method-section>
   * @param {Contract} contract. The contract to which the payment instrument is attached.
   * @param {Boolean} [walletOnly] Determines if only the wallet component should be rendered
   * or the entire payment method section. Default is to show the surrounding section-with-icon with the wallet.
   */
  angular.module('app.widgets.products.purchase').component('appPaymentMethodSection', {
    bindings: {
      contract: '<',
      walletOnly: '<?',
    },
    controller,
    templateUrl:
      'app/widgets/products/purchase/payment-method-section/payment-method-section.component.html',
  });

  // eslint-disable-next-line @admin-tribe/admin-tribe/angular-max-params
  function controller(
    _,
    $location,
    $rootScope,
    AnalyticsHelper,
    AuthenticatedUser,
    feature,
    locale,
    OrganizationManager,
    PAYMENT_INFO_EDITOR_EVENT,
    paymentInfoEditor,
    PaymentInstrument
  ) {
    const vm = this;

    _.assign(vm, {
      $onDestroy,
      $onInit,
      openEditPaymentInfoModal,
    });

    const deregisterHandles = [];
    deregisterHandles.push(
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(PAYMENT_INFO_EDITOR_EVENT.SAVED, () => {
        getPaymentInstrument(false);
      }),
      // eslint-disable-next-line angular/on-watch
      $rootScope.$on(PAYMENT_INFO_EDITOR_EVENT.LOADING, (event, info) => {
        const {backdrop} = info;
        if (backdrop) {
          // Temporary fix to show PIE on top of ADD_PRODUCTS_MODAL2
          // PIE overlay is rendered under Add Products modal, due to z-index conflicts.
          // Seems the solution on PIE team will be to provide a new zIndex configuration on their API.
          // We will need to remove this temporary fix and update with PIE proposed solution:
          // https://jira.corp.adobe.com/browse/ECOMM-82957.
          // eslint-disable-next-line no-underscore-dangle
          backdrop.style.zIndex = Coral.Overlay._OverlayManager.getHighestZIndex() + 1;
        }
      })
    );

    function $onDestroy() {
      _.invokeMap(deregisterHandles, _.call);
    }

    function $onInit() {
      vm.nextBillingDate = vm.contract.isM2M() ? vm.contract.getNextBillingDate() : undefined;

      getPaymentInstrument();

      if (isReturningFromPaypal()) {
        openEditPaymentInfoModal();
      }
    }

    function openEditPaymentInfoModal() {
      // lazy-load the PIE by calling Payment Info Editor provider
      // to download the billing sdk and open the PIE modal
      paymentInfoEditor.load({
        contractId: vm.contract.id,
        contractType: 'team-direct',
        country: AuthenticatedUser.get().getCountryCode(),
        currency: _.get(vm.contract, 'billingInfo.nextBillingAmount.currency.iso3code'),
        features: {
          pie: {
            'pf-enable-cta-layout': true,
            'pf-enable-error-banner': true,
          },
        },
        hasStock: OrganizationManager.getProductsForActiveOrg().hasStockProducts(),
        hidePaymentSelector: false,
        isNewPayment: false,
        locale: locale.getActiveLocaleCode(),
        marketSegment: OrganizationManager.isActiveOrgEdu() ? 'EDU' : 'COM',
        orgId: OrganizationManager.getActiveOrgId(),
        paymentStatus: vm.contract.complianceStatus,
        showPaymentInfoDialog: true,
      });

      AnalyticsHelper.dispatchUiEventAnalytics({eventAction: 'click', eventName: 'editPayment'});
    }

    /////////////

    const EDITABLE_PAYMENT_CATEGORIES = {
      CREDIT_CARD: true,
      DEBIT: true,
      OFFLINE: true,
      PAYPAL: true,
      PURCHASE_ORDER: true,
    };

    function canShowEditPayment({paymentCategory} = {}) {
      return EDITABLE_PAYMENT_CATEGORIES[paymentCategory];
    }

    function isReturningFromPaypal() {
      const queryParams = $location.search();

      // the location check is to avoid opening PIE twice when in the account page where two payment method
      // sections are avaiable simultaneously when add products is open. Allow the contract-info section to manage opening in this case.
      return queryParams.paypal && queryParams.token && !_.includes($location.path(), '/account');
    }

    function getPaymentInstrument(useCache = true) {
      // Hiding previous PaymentInstrument before fetching updated one
      vm.paymentInstrument = undefined;
      vm.showEditPayment = false;
      let model;
      return PaymentInstrument.get({
        contractId: vm.contract.id,
        useCache,
      })
        .$promise.then((paymentInstrumentModel) => {
          model = paymentInstrumentModel;
        })
        .finally(() => {
          vm.paymentInstrument = model;
          vm.showEditPayment = canShowEditPayment(model);
        });
    }
  }
})();
