import {ActionButton, Provider, Tooltip, TooltipTrigger, View} from '@adobe/react-spectrum';
import BellIcon from '@spectrum-icons/workflow/Bell';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import './NotificationsDrawerTrigger.pcss';

// Icon to open the notification drawer from the global header
const NotificationsDrawerTrigger = ({notificationDisplayCount, openDrawer}) => {
  const intl = useIntl();
  const formatNotificationCount = intl.formatNumber(notificationDisplayCount);
  return (
    <Provider colorScheme="dark">
      <View backgroundColor="gray-50">
        <TooltipTrigger>
          <ActionButton
            aria-label={intl.formatMessage(
              {id: 'shell.notifications.icon.ariaLabel'},
              {notificationDisplayCount}
            )}
            data-testid="open-notifications-button"
            isQuiet
            onPress={openDrawer}
          >
            <BellIcon aria-hidden size="S" />
            {notificationDisplayCount > 0 && (
              <div
                aria-hidden
                data-testid="notification-count"
                styleName="unread-notification-count"
              >
                {formatNotificationCount}
              </div>
            )}
          </ActionButton>
          <Tooltip>
            {intl.formatMessage({id: 'shell.notifications.toolTip'}, {formatNotificationCount})}
          </Tooltip>
        </TooltipTrigger>
      </View>
    </Provider>
  );
};

NotificationsDrawerTrigger.propTypes = {
  /**
   * Unread notification count displayed
   */
  notificationDisplayCount: PropTypes.number.isRequired,
  /**
   * Call back function to open drawer
   */
  openDrawer: PropTypes.func.isRequired,
};

export default NotificationsDrawerTrigger;
