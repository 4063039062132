(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name app.widgets.directories:directorySyncDomainEntity
   * @description entity that represents a Directory Sync Domain.
   */

  angular.module('app.core.directories.sync').factory('DirectorySyncDomain', directorySyncDomain);

  function directorySyncDomain(
    _,
    DIRECTORY_SYNC_DOMAIN_LINK_STATUS,
    DIRECTORY_SYNC_DOMAIN_VERIFICATION_STATUS
  ) {
    class DirectorySyncDomain {
      // A directory sync domain as returned by the API looks like this:
      // {
      //   "entity": {
      //     "id": "string",
      //     "name": "string",
      //     "primary": true,
      //     "status": "VERIFIED",
      //     "type": "USER"
      //   },
      //   "id": "string",
      //   "status": "LINKED",
      //   "type": "USER"
      // }
      constructor(data = {}) {
        this.id = data.id;
        this.name = _.get(data, 'entity.name');

        this.linkStatus = data.status;
        this.isFree = this.linkStatus === DIRECTORY_SYNC_DOMAIN_LINK_STATUS.FREE;
        this.isLinked = this.linkStatus === DIRECTORY_SYNC_DOMAIN_LINK_STATUS.LINKED;
        this.isInvalid = this.linkStatus === DIRECTORY_SYNC_DOMAIN_LINK_STATUS.INVALID;

        this.verificationStatus = _.get(data, 'entity.status');
        this.isVerified =
          this.verificationStatus === DIRECTORY_SYNC_DOMAIN_VERIFICATION_STATUS.VERIFIED;
      }
    }

    return DirectorySyncDomain;
  }
})();
