(function () {
  /**
   * @deprecated use Pandora Add Products Modal
   */
  // eslint-disable-next-line angular/file-name
  angular
    .module('app.widgets.products.purchase')
    .constant('CANCEL_REASONS', {
      CHANGE_PLAN: 'CHANGE_PLAN',
      DUPLICATE_LICENSES: 'DUPLICATE_LICENSES',
      NO_LONGER_WITH_COMPANY: 'NO_LONGER_WITH_COMPANY',
      NOT_USING_THE_PRODUCT_ENOUGH: 'NOT_USING_THE_PRODUCT_ENOUGH',
      OTHER: 'OTHER',
      TECHNICAL_DIFFICULTY: 'TECHNICAL_DIFFICULTY',
      TOO_EXPENSIVE: 'TOO_EXPENSIVE',
    })
    // Array of CANCEL_REASONS keys used for display order
    .constant('CANCELLATION_REASONS_LIST', [
      'NOT_USING_THE_PRODUCT_ENOUGH',
      'DUPLICATE_LICENSES',
      'TOO_EXPENSIVE',
      'CHANGE_PLAN',
      'NO_LONGER_WITH_COMPANY',
      'TECHNICAL_DIFFICULTY',
      'OTHER',
    ]);
})();
