(function () {
  /**
   * @deprecated use src2
   *
   * @ngdoc factory
   * @name StorageQuota
   * @description Model for the Storage Quota of this org
   */
  angular.module('app.core.storage-quota').factory('StorageQuota', getStorageQuotaModel);

  /* @ngInject */
  function getStorageQuotaModel(
    $log,
    $q,
    _,
    CART_EVENT,
    jilStorageQuota,
    MODEL,
    modelCache,
    STORAGE_QUOTA_UNIT
  ) {
    class StorageQuota {
      /**
       * @description Creates a new StorageQuota for use.
       *
       * @param {Object} options Initialization Object (params described below)
       * @param {String} options.enforcement The enforcement of the quota for this org
       * @param {Number} options.amount The total number of quota
       * @param {Number} options.consumed The number of consumer quota
       * @param {String} options.unit The unit of quota
       */
      constructor(options = {}) {
        updateModel(this, options);
      }

      getAmount() {
        return _.toInteger(this.amount);
      }

      getEnforcement() {
        return this.enforcement;
      }

      getUsagePercentage() {
        return _.round((this.consumed / this.amount) * 100);
      }

      /**
       * @description Method to fetch storage quota from back-end.
       * @returns {Promise} resolves to StorageQuota on success, else rejects with error
       */
      refresh() {
        this.$resolved = false;
        this.$promise = jilStorageQuota.storageQuota
          .get()
          .$promise.then(onSuccess.bind(this))
          .catch(onError.bind(this));

        function onError(error) {
          this.$resolved = true;
          return $q.reject(error);
        }

        function onSuccess(response) {
          this.$resolved = true;
          updateModel(this, response);
          modelCache.put(MODEL.STORAGEQUOTA, this);
          return this;
        }

        return this.$promise;
      }

      /**
       * @description Method to fetch the StorageQuota. It will be refreshed on
       *     registering the active org
       * @returns {StorageQuota} singleton StorageQuota.
       */
      static get() {
        let model = new StorageQuota();
        const cachedModel = modelCache.get(MODEL.STORAGEQUOTA);
        if (cachedModel) {
          model = cachedModel;
        } else {
          model.refresh();
        }
        return model;
      }

      /**
       * @class
       * @description Transforms the /organizations StorageQuota response Object into a new
       *   StorageQuota instance for use.
       *
       * @param {Object} dataTransferObject Initialization Object (params described below)
       */
      static apiResponseTransformer(dataTransferObject) {
        return new StorageQuota(dataTransferObject);
      }
    }

    /** Private Methods **/

    /**
     * @description Initializes Storage Quota data.
     *
     * @param {Object} model StorageQuota model Object instance to initialize
     * @param {Object} options initialization object (as described in constructor options parameter)
     */
    function updateModel(model, options = {}) {
      // First we assign the model fields
      _.assignIn(
        model,
        _(options)
          .pick(['enforcement', 'amount', 'consumed'])
          // we clone to avoid issues when updating the nested object items
          .cloneDeep()
      );
      getUnit(model, options.unit);
      // setup model as already resolved which ensures it can be used
      model.$resolved = true;
      model.$promise = $q.resolve(model);
    }

    function getUnit(model, unit) {
      if (STORAGE_QUOTA_UNIT[_.upperCase(unit)]) {
        model.unit = STORAGE_QUOTA_UNIT[_.upperCase(unit)];
      } else if (unit === undefined) {
        model.unit = STORAGE_QUOTA_UNIT.GIGABYTES;
      } else {
        $log.error(`Invalid Storage Quota Unit: ${unit}`);
      }
    }

    // We register the cache size for this class
    modelCache.register(MODEL.STORAGEQUOTA, 1);
    modelCache.removeAllOnEvent(MODEL.STORAGEQUOTA, [CART_EVENT.SUBMIT]);

    return StorageQuota;
  }
})();
