import binkyUI, {ModalContent, ModalDescription, ModalWizardStepList} from '@admin-tribe/binky-ui';
import {Heading, NumberField, Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import auth from '../../../../../core/services/auth';
import {useProductProfileModalContext} from '../AddProductProfileModalContext';

import './AddProductProfileModalQuotaDetailsPage.pcss';

const GoUrl = binkyUI.common.components.GoUrl;

const AddProductProfileModalQuotaDetailsPage = () => {
  const intl = useIntl();
  const {product, currentProductProfile, setModalError, setIsValid} =
    useProductProfileModalContext();
  const [quotaNumberValue, setQuotaNumberValue] = React.useState(
    currentProductProfile.totalQuantity
  );

  const productName = product.longName;
  const PRODUCT_QUOTA_MAX = 999999;

  const canUpdateQuota = React.useMemo(
    () =>
      auth.isUserOrgAdmin() ||
      auth.isUserContractAdmin() ||
      auth.isUserProductAdminForTarget(product.targetExpression),
    [product.targetExpression]
  );

  return (
    <>
      <ModalDescription>
        <ModalWizardStepList />
        <Text data-testid="description-text">
          {intl.formatMessage(
            {
              id: 'products.addProductProfileModal.quota.description',
            },
            {
              goUrl: (str) => <GoUrl name="aac_manage_product_quotas">{str}</GoUrl>,
            }
          )}
        </Text>
      </ModalDescription>
      <ModalContent>
        <Heading data-testid="quota-subheading" level={3} marginTop="0">
          {intl.formatMessage({
            id: 'products.addProductProfileModal.quota.title',
          })}
        </Heading>
        <p>
          {intl.formatMessage({
            id: 'products.addProductProfileModal.quota.description2',
          })}
        </p>
        <p
          data-testid="product-name"
          // eslint-disable-next-line react/forbid-dom-props -- aria needs id
          id="product-name-description"
          styleName="product-name"
        >
          {intl.formatMessage(
            {
              id: 'products.addProductProfileModal.quota.productLicenses',
            },
            {numAvailable: product.getAssignableLicenseCount(), productName}
          )}
        </p>
        <NumberField
          data-testid="quota-number-input"
          decrementAriaLabel={intl.formatMessage({
            id: 'products.addProductProfileModal.quota.decrementQuotaLabel',
          })}
          id="quota-input"
          incrementAriaLabel={intl.formatMessage({
            id: 'products.addProductProfileModal.quota.incrementQuotaLabel',
          })}
          isDisabled={!canUpdateQuota}
          label={intl.formatMessage({id: 'products.addProductProfileModal.quota.quotaLabel'})}
          maxValue={PRODUCT_QUOTA_MAX}
          minValue={0}
          onChange={(value) => {
            setModalError(null);
            currentProductProfile.totalQuantity = value;
            setQuotaNumberValue(currentProductProfile.totalQuantity);
            const invalidQuotaInput = value === null || value > PRODUCT_QUOTA_MAX || value < 0;
            setIsValid(!invalidQuotaInput);
          }}
          value={quotaNumberValue}
        />
      </ModalContent>
    </>
  );
};

export default AddProductProfileModalQuotaDetailsPage;
