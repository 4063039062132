import {Table as SpectrumTable} from '@react/react-spectrum/Table';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Wait should be replaced with v3 ProgressCircle
import Wait from '@react/react-spectrum/Wait';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import requiredIf from 'common/prop-types/requiredIf';

import Cell from './Cell';
import Pagination from './Pagination';
import Row from './Row';
import TableBody from './TableBody';
import TableConstants from './TableConstants';
import TableContext from './TableContext';
import TableHeader from './TableHeader';
import usePagination from './usePagination';
import useSorting from './useSorting';

import './Table.pcss';

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
/**
 * Component for displaying tabular data
 *
 * @component
 * @example
 * <Table
 *   paginated={true/false}
 *   totalItems={10}
 *   loading={true/false}
 *   onPageChanged={function() {}}
 *   onPageSizeChanged={function() {}}>
 *   <TableHeader>
 *     <Cell header={true/false} sortable={true/false} onSort={function() {}}>
 *       ID
 *     </Cell>
 *   </TableHeader>
 *   <TableBody>
 *     {items.map((item) => (
 *       <Row key={item.id}>
 *         <Cell>{item.id}</Cell>
 *       </Row>
 *     ))}
 *   </TableBody>
 * </Table>
 */
const Table = observer((props) => {
  const tableContext = useRef({
    isTableSelectable: props.selectable,
  }).current;

  return (
    <TableContext.Provider value={tableContext}>
      <div className={props.className} styleName="table">
        <SpectrumTable role="grid" {...props}>
          {props.children}
        </SpectrumTable>
        {props.loading ? (
          <div styleName="wait-container">
            <Wait centered />
          </div>
        ) : null}
      </div>

      {props.paginated ? (
        <Pagination
          currentPage={props.currentPage}
          defaultPageSize={props.defaultPageSize || TableConstants.DEFAULT_PAGE_SIZE}
          onGoNext={props.onGoNext}
          onGoPrevious={props.onGoPrevious}
          onPageSizeChange={props.onPageSizeChange}
          pageSize={props.pageSize}
          pageSizeOptions={props.pageSizeOptions || TableConstants.PAGE_SIZES}
          totalPages={props.totalPages}
        />
      ) : null}
    </TableContext.Provider>
  );
});

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  currentPage: requiredIf(PropTypes.number, (props) => props.paginated),
  loading: PropTypes.bool,
  onGoNext: PropTypes.func,
  onGoPrevious: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  paginated: PropTypes.bool,
  selectable: PropTypes.bool,
  totalPages: requiredIf(PropTypes.number, (props) => props.paginated),
};

export {Table, TableBody, TableHeader, Row, Cell, usePagination, useSorting};
