(function () {
  /**
   * @deprecated - This file will be removed once all routes are migrated to React
   * See GlobalHeader.jsx for the new header component
   */
  angular.module('app.shell').component('appShell', {
    controller,
    templateUrl: 'app/shell/shell.component.html',
  });

  /* @ngInject */
  function controller(
    _,
    $q,
    $log,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $transitions,
    $uiRouterGlobals,
    APP_OKTA_WARNING_MODAL_ID,
    auth,
    binkyUISrc2,
    DirectoryList,
    feature,
    idpStateManager,
    MESSAGE,
    onesieSrc2,
    OrganizationManager,
    organizationAccess,
    PageContext,
    PAGE_TARGET,
    panelManager,
    ROLE
  ) {
    const vm = this;

    /* Bindable members */
    _.assign(vm, {
      $onDestroy,
      $onInit,
      canAddProducts: false,
      feature,
      isReactRoute: onesieSrc2.core.routeMigration.isReactRouteByState($state.current.name),
    });

    /////////
    let onGlobalBannerModalOpenListener,
      onHideAngular,
      onOrgChangeErrorListener,
      onOrgChangeStartListener,
      onOrgChangeSuccessListener;

    function $onDestroy() {
      onGlobalBannerModalOpenListener();
      onOrgChangeErrorListener();
      onOrgChangeStartListener();
      onOrgChangeSuccessListener();
      onHideAngular();
    }

    function $onInit() {
      $transitions.onSuccess({}, (transition) => {
        const {name} = transition.to();

        // Don't render the state if it's a React route
        vm.isReactRoute = onesieSrc2.core.routeMigration.isReactRouteByState(name);

        if (vm.isReactRoute) {
          // This is handled in App.jsx during a React route
          vm.waitOnShell.promise = $q.resolve();
        }
      });

      _.assign(vm, {
        BANNER_ORGANIZATION_TYPE_MODAL_ID:
          onesieSrc2.core.organizations.organizationSettings.BANNER_ORGANIZATION_TYPE_MODAL_ID,
        binkyUISrc2,
        HelpRailContainer: onesieSrc2.shell.help.rail.HelpRailContainer,
        onesieSrc2,
        PortalContainerInDocumentBody: onesieSrc2.shell.components.PortalContainerInDocumentBody,
      });
      vm.waitOnShell = {
        promise: $q.resolve(),
      };
      let deferred;
      onOrgChangeStartListener = $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.START, () => {
        if (!vm.isReactRoute) {
          deferred = $q.defer();
          vm.waitOnShell.promise = deferred.promise;
        }
      });
      onOrgChangeSuccessListener = $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.SUCCESS, () => {
        if (!vm.isReactRoute && deferred) {
          deferred.resolve();
          initialization();
        }
      });
      onOrgChangeErrorListener = $rootScope.$on(MESSAGE.CHANGE.ACTIVEORGANIZATION.ERROR, () => {
        if (!vm.isReactRoute && deferred) {
          deferred.reject();
        }
      });
      onHideAngular = $rootScope.$on(onesieSrc2.shell.utils.HIDE_ANGULAR, () => {
        // Hides the Angular shell's content when this event is fired
        // This is needed because some navigation actions don't trigger an Angular re-render, so the
        // value of isReactRoute does not get re-calculated
        vm.isReactRoute = true;
        $rootScope.$apply();
      });

      onGlobalBannerModalOpenListener = $rootScope.$on(
        onesieSrc2.shell.components.GLOBAL_BANNER_EVENT.MODAL.OPEN,
        onGlobalBannerModalOpen
      );

      initialization();

      // Only show the modal if there is no return from login, flag is on and user is org admin
      if (
        !idpStateManager.isLoginInProgress() &&
        feature.isEnabled('temp_okta_modal_warning') &&
        auth.hasAnyRole([ROLE.ADMIN.ORG])
      ) {
        showOktaWarningModal();
      }

      onesieSrc2.core.rootStore.setIsReady(true);
    }

    function initialization() {
      initOrgTypeModal();
      fetchMigrationEligibleContracts();
      vm.addProductsPageContext = new PageContext({
        target: PAGE_TARGET.ORGANIZATION,
        targetId: $uiRouterGlobals.params.orgId,
      });
    }

    function fetchMigrationEligibleContracts() {
      return OrganizationManager.getContractsForActiveOrg().$promise.then((contractList) => {
        if (contractList) {
          initTeamToEnterpriseMigrationEligibleContract(contractList);

          vm.canAddProducts =
            contractList.canAddProducts() &&
            // Currently this org admin check is redundant because the JIL
            // contracts API is already doing this check, but that will be
            // removed in this PR: https://git.corp.adobe.com/JIL-v2/jil-core/pull/12275
            // to unblock the Orders API from getting doctored symptoms. This
            // comment can be removed when https://jira.corp.adobe.com/browse/ONESIE-33187
            // is completed.
            onesieSrc2.core.organizations.access.isOrgAdmin();
        }
      });

      function initTeamToEnterpriseMigrationEligibleContract(contractList) {
        if (!vm.migrationEligibleContract || !contractList.isMigrating()) {
          vm.migrationEligibleContract =
            contractList.getTeamToEnterpriseMigrationEligibleContract();
        }
      }
    }

    function initOrgTypeModal() {
      // eslint-disable-next-line promise/catch-or-return
      organizationAccess.canSetUpOrganizationTypes().then((canSetUpOrganizationTypes) => {
        vm.canSetUpOrganizationTypes = canSetUpOrganizationTypes;
      });
    }

    function onGlobalBannerModalOpen(args, {modalId}) {
      panelManager.open(modalId);
      $scope.$apply();
    }

    function showOktaWarningModal() {
      // Give a chance to the okta warning modal to register
      $timeout(() => {
        DirectoryList.get({orgId: OrganizationManager.getActiveOrgId()})
          .$promise.then((directoryList) => {
            const hasOktaDirectories = _.some(directoryList.items, {isPrimaryIdpOkta: true});
            if (hasOktaDirectories) {
              panelManager.open(APP_OKTA_WARNING_MODAL_ID);
            }
          })
          .catch((error) => $log.error(error));
      });
    }
  }
})();
