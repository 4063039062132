import {OverlayWait} from '@admin-tribe/binky-ui';
import {Cell, Column, Item, Picker, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {
  EN_DASH,
  SELECTION_MODE,
  SORT_ORDER,
  Table,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import DeviceActionsMenu from 'features/products/device-licenses/components/device-actions-menu/DeviceActionsMenu';
import DeviceListActions from 'features/products/device-licenses/components/device-list/device-list-actions/DeviceListActions';
import DeviceStatus from 'features/products/device-licenses/components/device-list/device-list-table/DeviceStatus';
import NoDevicesView from 'features/products/device-licenses/components/no-devices-view/NoDevicesView';
import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {TABLE_SECTION_ACTIONS} from 'features/products/hooks/useDeviceList';

/**
 * Defines the list of the table of devices
 */
const DeviceListTable = ({
  deviceList,
  hasLoadingError,
  isLoading,
  isSdl,
  licenseGroupId,
  productId,
  namespace,
  onTableSectionChange,
  tableOptions,
  pageNumber,
  showIPAddress,
  totalLicensesCount,
}) => {
  const intl = useIntl();
  const {totalDevicesCount} = useDeviceLicensesPageContext();

  const DEVICE_STATUSES = {
    activatedDevices: intl.formatMessage({
      id: `${namespace}.filter.activatedDevices`,
    }),
    blockedDevices: intl.formatMessage({
      id: `${namespace}.filter.blockedDevices`,
    }),
  };
  return (
    <OverlayWait isLoading={isLoading} showContent>
      <TableSection
        data-testid="device-view-table"
        isServerError={hasLoadingError}
        items={deviceList}
        onTableSectionChange={onTableSectionChange}
        pageNumber={pageNumber}
        selectionMode={SELECTION_MODE.MULTIPLE}
        totalPages={tableOptions?.totalPages}
      >
        <TableFilters
          label={intl.formatMessage({
            id: `${namespace}.searchLabel`,
          })}
        >
          <Picker
            data-testid="device-filter"
            defaultSelectedKey="ALL_STATUSES"
            isDisabled={!totalDevicesCount || totalDevicesCount === EN_DASH}
            label={intl.formatMessage({
              id: `${namespace}.statusDropdown.label`,
            })}
            marginBottom="size-300"
            marginStart="size-200"
            onSelectionChange={(value) =>
              onTableSectionChange({
                action: TABLE_SECTION_ACTIONS.FILTER_BY_STATUS,
                payload: {value},
              })
            }
          >
            <Item key="ALL_STATUSES">
              {intl.formatMessage({
                id: `${namespace}.filter.allDevices`,
              })}
            </Item>
            {Object.keys(DEVICE_STATUSES).map((status) => (
              <Item key={status}>
                {intl.formatMessage({
                  id: `${namespace}.filter.${status}`,
                })}
              </Item>
            ))}
          </Picker>
        </TableFilters>
        <DeviceListActions
          licenseGroupId={licenseGroupId}
          productId={productId}
          totalLicensesCount={totalLicensesCount}
        />
        <Table
          aria-label={intl.formatMessage({id: `${namespace}.table.ariaLabel`})}
          renderEmptyState={() => <NoDevicesView isSdl={isSdl} />}
          sortDescriptor={{column: 'deviceName', direction: SORT_ORDER.ASC}}
        >
          <TableHeader>
            <Column key="deviceName" allowsSorting data-testid="device-name">
              {intl.formatMessage({
                id: `${namespace}.keys.deviceName`,
              })}
            </Column>
            <Column key="deviceAction" data-testid="device-action" hideHeader showDivider />
            <Column key="deviceId" data-testid="device-id">
              {intl.formatMessage({
                id: `${namespace}.keys.deviceId`,
              })}
            </Column>

            <Column key="ipAddress" hideHeader={!showIPAddress}>
              {intl.formatMessage({
                id: `${namespace}.keys.ipAddress`,
              })}
            </Column>
            <Column key="activationState" data-testid="activation-state">
              {intl.formatMessage({
                id: `${namespace}.keys.status`,
              })}
            </Column>
          </TableHeader>
          <TableBody items={deviceList}>
            {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- Not a large computation */}
            {(item) => (
              <Row key={item.id}>
                <Cell data-testid="device-item-name-cell">{item.deviceName ?? EN_DASH}</Cell>
                <Cell data-testid="device-action-cell">
                  <DeviceActionsMenu
                    deviceDetails={new Device(item)}
                    licenseGroupId={licenseGroupId}
                    onTableSectionChange={onTableSectionChange}
                    productId={productId}
                    showIPAddress={showIPAddress}
                    totalLicensesCount={totalLicensesCount}
                  />
                </Cell>
                <Cell data-testid="device-item-device-id-cell">{item.deviceId}</Cell>
                <Cell data-testid="device-item-ip-address-cell">
                  {showIPAddress && (item.ipAddress ?? EN_DASH)}
                </Cell>
                <Cell data-testid="device-item-status-cell">
                  <DeviceStatus status={item.activationState} />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableSection>
    </OverlayWait>
  );
};

DeviceListTable.displayName = 'DeviceListTable';

DeviceListTable.propTypes = {
  /**
   * The device list details
   */
  deviceList: PropTypes.arrayOf(
    PropTypes.shape({
      // Item returned by frl-device-activation-details API
      activationState: PropTypes.string,
      deviceId: PropTypes.string.isRequired,
      deviceName: PropTypes.string,
      ipAddress: PropTypes.string,
    })
  ).isRequired,

  /**
   * Whether an error occurred during fetching data or not.
   */
  hasLoadingError: PropTypes.bool.isRequired,

  /**
   * Whether loading the devices is in progress or not.
   */
  isLoading: PropTypes.bool,

  /**
   * isSdl - Flag which indicates if product is sdl or not
   */
  isSdl: PropTypes.bool,

  /**
   *  licenseGroupId - Id of the current product profile
   */
  licenseGroupId: PropTypes.string,

  /**
   * The common namespace for react-intl
   */
  namespace: PropTypes.string.isRequired,

  /**
   * Handler for the table interaction
   * eg: search, filter, navigate to another page
   */
  onTableSectionChange: PropTypes.func.isRequired,

  /**
   * The number of the Page
   */
  pageNumber: PropTypes.number.isRequired,

  /**
   *  productId - Id of the current product
   */
  productId: PropTypes.string.isRequired,

  /**
   * showIPAddress - Flag which indicates to show/hide the IP Address column in the Device Table
   */
  showIPAddress: PropTypes.bool.isRequired,

  /**
   * The options required by the @pandora/react-table-section.
   */
  tableOptions: PropTypes.shape({
    page: PropTypes.number,
    pageSizeOptions: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,

  /**
   * The total number of licenses. This will be initially undefined until it is resolved
   */
  totalLicensesCount: PropTypes.number,
};

export default DeviceListTable;
