import {Link} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Cell, Row, Table, TableBody, TableHeader} from 'common/components/table/Table';
import ImportedDomainsStatus from 'features/settings/components/dns-import-domains-modal/ImportedDomainStatus';
import {ERROR_CODES} from 'features/settings/stores/AddDomainsByDnsStore';

const ERRORS = {
  [ERROR_CODES.DOMAIN_ALREADY_CLAIMED]:
    'settings.directoryDomains.reviewDomainsTable.table.errors.alreadyClaimed',
  [ERROR_CODES.DOMAIN_NAME_INVALID]:
    'settings.directoryDomains.reviewDomainsTable.table.errors.nameInvalid',
  [ERROR_CODES.DOMAIN_NOT_LINKED]:
    'settings.directoryDomains.reviewDomainsTable.table.errors.notLinked',
  [ERROR_CODES.DOMAIN_RESERVED]:
    'settings.directoryDomains.reviewDomainsTable.table.errors.domainReserved',
  [ERROR_CODES.UNKNOWN]: 'settings.directoryDomains.reviewDomainsTable.table.errors.unknown',
};

const ReviewDomainsTable = ({domains, onRemoveDomain}) => {
  const intl = useIntl();

  return (
    <>
      <p>
        <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.description" />
      </p>
      <Table>
        <TableHeader>
          <Cell header>
            <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.table.domain" />
          </Cell>
          <Cell header>
            <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.table.status" />
          </Cell>
          <Cell header>
            <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.table.error" />
          </Cell>
        </TableHeader>
        <TableBody>
          {domains.length === 0 ? (
            <Row>
              <Cell colspan="3">
                <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.table.noDomains" />
              </Cell>
            </Row>
          ) : null}
          {domains.map((domain) => (
            <Row key={domain.domainName}>
              <Cell>{domain.domainName}</Cell>
              <Cell>
                <ImportedDomainsStatus domain={domain} />
              </Cell>
              <Cell>
                {domain.isAvailable ? (
                  <FormattedMessage id="settings.directoryDomains.reviewDomainsTable.table.errors.noError" />
                ) : (
                  <FormattedMessage id={ERRORS[domain.errorCode]} />
                )}
              </Cell>
              <Cell>
                <Link onPress={() => onRemoveDomain(domain.domainName)} role="button">
                  {intl.formatMessage({
                    id: 'settings.directoryDomains.reviewDomainsTable.table.remove',
                  })}
                </Link>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

ReviewDomainsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: Add mobx react proptypes to support observable types
  domains: PropTypes.any,
  onRemoveDomain: PropTypes.func,
};

export default observer(ReviewDomainsTable);
