import {HybridListState} from '@admin-tribe/binky';
import {TableConstants, TokenPagingTableStore} from '@admin-tribe/binky-ui';
import {action, makeObservable, runInAction} from 'mobx';

import {JIL_STORAGE_FOLDER_SORT} from 'common/api/jil/jilStorageFolderConstants';
import StorageReportList from 'common/services/storage-report-list/StorageReportList';

// Domain data for Storage report list.
class StorageReportStore extends TokenPagingTableStore {
  folderType = undefined;

  constructor({folderType}) {
    super({
      overflowMode: TableConstants.OVERFLOW_MODE.WRAP,
      selectionMode: TableConstants.SELECTION_MODE.MULTIPLE,
      sortDescriptor: {},
    });

    this.folderType = folderType;

    makeObservable(this, {
      fetchData: action,
    });
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    if (this.tokenService) {
      await this.tokenService.refresh();
    } else {
      this.tokenService = await StorageReportList.get({
        folderType: this.folderType,
        state: new HybridListState({
          sortKey: JIL_STORAGE_FOLDER_SORT.CREATED_DATE,
          sortReverse: true,
        }),
      });
    }

    runInAction(() => {
      // Update the rows displayed in the table
      this.tableStore.mapDataToRows(this.tokenService.items, {isSelectableFn: () => true});
    });
  }
}

export default StorageReportStore;
