import {Content, Grid, Heading, IllustratedMessage, Image} from '@adobe/react-spectrum';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import NotificationBell from './NotificationBell.svg';

/**
 * @description notifications bumper component
 */
const NotificationsBumper = () => (
  <Grid areas={['icon', 'message']} data-testid="notifications-bumper" justifyItems="center">
    <Image alt="" gridArea="icon" marginTop="size-2000" src={NotificationBell} width="size-2000" />
    <IllustratedMessage gridArea="message">
      {/* IllustratedMessage does not expect images, only spectrum icons. Using IllustratedMessage for Spectrum styling */}
      <Heading data-testid="bumper-title">
        <FormattedMessage id="notifications.bumper.none.title" />
      </Heading>
      <Content data-testid="bumper-content">
        <FormattedMessage id="notifications.bumper.none.content" />
      </Content>
    </IllustratedMessage>
  </Grid>
);

export default NotificationsBumper;
