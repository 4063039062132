import {feature} from '@admin-tribe/binky';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {updateIdpStatus} from 'features/settings/api/ims-federated';

const IDP_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
};

class IdpStore {
  hasError = false;
  idp;
  isLoading = false;

  constructor(idp) {
    makeObservable(this, {
      hasError: observable,
      idp: observable,
      isDropdownButtonVisible: computed,
      isLoading: observable,
      setIdp: action,
      updateIdpStatus: action,
    });

    this.idp = idp;
  }

  get isDropdownButtonVisible() {
    if (this.idp.isSoidc) {
      return feature.isDisabled('temp_disable_edu_adding');
    }
    return this.idp.isTestable && !this.idp.isDefault;
  }

  setIdp(idp) {
    this.idp = idp;
  }

  async updateIdpStatus() {
    this.isLoading = true;
    const authSourceId = this.idp.authSrcId;
    const idpId = this.idp.id;
    const status = this.idp.isDisabled ? IDP_STATUS.ACTIVE : IDP_STATUS.DISABLED;

    try {
      await updateIdpStatus({
        authSourceId,
        idpId,
        status,
      });

      this.hasError = false;
    } catch (error) {
      runInAction(() => {
        this.hasError = true;
      });

      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default IdpStore;
export {IDP_STATUS};
