/**
 * @description Method to return the instance name for a given product
 * @param {Product} product - the product to retrieve the instance name from
 * @returns the constructed instance name
 */
function getProductInstanceName(product) {
  return product.fulfillableEntityResourceName || getUniqueResourceName(product);
}

/**
 * @description Method to generate and return a unique name for a given product
 *   that we can use in lieu of a FERN (fulfilled entity resource name). Since a
 *   FERN can be undefined at runtime, we need a way to generate unique names
 *   that we can use in the UI. Otherwise, we would display a list of blank
 *   names with no way to click into additional details
 * @param {Product} product - the product to generate a unique name for
 * @returns {String} the unique product name to use
 */
function getUniqueResourceName(product) {
  return `${product.longName} - ${product.id}`;
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {getProductInstanceName};
