/**
 * @deprecated use src2 Support pages
 *
 * @ngdoc component
 * @name app.widgets.support:appCaseCreatingView
 *
 * @description The creating case view for the Create Case modal wizard.
 * @param {Array} attachments - storage for any attachments added while creating
 *   a support case (optional)
 * @param {SupportCase} supportTicket - reference to newly-created SupportCase,
 *   this is required for the component to continue, but the component can and
 *   should be shown prior to this binding being present (will show that case
 *   is being created, until the created case is passed in)
 */
(function () {
  angular.module('app.widgets.support').component('appCaseCreatingView', {
    bindings: {
      attachments: '<?',
      supportTicket: '<',
    },
    controller,
    templateUrl:
      'app/widgets/support/create-case-modal/case-creating-view/case-creating-view.component.html',
  });

  function controller($q, $state, $translate, _, CREATE_CASE_MODAL_ID, panelManager, toastManager) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
    });

    function $onChanges(changesObj) {
      if (changesObj.supportTicket && vm.supportTicket) {
        if (_.isEmpty(vm.attachments)) {
          finalizeOnSuccess(vm.supportTicket.id);
        } else {
          uploadCaseAttachments().finally(() => finalizeOnSuccess(vm.supportTicket.id));
        }
      }
    }

    ////////

    function finalizeOnSuccess(caseId) {
      panelManager.close(CREATE_CASE_MODAL_ID);

      toastManager.showSuccessToast(
        $translate.instant('widgets.support.createCaseModal.toast.caseCreated', {
          caseId,
        })
      );

      $state.go('org.support.support-case-details', {id: caseId});
      vm.uploadingAttachment = false;
    }

    /**
     * @description Method to upload case attachments to a newly-created Support
     *   case.
     * @return {Promise} resolves when attachments have been uploaded and case has
     *   been refreshed in cache (never rejects - attaching files is not an action
     *   that can fail the case creation process)
     */
    function uploadCaseAttachments() {
      return $q((resolve) => {
        vm.attachmentNumber = 1;
        vm.totalAttachments = vm.attachments.length;
        vm.uploadingAttachment = true;
        vm.supportTicket
          .addAttachments({
            attachments: vm.attachments,
            onAttachmentError,
            onAttachmentSuccess,
            // Avoid the usual refresh after uploading the attachments, because the model will soon
            // be refreshed again when navigating to the detail page
            refreshAfterUpload: false,
          })
          .finally(() => resolve());
      });

      /**
       * @description Method to handle case when a single file succeeds in being
       *   attached to the Support Ticket.
       */
      function onAttachmentSuccess() {
        vm.attachmentNumber += 1;
      }

      /**
       * @description Method to handle case when a single file fails to be
       *   attached to the Support Ticket.
       * @param {File} file - reference to file that failed to attach
       */
      function onAttachmentError(file) {
        vm.attachmentNumber += 1;
        toastManager.showErrorToast(
          $translate.instant('widgets.support.createCaseModal.creating.toast.error', {
            filename: file.name,
          })
        );
      }
    }
  }
})();
