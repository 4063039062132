import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';

import CardListContentModel from './CardListContentModel';
/**
 * Create a localized copy of RichTextJSON that will be used by the Document Cloud help topic card list content model.
 */
const LocalizeDocumentCloudRichTextJson = (intl, locale) => {
  const shortLocale = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale];
  return {
    helpTopicCards: [
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_DC_deploy_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.0.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_DC_deploy_dash_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.1.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.linksDescription',
                  }),
                },
                {
                  content: [],
                  nodeType: 'text',
                },
                {
                  data: {
                    href: `https://www.adobe.com/go/DC_deploy_Win_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.link1.text',
                  }),
                },
                {
                  nodeType: 'text',
                  value: ' | ',
                },
                {
                  data: {
                    href: `https://www.adobe.com/go/DC_deploy_Mac_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.2.richTextJson.link2.text',
                  }),
                },
                {
                  nodeType: 'text',
                  value: ')',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_dc_feature_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.3.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
      {
        richTextJson: {
          json: [
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.heading',
                  }),
                },
              ],
              nodeType: 'header',
              style: 'h3',
            },
            {
              content: [
                {
                  nodeType: 'text',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.description',
                  }),
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  data: {
                    href: `https://www.adobe.com/go/ac_dc_config_service_${shortLocale}`,
                    target: '_blank',
                    title: 'tooltip',
                  },
                  nodeType: 'link',
                  value: intl.formatMessage({
                    id: 'documentCloudHelpTopicListContentModel.helpTopicCards.4.richTextJson.link.text',
                  }),
                },
              ],
              nodeType: 'paragraph',
            },
            {
              content: [
                {
                  content: [],
                  nodeType: 'line-break',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
        },
      },
    ],
  };
};

/**
 * Definition of the DocumentCloudHelpTopics content model.  Gets the strings themselves from en.json, but enables us to
 * use MultiTextJson components for the cards.
 */
const DocumentCloud = (intl, locale) =>
  CardListContentModel(LocalizeDocumentCloudRichTextJson(intl, locale));

export default DocumentCloud;
