import {View} from '@adobe/react-spectrum';
import {TrialBadge} from '@pandora/react-badges';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const TrialOfferBadge = ({days, className}) => {
  const intl = useIntl();
  return (
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to add width
    <View data-testid="trial-badge" UNSAFE_className={className}>
      <ContentEntryProvider
        value={{
          label: intl.formatMessage(
            {
              id: 'products.productProfileDetails.infobar.summaryItem.label.trialProuct',
            },
            {days}
          ),
        }}
      >
        <TrialBadge
          data={{value: 1}} // value is needed in order to use TrialBadge. The actual label is passed to ContentEntryProvider.
        />
      </ContentEntryProvider>
    </View>
  );
};

TrialOfferBadge.displayName = 'TrialOfferBadge';

TrialOfferBadge.propTypes = {
  className: PropTypes.string,
  days: PropTypes.number,
};

export default TrialOfferBadge;
