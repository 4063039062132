import {AuthenticatedUser, Locale, log} from '@admin-tribe/binky';
import {showError, showSuccess} from '@admin-tribe/binky-ui';
import {View} from '@adobe/react-spectrum';
import {withRuntimeContainer} from '@pandora/mini-app-runtime-container-react';
import {useAuth} from '@pandora/react-auth-provider';
import {useEnv} from '@pandora/react-env-provider';
import MiniAppComponent, {getInitialProps} from '@pandora/react-mini-app-contract-admin-table';
import React from 'react';
import {useParams} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canAssignAdmin, canRemoveAdmin} from 'core/admin/access/adminAccess';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {canViewUserDetails} from 'core/user/access/userAccess';

const MiniAppContractAdminTableWithRC = withRuntimeContainer({
  default: MiniAppComponent,
  getInitialProps,
});

const ContractAdminsTable = () => {
  const {contractId} = useParams();

  const auth = useAuth();
  const env = useEnv();

  const accessToken = auth.getAccessToken();
  const orgId = rootStore.organizationStore.activeOrgId;
  const locale = Locale.get().activeLanguage;
  const currentUserRoles = AuthenticatedUser.get().getRoles();
  const isContractAdmin = currentUserRoles.isAdminForContract(orgId, contractId);

  const canAddAdmins = canAssignAdmin() || isContractAdmin;
  const canRemoveAdmins = canRemoveAdmin() || isContractAdmin;

  // Mini app common props
  const commonProps = {
    env,
    lang: locale,
    locale,
    logger: log,
    user: {
      accessToken,
      isAuthenticated: !!accessToken,
      profile: {
        userId: auth?.getUserProfile()?.userId || '',
      },
    },
  };

  // Mini app params
  const miniAppParams = {
    canAddAdmins,
    canRemoveAdmins,
    canViewAdminDetails: canViewUserDetails(),
    contractId,
    isAuthenticatedUserOrgAdmin: isOrgAdmin(),
    lang: locale,
    onError: showError,
    onSuccess: showSuccess,
    orgId,
  };

  return (
    <View>
      <MiniAppContractAdminTableWithRC commonProps={commonProps} params={miniAppParams} />
    </View>
  );
};

export default ContractAdminsTable;
