(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc service
   * @name app.widgets.hva.hva-card.assembler:HvaAssember
   * @description factory for assembling a HVA (High Value Action) card
   */
  angular.module('app.widgets.hva.hva-card.assembler').factory('hvaAssembler', hvaAssembler);

  /* @ngInject */
  function hvaAssembler($q, _, HvaBuildActionList, HvaCardList) {
    const service = {
      buildActionsForHVA: buildDefaultActionsForHVA,
      getPrioritizedHVACardList,
    };

    return service;

    /**
     * Computes and returns a list of HVA cards for the overview page.
     * @returns {Promise<HvaCardList>} A promise that contains a list of HVA cards.
     */
    function getPrioritizedHVACardList() {
      const buildActionList = this.buildActionsForHVA();
      const sortedActions = buildActionList.getSortedBuildActions();
      return getHVACards(sortedActions).then((hvaCardList) => {
        hvaCardList.registerSavedState();
        return hvaCardList;
      });
    }

    // Private Functions

    /**
     * HVA Card is defaulted to an empty list.
     * When the function hvaAssembler.buildActionsForHVA() is called,
     * it will trigger all decorators for hvaAssembler.buildActionsForHVA()
     * and populate the HVA Build Action into the list.
     * @returns {HvaBuildActionList} a list of HVA Build Actions.
     */
    function buildDefaultActionsForHVA() {
      return new HvaBuildActionList();
    }

    /**
     * The function gets HVA Cards from a list of HVA Build Actions.
     * @param {HvaBuildAction[]} sortedActions a list of build actions
     * @param {Number} [index] The index of HvaBuildAction list
     * @param {HvaCardList} [retVal] The return values will be added into this list. It is defaulted to an empty list.
     * @param {String} [previousStackType] The type of the build action that is used to group all consecutive hva cards with the same type.
     * @param {Object} [stateCache] The cache to share data between decorators on initialization
     * @returns {Promise<HvaCardList>} returns a non-empty HVA card list for a coresponding
     * build action HvaBuildAction that has accessCallback resolved with a non-empty value.
     */
    function getHVACards(
      sortedActions,
      index = 0,
      retVal = new HvaCardList(),
      previousStackType,
      stateCache = {}
    ) {
      if (index < sortedActions.length) {
        const curHVABuildAction = sortedActions[index];

        // It's stackable when
        // 1. there is no return value found,
        // 2. OR the stack type of the current action and the previous action are matching.
        const isStackable =
          retVal.isEmpty() || _.isEqual(curHVABuildAction.type, previousStackType);
        if (isStackable) {
          return curHVABuildAction
            .accessCallback(stateCache)
            .then((value) => {
              const foundNewHVACards = _.isArray(value) && _.size(value) > 0;
              if (foundNewHVACards) {
                const nonDismissedHvaCards = getNonDismissedHvaCards(value, retVal);

                if (!_.isEmpty(nonDismissedHvaCards)) {
                  retVal.addAll(nonDismissedHvaCards);
                  retVal.setType(curHVABuildAction.type);

                  if (curHVABuildAction.isStackable) {
                    return getHVACards(
                      sortedActions,
                      index + 1,
                      retVal,
                      curHVABuildAction.type,
                      stateCache
                    );
                  }

                  return $q.resolve(retVal);
                }
              }
              return $q.reject();
            })
            .catch(() =>
              getHVACards(sortedActions, index + 1, retVal, previousStackType, stateCache)
            );
        }
      }

      return $q.resolve(retVal);
    }

    function getNonDismissedHvaCards(newHvaCards = [], hvaCardList) {
      return _.filter(newHvaCards, isNonDismissedCard);

      function isNonDismissedCard(hvaCard) {
        return !hvaCardList.isCardDismissed(hvaCard);
      }
    }
  }
})();
