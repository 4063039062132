(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc component
   * @name app.widgets.domains2:domainsRequestAccess
   *
   * @description renders a table of domains which this org can request access to, via directory
   *   trusting. Domains can removed from the list via a "Remove" link in each row, until the list
   *   is down to one item.
   *
   * @binding {Array} accessRequests - array of objects each containing two properties:
   *   domainName: the domain which this organization wants to access
   *   directoryId: the directory to which the domain is linked and to which access will be
   *     requrested
   * @binding {Function} onRemoveAccessRequest - called when an access request is removed from the
   *   list, with the removed item as a parameter
   */
  angular.module('app.widgets.domains2').component('appDomainsRequestAccess', {
    bindings: {
      accessRequests: '<',
      onRemoveAccessRequest: '&',
    },
    controller,
    templateUrl:
      'app/widgets/domains2/add-domains-modal/domains-request-access/domains-request-access.component.html',
  });

  function controller(_) {
    const vm = this;

    _.assign(vm, {
      $onChanges,
      onRemove,
    });

    /////////////////

    function $onChanges(changes) {
      if (changes.accessRequests) {
        setAccessRequests(changes.accessRequests.currentValue);
      }
    }

    function onRemove(accessRequest) {
      const newAccessRequests = _.reject(vm.accessRequests, accessRequest);
      setAccessRequests(newAccessRequests);
      vm.onRemoveAccessRequest({$event: {accessRequest}});
    }

    /////////////////

    function setAccessRequests(accessRequests) {
      _.assign(vm, {
        accessRequests,
        directoryCount: _.uniqBy(accessRequests, 'directoryId').length,
        domainCount: _.uniqBy(accessRequests, 'domainName').length,
      });
    }
  }
})();
