(function () {
  /**
   * @ngdoc module
   * @name app.core.content-platform
   * @description Module which defines content platform related models
   */
  angular.module('app.core.content-platform', [
    'app.core.api.content-platform',
    'binky.core.lodash',
  ]);
})();
