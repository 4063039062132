import {
  CONTRACT_BUYING_PROGRAM,
  OFFER_LIST_INTENT,
  OFFER_LIST_MODE,
  OFFER_LIST_SERVICE_PROVIDER,
  OfferList,
  PRICE_POINT,
  PoresOfferList,
  feature,
  getEnterpriseContract,
  getIndirectContract,
} from '@admin-tribe/binky';
import groupBy from 'lodash/groupBy';

import rootStore from 'core/RootStore';
import {canViewFreeOffers} from 'core/organizations/access/organizationAccess';
import {activeOrgHasESM} from 'core/storage/access/storageAccess';
import FreeOfferModal from 'features/offers/FreeOfferModal';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * @description Get contract ID
 *
 * @returns {String} the contract ID
 */
function getContractId() {
  const contractList = rootStore.organizationStore.contractList;

  const contract =
    getIndirectContract(contractList) ||
    getEnterpriseContract(contractList, CONTRACT_BUYING_PROGRAM.ETLA);
  return contract?.id;
}

/**
 * This class creates a HVA card allowing the user to accept free offers
 */
class AcceptFreeOffersHva extends HvaCard {
  /**
   * @description Method to fetch AcceptFreeOffersHva
   *
   * @param {Object} intl - react-intl
   *
   * @returns {Promise<AcceptFreeOffersHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (!canViewFreeOffers()) {
      return Promise.reject();
    }
    let freeOfferList, orgHasESM;

    if (feature.isDisabled('temp_ctir_21074_oms_removal')) {
      [freeOfferList, orgHasESM] = await Promise.all([
        OfferList.get({
          contractId: getContractId(),
          orgId: rootStore.organizationStore.activeOrgId,
          queryParams: {
            mode: OFFER_LIST_MODE.PURCHASE,
            price_point: PRICE_POINT.FREE,
          },
        }),
        activeOrgHasESM(),
      ]);
    } else {
      [freeOfferList, orgHasESM] = await Promise.all([
        PoresOfferList.get({
          contractId: getContractId(),
          orgId: rootStore.organizationStore.activeOrgId,
          queryParams: {
            intent: OFFER_LIST_INTENT.FREE_OFFER_DISCOVERY,
            price_point: PRICE_POINT.FREE,
            service_providers: [
              OFFER_LIST_SERVICE_PROVIDER.MERCHANDISING,
              OFFER_LIST_SERVICE_PROVIDER.PRODUCT_ARRANGEMENT,
            ],
          },
        }),
        activeOrgHasESM(),
      ]);
    }
    freeOfferList.filterOnQualifyingFreeOffers({
      organization: rootStore.organizationStore.activeOrg,
      orgHasESM,
      ownedProducts: rootStore.organizationStore.productList,
    });

    if (freeOfferList.items.length === 0) {
      return Promise.reject();
    }
    freeOfferList.items = freeOfferList.items.filter((item) => !!item.product_arrangement.family);
    const freeOffersByFamily = groupBy(freeOfferList.items, 'product_arrangement.family');
    const hvaCards = Object.keys(freeOffersByFamily).map(
      (productFamily) =>
        new AcceptFreeOffersHva({
          intl,
          offer: freeOffersByFamily[productFamily][0],
          offerList: freeOfferList,
        })
    );
    return hvaCards.length === 0 ? Promise.reject() : hvaCards;
  }

  /**
   * @description Method to create an AcceptFreeOffersHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl
   *
   * @param {Offer} obj.offer - first free offer in each product family
   *
   * @param {OfferList} obj.offerList - OfferList model object
   *
   * @returns {AcceptFreeOffersHva} the fetched instance
   */
  constructor({intl, offer, offerList}) {
    const offerName = offer?.merchandising?.copy?.name;
    const id = `hva-free-offer-${offer.product_arrangement.code}`;
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.acceptFreeOffers';
    const modalConfig = {
      closeProp: 'onClosed',
      Component: FreeOfferModal,
      props: {color: 'informative', includeIsOpen: true, offerList, preferredOffer: offer},
    };
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: offer?.merchandising?.copy?.short_description,
      dismissOnCTA: true,
      header: offerName,
      icon: {
        src: offer?.merchandising?.assets?.icons?.svg,
        style: {width: '48px'},
      },
      id,
      isDismissible: true,
      modalConfig,
      priorityOrder: HVA_ORDER.ACCEPT_FREE_OFFERS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default AcceptFreeOffersHva;
