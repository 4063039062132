import {EVENT_ACTION, dispatchUiEventAnalytics, log} from '@admin-tribe/binky';
import {CarouselArrowClickType} from '@pandora/react-carousel';

import {getOrgInfoWithSubscriptionStatusForAnalytics} from '../../../common/utils/analyticsUtils';

import {MANAGE_PLAN_ACTIONS, SELF_CANCEL_STEPS} from './SelfCancelConstants';
import {
  getCancellationItems,
  getCurrency,
  getOrderNumber,
  getRetentionContractItems,
  getRetentionDetails,
  getRetentionItemById,
} from './SelfCancelUtils';

// Map a Carousel UI Event type to a label used in the Analytics Event Name
const CAROUSEL_TYPE_TO_EVENT_NAME = {
  [CarouselArrowClickType.LEFT_BUTTON_CLICKED]: 'Previous',
  [CarouselArrowClickType.RIGHT_BUTTON_CLICKED]: 'Next',
};

// map a step in the renewal flow to a event name section for carousel arrow clicks
const STEP_TO_CAROUSEL_NAV_EVENT_NAME = {
  [SELF_CANCEL_STEPS.LOSS_AVERSION_STEP]: 'cancelFlowDetailsCard',
  [SELF_CANCEL_STEPS.SAVE_OFFERS_STEP]: 'cancelFlowOffersCard',
};

const MANAGE_PLAN_DROPDOWN_OPEN = 'openDropdown';
const MANAGE_PLAN_IMPRESSION_ID = '|adminConsole|add-cancelFlow';
const SELF_CANCEL_IMPRESSION_ID = '|adminConsole|cancelFlow';

const EVENT_NAME = {
  BANNER_GO_BACK: 'EntryExclusionBannerGoBack',
  CANCEL: 'Cancel',
  CANCEL_REVIEW: 'CancelReview',
  CANCELLATION_REASONS: 'FeedBack',
  CLOSE: 'Close',
  CONFIRM: 'Confirm',
  CONTINUE: 'Continue',
  DISCOUNT_OFFER_DONE: 'DiscountOfferDone',
  DISCOUNT_OFFER_REVIEW: 'DiscountOfferReview',
  DONE: 'Done',
  EXIT_PAGE_DONE: 'ExitPageDone',
  FREE_OFFER_DONE: 'FreeOfferDone',
  FULL_CANCEL_DONE: 'FullCancelDone',
  LOSS_AVERSION: 'Details',
  MANAGE: 'FullCancelManage',
  NO_THANKS: 'NoThanks',
  OFFERS: 'Offers',
  PARTIAL_CANCEL_DONE: 'PartialCancelDone',
  PREVIOUS: 'Previous',
  PURCHASE: 'FullCancelPurchase',
  SELECT_CANCEL_LICENSES: 'CancelLicencesModal',
  START_CHAT: 'EntryExclusionBannerStartChat',
};

const FLOW_NAME = 'cancelFlow';

const CLICKTYPE_TO_NAVSTRING = {
  [EVENT_NAME.CANCEL]: 'Cancel',
  [EVENT_NAME.CLOSE]: 'Close',
  [EVENT_NAME.CONFIRM]: 'Confirm',
  [EVENT_NAME.CONTINUE]: 'Continue',
  [EVENT_NAME.DONE]: 'Done',
  [EVENT_NAME.NO_THANKS]: 'NoThanks',
  [EVENT_NAME.PREVIOUS]: 'Previous',
};

const STEP_TO_STATE_STRING = {
  [SELF_CANCEL_STEPS.SELECT_LICENSES_STEP]: EVENT_NAME.SELECT_CANCEL_LICENSES,
  [SELF_CANCEL_STEPS.CANCELLATION_REASONS_STEP]: EVENT_NAME.CANCELLATION_REASONS,
  [SELF_CANCEL_STEPS.LOSS_AVERSION_STEP]: EVENT_NAME.LOSS_AVERSION,
  [SELF_CANCEL_STEPS.SAVE_OFFERS_STEP]: EVENT_NAME.OFFERS,
  // these are build on assumptions need to confirm with Bhim as he did not define this flow
  [SELF_CANCEL_STEPS.SAVE_OFFER_REVIEW_STEP]: EVENT_NAME.DISCOUNT_OFFER_REVIEW,
  [SELF_CANCEL_STEPS.CANCEL_REVIEW_STEP]: EVENT_NAME.CANCEL_REVIEW,
  [SELF_CANCEL_STEPS.DONE_FULL_CANCEL_STEP]: EVENT_NAME.FULL_CANCEL_DONE,
  [SELF_CANCEL_STEPS.DONE_PARTIAL_CANCEL_STEP]: EVENT_NAME.PARTIAL_CANCEL_DONE,
  [SELF_CANCEL_STEPS.DONE_DISCOUNT_OFFER_STEP]: EVENT_NAME.DISCOUNT_OFFER_DONE,
  [SELF_CANCEL_STEPS.DONE_FREE_MONTHS_OFFER_STEP]: EVENT_NAME.FREE_OFFER_DONE,
  [SELF_CANCEL_STEPS.EXIT_STEP]: EVENT_NAME.EXIT_PAGE_DONE,
  [SELF_CANCEL_STEPS.BUMPER_STEP]: EVENT_NAME.BANNER_GO_BACK,
};

/**
 *
 * @description build a string based on the navigation element and a series of customizable options
 * @param {Object} options list of options to customize the navigation string
 * @param {String} options.clickType the type of click event that initalised the string build e.g. CONTINUE or PREVIOUS
 * @param {Boolean} [options.noEnd] do not append an action to the end of the string
 * @param {String} options.stateString a string representing the modal state
 * @returns {String} representing a navigation in eventName format for use in Analytics
 */
function buildNavString({clickType, noEnd, stateString}) {
  let navString = CLICKTYPE_TO_NAVSTRING[clickType];

  if (clickType === EVENT_NAME.DONE || noEnd) {
    navString = `${FLOW_NAME}${stateString}`;
  } else if (navString) {
    navString = `${FLOW_NAME}${stateString}${navString}`;
  } else {
    // this is for coverage
    return undefined;
  }

  return navString;
}

/**
 * Dispatch an analytics UI event with the expected impressions data when a user interacts with the Manage Plan dropdown.
 * @param {String} action - The action (from MANAGE_PLAN_ACTIONS) that the user has clicked
 * @returns {Object} - An object representing the analytics properties that should be dispatched.
 */
const dispatchManagePlanButtonAnalytics = ({action, product = null}) => {
  let click, eventName;
  switch (action) {
    case MANAGE_PLAN_ACTIONS.ADD_LICENSES:
      click = `managePlanDropDown-add${MANAGE_PLAN_IMPRESSION_ID}`;
      eventName = 'managePlanDropDownAddProducts';
      break;
    case MANAGE_PLAN_ACTIONS.CANCEL_LICENSES:
      click = `managePlanDropDown-cancel${MANAGE_PLAN_IMPRESSION_ID}`;
      eventName = 'managePlanDropDownCancelProducts';
      break;
    case MANAGE_PLAN_ACTIONS.CANCEL_TRIAL:
      click = `managePlanDropDown-trial${MANAGE_PLAN_IMPRESSION_ID}`;
      eventName = 'managePlanDropDownCancelTrialProducts';
      break;
    case MANAGE_PLAN_DROPDOWN_OPEN:
      click = `managePlanButton${MANAGE_PLAN_IMPRESSION_ID}`;
      eventName = 'managePlanButton';
      break;
    default:
      return;
  }

  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.CLICK,
    eventName,
    interaction: {click},
    ...(product
      ? {
          organizationInfo: getOrgInfoWithSubscriptionStatusForAnalytics(
            product.offerId,
            product.offerType
          ),
        }
      : {}),
  });
};

/**
 * @description generate the event name for click interactions
 * @param {String} clickType represents the button that was clicked
 * @param {String} currentStep represents the state step the modal is in
 * @returns {String} eventName that is accepted by adobe analytics
 */
function generateClickEventName(clickType, currentStep) {
  let eventName;

  if (!clickType || !currentStep) {
    log.error(
      'Click Type or State String undefined: Unable to build event name for navigational click analytics event',
      currentStep,
      clickType
    );
    return undefined;
  }

  const stateString = STEP_TO_STATE_STRING[currentStep];

  if (currentStep === SELF_CANCEL_STEPS.BUMPER_STEP && clickType !== EVENT_NAME.DONE) {
    eventName = `${FLOW_NAME}${EVENT_NAME.START_CHAT}`;
  } else if (stateString) {
    eventName = buildNavString({
      clickType,
      noEnd: currentStep === SELF_CANCEL_STEPS.SELECT_LICENSES_STEP,
      stateString,
    });
  } else {
    log.error(
      'State not suported: Unable to build navigational click analytics event name',
      currentStep,
      clickType
    );
  }

  return eventName;
}

/**
 *
 * @param {Object} options - Parameter object passed to function
 * @param {Array<Object>} options.cards - A collection of cards to enumerate for impression data
 * @returns {String} The resulting impression string for the cards and step provided.
 */
const getCardImpressions = ({cards}) => {
  const impressions = cards.reduce((memo, card, index) => {
    // when a card impression is built from a click interaction, use the card's index property rather than reduce index.
    let cardImpressions = Number.isInteger(card?.index)
      ? `card-${card.index + 1}`
      : `card-${index + 1}`;

    if (card?.type) {
      cardImpressions = `${cardImpressions}|${card.type}`;
    }

    if (card?.retentionId) {
      cardImpressions = `${cardImpressions}|${card.retentionId}`;
    }

    return `${memo}${index > 0 ? ',' : ''}${cardImpressions}${SELF_CANCEL_IMPRESSION_ID}`;
  }, '');

  return impressions;
};

/**
 * Convert a ProductList into an analytics collection that can be interpreted by the ProductListDescriptor.
 * This collection should only return those properties necessary for products in the Self Cancel flow.
 * @param {Object} object - The parameter object
 * @param {Object} object.productList - The ProductList class to map to the data structure expected by ProductListDescriptor.
 * @returns {Object} object - The analytics data context returned by this function.
 *          {Object} object.productList - A representation of the ProductList that can be interpreted by the ProductListDescriptor
 *          {Array<Object>} object.productList.items - The collection of products
 *          {String} object.productList.items[].id - The Product ID
 *          {Number} object.productList.items[].licenseCount - The total number of assignable licenses for this product
 *          {Number} object.productList.items[].numberSelected - The total number of assigned licenses for this product
 *          {Number} object.productList.items[].numberUnassigned - The total number of unassigned licenses for this product
 *          {Number} object.productList.items[].offer_id - The Offer ID for this Product
 */
const mapProductListToAnalytics = ({productList}) => {
  if (!productList?.items?.length > 0) {
    return {};
  }

  return {
    productList: {
      items: productList.items.map((product) => ({
        id: product.id,
        licenseCount: product.getSeatBasedAssignableLicenseCount(),
        numberSelected: product.delegatedQuantity,
        numberUnassigned: product.getAvailableLicenseCount(),
        offer_id: product.offerId,
      })),
    },
  };
};

/**
 * @description A helper function to map properties from the Self Cancel modal state to
 * a structure suitable for interpretation by analytics descriptors and translators.
 * @param {Object} options
 * @param {String} [options.comment] - The user-provided cancellation reason text.
 * @param {Object} options.productsChange - The product change object
 * @param {Object} options.retentionId - The retention ID
 * @param {Array<String>} [options.selectedReasons] - The selected cancellation reason keys.
 * @returns {Object} object - An object containing properties that should be dispatched with analytics events
 *          {Object} object.cancellationOrder - Properties for the CancellationOrderDescriptor
 *          {Object} object.productList - Properties for the ProductListDescriptor
 */
const mapSelfCancelStateToAnalytics = ({comment, productsChange, retentionId, selectedReasons}) => {
  const response = {};
  if (!productsChange?.response) {
    return response;
  }

  let retention;
  let productsChangedItems = [];
  if (retentionId) {
    retention = getRetentionItemById(productsChange, retentionId);
    productsChangedItems = getRetentionContractItems(retention);
  } else {
    productsChangedItems = getCancellationItems(productsChange);
  }

  const items = productsChangedItems?.map((item) => {
    // eslint-disable-next-line no-unsafe-optional-chaining -- @rmckenzi to fix
    const cancelQuantity = item?.totalQuantity - item?.newQuantity;
    return {
      cancelQuantity: Number.isNaN(cancelQuantity) ? undefined : cancelQuantity,
      licenseCount: item.totalQuantity,
      offer_id: item.offerId,
      pricing: {
        currency: {
          code: getCurrency(productsChange)?.iso3code,
        },
        prices: [
          {
            price_details: {
              unit: {
                amount_with_tax: item.newPrices?.unitPriceWithTax,
                amount_without_tax: item.newPrices?.unitPriceWithoutTax,
              },
              unit_without_discount: {
                amount_with_tax: item.existingPrices?.unitPriceWithTax,
              },
            },
          },
        ],
      },
    };
  });

  response.cancellationOrder = {
    orderNumber: getOrderNumber(productsChange),
    reasonCodes: selectedReasons,
    userReason: comment,
  };

  if (retention) {
    const retentionDetails = getRetentionDetails(retention);
    const {nextBilling, discount} = retentionDetails?.contractData || {};
    const promotion = retention.promotion;

    const outcomes = promotion?.outcomes?.map(({discounts, duration, type}) => ({
      discountAmount: discounts?.[0]?.amount,
      discountCurrency: discounts?.[0]?.currency,
      durationAmount: duration?.amount,
      durationType: duration?.type,
      durationUnit: duration?.unit,
      type,
    }));

    response.cancellationOrder.retention = {
      billingTotalTax: nextBilling?.totalTax,
      billingTotalWithoutTax: nextBilling?.totalWithoutTax,
      billingTotalWithTax: nextBilling?.totalWithTax,
      discountTotalTax: discount?.totalTax,
      discountTotalWithoutTax: discount?.totalWithoutTax,
      discountTotalWithTax: discount?.totalWithTax,
      offerId: retention.id,
      orderNumber: retentionDetails?.orderNumber,
      outcomes,
      promoId: promotion?.id,
    };
  }

  if (items) {
    response.productList = {items};
  }

  return response;
};

/**
 * @description A helper function to map the selected cancellation reasons from the Self Cancel modal to
 * the string with their key and positional index suitable for analytics.
 * @param {Array<String>} [selectedReasons] - The selected cancellation reason keys.
 * @param {Array<Object>} [reasons] - The list of cancellation reasons.
 * @returns {String} Comma separated list of selected reasons, each containing key and positional index
 */
const mapSelectedReasonsToAnalyticsImpression = (selectedReasons, reasons) =>
  selectedReasons
    .map((reasonKey) => `${reasonKey}|${reasons.findIndex((r) => r.key === reasonKey) + 1}`)
    .join(',');

/**
 * @description A helper function to map the list of all cancellation reasons from the Self Cancel modal to
 * the string with their key and positional index suitable for analytics.
 * @param {Array<Object>} [reasons] - The list of all cancellation reasons.
 * @returns {String} Comma separated list of all cancellation reasons, each containing key and positional index
 */
const mapReasonsToAnalyticsImpression = (reasons) =>
  reasons.map((reason, idx) => `${reason.key}|${idx + 1}`).join(',');

export {
  CAROUSEL_TYPE_TO_EVENT_NAME,
  EVENT_NAME,
  FLOW_NAME,
  MANAGE_PLAN_DROPDOWN_OPEN,
  STEP_TO_CAROUSEL_NAV_EVENT_NAME,
  dispatchManagePlanButtonAnalytics,
  generateClickEventName,
  getCardImpressions,
  mapProductListToAnalytics,
  mapSelectedReasonsToAnalyticsImpression,
  mapReasonsToAnalyticsImpression,
  mapSelfCancelStateToAnalytics,
};
