import {useEffect, useState} from 'react';

import {canViewServers, canViewTemplates} from 'core/packages/access/packagesAccess';
/** Provides essential data for viewing Packages tab pages */
const usePackagesAccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [canViewTemplatesTab, setCanViewTemplatesTab] = useState(false);
  const [canViewServersTab, setCanViewServersTab] = useState(false);

  /** Fetches essential data for viewing Templates */
  useEffect(() => {
    (async () => {
      setCanViewTemplatesTab(await canViewTemplates());
      setCanViewServersTab(await canViewServers());
      setIsLoading(false);
    })();
  }, []);

  return {
    canViewServersTab,
    canViewTemplatesTab,
    isLoading,
  };
};

export default usePackagesAccess;
