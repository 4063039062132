import {
  AuthenticatedUser,
  CONTRACT_DX_CORE_SERVICES_DEAL_ID,
  CONTRACT_LEGACY_DX_ID,
  feature,
} from '@admin-tribe/binky';

import rootStore from 'core/RootStore';
import {isContractAdmin, isOrgAdmin} from 'core/organizations/access/organizationAccess';

/**
 * @description Access check for whether or not current user can change the contract owner
 *
 * @param {Contract} contract the contract in which the contract owner could be changed
 * @param {OrganizationAdminList} adminList AdminList
 *
 * @returns {Boolean} A boolean determining whether or not whether the current user has the
 * ability to change the contract owner
 */
function canChangeContractOwner(contract, adminList) {
  return isContractEligibleForNewOwner(contract) && adminList.items.length > 1;
}

function isContractEligibleForNewOwner(contract) {
  return (
    contract.isContractOwner({checkAuthUser: true}) &&
    !contract.changeOwnerInvitation &&
    !contract.isPaymentCategoryPaypal() &&
    !contract.isOrderProcessing() &&
    !contract.isDrContract &&
    (contract.isDirectContract() || contract.isIndirectContract())
  );
}

/**
 * Checks if the authenticated user is an admin for the specified contract.
 * @param {Object} contract - The contract object.
 * @returns {boolean} - True if the authenticated user is an admin for the contract, false otherwise.
 */
function isAuthUserContractAdmin(contractId) {
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUser = AuthenticatedUser.get();
  const roles = currentUser.getRoles();

  return roles.isAdminForContract(orgId, contractId);
}

/**
 * Checks if a contract is eligible for contract admin.
 * @param {Object} contract - The contract object to check.
 * @returns {boolean} - Returns true if the contract is eligible for contract admin, false otherwise.
 */
function isContractEligibleForContractAdmin(contract) {
  return !!contract?.adminGroupId;
}

/**
 * @description Check whether a contract can be shown to users.
 * @param {Contract} contract Contract to check
 * @returns {Boolean} Return true if the contract can be displayed.
 * Otherwise, return falsy.
 */
function canDisplayContract(contract = {}) {
  return (
    contract.id &&
    contract.id !== CONTRACT_LEGACY_DX_ID &&
    (feature.isEnabled('temp_contract_admin_pa_4759')
      ? isOrgAdmin() || isAuthUserContractAdmin(contract.id)
      : !contract.isValidDealId() ||
        contract.accountInfo.dealId !== CONTRACT_DX_CORE_SERVICES_DEAL_ID)
  );
}

/**
 * @description Access check for whether or not current user can edit the contract payment
 * @param {Contract} contract the contract in which the payment could be edited.
 *
 * @returns {Boolean} True if payment can be edited, false otherwise.
 */
function canEditPayment(contract) {
  return !!(
    !contract.isDrContract &&
    contract.isDirectContract() &&
    // Set checkAuthUser: false to force direct ID comparison because
    // add/renew modals don't support T2E-to-T1 ID switch
    contract.isContractOwner({
      checkAuthUser: false,
      userId: feature.isEnabled('temp_t2e_contract_owners')
        ? AuthenticatedUser.get().getAuthenticatingAccountId()
        : AuthenticatedUser.get().getId(),
    }) &&
    !contract.isOrderProcessing()
  );
}

/**
 * @description Access check for Overview Anniversary Date
 * @param {Contract} contract the contract used to determine if this feature is shown.
 *
 * @returns {Boolean} True if anniversary date should be shown, false otherwise.
 */
function canViewAnniversaryDate(contract) {
  return !!(
    contract &&
    contract.getAnniversaryDate() &&
    !contract.isVIPMPContract() &&
    !contract.isModelTrial()
  );
}

/**
 * @description Access check for Overview Pending Anniversary Date
 * For indirect org, if the anniversary date is not set by the Reseller, it will be set when the first order is placed.
 * Before the anniversary date is set, we would like to show the anniversary date pending string to the customer.
 * @param {Contract} contract the contract used to determine if this feature is shown.
 *
 * @returns {Boolean} True if pending anniversary date should be shown, false otherwise.
 */
function canViewAnniversaryDatePending(contract) {
  return !!(contract && !contract.getAnniversaryDate() && contract.isIndirectButNotVIPMPContract());
}

/**
 * @description Check whether the user can see additional local currency change contract information
 * @param {Contract} contract current contract to show additional info for
 *
 * @returns {Boolean} returns true if the user can see the additional change contract owner info, else resolves to false
 */
function canViewLocalCurrencyChangeInfo(contract) {
  return !!(
    contract.isInRenewalWindow() &&
    canEditPayment(contract) &&
    contract?.billingInfo?.nextBillingAmount?.currency?.futureCurrency
  );
}

/**
 * @description Check whether the user can see additional change contract owner information
 * @param {Contract} contract current contract to show additional info for
 * @param {OrganizationAdminList} adminList a paginated list of admins
 *
 * @returns {Boolean} true if the user can see the additional change contract owner info
 */
function canViewChangeContractOwnerInfo(contract, adminList) {
  return !!(isContractEligibleForNewOwner(contract) && adminList.items.length === 1);
}

/**
 * @description Access check for contract-info Products and Services section
 * @param {Contract} contract the contract used to determine if this feature is shown.
 * @param {Product[]} productsAndLicenses list of products and licenses associated with the account
 *
 * @returns {Boolean} True if Products and Services section should be shown, false otherwise.
 */
function canViewProductAndServices(contract, productsAndLicenses) {
  return !!(
    (contract.isIndirectContract() || contract.isRenewableDirectContract()) &&
    !contract.isEnterpriseContract() &&
    productsAndLicenses.length > 0
  );
}

/**
 * @description Access check for whether renewal notifications should be shown
 *
 * @returns {Boolean} True if Renewal notifications should be shown to this user.
 */
function canViewRenewalNotifications() {
  // This may be extended in the future to allow other admin roles (ie, Contract Admin)
  return isOrgAdmin() || isContractAdmin();
}

/**
 * @description Check whether the user is both: (1) the contract owner, and
 * (2) the user is not logged in as the authenticating user.
 *
 * @param {Object} options input options
 * @param {Contract} options.contract the contract to check from
 *
 * @returns {Boolean} true the current user is the contract owner, and
 * the user is not logged in as the authenticating user.
 * false when (1) the user is not the contract owner, or (2) the user
 * is the contract owner and is already logged in as the authenticating
 * user.
 */
function needToSwitchAsAuthenticatingUser({contract} = {}) {
  return !!(
    contract?.isContractOwner?.({checkAuthUser: true}) &&
    contract?.getOwnerUserId?.() !== AuthenticatedUser.get().getId()
  );
}

export {
  canChangeContractOwner,
  canDisplayContract,
  canEditPayment,
  canViewAnniversaryDate,
  canViewAnniversaryDatePending,
  canViewChangeContractOwnerInfo,
  canViewLocalCurrencyChangeInfo,
  canViewProductAndServices,
  canViewRenewalNotifications,
  isAuthUserContractAdmin,
  isContractEligibleForContractAdmin,
  needToSwitchAsAuthenticatingUser,
};
