import {Device} from '@pandora/react-data-model-device';
import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';

const DeviceDetailsContext = createContext({});

const useDeviceDetailsContext = () => useContext(DeviceDetailsContext);

/**
 * A component to provide context variables to its children component
 */

const DeviceDetailsContextProvider = ({children, value}) => (
  <DeviceDetailsContext.Provider value={value}>{children}</DeviceDetailsContext.Provider>
);

DeviceDetailsContextProvider.propTypes = {
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,

  /**
   * Values passed to all of its children
   */
  value: PropTypes.PropTypes.shape({
    closeDeviceDetailsDrawer: PropTypes.func,
    deviceDetails: PropTypes.instanceOf(Device),
    licenseId: PropTypes.string,
    onDeviceUpdated: PropTypes.func,
    onDialogUpdated: PropTypes.func,
    showIPAddress: PropTypes.bool,
    totalLicensesCount: PropTypes.number,
    usedLicensesCount: PropTypes.number,
  }).isRequired,
};
export {DeviceDetailsContextProvider, useDeviceDetailsContext};
