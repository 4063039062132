import {showInfo} from '@admin-tribe/binky-ui';
import {Content, ContextualHelp, Heading, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

/**
 * For directory users, shown next to the remove button if more than 'maxUsers' users are selected.
 */
const DirectoryUsersContextualHelp = ({maxUsers}) => {
  const intl = useIntl();
  const message = intl.formatMessage(
    {id: 'common.removeMemberButton.directoryUsers.contextualHelp.content'},
    {count: maxUsers}
  );

  /**
   * Pop the toast once when this component is rendered.
   * It will be rendered everytime the selectedCount goes from <= maxUsers to > maxUsers.
   */
  useEffect(() => {
    showInfo(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on init
  }, []);

  return (
    <ContextualHelp alignSelf="center" variant="info">
      <Heading>
        {intl.formatMessage({
          id: 'common.removeMemberButton.directoryUsers.contextualHelp.heading',
        })}
      </Heading>
      <Content>
        <Text>{message}</Text>
      </Content>
    </ContextualHelp>
  );
};

DirectoryUsersContextualHelp.propTypes = {
  /**
   * The maximum number of users that can be removed synchronously via selection.
   * This number will be used in the ContextualHelp message that tells the admin
   * to use `Remove users by CSV` for anything more than maxUsers.
   */
  maxUsers: PropTypes.number.isRequired,
};

export default DirectoryUsersContextualHelp;
