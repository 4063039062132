import {useRadioGroup} from '@react-aria/radio';
import {useRadioGroupState} from '@react-stately/radio';
import PropTypes from 'prop-types';
import React from 'react';

import ProductRadioContext from './ProductRadioContext';

const ProductRadioGroup = (props) => {
  const {children, label} = props;
  const radioState = useRadioGroupState(props);
  const {radioGroupProps, labelProps} = useRadioGroup(props, radioState);

  return (
    <div {...radioGroupProps} data-testid="radio-group">
      <span data-testid="product-radio-group-label" {...labelProps}>
        {label}
      </span>
      <ProductRadioContext.Provider value={radioState}>{children}</ProductRadioContext.Provider>
    </div>
  );
};

ProductRadioGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default ProductRadioGroup;
