import {feature} from '@admin-tribe/binky';
import {Flex} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {hasManageAdminsPolicy} from 'core/admin/access/adminAccess';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

import {
  AccountQuickLink,
  AddAdminsQuickLink,
  AddDevelopersQuickLink,
  AddLicensesQuickLink,
  AddUsersQuickLink,
  ConvertTrialQuickLink,
  ProductAccessRequestQuickLink,
  SavedUserIntroductionsQuickLink,
  SophiaQuickLink,
  UserIntroductionsQuickLink,
} from './quick-links';

const MAX_QUICK_LINKS = 6;

async function getQuickLinks() {
  // this order determines the priority for Quick links.
  // The first 6 will be rendered, if they are relevant.

  const links = [
    AddLicensesQuickLink,
    ConvertTrialQuickLink,
    AddUsersQuickLink,
    AddAdminsQuickLink,
    ...(feature.isEnabled('temp_can_use_introductions_quicklink')
      ? [UserIntroductionsQuickLink]
      : []),
    ...(feature.isEnabled('temp_can_use_saved_introductions_quicklink')
      ? [SavedUserIntroductionsQuickLink]
      : []),
    ProductAccessRequestQuickLink,
    SophiaQuickLink,
    AccountQuickLink,
    AddDevelopersQuickLink,
  ];

  const quickLinks = await Promise.allSettled(links.map((link) => link.get()));

  const filteredLinks = quickLinks
    // rejected links are not viewable (conditions not met)
    .filter((promise) => promise.status === 'fulfilled')
    .flatMap((promise) => promise.value);

  // debug flag to always show all visible links
  if (feature.isEnabled('show_all_quick_links')) {
    return filteredLinks;
  }

  // only render the first 3 links.
  return filteredLinks.slice(0, MAX_QUICK_LINKS);
}

function getTooltip(intl) {
  const actionNames = [];

  // check if any roles are disabled (currently only add admin)
  if (!hasManageAdminsPolicy()) {
    actionNames.push(intl.formatMessage({id: 'common.globalAdmin.disabledActions.addAdmins'}));
  }

  return actionNames.length > 0
    ? intl.formatMessage(
        {id: 'common.globalAdminPolicyBanner.description'},
        {
          actionNames,
          count: actionNames.length,
        }
      )
    : null;
}

/**
 * The Quick Links Section component for the Overview page.
 */
const QuickLinksSection = ({dividerPlacement = DividerPlacement.NONE, isHidden}) => {
  const hideContent = {...{base: false, L: false, M: false, S: false}, ...isHidden};
  const intl = useIntl();
  const headingId = useId();
  const {model: quickLinks, isLoading} = useAsyncModel({
    initState: [],
    loadFn: getQuickLinks,
  });

  if (quickLinks.length === 0) {
    return null;
  }

  const tooltip = getTooltip(intl);

  return (
    <OverviewPod data-testid="quick-links-section" isHidden={hideContent} isLoading={isLoading}>
      <TitledSection
        data-testid="quick-links-titled-section"
        dividerPlacement={dividerPlacement}
        headingLevel={2}
        id={headingId}
        title={intl.formatMessage({id: 'overview.quickLinks.pod.title'})}
        tooltipDescription={tooltip}
      >
        <Flex
          alignItems="baseline"
          data-testid="quick-links"
          gap="size-300"
          marginTop="size-150"
          wrap
        >
          {quickLinks}
        </Flex>
      </TitledSection>
    </OverviewPod>
  );
};

QuickLinksSection.propTypes = {
  /**
   * Location of the divider in the quick links section. Defaults to NONE
   * (main content appearance)
   */
  dividerPlacement: PropTypes.string,
  /**
   * Responsive props instructing quick links section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default QuickLinksSection;
