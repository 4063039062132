import {Contract} from '@admin-tribe/binky';
import {GoUrl} from '@admin-tribe/binky-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import useCancellationDetails from '../../hooks/use-cancellation-details/useCancellationDetails';
import SelfCancelCard from '../card/SelfCancelCard';

import styles from './Card.pcss';

const CancellationFeeCard = ({contract}) => {
  const intl = useIntl();
  const {cancelFeeAmt, currency, taxTerm} = useCancellationDetails({contract});
  const isTaxInclusive = true;
  return (
    <SelfCancelCard
      headerBackgroundColor="orange-600"
      headerComponent={
        <Flex alignItems="center" height="100%" justifyContent="center">
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change text color */}
          <AlertIcon alt="" size="L" UNSAFE_className={styles['cancellation-fee-card-header']} />
        </Flex>
      }
      testId="cancellation-fee-card"
      title={intl.formatMessage({
        id: 'account.selfCancel.components.lossAversionCard.cancellationFee.title',
      })}
    >
      <Flex direction="column" gap="size-300" justifyContent="space-between">
        <SafePrice
          currency={currency}
          data-testid="Price"
          isTaxInclusive={isTaxInclusive}
          overrideStyles={styles}
          price={cancelFeeAmt}
          taxTerm={taxTerm}
        />
        <Text>
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- want to keep the goUrl inline */}
          {intl.formatMessage(
            {id: 'account.selfCancel.components.lossAversionCard.cancellationFee.content'},
            {
              goUrl: (urlText) => (
                <GoUrl
                  name="manage_account"
                  noWrap={false}
                  /* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to change link color */
                  UNSAFE_className={styles['card-go-url']}
                >
                  {urlText}
                </GoUrl>
              ),
            }
          )}
        </Text>
      </Flex>
    </SelfCancelCard>
  );
};

CancellationFeeCard.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
};

export default CancellationFeeCard;
