(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name directoryFetcher
   * @description Directory data fetcher
   */
  angular.module('app.core.directories').factory('directoryFacade', factory);

  /* @ngInject */
  function factory(_, Directory, DirectoryList, OrganizationList, OrganizationManager) {
    const service = {
      getDirectory,
      getDirectoryList,
    };

    return service;

    ////////

    function getDirectory(directoryId) {
      const directoryData = Directory.get({
        id: directoryId,
        orgType: OrganizationList.get().activeOrg.type,
      });

      return directoryData;
    }

    function getDirectoryList(apiOptions = {}) {
      return DirectoryList.get(
        _.assign(
          {
            orgId: OrganizationManager.getActiveOrgId(),
            orgType: OrganizationList.get().activeOrg.type,
          },
          apiOptions
        )
      );
    }
  }
})();
