import {SELECTION_MODE} from '@pandora/react-table-section';
import React from 'react';

import rootStore from 'core/RootStore';

import {AppIntegrationTableContextProvider} from '../../hooks/AppIntegrationTableContext';
import AppIntegrationsTableSection from '../table/AppIntegrationsTableSection';

import {useAddAppIntegrationModalContext} from './hooks/AddAppIntegrationModalContext';

// The 'Select app integration' step in the AddAppIntegrationModal workflow
const AddAppIntegrationModalSelectApp = () => {
  const {setTableSelection} = useAddAppIntegrationModalContext();

  return (
    <AppIntegrationTableContextProvider orgId={rootStore.organizationStore.activeOrgId}>
      <AppIntegrationsTableSection
        // Need exact px, can't use Spectrum variables. This exact px intentionally
        // cuts off a table row to indicate that the table is scroll-able.
        maxHeight="395px"
        onTableSelection={setTableSelection}
        selectionMode={SELECTION_MODE.SINGLE}
      />
    </AppIntegrationTableContextProvider>
  );
};

export default AddAppIntegrationModalSelectApp;
