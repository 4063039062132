import binkyUI from '@admin-tribe/binky-ui';
import {Flex} from '@adobe/react-spectrum';
import {ITEM_TYPE} from '@pandora/react-assignment-modal';
import {
  AssignmentModalSection,
  AssignmentModalSectionContentModel,
  AssignmentSectionContext,
} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {transformToPandoraProductsList} from 'common/utils/products-utils/getPandoraProducts';
import {getContractDisplayNames} from 'core/products/utils/productUtils';

import {useAdminModalContext} from '../AddAdminModalContext';

const {toPandoraProductTargets} = binkyUI.common.components.assignmentSectionUtils;

const ProductProfileSelectModal = ({onItemAssignment, itemsToPreselect, itemsToDisable}) => {
  const [pandoraItemsToDisable, setPandoraItemsToDisable] = useState([]);
  const [pandoraItemsToPreselect, setPandoraItemsToPreselect] = useState(undefined);
  const [pandoraAssignmentSectionValue, setPandoraAssignmentSectionValue] = useState({});
  const {orgId, products, user} = useAdminModalContext();
  const binkyItemMap = useRef({
    [ITEM_TYPE.PRODUCT_PROFILES]: [],
  });
  const assignedItems = useRef({});
  const content = useContentEntry(AssignmentModalSectionContentModel);
  const profileTargets = toPandoraProductTargets([ITEM_TYPE.PRODUCTS, ITEM_TYPE.PRODUCT_PROFILES]);

  const onItemAssignmentInternal = useCallback(
    (items) => {
      assignedItems.current = onItemAssignment(items);
    },
    [onItemAssignment]
  );

  const productAssignmentModalOnChange = useCallback(
    (itemMap) => {
      if (itemMap && itemMap[ITEM_TYPE.PRODUCT_PROFILES]) {
        const combinedItemMap = {
          ...binkyItemMap.current,
          [ITEM_TYPE.PRODUCT_PROFILES]: itemMap[ITEM_TYPE.PRODUCT_PROFILES],
        };
        binkyItemMap.current = combinedItemMap;
        onItemAssignmentInternal([...combinedItemMap[ITEM_TYPE.PRODUCT_PROFILES]]);
      }
    },
    [onItemAssignmentInternal]
  );

  // Generate the AssignmentModal's "preselectedItems" from the given parameters
  useEffect(() => {
    if (itemsToPreselect[ITEM_TYPE.PRODUCT_PROFILES]) {
      const preselectedItems = itemsToPreselect;

      setPandoraItemsToPreselect({
        [ITEM_TYPE.PRODUCT_PROFILES]: preselectedItems[ITEM_TYPE.PRODUCT_PROFILES].map(
          (profile) => {
            const contractDisplayNames = getContractDisplayNames(profile.product.contractIds);
            if (contractDisplayNames?.length > 0) {
              profile.product.contractDisplayNames = contractDisplayNames;
            }

            return profile;
          }
        ),
      });
    }
  }, [itemsToPreselect]);

  // Generate the AssignmentModal's "disabledItems" from the given parameters
  useEffect(() => {
    setPandoraItemsToDisable({
      [ITEM_TYPE.PRODUCTS]:
        itemsToDisable && itemsToDisable[ITEM_TYPE.PRODUCT_PROFILES]
          ? itemsToDisable[ITEM_TYPE.PRODUCT_PROFILES]
          : [],
    });
  }, [itemsToDisable]);

  // Update the pandora-assignment section value when the products or pandoraItemsToDisable or pandoraItemsToPreselect change.
  useEffect(() => {
    if (products?.length > 0 && itemsToPreselect) {
      setPandoraAssignmentSectionValue({
        itemsToDisable: pandoraItemsToDisable,
        itemsToPreselect: pandoraItemsToPreselect,
        products: transformToPandoraProductsList(products),
        setItemsToPreselect: setPandoraItemsToPreselect,
      });
    }
  }, [itemsToDisable, pandoraItemsToDisable, itemsToPreselect, pandoraItemsToPreselect, products]);

  return (
    <AssignmentSectionContext.Provider
      // We cannot convert Binky Products to Pandora Products here, this gets run
      // every render, and the conversion is too heavy-weight.
      value={pandoraAssignmentSectionValue}
    >
      <Flex direction="column" gap="size-200" marginTop="size-200">
        <AssignmentModalSection
          content={content}
          member={user}
          onChange={productAssignmentModalOnChange}
          orgId={orgId}
          showButtonLabel={false}
          targets={profileTargets}
        />
      </Flex>
    </AssignmentSectionContext.Provider>
  );
};

ProductProfileSelectModal.propTypes = {
  itemsToDisable: PropTypes.instanceOf(Object),
  itemsToPreselect: PropTypes.instanceOf(Object),
  onItemAssignment: PropTypes.func.isRequired,
};

export default ProductProfileSelectModal;
