/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains2:appDomainTableSection
 *
 * @description A section which consists of a title, and optional description and a 1-column table for the domain name
 */
(function () {
  angular.module('app.widgets.domains2').component('appDomainTableSection', {
    bindings: {
      description: '@?',
      domainList: '<',
      title: '@',
    },
    templateUrl: 'app/widgets/domains2/domain-table-section/domain-table-section.component.html',
  });
})();
