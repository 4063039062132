const PATH_PACKAGES_ROOT = '/:orgId/packages';
const PATH_ALL_PACKAGES = `${PATH_PACKAGES_ROOT}/all-packages`;
const PATH_ADOBE_TEMPLATES = `${PATH_PACKAGES_ROOT}/adobe-templates`;
const PATH_PACKAGES_TOOLS = `${PATH_PACKAGES_ROOT}/tools`;
const PATH_PACKAGES_PREFERENCES = `${PATH_PACKAGES_ROOT}/preferences`;
const PATH_PACKAGES_SERVERS = `${PATH_PACKAGES_ROOT}/servers`;
export {
  PATH_PACKAGES_ROOT,
  PATH_ALL_PACKAGES,
  PATH_ADOBE_TEMPLATES,
  PATH_PACKAGES_TOOLS,
  PATH_PACKAGES_PREFERENCES,
  PATH_PACKAGES_SERVERS,
};
