import React from 'react';
import {useIntl} from 'react-intl';

import StorageDetailDrawer from '../common/details-drawer/StorageDetailDrawer';

/**
 * This is a detail drawer available on the Individual Folders tab in Storage
 */
const IndividualFolderDetailDrawer = () => {
  const intl = useIntl();
  const headerText = intl.formatMessage({
    id: 'storage.individualFolderDetailsDrawer.header',
  });
  const descriptionText = intl.formatMessage({
    id: 'storage.individualFolderDetailsDrawer.description',
  });
  const helpfulLinksText = intl.formatMessage({
    id: 'storage.individualFolderDetailsDrawer.link.title',
  });

  const goUrls = [
    {
      label: intl.formatMessage({
        id: 'storage.individualFolderDetailsDrawer.link.generalInformation',
      }),
      name: 'aac_individ_gen_info',
    },
    {
      label: intl.formatMessage({id: 'storage.individualFolderDetailsDrawer.link.howToUse'}),
      name: 'aac_individ_folder_use',
    },
    {
      label: intl.formatMessage({id: 'storage.individualFolderDetailsDrawer.link.information'}),
      name: 'aac_individ_user_enduser',
    },
  ];

  return (
    <StorageDetailDrawer
      descriptionText={descriptionText}
      goUrls={goUrls}
      headerText={headerText}
      helpfulLinksText={helpfulLinksText}
    />
  );
};

export default IndividualFolderDetailDrawer;
