import './AppSystemReqDetails.pcss';

import {Provider, lightTheme} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {RawIntlProvider} from 'react-intl';

import AppListTable from '../app-list-table/AppListTable';

const AppSystemReqDetails = ({intl, products}) => (
  <RawIntlProvider value={intl}>
    <Provider colorScheme="light" theme={lightTheme}>
      <div styleName="sys-req-popover-content">
        <div>
          {intl.formatMessage({
            id: 'packages.createPackage.chooseApps.sysReq.desc',
          })}
        </div>
        <div styleName="sys-req-app-list-container">
          <AppListTable products={products} showSysReqLink />
        </div>
      </div>
    </Provider>
  </RawIntlProvider>
);

AppSystemReqDetails.propTypes = {
  /**
   * intl object. Needs to be passed here since this component is rendered in a v2 Popover,
   * and the v2 Popover ultimately renders outside the root binky-react-container.
   */
  // eslint-disable-next-line react/forbid-prop-types -- Allow passing Intl to V2Popover
  intl: PropTypes.any,
  /**
   * List of products to display
   */
  products: PropTypes.instanceOf(Array).isRequired,
};

export default AppSystemReqDetails;
