import React from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';

import OrganizationDetailsInfo from '../organization-details/OrganizationDetailsInfo';

import AccountPageWrapper from './AccountPageWrapper';

/**
 * The Organization details tab on account page
 */
const OrganizationDetails = () => {
  const intl = useIntl();

  const overviewTitleKey = 'account.organizationDetails.page.title';
  useDocumentTitle({key: overviewTitleKey});
  const pageTitle = intl.formatMessage({id: overviewTitleKey});

  return (
    <AccountPageWrapper pageTestId="account-organization-details-page" title={pageTitle}>
      <OrganizationDetailsInfo />
    </AccountPageWrapper>
  );
};

export default OrganizationDetails;
