import {Button} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

const GlobalBannerRedirectUrlButton = (props) => {
  const {buttonUrl, currentBanner} = {...props};
  const intl = useIntl();
  return (
    <Button
      elementType="a"
      height="size-300"
      href={buttonUrl}
      staticColor="white"
      variant="primary"
    >
      <p color="white">{intl.formatMessage({id: currentBanner.buttonText})}</p>
    </Button>
  );
};

export default GlobalBannerRedirectUrlButton;
