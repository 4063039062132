import {StatusLight} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import './IdpCard.pcss';

const IDP_STATUS = {
  ACTIVE: {
    label: 'settings.authentication.status.active',
    variant: 'info',
  },
  DEFAULT: {
    label: 'settings.authentication.status.default',
    variant: 'info',
  },
  DEPRECATED: {
    label: 'settings.authentication.inactiveStatus',
    variant: 'neutral',
  },
  DISABLED: {
    label: 'settings.authentication.status.disabled',
    variant: 'neutral',
  },
  PENDING: {
    label: 'settings.authentication.unconfiguredStatus',
    variant: 'notice',
  },
  UNKNOWN: {
    label: 'settings.authentication.unknownStatus',
    variant: 'neutral',
  },
};

const IdpStatus = observer(({idp}) => {
  let idpStatus;

  if (idp.isActive) {
    idpStatus = idp.isDefault ? IDP_STATUS.DEFAULT : IDP_STATUS.ACTIVE;
  } else if (idp.isDisabled) {
    idpStatus = IDP_STATUS.DISABLED;
  } else {
    idpStatus = IDP_STATUS[idp.status] ?? IDP_STATUS.UNKNOWN;
  }

  return (
    <StatusLight data-testid="idp-status" marginStart="-10px" variant={idpStatus.variant}>
      <FormattedMessage id={idpStatus.label} />
    </StatusLight>
  );
});

export default IdpStatus;
export {IDP_STATUS};
