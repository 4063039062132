(function () {
  const componentName = 'appQuickAssignCsvForm';
  /**
   * @deprecated use src2 Quick Assign modal
   */
  angular.module('app.widgets.users').component(componentName, {
    bindings: {
      bulkOpMode: '<',
      displayMessage: '<?',
      files: '<',
      onSwitchToEmail: '&',
      pageContext: '<',
      preassignedProductCount: '<',
      productDataList: '<',
    },
    controller,
    templateUrl: 'app/widgets/users/quick-assign-csv-form/quick-assign-csv-form.component.html',
  });

  /* @ngInject */
  function controller($translate, _, AnalyticsEvent) {
    const vm = this;
    vm.$onInit = $onInit;

    /////////////

    function $onInit() {
      _.assign(vm, {
        $onInit,
        getUsersCount,
        PRODUCTS_CASE: {
          MANY: 'manyProductsCase',
          ONE: 'oneProductCase',
        },
        switchToEmail,
      });
      vm.modalId = 'quick-assign-modal';
      vm.productToLicenseCountMap = vm.preassignedProductCount;
      if (vm.productDataList.length > 1) {
        vm.productsCase = vm.PRODUCTS_CASE.MANY;
      } else {
        vm.productsCase = vm.PRODUCTS_CASE.ONE;
      }
    }

    function getUsersCount() {
      return $translate.instant(
        'widgets.users.quickAssignModal2.usersCount',
        {
          count: vm.productDataList[0].availableLicenses,
          usersAdded: 0,
        },
        'messageformat'
      );
    }

    function switchToEmail() {
      AnalyticsEvent.dispatch({
        componentMethod: 'quick-assign-email-list-switch-to-email',
        componentMethodType: 'click',
        componentName,
      });
      vm.onSwitchToEmail();
    }
  }
})();
