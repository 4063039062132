import {authentication} from '@admin-tribe/binky';
import axios from 'axios';

const DOMAIN_CHECK_ERRORS = {
  DOMAIN_ALREADY_CLAIMED_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_ACTIVE',
  DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN: 'DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN',
  DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG: 'DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG',
  DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE',
  DOMAIN_NAME_INVALID: 'DOMAIN_NAME_INVALID',
  DOMAIN_RESERVED: 'DOMAIN_RESERVED',
  ORG_PENDING_ESM_MIGRATION: 'ORG_PENDING_ESM_MIGRATION',
  ORG_PENDING_T2E_MIGRATION: 'ORG_PENDING_T2E_MIGRATION',
  ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST: 'ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST',
  ORGS_DO_NOT_SHARE_GTM: 'ORGS_DO_NOT_SHARE_GTM',
};

/**
 * Get headers for JIL API calls
 *
 * @param {String} clientId - The client id
 * @param {Array<String>} [includeRoles] - An array of admin roles to include in the request
 * @returns {Object} The header map
 */
const getHeaders = ({clientId, includeRoles}) => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject || !tokenObject.token) {
    throw new Error('Unable to get token for JIL API call');
  }
  const headers = {
    Authorization: `Bearer ${tokenObject.token}`,
    'X-Api-Key': clientId,
  };

  if (includeRoles) {
    headers['X-Include-Roles'] = includeRoles.join(',');
  }
  return headers;
};

let clientId, includeRoles, url;

/**
 * Configure JIL user groups APIs
 *
 * @param {Object} config - The configuration object
 * @param {String} config.url - The root url for JIL User Groups api
 * @param {String} config.clientId - The identifier for application
 * @param {Array<String>} config.includeRoles - An array of admin roles to include in the request
 */
const configure = (config) => {
  ({url, clientId, includeRoles} = config);
};

/**
 * @deprecated. Please use binky/src2/core/api/jil/jilDomains.js
 */
const previewDomains = ({orgId, domains}) => {
  const data = domains.map((domainName) => ({domainName}));

  return axios.post(`${url}/v2/organizations/${orgId}/domains:preview`, data, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @deprecated. Please use binky/src2/core/api/jil/jilDomains.js
 */
const claimDomains = ({directoryId, orgId, domains}) => {
  const data = domains.map((domainName) => ({directoryId, domainName}));

  return axios.post(`${url}/v2/organizations/${orgId}/domains`, data, {
    headers: getHeaders({clientId, includeRoles}),
  });
};

/**
 * @deprecated. Please use binky/src2/core/api/jil/jilDomains.js
 */
const setDomainStatus = ({orgId, domainName, status}) =>
  axios.patch(
    `${url}/v2/organizations/${orgId}/domains`,
    [{op: 'replace', path: `/${domainName}/status`, value: status}],
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilDirectoryDomains = {claimDomains, configure, previewDomains, setDomainStatus};

export default jilDirectoryDomains;
export {DOMAIN_CHECK_ERRORS, getHeaders};
