/**
 * @deprecated ported to src2 or no longer required
 *
 * @ngdoc component
 * @name app.widgets.domains:appValidateDomainOwnershipModal
 *
 * @description The Validate Domain Ownership Modal which is invoked from several places on the identity domain page.
 * @binding {Function} onStatusChange. The callback function to be invoked after the validate domains operation has
 *   completed.
 */
(function () {
  angular.module('app.widgets.domains2').component('appValidateDomainOwnershipModal', {
    bindings: {
      onStatusChange: '&?',
    },
    controller,
    templateUrl:
      'app/widgets/domains2/validate-ownership-modal/validate-domain-ownership-modal.component.html',
  });

  /* @ngInject */
  function controller(
    $q,
    $translate,
    _,
    clipboard,
    DisplayMessage,
    DomainList,
    DOMAIN_STATUS,
    feature,
    OrganizationList,
    OrganizationManager,
    OrganizationToken,
    panelManager,
    toastManager,
    TOAST_TYPES,
    VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID
  ) {
    const vm = this;

    const DNS_RECORD_PREFIX = '@ 3600 IN TXT';
    const RECORD_VALUE_PREFIX = 'adobe-idp-site-verification=';

    const orgId = OrganizationList.get().activeOrg.id;

    _.assign(vm, {
      $onInit,
      onCopy,
      onModalClose,
      onModalOpen,
      onValidateDomains,
      showValidateDomainsButton,
    });

    function $onInit() {
      _.assign(vm, {
        copyToClipboardSupported: clipboard.supported,
        domainValidationListDisplay: {
          checkbox: false,
          header: false,
          name: true,
        },
        modalId: VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID,
        showContent: true,
        waitOnModal: $q.defer().promise,
      });
    }

    function onCopy(value) {
      const message = $translate.instant(
        value === 'dns-record'
          ? 'widgets.domains2.validateOwnershipModal.copyConfirmationDNSRecord'
          : 'widgets.domains2.validateOwnershipModal.copyConfirmationRecordValue'
      );
      toastManager.showSuccessToast(message);
    }

    function onModalClose() {
      vm.dnsRecord = null;
      vm.displayMessage = undefined;
      vm.recordValue = null;
    }

    /**
     * @description Opens the Access DNS Token / Validate Domain Ownership modal.
     * @param {Object} params - context which determines which operation and domains to validate
     * @param {Object} params.context - one of 'token', 'items', or 'all.
     *   'token' -> then model will be the 'Access DNS Token' variant
     *   'items' -> for validate link or selection and validate button click, requires value which is array of Domain 2
     *   'all' -> then modal will GET and show first 100 unclaimed domains. Note: JIL GET domain is limited to 1000
     *     domains, and JIL PATCH to validate domains is limited to 100 domains.
     * @param {Object} params.domainList - this vm.domainList
     * @param {Object} params.value - array of Domain - required if context is 'items'
     * @returns {undefined}
     */
    function onModalOpen(params) {
      const variant = params.context === 'token' ? 'accessDnsToken' : 'validateDomainOwnership';
      vm.modalTitle = $translate.instant(
        `widgets.domains2.validateOwnershipModal.modalTitle.${variant}`
      );

      // The domain list for the domain table.
      vm.domainList = params.domainList;

      // The DNS token for the org.
      vm.organizationToken = OrganizationToken.get({id: orgId});

      const promises = [vm.organizationToken.$promise];

      // Because of pageSize limitations, and perhaps the number of domains we can validate at one time,
      // only the first 100 unclaimed domains will be shown for validation.
      if (params.context === 'all') {
        vm.unclaimedDomainList = DomainList.get({
          orgId: OrganizationManager.getActiveOrgId(),
          pageSize: 100,
          status: DOMAIN_STATUS.UNCLAIMED,
        });
        promises.push(vm.unclaimedDomainList.$promise);
      }

      vm.waitOnModal = $q
        .all(promises)
        .then(() => {
          vm.recordValue = `${RECORD_VALUE_PREFIX}${vm.organizationToken.token}`;
          // for ex. @ 3600 IN TXT adobe‑idp‑site‑verification={token}
          vm.dnsRecord = `${DNS_RECORD_PREFIX} ${vm.recordValue}`;

          // Items are dependent on context.
          // context: items, all, token
          vm.items = getItems(params);
        })
        .catch(() => {
          // hide all content as well as the Validate Now button
          vm.showContent = false;

          vm.displayMessage = new DisplayMessage({
            body: $translate.instant(
              'widgets.domains2.validateOwnershipModal.pageBanner.catchallError'
            ),
          });
        });
    }

    // Due to API performance, there may be multiple underlying PATCH calls.
    // Each call, except the last one, will call onNotify. The last one will call onSuccess.
    function onValidateDomains() {
      vm.isBusy = true;
      vm.displayMessage = undefined;
      vm.waitOnModal = vm.domainList.$promise;
      const requestCount = vm.items.length;

      // eslint-disable-next-line promise/valid-params
      vm.domainList
        .validateDomains(vm.items)
        .then(onSuccess, onError, onNotify)
        .catch(onError)
        .finally(() => {
          _.result(vm, 'onStatusChange');
          vm.isBusy = false;
        });

      function onNotify(validatedDomains) {
        const message = $translate.instant(
          'widgets.domains2.validateOwnershipModal.messageFormat.validateDomainsProgress',
          {count: validatedDomains.length},
          'messageformat'
        );
        toastManager.showToast(TOAST_TYPES.INFO, message);
      }

      // validatedDomains is a list of those domains that were successfully validated.
      function onSuccess(validatedDomains) {
        if (validatedDomains.length === requestCount) {
          panelManager.close(VALIDATE_DOMAIN_OWNERSHIP_MODAL_ID);
          showValidatedToast(validatedDomains.length);
        } else if (validatedDomains.length > 0) {
          onPartialSuccess(validatedDomains);
        } else {
          onError();
        }
      }

      // No domains in one of the chunks could be updated.
      function onError() {
        showPageBanner('error', requestCount);
        toastManager.showErrorToast();
      }

      // At least one, but not all the domains have successfully been updated.
      function onPartialSuccess(validatedDomains) {
        // Remove the domains that were validated from vm.items so only the domains that still need to be validated
        // are in the table.
        // items are [Domain] and validatedDomains are [Resource] so compare by domainName.
        vm.items = _.differenceWith(
          vm.items,
          validatedDomains,
          (a, b) => a.domainName === b.domainName
        );

        showPageBanner('partialSuccess', validatedDomains.length);
        showValidatedToast(validatedDomains.length);
      }
    }

    function showValidateDomainsButton() {
      return vm.showContent && _.get(vm.items, 'length', 0) > 0;
    }

    ////////////

    // Click on Validate link should set context to items with value an array of 1 items.
    // Clicking on rows, and then the Validate button, should set context to items with value an array of items.
    // Clicking on Validate Domain Ownership in kabob should set context to all with no value.
    // Clicking on Access DNS Token in the kabob should set context to token with no value.
    function getItems(params) {
      if (params.context === 'items') {
        vm.items = params.value;
      } else if (params.context === 'all') {
        vm.items = vm.unclaimedDomainList.items;
      } else {
        vm.items = [];
      }

      return vm.items;
    }

    function showPageBanner(msg, count) {
      vm.displayMessage = new DisplayMessage({
        body: $translate.instant(
          `widgets.domains2.validateOwnershipModal.pageBanner.messageFormat.${msg}`,
          {count},
          'messageformat'
        ),
      });
    }

    function showValidatedToast(count) {
      toastManager.showSuccessToast(
        $translate.instant(
          'widgets.domains2.validateOwnershipModal.toasts.messageFormat.domainsValidated',
          {count},
          'messageformat'
        )
      );
    }
  }
})();
