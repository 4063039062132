(function () {
  /**
   * @deprecated ported to src2 or no longer required
   *
   * @ngdoc factory
   * @name AdobeStatusDataList
   * @description model that represents the collection of adobe status items
   */
  angular.module('app.core.adobe-status').factory('AdobeStatusDataList', getAdobeStatusDataList);

  /* @ngInject */
  function getAdobeStatusDataList(
    $interval,
    $q,
    $rootScope,
    _,
    ADOBE_STATUS_CONSTANTS,
    ADOBE_STATUS_MESSAGES,
    adobestatus,
    AdobeStatusData,
    List
  ) {
    class AdobeStatusDataList extends List {
      /**
       * @description Method to create a new instance of a AdobeStatusDataList.
       */
      constructor() {
        super();
        this.$resolved = false;
        // We want to keep track of the status of any currently active (or very recent) events,
        // so that the user can see when they're resolved. The RECENT_HISTORY_THRESHOLD determines
        // how far back from now we should look for resolved events.
        this.statusWatchStart = new Date(
          Date.now() - ADOBE_STATUS_CONSTANTS.RECENT_HISTORY_THRESHOLD
        );
      }

      /**
       * @description Returns all active events and any that ended after we started watching
       * @returns {Array} The events
       */
      getActiveAndRecentStatusEvents() {
        let currentStatusEvents = [];
        const vm = this;
        _.forEach(this.items, (item) => {
          if (item.hasActiveOrRecentEvents({eventsSinceDate: vm.statusWatchStart})) {
            currentStatusEvents = _.concat(
              currentStatusEvents,
              item.getActiveAndRecentEvents(vm.statusWatchStart)
            );
          }
          if (item.hasScheduledMaintenance()) {
            currentStatusEvents = _.concat(currentStatusEvents, item.scheduledMaintenance);
          }
        });
        return _.compact(currentStatusEvents).sort(eventSort);
      }

      /**
       * @description Determines if there are any currently ongoing non-maintenance events
       * @returns {Boolean} True if some non-maintenance events are ongoing, false otherwise
       */
      hasOngoingEvents() {
        const types = [
          ADOBE_STATUS_CONSTANTS.EVENT_TYPE.DISCOVERY,
          ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MAJOR,
          ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MINOR,
        ];
        return _.some(this.items, (item) =>
          item.hasActiveOrRecentEvents({
            restrictToTypes: types,
          })
        );
      }

      /**
       * @description Determines if there are any currently ongoing maintenance events
       * @returns {Boolean} True if some maintenance events are ongoing, false otherwise
       */
      hasOngoingMaintenance() {
        return _.some(this.items, (item) =>
          item.hasActiveOrRecentEvents({
            restrictToTypes: [ADOBE_STATUS_CONSTANTS.EVENT_TYPE.MAINTENANCE],
          })
        );
      }

      /**
       * @description Method to load and process the adobe status list.
       * @returns {Promise} promise resolved when adobe status list is loaded.
       */
      refresh() {
        this.$resolved = false;
        this.$promise = $q((resolve, reject) => {
          this.items = [];

          $rootScope.$emit(ADOBE_STATUS_MESSAGES.INVALIDATE);
          adobestatus.currentStatus.get({}, onSuccess.bind(this), onError.bind(this));

          function onSuccess(response) {
            const responseItems = _.get(response, 'products', []);
            this.items = _(responseItems)
              .map((statusData) => {
                try {
                  return AdobeStatusData.apiResponseTransformer(statusData);
                } catch (error) {
                  return undefined;
                }
              })
              .compact()
              .concat(this.items)
              .value();

            this.$resolved = true;
            resolve(this);
            $rootScope.$emit(ADOBE_STATUS_MESSAGES.REFRESH);
          }
          function onError(error) {
            this.$resolved = true;
            reject(error);
            $rootScope.$emit(ADOBE_STATUS_MESSAGES.ERROR);
          }
        });

        return this.$promise;
      }

      /**
       * @description Method to set up polling the status API for updates
       */
      startPolling() {
        this.$pollingPromise = $interval(
          this.refresh.bind(this),
          ADOBE_STATUS_CONSTANTS.POLL_FREQUENCY
        );
      }

      /**
       * @description Method to halt polling the status API
       */
      stopPolling() {
        if (this.$pollingPromise) {
          $interval.cancel(this.$pollingPromise);
          this.$pollingPromise = undefined;
        }
      }

      /**
       * @description Method to fetch the singleton AdobeStatusDataList.
       * @returns {AdobeStatusDataList} the AdobeStatusDataList.
       */
      static get() {
        if (!AdobeStatusDataList.model) {
          AdobeStatusDataList.model = new AdobeStatusDataList();
          AdobeStatusDataList.model.refresh();
          AdobeStatusDataList.model.startPolling();
        }
        return AdobeStatusDataList.model;
      }
    }

    return AdobeStatusDataList;

    /** Private Methods **/

    /**
     * @description Method to use for array sort of events
     *  This sort function is designed to put active (ongoing) events at the top.
     *  Resolved or scheduled maintenance events are below. Each section is sorted by date
     *  with newer -> older. The effect of this is that scheduled maintenance (with their
     *  dates in the future) will always be sorted before resolved events (dates in the past).
     * @param {Object} a Event a for comparison
     * @param {Object} b Event b for comparison
     * @returns {Number} -1 for a sorts before b; 1 for b sorts before a; 0 for a and b equal for sorting
     */
    function eventSort(a, b) {
      if (a.isActive() && !b.isActive()) {
        return -1;
      } else if (b.isActive() && !a.isActive()) {
        return 1;
      }
      return b.timestamp.getTime() - a.timestamp.getTime();
    }
  }
})();
