(function () {
  /**
   * @deprecated ported to src2 or no longer required
   */
  angular.module('app.widgets.domains2').component('appEditDirectoryModal', {
    bindings: {
      directoryList: '<',
      domainList: '<',
      domainsNotMigrated: '<',
      domainsToMigrate: '<',
      onCloseModal: '&?',
      onEditDirectory: '&?',
    },
    controller,
    templateUrl: 'app/widgets/domains2/edit-directory-modal/edit-directory-modal.component.html',
  });

  function controller(
    $translate,
    $q,
    _,
    APP_EDIT_DIRECTORY_MODAL_ID,
    OrganizationEncryptionMigration,
    OrganizationList,
    panelManager,
    toastManager
  ) {
    const vm = this;

    _.assign(vm, {
      APP_EDIT_DIRECTORY_MODAL_ID,
      isBusy: false,
      onCancel,
      onNotifyAdminsChange,
      onOpen,
      onSave,
      onSearch,
      onSelectDirectoryListItem,
      waitPromise: $q.resolve(),
    });

    function onCancel() {
      panelManager.close(APP_EDIT_DIRECTORY_MODAL_ID);
    }

    function onNotifyAdminsChange() {
      vm.notifyAdmins = !vm.notifyAdmins;
    }

    function onOpen() {
      _.assign(vm, {
        isMigratedToOrgLevelEncryption: OrganizationEncryptionMigration.get({
          orgId: OrganizationList.get().activeOrg.id,
        }).isMigrated(),
        notifyAdmins: true,
        selectedDirectoryId: undefined,
      });
      showDefaultPageBanner();
    }

    function onSave() {
      showDefaultPageBanner();

      const selectedDirectory = getSelectedDirectory();
      vm.waitPromise = selectedDirectory
        .update({
          notifyAdminsDomainMigrationComplete: vm.notifyAdmins,
        })
        .then(() =>
          // JIL will know what domains are getting re-linked as opposed to getting linked the
          // first time looking at the current domain status.
          vm.domainList.linkDomainsToDirectory({
            directoryId: selectedDirectory.id,
            domains: vm.domainsToMigrate,
          })
        )
        .then(onSuccess)
        .catch(onError);

      function onSuccess(response) {
        const {difference} = response;
        if (_.isEmpty(difference)) {
          panelManager.close(APP_EDIT_DIRECTORY_MODAL_ID);
          toastManager.showSuccessToast(
            $translate.instant(
              vm.notifyAdmins
                ? 'widgets.domains2.editDirectory.toast.successWithEmailEnabled'
                : 'widgets.domains2.editDirectory.toast.successWithEmailDisabled'
            )
          );
          vm.selectedDirectoryId = undefined;
          _.result(vm, 'onEditDirectory');
        } else {
          // Grab the domainNames that were not linked
          const domainsToFilter = _(difference)
            .filter((item) => item.path && _.includes(item.path, '/'))
            .map((item) => {
              const splitPath = _.split(item.path, '/');
              const domainName = splitPath[1];
              return domainName;
            })
            .value();

          // Reset the domainsToMigrate array with ONLY the domains that were not migrated
          vm.domainsToMigrate = _.filter(vm.domainsToMigrate, (domain) =>
            _.includes(domainsToFilter, domain.domainName)
          );

          // Show the page banner so the user can decide to try again.
          showPageBanner({
            body: $translate.instant(
              'widgets.domains2.editDirectory.pageBanner.editDirectoryError'
            ),
          });
        }
      }
      function onError() {
        showPageBanner({
          body: $translate.instant('widgets.domains2.editDirectory.pageBanner.editDirectoryError'),
        });
      }
    }

    function onSearch(searchQuery) {
      const searchQueryLowerCased = _.toLower(searchQuery);
      vm.searchResults = _.filter(
        vm.directoryList,
        (directory) =>
          !directory.isType2E() &&
          _.chain(directory.name).toLower().includes(searchQueryLowerCased).value()
      );
    }

    function onSelectDirectoryListItem(directoryId) {
      vm.selectedDirectoryId = directoryId;
      if (vm.selectedDirectoryId) {
        showDefaultPageBanner();
      }
    }

    /////////////////

    function getSelectedDirectory() {
      return _.find(vm.directoryList, ['id', vm.selectedDirectoryId]);
    }

    function showDefaultPageBanner() {
      showPageBanner({
        body: $translate.instant('widgets.domains2.editDirectory.pageBanner.usersLoggedOut.body'),
        header: $translate.instant(
          'widgets.domains2.editDirectory.pageBanner.usersLoggedOut.header'
        ),
        variant: 'info',
      });
    }

    function showPageBanner({body, header = '', variant = 'error'}) {
      _.assign(vm, {
        pageBannerBody: body,
        pageBannerHeader: header,
        pageBannerVariant: variant,
        showPageBanner: true,
      });
    }
  }
})();
