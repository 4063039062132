//
// App Configuration - analytics
//
(function () {
  /**
   * @awaitingdeprecation
   */
  angular.module('app.core.ready.analytics').provider('analyticsReady', analyticsReadyProvider);

  /* @ngInject */
  function analyticsReadyProvider(aepAnalyticsProvider, configurationReadyProvider) {
    this.$get = $get;
    this.configure = configure;

    ////////////

    function configure() {
      return configurationReadyProvider
        .whenConfigReady()
        .then(onConfigurationReadyProviderFulfilled);

      ////////////

      function onConfigurationReadyProviderFulfilled(configData) {
        aepAnalyticsProvider.configure(configData.services.launch);
      }
    }

    /* @ngInject */
    function $get($q, authenticationReady, aepAnalytics, orgReady) {
      const deferred = $q.defer();

      return {
        run,
        whenRunReady,
      };

      ////////////

      function run() {
        const promises = [authenticationReady.whenRunReady(), orgReady.whenRunReady()];
        const runPromise = $q.all(promises).then(initLaunchAnalytics);

        runPromise.then(deferred.resolve).catch(deferred.reject);
        return runPromise;
      }

      function whenRunReady() {
        return deferred.promise;
      }

      function initLaunchAnalytics() {
        aepAnalytics.initialize();
        return $q.resolve();
      }
    }
  }
})();
