import {Workspace} from '@admin-tribe/binky-ui';
import React from 'react';

import AccountWorkspaceNav from './AccountWorkspaceNav';

/**
 * Workspace for the account tab.
 */
const AccountWorkspace = () => (
  <Workspace useOutlet>
    <AccountWorkspaceNav />
  </Workspace>
);

export default AccountWorkspace;
