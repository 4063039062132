const {JIL_STORAGE_FOLDER_PATH, JIL_STORAGE_FOLDER_SORT} =
  // eslint-disable-next-line angular/window-service
  window.onesieSrc2.common.api.jil.jilStorageFoldersConstants;

(function () {
  /**
   * @deprecated use src2/app/common/api/jil/jilStorageFolderConstants
   *
   * @ngdoc constant
   * @name JIL_STORAGE_FOLDERS
   * @description defines constants used in making api calls to JIL storage
   *  folders API
   */
  angular.module('app.core.api.jil').constant('JIL_STORAGE_FOLDERS', {
    PATH: JIL_STORAGE_FOLDER_PATH,
    SORT: JIL_STORAGE_FOLDER_SORT,
  });
})();
