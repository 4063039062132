import React from 'react';

import {TrusteeListContextProvider} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import TrusteeListTable from 'features/settings/common/components/trustee-list-table/TrusteeListTable';
import useTrusteeListState from 'features/settings/components/trustee/hooks/useTrusteeListState';

/**
 * Defines the list of trusted directories (trustees)
 */
const TrusteeList = () => {
  const listState = useTrusteeListState();

  return (
    <TrusteeListContextProvider listState={listState}>
      <TrusteeListTable />
    </TrusteeListContextProvider>
  );
};

TrusteeList.displayName = 'TrusteeList';

export default TrusteeList;
