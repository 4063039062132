import binky from '@admin-tribe/binky';
import axios from 'axios';

const {authentication, Locale} = binky.services;
let clientId, url, urlV2;

/**
 * Configure ANS Notification APIs
 *
 * @param {Object} config - The configuration object
 * @param {string} config.clientId - The identifier for the consuming application
 * @param {String} config.url - The root url for ANS api
 */
const configure = (config) => {
  clientId = config.clientId;
  url = `${config.url}/notifications`;
  urlV2 = `${config.urlV2}/notifications`;
};

/**
 * Calls ANS APIs to delete a list of notifications.
 *
 * @param {Object} data - An object contains list of Notification id that need to be deleted.
 * Ex: {notifications: {notification: [{'notification-id': 'id'}]}}
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const deleteNotifications = async (data) => {
  const response = await axios.delete(
    urlV2, // delete operation is only available on v2 path
    buildRequest({data})
  );
  return response.data;
};

/**
 * Calls ANS APIs to retrieve a list of notifications.
 *
 * @param {Long} from - Start timestamp of notifications needed in milliseconds, e.g. 12569537329
 * @param {Long} to - End timestamp of notifications needed in milliseconds, e.g. 12569537329
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const getNotifications = async ({from, to}) => {
  const response = await axios.get(url, buildRequest({params: {from, to}}));
  return response.data;
};

/**
 * Calls ANS APIs to update a list of notifications.
 *
 * @param {Array} notifications - List of Notifications that need to be updated
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const updateNotifications = async (notifications) => {
  const response = await axios.put(url, notifications, buildRequest());
  return response.data;
};

// Helpers

function buildHeaders() {
  const headers = {
    'x-adobe-app-id': 'AdobeAdminConsole',
    'X-Api-Key': clientId,
  };
  const token = authentication.getAccessToken();
  if (token?.token) {
    headers.Authorization = `Bearer ${token.token}`;
  }
  return headers;
}

function buildParams(params) {
  return {
    locale: Locale.get() ? Locale.get().activeLocaleCode : undefined,
    ...params,
  };
}

function buildRequest({data, params} = {}) {
  const request = {
    data,
    headers: buildHeaders(),
    params: buildParams(params),
    timeout: 5000,
  };
  return request;
}

const ans = {configure, deleteNotifications, getNotifications, updateNotifications};

export default ans;
