(function () {
  /**
   * @deprecated
   */
  angular
    .module('app.widgets.products')
    .constant('CONSUMABLES_LIST_TABLE_SORT', {
      SORT_EXPIRATION_DATE: 'expiration_date',
      SORT_ORDER_DESC: false,
    })
    .constant('CONSUMABLES_LIST_TABLE_ID', 'consumables-list-table-id')
    .constant('CONSUMABLES_LIST_TABLE_TRANSLATION_KEY', {
      CREDIT: 'credit',
      TRANSACTION: 'transaction',
    });
})();
